import React, { useState, useEffect, useContext } from 'react';
import * as fkn from "../lib/fkn";
import * as net from "../lib/net";
import SDOContext from '../sdo.js'

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Red_Home extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, tra: [] };
    //context.posrend = this;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.trans();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      //css.height = window.innerHeight - 200;
      return css;
  }

  trans() {
      var self = this;
    var datum = "20200328";
    var prm = {};
    prm.req = "pos.pos_cas.trs_dt";
    prm.fdat = datum;
    prm.tdat = datum;

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        var o = {};
        //o.deps = ret.rco;
        //alert(JSON.stringify(o));
        self.setState({tra: ret.rca });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
    }

  render() {
    return (
    <div className="web-app">
    <h3>Hem</h3>
    <div className="web-row flex">
        <div className="web-b01 flex">
            Q1
        </div>
        <div className="web-b01 flex">
            Q2
        </div>
    </div>
    <div className="web-row flex">
        <div className="web-b01 flex">
        </div>
        <div className="web-b01 flex">
            <table className="table table-bordered table-hover tb fixed_header">
                <thead>
                <tr>
                    <th>ID</th>
                    <th>Text</th>
                    <th>Antal</th>
                    <th>Pris</th>
                    <th>Summa</th>
                    <th>Terminal</th>
                    <th>Status</th>
                </tr>
                </thead>
                <tbody style={ this.tb_style() }>
                { this.state.tra.map((row, key) =>
                <tr id={ row.r0dateid } ng-dblclick="trans_open(row);">
                    <td>{row.r0etyp}</td>
                    <td>{row.r0text}</td>
                    <td>{row.r0noof}</td>
                    <td>{row.r0perprice}</td>
                    <td>{row.r0perprice}</td>
                    <td>{row.r0terminal}</td>
                    <td>{row.r0ttyp}</td>
                </tr>
                )}
                </tbody>
            </table>
        </div>
    </div>

    </div>
  );
    }
}
export default Red_Home;
