import React, { useState, useEffect, useContext } from 'react';

import * as utils from "../../assets/utils";
import * as net from "../../lib/net";
import Lang from "../../lib/lang";

import Td_Sel from "../../lib/ui/td_sel";
import Wnd_Confirm from "../../lib/ui/wnd_confirm";
import Wnd_Sign from "../../lib/ui/wnd_sign";

import Pms_InhSel from "./inh/inh_sel";
import Pms_Nota from "./nota/nota";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Pms_Inh extends React.Component {
  constructor(props, context) {
    super(props);

    //this.lang = {};
    this.webStore = props.store;
    this.state = { pgid: 11, tra: [] };
    this.state.today = new Date();
    this.state.avd = "01";
    this.state.aa = [];
    this.state.inhsel = 0;
      this.state.msg = {};
      this.state.rvo = {};

    this.state.rva = [];
    this.state.rvo = {};
    this.state.selrow = {};
    this.state.wnds = {};
    this.state.sortid = "";
    this.state.sortdir = 1;
      this.state.loading = false;

    this.selitems = [
        {fkn: 'open', text: 'ÖPPNA'},
        {fkn: 'cancel', text: 'AVBOKA'}
    ];
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.inh();
  }

  body_style() {
    var css = {};
    css.height = window.innerHeight - 280;
    return css;
  }
  tb_style() {
    var css = {};
    css.height = window.innerHeight - 250;
    css.overflow = "auto";
    return css;
  }
  th_style(id) {
      var css = {};
      css.cursor = "pointer";
      if(id == this.state.sortid) {
          if(this.state.sortdir) css.color = "#800";
          else css.color = "#080";
      }
      return css;
  }
  row_style(row) {
    var srow = this.state.selrow;

    var css = {};
    if(srow == row) {
        css.background = "#080";
        css.color = "#fff";
    }
    return css;
  }
  clean_style(row) {
    var css = {};
    css.background = "#ff8";
    css.color = "#222";

    if(row.clean == "1") {
        css.background = "#080";
        css.color = "#fff";
    }
    if(row.clean == "2") {
        css.background = "#f90";
        css.color = "#fff";
    }
    return css;
}

  onUnitChange(e) {
    this.setState({unit: e.value});
  }

    sel_gst(row) {
        var self = this;
        var msg = { info: false, sign: true, text: "Signatur?", text0: "JA", text1: "NEJ", cb: "sel_gst_do" };
        self.setState({ msg: msg, srow: row });
    }
    sel_gst_do(signatur) {
        var self = this;
        var row = this.state.srow;

        //alert(JSON.stringify(val));
        self.setState({msg: {} });

        var wnds = {};
        wnds.inhsel = true;
        wnds.boknr = row.boknr;
        wnds.rvo = row;
        ///this.setState({inhsel: 1, boknr: row.boknr, rvo: row});
        this.setState({wnds: wnds});
    }
  tst() {
    var self = this;
  }
  gst_nota() {
    var self = this;

    var srow = this.state.selrow;
    if(!srow.boknr) return;

    var wnds = {};
    wnds.nota = true;
    wnds.boknr = srow.boknr;
    wnds.rvo = srow;
    this.setState({wnds: wnds});
  }

  inh() {
    var self = this;
    this.setState({ rva: [], loading: true });

    var prm = {};
    prm.req = "pms.op.my_inhouse";

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        var rva = [];
        var row = {};
        for(row of ret.rca) {
            //alert(JSON.stringify(row.resv00));

            //row.charges.saldo = "0";
            //row.charges.payments = "0";
            //row.charges.topay = "0";
            if(row.gsb00) rva.push(row);
        }
        self.setState({ rva: rva, loading: false }, function() {
            self.syncTb("inhTable");
        });
        //self.setState({ rva: ret.rca });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
    syncTb(tb) {
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
        tdElements = tbel.getElementsByTagName('td');
        if(tdElements.length < 1 ) return;

        for (let i = 0; i < thElements.length; i++) {
            if(!tdElements[i]) continue;
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
            width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
  sel_row(row) {
    var srow = this.state.selrow;
    if(srow == row) {
      this.setState({selrow: {}});
    }
    else this.setState({selrow: row});
  }
  sel_row_fkn(row, sel) {
      if(sel.fkn == "open") {
          this.sel_gst(row);
      }
  }
    gst_close(ctx) {
        ctx.setState({ wnds: {} });
    };
   tb_sort(typ) {
      var self = this;
      //var rvs = self.state.rvs;
      var rva = self.state.rva;
      var sortid = this.state.sortid;
      var sortdir = this.state.sortdir;

      if(sortid != typ) sortdir = 0;
      else {
        if(sortdir) sortdir = 0;
        else sortdir = 1;
      }

      if(typ == "ank") {
        if(sortdir) rva.sort((a,b) => (a.gsb00.r0ankdatum < b.gsb00.r0ankdatum) ? 1 : ((b.gsb00.r0ankdatum < a.gsb00.r0ankdatum) ? -1 : 0));
        else rva.sort((a,b) => (a.gsb00.r0ankdatum > b.gsb00.r0ankdatum) ? 1 : ((b.gsb00.r0ankdatum > a.gsb00.r0ankdatum) ? -1 : 0));
      }
      if(typ == "avr") {
        if(sortdir) rva.sort((a,b) => (a.gsb00.r0avrdatum < b.gsb00.r0avrdatum) ? 1 : ((b.gsb00.r0avrdatum < a.gsb00.r0avrdatum) ? -1 : 0));
        else rva.sort((a,b) => (a.gsb00.r0avrdatum > b.gsb00.r0avrdatum) ? 1 : ((b.gsb00.r0avrdatum > a.gsb00.r0avrdatum) ? -1 : 0));
      }
      if(typ == "boknr") {
        if(sortdir) rva.sort((a,b) => (a.gsb00.r0boknr < b.gsb00.r0boknr) ? 1 : ((b.gsb00.r0boknr < a.gsb00.r0boknr) ? -1 : 0));
        else rva.sort((a,b) => (a.gsb00.r0boknr > b.gsb00.r0boknr) ? 1 : ((b.gsb00.r0boknr > a.gsb00.r0boknr) ? -1 : 0));
      }
      if(typ == "ftg") {
        if(sortdir) rva.sort((a,b) => (a.resv00.Company < b.resv00.Company) ? 1 : ((b.resv00.Company < a.resv00.Company) ? -1 : 0));
        else rva.sort((a,b) => (a.resv00.Company > b.resv00.Company) ? 1 : ((b.resv00.Company > a.resv00.Company) ? -1 : 0));
      }
      if(typ == "namn") {
        if(sortdir) rva.sort((a,b) => (a.gsb00.r0namn < b.gsb00.r0namn) ? 1 : ((b.gsb00.r0namn < a.gsb00.r0namn) ? -1 : 0));
        else rva.sort((a,b) => (a.gsb00.r0namn > b.gsb00.r0namn) ? 1 : ((b.gsb00.r0namn > a.gsb00.r0namn) ? -1 : 0));
      }
      if(typ == "rumsnr") {
        if(sortdir) rva.sort((a,b) => (a.gsb00.r0rumsnr < b.gsb00.r0rumsnr) ? 1 : ((b.gsb00.r0rumsnr < a.gsb00.r0rumsnr) ? -1 : 0));
        else rva.sort((a,b) => (a.gsb00.r0rumsnr > b.gsb00.r0rumsnr) ? 1 : ((b.gsb00.r0rumsnr > a.gsb00.r0rumsnr) ? -1 : 0));
      }
      if(typ == "rt") {
        if(sortdir) rva.sort((a,b) => (a.rblx0.r0typ < b.rblx0.r0typ) ? 1 : ((b.rblx0.r0typ < a.rblx0.r0typ) ? -1 : 0));
        else rva.sort((a,b) => (a.rblx0.r0typ > b.rblx0.r0typ) ? 1 : ((b.rblx0.r0typ > a.rblx0.r0typ) ? -1 : 0));
      }
      if(typ == "saldo") {
        if(sortdir) rva.sort((a,b) => (a.charges.saldo < b.charges.saldo) ? 1 : ((b.charges.saldo < a.charges.saldo) ? -1 : 0));
        else rva.sort((a,b) => (a.charges.saldo > b.charges.saldo) ? 1 : ((b.charges.saldo > a.charges.saldo) ? -1 : 0));
      }
      if(typ == "topay") {
        if(sortdir) rva.sort((a,b) => (a.charges.topay < b.charges.topay) ? 1 : ((b.charges.topay < a.charges.topay) ? -1 : 0));
        else rva.sort((a,b) => (a.charges.topay > b.charges.topay) ? 1 : ((b.charges.topay > a.charges.topay) ? -1 : 0));
      }
      if(typ == "bettyp") {
        if(sortdir) rva.sort((a,b) => (a.bettyp < b.bettyp) ? 1 : ((b.bettyp < a.bettyp) ? -1 : 0));
        else rva.sort((a,b) => (a.bettyp > b.bettyp) ? 1 : ((b.bettyp > a.bettyp) ? -1 : 0));
      }
      if(typ == "segment") {      
        if(sortdir) rva.sort((a,b) => (a.resm01.Kanal < b.resm01.Kanal) ? 1 : ((b.resm01.Kanal < a.resm01.Kanal) ? -1 : 0));
        else rva.sort((a,b) => (a.resm01.Kanal > b.resm01.Kanal) ? 1 : ((b.resm01.Kanal > a.resm01.Kanal) ? -1 : 0));
     }
     self.setState({ rva: rva, sortid: typ, sortdir: sortdir });
   }
   th_icon(typ) {
      var cls = "";
      var sortid = this.state.sortid;
      var sortdir = this.state.sortdir;
      if(sortid == typ)
      {
        if(sortdir) cls = "fa fa-caret-up";
        else cls = "fa fa-caret-down";
      }
      return cls;
    }

  render() {
    return (
    <div className="web-bas">

    <div className="flex-row">
        <button className="btn btn-hw-blue w200 hide" onClick={() => this.tst() } aria-label="System" >
            GÄSTFUNKTIONER
        </button>
        <button className="btn btn-hw-blue w200 hide" onClick={() => this.tst() } aria-label="System" >
            RUMSFUNKTIONER
        </button>
        <div className="flex"></div>
        <button className="btn btn-hw-blue w200" onClick={() => this.gst_nota() } aria-label="System" >
            GÄSTNOTA
        </button>
    </div>
    <div className="divider_green"></div>

    { this.state.loading ? <div className="flex flex-col flex-center"><FontAwesomeIcon icon="spinner" spin size="4x" /></div> : null }
    { !this.state.loading ?
    <div className="web-row">

        <div id="inhTable" className="flex">
          <table className="table table-bordered table-hover tb" style={{marginBottom: "0px"}}>
            <thead>
            <tr>
                <th style={ this.th_style("rumsnr") } onClick={() => this.tb_sort("rumsnr") }><Lang>Roomnr <i className={ this.th_icon("rumsnr") }></i></Lang></th>
                <th style={ this.th_style("boknr") } onClick={() => this.tb_sort("boknr") }><Lang>Resno</Lang> <i className={ this.th_icon("boknr") }></i></th>
                <th style={ this.th_style("ank") } onClick={() => this.tb_sort("ank") }><Lang>Arrival</Lang> <i className={ this.th_icon("ank") }></i></th>
                <th style={ this.th_style("avr") } onClick={() => this.tb_sort("avr") }><Lang>Departure</Lang> <i className={ this.th_icon("avr") }></i></th>
                <th style={ this.th_style("segment") } onClick={() => this.tb_sort("segment") }><Lang>Segment</Lang> <i className={ this.th_icon("segment") }></i></th>
                <th style={ this.th_style("ftg") } onClick={() => this.tb_sort("ftg") }><Lang>Company</Lang> <i className={ this.th_icon("ftg") }></i></th>
                <th style={ this.th_style("namn") } onClick={() => this.tb_sort("namn") }><Lang>Name</Lang> <i className={ this.th_icon("namn") }></i></th>
                <th style={ this.th_style("nrg") } onClick={() => this.tb_sort("nrg") }><Lang>Guests</Lang> <i className={ this.th_icon("nrg") }></i></th>
                <th style={ this.th_style("rt") } onClick={() => this.tb_sort("rt") }><Lang>Roomtype</Lang> <i className={ this.th_icon("rt") }></i></th>
                <th style={ this.th_style("saldo") } onClick={() => this.tb_sort("saldo") }><Lang>Saldo</Lang> <i className={ this.th_icon("saldo") }></i></th>
                <th style={ this.th_style("topay") } onClick={() => this.tb_sort("topay") }><Lang>Topay</Lang> <i className={ this.th_icon("topay") }></i></th>
                <th style={ this.th_style("bettyp") } onClick={() => this.tb_sort("bettyp") }><Lang>Paytype</Lang> <i className={ this.th_icon("bettyp") }></i></th>
                <th></th>
            </tr>
            </thead>
        </table>
        <div style={ this.tb_style() }>
          <table className="table table-bordered table-hover tb tbs02">
            <tbody>
            { this.state.rva.map((row, key) =>
            <tr key={key} style={ this.row_style(row) } onClick={() => this.sel_row(row)} onDoubleClick={ () => this.sel_gst(row) }>
                <td className="bold text-center" style={ this.clean_style(row) }>{ utils.toRumsnr( row.gsb00.r0rumsnr ) }</td>
                <td>{ row.gsb00.r0boknr }</td>
                <td>{ utils.toDate( row.gsb00.r0ankdatum ) }</td>
                <td>{ utils.toDate( row.gsb00.r0avrdatum ) }</td>
                <td>{ utils.toLang( row.resm01.Kanal ) }</td>
                <td>{ utils.toLang( row.resv00.Company ) }</td>
                <td className="bold">{ utils.toLang( row.gsb00.r0namn ) }</td>
                <td className="text-center">{ utils.toNum( row.gsb00.r0guests ) }</td>
                <td>{ row.rblx0.r0typ }</td>
                <td className="text-right">{ utils.toPris( row.charges.saldo ) }</td>
                <td className="text-right">{ utils.toPris( row.charges.topay ) }</td>
                <td>{ row.bettyp }</td>
                <td>
                    <Td_Sel items={ this.selitems } getValue={(e)=> this.sel_row_fkn(row, e)} />
                </td>
            </tr>
            )}
            </tbody>
        </table>
        </div>
        </div>
    </div>
    : null }

    { this.state.wnds.inhsel ? <Pms_InhSel boknr={this.state.boknr} wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
    { this.state.wnds.nota ? <Pms_Nota close={this.gst_close} wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
        <Wnd_Confirm msg={this.state.msg} ctx={this} />
        { this.state.msg.sign ? <Wnd_Sign msg={this.state.msg} ctx={this} /> : null }
    </div>
  );
    }
}
export default Pms_Inh;
