import React, { useState, useEffect, useContext } from 'react';
import {format} from "date-fns";

import * as fkn from "../../../lib/fkn";
import * as net from "../../../lib/net";
import * as utils from "../../../assets/utils";
import {toTid} from "../../../assets/utils";

import Dt_Cal from "../../../lib/ui/dt_cal";

import Dd_Sel from "../../../lib/ui/dd_sel";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Rv_Trs from "./rv_trs";

class Krub_Rv extends React.Component {
  constructor(props, context) {
    super(props);

    this.dt_fd = React.createRef();
      this.dt_td = React.createRef();

    this.store = props.store;
    this.pms = props.store.pms;

    this.state = {};
    this.state.fkn = "";
      this.state.sortid = "";
      this.state.dta = [];
      this.state.tra = [];
      this.state.rva = [];
      this.state.tot = {};
      this.state.boknr = "";

      this.state.today = new Date();
    var dts = format(this.state.today, "yyyy-MM-dd");
    this.state.fdat = dts;
    this.state.tdat = dts;
    if(this.pms.base) {
      this.state.fdat = this.pms.base.fdat;
      this.state.tdat = this.pms.base.tdat;
  }
  if(props.wnds) {
    if(props.wnds.boknr) this.state.boknr = props.wnds.boknr;
    if(props.wnds.fdat) this.state.fdat = props.wnds.fdat;
    if(props.wnds.tdat) this.state.tdat = props.wnds.tdat;
  }
    var bdts = format(this.state.today, "yyyy-01-01");
      this.state.bdat = bdts;

      this.state.msg = {};
      this.state.wnds = {};
      this.selitems = [
        { fkn: 'upd', text: 'Uppdatera' }
      ];

      this.state.msg.wmsg = "Välj period";

  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //if(this.props.wnds.show) this.saldo_rv_ft();
    this.maindays();
  }

  dlg_style() {
    var css = {};
    //css.width = window.innerWidth * 0.8;
    //css.height = window.innerHeight * 0.8;
    return css;
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = (window.innerHeight * 0.9) - 210;
      css.overflow = "auto";
      return css;
  }
  th_style(id) {
      var css = {};
      css.cursor = "pointer";
      if(id == this.state.sortid) css.color = "#080";
      return css;
  }
    row_style(row) {

        var css = {};
        if(row.e0typ == "xx") {
            css.background = "#3c983c";
            css.color = "#fff";
        }
        if(this.state.fkn == "FSK") {
            if(row.e0typ.substr(0,3) !== "fsk") {
                css.display = "none";
            }
        }
        return css;
    }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

  dt_fdat(e) {
    //alert(JSON.stringify(e));
    var dts = format(e, "yyyy-MM-dd");
    this.setState({ fdat: dts });
  }
    dt_tdat(e) {
        //alert(JSON.stringify(e));
        var dts = format(e, "yyyy-MM-dd");
        this.setState({ tdat: dts });
    }
    dt_bdat(e) {
        //alert(JSON.stringify(e));
        var dts = format(e, "yyyy-MM-dd");
        this.setState({ bdat: dts });
    }
  oneday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_td.current.updateDt(this.state.fdat);
  }
  addday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_fd.current.updateAdd();
    this.dt_td.current.updateAdd();
  }
  subday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_fd.current.updateSub();
    this.dt_td.current.updateSub();
  }

  change_fkn(e) {
      var self = this;
    //let cdat = this.state.seldate;
    //alert(JSON.stringify(e));
    let fkn = e.target.value;
    this.setState({fkn: fkn}, function () {
        self.syncTb("trsTable");
    });
    //this.setState({tra: [], tsa: [], tta: [] });
  }

  saldo_rv_ft() {
    var self = this;


      var msg = { info: true, wmsg: "Hämtar period ..."};
      self.setState({ dta: [], msg: msg });

    var prm = {};
    prm.req = "pms.krub.saldo_rv_ft";
    prm.fdat = this.state.fdat;
    prm.tdat = this.state.tdat;
    prm.boknr = this.state.boknr;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        if(ret.ok != "000") {
            self.setState({ msg: {} });
            return;
        }
        self.setState({dta: ret.rca, tot: ret.tot, msg: {} }, function () {
            self.syncTb("rvTable");
        });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

  gstdays() {
    var self = this;
    self.maindays();
  }

  maindays() {
    var self = this;

    if(this.state.boknr == "B9999") {
      self.saldo_rv_ft();
      return;
    }
        var msg = { info: true, wmsg: "Hämtar bokning ..."};
        self.setState({ dta: [], msg: msg });
  
      var prm = {};
      prm.req = "pms.krub.saldo_rv_ft";
      prm.fdat = "2022-12-01";
      prm.tdat = "2023-12-31";
      prm.boknr = this.state.boknr;
  
      net.sio_req(prm)
      .then(function(ret){
          //alert(JSON.stringify(ret.rca));
  
          if(ret.ok != "000") {
              self.setState({ msg: {} });
              return;
          }
          self.setState({dta: ret.rca, tra: [], tot: ret.tot, msg: {} }, function () {
              self.syncTb("rvTable");
          });
      })
      .catch(function(e){
          var txt = JSON.stringify(e);
      });
      }

      gsttrs() {
        var self = this;
        self.maintrs();
      }
    
      maintrs() {
        var self = this;
    
            var msg = { info: true, text: "Hämtar notor ..."};
            self.setState({ dta: [], msg: msg });
      
          var prm = {};
          prm.req = "pms.krub.saldo_rv_ft";
          prm.fdat = "2023-01-01";
          prm.tdat = "2023-12-31";
          prm.boknr = this.state.boknr;
      
          net.sio_req(prm)
          .then(function(ret){
              //alert(JSON.stringify(ret.rca));
      
              if(ret.ok != "000") {
                  self.setState({ msg: {} });
                  return;
              }
              self.setState({dta: [], tra: ret.tra, tot: ret.tot, msg: {} }, function () {
                  self.syncTb("trTable");
              });
          })
          .catch(function(e){
              var txt = JSON.stringify(e);
          });
          }
      
  tr_edit(row) {
    var self = this;
    var wnds = {};
    wnds.tropen = true;
    wnds.tro = row;
    this.setState({ wnds: wnds });
  }
    syncTb(tb){
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
        tdElements = tbel.getElementsByTagName('td');

        for (let i = 0; i < thElements.length; i++) {
            if(!thElements[i]) continue;
            if(!tdElements[i]) continue;
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
            width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
   tb_sort(typ) {
      var self = this;
      //var rvs = self.state.rvs;
      var tra = self.state.tra;
      var sz = this.state.sz_val;

      tra.sort((a,b) => (a[typ] > b[typ]) ? 1 : ((b[typ] > a[typ]) ? -1 : 0));
      self.setState({ tra: tra, sortid: typ });
   }
  tb_filtr() {
      var self = this;
      var rvs = self.state.rvs;
      var sz = this.state.sz_val;

      if(sz.length < 1) {
        self.setState({ rva: rvs });
          return;
      }
      sz = sz.toUpperCase();
      var rva = rvs.filter(function(row) {
            var brc = false;
            if(row.company.toUpperCase().includes(sz)) brc = true;
            if(row.name.toUpperCase().includes(sz)) brc = true;
            return brc;
      });
      self.setState({rva: rva });
   }
    sel_fkn(sel) {
        //alert(JSON.stringify(fkn));
        if(sel.fkn == "maindays") {
            this.maindays();
        }
        if(sel.fkn == "gstdays") {
          this.gstdays();
      }
      if(sel.fkn == "maintrs") {
        this.maintrs();
      }
      if(sel.fkn == "gsttrs") {
        this.gsttrs();
      }
    }
    invs_rp() {
        var self = this;
        var wnds = {};
        wnds.invsrp = true;
        wnds.fdat = this.state.fdat;
        wnds.tdat = this.state.tdat;
        wnds.bdat = this.state.bdat;
        this.setState({ wnds: wnds });
    }
    wnd_close = () => {
      this.props.ctx.setState({ wnds: {} });
    };
    rv_trs() {
      var self = this;
      var wnds = {};
      wnds.rvtrs = true;
      wnds.fdat = this.state.fdat;
      wnds.tdat = this.state.tdat;
      wnds.boknr = this.state.boknr;
      //alert(JSON.stringify(wnds));
      this.setState({ wnds: wnds });
    }
    rv_trs_edit(row) {
      var self = this;
      var wnds = {};
      wnds.rvtrs = true;
      //wnds.fdat = row.datum;
      //wnds.tdat = row.datum;
      wnds.fdat = this.state.fdat;
      wnds.tdat = this.state.tdat;
      wnds.boknr = this.state.boknr;
      //alert(JSON.stringify(wnds));
      this.setState({ wnds: wnds });
    }
  
  render() {
    return (
      <div className="mx_base">
      { this.props.backdrop ? <div className="m2_backdrop" /> : null }
      <div className="m2_dlg mx_90h flex-col" style={ this.dlg_style() }>
      <div className="mx_h01 flex-row" style={{background: "#1a456b", color: "#fff"}}>
          <div>KRUBB BOKNING: { this.state.boknr }</div>
          <div className="flex"></div>
          <div>UPPDATERA</div>
      </div>

      <div className="mx_content flex px-3">


    <div className="flex-row flex-space-between">
        <div className="flex-row">
        <div className="flex-row">
              <div className="dt-btn-01" onClick={() => this.subday() } ><FontAwesomeIcon icon="minus" size="1x" /></div>
              <Dt_Cal dts={ this.state.fdat } rp="1" ref={this.dt_fd} getValue={(e)=> this.dt_fdat(e)} />
              <div className="dt-btn-01" onClick={() => this.oneday() } >1</div>
              <Dt_Cal dts={ this.state.tdat } rp="1" ref={this.dt_td} getValue={(e)=> this.dt_tdat(e)} />
              <div className="dt-btn-01" onClick={() => this.addday() } ><FontAwesomeIcon icon="plus" size="1x" /></div>
        </div>
        <button className="btn btn-hw-blue w150" onClick={() => this.saldo_rv_ft() } aria-label="System" >
            Endast Period
        </button>
        <button className="btn btn-hw-blue w150" onClick={() => this.maindays() } aria-label="System" >
            Hela bokningen
        </button>
        </div>

        <div className="flex"></div>

        <button className="btn btn-hw-blue w150" onClick={() => this.rv_trs() } aria-label="System" >
            Transaktioner
        </button>
        <Dd_Sel items={ this.selitems } cls="web-x-icon" getValue={(e)=> this.sel_fkn(e)} />
    </div>
    <div className="divider_green"></div>

    { this.state.dta.length ?
        <div id="rvTable">
        <table className="table table-bordered table-hover" style={{marginBottom: "0px"}}>
                <thead>
                <tr>
                <th style={ this.th_style("datum") } onClick={() => this.tb_sort("e0datum") }>Datum</th>
                <th style={ this.th_style("notanr") } onClick={() => this.tb_sort("e0typ") }>Dag</th>
                <th style={ this.th_style("notanr") } onClick={() => this.tb_sort("e0typ") }>Omsättning</th>
                    <th style={ this.th_style("notanr") } onClick={() => this.tb_sort("e0typ") }>Betalt</th>
                    <th style={ this.th_style("notanr") } onClick={() => this.tb_sort("e0typ") }>Saldo</th>
                    <th style={ this.th_style("notanr") } onClick={() => this.tb_sort("e0typ") }>HSaldo</th>
                    <th style={ this.th_style("e0ptyp") } onClick={() => this.tb_sort("e0ptyp") }>Status</th>
                </tr>
                </thead>
            </table>
            <div style={ this.tb_style() }>
          <table className="table table-bordered table-hover">
            <tbody>
                { this.state.dta.map((row, key) =>
                    <tr key={key} style={ this.row_style(row) } onDoubleClick={() => this.rv_trs_edit(row) }>
                        <td>{ utils.toDate( row.datum ) }</td>
                        <td>{ utils.toLang( row.wday ) }</td>
                        <td>{ utils.toPris( row.fsg ) }</td>
                        <td>{ utils.toPris( row.bet ) }</td>
                        <td>{ utils.toPris( row.saldo ) }</td>
                        <td>{ utils.toPris( row.hsaldo ) }</td>
                        <td>{ row.status }</td>
                    </tr>
                )}
                </tbody>
            </table>
            </div>
          </div>
        : <div className="flex flex-cc"><h3>{ this.state.msg.wmsg }</h3></div> }
                

        </div>
        <div className="divider_green"></div>
        <div className="flex-row px-5">
                <div className="txt_14">{utils.toPris(this.state.tot.fsg)}</div>
                <div className="flex"></div>
                <div className="txt_14">{utils.toPris(this.state.tot.bet)}</div>
                <div className="flex"></div>
                <div className="txt_14">{utils.toPris(this.state.tot.saldo)}</div>
        </div>
        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex-row">
            <button className="btn btn-hw-green w250 hide" onClick={(event) => this.wnd_close() } >
                SKRIV UT
            </button>
            </div>
      </div>

        </div>
        { this.state.wnds.rvtrs ? <Rv_Trs wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
        </div>
  );
}
}
export default Krub_Rv;
