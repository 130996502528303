import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";

import * as utils from "../../assets/utils";
import ddo from '../../ddo.js'

class Tmpl_Uds extends React.Component {
  constructor(props, context) {
    super(props);

    //this.pos = props.store.pos;
    this.state = {};
    this.state.lsa = [];
    this.state.lso = {};
    //this.state.lro = props.lro;
    this.state.tya = [
                {id: '01', text: 'Summerad'},
                {id: '02', text: 'Dag för dag'}
            ];
    this.state.aa = [];
      this.state.cols = {};

      this.state.selavd = ddo.cache.avd;
      this.state.dpo = {};

    this.state.today = new Date();

    this.state.pdf = 0;
    this.state.pdf_content = null;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    tab_style(tabid) {
      var css = {};
      css.display = "none";
      if(this.state.tabval === tabid) css.display = "block";
      return css;
    }
    tab_head(tabid) {
        var css = {};
        css.background = "#fff";
        css.color = "#000";
        if(this.state.tabval === tabid) {
            css.background = "#080";
            css.color = "#fff";
        }
        return css;
    }
    change_avd(e) {
        //let cdat = this.state.seldate;0
        let key = e.target.value;
        let dpo = ddo.cache.deps[key];
        this.setState({dpo: dpo});
    }
    avd_sel(row) {
        var self = this;
        this.setState({ dpo: row }, function() {
            ddo.cache.avd = row.avd;
            ddo.cache.avdtxt = row.text;
            //alert(JSON.stringify(ddo.cache.avd));
            self.props.cb();
        });
    }

    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    handleClose = () => {
		this.props.ctx.setState({ rptyp: "" });
	};

    tb_style() {
        var css = {};
        css.height = window.innerHeight - 275;
        return css;
    }
    sel_unit() {
        var dpo = this.state.dpo;
        if(!dpo) {
            this.props.cb();
            return;
        }
        ddo.cache.avd = dpo.avd;
        ddo.cache.avdtxt = dpo.text;
        //alert(JSON.stringify(dpo.avd));
        this.props.cb();
    }
    wnd_close() {
        //this.props.ctx.setState({ wnds: {} });
        this.props.cb();
    };

  render() {
    if(!this.props.wnds || !this.props.wnds.uds) return "";
    var title = this.props.title;
    if(!title) title = "";

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="mx_backdrop" /> : null }
        <div className="mx_dlg mx_80h flex-col" style={ this.dlg_style() }>
        <div className="mx_head mx_bg flex-row flex-space-between">
            <div className="flex-col flex">
                <div>ENHETS VAL</div>
            </div>
        </div>
        <div className="mx_content flex">
            <div className="flex-row">
                    <div className="flex-col flex">
                        <div>Start</div>
                    </div>
                    <div className="flex-col flex">
                        <div></div>
                    </div>
                    <div className="flex-col w50">
                        <div>Ändra</div>
                    </div>
            </div>
            <div className="divider_orange"></div>

        <div className="mx_body flex">

        <div className="mnu-grid-3">
            { ddo.cache.deps.map((row, key) =>
                <div key={key} className="web-mnu-btn text-center" onClick={() => this.avd_sel(row) }>
                    <div className="txt_16">
                        { utils.toLang( row.text ) }
                    </div>
                    <div className="txt_14">
                        { utils.toLang( row.avd ) }
                    </div>
                </div>
            )}
        </div>
        <div className="flex-row hide">
              <div className="flex-col">
                <span>Välj Enhet</span>
                <select className="form-control w250" value={this.state.avd} placeholder="Avdelning" onChange={(e) => this.change_avd(e)}>
                    { ddo.cache.deps.map((row, key) =>
                    <option key={key} value={key}>{row.avd} - { utils.toLang(row.text) }</option>
                    )}
                </select>
            </div>
            </div>

        </div>

        </div>

        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w150" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex-row"></div>
            <button className="btn btn-hw-green w150" onClick={(event) => this.sel_unit(event) } >
                VÄLJ ENHET
            </button>
        </div>
      </div>
    </div>
	;
	return html;
  };
}

export default Tmpl_Uds;