import React, { useState, useEffect, useContext } from 'react';
import * as net from "../../../lib/net";
import PdfRpDlg from "../fkn/pdf_rp_dlg";
import Dt_Cal from "../../../lib/ui/dt_cal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Crt_Dlg extends React.Component {
  constructor(props, context) {
    super(props);

    //alert('LR CTX: ' + JSON.stringify(props.lro));
   //this.webStore = props.store;
    this.state = {};
    this.state.lsa = [];
    this.state.lso = {};
    //this.state.lro = props.lro;
    this.state.tya = [
                {id: '01', text: 'Summerad'},
                {id: '02', text: 'Dag för dag'}
            ];
    this.state.pra = [
                {id: '01', text: 'Lokal Skrivare'},
                {id: '02', text: 'Global Skrivare'}
            ];
    this.state.aa = [
                {id: '01', text: 'AVD 01'},
                {id: '02', text: 'AVD 02'}
            ];
    this.state.cols = {};
    this.state.lrwrk = false;
    //context.posrend = this;
    this.state.fromdate = new Date("2017-06-01");
    this.state.todate = new Date("2017-06-01");
    this.state.fdat = "2017-06-01";
    this.state.tdat = "2017-06-01";
    this.state.pdf = 0;
    this.state.pdf_content = null;
  }
  componentDidUpdate() {
    let value = this.context;
    /* ... */
  }
  componentWillUnmount() {
    let value = this.context;
    /* ... */
  }
  componentDidMount() {
    //this.props.fetchWells()
    //  .then(res => this.setState({ wells: res.wells }) )
    //this.lr_lsa();
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    tab_style(tabid) {
      var css = {};
      css.display = "none";
      if(this.state.tabval === tabid) css.display = "block";
      return css;
    }
    tab_head(tabid) {
      var css = {};
      css.background = "#fff";
      css.color = "#000";
      if(this.state.tabval === tabid) {
          css.background = "#080";
          css.color = "#fff";
      }
      return css;
    }
  change_fdat(e) {
    //let cdat = this.state.seldate;
    let cdat = e.value;
    let formatted_date = cdat.getFullYear() + "-" + (cdat.getMonth() + 1).toString().tonum(2) + "-" + cdat.getDate().toString().tonum(2);
    this.setState({fromdate: e.value, fdat: formatted_date});
  }
  change_tdat(e) {
    //let cdat = this.state.seldate;
    let cdat = e.value;
    let formatted_date = cdat.getFullYear() + "-" + (cdat.getMonth() + 1).toString().tonum(2) + "-" + cdat.getDate().toString().tonum(2);
    this.setState({todate: e.value, tdat: formatted_date});
  }

    rp_prn() {
		//this.props.ctx.setState({ mr: false });
        //this.setState({ pdf: 1 });
        this.zr_prn();
    }

    zr_prn() {
        var self = this;
        var prm = {};
     	prm.req = "vcm.rp.zr_ft";
   		prm.fdat = this.state.fdat;
   		prm.tdat = this.state.tdat;
   		//prm.fdat = "20200605";
   		//prm.tdat = "20200605";
   		prm.favd = "01";
   		prm.favd = "01";
        //alert(JSON.stringify(prm));

        //gda.wndLoading("Hämtar");
        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret.ok));

            //var uri = "data:application/pdf;base64," + ret.pdf;
            //self.setState({pdf: 1, pdf_content: ret.pdf });
            //self.syncCols("lsTable");
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
            alert(JSON.stringify(e));
        });
    }

    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    handleClose = () => {
		this.props.ctx.setState({ rptyp: "" });
	};

  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  cssCols (cid) {
      var css = {};
      if(this.state.cols[cid]) css.width = this.state.cols[cid].toString() + "px";
      //if(cid == "c01") alert(JSON.stringify(this.state.cols[cid]));
      return css;
  }
  syncCols (tb) {

    if (!document.getElementById(tb)) return;

    var cols = {};
    var ix = document.getElementById(tb).rows.length - 1;
    var ic = document.getElementById(tb).rows[0].cells.length;
    var ci = 0;
    while(ci < ic) {
        var cxi = ci + 1;
        var col = "c" + cxi.toString().padStart(2, "0");
        cols[col] = document.getElementById(tb).rows[ix].cells[ci].clientWidth + 1;
        ci = ci + 1;
    }
    this.setState({cols: cols });
    //alert(JSON.stringify(this.state.cols));
    //this.forceUpdate();
  };

  render() {
    if(!this.props.show) return "";

    //alert(this.props.ctx.state.lro);

    var title = this.props.title;
    if(!title) title = "Z-RAPPORT";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="mx_backdrop" /> : null }
        <div className="mx_dlg mx_70 flex-col" style={ this.dlg_style() }>
        <div className="mx_head mx_bg flex-row flex-space-between">
            <div className="flex-col flex">
                <div>Total Kassarapport</div>
            </div>
        </div>
        <div className="mx_content flex">
            <div className="flex-row">
                    <div className="flex-col flex">
                        <div>Start</div>
                    </div>
                    <div className="flex-col flex">
                        <div></div>
                    </div>
                    <div className="flex-col w50">
                        <div>Ändra</div>
                    </div>
            </div>
            <div className="divider_orange"></div>

        <div className="mx_body flex">
            <div className="flex-row">
                <div className="flex-row">
                    <div className="dt-btn-01" onClick={() => this.subday() } ><FontAwesomeIcon icon="minus" size="1x" /></div>
                    <Dt_Cal dts={this.state.dts} rp="1" ref={this.dt_dt} getValue={(e)=> this.dt_dat(e)} />
                    <div className="dt-btn-01" onClick={() => this.addday() } ><FontAwesomeIcon icon="plus" size="1x" /></div>
                </div>
                <div className="flex-row">
                    <div className="dt-btn-01" onClick={() => this.subday() } ><FontAwesomeIcon icon="minus" size="1x" /></div>
                    <Dt_Cal dts={this.state.dts} rp="1" ref={this.dt_dt} getValue={(e)=> this.dt_dat(e)} />
                    <div className="dt-btn-01" onClick={() => this.addday() } ><FontAwesomeIcon icon="plus" size="1x" /></div>
                </div>
            </div>

            <div className="flex-row">
              <div className="flex-col">
                  <span>Från</span>
        <select className="form-control w250" placeholder="Från avdelning">
            { this.state.aa.map((row, key) =>
            <option value={row.id}>{row.text}</option>
            )}
        </select>
            </div>
              <div className="flex-col">
                  <span>Till</span>
        <select className="form-control w250" placeholder="Till avdelning">
            { this.state.aa.map((row, key) =>
            <option value={row.id}>{row.text}</option>
            )}
        </select>
            </div>
            </div>

            <div className="flex-row">
              <div className="flex-col">
                  <span>Typ</span>
        <select className="form-control w250" placeholder="Typ">
            { this.state.tya.map((row, key) =>
            <option value={row.id}>{row.text}</option>
            )}
        </select>
            </div>
            </div>
            <div className="flex-row">
              <div className="flex-col">
                  <span>Skrivare</span>
        <select className="form-control w250" placeholder="Typ">
            { this.state.pra.map((row, key) =>
            <option value={row.id}>{row.text}</option>
            )}
        </select>
            </div>
            </div>

        </div>

        </div>
        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w150 ripple" onClick={() => this.handleClose() } >
                STÄNG
            </button>
            <div className="flex-row">
            </div>
            <button className="btn btn-hw-green w150 ripple" onClick={(event) => this.rp_prn(event) } >
                SKRIV UT
            </button>
        </div>
      </div>
        <PdfRpDlg show={this.state.pdf} ctx={this} backdrop={true}/>
    </div>
	;
	return html;
  };
}

export default Crt_Dlg;