import React, { useState, useEffect, useContext } from 'react';
import socketIOClient from 'socket.io-client'

import SDOContext from '../sdo.js'
import * as fkn from "../lib/fkn";
import Dt_Cal from "../lib/ui/dt_cal";
//import * as mx from "../lib/mx";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Sys_Gms extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, tra: [] };
    this.state.uda = [];

    this.sio = null;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
      this.gms_down();
  }
  componentDidMount() {
      this.gms_up();
  }

  gms_up() {
    var self = this;
    var url = 'http://127.0.0.1:9090/';
    this.sio = socketIOClient(url);

	this.sio.on('connect', function () {
	    ///alert('GMS Connected!');
	    //toastr.success("IO CONNECTED");
        self.sio.emit("ctrl:link", {}, function () {
        });
	});
	this.sio.on('connect_error', function (e) {
	    //alert('Connect Error : ' + JSON.stringify(e));
	    //toastr.error("IO CONNECT ERROR", e);
	});
	this.sio.on('error', function (e) {
	    //alert('Socket Error : ' + e);
	    //toastr.error("IO ERROR", e);
	});

	this.sio.on('log', function (data) {
		alert(JSON.stringify(data));
	});
	this.sio.on('tst', function (data) {
		alert(JSON.stringify(data));
	});
	this.sio.on('udid:rsp', function (data) {
		//alert(JSON.stringify(data));
		var ret = data;
        self.setState({uda: ret.rca });
	});
  }
  gms_down() {
      this.sio.disconnect();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 200;
      return css;
  }
  tst_req() {
    var self = this;
    self.sio.emit("tst:req", {}, function () {
    });
  }
  udid_list() {
    var self = this;
    self.sio.emit("udid:list", {}, function () {
    });
  }
  udid_req(row) {
    var self = this;
    var req = {};
    req.udid = row.udid;
    req.fkn = "settle";
    self.sio.emit("udid:req", req, function () {
    });
  }
  udid_debit(row) {
    var self = this;
    var req = {};
    req.udid = row.udid;
    req.fkn = "debit";
    self.sio.emit("udid:req", req, function () {
    });
  }

  render() {
    return (
    <div className="web-app">
    <h3>GMS</h3>
    <div className="flex-row">
        <div className="flex">
        <button className="btn btn-hw-blue w150" onClick={() => this.udid_list() } aria-label="System" >
            Funktioner
        </button>
        </div>


        <div className="flex flex-end">
        <button className="btn btn-hw-blue w250" onClick={() => this.tst_req() } aria-label="System" >
            UPPDATERA
        </button>
        </div>
    </div>
    <div className="divider_orange"></div>
    <div className="web-row">
        <table className="table table-bordered table-hover tb fixed_header">
            <thead>
            <tr>
                <th>ID</th>
                <th>Text</th>
                <th>Kopplad</th>
                <th>Status</th>
            </tr>
            </thead>
            <tbody style={ this.tb_style() }>
            { this.state.uda.map((row, key) =>
            <tr key={key}>
                <td onClick={() => this.udid_req(row) }>{row.udid}</td>
                <td onClick={() => this.udid_debit(row) }>{row.info}</td>
                <td>{row.tid}</td>
                <td>{row.status}</td>
            </tr>
            )}
            </tbody>
        </table>
    </div>

    </div>
  );
    }
}
export default Sys_Gms;
