import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Tele_Bo extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, tra: [] };
    this.state.seldate = new Date();
    this.state.avd = "01";
    this.state.paa = [];
    this.state.aa = [
                {id: '01', text: 'AVD 01'},
                {id: '02', text: 'AVD 02'}
            ];
    //context.posrend = this;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.plus();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 480;
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

  plu_open(row) {
      var self = this;
  }
  plu_edit(row) {
      var self = this;
      alert(JSON.stringify(row.plu00));
  }

  plus() {
    var self = this;
    var prm = {};
    prm.req = "pos.pos_plu.plus";
    prm.avd = this.state.avd;
    //prm.fplu = datum;
    //prm.tplu = datum;

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        var o = {};
        //o.deps = ret.rco;
        //alert(JSON.stringify(o));
        self.setState({paa: ret.rco });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }

  render() {
    return (
    <div className="web-app">
    <h3>Backoffice</h3>

    <div className="web-p01 flex">

    <div className="web-row">
    <div className="web-col flex">
        <div className="web-btn" onClick={() => this.fknGo("/pos/eko/rps") } >
            <div>RAPPORTER</div>
            <div>Rapporter och försälningsunderlag</div>
        </div>
        <div className="web-btn" onClick={() => this.fknGo("/pos/eko/list") } >
            <div>LISTOR</div>
            <div>Sortiment och register</div>
        </div>
    </div>
     <div className="web-col flex" style={{padding: "0px 10px"}}>
        <div className="web-btn" onClick={() => this.fknGo("/pos/eko/trs") } >
            <div>TRANSAKTIONER</div>
            <div>periodens inslagt</div>
        </div>
        <div className="web-btn" onClick={() => this.fknGo("/pos/eko/konto") } >
            <div>KONTON</div>
            <div>Kontobaserad samman ställning</div>
        </div>
   </div>
     <div className="web-col flex">
        <div className="web-btn" onClick={() => this.fknGo("/pos/eko/kvitton") } >
            <div>KVITTON</div>
            <div>Kvitto underlag</div>
        </div>
        <div className="web-btn" onClick={() => this.fknGo("/pos/eko/kassa") } >
            <div>KASSA</div>
            <div>Kassarapporter och myndighetsrapporter</div>
        </div>
   </div>
   </div>

    </div>

    </div>
  );
    }
}
export default Tele_Bo;
