import React, { useState, useEffect, useContext } from 'react';
import Fr_Input from "../../lib/ui/fr_input";
import {withRouter} from "../../lib/react/withRouter";

class Reg_Auth extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.pos = props.store.pos;
    this.state = { pgid: 11, tra: [] };
    this.state.seldate = new Date();
    this.state.avd = "01";
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }
  box_style() {
      var css = {};
      css.width = window.innerWidth / 3;
      css.height = window.innerHeight / 3;
      css.border = "1px solid #ccc";
      return css;
  }

  fknGo(url) {
    //alert(row.href);
    this.props.navigate(url);
  }
    sign_save (value) {
      var signatur = this.state.signatur;
      //tbo[name] = value;
      this.setState({signatur: value});
    }
    sign() {
      var signatur = this.state.signatur;
      if(signatur == "00") {
        this.props.store.pms.regauth = true;
        var url = "/pms/hotreg/home";
        this.props.navigate(url);
      }
  }
    exit() {
        this.props.store.pms.regauth = false;
        var url = "/pms/";
        this.props.navigate(url);
  }

  render() {
    return (
    <div className="web-app">
    <h3>Register System</h3>

    <div className="web-col flex">
        <div className="flex"></div>
        <div className="web-row">
            <div className="flex"></div>

            <form className="cdi_f03">
            <div className="web-col text-center" style={ this.box_style() }>
            <div className="flex"></div>
                <div className="txt_22">REGISTER FUNKTIONER</div>
                <div className="txt_22">ANGE SIGNATUR</div>

        <div className="web-row">
            <div className="flex"></div>
                <Fr_Input type="password" noheader className="text-center w250" text={this.state.signatur} getValue={(e)=> this.sign_save(e) } />
            <div className="flex"></div>
        </div>
            <div className="flex"></div>

        <div className="web-row">
            <div className="flex"></div>
            <button className="btn btn-hw-red w200" onClick={() => this.exit() } >
                AVBRYT
            </button>
            <button className="btn btn-hw-green w200" onClick={() => this.sign() } >
                SIGNERA
            </button>
            <div className="flex"></div>
        </div>
            </div>
            </form>

            <div className="flex"></div>
        </div>
        <div className="flex"></div>
   </div>

    </div>
  );
    }
}
export default withRouter(Reg_Auth);
