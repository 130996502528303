import React, { useState, useEffect, useContext } from 'react';

import * as utils from "../../../../assets/utils";
import * as net from "../../../../lib/net";
import Pms_Rvo from "../../../rv/rvo";
import Dd_Sel from "../../../../lib/ui/dd_sel";
import Td_Sel from "../../../../lib/ui/td_sel";

class Collect_Rv extends React.Component {
  constructor(props, context) {
    super(props);

     this.selitems = [
        {fkn: 'setup', text: 'INSTÄLLNINGAR'},
        {fkn: 'upd', text: 'UPPDATERA'},
        {fkn: 'sys', text: 'SYSTEM'},
        {fkn: 'logout', text: 'LOGGA UT'}
    ];

   //this.webStore = props.store;
    this.state = {};
    this.state.ynq = [];
    this.state.aa = [];
    this.state.nta = [];
    this.state.srow = {};

    if(props.wnds) {
        this.fkn_close = props.wnds.fkn_close;
        this.state.boknr = props.wnds.boknr;
        this.state.rvo = props.wnds.rvo;
    }
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.charges_rv();
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }

    wnd_close() {
      var rsp = {};
      rsp.ok = "999";

      if(this.fkn_close) this.fkn_close(this.props.ctx, rsp);
      else this.props.ctx.setState({ wnds: {} });
	};
    collect() {
        var cra = Object.values(this.state.srow);
      var rsp = {};
      rsp.ok = "000";
      rsp.cra = cra;
        if(cra.length < 1) {
            alert("Inget att samla in!");
            return;
        }

      if(this.fkn_close) this.fkn_close(this.props.ctx, rsp);
      else this.props.ctx.setState({ wnds: {} });
	};
  tb_style() {
      var css = {};
      css.height = (window.innerHeight * 0.9) - 275;
      css.overflow = "auto";
      return css;
  }
  row_style(row) {
    var srow = this.state.srow;
    var css = {};
    if(srow[row.etr01.e0lopnr]) {
        css.background = "#080";
        css.color = "#fff";
    }
    return css;
  }
charges_rv() {
    var self = this;
    var prm = {};
    prm.req = "pms.charge.charges_rv";
    prm.boknr = this.state.boknr;
    //alert(JSON.stringify(prm));

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        var tot = ret.tot;
        var nta = ret.rca;
        var pra = [];
        for(var row of nta) {
            pra.push( row.etr01);
        }
        self.setState({ nta: nta, pra: pra, tot: tot });
        self.syncTb("rvCollectTable");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  sel_row(row) {
    var srow = this.state.srow;

    if(srow[row.etr01.e0lopnr]) {
      delete( srow[row.etr01.e0lopnr] );
    }
    else srow[row.etr01.e0lopnr] = row;
    this.setState({srow: srow});
  }
    syncTb(tb){
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
        tdElements = tbel.getElementsByTagName('td');

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
            width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }

  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "";

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="m2_backdrop" /> : null }
        <div className="m2_dlg mx_80h flex-col" style={ this.dlg_style() }>
        <div className="flex-row " style={{padding:"0px",height:"50px",lineHeight:"50px", background: "#1a456b", color: "#fff"}}>
                <div className="flex-row flex" style={{padding:"0px 5px", fontSize: "22px"}}>
                    <div>SAMLAIN BOKNING { this.state.rumsnr }</div>
                </div>
            <div className="">
                <Dd_Sel items={ this.selitems } cls="web-top-icon" getValue={(e)=> this.sel_fkn(e)} />
            </div>
        </div>
        <div className="mx_content flex">

        <div className="web-row flex">
        <div className="web-col flex" style={{padding: "0px"}}>

        <div id="rvCollectTable" className="flex">
          <table className="table table-bordered table-hover tb" style={{marginBottom: "0px"}}>
            <thead>
            <tr>
                <th>Datum</th>
                <th>T</th>
                <th>Typ</th>
                <th>Text</th>
                <th>Antal</th>
                <th>Pris</th>
                <th>Summa</th>
                <th>Tecken</th>
                <th>Löpnr</th>
                <th></th>
            </tr>
            </thead>
        </table>
        <div style={ this.tb_style() }>
          <table className="table table-bordered table-hover tb">
            <tbody>
            { this.state.nta.map((row, key) =>
            <tr key={key} style={ this.row_style(row) } onClick={ () => this.sel_row(row) }>
                <td>{ utils.toDate( row.etr01.e0datum ) }</td>
                <td>{ row.etr01.e0bastyp }</td>
                <td>{ row.etr01.e0typ }</td>
                <td>{ row.etr01.e0text }</td>
                <td className="text-center">{ utils.toNum( row.etr01.e0antal ) }</td>
                <td className="text-right">{ utils.toPris( row.etr01.e0pris ) }</td>
                <td className="text-right">{ utils.toPris((row.etr01.e0antal * 1) * (row.etr01.e0pris * 1)) }</td>
                <td>{ row.etr01.e0tkn }</td>
                <td>{ utils.toNum( row.etr01.e0lopnr ) }</td>
                <td>
                    <Td_Sel items={ this.rowitems } getValue={(e)=> this.sel_row_fkn(row, e)} />
                </td>
            </tr>
            )}
            </tbody>
        </table>
        </div>
        </div>

        </div>
        </div>

        </div>
        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250 ripple" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex-row">
            </div>
            <button className="btn btn-hw-green w250 ripple" onClick={(event) => this.collect() } >
                SAMLA IN VALT
            </button>
        </div>
      </div>
            { this.state.wnd_rvo ?
        <Pms_Rvo close={this.rvo_close} boknr={this.state.boknr} ctx={this} store={this.props.store} backdrop={true}/>
            : null }
    </div>
	;
	return html;
  };
}

export default Collect_Rv;