import React, { useState, useEffect, useContext } from 'react';
import {observer} from "mobx-react";

import * as net from "../../lib/net";
import ddo from "../../ddo";

import Btn_View from "./btn/btn_view";
import Btn_Term from "./btn/btn_term";
import Btn_Grps from "./btn/btn_grps";
import Btn_Btns from "./btn/btn_btns";
import Btn_Fkns from "./btn/btn_fkns";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Pos_Btns extends React.Component {
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pos = props.store.pos;

    this.state = {};
    this.state.seldate = new Date();
    this.state.avd = ddo.cache.avd;
    this.state.paa = [];
    this.state.wnds = {};
    this.state.aa = [];

    if(this.pos.deps) this.state.aa = this.pos.deps;

      this.state.mna = [];
      this.state.mna.push({ mnid: "mn02", pos: "c1", text: "KNAPP VY", desc: "Grupper och knappvyer", link: "/tmpl/pos/plus", fkn: "btn_view" });
      //this.state.mna.push({ mnid: "mn03", pos: "c1", text: "TERMINAL KNAPPAR", desc: "Knappar enligt terminalen", link: "/tmpl/pos/menus", fkn: "btn_term" });
      this.state.mna.push({ mnid: "mn05", pos: "c2", text: "KNAPPGRUPPER", desc: "Knappgruppslista", link: "/tmpl/pos/btns", fkn: "btn_grps" });
      this.state.mna.push({ mnid: "mn06", pos: "c2", text: "KNAPPAR", desc: "Knapplista", link: "/tmpl/pos/addons", fkn: "btn_btns" });
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.btn_grps();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 480;
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }
    change_avd(e) {
        //let cdat = this.state.seldate;
        this.pos.plus = [];
        let avd = e.target.value;
        this.setState({avd: avd});
    }

  btn_view() {
    var self = this;
      var wnds = {};
      wnds.btn_view = true;
      wnds.avd = this.state.avd;
    this.setState({ wnds: wnds });
  }
  btn_term() {
      var self = this;
    var wnds = { btn_term: true };
    this.setState({ wnds: wnds });
  }
  btn_grps() {
      var self = this;
    var wnds = { btn_grps: true };
    this.setState({ wnds: wnds });
  }
  btn_btns() {
      var self = this;
    var wnds = {};
    wnds.btn_btns = true;
    wnds.avd = this.state.avd;
    this.setState({ wnds: wnds });
  }
  btn_fkns() {
    var self = this;
    var wnds = { btn_fkns: true };
    this.setState({ wnds: wnds });
  }

  xxx() {
    var self = this;
    var prm = {};
    prm.req = "pos.pos_btn.btnGrps";
    prm.avd = this.state.avd;
    //prm.fplu = datum;
    //prm.tplu = datum;

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        alert(JSON.stringify(ret));

        //var o = {};
        //o.deps = ret.rco;
        //alert(JSON.stringify(o));
        self.setState({gaa: ret.rco });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }
    mnc(pos) {
        var mna = [];
        for(var row of this.state.mna) {
            if(row.pos == pos) mna.push( row );
        }
        return mna;
    }
    fknGo(url) {
        //alert(row.href);
        this.props.navigate(url);
    }
    fknStart(fkn) {
        //alert(row.href);
        if(fkn == "btn_view") this.btn_view();
        if(fkn == "btn_term") this.btn_term();
        if(fkn == "btn_grps") this.btn_grps();
        if(fkn == "btn_btns") this.btn_btns();
    }

  render() {
    return (
    <div className="web-app">
        <div className="web-pg py-4 flex">
            <h3>Knappar</h3>
            <p>Knapphanterings funktioner</p>

    <div className="divider_orange"></div>

        <div className="web-row">
            <div className="flex-col flex">
                { this.mnc("c1").map((row, key) =>
                    <div key={key} className="web-bx" onClick={() => this.fknStart(row.fkn) }>
                        <div>{ row.text }</div>
                        <div className="flex-row">
                            <div className="flex">{ row.desc }</div>
                            <div className="web-link">Välj</div>
                        </div>
                    </div>
                )}
            </div>
            <div className="flex-col flex">
                { this.mnc("c2").map((row, key) =>
                    <div key={key} className="web-bx" onClick={() => this.fknStart(row.fkn) }>
                        <div>{ row.text }</div>
                        <div className="flex-row">
                            <div className="flex">{ row.desc }</div>
                            <div className="web-link">Välj</div>
                        </div>
                    </div>
                )}
            </div>
        </div>
        </div>

    { this.state.wnds.btn_view ? <Btn_View wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
    { this.state.wnds.btn_term ? <Btn_Term wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
    { this.state.wnds.btn_grps ? <Btn_Grps wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
    { this.state.wnds.btn_btns ? <Btn_Btns wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
    { this.state.wnds.btn_fkns ? <Btn_Fkns wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
    </div>

    )}
}
export default observer(Pos_Btns);

/*
    <div className="web-row">
        <div className="web-btn flex" onClick={() => this.btn_view() } >
            <div>KNAPP VY</div>
            <div>GRUPPER OCH KNAPPAR</div>
        </div>
        <div className="web-btn flex" >
            <div></div>
            <div></div>
        </div>
        <div className="web-btn flex hide" onClick={() => this.btn_term() } >
            <div>TERMINAL KNAPPAR</div>
            <div>KNAPPAR PÅ TERMINALEN</div>
        </div>
    </div>
    <div className="divider_orange"></div>
    <div className="web-row">
        <div className="web-btn flex" onClick={() => this.btn_grps() } >
            <div>KNAPPGRUPPER</div>
            <div>LISTA</div>
        </div>
        <div className="web-btn flex" onClick={() => this.btn_btns() } >
            <div>KNAPPAR</div>
            <div>LISTA</div>
        </div>
    </div>

 */