import React, { useState, useEffect, useContext } from 'react';
import {addDays, format} from "date-fns";

import * as utils from "../../assets/utils";
import * as net from "../../lib/net";
import Td_Sel from "../../lib/ui/td_sel";
import Dep_Per from "./fkn/dep_per";
import Dt_Cal from "../../lib/ui/dt_cal";
import {toMinTid, toTid} from "../../assets/utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class TH_Avd extends React.Component {
  constructor(props, context) {
    super(props);

    this.dt_fd = React.createRef();
    this.dt_td = React.createRef();

    this.store = props.store;
      this.ta = props.store.ta;

    this.state = {};
    this.state.seldate = new Date();
    this.state.avd = "";
    this.state.tha = [];
    this.state.kro = {};
    this.state.aa = [];
      this.state.tot = {};
      this.state.wnds = {};
    this.state.sortid = "";
    this.state.sortdir = 1;

    this.state.today = new Date();
    var dts = format(this.state.today, "yyyy-MM-dd");
    //var tdat = addDays(this.state.today, 15);
    //var tdat = subDays(this.state.today, 15);
    let cdat = new Date();
      //let formatted_date = cdat.getFullYear() + "-" + (cdat.getMonth() + 1) + "-" + cdat.getDate();
      let formatted_date = cdat.getFullYear() + "-" + (cdat.getMonth() + 1) + "-" + "01";
    this.state.fdat = formatted_date;
    this.state.tdat = dts;

      if(this.ta && this.ta.base) {
          this.state.fdat = this.ta.base.fdat;
          this.state.tdat = this.ta.base.tdat;
      }

    this.selitems = [
      {fkn: 'open', text: 'ÖPPNA'},
      {fkn: 'prn', text: 'SKRIV UT'}
    ];
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.per();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      css.overflow = "auto";
      return css;
  }
    th_style(id) {
        var css = {};
        css.cursor = "pointer";
        if(id == this.state.sortid) {
            if(this.state.sortdir) css.color = "#800";
            else css.color = "#080";
        }
        return css;
    }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }
    dt_fdat(e) {
        //alert(JSON.stringify(e));
        var dts = format(e, "yyyy-MM-dd");
        this.setState({ fdat: dts });
    }
    dt_tdat(e) {
        //alert(JSON.stringify(e));
        var dts = format(e, "yyyy-MM-dd");
        this.setState({ tdat: dts });
    }
    oneday(e) {
        //alert(JSON.stringify(e));
        //this.setState({ tdat: this.state.fdat });
        this.dt_td.current.updateDt(this.state.fdat);
    }
    addday(e) {
        //alert(JSON.stringify(e));
        //this.setState({ tdat: this.state.fdat });
        this.dt_fd.current.updateAdd();
        this.dt_td.current.updateAdd();
    }
    subday(e) {
        //alert(JSON.stringify(e));
        //this.setState({ tdat: this.state.fdat });
        this.dt_fd.current.updateSub();
        this.dt_td.current.updateSub();
    }
    change_avd (e) {
        const value = e.target.value;
        this.setState({avd: value});
    }

  dep_edit(row) {
    var self = this;
    var wnds = {};
    wnds.dpper = 1;
      wnds.fdat = this.state.fdat;
      wnds.tdat = this.state.tdat;
      wnds.dpo = row;
    this.setState({wnds: wnds});
  }
  fkn_close() {
    this.setState({edit: false });
    //this.props.history.push("cas");
    //this.login_srv(uid, pwd);
  }

  per() {
    var self = this;
    var prm = {};
    prm.req = "ta.ta_th.avd_wrk_ft";
    prm.fdat = this.state.fdat;
    prm.tdat = this.state.tdat;
    //prm.avd = this.state.avd;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        self.setState({ tha: ret.rca, tot: ret.tot }, function() {
            self.syncTb("dpaTable");
        });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
    syncTb(tb) {
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
            tdElements = tbel.getElementsByTagName('td');
        if(tdElements.length < 1 ) return;

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
                width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
    tb_sort(typ) {
        var self = this;
        //var rvs = self.state.rvs;
        var tha = self.state.tha;
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;

        if(sortid != typ) sortdir = 0;
        else {
            if(sortdir) sortdir = 0;
            else sortdir = 1;
        }

        if(typ) {
            if(sortdir) tha.sort((a,b) => (a[typ] < b[typ]) ? 1 : ((b[typ] < a[typ]) ? -1 : 0));
            else tha.sort((a,b) => (a[typ] > b[typ]) ? 1 : ((b[typ] > a[typ]) ? -1 : 0));
        }
        self.setState({ tha: tha, sortid: typ, sortdir: sortdir });
    }
    show_text(row) {
      var txt = "";
      var avd = row.avd;
      var dpa = this.props.store.ta.dpa;
      //var keys = Object.keys(dpa);
      for(var o of dpa) {
        if(o.avdelning == avd) txt = o.namn;
      }
      //if(dpo[avd] != undefined) {
      //    txt = dpo.namn;
      //}
        txt = txt.toString().toswe();
      return txt;
    }
    th_icon(typ) {
        var cls = "";
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;
        if(sortid == typ)
        {
            if(sortdir) cls = "fa fa-caret-up";
            else cls = "fa fa-caret-down";
        }
        return cls;
    }

  render() {
    return (
    <div className="web-app">

    <div className="flex-row flex-space-between">
        <div className="label_h03">AVDELNINGS TIDER</div>
        <div className="flex"></div>
        <div className="flex-row">
            <div className="dt-btn-01" onClick={() => this.subday() } ><FontAwesomeIcon icon="minus" size="1x" /></div>
            <Dt_Cal dts={ this.state.fdat } rp="1" ref={this.dt_fd} getValue={(e)=> this.dt_fdat(e)} />
            <div className="dt-btn-01" onClick={() => this.oneday() } >1</div>
            <Dt_Cal dts={ this.state.tdat } rp="1" ref={this.dt_td} getValue={(e)=> this.dt_tdat(e)} />
            <div className="dt-btn-01" onClick={() => this.addday() } ><FontAwesomeIcon icon="plus" size="1x" /></div>
        </div>
        <div className="flex"></div>
        <button className="btn btn-hw-blue w150" onClick={() => this.per() } aria-label="System" >
            UPPDATERA
        </button>
    </div>
    <div className="divider"></div>

    <div id="dpaTable" className="web-col">
        <table className="table table-bordered table-hover tb " style={{marginBottom: "0px"}}>
            <thead>
            <tr>
                <th style={ this.th_style("avd") } onClick={() => this.tb_sort("avd") }>Avdelning <i className={ this.th_icon("avd") }></i></th>
                <th style={ this.th_style("text") } onClick={() => this.tb_sort("text") }>Text <i className={ this.th_icon("avd") }></i></th>
                <th style={ this.th_style("nrp") } onClick={() => this.tb_sort("nrp") }>Antal <i className={ this.th_icon("nrp") }></i></th>
                <th style={ this.th_style("netto") } onClick={() => this.tb_sort("netto") }>Nettotid <i className={ this.th_icon("netto") }></i></th>
                <th style={ this.th_style("rast") } onClick={() => this.tb_sort("rast") }>Rast <i className={ this.th_icon("rast") }></i></th>
                <th style={ this.th_style("brutto") } onClick={() => this.tb_sort("brutto") }>Bruttotid <i className={ this.th_icon("brutto") }></i></th>
                <th style={ this.th_style("ob1") } onClick={() => this.tb_sort("ob1") }>OB1 <i className={ this.th_icon("ob1") }></i></th>
                <th style={ this.th_style("ob2") } onClick={() => this.tb_sort("ob2") }>OB2 <i className={ this.th_icon("ob2") }></i></th>
                <th style={ this.th_style("nreg") } onClick={() => this.tb_sort("nreg") }>Regs <i className={ this.th_icon("nreg") }></i></th>
                <th></th>
            </tr>
            </thead>
        </table>
       <div className="tb-cdi" style={ this.tb_style() }>
       <table className="table table-bordered table-hover tb">
            <tbody>
            { this.state.tha.map((row, key) =>
            <tr key={key} onDoubleClick={() => this.dep_edit(row) }>
                <td>{ row.avd }</td>
                <td>{ this.show_text(row) }</td>
                <td className="text-center">{ utils.toNum(row.nrp) }</td>
                <td className="text-center">{ utils.toMinTid(row.netto) }</td>
                <td className="text-center">{ utils.toMinTid(row.rast) }</td>
                <td className="text-center">{ utils.toMinTid(row.brutto) }</td>
                <td className="text-center">{ utils.toMinTid(row.ob1) }</td>
                <td className="text-center">{ utils.toMinTid(row.ob2) }</td>
                <td className="text-center">{ row.nreg }</td>
                <td>
                    <Td_Sel items={ this.selitems } getValue={(e)=> this.sel_row_fkn(row, e)} />
                </td>
            </tr>
            )}
            </tbody>
        </table>
        </div>
    </div>
        <div className="divider_blue"></div>
        <div className="flex-row">
            <div className="txt_18">TOTAL NETTO: { utils.toMinTid(this.state.tot.netto) }</div>
            <div className="flex"></div>
            <div className="flex"></div>
            <div className="txt_18">TOTAL BRUTTO: { utils.toMinTid(this.state.tot.brutto) }</div>
        </div>
        { this.state.wnds.dpper ? <Dep_Per wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }

   </div>
  );
    }
}
export default TH_Avd;
