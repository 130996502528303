import React, { useState, useEffect, useContext } from 'react';
import * as fkn from "../../lib/fkn";
import * as net from "../../lib/net";
import Dt_Cal from "../../lib/ui/dt_cal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Eko_Dr extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, dta: [] };
    this.state.date = new Date();
    this.state.fdat = new Date();
    this.state.tdat = new Date();
    this.state.unit = null;
    this.state.sdo = {};
    this.state.fsa = [];
    this.state.bea = [];
    //this.state.units = [];
    //context.posrend = this;
    let cdat = new Date();
    cdat.setDate(cdat.getDate() - 1);
    let formatted_date = cdat.getFullYear() + "-" + (cdat.getMonth() + 1) + "-" + cdat.getDate();
    this.state.today = formatted_date;
    this.state.datum = formatted_date;
    this.state.fdatum = formatted_date;
    this.state.tdatum = formatted_date;
  }
  componentDidUpdate() {
    let value = this.context;
    /* ... */
  }
  componentWillUnmount() {
    let value = this.context;
    /* ... */
  }
  componentDidMount() {
    //this.props.fetchWells()
    //  .then(res => this.setState({ wells: res.wells }) )
    //this.trans();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tbs_style() {
      var css = {};
      css.minHeight = window.innerHeight - 250;
      return css;
  }
  tb_style() {
      var css = {};
      //css.height = window.innerHeight - 250;
      return css;
  }
  tbxx_style() {
      var css = {};
      css.borderTop = "1px solid #080";
      //css.height = window.innerHeight - 250;
      return css;
  }
  tbyy_style() {
      var css = {};
      css.borderBottom = "1px solid #ff9900";
      //css.height = window.innerHeight - 250;
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }
  change_datum(e) {
    //let cdat = this.state.seldate;
    let cdat = e.value;
    let formatted_date = cdat.getFullYear() + "-" + (cdat.getMonth() + 1).toString().tonum(2) + "-" + cdat.getDate().toString().tonum(2);
    this.setState({date: e.value});
    this.setState({datum: formatted_date});
    this.setState({tra: [] });
  }
  change_fdat(e) {
    //let cdat = this.state.seldate;
    let cdat = e.value;
    let formatted_date = cdat.getFullYear() + "-" + (cdat.getMonth() + 1).toString().tonum(2) + "-" + cdat.getDate().toString().tonum(2);
    this.setState({fdat: e.value});
    this.setState({fdatum: formatted_date});
    this.setState({tra: [] });
  }
  change_tdat(e) {
    //let cdat = this.state.seldate;
    let cdat = e.value;
    let formatted_date = cdat.getFullYear() + "-" + (cdat.getMonth() + 1).toString().tonum(2) + "-" + cdat.getDate().toString().tonum(2);
    this.setState({tdat: e.value});
    this.setState({tdatum: formatted_date});
    this.setState({tra: [] });
  }
  change_unit(e) {
    //let cdat = this.state.seldate;
    //alert(JSON.stringify(e));
    let unit = e.target.value;
    this.setState({unit: unit});
    this.setState({tra: [] });
  }

  show_fsg() {
    var pris = "0.00";
    if(this.state.sdo.tot && this.state.sdo.tot.fsg) {
        pris = this.state.sdo.tot.fsg.cdipris()
    }
    return pris;
  }
  show_bet() {
    var pris = "0.00";
    if(this.state.sdo.tot && this.state.sdo.tot.bet) {
        pris = this.state.sdo.tot.bet.cdipris()
    }
    return pris;
  }

  trans() {
    var self = this;
    var datum = this.state.datum;
    var today = this.state.today;

    var sid = null;
    var unit = this.state.unit;
    if(unit) {
        var o = this.state.uno[unit];
        sid = o.sid;
    }
    if(!sid) {
        //alert("VÄLJ ENHET");
        self.setState({tra: [] });
        return;
    }

    //if(datum == today) this.trans_today(sid);
    //else this.trans_dt(sid, datum);
  }

  dt_dr() {
    var self = this;
    var prm = {};
    prm.req = "pms.eko_red.red_konto";
    prm.fdat = this.state.fdatum;
    prm.tdat = this.state.tdatum;

    //gda.wndLoading("Hämtar");
    //net.sio_req(prm)
    net.sid_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rco.tot));

        if(ret.ok != "000") {
            return;
        }
        //o.deps = ret.rco;
        //alert(JSON.stringify(o));
        self.setState({sdo: ret.sdo });
        var fsa = [];
        var bea = [];
        if(ret.sdo.fsa) fsa = ret.sdo.fsa;
        if(ret.sdo.bea) bea = ret.sdo.bea;
        self.setState({ fsa: fsa, bea: bea });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
    }

  render() {
    return (
    <div className="web-app" style={{margin: "0px", padding: "5px 10px"}}>
    <h3>Total Dagrapport</h3>

    <div className="flex-row flex-space-between">
        <div className="flex-row">
            <div className="dt-btn-01" onClick={() => this.subday() } ><FontAwesomeIcon icon="minus" size="1x" /></div>
            <Dt_Cal dts={this.state.dts} rp="1" ref={this.dt_dt} getValue={(e)=> this.dt_dat(e)} />
            <div className="dt-btn-01" onClick={() => this.addday() } ><FontAwesomeIcon icon="plus" size="1x" /></div>
        </div>
        <button className="btn btn-hw-blue w150 ripple" onClick={() => this.dt_dr() } aria-label="System" >
            Visa
        </button>
    </div>
    <div className="divider_orange"></div>

    <div className="web-col" style={{background: "#555"}}>
    <div className="web-row" style={{margin: "0px auto", padding: "5px 10px", width: "750px", background: "#fff"}}>
        <table className="tb-lt01 tb fix_head" style={ this.tbs_style() }>
            <thead>
            <tr>
                <th>Konto</th>
                <th style={{width: "300px"}}>Text</th>
                <th>Antal</th>
                <th>Belopp</th>
            </tr>
            </thead>
            <tbody style={ this.tbyy_style() }>
            <tr>
                <td colSpan="4">Försäljning</td>
            </tr>
            </tbody>
            <tbody style={ this.tb_style() }>
            { this.state.fsa.map((row, key) =>
            <tr key={ row.konto } ng-dblclick="trans_open(row);">
                <td>{ row.konto }</td>
                <td style={{width: "300px"}}>{ row.text }</td>
                <td className="text-center">{ row.antal.ztrim() }</td>
                <td className="text-right">{ row.belopp.cdipris() }</td>
            </tr>
            )}
            </tbody>
            <tbody style={ this.tbxx_style() }>
            <tr>
                <td colSpan="3"></td>
                <td className="text-right">{this.show_fsg()}</td>
            </tr>
            </tbody>

            <tbody style={ this.tbyy_style() }>
            <tr>
                <td colSpan="4">Betalning</td>
            </tr>
            </tbody>
            <tbody style={ this.tb_style() }>
            { this.state.bea.map((row, key) =>
            <tr key={ row.konto } ng-dblclick="trans_open(row);">
                <td>{ row.konto }</td>
                <td style={{width: "300px"}}>{ row.text }</td>
                <td className="text-center">{ row.antal.ztrim() }</td>
                <td className="text-right">{ row.belopp.cdipris() }</td>
            </tr>
            )}
            </tbody>
            <tbody style={ this.tbxx_style() }>
            <tr>
                <td colSpan="3"></td>
                <td className="text-right">{this.show_bet()}</td>
            </tr>
            </tbody>
        </table>
    </div>
    </div>
    <div className="divider_orange"></div>

    </div>
  );
}
}
export default Eko_Dr;
