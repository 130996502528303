import React, { useState, useEffect, useContext } from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";

import { observer } from 'mobx-react'

import * as net from "../../lib/net";

function web01() {

    var html =
    <div className="web-content">
    </div>
    ;
    return html;
}

class Csg extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pos = props.store.pos;
    this.state = { pgid: 11, mnu_show: true, deps: [] };
    //context.posrend = this;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.props.fetchWells()
    //  .then(res => this.setState({ wells: res.wells }) )
    var deps = [];
    deps.push( {"id": "01", "text": "DEP X1" } );
    deps.push( {"id": "02", "text": "DEP X2" } );
    deps.push( {"id": "03", "text": "DEP X3" } );

    //this.props.store.seo.cs = "5";
    ///this.props.store.seo.unit = "BSENHET";

    var pos = {};
    pos.deps = deps;
    pos.vgrps = [];
    pos.plus = [];
    this.props.store.pos = pos;
    //alert(JSON.stringify(pos));
      //this.pos_init();
      this.units();
  }

  fknGo(url) {
    //var sdo = useContext(SDO);
    //alert(fkn);
    //var fkn = row.fid;
    //setState({ row : row,  ma : [] });
    //ctx.fid = fkn;

    //props.history.push(row.url);
    this.props.history.push(url);
   };

  ddo() {
    //alert("POS" + this.state.pgid );
    //this.setState({ status: 1 });
  }

    pos_init() {
    var self = this;

	var req = {};
	req.req = "pos.pos_fkn.pos_init";

    //gda.wndLoading("Hämtar");
    net.sio_req(req)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        //var pos = {};
        //pos.deps = ret.rco.deps;
        //self.props.store.pos = pos;
        if(!self.props.store.pos) self.props.store.pos = {};
        self.props.store.pos.deps = ret.rco.deps;
        self.props.store.pos.vgrps = ret.rco.vgrps;

        //self.setState({ deps: ret.rco });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //alert(JSON.stringify(txt));
    })
    }
  units() {
    var self = this;

    if(!self.props.store.pos) self.props.store.pos = {};
    self.props.store.pos.csa = [];

    //this.props.store.seo.cs = "0012";
    if( !this.props.store.seo.cs || ( this.props.store.seo.cs.length < 1)) return;

    var prm = {};
    prm.req = "cs.cs_units.csa";
	prm.cs = this.props.store.seo.cs;
	prm.csid = this.props.store.seo.csid;

    net.ccs_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            alert(JSON.stringify(ret));
            return;
        }
        var sa = ret.rca;
        if(!self.props.store.pos) self.props.store.pos = {};
        self.props.store.pos.csa = sa;
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

  render() {

    return (
    <div className="web-app">
    </div>
  );
}
}
//Sys.contextType = ADOContext;

export default observer(Csg);
