import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";
import * as utils from "../../assets/utils";

import Fak_New from "./fak/fak_new";
import Fak_Edit from "./fak/fak_edit";
import Td_Sel from "../../lib/ui/td_sel";
import PdfFakDlg from "../rsk/fkn/pdf_fak_dlg";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Fo_Intrsk extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, tra: [] };
    this.state.seldate = new Date();
    this.state.avd = "01";
    this.state.rsa = [];
    this.state.rss = [];
    this.state.aa = [];
    this.state.fak = {};
    this.state.cols = {};
    this.state.wnds = {};
      this.state.sortid = "";
      this.state.sortdir = 0;
      this.state.sz_val = "";

      this.selitems = [
          {fkn: 'edit', text: 'ÖPPNA'},
          {fkn: 'del', text: 'TA BORT'}
      ];

      this.state.pdf = 0;
      this.state.pdf_content = null;
      this.state.loading = 0;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.rsk();
  }

    pdf_done (ret) {
        this.setState({loading: 0, pdf: 0, pdf_content: null });
    }
  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      css.overflow = "auto";
      return css;
  }
    th_style(id) {
        var css = {};
        css.cursor = "pointer";
        if(id == this.state.sortid) {
            if(this.state.sortdir) css.color = "#800";
            else css.color = "#080";
        }
        return css;
    }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

    fak_creat() {
        var self = this;
        var wnds = {};
        wnds.new = 1;
        wnds.cb = this.cb_faknew;
        this.setState({ wnds: wnds });
    }
    cb_faknew(ctx, faknr) {
        var self = this;
        var wnds = {};
        wnds.edit = 1;
        wnds.faknr = faknr;
        wnds.cb = ctx.cb_fakedit;
        ctx.setState({ wnds: wnds });
    }
    fak_edit(row) {
        var self = this;
        var wnds = {};
        wnds.edit = 1;
        wnds.faknr = row.wfh.wfak00.faknr;
        wnds.fak = row;
        wnds.cb = this.cb_fakedit;
        this.setState({ wnds: wnds });
    }
    cb_fakedit(ctx, rsp) {
        ctx.setState({ wnds: {} });
        ctx.rsk();
        if(rsp && (rsp.fkn == "fak_ext")) {
            ctx.prn_fak_ext(rsp.faknr);
        }
    }
    prn_fak_ext(faknr) {
        var self = this;

        var prm = {};
        prm.req = "fakt.fakt_prn.ext_prn";
        prm.faktnr = faknr;
        //alert(JSON.stringify(prm));
        //return;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.ok));

                //var uri = "data:application/pdf;base64," + ret.pdf;
                self.setState({pdf: 1, pdf_content: ret.pdf });
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    fak_del(row) {
        var self = this;
        var prm = {};
        prm.req = "fakt.fakt_int.int_remove";
        prm.faktyp = "I";
        prm.faknr = row.wfh.wfak00.faknr;
        prm.wfak00 = row.wfh.wfak00;

        net.sio_req(prm)
        .then(function(ret) {
            //alert(JSON.stringify(ret.rco));

            self.rsk();
            var msg = { info: false, error: true, text: "Borttagen ..."};
            self.setState({msg: msg });
            setTimeout(function(){
                self.setState({msg: {} });
                return;
            }, 1000);
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }

  rsk() {
    var self = this;
    var prm = {};
    prm.req = "fakt.fakt_int.int_rsk";

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        self.setState({rsa: ret.rca, rss: ret.rca }, function () {
            self.syncTb("rskTable");
        });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
    syncTb(tb){
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
            tdElements = tbel.getElementsByTagName('td');

        for (let i = 0; i < thElements.length; i++) {
            if(!tdElements[i]) continue;
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
                width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
    tb_sort(typ) {
        var self = this;
        //var rvs = self.state.rvs;
        var rsa = self.state.rsa;
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;

        if(sortid != typ) sortdir = 0;
        else {
            if(sortdir) sortdir = 0;
            else sortdir = 1;
        }

        var f1 = "wfak00";
        var f2 = "faknr";
        if(typ == "kund") { f1 = "wfak00"; f2 = "kund"; }
        if(typ == "company") { f1 = "wfak00"; f2 = "company"; }
        if(typ == "belopp") { f1 = "wfak00"; f2 = "belopp"; }
        if(typ == "moms") { f1 = "wfak00"; f2 = "moms"; }
        if(typ == "fakdatum") { f1 = "wfak00"; f2 = "fakdatum"; }
        if(typ == "ffdatum") { f1 = "wfak00"; f2 = "ffdatum"; }
        if(typ == "days") { f1 = "wfak00"; f2 = "days"; }
        if(typ == "status") { f1 = "wfak00"; f2 = "status"; }

        if(true) {
            if(sortdir) rsa.sort((a,b) => (a.wfh[f1][f2] < b.wfh[f1][f2]) ? 1 : ((b.wfh[f1][f2] < a.wfh[f1][f2]) ? -1 : 0));
            else rsa.sort((a,b) => (a.wfh[f1][f2] > b.wfh[f1][f2]) ? 1 : ((b.wfh[f1][f2] > a.wfh[f1][f2]) ? -1 : 0));
        }
        self.setState({ rsa: rsa, sortid: typ, sortdir: sortdir });
    }
    sel_row_fkn(row, sel) {
        //alert(JSON.stringify(fkn));
        if(sel.fkn == "edit") {
            this.fak_edit(row);
        }
        if(sel.fkn == "del") {
            this.fak_del(row);
        }
    }
    tb_filtr() {
        var self = this;
        var rss = self.state.rss;
        var sz = this.state.sz_val;
    
        if(sz.length < 1) {
          self.setState({ rsa: rss });
            return;
        }
        sz = sz.toUpperCase();
        var rsa = rss.filter(function(row) {
              var brc = false;
              if(row.wfh.wfak00.faknr.toUpperCase().includes(sz)) brc = true;
              if(row.wfh.wfak00.kund.toUpperCase().includes(sz)) brc = true;
              if(row.wfh.wfak00.company.toUpperCase().includes(sz)) brc = true;
              if(row.wfh.wfak00.belopp.toUpperCase().includes(sz)) brc = true;
              if(row.wfh.wfak00.fakdatum.toUpperCase().includes(sz)) brc = true;
              return brc;
        });
        self.setState({rsa: rsa });
        }
    
    sz_style() {
        var css = {};
        css.height = "40px";
        css.lineHeight = "40px";
        css.padding = "0px 10px";
        css.fontSize = "12px";
        css.cursor = "pointer";
        return css;
    }

    sz_keydown (e) {
    //e.preventDefault();
      const key = e.key;
      const keyCode = e.keyCode;

      if(keyCode == 13) {
          this.tb_filtr();
      };
      if(keyCode == 27) {
          this.setState({sz_val: ""});
      };
  }
  sz_change (e) {
      const name = e.target.name;
      const value = e.target.value;
      this.setState({sz_val: value});
      //setState({[name]: value});
  }

  render() {
    return (
    <div className="web-app">

    <div className="flex-row">
        <div className="label_h03">Intern Reskontra</div>

        <div className="flex"></div>
        <input className="txt sztxt" value={this.state.sz_val} placeholder="Ange söktext..."  style={ this.sz_style() } onChange={(e) => this.sz_change(e)} onKeyDown={(e) => this.sz_keydown(e)} />
        <button className="btn btn-hw-blue w150" onClick={() => this.fak_creat() } aria-label="System" >
            Ny Faktura
        </button>
        <button className="btn btn-hw-blue w150" onClick={() => this.rsk() } aria-label="System" >
            Visa
        </button>
    </div>
    <div className="divider"></div>

        <div className="web-col">
        <div id="rskTable">
        <table className="table table-bordered table-hover tb" style={{marginBottom: "0px"}}>
            <thead>
            <tr>
                <th style={ this.th_style("faknr") } onClick={() => this.tb_sort("faknr") }>Int nr</th>
                <th style={ this.th_style("kund") } onClick={() => this.tb_sort("kund") }>Kund</th>
                <th style={ this.th_style("company") } onClick={() => this.tb_sort("company") }>Företag</th>
                <th style={ this.th_style("belopp") } onClick={() => this.tb_sort("belopp") }>Belopp</th>
                <th style={ this.th_style("moms") } onClick={() => this.tb_sort("moms") }>Moms</th>
                <th style={ this.th_style("brutto") } onClick={() => this.tb_sort("brutto") }>Ex moms</th>
                <th style={ this.th_style("fakdatum") } onClick={() => this.tb_sort("fakdatum") }>Fakturadatum</th>
                <th style={ this.th_style("ffdatum") } onClick={() => this.tb_sort("ffdatum") }>Förfallodatum</th>
                <th style={ this.th_style("days") } onClick={() => this.tb_sort("days") }>Dagar</th>
                <th style={ this.th_style("status") } onClick={() => this.tb_sort("status") }>Status</th>
                <th style={ this.th_style("" ) }></th>
            </tr>
            </thead>
        </table>
        <div style={ this.tb_style() }>
       <table className="table table-bordered table-hover tb">
            <tbody>
            { this.state.rsa.map((row, key) =>
            <tr key={ key } onDoubleClick={() => this.fak_edit(row) }>
                <td>{ utils.toNum( row.wfh.wfak00.faknr ) }</td>
                <td>{ utils.toNum( row.wfh.wfak00.kund ) }</td>
                <td>{ utils.toLang( row.wfh.wfak00.company ) }</td>
                <td>{ utils.toPris( row.wfh.wfak00.belopp ) }</td>
                <td>{ utils.toPris( row.wfh.wfak00.moms ) }</td>
                <td>{ utils.toPris( (row.wfh.wfak00.belopp * 1) - (row.wfh.wfak00.moms * 1) ) }</td>
                <td>{ utils.toDate( row.wfh.wfak00.fakdatum ) }</td>
                <td>{ utils.toDate( row.wfh.wfak00.ffdatum ) }</td>
                <td>{ row.wfh.wfak00.days }</td>
                <td>{ row.wfh.wfak00.status }</td>
                <td>
                    <Td_Sel items={ this.selitems } getValue={(e)=> this.sel_row_fkn(row, e)} />
                </td>
            </tr>
            )}
            </tbody>
        </table>
        </div>
        </div>
    </div>
        { this.state.wnds.new ? <Fak_New wnds={this.state.wnds} ctx={this} backdrop={true}/> : null }
        { this.state.wnds.edit ? <Fak_Edit wnds={this.state.wnds} ctx={this} backdrop={true}/> : null }
        <PdfFakDlg show={this.state.pdf} pdf={this.state.pdf_content} done={(ret) => this.pdf_done(ret)} backdrop={true}/>
    </div>
  );
    }
}
export default Fo_Intrsk;
