import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../../lib/net";
import Wnd_Ok from "../../../lib/ui/wnd_ok";
import Wnd_Error from "../../../lib/ui/wnd_error";
import Wnd_Info from "../../../lib/ui/wnd_info";
import Wnd_Confirm from "../../../lib/ui/wnd_confirm";

function TabPanel(props) {
  const { children, value, index, ctx, ...other } = props;
    function tab_change(val) {
        //alert(newValue);
        ctx.setState({ix: val});
      };
  function tab_style(val) {
      var css = {};
      css.height = "30px";
      css.lineHeight = "30px";
      css.background = "#fff";
      css.borderBottom = "1px #ccc solid";
      css.color = "#ccc";
      if(val == ctx.state.ix) {
          css.color = "#080";
          css.borderBottom = "2px #080 solid";
      }
      return css;
  }

  return (
    <div
      className="flex ripple text-center" onClick={() => tab_change(index)} style={ tab_style(index) }
      {...other}
    >
        {children}
    </div>
  );
}
class Usr_Open extends React.Component {
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.state = {};
    this.state.uaa = [];
    this.state.laa = [];
    this.state.lso = {};
    //this.state.lro = props.lro;
    this.state.ynq = [
                {id: '0', text: 'JA'},
                {id: '1', text: 'NEJ'}
            ];
    this.state.aa = [
                {id: '01', text: 'AVD 01'},
                {id: '02', text: 'AVD 02'}
            ];
    this.state.authval = [
                {id: '00', text: 'NIVÅ 00'},
                {id: '05', text: 'NIVÅ 05'},
                {id: '09', text: 'NIVÅ 09'},
                {id: '59', text: 'NIVÅ 59'},
                {id: '91', text: 'NIVÅ 91'},
                {id: '99', text: 'NIVÅ 99'}
            ];
    this.state.grps = [
                {id: '000', text: 'ALLA'},
                {id: '001', text: 'INTERNA'},
                {id: '051', text: 'PARTNERS'},
                {id: '101', text: 'KUNDER SVERIGE'},
                {id: '201', text: 'KUNDER NORGE'}
            ];
    this.state.cols = {};
    this.state.lrwrk = false;

    this.state.pro = {};
    this.state.pbo = {};
    this.state.qbo = props.pbo;
    if(!this.state.qbo.cfg) this.state.qbo.cfg = {};

      this.state.ix = 0;
    this.state.md_01 = false;
    this.state.md_init = false;
    this.state.msg = {};
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.cdi_init();
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    tab_style(tabid) {
      var css = {};
      css.display = "none";
      if(this.state.tabval === tabid) css.display = "block";
      return css;
    }
    tab_head(tabid) {
      var css = {};
      css.background = "#fff";
      css.color = "#000";
      if(this.state.tabval === tabid) {
          css.background = "#080";
          css.color = "#fff";
      }
      return css;
    }
    cdi_init() {
        var self = this;
        var pro = {};
        var pbo = {};
        var qbo = self.state.qbo;

        var keys = Object.keys(qbo);
        for(var key of keys) {
            var o = qbo[key];
            pbo[key] = {};
            var okeys = Object.keys(qbo[key]);
            for(var okey of okeys) {
                var oo = qbo[key][okey];
                pbo[key][okey] = oo;
            }
        }

        pbo.usr00.u0namn = pbo.usr00.u0namn.cdi2txt();

        self.setState({pbo: pbo, pro: pro,  md_init: true});
    }
    cdi_store() {
        var self = this;
        var pbo = self.state.pbo;
        var qbo = self.state.qbo;
        var pro = self.state.pro;

        pbo.usr00.u0namn = pbo.usr00.u0namn.txt2cdi();

        var keys = Object.keys(pbo);
        for(var key of keys) {
            var o = pbo[key];
            if(key.substr(0,3) != "plu") continue;

            var okeys = Object.keys(pbo[key]);
            for(var okey of okeys) {
                var oo = pbo[key][okey];
                qbo[key][okey] = oo;
            }
        }
        self.setState({qbo: qbo});
    }
    cdi_usrbuf() {
        var self = this;
        var usrbuf = {};
        var pbo = self.state.pbo;
        var pro = self.state.pro;

        pbo.usr00.u0namn = pbo.usr00.u0namn.txt2cdi();

        var keys = Object.keys(pbo);
        for(var key of keys) {
            var o = pbo[key];
            if(key.substr(0,3) != "usr") continue;

            usrbuf[key] = {};
            var okeys = Object.keys(pbo[key]);
            for(var okey of okeys) {
                var oo = pbo[key][okey];
                usrbuf[key][okey] = oo;
            }
        }
        self.setState({pro: pro});

        return usrbuf;
    }

    formchange (e) {
      const name = e.target.name;
      const value = e.target.value;
      var pbo = this.state.pbo;
      var na = name.split(".");
      pbo[na[0]][na[1]] = value;
      this.setState({pbo: pbo});
      //setState({[name]: value});
    }
    prischange (e) {
      const name = e.target.name;
      const value = e.target.value;
      var pro = this.state.pro;
      var na = name.split(".");
      pro[na[1]] = value;
      this.setState({pro: pro});
      //setState({[name]: value});
    }

    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    handleClose = () => {
		//this.props.ctx.setState({ usropen: false });
        this.props.cb();
	};
    handleChange = (event, newValue) => {
        //alert(newValue);
        this.setState({ix: newValue});
      };
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }

  usr_save (event) {
    var self = this;
    var msg = { info: true, text: "SPARAR ..." };
    self.setState({msg: msg });

    var usrbuf = self.cdi_usrbuf();

    //event.preventDefault();
    //const data = new FormData(event.target);
    //const value = Object.fromEntries(data.entries());

    var prm = {};
    prm.req = "usr:save";
    prm.usrbuf = usrbuf;

    //gda.wndLoading("Hämtar");
    net.gcs_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok == "000") {
            self.cdi_store();
        }
        var msg = { info: false, ok: true, text: "Sparat ..."};
        self.setState({msg: msg });
        setTimeout(function(){
            self.setState({msg: {} });
            //self.props.ctx.setState({ pluopen: false });
            self.props.ctx.usr_upd(self.props.ctx, usrbuf);
        }, 1000);
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        var msg = { info: false, error: true, text: "Misslyckades ..."};
        self.setState({msg: msg });
        setTimeout(function(){
            self.setState({msg: {} });
            //self.props.ctx.setState({ pluopen: false });
            self.props.ctx.usr_upd(self.props.ctx, usrbuf);
        }, 1000);
    });
    return;
  }

  usr_delete (event) {
    var self = this;
    var msg = { confirm: true, text: "Ta bort användaren?", text0: "NEJ", text1: "JA", cb: "usr_del_do"};
    self.setState({msg: msg });
    //self.usr_delete();
    return;
  }

  usr_del_do (val) {
    var self = this;

    if(val != "1") {
        self.setState({msg: {} });
        return;
    }
    var msg = { info: true, text: "TAR BORT ..." };
    self.setState({msg: msg });

    var usrbuf = self.cdi_usrbuf();

    var prm = {};
    prm.req = "usr:delete";
    prm.usrbuf = usrbuf;

    //gda.wndLoading("Hämtar");
    net.gcs_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok == "000") {
            self.cdi_store();
        }
        var msg = { info: false, ok: true, text: "Borttagen ..."};
        self.setState({msg: msg });
        setTimeout(function(){
            self.setState({msg: {} });
            //self.props.ctx.setState({ pluopen: false });
            self.props.ctx.usr_upd(self.props.ctx, usrbuf);
        }, 1000);
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        var msg = { info: false, error: true, text: "Misslyckades ..."};
        self.setState({msg: msg });
        setTimeout(function(){
            self.setState({msg: {} });
            //self.props.ctx.setState({ pluopen: false });
            self.props.ctx.usr_upd(self.props.ctx, usrbuf);
        }, 1000);
    });
    return;
  }

  render() {
    if(!this.props.show) return "";

    //alert(this.props.ctx.state.lro);

    var title = this.props.title;
    if(!title) title = "Plu";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="mx_backdrop" /> : null }
        <div className="mx_dlg mx_90 flex-col" style={ this.dlg_style() }>
        { this.state.md_init ?
        <div className="mx_content flex">
            <div className="flex-row" style={{padding:"5px",height:"30px"}}>
                    <div className="flex-row flex txt_18">
                        <div>{ this.state.pbo.usr00.u0id } - { this.state.pbo.usr00.u0namn }</div>
                    </div>
                    <div className="flex-col">
                        <div>UPPDATERA</div>
                    </div>
            </div>
            <div className="divider_orange"></div>

            <div className="flex-row">
                <TabPanel index={0} ctx={this}>BAS</TabPanel>
                <TabPanel index={1} ctx={this}>INFO</TabPanel>
                <TabPanel index={2} ctx={this}>PARAMETRAR</TabPanel>
            </div>
            <div style={{padding:"5px"}}></div>

            <form className="cdi_f03">
            <div hidden={this.state.ix !== 0}>

                <div className="form-row flex-row">
                    <div className="form-group flex-row flex">
                        <span className="header">Namn</span>
                        <input type="text" className="form-control text-left w350"
                               name="usr00.u0namn" value={this.state.pbo.usr00.u0namn}
                               placeholder="" onChange={(e) => this.formchange(e)} />
                    </div>
                </div>
                <div className="flex-row">
                    <div className="form-group flex-row flex">
                        <span className="header">Användare</span>
                        <input type="text" className="form-control text-left w350"
                               name="usr00.u0uid" value={this.state.pbo.usr00.u0uid}
                               placeholder="" onChange={(e) => this.formchange(e)} />
                    </div>
                    <div className="form-group flex-row flex">
                        <span className="header">Lösenord</span>
                        <input type="text" className="form-control text-left w250"
                               name="usr00.u0pwd" value={this.state.pbo.usr00.u0pwd}
                               placeholder="" onChange={(e) => this.formchange(e)} />
                    </div>
                </div>

                <div className="form-row flex-row">
                    <div className="form-group flex-row flex">
                        <span className="header">Födelse nr</span>
                        <input type="text" className="form-control text-left w250"
                               name="usr00.u0fodnr" value={this.state.pbo.usr00.u0fodnr}
                               placeholder="" onChange={(e) => this.formchange(e)} />
                    </div>
                </div>

                <div className="divider_green"></div>

                <div className="form-row flex-row">
                    <div className="form-group flex-row flex">
                        <span className="header">Anst nr</span>
                        <input type="text" className="form-control text-left w250"
                               name="usr00.u0enr" value={this.state.pbo.usr00.u0enr}
                               placeholder="" onChange={(e) => this.formchange(e)} />
                    </div>
                    <div className="form-group flex-row flex">
                        <span className="header">Signatur</span>
                        <input type="text" className="form-control text-left w250"
                               name="usr00.u0sign" value={this.state.pbo.usr00.u0sign}
                               placeholder="" onChange={(e) => this.formchange(e)} />
                    </div>
                </div>

                <div className="form-row flex-row">
                    <div className="form-group flex-row flex">
                        <span className="header">Kund ID</span>
                        <input type="text" className="form-control text-left w250" onChange={(e) => this.formchange(e)}
                               name="usr00.u0kid" value={this.state.pbo.usr00.u0kid}
                               placeholder="" maxLength="8" />
                    </div>
                    <div className="form-group flex-row flex">
                        <span className="header">Profil ID</span>
                        <input type="text" className="form-control text-left w150" onChange={(e) => this.formchange(e)}
                               name="usr00.u0pid" value={this.state.pbo.usr00.u0pid}
                               placeholder="" maxLength="4" />
                    </div>
                </div>
                <div className="form-row flex-row">
                    <div className="form-group flex-row flex">
                        <span className="header">Bassystem</span>
                        <input type="text" className="form-control text-left w150" onChange={(e) => this.formchange(e)}
                               name="usr00.u0sub" value={this.state.pbo.usr00.u0sub}
                               placeholder="" maxLength="3" />
                    </div>
                    <div className="form-group flex-row flex">
                        <span className="header">Nivå</span>
                        <select name="usr00.u0auth" value={this.state.pbo.usr00.u0auth} className="form-control w250" onChange={(e) => this.formchange(e)}>
                            { this.state.authval.map((row, key) =>
                            <option key={key} value={row.id}>{ row.text }</option>
                            )}
                        </select>
                    </div>
                </div>
                <div className="form-row flex-row">
                    <div className="form-group flex-row flex">
                        <span className="header">Status</span>
                        <input type="text" className="form-control text-left w150" onChange={(e) => this.formchange(e)}
                               name="usr00.u0status" value={this.state.pbo.usr00.u0status}
                               placeholder="" />
                    </div>
                    <div className="form-group flex-row flex">
                        <span className="header">Grupp/Sortering</span>
                        <select name="usr00.u0sort" value={this.state.pbo.usr00.u0auth} className="form-control w250" onChange={(e) => this.formchange(e)}>
                            { this.state.grps.map((row, key) =>
                            <option key={key} value={row.id}>{ row.text }</option>
                            )}
                        </select>
                    </div>
                </div>

            </div>


            <div hidden={this.state.ix !== 1}>

                <div className="form-row flex-row">
                    <div className="form-group flex-row flex">
                        <span className="header">Centralsystem</span>
                        <input type="text" className="form-control text-left w150" onChange={(e) => this.formchange(e)}
                               name="usr01.u1cs" value={this.state.pbo.usr01.u1cs}
                               placeholder="" maxLength="1" />
                    </div>
                </div>
                <div className="form-row flex-row">
                     <div className="form-group flex-row flex">
                        <span className="header">Centralsystem ID</span>
                        <input type="text" className="form-control text-left w250" onChange={(e) => this.formchange(e)}
                               name="usr01.u1csid" value={this.state.pbo.usr01.u1csid}
                               placeholder="" maxLength="4" />
                    </div>
                </div>

                <div className="divider_green"></div>

            </div>

            <div hidden={this.state.ix !== 2}>
                <div className="form-row flex-row">
                    <div className="form-group flex-row flex">
                        <span className="header">Termnr</span>
                        <input type="text" className="form-control text-left w150" onChange={(e) => this.formchange(e)}
                               name="cfg.termnr" value={this.state.pbo.cfg.termnr}
                               placeholder="" maxLength="3" />
                    </div>
                    <div className="form-group flex-row flex">
                        <span className="header">Emvnr</span>
                        <input type="text" className="form-control text-left w150" onChange={(e) => this.formchange(e)}
                               name="cfg.emvnr" value={this.state.pbo.cfg.emvnr}
                               placeholder="" maxLength="3" />
                    </div>
                </div>
                <div className="form-row flex-row">
                    <div className="form-group flex-row flex">
                        <span className="header">Encoder</span>
                        <input type="text" className="form-control text-left w150" onChange={(e) => this.formchange(e)}
                               name="cfg.encoder" value={this.state.pbo.cfg.encoder}
                               placeholder="" maxLength="3" />
                    </div>
                </div>
                <div className="form-row flex-row">
                    <div className="form-group flex-row flex">
                        <span className="header">Bokningsbild</span>
                        <input type="text" className="form-control text-left w150" onChange={(e) => this.formchange(e)}
                               name="cfg.pmsrv" value={this.state.pbo.cfg.pmsrv}
                               placeholder="" maxLength="3" />
                    </div>
                </div>
            </div>

        </form>

        </div>
        : null }

        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250 ripple" onClick={() => this.handleClose() } >
                STÄNG
            </button>
            <div className="flex-row">
             <button className="btn btn-hw-blue w250 ripple" onClick={(event) => this.usr_delete(event) } >
                TA BORT
            </button>
           </div>
            <button className="btn btn-hw-green w250 ripple" onClick={(event) => this.usr_save(event) } >
                SPARA
            </button>
        </div>
      </div>

        <Wnd_Error msg={this.state.msg} ctx={this} />
        <Wnd_Ok msg={this.state.msg} ctx={this} />
        <Wnd_Info msg={this.state.msg} ctx={this} />
        <Wnd_Confirm msg={this.state.msg} ctx={this} />
    </div>
	;
	return html;
  };
}

export default Usr_Open;