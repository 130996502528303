import React, { useState, useEffect, useContext } from 'react';
import {format} from "date-fns";

import * as net from "../../lib/net";

import Dt_Cal from "../../lib/ui/dt_cal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Pms_SaldoRvs extends React.Component {
  constructor(props, context) {
    super(props);

    this.dt_fd = React.createRef();
    this.dt_td = React.createRef();

    this.webStore = props.store;
    this.state = { pgid: 11, gsa: [] };
    this.state.fdat = new Date();
    this.state.tdat = new Date();
    this.state.unit = null;
    this.state.wnds = {};

    this.state.today = new Date();

    var dts = format(this.state.today, "yyyy-MM-dd");
    this.state.fdat = dts;
    this.state.tdat = dts;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.trans();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 250;
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }
  dt_fdat(e) {
    //alert(JSON.stringify(e));
    var dts = format(e, "yyyy-MM-dd");
    this.setState({ fdat: dts });
  }
  dt_tdat(e) {
      //alert(JSON.stringify(e));
    var dts = format(e, "yyyy-MM-dd");
    this.setState({ tdat: dts });
  }
  oneday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_td.current.updateDt(this.state.fdat);
  }
  addday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_fd.current.updateAdd();
    this.dt_td.current.updateAdd();
  }
  subday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_fd.current.updateSub();
    this.dt_td.current.updateSub();
  }
  change_unit(e) {
    //let cdat = this.state.seldate;
    //alert(JSON.stringify(e));
    let unit = e.target.value;
    this.setState({unit: unit});
    this.setState({tra: [] });
  }

  saldo_rvs() {
    var self = this;

    var prm = {};
    prm.req = "pms.eko_red.red_rvs";
    prm.fdat = this.state.fdat;
    prm.tdat = this.state.tdat;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            return;
        }
        var gsa = ret.sdo.gsa;
        self.setState({ gsa: gsa });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
    }

  val_pris(val) {
      var s = "";
      if(val) s = val.cdipris();
      return s;
  };

  render() {
    return (
    <div className="web-app">
    <div className="">
        BOKNINGAR
    </div>

    <div className="flex-row flex-space-between">
        <div className="flex-row">
              <div className="dt-btn-01" onClick={() => this.subday() } ><FontAwesomeIcon icon="minus" size="1x" /></div>
              <Dt_Cal startdatum="2020-02-01" rp="1" ref={this.dt_fd} getValue={(e)=> this.dt_fdat(e)} />
              <div className="dt-btn-01" onClick={() => this.oneday() } >1</div>
              <Dt_Cal startdatum="2020-02-01" rp="1" ref={this.dt_td} getValue={(e)=> this.dt_tdat(e)} />
              <div className="dt-btn-01" onClick={() => this.addday() } ><FontAwesomeIcon icon="plus" size="1x" /></div>
        </div>
        <div className="flex"></div>
       <button className="btn btn-hw-green w250" onClick={() => this.saldo_dt() } aria-label="System" >
            Bokning dag
        </button>
        <button className="btn btn-hw-blue w150" onClick={() => this.saldo_rvs() } aria-label="System" >
            Visa
        </button>
    </div>
    <div className="divider"></div>

    <div className="web-row">
        <table className="table table-bordered table-hover tb fixed_header">
            <thead>
            <tr>
                <th>Boknr</th>
                <th>Namn</th>
                <th>Ankomst</th>
                <th>Avresa</th>
                <th>Rumsnr</th>
                <th>Fsg</th>
                <th>Bet</th>
                <th>Saldo</th>
                <th>Status</th>
            </tr>
            </thead>
            <tbody style={ this.tb_style() }>
            { this.state.gsa.map((row, key) =>
            <tr key={key} onDoubleClick={() => this.trans_open(row) }>
                <td>{ row.boknr }</td>
                <td>{ row.name }</td>
                <td>{ row.ankdat.cdidate() }</td>
                <td>{ row.avrdat.cdidate() }</td>
                <td>{ row.rumsnr }</td>
                <td>{ this.val_pris( row.charges.fsg ) }</td>
                <td>{ this.val_pris( row.charges.bet ) }</td>
                <td>{ this.val_pris( row.charges.saldo ) }</td>
                <td>{ row.status }</td>
            </tr>
            )}
            </tbody>
        </table>
    </div>

    </div>
  );
}
}
export default Pms_SaldoRvs;
