import React, { useState, useEffect, useContext } from 'react';
import pdf_c100 from "../docs//c100.pdf";
import pdf_a920 from "../docs//a920.pdf";

function PdfDlg(props) {
    const [state, setState] = useState({ pdf : null });
    //const [pdf, setPdf] = useState({ null });

    //var uri = "data:application/pdf;base64," + ret.pdf;
    //self.setState({ pdf_uri: uri });
    //var pdf_uri = "data:application/pdf;base64," + props.pdf;

    function handlePrn(event) {
        //props.ctx.dologin(state.user.uid, state.user.pwd);
    }
	const handleClose = () => {
		props.ctx.setState({ pdf: 0 });
	};
    if(!props.show) return "";

    var pdf_content = null;
    if(props.ctx.state.pdf == "C100") pdf_content = pdf_c100;
    if(props.ctx.state.pdf == "A920") pdf_content = pdf_a920;
    if(pdf_content == null) return "";
    //var pdf_uri = props.ctx.state.pdf_uri;
    //alert(pdf_uri);

	var html =
        <div className="mx_base">
        { props.backdrop ? <div className="mx_backdrop" /> : null }
        <div className="mx_dlg_big flex-col">
        <div className="mx_title">
            {"Sverigekassan PDF"}
        </div>
        <div className="mx_content flex">
            <object data={ pdf_content } type="application/pdf" style={{width: "100%", height: "100%"}}></object>
        </div>
        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w150 ripple" onClick={() => handleClose() } aria-label="System" >
                STÄNG
            </button>
            <button className="btn btn-hw-green w150 ripple web-hide" onClick={(event) => handlePrn(event) } aria-label="System" >
                SKRIV UT
            </button>
        </div>
      </div>
    </div>
	;
	return html;
}

export default PdfDlg;
