import React, { useState, useEffect, useContext } from 'react';
import {format, getHours, addDays, subDays} from "date-fns";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import * as net from "../../lib/net";
import * as utils from "../../assets/utils";
import Dt_Cal from "../../lib/ui/dt_cal";

import Cal_Add from "./fkn/cal_add";
import Cal_Open from "./fkn/cal_open";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Wrk_Plan extends React.Component {
  constructor(props, context) {
    super(props);

    this.dt_fd = React.createRef();
    this.dt_td = React.createRef();

    this.store = props.store;
    this.state = {};
    this.state.seldate = new Date();
    this.state.avd = "01";

    this.state.dta = [];

    this.state.tro = {};
    this.state.wnds = {};
    this.state.typ = "";

    this.state.today = new Date();
    var hh = getHours(this.state.today);
    if( hh < 5) {
        const dt = subDays(new Date(), 1);
        this.state.dts = format(dt, "yyyy-MM-dd");
    }
    else this.state.dts = format(this.state.today, "yyyy-MM-dd");

    this.state.fdat = format(this.state.today, "yyyy-MM-dd");
    const dt = addDays(new Date(), 7);
    this.state.tdat = format(dt, "yyyy-MM-dd");

    this.selitems = [
        {fkn: 'open', text: 'ÖPPNA'},
        {fkn: 'status', text: 'STATUS'},
        {fkn: 'remove', text: 'TA BORT'}
    ];

  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.cal_per();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 225;
      css.overflow = "auto";
      return css;
  }
  tab_style(typ) {
      var css = {};
      if(this.state.typ == typ) { css.background = "#009900"; css.color = "#fff"; }
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }
  th_style(id) {
      var css = {};
      css.cursor = "pointer";
      if(id == this.state.sortid) css.color = "#080";
      return css;
  }
    dt_fdat(e) {
        //alert(JSON.stringify(e));
        var dts = format(e, "yyyy-MM-dd");
        this.setState({ fdat: dts });
    }
    dt_tdat(e) {
        //alert(JSON.stringify(e));
        var dts = format(e, "yyyy-MM-dd");
        this.setState({ tdat: dts });
    }
    oneday(e) {
        //alert(JSON.stringify(e));
        //this.setState({ tdat: this.state.fdat });
        this.dt_td.current.updateDt(this.state.fdat);
    }
    addday(e) {
        //alert(JSON.stringify(e));
        //this.setState({ tdat: this.state.fdat });
        this.dt_fd.current.updateAdd();
        this.dt_td.current.updateAdd();
    }
    subday(e) {
        //alert(JSON.stringify(e));
        //this.setState({ tdat: this.state.fdat });
        this.dt_fd.current.updateSub();
        this.dt_td.current.updateSub();
    }

  cal_add() {
    var self = this;
    var wnds = {};
    wnds.caladd = true;
    this.setState({ wnds: wnds });
  }
    cal_edit(row) {
    var self = this;
    var wnds = {};
    wnds.caledit = true;
    wnds.cdo = row;
    this.setState({ wnds: wnds });
  }
    cal_close(ctx) {
    ctx.setState({ wnds: {} });
    ctx.trs();
  }

    cal_per() {
    var self = this;
    self.setState({typ: "trs", dta: [] });

    var prm = {};
    prm.req = "bcs.cal.cal_per";
    prm.fdat = this.state.fdat;
    prm.tdat = this.state.tdat;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        self.setState({dta: ret.rca }, function () {
            self.syncTb("dtaTable");
        });
    })
    .catch(function(e) {
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }
    syncTb(tb) {
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
        tdElements = tbel.getElementsByTagName('td');
        if(tdElements.length < 1 ) return;

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
            width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
   tb_sort(typ) {
      var self = this;
      if(true) return;

      //var rvs = self.state.rvs;
      var tra = self.state.tra;
      var sz = this.state.sz_val;

      tra.sort((a,b) => (a[typ] > b[typ]) ? 1 : ((b[typ] > a[typ]) ? -1 : 0));
      self.setState({ tra: tra, sortid: typ });
   }
  sel_row_fkn(row, sel) {
      //alert(JSON.stringify(fkn));
    if(sel.fkn == "open") {
        //this.sel_gst(row);
    }
    if(sel.fkn == "br") {
    }
  }
    refresh() {
      this.cal_per();
    }

  render() {
    return (
    <div className="web-app">
    <div className="flex-row">
        <div className="flex-row">
            <div className="dt-btn-01" onClick={() => this.subday() } ><FontAwesomeIcon icon="minus" size="1x" /></div>
            <Dt_Cal dts={this.state.fdat} rp="1" ref={this.dt_fd} getValue={(e)=> this.dt_fdat(e)} />
            <div className="dt-btn-01" onClick={() => this.oneday() } >1</div>
            <Dt_Cal dts={this.state.tdat} rp="1" ref={this.dt_td} getValue={(e)=> this.dt_tdat(e)} />
            <div className="dt-btn-01" onClick={() => this.addday() } ><FontAwesomeIcon icon="plus" size="1x" /></div>
        </div>

        <div className="flex"></div>

        <div className="flex-row">
        <button className="btn btn-hw-orange w150" onClick={() => this.cal_add() } aria-label="System" >
            LÄGG TILL
        </button>
        <button className="btn btn-hw-blue w150" onClick={() => this.refresh() } aria-label="System" >
            UPPDATERA
        </button>
        </div>
    </div>
        <div className="divider_orange"></div>

        <div className="cal flex-row">
            { this.state.dta.map((dto, key) =>
                <div className="flex-col flex">
                    <div className="cal_head" style={ this.th_style("dt") } onClick={() => this.tb_sort("dt") }>{ utils.toDate(dto.datum) }</div>
                    { dto.rows.map((row, key) =>
                        <div className="cal_row flex-col" style={ this.th_style("dt") } onClick={() => this.cal_edit(row) }>
                            <div className="hide">{ utils.toDate(row.cal00.fdat) } - { utils.toDate(row.cal00.tdat) }</div>
                            <div>{ utils.toTid(row.cal00.ftid) } - { utils.toTid(row.cal00.ttid) }</div>
                            <div className="fg-red">{ utils.toLang(row.cal00.pid) }</div>
                            <div>{ utils.toLang(row.cal00.text) }</div>
                        </div>
                    )}
                </div>
            )}
        </div>

        { this.state.wnds.caladd ? <Cal_Add wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.wnds.caledit ? <Cal_Open wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }

   </div>
  );
    }
}
export default Wrk_Plan;
/*

*/