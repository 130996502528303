import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";
import * as utils from "../../assets/utils";

import Task_add from "./fkn/task_add";
import Complete_open from "./fkn/complete_open";
import Td_Sel from "../../lib/ui/td_sel";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Wrk_Fakt extends React.Component {
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.bcs = props.store.bcs;
    this.state = {};
    this.state.seldate = new Date();
    this.state.avd = "01";

    this.state.tsa = [];

    this.state.tro = {};
    this.state.wnds = {};
    this.state.typ = "";

    this.selitems = [
        {fkn: 'open', text: 'ÖPPNA'},
        {fkn: 'status', text: 'STATUS'},
        {fkn: 'remove', text: 'TA BORT'}
    ];
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.tasks();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 225;
      css.overflow = "auto";
      return css;
  }
  tab_style(typ) {
      var css = {};
      if(this.state.typ == typ) { css.background = "#009900"; css.color = "#fff"; }
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }
  th_style(id) {
      var css = {};
      css.cursor = "pointer";
      if(id == this.state.sortid) css.color = "#080";
      return css;
  }

  task_add() {
    var self = this;
    var wnds = {};
    wnds.taskadd = true;
    wnds.cb = self.task_close;
    this.setState({ wnds: wnds });
  }
  task_edit(row) {
    var self = this;
    var wnds = {};
    wnds.taskedit = true;
    wnds.tdo = row;
    wnds.cb = self.task_close;
    this.setState({ wnds: wnds });
  }
    task_close(ctx) {
        ctx.setState({ wnds: {} });
        ctx.tasks();
    }
    task_fkn() {
    }

  tasks() {
    var self = this;
    self.setState({typ: "trs", tsa: [] });

    var prm = {};
    prm.req = "bcs.wrk.tasks";
      prm.typ = "T";
      prm.status = "F";

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        self.setState({tsa: ret.rca }, function () {
            self.syncTb("tsaTable");
        });
    })
    .catch(function(e) {
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }
    syncTb(tb) {
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
        tdElements = tbel.getElementsByTagName('td');
        if(tdElements.length < 1 ) return;

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
            width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
   tb_sort(typ) {
      var self = this;
      //var rvs = self.state.rvs;
      var tsa = self.state.tsa;
      var sz = this.state.sz_val;

      tsa.sort((a,b) => (a[typ] > b[typ]) ? 1 : ((b[typ] > a[typ]) ? -1 : 0));
      self.setState({ tsa: tsa, sortid: typ });
   }
  sel_row_fkn(row, sel) {
      //alert(JSON.stringify(fkn));
    if(sel.fkn == "open") {
        //this.sel_gst(row);
    }
    if(sel.fkn == "br") {
    }
  }
   refresh() {
    }
    stat_txt(status) {
        var text = "";
        status = status.toString().tonum(1);
        if( status == '0' )  text = 'REGISTRERAD';
        if( status == '1' )  text = 'BEARBETAS';
        if( status == '3' )  text = 'VÄNTAR';
        if( status == '5' )  text = 'KLAR';
        if( status == '7' )  text = 'FAKTURERAD';
        if( status == '9' )  text = 'AVSLUTAD';
        return text;
    }
    pr_txt(pid) {
        var text = "";
        pid = pid.toString().tonum(5);
        if( pid == '00000' )  text = 'EJ TILLDELAD';
        if( pid == '00001' )  text = 'Joakim';
        if( pid == '00002' )  text = 'Daniel';
        if( pid == '00003' )  text = 'Stellan';
        if( pid == '00004' )  text = 'Tobias';
        if( pid == '00005' )  text = 'Emmanuel';
        if( pid == '00006' )  text = 'Kari';
        return text;
    }
    kk_txt(kid) {
        var text = kid.ztrim();
        kid = kid.toString().tonum(10);
        var key = "K" + kid;
        if(this.bcs && this.bcs.kro && this.bcs.kro[key]) {
            text = text + " - " + this.bcs.kro[key].kk00.ftg;
        }
        return text;
    }

  render() {
    return (
    <div className="web-app">
    <div className="flex-row flex-space-between">
        <div className="flex-row">
            <button className="btn btn-hw-blue w250" onClick={() => this.task_fkn() } aria-label="System" >
                FUNKTIONER
            </button>
        </div>

        <div className="flex"></div>

        <div className="flex-row">
        <button className="btn btn-hw-orange w200" onClick={() => this.task_add() } aria-label="System" >
            LÄGG TILL
        </button>
        <button className="btn btn-hw-blue w200" onClick={() => this.refresh() } aria-label="System" >
            UPPDATERA
        </button>
        </div>
    </div>
    <div className="divider_orange"></div>

        <div id="tsaTable" className="flex">
          <table className="table table-bordered table-hover tb" style={{marginBottom: "0px"}}>
            <thead>
            <tr>
                <th style={ this.th_style("typ") } onClick={() => this.tb_sort("typ") }>Typ</th>
                <th style={ this.th_style("id") } onClick={() => this.tb_sort("id") }>ID</th>
                <th style={ this.th_style("status") } onClick={() => this.tb_sort("status") }>Status</th>
                <th style={ this.th_style("text") } onClick={() => this.tb_sort("text") }>Text</th>
                <th style={ this.th_style("pid") } onClick={() => this.tb_sort("pid") }>Ägare</th>
                <th style={ this.th_style("kid") } onClick={() => this.tb_sort("kid") }>Kund</th>
                <th style={ this.th_style("reg") } onClick={() => this.tb_sort("reg") }>Registrerat</th>
                <th style={ this.th_style("due") } onClick={() => this.tb_sort("due") }>Förfaller</th>
                <th style={ this.th_style("upd") } onClick={() => this.tb_sort("upd") }>Upd</th>
                <th></th>
            </tr>
            </thead>
        </table>
        <div style={ this.tb_style() }>
          <table className="table table-bordered table-hover tb">
            <tbody>
            { this.state.tsa.map((row, key) =>
            <tr key={key} onDoubleClick={() => this.task_edit(row) }>
                <td>{ row.typ }</td>
                <td>{ utils.toNum( row.id ) }</td>
                <td>{ this.stat_txt(row.status) }</td>
                <td>{ utils.toLang( row.text ) }</td>
                <td>{ this.pr_txt(row.pid) }</td>
                <td>{ this.kk_txt(row.kid) }</td>
                <td>{ utils.toTsDate( row.reg ) }</td>
                <td>{ utils.toTsDate( row.due ) }</td>
                <td>{ row.upd }</td>
                <td>
                    <Td_Sel items={ this.selitems } getValue={(e)=> this.sel_row_fkn(row, e)} />
                </td>
            </tr>
            )}
            </tbody>
        </table>
        </div>
    </div>
        { this.state.wnds.taskadd ? <Task_add wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.wnds.taskedit ? <Complete_open wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }

   </div>
  );
    }
}
export default Wrk_Fakt;
