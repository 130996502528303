import React, { useState, useEffect, useContext } from 'react';

import * as utils from "../../../assets/utils";
import Fr_Input from "../../../lib/ui/fr_input";
import * as net from "../../../lib/net";
import Wnd_Info from "../../../lib/ui/wnd_info";
import Wnd_Error from "../../../lib/ui/wnd_error";
import Wnd_Ok from "../../../lib/ui/wnd_ok";
import Wnd_Confirm from "../../../lib/ui/wnd_confirm";

class Prs_Edit extends React.Component {
    constructor(props, context) {
        super(props);

        this.state = {};
        this.state.ynq = [];
        this.state.user = {};
        this.state.tabval = 0;
        this.state.msg = {};
        this.state.wnds = {};

        this.state.fra = [];
        this.state.aa = [];

        this.state.pid = "";
        this.state.kanm = "";
        this.state.eeo = {};
        this.state.eeo.p1 = {};
        this.state.eeo.p2 = {};
        this.state.eeo.p3 = {};
        this.state.eeo.p4 = {};
        if(props.wnds) {
            this.state.pid = props.wnds.pid;
            if(props.wnds.eeo) this.state.eeo = props.wnds.eeo;
        }

        this.state.pdf = 0;
        this.state.pdf_content = null;
        this.state.loading = 0;
    }

    componentDidUpdate() {
    }
    componentWillUnmount() {
    }
    componentDidMount() {
        //this.fak_read();
    }

    dlg_style() {
      var css = {};
      css.width = window.innerWidth * 0.8;
      css.height = window.innerHeight * 0.8;
      return css;
    }
    tab_style(tabid) {
      var css = {};
      css.display = "none";
      if(this.state.tabval === tabid) css.display = "block";
      return css;
    }
    tab_head(tabid) {
      var css = {};
      css.background = "#fff";
      css.color = "#000";
      if(this.state.tabval === tabid) {
          css.background = "#080";
          css.color = "#fff";
      }
      return css;
    }
    save_ee() {
        var self = this;
        var msg = { info: true, text: "Sparar ..."};
        self.setState({msg: msg });

        var prm = {};
        prm.req = "ta.ta_ee.ee_upd";
        prm.p1 = this.state.eeo.p1;
        prm.p2 = this.state.eeo.p2;
        prm.p3 = this.state.eeo.p3;
        prm.p4 = this.state.eeo.p4;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.rca));

                if(ret.ok != "000") {
                    return;
                }
                var msg = { info: false, ok: true, text: "Sparad ..."};
                self.setState({msg: msg });
                setTimeout(function(){
                    self.setState({msg: {} });
                    if(self.props.wnds.cb) {
                        self.props.wnds.cb(self.props.ctx);
                    }
                    else self.props.ctx.setState({ wnds: {} });
                    return;
                }, 1000);
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    remove_ee() {
        var self = this;
        var prm = {};
        prm.req = "ta.ta_ee.ee_del";
        prm.p1 = this.state.eeo.p1;
        prm.p2 = this.state.eeo.p2;
        prm.p3 = this.state.eeo.p3;
        prm.p4 = this.state.eeo.p4;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.rca));

                if(ret.ok != "000") {
                    return;
                }
                var msg = { info: false, ok: true, text: "Borttagen ..."};
                self.setState({msg: msg });
                setTimeout(function(){
                    self.setState({msg: {} });
                    if(self.props.wnds.cb) {
                        self.props.wnds.cb(self.props.ctx);
                    }
                    else self.props.ctx.setState({ wnds: {} });
                    return;
                }, 1000);
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    save_anm() {
        var self = this;
        var prm = {};
        prm.req = "ta.ta_ee.ee_anm_save";
        prm.enr = this.state.eeo.p1.anstnr;
        prm.kamn = this.state.kamn;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.rca));

                if(ret.ok != "000") {
                    return;
                }
                var msg = { info: false, ok: true, text: "Anm Sparad ..."};
                self.setState({msg: msg });
                setTimeout(function(){
                    self.setState({msg: {} });
                    return;
                }, 1000);
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    val_save (name, value) {
        //alert(JSON.stringify(name));
        //alert(JSON.stringify(value));
        var na = name.split(".");
        var eeo = this.state.eeo;
        eeo[na[0]][na[1]] = value;
        this.setState({eeo: eeo});
    }
    val_anm_save (e) {
        //alert(JSON.stringify(name));
        //alert(JSON.stringify(value));
        var kanm = e.target.value;
        this.setState({kanm: kanm});
    }
    tabChange (e) {
        this.setState({tabval: e.index});
    }
    wnd_close() {
        this.props.ctx.setState({ wnds: {} });
    };

    render() {
    //if(!props.show) return "";
    var title = this.props.title;
    if(!title) title = "KUND";
    //if(props.kro) alert(props.kro.krr00.kid);

    var html =
        <div className="mx_base">
            { this.props.backdrop ? <div className="m2_backdrop" /> : null }
            <div className="m2_dlg mx_90 flex-col" style={ this.dlg_style() }>
                <div className="mx_h01 flex-row px-3" style={{background: "#235d91", color: "#fff"}}>
                    <div>ANST NR: { utils.toNum( this.state.eeo.p1.anstnummer ) }</div>
                    <div className="flex"></div>
                    <div onClick={() => this.wnd_close() }>X</div>
                </div>
                <div className="mx_content flex-col flex py-3 px-3">
                    <div className="flex-row">
                        <div className="tab_header flex" style={ this.tab_head(0) } onClick={ () => this.setState({tabval: 0}) }>INFORMATION</div>
                        <div className="tab_header flex hide" style={ this.tab_head(1) } onClick={ () => this.setState({tabval: 1}) }>ANMÄRKNING</div>
                        <div className="tab_header flex" style={ this.tab_head(2) } onClick={ () => this.setState({tabval: 2}) }>EKONOMI</div>
                    </div>
                    <div className="divider_green"></div>

                    <div style={ this.tab_style(0) }>
                        <form className="cdi_f03">
                        <div className="flex-row">
                            <Fr_Input label="Namn" className="text-left w350" text={this.state.eeo.p1.namn} getValue={(e)=> this.val_save("p1.namn", e) } />
                            <Fr_Input label="Personnr" className="text-left w250" text={this.state.eeo.p1.fodnummer} getValue={(e)=> this.val_save("p1.fodnummer", e) } />
                        </div>
                        <div className="flex-row">
                            <Fr_Input label="Kort" className="text-left w250" text={this.state.eeo.p1.kort} getValue={(e)=> this.val_save("p1.kort", e) } />
                        </div>
                        <div className="flex-row">
                            <Fr_Input label="Telefon" className="text-left w250" text={this.state.eeo.p3.Telefon} getValue={(e)=> this.val_save("p3.Telefon", e) } />
                            <Fr_Input label="Mobil" className="text-left w250" text={this.state.eeo.p4.Telefon} getValue={(e)=> this.val_save("p4.Telefon", e) } />
                        </div>
                        <div className="flex-row">
                            <Fr_Input label="Email" className="text-left w350" text={this.state.eeo.p4.mailadress} getValue={(e)=> this.val_save("p4.mailadress", e) } />
                        </div>
                            <div className="divider_green"></div>

                            <div className="flex-row">
                                <Fr_Input label="Avdelning" className="text-left w250" text={this.state.eeo.p1.avdelning} getValue={(e)=> this.val_save("p1.avdelning", e) } />
                                <Fr_Input label="Kategori" className="text-left w250" text={this.state.eeo.p2.kategori} getValue={(e)=> this.val_save("p2.kategori", e) } />
                            </div>
                            <div className="flex-row">
                                <Fr_Input label="Schema Datum" className="text-left w250" text={this.state.eeo.p2.schedat} getValue={(e)=> this.val_save("p2.schedat", e) } />
                                <Fr_Input label="Arbschema" className="text-left w250" text={this.state.eeo.p1.arbschema} getValue={(e)=> this.val_save("p1.arbschema", e) } />
                            </div>
                            <div className="flex-row">
                                <Fr_Input label="Start Datum" className="text-left w250" text={this.state.eeo.p2.startdat} getValue={(e)=> this.val_save("p2.startdat", e) } />
                                <Fr_Input label="Slut Datum" className="text-left w250" text={this.state.eeo.p2.prssdat} getValue={(e)=> this.val_save("p2.prssdat", e) } />
                            </div>
                            <div className="divider_green"></div>

                            <div className="flex-row">
                                <Fr_Input label="Initialer" className="text-left w250" text={this.state.eeo.p2.initialer} getValue={(e)=> this.val_save("p2.initialer", e) } />
                                <Fr_Input label="Annat anstnr" className="text-left w250" text={this.state.eeo.p2.annatanstnr} getValue={(e)=> this.val_save("p2.annatanstnr", e) } />
                            </div>
                            <div className="flex-row">
                                <Fr_Input label="Bakgrund" className="text-left w250" text={this.state.eeo.p4.bgfarg} getValue={(e)=> this.val_save("p4.bgfarg", e) } />
                                <Fr_Input label="Kvalitet" className="text-left w250" text={this.state.eeo.p4.kvalitet} getValue={(e)=> this.val_save("p4.kvalitet", e) } />
                            </div>

                        </form>
                    </div>

                    <div style={ this.tab_style(1) }>
                        <form className="cdi_f03">
                        <div className="form-group">
                            <label>Anmärkning</label>
                            <textarea rows="10" className="form-control" value={ utils.toLang( this.state.kanm )} onChange={(event) => this.val_anm_save(event)} />
                        </div>
                        <div className="form-group">
                            <div className="txt_18" onClick={() => this.save_anm() }>Spara Anmärkning</div>
                        </div>
                    </form>
                </div>

                    <div style={ this.tab_style(2) }>
                        <form className="cdi_f03">

                            <div className="flex-row">
                                <Fr_Input label="Bank" className="text-left w350" text={this.state.eeo.p3.Bank} getValue={(e)=> this.val_save("p3.Bank", e) } />
                                <Fr_Input label="Konto" className="text-left w250" text={this.state.eeo.p3.Konto} getValue={(e)=> this.val_save("p3.Konto", e) } />
                            </div>
                            <div className="flex-row">
                                <Fr_Input label="Timlön" className="text-left w250" text={this.state.eeo.p1.timlon} getValue={(e)=> this.val_save("p1.timlon", e) } />
                                <Fr_Input label="Månadslön" className="text-left w250" text={this.state.eeo.p1.manlon} getValue={(e)=> this.val_save("p1.manlon", e) } />
                            </div>
                            <div className="flex-row">
                                <Fr_Input label="Skattesats" className="text-left w250" text={this.state.eeo.p2.taxproc} getValue={(e)=> this.val_save("p2.taxproc", e) } />
                            </div>
                            <div className="divider_green"></div>

                            <div className="flex-row">
                                <Fr_Input label="Tidanledning" className="text-left w150" text={this.state.eeo.p1.anledning} getValue={(e)=> this.val_save("p1.anledning", e) } />
                                <Fr_Input label="Justera tid" className="text-left w150" text={this.state.eeo.p2.justtid} getValue={(e)=> this.val_save("p2.justtid", e) } />
                            </div>
                            <div className="flex-row">
                                <Fr_Input label="Matavdrag" className="text-left w150" text={this.state.eeo.p2.ejmat} getValue={(e)=> this.val_save("p2.ejmat", e) } />
                                <Fr_Input label="Avdelnings val" className="text-left w150" text={this.state.eeo.p2.avdkrav} getValue={(e)=> this.val_save("p2.avdkrav", e) } />
                            </div>
                            <div className="flex-row">
                                <Fr_Input label="OB" className="text-left w250" text={this.state.eeo.p2.prsejob} getValue={(e)=> this.val_save("p2.prsejob", e) } />
                                <Fr_Input label="Natt OB" className="text-left w250" text={this.state.eeo.p2.prsejnob} getValue={(e)=> this.val_save("p2.prsejnob", e) } />
                            </div>

                        </form>
                    </div>
                </div>
                <div className="mx_foot flex-row">
                    <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                        STÄNG
                    </button>
                    <div className="flex"></div>
                    <button className="btn btn-hw-green w250" onClick={(event) => this.remove_ee() } >
                        TA BORT
                    </button>
                    <div className="flex"></div>
                    <button className="btn btn-hw-green w250" onClick={(event) => this.save_ee() } >
                        SPARA
                    </button>
                </div>
            </div>
            <Wnd_Info msg={this.state.msg} ctx={this} />
            <Wnd_Error msg={this.state.msg} ctx={this} />
            <Wnd_Ok msg={this.state.msg} ctx={this} />
            <Wnd_Confirm msg={this.state.msg} ctx={this} />
        </div>
    ;
    return html;
    }
}

export default Prs_Edit;