import React, { useState, useEffect, useContext } from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import { observer } from 'mobx-react'

import Bfs_Top from './com/bfs_top.js';
import Bfs_Mnu from './com/bfs_mnu.js';
import Bfs_Foot from './com/bfs_foot.js';

import Bfs_Home from './home.js';
import Bfs_Dash from './dash.js';

import Bfs_Bf from './bf.js';
import Bfs_Wrk from './wrk.js';
import Bfs_Rps from './rps.js';
import Bfs_Reg from './reg.js';
import Bfs_Bo from './bo.js';

import * as net from "../lib/net";
import Tiden from "../lib/ui/tiden";
import {withRouter} from "../lib/react/withRouter";
import Bf_Home from "./bf/home";

function web01() {

    var html =
    <div className="web-content">
    </div>
    ;
    return html;
}

class Bfs extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.bfs = props.store.bfs;
    this.state = {};
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {

    var bfs = {};
      bfs.kro = {};
      bfs.vgrps = [];
    this.props.store.bfs = bfs;
    //alert(JSON.stringify(bfs));
    //this.bfs_init();
  }

  fknGo(url) {
    //var sdo = useContext(SDO);
    //alert(fkn);
    //var fkn = row.fid;
    //setState({ row : row,  ma : [] });
    //ctx.fid = fkn;

    //props.history.push(row.url);
    this.props.navigate(url);
   };

  ddo() {
    //alert("POS" + this.state.pgid );
    //this.setState({ status: 1 });
  }

    bfs_init() {
    var self = this;

	var req = {};
    req.req = "bfs.kreg.kks";
    req.typ = "K";

    //gda.wndLoading("Hämtar");
    net.sio_req(req)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        //var pos = {};
        //pos.deps = ret.rco.deps;
        //self.props.store.pos = pos;
        if(!self.props.store.bcs) self.props.store.bcs = {};
        self.props.store.bcs.kro = ret.rco;
        //self.props.store.bcs.vgrps = ret.rco.vgrps;

        //self.setState({ deps: ret.rco });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //alert(JSON.stringify(txt));
    })
    }

  render() {

    return (
    <div className="web-app">
    <div className="web-top">
        <Bfs_Top store={this.store} />
    </div>
    <div className="web-main">

    <div className="web-row" style={{ height:"100%" }}>
    <div className="web-side">
        <Bfs_Mnu store={this.store} />

    </div>

    <div className="web-body">
          <Routes>
              <Route path="bf/*" element={ <Bfs_Bf store={this.store} />} />
              <Route path="wrk/*" element={ <Bfs_Wrk store={this.store} />} />
              <Route path="rps/*" element={ <Bfs_Rps store={this.store} />} />
              <Route path="reg/*" element={ <Bfs_Reg store={this.store} />} />
              <Route path="bo/*" element={ <Bfs_Bo store={this.store} />} />
              <Route path="home" element={ <Bfs_Home store={this.store} />} />
              <Route path="*" element={ <Bfs_Dash store={this.store} />} />
          </Routes>
    </div>

    </div>

    </div>
        <Bfs_Foot store={this.store} />

    </div>
  );
}
}
//Sys.contextType = ADOContext;

export default withRouter(observer(Bfs));
