import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../../lib/net";
import Fr_Input from "../../../lib/ui/fr_input";
import Fr_Inpsel from "../../../lib/ui/fr_inpsel";
import Fr_Cal from "../../../lib/ui/fr_cal";
import {format} from "date-fns";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Row_Art from "./row_art";

class Row_Add extends React.Component {
  constructor(props, context) {
    super(props);

    this.state = {};
    this.state.today = new Date();
    this.state.mka = [];
    this.state.sval = [];
    this.state.tval = [];
    this.state.paa = [];
    this.state.uaa = [];
    this.state.laa = [];

    this.state.gnr = "001";
    this.state.ynq = [
                {id: '0', text: 'NEJ'},
                {id: '1', text: 'JA'}
            ];
      this.state.mexm = [
          {val: '0', text: 'INK MOMS'},
          {val: '1', text: 'EX MOMS'}
      ];
      this.state.mval = [
          {val: '0000', text: 'MOMSFRITT'},
          {val: '0566', text: 'MOMS 6 %'},
          {val: '1071', text: 'MOMS 12 %'},
          {val: '2000', text: 'MOMS 25 %'}
      ];
      this.state.mtkn = [
          {val: '+', text: '+ : DEBIT'},
          {val: '-', text: '- : KREDIT'}
      ];
      this.state.paa = [
          { plu: '1001', text: 'MAT', pris: '' },
          { plu: '1002', text: 'DRYCK', pris: '' }
      ];

    this.state.wnd_ok = false;
    this.state.wnd_err = false;

      this.state.wnds = {};
      this.state.cols = {};
    this.state.tr00 = {};
    this.state.faknr = "";
    this.state.wfr = {};
    if(props.wnds) {
        this.state.faknr = props.wnds.faknr;
        //this.state.wfr = props.wnds.wfr;
        //this.state.wfr = JSON.parse(JSON.stringify(props.wnds.wfr));
    }
      if(!this.props.wnds.wfr) {
          var wfr = {};
          wfr.faktyp = "I";
          wfr.faknr = this.state.faknr;
          wfr.fakrad = "P";
          wfr.fakradnr = "001";
          wfr.plu = "";
          wfr.text = "";
          wfr.antal = "1";
          wfr.pris = "";
          wfr.summa = "";
          wfr.moms = "2000";
          wfr.notanr = "";
          wfr.tecken = "+";
          wfr.kassal = "";
          wfr.datum = format(this.state.today, "yyyy-MM-dd");
          wfr.radrab = "";
          wfr.radmoms = "";
          wfr.exmoms = "0";
          wfr.filler = "";
          wfr.time = "";
          this.state.wfr = wfr;
      }
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.btn_grps();
      //if(!this.props.wnds.wfr) this.wfr_init();
  }

    wfr_init() {
        var wfr = {};
        wfr.faktyp = "I";
        wfr.faknr = this.state.faknr;
        wfr.fakrad = "P";
        wfr.fakradnr = "001";
        wfr.plu = "";
        wfr.text = "";
        wfr.antal = "1";
        wfr.pris = "";
        wfr.summa = "";
        wfr.moms = "2000";
        wfr.notanr = "";
        wfr.tecken = "+";
        wfr.kassal = "";
        wfr.datum = format(this.state.today, "yyyy-MM-dd");
        wfr.radrab = "";
        wfr.radmoms = "";
        wfr.exmoms = "0";
        wfr.filler = "";
        wfr.time = "";
        //alert(JSON.stringify(rso));
        this.setState({ wfr: wfr });
        return;
    }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }

    wnd_close = () => {
      if(this.props.close) {
          this.props.close(this.props.ctx);
      }
      else this.props.ctx.setState({ wnds: {} });
	};
    toggle() {
      this.setState({modal: false});
	};

  submitHandler = event => {
    event.preventDefault();
    event.target.className += " was-validated";
  };

    changeHandler = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    val_save(id, val) {
        var wfr = this.state.wfr;
        wfr[id] = val;
        this.setState({ wfr: wfr });
        return;
    }
    val_dt_save (id, dts) {
        var wfr = this.state.wfr;
        //var dts = format(value, "yyyy-MM-dd");
        //if(dts < this.state.tds) dts = this.state.tds;
        //bro.fdat = dts;
        //if(bro.tdat < dts) bro.tdat = dts;
        wfr[id] = dts;
        this.setState({ wfr: wfr });
    }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
    fak_row_save() {
        var self = this;

        var wfr = this.state.wfr;
        if(wfr.text.trim() == "") {
            alert("Text saknas");
            return;
        }

        var rc = wfr.pris.indexOf(".");
        if(rc != -1) wfr.pris = wfr.pris.replace(".","");
        else wfr.pris = wfr.pris + "00";

        var antal = (wfr.antal * 1);
        var pris = (wfr.pris * 1);
        var belopp = antal * pris;
        var mbelopp = 0;
        if(wfr.exmoms != "1") {
            var mproc = 0;
            if(wfr.moms == "0600") mproc = 566;
            if(wfr.moms == "1200") mproc = 1071;
            if(wfr.moms == "2500") mproc = 2000;
            mbelopp = (belopp * mproc) / 10000;
        }
        var summa = belopp + mbelopp;
        wfr.antal = antal.toString();
        wfr.pris = pris.toString();
        wfr.summa = summa.toString();

        var prm = {};
        prm.req = "fakt.fakt_fak.fak_row_add";
        prm.faknr = this.state.faknr;
        prm.wfak04 = this.state.wfr;

        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret));

            var wfr = JSON.parse(JSON.stringify(self.state.wfr));
            self.setState({ wfr: wfr, wnd_ok: true });

            setTimeout(function(){
                self.setState({wnd_ok: false });
                if(self.props.wnds.cb) {
                    self.props.wnds.cb(self.props.ctx);
                }
                else self.props.ctx.setState({ wnds: {} });
                return;
            }, 1000);
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }
    art_sz() {
        var self = this;
        //alert(JSON.stringify( row ));

        var wnds = {};
        wnds.rowart = 1;
        wnds.cb = self.cb_art;
        this.setState({ wnds: wnds });
    }
    cb_art(ctx, row) {
        if(row) {
            var wfr = ctx.state.wfr;
            wfr.plu = row.artnr;
            wfr.text = row.text;
            wfr.antal = "1";
            wfr.pris = "";
            if(row.pris) wfr.pris = row.pris.cdipris();
            wfr.summa = "";
            wfr.moms = row.moms;
            wfr.notanr = "";
            wfr.tecken = "+";
            ctx.setState({ wfr: wfr,  wnds: {} });
        }
        else ctx.setState({ wnds: {} });
    }

  render() {
    //if(!this.props.show) return "";
    var title = this.props.title;
    if(!title) title = "Resurs";
    //if(props.kro) alert(props.kro.krr00.kid);
    const popover = {
      position: 'absolute',
      top: '50px',
      zIndex: '2',
    }
    const cover = {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    }
    const okwnd = {
      position: 'absolute',
      width: '35%',
      height: '35%',
      zIndex: '19999',
      top: '35%',
      left: '35%',
        border: "1px solid #ccc",
        borderRadius: "5px",
       backgroundColor: "#015d00",
        backgroundImage: "linear-gradient(314deg, #015d00 0%, #04bf00 74%)"
    }
    const errwnd = {
      position: 'absolute',
      width: '35%',
      height: '35%',
      zIndex: '19999',
      top: '35%',
      left: '35%',
        border: "1px solid #ccc",
        borderRadius: "5px",
        backgroundColor: "#ED213A",  /* fallback for old browsers */
        background: "-webkit-linear-gradient(to right, #93291E, #ED213A)",  /* Chrome 10-25, Safari 5.1-6 */
        backgroundImage: "linear-gradient(to right, #93291E, #ED213A)" /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        //backgroundColor: "#8E0E00",  /* fallback for old browsers */
        //background: "-webkit-linear-gradient(to right, #1F1C18, #8E0E00)",  /* Chrome 10-25, Safari 5.1-6 */
        //backgroundImage: "linear-gradient(to right, #1F1C18, #8E0E00)" /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    }

	var html =
        <div className="mx_base">
        <div className="m2_backdrop" />
        <div className="m2_dlg mx_70 flex-col" style={ this.dlg_style() } >
        <div className="mx_content flex">

        <div className="mx_h01">
            <div>FAKTURA NR</div>
            <div> : { this.state.faknr.ztrim() }</div>
        </div>
            <div className="flex-row" style={{padding:"5px",height:"35px"}}>
                    <div className="flex-col flex">
                    </div>
                    <div className="flex-col">
                        <div>SKAPA</div>
                    </div>
            </div>
            <div className="divider_orange"></div>

            <div className="cdi_f03">
            <div className="flex-row">
                    <Fr_Cal label="Datum" className="text-left w250" min="0" text={this.state.wfr.datum} getValue={(e)=> this.val_dt_save( "datum", e ) }/>
                    <Fr_Input label="Kassa" className="text-left w150" text={this.state.wfr.kassal} getValue={(e)=> this.val_save("kassal", e) } />
            </div>
            <div className="divider_green"></div>
            <div className="flex-row">
                <Fr_Input label="Plu" className="text-left w250" text={this.state.wfr.plu} getValue={(e)=> this.val_save("plu", e) } />
                <button className="web-link w150" onClick={() => this.art_sz() } aria-label="System" >
                    SÖK
                </button>
            </div>
            <div className="flex-row">
                <Fr_Input label="Text" className="text-left w350" text={this.state.wfr.text} getValue={(e)=> this.val_save("text", e) } />
            </div>
            <div className="flex-row">
                <Fr_Input label="Antal" className="text-left w250" text={this.state.wfr.antal} getValue={(e)=> this.val_save("antal", e) } />
                <Fr_Inpsel label="Tecken" className="text-left w250"
                           text={this.state.wfr.tecken}
                           items={this.state.mtkn}
                           getValue={(e)=> this.val_save("tecken", e) }
                />
            </div>
            <div className="flex-row">
                    <Fr_Input label="Pris" className="text-left w250" text={this.state.wfr.pris} getValue={(e)=> this.val_save("pris", e) } />
                    <Fr_Inpsel label="Moms" className="text-left w250"
                               text={this.state.wfr.moms}
                               items={this.state.mval}
                               getValue={(e)=> this.val_save("moms", e) }
                    />
            </div>
                <div className="flex-row">
                    <Fr_Inpsel label="Exmoms" className="text-left w250"
                               text={this.state.wfr.exmoms}
                               items={this.state.mexm}
                               getValue={(e)=> this.val_save("exmoms", e) }
                    />
                </div>
            <div className="divider_green"></div>
            <div className="flex-row hide">
                    <Fr_Input label="Radrabatt" className="text-left w250" text={this.state.wfr.radrab} getValue={(e)=> this.val_save("radrab", e) } />
                    <Fr_Input label="Radmoms" className="text-left w250" text={this.state.wfr.radmoms} getValue={(e)=> this.val_save("radmoms", e) } />
            </div>
            </div>

        </div>
        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex"></div>
            <button className="btn btn-hw-red w250" onClick={() => this.fak_row_save() } >
                SPARA
            </button>
        </div>
      </div>
            { this.state.wnds.rowart ? <Row_Art wnds={this.state.wnds} ctx={this} backdrop={true}/> : null }

        { this.state.wnd_ok ?
            <div style={ okwnd } className="d-flex flex-column align-content-center justify-content-center text-center" >
                    <h2 className="" style={{color: "#fff"}}>SPARAT</h2>
                    <FontAwesomeIcon className="" icon="check" size="4x" style={{color: "#fff"}} />
            </div>
            : null }
            { this.state.wnd_err ?
                <div style={ errwnd } className="d-flex flex-column align-content-center justify-content-center text-center" >
                        <h2 className="" style={{color: "#fff"}}>FEL</h2>
                        <FontAwesomeIcon className="" icon="exclamation" size="4x" style={{color: "#fff"}} />
                </div>
                : null }
      </div>
	;
	return html;
  };
}

export default Row_Add;