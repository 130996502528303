import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../../lib/net";
import Fr_Input from "../../../lib/ui/fr_input";
import Fr_Select from "../../../lib/ui/fr_select";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Dep_Edit extends React.Component {
  constructor(props, context) {
    super(props);

    this.pos = props.store.pos;
    this.state = {};
    this.state.mka = [];
    this.state.sval = [];
    this.state.tval = [];
    this.state.paa = [];
    this.state.uaa = [];
    this.state.laa = [];

    this.state.gnr = "001";
    //this.state.lro = props.lro;
    this.state.ynq = [
                {id: '0', text: 'NEJ'},
                {id: '1', text: 'JA'}
            ];

    this.state.wnd_ok = false;
    this.state.wnd_err = false;

    this.state.cols = {};
    this.state.tr00 = {};
    this.state.dpo = {};
    if(props.wnds.dpo) {
        this.state.dpo = props.wnds.dpo;
        this.state.dpo = JSON.parse(JSON.stringify(props.wnds.dpo));
    }
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.btn_grps();
      if(!this.props.wnds.dpo) this.dpo_init();
  }

    dpo_init() {
        var dpo = {};
        dpo.avdelning = "";
        dpo.namn = "";
        dpo.status = "";
        //alert(JSON.stringify(rso));
        this.setState({ dpo: dpo });
        return;
    }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }

    formchange (e) {
      const name = e.target.name;
      const value = e.target.value;
      var pbo = this.state.pbo;
      var na = name.split(".");
      pbo[na[0]][na[1]] = value;
      this.setState({pbo: pbo});
      //setState({[name]: value});
    }

    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    wnd_close = () => {
      if(this.props.close) {
          this.props.close(this.props.ctx);
      }
      else this.props.ctx.setState({ wnds: {} });
	};
    toggle() {
      this.setState({modal: false});
	};

  submitHandler = event => {
    event.preventDefault();
    event.target.className += " was-validated";
  };

  changeHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  val_save(id, val) {
      var dpo = this.state.dpo;
      dpo[id] = val;
        this.setState({ dpo: dpo });
      return;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }

  dep_save() {
    var self = this;
    var dpo = this.state.dpo;
    dpo.namn = dpo.namn.fromswe();

    var prm = {};
    prm.req = "ta.ta_def.dep_upd";
    prm.dpo = this.state.dpo;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));
        if(ret.ok == "000") {

            var dpo = JSON.parse(JSON.stringify(self.state.dpo));
            self.setState({ dpo: dpo, wnd_ok: true });

            setTimeout(function(){
                self.setState({wnd_ok: false });
                  if(self.props.close) {
                      self.props.close(self.props.ctx);
                  }
                  else self.props.ctx.setState({ wnds: {} });
                return;
            }, 1000);
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

  dep_delete() {
    var self = this;
    var dpo = this.state.dpo;
    dpo.namn = dpo.namn.fromswe();

    var prm = {};
    prm.req = "ta.ta_def.dep_delete";
    prm.dpo = this.state.dpo;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));
        if(ret.ok == "000") {

            var dpo = JSON.parse(JSON.stringify(self.state.dpo));
            self.setState({ dpo: dpo, wnd_ok: true });

            setTimeout(function(){
                self.setState({wnd_ok: false });
                  if(self.props.close) {
                      self.props.close(self.props.ctx);
                  }
                  else self.props.ctx.setState({});
                return;
            }, 1000);
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }


  render() {
    //if(!this.props.show) return "";
    var title = this.props.title;
    if(!title) title = "Resurs";
    //if(props.kro) alert(props.kro.krr00.kid);
    const popover = {
      position: 'absolute',
      top: '50px',
      zIndex: '2',
    }
    const cover = {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    }
    const okwnd = {
      position: 'absolute',
      width: '35%',
      height: '35%',
      zIndex: '19999',
      top: '35%',
      left: '35%',
        border: "1px solid #ccc",
        borderRadius: "5px",
       backgroundColor: "#015d00",
        backgroundImage: "linear-gradient(314deg, #015d00 0%, #04bf00 74%)"
    }
    const errwnd = {
      position: 'absolute',
      width: '35%',
      height: '35%',
      zIndex: '19999',
      top: '35%',
      left: '35%',
        border: "1px solid #ccc",
        borderRadius: "5px",
        backgroundColor: "#ED213A",  /* fallback for old browsers */
        background: "-webkit-linear-gradient(to right, #93291E, #ED213A)",  /* Chrome 10-25, Safari 5.1-6 */
        backgroundImage: "linear-gradient(to right, #93291E, #ED213A)" /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        //backgroundColor: "#8E0E00",  /* fallback for old browsers */
        //background: "-webkit-linear-gradient(to right, #1F1C18, #8E0E00)",  /* Chrome 10-25, Safari 5.1-6 */
        //backgroundImage: "linear-gradient(to right, #1F1C18, #8E0E00)" /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    }

	var html =
        <div className="mx_base">
        <div className="m2_backdrop" />
        <div className="m2_dlg mx_70 flex-col" style={ this.dlg_style() } draggable="true" >
        <div className="mx_content flex">

        <div className="mx_h01">
            <div>AVDELNING</div>
            <div>: { this.state.dpo.avdelning.ztrim()  }</div>
        </div>
            <div className="flex-row" style={{padding:"5px",height:"35px"}}>
                    <div className="flex-col flex">
                    </div>
                    <div className="flex-col">
                        <div>UPPDATERA</div>
                    </div>
            </div>
            <div className="divider_orange"></div>

            <form className="cdi_f03">
            <div className="flex-row">
                    <Fr_Input label="Avdelning" className="text-left w150" text={this.state.dpo.avdelning} getValue={(e)=> this.val_save("avdelning", e) } />
                    <Fr_Input label="Orgnr" className="text-left w250" text={this.state.dpo.orgnr} getValue={(e)=> this.val_save("orgnr", e) } />
            </div>
            <div className="flex-row">
                    <Fr_Input label="Namn" className="text-left w350" text={this.state.dpo.namn} getValue={(e)=> this.val_save("namn", e) } />
            </div>
            <div className="divider_green"></div>
            <div className="flex-row">
                    <Fr_Input label="Nattob" className="text-left w150" text={this.state.dpo.nattob} getValue={(e)=> this.val_save("nattob", e) } />
                    <Fr_Input label="Restavd" className="text-left w150" text={this.state.dpo.restavd} getValue={(e)=> this.val_save("restavd", e) } />
            </div>
            <div className="flex-row">
                    <Fr_Input label="Rest visa" className="text-left w150" text={this.state.dpo.restvisa} getValue={(e)=> this.val_save("restvisa", e) } />
            </div>
                <div className="divider_green"></div>
                <div className="flex-row">
                    <Fr_Input label="Hotell Avd" className="text-left w150" text={this.state.dpo.pmsavd} getValue={(e)=> this.val_save("pmsavd", e) } />
                    <Fr_Input label="Rest Avd" className="text-left w150" text={this.state.dpo.posavd} getValue={(e)=> this.val_save("posavd", e) } />
                </div>
            <div className="divider_green"></div>
            <div className="flex-row">
                    <Fr_Input label="Färg" className="text-left w250" text={this.state.dpo.farg} getValue={(e)=> this.val_save("farg", e) } />
            </div>

            </form>

        </div>
        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex"></div>
            <button className="btn btn-hw-red w250" onClick={() => this.dep_delete() } >
                TA BORT
            </button>
            <div className="flex"></div>
            <button className="btn btn-hw-red w250" onClick={() => this.dep_save() } >
                SPARA
            </button>
        </div>
      </div>

        { this.state.wnd_ok ?
            <div style={ okwnd } className="d-flex flex-column align-content-center justify-content-center text-center" >
                    <h2 className="" style={{color: "#fff"}}>SPARAT</h2>
                    <FontAwesomeIcon className="" icon="check" size="4x" style={{color: "#fff"}} />
            </div>
            : null }
            { this.state.wnd_err ?
                <div style={ errwnd } className="d-flex flex-column align-content-center justify-content-center text-center" >
                        <h2 className="" style={{color: "#fff"}}>FEL</h2>
                        <FontAwesomeIcon className="" icon="exclamation" size="4x" style={{color: "#fff"}} />
                </div>
                : null }
      </div>
	;
	return html;
  };
}

export default Dep_Edit;