import React, { useState, useEffect, useContext } from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import * as net from "../../../lib/net";
import Fr_Input from "../../../lib/ui/fr_input";
import Fr_Select from "../../../lib/ui/fr_select";
import * as utils from "../../../assets/utils";

class Fsk_Edit extends React.Component {
  constructor(props, context) {
    super(props);

    this.pos = props.store.pos;
    this.state = {};
    this.state.mka = [];
    this.state.sval = [];
    this.state.tval = [];
    this.state.paa = [];
    this.state.uaa = [];
    this.state.laa = [];

    this.state.sz_val = "";
      this.state.gnr = "001";
    //this.state.lro = props.lro;
    this.state.ynq = [
                {id: '0', text: 'NEJ'},
                {id: '1', text: 'JA'}
            ];

    this.state.cols = {};
      this.state.fso = {};
      this.state.fso.fsk00 = {};
      this.state.fso.fsk01 = {};
    if(props.wnds.fso) {
        this.state.fso = props.wnds.fso;
    }
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      if(!this.props.wnds.fso) this.fsk_init();
  }

    preg_init() {
        var fso = {};
        fso.e1bastyp = "";
        fso.e1typ = "";
        //alert(JSON.stringify(rso));
        this.setState({ fso: fso });
        return;
    }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }

    formchange (e) {
      const name = e.target.name;
      const value = e.target.value;
      var pbo = this.state.pbo;
      var na = name.split(".");
      pbo[na[0]][na[1]] = value;
      this.setState({pbo: pbo});
      //setState({[name]: value});
    }

    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    wnd_close = () => {
      if(this.props.close) {
          this.props.close(this.props.ctx);
      }
      else this.props.ctx.setState({ wnds: {} });
	};
    handleChange = (event, newValue) => {
        //alert(newValue);
        this.setState({ix: newValue});
      };
    toggle() {
      this.setState({modal: false});
	};

  submitHandler = event => {
    event.preventDefault();
    event.target.className += " was-validated";
  };

  changeHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

    val_save(id, val) {
        var fso = this.state.fso;
        var f0 = id.split(".")[0];
        var f1 = id.split(".")[1];
        fso[f0][f1] = val;
        //alert( JSON.stringify( val ) );
        this.setState({ fso: fso });
        return;
    }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  cssCols (cid) {
      var css = {};
      if(this.state.cols[cid]) css.width = this.state.cols[cid].toString() + "px";
      //if(cid == "c01") alert(JSON.stringify(this.state.cols[cid]));
      return css;
  }
  fsk_save() {
    var self = this;
    var prm = {};
    prm.req = "pms.fsk.fsk_upd";
    prm.fso = this.state.fso;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));
        if(ret.ok == "000") {
            self.setState({wnd_ok: true });
            setTimeout(function(){
                self.setState({wnd_ok: false });
                if(self.props.wnds.cb) {
                    self.props.wnds.cb(self.props.ctx);
                }
                else self.props.ctx.setState({ wnds: {} });
                return;
            }, 1000);
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  fsk_del() {
    var self = this;
    var prm = {};
    prm.req = "pms.fsk.fsk_del";
    prm.fso = this.state.fso;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));
        if(ret.ok == "000") {
            self.setState({wnd_ok: true });
            setTimeout(function(){
                self.setState({wnd_ok: false });
                  if(self.props.close) {
                      self.props.close(self.props.ctx);
                  }
                  else self.props.ctx.setState({ wnd_rs: false });
                return;
            }, 1000);
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
    sz_keydown (e) {
        //e.preventDefault();
        const key = e.key;
        const keyCode = e.keyCode;

        if(keyCode == 13) {
            this.plu_sz();
        };
        if(keyCode == 27) {
            this.setState({sz_val: ""});
        };
    }
    sz_change (e) {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({sz_val: value});
        //setState({[name]: value});
    }

  render() {
    //if(!this.props.show) return "";
    var title = this.props.title;
    if(!title) title = "UDID";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        <div className="m2_backdrop" />
        <div className="m2_dlg mx_70 flex-col" style={ this.dlg_style() } >
        <div className="mx_top flex-row">

            <div className="txt-22">
                <div>FÖRSKOTT: { utils.toNum( this.state.fso.fsk00.f0id ) }</div>
            </div>
            <div className="flex"></div>
            <div>UPPDATERA</div>
        </div>
            <div className="divider_orange"></div>

            <form className="cdi_f03">
              <div className="flex-row">
                        <Fr_Input label="Bokningsnr" className="text-left w150" text={ this.state.fso.fsk00.f0boknr } getValue={(e)=> this.val_save("fsk00.f0boknr", e) } />
                </div>
                <div className="flex-row">
                        <Fr_Input label="Text" className="text-left w350" text={ this.state.fso.fsk00.f0text } getValue={(e)=> this.val_save("fsk00.f0text", e) } />
                </div>
                <div className="flex-row">
                    <Fr_Input label="Skapat" className="text-left w150" text={ this.state.fso.fsk00.f0datum } getValue={(e)=> this.val_save("fsk00.f0datum", e) } />
                </div>
                <div className="flex-row">
                    <Fr_Input label="Belopp" className="text-right w250" text={utils.toPris( this.state.fso.fsk00.f0belopp )} getValue={(e)=> this.val_save("fsk00.f0belopp", e) } />
                </div>
                <div className="flex-row">
                    <Fr_Input label="Saldo" className="text-right w250" text={utils.toPris( this.state.fso.fsk00.f0saldo )} getValue={(e)=> this.val_save("fsk00.f0saldo", e) } />
                </div>
            </form>

            <div className="mx_section">

                <form className="cdi_f03">
                <div className="divider_green"></div>
                <div className="flex-row">
                    <Fr_Input label="Skapad av" className="text-left w250" text={this.state.fso.fsk01.f1issuedby} getValue={(e)=> this.val_save("fsk01.f1issuedby", e) } />
                </div>
                <div className="flex flex-column" style={{padding: "0px 5px"}}>
                    <span className="header">Anmärkning</span>
                    <textarea label="Anm" className="form-control" maxLength="512" rows="5" value={this.state.fso.fsk01.f1anm} onChange={(e) => this.val_save("fsk01.f1anm", e.target.value)} ></textarea>
                </div>
                </form>

            </div>
            <div className="flex"></div>

        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <button className="btn btn-hw-orange w250" onClick={() => this.fsk_del() } >
                TA BORT
            </button>
            <div className="flex-row">
            <button className="btn btn-hw-green w250" onClick={() => this.fsk_save() } >
                SPARA
            </button>
            </div>
        </div>
      </div>

      </div>
	;
	return html;
  };
}

export default Fsk_Edit;