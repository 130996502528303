import React, { useState, useEffect, useContext } from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {observer} from "mobx-react";

import * as srs from "../lib/srs";
import * as net from "../lib/net";
import * as fkn from "../lib/fkn";
import Tiden from "../lib/ui/tiden";

import Fakt_Top from './com/fakt_top.js';
import Fakt_Menu from './com/fakt_mnu.js';
import Fakt_Home from './home.js';
import Fakt_Dash from './dash.js';

import Fakt_Kreg from './kreg.js';
import Fakt_Fo from './fo.js';
import Fakt_Rsk from './rsk.js';
import Fakt_Und from './und.js';
import Fakt_Bo from './bo.js';
import Pos_Dash from "../pos/dash";
import {withRouter} from "../lib/react/withRouter";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function pg_home() {

    var html =
    <div className="web-content">
      Hem
    </div>
    ;
    return html;
}
function pg_s1() {

    var html =
    <div className="">
      S1
    </div>
    ;
    return html;
}
function pg_s2() {
    var units = [];
    units.push( {"id": "01", "unit": "u01", "text": "Tst 01"} );
    units.push( {"id": "02", "unit": "u02", "text": "Tst 02"} );

    var html =
    <div className="">
        <table className="table table-bordered table-striped table-hover">
            <thead>
            <tr>
                <th>ID</th>
                <th>Enhet</th>
                <th>Text</th>
                <th>Adress</th>
                <th>Postnr</th>
                <th>Stad</th>
                <th>Tel</th>
                <th>Öppet</th>
                <th>Status</th>
            </tr>
            </thead>
            <tbody>
            { units.map((row, key) =>
                <tr ondblclick={() => this.unit_open(row)}>
                <td>{ row.id }</td>
                <td>{ row.unit }</td>
                <td>{ row.text }</td>
                <td>{ row.adress }</td>
                <td>{ row.postnr }</td>
                <td>{ row.stad }</td>
                <td>{ row.tele }</td>
                <td>{ row.open }</td>
                <td>{ row.status }</td>
                </tr>
            )}
            </tbody>
        </table>
    </div>
    ;
    return html;
}
function pg_trans() {
    var units = [];
    units.push( {"id": "01", "unit": "u01", "text": "Tst 01"} );
    units.push( {"id": "02", "unit": "u02", "text": "Tst 02"} );

    var html =
    <div className="">
        <table className="table table-bordered table-striped table-hover">
            <thead>
            <tr>
                <th>ID</th>
                <th>Enhet</th>
                <th>Text</th>
                <th>Adress</th>
                <th>Postnr</th>
                <th>Stad</th>
                <th>Tel</th>
                <th>Öppet</th>
                <th>Status</th>
            </tr>
            </thead>
            <tbody>
            { units.map((row, key) =>
                <tr ondblclick={() => this.unit_open(row)}>
                <td>{ row.id }</td>
                <td>{ row.unit }</td>
                <td>{ row.text }</td>
                <td>{ row.adress }</td>
                <td>{ row.postnr }</td>
                <td>{ row.stad }</td>
                <td>{ row.tele }</td>
                <td>{ row.open }</td>
                <td>{ row.status }</td>
                </tr>
            )}
            </tbody>
        </table>
    </div>
    ;
    return html;
}

function web01() {

    var html =
    <div className="web-content">
    </div>
    ;
    return html;
}

class Fakt extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, deps: [] };
    //context.posrend = this;
  }
/*
    const[txt, setTxt] = useState("SS00");
    var sdo = {};
    sdo.text = txt;
    sdo.setText = setTxt;
*/
  ddo() {
    //alert("POS" + this.state.pgid );
    this.setState({ status: 1 });
  }
    fknGo(url) {
        //alert(row.href);
        this.props.navigate(url);
    }
  udid_req() {
    var udid = "TT001920";
    var self = this;
	var req = {};
	req.req = "emv.print";
	req.udid = udid;
	req.fkn = "PRINT";

	var prm = {};
	req.prm = prm;

    //gda.wndLoading("Hämtar");
    srs.udid_req(req)
    .then(function(ret){
        //alert(JSON.stringify(ret.rco));
        //gda.wndClose();
        //var txt = JSON.stringify(data);
        //gda.wndSuccess(txt);
        //deps_list(ret.rco)

        alert(JSON.stringify(ret.rco));
        self.setState({ deps: ret.rco });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    })
    }
    settle() {
      var udid = "TT001920";
    var self = this;
	var req = {};
	req.req = "emv.settle";
	req.udid = udid;
	req.fkn = "SETTLE";

	var prm = {};
	req.prm = prm;

    //gda.wndLoading("Hämtar");
    srs.udid_req(req)
    .then(function(ret){
        //alert(JSON.stringify(ret.rco));
        //gda.wndClose();
        //var txt = JSON.stringify(data);
        //gda.wndSuccess(txt);
        //deps_list(ret.rco)

        alert(JSON.stringify(ret.rco));
        self.setState({ deps: ret.rco });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    })
    }

  render() {
    return (
    <div className="web-app">
    <div className="web-top">
        <Fakt_Top store={this.webStore} />
    </div>
    <div className="web-main">

    <div className="web-row" style={{ height:"100%" }}>

    { this.props.store.cfg.mnu_show ?
    <div className="web-side">
        <Fakt_Menu store={this.webStore} />
    </div>
    : null }

    <div className="web-body">
        <Routes>
            <Route path="kreg/*" element={ <Fakt_Kreg store={this.webStore} />} />
            <Route path="fo/*" element={ <Fakt_Fo store={this.webStore} />} />
            <Route path="rsk/*" element={ <Fakt_Rsk store={this.webStore} />} />
            <Route path="und/*" element={ <Fakt_Und store={this.webStore} />} />
            <Route path="bo/*" element={ <Fakt_Bo store={this.webStore} />} />
            <Route path="dash" element={ <Fakt_Dash store={this.webStore} />} />
            <Route path="home" element={ <Fakt_Home store={this.webStore} />} />
            <Route path="*" element={ <Fakt_Dash store={this.webStore} />} />
        </Routes>
    </div>
    </div>

    </div>

    <div className="web-foot">
        <div className="web-foot-mnu" onClick={() => this.ddo() }>
            <FontAwesomeIcon icon="bars" size="1x"/>
        </div>
        <div className="flex flex-row">
            <div className="web-foot-btn" onClick={() => this.fknGo("/fakt/") } >
                Fakturasystem
            </div>
            <div className="flex"></div>

            <div className="web-foot-btn" onClick={() => this.fknGo("/fakt/kreg/kund") } >KUNDER</div>
            <div className="web-foot-btn" onClick={() => this.fknGo("/fakt/fo/intrsk") } >INTERN</div>
        </div>
        <div className="web-foot-icon">
          <FontAwesomeIcon icon="ellipsis-v" size="1x"/>
        </div>
        <div className="flex flex-row">
            <div className="web-foot-btn" onClick={() => this.fknGo("/fakt/rsk/rsk") }>RESKONTRA</div>

            <div className="flex"></div>
            <Tiden />
        </div>
    </div>

    </div>
  );
}
}
//Sys.contextType = ADOContext;

export default withRouter(observer(Fakt));

/*
          <Route path="/cas/" component={pg_s2} />
    <p>KASINO : { this.state.status }</p>
    <div className="flex-row">
    <button className="btn btn-hw-blue w150 ripple" onClick={this.udid_req} aria-label="System" >
        Fråga
    </button>
    <button className="btn btn-hw-blue w150 ripple" onClick={this.settle} aria-label="System" >
        Settle
    </button>
    </div>
*/