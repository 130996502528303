import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";
import Dt_Cal from "../../lib/ui/dt_cal";
import ddo from "../../ddo";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Pos_Trs extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.pos = props.store.pos;
    this.state = {};
    this.state.seldate = new Date();
    this.state.avd = ddo.cache.avd;
    this.state.tra = [];
    this.state.cols = {};
    this.state.aa = [];
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.st_trs();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 200;
      css.overflow = "auto";
      return css;
  }
  col_style(id) {
      var css = {};
      css.width = this.state.cols[id];
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

  split() {
    var self = this;
    var prm = {};
    prm.req = "vcm.pp.split_row";
    prm.avd = ddo.cache.avd;
    prm.fbord = "9001";
    prm.tbord = "9002";
    prm.notanr = "053680E";
    prm.rad = "0001";

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        alert(JSON.stringify(ret));

    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }
  st_trs() {
    var self = this;
    var prm = {};
    prm.req = "pos.pos_eko.trs";
    prm.avd = ddo.cache.avd;
    //prm.fplu = datum;
    //prm.tplu = datum;

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        var tra = ret.rca.filter(rrs00 => {
        if(rrs00.r0ttyp.includes('J')) return false;
        if(rrs00.r0etyp.includes('NOTA')) return false;
        return true;
        });
        self.setState({tra: tra });
        //self.setState({tra: ret.rca });
        //toastr.success("OK", "SAPI");
        self.syncTb();
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }
  syncTb() {
      var tb = "trsTable";
      var tbh = "trsHead";
        var cols = {};

            if (!document.getElementById(tb)) {
                //alert("No tb");
                return;
            }

            var ix = document.getElementById(tb).rows.length - 1;
            var ic = document.getElementById(tb).rows[0].cells.length;
            //alert(ic);
            //ic = 0;
            var ci = 0;
            while(ci < ic) {
                var cxi = ci + 1;
                //var col = "c" + cxi.toString().pad(2);
                //var col = "c" + cxi.toString().pad(2);
                var col = "c" + cxi.toString().pad(2);
                cols[col] = document.getElementById(tb).rows[ix].cells[ci].clientWidth + 1;
                ci = ci + 1;
            }
            //alert(JSON.stringify(cols));
        this.setState({cols: cols });
  }

  render() {
    return (
    <div className="web-p00 flex flex-col">
        <div className="">
            Transaktioner
        </div>

    <div className="flex-row">
        <Dt_Cal startdatum="2020-02-01" getValue={(e)=> this.setState({seldate: e}) }/>
        <select className="form-control w250 hide" placeholder="Välj avdelning">
            { this.pos.deps.map((row, key) =>
            <option key={key} value={row.id}>{row.text}</option>
            )}
        </select>
        <div className="flex"></div>

        <button className="btn btn-hw-blue w150 ripple" onClick={() => this.split() } aria-label="System" >
            SPLIT
        </button>
        <button className="btn btn-hw-blue w150 ripple" onClick={() => this.plus() } aria-label="System" >
            Visa
        </button>
    </div>
    <div className="divider">
    </div>

    <div className="web-col">
        <table id="trsHead" className="table table-bordered table-hover tb" style={{marginBottom: "0px"}}>
            <thead>
            <tr>
				<th style={ this.col_style("c01") }>Datum</th>
				<th style={ this.col_style("c02") }>ID</th>
				<th style={ this.col_style("c03") }>Tid</th>
				<th style={ this.col_style("c04") }>Notanr</th>
				<th style={ this.col_style("c05") }>Rad</th>
				<th style={ this.col_style("c06") }>Bord</th>
				<th style={ this.col_style("c07") }>PLU</th>
				<th style={ this.col_style("c08") } className="flex">Text</th>
				<th style={ this.col_style("c09") }>Antal</th>
				<th style={ this.col_style("c10") }>Pris</th>
				<th style={ this.col_style("c11") }>Procent</th>
            </tr>
            </thead>
            </table>
            <div style={ this.tb_style() }>
          <table id="trsTable" className="table table-bordered table-hover tb">
            <tbody>
            { this.state.tra.map((rrs00, key) =>
            <tr key={key} id={ rrs00.r0datum } onDoubleClick={() => this.plu_open(rrs00) }>
                <td>{ rrs00.r0datum.cdidate() }</td>
                <td>{ rrs00.r0dateid }</td>
                <td>{ rrs00.r0time }</td>
                <td>{ rrs00.r0faktnr }</td>
                <td>{ rrs00.r0lopnr }</td>
                <td>{ rrs00.r0tablnr }</td>
                <td>{ rrs00.r0etyp }</td>
                <td>{ rrs00.r0text.toswe() }</td>
                <td>{ rrs00.r0noof.ztrim() }</td>
                <td>{ rrs00.r0perprice.cdipris() }</td>
                <td>{ rrs00.r0vatproc.dec2() }</td>
            </tr>
            )}
            </tbody>
        </table>
        </div>
    </div>

    </div>
  );
    }
}
export default Pos_Trs;
