import React, { useState, useEffect, useContext } from 'react';
import {format} from "date-fns";

import * as net from "../../../lib/net";

import Dd_Sel from "../../../lib/ui/dd_sel";
import Dt_Cal from "../../../lib/ui/dt_cal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Und_Kund extends React.Component {
  constructor(props, context) {
    super(props);

    this.dt_fd = React.createRef();
    this.dt_td = React.createRef();
    this.selitems = [
        {fkn: 'bevaka', text: 'BEVAKA'},
        {fkn: 'bokbild', text: 'BOKNINGSBILD'},
        {fkn: 'confirm', text: 'BEKRÄFTA'},
        {fkn: 'cancel', text: 'AVBOKA'}
    ];

    this.store = props.store;
    this.state = {};
    this.state.ynq = [];

    this.state.rta = [];
    this.state.bra = [];

    this.state.dta = [];
    this.state.kaa = [];

    this.state.tot = {};
    this.state.tot.fsg = 0;
    this.state.tot.krubba = 0;

    this.state.ix = 0;
    this.state.boknr = "";
    this.state.datum = "";
    this.state.fdat = "";
    this.state.tdat = "";

    this.state.wnds = {};
    this.state.rvo = {};
    if(props.wnds) {
        if(props.wnds.boknr) this.state.boknr = props.wnds.boknr;
    }
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      this.kreg();
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }

  val_pris(val) {
      var s = "";
      if(val == 0) s = "0.00";
      if(val) s = val.cdipris();
      return s;
  };

    wnd_close = () => {
      if(this.props.close) {
          this.props.close(this.props.ctx);
      }
      else this.props.ctx.setState({ wnds: {} });
	};
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      css.overflow = "auto";
      return css;
  }
    th_style(id) {
        var css = {};
        css.cursor = "pointer";
        if(id == this.state.sortid) {
            if(this.state.sortdir) css.color = "#800";
            else css.color = "#080";
        }
        return css;
    }
    val_save (name, value) {
        //alert(JSON.stringify(name));
        //alert(JSON.stringify(value));
      var rvo = this.state.rvo;
      var na = name.split(".");
      rvo[na[0]][na[1]] = value;
      this.setState({rvo: rvo});
      //setState({[name]: value});
    }
  dt_fdat(e) {
    //alert(JSON.stringify(e));
    var dts = format(e, "yyyy-MM-dd");
    this.setState({ fdat: dts });
  }
  dt_tdat(e) {
      //alert(JSON.stringify(e));
    var dts = format(e, "yyyy-MM-dd");
    this.setState({ tdat: dts });
  }
  oneday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_td.current.updateDt(this.state.fdat);
  }
  addday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_fd.current.updateAdd();
    this.dt_td.current.updateAdd();
  }
  subday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_fd.current.updateSub();
    this.dt_td.current.updateSub();
  }
    kund_sel(row) {
        var self = this;

        if(this.props.wnds.cb) {
            this.props.wnds.cb(this.props.ctx, row);
        }
        else this.props.ctx.setState({ wnds: {} });
    }
    kreg() {
        var self = this;
        var prm = {};
        prm.req = "kreg.kreg_wrk.kreg_list";

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.rca));

                if(ret.ok != "000") {
                    return;
                }
                self.setState({kaa: ret.rca });
                self.syncTb("kidTable");
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    syncTb(tb){
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
        tdElements = tbel.getElementsByTagName('td');

        for (let i = 0; i < thElements.length; i++) {
            if(!tdElements[i]) continue;
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
            width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
  trs_rum() {
    //alert(JSON.stringify(e));
    var wnds = {};
    wnds.trsrum = true;
    this.setState({ wnds: wnds });
  }

  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "Bokning";

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="m2_backdrop" /> : null }
        <div className="m2_dlg mx_70h flex-col" style={ this.dlg_style() }>
        <div className="mx_h01">
            <div>UNDERLAG</div>
        </div>

            <div className="divider_orange hide"></div>

            <div className="flex-row">
                <div className="flex"></div>
            <div className="flex-row">
                  <div className="dt-btn-01" onClick={() => this.subday() } ><FontAwesomeIcon icon="minus" size="1x" /></div>
                  <Dt_Cal dts={ this.state.fdat } rp="1" ref={this.dt_fd} getValue={(e)=> this.dt_fdat(e)} />
                  <div className="dt-btn-01" onClick={() => this.oneday() } >1</div>
                  <Dt_Cal dts={ this.state.tdat } rp="1" ref={this.dt_td} getValue={(e)=> this.dt_tdat(e)} />
                  <div className="dt-btn-01" onClick={() => this.addday() } ><FontAwesomeIcon icon="plus" size="1x" /></div>
            </div>
                <div className="flex"></div>
                <button className="btn btn-hw-blue w150" onClick={() => this.red_logi() } aria-label="System" >
                    Visa
                </button>
            </div>
             <div className="divider_green"></div>
       <div className="mx_content flex">

        <div id="kidTable">
            <table className="table table-bordered table-hover tb" style={{marginBottom: "0px"}}>
                <thead>
                <tr>
                    <th style={ this.th_style("kid" ) }>KID</th>
                    <th style={ this.th_style("company" ) }>Företag</th>
                    <th style={ this.th_style("namn" ) }>Namn</th>
                    <th style={ this.th_style("orgnr" ) }>Orgnr</th>
                    <th style={ this.th_style("tfn" ) }>Telefon</th>
                    <th style={ this.th_style("mobil" ) }>Mobil</th>
                    <th style={ this.th_style("email" ) }>Epost</th>
                    <th style={ this.th_style("betvillkor" ) }>Villkor</th>
                    <th style={ this.th_style("" ) }></th>
                </tr>
                </thead>
            </table>
            <div style={ this.tb_style() }>
                <table className="table table-bordered table-hover tb">
                    <tbody>
                    { this.state.kaa.map((row, key) =>
                        <tr key={ key } onDoubleClick={() => this.kund_sel(row) }>
                            <td>{ row.krr00.kid }</td>
                            <td>{ row.krr00.company }</td>
                            <td>{ row.krr00.namn }</td>
                            <td>{ row.krr02.orgnr }</td>
                            <td>{ row.krr03.tfn }</td>
                            <td>{ row.krr03.mobil }</td>
                            <td>{ row.krr03.email }</td>
                            <td>{ row.krr00.betvillkor }</td>
                            <td onClick={() => this.kund_edit(row) }><i className="fa fa-cog text-danger"></i></td>
                        </tr>
                    )}
                    </tbody>
                </table>
        </div>
        </div>

            </div>

            <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250 ripple" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex-row">
            </div>
        </div>
      </div>

    </div>
	;
	return html;
  };
}

export default Und_Kund;