import React, { useState, useEffect, useContext } from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";

import Xt_Home from './home.js';
import Xt_Ta from './ta.js';

import UI_Tiden from '../com/ui/tiden.js';

import * as srs from "../lib/srs";
import * as net from "../lib/net";
import {observer} from "mobx-react";
import Tiden from "../lib/ui/tiden";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Xt extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, deps: [] };
    //context.posrend = this;
  }

  ddo() {
    alert("POS" + this.state.pgid );
    this.setState({ status: 1 });
  }
  udid_req() {
      var udid = "TT001920";
    var self = this;
	var req = {};
	req.req = "emv.print";
	req.udid = udid;
	req.fkn = "PRINT";

	var prm = {};
	req.prm = prm;

    //gda.wndLoading("Hämtar");
    srs.udid_req(req)
    .then(function(ret){
        //alert(JSON.stringify(ret.rco));
        //gda.wndClose();
        //var txt = JSON.stringify(data);
        //gda.wndSuccess(txt);
        //deps_list(ret.rco)

        alert(JSON.stringify(ret.rco));
        self.setState({ deps: ret.rco });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    })
    }

  render() {
    return (
    <div className="web-app">
    <div className="web-main">

    <div className="web-row" style={{ height:"100%" }}>

    <div className="web-body">
        <Routes>
            <Route path="ta/*" element={ <Xt_Ta store={this.webStore} />} />
            <Route path="*" element={ <Xt_Home store={this.webStore} />} />
        </Routes>
    </div>

    </div>

    </div>
    <div className="web-foot">
        <div className="web-foot-mnu" onClick={() => this.ddo() }>
            <FontAwesomeIcon icon="bars" size="1x"/>
        </div>
        <div className="flex flex-row">
            <div className="web-foot-btn" onClick={() => this.ddo() } >
                XT
            </div>
            <div className="flex"></div>

        </div>
        <div className="web-foot-icon">
          <FontAwesomeIcon icon="ellipsis-v" size="1x"/>
        </div>
        <div className="flex flex-row">

            <div className="flex"></div>
            <Tiden />
        </div>
    </div>

    </div>
  );
}
}
//Sys.contextType = ADOContext;

export default observer(Xt);

/*
    <div className="web-foot-btn" onClick={() => this.ddo() }>STATUS</div>
*/