import React, { useState, useEffect, useContext } from 'react';
import ReactQuill from "react-quill"
import 'react-quill/dist/quill.snow.css'

import * as utils from "../../assets/utils";
import * as net from "../../lib/net";
import Lang from "../../lib/lang";

import Td_Sel from "../../lib/ui/td_sel";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Cms_Txt extends React.Component {
  constructor(props, context) {
    super(props);

    //this.lang = {};
    this.store = props.store;
    this.state = { pgid: 11, tra: [] };
    this.state.today = new Date();
    this.state.avd = "01";
    this.state.aa = [];
    this.state.inhsel = 0;
      this.state.msg = {};
      this.state.rvo = {};

    this.state.sca = [];
    this.state.rvo = {};
    this.state.selrow = {};
    this.state.wnds = {};
    this.state.sortid = "";
    this.state.sortdir = 1;

      this.state.convertedText = "";
      this.state.convertedText = "";

      this.state.pna = [
          {fzip: '61100', tzip: '61199', unitid: 'food0001', text: 'NYKÖPING'},
          {fzip: '11000', tzip: '11399', unitid: 'food0002', text: 'DANDERYD'},
          {fzip: '11500', tzip: '11699', unitid: 'food0003', text: 'SICKLA'}
      ];
    this.selitems = [
        {fkn: 'open', text: 'ÖPPNA'},
        {fkn: 'cancel', text: 'AVBOKA'}
    ];
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

  body_style() {
    var css = {};
    css.height = window.innerHeight - 280;
    return css;
  }
  tb_style() {
    var css = {};
    css.height = window.innerHeight - 250;
    css.overflow = "auto";
    return css;
  }
  th_style(id) {
      var css = {};
      css.cursor = "pointer";
      if(id == this.state.sortid) {
          if(this.state.sortdir) css.color = "#800";
          else css.color = "#080";
      }
      return css;
  }
  row_style(row) {
    var srow = this.state.selrow;

    var css = {};
    if(srow == row) {
        css.background = "#080";
        css.color = "#fff";
    }
    return css;
  }
  onUnitChange(e) {
    this.setState({unit: e.value});
  }
    scr_add() {
        var self = this;

        var wnds = {};
        wnds.scradd = true;
        //wnds.sco = row;
        wnds.cb = self.cb_scr;
        //alert(JSON.stringify(wnds));
        self.setState({ wnds: wnds });
    }
    scr_edit(row) {
        var self = this;

        var wnds = {};
        wnds.scredit = true;
        wnds.sco = row;
        wnds.cb = self.cb_scr;
        //alert(JSON.stringify(wnds));
        self.setState({ wnds: wnds });
    }
    cb_scr(ctx) {
        ctx.setState({ wnds: {} });
    };
    tvm_show() {
        var self = this;
        var wnds = {};
        wnds.tvm = true;
        wnds.tvid = "tv01";
        //wnds.tvid = self.store.cfg.scrid;
        //wnds.cb = self.cb_tbo;
        self.setState({wnds: wnds });
    }

  dsi_screens() {
    var self = this;
    var prm = {};
    prm.req = "dsi.dsi_scr.scr_list";

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        self.setState({ sca: ret.rca });
        //self.setState({ rva: ret.rca });
        self.syncTb("scrTable");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
    syncTb(tb) {
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
        tdElements = tbel.getElementsByTagName('td');
        if(tdElements.length < 1 ) return;

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
            width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }

  sel_row(row) {
    var srow = this.state.selrow;
    if(srow == row) {
      this.setState({selrow: {}});
    }
    else this.setState({selrow: row});
  }
  sel_row_fkn(row, sel) {
      if(sel.fkn == "open") {
          this.sel_gst(row);
      }
  }
   tb_sort(typ) {
      var self = this;
      //var rvs = self.state.rvs;
      var rva = self.state.rva;
      var sortid = this.state.sortid;
      var sortdir = this.state.sortdir;

      if(sortid != typ) sortdir = 0;
      else {
        if(sortdir) sortdir = 0;
        else sortdir = 1;
      }

      if(typ == "ank") {
        if(sortdir) rva.sort((a,b) => (a.gsb00.r0ankdatum < b.gsb00.r0ankdatum) ? 1 : ((b.gsb00.r0ankdatum < a.gsb00.r0ankdatum) ? -1 : 0));
        else rva.sort((a,b) => (a.gsb00.r0ankdatum > b.gsb00.r0ankdatum) ? 1 : ((b.gsb00.r0ankdatum > a.gsb00.r0ankdatum) ? -1 : 0));
      }
      self.setState({ rva: rva, sortid: typ, sortdir: sortdir });
   }
    th_icon(typ) {
      var cls = "";
      var sortid = this.state.sortid;
      var sortdir = this.state.sortdir;
      if(sortid == typ)
      {
        if(sortdir) cls = "fa fa-caret-up";
        else cls = "fa fa-caret-down";
      }
      return cls;
    }
    set_text(txt) {
        //alert(JSON.stringify(txt));
        this.setState({convertedText: txt });
    }

  render() {
    return (
    <div className="web-bas">

    <div className="flex-row">
        <button className="btn btn-hw-blue w200" onClick={() => this.tvm_show() } aria-label="System" >
            TEXT
        </button>
        <div className="flex"></div>
        <button className="btn btn-hw-blue w200" onClick={() => this.scr_add() } aria-label="System" >
            LÄGG TILL
        </button>
        <button className="btn btn-hw-blue w200" onClick={() => this.dsi_screens() } aria-label="System" >
            UPPDATERA
        </button>
    </div>
    <div className="divider_green"></div>

        <ReactQuill
            theme='snow'
            value={ this.state.convertedText }
            onChange={ (e) => this.set_text(e) }
            style={{minHeight: '300px'}}
        />
    </div>
  );
    }
}
export default Cms_Txt;
