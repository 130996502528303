import React, { useState, useEffect, useContext } from 'react';
import {format} from "date-fns";

import * as net from "../../../../lib/net";
import Fr_Input from "../../../../lib/ui/fr_input";

class Split_Row extends React.Component {
  constructor(props, context) {
    super(props);

     this.selitems = [
        {fkn: 'setup', text: 'INSTÄLLNINGAR'},
        {fkn: 'upd', text: 'UPPDATERA'},
        {fkn: 'sys', text: 'SYSTEM'},
        {fkn: 'logout', text: 'LOGGA UT'}
    ];

    this.store = props.store;
    this.state = {};
    this.state.ynq = [];

    this.state.paa = [];
    this.state.aa = [];

    this.state.splittext = "";
    this.state.splitantal = "";
    this.state.splitbelopp = "";

    this.state.boknr = "";
    this.state.rvo = {};
    this.state.gsb00 = {};
    this.state.etr01 = {};
    if(props.wnds) {
        this.state.boknr = props.wnds.boknr;
        this.state.gsb00 = props.wnds.gsb00;
        this.state.etr01 = props.wnds.etr01;

        var tro = this.state.etr01;
        this.state.splittext = tro.e0text.toswe();
        this.state.splitantal = "1";
        this.state.splitbelopp = "";
        //this.state.splitbelopp = tro.e0pris.cdipris();
    }

    this.state.today = new Date();
    var dts = format(this.state.today, "yyyy-MM-dd");
    this.state.dts = dts;
    this.state.ok = false;
    this.state.error = false;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    body_style() {
      var css = {};
      if(this.state.ok) {
          css.background = "#080";
          css.color = "#fff";
      }
      if(this.state.error) {
          css.background = "#800";
          css.color = "#fff";
      }
      //css.height = window.innerHeight * 0.8;
      return css;
    }

    wnd_close() {
      if(this.props.wnds.cb) {
        var rsp = {};
        rsp.ok = "999";
          this.props.wnds.cb(this.props.ctx, rsp);
      }
      else this.props.ctx.setState({ wnds: {} });
	};
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
    val_save (name, value) {
      var state = this.state;
      //var na = name.split(".");
      //gso[na[0]][na[1]] = value;
      state[name] = value;
      this.setState(state);
      //setState({[name]: value});
    }
  split() {
    var self = this;

    var antal = this.state.splitantal;
    var belopp = this.state.splitbelopp;
    if(antal.length < 1) {
        alert("Belopp måste fyllas i");
        return;
    }
    if(belopp.length < 1) {
        alert("Belopp måste fyllas i");
        return;
    }
    belopp = belopp.topris(11).ztrim();

    var tro = this.state.etr01;
    var lopnr = tro.e0lopnr;

    var obelopp = (tro.e0pris * 1);
    if(obelopp == (belopp * 1)) {
        alert("Ej samma belopp som raden");
        return;
    }
    if(obelopp < (belopp * 1)) {
        alert("Ej hägre belopp än raden");
        return;
    }
    if((antal * 1) != 1) {
        var oantal = (tro.e0pris * 1);
        if(oantal == (antal * 1)) {
            alert("Ej samma antal som raden");
            return;
        }
        if(oantal < (antal * 1)) {
            alert("Ej hägre antal än raden");
            return;
        }
    }

    var prm = {};
    prm.req = "pms.split.split_tr";
    prm.boknr = this.state.boknr;
    prm.gsb00 = this.state.gsb00;
    prm.etr01 = tro;
    prm.lopnr = lopnr;
    prm.splitantal = antal;
    prm.splitbelopp = belopp;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rco));

      if(self.fkn_close) self.fkn_close(self.props.ctx);
      else self.props.ctx.setState({ wnds: {} });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "";

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="m2_backdrop" /> : null }
        <div className="m2_dlg mx_50h flex-col" style={ this.dlg_style() }>
        <div className="flex-row " style={{padding:"0px",height:"50px",lineHeight:"50px", background: "#1a456b", color: "#fff"}}>
                <div className="flex-row flex" style={{padding:"0px 5px", fontSize: "22px"}}>
                    <div>DELA RAD</div>
                </div>
            <div className="wnd-close-btn" onClick={() => this.wnd_close() }>X</div>
        </div>
        <div className="mx_content flex" style={ this.body_style() }>

            <div className="flex-row txt_22">
                <div className="flex text-center">Rad : { this.state.etr01.e0lopnr.ztrim() }</div>
            </div>
            <div className="flex"></div>
            <form className="cdi_f03">
            <div className="flex-col">
                <div className="flex-row py-2">
                    <div className="f30"></div>
                    <Fr_Input label="Text" col className="text-left w350 mx-2" text={this.state.splittext} getValue={(e)=> this.val_save("splittext", e) } />
                </div>
                <div className="flex-row py-2">
                    <div className="f30"></div>
                    <Fr_Input label="Antal" col className="text-left w150 mx-2" text={this.state.splitantal} getValue={(e)=> this.val_save("splitantal", e) } />
                </div>
                <div className="flex-row py-2">
                    <div className="f30"></div>
                    <Fr_Input label="Pris" col className="text-left w250 mx-2" text={this.state.splitbelopp} getValue={(e)=> this.val_save("splitbelopp", e) } />
                </div>
            </div>
            </form>
            <div className="flex"></div>

        </div>
        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250 ripple" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex"></div>
            <button className="btn btn-hw-red w250 ripple" onClick={() => this.split() } >
                DELA
            </button>
        </div>
      </div>
    </div>
	;
	return html;
  };
}

export default Split_Row;