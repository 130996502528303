import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../../lib/net";
import ddo from "../../../ddo";

class Btn_Fkns extends React.Component {
  constructor(props, context) {
    super(props);

    this.pos = props.store.pos;
    this.state = {};

    this.state.avd = ddo.cache.avd;
    this.state.selrows = {};

    this.state.ynq = [
                {id: '0', text: 'NEJ'},
                {id: '1', text: 'JA'}
            ];
    this.state.bto = {};
    this.state.background = "#fff";

  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }

    wnd_close = () => {
		this.props.ctx.setState({ wnds: {} });
	};

  export_grps() {
    var self = this;
    var prm = {};
    prm.req = "pos.pos_btns.dump_grps";
    prm.avd = this.state.avd;

    net.sio_req(prm)
    .then(function(ret){
        alert(JSON.stringify(ret));
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  export_fams() {
    var self = this;
    var prm = {};
    prm.req = "pos.pos_btns.dump_fams";
    prm.avd = this.state.avd;

    net.sio_req(prm)
    .then(function(ret){
        alert(JSON.stringify(ret));
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  export_btns() {
    var self = this;
    var prm = {};
    prm.req = "pos.pos_btns.dump_btns";
    prm.avd = this.state.avd;

    net.sio_req(prm)
    .then(function(ret){
        alert(JSON.stringify(ret));
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

  clear_grps() {
    var self = this;
    var prm = {};
    prm.req = "pos.pos_btns.clear_grps";
    prm.avd = this.state.avd;

    net.sio_req(prm)
    .then(function(ret){
        alert(JSON.stringify(ret));
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  clear_fams() {
    var self = this;
    var prm = {};
    prm.req = "pos.pos_btns.clear_fams";
    prm.avd = this.state.avd;

    net.sio_req(prm)
    .then(function(ret){
        alert(JSON.stringify(ret));
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  clear_btns() {
    var self = this;
    var prm = {};
    prm.req = "pos.pos_btns.clear_btns";
    prm.avd = this.state.avd;

    net.sio_req(prm)
    .then(function(ret){
        alert(JSON.stringify(ret));
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

  import_grps() {
    var self = this;
    var prm = {};
    prm.req = "pos.pos_btns.import_grps";
    prm.avd = this.state.avd;

    net.sio_req(prm)
    .then(function(ret){
        alert(JSON.stringify(ret));
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  import_fams() {
    var self = this;
    var prm = {};
    prm.req = "pos.pos_btns.import_fams";
    prm.avd = this.state.avd;

    net.sio_req(prm)
    .then(function(ret){
        alert(JSON.stringify(ret));
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  import_btns() {
    var self = this;
    var prm = {};
    prm.req = "pos.pos_btns.import_btns";
    prm.avd = this.state.avd;

    net.sio_req(prm)
    .then(function(ret){
        alert(JSON.stringify(ret));
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

    export_plus() {
    var self = this;
    var prm = {};
    prm.req = "pos.pos_btns.dump_plus";
    prm.avd = this.state.avd;

    net.sio_req(prm)
    .then(function(ret){
        alert(JSON.stringify(ret));
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
    export_plumenu() {
    var self = this;
    var prm = {};
    prm.req = "pos.pos_btns.dump_plumenu";
    prm.avd = this.state.avd;

    net.sio_req(prm)
    .then(function(ret){
        alert(JSON.stringify(ret));
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
    clear_plus() {
    var self = this;
    var prm = {};
    prm.req = "pos.pos_btns.clear_plus";
    prm.avd = this.state.avd;

    net.sio_req(prm)
    .then(function(ret){
        alert(JSON.stringify(ret));
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
    clear_plumenu() {
    var self = this;
    var prm = {};
    prm.req = "pos.pos_btns.clear_plumenu";
    prm.avd = this.state.avd;

    net.sio_req(prm)
    .then(function(ret){
        alert(JSON.stringify(ret));
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
    import_plus() {
    var self = this;
    var prm = {};
    prm.req = "pos.pos_btns.import_plus";
    prm.avd = this.state.avd;

    net.sio_req(prm)
    .then(function(ret){
        alert(JSON.stringify(ret));
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
    import_plumenu() {
    var self = this;
    var prm = {};
    prm.req = "pos.pos_btns.import_plumenu";
    prm.avd = this.state.avd;

    net.sio_req(prm)
    .then(function(ret){
        alert(JSON.stringify(ret));
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

  render() {

	var html =
        <div className="mx_base">
        { this.props.wnds.backdrop ? <div className="mx_backdrop" /> : null }
        <div className="mx_dlg mx_70h flex-col" style={ this.dlg_style() }>
        <div className="flex-row " style={{padding:"0px",height:"50px",lineHeight:"50px", background: "#1a456b", color: "#fff"}}>
                <div className="flex-row flex" style={{padding:"0px 5px", fontSize: "22px"}}>
                    <div>Funktioner { this.state.rumsnr }</div>
                </div>
            <div className="wnd-close-btn" onClick={() => this.wnd_close() }>X</div>
        </div>
        <div className="mx_content flex">

    <div className="web-row">
        <div className="web-btn flex" onClick={() => this.export_grps() } >
            <div>EXPORTERA GRUPPER</div>
            <div></div>
        </div>
        <div className="web-btn flex" onClick={() => this.clear_grps() } >
            <div>RENSA GRUPPER</div>
            <div></div>
        </div>
        <div className="web-btn flex" onClick={() => this.import_grps() } >
            <div>IMPORTERA GRUPPER</div>
            <div></div>
        </div>
    </div>
    <div className="divider_orange"></div>
    <div className="web-row">
        <div className="web-btn flex" onClick={() => this.export_fams() } >
            <div>EXPORTERA FAMILJER</div>
            <div></div>
        </div>
        <div className="web-btn flex" onClick={() => this.clear_fams() } >
            <div>RENSA FAMILJER</div>
            <div></div>
        </div>
        <div className="web-btn flex" onClick={() => this.import_fams() } >
            <div>IMPORTERA FAMILJER</div>
            <div></div>
        </div>
    </div>
    <div className="divider_orange"></div>
    <div className="web-row">
        <div className="web-btn flex" onClick={() => this.export_btns() } >
            <div>EXPORTERA KNAPPAR</div>
            <div></div>
        </div>
        <div className="web-btn flex" onClick={() => this.clear_btns() } >
            <div>RENSA KNAPPAR</div>
            <div></div>
        </div>
        <div className="web-btn flex" onClick={() => this.import_btns() } >
            <div>IMPORTERA KNAPPAR</div>
            <div></div>
        </div>
    </div>
    <div className="divider_blue"></div>
    <div className="web-row">
        <div className="web-btn flex" onClick={() => this.export_plus() } >
            <div>EXPORTERA PLUER</div>
            <div></div>
        </div>
        <div className="web-btn flex" onClick={() => this.clear_plus() } >
            <div>RENSA PLUER</div>
            <div></div>
        </div>
        <div className="web-btn flex" onClick={() => this.import_plus() } >
            <div>IMPORTERA PLUER</div>
            <div></div>
        </div>
    </div>
    <div className="divider_blue"></div>
    <div className="web-row">
        <div className="web-btn flex" onClick={() => this.export_plumenu() } >
            <div>EXPORTERA PLUMENYER</div>
            <div></div>
        </div>
        <div className="web-btn flex" onClick={() => this.clear_plumenu() } >
            <div>RENSA PLUMENYER</div>
            <div></div>
        </div>
        <div className="web-btn flex" onClick={() => this.import_plumenu() } >
            <div>IMPORTERA PLUMENYER</div>
            <div></div>
        </div>
    </div>


        </div>
        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
        </div>
      </div>
      </div>
	;
	return html;
  };
}

export default Btn_Fkns;