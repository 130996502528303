import React, { useState, useEffect, useContext } from 'react';

import * as utils from "../../../assets/utils";
import * as net from "../../../lib/net";
import Td_Sel from "../../../lib/ui/td_sel";

import Pms_Rvo from "../../rv/fb/rvo";
import Pms_Nota from "../nota/nota";
import Pay_Emv from "./pay/pay_emv";

class Pms_CiPay extends React.Component {
  constructor(props, context) {
    super(props);

     this.rowitems = [
        {fkn: 'open', text: 'ÖPPNA'},
        {fkn: 'mark', text: 'MARKERA'}
    ];

    this.store = props.store;
    this.state = {};
    this.state.ynq = [];

    this.state.aa = [];
    this.state.boknr = "";
    this.state.rvo = {};
    this.state.pka = [];
    this.state.srow = {};
    this.state.wnds = {};

    if(props.wnds) {
        this.state.boknr = props.wnds.boknr;
        this.state.gso = props.wnds.gso;
        //this.state.gsb00 = props.wnds.gsb00;
        this.state.rvo.charges = {};
   }
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      this.charge_todate();
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }

    wnd_close() {
		this.props.ctx.setState({ wnds: {} });
	};
    tb_style() {
      var css = {};
      //css.height = window.innerHeight - 275;
      return css;
    }
  row_style(row) {
    var srow = this.state.srow;
    var css = {};
    if(srow[row.etr01.e0lopnr]) {
        css.background = "#080";
        css.color = "#fff";
    }
    return css;
  }
  sel_row_fkn(row, e) {
    var self = this;
    //alert(JSON.stringify(fkn));
      if(e.fkn == "open") {
      }
  }
  rvg_belopp() {
    var self = this;

    var prm = {};
    //prm.req = "pms.br.brg_rr_upd";
    prm.req = "pms.charge.rvg_belopp";
    prm.boknr = this.state.gso.r0boknr;
    prm.glopnr = this.state.gso.r0glopnr;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rco));

        var rvo = self.state.rvo;
        rvo.charges.bokat = ret.rco.bokat;
        self.setState({rvo: rvo });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  charge_todate() {
    var self = this;

    var todate = this.state.gso.r0avrdatum;

    var prm = {};
    prm.req = "pms.sell.charge_todate";
    prm.gsb00 = this.state.gso;
    prm.permanent = 0;
    prm.datum = todate;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            return;
        }
        self.charges_pkats();
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  charges_pkats() {
    var self = this;
    var prm = {};
    prm.req = "pms.charge.charges_pkats";
    prm.boknr = this.state.gso.r0boknr;
    prm.glopnr = this.state.gso.r0glopnr;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            return;
        }
        var tot = ret.tot;
        var pka = ret.rca;
        self.setState({ pka: pka, tot: tot });
        self.syncTb("pkaTable");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
    syncTb(tb){
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
        tdElements = tbel.getElementsByTagName('td');

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
            width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
  clear_todate() {
    var self = this;
    var prm = {};
    prm.req = "pms.sell.clear_todate";
    prm.gsb00 = this.state.gso;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            return;
        }
        self.charges_pkats();
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  clear_close() {
    var self = this;
    var prm = {};
    prm.req = "pms.sell.clear_todate";
    prm.gsb00 = this.state.gso;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            return;
        }
		self.props.ctx.setState({ wnds: {} });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  pay_sel() {
    var self = this;
  }
  pay_ci(betkod) {
    var self = this;

    if(this.state.pka.length < 1) {
        alert("Inga rader betala");
        return;
    }

    var pra = [];
    //pra.push( this.state.selrow.etr01);
    var belopp = 0;
    for(var row of this.state.pka) {
        var pris = (row.etr01.e0antal * 1) * (row.etr01.e0pris * 1);
        belopp = belopp + pris
        pra.push( row.etr01 );
    }

    if(belopp == 0) {
        alert("Inget att betala");
        return;
    }
    belopp = belopp.toString();

    var prm = {};
    prm.req = "pms.charge.pay_trs";
    prm.boknr = this.state.gso.r0boknr;
    prm.glopnr = this.state.gso.r0glopnr;
    prm.payrows = pra;
    prm.paycode = betkod;
    prm.paytext = "BET: " + betkod;
    prm.payamount = belopp;
    prm.tkn = "+";
    prm.datum = this.state.dts;
    //alert(JSON.stringify(prm));

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));
        if(ret.ok != "000") {
            alert("Betalning misslyckades");
            return;
        }
        var notanr = ret.notanr;
        self.prn_spec(notanr);
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  pay_emv() {
    var self = this;

    if(this.state.pka.length < 1) {
        alert("Inga rader betala");
        return;
    }

    var pra = [];
    //pra.push( this.state.selrow.etr01);
    var belopp = 0;
    for(var row of this.state.pka) {
        var pris = (row.etr01.e0antal * 1) * (row.etr01.e0pris * 1);
        belopp = belopp + pris
        pra.push( row.etr01 );
    }

    if(belopp == 0) {
        alert("Inget att betala");
        return;
    }
    belopp = belopp.toString();

    var wnds = {};
    wnds.emv = 1;
    wnds.boknr = this.state.gso.r0boknr;
    wnds.glopnr = this.state.gso.r0glopnr;
    wnds.gso = this.state.gso;
    wnds.pra = pra;
    wnds.belopp = belopp;
    wnds.cb = this.cb_payemv;
    this.setState({wnds: wnds});
  };
  cb_payemv(ctx, ret) {
      if(ret && (ret.ok == "000")) {

      }
      ctx.setState({ wnds: {} });
      ctx.wnd_close();
  };
  prn_spec(notanr) {
    var self = this;

    var prm = {};
    prm.req = "pms.charge.prn_spec";
    prm.boknr = this.state.gso.r0boknr;
    prm.glopnr = this.state.gso.r0glopnr;
    prm.notanr = notanr;
    //alert(JSON.stringify(prm));
    //return;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.ok));

        //var uri = "data:application/pdf;base64," + ret.pdf;
        self.setState({pdf: 1, pdf_content: ret.pdf });
        self.wnd_close();
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "";

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="m2_backdrop" /> : null }
        <div className="m2_dlg mx_80h flex-col" style={ this.dlg_style() }>
            <div className="mx_h01 flex-row" style={{background: "#1a456b", color: "#fff"}}>
                    <div className="flex-col flex">
                        <div>BETALA VISTELSE</div>
                    </div>
                    <div className="flex-col">
                        <div>BOKNR: { this.state.boknr }</div>
                    </div>
            </div>
        <div className="mx_content flex">

            <div className="txt_box flex-col">
            <div className="flex-row">
                <div className="wnd_link">
                    DELBOKNING
                </div>
                <div className="flex"></div>
                <div className="wnd_link" onClick={() => this.gst_upd() }>
                    UPPDATERA
                </div>
            </div>
            <div className="flex-row">
                    <div className="flex-column flex py-2">
                        <div className="txt_lbl">PERIOD:</div>
                        <div className="txt_data">{ this.state.gso.r0ankdatum.cdidate() } - { this.state.gso.r0avrdatum.cdidate() }</div>
                    </div>
                    <div className="flex-column flex py-2">
                        <div className="txt_lbl">RUMSTYP:</div>
                        <div className="txt_data">{ this.state.rvo.rt }</div>
                    </div>
            </div>
            </div>

        <div id="pkaTable" className="flex">
          <table className="table table-bordered table-hover tb" style={{marginBottom: "0px"}}>
            <thead>
            <tr>
                <th>Datum</th>
                <th>T</th>
                <th>Typ</th>
                <th>Text</th>
                <th>Antal</th>
                <th>Pris</th>
                <th>Summa</th>
                <th>Tecken</th>
                <th>Löpnr</th>
                <th></th>
            </tr>
            </thead>
        </table>
        <div style={ this.tb_style() }>
          <table className="table table-bordered table-hover tb">
            <tbody>
            { this.state.pka.map((row, key) =>
            <tr key={key} style={ this.row_style(row) } onClick={ () => this.sel_row(row) } onDoubleClick={ () => this.row_sel(row) }>
                <td>{ utils.toDate( row.etr01.e0rdatum ) }</td>
                <td>{ row.etr01.e0bastyp }</td>
                <td>{ row.etr01.e0typ }</td>
                <td>{ row.etr01.e0text }</td>
                <td className="text-center">{ row.etr01.e0antal.ztrim() }</td>
                <td className="text-right">{ row.etr01.e0pris.cdipris() }</td>
                <td className="text-right">{ row.etr01.e0pris.cdipris() }</td>
                <td>{ row.etr01.e0tkn }</td>
                <td>{ row.etr01.e0lopnr.ztrim() }</td>
                <td>
                    <Td_Sel items={ this.rowitems } getValue={(e)=> this.sel_row_fkn(row, e)} />
                </td>
            </tr>
            )}
            </tbody>
        </table>
        </div>
        </div>

        <div className="divider_orange"></div>

        <div className="web-row">
        <div className="web-col flex">
            <div className="wnd-btn" onClick={() => this.clear_todate() } >
                <div>RENSA</div>
                <div></div>
            </div>
            <div className="wnd-btn" onClick={() => this.clear_close() } >
                <div>STÄNG</div>
                <div></div>
            </div>
        </div>
        <div className="web-col flex">
            <div className="wnd-btn" onClick={() => this.pay_sel() } >
                <div>VÄLJ BETALSÄTT</div>
            </div>
        </div>
        <div className="web-col flex">
            <div className="wnd-btn" onClick={() => this.pay_ci("kont") } >
                <div>KONTANT</div>
                <div></div>
            </div>
            <div className="wnd-btn" onClick={() => this.pay_emv() } >
                <div>EMV KORT</div>
            </div>
        </div>
        </div>

      </div>
      </div>
            { this.state.wnds.wnd_rvo ?
        <Pms_Rvo close={this.rvo_close} boknr={this.state.boknr} ctx={this} store={this.props.store} backdrop={true}/>
            : null }
            { this.state.wnds.nota ?
        <Pms_Nota wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
            : null }
    { this.state.wnds.emv ? <Pay_Emv wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
    </div>
	;
	return html;
  };
}

export default Pms_CiPay;