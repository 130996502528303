import React, { useState, useEffect, useContext } from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {withRouter} from "../lib/react/withRouter";
import { observer } from 'mobx-react'

import SDOContext from '../sdo.js'
import ddo from '../ddo.js'

import Pos_Top from './com/pos_top.js';
import Pos_Head from './com/pos_head.js';
import Pos_Mnu from './com/pos_mnu.js';
import Pos_Foot from './com/pos_foot.js';
import Std_Top from './com/std_top.js';
import Std_Mnu from './com/std_mnu.js';
import Std_Foot from './com/std_foot.js';

import Ccs_Mnu from './com/ccs_mnu.js';
import Ccs_Foot from './com/ccs_foot.js';

import Pos_Home from './home.js';
import Pos_Dash from './dash.js';
import Pos_Ccs from './ccs.js';
import Pos_Stat from './stat.js';
import Pos_Op from './op.js';
import Pos_Man from './man.js';
import Pos_Eko from './eko.js';
import Pos_Prds from './prds.js';
import Pos_Lager from './lager.js';
import Pos_Bo from './bo.js';
import Pos_Tech from './tech/tech.js';
import Pos_Red from './red/red.js';
import Pos_Reg from './reg/reg.js';

import * as net from "../lib/net";
import Tiden from "../lib/ui/tiden";

function web01() {

    var html =
    <div className="web-content">
    </div>
    ;
    return html;
}

class Pos_Bas extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pos = props.store.pos;
    this.state = { pgid: 11, mnu_show: true, deps: [] };
  }

  componentDidUpdate() {
  }

  componentWillUnmount() {
  }

  componentDidMount() {
    //this.props.fetchWells()
    //  .then(res => this.setState({ wells: res.wells }) )
    var deps = [];
    deps.push( {"id": "01", "text": "DEP X1" } );
    deps.push( {"id": "02", "text": "DEP X2" } );
    deps.push( {"id": "03", "text": "DEP X3" } );

    //this.props.store.seo.cs = "5";
    ///this.props.store.seo.unit = "BSENHET";

    var pos = {};
    pos.deps = deps;
    pos.vgrps = [];
    pos.plus = [];
    this.props.store.pos = pos;
    //alert(JSON.stringify(pos));
    this.pos_init();
  }

  fknGo(url) {
    //var sdo = useContext(SDO);
    //alert(fkn);
    //var fkn = row.fid;
    //setState({ row : row,  ma : [] });
    //ctx.fid = fkn;

    //props.history.push(row.url);
    this.props.navigate(url);
   };

  ddo() {
    //alert("POS" + this.state.pgid );
    //this.setState({ status: 1 });
  }

    pos_init() {
    var self = this;

    ddo.cache.deps = {};
    ddo.cache.vgrps = [];
    ddo.cache.mkods = {};

	var req = {};
	req.req = "pos.pos_fkn.pos_init";

    net.sio_req(req)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(!self.props.store.pos) self.props.store.pos = {};
        self.props.store.pos.deps = ret.rco.deps;
        self.props.store.pos.vgrps = ret.rco.vgrps;
        if(ret.rco.depo) ddo.cache.deps = ret.rco.depo;
        if(ret.rco.vgrps) ddo.cache.vgrps = ret.rco.vgrps;
        if(ret.rco.mkods) ddo.cache.mkods = ret.rco.mkods;

        var avd = Object.keys(ddo.cache.deps).sort()[0];
        if((avd * 1) > (ddo.cache.avd * 1)) {
            ddo.cache.avd = avd;
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //alert(JSON.stringify(txt));
    })
    }

  render() {

    if(this.props.store.seo.cs == "9") {
        return (
          <div className="web-app">
          <div className="web-top">
              <Std_Top store={this.store} />
          </div>
          <div className="web-main">
          <div className="web-row" style={{ height:"100%" }}>
          { this.props.store.cfg.mnu_show ?
          <div className="web-side">
                  <Std_Mnu store={this.store} />
          </div>
          : null }
          <div className="web-body">
                <Routes>
                    <Route path="ccs/*" element={ <Pos_Ccs store={this.store} />} />
                    <Route path="*" element={ <Pos_Dash store={this.store} />} />
                </Routes>
          </div>
          </div>
          </div>
              <Std_Foot store={this.store} />
          </div>
      );
    }

    return (
    <div className="web-app">

    <div className="web-top">
        <Pos_Top store={this.store} />
    </div>

    <div className="web-main">

    <div className="web-row" style={{ height:"100%" }}>

    { this.props.store.cfg.mnu_show ?
    <div className="web-side">
        { this.props.store.seo.cs == "5"
            ? <Ccs_Mnu store={this.store} />
            : <Pos_Mnu store={this.store} />
        }

    </div>
    : null }

    <div className="web-body">
        { this.props.store.seo.cs == "5"
            ?
          <Routes>
              <Route path="ccs/*" element={ <Pos_Ccs store={this.store} />} />
              <Route path="*" element={ <Pos_Dash store={this.store} />} />
          </Routes>
            :
          <Routes>
              <Route path="stat/*" element={ <Pos_Stat store={this.store} />} />
              <Route path="op/*" element={ <Pos_Op store={this.store} />} />
              <Route path="prds/*" element={ <Pos_Prds store={this.store} />} />
              <Route path="man/*" element={ <Pos_Man store={this.store} />} />
              <Route path="eko/*" element={ <Pos_Eko store={this.store} />} />
              <Route path="lager/*" element={ <Pos_Lager store={this.store} />} />
              <Route path="bo/*" element={ <Pos_Bo store={this.store} />} />
              <Route path="tech/*" element={ <Pos_Tech store={this.store} />} />
              <Route path="*" element={ <Pos_Dash store={this.store} />} />
          </Routes>
        }
    </div>

    </div>

    </div>

    { this.props.store.seo.cs == "5"
        ? <Ccs_Foot store={this.store} />
        : <Pos_Foot store={this.store} />
    }

    </div>
  );
}
}
//Sys.contextType = ADOContext;

export default withRouter(observer(Pos_Bas));

/*
    <div className="web-top-sys">
        <Pos_Top store={this.store} />
    </div>
    <div className="web-top-sub">
        <Pos_Head store={this.store} />
    </div>

*/