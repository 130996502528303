import React, { useState, useEffect, useContext } from 'react';
import {observer} from "mobx-react";

import * as net from "../../lib/net";

import Btn_View from "./btn/btn_view";
import Btn_Term from "./btn/btn_term";
import Btn_Grps from "./btn/btn_grps";
import Btn_Btns from "./btn/btn_btns";
import Btn_Fkns from "./btn/btn_fkns";
import ddo from "../../ddo";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Pos_Btns extends React.Component {
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pos = props.store.pos;

    this.state = {};
    this.state.seldate = new Date();
    this.state.avd = ddo.cache.avd;
    this.state.paa = [];
    this.state.wnds = {};
    this.state.aa = [];

    if(this.pos.deps) this.state.aa = this.pos.deps;

      this.state.mna = [];
      this.state.mna.push({ fknid: "btns", pos: "c1", text: "KNAPP VY", desc: "Grupper och knappar", link: "" });
      this.state.mna.push({ fknid: "term", pos: "c1", text: "TERMINAL KNAPPAR", desc: "Knappar på terminalen", link: "" });
      this.state.mna.push({ fknid: "btngrp", pos: "c2", text: "KNAPPGRUPPER", desc: "Grupper", link: "" });
      this.state.mna.push({ fknid: "btnlist", pos: "c2", text: "KNAPPAR", desc: "Knapp lists", link: "" });
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.btn_grps();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 480;
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }
    change_avd(e) {
        //let cdat = this.state.seldate;
        this.pos.plus = [];
        let avd = e.target.value;
        this.setState({avd: avd});
    }

  btn_view() {
    var self = this;
      var wnds = {};
      wnds.btn_view = true;
      wnds.avd = ddo.cache.avd;
    this.setState({ wnds: wnds });
  }
  btn_term() {
      var self = this;
    var wnds = { btn_term: true };
    this.setState({ wnds: wnds });
  }
  btn_grps() {
      var self = this;
    var wnds = { btn_grps: true };
    this.setState({ wnds: wnds });
  }
  btn_btns() {
      var self = this;
    var wnds = {};
    wnds.btn_btns = true;
    wnds.avd = ddo.cache.avd;
    this.setState({ wnds: wnds });
  }
  btn_fkns() {
    var self = this;
    var wnds = { btn_fkns: true };
    this.setState({ wnds: wnds });
  }

  fkn_sel(fknid) {
    var self = this;

      if(fknid == "btns") this.btn_view();
      if(fknid == "term") this.btn_term();
      if(fknid == "btngrp") this.btn_grps();
      if(fknid == "btnlist") this.btn_btns();
  }
    mnc(pos) {
        var mna = [];
        for(var row of this.state.mna) {
            if(row.pos == pos) mna.push( row );
        }
        return mna;
    }

  render() {
    return (
    <div className="web-app">
        <div className="web-pg py-4 flex">
            <h3>Knappar</h3>
            <p>Knapp funktioner</p>

    <div className="flex-row">
        <select className="form-control w250 hide" placeholder="Välj avdelning" onChange={(e) => this.change_avd(e)}>
            { this.props.store.pos.deps.map((row, key) =>
                <option key={key} value={row.avd}>{row.avd} - {row.text}</option>
            )}
        </select>
        <div className="flex"></div>
        <button className="btn btn-hw-orange w150 hide" onClick={() => this.btn_fkns() } aria-label="System" >
            Funktioner
        </button>
        <button className="btn btn-hw-blue w150" onClick={() => this.plus() } aria-label="System" >
            Visa
        </button>
    </div>
    <div className="divider_orange"></div>

        <div className="web-row">
            <div className="flex-col flex">
                { this.mnc("c1").map((row, key) =>
                    <div key={key} className="web-bx" onClick={() => this.fkn_sel(row.fknid) }>
                        <div>{ row.text }</div>
                        <div className="flex-row">
                            <div className="flex">{ row.desc }</div>
                            <div className="web-link">Välj</div>
                        </div>
                    </div>
                )}
            </div>
            <div className="flex-col flex">
                { this.mnc("c2").map((row, key) =>
                    <div key={key} className="web-bx" onClick={() => this.fkn_sel(row.fknid) }>
                        <div>{ row.text }</div>
                        <div className="flex-row">
                            <div className="flex">{ row.desc }</div>
                            <div className="web-link">Välj</div>
                        </div>
                    </div>
                )}
            </div>
        </div>
        </div>


    { this.state.wnds.btn_view ? <Btn_View wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
    { this.state.wnds.btn_term ? <Btn_Term wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
    { this.state.wnds.btn_grps ? <Btn_Grps wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
    { this.state.wnds.btn_btns ? <Btn_Btns wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
    { this.state.wnds.btn_fkns ? <Btn_Fkns wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
    </div>

    )}
}
export default observer(Pos_Btns);
