import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";

import * as utils from "../../assets/utils";
import ddo from '../../ddo.js'
import sso from "../../sso";

class Tmpl_Sids extends React.Component {
  constructor(props, context) {
    super(props);

    //this.pos = props.store.pos;
    this.state = {};
    this.state.lsa = [];
    this.state.lso = {};
    //this.state.lro = props.lro;
    this.state.tya = [
                {id: '01', text: 'Summerad'},
                {id: '02', text: 'Dag för dag'}
            ];
    this.state.aa = [];
      this.state.cols = {};

      this.state.sid = sso.sdo.sid;
      this.state.sdo = {};

    this.state.today = new Date();

    this.state.pdf = 0;
    this.state.pdf_content = null;
      this.state.sda = [
          {sid: 'S0001393', text: 'KFT - KULTUR OCH FRITID'},
          {sid: 'S0001394', text: 'FG - FÖRSKOLA OCH GRUNDSKOLA'},
          {sid: 'S0001395', text: 'TE - TEKNIK'},
          {sid: 'S0001396', text: 'IF - INDIVID OCH FAMILJEOMSORG'},
          {sid: 'S0001397', text: 'GA - GYMNASIUM OCH ARBETSMARKNAD'}
      ];
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    tab_style(tabid) {
      var css = {};
      css.display = "none";
      if(this.state.tabval === tabid) css.display = "block";
      return css;
    }
    tab_head(tabid) {
        var css = {};
        css.background = "#fff";
        css.color = "#000";
        if(this.state.tabval === tabid) {
            css.background = "#080";
            css.color = "#fff";
        }
        return css;
    }
    change_sid(e) {
        //let cdat = this.state.seldate;0
        let key = e.target.value;
        let sdo = ddo.cache.deps[key];
        this.setState({sdo: sdo});
    }
    sid_sel(row) {
        var self = this;
        this.setState({ sdo: row }, function() {
            //ddo.cache.sid = row.sid;
            //ddo.cache.avdtxt = row.text;
            //alert(JSON.stringify(ddo.cache.avd));
            //self.props.cb();
            sso.sdo.sid = row.sid;
            this.tmpl_reinit();
        });
    }

    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    handleClose = () => {
		this.props.ctx.setState({ rptyp: "" });
	};

    tb_style() {
        var css = {};
        css.height = window.innerHeight - 275;
        return css;
    }
    sel_unit() {
        var sdo = this.state.sdo;
        if(!sdo) {
            this.props.cb();
            return;
        }
        //ddo.cache.avd = dpo.avd;
        //ddo.cache.avdtxt = dpo.text;
        sso.sdo.sid = sdo.sid;
        this.tmpl_reinit();
        //alert(JSON.stringify(dpo.avd));
        //this.props.cb();
    }
    wnd_close() {
        //this.props.ctx.setState({ wnds: {} });
        this.props.cb();
    };

    tmpl_reinit() {
        var self = this;

        ddo.cache.sid = sso.sdo.sid;
        ddo.cache.sidtxt = "";
        if(ddo.cache.sid == "S0001393") ddo.cache.sidtxt = "KFT";
        if(ddo.cache.sid == "S0001394") ddo.cache.sidtxt = "FG";
        if(ddo.cache.sid == "S0001395") ddo.cache.sidtxt = "TE";
        if(ddo.cache.sid == "S0001396") ddo.cache.sidtxt = "IF";
        if(ddo.cache.sid == "S0001397") ddo.cache.sidtxt = "GA";

        ddo.cache.avd = sso.seo.avd;
        ddo.cache.auth = sso.seo.auth;
        //alert(JSON.stringify(ddo.cache));

        ddo.cache.depo = {};
        ddo.cache.deps = [];
        ddo.cache.vgrps = [];
        ddo.cache.vga = [];
        ddo.cache.mkods = {};

        var req = {};
        req.req = "pos.pos_fkn.pos_init";

        net.sio_req(req)
            .then(function(ret){
                //alert(JSON.stringify(ret));

                if(!self.props.store.pos) self.props.store.pos = {};
                self.props.store.pos.deps = ret.rco.deps;
                self.props.store.pos.vgrps = ret.rco.vgrps;
                if(ret.rco.depo) ddo.cache.depo = ret.rco.depo;
                if(ret.rco.deps) ddo.cache.deps = ret.rco.deps;
                if(ret.rco.vgrps) ddo.cache.vgrps = ret.rco.vgrps;
                if(ret.rco.mkods) ddo.cache.mkods = ret.rco.mkods;

                var avd = Object.keys(ddo.cache.deps).sort()[0];
                if((avd * 1) > (ddo.cache.avd * 1)) {
                    ddo.cache.avd = avd;
                }
                ddo.cache.avdtxt = "";
                if(ddo.cache.depo[ddo.cache.avd]) {
                    var dpo = ddo.cache.depo[ddo.cache.avd];
                    //alert(JSON.stringify(dpo));
                    ddo.cache.avdtxt = dpo.text;
                }
                var a = [];
                a.push({ var00: { v0katnr: '000', v0text: 'ALLA VGRP' } });
                if(ddo.cache.vgrps) a = a.concat(ddo.cache.vgrps);
                ddo.cache.vga = a;
                //alert(JSON.stringify(ddo.cache.vgrps));
                self.props.cb();
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
                //alert(JSON.stringify(txt));
            })
        //ddo.cache.auth = "09";
    }

  render() {
    if(!this.props.wnds || !this.props.wnds.sids) return "";
    var title = this.props.title;
    if(!title) title = "";

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="mx_backdrop" /> : null }
        <div className="mx_dlg mx_80h flex-col" style={ this.dlg_style() }>
        <div className="mx_head mx_bg flex-row flex-space-between">
            <div className="flex-col flex">
                <div>FÖRVALTNINGS VAL</div>
            </div>
        </div>
        <div className="mx_content flex">
            <div className="flex-row">
                    <div className="flex-col flex">
                        <div>Start</div>
                    </div>
                    <div className="flex-col flex">
                        <div></div>
                    </div>
                    <div className="flex-col w50">
                        <div>Ändra</div>
                    </div>
            </div>
            <div className="divider_orange"></div>

        <div className="mx_body flex">

        <div className="mnu-grid-3">
            { this.state.sda.map((row, key) =>
                <div key={key} className="web-mnu-btn text-center" onClick={() => this.sid_sel(row) }>
                    <div className="txt_16">
                        { utils.toLang( row.text ) }
                    </div>
                    <div className="txt_14">
                        { utils.toLang( row.sid ) }
                    </div>
                </div>
            )}
        </div>
        <div className="flex-row hide">
              <div className="flex-col">
                <span>Välj Enhet</span>
                <select className="form-control w250" value={this.state.sid} placeholder="Avdelning" onChange={(e) => this.change_sid(e)}>
                    { this.state.sda.map((row, key) =>
                    <option key={key} value={key}>{row.sid} - { utils.toLang(row.text) }</option>
                    )}
                </select>
            </div>
            </div>

        </div>

        </div>

        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w150" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex-row"></div>
            <button className="btn btn-hw-green w150" onClick={(event) => this.sel_unit(event) } >
                VÄLJ SYSTEM
            </button>
        </div>
      </div>
    </div>
	;
	return html;
  };
}

export default Tmpl_Sids;