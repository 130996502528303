import React, { useState, useEffect, useContext } from 'react';
import { format, addDays } from 'date-fns'

import * as net from "../../lib/net";

import Dt_Cal from "../../lib/ui/dt_cal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class PMS_BelCal extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, tra: [] };
    this.state.avd = "01";
    this.state.rva = [];
    this.state.kro = {};
    this.state.aa = [];
    this.state.cols = {};
    this.state.firstday = {};
    this.state.caa = [];
    this.state.rta = [];
    this.state.kfa = [];
    this.state.rsa = [];
    this.state.dio = {};
    this.state.loading = true;

    this.state.fdate = new Date();
    this.state.tdate = addDays(this.state.fdate, 15);

    //context.posrend = this;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.cal_show();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  row_style(dto) {
      var css = {};
      //css.height = window.innerHeight - 275;
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

  dt_style(dto) {
      //dto.wday == '0';
      return;
  }
  dt_class(dto) {
      var cls = "";
      if(dto.wday == 5) cls = "weekend";
      if(dto.wday == 6) cls = "weekend2";
      return cls;
  }
  get_css(dto) {
      var cls = "";
      if(dto.wday == 5) cls = "weekend";
      if(dto.wday == 6) cls = "weekend2";
      return cls;
  }
  dt_check(dto) {
      //dto.wday == '0';
      return true;
  }
  dioenter(dto) {
      //dto.wday == '0';
      return;
  }
  dioleave(dto) {
      //dto.wday == '0';
      return;
  }
  set_di(dto) {
      //dto.wday == '0';
      return;
  }
  selov(dto) {
      //dto.wday == '0';
      return;
  }
  seldn(dto) {
      //dto.wday == '0';
      return;
  }
  selup(dto) {
      //dto.wday == '0';
      return;
  }
  kund_open(row) {
      var self = this;
  }
  kund_edit(row) {
      var self = this;
      //alert(JSON.stringify(row.krr00));
        this.setState({edit: 1, kro: row});
  }
  fkn_close() {
    this.setState({edit: false });
    //this.props.history.push("cas");
    //this.login_srv(uid, pwd);
  }

  cal_show() {
    var self = this;
    var fdat = format(this.state.fdate, "yyyy-MM-dd");
    var dat = addDays(this.state.fdate, 30);
    var tdat = format(dat, "yyyy-MM-dd");

    var prm = {};
    prm.req = "pms.cal.pms_cal_state";
    prm.fdat = fdat;
    prm.tdat = tdat;
    //prm.avd = this.state.avd;

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        //alert(JSON.stringify(ret.rta));
        var caa = [];
        var keys = Object.keys(ret.rco);
        for(var key of keys) {
            var dto = ret.rco[key];
            //alert(JSON.stringify(key));

            caa.push( dto );
        };

        self.setState({caa: caa, loading: false }, function () {
        });
        self.hot_show();
    })
    .catch(function(e) {
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }
  hot_show() {
    var self = this;
    var datum = format(this.state.fdate, "yyyy-MM-dd");

    var prm = {};
    prm.req = "pms.rt.rt_state";
    prm.datum = datum;

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        //alert(JSON.stringify(ret.rta));
        var rta = [];
        var keys = Object.keys(ret.rco);
        for(var key of keys) {
            var rto = ret.rco[key];
            //alert(JSON.stringify(key));

            rta.push( rto );
        };

        self.setState({rta: rta, loading: false });
        self.kf_show();
    })
    .catch(function(e) {
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }
  kf_show() {
    var self = this;
    var datum = format(this.state.fdate, "yyyy-MM-dd");

    var prm = {};
    prm.req = "pms.rs.rs_per_dt";
    prm.fdat = datum;
    prm.tdat = datum;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        /*
        var kfa = [];
        var keys = Object.keys(ret.rco);
        for(var key of keys) {
            var kfo = ret.rco[key];
            //alert(JSON.stringify(key));

            kfa.push( kfo );
        };
        */
        var kfa = ret.rco.rsa;
        self.setState({kfa: kfa, loading: false });
    })
    .catch(function(e) {
        var txt = JSON.stringify(e);
    });
  }
  rs_show() {
    var self = this;
    var datum = format(this.state.fdate, "yyyy-MM-dd");

    var prm = {};
    prm.req = "pms.rs.rs_per_dt";
    prm.fdat = datum;
    prm.tdat = datum;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        /*
        var kfa = [];
        var keys = Object.keys(ret.rco);
        for(var key of keys) {
            var kfo = ret.rco[key];
            //alert(JSON.stringify(key));

            kfa.push( kfo );
        };
        */
        var kfa = ret.rco.rsa;
        self.setState({kfa: kfa, loading: false });
    })
    .catch(function(e) {
        var txt = JSON.stringify(e);
    });
  }
  cssCols = function (cid) {
      var css = {};
      if(this.state.cols[cid]) css.width = this.state.cols[cid].toString() + "px";
      //if(cid == "c01") alert(JSON.stringify(this.state.cols[cid]));
      return css;
  }
  syncCols = function (tb) {

    if (!document.getElementById(tb)) return;

    var cols = {};
    var ix = document.getElementById(tb).rows.length - 1;
    var ic = document.getElementById(tb).rows[0].cells.length;
    var ci = 0;
    while(ci < ic) {
        var cxi = ci + 1;
        var col = "c" + cxi.toString().padStart(2, "0");
        cols[col] = document.getElementById(tb).rows[ix].cells[ci].clientWidth + 1;
        ci = ci + 1;
    }
    this.setState({cols: cols });
    //alert(JSON.stringify(this.state.cols));
    //this.forceUpdate();
  };

  render() {
    return (
    <div className="web-app">

    <div className="flex-row flex-space-between">
        <Dt_Cal setdat={this.state.fdate} getValue={(e)=> this.setState({fdate: e}) }/>
        <div className="flex"></div>
        <button className="btn btn-hw-blue w150 ripple" onClick={() => this.bel_rt() } aria-label="System" >
            UPPDATERA
        </button>
    </div>
    <div className="divider_orange"></div>

    { this.state.loading ? <div className="flex center"><FontAwesomeIcon icon="spinner" spin /></div> : null }
    { !this.state.loading ?
    <div className="web-row">
    <div className="web-col flex f60">

		<table className="table table-bordered table-striped tb_pres" style={{marginBottom: "0px",marginTop: "2px"}}>
			<thead>
			<tr>
				<td style={{textAlign: "center"}}>Datum</td>
				<td style={{textAlign: "center"}}>Vecka</td>
				<td style={{textAlign: "center"}}>Dag</td>
				<td style={{textAlign: "center"}}>S</td>
				<td style={{textAlign: "center"}}>H</td>
				<td style={{textAlign: "center"}}>K</td>
				<td style={{textAlign: "center"}}>R</td>
				<td style={{textAlign: "center"}}>Belägg</td>
				<td style={{textAlign: "center"}}>Information</td>
			</tr>
			</thead>
			<tbody>
            { this.state.caa.map((dto, key) =>
			<tr className="" style={ this.row_style(dto) }>
				<td class='header' ng-click="selrt();">{ dto.date }</td>
				<td>{ dto.week }</td>
				<td>{ dto.day }</td>
				<td>{ dto.cleaned }</td>
				<td>{ dto.nrr } / { dto.free }</td>
				<td></td>
				<td></td>
				<td>{ dto.belag } %</td>
				<td style={{textAlign: "left"}}>{ dto.info }</td>
			</tr>
            )}
			<tr className="hide">
				<th style={{textAlign: "center"}}>Datum</th>
				<th style={{textAlign: "center"}}>Vecka</th>
				<th style={{textAlign: "center"}}>Dag</th>
				<th style={{textAlign: "center"}}>S</th>
				<th style={{textAlign: "center"}}>H</th>
				<th style={{textAlign: "center"}}>K</th>
				<th style={{textAlign: "center"}}>R</th>
				<th style={{textAlign: "center"}}>Belägg</th>
				<th style={{textAlign: "center"}}>Information</th>
			</tr>
			</tbody>
			</table>

    </div>

    <div className="web-col f40">
        <table className="table table-bordered table-striped tb_pres" style={{margin: "0px"}}>
            <tr>
                <td style={{textAlign: "center", maxWidth: "200px"}}>Rumstyp</td>
                <td style={{textAlign: "center"}}>Ank</td>
                <td style={{textAlign: "center"}}>Bo</td>
                <td style={{textAlign: "center"}}>Avr</td>
                <td style={{textAlign: "center"}}>Fria</td>
                <td style={{textAlign: "center"}}>Pris</td>
            </tr>
            <tbody>
            { this.state.rta.map((rto, key) =>
            <tr key={key}>
                <td className='header'>{ rto.rt }</td>
                <td>{ rto.ankr }/{ rto.ankg }</td>
                <td>{ rto.borr }/{ rto.borg }</td>
                <td>{ rto.avrr }/{ rto.avrg }</td>
                <td>{ rto.free }</td>
                <td>{ rto.maxr }</td>
            </tr>
            )}
            </tbody>
        </table>

        <table className="table table-bordered table-striped tb_pres" style={{margin: "0px"}}>
            <tr>
                <td style={{textAlign: "center", maxWidth: "200px"}}>Lokal</td>
                <td style={{textAlign: "center"}}>F</td>
                <td style={{textAlign: "center"}}>E</td>
                <td style={{textAlign: "center"}}>K</td>
                <td style={{textAlign: "center"}}>Fria</td>
                <td style={{textAlign: "center"}}>Pris</td>
            </tr>
            <tbody>
            { this.state.kfa.map((kfo, key) =>
            <tr ng-dblclick="">
                <td className='header' ng-click="">{ kfo.restname }</td>
                <td>{ kfo.status }</td>
                <td>{ kfo.status }</td>
                <td>{ kfo.status }</td>
                <td>{ kfo.platser }</td>
                <td>{ kfo.reservtime }</td>
            </tr>
            )}
            </tbody>
        </table>

        <table className="table table-bordered table-striped tb_pres" style={{margin: "0px"}}>
            <tr>
                <td className='header' style={{textAlign: "center", maxWidth: "200px"}}>Rumstyp</td>
                <td style={{textAlign: "center"}}>FR</td>
                <td style={{textAlign: "center"}}>FM</td>
                <td style={{textAlign: "center"}}>LU</td>
                <td style={{textAlign: "center"}}>EM</td>
                <td style={{textAlign: "center"}}>KV</td>
                <td style={{textAlign: "center"}}>Fria</td>
                <td style={{textAlign: "center"}}>Pris</td>
            </tr>
            <tbody>
            { this.state.rsa.map((rso, key) =>
            <tr ng-dblclick="sel_guest();">
                <td className='header' ng-click="selrt();">{ rso.restname }</td>
                <td>{ rso.iresurs }</td>
                <td>{ rso.platser }</td>
                <td>{ rso.platser }</td>
                <td>{ rso.platser }</td>
                <td>{ rso.platser }</td>
            </tr>
            )}
            </tbody>
        </table>

    </div>

    </div>
        : null }
   </div>
  );
    }
}
export default PMS_BelCal;

/*
*/