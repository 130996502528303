import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../../lib/net";
import {withRouter} from "../../../lib/react/withRouter";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Red_Eko extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, tra: [] };
    this.state.seldate = new Date();
    this.state.avd = "01";
    this.state.paa = [];
    this.state.aa = [];

      this.state.mna = [];
      this.state.mna.push({ mnid: "mn01", pos: "c1", text: "OMSÄTTNING", desc: "", link: "/pms/hotback/eko/oms" });
      this.state.mna.push({ mnid: "mn02", pos: "c1", text: "SALDO", desc: "", link: "/pms/hotback/eko/saldo" });
      this.state.mna.push({ mnid: "mn03", pos: "c1", text: "BOKNINGAR", desc: "", link: "/pms/hotback/eko/rvs" });
      this.state.mna.push({ mnid: "mn04", pos: "c2", text: "HUVUDBOK", desc: "", link: "/pms/hotback/eko/hbok" });
      this.state.mna.push({ mnid: "mn05", pos: "c2", text: "TRANSAKTIONER", desc: "", link: "/pms/hotback/eko/trs" });
      this.state.mna.push({ mnid: "mn06", pos: "c2", text: "NOTOR", desc: "", link: "/pms/hotback/eko/invs" });
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 480;
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

    mnc(pos) {
        var mna = [];
        for(var row of this.state.mna) {
            if(row.pos == pos) mna.push( row );
        }
        return mna;
    }

  fknGo(url) {
    //alert(row.href);
    this.props.navigate(url);
  }

  render() {
    return (
    <div className="web-app">
        <div className="web-pg py-4 flex">
            <h3>Redovisning Ekonomi</h3>
            <p>Rapport funktioner</p>

            <div className="divider_green"></div>

            <div className="web-row">
                <div className="flex-col flex">
                    { this.mnc("c1").map((row, key) =>
                        <div key={key} className="web-bx" onClick={() => this.fknGo(row.link) }>
                            <div>{ row.text }</div>
                            <div className="flex-row">
                                <div className="flex">{ row.desc }</div>
                                <div className="web-link"></div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="flex-col flex">
                    { this.mnc("c2").map((row, key) =>
                        <div key={key} className="web-bx" onClick={() => this.fknGo(row.link) }>
                            <div>{ row.text }</div>
                            <div className="flex-row">
                                <div className="flex">{ row.desc }</div>
                                <div className="web-link"></div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    </div>
  );
    }
}
export default withRouter(Red_Eko);
