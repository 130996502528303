import React, { useState, useEffect, useContext } from 'react';
import {format, addDays, subDays, getHours} from "date-fns";

import * as net from "../../lib/net";
import * as utils from "../../assets/utils";

import Lang from "../../lib/lang";
import Dt_Cal from "../../lib/ui/dt_cal";
import Td_Sel from "../../lib/ui/td_sel";

import Pms_AnkBr from "./ank/ank_br";
import Pms_AnkGst from "./ank/ank_gst";
import Pms_Rf from "./fkn/rf";
import Wnd_Sign from "../../lib/ui/wnd_sign";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Pms_Fo extends React.Component {
  constructor(props, context) {
    super(props);

    this.dt_dt = React.createRef();

    this.store = props.store;
    this.state = {};
    this.state.avd = "01";
    this.state.rvs = [];
    this.state.rva = [];
    this.state.msg = {};

    this.state.aa = [];
    this.state.anksel = 0;
    this.state.boknr = "";
    this.state.rvo = {};
    this.state.wnds = {};
    this.state.selrow = {};
    this.state.sz_val = "";
    this.state.sortid = "";
    this.state.sortdir = 1;
    this.state.showanm = 1;
    this.state.loading = false;

    this.state.today = new Date();
    var hh = getHours(this.state.today);
    if( hh < 5) {
        const dt = subDays(new Date(), 1);
        this.state.dts = format(dt, "yyyy-MM-dd");
    }
    else this.state.dts = format(this.state.today, "yyyy-MM-dd");

    this.selitems = [
        {fkn: 'open', text: 'ÖPPNA'},
        {fkn: 'br', text: 'DELBOKNING'},
        {fkn: 'cancel', text: 'AVBOKA'}
    ];
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.ank();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 210;
      css.overflow = "auto";
      return css;
  }
  tbrow_style() {
      var css = {};
      css.border = "1px solid #ff9900";
      return css;
  }
  btnanm_style() {
    var css = {};
    css.height = "40px";
    css.lineHeight = "40px";
    css.padding = "0px 10px";
    css.fontSize = "12px";
    css.cursor = "pointer";
    css.background = "#080";
      css.color = "#fff";
      css.borderLeft = "1px solid #222";
      css.borderRight = "1px solid #222";
      if(this.state.showanm) {
          css.background = "#800";
          css.color = "#fff";
          css.borderLeft = "1px solid #222";
          css.borderRight = "1px solid #222";
      }
      return css;
    }
    sz_style() {
        var css = {};
        css.height = "40px";
        css.lineHeight = "40px";
        css.padding = "0px 10px";
        css.fontSize = "12px";
        css.cursor = "pointer";
        return css;
    }
    th_style(id) {
          var css = {};
      css.cursor = "pointer";
      if(id == this.state.sortid) {
          if(this.state.sortdir) css.color = "#800";
          else css.color = "#080";
      }
      return css;
  }
  row_style(row) {
    var srow = this.state.selrow;

    var css = {};
    if(srow == row) {
        css.background = "#080";
        css.color = "#fff";
    }
    return css;
}
clean_style(row) {
    var css = {};
    css.background = "#ff8";
    css.color = "#222";

    if(row.clean == "1") {
        css.background = "#080";
        css.color = "#fff";
    }
    if(row.clean == "2") {
        css.background = "#f90";
        css.color = "#fff";
    }
    return css;
}
btn_anm() {
      var showanm = this.state.showanm;
      if(showanm) showanm = 0;
      else showanm = 1;
      this.setState({showanm: showanm});
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }
    sel_row(row) {
        var srow = this.state.selrow;
        if(srow == row) {
            this.setState({selrow: {}});
        }
        else this.setState({selrow: row});
    }

  dt_dat(e) {
    //alert(JSON.stringify(e));
    var dts = format(e, "yyyy-MM-dd");
    this.setState({ dts: dts });
  }
  addday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_dt.current.updateAdd();
  }
  subday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_dt.current.updateSub();
  }
    sel_gst(row) {
        var self = this;
        var msg = { info: false, sign: true, text: "Signatur?", text0: "JA", text1: "NEJ", cb: "sel_gst_do" };
        self.setState({ msg: msg, srow: row });
    }
    sel_gst_do(signatur) {
        var self = this;
        var row = this.state.srow;
        self.setState({msg: {} });

    if(row.typ == "D") {
        var wnds = {};
        wnds.ankbr = 1;
        wnds.boknr = row.boknr;
        wnds.rvo = row;
        this.setState({ wnds: wnds });
    }
    if(row.typ == "G") {
        var wnds = {};
        wnds.ankgst = 1;
        wnds.boknr = row.boknr;
        wnds.rvo = row;
        this.setState({ wnds: wnds });
    }
  }
  sel_row_fkn(row, sel) {
      //alert(JSON.stringify(fkn));
    if(sel.fkn == "open") {
        this.sel_gst(row);
    }
    if(sel.fkn == "br") {
        var wnds = {};
        wnds.ankbr = true;
        wnds.boknr = row.boknr;
        wnds.rvo = row;
        this.setState({wnds: wnds});
    }
  }

  ank() {
    var self = this;
      this.setState({rvs: [], rva: [], loading: true});

    var prm = {};
    prm.req = "pms.op.my_arrivals";
    prm.datum = this.state.dts;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rco));

        self.setState({ rvs: ret.rco, rva: ret.rco, loading: false }, function() {
            self.syncTb("ankTable");
        });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  fkn() {
      var self = this;
  }
  rf() {
    var self = this;
    var wnds = {};
    wnds.rf = true;
    this.setState({wnds: wnds});
  }
    syncTb(tb){
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
        tdElements = tbel.getElementsByTagName('td');

        for (let i = 0; i < thElements.length; i++) {
            if(!tdElements[i]) continue;
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
            width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
   th_icon(typ) {
      var cls = "";
      var sortid = this.state.sortid;
      var sortdir = this.state.sortdir;
      if(sortid == typ)
      {
        if(sortdir) cls = "fa fa-caret-up";
        else cls = "fa fa-caret-down";
      }
      return cls;
    }
   tb_sort(typ) {
      var self = this;
      //var rvs = self.state.rvs;
      var rva = self.state.rva;
      var sortid = this.state.sortid;
      var sortdir = this.state.sortdir;

      if(sortid != typ) sortdir = 0;
      else {
        if(sortdir) sortdir = 0;
        else sortdir = 1;
      }

      if(sortdir) rva.sort((a,b) => (a[typ] > b[typ]) ? 1 : ((b[typ] > a[typ]) ? -1 : 0));
      else rva.sort((a,b) => (a[typ] < b[typ]) ? 1 : ((b[typ] < a[typ]) ? -1 : 0));
      self.setState({ rva: rva, sortid: typ, sortdir: sortdir });
   }
  tb_filtr() {
      var self = this;
      var rvs = self.state.rvs;
      var sz = this.state.sz_val;

      if(sz.length < 1) {
        self.setState({ rva: rvs });
          return;
      }
      sz = sz.toUpperCase();
      var rva = rvs.filter(function(row) {
            var brc = false;
            if(row.company.toUpperCase().includes(sz)) brc = true;
            if(row.name.toUpperCase().includes(sz)) brc = true;
            if(row.rumsnr.toUpperCase().includes(sz)) brc = true;
            if(row.boknr.toUpperCase().includes(sz)) brc = true;
            return brc;
      });
      self.setState({rva: rva });
   }
  val_date(val) {
      var ret = "";
      if(val) ret = val.toString().cdidate();
      return ret;
  }
  val_rum(val) {
      var ret = "";
      if(val) ret = val.toString().cdirumsnr();
      return ret;
  }
  val_pris(val) {
      var ret = "";
      if(val) ret = val.toString().cdipris();
      return ret;
  }
    sz_keydown (e) {
      //e.preventDefault();
        const key = e.key;
        const keyCode = e.keyCode;

        if(keyCode == 13) {
            this.tb_filtr();
        };
        if(keyCode == 27) {
            this.setState({sz_val: ""});
        };
    }
    sz_change (e) {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({sz_val: value});
        //setState({[name]: value});
    }

  render() {
    return (
    <div className="web-bas">

    <div className="flex-row">
        <div className="flex">
        <button className="btn btn-hw-blue w150 hide" onClick={() => this.fkn() } aria-label="System" >
            Funktioner
        </button>
        <button className="btn btn-hw-blue w200" onClick={() => this.rf() } aria-label="System" >
            Rumsfördela
        </button>
        </div>

        <div className="flex-row">
              <div className="dt-btn-01" onClick={() => this.subday() } ><FontAwesomeIcon icon="minus" size="1x" /></div>
              <Dt_Cal dts={this.state.dts} rp="1" ref={this.dt_dt} getValue={(e)=> this.dt_dat(e)} />
              <div className="dt-btn-01" onClick={() => this.addday() } ><FontAwesomeIcon icon="plus" size="1x" /></div>
        </div>
        <div className="flex-col" style={ this.btnanm_style() } onClick={() => this.btn_anm() }>
            <div>ANM</div>
        </div>

        <div className="flex flex-end">
        <input className="txt sztxt" value={this.state.sz_val} placeholder="Ange söktext..."  style={ this.sz_style() } onChange={(e) => this.sz_change(e)} onKeyDown={(e) => this.sz_keydown(e)} />
        <button className="btn btn-hw-blue w200" onClick={() => this.ank() } aria-label="System" >
            UPPDATERA
        </button>
        </div>
    </div>
    <div className="divider_orange"></div>

    { this.state.loading ? <div className="flex flex-col flex-center" style={{ overflow: "hidden" }}><FontAwesomeIcon icon="spinner" spin size="4x" /></div> : null }
    { !this.state.loading ?
    <div className="web-col">

        <div id="ankTable">
          <table className="table table-bordered table-hover" style={{marginBottom: "0px"}}>
            <thead>
            <tr>
                <th style={ this.th_style("rumsnr") } onClick={() => this.tb_sort("rumsnr") }><Lang>Roomnr</Lang> <i className={ this.th_icon("rumsnr") }></i></th>
                <th style={ this.th_style("boknr") } onClick={() => this.tb_sort("boknr") }><Lang>Resno</Lang> <i className={ this.th_icon("boknr") }></i></th>
                <th style={ this.th_style("kanal") } onClick={() => this.tb_sort("kanal") }><Lang>Segment</Lang> <i className={ this.th_icon("kanal") }></i></th>
                <th style={ this.th_style("company") } onClick={() => this.tb_sort("company") }><Lang>Company</Lang> <i className={ this.th_icon("company") }></i></th>
                <th style={ this.th_style("name") } onClick={() => this.tb_sort("name") }><Lang>Name</Lang> <i className={ this.th_icon("name") }></i></th>
                <th style={ this.th_style("nrr") } onClick={() => this.tb_sort("nrr") }><Lang>Rooms</Lang> <i className={ this.th_icon("nrr") }></i></th>
                <th style={ this.th_style("nrg") } onClick={() => this.tb_sort("nrg") }><Lang>Guests</Lang> <i className={ this.th_icon("nrg") }></i></th>
                <th style={ this.th_style("rt") } onClick={() => this.tb_sort("rt") }><Lang>Roomtype</Lang> <i className={ this.th_icon("rt") }></i></th>
                <th style={ this.th_style("anktid") } onClick={() => this.tb_sort("anktid") }><Lang>Arrivaltime</Lang> <i className={ this.th_icon("anktid") }></i></th>
                <th style={ this.th_style("avr") } onClick={() => this.tb_sort("avr") }><Lang>Departure</Lang> <i className={ this.th_icon("avr") }></i></th>
                <th style={ this.th_style("bettyp") } onClick={() => this.tb_sort("bettyp") }><Lang>Paytype</Lang> <i className={ this.th_icon("bettyp") }></i></th>
                <th style={ this.th_style("typ") } onClick={() => this.tb_sort("typ") }><Lang>Type</Lang> <i className={ this.th_icon("typ") }></i></th>
                <th style={ this.th_style("status") } onClick={() => this.tb_sort("status") }><Lang>Status</Lang> <i className={ this.th_icon("status") }></i></th>
                <th></th>
            </tr>
            </thead>
            </table>
            <div style={ this.tb_style() }>
          <table className="table table-bordered table-hover tbs01">
            { this.state.rva.map((row, key) =>
            <tbody key={key}>
            <tr style={ this.row_style(row) } onClick={() => this.sel_row(row)} onDoubleClick={() => this.sel_gst(row)}>
                <td className="bold text-center" style={ this.clean_style(row) }>{ this.val_rum(row.rumsnr) }</td>
                <td>{ row.boknr }</td>
                <td>{ row.kanal }</td>
                <td>{ row.company }</td>
                <td className="bold">{ row.name }</td>
                <td>{ row.nrr.ztrim() }</td>
                <td>{ row.nrg.ztrim() }</td>
                <td>{ row.rt }</td>
                <td>{ utils.toTid( row.resv02.anktid ) }</td>
                <td>{ this.val_date(row.avr) }</td>
                <td>{ row.resv02.bettyp }</td>
                <td>{ row.typ }</td>
                <td>{ row.status }</td>
                <td>
                    <Td_Sel items={ this.selitems } getValue={(e)=> this.sel_row_fkn(row, e)} />
                </td>
            </tr>
            { ( this.state.showanm && row.anm ) ?
                <tr className="txt_tb_anm"  onDoubleClick={() => this.sel_gst(row)}>
                    <td colSpan={15}>{ row.anm.toswe() }</td>
                </tr>
            : null }
            </tbody>
            )}
        </table>
        </div>
        </div>
    </div>
    : null }

    { this.state.wnds.ankbr ?
        <Pms_AnkBr wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
    : null }
    { this.state.wnds.ankgst ?
        <Pms_AnkGst wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
    : null }
    { this.state.wnds.rf ?
        <Pms_Rf wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
    : null }
        { this.state.msg.sign ? <Wnd_Sign msg={this.state.msg} ctx={this} /> : null }
    </div>
  );
    }
}
export default Pms_Fo;

/*
                 <th style={ this.th_style("segment") } onClick={() => this.tb_sort("segment") }><Lang>Segment</Lang> <i className={ this.th_icon("segment") }></i></th>
                <td>{ row.segment }</td>

           <tr key={key} id={ row.boknr } onDoubleClick={this.sel_gst(row)}>
                <td className="cdired" style={{borderTop: "0px"}}>Anm:</td>
                <td className="cdired" colSpan="13">{row.anm}</td>
            </tr>
*/