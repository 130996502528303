import React, { useState, useEffect, useContext } from 'react';
import socketIOClient from "socket.io-client";
import axios from 'axios'

import * as net from "../../lib/net";
import Wnd_Ok from "../../lib/ui/wnd_ok";

class Tst_Emv extends React.Component {
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.state = {};
    this.state.bqa = [];
    this.state.tra = [];
    this.state.pfa = [];
    this.state.splitrows = {};
    this.state.msg = {};
    this.state.srs = {};
    this.state.emvreq = {};
    this.state.srs.connected = 0;
    this.sio = null;

    this.state.phase = "CC";

   }
  componentDidUpdate() {
  }
  componentWillUnmount() {
      this.srs_down();
  }
  componentDidMount() {
      //this.my_ip();
      this.srs_up();
      //this.ppr();
  }
    my_ip = async () => {
        const res = await axios.get('https://geolocation-db.com/json/');
        //console.log(res.data);
        alert(JSON.stringify(res.data));
        //setIP(res.data.IPv4)
    }

    srs_up() {
        var self = this;
        //var url = window.location.host;
        //var url = window.location.origin;
        //alert(JSON.stringify(url));
        var url = 'http://212.112.180.177:9431/';
        //var url = 'http://127.0.0.1:9231/';
        //if(true) url = 'https://services.svepos.se:9431/';
        this.sio = socketIOClient(url, {transports: ["websocket"]});
        //sio.emit('change color', this.state.color);

        this.sio.on('connect', function () {
            //alert('Connected!');
            //toastr.success("IO CONNECTED");
            var srs = self.state.srs;
            srs.connected = 1;
            self.setState({ srs: srs });
            self.emv_register();
        });
        this.sio.on('disconnect', function () {
            var srs = self.state.srs;
            srs.connected = 0;
            self.setState({ srs: srs });
        });
        this.sio.on('connect_error', function (e) {
            //alert('Connect Error : ' + JSON.stringify(e));
            //toastr.error("IO CONNECT ERROR", e);
        });
        this.sio.on('error', function (e) {
            //alert('Socket Error : ' + e);
            //toastr.error("IO ERROR", e);
        });
        this.sio.on('srs:rsp', function (base64) {
            //var s = atob(base64);
            //var rsp = JSON.parse(s);
            //alert(JSON.stringify(base64));
            var rsp = base64;
            var reqid = rsp.reqid;
            //if(self.defer[reqid]) self.defer[reqid].resolve(rsp);
            //if(defer[reqid]) defer[reqid].resolve(rsp);
        });
        this.sio.on('emv:req', function (base64) {
            //var s = atob(base64);
            //var req = JSON.parse(base64);
            var req = base64;
            //alert(JSON.stringify(req));
            if(req.fkn == "emv_sale") {
                var srs = self.state.srs;
                srs.fkn = req.fkn;
                srs.cmd = "DRAW CARD";
                srs.token = req.token;
                srs.kvitto = "";
                self.setState({ srs: srs, emvreq: req });
            }
            if(req.fkn == "emv_print") {
                var pbuf = "";
                var pfa = req.print.rows;
                for(var row of req.print.rows) {
                    //alert(JSON.stringify(row));
                    pbuf = pbuf + row[0].text;
                    pbuf = pbuf + "\r\n";
                    //break;
                }
                var srs = self.state.srs;
                srs.fkn = req.fkn;
                srs.kvitto = pbuf;
                self.setState({ srs: srs, pfa: pfa });
            }
            //var req = base64;
            //var reqid = req.reqid;
            //if(self.defer[reqid]) self.defer[reqid].resolve(rsp);
            //if(defer[reqid]) defer[reqid].resolve(rsp);
        });
        this.sio.on('emv:rsp', function (base64) {
            //var s = atob(base64);
            //var req = JSON.parse(base64);
            var req = base64;
            //alert(JSON.stringify(req));
            if(req.fkn == "reg") {
                var srs = self.state.srs;
                srs.reg = "EMV: " + req.emvnr;
                self.setState({ srs: srs });
            }
            //var req = base64;
            //var reqid = req.reqid;
            //if(self.defer[reqid]) self.defer[reqid].resolve(rsp);
            //if(defer[reqid]) defer[reqid].resolve(rsp);
        });
    }
    srs_down() {
        this.sio.disconnect();
    }
    srs_reset() {
        this.sio.disconnect();
    }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    tab_style(tabid) {
      var css = {};
      css.display = "none";
      if(this.state.tabval === tabid) css.display = "block";
      return css;
    }
    wnd_close() {
		this.props.ctx.setState({ wnds: {} });
	};
    tb_style() {
        var css = {};
        css.height = window.innerHeight - 275;
        return css;
    }
    row_style(row) {
        var css = {};
        var splitrows = this.state.splitrows;
        var key = row.r0dateid;
        if(splitrows[key]) {
            css.background = "#080";
            css.color = "#fff";
        }
        return css;
    }
    emv_register() {
        var self = this;
        var req = {};
        req.fkn = "emv";
        req.emvnr = "00";
        req.sid = "S0001122";
        this.sio.emit("emv:reg", req, function () {
        });

        var srs = self.state.srs;
        srs.cmd = "";
        srs.token = "";
        srs.registered = 1;
        self.setState({ srs: srs });
    }
    emv_drawcard() {
        var self = this;
        var srs = this.state.srs;
        srs.cmd = "CARD PROCESSING";
        this.setState({ srs: srs });

        //var rsp = {};
        var rsp = this.state.emvreq;
        rsp.fkn = "emv";
        rsp.rc = "000";
        rsp.sid = "S0001122";
        rsp.emvnr = "00";
        rsp.token = self.state.srs.token;
        this.sio.emit("emv:rsp", rsp, function () {});

        var srs = self.state.srs;
        srs.fkn = "";
        srs.cmd = "CARD DONE";
        srs.token = "";
        self.setState({ srs: srs });
    }
    emv_abort() {
        var self = this;
        var rsp = {};
        rsp.fkn = "emv";
        rsp.rc = "999";
        rsp.sid = "S0001122";
        rsp.emvnr = "00";
        rsp.token = self.state.srs.token;
        this.sio.emit("emv:rsp", rsp, function () {});

        var srs = this.state.srs;
        srs.cmd = "ABORT";
        srs.token = "";
        this.setState({ srs: srs });
    }
    pos_sale() {
        var self = this;
        var req = {};
        req.fkn = "sale";
        req.emvnr = "00";
        req.sid = "S0001122";
        req.belopp = "2200";
        this.sio.emit("emv:tst", req, function () {
        });
    }
    emv_poll() {
        var self = this;
        var req = {};
        req.req = "emv.poll";
        req.emvnr = "00";
        req.sid = "S0001122";
        this.sio.emit("emv:req", req, function () {
        });
    }
    emv_sale() {
        var self = this;
        var req = {};
        req.req = "emv.sale";
        req.emvnr = "00";
        req.sid = "S0001122";
        req.belopp = "37.00";
        this.sio.emit("emv:req", req, function () {
        });
    }

  ppr() {
    var self = this;
    var prm = {};
    prm.req = "vcm.pp.pp_rr";
    prm.notanr = "20318E";

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        self.setState({tra: ret.rca });
        self.syncTb("trsTable");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }
  pp_rows() {
    var self = this;
    var splitrows = this.state.splitrows;
    var srows = Object.values(splitrows);
    if(srows.length < 1) {
        alert("Inget att delbetala");
        return;
    }

    var prm = {};
    prm.req = "vcm.pp.pp_rows";
    prm.avd = "01";
    prm.notanr = "20318E";
    prm.betkod = "kont";
    prm.fbord = "260";
    prm.tbord = "260";
    prm.splitrows = [];

    for(var row of srows) {
        var o = {};
        o.radnr = row.r0lopnr;
        o.radtext = row.r0text;
        o.radantal = row.r0noof;
        o.radpris = row.r0perprice;
        o.radtot = row.r0perprice;
        o.tant = row.r0noof;
        o.ttot = row.r0perprice;
        o.ttyp = "";
        prm.splitrows.push( o );
    }

    net.sio_req(prm)
    .then(function(ret){
        alert(JSON.stringify(ret));

    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }
    syncTb(tb){
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
        tdElements = tbel.getElementsByTagName('td');

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
            width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
    skvinfo() {
        var self = this;

        var prm = {};
        prm.req = "vcm.pp.skv_info";
        prm.status = "";

        net.sio_req(prm)
        .then(function(ret){
            alert(JSON.stringify(ret));

            //self.setState({bqa: ret.rca });
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }

  render() {

    var title = this.props.title;
    if(!title) title = "Titel";

	var html =
        <div className="mx_base">
        { this.props.wnds.backdrop ? <div className="mx_backdrop" /> : null }
        <div className="mx_dlg mx_80 flex-col" style={ this.dlg_style() }>
        <div className="mx_content flex">

            <div className="mx_h01">
                <div>EMV TEST INTEGRATION</div>
                <div className="flex"></div>
                <div className="web-x-icon" onClick={() => this.wnd_close() }>X</div>
            </div>
        <div className="flex-row px-2">
            <div className="flex-col">
                <div className="flex-row">SRS: { this.state.srs.connected ? <div className="cdigreen">UPPKOPPLAD</div> : <div className="cdired">NEDKOPPLAD</div> }</div>
            </div>
            <div className="flex"></div>
            <div className="flex-row px-2">
                <div className="web-link px-2" onClick={() => this.srs_up() }>SRS UPP</div>
                <div className="flex"></div>
                <div className="web-link px-2" onClick={() => this.srs_down() }>SRS NER</div>
            </div>
        </div>
            <div className="divider_orange"></div>
            <div className="flex-row px-2">
                <button className="btn btn-hw-green w150" onClick={() => this.emv_poll() } aria-label="System" >
                    POLL EMV
                </button>
                <button className="btn btn-hw-green w150" onClick={() => this.emv_sale() } aria-label="System" >
                    SKICKA BELOPP
                </button>
                <button className="btn btn-hw-green w150" onClick={() => this.emv_settle() } aria-label="System" >
                    DAGS AVSLUT
                </button>
            </div>

        <div className="flex-row flex px-2">
            <div className="web-emv flex">
                <div className="flex-row">
                    <div className="flex"></div>
                    <div className="txt_18 text-center">EMV DISPLAY SHOWS</div>
                    <div className="flex"></div>
                </div>
                <div className="divider_blue"></div>

                <div className="flex-center flex-col flex">
                    <div className="txt_16">{ this.state.srs.cmd }</div>
                </div>
                { this.state.srs.fkn == "emv_sale" ?
                    <div className="flex-center flex-col flex">
                         <div className="flex-row px-2">
                            <button className="btn btn-hw-red w250" onClick={() => this.emv_abort() } aria-label="System" >
                                ABORT TRANSACTION
                            </button>
                            <button className="btn btn-hw-green w250" onClick={() => this.emv_drawcard() } aria-label="System" >
                                SIMULATE DRAW CARD
                            </button>
                        </div>
                    </div>
                : null }

                <div className="flex-row">
                    <div className="web-link">TOKEN: { this.state.srs.token }</div>
                    <div className="flex"></div>
                    <div className="web-link">REG: { this.state.srs.reg }</div>
                </div>
                <div className="divider_blue"></div>
            </div>
            <div className="px-2"></div>
            <div className="web-emv flex">
                <div className="txt_18 text-center">SENT TO PRINTER</div>
                <div className="divider_blue"></div>
                <div className="flex-col flex">
                    { this.state.pfa.map((row, key) =>
                    <div className="">{ row[0].text }</div>
                    )}
                </div>
            </div>
        </div>

        </div>

        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                CLOSE
            </button>
            <div className="flex"></div>
            <button className="btn btn-hw-blue w250" onClick={() => this.pos_sale() } >
                POS FKN
            </button>
            <div className="flex"></div>
            <button className="btn btn-hw-green w250" onClick={() => this.wnd_close() } >
                UPPDATERA
            </button>
        </div>
      </div>
        <Wnd_Ok msg={this.state.msg} ctx={this} />
    </div>
	;
	return html;
  };
}

export default Tst_Emv;