import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";
import {withRouter} from "../../lib/react/withRouter";
import sso from "../../sso";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Pos_Stat extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.pos = props.store.pos;
    this.state = { pgid: 11, tra: [] };
    this.state.seldate = new Date();
    this.state.avd = "01";
    this.state.paa = [];
    this.state.aa = [];
      this.state.mna = [];
      this.state.mna.push({ mnid: "mn01", pos: "c1", text: "TOTAL", desc: "Summerad total fsg", link: "/pos/stat/total" });
      this.state.mna.push({ mnid: "mn02", pos: "c1", text: "GRUPP", desc: "Fsg per varugrupp", link: "/pos/stat/grp" });
      this.state.mna.push({ mnid: "mn03", pos: "c1", text: "TIM", desc: "Fsg timme för timme", link: "/pos/stat/hh" });
      this.state.mna.push({ mnid: "mn04", pos: "c1", text: "VECKO", desc: "Veckosammanställning", link: "/pos/stat/week" });
      this.state.mna.push({ mnid: "mn05", pos: "c2", text: "PASS", desc: "PASS avstämning", link: "/pos/stat/pass" });
      this.state.mna.push({ mnid: "mn06", pos: "c2", text: "TRANSAKTIONER", desc: "Transaktions flöde", link: "/pos/stat/trs" });
      this.state.mna.push({ mnid: "mn07", pos: "c2", text: "ARTIKLAR", desc: "Sammanställda artiklar", link: "/pos/stat/art" });
      this.state.mna.push({ mnid: "mn08", pos: "c2", text: "DRIFT/LOGG", desc: "Drift med loggar", link: "/pos/stat/drift" });
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

    mnc(pos) {
        var mna = [];
        for(var row of this.state.mna) {
            if(row.pos == pos) mna.push( row );
        }
        return mna;
    }
  fknGo(url) {
    //alert(row.href);
      var row = {};
      row.url = url;
      sso.seo.sfkn = row;
      this.props.navigate(url);
  }

  render() {
    return (
    <div className="web-app">
    <div className="web-pg py-4 flex">
        <h3>Status funktioner</h3>
        <p>Diverse funktioner för status</p>

        <div className="divider_green"></div>

        <div className="web-row">
            <div className="flex-col flex">
                { this.mnc("c1").map((row, key) =>
                    <div key={key} className="web-bx" onClick={() => this.fknGo(row.link) }>
                        <div>{ row.text }</div>
                        <div className="flex-row">
                            <div className="flex">{ row.desc }</div>
                            <div className="web-link">Välj</div>
                        </div>
                    </div>
                )}
            </div>
            <div className="flex-col flex">
                { this.mnc("c2").map((row, key) =>
                    <div key={key} className="web-bx" onClick={() => this.fknGo(row.link) }>
                        <div>{ row.text }</div>
                        <div className="flex-row">
                            <div className="flex">{ row.desc }</div>
                            <div className="web-link">Välj</div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    </div>

    </div>
  );
    }
}
export default withRouter(Pos_Stat);
