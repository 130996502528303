import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";
import {withRouter} from "../../lib/react/withRouter";
import sso from "../../sso";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Wrk_Home extends React.Component {
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pos = props.store.pos;
    this.state = {};
    this.state.seldate = new Date();
    this.state.avd = "01";
    this.state.paa = [];
    this.state.aa = [];

      this.state.mna = [];
      this.state.mna.push({ mnid: "mn01", pos: "c1", text: "PLAN", desc: "Planering", link: "/bcs/wrk/plan" });
      this.state.mna.push({ mnid: "mn02", pos: "c1", text: "TASKS", desc: "Uppgifter", link: "/bcs/wrk/tasks" });
      this.state.mna.push({ mnid: "mn03", pos: "c1", text: "ON BOARDING", desc: "Nya kunder i installation/uppsättning", link: "/bcs/wrk/board" });
      this.state.mna.push({ mnid: "mn04", pos: "c1", text: "SUPPORT", desc: "Support", link: "/bcs/wrk/sup" });
      this.state.mna.push({ mnid: "mn05", pos: "c2", text: "SLUTFÖR", desc: "Klara ärenden att slutföra/fakturera", link: "/bcs/wrk/complete" });
      this.state.mna.push({ mnid: "mn06", pos: "c2", text: "HISTORIK", desc: "Historik", link: "/bcs/wrk/hist" });
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

    mnc(pos) {
        var mna = [];
        for(var row of this.state.mna) {
            if(row.pos == pos) mna.push( row );
        }
        return mna;
    }
  fknGo(url) {
    //alert(row.href);
      var row = {};
      row.url = url;
      sso.seo.sfkn = row;
    this.props.navigate(url);
  }

  render() {
    return (
    <div className="web-app">
        <div className="web-pg py-4 flex">
            <h3>Arbete</h3>
            <p>Arbets funktioner</p>

            <div className="divider_green"></div>

            <div className="web-row">
                <div className="flex-col flex">
                    { this.mnc("c1").map((row, key) =>
                        <div className="web-bx" onClick={() => this.fknGo(row.link) }>
                            <div>{ row.text }</div>
                            <div className="flex-row">
                                <div className="flex">{ row.desc }</div>
                                <div className="web-link">Välj</div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="flex-col flex">
                    { this.mnc("c2").map((row, key) =>
                        <div className="web-bx" onClick={() => this.fknGo(row.link) }>
                            <div>{ row.text }</div>
                            <div className="flex-row">
                                <div className="flex">{ row.desc }</div>
                                <div className="web-link">Välj</div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>

    </div>
  );
    }
}
export default withRouter(Wrk_Home);
