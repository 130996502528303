import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../../lib/net";
import Fr_Input from "../../../lib/ui/fr_input";

class Vip_Trs extends React.Component {
  constructor(props, context) {
    super(props);

    this.pos = props.store.pos;
    this.state = {};
    this.state.ynq = [];
    this.state.sval = [];

    this.state.dao = {};
    this.state.aa = [];
    this.state.cval = [
                {id: '001', text: 'ANTAL GER NYTT PRIS'},
                {id: '002', text: 'ANTAL GER RABATT PÅ PRIS'},
                {id: '003', text: 'PRISÄNDRING GENOM RABATT'}
            ];

    this.state.tra = [];
    this.state.vpo = props.vpo;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.vip_trs();
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    tab_style(tabid) {
      var css = {};
      css.display = "none";
      if(this.state.tabval === tabid) css.display = "block";
      return css;
    }
    tab_head(tabid) {
      var css = {};
      css.background = "#fff";
      css.color = "#000";
      if(this.state.tabval === tabid) {
          css.background = "#080";
          css.color = "#fff";
      }
      return css;
    }
    grp_style(row) {
        var css = {};
        css.background = "#008";
        if(this.state.gnr === row.vgrupp) css.background = "#080";
        return css;
    }
    row_style(row) {
        var css = {};
        if(row.p0typ === "K") css.color = "#800";
        return css;
    }

    formchange (e) {
      const name = e.target.name;
      const value = e.target.value;
      var pbo = this.state.pbo;
      var na = name.split(".");
      pbo[na[0]][na[1]] = value;
      this.setState({pbo: pbo});
      //setState({[name]: value});
    }

    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    wnd_close = () => {
		this.props.ctx.setState({ wnds: {} });
	};
    handleChange = (event, newValue) => {
        //alert(newValue);
        this.setState({ix: newValue});
      };
    toggle() {
      this.setState({modal: false});
	};

  submitHandler = event => {
    event.preventDefault();
    event.target.className += " was-validated";
  };

  changeHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  tb_style() {
      var css = {};
      css.width = window.innerwidth * 0.7;
      css.height = window.innerHeight - 375;
      return css;
  }
  cssCols (cid) {
      var css = {};
      if(this.state.cols[cid]) css.width = this.state.cols[cid].toString() + "px";
      //if(cid == "c01") alert(JSON.stringify(this.state.cols[cid]));
      return css;
  }
    vip_trs() {
    var self = this;
    var vip00 = this.state.vpo.vip00;

    var prm = {};
    prm.req = "vip.vip_buy.vip_trs";
    prm.typ = vip00.v0ktyp;
    prm.id = vip00.v0id;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        self.setState({tra: ret.rca }, function() { });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
    show_brutto(row) {
      var brutto = row.p0brutto.cdipris();
      if(row.p0typ !== "K") brutto = "-" + brutto;
      return brutto;
    }

  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "Knappar";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        <div className="m2_backdrop" />
        <div className="m2_dlg mx_70 flex-col" style={ this.dlg_style() } draggable="true" >
        <div className="mx_h11">
            <div>TRS</div>
        </div>
        <div className="mx_content flex">

            <div className="flex-row" style={{padding:"5px",height:"35px"}}>
                    <div className="flex-col flex">
                        <div>TRANSAKTIONER:</div>
                    </div>
                    <div className="flex-col">
                        <div>UPPDATERA</div>
                    </div>
            </div>
            <div className="divider_orange"></div>

        <table className="table table-bordered table-hover tb">
            <thead>
            <tr>
				<th>Datum</th>
				<th>Tid</th>
				<th>Typ</th>
				<th>Notanr</th>
				<th>Text</th>
				<th>Brutto</th>
				<th>Rabatt</th>
				<th>Signatur</th>
            </tr>
            </thead>
            <tbody style={ this.tb_style() }>
            { this.state.tra.map((pao, key) =>
            <tr key={key} style={ this.row_style(pao) } onDoubleClick={ () => this.tro_edit(pao) }>
                <td>{ pao.p0datum }</td>
                <td>{ pao.p0tid }</td>
                <td>{ pao.p0typ }</td>
                <td>{ pao.p0notanr }</td>
                <td>{ pao.p0text }</td>
                <td className="text-center">{ this.show_brutto(pao) }</td>
                <td className="text-right">{ pao.p0rabatt.cdipris() }</td>
                <td>{ pao.p0sign }</td>
            </tr>
            )}
            </tbody>
        </table>

        </div>
        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
        </div>
      </div>
      </div>
	;
	return html;
  };
}

export default Vip_Trs;