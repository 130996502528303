import React, { useState, useEffect, useContext } from 'react';

function PdfDlg(props) {
    const [state, setState] = useState({ pdf : null });

    function handlePrn(event) {
        //props.ctx.dologin(state.user.uid, state.user.pwd);
    }
	const handleClose = () => {
		//props.ctx.setState({ pdf: 0, pdf_content: null });
		props.done({ ok: "000" });
	};
    if(!props.show) return "";

    //var pdf_content = "data:application/pdf;base64," + props.ctx.state.pdf_content;
    var pdf_content = "data:application/pdf;base64," + props.pdf;

    if(pdf_content == null) return "";

	var html =
        <div className="mx_base">
        { props.backdrop ? <div className="mx_backdrop" /> : null }
        <div className="mx_dlg_big flex-col" style={{zIndex: "19999"}}>
        <div className="mx_title">
            {"Rapport PDF"}
        </div>
        <div className="mx_content flex">
            <object data={ pdf_content } type="application/pdf" style={{width: "100%", height: "100%"}}></object>
        </div>
        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w150 ripple" onClick={() => handleClose() } aria-label="System" >
                STÄNG
            </button>
            <button className="btn btn-hw-green w150 ripple web-hide" onClick={(event) => handlePrn(event) } aria-label="System" >
                SKRIV UT
            </button>
        </div>
      </div>
    </div>
	;
	return html;
}

export default PdfDlg;
