import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";
import Kid_Add from "./kid_add";
import Kid_Open from "./kid_open";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Bo_Kids extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.pos = props.store.pos;

    this.state = {};
    this.state.sio = null;
    this.state.seldate = new Date();
    this.state.avd = "01";
    this.state.kaa = [];
    this.state.paa = [];
    this.state.kidadd = 0;
    this.state.kidopen = 0;
    this.state.pbo = {};
    this.state.grps = [
                {id: '000', text: 'ALLA'},
                {id: '001', text: 'INTERNA'},
                {id: '051', text: 'PARTNERS'},
                {id: '101', text: 'KUNDER SVERIGE'},
                {id: '201', text: 'KUNDER NORGE'}
            ];
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.kids();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 250;
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

  kid_add(row) {
      var self = this;
    this.setState({ pbo: row, kidadd: 1 });
  }
  kid_open(row) {
      var self = this;
    this.setState({ pbo: row, kidopen: 1 });
  }
  users_sz() {
      var self = this;
      var poa = self.state.poa;

      var paa = poa.filter(row => row.plu00.p0namn.includes("z"));
      self.setState({paa: paa });
   }

  kids() {
    var self = this;
    var prm = {};
    prm.req = "kid:list";

    net.gcs_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca.length));

        self.setState({kaa: ret.rca });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  tst() {
    var self = this;
    var prm = {};
    prm.req = "usr:list";
    //prm.sz = "a";

    //gda.wndLoading("Hämtar");
    net.gcs_req(prm)
    .then(function(ret){
        alert(JSON.stringify(ret));

        var o = {};
        //o.deps = ret.rco;
        //alert(JSON.stringify(o));
        //self.setState({paa: ret.rco });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }
  kid_upd(ctx, kdbuf) {
    var self = this;
    ctx.kids();
    this.setState({ kidadd: 0, kidopen: 0 });
  }

  render() {
    return (
    <div className="web-app d-flex flex-column">

    <div className="web-p01">
    <div className="flex-row">
        <div className="txt_18">KUNDER</div>
        <div className="flex"></div>
        <div className="rlink_12" onClick={() => this.kid_add() }>NY KUND</div>
    </div>
    <div className="divider_orange"></div>
    <div className="flex-row">
        <select className="form-control w250" placeholder="Välj grupp">
            { this.state.grps.map((row, key) =>
            <option key={key} value={row.id}>{row.text}</option>
            )}
        </select>
        <div className="flex"></div>
        <div className="link_14" onClick={() => this.kid_add() }>NY KUND</div>
        <div className="link_14" onClick={() => this.kids() }>UPPDATERA</div>
    </div>
    <div className="divider_orange"></div>

    <div className="web-row">
        <table className="table table-bordered table-hover tb">
            <thead>
            <tr>
                <th>Kundid</th>
                <th>Text</th>
                <th>Företag</th>
                <th>Stad</th>
                <th>Orgnr</th>
                <th>Telefon</th>
                <th>Status</th>
                <th className="w50"></th>
            </tr>
            </thead>
            <tbody style={ this.tb_style() }>
            { this.state.kaa.map((row, key) =>
            <tr key={key} id={ row.kd00.k0kid } onDoubleClick={() => this.kid_open(row) }>
                <td>{ row.kd00.k0kid }</td>
                <td>{ row.kd00.k0text.toswe() }</td>
                <td className="text-left">{ row.kd00.k0company }</td>
                <td>{ row.kd00.k0city }</td>
                <td>{ row.kd00.k0orgnr }</td>
                <td>{ row.kd00.k0phone }</td>
                <td>{ row.kd00.k0status }</td>
                <td className="text-center w40" style={{cursor:"pointer"}} onClick={() => this.kid_open(row) }><i className="fa fa-cog text-danger"></i></td>
            </tr>
            )}
            </tbody>
        </table>
    </div>

    </div>

            { this.state.kidadd ?
        <Kid_Add show={this.state.kidadd} ctx={this} pbo={this.state.pbo} backdrop={true}/>
            : null }
            { this.state.kidopen ?
        <Kid_Open show={this.state.kidopen} cb={(e)=> {this.setState({kidopen: false}); this.kids();} } ctx={this} pbo={this.state.pbo} backdrop={true}/>
            : null }

    </div>
  );
    }
}
export default Bo_Kids;

/*

*/