import React, { useState, useEffect, useContext } from 'react';

import * as utils from "../../../assets/utils";
import * as net from "../../../lib/net";
import Wnd_Ok from "../../../lib/ui/wnd_ok";
import Wnd_Error from "../../../lib/ui/wnd_error";
import Wnd_Confirm from "../../../lib/ui/wnd_confirm";

import Fak_Head from "./fak_head";
import Row_Add from "./row_add";
import Row_Edit from "./row_edit";
import Fak_Und from "./fak_und";
import PdfFakDlg from "./pdf_fak_dlg";

class Fak_Edit extends React.Component {
    constructor(props, context) {
        super(props);

        //alert('PBO CTX: ' + JSON.stringify(props.pbo));
        //this.webStore = props.store;
        this.state = {};
        this.state.ynq = [];
        this.state.msg = {};
        this.state.wnds = {};

        this.state.fra = [];
        this.state.aa = [];

        this.state.faknr = "";
        this.state.fak = {};
        this.state.fak.wfh = {};
        this.state.fak.wfh.wfak00 = {};
        this.state.fak.wfh.wfak00.faknr = "";
        this.state.fak.wfh.wfak00.kund = "";
        if(props.wnds) {
            this.state.faknr = props.wnds.faknr;
            if(props.wnds.fak) this.state.fak = props.wnds.fak;
        }

        this.state.pdf = 0;
        this.state.pdf_content = null;
        this.state.loading = 0;
    }

    componentDidUpdate() {
    }
    componentWillUnmount() {
    }
    componentDidMount() {
        this.fak_read();
    }

    pdf_done (ret) {
        this.setState({loading: 0, pdf: 0, pdf_content: null });
    }
    dlg_style() {
      var css = {};
      css.width = window.innerWidth * 0.8;
      css.height = window.innerHeight * 0.8;
      return css;
    }
    tab_style(tabid) {
      var css = {};
      css.display = "none";
      if(this.state.tabval === tabid) css.display = "block";
      return css;
    }
    tb_style() {
      var css = {};
        css.height = (window.innerHeight * 0.8) - 325;
        css.overflow = "auto";
      return css;
  }
    th_style(id) {
        var css = {};
        css.cursor = "pointer";
        if(id == this.state.sortid) {
            if(this.state.sortdir) css.color = "#800";
            else css.color = "#080";
        }
        return css;
    }
    tab_head(tabid) {
      var css = {};
      css.background = "#fff";
      css.color = "#000";
      if(this.state.tabval === tabid) {
          css.background = "#080";
          css.color = "#fff";
      }
      return css;
    }
    fak_read() {
        var self = this;
        var prm = {};
        prm.req = "fakt.fakt_int.int_fak";
        prm.faknr = this.state.faknr;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.rco));

                var fra = ret.rco.sale;
                self.setState({ fak: ret.rco, fra: fra });
                self.syncTb("fakTable");
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    fak_recalc() {
        var self = this;
        var prm = {};
        prm.req = "fakt.fakt_fak.fak_recalc";
        prm.faktyp = "I";
        prm.faknr = this.state.faknr;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.rco));

                var prm = {};
                prm.req = "fakt.fakt_int.int_fak";
                prm.faknr = self.state.faknr;
                return net.sio_req(prm);
            })
            .then(function(ret){
                //alert(JSON.stringify(ret.rco));

                var fra = ret.rco.sale;
                self.setState({ wnds: {}, fak: ret.rco, fra: fra });
                self.syncTb("fakTable");
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    fak_del() {
        var self = this;
        var msg = { info: false, confirm: true, text: "Ta bort internfaktura?", text0: "JA", text1: "NEJ", cb: "fak_del_do" };
        self.setState({msg: msg });
    }
    fak_del_do(val) {
        var self = this;

        self.setState({msg: {} });
        if(val != "0") {
            //alert(JSON.stringify("EXIT"));
            return;
        }

        var self = this;
        var prm = {};
        prm.req = "fakt.fakt_int.int_remove";
        prm.faktyp = "I";
        prm.faknr = this.state.faknr;
        prm.wfak00 = this.state.fak.wfh.wfak00;

        net.sio_req(prm)
        .then(function(ret) {
            //alert(JSON.stringify(ret.rco));

            var msg = { info: false, error: true, text: "Borttagen ..."};
            self.setState({msg: msg });
            setTimeout(function(){
                self.setState({msg: {} });
                if(self.props.wnds.cb) {
                    self.props.wnds.cb(self.props.ctx);
                }
                else self.props.ctx.setState({ wnds: {} });
                return;
            }, 1000);
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }
    fak_addrow() {
        var self = this;
        var wnds = {};
        wnds.rowadd = 1;
        wnds.faknr = this.state.faknr;
        wnds.cb = this.cb_fak;
        this.setState({ wnds: wnds });
    }
    cb_fak (ctx) {
        var prm = {};
        prm.req = "fakt.fakt_fak.fak_recalc";
        prm.faktyp = "I";
        prm.faknr = ctx.state.faknr;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.rco));

                var prm = {};
                prm.req = "fakt.fakt_int.int_fak";
                prm.faknr = ctx.state.faknr;
                return net.sio_req(prm);
            })
            .then(function(ret){
                //alert(JSON.stringify(ret.rco));

                var fra = ret.rco.sale;
                ctx.setState({ wnds: {}, fak: ret.rco, fra: fra });
                ctx.syncTb("fakTable");
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    row_edit (row) {
        var self = this;
        var wnds = {};
        wnds.rowedit = 1;
        wnds.faknr = this.state.faknr;
        wnds.wfr = row;
        wnds.cb = this.cb_fak;
        this.setState({ wnds: wnds });
    }
    fak_addund () {
        var self = this;
        var wnds = {};
        wnds.fakund = 1;
        wnds.faknr = this.state.faknr;
        wnds.cb = this.cb_fak;
        this.setState({ wnds: wnds });
    }
    fak_head() {
        var self = this;
        var wnds = {};
        wnds.fakhead = 1;
        wnds.faknr = this.state.faknr;
        wnds.wfh = this.state.fak.wfh;
        this.setState({ wnds: wnds });
    }
    xfak_addrow() {
        var self = this;
        var wfak04 = {};
        wfak04.faktyp = "I";
        wfak04.faknr = this.state.faknr;
        wfak04.fakrad = "P";
        wfak04.fakradnr = "001";
        wfak04.plu = "1234";
        wfak04.text = "TEST PRD";
        wfak04.antal = "1";
        wfak04.pris = "12300";
        wfak04.summa = "12300";
        wfak04.moms = "1500";
        wfak04.notanr = "9876E";
        wfak04.tecken = "+";
        wfak04.kassal = "";
        wfak04.datum = "20210927";
        wfak04.radrab = "";
        wfak04.radmoms = "";
        wfak04.exmoms = "";
        wfak04.filler = "";
        wfak04.time = "";

        var prm = {};
        prm.req = "fakt.fakt_fak.fak_row_save";
        prm.wfak04 = wfak04;

        net.sio_req(prm)
            .then(function(ret){
                alert(JSON.stringify(ret));
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    fak_ext() {
        var self = this;
        var prm = {};
        prm.req = "fakt.fakt_int.int_toext";
        prm.faknr = this.state.faknr;

        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret));

            ret.fkn = "fak_ext";

            var msg = { info: false, ok: true, text: "Fakturerad ..."};
            self.setState({msg: msg });
            setTimeout(function(){
                self.setState({msg: {} });
                if(self.props.wnds.cb) {
                    self.props.wnds.cb(self.props.ctx, ret);
                }
                else self.props.ctx.setState({ wnds: {} });
                return;
            }, 1000);
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }
    wnd_close() {
        //this.props.ctx.setState({ wnds: {} });
        if(this.props.wnds.cb) {
            this.props.wnds.cb(this.props.ctx, {});
        }
        else this.props.ctx.setState({ wnds: {} });
    };
    syncTb(tb){
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
            tdElements = tbel.getElementsByTagName('td');

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
                width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
    prn_fak() {
        var self = this;

        var prm = {};
        prm.req = "fakt.fakt_prn.fak_prn";
        prm.faktnr = this.state.faknr;
        //alert(JSON.stringify(prm));
        //return;

        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret.ok));

            //var uri = "data:application/pdf;base64," + ret.pdf;
            self.setState({pdf: 1, pdf_content: ret.pdf });
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }

    render() {
        //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "INTERN FAKTURA";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="mx_backdrop" /> : null }
        <div className="mx_dlg mx_80h flex-col" style={ this.dlg_style() }>
        <div className="mx_h01 flex-row" style={{background: "#1a456b", color: "#fff"}}>
            <div>FAKTURA: { utils.toNum( this.state.faknr ) }</div>
            <div className="flex"></div>
            <div onClick={() => this.fak_read() }>UPPDATERA</div>
        </div>
        <div className="mx_content flex-col flex px-3">

        <div className="flex-row" style={{ padding: "0px 10px" }}>
        <div className="flex-col">
            <div className="flex-row">
                <div className="cdi-label">Kund</div>
                <div className="">{ utils.toNum( this.state.fak.wfh.wfak00.kund ) }</div>
            </div>
            <div className="flex-row">
                <div className="cdi-label">Företag</div>
                <div className="">{ utils.toLang( this.state.fak.wfh.wfak00.company ) }</div>
            </div>
            <div className="flex-row">
                <div className="cdi-label">Referens</div>
                <div className="">{ utils.toLang( this.state.fak.wfh.wfak00.referens ) }</div>
            </div>
        </div>
        <div className="flex-col flex">
        </div>
        <div className="flex-col">
            <div className="flex-row">
                <div className="cdi-label">Belopp</div>
                <div className="">{ utils.toPris( this.state.fak.wfh.wfak00.belopp ) }</div>
            </div>
            <div className="flex-row">
                <div className="cdi-label">Fakdatum</div>
                <div className="">{ utils.toDate( this.state.fak.wfh.wfak00.fakdatum ) }</div>
            </div>
            <div className="flex-row">
                <div className="cdi-label">Förfallodatum</div>
                <div className="">{ utils.toDate( this.state.fak.wfh.wfak00.fakdatum ) }</div>
            </div>
        </div>
        </div>
            <div className="divider_orange"></div>

            <div className="flex-row">
                <div className="link_14" onClick={() => this.fak_head() } >FAKTURA HUVUD</div>
                <div className="flex"></div>
                <div className="link_14" onClick={() => this.fak_del() } >TA BORT</div>
                <div className="link_14" onClick={() => this.fak_recalc() } >RÄKNA OM</div>
                <div className="link_14" onClick={() => this.fak_addund() } >UNDERLAG</div>
                <div className="flex"></div>
                <div className="link_14" onClick={() => this.fak_read() } >UPPDATERA</div>
            </div>

        <div className="divider_orange"></div>

        <div className="flex-col flex">
            <div id="fakTable">
        <table className="table table-bordered table-hover tb " style={{marginBottom: "0px"}}>
            <thead>
            <tr>
                <th style={ this.th_style( "fakradnr" ) }>Radnr</th>
                <th style={ this.th_style( "fakplu" ) }>Plu</th>
                <th style={ this.th_style( "text" ) }>Text</th>
                <th style={ this.th_style( "antal" ) }>Antal</th>
                <th style={ this.th_style( "pris" ) }>Pris</th>
                <th style={ this.th_style( "sum" ) }>Summa</th>
                <th style={ this.th_style( "ink" ) }>Moms</th>
                <th style={ this.th_style( "tkn" ) }>Tecken</th>
            </tr>
            </thead>
        </table>
        <div style={ this.tb_style() }>
       <table className="table table-bordered table-hover tb">
            <tbody>
            { this.state.fra.map((row, key) =>
            <tr key={ key } onDoubleClick={() => this.row_edit(row) }>
                <td>{ row.fakradnr }</td>
                <td>{ row.plu }</td>
                <td>{ row.text }</td>
                <td>{ utils.toNum( row.antal ) }</td>
                <td>{ utils.toPris( row.pris ) }</td>
                <td>{ utils.toPris( row.summa ) }</td>
                <td>{ row.moms }</td>
                <td>{ row.tecken }</td>
            </tr>
            )}
            </tbody>
        </table>
        </div>
        </div>
    </div>

    <div className="divider_orange"></div>

    <div className="flex-row flex-space-between">
        <div className="flex-col">
            <div className="cdi-label">Netto</div>
            <div className="">{ utils.toPris( this.state.fak.wfh.wfak00.belopp ) }</div>
        </div>
        <div className="flex-col">
            <div className="cdi-label">Moms</div>
            <div className="">{ utils.toPris( this.state.fak.wfh.wfak00.moms ) }</div>
        </div>
        <div className="flex-col">
            <div className="cdi-label">Brutto</div>
            <div className="">{ utils.toPris( this.state.fak.wfh.wfak00.belopp ) }</div>
        </div>
    </div>

        </div>
        <div className="mx_foot flex-row">
            <button className="btn btn-hw-red w150" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex"></div>
            <button className="btn btn-hw-green w150" onClick={(event) => this.prn_fak() } >
                SKRIV UT
            </button>
            <button className="btn btn-hw-red w150" onClick={() => this.fak_addrow() } >
                LÄGG TILL RAD
            </button>
            <div className="flex"></div>
            <button className="btn btn-hw-green w150" onClick={(event) => this.fak_ext() } >
                FAKTURERA
            </button>
        </div>
      </div>
            { this.state.wnds.fakhead ? <Fak_Head wnds={this.state.wnds} ctx={this} backdrop={true}/> : null }
            { this.state.wnds.rowadd ? <Row_Add wnds={this.state.wnds} ctx={this} backdrop={true}/> : null }
            { this.state.wnds.rowedit ? <Row_Edit wnds={this.state.wnds} ctx={this} backdrop={true}/> : null }
            { this.state.wnds.fakund ? <Fak_Und wnds={this.state.wnds} ctx={this} backdrop={true}/> : null }
            <PdfFakDlg show={this.state.pdf} pdf={this.state.pdf_content} done={(ret) => this.pdf_done(ret)} backdrop={true}/>
            <Wnd_Error msg={this.state.msg} ctx={this} />
            <Wnd_Ok msg={this.state.msg} ctx={this} />
            <Wnd_Confirm msg={this.state.msg} ctx={this} />
    </div>
	;
    return html;
};
}

export default Fak_Edit;