import React, {useState} from 'react';

import { observer } from 'mobx-react';
import { useStore } from '../../sdo.js'
import ddo from "../../ddo";

import * as net from "../../lib/net";
import Dd_Sel from "../../lib/ui/dd_sel";
import Pms_Term from "../op/fkn/rf_gst";
import {useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function Pms_Top(props) {
    var navigate = useNavigate();
    let [state, setState] = useState({modal: false, ma: []});
    let ctx = useStore();

    var seo = props.store.seo;


    var selitems = [
        {fkn: 'home', text: 'HEM'},
        {fkn: 'setup', text: 'INSTÄLLNINGAR'},
        {fkn: 'upd', text: 'UPPDATERA'},
        {fkn: 'sys', text: 'SYSTEM'},
        {fkn: 'logout', text: 'LOGGA UT'}
    ];

      const goFull = () => {
        alert("Full");
      };

    function toggle() {
    };
    function logout() {
        var url = "/logout";
        navigate(url);
    };
    function acs() {
        var wnds = {};
        wnds.acs = 1;
        props.ctx.setState({ wnds: wnds });
    };
    function stat() {
        var wnds = {};
        wnds.stat = 1;
        props.ctx.setState({ wnds: wnds });
    };
    function bel() {
        var wnds = {};
        wnds.bel = 1;
        props.ctx.setState({ wnds: wnds });
    };
    function term() {
        var wnds = {};
        wnds.term = 1;
        props.ctx.setState({ wnds: wnds });
    };
      const mnu_toggle = () => {
		//props.ctx.setState({ mnu_show: !props.ctx.state.mnu_show });
		props.store.cfg.mnu_show = !props.store.cfg.mnu_show;
      };
      const bread_toggle = () => {
		props.store.cfg.bread = !props.store.cfg.bread;
      };
      const mnu_item_css = () => {
      };
      const mnu_sel = () => {
      };
      const login = () => {
      };
      const header_class = () => {
  	    var cls = "flex_col";

		if(false) {
			cls = cls + " web-head_1";
		}
		//else cls = cls +  " web-top-sub";


		return cls;
    }
  function fknGo(url) {
    //alert(url);
      navigate(url);
  }
  function sel_fkn(sel) {
    //alert(JSON.stringify(fkn));
      if(sel.fkn == "home") {
        var url = "/hello";
          navigate(url);
      }
      if(sel.fkn == "logout") {
        var url = "/logout";
          navigate(url);
      }
  }

    var html =
            <div className={ header_class() }>
            <div className="web-head-content flex-row">
                <div className="web-top-icon px-3 w60" onClick={() => mnu_toggle() }>
                    <FontAwesomeIcon icon="bars" size="1x"/>
                </div>


                <div className="flex-fill"></div>
                <div className="web-top-icon px-3 w60" onClick={(e) => bel(e) }>
                    <FontAwesomeIcon icon="bed" size="1x"/>
                </div>
                <div className="flex-row" style={{padding: "5px", fontSize:"10px", color:"#ccc" }} onClick={() => stat() }>
                <div className="flex-col" style={{padding: "0px 5px", borderLeft: "1px solid #999", borderRight: "1px solid #999" }}>
                    <div>TOT</div>
                    <div style={{fontSize:"16px", color:"#ff9900", textAlign: "center" }}>{ props.store.pms.stat.nrr }</div>
                </div>
                <div className="flex-col" style={{padding: "0px 5px", borderRight: "1px solid #999" }}>
                    <div>ANK</div>
                    <div style={{fontSize:"16px", color:"#ff9900", textAlign: "center" }}>{ props.store.pms.stat.ankr }</div>
                </div>
                <div className="flex-col" style={{padding: "0px 5px", borderRight: "1px solid #999" }}>
                    <div>BO</div>
                    <div style={{fontSize:"16px", color:"#ff9900", textAlign: "center" }}>{ props.store.pms.stat.borr }</div>
                </div>
                <div className="flex-col" style={{padding: "0px 5px", borderRight: "1px solid #999" }}>
                    <div>AVR</div>
                    <div style={{fontSize:"16px", color:"#ff9900", textAlign: "center" }}>{ props.store.pms.stat.avrr }</div>
                </div>
                <div className="flex-col" style={{padding: "0px 5px", borderRight: "1px solid #999" }}>
                    <div>FREE</div>
                    <div style={{fontSize:"16px", color:"#ff9900", textAlign: "center" }}>{ props.store.pms.stat.free }</div>
                </div>
                </div>
                <div className="web-top-icon px-3 w60" onClick={(e) => acs(e) }>
                    <FontAwesomeIcon icon="clock" size="1x"/>
                </div>

                <div className="flex-fill"></div>

                <div className="web-top-sep"></div>
                <div className="">
                    <Dd_Sel items={ selitems } cls="web-top-icon" getValue={(e)=> sel_fkn(e)} />
                </div>
                <div className="">
                </div>
            </div>

            </div>
    ;
    return html;
}

export default observer(Pms_Top);
