import React, { useState, useEffect, useRef, useContext } from 'react';
import { parseISO, format, getDate, getMonth, getWeek, eachDayOfInterval, eachWeekOfInterval } from 'date-fns'
import { setMonth, setYear, startOfWeek, endOfWeek, startOfMonth, endOfMonth, addMonths, subMonths, addDays, subDays, isSameDay } from 'date-fns'
import { sv } from 'date-fns/locale'

import './css/dt_cal.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Dt_Tid extends React.Component {
  constructor(props, context) {
    super(props);

    this.node = React.createRef();
    //this.setNodeRef = this.setNodeRef.bind(this);

    this.state = {};
    this.state.wka = [];
    this.state.lso = {};
    //this.state.lro = props.lro;
    this.state.tya = [
                {id: '01', text: 'Summerad'},
                {id: '02', text: 'Dag för dag'}
            ];
    this.state.aa = [];
    this.state.cols = {};
    this.state.cal_show = false;
    this.state.cal_step = 0;

    //context.posrend = this;
    this.state.today = new Date();
    this.state.seldat = new Date();
    this.state.dat = new Date();
    this.state.year = "";
    this.state.month = "";
    this.state.dts = "";
    this.state.tps = "00:00";
    this.state.tp_hh = "00";
    this.state.tp_mm = "00";
    if(props.tps) {
      this.state.tps = props.tps;
      this.state.tp_hh = props.tps.substr(0, 2);
      this.state.tp_mm = props.tps.substr(props.tps.length-2, 2);
    }
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick);
  }
  componentDidMount() {
    if(this.props.setiso) {
      var dat = parseISO(this.props.text);
      //dat = subDays(dat, 1);
      var tps = format(dat, "hh:mm");
      this.setState({dat: dat, seltp: tps, tps: tps});
    }
    else {
      var tps = this.state.tps;
      var tp_hh = tps.substr(0, 2);
      var tp_mm = tps.substr(tps.length-2, 2);
      //this.setState({tps: tps, tp_hh: tp_hh, tp_mm: tp_mm});
    }
    document.addEventListener("mousedown", this.handleClick);
    this.set_tps(this.state.dat);
  }

  updateTp(tps) {
    var tp_hh = tps.substr(0, 2);
    var tp_mm = tps.substr(tps.length-2, 2);
    this.setState({tps: tps, tp_hh: tp_hh, tp_mm: tp_mm, cal_show: false, cal_step: 0});
    //this.set_month(dat);
    this.props.getValue(tps);
  }
  updateTps(dts) {
    //alert(JSON.stringify(dts));
    var dat = parseISO(dts, "yyyyMMdd");
    this.setState({dat: dat, seldat: dat, dts: dts, cal_show: false, cal_step: 0});
    this.set_month(dat);
    this.props.getValue(dat);
  }

  updateAdd() {
    var tp_hh = this.state.tp_hh;
    var tp_mm = this.state.tp_mm;
    if((tp_hh * 1) >= 24) return;

    if(tp_mm == "00") tp_mm = "30";
    else {
      tp_hh = (tp_hh * 1) + 1;
      if(tp_hh > 24) tp_hh = 24;
      tp_hh = tp_hh.toString().tonum(2);
      tp_mm = "00";
    }
    var tps = tp_hh + ":" + tp_mm;
    this.setState({tps: tps, tp_hh: tp_hh, tp_mm: tp_mm});
    this.props.getValue(tps);
  }
  updateSub() {
    var tp_hh = this.state.tp_hh;
    var tp_mm = this.state.tp_mm;
    if(tp_mm == "30") tp_mm = "00";
    else {
      tp_hh = (tp_hh * 1) - 1;
      if(tp_hh < 0) {
        tp_hh = 0;
        tp_hh = tp_hh.toString().tonum(2);
      }
      else {
        tp_hh = tp_hh.toString().tonum(2);
        tp_mm = "30";
      }
    }
    var tps = tp_hh + ":" + tp_mm;
    this.setState({tps: tps, tp_hh: tp_hh, tp_mm: tp_mm});
    this.props.getValue(tps);
  }

  handleClick = (e) => {
    if(!this.state.cal_show) return;

    if (!this.node.current.contains(e.target)) {
      this.setState({cal_show: false});
      return;
    }
  }

  set_tp(tps,  e) {
    e.preventDefault();

    var tp_hh = tps.substr(0, 2);
    var tp_mm = tps.substr(tps.length-2, 2);
    //var dts = format(dat, "yyyy-MM-dd");
    //this.props.getValue(dat);
    this.props.getValue(tps);
    this.setState({tps: tps, tp_hh: tp_hh, tp_mm: tp_mm, cal_show: false, cal_step: 0});
    //this.setState({dat: dat, seldat: dat, dts: dts, cal_show: false, cal_step: 0});
  }
  subTp(e) {
    e.preventDefault();

    var tp_hh = this.state.tp_hh;
    var tp_mm = this.state.tp_mm;
    if(tp_mm == "30") tp_mm = "00";
    else {
      tp_hh = (tp_hh * 1) - 1;
      if(tp_hh < 0) {
        tp_hh = 0;
        tp_hh = tp_hh.toString().tonum(2);
      }
      else {
        tp_hh = tp_hh.toString().tonum(2);
        tp_mm = "30";
      }
    }
    var tps = tp_hh + ":" + tp_mm;
    this.setState({tps: tps, tp_hh: tp_hh, tp_mm: tp_mm});
  }
  addTp(e) {
    e.preventDefault();

    var tp_hh = this.state.tp_hh;
    var tp_mm = this.state.tp_mm;
    if((tp_hh * 1) >= 24) return;

    if(tp_mm == "00") tp_mm = "30";
    else {
      tp_hh = (tp_hh * 1) + 1;
      if(tp_hh > 24) {
        tp_hh = 24;
        tp_hh = tp_hh.toString().tonum(2);
      }
      else {
        tp_hh = tp_hh.toString().tonum(2);
        tp_mm = "00";
      }
    }
   var tps = tp_hh + ":" + tp_mm;
    this.setState({tps: tps, tp_hh: tp_hh, tp_mm: tp_mm});
  }
  sel_hh(e) {
    e.preventDefault();
  }
  sel_mm(e) {
    e.preventDefault();
  }

  set_tps(dat) {
    var tpa = [];
    tpa.push( "00:00" );
    tpa.push( "01:00" );
    tpa.push( "02:00" );
    tpa.push( "03:00" );
    tpa.push( "04:00" );
    tpa.push( "05:00" );
    tpa.push( "06:00" );
    tpa.push( "07:00" );
    tpa.push( "08:00" );
    tpa.push( "09:00" );
    tpa.push( "10:00" );
    tpa.push( "11:00" );
    tpa.push( "12:00" );
    tpa.push( "13:00" );
    tpa.push( "14:00" );
    tpa.push( "15:00" );
    tpa.push( "16:00" );
    tpa.push( "17:00" );
    tpa.push( "18:00" );
    tpa.push( "19:00" );
    tpa.push( "20:00" );
    tpa.push( "21:00" );
    tpa.push( "22:00" );
    tpa.push( "23:00" );
    tpa.push( "24:00" );
    this.setState({tpa: tpa});
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    tab_style(tabid) {
      var css = {};
      css.display = "none";
      if(this.state.tabval === tabid) css.display = "block";
      return css;
    }
    tab_head(tabid) {
      var css = {};
      css.background = "#fff";
      css.color = "#000";
      if(this.state.tabval === tabid) {
          css.background = "#080";
          css.color = "#fff";
      }
      return css;
    }

    showcal(e) {
      e.preventDefault();

      var brc = this.state.cal_show;
      brc = !brc;
      this.setState({cal_show: brc, cal_step: 0});
    }
  sel_30(e) {
    e.preventDefault();

    if(this.state.cal_step == 0) this.setState({cal_step: 1})
    else this.setState({cal_step: 0});
  }
  sel_no_30(e) {
    e.preventDefault();

    if(this.state.cal_step == 0) this.setState({cal_step: 1})
    else this.setState({cal_step: 0});
  }

    keydown (e) {
      const keyCode = e.keyCode;
      const key = e.key;
      const value = e.target.value;
      //alert(JSON.stringify(keyCode));

      if(keyCode == 9) {
        if(!this.state.cal_show) return;
        this.setState({cal_show: false});
        return;
      }

      e.preventDefault();

      if(keyCode == 13) {
        //if(!this.state.cal_show) return;
        //this.setState({cal_show: false});
        var tps = this.state.tps;
        if(tps.length < 4) tps = tps.tonum(4);
        var tp_hh = tps.substr(0, 2);
        var tp_mm = tps.substr(tps.length-2, 2);
        tps = tp_hh + ":" + tp_mm;
        this.setState({tps: tps, tp_hh: tp_hh, tp_mm: tp_mm, cal_show: false});
        return;
      }
      if(keyCode == 8) {
        var tps = this.state.tps;
        tps = tps.substr(0, tps.length - 1);
        this.setState({tps: tps});
      }
      if(!isNaN(key)) {
        var tps = this.state.tps + key;
        this.setState({tps: tps});
      }
      return;
   }
  formchange (e) {
    e.preventDefault();
    const value = e.target.value;
    alert("CHANGE: " + JSON.stringify(value));
  }

    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    handleClose(e) {
      //alert(JSON.stringify(this.state.dat));
      var dts = format(this.state.dat, "yyyy-MM-dd");
      //this.props.getValue(this.state.dat);
      //alert(JSON.stringify(dts));
      this.props.getValue(dts);
      this.showcal(e);
	};

  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  inputStyle() {
      var css = {};
      if(this.props.rp) {
        css.height = "40px";
        css.lineHeight = "40px";
        css.fontSize = "18px";
        css.borderRadius = "5px";
        css.border = "1px #ccc solid";
      }
      if(this.state.cal_show) {
        css.background = "#080";
        css.color = "#fff";
      }
      return css;
  }
  cssCols (cid) {
      var css = {};
      if(this.state.cols[cid]) css.width = this.state.cols[cid].toString() + "px";
      //if(cid == "c01") alert(JSON.stringify(this.state.cols[cid]));
      return css;
  }
  tps_class(tps) {
      var cls = "";

      cls = "flex-col flex hh-body";
      if(tps == this.state.tps) cls = cls + " dt_seldat";
      //else if(tps == this.state.xps) cls = cls + " dt_seldat";
      //else cls = cls + " dt_day";

      return cls;
  }
  cal_style() {
    var css = {};

    if(!this.node.current) return css;

    var max_w = window.innerWidth;
    var max_h = window.innerHeight;
    var pos_w = this.node.current.getBoundingClientRect().left;
    var pos_h = this.node.current.getBoundingClientRect().top;
    var out_w = false;
    var out_h = false;

    if(max_h < (pos_h + 200)) out_h = true;;
    if(out_h) css.bottom = (max_h - pos_h).toString() + "px";

    if(max_w < (pos_w + 400)) out_w = true;;
    if(out_w) css.right = "5px";

    return css;
  }

  render() {
    //if(!this.props.show) return "";

    //alert(this.props.ctx.state.lro);

    var title = this.props.title;
    if(!title) title = "KLOCKAN";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
          <div ref={this.node} className="dt_sel">
        <input type="text" className="dt_val w150" onClick={(e) => this.showcal(e) }
               onKeyDown={(e) => this.keydown(e) }
               onChange={(e) => this.formchange(e)}
               value={this.state.tps}
               placeholder="Klockslag"
               style={ this.inputStyle() }
        />

       { this.state.cal_show ?
        <div className="dt_cal flex flex-col" style={ this.cal_style() }>
        <div className="dt_head flex-row flex-space-between">

            <div className="txt_btn" onClick={(e) => this.subTp(e) }>
                <FontAwesomeIcon icon="angle-double-left" size="2x"/>
            </div>
            <div className="flex-row"></div>
            <div className="txt_btn text-capitalize" onClick={(e) => this.sel_hh(e) }>
                <div>{ this.state.tp_hh }</div>
            </div>
            <div className="txt_btn text-capitalize" onClick={(e) => this.sel_mm(e) }>
                <div>{ this.state.tp_mm }</div>
            </div>
            <div className="flex-row"></div>
            <div className="txt_btn" onClick={(e) => this.addTp(e) }>
                <FontAwesomeIcon icon="angle-double-right" size="2x"/>
            </div>
        </div>
       { this.state.cal_step == 0 ?
        <div className="dt_content flex">

          <div className="tpv">
            <div className="tpv-grid">
            { this.state.tpa.map((row, key) =>
                <div key={"dt_" + key} className={ this.tps_class(row) } onClick={(e) => this.set_tp(row, e) }>
                  { row }
                </div>
            )}
            </div>
          </div>


        </div>
        : null }

          <div className="dt_foot flex-row flex-space-between">
            <div className="foot_btn" onClick={(e) => this.sel_30(e) }>
                MED 1/2 TIM
            </div>
            <div className="flex-row"></div>
            <div className="foot_btn" onClick={(e) => this.sel_no_30(e) }>
              UTAN 1/2 TIM
            </div>
        </div>

        </div>
        : null }

        </div>
	;
	return html;
  };
}

export default Dt_Tid;