import React, { useState, useEffect, useContext } from 'react';

import * as utils from "../../../assets/utils";
import * as net from "../../../lib/net";
import Wnd_Ok from "../../../lib/ui/wnd_ok";
import Wnd_Error from "../../../lib/ui/wnd_error";
import Wnd_Confirm from "../../../lib/ui/wnd_confirm";

import PdfNotaDlg from "./pdf_nota_dlg";
import Fm_Input from "../../../lib/ui/ff_input";
import Und_Kund from "./und_kund";

class Und_Edit extends React.Component {
    constructor(props, context) {
        super(props);

        //this.webStore = props.store;
        this.state = {};
        this.state.ynq = [];
        this.state.msg = {};
        this.state.wnds = {};

        this.state.fra = [];
        this.state.aa = [];

        this.state.state_text = "AVAKTIVERA";
        this.state.datum = "";
        this.state.notanr = "";
        this.state.kid = "";
        this.state.ktxt = "";
        this.state.tot = {};
        this.state.und = {};
        this.state.und.rows = [];
        if(props.wnds) {
            this.state.datum = props.wnds.datum;
            this.state.notanr = props.wnds.notanr;
            if(props.wnds.und) this.state.und = props.wnds.und;
            if(props.wnds.und.fu.aktiv == "1") this.state.state_text = "AKTIVERA";
            if(props.wnds.und.fu.kundnr) this.state.kid = props.wnds.und.fu.kundnr;
            if(props.wnds.und.krr.krr00) this.state.ktxt = props.wnds.und.krr.krr00.company;
        }

        this.state.pdf = 0;
        this.state.pdf_content = null;
        this.state.loading = 0;
    }

    componentDidUpdate() {
    }
    componentWillUnmount() {
    }
    componentDidMount() {
        //alert(JSON.stringify(this.state.und));
        this.nota_rows();
        //this.syncTb("notaTable");
    }

    pdf_done (ret) {
        this.setState({loading: 0, pdf: 0, pdf_content: null });
    }
    dlg_style() {
      var css = {};
      css.width = window.innerWidth * 0.8;
      css.height = window.innerHeight * 0.9;
      return css;
    }
    tab_style(tabid) {
      var css = {};
      css.display = "none";
      if(this.state.tabval === tabid) css.display = "block";
      return css;
    }
    tb_style() {
      var css = {};
        css.height = (window.innerHeight * 0.9) - 325;
        css.overflow = "auto";
      return css;
  }
    th_style(id) {
        var css = {};
        css.cursor = "pointer";
        if(id == this.state.sortid) {
            if(this.state.sortdir) css.color = "#800";
            else css.color = "#080";
        }
        return css;
    }
    belopp_style(row) {
        var css = {};
        //css.cursor = "pointer";
        css.textAlign = "right";
        return css;
    }
    xrow_style(row) {
        var css = {};
        //css.cursor = "pointer";
        //css.textAlign = "right";

        if(row.r0ttyp == "P") {
            if(row.r0debcre == "0") {
                css.color = "#800";
            }
        }
        if(row.r0ttyp == "B") {
            if(row.r0debcre == "1") {
                css.color = "#800";
            }
        }
        return css;
    }
   
    tab_head(tabid) {
      var css = {};
      css.background = "#fff";
      css.color = "#000";
      if(this.state.tabval === tabid) {
          css.background = "#080";
          css.color = "#fff";
      }
      return css;
    }
    nota_rows() {
        var self = this;
        var prm = {};
        prm.req = "fakt.fakt_pos.underlag_notanr";
        prm.datum = this.state.datum;
        prm.notanr = this.state.notanr;

        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret.rco));

            var ktxt = ret.rco.krr.krr00.company;
            self.setState({ und: ret.rco, ktxt: ktxt });
            self.syncTb("notaTable");
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }
    inv_state() {
        if(this.state.und.fu.aktiv == "1") {
            this.inv_act();
        }
        else {
            this.inv_deact();
        }
    }
    inv_act() {
        var self = this;
        var prm = {};
        prm.req = "fakt.fakt_pos.inv_act";
        prm.wfkt00 = this.state.und.fu;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.rco));
                self.setState({ state_text: "AVAKTIVERA" });
                if(self.props.wnds.cb) {
                    self.props.wnds.cb(self.props.ctx);
                }
                else self.props.ctx.setState({ wnds: {} });
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    inv_deact() {
        var self = this;
        var prm = {};
        prm.req = "fakt.fakt_pos.inv_deact";
        prm.wfkt00 = this.state.und.fu;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.rco));
                self.setState({ state_text: "AKTIVERA" });
                if(self.props.wnds.cb) {
                    self.props.wnds.cb(self.props.ctx);
                }
                else self.props.ctx.setState({ wnds: {} });
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    inv_fakt() {
        var self = this;
        var prm = {};
        prm.req = "fakt.fakt_pos.inv_fakt";
        prm.wfkt00 = this.state.und.fu;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.rco));
                self.setState({ state_text: "FAKTURERA" });
                if(self.props.wnds.cb) {
                    self.props.wnds.cb(self.props.ctx);
                }
                else self.props.ctx.setState({ wnds: {} });
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    fak_del() {
        var self = this;
        var msg = { info: false, confirm: true, text: "Ta bort internfaktura?", text0: "JA", text1: "NEJ", cb: "fak_del_do" };
        self.setState({msg: msg });
    }
    fak_del_do(val) {
        var self = this;

        self.setState({msg: {} });
        if(val != "0") {
            //alert(JSON.stringify("EXIT"));
            return;
        }

        var self = this;
        var prm = {};
        prm.req = "fakt.fakt_int.int_remove";
        prm.faktyp = "I";
        prm.faknr = this.state.faknr;
        prm.wfak00 = this.state.fak.wfh.wfak00;

        net.sio_req(prm)
        .then(function(ret) {
            //alert(JSON.stringify(ret.rco));

            var msg = { info: false, error: true, text: "Borttagen ..."};
            self.setState({msg: msg });
            setTimeout(function(){
                self.setState({msg: {} });
                if(self.props.wnds.cb) {
                    self.props.wnds.cb(self.props.ctx);
                }
                else self.props.ctx.setState({ wnds: {} });
                return;
            }, 1000);
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }
    fak_addrow() {
        var self = this;
        var wnds = {};
        wnds.rowadd = 1;
        wnds.faknr = this.state.faknr;
        wnds.cb = this.cb_fak;
        this.setState({ wnds: wnds });
    }
    cb_fak (ctx) {
        var prm = {};
        prm.req = "fakt.fakt_fak.fak_recalc";
        prm.faktyp = "I";
        prm.faknr = ctx.state.faknr;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.rco));

                var prm = {};
                prm.req = "fakt.fakt_int.int_fak";
                prm.faknr = ctx.state.faknr;
                return net.sio_req(prm);
            })
            .then(function(ret){
                //alert(JSON.stringify(ret.rco));

                var fra = ret.rco.sale;
                ctx.setState({ wnds: {}, fak: ret.rco, fra: fra });
                ctx.syncTb("fakTable");
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    row_edit (row) {
        var self = this;
        var wnds = {};
        wnds.rowedit = 1;
        wnds.faknr = this.state.faknr;
        wnds.wfr = row;
        wnds.cb = this.cb_fak;
        this.setState({ wnds: wnds });
    }
    fak_addund () {
        var self = this;
        var wnds = {};
        wnds.fakund = 1;
        wnds.faknr = this.state.faknr;
        this.setState({ wnds: wnds });
    }
    fak_head() {
        var self = this;
        var wnds = {};
        wnds.fakhead = 1;
        wnds.faknr = this.state.faknr;
        wnds.wfh = this.state.fak.wfh;
        this.setState({ wnds: wnds });
    }
    kund_koppl() {
        var self = this;
        var prm = {};
        prm.req = "fakt.fakt_pos.inv_tokid";
        prm.wfkt00 = this.state.und.fu;
        prm.kid = this.state.kid;
        //alert(JSON.stringify(prm));

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.rca));

                var msg = { info: false, ok: true, text: "Kopplad ..."};
                self.setState({msg: msg });
                setTimeout(function(){
                    self.setState({msg: {} });
                    return;
                }, 1000);
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    wnd_close() {
        this.props.ctx.setState({ wnds: {} });
    };
    syncTb(tb){
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
            tdElements = tbel.getElementsByTagName('td');
        if(tdElements.length < 1 ) return;

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
                width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
    prn_nota() {
        var self = this;

        var prm = {};
        prm.req = "fakt.fakt_pos.fu_prn";
        prm.datum = this.state.datum;
        prm.notanr = this.state.notanr;
        //alert(JSON.stringify(prm));
        //return;

        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret.ok));

            //var uri = "data:application/pdf;base64," + ret.pdf;
            self.setState({pdf: 1, pdf_content: ret.pdf });
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }
    val_save(id, val) {
        //var wfh = this.state.wfh;
        //var id1 = id.split(".")[0];
        //var id2 = id.split(".")[1];
        //wfh[id1][id2] = val;
        //this.setState({ id: val });
        var state = this.state;
        state[id] = val;
        alert(JSON.stringify(id));
        this.setState(state);
        return;
    }
    ksz() {
        var self = this;
        var wnds = {};
        wnds.kund = 1;
        wnds.cb = this.cb_kund;
        this.setState({ wnds: wnds });
    }
    cb_kund(ctx, kro) {
        var self = this;
        var kid = kro.krr00.kid;
        var ktxt = kro.krr00.company;
        ctx.setState({ wnds: {}, kid: kid, ktxt: ktxt });
    }

    render() {
        //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "UNDERLAG";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="m2_backdrop" /> : null }
        <div className="m2_dlg mx_80h flex-col" style={ this.dlg_style() }>
        <div className="mx_h01 flex-row" style={{background: "#1a456b", color: "#fff"}}>
            <div>UNDERLAG NOTANR: { utils.toNum( this.state.notanr ) }</div>
            <div className="flex"></div>
            <div onClick={() => this.wnd_close() }>X</div>
        </div>
        <div className="mx_content flex-col flex px-3">

        <div className="flex-row cdi-txt" style={{ padding: "0px 10px" }}>
        <div className="flex-col">
            <div className="flex-row">
                <div className="w100">Datum</div>
                <div className="">{ utils.toNum( this.state.und.datum ) }</div>
            </div>
            <div className="flex-row">
                <div className="w100">Notanr</div>
                <div className="">{ utils.toLang( this.state.und.notanr ) }</div>
            </div>
            <div className="flex-row">
                <div className="w100">Bord</div>
                <div className="">{ utils.toLang( this.state.und.bord ) }</div>
            </div>
        </div>
        <div className="flex-col flex">
        </div>
        <div className="flex-col">
            <div className="flex-row">
                <div className="w100">Belopp</div>
                <div className="">{ utils.toPris( this.state.und.belopp ) }</div>
            </div>
            <div className="flex-row">
                <div className="w100">Kassör</div>
                <div className="">{ utils.toLang( this.state.und.cnr ) }</div>
            </div>
        </div>
        </div>
        <div className="divider_orange"></div>

            <div className="flex-row">
                <Fm_Input className="text-left w250 hide" text={this.state.kid} getValue={(e)=> this.val_save("kid", e) } />
                <div className="txt_14">{this.state.kid} - {this.state.ktxt}</div>
                <div className="flex"></div>
                <div className="link_14" onClick={() => this.ksz() } >SÖK KUND</div>
                <div className="link_14" onClick={() => this.kund_koppl() } >KOPPLA KUND</div>
            </div>

        <div className="divider_orange"></div>

        <div className="flex-col flex">
        <div id="notaTable">
        <table className="table table-bordered table-hover tb " style={{marginBottom: "0px"}}>
            <thead>
            <tr>
                <th style={ this.th_style( "fakradnr" ) }>Radnr</th>
                <th style={ this.th_style( "fakplu" ) }>Plu</th>
                <th style={ this.th_style( "text" ) }>Text</th>
                <th style={ this.th_style( "antal" ) }>Antal</th>
                <th style={ this.th_style( "pris" ) }>Pris</th>
                <th style={ this.th_style( "sum" ) }>Summa</th>
                <th style={ this.th_style( "ink" ) }>Moms</th>
                <th style={ this.th_style( "tkn" ) }>Tecken</th>
            </tr>
            </thead>
        </table>
        <div style={ this.tb_style() }>
       <table className="table table-bordered table-hover tb">
            <tbody>
            { this.state.und.rows.map((row, key) =>
            <tr key={ key } style={ this.xrow_style(row) } onDoubleClick={() => this.row_edit(row) }>
                <td>{ row.r0lopnr }</td>
                <td>{ row.r0etyp }</td>
                <td>{ row.r0text }</td>
                <td>{ utils.toNum( row.r0noof ) }</td>
                <td style={ this.belopp_style(row) } >{ utils.toPris( row.r0perprice ) }</td>
                <td style={ this.belopp_style(row) } >{ utils.toPris( (row.r0noof * 1) * (row.r0perprice * 1) ) }</td>
                <td style={{textAlign: "center"}}>{ utils.toPris( row.r0vatproc ) }</td>
                <td>{ row.r0debcre }</td>
            </tr>
            )}
            </tbody>
        </table>
        </div>
        </div>
    </div>

            <div className="divider_orange"></div>

    <div className="flex-row flex-space-between">
        <div className="flex-col">
            <div className="cdi-label">Netto</div>
            <div className="">{ utils.toPris( this.state.und.belopp ) }</div>
        </div>
        <div className="flex-col">
            <div className="cdi-label">Moms</div>
            <div className="">{ utils.toPris( this.state.und.moms ) }</div>
        </div>
        <div className="flex-col">
            <div className="cdi-label">Brutto</div>
            <div className="">{ utils.toPris( this.state.und.belopp ) }</div>
        </div>
    </div>

        </div>
        <div className="mx_foot flex-row">
            <button className="btn btn-hw-red w150" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex"></div>
            <button className="btn btn-hw-green w150" onClick={(event) => this.prn_nota() } >
                SKRIV UT
            </button>
            <div className="flex"></div>
            <button className="btn btn-hw-green w150" onClick={(event) => this.inv_fakt() } >
                FAKTURERA
            </button>
            <button className="btn btn-hw-green w150 hide" onClick={(event) => this.inv_state() } >
                { this.state.state_text }
            </button>
        </div>
      </div>
            { this.state.wnds.kund ? <Und_Kund wnds={this.state.wnds} ctx={this} backdrop={true}/> : null }
            <PdfNotaDlg show={this.state.pdf} pdf={this.state.pdf_content} done={(ret) => this.pdf_done(ret)} backdrop={true}/>
            <Wnd_Error msg={this.state.msg} ctx={this} />
            <Wnd_Ok msg={this.state.msg} ctx={this} />
            <Wnd_Confirm msg={this.state.msg} ctx={this} />
    </div>
	;
    return html;
};
}

export default Und_Edit;