import React, { useState, useEffect, useContext } from 'react';
import {format, getHours, subDays} from "date-fns";

import * as net from "../../lib/net";
import * as utils from "../../assets/utils";

import Td_Sel from "../../lib/ui/td_sel";
import Dt_Cal from "../../lib/ui/dt_cal";

import KFakt_Add from "./fkn/kfakt_add";
import KFakt_Edit from "./fkn/kfakt_edit";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Wrk_Kund extends React.Component {
  constructor(props, context) {
    super(props);

      this.dt_fd = React.createRef();
      this.dt_td = React.createRef();

    this.store = props.store;
    this.bcs = props.store.bcs;
    this.state = {};
    this.state.avd = "01";

    this.state.fka = [];
    this.state.tot = {};

    this.state.tro = {};
    this.state.wnds = {};
      this.state.typ = "";
      this.state.sortid = "";
      this.state.sortdir = 1;

    this.state.today = new Date();
    var datum = format(this.state.today, "yyyy-MM-dd");
    this.state.fdat = datum.substr(0, 8) + "01";
    this.state.tdat = datum;

    this.selitems = [
        {fkn: 'open', text: 'ÖPPNA'},
        {fkn: 'status', text: 'STATUS'},
        {fkn: 'remove', text: 'TA BORT'}
    ];
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      this.fak_per();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 265;
      css.overflow = "auto";
      return css;
  }
  tab_style(typ) {
      var css = {};
      if(this.state.typ == typ) { css.background = "#009900"; css.color = "#fff"; }
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }
  th_style(id) {
      var css = {};
      css.cursor = "pointer";
      if(id == this.state.sortid) css.color = "#080";
      return css;
  }
    dt_fdat(e) {
        //alert(JSON.stringify(e));
        var dts = format(e, "yyyy-MM-dd");
        this.setState({ fdat: dts });
    }
    dt_tdat(e) {
        //alert(JSON.stringify(e));
        var dts = format(e, "yyyy-MM-dd");
        this.setState({ tdat: dts });
    }
    oneday(e) {
        //alert(JSON.stringify(e));
        //this.setState({ tdat: this.state.fdat });
        this.dt_td.current.updateDt(this.state.fdat);
    }
    addday(e) {
        //alert(JSON.stringify(e));
        //this.setState({ tdat: this.state.fdat });
        this.dt_fd.current.updateAdd();
        this.dt_td.current.updateAdd();
    }
    subday(e) {
        //alert(JSON.stringify(e));
        //this.setState({ tdat: this.state.fdat });
        this.dt_fd.current.updateSub();
        this.dt_td.current.updateSub();
    }

    kfak_add() {
        var self = this;
        var wnds = {};
        wnds.kfaktadd = true;
        wnds.cb = self.cb_fkn;
        this.setState({ wnds: wnds });
    }
  kfak_edit(row) {
    var self = this;
    var wnds = {};
    wnds.kfaktedit = true;
    wnds.fko = row;
    wnds.cb = self.cb_fkn;
    this.setState({ wnds: wnds });
  }
    cb_fkn(ctx) {
        ctx.setState({ wnds: {} });
        ctx.fak_per();
    }
    task_fkn() {
    }

    fak_per() {
    var self = this;
    self.setState({ fka: [] });

    var prm = {};
    prm.req = "bfs.kfak.fak_per";
    prm.typ = "K";
    prm.fdat = this.state.fdat;
    prm.tdat = this.state.tdat;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        self.setState({ fka: ret.rca, tot: ret.rco });
        self.syncTb("fkaTable");
    })
    .catch(function(e) {
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }
    syncTb(tb) {
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
        tdElements = tbel.getElementsByTagName('td');
        if(tdElements.length < 1 ) return;

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
            width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
   tb_sort(typ) {
      var self = this;
      //var rvs = self.state.rvs;
      var fka = self.state.fka;
      var sz = this.state.sz_val;

       fka.sort((a,b) => (a[typ] > b[typ]) ? 1 : ((b[typ] > a[typ]) ? -1 : 0));
      self.setState({ fka: fka, sortid: typ });
   }
  sel_row_fkn(row, sel) {
      //alert(JSON.stringify(fkn));
    if(sel.fkn == "open") {
        //this.sel_gst(row);
    }
    if(sel.fkn == "br") {
    }
  }
   refresh() {
      this.fak_per();
    }
    stat_txt(status) {
        var text = "";
        status = status.toString().tonum(1);
        if( status == '0' )  text = 'REGISTRERAD';
        if( status == '1' )  text = 'BEARBETAS';
        if( status == '3' )  text = 'VÄNTAR';
        if( status == '5' )  text = 'KLAR';
        if( status == '7' )  text = 'FAKTURERAD';
        if( status == '9' )  text = 'AVSLUTAD';
        return text;
    }
    pr_txt(pid) {
        var text = "";
        pid = pid.toString().tonum(5);
        if( pid == '00000' )  text = 'EJ TILLDELAD';
        if( pid == '00001' )  text = 'Joakim';
        if( pid == '00002' )  text = 'Daniel';
        if( pid == '00003' )  text = 'Stellan';
        if( pid == '00004' )  text = 'Tobias';
        if( pid == '00005' )  text = 'Emmanuel';
        if( pid == '00006' )  text = 'Kari';
        return text;
    }
    kk_txt(kid) {
        var text = kid.ztrim();
        kid = kid.toString().tonum(10);
        var key = "K" + kid;
        if(this.bcs && this.bcs.kro && this.bcs.kro[key]) {
            text = text + " - " + this.bcs.kro[key].kk00.ftg;
        }
        return text;
    }
    th_icon(typ) {
        var cls = "";
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;
        if(sortid == typ)
        {
            if(sortdir) cls = "fa fa-caret-up";
            else cls = "fa fa-caret-down";
        }
        return cls;
    }
    tb_sort(typ) {
        var self = this;
        //var rvs = self.state.rvs;
        var fka = self.state.fka;
        var sz = this.state.sz_val;
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;

        if(sortid != typ) sortdir = 0;
        else {
            if(sortdir) sortdir = 0;
            else sortdir = 1;
        }

        if(sortdir) fka.sort((a,b) => (a[typ] < b[typ]) ? 1 : ((b[typ] < a[typ]) ? -1 : 0));
        else fka.sort((a,b) => (a[typ] > b[typ]) ? 1 : ((b[typ] > a[typ]) ? -1 : 0));
        self.setState({ fka: fka, sortid: typ, sortdir: sortdir });
    }

  render() {
    return (
    <div className="web-app">
    <div className="flex-row flex-space-between">
        <div className="flex-row">
            <div className="flex-row">
                <div className="dt-btn-01" onClick={() => this.subday() } ><FontAwesomeIcon icon="minus" size="1x" /></div>
                <Dt_Cal dts={ this.state.fdat } rp="1" ref={this.dt_fd} getValue={(e)=> this.dt_fdat(e)} />
                <div className="dt-btn-01" onClick={() => this.oneday() } >1</div>
                <Dt_Cal dts={ this.state.tdat } rp="1" ref={this.dt_td} getValue={(e)=> this.dt_tdat(e)} />
                <div className="dt-btn-01" onClick={() => this.addday() } ><FontAwesomeIcon icon="plus" size="1x" /></div>
            </div>
        </div>

        <div className="flex"></div>

        <div className="flex-row">
            <button className="btn btn-hw-orange w200" onClick={() => this.kfak_add() } aria-label="System" >
                REG KUND FAKTURA
            </button>
            <button className="btn btn-hw-blue w200" onClick={() => this.refresh() } aria-label="System" >
                UPPDATERA
            </button>
        </div>
    </div>
    <div className="divider_orange"></div>

        <div id="fkaTable" className="flex">
          <table className="table table-bordered table-hover tb" style={{marginBottom: "0px"}}>
            <thead>
            <tr>
                <th style={ this.th_style("f0faknr") } onClick={() => this.tb_sort("f0faknr") }>Faknr <i className={ this.th_icon("f0faknr") }></i></th>
                <th style={ this.th_style("f0extfaknr") } onClick={() => this.tb_sort("f0extfaknr") }>Extfaknr <i className={ this.th_icon("f0extfaknr") }></i></th>
                <th style={ this.th_style("f0kid") } onClick={() => this.tb_sort("f0kid") }>Kund iD <i className={ this.th_icon("f0kid") }></i></th>
                <th style={ this.th_style("f0company") } onClick={() => this.tb_sort("f0company") }>Företag <i className={ this.th_icon("f0company") }></i></th>
                <th style={ this.th_style("f0text") } onClick={() => this.tb_sort("f0text") }>Text <i className={ this.th_icon("f0text") }></i></th>
                <th style={ this.th_style("f0belopp") } onClick={() => this.tb_sort("f0belopp") }>Belopp <i className={ this.th_icon("f0belopp") }></i></th>
                <th style={ this.th_style("f0fakdatum") } onClick={() => this.tb_sort("f0fakdatum") }>Fakturadatum <i className={ this.th_icon("f0fakdatum") }></i></th>
                <th style={ this.th_style("f0ffdatum") } onClick={() => this.tb_sort("f0ffdatum") }>Förfallodatum <i className={ this.th_icon("f0ffdatum") }></i></th>
                <th style={ this.th_style("f0bokad") } onClick={() => this.tb_sort("f0bokad") }>Bokad <i className={ this.th_icon("f0bokad") }></i></th>
                <th></th>
            </tr>
            </thead>
        </table>
        <div style={ this.tb_style() }>
          <table className="table table-bordered table-hover tb">
            <tbody>
            { this.state.fka.map((row, key) =>
            <tr key={key} onDoubleClick={() => this.kfak_edit(row) }>
                <td>{ utils.toNum( row.f0faknr ) }</td>
                <td>{ utils.toNum(row.f0extfaknr) }</td>
                <td>{ utils.toNum(row.f0kid) }</td>
                <td>{ utils.toLang( row.f0company ) }</td>
                <td>{ utils.toLang( row.f0text ) }</td>
                <td>{ utils.toPris( row.f0belopp ) }</td>
                <td>{ utils.toDate( row.f0fakdatum ) }</td>
                <td>{ utils.toDate( row.f0ffdatum ) }</td>
                <td>{ row.f0bokad }</td>
                <td>
                    <Td_Sel items={ this.selitems } getValue={(e)=> this.sel_row_fkn(row, e)} />
                </td>
            </tr>
            )}
            </tbody>
        </table>
        </div>
    </div>
        <div className="divider_orange"></div>
        <div className="flex-row px-3">
            <div className="txt_18">ANTAL: { utils.toNum(this.state.tot.antal ) }</div>
            <div className="flex"></div>
            <div className="txt_18">SUMMA: { utils.toPris( this.state.tot.belopp ) }</div>
        </div>


        { this.state.wnds.kfaktadd ? <KFakt_Add wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.wnds.kfaktedit ? <KFakt_Edit wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }

   </div>
  );
    }
}
export default Wrk_Kund;
