import React, { useState, useEffect, useContext } from 'react';
import {Route, Routes} from "react-router-dom";
import {observer} from "mobx-react";

import Op_Home from './op/home.js';
import Op_Ank from './op/ank.js';
import Op_Ci from './op/ci.js';
import Op_Inh from './op/inh.js';
import Op_Avr from './op/avr.js';
import Op_Pco from './op/pco.js';
import Op_Co from './op/co.js';
import Op_Noshow from './op/noshow.js';
import {withRouter} from "../lib/react/withRouter";
import sso from "../sso";

function pg_home() {

    var html =
    <div className="web-content">
      Hem
    </div>
    ;
    return html;
}

class Pms_Op extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.state = {};
    //context.posrend = this;
  }

  xxn(row) {
      var html = "";
      if(row.active) html = <li className="">{row.text}</li>;
      else html = <li className=""><a href={row.href}>{row.text}</a></li>;
      return html;
  }
  fknGo(row) {
    //alert(row.href);
    //this.props.xstore.setFkn(row);
    this.props.store.fkn = row;
      sso.seo.sfkn = row;
    this.props.navigate(row.url);
  }
    br_css(row) {
        var css = {};
        if(sso.seo.sfkn && (row.url == sso.seo.sfkn.url)) css.color = "#080";
        //var brc = false;
        //if(row.url == sbo.state.url) brc = true;
        return css;
    }

  render() {
      var path = window.location.pathname;
      if(path.substr(-1) == "/") path = path.substring(0, path.length - 1);
      var op = path.split("/").pop();
      var po = {};
      po.ank = { "fid": "f03", "url": "/pms/op/ank", "name": "Ankommande", "active": false };
      po.ci = { "fid": "f03", "url": "/pms/op/ci", "name": "Checkin", "active": false };
      po.inh = { "fid": "f03", "url": "/pms/op/inh", "name": "Inhouse", "active": false };
      po.avr = { "fid": "f03", "url": "/pms/op/avr", "name": "Avresande", "active": false };
      po.pco = { "fid": "f03", "url": "/pms/op/pco", "name": "Delutcheckade", "active": false };
      po.co = { "fid": "f03", "url": "/pms/op/co", "name": "Utcheckade", "active": false };
      po.noshow = { "fid": "f03", "url": "/pms/op/noshow", "name": "No Show", "active": false };
      if(po[op]) {
          po[op].active = true;
          //this.props.store.fkn = po[op];
          //this.props.xstore.setFkn(po[op]);
      }
      var pa = Object.values(po);

    return (
    <div className="web-app">
         { this.props.store.cfg.bread ?
        <div className="web-bread">
            <div className="breadcrumb">
            { pa.map((row, key) =>
                <div className="breadcrumb-item" key={key} style={this.br_css(row)} onClick={() => this.fknGo(row)}>{row.name}</div>
            )}
            <div className="flex"></div>
            <div className="cdigreen">{ this.props.store.fkn ? this.props.store.fkn.name : null }</div>
            </div>
        </div>
        : null }
        <Routes>
          <Route path="/xx" component={pg_home} />
            <Route path="ank" element={ <Op_Ank store={this.props.store} />} />
            <Route path="ci" element={ <Op_Ci store={this.props.store} />} />
            <Route path="inh" element={ <Op_Inh store={this.props.store} />} />
            <Route path="avr" element={ <Op_Avr store={this.props.store} />} />
            <Route path="pco" element={ <Op_Pco store={this.props.store} />} />
            <Route path="co" element={ <Op_Co store={this.props.store} />} />
            <Route path="noshow" element={ <Op_Noshow store={this.props.store} />} />
            <Route path="*" element={ <Op_Home store={this.props.store} />} />
        </Routes>
    </div>

  );
}
}
//Sys.contextType = ADOContext;

export default withRouter(observer(Pms_Op));
