import React, { useState, useEffect, useContext } from 'react';
import * as net from "../lib/net";
import {withRouter} from "../lib/react/withRouter";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Ta_Home extends React.Component {
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.state = {};
      this.state.mna = [];
      this.state.mna.push({ mnid: "mn01", pos: "c1", text: "PERSONAL", desc: "Personal och lönehantering", link: "/ta/ee" });
      this.state.mna.push({ mnid: "mn02", pos: "c1", text: "SCHEMA", desc: "Schema styrning", link: "/ta/shd" });
      this.state.mna.push({ mnid: "mn03", pos: "c1", text: "PASS", desc: "Pass styrning", link: "/ta/pass" });
      this.state.mna.push({ mnid: "mn04", pos: "c1", text: "TIDER", desc: "Löneberedning och tidshantering", link: "/ta/th" });
      this.state.mna.push({ mnid: "mn05", pos: "c2", text: "KALKYL", desc: "Kalkyl, Utfall mm", link: "/ta/calc" });
      this.state.mna.push({ mnid: "mn06", pos: "c2", text: "RAPPORTER", desc: "Rapport funktioner", link: "/ta/rp" });
      this.state.mna.push({ mnid: "mn06", pos: "c2", text: "BACKOFFICE", desc: "Backoffice funktioner", link: "/ta/bo" });
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 200;
      return css;
  }
    mnc(pos) {
        var mna = [];
        for(var row of this.state.mna) {
            if(row.pos == pos) mna.push( row );
        }
        return mna;
    }
    fknGo(url) {
        //alert(row.href);
        this.props.navigate(url);
    }

  trans() {
      var self = this;
    var datum = "20200328";
    var prm = {};
    prm.req = "pos.pos_cas.trs_dt";
    prm.fdat = datum;
    prm.tdat = datum;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        self.setState({tra: ret.rca });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
    }

  render() {
    return (
        <div className="web-app">
            <div className="web-pg py-4 flex">
                <h3>TIDSYSTEM</h3>
                <p>Tidsystem med löneberedning, schema, pass och lönehantering</p>

                <div className="divider_green"></div>

                <div className="web-row">
                    <div className="flex-col flex">
                        { this.mnc("c1").map((row, key) =>
                            <div key={key} className="web-bx" onClick={() => this.fknGo(row.link) }>
                                <div>{ row.text }</div>
                                <div className="flex-row">
                                    <div className="flex">{ row.desc }</div>
                                    <div className="web-link">Välj</div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="flex-col flex">
                        { this.mnc("c2").map((row, key) =>
                            <div key={key} className="web-bx" onClick={() => this.fknGo(row.link) }>
                                <div>{ row.text }</div>
                                <div className="flex-row">
                                    <div className="flex">{ row.desc }</div>
                                    <div className="web-link">Välj</div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
  );
    }
}
export default withRouter(Ta_Home);
