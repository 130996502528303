import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../../lib/net";
import * as utils from "../../../assets/utils";
import Fr_Input from "../../../lib/ui/fr_input";
import Slot_Orders from "./slot_orders";

class Slot_Edit extends React.Component {
  constructor(props, context) {
    super(props);

    this.pos = props.store.pos;
    this.state = {};
    this.state.ynq = [];
    this.state.sval = [];

    this.state.dao = {};
    this.state.aa = [];
    this.state.cval = [
                {id: '001', text: 'ANTAL GER NYTT PRIS'},
                {id: '002', text: 'ANTAL GER RABATT PÅ PRIS'},
                {id: '003', text: 'PRISÄNDRING GENOM RABATT'}
            ];

      this.state.wnds = {};
      this.state.slo = {};
    if(props.wnds) {
        this.state.slo = props.wnds.slo;
    }
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.happy();
  }
    cdi_init() {
        var self = this;
        var vpo = self.state.vpo;

        if(vpo.vip00) {
            //vpo.vip00.q0pris2 = vpo.vip00.q0pris2.formatpris();
            //vpo.vip00.q0npris2 = vpo.vip00.q0npris2.formatpris();
        }

        //self.setState({hpo: hpo,  md_init: true});
    }
    cdi_store() {
        var self = this;
/*
        var hap = self.state.hap;
        var hpo = self.state.hpo;

        if(!hap.hap00) {
            hap.hap00 = {};
        }
        hap.hap00 = {};

        hap.hap00.q0p = "Q";
        hap.hap00.q0kstns = this.state.pbo.plu00.p0kstns
        hap.hap00.q0katnr = this.state.pbo.plu00.p0katnr

        hap.hap00.q0pris2 = "";
        hap.hap00.q0npris2 = "";

        if(hpo.q0pris2) hap.hap00.q0pris2 = hpo.q0pris2.pris2cdi();
        if(hpo.q0npris2) hap.hap00.q0npris2 = hpo.q0npris2.pris2cdi();

        self.setState({hap: hap});
*/
    }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    tab_style(tabid) {
      var css = {};
      css.display = "none";
      if(this.state.tabval === tabid) css.display = "block";
      return css;
    }
    tab_head(tabid) {
      var css = {};
      css.background = "#fff";
      css.color = "#000";
      if(this.state.tabval === tabid) {
          css.background = "#080";
          css.color = "#fff";
      }
      return css;
    }
    grp_style(row) {
      var css = {};
      css.background = "#008";
      if(this.state.gnr === row.vgrupp) css.background = "#080";
      return css;
    }

    formchange (e) {
      const name = e.target.name;
      const value = e.target.value;
      var pbo = this.state.pbo;
      var na = name.split(".");
      pbo[na[0]][na[1]] = value;
      this.setState({pbo: pbo});
      //setState({[name]: value});
    }

    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    wnd_close = () => {
        if(this.props.wnds.cb) {
            this.props.wnds.cb(this.props.ctx);
        }
        else this.props.ctx.setState({ wnds: {} });
	};
    handleChange = (event, newValue) => {
        //alert(newValue);
        this.setState({ix: newValue});
      };
    toggle() {
      this.setState({modal: false});
	};

  submitHandler = event => {
    event.preventDefault();
    event.target.className += " was-validated";
  };

  changeHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  cssCols (cid) {
      var css = {};
      if(this.state.cols[cid]) css.width = this.state.cols[cid].toString() + "px";
      //if(cid == "c01") alert(JSON.stringify(this.state.cols[cid]));
      return css;
  }

  slot_save() {
    var self = this;
    var prm = {};
    prm.req = "pos.pos_ecom_slot.slot_upd";
    prm.slo = this.state.slo;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        //self.setState({hap: ret.rco }, function() { this.cdi_init(); });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
    }

    slot_del() {
        var self = this;
        var prm = {};
        prm.req = "pos.pos_ecom_slot.slot_del";
        prm.slo = this.state.slo;

        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret.rca));

            if(self.props.wnds.cb) {
                self.props.wnds.cb(self.props.ctx);
            }
            else self.props.ctx.setState({ wnds: {} });
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }

    shownota() {
        var self = this;
        var wnds = {};
        wnds.ordertrs = 1;
        wnds.vpo = this.state.vpo;
        self.setState({ wnds: wnds });
    }
    val_set (name, value) {
        //alert(JSON.stringify(name));
        //alert(JSON.stringify(value));
        //var na = name.split(".");
        var slo = this.state.slo;
        //vpo[na[0]][na[1]] = value;
        slo[name] = value;
        this.setState({slo: slo});
    }

    vip_trs() {
        var self = this;
        var wnds = {};
        wnds.viptrs = 1;
        self.setState({ wnds: wnds, vpo: this.state.vpo });
    }

  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "Knappar";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        <div className="m2_backdrop" />
        <div className="m2_dlg mx_80 flex-col" style={ this.dlg_style() } >
        <div className="mx_head">
            <div>SLOT: { utils.toDate(this.state.slo.datum) } { utils.toTid(this.state.slo.slottid) }</div>
        </div>
        <div className="mx_content flex">

            <div className="flex-row" style={{padding:"5px",height:"35px"}}>
                    <div className="flex-col flex">
                    </div>
                    <div className="flex-col">
                        <div>UPPDATERA</div>
                    </div>
            </div>
            <div className="divider_orange"></div>


            <form className="cdi_f03">
                <div className="form-row flex-row">
                    <Fr_Input label="Datum" className="w250" text={ this.state.slo.datum } getValue={(e)=> this.val_set("datum", e) } placeholder="" />
                </div>
                <div className="form-row flex-row">
                    <Fr_Input label="Slottid" className="w250" text={ this.state.slo.slottid } getValue={(e)=> this.val_set("slottid", e) } placeholder="" />
                </div>
                <div className="form-row flex-row">
                    <Fr_Input label="Slotslut" className="w250" text={ this.state.slo.slotslut } getValue={(e)=> this.val_set("slotslut", e) } placeholder="" />
                </div>
                <div className="divider_green"></div>
                <div className="form-row flex-row">
                    <Fr_Input label="Text" className="w350" text={ this.state.slo.text } getValue={(e)=> this.val_set("text", e) } placeholder="" />
                </div>
                <div className="form-row flex-row">
                    <Fr_Input label="Max antal" className="w250" text={ this.state.slo.maxorder } getValue={(e)=> this.val_set("maxorder", e) } placeholder="" />
                </div>
                <div className="divider_green"></div>
                <div className="form-row flex-row">
                    <Fr_Input label="Slottyp" className="w250" text={ this.state.slo.slottyp } getValue={(e)=> this.val_set("slottyp", e) } placeholder="" />
                </div>
                <div className="form-row flex-row">
                    <Fr_Input label="Status" className="w150" text={ this.state.slo.status } getValue={(e)=> this.val_set("status", e) } placeholder="" />
                </div>
            </form>


        </div>
        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="form-row flex-row">
                <button className="btn btn-hw-red w250" onClick={() => this.slot_del() } >
                    TA BORT
                </button>
                <button className="btn btn-hw-green w250" onClick={() => this.shownota() } >
                    SE BONGAR
                </button>
            </div>
            <button className="btn btn-hw-green w250" onClick={() => this.slot_save() } >
                SPARA
            </button>
        </div>
      </div>
        { this.state.wnds.ordertrs ?
                <Slot_Orders wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
        : null }
      </div>
	;
	return html;
  };
}

export default Slot_Edit;