import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";

import Dep_Edit from "./dep/dep_edit";
import Dep_New from "./dep/dep_new";
import * as utils from "../../assets/utils";
import Td_Sel from "../../lib/ui/td_sel";

class Bo_Dep extends React.Component {
  constructor(props, context) {
    super(props);

    this.store = props.store;

    this.state = {};
    this.state.seldate = new Date();
    this.state.avd = "01";
    this.state.dpa = [];
    this.state.kro = {};
    this.state.aa = [];
    this.state.cols = {};
    this.state.wnds = {};
      this.state.msg = {};
      this.state.sortid = "";
      this.state.sortdir = 1;

      this.selitems = [
          {fkn: 'open', text: 'ÖPPNA'},
          {fkn: 'stat', text: 'STATUS'}
      ];
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.deps();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
    tb_style() {
        var css = {};
        css.height = window.innerHeight - 250;
        css.overflow = "auto";
        return css;
    }
    th_style(id) {
        var css = {};
        css.cursor = "pointer";
        if(id == this.state.sortid) css.color = "#080";
        return css;
    }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

  refresh() {
      this.deps();
  }
  wnd_new() {
    var self = this;
    var wnds = {};
    wnds.depnew = true;
    this.setState({ wnds: wnds });
  }
  wnd_edit(row) {
    var self = this;
    var wnds = {};
    wnds.depedit = true;
    wnds.dpo = row;
    this.setState({ wnds: wnds });
  }

  deps() {
    var self = this;
    var prm = {};
    prm.req = "ta.ta_def.dep_list";
    //prm.avd = this.state.avd;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        self.setState({dpa: ret.rca });
        self.syncTb("depTable");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }
    syncTb(tb){
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
            tdElements = tbel.getElementsByTagName('td');

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
                width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
    th_icon(typ) {
        var cls = "";
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;
        if(sortid == typ)
        {
            if(sortdir) cls = "fa fa-caret-up";
            else cls = "fa fa-caret-down";
        }
        return cls;
    }
    tb_sort(typ) {
        var self = this;
        //var rvs = self.state.rvs;
        var dpa = self.state.dpa;
        var sz = this.state.sz_val;
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;

        if(sortid != typ) sortdir = 0;
        else {
            if(sortdir) sortdir = 0;
            else sortdir = 1;
        }

        if(sortdir) dpa.sort((a,b) => (a[typ] > b[typ]) ? 1 : ((b[typ] > a[typ]) ? -1 : 0));
        else dpa.sort((a,b) => (a[typ] < b[typ]) ? 1 : ((b[typ] < a[typ]) ? -1 : 0));
        self.setState({ dpa: dpa, sortid: typ, sortdir: sortdir });
    }
    sel_row_fkn(row, sel) {
        if(sel.fkn == "open") {
            this.wnd_edit(row);
        }
    }

  render() {
    return (
    <div className="web-app">

    <div className="flex-row">
        <div className="label_h03">AVDELNINGAR</div>
        <div className="flex"></div>
        <button className="btn btn-hw-blue w150" onClick={() => this.wnd_new() } aria-label="System" >
            Ny
        </button>
        <button className="btn btn-hw-blue w150" onClick={() => this.refresh() } aria-label="System" >
            Visa
        </button>
    </div>
    <div className="divider"></div>

        <div id="depTable">
            <table id="coHead" className="table table-bordered table-hover tb" style={{marginBottom: "0px"}}>
                <thead>
                <tr>
                    <th style={ this.th_style("avdelning") } onClick={() => this.tb_sort("avdelning") }>Avd <i className={ this.th_icon("avdelning") }></i></th>
                    <th style={ this.th_style("namn") } onClick={() => this.tb_sort("namn") }>Text <i className={ this.th_icon("namn") }></i></th>
                    <th style={ this.th_style("restavd") } onClick={() => this.tb_sort("restavd") }>Restavd <i className={ this.th_icon("restavd") }></i></th>
                    <th style={ this.th_style("orgnr") } onClick={() => this.tb_sort("orgnr") }>Orgnr <i className={ this.th_icon("orgnr") }></i></th>
                    <th style={ this.th_style("pmsavd") } onClick={() => this.tb_sort("pmsavd") }>Hotell Avd <i className={ this.th_icon("pmsavd") }></i></th>
                    <th style={ this.th_style("posavd") } onClick={() => this.tb_sort("posavd") }>Rest Avd <i className={ this.th_icon("posavd") }></i></th>
                    <th style={ this.th_style("farg") } onClick={() => this.tb_sort("farg") }>Färg <i className={ this.th_icon("farg") }></i></th>
                    <th style={ this.th_style("status") } onClick={() => this.tb_sort("status") }>Status <i className={ this.th_icon("status") }></i></th>
                    <th></th>
                </tr>
                </thead>
            </table>
            <div style={ this.tb_style() }>
                <table id="coTable" className="table table-bordered table-hover tb">
                    <tbody>
                    { this.state.dpa.map((row, key) =>
                        <tr key={key} onDoubleClick={() => this.wnd_edit(row)}>
                            <td>{ row.avdelning }</td>
                            <td>{ utils.toLang( row.namn ) }</td>
                            <td>{ row.restavd }</td>
                            <td>{ row.orgnr }</td>
                            <td>{ row.pmsavd }</td>
                            <td>{ row.posavd }</td>
                            <td>{ row.farg }</td>
                            <td>{ row.status }</td>
                            <td>
                                <Td_Sel items={ this.selitems } getValue={(e)=> this.sel_row_fkn(row, e)} />
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
        </div>

        { this.state.wnds.depnew ? <Dep_New wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.wnds.depedit ? <Dep_Edit wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
   </div>
  );
}
}
export default Bo_Dep;
