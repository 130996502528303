import React, { useState, useEffect, useContext } from 'react';
import {format, getHours, subDays} from "date-fns";

import * as net from "../../lib/net";
import {SDOProvider} from "../../sdo";

import Dt_Cal from "../../lib/ui/dt_cal";
import PdfRpDlg from "./pdf_rp_dlg";
import ddo from "../../ddo";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Rps_Mr extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, tra: [] };
    this.state.seldate = new Date();
    //this.state.unit = null;
    this.state.sdo = {};
    this.state.unitid = "00";
    this.state.unit = {};
    this.state.dta = [];
      this.state.una = ddo.cfg.una;
      this.state.uno = ddo.cfg.units;

      this.state.today = new Date();
      var hh = getHours(this.state.today);
      if( hh < 7) {
          const dt = subDays(new Date(), 1);
          this.state.datum = format(dt, "yyyy-MM-dd");
      }
      else this.state.datum = format(this.state.today, "yyyy-MM-dd");
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.dagsavslut(this.state.unitid);
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 480;
      return css;
  }

  dt_dat(e) {
    //alert(JSON.stringify(e));
    var dts = format(e, "yyyy-MM-dd");
    this.setState({ datum: dts });
  }

  onUnitChange(e) {
        this.setState({unit: e.value});
  }
  change_unit(e) {
    let unitid = e.target.value;
    var o = this.state.uno[unitid];
    this.setState({unitid: unitid, unit: o, sdo: {} });
    this.dagsavslut(unitid);
  }

  dagsavslut(unitid) {
    var self = this;

    var avd = "";
    var sid = null;
    //var unitid = this.state.unitid;
    if(unitid) {
        var o = this.state.uno[unitid];
        sid = o.sid;
        avd = o.id;
    }
    if(!sid) {
        //alert("VÄLJ ENHET");
        //self.setState({sdo: {} });
        //return;
    }

    var datum = this.state.datum;
    var prm = {};
    prm.avd = avd;
    prm.req = "pos.pos_mlager.dagsavslut";
    prm.datum = datum;

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rco));
        if(ret.ok != "000") {
            self.setState({sdo: {} });
            return;
        }

        var o = {};
        //o.deps = ret.rco;
        //alert(JSON.stringify(o));
        self.setState({sdo: ret.rco });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
    }
  dagsavslut_prn(unitid) {
    var self = this;

    var avd = "";
    var sid = null;
    //var unitid = this.state.unitid;
    if(unitid) {
        var o = this.state.uno[unitid];
        sid = o.sid;
        avd = o.id;
    }
    if(!sid) {
        //alert("VÄLJ ENHET");
        //self.setState({sdo: {} });
        //return;
    }

    var datum = this.state.datum;
    var prm = {};
    //prm.req = "pos.pos_mlager.dagsavslut";
    prm.req = "pos.pos_casino_rp.cas_dr";
    prm.datum = datum;
    prm.avd = avd;

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rco));
        if(ret.ok != "000") {
            return;
        }

        var o = {};
        //o.deps = ret.rco;
        //alert(JSON.stringify(o));
        self.setState({pdf: 1, pdf_content: ret.pdf });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
    }
  pdf_done (ret) {
    this.setState({loading: 0, pdf: 0, pdf_content: null });
  }

  show_val(id) {
     var val = this.state.sdo[id];
     if(!val) val = "0";
     val = val.toString().cdipris();
     val = val + " SEK";
     return val;
  }

  render() {
    return (
    <div className="web-app">
    <h3>Rapport</h3>

    <div className="flex-row flex-space-between">
        <div className="flex-row">
            <Dt_Cal dts={this.state.datum} getValue={(e)=> this.dt_dat(e)} />
        <select className="form-control sel_input w300" placeholder="Välj enhet" value={this.state.unitid} onChange={(e) => this.change_unit(e)} >
            { this.state.una.map((row, key) =>
            <option key={key} value={row.id}>{row.text}</option>
            )}
        </select>
        </div>
        <div className="flex"></div>
         <button className="btn btn-hw-blue w150 ripple" onClick={() => this.dagsavslut_prn(this.state.unitid) } aria-label="System" >
            SKRIV UT
        </button>
         <button className="btn btn-hw-blue w150 ripple" onClick={() => this.dagsavslut(this.state.unitid) } aria-label="System" >
            Visa
        </button>
   </div>
    <div className="divider_orange"></div>

    <div className="web-kvitto">
    <div className="web-row">
        <div className="txt_20">MÅNADS RAPPORT</div>
    </div>
    <div className="web-row">
        <div className="txt_16">{ this.state.datum }</div>
    </div>
    <div className="web-row">
        <div className="txt_16">{ this.state.unit.text }</div>
    </div>

    <br />

    <div className="divider_orange"></div>
    <div className="web-row">
        <div className="txt_b16">OMSÄTTNING</div>
    </div>
     <div className="web-row txt_16">
        <div className="f50">Datum</div>
        <div className="f20">Text</div>
        <div className="f30">Belopp</div>
    </div>
   { this.state.dta.map((row, key) =>
    <div className="web-row txt_16">
        <div className="f50">{row.datum}</div>
        <div className="f20">{row.text}</div>
        <div className="f30 text-right">{ this.show_val("fsg") }</div>
    </div>
    )}

    <div className="web-row txt_b16 flex-space-between">
        <div className="f50">SUMMA</div>
        <div className="f20">#</div>
        <div className="f30 text-right">SEK</div>
    </div>


    </div>

        <PdfRpDlg show={this.state.pdf} pdf={this.state.pdf_content} done={(ret) => this.pdf_done(ret)} backdrop={true}/>
    </div>
  );
    }
}
export default Rps_Mr;
