import React, { useState, useEffect, useContext } from 'react';

function PdfDlg(props) {
    const [state, setState] = useState({ pdf : null });
    //const [pdf, setPdf] = useState({ null });

    //var uri = "data:application/pdf;base64," + ret.pdf;
    //self.setState({ pdf_uri: uri });
    //var pdf_uri = "data:application/pdf;base64," + props.pdf;

    function handlePrn(event) {
        //props.ctx.dologin(state.user.uid, state.user.pwd);
    }
	const handleClose = () => {
		//props.ctx.setState({ pdf: 0, pdf_content: null });
		props.done({ ok: "000" });
	};
    if(!props.show) return "";

    //alert(JSON.stringify(props.ctx.state.pdf_content));
    //var pdf_content = "data:application/pdf;base64," + props.ctx.state.pdf_content;
    var pdf_content = "data:application/pdf;base64," + props.pdf;
    //var pdf_content = "data:application/pdf;" + props.pdf;

    //var pdf_content = null;
    //pdf_content = props.ctx.state.pdf_content;
    if(pdf_content == null) return "";
    //var pdf_uri = props.ctx.state.pdf_uri;
    //alert(pdf_uri);

	var html =
        <div className="mx_base">
        { props.backdrop ? <div className="mx_backdrop" /> : null }
        <div className="mx_dlg_rpx flex-col" style={{zIndex: "19999"}}>
        <div className="mx_title">
            <div className="">Utskrift</div>
            <div className="flex"></div>
            <div className="black-icon-close" onClick={() => handleClose() }>
                X
            </div>
        </div>

        <div className="mx_content flex">
            <object data={ pdf_content } type="application/pdf" style={{width: "100%", height: "100%"}}></object>
        </div>
        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w200" onClick={() => handleClose() } aria-label="System" >
                STÄNG
            </button>
            <button className="btn btn-hw-green w200 web-hide" onClick={(event) => handlePrn(event) } aria-label="System" >
                SKRIV UT
            </button>
        </div>
      </div>
    </div>
	;
	return html;
}

export default PdfDlg;
