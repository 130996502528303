import React, { useState, useEffect, useContext } from 'react';
import {format, addDays, subDays} from "date-fns";

import * as net from "../../../../lib/net";
import Fr_Input from "../../../../lib/ui/fr_input";
import Fr_Select from "../../../../lib/ui/fr_select";
import Dt_Cal from "../../../../lib/ui/dt_cal";
import PdfRpDlg from "./pdf_rp_dlg";
import * as q from "../../../../lib/defer";
import Wnd_Error from "../../../../lib/ui/wnd_error";
import Wnd_Ok from "../../../../lib/ui/wnd_ok";
import Wnd_Info from "../../../../lib/ui/wnd_info";
import Wnd_Confirm from "../../../../lib/ui/wnd_confirm";

class Rv_Bekr extends React.Component {
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pms = props.store.pms;

    this.state = {};
    this.state.today = new Date();
    this.state.rta = [];
    this.state.pka = [];

    this.state.gnr = "001";
    this.state.bokr00 = {};
    this.state.bokr01 = {};
    this.state.konf01 = {};
    //this.state.lro = props.lro;
    this.state.ynq = [
                {id: '0', text: 'NEJ'},
                {id: '1', text: 'JA'}
            ];

    this.state.bgo = {};
    this.state.bto = {};
    this.state.background = "#fff";

    this.state.subject = "";
    this.state.subject = "Dorsia Hotel & Restaurant";
    this.state.email = "";

    this.state.msg = {};
    this.state.cols = {};
    this.state.lrwrk = false;
    this.state.boknr = {};
    this.state.rsv = {};
    this.state.lang = "se";
    if(props.wnds) {
        this.state.boknr = props.wnds.boknr;
        this.state.rsv = props.wnds.rsv;
        this.state.email = props.wnds.rsv.resv02.Email;
        if(props.wnds.rsv.resv04.Scb != "SE") this.state.lang = "en";
    }
    this.state.pdf = 0;
    this.state.pdf_content = null;
    this.state.loading = 0;
  }
  
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.row_init();
    //this.rts();
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    grp_style(row) {
      var css = {};
      css.background = "#008";
      if(this.state.gnr === row.vgrupp) css.background = "#080";
      return css;
    }

    wnd_close = () => {
		//this.props.ctx.setState({ rvbradd: false });
        if(this.props.cb) {
            var ret = {};
            ret.ok = "999";
            this.props.cb(this.props.ctx, ret);
        }
        else this.props.ctx.setState({ wnds: {} });
	};
  pdf_done (ret) {
    this.setState({loading: 0, pdf: 0, pdf_content: null });
  }
    handleChange = (event, newValue) => {
        //alert(newValue);
        this.setState({ix: newValue});
      };
    toggle() {
      this.setState({modal: false});
	};

  submitHandler = event => {
    event.preventDefault();
    event.target.className += " was-validated";
  };

  changeHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

    rsv_save (name, value) {
        //alert(JSON.stringify(name));
        //alert(JSON.stringify(value));
      var rsv = this.state.bro;
      var na = name.split(".");
      rsv[na[0]][na[1]] = value;
      this.setState({rsv: rsv});
      //setState({[name]: value});
    }
    val_save (name, value) {
      var state = this.state;
      state[name] = value;
      this.setState(state);
    }
    cb_save (name, e) {
      const value = e.target.value;
      var dro = this.state.dro;
      dro[name] = value;
      this.setState({dro: dro});
      //setState({[name]: value});
    }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }

  bekr_pdf() {
    var self = this;

    var prm = {};
    prm.req = "pms.bekr.pdf_pms_confirm";
    prm.boknr = this.state.boknr;
    prm.lang = this.state.lang;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.pdf));

        //var s = atob(ret.pdf);
        //alert(JSON.stringify(s));

        self.setState({pdf: 1, pdf_content: ret.pdf });
    })
    .catch(function(e) {
        var txt = JSON.stringify(e);
    });
  }

  bekr_mail() {
    var self = this;

    var subject = this.state.subject;
    var email = this.state.email;
    if(email.trim() == "") {
        alert("Epost saknas");
        return;
    }
    if(subject.trim() == "") {
        subject = "Bokning: " + this.state.boknr;
    }
    var prm = {};
    prm.req = "pms.bekr.mail_pms_confirm";
    prm.boknr = this.state.boknr;
    prm.subject = subject;
    prm.email = email;
    prm.lang = this.state.lang;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        //self.setState({taa: ret.rca });
        self.toast("OK", "Bekräftelse skickad");
    })
    .catch(function(e) {
        var txt = JSON.stringify(e);
    });
  }
  toast(type, txt) {
        var self = this;
        var defer = q.defer();

        var msg = {};
        msg.text = txt;
        if(type == "OK") msg.ok = true;
        if(type == "ERROR") msg.error = true;

        self.setState({msg: msg });
        setTimeout(function(){
            self.setState({msg: {} });
            //self.props.ctx.setState({ pluopen: false });
            //self.props.ctx.plu_upd(self.props.ctx, plubuf);
            var rsp = {};
            defer.resolve(rsp);
        }, 2000);
  }
  showLang() {
    var langtxt = "Svenska";
    if(this.state.lang == "en") langtxt = "Engelska";
    return langtxt;
}
set_lang() {
  var lang = this.state.lang;
  if(lang == "en") lang = "se";
  else lang = "en";
  this.setState({lang: lang});
}

  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "Delbokning";
    //if(props.kro) alert(props.kro.krr00.kid);
    const popover = {
      position: 'absolute',
      top: '50px',
      zIndex: '2',
    }
    const cover = {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    }

	var html =
        <div className="mx_base">
        <div className="m2_backdrop" />
        <div className="m2_dlg mx_70 flex-col" style={ this.dlg_style() } draggable="true" >

        <div className="mx_h01">
            <div>BEKRÄFTELSE</div>
        </div>
        <div className="mx_content flex">

            <div className="flex-row" style={{padding:"5px",height:"35px"}}>
                    <div className="flex-col flex">
                        <div></div>
                    </div>
                    <div className="flex-col">
                        <div></div>
                    </div>
            </div>
            <div className="divider_orange"></div>

            <form className="cdi_f03">
            <div className="flex-row py-2">
                <div className="txt_18">BOKNR : { this.state.boknr }</div>
            </div>
            <div className="divider_green"></div>
            <div className="flex-row py-2">
                    <Fr_Input label="Ämnesrad" className="text-left w350" text={this.state.subject} getValue={(e)=> this.val_save("subject", e) } />
            </div>
            <div className="flex-row py-2">
                    <Fr_Input label="Epost" className="text-left w250" text={this.state.email} getValue={(e)=> this.val_save("email", e) } />
            </div>
            </form>

        </div>

        <div className="flex-row px-2">
            <div className="flex"></div>
            <div className="web-link" onClick={() => this.set_lang() }>{ this.showLang() }</div>
            <div className="flex"></div>
        </div>
        <div className="divider_orange"></div>

       <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                AVBRYT
            </button>
            <div className="flex-row">
            <button className="btn btn-hw-red w250" onClick={() => this.bekr_pdf() } >
               SKRIV UT
            </button>
            <button className="btn btn-hw-red w250" onClick={() => this.bekr_mail() } >
               SKICKA
            </button>
            </div>
        </div>
      </div>
        { this.state.pdf ? <PdfRpDlg show={this.state.pdf} pdf={this.state.pdf_content} done={(ret) => this.pdf_done(ret)} backdrop={true}/> : null }
        <Wnd_Error msg={this.state.msg} ctx={this} />
        <Wnd_Ok msg={this.state.msg} ctx={this} />
        <Wnd_Info msg={this.state.msg} ctx={this} />
        <Wnd_Confirm msg={this.state.msg} ctx={this} />
      </div>
	;
	return html;
  };
}

export default Rv_Bekr;