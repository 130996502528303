import React, { useState, useEffect, useContext } from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import { observer } from 'mobx-react'

import SDOContext from '../sdo.js'

import Bi_Top from './com/bi_top.js';
import Bi_Mnu from './com/bi_mnu.js';

import Bi_Home from './home.js';
import Bi_Dash from './dash.js';
import Bi_Pos from './pos.js';
import Bi_Stat from './stat.js';
import Bi_Bo from './bo.js';
import Bi_Oms from './pos/oms.js';
import Bi_Rev from './rev/rev.js';
import Bi_Cost from './cost/cost.js';
import Bi_Bal from './bal/bal.js';
import Bi_Pt from './pos/pt.js';

import * as net from "../lib/net";
import Tiden from "../lib/ui/tiden";
import {withRouter} from "../lib/react/withRouter";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function web01() {

    var html =
    <div className="web-content">
    </div>
    ;
    return html;
}

class Bi extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.pos = props.store.pos;
    this.state = { pgid: 11, mnu_show: true, deps: [] };
    //context.posrend = this;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.props.fetchWells()
    //  .then(res => this.setState({ wells: res.wells }) )
    var deps = [];
    deps.push( {"id": "01", "text": "DEP X1" } );
    deps.push( {"id": "02", "text": "DEP X2" } );
    deps.push( {"id": "03", "text": "DEP X3" } );
    var pos = {};
    pos.deps = deps;
    this.props.store.pos = pos;
    //alert(JSON.stringify(pos));
      this.pos_init();
  }

  fknGo(url) {
    //var sdo = useContext(SDO);
    //alert(fkn);
    //var fkn = row.fid;
    //setState({ row : row,  ma : [] });
    //ctx.fid = fkn;

    //props.history.push(row.url);
    this.props.navigate(url);
   };

  ddo() {
    //alert("POS" + this.state.pgid );
    //this.setState({ status: 1 });
  }

    pos_init() {
    var self = this;

	var req = {};
	req.req = "pos.pos_fkn.pos_init";

    //gda.wndLoading("Hämtar");
    net.sio_req(req)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        //var pos = {};
        //pos.deps = ret.rco.deps;
        //self.props.store.pos = pos;
        if(!self.props.store.pos) self.props.store.pos = {};
        self.props.store.pos.deps = ret.rco.deps;

        //self.setState({ deps: ret.rco });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //alert(JSON.stringify(txt));
    })
    }

  render() {
    return (
    <div className="web-app">
    <div className="web-top">
        <Bi_Top store={this.webStore} />
    </div>
    <div className="web-main">

    <div className="web-row" style={{ height:"100%" }}>

    { this.props.store.cfg.mnu_show ?
    <div className="web-side">
        <Bi_Mnu store={this.webStore} />
    </div>
    : null }

    <div className="web-body">
        <Routes>
            <Route path="pos/*" element={ <Bi_Pos store={this.webStore} />} />
            <Route path="stat/*" element={ <Bi_Stat store={this.webStore} />} />
            <Route path="bo/*" element={ <Bi_Bo store={this.webStore} />} />
            <Route path="pt/*" element={ <Bi_Pt store={this.webStore} />} />
            <Route path="oms/*" element={ <Bi_Oms store={this.webStore} />} />
            <Route path="rev/*" element={ <Bi_Rev store={this.webStore} />} />
            <Route path="cost/*" element={ <Bi_Cost store={this.webStore} />} />
            <Route path="bal/*" element={ <Bi_Bal store={this.webStore} />} />
            <Route path="*" element={ <Bi_Dash store={this.webStore} />} />
        </Routes>
    </div>

    </div>

    </div>
    <div className="web-foot">
        <div className="web-foot-mnu" onClick={() => this.ddo() }>
            <FontAwesomeIcon icon="bars" size="1x"/>
        </div>
        <div className="flex flex-row">
            <div className="web-foot-btn" onClick={() => this.ddo() } >
                BI
            </div>
            <div className="flex"></div>

            <div className="web-foot-btn" onClick={() => this.fknGo("/bi/stat/tot") } >TOTALT</div>
        </div>
        <div className="web-foot-icon">
          <FontAwesomeIcon icon="ellipsis-v" size="1x"/>
        </div>
        <div className="flex flex-row">
            <div className="web-foot-btn" onClick={() => this.fknGo("/bi/stat/hh") } >ENHETER</div>

            <div className="flex"></div>
            <Tiden />
        </div>
    </div>

    </div>
  );
}
}
//Sys.contextType = ADOContext;

export default withRouter(observer(Bi));

/*
          <Route path="/xx" component={pg_s1} />
          <Route path="/pos/yy" component={pg_s2} />
          <Route path="/cas/" component={pg_s2} />
    <p>KASINO : { this.state.status }</p>
    <div className="flex-row">
    <button className="btn btn-hw-blue w150 ripple" onClick={this.udid_req} aria-label="System" >
        Fråga
    </button>
    <button className="btn btn-hw-blue w150 ripple" onClick={this.settle} aria-label="System" >
        Settle
    </button>
    </div>
*/