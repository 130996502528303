import React, { useState, useEffect, useContext } from 'react';
import {Route, Routes} from "react-router-dom";
import {withRouter} from "../lib/react/withRouter";
import {observer} from "mobx-react";

import Bo_Home from './bo/home.js';
import sso from "../sso";

function pg_home() {

    var html =
    <div className="web-content">
      Hem
    </div>
    ;
    return html;
}

class Pos_Bo extends React.Component {
    //var sdo = useContext(SDO);
    constructor(props, context) {
        super(props);

        this.webStore = props.store;
        this.state = { pgid: 11, deps: [] };
        //context.posrend = this;

        var po = {};
        po.reg = { "fid": "f06", "url": "/pos/reg", "name": "Register", "active": false };
        po.tech = { "fid": "f06", "url": "/pos/tech", "name": "Teknik", "active": false };
        this.state.po = po;
        this.state.pa = [];
        this.state.pa = Object.values(po);
    }
    componentDidUpdate() {
    }
    componentWillUnmount() {
    }
    componentDidMount() {
        /*
        var path = window.location.pathname;
        if(path.substr(-1) == "/") path = path.substring(0, path.length - 1);
        var op = path.split("/").pop();

        var po = this.state.po;
        if(po[op]) {
            po[op].active = true;
            //this.props.store.fkn = po[op];
            sbo.state.fkn = po[op].name;
            //alert(JSON.stringify(sbo.state));
        }
        var pa = Object.values(po);
        this.setState({ po: po, pa: pa });
         */
    }
    fknGo(row) {
        sso.seo.sfkn = row;
        this.props.navigate(row.url);
    }
    br_css(row) {
        var css = {};
        if(sso.seo.sfkn && (row.url == sso.seo.sfkn.url)) css.color = "#080";
        //var brc = false;
        //if(row.url == sbo.state.url) brc = true;
        return css;
    }

    render() {
        return (
            <div className="web-app">
                { this.props.store.cfg.bread ?
                    <div className="web-bread">
                        <div className="breadcrumb">
                            { this.state.pa.map((row, key) =>
                                <div key={key} className="breadcrumb-item" style={this.br_css(row)} onClick={() => this.fknGo(row)}>{row.name}</div>
                            )}
                            <div className="flex"></div>
                            <div className="cdigreen">{ sso.sdo.sfkn ? sso.sdo.sfkn.name : null }</div>
                        </div>
                    </div>
                    : null }
        <Routes>
          <Route path="/xx" component={pg_home} />
            <Route path="di" element={ <Bo_Home store={this.store} />} />
            <Route path="*" element={ <Bo_Home store={this.store} />} />
        </Routes>
    </div>
  );
}
}
//Sys.contextType = ADOContext;

export default withRouter(observer(Pos_Bo));

/*
import Bo_Konto from './bo/konto.js';
import Bo_Deps from './bo/deps.js';
import Bo_Vgrps from './bo/vgrps.js';
import Bo_Pays from './bo/pays.js';
import Bo_Tax from './bo/tax.js';
import Bo_Cur from './bo/cur.js';
import Bo_Cas from './bo/cas.js';
import Bo_Tcfg from './bo/tcfg.js';
import Bo_Tdef from './bo/tdef.js';

      var po = {};
      po.konto = { "fid": "f06", "url": "/pos/bo/konto", "name": "Kontoplan", "active": false };
      po.deps = { "fid": "f06", "url": "/pos/bo/deps", "name": "Avdelningar", "active": false };
      po.vgrps = { "fid": "f06", "url": "/pos/bo/vgrps", "name": "Varugrupper", "active": false };
      po.pays = { "fid": "f06", "url": "/pos/bo/pays", "name": "Betalmedel", "active": false };
      po.tax = { "fid": "f06", "url": "/pos/bo/tax", "name": "Moms", "active": false };
      po.cur = { "fid": "f06", "url": "/pos/bo/cur", "name": "Valuta", "active": false };
      po.cas = { "fid": "f06", "url": "/pos/bo/cas", "name": "Kassörer", "active": false };
      po.tcfg = { "fid": "f06", "url": "/pos/bo/tcfg", "name": "Terminal Def", "active": false };
      po.tdef = { "fid": "f06", "url": "/pos/bo/tdef", "name": "Terminal Cfg", "active": false };

        <Routes>
          <Route path="/xx" component={pg_home} />
          <Route path="/pos/bo/konto" render={(props) => <Bo_Konto {...props} store={this.webStore} />} />
          <Route path="/pos/bo/deps" render={(props) => <Bo_Deps {...props} store={this.webStore} />} />
          <Route path="/pos/bo/vgrps" render={(props) => <Bo_Vgrps {...props} store={this.webStore} />} />
          <Route path="/pos/bo/pays" render={(props) => <Bo_Pays {...props} store={this.webStore} />} />
          <Route path="/pos/bo/tax" render={(props) => <Bo_Tax {...props} store={this.webStore} />} />
          <Route path="/pos/bo/cur" render={(props) => <Bo_Cur {...props} store={this.webStore} />} />
          <Route path="/pos/bo/cas" render={(props) => <Bo_Cas {...props} store={this.webStore} />} />
          <Route path="/pos/bo/tcfg" render={(props) => <Bo_Tcfg {...props} store={this.webStore} />} />
          <Route path="/pos/bo/tdef" render={(props) => <Bo_Tdef {...props} store={this.webStore} />} />
          <Route path="/pos/bo" render={(props) => <Bo_Home {...props} store={this.webStore} />} />
        </Routes>
*/