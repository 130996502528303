import React, { useState, useEffect, useContext } from 'react';
import {Route, Routes} from "react-router-dom";
import {withRouter} from "../lib/react/withRouter";

import Eko_Home from './eko/home.js';
import Eko_Status from './eko/hh.js';
import Eko_Per from './eko/per.js';
import Eko_Rps from './eko/rps.js';
import Eko_Trs from './eko/trs.js';
import Eko_Kvitton from './eko/kvitton.js';
import sso from "../sso";

function pg_home() {

    var html =
        <div className="web-content">
            Hem
        </div>
    ;
    return html;
}

class Ecom_Eko extends React.Component {
    //var sdo = useContext(SDO);
    constructor(props, context) {
        super(props);

        this.store = props.store;
        this.state = { pgid: 11, deps: [] };
        var po = {};
        po.status = { "fid": "f05", "url": "/ecom/eko/status", "name": "Status", "active": false };
        po.per = { "fid": "f05", "url": "/ecom/eko/per", "name": "Period", "active": false };
        po.rps = { "fid": "f05", "url": "/ecom/eko/rps", "name": "Rapporter", "active": false };
        po.trs = { "fid": "f05", "url": "/ecom/eko/trs", "name": "Transaktioner", "active": false };
        po.kvitton = { "fid": "f05", "url": "/ecom/eko/kvitton", "name": "Kvitton", "active": false };
        this.state.po = po;
        this.state.pa = [];
        this.state.pa = Object.values(po);
    }
    componentDidUpdate() {
    }
    componentWillUnmount() {
    }
    componentDidMount() {
    }

    fknGo(row) {
        sso.seo.sfkn = row;
        this.props.navigate(row.url);
    }
    br_css(row) {
        var css = {};
        if(sso.seo.sfkn && (row.url == sso.seo.sfkn.url)) css.color = "#080";
        //var brc = false;
        //if(row.url == sbo.state.url) brc = true;
        return css;
    }

    render() {
        return (
            <div className="web-app">
                { this.props.store.cfg.bread ?
                    <div className="web-bread">
                        <div className="breadcrumb">
                            { this.state.pa.map((row, key) =>
                                <div key={key} className="breadcrumb-item" style={this.br_css(row)} onClick={() => this.fknGo(row)}>{row.name}</div>
                            )}
                            <div className="flex"></div>
                            <div className="cdigreen">{ sso.seo.sfkn ? sso.seo.sfkn.name : null }</div>
                        </div>
                    </div>
                    : null }
                <Routes>
                    <Route path="/xx" component={pg_home} />
                    <Route path="status" element={ <Eko_Status store={this.store} />} />
                    <Route path="per" element={ <Eko_Per store={this.store} />} />
                    <Route path="rps" element={ <Eko_Rps store={this.store} />} />
                    <Route path="trs" element={ <Eko_Trs store={this.store} />} />
                    <Route path="kvitton" element={ <Eko_Kvitton store={this.store} />} />
                    <Route path="*" element={ <Eko_Home store={this.store} />} />
                </Routes>
            </div>

        );
    }
}
//Sys.contextType = ADOContext;

export default withRouter(Ecom_Eko);
