import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../../lib/net";
import * as utils from "../../../assets/utils";

import Fr_Input from "../../../lib/ui/fr_input";

import Wnd_Error from "../../../lib/ui/wnd_error";
import Wnd_Ok from "../../../lib/ui/wnd_ok";
import Wnd_Info from "../../../lib/ui/wnd_info";
import Wnd_Confirm from "../../../lib/ui/wnd_confirm";
import Td_Sel from "../../../lib/ui/td_sel";
import ddo from "../../../ddo";

class Md_Plu_Sz extends React.Component {
  constructor(props, context) {
    super(props);

      this.store = props.store;
    ///this.pos = props.store.pos;

    this.state = {};
    this.state.mpa = [];

    this.state.gnr = "001";
    this.state.ynq = [
                {id: '0', text: 'NEJ'},
                {id: '1', text: 'JA'}
            ];
      this.state.mtval = [
          {id: 'bas', text: 'BAS MENY'},
          {id: 'xtra', text: 'EXTRA MENY'}
      ];

    this.state.tabval = 0;
    this.state.selrow = {};
    this.state.msg = {};
    this.state.wnds = {};
      this.state.mtyp = "";
      this.state.mavd = "01";
      this.state.mnuid = "";
      this.state.title = "NY MENYDATA";
    this.state.mno = {};
    this.state.mdo = {};
    //if(props.wnds.mdo) this.state.mdo = props.wnds.mdo;
    if(props.wnds) {
        this.state.mtyp = props.wnds.mtyp;
        if(props.wnds.mno) {
            this.state.mno = props.wnds.mno;
            //this.state.mavd = this.state.mno.avd;
            this.state.mnuid = this.state.mno.mnuid;
        }
        if(props.wnds.mdo) this.state.mdo = props.wnds.mdo;
    }
      if(this.state.mtyp == "E") this.state.title = "NY EXTRA INGREDIENS";
      if(this.state.mtyp == "T") this.state.title = "NYTT TILLBEHÖR";
      if(this.state.mtyp == "S") this.state.title = "NY SIDEORDER";
      if(this.state.mtyp == "D") this.state.title = "NY DRYCK";

      this.state.paa = props.store.pos.plus;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      if(!this.props.wnds.mdo) this.md_init();
      if(this.state.paa.length) this.syncTb("pluSz");
      else this.plus();
  }

    md_init() {
        var mdo = {};
        mdo.m0typ = this.state.mtyp;
        mdo.m0kstns = this.state.mavd;
        mdo.m0mnuid = this.state.mnuid;
        mdo.m0katnr = "";
        mdo.m0ingnr = "";
        mdo.m0text = "";
        mdo.m0pris = "";
        mdo.m0moms = "";
        mdo.m0debcre = "";
        mdo.m0fast = "";
        mdo.m0sort = "";
        //alert(JSON.stringify(rso));
        this.setState({ mdo: mdo });
        return;
    }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    tb_style() {
        var css = {};
        css.height = (window.innerHeight * 0.8) - 175;
        css.overflow = "auto";
        return css;
    }
    th_style(id) {
        var css = {};
        css.cursor = "pointer";
        if(id == this.state.sortid) {
            if(this.state.sortdir) css.color = "#800";
            else css.color = "#080";
        }
        return css;
    }
    row_style(row) {
        var srow = this.state.selrow;

        var css = {};
        if(srow == row) {
            css.background = "#080";
            css.color = "#fff";
        }
        return css;
    }
    tab_head(tabid) {
        var css = {};
        css.background = "#fff";
        css.color = "#000";
        if(this.state.tabval === tabid) {
            css.background = "#080";
            css.color = "#fff";
        }
        return css;
    }
    tab_style(tabid) {
        var css = {};
        css.display = "none";
        if(this.state.tabval === tabid) css.display = "block";
        return css;
    }
    tab_sel(tabid) {
      var self = this;
        this.setState({tabval: tabid}, function() {
            if(tabid == 1) {
                self.syncTb("mnpTable");
            }
        });
    }

    plus() {
        var self = this;

        var prm = {};
        prm.req = "pos.pos_plu.plus";
        //prm.avd = this.state.avd;
        prm.avd = ddo.cache.avd;
        //alert(JSON.stringify(prm));

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret));

                self.props.store.pos.plus = ret.rco;
                var paa = ret.rco;
                self.setState({ paa: paa });
                self.syncTb("pluSz");
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    plu_sel(row) {

        if(this.props.wnds.cb) {
            var rsp = {};
            rsp.ok = "000";
            rsp.pbo = row;
            this.props.wnds.cb(this.props.ctx, rsp);
        }
        else this.props.ctx.setState({ wnds: {} });
    };
    wnd_close = () => {
        if(this.props.close) {
            this.props.close(this.props.ctx);
        }
        else this.props.ctx.setState({ wnds: {} });
    };
    anm_change (e) {
        const value = e.target.value;
        var mdo = this.state.mdo;
        mdo.anm = value;
        this.setState({mdo: mdo});
    }

    val_save(id, val) {
        var mdo = this.state.mdo;
        mdo[id] = val;
        this.setState({ mdo: mdo });
        return;
    }
    sel_row(row) {
        var srow = this.state.selrow;
        if(srow == row) {
            this.setState({selrow: {}});
        }
        else this.setState({selrow: row});
        this.syncTb("mnpTable");
    }
    sel_row_fkn(row, sel) {
        if(sel.fkn == "open") {
        }
    }
    syncTb(tb) {
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
            tdElements = tbel.getElementsByTagName('td');
        if(tdElements.length < 1 ) return;

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
                width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
    tb_sort(typ) {
        var self = this;
        //var rvs = self.state.rvs;
        var rva = self.state.rva;
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;

        if(sortid != typ) sortdir = 0;
        else {
            if(sortdir) sortdir = 0;
            else sortdir = 1;
        }

        if(typ == "ank") {
            if(sortdir) rva.sort((a,b) => (a.gsb00.r0ankdatum < b.gsb00.r0ankdatum) ? 1 : ((b.gsb00.r0ankdatum < a.gsb00.r0ankdatum) ? -1 : 0));
            else rva.sort((a,b) => (a.gsb00.r0ankdatum > b.gsb00.r0ankdatum) ? 1 : ((b.gsb00.r0ankdatum > a.gsb00.r0ankdatum) ? -1 : 0));
        }
        self.setState({ rva: rva, sortid: typ, sortdir: sortdir });
    }
    th_icon(typ) {
        var cls = "";
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;
        if(sortid == typ)
        {
            if(sortdir) cls = "fa fa-caret-up";
            else cls = "fa fa-caret-down";
        }
        return cls;
    }
    cat_sz() {
        var self = this;
    }
    md_add() {
        var self = this;
    var prm = {};
    prm.req = "pos.pos_mnudata.md_add";
    prm.mdo = this.state.mdo;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));
        if(ret.ok == "000") {
            self.setState({ msg: {ok: true, text: "Sparad ..."} });
            setTimeout(function(){
                self.setState({msg: {} });
                  if(self.props.wnds.cb) {
                      self.props.wnds.cb(self.props.ctx);
                  }
                  else self.props.ctx.setState({ wnds: {} });
                return;
            }, 1000);
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

  render() {
    //if(!this.props.show) return "";
    var title = this.props.title;
    if(!title) title = "Resurs";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        <div className="m2_backdrop" />
        <div className="m2_dlg mx_70h flex-col" style={ this.dlg_style() } >
        <div className="mx_content flex">

        <div className="mx_h01">
            <div>{ this.state.title }</div>
        </div>
        <div className="divider_orange"></div>

            <div id="pluSz" className="flex">
                <table className="table table-bordered table-hover tb" style={{marginBottom:"0px"}}>
                    <thead>
                    <tr>
                        <th style={ this.th_style("plu") } onClick={() => this.tb_sort("plu") }>PLU</th>
                        <th style={ this.th_style("vgrp") } onClick={() => this.tb_sort("vgrp") }>Varugrupp</th>
                        <th style={ this.th_style("text") } onClick={() => this.tb_sort("text") }>Text</th>
                        <th style={ this.th_style("pris") } onClick={() => this.tb_sort("pris") }>Pris</th>
                        <th>Moms</th>
                        <th>Sort</th>
                        <th>Uppdat</th>
                        <th></th>
                    </tr>
                    </thead>
                </table>
                <div style={ this.tb_style() }>
                    <table className="table table-bordered table-hover tb" style={{marginBottom:"0px"}}>
                        <tbody>
                        { this.state.paa.map((row, key) =>
                            <tr key={key} onDoubleClick={() => this.plu_sel(row) }>
                                <td>{ row.plu00.p0katnr }</td>
                                <td>{ row.plu02.p2vgrupp }</td>
                                <td>{ row.plu00.p0namn.toswe() }</td>
                                <td className="text-right">{ row.plu00.p0pris.formatpris() }</td>
                                <td>{ row.plu00.p0moms }</td>
                                <td>{ row.plu00.p0sort }</td>
                                <td>{ row.plu00.p0upddat }</td>
                                <td>
                                    <Td_Sel items={ this.selitems } getValue={(e)=> this.sel_row_fkn(row, e)} />
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex-row">
            <button className="btn btn-hw-red w250" onClick={() => this.md_add() } >
                VÄLJ
            </button>
            </div>
        </div>
      </div>

        <Wnd_Error msg={this.state.msg} ctx={this} />
        <Wnd_Ok msg={this.state.msg} ctx={this} />
        <Wnd_Info msg={this.state.msg} ctx={this} />
        <Wnd_Confirm msg={this.state.msg} ctx={this} />
      </div>
	;
	return html;
  };
}

export default Md_Plu_Sz;