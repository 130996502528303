import React, { useState, useEffect, useContext } from 'react';
import {Route, Routes} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {withRouter} from "../../lib/react/withRouter";
import { observer } from "mobx-react";
import {withStore} from "../../sdo";

import * as net from "../../lib/net";

import Tiden from "../../lib/ui/tiden";
import Dd_Sel from "../../lib/ui/dd_sel";

import Red_Top from './red_top.js';
import Red_Mnu from './red_mnu.js';
import Red_Home from './home.js';
import Red_Dash from './dash.js';
import Red_Auth from './auth.js';
import Red_Rps from './rps.js';
import Red_Trs from './trs.js';

function web01() {

    var html =
    <div className="web-content">
    </div>
    ;
    return html;
}

class Red extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.selitems = [
        {fkn: 'setup', text: 'INSTÄLLNINGAR'},
        {fkn: 'upd', text: 'UPPDATERA'},
        {fkn: 'sys', text: 'SYSTEM'},
        {fkn: 'logout', text: 'LOGGA UT'}
    ];
    this.btmitems = [
        {fkn: 'kreg', text: 'KUNDREGISTER'},
        {fkn: 'logg', text: 'LOGG'},
        {fkn: 'hist', text: 'HISTORIK'}
    ];
    this.langitems = [
        {fkn: 'sv', text: 'SVENSKA'},
        {fkn: 'no', text: 'NORSKA'},
        {fkn: 'en', text: 'ENGELSKA'}
    ];

    this.store = props.store;
    this.state = {};

    this.state.wnds = {};
    this.state.boknr = "";
    this.state.lang = "sv";

  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    var pos = {};
    pos.deps = [];
    pos.vgrps = [];
    pos.plus = [];
    this.props.store.pos = pos;
    //alert(JSON.stringify(pos));
      this.pos_init();
  }

    pos_init() {
    var self = this;

	var req = {};
	req.req = "pos.pos_fkn.pos_init";

    //gda.wndLoading("Hämtar");
    net.sio_req(req)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        //var pos = {};
        //pos.deps = ret.rco.deps;
        //self.props.store.pos = pos;
        if(!self.props.store.pos) self.props.store.pos = {};
        self.props.store.pos.deps = ret.rco.deps;
        self.props.store.pos.vgrps = ret.rco.vgrps;

        //self.setState({ deps: ret.rco });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //alert(JSON.stringify(txt));
    })
    }
  fknGo(url) {
    //alert(row.href);
    this.props.navigate(url);
  }
  sel_fkn(sel) {
    //alert(JSON.stringify(fkn));
      if(sel.fkn == "logout") {
      }
  }
  sel_btm(sel) {
    //alert(JSON.stringify(fkn));
      if(sel.fkn == "kreg") this.fknGo("/pms/fo/kreg")
      if(sel.fkn == "logg") this.fknGo("/pms/fkn/logg")
  }
  sel_lang(sel) {
    //alert(JSON.stringify(fkn));
      if(sel.fkn == "sv") {
        this.props.xstore.setLang("sv");
      }
      if(sel.fkn == "no") {
        this.props.xstore.setLang("no");
      }
      if(sel.fkn == "en") {
        this.props.xstore.setLang("en");
      }
  }

  render() {
      if(!this.props.store.pos.redauth) {
          //this.props.store.pos.regauth = true;
            //alert("REGISTER AUTH");
            return (
            <div className="web-app">
                <div className="web-body">
                    <Routes>
                        <Route path="*" element={ <Red_Auth store={this.store} />} />
                    </Routes>
                </div>
            </div>
          );
      }
    return (
    <div className="web-app">
    <div className="web-top">
        <Red_Top ctx={this} store={this.store} />
    </div>
    <div className="web-main">

    <div className="web-row" style={{ height:"100%" }}>

    { this.props.store.cfg.mnu_show ?
    <div className="web-side">
        <Red_Mnu store={this.store} />
    </div>
    : null }

    <div className="web-body">
        <Routes>
            <Route path="deps" element={ <Red_Home store={this.store} />} />
            <Route path="home" element={ <Red_Home store={this.store} />} />
            <Route path="trs/*" element={ <Red_Trs store={this.store} />} />
            <Route path="rps/*" element={ <Red_Rps store={this.store} />} />
            <Route path="*" element={ <Red_Home store={this.store} />} />
        </Routes>
    </div>

    </div>

    </div>
    <div className="web-foot">
        <div className="web-foot-mnu" onClick={() => this.rvn_open() } title="NY BOKNING">
            <FontAwesomeIcon icon="file-alt" size="1x"/>
        </div>
        <div className="flex flex-row">
            <div className="flex"></div>

            <div className="web-foot-btn" onClick={() => this.fknGo("/pms/reg/tr") } >TRANSAKTIONER</div>
        </div>
        <Dd_Sel items={ this.btmitems } getValue={(e)=> this.sel_btm(e)} />
        <div className="flex flex-row">
            <div className="web-foot-btn" onClick={() => this.fknGo("/pms/reg/pay") }>BETALNINGAR</div>

            <div className="flex"></div>
        </div>
        <div className="web-foot-rmnu hide" >
            <Dd_Sel items={ this.langitems } getValue={(e)=> this.sel_lang(e)} />
        </div>
        <Tiden />
    </div>

    </div>
  );
}
}
//Sys.contextType = ADOContext;
export default withRouter(withStore(observer(Red)));
