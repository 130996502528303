import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";
import Dt_Cal from "../../lib/ui/dt_cal";
import {format} from "date-fns";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Stat_Typ extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, tra: [] };
    this.state.seldate = new Date();
    this.state.typ = "01";
    this.state.dta = [];
    this.state.aa = [];

      this.state.today = new Date();
      var dat = this.state.today;
      ///dat = subDays(dat, 1);
      var dts = format(dat, "yyyyMMdd");
      this.state.datum = dts;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.reg_list();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 250;
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

    dt_dat(e) {
        //alert(JSON.stringify(e));
        var dts = format(e, "yyyyMMdd");
        this.setState({ datum: dts });
    }

  ladda() {
    var self = this;
    var prm = {};
    prm.req = "vcm.pres.refill";
    prm.typ = this.state.typ;
    prm.id = "12";
    prm.belopp = "4500";

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        alert(JSON.stringify(ret));

    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }
  kt_list() {
    var self = this;
    var prm = {};
    prm.req = "vip.vip_buy.kt_per";
    prm.typ = this.state.typ;
    prm.fdat = this.state.datum;
    prm.tdat = this.state.datum;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        self.setState({dta: ret.rca });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

  render() {
    return (
    <div className="web-p00 flex flex-col">
        <div className="">
            PER KORTTYP
        </div>

    <div className="web-p01">
    <div className="flex-row">
        <Dt_Cal startdatum="2020-02-01" getValue={(e)=> this.dt_dat(e)} />
        <select className="form-control w250" placeholder="Välj avdelning">
            { this.state.aa.map((row, key) =>
            <option key={key} value={row.id}>{row.text}</option>
            )}
        </select>
        <div className="flex"></div>

        <button className="btn btn-hw-blue w150 ripple" onClick={() => this.kt_list() } aria-label="System" >
            Visa
        </button>
    </div>
    <div className="divider">
    </div>

    <div className="web-row">
        <table className="table table-bordered table-hover tb fixed_header">
            <thead>
            <tr>
				<th>Datum</th>
				<th>Text</th>
				<th>Antal Debit</th>
				<th>Antal Kredit</th>
				<th>Debit</th>
				<th>Kredit</th>
				<th>Status</th>
            </tr>
            </thead>
            <tbody style={ this.tb_style() }>
            { this.state.dta.map((dto, key) =>
            <tr key={key} onDoubleClick={ () => this.dto_edit(dto) }>
                <td>{ dto.datum }</td>
                <td>{ dto.text }</td>
                <td>{ dto.nrd.ztrim() }</td>
                <td>{ dto.nrk.ztrim() }</td>
                <td>{ dto.debit.cdipris() }</td>
                <td>{ dto.kredit.cdipris() }</td>
                <td>{ dto.status }</td>
            </tr>
            )}
            </tbody>
        </table>
    </div>

    </div>
    </div>
  );
    }
}
export default Stat_Typ;
