import React, { useState, useEffect, useContext } from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import * as net from "../../../lib/net";
import * as utils from "../../../assets/utils";

import Fr_Input from "../../../lib/ui/fr_input";
import Fr_Select from "../../../lib/ui/fr_select";
import Fr_Cal from "../../../lib/ui/fr_cal";
import Wnd_Error from "../../../lib/ui/wnd_error";
import Wnd_Ok from "../../../lib/ui/wnd_ok";
import Wnd_Info from "../../../lib/ui/wnd_info";
import Wnd_Confirm from "../../../lib/ui/wnd_confirm";
import Sz_Kid from "./sz_kid";
import {addDays, format} from "date-fns";
import Dt_Cal from "../../../lib/ui/dt_cal";
import Fr_Tid from "../../../lib/ui/fr_tid";

class Cal_Edit extends React.Component {
  constructor(props, context) {
    super(props);

      this.dt_fd = React.createRef();
      this.dt_td = React.createRef();
      this.dt_ftid = React.createRef();
      this.dt_ttid = React.createRef();

    this.bcs = props.store.bcs;
    this.state = {};
    this.state.mka = [];

    this.state.gnr = "001";
    //this.state.lro = props.lro;
      this.state.ynq = [
          {id: '0', text: 'NEJ'},
          {id: '1', text: 'JA'}
      ];

    this.state.wnds = {};
    this.state.msg = {};
    this.state.cdo = {};
    this.state.cdo.status = "";

      this.state.fdat = "";
      this.state.tdat = "";
    this.state.dtime = "";
    this.state.ktxt = "";

      if(props.wnds.cdo) {
          this.state.odo = props.wnds.cdo.cal00;
          this.state.cdo = JSON.parse(JSON.stringify(this.state.odo));
          this.state.fdat = utils.toDate(this.state.cdo.fdat);
          this.state.tdat = utils.toDate(this.state.cdo.tdat);
          this.state.cdo.ftid = utils.toTid(this.state.cdo.ftid);
          this.state.cdo.ttid = utils.toTid(this.state.cdo.ttid);
          //alert(JSON.stringify(this.state.cdo));
      }
      this.state.preg = [
          {id: '00000', text: 'EJ TILLDELAD'},
          {id: '00001', text: 'Joakim'},
          {id: '00002', text: 'Daniel'},
          {id: '00003', text: 'Stellan'},
          {id: '00004', text: 'Tobias'},
          {id: '00005', text: 'Emmanuel'},
          {id: '00006', text: 'Kari'}
      ];
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      //this.cdo_init();
  }

    cdo_init() {
        var dt = new Date();
        //var fdat = new Date();
        //var tdat = addDays(fdat, 7);

        var cdo = {};
        cdo.typ = "C";
        cdo.fdat = format(dt, "yyyy-MM-dd");
        cdo.tdat = format(dt, "yyyy-MM-dd");
        cdo.ftid = "";
        cdo.ttid = "";
        cdo.text = "";
        cdo.kid = "";
        cdo.status = "";
        //alert(JSON.stringify(rso));
        this.setState({ cdo: cdo });
        return;
    }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }

    dt_fdat(e) {
        //alert(JSON.stringify(e));
        var dts = format(e, "yyyy-MM-dd");
        this.setState({ fdat: dts });
    }
    dt_tdat(e) {
        //alert(JSON.stringify(e));
        var dts = format(e, "yyyy-MM-dd");
        this.setState({ tdat: dts });
    }
    oneday(e) {
        //alert(JSON.stringify(e));
        //this.setState({ tdat: this.state.fdat });
        this.dt_td.current.updateDt(this.state.fdat);
    }
    addday(e) {
        //alert(JSON.stringify(e));
        //this.setState({ tdat: this.state.fdat });
        this.dt_fd.current.updateAdd();
        this.dt_td.current.updateAdd();
    }
    subday(e) {
        //alert(JSON.stringify(e));
        //this.setState({ tdat: this.state.fdat });
        this.dt_fd.current.updateSub();
        this.dt_td.current.updateSub();
    }

    wnd_close = () => {
      if(this.props.close) {
          this.props.close(this.props.ctx);
      }
      else this.props.ctx.setState({ wnds: {} });
	};
    preg_change (e) {
        const value = e.target.value;
        var cdo = this.state.cdo;
        cdo.pid = value;
        this.setState({cdo: cdo});
    }
    sreg_change (e) {
        const value = e.target.value;
        var tdo = this.state.tdo;
        tdo.sys = value;
        this.setState({tdo: tdo});
    }
    sval_change (e) {
        const value = e.target.value;
        var tdo = this.state.tdo;
        tdo.status = value;
        this.setState({tdo: tdo});
    }

    cdata_change (e) {
        const value = e.target.value;
        var cdo = this.state.cdo;
        cdo.cdata = value;
        this.setState({ cdo: cdo });
    }
    val_state_save(id, val) {
        var state = this.state;
        state[id] = val;
        this.setState(state);
        return;
    }
    val_save(id, val) {
        var cdo = this.state.cdo;
        cdo[id] = val;
        this.setState({ cdo: cdo });
        return;
    }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
    stat_style(sval) {
        var css = {};
        var status = this.state.cdo.status.toString().tonum(1);
        if(sval == status) {
            css.background = "#080";
            css.color = "#fff";
        }
        return css;
    }

    cal_upd() {
    var self = this;

    var cdo = this.state.cdo;
    //ts = ts.replaceAll("-", "");
    //ts = ts.replaceAll(".", "");
    cdo.fdat = this.state.fdat;
    cdo.tdat = this.state.tdat;

    //alert(JSON.stringify(cdo));

    var prm = {};
    prm.req = "bcs.cal.cal_upd";
    prm.odo = this.state.odo;
    prm.cdo = cdo;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));
        if(ret.ok == "000") {
            self.setState({ msg: {ok: true, text: "Sparad ..."} });
            setTimeout(function(){
                self.setState({msg: {} });
                if(self.props.wnds.cb) {
                    self.props.wnds.cb(self.props.ctx);
                }
                else self.props.ctx.setState({ wnds: {} });
                return;
            }, 1000);
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  cal_del() {
    var self = this;
    var prm = {};
    prm.req = "bcs.cal.cal_del";
    prm.cdo = this.state.cdo;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));
        if(ret.ok == "000") {
            self.setState({ msg: {ok: true, text: "Borttagen ..."} });
            setTimeout(function(){
                self.setState({msg: {} });
                if(self.props.close) {
                    self.props.close(self.props.ctx);
                }
                else self.props.ctx.setState({ wnds: {} });
                return;
            }, 1000);
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
    sz_kid() {
        var self = this;
        var wnds = {};
        wnds.szkid = true;
        wnds.tdo = this.state.tdo;
        wnds.cb = self.cb_kid;
        this.setState({ wnds: wnds });
    }
    cb_kid(ctx, rsp) {
        //alert(JSON.stringify(rsp));
        if(rsp.ok == "000") {
            var kko = rsp.kko;
            var tdo = ctx.state.tdo;
            tdo.kid = kko.kk00.kid;
            var key = "K" + tdo.kid;
            var ktxt = "";
            if(ctx.bcs.kro[key]) {
                ktxt = ctx.bcs.kro[key].kk00.ftg;
            }
            ctx.setState({ wnds: {}, tdo: tdo, ktxt: ktxt });
            return;
        }
        ctx.setState({ wnds: {} });
        //ctx.tasks();
        //if(ctx.state.tdo.kid) ctx.state.ktxt = ctx.bcs.kro[this.state.tdo.kid];
    }
    set_status(status) {
        var cdo = this.state.cdo;
        cdo.status = status;
        this.setState({cdo: cdo});
    }

  render() {
    //if(!this.props.show) return "";
    var title = this.props.title;
    if(!title) title = "Resurs";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        <div className="m2_backdrop" />
        <div className="m2_dlg mx_90 flex-col" style={ this.dlg_style() } >
        <div className="mx_content flex">

        <div className="mx_h01">
            <div>NY TID</div>
            <div className="flex"></div>
            <div className="flex"></div>
            <div onClick={() => this.wnd_close() }>X</div>
        </div>
            <div className="flex-row" style={{padding:"5px",height:"35px"}}>
                    <div className="flex-col flex">
                       </div>
                    <div className="flex-col">
                        <div>UPPDATERA</div>
                    </div>
            </div>
            <div className="divider_orange"></div>

            <form className="cdi_f03 px-3">
                <div className="flex-row">
                    <Fr_Input label="Ämne" className="text-left flex" text={this.state.cdo.text} getValue={(e)=> this.val_save("text", e) } />
                </div>
                <div className="flex-row">
                    <Fr_Input label="Plats" className="text-left flex" text={this.state.cdo.plats} getValue={(e)=> this.val_save("plats", e) } />
                </div>
                <div className="flex-row">
                    <div className="form-group flex-row flex">
                        <span className="header">Ägare</span>
                        <select value={this.state.cdo.pid} className="form-control w250" onChange={(e) => this.preg_change(e)}>
                            { this.state.preg.map((row, key) =>
                                <option key={key} value={row.id}>{row.text}</option>
                            )}
                        </select>
                    </div>
                </div>
                <div className="divider_green"></div>
                <div className="flex-row">
                    <div className="flex-row">
                        <div className="dt-btn-01" onClick={() => this.subday() } ><FontAwesomeIcon icon="minus" size="1x" /></div>
                        <Dt_Cal dts={this.state.fdat} rp="1" ref={this.dt_fd} getValue={(e)=> this.dt_fdat(e)} />
                        <div className="dt-btn-01" onClick={() => this.oneday() } >1</div>
                        <Dt_Cal dts={this.state.tdat} rp="1" ref={this.dt_td} getValue={(e)=> this.dt_tdat(e)} />
                        <div className="dt-btn-01" onClick={() => this.addday() } ><FontAwesomeIcon icon="plus" size="1x" /></div>
                    </div>
                    <div className="flex"></div>
                    <div className="flex-row">
                        <Fr_Tid col noflex label="Start tid" tps="00:00" rp="1" ref={this.dt_ftid} tps={this.state.cdo.ftid} getValue={(e)=> this.val_save("ftid", e) } />
                        <Fr_Tid col noflex label="Slut tid" tps="00:00" rp="1" ref={this.dt_ttid} tps={this.state.cdo.ttid} getValue={(e)=> this.val_save("ttid", e) } />
                    </div>
                </div>

                <div className="txt_box txt_bg_01 hide">
                    <div className="flex-row">
                        <div className="flex-col">
                            <div className="txt_14">Registrerad: { utils.toTsDate(this.state.cdo.reg)}</div>
                            <div className="txt_14">Start: { utils.toTsDate(this.state.cdo.start)}</div>
                            <div className="txt_14">Slut: { utils.toTsDate(this.state.cdo.slut)}</div>
                        </div>
                        <div className="flex"></div>
                        <div className="flex-col">
                            <div className="flex-row">
                                <div className="btn_sel" style={ this.stat_style("0") } onClick={() => this.set_status("0") }>REGISTRERAD</div>
                                <div className="btn_sel" style={ this.stat_style("1") } onClick={() => this.set_status("1") }>BEARBETAS</div>
                            </div>
                            <div className="flex-row">
                                <div className="btn_sel" style={ this.stat_style("3") } onClick={() => this.set_status("3") }>VÄNTAR</div>
                                <div className="btn_sel" style={ this.stat_style("5") } onClick={() => this.set_status("5") }>KLAR</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="divider_green"></div>

                <div className="flex-row">
                    <div className="txt_18">KUND: { utils.toNum( this.state.cdo.kid )} - { utils.toLang( this.state.ktxt )}</div>
                    <div className="flex text-right">
                        <div className="web-link" onClick={() => this.sz_kid() }>KOPPLA KUND</div>
                    </div>
                </div>
                <div className="flex-row">
                    <div className="flex text-center">
                        <div className="txt_18">&nbsp;</div>
                    </div>
                    <div className="flex text-right">
                    </div>
                </div>

                <div className="divider_green"></div>
                <div className="flex-row">
                    <div className="flex flex-column" style={{padding: "0px 5px"}}>
                        <span className="header">Ärende information</span>
                        <textarea className="form-control" maxLength="512" rows="10" value={this.state.cdo.cdata} onChange={(e) => this.cdata_change(e)}></textarea>
                    </div>
                </div>
            </form>

        </div>
        <div className="mx_foot flex-row">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex"></div>
            <button className="btn btn-hw-orange w250" onClick={() => this.cal_del() } >
                TA BORT
            </button>
            <div className="flex"></div>
            <button className="btn btn-hw-green w250" onClick={() => this.cal_upd() } >
                SPARA
            </button>
        </div>
      </div>

        { this.state.wnds.szkid ? <Sz_Kid wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
        <Wnd_Error msg={this.state.msg} ctx={this} />
        <Wnd_Ok msg={this.state.msg} ctx={this} />
        <Wnd_Info msg={this.state.msg} ctx={this} />
        <Wnd_Confirm msg={this.state.msg} ctx={this} />
    </div>
	;
	return html;
  };
}

export default Cal_Edit;