import React, { useEffect } from 'react';

import * as net from "../../lib/net";
import SDOContext from "../../sdo";
import Dt_Cal from "../../lib/ui/dt_cal";
import Pay_Add from "./pays/pay_add";
import Pay_Edit from "./pays/pay_edit";
import ddo from "../../ddo";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Bo_Pays extends React.Component {
    static contextType = SDOContext;

  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.pos = props.store.pos;

    this.state = {};
    this.state.seldate = new Date();
    this.state.avd = ddo.cache.avd;
    this.state.paa = [];
    this.state.aa = [];
    this.state.payedit = false;
    this.state.bto = {};
    this.state.wnds = {};
    if(this.pos.deps) this.state.aa = this.pos.deps;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.pays();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

  dep_show() {
    var self = this;
    //alert(JSON.stringify(this.props.store));
  }

  dep_add() {
    var self = this;

    if(!this.pos.deps) {
        this.pos.deps = [];
        this.pos.deps.push( {"id": "01", "text": "DEP 01" } );
        this.pos.deps.push( {"id": "02", "text": "DEP 02" } );
        this.pos.deps.push( {"id": "03", "text": "DEP 03" } );
    }
    this.pos.deps.push( {"id": "04", "text": "DEP 04" } );
    self.setState({aa: this.pos.deps });
  }

    pay_new() {
        var self = this;
        var wnds = {};
        wnds.payadd = true;
        wnds.pbo = {};
        self.setState({wnds: wnds });
    }
  pay_edit(row) {
    var self = this;
    //alert(JSON.stringify(row.bet00));
    //var bto = JSON.parse(JSON.stringify(row.bet00));
    var wnds = {};
    wnds.payopen = true;
    wnds.bto = row;
    wnds.backdrop = true;
    self.setState({wnds: wnds });
  }

  pays() {
    var self = this;

    var prm = {};
    prm.req = "pos.pos_pay.qs_pay";
    prm.avd = this.state.avd;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        self.setState({paa: ret.rca });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  change_avd(e) {
    //let cdat = this.state.seldate;
    let avd = e.target.value;
    this.setState({avd: avd});
  }

  render() {
    return (
    <div className="web-app">

    <div className="flex-row">
        <select className="form-control w250" value={this.state.avd} placeholder="Välj avdelning" onChange={(e) => this.change_avd(e)}>
            { this.props.store.pos.deps.map((row, key) =>
            <option key={key} value={row.avd}>{row.avd} - {row.text}</option>
            )}
        </select>
        <div className="flex"></div>

        <button className="btn btn-hw-blue w150" onClick={() => this.pay_add() } aria-label="System" >
            LÄGG TILL
        </button>
        <button className="btn btn-hw-blue w150" onClick={() => this.pays() } aria-label="System" >
            Visa
        </button>
    </div>
    <div className="divider_orange"></div>

    <div className="web-row">
        <table className="table table-bordered table-hover tb fixed_header">
            <thead>
            <tr>
                <th>Bet</th>
                <th>Text</th>
                <th>Sortering</th>
                <th>Lådöppning</th>
                <th>Kontonr</th>
                <th>Underkonto</th>
                <th>Uppdat</th>
            </tr>
            </thead>
            <tbody style={ this.tb_style() }>
            { this.state.paa.map((row, key) =>
            <tr key={key} onDoubleClick={() => this.pay_edit(row) }>
                <td>{ row.bet00.b0kod }</td>
                <td>{ row.bet00.b0txt }</td>
                <td>{ row.bet00.b0red }</td>
                <td>{ row.bet00.b0ladopn }</td>
                <td>{ row.bet00.b0kontonr }</td>
                <td>{ row.bet00.b0ukonto }</td>
                <td>{ row.bet00.b0upddat }</td>
            </tr>
            )}
            </tbody>
        </table>
    </div>

        { this.state.wnds.payadd ? <Pay_Add wnds={ this.state.wnds } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.wnds.payopen ? <Pay_Edit wnds={ this.state.wnds } ctx={this} store={this.props.store} backdrop={true}/> : null }
    </div>
  );
    }
}
export default Bo_Pays;
