import React, { useState, useEffect, useContext } from 'react';
import * as fkn from "../lib/fkn";
import SDOContext from '../sdo.js'
import {Route, Routes} from "react-router-dom";
//import ADOContext from "../ado";

import Pms_Home from './pms/home.js';
import Pms_Period from './pms/period.js';
import Pms_Dr from './pms/dr.js';
import Pms_Trs from './pms/trs.js';
import Pms_Saldo from './pms/saldo.js';
import Pms_Krubba from './pms/krubba.js';
import Pms_CC from './pms/cc.js';
import Pms_Kassa from './pms/kassa.js';
import Pms_Bokat from './pms/bokat.js';
import Pms_Gst from './pms/gst.js';
import Pms_Deltagare from './pms/deltagare.js';
import {withRouter} from "../lib/react/withRouter";
import Eko_Home from "./eko/home";
import sso from "../sso";

function pg_home() {

    var html =
    <div className="web-content">
      Hem
    </div>
    ;
    return html;
}

class Red_Pms extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, deps: [] };
      var po = {};
      po.period = { "fid": "f06", "url": "/red/pms/period", "name": "Period", "active": false };
      po.dr = { "fid": "f06", "url": "/red/pms/dr", "name": "Dagrapport", "active": false };
      po.trs = { "fid": "f06", "url": "/red/pms/trs", "name": "Transaktioner", "active": false };
      po.saldo = { "fid": "f06", "url": "/red/pms/saldo", "name": "Saldo", "active": false };
      po.krubba = { "fid": "f06", "url": "/red/pms/krubba", "name": "Krubba", "active": false };
      po.cc = { "fid": "f06", "url": "/red/pms/cc", "name": "Kort", "active": false };
      po.kassa = { "fid": "f06", "url": "/red/pms/kassa", "name": "Kassa", "active": false };
      this.state.po = po;
      this.state.pa = [];
      this.state.pa = Object.values(po);
  }
    fknGo(row) {
        sso.seo.sfkn = row;
        this.props.navigate(row.url);
    }
    br_css(row) {
        var css = {};
        if(sso.seo.sfkn && (row.url == sso.seo.sfkn.url)) css.color = "#080";
        //var brc = false;
        //if(row.url == sbo.state.url) brc = true;
        return css;
    }

  render() {
    return (
        <div className="web-app">
            { this.props.store.cfg.bread ?
                <div className="web-bread">
                    <div className="breadcrumb">
                        { this.state.pa.map((row, key) =>
                            <div key={key} className="breadcrumb-item" style={this.br_css(row)} onClick={() => this.fknGo(row)}>{row.name}</div>
                        )}
                        <div className="flex"></div>
                        <div className="cdigreen">{ sso.seo.sfkn ? sso.seo.sfkn.name : null }</div>
                    </div>
                </div>
                : null }
        <Routes>
          <Route path="/xx" component={pg_home} />
            <Route path="period" element={ <Pms_Period store={this.webStore} />} />
            <Route path="dr" element={ <Pms_Dr store={this.webStore} />} />
            <Route path="trs" element={ <Pms_Trs store={this.webStore} />} />
            <Route path="saldo" element={ <Pms_Saldo store={this.webStore} />} />
            <Route path="krubba" element={ <Pms_Krubba store={this.webStore} />} />
            <Route path="cc" element={ <Pms_CC store={this.webStore} />} />
            <Route path="kassa" element={ <Pms_Kassa store={this.webStore} />} />
            <Route path="bokat" element={ <Pms_Bokat store={this.webStore} />} />
            <Route path="gst" element={ <Pms_Gst store={this.webStore} />} />
            <Route path="deltagare" element={ <Pms_Deltagare store={this.webStore} />} />
            <Route path="*" element={ <Pms_Home store={this.webStore} />} />
        </Routes>
    </div>

  );
}
}
//Sys.contextType = ADOContext;

export default withRouter(Red_Pms);
