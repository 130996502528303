import React, { useState, useEffect, useContext } from 'react';
import * as net from "../lib/net";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Wsc_Home extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, tra: [] };
    //context.posrend = this;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.props.fetchWells()
    //  .then(res => this.setState({ wells: res.wells }) )
    //this.trans();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 200;
      return css;
  }

  trans() {
      var self = this;
    var datum = "20200328";
    var prm = {};
    prm.req = "pos.pos_cas.trs_dt";
    prm.fdat = datum;
    prm.tdat = datum;

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        var o = {};
        //o.deps = ret.rco;
        //alert(JSON.stringify(o));
        self.setState({tra: ret.rca });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
    }

  render() {
    return (
    <div className="web-app">
        <section className="svp-s2 flex">
            <div className="cdi-body flex-col">
                <div className="flex-center flex">
                    <h3>Systemval</h3>
                    <h3></h3>
                </div>
                <div className="svp-body txt-18 py-5">
                </div>
            </div>
        </section>

    </div>
  );
    }
}
export default Wsc_Home;
