import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../../lib/net";
import * as utils from "../../../assets/utils";

import Order_Trs from "./order_trs";
import Order_Kvitto from "./order_kvitto";
import Order_Cart from "./order_cart";

class Order_Edit extends React.Component {
  constructor(props, context) {
    super(props);

    this.pos = props.store.pos;
    this.state = {};
    this.state.ynq = [];
    this.state.sval = [];

    this.state.dao = {};
    this.state.aa = [];
    this.state.cval = [
                {id: '001', text: 'ANTAL GER NYTT PRIS'},
                {id: '002', text: 'ANTAL GER RABATT PÅ PRIS'},
                {id: '003', text: 'PRISÄNDRING GENOM RABATT'}
            ];

      this.state.wnds = {};
      this.state.vpo = {};
    if(props.wnds) {
        this.state.vpo = props.wnds.vpo;
    }
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.happy();
  }
    cdi_init() {
        var self = this;
        var vpo = self.state.vpo;

        if(vpo.vip00) {
            //vpo.vip00.q0pris2 = vpo.vip00.q0pris2.formatpris();
            //vpo.vip00.q0npris2 = vpo.vip00.q0npris2.formatpris();
        }

        //self.setState({hpo: hpo,  md_init: true});
    }
    cdi_store() {
        var self = this;
/*
        var hap = self.state.hap;
        var hpo = self.state.hpo;

        if(!hap.hap00) {
            hap.hap00 = {};
        }
        hap.hap00 = {};

        hap.hap00.q0p = "Q";
        hap.hap00.q0kstns = this.state.pbo.plu00.p0kstns
        hap.hap00.q0katnr = this.state.pbo.plu00.p0katnr

        hap.hap00.q0pris2 = "";
        hap.hap00.q0npris2 = "";

        if(hpo.q0pris2) hap.hap00.q0pris2 = hpo.q0pris2.pris2cdi();
        if(hpo.q0npris2) hap.hap00.q0npris2 = hpo.q0npris2.pris2cdi();

        self.setState({hap: hap});
*/
    }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    tab_style(tabid) {
      var css = {};
      css.display = "none";
      if(this.state.tabval === tabid) css.display = "block";
      return css;
    }
    tab_head(tabid) {
      var css = {};
      css.background = "#fff";
      css.color = "#000";
      if(this.state.tabval === tabid) {
          css.background = "#080";
          css.color = "#fff";
      }
      return css;
    }
    grp_style(row) {
      var css = {};
      css.background = "#008";
      if(this.state.gnr === row.vgrupp) css.background = "#080";
      return css;
    }

    formchange (e) {
      const name = e.target.name;
      const value = e.target.value;
      var pbo = this.state.pbo;
      var na = name.split(".");
      pbo[na[0]][na[1]] = value;
      this.setState({pbo: pbo});
      //setState({[name]: value});
    }

    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    wnd_close = () => {
		this.props.ctx.setState({ wnds: {} });
	};
    handleChange = (event, newValue) => {
        //alert(newValue);
        this.setState({ix: newValue});
      };
    toggle() {
      this.setState({modal: false});
	};

  submitHandler = event => {
    event.preventDefault();
    event.target.className += " was-validated";
  };

  changeHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  cssCols (cid) {
      var css = {};
      if(this.state.cols[cid]) css.width = this.state.cols[cid].toString() + "px";
      //if(cid == "c01") alert(JSON.stringify(this.state.cols[cid]));
      return css;
  }
  vip_read() {
    var self = this;
    var prm = {};
    prm.req = "vip.vip_reg.vip_read";
    prm.avd = this.state.pbo.plu00.p0kstns;
    prm.plu = this.state.pbo.plu00.p0katnr;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        self.setState({hap: ret.rco }, function() { this.cdi_init(); });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
    }
    order_save() {
        var self = this;

    }
    onfleet() {
        var self = this;
        var prm = {};
        prm.req = "vcm.onfleet.task_add";
        prm.cdo = this.state.vpo;

        net.sio_req(prm)
            .then(function(ret){
                alert(JSON.stringify(ret));
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    kvitto_mail() {
        var self = this;
        var orderid = this.state.vpo.order00.orderid;
        var prm = {};
        prm.req = "vcm.ecom.order_confirm_mail";
        prm.orderid = orderid;

        net.sio_req(prm)
            .then(function(ret){
                alert(JSON.stringify(ret));
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    kvitto_sms() {
        var self = this;
        var orderid = this.state.vpo.order00.orderid;
        var prm = {};
        prm.req = "vcm.ecom.order_confirm_sms";
        prm.orderid = orderid;

        net.sio_req(prm)
            .then(function(ret){
                alert(JSON.stringify(ret));
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    order_edit() {
        var self = this;
        var wnds = {};
        wnds.kvittofkn = 1;
        wnds.vpo = this.state.vpo;
        self.setState({ wnds: wnds });
    }
    kvitto_fkn() {
        var self = this;
        var wnds = {};
        wnds.kvittofkn = 1;
        wnds.vpo = this.state.vpo;
        self.setState({ wnds: wnds });
    }
    shownota() {
        if(this.state.vpo.order00.paid != "1") {
            var self = this;
            var wnds = {};
            wnds.ordercart = 1;
            wnds.vpo = this.state.vpo;
            self.setState({ wnds: wnds });
            return;
        }
        var self = this;
        var wnds = {};
        wnds.ordertrs = 1;
        wnds.vpo = this.state.vpo;
        self.setState({ wnds: wnds });
    }
    showcart() {
        var self = this;
        var wnds = {};
        wnds.ordercart = 1;
        wnds.vpo = this.state.vpo;
        self.setState({ wnds: wnds });
    }
    val_get (name) {
      var value = "";
    //alert(JSON.stringify(name));
    //alert(JSON.stringify(value));
    var na = name.split(".");
    //var ff = na[1];
    var vpo = this.state.vpo;
    if(vpo) value = vpo[na[0]][na[1]];
    if(name == "vip02.v2kortnr") value = value.ztrim();
    if(name == "vip02.v2tillgodo") value = value.cdipris();
    if(name == "vip02.v2kredit") value = value.cdipris();
    return value;
   }
    val_set (name, value) {
        //alert(JSON.stringify(name));
        //alert(JSON.stringify(value));
        var na = name.split(".");
        var vpo = this.state.vpo;
        vpo[na[0]][na[1]] = value;
        this.setState({vpo: vpo});
    }

    vip_trs() {
        var self = this;
        var wnds = {};
        wnds.viptrs = 1;
        self.setState({ wnds: wnds, vpo: this.state.vpo });
    }

  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "Knappar";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        <div className="m2_backdrop" />
        <div className="m2_dlg mx_80 flex-col" style={ this.dlg_style() } >
        <div className="mx_head">
            <div>ORDER: { utils.toNum(this.state.vpo.order00.orderid) }</div>
        </div>
        <div className="mx_content flex">

            <div className="flex-row" style={{padding:"5px",height:"35px"}}>
                <div className="flex"></div>
                    <div className="flex-col">
                        <div>UPPDATERA</div>
                    </div>
            </div>
            <div className="divider_orange"></div>


            <div className="flex-row">
                <div className="txt_box py-5 px-5 flex">

                <div className="form-row flex-row">
                    <div className="txt_14 w100">OrderID:</div>
                    <div className="txt_20">{ utils.toNum(this.state.vpo.order00.orderid) }</div>
                </div>
                    <div className="form-row flex-row">
                        <div className="txt_14 w100">Datum:</div>
                        <div className="txt_20">{ utils.toDate(this.state.vpo.order00.datum) }</div>
                    </div>
                    <div className="form-row flex-row">
                        <div className="txt_14 w100">Slot:</div>
                        <div className="txt_20">{ utils.toTid(this.state.vpo.order00.slot) }</div>
                    </div>
                <div className="form-row flex-row">
                    <div className="txt_14 w100">Levtyp:</div>
                    <div className="txt_20">{ this.state.vpo.order00.levtyp }</div>
                </div>
                <div className="form-row flex-row">
                    <div className="txt_14 w100">Notanr:</div>
                    <div className="txt_20">{ utils.toNum(this.state.vpo.order00.notanr) }</div>
                </div>
                    <div className="form-row flex-row">
                        <div className="txt_14 w100">Belopp:</div>
                        <div className="txt_20">{ utils.toPris(this.state.vpo.order00.belopp) }</div>
                    </div>
                </div>

                <div className="txt_box flex">
                    <div className="py-5 px-5">
                        <div className="form-row flex-row">
                            <div className="txt_14 w100">Namn:</div>
                            <div className="txt_20">{ utils.toLang(this.state.vpo.order01.fnamn) } { utils.toLang(this.state.vpo.order01.enamn) }</div>
                        </div>
                        <div className="form-row flex-row">
                            <div className="txt_14 w100">Tele:</div>
                            <div className="txt_20">{ this.state.vpo.order01.tele }</div>
                        </div>
                        <div className="form-row flex-row">
                            <div className="txt_14 w100">Epost:</div>
                            <div className="txt_20">{ this.state.vpo.order01.epost }</div>
                        </div>
                        <div className="form-row flex-row">
                            <div className="txt_14 w100">Adress:</div>
                            <div className="txt_20">{ utils.toLang(this.state.vpo.order02.adress) }</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="divider_green"></div>
            <div className="flex-row" style={{ padding: "0px 5px", lineHeight:"25px",height:"25px"}}>
                <div className="web-link flex-col px-3" onClick={() => this.order_edit() }>
                    <div>ÄNDRA PÅ ORDER</div>
                </div>
                <div className="web-link flex-col px-3" onClick={() => this.onfleet() }>
                    <div>ONFLEET FUNKTION</div>
                </div>
                <div className="flex"></div>
                <div className="web-link flex-col px-3" onClick={() => this.shownota() }>
                    <div>VISA NOTA</div>
                </div>
                <div className="web-link flex-col px-3" onClick={() => this.kvitto_fkn() }>
                    <div>KVITTO</div>
                </div>
            </div>
            <div className="divider_green"></div>

            <div className="txt_box flex">
            <div className="px-5">
                <div className="form-row flex-row">
                    <div className="txt_14 w100">Meddelande:</div>
                </div>
                <div className="form-row flex-row">
                    <div className="txt_20">{ utils.toLang(this.state.vpo.order03.msg) }</div>
                </div>
            </div>
            </div>
        </div>

        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="form-row flex-row">
                <button className="btn btn-hw-green w250" onClick={() => this.showcart() } >
                    SE KORG
                </button>
                <button className="btn btn-hw-green w250" onClick={() => this.shownota() } >
                    SE NOTA
                </button>
            </div>
            <button className="btn btn-hw-green w250" onClick={() => this.order_save() } >
                SPARA
            </button>
        </div>
      </div>
            { this.state.wnds.ordertrs ?
                <Order_Trs wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
                : null }
            { this.state.wnds.kvittofkn ?
                <Order_Kvitto wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
                : null }
            { this.state.wnds.ordercart ?
                <Order_Cart wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
                : null }
      </div>
	;
	return html;
  };
}

export default Order_Edit;