import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Fakt_List extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, tra: [] };
    this.state.seldate = new Date();
    this.state.avd = "01";
    this.state.paa = [];
    this.state.aa = [
                {id: '01', text: 'AVD 01'},
                {id: '02', text: 'AVD 02'}
            ];
    //context.posrend = this;
  }
  componentDidUpdate() {
    let value = this.context;
    /* ... */
  }
  componentWillUnmount() {
    let value = this.context;
    /* ... */
  }
  componentDidMount() {
    //this.props.fetchWells()
    //  .then(res => this.setState({ wells: res.wells }) )
    //this.plus();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 480;
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

  plu_open(row) {
      var self = this;
  }
  plu_edit(row) {
      var self = this;
      alert(JSON.stringify(row.plu00));
  }

  plus() {
    var self = this;
    var prm = {};
    prm.req = "pos.pos_plu.plus";
    prm.avd = this.state.avd;
    //prm.fplu = datum;
    //prm.tplu = datum;

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        var o = {};
        //o.deps = ret.rco;
        //alert(JSON.stringify(o));
        self.setState({paa: ret.rco });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }

  render() {
    return (
    <div className="web-app">

    <div className="flex-row">
        <div className="label_h02">Listor</div>
    </div>
    <div className="divider_orange"></div>

    <div className="flex-row">
    <div className="flex-col flex">
        <div className="web-rp-box" onClick={() => this.rp_01() }>
            <div>Lista 1</div>
            <div>1234</div>
        </div>
        <div className="web-rp-box" onClick={() => this.rp_01() }>
            <div>Lista 2</div>
        </div>
    </div>
    <div className="flex-col flex">
        <div className="web-rp-box" onClick={() => this.rp_01() }>
            <div>Lista 3</div>
        </div>
        <div className="web-rp-box" onClick={() => this.rp_01() }>
            <div>Lista 4</div>
        </div>
    </div>
    </div>

    </div>
  );
    }
}
export default Fakt_List;
