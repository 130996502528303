import React, { useState, useEffect, useContext } from 'react';
import * as fkn from "../../lib/fkn";
import * as net from "../../lib/net";

import Dt_Cal from "../../lib/ui/dt_cal";
import Dd_Sel from "../../lib/ui/dd_sel";

import {format} from "date-fns";
import Lang from "../../lib/lang";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import * as utils from "../../assets/utils";

import Konto_Dts from "./konto/konto_dts";
import Konto_Per from "./konto/konto_per";
import Konto_Analys from "./konto/konto_analys";
import Konto_Analys_Dt from "./konto/konto_analys_dt";
import Kort_Analys from "./konto/kort_analys";

class Eko_Konto extends React.Component {
  constructor(props, context) {
    super(props);

    this.dt_fd = React.createRef();
    this.dt_td = React.createRef();

    this.store = props.store;
    this.pms = props.store.pms;
    this.state = {};
    this.state.fkn = "";

    this.state.superusr = "S";

    this.state.today = new Date();
    var dts = format(this.state.today, "yyyy-MM-dd");
    this.state.fdat = dts;
    this.state.tdat = dts;
    this.state.wnds = {};
    this.state.sortid = "";
    this.state.sortdir = 1;
    this.state.dta = [];
    this.state.tot = {};
    if(this.pms.base) {
        this.state.fdat = this.pms.base.fdat;
        this.state.tdat = this.pms.base.tdat;
    }
    this.selitems = [
      { fkn: 'kortanalys', text: 'Kort Analys' },
      { fkn: 'verify', text: 'Verifiera' }
  ];
}

  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.trans();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 260;
      css.overflow = "auto";
      return css;
  }
    th_style(id) {
        var css = {};
        css.cursor = "pointer";
        if(id == this.state.sortid) {
            if(this.state.sortdir) css.color = "#800";
            else css.color = "#080";
        }
        return css;
    }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }
  dt_fdat(e) {
    //alert(JSON.stringify(e));
    var dts = format(e, "yyyy-MM-dd");
    this.setState({ fdat: dts });
  }
  dt_tdat(e) {
      //alert(JSON.stringify(e));
    var dts = format(e, "yyyy-MM-dd");
    this.setState({ tdat: dts });
  }
  oneday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_td.current.updateDt(this.state.fdat);
  }
  addday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_fd.current.updateAdd();
    this.dt_td.current.updateAdd();
  }
  subday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_fd.current.updateSub();
    this.dt_td.current.updateSub();
  }

  change_fkn(e) {
    //let cdat = this.state.seldate;
    //alert(JSON.stringify(e));
    let fkn = e.target.value;
    this.setState({fkn: fkn});
    this.setState({tra: [], tsa: [], tta: [] });
  }

  dt_konto() {
    var self = this;
    //var datum = format(this.state.datum, "yyyy-MM-dd");
    var msg = { info: true, text: "Hämtar konton ..."};
    self.setState({gsa: [], tot: {}, msg: msg });

    var prm = {};
    prm.req = "pms.eko.trs_konto_dt";
    prm.fdat = this.state.fdat;
    prm.tdat = this.state.tdat;
    //alert(JSON.stringify(prm));

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            return;
        }
        var dta = ret.dta;
        var tot = ret.tot;
       
        self.setState({dta: dta, tot: tot, msg: {} }, function () {
            self.syncTb("kontoTable");
        });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
    }
    
    syncTb(tb) {
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
            tdElements = tbel.getElementsByTagName('td');
        if(tdElements.length < 1 ) return;

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
                width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
    tb_sort(typ) {
        var self = this;
        //var rvs = self.state.rvs;
        var tra = self.state.tra;
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;

        if(sortid != typ) sortdir = 0;
        else {
            if(sortdir) sortdir = 0;
            else sortdir = 1;
        }

        if(sortdir) tra.sort((a,b) => (a[typ] < b[typ]) ? 1 : ((b[typ] < a[typ]) ? -1 : 0));
        else tra.sort((a,b) => (a[typ] > b[typ]) ? 1 : ((b[typ] > a[typ]) ? -1 : 0));

        self.setState({ tra: tra, sortid: typ, sortdir: sortdir });
    }
    th_icon(typ) {
        var cls = "";
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;
        if(sortid == typ)
        {
            if(sortdir) cls = "fa fa-caret-up";
            else cls = "fa fa-caret-down";
        }
        return cls;
    }
    sie_pms(datum) {
      var self = this;
      var prm = {};
     //prm.req = "pms.rp.bok_sie";
     prm.req = "pms.rp.bok_tot_sie";
     prm.fdat = datum;
     prm.tdat = datum;
     prm.typ = "";
     prm.def = "";
      //alert(JSON.stringify(prm));

      net.sio_req(prm)
      .then(function(ret) {
          //alert(JSON.stringify(ret.rca));
          //var uri = "data:application/pdf;base64," + ret.pdf;
          //self.setState({pdf: 1, pdf_content: ret.pdf });
          //self.syncCols("lsTable");
       var rawbuf = ret.buf;
    var file = new Blob([(rawbuf)], {type: 'application/octet-stream'});
    var fileURL = window.URL.createObjectURL(file);
    //$scope.fil_object = $sce.trustAsResourceUrl(fileURL);
    var a = document.createElement('a');
    a.href = fileURL;
    //var filename = req.filename;
    var filename = "";
    //if(filename.length < 1) filename = "webfile.txt";
    if(filename.length < 1) filename = prm.fdat + "-" + prm.tdat + ".si";
    a.download = filename;
    document.body.appendChild(a);
    a.click();
     })
      .catch(function(e){
          var txt = JSON.stringify(e);
          //gda.wndError(txt);
      });
  }

  konto_dts(row) {
    var self = this;
    var dts = row.datum;
    //alert(JSON.stringify(dts));

    var wnds = {};
    wnds.ktodts = true;
    wnds.row = row;
    wnds.fdat = dts;
    wnds.tdat = dts;
    wnds.show = true;
    this.setState({ wnds: wnds });
  }
  konto_per() {
    var self = this;
    var wnds = {};
    wnds.ktoper = true;
    wnds.fdat = this.state.fdat;
    wnds.tdat = this.state.tdat;
    wnds.show = true;
    this.setState({ wnds: wnds });
  }
  konto_analys() {
    var self = this;
    var wnds = {};
    wnds.ktoanalys = true;
    wnds.fdat = this.state.fdat;
    wnds.tdat = this.state.tdat;
    wnds.show = true;
    this.setState({ wnds: wnds });
  }
  konto_analys_dt() {
    var self = this;
    var wnds = {};
    wnds.kadfd = true;
    wnds.fdat = this.state.fdat;
    wnds.tdat = this.state.tdat;
    wnds.show = true;
    this.setState({ wnds: wnds });
  }
  kort_analys() {
    var self = this;
    var wnds = {};
    wnds.kortanalys = true;
    wnds.fdat = this.state.fdat;
    wnds.tdat = this.state.tdat;
    wnds.show = true;
    this.setState({ wnds: wnds });
  }
  sel_fkn(sel) {
    //alert(JSON.stringify(fkn));
    if(sel.fkn == "verify") {
        this.krub_verify();
    }
    if(sel.fkn == "kortanalys") {
        this.kort_analys();
    }
}

  render() {
    return (
    <div className="web-app">
    <div className="">
        KONTO
    </div>

    <div className="flex-row">
    <div className="flex-row">
              <div className="dt-btn-01" onClick={() => this.subday() } ><FontAwesomeIcon icon="minus" size="1x" /></div>
              <Dt_Cal dts={ this.state.fdat } rp="1" ref={this.dt_fd} getValue={(e)=> this.dt_fdat(e)} />
              <div className="dt-btn-01" onClick={() => this.oneday() } >1</div>
              <Dt_Cal dts={ this.state.tdat } rp="1" ref={this.dt_td} getValue={(e)=> this.dt_tdat(e)} />
              <div className="dt-btn-01" onClick={() => this.addday() } ><FontAwesomeIcon icon="plus" size="1x" /></div>
        </div>
        <div className="flex"></div>
        <div className="flex"></div>
        <button className="btn btn-hw-blue w150" onClick={() => this.konto_analys_dt() } aria-label="System" >
            Dag för Dag
        </button>
        <button className="btn btn-hw-blue w150" onClick={() => this.konto_analys() } aria-label="System" >
            Konto Analys
        </button>
        <button className="btn btn-hw-blue w150" onClick={() => this.konto_per() } aria-label="System" >
            Konto Period
        </button>
        <button className="btn btn-hw-blue w150" onClick={() => this.dt_konto() } aria-label="System" >
            Visa
        </button>
        { this.state.superusr == "S" ? <Dd_Sel items={ this.selitems } cls="web-x-icon" getValue={(e)=> this.sel_fkn(e)} /> : null }
    </div>
    <div className="divider_green"></div>

    <div id="kontoTable">
          <table className="table table-bordered table-hover" style={{marginBottom: "0px"}}>
            <thead>
            <tr>
            <th style={ this.th_style("datum") } onClick={() => this.tb_sort("datum") }>Datum</th>
            <th style={ this.th_style("dag") } onClick={() => this.tb_sort("dag") }>Dag</th>
                <th style={ this.th_style("fsg") } onClick={() => this.tb_sort("fsg") }>Fsg</th>
                <th style={ this.th_style("trf") } onClick={() => this.tb_sort("trf") }>Trf</th>
                <th style={ this.th_style("oms") } onClick={() => this.tb_sort("oms") }>Oms</th>
                <th style={ this.th_style("bet") } onClick={() => this.tb_sort("bet") }>Bet</th>
                <th style={ this.th_style("krubba") } onClick={() => this.tb_sort("krubba") }>Krubba</th>
            </tr>
            </thead>
            </table>
            <div style={ this.tb_style() }>
          <table className="table table-bordered table-hover">
            <tbody>
            { this.state.dta.map((row, key) =>
            <tr key={key} onDoubleClick={() => this.konto_dts(row) }>
                <td>{ utils.toDate( row.datum ) }</td>
                <td>{ utils.toLang( row.dag ) }</td>
                <td>{ utils.toPris( row.tot.fsg ) }</td>
                <td>{ utils.toPris( row.tot.trf ) }</td>
                <td>{ utils.toPris( row.tot.oms ) }</td>
                <td>{ utils.toPris( row.tot.bet ) }</td>
                 <td>{ utils.toPris( row.krubba ) }</td>
            </tr>
            )}
            </tbody>
        </table>
        </div>
    </div>
     <div className="flex-row">
         <div className="flex">TOTALER:</div>
         <div className="px-5">FSG: { utils.toPris(this.state.tot.fsg) }</div>
         <div className="px-5">OMS: { utils.toPris(this.state.tot.oms) }</div>
         <div className="px-5">BET: { utils.toPris(this.state.tot.bet) }</div>
           <div className="px-5">SALDO: { utils.toPris(this.state.tot.saldo) }</div>
     </div>

     { this.state.wnds.ktodts ? <Konto_Dts wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
     { this.state.wnds.ktoper ? <Konto_Per wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
     { this.state.wnds.ktoanalys ? <Konto_Analys wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
     { this.state.wnds.kadfd ? <Konto_Analys_Dt wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
     { this.state.wnds.kortanalys ? <Kort_Analys wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
    </div>
  );
}
}
export default Eko_Konto;
