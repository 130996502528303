import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";
import Art_Add from "./fkn/art_add";
import Art_Edit from "./fkn/art_edit";
import * as utils from "../../assets/utils";
import Td_Sel from "../../lib/ui/td_sel";
import Wnd_Error from "../../lib/ui/wnd_error";
import Wnd_Ok from "../../lib/ui/wnd_ok";
import Wnd_Confirm from "../../lib/ui/wnd_confirm";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Bo_Art extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, tra: [] };
    this.state.seldate = new Date();
    this.state.avd = "01";
    this.state.ara = [];
    this.state.aa = [];
    this.state.fao = {};
    this.state.cols = {};
      this.state.msg = {};
      this.state.wnds = {};
      this.selitems = [
          {fkn: 'open', text: 'ÖPPNA'},
          {fkn: 'del', text: 'TA BORT'}
      ];
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.arts();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 225;
      css.overflow = "auto";
      return css;
  }
    th_style(id) {
        var css = {};
        css.cursor = "pointer";
        if(id == this.state.sortid) {
            if(this.state.sortdir) css.color = "#800";
            else css.color = "#080";
        }
        return css;
    }
    row_style(row) {
        var css = {};
        if(row.kredit == "1") {
            css.color = "#800";
        }
        return css;
    }
    fak_belopp(row, belopp) {
        var amount = "";
        amount = utils.toPris( belopp );
        if(row.wfak00.kredit == "1") {
            amount = "-" + amount;
        }
        return amount;
    }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

    art_add() {
        var self = this;
        //alert(JSON.stringify( row ));

        var wnds = {};
        wnds.artadd = 1;
        wnds.cb = this.cb_art;
        this.setState({ wnds: wnds });
    }
    art_open(row) {
      var self = this;
      //alert(JSON.stringify( row ));

      var wnds = {};
      wnds.artedit = 1;
      wnds.art = row;
      wnds.cb = this.cb_art;
      this.setState({ wnds: wnds });
  }

    cb_art(ctx) {
        ctx.arts();
        ctx.setState({ wnds: {} });
    }

    rsk_pos() {
        var self = this;
        var prm = {};
        prm.req = "fakt.fakt_rsk.rsk_fsg_pos";
        prm.fdat = "20221201";
        prm.tdat = "20221231";

        net.sio_req(prm)
        .then(function(ret){
            alert(JSON.stringify(ret));
            //toastr.success("OK", "SAPI");
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }

    arts() {
    var self = this;
    var prm = {};
    prm.req = "fakt.fakt_art.art_list";
    //prm.avd = this.state.avd;
    //prm.fplu = datum;
    //prm.tplu = datum;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        self.setState({ara: ret.rca }, function() {
            self.syncTb("artTable");
        });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
    syncTb(tb){
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
            tdElements = tbel.getElementsByTagName('td');
        if(tdElements.length < 1 ) return;

        for (let i = 0; i < thElements.length; i++) {
            if(!tdElements[i]) continue;
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
                width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
    fak_cred(row) {
        var self = this;

        var prm = {};
        prm.req = "fakt.fakt_fak.fak_ext_cred";
        prm.faktyp = "E";
        prm.faknr = row.wfak00.faknr;
        prm.wfak00 = row.wfak00;

        net.sio_req(prm)
            .then(function(ret) {
                //alert(JSON.stringify(ret.rco));

                var msg = { info: false, error: true, text: "Krediterad ..."};
                self.setState({msg: msg });
                setTimeout(function(){
                    self.setState({msg: {} });
                    self.rsk();
                   return;
                }, 1000);
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    sel_row_fkn(row, sel) {
        //alert(JSON.stringify(fkn));
        if(sel.fkn == "open") {
            this.art_open(row);
        }
        if(sel.fkn == "del") {
            //this.fak_cred(row);
        }
    }

  render() {
    return (
    <div className="web-app">

    <div className="flex-row">
        <div className="label_h03">Artiklar</div>
        <button className="btn btn-hw-orange w150" onClick={() => this.rsk_pos() } aria-label="System" >
            RSK TILL POS
        </button>
        <div className="flex"></div>
        <button className="btn btn-hw-blue w150" onClick={() => this.art_add() } aria-label="System" >
            Lägg till
        </button>
        <button className="btn btn-hw-blue w150" onClick={() => this.rsk() } aria-label="System" >
            Visa
        </button>
    </div>
    <div className="divider"></div>

    <div className="web-col">
        <div id="artTable">
        <table className="table table-bordered table-hover tb" style={{marginBottom: "0px"}}>
            <thead>
            <tr>
                <th style={ this.th_style("artnr" ) }>Artnr</th>
                <th style={ this.th_style("konto" ) }>Konto</th>
                <th style={ this.th_style("text" ) }>Text</th>
                <th style={ this.th_style("belopp" ) }>Belopp</th>
                <th style={ this.th_style("moms" ) }>Moms</th>
                 <th style={ this.th_style("kredit" ) }>Kredit</th>
                <th style={ this.th_style("status" ) }>Status</th>
                <th style={ this.th_style("" ) }></th>
            </tr>
            </thead>
        </table>
        <div style={ this.tb_style() }>
       <table className="table table-bordered table-hover tb">
            <tbody>
            { this.state.ara.map((row, key) =>
            <tr key={ key } style={ this.row_style(row) } onDoubleClick={() => this.art_open(row) }>
                <td>{ utils.toNum( row.artnr ) }</td>
                <td>{ utils.toNum( row.konto ) }</td>
                <td>{ utils.toLang( row.text ) }</td>
                <td className="txt_right">{ utils.toPris( row.pris ) }</td>
                <td className="txt_center">{ utils.toNum( row.moms ) }</td>
                <td>{ row.kredit }</td>
                <td>{ row.status }</td>
                <td>
                    <Td_Sel items={ this.selitems } getValue={(e)=> this.sel_row_fkn(row, e)} />
                </td>
            </tr>
            )}
            </tbody>
        </table>
        </div>
        </div>
    </div>
        { this.state.wnds.artadd ? <Art_Add wnds={this.state.wnds} ctx={this} backdrop={true}/> : null }
        { this.state.wnds.artedit ? <Art_Edit wnds={this.state.wnds} ctx={this} backdrop={true}/> : null }
        <Wnd_Error msg={this.state.msg} ctx={this} />
        <Wnd_Ok msg={this.state.msg} ctx={this} />
        <Wnd_Confirm msg={this.state.msg} ctx={this} />
    </div>
  );
}
}
export default Bo_Art;
