import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";

import Rp_Pdf from "./rps/rp_01.js";
import Rp_Rsk from "./rps/rsk_dlg.js";
import Rp_Fj from "./rps/fj_dlg.js";
import Rp_Pj from "./rps/pj_dlg.js";
import Rp_Pos from "./rps/pos_dlg.js";
import Rp_Fsg from "./rps/fsg_dlg.js";
import Rp_Fsg_Dt from "./rps/fsg_dt_dlg.js";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Fakt_Rps extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = {};

    this.state.avd = "01";
    this.state.paa = [];
    this.state.aa = [];
    this.state.pdf_show = false;
    this.state.pdf_uri = "";
      this.state.mna = [];
      this.state.mna.push({ rpid: "rsk", pos: "c1", text: "RESKONTRA", desc: "Reskontra för perioden", link: "" });
      this.state.mna.push({ rpid: "fj", pos: "c1", text: "FAKTURA JOURNAL", desc: "Fakturor för perioden", link: "" });
      this.state.mna.push({ rpid: "pj", pos: "c1", text: "BETAL JOURNAL", desc: "Betalningar för perioden", link: "" });
      this.state.mna.push({ rpid: "fsg", pos: "c2", text: "FAKTURA FSG", desc: "Faktura försäljning för perioden", link: "" });
      this.state.mna.push({ rpid: "fsgdt", pos: "c2", text: "FAKTURA FSG DATUM", desc: "Faktura försäljning per datum", link: "" });
      this.state.mna.push({ rpid: "pos", pos: "c2", text: "POS UNDERLAG", desc: "Kassa underlag för perioden", link: "" });
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 480;
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

    mnc(pos) {
        var mna = [];
        for(var row of this.state.mna) {
            if(row.pos == pos) mna.push( row );
        }
        return mna;
    }
    rp_open(typ) {
        var self = this;
        this.setState({ rptyp: typ });
    }

  render() {
    return (
    <div className="web-app">
    <div className="web-pg py-4 flex">
        <h3>Rapporter</h3>
        <p>Rapport funktioner</p>

        <div className="divider_green"></div>

        <div className="web-row">
            <div className="flex-col flex">
                { this.mnc("c1").map((row, key) =>
                    <div key={ key } className="web-bx" onClick={() => this.rp_open(row.rpid) }>
                        <div>{ row.text }</div>
                        <div className="flex-row">
                            <div className="flex">{ row.desc }</div>
                            <div className="web-link">Välj</div>
                        </div>
                    </div>
                )}
            </div>
            <div className="flex-col flex">
                { this.mnc("c2").map((row, key) =>
                    <div key={ key } className="web-bx" onClick={() => this.rp_open(row.rpid) }>
                        <div>{ row.text }</div>
                        <div className="flex-row">
                            <div className="flex">{ row.desc }</div>
                            <div className="web-link">Välj</div>
                        </div>
                    </div>
                )}
            </div>
        </div>

    </div>
        { this.state.rptyp == "pdf" ? <Rp_Pdf show={ 1 } ctx={this} pdf_uri={this.state.pdf_uri} backdrop={true}/> : null }
        { this.state.rptyp == "rsk" ? <Rp_Rsk show={ 1 } ctx={this} pdf_uri={this.state.pdf_uri} backdrop={true}/> : null }
        { this.state.rptyp == "fj" ? <Rp_Fj show={ 1 } ctx={this} pdf_uri={this.state.pdf_uri} backdrop={true}/> : null }
        { this.state.rptyp == "pj" ? <Rp_Pj show={ 1 } ctx={this} pdf_uri={this.state.pdf_uri} backdrop={true}/> : null }
        { this.state.rptyp == "pos" ? <Rp_Pos show={ 1 } ctx={this} pdf_uri={this.state.pdf_uri} backdrop={true}/> : null }
        { this.state.rptyp == "fsg" ? <Rp_Fsg show={ 1 } ctx={this} pdf_uri={this.state.pdf_uri} backdrop={true}/> : null }
        { this.state.rptyp == "fsgdt" ? <Rp_Fsg_Dt show={ 1 } ctx={this} pdf_uri={this.state.pdf_uri} backdrop={true}/> : null }

    </div>
  );
    }
}
export default Fakt_Rps;
