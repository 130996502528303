import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../../lib/net";
import Grp_Open from "./grp_open";
import Btn_New from "./btn_new";
import Btn_Add from "./btn_add";
import Btn_Open from "./btn_open";
import Fam_New from "./fam_new";
import Fam_Open from "./fam_open";
import ddo from "../../../ddo";

function TabPanel(props) {
  const { children, value, index, ctx, ...other } = props;
    function tab_change(val) {
        //alert(newValue);
        ctx.setState({ix: val});
      };
  function tab_style(val) {
      var css = {};
      css.height = "30px";
      css.lineHeight = "30px";
      css.background = "#fff";
      css.borderBottom = "1px #ccc solid";
      css.color = "#ccc";
      if(val == ctx.state.ix) {
          css.color = "#080";
          css.borderBottom = "2px #080 solid";
      }
      return css;
  }

  return (
    <div
      className="flex ripple text-center" onClick={() => tab_change(index)} style={ tab_style(index) }
      {...other}
    >
        {children}
    </div>
  );
}
class Btn_View extends React.Component {
  constructor(props, context) {
    super(props);

    this.pos = props.store.pos;
    if(!this.pos.plus) this.pos.plus = [];

    this.node = React.createRef();

    this.state = {};
    this.state.paa = this.pos.plus;
      this.state.poa = this.pos.plus;
      this.state.poo = {};
    this.state.ynq = [];
    //this.state.vga = [];
    this.state.mka = [];
    this.state.sval = [];
    this.state.tval = [];
    this.state.uaa = [];
    this.state.laa = [];

    this.state.avd = ddo.cache.avd;
    this.state.gnr = "001";
    this.state.sel_btn = {};
    this.state.gbo = {};
    //this.state.lro = props.lro;
    this.state.aa = [];
    this.state.vga = [].concat( { var00: { v0katnr: '000', v0text: 'ALLA VGRP' } }, props.store.pos.vgrps );
    //this.state.vga.push( props.store.pos.vgrps );
    //this.state.vga = props.store.pos.vgrps;
    //this.state.vga.unshift( { var00: { v0katnr: '', v0namn: 'ALLA VGRP' } } );
    //alert(JSON.stringify(this.state.vga));

    this.state.bgo = {};
    this.state.bgo[0] = [
                {vgrupp: '001', namn: 'VGRP 001'},
                {vgrupp: '002', namn: 'VGRP 002'},
                {vgrupp: '003', namn: 'VGRP 003'},
                {vgrupp: '004', namn: 'VGRP 004'},
                {vgrupp: '005', namn: 'VGRP 005'},
                {vgrupp: '006', namn: 'VGRP 006'}
            ];
    this.state.bgo[1] = [
                {vgrupp: '007', namn: 'VGRP 007'},
                {vgrupp: '008', namn: 'VGRP 008'},
                {vgrupp: '009', namn: 'VGRP 009'},
                {vgrupp: '010', namn: 'VGRP 010'},
                {vgrupp: '011', namn: 'VGRP 011'},
                {vgrupp: '012', namn: 'VGRP 012'}
            ];
    this.state.bto = {};

    this.state.bta = [];
    this.state.cols = {};
    this.state.lrwrk = false;

    this.state.ix = 0;
    this.prev_fg = "";
    this.prev_bg = "";
    this.state.vgrp = "";
    this.state.sz_txt = "";

    this.dragrow = {};
    this.dragbtn = {};
    this.state.dragtyp = "";
    this.state.sortid = "";
    //if(props.wnds.avd) this.state.avd = props.wnds.avd;

    this.state.wnds = {};
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.btn_grps();
      this.btn_btns();
      this.plus();
    //if(!this.pos.plus.length > 0) this.plus();
  }

    change_avd(e) {
        var self = this;
        this.pos.plus = [];
        let avd = e.target.value;
        this.setState({avd: avd}, function(){
            self.btn_btns();
            if(!self.pos.plus.length > 0) self.plus();
        });
    }
    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    tab_style(tabid) {
      var css = {};
      css.display = "none";
      if(this.state.tabval === tabid) css.display = "block";
      return css;
    }
    tab_head(tabid) {
      var css = {};
      css.background = "#fff";
      css.color = "#000";
      if(this.state.tabval === tabid) {
          css.background = "#080";
          css.color = "#fff";
      }
      return css;
    }
    grp_style(row) {
      var css = {};
      css.background = "#008";
      if(this.state.gnr === row.vgrupp) css.background = "#080";
      if(row.bgfarg) css.color = this.cdi_rgb(row.bgfarg);
      if(row.fgfarg) css.background = this.cdi_rgb(row.fgfarg);
      return css;
    }
  th_style(id) {
      var css = {};
      css.cursor = "pointer";
      if(id == this.state.sortid) css.color = "#080";
      return css;
  }

    wnd_close = () => {
		this.props.ctx.setState({ wnds: {} });
	};
    grp_list() {
      this.setState({grplist: true});
    }
    btn_list() {
      this.setState({btnlist: false});
    }

    dragcss() {
        var css = {};
        css["-khtml-user-drag"] = "element";
        css["-webkit-user-drag"] = "element";
        css["-webkit-user-select"] = "none";
        css["-khtml-user-select"] = "none";
        return css;
    }
    drag_start(event, row) {
        //event.preventDefault();
        var text = "XX";
        if(row) {
            text = row.plu00.p0namn.toswe();
        }

        var dragImage = document.createElement('div');
        dragImage.id = 'drag-image';
        dragImage.classList.add('btn_drag');
        dragImage.innerHTML = "<div>"+ text + "</div><div>R2</div>";
        this.dragImage = dragImage;

        //document.body.appendChild( dragImage );
        this.node.current.appendChild( dragImage );
        event.dataTransfer.setDragImage(dragImage, 62, 32);
        //var buf = JSON.stringify(row);
        //event.dataTransfer.setData("pbuf", buf);
        //event.dataTransfer.setData("typ", "plu");
        //this.setState({dragrow: row});
        var o = {};
        o.typ = "plu";
        o.plu = row;
        var txt = JSON.stringify(o);
        event.dataTransfer.setData("text", txt);
        //this.setState({dragrow: row, dragbtn: {}, dragtyp : "plu"});
    }
    drag_btn(event, btn) {
        //event.preventDefault();
        var knapp = "";
        var text = "";
        if(btn) {
            knapp = btn.f0knapp;
            text = btn.f0text.toswe();
        }

        var dragImage = document.createElement('div');
        dragImage.id = 'drag-image';
        dragImage.classList.add('btn_drag');
        dragImage.innerHTML = "<div>"+ text + "</div><div>"+ knapp + "</div>";
        this.dragImage = dragImage;
        //document.body.appendChild( dragImage );
        this.node.current.appendChild( dragImage );
        event.dataTransfer.setDragImage(dragImage, 62, 32);
        //event.dataTransfer.setData("typ", "btn");
        var o = {};
        o.typ = "btn";
        o.btn = btn;
        var txt = JSON.stringify(o);
        event.dataTransfer.setData("text", txt);
        //this.setState({dragbtn: btn, dragtyp : "btn"});
    }
    drag_end(event) {
        this.dragImage.remove();
        ///this.setState({dragrow: {}, dragbtn: {}, dragtyp : ""});
    };
    drag(event) {
        //event.preventDefault();
        //alert("Drag");
        //event.dataTransfer.setData("text", event.target.id);
/*        var crt = this.cloneNode(true);
        crt.style.backgroundColor = "red";
        crt.style.position = "absolute"; crt.style.top = "0px"; crt.style.right = "0px";
        //document.body.appendChild(crt);
        event.dataTransfer.setDragImage(crt, 0, 0);
*/
    };
    drop(btn, event) {
        event.preventDefault();
        //event.target.style.background = this.prev_bg;
        var txt = event.dataTransfer.getData("text");
        var o = JSON.parse(txt);
        //alert(JSON.stringify(o));

        if(btn.f0plu == "FAM") {
            if(o.typ == "btn") {
                alert("Knapp till fam");
                return;
            }
            if(o.typ == "plu") {
                //alert("Plu till fam");
                this.fam_plu_add(btn, o.plu);
                return;
            }
            return;
        }
        if(btn.f0plu != "") {
            alert("Upptagen");
            return;
        }
        if(o.typ == "btn") {
            if(o.btn.f0plu == "FAM") {
                alert("Flytta fam");
                return;
            }
            this.btn_move(o.btn, btn);
            return;
        }
        if(o.typ == "plu") {
            this.btn_plu_add(btn, o.plu);
            return;
        }

        //this.btn_add(btn, pbuf);
      //this.setState({v03: text, modal: true});
	};
    dragEnter(event) {
        event.preventDefault();
        //this.prev_fg = event.target.style.color;
        //this.prev_bg = event.target.style.background;
        //event.target.style.color = "#fff";
        //event.target.style.background = "#ccc";
        //event.target.style.opacity = "0.2";
        //alert("Allow Drop");
	};
    dragLeave(event) {
        event.preventDefault();
        ///event.target.style.opacity = "1.0";
        /*
        var key = event.target.id;
        var btn = this.bto[key];
        if(btn.f0kod) event.target.style.background = this.cdi_rgb(btn.f0kod);
        if(btn.f0bkod) event.target.style.color = this.cdi_rgb(btn.f0bkod);
        */
        //event.target.style.color = "#fff";
        //event.target.style.background = "#080";
        //event.target.style.color = this.prev_fg;
        //event.target.style.background = this.prev_bg;
        //alert("Allow Drop");
	};
    hoverEnter(event) {
        event.preventDefault();
        //this.prev_fg = event.target.style.color;
        //this.prev_bg = event.target.style.background;
        //event.target.style.color = "#fff";
        //event.target.style.background = "#ccc";
        //alert("Allow Drop");
	};
    hoverLeave(event) {
        event.preventDefault();
        //event.target.style.color = "#fff";
        //event.target.style.background = "#080";
        //event.target.style.color = this.prev_fg;
        //event.target.style.background = this.prev_bg;
        //event.target.style.background = "repeating-linear-gradient( -55deg,#222,#222 10px,#333 10px,#333 20px)";
        //alert("Allow Drop");
	};
  submitHandler = event => {
    event.preventDefault();
    event.target.className += " was-validated";
  };

  changeHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  };


  pad(n, width, z) {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
  }

  cdi_iscolor(rgb) {
    var brc = false;
    var pre = parseInt( rgb.substring(0,2) );
    if(pre == 11) brc = true;
    return brc;
  }
  cdi_rgb(rgb) {
    var r = parseInt( rgb.substring(2,5) ).toString(16);
    var g = parseInt( rgb.substring(5,8) ).toString(16);
    var b = parseInt( rgb.substring(8,11) ).toString(16);
    var hex = "#" + this.pad(r, 2) + this.pad(g, 2) + this.pad(b, 2);
    return hex;
  }

  btn_cls(btn) {
      var cls = "flex col";
      if(btn.f0plu && (btn.f0plu.trim() != "")) cls = cls + " btn_pos";
      else cls = cls + " btn_free";
      return cls;
  }
  btn_style(btn) {
    var css = {};
    if(btn.f0kod) css.background = this.cdi_rgb(btn.f0kod);
    if(btn.f0bkod) css.color = this.cdi_rgb(btn.f0bkod);
    return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 175;
      css.overflow = "auto";
      return css;
  }
    grp_open(row) {
        var self = this;

        if(row.grp == "") {
            //row.avd = self.state.avd;
            ///row.grp = self.state.gnr;
        }

        var wnds = {};
        wnds.grpopen = true;
        wnds.avd = this.state.avd;
        wnds.gro = row;
        wnds.backdrop = true;
        self.setState({wnds: wnds });
    };
    syncTb(tb){
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
        tdElements = tbel.getElementsByTagName('td');

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
            width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
  plus() {
    var self = this;
    var prm = {};
    prm.req = "pos.pos_plu.plus";
    prm.avd = this.state.avd;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));
        //alert(JSON.stringify(ret.rco));
        self.props.store.pos.plus = ret.rco;
        self.setState({paa: ret.rco, poa: ret.rco, poo: ret.poo });
        self.syncTb("plusTable");
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  btn_grps() {
    var self = this;
    var prm = {};
    prm.req = "pos.pos_btns.famgrps";
    prm.avd = this.state.avd;
    //prm.fplu = datum;
    //prm.tplu = datum;

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        var bga = ret.rco;
        //alert(JSON.stringify(bga.length));
        var o = {};
        o[0] = bga.slice(0, 6);
        o[1] = bga.slice(6, 12);
        self.setState({bgo: o });
        //alert(JSON.stringify(o[0].length));
        //alert(JSON.stringify(o[1].length));
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }
  set_grp(row) {
    var self = this;
    //self.setState({gnr: row.vgrupp });
    self.show_btns(row.vgrupp);
  }
  show_grps(gbo) {
    var self = this;
    //var gbo = self.state.gbo;

    var bka = Object.keys(gbo);
    bka.sort();
    //alert(JSON.stringify(bga.length));
    //alert(JSON.stringify(bga[0]));
    var bga = [];
    for(var bkey of bka) {
      var o = gbo[bkey];
      var gro = {};
      gro.vgrupp = o.varbuf.vgrupp;
      gro.namn = o.varbuf.namn;
      if(!gro.vgrupp) {
          gro.vgrupp = o.grp;
          gro.namn = "";
      }
        //bga.push( gro );
        bga.push( o.varbuf );
    };

    var o = {};
    o[0] = bga.slice(0, 6);
    o[1] = bga.slice(6, 12);
    self.setState({bgo: o });
  }
    compare( a, b ) {
        if ( a.f0knapp < b.f0knapp ){
            return -1;
        }
        if ( a.f0knapp > b.f0knapp ){
            return 1;
        }
        return 0;
    }

  show_btns(gnr) {
    var self = this;
    var gbo = self.state.gbo;
    //var gnr = self.state.gnr;

    if(!gbo[gnr]) return;

    var bka = Object.keys(gbo[gnr].btns);
    bka.sort();
    var bto = {};
    var bta = [];
    for(var bkey of bka) {
      var o = gbo[gnr].btns[bkey];
      bta.push( o );
      bto[bkey] = o;
    };
    //alert(JSON.stringify(bta.length));
    //alert(JSON.stringify(bta[0]));
    /*
    var o = {};
    o[0] = bta.slice(0, 6);
    o[1] = bta.slice(6, 12);
    o[2] = bta.slice(12, 18);
    o[3] = bta.slice(18, 24);
    o[4] = bta.slice(24, 30);
    */
    self.setState({ gnr: gnr, bta: bta, bto: bto });
  }
  btn_array() {
    var keys = Object.keys(this.state.bto).sort();
    //alert(JSON.stringify(keys));
    var bta = [];
    for(var key of keys) {
      var o = this.state.bto[key];
      bta.push( o );
    };
    return bta;
  }
  btn_btns() {
    var self = this;
    var prm = {};
    prm.req = "pos.pos_btns.btns_yy";
    prm.avd = this.state.avd;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        var gbo = ret.rco;
        self.setState({gbo: gbo });
        self.show_grps(gbo);
        self.show_btns(self.state.gnr);
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
    btn_move(frombtn, tobtn) {
      var self = this;
        var frompos = frombtn.f0knapp;

        var prm = {};
        prm.req = "pos.pos_btns.btn_remove";
        prm.fam00 = frombtn;
        //alert(JSON.stringify(prm.fam00));

        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret));
            frombtn.f0knapp = tobtn.f0knapp;
            var prm = {};
        prm.req = "pos.pos_btns.btn_add";
        prm.fam00 = frombtn;
        return net.sio_req(prm);
      })
        .then(function(ret){
            //alert(JSON.stringify(ret));
        var bto = self.state.bto;
        bto[frombtn.f0knapp] = frombtn;
        bto[frompos] = { f0knapp: frompos, f0plu: "" };
		self.setState({ bto: bto, wnds: {} });

      })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
  }
    btn_plu_add(btn, pbuf) {
      var self = this;
      //alert(JSON.stringify(btn));
      //alert(JSON.stringify(pbuf));
      var bto = {};
      bto.f0typ = "Y";
        //bto.f0avd = pbuf.plu00.p0kstns;
        bto.f0avd = self.state.avd;
      bto.f0plu = pbuf.plu00.p0katnr;
      bto.f0grupp = this.state.gnr;
      bto.f0fam = "";
      bto.f0knapp = btn.f0knapp;
      bto.f0text = pbuf.plu00.p0namn;
      bto.f0farg = "";
      bto.f0kod = "";
      bto.f0bkod = "";
      bto.f0betlkod = "";
      bto.f0ggrupp = "";
      bto.f0under = "";
      bto.f0sub = "";
      bto.f0sort = "";

      var wnds = {};
      wnds.btnadd = true;
      wnds.bto = bto;
      wnds.backdrop = true;
      self.setState({wnds: wnds });
    };
    fam_plu_add(btn, pbuf) {
      var self = this;
      //alert(JSON.stringify(btn));
      //alert(JSON.stringify(pbuf));
      var bto = {};
      bto.f0typ = "Y";
        //bto.f0avd = pbuf.plu00.p0kstns;
        bto.f0avd = self.state.avd;
      bto.f0plu = pbuf.plu00.p0katnr;
      bto.f0grupp = this.state.gnr;
      bto.f0fam = btn.f0knapp;
      bto.f0knapp = "00";
      bto.f0text = pbuf.plu00.p0namn;
      bto.f0farg = "";
      bto.f0kod = "";
      bto.f0bkod = "";
      bto.f0betlkod = "";
      bto.f0ggrupp = "";
      bto.f0under = "";
      bto.f0sub = "";
      bto.f0sort = "";

        var prm = {};
        prm.req = "pos.pos_btns.btn_add";
        prm.fam00 = bto;
        //alert(JSON.stringify(prm.fam00));

        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret));
            //self.props.ctx.btn_saved(self.props.ctx, self.state.bto);
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    };
    btn_open(btn) {
        var self = this;
        var bto = JSON.parse(JSON.stringify(btn));
        //alert(JSON.stringify(bto));

        if(bto.f0plu == "") {
            bto.f0avd = self.state.avd;
            bto.f0grupp = self.state.gnr;
            bto.f0fam = bto.f0knapp;

            var wnds = {};
            wnds.famnew = true;
            wnds.bto = bto;
            wnds.backdrop = true;
            self.setState({wnds: wnds });
            return;
        }
        if(bto.f0plu == "FAM") {
            var wnds = {};
            wnds.famopen = true;
            wnds.bto = bto;
            wnds.backdrop = true;
            self.setState({wnds: wnds });
            return;
        }
        var wnds = {};
        wnds.btnopen = true;
        wnds.bto = bto;
        wnds.backdrop = true;
        self.setState({wnds: wnds });
    };
    btn_saved(ctx, btn) {
        var self = this;

        //alert(JSON.stringify(bto));
        var bto = ctx.state.bto;
        bto[btn.f0knapp] = btn;
		ctx.setState({ bto: bto, wnds: {} });
		//this.btn_btns();
    };
    btn_removed(ctx, btn) {
        var self = this;

        //alert(JSON.stringify(bto));
        var bto = ctx.state.bto;
        bto[btn.f0knapp] = { f0knapp: btn.f0knapp };
		ctx.setState({ bto: bto, wnds: {} });
		this.btn_btns();
    };
    plu_open(btn) {
        var self = this;
    };

  toswe(s) {
      if(!s) s = "";
      s = s.toswe();
      return s;
    };
  grp_text(grp) {
      var s = "";
      if(!grp) return s;
      if(grp.namn) s = grp.vgrupp + " - " + grp.namn.toswe();
      return s;
    };
    sz_keydown (e) {
      //e.preventDefault();
        const key = e.key;
        const keyCode = e.keyCode;

        if(keyCode == 13) {
            this.tb_filtr();
        };
        if(keyCode == 27) {
            this.setState({sz_txt: ""});
        };
    }
    sz_plu (e) {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({sz_txt: value});
        //setState({[name]: value});
    }
  plu_sz() {
      var self = this;
      var poa = self.state.poa;
      var sz = this.state.sz_txt;

      var paa = poa.filter(row => row.plu00.p0namn.toUpperCase().includes(sz.toUpperCase()));
      self.setState({paa: paa });
   }
   tb_sort(typ) {
      var self = this;
      //var rvs = self.state.rvs;
      var paa = self.state.paa;
      var sz = this.state.sz_val;

      if(typ == "plu") {
        paa.sort((a,b) => (a.plu00.p0katnr > b.plu00.p0katnr) ? 1 : ((b.plu00.p0katnr > a.plu00.p0katnr) ? -1 : 0));
      }
      if(typ == "text") {
        paa.sort((a,b) => (a.plu00.p0namn > b.plu00.p0namn) ? 1 : ((b.plu00.p0namn > a.plu00.p0namn) ? -1 : 0));
      }
      if(typ == "pris") {
        paa.sort((a,b) => (a.plu00.p0pris > b.plu00.p0pris) ? 1 : ((b.plu00.p0pris > a.plu00.p0pris) ? -1 : 0));
      }
      self.setState({ paa: paa, sortid: typ });
      self.syncTb("plusTable");
   }
  tb_filtr() {
      var self = this;
      var poa = self.state.poa;
      var sz = this.state.sz_txt;

      if(sz.length < 1) {
        self.setState({ paa: poa });
          return;
      }
      sz = sz.toUpperCase();
      var paa = poa.filter(function(row) {
            var brc = false;
            if(row.plu00.p0namn.toUpperCase().includes(sz)) brc = true;
            if(row.plu00.p0katnr.toUpperCase().includes(sz)) brc = true;
            return brc;
      });
      self.setState({paa: paa });
      self.syncTb("plusTable");
   }
  change_vgrp(e) {
    var self = this;
    //let cdat = this.state.seldate;
    let vgrp = e.target.value;
    this.setState({vgrp: vgrp}, function(){
      self.vgrp_filtr();
    });
  }
    vgrp_filtr() {
        var self = this;
        var poa = self.state.poa;
        var vgrp = this.state.vgrp;

        if(vgrp == "000") {
            self.setState({paa: poa });
            return;
        }
        var paa = poa.filter(row => row.plu02.p2vgrupp == vgrp);
        self.setState({paa: paa });
    }
    get_knapptxt(bto) {
        var self = this;
        var poo = self.state.poo;

        //alert(JSON.stringify(bto));
        var knapptxt = "";
        if(bto.f0text) knapptxt = bto.f0text;
        var plu = bto.f0plu;
        if(poo[plu]) {
            //alert(JSON.stringify(poo[plu]));
            if(poo[plu].plu00) knapptxt = poo[plu].plu00.p0namn.trim();
            if(poo[plu].plu02 && (poo[plu].plu02.p2pluknapp.trim().length > 0)) knapptxt = poo[plu].plu02.p2pluknapp.trim();
        }
        if(!knapptxt) knapptxt = "";
        knapptxt = knapptxt.toswe();
        return knapptxt;
    }

  render() {
    //if(!this.props.show) return "";

    //alert(this.props.ctx.state.lro);

    var title = this.props.title;
    if(!title) title = "Knappar";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="mx_backdrop" /> : null }
        <div className="mx_dlg mx_100 flex-col" style={ this.dlg_style() }>
        <div className="mx_content flex">

            <div className="flex-row" style={{padding:"5px",height:"45px"}}>
                    <div className="flex-col txt_18">
                        <div>KNAPPAR:</div>
                    </div>
                <div className="flex"></div>
                <select className="form-control w250 hide" placeholder="Välj avdelning" onChange={(e) => this.change_avd(e)}>
                    { this.props.store.pos.deps.map((row, key) =>
                        <option key={key} value={row.avd}>{row.avd} - {row.text}</option>
                    )}
                </select>
                <div className="flex"></div>
                <div className="flex-col">
                    <div onClick={() => this.btn_btns() }>UPPDATERA</div>
                </div>
                <div className="flex-row w400 px-1">
                    <div className="flex"></div>
                    <select className="form-control w250" placeholder="Välj Varugrupp" onChange={(e) => this.change_vgrp(e)}>
                        { this.state.vga.map((row, key) =>
                            <option key={key} value={row.var00.v0katnr}>{row.var00.v0katnr} - {row.var00.v0text.toswe()}</option>
                        )}
                    </select>
                </div>
            </div>
            <div className="divider_orange"></div>

            <div className="flex-row">

            <div className="flex-col flex">
            <div className="">
                <div className="row btn_row">
            { this.state.bgo[0].map((row, key) =>
                <div key={ key } className="btn_grp flex" style={ this.grp_style(row) } onClick={() => this.set_grp(row) } onDoubleClick={() => this.grp_open(row) }>
                    <div className="btn-body">
                    { this.grp_text(row) }
                    </div>
                </div>
            )}
                </div>
                <div className="row btn_row">
            { this.state.bgo[1].map((row, key) =>
                <div key={ key } className="btn_grp flex" style={ this.grp_style(row) } onClick={() => this.set_grp(row) } onDoubleClick={() => this.grp_open(row) }>
                    <div className="btn-body">
                    { this.grp_text(row) }
                    </div>
                </div>
            )}
                </div>
            </div>
            <div className="divider_orange"></div>

            <div className="bv">
            <div className="bv-grid">
            { this.btn_array().map((btn, key) =>
                <div className={ this.btn_cls(btn) } key={key} draggable
                     onDragStart={ (event) => this.drag_btn(event, btn)}
                     onDragEnd={ (event) => this.drag_end(event)}
                     onDrag={ (event) => this.drag(event)}
                     onDragOver={ (event) => this.dragEnter(event) }
                     onDragLeave={ (event) => this.dragLeave(event) }
                     onDrop={ (event) => this.drop(btn, event)}
                     onMouseEnter={ (event) => this.hoverEnter(event) }
                     onMouseLeave={ (event) => this.hoverLeave(event) }
                     onDoubleClick={() => this.btn_open(btn) }
                     style={ this.btn_style(btn) }
                    >
                    <div className="btn-body">
                            { this.get_knapptxt(btn) }
                    </div>
                        <div className="flex-row flex-space-between btn-bottom" >
                            <div>{ btn.f0plu }</div>
                            <div>{ btn.f0knapp }</div>
                        </div>
                 </div>
            )}
            </div>
            </div>
            </div>

             <div className="web-col w400 px-1">
             <div id="plusTable" className="flex">
                <table className="table table-bordered table-hover tb"  style={{marginBottom: "0px"}}>
                    <thead>
                    <tr>
                        <th style={ this.th_style("plu") } onClick={() => this.tb_sort("plu") }>PLU</th>
                        <th style={ this.th_style("text") } onClick={() => this.tb_sort("text") }>Text</th>
                        <th style={ this.th_style("pris") } onClick={() => this.tb_sort("pris") }>Pris</th>
                    </tr>
                    </thead>
                </table>
                <div style={ this.tb_style() }>
                  <table className="table table-bordered table-hover tb">
                    <tbody>
                    { this.state.paa.map((row, key) =>
                    <tr key={ key }
                        onDoubleClick={() => this.plu_open(row) }
                        draggable={true}
                        onDragStart={ (event) => this.drag_start(event, row)}
                        onDragEnd={ (event) => this.drag_end(event)}
                        onDrag={ (event) => this.drag(event)}
                    >
                        <td>{ row.plu00.p0katnr }</td>
                        <td>{ row.plu00.p0namn.toswe() }</td>
                        <td className="text-right">{ row.plu00.p0pris.formatpris() }</td>
                    </tr>
                    )}
                    </tbody>
                </table>
            </div>
                 <div className="flex-col">
                     <div className="txt_8">SÖK:</div>
                     <input className="txt" value={this.state.sz_txt} onChange={(e) => this.sz_plu(e)} onKeyDown={(e) => this.sz_keydown(e)} />
                 </div>
            </div>

            </div>

           </div>
            <div className="" ref={this.node} style={{ position: "absolute", top: "-250px"}}></div>

        </div>

        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex-row hide">
            <button className="btn btn-hw-blue w250" onClick={(event) => this.grp_list(event) } >
                GRUPPLISTA
            </button>
            <button className="btn btn-hw-blue w250" onClick={(event) => this.btn_list(event) } >
                KNAPPLISTA
            </button>
            </div>
            <button className="btn btn-hw-green w250" onClick={(event) => this.wnd_close(event) } >
                STÄNG
            </button>
        </div>
      </div>

    { this.state.wnds.grpopen ? <Grp_Open wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
    { this.state.wnds.btnnew ? <Btn_New wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
    { this.state.wnds.btnadd ? <Btn_Add wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
    { this.state.wnds.btnopen ? <Btn_Open wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
    { this.state.wnds.famnew ? <Fam_New wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
    { this.state.wnds.famopen ? <Fam_Open wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
    </div>
	;
	return html;
  };
}

export default Btn_View;
