import React, { useState, useEffect, useContext } from 'react';
import {format} from "date-fns";

import * as utils from "../../assets/utils";
import * as net from "../../lib/net";

import Dt_Cal from "../../lib/ui/dt_cal";

import Pms_Rooms from "./rr/rooms";
import Rr_Status from "./rr/status";
import Rr_Help from "./rr/help";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class PMS_BelRr extends React.Component {
  constructor(props, context) {
    super(props);

    this.dt_dt = React.createRef();

    this.store = props.store;
    this.pms = props.store.pms;
    this.state = { pgid: 11, tra: [] };
    this.state.avd = "01";
    this.state.rva = [];
    this.state.kro = {};
    this.state.aa = [];

    this.state.wnds = {};

    this.state.rra = [];
      this.state.flo = {};
    this.state.loading = true;

      this.state.today = new Date();
      var hh = format(this.state.today, "HH");
      if(hh < 5) this.state.today.setDate(this.state.today.getDate() - 1);
      this.state.tday = format(this.state.today, "yyyy-MM-dd");
      this.state.dts = format(this.state.today, "yyyy-MM-dd");
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.rr();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

  help() {
    var self = this;
    var wnds = {};
    wnds.help = "1";
    self.setState({wnds: wnds });
  }
  rooms() {
    var self = this;
    var wnds = {};
    wnds.rstatus = "1";
    wnds.rra = this.state.rra;
    self.setState({wnds: wnds });
  }
  status(rro) {
    var self = this;
    var wnds = {};
    wnds.status = "1";
    wnds.rro = rro;
    wnds.fkn_close = this.wnds_close;
    self.setState({wnds: wnds });
  }

  rr() {
    var self = this;
    var prm = {};
    prm.req = "pms.rr.rr_dt";
    if(this.state.dts == this.state.tday) prm.req = "pms.rr.rrq";
    prm.datum = this.state.dts;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        var rra = [];
        var keys = Object.keys(ret.rco);
        for(var key of keys) {
            var rro = ret.rco[key];
            //alert(JSON.stringify(key));

            rra.push( rro );
        };
        self.setState({rra: rra, flo: ret.flo, loading: false });
    })
    .catch(function(e) {
        var txt = JSON.stringify(e);
    });
  }
  rr_status() {
    var self = this;
    var prm = {};
    prm.req = "pms.rr.rrq";
    prm.datum = this.state.dts;
    prm.status = this.state.status;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        var rra = [];
        var keys = Object.keys(ret.rco);
        for(var key of keys) {
            var rro = ret.rco[key];
            //alert(JSON.stringify(key));

            rra.push( rro );
        };
        self.setState({rra: rra, loading: false });
    })
    .catch(function(e) {
        var txt = JSON.stringify(e);
    });
  }
  rr_clean(row, status) {
    var self = this;
    var prm = {};
    prm.req = "pms.rr.rr_clean";
    prm.gsb01 = row.gsb01;
    prm.status = status;

    net.sio_req(prm)
    .then(function(ret){
        alert(JSON.stringify(ret));
    })
    .catch(function(e) {
        var txt = JSON.stringify(e);
    });
  }
  rr_close(row, status) {
    var self = this;
    var prm = {};
    prm.req = "pms.rr.rr_close";
    prm.gsb01 = row.gsb01;
    prm.status = status;

    net.sio_req(prm)
    .then(function(ret){
        alert(JSON.stringify(ret));
    })
    .catch(function(e) {
        var txt = JSON.stringify(e);
    });
  }

  cls_rr(row) {
      var cls = "";
      cls = cls + "flex col";
      if(row.nrg > '0') cls = cls + " rr_inh";
      else if(row.status == 'C') cls = cls + " rr_close";
      else if(row.closed == '1') cls = cls + " rr_close";
      else cls = cls + " rr_free";
      return cls;
  }
  cls_clean(row) {
      var cls = "";
      cls = cls + "";
      if(row.cleaned == '0') cls = cls + " rr_clean";
      return cls;
  }
  cls_ank(row) {
      var cls = "";
      cls = cls + "";
      if(row.ankg > '0') cls = cls + " rr_ank";
      return cls;
  }
  cls_avr(row) {
      var cls = "";
      cls = cls + "";
      if(row.avrg > '0') cls = cls + " rr_avr";
      return cls;
  }
  dt_dat(e) {
    //alert(JSON.stringify(e));
    var dts = format(e, "yyyy-MM-dd");
    this.setState({ dts: dts });
  }
  addday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_dt.current.updateAdd();
  }
  subday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_dt.current.updateSub();
  }
  wnds_close(ctx) {
    ctx.setState({ wnds: {} });
    ctx.rr();
  }

  render() {
    return (
    <div className="web-app">

    <div className="flex-row flex-space-between">
        <button className="btn btn-hw-blue w150" onClick={() => this.help() } aria-label="System" >
            HJÄLP
        </button>
        <div className="flex-row">
              <div className="dt-btn-01" onClick={() => this.subday() } ><FontAwesomeIcon icon="minus" size="1x" /></div>
              <Dt_Cal dts={this.state.dts} rp="1" ref={this.dt_dt} getValue={(e)=> this.dt_dat(e)} />
              <div className="dt-btn-01" onClick={() => this.addday() } ><FontAwesomeIcon icon="plus" size="1x" /></div>
        </div>
        <div className="flex"></div>
        <button className="btn btn-hw-blue w150" onClick={() => this.rooms() } aria-label="System" >
            RUMS HANTERING
        </button>
        <button className="btn btn-hw-blue w150" onClick={() => this.rr() } aria-label="System" >
            Visa
        </button>
    </div>
    <div className="divider_orange"></div>

        <div className="web-col">
            <div className="container">
                <div className="col">
                    { Object.values(this.state.flo).map((flo, key) =>
                        <div key={key} className="col">
                            <div className="divider_green"></div>
                            <div className="txt_18">Våning: { flo.floor }</div>
                            <div className="row">
                                { Object.values(flo.rro).map((row, key) =>
                                    <div key={key} className="rr_grp flex-row" onClick={() => this.status(row) }>
                                        <div className="rr_pre flex-row">
                                            <div className={this.cls_avr(row)}></div>
                                        </div>
                                        <div className={this.cls_rr(row)}>
                                            <div className="rr_txt">{ utils.toNum( row.rumsnr ) }</div>
                                            <div className="rr_txt">{row.rt}</div>
                                        </div>
                                        <div className="rr_post flex-row">
                                            <div className={this.cls_ank(row)}></div>
                                            <div className={this.cls_clean(row)}></div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
            { this.state.wnds.help ?
        <Rr_Help wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
            : null }
    { this.state.wnds.status ?
        <Rr_Status wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
    : null }
    { this.state.wnds.rstatus ?
        <Pms_Rooms wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
    : null }
   </div>
  );
}
}
export default PMS_BelRr;

/*
    <div className="web-col">
        <div className="container">
            <div className="row rr_row">
        { this.state.rra.map((row, key) =>
            <div key={key} className="rr_grp flex-row" onClick={() => this.status(row) }>
                <div className={this.cls_avr(row)}></div>
                <div className={this.cls_rr(row)}>
                    <div className="rr_txt">{row.rumsnr}</div>
                    <div className="rr_txt">{row.rt}</div>
                </div>
                <div className={this.cls_ank(row)}></div>
                <div className={this.cls_clean(row)}></div>
            </div>
        )}
            </div>
        </div>
    </div>

 */