import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../../lib/net";
import * as utils from "../../../assets/utils";

import Pms_Rvo from "../../rv/fb/rvo";
import Pms_RfGst from "../fkn/rf_gst";
import Pms_Rr from "../../bel/rr/rr";
import Gst_Edit from "./ank_gst_edit";
import Pms_Nota from "../nota/nota";

class Pms_AnkGst extends React.Component {
  constructor(props, context) {
    super(props);

    //alert('PBO CTX: ' + JSON.stringify(props.pbo));
   //this.webStore = props.store;
    this.state = {};
    this.state.ynq = [];
    //this.state.vga = [];
    this.state.wnds = {};

    this.state.paa = [];
    this.state.aa = [];

    this.state.boknr = "";
    this.state.rvo = {};
    this.state.bro = {};
    this.state.gso = {};
    if(props.wnds) {
        this.state.boknr = props.wnds.boknr;
        this.state.rvo = props.wnds.rvo;
        this.state.gso = props.wnds.rvo.gsb00;
        this.state.bro = this.state.rvo.rblx0;
    }
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      this.rvg_belopp();
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }

    wnd_close() {
		this.props.ctx.setState({ wnds: {} });
	};
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  fknSel(sel) {
    //alert(JSON.stringify(fkn));
      if(sel == "rvo") {
        var wnds = {};
        wnds.wnd_rvo = true;
        this.setState({wnds: wnds});
      }
      if(sel == "gso") {
        var wnds = {};
        wnds.wnd_gso = true;
        wnds.bro = this.state.bro;
        wnds.gso = this.state.gso;
        this.setState({wnds: wnds});
      }
      if(sel == "rr") {
        var wnds = {};
        wnds.rr = true;
        this.setState({wnds: wnds});
      }
      if(sel == "nota") {
          var wnds = {};
          wnds.wnd_nota = true;
          wnds.boknr = this.state.boknr;
          wnds.rvo = this.state.rvo;
        this.setState({wnds: wnds});
      }
  }
  rf_free() {
    var self = this;

    //if(gso.rumsnr.length == 4) gso.rumsnr = gso.rumsnr + "00";

    var prm = {};
    prm.req = "pms.br.brg_rr_free";
    prm.gsb00 = this.state.rvo.gsb00;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok == "000") {
          var rvo = self.state.rvo;
          rvo.gsb00.r0rumsnr = "";
          self.setState({ rvo: rvo });
            return;
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  rf_gst() {
      var wnds = {};
      wnds.rfgst = true;
      wnds.bro = this.state.rvo.rblx0;
      wnds.gso = this.state.rvo.gsb00;
      wnds.cb = this.cb_rf;
    this.setState({wnds: wnds});
  }
  cb_rf(ctx, ret) {
      if(ret.ok == "000") {
          var rumsnr = ret.rumsnr + "00";
          var gso = ctx.state.gso;
          gso.rumsnr = rumsnr;
        //ctx.setState({gso: gso, wnds: {} });
        var prm = {};
        prm.req = "pms.br.brg_rr_set";
        //prm.req = "pms.gso.gst_rr_upd";
        prm.boknr = ctx.state.rvo.boknr;
        prm.gsb00 = ctx.state.rvo.gsb00;
        prm.rumsnr = ctx.state.gso.rumsnr;

        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret.rco));

            var rvo = ctx.state.rvo;
            rvo.gsb00.r0rumsnr = rumsnr;
            ctx.setState({ rvo: rvo, wnds: {} });
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
        return;
      }
    ctx.setState({ wnds: {} });
  }

    rvo_close(ctx) {
        ctx.setState({ wnds: {} });
    };
  gst_setroom() {
    var self = this;

    var prm = {};
    //prm.req = "pms.br.brg_rr_upd";
    prm.req = "pms.gso.gst_rr_upd";
    prm.boknr = this.state.rvo.boknr;
    prm.gsb00 = this.state.rvo.gsb00;
    prm.rumsnr = this.state.gso.rumsnr;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rco));

        var rvo = self.state.rvo;
        rvo.gsb00 = ret.rco;
        self.setState({rvo: rvo });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  rvg_belopp() {
    var self = this;

    var prm = {};
    //prm.req = "pms.br.brg_rr_upd";
    prm.req = "pms.charge.rvg_belopp";
    prm.boknr = this.state.rvo.boknr;
    prm.glopnr = this.state.rvo.gsb00.r0glopnr;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rco));

        var rvo = self.state.rvo;
        rvo.charges.bokat = ret.rco.bokat;
        self.setState({rvo: rvo });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "";

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="mx_backdrop" /> : null }
        <div className="mx_dlg mx_80 flex-col" style={ this.dlg_style() }>
        <div className="mx_h01 flex-row" style={{background: "#1a456b", color: "#fff"}}>
            <div>GÄST: { this.state.boknr } / { this.state.rvo.glopnr }</div>
            <div className="flex"></div>
            <div>UPPDATERA</div>
        </div>
        <div className="mx_content flex">

            <div className="flex-row flex">
            <div className="flex-col flex">

            <div className="txt_box txt_bg_01">
            <div className="flex-row">
                    <div className="flex-col flex">
                        <div className="txt_lbl">FÖRETAG:</div>
                        <div>{ this.state.rvo.resv00.Company.toswe() }</div>
                    </div>
                    <div className="flex-col flex">
                        <div className="txt_lbl">TELE:</div>
                        <div>{ this.state.rvo.tele }</div>
                    </div>
            </div>
            <div className="flex-row">
                    <div className="flex-col flex">
                        <div className="txt_lbl">GÄST:</div>
                        <div>{ this.state.rvo.gsb00.r0namn.toswe() }</div>
                    </div>
                    <div className="flex-col flex">
                        <div className="txt_lbl">EPOST:</div>
                        <div>{ this.state.rvo.gsb00.r0email }</div>
                    </div>
            </div>
            <div className="flex-row">
                    <div className="flex-col flex">
                        <div className="txt_lbl">ANM:</div>
                        <div>{ this.state.rvo.anm.toswe() }</div>
                    </div>
            </div>
           </div>

            <div className="txt_box flex-col">
            <div className="flex-row">
                <div className="wnd_link">
                    DELBOKNING
                </div>
                <div className="flex"></div>
                <div className="wnd_link" onClick={() => this.gst_upd() }>
                    UPPDATERA
                </div>
            </div>
            <div className="flex-row">
                    <div className="flex-column flex py-2">
                        <div className="txt_lbl">PERIOD:</div>
                        <div className="txt_data">{ this.state.rvo.ank.cdidate() } - { this.state.rvo.avr.cdidate() }</div>
                    </div>
                    <div className="flex-column flex py-2">
                        <div className="txt_lbl">RUMSTYP:</div>
                        <div className="txt_data">{ this.state.rvo.rt.toswe() }</div>
                    </div>
            </div>
            </div>

            <div className="txt_box flex flex-col">
            <div className="flex-row">
                <div className="wnd_link">
                    GÄST
                </div>
                <div className="flex"></div>
                <div className="wnd_link" onClick={() => this.gst_upd() }>
                    UPPDATERA
                </div>
            </div>
            <div className="flex-row">
                    <div className="flex-column flex py-2">
                        <div className="txt_lbl">Namn:</div>
                        <div className="txt_data">{ this.state.gso.r0namn.toswe() }</div>
                    </div>
                    <div className="flex-column flex py-2">
                        <div className="txt_lbl">Gäster:</div>
                        <div className="txt_data">{ this.state.gso.r0guests }</div>
                    </div>
            </div>
            <div className="flex-row">
                    <div className="flex-column flex py-2">
                        <div className="txt_lbl">Info:</div>
                        <div className="txt_data">{ this.state.gso.r0namn.toswe() }</div>
                    </div>
            </div>
            </div>

           </div>

             <div className="txt_box txt_bg_01 txt_22  flex-col">
                 <div className="flex-col">
                    <div className="flex-row flex">
                        <div className="txt_lbl flex">BOKAT VÄRDE:</div>
                        <div className="txt_price">{ utils.toPris( this.state.rvo.charges.bokat ) }</div>
                    </div>
                    <div className="flex-row flex">
                        <div className="txt_lbl flex">SALDO:</div>
                        <div className="txt_price">{ utils.toPris( this.state.rvo.charges.saldo ) }</div>
                    </div>
                    <div className="flex-row flex">
                        <div className="txt_lbl flex">BETALT:</div>
                        <div className="txt_price">{ utils.toPris( this.state.rvo.charges.paid ) }</div>
                    </div>
                    <div className="flex-row flex">
                        <div className="txt_lbl flex">ATT BETALA:</div>
                        <div className="txt_price">{ utils.toPris( this.state.rvo.charges.topay ) }</div>
                    </div>
                    <div className="divider_blue"></div>
                    <div className="flex-row flex py-2">
                        <div className="txt_lbl flex">RUMSNR:</div>
                        <div className="txt_price">{ utils.toRumsnr( this.state.rvo.gsb00.r0rumsnr ) }</div>
                    </div>
                </div>
                <div className="flex"></div>
                <div className="wnd-btn txt_18" onClick={() => this.rf_free() } >
                    <div>RENSA RUM</div>
                    <div></div>
                </div>
                <div className="wnd-btn txt_18" onClick={() => this.rf_gst() } >
                    <div>RUMSVAL</div>
                    <div></div>
                </div>
            </div>

            </div>

            <div className="divider_orange"></div>

        <div className="web-row">
        <div className="web-col flex">
            <div className="wnd-btn" onClick={() => this.fknSel("rvo") } >
                <div>ÖPPNA BOKNING</div>
                <div></div>
            </div>
            <div className="wnd-btn" onClick={() => this.wnd_close() } >
                <div>STÄNG</div>
                <div></div>
            </div>
        </div>
         <div className="web-col flex" style={{padding: "0px 10px"}}>
            <div className="wnd-btn" onClick={() => this.fknSel("gso") } >
                <div>GÄST HANTERING</div>
                <div></div>
            </div>
            <div className="wnd-btn" onClick={() => this.fknSel("nota") } >
                <div>GÄST NOTA</div>
                <div></div>
            </div>
       </div>
         <div className="web-col flex" style={{padding: "0px 10px"}}>
            <div className="wnd-btn" onClick={() => this.fknSel("rr") } >
                <div>ROOMRACK</div>
                <div></div>
            </div>
            <div className="wnd-btn" onClick={() => this.fknSel("confirm") } >
                <div>BEKRÄFTA BOKNING</div>
                <div></div>
            </div>
       </div>
       </div>

        </div>
      </div>
            { this.state.wnds.wnd_rvo ?
        <Pms_Rvo close={this.rvo_close} boknr={this.state.boknr} ctx={this} store={this.props.store} backdrop={true}/>
            : null }
            { this.state.wnds.wnd_gso ?
        <Gst_Edit close={this.rvo_close} wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
            : null }
            { this.state.wnds.rfgst ?
        <Pms_RfGst wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
            : null }
            { this.state.wnds.rr ?
        <Pms_Rr wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
            : null }
            { this.state.wnds.wnd_nota ?
        <Pms_Nota wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
            : null }
    </div>
	;
	return html;
  };
}

export default Pms_AnkGst;