import React, { useState, useEffect, useContext } from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import { observer } from 'mobx-react'

import Bcs_Top from './com/bcs_top.js';
import Bcs_Mnu from './com/bcs_mnu.js';
import Bcs_Foot from './com/bcs_foot.js';

import Bcs_Home from './home.js';
import Bcs_Dash from './dash.js';

import Bcs_Wrk from './wrk.js';
import Bcs_Sup from './sup.js';
import Bcs_Pro from './pro.js';
import Bcs_Prj from './prj.js';
import Bcs_Reg from './reg.js';
import Bcs_Run from './run.js';
import Bcs_Bo from './bo.js';

import * as net from "../lib/net";
import Tiden from "../lib/ui/tiden";
import {withRouter} from "../lib/react/withRouter";
import Mnu_Home from "../dsi/mnu/home";

function web01() {

    var html =
    <div className="web-content">
    </div>
    ;
    return html;
}

class Bcs extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pos = props.store.pos;
    this.state = { pgid: 11, mnu_show: true, deps: [] };
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {

    var bcs = {};
    bcs.kro = {};
    bcs.vgrps = [];
    this.props.store.bcs = bcs;
    //alert(JSON.stringify(pos));
    this.bcs_init();
  }

  fknGo(url) {
    //var sdo = useContext(SDO);
    //alert(fkn);
    //var fkn = row.fid;
    //setState({ row : row,  ma : [] });
    //ctx.fid = fkn;

    //props.history.push(row.url);
    this.props.navigate(url);
   };

  ddo() {
    //alert("POS" + this.state.pgid );
    //this.setState({ status: 1 });
  }

    bcs_init() {
    var self = this;

	var req = {};
    req.req = "bcs.kreg.kks";
    req.typ = "K";

    //gda.wndLoading("Hämtar");
    net.sio_req(req)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        //var pos = {};
        //pos.deps = ret.rco.deps;
        //self.props.store.pos = pos;
        if(!self.props.store.bcs) self.props.store.bcs = {};
        self.props.store.bcs.kro = ret.rco;
        //self.props.store.bcs.vgrps = ret.rco.vgrps;

        //self.setState({ deps: ret.rco });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //alert(JSON.stringify(txt));
    })
    }

  render() {

    return (
    <div className="web-app">
    <div className="web-top">
        <Bcs_Top store={this.store} />
    </div>
    <div className="web-main">

    <div className="web-row" style={{ height:"100%" }}>
    <div className="web-side">
        <Bcs_Mnu store={this.store} />

    </div>

    <div className="web-body">
          <Routes>
              <Route path="wrk/*" element={ <Bcs_Wrk store={this.store} />} />
              <Route path="sup/*" element={ <Bcs_Sup store={this.store} />} />
              <Route path="pro/*" element={ <Bcs_Pro store={this.store} />} />
              <Route path="prj/*" element={ <Bcs_Prj store={this.store} />} />
              <Route path="reg/*" element={ <Bcs_Reg store={this.store} />} />
              <Route path="run/*" element={ <Bcs_Run store={this.store} />} />
              <Route path="bo/*" element={ <Bcs_Bo store={this.store} />} />
              <Route path="home" element={ <Bcs_Home store={this.store} />} />
              <Route path="*" element={ <Bcs_Dash store={this.store} />} />
          </Routes>
    </div>

    </div>

    </div>
        <Bcs_Foot store={this.store} />

    </div>
  );
}
}
//Sys.contextType = ADOContext;

export default withRouter(observer(Bcs));
