import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../../lib/net";
import Wnd_Ok from "../../../lib/ui/wnd_ok";

class Bq_Bong extends React.Component {
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.state = {};
    this.state.bqa = [];
    this.state.tra = [];
    this.state.msg = {};
    this.state.bqr = {};
    this.state.bqr.srows = {};
    if(props.wnds && props.wnds.row) this.state.bqr = props.wnds.row;
   }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      //this.trs();
      //alert(JSON.stringify(this.props.wnds.row));
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    tab_style(tabid) {
      var css = {};
      css.display = "none";
      if(this.state.tabval === tabid) css.display = "block";
      return css;
    }
    bq_close() {
		this.props.ctx.setState({ wnds: {} });
	};
  bong_style(row) {
      var css = {};

      if(!row) return css;

      if(row.vtid > 1150) {
          css.background = "#ff9900";
          css.color = "#fff";
      }
      if(row.vtid > 1200) {
          css.background = "#800";
          css.color = "#fff";
      }
      return css;
  }
  stol_text(row) {
      var text = "";
      if((row.stol == "00") || (row.stol == "  ")) {
          text = "";
      }
      else {
          text = "STOL: " + row.stol;
      }
      return text;
  }
  bongrow_style(row) {
      var css = {};
      if(row.r0guestno != "00") {
          css.color = "#ff9900";
          css.textDecoration = "line-through";
      }
      return css;
  }
  cssCols (cid) {
      var css = {};
      if(this.state.cols[cid]) css.width = this.state.cols[cid].toString() + "px";
      //if(cid == "c01") alert(JSON.stringify(this.state.cols[cid]));
      return css;
  }
  syncCols (tb) {

    if (!document.getElementById(tb)) return;

    var cols = {};
    var ix = document.getElementById(tb).rows.length - 1;
    var ic = document.getElementById(tb).rows[0].cells.length;
    var ci = 0;
    while(ci < ic) {
        var cxi = ci + 1;
        var col = "c" + cxi.toString().padStart(2, "0");
        cols[col] = document.getElementById(tb).rows[ix].cells[ci].clientWidth + 1;
        ci = ci + 1;
    }
    this.setState({cols: cols });
    //alert(JSON.stringify(this.state.cols));
    //this.forceUpdate();
  };
  trs() {
    var self = this;
    var prm = {};
    prm.req = "pos.pos_bq.trs";
    prm.avd = this.state.avd;
    //prm.fplu = datum;
    //prm.tplu = datum;

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        self.setState({tra: ret.rca });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }
  row_bong(row) {
    var self = this;
    var status = "09";
    if(row.r0guestno == "09") status = "00";

    var prm = {};
    prm.req = "pos.pos_bq.bongrow";
    prm.row = row;
    prm.status = status;

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        if(ret.ok != "000") {
            var msg = { ok: true, text: "MISSLYCKADES ..." };
            self.setState({msg: msg });
            setTimeout(function(){
                self.setState({msg: {} });
            }, 1000);
            return;
        }
        row.r0guestno = status;
        var msg = { ok: true, text: "BONGAD ..." };
        self.setState({msg: msg });
        setTimeout(function(){
            self.setState({msg: {} });
        }, 1000);
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }

  row_stol(row, e) {
    var self = this;

    e.preventDefault();

    var prm = {};
    prm.req = "pos.pos_bq.stolrow";
    prm.row = row;
    prm.status = "03";

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        //self.setState({bqa: ret.rca });
        //toastr.success("OK", "SAPI");
        var msg = { ok: true, text: "STOL SATT ..." };
        self.setState({msg: msg });
        setTimeout(function(){
            self.setState({msg: {} });
        }, 1000);
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }

  render() {
    if(!this.props.wnds.bong) return "";

    var title = this.props.title;
    if(!title) title = "Titel";

	var html =
        <div className="mx_base">
        { this.props.wnds.backdrop ? <div className="mx_backdrop" /> : null }
        <div className="m2_dlg mx_70 flex-col" style={ this.dlg_style() }>
        <div className="mx_content flex">

            <div className="flex-row" style={{padding:"5px",height:"35px"}}>
                    <div className="flex-col flex">
                        <div>BONG</div>
                    </div>
                    <div className="flex-col">
                        <div>UPPDATERA</div>
                    </div>
            </div>
            <div className="divider_orange"></div>
            <div className="px-5">

            <div className="" style={ this.bong_style(this.state.bqr) }>
            <div className="flex-row flex-sb txt_18">
                <div className="">Bord: { this.state.bqr.bordnr }</div>
                <div>Notanr: { this.state.bqr.notanr }</div>
            </div>
            <div className="flex-row flex-sb txt_14">
                <div className="">Tid: { this.state.bqr.tid }</div>
                <div>VTid: { this.state.bqr.vtid }</div>
            </div>
            </div>
            <div className="divider_green"></div>

            { Object.values(this.state.bqr.rows).map((row, skey) =>
                <div key={skey} className="">
                    <div className="flex-row flex-sb txt_14">
                        <div className="">{ this.stol_text(row) }</div>
                    </div>
                    <div className="bq-row"
                         style={ this.bongrow_style(row) }
                         onClick={() => this.row_bong(row) }
                         onContextMenu={(e) => this.row_stol(row, e) }
                    >
                        { row.text.toswe() } - { row.pris.cdipris() }
                    </div>
                { row.tval.map((trow, tkey) =>
                    <div className="bq-row"
                    >
                        { trow.text.toswe() } - { trow.pris }
                    </div>
                )}
                { row.xtra.map((xrow, xkey) =>
                    <div className="bq-row"
                    >
                        { xrow.text.toswe() } - { xrow.pris }
                    </div>
                )}
                </div>
            )}

            <div className="divider_green"></div>
            <div>Bongat: { this.state.bqr.bongat }</div>
        </div>
        </div>

        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250 ripple" onClick={() => this.bq_close() } >
                STÄNG
            </button>
            <div className="flex-row">
            </div>
            <button className="btn btn-hw-green w250 ripple" onClick={(event) => this.prn(event) } >
                SKRIV UT
            </button>
        </div>
      </div>
        <Wnd_Ok msg={this.state.msg} ctx={this} />
    </div>
	;
	return html;
  };
}

export default Bq_Bong;

/*
            { Object.values(this.state.bqr.srows).map((srow, skey) =>
                <div key={skey} className="">
                    <div className="flex-row flex-sb txt_14">
                        <div className="">{ this.stol_text(srow) }</div>
                    </div>
                { srow.rows.map((rrs00, rkey) =>
                    <div className="bq-row"  style={ this.bongrow_style(rrs00) } onClick={() => this.row_bong(rrs00) } onContextMenu={(e) => this.row_stol(rrs00, e) }>{ rrs00.r0radnr } : { rrs00.r0etyp } - { rrs00.r0text.toswe() } - { rrs00.r0perprice.cdipris() }</div>
                )}
                </div>
            )}

*/