import React, { useState, useEffect, useContext } from 'react';
import {format, addDays, subDays} from "date-fns";

import * as net from "../../../lib/net";
import Td_Sel from "../../../lib/ui/td_sel";
import Fr_Input from "../../../lib/ui/fr_input";

class Gst_Ch_Room extends React.Component {
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pms = props.store.pms;

    this.state = {};
    this.state.today = new Date();
    this.state.rta = [];
    this.state.pka = [];

    this.state.gnr = "001";
    this.state.gbo = {};
    //this.state.lro = props.lro;
    this.state.ynq = [
                {id: '0', text: 'NEJ'},
                {id: '1', text: 'JA'}
            ];

    this.state.bgo = {};
    this.state.bto = {};
    this.state.background = "#fff";

    this.state.cols = {};
    this.state.lrwrk = false;
    this.state.boknr = "";
    this.state.rumsnr = "";

    this.state.wnds = {};
    this.state.rt = "";
    this.state.rta = [];
    this.state.raa = [];
    this.state.rra = [];
      this.state.rvo = {};
      this.state.bro = {};
    this.state.gso = {};
    this.cb = null;
    if(props.wnds) {
        if(props.wnds.boknr) this.state.boknr = props.wnds.boknr;
        if(props.wnds.rvo) this.state.rvo = props.wnds.rvo;
        this.state.bro = this.state.rvo.rblx0;
        this.state.gso = this.state.rvo.gsb00;
        //if(props.wnds.gso) this.state.gso = props.wnds.gso;
        if(props.wnds.cb) this.cb = props.wnds.cb;
    }

    this.roomfkns = [
        {fkn: 'open', text: 'ÖPPNA'},
        {fkn: 'cancel', text: 'AVBOKA'}
    ];
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.dlg_init();
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    tb_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      css.height = window.innerHeight * 0.9 - 250;
      css.overflow = "auto";
      return css;
    }
    grp_style(row) {
      var css = {};
      css.background = "#008";
      if(this.state.gnr === row.vgrupp) css.background = "#080";
      return css;
    }

    wnd_close = () => {
      if(this.props.cb) {
        var ret = {};
        ret.ok = "999";
        this.props.cb(this.props.ctx, ret);
      }
      else this.props.ctx.setState({ wnds: {} });
	};
    handleChange = (event, newValue) => {
        //alert(newValue);
        this.setState({ix: newValue});
      };
    toggle() {
      this.setState({modal: false});
	};

    rt_set (name, e) {
      var self = this;
      const value = e.target.value;
      //var bro = this.state.bro;
      //bro[name] = value;
      //this.setState({bro: bro});
      this.setState({rt: value}, function(){
          self.rt_filtr();
      });
    }
  rt_filtr() {
      var self = this;
      var rra = self.state.rra;
      var rt = this.state.rt;

      var raa = rra.filter(row => row.rt == rt);
      self.setState({raa: raa });
   }
  room_sz() {
      var self = this;
      var poa = self.state.poa;
      var sz = this.state.sz_val;

      var paa = poa.filter(row => row.plu00.p0namn.toUpperCase().includes(sz.toUpperCase()));
      self.setState({paa: paa });
   }
  room_sel(row) {
      var self = this;
      var rumsnr = row.rumsnr;
      self.setState({rumsnr: rumsnr });
  }

    xdlg_init() {
        var self = this;
    }

    dlg_init() {
        var self = this;

    var prm = {};
    prm.req = "pms.rt.rt_date";
    //prm.datum = this.state.datum;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok == "000") {
            self.setState({rta: ret.rco});
        }

        var prm = {};
        prm.req = "pms.rr.rrq_free";
        prm.datum = self.state.bro.r0ankdatum;
        return net.sio_req(prm);
    })
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok == "000") {
            var rt = self.state.rt;
            var rra = ret.rca;
            var raa = ret.rca;
            if(rt != "") {
                raa = rra.filter(row => row.rt == rt);
            }
            self.setState({rra: rra, raa: raa});
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

  rrq() {
    var self = this;
    var prm = {};
    prm.req = "pms.rr.rrq_free";
    prm.datum = this.state.bro.r0ankdatum;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            return;
        }
        self.setState({rra: ret.rca, raa: ret.rca});
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

  room_add() {
    var self = this;

    if(true) {
          if(self.cb) {
              if(self.state.rumsnr.length) {
                var ret = {};
                ret.ok = "000";
                ret.rumsnr = self.state.rumsnr;
                ret.rt = self.state.rt;
                self.cb(self.props.ctx, ret);
              }
              else {
                var ret = {};
                ret.ok = "999";
                ret.rumsnr = "";
                self.cb(self.props.ctx, ret);
              }
          }
          else self.props.ctx.setState({ wnds: {} });
        return;
    }

    var prm = {};
    prm.req = "pms.br.br_gst_add";
    prm.boknr = this.state.boknr;
    prm.rblx0 = this.state.bro;
    prm.gso = this.state.gso;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok == "000") {
		    //self.props.cb(self.props.ctx, ret);
              if(self.props.cb) {
                var ret = {};
                ret.ok = "999";
                self.props.cb(self.props.ctx, ret);
              }
              else self.props.ctx.setState({ wnds: {} });
            return;
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  room_fkn(row, sel) {
    //alert(JSON.stringify(fkn));
      if(sel.fkn == "open") {
          //this.rv_edit(row);
      }
      if(sel.fkn == "cancel") {
          //this.gst_cancel(row);
      }
  }
    val_save (name, value) {
        //alert(JSON.stringify(name));
        //alert(JSON.stringify(value));
      //var bro = this.state.bro;
      //bro[name] = value;
      this.setState({rumsnr: value});
      //setState({[name]: value});
    }
  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "Gäst";

	var html =
        <div className="mx_base">
        <div className="m3_backdrop" />
        <div className="m3_dlg mx_80h flex-col" style={ this.dlg_style() } >

        <div className="mx_h01">
            <div>ÄNDRA RUM</div>
        </div>
        <div className="mx_content flex">

            <div className="txt_box txt_bg_01 txt_22">
            <div className="flex-row">
                <div className="wnd_link">
                    DELBOKNING
                </div>
                <div className="flex"></div>
                <div className="wnd_link" onClick={() => this.br_open() }>
                    ÄNDRA
                </div>
            </div>
            <div className="flex-row py-2">
                <div className="flex-row flex">
                    <div className="rv-lbl">Från:</div>
                    <div className="rv-txt">{this.state.bro.r0ankdatum.cdidate()}</div>
                </div>
                <div className="flex-row flex">
                    <div className="rv-lbl">Till:</div>
                    <div className="rv-txt">{this.state.bro.r0avrdatum.cdidate()}</div>
                </div>
            </div>
            <div className="flex-row py-2">
                <div className="flex-row flex">
                    <div className="rv-lbl">Antal Rum:</div>
                    <div className="rv-txt">{this.state.bro.r0borr}</div>
                </div>
                <div className="flex-row flex">
                    <div className="rv-lbl">Antal Gäster:</div>
                    <div className="rv-txt">{this.state.bro.r0borg}</div>
                </div>
            </div>
            <div className="flex-row py-2">
                <div className="flex-row flex">
                    <div className="rv-lbl">Barn (0-4):</div>
                    <div className="rv-txt">{this.state.bro.r0antb0}</div>
                </div>
                <div className="flex-row flex">
                    <div className="rv-lbl">Barn (5-18):</div>
                    <div className="rv-txt">{this.state.bro.r0antb1}</div>
                </div>
            </div>
            <div className="flex-row py-2">
                <div className="flex-row flex">
                    <div className="rv-lbl">Rumstyp:</div>
                    <div className="rv-txt">{this.state.bro.r0typ}</div>
                </div>
            </div>

            </div>

            <div className="divider_orange"></div>

            <div style={ this.tb_style() }>
            <div className="flex flex-row">
                <table className="table">
                    <thead style={{fontSize: "12px", fontWeight: "normal", color: "#080", border: "0px", margin: "0px", padding: "0px"}}>
                    <tr>
                        <th style={{textAlign: "left"}}>Rum</th>
                        <th style={{textAlign: "left"}}>Rumstyp</th>
                        <th style={{textAlign: "left"}}>Text</th>
                        <th style={{textAlign: "left"}}>Gäster</th>
                        <th style={{textAlign: "left"}}>Bäddar</th>
                        <th style={{textAlign: "left"}}>Status</th>
                        <th style={{textAlign: "center", color: "#800"}}>#</th>
                    </tr>
                    </thead>
                    <tbody style={{fontSize: "16px", color: "#000", border: "0px", margin: "0px", padding: "0px", borderBottom: "1px solid #ccc"}}>
                    { this.state.raa.map((row, key) =>
                    <tr onDoubleClick={() => this.room_sel(row) }>
                        <td>{ row.rumsnr }</td>
                        <td>{ row.rt }</td>
                        <td>{ row.text }</td>
                        <td>{ row.nrg }</td>
                        <td>{ row.beds }</td>
                        <td>{ row.status }</td>
                        <td>
                            <Td_Sel items={ this.roomfkns } getValue={(e)=> this.room_fkn(row, e)} />
                        </td>
                    </tr>
                    )}
                    </tbody>
                </table>
             </div>
             </div>

            <div className="divider_green"></div>

            <div className="flex-row px-5 py-2">
                <div className="form-group flex-column flex">
                        <span className="header">Rumstyp</span>
                        <select value={this.state.rt} className="form-control w350" onChange={(e) => this.rt_set("rt", e)}>
                            { this.state.rta.map((row, key) =>
                            <option key={key} value={row.r1typ}>{row.r1typ}</option>
                            )}
                        </select>
                </div>
                <Fr_Input label="Rumsnr" col className="text-left w250" text={this.state.rumsnr} getValue={(e)=> this.val_save("rumsnr", e) } />
            </div>

        </div>

        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                AVBRYT
            </button>
            <div className="flex-row">
            <button className="btn btn-hw-red w250" onClick={() => this.room_add() } >
                LÄGG TILL
            </button>
            </div>
        </div>
      </div>
      </div>
	;
	return html;
  };
}

export default Gst_Ch_Room;