import React, { useState, useEffect, useContext } from 'react';
import {Route, Routes} from "react-router-dom";
import {withRouter} from "../lib/react/withRouter";

import Reg_Home from './reg/home.js';
import Reg_Menus from './reg/menus.js';
import Reg_Prds from './reg/prds.js';
import Reg_Ingred from './reg/ingred.js';
import sso from "../sso";

function pg_home() {

    var html =
        <div className="web-content">
            Hem
        </div>
    ;
    return html;
}

class Ecom_Reg extends React.Component {
    //var sdo = useContext(SDO);
    constructor(props, context) {
        super(props);

        this.store = props.store;
        this.state = {};
        var po = {};
        po.menus = { "fid": "f03", "url": "/ecom/reg/menus", "name": "Menyer", "active": false };
        po.prds = { "fid": "f03", "url": "/ecom/reg/prds", "name": "Produkter", "active": false };
        po.ingred = { "fid": "f03", "url": "/ecom/reg/ingred", "name": "Ingredienser", "active": false };
        this.state.po = po;
        this.state.pa = [];
        this.state.pa = Object.values(po);
    }
    componentDidUpdate() {
    }
    componentWillUnmount() {
    }
    componentDidMount() {
    }

    fknGo(row) {
        sso.seo.sfkn = row;
        this.props.navigate(row.url);
    }
    br_css(row) {
        var css = {};
        if(sso.seo.sfkn && (row.url == sso.sdo.sfkn.url)) css.color = "#080";
        //var brc = false;
        //if(row.url == sbo.state.url) brc = true;
        return css;
    }
    render() {
        return (
            <div className="web-app">
                { this.props.store.cfg.bread ?
                    <div className="web-bread">
                        <div className="breadcrumb">
                            { this.state.pa.map((row, key) =>
                                <div key={key} className="breadcrumb-item" style={this.br_css(row)} onClick={() => this.fknGo(row)}>{row.name}</div>
                            )}
                            <div className="flex"></div>
                            <div className="cdigreen">{ sso.seo.sfkn ? sso.seo.sfkn.name : null }</div>
                        </div>
                    </div>
                    : null }
                <Routes>
                    <Route path="/xx" component={pg_home} />
                    <Route path="menus" element={ <Reg_Menus store={this.store} />} />
                    <Route path="prds" element={ <Reg_Prds store={this.store} />} />
                    <Route path="ingred" element={ <Reg_Ingred store={this.store} />} />
                    <Route path="*" element={ <Reg_Home store={this.store} />} />
                </Routes>
            </div>

        );
    }
}
//Sys.contextType = ADOContext;

export default withRouter(Ecom_Reg);
