import React from 'react';
import * as q from '../lib/defer';

import socketIOClient from 'socket.io-client'

    var srs_oo = {};
    var defer = {};
   //alert('SIO!');
/*
    var url = 'http://127.0.0.1:9231/';
    const sio = socketIOClient(url);
    //sio.emit('change color', this.state.color);

	sio.on('connect', function () {
	    //alert('Connected!');
	    //toastr.success("IO CONNECTED");
	  });
	sio.on('connect_error', function (e) {
	    //alert('Socket Error : ' + e);
	    //toastr.error("IO CONNECT ERROR", e);
	  });
	sio.on('error', function (e) {
	    //alert('Socket Error : ' + e);
	    //toastr.error("IO ERROR", e);
	  });

	sio.on('srs:msg', function (data) {
		alert(data);
	});

	sio.on('usr:rsp', function (base64) {
		var s = atob(base64);
		var rsp = JSON.parse(s);
		//alert(JSON.stringify(rsp));
		var reqid = rsp.reqid;
		//if(self.defer[reqid]) self.defer[reqid].resolve(rsp);
	});
	sio.on('udid:rsp', function (base64) {
	    //alert(JSON.stringify(base64));
		//var s = atob(base64);
		var rsp = base64;
		//var rsp = JSON.parse(s);
		var reqid = rsp.reqid;
		//alert(JSON.stringify(reqid));
		if(defer[reqid]) defer[reqid].resolve(rsp);
	});
	sio.on('gfs:rsp', function (base64) {
		var s = atob(base64);
		var rsp = JSON.parse(s);
		//alert(JSON.stringify(rsp));
		var reqid = rsp.reqid;
		//if(self.defer[reqid]) self.defer[reqid].resolve(rsp);
	});
*/
export function udid_req(req) {

    //var req = {};
    var reqid = "1234567890123456".split('').map(function(){return 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'.charAt(Math.floor(62*Math.random()));}).join('');
    //var reqid = crypto.randomBytes(16).toString('hex');
    //var reqid = Math.random().toString(16).substr(2, 16);
    //var reqid = 1212;
    defer[reqid] = q.defer();
    req.reqid = reqid;
    //req.token = $sessionStorage.token;
    //var defer = q.defer();
    //alert(JSON.stringify(reqid));
    //sio.emit("udid:req", req, function () {
    //});

    return defer[reqid].promise;
}
