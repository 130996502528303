import React, { useState, useEffect, useContext } from 'react';
import {format, addDays, subDays} from "date-fns";

import * as net from "../../../../lib/net";
import * as utils from "../../../../assets/utils";

import Fr_Input from "../../../../lib/ui/fr_input";
import Td_Sel from "../../../../lib/ui/td_sel";
import Fr_Cal from "../../../../lib/ui/fr_cal";

class Br_Pkat_Add extends React.Component {
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pms = props.store.pms;

    this.state = {};
    this.state.today = new Date();
    this.state.rta = [];
    this.state.nba = [];
    this.state.pka = [];
    this.state.pkat = "";
    this.state.pko = {};
    this.state.pks = {};

    this.state.gnr = "001";
    this.state.gbo = {};
    //this.state.lro = props.lro;
    this.state.ynq = [
                {id: '0', text: 'NEJ'},
                {id: '1', text: 'JA'}
            ];

    this.state.background = "#fff";

    this.state.fdat = "";
    this.state.tdat = "";
    this.state.pris = "";
    this.state.baspris = "";

    this.state.part = "01";
    this.state.wnds = {};
    this.state.cols = {};
    this.state.lrwrk = false;
    this.state.boknr = {};
    this.state.bro = {};
    this.state.gsa = [];
    if(props.wnds) {
        if(props.wnds.boknr) this.state.boknr = props.wnds.boknr;
        if(props.wnds.bro) this.state.bro = props.wnds.bro;
        this.state.fdat = this.state.bro.r0ankdatum.cdidate();
        this.state.tdat = this.state.bro.r0avrdatum.cdidate();
    }
    this.state.pkk = [
        {pkat: 'rack', text: 'ROM OG FROKOST', pris: '79500'},
        {pkat: 'halp', text: 'HALVPENSION', pris: '99500'},
        {pkat: 'help', text: 'HELPENSION', pris: '129500'}
    ];
    this.state.pkd = [];
    this.pkatitems = [
        {fkn: 'cancel', text: 'TA BORT'}
    ];
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.pk_init();
    this.br_pkats();
  }
  pk_init() {
      var pris = "";

      if(this.state.bro.r0typ.substr(0, 3) == "LAR") pris = "780000";
      if(this.state.bro.r0typ.substr(0, 3) == "LEK") pris = "720000";
      if(this.state.bro.r0typ.substr(0, 3) == "LPK") pris = "640000";
      if(this.state.bro.r0typ.substr(0, 3) == "LCK") pris = "470000";
      if(this.state.bro.r0typ.substr(0, 3) == "MCK") pris = "390000";
      if(this.state.bro.r0typ.substr(0, 3) == "MCQ") pris = "310000";
      if(this.state.bro.r0typ.substr(0, 3) == "SPQ") pris = "270000";
      if(this.state.bro.r0typ.substr(0, 3) == "SCS") pris = "220000";

      pris = pris.cdipris();
      var pka = [];
      pka.push( {pkat: 'F', text: 'ORDINARIE LOGI - F', pris: pris} );
      this.setState({pkd: pka, pris: pris, baspris: pris});
      return;
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    grp_style(row) {
      var css = {};
      css.background = "#008";
      if(this.state.gnr === row.vgrupp) css.background = "#080";
      return css;
    }

    wnd_close = () => {
        if( this.props.wnds.cb ) {
            var ret = {};
            ret.ok = "000";
            this.props.wnds.cb(this.props.ctx, {});
        }
      else {
          this.props.ctx.setState({ wnds: {} });
          var ret = {};
          ret.ok = "999";
          //this.props.cb(this.props.ctx, ret);
      }
    };

    pkk_set (e) {
      const value = e.target.value;
      //alert(value);
      //var pks = this.state.pks;
      //var pko = pks[value];
        var pris = "";
        for(var row of this.state.pkk) {
            if(value == row.pkat) {
                pris = row.pris.cdipris();
            }
        }
      this.setState({pkat: value, pris: pris});
      //setState({[name]: value});
    }
    pkat_set (e) {
      const value = e.target.value;
      var pkats = this.pms.pkats;
      var pko = pkats[value];
      this.setState({pkat: pko.n0typ, pko: pko});
      //setState({[name]: value});
    }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }

  br_init() {
    var self = this;

    var bro = this.state.bro;
    this.setState({fdat: bro.fdat, tdat: bro.tdat});
  }
  pkats() {
    var self = this;
    var prm = {};
    prm.req = "pms.pkat.qa_pkats";
    //prm.datum = this.state.datum;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            return;
        }
        self.setState({pka: ret.rca, pks: ret.rco});
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

  br_save() {
    var self = this;
    var prm = {};
    prm.req = "pms.br.br_upd";
    prm.boknr = this.state.boknr;
    prm.rblx0 = this.state.bro;
    //prm.nyp00 = this.state.pko;
    //alert(JSON.stringify(prm));
    //return;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok == "000") {
		    self.props.cb(self.props.ctx, ret);
            return;
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  br_del() {
    var self = this;

    var prm = {};
    prm.req = "pms.br.br_del";
    //prm.fkn = "7217";
    prm.boknr = this.state.boknr;
    prm.rblx0 = this.state.bro;
    //alert(JSON.stringify(prm));

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            return;
        }
        self.props.cb(self.props.ctx, ret);
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
    return;
  }
  br_pkats() {
    var self = this;
    var prm = {};
    prm.req = "pms.br.br_pkats";
    prm.rblx0 = this.state.bro;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            return;
        }
        self.setState({nba: ret.rca});
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
    br_pkat_add() {
    var self = this;

    var prm = {};
    prm.req = "pms.br.br_pkat_madd";
    prm.boknr = this.state.boknr;
    prm.pkat = this.state.pkat;
    prm.fdat = this.state.fdat;
    prm.tdat = this.state.tdat;
    prm.pris = this.state.pris;
    prm.baspris = this.state.baspris;
    prm.rblx0 = this.state.bro;
    //prm.nyp00 = this.state.pko;
    //alert(JSON.stringify(prm));

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            alert("Priskategori ej tillagd, finns kategorin redan?");
            return;
        }
        self.br_pkats();
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
    return;
  }
    br_pkat_cancel(row) {
    var self = this;

    var prm = {};
    prm.req = "pms.br.br_pkat_del";
    prm.boknr = this.state.boknr;
    prm.nyb00 = row;
    //prm.nyp00 = this.state.pko;
    //alert(JSON.stringify(prm));

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            return;
        }
        self.br_pkats();
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
    return;
  }
    br_pkat_edit(row) {
  }
  pkat_row_fkn(row, sel) {
    //alert(JSON.stringify(fkn));
      if(sel.fkn == "edit") {
          //this.rv_edit(row);
      }
      if(sel.fkn == "cancel") {
          this.br_pkat_cancel(row);
      }
  }
    val_pris_save (value) {
      this.setState({pris: value});
    }
    val_fd_save (dts) {
     this.setState({fdat: dts});
    }
    val_td_save (dts) {
     this.setState({tdat: dts});
    }

  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "Delbokning";
    //if(props.kro) alert(props.kro.krr00.kid);
    const popover = {
      position: 'absolute',
      top: '50px',
      zIndex: '2',
    }
    const cover = {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    }

	var html =
        <div className="mx_base">
        <div className="m3_backdrop" />
        <div className="m3_dlg mx_80 flex-col" style={ this.dlg_style() } draggable="true" >

        <div className="mx_h01">
            <div>PRISER: {this.state.bro.r0lopnr} </div>
        </div>
        <div className="mx_content flex">

            <div className="divider_orange"></div>

            <div className="px-2 flex">
            <div className="flex-row py-2">
                <div className="flex-row flex">
                    <div className="rv-lbl">Från:</div>
                    <div className="rv-txt">{this.state.bro.r0ankdatum.cdidate()}</div>
                </div>
                <div className="flex-row flex">
                    <div className="rv-lbl">Till:</div>
                    <div className="rv-txt">{this.state.bro.r0avrdatum.cdidate()}</div>
                </div>
            </div>
            <div className="flex-row py-2">
                <div className="flex-row flex">
                    <div className="rv-lbl">Rumstyp:</div>
                    <div className="rv-txt">{this.state.bro.r0typ}</div>
                </div>
            </div>
            <div className="divider_green"></div>
            <div className="flex-column flex">

            <div className="flex flex-row">
                <table className="table" style={{border: "0px", margin: "0px", padding: "0px"}}>
                    <thead style={{fontSize: "12px", fontWeight: "normal", color: "#080", border: "0px", margin: "0px", padding: "0px"}}>
                    <tr>
                        <th style={{textAlign: "left"}}>Datum</th>
                        <th style={{textAlign: "left"}}>Text</th>
                        <th style={{textAlign: "left"}}>Pkat</th>
                        <th style={{textAlign: "left"}}>Baspris</th>
                        <th style={{textAlign: "left"}}>Pris</th>
                        <th style={{textAlign: "left"}}></th>
                    </tr>
                    </thead>
                    <tbody style={{fontSize: "16px", color: "#000", border: "0px", margin: "0px", padding: "0px", borderBottom: "1px solid #ccc"}}>
                    { this.state.nba.map((row, key) =>
                    <tr onDoubleClick={() => this.br_pkat_edit(row) }>
                        <td>{ utils.toDate(row.n0datum) }</td>
                        <td>{ row.n0text }</td>
                        <td>{ row.n0typ }</td>
                        <td>{ row.n0baspris.cdipris() }</td>
                        <td>{ row.n0pris.cdipris() }</td>
                        <td>
                            <Td_Sel items={ this.pkatitems } getValue={(e)=> this.pkat_row_fkn(row, e)} />
                        </td>
                    </tr>
                    )}
                    </tbody>
                </table>
             </div>

         </div>
         </div>

            <div className="divider_orange"></div>
            <div className="flex-row px-2">
                <div className="">OBS! Samma kategori kan endast finnas en gång per dag!</div>
            </div>
            <div className="flex-column px-2">
            <div className="flex-row">
                <div className="form-group flex-column flex">
                        <span className="header">PRIS ATT LÄGGA TILL</span>
                        <select value={this.state.pkat} className="form-control w450" onChange={(e, row) => this.pkk_set(e, row)}>
                            { this.state.pkd.map((row, key) =>
                            <option key={key} value={row.pkat}>{row.text} - {row.pris.cdipris()}</option>
                            )}
                        </select>
                </div>
                <div className="form-group flex-column">
                    <button className="btn btn-hw-red w150" onClick={() => this.br_pkat_add() } >
                        LÄGG TILL
                    </button>
                </div>
            </div>
            <div className="flex-row">
            </div>
            <div className="flex-row py-3">
                <div className="flex-row flex">
                    <Fr_Cal above label="Från" col className="text-left w250" min="1" text={this.state.fdat} getValue={(e)=> this.val_fd_save( e ) }/>
                    <Fr_Cal above label="Till" col className="text-left w250" min="1" text={this.state.tdat} getValue={(e)=> this.val_td_save( e ) }/>
                </div>
                <Fr_Input label="Belopp" col className="text-right w250" text={this.state.pris} getValue={(e)=> this.val_pris_save(e) } />
            </div>
            </div>

        </div>

        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex-row">
            </div>
        </div>
      </div>

      </div>
	;
	return html;
  };
}

export default Br_Pkat_Add;