import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";
import {withRouter} from "../../lib/react/withRouter";
import sso from "../../sso";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Eko_Home extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = {};
    this.state.seldate = new Date();
    this.state.avd = "01";
    this.state.paa = [];
    this.state.aa = [];

    this.state.mna = [];
    this.state.mna.push({ mnid: "mn01", pos: "c1", text: "STATUS", desc: "Aktuell status", link: "/ecom/eko/status" });
    this.state.mna.push({ mnid: "mn02", pos: "c1", text: "PERIOD", desc: "Period summering", link: "/ecom/eko/per" });
      this.state.mna.push({ mnid: "mn03", pos: "c2", text: "RAPPORT", desc: "Ekonomiska rapporter", link: "/ecom/eko/rps" });
      this.state.mna.push({ mnid: "mn03", pos: "c2", text: "TRANSAKTIONER", desc: "Ekonomiska rapporter", link: "/ecom/eko/trs" });
      this.state.mna.push({ mnid: "mn03", pos: "c2", text: "KVITTON", desc: "Ekonomiska rapporter", link: "/ecom/eko/kvitton" });
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

    mnc(pos) {
        var mna = [];
        for(var row of this.state.mna) {
            if(row.pos == pos) mna.push( row );
        }
        return mna;
    }
    fknGo(row) {
        //alert(row.href);
        var sfkn = {};
        sfkn.text = row.text;
        sfkn.url = row.link;
        sso.sdo.sfkn = sfkn;
        this.props.navigate(sfkn.url);
    }

  render() {
    return (
    <div className="web-app">
        <div className="web-pg py-4 flex">
            <h3>Ekonomi</h3>
            <p>Ekonomi funktioner</p>

            <div className="divider_green"></div>

            <div className="web-row">
                <div className="flex-col flex">
                    { this.mnc("c1").map((row, key) =>
                        <div key={key} className="web-bx" onClick={() => this.fknGo(row) }>
                            <div>{ row.text }</div>
                            <div className="flex-row">
                                <div className="flex">{ row.desc }</div>
                                <div className="web-link">Välj</div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="flex-col flex">
                    { this.mnc("c2").map((row, key) =>
                        <div key={key} className="web-bx" onClick={() => this.fknGo(row) }>
                            <div>{ row.text }</div>
                            <div className="flex-row">
                                <div className="flex">{ row.desc }</div>
                                <div className="web-link">Välj</div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>

    </div>
  );
    }
}
export default withRouter(Eko_Home);
