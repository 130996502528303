import React, { useState, useEffect, useContext } from 'react';
import {Route, Routes} from "react-router-dom";
import {withRouter} from "../lib/react/withRouter";

import Fo_Home from './fo/home.js';
import Fo_Hubs from './fo/hubs.js';
import Fo_Kds from './fo/kds.js';
import Fo_Mtv from './fo/mtv.js';
import Fo_Vkod from './fo/vkod.js';
import Fo_Vip from './fo/vip.js';
import sso from "../sso";

function pg_home() {

    var html =
        <div className="web-content">
            Hem
        </div>
    ;
    return html;
}

class Ecom_Fo extends React.Component {
    //var sdo = useContext(SDO);
    constructor(props, context) {
        super(props);

        this.store = props.store;
        this.state = { pgid: 11, deps: [] };
        var po = {};
        po.hubs = { "fid": "f02", "url": "/ecom/fo/hubs", "name": "Hubbar", "active": false };
        po.kds = { "fid": "f02", "url": "/ecom/fo/kds", "name": "KDS", "active": false };
        po.mtv = { "fid": "f02", "url": "/ecom/fo/mtv", "name": "MenyTV", "active": false };
        po.vkod = { "fid": "f02", "url": "/ecom/fo/vkod", "name": "Värdekod", "active": false };
        po.vip = { "fid": "f02", "url": "/ecom/fo/vip", "name": "Lojalitet", "active": false };
        this.state.po = po;
        this.state.pa = [];
        this.state.pa = Object.values(po);
    }
    componentDidUpdate() {
    }
    componentWillUnmount() {
    }
    componentDidMount() {
    }

    fknGo(row) {
        sso.seo.sfkn = row;
        this.props.navigate(row.url);
    }
    br_css(row) {
        var css = {};
        if(sso.seo.sfkn && (row.url == sso.seo.sfkn.url)) css.color = "#080";
        //var brc = false;
        //if(row.url == sbo.state.url) brc = true;
        return css;
    }

    render() {
        return (
            <div className="web-app">
                { this.props.store.cfg.bread ?
                    <div className="web-bread">
                        <div className="breadcrumb">
                            { this.state.pa.map((row, key) =>
                                <div key={key} className="breadcrumb-item" style={this.br_css(row)} onClick={() => this.fknGo(row)}>{row.name}</div>
                            )}
                            <div className="flex"></div>
                            <div className="cdigreen">{ sso.seo.sfkn ? sso.seo.sfkn.name : null }</div>
                        </div>
                    </div>
                    : null }
                <Routes>
                    <Route path="/xx" component={pg_home} />
                    <Route path="hubs" element={ <Fo_Hubs store={this.store} />} />
                    <Route path="kds" element={ <Fo_Kds store={this.store} />} />
                    <Route path="mtv" element={ <Fo_Mtv store={this.store} />} />
                    <Route path="vkod" element={ <Fo_Vkod store={this.store} />} />
                    <Route path="vip" element={ <Fo_Vip store={this.store} />} />
                    <Route path="*" element={ <Fo_Home store={this.store} />} />
                </Routes>
            </div>

        );
    }
}
//Sys.contextType = ADOContext;

export default withRouter(Ecom_Fo);
