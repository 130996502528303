import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";
import ddo from "../../ddo";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Prs_Reg extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, eea: [] };
    this.state.seldate = new Date();
    this.state.unit = null;
      this.state.una = Object.values(ddo.cfg.units);
      this.state.uno = ddo.cfg.units;
    let cdat = new Date();
    let formatted_date = cdat.getFullYear() + "-" + (cdat.getMonth() + 1) + "-" + cdat.getDate();
    this.state.today = formatted_date;
    this.state.datum = formatted_date;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.prs();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 250;
      return css;
  }

  onUnitChange(e) {
        this.setState({unit: e.value});
  }
  change_unit(e) {
    //let cdat = this.state.seldate;
    //alert(JSON.stringify(e));
    let unit = e.target.value;
    this.setState({unit: unit});
    this.setState({tra: [] });
  }

  prs(sid) {
    var self = this;
    var prm = {};
    //prm.sid = sid;
    prm.req = "ta.ta_ee.eea";
    //prm.fdat = datum;
    //prm.tdat = datum;

    //net.sid_req(prm)
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            return;
        }
        self.setState({eea: ret.rca });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
    }

  render() {
    return (
    <div className="web-app">

    <div className="flex-row">
         <button className="btn btn-hw-blue w150 ripple" onClick={() => this.prs_add() } aria-label="System" >
            LÄGG TILL
        </button>
        <div className="flex"></div>
        <div className="link_14" onClick={() => this.prs_active() }>AKTIVA</div>
        <div className="link_14" onClick={() => this.prs() }>UPPDATERA</div>
   </div>
    <div className="divider_orange"></div>

    <div className="web-row">
        <table className="table table-bordered table-hover tb fixed_header">
            <thead>
            <tr>
                <th>Anstnr</th>
                <th>Namn</th>
                <th>Pnr</th>
                <th>Avd</th>
                <th>Schema</th>
                <th>Start</th>
                <th>Sign</th>
                <th>Extraid</th>
            </tr>
            </thead>
            <tbody style={ this.tb_style() }>
            { this.state.eea.map((row, key) =>
            <tr key={key} id={ row.p1.anstnummer } ng-dblclick="trans_open(row);">
                <td>{ row.p1.anstnummer }</td>
                <td>{ row.p1.namn.toswe() }</td>
                <td>{ row.p1.fodnummer }</td>
                <td>{ row.p1.avdelning }</td>
                <td>{ row.p1.arbschema }</td>
                <td>{ row.p2.startdat }</td>
                <td>{ row.p2.initialer }</td>
                <td>{ row.p2.annatanstnr }</td>
            </tr>
            )}
            </tbody>
        </table>
    </div>

    </div>
  );
}
}
export default Prs_Reg;
