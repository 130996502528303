import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";
import {withRouter} from "../../lib/react/withRouter";
import sso from "../../sso";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Wsa_Home extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = {};
    this.state.seldate = new Date();
    this.state.avd = "01";
    this.state.paa = [];
    this.state.aa = [];

      this.state.mna = [];
      this.state.mna.push({ mnid: "mn01", pos: "c1", text: "KASSA", desc: "Status på alla kassor", link: "/bos/wsa/kassa" });
      this.state.mna.push({ mnid: "mn01", pos: "c1", text: "BORD", desc: "Status på öppna bord", link: "/bos/wsa/ot" });
      this.state.mna.push({ mnid: "mn02", pos: "c1", text: "EVENT", desc: "Register över alla event", link: "/bos/wsa/event" });
      this.state.mna.push({ mnid: "mn03", pos: "c1", text: "KUNDER", desc: "Kundregister", link: "/bos/wsa/kund" });
      this.state.mna.push({ mnid: "mn04", pos: "c1", text: "KVITTOINFO", desc: "Kvitton med registrerad text", link: "/bos/wsa/ki" });
      this.state.mna.push({ mnid: "mn05", pos: "c2", text: "BORDSVY", desc: "Vyer för bord och miljö", link: "/bos/wsa/tbv" });
      this.state.mna.push({ mnid: "mn06", pos: "c2", text: "BORDSSTATUS", desc: "Aktuellstatus på borden", link: "/bos/wsa/tbs" });
      this.state.mna.push({ mnid: "mn07", pos: "c2", text: "BONGKÖ", desc: "Beställningskö", link: "/bos/wsa/bq" });

  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

    mnc(pos) {
      var mna = [];
      for(var row of this.state.mna) {
          if(row.pos == pos) mna.push( row );
      }
      return mna;
    }
    fknGo(url) {
        //alert(row.href);
        var row = {};
        row.url = url;
        sso.seo.sfkn = row;
        this.props.navigate(url);
    }

  render() {
    return (
    <div className="web-app">

    <div className="web-pg py-4 flex">
        <h3>WS funktioner</h3>
        <p>Diverse funktioner för admin systemet</p>

        <div className="divider_green"></div>

        <div className="web-row">
            <div className="flex-col flex">
                { this.mnc("c1").map((row, key) =>
                    <div key={key} className="web-bx" onClick={() => this.fknGo(row.link) }>
                        <div>{ row.text }</div>
                        <div className="flex-row">
                            <div className="flex">{ row.desc }</div>
                            <div className="web-link">Välj</div>
                        </div>
                    </div>
                )}
            </div>
            <div className="flex-col flex">
                { this.mnc("c2").map((row, key) =>
                    <div key={key} className="web-bx" onClick={() => this.fknGo(row.link) }>
                        <div>{ row.text }</div>
                        <div className="flex-row">
                            <div className="flex">{ row.desc }</div>
                            <div className="web-link">Välj</div>
                        </div>
                    </div>
                )}
            </div>
        </div>

    </div>

    </div>
  );
    }
}
export default withRouter(Wsa_Home);
