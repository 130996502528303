import React, { useState, useEffect, useContext } from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import { observer } from 'mobx-react';
import { useStore } from '../../sdo.js'
import {useNavigate} from "react-router-dom";
import sso from "../../sso";

function Cas_Mnu(props) {
    var navigate = useNavigate();
    let [state, setState] = useState({row: {}, hrow: {}, ma: []});
    let ctx = useStore();

    var lock = "lock";
    var moa = {};
    moa["f00"] = { fid: "f00", name: "Hem", url: "/cas", icon: "home", fa: "fa fa-home flex", ma: [] };
    moa["f01"] = { fid: "f01", name: "Rapporter", url: "/cas/rps", icon: "key", fa: "fa fa-key flex", ma: [] };
    moa["f02"] = { fid: "f02", name: "Fsg", url: "/cas/fsg", icon: "chart-bar", fa: "fa fa-chart-bar flex", ma: [] };
    moa["f03"] = { fid: "f03", name: "Marker", url: "/cas/mark", icon: "play", fa: "fa fa-play flex", ma: [] };
    //moa["f03"] = { fid: "f03", name: "Statistik", url: "/cas/stat", icon: "play", fa: "fa fa-play flex", ma: [] };
    moa["f04"] = { fid: "f04", name: "Personal", url: "/cas/prs", icon: "list-alt", fa: "fa fa-list-alt flex", ma: [] };
    moa["f05"] = { fid: "f05", name: "Backoffice", url: "/cas/bo", icon: "cogs", fa: "fa fa-cogs flex", ma: [] };

    moa["f01"].ma.push( { fid: "f01", name: "Kasinorapport", url: "/cas/rps/cas", fa: "fa fa-bars" } );
    moa["f01"].ma.push( { fid: "f01", name: "Kassarapport", url: "/cas/rps/cr", fa: "fa fa-bars" } );
    moa["f01"].ma.push( { fid: "f01", name: "Dagrapport", url: "/cas/rps/dr", fa: "fa fa-bars" } );
    moa["f01"].ma.push( { fid: "f01", name: "Månadsrapport", url: "/cas/rps/mr", fa: "fa fa-bars" } );

    moa["f02"].ma.push( { fid: "f02", name: "Transaktioner", url: "/cas/fsg/trs", fa: "fa fa-bars" } );
    moa["f02"].ma.push( { fid: "f02", name: "Enhetsfsg", url: "/cas/fsg/unit", fa: "fa fa-bars" } );
    moa["f02"].ma.push( { fid: "f02", name: "Timfsg", url: "/cas/fsg/hh", fa: "fa fa-bars" } );

    moa["f03"].ma.push( { fid: "f03", name: "Kontroll", url: "/cas/mark/ctrl", fa: "fa fa-bars" } );
    moa["f03"].ma.push( { fid: "f03", name: "Cashbox", url: "/cas/mark/cash", fa: "fa fa-bars" } );
    moa["f03"].ma.push( { fid: "f03", name: "Pass", url: "/cas/mark/pass", fa: "fa fa-bars" } );
    moa["f03"].ma.push( { fid: "f03", name: "Logg", url: "/cas/mark/logg", fa: "fa fa-bars" } );

    //moa["f03"].ma.push( { fid: "m01", name: "Enheter", url: "/cas/stat/units", fa: "fa fa-bars" } );
    //moa["f03"].ma.push( { fid: "m01", name: "Månad", url: "/cas/stat/month", fa: "fa fa-bars" } );
    //moa["f03"].ma.push( { fid: "m01", name: "Vecko", url: "/cas/stat/week", fa: "fa fa-bars" } );
    //moa["f03"].ma.push( { fid: "m01", name: "Tim", url: "/cas/stat/hh", fa: "fa fa-bars" } );

    //moa["f03"].ma.push( { fid: "m01", name: "Enheter", url: "/cas/trs/all", fa: "fa fa-bars" } );
    //moa["f03"].ma.push( { fid: "m01", name: "Enhet", url: "/cas/trs/one", fa: "fa fa-bars" } );

    moa["f04"].ma.push( { fid: "f04", name: "Register", url: "/cas/prs/reg", fa: "fa fa-bars" } );
    moa["f04"].ma.push( { fid: "f04", name: "Pass", url: "/cas/prs/pass", fa: "fa fa-bars" } );

    moa["f05"].ma.push( { fid: "f05", name: "Kontodefinition", url: "/cas/bo/konto", fa: "fa fa-bars" } );
    moa["f05"].ma.push( { fid: "f05", name: "Företag", url: "/cas/bo/ftg", fa: "fa fa-bars" } );
    moa["f05"].ma.push( { fid: "f05", name: "Enheter", url: "/cas/bo/units", fa: "fa fa-bars" } );
    moa["f05"].ma.push( { fid: "f05", name: "Marker", url: "/cas/bo/marker", fa: "fa fa-bars" } );

    var aa = [];
    var keys = Object.keys(moa);
    for(var key of keys) {
        var o = moa[key];
        aa.push( o );
    }

    var ma = [];
    var mnuHeight = window.innerHeight - 100;

   function fknGo(row) {
    //var sdo = useContext(SDO);
    //alert(fkn);
    var fkn = row.fid;
    setState({ mnu : row.fid, row : row,  ma : [] });
    ctx.fid = fkn;

       sso.seo.sfkn = row;
    //props.store.fkn = row;
       navigate(row.url);
   };
   function fknFix(fkn) {
    //var sdo = useContext(SDO);
    //alert(fkn);
    ctx.fid = fkn;

    if(fkn == "lock") navigate('/web');
    if(fkn == "logout") navigate('/');
   };
   function mnuGo(row) {
    //var sdo = useContext(SDO);
    //alert(fkn);
    //ctx.fid = fkn;
    setState({ mnu : state.hfid, row : state.row,  ma : [] });

       sso.seo.sfkn = row;
    //props.store.fkn = row;
       navigate(row.url);
   };
   function hideMnu() {
    setState({mnu : state.mnu, row : state.row, ma : [] });
   };
   function mouseEnter(it) {
    setState({mnu : state.mnu, row : state.row, hfid : it.fid, htitle : it.name, ma : it.ma });
   };
   function mouseLeave() {
    setState({mnu : state.mnu, row : state.row, ma : [] });
   };

   function row_css(row) {
  	    var css = {};
        css.margin = "0px";
        css.padding = "0px";
        css.height = "55px";
        css.borderBottom = "1px #ccc solid";

		if(state.mnu == row.fid) {
			css.background = "#080";
			css.color = "#fff";
		}
		return css;
    }
   function row_icon_css(row) {
  	    var css = {};
        css.paddingTop = "10px";
        css.fontSize = "20px";

		if(state.mnu == row.fid) {
			//css.color = "#ff9900";
			css.color = "#fff";
		}
		return css;
    }

   //<Icon className={ it.fa } style={{ fontSize: 18 }} />
   var html =
        <div className="">
        <div className="cdi_xmnu flex-fullcol" style={{padding: "5px 0px"}}>
            { aa.map((it, key) =>
            <div key={ key } onClick={() => fknGo(it)} className="web-col flex-cc xmnu_tab" style={ row_css(it) } onMouseEnter={ () => mouseEnter(it) }>
                <FontAwesomeIcon icon={ it.icon } size="1x" className="" style={ row_icon_css(it) } />
                <div style={{fontSize: "10px"}}>{ it.name }</div>
            </div>
            )}
            <div onClick={ () => fknFix("lock") } className="web-col flex-cc xmnu_tab">
                <FontAwesomeIcon icon="lock" size="1x" className="" style={{paddingTop: "10px", fontSize: "20px"}} />
                <div style={{fontSize: "10px"}}>Lås</div>
            </div>
            <div onClick={() => fknFix("logout") } className="web-col flex-cc xmnu_tab" >
                <i className="fa fa-power-off flex" aria-hidden="true" style={{paddingTop: "10px", fontSize: "20px"}}></i>
                <div style={{fontSize: "10px"}}>Logga ur</div>
            </div>
        </div>

            { state.ma.length ?
        <div className="mtrl_mnu_sub mtrl_mnu_bg" aria-label="Sub Menu" style={{height: mnuHeight}} onMouseLeave={mouseLeave}>
          <div className="mtrl_mnu_title" onClick={() => hideMnu()}>
            { state.htitle }
          </div>
            { state.ma.map((mi, key) =>
          <div key={key} className="mtrl_mnu_item flex-row" onClick={ () => mnuGo(mi) }>
            <div className="flex">{ mi.name }</div>
            <i className={ mi.fa } aria-hidden="true" style={ row_icon_css(mi) }></i>
          </div>
            )}
        </div>
                : null }
        </div>
    ;
    return html;
}

export default observer(Cas_Mnu);
