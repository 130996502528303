import React, { useState, useEffect, useContext } from 'react';
import { observer } from 'mobx-react'
import ddo from '../../ddo.js'

import Fsg_dlg from "./rps/fsg_dlg";
import Bet_dlg from "./rps/bet_dlg";
import HH_Dlg from "./rps/hh_dlg";
import Hotel_Dlg from "./rps/hotel_dlg";
import Term_Dlg from "./rps/term_dlg";
import Tips_Dlg from "./rps/tips_dlg";
import Tp_Dlg from "./rps/tp_dlg";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Pos_Red_Rps extends React.Component {
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.state = {};
    this.state.seldate = new Date();
    this.state.avd = ddo.cache.avd;
    this.state.paa = [];
    this.state.aa = [];

    this.state.pdf = 0;
    this.state.rptyp = "";
    this.state.xrecov = 0;

      this.state.mna = [];
      this.state.mna.push({ rpid: "fsg", pos: "c1", text: "FSG STATISTIK", desc: "Statistik för perioden", link: "" });
      this.state.mna.push({ rpid: "bet", pos: "c1", text: "BETALSTATISTIK", desc: "Statistik för perioden", link: "" });
      this.state.mna.push({ rpid: "hh", pos: "c1", text: "TIMFSGRAPPORT", desc: "Timfsg över perioden", link: "" });
      this.state.mna.push({ rpid: "tp", pos: "c1", text: "TIDPERIODSRAPPORT", desc: "Specifik period dag för dag", link: "" });
      this.state.mna.push({ rpid: "tips", pos: "c2", text: "TIPSRAPPORT", desc: "Sammanställd tips rapport för perioden", link: "" });
      this.state.mna.push({ rpid: "term", pos: "c2", text: "TERMINALRAPPORT", desc: "Fsg per terminal för perioden", link: "" });
      this.state.mna.push({ rpid: "hotel", pos: "c2", text: "HOTELLRAPPORT", desc: "Hotellöverföring för perioden", link: "" });
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 480;
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

    mnc(pos) {
        var mna = [];
        for(var row of this.state.mna) {
            if(row.pos == pos) mna.push( row );
        }
        return mna;
    }
  rp_open(typ) {
    var self = this;
    this.setState({ rptyp: typ });
  }

  render() {
    return (
        <div className="web-app">
            <div className="web-pg py-4 flex">
                <h3>Rapporter</h3>
                <p>Rapport funktioner</p>

                <div className="divider_green"></div>

        <div className="web-row">
            <div className="flex-col flex">
                { this.mnc("c1").map((row, key) =>
                    <div key={key} className="web-bx" onClick={() => this.rp_open(row.rpid) }>
                        <div>{ row.text }</div>
                        <div className="flex-row">
                            <div className="flex">{ row.desc }</div>
                            <div className="web-link">Välj</div>
                        </div>
                    </div>
                )}
            </div>
            <div className="flex-col flex">
                { this.mnc("c2").map((row, key) =>
                    <div key={key} className="web-bx" onClick={() => this.rp_open(row.rpid) }>
                        <div>{ row.text }</div>
                        <div className="flex-row">
                            <div className="flex">{ row.desc }</div>
                            <div className="web-link">Välj</div>
                        </div>
                    </div>
                )}
            </div>
        </div>

    </div>

        { this.state.rptyp == "fsg" ? <Fsg_dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "bet" ? <Bet_dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "hh" ? <HH_Dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "tp" ? <Tp_Dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "hotel" ? <Hotel_Dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "term" ? <Term_Dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "tips" ? <Tips_Dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
    </div>
  );
    }
}
export default observer(Pos_Red_Rps);
