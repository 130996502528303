import React, { useState, useEffect, useContext } from 'react';
import {withRouter} from "../../lib/react/withRouter";
import sso from "../../sso";

class Red_Home extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.pos = props.store.pos;
    this.state = { pgid: 11, tra: [] };
    this.state.seldate = new Date();
    this.state.avd = "01";
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

  fknGo(url) {
    //alert(row.href);
    var row = {};
    row.url = url;
    sso.seo.sfkn = row;
    this.props.navigate(url);
  }

  render() {
    return (
    <div className="web-app">
    <h3>Redovisnings System</h3>

    <div className="web-p01 flex">

    <div className="web-row">
    <div className="web-col flex">
        <div className="web-btn" onClick={() => this.fknGo("/pos/red/total") } >
            <div>TOTAL</div>
            <div>Summerad total fsg</div>
        </div>
        <div className="web-btn" onClick={() => this.fknGo("/pos/red/grp") } >
            <div>GRUPP</div>
            <div>Fsg per varugrupp</div>
        </div>
    </div>
     <div className="web-col flex" style={{padding: "0px 10px"}}>
        <div className="web-btn" onClick={() => this.fknGo("/pos/red/hh") } >
            <div>TIM</div>
            <div>Fsg timme för timme</div>
        </div>
        <div className="web-btn" onClick={() => this.fknGo("/pos/red/week") } >
            <div>VECKO</div>
            <div>Veckosammanställning</div>
        </div>
        <div className="web-btn" onClick={() => this.fknGo("/pos/red/pass") } >
            <div>PASS</div>
            <div>PASS avstämning</div>
        </div>
   </div>
     <div className="web-col flex">
        <div className="web-btn" onClick={() => this.fknGo("/pos/red/trs") } >
            <div>TRANSAKTIONER</div>
            <div>Transaktions flöde</div>
        </div>
        <div className="web-btn" onClick={() => this.fknGo("/pos/red/art") } >
            <div>ARTIKLAR</div>
            <div>Sammanställda artiklar</div>
        </div>
        <div className="web-btn" onClick={() => this.fknGo("/pos/red/drift") } >
            <div>DRIFT/LOGG</div>
            <div>Drift med loggar</div>
        </div>
   </div>
   </div>

    </div>

    </div>
  );
    }
}
export default withRouter(Red_Home);
