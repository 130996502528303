import React, { useState, useEffect, useContext } from 'react';
import * as fkn from "../../lib/fkn";
import * as net from "../../lib/net";

import Dt_Cal from "../../lib/ui/dt_cal";
import {format} from "date-fns";
import Lang from "../../lib/lang";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import * as utils from "../../assets/utils";

class Eko_Saldo extends React.Component {
  constructor(props, context) {
    super(props);

    this.dt_fd = React.createRef();
    this.dt_td = React.createRef();

    this.store = props.store;
    this.pms = props.store.pms;
    this.state = {};
    this.state.fkn = "";

    this.state.today = new Date();
    var dts = format(this.state.today, "yyyy-MM-dd");
    this.state.fdat = dts;
    this.state.tdat = dts;
      this.state.wnds = {};
      this.state.sortid = "";
      this.state.sortdir = 1;
      this.state.gsa = [];
      this.state.tot = {};
      if(this.pms.base) {
          this.state.fdat = this.pms.base.fdat;
          this.state.tdat = this.pms.base.tdat;
      }

    }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.trans();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 250;
      css.overflow = "auto";
      return css;
  }
    th_style(id) {
        var css = {};
        css.cursor = "pointer";
        if(id == this.state.sortid) {
            if(this.state.sortdir) css.color = "#800";
            else css.color = "#080";
        }
        return css;
    }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }
  dt_fdat(e) {
    //alert(JSON.stringify(e));
    var dts = format(e, "yyyy-MM-dd");
    this.setState({ fdat: dts });
  }
  dt_tdat(e) {
      //alert(JSON.stringify(e));
    var dts = format(e, "yyyy-MM-dd");
    this.setState({ tdat: dts });
  }
  oneday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_td.current.updateDt(this.state.fdat);
  }
  addday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_fd.current.updateAdd();
    this.dt_td.current.updateAdd();
  }
  subday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_fd.current.updateSub();
    this.dt_td.current.updateSub();
  }

  change_fkn(e) {
    //let cdat = this.state.seldate;
    //alert(JSON.stringify(e));
    let fkn = e.target.value;
    this.setState({fkn: fkn});
    this.setState({tra: [], tsa: [], tta: [] });
  }

  dt_saldo() {
    var self = this;
    //var datum = format(this.state.datum, "yyyy-MM-dd");
    var msg = { info: true, text: "Hämtar saldo ..."};
    self.setState({gsa: [], tot: {}, msg: msg });

    var prm = {};
    prm.req = "pms.eko_red.red_saldo";
    prm.fdat = this.state.fdat;
    prm.tdat = this.state.tdat;
    //alert(JSON.stringify(prm));

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.ok));

        if(ret.ok != "000") {
            return;
        }
        var gsa = ret.sdo.gsa;
        var tot = ret.tot;
        self.setState({gsa: gsa, tot: tot, msg: {} }, function () {
            self.syncTb("saldoTable");
        });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
    }
    
    syncTb(tb) {
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
            tdElements = tbel.getElementsByTagName('td');
        if(tdElements.length < 1 ) return;

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
                width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
    tb_sort(typ) {
        var self = this;
        //var rvs = self.state.rvs;
        var tra = self.state.tra;
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;

        if(sortid != typ) sortdir = 0;
        else {
            if(sortdir) sortdir = 0;
            else sortdir = 1;
        }

        if(sortdir) tra.sort((a,b) => (a[typ] < b[typ]) ? 1 : ((b[typ] < a[typ]) ? -1 : 0));
        else tra.sort((a,b) => (a[typ] > b[typ]) ? 1 : ((b[typ] > a[typ]) ? -1 : 0));

        self.setState({ tra: tra, sortid: typ, sortdir: sortdir });
    }
    th_icon(typ) {
        var cls = "";
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;
        if(sortid == typ)
        {
            if(sortdir) cls = "fa fa-caret-up";
            else cls = "fa fa-caret-down";
        }
        return cls;
    }

  render() {
    return (
    <div className="web-app">
    <div className="">
        SALDO
    </div>

    <div className="flex-row">
    <div className="flex-row">
              <div className="dt-btn-01" onClick={() => this.subday() } ><FontAwesomeIcon icon="minus" size="1x" /></div>
              <Dt_Cal dts={ this.state.fdat } rp="1" ref={this.dt_fd} getValue={(e)=> this.dt_fdat(e)} />
              <div className="dt-btn-01" onClick={() => this.oneday() } >1</div>
              <Dt_Cal dts={ this.state.tdat } rp="1" ref={this.dt_td} getValue={(e)=> this.dt_tdat(e)} />
              <div className="dt-btn-01" onClick={() => this.addday() } ><FontAwesomeIcon icon="plus" size="1x" /></div>
        </div>
        <div className="flex"></div>
        <div className="flex"></div>
        <button className="btn btn-hw-blue w150" onClick={() => this.dt_saldo() } aria-label="System" >
            Visa
        </button>
    </div>
    <div className="divider_green"></div>

    <div id="saldoTable">
          <table className="table table-bordered table-hover" style={{marginBottom: "0px"}}>
            <thead>
            <tr>
                <th style={ this.th_style("ankdat") } onClick={() => this.tb_sort("ankdat") }>Från - Till</th>
                <th style={ this.th_style("boknr") } onClick={() => this.tb_sort("boknr") }>Boknr</th>
                <th style={ this.th_style("rumsnr") } onClick={() => this.tb_sort("rumsnr") }>Rumsnr</th>
                <th style={ this.th_style("typ") } onClick={() => this.tb_sort("typ") }>Rumstyp</th>
                <th style={ this.th_style("name") } onClick={() => this.tb_sort("name") }>Namn</th>
                <th style={ this.th_style("nrg") } onClick={() => this.tb_sort("nrg") }>Gäster</th>
                <th style={{textAlign: "left"}}>Fsg</th>
                <th style={{textAlign: "left"}}>Bet</th>
                <th style={{textAlign: "left"}}>Saldo</th>
                <th style={{textAlign: "left"}}>Krubba</th>
            </tr>
            </thead>
            </table>
            <div style={ this.tb_style() }>
          <table className="table table-bordered table-hover">
            <tbody>
            { this.state.gsa.map((row, key) =>
            <tr key={key} onDoubleClick={() => this.gst_open(row) }>
                <td>{ utils.toDate( row.ankdat ) } - { utils.toDate( row.avrdat ) }</td>
                <td>{ row.boknr }/{ row.lopnr }</td>
                <td>{ utils.toRumsnr( row.rumsnr ) }</td>
                <td>{ row.typ }</td>
                <td>{ utils.toLang( row.name ) }</td>
                <td>{ row.nrg }</td>
                <td>{ utils.toPris( row.charges.fsg ) }</td>
                <td>{ utils.toPris( row.charges.bet ) }</td>
                <td>{ utils.toPris( row.charges.saldo ) }</td>
                <td>{ utils.toPris( row.charges.krubba ) }</td>
            </tr>
            )}
            </tbody>
        </table>
        </div>
    </div>
     <div className="flex-row">
         <div className="flex">TOTALER:</div>
         <div className="px-5">FSG: { utils.toPris(this.state.tot.fsg) }</div>
         <div className="px-5">BET: { utils.toPris(this.state.tot.bet) }</div>
         <div className="px-5">SALDO: { utils.toPris(this.state.tot.saldo) }</div>
         <div className="px-5">KRUBBA: { utils.toPris(this.state.tot.krubba) }</div>
     </div>

    </div>
  );
}
}
export default Eko_Saldo;
