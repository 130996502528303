import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../../lib/net";
import Fr_Input from "../../../lib/ui/fr_input";
import Fr_Cal from "../../../lib/ui/fr_cal";
import Wnd_Ok from "../../../lib/ui/wnd_ok";

class EE_Ut extends React.Component {
  constructor(props, context) {
    super(props);

    //this.ta = props.store.ta;
    this.state = {};
    this.state.ynq = [];
    this.state.sval = [];

    this.state.dao = {};
    this.state.aa = [
                {id: '01', text: 'AVD 01'},
                {id: '02', text: 'AVD 02'}
            ];
    this.state.cval = [
                {id: '001', text: 'ANTAL GER NYTT PRIS'},
                {id: '002', text: 'ANTAL GER RABATT PÅ PRIS'},
                {id: '003', text: 'PRISÄNDRING GENOM RABATT'}
            ];

    this.state.lro = {};
    this.state.msg = {};

      this.state.enr = "";
      if(props.wnds.enr) this.state.enr = props.wnds.enr;
      this.state.tro = {};
      this.state.tro.avd = "0001";
      this.state.tro.typ = "0002";

  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.cdi_init();
  }
    cdi_init() {
        var self = this;

        var lro = {};
        lro.typ = "R";		    //[1];          /* TYP = R LÖNEKÖR     */
        lro.lrid = "";	        //[5];          /* Lönekör ID           */
        lro.datum = "20210101";		    //[8];          /* DATUM Lönekörning    */
        lro.text = "";		    //[30];         /* TEXT / INFO          */
        lro.fdat = "20210101";		    //[8];          /* Från datum           */
        lro.tdat = "20210131";	        //[8];          /* Till datum           */
        lro.betdat = "20210125";	    //[8];          /* Utbetlnigsdag        */
        lro.status = "";	    //[1];          /* Status               */
        lro.sign = "";	        //[3];          /* Signatur             */
        lro.filler = "";		//[6];
        lro.ts = "";		    //[46];
        lro.updtime = "";		//[4];          /* updtime              */

        self.setState({lro: lro});
    }
    cdi_store() {
        var self = this;
   }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    tab_style(tabid) {
      var css = {};
      css.display = "none";
      if(this.state.tabval === tabid) css.display = "block";
      return css;
    }
    tab_head(tabid) {
      var css = {};
      css.background = "#fff";
      css.color = "#000";
      if(this.state.tabval === tabid) {
          css.background = "#080";
          css.color = "#fff";
      }
      return css;
    }
    grp_style(row) {
      var css = {};
      css.background = "#008";
      if(this.state.gnr === row.vgrupp) css.background = "#080";
      return css;
    }

    formchange (e) {
      const name = e.target.name;
      const value = e.target.value;
      var pbo = this.state.pbo;
      var na = name.split(".");
      pbo[na[0]][na[1]] = value;
      this.setState({pbo: pbo});
      //setState({[name]: value});
    }

    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    wnd_close = () => {
		this.props.ctx.setState({ wnds: {} });
	};
    handleChange = (event, newValue) => {
        //alert(newValue);
        this.setState({ix: newValue});
      };
    toggle() {
      this.setState({modal: false});
	};

  submitHandler = event => {
    event.preventDefault();
    event.target.className += " was-validated";
  };

  changeHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  };


  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  btn_ut() {
    var self = this;

    //this.cdi_store();

    if(false){
      //alert(JSON.stringify(this.state.lro));
        var msg = { info: false, ok: true, text: "Urloggad ..."};
        self.setState({msg: msg });
        setTimeout(function(){
            self.setState({msg: {} });
            //self.props.ctx.setState({ pluopen: false });
            self.props.ctx.ee_upd(self.props.ctx);
        }, 1000);
      return;
    }

      var tro = this.state.tro;
      tro.dts = this.state.dts;
      tro.enr = this.state.enr;
      //alert(JSON.stringify(tro));

      var prm = {};
      prm.req = "ta.ta_th.ee_tr_add";
      prm.tro = tro;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

		//self.props.ctx.setState({ pluhappy: false });
		self.props.ctx.setState({ lradd: false });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
    val_save (name, value) {
        //alert(JSON.stringify(name));
        //alert(JSON.stringify(value));
      var lro = this.state.lro;
      lro[name] = value;
      this.setState({lro: lro});
      //setState({[name]: value});
    }

  render() {
    var title = this.props.title;
    if(!title) title = "Knappar";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        <div className="m2_backdrop" />
        <div className="m2_dlg mx_70 flex-col" style={ this.dlg_style() } draggable="true" >
        <div className="mx_content flex">

            <div className="flex-row" style={{padding:"5px",height:"35px"}}>
                    <div className="flex-col flex">
                        <div>UTSTÄMPLING: { this.state.enr }</div>
                    </div>
                    <div className="flex-col">
                        <div>UPPDATERA</div>
                    </div>
            </div>
            <div className="divider_orange"></div>

            <form className="cdi_f03">
                <div className="form-row flex-row">
                    <Fr_Input label="Arbetspass" className="w350" text={this.state.lro.text} getValue={(e)=> this.val_save("text", e) } placeholder="" />
                </div>
                <div className="form-row flex-row">
                    <Fr_Input label="Namn" className="w350" text={this.state.lro.text} getValue={(e)=> this.val_save("text", e) } placeholder="" />
                </div>
                <div className="divider_green"></div>
                <div className="form-row flex-row">
                    <Fr_Input label="Från" className="text-center w250" text={this.state.lro.fdat} getValue={(e)=> this.val_save("fdat", e) } />
                    <Fr_Input label="Till" className="text-center w250" text={this.state.lro.tdat} getValue={(e)=> this.val_save("tdat", e) } />
                </div>
            </form>

        </div>
        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                AVBRYT
            </button>
            <button className="btn btn-hw-green w250" onClick={() => this.btn_ut() } >
                STÄMPLA UT
            </button>
        </div>
      </div>

        <Wnd_Ok msg={this.state.msg} ctx={this} />
      </div>
	;
	return html;
  };
}

export default EE_Ut;