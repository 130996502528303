import React, { useState, useEffect, useContext } from 'react';
import {format, getDate} from "date-fns";
import {Chart} from "chart.js";

import * as net from "../../lib/net";
import Dt_Cal from '../../lib/ui/dt_cal';
import ddo from "../../ddo";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Pos_Total extends React.Component {
  constructor(props, context) {
    super(props);

    this.my_bar = null;
    this.store = props.store;
    this.pos = props.store.pos;

    this.state = {};
    this.state.today = new Date();
    this.state.seldate = new Date();
    this.state.dts = "";
    this.state.avd = ddo.cache.avd;
    this.state.eka = [];
    //context.posrend = this;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.st_tot();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.position = "relative";
      css.height = window.innerHeight - 480;
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

  door() {
    var self = this;
    if(true) {
        //toast.success("Lorem ipsum dolor");
        return;
    }
    var prm = {};
    prm.req = "gym.access.force_door";
    //prm.avd = this.state.avd;

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        alert(JSON.stringify(ret));
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }

  st_tot() {
      var dtt = format(this.state.today, "yyyy-MM-dd");
      var dts = format(this.state.seldate, "yyyy-MM-dd");
      if(dtt == dts) {
          this.st_today();
      }
      else {
          this.st_dt_tot(dts);
      }
  }

  st_dt_tot(dts) {
    var self = this;
    var prm = {};
    prm.req = "pos.pos_eko.trh_tot";
    prm.avd = ddo.cache.avd;
    prm.fdat = dts;
    prm.tdat = dts;

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rco));

        self.setState({ eko: ret.rco }, () => self.bar_tot() );
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }
  st_today() {
    var self = this;
    var prm = {};
    prm.req = "pos.pos_eko.tr_tot";
    prm.avd = ddo.cache.avd;

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rco));

        self.setState({ eko: ret.rco }, () => self.bar_tot() );
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }
    bar_tot() {
      var lbls = [];
      var da = {};
      da.ink = [];
      da.ex = [];

      var eko = this.state.eko;
      if( eko.tot ) {
          lbls.push( "Totalt" );
          da.ink.push( eko.tot.belopp.cdipris() );
          da.ex.push( eko.tot.belopp.cdipris() );
      }

      //lbls.push( "AVD 02");
      //da.ink.push( "2500.00");
      //da.ex.push( "1750.00");

        if(this.my_bar) this.my_bar.destroy();
        this.my_bar =  new Chart(document.getElementById("barchart"), {
            type: 'bar',
            data: {
				labels: lbls,
                datasets: [{
                    data: da.ink,
                    label: 'Inklusive Moms',
                    backgroundColor: "#4755AB",
                    borderWidth: 1,
                }, {
                    data: da.ex,
                    label: 'Exklusive Moms',
                    backgroundColor: "#E7EDF6",
                    borderWidth: 1,
                }]
            },
            options: {
                responsive: true,
                legend: {
                    position: 'top',
                },
            }
        });
	};
    bar_chart() {
        if(this.my_bar) this.my_bar.destroy();
        this.my_bar =  new Chart(document.getElementById("barchart"), {
            type: 'bar',
            data: {
				labels: ['Januari', 'Februari', 'Mars', 'April', 'Maj', 'Juni', 'Juli', 'Augusti', 'September'],
                datasets: [{
                    data: [10,20,30,40,50,60,70,80],
                    label: 'Inklusive Moms',
                    backgroundColor: "#4755AB",
                    borderWidth: 1,
                }, {
                    data: [30,10,70,15,30,20,70,80],
                    label: 'Exklusive Moms',
                    backgroundColor: "#E7EDF6",
                    borderWidth: 1,
                }]
            },
            options: {
                responsive: true,
                legend: {
                    display: false,
                    position: 'top',
                },
            }
        });
	};

  render() {
    return (
    <div className="web-p00 flex flex-col">
        <div className="">
            Total
        </div>

    <div className="web-p01">
    <div className="flex-row">
        <Dt_Cal startdatum="2020-02-01" getValue={(e)=> this.setState({seldate: e}) }/>
        <select className="form-control w250 hide" placeholder="Välj avdelning">
            { this.pos.deps.map((row, key) =>
            <option key={key} value={row.id}>{row.text}</option>
            )}
        </select>
        <div className="flex"></div>

        <button className="btn btn-hw-blue w150 hide" onClick={() => this.door() } aria-label="System" >
            DOOR
        </button>
        <button className="btn btn-hw-blue w150" onClick={() => this.st_tot() } aria-label="System" >
            UPPDATERA
        </button>
    </div>
    <div className="divider_orange"></div>

    <div className="chart">
        <div className="row">
            <div className="col-lg-12 pr-lg-2 chart-grid">
                <div className="card text-center card_cdi">
                    <div className="card-header chart-grid__header">
                        Kostnadsställe Omsättning
                    </div>
                    <div className="card-body">
                        <div id="container">
                            <canvas id="barchart" height="100"></canvas>
                        </div>
                    </div>
                    <div className="card-footer text-muted chart-grid__footer">
                        Detaljer och djupdykning
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="web-row hide">
        <table className="table table-bordered table-hover tb fixed_header">
            <thead>
            <tr>
                <th>Grp</th>
                <th>Text</th>
                <th>Belopp</th>
                <th>Procent</th>
            </tr>
            </thead>
            <tbody style={ this.tb_style() }>
            { this.state.eka.map((row, key) =>
            <tr key={ key } onDoubleClick={ () => this.st_tot() }>
                <td>{ row.grp }</td>
                <td>{ row.text.toswe() }</td>
                <td>{ row.belopp.cdipris() }</td>
                <td>{ row.proc.dec2() }</td>
            </tr>
            )}
            </tbody>
        </table>
    </div>

    </div>
    </div>
  );
    }
}
export default Pos_Total;
