import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";

import Prs_Edit from "./prs/prs_xx.js";
import Td_Sel from "../../lib/ui/td_sel";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class EE_Dep extends React.Component {
    constructor(props, context) {
        super(props);

        this.webStore = props.store;
        this.state = { pgid: 11, tra: [] };
        this.state.seldate = new Date();
        this.state.avd = "01";
        this.state.eea = [];
        this.state.eeo = {};
        this.state.aa = [
            {id: '01', text: 'AVD 01'},
            {id: '02', text: 'AVD 02'}
        ];
        this.state.cols = {};
        this.state.sortid = "";
        this.state.sortdir = 1;

        this.selitems = [
            {fkn: 'open', text: 'ÖPPNA'},
            {fkn: 'finished', text: 'SLUTAT'}
        ];
    }
    componentDidUpdate() {
    }
    componentWillUnmount() {
    }
    componentDidMount() {
        this.ee();
    }

    body_style() {
        var css = {};
        css.height = window.innerHeight - 280;
        return css;
    }
    tb_style() {
        var css = {};
        css.height = window.innerHeight - 275;
        css.overflow = "auto";
        return css;
    }
    th_style(id) {
        var css = {};
        css.cursor = "pointer";
        if(id == this.state.sortid) {
            if(this.state.sortdir) css.color = "#800";
            else css.color = "#080";
        }
        return css;
    }
    onUnitChange(e) {
        this.setState({unit: e.value});
    }

    prs_open(row) {
        var self = this;
    }
    prs_edit(row) {
        var self = this;
        //alert(JSON.stringify(row.krr00));
        this.setState({edit: 1, eeo: row});
    }
    fkn_close() {
        this.setState({edit: false });
        //this.props.history.push("cas");
        //this.login_srv(uid, pwd);
    }

    ee() {
        var self = this;
        var prm = {};
        prm.req = "ta.ta_ee.eea";
        prm.slutat = "1";
        //prm.avd = this.state.avd;
        //prm.fplu = datum;
        //prm.tplu = datum;

        //gda.wndLoading("Hämtar");
        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.rca));

                self.setState({eea: ret.rca });
                self.syncTb("eeTable");
                //toastr.success("OK", "SAPI");
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
                //gda.wndError(txt);
            });
    }
    syncTb(tb) {
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
            tdElements = tbel.getElementsByTagName('td');
        if(tdElements.length < 1 ) return;

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
                width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
    tb_sort(typ) {
        var self = this;
        //var rvs = self.state.rvs;
        var eea = self.state.eea;
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;

        if(sortid != typ) sortdir = 0;
        else {
            if(sortdir) sortdir = 0;
            else sortdir = 1;
        }

        if(typ == "p1.anstnummer") {
            if(sortdir) eea.sort((a,b) => (a.p1.anstnummer < b.p1.anstnummer) ? 1 : ((b.p1.anstnummer < a.p1.anstnummer) ? -1 : 0));
            else eea.sort((a,b) => (a.p1.anstnummer > b.p1.anstnummer) ? 1 : ((b.p1.anstnummer > a.p1.anstnummer) ? -1 : 0));
        }
        if(typ == "p1.namn") {
            if(sortdir) eea.sort((a,b) => (a.p1.namn < b.p1.namn) ? 1 : ((b.p1.namn < a.p1.namn) ? -1 : 0));
            else eea.sort((a,b) => (a.p1.namn > b.p1.namn) ? 1 : ((b.p1.namn > a.p1.namn) ? -1 : 0));
        }
        if(typ == "p1.fodnummer") {
            if(sortdir) eea.sort((a,b) => (a.p1.fodnummer < b.p1.fodnummer) ? 1 : ((b.p1.fodnummer < a.p1.fodnummer) ? -1 : 0));
            else eea.sort((a,b) => (a.p1.fodnummer > b.p1.fodnummer) ? 1 : ((b.p1.fodnummer > a.p1.fodnummer) ? -1 : 0));
        }
        self.setState({ eea: eea, sortid: typ, sortdir: sortdir });
    }
    th_icon(typ) {
        var cls = "";
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;
        if(sortid == typ)
        {
            if(sortdir) cls = "fa fa-caret-up";
            else cls = "fa fa-caret-down";
        }
        return cls;
    }

    render() {
        return (
            <div className="web-app">

                <div className="flex-row flex-space-between">
                    <div className="label_h03">Personal Avdelning</div>
                    <button className="btn btn-hw-blue w150" onClick={() => this.ee() } aria-label="System" >
                        UPPDATERA
                    </button>
                </div>
                <div className="divider"></div>

                <div className="web-col">
                    <div id="eeTable" className="flex">
                        <table className="table table-bordered table-hover tb " style={{marginBottom: "0px"}}>
                            <thead>
                            <tr>
                                <th style={ this.th_style("p1.anstnummer") } onClick={() => this.tb_sort("p1.anstnummer") }>Anstnr <i className={ this.th_icon("p1.anstnummer") }></i></th>
                                <th style={ this.th_style("p2.initialer") } onClick={() => this.tb_sort("p2.initialer") }>Initialer <i className={ this.th_icon("p1.initialer") }></i></th>
                                <th style={ this.th_style("p1.namn") } onClick={() => this.tb_sort("p1.namn") }>Namn <i className={ this.th_icon("p1.namn") }></i></th>
                                <th style={ this.th_style("p1.fodnummer") } onClick={() => this.tb_sort("p1.fodnummer") }>Personnr <i className={ this.th_icon("p1.fodnummer") }></i></th>
                                <th style={ this.th_style("p1.avdelning") } onClick={() => this.tb_sort("p1.avdelning") }>Avdelning <i className={ this.th_icon("p1.avdelning") }></i></th>
                                <th style={ this.th_style("p1.arbschema") } onClick={() => this.tb_sort("p1.arbschema") }>Schema <i className={ this.th_icon("p1.arbschema") }></i></th>
                                <th style={ this.th_style("p2.startdat") } onClick={() => this.tb_sort("p2.startdat") }>Start <i className={ this.th_icon("p1.startdat") }></i></th>
                                <th style={ this.th_style("p2.prssdat") } onClick={() => this.tb_sort("p2.prssdat") }>Slut <i className={ this.th_icon("p1.prssdat") }></i></th>
                                <th style={ this.th_style("status") } onClick={() => this.tb_sort("status") }>Upd <i className={ this.th_icon("status") }></i></th>
                                <th></th>
                            </tr>
                            </thead>
                        </table>
                        <div className="tb-cdi" style={ this.tb_style() }>
                            <table className="table table-bordered table-hover tb">
                                <tbody>
                                { this.state.eea.map((row, key) =>
                                    <tr key={key} onDoubleClick={() => this.prs_edit(row) }>
                                        <td>{ row.p1.anstnummer }</td>
                                        <td>{ row.p2.initialer }</td>
                                        <td>{ row.p1.namn }</td>
                                        <td>{ row.p1.fodnummer }</td>
                                        <td>{ row.p1.avdelning }</td>
                                        <td>{ row.p1.arbschema }</td>
                                        <td>{ row.p2.startdat }</td>
                                        <td>{ row.p2.prssdat }</td>
                                        <td>{ row.status }</td>
                                        <td>
                                            <Td_Sel items={ this.selitems } getValue={(e)=> this.sel_row_fkn(row, e)} />
                                        </td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <Prs_Edit show={this.state.edit} ctx={this} eeo={this.state.eeo} backdrop={true}/>
            </div>
        );
    }
}
export default EE_Dep;
