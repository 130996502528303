import React, { useState, useEffect, useContext } from 'react';
import {Route, Routes} from "react-router-dom";
import {withRouter} from "../lib/react/withRouter";
import { observer } from 'mobx-react'

import Lager_Home from './lager/home.js';
import Lager_Levs from './lager/levs.js';
import Lager_Avd from './lager/avd.js';
import Lager_Art from './lager/art.js';
import Lager_Rcpt from './lager/rcpt.js';
import Lager_Srt from './lager/srt.js';
import Stat_Home from "./stat/home";
import sso from "../sso";
//import Lager_Tbv from './lager/tableview.js';

function pg_home() {

    var html =
    <div className="web-content">
      Hem
    </div>
    ;
    return html;
}

class Pos_Lager extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.state = {};
    //context.posrend = this;
      var po = {};
      po.levs = { "fid": "f06", "url": "/pos/lager/levs", "name": "Leverantörer", "active": false };
      po.avd = { "fid": "f06", "url": "/pos/lager/avd", "name": "Avdelningar", "active": false };
      po.art = { "fid": "f06", "url": "/pos/lager/art", "name": "Artiklar", "active": false };
      po.inv = { "fid": "f06", "url": "/pos/lager/inv", "name": "Inventering", "active": false };
      po.drift = { "fid": "f06", "url": "/pos/lager/drift", "name": "Drift", "active": false };
      po.lev = { "fid": "f06", "url": "/pos/lager/lev", "name": "Leverans", "active": false };
      po.res = { "fid": "f06", "url": "/pos/lager/res", "name": "Resultatet", "active": false };
      po.list = { "fid": "f06", "url": "/pos/lager/list", "name": "Listor", "active": false };
      this.state.po = po;
      this.state.pa = [];
      this.state.pa = Object.values(po);
  }
    componentDidUpdate() {
    }
    componentWillUnmount() {
    }
    componentDidMount() {
      /*
        var path = window.location.pathname;
        if(path.substr(-1) == "/") path = path.substring(0, path.length - 1);
        var op = path.split("/").pop();

        var po = this.state.po;
        if(po[op]) {
            po[op].active = true;
            //this.props.store.fkn = po[op];
            sbo.state.fkn = po[op].name;
            //alert(JSON.stringify(sbo.state));
        }
        var pa = Object.values(po);
        this.setState({ po: po, pa: pa });

       */
    }
    fknGo(row) {
        sso.seo.sfkn = row;
        this.props.navigate(row.url);
    }
    br_css(row) {
        var css = {};
        if(sso.seo.sfkn && (row.url == sso.seo.sfkn.url)) css.color = "#080";
        //var brc = false;
        //if(row.url == sbo.state.url) brc = true;
        return css;
    }

    render() {
        return (
            <div className="web-app">
                { this.props.store.cfg.bread ?
                    <div className="web-bread">
                        <div className="breadcrumb">
                            { this.state.pa.map((row, key) =>
                                <div key={key} className="breadcrumb-item" style={this.br_css(row)} onClick={() => this.fknGo(row)}>{row.name}</div>
                            )}
                            <div className="flex"></div>
                            <div className="cdigreen">{ sso.sdo.sfkn ? sso.sdo.sfkn.name : null }</div>
                        </div>
                    </div>
                    : null }
        <Routes>
          <Route path="/xx" component={pg_home} />
            <Route path="levs" element={ <Lager_Levs store={this.store} />} />
            <Route path="avd" element={ <Lager_Avd store={this.store} />} />
            <Route path="art" element={ <Lager_Art store={this.store} />} />
            <Route path="rcpt" element={ <Lager_Rcpt store={this.store} />} />
            <Route path="srt" element={ <Lager_Srt store={this.store} />} />
            <Route path="*" element={ <Lager_Home store={this.store} />} />
        </Routes>
    </div>
  );
}
}
//Sys.contextType = ADOContext;

export default withRouter(observer(Pos_Lager));
