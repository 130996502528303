import React, { useState, useEffect, useContext } from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";

import Ta_Top from './com/ta_top.js';
import Ta_Mnu from './com/ta_mnu.js';
import Ta_Dash from './dash.js';
import Ta_Home from './home.js';
import Ta_Ee from './ee.js';
import Ta_Shd from './shd.js';
import Ta_Pass from './pass.js';
import Ta_Th from './th.js';
import Ta_Calc from './calc.js';
import Ta_Rps from './rps.js';
import Ta_Bo from './bo.js';
import Base_Conf from "./lib/base_conf";

import UI_Tiden from '../com/ui/tiden.js';

import * as srs from "../lib/srs";
import * as net from "../lib/net";
import {observer} from "mobx-react";
import Tiden from "../lib/ui/tiden";
import {format} from "date-fns";
import {withRouter} from "../lib/react/withRouter";
import Bo_Home from "./bo/home";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function pg_home() {

    var html =
    <div className="web-content">
      Hem
    </div>
    ;
    return html;
}
function pg_s1() {

    var html =
    <div className="">
      S1
    </div>
    ;
    return html;
}
function pg_s2() {
    var units = [];
    units.push( {"id": "01", "unit": "u01", "text": "Tst 01"} );
    units.push( {"id": "02", "unit": "u02", "text": "Tst 02"} );

    var html =
    <div className="">
        <table className="table table-bordered table-striped table-hover">
            <thead>
            <tr>
                <th>ID</th>
                <th>Enhet</th>
                <th>Text</th>
                <th>Adress</th>
                <th>Postnr</th>
                <th>Stad</th>
                <th>Tel</th>
                <th>Öppet</th>
                <th>Status</th>
            </tr>
            </thead>
            <tbody>
            { units.map((row, key) =>
                <tr ondblclick={() => this.unit_open(row)}>
                <td>{ row.id }</td>
                <td>{ row.unit }</td>
                <td>{ row.text }</td>
                <td>{ row.adress }</td>
                <td>{ row.postnr }</td>
                <td>{ row.stad }</td>
                <td>{ row.tele }</td>
                <td>{ row.open }</td>
                <td>{ row.status }</td>
                </tr>
            )}
            </tbody>
        </table>
    </div>
    ;
    return html;
}
function pg_trans() {
    var units = [];
    units.push( {"id": "01", "unit": "u01", "text": "Tst 01"} );
    units.push( {"id": "02", "unit": "u02", "text": "Tst 02"} );

    var html =
    <div className="">
        <table className="table table-bordered table-striped table-hover">
            <thead>
            <tr>
                <th>ID</th>
                <th>Enhet</th>
                <th>Text</th>
                <th>Adress</th>
                <th>Postnr</th>
                <th>Stad</th>
                <th>Tel</th>
                <th>Öppet</th>
                <th>Status</th>
            </tr>
            </thead>
            <tbody>
            { units.map((row, key) =>
                <tr ondblclick={() => this.unit_open(row)}>
                <td>{ row.id }</td>
                <td>{ row.unit }</td>
                <td>{ row.text }</td>
                <td>{ row.adress }</td>
                <td>{ row.postnr }</td>
                <td>{ row.stad }</td>
                <td>{ row.tele }</td>
                <td>{ row.open }</td>
                <td>{ row.status }</td>
                </tr>
            )}
            </tbody>
        </table>
    </div>
    ;
    return html;
}

function web01() {

    var html =
    <div className="web-content">
    </div>
    ;
    return html;
}

class Ta extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

      var ta = {};
      ta.dpa = [];
      ta.stat = {};
      ta.base = {};
      ta.base.today = new Date();
      ta.base.fdat = format(ta.base.today, "yyyy-MM-dd");
      ta.base.tdat = format(ta.base.today, "yyyy-MM-dd");
      //pms.base.fdat = "2021-04-01";
      //pms.base.tdat = "2021-04-04";
      props.store.ta = ta;

      this.store = props.store;
      this.ta = props.store.ta;

    this.state = {};
    this.state.deps = [];
    this.state.wnds = {};
  }
    componentDidUpdate() {
    }
    componentWillUnmount() {
    }
    componentDidMount() {
       this.ta_init();
    }

    ta_init() {
        var self = this;

        var req = {};
        req.req = "ta.ta_def.ta_init";

        net.sio_req(req)
            .then(function(ret){
                //alert(JSON.stringify(ret));

                if(!self.props.store.ta) self.props.store.ta = {};
                self.props.store.ta.dpa = ret.dpa;
                //self.props.store.ta.eea = ret.rco.eea;

                //self.setState({ deps: ret.rco });
                //self.st_state();
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
                //alert(JSON.stringify(txt));
            })
    }
    fknGo(url) {
        this.props.navigate(url);
    };
  udid_req() {
        var udid = "TT001920";
        var self = this;
        var req = {};
        req.req = "emv.print";
        req.udid = udid;
        req.fkn = "PRINT";

        var prm = {};
        req.prm = prm;

        //gda.wndLoading("Hämtar");
        srs.udid_req(req)
        .then(function(ret){
            //alert(JSON.stringify(ret.rco));
            //gda.wndClose();
            //var txt = JSON.stringify(data);
            //gda.wndSuccess(txt);
            //deps_list(ret.rco)

            alert(JSON.stringify(ret.rco));
            self.setState({ deps: ret.rco });
            //toastr.success("OK", "SAPI");
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
            //gda.wndError(txt);
        })
    }
    settle() {
        var udid = "TT001920";
        var self = this;
        var req = {};
        req.req = "emv.settle";
        req.udid = udid;
        req.fkn = "SETTLE";

        var prm = {};
        req.prm = prm;

        srs.udid_req(req)
        .then(function(ret){

            alert(JSON.stringify(ret.rco));
            self.setState({ deps: ret.rco });
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        })
    }
    base_period() {
        var wnds = {};
        wnds.baseconf = true;
        //wnds.tro = row;
        this.setState({ wnds: wnds });
    }

  render() {
    return (
    <div className="web-app">
    <div className="web-top">
        <Ta_Top store={this.store} />
    </div>
    <div className="web-main">

    <div className="web-row" style={{ height:"100%" }}>

    { this.props.store.cfg.mnu_show ?
    <div className="web-side">
        <Ta_Mnu store={this.store} />
    </div>
    : null }

    <div className="web-body">
        <Routes>
          <Route path="/xx" component={pg_s1} />
          <Route path="/ta/yy" component={pg_s2} />
            <Route path="ee/*" element={ <Ta_Ee store={this.store} />} />
            <Route path="shd/*" element={ <Ta_Shd store={this.store} />} />
            <Route path="pass/*" element={ <Ta_Pass store={this.store} />} />
            <Route path="th/*" element={ <Ta_Th store={this.store} />} />
            <Route path="calc/*" element={ <Ta_Calc store={this.store} />} />
            <Route path="rps/*" element={ <Ta_Rps store={this.store} />} />
            <Route path="bo/*" element={ <Ta_Bo store={this.store} />} />
            <Route path="dash" element={ <Ta_Dash store={this.store} />} />
            <Route path="home" element={ <Ta_Home store={this.store} />} />
            <Route path="*" element={ <Ta_Home store={this.store} />} />
        </Routes>
    </div>

    </div>

    </div>
    <div className="web-foot">
        <div className="web-foot-mnu" onClick={() => this.ddo() }>
            <FontAwesomeIcon icon="bars" size="1x"/>
        </div>
        <div className="flex flex-row">
            <div className="web-foot-info px-1" onClick={() => this.base_period() } title="Period">
                <div className="txt_12">Bas period</div>
                <div className="flex-row" >
                    <div className="txt_12">{ this.ta.base.fdat }</div>
                    <div className="txt_12 px-1">-</div>
                    <div className="txt_12">{ this.ta.base.tdat }</div>
                </div>
            </div>
            <div className="flex"></div>

            <div className="web-foot-btn" onClick={() => this.fknGo("/ta/ee/prs") } >PERSONAL</div>
            <div className="web-foot-btn" onClick={() => this.fknGo("/ta/pass") } >ARBETSPASS</div>
        </div>
        <div className="web-foot-icon">
          <FontAwesomeIcon icon="ellipsis-v" size="1x"/>
        </div>
        <div className="flex flex-row">
            <div className="web-foot-btn" onClick={() => this.fknGo("/ta/th/per") }>PERIOD TIDER</div>
            <div className="web-foot-btn" onClick={() => this.fknGo("/ta/calc/tot") }>TOTAL KALKYL</div>

            <div className="flex"></div>
            <Tiden />
        </div>
    </div>
        { this.state.wnds.baseconf ? <Base_Conf wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }

    </div>
  );
}
}
//Sys.contextType = ADOContext;
export default withRouter(observer(Ta));
