import React, { useState, useEffect, useContext } from 'react';
import {Route, Routes} from "react-router-dom";
import {withRouter} from "../lib/react/withRouter";
import { observer } from 'mobx-react'

import Prds_Home from './prds/home.js';
import Prds_Struktur from './prds/struktur.js';
import Prds_Plus from './prds/plus.js';
import Prds_Menus from './prds/menus.js';
import Prds_Ingred from './prds/ingred.js';
import Prds_Btns from './prds/btns.js';
import Prds_Addons from './prds/addons.js';
import Prds_Cond from './prds/cond.js';
import Prds_Psel from './prds/psel.js';
import Prds_Steps from './prds/steps.js';
import Prds_Happy from './prds/happy.js';
import Prds_Pt from './prds/pt.js';
import Stat_Home from "./stat/home";
import sso from "../sso";

function pg_home() {

    var html =
    <div className="web-content">
      Hem
    </div>
    ;
    return html;
}

class Pos_Prds extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.state = { pgid: 11, deps: [] };
    //context.posrend = this;
      var po = {};
      po.str = { "fid": "f03", "url": "/pos/prds/str", "name": "Strukturer", "active": false };
      po.plus = { "fid": "f03", "url": "/pos/prds/plus", "name": "Produkter", "active": false };
      po.menus = { "fid": "f03", "url": "/pos/prds/menus", "name": "Menyer", "active": false };
      po.ingred = { "fid": "f03", "url": "/pos/prds/ingred", "name": "Ingredienser", "active": false };
      po.btns = { "fid": "f03", "url": "/pos/prds/btns", "name": "Knappar", "active": false };
      po.addons = { "fid": "f03", "url": "/pos/prds/addons", "name": "Tillval", "active": false };
      po.cond = { "fid": "f03", "url": "/pos/prds/cond", "name": "Villkor", "active": false };
      //po.psel = { "fid": "f03", "url": "/pos/prds/psel", "name": "Produktval", "active": false };
      po.steps = { "fid": "f03", "url": "/pos/prds/steps", "name": "Stegval", "active": false };
      po.happy = { "fid": "f03", "url": "/pos/prds/happy", "name": "Happyhour", "active": false };
      //po.pt = { "fid": "f03", "url": "/pos/prds/pt", "name": "Pristabell", "active": false };
      this.state.po = po;
      this.state.pa = [];
      this.state.pa = Object.values(po);
  }
    componentDidUpdate() {
    }
    componentWillUnmount() {
    }
    componentDidMount() {
      /*
        var path = window.location.pathname;
        if(path.substr(-1) == "/") path = path.substring(0, path.length - 1);
        var op = path.split("/").pop();

        var po = this.state.po;
        if(po[op]) {
            po[op].active = true;
            //this.props.store.fkn = po[op];
            sbo.state.fkn = po[op].name;
            //alert(JSON.stringify(sbo.state));
        }
        var pa = Object.values(po);
        this.setState({ po: po, pa: pa });

       */
    }
    fknGo(row) {
        sso.seo.sfkn = row;
        this.props.navigate(row.url);
    }
    br_css(row) {
        var css = {};
        if(sso.seo.sfkn && (row.url == sso.seo.sfkn.url)) css.color = "#080";
        //var brc = false;
        //if(row.url == sbo.state.url) brc = true;
        return css;
    }

    render() {
        return (
            <div className="web-app">
                { this.props.store.cfg.bread ?
                    <div className="web-bread">
                        <div className="breadcrumb">
                            { this.state.pa.map((row, key) =>
                                <div key={key} className="breadcrumb-item" style={this.br_css(row)} onClick={() => this.fknGo(row)}>{row.name}</div>
                            )}
                            <div className="flex"></div>
                            <div className="cdigreen">{ sso.sdo.sfkn ? sso.sdo.sfkn.name : null }</div>
                        </div>
                    </div>
                    : null }
        <Routes>
          <Route path="/xx" component={pg_home} />
            <Route path="str" element={ <Prds_Struktur store={this.store} />} />
            <Route path="plus" element={ <Prds_Plus store={this.store} />} />
            <Route path="menus" element={ <Prds_Menus store={this.store} />} />
            <Route path="ingred" element={ <Prds_Ingred store={this.store} />} />
            <Route path="btns" element={ <Prds_Btns store={this.store} />} />
            <Route path="addons" element={ <Prds_Addons store={this.store} />} />
            <Route path="cond" element={ <Prds_Cond store={this.store} />} />
            <Route path="psel" element={ <Prds_Psel store={this.store} />} />
            <Route path="steps" element={ <Prds_Steps store={this.store} />} />
            <Route path="happy" element={ <Prds_Happy store={this.store} />} />
            <Route path="pt" element={ <Prds_Pt store={this.store} />} />
            <Route path="*" element={ <Prds_Home store={this.store} />} />
        </Routes>
    </div>
  );
}
}
//Sys.contextType = ADOContext;

export default withRouter(observer(Pos_Prds));
