import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";

import Ank_List from "./list/ank_list";
import Avr_List from "./list/avr_list";
import Gst_List from "./list/gst_list";
import Stad_List from "./list/stad_list";
import PM_List from "./list/pm_list";
import Anm_List from "./list/anm_list";
import Bevak_List from "./list/bevak_list";
import Bel_List from "./list/bel_list";
import Mbel_List from "./list/mbel_list";
import Hk_List from "./list/hk_list";
import Fruk_List from "./list/fruk_list";
import Sec_List from "./list/sec_list";
import Rvs_List from "./list/rvs_list";
import Rvper_List from "./list/rvper_list";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Pms_List extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, tra: [] };
    this.state.seldate = new Date();
    this.state.avd = "01";
    this.state.paa = [];
    this.state.aa = [];
    //context.posrend = this;
    this.state.pdf = 0;
    this.state.rptyp = "";
    this.state.mr = 0;
    this.state.bok = 0;
    this.state.xrecov = 0;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.plus();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 480;
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

  rp_open(typ) {
    var self = this;
    this.setState({ rptyp: typ });
  }
    pdf_show(id) {
        this.setState({ pdf: id });
        return;
    }

  plus() {
    var self = this;
    var prm = {};
    prm.req = "pos.pos_plu.plus";
    prm.avd = this.state.avd;
    //prm.fplu = datum;
    //prm.tplu = datum;

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        var o = {};
        //o.deps = ret.rco;
        //alert(JSON.stringify(o));
        self.setState({paa: ret.rco });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }

  render() {
    return (
    <div className="web-p00 flex flex-col">
        <div className="">
            HOTELL LISTOR
        </div>

    <div className="web-p01 flex">

    <div className="web-row">
    <div className="web-col flex">
        <div className="web-btn" onClick={() => this.rp_open("ank") } >
            <div>ANKOMSTLISTA</div>
            <div>Periodens ankomster</div>
        </div>
        <div className="web-btn" onClick={() => this.rp_open("avr") } >
            <div>AVRESELISTA</div>
            <div>Periodens avresor</div>
        </div>
        <div className="web-btn" onClick={() => this.rp_open("gst") } >
            <div>GÄSTLISTA</div>
            <div>Periodens gäster</div>
        </div>
        <div className="web-btn" onClick={() => this.rp_open("stad") } >
            <div>STÄDLISTA</div>
            <div>Aktuell städlista</div>
        </div>
        <div className="web-btn" onClick={() => this.rp_open("pm") } >
            <div>PM LISTA</div>
            <div>Periodens PM</div>
        </div>
    </div>
     <div className="web-col flex" style={{padding: "0px 10px"}}>
        <div className="web-btn" onClick={() => this.rp_open("anm") } >
            <div>ANMÄRKNINGSLISTA</div>
            <div>Periodens anmärkningar</div>
        </div>
        <div className="web-btn" onClick={() => this.rp_open("bevak") } >
            <div>BEVAKNINGSLISTA</div>
            <div>Periodens bevakningar</div>
        </div>
        <div className="web-btn" onClick={() => this.rp_open("bel") } >
            <div>BELÄGGNINGSLISTA</div>
            <div>Beläggning för perioden</div>
        </div>
        <div className="web-btn" onClick={() => this.rp_open("mbel") } >
            <div>MÅNADS BELÄGGNING</div>
            <div>Summeradbeläggning för perioden</div>
        </div>
   </div>
     <div className="web-col flex">
        <div className="web-btn" onClick={() => this.rp_open("hk") } >
            <div>HOUSEKEEPING RÖRELSER</div>
            <div>Städbehov över period</div>
        </div>
        <div className="web-btn" onClick={() => this.rp_open("fruk") } >
            <div>FRUKOSTLISTA</div>
            <div>Periodens frukostlista</div>
        </div>
        <div className="web-btn" onClick={() => this.rp_open("sec") } >
            <div>SÄKERHETSLISTA</div>
            <div>Aktuell säkerhetslista</div>
        </div>
        <div className="web-btn" onClick={() => this.rp_open("rvs") } >
            <div>BOKNINGSLISTA</div>
            <div>boknings sammanställning</div>
        </div>
        <div className="web-btn" onClick={() => this.rp_open("rvper") } >
            <div>BOKNINGAR PERIOD</div>
            <div>Bokningar för perioden</div>
        </div>
   </div>
   </div>

    </div>

        { this.state.rptyp == "ank" ? <Ank_List show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "avr" ? <Avr_List show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "gst" ? <Gst_List show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "stad" ? <Stad_List show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "pm" ? <PM_List show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "anm" ? <Anm_List show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "bevak" ? <Bevak_List show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "bel" ? <Bel_List show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "mbel" ? <Mbel_List show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "hk" ? <Hk_List show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "fruk" ? <Fruk_List show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "sec" ? <Sec_List show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "rvs" ? <Rvs_List show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "rvper" ? <Rvper_List show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
    </div>
  );
    }
}
export default Pms_List;
