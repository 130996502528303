import React, { useState, useEffect, useContext } from 'react';
import {withRouter} from "../lib/react/withRouter";
import sso from "../sso";

//import './css/style-liberty.css';
import Tiden from "../lib/ui/tiden";

import * as net from "../lib/net";
import Pos_Top from "../pos/com/pos_top";
import Dd_Sel from "../lib/ui/dd_sel";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function web01() {

    var html =
    <div className="">
        Test
    </div>
    ;
    return html;
}

class Logout extends React.Component {
    //var sdo = useContext(SDOContext); // Functional
    //static contextType = SDOContext; // Class

    constructor(props, context) {
        super(props);

        this.s1ref = React.createRef();
        this.s2ref = React.createRef();

        this.store = props.store;
        this.seo = props.store.seo;

        this.state = { pgid: 11, stat: [] };
        this.state.open = false;
        this.state.pdfopen = false;
        this.state.xxopen = false;
        this.state.pdf_uri = "";
        this.state.seldate = new Date('2020-03-24T13:11:54');
        this.state.row = null;
        this.state.info = "";
        this.state.top = 1;
        this.state.user = {};
        this.state.sdo = {};
        //this.state.sdo = context;
        //context.posrend = this;
        this.state.notify = false;
        this.state.step = 1;
        this.state.pdf = 0;
        this.state.pdf_uri = "";
        this.state.mnus = ["m00", "m01", "m02", "m03", "m08", "m09"];
        this.state.mnu_sel = "m00";
        this.selitems = [
            {fkn: 'setup', text: 'INSTÄLLNINGAR'},
            {fkn: 'upd', text: 'UPPDATERA'},
            {fkn: 'sys', text: 'SYSTEM'},
            {fkn: 'logout', text: 'LOGGA UT'}
        ];

    }
    componentDidMount(){
        this.xlogout();
/*
        var self = this;
        setTimeout(function(){
            var url = "/";
            self.props.history.push(url);
            self.props.history.go(0);
        }, 3000);
*/
    }

    componentWillUnmount(){
        //this.setState({info: "UUU" });
    }
    logo(){
        var logo = null;
        if(this.store.cfg.logo == "cdi") logo = require('../img/logo-cdi.png');
        else logo = require('../img/logo-sk.png');
        return logo;
    }
   header_css() {
  	    var css = {};
		if(this.state.top) {
			css.color = "#fff";
			css.background = "transparent";
			css.height = "50px";
			//css["background"] = "green";
		}
		else {
			css.color = "#000";
			css.background = "#fff";
		    css.height = "50px";
        }

		return css;
    }
    header_class() {
  	    var cls = "flex_col";
		if(this.state.top) {
			cls = cls + " web-head_1";
		}
		else cls = cls +  " web-header";

		return cls;
    }

    row_css(row) {
  	    var css = {};
		if(this.state.row) {
		if(this.state.row.ank == row.ank) {
			css.background = "#080";
			css.color = "#fff";
		}
		}
		return css;
    }
    login() {
        this.setState({login: true });
        //this.props.history.push("pos");
    }
    slogout(self) {
        var xdo = sessionStorage.getItem("svp_sso");
		//var sdo = JSON.parse(xdo);

		var prm = {};
        prm.req = "usr:logout";
		prm.uid = sso.seo.uid;
		prm.token = sso.seo.token;
        sessionStorage.removeItem("svp_sso");
        sso.seo = {};

		//alert(JSON.stringify(prm));
        net.gcs_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret));

            if(ret.ok != "000") {
                alert(JSON.stringify(ret));
                //return;
            }

            var url = "/";
            self.props.navigate(url);
            //self.props.history.go(0);
        })
        .catch(function(e){
            //var txt = JSON.stringify(e);
            var url = "/";
            self.props.navigate(url);
        });
      };
    logout() {
        var self = this;
            var url = "/";
            //self.props.navigate(url);
            //self.props.navigate(0);
            //self.props.history.go(0);
        window.location.reload();
     };
    xlogout() {
        var self = this;
        //var xdo = sessionStorage.getItem("svp_sso");
		//var sdo = JSON.parse(xdo);

		var prm = {};
        prm.req = "usr:logout";
		prm.uid = sso.seo.uid;
		prm.token = sso.seo.token;

        sessionStorage.removeItem("svp_sso");
        sso.seo = {};

		//alert(JSON.stringify(prm));
        net.gcs_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret));

            if(ret.ok != "000") {
                alert(JSON.stringify(ret));
                //return;
            }

        })
        .catch(function(e){
            //var txt = JSON.stringify(e);
            var url = "/";
            self.props.navigate(url);
            //alert("LOGOUT ERR: " + JSON.stringify(e));
            //gda.wndError(txt);
        });
      };
    notifyShow() {
        //toast.info('<MDBIcon far icon="user" size="1x"/> Info message');
        //toast.success('Info message', {
        //    closeButton: false
        //  });
        //alert(this.state.notify);
        //this.setState({notify: true });
        //this.props.history.push("pos");
    }
    sysStart() {
        var url = "/pos";
        if(this.seo.auto) url = "/" + this.seo.auto;
        this.props.navigate(url);
   };
    verify() {
        var self = this;
        var xdo = sessionStorage.getItem("svp_sso");
		var sdo = JSON.parse(xdo);

		var prm = {};
        prm.req = "usr:verify";
		prm.uid = sdo.uid;
		prm.token = sdo.token;

		//alert(JSON.stringify(prm));
        net.gcs_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret));

            if(ret.ok != "000") {
                //alert(JSON.stringify(ret));
                sessionStorage.removeItem("svp_sso");
                self.props.store.seo = {};

                var url = "/";
                self.props.navigate(url);
                //self.props.history.go(0);
                return;
            }

        })
        .catch(function(e){
            //var txt = JSON.stringify(e);
            var url = "/";
            self.props.navigate(url);
            //self.props.history.go(0);
        });

      };
  sel_fkn(sel) {
    //alert(JSON.stringify(fkn));
      if(sel.fkn == "logout") {
        var url = "/";
        this.props.navigate(url);
        //this.props.history.go(0);
      }
      if(sel.fkn == "sys") {
        var url = "/bo";
        this.props.navigate(url);
      }
  }

    render() {
        var stat = [];
        var keys = Object.keys(this.state.stat);
        for (var key of keys) {
            var row = this.state.stat[key];
            stat.push(row);
        }
        const handleDateChange = date => {
            this.setState({seldate: date });
        };

        return (
    <div className="web-app">
    <div className="web-top">

            <div id="header" className={ this.header_class() }>
            <div className="web-top-content d-flex flex-row">
                <div className="web-top-icon px-3 w60" onClick={() => this.sysStart() }>
                    <FontAwesomeIcon icon="bars" size="1x"/>
                </div>
                <div className="web-top-txt txt_18">Hello</div>
                <div className="flex-fill"></div>
                <div className="web-top-usr d-flex flex-column">
                    <div><FontAwesomeIcon icon="user" size="1x"/> { this.seo.name }</div>
                    <div><FontAwesomeIcon icon="envelope" size="1x"/> { this.seo.mbox }</div>
                </div>
                <div className="">
                    <Dd_Sel items={ this.selitems } cls="web-top-icon" getValue={(e)=> this.sel_fkn(e)} />
                </div>
            </div>
            </div>

    </div>

    <div className="web-main">
    <div className="web-body">
    <div className="web-center">

        <div className="web-box">
           <div className="web-logo">
                <img className="svk-logo" src={ this.logo() } />
            </div>
            <h1 className="h1-responsive">TACK FÖR PASSET!</h1>
            <p className="lead">
              VÄLKOMMEN ÅTER!
            </p>
            <hr className="my-2" />
            <p>Anv : { this.seo.name }</p>
            <p>System : { this.seo.auto }</p>
            <div className="divider_orange"></div>
            <div className="flex-row flex-center">
              <button className="btn btn-hw-blue w250" onClick={ () => this.logout() }>
              STÄNG
              </button>
            </div>
        </div>
    </div>


    </div>
    </div>

    <div className="web-foot">
        <div className="flex">
        <div className="web-foot-txt">Hello : { this.state.status }</div>
        </div>
        <div className="web-foot-icon">
          <FontAwesomeIcon icon="ellipsis-v" size="1x"/>
        </div>

        <div className="flex flex-row justify-content-end">

        <div className="web-foot-txt hide" onClick={() => this.fknGo("pms")}>Byt System</div>
        <div className="web-foot-txt">
          <Tiden />
        </div>
        </div>
    </div>
    </div>
    );
    }
}

//WebPub.contextType = SDOContext; // Function
export default withRouter(Logout);
