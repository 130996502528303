import React, { useState, useEffect, useContext } from 'react';
import {format, parse} from "date-fns";

import * as net from "../../../lib/net";
import * as utils from "../../../assets/utils";
import Td_Sel from "../../../lib/ui/td_sel";

class EE_Sel extends React.Component {
  constructor(props, context) {
    super(props);

      this.selrowitems = [
          {fkn: 'edit', text: 'ÖPPNA'},
          {fkn: 'remove', text: 'TABORT'}
      ];

    this.store = props.store;
    //this.pms = props.store.pms;

    this.state = {};
    this.state.ynq = [];
    //this.state.vga = [];
    this.state.rta = [];
    this.state.bra = [];

      this.state.tya = [];
      this.state.tya.push({ "id": "0001", "text": "START" });
      this.state.tya.push({ "id": "0002", "text": "SLUT" });

      this.state.eea = [];
      this.state.dta = [];
      this.state.tot = {};
      this.state.tro = {};
      this.state.tro.avd = "";
      //if(this.props.store.ta.dpa[0]) this.state.tro.avd = this.props.store.ta.dpa[0].avdelning;
      this.state.tro.typ = "0001";

    this.state.datum = "";
    this.state.fdat = "";
    this.state.tdat = "";
      this.state.enr = "";

    this.state.wnds = {};
      this.state.eeo = {};
    if(props.wnds) {
        if(props.wnds.datum) this.state.datum = props.wnds.datum;
    }
     //var dat = new Date(this.state.datum);

  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      this.ee();
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    tb_style() {
        var css = {};
        css.height = ( window.innerHeight * 0.7 ) - 265;
        css.overfow = "auto";
        return css;
    }
    change_avd(e) {
        let tro = this.state.tro;
        tro.avd = e.target.value;
        this.setState({tro: tro});
    }
    change_typ(e) {
        let tro = this.state.tro;
        tro.typ = e.target.value;
        this.setState({tro: tro});
    }
    val_save (name, value) {
        var tro = this.state.tro;
        tro[name] = value;
        this.setState({tro: tro});
    }

    wnd_close = () => {
      if(this.props.wnds.cb) {
          this.props.wnds.cb(this.props.ctx);
      }
      else this.props.ctx.setState({ wnds: {} });
	};
    ee() {
        var self = this;
        var slutat = "1";
        if(this.state.typ == "0") slutat = "0";

        var prm = {};
        prm.req = "ta.ta_ee.eea";
        prm.slutat = slutat;

        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret.rca));

            self.setState({eea: ret.rca });
            self.syncTb("eeTable");
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }
    syncTb(tb) {
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
            tdElements = tbel.getElementsByTagName('td');
        if(tdElements.length < 1 ) return;

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
                width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
    sel_row_fkn(row, sel) {
        //alert(JSON.stringify(fkn));
        if(sel.fkn == "edit") {
        }
        if(sel.fkn == "remove") {
            this.tr_del(row);
        }
    }
    show_reason(row) {
        var txt = row.anledning;

        if(row.anledning == "0001") {
            txt = "START";
        }
        if(row.anledning == "0002") {
            txt = "SLUT";
        }
        return txt;
    }
    th_icon(typ) {
        var cls = "";
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;
        if(sortid == typ)
        {
            if(sortdir) cls = "fa fa-caret-up";
            else cls = "fa fa-caret-down";
        }
        return cls;
    }
    th_style(id) {
        var css = {};
        css.cursor = "pointer";
        if(id == this.state.sortid) {
            if(this.state.sortdir) css.color = "#800";
            else css.color = "#080";
        }
        return css;
    }
    prs_sel(row) {
        if(this.props.wnds.cb) {
            var rsp = {};
            rsp.ok = "000";
            rsp.enr = row.p1.anstnummer;
            this.props.wnds.cb(this.props.ctx, rsp);
        }
        else this.props.ctx.setState({ wnds: {} });
    };

  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "Bokning";

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="mx_backdrop" /> : null }
        <div className="mx_dlg mx_70 flex-col" style={ this.dlg_style() }>
        <div className="mx_h01">
            <div>Personal lista</div>
        </div>

            <div className="divider_orange hide"></div>

            <div className="flex-row">
                <div className="flex"></div>
                <div className="flex"></div>
                <button className="btn btn-hw-blue w150" onClick={() => this.ee_dt() } aria-label="System" >
                    Visa
                </button>
            </div>
             <div className="divider_green"></div>
        <div className="mx_content flex-col flex">

        <div className="web-col">
            <div id="eeTable" className="flex">
                <table className="table table-bordered table-hover tb " style={{marginBottom: "0px"}}>
                    <thead>
                    <tr>
                        <th style={ this.th_style("p1.anstnummer") } onClick={() => this.tb_sort("p1.anstnummer") }>Anstnr <i className={ this.th_icon("p1.anstnummer") }></i></th>
                        <th style={ this.th_style("p2.initialer") } onClick={() => this.tb_sort("p2.initialer") }>Initialer <i className={ this.th_icon("p1.initialer") }></i></th>
                        <th style={ this.th_style("p1.namn") } onClick={() => this.tb_sort("p1.namn") }>Namn <i className={ this.th_icon("p1.namn") }></i></th>
                        <th style={ this.th_style("p1.fodnummer") } onClick={() => this.tb_sort("p1.fodnummer") }>Personnr <i className={ this.th_icon("p1.fodnummer") }></i></th>
                        <th style={ this.th_style("p1.avdelning") } onClick={() => this.tb_sort("p1.avdelning") }>Avdelning <i className={ this.th_icon("p1.avdelning") }></i></th>
                        <th style={ this.th_style("p1.arbschema") } onClick={() => this.tb_sort("p1.arbschema") }>Schema <i className={ this.th_icon("p1.arbschema") }></i></th>
                        <th style={ this.th_style("p2.startdat") } onClick={() => this.tb_sort("p2.startdat") }>Start <i className={ this.th_icon("p1.startdat") }></i></th>
                        <th style={ this.th_style("p2.prssdat") } onClick={() => this.tb_sort("p2.prssdat") }>Slut <i className={ this.th_icon("p1.prssdat") }></i></th>
                        <th style={ this.th_style("status") } onClick={() => this.tb_sort("status") }>Upd <i className={ this.th_icon("status") }></i></th>
                        <th></th>
                    </tr>
                    </thead>
                </table>
                <div className="tb-cdi" style={ this.tb_style() }>
                    <table className="table table-bordered table-hover tb">
                        <tbody>
                        { this.state.eea.map((row, key) =>
                            <tr key={key} onDoubleClick={() => this.prs_sel(row) }>
                                <td>{ utils.toNum( row.p1.anstnummer ) }</td>
                                <td>{ row.p2.initialer }</td>
                                <td>{ utils.toLang( row.p1.namn ) }</td>
                                <td>{ row.p1.fodnummer }</td>
                                <td>{ row.p1.avdelning }</td>
                                <td>{ row.p1.arbschema }</td>
                                <td>{ utils.toDate( row.p2.startdat ) }</td>
                                <td>{ utils.toDate( row.p2.prssdat ) }</td>
                                <td>{ row.status }</td>
                                <td>
                                    <Td_Sel items={ this.selitems } getValue={(e)=> this.sel_row_fkn(row, e)} />
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        </div>

        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex-row">
            </div>
        </div>
      </div>

    </div>
	;
	return html;
  };
}

export default EE_Sel;