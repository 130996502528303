import React, {useState} from 'react';

import { observer } from 'mobx-react';
import { useStore } from '../../sdo.js'

import * as net from "../../lib/net";
import Dd_Sel from "../../lib/ui/dd_sel";
import Pms_Term from "../op/fkn/rf_gst";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import sso from "../../sso";
import {useNavigate} from "react-router-dom";

function Red_top(props) {
  var navigate = useNavigate();
  let [state, setState] = useState({modal: false, ma: []});
    let ctx = useStore();

    var seo = props.store.seo;

    var selitems = [
        {fkn: 'home', text: 'HEM'},
        {fkn: 'setup', text: 'INSTÄLLNINGAR'},
        {fkn: 'upd', text: 'UPPDATERA'},
        {fkn: 'sys', text: 'SYSTEM'},
        {fkn: 'logout', text: 'LOGGA UT'}
    ];

      const goFull = () => {
        alert("Full");
      };

    function toggle() {
    };
    function logout() {
        var url = "/logout";
        this.props.navigate(url);
    };
    function acs() {
        var wnds = {};
        wnds.acs = 1;
        props.ctx.setState({ wnds: wnds });
    };
    function stat() {
        var wnds = {};
        wnds.stat = 1;
        props.ctx.setState({ wnds: wnds });
    };
    function bel() {
        var wnds = {};
        wnds.bel = 1;
        props.ctx.setState({ wnds: wnds });
    };
    function term() {
        var wnds = {};
        wnds.term = 1;
        props.ctx.setState({ wnds: wnds });
    };
      const mnu_toggle = () => {
		//props.ctx.setState({ mnu_show: !props.ctx.state.mnu_show });
		props.store.cfg.mnu_show = !props.store.cfg.mnu_show;
      };
      const bread_toggle = () => {
		props.store.cfg.bread = !props.store.cfg.bread;
      };
      const mnu_item_css = () => {
      };
      const mnu_sel = () => {
      };
      const login = () => {
      };
  
    const header_class = () => {
  	    var cls = "flex_col flex bg-hotback";

        //cls = cls + " web-head_1";
        //else cls = cls +  " web-header";
		    return cls;
    }

  function fknGo(url) {
    //alert(url);
      this.props.navigate(url);
    }
    function kund_info() {
    }
    function dlg_close() {
      sso.wnds.hotback = 0;
      var url = "/pms";
      navigate(url);
}
    function sel_fkn(sel) {
        //alert(JSON.stringify(fkn));
      if(sel.fkn == "home") {
        var url = "/hello";
          this.props.navigate(url);
      }
      if(sel.fkn == "logout") {
          sso.wnds.hotback = 0;
            var url = "/pms";
            navigate(url);
      }
  }
  function usr_info() {
    var wnds = {};
    wnds.usrinfo = 1;
    props.ctx.setState({ wnds: wnds });
};

    var html =
    <div className={ header_class() }>
    <div className="web-top-content flex-row flex">

    <div className="web-top-icon px-3 hide" onClick={() => mnu_toggle() }>
            <FontAwesomeIcon icon="bars" size="1x"/>
        </div>
        <div className="web-top-id flex-column cursor-point" onClick={() => toggle() }>
            <div className="txt_14"><img className="logo-img" height="25px" src={require('../../img/logo-cdi.png')} /></div>
            <div className="txt_14">HOTELL BACKOFFICE</div>
        </div>
        <div className="web-top-sep"></div>
        <div className="web-top-mnu flex-column cursor-point" onClick={() => kund_info() }>
            <div className="item-sys">Företag</div>
            <div className="item-fkn">{ sso.seo.company }</div>
        </div>

        <div className="web-top-sep"></div>

        <div className="flex-fill"></div>
        <div className="web-top-icon px-3" onClick={(e) => bel(e) }>
        </div>
        <div className="flex-fill"></div>

        <div className="flex-fill"></div>

        <div className="web-top-sep"></div>

        <div className="web-top-usr px-3" onClick={() => usr_info() }>
            <div className="text-center"><FontAwesomeIcon icon={["far", "user"]} size="1x"/></div>
            <div>{ sso.seo.name }</div>
        </div>
        <div className="web-top-sep"></div>
        <div className="hide">
            <Dd_Sel icon="right-from-bracket" items={ selitems } cls="web-top-icon" getValue={(e)=> sel_fkn(e)} />
        </div>
        <div className="web-top-icon px-3" onClick={() => dlg_close() }>
            <FontAwesomeIcon icon="xmark" size="1x"/>
        </div>

        </div>
        </div>
    ;
    return html;
}

export default observer(Red_top);
