import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Bo_Prm extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = {};
      this.state.wnds = {};
      this.state.msg = {};
      this.state.gpo = {};
      this.state.gpo.gpost1 = {};
      this.state.gpo.gpost2 = {};
      this.state.gpo.gpost3 = {};
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.ftg_rd();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      css.overflow = "auto";
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

    ftg_rd() {
        var self = this;
        var prm = {};
        prm.req = "ta.ta_bo.company";
        //prm.avd = this.state.avd;

        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret.rca));

            self.setState({gpo: ret.rco });
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }

  render() {
    return (
    <div className="web-app">
    <div className="flex-row">
        <div className="label_h03">PARAMETRAR</div>
        <div className="flex"></div>
        <button className="btn btn-hw-blue w150 ripple" onClick={() => this.ftg_rd() } aria-label="System" >
            Visa
        </button>
    </div>
    <div className="divider_blue"></div>

        <div className="flex-row txt_18">
            <div className="flex"></div>
            <div className="web-col flex">
                <div className="">Mintid Lunch: { this.state.gpo.gpost2.minlunch }</div>
                <div className="">Lunch 1: { this.state.gpo.gpost2.xlunchtim1 }</div>
                <div className="">Lunchtid 1: { this.state.gpo.gpost2.lunchtid1 }</div>
                <div className="">Lunch 2: { this.state.gpo.gpost2.xlunchtim2 }</div>
                <div className="">Lunchtid 2: { this.state.gpo.gpost2.lunchtid2 }</div>
                <div className="">Min Lunch: { this.state.gpo.gpost2.xlunchmin }</div>
                <div className="">Lunch 1: { this.state.gpo.gpost2.lunchtim1 }</div>
                <div className="">Lunch 2: { this.state.gpo.gpost2.lunchtim2 }</div>
                <div className="">Min Lunch: { this.state.gpo.gpost2.lunchmin }</div>
                <div className="">Lunch 2 Start: { this.state.gpo.gpost2.lunch2start }</div>
                <div className="">Matavdragstid: { this.state.gpo.gpost2.matavdragtid }</div>
            </div>
            <div className="w50"></div>
            <div className="web-col flex">
                <div className="">Anl Tid: { this.state.gpo.gpost2.anledn_tid }</div>
                <div className="">Anl OB1: { this.state.gpo.gpost2.anledn_ob1 }</div>
                <div className="">Anl OB2: { this.state.gpo.gpost2.anledn_ob2 }</div>
                <div className="">Utj Tid: { this.state.gpo.gpost2.utjamn_tid }</div>
                <div className="">Utj Mat: { this.state.gpo.gpost2.utjmatavdrag }</div>
            </div>
            <div className="flex"></div>
        </div>
        <div className="divider_blue"></div>
        <div className="flex-row txt_18">
            <div className="flex"></div>
            <div className="web-col flex">
                <div className="">OB Lördag start: { this.state.gpo.gpost3.obstartl }</div>
                <div className="">OB Vecka start: { this.state.gpo.gpost3.obstartv }</div>
                <div className="">OB Natt start: { this.state.gpo.gpost3.obstnatt }</div>
                <div className="">OB Natt slut: { this.state.gpo.gpost3.obslnatt }</div>
                <div className="">OB slut: { this.state.gpo.gpost3.obslut }</div>
            </div>
            <div className="w50"></div>
            <div className="web-col flex">
                <div className="">OB Avrundning: { this.state.gpo.gpost3.obavrund }</div>
                <div className="">OB Helg: { this.state.gpo.gpost3.obhelg }</div>
                <div className="">OB Röddag: { this.state.gpo.gpost3.obHT }</div>
            </div>
            <div className="flex"></div>
        </div>

   </div>
  );
    }
}
export default Bo_Prm;
