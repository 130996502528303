import React, { useState, useEffect, useContext } from 'react';
import {Route, Routes} from "react-router-dom";

import Drift_Home from './drift/home.js';
import Drift_Calls from './drift/calls.js';
import Drift_Queues from './drift/queues.js';
import {withRouter} from "../lib/react/withRouter";
import sso from "../sso";

function pg_home() {

    var html =
    <div className="web-content">
      Hem
    </div>
    ;
    return html;
}

class Tele_Drift extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, deps: [] };
      var po = {};
      po.calls = { "fid": "f06", "url": "/tele/drift/calls", "name": "Samtal", "active": false };
      po.queues = { "fid": "f06", "url": "/tele/drift/queues", "name": "Köer", "active": false };
      po.ext = { "fid": "f06", "url": "/tele/drift/ext", "name": "Anknytningar", "active": false };
      this.state.po = po;
      this.state.pa = [];
      this.state.pa = Object.values(po);
  }
    fknGo(row) {
        sso.seo.sfkn = row;
        this.props.navigate(row.url);
    }
    br_css(row) {
        var css = {};
        if(sso.seo.sfkn && (row.url == sso.seo.sfkn.url)) css.color = "#080";
        //var brc = false;
        //if(row.url == sbo.state.url) brc = true;
        return css;
    }

  render() {
    return (
        <div className="web-app">
            { this.props.store.cfg.bread ?
                <div className="web-bread">
                    <div className="breadcrumb">
                        { this.state.pa.map((row, key) =>
                            <div key={key} className="breadcrumb-item" style={this.br_css(row)} onClick={() => this.fknGo(row)}>{row.name}</div>
                        )}
                        <div className="flex"></div>
                        <div className="cdigreen">{ sso.seo.sfkn ? sso.seo.sfkn.name : null }</div>
                    </div>
                </div>
                : null }
        <Routes>
          <Route path="/xx" component={pg_home} />
            <Route path="calls" element={ <Drift_Calls store={this.webStore} />} />
            <Route path="queues" element={ <Drift_Queues store={this.webStore} />} />
            <Route path="*" element={ <Drift_Home store={this.webStore} />} />
        </Routes>
    </div>
  );
}
}
//Sys.contextType = ADOContext;

export default withRouter(Tele_Drift);
