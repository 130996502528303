import React, { useState, useEffect, useContext } from 'react';
import * as fkn from "../../lib/fkn";
import * as net from "../../lib/net";
import Dt_Cal from "../../lib/ui/dt_cal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Pos_Trs extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, tra: [], tsa: [], tta: [] };
    this.state.fdat = new Date();
    this.state.tdat = new Date();
    this.state.fkn = "";
    //this.state.units = [];
    //context.posrend = this;
    let cdat = new Date();
    let formatted_date = cdat.getFullYear() + "-" + (cdat.getMonth() + 1) + "-" + cdat.getDate();
    this.state.today = formatted_date;
    this.state.fdatum = formatted_date;
    this.state.tdatum = formatted_date;
  }
  componentDidUpdate() {
    let value = this.context;
    /* ... */
  }
  componentWillUnmount() {
    let value = this.context;
    /* ... */
  }
  componentDidMount() {
    //this.props.fetchWells()
    //  .then(res => this.setState({ wells: res.wells }) )
    //this.trans();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 250;
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }
  change_fdat(e) {
    //let cdat = this.state.seldate;
      if(e.value == null) return;

    let cdat = e.value;
    let formatted_date = cdat.getFullYear() + "-" + (cdat.getMonth() + 1).toString().tonum(2) + "-" + cdat.getDate().toString().tonum(2);
    this.setState({fdat: e.value});
    this.setState({fdatum: formatted_date});
    this.setState({tra: [] });
  }
  change_tdat(e) {
    //let cdat = this.state.seldate;
      if(e.value == null) return;
    let cdat = e.value;
    let formatted_date = cdat.getFullYear() + "-" + (cdat.getMonth() + 1).toString().tonum(2) + "-" + cdat.getDate().toString().tonum(2);
    this.setState({tdat: e.value});
    this.setState({tdatum: formatted_date});
    this.setState({tra: [] });
  }
  change_fkn(e) {
    //let cdat = this.state.seldate;
    //alert(JSON.stringify(e));
    let fkn = e.target.value;
    this.setState({fkn: fkn});
    this.setState({tra: [], tsa: [], tta: [] });
  }

  dt_fkn() {
    var self = this;
    var datum = this.state.datum;
    var today = this.state.today;

    if(this.state.fkn == "TRS") this.dt_hbok();
    else if(this.state.fkn == "TOT") this.dt_htot();
    else if(this.state.fkn == "KTO") this.dt_hkonto();
    else this.dt_hsum();
  }

  dt_trs() {
    var self = this;
    var prm = {};
    prm.req = "pos.pos_eko.trs_history";
    prm.fdat = this.state.fdatum;
    prm.tdat = this.state.tdatum;

    //gda.wndLoading("Hämtar");
    //net.sio_req(prm)
    net.sid_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rco.tot));

        if(ret.ok != "000") {
            return;
        }
        var o = {};
        //o.deps = ret.rco;
        //alert(JSON.stringify(o));
        self.setState({tra: ret.rca });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
    }

  render() {
    return (
    <div className="web-app" style={{margin: "0px", padding: "5px 10px"}}>
    <h3>Transaktioner</h3>

    <div className="flex-row flex-space-between">
        <div className="flex-row">
            <div className="flex-row">
                <div className="dt-btn-01" onClick={() => this.subday() } ><FontAwesomeIcon icon="minus" size="1x" /></div>
                <Dt_Cal dts={this.state.dts} rp="1" ref={this.dt_dt} getValue={(e)=> this.dt_dat(e)} />
                <div className="dt-btn-01" onClick={() => this.addday() } ><FontAwesomeIcon icon="plus" size="1x" /></div>
            </div>
            <div className="flex-row">
                <div className="dt-btn-01" onClick={() => this.subday() } ><FontAwesomeIcon icon="minus" size="1x" /></div>
                <Dt_Cal dts={this.state.dts} rp="1" ref={this.dt_dt} getValue={(e)=> this.dt_dat(e)} />
                <div className="dt-btn-01" onClick={() => this.addday() } ><FontAwesomeIcon icon="plus" size="1x" /></div>
            </div>
            <select className="form-control sel_input" placeholder="Välj funktion" value={this.state.fkn} onChange={(e) => this.change_fkn(e)} >
                <option value="">SUMMERAD</option>
                <option value="KTO">KONTO</option>
                <option value="TOT">TOTAL</option>
                <option value="TRS">TRANSAKTIONER</option>
            </select>
        </div>
        <button className="btn btn-hw-blue w150 ripple" onClick={() => this.dt_trs() } aria-label="System" >
            Visa
        </button>
    </div>
    <div className="divider">
    </div>

        <div className="web-row">
            <table className="table table-bordered table-hover tb fixed_header">
                <thead>
                <tr>
                    <th>Datum</th>
                    <th>Notanr</th>
                    <th>Typ</th>
                    <th>Tid</th>
                    <th>Terminal</th>
                    <th>Text</th>
                    <th>Antal</th>
                    <th>Pris</th>
                    <th>Tkn</th>
                </tr>
                </thead>
                <tbody style={ this.tb_style() }>
                { this.state.tra.map((row, key) =>
                    <tr id={ row.r0dateid } ng-dblclick="trans_open(row);">
                        <td>{ row.r0datum.cdidate() }</td>
                        <td>{ row.r0faktnr }</td>
                        <td>{ row.r0etyp }</td>
                        <td>{ row.r0time }</td>
                        <td>{ row.r0terminal }</td>
                        <td>{ row.r0text }</td>
                        <td>{ row.r0noof.ztrim() }</td>
                        <td>{ row.r0perprice.cdipris() }</td>
                        <td>{ row.r0debcre }</td>
                    </tr>
                )}
                </tbody>
            </table>
        </div>
    </div>
  );
}
}
export default Pos_Trs;
