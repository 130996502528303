import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";
import ddo from "../../ddo";

import Dt_Cal from "../../lib/ui/dt_cal";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Pos_Tbs extends React.Component {
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pos = props.store.pos;

    this.state = {};
    this.state.seldate = new Date();
    this.state.avd = ddo.cache.avd;
    this.state.tba = [];
    this.state.aa = [];
    //context.posrend = this;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.tbs();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 480;
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

  tb_edit(row) {
      var self = this;
      alert(JSON.stringify(row.plu00));
  }

  tbs() {
    var self = this;
    var prm = {};
    prm.req = "pos.pos_tbs.tbs";
    prm.avd = ddo.cache.avd;

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        self.setState({tba: ret.rca });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }

  render() {
    return (
    <div className="web-app">

    <div className="flex-row">
        <Dt_Cal startdatum="2020-02-01" getValue={(e)=> this.setState({seldate: e}) }/>
        <select className="form-control w250 hide" placeholder="Välj avdelning">
            { this.pos.deps.map((row, key) =>
            <option key={key} value={row.id}>{row.text}</option>
            )}
        </select>
        <div className="flex"></div>
        <button className="btn btn-hw-blue w150 ripple" onClick={() => this.tbs() } aria-label="System" >
            UPPDATERA
        </button>
    </div>
    <div className="divider_orange"></div>

    <div className="web-row">
        <table className="table table-bordered table-hover tb fixed_header">
            <thead>
            <tr>
                <th>Avd</th>
                <th>Bordsnr</th>
                <th>Text</th>
                <th>Kassör</th>
                <th>Notanr</th>
                <th>Löpnr</th>
                <th>Belopp</th>
                <th>Status</th>
                <th style={{width:"40px"}} ></th>
            </tr>
            </thead>
            <tbody style={ this.tb_style() }>
            { this.state.tba.map((row, key) =>
            <tr key={key} id={ row.b0bord } onDoubleClick={() => this.bord_edit(row) }>
                <td>{ row.b0kstns }</td>
                <td>{ row.b0bord }</td>
                <td>{ row.b0bord }</td>
                <td>{ row.b0serv }</td>
                <td>{ row.b0faktnr }</td>
                <td>{ row.b0lopnr }</td>
                <td>{ row.b0bestallt.cdipris() }</td>
                <td>{ row.bostatus }</td>
                <td style={{width:"40px",cursor:"pointer"}} onClick={() => this.bord_edit(row) }><i className="fa fa-cog text-danger"></i></td>
            </tr>
            )}
            </tbody>
        </table>
    </div>

    </div>
  );
    }
}
export default Pos_Tbs;
