import React, { useState, useEffect, useContext } from 'react';
import * as fkn from "../lib/fkn";
import SDOContext from '../sdo.js'
import {BrowserRouter, Route, Routes} from "react-router-dom";
//import ADOContext from "../ado";
import Vip_Top from './com/vip_top.js';
import Vip_Mnu from './com/vip_mnu.js';
import Vip_Foot from "./com/vip_foot";

import Vip_Home from './home.js';
import Vip_Dash from './dash.js';
import Vip_St from './st.js';
import Vip_Reg from './reg.js';
import Vip_Stat from './stat.js';
import Vip_Bo from './bo.js';

import * as net from "../lib/net";
import {withRouter} from "../lib/react/withRouter";
import Man_Home from "../pos/man/home";

class Vip extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.state = { pgid: 11, deps: [] };
    //context.posrend = this;
  }

  fknGo(url) {
    //var sdo = useContext(SDO);
    //alert(fkn);
    //var fkn = row.fid;
    //setState({ row : row,  ma : [] });
    //ctx.fid = fkn;

    //props.history.push(row.url);
    this.props.navigate(url);
   };

  ddo() {
    alert("POS" + this.state.pgid );
    this.setState({ status: 1 });
  }

  render() {
    return (
    <div className="web-app">
    <div className="web-top">
        <Vip_Top store={this.store} />
    </div>
    <div className="web-main">

    <div className="web-row" style={{ height:"100%" }}>

    <div className="web-side">
        <Vip_Mnu store={this.store} />
    </div>

    <div className="web-body">
        <Routes>
          <Route path="st/*" element={ <Vip_St store={this.store} />} />
          <Route path="reg/*" element={ <Vip_Reg store={this.store} />} />
          <Route path="stat/*" element={ <Vip_Stat store={this.store} />} />
          <Route path="bo/*" element={ <Vip_Bo store={this.store} />} />
          <Route path="*" element={ <Vip_Dash store={this.store} />} />
        </Routes>
    </div>

    </div>

    </div>
        <Vip_Foot store={this.store} />
    </div>
  );
}
}
//Sys.contextType = ADOContext;

export default withRouter(Vip);
