import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../../lib/net";
import Dd_Sel from "../../../lib/ui/dd_sel";
import Td_Sel from "../../../lib/ui/td_sel";

class Pms_Rooms extends React.Component {
  constructor(props, context) {
    super(props);

     this.selitems = [
        {fkn: 'setup', text: 'INSTÄLLNINGAR'},
        {fkn: 'upd', text: 'UPPDATERA'},
        {fkn: 'sys', text: 'SYSTEM'},
        {fkn: 'logout', text: 'LOGGA UT'}
    ];
     this.rowitems = [
        {fkn: 'open', text: 'ÖPPNA'},
        {fkn: 'txt', text: 'RUMSTEXT'}
    ];

    //alert('PBO CTX: ' + JSON.stringify(props.pbo));
   //this.webStore = props.store;
    this.state = {};
    this.state.ynq = [];

    this.state.rra = [];
    this.state.rro = {};
    if(props.wnds) {
        this.state.rra = props.wnds.rra;
    }

    this.state.loading = 0;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      //this.notor();
    this.syncTb("roomsTable");
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }

    wnd_close() {
      if(this.props.close) this.props.close(this.props.ctx);
      else this.props.ctx.setState({ wnds: {} });
	};
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      css.overflow = "auto";
      return css;
  }
  sel_row(row) {
    var self = this;
  }
  sel_row_fkn(row, sel) {
      if(sel.fkn == "open") {
      }
  }
  notor() {
    var self = this;
    var prm = {};
    prm.req = "pms.charge_bill.gst_bills";
    prm.boknr = this.state.rvo.boknr;
    prm.glopnr = this.state.rvo.gsb00.r0glopnr;
    prm.gsb00 = this.state.rvo.gsb00;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        self.setState({ rra: ret.rra });
        self.syncTb("roomsTable");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
    syncTb(tb){
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
        tdElements = tbel.getElementsByTagName('td');

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
            width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }

  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "";

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="m2_backdrop" /> : null }
        <div className="m2_dlg mx_90h flex-col" style={ this.dlg_style() }>
        <div className="flex-row " style={{padding:"0px",height:"50px",lineHeight:"50px", background: "#1a456b", color: "#fff"}}>
                <div className="flex-row flex" style={{padding:"0px 5px", fontSize: "22px"}}>
                    <div>RUM</div>
                </div>
            <div className="">
                <Dd_Sel items={ this.selitems } cls="web-top-icon" getValue={(e)=> this.sel_fkn(e)} />
            </div>
        </div>
        <div className="mx_content flex">

        <div className="web-row flex">

        <div id="roomsTable" className="flex">
          <table className="table table-bordered table-hover tb" style={{marginBottom: "0px"}}>
            <thead>
            <tr>
                <th>Rumsnr</th>
                <th>Rumstyp</th>
                <th>Text</th>
                <th>Nyckel</th>
                <th>TV</th>
                <th>Ljus</th>
                <th>Ventilation</th>
                <th></th>
            </tr>
            </thead>
        </table>
        <div style={ this.tb_style() }>
          <table className="table table-bordered table-hover tb">
            <tbody>
            { this.state.rra.map((row, key) =>
            <tr key={key} onDoubleClick={ () => this.sel_row(row) }>
                <td>{ row.rumsnr }</td>
                <td>{ row.rt }</td>
                <td>{ row.txt }</td>
                <td>{ row.key }</td>
                <td>{ row.tv }</td>
                <td>{ row.ljus }</td>
                <td>{ row.vent }</td>
                <td>
                    <Td_Sel items={ this.rowitems } getValue={(e)=> this.sel_row_fkn(row, e)} />
                </td>
            </tr>
            )}
            </tbody>
        </table>
        </div>
        </div>

        </div>

        </div>
        <div className="mx_foot flex-row flex-space-between">
            <div className="flex-row"></div>
            <button className="btn btn-hw-red w250 ripple" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex-row"></div>
        </div>
      </div>

    </div>
	;
	return html;
  };
}

export default Pms_Rooms;