import React, { useState, useEffect, useContext } from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {format} from "date-fns";

import * as net from "../../../../lib/net";
import Fr_Input from "../../../../lib/ui/fr_input";
import * as utils from "../../../../assets/utils";

class Sell_Preg extends React.Component {
  constructor(props, context) {
    super(props);

    this.pos = props.store.pos;
    this.state = {};
    this.state.today = new Date();
    var dts = format(this.state.today, "yyyy-MM-dd");
    this.state.dts = dts;

    this.state.wnd_ok = false;
    this.state.wnd_error = false;

    this.state.sz_val = "";
      this.state.gnr = "001";
    //this.state.lro = props.lro;
    this.state.ynq = [
                {id: '0', text: 'NEJ'},
                {id: '1', text: 'JA'}
            ];

    this.state.boknr = "";
    this.state.gso = {};
    this.state.eko = {};

    this.state.cols = {};
      this.state.pro = {};
      this.state.pro.pres00 = {};
      this.state.pro.pres01 = {};
      this.state.pro.pres00.p0id = "";
      this.state.pro.pres00.p0text = "";
      this.state.pro.pres00.p0datum = "";
      this.state.pro.pres00.p0expire = "";
      this.state.pro.pres00.p0saldo = "";
      this.state.pro.pres00.p0belopp = "";
      this.state.pro.pres01.p1issuedby = "";
      this.state.pro.pres01.p1anm = "";
      this.state.tro = {};
      if(props.wnds) {
        this.state.boknr = props.wnds.boknr;
        this.state.gso = props.wnds.gso;
        this.state.tro = props.wnds.tro;
        this.state.eko = props.wnds.eko;
        this.state.pro.pres00.p0belopp = this.state.tro.belopp;
        this.state.pro.pres00.p0saldo = this.state.tro.belopp;

        if(this.state.pro.pres00.p0belopp.includes(".")) this.state.pro.pres00.p0belopp = this.state.pro.pres00.p0belopp.replace(/\./g,"");
        else this.state.pro.pres00.p0belopp = this.state.pro.pres00.p0belopp + "00";
        if(this.state.pro.pres00.p0saldo.includes(".")) this.state.pro.pres00.p0saldo = this.state.pro.pres00.p0saldo.replace(/\./g,"");
        else this.state.pro.pres00.p0saldo = this.state.pro.pres00.p0saldo + "00";
          }
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      //if(!this.props.wnds.pro) this.preg_init();
  }

    preg_init() {
        var pro = {};
        pro.pres00 = {};
        pro.pres01 = {};
        pro.pres00.p0belopp = "";
        //alert(JSON.stringify(pro));
        this.setState({ pro: pro });
        return;
    }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      if(this.state.wnd_ok) {
        css.background = "#080";
        css.color = "#fff";
      }
      if(this.state.wnd_error) {
        css.background = "#800";
        css.color = "#fff";
      }
      return css;
    }

    formchange (e) {
      const name = e.target.name;
      const value = e.target.value;
      var pbo = this.state.pbo;
      var na = name.split(".");
      pbo[na[0]][na[1]] = value;
      this.setState({pbo: pbo});
      //setState({[name]: value});
    }

    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    wnd_close = () => {
      if(this.props.wnds.cb) {
          this.props.wnds.cb(this.props.ctx, {});
      }
      else this.props.ctx.setState({ wnds: {} });
	};
    handleChange = (event, newValue) => {
        //alert(newValue);
        this.setState({ix: newValue});
      };
    toggle() {
      this.setState({modal: false});
	};

  submitHandler = event => {
    event.preventDefault();
    event.target.className += " was-validated";
  };

  changeHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

    val_save(id, val) {
        var pro = this.state.pro;
        var f0 = id.split(".")[0];
        var f1 = id.split(".")[1];
        pro[f0][f1] = val;
        this.setState({ pro: pro });
        return;
    }
    tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  cssCols (cid) {
      var css = {};
      if(this.state.cols[cid]) css.width = this.state.cols[cid].toString() + "px";
      //if(cid == "c01") alert(JSON.stringify(this.state.cols[cid]));
      return css;
  }
  preg_save() {
    var self = this;
    var prm = {};
    prm.req = "crs.crs_reg.pres_set";
    prm.pro = this.state.pro;
    //alert(JSON.stringify(prm));

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));
        if(ret.ok != "000") {
          alert("Kunde ej lägga upp id, försök igen");
        }
        if(ret.ok == "000") {
          self.setState({wnd_ok: true });
          setTimeout(function(){
              self.setState({wnd_ok: false });
              self.sell_preg();
              return;
          }, 1000);
      }
      else {
        self.setState({wnd_error: true });
        setTimeout(function(){
            self.setState({wnd_error: false });
            return;
        }, 1000);
    }
  })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
    sz_keydown (e) {
        //e.preventDefault();
        const key = e.key;
        const keyCode = e.keyCode;

        if(keyCode == 13) {
            this.plu_sz();
        };
        if(keyCode == 27) {
            this.setState({sz_val: ""});
        };
    }
    sz_change (e) {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({sz_val: value});
        //setState({[name]: value});
    }
    sell_preg() {
      var self = this;
  
      var tro = this.state.tro;
      //alert(JSON.stringify(tro));

      var tkn = "+";
      if(tro.pris.includes("-")) {
          tro.pris = tro.pris.replace(/\-/g,"");
          tkn = "-";
      }
      //if(tro.tkn.includes("-")) tkn = "-";
      //tkn = "+";

      //if(tro.pris.includes(".")) tro.pris = tro.pris.replace(".", "");
      if(tro.pris.includes(".")) tro.pris = tro.pris.replace(/\./g,"");
      else tro.pris = tro.pris + "00";
  
      var prm = {};
    //prm.req = "pms.sell.charge_payment";
    prm.req = "pms.sell.charge_tro";
    prm.boknr = this.state.boknr;
    prm.gsb00 = this.state.gso;
    prm.eko = this.state.eko;
    prm.bastyp = "P";
    prm.typ = "P";
    prm.trkod = tro.id;
    prm.text = tro.text;
    prm.antal = tro.antal;
    prm.pris = tro.pris;
    prm.tkn = tkn;
    prm.moms = tro.moms;
    prm.datum = this.state.dts;

      net.sio_req(prm)
      .then(function(ret){
          //alert(JSON.stringify(tro));
  
        if(self.props.wnds.cb) {
          var rsp = {};
          rsp.ok = "000";
            self.props.wnds.cb(self.props.ctx, rsp);
        }
        else self.props.ctx.setState({ wnds: {} });
      })
      .catch(function(e){
          var txt = JSON.stringify(e);
      });
    }
  
  render() {
    //if(!this.props.show) return "";
    var title = this.props.title;
    if(!title) title = "UDID";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        <div className="m2_backdrop" />
        <div className="m2_dlg mx_70 flex-col" style={ this.dlg_style() } >
        <div className="mx_top flex-row">

            <div className="txt-22">
                <div>SÄLJ PRESENTKORT: { utils.toPris(this.state.tro.belopp) }</div>
            </div>
            <div className="flex"></div>
            <div>UPPDATERA</div>
        </div>
            <div className="divider_orange"></div>

            <form className="cdi_f03">
                <div className="flex-row">
                    <Fr_Input label="Presntkorts ID" className="text-left w250" text={ this.state.pro.pres00.p0id } getValue={(e)=> this.val_save("pres00.p0id", e) } />
                </div>
                <div className="flex-row">
                    <Fr_Input label="Text" className="text-left w350" text={ this.state.pro.pres00.p0text } getValue={(e)=> this.val_save("pres00.p0text", e) } />
                </div>
                <div className="flex-row hide">
                    <Fr_Input label="Skapat" className="text-left w150" text={ this.state.pro.pres00.p0datum } getValue={(e)=> this.val_save("pres00.p0datum", e) } />
                </div>
                <div className="flex-row hide">
                    <Fr_Input label="Giltigt till" className="text-left w150" text={ this.state.pro.pres00.p0expire } getValue={(e)=> this.val_save("pres00.p0expire", e) } />
                </div>
                <div className="flex-row">
                    <Fr_Input readonly label="Belopp" className="text-right w250" text={utils.toPris( this.state.pro.pres00.p0belopp )} getValue={(e)=> this.val_save("pres00.p0belopp", e) } />
                </div>
                <div className="flex-row">
                    <Fr_Input readonly label="Saldo" className="text-right w250" text={utils.toPris( this.state.pro.pres00.p0saldo )} getValue={(e)=> this.val_save("pres00.p0saldo", e) } />
                </div>
            </form>

            <div className="mx_section">

                <form className="cdi_f03">
                <div className="divider_green"></div>
                <div className="flex-row">
                    <Fr_Input label="Skapad av" className="text-left w250" text={this.state.pro.pres01.p1issuedby} getValue={(e)=> this.val_save("pres01.p1issuedby", e) } />
                </div>
                <div className="flex flex-column" style={{padding: "0px 5px"}}>
                    <span className="header">Anmärkning</span>
                    <textarea label="Anm" className="form-control" maxLength="512" rows="5" value={this.state.pro.pres01.p1anm} onChange={(e) => this.val_save("pres01.p1anm", e)} ></textarea>
                </div>
                </form>

            </div>
            <div className="flex"></div>

        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex-row">
            <button className="btn btn-hw-green w250" onClick={() => this.preg_save() } >
                SPARA
            </button>
            </div>
        </div>
      </div>

      </div>
	;
	return html;
  };
}

export default Sell_Preg;