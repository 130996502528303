import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";

import Xr_dlg from "./kassa/xr_dlg";
import Zr_Dlg from "./kassa/zr_dlg";
import Jr_Dlg from "./kassa/jr_dlg";
import Cr_Dlg from "./kassa/cr_dlg";
import Crt_Dlg from "./kassa/crt_dlg";
import Prd_Dlg from "./kassa/prd_dlg";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Pos_Rps_Kassa extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, tra: [] };
    this.state.seldate = new Date();
    this.state.avd = "01";
    this.state.paa = [];
    this.state.aa = [
                {id: '01', text: 'AVD 01'},
                {id: '02', text: 'AVD 02'}
            ];
    //context.posrend = this;
    this.state.pdf = 0;
    this.state.rptyp = "";
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.plus();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 480;
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

  rp_open(typ) {
      var self = this;
    this.setState({ rptyp: typ });
  }
    pdf_show(id) {
        this.setState({ pdf: id });
        return;
    }

  render() {
    return (
    <div className="web-p00 flex flex-col">
        <div className="">
            RAPPORTER
        </div>

    <div className="web-p01 flex">

    <div className="web-row">
     <div className="web-col flex" style={{padding: "0px 10px"}}>
        <div className="web-btn" onClick={() => this.rp_open("cr") } >
            <div>KASSARAPPORT</div>
            <div>Total / Enskild kassarapport</div>
        </div>
        <div className="web-btn" onClick={() => this.rp_open("cred") } >
            <div>KASSÖRSRAPPORT</div>
            <div>Total / Enskild kassörsrapport</div>
        </div>
   </div>
     <div className="web-col flex">
        <div className="web-btn" onClick={() => this.rp_open("pr") } >
            <div>PRODUKTRAPPORT</div>
            <div>Total / Enskild produktrapport</div>
        </div>
   </div>
    <div className="web-col flex">
        <div className="web-btn" onClick={() => this.rp_open("xr") } >
            <div>X-RAPPORT</div>
            <div>Kontrollrapport innevarande period</div>
        </div>
        <div className="web-btn" onClick={() => this.rp_open("zr") } >
            <div>Z-RAPPORT</div>
            <div>Kontrollrapport stängd period</div>
        </div>
        <div className="web-btn" onClick={() => this.rp_open("jr") } >
            <div>JOURNALRAPPORT</div>
            <div>Kontrollrapport för periodens journal</div>
        </div>
    </div>
   </div>

    </div>

        { this.state.rptyp == "xr" ? <Xr_dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "zr" ? <Zr_Dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "jr" ? <Jr_Dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "cr" ? <Cr_Dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "tcr" ? <Crt_Dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "pr" ? <Prd_Dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
    </div>
  );
    }
}
export default Pos_Rps_Kassa;
