import React, { useState, useEffect, useContext } from 'react';
import * as fkn from "../../lib/fkn";
import * as net from "../../lib/net";
import SDOContext from '../../sdo.js'

import '../css/pitv.css';

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Dsi_Mob extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, tra: [] };
    this.state.msel = "";
    this.state.tva = [];
    this.state.m01 = [];
    var tvo = { text: "FRUKOSTAR", rows: [], descrows: [] };
    tvo.rows.push({ text: "Havregrynsgröt", pris: "75.00", desc: "Serveras med jordgubbssylt, ägg & kaviar samt valfri fralla ur montern." });
    tvo.rows.push({ text: "Risgrynsgröt", pris: "75.00", desc: "Serveras med jordgubbssylt, hårdkokt ägg & kaviar samt valfri fralla ur montern." });
    tvo.rows.push({ text: "Scrambled eggs", pris: "75.00", desc: "Serveras med bacon på stekt frasigt bröd." });
    tvo.rows.push({ text: "Toastfrukost", pris: "75.00", desc: "Ugnsrostat bröd med pålägg samt ägg & kaviar." });
    tvo.rows.push({ text: "Skål med gröt & sylt", pris: "75.00", desc: "" });
    this.state.m01.push(tvo);
    tvo = { text: "PANNKAKOR", rows: [], descrows: [] };
    tvo.rows.push({ text: "Pannkakor 2 st", pris: "55.00", desc: "Serveras med sylt och grädde." });
    tvo.rows.push({ text: "Pannkakor 4 st", pris: "79.00", desc: "Serveras med sylt och grädde." });
    this.state.m01.push(tvo);

    this.state.m02 = [];
    tvo = { text: "SALLADER", rows: [], descrows: [] };
    tvo.rows.push({ text: "Kycklingsallad", price: "109.00", desc: "Stekt kyckling, mixsallad med bl.a. ruccola och babyspenat, tomat, majs, gurka, rödlök, champinjoner, paprika. Serveras med vår berömda currydressing och nygräddat bröd." });
    tvo.rows.push({ text: "Tonfisksallad", price: "119.00", desc: "Hemmagjord tonfiskröra, mixsallad med bl.a. ruccola och babyspenat, tomat, majs, gurka, rödlök, champinjoner, paprika. Serveras med ägg, citron och nygräddat bröd." });
    tvo.rows.push({ text: "Caesarsallad med Kyckling & Bacon", price: "134.00", desc: "Romansallat, hemmagjord caesardressing, krutonger, italiensk hårdost, stekt kyckling & bacon.\n" });
    tvo.rows.push({ text: "Caesarsallad med Kyckling", price: "119.00", desc: "Romansallat, hemmagjord caesardressing, krutonger, italiensk hårdost & stekt kyckling.\n." });
    this.state.m02.push(tvo);
    tvo = { text: "NYSTEKTA MACKOR", rows: [], descrows: [] };
    tvo.rows.push({ text: "Baconmacka", price: "79.00", desc: "Frasigt stekt bröd med bacon & 2 stekta ägg." });
    tvo.rows.push({ text: "Pannbiffmacka", price: "99.00", desc: "Frasigt stekt bröd med 120 gr nötbiff & 2 stekta ägg." });
    tvo.rows.push({ text: "Falukorvmacka", price: "75.00", desc: "Frasigt stekt bröd med falukorv & 2 stekta ägg." });
    tvo.rows.push({ text: "Kycklingmacka", price: "89.00", desc: "Frasigt stekt bröd med 125 gr kyckling & currymajonnäs." });
    this.state.m02.push(tvo);
    tvo = { text: "NYGRÄDDADE BAGUETTER", rows: [], descrows: [] };
    tvo.rows.push({ text: "Kycklingbaguette", price: "89.00", desc: "Ljust bröd vår berömda currydressing och stekta kycklingfile bitar." });
    tvo.rows.push({ text: "Halv Kycklingbaguette", price: "55.00", desc: "Ljust bröd vår berömda currydressing och stekta kycklingfile bitar." });
    tvo.rows.push({ text: "Tonfiskbaguette", price: "89.00", desc: "Ljust bröd med smör, vår underbart goda hemmagjorda tonfiskröra och citron." });
    tvo.rows.push({ text: "Halv Tonfiskbaguette", price: "59.00", desc: "Ljust bröd med smör, vår underbart goda hemmagjorda tonfiskröra och citron." });
    this.state.m02.push(tvo);

    this.state.m03 = [];
    tvo = { text: "KAKOR", rows: [], descrows: [] };
    tvo.rows.push({ text: "Kaka", price: "29.00", desc: "" });
    tvo.rows.push({ text: "Lägger till kakor", price: "", desc: "" });
    this.state.m03.push(tvo);
    this.state.m04 = [];
    tvo = { text: "KAFFE", rows: [], descrows: [] };
    tvo.rows.push({ text: "Kaffe", price: "29.00", desc: "" });
    tvo.rows.push({ text: "Espresso", price: "19.00", desc: "" });
    tvo.rows.push({ text: "Lägger till kaffe/dryck", price: "", desc: "" });
    this.state.m04.push(tvo);
    this.state.m05 = [];
    tvo = { text: "TILLVAL", rows: [], descrows: [] };
    tvo.rows.push({ text: "Kaviar", price: "5.00", desc: "" });
    tvo.rows.push({ text: "Salami", price: "5.00", desc: "" });
    tvo.rows.push({ text: "....", price: "", desc: "" });
    this.state.m05.push(tvo);

    this.state.msel = "m01";
    this.state.tva = this.state.m01;

    //context.posrend = this;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.props.fetchWells()
    //  .then(res => this.setState({ wells: res.wells }) )
    this.tv_get();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 200;
      return css;
  }
  css_category(tvo) {
      var css = {};
      //css.height = window.innerHeight - 200;
      return css;
  }
  css_desc(tvo) {
      var css = {};
      //css.height = window.innerHeight - 200;
      return css;
  }
  css_row_text(tvo) {
      var css = {};
      //css.height = window.innerHeight - 200;
      return css;
  }
  css_row_price(tvo) {
      var css = {};
      //css.height = window.innerHeight - 200;
      return css;
  }
  css_row_desc(tvo) {
      var css = {};
      //css.height = window.innerHeight - 200;
      return css;
  }
  css_mnu_item(mid) {
      var css = {};
      css.paddingTop = "5px";
      if(this.state.msel == mid) {
        css.background = "#000";
        css.color = "#fff";
      }
      return css;
  }

  show_mnu(tvo) {
      var brc = true;
      //css.height = window.innerHeight - 200;
      //return brc;
  }
  price_text(row) {
      var price = row.pris;
      if(price) price = price.cdipris();
      //css.height = window.innerHeight - 200;
      return price;
  }
  mnu_show(mid) {
      if(mid == "m01") {
          this.setState({ msel: mid, tva: this.state.m01 });
      }
      if(mid == "m02") {
          this.setState({ msel: mid, tva: this.state.m02 });
      }
      if(mid == "m03") {
          this.setState({ msel: mid, tva: this.state.m03 });
      }
      if(mid == "m04") {
          this.setState({ msel: mid, tva: this.state.m04 });
      }
      if(mid == "m05") {
          this.setState({ msel: mid, tva: this.state.m05 });
      }
  }

  tv_get() {
    var self = this;
    var tvid = "web0";
    var prm = {};
    prm.req = "dsi.dsi_run.tv_lt";
    prm.scrid = tvid;

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rco));
        var tvo = ret.rco;
        var m01 = [];
        if(tvo.data.c11.mnu) m01.push(tvo.data.c11.mnu);
        var m02 = [];
        if(tvo.data.c12.mnu) m02.push(tvo.data.c12.mnu);
        var m03 = [];
        if(tvo.data.c21.mnu) m03.push(tvo.data.c21.mnu);
        var m04 = [];
        if(tvo.data.c22.mnu) m04.push(tvo.data.c22.mnu);
        var m05 = [];
        if(tvo.data.c25.mnu) m05.push(tvo.data.c25.mnu);
        //alert(JSON.stringify(m01))
        //alert(JSON.stringify(tvo.data.c12.mnu));
        self.setState({ m01: m01 });
        self.setState({ m02: m02 });
        self.setState({ m03: m03 });
        self.setState({ m04: m04 });
        self.setState({ m05: m05 });
        self.mnu_show("m01");
        //var o = {};
        //o.deps = ret.rco;
        //alert(JSON.stringify(o));
        //self.setState({tra: ret.rca });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
    }

  render() {
    return (
    <div className="web-app" style={{background: "#000",overfow:"hidden"}}>
        <div className="mnu_xtop" style={{display: "none",background: "#000",overfow:"hidden"}}>
            <div className="flex_row flex_ss">
                 <div className="mnu_text_0 flex">CT 24</div>
                 <div className="mnu_text_2">24 / 7</div>
           </div>
        </div>

        <div className="web-body flex_row" style={{background: "#000",overfow:"hidden"}}>
          <div className="flex flex_col mnu-base">

            { this.state.tva.map((tvo, tkey) =>
            <div key={tkey} className="flex flex_col" show={ this.show_mnu(tvo) }>
              <div className="flex_col category-box">
                <div className="flex_row category-title" style={ this.css_category(tvo) }>
                  { tvo.text.toswe() }
                </div>
            { tvo.descrows.map((desc, key) =>
                <div className="dish-description" style={ this.css_desc(tvo) }>
                  { desc.toswe() }
                </div>
            ) }
              </div>

            { tvo.rows.map((row, key) =>
              <div key={key} className="flex_col" style={{padding: "20px 0px"}}>
                <div className="flex_row">
                  <div className="flex dish-title flex" style={ this.css_row_text(tvo) }>
                    { row.text.toswe() }
                  </div>
                  <div className="dish-pricing" style={ this.css_row_price(tvo) }>
                    { this.price_text(row) }
                  </div>
                </div>
                <div className="dish-description">
                  <div style={ this.css_row_desc(tvo) } flex="75">
                    { row.desc.toswe() }
                  </div>
                </div>
              </div>
            )}
            </div>
        ) }

        </div>
    </div>

    <div className="mnu_foot flex-row">
        <div className="f20 flex-column flex-center" style={ this.css_mnu_item("m01") } onClick={() => this.mnu_show("m01") } >
            <i className="fa fa-clock" aria-hidden="true"></i>
            <div style={{paddingTop:"5px", fontSize:"10px"}}>FRUKOST</div>
        </div>
        <div className="f20 flex-column flex-center" style={ this.css_mnu_item("m02") } onClick={() => this.mnu_show("m02") } >
            <i className="fa fa-utensils" aria-hidden="true"></i>
            <div style={{paddingTop:"5px", fontSize:"10px"}}>MAT</div>
        </div>
        <div className="f20 flex-column flex-center" style={ this.css_mnu_item("m03") } onClick={() => this.mnu_show("m03") } >
            <i className="fa fa-cookie" aria-hidden="true"></i>
            <div style={{paddingTop:"5px", fontSize:"10px"}}>BAK</div>
        </div>
        <div className="f20 flex-column flex-center" style={ this.css_mnu_item("m04") } onClick={() => this.mnu_show("m04") } >
            <i className="fa fa-coffee" aria-hidden="true"></i>
            <div style={{paddingTop:"5px", fontSize:"10px"}}>DRYCK</div>
        </div>
        <div className="f20 flex-column flex-center" style={ this.css_mnu_item("m05") } onClick={() => this.mnu_show("m05") } >
            <i className="f50 fa fa-plus" aria-hidden="true"></i>
            <div style={{paddingTop:"5px", fontSize:"10px"}}>TILLVAL</div>
        </div>
    </div>

    </div>
  );
    }
}
export default Dsi_Mob;

/*
    <div className="mnu_foot flex-row">
        <div className="f20 flex-column flex-center" style={{paddingTop:"5px"}} >
            <i className="fa fa-home" aria-hidden="true"></i>
            <div style={{paddingTop:"5px", fontSize:"10px"}}>HEM</div>
        </div>
        <div className="f20 flex-column flex-center" style={{paddingTop:"5px", background: "#000", color: "#fff"}}>
            <i className="fa fa-utensils" aria-hidden="true"></i>
            <div style={{paddingTop:"5px", fontSize:"10px"}}>MENYER</div>
        </div>
        <div className="f20 flex-column flex-center" style={{paddingTop:"5px"}}>
            <i className="fa fa-glass-martini" aria-hidden="true"></i>
            <div style={{paddingTop:"5px", fontSize:"10px"}}>DRYCK</div>
        </div>
        <div className="f20 flex-column flex-center" style={{paddingTop:"5px"}}>
            <i className="fa fa-cart-plus" aria-hidden="true"></i>
            <div style={{paddingTop:"5px", fontSize:"10px"}}>ORDER</div>
        </div>
        <div className="f20 flex-column flex-center" style={{paddingTop:"5px"}}>
            <i className="f50 fa fa-shopping-cart" aria-hidden="true"></i>
            <div style={{paddingTop:"5px", fontSize:"10px"}}>BETALA</div>
        </div>


        <div className="f20 flex-column flex-center" style={ this.css_mnu_item("m05") } onClick={() => this.mnu_show("m05") } >
            <i className="f50 fa fa-info" aria-hidden="true"></i>
            <div style={{paddingTop:"5px", fontSize:"10px"}}>INFO</div>
        </div>

    </div>
*/