import React, { useState, useEffect, useRef, useContext } from 'react';
import { parseISO, format, getDate, getMonth, getWeek, eachDayOfInterval, eachWeekOfInterval } from 'date-fns'
import { setMonth, setYear, startOfWeek, endOfWeek, startOfMonth, endOfMonth, addMonths, subMonths, addDays, subDays, isSameDay, isBefore, isAfter } from 'date-fns'
import { sv } from 'date-fns/locale'

import './css/dt_cal.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Fr_Cal extends React.Component {
  constructor(props, context) {
    super(props);

    this.node = React.createRef();
    //this.setNodeRef = this.setNodeRef.bind(this);

    this.state = {};
    this.state.wka = [];
    this.state.lso = {};
    //this.state.lro = props.lro;
    this.state.tya = [
                {id: '01', text: 'Summerad'},
                {id: '02', text: 'Dag för dag'}
            ];
    this.state.aa = [
                {id: '01', text: 'AVD 01'},
                {id: '02', text: 'AVD 02'}
            ];
    this.state.cols = {};
    this.state.cal_show = false;
    this.state.cal_step = 0;

    this.state.row = 0;
    if(props.row) this.state.row = 1;
    this.state.col = 0;
    if(props.col) this.state.col = 1;
    //context.posrend = this;
    this.state.dt_min = props.min;
    this.state.dt_max = props.max;
    this.state.today = new Date();
    this.state.seldat = new Date();
    this.state.dat = new Date();
    this.state.year = "";
    this.state.month = "";
    this.state.dts = "";
    this.state.err = 0;
    this.state.text = props.text;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick);
  }
  componentDidMount() {
    //this.props.fetchWells()
    //  .then(res => this.setState({ wells: res.wells }) )
    //alert(JSON.stringify(this.props));

    if(this.props.setdat) {
      var dat = this.props.setdat;
      var dts = format(dat, "yyyy-MM-dd");
      this.setState({dat: dat, seldat: dat, dts: dts});
    }
    else if(this.props.dts) {
      var dat = new Date(this.props.dts);
      var dts = format(dat, "yyyy-MM-dd");
      this.setState({dat: dat, seldat: dat, dts: dts});
    }
    else if(this.props.startdat) {
      var dat = this.state.dat;
      dat = subDays(dat, 1);
      var dts = format(dat, "yyyy-MM-dd");
      this.setState({dat: dat, seldat: dat, dts: dts});
    }
    else {
      var dat = this.state.dat;
      var dts = format(dat, "yyyy-MM-dd");
      this.setState({dat: dat, seldat: dat, dts: dts});
    }
    document.addEventListener("mousedown", this.handleClick);
    this.set_month(this.state.dat);
  }

  cls() {
    var cls = "";
    cls = "ff_ctrl flex";
    //if(this.props.className) cls = cls + " " + this.props.className;
    if(this.props.noflex) cls = "ff_ctrl";
    return cls;
  }
  clsGroup() {
    var cls = "";
    cls = "form-group";
    if(!this.props.col) cls = cls + " flex-row";
    if(this.props.col) cls = cls + " flex-column";
    cls = cls + " flex";
    return cls;
  }
  clsInput() {
    var cls = "";
    cls = "form-control";
    if(this.props.className) cls = cls + " " + this.props.className;
    return cls;
  }

  updateDt(dts) {
    //alert(JSON.stringify(dts));
    var dat = parseISO(dts, "yyyy-MM-dd");
    this.setState({dat: dat, seldat: dat, dts: dts, cal_show: false, cal_step: 0});
    this.props.getValue(dat);
  }

  updateAdd() {
    var dat = this.state.dat;
    dat = addDays(dat, 1);
    var dts = format(dat, "yyyy-MM-dd");
    this.setState({dat: dat, seldat: dat, dts: dts});
    this.props.getValue(dat);
  }
  updateSub() {
    var dat = this.state.dat;
    dat = subDays(dat, 1);
    var dts = format(dat, "yyyy-MM-dd");
    this.setState({dat: dat, seldat: dat, dts: dts});
    this.props.getValue(dat);
  }

  handleClick = (e) => {
    if(!this.state.cal_show) return;

    if (!this.node.current.contains(e.target)) {
      this.setState({cal_show: false});
      return;
    }
  }

  setYear(year, e) {
    e.preventDefault();

    var dat = setYear(this.state.dat, year);
    this.setState({dat: dat, cal_step: 0});
    this.set_month(dat);
  }
  setMonth(month, e) {
    e.preventDefault();

    var dat = setMonth(this.state.dat, month);
    this.setState({dat: dat, cal_step: 0});
    this.set_month(dat);
  }
  setDt(dat,  e) {
    e.preventDefault();
    var self = this;

    if(this.state.dt_min == "1") {
      if(!isSameDay(dat, this.state.today) && isBefore(dat, this.state.today)) {
        self.setState({err: 1});
        setTimeout(function(){
          self.setState({err: 0});
        }, 1000);
        return;
      }
    }
    if(this.state.dt_max == "1") {
      if(!isSameDay(dat, this.state.today) && isAfter(dat, this.state.today)) {
        self.setState({err: 1});
        setTimeout(function(){
          self.setState({err: 0});
        }, 1000);
        return;
      }
    }
    var dts = format(dat, "yyyy-MM-dd");
    this.props.getValue(dts);
    this.setState({dat: dat, seldat: dat, dts: dts, cal_show: false, cal_step: 0});
  }
  prevMonth(e) {
    e.preventDefault();

    var dat = subMonths(this.state.dat, 1);
    this.setState({dat: dat});
    this.set_month(dat);
  }
  nextMonth(e) {
    e.preventDefault();

    var dat = addMonths(this.state.dat, 1);
    this.setState({dat: dat});
    this.set_month(dat);
  }

  set_month(dat) {
    //var fdat = startOfMonth(this.state.dat);
    //var tdat = endOfMonth(this.state.dat);
    var fdat = startOfMonth(dat);
    var tdat = endOfMonth(dat);

    //var month = getMonth(fdat);
    var year = format(fdat, "yyyy", { locale: sv });
    var month = format(fdat, "MMMM", { locale: sv });
    fdat = startOfWeek(fdat, { weekStartsOn: 1, locale: sv });
    tdat = endOfWeek(tdat, { locale: sv });

    var days = eachDayOfInterval({
        start: fdat,
        end: tdat
    });

    var wko = {};
    var dto = {};
    for(var day of days) {
      var wnr = getWeek(day, { locale: sv });
      var dnr = getDate(day, { locale: sv });
      //alert(wnr);
      if(!wko[wnr]) {
        wko[wnr] = {};
        wko[wnr].week = wnr;
        wko[wnr].days = [];
      }
      wko[wnr].days.push(day);
    }
    var wka = [];
    var keys = Object.keys(wko);
    for(var key of keys){
      var o = wko[key];
      wka.push( o );
    }

    this.setState({year: year, month: month, wka: wka});
  }

  set_week(e) {
    var fdat = new Date(2020, 9, 1);
    var tdat = new Date(2020, 9, 31);

    var weeks = eachWeekOfInterval({
        start: fdat,
        end: tdat
    }, { weekStartsOn: 1 });

    for(var week of weeks) {
      var wnr = getWeek(week);
      var wko = {};
      alert(wnr);
    }
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    tab_style(tabid) {
      var css = {};
      css.display = "none";
      if(this.state.tabval === tabid) css.display = "block";
      return css;
    }
    tab_head(tabid) {
      var css = {};
      css.background = "#fff";
      css.color = "#000";
      if(this.state.tabval === tabid) {
          css.background = "#080";
          css.color = "#fff";
      }
      return css;
    }
  change_fdat(e) {
    //let cdat = this.state.seldate;
    let cdat = e.value;
    let formatted_date = cdat.getFullYear() + "-" + (cdat.getMonth() + 1).toString().tonum(2) + "-" + cdat.getDate().toString().tonum(2);
    this.setState({fromdate: e.value, fdat: formatted_date});
  }
  change_tdat(e) {
    //let cdat = this.state.seldate;
    let cdat = e.value;
    let formatted_date = cdat.getFullYear() + "-" + (cdat.getMonth() + 1).toString().tonum(2) + "-" + cdat.getDate().toString().tonum(2);
    this.setState({todate: e.value, tdat: formatted_date});
  }

    rp_prn() {
		//this.props.ctx.setState({ mr: false });
        //this.setState({ pdf: 1 });
        //this.dr_prn();
    }

    showcal(e) {
      e.preventDefault();

      var dat = parseISO(this.props.text);
      if( isNaN(dat.getTime()) ) {
        dat = new Date();
      }
      var dts = format(dat, "yyyy-MM-dd");
      this.set_month(dat);

      var brc = this.state.cal_show;
      brc = !brc;
      this.setState({dat: dat, seldat: dat, dts: dts, cal_show: brc, cal_step: 0});
    }
    selDays(e) {
      e.preventDefault();

      this.setState({cal_step: 0});
    }
    selYear(e) {
      e.preventDefault();

      if(this.state.cal_step == 2) this.setState({cal_step: 0})
      else this.setState({cal_step: 2});
    }
    selMonth(e) {
      e.preventDefault();

      if(this.state.cal_step == 0) this.setState({cal_step: 1})
      else this.setState({cal_step: 0});
    }

    keydown (e) {
      const keyCode = e.keyCode;
      const key = e.key;
      const value = e.target.value;
      //alert(JSON.stringify(keyCode));

      e.preventDefault();

      if(keyCode == 8) {
        var dts = this.state.dts;
        if(dts.length > 0) dts = dts.substr(0, dts.length - 1);
        //this.setState({dts: dts});
        this.setState({text: dts});
      }
      if(keyCode == 9) {
        //if(!this.state.cal_show) return;
        //this.setState({cal_show: false});
        var dat = new Date(this.props.dts);
        var dts = format(dat, "yyyy-MM-dd");
        this.props.getValue(dts);
        //this.setState({dat: dat, seldat: dat, dts: dts, cal_show: false, cal_step: 0});
        return;
      }

      if(!isNaN(key)) {
        var dts = this.state.dts + key;
        //this.setState({dts: dts});
        this.setState({text: dts});
        //this.props.getValue(dts);
      }
      return;

      //var dat = new Date(2020, key, 15);

      //var dts = format(dat, "yyyy-MM-dd");
      //this.setState({dat: dat, dts: dts});
    }

    formchange (e) {
    return;
        e.preventDefault();
      const value = e.target.value;
      alert("CHANGE: " + JSON.stringify(value));
      var dat = new Date(2020, 12, 15);

      var dts = format(dat, "yyyy-MM-dd");
      this.setState({dat: dat, dts: dts});
/*
      const name = e.target.name;
      const value = e.target.value;
      var pbo = this.state.pbo;
      var na = name.split(".");
      pbo[na[0]][na[1]] = value;
      this.setState({pbo: pbo});
      //setState({[name]: value});
 */
    }
    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    handleClose(e) {
      //alert(JSON.stringify(this.state.dat));
      var dts = format(this.state.dat, "yyyy-MM-dd");
      //this.props.getValue(this.state.dat);
      //alert(JSON.stringify(dts));
      this.props.getValue(dts);
      this.showcal(e);
	};

  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  inputStyle() {
      var css = {};
      if(this.props.rp) {
        css.height = "40px";
        css.lineHeight = "40px";
        css.fontSize = "18px";
        css.borderRadius = "5px";
        css.border = "1px #ccc solid";
      }
      if(this.state.cal_show) {
        css.background = "#080";
        css.color = "#fff";
      }
      return css;
  }
  cssCols (cid) {
      var css = {};
      if(this.state.cols[cid]) css.width = this.state.cols[cid].toString() + "px";
      //if(cid == "c01") alert(JSON.stringify(this.state.cols[cid]));
      return css;
  }
  day_class (day) {
      var cls = "";

      cls = "flex-col flex dt_day";
      if(isSameDay(day, this.state.today)) cls = cls + " dt_today";
      else if(isSameDay(day, this.state.seldat)) cls = cls + " dt_seldat";
      else if(day < this.state.today) cls = cls + " dt_pday";
      //else cls = cls + " dt_day";

      return cls;
  }
  syncCols (tb) {

    if (!document.getElementById(tb)) return;

    var cols = {};
    var ix = document.getElementById(tb).rows.length - 1;
    var ic = document.getElementById(tb).rows[0].cells.length;
    var ci = 0;
    while(ci < ic) {
        var cxi = ci + 1;
        var col = "c" + cxi.toString().padStart(2, "0");
        cols[col] = document.getElementById(tb).rows[ix].cells[ci].clientWidth + 1;
        ci = ci + 1;
    }
    this.setState({cols: cols });
    //alert(JSON.stringify(this.state.cols));
    //this.forceUpdate();
  };
  cal_style() {
    var css = {};

    if(!this.node.current) return css;

    var max_w = window.innerWidth;
    var pos_h = this.node.current.getBoundingClientRect().top;
    var pos_w = this.node.current.getBoundingClientRect().left;
    var left = this.node.current.offsetLeft + 100;
    var aboveTop = ((this.node.current.offsetTop * 1) - 350);
    var out = false;

    css.left = left + "px";
    if(max_w < (pos_w + 400)) out = true;
    if(out) css.right = "5px";
    if(this.state.err) css.background = "#800";
    if(this.props.mid) css.top = (pos_h - 75).toString() + "px";
    if(this.props.above) css.top = aboveTop.toString() + "px";

    return css;
  }

  render() {
    //if(!this.props.show) return "";

    //alert(this.props.ctx.state.lro);

    var title = this.props.title;
    if(!title) title = "KALENDER";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div ref={this.node} className={ this.cls() }>
        <div className={ this.clsGroup() }>
            <span className="header txt_12">{ this.props.label }</span>
            <input type="text" className={ this.clsInput() }
                   onClick={(e) => this.showcal(e) }
                   onKeyDown={(e) => this.keydown(e) }
                   onChange={(e) => this.formchange(e)}
                   value={this.props.text}
                   placeholder={this.state.placeholder}
            />
        </div>

       { this.state.cal_show ?
        <div className="dt_cal flex flex-col" style={ this.cal_style() }>
        <div className="dt_head flex-row flex-space-between">

            <div className="txt_btn" onClick={(e) => this.prevMonth(e) }>
                <FontAwesomeIcon icon="angle-double-left" size="1x"/>
            </div>
            <div className="flex-row"></div>
            <div className="txt_btn text-capitalize" onClick={(e) => this.selMonth(e) }>
                <div>{ this.state.month }</div>
            </div>
            <div className="txt_btn text-capitalize" onClick={(e) => this.selYear(e) }>
                <div>{ this.state.year }</div>
            </div>
            <div className="flex-row"></div>
            <div className="txt_btn" onClick={(e) => this.nextMonth(e) }>
                <FontAwesomeIcon icon="angle-double-right" size="1x"/>
            </div>
        </div>
       { this.state.cal_step == 0 ?
        <div className="dt_content flex">
            <div className="flex-row">
                    <div className="flex-col dt_top_week">W</div>
                    <div className="flex-col flex dt_top">M</div>
                    <div className="flex-col flex dt_top">T</div>
                    <div className="flex-col flex dt_top">O</div>
                    <div className="flex-col flex dt_top">T</div>
                    <div className="flex-col flex dt_top">F</div>
                    <div className="flex-col flex dt_top">L</div>
                    <div className="flex-col flex dt_top">S</div>
            </div>
            <div className="divider_orange"></div>

            { this.state.wka.map((row, key) =>
            <div key={"wk_" + key} className="flex-row">
                    <div className="flex-col dt_week">
                        <div>{ row.week }</div>
                    </div>
            { row.days.map((day, key) =>
                    <div key={"dt_" + key} className={ this.day_class(day) } onClick={(e) => this.setDt(day, e) }>
                      { getDate(day) }
                    </div>
            )}
            </div>
            )}


        </div>
        : null }
       { this.state.cal_step == 1 ?
        <div className="dt_content flex">
            <div className="flex-row">
                    <div className="flex dt_month" onClick={(e) => this.setMonth(0, e) }>Januari</div>
                    <div className="flex dt_month" onClick={(e) => this.setMonth(1, e) }>Februari</div>
                    <div className="flex dt_month" onClick={(e) => this.setMonth(2, e) }>Mars</div>
            </div>
            <div className="flex-row">
                    <div className="flex dt_month" onClick={(e) => this.setMonth(3, e) }>April</div>
                    <div className="flex dt_month" onClick={(e) => this.setMonth(4, e) }>Maj</div>
                    <div className="flex dt_month" onClick={(e) => this.setMonth(5, e) }>Juni</div>
            </div>
            <div className="flex-row">
                    <div className="flex dt_month" onClick={(e) => this.setMonth(6, e) }>Juli</div>
                    <div className="flex dt_month" onClick={(e) => this.setMonth(7, e) }>Augusti</div>
                    <div className="flex dt_month" onClick={(e) => this.setMonth(8, e) }>September</div>
            </div>
            <div className="flex-row">
                    <div className="flex dt_month" onClick={(e) => this.setMonth(9, e) }>Oktober</div>
                    <div className="flex dt_month" onClick={(e) => this.setMonth(10, e) }>November</div>
                    <div className="flex dt_month" onClick={(e) => this.setMonth(11, e) }>December</div>
            </div>
        </div>
        : null }
       { this.state.cal_step == 2 ?
        <div className="dt_content flex">
            <div className="flex-row">
                    <div className="flex dt_year" onClick={(e) => this.setYear(2017, e) }>2017</div>
                    <div className="flex dt_year" onClick={(e) => this.setYear(2018, e) }>2018</div>
                    <div className="flex dt_year" onClick={(e) => this.setYear(2019, e) }>2019</div>
            </div>
            <div className="flex-row">
                    <div className="flex dt_year" onClick={(e) => this.setYear(2020, e) }>2020</div>
                    <div className="flex dt_year" onClick={(e) => this.setYear(2021, e) }>2021</div>
                    <div className="flex dt_year" onClick={(e) => this.setYear(2022, e) }>2022</div>
            </div>
            <div className="flex-row">
                    <div className="flex dt_year" onClick={(e) => this.setYear(2023, e) }>2023</div>
                    <div className="flex dt_year" onClick={(e) => this.setYear(2024, e) }>2024</div>
                    <div className="flex dt_year" onClick={(e) => this.setYear(2025, e) }>2025</div>
            </div>
        </div>
        : null }

        <div className="dt_foot flex-row flex-space-between">
            <div className="foot_btn" onClick={(e) => this.selDays(e) }>
                DAGAR
            </div>
            <div className="flex-row"></div>
            <div className="foot_btn" onClick={(e) => this.selMonth(e) } >
                MÅNAD
            </div>
            <div className="flex-row"></div>
            <div className="foot_btn" onClick={(e) => this.selYear(e) } >
                ÅR
            </div>
        </div>

        </div>
        : null }

        </div>
	;
	return html;
  };
}

export default Fr_Cal;