import React, { useState, useEffect, useContext } from 'react';
import * as net from "../lib/net";
import {Route, Routes} from "react-router-dom";

import Mark_Home from "./mark/home";
import Mark_Ctrl from "./mark/ctrl";
import Mark_Cash from "./mark/cash";
import Mark_Pass from "./mark/pass";
import Mark_Logg from "./mark/logg";
import ddo from "../ddo";
import {withRouter} from "../lib/react/withRouter";
import sso from "../sso";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Cas_Mark extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, tra: [] };
    this.state.seldate = new Date();
    this.state.unit = null;
      this.state.una = Object.values(ddo.cfg.units);
      this.state.uno = ddo.cfg.units;
    let cdat = new Date();
    let formatted_date = cdat.getFullYear() + "-" + (cdat.getMonth() + 1) + "-" + cdat.getDate();
    this.state.today = formatted_date;
    this.state.datum = formatted_date;
      var po = {};
      po.ctrl = { "fid": "f06", "url": "/cas/mark/ctrl", "name": "Kontroll", "active": false };
      po.cash = { "fid": "f06", "url": "/cas/mark/cash", "name": "Kassa", "active": false };
      po.pass = { "fid": "f06", "url": "/cas/mark/pass", "name": "Pass", "active": false };
      po.logg = { "fid": "f06", "url": "/cas/mark/logg", "name": "Logg", "active": false };
      this.state.po = po;
      this.state.pa = [];
      this.state.pa = Object.values(po);
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

    fknGo(row) {
        sso.seo.sfkn = row;
        this.props.navigate(row.url);
    }
    br_css(row) {
        var css = {};
        if(sso.seo.sfkn && (row.url == sso.seo.sfkn.url)) css.color = "#080";
        //var brc = false;
        //if(row.url == sbo.state.url) brc = true;
        return css;
    }

  render() {
    return (
        <div className="web-app">
            { this.props.store.cfg.bread ?
                <div className="web-bread">
                    <div className="breadcrumb">
                        { this.state.pa.map((row, key) =>
                            <div key={key} className="breadcrumb-item" style={this.br_css(row)} onClick={() => this.fknGo(row)}>{row.name}</div>
                        )}
                        <div className="flex"></div>
                        <div className="cdigreen">{ sso.seo.sfkn ? sso.seo.sfkn.name : null }</div>
                    </div>
                </div>
                : null }
        <Routes>
            <Route path="ctrl" element={ <Mark_Ctrl store={this.webStore} />} />
            <Route path="cash" element={ <Mark_Cash store={this.webStore} />} />
            <Route path="pass" element={ <Mark_Pass store={this.webStore} />} />
            <Route path="logg" element={ <Mark_Logg store={this.webStore} />} />
            <Route path="*" element={ <Mark_Home store={this.webStore} />} />
        </Routes>
    </div>
  );
}
}
export default withRouter(Cas_Mark);

/*
    <td>{ this.state.uno[row.r0cstplc].text }</td>
*/