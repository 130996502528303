import React from 'react';

const langdb = {};
langdb.sv = {};
langdb.sv["Arrival"] = "Ankomst";
langdb.sv["Departure"] = "Avresa";
langdb.sv["Resno"] = "Boknr";
langdb.sv["Company"] = "Företag";
langdb.sv["Name"] = "Namn";
langdb.sv["Guests"] = "Gäster";
langdb.sv["Roomnr"] = "Rumsnr";
langdb.sv["Roomtype"] = "Rumstyp";
langdb.sv["Price"] = "Pris";
langdb.sv["Balance"] = "Balans";
langdb.sv["Topay"] = "Att Betala";
langdb.sv["Paytype"] = "Betalning";
langdb.sv["Type"] = "Typ";
langdb.sv["Arrivaltime"] = "Ankomsttid";
langdb.sv["11"] = "Testinn";

langdb.no = {};
langdb.no["Arrival"] = "Ankunft";
langdb.no["Departure"] = "Afreise";

export default langdb;