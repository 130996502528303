import React, { useState, useEffect, useContext } from 'react';
import {Route, Routes} from "react-router-dom";
import {withRouter} from "../lib/react/withRouter";
import { observer } from 'mobx-react'

import Eko_Home from './eko/home.js';
import Eko_Rps from './eko/rps.js';
import Eko_Trs from './eko/trs.js';
import Eko_Kvitton from './eko/kvitton.js';
import Eko_Orders from './eko/orders.js';
import Eko_Swish from './eko/swish.js';
import sso from "../sso";

function pg_home() {

    var html =
    <div className="web-content">
      Hem
    </div>
    ;
    return html;
}

class Tmpl_Eko extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.state = { pgid: 11, deps: [] };
    //context.posrend = this;
      var po = {};
      po.rps = { "fid": "f05", "url": "/tmpl/eko/rps", "name": "Rapporter", "active": false };
      po.trs = { "fid": "f05", "url": "/tmpl/eko/trs", "name": "Transaktioner", "active": false };
      po.kvitton = { "fid": "f05", "url": "/tmpl/eko/kvitton", "name": "Kvitton", "active": false };
      po.orders = { "fid": "f05", "url": "/tmpl/eko/orders", "name": "Ordrar", "active": false };
      po.swish = { "fid": "f05", "url": "/tmpl/eko/swish", "name": "Swish", "active": false };
      //po.pt = { "fid": "f05", "url": "/tmpl/eko/pt", "name": "Pristabell", "active": false };
      this.state.po = po;
      this.state.pa = [];
      this.state.pa = Object.values(po);
  }
    componentDidUpdate() {
    }
    componentWillUnmount() {
    }
    componentDidMount() {
      /*
        var path = window.location.pathname;
        if(path.substr(-1) == "/") path = path.substring(0, path.length - 1);
        var op = path.split("/").pop();

        var po = this.state.po;
        if(po[op]) {
            po[op].active = true;
            //this.props.store.fkn = po[op];
            sbo.state.fkn = po[op].name;
            //alert(JSON.stringify(sbo.state));
        }
        var pa = Object.values(po);
        this.setState({ po: po, pa: pa });

       */
    }
    fknGo(row) {
        sso.seo.sfkn = row;
        this.props.navigate(row.url);
    }
    br_css(row) {
        var css = {};
        if(sso.seo.sfkn && (row.url == sso.seo.sfkn.url)) css.color = "#080";
        //var brc = false;
        //if(row.url == sbo.state.url) brc = true;
        return css;
    }

    render() {
        return (
            <div className="web-app">
                { this.props.store.cfg.bread ?
                    <div className="web-bread">
                        <div className="breadcrumb">
                            { this.state.pa.map((row, key) =>
                                <div key={key} className="breadcrumb-item" style={this.br_css(row)} onClick={() => this.fknGo(row)}>{row.name}</div>
                            )}
                            <div className="flex"></div>
                            <div className="cdigreen">{ sso.sdo.sfkn ? sso.sdo.sfkn.name : null }</div>
                        </div>
                    </div>
                    : null }
        <Routes>
          <Route path="/xx" component={pg_home} />
            <Route path="rps" element={ <Eko_Rps store={this.store} />} />
            <Route path="trs" element={ <Eko_Trs store={this.store} />} />
            <Route path="kvitton" element={ <Eko_Kvitton store={this.store} />} />
            <Route path="orders" element={ <Eko_Orders store={this.store} />} />
            <Route path="swish" element={ <Eko_Swish store={this.store} />} />
            <Route path="*" element={ <Eko_Home store={this.store} />} />
        </Routes>
    </div>
  );
}
}
//Sys.contextType = ADOContext;

export default withRouter(observer(Tmpl_Eko));
