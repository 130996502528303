import React, { useState, useEffect, useContext } from 'react';
import { observer } from 'mobx-react';

function Trs_Top(props) {
    var it = {};
    it.name = "TesTe";
    var app = {};
    app.name = "TesTe";
    var sdo = {};
    sdo.name = "TesTe";
    sdo.user = {};
    sdo.user.text = "TesTe";
    var app = {};
    app.sub_active = "11";
    app.fkn_active = "22";

    var fa = "fa fa-key";
    const [anchorEl, setAnchorEl] = React.useState(null);

      const handleOpen = event => {
        setAnchorEl(event.currentTarget);
      };

      const handleClose = () => {
        setAnchorEl(null);
      };

      const goFull = () => {
        alert("Full");
      };

    var html =
        <div className="flex flex-row flex-space-between">
            <div className="flex-row flex-vcenter">
                <button className="btn btn-icon" onClick={handleOpen}>
                </button>
                <div>Telefoni</div>
            </div>

        <button className="btn btn-icon" onClick={handleOpen}>
        </button>

        </div>
    ;
    return html;
}

export default observer(Trs_Top);
