import React, { useState, useEffect, useContext } from 'react';
import ReactDOM from 'react-dom';
import { SDOContext } from '../sdo.js'

//import './css/style-liberty.css';

import * as net from "../lib/net";
import Dd_Sel from "../lib/ui/dd_sel";
import {withRouter} from "../lib/react/withRouter";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function web01() {

    var html =
    <div className="">
        Test
    </div>
    ;
    return html;
}

class Renew extends React.Component {
    //var sdo = useContext(SDOContext); // Functional
    static contextType = SDOContext; // Class

    constructor(props, context) {
        super(props);

        this.s1ref = React.createRef();
        this.s2ref = React.createRef();

        this.store = props.store;
        this.seo = props.store.seo;

        this.state = { pgid: 11, stat: [] };
        this.state.open = false;
        this.state.pdfopen = false;
        this.state.xxopen = false;
        this.state.pdf_uri = "";
        this.state.seldate = new Date('2020-03-24T13:11:54');
        this.state.row = null;
        this.state.info = "";
        this.state.top = 1;
        this.state.user = {};
        this.state.sdo = {};
        //this.state.sdo = context;
        //context.posrend = this;
        this.state.step = 1;
        this.state.pdf = 0;
        this.state.pdf_uri = "";
        this.state.mnus = ["m00", "m01", "m02", "m03", "m08", "m09"];
        this.state.mnu_sel = "m00";
        this.selitems = [
            {fkn: 'setup', text: 'INSTÄLLNINGAR'},
            {fkn: 'upd', text: 'UPPDATERA'},
            {fkn: 'sys', text: 'SYSTEM'}
        ];

    }
    componentDidMount(){
    }

    componentWillUnmount(){
    }
    logo(){
        var logo = null;
        if(this.store.cfg.logo == "cdi") logo = require('./img/logo-cdi.png');
        else logo = require('./img/logo-sk.png');
        return logo;
    }
    onScroll(event) {
        var body = document.getElementById("web-body");
        if (body.scrollTop > 150) {
          this.setState({top: 0})
          this.setState({info: 'small'})
        } else {
          this.setState({top: 1})
          this.setState({info: 'big:'+body.scrollTop})
        }

        if (true) {
            var offset = body.scrollTop;
            var start = body.scrollTop;
            var slut = body.scrollTop + window.innerHeight;

            for(var mnuid of this.state.mnus) {
                const element = document.getElementById(mnuid);
                if(!element || (element == null) || (element == undefined)) continue;
                var eltop = element.offsetTop;
                var elbot = element.offsetTop + element.offsetHeight;
                var brc = eltop >= start && eltop <= slut;
                //if(!brc) brc = elbot >= start && elbot <= slut;
                if(brc) {
                    this.setState({mnu_sel: mnuid});
                    return;
                }
            }
        }
    }
   header_css() {
  	    var css = {};
		if(this.state.top) {
			css.color = "#fff";
			css.background = "transparent";
			css.height = "50px";
			//css["background"] = "green";
		}
		else {
			css.color = "#000";
			css.background = "#fff";
		    css.height = "50px";
        }

		return css;
    }
   ham_mnu_css() {
  	    var css = {};
		if(this.state.top) {
			css.display = "flex";
			css.display = "none";
		}
		else {
			css.display = "none";
        }

		return css;
    }
    header_class() {
  	    var cls = "flex_col";
		if(this.state.top) {
			cls = cls + " web-head_1";
		}
		else cls = cls +  " web-header";

		return cls;
    }

    ham_show() {
         this.setState({ isOpen: !this.state.isOpen });
   }
    ham_ol() {
        //alert("OL : ..");
         //this.setState({ isOpen: 0 });
   }
    sdo_upd() {
        var sdo = this.state.sdo;
        if(!sdo.fid) sdo.fid = "0";
        sdo.fid = ((sdo.fid * 1) + 1).toString();
        this.setState({sdo: sdo})
        sessionStorage.setItem("svp", JSON.stringify(this.state.sdo));
        //this.props.history.push("cas");
    }
    sdo_ctx() {
        sessionStorage.removeItem('svp');
        //sessionStorage.clear();
    }
    sdo_cas() {
        this.context.fid = "45";
        this.props.navigate("cas");
    }
    ct_show(id) {
        //alert(id);
        const element = document.getElementById(id);
        if (element) element.scrollIntoView();
        //const tesNode = ReactDOM.findDOMNode(id);
        //window.scrollTo(0, tesNode.offsetTop);
    }
    w00_css() {
  	    var css = {};
        css.height = window.innerHeight;
        css['minHeight'] = window.innerHeight;
		return css;
    }
    cdi_step() {
        //alert(id);
        var step = this.state.step + 1;
        if(step > 2) step = 1;
        this.setState({ step: step });
        if(step == 1) {
            var element = ReactDOM.findDOMNode(this.s1ref.current);
            element.classList.add("animated", "rotateIn");
            element.addEventListener('animationend', function() {
                element.classList.remove('animated', "rotateIn")
                //node.removeEventListener('animationend', handleAnimationEnd)
            })
        }
        if(step == 2) {
            var element = ReactDOM.findDOMNode(this.s2ref.current);
            element.classList.add("animated", "bounceIn");
            element.addEventListener('animationend', function() {
                element.classList.remove('animated', "bounceIn")
                //node.removeEventListener('animationend', handleAnimationEnd)
            })
        }

         setTimeout(() => {
            this.cdi_step();
        }, 5000);
    }
    cdi_show(id) {
        //alert(id);
        var brc = false;
        if(this.state.step == id) brc = true;
        return brc;
    }

    row_css(row) {
  	    var css = {};
		if(this.state.row) {
		if(this.state.row.ank == row.ank) {
			css.background = "#080";
			css.color = "#fff";
		}
		}
		return css;
    }
    login() {
        this.setState({login: true });
        //this.props.history.push("pos");
    }
    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    handleLogin(event) {
        this.login_srv(this.state.user.uid, this.state.user.pwd);
    }
	handleClose = () => {
		//this.props.ctx.setState({ login: false });
	};
    login_srv(uid, pwd) {
        var self = this;
		var prm = {};
        prm.req = "usr:login";
		prm.uid = uid;
		prm.pwd = pwd;
		prm.token = "";

		//alert(JSON.stringify(uid));
        //gda.wndLoading("Hämtar");
        net.gcs_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret));

            if(ret.ok != "000") {
                alert(JSON.stringify(ret));
                return;
            }
            //alert(JSON.stringify(ret.seo));

            var seo = ret.seo;
            //alert(JSON.stringify(seo));
            self.props.store.seo = seo;
            var xdo = JSON.stringify(seo);
            //alert(JSON.stringify(xdo));
            sessionStorage.setItem("svp", xdo);
            var gdo = JSON.stringify(ret.gdo);
            //sessionStorage.setItem("gdo", gdo);

            var url = seo.auto;
            if(url == "casino.ov") url = "cas.ov";
            url = "/" + url.replace(/\./g,"/");
            if(true) url = "/hello";

            if(self.props.navigate == null) {
                alert("Ctx");
                //alert(self.context.history);
                self.context.navigate(url);
                //alert("Done");
           }
            else {
                //alert("Props");
                //alert(self.props.history);
                self.props.navigate(url);
                //self.props.history.go(0);
                //self.forceUpdate();
                //alert("Done");
            }
        })
        .catch(function(e){
            //var txt = JSON.stringify(e);
            alert("LOGIN ERR: " + JSON.stringify(e));
            //gda.wndError(txt);
        });
    }
  sel_fkn(sel) {
    //alert(JSON.stringify(fkn));
      if(sel.fkn == "logout") {
        var url = "/logout";
        this.props.navigate(url);
      }
      if(sel.fkn == "sys") {
        var url = "/bo";
        this.props.navigate(url);
      }
  }
  home() {
    //alert(JSON.stringify(fkn));
    var url = "/";
    this.props.navigate(url);
  }

    render() {
        var stat = [];
        var keys = Object.keys(this.state.stat);
        for (var key of keys) {
            var row = this.state.stat[key];
            stat.push(row);
        }
        const handleDateChange = date => {
            this.setState({seldate: date });
        };

        return (
    <div className="web-app">
    <div className="web-top">

            <div id="header" className={ this.header_class() }>
            <div className="web-top-content d-flex flex-row">
                <div className="px-3 web-top-icon" onClick={() => this.home() }>
                    <FontAwesomeIcon icon="home" size="1x"/>
                </div>
                <div className="web-top-mnu d-none d-md-inline-flex flex-row">
                    <div className="item">Omregistrering</div>
                </div>
                <div className="flex-fill"></div>
                <div className="px-3 web-top-txt">
                    { this.seo.name }
                </div>
                <div className="px-3 web-top-icon" onClick={() => this.login() }>
                    <FontAwesomeIcon icon="user" size="1x"/>
                </div>
                <div className="">
                    <Dd_Sel items={ this.selitems } getValue={(e)=> this.sel_fkn(e)} />
                </div>
            </div>
            </div>
    </div>


    <div className="web-main">

    <div className="web-body">

    <div>
                <div className="login__header text-center mb-lg-5 mb-4">
                    <h3 className="login__title mb-2"> Login</h3>
                    <p>Registrera Er</p>
                </div>
                <div className="divider_orange"></div>

            <div className="flex flex-row px-5">
            <div className="flex-column">
                <div className="web-login">
                <div className="form-group">
                    <label className="">Namn</label>
                    <input type="text" className="form-control w350" placeholder="" required="" autoFocus
                      value={this.state.uid} onChange={(event) => this.val_save(event)}
                    />
                </div>
                <div className="form-group">
                    <label className="">Epost</label>
                    <input type="text" className="form-control w350" placeholder="" required="" autoFocus
                      value={this.state.uid} onChange={(event) => this.val_save(event)}
                    />
                </div>
                <div className="form-group">
                    <label className="">Lösenord</label>
                    <input type="password" className="form-control input-style w250" placeholder="" required
                       value={this.state.pwd} onChange={(event) => this.val_save(event)}
                    />
                </div>
                <div className="d-flex align-items-center justify-content-between">
                    <button onClick={(event) => this.cancel_reg(event) } className="btn btn-hw-blue w150">
                        Rensa
                    </button>
                    <button onClick={(event) => this.send_reg(event) } className="btn btn-hw-green w150">
                        Registrera
                    </button>
                </div>

                </div>
                <div className="">
                    <img className="svk-logo" src={ this.logo() } />
                </div>
            </div>
            <div className="flex flex-row px-5">
                <div className="txt_18">Registrering</div>
                <div className="divider_orange"></div>

            </div>
            </div>
        </div>
        </div>
        </div>

        </div>
        );
    }
}

//WebPub.contextType = SDOContext; // Function
export default withRouter(Renew);