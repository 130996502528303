import React, { useState, useEffect, useContext } from 'react';
import {format, getHours, subDays} from "date-fns";

import * as utils from "../../assets/utils";
import * as net from "../../lib/net";
import Lang from "../../lib/lang";

import Td_Sel from "../../lib/ui/td_sel";

import Pms_AvrSel from "./avr/avr_sel";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Pms_Avr extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, tra: [] };
    this.state.boknr = "";
    this.state.rva = [];
    this.state.rvo = {};
    this.state.aa = [];
    this.state.wnds = {};
    this.state.sortid = "";
    this.state.sortdir = 1;
    this.state.loading = false;

    this.state.today = new Date();
    var hh = getHours(this.state.today);
    if( hh < 5) {
        const dt = subDays(new Date(), 1);
        this.state.dts = format(dt, "yyyyMMdd");
    }
    else this.state.dts = format(this.state.today, "yyyyMMdd");

    this.selitems = [
        {fkn: 'open', text: 'ÖPPNA'},
        {fkn: 'co', text: 'CHECKA UT'}
    ];
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.avr();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
    var css = {};
    css.height = window.innerHeight - 250;
    css.overflow = "auto";
    return css;
  }
  th_style(id) {
      var css = {};
      css.cursor = "pointer";
      if(id == this.state.sortid) css.color = "#080";
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }
  sel_gst(row) {
    var self = this;
      var wnds = {};
      wnds.avrsel = true;
      wnds.boknr = row.boknr;
      wnds.rvo = row;
    this.setState({wnds: wnds});
  }
  sel_row_fkn(row, sel) {
      if(sel.fkn == "open") {
          this.sel_gst(row);
      }
  }

  xavr() {
    var self = this;
    var prm = {};
    prm.req = "pms.op.my_departures";

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        self.setState({rva: ret.rco });
        self.syncTb("avrTable");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  avr() {
    var self = this;
      this.setState({rvs: [], rva: [], loading: true});

    var prm = {};
    prm.req = "pms.op.my_inhouse";

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        var rva = [];
        var row = {};
        for(row of ret.rca) {
            //alert(JSON.stringify(row.resv00));

            //row.charges.saldo = "0";
            //row.charges.payments = "0";
            //row.charges.topay = "0";
            if(row.gsb00) {
                if(row.gsb00.r0avrdatum <= self.state.dts) {
                    rva.push(row);
                }
            }
        }
        self.setState({ rva: rva, loading: false }, function() {
            self.syncTb("avrTable");
        });
        //self.setState({ rva: ret.rca });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
    syncTb(tb){
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
        tdElements = tbel.getElementsByTagName('td');

        for (let i = 0; i < thElements.length; i++) {
            if(!tdElements[i]) continue;
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
            width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
  val_date(val) {
      var ret = "";
      if(val) ret = val.toString().cdidate();
      return ret;
  }
  val_rum(val) {
      var ret = "";
      if(val) ret = val.toString().cdirumsnr();
      return ret;
  }
  val_pris(val) {
      var ret = "";
      if(val) ret = val.toString().cdipris();
      return ret;
  }
   tb_sort(typ) {
      var self = this;
      //var rvs = self.state.rvs;
      var rva = self.state.rva;
      var sz = this.state.sz_val;
      var sortid = this.state.sortid;
      var sortdir = this.state.sortdir;

      if(sortid != typ) sortdir = 0;
      else {
        if(sortdir) sortdir = 0;
        else sortdir = 1;
      }

      var val1 = 1, val2 = -1, val3 = 0;
      if(sortdir == 0) { val1 = -1; val2 = 1; val3 = 0; }

      if(typ == "ank") {
        rva.sort((a,b) => (a.gsb00.r0ankdatum > b.gsb00.r0ankdatum) ? val1 : ((b.gsb00.r0ankdatum > a.gsb00.r0ankdatum) ? val2 : val3));
      }
      if(typ == "avr") {
        rva.sort((a,b) => (a.gsb00.r0avrdatum > b.gsb00.r0avrdatum) ? val1 : ((b.gsb00.r0avrdatum > a.gsb00.r0avrdatum) ? val2 : val3));
      }
      if(typ == "boknr") {
        rva.sort((a,b) => (a.gsb00.r0boknr > b.gsb00.r0boknr) ? val1 : ((b.gsb00.r0boknr > a.gsb00.r0boknr) ? val2 : val3));
      }
      if(typ == "ftg") {
        rva.sort((a,b) => (a.resv00.Company > b.resv00.Company) ? val1 : ((b.resv00.Company > a.resv00.Company) ? val2 : val3));
      }
      if(typ == "namn") {
        rva.sort((a,b) => (a.gsb00.r0namn > b.gsb00.r0namn) ? val1 : ((b.gsb00.r0namn > a.gsb00.r0namn) ? val2 : val3));
      }
      if(typ == "rumsnr") {
        rva.sort((a,b) => (a.gsb00.r0rumsnr > b.gsb00.r0rumsnr) ? val1 : ((b.gsb00.r0rumsnr > a.gsb00.r0rumsnr) ? val2 : val3));
      }
      if(typ == "rt") {
        rva.sort((a,b) => (a.rblx0.r0typ > b.rblx0.r0typ) ? val1 : ((b.rblx0.r0typ > a.rblx0.r0typ) ? val2 : val3));
      }
      if(typ == "saldo") {
        rva.sort((a,b) => (a.charges.saldo > b.charges.saldo) ? val1 : ((b.charges.saldo > a.charges.saldo) ? val2 : val3));
      }
      if(typ == "topay") {
        rva.sort((a,b) => (a.charges.topay > b.charges.topay) ? val1 : ((b.charges.topay > a.charges.topay) ? val2 : val3));
      }
      if(typ == "bettyp") {
        rva.sort((a,b) => (a.bettyp > b.bettyp) ? val1 : ((b.bettyp > a.bettyp) ? val2 : val3));
      }
      if(typ == "segment") {
        rva.sort((a,b) => (a.resm01.Kanal > b.resm01.Kanal) ? val1 : ((b.resm01.Kanal > a.resm01.Kanal) ? val2 : val3));
      }
      self.setState({ rva: rva, sortid: typ, sortdir: sortdir });
   }

  render() {
    return (
    <div className="web-bas">

    <div className="flex-row">
        <button className="btn btn-hw-blue w150 ripple" onClick={() => this.plus() } aria-label="System" >
            GÄST
        </button>
        <button className="btn btn-hw-blue w150 ripple" onClick={() => this.tst() } aria-label="System" >
            RUM
        </button>
        <div className="flex"></div>
        <button className="btn btn-hw-blue w150 ripple" onClick={() => this.tst() } aria-label="System" >
            NOTA
        </button>
    </div>
    <div className="divider_green"></div>

    { this.state.loading ? <div className="flex flex-col flex-center"><FontAwesomeIcon icon="spinner" spin size="4x" /></div> : null }
    { !this.state.loading ?
    <div className="web-row">

        <div id="avrTable" className="flex">
          <table className="table table-bordered table-hover tb" style={{marginBottom: "0px"}}>
            <thead>
            <tr>
                <th style={ this.th_style("ank") } onClick={() => this.tb_sort("ank") }><Lang>Arrival</Lang></th>
                <th style={ this.th_style("avr") } onClick={() => this.tb_sort("avr") }><Lang>Departure</Lang></th>
                <th style={ this.th_style("boknr") } onClick={() => this.tb_sort("boknr") }><Lang>Resno</Lang></th>
                <th style={ this.th_style("segment") } onClick={() => this.tb_sort("segment") }><Lang>Segment</Lang></th>
                <th style={ this.th_style("ftg") } onClick={() => this.tb_sort("ftg") }><Lang>Company</Lang></th>
                <th style={ this.th_style("namn") } onClick={() => this.tb_sort("namn") }><Lang>Name</Lang></th>
                <th style={ this.th_style("nrg") } onClick={() => this.tb_sort("nrg") }><Lang>Guests</Lang></th>
                <th style={ this.th_style("rumsnr") } onClick={() => this.tb_sort("rumsnr") }><Lang>Roomnr</Lang></th>
                <th style={ this.th_style("rt") } onClick={() => this.tb_sort("rt") }><Lang>Roomtype</Lang></th>
                <th style={ this.th_style("saldo") } onClick={() => this.tb_sort("saldo") }><Lang>Balance</Lang></th>
                <th style={ this.th_style("topay") } onClick={() => this.tb_sort("topay") }><Lang>Topay</Lang></th>
                <th style={ this.th_style("bettyp") } onClick={() => this.tb_sort("bettyp") }><Lang>Paytype</Lang></th>
                <th></th>
            </tr>
            </thead>
        </table>
        <div style={ this.tb_style() }>
          <table className="table table-bordered table-hover tb tbs02">
            <tbody>
            { this.state.rva.map((row, key) =>
            <tr key={key} onDoubleClick={ () => this.sel_gst(row) }>
                <td>{ utils.toDate( row.gsb00.r0ankdatum )}</td>
                <td>{ utils.toDate( row.gsb00.r0avrdatum )}</td>
                <td>{ row.gsb00.r0boknr }</td>
                <td>{ utils.toLang( row.resm01.Kanal ) }</td>
                <td>{ row.resv00.Company }</td>
                <td className="bold">{ row.gsb00.r0namn }</td>
                <td>{ row.gsb00.r0guests }</td>
                <td className="bold text-center">{ utils.toRumsnr( row.gsb00.r0rumsnr ) }</td>
                <td>{ row.rblx0.r0typ }</td>
                <td>{ utils.toPris( row.charges.saldo ) }</td>
                <td>{ utils.toPris( row.charges.topay ) }</td>
                <td>{ row.bettyp }</td>
                <td>
                    <Td_Sel items={ this.selitems } getValue={(e)=> this.sel_row_fkn(row, e)} />
                </td>
            </tr>
            )}
            </tbody>
        </table>
        </div>
        </div>
    </div>
    : null }

    { this.state.wnds.avrsel ?
        <Pms_AvrSel wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
    : null }
    </div>
  );
    }
}
export default Pms_Avr;
