import React, { useState, useEffect, useContext } from 'react';
import {Route, Routes} from "react-router-dom";

import ddo from '../ddo.js'

import Th_Home from './th/home.js';
import Th_Act from './th/act.js';
import Th_Per from './th/per.js';
import Th_Avd from './th/avd.js';
import Th_Trs from './th/trs.js';
import Th_Day from './th/day.js';
import Th_Week from './th/week.js';
import {observer} from "mobx-react";
import {withRouter} from "../lib/react/withRouter";
import Ta_Home from "./home";
import sso from "../sso";

function pg_home() {

    var html =
    <div className="web-content">
      Hem
    </div>
    ;
    return html;
}

class Ta_Th extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, deps: [] };
      var po = {};
      po.act = { "fid": "f06", "url": "/ta/th/act", "name": "Aktiva", "active": false };
      po.per = { "fid": "f06", "url": "/ta/th/per", "name": "Period", "active": false };
      po.avd = { "fid": "f06", "url": "/ta/th/avd", "name": "Avdelning", "active": false };
      po.trs = { "fid": "f06", "url": "/ta/th/trs", "name": "Transaktioner", "active": false };
      po.day = { "fid": "f06", "url": "/ta/th/day", "name": "Dag", "active": false };
      po.week = { "fid": "f06", "url": "/ta/th/week", "name": "Vecko", "active": false };
      this.state.po = po;
      this.state.pa = [];
      this.state.pa = Object.values(po);
  }
    fknGo(row) {
        sso.seo.sfkn = row;
        this.props.navigate(row.url);
    }
    br_css(row) {
        var css = {};
        if(sso.seo.sfkn && (row.url == sso.seo.sfkn.url)) css.color = "#080";
        //var brc = false;
        //if(row.url == sbo.state.url) brc = true;
        return css;
    }

  render() {

    return (
        <div className="web-app">
            { this.props.store.cfg.bread ?
                <div className="web-bread">
                    <div className="breadcrumb">
                        { this.state.pa.map((row, key) =>
                            <div key={key} className="breadcrumb-item" style={this.br_css(row)} onClick={() => this.fknGo(row)}>{row.name}</div>
                        )}
                        <div className="flex"></div>
                        <div className="cdigreen">{ sso.seo.sfkn ? sso.seo.sfkn.name : null }</div>
                    </div>
                </div>
                : null }
        <Routes>
          <Route path="/xx" component={pg_home} />
            <Route path="act" element={ <Th_Act store={this.webStore} />} />
            <Route path="per" element={ <Th_Per store={this.webStore} />} />
            <Route path="avd" element={ <Th_Avd store={this.webStore} />} />
            <Route path="trs" element={ <Th_Trs store={this.webStore} />} />
            <Route path="day" element={ <Th_Day store={this.webStore} />} />
            <Route path="week" element={ <Th_Week store={this.webStore} />} />
            <Route path="*" element={ <Th_Home store={this.webStore} />} />
        </Routes>
    </div>
  );
}
}
//Sys.contextType = ADOContext;

export default withRouter(observer(Ta_Th));
