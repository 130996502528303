import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";
import {SDOProvider} from "../../sdo";
import Dt_Cal from "../../lib/ui/dt_cal";
import {Chart} from "chart.js";
import {format} from "date-fns";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Eko_HH extends React.Component {
  constructor(props, context) {
    super(props);

    this.my_line = null;
    this.store = props.store;
    this.pos = props.store.pos;

    this.state = { pgid: 11, tra: [] };
    this.state.today = new Date();
    this.state.seldate = new Date();
    this.state.avd = "01";
    this.state.eka = [];
    //context.posrend = this;
    this.chartColors = {
        navy: '#4755AB',
        green: '#03c895',
        danger: '#ff4f81',
        blue: '#4A496E',
        purple: '#B162AC',
        grey: '#E7EDF6'
    };

      this.state.units = [];
      this.state.units.push({ id: "food0000", sid: "S0003200", text: "BAS ENHET" });
      this.state.units.push({ id: "food0001", sid: "S0003201", text: "NYKOPING" });
      this.state.units.push({ id: "food0002", sid: "S0003202", text: "DANDERYD" });
      this.state.units.push({ id: "food0003", sid: "S0003203", text: "SICKLA" });
      this.state.units.push({ id: "food0004", sid: "S0003204", text: "HUDDINGE" });
      this.state.unit = this.state.units[0];

  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.st_hh();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 450;
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }
    change_unit(e) {
        //let cdat = this.state.seldate;
        let id = e.target.value;
        var unit = {};
        for(var row of this.state.units) {
            if(row.id == id) unit = row;
        }
        this.setState({unit: unit});
    }

  st_hh() {
      var dtt = format(this.state.today, "yyyy-MM-dd");
      var dts = format(this.state.seldate, "yyyy-MM-dd");
      if(dtt == dts) {
          this.st_today();
      }
      else {
          this.st_dt(dts);
      }
  }

  st_dt(dts) {
    var self = this;
    var prm = {};
    prm.sid = this.state.unit.sid;
    prm.req = "pos.pos_eko.trh_hh";
    prm.avd = this.state.avd;
    prm.fdat = dts;
    prm.tdat = dts;

    net.sid_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        var dto = ret.dto;
        var a = [];
        var keys = Object.keys(dto.hhs);
        for(var key of keys) {
            var hho = dto.hhs[key];
            a.push( hho );
        }
        self.setState({dto: dto, eka: a }, () => self.line_chart());
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  st_today() {
    var self = this;
    var prm = {};
    prm.sid = this.state.unit.sid;
    prm.req = "pos.pos_eko.tr_hh";
    prm.avd = this.state.avd;
    //alert(JSON.stringify(prm));

    net.sid_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        var dto = ret.dto;
        var a = [];
        var keys = Object.keys(dto.hhs);
        for(var key of keys) {
            var hho = dto.hhs[key];
            a.push( hho );
        }
        self.setState({dto: dto, eka: a }, () => self.line_chart());
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
    line_chart() {
      var lbls = [];
      var da = {};
      da.hh = [];
      da.nrof = [];

      for(var row of this.state.eka) {
          lbls.push( row.text.toswe() );
          var s2 = (row.belopp * 1) / (row.antal * 1);
          da.hh.push( row.belopp.cdipris() );
          da.nrof.push( s2.cdipris() );
      }

        if(this.my_line) this.my_line.destroy();
        this.my_line =  new Chart(document.getElementById("linechart"), {
            type: 'line',
            data: {
				labels: lbls,
                datasets: [{
                    label: 'Timfsg',
                    backgroundColor: this.chartColors.navy,
                    borderColor: this.chartColors.navy,
                    data: da.hh,
                    fill: false,
/*                }, {
                    label: 'Snitt per kvitto',
                    fill: false,
                    backgroundColor: this.chartColors.purple,
                    borderColor: this.chartColors.purple,
                    data: da.nrof,
*/                }]
            },
            options: {
                responsive: true,
                legend: {
                    display: false,
                    position: 'top'
                },
                // title: {
                // 	display: true,
                // 	text: 'Chart.js Line Chart'
                // },
                tooltips: {
                    mode: 'index',
                    intersect: false,
                },
                hover: {
                    mode: 'nearest',
                    intersect: true
                },
                scales: {
                    x: {
                        display: true,
                        scaleLabel: {
                            display: true,
                            labelString: 'Month'
                        }
                    },
                    y: {
                        display: true,
                        scaleLabel: {
                            display: true,
                            labelString: 'Value'
                        }
                    }
                }
            }
        });
    };

  render() {
    return (
    <div className="web-p00 flex flex-col">
        <div className="">
            Tim
        </div>

    <div className="web-p01">
    <div className="flex-row">
        <Dt_Cal startdatum="2020-02-01" getValue={(e)=> this.setState({seldate: e}) }/>
        <select className="form-control w250" placeholder="Välj enhet" onChange={(e) => this.change_unit(e)}>
            { this.state.units.map((row, key) =>
            <option key={key} value={row.id}>{row.text}</option>
            )}
        </select>
        <div className="flex"></div>

        <button className="btn btn-hw-blue w150 ripple" onClick={() => this.st_hh() } aria-label="System" >
            UPPDATERA
        </button>
    </div>
    <div className="divider_orange"></div>

    <div className="chart hide">
        <div className="row">
            <div className="col-lg-12 pr-lg-2 chart-grid">
                <div className="card text-center card_cdi">
                    <div className="card-body">
                        <div id="container">
                            <canvas id="linechart" height="50"></canvas>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div className="web-row">
        <table className="table table-bordered table-hover tb fixed_header">
            <thead>
            <tr>
                <th>Text</th>
                <th>Antal</th>
                <th>Belopp</th>
                <th>Snitt</th>
                <th>Procent</th>
                <th style={{width:"40px"}} ></th>
            </tr>
            </thead>
            <tbody style={ this.tb_style() }>
            { this.state.eka.map((row, key) =>
            <tr key={key} id={ row.datum } onDoubleClick={() => this.plu_edit(row) }>
                <td>{ row.text.toswe() }</td>
                <td>{ row.antal.ztrim() }</td>
                <td>{ row.belopp.cdipris() }</td>
                <td>{ row.snitt.cdipris() }</td>
                <td>{ row.proc.dec2() } %</td>
                <td style={{width:"40px",cursor:"pointer"}} onClick={() => this.plu_edit(row) }><i className="fa fa-cog text-danger"></i></td>
            </tr>
            )}
            </tbody>
        </table>
    </div>

    </div>
    </div>
  );
    }
}
export default Eko_HH;
