import React, { useState, useEffect, useContext } from 'react';
import {Route, Routes} from "react-router-dom";

import Bf_Home from './bf/home.js';
import Bf_Hbok from './bf/hbok.js';
import Bf_Veri from './bf/veri.js';
import Bf_Wrk from './bf/wrk.js';
import {withRouter} from "../lib/react/withRouter";
import Bi_Home from "../csa/csc/bi/home";

function pg_home() {

    var html =
    <div className="web-content">
      Hem
    </div>
    ;
    return html;
}

class Bfs_Bf extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.state = {};
  }
  fknGo(row) {
    //alert(row.href);
    this.props.navigate(row.url);
  }

  render() {
      var path = window.location.pathname;
      if(path.substr(-1) == "/") path = path.substring(0, path.length - 1);
      var op = path.split("/").pop();
      var po = {};
      po.hbok = { "fid": "f01", "url": "/bfs/bf/hbok", "name": "Huvudbok", "active": false };
      po.veri = { "fid": "f02", "url": "/bfs/bf/veri", "name": "Verifikat", "active": false };
      po.wrk = { "fid": "f03", "url": "/bfs/bf/wrk", "name": "Bearbeta", "active": false };
      if(po[op]) {
          po[op].active = true;
          this.props.store.fkn = po[op];
      }
      var pa = Object.values(po);

    return (

    <div className="web-app">
         { this.props.store.cfg.bread ?
             <div className="web-bread">
                 <div className="breadcrumb">
            { pa.map((row, key) =>
                <div key={key} className="breadcrumb-item" active={row.active} onClick={() => this.fknGo(row)}>{row.name}</div>
            )}
            <div className="flex"></div>
            <div className="cdigreen">{ this.props.store.fkn ? this.props.store.fkn.name : null }</div>
            </div>
        </div>
        : null }
        <Routes>
          <Route path="/xx" component={pg_home} />
            <Route path="hbok" element={ <Bf_Hbok store={this.store} />} />
            <Route path="veri" element={ <Bf_Veri store={this.store} />} />
            <Route path="wrk" element={ <Bf_Wrk store={this.store} />} />
            <Route path="*" element={ <Bf_Home store={this.store} />} />
        </Routes>
    </div>

  );
}
}
//Sys.contextType = ADOContext;

export default withRouter(Bfs_Bf);
