import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../../lib/net";
import Fr_Input from "../../../lib/ui/fr_input";
import * as utils from "../../../assets/utils";
import {format, getHours, subDays} from "date-fns";

class Order_Trs extends React.Component {
  constructor(props, context) {
    super(props);

    this.pos = props.store.pos;
    this.state = {};
    this.state.ynq = [];
    this.state.sval = [];

    this.state.dao = {};
    this.state.aa = [];
    this.state.cval = [
                {id: '001', text: 'ANTAL GER NYTT PRIS'},
                {id: '002', text: 'ANTAL GER RABATT PÅ PRIS'},
                {id: '003', text: 'PRISÄNDRING GENOM RABATT'}
            ];

    this.state.tra = [];
      this.state.vpo = {};
      if(props.wnds) {
          this.state.vpo = props.wnds.vpo;
      }
      this.state.today = new Date();
      var hh = getHours(this.state.today);
      if( hh < 5) {
          const dt = subDays(new Date(), 1);
          this.state.dts = format(dt, "yyyyMMdd");
      }
      else this.state.dts = format(this.state.today, "yyyyMMdd");
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.nota_trs();
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    tab_style(tabid) {
      var css = {};
      css.display = "none";
      if(this.state.tabval === tabid) css.display = "block";
      return css;
    }
    tab_head(tabid) {
      var css = {};
      css.background = "#fff";
      css.color = "#000";
      if(this.state.tabval === tabid) {
          css.background = "#080";
          css.color = "#fff";
      }
      return css;
    }
    grp_style(row) {
      var css = {};
      css.background = "#008";
      if(this.state.gnr === row.vgrupp) css.background = "#080";
      return css;
    }

    formchange (e) {
      const name = e.target.name;
      const value = e.target.value;
      var pbo = this.state.pbo;
      var na = name.split(".");
      pbo[na[0]][na[1]] = value;
      this.setState({pbo: pbo});
      //setState({[name]: value});
    }

    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    wnd_close = () => {
		this.props.ctx.setState({ wnds: {} });
	};
    handleChange = (event, newValue) => {
        //alert(newValue);
        this.setState({ix: newValue});
      };
    toggle() {
      this.setState({modal: false});
	};

  submitHandler = event => {
    event.preventDefault();
    event.target.className += " was-validated";
  };

  changeHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  tb_style() {
      var css = {};
      //css.width = window.innerwidth * 0.7;
      //css.height = window.innerHeight - 375;
      return css;
  }
  cssCols (cid) {
      var css = {};
      if(this.state.cols[cid]) css.width = this.state.cols[cid].toString() + "px";
      //if(cid == "c01") alert(JSON.stringify(this.state.cols[cid]));
      return css;
  }
    nota_trs() {
        var self = this;
        var vpo = this.state.vpo;
        var notanr = vpo.order00.notanr;
        if(this.state.dts != vpo.order00.datum) {
            this.nota_trhs();
            return;
        }

        var prm = {};
        prm.req = "pos.pos_eko.trds";
        prm.sztxt = notanr;

        //alert(JSON.stringify(prm));
        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret.rca));

            self.setState({tra: ret.rca }, function() { });
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }
    nota_trhs() {
        var self = this;
        var vpo = this.state.vpo;
        var datum = vpo.order00.datum;
        var avd = "01";
        var notanr = vpo.order00.notanr;

        var prm = {};
        prm.req = "pos.pos_eko.trhds";
        prm.datum = datum;
        prm.avd = avd;
        prm.notanr = notanr;

        //alert(JSON.stringify(prm));
        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.rca));

                self.setState({tra: ret.rca }, function() { });
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }

  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        <div className="m2_backdrop" />
        <div className="m2_dlg mx_70 flex-col" style={ this.dlg_style() } >
        <div className="mx_head">
            <div>ORDER RADER</div>
        </div>
        <div className="mx_content flex">

            <div className="flex-row" style={{padding:"5px",height:"35px"}}>
                    <div className="flex-col flex">
                    </div>
                    <div className="flex-col">
                        <div>UPPDATERA</div>
                    </div>
            </div>
            <div className="divider_orange"></div>

        <table className="table table-bordered table-hover">
            <thead>
            <tr>
				<th>Datum</th>
				<th>Tid</th>
				<th>Text</th>
				<th>Antal</th>
                <th>Pris</th>
                <th>Summa</th>
				<th>Moms</th>
				<th>Notanr</th>
				<th>Typ</th>
            </tr>
            </thead>
            <tbody style={ this.tb_style() }>
            { this.state.tra.map((tro, key) =>
            <tr key={key} onDoubleClick={ () => this.tro_edit(tro) }>
                <td>{ utils.toDate(tro.r0datum) }</td>
                <td>{ utils.toTid(tro.r0time) }</td>
                <td>{ utils.toLang(tro.r0text) }</td>
                <td>{ utils.toNum(tro.r0noof) }</td>
                <td>{ utils.toPris(tro.r0perprice) }</td>
                <td>{ utils.toPris( (tro.r0noof * 1) * (tro.r0perprice * 1) ) }</td>
                <td>{ tro.r0vatproc }</td>
                <td>{ utils.toNum(tro.r0faktnr) }</td>
                <td>{ tro.r0ttyp }</td>
            </tr>
            )}
            </tbody>
        </table>

        </div>
        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
        </div>
      </div>
      </div>
	;
	return html;
  };
}

export default Order_Trs;