import React, { useState, useEffect, useContext } from 'react';
import {format, addDays, subDays} from "date-fns";

import * as net from "../../../lib/net";
import Fr_Input from "../../../lib/ui/fr_input";
import Fr_Select from "../../../lib/ui/fr_select";
import Dt_Cal from "../../../lib/ui/dt_cal";

class Profil_Add extends React.Component {
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pms = props.store.pms;

    this.state = {};
    this.state.today = new Date();
    this.state.rta = [];
    this.state.pka = [];

    this.state.gnr = "001";
    this.state.gbo = {};
    //this.state.lro = props.lro;
    this.state.ynq = [
                {id: '0', text: 'NEJ'},
                {id: '1', text: 'JA'}
            ];

    this.state.bgo = {};
    this.state.bto = {};
    this.state.background = "#fff";

    this.state.cols = {};
    this.state.lrwrk = false;
    this.state.boknr = {};
    if(props.boknr) this.state.boknr = props.boknr;

    this.state.wnds = {};
    this.state.pfo = {};
    this.state.pfb = {};
    if(props.wnds) {
        //if(props.wnds.pfo) this.state.pfo = props.wnds.pfo;
        //if(props.wnds.pfo) this.state.pfb = props.wnds.pfo.pfb;
   }
   //alert(JSON.stringify(this.state.pfb.besok));

  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.gst_init();
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    grp_style(row) {
      var css = {};
      css.background = "#008";
      if(this.state.gnr === row.vgrupp) css.background = "#080";
      return css;
    }

    formchange (e) {
      const name = e.target.name;
      const value = e.target.value;
      var pbo = this.state.pbo;
      var na = name.split(".");
      pbo[na[0]][na[1]] = value;
      this.setState({pbo: pbo});
      //setState({[name]: value});
    }

    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    wnd_close = () => {
      if(this.props.cb) {
        var ret = {};
        ret.ok = "999";
        this.props.cb(this.props.ctx, ret);
      }
      else this.props.ctx.setState({ wnds: {} });
	};

  gst_room(e) {
    e.preventDefault();
    var self = this;
    var wnds = {gstroom: true, boknr: this.state.boknr, bro: this.state.bro};
    this.setState({wnds: wnds});
  }
  cb_gstrooms(ctx, ret) {
      if(ret.ok == "000") {
          //ctx.rv_br();
        var gso = this.state.gso;
        gso.rumsnr = ret.rumsnr;
        ctx.setState({ wnds: {}, gso: gso });
      }
      else {
        ctx.setState({ wnds: {} });
      }
  };

  val_save (name, value) {
      //alert(JSON.stringify(name));
      //alert(JSON.stringify(value));
      var pfb = this.state.pfb;
      pfb[name] = value;
      this.setState({ pfb: pfb });
  }

  cb_save (name, e) {
      const value = e.target.value;
      var bro = this.state.bro;
      bro[name] = value;
      this.setState({bro: bro});
      //setState({[name]: value});
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }

  gst_init() {
    var self = this;
    var dat = addDays(this.state.today, 1);
    var fdat = format(this.state.today, "yyyy-MM-dd");
    var tdat = format(dat, "yyyy-MM-dd");

    var gsb00 = self.state.gsb00;
    var gso = {};
    gso.glopnr = gsb00.r0glopnr;
    gso.blopnr = gsb00.r0blopnr;
    gso.fdat = gsb00.r0ankdatum;
    gso.tdat = gsb00.r0avrdatum;
    gso.rumsnr = gsb00.r0rumsnr;
    gso.namn = gsb00.r0namn;
    gso.nrr = "1";
    gso.nrg = "1";
    gso.nrgr = "1";
    gso.nrb0 = "0";
    gso.nrb1 = "0";
    gso.nrb2 = "0";
    gso.rt = "";
    gso.pkat = "";
    gso.rab = "";
    this.setState({gso: gso});
  }

  profil_new() {
    var self = this;

    var prm = {};
    prm.req = "pms.profil.profil_new";
    prm.pfb = self.state.pfb;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok == "000") {
		      self.props.cb(self.props.ctx, ret);
          return;
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "Gäst";
    //if(props.kro) alert(props.kro.krr00.kid);
    const popover = {
      position: 'absolute',
      top: '50px',
      zIndex: '2',
    }
    const cover = {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    }

	var html =
        <div className="mx_base">
        <div className="m2_backdrop" />
        <div className="m2_dlg mx_90 flex-col" style={ this.dlg_style() } >

        <div className="mx_h01">
            <div>NY PROFIL</div>
        </div>
        <div className="mx_content flex">

            <div className="divider_orange"></div>

            <form className="cdi_f03">
            <div className="flex-row py-2">
                    <Fr_Input label="Namn" className="text-left w350" text={this.state.pfb.namn} getValue={(e)=> this.val_save("namn", e) } />
            </div>
            <div className="flex-row py-2">
                    <Fr_Input label="Företag" className="text-left w350" text={this.state.pfb.ftg} getValue={(e)=> this.val_save("ftg", e) } />
                    <Fr_Input label="Orgnr" className="text-left w250" text={this.state.pfb.orgnr} getValue={(e)=> this.val_save("orgnr", e) } />
            </div>
            <div className="divider_green"></div>
            <div className="flex-row py-2">
                    <Fr_Input label="Epost" className="text-left w250" text={this.state.pfb.email} getValue={(e)=> this.val_save("email", e) } />
            </div>
            <div className="flex-row py-2">
                    <Fr_Input label="Telefon" className="text-left w250" text={this.state.pfb.tele} getValue={(e)=> this.val_save("tele", e) } />
                    <Fr_Input label="Mobil" className="text-left w250" text={this.state.pfb.mobil} getValue={(e)=> this.val_save("mobil", e) } />
            </div>
            <div className="divider_green"></div>
            <div className="flex-row py-2">
                    <Fr_Input label="Rumsnr" className="text-left w150" text={this.state.pfb.rumsnr} getValue={(e)=> this.val_save("rumsnr", e) } />
                    <Fr_Input label="Status" className="text-left w250" text={this.state.pfb.status} getValue={(e)=> this.val_save("status", e) } />
            </div>
            <div className="flex-row py-2">
                    <Fr_Input label="Nivå" className="text-left w150" text={this.state.pfb.niva} getValue={(e)=> this.val_save("niva", e) } />
            </div>
            </form>

            <div className="divider_orange"></div>


        </div>

        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                AVBRYT
            </button>
            <div className="flex"></div>
            <div className="flex"></div>
            <button className="btn btn-hw-green w250" onClick={() => this.profil_new() } >
                SPARA
            </button>
        </div>
      </div>
      </div>
	;
	return html;
  };
}

export default Profil_Add;