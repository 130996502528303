import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../../../lib/net";
import Wnd_Ok from "../../../../lib/ui/wnd_ok";

class Export_Spec extends React.Component {
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.state = {};
    this.state.bqa = [];
    this.state.tra = [];
    this.state.msg = {};
    this.state.bqr = {};
    this.state.bqr.srows = {};
    if(props.wnds && props.wnds.row) this.state.bqr = props.wnds.row;
   }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      //this.trs();
      //alert(JSON.stringify(this.props.wnds.row));
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    tab_style(tabid) {
      var css = {};
      css.display = "none";
      if(this.state.tabval === tabid) css.display = "block";
      return css;
    }
    bq_close() {
		this.props.ctx.setState({ wnds: {} });
	};

  render() {
    var title = this.props.title;
    if(!title) title = "Titel";

	var html =
        <div className="mx_base">
        { this.props.wnds.backdrop ? <div className="mx_backdrop" /> : null }
        <div className="m2_dlg mx_80h flex-col" style={ this.dlg_style() }>
        <div className="mx_content flex">

            <div className="flex-row" style={{padding:"5px",height:"35px"}}>
                    <div className="flex-col flex">
                        <div>EXPORT TRANSAKTION</div>
                    </div>
                    <div className="flex-col">
                        <div>UPPDATERA</div>
                    </div>
            </div>
            <div className="divider_orange"></div>
            <div className="px-5">

            <div className="flex-row txt_18">
                <div className="w200">Fält</div>
                <div className="w100">Längd</div>
                <div className="w200">Typ</div>
                <div className="flex">Information</div>
            </div>
            <div className="divider_green"></div>

            <div className="flex-row txt_18">
                <div className="w200">date</div>
                <div className="w100">8</div>
                <div className="w200">ALFA</div>
                <div className="flex">Datum på transaktionen</div>
            </div>
            <div className="flex-row txt_18">
                <div className="w200">serial</div>
                <div className="w100">4</div>
                <div className="w200">NUM</div>
                <div className="flex">Löpnr på transaktionen</div>
            </div>
            <div className="flex-row txt_18">
                <div className="w200">time</div>
                <div className="w100">4</div>
                <div className="w200">NUM</div>
                <div className="flex">Tidpunkt registrerad/såld</div>
            </div>
            <div className="flex-row txt_18">
                <div className="w200">invoicenr</div>
                <div className="w100">7</div>
                <div className="w200">ALFA</div>
                <div className="flex">Notanummer</div>
            </div>
            <div className="flex-row txt_18">
                <div className="w200">guests</div>
                <div className="w100">5</div>
                <div className="w200">NUM</div>
                <div className="flex">Totalt gäster på notan</div>
            </div>
            <div className="flex-row txt_18">
                <div className="w200">transtype</div>
                <div className="w100">1</div>
                <div className="w200">ALFA</div>
                <div className="flex">Typ av transaktion P/B (Produkt/Betalning)</div>
            </div>
            <div className="flex-row txt_18">
                <div className="w200">prodtype</div>
                <div className="w100">4</div>
                <div className="w200">ALFA</div>
                <div className="flex">Produkt typ/kategori</div>
            </div>
            <div className="flex-row txt_18">
                <div className="w200">text</div>
                <div className="w100">30</div>
                <div className="w200">ALFA</div>
                <div className="flex">Produkt text</div>
            </div>
            <div className="flex-row txt_18">
                <div className="w200">noof</div>
                <div className="w100">11</div>
                <div className="w200">ALFA</div>
                <div className="flex">Antal av produkt</div>
            </div>
            <div className="flex-row txt_18">
                <div className="w200">prodprice</div>
                <div className="w100">11</div>
                <div className="w200">ALFA</div>
                <div className="flex">Produkt pris</div>
            </div>
            <div className="flex-row txt_18">
                <div className="w200">debcre</div>
                <div className="w100">1</div>
                <div className="w200">ALFA</div>
                <div className="flex">Debit / Kredit</div>
            </div>
            <div className="flex-row txt_18">
                <div className="w200">bong</div>
                <div className="w100">1</div>
                <div className="w200">ALFA</div>
                <div className="flex">Produkt bongas</div>
            </div>
            <div className="flex-row txt_18">
                <div className="w200">bongtime</div>
                <div className="w100">4</div>
                <div className="w200">NUM</div>
                <div className="flex">Bong tid(Produkt skickad för produktion)</div>
            </div>
            <div className="flex-row txt_18">
                <div className="w200">producetime</div>
                <div className="w100">4</div>
                <div className="w200">NUM</div>
                <div className="flex">Produktion statad(Köket påbörjar)</div>
            </div>
            <div className="flex-row txt_18">
                <div className="w200">delivertime</div>
                <div className="w100">4</div>
                <div className="w200">NUM</div>
                <div className="flex">Levererad från produktion(Kök) - Är i luckan</div>
            </div>
            <div className="flex-row txt_18">
                <div className="w200">pickuptime</div>
                <div className="w100">4</div>
                <div className="w200">NUM</div>
                <div className="flex">Hämtad till gäst från luckan</div>
            </div>
            <div className="flex-row txt_18">
                <div className="w200">status</div>
                <div className="w100">1</div>
                <div className="w200">ALFA</div>
                <div className="flex">Transaktions status</div>
            </div>

            <div className="divider_green"></div>


        </div>
        </div>

        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250 ripple" onClick={() => this.bq_close() } >
                STÄNG
            </button>
            <div className="flex-row">
            </div>
            <button className="btn btn-hw-green w250 ripple" onClick={(event) => this.prn(event) } >
                SKRIV UT
            </button>
        </div>
      </div>
        <Wnd_Ok msg={this.state.msg} ctx={this} />
    </div>
	;
	return html;
  };
}

export default Export_Spec;

/*
            { Object.values(this.state.bqr.srows).map((srow, skey) =>
                <div key={skey} className="">
                    <div className="flex-row flex-sb txt_14">
                        <div className="">{ this.stol_text(srow) }</div>
                    </div>
                { srow.rows.map((rrs00, rkey) =>
                    <div className="bq-row"  style={ this.bongrow_style(rrs00) } onClick={() => this.row_bong(rrs00) } onContextMenu={(e) => this.row_stol(rrs00, e) }>{ rrs00.r0radnr } : { rrs00.r0etyp } - { rrs00.r0text.toswe() } - { rrs00.r0perprice.cdipris() }</div>
                )}
                </div>
            )}

*/