import React, { useState, useEffect, useContext } from 'react';

import * as fkn from "../lib/fkn";
import * as net from "../lib/net";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Fakt_Home extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, tra: [] };
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 480;
      return css;
  }

  render() {
    return (
    <div className="web-app">
    <h3>FAKTURA SYSTEM</h3>

    </div>
  );
    }
}
export default Fakt_Home;
