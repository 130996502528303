import React, { useState, useEffect, useContext } from 'react';
import {format, addDays, subDays} from "date-fns";

import * as net from "../../../../lib/net";
import Doc_Prds_Add from "./doc_prds_add";

class Doc_Prds extends React.Component {
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pms = props.store.pms;

    this.state = {};
    this.state.today = new Date();
    this.state.rta = [];
    this.state.pka = [];
    this.state.prds = [];

    this.state.gnr = "001";
    this.state.bokr00 = {};
    this.state.bokr01 = {};
    this.state.konf01 = {};
    //this.state.lro = props.lro;
    this.state.ynq = [
                {id: '0', text: 'NEJ'},
                {id: '1', text: 'JA'}
            ];

    this.state.bgo = {};
    this.state.bto = {};
    this.state.background = "#fff";

    this.state.wnds = {};
    this.state.cols = {};
    this.state.lrwrk = false;
    this.state.boknr = {};
    if(props.boknr) this.state.boknr = props.boknr;
    this.state.dro = {};
    if(props.dro) this.state.dro = props.dro;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.prds_init();
    //this.row_init();
  }
  prds_init() {
    var self = this;

    var prds = [];

    prds.push({ "id": 1, "text": "LAPPER MED R\\MME OG SYLTET\\Y", "pris": "5000", "status": "" });
    prds.push({ "id": 2, "text": "VAFFLER MED R\\MME OG SYLTET\\", "pris": "5000", "status": "" });
    prds.push({ "id": 3, "text": "GR\\NNSAKER MED DIP", "pris": "5000", "status": "" });
    prds.push({ "id": 4, "text": "OPPSK]RET FRUKT MED VANILJE KESAM", "pris": "5000", "status": "" });
    prds.push({ "id": 5, "text": "P\\\LSER MED LOMPE ELLER BR\\D (2 STK)", "pris": "8000", "status": "" });
    prds.push({ "id": 6, "text": "SUPPE MED BR\\D OG SM\\R", "pris": "10000", "status": "" });
    prds.push({ "id": 7, "text": "FOCACCIA MED SPEKESKINKE OG KREMEOST", "pris": "7500", "status": "" });
    prds.push({ "id": 8, "text": "KYCKLINGWRAPS", "pris": "8500", "status": "" });
    prds.push({ "id": 9, "text": "LEFSE MED SN\\FRISK, R\\DL\\K OG R\\KELAKS (2 STK)", "pris": "5500", "status": "" });
    prds.push({ "id": 10, "text": "LEFSE MED SN\\FRISK, SPEKESKINK, RUCCULA (2 STK)", "pris": "5500", "status": "" });
    prds.push({ "id": 11, "text": "GROVT ELLER FINT RUNDSTYKKE MED P]LEGG ELLER OST (2 HALVE)", "pris": "7000", "status": "" });
    prds.push({ "id": 12, "text": "HVETEBAKST", "pris": "4000", "status": "" });
    prds.push({ "id": 13, "text": "N\\TTEBLANDNING", "pris": "2500", "status": "" });
    prds.push({ "id": 14, "text": "YOGHURT MED MUSLI", "pris": "3000", "status": "" });
    prds.push({ "id": 15, "text": "PIZZASTYKKE MED PEPPERONI, SKINKE ELLER VEGETAR", "pris": "2500", "status": "" });
    prds.push({ "id": 16, "text": "BAGUETT MED OST OG SKINKE", "pris": "6500", "status": "" });
    prds.push({ "id": 17, "text": "BAGUETT MED REKER OG MAJONES", "pris": "7500", "status": "" });
    prds.push({ "id": 18, "text": "BAGUETT MED R\\KT LAKS OG EGG", "pris": "7500", "status": "" });
    prds.push({ "id": 19, "text": "BAGUETT MED KYLLING OG KARRISALAT", "pris": "7500", "status": "" });

    this.setState({prds: prds});
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    grp_style(row) {
      var css = {};
      css.background = "#008";
      if(this.state.gnr === row.vgrupp) css.background = "#080";
      return css;
    }

    formchange (e) {
      const name = e.target.name;
      const value = e.target.value;
      var pbo = this.state.pbo;
      var na = name.split(".");
      pbo[na[0]][na[1]] = value;
      this.setState({pbo: pbo});
      //setState({[name]: value});
    }

    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    wnd_close = () => {
      if(this.props.cb) {
        var ret = {};
        ret.ok = "999";
        this.props.cb(this.props.ctx, ret);
      }
      else this.props.ctx.setState({ wnds: {} });
	};
    handleChange = (event, newValue) => {
        //alert(newValue);
        this.setState({ix: newValue});
      };
    toggle() {
      this.setState({modal: false});
	};

  submitHandler = event => {
    event.preventDefault();
    event.target.className += " was-validated";
  };

  changeHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

    rsv_save (name, value) {
        //alert(JSON.stringify(name));
        //alert(JSON.stringify(value));
      var rsv = this.state.bro;
      var na = name.split(".");
      rsv[na[0]][na[1]] = value;
      this.setState({rsv: rsv});
      //setState({[name]: value});
    }
    val_save (name, value) {
        //alert(JSON.stringify(name));
        //alert(JSON.stringify(value));
      var dro = this.state.dro;
      dro[name] = value;
      this.setState({dro: dro});
      //setState({[name]: value});
    }
    cb_save (name, e) {
      const value = e.target.value;
      var dro = this.state.dro;
      dro[name] = value;
      this.setState({dro: dro});
      //setState({[name]: value});
    }
  tb_style() {
      var css = {};
      css.height = (window.innerHeight * 0.9) - 275;
      return css;
  }

  row_init() {
    var self = this;
    var datum = format(this.state.today, "yyyy-MM-dd");

    var dro = {};
    dro.datum = datum;
    dro.tid = "";
    dro.text = "";
    dro.antal = "";

    var bokr00 = {};
	bokr00.resno = this.state.boknr;
	bokr00.lopnr = "00001";
	bokr00.radnr = "0001";
	bokr00.resurs = "HOT";
	bokr00.restyp = "M001";
	bokr00.datum = "";
	bokr00.start = "";
	bokr00.slut = "";
	bokr00.text = "";
	bokr00.antal = "";
	bokr00.belopp = "";

    var bokr01 = {};
	bokr01.apris = "";
	bokr01.fakturanr = "";

    var konf01 = {};
	konf01.r0typ = "M";
	konf01.r0datum = "";
	konf01.r0lopnr = "001";
	konf01.r0plunr = "";
	konf01.r0text = "";
	konf01.r0antal = "";
	konf01.r0pris = "";
	konf01.r0tid = "";
	konf01.r0kat = "";

    this.setState({dro: dro, bokr00: bokr00, bokr01: bokr01, konf01: konf01});
  }
  prds_add(row) {
      var wnds = {};
      wnds.docprdsadd = 1;
      wnds.boknr = this.state.boknr;
      wnds.prd = row;
      this.setState({wnds: wnds});
  };
  rts() {
    var self = this;
    var prm = {};
    prm.req = "pms.rt.rt_date";
    //prm.datum = this.state.datum;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            return;
        }
        self.setState({rta: ret.rco});
        self.pkats();
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

  doc_row_add() {
    var self = this;

    var bokr00 = this.state.bokr00;
	bokr00.datum = this.state.dro.datum.replace(/-/g,"");
	bokr00.start = this.state.dro.tid;
	bokr00.slut = this.state.dro.tid;
	bokr00.text = this.state.dro.text;
	bokr00.antal = this.state.dro.antal;
    var bokr01 = this.state.bokr01;

    var konf01 = this.state.konf01;
	konf01.r0datum = this.state.dro.datum.replace(/-/g,"");
	konf01.r0tid = this.state.dro.tid;
	konf01.r0text = this.state.dro.text;
	konf01.r0antal = this.state.dro.antal;

    //alert(JSON.stringify(this.state.dro));
    //alert(JSON.stringify(bokr00));
    //alert(JSON.stringify(konf01));

    var prm = {};
    prm.req = "pms.doc.doc_row_add";
    prm.boknr = this.state.boknr;
    prm.bokr00 = bokr00;
    prm.bokr01 = bokr01;
    prm.konf01 = konf01;
    //prm.nyp00 = this.state.nyp00;
    //alert(JSON.stringify(prm));
    //return;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok == "000") {
		    self.props.cb(self.props.ctx, ret);
            return;
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  doc_row_res(ctx, ret) {
      ctx.setState({wnds: {}});
      if(ret.ok == "000") {
        ctx.props.cb(ctx.props.ctx, ret);
      }
  };
  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "Delbokning";
    //if(props.kro) alert(props.kro.krr00.kid);
    const popover = {
      position: 'absolute',
      top: '50px',
      zIndex: '2',
    }
    const cover = {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    }

	var html =
        <div className="mx_base">
        <div className="m2_backdrop" />
        <div className="m2_dlg mx_80h flex-col" style={ this.dlg_style() } >

        <div className="mx_h01">
            <div>PRODUKTER</div>
        </div>
        <div className="mx_content flex">

            <div className="flex-row" style={{padding:"5px",height:"35px"}}>
                    <div className="flex-col flex">
                        <div></div>
                    </div>
                    <div className="flex-col">
                        <div>UPPDATERA</div>
                    </div>
            </div>
            <div className="divider_orange"></div>

            <div className="flex flex-row" style={{border: "0px", margin: "0px", padding: "0px"}}>
                <table className="table" style={{border: "0px", margin: "0px", padding: "0px"}}>
                    <thead style={{fontSize: "12px", fontWeight: "normal", color: "#080", border: "0px", margin: "0px", padding: "0px"}}>
                    <tr>
                        <th style={{textAlign: "left"}}>ID</th>
                        <th style={{textAlign: "left"}}>Text</th>
                        <th style={{textAlign: "left"}}>Pris</th>
                        <th style={{textAlign: "left"}}>Status</th>
                    </tr>
                    </thead>
                    <tbody style={ this.tb_style() }>
                    { this.state.prds.map((row, key) =>
                    <tr onDoubleClick={() => this.prds_add(row) }>
                        <td>{ row.id }</td>
                        <td>{ row.text.tonor() }</td>
                        <td>{ row.pris.cdipris() }</td>
                        <td>{ row.status }</td>
                    </tr>
                    )}
                    </tbody>
                </table>
             </div>

        </div>

        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex-row">
            <button className="btn btn-hw-red w250 hide" onClick={() => this.doc_row_add() } >
                LÄGG TILL
            </button>
            </div>
        </div>
      </div>
         { this.state.wnds.docprdsadd ? <Doc_Prds_Add wnds={this.state.wnds} cb={this.doc_row_res}  ctx={this} store={this.props.store} backdrop={true}/> : null }
     </div>
	;
	return html;
  };
}

export default Doc_Prds;