import React, { useState, useEffect, useContext } from 'react';
import {Chart} from "chart.js";
import {format} from "date-fns";

import * as net from "../../../lib/net";
import sso from "../../../sso";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Bi_Ov extends React.Component {
  constructor(props, context) {
    super(props);

    this.my_bar = null;
    this.store = props.store;
    this.pos = props.store.pos;

    this.state = { pgid: 11, tra: [] };
    this.state.today = new Date();
    this.state.fdat = new Date(2021, 0, 1);
    this.state.tdat = new Date(2021, 0, 21);
    this.state.avd = "01";
    this.state.eka = [];
    this.state.tpa = [];
    this.state.tpa = [
                {id: '0', text: 'OMSÄTTNING'}
            ];
    this.state.typ = "0";
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.st_oms();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }


  st_omx() {
    var self = this;

    var fdat = format(this.state.fdat, "yyyy-MM-dd");
    var tdat = format(this.state.tdat, "yyyy-MM-dd");

    var prm = {};
    prm.req = "pos.pos_eko.sth_oms";
    prm.avd = this.state.avd;
    prm.fdat = fdat;
    prm.tdat = tdat;
    //alert(JSON.stringify(prm));

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        var sdo = ret.rco;
        var a = [];
        var keys = Object.keys(sdo.dto);
        for(var key of keys) {
            var o = sdo.dto[key];
            a.push( o );
        }
        a.push( sdo.tot );
        //self.setState({dto: dto, eka: a }, () => self.line_chart());
        self.setState({sdo: sdo, eka: a });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
    st_oms() {
        var self = this;

        var sdo = {};
        sdo.eko = {};
        var fdat = format(this.state.fdat, "yyyy-MM-dd");
        var tdat = format(this.state.tdat, "yyyy-MM-dd");

        //var csa = self.get_csa();
        var csa = sso.sdo.csa;

        function send_req(cso) {
            const promise1 = new Promise((resolve, reject) => {
                //setTimeout(resolve, 500, 'one');
                var prm = {};
                prm.sid = cso.c0sid;
                prm.req = "pos.pos_eko.st_oms";
                prm.avd = "01";
                prm.fdat = fdat;
                prm.tdat = tdat;
                //alert(JSON.stringify(prm));

                net.sid_req(prm)
                    .then(function(ret){
                        //alert(JSON.stringify(ret));
                        //alert(JSON.stringify(ret));
                        //sdo.eko[sid] = ret.rco;
                        sdo.eko[cso.c0sid] = {};
                        sdo.eko[cso.c0sid].lopnr = cso.c0lopnr;
                        sdo.eko[cso.c0sid].text = cso.c0text;
                        sdo.eko[cso.c0sid].tot = ret.rco.tot;
                        resolve();
                    })
                    .catch(function(e){
                        var txt = JSON.stringify(e);
                    });
            });
            return promise1;
        }
        var qa = [];
        for(var so of csa) {
            var promise = send_req(so);
            qa.push(promise);
        }
        Promise.all(qa)
            .then(function(ret){
                //alert(JSON.stringify(ret));
                //var eka = Object.values(sdo.eko);
                var eka = [];
                var keys = Object.keys(sdo.eko);
                keys.sort();
                for(var key of keys) {
                    var o = sdo.eko[key];
                    eka.push(o);
                }
                self.setState({sdo: sdo, eka: eka });
                /*
                        var sdo = ret.rco;
                        var a = [];
                        var keys = Object.keys(sdo.dto);
                        for(var key of keys) {
                            var o = sdo.dto[key];
                            a.push( o );
                        }
                        a.push( sdo.tot );
                        //self.setState({dto: dto, eka: a }, () => self.line_chart());
                        self.setState({sdo: sdo, eka: a });
                 */
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
  st_omsx() {
    var self = this;

    var prm = {};
    prm.req = "bi.bi_stat.cs_pt";
    prm.csid = this.store.seo.csid;
    prm.avd = this.state.avd;
    //prm.fdat = fdat;
    //prm.tdat = tdat;

    //gda.wndLoading("Hämtar");
    net.ccs_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            alert(JSON.stringify(ret));
            return;
        }
        var sa = ret.rca;
        //alert(JSON.stringify(sa));
        self.setState({ eka: sa }, () => {
        });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }

    row_proc(row) {
      var proc = 0;
      if(this.state.sdo.tot.fsg > 0) {
          proc = (row.fsg / this.state.sdo.tot.fsg) * 100;
          proc = Math.round(proc);
      }
      return proc + " %";
    }
    row_wday(row) {
      var day = "";
      if(row.wday == 1) day = "Måndag";
      if(row.wday == 2) day = "Tisdag";
      if(row.wday == 3) day = "Onsdag";
      if(row.wday == 4) day = "Torsdag";
      if(row.wday == 5) day = "Fredag";
      if(row.wday == 6) day = "Lördag";
      if(row.wday == 7) day = "Söndag";
      return day;
    }
    bar_chart() {
      var lbls = [];
      var da = {};
      da.ink = [];
      da.ex = [];

      for(var row of this.state.eka) {
          if(row.grp == "tot") continue;
          lbls.push( row.text.toswe() );
          da.ink.push( row.belopp.cdipris() );
      }

        if(this.my_bar) this.my_bar.destroy();
        this.my_bar =  new Chart(document.getElementById("barchart"), {
            type: 'bar',
            data: {
				labels: lbls,
                datasets: [{
                    data: da.ink,
                    label: 'Belopp',
                    backgroundColor: "#4755AB",
                    borderWidth: 1,
                }]
            },
            options: {
                responsive: true,
                legend: {
                    display: false,
                    position: 'top'
                },
            }
        });
	};

  render() {
    return (
    <div className="web-p00 flex flex-col">

    <div className="web-p01">
    <div className="flex-row">
        <h5>ÖVERSIKT</h5>
        <div className="flex"></div>

        <button className="btn btn-hw-blue w150" onClick={() => this.st_oms() } aria-label="System" >
            UPPDATERA
        </button>
    </div>
    <div className="divider_orange"></div>

    <div className="web-row">
        <table className="table table-bordered table-hover tb fixed_header">
            <thead>
            <tr>
                <th>Text</th>
                <th>Brutto</th>
                <th>Moms</th>
                <th>Netto</th>
                <th>Bet</th>
                <th>Diff</th>
                <th>Rabatt</th>
                <th>Resultat</th>
            </tr>
            </thead>
            <tbody style={ this.tb_style() }>
            { this.state.eka.map((row, key) =>
            <tr key={ key } id={ row.grp } onDoubleClick={() => this.st_edit(row) }>
                <td>{ row.text }</td>
                <td class="text-right">{ row.tot.brutto.cdipris() }</td>
                <td class="text-right">{ row.tot.moms.cdipris() }</td>
                <td class="text-right">{ row.tot.netto.cdipris() }</td>
                <td class="text-right">{ row.tot.bet.cdipris() }</td>
                <td class="text-right">{ row.tot.diff.cdipris() }</td>
                <td class="text-right">{ row.tot.rab.cdipris() }</td>
                <td class="text-right">{ row.tot.resultat.cdipris() }</td>
            </tr>
            )}
            </tbody>
        </table>
    </div>

    </div>
    </div>
  );
    }
}
export default Bi_Ov;
