import React, { useState, useEffect, useContext } from 'react';
import {Route, Routes} from "react-router-dom";

import Reg_Home from './reg/home.js';
import Reg_Kreg from './reg/kreg.js';
import Reg_Lreg from './reg/lreg.js';
import Reg_Srvs from './reg/srvs.js';
import Reg_Sreg from './reg/sreg.js';
import Reg_Ureg from './reg/ureg.js';
import Reg_Udid from './reg/udid.js';
import {withRouter} from "../lib/react/withRouter";
import sso from "../sso";

function pg_home() {

    var html =
    <div className="web-content">
      Hem
    </div>
    ;
    return html;
}

class Bcs_Reg extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = {};

  }
  fknGo(row) {
    //alert(row.href);
      sso.seo.sfkn = row;
    this.props.navigate(row.url);
  }
    br_css(row) {
        var css = {};
        if(sso.seo.sfkn && (row.url == sso.seo.sfkn.url)) css.color = "#080";
        //var brc = false;
        //if(row.url == sbo.state.url) brc = true;
        return css;
    }

  render() {
      var path = window.location.pathname;
      if(path.substr(-1) == "/") path = path.substring(0, path.length - 1);
      var op = path.split("/").pop();
      var po = {};
      po.kreg = { "fid": "f05", "url": "/bcs/reg/kreg", "name": "Kundregister", "active": false };
      po.lreg = { "fid": "f05", "url": "/bcs/reg/lreg", "name": "Leverantörer", "active": false };
      po.srvs = { "fid": "f05", "url": "/bcs/reg/srvs", "name": "Servrar", "active": false };
      po.sreg = { "fid": "f05", "url": "/bcs/reg/sreg", "name": "System", "active": false };
      po.ureg = { "fid": "f05", "url": "/bcs/reg/ureg", "name": "Användare", "active": false };
      po.udid = { "fid": "f05", "url": "/bcs/reg/udid", "name": "Udid", "active": false };
      if(po[op]) {
          po[op].active = true;
          this.props.store.fkn = po[op];
      }
      var pa = Object.values(po);

    return (

    <div className="web-app">
         { this.props.store.cfg.bread ?
             <div className="web-bread">
                 <div className="breadcrumb">
            { pa.map((row, key) =>
                <div key={key} className="breadcrumb-item" style={this.br_css(row)} onClick={() => this.fknGo(row)}>{row.name}</div>
            )}
            <div className="flex"></div>
            <div className="cdigreen">{ this.props.store.fkn ? this.props.store.fkn.name : null }</div>
            </div>
        </div>
        : null }
        <Routes>
          <Route path="/xx" component={pg_home} />
            <Route path="kreg" element={ <Reg_Kreg store={this.webStore} />} />
            <Route path="lreg" element={ <Reg_Lreg store={this.webStore} />} />
            <Route path="srvs" element={ <Reg_Srvs store={this.webStore} />} />
            <Route path="sreg" element={ <Reg_Sreg store={this.webStore} />} />
            <Route path="ureg" element={ <Reg_Ureg store={this.webStore} />} />
            <Route path="udid" element={ <Reg_Udid store={this.webStore} />} />
            <Route path="*" element={ <Reg_Home store={this.webStore} />} />
        </Routes>
    </div>
  );
}
}
//Sys.contextType = ADOContext;

export default withRouter(Bcs_Reg);
