import React, { useState, useEffect, useContext } from 'react';
import {format} from "date-fns";

import * as utils from "../../assets/utils";
import * as net from "../../lib/net";
import Dt_Cal from "../../lib/ui/dt_cal";
import Td_Sel from "../../lib/ui/td_sel";

import Ns_Edit from "./fkn/ns_edit";
import Wnd_Sign from "../../lib/ui/wnd_sign";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Pms_NoShow extends React.Component {
  constructor(props, context) {
    super(props);

      this.dt_fd = React.createRef();
      this.dt_td = React.createRef();

    this.webStore = props.store;
    this.state = { pgid: 11, tra: [] };
    this.state.fdate = new Date();
    this.state.tdate = new Date();
    this.state.avd = "01";
    this.state.sortid = "";
    this.state.sortdir = 1;
    this.state.rva = [];
    this.state.aa = [];
    this.state.wnds = {};
    this.state.msg = {};

    let cdat = new Date();
    let formatted_date = cdat.getFullYear() + "-" + (cdat.getMonth() + 1) + "-" + cdat.getDate();
    this.state.today = formatted_date;
    this.state.fdatum = formatted_date;
    this.state.tdatum = formatted_date;

    this.selitems = [
        {fkn: 'edit', text: 'ÖPPNA'},
        {fkn: 'cancel', text: 'AVBOKA'}
    ];
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.noshow();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      css.overflow = "auto";
      return css;
  }
  th_style(id) {
      var css = {};
      css.cursor = "pointer";
      if(id == this.state.sortid) {
          if(this.state.sortdir) css.color = "#800";
          else css.color = "#080";
      }
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }
    dt_fdat(e) {
        //alert(JSON.stringify(e));
        var dts = format(e, "yyyy-MM-dd");
        this.setState({ fdat: dts });
    }
    dt_tdat(e) {
        //alert(JSON.stringify(e));
        var dts = format(e, "yyyy-MM-dd");
        this.setState({ tdat: dts });
    }
    oneday(e) {
        //alert(JSON.stringify(e));
        //this.setState({ tdat: this.state.fdat });
        this.dt_td.current.updateDt(this.state.fdat);
    }
    addday(e) {
        //alert(JSON.stringify(e));
        //this.setState({ tdat: this.state.fdat });
        this.dt_fd.current.updateAdd();
        this.dt_td.current.updateAdd();
    }
    subday(e) {
        //alert(JSON.stringify(e));
        //this.setState({ tdat: this.state.fdat });
        this.dt_fd.current.updateSub();
        this.dt_td.current.updateSub();
    }

  sel_gst(row) {
      var self = this;
  }

  noshow() {
    var self = this;
    var prm = {};
    //prm.fkn = "7107";
    //prm.req = "pms.hot_fkn.noshow_list";
    prm.req = "pms.hot_fkn.ns_ft";
    prm.fdat = this.state.fdat;
    prm.tdat = this.state.tdat;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        self.setState({rva: ret.rca }, function() {
            self.syncTb("nsTable");
        });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
    }
    ns_sel(row) {
        var self = this;
        var msg = { info: false, sign: true, text: "Signatur?", text0: "JA", text1: "NEJ", cb: "ns_sel_do" };
        self.setState({ msg: msg, srow: row });
    }
    ns_sel_do(signatur) {
        var self = this;
        var row = this.state.srow;
        self.setState({msg: {} });

    var wnds = {};
    wnds.nsedit = true;
    wnds.boknr = row.noshow.boknr;
    wnds.blopnr = row.noshow.blopnr;
    wnds.rvo = row;
    this.setState({wnds: wnds});
  }
  sel_row_fkn(row, sel) {
    if(sel.fkn == "edit") {
        this.ns_sel(row);
    }
  }
    syncTb(tb){
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
        tdElements = tbel.getElementsByTagName('td');

        for (let i = 0; i < thElements.length; i++) {
            if(!tdElements[i]) continue;
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
            width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
   th_icon(typ) {
      var cls = "";
      var sortid = this.state.sortid;
      var sortdir = this.state.sortdir;
      if(sortid == typ)
      {
        if(sortdir) cls = "fa fa-caret-up";
        else cls = "fa fa-caret-down";
      }
      return cls;
    }
   tb_sort(typ) {
      var self = this;
      //var rvs = self.state.rvs;
      var rva = self.state.rva;
      var sortid = this.state.sortid;
      var sortdir = this.state.sortdir;

      if(sortid != typ) sortdir = 0;
      else {
        if(sortdir) sortdir = 0;
        else sortdir = 1;
      }

      if(sortdir) rva.sort((a,b) => (a.noshow[typ] > b.noshow[typ]) ? 1 : ((b.noshow[typ] > a.noshow[typ]) ? -1 : 0));
      else rva.sort((a,b) => (a.noshow[typ] < b.noshow[typ]) ? 1 : ((b.noshow[typ] < a.noshow[typ]) ? -1 : 0));
      self.setState({ rva: rva, sortid: typ, sortdir: sortdir });
   }

  render() {
    return (
    <div className="web-bas">

    <div className="flex-row">
        <button className="btn btn-hw-blue w150 ripple" onClick={() => this.plus() } aria-label="System" >
            FUNKTIONER
        </button>
        <div className="flex"></div>
        <div className="flex-row">
              <div className="dt-btn-01" onClick={() => this.subday() } ><FontAwesomeIcon icon="minus" size="1x" /></div>
              <Dt_Cal startdatum="2020-02-01" rp="1" ref={this.dt_fd} getValue={(e)=> this.dt_fdat(e)} />
              <div className="dt-btn-01" onClick={() => this.oneday() } >1</div>
              <Dt_Cal startdatum="2020-02-01" rp="1" ref={this.dt_td} getValue={(e)=> this.dt_tdat(e)} />
              <div className="dt-btn-01" onClick={() => this.addday() } ><FontAwesomeIcon icon="plus" size="1x" /></div>
        </div>
        <div className="flex"></div>
        <button className="btn btn-hw-orange w150" onClick={() => this.ns_hist() } aria-label="System" >
            HISTORIK
        </button>
        <button className="btn btn-hw-blue w150" onClick={() => this.noshow() } aria-label="System" >
            UPPDATERA
        </button>
    </div>
    <div className="divider_orange"></div>

    <div id="nsTable">

          <table className="table table-bordered table-hover" style={{marginBottom: "0px"}}>
            <thead>
            <tr>
                <th style={ this.th_style("boknr") } onClick={() => this.tb_sort("boknr") }>Boknr <i className={ this.th_icon("boknr") }></i></th>
                <th style={ this.th_style("Ankomst") } onClick={() => this.tb_sort("datum") }>Datum <i className={ this.th_icon("datum") }></i></th>
                <th style={ this.th_style("Avresa") } onClick={() => this.tb_sort("datum") }>Datum <i className={ this.th_icon("datum") }></i></th>
                <th style={ this.th_style("Company") } onClick={() => this.tb_sort("Company") }>Company <i className={ this.th_icon("Company") }></i></th>
                <th style={ this.th_style("Name") } onClick={() => this.tb_sort("Name") }>Name <i className={ this.th_icon("Name") }></i></th>
                <th style={ this.th_style("antalrum") } onClick={() => this.tb_sort("antalrum") }>Rum <i className={ this.th_icon("antalrum") }></i></th>
                <th style={ this.th_style("guests") } onClick={() => this.tb_sort("guests") }>Gäster <i className={ this.th_icon("guests") }></i></th>
                <th style={ this.th_style("status") } onClick={() => this.tb_sort("status") }>Status <i className={ this.th_icon("status") }></i></th>
                <th></th>
            </tr>
            </thead>
            </table>
            <div style={ this.tb_style() }>
          <table className="table table-bordered table-hover">
            <tbody>
            { this.state.rva.map((row, key) =>
            <tr key={key} onDoubleClick={() =>  this.ns_sel(row) }>
                <td>{ row.rblx0.r0boknr }</td>
                <td>{ utils.toDate( row.rblx0.r0ankdatum ) }</td>
                <td>{ utils.toDate( row.rblx0.r0avrdatum ) }</td>
                <td>{ row.resv00.Company }</td>
                <td>{ row.resv01.Name }</td>
                <td>{ utils.toNum( row.rblx0.r0borr ) }</td>
                <td>{ utils.toNum( row.rblx0.r0borg ) }</td>
                <td>{ row.status }</td>
                <td>
                    <Td_Sel items={ this.selitems } getValue={(e)=> this.sel_row_fkn(row, e)} />
                </td>
            </tr>
            )}
            </tbody>
        </table>
        </div>
    </div>

    { this.state.wnds.nsedit ?
        <Ns_Edit wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
    : null }
        { this.state.msg.sign ? <Wnd_Sign msg={this.state.msg} ctx={this} /> : null }
    </div>
  );
    }
}
export default Pms_NoShow;
