import React, { useState, useEffect, useContext } from 'react';
import {observer} from "mobx-react";

import * as net from "../lib/net";
import {withRouter} from "../lib/react/withRouter";

class Std_Units extends React.Component {
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pos = props.store.pos;

    this.state = { pgid: 11, tra: [] };
    this.state.seldate = new Date();
    this.state.avd = "01";
    this.state.csa = [];
    this.state.aa = [];
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      //this.units();
  }
  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }

  fknGo(url) {
    //alert(row.href);
    this.props.navigate(url);
  }
  units() {
    var self = this;

    var prm = {};
    prm.req = "cs.cs_units.csa";
    //prm.fdat = fdat;
    //prm.tdat = tdat;

    net.ccs_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            alert(JSON.stringify(ret));
            return;
        }
        var sa = ret.rca;
        //alert(JSON.stringify(sa));
        self.setState({ csa: sa }, () => {
        });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

  render() {
    return (
    <div className="web-app">
    <h3>Enheter</h3>

    <div className="web-p01 flex">

        <div className="flex-row">
            <h5>ENHETER</h5>
            <div className="flex"></div>

            <button className="btn btn-hw-blue w150" onClick={() => this.units()} aria-label="System">
                UPPDATERA
            </button>
        </div>
        <div className="divider_orange"></div>

        <div className="web-row">
            <table className="table table-bordered table-hover tb fixed_header">
                <thead>
                <tr>
                    <th>ID</th>
                    <th>Text</th>
                    <th>Typ</th>
                    <th>Sid</th>
                    <th>Status</th>
                </tr>
                </thead>
                <tbody style={this.tb_style()}>
                {this.props.store.pos.deps.map((row, key) =>
                    <tr key={key} onDoubleClick={() => this.unit_edit(row)}>
                        <td>{row.avd}</td>
                        <td>{row.text}</td>
                        <td>{row.typ}</td>
                        <td>{row.sid}</td>
                        <td>{row.status}</td>
                    </tr>
                )}
                </tbody>
            </table>
        </div>

    </div>

    </div>
  );
    }
}
export default withRouter(observer(Std_Units));
