import React, { useState, useEffect, useContext } from 'react';
import socketIOClient from "socket.io-client";
import axios from 'axios'

import * as net from "../../lib/net";
import Wnd_Ok from "../../lib/ui/wnd_ok";

class Emv_WsApi extends React.Component {
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.state = {};
    this.state.bqa = [];
    this.state.tra = [];
    this.state.splitrows = {};
    this.state.msg = {};

      this.state.skey = "";
      this.state.logg = "";
      this.state.grs = {};
    this.state.grs.connected = 0;
    this.gio = null;
      this.state.sale = [];
      this.state.sale.push({"productid": "1011", "text": "TST SALE", "nrof": "1", "amount": "12.00"});
      this.state.sale.push({"productid": "1011", "text": "TST SALE", "nrof": "1", "amount": "15.00"});
   }
  componentDidUpdate() {
  }
  componentWillUnmount() {
      this.grs_down();
  }
  componentDidMount() {
      //this.my_ip();
      //this.grs_up();
  }
    my_ip = async () => {
        const res = await axios.get('https://geolocation-db.com/json/');
        //console.log(res.data);
        alert(JSON.stringify(res.data));
        //setIP(res.data.IPv4)
    }
    grs_up() {
      var self = this;
        //var url = window.location.host;
        //var url = window.location.origin;
        //alert(JSON.stringify(url));
        var url = 'http://127.0.0.1:9140/';
        this.gio = socketIOClient(url, {transports: ["websocket"]});
        //sio.emit('change color', this.state.color);

        this.gio.on('connect', function () {
            //alert('Connected!');
            //toastr.success("IO CONNECTED");
            var grs = self.state.grs;
            grs.connected = 1;
            self.setState({ grs: grs });
        });
        this.gio.on('disconnect', function () {
            var grs = self.state.grs;
            grs.connected = 0;
            self.setState({ grs: grs });
        });
        this.gio.on('connect_error', function (e) {
            //alert('Connect Error : ' + JSON.stringify(e));
            //toastr.error("IO CONNECT ERROR", e);
        });
        this.gio.on('error', function (e) {
            //alert('Socket Error : ' + e);
            //toastr.error("IO ERROR", e);
        });
        this.gio.on('gcs:rsp', function (base64) {
            //var s = atob(base64);
            //var rsp = JSON.parse(s);
            //alert(JSON.stringify(base64));
            var rsp = base64;
            var reqid = rsp.reqid;
            //if(self.defer[reqid]) self.defer[reqid].resolve(rsp);
            //if(defer[reqid]) defer[reqid].resolve(rsp);
        });
    }

    grs_down() {
        if(this.gio) this.gio.disconnect();
    }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    tab_style(tabid) {
      var css = {};
      css.display = "none";
      if(this.state.tabval === tabid) css.display = "block";
      return css;
    }
    bq_close() {
		this.props.ctx.setState({ wnds: {} });
	};
    tb_style() {
        var css = {};
        css.height = window.innerHeight - 275;
        return css;
    }
    row_style(row) {
        var css = {};
        var splitrows = this.state.splitrows;
        var key = row.r0dateid;
        if(splitrows[key]) {
            css.background = "#080";
            css.color = "#fff";
        }
        return css;
    }

    grs_poll() {
        var req = {};
        req.fkn = "emv";
        req.sid = "S0001122";
        this.gio.emit("grs:req", req, function () {
        });
    }
    grs_sale() {
        var req = {};
        req.fkn = "emv_sale";
        req.sid = "S0001122";
        req.token = "a1b2";
        req.emvnr = "095";
        req.prm = {};
        req.prm.emvnr = "095";
        req.prm.belopp = "6100";
        req.prm.rows = [];
        req.prm.rows.push({ "plu": "", "taxproc": "2500", "text": "Produkt 1", "antal": "1", "belopp": "2900" });
        req.prm.rows.push({ "plu": "", "taxproc": "1200", "text": "Produkt 2", "antal": "1", "belopp": "3200" });
        this.gio.emit("grs:req", req, function () {
        });
    }
    grs_settle() {
        var req = {};
        req.fkn = "emv";
        req.sid = "S0001122";
        this.gio.emit("grs:req", req, function () {
        });
    }

    api_auth() {
        var self = this;

        self.setState({ skey: "" });

        var req = {};
        req.pid = "P0003118";
        req.pkey = "pel2021ht";
        //alert(JSON.stringify(req));

        axios.post("https://api.svepos.se/payment/session/auth", req)
        .then(res => {
            //alert("RSP: " + JSON.stringify(res));
            var rsp = res.data;
            var skey = rsp.skey;
            //alert("SKEY: " + JSON.stringify(skey));
            self.setState({ skey: skey });
        })
        .catch((error) => {
            alert("ERROR: " + JSON.stringify(error));
            if (error.response) {
            }
            else if (error.request) {
                console.log(error.request);
            }
            else {
                console.log('Error', error.message);
            }
            console.log(error.config);
        });

        /*
        var url = "https://api.svepos.se/payment/session/auth";
        //url = url + "?pid=" + "P0003118" + "&" + "pkey=" + "pel2021ht";
        var opts = {};
        opts.method = 'POST';
        opts.headers = { 'Content-Type':'application/json' };
        //opts.body = {};
        //opts.body = req;
        opts.body = JSON.stringify(req);
        //alert(JSON.stringify(opts));

        fetch(url, opts)
        .then(res => res.json())
        .then((data) => {
            //alert(JSON.stringify(data));
            var rsp = data;
            var skey = rsp.skey;
            alert("SKEY: " + JSON.stringify(skey));
            this.setState({ skey: skey })
        })
        .catch(console.log)
       */
    }
    api_purchase() {
        var self = this;

        var belopp = 0;
        for(var row of this.state.sale) {
            belopp = belopp + (row.amount * 1);
        }

        var req = {};
        req.skey = this.state.skey;
        req.emvnr = "095";
        req.amount = belopp.toString();
        req.sale = this.state.sale;
        req.callback = "http://127.0.0.1:7139/rsp";

        axios.post("https://api.svepos.se/payment/emv/purchase", req)
            .then(res => {
                //alert("RSP: " + JSON.stringify(res));
                var rsp = res.data;
                alert("RSP: " + JSON.stringify(rsp));
            })
    }
    emv_purchase(emvnr) {
        var self = this;

        var belopp = 0;
        for(var row of this.state.sale) {
            belopp = belopp + (row.amount * 1);
        }

        var req = {};
        req.skey = this.state.skey;
        req.emvnr = emvnr;
        req.amount = belopp.toString();
        req.sale = this.state.sale;
        req.callback = "http://127.0.0.1:7139/rsp";

        axios.post("https://api.svepos.se/payment/emv/purchase", req)
            .then(res => {
                //alert("RSP: " + JSON.stringify(res));
                var rsp = res.data;
                alert("RSP: " + JSON.stringify(rsp));
            })
    }
    emv_refund(emvnr) {
        var self = this;

        var belopp = 0;
        for(var row of this.state.sale) {
            belopp = belopp + (row.amount * 1);
        }

        var req = {};
        req.skey = this.state.skey;
        req.emvnr = emvnr;
        req.amount = belopp.toString();
        req.sale = this.state.sale;
        req.callback = "http://127.0.0.1:7139/rsp";

        axios.post("https://api.svepos.se/payment/emv/refund", req)
            .then(res => {
                //alert("RSP: " + JSON.stringify(res));
                var rsp = res.data;
                alert("RSP: " + JSON.stringify(rsp));
            })
    }
    api_refund() {
        this.emv_refund("095");
    }
    api_settle() {
    }
    api_terminals() {
    }

    syncTb(tb){
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
        tdElements = tbel.getElementsByTagName('td');

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
            width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
    skvinfo() {
        var self = this;

        var prm = {};
        prm.req = "vcm.pp.skv_info";
        prm.status = "";

        net.sio_req(prm)
        .then(function(ret){
            alert(JSON.stringify(ret));

            //self.setState({bqa: ret.rca });
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }

  render() {

    var title = this.props.title;
    if(!title) title = "Titel";

	var html =
        <div className="mx_base">
        { this.props.wnds.backdrop ? <div className="mx_backdrop" /> : null }
        <div className="mx_dlg mx_90 flex-col" style={ this.dlg_style() }>
        <div className="mx_content flex">

            <div className="flex-row" style={{padding:"5px",height:"35px"}}>
                    <div className="flex-col flex">
                        <div>SIM EMV WS API</div>
                    </div>
                    <div className="flex-col">
                        <div>UPPDATERA</div>
                    </div>
            </div>
            <div className="divider_blue"></div>
        <div className="flex-row">
            <div className="flex-row">
                <button className="btn btn-hw-green w150" onClick={() => this.api_auth() } aria-label="System" >
                    API AUTH
                </button>
            </div>
            <div className="flex"></div>
            <div className="txt_16">{ this.state.skey }</div>
            <div className="flex"></div>
            <div className="flex-row">
                <button className="btn btn-hw-blue w150" onClick={() => this.api_terminals() } aria-label="System" >
                    API TERMINALS
                </button>
                <button className="btn btn-hw-blue w150 hide" onClick={() => this.api_purchase() } aria-label="System" >
                    API PURCHASE
                </button>
                <button className="btn btn-hw-blue w150 hide" onClick={() => this.api_refund() } aria-label="System" >
                    API REFUND
                </button>
                <button className="btn btn-hw-blue w150 hide" onClick={() => this.api_settle() } aria-label="System" >
                    API SETTLE
                </button>
            </div>
        </div>
            <div className="divider_blue"></div>
            <div className="flex-row px-2">
                <div className="flex"></div>
                <div className="flex-row">GRS: { this.state.grs.connected ? <div className="cdigreen">UPPKOPPLAD</div> : <div className="cdired">NEDKOPPLAD</div> }</div>
                <div className="flex"></div>
            </div>
            <div className="divider_orange"></div>

            <div className="flex-row px-2">
                <div className="web-btn flex" onClick={() => this.emv_purchase("001") } >
                    <div>EMV 001</div>
                    <div>Kungsholmen</div>
                </div>
                <div className="web-btn flex" onClick={() => this.emv_purchase("002") } >
                    <div>EMV 002</div>
                    <div>Lidingö</div>
                </div>
                <div className="web-btn flex" onClick={() => this.emv_purchase("003") } >
                    <div>EMV 003</div>
                    <div>Bromma</div>
                </div>
                <div className="web-btn flex" onClick={() => this.emv_purchase("004") } >
                    <div>EMV 004</div>
                    <div>Sollentuna</div>
                </div>
            </div>
            <div className="flex-row px-2">
                <div className="web-btn flex" onClick={() => this.emv_purchase("005") } >
                    <div>EMV 005</div>
                    <div>Täby</div>
                </div>
                <div className="web-btn flex" onClick={() => this.emv_purchase("006") } >
                    <div>EMV 006</div>
                    <div>Botkyrka</div>
                </div>
                <div className="web-btn flex" onClick={() => this.emv_purchase("007") } >
                    <div>EMV 007</div>
                    <div>Stockby</div>
                </div>
                <div className="web-btn flex" onClick={() => this.emv_purchase("095") } >
                    <div>EMV 095</div>
                    <div>Test terminal</div>
                </div>
            </div>

            <div className="flex-row flex px-2">
            <div className="web-emv flex">
                <div className="txt_18 text-center">NOTA</div>
                <div className="divider_blue"></div>
                <div className="flex">
                { this.state.sale.map((row, key) =>
                    <div className="flex-row txt_14">
                        <div className="text-left flex">{ row.productid }</div>
                        <div className="text-left flex">{ row.text }</div>
                        <div className="text-center flex">{ row.nrof }</div>
                        <div className="text-right flex">{ row.amount }</div>
                    </div>
                )}
                </div>
                <div className="divider_blue"></div>
                <div className="flex-row px-2">
                    <div className="web-link">RENSA</div>
                    <div className="flex"></div>
                    <div className="web-link">LÄGG TILL</div>
                </div>
            </div>
            <div className="px-2"></div>
            <div className="web-emv flex">
                <div className="txt_18 text-center">MOTTAGET</div>
                <div className="divider_blue"></div>
                <div className="flex">
                    <div className="">{ this.state.logg }</div>
                </div>
            </div>
        </div>

        </div>

        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.bq_close() } >
                STÄNG
            </button>
            <div className="flex"></div>
            <button className="btn btn-hw-green w250" onClick={() => this.ppr() } >
                UPPDATERA
            </button>
        </div>
      </div>
        <Wnd_Ok msg={this.state.msg} ctx={this} />
    </div>
	;
	return html;
  };
}

export default Emv_WsApi;