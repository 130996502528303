import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";
import * as utils from "../../assets/utils";
import Dt_Cal from "../../lib/ui/dt_cal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Rsk_Rmd extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, tra: [] };
    this.state.seldate = new Date();
    this.state.avd = "01";
    this.state.rsa = [];
    this.state.aa = [
                {id: '01', text: 'AVD 01'},
                {id: '02', text: 'AVD 02'}
            ];
    this.state.fao = {};
    this.state.cols = {};
    //context.posrend = this;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.rmd();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
    th_style(id) {
        var css = {};
        css.cursor = "pointer";
        if(id == this.state.sortid) {
            if(this.state.sortdir) css.color = "#800";
            else css.color = "#080";
        }
        return css;
    }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

  fak_open(row) {
      var self = this;
  }
  fak_edit(row) {
      var self = this;
      alert(JSON.stringify(row.plu00));
  }

    rmd() {
        var self = this;
        var prm = {};
        prm.req = "fakt.fakt_rsk.rsk";
        //prm.avd = this.state.avd;
        //prm.fplu = datum;
        //prm.tplu = datum;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.rca));

                self.setState({rsa: ret.rco });
                self.syncTb("rskTable");
                //toastr.success("OK", "SAPI");
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    syncTb(tb){
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
            tdElements = tbel.getElementsByTagName('td');
        if(tdElements.length < 1 ) return;

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
                width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }

  render() {
    return (
    <div className="web-app">

    <div className="flex-row">
        <div className="flex-row">
            <div className="dt-btn-01" onClick={() => this.subday() } ><FontAwesomeIcon icon="minus" size="1x" /></div>
            <Dt_Cal dts={this.state.dts} rp="1" ref={this.dt_dt} getValue={(e)=> this.dt_dat(e)} />
            <div className="dt-btn-01" onClick={() => this.addday() } ><FontAwesomeIcon icon="plus" size="1x" /></div>
        </div>
        <select className="form-control sel_input" placeholder="Välj avdelning">
            { this.state.aa.map((row, key) =>
            <option value={row.id}>{row.text}</option>
            )}
        </select>
        <button className="btn btn-hw-blue w150 ripple" onClick={() => this.plus() } aria-label="System" >
            Visa
        </button>
    </div>
        <div className="divider"></div>

        <div className="web-col">
            <div id="rskTable">
                <table className="table table-bordered table-hover tb" style={{marginBottom: "0px"}}>
                    <thead>
                    <tr>
                        <th style={ this.th_style("faknr" ) }>Faknr</th>
                        <th style={ this.th_style("kund" ) }>Kund</th>
                        <th style={ this.th_style("company" ) }>Företag</th>
                        <th style={ this.th_style("belopp" ) }>Belopp</th>
                        <th style={ this.th_style("moms" ) }>Moms</th>
                        <th style={ this.th_style("brutto" ) }>Ex moms</th>
                        <th style={ this.th_style("fakdatum" ) }>Fakturadatum</th>
                        <th style={ this.th_style("ffdatum" ) }>Förfallodatum</th>
                        <th style={ this.th_style("days" ) }>Dagar</th>
                        <th style={ this.th_style("status" ) }>Status</th>
                        <th style={ this.th_style("" ) }></th>
                    </tr>
                    </thead>
                </table>
                <div style={ this.tb_style() }>
                    <table className="table table-bordered table-hover tb">
                        <tbody>
                        { this.state.rsa.map((row, key) =>
                            <tr key={ key } onDoubleClick={() => this.fak_open(row) }>
                                <td>{ utils.toNum( row.wfak00.faknr ) }</td>
                                <td>{ utils.toNum( row.wfak00.kund ) }</td>
                                <td>{ utils.toLang( row.wfak00.company ) }</td>
                                <td className="txt_right">{ utils.toPris( row.wfak00.belopp ) }</td>
                                <td className="txt_right">{ utils.toPris( row.wfak00.moms ) }</td>
                                <td className="txt_right">{ utils.toPris( ((row.wfak00.belopp * 1) - (row.wfak00.moms * 1)).toString() ) }</td>
                                <td className="txt_center">{ utils.toDate( row.wfak00.fakdatum ) }</td>
                                <td className="txt_center">{ utils.toDate( row.wfak00.ffdatum ) }</td>
                                <td className="txt_center">{ utils.toNum( row.wfak00.days ) }</td>
                                <td>{ row.wfak00.status }</td>
                                <td onClick={() => this.fak_open(row) }><i className="fa fa-cog text-danger"></i></td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    </div>
  );
    }
}
export default Rsk_Rmd;
