import React, { useState, useEffect, useContext } from 'react';
import {format} from "date-fns";

import * as net from "../../../../lib/net";
import Dd_Sel from "../../../../lib/ui/dd_sel";
import Td_Sel from "../../../../lib/ui/td_sel";
import Sell_Tr from "../sell_tr";
import Pay_Sz from "./pay_sz";
import sso from "../../../../sso";
import Pay_Pres from "./pay_pres";
import Pay_Fsk from "./pay_fsk";

class Pay_Sel extends React.Component {
  constructor(props, context) {
    super(props);

     this.selitems = [
        {fkn: 'setup', text: 'INSTÄLLNINGAR'},
        {fkn: 'upd', text: 'UPPDATERA'},
        {fkn: 'sys', text: 'SYSTEM'},
        {fkn: 'logout', text: 'LOGGA UT'}
    ];

    this.fkn_close = null;
    this.store = props.store;
    this.state = {};
    this.state.ynq = [];

    this.state.paa = [];
    this.state.aa = [];
    this.state.wnds = {};
    this.state.selrow = {};
    this.state.srow = {};

    this.state.boknr = "";
    this.state.rvo = {};
    this.state.nta = [];
    if(props.wnds) {
        this.fkn_close = props.wnds.fkn_close;
        this.state.boknr = props.wnds.boknr;
        this.state.gso = props.wnds.gso;
        this.state.nta = props.wnds.nta;
    }

    this.state.today = new Date();
    var dts = format(this.state.today, "yyyy-MM-dd");
    this.state.dts = dts;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      this.syncTb("payrowsTable")
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }

    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    wnd_close() {
      if(this.fkn_close) this.fkn_close(this.props.ctx);
      else this.props.ctx.setState({ wnds: {} });
	};
  tb_style() {
      var css = {};
      //css.height = window.innerHeight - 275;
      css.overflow = "auto";
      return css;
  }
  row_style(row) {
    var srow = this.state.srow;
    var css = {};
    if(srow[row.etr01.e0lopnr]) {
        css.background = "#080";
        css.color = "#fff";
    }
    return css;
  }
  clear() {
    this.setState({srow: {}});
  }
  set_all() {
    var srow = this.state.srow;
    for(var row of this.state.nta) {
        srow[row.etr01.e0lopnr] = row;
    }
    this.setState({srow: srow});
  }
  set_lang() {
    var lang = this.state.lang;
    if(lang == "en") lang = "se";
    else lang = "en";
    this.setState({lang: lang});
  }
  sel_row(row) {
    var srow = this.state.srow;

    if(srow[row.etr01.e0lopnr]) {
      delete( srow[row.etr01.e0lopnr] );
    }
    else srow[row.etr01.e0lopnr] = row;
    this.setState({srow: srow});
  }
  pay_trs(betkod) {
    var self = this;

    var sdo = {};
    var bettxt = "BET: " + betkod;

    if(betkod == "kont") bettxt = "KONTANT";
    if(betkod == "fakt") bettxt = "FAKTURA";
    if(betkod == "swis") bettxt = "SWISH";
    if(betkod == "pres") bettxt = "PRESENTKORT";
    if(betkod == "fsk") bettxt = "FÖRSKOTT";
    if(betkod == "card") bettxt = "KORT EXTERN";

    if(betkod == "emv") {
        bettxt = "KORT";
        return;
    }
    if(betkod == "pres") {
        this.pay_pres();
        return;
    }
    if(betkod == "fsk") {
        this.pay_fsk();
        return;
    }

    var belopp = 0;
    var pra = [];
    if(Object.keys(this.state.srow).length < 1) {
        for(var row of this.state.nta) {
            var pris = row.etr01.e0pris * 1;
            belopp = belopp + pris
            pra.push( row.etr01);
        }
    }
    else {
        //pra.push( this.state.selrow.etr01);
        for(var row of Object.values(this.state.srow)) {
            var pris = row.etr01.e0pris * 1;
            belopp = belopp + pris
            pra.push( row.etr01);
        }
    }

    if(belopp == 0) {
        alert("Inget att betala");
        return;
    }
    belopp = belopp.toString();

    var prm = {};
    prm.req = "pms.charge.pay_trs";
    prm.boknr = this.state.gso.r0boknr;
    prm.glopnr = this.state.gso.r0glopnr;
    prm.payrows = pra;
    prm.paycode = betkod;
    prm.paytext = bettxt;
    prm.payamount = belopp;
    prm.tkn = "+";
    prm.datum = this.state.dts;
    //alert(JSON.stringify(prm));

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        sdo.notanr = ret.notanr;

        var prm = {};
        prm.req = "pms.charge.kvitto_petty";
        prm.boknr = self.state.gso.r0boknr;
        prm.glopnr = self.state.gso.r0glopnr;
        prm.gso = self.state.gso;
        prm.notanr = sdo.notanr;
        prm.prnnr = sso.seo.prnnr;
        prm.lang = self.state.lang;
        return net.sio_req(prm);
    })
    .then(function(ret){
        //alert(JSON.stringify(ret));
        if(ret.ok != "000") {
            alert("Skriv fel...");
            return;
        }

        if(self.props.wnds.cb) {
            var rsp = {};
            rsp.ok = "000";
            self.props.wnds.cb(self.props.ctx, rsp);
        }
        else self.props.ctx.setState({ wnds: {} });
        //self.charges();
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  pay_pres() {
    var self = this;

    var nta = this.state.nta;
    if(Object.keys(this.state.srow).length > 0) {
        nta = Object.values(this.state.srow);
    }

    var pra = [];
    //pra.push( this.state.selrow.etr01);
    var belopp = 0;
    for(var row of nta) {
        var pris = (row.etr01.e0antal * 1) * (row.etr01.e0pris * 1);
        if(row.etr01.e0tkn == "-") pris = pris * (-1);
        belopp = belopp + pris
        pra.push( row.etr01);
    }

    if(belopp == 0) {
        alert("Inget att betala");
        return;
    }
    belopp = belopp.toString();

    //alert("Pres");
     var wnds = {};
     wnds.paypres = 1;
    wnds.boknr = this.state.gso.r0boknr;
    wnds.gso = this.state.gso;
    wnds.pra = pra;
    wnds.belopp = belopp;
     wnds.cb = this.cb_pay_pres;
     this.setState({wnds: wnds});
 }
 
 cb_pay_pres(ctx, ret) {
     if(ret && (ret.ok == "000")) {

     }
     if(ctx.props.wnds.cb) {
        var rsp = {};
        rsp.ok = "000";
        ctx.props.wnds.cb(ctx.props.ctx, rsp);
    }
    else ctx.props.ctx.setState({ wnds: {} });
}
 pay_fsk() {
     var self = this;

     var nta = this.state.nta;
     if(Object.keys(this.state.srow).length > 0) {
         nta = Object.values(this.state.srow);
     }

     var pra = [];
     //pra.push( this.state.selrow.etr01);
     var belopp = 0;
     for(var row of nta) {
         var pris = (row.etr01.e0antal * 1) * (row.etr01.e0pris * 1);
         if(row.etr01.e0tkn == "-") pris = pris * (-1);
         belopp = belopp + pris
         pra.push( row.etr01);
     }

     if(belopp == 0) {
         alert("Inget att betala");
         return;
     }
     belopp = belopp.toString();

     var wnds = {};
     wnds.payfsk = 1;
     wnds.boknr = this.state.gso.r0boknr;
     wnds.gso = this.state.gso;
     wnds.pra = pra;
     wnds.belopp = belopp;
     wnds.cb = this.cb_pay_fsk;
     this.setState({wnds: wnds});
 }
 cb_pay_fsk(ctx, ret) {
     if(ret && (ret.ok == "000")) {

     }
     if(ctx.props.wnds.cb) {
        var rsp = {};
        rsp.ok = "000";
        ctx.props.wnds.cb(ctx.props.ctx, rsp);
    }
    else ctx.props.ctx.setState({ wnds: {} });
}

 syncTb(tb){
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
        tdElements = tbel.getElementsByTagName('td');
        if(tdElements.length == 0) {
            //alert("No td");
             return;
       }

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
            width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
  pay_sz() {
    var wnds = {};
    wnds.paysz = 1;
    wnds.boknr = this.state.boknr;
    wnds.gso = this.state.gso;
    wnds.nta = this.state.nta;
    wnds.cb = this.cb_paysz;
    this.setState({wnds: wnds});
  }
  cb_paysz(ctx, ret) {
      if(ret && (ret.ok == "000")) {

      }
      ctx.setState({ wnds: {} });
  };
  val_date(val) {
    var ret = "";
    if(val) ret = val.toString().cdidate();
    return ret;
}
showLang() {
    var langtxt = "Svenska";
    if(this.state.lang == "en") langtxt = "Engelska";
    return langtxt;
}

  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "";

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="m2_backdrop" /> : null }
        <div className="m2_dlg mx_70 flex-col" style={ this.dlg_style() }>
        <div className="flex-row " style={{padding:"0px",height:"50px",lineHeight:"50px", background: "#1a456b", color: "#fff"}}>
                <div className="flex-row flex" style={{padding:"0px 5px", fontSize: "22px"}}>
                    <div>BETAL VAL { this.state.rumsnr }</div>
                </div>
            <div className="wnd-close-btn" onClick={() => this.wnd_close() }>X</div>
        </div>
        <div className="mx_content flex">

        <div className="web-col flex" style={{padding: "0px"}}>

        <div id="payrowsTable" className="flex">
          <table className="table table-bordered table-hover tb" style={{marginBottom: "0px"}}>
            <thead>
            <tr>
                <th>Datum</th>
                <th>T</th>
                <th>Typ</th>
                <th>Text</th>
                <th>Antal</th>
                <th>Pris</th>
                <th>Summa</th>
                <th>Tecken</th>
                <th>Löpnr</th>
                <th></th>
            </tr>
            </thead>
        </table>
        <div style={ this.tb_style() }>
          <table className="table table-bordered table-hover tb">
            <tbody>
            { this.state.nta.map((row, key) =>
            <tr key={key} style={ this.row_style(row) } onClick={ () => this.sel_row(row) }>
                <td>{ this.val_date( row.etr01.e0datum ) }</td>
                <td>{ row.etr01.e0bastyp }</td>
                <td>{ row.etr01.e0typ }</td>
                <td>{ row.etr01.e0text }</td>
                <td className="text-center">{ row.etr01.e0antal.ztrim() }</td>
                <td className="text-right">{ row.etr01.e0pris.cdipris() }</td>
                <td className="text-right">{ ((row.etr01.e0antal * 1) * (row.etr01.e0pris * 1)).cdipris() }</td>
                <td>{ row.etr01.e0tkn }</td>
                <td>{ row.etr01.e0lopnr.ztrim() }</td>
                <td>
                    <Td_Sel items={ this.rowitems } getValue={(e)=> this.sel_row_fkn(row, e)} />
                </td>
            </tr>
            )}
            </tbody>
        </table>
        </div>
        </div>

        <div className="flex-row px-2">
            <div className="web-link" onClick={() => this.clear() }>Rensa</div>
            <div className="flex"></div>
            <div className="web-link" onClick={() => this.set_lang() }>{ this.showLang() }</div>
            <div className="flex"></div>
            <div className="web-link" onClick={() => this.set_all() }>Välj alla</div>
        </div>
        <div className="divider_orange"></div>

        <div className="web-row">
        <div className="web-col flex">
            <div className="wnd-btn" onClick={() => this.pay_trs("fakt") } >
                <div>FAKTURA</div>
                <div></div>
            </div>
            <div className="wnd-btn" onClick={() => this.wnd_close() } >
                <div>STÄNG</div>
                <div></div>
            </div>
        </div>
        <div className="web-col flex">
            <div className="wnd-btn" onClick={() => this.pay_trs("card") } >
                <div>KORT EXTERN</div>
                <div></div>
            </div>
            <div className="wnd-btn" onClick={() => this.pay_trs("fsk") } >
                <div>FÖRSKOTT</div>
                <div></div>
            </div>
       </div>
       <div className="web-col flex" style={{padding: "0px 10px"}}>
            <div className="wnd-btn" onClick={() => this.pay_trs("bg") } >
                <div>BANKGIRO</div>
                <div></div>
            </div>
       </div>
         <div className="web-col flex" style={{padding: "0px 10px"}}>
            <div className="wnd-btn" onClick={() => this.pay_trs("swis") } >
                <div>SWISH</div>
                <div></div>
            </div>
            <div className="wnd-btn" onClick={() => this.pay_trs("pres") } >
                <div>PRESENTKORT</div>
                <div></div>
            </div>
       </div>
         <div className="web-col flex" style={{padding: "0px 10px"}}>
            <div className="wnd-btn" onClick={() => this.pay_trs("emv") } >
                <div>EMV KORT</div>
                <div></div>
            </div>
            <div className="wnd-btn" onClick={() => this.pay_trs("kont") } >
                <div>KONTANT</div>
                <div></div>
            </div>
       </div>
       </div>

        </div>

        </div>
      </div>
    { this.state.wnds.paysz ? <Pay_Sz wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
    { this.state.wnds.paypres ? <Pay_Pres wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
    { this.state.wnds.payfsk ? <Pay_Fsk wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
    </div>
	;
	return html;
  };
}

export default Pay_Sel;