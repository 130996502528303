import React, { useState, useEffect, useContext } from 'react';
import {format, addDays, subDays, parseISO} from "date-fns";

import * as net from "../../../../lib/net";
import Fr_Input from "../../../../lib/ui/fr_input";
import Fr_Select from "../../../../lib/ui/fr_select";
import Fr_Cal from "../../../../lib/ui/fr_cal";

class Kf_Add extends React.Component {
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pms = props.store.pms;

    this.state = {};
    this.state.today = new Date();
    this.state.tds = parseISO(this.state.today);;

    this.state.dta = [];
    this.state.rsa = [];
    this.state.pka = [];
    this.state.pkat = "";
    this.state.pko = {};
    this.state.pks = {};

    this.state.gnr = "001";
    this.state.gbo = {};
    //this.state.lro = props.lro;
    this.state.ynq = [
                {id: '0', text: 'NEJ'},
                {id: '1', text: 'JA'}
            ];

    this.state.background = "#fff";
    this.state.fdat = "";
    this.state.tdat = "";

    this.state.cols = {};
    this.state.lrwrk = false;
    this.state.boknr = {};
    if(props.boknr) this.state.boknr = props.boknr;
    this.state.kfo = {};
    if(props.kfo) this.state.kfo = props.kfo;
    if(props.wnds) {
        if(props.wnds.boknr) this.state.boknr = props.wnds.boknr;
        if(props.wnds.kfo) this.state.kfo = props.wnds.kfo;
    }
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.kf_init();
    this.dd_init();
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    grp_style(row) {
      var css = {};
      css.background = "#008";
      if(this.state.gnr === row.vgrupp) css.background = "#080";
      return css;
    }
  bel_style() {
      var h = window.innerHeight;
      ///var h = (window.innerHeight * 0.95);
      var css = {};
      css.height = (h - 650);
      css.height = (h - 650);
      css.overflow = "auto";
      return css;
  }

    formchange (e) {
      const name = e.target.name;
      const value = e.target.value;
      var pbo = this.state.pbo;
      var na = name.split(".");
      pbo[na[0]][na[1]] = value;
      this.setState({pbo: pbo});
      //setState({[name]: value});
    }

    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    wnd_close = () => {
      if(this.props.cb) {
        var ret = {};
        ret.ok = "999";
        this.props.cb(this.props.ctx, ret);
      }
      else this.props.ctx.setState({ wnds: {} });
	};
    handleChange = (event, newValue) => {
        //alert(newValue);
        this.setState({ix: newValue});
      };
    toggle() {
      this.setState({modal: false});
	};

    submitHandler = event => {
        event.preventDefault();
        event.target.className += " was-validated";
    };

    changeHandler = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

  dt_kf(row, dto) {
      var self = this;
      var txt = "";

      var rs = row.restname.trim();
      if(dto.rs[rs]) {
          var o = dto.rs[rs];
          //alert(JSON.stringify(dto));
          txt = o.status;
      }
      return txt;
  }
    rsv_save (name, value) {
        //alert(JSON.stringify(name));
        //alert(JSON.stringify(value));
        var rsv = this.state.bro;
        var na = name.split(".");
        rsv[na[0]][na[1]] = value;
        this.setState({rsv: rsv});
        //setState({[name]: value});
    }
    val_save (name, value) {
        //alert(JSON.stringify(name));
        //alert(JSON.stringify(value));
      var kfo = this.state.kfo;
      kfo[name] = value;
      this.setState({kfo: kfo});
      //setState({[name]: value});
    }
    val_dt_save (name, value) {
        var state = this.state;
        //var dts = format(value, "yyyy-MM-dd");
        state[name] = value;
        this.setState(state);
    }
    val_fd_save (dts) {
     var self = this;
     var fdat = this.state.fdat;
      var tdat = this.state.tdat;
      //var dts = format(value, "yyyy-MM-dd");
      if(dts < this.state.tds) dts = this.state.tds;
      fdat = dts;
      if(tdat < dts) tdat = dts;
      this.setState({fdat: fdat, tdat: tdat}, function() {
        self.bel_kf();
      });
    }
    val_td_save (dts) {
    var self = this;
      var fdat = this.state.fdat;
      var tdat = this.state.tdat;
      //var dts = format(value, "yyyy-MM-dd");
      if(dts < this.state.tds) dts = this.state.tds;
      if(dts < fdat) fdat = dts;
      tdat = dts;
      this.setState({fdat: fdat, tdat: tdat});
    }
    rs_set (name, e) {
      const value = e.target.value;
      var kfo = this.state.kfo;
      kfo[name] = value;
      this.setState({kfo: kfo});
      //setState({[name]: value});
    }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }

  kf_init() {
    var self = this;
    var dat = addDays(this.state.today, 1);
    var fdat = format(this.state.today, "yyyy-MM-dd");
    var tdat = format(dat, "yyyy-MM-dd");

    var kfo = {};
    kfo.resno = this.state.boknr;
    kfo.noof = "1";
    kfo.rtime = "0800";
    kfo.sluttid = "1700";
    kfo.rtime = "0800";
    kfo.sluttid = "1700";
    kfo.astart = "0800";
    kfo.aslut = "1700";
    kfo.restname = "";
    kfo.konferens = "";
    kfo.dukning = "";
    kfo.pristyp = "";
    kfo.pris = "";
    this.setState({fdat: fdat, tdat: tdat, kfo: kfo});
  }
  dd_init() {
    var self = this;
    var fdat = format(this.state.today, "yyyy-MM-dd");
    var dat = addDays(this.state.today, 15);
    var tdat = format(dat, "yyyy-MM-dd");

    var prm = {};
    prm.req = "pms.rs.rs_per_dt";
    prm.fdat = fdat;
    prm.tdat = tdat;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        var rsa = ret.rco.rsa;
        var kfo = self.state.kfo;
        kfo.restname = rsa[0].restname;

        self.setState({rsa: rsa, kfo: kfo, dta: ret.rco.rca, loading: false });
    })
    .catch(function(e) {
        var txt = JSON.stringify(e);
    });
  }
  bel_kf() {
    var self = this;
    var dat = parseISO(this.state.fdat);
    var fdat = format(dat, "yyyy-MM-dd");
    dat = addDays(dat, 15);
    var tdat = format(dat, "yyyy-MM-dd");

    var prm = {};
    prm.req = "pms.rs.rs_per_dt";
    prm.fdat = fdat;
    prm.tdat = tdat;
    //prm.avd = this.state.avd;

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        self.setState({rsa: ret.rco.rsa, dta: ret.rco.rca, loading: false });
        //self.syncCols("rvpTable");
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e) {
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }
  rs() {
    var self = this;
    var prm = {};
    prm.req = "pms.kf.rsa";
    //prm.datum = this.state.datum;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            return;
        }
        self.setState({rsa: ret.rca});
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  pkats() {
    var self = this;
    var prm = {};
    prm.req = "pms.pkat.qa_pkats";
    //prm.datum = this.state.datum;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            return;
        }
        self.setState({pka: ret.rca, pks: ret.rco});
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

  kf_add() {
    var self = this;

    if(this.state.kfo.restname == "") {
        alert("Lokal saknas");
        return;
    }

    var prm = {};
    prm.req = "pms.kf.kf_add";
    prm.boknr = this.state.boknr;
    prm.fdat = this.state.fdat;
    prm.tdat = this.state.tdat;
    prm.rst00 = this.state.kfo;
    //alert(JSON.stringify(prm));
    //return;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok == "000") {
		    //self.props.cb(self.props.ctx, ret);
            if(self.props.cb) {
                self.props.cb(self.props.ctx, ret);
            }
		    else self.props.ctx.setState({ wnds: {} });
            return;
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "Delbokning";
    //if(props.kro) alert(props.kro.krr00.kid);
    const popover = {
      position: 'absolute',
      top: '50px',
      zIndex: '2',
    }
    const cover = {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    }

	var html =
        <div className="mx_base">
        <div className="m2_backdrop" />
        <div className="m2_dlg mx_100 flex-col" style={ this.dlg_style() } draggable="true" >

        <div className="mx_h01">
            <div>NY LOKAL BOKNING</div>
        </div>
        <div className="mx_content px-2 flex">

            <div className="" style={{border: "0px", margin: "0px", padding: "0px"}}>
                <div style={ this.bel_style() }>
            <table className="table table-bordered tb_pres">
                <thead>
                <tr>
                    <th style={{textAlign: "center"}}>Text</th>
                    <th style={{textAlign: "center"}}>Plats</th>
                    { this.state.dta.map((dto, key) =>
                        <th key={key}>{ dto.dt }<br />{ dto.day }</th>
                    )}
                </tr>
                </thead>
                <tbody>
                { this.state.rsa.map((row, key) =>
                <tr key={key} >
                    <td className='header' onClick={ () => this.selrt(row) }>{ row.restname }</td>
                    <td>{ row.platser }</td>
                    { this.state.dta.map((dto, key) =>
                        <td key={key}>{ this.dt_kf(row, dto) }</td>
                    )}
                </tr>
                    )}
                </tbody>
            </table>
                </div>
            </div>
            <div className="divider_orange"></div>

            <form className="cdi_f03">
            <div className="flex-row py-2">
                <Fr_Cal label="Från" className="text-left w250" min="1" text={this.state.fdat} getValue={(e)=> this.val_fd_save(e) }/>
                <Fr_Cal label="Till" className="text-left w250" min="1" text={this.state.tdat} getValue={(e)=> this.val_td_save(e) }/>
            </div>
            <div className="flex-row py-2">
                    <Fr_Input label="Start" className="text-left w250" text={this.state.kfo.rtime} getValue={(e)=> this.val_save("rtime", e) } />
                    <Fr_Input label="Slut" className="text-left w250" text={this.state.kfo.sluttid} getValue={(e)=> this.val_save("sluttid", e) } />
            </div>
            <div className="flex-row py-2">
                    <Fr_Input label="Dag Start" className="text-left w250" text={this.state.kfo.astart} getValue={(e)=> this.val_save("astart", e) } />
                    <Fr_Input label="Dag Slut" className="text-left w250" text={this.state.kfo.aslut} getValue={(e)=> this.val_save("aslut", e) } />
            </div>
            <div className="flex-row py-2">
                    <Fr_Input label="Antal deltagare" className="text-left w250" text={this.state.kfo.noof} getValue={(e)=> this.val_save("noof", e) } />
            </div>
            <div className="divider_green"></div>
            <div className="flex-row py-2">
                <div className="form-group flex-row py-2">
                        <span className="header">Lokal</span>
                        <select value={this.state.kfo.restname} className="form-control w350" onChange={(e) => this.rs_set("restname", e)}>
                            { this.state.rsa.map((row, key) =>
                            <option key={key} value={row.restname}>{row.restname}</option>
                            )}
                        </select>
                </div>
            </div>
            <div className="flex-row py-2">
                    <Fr_Input label="Konferens typ" className="text-left w250" text={this.state.kfo.konferens} getValue={(e)=> this.val_save("konferens", e) } />
                    <Fr_Input label="Dukning" className="text-left w250" text={this.state.kfo.dukning} getValue={(e)=> this.val_save("dukning", e) } />
            </div>
            <div className="flex-row py-2">
                    <Fr_Input label="Pristyp" className="text-left w250" text={this.state.kfo.pristyp} getValue={(e)=> this.val_save("pristyp", e) } />
                    <Fr_Input label="Pris" className="text-left w250" text={this.state.kfo.pris} getValue={(e)=> this.val_save("pris", e) } />
            </div>
            </form>

        </div>

        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                AVBRYT
            </button>
            <div className="flex-row">
            <button className="btn btn-hw-red w250" onClick={() => this.kf_add() } >
                BOKA
            </button>
            </div>
        </div>
      </div>
      </div>
	;
	return html;
  };
}

export default Kf_Add;