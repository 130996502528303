import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../../lib/net";
import * as utils from "../../../assets/utils";
import Td_Sel from "../../../lib/ui/td_sel";
import Dt_Cal from "../../../lib/ui/dt_cal";
import {format} from "date-fns";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Reg_RoomEdit extends React.Component {
  constructor(props, context) {
    super(props);


      this.dt_fd = React.createRef();
      this.dt_td = React.createRef();
      this.store = props.store;
    this.pms = props.store.pms;

    this.state = {};
    this.state.cols = {};
    this.state.wnds = {};
    this.state.lrwrk = false;
      this.state.ix = 0;
      this.state.rumsnr = "";
      this.state.rt = "";
      this.state.rta = [];
      this.state.rro = {};
    if(props.wnds.rro) {
        this.state.rro = props.wnds.rro;
        this.state.rta = props.wnds.rta;
        this.state.rumsnr = props.wnds.rro.rumsnr;

        this.state.today = new Date();
        this.state.fdat = format(this.state.today, "yyyy-MM-dd");
        this.state.tdat = format(this.state.today, "yyyy-MM-dd");
    }
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      //this.rr();
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }

    wnd_close = () => {
		this.props.ctx.setState({ wnds: {} });
	};
    tb_style() {
        var css = {};
        css.height = window.innerHeight - 275;
        css.overflow = "auto";
        return css;
    }

    change_rt(e) {
        //alert(JSON.stringify(e));
        var rt = e.target.value;
        this.setState({ rt: rt });
    }
    dt_fdat(e) {
        //alert(JSON.stringify(e));
        var dts = format(e, "yyyy-MM-dd");
        this.setState({ fdat: dts });
    }
    dt_tdat(e) {
        //alert(JSON.stringify(e));
        var dts = format(e, "yyyy-MM-dd");
        this.setState({ tdat: dts });
    }
    oneday(e) {
        //alert(JSON.stringify(e));
        //this.setState({ tdat: this.state.fdat });
        this.dt_td.current.updateDt(this.state.fdat);
    }
    addday(e) {
        //alert(JSON.stringify(e));
        //this.setState({ tdat: this.state.fdat });
        this.dt_fd.current.updateAdd();
        this.dt_td.current.updateAdd();
    }
    subday(e) {
        //alert(JSON.stringify(e));
        //this.setState({ tdat: this.state.fdat });
        this.dt_fd.current.updateSub();
        this.dt_td.current.updateSub();
    }
  rr() {
    var self = this;
    var prm = {};
    prm.req = "pms.rr.rrq";
    prm.datum = this.state.datum;
    //prm.avd = this.state.avd;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        var rra = [];
        var keys = Object.keys(ret.rco);
        for(var key of keys) {
            var rro = ret.rco[key];
            //alert(JSON.stringify(key));

            rra.push( rro );
        };
        self.setState({ rra: rra, loading: false });
        self.syncTb("roomsTable");
    })
    .catch(function(e) {
        var txt = JSON.stringify(e);
    });
  }
    rt_change() {
        var self = this;

        if(this.state.rt.length < 1) {
            alert(JSON.stringify("INGEN RUMSTYP VALD."));
            return;
        }
        if(this.state.rro.rt == this.state.rt) {
            alert(JSON.stringify("EJ SAMMA RUMSTYP."));
            return;
        }

        var prm = {};
        prm.req = "pms.rt.rt_change";
        prm.fdat = this.state.fdat;
        prm.tdat = this.state.tdat;
        prm.rumsnr = this.state.rro.rumsnr;
        prm.fromrt = this.state.rro.rt;
        prm.tort = this.state.rt;

        net.sio_req(prm)
            .then(function(ret){
                alert(JSON.stringify(ret));

                //self.setState({rta: ret.rco });
            })
            .catch(function(e) {
                var txt = JSON.stringify(e);
            });
    }

  render() {
    //if(!this.props.show) return "";
    var title = this.props.title;
    if(!title) title = "Rum";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="mx_backdrop" /> : null }
        <div className="mx_dlg mx_50 flex-col" style={ this.dlg_style() }>
        <div className="mx_content flex">

            <div className="flex-row" style={{padding:"5px",height:"35px"}}>
                    <div className="flex-col flex">
                        <div>RUMSNR: { this.state.rumsnr }</div>
                    </div>
                    <div className="flex-col">
                        <div>UPPDATERA</div>
                    </div>
            </div>
            <div className="divider_orange"></div>
            <div className="flex-row">
                <div className="dt-btn-01" onClick={() => this.subday() } ><FontAwesomeIcon icon="minus" size="1x" /></div>
                <Dt_Cal dts={ this.state.fdat } rp="1" ref={this.dt_fd} getValue={(e)=> this.dt_fdat(e)} />
                <div className="dt-btn-01" onClick={() => this.oneday() } >1</div>
                <Dt_Cal dts={ this.state.tdat } rp="1" ref={this.dt_td} getValue={(e)=> this.dt_tdat(e)} />
                <div className="dt-btn-01" onClick={() => this.addday() } ><FontAwesomeIcon icon="plus" size="1x" /></div>
            </div>

            <div className="divider_orange"></div>
            <div className="flex-row px-3">
                <div className="flex-col flex">
                    <div>RUMSNR: { this.state.rro.rumsnr }</div>
                    <div>RUMSTYP: { this.state.rro.rt }</div>
                    <div>TILLGÄNLIGT: { this.state.rro.r1tillgr }</div>
                </div>
                <div className="flex-col">
                    <div></div>
                    <div className="form-group flex-col">
                        <span className="header">Rumstyp</span>
                        <select value={this.state.rt} className="form-control text-left w350" onChange={(e) => this.change_rt(e)}>
                            { this.pms.rta.map((row, key) =>
                                <option key={key} value={row.r1typ}>{ row.r1typ }</option>
                            )}
                        </select>
                    </div>
                </div>
            </div>
        </div>

        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex-row">
            </div>
            <button className="btn btn-hw-green w250" onClick={(event) => this.rt_change() } >
                BYT RUMSTYP
            </button>
        </div>

      </div>
    </div>
	;
	return html;
  };
}

export default Reg_RoomEdit;