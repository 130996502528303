import React, { useState, useEffect, useContext } from 'react';
import {format} from "date-fns";

import * as net from "../../lib/net";
import * as utils from "../../assets/utils";
import Dt_Cal from "../../lib/ui/dt_cal";
import Td_Sel from "../../lib/ui/td_sel";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Fo_Kund extends React.Component {
    constructor(props, context) {
        super(props);

        this.dt_fd = React.createRef();
        this.dt_td = React.createRef();

        this.store = props.store;

        this.state = {};
        this.state.seldate = new Date();
        this.state.avd = "01";
        this.state.kaa = [];
        this.state.aa = [];
        this.state.fao = {};
        this.state.tot = {};

        this.state.today = new Date();
        var dts = format(this.state.today, "yyyy-MM-dd");
        this.state.fdat = format(this.state.today, "yyyy-MM-01");
        this.state.tdat = dts;
        this.state.sortid = "";
        this.state.msg = {};
        this.state.wnds = {};
        this.selitems = [
            {fkn: 'edit', text: 'ÖPPNA'},
            {fkn: 'remove', text: 'TABORT'}
        ];
    }
    componentDidUpdate() {
    }
    componentWillUnmount() {
    }
    componentDidMount() {
        this.unds();
    }

    body_style() {
        var css = {};
        css.height = window.innerHeight - 280;
        return css;
    }
    tb_style() {
        var css = {};
        css.height = window.innerHeight - 275;
        css.overflow = "auto";
        return css;
    }
    th_style(id) {
        var css = {};
        css.cursor = "pointer";
        if(id == this.state.sortid) css.color = "#080";
        return css;
    }
    onUnitChange(e) {
        this.setState({unit: e.value});
    }
    dt_fdat(e) {
        //alert(JSON.stringify(e));
        var dts = format(e, "yyyy-MM-dd");
        this.setState({ fdat: dts });
    }
    dt_tdat(e) {
        //alert(JSON.stringify(e));
        var dts = format(e, "yyyy-MM-dd");
        this.setState({ tdat: dts });
    }
    oneday(e) {
        //alert(JSON.stringify(e));
        //this.setState({ tdat: this.state.fdat });
        this.dt_td.current.updateDt(this.state.fdat);
    }
    addday(e) {
        //alert(JSON.stringify(e));
        //this.setState({ tdat: this.state.fdat });
        this.dt_fd.current.updateAdd();
        this.dt_td.current.updateAdd();
    }
    subday(e) {
        //alert(JSON.stringify(e));
        //this.setState({ tdat: this.state.fdat });
        this.dt_fd.current.updateSub();
        this.dt_td.current.updateSub();
    }

    fak_open(row) {
        var self = this;
    }
    fak_edit(row) {
        var self = this;
        alert(JSON.stringify(row.plu00));
    }

    unds() {
        var self = this;
        var prm = {};
        prm.req = "fakt.fakt_pos.underlag_kund";
        prm.datum = this.state.fdat;
        prm.fdat = this.state.fdat;
        prm.tdat = this.state.tdat;
        prm.avd = this.state.avd;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.rca));

                var tot = {};
                tot.antal = 0;
                tot.belopp = 0;
                for(var row of ret.rca) {
                    //alert(JSON.stringify(row.belopp));
                    tot.antal = tot.antal + 1;
                    if(row.belopp && (row.belopp != null)) {
                        var belopp = row.belopp.toString();
                        if(belopp.indexOf("-") != -1) {
                            belopp = belopp.replace("-", "");
                            //alert(JSON.stringify(belopp))
                        }
                        tot.belopp = tot.belopp + (belopp * 1);
                    }
                    //alert(JSON.stringify(row.belopp));
                }
                //alert(JSON.stringify(tot.belopp));
                self.setState({ kaa: ret.rca, tot: tot }, function () {
                    self.syncTb("undTable");
                });
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
                //gda.wndError(txt);
            });
    }
    syncTb(tb){
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
            tdElements = tbel.getElementsByTagName('td');

        for (let i = 0; i < thElements.length; i++) {
            if(!tdElements[i]) continue;
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
                width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
    tb_sort(typ) {
        var self = this;
        var poa = self.state.poa;
        var paa = self.state.paa;
        var sz = this.state.sz_val;

        if(typ == "plu") {
            //paa.sort((a,b) => (a.plu00.p0katnr > b.plu00.p0katnr) ? 1 : ((b.plu00.p0katnr > a.plu00.p0katnr) ? -1 : 0));
        }
        self.setState({paa: paa, sortid: typ });
    }
    kundtext(row) {
        var text = "";
        if(row.krr && row.krr.krr00) text = row.krr.krr00.company;
        return text;
    }

    render() {
        return (
            <div className="web-app">

                <div className="flex-row">
                    <div className="flex-row">
                        <div className="dt-btn-01" onClick={() => this.subday() } ><FontAwesomeIcon icon="minus" size="1x" /></div>
                        <Dt_Cal dts={this.state.fdat} rp="1" ref={this.dt_fd} getValue={(e)=> this.dt_fdat(e)} />
                        <div className="dt-btn-01" onClick={() => this.oneday() } >1</div>
                        <Dt_Cal dts={this.state.tdat} rp="1" ref={this.dt_td} getValue={(e)=> this.dt_tdat(e)} />
                        <div className="dt-btn-01" onClick={() => this.addday() } ><FontAwesomeIcon icon="plus" size="1x" /></div>
                    </div>
                    <select className="form-control sel_input" placeholder="Välj avdelning">
                        { this.state.aa.map((row, key) =>
                            <option value={row.id}>{row.text}</option>
                        )}
                    </select>
                    <button className="btn btn-hw-blue w150" onClick={() => this.unds() } aria-label="System" >
                        Visa
                    </button>
                </div>
                <div className="divider"></div>

                <div id="undTable" className="flex">
                    <table className="table table-bordered table-hover tb " style={{marginBottom: "0px"}}>
                        <thead>
                        <tr>
                            <th style={ this.th_style("kundnr") } onClick={() => this.tb_sort("kundnr") }>Kund</th>
                            <th style={ this.th_style("text") } onClick={() => this.tb_sort("text") }>Text</th>
                            <th style={ this.th_style("antal") } onClick={() => this.tb_sort("antal") }>Antal</th>
                            <th style={ this.th_style("belopp") } onClick={() => this.tb_sort("belopp") }>Belopp</th>
                            <th style={ this.th_style("status") } onClick={() => this.tb_sort("status") }>Status</th>
                            <th></th>
                        </tr>
                        </thead>
                    </table>
                    <div style={ this.tb_style() }>
                        <table className="table table-bordered table-hover tb" style={{marginBottom:"0px"}}>
                            <tbody>
                            { this.state.kaa.map((row, key) =>
                                <tr key={key} onDoubleClick={() => this.fak_edit(row) }>
                                    <td>{ utils.toNum( row.kundnr ) }</td>
                                    <td>{ this.kundtext( row ) }</td>
                                    <td>{ utils.toNum( row.antal ) }</td>
                                    <td>{ utils.toPris( row.belopp ) }</td>
                                    <td>{ row.status }</td>
                                    <td>
                                        <Td_Sel items={ this.selitems } getValue={(e)=> this.sel_row_fkn(row, e)} />
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="flex-row flex-space-between">
                    <div className="flex-col">
                        <div className="cdi-label">Antal</div>
                        <div className="">{ utils.toNum( this.state.tot.antal ) }</div>
                    </div>
                    <div className="flex-col">
                    </div>
                    <div className="flex-col">
                        <div className="cdi-label">Summa</div>
                        <div className="">{ utils.toPris( this.state.tot.belopp ) }</div>
                    </div>
                </div>

            </div>
        );
    }
}
export default Fo_Kund;
