import React, { useState, useEffect, useRef, useContext } from 'react';
import { sv } from 'date-fns/locale'

import './css/fm_input.css';

class Fr_Select extends React.Component {
  constructor(props, context) {
    super(props);

    this.node = React.createRef();
    //this.setNodeRef = this.setNodeRef.bind(this);

    this.state = {};
    this.state.items = [];
    this.state.lso = {};
    //this.state.lro = props.lro;
    this.state.cols = {};
    this.state.sel_show = false;
    this.state.sel_step = 0;

    //context.posrend = this;
    this.state.today = new Date();
    this.state.ctrltext = props.text;
    this.state.txtval = "";
    this.state.txtsel = 0;
    if(props.text) this.state.txtval = props.text;
    this.state.row = 0;
    if(props.row) this.state.row = 1;
    this.state.placeholder = "";
    if(props.placeholder) this.state.placeholder = props.placeholder;
    if(props.items) this.state.items = props.items;
    this.state.flex = 0;
    if(props.flex) this.state.flex = 1;
    this.state.idval = "id";
    if(props.idval) this.state.idval = props.idval;

    this.txtsel = 0;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
    //document.removeEventListener("mousedown", this.handleClick);
  }
  componentDidMount() {
    //document.addEventListener("mousedown", this.handleClick);
    this.setState({ txtsel: false })
  }

  cls() {
    var cls = "";
    cls = "ff_ctrl";
    if(this.state.flex) cls = cls + " flex";
    //if(this.props.className) cls = cls + " " + this.props.className;
    return cls;
  }
    clsGroup() {
        var cls = "";
        cls = "form-group";
        if(!this.props.col) cls = cls + " flex-row";
        if(this.props.col) cls = cls + " flex-column";
        cls = cls + " flex";
        return cls;
    }
    clsLabel() {
        var cls = "";
        cls = "header";
        if(this.props.noheader) cls = "no_label";
        return cls;
    }

  clsFrm() {
    var cls = "";
    cls = "";
    if(this.props.row) cls = "flex-row";
    return cls;
  }

  clsInput() {
    var cls = "";
    cls = "form-control";
    if(this.props.className) cls = cls + " " + this.props.className;
    return cls;
  }

    showsel(e) {
      e.preventDefault();

      var brc = this.state.sel_show;
      brc = !brc;
      //var val = this.props.text;
      //this.setState({sel_show: brc, sel_step: 0, txtval: val});
      //this.setState({txtsel: true});
   }
    onFocus(e) {
      e.preventDefault();

      this.setState({txtsel: 1});
    }
    onBlur(e) {
      e.preventDefault();

      this.setState({txtsel: 0});
    }

    changeHandler (e) {
      e.preventDefault();
      const value = e.target.value;
      //alert("CHANGE: " + JSON.stringify(value));
      this.setState({ txtval: value });
      if(this.props.getValue) {
          this.props.getValue(value);
      }
    }

  inputStyle() {
      var css = {};
      if(this.state.cal_show) {
        css.background = "#080";
        css.color = "#fff";
      }
      return css;
  }
  label_style() {
    var css = {};

    if(this.state.row) {
        css.width = "75px";
        css.fontSize = "14px";
        css.background = "#fff";
        css.color = "#800";
    }

    return css;
  }

  render() {
    //if(!this.props.show) return "";
    //alert(this.props.ctx.state.lro);

    var title = this.props.title;
    if(!title) title = "URVAL";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div ref={this.node} className={ this.cls() }>
        <div className={ this.clsGroup() }>
            <span className={ this.clsLabel() }>{ this.props.label }</span>
            <select value={this.props.text} className={ this.clsInput() } onChange={(e) => this.changeHandler(e)}>
                { this.state.items.map((row, key) =>
                    <option key={key} value={row[this.state.idval]}>{row[this.state.idval] } - { row.text}</option>
                )}
            </select>
        </div>
        </div>
	;
	return html;
  };
}

export default Fr_Select;
