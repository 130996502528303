import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../../lib/net";
import * as utils from "../../../assets/utils";

import Fr_Input from "../../../lib/ui/fr_input";
import Dd_Sel from "../../../lib/ui/dd_sel";
import Wnd_Error from "../../../lib/ui/wnd_error";
import Wnd_Ok from "../../../lib/ui/wnd_ok";
import Wnd_Info from "../../../lib/ui/wnd_info";
import Wnd_Confirm from "../../../lib/ui/wnd_confirm";
import Fr_Cal from "../../../lib/ui/fr_cal";
import Sz_Kid from "./sz_kid";
import Tev_Add from "./task_event_add";

class Complete_open extends React.Component {
  constructor(props, context) {
    super(props);

      this.selitems = [
          {fkn: 'del', text: 'TA BORT'},
          {fkn: 'close', text: 'STÄNG'}
      ];

    this.bcs = props.store.bcs;
    this.state = {};
    this.state.mka = [];
    this.state.sval = [];
    this.state.tval = [];
    this.state.paa = [];
    this.state.uaa = [];
    this.state.laa = [];

    this.state.gnr = "001";
    //this.state.lro = props.lro;
    this.state.ynq = [
                {id: '0', text: 'NEJ'},
                {id: '1', text: 'JA'}
    ];
      this.state.sval = [
          {id: '0', text: 'REGISTRERAD'},
          {id: '1', text: 'BEARBETAS'},
          {id: '5', text: 'KLAR'},
          {id: '8', text: 'FAKTURERAD'},
          {id: '9', text: 'AVSLUTAD'}
      ];
      this.state.preg = [
          {id: '00000', text: 'EJ TILLDELAD'},
          {id: '00001', text: 'Joakim'},
          {id: '00002', text: 'Daniel'},
          {id: '00003', text: 'Stellan'},
          {id: '00004', text: 'Tobias'},
          {id: '00005', text: 'Emmanuel'},
          {id: '00006', text: 'Kari'}
      ];
      this.state.sreg = [
          {id: 'pub', text: 'ALLMÄNT'},
          {id: 'pos', text: 'KASSASYSTEM'},
          {id: 'pms', text: 'HOTELLSYSTEM'},
          {id: 'ta', text: 'TIDSYSTEM'},
          {id: 'fakt', text: 'FAKTURASYSTEM'},
          {id: 'net', text: 'NÄTVERK'}
      ];
      this.state.hla = [
          { id: '1', text: 'Txt 1', tid: '2005' },
          { id: '2', text: 'Txt 2', tid: '2005' }
      ];

      this.state.eva = [];
      this.state.msg = {};
      this.state.wnds = {};
      this.state.cols = {};
    this.state.tdo = {};
    this.state.ddat = "";
    this.state.dtime = "";
    if(props.wnds.tdo) {
        this.state.tdo = props.wnds.tdo;
        this.state.ddat = props.wnds.tdo.due.substr(0,8);
        this.state.dtime = props.wnds.tdo.due.substr(8,4);
        this.state.ddat = this.state.ddat.cdidate();
        this.state.dtime = this.state.dtime.cditid();
    }
      this.state.tabval = 0;
      this.state.ktxt = "";
      if(this.state.tdo.kid) {
          var key = "K" + this.state.tdo.kid;
          if(this.bcs.kro[key]) {
            this.state.ktxt = this.bcs.kro[key].kk00.ftg;
          }
      }
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.btn_grps();
      if(!this.props.wnds.tdo) this.task_init();
  }

    sel_fkn(sel) {
        //alert(JSON.stringify(fkn));
        if(sel.fkn == "close") {
            this.wnd_close();
        }
        if(sel.fkn == "del") {
            this.task_del();
        }
    }

    task_init() {
        var tdo = {};
        tdo.typ = "";
        tdo.id = "";
        tdo.text = "";
        tdo.reg = "";
        tdo.status = "";
        //alert(JSON.stringify(rso));
        this.setState({ tdo: tdo });
        return;
    }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    tb_style() {
        var css = {};
        css.height = window.innerHeight - 275;
        return css;
    }
    tab_style(tabid) {
        var css = {};
        css.display = "none";
        if(this.state.tabval === tabid) css.display = "block";
        return css;
    }
    stat_style(sval) {
        var css = {};
        var status = this.state.tdo.status.toString().tonum(1);
        if(sval == status) {
            css.background = "#080";
            css.color = "#fff";
        }
        return css;
    }
    tab_head(tabid) {
        var css = {};
        css.background = "#fff";
        css.color = "#000";
        if(this.state.tabval === tabid) {
            css.background = "#080";
            css.color = "#fff";
        }
        return css;
    }

    wnd_close = () => {
        if(this.props.close) {
            this.props.close(this.props.ctx);
        }
        else this.props.ctx.setState({ wnds: {} });
    };
    preg_change (e) {
        const value = e.target.value;
        var tdo = this.state.tdo;
        tdo.pid = value;
        this.setState({tdo: tdo});
    }
    sreg_change (e) {
        const value = e.target.value;
        var tdo = this.state.tdo;
        tdo.sys = value;
        this.setState({tdo: tdo});
    }
    sval_change (e) {
        const value = e.target.value;
        var tdo = this.state.tdo;
        tdo.status = value;
        this.setState({tdo: tdo});
    }
    tdata_change (e) {
        const value = e.target.value;
        var tdo = this.state.tdo;
        tdo.tdata = value;
        this.setState({tdo: tdo});
    }

    pr_txt(pid) {
        var text = "";
        pid = pid.toString().tonum(5);
        if( pid == '00000' )  text = 'EJ TILLDELAD';
        if( pid == '00001' )  text = 'Joakim';
        if( pid == '00002' )  text = 'Daniel';
        if( pid == '00003' )  text = 'Stellan';
        if( pid == '00004' )  text = 'Tobias';
        if( pid == '00005' )  text = 'Emmanuel';
        if( pid == '00006' )  text = 'Kari';
        return text;
    }

    val_ddat_save(val) {
        //var tdo = this.state.tdo;
        //tdo.due = val;
        this.setState({ ddat: val });
        return;
    }
    val_dtime_save(val) {
        //var tdo = this.state.tdo;
        //tdo.due = val;
        this.setState({ dtime: val });
        return;
    }
    val_dt_save(val) {
        var tdo = this.state.tdo;
        tdo.due = val;
        this.setState({ tdo: tdo });
        return;
    }
    val_save(id, val) {
        var tdo = this.state.tdo;
        tdo[id] = val;
        this.setState({ tdo: tdo });
        return;
    }
  task_save() {
    var self = this;
      var tdo = this.state.tdo;
      var ts = this.state.ddat + this.state.dtime;
      ts = ts.replaceAll("-", "");
      ts = ts.replaceAll(".", "");
      ts = ts.replaceAll(":", "");
      tdo.due = ts;
    var prm = {};
    prm.req = "bcs.wrk.task_upd";
    prm.tdo = tdo;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));
        if(ret.ok == "000") {
            self.setState({ msg: {ok: true, text: "Sparad ..."} });
            setTimeout(function(){
                self.setState({msg: {} });
                  if(self.props.wnds.cb) {
                      self.props.wnds.cb(self.props.ctx);
                  }
                  else self.props.ctx.setState({ wnds: {} });
                return;
            }, 1000);
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  task_del() {
    var self = this;
    var prm = {};
    prm.req = "bcs.wrk.task_del";
    prm.tdo = this.state.tdo;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));
        if(ret.ok == "000") {
            self.setState({ msg: {ok: true, text: "Borttagen"} });
            setTimeout(function(){
                self.setState({msg: {} });
                  if(self.props.close) {
                      self.props.close(self.props.ctx);
                  }
                  else self.props.ctx.setState({ wnds: {} });
                return;
            }, 1000);
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
    task_events() {
        var self = this;
        var prm = {};
        prm.req = "bcs.wrk.task_events";
        prm.taskid = this.state.tdo.id;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret));
                if(ret.ok == "000") {
                    self.setState({ eva: ret.rca });
                }
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    sz_kid() {
        //alert(JSON.stringify(this.bcs.kro));
        var self = this;
        var wnds = {};
        wnds.szkid = true;
        wnds.tdo = this.state.tdo;
        wnds.cb = self.cb_kid;
        this.setState({ wnds: wnds });
    }
    cb_kid(ctx, rsp) {
        //alert(JSON.stringify(rsp));
        if(rsp.ok == "000") {
            var kko = rsp.kko;
            var tdo = ctx.state.tdo;
            tdo.kid = kko.kk00.kid;
            var key = "K" + tdo.kid;
            var ktxt = "";
            if(ctx.bcs.kro[key]) {
                ktxt = ctx.bcs.kro[key].kk00.ftg;
            }
            ctx.setState({ wnds: {}, tdo: tdo, ktxt: ktxt });
            return;
        }
        ctx.setState({ wnds: {} });
        //ctx.tasks();
        //if(ctx.state.tdo.kid) ctx.state.ktxt = ctx.bcs.kro[this.state.tdo.kid];
    }
    tev_add() {
        var self = this;
        var wnds = {};
        wnds.tevadd = true;
        wnds.taskid = this.state.tdo.id;
        wnds.cb = self.cb_tev;
        this.setState({ wnds: wnds });
    }
    cb_tev(ctx) {
        ctx.setState({ wnds: {} });
        //ctx.tasks();
    }
    set_status(status) {
        var tdo = this.state.tdo;
        tdo.status = status;
        this.setState({tdo: tdo});
    }
    set_tab(tabid) {
        this.setState({tabval: tabid});
        if(tabid == 1) {
            this.task_events();
        }
    }
    stat_txt(status) {
        var text = "";
        status = status.toString().tonum(1);
        if( status == '0' )  text = 'REGISTRERAD';
        if( status == '1' )  text = 'BEARBETAS';
        if( status == '3' )  text = 'VÄNTAR';
        if( status == '5' )  text = 'KLAR';
        if( status == '7' )  text = 'FAKTURERAD';
        if( status == '9' )  text = 'AVSLUTAD';
        return text;
    }
  render() {
    //if(!this.props.show) return "";
    var title = this.props.title;
    if(!title) title = "Resurs";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        <div className="mx_backdrop" />
        <div className="mx_dlg mx_90 flex-col" style={ this.dlg_style() } >
        <div className="mx_content flex">

        <div className="mx_h01">
            <div>SLUTFÖR ÄRENDE : { utils.toNum( this.state.tdo.id ) }</div>
            <div className="flex"></div>
            <div className="">{ this.stat_txt( this.state.tdo.status ) }</div>
            <div className="flex"></div>
            <div className="">
                <Dd_Sel items={ this.selitems } cls="py-1" getValue={(e)=> this.sel_fkn(e)} />
            </div>
            <div onClick={() => this.wnd_close() }>X</div>
        </div>

            <div className="flex-row">
                <div className="tab_header flex" style={ this.tab_head(0) } onClick={ () => this.set_tab(0) }>INFORMATION</div>
                <div className="tab_header flex" style={ this.tab_head(1) } onClick={ () => this.set_tab(1) }>HÄNDELSER</div>
            </div>
            <div className="divider_orange"></div>

            <div style={ this.tab_style(0) }>
            <form className="cdi_f03">
                <div className="txt_box txt_bg_01">
                    <div className="flex-row">
                        <div className="flex-col">
                        <div className="txt_14">Registrerad: { utils.toTsDate(this.state.tdo.reg)}</div>
                        <div className="txt_14">Start: { utils.toTsDate(this.state.tdo.start)}</div>
                        <div className="txt_14">Slut: { utils.toTsDate(this.state.tdo.slut)}</div>
                        </div>
                        <div className="flex"></div>
                        <div className="flex-col">
                            <div className="flex-row">
                                <div className="btn_sel" style={ this.stat_style("0") } onClick={() => this.set_status("0") }>REGISTRERAD</div>
                                <div className="btn_sel" style={ this.stat_style("1") } onClick={() => this.set_status("1") }>BEARBETAS</div>
                            </div>
                            <div className="flex-row">
                                <div className="btn_sel" style={ this.stat_style("3") } onClick={() => this.set_status("3") }>VÄNTAR</div>
                                <div className="btn_sel" style={ this.stat_style("5") } onClick={() => this.set_status("5") }>KLAR</div>
                            </div>
                            <div className="flex-row">
                                <div className="btn_sel" style={ this.stat_style("7") } onClick={() => this.set_status("7") }>FAKTURERAD</div>
                                <div className="btn_sel" style={ this.stat_style("9") } onClick={() => this.set_status("9") }>AVSLUTAD</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="divider_green"></div>

                <div className="txt_box">
                <div className="flex-row">
                    <div className="txt_18">KUND: { utils.toNum( this.state.tdo.kid )} - { utils.toLang( this.state.ktxt )}</div>
                    <div className="flex"></div>
                    <div className="text-right">
                        <div className="web-link txt_14" onClick={() => this.sz_kid() }>KOPPLA KUND</div>
                    </div>
                </div>
                <div className="flex-row">
                    <div className="flex text-center">
                        <div className="txt_18">&nbsp;</div>
                    </div>
                    <div className="flex text-right">
                    </div>
                </div>
                <div className="flex-row">
                    <Fr_Input label="Text" className="text-left w350" text={this.state.tdo.text} getValue={(e)=> this.val_save("text", e) } />
                </div>
                </div>

                <div className="divider_green"></div>
                <div className="flex-row">
                    <div className="form-group flex-row flex">
                        <span className="header">Tilldelad</span>
                        <select value={this.state.tdo.pid} className="form-control w250" onChange={(e) => this.preg_change(e)}>
                            { this.state.preg.map((row, key) =>
                                <option key={key} value={row.id}>{row.text}</option>
                            )}
                        </select>
                    </div>
                    <div className="form-group flex-row flex">
                        <span className="header">System</span>
                        <select value={this.state.tdo.sys} className="form-control w250" onChange={(e) => this.sreg_change(e)}>
                            { this.state.sreg.map((row, key) =>
                                <option key={key} value={row.id}>{row.text}</option>
                            )}
                        </select>
                    </div>
                </div>
                <div className="flex-row">
                    <Fr_Cal label="Förväntat datum" className="text-left w200" text={this.state.ddat} getValue={(e)=> this.val_ddat_save( e ) }/>
                    <Fr_Input label="Förväntad tid" className="text-left w150" text={this.state.dtime} getValue={(e)=> this.val_dtime_save( e) } />
                </div>
                <div className="divider_green"></div>
                <div className="flex-row">
                    <div className="flex flex-column" style={{padding: "0px 5px"}}>
                        <span className="header">Ärende information</span>
                        <textarea className="form-control" maxLength="512" rows="10" value={this.state.tdo.tdata} onChange={(e) => this.tdata_change(e)}></textarea>
                    </div>
                </div>
            </form>
            </div>

            <div style={ this.tab_style(1) }>
                <div className="flex-row px-2">
                    <div>Händelse logg</div>
                    <div className="flex"></div>
                    <div className="web-link" onClick={() => this.task_events() }>Uppdatera</div>
                </div>
                <div className="divider_orange"></div>
                { this.state.eva.map((row, key) =>
                    <div className="flex-col px-2">
                        <div className="flex-row">
                            <div className="wnd_link px-2">{ this.pr_txt(row.pid) }</div>
                            <div className="wnd_link px-2">{ utils.toTsDate(row.ts) }</div>
                            <div className="flex"></div>
                        </div>
                        <div className="flex-row px-2">
                            <div>{ row.msg }</div>
                        </div>
                        <div className="divider_gray"></div>
                    </div>
                )}
            </div>

        </div>
        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250 ripple" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <button className="btn btn-hw-red w250 ripple" onClick={() => this.tev_add() } >
                NY HÄNDELSE
            </button>
            <div className="flex-row">
            <button className="btn btn-hw-red w250 ripple" onClick={() => this.task_save() } >
                SPARA
            </button>
            </div>
        </div>
      </div>

            { this.state.wnds.szkid ? <Sz_Kid wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
            { this.state.wnds.tevadd ? <Tev_Add wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
        <Wnd_Error msg={this.state.msg} ctx={this} />
        <Wnd_Ok msg={this.state.msg} ctx={this} />
        <Wnd_Info msg={this.state.msg} ctx={this} />
        <Wnd_Confirm msg={this.state.msg} ctx={this} />
      </div>
	;
	return html;
  };
}

export default Complete_open;

/*
                    <div className="flex-row">
                        <div className="form-group flex-row flex">
                            <span className="header">Status</span>
                            <select value={this.state.tdo.status} className="form-control w250" onChange={(e) => this.sval_change(e)}>
                                { this.state.sval.map((row, key) =>
                                    <option key={key} value={row.id}>{row.text}</option>
                                )}
                            </select>
                        </div>
                    </div>
*/