import React, { useState, useEffect, useContext } from 'react';
import {Route, Routes, Navigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {withRouter} from "../lib/react/withRouter";

import Dd_Sel from "../lib/ui/dd_sel";
import Tiden from "../lib/ui/tiden";
import sso from "../sso";
import * as net from "../lib/net";

import Bos_Top from "./com/bos_top";
import Bos_Mnu from "./com/bos_mnu";
import Bos_Foot from "./com/bos_foot";

import Bos_Home from "./home";
import Bos_Dash from "./dash";
import Bos_Kids from "./kid/kids";
import Bos_Sids from "./sid/sids";
import Bos_Udids from "./udid/udids";
import Bos_Users from "./wsa/usr/users";

import Bos_Wsa from "./wsa/home";
import Bos_Wsm from "./wsm/home";

function wsredirect(url) {

    //window.location.href = url;
    //return null;
}

class Bos extends React.Component {
  constructor(props, context) {
    super(props);

    this.store = props.store;
      //this.seo = props.store.seo;
      this.seo = sso.seo;

    this.state = {};
    this.state.user = {};
    this.state.loggedin = false;
    this.state.selitems = [
        {fkn: 'home', text: 'HEM'},
        {fkn: 'setup', text: 'INSTÄLLNINGAR'},
        {fkn: 'ses', text: 'SESSION'},
        {fkn: 'logout', text: 'LOGGA UT'}
    ];
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      if(this.seo && (this.seo.auth >= 90)) {
        this.setState({loggedin: true});
      }

      if(this.seo && (this.seo.auth < 90)) {
        var url = "/";
        this.props.navigate(url);
        //this.props.history.go(0);
      }
    }

    logo(){
        var logo = null;
        if(this.store.cfg.logo == "cdi") logo = require('../img/logo-cdi.png');
        else logo = require('../img/logo-sk.png');
        return logo;
    }
    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    handleLogin(event) {
        this.login_srv(this.state.user.uid, this.state.user.pwd);
    }
	handleClose = () => {
		//this.props.ctx.setState({ login: false });
	};
    login_srv(uid, pwd) {
        var self = this;
		var prm = {};
        prm.req = "usr:login";
		prm.uid = uid;
		prm.pwd = pwd;
		prm.token = "";

		//alert(JSON.stringify(uid));
        net.gcs_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret));

            if(ret.ok == "991") {
                var url = "/renew";
                self.props.navigate(url);
                //self.props.history.go(0);
                return;
            }
            if(ret.ok != "000") {
                alert(JSON.stringify(ret));
                return;
            }
            //alert(JSON.stringify(ret.seo));

            var seo = ret.seo;
            //alert(JSON.stringify(seo));
            if(!seo) {
                return;
            }
            self.props.store.seo = seo;
            var xdo = JSON.stringify(seo);
            //alert(JSON.stringify(xdo));
            sessionStorage.setItem("svp", xdo);
            var gdo = JSON.stringify(ret.gdo);
            //sessionStorage.setItem("gdo", gdo);

            if(seo.auth < 90) {
                var url = "/";
                self.props.navigate(url);
                //self.props.history.go(0);
            }
            self.setState({loggedin: true});
       })
        .catch(function(e){
            //var txt = JSON.stringify(e);
            alert("LOGIN ERR: " + JSON.stringify(e));
        });
    }

    verify() {
        var self = this;
        var xdo = sessionStorage.getItem("svp");
		var sdo = JSON.parse(xdo);

		var prm = {};
        prm.req = "ses:verify";
		prm.uid = sdo.uid;
		prm.token = sdo.token;

		//alert(JSON.stringify(prm));
        net.gcs_req(prm)
        .then(function(ret){
            alert(JSON.stringify(ret));

            if(ret.ok != "000") {
                //alert(JSON.stringify(ret));
                sessionStorage.removeItem("svp");
                self.props.store.seo = {};

                var url = "/sesout";
                self.props.navigate(url);
                //self.props.history.go(0);
                return;
            }

        })
        .catch(function(e){
            //var txt = JSON.stringify(e);
            var url = "/sesout";
            self.props.navigate(url);
            //self.props.history.go(0);
        });

      };
  sel_fkn(sel) {
    //alert(JSON.stringify(fkn));
      if(sel.fkn == "home") {
        var url = "/";
        this.props.navigate(url);
      }
      if(sel.fkn == "logout") {
        var url = "/logout";
        this.props.navigate(url);
      }
      if(sel.fkn == "sys") {
        var url = "/hello";
        this.props.navigate(url);
      }
  }
  abort_login() {
    var url = "/";
    this.props.navigate(url);
    //this.props.history.go(0);
  }
  sysStart() {
    var url = "/hello";
    this.props.navigate(url);
    //this.props.history.go(0);
  }

  render() {
    return (
    <div className="web-app">

    <div className="web-top">
        <Bos_Top store={this.store} />
    </div>

    <div className="web-main">

    <div className="web-row" style={{ height:"100%" }}>

    { this.props.store.cfg.mnu_show ?
    <div className="web-side">
        <Bos_Mnu store={this.store} />

    </div>
    : null }

    <div className="web-body">
          <Routes>
          <Route path="kid/*" element={ <Bos_Kids store={this.store} />} />
          <Route path="sid/*" element={ <Bos_Sids store={this.store} />} />
          <Route path="udid/*" element={ <Bos_Udids store={this.store} />} />
          <Route path="users/*" element={ <Bos_Users store={this.store} />} />
          
          <Route path="wsa/*" element={ <Bos_Wsa store={this.store} />} />
          <Route path="wsm/*" element={ <Bos_Wsm store={this.store} />} />
          <Route path="*" element={ <Bos_Home store={this.store} />} />
          </Routes>
    </div>
    
    </div>

    </div>

    <Bos_Foot store={this.store} />
    </div>
    );
    }
}
//Sys.contextType = ADOContext;

export default withRouter(Bos);

/*
    if(this.state.loggedin) {
        var html =
            <div className="web-app">

            <div className="web-xtop d-flex flex-row z-depth-1">
                <div className="top-txt">
                  <div className="txt_22">BACKOFFICE</div>
                </div>
                <div className="flex-fill"></div>
                <div className="web-top-usr d-flex flex-column">
                    <div><FontAwesomeIcon icon="building" size="1x"/> { this.seo.company }</div>
                    <div><FontAwesomeIcon icon="user" size="1x"/> { this.seo.name }</div>
                </div>
                <div className="">
                    <Dd_Sel items={ this.state.selitems } cls="web-top-icon" getValue={(e)=> this.sel_fkn(e)} />
                </div>
            </div>

        <div className="web-xbody d-flex flex-row">
    <div className="web-side">
        <Bos_Mnu store={this.store} />
    </div>
            <Routes>
                <Route path="kids/*" element={ <Bos_Kids store={this.store} />} />
                <Route path="home" element={ <Bos_Home store={this.store} />} />
                <Route path="*" element={ <Bos_Dash store={this.store} />} />
            </Routes>
        </div>

    <div className="web-foot">
        <div className="web-foot-mnu" onClick={() => this.br_menu() }>
            <FontAwesomeIcon icon="bars" size="1x"/>
        </div>

        <div className="flex flex-row">
            <div className="flex"></div>

            <div className="web-foot-btn" onClick={() => this.logout() } >LOGGA UR</div>
        </div>
        <div className="web-foot-icon">
          <FontAwesomeIcon icon="ellipsis-v" size="1x"/>
        </div>
        <div className="flex flex-row">
            <div className="web-foot-btn" onClick={() => this.sysStart() }>SYSTEM</div>

            <div className="flex"></div>
            <Tiden />
        </div>
    </div>
        </div>
        return html;
    }

    var html =
      <div className="web-app">
            <div className="web-xtop d-flex flex-row z-depth-1">
                <div className="top-txt">
                  <div className="txt_22">Sverigekassan Admin</div>
                </div>
                <div className="flex-fill"></div>
                <div className="web-top-usr d-flex flex-column">
                    <div><FontAwesomeIcon icon="user" size="1x"/> :{ this.seo.name }</div>
                    <div><FontAwesomeIcon icon="clock" size="1x"/> :{ this.seo.creat }</div>
                </div>
            </div>


    <div className="web-body">

          <div className="d-flex flex-column align-items-center justify-content-center" style={{ height: "100%" }}>
            <div className="p-md-4 bg-w01">
                <div className="">
                <div className="login__header text-center mb-lg-5 mb-4">
                    <h3 className="login__title mb-2">Backoffice Login</h3>
                    <p>Svepos Administration</p>
                </div>
                <div className="web-login">
                <div className="form-group">
                    <label className="">Användare</label>
                    <input type="text" name="uid" className="form-control" placeholder="" required="" autoFocus
                      value={this.state.uid} onChange={(event) => this.handleUserInput(event)}
                    />
                </div>
                <div className="form-group">
                    <label className="">Lösenord</label>
                    <input type="password" name="pwd" className="form-control input-style" placeholder="" required
                       value={this.state.pwd} onChange={(event) => this.handleUserInput(event)}
                    />
                </div>
                <div className="d-flex align-items-center justify-content-between">
                    <button onClick={(event) => this.handleLogin(event) } className="btn btn-hw-blue w150">
                        Rensa
                    </button>
                    <button onClick={(event) => this.handleLogin(event) } className="btn btn-hw-green w150">
                        Logga in
                    </button>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                    <p className="signup mt-4">
                        Hamnat fel? <span className="olink" onClick={() => this.abort_login() }>Avbryt Inloggning</span>
                    </p>
                </div>
                </div>
            </div>
        </div>
        </div>

            <div className="">
                <img className="svk-logo" src={ this.logo() } />
            </div>
        </div>

      </div>

*/
