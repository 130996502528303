import React, { useState, useEffect, useContext } from 'react';
import * as net from "../../../lib/net";

class Swish_Refund extends React.Component {
  constructor(props, context) {
    super(props);

   //this.webStore = props.store;
    this.state = {};
    this.state.ynq = [];
    this.state.paa = [];
    this.state.aa = [];

      this.state.refund_ok = 0;
      this.state.notanr = "";
      this.state.swo = {};
      this.state.wnds = {};
    if(props.wnds) {
        this.state.notanr = props.wnds.notanr;
        this.state.swo = props.wnds.swo;
    }
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
        if(this.state.refund_ok == 1) {
            css.background = "#080";
        }
        if(this.state.refund_ok == 9) {
            css.background = "#800";
        }
      return css;
    }


    wnd_close() {
        var self = this;
        var ret = {};
        ret.ok = "999";
        if(self.props.close) self.props.close(self.props.ctx, ret);
        else self.props.ctx.setState({ wnds: {} });
	};
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }

    swish_refund() {
    var self = this;

    var prm = {};
    prm.req = "vcm.swish.swish_refund";
    prm.notanr = this.state.notanr;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));
        if(ret.ok !== "000") {
            self.setState({ refund_ok: 9 });
        }
        else {
            self.setState({ refund_ok: 1 });
            setTimeout(function() {
                if(self.props.close) self.props.close(self.props.ctx, ret);
                else self.props.ctx.setState({ wnds: {} });
            }, 2000);
        }
    })
    .catch(function(e) {
        var txt = JSON.stringify(e);
    });
  }

  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "";

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="mx_backdrop" /> : null }
        <div className="m2_dlg mx_50 flex-col" style={ this.dlg_style() }>
            <div className="mx_h01 flex-row" style={{background: "#1a456b", color: "#fff"}}>
                    <div className="flex-col flex">
                        <div>ÅTERKÖP SWISHBETALNING</div>
                    </div>
                    <div className="flex-col">
                        <div>NOTANR: { this.state.notanr.ztrim() }</div>
                    </div>
            </div>
        <div className="mx_content flex">

            <div className="flex-col">
                <div>DATUM: { this.state.swo.datum.cdidate() }</div>
            </div>
            <div className="flex-col">
                <div>NOTANR: { this.state.swo.notanr.cdidate() }</div>
            </div>
            <div className="flex-col">
                <div>BELOPP: { this.state.swo.belopp.cdipris() }</div>
            </div>
            <div className="flex-col">
                <div>TELEFONNR: { this.state.swo.tele }</div>
            </div>
            <div className="flex"></div>
            <div className="divider_orange"></div>
        <div className="web-row">
        <div className="web-col flex">
            <div className="wnd-btn" onClick={() => this.wnd_close() } >
                <div>STÄNG</div>
                <div></div>
            </div>
        </div>
         <div className="web-col flex hide" style={{padding: "0px 10px"}}>
            <div className="wnd-btn" onClick={() => this.rr_clean("0") } >
                <div>XXX</div>
                <div></div>
            </div>
       </div>
         <div className="web-col flex" style={{padding: "0px 10px"}}>
            <div className="wnd-btn" onClick={() => this.swish_refund() } >
                <div>ÅTERKÖP</div>
                <div></div>
            </div>
       </div>
       </div>

        </div>
      </div>
    </div>
	;
	return html;
  };
}

export default Swish_Refund;