import React, { useState, useEffect, useContext } from 'react';
import {format} from "date-fns";

import * as net from "../../../lib/net";

import Dt_Cal from "../../../lib/ui/dt_cal";

import Rvs_Balans from "./saldo/rvs_balans";
import Saldo_Dt from "./saldo/saldo_dt";
import * as utils from "../../../assets/utils";
import Wnd_Error from "../../../lib/ui/wnd_error";
import Wnd_Ok from "../../../lib/ui/wnd_ok";
import Wnd_Info from "../../../lib/ui/wnd_info";
import Wnd_Confirm from "../../../lib/ui/wnd_confirm";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Eko_Rvs extends React.Component {
  constructor(props, context) {
    super(props);

    this.dt_fd = React.createRef();
    this.dt_td = React.createRef();

    this.webStore = props.store;
    this.state = { pgid: 11, gsa: [] };
    this.state.fdat = new Date();
    this.state.tdat = new Date();
    this.state.unit = null;
      this.state.msg = {};
      this.state.wnds = {};
    this.state.sortid = "";

    this.state.today = new Date();

    var dts = format(this.state.today, "yyyy-MM-dd");
    this.state.fdat = dts;
    this.state.tdat = dts;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.trans();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      css.overflow = "auto";
      return css;
  }
  th_style(id) {
      var css = {};
      css.cursor = "pointer";
      if(id == this.state.sortid) css.color = "#080";
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }
  dt_fdat(e) {
    //alert(JSON.stringify(e));
    var dts = format(e, "yyyy-MM-dd");
    this.setState({ fdat: dts });
  }
  dt_tdat(e) {
      //alert(JSON.stringify(e));
    var dts = format(e, "yyyy-MM-dd");
    this.setState({ tdat: dts });
  }
  oneday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_td.current.updateDt(this.state.fdat);
  }
  addday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_fd.current.updateAdd();
    this.dt_td.current.updateAdd();
  }
  subday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_fd.current.updateSub();
    this.dt_td.current.updateSub();
  }
  change_unit(e) {
    //let cdat = this.state.seldate;
    //alert(JSON.stringify(e));
    let unit = e.target.value;
    this.setState({unit: unit});
    this.setState({tra: [] });
  }
    rvs_balans() {
        var wnds = {rvsbal: true, datum: this.state.datum};
        this.setState({wnds: wnds});
    };
    saldo_dt() {
        var wnds = {saldo: true, datum: this.state.datum};
        this.setState({wnds: wnds});
    };

  saldo_rvs() {
    var self = this;

      var msg = { info: true, text: "Hämtar bokningar ..."};
      self.setState({ msg: msg });

    var prm = {};
    prm.req = "pms.eko_red.red_rvs";
    prm.fdat = this.state.fdat;
    prm.tdat = this.state.tdat;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            self.setState({ msg: {} });
            return;
        }
        var gsa = ret.sdo.gsa;
        self.setState({ gsa: gsa, msg: {} }, function () {
            self.syncTb("rvsTable");
        });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
    }

  val_pris(val) {
      var s = "";
      if(val) s = val.cdipris();
      return s;
  };
    syncTb(tb){
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
        tdElements = tbel.getElementsByTagName('td');

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
            width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
   tb_sort(typ) {
      var self = this;
      //var rvs = self.state.rvs;
      var gsa = self.state.gsa;
      var sz = this.state.sz_val;

      gsa.sort((a,b) => (a[typ] > b[typ]) ? 1 : ((b[typ] > a[typ]) ? -1 : 0));
      self.setState({ gsa: gsa, sortid: typ });
   }
  tb_filtr() {
      var self = this;
      var rvs = self.state.rvs;
      var sz = this.state.sz_val;

      if(sz.length < 1) {
        self.setState({ rva: rvs });
          return;
      }
      sz = sz.toUpperCase();
      var rva = rvs.filter(function(row) {
            var brc = false;
            if(row.company.toUpperCase().includes(sz)) brc = true;
            if(row.name.toUpperCase().includes(sz)) brc = true;
            return brc;
      });
      self.setState({rva: rva });
   }

  render() {
    return (
    <div className="web-app">
    <div className="">
        BOKNINGAR
    </div>

    <div className="flex-row flex-space-between">
        <div className="flex-row">
              <div className="dt-btn-01" onClick={() => this.subday() } ><FontAwesomeIcon icon="minus" size="1x" /></div>
              <Dt_Cal startdatum="2020-02-01" rp="1" ref={this.dt_fd} getValue={(e)=> this.dt_fdat(e)} />
              <div className="dt-btn-01" onClick={() => this.oneday() } >1</div>
              <Dt_Cal startdatum="2020-02-01" rp="1" ref={this.dt_td} getValue={(e)=> this.dt_tdat(e)} />
              <div className="dt-btn-01" onClick={() => this.addday() } ><FontAwesomeIcon icon="plus" size="1x" /></div>
        </div>
        <div className="flex"></div>
        <button className="btn btn-hw-blue w250" onClick={() => this.rvs_balans() } aria-label="System" >
            Bokningar
        </button>
        <button className="btn btn-hw-green w250" onClick={() => this.saldo_dt() } aria-label="System" >
            Bokning dag
        </button>
        <button className="btn btn-hw-blue w150" onClick={() => this.saldo_rvs() } aria-label="System" >
            Visa
        </button>
    </div>
    <div className="divider"></div>

        <div id="rvsTable">
          <table className="table table-bordered table-hover" style={{marginBottom: "0px"}}>
            <thead>
            <tr>
                <th style={ this.th_style("boknr") } onClick={() => this.tb_sort("boknr") }>Boknr</th>
                <th style={ this.th_style("name") } onClick={() => this.tb_sort("name") }>Namn</th>
                <th style={ this.th_style("ankdat") } onClick={() => this.tb_sort("ankdat") }>Ankomst</th>
                <th style={ this.th_style("avrdat") } onClick={() => this.tb_sort("avrdat") }>Avresa</th>
                <th style={ this.th_style("rumsnr") } onClick={() => this.tb_sort("rumsnr") }>Rumsnr</th>
                <th>Fsg</th>
                <th>Bet</th>
                <th>Saldo</th>
                <th>Status</th>
            </tr>
            </thead>
            </table>
            <div style={ this.tb_style() }>
          <table className="table table-bordered table-hover">
            <tbody>
            { this.state.gsa.map((row, key) =>
            <tr key={key} onDoubleClick={() => this.trans_open(row) }>
                <td>{ row.boknr }</td>
                <td>{ utils.toLang( row.name ) }</td>
                <td>{ utils.toDate( row.ankdat ) }</td>
                <td>{ utils.toDate( row.avrdat ) }</td>
                <td>{ utils.toRumsnr( row.rumsnr ) }</td>
                <td>{ this.val_pris( row.charges.fsg ) }</td>
                <td>{ this.val_pris( row.charges.bet ) }</td>
                <td>{ this.val_pris( row.charges.saldo ) }</td>
                <td>{ row.status }</td>
            </tr>
            )}
            </tbody>
        </table>
        </div>
        </div>
        { this.state.wnds.saldo ? <Saldo_Dt wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.wnds.rvsbal ? <Rvs_Balans wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }

        <Wnd_Error msg={this.state.msg} ctx={this} />
        <Wnd_Ok msg={this.state.msg} ctx={this} />
        <Wnd_Info msg={this.state.msg} ctx={this} />
        <Wnd_Confirm msg={this.state.msg} ctx={this} />

    </div>
  );
}
}
export default Eko_Rvs;
