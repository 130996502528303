import React, { useState, useEffect, useContext } from 'react';
import * as net from "../../lib/net";

class Mark_Add extends React.Component {
  constructor(props, context) {
    super(props);

   //this.webStore = props.store;
    this.state = {};
    this.state.show = props.show;
    this.state.uaa = [];
    this.state.laa = [];
    this.state.lso = {};
    this.state.m00 = {};
    //this.state.lro = props.lro;
    this.state.ynq = [
                {id: '0', text: 'JA'},
                {id: '1', text: 'NEJ'}
            ];
    this.state.aa = [
                {id: '01', text: 'AVD 01'},
                {id: '02', text: 'AVD 02'}
            ];
    this.state.authval = [
                {id: '000', text: 'NIVÅ 000'},
                {id: '100', text: 'NIVÅ 100'},
                {id: '900', text: 'NIVÅ 900'}
            ];
    this.state.cols = {};
    this.state.lrwrk = false;

    this.state.pro = {};
    this.state.pbo = {};
    this.state.qbo = props.pbo;
    this.state.ix = 0;
    this.state.md_01 = false;
    this.state.md_init = false;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.mark_init();
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    tab_style(tabid) {
      var css = {};
      css.display = "none";
      if(this.state.tabval === tabid) css.display = "block";
      return css;
    }
    tab_head(tabid) {
      var css = {};
      css.background = "#fff";
      css.color = "#000";
      if(this.state.tabval === tabid) {
          css.background = "#080";
          css.color = "#fff";
      }
      return css;
    }
    mark_init() {
        var self = this;
        var qbo = {};

        qbo.m00 = {};
        qbo.m00.m0m = "";
        qbo.m00.m0enhet = "";
        qbo.m00.m0datum = "";
        qbo.m00.m0pass = "";
        qbo.m00.m0bord = "";
        qbo.m00.m0lopnr = "";
        qbo.m00.m0id = "";
        qbo.m00.m0typ = "";
        qbo.m00.m0m01 = "";
        qbo.m00.m0m02 = "";
        qbo.m00.m0m03 = "";
        qbo.m00.m0m04 = "";
        qbo.m00.m0m05 = "";
        qbo.m00.m0m06 = "";
        qbo.m00.m0m07 = "";
        qbo.m00.m0m08 = "";
        qbo.m00.m0m09 = "";
        qbo.m00.m0m10 = "";
        qbo.m00.m0reflager = "";
        qbo.m00.m0status = "";
        qbo.m00.m0filler = "";
        qbo.m00.m0tid = "";
        qbo.m00.m0updtime = "";

        self.setState({qbo: qbo, pbo: qbo,  md_init: true});
    }
    cdi_init() {
        var self = this;
        var pro = {};
        var pbo = {};
        var qbo = self.state.qbo;

        var keys = Object.keys(qbo);
        if(keys.length < 1) {
            self.setState({pbo: pbo, pro: pro,  md_init: true});
            return;
        }
        for(var key of keys) {
            var o = qbo[key];
            pbo[key] = {};
            var okeys = Object.keys(qbo[key]);
            for(var okey of okeys) {
                var oo = qbo[key][okey];
                pbo[key][okey] = oo;
            }
        }

        pbo.kd00.k0text = pbo.kd00.k0text.cdi2txt();

        self.setState({pbo: pbo, pro: pro,  md_init: true});
    }
    cdi_store() {
        var self = this;
        var pbo = self.state.pbo;
        var qbo = self.state.qbo;
        var pro = self.state.pro;

        //if(pbo.kd00 && pbo.kd00.k0text) pbo.kd00.k0text = pbo.kd00.k0text.txt2cdi();

        var keys = Object.keys(pbo);
        if(keys.length < 1) {
            return;
        }
        for(var key of keys) {
            var o = pbo[key];
            if(key.substr(0,3) != "plu") continue;

            var okeys = Object.keys(pbo[key]);
            for(var okey of okeys) {
                var oo = pbo[key][okey];
                qbo[key][okey] = oo;
            }
        }
        self.setState({qbo: qbo});
    }
    cdi_mbuf() {
        var self = this;
        var kdbuf = {};
        var pbo = self.state.pbo;
        var pro = self.state.pro;

        //if(pbo.kd00 && pbo.kd00.k0text) pbo.kd00.k0text = pbo.kd00.k0text.txt2cdi();

        var keys = Object.keys(pbo);
        if(keys.length < 1) {
            return null;
        }
        for(var key of keys) {
            var o = pbo[key];
            if(key.substr(0,2) != "m") continue;

            kdbuf[key] = {};
            var okeys = Object.keys(pbo[key]);
            for(var okey of okeys) {
                var oo = pbo[key][okey];
                kdbuf[key][okey] = oo;
            }
        }
        self.setState({pro: pro});

        return kdbuf;
    }

    prn_val() {
      alert(this.state.pbo.usr00.u0namn);
    }

    formchange (e) {
      const name = e.target.name;
      const value = e.target.value;
      var pbo = this.state.pbo;
      var na = name.split(".");
      pbo[na[0]][na[1]] = value;
      this.setState({pbo: pbo});
      //setState({[name]: value});
    }
    prischange (e) {
      const name = e.target.name;
      const value = e.target.value;
      var pro = this.state.pro;
      var na = name.split(".");
      pro[na[1]] = value;
      this.setState({pro: pro});
      //setState({[name]: value});
    }

    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    dlg_close = () => {
		//this.props.ctx.setState({ usropen: false });
		this.props.ctx.setState({ markadd: false });
	};
    handleChange = (event, newValue) => {
        //alert(newValue);
        this.setState({ix: newValue});
      };
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  cssCols (cid) {
      var css = {};
      if(this.state.cols[cid]) css.width = this.state.cols[cid].toString() + "px";
      //if(cid == "c01") alert(JSON.stringify(this.state.cols[cid]));
      return css;
  }

  md_toggle (event) {
    var self = this;
    self.setState({md_01: false });
    return;
  }

  mark_fkn (event) {
    var self = this;
    self.setState({md_01: true });
    return;
  }

  mark_add() {
    var self = this;
    //var marker00 = self.cdi_mbuf();
    //alert(JSON.stringify(marker00));
    //alert(JSON.stringify(self.state.pbo));
    //return;
    var marker00 = self.state.pbo.m00;

    var prm = {};
    prm.req = "pos.pos_cas.mark_add";
    prm.marker00 = marker00;

    //gda.wndLoading("Hämtar");
    //net.sio_req(prm)
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            return;
        }
        self.setState({paa: ret.rca });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
    }

  render() {
    if(!this.props.show) return "";

    //alert(this.props.ctx.state.lro);

    var title = this.props.title;
    if(!title) title = "Plu";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="mx_backdrop" /> : null }
        <div className="mx_dlg mx_90 flex-col" style={ this.dlg_style() }>
        { this.state.md_init ?
        <div className="mx_content flex">
            <div className="flex-row" style={{padding:"5px",height:"30px"}}>
                    <div className="flex-row flex txt_18">
                        <div>REGISTRERA MARKER</div>
                    </div>
                    <div className="flex-col">
                        <div>SKAPA</div>
                    </div>
            </div>
            <div className="divider_orange"></div>

            <div style={{padding:"5px"}}></div>

            <form className="cdi_f03">
            <div hidden={this.state.ix !== 0}>

                <div className="form-row flex-row">
                    <div className="form-group flex-row flex">
                        <span className="header">Typ</span>
                        <input type="text" className="form-control text-left w350"
                               name="m00.m0m" value={this.state.m00.m0m}
                               placeholder="" onChange={(e) => this.formchange(e)} />
                    </div>
                </div>
                <div className="form-row flex-row">
                    <div className="form-group flex-row flex">
                        <span className="header">Enhet</span>
                        <input type="text" className="form-control text-left w350"
                               name="m00.m0enhet" value={this.state.m00.m0enhet}
                               placeholder="" onChange={(e) => this.formchange(e)} />
                    </div>
                    <div className="form-group flex-row flex">
                        <span className="header">Datum</span>
                        <input type="text" className="form-control text-left w350"
                               name="m00.m0datum" value={this.state.m00.m0datum}
                               placeholder="" onChange={(e) => this.formchange(e)} />
                    </div>
                </div>

                <div className="form-row flex-row">
                    <div className="form-group flex-row flex">
                        <span className="header">Pass</span>
                        <input type="text" className="form-control text-left w250"
                               name="m00.m0pass" value={this.state.m00.m0pass}
                               placeholder="" onChange={(e) => this.formchange(e)} />
                    </div>
                    <div className="form-group flex-row flex">
                        <span className="header">Bord</span>
                        <input type="text" className="form-control text-left w250"
                               name="m00.m0bord" value={this.state.m00.m0bord}
                               placeholder="" onChange={(e) => this.formchange(e)} />
                    </div>
                </div>

                <div className="form-row flex-row">
                    <div className="form-group flex-row flex">
                        <span className="header">ID</span>
                        <input type="text" className="form-control text-left w250"
                               name="m00.m0id" value={this.state.m00.m0id}
                               placeholder="" onChange={(e) => this.formchange(e)} />
                    </div>
                    <div className="form-group flex-row flex">
                        <span className="header">TYP</span>
                        <input type="text" className="form-control text-left w250"
                               name="m00.m0typ" value={this.state.m00.m0typ}
                               placeholder="" onChange={(e) => this.formchange(e)} />
                    </div>
                </div>

                <div className="form-sep"></div>

                <div className="form-row flex-row">
                    <div className="form-group flex-row flex">
                        <span className="header">Val 01</span>
                        <input type="text" className="form-control text-left w250"
                               name="m00.m0m01" value={this.state.m00.m0m01}
                               placeholder="" onChange={(e) => this.formchange(e)} />
                    </div>
                    <div className="form-group flex-row flex">
                        <span className="header">Val 02</span>
                        <input type="text" className="form-control text-left w250"
                               name="m00.m0m02" value={this.state.m00.m0m02}
                               placeholder="" onChange={(e) => this.formchange(e)} />
                    </div>
                </div>
                <div className="form-row flex-row">
                    <div className="form-group flex-row flex">
                        <span className="header">Val 03</span>
                        <input type="text" className="form-control text-left w250"
                               name="m00.m0m03" value={this.state.m00.m0m03}
                               placeholder="" onChange={(e) => this.formchange(e)} />
                    </div>
                    <div className="form-group flex-row flex">
                        <span className="header">Val 04</span>
                        <input type="text" className="form-control text-left w250"
                               name="m00.m0m04" value={this.state.m00.m0m04}
                               placeholder="" onChange={(e) => this.formchange(e)} />
                    </div>
                </div>
                <div className="form-row flex-row">
                    <div className="form-group flex-row flex">
                        <span className="header">Val 05</span>
                        <input type="text" className="form-control text-left w250"
                               name="m00.m0m05" value={this.state.m00.m0m05}
                               placeholder="" onChange={(e) => this.formchange(e)} />
                    </div>
                    <div className="form-group flex-row flex">
                        <span className="header">Val 06</span>
                        <input type="text" className="form-control text-left w250"
                               name="m00.m0m06" value={this.state.m00.m0m06}
                               placeholder="" onChange={(e) => this.formchange(e)} />
                    </div>
                </div>

            </div>

        </form>

        </div>
        : null }
        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250 ripple" onClick={() => this.dlg_close() } >
                STÄNG
            </button>
            <div className="flex-row">
             <button className="btn btn-hw-blue w250 ripple" onClick={(event) => this.mark_fkn(event) } >
                FUNKTIONER
            </button>
           </div>
            <button className="btn btn-hw-green w250 ripple" onClick={(event) => this.mark_add(event) } >
                SPARA
            </button>
        </div>
      </div>

    </div>
	;
	return html;
  };
}

export default Mark_Add;