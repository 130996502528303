import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../../lib/net";
import Fr_Input from "../../../lib/ui/fr_input";
import Vip_Trs from "./vip_trs";

class Vip_Edit extends React.Component {
  constructor(props, context) {
    super(props);

    this.pos = props.store.pos;
    this.state = {};
    this.state.ynq = [];
    this.state.sval = [];

    this.state.dao = {};
    this.state.aa = [];
    this.state.cval = [
                {id: '001', text: 'ANTAL GER NYTT PRIS'},
                {id: '002', text: 'ANTAL GER RABATT PÅ PRIS'},
                {id: '003', text: 'PRISÄNDRING GENOM RABATT'}
            ];

    this.state.wnds = {};
    this.state.vpo = props.vpo;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.happy();
  }
    cdi_init() {
        var self = this;
        var vpo = self.state.vpo;

        if(vpo.vip00) {
            //vpo.vip00.q0pris2 = vpo.vip00.q0pris2.formatpris();
            //vpo.vip00.q0npris2 = vpo.vip00.q0npris2.formatpris();
        }

        //self.setState({hpo: hpo,  md_init: true});
    }
    cdi_store() {
        var self = this;
/*
        var hap = self.state.hap;
        var hpo = self.state.hpo;

        if(!hap.hap00) {
            hap.hap00 = {};
        }
        hap.hap00 = {};

        hap.hap00.q0p = "Q";
        hap.hap00.q0kstns = this.state.pbo.plu00.p0kstns
        hap.hap00.q0katnr = this.state.pbo.plu00.p0katnr

        hap.hap00.q0pris2 = "";
        hap.hap00.q0npris2 = "";

        if(hpo.q0pris2) hap.hap00.q0pris2 = hpo.q0pris2.pris2cdi();
        if(hpo.q0npris2) hap.hap00.q0npris2 = hpo.q0npris2.pris2cdi();

        self.setState({hap: hap});
*/
    }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    tab_style(tabid) {
      var css = {};
      css.display = "none";
      if(this.state.tabval === tabid) css.display = "block";
      return css;
    }
    tab_head(tabid) {
      var css = {};
      css.background = "#fff";
      css.color = "#000";
      if(this.state.tabval === tabid) {
          css.background = "#080";
          css.color = "#fff";
      }
      return css;
    }
    grp_style(row) {
      var css = {};
      css.background = "#008";
      if(this.state.gnr === row.vgrupp) css.background = "#080";
      return css;
    }

    formchange (e) {
      const name = e.target.name;
      const value = e.target.value;
      var pbo = this.state.pbo;
      var na = name.split(".");
      pbo[na[0]][na[1]] = value;
      this.setState({pbo: pbo});
      //setState({[name]: value});
    }

    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    wnd_close = () => {
		this.props.ctx.setState({ wnds: {} });
	};
    handleChange = (event, newValue) => {
        //alert(newValue);
        this.setState({ix: newValue});
      };
    toggle() {
      this.setState({modal: false});
	};

  submitHandler = event => {
    event.preventDefault();
    event.target.className += " was-validated";
  };

  changeHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  cssCols (cid) {
      var css = {};
      if(this.state.cols[cid]) css.width = this.state.cols[cid].toString() + "px";
      //if(cid == "c01") alert(JSON.stringify(this.state.cols[cid]));
      return css;
  }
  vip_read() {
    var self = this;
    var prm = {};
    prm.req = "vip.vip_reg.vip_read";
    prm.avd = this.state.pbo.plu00.p0kstns;
    prm.plu = this.state.pbo.plu00.p0katnr;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        self.setState({hap: ret.rco }, function() { this.cdi_init(); });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  vip_save() {
    var self = this;

    //this.cdi_store();

    var prm = {};
    prm.req = "vip.vip_reg.vip_write";
    prm.vip00 = this.state.vpo.vip00;
    prm.vip01 = this.state.vpo.vip01;
    prm.vip02 = this.state.vpo.vip02;
    prm.vip03 = this.state.vpo.vip03;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

		self.props.ctx.setState({ wnds: {} });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  val_get (name) {
      var value = "";
    //alert(JSON.stringify(name));
    //alert(JSON.stringify(value));
    var na = name.split(".");
    //var ff = na[1];
    var vpo = this.state.vpo;
    if(vpo) value = vpo[na[0]][na[1]];
    if(name == "vip02.v2kortnr") value = value.ztrim();
    if(name == "vip02.v2tillgodo") value = value.cdipris();
    if(name == "vip02.v2kredit") value = value.cdipris();
    return value;
   }
    val_set (name, value) {
        //alert(JSON.stringify(name));
        //alert(JSON.stringify(value));
        var na = name.split(".");
        var vpo = this.state.vpo;
        vpo[na[0]][na[1]] = value;
        this.setState({vpo: vpo});
    }

    vip_trs() {
        var self = this;
        var wnds = {};
        wnds.viptrs = 1;
        self.setState({ wnds: wnds, vpo: this.state.vpo });
    }

  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "Knappar";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        <div className="m2_backdrop" />
        <div className="m2_dlg mx_80 flex-col" style={ this.dlg_style() } draggable="true" >
        <div className="mx_h11">
            <div>ID</div>
        </div>
        <div className="mx_content flex">

            <div className="flex-row" style={{padding:"5px",height:"35px"}}>
                    <div className="flex-col flex">
                        <div>KORT:</div>
                    </div>
                    <div className="flex-col">
                        <div>UPPDATERA</div>
                    </div>
            </div>
            <div className="divider_orange"></div>

            <form className="cdi_f03">
                <div className="form-row flex-row">
                    <Fr_Input label="ID" className="w250" text={ this.val_get("vip00.v0id") } getValue={(e)=> this.val_set("vip00.v0id", e) } placeholder="" />
                    <Fr_Input label="Typ" className="w250" text={ this.val_get("vip00.v0ktyp") } getValue={(e)=> this.val_set("vip00.v0ktyp", e) } placeholder="" />
                </div>
                <div className="divider_green"></div>
                <div className="form-row flex-row">
                    <Fr_Input label="Företag" className="w250" text={ this.val_get("vip00.v0company") } getValue={(e)=> this.val_set("vip00.v0company", e) } placeholder="" />
                </div>
                <div className="form-row flex-row">
                    <Fr_Input label="Namn" className="w250" text={ this.val_get("vip00.v0namn") } getValue={(e)=> this.val_set("vip00.v0namn", e) } placeholder="" />
                </div>
                <div className="divider_green"></div>
                <div className="form-row flex-row">
                    <Fr_Input label="Giltigt till" className="w250" text={ this.val_get("vip00.v0expire") } getValue={(e)=> this.val_set("vip00.v0expire", e) } placeholder="" />
                    <Fr_Input label="Kortnr" className="w250" text={ this.val_get("vip02.v2kortnr") } getValue={(e)=> this.val_set("vip02.v2kortnr", e) } placeholder="" />
                </div>
                <div className="form-row flex-row">
                    <Fr_Input label="Rabatt" className="w250" text={ this.val_get("vip02.v2rabatt") } getValue={(e)=> this.val_set("vip02.v2rabatt", e) } placeholder="" />
                </div>
                <div className="form-row flex-row">
                    <Fr_Input label="Tillgodo" className="text-right w250" text={ this.val_get("vip02.v2tillgodo") } getValue={(e)=> this.val_set("vip02.v2tillgodo", e) } placeholder="0.00" />
                    <Fr_Input label="Kredit" className="text-right w250" text={ this.val_get("vip02.v2kredit") } getValue={(e)=> this.val_set("vip02.v2kredit", e) } placeholder="0.00" />
                </div>
            </form>

        </div>
        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <button className="btn btn-hw-green w250" onClick={() => this.vip_trs() } >
                TRANSAKTIONER
            </button>
            <button className="btn btn-hw-green w250" onClick={() => this.vip_save() } >
                SPARA
            </button>
        </div>
      </div>
        { this.state.wnds.viptrs ?
            <Vip_Trs wnds={this.state.wnds} ctx={this} vpo={this.state.vpo} store={this.props.store} backdrop={true}/>
        : null }
      </div>
	;
	return html;
  };
}

export default Vip_Edit;