import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";
import * as utils from "../../assets/utils";

import Dt_Cal from "../../lib/ui/dt_cal";
import Td_Sel from "../../lib/ui/td_sel";
import Pms_FbRvo from "../rv/fb/rvo";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class PMS_Rwp extends React.Component {
  constructor(props, context) {
    super(props);

    this.selitems = [
        {fkn: 'open', text: 'ÖPPNA'},
        {fkn: 'cancel', text: 'AVBOKA'}
    ];

    this.store = props.store;
    this.state = { pgid: 11, tra: [] };
    this.state.seldate = new Date();
    this.state.avd = "01";
    this.state.rva = [];
    this.state.kro = {};
    this.state.aa = [];
    this.state.cols = {};
    this.state.boknr = "";
    this.state.rvo = false;
    this.state.wnds = {};

      this.state.sz_val = "";
      this.state.sortid = "";
      this.state.sortdir = 1;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.rvp();
  }
  sel_row_fkn(row, sel) {
    //alert(JSON.stringify(fkn));
      if(sel.fkn == "open") {
          this.rv_edit(row);
      }
      if(sel.fkn == "cancel") {
          this.rvp_cancel(row.resv00.resno);
      }
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      css.overflow = "auto";
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

  rv_edit(row) {
      var self = this;
      var wnds = {};
      wnds.rvo = 1;
      wnds.boknr = row.resv00.resno;
      wnds.cb = this.cb_rv;
    this.setState({wnds: wnds});
  }
   cb_rv(ctx, ret) {
    //alert(JSON.stringify(ret));
    if(ret && (ret.ok == "000")) {
        ctx.rvp();
        ctx.setState({ wnds: {} });
    }
    else ctx.setState({wnds: {}});
  }
  fkn_close() {
    this.setState({edit: false });
    //this.props.history.push("cas");
    //this.login_srv(uid, pwd);
  }
  rvp_cancel(boknr) {
    var self = this;
    var prm = {};
    prm.req = "pms.rv_fkn.py_cancel";
    prm.boknr = boknr;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        self.rvp();
    })
    .catch(function(e) {
        var txt = JSON.stringify(e);
    });
  }

  rvp() {
    var self = this;

    self.setState({ rva: [] });

    var prm = {};
    prm.req = "pms.rv_fkn.rv_pys";

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        self.setState({rva: ret.rco }, function () {
            self.syncTb("rvpTable");
        });
    })
    .catch(function(e) {
        var txt = JSON.stringify(e);
    });
  }
    syncTb(tb) {
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
        tdElements = tbel.getElementsByTagName('td');

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
            width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
    tb_sort(grp, typ) {
        var self = this;
        //var rvs = self.state.rvs;
        var rva = self.state.rva;
        var sz = this.state.sz_val;
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;

        if(sortid != typ) sortdir = 0;
        else {
            if(sortdir) sortdir = 0;
            else sortdir = 1;
        }

        if(sortdir) rva.sort((a,b) => (a[grp][typ] > b[grp][typ]) ? 1 : ((b[grp][typ] > a[grp][typ]) ? -1 : 0));
        else rva.sort((a,b) => (a[grp][typ] < b[grp][typ]) ? 1 : ((b[grp][typ] < a[grp][typ]) ? -1 : 0));
        self.setState({ rva: rva, sortid: typ, sortdir: sortdir });
    }

    mshow(part) {
      var css = {};
        css.display = "none";
        if(part == 0) {
            if(this.state.rva.length < 1) css.display = "";
        }
        if(part == 1) {
            if(this.state.rva.length > 0) css.display = "";
        }
      return css;
    }

    render() {
    return (
    <div className="web-app">

    <div className="flex-row flex-space-between">
        <Dt_Cal startdatum="2020-02-01" getValue={(e)=> this.setState({seldate: e}) }/>
        <button className="btn btn-hw-blue w150 ripple" onClick={() => this.rvp() } aria-label="System" >
            Visa
        </button>
    </div>
    <div className="divider_orange"></div>

        <div className="web-col flex-center" style={ this.mshow(0) }>
            <div className="flex-row flex-center">
                <div className="txt_22">LADDAR ...</div>
            </div>
        </div>
        <div className="web-col" style={ this.mshow(1) }>
        <div id="rvpTable" className="flex">
        <table className="table table-bordered table-hover tb " style={{marginBottom: "0px"}}>
            <thead>
            <tr>
                <th onClick={() => this.tb_sort("resv00", "resno") }>Boknr</th>
                <th onClick={() => this.tb_sort("resv00", "Company") }>Företag</th>
                <th onClick={() => this.tb_sort("resv01", "Name") }>Namn</th>
                <th onClick={() => this.tb_sort("resv01", "Tfn") }>Telefon</th>
                <th onClick={() => this.tb_sort("resv00", "ankdat") }>Ankomst</th>
                <th onClick={() => this.tb_sort("resv00", "avrdat") }>Avresa</th>
                <th onClick={() => this.tb_sort("resv00", "creat") }>Skapad</th>
                <th onClick={() => this.tb_sort("resv00", "sign") }>Signatur</th>
                <th></th>
            </tr>
            </thead>
        </table>
       <div className="tb-cdi" style={ this.tb_style() }>
       <table className="table table-bordered table-hover tb">
            <tbody>
            { this.state.rva.map((row, key) =>
            <tr key={key} onDoubleClick={() => this.rv_edit(row) }>
                <td>{ row.resv00.resno }</td>
                <td>{ row.resv00.Company }</td>
                <td>{ row.resv01.Name }</td>
                <td>{ row.resv01.Tfn }</td>
                <td>{ utils.toDate( row.ankdat ) }</td>
                <td>{ utils.toDate( row.avrdat ) }</td>
                <td>{ row.creat }</td>
                <td>{ row.sign }</td>
                <td>
                    <Td_Sel items={ this.selitems } getValue={(e)=> this.sel_row_fkn(row, e)} />
                </td>
            </tr>
            )}
            </tbody>
        </table>
        </div>
        </div>
    </div>
            { this.state.wnds.rvo ?
        <Pms_FbRvo wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
            : null }
   </div>
  );
    }
}
export default PMS_Rwp;
