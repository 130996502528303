import React, { useState, useEffect, useContext } from 'react';

import ddo from '../../../ddo.js'
import * as net from "../../../lib/net";

class Ki_Nota extends React.Component {
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.state = {};
    this.state.ynq = [];

    this.state.laa = [];
    this.state.lso = {};

    this.state.aa = [];

    this.state.kio = {};
    this.state.nto = {};
    this.state.kta = [];
    this.state.nta = [];
    if(props.wnds) {
        this.state.kio = props.wnds.kio;
        //this.state.kta = this.state.kio.rows;
    }
    this.state.ix = 0;
  }

  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      this.ki_nota();
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    tab_style(tabid) {
      var css = {};
      css.display = "none";
      if(this.state.tabval === tabid) css.display = "block";
      return css;
    }
    tab_head(tabid) {
      var css = {};
      css.background = "#fff";
      css.color = "#000";
      if(this.state.tabval === tabid) {
          css.background = "#080";
          css.color = "#fff";
      }
      return css;
    }

    wnd_close = () => {
		this.props.ctx.setState({ wnds: {} });
	};
  tb_style() {
      var css = {};
      css.height = (window.innerHeight * 0.8) - 275;
      css.overflow = "auto";
      return css;
  }

    ki_nota() {
        var self = this;
        var prm = {};
        prm.req = "pos.pos_eko.trh_nota";
        prm.datum = this.state.kio.datum;
        prm.avd = this.state.kio.avd;
        prm.notanr = this.state.kio.notanr;

        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret.rco));
            self.setState({ nto: ret.rco, nta: ret.rca });
            self.syncTb("ntaTable");

            prm.req = "pos.pos_ki.ki_rd";
            return net.sio_req(prm);
        })
        .then(function(ret){
            //alert(JSON.stringify(ret.rco));
            var kio = ret.rco;
            self.setState({ kio: kio, kta: kio.rows });
            self.syncTb("ktaTable");
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }
    ki_prn() {
        var self = this;
        var prm = {};
        prm.req = "pos.pos_rp.ki_sz";
        prm.datum = this.state.fdat;
        prm.fdat = this.state.fdat;
        prm.tdat = this.state.tdat;
        prm.notanr = this.state.notanr;

        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret.rca));

            self.setState({pdf: 1, pdf_content: ret.pdf });
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }
    syncTb(tb){
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
            tdElements = tbel.getElementsByTagName('td');
        if(tdElements.length < 1) return;

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
                width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
    LevShow(row) {
        var self = this;
        var ktxt = "";
        var levid = row.lev.trim();
        ktxt = levid;
        //alert(JSON.stringify(kid));
        if(ddo.cache.levs[levid]) {
            var o = ddo.cache.levs[levid];
            ktxt = levid + " - " + o.name.toswe();
        }
        return ktxt;
    }

  render() {
    var title = this.props.title;
    if(!title) title = "Plu";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="m2_backdrop" /> : null }
        <div className="m2_dlg mx_80h flex-col" style={ this.dlg_style() }>

            <div className="flex-row" style={{padding:"5px",height:"35px"}}>
                    <div className="flex-col flex">
                        <div>UNDERLAG:</div>
                        <div>{ this.state.datum }</div>
                    </div>
                    <div className="flex-col">
                        <div>UPPDATERA</div>
                    </div>
            </div>
            <div className="divider_orange"></div>
        <div className="mx_content px-2 flex">

            <div className="flex-row">
                <div className="flex">Datum: { this.state.kio.datum }</div>
                <div className="flex">Lev: { this.LevShow(this.state.kio) }</div>
                <div className="flex">Notanr: { this.state.kio.notanr }</div>
            </div>
            <div className="flex-row">
                <div className="flex">Tid: { this.state.nto.slut }</div>
                <div className="flex">Bettyp: { this.state.nto.bettyp }</div>
                <div className="flex">Betalt: { this.state.nto.bet }</div>
            </div>
            <div className="divider_orange"></div>

    <div className="web-row">
        <div id="ntaTable" className="flex" style={ this.tb_style() }>
            <div className="txt_18">Kvitto rader</div>
            <table className="table table-bordered table-hover">
                <thead>
                <tr>
                    <th>Radnr</th>
                    <th>Plu</th>
                    <th>Text</th>
                    <th>Antal</th>
                    <th>Pris</th>
                    <th>Summa</th>
                    <th>Moms</th>
                </tr>
                </thead>
                <tbody>
                { this.state.nta.map((row, key) =>
                    <tr key={ key } onDoubleClick={() => this.row_edit(row)}>
                        <td>{ row.r0lopnr }</td>
                        <td>{ row.r0etyp }</td>
                        <td>{ row.r0text.toswe() }</td>
                        <td>{ row.r0noof.ztrim() }</td>
                        <td>{ row.r0perprice.cdipris() }</td>
                        <td>{ ((row.r0noof * 1) * (row.r0perprice * 1)).cdipris() }</td>
                        <td>{ row.r0vatproc }</td>
                    </tr>
                )}
                </tbody>
            </table>
        </div>
        <div id="ktaTable" className="flex" style={ this.tb_style() }>
            <div className="txt_18">Text rader</div>
            <table className="table table-bordered table-hover">
                <thead>
                <tr>
                    <th>Löpnr</th>
                    <th>Text</th>
                    <th>Status</th>
                </tr>
                </thead>
                <tbody>
                { this.state.kta.map((row, key) =>
                    <tr key={ key } onDoubleClick={() => this.row_edit(row)}>
                        <td>{ row.n0lopnr }</td>
                        <td>{ row.n0text.toswe() }</td>
                        <td>{ row.n0mdat }</td>
                    </tr>
                )}
                </tbody>
            </table>
        </div>
        </div>

        </div>

        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex-row">
            <button className="btn btn-hw-blue w250 hide" onClick={(event) => this.prn_val(event) } >
                FUNKTIONER
            </button>
            </div>
            <button className="btn btn-hw-green w250 hide" onClick={(event) => this.ki_prn(event) } >
                SKRIV UT
            </button>
        </div>
      </div>
    </div>
	;
	return html;
  };
}

export default Ki_Nota;