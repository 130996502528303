import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";

import Zrx_Dlg from "./kassa/zrx_dlg";
import Jrx_Dlg from "./kassa/jrx_dlg";
import Xr_dlg from "./kassa/xr_dlg";
import Zr_Dlg from "./kassa/zr_dlg";
import Jr_Dlg from "./kassa/jr_dlg";
import Beh_Dlg from "./kassa/beh_dlg";
import Cr_Dlg from "./kassa/cr_dlg";
import Cred_Dlg from "./kassa/cred_dlg";
import Prd_Dlg from "./kassa/prd_dlg";
import ddo from '../../ddo.js'

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Pos_Rps_Kassa extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = {};
    this.state.seldate = new Date();
    this.state.avd = ddo.cache.avd;
    this.state.paa = [];
    this.state.aa = [];

    this.state.pdf = 0;
    this.state.rptyp = "";

      this.state.mxa = [];
      this.state.mxa.push({ rpid: "zrx", pos: "c1", text: "Z-RAPPORT", desc: "Kontrollrapport stängd period", link: "" });
      this.state.mxa.push({ rpid: "jrx", pos: "c2", text: "JOURNALRAPPORT", desc: "Kontrollrapport för periodens journal", link: "" });

      this.state.mna = [];
      this.state.mna.push({ rpid: "cr", pos: "c1", text: "KASSARAPPORT", desc: "Total / Enskild kassarapport", link: "" });
      this.state.mna.push({ rpid: "cred", pos: "c1", text: "KASSÖRSRAPPORT", desc: "Total / Enskild kassörsrapportt", link: "" });
      this.state.mna.push({ rpid: "terr", pos: "c1", text: "TERMINAL RAPPORT", desc: "Total / Enskild terminalrapport", link: "" });
      this.state.mna.push({ rpid: "dpr", pos: "c1", text: "AVDELNINGS RAPPORT", desc: "Total / Enskild avdelningsrapport", link: "" });
      this.state.mna.push({ rpid: "pr", pos: "c1", text: "PRODUKTRAPPORT", desc: "Total / Enskild produktrapport", link: "" });
      this.state.mna.push({ rpid: "xr", pos: "c2", text: "X-RAPPORT", desc: "Kontrollrapport innevarande period", link: "" });
      this.state.mna.push({ rpid: "zr", pos: "c2", text: "Z-RAPPORT", desc: "Kontrollrapport stängd period", link: "" });
      this.state.mna.push({ rpid: "jr", pos: "c2", text: "JOURNALRAPPORT", desc: "Kontrollrapport för periodens journal", link: "" });
      this.state.mna.push({ rpid: "beh", pos: "c2", text: "BEHNDLINGSHISTORIK", desc: "Kontrollrapport av behandlingshistoriken", link: "" });
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 480;
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

    mnc(pos) {
        var mna = [];
        for(var row of this.state.mna) {
            if(row.pos == pos) mna.push( row );
        }
        return mna;
    }
    mnx(pos) {
        var mna = [];
        for(var row of this.state.mxa) {
            if(row.pos == pos) mna.push( row );
        }
        return mna;
    }
  rp_open(typ) {
      var self = this;
    this.setState({ rptyp: typ });
  }
    pdf_show(id) {
        this.setState({ pdf: id });
        return;
    }

  render() {
    return (
        <div className="web-app">
            <div className="web-pg py-4 flex">
                <h3>Kontroll rapporter</h3>
                <p>Kassa funktioner</p>

                <div className="divider_green"></div>
                <div className="web-row">
                    <div className="flex-col flex">
                        { this.mnx("c1").map((row, key) =>
                            <div key={key} className="web-bx" onClick={() => this.rp_open(row.rpid) }>
                                <div>{ row.text }</div>
                                <div className="flex-row">
                                    <div className="flex">{ row.desc }</div>
                                    <div className="web-link">Välj</div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="flex-col flex">
                        { this.mnx("c2").map((row, key) =>
                            <div key={key} className="web-bx" onClick={() => this.rp_open(row.rpid) }>
                                <div>{ row.text }</div>
                                <div className="flex-row">
                                    <div className="flex">{ row.desc }</div>
                                    <div className="web-link">Välj</div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>

                <div className="web-pg py-4 flex">
                    <h3>Kassa</h3>
                    <p>Kassa funktioner</p>

                    <div className="divider_green"></div>

                <div className="web-row">
                    <div className="flex-col flex">
                        { this.mnc("c1").map((row, key) =>
                            <div key={key} className="web-bx" onClick={() => this.rp_open(row.rpid) }>
                                <div>{ row.text }</div>
                                <div className="flex-row">
                                    <div className="flex">{ row.desc }</div>
                                    <div className="web-link">Välj</div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="flex-col flex">
                        { this.mnc("c2").map((row, key) =>
                            <div key={key} className="web-bx" onClick={() => this.rp_open(row.rpid) }>
                                <div>{ row.text }</div>
                                <div className="flex-row">
                                    <div className="flex">{ row.desc }</div>
                                    <div className="web-link">Välj</div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            { this.state.rptyp == "zrx" ? <Zrx_Dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
            { this.state.rptyp == "jrx" ? <Jrx_Dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }

        { this.state.rptyp == "xr" ? <Xr_dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "zr" ? <Zr_Dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "jr" ? <Jr_Dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "beh" ? <Beh_Dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "cr" ? <Cr_Dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "cred" ? <Cred_Dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "pr" ? <Prd_Dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
    </div>
  );
    }
}
export default Pos_Rps_Kassa;

/*
    <div className="web-row">
     <div className="web-col flex" style={{padding: "0px 10px"}}>
        <div className="web-btn" onClick={() => this.rp_open("cr") } >
            <div>KASSARAPPORT</div>
            <div>Total / Enskild kassarapport</div>
        </div>
        <div className="web-btn" onClick={() => this.rp_open("cred") } >
            <div>KASSÖRSRAPPORT</div>
            <div>Total / Enskild kassörsrapport</div>
        </div>
   </div>
     <div className="web-col flex">
        <div className="web-btn" onClick={() => this.rp_open("pr") } >
            <div>PRODUKTRAPPORT</div>
            <div>Total / Enskild produktrapport</div>
        </div>
   </div>
    <div className="web-col flex">
        <div className="web-btn" onClick={() => this.rp_open("xr") } >
            <div>X-RAPPORT</div>
            <div>Kontrollrapport innevarande period</div>
        </div>
        <div className="web-btn" onClick={() => this.rp_open("zr") } >
            <div>Z-RAPPORT</div>
            <div>Kontrollrapport stängd period</div>
        </div>
        <div className="web-btn" onClick={() => this.rp_open("jr") } >
            <div>JOURNALRAPPORT</div>
            <div>Kontrollrapport för periodens journal</div>
        </div>
    </div>
   </div>

    </div>

*/