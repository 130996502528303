import React, { useState, useEffect, useContext } from 'react';

import * as utils from "../../../assets/utils";
import * as net from "../../../lib/net";
import Wnd_Ok from "../../../lib/ui/wnd_ok";
import Wnd_Error from "../../../lib/ui/wnd_error";
import Wnd_Confirm from "../../../lib/ui/wnd_confirm";

import PdfNotaDlg from "./pdf_nota_dlg";
import Dt_Cal from "../../../lib/ui/dt_cal";
import Td_Sel from "../../../lib/ui/td_sel";
import {format} from "date-fns";
import Und_Edit from "./und_edit";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Und_Period extends React.Component {
    constructor(props, context) {
        super(props);

        this.dt_fd = React.createRef();
        this.dt_td = React.createRef();

        this.store = props.store;

        this.state = {};
        this.state.seldate = new Date();
        this.state.avd = "01";
        this.state.uaa = [];
        this.state.aa = [];
        this.state.fao = {};

        this.state.today = new Date();
        var fdts = format(this.state.today, "yyyy-MM-01");
        var tdts = format(this.state.today, "yyyy-MM-dd");
        this.state.fdat = fdts;
        this.state.tdat = tdts;
        this.state.sortid = "";
        this.state.msg = {};
        this.state.wnds = {};
        this.selitems = [
            {fkn: 'edit', text: 'ÖPPNA'},
            {fkn: 'activate', text: 'AKTIVERA'}
        ];
        this.tya = [
            {id: '0', text: 'ÖPPNA'},
            {id: '1', text: 'STÄNGDA'},
            {id: '2', text: 'ALLA'}
        ];
        this.state.typ = "1";
    }
    componentDidUpdate() {
    }
    componentWillUnmount() {
    }
    componentDidMount() {
        this.unds_ft();
    }

    dlg_style() {
        var css = {};
        css.width = window.innerWidth * 0.9;
        css.height = window.innerHeight * 0.9;
        return css;
    }
    body_style() {
        var css = {};
        css.height = window.innerHeight - 280;
        return css;
    }
    tb_style() {
        var css = {};
        css.height = window.innerHeight - 275;
        css.overflow = "auto";
        return css;
    }
    th_style(id) {
        var css = {};
        css.cursor = "pointer";
        if(id == this.state.sortid) css.color = "#080";
        return css;
    }
    onUnitChange(e) {
        this.setState({unit: e.value});
    }
    dt_fdat(e) {
        //alert(JSON.stringify(e));
        var dts = format(e, "yyyy-MM-dd");
        this.setState({ fdat: dts });
    }
    dt_tdat(e) {
        //alert(JSON.stringify(e));
        var dts = format(e, "yyyy-MM-dd");
        this.setState({ tdat: dts });
    }
    oneday(e) {
        //alert(JSON.stringify(e));
        //this.setState({ tdat: this.state.fdat });
        this.dt_td.current.updateDt(this.state.fdat);
    }
    addday(e) {
        //alert(JSON.stringify(e));
        //this.setState({ tdat: this.state.fdat });
        this.dt_fd.current.updateAdd();
        this.dt_td.current.updateAdd();
    }
    subday(e) {
        //alert(JSON.stringify(e));
        //this.setState({ tdat: this.state.fdat });
        this.dt_fd.current.updateSub();
        this.dt_td.current.updateSub();
    }

    und_edit(row) {
        var self = this;

        var wnds = {};
        wnds.edit = 1;
        wnds.datum = row.datum;
        wnds.notanr = row.notanr;
        wnds.und = row;
        wnds.cb = this.cb_undedit;
        this.setState({ wnds: wnds });
    }
    cb_undedit(ctx, rsp) {
        ctx.setState({ wnds: {} });
        ctx.unds_ft();
    }

    unds_ft() {
        var self = this;

        var typ = this.state.typ;
        if(typ == "2") typ = "";

        var prm = {};
        prm.req = "fakt.fakt_pos.underlag_ft";
        prm.datum = this.state.fdat;
        prm.fdat = this.state.fdat;
        prm.tdat = this.state.tdat;
        prm.avd = this.state.avd;
        prm.status = typ;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.rca));

                self.setState({uaa: ret.rca }, function () {
                    self.syncTb("closedTable");
                });
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
                //gda.wndError(txt);
            });
    }
    syncTb(tb){
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
            tdElements = tbel.getElementsByTagName('td');
        if(tdElements.length < 1 ) return;

        for (let i = 0; i < thElements.length; i++) {
            if(!tdElements[i]) continue;
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
                width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
    tb_sort(typ) {
        var self = this;
        var poa = self.state.poa;
        var paa = self.state.paa;
        var sz = this.state.sz_val;

        if(typ == "plu") {
            //paa.sort((a,b) => (a.plu00.p0katnr > b.plu00.p0katnr) ? 1 : ((b.plu00.p0katnr > a.plu00.p0katnr) ? -1 : 0));
        }
        self.setState({paa: paa, sortid: typ });
    }
    wnd_close() {
        var self = this;
        //this.props.ctx.setState({ wnds: {} });
        if(self.props.wnds.cb) {
            self.props.wnds.cb(self.props.ctx);
        }
        else self.props.ctx.setState({ wnds: {} });
    };
    sel_row_fkn(row, sel) {
        //alert(JSON.stringify(fkn));
        if(sel.fkn == "open") {
            this.und_edit(row);
        }
        if(sel.fkn == "activate") {
            this.inv_act(row);
        }
    }
    inv_act(row) {
        var self = this;
        var prm = {};
        prm.req = "fakt.fakt_pos.inv_act";
        prm.wfkt00 = row.fu;

        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret.rco));

        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }
    typ_sel (e) {
        const value = e.target.value;
        this.setState({typ: value});
    }

    render() {
        //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "UNDERLAG";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="mx_backdrop" /> : null }
        <div className="mx_dlg mx_90h flex-col" style={ this.dlg_style() }>
        <div className="mx_h01 flex-row" style={{background: "#1a456b", color: "#fff"}}>
            <div>UNDERLAG PERIOD</div>
            <div className="flex"></div>
            <div onClick={() => this.wnd_close() }>X</div>
        </div>
        <div className="mx_content flex-col flex px-3">

            <div className="flex-row">
                <div className="flex-row">
                    <div className="dt-btn-01" onClick={() => this.subday() } ><FontAwesomeIcon icon="minus" size="1x" /></div>
                    <Dt_Cal rp="1" ref={this.dt_fd} dts={this.state.fdat} getValue={(e)=> this.dt_fdat(e)} />
                    <div className="dt-btn-01" onClick={() => this.oneday() } >1</div>
                    <Dt_Cal rp="1" ref={this.dt_td} dts={this.state.tdat} getValue={(e)=> this.dt_tdat(e)} />
                    <div className="dt-btn-01" onClick={() => this.addday() } ><FontAwesomeIcon icon="plus" size="1x" /></div>
                </div>
                <select value={this.state.typ} className="form-control sel_input" placeholder="Välj typ" onChange={(e) => this.typ_sel(e)}>
                    { this.tya.map((row, key) =>
                        <option value={row.id}>{ row.text }</option>
                    )}
                </select>
                <div className="flex"></div>
                <button className="btn btn-hw-blue w250" onClick={() => this.unds_ft() } aria-label="System" >
                    Uppdatera
                </button>
            </div>
            <div className="divider"></div>

            <div id="closedTable" className="flex">
                <table className="table table-bordered table-hover tb " style={{marginBottom: "0px"}}>
                    <thead>
                    <tr>
                        <th style={ this.th_style("datum") } onClick={() => this.tb_sort("datum") }>Datum</th>
                        <th style={ this.th_style("notanr") } onClick={() => this.tb_sort("notanr") }>Notanr</th>
                        <th style={ this.th_style("belopp") } onClick={() => this.tb_sort("belopp") }>Belopp</th>
                        <th style={ this.th_style("kundnr") } onClick={() => this.tb_sort("kundnr") }>Kundnr</th>
                        <th style={ this.th_style("status") } onClick={() => this.tb_sort("status") }>Status</th>
                        <th></th>
                    </tr>
                    </thead>
                </table>
                <div style={ this.tb_style() }>
                    <table className="table table-bordered table-hover tb" style={{marginBottom:"0px"}}>
                        <tbody>
                        { this.state.uaa.map((row, key) =>
                            <tr key={key} onDoubleClick={() => this.und_edit(row) }>
                                <td>{ utils.toDate( row.datum ) }</td>
                                <td>{ utils.toNum( row.notanr ) }</td>
                                <td>{ utils.toPris( row.belopp ) }</td>
                                <td>{ row.fu.kundnr }</td>
                                <td>{ row.status }</td>
                                <td>
                                    <Td_Sel items={ this.selitems } getValue={(e)=> this.sel_row_fkn(row, e)} />
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
        <div className="mx_foot flex-row">
            <button className="btn btn-hw-red w150" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex"></div>
            <button className="btn btn-hw-green w150 hide" onClick={(event) => this.prn_nota() } >
                SKRIV UT
            </button>
        </div>
      </div>
            { this.state.wnds.edit ? <Und_Edit wnds={this.state.wnds} ctx={this} backdrop={true}/> : null }
            <PdfNotaDlg show={this.state.pdf} pdf={this.state.pdf_content} done={(ret) => this.pdf_done(ret)} backdrop={true}/>
            <Wnd_Error msg={this.state.msg} ctx={this} />
            <Wnd_Ok msg={this.state.msg} ctx={this} />
            <Wnd_Confirm msg={this.state.msg} ctx={this} />
    </div>
	;
    return html;
};
}

export default Und_Period;