import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";
import {withRouter} from "../../lib/react/withRouter";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Bi_Stat extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.pos = props.store.pos;
    this.state = { pgid: 11, tra: [] };
    this.state.seldate = new Date();
    this.state.avd = "01";
    this.state.paa = [];
    this.state.aa = [
                {id: '01', text: 'AVD 01'},
                {id: '02', text: 'AVD 02'}
            ];
    //context.posrend = this;
  }
  componentDidUpdate() {
    let value = this.context;
    /* ... */
  }
  componentWillUnmount() {
    let value = this.context;
    /* ... */
  }
  componentDidMount() {
    //this.props.fetchWells()
    //  .then(res => this.setState({ wells: res.wells }) )
  }

  fknGo(url) {
    //alert(row.href);
    this.props.navigate(url);
  }

  render() {
    return (
    <div className="web-app">
    <h3>Status</h3>

    <div className="web-p01 flex">

    <div className="web-row">
    <div className="web-col flex">
        <div className="web-btn" onClick={() => this.fknGo("/pos/stat/total") } >
            <div>TOTAL</div>
            <div>Summerad total fsg</div>
        </div>
        <div className="web-btn" onClick={() => this.fknGo("/pos/stat/grp") } >
            <div>GRUPP</div>
            <div>Fsg per varugrupp</div>
        </div>
    </div>
     <div className="web-col flex" style={{padding: "0px 10px"}}>
        <div className="web-btn" onClick={() => this.fknGo("/pos/stat/hh") } >
            <div>TIM</div>
            <div>Fsg timme för timme</div>
        </div>
        <div className="web-btn" onClick={() => this.fknGo("/pos/stat/week") } >
            <div>VECKO</div>
            <div>Veckosammanställning</div>
        </div>
        <div className="web-btn" onClick={() => this.fknGo("/pos/stat/pass") } >
            <div>PASS</div>
            <div>PASS avstämning</div>
        </div>
   </div>
     <div className="web-col flex">
        <div className="web-btn" onClick={() => this.fknGo("/pos/stat/trs") } >
            <div>TRANSAKTIONER</div>
            <div>Transaktions flöde</div>
        </div>
        <div className="web-btn" onClick={() => this.fknGo("/pos/stat/art") } >
            <div>ARTIKLAR</div>
            <div>Sammanställda artiklar</div>
        </div>
        <div className="web-btn" onClick={() => this.fknGo("/pos/stat/drift") } >
            <div>DRIFT/LOGG</div>
            <div>Drift med loggar</div>
        </div>
   </div>
   </div>

    </div>

    </div>
  );
    }
}
export default withRouter(Bi_Stat);
