import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../../lib/net";
import Fr_Input from "../../../lib/ui/fr_input";
import Fr_Select from "../../../lib/ui/fr_select";

class Tax_Edit extends React.Component {
  constructor(props, context) {
    super(props);

    this.pos = props.store.pos;
    this.state = {};
    this.state.mka = [];

    this.state.ynq = [
                {id: '0', text: 'NEJ'},
                {id: '1', text: 'JA'}
            ];

    this.state.dao = {};
    if(props.wnds.dao) this.state.dao = props.wnds.dao;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    tab_style(tabid) {
      var css = {};
      css.display = "none";
      if(this.state.tabval === tabid) css.display = "block";
      return css;
    }
    tab_head(tabid) {
      var css = {};
      css.background = "#fff";
      css.color = "#000";
      if(this.state.tabval === tabid) {
          css.background = "#080";
          css.color = "#fff";
      }
      return css;
    }
    grp_style(row) {
      var css = {};
      css.background = "#008";
      if(this.state.gnr === row.vgrupp) css.background = "#080";
      return css;
    }

    formchange (e) {
      const name = e.target.name;
      const value = e.target.value;
      var pbo = this.state.pbo;
      var na = name.split(".");
      pbo[na[0]][na[1]] = value;
      this.setState({pbo: pbo});
      //setState({[name]: value});
    }

    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    wnd_close = () => {
		this.props.ctx.setState({ wnds: {} });
	};
    handleChange = (event, newValue) => {
        //alert(newValue);
        this.setState({ix: newValue});
      };

  val_color(id, val) {
      var css = {};
      //alert(JSON.stringify(val))
      this.ctrl_fg.updateState(val.hex);
      this.setState({ bgfarg: val.hex });
      return css;
  }
  val_save(name, value) {
      var dao = this.state.dao;
      var na = name.split(".");
      dao[na[0]][na[1]] = value;
      //bto[na[1]] = value;
      this.setState({dao: dao});
      return;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  tax_save() {
    var self = this;
    var prm = {};
    prm.req = "pos.pos_tax.tax_upd";
    prm.mom00 = this.state.dao.mom00;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        //self.setState({paa: ret.rco });
		self.props.ctx.setState({ wnds: {} });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "Momskod";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        <div className="m2_backdrop" />
        <div className="m2_dlg mx_70 flex-col" style={ this.dlg_style() } draggable="true" >
        <div className="mx_content flex">

            <div className="flex-row" style={{padding:"5px",height:"35px"}}>
                    <div className="flex-col flex">
                        <div>MOMSKOD:</div>
                    </div>
                    <div className="flex-col">
                        <div>UPPDATERA</div>
                    </div>
            </div>
            <div className="divider_orange"></div>

            <form className="cdi_f03">
            <div className="flex-row">
                    <Fr_Input label="Avd" className="text-left w350" text={this.state.dao.mom00.m0kstns} getValue={(e)=> this.val_save("mom00.m0kstns", e) } />
                    <Fr_Input label="Momskod" className="text-left w350" text={this.state.dao.mom00.m0kod} getValue={(e)=> this.val_save("mom00.m0kod", e) } />
            </div>
            <div className="flex-row">
                    <Fr_Input label="Text" className="text-left w350" text={this.state.dao.mom00.m0txt} getValue={(e)=> this.val_save("mom00.m0txt", e) } />
                    <Fr_Input label="Redovisningsgrupp" className="text-left w350" text={this.state.dao.mom00.m0red} getValue={(e)=> this.val_save("mom00.m0red", e) } />
            </div>
            <div className="divider_green"></div>
            <div className="flex-row">
                    <Fr_Input label="Kostnadsställe" className="text-left w250" text={this.state.dao.mom00.m0kst} getValue={(e)=> this.val_save("mom00.m0kst", e) } />
                    <Fr_Input label="Projekt" className="text-left w250" text={this.state.dao.mom00.m0projekt} getValue={(e)=> this.val_save("mom00.m0projekt", e) } />
            </div>
            <div className="flex-row">
                    <Fr_Input label="Konto" className="text-left w250" text={this.state.dao.mom00.m0konto} getValue={(e)=> this.val_save("mom00.m0konto", e) } />
            </div>

                <div className="divider_green"></div>
                <div className="flex-row">
                    <Fr_Input label="Ansvar" className="text-left w250" text={this.state.dao.mom00.m0ansvar} getValue={(e)=> this.val_save("mom00.m0ansvar", e) } />
                    <Fr_Input label="Verksamhet" className="text-left w250" text={this.state.dao.mom00.m0verksamhet} getValue={(e)=> this.val_save("mom00.m0verksamhet", e) } />
                </div>
                <div className="flex-row">
                    <Fr_Input label="Aktivitet" className="text-left w250" text={this.state.dao.mom00.m0aktivitet} getValue={(e)=> this.val_save("mom00.m0aktivitet", e) } />
                    <Fr_Input label="Projekt" className="text-left w250" text={this.state.dao.mom00.m0projekt} getValue={(e)=> this.val_save("mom00.m0projekt", e) } />
                </div>
                <div className="flex-row">
                    <Fr_Input label="Objekt" className="text-left w250" text={this.state.dao.mom00.m0objekt} getValue={(e)=> this.val_save("mom00.m0objekt", e) } />
                    <Fr_Input label="Fridel" className="text-left w250" text={this.state.dao.mom00.m0fridel} getValue={(e)=> this.val_save("mom00.m0fridel", e) } />
                </div>
                <div className="flex-row">
                    <Fr_Input label="Motpart" className="text-left w250" text={this.state.dao.mom00.m0motpart} getValue={(e)=> this.val_save("mom00.m0motpart", e) } />
                </div>

            </form>

        </div>
        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                TA BORT
            </button>
            <div className="flex-row">
            <button className="btn btn-hw-red w250" onClick={() => this.tax_save() } >
                SPARA
            </button>
            </div>
        </div>
      </div>
      </div>
	;
	return html;
  };
}

export default Tax_Edit;