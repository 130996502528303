import React, { useState, useEffect, useContext } from 'react';
import {format, addDays, subDays} from "date-fns";

import * as net from "../../../../lib/net";
import Fr_Input from "../../../../lib/ui/fr_input";
import Fr_Select from "../../../../lib/ui/fr_select";
import Dt_Cal from "../../../../lib/ui/dt_cal";

class Rsk_Add extends React.Component {
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pms = props.store.pms;

    this.state = {};
    this.state.today = new Date();
    this.state.rta = [];
    this.state.pka = [];

    this.state.gnr = "001";
    this.state.gbo = {};
    //this.state.lro = props.lro;
    this.state.ynq = [
                {id: '0', text: 'NEJ'},
                {id: '1', text: 'JA'}
            ];

    this.state.bgo = {};
    this.state.bto = {};
    this.state.background = "#fff";

    this.state.cols = {};
    this.state.lrwrk = false;
    this.state.boknr = {};
    if(props.boknr) this.state.boknr = props.boknr;
    this.state.rso = {};
    if(props.rso) this.state.rso = props.rso;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.rs_init();
    //this.rts();
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    grp_style(row) {
      var css = {};
      css.background = "#008";
      if(this.state.gnr === row.vgrupp) css.background = "#080";
      return css;
    }

    formchange (e) {
      const name = e.target.name;
      const value = e.target.value;
      var pbo = this.state.pbo;
      var na = name.split(".");
      pbo[na[0]][na[1]] = value;
      this.setState({pbo: pbo});
      //setState({[name]: value});
    }

    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    wnd_close = () => {
		//this.props.ctx.setState({ rvbradd: false });
        var ret = {};
        ret.ok = "999";
        this.props.cb(this.props.ctx, ret);
	};
    handleChange = (event, newValue) => {
        //alert(newValue);
        this.setState({ix: newValue});
      };
    toggle() {
      this.setState({modal: false});
	};

  submitHandler = event => {
    event.preventDefault();
    event.target.className += " was-validated";
  };

  changeHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  };


    rsv_save (name, value) {
        //alert(JSON.stringify(name));
        //alert(JSON.stringify(value));
      var rsv = this.state.bro;
      var na = name.split(".");
      rsv[na[0]][na[1]] = value;
      this.setState({rsv: rsv});
      //setState({[name]: value});
    }
    val_save (name, value) {
        //alert(JSON.stringify(name));
        //alert(JSON.stringify(value));
      var rso = this.state.rso;
      rso[name] = value;
      this.setState({rso: rso});
      //setState({[name]: value});
    }
    cb_save (name, e) {
      const value = e.target.value;
      var rso = this.state.rso;
      rso[name] = value;
      this.setState({rso: rso});
      //setState({[name]: value});
    }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }

  rs_init() {
    var self = this;
    var dat = addDays(this.state.today, 1);
    var fdat = format(this.state.today, "yyyy-MM-dd");
    var tdat = format(dat, "yyyy-MM-dd");

    var rso = {};
    rso.fdat = fdat;
    rso.tdat = tdat;
    rso.nrr = "1";
    rso.nrg = "1";
    rso.nrgr = "1";
    rso.nrb0 = "0";
    rso.nrb1 = "0";
    rso.nrb2 = "0";
    rso.rt = "";
    rso.pkat = "";
    rso.rab = "";
    this.setState({rso: rso});
  }
  rts() {
    var self = this;
    var prm = {};
    prm.req = "pms.rt.rt_date";
    //prm.datum = this.state.datum;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            return;
        }
        self.setState({rta: ret.rco});
        self.pkats();
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  pkats() {
    var self = this;
    var prm = {};
    prm.req = "pms.pkat.qa_pkats";
    //prm.datum = this.state.datum;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            return;
        }
        self.setState({pka: ret.rco});
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

  rs_add() {
    var self = this;
    var prm = {};
    prm.req = "pms.br.br_add";
    prm.boknr = this.state.boknr;
    prm.rt = this.state.rso;
    //prm.nyp00 = this.state.nyp00;
    //alert(JSON.stringify(prm));
    //return;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok == "000") {
		    self.props.cb(self.props.ctx, ret);
            return;
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "Delbokning";
    //if(props.kro) alert(props.kro.krr00.kid);
    const popover = {
      position: 'absolute',
      top: '50px',
      zIndex: '2',
    }
    const cover = {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    }

	var html =
        <div className="mx_base">
        <div className="m2_backdrop" />
        <div className="m2_dlg mx_70 flex-col" style={ this.dlg_style() } draggable="true" >

        <div className="mx_content flex">

            <div className="flex-row" style={{padding:"5px",height:"35px"}}>
                    <div className="flex-col flex">
                        <div>Ny Lokal:</div>
                    </div>
                    <div className="flex-col">
                        <div>UPPDATERA</div>
                    </div>
            </div>
            <div className="divider_orange"></div>

            <form className="cdi_f03">
            <div className="flex-row hide">
                <Dt_Cal startdatum="2020-02-01" getValue={(e)=> this.setState({fdat: e}) }/>
                <Dt_Cal startdatum="2020-02-01" getValue={(e)=> this.setState({tdat: e}) }/>
            </div>
            <div className="flex-row py-2">
                    <Fr_Input label="Från" className="text-left w250" text={this.state.rso.fdat} getValue={(e)=> this.val_save("fdat", e) } />
                    <Fr_Input label="Till" className="text-left w250" text={this.state.rso.tdat} getValue={(e)=> this.val_save("tdat", e) } />
            </div>
            <div className="flex-row py-2">
                    <Fr_Input label="Antal Rum" className="text-left w250" text={this.state.rso.nrr} getValue={(e)=> this.val_save("nrr", e) } />
                    <Fr_Input label="Antal Gäster" className="text-left w250" text={this.state.rso.nrg} getValue={(e)=> this.val_save("nrg", e) } />
            </div>
            <div className="flex-row py-2">
                    <Fr_Input label="Barn" className="text-left w250" text={this.state.rso.nrb} getValue={(e)=> this.val_save("nrb", e) } />
            </div>
            <div className="divider_green"></div>
            <div className="flex-row py-2 hide">
                    <Fr_Input label="Rumstyp" className="text-left w350" text={this.state.rso.rt} getValue={(e)=> this.val_save("rt", e) } />
                    <Fr_Input label="Priskategori" className="text-left w350" text={this.state.rso.pkat} getValue={(e)=> this.val_save("pkat", e) } />
            </div>
            <div className="form-group flex-row py-2">
                    <span className="header">Rumstyp</span>
                    <select value={this.state.rso.rt} className="form-control w350" onChange={(e) => this.cb_save("rt", e)}>
                        { this.state.rta.map((row, key) =>
                        <option key={key} value={row.r1typ}>{row.r1typ}</option>
                        )}
                    </select>
            </div>
            <div className="form-group flex-row py-2">
                    <span className="header">Priskategori</span>
                    <select value={this.state.rso.pkat} className="form-control w350" onChange={(e) => this.cb_save("pkat", e)}>
                        { this.state.pka.map((row, key) =>
                        <option key={key} value={row.n0typ}>{row.n0typ} - {row.n0text}</option>
                        )}
                    </select>
            </div>
            </form>

        </div>

        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                AVBRYT
            </button>
            <div className="flex-row">
            <button className="btn btn-hw-red w250" onClick={() => this.rs_add() } >
                LÄGG TILL
            </button>
            </div>
        </div>
      </div>
      </div>
	;
	return html;
  };
}

export default Rsk_Add;