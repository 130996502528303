import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../../lib/net";
import * as utils from "../../../assets/utils";

import Fr_Input from "../../../lib/ui/fr_input";
import Wnd_Error from "../../../lib/ui/wnd_error";
import Wnd_Ok from "../../../lib/ui/wnd_ok";
import Wnd_Info from "../../../lib/ui/wnd_info";
import Wnd_Confirm from "../../../lib/ui/wnd_confirm";
import Fr_Cal from "../../../lib/ui/fr_cal";
import Lang from "../../../lib/lang";
import Td_Sel from "../../../lib/ui/td_sel";
import Vkod_Edit from "./vkod_edit";
import Dd_Sel from "../../../lib/ui/dd_sel";
import Vkod_Add from "./vkod_add";
import Vkod_Gen from "./vkod_gen";

class VSerie_Edit extends React.Component {
  constructor(props, context) {
    super(props);

    ///this.pos = props.store.pos;

    this.state = {};
    this.state.vpa = [];

    this.state.gnr = "001";
    this.state.ynq = [
        {id: '0', text: 'NEJ'},
        {id: '1', text: 'JA'}
    ];
    this.state.hubtypval = [
        {id: 'bas', text: 'BAS HUB'},
        {id: 'enk', text: 'ENKEL HUB'},
        {id: 'mul', text: 'KOMPLETT HUB'}
    ];
      this.state.fknitems = [
          {fkn: 'create', text: 'SKAPA KODSERIE'},
          {fkn: 'activate', text: 'AKTIVERA SERIE'},
          {fkn: 'deactivate', text: 'DEAKTIVERA SERIE'},
          {fkn: 'export', text: 'EXPORTERA SERIE'},
          {fkn: 'clear', text: 'RENSA SERIE'}
      ];

      this.state.serie = "";
      this.state.tzip = "";
    this.state.tzip = "";
    this.state.ztext = "";
    this.state.tabval = 0;
      this.state.wnds = {};
      this.state.msg = {};
    this.state.cols = {};
      this.state.vso = {};
    if(props.wnds.vso){
        this.state.vso = props.wnds.vso;
    }
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      this.vkods();
  }

    task_init() {
        var tdo = {};
        tdo.typ = "";
        tdo.id = "";
        tdo.text = "";
        tdo.reg = "";
        tdo.status = "";
        //alert(JSON.stringify(rso));
        this.setState({ tdo: tdo });
        return;
    }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    tb_style() {
        var css = {};
        css.height = (window.innerHeight * 0.9) - 275;
        return css;
    }
    th_style(id) {
        var css = {};
        css.cursor = "pointer";
        if(id == this.state.sortid) {
            if(this.state.sortdir) css.color = "#800";
            else css.color = "#080";
        }
        return css;
    }
    row_style(row) {
        var srow = this.state.selrow;

        var css = {};
        if(srow == row) {
            css.background = "#080";
            css.color = "#fff";
        }
        return css;
    }
    tab_head(tabid) {
        var css = {};
        css.background = "#fff";
        css.color = "#000";
        if(this.state.tabval === tabid) {
            css.background = "#080";
            css.color = "#fff";
        }
        return css;
    }
    tab_style(tabid) {
        var css = {};
        css.display = "none";
        if(this.state.tabval === tabid) css.display = "block";
        return css;
    }
    tab_sel(tabid) {
        var self = this;
        this.setState({tabval: tabid}, function() {
            //alert(JSON.stringify(tabid));
            if(tabid == 1) {
                //self.zips();
                //self.syncTb("mnpTable");
            }
            if(tabid == 2) {
                //self.syncTb("mdeTable");
            }
        });
    }
    tb_sort(typ) {
        var self = this;
        //var rvs = self.state.rvs;
        var mpa = self.state.mpa;
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;

        if(sortid != typ) sortdir = 0;
        else {
            if(sortdir) sortdir = 0;
            else sortdir = 1;
        }

        if(typ.indexOf(".") != -1) {
            var fa = typ.split(".");
            if(sortdir) mpa.sort((a,b) => (a[fa[0]][fa[1]] < b[fa[0]][fa[1]]) ? 1 : ((b[fa[0]][fa[1]] < a[fa[0]][fa[1]]) ? -1 : 0));
            else mpa.sort((a,b) => (a[fa[0]][fa[1]] > b[fa[0]][fa[1]]) ? 1 : ((b[fa[0]][fa[1]] > a[fa[0]][fa[1]]) ? -1 : 0));
        }
        else {
            if(sortdir) mpa.sort((a,b) => (a[typ] < b[typ]) ? 1 : ((b[typ] < a[typ]) ? -1 : 0));
            else mpa.sort((a,b) => (a[typ] > b[typ]) ? 1 : ((b[typ] > a[typ]) ? -1 : 0));
        }
        self.setState({ mpa: mpa, sortid: typ, sortdir: sortdir });
    }
    th_icon(typ) {
        var cls = "";
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;
        if(sortid == typ)
        {
            if(sortdir) cls = "fa fa-caret-up";
            else cls = "fa fa-caret-down";
        }
        return cls;
    }

    wnd_close = () => {
        if(this.props.close) {
            this.props.close(this.props.ctx);
        }
        else this.props.ctx.setState({ wnds: {} });
    };
    hubtyp_change (e) {
        const value = e.target.value;
        var hbo = this.state.hbo;
        hbo.hub00.hubtyp = value;
        this.setState({hbo: hbo});
    }
    anm_change (e) {
        const value = e.target.value;
        var sco = this.state.sco;
        sco.anm = value;
        this.setState({sco: sco});
    }

    val_save(id, val) {
        var vso = this.state.vso;
        vso[id] = val;
        this.setState({ vso: vso });
        return;
    }
    xval_save(id, val) {
        var hbo = this.state.hbo;
        var ida = id.split(".");
        var id1 = ida[0];
        var id2 = ida[1];
        hbo[id1][id2] = val;
        this.setState({ hbo: hbo });
        return;
    }
    vkods() {
        var self = this;
        self.setState({vpa: [], loading: 1 });

        var serie = this.state.vso.serie;
        var prm = {};
        prm.req = "pos.pos_ecom_vkod.vkod_serie";
        prm.serie = serie;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.rca));

                //var a = self.fltr(ret.rca);
                self.setState({vpa: ret.rca, loading: 0 });
                //toastr.success("OK", "SAPI");
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    syncTb(tb) {
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
            tdElements = tbel.getElementsByTagName('td');
        if(tdElements.length < 1 ) return;

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
                width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
    vserie_upd() {
    var self = this;
    var prm = {};
    prm.req = "pos.pos_ecom_vkod.vserie_upd";
    prm.vso = this.state.vso;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));
        if(ret.ok == "000") {
            self.setState({ msg: {ok: true, text: "Sparad ..."} });
            setTimeout(function(){
                self.setState({msg: {} });
                  if(self.props.wnds.cb) {
                      self.props.wnds.cb(self.props.ctx);
                  }
                  else self.props.ctx.setState({ wnds: {} });
                return;
            }, 1000);
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
    vserie_del() {
        var self = this;
        var prm = {};
        prm.req = "pos.pos_ecom_vkod.vserie_del";
        prm.vso = this.state.vso;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret));
                if(ret.ok == "000") {
                    self.setState({ msg: {ok: true, text: "Borttagen ..."} });
                    setTimeout(function(){
                        self.setState({msg: {} });
                        if(self.props.wnds.cb) {
                            self.props.wnds.cb(self.props.ctx);
                        }
                        else self.props.ctx.setState({ wnds: {} });
                        return;
                    }, 1000);
                }
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    vserie_export() {
        var self = this;
        var serie = this.state.vso.serie;
        var prm = {};
        prm.req = "pos.pos_ecom_vkod.vkod_export";
        prm.serie = serie;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret));
                if(ret.ok == "000") {
                    //alert(JSON.stringify(ret.rco));
                    var rawbuf = ret.rco.data;
                    var file = new Blob([(rawbuf)], {type: 'application/octet-stream'});
                    var fileURL = window.URL.createObjectURL(file);
                    //$scope.fil_object = $sce.trustAsResourceUrl(fileURL);
                    var a = document.createElement('a');
                    a.href = fileURL;
                    var filename = ret.rco.filename;
                    if(filename.length < 1) filename = "vkod.xlsx";
                    a.download = filename;
                    document.body.appendChild(a);
                    a.click();
                }
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    vkod_serie_upd() {
        var self = this;
        var serie = this.state.vso.serie;
        var prm = {};
        prm.req = "pos.pos_ecom_vkod.vkod_serie_upd";
        prm.serie = serie;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret));
                if(ret.ok == "000") {
                    alert(JSON.stringify(ret));
                }
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    vkod_edit(vpo) {
        var self = this;
        var wnds = {};
        wnds.vkodedit = 1;
        wnds.vpo = vpo;
        self.setState({ wnds: wnds });
    }
    sel_fkn(sel) {
        //alert(JSON.stringify(fkn));
        if(sel.fkn == "create") {
            this.vkod_gen();
        }
        if(sel.fkn == "activate") {
            this.vkod_serie_upd();
        }
        if(sel.fkn == "deactivate") {
            //this.vkod_serie_deact();
        }
        if(sel.fkn == "export") {
            this.vserie_export();
        }
        if(sel.fkn == "clear") {
            //this.vserie_clear();
        }
    }
    vkod_add() {
        var self = this;
        var wnds = {};
        wnds.vkodadd = 1;
        wnds.vso = this.state.vso;
        self.setState({ wnds: wnds });
    }
    vkod_gen() {
        var self = this;
        var wnds = {};
        wnds.vkodgen = 1;
        wnds.vso = this.state.vso;
        self.setState({ wnds: wnds });
    }

  render() {
    //if(!this.props.show) return "";
    var title = this.props.title;
    if(!title) title = "Resurs";

	var html =
        <div className="mx_base">
        <div className="m2_backdrop" />
        <div className="m2_dlg mx_90 flex-col" style={ this.dlg_style() }>
        <div className="mx_content flex">

        <div className="mx_h01">
            <div>Serie: {this.state.vso.serie}</div>
        </div>
            <div className="flex-row" style={{padding:"5px",height:"35px"}}>
                    <div className="flex-col flex">
                    </div>
                    <div className="flex-col">
                        <div>UPPDATERA</div>
                    </div>
            </div>
            <div className="divider_orange"></div>

            <div>
            <form className="cdi_f03">
                <div className="flex-row">
                    <Fr_Input label="Text" className="text-left w350" text={this.state.vso.text} getValue={(e)=> this.val_save("text", e) } />
                    <Fr_Input label="Belopp" className="text-left w250" text={this.state.vso.belopp} getValue={(e)=> this.val_save("belopp", e) } />
                </div>
                <div className="flex-row">
                    <Fr_Input label="Expire" className="text-left w250" text={this.state.vso.expire} getValue={(e)=> this.val_save("expire", e) } />
                </div>
            </form>

                <div className="divider_green"></div>
                <div className="web-row">
                    <table className="table table-bordered table-hover tb fixed_header">
                        <thead>
                        <tr>
                            <th>Kod</th>
                            <th>Text</th>
                            <th>State</th>
                            <th>Procent</th>
                            <th>Belopp</th>
                            <th>Använt</th>
                            <th>Notanr</th>
                            <th>Status</th>
                        </tr>
                        </thead>
                        <tbody style={ this.tb_style() }>
                        { this.state.vpa.map((vpo, key) =>
                            <tr key={key} onDoubleClick={ () => this.vkod_edit(vpo) }>
                                <td>{ vpo.rkod }</td>
                                <td>{ vpo.text }</td>
                                <td>{ vpo.state }</td>
                                <td>{ vpo.rproc }</td>
                                <td>{ vpo.belopp }</td>
                                <td>{ vpo.used }</td>
                                <td>{ vpo.notanr }</td>
                                <td>{ vpo.status }</td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
        <div className="mx_foot flex-row">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex"></div>
            <button className="btn btn-hw-blue w250" onClick={() => this.vserie_del() } >
                Ta BORT
            </button>
            <Dd_Sel up items={ this.state.fknitems } getValue={(e)=> this.sel_fkn(e)} />
            <button className="btn btn-hw-blue w250" onClick={() => this.vkod_add() } >
                LÄGG TILL
            </button>
            <div className="flex"></div>
            <button className="btn btn-hw-green w250" onClick={() => this.vserie_upd() } >
                SPARA
            </button>
        </div>
      </div>

            { this.state.wnds.vkodedit ? <Vkod_Edit wnds={this.state.wnds} store={this.store} ctx={this} /> : null}
            { this.state.wnds.vkodadd ? <Vkod_Add wnds={this.state.wnds} store={this.store} ctx={this} /> : null}
            { this.state.wnds.vkodgen ? <Vkod_Gen wnds={this.state.wnds} store={this.store} ctx={this} /> : null}
        <Wnd_Error msg={this.state.msg} ctx={this} />
        <Wnd_Ok msg={this.state.msg} ctx={this} />
        <Wnd_Info msg={this.state.msg} ctx={this} />
        <Wnd_Confirm msg={this.state.msg} ctx={this} />
      </div>
	;
	return html;
  };
}

export default VSerie_Edit;