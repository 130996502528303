import React, { useState, useEffect, useContext } from 'react';
import {format, getHours, subDays} from "date-fns";

import * as net from "../../../lib/net";
import Dt_Cal from "../../../lib/ui/dt_cal";

import Export_Spec from "./export/spec";
import Export_Format from "./export/format";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Bi_Export extends React.Component {
  constructor(props, context) {
    super(props);

    this.dt_dt = React.createRef();

    this.store = props.store;
    this.pos = props.store.pos;

    this.state = { pgid: 11, tra: [] };
    this.state.avd = "01";
    this.state.tra = [];
    this.state.wnds = {};

    this.state.today = new Date();
    var hh = getHours(this.state.today);
    if( hh < 5) {
        const dt = subDays(new Date(), 1);
        this.state.dts = format(dt, "yyyy-MM-dd");
    }
    else this.state.dts = format(this.state.today, "yyyy-MM-dd");
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.trs();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

  dt_dat(e) {
    //alert(JSON.stringify(e));
    var dts = format(e, "yyyy-MM-dd");
    this.setState({ dts: dts });
  }
  addday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_dt.current.updateAdd();
  }
  subday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_dt.current.updateSub();
  }

  trs() {
    var self = this;

    var datum = this.state.dts;
    //datum = "20210209";

    	var prm = {};
     	prm.req = "pos.pos_trs.trs_per";
     	prm.sid = "S0003101";
     	//prm.sid = "S0000091";
   		prm.avd = this.state.avd;
   		prm.datum = datum;
   		prm.fdat = datum;
   		prm.tdat = datum;
   		prm.favd = this.state.avd;
   		prm.tavd = this.state.avd;

        net.sid_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret.rco));

            self.setState({ tra: ret.rca });
            //toastr.success("OK", "SAPI");
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
            //gda.wndError(txt);
        });
  }

    row_proc(row) {
      var proc = 0;
      if(this.state.sdo.tot.fsg > 0) {
          proc = (row.fsg / this.state.sdo.tot.fsg) * 100;
          proc = Math.round(proc);
      }
      return proc + " %";
    }
    row_wday(row) {
      var day = "";
      if(row.wday == 1) day = "Måndag";
      if(row.wday == 2) day = "Tisdag";
      if(row.wday == 3) day = "Onsdag";
      if(row.wday == 4) day = "Torsdag";
      if(row.wday == 5) day = "Fredag";
      if(row.wday == 6) day = "Lördag";
      if(row.wday == 7) day = "Söndag";
      return day;
    }
  export() {
      var self = this;
      var wnds = {};
      wnds.format = true;
      wnds.datum = this.state.dts;
      //wnds.tra = this.state.bqa;
      wnds.backdrop = true;
      self.setState({wnds: wnds });
  }
  row_edit(row) {
  }
  export_spec() {
      var self = this;
      var wnds = {};
      wnds.spec = true;
      wnds.datum = this.state.dts;
      //wnds.tra = this.state.bqa;
      wnds.backdrop = true;
      self.setState({wnds: wnds });
  }

  render() {
    return (
    <div className="web-p00 flex flex-col">

    <div className="web-p01">
    <div className="flex-row">
        <h5>EXPORT</h5>
        <div className="flex"></div>
        <div className="flex-row">
              <div className="dt-btn-01" onClick={() => this.subday() } ><FontAwesomeIcon icon="minus" size="1x" /></div>
              <Dt_Cal dts={this.state.dts} rp="1" ref={this.dt_dt} getValue={(e)=> this.dt_dat(e)} />
              <div className="dt-btn-01" onClick={() => this.addday() } ><FontAwesomeIcon icon="plus" size="1x" /></div>
        </div>

        <div className="flex"></div>
        <button className="btn btn-hw-blue w150" onClick={() => this.export() } aria-label="System" >
            EXPORT
        </button>
        <button className="btn btn-hw-blue w150" onClick={() => this.export_spec() } aria-label="System" >
            SPECIFIKATION
        </button>
        <button className="btn btn-hw-blue w150" onClick={() => this.trs() } aria-label="System" >
            VISA
        </button>
    </div>
    <div className="divider_orange"></div>

    <div className="web-row">
        <table className="table table-bordered table-hover tb fixed_header">
            <thead>
            <tr>
                <th>Datum</th>
                <th>Notanr</th>
                <th>Gäster</th>
                <th>Typ</th>
                <th>Text</th>
                <th>Antal</th>
                <th>Pris</th>
                <th>Moms</th>
                <th>Debit/Kredit</th>
                <th>Bong</th>
                <th>Bongtid</th>
                <th>Starttid</th>
                <th>Leveranstid</th>
                <th>Gästtid</th>
                <th>Status</th>
            </tr>
            </thead>
            <tbody style={ this.tb_style() }>
            { this.state.tra.map((row, key) =>
            <tr key={ key } onDoubleClick={() => this.row_edit(row) }>
                <td>{ row.r0datum.cdidate() }</td>
                <td>{ row.r0faktnr.ztrim() }</td>
                <td>{ row.r0noof.ztrim() }</td>
                <td>{ row.r0ttyp }</td>
                <td>{ row.r0text }</td>
                <td>{ row.r0noof.ztrim() }</td>
                <td className="text-right">{ row.r0perprice.cdipris() }</td>
                <td>{ row.r0vatproc }</td>
                <td>{ row.r0debcre }</td>
                <td>{ row.r0init }</td>
                <td>{ row.r0time.cditid() }</td>
                <td>{ row.r0time.cditid() }</td>
                <td>{ row.r0time.cditid() }</td>
                <td>{ row.r0time.cditid() }</td>
                <td>{ row.r0guestno }</td>
            </tr>
            )}
            </tbody>
        </table>
    </div>

    </div>
        { this.state.wnds.spec ? <Export_Spec wnds={this.state.wnds} ctx={this} /> : null}
        { this.state.wnds.format ? <Export_Format wnds={this.state.wnds} ctx={this} /> : null}
    </div>
  );
    }
}
export default Bi_Export;
