import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../../lib/net";
import Fr_Input from "../../../lib/ui/fr_input";
import Lang from "../../../lib/lang";
import * as utils from "../../../assets/utils";
import Td_Sel from "../../../lib/ui/td_sel";
import Fr_Select from "../../../lib/ui/fr_select";

class Plu_ingred extends React.Component {
  constructor(props, context) {
    super(props);

    this.pos = props.store.pos;
    this.state = {};
    this.state.ynq = [];
    this.state.sval = [];

    this.state.dao = {};
      this.state.cval = [
          {id: '001', text: 'ANTAL GER NYTT PRIS'},
          {id: '002', text: 'ANTAL GER RABATT PÅ PRIS'},
          {id: '003', text: 'PRISÄNDRING GENOM RABATT'}
      ];
      this.state.ynq = [
          {id: '0', text: 'NEJ'},
          {id: '1', text: 'JA'}
      ];
      this.state.fastval = [
          {id: '0', text: 'VALBAR'},
          {id: '1', text: 'FAST/LÅST'}
      ];
      this.state.typval = [
          {id: '0', text: 'INGREDIENS'},
          {id: '1', text: 'INFORMATION'}
      ];

    this.state.hpo = {};
      this.state.hap = {};
      this.state.pbo = {};
      this.state.igb = {};
      this.state.igo = {};
      this.state.iga = [];
      //if(props.wnds.igo) this.state.igo = props.wnds.igo;
      if(props.wnds.igo) {
          this.state.igb = props.wnds.igo;
          this.state.igo = JSON.parse(JSON.stringify(props.wnds.igo));
          this.state.igo.p0text = this.state.igo.p0text.cdi2txt();
      }
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.ingreds();
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    tab_style(tabid) {
      var css = {};
      css.display = "none";
      if(this.state.tabval === tabid) css.display = "block";
      return css;
    }
    tb_style() {
        var css = {};
        css.height = (window.innerHeight * 0.8) - 150;
        css.overflow = "auto";
        return css;
    }
    th_style(id) {
        var css = {};
        css.cursor = "pointer";
        if(id == this.state.sortid) {
            if(this.state.sortdir) css.color = "#800";
            else css.color = "#080";
        }
        return css;
    }
    row_style(row) {
        var srow = this.state.selrow;

        var css = {};
        if(srow == row) {
            css.background = "#080";
            css.color = "#fff";
        }
        return css;
    }

    wnd_close = () => {
		this.props.ctx.setState({ wnds: {} });
	};

  ingreds() {
        var self = this;
        var prm = {};
        prm.req = "pos.pos_ingred.ingred_list";

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.rca));

                self.setState({ iga: ret.rca });
                //self.setState({ rva: ret.rca });
                self.syncTb("ingsTable");
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    syncTb(tb) {
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
            tdElements = tbel.getElementsByTagName('td');
        if(tdElements.length < 1 ) return;

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
                width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
    sel_row(row) {
        var srow = this.state.selrow;
        if(srow == row) {
            this.setState({selrow: {}});
        }
        else this.setState({selrow: row});
    }
    tb_sort(typ) {
        var self = this;
        //var rvs = self.state.rvs;
        var rva = self.state.rva;
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;

        if(sortid != typ) sortdir = 0;
        else {
            if(sortdir) sortdir = 0;
            else sortdir = 1;
        }

        if(typ == "ank") {
            if(sortdir) rva.sort((a,b) => (a.gsb00.r0ankdatum < b.gsb00.r0ankdatum) ? 1 : ((b.gsb00.r0ankdatum < a.gsb00.r0ankdatum) ? -1 : 0));
            else rva.sort((a,b) => (a.gsb00.r0ankdatum > b.gsb00.r0ankdatum) ? 1 : ((b.gsb00.r0ankdatum > a.gsb00.r0ankdatum) ? -1 : 0));
        }
        self.setState({ rva: rva, sortid: typ, sortdir: sortdir });
    }
    th_icon(typ) {
        var cls = "";
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;
        if(sortid == typ)
        {
            if(sortdir) cls = "fa fa-caret-up";
            else cls = "fa fa-caret-down";
        }
        return cls;
    }
    pluing_save() {
        var self = this;

        var prm = {};
        prm.req = "pos.pos_ingred.pluing_upd";
        prm.plo = this.state.igo;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret));
                if(self.props.wnds.cb) {
                    self.props.wnds.cb(self.props.ctx);
                }
                else self.props.ctx.setState({ wnds: {} });
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    pluing_del() {
        var self = this;

        var prm = {};
        prm.req = "pos.pos_ingred.pluing_del";
        prm.plo = this.state.igo;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret));
                if(self.props.wnds.cb) {
                    self.props.wnds.cb(self.props.ctx);
                }
                else self.props.ctx.setState({ wnds: {} });
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    val_save(id, val) {
        var igo = this.state.igo;
        igo[id] = val;
        this.setState({ igo: igo });
        return;
    }

  render() {
    var title = this.props.title;
    if(!title) title = "Knappar";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        <div className="m2_backdrop" />
        <div className="m2_dlg mx_70h flex-col" style={ this.dlg_style() } >
        <div className="mx_content flex">

            <div className="mx_h01">
                <div>INGREDIENS Löpnr : { utils.toNum( this.state.igo.p0lopnr ) }</div>
            </div>
            <div className="divider_orange"></div>

            <form className="cdi_f03">
                <div className="flex-row">
                    <Fr_Input label="Ingrediens Artikel" className="text-left w150" text={this.state.igo.p0ingnr} getValue={(e)=> this.val_save("p0artid", e) } />
                </div>
                <div className="flex-row">
                    <Fr_Input label="Text" className="text-left w350" text={this.state.igo.p0text} getValue={(e)=> this.val_save("p0text", e) } />
                </div>
                <div className="flex-row">
                    <Fr_Input label="Pris" className="text-left w250" text={this.state.igo.p0pris} getValue={(e)=> this.val_save("p0pris", e) } />
                </div>
                <div className="flex-row">
                    <Fr_Input label="Moms" className="text-left w150" text={this.state.igo.p0moms} getValue={(e)=> this.val_save("p0pris", e) } />
                </div>
                <div className="divider_green"></div>
                <div className="flex-row">
                    <Fr_Select label="Typ" className="text-left w250" text={this.state.igo.p0form} items={this.state.typval} getValue={(e)=> this.val_save("p0form", e) } />
                </div>
                <div className="flex-row">
                    <Fr_Select label="Fast/Ej valbar" className="text-left w150" text={this.state.igo.p0fast} items={this.state.fastval} getValue={(e)=> this.val_save("p0fast", e) } />
                </div>
                <div className="flex-row">
                    <Fr_Input label="Sortering" className="text-left w150" text={this.state.igo.p0sort} getValue={(e)=> this.val_save("p0sort", e) } />
                </div>
                <div className="divider_green"></div>
                <div className="flex-row">
                    <Fr_Input label="Debit/Kredit" className="text-left w150" text={this.state.igo.p0debcre} getValue={(e)=> this.val_save("p0debcre", e) } />
                </div>
                <div className="flex-row">
                    <Fr_Input label="Status" className="text-left w150" text={this.state.igo.status} getValue={(e)=> this.val_save("status", e) } />
                </div>

            </form>

        </div>
        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <button className="btn btn-hw-orange w250" onClick={() => this.pluing_del() } >
                TA BORT
            </button>
            <button className="btn btn-hw-green w250" onClick={() => this.pluing_save() } >
                SPARA
            </button>
        </div>
      </div>
      </div>
	;
	return html;
  };
}

export default Plu_ingred;