import React, { useState, useEffect, useContext } from 'react';
import {format, addDays, subDays} from "date-fns";

import * as net from "../../../lib/net";
import Fr_Input from "../../../lib/ui/fr_input";

class Zon_Edit extends React.Component {
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pos = props.store.pos;

    this.state = {};
    this.state.today = new Date();
    this.state.rta = [];
    this.state.rsa = [];

    this.state.gnr = "001";
    this.state.gso = {};

    this.state.ynq = [
                {id: '0', text: 'NEJ'},
                {id: '1', text: 'JA'}
            ];

    this.state.rs = "";
    this.state.zso = {};
    this.state.background = "#fff";

    this.state.wnds = {};
    if(props.wnds) {
        this.state.zso = props.wnds.zso;
   }
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.zso_init();
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    grp_style(row) {
      var css = {};
      css.background = "#008";
      if(this.state.gnr === row.vgrupp) css.background = "#080";
      return css;
    }

    formchange (e) {
      const name = e.target.name;
      const value = e.target.value;
      var pbo = this.state.pbo;
      var na = name.split(".");
      pbo[na[0]][na[1]] = value;
      this.setState({pbo: pbo});
      //setState({[name]: value});
    }

    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    wnd_close = () => {
      if(this.props.cb) {
        var ret = {};
        ret.ok = "999";
        this.props.cb(this.props.ctx, ret);
      }
      else this.props.ctx.setState({ wnds: {} });
	};

  submitHandler = event => {
    event.preventDefault();
    event.target.className += " was-validated";
  };

    val_save (name, value) {
        //alert(JSON.stringify(name));
        //alert(JSON.stringify(value));
      var zso = this.state.zso;
      zso[name] = value;
      this.setState({zso: zso});
    }
    cb_save (name, e) {
      const value = e.target.value;
      var bro = this.state.bro;
      bro[name] = value;
      this.setState({bro: bro});
      //setState({[name]: value});
    }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }

  zso_init() {
    var self = this;

    var zso = {};
    zso.zonid = "";
    zso.text = "";
    zso.resursordn = "";
    zso.ftid = "";
    zso.ttid = "";
    zso.max = "";
    zso.status = "";
    this.setState({zso: zso});
  }

    zon_save() {
        var self = this;
        var prm = {};
        prm.req = "pos.pos_zon.zon_upd";
        prm.zso = this.state.zso;

        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret));

          if(self.props.wnds.cb) {
            var ret = {};
            ret.ok = "000";
            self.props.wnds.cb(self.props.ctx, ret);
          }
          else self.props.ctx.setState({ wnds: {} });
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }
    zon_del() {
        var self = this;
        var prm = {};
        prm.req = "pos.pos_zon.zon_del";
        prm.zso = this.state.zso;

        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret));

            if(self.props.wnds.cb) {
                var ret = {};
                ret.ok = "000";
                self.props.wnds.cb(self.props.ctx, ret);
            }
            else self.props.ctx.setState({ wnds: {} });
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }

  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "Gäst";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        <div className="m3_backdrop" />
        <div className="m3_dlg mx_70 flex-col" style={ this.dlg_style() } >

        <div className="mx_h01">
            <div>Zon: {this.state.zso.zonid}</div>
        </div>
        <div className="mx_content flex">

            <div className="divider_orange"></div>

            <form className="cdi_f03">
            <div className="flex-row py-2">
                    <Fr_Input label="Text" className="text-left w350" text={this.state.zso.text} getValue={(e)=> this.val_save("text", e) } />
            </div>
            <div className="flex-row py-2">
                    <Fr_Input label="Från Bord" className="text-left w250" text={this.state.zso.fbord} getValue={(e)=> this.val_save("fbord", e) } />
                    <Fr_Input label="Till Bord" className="text-left w250" text={this.state.zso.tbord} getValue={(e)=> this.val_save("tbord", e) } />
            </div>
            <div className="flex-row py-2">
                    <Fr_Input label="Från Tid" className="text-left w150" text={this.state.zso.ftid} getValue={(e)=> this.val_save("ftid", e) } />
                    <Fr_Input label="Till Tid" className="text-left w150" text={this.state.zso.ttid} getValue={(e)=> this.val_save("ttid", e) } />
            </div>
             <div className="divider_green"></div>
            <div className="flex-row py-2">
                    <Fr_Input label="Max" className="text-left w250" text={this.state.zso.max} getValue={(e)=> this.val_save("max", e) } />
            </div>
            <div className="flex-row py-2">
                    <Fr_Input label="Status" className="text-left w150" text={this.state.zso.status} getValue={(e)=> this.val_save("status", e) } />
            </div>
            <div className="divider_green"></div>
            </form>
        </div>

        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                AVBRYT
            </button>
            <div className="flex-row"></div>
            <button className="btn btn-hw-orange w250" onClick={() => this.zon_del() } >
                TA BORT
            </button>
                <div className="flex-row"></div>
                <button className="btn btn-hw-green w250" onClick={() => this.zon_save() } >
                    SPARA
                </button>
        </div>
      </div>

      </div>
	;
	return html;
  };
}

export default Zon_Edit;
