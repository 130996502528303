import React, { useState, useEffect, useContext } from 'react';
import * as net from "../../../../lib/net";
import Dt_Cal from '../../../../lib/ui/dt_cal';
import {format, subDays} from "date-fns";

import Fr_Input from "../../../../lib/ui/fr_input";
import Wnd_Error from "../../../../lib/ui/wnd_error";
import Wnd_Ok from "../../../../lib/ui/wnd_ok";
import Wnd_Info from "../../../../lib/ui/wnd_info";
import Wnd_Confirm from "../../../../lib/ui/wnd_confirm";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Krub_Recov extends React.Component {
  constructor(props, context) {
    super(props);

    this.dt_fd = React.createRef();
    this.dt_td = React.createRef();

    this.pms = props.store.pms;
    this.state = {};
    this.state.today = new Date();
    this.state.lsa = [];
    this.state.lso = {};
    //this.state.lro = props.lro;
    this.state.tya = [
                {id: '01', text: 'Summerad'},
                {id: '02', text: 'Dag för dag'}
            ];
    this.state.dea = [
                {id: '01', text: 'Definition v1'},
                {id: '02', text: 'Definition v2'}
            ];
      this.state.msg = {};
      this.state.cols = {};
    this.state.lrwrk = false;
    //context.posrend = this;
    this.state.start = 0;
    this.state.fdat = "";
    this.state.tdat = "";
    this.state.typ = "";
    this.state.def = "";
    this.state.loading = 0;
    if(this.pms.base) {
        this.state.fdat = this.pms.base.fdat;
        this.state.tdat = this.pms.base.tdat;
    }
    if(props.wnds) {
      if(props.wnds.fdat) this.state.fdat = props.wnds.fdat;
      if(props.wnds.tdat) this.state.tdat = props.wnds.tdat;
    }
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.dlg_init();
  }

  dlg_init() {
      var dat = this.state.today;
      dat = subDays(dat, 1);
      var dts = format(dat, "yyyy-MM-dd");
      var state = this.state;
      state.fdat = dts;
      state.tdat = dts;
      state.favd = "01";
      state.tavd = "01";
      state.typ = "01";
      state.def = "01";
      state.loading = 0;
    this.setState(state);
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    tab_style(tabid) {
      var css = {};
      css.display = "none";
      if(this.state.tabval === tabid) css.display = "block";
      return css;
    }
    tab_head(tabid) {
      var css = {};
      css.background = "#fff";
      css.color = "#000";
      if(this.state.tabval === tabid) {
          css.background = "#080";
          css.color = "#fff";
      }
      return css;
    }
  change_typ(e) {
    //let cdat = this.state.seldate;
    let typ = e.target.value;
    this.setState({typ: typ});
  }
  change_def(e) {
    //let cdat = this.state.seldate;
    let def = e.target.value;
    this.setState({def: def});
  }

  recov_day() {
    var self = this;

      var msg = { info: true, text: "Återställer krubba ..."};
      self.setState({ msg: msg });

    //var fdatum = format(this.state.fdat, "yyyy-MM-dd");
    //var tdatum = format(this.state.tdat, "yyyy-MM-dd");

    var prm = {};
    prm.req = "pms.krub.krub_recov";
    prm.fdat = this.state.fdat;
    prm.tdat = this.state.tdat;
    prm.start = this.state.start;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            self.setState({ msg: {} });
            return;
        }
        self.setState({ msg: {} });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
    }

    rp_mx() {
        var prm = {};
     	prm.req = "pms.rp.bok";
   		prm.fdat = this.state.fdat;
   		prm.tdat = this.state.tdat;
   		prm.typ = this.state.typ;
   		prm.def = this.state.def;

        var mx = {};
     	mx.rp = "bok";
     	mx.prm = prm;

        this.setState({ mx: mx });
    }
    rp_prn() {
        //this.props.ctx.setState({ mr: false });
        //this.setState({ pdf: 1 });
        if(this.state.typ == "02") {
            //this.bok_dts_prn();
            this.bok_totdts_prn();
            return;
        }
        //this.bok_prn();
        this.bok_tot_prn();
    }

    wnd_close = () => {
      if(this.props.close) {
          this.props.close(this.props.ctx);
      }
      else this.props.ctx.setState({ wnds: {} });
	};

  dt_fdat(e) {
    //alert(JSON.stringify(e));
    var dts = format(e, "yyyy-MM-dd");
    this.setState({ fdat: dts });
  }
  dt_tdat(e) {
      //alert(JSON.stringify(e));
    var dts = format(e, "yyyy-MM-dd");
    this.setState({ tdat: dts });
  }
  oneday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_td.current.updateDt(this.state.fdat);
  }
  addday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_fd.current.updateAdd();
    this.dt_td.current.updateAdd();
  }
  subday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_fd.current.updateSub();
    this.dt_td.current.updateSub();
  }

  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  body_style() {
      var css = {};
      css.display = "";
      if( this.state.loading == 1 ) css.display = "none";
      return css;
  }
  cssCols (cid) {
      var css = {};
      if(this.state.cols[cid]) css.width = this.state.cols[cid].toString() + "px";
      //if(cid == "c01") alert(JSON.stringify(this.state.cols[cid]));
      return css;
  }
  syncCols (tb) {

    if (!document.getElementById(tb)) return;

    var cols = {};
    var ix = document.getElementById(tb).rows.length - 1;
    var ic = document.getElementById(tb).rows[0].cells.length;
    var ci = 0;
    while(ci < ic) {
        var cxi = ci + 1;
        var col = "c" + cxi.toString().padStart(2, "0");
        cols[col] = document.getElementById(tb).rows[ix].cells[ci].clientWidth + 1;
        ci = ci + 1;
    }
    this.setState({cols: cols });
    //alert(JSON.stringify(this.state.cols));
    //this.forceUpdate();
  };
  val_save(id, val) {
      var state = this.state;
      state[id] = val;
        this.setState(state);
      return;
  }

  render() {
    var title = this.props.title;
    if(!title) title = "";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="mx_backdrop" /> : null }
        <div className="mx_dlg mx_rp flex-col" style={ this.dlg_style() }>
        <div className="mx_head mx_bg flex-row flex-space-between">
            <div className="flex-col flex">
                <div>Krubba Rekonstruktion</div>
            </div>
            <div className="white-icon-close" onClick={() => this.handleClose() }>X</div>
        </div>
        { this.state.loading ? <div className="mx_content flex align-items-center justify-content-center"><FontAwesomeIcon icon="spinner" spin size="3x" fixed /></div> :
        null }
        <div className="mx_content flex" style={ this.body_style() }>
            <div className="flex-row px-2">
                    <div className="flex-col flex">
                        <div>Start</div>
                    </div>
                    <div className="flex-col flex">
                        <div></div>
                    </div>
                    <div className="flex-col w50">
                        <div>Ändra</div>
                    </div>
            </div>
            <div className="divider_orange"></div>

        <div className="mx_body flex">

            <div className="flex-row">
              <div className="flex-col">
                  <span>&nbsp;</span>
                  <div className="dt-btn-01" onClick={() => this.subday() } ><FontAwesomeIcon icon="minus" size="1x" /></div>
            </div>
              <div className="flex-col">
                  <span>Från</span>
                  <Dt_Cal dts={ this.state.fdat }  rp="1" ref={this.dt_fd} getValue={(e)=> this.dt_fdat(e)} />
            </div>
               <div className="flex-col">
                  <span>&nbsp;</span>
                  <div className="dt-btn-01" onClick={() => this.oneday() } >1</div>
            </div>
             <div className="flex-col">
                  <span>Till</span>
                  <Dt_Cal dts={ this.state.tdat }  rp="1" ref={this.dt_td} getValue={(e)=> this.dt_tdat(e)} />
            </div>
              <div className="flex-col">
                  <span>&nbsp;</span>
                  <div className="dt-btn-01" onClick={() => this.addday() } ><FontAwesomeIcon icon="plus" size="1x" /></div>
            </div>
            </div>

            <div className="flex-row">
              <div className="flex-col">
                  <Fr_Input col label="Start saldo" className="text-left w250" text={this.state.start} getValue={(e)=> this.val_save("start", e) } />
            </div>
            </div>

        </div>

        </div>


        <div className="mx_foot flex-row">
            <div className="flex-row flex">
                <div className="btn-rp" onClick={() => this.wnd_close() } >
                    <div>STÄNG</div>
                    <div><FontAwesomeIcon icon="times" size="2x" /></div>
                </div>
            </div>
            <div className="flex-row">
                <div className="btn-rp" onClick={() => this.recov_day() } >
                    <div>ÅTERSTÄLL</div>
                    <div><FontAwesomeIcon icon="print" size="2x" /></div>
                </div>
            </div>
        </div>

      </div>

            <Wnd_Error msg={this.state.msg} ctx={this} />
            <Wnd_Ok msg={this.state.msg} ctx={this} />
            <Wnd_Info msg={this.state.msg} ctx={this} />
            <Wnd_Confirm msg={this.state.msg} ctx={this} />

    </div>
	;
	return html;
  };
}

export default Krub_Recov;