import React, { useState, useEffect, useContext } from 'react';
import ReactDOM from 'react-dom';
import { SDOContext } from '../sdo.js'
import { Carousel } from 'react-bootstrap'

import './css/animate.css';
import './css/carousel.css';

import { ReactComponent as Ico_Like } from './ico/like.svg';
import { ReactComponent as Ico_Group } from './ico/group.svg';
import PdfDlg from "./dlg/pdf_dlg";
import LoginDlg from "./dlg/login_dlg";
import {withRouter} from "../lib/react/withRouter";

function web01() {

    var html =
    <div className="">
        Test
    </div>
    ;
    return html;
}
function web_ct05() {

    var html =
<Carousel fade="true" interval="5000">
  <Carousel.Item>
    <div class="item-image" style={{
      background: "url(./img/pax01.jpg) top right no-repeat",
      "-webkit-background-size": "cover",
      "background-size": "cover"
    }} />
    <Carousel.Caption className="flex-caption">
      <h3>MÄSSA</h3>
      <p>Möt oss på Restaurang Expo Malmö 29-30/1 på Malmömässan</p>
  </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <div className="item-image" style={{
      background: "url(./img/pax02.png) top right no-repeat",
      "-webkit-background-size": "cover",
      "background-size": "cover"
    }}/>

    <Carousel.Caption className="flex-caption">
      <h3>SVERIGEKASSAN A02</h3>
      <p>Samtliga kassafunktioner i handen</p>
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <div className="item-image" style={{
      background: "url(../img/svepos.png) top right no-repeat",
      "-webkit-background-size": "cover",
      "background-size": "cover"
    }}/>

    <Carousel.Caption className="flex-caption">
      <h3>SVEPOS</h3>
      <p>Simplifies Business</p>
    </Carousel.Caption>
  </Carousel.Item>
</Carousel>
        ;
    return html;
}

class Web extends React.Component {
    //var sdo = useContext(SDOContext); // Functional
    static contextType = SDOContext; // Class

    constructor(props, context) {
        super(props);

        this.s1ref = React.createRef();
        this.s2ref = React.createRef();

        this.webStore = props.store;
        this.state = { pgid: 11, stat: [] };
        this.state.open = false;
        this.state.pdfopen = false;
        this.state.xxopen = false;
        this.state.pdf_uri = "";
        this.state.seldate = new Date('2020-03-24T13:11:54');
        this.state.row = null;
        this.state.info = "";
        this.state.top = 1;
        this.state.sdo = {};
        //this.state.sdo = context;
        //context.posrend = this;
        this.state.step = 1;
        this.state.pdf = 0;
        this.state.pdf_uri = "";
        this.state.mnus = ["m00", "m01", "m02", "m03", "m08", "m09"];
        this.state.mnu_sel = "m00";
    }
    componentDidMount(){
        //var sdo = sessionStorage.getItem("svp");
        //if(sdo) this.setState({sdo: JSON.parse(sdo) });
        //this.cdi_step();
    }

    componentWillUnmount(){
            this.setState({info: "UUU" });
    }
    onScroll(event) {
        var body = document.getElementById("web-body");
        if (body.scrollTop > 150) {
          this.setState({top: 0})
          this.setState({info: 'small'})
        } else {
          this.setState({top: 1})
          this.setState({info: 'big:'+body.scrollTop})
        }

        if (true) {
            var offset = body.scrollTop;
            var start = body.scrollTop;
            var slut = body.scrollTop + window.innerHeight;

            for(var mnuid of this.state.mnus) {
                const element = document.getElementById(mnuid);
                if(!element || (element == null) || (element == undefined)) continue;
                var eltop = element.offsetTop;
                var elbot = element.offsetTop + element.offsetHeight;
                var brc = eltop >= start && eltop <= slut;
                //if(!brc) brc = elbot >= start && elbot <= slut;
                if(brc) {
                    this.setState({mnu_sel: mnuid});
                    return;
                }
            }
        }
    }
   header_css() {
  	    var css = {};
		if(this.state.top) {
			css.color = "#fff";
			css.background = "transparent";
			css.height = "50px";
			//css["background"] = "green";
		}
		else {
			css.color = "#000";
			css.background = "#fff";
		    css.height = "50px";
        }

		return css;
    }
   ham_mnu_css() {
  	    var css = {};
		if(this.state.top) {
			css.display = "flex";
			css.display = "none";
		}
		else {
			css.display = "none";
        }

		return css;
    }
    header_class() {
  	    var cls = "flex_col";
		if(this.state.top) {
			cls = cls + " web-head_1";
		}
		else cls = cls +  " web-header";

		return cls;
    }

    sdo_upd() {
        var sdo = this.state.sdo;
        if(!sdo.fid) sdo.fid = "0";
        sdo.fid = ((sdo.fid * 1) + 1).toString();
        this.setState({sdo: sdo})
        sessionStorage.setItem("svp", JSON.stringify(this.state.sdo));
        //this.props.history.push("cas");
    }
    sdo_ctx() {
        sessionStorage.removeItem('svp');
        //sessionStorage.clear();
    }
    sdo_cas() {
        this.context.fid = "45";
        this.props.navigate("cas");
    }
    ct_show(id) {
        //alert(id);
        const element = document.getElementById(id);
        if (element) element.scrollIntoView();
        //const tesNode = ReactDOM.findDOMNode(id);
        //window.scrollTo(0, tesNode.offsetTop);
    }
    w00_css() {
  	    var css = {};
        css.height = window.innerHeight;
        css['minHeight'] = window.innerHeight;
		return css;
    }
    cdi_step() {
        //alert(id);
        var step = this.state.step + 1;
        if(step > 2) step = 1;
        this.setState({ step: step });
        if(step == 1) {
            var element = ReactDOM.findDOMNode(this.s1ref.current);
            element.classList.add("animated", "rotateIn");
            element.addEventListener('animationend', function() {
                element.classList.remove('animated', "rotateIn")
                //node.removeEventListener('animationend', handleAnimationEnd)
            })
        }
        if(step == 2) {
            var element = ReactDOM.findDOMNode(this.s2ref.current);
            element.classList.add("animated", "bounceIn");
            element.addEventListener('animationend', function() {
                element.classList.remove('animated', "bounceIn")
                //node.removeEventListener('animationend', handleAnimationEnd)
            })
        }

         setTimeout(() => {
            this.cdi_step();
        }, 5000);
    }
    cdi_show(id) {
        //alert(id);
        var brc = false;
        if(this.state.step == id) brc = true;
        return brc;
    }
    cdi_slide(id) {
        //alert(id);
  	    var css = {};
        //css.display = "none";
        css.width = "150px";
        css.height = "150px";
        css.color = "#fff";
        css.display = "none";
        if(id == "s1") {
            css.background = "#080";
            if(this.state.step == 1) css.display = "block";
        }
        if(id == "s2") {
            css.background = "#008";
            if(this.state.step == 2) css.display = "block";
        }
		return css;
    }

    mnu_sel(mnuid) {

        this.setState({mnu_sel: mnuid});
        const element = document.getElementById(mnuid);
        if (element) element.scrollIntoView();
    }

   mnu_item_css(mnuid) {
  	    var css = {};

        if(this.state.mnu_sel == mnuid) {
			///css.background = "#080";
			//css.color = "#fff";
			css.color = "#080";
        }
		return css;
    }
   xmnu_item_css(mnuid) {
  	    var css = {};
        var body = document.getElementById("web-body");
        if(!body) {
		    return css;
		}

        var offset = body.scrollTop;
        var start = body.scrollTop;
        var slut = body.scrollTop + window.innerHeight;

        const element = document.getElementById(mnuid);
        const top = element.getBoundingClientRect().top;
        var eltop = element.offsetTop;
        var elbot = element.offsetTop + element.offsetHeight;
        var brc = eltop >= start && eltop <= slut;
        if(!brc) brc = elbot >= start && elbot <= slut;
        //const brc = (top + offset) >= 0 && (top - offset) <= body.scrollHeight;

		if(brc) {
			css.background = "#080";
			css.color = "#fff";
		}

		return css;
    }

    row_css(row) {
  	    var css = {};
		if(this.state.row) {
		if(this.state.row.ank == row.ank) {
			css.background = "#080";
			css.color = "#fff";
		}
		}
		return css;
    }
    login() {
        this.setState({login: true });
        //this.props.history.push("pos");
    }
    pdf_show(id) {
        this.setState({ pdf: id });
        return;
    }
    pdf_fetch(id) {
       var headers = new Headers({'Content-Type': 'application/pdf'});
       var options = {
            method: 'GET',
            headers: headers,
            mode: 'no-cors',
            cache: 'default'
        };
        //var pdf_url = "http://pub.svepos.se/docs/marknad/c100.pdf";
        var pdf_url = "/docs/c100.pdf";
        //alert(pdf_url);
        //fetch(pdf_url, options)
        fetch(pdf_url)
        .then(response => response.blob())
        .then(data => {
            //alert(data);
            //alert(response.data);
            var pdf_uri = "data:application/pdf;base64," + data;
            //alert(JSON.stringify(pdf_uri));
            this.setState({ pdf: 1, pdf_uri: pdf_uri })
        });

        //this.setState({ pdf: 1 });
		return;
    }

    render() {
        var stat = [];
        var keys = Object.keys(this.state.stat);
        for (var key of keys) {
            var row = this.state.stat[key];
            stat.push(row);
        }
        const handleDateChange = date => {
            this.setState({seldate: date });
        };

        return (
            <div className="web-app">
            <div id="header" className={ this.header_class() }>
            <div className="web-top-content flex flex-row flex-space-between">
                <div className="web-mnu-bars">
                    <i className="fa fa-bars flex" aria-hidden="true" style={{padding: "10px"}}></i>
                </div>
                <img className="web-logo" src={require('./img/logosvk.svg')} />
                <div className="web-top-mnu flex-row">
                    <div className="item" onClick={() => this.mnu_sel("m00") } style={ this.mnu_item_css("m00") }>Hem</div>
                    <div className="item" onClick={() => this.mnu_sel("m01") } style={ this.mnu_item_css("m01") }>Nyheter</div>
                    <div className="item" onClick={() => this.mnu_sel("m02") } style={ this.mnu_item_css("m02") }>Produkter</div>
                    <div className="item" onClick={() => this.mnu_sel("m03") } style={ this.mnu_item_css("m03") }>Tjänster</div>
                    <div className="item" onClick={() => this.mnu_sel("m08") } style={ this.mnu_item_css("m08") }>Kontakt</div>
                </div>
                <div className="web-top-btn flex-row">
                    <button className="btn btn-hw-blue w150 ripple" onClick={() => this.login() } aria-label="System" >
                        Logga in
                    </button>
                </div>
            </div>
            <div className="ham-mnu web-col" style={ this.ham_mnu_css() }>
                <div className="item" onClick={() => this.mnu_sel("m00") } style={ this.mnu_item_css("m00") }>Hem</div>
                <div className="item" onClick={() => this.mnu_sel("m01") } style={ this.mnu_item_css("m01") }>Nyheter</div>
                <div className="item" onClick={() => this.mnu_sel("m02") } style={ this.mnu_item_css("m02") }>Produkter</div>
                <div className="item" onClick={() => this.mnu_sel("m03") } style={ this.mnu_item_css("m03") }>Tjänster</div>
                <div className="item" onClick={() => this.mnu_sel("m08") } style={ this.mnu_item_css("m08") }>Kontakt</div>
            </div>
            </div>


            <div className="web-body" id="web-body" onScroll={ (event) => this.onScroll(event) }>
            <div id="m00" className="web-s0 web-bg00 web-center" style={ this.w00_css() }>
                <div className="white">
                    <div className="txt_42">SVERIGEKASSAN</div>
                    <div className="txt_32">MOLNBASERAD KASSA LEVERANTÖR FÖR ALLA BRANSCHER</div>
                </div>
            </div>

            <div id="m01" className="web-s0 bg-w05 web-center flex-col">
                <div className="flex-row web-center">
                    <div className="txt_32">NYHETER</div>
                </div>
                <div className="web-row">
                <div className="web-box_01 flex">
                    <div className="web-icon">
                        <i className="fa fa-desktop fa-3x flex" aria-hidden="true" style={{padding: "10px"}}></i>
                    </div>
                    <div className="web-box-title">C100</div>
                    <div className="web-box-body txt_16">
                        Allt i ett SVEPOS kassaprogram i Westpay Carbon100.
                        Svepos Carbon100 har kompletta funktioner
                        för restauranghantering såsom: dela nota,
                        bordhantering mm. Svepos Carbon100 ger er
                        möjlighet att koppla bongskrivare, köksskärmar etc.
                        Då Svepos Carbon100 har steckkodläsare och
                        möjlighet till lagerhantering är det även det perfekta
                        verktyget för butiker och handel.
                    </div>
                    <div className="divider_orange"></div>
                    <div className="txt_16">
                    <button className="btn btn-hw-blue w150 ripple" onClick={() => this.pdf_show("C100") } aria-label="System" >
                        Läs Mer
                    </button>
                    </div>
                </div>
                <div className="web-box_01 flex">
                    <div className="web-icon">
                        <i className="fa fa-desktop fa-3x flex" aria-hidden="true" style={{padding: "10px"}}></i>
                    </div>
                    <div className="web-box-title">A920</div>
                    <div className="web-box-body">
                        Allt i ett SVEPOS kassaprogram i PAX A920.
                        Svepos PAX A920 har kompletta funktioner för
                        restauranghantering såsom: dela nota,
                        bordhantering mm. Svepos PAX A920 ger er
                        möjlighet att koppla bongskrivare, köksskärmar etc.
                        Då Svepos PAX A920 har dubbla kameror för
                        steckkod/QR-kods läsning och möjlighet till
                        lagerhantering är det även det perfekta verktyget för
                        butiker och handel.
                    </div>
                    <div className="divider_orange"></div>
                    <div className="txt_16">
                    <button className="btn btn-hw-blue w150 ripple" onClick={() => this.pdf_show("A920") } aria-label="System" >
                        Läs Mer
                    </button>
                    </div>
                </div>

                </div>
            </div>

            <div id="m0x" className="web-s0 bg-w00 web-center" style={ this.w00_css() }>
                <div className="web-txt-32 white">
                    <div>SVERIGEKASSAN</div>
                    <div>MOLNBASERAD KASSA LEVERANTÖR FÖR ALLA BRANSCHER</div>
                </div>
            </div>

            <div className="web-row">
                <img className="web-img" src={require('./img/frilagds_pckassa.png')} />
                <div className="flex">
                    <div className="txt_32">SVERIGEKASSAN SVEPOS A02</div>
                    <div className="txt_16">
                        Svepos-A02 har kompletta funktioner för
                        restauranghantering såsom: dela nota,
                        bordhantering mm. Med kassaprogrammet Svepos-
                        A02 har ni möjlighet att koppla bongskrivare,
                        köksskärmar etc, kassakopplade kortterminaler och
                        interface till hotellsystem och ekonomiprogram.
                    </div>
                    <button className="btn btn-hw-blue w150 ripple" onClick={() => this.pdf_show("fec_1635") } aria-label="System" >
                        Läs Mer
                    </button>
                </div>
                <div className="flex">
                </div>
            </div>

            <div className="parallax"></div>

            <div id="m02" className="web-content bg-w00 web-center">
                <div className="web-row">
                    <div className="txt_32">PRODUKTER</div>
                </div>
                <div className="web-row">
                <div className="web-box flex">
                    <div className="web-icon">
                        <i className="fa fa-bed fa-3x flex" aria-hidden="true" style={{padding: "10px"}}></i>
                    </div>
                    <div className="web-box-title">KASSASYSTEM</div>
                    <div className="web-box-body h200">
                        Molnbaserade kassalösningar med anpassade lösningar för respektive bransch.
                        Stor fokus på hospitality industrin med möjligheter att leverera kompletta driftlösningar.
                    </div>
                    <div className="web-box-foot">
                        Kontakta oss för mer info...
                        <button className="web-hide btn btn-hw-blue w150 ripple" onClick={() => this.pdf_show("C100") } aria-label="System" >
                            Läs Mer
                        </button>
                    </div>
                </div>
                <div className="web-box flex">
                    <div className="web-icon">
                        <i className="fa fa-desktop fa-3x flex" aria-hidden="true" style={{padding: "10px"}}></i>
                    </div>
                    <div className="web-box-title">APP / ONLINE ORDER</div>
                    <div className="web-box-body h200">
                        Med kassasystemet hel integrerat app/online order och betalningssystem system.
                        Integreras med kunds befintliga hemsida. Gemensam rapportering mm med kassasystem.
                    </div>
                    <div className="web-box-foot">
                        Kontakta oss för mer info...
                    </div>
                </div>
                <div className="web-box flex">
                    <div className="web-icon">
                        <i className="fa fa-clock fa-3x flex" aria-hidden="true" style={{padding: "10px"}}></i>
                    </div>
                    <div className="web-box-title">SELF SERVICE / KIOSK</div>
                    <div className="web-box-body h200">
                        Självbetjänings kassor och kiosker fullt intgrerade med kassasystemet. Gemensam rapportering mm.
                    </div>
                    <div className="web-box-foot">
                        Kontakta oss för mer info...
                    </div>
                </div>
                </div>
            </div>

            <div id="m0y" className="web-content web-center">
                <div className="web-row">
                <div className="web-box flex">
                    <div className="web-icon">
                        <i className="fa fa-bed fa-3x flex" aria-hidden="true" style={{padding: "10px"}}></i>
                    </div>
                    <div className="web-box-title">BORDSBOKNINGSYSTEM</div>
                    <div className="web-box-body h200">
                        Bokningssystem direkt i kassasystemet, integrerat med kunds befintliga hemsida.
                        Skapar kundregister med reella värden, integreras med Svepos Lojalitets system.
                    </div>
                    <div className="web-box-foot">
                        Kontakta oss för mer info...
                    </div>
                </div>
                <div className="web-box flex">
                    <div className="web-icon">
                        <i className="fa fa-desktop fa-3x flex" aria-hidden="true" style={{padding: "10px"}}></i>
                    </div>
                    <div className="web-box-title">FAKTURASYSTEM</div>
                    <div className="web-box-body h200">
                        Fakturering direkt från kassa som landar direkt i reskontran.
                        Fakturor kan skapas i Sverigekassans samtliga driftsystem så väl som direkt i faktureringen.
                        Integrerat med alla ekonomisystem med möjlighet att motta SIE fil.
                    </div>
                    <div className="web-box-foot">
                        Kontakta oss för mer info...
                    </div>
                </div>
                <div className="web-box flex">
                    <div className="web-icon">
                        <i className="fa fa-clock fa-3x flex" aria-hidden="true" style={{padding: "10px"}}></i>
                    </div>
                    <div className="web-box-title">TIDSYSTEM</div>
                    <div className="web-box-body h200">
                        Personal hanteringssystem med planering, lönehantering mm.
                        Fungerar lika bra som enskilt system som fullt integrerat med våra övriga produkter.
                    </div>
                    <div className="web-box-foot">
                        Kontakta oss för mer info...
                    </div>
                </div>
                </div>
            </div>

            <div id="m02" className="web-content bg-w00 web-center">
                <div className="web-row">
                <div className="web-box flex">
                    <div className="web-icon">
                        <i className="fa fa-bed fa-3x flex" aria-hidden="true" style={{padding: "10px"}}></i>
                    </div>
                    <div className="web-box-title">HOTELLSYSTEM</div>
                    <div className="web-box-body h200">
                        CDI hotelsystem moln och webbaserat hotellsystem komplett för modern hotelldrift.
                        Hanterar allt från boutique hotellet till konferensanläggningen likväl som kedjelösningar.
                    </div>
                    <div className="web-box-foot">
                        Kontakta oss för mer info...
                    </div>
                </div>
                <div className="web-box flex">
                    <div className="web-icon">
                        <i className="fa fa-desktop fa-3x flex" aria-hidden="true" style={{padding: "10px"}}></i>
                    </div>
                    <div className="web-box-title">MENY SYSTEM</div>
                    <div className="web-box-body h200">
                        Digital Signage system som kan integreras med Sverigekassans Kassasystem.
                        Tema och skärmstyrning på klocka och kalender.
                    </div>
                    <div className="web-box-foot">
                        Kontakta oss för mer info...
                    </div>
                </div>
                <div className="web-box flex">
                    <div className="web-icon">
                        <i className="fa fa-clock fa-3x flex" aria-hidden="true" style={{padding: "10px"}}></i>
                    </div>
                    <div className="web-box-title">LOJALITETSVSYSTEM</div>
                    <div className="web-box-body h200">
                        Lojalitets system med presentkortshantering. Kan integreras med driftsystem såsom kassa, hotell mm.
                        Kan styras av magnetkort, kontaktalösa kort, app mm. Kundsidor kan aktiveras mm.
                    </div>
                    <div className="web-box-foot">
                        Kontakta oss för mer info...
                    </div>
                </div>
                </div>
                <div className="divider_green"></div>
                <div className="web-col">
                    <div className="txt_22">KONTAKTA OSS FÖR DISKUSSION</div>
                    <div className="txt_22">SAMT KOMPLETT PRESENTATION AV DELSYSTEM</div>
                </div>
            </div>

            <div className="parallax"></div>

            <div id="m03" className="web-content bg-w00 web-center">
                <div className="web-row">
                    <div className="txt_32">TJÄNSTER</div>
                </div>
                <div className="web-row">
                <div className="web-box flex">
                    <Ico_Like className="web-ico" />
                    <div className="web-box-title">UTBILDNING</div>
                    <div className="web-box-body h200">
                        Vi erbjuder utbildning på samtliga av våra system.
                        Utbildning kan erbjudas både som kurs fysiskt hos kund eller online.
                        E-utbildningar kan även erbjudas.
                    </div>
                    <div className="web-box-foot">
                        Kontakta oss för mer info...
                    </div>
                </div>
                <div className="web-box flex">
                    <Ico_Group className="web-ico" />
                    <div className="web-box-title">INSTALLATION</div>
                    <div className="web-box-body h200">
                        Installation av nätverk, datorer, skrivare och annan hårdvara erbjuds efter behov.
                    </div>
                    <div className="web-box-foot">
                        Kontakta oss för mer info...
                    </div>
                </div>
                <div className="web-box flex">
                    <div className="web-icon">
                        <i className="fa fa-clock fa-3x flex" aria-hidden="true" style={{padding: "10px"}}></i>
                    </div>
                    <div className="web-box-title">DRIFT</div>
                    <div className="web-box-body h200">
                        Vi driftsätter Er IT miljö i molnet.
                        Erbjuder både host av driftsystem och molnbaserade tjänster.
                    </div>
                    <div className="web-box-foot">
                        Kontakta oss för mer info...
                    </div>
                </div>
                </div>
            </div>
            <div id="m03" className="web-content web-center">
                <div className="web-row">
                <div className="web-box flex">
                    <Ico_Like className="web-ico" />
                    <div className="web-box-title">UTVECKLING</div>
                    <div className="web-box-body h200">
                        Vi utvecklar efter dina behov. Programutveckling för det lilla såväl som det stora projektet.
                        Utveckling för både backend och frontend system samt lokal eller molnbaserad drift.
                    </div>
                    <div className="web-box-foot">
                        Kontakta oss för mer info...
                    </div>
                </div>
                <div className="web-box flex">
                    <Ico_Group className="web-ico" />
                    <div className="web-box-title">OFFICE 365</div>
                    <div className="web-box-body h200">
                        Vi har hög kompetens inom Microsoft produkt sortiment och adminstrerar stora som små totala IT miljöer.
                    </div>
                    <div className="web-box-foot">
                        Kontakta oss för mer info...
                    </div>
                </div>
                <div className="web-box flex">
                    <div className="web-icon">
                        <i className="fa fa-clock fa-3x flex" aria-hidden="true" style={{padding: "10px"}}></i>
                    </div>
                    <div className="web-box-title">SUPPORT</div>
                    <div className="web-box-body h200">
                        Vi erbjuder support tjänster för samtliga av våra produkter. Även dygnet runt, året runt support erbjuds.
                    </div>
                    <div className="web-box-foot">
                        Kontakta oss för mer info...
                    </div>
                </div>
                </div>
            </div>

            <div className="divider_orange"></div>

            <div id="m08" className="web-s0 bg-w01 web-center">
                <div className="white web-center">
                    <div className="txt_32">Kontakt</div>
                    <div className="txt_22">010-7505000</div>
                    <div className="txt_22">info@sverigekassan.se</div>
                </div>
            </div>

            <div id="m09" className="web-foot" style={{padding:"0px 50px"}}>
                <div className="web-row flex">
                <div className="flex web-start">
                    <div className="txt_16">Sverigekassan AB</div>
                    <div className="txt_12">Energigatan 9</div>
                    <div className="txt_12">434 37 Kungsbacka</div>
                </div>
                <div className="flex web-cc">
                    <div className="txt_12">Tel : 010-7505000</div>
                    <div className="txt_12">info@sverigekassan.se</div>
                </div>
                <div className="flex web-end">
                    <div className="red txt_14">Support ärende</div>
                    <div className="red txt_14">Kund Login</div>
                </div>
                </div>
            </div>

            <div className="web-copyright web-row" style={{padding:"0px 50px"}}>
                <div className="flex web-start">
                    <div>Sverigekassan AB</div>
                </div>
                <div className="flex web-cc">
                    <div>www.sverigekassan.se</div>
                </div>
                <div className="flex web-end">
                    <div>@Copyright 2020</div>
                </div>
            </div>

            </div>

              <LoginDlg show={this.state.login} ctx={this} backdrop={true}/>
              <PdfDlg show={this.state.pdf} ctx={this} backdrop={true}/>
          </div>
        );
    }

}
//WebPub.contextType = SDOContext; // Function
export default withRouter(Web);

/*
            <div id="header" className="web-header web-title" style={ this.header_css() }>

            <div className="web-s0 web-center" style={ this.w00_css() }>
                <div className="cdi-slide">
                        <div className="animated" ref={this.s1ref} style={ this.cdi_slide("s1") }>
                        S1
                        </div>
                        <div className="animated" ref={this.s2ref} style={ this.cdi_slide("s2") }>
                        S2
                        </div>
                </div>
            </div>

                <div className="bg-w01 flex">
                    <h6>SDO : { this.state.sdo.fid }</h6>
                    <h6>CTX : { this.context.fid }</h6>
                <div className="flex-row">
                    <button className="btn btn-hw-blue w150 ripple" onClick={() => this.tele_ank() } aria-label="System" >
                        Ladda
                    </button>
                    <button className="btn btn-hw-blue w150 ripple" onClick={() => this.openPie() } aria-label="System" >
                        Diagram
                    </button>
                    <button className="btn btn-hw-blue w150 ripple" onClick={() => this.pdf_show() } aria-label="System" >
                        Skriv ut
                    </button>
                    <button className="btn btn-hw-blue w150 ripple" onClick={() => this.openXX() } aria-label="System" >
                        Skriv ut
                    </button>
                </div>
                </div>

 */