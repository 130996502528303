import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";
import Dt_Cal from "../../lib/ui/dt_cal";
import Cond_New from "./fkn/cond_new";
import Cond_Open from "./fkn/cond_open";
import Plu_Open from "./plu/plu_open";
import ddo from "../../ddo";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Pos_Cond extends React.Component {
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.state = { pgid: 11, tra: [] };
    this.state.seldate = new Date();
    this.state.avd = "01";
    this.state.caa = [];
    this.state.dao = {};
    this.state.aa = [];
    this.state.condnew = false;
    this.state.condopen = false;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.conds();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

  cond_new() {
    var self = this;
    this.setState({condnew: true});
  }
  cond_open(row) {
    var self = this;
    this.setState({condopen: true});
  }

  conds() {
    var self = this;
    var prm = {};
    prm.req = "pos.pos_cond.conds";
    prm.avd = ddo.cache.avd;
    //prm.fplu = datum;
    //prm.tplu = datum;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        self.setState({caa: ret.rca });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }

  render() {
    return (
    <div className="web-app">

    <div className="flex-row">
        <select className="form-control w250 hide" placeholder="Välj avdelning">
            { this.props.store.pos.deps.map((row, key) =>
            <option key={key} value={row.avd}>{row.avd} - {row.text}</option>
            )}
        </select>
        <div className="flex"></div>
        <button className="btn btn-hw-green w150" onClick={() => this.cond_new() } aria-label="System" >
            LÄGG TILL
        </button>
        <button className="btn btn-hw-blue w150" onClick={() => this.conds() } aria-label="System" >
            UPPDATERA
        </button>
    </div>
    <div className="divider_orange"></div>

    <div className="web-row">
        <table className="table table-bordered table-hover tb fixed_header">
            <thead>
            <tr>
                <th>Avd</th>
                <th>Plu</th>
                <th>Villkor</th>
                <th>Antal</th>
                <th>Pris</th>
                <th>Koppling</th>
                <th style={{width:"40px"}} ></th>
            </tr>
            </thead>
            <tbody style={ this.tb_style() }>
            { this.state.caa.map((row, key) =>
            <tr key={key} id={ row.c0plu } onDoubleClick={() => this.cond_open(row) }>
                <td>{ row.c0avd }</td>
                <td>{ row.c0plu }</td>
                <td>{ row.c0cond }</td>
                <td>{ row.c0antal }</td>
                <td>{ row.c0pris }</td>
                <td>{ row.c0koppl }</td>
                <td style={{width:"40px",cursor:"pointer"}} onClick={() => this.cond_open(row) }><i className="fa fa-cog text-danger"></i></td>
            </tr>
            )}
            </tbody>
        </table>
    </div>

            { this.state.condnew ?
        <Cond_New show={this.state.condnew} ctx={this} pbo={this.state.pbo} store={this.store} backdrop={true}/>
            : null }
            { this.state.condopen ?
        <Cond_Open show={this.state.condopen} ctx={this} pbo={this.state.pbo} store={this.store}backdrop={true}/>
            : null }
    </div>
  );
    }
}
export default Pos_Cond;
