import React, { useState, useEffect, useContext } from 'react';
import * as net from "../../lib/net";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function PdfDlg(props) {
    const [state, setState] = useState({ email:"", pdf : null });
    //const [pdf, setPdf] = useState({ null });

    function sendPrn() {
        //props.ctx.dologin(state.user.uid, state.user.pwd);
        if(state.email.length < 1) {
            alert("Saknas epostadress?");
            return;
        }
        send_mx(state.email);
    }
    function handleUserInput (e) {
      const email = e.target.value;
      setState({email: email});
      //setState({[name]: value});
    }
	const handleClose = () => {
		//props.ctx.setState({ pdf: 0, pdf_content: null });
		props.done({ ok: "000" });
	};

    function send_mx(email) {
        var prm = {};
     	prm.req = "pms.rp.mx_rp";
     	if(props.mx.req) prm.req = props.mx.req;
     	prm.rp = props.mx.rp;
     	prm.email = email;
     	prm.prm = props.mx.prm;
        //alert(JSON.stringify(prm));

        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret.rca));
		    props.done({ ok: "000" });
       })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }

    if(!props.mx.rp) return "";


	var html =
        <div className="mx_base">
        { props.backdrop ? <div className="mx_backdrop" /> : null }
        <div className="mx_dlg mx_rp flex-col" style={{zIndex: "19999"}}>
        <div className="mx_title">
            <div className="">Maila Utskrift</div>
            <div className="flex"></div>
            <div className="black-icon-close" onClick={() => handleClose() }>
                X
            </div>
        </div>
        <div className="mx_content flex">
            <div className="flex-row flex-center">
            <div className="flex-column">
                <div className="w250">
                    <label className="">Epostadress</label>
                    <input type="text" name="uid" className="form-control input-rp-01" placeholder="" required="" autoFocus
                      value={state.email} onChange={(event) => handleUserInput(event)}
                    />
                </div>
                <div className="btn-rp w250" onClick={() => sendPrn() } >
                    <div>SKICKA EPOST</div>
                    <div><FontAwesomeIcon icon="envelope" size="2x" /></div>
                </div>
            </div>
            </div>
        </div>

        <div className="mx_foot flex-row">
            <div className="flex"></div>
            <button className="btn btn-hw-red w200" onClick={() => handleClose() } aria-label="System" >
                STÄNG
            </button>
            <div className="flex"></div>
        </div>
      </div>
    </div>
	;
	return html;
}

export default PdfDlg;
