import React, { useState, useEffect, useContext } from 'react';
import * as net from "../../lib/net";

function TabPanel(props) {
  const { children, value, index, ctx, ...other } = props;
    function tab_change(val) {
        //alert(newValue);
        ctx.setState({ix: val});
      };
  function tab_style(val) {
      var css = {};
      css.height = "30px";
      css.lineHeight = "30px";
      css.background = "#fff";
      css.borderBottom = "1px #ccc solid";
      css.color = "#ccc";
      if(val == ctx.state.ix) {
          css.color = "#080";
          css.borderBottom = "2px #080 solid";
      }
      return css;
  }

  return (
    <div
      className="flex ripple text-center" onClick={() => tab_change(index)} style={ tab_style(index) }
      {...other}
    >
        {children}
    </div>
  );
}
class Sid_Open extends React.Component {
  constructor(props, context) {
    super(props);

    //alert('PBO CTX: ' + JSON.stringify(props.pbo));
   //this.webStore = props.store;
    this.state = {};
    this.state.show = props.show;
    this.state.uaa = [];
    this.state.laa = [];
    this.state.lso = {};
    //this.state.lro = props.lro;
    this.state.ynq = [
                {id: '0', text: 'JA'},
                {id: '1', text: 'NEJ'}
            ];
    this.state.aa = [
                {id: '01', text: 'AVD 01'},
                {id: '02', text: 'AVD 02'}
            ];
    this.state.authval = [
                {id: '000', text: 'NIVÅ 000'},
                {id: '100', text: 'NIVÅ 100'},
                {id: '900', text: 'NIVÅ 900'}
            ];
    this.state.cols = {};
    this.state.lrwrk = false;

    this.state.pro = {};
    this.state.pbo = {};
    this.state.qbo = props.pbo;
    this.state.ix = 0;
    this.state.md_01 = false;
    this.state.md_init = false;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.cdi_init();
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    tab_style(tabid) {
      var css = {};
      css.display = "none";
      if(this.state.tabval === tabid) css.display = "block";
      return css;
    }
    tab_head(tabid) {
      var css = {};
      css.background = "#fff";
      css.color = "#000";
      if(this.state.tabval === tabid) {
          css.background = "#080";
          css.color = "#fff";
      }
      return css;
    }
    kid_init() {
        var self = this;
        var qbo = {};

        qbo.kd00 = {};
        qbo.kd00.k0typ = "";
        qbo.kd00.k0kid = "";
        qbo.kd00.k0text = "";
        qbo.kd00.k0company = "";
        qbo.kd00.k0adress = "";
        qbo.kd00.k0postadress = "";
        qbo.kd00.k0city = "";
        qbo.kd00.k0orgnr = "";
        qbo.kd00.k0phone = "";
        qbo.kd00.k0country = "";
        qbo.kd00.k0status = "";
        qbo.kd00.k0filler = "";
        qbo.kd00.k0ts = "";

        qbo.kd01 = {};
        qbo.kd01.k1email = "";
        qbo.kd01.k1web = "";
        qbo.kd01.k1ref = "";
        qbo.kd01.k1mobile = "";
        qbo.kd01.k1filler = "";
        qbo.kd01.k1ts = "";

        self.setState({qbo: qbo, pbo: qbo,  md_init: true});
    }
    cdi_init() {
        var self = this;
        var pro = {};
        var pbo = {};
        var qbo = self.state.qbo;

        var keys = Object.keys(qbo);
        if(keys.length < 1) {
            self.setState({pbo: pbo, pro: pro,  md_init: true});
            return;
        }
        for(var key of keys) {
            var o = qbo[key];
            pbo[key] = {};
            var okeys = Object.keys(qbo[key]);
            for(var okey of okeys) {
                var oo = qbo[key][okey];
                pbo[key][okey] = oo;
            }
        }

        //pbo.kd00.k0text = pbo.kd00.k0text.cdi2txt();

        self.setState({pbo: pbo, pro: pro,  md_init: true});
    }
    cdi_store() {
        var self = this;
        var pbo = self.state.pbo;
        var qbo = self.state.qbo;
        var pro = self.state.pro;

        //if(pbo.kd00 && pbo.kd00.k0text) pbo.kd00.k0text = pbo.kd00.k0text.txt2cdi();

        var keys = Object.keys(pbo);
        if(keys.length < 1) {
            return;
        }
        for(var key of keys) {
            var o = pbo[key];
            if(key.substr(0,3) != "plu") continue;

            var okeys = Object.keys(pbo[key]);
            for(var okey of okeys) {
                var oo = pbo[key][okey];
                qbo[key][okey] = oo;
            }
        }
        self.setState({qbo: qbo});
    }
    cdi_kdbuf() {
        var self = this;
        var kdbuf = {};
        var pbo = self.state.pbo;
        var pro = self.state.pro;

        //if(pbo.kd00 && pbo.kd00.k0text) pbo.kd00.k0text = pbo.kd00.k0text.txt2cdi();

        var keys = Object.keys(pbo);
        if(keys.length < 1) {
            return null;
        }
        for(var key of keys) {
            var o = pbo[key];
            if(key.substr(0,2) != "kd") continue;

            kdbuf[key] = {};
            var okeys = Object.keys(pbo[key]);
            for(var okey of okeys) {
                var oo = pbo[key][okey];
                kdbuf[key][okey] = oo;
            }
        }
        self.setState({pro: pro});

        return kdbuf;
    }

    prn_val() {
      alert(this.state.pbo.usr00.u0namn);
    }

    formchange (e) {
      const name = e.target.name;
      const value = e.target.value;
      var pbo = this.state.pbo;
      var na = name.split(".");
      pbo[na[0]][na[1]] = value;
      this.setState({pbo: pbo});
      //setState({[name]: value});
    }
    prischange (e) {
      const name = e.target.name;
      const value = e.target.value;
      var pro = this.state.pro;
      var na = name.split(".");
      pro[na[1]] = value;
      this.setState({pro: pro});
      //setState({[name]: value});
    }

    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    dlg_close = () => {
		//this.props.ctx.setState({ usropen: false });
		this.props.cb();
	};
    handleChange = (event, newValue) => {
        //alert(newValue);
        this.setState({ix: newValue});
      };
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  cssCols (cid) {
      var css = {};
      if(this.state.cols[cid]) css.width = this.state.cols[cid].toString() + "px";
      //if(cid == "c01") alert(JSON.stringify(this.state.cols[cid]));
      return css;
  }

  md_toggle (event) {
    var self = this;
    self.setState({md_01: false });
    return;
  }

  kid_fkn (event) {
    var self = this;
    self.setState({md_01: true });
    return;
  }

  kid_add (event) {
    var self = this;
    self.setState({md_01: true });

    var kdbuf = self.cdi_kdbuf();

    //alert(JSON.stringify(plubuf.plu00.p0namn));
    //alert(JSON.stringify(plubuf.plu00.p0pris));

    var prm = {};
    prm.req = "kid:add";
    prm.kdbuf = kdbuf;

    //gda.wndLoading("Hämtar");
    net.gcs_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok == "000") {
            self.cdi_store();
        }
        //alert(JSON.stringify(o));
        //toastr.success("OK", "SAPI");
        //self.setState({md_01: false });
        setTimeout(function(){
            self.setState({md_01: false });
        }, 1000);
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
        self.setState({md_01: false });
    });
    return;
  }

  render() {
    if(!this.props.show) return "";

    //alert(this.props.ctx.state.lro);

    var title = this.props.title;
    if(!title) title = "Plu";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="mx_backdrop" /> : null }
        <div className="mx_dlg mx_90 flex-col" style={ this.dlg_style() }>
        { this.state.md_init ?
        <div className="mx_content flex">
            <div className="flex-row" style={{padding:"5px",height:"30px"}}>
                    <div className="flex-row flex txt_18">
                        <div>{ this.state.pbo.sid00.s0sid } - { this.state.pbo.sid00.s0text }</div>
                    </div>
                    <div className="flex-col">
                        <div>SKAPA</div>
                    </div>
            </div>
            <div className="divider_orange"></div>

            <div className="flex-row">
                <TabPanel index={0} ctx={this}>DATA</TabPanel>
                <TabPanel index={1} ctx={this}>EXTRA</TabPanel>
            </div>
            <div style={{padding:"5px"}}></div>

            <form className="cdi_f03">
            <div hidden={this.state.ix !== 0}>

                <div className="form-row flex-row">
                    <div className="form-group flex-row flex">
                        <span className="header">Kund ID</span>
                        <input type="text" className="form-control text-left w350"
                               name="kd00.k0kid" value={this.state.pbo.sid00.s0kid}
                               placeholder="" onChange={(e) => this.formchange(e)} />
                    </div>
                </div>
                <div className="form-row flex-row">
                    <div className="form-group flex-row flex">
                        <span className="header">Text</span>
                        <input type="text" className="form-control text-left w350"
                               name="kd00.k0text" value={this.state.pbo.sid00.s0text}
                               placeholder="" onChange={(e) => this.formchange(e)} />
                    </div>
                    <div className="form-group flex-row flex">
                        <span className="header">Företag</span>
                        <input type="text" className="form-control text-left w350"
                               name="kd00.k0company" value={this.state.pbo.sid00.k0company}
                               placeholder="" onChange={(e) => this.formchange(e)} />
                    </div>
                </div>

                <div className="form-row flex-row">
                    <div className="form-group flex-row flex">
                        <span className="header">Företag</span>
                        <input type="text" className="form-control text-left w450"
                               name="kd00.k0adress" value={this.state.pbo.sid00.k0adress}
                               placeholder="" onChange={(e) => this.formchange(e)} />
                    </div>
                </div>

                <div className="form-row flex-row">
                    <div className="form-group flex-row flex">
                        <span className="header">Postadress</span>
                        <input type="text" className="form-control text-left w250"
                               name="kd00.k0postadress" value={this.state.pbo.sid00.k0postadress}
                               placeholder="" onChange={(e) => this.formchange(e)} />
                    </div>
                    <div className="form-group flex-row flex">
                        <span className="header">Stad</span>
                        <input type="text" className="form-control text-left w250"
                               name="kd00.k0city" value={this.state.pbo.sid00.k0city}
                               placeholder="" onChange={(e) => this.formchange(e)} />
                    </div>
                </div>

                <div className="form-sep"></div>

                <div className="form-row flex-row">
                    <div className="form-group flex-row flex">
                        <span className="header">Org nr</span>
                        <input type="text" className="form-control text-left w250"
                               name="kd00.u0enr" value={this.state.pbo.sid00.u0enr}
                               placeholder="" onChange={(e) => this.formchange(e)} />
                    </div>
                    <div className="form-group flex-row flex">
                        <span className="header">Telefon</span>
                        <input type="text" className="form-control text-left w250"
                               name="kd00.u0sign" value={this.state.pbo.sid00.u0sign}
                               placeholder="" onChange={(e) => this.formchange(e)} />
                    </div>
                </div>

                <div className="form-row flex-row">
                    <div className="form-group flex-row flex">
                        <span className="header">Land</span>
                        <input type="text" className="form-control text-left w150" onChange={(e) => this.formchange(e)}
                               name="kd00.k0country" value={this.state.pbo.sid00.k0country}
                               placeholder="" />
                    </div>
                </div>

                <div className="form-row flex-row">
                    <div className="form-group flex-row flex">
                        <span className="header">Status</span>
                        <input type="text" className="form-control text-left w150" onChange={(e) => this.formchange(e)}
                               name="kd00.k0status" value={this.state.pbo.sid00.k0status}
                               placeholder="" />
                    </div>
                </div>

            </div>

            <div hidden={this.state.ix !== 1}>

                <div className="flex-row">
                    <div className="form-group flex-row flex">
                        <span className="header">Epost</span>
                        <input type="text" className="form-control text-left w150" onChange={(e) => this.formchange(e)}
                               name="kd00.k1email" value={this.state.pbo.sid00.k1email}
                               placeholder="" />
                    </div>
                    <div className="form-group flex-row flex">
                        <span className="header">Web</span>
                        <input type="text" className="form-control text-left w150" onChange={(e) => this.formchange(e)}
                               name="kd00.k1web" value={this.state.pbo.sid00.k1web}
                               placeholder="" />
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group flex-row flex">
                        <span className="header">Referens></span>
                        <input type="text" className="form-control text-left w150" onChange={(e) => this.formchange(e)}
                               name="kd00.k1ref" value={this.state.pbo.sid00.k1ref}
                               placeholder="" />
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group flex-row flex">
                        <span className="header">Mobil</span>
                        <input type="text" className="form-control text-left w150" onChange={(e) => this.formchange(e)}
                               name="kd00.k1mobile" value={this.state.pbo.sid00.k1mobile}
                               placeholder="" />
                    </div>
                </div>

            </div>

        </form>

        </div>
        : null }
        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250 ripple" onClick={() => this.dlg_close() } >
                STÄNG
            </button>
            <div className="flex-row">
             <button className="btn btn-hw-blue w250 ripple" onClick={(event) => this.kid_fkn(event) } >
                FUNKTIONER
            </button>
           </div>
            <button className="btn btn-hw-green w250 ripple" onClick={(event) => this.kid_add(event) } >
                SPARA
            </button>
        </div>
      </div>
            { this.state.md_01 ?
    <div>
      <div isOpen={this.state.md_01} centered keyboard={false} backdrop={true}>
        <div>MDBModal title</div>
        <div>
  <div>
    <div md="6">
        Information
    </div>
  </div>
        </div>
        <div>
          <div color="secondary" onClick={(event) => this.md_toggle(event) }>Close</div>
          <div color="primary">LÄGG TILL</div>
        </div>
      </div>
    </div>
            : null }

    </div>
	;
	return html;
  };
}

export default Sid_Open;