import React, { useState, useContext } from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import sso from "../../sso";
//import { useStore } from '../../sdo.js'
import { useNavigate } from 'react-router-dom';
//import {withRouter} from "../../lib/react/withRouter";
import {observer} from "mobx-react";

function Sys_Menu(props) {
    var navigate = useNavigate();
    var row = {};
    //if(props.store.fkn) row = props.store.fkn;
    let [state, setState] = useState({row: row, mnu: "", htitle: "", ma: []});
    //let ctx = useStore();

    var lock = "lock";
    var moa = {};
    moa["f00"] = { fid: "f00", name: "Hem", url: "/sys", icon: "home", fa: "fa fa-home flex", ma: [] };
    moa["f01"] = { fid: "f01", name: "Kunder", url: "/sys/kids", icon: "key", fa: "fa fa-key flex", ma: [] };
    moa["f02"] = { fid: "f02", name: "Servrar", url: "/sys/srvs", icon: "play", fa: "fa fa-play flex", ma: [] };
    moa["f03"] = { fid: "f03", name: "System", url: "/sys/sids", icon: "desktop", fa: "fa fa-desktop flex", ma: [] };
    moa["f05"] = { fid: "f05", name: "Terminaler", url: "/sys/terms", icon: "chart-bar", fa: "fa fa-chart-bar flex", ma: [] };
    moa["f06"] = { fid: "f06", name: "Diverse", url: "/sys/div", icon: "list-alt", fa: "fa fa-list-alt flex", ma: [] };
    moa["f07"] = { fid: "f07", name: "Backoffice", url: "/sys/bo", icon: "cogs", fa: "fa fa-cogs flex", ma: [] };

    moa["f01"].ma.push( { fid: "f01", name: "Kunder", url: "/sys/kids/kids", fa: "fa fa-bars" } );

    moa["f02"].ma.push( { fid: "f02", name: "Servrar", url: "/sys/srvs/sids", fa: "fa fa-bars" } );

    moa["f03"].ma.push( { fid: "f03", name: "System IDs", url: "/sys/sids/sids", fa: "fa fa-bars" } );

    moa["f05"].ma.push( { fid: "f05", name: "UDIDs", url: "/sys/terms/udids", fa: "fa fa-bars" } );
 
    moa["f07"].ma.push( { fid: "f07", name: "Register", url: "/sys/reg", fa: "fa fa-bars" } );

    var aa = [];
    var keys = Object.keys(moa);
    for(var key of keys) {
        var o = moa[key];
        aa.push( o );
    }

    //document.removeEventListener("mousedown", this.handleClick);
    document.addEventListener("mousedown", handleClick);

    var ma = [];
    var mnuHeight = window.innerHeight - 100;

   function handleClick(e) {
    if (e.pageX > 320) {
        if (state.ma.length) {
            setState({mnu : state.mnu, row : state.row, ma : [] });
            return;
        }
    }
  }
   function fknGo(row) {
    //var sdo = useContext(SDO);
    //alert(fkn);
   sso.seo.sfkn = row;

   var fkn = row.fid;
    setState({ mnu : row.fid, row : row,  ma : [] });
    //ctx.fid = fkn;
    //props.store.fkn = row;
    navigate(row.url);
   };
   function fknFix(fkn) {
    //var sdo = useContext(SDO);
    //alert(fkn);
       //ctx.fid = fkn;
       var sfkn = {};
       sfkn.text = "";
       sfkn.url = fkn;
       sso.seo.sfkn = sfkn;

        if(fkn == "lock") navigate('/web');
        if(fkn == "logout") navigate('/');
   };

   function mnuGo(row) {
        //var sdo = useContext(SDO);
        //alert(fkn);
        //ctx.fid = fkn;
        //sbo.state.fid = fkn;
        setState({ mnu : state.hfid, row : state.row,  ma : [] });

        sso.seo.sfkn = row;
        //props.store.fkn = row;
        navigate(row.url);
   };
   function hideMnu() {
    setState({mnu : state.mnu, row : state.row, ma : [] });
   };
   function mouseEnter(it) {
    setState({mnu : state.mnu, row : state.row, hfid : it.fid, htitle : it.name, ma : it.ma });
   };
   function mouseLeave() {
    setState({mnu : state.mnu, row : state.row, ma : [] });
   };

   function row_css(row) {
  	    var css = {};
        css.margin = "0px";
        css.padding = "0px";
        css.height = "55px";
        css.borderBottom = "1px #ccc solid";

       //if(props.store.fkn && (props.store.fkn != undefined))
       if(sso.seo.sfkn && (sso.seo.sfkn != undefined))
        {
            //if (props.store.fkn.fid == row.fid) {
            if (sso.seo.sfkn.fid == row.fid) {
               css.background = "#080";
               css.color = "#fff";
            }
        }
		return css;
    }
   function row_icon_css(row) {
  	    var css = {};
        css.paddingTop = "10px";
        css.fontSize = "20px";

       //if(props.store.fkn && (props.store.fkn != undefined))
       if(sso.seo.sfkn && (sso.seo.sfkn != undefined))
       {
           //if (props.store.fkn.fid == row.fid) {
           if (sso.seo.sfkn.fid == row.fid) {
               //css.background = "#080";
               css.color = "#fff";
            }
        }
		return css;
    }

   //<Icon className={ it.fa } style={{ fontSize: 18 }} />
   var html =
        <div className="">
        <div className="cdi_xmnu flex-fullcol" style={{padding: "5px 0px"}}>
            { aa.map((it, key) =>
            <div key={ key } onClick={() => fknGo(it)} className="web-col flex-cc xmnu_tab" style={ row_css(it) } onMouseEnter={ () => mouseEnter(it) }>
                <FontAwesomeIcon icon={ it.icon } size="1x" className="" style={ row_icon_css(it) } />
                <div style={{fontSize: "10px"}}>{ it.name }</div>
            </div>
            )}
        </div>

            { state.ma.length ?
        <div className="mtrl_mnu_sub mtrl_mnu_bg" aria-label="Sub Menu" style={{height: mnuHeight}} onMouseLeave={mouseLeave}>
          <div className="mtrl_mnu_title" onClick={() => hideMnu()}>
            { state.htitle }
          </div>
            { state.ma.map((mi, key) =>
          <div key={key} className="mtrl_mnu_item flex-row" onClick={ () => mnuGo(mi) }>
            <div className="flex">{ mi.name }</div>
            <i className={ mi.fa } aria-hidden="true" style={ row_icon_css(mi) }></i>
          </div>
            )}
        </div>
                : null }
        </div>
    ;
    return html;
}

export default observer(Sys_Menu);
