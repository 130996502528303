import React, { useState, useEffect, useContext } from 'react';

import * as utils from "../../../assets/utils";
import Fr_Input from "../../../lib/ui/fr_input";
import * as net from "../../../lib/net";
import Wnd_Error from "../../../lib/ui/wnd_error";
import Wnd_Ok from "../../../lib/ui/wnd_ok";
import Wnd_Confirm from "../../../lib/ui/wnd_confirm";

class Kreg_Edit extends React.Component {
    constructor(props, context) {
        super(props);

        this.state = {};
        this.state.ynq = [];
        this.state.user = {};
        this.state.tabval = 0;
        this.state.msg = {};
        this.state.wnds = {};

        this.state.fra = [];
        this.state.aa = [];

        this.state.kid = "";
        this.state.kanm = "";
        this.state.kro = {};
        this.state.kro.krr00 = {};
        this.state.kro.krr01 = {};
        this.state.kro.krr02 = {};
        this.state.kro.krr03 = {};
        if(props.wnds) {
            this.state.kid = props.wnds.kid;
            if(props.wnds.kro) this.state.kro = props.wnds.kro;
        }

        this.state.pdf = 0;
        this.state.pdf_content = null;
        this.state.loading = 0;
    }

    componentDidUpdate() {
    }
    componentWillUnmount() {
    }
    componentDidMount() {
        //this.fak_read();
    }

    dlg_style() {
      var css = {};
      css.width = window.innerWidth * 0.8;
      css.height = window.innerHeight * 0.8;
      return css;
    }
    tab_style(tabid) {
      var css = {};
      css.display = "none";
      if(this.state.tabval === tabid) css.display = "block";
      return css;
    }
    tab_head(tabid) {
      var css = {};
      css.background = "#fff";
      css.color = "#000";
      if(this.state.tabval === tabid) {
          css.background = "#080";
          css.color = "#fff";
      }
      return css;
    }
    save_kid() {
        var self = this;
        var prm = {};
        prm.req = "kreg.kreg_wrk.kreg_upd";
        prm.krr00 = this.state.kro.krr00;
        prm.krr01 = this.state.kro.krr01;
        prm.krr02 = this.state.kro.krr02;
        prm.krr03 = this.state.kro.krr03;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.rca));

                if(ret.ok != "000") {
                    return;
                }
                var msg = { info: false, ok: true, text: "Sparad ..."};
                self.setState({msg: msg });
                setTimeout(function(){
                    self.setState({msg: {} });
                    if(self.props.wnds.cb) {
                        self.props.wnds.cb(self.props.ctx);
                    }
                    else self.props.ctx.setState({ wnds: {} });
                    return;
                }, 1000);
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    remove_kid() {
        var self = this;
        var prm = {};
        prm.req = "kreg.kreg_wrk.kreg_del";
        prm.krr00 = this.state.kro.krr00;
        prm.krr01 = this.state.kro.krr01;
        prm.krr02 = this.state.kro.krr02;
        prm.krr03 = this.state.kro.krr03;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.rca));

                if(ret.ok != "000") {
                    return;
                }
                var msg = { info: false, ok: true, text: "Borttagen ..."};
                self.setState({msg: msg });
                setTimeout(function(){
                    self.setState({msg: {} });
                    if(self.props.wnds.cb) {
                        self.props.wnds.cb(self.props.ctx);
                    }
                    else self.props.ctx.setState({ wnds: {} });
                    return;
                }, 1000);
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    save_anm() {
        var self = this;
        var prm = {};
        prm.req = "kreg.kreg_data.kanm_save";
        prm.kid = this.state.kro.krr00.kid;
        prm.kamn = this.state.kamn;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.rca));

                if(ret.ok != "000") {
                    return;
                }
                var msg = { info: false, ok: true, text: "Anm Sparad ..."};
                self.setState({msg: msg });
                setTimeout(function(){
                    self.setState({msg: {} });
                    return;
                }, 1000);
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    val_save (name, value) {
        //alert(JSON.stringify(name));
        //alert(JSON.stringify(value));
        var na = name.split(".");
        var kro = this.state.kro;
        kro[na[0]][na[1]] = value;
        this.setState({kro: kro});
    }
    val_anm_save (e) {
        //alert(JSON.stringify(name));
        //alert(JSON.stringify(value));
        var kanm = e.target.value;
        this.setState({kanm: kanm});
    }
    tabChange (e) {
        this.setState({tabval: e.index});
    }
    wnd_close() {
        this.props.ctx.setState({ wnds: {} });
    };

    render() {
    //if(!props.show) return "";
    var title = this.props.title;
    if(!title) title = "KUND";
    //if(props.kro) alert(props.kro.krr00.kid);

    var html =
        <div className="mx_base">
            { this.props.backdrop ? <div className="mx_backdrop" /> : null }
            <div className="mx_dlg mx_90 flex-col" style={ this.dlg_style() }>
                <div className="mx_h01 flex-row px-3" style={{background: "#235d91", color: "#fff"}}>
                    <div>KUND: { this.state.kro.krr00.kid }</div>
                    <div className="flex"></div>
                    <div onClick={() => this.wnd_close() }>X</div>
                </div>
                <div className="mx_content flex-col flex py-3 px-3">
                    <div className="flex-row">
                        <div className="tab_header flex" style={ this.tab_head(0) } onClick={ () => this.setState({tabval: 0}) }>FÖRETAG</div>
                        <div className="tab_header flex hide" style={ this.tab_head(1) } onClick={ () => this.setState({tabval: 1}) }>ANMÄRKNING</div>
                        <div className="tab_header flex" style={ this.tab_head(2) } onClick={ () => this.setState({tabval: 2}) }>VILLKOR</div>
                    </div>
                    <div className="divider_green"></div>

                    <div style={ this.tab_style(0) }>
                        <form className="cdi_f03">
                        <div className="flex-row">
                            <Fr_Input label="Företag" className="text-left w350" text={this.state.kro.krr00.company} getValue={(e)=> this.val_save("krr00.company", e) } />
                            <Fr_Input label="Orgnr" className="text-left w250" text={this.state.kro.krr02.orgnr} getValue={(e)=> this.val_save("krr02.orgnr", e) } />
                        </div>
                        <div className="flex-row">
                            <Fr_Input label="Namn" className="text-left w350" text={this.state.kro.krr00.namn} getValue={(e)=> this.val_save("krr00.namn", e) } />
                            <Fr_Input label="Email" className="text-left w250" text={this.state.kro.krr03.email} getValue={(e)=> this.val_save("krr03.email", e) } />
                        </div>
                        <div className="flex-row">
                            <Fr_Input label="Postadress" className="text-left w350" text={this.state.kro.krr01.adress1} getValue={(e)=> this.val_save("krr01.adress1", e) } />
                            <Fr_Input label="Telefon" className="text-left w250" text={this.state.kro.krr03.tfn} getValue={(e)=> this.val_save("krr03.tfn", e) } />
                        </div>
                        <div className="flex-row">
                            <Fr_Input label="Gatuadress" className="text-left w350" text={this.state.kro.krr01.adress2} getValue={(e)=> this.val_save("krr01.adress2", e) } />
                            <Fr_Input label="Mobil" className="text-left w250" text={this.state.kro.krr03.mobil} getValue={(e)=> this.val_save("krr03.mobil", e) } />
                        </div>
                        <div className="flex-row">
                            <Fr_Input label="Postnr" className="text-left w350" text={this.state.kro.krr02.postnr} getValue={(e)=> this.val_save("krr02.postnr", e) } />
                            <Fr_Input label="Ort" className="text-left w250" text={this.state.kro.krr02.city} getValue={(e)=> this.val_save("krr02.city", e) } />
                        </div>
                        <div className="flex-row">
                            <Fr_Input label="Land" className="text-left w350" text={this.state.kro.krr02.land} getValue={(e)=> this.val_save("krr02.land", e) } />
                        </div>
                        </form>
                    </div>

                    <div style={ this.tab_style(1) }>
                        <form className="cdi_f03">
                        <div className="form-group">
                            <label>Anmärkning</label>
                            <textarea rows="10" className="form-control" value={ utils.toLang( this.state.kanm )} onChange={(event) => this.val_anm_save(event)} />
                        </div>
                        <div className="form-group">
                            <div className="txt_18" onClick={() => this.save_anm() }>Spara Anmärkning</div>
                        </div>
                    </form>
                </div>

                    <div style={ this.tab_style(2) }>
                        <form className="cdi_f03">
                        <div className="form-group">
                            <Fr_Input col label="Betallvillkor" className="text-left w250" text={ this.state.kro.krr00.betvillkor } getValue={(e)=> this.val_save("krr00.betvillkor", e) } />
                        </div>
                        <div className="form-group">
                            <Fr_Input col label="Påminnelse" className="text-left w150" text={ this.state.kro.krr00.reminder } getValue={(e)=> this.val_save("krr00.reminder", e) } />
                        </div>
                        <div className="form-group">
                            <Fr_Input col label="Ränta" className="text-left w150" text={ this.state.kro.krr00.interest } getValue={(e)=> this.val_save("krr00.interest", e) } />
                        </div>
                    </form>
                    </div>
                </div>
                <div className="mx_foot flex-row">
                    <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                        STÄNG
                    </button>
                    <div className="flex"></div>
                    <button className="btn btn-hw-green w250" onClick={(event) => this.remove_kid() } >
                        TA BORT
                    </button>
                    <div className="flex"></div>
                    <button className="btn btn-hw-green w250" onClick={(event) => this.save_kid() } >
                        SPARA
                    </button>
                </div>
            </div>
            <Wnd_Error msg={this.state.msg} ctx={this} />
            <Wnd_Ok msg={this.state.msg} ctx={this} />
            <Wnd_Confirm msg={this.state.msg} ctx={this} />
        </div>
    ;
    return html;
    }
}

export default Kreg_Edit;