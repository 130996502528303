import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";

import Tr_dlg from "./list/tr_dlg";
import Sort_dlg from "./list/sort_dlg";
import Betm_dlg from "./list/betm_dlg";
import Term_dlg from "./list/term_dlg";
import Kassor_dlg from "./list/kassor_dlg";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Pos_List extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, tra: [] };
    this.state.seldate = new Date();
    this.state.avd = "01";
    this.state.paa = [];
    this.state.aa = [
                {id: '01', text: 'AVD 01'},
                {id: '02', text: 'AVD 02'}
            ];
    //context.posrend = this;
    this.state.pdf = 0;
    this.state.rptyp = "";
    this.state.fsg = 0;
    this.state.bet = 0;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.plus();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 480;
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

  rp_open(typ) {
      var self = this;
    this.setState({ rptyp: typ });
  }

  render() {
    return (
    <div className="web-p00 flex flex-col">
        <div className="">
            LISTOR
        </div>

    <div className="web-p01 flex">

    <div className="web-row">
    <div className="web-col flex">
        <div className="web-btn" onClick={() => this.rp_open("tr") } >
            <div>TRANSAKTIONSLISTA</div>
            <div>Transaktioner för perioden</div>
        </div>
    </div>
     <div className="web-col flex">
        <div className="web-btn" onClick={() => this.rp_open("sort") } >
            <div>SORTIMENTS LISTA</div>
            <div>Sortiment hela eller urval</div>
        </div>
        <div className="web-btn" onClick={() => this.rp_open("betm") } >
            <div>BETALMEDELS LISTA</div>
            <div>Upplagda betalmedel</div>
        </div>
   </div>
     <div className="web-col flex">
        <div className="web-btn" onClick={() => this.rp_open("term") } >
            <div>TERMINALLISTA</div>
            <div>Upplagda terminaler</div>
        </div>
        <div className="web-btn" onClick={() => this.rp_open("kassor") } >
            <div>KASSÖRS LISTA</div>
            <div>Upplagda kassörer</div>
        </div>
   </div>
   </div>

    </div>

        { this.state.rptyp == "tr" ? <Tr_dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "sort" ? <Sort_dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "betm" ? <Betm_dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "term" ? <Term_dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "kassor" ? <Kassor_dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
    </div>
  );
    }
}
export default Pos_List;
