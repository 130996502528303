import React, {useState} from 'react';
import {observer} from "mobx-react";

import * as net from "../../lib/net";
import Dd_Sel from "../../lib/ui/dd_sel";

import Tiden from "../../lib/ui/tiden";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useNavigate} from "react-router-dom";

function Bfs_Foot(props) {
    var navigate = useNavigate();
    let [state, setState] = useState({wnds: {}, ma: []});

    var seo = props.store.seo;

    var selitems = [
        {fkn: 'setup', text: 'INSTÄLLNINGAR'},
        {fkn: 'ses', text: 'MIN SESSION'},
    ];
    if(seo.cs > 0) {
        selitems.push( {fkn: 'ccs', text: 'CENTRALSYSTEM'} );
    }
    if(seo.auth >=90) {
        selitems.push( {fkn: 'man', text: 'MANAGER'} );
    }
    selitems.push( {fkn: 'logout', text: 'LOGGA UT'} );

  function ddo() {
    //alert(row.href);
  }
  function fknGo(url) {
    //alert(row.href);
      navigate(url);
  }
  function fkn_cs() {
      var wnds = {};
      wnds.ccs = true;
      wnds.backdrop = true;
      setState({"wnds": wnds});
  }
  function sel_fkn(sel) {
    //alert(JSON.stringify(fkn));
      if(sel.fkn == "setup") {
          var wnds = {};
          wnds.setup = true;
          setState({"wnds": wnds});
      }
      if(sel.fkn == "ses") {
          var wnds = {};
          wnds.ses = true;
          setState({"wnds": wnds});
      }
      if(sel.fkn == "ccs") {
          var wnds = {};
          wnds.ccs = true;
          wnds.backdrop = true;
          setState({"wnds": wnds});
      }
      if(sel.fkn == "man") {
          var wnds = {};
          wnds.manager = true;
          setState({"wnds": wnds});
      }
      if(sel.fkn == "logout") {
        var url = "/logout";
          navigate(url);
      }
  }

    var html =
    <div className="web-foot">
        <div className="web-foot-mnu" onClick={() => ddo() }>
            <FontAwesomeIcon icon="bars" size="1x"/>
        </div>
        <div className="flex flex-row">
            <div className="web-foot-btn" onClick={() => ddo() } >
                BFS
            </div>
            <div className="flex"></div>

            <div className="web-foot-btn" onClick={() => fknGo("/bfs/bf/hbok") } >HUVUDBOK</div>
            <div className="web-foot-btn" onClick={() => fknGo("/bfs/bf/veri") } >VERIFIKAT</div>
        </div>
        <div className="web-foot-icon">
          <FontAwesomeIcon icon="ellipsis-v" size="1x"/>
        </div>
        <div className="flex flex-row">
            <div className="web-foot-btn" onClick={() => fknGo("/bfs/reg/lreg") } >LEVERANTÖRER</div>
            <div className="web-foot-btn" onClick={() => fknGo("/bfs/reg/kreg") } >KUNDER</div>

            <div className="flex"></div>
            <Tiden />
        </div>
    </div>
    ;
    return html;
}

export default observer(Bfs_Foot);
