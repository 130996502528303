import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../../lib/net";

import Ff_Input from "../../../lib/ui/ff_input";
import PdfRpDlg from "../fkn/pdf_rp_dlg";

class Swish_Wnd extends React.Component {
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.state = {};
    this.state.cols = {};

    this.state.ix = 0;
    this.state.md_01 = false;
    this.state.plu = "";
    this.state.text = "";
    this.state.pris = "";
    this.state.dto = {};
    this.state.tra = [];
    if(props.wnds) {
        this.state.dto = props.wnds.dto;
        this.state.tra = Object.values(this.state.dto.nts);
    }

    this.state.pdf = 0;
    this.state.pdf_content = null;
    //alert('BRA: ' + JSON.stringify(this.state.bra));
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //alert('BRA: ' + JSON.stringify(this.state.bra));
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
  tb_style() {
      var css = {};
      css.height = (window.innerHeight * 0.9) - 150;
      css.overflow = "auto";
      return css;
  }

    wnd_close = () => {
		this.props.ctx.setState({ wnds: {} });
	};

  pdf_done (ret) {
    this.setState({loading: 0, pdf: 0, pdf_content: null });
  }
  prn_copy (event) {
    var self = this;

    var prm = {};
    prm.req = "pos.pos_rp.inv_copy";
    prm.datum = this.state.kvo.datum;
    prm.avd = this.state.kvo.avd;
    prm.notanr = this.state.kvo.notanr;
    //alert(JSON.stringify(prm));

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok == "000") {
            //self.cdi_store();
        }
        self.setState({pdf: 1, pdf_content: ret.pdf });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
        self.setState({md_01: false });
    });
    return;
  }
  prn_combo (event) {
    var self = this;

    var prm = {};
    prm.req = "pos.pos_rp.invrec_copy";
    prm.datum = this.state.kvo.datum;
    prm.avd = this.state.kvo.avd;
    prm.notanr = this.state.kvo.notanr;
    //alert(JSON.stringify(prm));

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok == "000") {
            //self.cdi_store();
        }
        self.setState({pdf: 1, pdf_content: ret.pdf });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
        self.setState({md_01: false });
    });
    return;
  }
    swish_dt() {
        var self = this;

        var prm = {};
        prm.req = "pos.pos_eko.trh_swish_dt";
        //prm.req = "pos.pos_red.jr_red";
        prm.avd = this.state.avd;
        prm.fdat = this.state.fdat;
        prm.tdat = this.state.tdat;
        prm.favd = this.state.avd;
        prm.tavd = this.state.avd;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.rco));

                var dta = [];
                var dto = ret.rco;
                dta = Object.values(dto);
                self.setState({ dta: dta });
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
                //gda.wndError(txt);
            });
    }

  render() {
    //if(!this.props.show) return "";

    //alert(this.props.ctx.state.lro);

    var title = this.props.title;
    if(!title) title = "Plu";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="mx_backdrop" /> : null }
        <div className="mx_dlg mx_90 flex-col" style={ this.dlg_style() }>

        <div className="mx_content flex">
            <div className="flex-row" style={{padding:"5px",height:"30px"}}>
                    <div className="flex-row txt_18">
                        <div>SWISH: </div>
                        <div>{ this.state.dto.datum }</div>
                    </div>
                    <div className="flex-row flex txt_18">
                    </div>
                    <div className="flex-row txt_18">
                        <div>BELOPP: </div>
                        <div>{ this.state.dto.belopp.cdipris() }/{ this.state.dto.abel.cdipris() }</div>
                    </div>
            </div>
            <div className="divider_orange"></div>

        <div className="web-row">
        <table className="table table-bordered table-hover tb fixed_header">
            <thead>
            <tr>
                <th>Datum</th>
                <th>Avd</th>
                <th>Notanr</th>
                <th>Bordsnr</th>
                <th>Fsg</th>
                <th>Belopp</th>
                <th>Bekräftat</th>
                <th>Term</th>
            </tr>
            </thead>
            <tbody style={ this.tb_style() }>
            { this.state.tra.map((row, key) =>
                <tr key={key} className="" onDoubleClick={() => this.row_sel(row) }>
                <td className="">{ row.datum.cdidate() }</td>
                <td className="">{ row.avd }</td>
                <td className="">{ row.notanr }</td>
                <td className="">{ row.bordsnr }</td>
                <td className="text-right">{ row.brutto.formatpris() }</td>
                <td className="text-right">{ row.belopp.formatpris() }</td>
                <td className="text-right">{ row.bekr.formatpris() }</td>
                <td className="">{ row.udid }</td>
                </tr>
            )}
            </tbody>
        </table>
        </div>

        </div>
        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex"></div>
            <div className="flex-row">
            <button className="btn btn-hw-green w250" onClick={(event) => this.prn_copy(event) } >
                SKRIV UT
            </button>
            </div>
        </div>
      </div>

        <PdfRpDlg show={this.state.pdf} pdf={this.state.pdf_content} done={(ret) => this.pdf_done(ret)} backdrop={true}/>
    </div>
	;
	return html;
  };
}

export default Swish_Wnd;