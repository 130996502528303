import React, { useState, useEffect, useContext } from 'react';
import {observer} from "mobx-react";
import {format} from "date-fns";

import * as net from "../../lib/net";
import * as utils from "../../assets/utils";
import ddo from '../../ddo.js'

import Dt_Cal from "../../lib/ui/dt_cal";
import Dd_Sel from "../../lib/ui/dd_sel";

import PdfRpDlg from "./fkn/pdf_rp_dlg";
import Trs_Per from "./trs/trs_per";
import Trs_Beh from "./trs/trs_beh";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Pos_Red_Trs extends React.Component {
  constructor(props, context) {
    super(props);

    this.ref_dat = React.createRef();

    this.store = props.store;
    this.pos = props.store.pos;

    this.state = {};

    var dt = new Date();
    var dts = format(dt, "yyyy-MM-dd");
    this.state.today = dts;
    this.state.datum = dts;

    this.state.avd = ddo.cache.avd;
    this.state.vgrp = "";
    this.state.wnds = {};
    this.state.sdo = {};
    this.state.sdo.fsg = "";
    this.state.sdo.bet = "";
    this.state.trs = [];
    this.state.tra = [];
    this.state.aa = [];

    this.state.pdf = 0;
    this.state.pdf_content = null;

    //if(this.pos.deps && this.pos.deps.length) {
    //  this.state.avd = this.pos.deps[0].avd;
    //}
      this.fknitems = [
          {fkn: 'rp_notor', text: 'SKRIV NOTOR'},
          {fkn: 'rp_trs', text: 'SKRIV LISTA'},
          {fkn: 'per', text: 'VISA PERIOD'}
      ];
      if(false) this.fknitems.push({fkn: 'beh', text: 'BEHANDLA'});
      this.fknitems.push({fkn: 'trs', text: 'UPPDATERA'});
      
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 220;
      css.overfow = "auto";
      return css;
  }
  th_style(id) {
      var css = {};
      css.cursor = "pointer";
      if(id == this.state.sortid) css.color = "#080";
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }
    dt_dat(e) {
        //alert(JSON.stringify(e));
        var dts = format(e, "yyyy-MM-dd");
        this.setState({ datum: dts });
    }
    addday(e) {
        //alert(JSON.stringify(e));
        //this.setState({ tdat: this.state.fdat });
        this.ref_dat.current.updateAdd();
    }
    subday(e) {
        //alert(JSON.stringify(e));
        //this.setState({ tdat: this.state.fdat });
        this.ref_dat.current.updateSub();
    }
  xdt_dat(e) {
    //alert(JSON.stringify(e));
    var dts = format(e, "yyyy-MM-dd");
    this.setState({ datum: dts });
  }
  change_avd(e) {
    //let cdat = this.state.seldate;
    let avd = e.target.value;
    this.setState({avd: avd});
  }
  change_vgrp(e) {
    var self = this;
    //let cdat = this.state.seldate;
    let vgrp = e.target.value;
    this.setState({ vgrp: vgrp }, function(){
        self.vgrp_filtr();
      });
  }
    pdf_done (ret) {
        this.setState({loading: 0, pdf: 0, pdf_content: null });
    }
    trs_open (row) {
    }

  trs() {
    var self = this;

    if(this.state.datum == this.state.today) {
        self.st_trs();
        return;
    }
    	var prm = {};
     	prm.req = "pos.pos_trs.trs_per";
     	//prm.req = "pos.pos_red.jr_red";
   		prm.avd = this.state.avd;
   		prm.datum = this.state.datum;
   		prm.fdat = this.state.datum;
   		prm.tdat = this.state.datum;
   		prm.favd = this.state.avd;
   		prm.tavd = this.state.avd;

        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret.rco));

            self.setState({tra: ret.rca, trs: ret.rca, sdo: ret.sdo });
            self.syncTb("trsTable");
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
            //gda.wndError(txt);
        });
  }
    st_trs() {
        var self = this;
        var prm = {};
        prm.req = "pos.pos_eko.trs";
        prm.avd = this.state.avd;

        //gda.wndLoading("Hämtar");
        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.rca));

                self.setState({tra: ret.rca, trs: ret.rca });
                //self.syncTb();
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
                //gda.wndError(txt);
            });
    }
    rp_trs() {
    var self = this;
    	var prm = {};
     	prm.req = "pos.pos_rp.rp_trs";
   		prm.fdat = this.state.datum;
   		prm.tdat = this.state.datum;
   		prm.favd = this.state.avd;
   		prm.tavd = this.state.avd;

        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret.pdf));

        self.setState({pdf: 1, pdf_content: ret.pdf });
            //toastr.success("OK", "SAPI");
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
            //gda.wndError(txt);
        });
  }
  rp_notor() {
    var self = this;
    	var prm = {};
     	prm.req = "pos.pos_rp.rp_notor";
   		prm.fdat = this.state.datum;
   		prm.tdat = this.state.datum;
   		prm.favd = this.state.avd;
   		prm.tavd = this.state.avd;

        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret.pdf));

        self.setState({pdf: 1, pdf_content: ret.pdf });
            //toastr.success("OK", "SAPI");
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
            //gda.wndError(txt);
        });
  }
    trs_per() {
        var self = this;
        var wnds = {};
        wnds.trsper = true;
        this.setState({ wnds: wnds });
    }
    trs_beh() {
        var self = this;
        var wnds = {};
        wnds.trsbeh = true;
        this.setState({ wnds: wnds });
    }
    syncTb(tb){
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
            tdElements = tbel.getElementsByTagName('td');

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
                width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
    tb_sort(typ) {
        var self = this;
        //var rvs = self.state.rvs;
        var tra = self.state.tra;
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;

        if(sortid != typ) sortdir = 0;
        else {
            if(sortdir) sortdir = 0;
            else sortdir = 1;
        }

        if(typ.indexOf(".") != -1) {
            var fa = typ.split(".");
            if(sortdir) tra.sort((a,b) => (a[fa[0]][fa[1]] < b[fa[0]][fa[1]]) ? 1 : ((b[fa[0]][fa[1]] < a[fa[0]][fa[1]]) ? -1 : 0));
            else tra.sort((a,b) => (a[fa[0]][fa[1]] > b[fa[0]][fa[1]]) ? 1 : ((b[fa[0]][fa[1]] > a[fa[0]][fa[1]]) ? -1 : 0));
        }
        else {
            if(sortdir) tra.sort((a,b) => (a[typ] < b[typ]) ? 1 : ((b[typ] < a[typ]) ? -1 : 0));
            else tra.sort((a,b) => (a[typ] > b[typ]) ? 1 : ((b[typ] > a[typ]) ? -1 : 0));
        }
        self.setState({ tra: tra, sortid: typ, sortdir: sortdir });
    }
    th_icon(typ) {
        var cls = "";
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;
        if(sortid == typ)
        {
            if(sortdir) cls = "fa fa-caret-up";
            else cls = "fa fa-caret-down";
        }
        return cls;
    }
  tb_filtr() {
      var self = this;
      var trs = self.state.trs;
      var sz = this.state.sz_val;

      if(sz.length < 1) {
        self.setState({ tra: trs });
          return;
      }
      sz = sz.toUpperCase();
      var tra = trs.filter(function(row) {
            var brc = false;
            if(row.r0text.toUpperCase().includes(sz)) brc = true;
            if(row.r0etyp.toUpperCase().includes(sz)) brc = true;
            return brc;
      });
      self.setState({tra: tra });
   }
  vgrp_filtr() {
      var self = this;
      var trs = self.state.trs;
      var vgrp = this.state.vgrp;

      if(vgrp.length < 1) {
        self.setState({ tra: trs });
          return;
      }
      var tra = trs.filter(function(row) {
            var brc = false;
            if(row.r0roomno.substr(1, 3) == vgrp) brc = true;
            return brc;
      });
      self.setState({tra: tra });
   }
    sel_fkn(sel) {
        //alert(JSON.stringify(fkn));
        if(sel.fkn == "rp_notor") {
            this.rp_notor();
        }
        if(sel.fkn == "rp_trs") {
            this.rp_trs();
        }
        if(sel.fkn == "per") {
            this.trs_per();
        }
        if(sel.fkn == "trs") {
            this.trs();
        }
        if(sel.fkn == "beh") {
            this.trs_beh();
        }
    }


  render() {
    return (
    <div className="web-p00 flex flex-col">
        <div className="">
            Transaktioner
        </div>

    <div className="web-p01">
    <div className="flex-row">
        <div className="flex-row">
            <div className="dt-btn-01" onClick={() => this.subday() } ><FontAwesomeIcon icon="minus" size="1x" /></div>
            <Dt_Cal dts={this.state.datum} rp="1" ref={this.ref_dat} getValue={(e)=> this.dt_dat(e)} />
            <div className="dt-btn-01" onClick={() => this.addday() } ><FontAwesomeIcon icon="plus" size="1x" /></div>
        </div>
        <select className="form-control w250" placeholder="Avdelning" onChange={(e) => this.change_avd(e)}>
            { this.props.store.pos.deps.map((row, key) =>
            <option key={key} value={row.avd}>{row.avd} - {row.text}</option>
            )}
        </select>
        <select className="form-control w250" placeholder="Varugrupp" onChange={(e) => this.change_vgrp(e)}>
            { this.props.store.pos.vgrps.map((row, key) =>
            <option key={key} value={row.v0katnr}>{row.v0katnr} - {row.v0text}</option>
            )}
        </select>
        <div className="flex"></div>
        <button className="btn btn-hw-blue w150" onClick={() => this.trs() } aria-label="System" >
            Visa
        </button>
        <Dd_Sel items={ this.fknitems } cls="web-fkn-icon" getValue={(e)=> this.sel_fkn(e)} />
    </div>
    <div className="divider_orange"></div>

    <div id="trsTable" className="flex">
        <table className="table table-bordered tb">
            <thead>
            <tr>
                <th style={ this.th_style("r0datum") } onClick={() => this.tb_sort("r0datum") }>Datum <i className={ this.th_icon("r0datum") }></i></th>
                <th style={ this.th_style("r0time") } onClick={() => this.tb_sort("r0time") }>Tid <i className={ this.th_icon("r0time") }></i></th>
                <th style={ this.th_style("r0cstplc") } onClick={() => this.tb_sort("r0cstplc") }>Avd <i className={ this.th_icon("r0cstplc") }></i></th>
                <th style={ this.th_style("r0tablnr") } onClick={() => this.tb_sort("r0tablnr") }>Bordnr <i className={ this.th_icon("r0tablnr") }></i></th>
                <th style={ this.th_style("r0faktnr") } onClick={() => this.tb_sort("r0faktnr") }>Notanr <i className={ this.th_icon("r0faktnr") }></i></th>
                <th style={ this.th_style("r0etyp") } onClick={() => this.tb_sort("r0etyp") }>Plu/Betkod <i className={ this.th_icon("r0etyp") }></i></th>
                <th style={ this.th_style("r0text") } onClick={() => this.tb_sort("r0text") }>Text <i className={ this.th_icon("r0text") }></i></th>
                <th style={ this.th_style("r0noof") } onClick={() => this.tb_sort("r0noof") }>Antal <i className={ this.th_icon("r0noof") }></i></th>
                <th style={ this.th_style("r0perprice") } onClick={() => this.tb_sort("r0perprice") }>Pris <i className={ this.th_icon("r0perprice") }></i></th>
                <th style={ this.th_style("summa") } onClick={() => this.tb_sort("summa") }>Summa <i className={ this.th_icon("summa") }></i></th>
                <th style={ this.th_style("r0debcre") } onClick={() => this.tb_sort("r0debcre") }>Tecken <i className={ this.th_icon("r0debcre") }></i></th>
                <th style={ this.th_style("r0vatproc") } onClick={() => this.tb_sort("r0vatproc") }>Moms <i className={ this.th_icon("r0vatproc") }></i></th>
                <th style={ this.th_style("r0rumsnr") } onClick={() => this.tb_sort("r0rumsnr") }>Rumsnr <i className={ this.th_icon("r0rumsnr") }></i></th>
                <th style={ this.th_style("r0terminal") } onClick={() => this.tb_sort("r0terminal") }>Terminal <i className={ this.th_icon("r0terminal") }></i></th>
                <th style={ this.th_style("r0sign") } onClick={() => this.tb_sort("r0sign") }>Signatur <i className={ this.th_icon("r0sign") }></i></th>
                <th style={ this.th_style("r0dateid") } onClick={() => this.tb_sort("r0dateid") }>ID <i className={ this.th_icon("r0dateid") }></i></th>
            </tr>
            </thead>
        </table>
        <div style={ this.tb_style() }>
            <table className="table table-bordered table-hover tb" style={{marginBottom:"0px"}}>
            <tbody style={ this.tb_style() }>
            { this.state.tra.map((row, key) =>
            <tr key={key} onDoubleClick={() => this.trs_open(row) } >
                <td>{ utils.toDate(row.r0datum) }</td>
                <td className="text-center">{ utils.toTid(row.r0time) }</td>
                <td className="text-center">{ row.r0cstplc }</td>
                <td className="text-center">{ row.r0tablnr }</td>
                <td>{ utils.toNum(row.r0faktnr) }</td>
                <td>{ row.r0etyp }</td>
                <td>{ utils.toLang(row.r0text) }</td>
                <td className="text-center">{ utils.toNum(row.r0noof) }</td>
                <td className="text-right">{ utils.toPris(row.r0perprice) }</td>
                <td className="text-right">{ utils.toPris(((row.r0noof * 1) * (row.r0perprice * 1))) }</td>
                <td>{ row.r0debcre }</td>
                <td>{ row.r0vatproc }</td>
                <td>{ row.r0rumsnr }</td>
                <td>{ row.r0terminal }</td>
                <td>{ row.r0sign }</td>
                <td>{ row.r0dateid }</td>
            </tr>
            )}
            </tbody>
        </table>
        <div className="divider_orange"></div>
        <div className="flex-row">
            <span className="flex">TOTAL: { this.state.tra.length }</span>
            <span className="flex">FSG: { this.state.sdo.fsg.cdipris() }</span>
            <span className="flex">BET: { this.state.sdo.bet.cdipris() }</span>
        </div>
        </div>
    </div>

    </div>

        { this.state.wnds.trsper ? <Trs_Per wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.wnds.trsbeh ? <Trs_Beh wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
        <PdfRpDlg show={this.state.pdf} pdf={this.state.pdf_content} done={(ret) => this.pdf_done(ret)} backdrop={true}/>
    </div>
  );
    }
}
export default observer(Pos_Red_Trs);
