import React, { useState, useEffect, useContext } from 'react';
import {format, getHours, subDays} from "date-fns";

import * as net from "../../lib/net";
import {SDOProvider} from "../../sdo";

import Dt_Cal from "../../lib/ui/dt_cal";
import PdfRpDlg from "./pdf_rp_dlg";
import ddo from "../../ddo";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Rps_Cas extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, tra: [] };
    this.state.seldate = new Date();
    //this.state.unit = null;
    this.state.sdo = {};
    this.state.unitid = "00";
    this.state.unit = {};
      //this.state.una = Object.values(ddo.cfg.units);
      this.state.una = ddo.cfg.una;
      this.state.uno = ddo.cfg.units;

      this.state.today = new Date();
      var hh = getHours(this.state.today);
      if( hh < 7) {
          const dt = subDays(new Date(), 1);
          this.state.datum = format(dt, "yyyy-MM-dd");
      }
      else this.state.datum = format(this.state.today, "yyyy-MM-dd");
    //this.state.today = format(new Date(), "yyyy-MM-dd");
    //this.state.datum = format(new Date(), "yyyy-MM-dd");
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.dagsavslut(this.state.unitid);
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 250;
      css.overflowY = "auto";
      return css;
  }

  dt_dat(e) {
    //alert(JSON.stringify(e));
    var dts = format(e, "yyyy-MM-dd");
    this.setState({ datum: dts });
  }

  onUnitChange(e) {
        this.setState({unit: e.value});
  }
  change_unit(e) {
    let unitid = e.target.value;
    var o = this.state.uno[unitid];
    this.setState({unitid: unitid, unit: o, sdo: {} });
    this.dagsavslut(unitid);
  }

  dagsavslut(unitid) {
    var self = this;

    var avd = "";
    var sid = null;
    //var unitid = this.state.unitid;
    if(unitid) {
        var o = this.state.uno[unitid];
        //sid = o.sid;
        avd = o.id;
    }
    if(!sid) {
        //alert("VÄLJ ENHET");
        //self.setState({sdo: {} });
        //return;
    }

    var datum = this.state.datum;
    var prm = {};
    prm.avd = avd;
    prm.req = "pos.pos_mlager.dagsavslut";
    prm.datum = datum;

    //alert(JSON.stringify(prm));
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rco));
        if(ret.ok != "000") {
            self.setState({sdo: {} });
            return;
        }

        self.setState({sdo: ret.rco });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
    }

  dagsavslut_prn(unitid) {
    var self = this;

      var avd = "";
      var enhet = "";
    var sid = null;
    //var unitid = this.state.unitid;
    if(unitid) {
        var o = this.state.uno[unitid];
        //sid = o.sid;
        avd = o.id;
        enhet = o.text;
    }
    if(!sid) {
        //alert("VÄLJ ENHET");
        //self.setState({sdo: {} });
        //return;
    }

    var datum = this.state.datum;
    var prm = {};
    //prm.req = "pos.pos_mlager.dagsavslut";
    prm.req = "pos.pos_casino_rp.cas_dslut";
    prm.datum = datum;
    prm.avd = avd;
    prm.enhet = enhet;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rco));
        if(ret.ok != "000") {
            return;
        }

        self.setState({pdf: 1, pdf_content: ret.pdf });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
    }
  pdf_done (ret) {
    this.setState({loading: 0, pdf: 0, pdf_content: null });
  }
  show_val(id) {
     var val = this.state.sdo[id];
     if(!val) val = "0";
     val = val.toString().cdipris();
     val = val + " SEK";
     return val;
  }
  show_mark(mid, id) {
    if(!this.state.sdo[mid]) {
     return "0";
    }
     var val = this.state.sdo[mid][id];
     if(!val) val = "0";
     val = val.toString().ztrim();
     //val = val.toString().cdipris();
     //val = val + " SEK";
     return val;
  }
  show_diff(id) {
    if(!this.state.sdo.min) {
     return "0";
    }
    if(!this.state.sdo.mut) {
     return "0";
    }
     var ival = this.state.sdo.min[id];
     if(!ival) ival = "0";
     var uval = this.state.sdo.mut[id];
     if(!uval) uval = "0";
     var val = uval - ival;
     val = val.toString().ztrim();
     //val = val.toString().cdipris();
     //val = val + " SEK";
     return val;
  }

  render() {
    return (
    <div className="web-app">
    <h3>Rapport</h3>

    <div className="flex-row flex-space-between">
        <div className="flex-row">
        <Dt_Cal dts={this.state.datum} getValue={(e)=> this.dt_dat(e)} />
        <select className="form-control sel_input w300" placeholder="Välj enhet" value={this.state.unitid} onChange={(e) => this.change_unit(e)} >
            { this.state.una.map((row, key) =>
            <option key={key} value={row.id}>{row.text}</option>
            )}
        </select>
        </div>
        <div className="flex"></div>
         <button className="btn btn-hw-blue w150 ripple" onClick={() => this.dagsavslut_prn(this.state.unitid) } aria-label="System" >
            SKRIV UT
        </button>
         <button className="btn btn-hw-blue w150 ripple" onClick={() => this.dagsavslut(this.state.unitid) } aria-label="System" >
            VISA
        </button>
   </div>
    <div className="divider_orange"></div>

    <div style={ this.tb_style() }>
    <div className="web-kvitto">
    <div className="web-row">
        <div className="txt_20">KASINO RAPPORT</div>
    </div>
    <div className="web-row">
        <div className="txt_16">{ this.state.datum }</div>
    </div>
    <div className="web-row">
        <div className="txt_16">{ this.state.unit.text }</div>
    </div>

    <br />

    <div className="divider_orange"></div>
    <div className="web-row">
        <div className="txt_b16">FÖRSÄLJNING</div>
    </div>
    <div className="web-row txt_16">
        <div className="f50">CASHBOX</div>
        <div className="f20">#</div>
        <div className="f30 text-right">{ this.show_val("cash")}</div>
    </div>
    <div className="web-row txt_16">
        <div className="f50">KONTANT</div>
        <div className="f20">#</div>
        <div className="f30 text-right">{ this.show_val("kont")}</div>
    </div>
    <div className="web-row txt_16">
        <div className="f50">KORT</div>
        <div className="f20">#</div>
        <div className="f30 text-right">{ this.show_val("kort")}</div>
    </div>
    <div className="web-row txt_b16">
        <div className="f50">TOTAL FSG</div>
        <div className="f20">#</div>
        <div className="f30 text-right">{ this.show_val("fsg") }</div>
    </div>

    <div className="divider_orange"></div>
    <div className="web-row txt_16">
        <div className="f50">VINST UTBETALD</div>
        <div className="f20">#</div>
        <div className="f30 text-right">{ this.show_val("retur")}</div>
    </div>
    <div className="web-row txt_16 flex-space-between">
        <div className="f50">ÅTERKÖPTA</div>
        <div className="f20">#</div>
        <div className="f30 text-right">{ this.show_val("ater")}</div>
    </div>
    <div className="web-row txt_b16 flex-space-between">
        <div className="f50">KASSANETTO</div>
        <div className="f20">#</div>
        <div className="f30 text-right">{ this.show_val("kassanetto")}</div>
    </div>

    <div className="divider_orange"></div>
    <div className="web-row">
        <div className="txt_b16">MARKER SUMMERING</div>
    </div>
    <div className="web-row txt_16 flex-space-between">
        <div className="f50">IN</div>
        <div className="f20">#</div>
        <div className="f30 text-right">{ this.show_val("in")}</div>
    </div>
    <div className="web-row txt_16 flex-space-between">
        <div className="f50">UT</div>
        <div className="f20">#</div>
        <div className="f30 text-right">{ this.show_val("ut")}</div>
    </div>
    <div className="web-row txt_16 flex-space-between">
        <div className="f50">DIFF</div>
        <div className="f20">#</div>
        <div className="f30 text-right">{ this.show_val("diff")}</div>
    </div>

    <div className="divider_orange"></div>
    <div className="web-row">
        <div className="txt_b16">TOTALT</div>
    </div>
    <div className="web-row txt_b16 flex-space-between">
        <div className="f50">NETTOINSPELAT</div>
        <div className="f20">#</div>
        <div className="f30 text-right">{ this.show_val("netto")}</div>
    </div>
    <div className="web-row txt_b16 flex-space-between">
        <div className="f50">BANKNING</div>
        <div className="f20">#</div>
        <div className="f30 text-right">{ this.show_val("banka")}</div>
    </div>

    <div className="divider_orange"></div>
    <div className="web-row">
        <div className="txt_b16">MARKER KONTROLL</div>
    </div>
    <div className="web-row txt_16 flex-space-between">
        <div className="f20">VAL</div>
        <div className="f20 text-valr">IN</div>
        <div className="f20 text-valr">UT</div>
        <div className="f20 text-valr">KÖP</div>
        <div className="f20 text-valr">DIFF</div>
    </div>
    <div className="web-row txt_16 flex-space-between">
        <div className="f20">5</div>
        <div className="f20 text-valr">{ this.show_mark("min", "m0m01")}</div>
        <div className="f20 text-valr">{ this.show_mark("mut", "m0m01")}</div>
        <div className="f20 text-valr">{ this.show_mark("mater", "m0m01")}</div>
        <div className="f20 text-valr">{ this.show_diff("m0m01")}</div>
    </div>
    <div className="web-row txt_16 flex-space-between">
        <div className="f20">10</div>
        <div className="f20 text-valr">{ this.show_mark("min", "m0m02")}</div>
        <div className="f20 text-valr">{ this.show_mark("mut", "m0m02")}</div>
        <div className="f20 text-valr">{ this.show_mark("mater", "m0m02")}</div>
        <div className="f20 text-valr">{ this.show_diff("m0m02")}</div>
    </div>
    <div className="web-row txt_16 flex-space-between">
        <div className="f20">20</div>
        <div className="f20 text-valr">{ this.show_mark("min", "m0m03")}</div>
        <div className="f20 text-valr">{ this.show_mark("mut", "m0m03")}</div>
        <div className="f20 text-valr">{ this.show_mark("mater", "m0m03")}</div>
        <div className="f20 text-valr">{ this.show_diff("m0m03")}</div>
    </div>
    <div className="web-row txt_16 flex-space-between">
        <div className="f20">50</div>
        <div className="f20 text-valr">{ this.show_mark("min", "m0m04")}</div>
        <div className="f20 text-valr">{ this.show_mark("mut", "m0m04")}</div>
        <div className="f20 text-valr">{ this.show_mark("mater", "m0m04")}</div>
        <div className="f20 text-valr">{ this.show_diff("m0m04")}</div>
    </div>
    <div className="web-row txt_16 flex-space-between">
        <div className="f20">100</div>
        <div className="f20 text-valr">{ this.show_mark("min", "m0m05")}</div>
        <div className="f20 text-valr">{ this.show_mark("mut", "m0m05")}</div>
        <div className="f20 text-valr">{ this.show_mark("mater", "m0m05")}</div>
        <div className="f20 text-valr">{ this.show_diff("m0m05")}</div>
    </div>
    <div className="web-row txt_16 flex-space-between">
        <div className="f20">200</div>
        <div className="f20 text-valr">{ this.show_mark("min", "m0m06")}</div>
        <div className="f20 text-valr">{ this.show_mark("mut", "m0m06")}</div>
        <div className="f20 text-valr">{ this.show_mark("mater", "m0m06")}</div>
        <div className="f20 text-valr">{ this.show_diff("m0m06")}</div>
    </div>

    </div>
    </div>

        <PdfRpDlg show={this.state.pdf} pdf={this.state.pdf_content} done={(ret) => this.pdf_done(ret)} backdrop={true}/>
    </div>
  );
    }
}
export default Rps_Cas;

/*
    <div className="divider_orange"></div>
    <div className="web-row">
        <div className="txt_b16">PASS</div>
    </div>
    <div className="web-row txt_16">
        <div className="f50">NAMN</div>
        <div className="f20"></div>
        <div className="f30 text-right">{ this.state.sdo.namn }</div>
    </div>
    <div className="web-row txt_16">
        <div className="f50">START</div>
        <div className="f20"></div>
        <div className="f30 text-right">{ this.state.sdo.start }</div>
    </div>
    <div className="web-row txt_16">
        <div className="f50">SLUT</div>
        <div className="f20"></div>
        <div className="f30 text-right">{ this.state.sdo.slut }</div>
    </div>

*/