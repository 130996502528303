import React, { useState, useEffect, useContext } from 'react';
import * as net from "../../../lib/net";
import Dd_Sel from "../../../lib/ui/dd_sel";

import Pms_Rvo from "../../rv/rvo";
import Fr_Input from "../../../lib/ui/fr_input";

class Gst_Ch_Info extends React.Component {
  constructor(props, context) {
    super(props);

     this.selitems = [
        {fkn: 'setup', text: 'INSTÄLLNINGAR'},
        {fkn: 'upd', text: 'UPPDATERA'},
        {fkn: 'sys', text: 'SYSTEM'},
        {fkn: 'logout', text: 'LOGGA UT'}
    ];

    this.state = {};
    this.state.ynq = [];
    this.state.paa = [];
    this.state.aa = [];
    this.state.gso = {};
    if(props.wnds) {
        this.fkn_close = props.wnds.fkn_close;
        this.state.boknr = props.wnds.boknr;
        this.state.rvo = props.wnds.rvo;
    }
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      this.gst_init();
  }

      gst_init() {
      var gso = {};
      if(this.state.rvo.gsb00) {
          gso.namn = this.state.rvo.gsb00.r0namn;
          gso.nrg = this.state.rvo.gsb00.r0guests;
          gso.rumsnr = this.state.rvo.gsb00.r0rumsnr;
          gso.ankdat = this.state.rvo.gsb00.r0ankdatum;
          gso.avrdat = this.state.rvo.gsb00.r0avrdatum;
          gso.ankdat = gso.ankdat.cdidate();
          gso.avrdat = gso.avrdat.cdidate();
          gso.rumsnr = gso.rumsnr.cdirumsnr();
      }
      this.setState({gso: gso});
    }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }

    wnd_close() {
      if(this.fkn_close) this.fkn_close(this.props.ctx);
      else this.props.ctx.setState({ wnds: {} });
	};
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  fknSel(sel) {
    //alert(JSON.stringify(fkn));
      if(sel == "rvo") {
        this.setState({wnd_rvo: true});
      }
      if(sel == "gst") {
        this.setState({wnd_gst: true});
      }
  }
    rvo_close(ctx) {
        ctx.setState({ wnd_rvo: false });
    };
  sel_fkn(sel) {
    //alert(JSON.stringify(fkn));
      if(sel.fkn == "logout") {
      }
  }

  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "";

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="m2_backdrop" /> : null }
        <div className="m2_dlg mx_80 flex-col" style={ this.dlg_style() }>
        <div className="flex-row " style={{padding:"0px",height:"50px",lineHeight:"50px", background: "#1a456b", color: "#fff"}}>
                <div className="flex-row flex" style={{padding:"0px 5px", fontSize: "22px"}}>
                    <div>Gäst Information { this.state.rumsnr }</div>
                </div>
            <div className="wnd-close-btn" onClick={() => this.wnd_close() }>X</div>
        </div>
        <div className="mx_content flex">

            <div className="divider_orange"></div>

            <form className="cdi_f03">
            <div className="flex-row py-2">
                    <Fr_Input label="Ankomst" className="text-left w350" text={this.state.gso.ankdat} getValue={(e)=> this.val_save("namn", e) } />
                    <Fr_Input label="Avresa" className="text-left w250" text={this.state.gso.avrdat} getValue={(e)=> this.val_save("rumsnr", e) } />
            </div>
            <div className="flex-row py-2">
                    <Fr_Input label="Namn" className="text-left w350" text={this.state.gso.namn} getValue={(e)=> this.val_save("namn", e) } />
                    <Fr_Input label="Rumsnr" className="text-left w250" text={this.state.gso.rumsnr} getValue={(e)=> this.val_save("rumsnr", e) } />
            </div>
            <div className="flex-row py-2">
                    <Fr_Input label="Antal" className="text-left w150" text={this.state.gso.nrg} getValue={(e)=> this.val_save("nrg", e) } />
                    <Fr_Input label="Barn" className="text-left w250" text={this.state.gso.nrb} getValue={(e)=> this.val_save("nrb", e) } />
            </div>
            <div className="divider_green"></div>
            <div className="flex-row py-2">
                    <Fr_Input label="Företag" className="text-left w350" text={this.state.gso.ftg} getValue={(e)=> this.val_save("ftg", e) } />
            </div>
            <div className="flex-row py-2">
                    <Fr_Input label="Epost" className="text-left w250" text={this.state.gso.email} getValue={(e)=> this.val_save("email", e) } />
                    <Fr_Input label="Pass" className="text-left w250" text={this.state.gso.pass} getValue={(e)=> this.val_save("pass", e) } />
            </div>
            <div className="flex-row py-2">
                    <Fr_Input label="Telefon" className="text-left w250" text={this.state.gso.tele} getValue={(e)=> this.val_save("tele", e) } />
                    <Fr_Input label="Mobil" className="text-left w250" text={this.state.gso.mobile} getValue={(e)=> this.val_save("mobile", e) } />
            </div>
            <div className="divider_green"></div>
            </form>


        </div>
        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250 ripple" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex"></div>
            <button className="btn btn-hw-green w250 ripple" onClick={(event) => this.wnd_close() } >
                SPARA
            </button>
        </div>
      </div>
            { this.state.wnd_rvo ?
        <Pms_Rvo close={this.rvo_close} boknr={this.state.boknr} ctx={this} store={this.props.store} backdrop={true}/>
            : null }
    </div>
	;
	return html;
  };
}

export default Gst_Ch_Info;