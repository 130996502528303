import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../../lib/net";
import * as utils from "../../../assets/utils";

import Fr_Input from "../../../lib/ui/fr_input";
import Dd_Sel from "../../../lib/ui/dd_sel";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Rv_Profil extends React.Component {
  constructor(props, context) {
    super(props);

    this.selitems = [
        {fkn: 'bevaka', text: 'BEVAKA'},
        {fkn: 'bokbild', text: 'BOKNINGSBILD'},
        {fkn: 'confirm', text: 'BEKRÄFTA'},
        {fkn: 'cancel', text: 'AVBOKA'}
    ];

    this.store = props.store;
    this.state = {};
    this.state.ynq = [];
    //this.state.vga = [];
    this.state.rta = [];
    this.state.bra = [];

    this.state.bgo = {};
    this.state.cols = {};

    this.state.ix = 0;
    this.state.boknr = "";
    if(props.boknr) this.state.boknr = props.boknr;

    this.state.rvo = {};
    this.state.rvo.nrr = "1";
    this.state.rvo.sum = "2000";
    this.state.rvo.rvo = {};

    this.state.kro = {};
    this.state.kro.krr00 = {};
    this.state.kro.krr01 = {};
    this.state.kro.krr02 = {};
    this.state.kro.krr03 = {};
    
    this.state.pfo = {};
    this.state.pfb = {};
    if(props.wnds) {
        if(props.wnds.pfo) this.state.pfo = props.wnds.pfo;
        if(props.wnds.pfb) this.state.pfb = props.wnds.pfb;
   }
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      //this.brs();
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }

    formchange (e) {
      const name = e.target.name;
      const value = e.target.value;
      var rvo = this.state.rvo;
      var na = name.split(".");
      rvo[na[0]][na[1]] = value;
      this.setState({rvo: rvo});
      //setState({[name]: value});
    }
    rsv_anm_change (e) {
      const name = e.target.name;
      const value = e.target.value;
      var rsv = this.state.rsv;
      var na = name.split(".");
      rsv[na[0]][na[1]] = value;
      this.setState({rsv: rsv});
      //setState({[name]: value});
    }

    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    wnd_close = () => {
      if(this.props.close) {
          this.props.close(this.props.ctx);
      }
      else this.props.ctx.setState({ wnds: {} });
	};
    drag(event) {
        //event.dataTransfer.setData("text", event.target.id);
	};
    drop(event) {
        event.preventDefault();
        alert("Drop");
	};
    dragEnter(event) {
        event.preventDefault();
        event.target.style.color = "#fff";
        event.target.style.background = "#ccc";
        //alert("Allow Drop");
	};
    dragLeave(event) {
        event.preventDefault();
        event.target.style.color = "#fff";
        event.target.style.background = "#080";
        //alert("Allow Drop");
	};
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  cssCols (cid) {
      var css = {};
      if(this.state.cols[cid]) css.width = this.state.cols[cid].toString() + "px";
      //if(cid == "c01") alert(JSON.stringify(this.state.cols[cid]));
      return css;
  }
  syncCols (tb) {

    if (!document.getElementById(tb)) return;

    var cols = {};
    var ix = document.getElementById(tb).rows.length - 1;
    var ic = document.getElementById(tb).rows[0].cells.length;
    var ci = 0;
    while(ci < ic) {
        var cxi = ci + 1;
        var col = "c" + cxi.toString().padStart(2, "0");
        cols[col] = document.getElementById(tb).rows[ix].cells[ci].clientWidth + 1;
        ci = ci + 1;
    }
    this.setState({cols: cols });
    //alert(JSON.stringify(this.state.cols));
    //this.forceUpdate();
  };
  mnu_toggle() {
    //props.ctx.setState({ mnu_show: !props.ctx.state.mnu_show });
    //props.store.cfg.mnu_show = !props.store.cfg.mnu_show;
  };
    val_save (name, value) {
        //alert(JSON.stringify(name));
        //alert(JSON.stringify(value));
      var rvo = this.state.rvo;
      var na = name.split(".");
      rvo[na[0]][na[1]] = value;
      this.setState({rvo: rvo});
      //setState({[name]: value});
    }
  sel_fkn(sel) {
    //alert(JSON.stringify(fkn));
      if(sel.fkn == "logout") {
      }
  }
  brs() {
    var self = this;

    var prm = {};
    prm.req = "pms.br.bra";
    prm.boknr = this.state.boknr;
    //alert(JSON.stringify(prm));

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            return;
        }
        self.setState({bra: ret.rca });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
    return;
  }
  kk() {
    var self = this;

    return;
    var prm = {};
    prm.req = "pos.pos_plu.plu_bufsave";
    prm.fkn = "7221";
    prm.boknr = this.state.boknr;
    //alert(JSON.stringify(prm));

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            return;
        }
        self.setState({gsa: ret.rca });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
    return;
  }

  render() {
    //if(!this.props.show) return "";

    //alert(this.props.ctx.state.lro);

    var title = this.props.title;
    if(!title) title = "Bokning";
    //if(props.kro) alert(props.kro.krr00.kid);

    var html =
    <div className="mx_base">
    <div className="m2_backdrop" />
    <div className="m2_dlg mx_90 flex-col" style={ this.dlg_style() } >

    <div className="mx_h01">
        <div>PROFIL: { this.state.pfb.id }</div>
    </div>
    <div className="mx_content flex">

        <div className="divider_orange"></div>

        <form className="cdi_f03">
        <div className="flex-row py-2">
                <Fr_Input label="Namn" className="text-left w350" text={this.state.pfb.namn} getValue={(e)=> this.val_save("namn", e) } />
        </div>
        <div className="flex-row py-2">
                <Fr_Input label="Företag" className="text-left w350" text={this.state.pfb.ftg} getValue={(e)=> this.val_save("ftg", e) } />
                <Fr_Input label="Orgnr" className="text-left w250" text={this.state.pfb.orgnr} getValue={(e)=> this.val_save("rumsnr", e) } />
        </div>
        <div className="divider_green"></div>
        <div className="flex-row py-2">
                <Fr_Input label="Epost" className="text-left w250" text={this.state.pfb.email} getValue={(e)=> this.val_save("email", e) } />
        </div>
        <div className="flex-row py-2">
                <Fr_Input label="Telefon" className="text-left w250" text={this.state.pfb.tele} getValue={(e)=> this.val_save("tele", e) } />
                <Fr_Input label="Mobil" className="text-left w250" text={this.state.pfb.mobil} getValue={(e)=> this.val_save("mobile", e) } />
        </div>
        <div className="divider_green"></div>
        <div className="flex-row py-2">
                <Fr_Input label="Rumsnr" className="text-left w150" text={this.state.pfb.rumsnr} getValue={(e)=> this.val_save("nrg", e) } />
                <Fr_Input label="Status" className="text-left w250" text={this.state.pfb.status} getValue={(e)=> this.val_save("nrb", e) } />
        </div>
        <div className="flex-row py-2">
                <Fr_Input label="Nivå" className="text-left w150" text={this.state.pfb.niva} getValue={(e)=> this.val_save("niva", e) } />
        </div>
        </form>

        <div className="divider_orange"></div>

        <div className="px-3">
        <p>Tidigare vistelser:</p>
        <table className="table table-bordered table-hover">
        <tbody>
        { this.state.pfb.besok.map((row, key) =>
        <tr key={key} onDoubleClick={() => this.visit_edit(row) }>
            <td>{ utils.toDate(row.r0ankdatum) }</td>
            <td>{ utils.toDate(row.r0avrdatum) }</td>
            <td>{ utils.toRumsnr(row.r0rumsnr) }</td>
            <td>{ utils.toLang(row.r0namn) }</td>
            <td>{ row.r0status }</td>
        </tr>
        )}
        </tbody>
    </table>
    </div>
    </div>

    <div className="mx_foot flex-row flex-space-between">
        <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
            AVBRYT
        </button>
        <div className="flex"></div>
        <div className="flex"></div>
        <button className="btn btn-hw-green w250" onClick={() => this.wnd_close() } >
            STÄNG
        </button>
    </div>
  </div>
  </div>
;
return html;
};
}

export default Rv_Profil;