import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../../lib/net";
import Fr_Input from "../../../lib/ui/fr_input";

class Prd_New extends React.Component {
    constructor(props, context) {
        super(props);

    this.store = props.store;
    this.pos = props.store.pos;

        this.state = {};
        this.state.ynq = [];
        this.state.sval = [];

        this.state.dao = {};
        this.state.aa = [];
        this.state.avd = "01";
        if(props.wnds.avd) this.state.avd = props.wnds.avd;

        this.state.pbo = {};
        this.state.pbo.avd = this.state.avd;
        this.state.pbo.plunr = "";
        this.state.pbo.pris = "0";
        this.state.pbo.vgrp = "001";
        this.state.pbo.text = "";
        this.state.pbo.langtext = "";
        this.state.pbo.knapptext = "";

    }
    componentDidUpdate() {
    }
    componentWillUnmount() {
    }
    componentDidMount() {
        this.plu_free();
    }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }

    wnd_close = () => {
		this.props.ctx.setState({ wnds: {} });
	};

    submitHandler = event => {
        event.preventDefault();
        event.target.className += " was-validated";
    };

    changeHandler = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

  plu_free() {
    var self = this;
    var prm = {};
    prm.req = "pos.pos_plu.plu_freex";
    prm.avd = this.state.avd;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        var pbo = self.state.pbo;
        pbo.plunr = ret.plu;
        self.setState({pbo: pbo }, function() {
            //this.cdi_init();
        });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  plu_save() {
    var self = this;

    var text = self.state.pbo.text.txt2cdi();
    var knapptext = self.state.pbo.knapptext.txt2cdi();
    var langtext = self.state.pbo.langtext.txt2cdi();
    var pris = self.state.pbo.pris.pris2cdi();

    var vgrp = self.state.pbo.vgrp;
    var mkod = "01";
    for(var row of self.pos.vgrps) {
        if(row.v0katnr == vgrp) {
            var kod = row.v0moms.substr(0, 2).trim();
            if(kod.length > 0) mkod = kod;
        }
    }

    var pbo = {};
    pbo.plu00 = {};
    pbo.plu01 = {};
    pbo.plu02 = {};
    pbo.plu03 = {};
    pbo.plu04 = {};
    pbo.plu05 = {};

    pbo.plu00.p0p = "P";
    pbo.plu00.p0kstns = self.state.pbo.avd;
    pbo.plu00.p0katnr = self.state.pbo.plunr;
    pbo.plu00.p0namn = text;
    pbo.plu00.p0pris = pris;
    pbo.plu00.p0nydat = "";
    pbo.plu00.p0nypris = "0";
    pbo.plu00.p0happy = "0";
    pbo.plu00.p0moms = mkod;
    pbo.plu00.p0debcre = "1";
    pbo.plu01.p1nyhappy = "0";
    pbo.plu02.p2vgrupp = vgrp;
    pbo.plu02.p2pluvis = "1";
    pbo.plu02.p2pluknapp = knapptext;
    pbo.plu02.p2lagst = "01";
    pbo.plu02.p2ulager = "";
    pbo.plu03.p3kost = "0";
    pbo.plu04.p4longname = langtext;

    if(pbo.plu00.p0pris == "") pbo.plu00.p0pris = "0";
    if(isNaN(pbo.plu00.p0pris)) pbo.plu00.p0pris = "0";

    //var plubuf = self.cdi_plubuf();

    var prm = {};
      prm.req = "pos.pos_plu.pb_upd";
      //prm.req = "pos.pos_plu.plu_bufsave";
    prm.plubuf = pbo;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

		//self.props.ctx.setState({ wnds: {} });
		self.props.ctx.plu_edit(pbo);
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }
  plu_save_close() {
    var self = this;

    var text = self.state.pbo.text.txt2cdi();
    var knapptext = self.state.pbo.knapptext.txt2cdi();
    var langtext = self.state.pbo.langtext.txt2cdi();
    var pris = self.state.pbo.pris.pris2cdi();

    var vgrp = self.state.pbo.vgrp;
    var mkod = "01";
    for(var row of self.pos.vgrps) {
        if(row.v0katnr == vgrp) {
            var kod = row.v0moms.substr(0, 2).trim();
            if(kod.length > 0) mkod = kod;
        }
    }

    var pbo = {};
    pbo.plu00 = {};
    pbo.plu01 = {};
    pbo.plu02 = {};
    pbo.plu03 = {};
    pbo.plu04 = {};
    pbo.plu05 = {};

    pbo.plu00.p0p = "P";
    pbo.plu00.p0kstns = self.state.pbo.avd;
    pbo.plu00.p0katnr = self.state.pbo.plunr;
    pbo.plu00.p0namn = text;
    pbo.plu00.p0pris = pris;
    pbo.plu00.p0nydat = "";
    pbo.plu00.p0nypris = "0";
    pbo.plu00.p0happy = "0";
    pbo.plu00.p0moms = mkod;
    pbo.plu00.p0debcre = "1";
    pbo.plu01.p1nyhappy = "0";
    pbo.plu02.p2vgrupp = vgrp;
    pbo.plu02.p2pluvis = "1";
    pbo.plu02.p2pluknapp = knapptext;
    pbo.plu02.p2lagst = "01";
    pbo.plu02.p2ulager = "";
    pbo.plu03.p3kost = "0";
    pbo.plu04.p4longname = langtext;

    if(pbo.plu00.p0pris == "") pbo.plu00.p0pris = "0";
    if(isNaN(pbo.plu00.p0pris)) pbo.plu00.p0pris = "0";
    //var plubuf = self.cdi_plubuf();

    var prm = {};
    prm.req = "pos.pos_plu.plu_bufsave";
    prm.plubuf = pbo;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

		self.props.ctx.setState({ wnds: {} });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }
    val_save (name, value) {
      var pbo = this.state.pbo;
      pbo[name] = value;
      this.setState({pbo: pbo});
    }
    val_pris (value) {
      this.setState({pris: value});
    }
    vgrp_change (e) {
      var pbo = this.state.pbo;
      const value = e.target.value;
      pbo.vgrp = value;
      this.setState({pbo: pbo});
    }

    render() {

    var title = this.props.title;
    if(!title) title = "Knappar";

	var html =
        <div className="mx_base">
        <div className="m2_backdrop" />
        <div className="m2_dlg mx_70 flex-col" style={ this.dlg_style() } draggable="true" >
        <div className="mx_content flex">

            <div className="flex-row" style={{padding:"5px",height:"35px"}}>
                    <div className="flex-col flex">
                        <div>LÄGG TILL PRODUKT</div>
                    </div>
                    <div className="flex-col">
                        <div>UPPDATERA</div>
                    </div>
            </div>
            <div className="divider_orange"></div>

            <form className="cdi_f03">
                <div className="form-row flex-column">
                    <Fr_Input label="Plunr" className="text-right w150" text={this.state.pbo.plunr} getValue={(e)=> this.val_save("plunr", e) } placeholder="" />
                    <Fr_Input label="Pris" className="text-right w250" text={this.state.pbo.pris} getValue={(e)=> this.val_save("pris", e) } placeholder="0.00" />
                </div>
                <div className="divider_green"></div>
                 <div className="form-row flex-column">
                    <Fr_Input label="Text" className="text-left w250" text={this.state.pbo.text} getValue={(e)=> this.val_save("text", e) } placeholder="" />
                    <Fr_Input label="Lång Text" className="text-left w350" text={this.state.pbo.langtext} getValue={(e)=> this.val_save("langtext", e) } placeholder="" />
                    <Fr_Input label="Knapptext" className="text-left w250" text={this.state.pbo.knapptext} getValue={(e)=> this.val_save("knapptext", e) } placeholder="" />
                </div>
               <div className="form-row flex-row">
                    <div className="form-group flex-row flex">
                        <span className="header">Varugrupp</span>
                        <select name="plu02.p2vgrupp" value={this.state.pbo.vgrp} className="form-control w350" onChange={(e) => this.vgrp_change(e)}>
                            { this.pos.vgrps.map((row, key) =>
                            <option key={key} value={row.v0katnr}>{ row.v0katnr } - { row.v0text.toswe() }</option>
                            )}
                        </select>
                    </div>
                </div>
            </form>
            <div className="flex"></div>
            <div className="flex-column hide">
                <div className="form-row">PLU: { this.state.pbo.plunr }</div>
                <div className="form-row">PRIS: { this.state.pbo.pris }</div>
                <div className="form-row">VGRP: { this.state.pbo.vgrp }</div>
            </div>

        </div>
        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <button className="btn btn-hw-green w250" onClick={() => this.plu_save_close() } >
                SKAPA OCH STÄNG
            </button>
            <button className="btn btn-hw-green w250" onClick={() => this.plu_save() } >
                SKAPA OCH ÖPPNA
            </button>
        </div>
      </div>
      </div>
	;
    return html;
    };
}

export default Prd_New;