import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";
import Lang from "../../lib/lang";
import Td_Sel from "../../lib/ui/td_sel";

import Pms_PcoSel from "./pco/pco_sel";
import Pms_Co from "./pco/co";
import Wnd_Sign from "../../lib/ui/wnd_sign";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Pms_Pco extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, tra: [] };
    this.state.seldate = new Date();
    this.state.avd = "01";
    this.state.rva = [];
    this.state.aa = [];
    this.state.pcosel = 0;
    this.state.boknr = "";
    this.state.sortid = "";
    this.state.sortdir = 1;
      this.state.loading = false;

    this.state.rvo = {};
    this.state.selrow = {};
      this.state.msg = {};
    this.state.wnds = {};

    this.selitems = [
        {fkn: 'open', text: 'ÖPPNA'},
        {fkn: 'co', text: 'CHECKA UT'}
    ];
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.pco();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 200;
      css.overflow = "auto";
      return css;
  }
  th_style(id) {
      var css = {};
      css.cursor = "pointer";
      if(id == this.state.sortid) {
          if(this.state.sortdir) css.color = "#800";
          else css.color = "#080";
      }
      return css;
  }
  row_style(row) {
    var srow = this.state.selrow;

    var css = {};
    if(srow == row) {
        css.background = "#080";
        css.color = "#fff";
    }
    return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

    sel_gst(row) {
        var self = this;
        var msg = { info: false, sign: true, text: "Signatur?", text0: "JA", text1: "NEJ", cb: "sel_gst_do" };
        self.setState({ msg: msg, srow: row });
    }
    sel_gst_do(signatur) {
        var self = this;
        var row = this.state.srow;

        //alert(JSON.stringify(val));
        self.setState({msg: {} });
    var wnds = {};
    wnds.pcosel = true;
    wnds.boknr = row.boknr;
    wnds.rvo = row;
    this.setState({wnds: wnds});
  }

  val_rum(val) {
      var ret = "";
      if(val) ret = val.toString().cdirumsnr();
      return ret;
  }
  val_pris(val) {
      var ret = "";
      if(val) ret = val.toString().cdipris();
      return ret;
  }
  gst_co() {
    var self = this;

    var srow = this.state.selrow;
    if(!srow.boknr) return;

    var wnds = {};
    wnds.co = true;
    wnds.boknr = srow.boknr;
    wnds.rvo = srow;
    this.setState({wnds: wnds});
  }

  pco() {
    var self = this;
    this.setState({ rva: [], loading: true });

    var prm = {};
    prm.req = "pms.op.my_pco";

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        var rva = [];
        var row = {};
        for(row of ret.rco) {
            //alert(JSON.stringify(row.resv00));

            //row.charges.saldo = "0";
            //row.charges.payments = "0";
            //row.charges.topay = "0";
            if(row.gsb00) rva.push(row);
        }
        self.setState({ rva: rva, loading: false }, function() {
            self.syncTb("pcoTable");
        });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

    syncTb(tb){
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
        tdElements = tbel.getElementsByTagName('td');

        for (let i = 0; i < thElements.length; i++) {
            if(!tdElements[i]) continue;
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
            width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
  sel_row(row) {
    var srow = this.state.selrow;
    if(srow == row) {
      this.setState({selrow: {}});
    }
    else this.setState({selrow: row});
  }
  sel_row_fkn(row, sel) {
      if(sel.fkn == "open") {
          this.sel_gst(row);
      }
  }
  val_date(val) {
      var ret = "";
      if(val) ret = val.toString().cdidate();
      return ret;
  }
   tb_sort(typ) {
      var self = this;
      //var rvs = self.state.rvs;
      var rva = self.state.rva;
      var sortid = this.state.sortid;
      var sortdir = this.state.sortdir;

      if(sortid != typ) sortdir = 0;
      else {
        if(sortdir) sortdir = 0;
        else sortdir = 1;
      }

      var val1 = 1, val2 = -1, val3 = 0;
      if(sortdir == 0) { val1 = -1; val2 = 1; val3 = 0; }

      if(typ == "ank") {
        if(sortdir) rva.sort((a,b) => (a.gsb00.r0ankdatum < b.gsb00.r0ankdatum) ? 1 : ((b.gsb00.r0ankdatum < a.gsb00.r0ankdatum) ? -1 : 0));
        else rva.sort((a,b) => (a.gsb00.r0ankdatum > b.gsb00.r0ankdatum) ? 1 : ((b.gsb00.r0ankdatum > a.gsb00.r0ankdatum) ? -1 : 0));
      }
      if(typ == "avr") {
        if(sortdir) rva.sort((a,b) => (a.gsb00.r0avrdatum < b.gsb00.r0avrdatum) ? 1 : ((b.gsb00.r0avrdatum < a.gsb00.r0avrdatum) ? -1 : 0));
        else rva.sort((a,b) => (a.gsb00.r0avrdatum > b.gsb00.r0avrdatum) ? 1 : ((b.gsb00.r0avrdatum > a.gsb00.r0avrdatum) ? -1 : 0));
      }
      if(typ == "boknr") {
        if(sortdir) rva.sort((a,b) => (a.gsb00.r0boknr < b.gsb00.r0boknr) ? 1 : ((b.gsb00.r0boknr < a.gsb00.r0boknr) ? -1 : 0));
        else rva.sort((a,b) => (a.gsb00.r0boknr > b.gsb00.r0boknr) ? 1 : ((b.gsb00.r0boknr > a.gsb00.r0boknr) ? -1 : 0));
      }
      if(typ == "ftg") {
        if(sortdir) rva.sort((a,b) => (a.resv00.Company < b.resv00.Company) ? 1 : ((b.resv00.Company < a.resv00.Company) ? -1 : 0));
        else rva.sort((a,b) => (a.resv00.Company > b.resv00.Company) ? 1 : ((b.resv00.Company > a.resv00.Company) ? -1 : 0));
      }
      if(typ == "namn") {
        if(sortdir) rva.sort((a,b) => (a.gsb00.r0namn < b.gsb00.r0namn) ? 1 : ((b.gsb00.r0namn < a.gsb00.r0namn) ? -1 : 0));
        else rva.sort((a,b) => (a.gsb00.r0namn > b.gsb00.r0namn) ? 1 : ((b.gsb00.r0namn > a.gsb00.r0namn) ? -1 : 0));
      }
      if(typ == "rumsnr") {
        if(sortdir) rva.sort((a,b) => (a.gsb00.r0rumsnr < b.gsb00.r0rumsnr) ? 1 : ((b.gsb00.r0rumsnr < a.gsb00.r0rumsnr) ? -1 : 0));
        else rva.sort((a,b) => (a.gsb00.r0rumsnr > b.gsb00.r0rumsnr) ? 1 : ((b.gsb00.r0rumsnr > a.gsb00.r0rumsnr) ? -1 : 0));
      }
      if(typ == "rt") {
        if(sortdir) rva.sort((a,b) => (a.rblx0.r0typ < b.rblx0.r0typ) ? 1 : ((b.rblx0.r0typ < a.rblx0.r0typ) ? -1 : 0));
        else rva.sort((a,b) => (a.rblx0.r0typ > b.rblx0.r0typ) ? 1 : ((b.rblx0.r0typ > a.rblx0.r0typ) ? -1 : 0));
      }
      if(typ == "saldo") {
        if(sortdir) rva.sort((a,b) => (a.charges.saldo < b.charges.saldo) ? 1 : ((b.charges.saldo < a.charges.saldo) ? -1 : 0));
        else rva.sort((a,b) => (a.charges.saldo > b.charges.saldo) ? 1 : ((b.charges.saldo > a.charges.saldo) ? -1 : 0));
      }
      if(typ == "paid") {
        if(sortdir) rva.sort((a,b) => (a.charges.payments < b.charges.payments) ? 1 : ((b.charges.payments < a.charges.payments) ? -1 : 0));
        else rva.sort((a,b) => (a.charges.payments > b.charges.payments) ? 1 : ((b.charges.payments > a.charges.payments) ? -1 : 0));
      }
      if(typ == "topay") {
        if(sortdir) rva.sort((a,b) => (a.charges.topay < b.charges.topay) ? 1 : ((b.charges.topay < a.charges.topay) ? -1 : 0));
        else rva.sort((a,b) => (a.charges.topay > b.charges.topay) ? 1 : ((b.charges.topay > a.charges.topay) ? -1 : 0));
      }
      if(typ == "bettyp") {
        if(sortdir) rva.sort((a,b) => (a.bettyp < b.bettyp) ? 1 : ((b.bettyp < a.bettyp) ? -1 : 0));
        else rva.sort((a,b) => (a.bettyp > b.bettyp) ? 1 : ((b.bettyp > a.bettyp) ? -1 : 0));
      }
      if(typ == "segment") {      
        if(sortdir) rva.sort((a,b) => (a.resm01.Kanal < b.resm01.Kanal) ? 1 : ((b.resm01.Kanal < a.resm01.Kanal) ? -1 : 0));
        else rva.sort((a,b) => (a.resm01.Kanal > b.resm01.Kanal) ? 1 : ((b.resm01.Kanal > a.resm01.Kanal) ? -1 : 0));
     }
      self.setState({ rva: rva, sortid: typ, sortdir: sortdir });
   }
   th_icon(typ) {
      var cls = "";
      var sortid = this.state.sortid;
      var sortdir = this.state.sortdir;
      if(sortid == typ)
      {
        if(sortdir) cls = "fa fa-caret-up";
        else cls = "fa fa-caret-down";
      }
      return cls;
    }

  render() {
    return (
    <div className="web-bas">

    <div className="flex-row">
        <button className="btn btn-hw-blue w250 hide" onClick={() => this.fkns() } aria-label="System" >
            FUNKTIONER
        </button>
        <div className="flex"></div>
        <button className="btn btn-hw-green w250" onClick={() => this.gst_co() } aria-label="System" >
            CHECKAUT
        </button>
    </div>
    <div className="divider_blue"></div>

    { this.state.loading ? <div className="flex flex-col flex-center"><FontAwesomeIcon icon="spinner" spin size="4x" /></div> : null }
    { !this.state.loading ?
    <div className="flex" style={{overflow: "auto"}}>

        <div id="pcoTable">
          <table className="table table-bordered table-hover" style={{marginBottom: "0px"}}>
            <thead>
            <tr>
                <th style={ this.th_style("ank") } onClick={() => this.tb_sort("ank") }><Lang>Arrival</Lang> <i className={ this.th_icon("ank") }></i></th>
                <th style={ this.th_style("avr") } onClick={() => this.tb_sort("avr") }><Lang>Departure</Lang> <i className={ this.th_icon("avr") }></i></th>
                <th style={ this.th_style("boknr") } onClick={() => this.tb_sort("boknr") }><Lang>Resno</Lang> <i className={ this.th_icon("boknr") }></i></th>
                <th style={ this.th_style("segment") } onClick={() => this.tb_sort("segment") }><Lang>Segment</Lang> <i className={ this.th_icon("segment") }></i></th>
                <th style={ this.th_style("ftg") } onClick={() => this.tb_sort("ftg") }><Lang>Company</Lang> <i className={ this.th_icon("ftg") }></i></th>
                <th style={ this.th_style("namn") } onClick={() => this.tb_sort("namn") }><Lang>Name</Lang> <i className={ this.th_icon("namn") }></i></th>
                <th style={ this.th_style("rumsnr") } onClick={() => this.tb_sort("rumsnr") }><Lang>Roomnr <i className={ this.th_icon("rumsnr") }></i></Lang></th>
                <th style={ this.th_style("saldo") } onClick={() => this.tb_sort("saldo") }><Lang>Saldo</Lang> <i className={ this.th_icon("saldo") }></i></th>
                <th style={ this.th_style("paid") } onClick={() => this.tb_sort("paid") }><Lang>Paid</Lang> <i className={ this.th_icon("paid") }></i></th>
                <th style={ this.th_style("topay") } onClick={() => this.tb_sort("topay") }><Lang>Topay</Lang> <i className={ this.th_icon("topay") }></i></th>
                <th></th>
            </tr>
            </thead>
            </table>
            <div style={ this.tb_style() }>
          <table className="table table-bordered table-hover tbs01">
            { this.state.rva.map((row, key) =>
           <tbody key={key} >
            <tr style={ this.row_style(row) } onClick={() => this.sel_row(row)} onDoubleClick={() => this.sel_gst(row) }>
                <td>{ this.val_date( row.gsb00.r0ankdatum ) }</td>
                <td>{ this.val_date( row.gsb00.r0avrdatum ) }</td>
                <td>{ row.gsb00.r0boknr }</td>
                <td>{ row.resm01.Kanal }</td>
                <td>{ row.resv00.Company }</td>
                <td className="bold">{ row.gsb00.r0namn }</td>
                <td className="bold text-center">{ this.val_rum(row.gsb00.r0rumsnr) }</td>
                <td>{ this.val_pris(row.charges.saldo) }</td>
                <td>{ this.val_pris(row.charges.payments) }</td>
                <td>{ this.val_pris(row.charges.topay) }</td>
                <td>
                    <Td_Sel items={ this.selitems } getValue={(e)=> this.sel_row_fkn(row, e)} />
                </td>
            </tr>
            { row.bettyp ?
                <tr className="txt_tb_anm" onDoubleClick={() => this.sel_gst(row)}>
                    <td colSpan={2}>Betalinformation</td>
                    <td colSpan={9}>{row.bettyp.toswe()}</td>
                </tr>
            : null }
            </tbody>
            )}
        </table>
        </div>
        </div>
    </div>
    : null }

    { this.state.wnds.pcosel ?
        <Pms_PcoSel wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
    : null }
        { this.state.wnds.co ?
    <Pms_Co close={this.gst_close} wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
        : null }
        { this.state.msg.sign ? <Wnd_Sign msg={this.state.msg} ctx={this} /> : null }
    </div>
  );
}
}
export default Pms_Pco;
