import React, { useState, useEffect, useContext } from 'react';
import {format} from "date-fns";

import * as net from "../../../lib/net";

import Dd_Sel from "../../../lib/ui/dd_sel";
import Dt_Cal from "../../../lib/ui/dt_cal";
import * as utils from "../../../assets/utils";
import Td_Sel from "../../../lib/ui/td_sel";
import Wnd_Error from "../../../lib/ui/wnd_error";
import Wnd_Ok from "../../../lib/ui/wnd_ok";
import Wnd_Confirm from "../../../lib/ui/wnd_confirm";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Fak_Und extends React.Component {
  constructor(props, context) {
    super(props);

    this.dt_fd = React.createRef();
    this.dt_td = React.createRef();
    this.selitems = [
      {fkn: 'sel', text: 'VÄLJ'},
      {fkn: 'show', text: 'VISA'}
    ];

    this.store = props.store;
    this.state = {};
    this.state.ynq = [];
    //this.state.vga = [];
    this.state.rta = [];
    this.state.bra = [];

    this.state.dta = [];
    this.state.uaa = [];

    this.state.tot = {};
    this.state.tot.fsg = 0;
    this.state.tot.krubba = 0;

    this.state.ix = 0;
    this.state.faknr = "";
    this.state.datum = "";
    this.state.fdat = "";
    this.state.tdat = "";

      this.state.msg = {};
    this.state.wnds = {};
    this.state.rvo = {};
    if(props.wnds) {
        if(props.wnds.faknr) this.state.faknr = props.wnds.faknr;
    }
      this.state.today = new Date();
      var dts = format(this.state.today, "yyyy-MM-dd");
      this.state.fdat = dts;
      this.state.tdat = dts;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      this.unds();
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }

  val_pris(val) {
      var s = "";
      if(val == 0) s = "0.00";
      if(val) s = val.cdipris();
      return s;
  };

    wnd_close = () => {
      if(this.props.close) {
          this.props.close(this.props.ctx);
      }
      else this.props.ctx.setState({ wnds: {} });
	};
  tb_style() {
      var css = {};
      css.height = (window.innerHeight * 0.8) - 275;
      css.overflow = "auto";
      return css;
  }
    th_style(id) {
        var css = {};
        css.cursor = "pointer";
        if(id == this.state.sortid) css.color = "#080";
        return css;
    }
    val_save (name, value) {
        //alert(JSON.stringify(name));
        //alert(JSON.stringify(value));
      var rvo = this.state.rvo;
      var na = name.split(".");
      rvo[na[0]][na[1]] = value;
      this.setState({rvo: rvo});
      //setState({[name]: value});
    }
  dt_fdat(e) {
    //alert(JSON.stringify(e));
    var dts = format(e, "yyyy-MM-dd");
    this.setState({ fdat: dts });
  }
  dt_tdat(e) {
      //alert(JSON.stringify(e));
    var dts = format(e, "yyyy-MM-dd");
    this.setState({ tdat: dts });
  }
  oneday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_td.current.updateDt(this.state.fdat);
  }
  addday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_fd.current.updateAdd();
    this.dt_td.current.updateAdd();
  }
  subday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_fd.current.updateSub();
    this.dt_td.current.updateSub();
  }
    unds() {
        var self = this;
        var prm = {};
        prm.req = "fakt.fakt_pos.underlag_open";
        prm.datum = this.state.fdat;
        prm.fdat = this.state.fdat;
        prm.tdat = this.state.tdat;
        prm.avd = this.state.avd;
        prm.status = "0";

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.rca));

                self.setState({uaa: ret.rca });
                self.syncTb("undTable");
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
                //gda.wndError(txt);
            });
    }
    syncTb(tb){
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
            tdElements = tbel.getElementsByTagName('td');
        if(tdElements.length < 1 ) return;

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
                width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
    tb_sort(typ) {
        var self = this;
        var poa = self.state.poa;
        var paa = self.state.paa;
        var sz = this.state.sz_val;

        if(typ == "plu") {
            //paa.sort((a,b) => (a.plu00.p0katnr > b.plu00.p0katnr) ? 1 : ((b.plu00.p0katnr > a.plu00.p0katnr) ? -1 : 0));
        }
        self.setState({paa: paa, sortid: typ });
    }
    sel_row_fkn(row, sel) {
        //alert(JSON.stringify(fkn));
        if(sel.fkn == "sel") {
            this.und_sel(row);
        }
        if(sel.fkn == "show") {
            this.und_show(row);
        }
    }
    und_sel(row) {
        var self = this;
        var prm = {};
        prm.req = "fakt.fakt_pos.pos_invto_fakt";
        prm.faknr = this.state.faknr;
        prm.wfkt00 = row.fu;

        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret.ok));
            //return;
            var msg = { info: false, ok: true, text: "Tillagd ..."};
            self.setState({msg: msg });
            setTimeout(function(){
                self.setState({msg: {} });
                if(self.props.wnds.cb) {
                    self.props.wnds.cb(self.props.ctx);
                }
                else self.props.ctx.setState({ wnds: {} });
                return;
            }, 1000);
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
            //gda.wndError(txt);
        });
    }
    und_show(row) {
    }

  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "Bokning";

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="mx_backdrop" /> : null }
        <div className="mx_dlg mx_70h flex-col" style={ this.dlg_style() }>
        <div className="mx_h01">
            <div>UNDERLAG : { this.state.faknr }</div>
        </div>

            <div className="divider_orange hide"></div>

            <div className="flex-row hide">
                <div className="flex"></div>
            <div className="flex-row">
                  <div className="dt-btn-01" onClick={() => this.subday() } ><FontAwesomeIcon icon="minus" size="1x" /></div>
                  <Dt_Cal dts={ this.state.fdat } rp="1" ref={this.dt_fd} getValue={(e)=> this.dt_fdat(e)} />
                  <div className="dt-btn-01" onClick={() => this.oneday() } >1</div>
                  <Dt_Cal dts={ this.state.tdat } rp="1" ref={this.dt_td} getValue={(e)=> this.dt_tdat(e)} />
                  <div className="dt-btn-01" onClick={() => this.addday() } ><FontAwesomeIcon icon="plus" size="1x" /></div>
            </div>
                <div className="flex"></div>
                <button className="btn btn-hw-blue w150" onClick={() => this.unds() } aria-label="System" >
                    Visa
                </button>
            </div>
             <div className="divider_green"></div>
       <div className="mx_content flex">

           <div id="undTable" className="flex">
               <table className="table table-bordered table-hover tb " style={{marginBottom: "0px"}}>
                   <thead>
                   <tr>
                       <th style={ this.th_style("datum") } onClick={() => this.tb_sort("datum") }>Datum</th>
                       <th style={ this.th_style("notanr") } onClick={() => this.tb_sort("notanr") }>Notanr</th>
                       <th style={ this.th_style("belopp") } onClick={() => this.tb_sort("belopp") }>Belopp</th>
                       <th style={ this.th_style("kundnr") } onClick={() => this.tb_sort("kundnr") }>Kundnr</th>
                       <th style={ this.th_style("status") } onClick={() => this.tb_sort("status") }>Status</th>
                       <th></th>
                   </tr>
                   </thead>
               </table>
               <div style={ this.tb_style() }>
                   <table className="table table-bordered table-hover tb" style={{marginBottom:"0px"}}>
                       <tbody>
                       { this.state.uaa.map((row, key) =>
                           <tr key={key} onDoubleClick={() => this.und_sel(row) }>
                               <td>{ utils.toDate( row.datum ) }</td>
                               <td>{ utils.toNum( row.notanr ) }</td>
                               <td>{ utils.toPris( row.belopp ) }</td>
                               <td>{ row.fu.kundnr }</td>
                               <td>{ row.status }</td>
                               <td>
                                   <Td_Sel items={ this.selitems } getValue={(e)=> this.sel_row_fkn(row, e)} />
                               </td>
                           </tr>
                       )}
                       </tbody>
                   </table>
               </div>
           </div>

            <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250 ripple" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex"></div>
        </div>
      </div>

        </div>

            <Wnd_Error msg={this.state.msg} ctx={this} />
            <Wnd_Ok msg={this.state.msg} ctx={this} />
            <Wnd_Confirm msg={this.state.msg} ctx={this} />
        </div>
	;
	return html;
  };
}

export default Fak_Und;