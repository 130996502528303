import React, { useState, useEffect, useContext } from 'react';
import {format} from "date-fns";

import * as net from "../../../lib/net";
import * as utils from "../../../assets/utils";
import Fr_Input from "../../../lib/ui/fr_input";
import Td_Sel from "../../../lib/ui/td_sel";
import Dt_Cal from "../../../lib/ui/dt_cal";

import Pms_RfBr from "./rf_br";
import Pms_RfGst from "./rf_gst";
import Pms_RfGstAdd from "./rf_gst_add";
import Pms_RfGstEdit from "./rf_gst_edit";
import Pms_Rr from "../../bel/rr/rr";
import Pms_Rrp from "../../bel/rr/rrp";

import Lang from "../../../lib/lang";
import Wnd_Error from "../../../lib/ui/wnd_error";
import Wnd_Ok from "../../../lib/ui/wnd_ok";
import Wnd_Info from "../../../lib/ui/wnd_info";
import Wnd_Confirm from "../../../lib/ui/wnd_confirm";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Pms_Rf extends React.Component {
  constructor(props, context) {
    super(props);

    this.selbritems = [
        {fkn: 'bropen', text: 'ÖPPNA'},
        {fkn: 'gstadd', text: 'SKAPA GST'},
        {fkn: 'gstanm', text: 'ANMÄRKNING'}
    ];
    this.selgstitems = [
        {fkn: 'gstopen', text: 'ÖPPNA'},
        {fkn: 'clear', text: 'RENSA RUM'},
        {fkn: 'gstanm', text: 'ANMÄRKNING'}
    ];
    this.dt_dt = React.createRef();

    this.state = {};
    this.state.ynq = [];
      this.state.wnds = {};
      this.state.msg = {};

    this.state.ix = 0;
    this.state.sz_val = "";
    this.state.szo = {};

    this.state.selgst = {};
    this.state.dropgst = {};
    this.state.selroom = {};
    this.state.droproom = {};
    this.state.selrt = "";

    this.state.rvs = [];
    this.state.rva = [];
    this.state.gsa = [];
    this.state.rrs = [];
    this.state.rra = [];
    this.state.rro = {};

    this.state.today = new Date();
    var dts = format(this.state.today, "yyyy-MM-dd");
    this.state.dts = dts;

    this.dragImage = null;
    this.prev_fg = "";
    this.prev_bg = "";
      this.state.showanm = 1;
      this.state.sortid = "";
      this.state.sortdir = 1;
      this.state.rsortid = "";
      this.state.rsortdir = 1;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      this.rf_dt();
  }
  selitems(typ) {
      var a = [];
      if(typ == "D") a = this.selbritems;
      if(typ == "G") a = this.selgstitems;
      return a;
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    wnd_close = () => {
		this.props.ctx.setState({ wnds: {} });
	};

  tb_style() {
      var css = {};
      css.height = window.innerHeight - 210;
      css.overflow = "auto";
      return css;
  }
    th_style(id) {
        var css = {};
        css.cursor = "pointer";
        if(id == this.state.sortid) {
            if(this.state.sortdir) css.color = "#800";
            else css.color = "#080";
        }
        return css;
    }
    thr_style(id) {
        var css = {};
        css.cursor = "pointer";
        if(id == this.state.rsortid) {
            if(this.state.rsortdir) css.color = "#800";
            else css.color = "#080";
        }
        return css;
    }
  gst_style(row) {
    var srow = this.state.selgst;
    var drow = this.state.dropgst;

    var css = {};
      if(row.gsb00 && (row.gsb00.r0status == "I")) {
          css.display = "none";
      }
      if(srow == row) {
          css.background = "#080";
          css.color = "#fff";
      }
    if(drow == row) {
        css.background = "#008";
        css.color = "#fff";
    }
    return css;
  }
  room_rt() {
    var rt = "ALLA RUM";
    var selrt = this.state.selrt;

    if(selrt) {
        rt = selrt.trim();
    }
    return rt;
 }
  room_style(row) {
    var srow = this.state.selroom;
    var drow = this.state.droproom;
    var grow = this.state.selgst;
    var selrt = this.state.selrt;

    var css = {};
    if(srow == row) {
        css.background = "#080";
        css.color = "#fff";
    }
    if(drow == row) {
        css.background = "#008";
        css.color = "#fff";
    }
    //if(grow.rt) {
    if(selrt) {
        if(selrt.trim() != row.rt.trim()) {
            css.display = "none";
        }
    }
    if(row.status == "I") {
        css.display = "none";
    }
    if(row.status == "C") {
        css.display = "none";
    }
    if(row.reserved == "1") {
        css.display = "none";
    }
    return css;
  }
    drag_room(event, row) {
        //event.preventDefault();
        var rumsnr = "";
        var text = "";
        if(row) {
            rumsnr = row.rumsnr;
            text = row.rt.toswe();
        }

        var dragImage = document.createElement('div');
        dragImage.id = 'drag-image';
        dragImage.classList.add('rf_drag');
        dragImage.innerHTML = "<div>"+ text + "</div><div>"+ rumsnr + "</div>";
        this.dragImage = dragImage;
        document.body.appendChild( dragImage );
        //this.node.current.appendChild( dragImage );
        event.dataTransfer.setDragImage(dragImage, 62, 32);
        //event.dataTransfer.setData("typ", "btn");
        var o = {};
        o.typ = "room";
        o.room = row;
        var txt = JSON.stringify(o);
        event.dataTransfer.setData("text", txt);
    }
    drag_end(event) {
        this.dragImage.remove();
        this.setState({dropgst: {}, droproom : ""});
    };
    drop_room(event, row) {
        event.preventDefault();
        //event.target.style.background = this.prev_bg;
        var txt = event.dataTransfer.getData("text");
        var gso = JSON.parse(txt);

        alert("Dra rum till gäst.");

        //alert(JSON.stringify(gso));
       if(row.closed == "1") {
            //alert("Stängt");
            return;
        }
    };
    drag_gst(event, row) {
        //event.preventDefault();
        var boknr = "";
        var text = "";
        if(row) {
            boknr = row.boknr;
            text = row.name.toswe();
        }

        var dragImage = document.createElement('div');
        dragImage.id = 'drag-image';
        dragImage.classList.add('rf_drag');
        dragImage.innerHTML = "<div>"+ text + "</div><div>"+ boknr + "</div>";
        this.dragImage = dragImage;
        document.body.appendChild( dragImage );
        //this.node.current.appendChild( dragImage );
        event.dataTransfer.setDragImage(dragImage, 62, 32);
        //event.dataTransfer.setData("typ", "btn");
        var o = {};
        o.typ = "gst";
        o.gst = row;
        var txt = JSON.stringify(o);
        event.dataTransfer.setData("text", txt);
    }
    drop_gst(event, row) {
        event.preventDefault();
        //event.target.style.background = this.prev_bg;
        var txt = event.dataTransfer.getData("text");
        var rro = JSON.parse(txt);

        //alert(JSON.stringify(rro));
       if(row.typ == "D") {
           //alert("Skapa gäst först");

            var wnds = {};
            wnds.rfgstadd = true;
            wnds.boknr = row.boknr;
            wnds.name = row.name;
            wnds.bro = row.rblx0;
            wnds.rro = rro;
            wnds.cb = this.cb_gst;
            this.setState({wnds: wnds});

            return;
        }
       if(row.typ == "G") {
            //alert("Gäst");
            var gso = row.gsb00;
            var rumsnr = rro.room.rumsnr;

            if(row.rblx0.r0typ.trim() != rro.room.rt.trim()) {
                this.gst_rt_rradd(gso, rro);
                //alert("Rumstyp skiljer");
                return;
           }

            if(gso.r0rumsnr.ztrim() == "") {
                this.gst_rr_add(gso, rumsnr);
            }
            else {
                alert("Rensa först");
            }
            return;
        }
    };
    cb_gst(ctx, rsp) {
        ctx.rf_dt();
        ctx.setState({ wnds: {} });
    };
    drag_gst_enter(event, row) {
        event.preventDefault();
        this.setState({dropgst: row});
	};
    drag_gst_leave(event) {
        event.preventDefault();
        //alert("Allow Drop");
        this.setState({dropgst: {}});
	};
    drag_room_enter(event, row) {
        event.preventDefault();
        this.setState({droproom: row});
	};
    drag_room_leave(event) {
        event.preventDefault();
        //alert("Allow Drop");
        this.setState({droproom: {}});
	};

  dt_dat(e) {
    //alert(JSON.stringify(e));
    var dts = format(e, "yyyy-MM-dd");
    this.setState({ dts: dts }, function() {
        this.rf_dt();
    });
  }
  addday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_dt.current.updateAdd();
  }
  subday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_dt.current.updateSub();
  }
  clear_rt() {
    var self = this;

    this.setState({selrt: ""});
  }
  mark_gst(row) {
    var self = this;
    var srow = this.state.selgst;

    if(srow == row) {
        this.setState({selgst: {}, selrt: ""});
    }
    else this.setState({selgst: row, selrt: row.rt});
  }
  sel_gst(row) {
    var self = this;
    //alert(JSON.stringify(row.anm));
    //this.setState({anksel: 1, boknr: row.boknr, rvo: row});
    //this.setState({rfgstedit: 1, boknr: row.boknr, rvo: row});
    //alert(JSON.stringify(row.rblx0));
    if(row.typ == "D") {
        var wnds = {};
        wnds.rfgstadd = true;
        wnds.boknr = row.boknr;
        wnds.name = row.name;
        wnds.bro = row.rblx0;
        wnds.rro = {};
        wnds.anm = row.anm;
        wnds.cb = this.cb_gstadd;
        this.setState({wnds: wnds});
    }
    if(row.typ == "G") {
        var wnds = {};
        wnds.rfgstedit = true;
        wnds.boknr = row.boknr;
        wnds.name = row.name;
        wnds.bro = row.rblx0;
        wnds.gso = row.gsb00;
        wnds.anm = row.anm;
        //wnds.cb = this.cb_gstadd;
        this.setState({wnds: wnds});
    }
  }
  mark_room(row) {
    var self = this;
    var srow = this.state.selroom;

    if(srow == row) {
        this.setState({selroom: {}});
    }
    else this.setState({selroom: row});
  }
  sel_room(row) {
    var self = this;
    //alert(JSON.stringify(row));
    this.setState({anksel: 1, boknr: row.boknr, rvo: row});
  }
  rf_connect() {
    var self = this;
    var srow = this.state.selroom;
    var grow = this.state.selgst;

    if(!srow.rt) {
        alert(JSON.stringify("Inget rum"));
        return;
    }
    if(!grow.rt) {
        alert(JSON.stringify("Ingen gäst"));
        return;
    }
    alert(JSON.stringify("Rum- Gäst kopplade"));
    return;
  }

  rf_upd(e) {
    var self = this;
    if(e) e.preventDefault();
    this.rf_dt();
  }

  rf_dt() {
    var self = this;

    var prm = {};
    prm.req = "pms.op.my_arrivals";
    prm.datum = this.state.dts;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rco));

        if(ret.ok == "000") {
            self.setState({ rvs: ret.rco, rva: ret.rco }, function () {
                self.syncTb("gstsTable");
            });
        }

        var prm = {};
        prm.req = "pms.rr.rrq";
        prm.datum = self.state.dts;
        return net.sio_req(prm);
    })
    .then(function(ret){
        //alert(JSON.stringify(ret));

        var rra = [];
        var keys = Object.keys(ret.rco);
        for(var key of keys) {
            var rro = ret.rco[key];
            if(rro.closed != "0") continue;
            //alert(JSON.stringify(key));
            rra.push( rro );
        };
        self.setState({ rrs: rra, rra: rra, rro: rro, loading: false }, function () {
            self.syncTb("rfrrTable");
        });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  ank() {
    var self = this;

    var prm = {};
    prm.req = "pms.op.my_arrivals";
    prm.datum = this.state.dts;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rco));

        self.setState({rva: ret.rco }, function () {
            self.syncTb("gstsTable");
        });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  rr() {
    var self = this;
    var prm = {};
    prm.req = "pms.rr.rrq";
    prm.datum = this.state.dts;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        var rra = [];
        var keys = Object.keys(ret.rco);
        for(var key of keys) {
            var rro = ret.rco[key];
            //alert(JSON.stringify(key));

            rra.push( rro );
        };
        self.setState({rra: rra, loading: false }, function () {
            self.syncTb("rrTable");
        });
    })
    .catch(function(e) {
        var txt = JSON.stringify(e);
    });
  }
    syncTb(tb){
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
        tdElements = tbel.getElementsByTagName('td');

        for (let i = 0; i < thElements.length; i++) {
            if(!tdElements[i]) continue;
            const widerElement =
            thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
            width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
    gst_rt_rradd(gso, rro) {
        var self = this;

        var msg = { confirm: true, text: "Skall rumstyp bytas?", text0: "JA", text1: "NEJ", cb: "gst_rt_rradd_do"};
        self.setState({ msg: msg, sel_gso: gso, sel_rro: rro });
    }
    gst_rt_rradd_do(val) {
        var self = this;

    self.setState({msg: {} });
    if(val != 0) {
        return;
    }
    //if(gso.rumsnr.length == 4) gso.rumsnr = gso.rumsnr + "00";

    var gso = this.state.sel_gso;
    var rro = this.state.sel_rro;

    var prm = {};
    prm.req = "pms.br.br_rt_chg";
    prm.boknr = gso.r0boknr;
    prm.lopnr = gso.r0blopnr;
    prm.rt = rro.room.rt;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            alert("RT Byte fel");
            return;
        }
        var prm = {};
        prm.req = "pms.br.brg_rr_set";
        prm.gsb00 = gso;
        prm.rumsnr = rro.room.rumsnr;

        return net.sio_req(prm);
    })
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok == "000") {
            self.rf_dt()
            return;
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

  gst_rr_add(gso, rumsnr) {
    var self = this;

    //if(gso.rumsnr.length == 4) gso.rumsnr = gso.rumsnr + "00";

    var prm = {};
    prm.req = "pms.br.brg_rr_set";
    prm.gsb00 = gso;
    prm.rumsnr = rumsnr;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok == "000") {
            self.rf_dt()
            return;
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  gst_rr_free(gso) {
    var self = this;

    //if(gso.rumsnr.length == 4) gso.rumsnr = gso.rumsnr + "00";

    var prm = {};
    prm.req = "pms.br.brg_rr_free";
    prm.gsb00 = gso;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok == "000") {
            self.rf_dt()
            return;
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
    val_date(val) {
        var ret = "";
        if(val) ret = val.toString().cdidate();
        return ret;
}
  val_rum(val) {
      var ret = "";
      if(val) ret = val.toString().cdirumsnr();
      return ret;
  }
  val_pris(val) {
      var ret = "";
      if(val) ret = val.toString().cdipris();
      return ret;
  }
  val_save(name, val) {
      var ret = "";
      if(val) ret = val.toString().cdirumsnr();
      return ret;
  }
  sel_row_fkn(row, sel) {
      //e.preventDefault();
      //alert(JSON.stringify(sel));
    if(sel.fkn == "bropen") {
    }
    if(sel.fkn == "gstadd") {
        var wnds = {};
        wnds.rfgstadd = true;
        wnds.boknr = row.boknr;
        wnds.name = row.name;
        wnds.bro = row.rblx0;
        wnds.rro = {};
        wnds.cb = this.cb_gstadd;
        this.setState({wnds: wnds});
    }
    if(sel.fkn == "gstopen") {
    }
    if(sel.fkn == "gstanm") {
        alert( row.anm.toswe() );
    }
    if(sel.fkn == "clear") {
        //alert(JSON.stringify(row));
        this.gst_rr_free(row.gsb00);
    }
  }
  cb_gstadd(ctx, ret) {
      if(ret.ok == "000") {
        ctx.rf_dt();
        ctx.setState({ wnds: {} });
      }
      else {
        ctx.setState({ wnds: {} });
      }
  }
    btnanm_style() {
        var css = {};
        css.height = "40px";
        css.lineHeight = "40px";
        css.padding = "0px 10px";
        css.fontSize = "12px";
        css.cursor = "pointer";
        css.background = "#080";
        css.color = "#fff";
        css.borderLeft = "1px solid #222";
        css.borderRight = "1px solid #222";
        if(this.state.showanm) {
            css.background = "#800";
            css.color = "#fff";
            css.borderLeft = "1px solid #222";
            css.borderRight = "1px solid #222";
        }
        return css;
    }
    btn_anm() {
        var showanm = this.state.showanm;
        if(showanm) showanm = 0;
        else showanm = 1;
        this.setState({showanm: showanm});
    }
    rr() {
        var self = this;
        var wnds = {};
        wnds.rr = 1;
        this.setState({ wnds: wnds });
    }
    th_icon(typ) {
        var cls = "";
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;
        if(sortid == typ)
        {
            if(sortdir) cls = "fa fa-caret-up";
            else cls = "fa fa-caret-down";
        }
        return cls;
    }
    thr_icon(typ) {
        var cls = "";
        var sortid = this.state.rsortid;
        var sortdir = this.state.rsortdir;
        if(sortid == typ)
        {
            if(sortdir) cls = "fa fa-caret-up";
            else cls = "fa fa-caret-down";
        }
        return cls;
    }
    tb_sort(typ) {
        var self = this;
        //var rvs = self.state.rvs;
        var rva = self.state.rva;
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;

        if(sortid != typ) sortdir = 0;
        else {
            if(sortdir) sortdir = 0;
            else sortdir = 1;
        }

        if(sortdir) rva.sort((a,b) => (a[typ] > b[typ]) ? 1 : ((b[typ] > a[typ]) ? -1 : 0));
        else rva.sort((a,b) => (a[typ] < b[typ]) ? 1 : ((b[typ] < a[typ]) ? -1 : 0));
        self.setState({ rva: rva, sortid: typ, sortdir: sortdir });
    }
    tbr_sort(typ) {
        var self = this;
        //var rvs = self.state.rvs;
        var rra = self.state.rra;
        var sortid = this.state.rsortid;
        var sortdir = this.state.rsortdir;

        if(sortid != typ) sortdir = 0;
        else {
            if(sortdir) sortdir = 0;
            else sortdir = 1;
        }

        if(sortdir) rra.sort((a,b) => (a[typ] > b[typ]) ? 1 : ((b[typ] > a[typ]) ? -1 : 0));
        else rra.sort((a,b) => (a[typ] < b[typ]) ? 1 : ((b[typ] < a[typ]) ? -1 : 0));
        self.setState({ rra: rra, rsortid: typ, rsortdir: sortdir });
    }
    tb_filtr() {
        var self = this;
        var rvs = self.state.rvs;
        var sz = this.state.sz_val;

        if(sz.length < 1) {
            self.setState({ rva: rvs });
            return;
        }
        sz = sz.toUpperCase();
        var rva = rvs.filter(function(row) {
            var brc = false;
            if(row.company.toUpperCase().includes(sz)) brc = true;
            if(row.name.toUpperCase().includes(sz)) brc = true;
            return brc;
        });
        self.setState({rva: rva });
    }
    sz_g(val) {
        var self = this;
        var rvs = self.state.rvs;
        var sz = val.toString();

        if(sz.length < 1) {
            self.setState({ rva: rvs });
            return;
        }
        sz = sz.toUpperCase();
        var rva = rvs.filter(function(row) {
            var brc = false;
            if(row.company.toUpperCase().includes(sz)) brc = true;
            if(row.name.toUpperCase().includes(sz)) brc = true;
            return brc;
        });
        self.setState({ rva: rva });
    }
    sz_r(val) {
        var self = this;
        var rrs = self.state.rrs;
        var sz = val.toString();

        if(sz.length < 1) {
            self.setState({ rra: rrs });
            return;
        }
        sz = sz.toUpperCase();
        var rra = rrs.filter(function(row) {
            var brc = false;
            if(row.rumsnr.toUpperCase().includes(sz)) brc = true;
            //if(row.name.toUpperCase().includes(sz)) brc = true;
            return brc;
        });
        self.setState({ rra: rra });
    }
    clean_style(row) {
        var css = {};
        css.background = "#ff8";
        css.color = "#222";
    
        if(row.clean == "1") {
            css.background = "#080";
            css.color = "#fff";
        }
        if(row.clean == "2") {
            css.background = "#f90";
            css.color = "#fff";
        }
        if(row.cleaned == "1") {
            css.background = "#080";
            css.color = "#fff";
        }
        if(row.cleaned == "2") {
            css.background = "#f90";
            css.color = "#fff";
        }
        return css;
    }
    
  render() {
    //if(!this.props.show) return "";

    //alert(this.props.ctx.state.lro);

    var title = this.props.title;
    if(!title) title = "Sök";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="mx_backdrop" /> : null }
        <div className="mx_dlg mx_100 flex-col" style={ this.dlg_style() }>
        <div className="mx_h01">
            <div>RUMSFÖRDELNING</div>
        </div>
        <div className="mx_content flex">

            <form className="cdi_f03">
            <div className="flex-row">
                <div className="flex-col" style={ this.btnanm_style() } onClick={() => this.btn_anm() }>
                    <div>ANM</div>
                </div>
                <div className="flex-row">
                      <div className="dt-btn-01" onClick={() => this.subday() } ><FontAwesomeIcon icon="minus" size="1x" /></div>
                      <Dt_Cal startdatum="2020-02-01" rp="1" ref={this.dt_dt} getValue={(e)=> this.dt_dat(e)} />
                      <div className="dt-btn-01" onClick={() => this.addday() } ><FontAwesomeIcon icon="plus" size="1x" /></div>
                </div>
                <Fr_Input noflex label="SÖK" noheader className="text-left w250" text={this.state.szo.szg} getValue={(e)=> this.sz_g(e) } />
                <div className="flex-col px-2" onClick={() => this.clear_rt() }>
                    <div className="txt_12 web-link">VALD TYP - RENSA</div>
                    <div className="txt_14">{ this.room_rt() }</div>
                </div>
                <div className="flex"></div>
                <div className="flex-row" style={{border: "0px", margin: "0px", padding: "0px"}}>
                    <Fr_Input label="RUMSNR" noheader className="text-left w150" text={this.state.szo.rumsnr} getValue={(e)=> this.sz_r(e) } />
                </div>
            </div>
            </form>
            <div className="divider_orange"></div>

            <div className="flex-row flex">

                <div id="gstsTable" className="flex">
                    <table className="table table-bordered table-hover" style={{marginBottom: "0px"}}>
                        <thead>
                        <tr>
                            <th style={ this.th_style("typ") } onClick={() => this.tb_sort("typ") }><Lang>Type</Lang> <i className={ this.th_icon("typ") }></i></th>
                            <th style={ this.th_style("company") } onClick={() => this.tb_sort("company") }><Lang>Company</Lang> <i className={ this.th_icon("company") }></i></th>
                            <th style={ this.th_style("name") } onClick={() => this.tb_sort("name") }><Lang>Name</Lang> <i className={ this.th_icon("name") }></i></th>
                            <th style={ this.th_style("nrg") } onClick={() => this.tb_sort("nrg") }><Lang>R/G</Lang> <i className={ this.th_icon("nrg") }></i></th>
                            <th style={ this.th_style("rt") } onClick={() => this.tb_sort("rt") }><Lang>Roomtype</Lang> <i className={ this.th_icon("rt") }></i></th>
                            <th style={ this.th_style("avr") } onClick={() => this.tb_sort("avr") }><Lang>Avresa</Lang> <i className={ this.th_icon("avr") }></i></th>
                            <th style={ this.th_style("boknr") } onClick={() => this.tb_sort("boknr") }><Lang>Boknr</Lang> <i className={ this.th_icon("boknr") }></i></th>
                            <th className="bold txt_center" style={ this.th_style("rumsnr") } onClick={() => this.tb_sort("rumsnr") }><Lang>Roomnr</Lang> <i className={ this.th_icon("rumsnr") }></i></th>
                            <th></th>
                        </tr>
                        </thead>
                    </table>
                    <div style={this.tb_style()}>
                        <table className="table table-bordered table-hover">
                            {this.state.rva.map((row, key) =>
                                <tbody>
                                <tr key={key}
                                    onClick={ () => this.mark_gst(row) }
                                    onDoubleClick={() => this.sel_gst(row)}
                                    draggable
                                    onDragStart={ (event) => this.drag_gst(event, row)}
                                    onDragEnd={ (event) => this.drag_end(event)}
                                    onDragOver={ (event) => this.drag_gst_enter(event, row) }
                                    onDragLeave={ (event) => this.drag_gst_leave(event) }
                                    onDrop={ (event) => this.drop_gst(event, row)}
                                    style={ this.gst_style(row) }
                                >
                                    <td>{row.typ}</td>
                                    <td>{utils.toLang(row.company)}</td>
                                    <td>{utils.toLang(row.name)}</td>
                                    <td>{utils.toNum(row.nrr)}/{utils.toNum(row.nrg)}</td>
                                    <td>{row.rt}</td>
                                    <td>{utils.toDate(row.avr)}</td>
                                    <td>{row.boknr}</td>
                                    <td className="bold txt_center" style={ this.clean_style(row) }>{this.val_rum(row.rumsnr)}</td>
                                    <td>
                                        <Td_Sel items={this.selitems(row.typ)} getValue={(e) => this.sel_row_fkn(row, e)}/>
                                    </td>
                                </tr>
                            { ( this.state.showanm && row.anm ) ?
                                <tr className="txt_tb_anm" style={ this.gst_style(row) } onDoubleClick={() => this.sel_gst(row)}>
                                <td colSpan={12}>{ utils.toLang(row.anm) }</td>
                                </tr>
                                : null }
                                </tbody>
                            )}
                        </table>
                    </div>
                </div>

                <div className="vert_orange"></div>

                <div style={{border: "0px", margin: "0px", padding: "0px", width: "350px"}}>
                <div id="rfrrTable" className="flex" style={{border: "0px", margin: "0px", padding: "0px", width: "350px"}}>
                <table className="table table-bordered table-hover" style={{marginBottom: "0px"}}>
                    <thead>
                    <tr>
                        <th style={ this.thr_style("rumsnr") } onClick={() => this.tbr_sort("rumsnr") }><Lang>Rumsnr</Lang> <i className={ this.thr_icon("rumsnr") }></i></th>
                        <th style={ this.thr_style("rt") } onClick={() => this.tbr_sort("rt") }><Lang>Text</Lang> <i className={ this.thr_icon("rt") }></i></th>
                        <th style={ this.thr_style("status") } onClick={() => this.tbr_sort("status") }><Lang>Status</Lang> <i className={ this.thr_icon("status") }></i></th>
                    </tr>
                    </thead>
                </table>
                <div style={this.tb_style()}>
                <table className="table table-bordered table-hover">
                <tbody>
                    {this.state.rra.map((row, key) =>
                    <tr key={key}
                        onClick={ () => this.mark_room(row) }
                        onDoubleClick={() => this.sel_room(row) }
                        draggable
                        onDragStart={ (event) => this.drag_room(event, row)}
                        onDragEnd={ (event) => this.drag_end(event)}
                        onDragOver={ (event) => this.drag_room_enter(event, row) }
                        onDragLeave={ (event) => this.drag_room_leave(event) }
                        onDrop={ (event) => this.drop_room(event, row)}
                        style={ this.room_style(row) }
                    >
                        <td style={ this.clean_style(row) }>{ utils.toNum(row.rumsnr) }</td>
                        <td>{ utils.toLang(row.rt) }</td>
                        <td>{ row.status }</td>
                    </tr>
                    )}
                </tbody>
                </table>
             </div>
             </div>

             </div>
             </div>

        </div>

        <div className="mx_foot flex-row">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex"></div>
            <div className="flex-row">
            <button className="btn btn-hw-orange w150 hide" onClick={(e) => this.rf_connect(e) } >
                KOPPLA
            </button>
                <button className="btn btn-hw-green w250 hide" onClick={(event) => this.sz_do(event) } >
                    SÖK
                </button>
                <button className="btn btn-hw-green w250" onClick={(event) => this.rr(event) } >
                    ROOMRACK
                </button>
            </div>
            <div className="flex"></div>
            <button className="btn btn-hw-green w250" onClick={(e) => this.rf_upd(e) } >
                UPPDATERA
            </button>
        </div>
    </div>
        { this.state.wnds.rfbr ?
            <Pms_RfBr wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
        : null }
        { this.state.wnds.rfgst ?
            <Pms_RfGst wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
        : null }
        { this.state.wnds.rfgstadd ?
            <Pms_RfGstAdd wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
        : null }
        { this.state.wnds.rfgstedit ?
            <Pms_RfGstEdit wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
        : null }
        { this.state.wnds.rr ?
            <Pms_Rr show={this.state.wnds.rr} ctx={this} store={this.props.store} backdrop={true}/>
        : null }
        { this.state.wnds.rrp ?
            <Pms_Rrp show={this.state.wnds.rrp} ctx={this} store={this.props.store} backdrop={true}/>
        : null }
            <Wnd_Error msg={this.state.msg} ctx={this} />
            <Wnd_Ok msg={this.state.msg} ctx={this} />
            <Wnd_Info msg={this.state.msg} ctx={this} />
            <Wnd_Confirm msg={this.state.msg} ctx={this} />
    </div>
	;
	return html;
  };
}

export default Pms_Rf;