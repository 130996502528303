import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class SHD_Bas extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, tra: [] };
    this.state.seldate = new Date();
    this.state.avd = "01";
    this.state.kaa = [];
    this.state.kro = {};
    this.state.aa = [
                {id: '01', text: 'AVD 01'},
                {id: '02', text: 'AVD 02'}
            ];
    this.state.cols = {};
    this.state.selrow = {};
    this.state.wnds = {};
    this.state.sortid = "";
    this.state.sortdir = 1;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.schds();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
    var css = {};
    css.height = window.innerHeight - 275;
    css.overflow = "auto";
    return css;
  }
  th_style(id) {
      var css = {};
      css.cursor = "pointer";
      if(id == this.state.sortid) {
          if(this.state.sortdir) css.color = "#800";
          else css.color = "#080";
      }
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

  shd_edit(row) {
    var self = this;
    alert(JSON.stringify(row));
  }

  shd_del(row) {
    var self = this;

    var prm = {};
    prm.req = "ta.ta_ee.shd_del";
    prm.schemanr = row.nr;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        //self.setState({kaa: ret.rca });
        //self.syncTb("schdTable");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

  schds() {
    var self = this;

    var prm = {};
    prm.req = "ta.ta_ee.shds";

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        self.setState({kaa: ret.rca }, function() {
            self.syncTb("schdTable");
        });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
    syncTb(tb) {
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
        tdElements = tbel.getElementsByTagName('td');
        if(tdElements.length < 1 ) return;

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
            width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }

  render() {
    return (
    <div className="web-app">

    <div className="flex-row flex-space-between">
        <div className="label_h03">BASSCHEMA</div>
        <select className="form-control sel_input" placeholder="Välj avdelning">
            { this.state.aa.map((row, key) =>
            <option key={key} value={row.id}>{row.text}</option>
            )}
        </select>
        <button className="btn btn-hw-blue w150 ripple" onClick={() => this.schds() } aria-label="System" >
            Visa
        </button>
    </div>
    <div className="divider"></div>

    <div className="web-col">
        <div id="schdTable" className="flex">
        <table className="table table-bordered table-hover tb " style={{marginBottom: "0px"}}>
            <thead>
            <tr>
                <th style={ this.th_style("c01" ) }>Schemanr</th>
                <th style={ this.th_style("c02" ) }>Text</th>
                <th style={ this.th_style("c03" ) }>Veckor</th>
                <th style={ this.th_style("c04" ) }>Tid</th>
                <th style={ this.th_style("c05" ) }>Status</th>
                <th style={ this.th_style("c06" ) }></th>
            </tr>
            </thead>
        </table>
        <div style={ this.tb_style() }>
       <table className="table table-bordered table-hover tb">
            <tbody>
            { this.state.kaa.map((row, key) =>
            <tr key={key} onDoubleClick={() => this.shd_edit(row) }>
                <td>{ row.nr }</td>
                <td>{ row.text }</td>
                <td>{ row.veckor }</td>
                <td>{ row.tid }</td>
                <td>{ row.status }</td>
                <td style={{width:"40px",cursor:"pointer"}} onClick={() => this.shd_del(row) }><i className="fa fa-cog text-danger"></i></td>
            </tr>
            )}
            </tbody>
        </table>
        </div>
        </div>
    </div>
   </div>
  );
    }
}
export default SHD_Bas;
