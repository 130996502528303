import React, { useState, useEffect, useContext } from 'react';
import {Route, Routes} from "react-router-dom";
import {withRouter} from "../lib/react/withRouter";
import {observer} from "mobx-react";

import Man_Home from './man/home.js';
import Man_Rps from './man/rps.js';
import Man_List from './man/list.js';
import Man_Trs from './man/trs.js';
import Man_Kvitton from './man/kvitton.js';
import Man_Kassa from './man/kassa.js';
import Stat_Home from "./stat/home";
import sso from "../sso";

function pg_home() {

    var html =
    <div className="web-content">
      Hem
    </div>
    ;
    return html;
}

class Pos_Man extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, deps: [] };
    //context.posrend = this;
      var po = {};
      po.rps = { "fid": "f04", "url": "/pos/man/rps", "name": "Rapporter", "active": false };
      po.kassa = { "fid": "f04", "url": "/pos/man/kassa", "name": "Kassa", "active": false };
      po.list = { "fid": "f04", "url": "/pos/man/list", "name": "Listor", "active": false };
      po.trs = { "fid": "f04", "url": "/pos/man/trs", "name": "Transaktioner", "active": false };
      po.kvitton = { "fid": "f04", "url": "/pos/man/kvitton", "name": "Kvitton", "active": false };
      this.state.po = po;
      this.state.pa = [];
      this.state.pa = Object.values(po);
  }
    componentDidUpdate() {
    }
    componentWillUnmount() {
    }
    componentDidMount() {
      /*
        var path = window.location.pathname;
        if(path.substr(-1) == "/") path = path.substring(0, path.length - 1);
        var op = path.split("/").pop();

        var po = this.state.po;
        if(po[op]) {
            po[op].active = true;
            //this.props.store.fkn = po[op];
            sbo.state.fkn = po[op].name;
            //alert(JSON.stringify(sbo.state));
        }
        var pa = Object.values(po);
        this.setState({ po: po, pa: pa });
        */
    }
    fknGo(row) {
        sso.seo.sfkn = row;
        this.props.navigate(row.url);
    }
    br_css(row) {
        var css = {};
        if(sso.seo.sfkn && (row.url == sso.seo.sfkn.url)) css.color = "#080";
        //var brc = false;
        //if(row.url == sbo.state.url) brc = true;
        return css;
    }

    render() {
        return (
            <div className="web-app">
                { this.props.store.cfg.bread ?
                    <div className="web-bread">
                        <div className="breadcrumb">
                            { this.state.pa.map((row, key) =>
                                <div key={key} className="breadcrumb-item" style={this.br_css(row)} onClick={() => this.fknGo(row)}>{row.name}</div>
                            )}
                            <div className="flex"></div>
                            <div className="cdigreen">{ sso.sdo.sfkn ? sso.sdo.sfkn.name : null }</div>
                        </div>
                    </div>
                    : null }
        <Routes>
          <Route path="/xx" component={pg_home} />
            <Route path="rps" element={ <Man_Rps store={this.store} />} />
            <Route path="list" element={ <Man_List store={this.store} />} />
            <Route path="trs" element={ <Man_Trs store={this.store} />} />
            <Route path="kvitton" element={ <Man_Kvitton store={this.store} />} />
            <Route path="kassa" element={ <Man_Kassa store={this.store} />} />
            <Route path="*" element={ <Man_Home store={this.store} />} />
        </Routes>
    </div>
  );
}
}
//Sys.contextType = ADOContext;

export default withRouter(observer(Pos_Man));

/*
    <div className="flex-row">
        <div>
            <ol className="cdibread">
            { pa.map((row, key) =>
                <li key={key} className="" onClick={() => this.fknGo(row)}>{ row.active ? row.text : <span className="nav_active">{row.text}</span> }</li>
            )}
            </ol>
        </div>
    </div>

*/