import React, { useState, useEffect, useContext } from 'react';

function PDF_Show(props) {
    const [state, setState] = useState({ user : {} });

    function dlg_style() {
      var css = {};
      css.width = window.innerWidth * 0.8;
      css.height = window.innerHeight * 0.8;
      return css;
    }
    function handleLogin(event) {
        //this.setState({value: event.target.value});
        //const body = new FormData(this.form);
        //alert('FORM: ' + JSON.stringify(state));
        props.ctx.fkn_close();
    }
    function handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = state.user;
      user[name] = value;
      setState({user: user});
      //setState({[name]: value});
    }
	const handleClose = () => {
		props.ctx.setState({ pdf_show: false });
	};
    if(!props.show) return "";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        { props.backdrop ? <div className="mx_backdrop" /> : null }
        <div className="mx_dlg flex-col" style={ dlg_style() }>
        <div className="mx_title">
            { props.title }
        </div>
        <div className="mx_content flex">
            <div className="flex-col">
                <object data={ props.pdf_uri } type="application/pdf" style={{width: "100%", height: "100%"}}></object>
            </div>
        </div>
        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w150 ripple" onClick={() => handleClose() } >
                STÄNG
            </button>
            <button className="btn btn-hw-green w150 ripple" onClick={(event) => handleLogin(event) } >
                SKRIV UT
            </button>
        </div>
      </div>
    </div>
	;
	return html;
}

export default PDF_Show;