import React, { useState, useEffect, useContext } from 'react';
import {format, addDays, subDays} from "date-fns";

import * as net from "../../../../lib/net";
import Fr_Input from "../../../../lib/ui/fr_input";
import Fr_Select from "../../../../lib/ui/fr_select";
import Dt_Cal from "../../../../lib/ui/dt_cal";

class Dtg_Add extends React.Component {
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pms = props.store.pms;

    this.state = {};
    this.state.today = new Date();
    this.state.rta = [];
    this.state.dtga = [];

    this.state.gnr = "001";
    this.state.gbo = {};
    //this.state.lro = props.lro;
    this.state.ynq = [
                {id: '0', text: 'NEJ'},
                {id: '1', text: 'JA'}
            ];

    this.state.background = "#fff";

    this.state.cols = {};
    this.state.boknr = {};
    if(props.boknr) this.state.boknr = props.boknr;

    this.state.wnds = {};
    this.state.dtg = {};
    if(props.wnds) {
        this.state.wnds = props.wnds;
        if(props.wnds.boknr) this.state.boknr = props.wnds.boknr;
        if(props.wnds.dtg) this.state.dtg = props.wnds.dtg;
    }
    this.state.bra = [];
    if(props.ctx) {
        this.state.bra = props.ctx.bra;
    }
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.dtg_init();
    this.dtgs();
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    grp_style(row) {
      var css = {};
      css.background = "#008";
      if(this.state.gnr === row.vgrupp) css.background = "#080";
      return css;
    }

    formchange (e) {
      const name = e.target.name;
      const value = e.target.value;
      var pbo = this.state.pbo;
      var na = name.split(".");
      pbo[na[0]][na[1]] = value;
      this.setState({pbo: pbo});
      //setState({[name]: value});
    }

    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    wnd_close = () => {
      if(this.props.cb) {
        var ret = {};
        ret.ok = "999";
        this.props.cb(this.props.ctx, ret);
      }
      else this.props.ctx.setState({ wnds: {} });
	};
    handleChange = (event, newValue) => {
        //alert(newValue);
        this.setState({ix: newValue});
      };
    toggle() {
      this.setState({modal: false});
	};

  submitHandler = event => {
    event.preventDefault();
    event.target.className += " was-validated";
  };

  changeHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  };


    val_save (name, value) {
        //alert(JSON.stringify(name));
        //alert(JSON.stringify(value));
      var dtg = this.state.dtg;
      dtg[name] = value;
      this.setState({dtg: dtg});
      //setState({[name]: value});
    }
    cb_save (name, e) {
      const value = e.target.value;
      var bro = this.state.bro;
      bro[name] = value;
      this.setState({bro: bro});
      //setState({[name]: value});
    }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }

  dtg_init() {
    var self = this;
    var dat = addDays(this.state.today, 1);
    var fdat = format(this.state.today, "yyyy-MM-dd");
    var tdat = format(dat, "yyyy-MM-dd");

    var dtg = {};
    dtg.fdat = fdat;
    dtg.tdat = tdat;
    dtg.rumsnr = "";
    dtg.namn = "";
    dtg.nrr = "1";
    dtg.nrg = "1";
    dtg.nrgr = "1";
    dtg.nrb0 = "0";
    dtg.nrb1 = "0";
    dtg.nrb2 = "0";
    dtg.rt = "";
    dtg.pkat = "";
    dtg.rab = "";
    this.setState({dtg: dtg});
  }
  dtgs() {
    var self = this;
    var prm = {};
    prm.req = "pms.kf.dtgs";
    prm.boknr = this.state.boknr;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            return;
        }
        self.setState({dtga: ret.rca});
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

  dtg_add() {
    var self = this;
    var prm = {};
    prm.req = "pms.kf.dtg_add";
    prm.boknr = this.state.boknr;
    prm.dtg = this.state.dtg;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok == "000") {
		    //self.props.cb(self.props.ctx, ret);
            self.dtgs();
            return;
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "Gäst";
    //if(props.kro) alert(props.kro.krr00.kid);
    const popover = {
      position: 'absolute',
      top: '50px',
      zIndex: '2',
    }
    const cover = {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    }

	var html =
        <div className="mx_base">
        <div className="m2_backdrop" />
        <div className="m2_dlg mx_100 flex-col" style={ this.dlg_style() } draggable="true" >

        <div className="mx_h01">
            <div>NY DELTAGARE</div>
        </div>
        <div className="mx_content flex">

            <div className="divider_orange"></div>
            <div className="flex">
            <div className="flex-row px-5 py-2">
                <div>DELTAGAR LISTA</div>
            </div>
            <div className="flex flex-row" style={{border: "0px", margin: "0px", padding: "0px"}}>
                <table className="table" style={{border: "0px", margin: "0px", padding: "0px"}}>
                    <thead style={{fontSize: "12px", fontWeight: "normal", color: "#080", border: "0px", margin: "0px", padding: "0px"}}>
                    <tr>
                        <th style={{textAlign: "left"}}>ID</th>
                        <th style={{textAlign: "left"}}>Namn</th>
                        <th style={{textAlign: "left"}}>Antal</th>
                        <th style={{textAlign: "left"}}>Barn 1</th>
                        <th style={{textAlign: "left"}}>Barn 2</th>
                        <th style={{textAlign: "left"}}>Status</th>
                        <th style={{textAlign: "center", color: "#800"}}>#</th>
                    </tr>
                    </thead>
                    <tbody style={{fontSize: "16px", color: "#000", border: "0px", margin: "0px", padding: "0px", borderBottom: "1px solid #ccc"}}>
                    { this.state.dtga.map((row, key) =>
                    <tr onDoubleClick={() => this.br_gst_edit(row) }>
                        <td>{ row.r0dlopnr }</td>
                        <td>{ row.r0namn }</td>
                        <td>{ row.r0guests }</td>
                        <td>{ row.r0antb0 }</td>
                        <td>{ row.r0antb1 }</td>
                        <td>{ row.r0status }</td>
                        <td></td>
                    </tr>
                    )}
                    </tbody>
                </table>
             </div>
             </div>
            <div className="divider_orange"></div>

            <form className="cdi_f03">
            <div className="flex-row py-2">
                    <Fr_Input label="Namn" className="text-left w350" text={this.state.dtg.namn} getValue={(e)=> this.val_save("namn", e) } />
            </div>
            <div className="flex-row py-2">
                    <Fr_Input label="Antal" className="text-left w150" text={this.state.dtg.nrg} getValue={(e)=> this.val_save("nrg", e) } />
                    <Fr_Input label="Barn" className="text-left w250" text={this.state.dtg.nrb0} getValue={(e)=> this.val_save("nrb0", e) } />
            </div>
            <div className="divider_green"></div>
            <div className="flex-row py-2">
                    <Fr_Input label="Företag" className="text-left w350" text={this.state.dtg.ftg} getValue={(e)=> this.val_save("ftg", e) } />
            </div>
            <div className="flex-row py-2">
                    <Fr_Input label="Epost" className="text-left w250" text={this.state.dtg.email} getValue={(e)=> this.val_save("email", e) } />
                    <Fr_Input label="Pass" className="text-left w250" text={this.state.dtg.pass} getValue={(e)=> this.val_save("pass", e) } />
            </div>
            <div className="flex-row py-2">
                    <Fr_Input label="Telefon" className="text-left w250" text={this.state.dtg.tele} getValue={(e)=> this.val_save("tele", e) } />
                    <Fr_Input label="Mobil" className="text-left w250" text={this.state.dtg.mobile} getValue={(e)=> this.val_save("mobile", e) } />
            </div>
            <div className="divider_green"></div>
            </form>
        </div>

        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                AVBRYT
            </button>
            <div className="flex-row">
            <button className="btn btn-hw-red w250" onClick={() => this.dtg_add() } >
                LÄGG TILL
            </button>
            </div>
        </div>
      </div>
      </div>
	;
	return html;
  };
}

export default Dtg_Add;