import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../../lib/net";
import * as utils from "../../../assets/utils";

import Fr_Input from "../../../lib/ui/fr_input";
import Wnd_Error from "../../../lib/ui/wnd_error";
import Wnd_Ok from "../../../lib/ui/wnd_ok";
import Wnd_Info from "../../../lib/ui/wnd_info";
import Wnd_Confirm from "../../../lib/ui/wnd_confirm";
import Fr_Cal from "../../../lib/ui/fr_cal";

class Scr_Edit extends React.Component {
  constructor(props, context) {
    super(props);

    ///this.pos = props.store.pos;

    this.state = {};
    this.state.mka = [];

    this.state.gnr = "001";
    this.state.ynq = [
                {id: '0', text: 'NEJ'},
                {id: '1', text: 'JA'}
            ];
      this.state.scrval = [
          {id: 'bas', text: 'BAS SKÄRM'},
          {id: 'mnu', text: 'MENY SKÄRM'}
      ];

    this.state.msg = {};
    this.state.cols = {};
    this.state.sco = {};
    if(props.wnds.sco) this.state.sco = props.wnds.sco;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.btn_grps();
      //if(!this.props.wnds.tdo) this.task_init();
      //this.mnu_mp();
  }

    task_init() {
        var tdo = {};
        tdo.typ = "";
        tdo.id = "";
        tdo.text = "";
        tdo.reg = "";
        tdo.status = "";
        //alert(JSON.stringify(rso));
        this.setState({ tdo: tdo });
        return;
    }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    tb_style() {
        var css = {};
        css.height = window.innerHeight - 275;
        return css;
    }

    wnd_close = () => {
        if(this.props.close) {
            this.props.close(this.props.ctx);
        }
        else this.props.ctx.setState({ wnds: {} });
    };
    scr_change (e) {
        const value = e.target.value;
        var sco = this.state.sco;
        sco.scrtyp = value;
        this.setState({sco: sco});
    }
    anm_change (e) {
        const value = e.target.value;
        var sco = this.state.sco;
        sco.anm = value;
        this.setState({sco: sco});
    }

    val_save(id, val) {
        var sco = this.state.sco;
        sco[id] = val;
        this.setState({ sco: sco });
        return;
    }
    mnu_mp() {
        var self = this;
        var prm = {};
        prm.req = "dsi.dsi_mp.mp_mnu";
        prm.mnuid = "mn02";

        net.sio_req(prm)
            .then(function(ret){
                alert(JSON.stringify(ret.rca));

                self.setState({ mna: ret.rca });
                //self.setState({ rva: ret.rca });
                self.syncTb("mnuTable");
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    syncTb(tb) {
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
            tdElements = tbel.getElementsByTagName('td');
        if(tdElements.length < 1 ) return;

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
                width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }

  scr_save() {
    var self = this;
    var prm = {};
    prm.req = "dsi.dsi_scr.scr_upd";
    prm.sco = this.state.sco;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));
        if(ret.ok == "000") {
            self.setState({ msg: {ok: true, text: "Sparad ..."} });
            setTimeout(function(){
                self.setState({msg: {} });
                  if(self.props.wnds.cb) {
                      self.props.wnds.cb(self.props.ctx);
                  }
                  else self.props.ctx.setState({ wnds: {} });
                return;
            }, 1000);
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

  scr_del() {
    var self = this;
    var prm = {};
    prm.req = "dsi.dsi_scr.scr_del";
    prm.sco = this.state.sco;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));
        if(ret.ok == "000") {
            self.setState({ msg: {ok: true, text: "Borttagen"} });
            setTimeout(function(){
                self.setState({msg: {} });
                  if(self.props.close) {
                      self.props.close(self.props.ctx);
                  }
                  else self.props.ctx.setState({ wnds: {} });
                return;
            }, 1000);
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

  render() {
    //if(!this.props.show) return "";
    var title = this.props.title;
    if(!title) title = "Resurs";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        <div className="m2_backdrop" />
        <div className="m2_dlg mx_90 flex-col" style={ this.dlg_style() } >
        <div className="mx_content flex">

        <div className="mx_h01">
            <div>Skärm : { utils.toNum( this.state.sco.scrid ) }</div>
        </div>
            <div className="flex-row" style={{padding:"5px",height:"35px"}}>
                    <div className="flex-col flex">
                    </div>
                    <div className="flex-col">
                        <div>UPPDATERA</div>
                    </div>
            </div>
            <div className="divider_orange"></div>

            <form className="cdi_f03">
                <div className="flex-row">
                    <Fr_Input label="Skärm ID" className="text-left w150" text={this.state.sco.scrid} getValue={(e)=> this.val_save("scrid", e) } />
                    <Fr_Input label="Status" className="text-left w350" text={this.state.sco.status} getValue={(e)=> this.val_save("status", e) } />
                </div>
                <div className="flex-row">
                    <Fr_Input label="Text" className="text-left w350" text={this.state.sco.text} getValue={(e)=> this.val_save("text", e) } />
                </div>
                <div className="flex-row">
                    <Fr_Input label="Layout" className="text-left w250" text={this.state.sco.ctid} getValue={(e)=> this.val_save("ctid", e) } />
                </div>
                <div className="flex-row">
                    <Fr_Input label="BG Typ" className="text-left w150" text={this.state.sco.bgtyp} getValue={(e)=> this.val_save("bgtyp", e) } />
                </div>
                <div className="flex-row">
                    <Fr_Input label="Färg" className="text-left w150" text={this.state.sco.bgfarg} getValue={(e)=> this.val_save("bgfarg", e) } />
                    <Fr_Input label="Url" className="text-left w350" text={this.state.sco.bgurl} getValue={(e)=> this.val_save("bgurl", e) } />
                </div>
                <div className="flex-row">
                    <div className="form-group flex-row flex">
                        <span className="header">Skärmtyp</span>
                        <select value={this.state.sco.scrtyp} className="form-control w250" onChange={(e) => this.scr_change(e)}>
                            { this.state.scrval.map((row, key) =>
                                <option key={key} value={row.id}>{row.text}</option>
                            )}
                        </select>
                    </div>
                </div>

                <div className="divider_green"></div>
                <div className="flex-row">
                    <div className="flex flex-column" style={{padding: "0px 5px"}}>
                        <span className="header">Skärm information</span>
                        <textarea className="form-control" maxLength="512" rows="10" value={this.state.sco.anm} onChange={(e) => this.anm_change(e)}></textarea>
                    </div>
                </div>
            </form>

        </div>
        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250 ripple" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <button className="btn btn-hw-red w250 ripple" onClick={() => this.scr_del() } >
                TA BORT
            </button>
            <div className="flex-row">
            <button className="btn btn-hw-red w250 ripple" onClick={() => this.scr_save() } >
                SPARA
            </button>
            </div>
        </div>
      </div>

        <Wnd_Error msg={this.state.msg} ctx={this} />
        <Wnd_Ok msg={this.state.msg} ctx={this} />
        <Wnd_Info msg={this.state.msg} ctx={this} />
        <Wnd_Confirm msg={this.state.msg} ctx={this} />
      </div>
	;
	return html;
  };
}

export default Scr_Edit;