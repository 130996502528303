import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";
import {withRouter} from "../../lib/react/withRouter";
import sso from "../../sso";

class Bo_Home extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, tra: [] };
    this.state.seldate = new Date();
    this.state.avd = "01";
    this.state.paa = [];
    this.state.aa = [];

      this.state.mna = [];
      this.state.mna.push({ mnid: "mn01", pos: "c1", text: "FÖRETAG", desc: "Företags data", link: "/ta/bo/ftg" });
      this.state.mna.push({ mnid: "mn02", pos: "c1", text: "PARAMETRAR", desc: "Företags parametrar tider", link: "/ta/bo/prm" });
      this.state.mna.push({ mnid: "mn03", pos: "c1", text: "AVDELNINGAR", desc: "Avdelnings definition", link: "/ta/bo/dep" });
      this.state.mna.push({ mnid: "mn04", pos: "c2", text: "KATEGORIER", desc: "Kategorier av anställda", link: "/ta/bo/cat" });
      this.state.mna.push({ mnid: "mn05", pos: "c2", text: "RÖDA DAGAR", desc: "Röda dagar per år", link: "/ta/bo/red" });
      this.state.mna.push({ mnid: "mn06", pos: "c2", text: "ANLEDNINGAR", desc: "Anledningar att ange", link: "/ta/bo/reason" });
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 480;
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }
    mnc(pos) {
        var mna = [];
        for(var row of this.state.mna) {
            if(row.pos == pos) mna.push( row );
        }
        return mna;
    }
    fknGo(url) {
        //alert(row.href);
        var row = {};
        row.url = url;
        sso.seo.sfkn = row;
        this.props.navigate(url);
    }

    render() {
    return (
    <div className="web-app">
        <div className="web-pg py-4 flex">
            <h3>BACKOFFICE</h3>
            <p>BACKOFFICE funktioner</p>

            <div className="divider_green"></div>

            <div className="web-row">
                <div className="flex-col flex">
                    { this.mnc("c1").map((row, key) =>
                        <div key={key} className="web-bx" onClick={() => this.fknGo(row.link) }>
                            <div>{ row.text }</div>
                            <div className="flex-row">
                                <div className="flex">{ row.desc }</div>
                                <div className="web-link">Välj</div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="flex-col flex">
                    { this.mnc("c2").map((row, key) =>
                        <div key={key} className="web-bx" onClick={() => this.fknGo(row.link) }>
                            <div>{ row.text }</div>
                            <div className="flex-row">
                                <div className="flex">{ row.desc }</div>
                                <div className="web-link">Välj</div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>

    </div>
  );
    }
}
export default withRouter(Bo_Home);
