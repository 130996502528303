import React, { useState, useEffect, useContext } from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";

import Cas_Top from './com/cas_top.js';
import Cas_Mnu from './com/cas_mnu.js';

import Cas_Home from './home.js';
import Cas_Dash from './dash.js';

import Cas_Rps from './rps.js';
import Cas_Fsg from './fsg.js';
import Cas_Mark from './mark.js';
import Cas_Prs from './prs.js';
import Cas_Bo from './bo.js';

import * as net from "../lib/net";
import Tiden from "../lib/ui/tiden";

import ddo from "../ddo";
import {withRouter} from "../lib/react/withRouter";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import sso from "../sso";

function web01() {

    var html =
    <div className="web-content">
    </div>
    ;
    return html;
}

class Casino extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.state = { pgid: 11, deps: [] };

    //alert( JSON.stringify(sso.seo.kid) );
      //ddo.cfg.units = {};
      ddo.cfg.units = {
          '00' : {id: '00', text: 'ALLA ENHETER'},
          '01' : {id: '01', text: 'ENHET 01'},
          '02' : {id: '02', text: 'ENHET 02'},
          '03' : {id: '03', text: 'ENHET 03'}
      };
      if( sso.seo && (sso.seo.kid == "00000356") ) {
          ddo.cfg.units = {
              '00' : {id: '00', text: 'ALLA ENHETER'},
              '01' : {id: '01', text: 'ICE'},
              '02' : {id: '02', text: 'ROYAL THAI'},
              '03' : {id: '03', text: 'BOOM BOOM ROOM'},
              '04' : {id: '04', text: 'CLUB PRIVE 2'},
              '05' : {id: '05', text: 'DEEP'},
              '06' : {id: '06', text: 'CLUB PRIVE 1'},
              '07' : {id: '07', text: 'SWING INN 1'},
              '08' : {id: '08', text: 'SWING INN 2'},
              '09' : {id: '09', text: 'LEDIG'},
              '10' : {id: '10', text: 'QUEENS'},
              '11' : {id: '11', text: 'KONG CHRISTIAN'},
              '12' : {id: '12', text: 'DANNYS'}
          };
      }
      if( sso.seo && (sso.seo.kid == "00000261") ) {
          ddo.cfg.units = {
              '00' : {id: '00', text: 'ALLA ENHETER'},
              '01' : {id: '01', text: 'CORNER'},
              '02' : {id: '02', text: 'SKEPPSBRON'},
              '03' : {id: '03', text: 'TORGET'},
              '04' : {id: '04', text: 'INTERNATIONAL'}
          };
      }
      if( sso.seo && (sso.seo.kid == "00002020") ) {
          ddo.cfg.units = {
              '00' : {id: '00', text: 'ALLA ENHETER'},
              '01' : {id: '01', text: 'HEYMARKERS'},
              '02' : {id: '02', text: 'FALUN BOWLING'},
              '03' : {id: '03', text: 'FOLKETSHUS BORLÄNGE'}
          };
      }
      if( sso.seo && (sso.seo.kid == "00000001") ) {
          ddo.cfg.units = {
              '00' : {id: '00', text: 'ALLA ENHETER'},
              '01' : {id: '01', text: 'CORNER'},
              '02' : {id: '02', text: 'SKEPPSBRON'},
              '03' : {id: '03', text: 'TORGET'},
              '04' : {id: '04', text: 'INTERNATIONAL'}
          };
      }
      ddo.cfg.una = [];
      var keys = Object.keys(ddo.cfg.units);
      keys.sort();
      for(var key of keys) {
          var o = ddo.cfg.units[key];
          ddo.cfg.una.push( o );
      }
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      /*
    var xdo = sessionStorage.getItem("svp");
    if(xdo) {
        var sdo = JSON.parse(xdo);
        //return routefkn(sdo);
    }
    else {
        //alert(xdo);
        sessionStorage.clear();
        this.props.navigate("/");
    }
    */
  }
  ddo() {
    alert("POS" + this.state.pgid );
    this.setState({ status: 1 });
  }
  logout() {
    sessionStorage.clear();
    this.props.navigate("/");
  }
    fknGo(url) {
        //alert(row.href);
        this.props.navigate(url);
    }

  render() {
    return (
    <div className="web-app">
    <div className="web-top">
        <Cas_Top ctx={this} store={this.store} />
    </div>
    <div className="web-main">

    <div className="web-row" style={{ height:"100%" }}>

    <div className="web-side">
        <Cas_Mnu store={this.store} />
    </div>

    <div className="web-body">
        <Routes>
            <Route path="rps/*" element={ <Cas_Rps store={this.store} />} />
            <Route path="fsg/*" element={ <Cas_Fsg store={this.store} />} />
            <Route path="mark/*" element={ <Cas_Mark store={this.store} />} />
            <Route path="prs/*" element={ <Cas_Prs store={this.store} />} />
            <Route path="bo/*" element={ <Cas_Bo store={this.store} />} />
            <Route path="home" element={ <Cas_Home store={this.store} />} />
            <Route path="dash" element={ <Cas_Dash store={this.store} />} />
            <Route path="*" element={ <Cas_Dash store={this.store} />} />
        </Routes>
    </div>
    </div>
    </div>

    <div className="web-foot">
        <div className="web-foot-mnu" onClick={() => this.ddo() }>
            <FontAwesomeIcon icon="bars" size="1x"/>
        </div>
        <div className="flex flex-row">
            <div className="flex"></div>

            <div className="web-foot-btn" onClick={() => this.fknGo("/cas/fsg/trs") } >FSG TRANS</div>
            <div className="web-foot-btn" onClick={() => this.fknGo("/cas/fsg/unit") } >FSG ENHET</div>
        </div>
        <div className="web-foot-icon">
          <FontAwesomeIcon icon="ellipsis-v" size="1x"/>
        </div>
        <div className="flex flex-row">
            <div className="web-foot-btn" onClick={() => this.fknGo("/cas/mark/ctrl") } >MARKER</div>

            <div className="flex"></div>
            <Tiden />
        </div>
    </div>

    </div>
  );
}
}

//Sys.contextType = ADOContext;

export default withRouter(Casino);

/*
  <Route path="/cas/stat" render={(props) => <Cas_Stat {...props} store={this.webStore} />} />
  <Route path="/cas/trs" render={(props) => <Cas_Trs {...props} store={this.webStore} />} />

*/