import React, { useState, useEffect, useContext } from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import * as net from "../../../lib/net";
import * as utils from "../../../assets/utils";

import Fr_Input from "../../../lib/ui/fr_input";
import Wnd_Error from "../../../lib/ui/wnd_error";
import Wnd_Ok from "../../../lib/ui/wnd_ok";
import Wnd_Info from "../../../lib/ui/wnd_info";
import Wnd_Confirm from "../../../lib/ui/wnd_confirm";
import Fr_Cal from "../../../lib/ui/fr_cal";
import Lang from "../../../lib/lang";
import Td_Sel from "../../../lib/ui/td_sel";

class Hub_edit extends React.Component {
  constructor(props, context) {
    super(props);

    ///this.pos = props.store.pos;

    this.state = {};
    this.state.zpa = [];

    this.state.gnr = "001";
    this.state.ynq = [
                {id: '0', text: 'NEJ'},
                {id: '1', text: 'JA'}
            ];
      this.state.hubtypval = [
          {id: 'bas', text: 'BAS HUB'},
          {id: 'enk', text: 'ENKEL HUB'},
          {id: 'mul', text: 'KOMPLETT HUB'}
      ];

    this.state.tzip = "";
    this.state.tzip = "";
    this.state.ztext = "";
    this.state.tabval = 0;
    this.state.msg = {};
    this.state.cols = {};
    this.state.hbo = {};
    if(props.wnds.hbo) this.state.hbo = props.wnds.hbo;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

    task_init() {
        var tdo = {};
        tdo.typ = "";
        tdo.id = "";
        tdo.text = "";
        tdo.reg = "";
        tdo.status = "";
        //alert(JSON.stringify(rso));
        this.setState({ tdo: tdo });
        return;
    }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    tb_style() {
        var css = {};
        css.height = window.innerHeight - 275;
        return css;
    }
    th_style(id) {
        var css = {};
        css.cursor = "pointer";
        if(id == this.state.sortid) {
            if(this.state.sortdir) css.color = "#800";
            else css.color = "#080";
        }
        return css;
    }
    row_style(row) {
        var srow = this.state.selrow;

        var css = {};
        if(srow == row) {
            css.background = "#080";
            css.color = "#fff";
        }
        return css;
    }
    tab_head(tabid) {
        var css = {};
        css.background = "#fff";
        css.color = "#000";
        if(this.state.tabval === tabid) {
            css.background = "#080";
            css.color = "#fff";
        }
        return css;
    }
    tab_style(tabid) {
        var css = {};
        css.display = "none";
        if(this.state.tabval === tabid) css.display = "block";
        return css;
    }
    tab_sel(tabid) {
        var self = this;
        this.setState({tabval: tabid}, function() {
            //alert(JSON.stringify(tabid));
            if(tabid == 1) {
                self.zips();
                //self.syncTb("mnpTable");
            }
            if(tabid == 2) {
                //self.syncTb("mdeTable");
            }
        });
    }
    tb_sort(typ) {
        var self = this;
        //var rvs = self.state.rvs;
        var mpa = self.state.mpa;
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;

        if(sortid != typ) sortdir = 0;
        else {
            if(sortdir) sortdir = 0;
            else sortdir = 1;
        }

        if(typ.indexOf(".") != -1) {
            var fa = typ.split(".");
            if(sortdir) mpa.sort((a,b) => (a[fa[0]][fa[1]] < b[fa[0]][fa[1]]) ? 1 : ((b[fa[0]][fa[1]] < a[fa[0]][fa[1]]) ? -1 : 0));
            else mpa.sort((a,b) => (a[fa[0]][fa[1]] > b[fa[0]][fa[1]]) ? 1 : ((b[fa[0]][fa[1]] > a[fa[0]][fa[1]]) ? -1 : 0));
        }
        else {
            if(sortdir) mpa.sort((a,b) => (a[typ] < b[typ]) ? 1 : ((b[typ] < a[typ]) ? -1 : 0));
            else mpa.sort((a,b) => (a[typ] > b[typ]) ? 1 : ((b[typ] > a[typ]) ? -1 : 0));
        }
        self.setState({ mpa: mpa, sortid: typ, sortdir: sortdir });
    }
    th_icon(typ) {
        var cls = "";
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;
        if(sortid == typ)
        {
            if(sortdir) cls = "fa fa-caret-up";
            else cls = "fa fa-caret-down";
        }
        return cls;
    }

    wnd_close = () => {
        if(this.props.close) {
            this.props.close(this.props.ctx);
        }
        else this.props.ctx.setState({ wnds: {} });
    };
    hubtyp_change (e) {
        const value = e.target.value;
        var hbo = this.state.hbo;
        hbo.hub00.hubtyp = value;
        this.setState({hbo: hbo});
    }
    anm_change (e) {
        const value = e.target.value;
        var sco = this.state.sco;
        sco.anm = value;
        this.setState({sco: sco});
    }

    val_save(id, val) {
        var hbo = this.state.hbo;
        var ida = id.split(".");
        var id1 = ida[0];
        var id2 = ida[1];
        hbo[id1][id2] = val;
        this.setState({ hbo: hbo });
        return;
    }
    mnu_mp() {
        var self = this;
        var prm = {};
        prm.req = "dsi.dsi_mp.mp_mnu";
        prm.mnuid = "mn02";

        net.sio_req(prm)
            .then(function(ret){
                alert(JSON.stringify(ret.rca));

                self.setState({ mna: ret.rca });
                //self.setState({ rva: ret.rca });
                self.syncTb("mnuTable");
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    syncTb(tb) {
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
            tdElements = tbel.getElementsByTagName('td');
        if(tdElements.length < 1 ) return;

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
                width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
  hub_upd() {
    var self = this;
    var prm = {};
    prm.req = "pos.pos_ecom_hub.hub_upd";
    prm.hbo = this.state.hbo;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));
        if(ret.ok == "000") {
            self.setState({ msg: {ok: true, text: "Sparad ..."} });
            setTimeout(function(){
                self.setState({msg: {} });
                  if(self.props.wnds.cb) {
                      self.props.wnds.cb(self.props.ctx);
                  }
                  else self.props.ctx.setState({ wnds: {} });
                return;
            }, 1000);
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
    zips() {
        var self = this;
        var self = this;
        var prm = {};
        prm.req = "pos.pos_ecom_hub.zip_list";
        //prm.hbo = this.state.hbo;

        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret));
            self.setState({ zpa: ret.rca });
            //self.setState({ rva: ret.rca });
            self.syncTb("zipTable");
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }
    zip_add() {
        var self = this;
        var zpo = {};
        zpo.typ = "Z";
        zpo.hubid = this.state.hbo.hub00.hubid;
        zpo.fzip = this.state.fzip;
        zpo.tzip = this.state.tzip;
        zpo.text = this.state.ztext;

        if(!zpo.fzip || (zpo.fzip.length < 1)) {
            return;
        };
        if(!zpo.tzip || (zpo.tzip.length < 1)) zpo.tzip = zpo.fzip;

        var prm = {};
        prm.req = "pos.pos_ecom_hub.zip_add";
        prm.zpo = zpo;

        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret));
            if(ret.ok == "000") {
                self.setState({ msg: {ok: true, text: "Tillagd"} });
                setTimeout(function(){
                    self.setState({msg: {} });
                    self.zips();
                    return;
                }, 1000);
            }
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }
    sel_row(row) {
    }
    zip_del(row) {
        var self = this;

        var prm = {};
        prm.req = "pos.pos_ecom_hub.zip_del";
        prm.zpo = row;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret));
                if(ret.ok == "000") {
                    self.setState({ msg: {ok: true, text: "Borttagen"} });
                    setTimeout(function(){
                        self.setState({msg: {} });
                        self.zips();
                        return;
                    }, 1000);
                }
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    hub_del() {
        var self = this;
    var prm = {};
    prm.req = "pos.pos_ecom_hub.hub_del";
    prm.hbo = this.state.hbo;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));
        if(ret.ok == "000") {
            self.setState({ msg: {ok: true, text: "Borttagen"} });
            setTimeout(function(){
                self.setState({msg: {} });
                  if(self.props.close) {
                      self.props.close(self.props.ctx);
                  }
                  else self.props.ctx.setState({ wnds: {} });
                return;
            }, 1000);
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

  render() {
    //if(!this.props.show) return "";
    var title = this.props.title;
    if(!title) title = "Resurs";

	var html =
        <div className="mx_base">
        <div className="m2_backdrop" />
        <div className="m2_dlg mx_90 flex-col" style={ this.dlg_style() } >
        <div className="mx_content flex">

        <div className="mx_h01">
            <div>Hub: {this.state.hbo.hub00.hubid} - {this.state.hbo.hub00.text}</div>
        </div>

            <div className="flex-row">
                <div className="tab_header flex" style={ this.tab_head(0) } onClick={ () => this.tab_sel(0) }>INFORMATION</div>
                <div className="tab_header flex" style={ this.tab_head(1) } onClick={ () => this.tab_sel(1) }>POSTNUMMER</div>
                <div className="tab_header flex" style={ this.tab_head(2) } onClick={ () => this.tab_sel(2) }>TIDER</div>
            </div>
            <div className="divider_green"></div>

            <div hidden={this.state.tabval !== 0}>
                <form className="cdi_f03">
                    <div className="flex-row">
                        <Fr_Input label="Hub ID" className="text-left w150" text={this.state.hbo.hub00.hubid} getValue={(e)=> this.val_save("hub00.hubid", e) } />
                    </div>
                    <div className="flex-row">
                        <Fr_Input label="Text" className="text-left w350" text={this.state.hbo.hub00.text} getValue={(e)=> this.val_save("hub00.text", e) } />
                    </div>
                    <div className="flex-row">
                        <Fr_Input label="System ID" className="text-left w250" text={this.state.hbo.hub00.sid} getValue={(e)=> this.val_save("hub00.sid", e) } />
                        <Fr_Input label="Avdelning" className="text-left w250" text={this.state.hbo.hub00.avd} getValue={(e)=> this.val_save("hub00.avd", e) } />
                    </div>
                    <div className="divider_green"></div>
                    <div className="flex-row">
                        <Fr_Input label="Slot längd" className="text-left w250" text={this.state.hbo.hub00.slottid} getValue={(e)=> this.val_save("hub00.slottid", e) } />
                        <Fr_Input label="Slot marginal" className="text-left w250" text={this.state.hbo.hub00.slotmarginal} getValue={(e)=> this.val_save("hub00.slotmarginal", e) } />
                    </div>
                    <div className="flex-row">
                        <Fr_Input label="Leverans Plu" className="text-left w250" text={this.state.hbo.hub00.levplu} getValue={(e)=> this.val_save("hub00.levplu", e) } />
                        <Fr_Input label="Mini Belopp" className="text-left w250" text={this.state.hbo.hub00.minbelopp} getValue={(e)=> this.val_save("hub00.minbelopp", e) } />
                    </div>
                    <div className="flex-row">
                        <Fr_Input label="Status" className="text-left w250" text={this.state.hbo.hub00.status} getValue={(e)=> this.val_save("hub00.status", e) } />
                    </div>
                    <div className="flex-row">
                        <div className="form-group flex-row flex">
                            <span className="header">Hubtyp</span>
                            <select value={this.state.hbo.hub00.hubtyp} className="form-control w250" onChange={(e) => this.hubtyp_change(e)}>
                                { this.state.hubtypval.map((row, key) =>
                                    <option key={key} value={row.id}>{row.text}</option>
                                )}
                            </select>
                        </div>
                    </div>

                    <div className="divider_green"></div>
                    <div className="flex-row">
                        <div className="flex flex-column" style={{padding: "0px 5px"}}>
                            <span className="header">Hub information</span>
                            <textarea className="form-control" maxLength="512" rows="10" value={this.state.hbo.anm} onChange={(e) => this.anm_change(e)}></textarea>
                        </div>
                    </div>
                </form>
            </div>
            <div hidden={this.state.tabval !== 1}>
                <div className="flex-row px-3">
                    <Fr_Input label="" noflex placeholder="Från" className="text-left w150" text={this.state.fzip} getValue={(e)=> this.setState({"fzip": e}) } />
                    <Fr_Input label="" noflex placeholder="Till" className="text-left w150" text={this.state.tzip} getValue={(e)=> this.setState({"tzip": e}) } />
                    <Fr_Input label="" noflex placeholder="Text" className="text-left w300" text={this.state.ztext} getValue={(e)=> this.setState({"ztext": e}) } />
                    <button className="btn btn-hw-blue w200" onClick={() => this.zip_add() } >
                        LÄGG TILL
                    </button>
                    <div className="flex"></div>
                    <div className="web-link h35" onClick={() => this.zips() }>UPPDATERA</div>
                </div>
                <div className="divider_green"></div>
                <div id="zipTable" className="flex">
                    <table className="table table-bordered table-hover" style={{marginBottom: "0px"}}>
                        <thead>
                        <tr>
                            <th style={ this.th_style("fzip") } onClick={() => this.tb_sort("fzip") }><Lang>Från Postnummer</Lang> <i className={ this.th_icon("fzip") }></i></th>
                            <th style={ this.th_style("tzip") } onClick={() => this.tb_sort("tzip") }><Lang>Till Postnummer</Lang> <i className={ this.th_icon("tzip") }></i></th>
                            <th style={ this.th_style("text") } onClick={() => this.tb_sort("text") }><Lang>Text</Lang> <i className={ this.th_icon("text") }></i></th>
                            <th style={ this.th_style("status") } onClick={() => this.tb_sort("status") }><Lang>Status</Lang> <i className={ this.th_icon("status") }></i></th>
                            <th></th>
                        </tr>
                        </thead>
                    </table>
                    <div style={ this.tb_style() }>
                        <table className="table table-bordered table-hover">
                            <tbody>
                            { this.state.zpa.map((row, key) =>
                                <tr key={key} style={ this.row_style(row) } onClick={() => this.sel_row(row)} onDoubleClick={ () => this.zip_edit(row) }>
                                    <td>{ row.fzip }</td>
                                    <td>{ row.tzip }</td>
                                    <td>{ utils.toLang( row.text ) }</td>
                                    <td>{ row.status }</td>
                                    <td className="text-center" onClick={() => this.zip_del(row) }>
                                        <FontAwesomeIcon style={{ color: "#800" }} icon="times" size="1x" />
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div hidden={this.state.tabval !== 2}>
                <div className="flex-row px-3">
                    <div className="web-link" onClick={() => this.prd_add() }>LÄGG TILL</div>
                    <div className="flex"></div>
                    <div className="web-link" onClick={() => this.mnu_mp() }>UPPDATERA</div>
                </div>
                <div className="divider_green"></div>
                <form className="cdi_f03">
                    <div className="flex-row">
                        <Fr_Input label="Måndag Från" className="text-left w250" text={this.state.hbo.hub02.mon_from} getValue={(e)=> this.val_save("hub02.mon_from", e) } />
                        <Fr_Input label="Måndag Till" className="text-left w250" text={this.state.hbo.hub02.mon_to} getValue={(e)=> this.val_save("hub02.mon_to", e) } />
                    </div>
                    <div className="flex-row">
                        <Fr_Input label="Tisdag Från" className="text-left w250" text={this.state.hbo.hub02.tue_from} getValue={(e)=> this.val_save("hub02.tue_from", e) } />
                        <Fr_Input label="Tisdag Till" className="text-left w250" text={this.state.hbo.hub02.tue_to} getValue={(e)=> this.val_save("hub02.tue_to", e) } />
                    </div>
                    <div className="flex-row">
                        <Fr_Input label="Onsdag Från" className="text-left w250" text={this.state.hbo.hub02.tue_from} getValue={(e)=> this.val_save("hub02.wed_from", e) } />
                        <Fr_Input label="Onsdag Till" className="text-left w250" text={this.state.hbo.hub02.tue_to} getValue={(e)=> this.val_save("hub02.wed_to", e) } />
                    </div>
                    <div className="flex-row">
                        <Fr_Input label="Torsdag Från" className="text-left w250" text={this.state.hbo.hub02.tue_from} getValue={(e)=> this.val_save("hub02.thu_from", e) } />
                        <Fr_Input label="Torsdag Till" className="text-left w250" text={this.state.hbo.hub02.tue_to} getValue={(e)=> this.val_save("hub02.thu_to", e) } />
                    </div>
                    <div className="flex-row">
                        <Fr_Input label="Fredag Från" className="text-left w250" text={this.state.hbo.hub02.tue_from} getValue={(e)=> this.val_save("hub02.fri_from", e) } />
                        <Fr_Input label="Fredag Till" className="text-left w250" text={this.state.hbo.hub02.tue_to} getValue={(e)=> this.val_save("hub02.fri_to", e) } />
                    </div>
                    <div className="flex-row">
                        <Fr_Input label="Lördag Från" className="text-left w250" text={this.state.hbo.hub02.tue_from} getValue={(e)=> this.val_save("hub02.sat_from", e) } />
                        <Fr_Input label="Lördag Till" className="text-left w250" text={this.state.hbo.hub02.tue_to} getValue={(e)=> this.val_save("hub02.sat_to", e) } />
                    </div>
                    <div className="flex-row">
                        <Fr_Input label="Söndag Från" className="text-left w250" text={this.state.hbo.hub02.tue_from} getValue={(e)=> this.val_save("hub02.sun_from", e) } />
                        <Fr_Input label="Söndag Till" className="text-left w250" text={this.state.hbo.hub02.tue_to} getValue={(e)=> this.val_save("hub02.sun_to", e) } />
                    </div>
                </form>
            </div>

        </div>
        <div className="mx_foot flex-row">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex"></div>
            <button className="btn btn-hw-red w250" onClick={() => this.hub_del() } >
                TA BORT
            </button>
            <div className="flex"></div>
            <button className="btn btn-hw-green w250" onClick={() => this.hub_upd() } >
                SPARA
            </button>
        </div>
      </div>

        <Wnd_Error msg={this.state.msg} ctx={this} />
        <Wnd_Ok msg={this.state.msg} ctx={this} />
        <Wnd_Info msg={this.state.msg} ctx={this} />
        <Wnd_Confirm msg={this.state.msg} ctx={this} />
      </div>
	;
	return html;
  };
}

export default Hub_edit;