import React, { useState, useEffect, useContext } from 'react';
import {format, subDays} from "date-fns";
import {Chart} from "chart.js";

import * as net from "../../../lib/net";
import Dt_Cal from "../../../lib/ui/dt_cal";
import Tid_FT from "../../../lib/ui/tid_ft";
import Fr_Select from "../../../lib/ui/fr_select";
import sso from "../../../sso";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Oms_Period extends React.Component {
  constructor(props, context) {
    super(props);

    this.my_bar = null;
    this.store = props.store;
    this.pos = props.store.pos;

    this.state = { pgid: 11, tra: [] };
    this.state.today = new Date();
    this.state.fdat = new Date(2023, 0, 1);
    this.state.tdat = subDays(this.state.today, 1);
    //this.state.tdat = new Date();
    //this.state.tdat.setDate(this.state.tdat.getDate() - 1);
    this.state.avd = "01";
    this.state.eka = [];
    this.state.tpa = [];
    this.state.tpa = [
                {id: '0', text: 'OMSÄTTNING'}
            ];
    this.state.typ = "0";
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.st_period();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

    st_period() {
        var self = this;

        var sdo = {};
        sdo.eko = {};
        var fdat = format(this.state.fdat, "yyyy-MM-dd");
        var tdat = format(this.state.tdat, "yyyy-MM-dd");

        //var csa = self.get_csa();
        var csa = sso.sdo.csa;

        function send_req(cso) {
            const promise1 = new Promise((resolve, reject) => {
                //setTimeout(resolve, 500, 'one');
                var prm = {};
                prm.sid = cso.c0sid;
                prm.req = "pos.pos_eko.sth_oms";
                prm.avd = "01";
                prm.fdat = fdat;
                prm.tdat = tdat;
                //alert(JSON.stringify(prm));

                net.sid_req(prm)
                    .then(function(ret){
                        //alert(JSON.stringify(ret));
                        //sdo.eko[sid] = ret.rco;
                        sdo.eko[cso.c0sid] = {};
                        sdo.eko[cso.c0sid].text = cso.c0text;
                        sdo.eko[cso.c0sid].tot = ret.rco.tot;
                        resolve();
                    })
                    .catch(function(e){
                        var txt = JSON.stringify(e);
                    });
            });
            return promise1;
        }
        var qa = [];
        for(var so of csa) {
            var promise = send_req(so);
            qa.push(promise);
        }
        Promise.all(qa)
        .then(function(ret){
            //alert(JSON.stringify(ret));
            var eka = [];
            var keys = Object.keys(sdo.eko);
            keys.sort();
            for(var key of keys) {
                var o = sdo.eko[key];
                eka.push(o);
            }
            self.setState({sdo: sdo, eka: eka });
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }

    row_proc(row) {
      var proc = 0;
      if(this.state.sdo.tot.fsg > 0) {
          proc = (row.fsg / this.state.sdo.tot.fsg) * 100;
          proc = Math.round(proc);
      }
      return proc + " %";
    }
    row_wday(row) {
      var day = "";
      if(row.wday == 1) day = "Måndag";
      if(row.wday == 2) day = "Tisdag";
      if(row.wday == 3) day = "Onsdag";
      if(row.wday == 4) day = "Torsdag";
      if(row.wday == 5) day = "Fredag";
      if(row.wday == 6) day = "Lördag";
      if(row.wday == 7) day = "Söndag";
      return day;
    }
    bar_chart() {
      var lbls = [];
      var da = {};
      da.ink = [];
      da.ex = [];

      for(var row of this.state.eka) {
          if(row.grp == "tot") continue;
          lbls.push( row.text.toswe() );
          da.ink.push( row.belopp.cdipris() );
      }

        if(this.my_bar) this.my_bar.destroy();
        this.my_bar =  new Chart(document.getElementById("barchart"), {
            type: 'bar',
            data: {
				labels: lbls,
                datasets: [{
                    data: da.ink,
                    label: 'Belopp',
                    backgroundColor: "#4755AB",
                    borderWidth: 1,
                }]
            },
            options: {
                responsive: true,
                legend: {
                    display: false,
                    position: 'top'
                },
            }
        });
	};

  render() {
    return (
    <div className="web-p00 flex flex-col">

    <div className="web-p01">
    <div className="flex-row">
        <Dt_Cal dat={this.state.fdat} getValue={(e)=> this.setState({fdat: e}) }/>
        <Dt_Cal dat={this.state.tdat} getValue={(e)=> this.setState({tdat: e}) }/>
        <Fr_Select label="" className="text-left w250" text={this.state.typ} items={this.state.tpa} getValue={(e)=> this.setState({ "typ": e }) } />
        <select className="form-control w250 hide" placeholder="Välj avdelning">
            { this.pos.deps.map((row, key) =>
            <option key={key} value={row.id}>{row.text}</option>
            )}
        </select>
        <div className="flex"></div>

        <button className="btn btn-hw-blue w150" onClick={() => this.st_period() } aria-label="System" >
            UPPDATERA
        </button>
    </div>

    <div className="divider_orange"></div>

    <div className="web-row">
        <table className="table table-bordered table-hover tb fixed_header">
            <thead>
            <tr>
                <th>Text</th>
                <th>Brutto</th>
                <th>Moms</th>
                <th>Netto</th>
                <th>Bet</th>
                <th>Diff</th>
                <th>Rabatt</th>
                <th>Resultat</th>
            </tr>
            </thead>
            <tbody style={ this.tb_style() }>
            { this.state.eka.map((row, key) =>
            <tr key={ key } id={ row.grp } onDoubleClick={() => this.st_edit(row) }>
                <td>{ row.text }</td>
                <td className="text-right">{ row.tot.brutto.cdipris() }</td>
                <td className="text-right">{ row.tot.moms.cdipris() }</td>
                <td className="text-right">{ row.tot.netto.cdipris() }</td>
                <td className="text-right">{ row.tot.bet.cdipris() }</td>
                <td className="text-right">{ row.tot.diff.cdipris() }</td>
                <td className="text-right">{ row.tot.rab.cdipris() }</td>
                <td className="text-right">{ row.tot.resultat.cdipris() }</td>
            </tr>
            )}
            </tbody>
        </table>
    </div>

    </div>
    </div>
  );
    }
}
export default Oms_Period;
