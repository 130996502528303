import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";

import PdfRpDlg from "./fkn/pdf_rp_dlg";
import Dt_Cal from "../../lib/ui/dt_cal";
import {format} from "date-fns";
import Trs_Per from "./trs/trs_per";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Pos_Trs extends React.Component {
    constructor(props, context) {
        super(props);

        this.ref_dat = React.createRef();

        this.pos = props.store.pos;
        this.state = {};

        this.state.today = new Date();
        var dts = format(this.state.today, "yyyy-MM-dd");
        this.state.datum = dts;

        this.state.avd = "01";
        this.state.vgrp = "";
        this.state.wnds = {};
        this.state.sdo = {};
        this.state.sdo.fsg = "";
        this.state.sdo.bet = "";
        this.state.trs = [];
        this.state.tra = [];
        this.state.aa = [];
        //context.posrend = this;
        this.state.pdf = 0;
        this.state.pdf_content = null;
        this.state.vgrps = [];
        if(this.pos.vgrps) this.state.vgrps = this.pos.vgrps;
        if(this.pos.deps && this.pos.deps.length) {
            this.state.avd = this.pos.deps[0].avd;
        }
    }
    componentDidUpdate() {
    }
    componentWillUnmount() {
    }
    componentDidMount() {
    }

    body_style() {
        var css = {};
        css.height = window.innerHeight - 280;
        return css;
    }
    tb_style() {
        var css = {};
        css.height = window.innerHeight - 300;
        return css;
    }
    th_style(id) {
        var css = {};
        css.cursor = "pointer";
        if(id == this.state.sortid) css.color = "#080";
        return css;
    }
    onUnitChange(e) {
        this.setState({unit: e.value});
    }
    dt_dat(e) {
        //alert(JSON.stringify(e));
        var dts = format(e, "yyyy-MM-dd");
        this.setState({ datum: dts });
    }
    addday(e) {
        //alert(JSON.stringify(e));
        //this.setState({ tdat: this.state.fdat });
        this.ref_dat.current.updateAdd();
    }
    subday(e) {
        //alert(JSON.stringify(e));
        //this.setState({ tdat: this.state.fdat });
        this.ref_dat.current.updateSub();
    }
    xdt_dat(e) {
        //alert(JSON.stringify(e));
        var dts = format(e, "yyyy-MM-dd");
        this.setState({ datum: dts });
    }
    change_avd(e) {
        //let cdat = this.state.seldate;
        let avd = e.target.value;
        this.setState({avd: avd});
    }
    change_vgrp(e) {
        var self = this;
        //let cdat = this.state.seldate;
        let vgrp = e.target.value;
        this.setState({ vgrp: vgrp }, function(){
            self.vgrp_filtr();
        });
    }
    pdf_done (ret) {
        this.setState({loading: 0, pdf: 0, pdf_content: null });
    }

    trs() {
        var self = this;

        var prm = {};
        prm.req = "pos.pos_trs.trs_per";
        //prm.req = "pos.pos_red.jr_red";
        prm.avd = this.state.avd;
        prm.datum = this.state.datum;
        prm.fdat = this.state.datum;
        prm.tdat = this.state.datum;
        prm.favd = this.state.avd;
        prm.tavd = this.state.avd;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.rco));

                self.setState({tra: ret.rca, trs: ret.rca, sdo: ret.sdo });
                //toastr.success("OK", "SAPI");
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
                //gda.wndError(txt);
            });
    }
    rp_trs() {
        var self = this;
        var prm = {};
        prm.req = "pos.pos_rp.rp_trs";
        prm.fdat = this.state.datum;
        prm.tdat = this.state.datum;
        prm.favd = this.state.avd;
        prm.tavd = this.state.avd;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.pdf));

                self.setState({pdf: 1, pdf_content: ret.pdf });
                //toastr.success("OK", "SAPI");
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
                //gda.wndError(txt);
            });
    }
    rp_notor() {
        var self = this;
        var prm = {};
        prm.req = "pos.pos_rp.rp_notor";
        prm.fdat = this.state.datum;
        prm.tdat = this.state.datum;
        prm.favd = this.state.avd;
        prm.tavd = this.state.avd;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.pdf));

                self.setState({pdf: 1, pdf_content: ret.pdf });
                //toastr.success("OK", "SAPI");
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
                //gda.wndError(txt);
            });
    }
    trs_per() {
        var self = this;
        var wnds = {};
        wnds.trsper = true;
        this.setState({ wnds: wnds });
    }
    tb_sort(typ) {
        var self = this;
        //var rvs = self.state.rvs;
        var tra = self.state.tra;
        var sz = this.state.sz_val;

        tra.sort((a,b) => (a[typ] > b[typ]) ? 1 : ((b[typ] > a[typ]) ? -1 : 0));
        self.setState({ tra: tra, sortid: typ });
    }
    tb_filtr() {
        var self = this;
        var trs = self.state.trs;
        var sz = this.state.sz_val;

        if(sz.length < 1) {
            self.setState({ tra: trs });
            return;
        }
        sz = sz.toUpperCase();
        var tra = trs.filter(function(row) {
            var brc = false;
            if(row.r0text.toUpperCase().includes(sz)) brc = true;
            if(row.r0etyp.toUpperCase().includes(sz)) brc = true;
            return brc;
        });
        self.setState({tra: tra });
    }
    vgrp_filtr() {
        var self = this;
        var trs = self.state.trs;
        var vgrp = this.state.vgrp;

        if(vgrp.length < 1) {
            self.setState({ tra: trs });
            return;
        }
        var tra = trs.filter(function(row) {
            var brc = false;
            if(row.r0roomno.substr(1, 3) == vgrp) brc = true;
            return brc;
        });
        self.setState({tra: tra });
    }


    render() {
        return (
            <div className="web-p00 flex flex-col">
                <div className="">
                    Transaktioner
                </div>

                <div className="web-p01">
                    <div className="flex-row">
                        <div className="flex-row">
                            <div className="dt-btn-01" onClick={() => this.subday() } ><FontAwesomeIcon icon="minus" size="1x" /></div>
                            <Dt_Cal dts={this.state.datum} rp="1" ref={this.ref_dat} getValue={(e)=> this.dt_dat(e)} />
                            <div className="dt-btn-01" onClick={() => this.addday() } ><FontAwesomeIcon icon="plus" size="1x" /></div>
                        </div>
                        <select className="form-control w250" placeholder="Avdelning" onChange={(e) => this.change_avd(e)}>
                            { this.pos.deps.map((row, key) =>
                                <option key={key} value={row.avd}>{row.avd} - {row.text}</option>
                            )}
                        </select>
                        <select className="form-control w250" placeholder="Varugrupp" onChange={(e) => this.change_vgrp(e)}>
                            { this.state.vgrps.map((row, key) =>
                                <option key={key} value={row.v0katnr}>{row.v0katnr} - {row.v0text}</option>
                            )}
                        </select>
                        <div className="flex"></div>
                        <button className="btn btn-hw-blue w150" onClick={() => this.trs_per() } aria-label="System" >
                            Period
                        </button>
                        <button className="btn btn-hw-blue w150" onClick={() => this.trs() } aria-label="System" >
                            Visa
                        </button>
                        <button className="btn btn-hw-blue w150" onClick={() => this.rp_trs() } aria-label="System" >
                            Skriv lista
                        </button>
                        <button className="btn btn-hw-blue w150" onClick={() => this.rp_notor() } aria-label="System" >
                            Skriv notor
                        </button>
                    </div>
                    <div className="divider_orange"></div>

                    <div className="flex-column">
                        <table className="table table-bordered table-hover tb fixed_header">
                            <thead>
                            <tr>
                                <th style={ this.th_style("r0lopnr") } onClick={() => this.tb_sort("r0lopnr") }>Trans</th>
                                <th style={ this.th_style("r0cstplc") } onClick={() => this.tb_sort("r0cstplc") }>Avd</th>
                                <th style={ this.th_style("r0tablnr") } onClick={() => this.tb_sort("r0tablnr") }>Bordnr</th>
                                <th style={ this.th_style("r0faktnr") } onClick={() => this.tb_sort("r0faktnr") }>Notanr</th>
                                <th style={ this.th_style("r0etyp") } onClick={() => this.tb_sort("r0etyp") }>Plu/Betkod</th>
                                <th style={ this.th_style("r0time") } onClick={() => this.tb_sort("r0time") }>Tid</th>
                                <th style={ this.th_style("r0text") } onClick={() => this.tb_sort("r0text") }>Text</th>
                                <th style={ this.th_style("r0noof") } onClick={() => this.tb_sort("r0noof") }>Antal</th>
                                <th style={ this.th_style("r0perprice") } onClick={() => this.tb_sort("r0perprice") }>Pris</th>
                                <th style={ this.th_style("summa") } onClick={() => this.tb_sort("summa") }>Summa</th>
                                <th style={ this.th_style("r0debcre") } onClick={() => this.tb_sort("r0debcre") }>Tecken</th>
                                <th style={ this.th_style("r0vatproc") } onClick={() => this.tb_sort("r0vatproc") }>Moms</th>
                                <th style={ this.th_style("r0datum") } onClick={() => this.tb_sort("r0datum") }>Datum</th>
                                <th style={ this.th_style("r0terminal") } onClick={() => this.tb_sort("r0terminal") }>Terminal</th>
                                <th style={ this.th_style("r0rumsnr") } onClick={() => this.tb_sort("r0rumsnr") }>Rumsnr</th>
                                <th style={ this.th_style("r0sign") } onClick={() => this.tb_sort("r0sign") }>Signatur</th>
                            </tr>
                            </thead>
                            <tbody style={ this.tb_style() }>
                            { this.state.tra.map((row, key) =>
                                <tr key={key} onDoubleClick={() => this.plu_open(row) } >
                                    <td>{ row.r0lopnr }</td>
                                    <td className="text-center">{ row.r0cstplc }</td>
                                    <td className="text-center">{ row.r0tablnr }</td>
                                    <td>{ row.r0faktnr }</td>
                                    <td>{ row.r0etyp }</td>
                                    <td className="text-center">{ row.r0time.cditid() }</td>
                                    <td>{ row.r0text.toswe() }</td>
                                    <td className="text-center">{ row.r0noof.ztrim() }</td>
                                    <td className="text-right">{ row.r0perprice.cdipris() }</td>
                                    <td className="text-right">{ ((row.r0noof * 1) * (row.r0perprice * 1)).cdipris() }</td>
                                    <td>{ row.r0debcre }</td>
                                    <td>{ row.r0vatproc }</td>
                                    <td>{ row.r0datum }</td>
                                    <td>{ row.r0terminal }</td>
                                    <td>{ row.r0rumsnr }</td>
                                    <td>{ row.r0sign }</td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                        <div className="divider_orange"></div>
                        <div className="flex-row">
                            <span className="flex">TOTAL: { this.state.tra.length }</span>
                            <span className="flex">FSG: { this.state.sdo.fsg.cdipris() }</span>
                            <span className="flex">BET: { this.state.sdo.bet.cdipris() }</span>
                        </div>
                    </div>

                </div>

                { this.state.wnds.trsper ? <Trs_Per wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
                <PdfRpDlg show={this.state.pdf} pdf={this.state.pdf_content} done={(ret) => this.pdf_done(ret)} backdrop={true}/>
            </div>
        );
    }
}
export default Pos_Trs;
