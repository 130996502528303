import React, { useState, useEffect, useContext } from 'react';
import {format} from "date-fns";

import * as net from "../../../../lib/net";
import Dd_Sel from "../../../../lib/ui/dd_sel";
import Fr_Input from "../../../../lib/ui/fr_input";
import Dt_Cal from "../../../../lib/ui/dt_cal";
import Td_Sel from "../../../../lib/ui/td_sel";

class Pay_Fakt extends React.Component {
  constructor(props, context) {
    super(props);

     this.selitems = [
        {fkn: 'setup', text: 'INSTÄLLNINGAR'},
        {fkn: 'upd', text: 'UPPDATERA'},
        {fkn: 'sys', text: 'SYSTEM'},
        {fkn: 'logout', text: 'LOGGA UT'}
    ];

    this.dt_dt = React.createRef();

    this.state = {};
    this.state.ynq = [];

    this.state.paa = [];
    this.state.aa = [];
    this.state.nta = [];
    this.state.fao = {};
    if(props.wnds) {
        this.fkn_close = props.wnds.fkn_close;
        this.state.boknr = props.wnds.boknr;
        this.state.rvo = props.wnds.rvo;
        this.state.nta = props.wnds.nta;
        this.state.srow = props.wnds.srow;
    }

    this.state.today = new Date();
    var dts = format(this.state.today, "yyyy-MM-dd");
    this.state.dts = dts;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }

    wnd_close() {
      if(this.fkn_close) this.fkn_close(this.props.ctx);
      else this.props.ctx.setState({ wnds: {} });
	};
  tb_style() {
      var css = {};
      //css.height = window.innerHeight - 275;
      css.overflow = "auto";
      return css;
  }
  row_style(row) {
    var srow = this.state.srow;
    var css = {};
    if(srow[row.etr01.e0lopnr]) {
        css.background = "#080";
        css.color = "#fff";
    }
    return css;
  }
  clear() {
    this.setState({srow: {}});
  }
  set_all() {
    var srow = this.state.srow;
    for(var row of this.state.nta) {
        srow[row.etr01.e0lopnr] = row;
    }
    this.setState({srow: srow});
  }
  sel_row(row) {
    var srow = this.state.srow;

    if(srow[row.etr01.e0lopnr]) {
      delete( srow[row.etr01.e0lopnr] );
    }
    else srow[row.etr01.e0lopnr] = row;
    this.setState({srow: srow});
  }
  val_date(val) {
      var ret = "";
      if(val) ret = val.toString().cdidate();
      return ret;
  }
  fakt() {
  }

  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "";

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="m2_backdrop" /> : null }
        <div className="m2_dlg mx_80h flex-col" style={ this.dlg_style() }>
        <div className="flex-row " style={{padding:"0px",height:"50px",lineHeight:"50px", background: "#1a456b", color: "#fff"}}>
                <div className="flex-row flex" style={{padding:"0px 5px", fontSize: "22px"}}>
                    <div>FAKTURA { this.state.rumsnr }</div>
                </div>
            <div className="wnd-close-btn" onClick={() => this.wnd_close() }>X</div>
        </div>
        <div className="mx_content flex">

        <div id="payrowsTable" className="flex">
          <table className="table table-bordered table-hover tb" style={{marginBottom: "0px"}}>
            <thead>
            <tr>
                <th>Datum</th>
                <th>T</th>
                <th>Typ</th>
                <th>Text</th>
                <th>Antal</th>
                <th>Pris</th>
                <th>Summa</th>
                <th>Tecken</th>
                <th>Löpnr</th>
                <th></th>
            </tr>
            </thead>
        </table>
        <div style={ this.tb_style() }>
          <table className="table table-bordered table-hover tb">
            <tbody>
            { this.state.nta.map((row, key) =>
            <tr key={key} style={ this.row_style(row) } onClick={ () => this.sel_row(row) }>
                <td>{ this.val_date( row.etr01.e0datum ) }</td>
                <td>{ row.etr01.e0bastyp }</td>
                <td>{ row.etr01.e0typ }</td>
                <td>{ row.etr01.e0text }</td>
                <td className="text-center">{ row.etr01.e0antal.ztrim() }</td>
                <td className="text-right">{ row.etr01.e0pris.cdipris() }</td>
                <td className="text-right">{ ((row.etr01.e0antal * 1) * (row.etr01.e0pris * 1)).cdipris() }</td>
                <td>{ row.etr01.e0tkn }</td>
                <td>{ row.etr01.e0lopnr.ztrim() }</td>
                <td>
                    <Td_Sel items={ this.rowitems } getValue={(e)=> this.sel_row_fkn(row, e)} />
                </td>
            </tr>
            )}
            </tbody>
        </table>
        </div>
        </div>

        <div className="flex-row px-2">
            <div className="web-link" onClick={() => this.clear() }>Rensa</div>
            <div className="flex"></div>
            <div className="web-link" onClick={() => this.set_all() }>Välj alla</div>
        </div>
        <div className="divider_orange"></div>

            <form className="cdi_f03">
            <div className="flex-row py-2">
            <div className="flex-col">
                    <Fr_Input label="Företag" className="text-left w350" text={this.state.fao.ankdat} getValue={(e)=> this.val_save("namn", e) } />
                    <Fr_Input label="Namn" className="text-left w350" text={this.state.fao.namn} getValue={(e)=> this.val_save("namn", e) } />
                    <Fr_Input label="Adress" className="text-left w250" text={this.state.fao.avrdat} getValue={(e)=> this.val_save("rumsnr", e) } />
                    <Fr_Input label="Stad" className="text-left w250" text={this.state.fao.avrdat} getValue={(e)=> this.val_save("rumsnr", e) } />
                    <Fr_Input label="Land" className="text-left w250" text={this.state.fao.avrdat} getValue={(e)=> this.val_save("rumsnr", e) } />
            </div>
            <div className="flex-col flex-start">
                    <Fr_Input noflex label="Orgnr" className="text-left w250" text={this.state.fao.rumsnr} getValue={(e)=> this.val_save("rumsnr", e) } />
                    <Fr_Input noflex label="Avdeelning" className="text-left w150" text={this.state.fao.nrg} getValue={(e)=> this.val_save("nrg", e) } />
                    <Fr_Input noflex label="Kod" className="text-left w250" text={this.state.fao.nrb} getValue={(e)=> this.val_save("nrb", e) } />
            </div>
            </div>
            <div className="divider_green"></div>
            <div className="flex-row py-2">
                    <Fr_Input label="Vår ref" className="text-left w250" text={this.state.fao.email} getValue={(e)=> this.val_save("email", e) } />
                    <Fr_Input label="Märkning" className="text-left w250" text={this.state.fao.pass} getValue={(e)=> this.val_save("pass", e) } />
            </div>
            <div className="divider_green"></div>
            <div className="flex-row py-2">
                    <Fr_Input label="Fakturadatum" className="text-left w250" text={this.state.fao.mobile} getValue={(e)=> this.val_save("mobile", e) } />
                    <Fr_Input label="Leveransdatum" className="text-left w250" text={this.state.fao.mobile} getValue={(e)=> this.val_save("mobile", e) } />
                    <Fr_Input label="Förfallodatum" className="text-left w250" text={this.state.fao.tele} getValue={(e)=> this.val_save("tele", e) } />
            </div>
            <div className="divider_green"></div>
            </form>

        </div>
        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250 ripple" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex-row"></div>
            <button className="btn btn-hw-green w250 ripple" onClick={() => this.fakt() } >
                FAKTURERA
            </button>
        </div>
      </div>
    </div>
	;
	return html;
  };
}

export default Pay_Fakt;