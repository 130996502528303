import React, { useState, useEffect, useContext } from 'react';
import * as fkn from "../../lib/fkn";
import * as net from "../../lib/net";

import Dt_Cal from "../../lib/ui/dt_cal";
import {format} from "date-fns";
import Lang from "../../lib/lang";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function Trs_lt(props) {
    var html = "";

    //alert(props.fkn);

    if(props.fkn == "TRS") {
        return(
                <div className="web-row">
                    <table className="table table-bordered table-hover tb fixed_header">
                        <thead>
                        <tr>
                            <th>Datum</th>
                            <th>Rum</th>
                            <th>Typ</th>
                            <th>Tid</th>
                            <th>Konto</th>
                            <th>Text</th>
                            <th>Antal</th>
                            <th>Pris</th>
                            <th>Tkn</th>
                        </tr>
                        </thead>
                        <tbody style={ props.ctx.tb_style() }>
                        { props.tra.map((row, key) =>
                            <tr key={key} id={ row.e0datum } ng-dblclick="trans_open(row);">
                                <td>{ row.e0datum.cdidate() }</td>
                                <td>{ row.e0rumsnr }</td>
                                <td>{ row.e0typ }</td>
                                <td>{ row.e0klockan }</td>
                                <td>{ row.e0kontonr }</td>
                                <td>{ row.e0text }</td>
                                <td>{ row.e0antal.ztrim() }</td>
                                <td>{ row.e0pris.cdipris() }</td>
                                <td>{ row.e0tkn }</td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
        );
    }
    else if(props.fkn == "TOT") {
        return(
    <div className="web-row">
        <table className="table table-bordered table-hover tb fixed_header">
            <thead>
            <tr>
                <th>Från</th>
                <th>Till</th>
                <th>FSG</th>
                <th>BET</th>
                <th>Status</th>
            </tr>
            </thead>
            <tbody style={ props.ctx.tb_style() }>
            { props.tra.map((row, key) =>
            <tr key={key} id={ row.id } ng-dblclick="trans_open(row);">
                <td>{ row.fdat.cdidate() }</td>
                <td>{ row.tdat.cdidate() }</td>
                <td>{ row.fsg.cdipris() }</td>
                <td>{ row.bet.cdipris() }</td>
                <td>{ row.status }</td>
            </tr>
            )}
            </tbody>
        </table>
    </div>
        );
    }
    else if(props.fkn == "KTO") {
        return(
    <div className="web-row">
        <table className="table table-bordered table-hover tb fixed_header">
            <thead>
            <tr>
                <th>Konto</th>
                <th>Text</th>
                <th>Antal</th>
                <th>Belopp</th>
                <th>Status</th>
            </tr>
            </thead>
            <tbody style={ props.ctx.tb_style() }>
            { props.tra.map((row, key) =>
            <tr key={key} id={ row.konto } ng-dblclick="trans_open(row);">
                <td>{ row.konto }</td>
                <td>{ row.text }</td>
                <td>{ row.antal.ztrim() }</td>
                <td>{ row.belopp.cdipris() }</td>
                <td>{ row.status }</td>
            </tr>
            )}
            </tbody>
        </table>
    </div>
        );
    }
    else {
        return(
    <div className="web-row">
        <table className="table table-bordered table-hover tb fixed_header">
            <thead>
            <tr>
                <th className="text-center">Datum</th>
                <th className="text-center">Typ</th>
                <th className="text-center">Konto</th>
                <th className="text-center">Text</th>
                <th className="text-center">Antal</th>
                <th className="text-center">Belopp</th>
                <th className="text-center">Status</th>
            </tr>
            </thead>
            <tbody style={ props.ctx.tb_style() }>
            { props.tra.map((row, key) =>
            <tr key={key} id={ row.id } ng-dblclick="trans_open(row);">
                <td className="text-center">{ row.datum.cdidate() }</td>
                <td>{ row.typ }</td>
                <td>{ row.konto }</td>
                <td>{ row.text }</td>
                <td className="text-center">{ row.antal.ztrim() }</td>
                <td className="text-right">{ row.belopp.cdipris() }</td>
                <td>{ row.status }</td>
            </tr>
            )}
            </tbody>
        </table>
    </div>
        );
    }
}

class Pms_Trs extends React.Component {
  constructor(props, context) {
    super(props);

    this.dt_fd = React.createRef();
    this.dt_td = React.createRef();

    this.webStore = props.store;
    this.state = { pgid: 11, tra: [], tsa: [], tta: [] };
    this.state.fkn = "";

    this.state.today = new Date();
    var dts = format(this.state.today, "yyyy-MM-dd");
    this.state.fdat = dts;
    this.state.tdat = dts;
      this.state.wnds = {};
      this.state.sortid = "";
      this.state.sortdir = 1;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.trans();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 250;
      css.overflow = "auto";
      return css;
  }
    th_style(id) {
        var css = {};
        css.cursor = "pointer";
        if(id == this.state.sortid) {
            if(this.state.sortdir) css.color = "#800";
            else css.color = "#080";
        }
        return css;
    }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }
  dt_fdat(e) {
    //alert(JSON.stringify(e));
    var dts = format(e, "yyyy-MM-dd");
    this.setState({ fdat: dts });
  }
  dt_tdat(e) {
      //alert(JSON.stringify(e));
    var dts = format(e, "yyyy-MM-dd");
    this.setState({ tdat: dts });
  }
  oneday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_td.current.updateDt(this.state.fdat);
  }
  addday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_fd.current.updateAdd();
    this.dt_td.current.updateAdd();
  }
  subday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_fd.current.updateSub();
    this.dt_td.current.updateSub();
  }

  change_fkn(e) {
    //let cdat = this.state.seldate;
    //alert(JSON.stringify(e));
    let fkn = e.target.value;
    this.setState({fkn: fkn});
    this.setState({tra: [], tsa: [], tta: [] });
  }

  dt_fkn() {
    var self = this;

    var prm = {};
    //prm.req = "pms.eko_red.red_hbok";
    prm.req = "pms.eko_red.red_trs";
    prm.fdat = this.state.fdat;
    prm.tdat = this.state.tdat;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rco.tot));

        if(ret.ok != "000") {
            return;
        }
        self.setState({tra: ret.rca });
        self.syncTb("trsTable");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
    }
    syncTb(tb) {
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
            tdElements = tbel.getElementsByTagName('td');
        if(tdElements.length < 1 ) return;

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
                width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
    tb_sort(typ) {
        var self = this;
        //var rvs = self.state.rvs;
        var tra = self.state.tra;
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;

        if(sortid != typ) sortdir = 0;
        else {
            if(sortdir) sortdir = 0;
            else sortdir = 1;
        }

        if(sortdir) tra.sort((a,b) => (a[typ] < b[typ]) ? 1 : ((b[typ] < a[typ]) ? -1 : 0));
        else tra.sort((a,b) => (a[typ] > b[typ]) ? 1 : ((b[typ] > a[typ]) ? -1 : 0));

        self.setState({ tra: tra, sortid: typ, sortdir: sortdir });
    }
    th_icon(typ) {
        var cls = "";
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;
        if(sortid == typ)
        {
            if(sortdir) cls = "fa fa-caret-up";
            else cls = "fa fa-caret-down";
        }
        return cls;
    }

  render() {
    return (
    <div className="web-app">
    <div className="">
        TRANSAKTIONER
    </div>

    <div className="flex-row">
        <div className="flex-row">
              <div className="dt-btn-01" onClick={() => this.subday() } ><FontAwesomeIcon icon="minus" size="1x" /></div>
              <Dt_Cal startdatum="2020-02-01" rp="1" ref={this.dt_fd} getValue={(e)=> this.dt_fdat(e)} />
              <div className="dt-btn-01" onClick={() => this.oneday() } >1</div>
              <Dt_Cal startdatum="2020-02-01" rp="1" ref={this.dt_td} getValue={(e)=> this.dt_tdat(e)} />
              <div className="dt-btn-01" onClick={() => this.addday() } ><FontAwesomeIcon icon="plus" size="1x" /></div>
        </div>
            <select className="form-control sel_input" placeholder="Välj funktion" value={this.state.fkn} onChange={(e) => this.change_fkn(e)} >
                <option value="">ALLA</option>
                <option value="FSG">FÖRSÄLJNING</option>
                <option value="BET">BETALNINGAR</option>
                <option value="FSK">FÖRSKOTT</option>
            </select>
        <div className="flex"></div>
        <button className="btn btn-hw-blue w150" onClick={() => this.dt_fkn() } aria-label="System" >
            Visa
        </button>
    </div>
    <div className="divider_green"></div>

        <div id="trsTable" className="flex">
            <table className="table table-bordered table-hover" style={{marginBottom: "0px"}}>
                <thead>
                <tr>
                    <th style={ this.th_style("e0datum") } onClick={() => this.tb_sort("e0datum") }><Lang>Datum <i className={ this.th_icon("e0datum") }></i></Lang></th>
                    <th style={ this.th_style("e0rumsnr") } onClick={() => this.tb_sort("e0rumsnr") }><Lang>Rum <i className={ this.th_icon("e0rumsnr") }></i></Lang></th>
                    <th style={ this.th_style("e0typ") } onClick={() => this.tb_sort("e0typ") }><Lang>Typ <i className={ this.th_icon("e0typ") }></i></Lang></th>
                    <th style={ this.th_style("e0tid") } onClick={() => this.tb_sort("e0tid") }><Lang>Tid <i className={ this.th_icon("e0tid") }></i></Lang></th>
                    <th style={ this.th_style("e0boknr") } onClick={() => this.tb_sort("e0boknr") }><Lang>Bok-Gst <i className={ this.th_icon("e0boknr") }></i></Lang></th>
                    <th style={ this.th_style("e0text") } onClick={() => this.tb_sort("e0text") }><Lang>Text <i className={ this.th_icon("e0text") }></i></Lang></th>
                    <th style={ this.th_style("e0antal") } onClick={() => this.tb_sort("e0antal") }><Lang>Antal <i className={ this.th_icon("e0antal") }></i></Lang></th>
                    <th style={ this.th_style("e0pris") } onClick={() => this.tb_sort("e0pris") }><Lang>Pris <i className={ this.th_icon("e0pris") }></i></Lang></th>
                    <th style={ this.th_style("e0tkn") } onClick={() => this.tb_sort("e0tkn") }><Lang>Tkn <i className={ this.th_icon("e0tkn") }></i></Lang></th>
                </tr>
                </thead>
            </table>
            <div style={ this.tb_style() }>
                <table className="table table-bordered table-hover tb tbs02">
                    <tbody>
                { this.state.tra.map((row, key) =>
                    <tr key={key} onDoubleClick={() => this.trans_open(row) }>
                        <td>{ row.e0datum.cdidate() }</td>
                        <td>{ row.e0rumsnr }</td>
                        <td>{ row.e0typ }</td>
                        <td>{ row.e0tid }</td>
                        <td>{ row.e0boknr }-{ row.e0glopnr }</td>
                        <td>{ row.e0text }</td>
                        <td>{ row.e0antal.ztrim() }</td>
                        <td>{ row.e0pris.cdipris() }</td>
                        <td>{ row.e0tkn }</td>
                    </tr>
                )}
                </tbody>
            </table>
            </div>
        </div>

    </div>
  );
}
}
export default Pms_Trs;
