import React, { useState, useEffect, useContext } from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {format} from "date-fns";

import { observer } from "mobx-react";
import {withStore} from "../../sdo";

import * as net from "../../lib/net";

import Tiden from "../../lib/ui/tiden";
import Dd_Sel from "../../lib/ui/dd_sel";

import Red_Top from './hotback_top.js';
import Red_Mnu from './hotback_mnu.js';
import Red_Home from './home.js';
import Red_Dash from './dash.js';
import Red_Auth from "./auth.js";

import Red_Kp from './kp.js';
import Red_Eko from './eko.js';
import Red_Rps from './rps.js';
import Dt_Cal from "../../lib/ui/dt_cal";
import Base_Conf from "./fkn/base_conf";
import {withRouter} from "../../lib/react/withRouter";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Bo_Red extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.selitems = [
        {fkn: 'setup', text: 'INSTÄLLNINGAR'},
        {fkn: 'upd', text: 'UPPDATERA'},
        {fkn: 'sys', text: 'SYSTEM'},
        {fkn: 'logout', text: 'LOGGA UT'}
    ];
    this.btmitems = [
        {fkn: 'kreg', text: 'KUNDREGISTER'},
        {fkn: 'logg', text: 'LOGG'},
        {fkn: 'hist', text: 'HISTORIK'}
    ];
    this.langitems = [
        {fkn: 'sv', text: 'SVENSKA'},
        {fkn: 'no', text: 'NORSKA'},
        {fkn: 'en', text: 'ENGELSKA'}
    ];

    var pms = {};
      pms.rta = [];
      pms.rto = {};
      pms.pka = [];
      pms.pko = {};
    pms.stat = {};
    pms.base = {};
    pms.base.today = new Date();
    pms.base.fdat = format(pms.base.today, "yyyy-MM-dd");
    pms.base.tdat = format(pms.base.today, "yyyy-MM-dd");
    //pms.base.fdat = "2021-04-01";
    //pms.base.tdat = "2021-04-04";
    props.store.pms = pms;

    this.store = props.store;
    this.pms = props.store.pms;

    this.state = { pgid: 11, deps: [] };

    this.state.wnds = {};
    this.state.boknr = "";
    this.state.lang = "sv";
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //alert(JSON.stringify(pos));
    this.pms_init();
  }

    pms_init() {
        var self = this;

        var req = {};
        req.req = "pms.fkn.pms_init";

        net.sio_req(req)
        .then(function(ret){
            //alert(JSON.stringify(ret));

            if(!self.props.store.pms) self.props.store.pms = {};
            self.props.store.pms.rto = ret.rco.rto;
            self.props.store.pms.rta = ret.rco.rta;
            self.props.store.pms.pko = ret.rco.pko;
            self.props.store.pms.pka = ret.rco.pka;

            self.props.store.pms.redauth = 1;

            //self.setState({ deps: ret.rco });
            self.st_state();
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
            //alert(JSON.stringify(txt));
        })
    }
  st_state() {
    var self = this;

    var prm = {};
    prm.req = "pms.cal.pms_dt_state";
    prm.datum = "";

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        self.props.store.pms.stat = ret.rco;
    })
    .catch(function(e) {
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }

  sz_open() {
      var self = this;
    var wnds = {};
    wnds.szw = 1;
    this.setState({ wnds: wnds });
  }
  rvn_open() {
      var self = this;
    var wnds = {};
    wnds.rvn = 1;
    this.setState({ wnds: wnds });
  }
  rr_open() {
    var self = this;
    var wnds = {};
    wnds.rr = 1;
    this.setState({ wnds: wnds });
  }
  rrp_open() {
    var self = this;
    var wnds = {};
    wnds.rrp = 1;
    this.setState({ wnds: wnds });
  }
  petty_open() {
      var self = this;
    var wnds = {};
    wnds.petty = 1;
    this.setState({ wnds: wnds });
  }
  fknGo(url) {
    //alert(row.href);
    this.props.navigate(url);
  }
  sel_fkn(sel) {
    //alert(JSON.stringify(fkn));
      if(sel.fkn == "logout") {
      }
  }
  sel_btm(sel) {
    //alert(JSON.stringify(fkn));
      if(sel.fkn == "kreg") this.fknGo("/pms/fo/kreg")
      if(sel.fkn == "logg") this.fknGo("/pms/fkn/logg")
  }
  sel_lang(sel) {
    //alert(JSON.stringify(fkn));
      if(sel.fkn == "sv") {
        this.props.xstore.setLang("sv");
      }
      if(sel.fkn == "no") {
        this.props.xstore.setLang("no");
      }
      if(sel.fkn == "en") {
        this.props.xstore.setLang("en");
      }
  }
  base_period() {
    var wnds = {};
    wnds.baseconf = true;
    //wnds.tro = row;
    this.setState({ wnds: wnds });
  }
  dlg_style() {
    var css = {};
    //css.width = window.innerWidth * 0.8;
    //css.height = window.innerHeight * 0.8;
    return css;
  }

  render() {
      //if(!this.props.store.pms.redauth) {
      if(false) {
          //this.props.store.pms.redauth = true;
            //alert("REGISTER AUTH");
            return (
            <div className="web-app">
                <div className="web-body">
                    <Routes>
                        <Route path="*" element={ <Red_Auth store={this.store} />} />
                    </Routes>
                </div>
            </div>
          );
      }
    return (
    <div className="mx_base">
    <div className="mx_fb flex-col" style={ this.dlg_style() }>
  <div className="web-app">
    <div className="web-top">
        <Red_Top ctx={this} store={this.store} />
    </div>
    <div className="web-main">

    <div className="web-row" style={{ height:"100%" }}>

    { this.props.store.cfg.mnu_show ?
    <div className="web-side">
        <Red_Mnu store={this.store} />
    </div>
    : null }

    <div className="web-body">
        <Routes>
            <Route path="kp" element={ <Red_Kp store={this.store} />} />
            <Route path="eko/*" element={ <Red_Eko store={this.store} />} />
            <Route path="rps" element={ <Red_Rps store={this.store} />} />
            <Route path="*" element={ <Red_Home store={this.store} />} />
        </Routes>
    </div>

    </div>

    </div>
    <div className="web-foot">
        <div className="web-foot-mnu" onClick={() => this.rvn_open() } title="NY BOKNING">
            <FontAwesomeIcon icon="file-alt" size="1x"/>
        </div>
        <div className="flex flex-row">
        <div className="web-foot-info px-1" onClick={() => this.base_period() } title="Period">
            <div className="txt_12">Bas period</div>
            <div className="flex-row" >
                 <div className="txt_12">{ this.pms.base.fdat }</div>
                 <div className="txt_12 px-1">-</div>
                <div className="txt_12">{ this.pms.base.tdat }</div>
            </div>
        </div>
            <div className="flex"></div>

            <div className="web-foot-btn" onClick={() => this.fknGo("/pms/hotback/eko/oms") } >OMSÄTTNING</div>
        </div>
        <Dd_Sel items={ this.btmitems } getValue={(e)=> this.sel_btm(e)} />
        <div className="flex flex-row">
            <div className="web-foot-btn" onClick={() => this.fknGo("/pms/hotback/rps") }>RAPPORTER</div>

            <div className="flex"></div>
        </div>
        <div className="web-foot-rmnu hide" >
            <Dd_Sel items={ this.langitems } getValue={(e)=> this.sel_lang(e)} />
        </div>
        <Tiden />
    </div>
        { this.state.wnds.baseconf ? <Base_Conf wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }

        </div>
        </div>
        </div>
  );
}
}
//Sys.contextType = ADOContext;
export default withRouter(withStore(observer(Bo_Red)));

/*
        <div className="web-foot-rmnu" onClick={() => this.sz_open() }>
            <MDBIcon icon="building" size="1x"/>
        </div>
        <div className="web-foot-mnu" onClick={() => this.fknGo("/pms/fkn/logg") }>
            <MDBIcon icon="undo" size="1x"/>
        </div>

          <Route path="/xx" component={pg_s1} />
          <Route path="/pms/yy" component={pg_s2} />

            <div className="web-foot-btn" onClick={() => this.rvn_open() }>BOKA</div>
            <div className="web-foot-btn" onClick={() => this.fknGo("/pms/fkn/logg") } >
                Logg
            </div>
 */