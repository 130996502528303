import React, { useState, useEffect, useContext } from 'react';
import * as net from "../../lib/net";
import Fr_Input from "../../lib/ui/fr_input";
import Dd_Sel from "../../lib/ui/dd_sel";

import Rv_Info from "./rv_info";
import Rv_Gsts from "./rv_gsts";
import Rv_Br from "./rv_br";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Pms_Rvo extends React.Component {
  constructor(props, context) {
    super(props);

    this.selitems = [
        {fkn: 'bevaka', text: 'BEVAKA'},
        {fkn: 'bokbild', text: 'BOKNINGSBILD'},
        {fkn: 'confirm', text: 'BEKRÄFTA'},
        {fkn: 'cancel', text: 'AVBOKA'}
    ];

    this.store = props.store;
    this.state = {};
    this.state.ynq = [];
    //this.state.vga = [];
    this.state.rta = [];
    this.state.bra = [];
    //this.state.lro = props.lro;
    this.state.paa = [];
    this.state.aa = [];
    this.state.vga = [
                {v0katnr: '001', v0text: 'VGRP 001'},
                {v0katnr: '002', v0text: 'VGRP 002'},
                {v0katnr: '009', v0text: 'VGRP 009'}
            ];
    this.state.bgo = {};
    this.state.cols = {};
    this.state.rvinfo = false;
    this.state.rvgsts = false;
    this.state.rvbr = false;
    //this.state.pbo = {};
    //this.state.pbo = props.pbo;
    this.state.ix = 0;
    this.state.boknr = "";
    if(props.boknr) this.state.boknr = props.boknr;

    this.state.rsv = {};
    this.state.rsv.resv00 = {};
    this.state.rsv.resv01 = {};
    this.state.rsv.resv02 = {};
    this.state.rsv.resv03 = {};
    this.state.rsv.resv04 = {};
    this.state.rsv.anm = {};

    this.state.resm = {};
    this.state.resm.resm00 = {};
    this.state.resm.resm01 = {};
    this.state.resm.resm02 = {};
    this.state.resm.resm03 = {};

    this.state.rvo = {};
    this.state.rvo.nrr = "1";
    this.state.rvo.sum = "2000";
    this.state.rvo.rvo = {};

  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      if(this.props.boknr) this.rv_init();
      else this.rv_create();
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }

    formchange (e) {
      const name = e.target.name;
      const value = e.target.value;
      var rvo = this.state.rvo;
      var na = name.split(".");
      rvo[na[0]][na[1]] = value;
      this.setState({rvo: rvo});
      //setState({[name]: value});
    }
    rsv_anm_change (e) {
      const name = e.target.name;
      const value = e.target.value;
      var rsv = this.state.rsv;
      var na = name.split(".");
      rsv[na[0]][na[1]] = value;
      this.setState({rsv: rsv});
      //setState({[name]: value});
    }

    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    wnd_close = () => {
      if(this.props.close) {
          this.props.close(this.props.ctx);
      }
      else this.props.ctx.setState({ rvn: false, rvo: false });
	};
    drag(event) {
        //event.dataTransfer.setData("text", event.target.id);
	};
    drop(event) {
        event.preventDefault();
        alert("Drop");
	};
    dragEnter(event) {
        event.preventDefault();
        event.target.style.color = "#fff";
        event.target.style.background = "#ccc";
        //alert("Allow Drop");
	};
    dragLeave(event) {
        event.preventDefault();
        event.target.style.color = "#fff";
        event.target.style.background = "#080";
        //alert("Allow Drop");
	};
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  cssCols (cid) {
      var css = {};
      if(this.state.cols[cid]) css.width = this.state.cols[cid].toString() + "px";
      //if(cid == "c01") alert(JSON.stringify(this.state.cols[cid]));
      return css;
  }
  syncCols (tb) {

    if (!document.getElementById(tb)) return;

    var cols = {};
    var ix = document.getElementById(tb).rows.length - 1;
    var ic = document.getElementById(tb).rows[0].cells.length;
    var ci = 0;
    while(ci < ic) {
        var cxi = ci + 1;
        var col = "c" + cxi.toString().padStart(2, "0");
        cols[col] = document.getElementById(tb).rows[ix].cells[ci].clientWidth + 1;
        ci = ci + 1;
    }
    this.setState({cols: cols });
    //alert(JSON.stringify(this.state.cols));
    //this.forceUpdate();
  };
  mnu_toggle() {
    //props.ctx.setState({ mnu_show: !props.ctx.state.mnu_show });
    //props.store.cfg.mnu_show = !props.store.cfg.mnu_show;
  };
  show_info() {
      this.setState({rvinfo: true});
  };
  show_gsts() {
      this.setState({rvgsts: true});
  };
  show_br() {
      this.setState({rvbr: true});
  };
    val_save (name, value) {
        //alert(JSON.stringify(name));
        //alert(JSON.stringify(value));
      var rvo = this.state.rvo;
      var na = name.split(".");
      rvo[na[0]][na[1]] = value;
      this.setState({rvo: rvo});
      //setState({[name]: value});
    }
    rsv_save (name, value) {
        //alert(JSON.stringify(name));
        //alert(JSON.stringify(value));
      var rsv = this.state.rsv;
      var na = name.split(".");
      rsv[na[0]][na[1]] = value;
      this.setState({rsv: rsv});
      //setState({[name]: value});
    }
    resm_save (name, value) {
        //alert(JSON.stringify(name));
        //alert(JSON.stringify(value));
      var resm = this.state.resm;
      var na = name.split(".");
      resm[na[0]][na[1]] = value;
      this.setState({resm: resm});
      //setState({[name]: value});
    }
  rv_close() {

  };

  rv_save() {
    alert(JSON.stringify(this.state.rsv));
  };
  sz() {
    alert(JSON.stringify("X123"));
  };
  sel_fkn(sel) {
    //alert(JSON.stringify(fkn));
      if(sel.fkn == "logout") {
      }
  }
  brs() {
    var self = this;

    var prm = {};
    prm.req = "pos.pos_plu.plu_bufsave";
    prm.fkn = "721G";
    prm.boknr = this.state.boknr;
    //alert(JSON.stringify(prm));

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            return;
        }
        self.setState({bra: ret.rca });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
    return;
  }
  gsts() {
    var self = this;

    var prm = {};
    prm.req = "pos.pos_plu.plu_bufsave";
    prm.fkn = "7221";
    prm.boknr = this.state.boknr;
    //alert(JSON.stringify(prm));

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            return;
        }
        self.setState({gsa: ret.rca });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
    return;
  }
  rv_store() {
    var self = this;

    var prm = {};
    prm.req = "pos.pos_plu.plu_bufsave";
    prm.fkn = "720W";
    prm.boknr = this.state.boknr;
    prm.rsv = this.state.rsv;
    //alert(JSON.stringify(prm));

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            //return;
        }
        var prm = {};
        prm.req = "pos.pos_plu.plu_bufsave";
        prm.fkn = "720U";
        prm.boknr = this.state.boknr;
        prm.resm = this.state.resm;
        return net.sio_req(prm);
    })
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            //return;
        }
        var rvo = {};
        rvo.boknr = this.state.boknr;
        rvo.anm = this.state.rsv.anm;
        var prm = {};
        prm.req = "pos.pos_plu.plu_bufsave";
        prm.fkn = "72P4";
        prm.boknr = this.state.boknr;
        prm.rvo = rvo;
        return net.sio_req(prm);
    })
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            return;
        }
        //toastr.success("Reservation sparad : " + $scope.boknr);
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
    return;
  }
  rv_cancel() {
    var self = this;

    var prm = {};
    prm.req = "pos.pos_plu.plu_bufsave";
    prm.fkn = "7217";
    prm.boknr = this.state.boknr;
    prm.rvo = this.state.rvo;
    //alert(JSON.stringify(prm));

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            return;
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
    return;
  }
  rv_cancel_prel() {
    var self = this;

    var prm = {};
    prm.req = "pos.pos_plu.plu_bufsave";
    prm.fkn = "7019";
    prm.boknr = this.state.boknr;
    //alert(JSON.stringify(prm));

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            return;
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
    return;
  }
  rv_reserve() {
    var self = this;

    var prm = {};
    //prm.req = "pos.pos_plu.plu_bufsave";
    prm.fkn = "7209";
    prm.boknr = this.state.boknr;
    //alert(JSON.stringify(prm));

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            return;
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
    return;
  }
  rv_create() {
    var self = this;

    var prm = {};
    //prm.req = "pos.pos_plu.plu_bufsave";
    prm.fkn = "7201";
    prm.boknr = "";
    //alert(JSON.stringify(prm));

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            return;
        }
        self.setState({boknr: ret.rco.boknr, rvo: ret.rco.rvo });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
    return;
  }
  rv_init() {
    var self = this;

    var prm = {};
    //prm.req = "pos.pos_plu.plu_bufsave";
    prm.fkn = "720R";
    prm.boknr = self.state.boknr;
    //alert(JSON.stringify(prm));

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            //return;
        }
        var rsv = ret.rco;
        rsv.anm = {};
        self.setState({rsv: rsv });

        var prm = {};
        //prm.req = "pos.pos_plu.plu_bufsave";
        prm.fkn = "720M";
        prm.boknr = self.state.boknr;
        return net.sio_req(prm);
    })
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            //return;
        }
        self.setState({resm: ret.rco });

        var prm = {};
        //prm.req = "pos.pos_plu.plu_bufsave";
        prm.fkn = "72PA";
        prm.boknr = self.state.boknr;
        return net.sio_req(prm);
    })
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok == "000") {
            var rsv = self.state.rsv;
            rsv.anm = ret.rco;
            rsv.anm.rec = rsv.anm.rec.toswe();
            rsv.anm.stad = rsv.anm.stad.toswe();
            rsv.anm.rest = rsv.anm.rest.toswe();
            self.setState({rsv: rsv });
        }

        var prm = {};
        //prm.req = "pos.pos_plu.plu_bufsave";
        prm.fkn = "72QP";
        prm.boknr = self.state.boknr;
        return net.sio_req(prm);
    })
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            //return;
        }
        var rvo = self.state.rvo;
        rvo.rate = ret.rco.belopp;
        rvo.xsum = "0";
        rvo.sum = ret.rco.belopp;
        rvo.pts = ret.rco.pta.length;
        self.setState({rvo: rvo, pta: ret.rco.pta });

        var prm = {};
        //prm.req = "pos.pos_plu.plu_bufsave";
        prm.fkn = "721G";
        prm.boknr = self.state.boknr;
        return net.sio_req(prm);
    })
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            //return;
        }
        var bra = [];
        if(ret.rca) bra = ret.rca;
    	var bro = {};
        if(bra.length) {
            for(var row of bra) {
                var rblx0 = row.rblx0;
                if(!bro[rblx0.r0lopnr]) bro[rblx0.r0lopnr] = {};
                bro[rblx0.r0lopnr] = rblx0;
            }
        }
        self.setState({bra: bra, bro: bro });

        var prm = {};
        //prm.req = "pos.pos_plu.plu_bufsave";
        prm.fkn = "7221";
        prm.boknr = self.state.boknr;
        return net.sio_req(prm);
    })
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            return;
        }
        var gsa = [];
        if(ret.rco) gsa = ret.rco;
        var nrr = 0;
        var gro = {};
        if(gsa.length) {
            for(var gsb00 of gsa) {
                if(!gro[gsb00.r0blopnr]) gro[gsb00.r0blopnr] = {};
                gro[gsb00.r0blopnr][gsb00.r0glopnr] = gsb00;
                if(gsb00.r0status != "C") nrr = nrr + 1;
            }
        }
        self.setState({gsa: gsa, gro: gro });
        //if(rvo.kid) self.rv_read_kid(rvo.kid);
        self.forceUpdate();
        //alert(JSON.stringify("Reservation hämtad : " + self.state.boknr));
        //toastr.success("Reservation hämtad : " + $scope.boknr);
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
    return;
  }

  render() {
    //if(!this.props.show) return "";

    //alert(this.props.ctx.state.lro);

    var title = this.props.title;
    if(!title) title = "Bokning";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="mx_backdrop" /> : null }
        <div className="mx_dlg mx_95 flex-col" style={ this.dlg_style() }>
            <div className="flex-row" style={{backgroundColor: "#080", color: "#fff", fontSize: "18px", paddingLeft: "5px"}}>
                <div className="f40 flex-row">
                <div className="web-top-icon px-3 w60" onClick={() => this.mnu_toggle() }>
                    <FontAwesomeIcon icon="bars" size="1x"/>
                </div>
                <div className="flex-column">
                    <div style={{fontSize: "12px", margin: "0px", padding: "0px", lineHeight: "20px"}}>
                        Bokning
                    </div>
                    <div style={{fontSize: "18px", margin: "0px", padding: "0px", lineHeight: "20px"}}>
                        { this.state.boknr }
                    </div>
                </div>
                </div>

                <div className="flex flex-row flex-space-between">
                 <div className="flex-column">
                     <div style={{fontSize: "12px", margin: "0px", padding: "0px", lineHeight: "20px"}}>Antal Rum</div>
                     <div className="text-center" style={{fontSize: "18px", margin: "0px", padding: "0px", lineHeight: "20px"}}>{ this.state.rvo.nrr }</div>
                 </div>
                 <div className="flex-column">
                     <div style={{fontSize: "12px", margin: "0px", padding: "0px", lineHeight: "20px"}}>Summa Rum</div>
                     <div style={{fontSize: "18px", margin: "0px", padding: "0px", lineHeight: "20px"}}>{ this.state.rvo.rate }</div>
                 </div>
                 <div className="flex-column">
                     <div style={{fontSize: "12px", margin: "0px", padding: "0px", lineHeight: "20px"}}>Tillägg</div>
                     <div style={{fontSize: "18px", margin: "0px", padding: "0px", lineHeight: "20px"}}>{ this.state.rvo.xsum }</div>
                 </div>
                 <div className="flex-column">
                     <div style={{fontSize: "12px", margin: "0px", padding: "0px", lineHeight: "20px"}}>Summa Vistelse</div>
                     <div className="text-right" style={{fontSize: "18px", margin: "0px", padding: "0px", lineHeight: "20px"}}>{ this.state.rvo.sum }</div>
                 </div>
                </div>
             </div>
        <div className="mx_content flex">

            <div className="flex-row hide" style={{padding:"5px",height:"35px"}}>
                    <div className="flex-col flex">
                        <div>NY BOKNING</div>
                    </div>
                    <div className="flex-col">
                        <div>UPPDATERA</div>
                    </div>
            </div>
            <div className="divider_orange hide"></div>


            <div className="flex-row" style={{fontSize: "18px", margin: "0px", height: "30px", lineHeight: "30px"}}>
                <div style={{width:"125px",fontSize: "16px",background: "#fff", color: "#080", textAlign:"center",borderRight: "1px dashed #080"}}>
                    Översikt
                </div>
                <div onClick={() => this.show_info() } style={{cursor:"pointer",width:"125px",fontSize: "16px",background: "#fff", color: "#800", textAlign:"center",borderRight: "1px dashed #080"}}>
                    Bokningsinfo
                </div>
                <div onClick={() => this.show_gsts() } style={{cursor:"pointer",width:"125px",fontSize: "16px",background: "#fff", color: "#800", textAlign:"center",borderRight: "1px dashed #080"}}>
                    Gäster
                </div>
                <div style={{width:"125px",fontSize: "16px",background: "#fff", color: "#800", textAlign:"center",borderRight: "1px dashed #080"}}>
                    Gäster
                </div>
                <div className="flex"></div>
                <div onClick={() => this.show_br() } style={{cursor:"pointer",width:"125px",fontSize: "16px",background: "#fff", color: "#800", textAlign:"center",borderRight: "1px dashed #080"}}>
                    Lägg till rum
                </div>
                <div onClick={() => this.show_gsts() } style={{cursor:"pointer",width:"125px",fontSize: "16px",background: "#fff", color: "#800", textAlign:"center",borderRight: "1px dashed #080"}}>
                    Lägg till gäst
                </div>
                <div className="text-center w50" onClick={() => this.mnu_toggle() }>
                    <FontAwesomeIcon icon="ellipsis-v" size="1x"/>
                </div>
            </div>

            <div className="divider_green"></div>
            <div className="flex flex-row" style={{border: "0px", margin: "0px", padding: "0px"}}>
                <table className="table" style={{border: "0px", margin: "0px", padding: "0px"}}>
                    <thead style={{fontSize: "12px", fontWeight: "normal", color: "#080", border: "0px", margin: "0px", padding: "0px"}}>
                    <tr>
                        <th style={{textAlign: "left"}}>Från - Till</th>
                        <th style={{textAlign: "left"}}>Rumsnr</th>
                        <th style={{textAlign: "left"}}>Rumstyp</th>
                        <th style={{textAlign: "left"}}>Rum</th>
                        <th style={{textAlign: "left"}}>Gäster</th>
                        <th style={{textAlign: "left"}}>Belopp</th>
                        <th style={{textAlign: "left"}}>Status</th>
                        <th style={{textAlign: "center", color: "#800"}}>Lägg till rum</th>
                    </tr>
                    </thead>
                    <tbody style={{fontSize: "16px", color: "#000", border: "0px", margin: "0px", padding: "0px", borderBottom: "1px solid #ccc"}}>
                    { this.state.bra.map((row, key) =>
                    <tr>
                        <td>{ row.rblx0.r0ankdatum } - { row.rblx0.r0ankdatum }</td>
                        <td>{ row.rblx0.r0typ }</td>
                        <td>{ row.rblx0.r0typ }</td>
                        <td>{ row.rblx0.r0borr }/{ row.regr }</td>
                        <td>{ row.rblx0.r0borg }/{ row.regg }</td>
                        <td>{ row.belopp }</td>
                        <td>{ row.rblx0.r0status }</td>
                        <td></td>
                    </tr>
                    )}
                    </tbody>
                </table>
             </div>

            <div className="divider_green"></div>
            <div className="flex-row">
                <div className="wnd_link">
                    RENSA
                </div>
                <div className="flex"></div>
                <div className="wnd_link">
                    BOKNINGSDATA
                </div>
            </div>

            <form className="cdi_f03">
            <div className="flex-row" style={{border: "0px", margin: "0px", padding: "0px"}}>
                <div className="form-row flex-row">
                <Fr_Input label="Namn" sz={this.sz} className="text-left w250" text={this.state.rsv.resv01.Name} getValue={(e)=> this.rsv_save("resv01.Name", e) } />
                <Fr_Input label="Företag" sz={this.sz} className="text-left w250" text={this.state.rsv.resv00.Company} getValue={(e)=> this.rsv_save("resv00.Company", e) } />
                <Fr_Input label="Org Nr" className="text-left w250" text={this.state.rsv.resv04.Orgnr} getValue={(e)=> this.rsv_save("resv04.Orgnr", e) } />
                </div>
                <div className="form-row flex-row">
                <Fr_Input label="Telefon" className="text-left w250" text={this.state.rsv.resv01.Tfn} getValue={(e)=> this.rsv_save("resv01.Tfn", e) } />
                <Fr_Input label="Mobil" className="text-left w250" text={this.state.rsv.resv01.Mobil} getValue={(e)=> this.rsv_save("resv01.Mobil", e) } />
                <Fr_Input label="Epost" className="text-left w250" text={this.state.rsv.resv02.Email} getValue={(e)=> this.rsv_save("resv02.Email", e) } />
                </div>
                <div className="form-row flex-row">
                <Fr_Input label="Betalsätt" className="text-left w250" text={this.state.rsv.resv02.bettyp} getValue={(e)=> this.rsv_save("resv02.bettyp", e) } />
                <Fr_Input label="Kortnr" className="text-left w250" text={this.state.resm.resm03.ccnr} getValue={(e)=> this.resm_save("resm03.ccnr", e) } />
                <Fr_Input label="Ankomsttid" className="text-left w250" text={this.state.rsv.resv02.anktid} getValue={(e)=> this.rsv_save("resv02.anktid", e) } />
                </div>
            </div>

            <div className="flex-row pb-3">
                <div className="flex flex-column" style={{padding: "0px 5px"}}>
                    <span className="header">Anmärkning reception</span>
                    <textarea className="form-control" maxLength="512" rows="5" name="anm.rec" value={this.state.rsv.anm.rec} onChange={(e) => this.rsv_anm_change(e)}></textarea>
                </div>
                <div className="flex flex-column" style={{padding: "0px 5px"}}>
                    <span className="header">Anmärkning städ</span>
                    <textarea className="form-control" maxLength="512" rows="5" name="anm.stad" value={this.state.rsv.anm.stad} onChange={(e) => this.rsv_anm_change(e)}></textarea>
                </div>
            </div>
            </form>

            </div>

            <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250 ripple" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex-row">
            <button className="btn btn-hw-blue w250 ripple" onClick={(event) => this.rv_logg(event) } >
                LOGG
            </button>
            <Dd_Sel items={ this.selitems } getValue={(e)=> this.sel_fkn(e)} />
            <button className="btn btn-hw-blue w250 ripple" onClick={(event) => this.rv_confirm(event) } >
                BEKRÄFTA
            </button>
            </div>
            <button className="btn btn-hw-green w250 ripple" onClick={(event) => this.rv_save(event) } >
                SPARA
            </button>
        </div>
      </div>

        { this.state.rvinfo ? <Rv_Info rsv={this.state.rsv} ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rvgsts ? <Rv_Gsts rsv={this.state.rsv} ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rvbr ? <Rv_Br rsv={this.state.rsv} ctx={this} store={this.props.store} backdrop={true}/> : null }
    </div>
	;
	return html;
  };
}

export default Pms_Rvo;