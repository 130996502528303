import React, { useState, useEffect, useContext } from 'react';

import * as utils from "../../assets/utils";
import * as net from "../../lib/net";
import Lang from "../../lib/lang";

import Td_Sel from "../../lib/ui/td_sel";
import Scr_Tvm from "./fkn/tvm";
import Scr_Add from "./fkn/scr_add";
import Scr_Edit from "./fkn/scr_edit";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Ecom_Web extends React.Component {
  constructor(props, context) {
    super(props);

    //this.lang = {};
    this.store = props.store;
    this.state = { pgid: 11, tra: [] };
    this.state.today = new Date();
    this.state.avd = "01";
    this.state.aa = [];
    this.state.inhsel = 0;
      this.state.msg = {};
      this.state.rvo = {};

    this.state.sca = [];
    this.state.rvo = {};
    this.state.selrow = {};
    this.state.wnds = {};
    this.state.sortid = "";
    this.state.sortdir = 1;

    this.selitems = [
        {fkn: 'open', text: 'ÖPPNA'},
        {fkn: 'cancel', text: 'AVBOKA'}
    ];
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.dsi_screens();
  }

  body_style() {
    var css = {};
    css.height = window.innerHeight - 280;
    return css;
  }
  tb_style() {
    var css = {};
    css.height = window.innerHeight - 250;
    css.overflow = "auto";
    return css;
  }
  th_style(id) {
      var css = {};
      css.cursor = "pointer";
      if(id == this.state.sortid) {
          if(this.state.sortdir) css.color = "#800";
          else css.color = "#080";
      }
      return css;
  }
  row_style(row) {
    var srow = this.state.selrow;

    var css = {};
    if(srow == row) {
        css.background = "#080";
        css.color = "#fff";
    }
    return css;
  }
  onUnitChange(e) {
    this.setState({unit: e.value});
  }
    scr_add() {
        var self = this;

        var wnds = {};
        wnds.scradd = true;
        //wnds.sco = row;
        wnds.cb = self.cb_scr;
        //alert(JSON.stringify(wnds));
        self.setState({ wnds: wnds });
    }
    scr_edit(row) {
        var self = this;

        var wnds = {};
        wnds.scredit = true;
        wnds.sco = row;
        wnds.cb = self.cb_scr;
        //alert(JSON.stringify(wnds));
        self.setState({ wnds: wnds });
    }
    cb_scr(ctx) {
        ctx.setState({ wnds: {} });
    };
    tvm_show() {
        var self = this;
        var wnds = {};
        wnds.tvm = true;
        wnds.tvid = "tv01";
        //wnds.tvid = self.store.cfg.scrid;
        //wnds.cb = self.cb_tbo;
        self.setState({wnds: wnds });
    }

  dsi_screens() {
    var self = this;
    var prm = {};
    prm.req = "dsi.dsi_scr.scr_list";

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        self.setState({ sca: ret.rca });
        //self.setState({ rva: ret.rca });
        self.syncTb("scrTable");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
    syncTb(tb) {
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
        tdElements = tbel.getElementsByTagName('td');
        if(tdElements.length < 1 ) return;

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
            width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }

  sel_row(row) {
    var srow = this.state.selrow;
    if(srow == row) {
      this.setState({selrow: {}});
    }
    else this.setState({selrow: row});
  }
  sel_row_fkn(row, sel) {
      if(sel.fkn == "open") {
          this.sel_gst(row);
      }
  }
   tb_sort(typ) {
      var self = this;
      //var rvs = self.state.rvs;
      var rva = self.state.rva;
      var sortid = this.state.sortid;
      var sortdir = this.state.sortdir;

      if(sortid != typ) sortdir = 0;
      else {
        if(sortdir) sortdir = 0;
        else sortdir = 1;
      }

      if(typ == "ank") {
        if(sortdir) rva.sort((a,b) => (a.gsb00.r0ankdatum < b.gsb00.r0ankdatum) ? 1 : ((b.gsb00.r0ankdatum < a.gsb00.r0ankdatum) ? -1 : 0));
        else rva.sort((a,b) => (a.gsb00.r0ankdatum > b.gsb00.r0ankdatum) ? 1 : ((b.gsb00.r0ankdatum > a.gsb00.r0ankdatum) ? -1 : 0));
      }
      self.setState({ rva: rva, sortid: typ, sortdir: sortdir });
   }
   th_icon(typ) {
      var cls = "";
      var sortid = this.state.sortid;
      var sortdir = this.state.sortdir;
      if(sortid == typ)
      {
        if(sortdir) cls = "fa fa-caret-up";
        else cls = "fa fa-caret-down";
      }
      return cls;
    }

  render() {
    return (
    <div className="web-bas">

    <div className="flex-row">
        <button className="btn btn-hw-blue w200" onClick={() => this.tvm_show() } aria-label="System" >
            MEDIA
        </button>
        <div className="flex"></div>
        <button className="btn btn-hw-blue w200" onClick={() => this.scr_add() } aria-label="System" >
            LÄGG TILL
        </button>
        <button className="btn btn-hw-blue w200" onClick={() => this.dsi_screens() } aria-label="System" >
            UPPDATERA
        </button>
    </div>
    <div className="divider_green"></div>

    <div className="web-row">

        <div id="scrTable" className="flex">
          <table className="table table-bordered table-hover" style={{marginBottom: "0px"}}>
            <thead>
            <tr>
                <th style={ this.th_style("typ") } onClick={() => this.tb_sort("typ") }><Lang>Typ <i className={ this.th_icon("typ") }></i></Lang></th>
                <th style={ this.th_style("scrid") } onClick={() => this.tb_sort("scrid") }><Lang>Skärm ID</Lang> <i className={ this.th_icon("scrid") }></i></th>
                <th style={ this.th_style("text") } onClick={() => this.tb_sort("text") }><Lang>Text</Lang> <i className={ this.th_icon("text") }></i></th>
                <th style={ this.th_style("scrtyp") } onClick={() => this.tb_sort("scrtyp") }><Lang>Skärmtyp</Lang> <i className={ this.th_icon("scrtyp") }></i></th>
                <th style={ this.th_style("ctid") } onClick={() => this.tb_sort("ctid") }><Lang>Layout</Lang> <i className={ this.th_icon("ctid") }></i></th>
                <th style={ this.th_style("status") } onClick={() => this.tb_sort("status") }><Lang>Status</Lang> <i className={ this.th_icon("status") }></i></th>
                <th></th>
            </tr>
            </thead>
        </table>
        <div style={ this.tb_style() }>
          <table className="table table-bordered table-hover">
            <tbody>
            { this.state.sca.map((row, key) =>
            <tr key={key} style={ this.row_style(row) } onClick={() => this.sel_row(row)} onDoubleClick={ () => this.scr_edit(row) }>
                <td>{ row.typ }</td>
                <td>{ row.scrid }</td>
                <td>{ utils.toLang( row.text ) }</td>
                <td className="text-center">{ row.scrtyp }</td>
                <td className="text-center">{ row.ctid }</td>
                <td>{ row.status }</td>
                <td>
                    <Td_Sel items={ this.selitems } getValue={(e)=> this.sel_row_fkn(row, e)} />
                </td>
            </tr>
            )}
            </tbody>
        </table>
        </div>
        </div>
    </div>

        { this.state.wnds.scradd ? <Scr_Add wnds={this.state.wnds} store={this.store} ctx={this} /> : null}
        { this.state.wnds.scredit ? <Scr_Edit wnds={this.state.wnds} store={this.store} ctx={this} /> : null}
        { this.state.wnds.tvm ? <Scr_Tvm wnds={this.state.wnds} store={this.store} ctx={this} /> : null}
    </div>
  );
    }
}
export default Ecom_Web;
