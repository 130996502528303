import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";
import Td_Sel from "../../lib/ui/td_sel";
import Dd_Sel from "../../lib/ui/dd_sel";
import * as utils from '../../assets/utils.js'

import Preg_Rp from "./rp/preg_rp";
import Preg_New from "./fkn/preg_new";
import Preg_Edit from "./fkn/preg_edit";
import Preg_Trs from "./fkn/pres_trs";
import Preg_Saldo from "./fkn/pres_saldo";
import Preg_Wrk from "./fkn/pres_wrk";
import Preg_Reg from "./fkn/pres_reg";
import { parseISO, addYears, addMonths, format } from 'date-fns';

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Reg_Pres extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = {};
    this.state.seldate = new Date();
    this.state.avd = "01";
    this.state.pra = [];
    this.state.prs = [];
    this.state.sz_val = "";

    this.state.tot = {};
    this.state.kro = {};
    this.state.ta = [
                {id: '01', text: 'PRESENTKORT'},
                {id: '02', text: 'VIPKORT'}
            ];
    this.state.wnds = {};

    this.selitems = [
        {fkn: 'open', text: 'ÖPPNA'}
    ];
      this.fknitems = [
        {fkn: 'preg_reg', text: 'HELA REGISTRET'},
        {fkn: 'preg_inx', text: 'INDEXERA REGISTER'},
        {fkn: 'preg_imp', text: 'IMPORTERA REGISTER'},
          {fkn: 'preg_wrk', text: 'REGISTER KONTROLL'}
      ];
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.presreg();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      css.overflow = "auto";
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

  kund_open(row) {
      var self = this;
  }
  kund_edit(row) {
    var self = this;
    var wnds = {};
    wnds.edit = 1;
    wnds.kro = row;
    this.setState({ wnds: wnds });
  }
    preg_imp() {
        var self = this;
        var prm = {};
        //prm.req = "vip.vip_reg.vip_import";
        prm.req = "crs.crs_reg.reg_import";
        prm.sz = "";
        //prm.avd = this.state.avd;
        //alert(JSON.stringify(prm));

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.rca));
                /*
                self.setState({paa: ret.rca }, function () {
                    self.syncTb("presTable");
                });

                 */
                //toastr.success("OK", "SAPI");
            })
            .catch(function(e) {
                var txt = JSON.stringify(e);
            });
    }

    presreg() {
    var self = this;
    var prm = {};
    prm.req = "crs.crs_reg.pres_act";
    prm.sz = "";
    //prm.avd = this.state.avd;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.tot));

        self.setState({pra: ret.rca, prs: ret.rca, tot: ret.tot }, function () {
            self.syncTb("presTable");
        });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e) {
        var txt = JSON.stringify(e);
    });
  }
    syncTb(tb){
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
        tdElements = tbel.getElementsByTagName('td');

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
            width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
  sel_row_fkn(sel, e) {
    //alert(JSON.stringify(fkn));
      if(sel.fkn == "logout") {
      }
  }
  lists() {
  }
    sel_fkn(sel) {
        //alert(JSON.stringify(sel));
        if(sel.fkn == "preg_imp") {
            this.preg_imp();
        }
        if(sel.fkn == "preg_reg") {
            this.preg_reg();
        }
        if(sel.fkn == "preg_wrk") {
            this.preg_wrk();
        }
    }

    preg_reg() {
        var wnds = {};
        wnds.pregreg = 1;
        wnds.pro = {};
        this.setState({ wnds: wnds });
    }
    preg_wrk() {
        var wnds = {};
        wnds.pregwrk = 1;
        wnds.pro = {};
        this.setState({ wnds: wnds });
    }
    preg_saldo() {
        var wnds = {};
        wnds.pregsaldo = 1;
        wnds.pro = {};
        this.setState({ wnds: wnds });
    }
    preg_rp() {
        var wnds = {};
        wnds.pregrp = 1;
        wnds.pro = {};
        this.setState({ wnds: wnds });
    }
    preg_new() {
        var wnds = {};
        wnds.pregnew = 1;
        wnds.pro = {};
        this.setState({ wnds: wnds });
    }
    preg_edit(row) {
        var wnds = {};
        wnds.pregedit = 1;
        wnds.pro = row;
        wnds.cb = this.cb_wnd;
        this.setState({ wnds: wnds });
    }
    cb_wnd(ctx, ret) {
        if(!ret.ok || (ret.ok !== "000")) {
          ctx.setState({ wnds: {} });
          ctx.presreg();
          return;
          }
      
          ctx.setState({ wnds: {} });
          ctx.presreg();
      }
    preg_trs() {
        var wnds = {};
        wnds.pregtrs = 1;
        wnds.pro = {};
        this.setState({ wnds: wnds });
    }
    tb_sort(typ) {
        var self = this;
        //var rvs = self.state.rvs;
        var pra = self.state.pra;
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;

        if(sortid != typ) sortdir = 0;
        else {
            if(sortdir) sortdir = 0;
            else sortdir = 1;
        }
        var fa = typ.split(".");
        if(true) {
            if(sortdir) pra.sort((a,b) => (a[fa[0]][fa[1]] < b[fa[0]][fa[1]]) ? 1 : ((b[fa[0]][fa[1]] < a[fa[0]][fa[1]]) ? -1 : 0));
            else pra.sort((a,b) => (a[fa[0]][fa[1]] > b[fa[0]][fa[1]]) ? 1 : ((b[fa[0]][fa[1]] > a[fa[0]][fa[1]]) ? -1 : 0));
        }
        self.setState({ pra: pra, sortid: typ, sortdir: sortdir });
    }

    preg_expire(row) {
        var dts = "";
        // utils.toDate(row.pres00.p0expire)
        //var dat = this.state.today;
        if(!row.pres00.p0datum) return dts;
        if(row.pres00.p0datum.trim() < 1) return dts;
        
        try {
            var datum = utils.toDate(row.pres00.p0datum);
            if(!datum || (datum.trim().length > 1)) {
                var dat = parseISO(datum);
                dat = addYears(dat, 1);
                dat = addMonths(dat, 1);
                dts = format(dat, "yyyy-MM-dd");
            }
        }
        catch(e) {
            dts = "";
        }
        return dts;
    }
    sz_change (e) {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({sz_val: value});
        //setState({[name]: value});
    }
    sz_keydown (e) {
      //e.preventDefault();
        const key = e.key;
        const keyCode = e.keyCode;
  
        if(keyCode == 13) {
            this.tb_filtr();
        };
        if(keyCode == 27) {
            this.setState({sz_val: ""});
        };
    }
  tb_filtr() {
      var self = this;
      var prs = self.state.prs;
      var sz = this.state.sz_val;
  
      if(sz.length < 1) {
        self.setState({ pra: prs });
          return;
      }
      sz = sz.toUpperCase();
      var pra = prs.filter(function(row) {
            var brc = false;
            if(row.id.toUpperCase().includes(sz)) brc = true;
            if(row.text.toUpperCase().includes(sz)) brc = true;
            if(row.pres00.p0belopp.toUpperCase().includes(sz)) brc = true;
            if(row.pres01.p1anm.toUpperCase().includes(sz)) brc = true;
            return brc;
      });
      self.setState({pra: pra });
   }
   
  render() {
    return (
    <div className="web-app">

    <div className="flex-row">
        <select className="form-control sel_input" placeholder="Välj typ">
            { this.state.ta.map((row, key) =>
            <option key={key} value={row.id}>{row.text}</option>
            )}
        </select>
        <input className="txt sztxt" value={this.state.sz_val} placeholder="Ange söktext..." onChange={(e) => this.sz_change(e)} onKeyDown={(e) => this.sz_keydown(e)} />
  
        <div className="flex"></div>
        <button className="btn btn-hw-blue w150" onClick={() => this.preg_saldo() } aria-label="System" >
            SALDO
        </button>
        <button className="btn btn-hw-green w150" onClick={() => this.preg_new() } aria-label="System" >
            LÄGG TILL
        </button>
        <button className="btn btn-hw-green w150" onClick={() => this.preg_trs() } aria-label="System" >
            TRANSAKTIONER
        </button>
        <button className="btn btn-hw-blue w150" onClick={() => this.presreg() } aria-label="System" >
            UPPDATERA
        </button>
        <Dd_Sel items={ this.fknitems } cls="web-top-icon" getValue={(e)=> this.sel_fkn(e)} />
    </div>
    <div className="divider_orange"></div>

    <div className="web-col">
        <div id="presTable">
        <table className="table table-bordered table-hover tb " style={{marginBottom: "0px"}}>
            <thead>
            <tr>
                <th onClick={() => this.tb_sort("pres00.p0id") }>Pres Nr</th>
                <th onClick={() => this.tb_sort("pres00.p0text") }>Text</th>
                <th onClick={() => this.tb_sort("pres00.p0datum") }>Datum</th>
                <th onClick={() => this.tb_sort("pres00.p0expire") }>Giltigt</th>
                <th onClick={() => this.tb_sort("pres00.p0belopp") }>Belopp</th>
                <th onClick={() => this.tb_sort("pres00.p0saldo") }>Att utnyttja</th>
                <th onClick={() => this.tb_sort("pres01.p1issuedby") }>Utställt</th>
                <th onClick={() => this.tb_sort("pres01.p1anm") }>Information</th>
                <th></th>
            </tr>
            </thead>
            </table>
            <div style={ this.tb_style() }>
          <table className="table table-bordered table-hover">
            <tbody>
            { this.state.pra.map((row, key) =>
            <tr key={key} onDoubleClick={() => this.preg_edit(row) }>
                <td className="text-center">{ utils.toNum(row.pres00.p0id) }</td>
                <td>{ row.pres00.p0text }</td>
                <td>{ utils.toDate(row.pres00.p0datum) }</td>
                <td>{ this.preg_expire(row) }</td>
                <td>{ utils.toPris(row.pres00.p0belopp) }</td>
                <td>{ utils.toPris(row.pres00.p0saldo) }</td>
                <td>{ row.pres01.p1issuedby }</td>
                <td>{ row.pres01.p1anm }</td>
                <td>
                    <Td_Sel items={ this.selitems } getValue={(e)=> this.sel_row_fkn(row, e)} />
                </td>
            </tr>
            )}
            </tbody>
        </table>
        </div>
        <div className="divider_green"></div>
           <div className="flex-row px-5">
               <div className="flex">TOTALER:</div>
               <div className="px-5">INGÅENDE SUMMA: { utils.toPris(this.state.tot.belopp) }</div>
               <div className="px-5">AKTUELLT SALDO: { utils.toPris(this.state.tot.saldo) }</div>
           </div>

        </div>
    </div>
    { this.state.wnds.pregsaldo ? <Preg_Saldo wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
    { this.state.wnds.pregreg ? <Preg_Reg wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
    { this.state.wnds.pregwrk ? <Preg_Wrk wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
    { this.state.wnds.pregrp ? <Preg_Rp wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
    { this.state.wnds.pregnew ? <Preg_New wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
    { this.state.wnds.pregedit ? <Preg_Edit wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
    { this.state.wnds.pregtrs ? <Preg_Trs wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
   </div>
  );
    }
}
export default Reg_Pres;

/*
<td>{ utils.toDate(row.pres00.p0expire) }</td>
*/