import React, { useState, useEffect, useContext } from 'react';
import ReactDOM from 'react-dom';
import { SDOContext } from '../sdo.js'

//import './css/style-liberty.css';
import Tiden from "../lib/ui/tiden";

import * as net from "../lib/net";
import Pos_Top from "../pos/com/pos_top";
import {withRouter} from "../lib/react/withRouter";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Dd_Sel from "../lib/ui/dd_sel";

function web01() {

    var html =
    <div className="">
        Test
    </div>
    ;
    return html;
}

class Sesout extends React.Component {
    //var sdo = useContext(SDOContext); // Functional
    static contextType = SDOContext; // Class

    constructor(props, context) {
        super(props);

        this.s1ref = React.createRef();
        this.s2ref = React.createRef();

        this.store = props.store;
        this.seo = props.store.seo;

        this.state = { pgid: 11, stat: [] };
        this.state.open = false;
        this.state.pdfopen = false;
        this.state.xxopen = false;
        this.state.pdf_uri = "";
        this.state.seldate = new Date('2020-03-24T13:11:54');
        this.state.row = null;
        this.state.info = "";
        this.state.top = 1;
        this.state.user = {};
        this.state.sdo = {};

        this.state.notify = false;
        this.state.step = 1;
        this.state.pdf = 0;
        this.state.pdf_uri = "";
        this.state.mnus = ["m00", "m01", "m02", "m03", "m08", "m09"];
        this.state.mnu_sel = "m00";
        this.state.useritems = [
            {fkn: 'user_setup', text: 'INSTÄLLNINGAR'},
            {fkn: 'user_ses', text: 'MIN SESSION'},
            {fkn: 'logout', text: 'LOGGA UT'}
        ];

    }
    componentDidMount(){
        //var sdo = sessionStorage.getItem("svp");
        //if(sdo) this.setState({sdo: JSON.parse(sdo) });
        //this.cdi_step();
        //alert(JSON.stringify("HELLO"));
        //this.forceUpdate();
        //this.verify();
        //var self = this;
        //setTimeout(function(){ self.slogout(self) }, 3000);
/*
        var self = this;
        setTimeout(function(){
            var url = "/";
            self.props.history.push(url);
            self.props.history.go(0);
        }, 3000);
*/
    }

    componentWillUnmount(){
        //this.setState({info: "UUU" });
    }
    logo(){
        var logo = null;
        if(this.store.cfg.logo == "cdi") logo = require('../img/logo-cdi.png');
        else logo = require('../img/logo-sk.png');
        return logo;
    }
   header_css() {
  	    var css = {};
		if(this.state.top) {
			css.color = "#fff";
			css.background = "transparent";
			css.height = "50px";
			//css["background"] = "green";
		}
		else {
			css.color = "#000";
			css.background = "#fff";
		    css.height = "50px";
        }

		return css;
    }
    header_class() {
  	    var cls = "flex_col";
		if(this.state.top) {
			cls = cls + " web-head_1";
		}
		else cls = cls +  " web-header";

		return cls;
    }

    row_css(row) {
  	    var css = {};
		if(this.state.row) {
		if(this.state.row.ank == row.ank) {
			css.background = "#080";
			css.color = "#fff";
		}
		}
		return css;
    }
    login() {
        this.setState({login: true });
        //this.props.history.push("pos");
    }
    slogout(self) {
        var xdo = sessionStorage.getItem("svp");
		var sdo = JSON.parse(xdo);

		var prm = {};
        prm.req = "usr:logout";
		prm.uid = sdo.uid;
		prm.token = sdo.token;

		//alert(JSON.stringify(prm));
        net.gcs_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret));

            if(ret.ok != "000") {
                alert(JSON.stringify(ret));
                //return;
            }
            sessionStorage.removeItem("svp");
            self.props.store.seo = {};

            var url = "/";
            self.props.navigate(url);
            //self.props.history.go(0);
        })
        .catch(function(e){
            //var txt = JSON.stringify(e);
            var url = "/";
            self.props.navigate(url);
            //alert("LOGOUT ERR: " + JSON.stringify(e));
            //gda.wndError(txt);
        });
      };
    logout() {
        var self = this;
            var url = "/";
            self.props.navigate(url);
            //self.props.history.go(0);
     };
    xlogout() {
        var self = this;
        var xdo = sessionStorage.getItem("svp");
		var sdo = JSON.parse(xdo);

		var prm = {};
        prm.req = "usr:logout";
		prm.uid = sdo.uid;
		prm.token = sdo.token;

		//alert(JSON.stringify(prm));
        net.gcs_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret));

            if(ret.ok != "000") {
                alert(JSON.stringify(ret));
                //return;
            }
            sessionStorage.removeItem("svp");
            self.props.store.seo = {};

        })
        .catch(function(e){
            //var txt = JSON.stringify(e);
            var url = "/";
            self.props.navigate(url);
            //alert("LOGOUT ERR: " + JSON.stringify(e));
            //gda.wndError(txt);
        });
      };
    notifyShow() {
        //toast.info('<MDBIcon far icon="user" size="1x"/> Info message');
        //toast.success('Info message', {
        //    closeButton: false
        //  });
        //alert(this.state.notify);
        //this.setState({notify: true });
        //this.props.history.push("pos");
    }
    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    handleLogin(event) {
        //this.setState({value: event.target.value});
        //const body = new FormData(this.form);
        //alert('FORM: ' + JSON.stringify(state));
        //props.ctx.dologin(state.user.uid, state.user.pwd);
        this.login_srv(this.state.user.uid, this.state.user.pwd);
    }
	handleClose = () => {
		//this.props.ctx.setState({ login: false });
	};
    sysStart() {
        var url = "/pos";
        if(this.seo.auto) url = "/" + this.seo.auto;
        this.props.navigate(url);
   };
    verify() {
        var self = this;
        var xdo = sessionStorage.getItem("svp");
		var sdo = JSON.parse(xdo);

		var prm = {};
        prm.req = "usr:verify";
		prm.uid = sdo.uid;
		prm.token = sdo.token;

		//alert(JSON.stringify(prm));
        net.gcs_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret));

            if(ret.ok != "000") {
                //alert(JSON.stringify(ret));
                sessionStorage.removeItem("svp");
                self.props.store.seo = {};

                var url = "/";
                self.props.navigate(url);
                //self.props.history.go(0);
                return;
            }

        })
        .catch(function(e){
            //var txt = JSON.stringify(e);
            var url = "/";
            self.props.navigate(url);
            //self.props.history.go(0);
        });

      };

    render() {
        var stat = [];
        var keys = Object.keys(this.state.stat);
        for (var key of keys) {
            var row = this.state.stat[key];
            stat.push(row);
        }
        const handleDateChange = date => {
            this.setState({seldate: date });
        };

        return (
    <div className="web-app">
    <div className="web-top">

            <div id="header" className={ this.header_class() }>
            <div className="web-top-content d-flex flex-row">
                <div className="web-top-icon px-3 w60" onClick={() => this.sysStart() }>
                    <FontAwesomeIcon icon="bars" size="1x"/>
                </div>
                <div className="web-top-txt txt_18">Session</div>
                <div className="flex-fill"></div>
                <div className="web-top-usr d-flex flex-column">
                    <div><FontAwesomeIcon icon="user" size="1x"/> { this.seo.name }</div>
                    <div><FontAwesomeIcon icon="envelope" size="1x"/> { this.seo.mbox }</div>
                </div>
                <div className="">
                    <Dd_Sel items={ this.state.useritems } cls="web-top-icon" getValue={(e)=> this.sel_fkn(e)} />
                </div>
            </div>
            </div>

    </div>

    <div className="web-main">
    <div className="web-body">
    <div className="web-center">

    <div className="web-box">
          <div className="text-center">
            <div className="flex-row">
              <div className="flex-col">
           <div className="web-logo">
                <img className="svk-logo" src={ this.logo() } />
            </div>
              </div>
            </div>
            <h1 className="h1-responsive">TACK FÖR PASSET!</h1>
            <p className="lead">
              SESSION HAR LOGGATS UR
            </p>
            <hr className="my-2" />
            <div className="divider_orange"></div>
            <div className="flex-row">
              <div className="flex-col">
            <button className="btn btn-red" size="lg" onClick={ () => this.logout() }>
              STÄNG
            </button>
              </div>
            </div>
          </div>
    </div>

    </div>
    </div>
    </div>

    <div className="web-foot">
        <div className="flex">
        <div className="web-foot-txt">Hello : { this.state.status }</div>
        </div>
        <div className="web-foot-icon">
          <FontAwesomeIcon icon="ellipsis-v" size="1x"/>
        </div>

        <div className="flex flex-row justify-content-end">

        <div className="web-foot-txt hide" onClick={() => this.fknGo("pms")}>Byt System</div>
        <div className="web-foot-txt">
          <Tiden />
        </div>
        </div>
    </div>

    </div>
    );
    }
}

//WebPub.contextType = SDOContext; // Function
export default withRouter(Sesout);
