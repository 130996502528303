import React, { useState, useEffect, useContext } from 'react';
import {Route, Routes} from "react-router-dom";

import Rps_Home from './rps/home.js';
import Rps_Bf from './rps/bf.js';
import Rps_Reg from './rps/reg.js';
import {withRouter} from "../lib/react/withRouter";

function pg_home() {

    var html =
    <div className="web-content">
      Hem
    </div>
    ;
    return html;
}

class Bfs_Rps extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.state = {};
  }
  fknGo(row) {
    //alert(row.href);
    this.props.navigate(row.url);
  }

  render() {
      var path = window.location.pathname;
      if(path.substr(-1) == "/") path = path.substring(0, path.length - 1);
      var op = path.split("/").pop();
      var po = {};
      po.bf = { "fid": "f04", "url": "/bfs/rps/bf", "name": "Bokföring", "active": false };
      po.reg = { "fid": "f04", "url": "/bfs/rps/reg", "name": "Register", "active": false };
      if(po[op]) {
          po[op].active = true;
          this.props.store.fkn = po[op];
      }
      var pa = Object.values(po);

    return (

    <div className="web-app">
         { this.props.store.cfg.bread ?
             <div className="web-bread">
                 <div className="breadcrumb">
            { pa.map((row, key) =>
                <div key={key} className="breadcrumb-item" active={row.active} onClick={() => this.fknGo(row)}>{row.name}</div>
            )}
            <div className="flex"></div>
            <div className="cdigreen">{ this.props.store.fkn ? this.props.store.fkn.name : null }</div>
            </div>
        </div>
        : null }
        <Routes>
          <Route path="/xx" component={pg_home} />
            <Route path="bf" element={ <Rps_Bf store={this.store} />} />
            <Route path="reg" element={ <Rps_Reg store={this.store} />} />
            <Route path="*" element={ <Rps_Home store={this.store} />} />
        </Routes>
    </div>

  );
}
}
//Sys.contextType = ADOContext;

export default withRouter(Bfs_Rps);
