import React, { useState, useEffect, useContext } from 'react';
import * as fkn from "../lib/fkn";
import SDOContext from '../sdo.js'
import {Route, Routes} from "react-router-dom";
import {withRouter} from "../lib/react/withRouter";

import Fakt_Home from './rsk/home.js';
import Rsk_Rsk from './rsk/rsk.js';
import Rsk_Rmd from './rsk/rmd.js';
import Rsk_Int from './rsk/int.js';
import Rsk_Hist from './rsk/hist.js';
import sso from "../sso";

function pg_home() {

    var html =
    <div className="web-content">
      Hem
    </div>
    ;
    return html;
}

class Fakt_Rsk extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.state = { pgid: 11, deps: [] };

      var po = {};
      po.rsk = { "fid": "f05", "url": "/fakt/rsk/rsk", "name": "Reskontra", "active": false };
      po.rmd = { "fid": "f05", "url": "/fakt/rsk/rmd", "name": "Påminnelser", "active": false };
      po.int = { "fid": "f05", "url": "/fakt/rsk/int", "name": "Räntehantering", "active": false };
      po.hist = { "fid": "f05", "url": "/fakt/rsk/hist", "name": "Historik", "active": false };
      this.state.po = po;
      this.state.pa = [];
      this.state.pa = Object.values(po);
  }

    fknGo(row) {
        sso.seo.sfkn = row;
        this.props.navigate(row.url);
    }
    br_css(row) {
        var css = {};
        if(sso.seo.sfkn && (row.url == sso.seo.sfkn.url)) css.color = "#080";
        //var brc = false;
        //if(row.url == sbo.state.url) brc = true;
        return css;
    }

  render() {
    return (
        <div className="web-app">
            { this.props.store.cfg.bread ?
                <div className="web-bread">
                    <div className="breadcrumb">
                        { this.state.pa.map((row, key) =>
                            <div key={key} className="breadcrumb-item" style={this.br_css(row)} onClick={() => this.fknGo(row)}>{row.name}</div>
                        )}
                        <div className="flex"></div>
                        <div className="cdigreen">{ sso.seo.sfkn ? sso.seo.sfkn.name : null }</div>
                    </div>
                </div>
                : null }

        <Routes>
          <Route path="/xx" component={pg_home} />
            <Route path="rsk" element={ <Rsk_Rsk store={this.store} />} />
            <Route path="rmd" element={ <Rsk_Rmd store={this.store} />} />
            <Route path="int" element={ <Rsk_Int store={this.store} />} />
            <Route path="hist" element={ <Rsk_Hist store={this.store} />} />
            <Route path="*" element={ <Fakt_Home store={this.store} />} />
        </Routes>

        </div>
  );
}
}
//Sys.contextType = ADOContext;

export default withRouter(Fakt_Rsk);
