import React, { useState, useEffect, useContext } from 'react';
import {observer} from "mobx-react";

import * as utils from '../../assets/utils.js'
import ddo from '../../ddo.js'

import * as net from "../../lib/net";
import Plu_New from "./plu/plu_new";
import Plu_Open from "./plu/plu_open";
import Plu_Copy from "./fkn/plu_cpy";
import Plu_Import from "./fkn/plu_import";
import Plus_Pub from "./fkn/plus_pub";
import Plus_Tech from "./fkn/plus_tech";

import Wnd_GV from "../../lib/ui/wnd_gv";
import Td_Sel from "../../lib/ui/td_sel";
import Dd_Sel from "../../lib/ui/dd_sel";
import Lang from "../../lib/lang";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Pos_Plus extends React.Component {
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pos = props.store.pos;

    this.state = {};
    this.state.seldate = new Date();
    this.state.avd = ddo.cache.avd;
    this.state.vgrp = "000";
    this.state.pluopen = 0;
    this.state.sz_val = "";
    this.state.pbo = {};
    this.state.aa = [];

    this.state.pstep = 0;
    this.state.pmax = 0;
    this.state.plen = 0;
    this.state.poa = [];
    this.state.paa = [];
    this.state.bta = [];

    this.state.sortid = "";
    this.state.msg = {};
    this.state.wnds = {};
    if(this.pos.deps) {
        this.state.aa = this.pos.deps;
        //this.state.avd = this.pos.deps[0].k0kstns;
    }
    this.state.vga = [].concat( { var00: { v0katnr: '000', v0text: 'ALLA VGRP' } }, this.pos.vgrps );

    this.selitems = [
        {fkn: 'edit', text: 'ÖPPNA'},
        {fkn: 'remove', text: 'TABORT'}
    ];
    this.fknitems = [
        {fkn: 'plus_copy', text: 'KOPIERA PLU/KNAPPAR'},
        {fkn: 'plus_import', text: 'IMPORTERA PLU'},
        {fkn: 'plus_pub', text: 'PUBLICERA PLU'},
        {fkn: 'plus_tech', text: 'TEKNIKER PLUBEARBETNING'}
    ];
      this.state.loading = false;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.props.store.fkn = { fid: "m02", name: "PLU", url: "/pos/prds/plus", fa: "fa fa-bars" };
    this.plus();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 235;
      css.overflow = "auto";
      return css;
  }
  th_style(id) {
      var css = {};
      css.cursor = "pointer";
      if(id == this.state.sortid) css.color = "#080";
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }
    sz_keydown (e) {
      //e.preventDefault();
        const key = e.key;
        const keyCode = e.keyCode;

        if(keyCode == 13) {
            this.plu_sz();
        };
        if(keyCode == 27) {
            this.setState({sz_val: ""});
        };
    }
    sz_change (e) {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({sz_val: value});
        //setState({[name]: value});
    }
  change_avd(e) {
    //let cdat = this.state.seldate;
    let avd = e.target.value;
    //ddo.cache.avd = avd;
    this.setState({avd: avd});
  }
  change_vgrp(e) {
    var self = this;
    //let cdat = this.state.seldate;
    let vgrp = e.target.value;
    this.setState({vgrp: vgrp}, function(){
      self.plu_filtr();
    });
  }

    plu_new() {
        var self = this;
        //var msg = { gv: true, text: "Ange PLUnr", text0: "Skapa", text1: "Avbryt", cb: "plu_new_do"};
        //self.setState({msg: msg });
        var wnds = {};
        wnds.plunew = 1;
        wnds.avd = ddo.cache.avd;
        self.setState({ wnds: wnds });
    }

  plu_new_do(val, plunr) {
    var self = this;

    if(val == 1) {
        self.setState({msg: {} });
        return;
    }

    var pbo = {};
    pbo.plu00 = {};
    pbo.plu01 = {};
    pbo.plu02 = {};
    pbo.plu03 = {};
    pbo.plu04 = {};
    pbo.plu05 = {};

    pbo.plu00.p0p = "P";
    pbo.plu00.p0kstns = ddo.cache.avd;
    pbo.plu00.p0katnr = plunr;
    pbo.plu00.p0namn = "NY";
    pbo.plu00.p0pris = "0";
    pbo.plu00.p0nypris = "0";
    pbo.plu00.p0happy = "0";
    pbo.plu00.p0moms = "01";
    pbo.plu00.p0debcre = "1";
    pbo.plu01.p1nyhappy = "0";
    pbo.plu02.p2vgrupp = "001";
    pbo.plu02.p2pluvis = "1";
    pbo.plu02.p2pluknapp = "";
    pbo.plu02.p2lagst = "01";
    pbo.plu02.p2ulager = "";
    pbo.plu03.p3kost = "0";
    pbo.plu04.p4longname = "";

    this.setState({ pbo: pbo, pluopen: 1, msg: {} });
  }
  plu_edit(row) {
    var self = this;
    var pbo = row;
    //var pbo = JSON.parse(JSON.stringify(row));
    //this.setState({ pbo: pbo, pluopen: 1 });
    var wnds = { pluopen: 1, pbo: pbo };
    self.setState({ wnds: wnds });
  }
  plu_upd(ctx, plubuf) {
    var self = this;
    ctx.plus();
    this.setState({ pluopen: 0 });
  }
  plu_sz() {
      var self = this;
      var poa = self.state.poa;
      var sz = this.state.sz_val;

      var paa = poa.filter( row => (
         row.plu00.p0namn.toUpperCase().includes(sz.toUpperCase()) ||
        row.plu00.p0katnr.toUpperCase().includes(sz.toUpperCase())
      ));
      self.setState({ paa: paa });
   }
  plu_filtr() {
      var self = this;
      var poa = self.state.poa;
      var vgrp = this.state.vgrp;

      if(vgrp == "000") {
        self.setState({paa: poa });
        return;
      }

      var paa = poa.filter(row => row.plu02.p2vgrupp == vgrp);
      self.setState({paa: paa });
   }

    plu_step() {
        var self = this;
        var poa = self.state.poa;
        var paa = self.state.paa;
        var pstep = self.state.pstep;

        pstep = pstep + 1;
        var pos = pstep * 10;
        var a = poa.slice(pos, pos+10);

        self.setState({pstep: pstep, paa: a });
    }

   tb_sort(typ) {
      var self = this;
      var poa = self.state.poa;
      var paa = self.state.paa;
      var sz = this.state.sz_val;

      if(typ == "plu") {
        paa.sort((a,b) => (a.plu00.p0katnr > b.plu00.p0katnr) ? 1 : ((b.plu00.p0katnr > a.plu00.p0katnr) ? -1 : 0));
      }
      if(typ == "text") {
        paa.sort((a,b) => (a.plu00.p0namn > b.plu00.p0namn) ? 1 : ((b.plu00.p0namn > a.plu00.p0namn) ? -1 : 0));
      }
      if(typ == "pris") {
        paa.sort((a,b) => (a.plu00.p0pris > b.plu00.p0pris) ? 1 : ((b.plu00.p0pris > a.plu00.p0pris) ? -1 : 0));
      }
      self.setState({paa: paa, sortid: typ });
   }
    tb_sort(typ) {
        var self = this;
        //var rvs = self.state.rvs;
        var paa = self.state.paa;
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;

        if(sortid != typ) sortdir = 0;
        else {
            if(sortdir) sortdir = 0;
            else sortdir = 1;
        }
        var fa = typ.split(".");
        if(true) {
            if(sortdir) paa.sort((a,b) => (a[fa[0]][fa[1]] < b[fa[0]][fa[1]]) ? 1 : ((b[fa[0]][fa[1]] < a[fa[0]][fa[1]]) ? -1 : 0));
            else paa.sort((a,b) => (a[fa[0]][fa[1]] > b[fa[0]][fa[1]]) ? 1 : ((b[fa[0]][fa[1]] > a[fa[0]][fa[1]]) ? -1 : 0));
        }
        self.setState({ paa: paa, sortid: typ, sortdir: sortdir });
    }
    th_icon(typ) {
        var cls = "";
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;
        if(sortid == typ)
        {
            if(sortdir) cls = "fa fa-caret-up";
            else cls = "fa fa-caret-down";
        }
        return cls;
    }

  plus() {
      var self = this;
      self.setState({ paa: [], poa: [], loading: true });
    var vgrp = this.state.vgrp;

    //if(!ddo.cache.avd) return;

    var prm = {};
    prm.req = "pos.pos_plu.plus";
    //prm.avd = this.state.avd;
    prm.avd = ddo.cache.avd;
    //alert(JSON.stringify(prm));

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        self.props.store.pos.plus = ret.rco;
        var poa = ret.rco;
        var paa = ret.rco;
        if(vgrp != "000") paa = poa.filter(row => row.plu02.p2vgrupp == vgrp);
        self.setState({poa: poa, paa: paa, loading: false }, function () {
            self.syncTb("pluTable");
        });
/*
		var a = paa.slice(0, 10);
		var plen = paa.length;
		var pmax = Math.round(plen / 10);
		var pstep = 0;
		var bta = [1, 2, 3, 4];
        var paa = poa.filter(row => row.plu02.p2vgrupp == vgrp);
        self.setState({poa: poa, pstep: pstep, plen: plen, pmax: pmax, paa: paa, bta: bta });
 */

        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }
    plus_copy() {
        var self = this;
        var wnds = {};
        wnds.pluscopy = 1;
        wnds.avd = ddo.cache.avd;
        self.setState({ wnds: wnds });
    }
    plus_import() {
        var self = this;
        var wnds = {};
        wnds.plusimport = 1;
        wnds.avd = ddo.cache.avd;
        self.setState({ wnds: wnds });
    }
    plus_pub() {
        var self = this;
        var wnds = {};
        wnds.pluspub = 1;
        wnds.avd = ddo.cache.avd;
        self.setState({ wnds: wnds });
    }
    plus_tech() {
        var self = this;
        var wnds = {};
        wnds.plustech = 1;
        wnds.avd = ddo.cache.avd;
        self.setState({ wnds: wnds });
    }
  xplus_cpy() {
    var self = this;
    var prm = {};
    prm.req = "pos.pos_plu.plus_cpy";
    prm.favd = ddo.cache.avd;
    prm.tavd = "02";

    net.sio_req(prm)
    .then(function(ret){
        alert(JSON.stringify(ret));

    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  xbtns_cpy() {
    var self = this;
    var prm = {};
    prm.req = "pos.pos_btns.btns_cpy";
    prm.favd = ddo.cache.avd;
    prm.tavd = "02";

    net.sio_req(prm)
    .then(function(ret){
        alert(JSON.stringify(ret));

    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  xpays_cpy() {
    var self = this;
    var prm = {};
    prm.req = "pos.pos_pay.pays_cpy";
    prm.favd = ddo.cache.avd;
    prm.tavd = "02";

    net.sio_req(prm)
    .then(function(ret){
        alert(JSON.stringify(ret));

    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

  tst() {
    var self = this;
    var prm = {};
    prm.req = "pos.pos_red.trs_fix";
    prm.fdat = "200630";
    prm.tdat = "200707";
    //prm.fplu = datum;
    //prm.tplu = datum;

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        alert(JSON.stringify(ret));

        var o = {};
        //o.deps = ret.rco;
        //alert(JSON.stringify(o));
        //self.setState({paa: ret.rco });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }
  trw() {
    var self = this;
    var prm = {};
    prm.req = "pos.pos_eko_hist.trw";
    prm.avd = ddo.cache.avd;
    //prm.fplu = datum;
    //prm.tplu = datum;

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        alert(JSON.stringify(ret));

        var o = {};
        //o.deps = ret.rco;
        //alert(JSON.stringify(o));
        //self.setState({poa: ret.rco, paa: ret.rco });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }
    syncTb(tb){
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
        tdElements = tbel.getElementsByTagName('td');

        for (let i = 0; i < thElements.length; i++) {
            if(!thElements[i] || !tdElements[i]) continue;
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
            width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
  sel_row_fkn(row, sel) {
      //alert(JSON.stringify(fkn));
    if(sel.fkn == "edit") {
        this.plu_edit(row);
    }
  }
    sel_fkn(sel) {
        //alert(JSON.stringify(fkn));
        if(sel.fkn == "plus_copy") {
            this.plus_copy();
        }
      if(sel.fkn == "plus_import") {
          this.plus_import();
      }
      if(sel.fkn == "plus_pub") {
          this.plus_pub();
      }
      if(sel.fkn == "plus_tech") {
          this.plus_tech();
      }
      if(sel.fkn == "plus_cpy") {
          //this.plus_cpy();
      }
      if(sel.fkn == "btns_cpy") {
          //this.btns_cpy();
      }
      if(sel.fkn == "pays_cpy") {
          //this.pays_cpy();
      }
  }
    show_mtxt(mkod) {
        var mkods = ddo.cache.mkods;
        var mtxt = "";

        if(mkods && mkods[mkod]) {
            mtxt = mkods[mkod].m0txt;
        }
        return mtxt;
    }
    vgrps() {
      var a = [];
      a.push({ var00: { v0katnr: '000', v0text: 'ALLA VGRP' } });
      if(ddo.cache.vgrps) a.concat(ddo.cache.vgrps);
        //[].concat( { var00: { v0katnr: '000', v0text: 'ALLA VGRP' } }, ddo.cache.vgrps );
        return a;
    }

  render() {
    return (
    <div className="web-p00 flex flex-col">

    { this.state.loading ? <div className="flex flex-col flex-center"><FontAwesomeIcon icon="spinner" spin size="4x" /></div> : null }
    { !this.state.loading ?

    <div className="">
        <div className="flex-row">
        <select className="form-control w250 hide" value={this.state.avd} placeholder="Välj avdelning" onChange={(e) => this.change_avd(e)}>
            { this.props.store.pos.deps.map((row, key) =>
            <option key={key} value={row.avd}>{row.avd} - { utils.toLang(row.text) }</option>
            )}
        </select>
        <select className="form-control w250" placeholder="Välj Varugrupp" value={this.state.vgrp} onChange={(e) => this.change_vgrp(e)}>
            <option value="000">ALLA VARUGRUPPER</option>
            { ddo.cache.vgrps.map((row, key) =>
            <option key={key} value={row.var00.v0katnr}>{row.var00.v0katnr} - {row.var00.v0text.toswe()}</option>
            )}
        </select>
        <input className="txt sztxt" value={this.state.sz_val} onChange={(e) => this.sz_change(e)} onKeyDown={(e) => this.sz_keydown(e)} />
        <div className="flex"></div>
        <button className="btn btn-hw-blue w150" onClick={() => this.plu_new() } aria-label="System" >
            NY
        </button>
        <button className="btn btn-hw-blue w150" onClick={() => this.plus() } aria-label="System" >
            UPPDATERA
        </button>
        <Dd_Sel items={ this.fknitems } cls="web-top-icon" getValue={(e)=> this.sel_fkn(e)} />
    </div>
    <div className="divider"></div>

    <div className="web-row">
        <div id="pluTable" className="flex">
        <table className="table table-bordered table-hover tb" style={{marginBottom:"0px"}}>
            <thead>
            <tr>
                <th style={ this.th_style("plu00.p0katnr") } onClick={() => this.tb_sort("plu00.p0katnr") }><Lang>PLU <i className={ this.th_icon("plu00.p0katnr") }></i></Lang></th>
                <th style={ this.th_style("plu02.p2vgrupp") } onClick={() => this.tb_sort("plu02.p2vgrupp") }><Lang>Varugrupp <i className={ this.th_icon("plu02.p2vgrupp") }></i></Lang></th>
                <th style={ this.th_style("plu00.p0namn") } onClick={() => this.tb_sort("plu00.p0namn") }><Lang>Text <i className={ this.th_icon("plu00.p0namn") }></i></Lang></th>
                <th style={ this.th_style("plu00.p0pris") } onClick={() => this.tb_sort("plu00.p0pris") }><Lang>Pris <i className={ this.th_icon("plu00.p0pris") }></i></Lang></th>
                <th style={ this.th_style("plu00.p0moms") } onClick={() => this.tb_sort("plu00.p0moms") }><Lang>Moms <i className={ this.th_icon("plu00.p0moms") }></i></Lang></th>
                <th style={ this.th_style("plu00.p0sort") } onClick={() => this.tb_sort("plu00.p0sort") }><Lang>Sort <i className={ this.th_icon("plu00.p0sort") }></i></Lang></th>
                <th style={ this.th_style("plu00.p0upddat") } onClick={() => this.tb_sort("plu00.p0upddat") }><Lang>Uppdat <i className={ this.th_icon("plu00.p0upddat") }></i></Lang></th>
                <th></th>
            </tr>
            </thead>
            </table>
            <div style={ this.tb_style() }>
              <table className="table table-bordered table-hover tb" style={{marginBottom:"0px"}}>
                <tbody>
            { this.state.paa.map((row, key) =>
            <tr key={key} onDoubleClick={() => this.plu_edit(row) }>
                <td>{ row.plu00.p0katnr }</td>
                <td>{ row.plu02.p2vgrupp }</td>
                <td>{ row.plu00.p0namn.toswe() }</td>
                <td className="text-right">{ row.plu00.p0pris.formatpris() }</td>
                <td>{ this.show_mtxt(row.plu00.p0moms) }</td>
                <td>{ row.plu00.p0sort }</td>
                <td>{ row.plu00.p0upddat }</td>
                <td>
                    <Td_Sel items={ this.selitems } getValue={(e)=> this.sel_row_fkn(row, e)} />
                </td>
            </tr>
            )}
            </tbody>
        </table>
        </div>
        </div>
    </div>
    </div>
    : null }
    { this.state.wnds.plunew ?
        <Plu_New wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
    : null }
        { this.state.wnds.pluopen ?
            <Plu_Open wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
            : null }
        { this.state.wnds.pluscopy ?
            <Plu_Copy wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
            : null }
        { this.state.wnds.plusimport ?
            <Plu_Import wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
            : null }
        { this.state.wnds.pluspub ?
            <Plus_Pub wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
            : null }
        { this.state.wnds.plustech ?
            <Plus_Tech wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
            : null }
    <Wnd_GV msg={this.state.msg} ctx={this} />

    </div>
  );
    }
}
export default observer(Pos_Plus);
