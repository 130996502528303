import React, { useState, useEffect, useContext } from 'react';
import {format} from "date-fns";

import * as net from "../../../lib/net";
import Dd_Sel from "../../../lib/ui/dd_sel";
import Dt_Cal from "../../../lib/ui/dt_cal";

import Pms_Rvo from "../../rv/rvo";
import Sell_Preg from "./fkn/sell_preg";
import Sell_Fsk from "./fkn/sell_fsk";

import Fr_Input from "../../../lib/ui/fr_input";

class Sell_Tr extends React.Component {
  constructor(props, context) {
    super(props);

     this.selitems = [
        {fkn: 'setup', text: 'INSTÄLLNINGAR'},
        {fkn: 'upd', text: 'UPPDATERA'},
        {fkn: 'sys', text: 'SYSTEM'},
        {fkn: 'logout', text: 'LOGGA UT'}
    ];

    this.store = props.store;
    this.state = {};
    this.state.ynq = [];
      this.state.wnds = {};

    this.state.paa = [];
    this.state.aa = [];

    this.state.boknr = "";
    this.state.gso = {};
    this.state.tro = {};
    if(props.wnds) {
        this.state.boknr = props.wnds.boknr;
        this.state.gso = props.wnds.gso;
        var tro = props.wnds.tro;
        //this.state.tro = JSON.parse(JSON.stringify(tro));
        this.state.tro = {};
        this.state.tro.antal = "1";
        //this.state.tro.pris = this.state.tro.pris.cdipris();
        this.state.tro.pris = tro.e1pris.cdipris();
        this.state.tro.id = tro.e1typ;
        this.state.tro.text = tro.e1text;
        this.state.tro.tkn = "+";
        if(tro.e1debcre == "0") this.state.tro.tkn = "-";
        this.state.tro.moms = tro.e1moms;
    }

    this.state.today = new Date();
    var dts = format(this.state.today, "yyyy-MM-dd");
    this.state.dts = dts;
    this.state.ok = false;
    this.state.error = false;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    body_style() {
      var css = {};
      if(this.state.ok) {
          css.background = "#080";
          css.color = "#fff";
      }
      if(this.state.error) {
          css.background = "#800";
          css.color = "#fff";
      }
      //css.height = window.innerHeight * 0.8;
      return css;
    }

    wnd_close() {
      if(this.props.wnds.cb) {
        var rsp = {};
        rsp.ok = "999";
          this.props.wnds.cb(this.props.ctx, rsp);
      }
      else this.props.ctx.setState({ wnds: {} });
	};
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
    val_save (name, value) {
      var tro = this.state.tro;
      //var na = name.split(".");
      //gso[na[0]][na[1]] = value;
      tro[name] = value;
      this.setState({tro: tro});
      //setState({[name]: value});
    }
  sell_tr() {
    var self = this;

    var tro = this.state.tro;
    
    if(tro.id.trim() == "Rpru") {
      tro.belopp = tro.pris;
      self.sell_preg(tro);
      return;
  }
  if(tro.id.trim() == "Fors") {
      tro.belopp = tro.pris;
      self.sell_fsk(tro);
      return;
  }

  var tkn = "+";
    if(tro.pris.includes("-")) {
        tro.pris = tro.pris.replace(/\-/g,"");
        tkn = "-";
    }
    if(tro.tkn.includes("-")) tkn = "-";

    //if(tro.pris.includes(".")) tro.pris = tro.pris.replace(".", "");
    if(tro.pris.includes(".")) tro.pris = tro.pris.replace(/\./g,"");
    else tro.pris = tro.pris + "00";

    //alert(JSON.stringify(this.state.rvo.gsb00));
    //alert(JSON.stringify(this.state.gso));
    //return;
    var prm = {};
    prm.req = "pms.sell.charge_tr";
    prm.boknr = this.state.boknr;
    prm.gsb00 = this.state.gso;
    prm.trkod = tro.id;
    prm.text = tro.text;
    prm.antal = tro.antal;
    prm.pris = tro.pris;
    prm.tkn = tkn;
    prm.moms = tro.moms;
    prm.datum = this.state.dts;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(tro));

      if(self.props.wnds.cb) {
        var rsp = {};
        rsp.ok = "000";
          self.props.wnds.cb(self.props.ctx, rsp);
      }
      else self.props.ctx.setState({ wnds: {} });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

    sell_preg(tro) {
        var self = this;

        var wnds = {};
        wnds.sellpreg = 1;
        wnds.boknr = this.state.boknr;
        wnds.gso = this.state.gso;
        wnds.tro = tro;
        wnds.cb = this.cb_sell_preg;
        this.setState({wnds: wnds});
    }
    cb_sell_preg(ctx, ret) {
      if(!ret.ok || (ret.ok !== "000")) {
        ctx.setState({ wnds: {} });
        return;
        }

        ctx.setState({ wnds: {} });

        if(ctx.props.wnds.cb) {
            var rsp = {};
            rsp.ok = "000";
            ctx.props.wnds.cb(ctx.props.ctx, rsp);
        }
        else ctx.props.ctx.setState({ wnds: {} });
    }
    sell_fsk(tro) {
        var self = this;

        var wnds = {};
        wnds.sellfsk = 1;
        wnds.boknr = this.state.boknr;
        wnds.gso = this.state.gso;
        wnds.tro = tro;
        wnds.cb = this.cb_sell_fsk;
        this.setState({wnds: wnds});
    }
    cb_sell_fsk(ctx, ret) {
        if(!ret.ok || (ret.ok !== "000")) {
          ctx.setState({ wnds: {} });
          return;
        }

        ctx.setState({ wnds: {} });

        if(ctx.props.wnds.cb) {
            var rsp = {};
            rsp.ok = "000";
            ctx.props.wnds.cb(ctx.props.ctx, rsp);
        }
        else ctx.props.ctx.setState({ wnds: {} });
    }

  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "";

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="m2_backdrop" /> : null }
        <div className="m2_dlg mx_50h flex-col" style={ this.dlg_style() }>
        <div className="flex-row " style={{padding:"0px",height:"50px",lineHeight:"50px", background: "#1a456b", color: "#fff"}}>
                <div className="flex-row flex" style={{padding:"0px 5px", fontSize: "22px"}}>
                    <div>Transaktion</div>
                </div>
            <div className="wnd-close-btn" onClick={() => this.wnd_close() }>X</div>
        </div>
        <div className="mx_content flex" style={ this.body_style() }>

            <div className="flex-row txt_22">
                <div className="flex text-center">Transaktion : { this.state.tro.id }</div>
            </div>
            <div className="flex"></div>
            <form className="cdi_f03">
            <div className="flex-col">
                <div className="flex-row py-2">
                    <div className="f30"></div>
                    <Fr_Input label="Text" col className="text-left w350 mx-2" text={this.state.tro.text} getValue={(e)=> this.val_save("text", e) } />
                </div>
                <div className="flex-row py-2">
                    <div className="f30"></div>
                    <Fr_Input label="Antal" col className="text-left w150 mx-2" text={this.state.tro.antal} getValue={(e)=> this.val_save("antal", e) } />
                </div>
                <div className="flex-row py-2">
                    <div className="f30"></div>
                    <Fr_Input label="Pris" col className="text-left w250 mx-2" text={this.state.tro.pris} getValue={(e)=> this.val_save("pris", e) } />
                </div>
                <div className="flex-row py-2">
                    <div className="f30"></div>
                    <Fr_Input label="Tecken" col className="text-left w150 mx-2" text={this.state.tro.tkn} getValue={(e)=> this.val_save("tkn", e) } />
                </div>
                <div className="flex-row py-2">
                    <div className="f30"></div>
                    <Fr_Input label="Tecken" col className="text-left w150 mx-2" text={this.state.tro.tkn} getValue={(e)=> this.val_save("tkn", e) } />
                </div>
            </div>
            </form>
            <div className="flex"></div>

        </div>
        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex"></div>
            <button className="btn btn-hw-red w250" onClick={() => this.sell_tr() } >
                SÄLJ
            </button>
        </div>
      </div>
            { this.state.wnds.sellpreg ? <Sell_Preg wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
            { this.state.wnds.sellfsk ? <Sell_Fsk wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
    </div>
	;
	return html;
  };
}

export default Sell_Tr;