import React, { useState, useEffect, useContext } from 'react';
import {format, addDays, subDays} from "date-fns";

import * as net from "../../../lib/net";
import Fr_Input from "../../../lib/ui/fr_input";

import Pms_FbRv00 from "./rv00.js";
import Pms_FbRv01 from "./rv01.js";
import Pms_FbRv02 from "./rv02.js";
import Pms_FbRv03 from "./rv03.js";

class Pms_FbRvo extends React.Component {
  constructor(props, context) {
    super(props);

    this.state = {};
    this.state.rvlt = 3; // 0 = Standard, 1 = Simple, 2 = Traditional, 3 = Modern
    this.state.ynq = [];
    this.state.sz_val = "";
    this.state.wnds = {};
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }

    wnd_close = () => {
      if(this.props.wnds.cb) {
        var ret = {};
        ret.ok = "999";
        this.props.wnds.cb(this.props.ctx, ret);
      }
      else this.props.ctx.setState({ wnds: {} });
	};

  render() {
    var title = this.props.title;
    if(!title) title = "Sök";
    //if(props.kro) alert(props.kro.krr00.kid);

    if(this.state.rvlt == 3) {
      var html =
          <Pms_FbRv03 {...this.props} store={this.props.store} />
      ;
    }
    if(this.state.rvlt == 2) {
      var html =
          <Pms_FbRv02 {...this.props} store={this.props.store} />
      ;
    }
    if(this.state.rvlt == 1) {
    var html =
        <Pms_FbRv01 {...this.props} store={this.props.store} />
      ;
    }
    if(this.state.rvlt == 0) {
      var html =
            <Pms_FbRv00 {...this.props} store={this.props.store} />
        ;
      }

      return html;
    };
}

export default Pms_FbRvo;