import React, { useState, useEffect, useRef, useContext } from 'react';
import { sv } from 'date-fns/locale'

import {observer} from "mobx-react";
import {withStore} from "../sdo";

import langdb from './langdb'

class Lang extends React.Component {
  constructor(props, context) {
    super(props);

    this.state = {};
    //this.state.lang = props.xstore.lang;
    this.state.txt = "";
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.getText();
  }

  getText() {
        var txt = this.props.children;
        //alert(this.state.lang);
        if(langdb[this.props.store.lang]) {
            if(langdb[this.props.store.lang][txt]) txt = langdb[this.props.store.lang][txt]
        }
        this.setState({txt: txt});
        //this.forceUpdate();
    }

  render() {
    var id = this.props.children;
    var txt = this.props.children;
    if(this.props.id) id = this.props.id;

    if(langdb[this.props.xstore.lang]) {
        if(langdb[this.props.xstore.lang][id]) txt = langdb[this.props.xstore.lang][id]
    }

	var html =
    <span className="">
        { txt }
    </span>
    ;

    return html;
  };
}
//Lang.contextType = LangContext;
export default withStore(observer(Lang));