import React, {useState} from 'react';

import { observer } from 'mobx-react';
import { useStore } from '../../sdo.js'

import * as net from "../../lib/net";
import Dd_Sel from "../../lib/ui/dd_sel";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function Reg_top(props) {
    let [state, setState] = useState({modal: false, ma: []});
    let ctx = useStore();

    var seo = props.store.seo;


    var selitems = [
        {fkn: 'home', text: 'HEM'},
        {fkn: 'setup', text: 'INSTÄLLNINGAR'},
        {fkn: 'upd', text: 'UPPDATERA'},
        {fkn: 'sys', text: 'SYSTEM'},
        {fkn: 'logout', text: 'LOGGA UT'}
    ];

      const goFull = () => {
        alert("Full");
      };

    function toggle() {
    };
    function logout() {
        var url = "/logout";
        this.props.navigate(url);
    };
    function acs() {
        var wnds = {};
        wnds.acs = 1;
        props.ctx.setState({ wnds: wnds });
    };
    function stat() {
        var wnds = {};
        wnds.stat = 1;
        props.ctx.setState({ wnds: wnds });
    };
    function bel() {
        var wnds = {};
        wnds.bel = 1;
        props.ctx.setState({ wnds: wnds });
    };
    function term() {
        var wnds = {};
        wnds.term = 1;
        props.ctx.setState({ wnds: wnds });
    };
      const mnu_toggle = () => {
		//props.ctx.setState({ mnu_show: !props.ctx.state.mnu_show });
		props.store.cfg.mnu_show = !props.store.cfg.mnu_show;
      };
      const bread_toggle = () => {
		props.store.cfg.bread = !props.store.cfg.bread;
      };
      const mnu_item_css = () => {
      };
      const mnu_sel = () => {
      };
      const login = () => {
      };
      const header_class = () => {
  	    var cls = "flex_col";

		if(false) {
			cls = cls + " web-head_1";
		}
		else cls = cls +  " web-header";


		return cls;
    }
  function fknGo(url) {
    //alert(url);
      this.props.navigate(url);
  }
  function sel_fkn(sel) {
    //alert(JSON.stringify(fkn));
      if(sel.fkn == "home") {
        var url = "/hello";
          this.props.navigate(url);
      }
      if(sel.fkn == "logout") {
        var url = "/logout";
          this.props.navigate(url);
      }
  }

    var html =
            <div id="header" className={ header_class() }>
            <div className="web-top-content d-flex flex-row">
                <div className="web-top-icon px-3 w60" onClick={() => mnu_toggle() }>
                    <FontAwesomeIcon icon="bars" size="1x"/>
                </div>
                <div className="web-top-mnu d-none d-md-inline-flex flex-column" onClick={() => toggle() }>
                    <div className="item-sys">Kassa register</div>
                    <div className="item-fkn">{ props.store.fkn.name }</div>
                </div>
                <div className="flex-fill"></div>
                <div className="web-top-icon px-3" onClick={(e) => bel(e) }>
                    <div>REGISTER</div>
                </div>
                <div className="flex-fill"></div>
                <div className="web-top-usr d-flex flex-column" onClick={(e) => fknGo("/hello") }>
                    <div className="txt_12"><FontAwesomeIcon icon="user" size="1x"/> { seo.name }</div>
                    <div className="txt_12"><FontAwesomeIcon icon="building" size="1x"/> { seo.company }</div>
                </div>

                <div className="web-top-sep"></div>
                <div className="web-top-usr d-flex flex-column" onClick={(e) => term() }>
                    { (seo.termnr && (seo.termnr != "")) ?
                    <div style={{padding: "0px", margin: "0px" }}>
                    <div className="txt_10">TERMNR</div>
                    <div className="txt_12 text-center">{ seo.termnr }</div>
                    </div>
                    :
                    <div style={{padding: "0px", margin: "0px" }}>
                    <div className="txt_10">STANDARD</div>
                    <div className="txt_10">TERMINAL</div>
                    </div>
                    }
                </div>
                <div className="web-top-sep"></div>
                <div className="">
                    <Dd_Sel items={ selitems } cls="web-top-icon" getValue={(e)=> sel_fkn(e)} />
                </div>
                <div className="">
                </div>
            </div>

            </div>
    ;
    return html;
}

export default observer(Reg_top);
