import React, { useState, useEffect, useContext } from 'react';
import { observer } from 'mobx-react';

import * as net from "../../lib/net";
import Wnd_Ok from "../../lib/ui/wnd_ok";

class Pos_Ccs extends React.Component {
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.seo = props.store.seo;

    this.state = {};
    this.state.cst = [
        //{ id:"0", typ:"0", text: "INGET CENTRALSYSTEM"},
        { id:"1", typ:"1", text: "ADMINISTRATIV / USER"},
        { id:"3", typ:"3", text: "ADMINISTRATIV / MANAGER"},
        { id:"5", typ:"5", text: "EKONOMI / ÖVERSIKT"}
    ];
    this.state.csa = [];
    this.state.msg = {};
   }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      this.ccs();
  }

    dlg_style() {
      var css = {};
       css.zIndex = "19999";
     //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    tab_style(tabid) {
      var css = {};
      css.display = "none";
      if(this.state.tabval === tabid) css.display = "block";
      return css;
    }
    wnd_close() {
		//this.props.ctx.setState({ wnds: {} });
		this.props.cb();
	};
    tb_style() {
        var css = {};
        css.height = window.innerHeight - 275;
        return css;
    }
    list_style() {
        var css = {};
        css.height = (window.innerHeight * 0.7) - 250;
        css.color = "#000";
        css.padding = "0px 5px";
        css.overflow = "auto";
        return css;
    }
    cssCols (cid) {
        var css = {};
        if(this.state.cols[cid]) css.width = this.state.cols[cid].toString() + "px";
        //if(cid == "c01") alert(JSON.stringify(this.state.cols[cid]));
        return css;
    }
  syncCols (tb) {

    if (!document.getElementById(tb)) return;

    var cols = {};
    var ix = document.getElementById(tb).rows.length - 1;
    var ic = document.getElementById(tb).rows[0].cells.length;
    var ci = 0;
    while(ci < ic) {
        var cxi = ci + 1;
        var col = "c" + cxi.toString().padStart(2, "0");
        cols[col] = document.getElementById(tb).rows[ix].cells[ci].clientWidth + 1;
        ci = ci + 1;
    }
    this.setState({cols: cols });
    //alert(JSON.stringify(this.state.cols));
    //this.forceUpdate();
  };
  cs_upd() {
    var self = this;
    self.ccs();
  };
  cs_active(row) {
    var self = this;
    self.ccs_sid(row.c0sid);
  };
  cs_typ(e) {
    var self = this;
    const value = e.target.value;
    self.seo.cs = value;
  };

  ccs_sid(sid) {
    var self = this;
    var prm = {};
    prm.req = "ccs:set_unit";
    prm.sid = sid;

    net.ccs_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        self.seo.sid = sid;
        //self.setState({cca: ret.rca });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

  ccs() {
    var self = this;
    var prm = {};
    prm.req = "cs.cs_units.csa";
    prm.avd = this.state.avd;
    //prm.fplu = datum;
    //prm.tplu = datum;

    //gda.wndLoading("Hämtar");
    net.ccs_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        self.setState({csa: ret.rca });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }

  render() {
    if(!this.props.wnds.ccs) return "";

    var title = this.props.title;
    if(!title) title = "Titel";

	var html =
        <div className="mx_base">
        { this.props.wnds.backdrop ? <div className="mx_backdrop" /> : null }
        <div className="mx_dlg mx_70 flex-col" style={ this.dlg_style() }>
        <div className="mx_content flex">

            <div className="flex-row" style={{color: "#000", padding:"5px",height:"35px"}}>
                    <div className="flex-col flex">
                        <div>ENHETER</div>
                    </div>
                    <div className="flex-col" onClick={() => this.cs_upd() }  >
                        <div>UPPDATERA</div>
                    </div>
            </div>

            <div className="divider_orange"></div>

            <div className="flex-row flex-space-between" style={{color: "#000", padding:"5px",height:"35px"}}>
                <div>CS ID: { this.seo.csid }</div>
                <select className="form-control w250" value={ this.seo.cs } onChange={(e) => this.cs_typ(e)}>
                    { this.state.cst.map((row, key) =>
                    <option key={key} value={row.id}>{row.text}</option>
                    )}
                </select>
            </div>
            <div className="web-row text-center" style={{color: "#000", padding:"5px",height:"35px"}}>
                AKTIVT: { this.seo.sid }
            </div>

            <div className="divider_green"></div>

            <div className="web-row">
              <div className="cdi_list flex-column flex" style={this.list_style()}>
                {this.state.csa.map((row, key) =>
                <div className="cdi_list_row flex" onClick={() => this.cs_active(row) }>
                  <div className="d-flex w-100 justify-content-between">
                    <h5 className="mb-1">{ row.c0text }</h5>
                    <small>xxx</small>
                  </div>
                  <p className="mb-1">SID: { row.c0sid }</p>
                  <small>{ row.c0typ }</small>
                </div>
            )}
            </div>
            </div>

        </div>

        <div className="mx_foot flex-row flex-space-between">
            <div className="flex-row">
            </div>
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex-row">
            </div>
        </div>
      </div>
        <Wnd_Ok msg={this.state.msg} ctx={this} />
    </div>
	;
	return html;
  };
}

export default observer(Pos_Ccs);