import React, { useState, useEffect, useContext } from 'react';
import * as net from "../lib/net";


class Gym_Door extends React.Component {

    constructor(props, context) {
        super(props);

        this.webStore = props.store;
        this.state = { pgid: 11, stat: [] };
        this.state.open = false;
        this.state.row = null;
        this.state.info = "";
        this.state.top = 1;
        this.state.user = {};
        this.state.sdo = {};
        this.state.qr = "";

    }
    componentDidMount(){
        //var sdo = sessionStorage.getItem("svp");
        //if(sdo) this.setState({sdo: JSON.parse(sdo) });
        //this.cdi_step();
    }

    componentWillUnmount(){
        this.setState({info: "UUU" });
    }

    header_class() {
  	    var cls = "flex_col";
		if(this.state.top) {
			cls = cls + " web-head_1";
		}
		else cls = cls +  " web-header";

		return cls;
    }

  door_open() {
    var self = this;

    var prm = {};
    prm.req = "gym.access.force_open";
    //prm.avd = this.state.avd;

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        alert(JSON.stringify(ret));
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }

  door_close() {
    var self = this;

    var prm = {};
    prm.req = "gym.access.force_close";
    //prm.avd = this.state.avd;

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        alert(JSON.stringify(ret));
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }
  qr1() {
    var self = this;

    var prm = {};
    prm.req = "gym.access.qr";
    //prm.avd = this.state.avd;

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));
        //var qr = 'data:image/png;base64,'+ ret.qr;
        self.setState({qr: ret.qr});
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  qr2() {
    var self = this;

    var prm = {};
    prm.req = "gym.access.qr";
    //prm.avd = this.state.avd;

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));
        //var qr = atob(ret.img);
        var qr = 'data:image/png;base64,'+ ret.qr;
        self.setState({qr: qr});
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

    render() {
        var stat = [];
        var keys = Object.keys(this.state.stat);
        for (var key of keys) {
            var row = this.state.stat[key];
            stat.push(row);
        }
        const handleDateChange = date => {
            this.setState({seldate: date });
        };

        return (
            <div className="web-app">

         <div className="web-row">
             <h3>ENTRE DÖRR KONTROLL</h3>
         </div>

        <div className="divider_orange"></div>

         <div className="web-row">
        <div className="web-col flex">
            <div className="wnd-btn" onClick={() => this.door_close() } >
                <div>STÄNG DÖRR</div>
                <div></div>
            </div>
        </div>
         <div className="web-col flex" style={{padding: "0px 10px"}}>
            <div className="wnd-btn" onClick={() => this.door_open() } >
                <div>ÖPPNA DÖRR</div>
                <div></div>
            </div>
       </div>
       </div>

     <div className="hide">
         <div className="web-row">
        <div className="web-col flex">
            <div className="wnd-btn" onClick={() => this.qr1() } >
                <div>QR 1</div>
                <div></div>
            </div>
        </div>
         <div className="web-col flex" style={{padding: "0px 10px"}}>
            <div className="wnd-btn" onClick={() => this.qr2() } >
                <div>QR 2</div>
                <div></div>
            </div>
       </div>
       </div>

        <div className="divider_orange"></div>
        <div className="web-row">
            { this.state.qr ?
            <img src={ this.state.qr } width="300px" height="300px" />
            : null}
        </div>
    </div>
           </div>

        );
    }

}
export default Gym_Door;
