import React, { useState, useEffect, useContext } from 'react';
import * as net from "../../../lib/net";

class Kid_Notor extends React.Component {
  constructor(props, context) {
    super(props);

    //alert('PBO CTX: ' + JSON.stringify(props.pbo));
   //this.webStore = props.store;
    this.state = {};
    this.state.ynq = [];
    //this.state.vga = [];
    this.state.rca = [];
    this.state.sval = [];
    this.state.tval = [];
    this.state.uaa = [];
    this.state.laa = [];
    this.state.lso = {};
    //this.state.lro = props.lro;
    this.state.aa = [];
    this.state.vga = [];
    this.state.cols = {};
    this.state.lrwrk = false;
    //this.state.pbo = {};
    this.state.pbo = props.pbo;
    this.state.ix = 0;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.notor();
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    tab_style(tabid) {
      var css = {};
      css.display = "none";
      if(this.state.tabval === tabid) css.display = "block";
      return css;
    }
    tab_head(tabid) {
      var css = {};
      css.background = "#fff";
      css.color = "#000";
      if(this.state.tabval === tabid) {
          css.background = "#080";
          css.color = "#fff";
      }
      return css;
    }

    slide_sel() {
    }
    ls_edit(row) {
    }
    lr_wrk(event) {
        this.setState({lrwrk: 1, lro: this.state.lro});
    }
    lr_shd(event) {
    }
    lr_prn(event) {
    }
    run_Lr(event) {
        //this.setState({value: event.target.value});
        //const body = new FormData(this.form);
        //alert('FORM: ' + JSON.stringify(state));
        //props.ctx.save_kid();
    }

    lr_lsa() {
        var self = this;
        var prm = {};
     	prm.req = "ta.ta_lon.ls_list";
   		prm.lrid = this.props.lro.lrid;
        //alert(JSON.stringify(prm));

        //gda.wndLoading("Hämtar");
        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret.rca));

            var o = {};
            //o.deps = ret.rco;
            //alert(JSON.stringify(o));
            self.setState({lsa: ret.rca });
            //alert(JSON.stringify(ret.rca));
            self.syncCols("lsTable");
            //toastr.success("OK", "SAPI");
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
            //gda.wndError(txt);
        });
    }
    formchange (e) {
      const name = e.target.name;
      const value = e.target.value;
      var pbo = this.state.pbo;
      var na = name.split(".");
      pbo[na[0]][na[1]] = value;
      this.setState({pbo: pbo});
      //setState({[name]: value});
    }

    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    handleClose = () => {
		this.props.ctx.setState({ md_01: false });
	};
    handleChange = (event, newValue) => {
        //alert(newValue);
        this.setState({ix: newValue});
      };
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  cssCols (cid) {
      var css = {};
      if(this.state.cols[cid]) css.width = this.state.cols[cid].toString() + "px";
      //if(cid == "c01") alert(JSON.stringify(this.state.cols[cid]));
      return css;
  }
  syncCols (tb) {

    if (!document.getElementById(tb)) return;

    var cols = {};
    var ix = document.getElementById(tb).rows.length - 1;
    var ic = document.getElementById(tb).rows[0].cells.length;
    var ci = 0;
    while(ci < ic) {
        var cxi = ci + 1;
        var col = "c" + cxi.toString().padStart(2, "0");
        cols[col] = document.getElementById(tb).rows[ix].cells[ci].clientWidth + 1;
        ci = ci + 1;
    }
    this.setState({cols: cols });
    //alert(JSON.stringify(this.state.cols));
    //this.forceUpdate();
  };

  notor() {
    var self = this;
    var prm = {};
    prm.req = "pos.pos_fakt.invs";
    prm.datum = "200817";

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        var o = {};
        //o.deps = ret.rco;
        //alert(JSON.stringify(o));
        self.setState({rca: ret.rca });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }

  render() {
	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="mx_backdrop" /> : null }
        <div className="mx_dlg mx_90 flex-col" style={ this.dlg_style() }>
        <div className="mx_content flex">

            <div className="flex-row" style={{padding:"5px",height:"35px"}}>
                    <div className="flex-col flex">
                        <div>UNDERLAG:</div>
                        <div>{ this.state.datum }</div>
                    </div>
                    <div className="flex-col">
                        <div>UPPDATERA</div>
                    </div>
            </div>
            <div className="divider_orange"></div>
    <div className="web-row">
        <table className="table table-bordered table-hover tb fixed_header">
            <thead>
            <tr>
                <th>Datum</th>
                <th>Avd</th>
                <th>Notanr</th>
                <th>Kid</th>
                <th>Belopp</th>
                <th>Status</th>
                <th style={{width:"40px"}} ></th>
            </tr>
            </thead>
            <tbody style={ this.tb_style() }>
            { this.state.rca.map((row, key) =>
            <tr id={ row.notanr } onDblClick="plu_open(row);">
                <td>{ row.notanr }</td>
                <td>{ row.datum }</td>
                <td>{ row.kundnr }</td>
                <td>{ row.antal }</td>
                <td>{ row.belopp }</td>
                <td>{ row.start }</td>
                <td>{ row.slut }</td>
                <td>{ row.aktiv }</td>
                <td style={{width:"40px",cursor:"pointer"}} onClick={() => this.plu_edit(row) }><i className="fa fa-cog text-danger"></i></td>
            </tr>
            )}
            </tbody>
        </table>
    </div>

        </div>

        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250 ripple" onClick={() => this.handleClose() } >
                STÄNG
            </button>
            <div className="flex-row">
            <button className="btn btn-hw-blue w250 ripple" onClick={(event) => this.prn_val(event) } >
                FÖRDELA
            </button>
            <button className="btn btn-hw-blue w250 ripple" onClick={(event) => this.prn_val(event) } >
                FUNKTIONER
            </button>
            </div>
            <button className="btn btn-hw-green w250 ripple" onClick={(event) => this.lr_prn(event) } >
                SPARA
            </button>
        </div>
      </div>
    </div>
	;
	return html;
  };
}

export default Kid_Notor;