import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";
import Edit_Txt from "../../lib/ui/edit_txt";
import ddo from "../../ddo";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Pos_Addons extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.pos = props.store.pos;

    this.state = {};
    this.state.seldate = new Date();
    this.state.avd = ddo.cache.avd;
    this.state.taa = [];
    this.state.tao = {};
    this.state.aa = [];
    if(this.pos.deps) this.state.aa = this.pos.deps;
    //context.posrend = this;
     this.state.modal = false;
     this.state.cols = ["ID", "Text", "Status"];
     this.state.dda = [
                ["R1", "Nooo", "A"],
                ["R2", "dCdfsd", "B"]
            ];
 }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.tval();
  }
    toggle = () => {
     var self = this;
      self.setState({
        modal: !self.state.modal
      });
    }
    xtoggle() {
     var self = this;
      self.setState({
        modal: !self.state.modal
      });
    }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 350;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 450;
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }
  formChange(e) {
        alert(JSON.stringify(e));
  }
  addon_save(row, e) {
    var self = this;

    //alert(JSON.stringify(this.state.tao[row.lopnr]));
    var ao = this.state.tao;
    ao[row.lopnr].text = e;
    var aa = Object.values(ao);
    self.setState({taa: aa, tao: ao});
  }

  addon_open(row) {
      var self = this;
      self.setState({modal: true});
      //var taa = this.state.taa;
      //taa[9].text = "TXXT";
      //self.setState({taa: taa});
  }
  plu_edit(row) {
      var self = this;
      alert(JSON.stringify(row.plu00));
  }

  tval() {
    var self = this;
    var prm = {};
    prm.req = "pos.pos_prds.addons";
    //prm.sid = "S0003103";
    prm.avd = ddo.cache.avd;
    //prm.fplu = datum;
    //prm.tplu = datum;

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        var ao = ret.rco;
        var aa = Object.values(ao);
        self.setState({taa: aa, tao: ao });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }
  tval_save() {
    var self = this;
    var prm = {};
    prm.req = "pos.pos_prds.addons_upd";
    prm.avd = ddo.cache.avd;
    prm.aa = this.state.taa;
    prm.ao = this.state.tao;

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }

  render() {
    return (
    <div className="web-app">
    <div className="flex-row">
        <h5>Tillval</h5>
        <div className="flex"></div>
         <button className="btn btn-hw-blue w150" onClick={() => this.tval_save() } aria-label="System" >
            SPARA
        </button>
         <button className="btn btn-hw-blue w150" onClick={() => this.tval() } aria-label="System" >
            UPPDATERA
        </button>
    </div>

   <div className="web-p01">
    <div className="web-row">

    <div className="flex flex-row">
        <table className="table table-bordered table-hover tb">
            <thead>
            <tr>
                <th>ID</th>
                <th className="flex">Text</th>
                <th>Status</th>
            </tr>
            </thead>
            <tbody style={ this.tb_style() }>
            { this.state.taa.slice(0, 20).map((row, key) =>
            <tr key={key} id={ row.id } onDoubleClick={() => this.addon_open(row) }>
                <td>{ row.id }</td>
                <td className=""><Edit_Txt text={ row.text.cdi2txt() } getValue={(e)=> this.addon_save(row, e) } /></td>
                <td>{ row.status }</td>
            </tr>
            )}
            </tbody>
        </table>
    </div>
    <div className="flex flex-row">
        <table className="table table-bordered table-hover tb">
            <thead>
            <tr>
                <th>ID</th>
                <th className="flex">Text</th>
                <th>Status</th>
            </tr>
            </thead>
            <tbody style={ this.tb_style() }>
            { this.state.taa.slice(20, 40).map((row, key) =>
            <tr key={key} id={ row.id } onDoubleClick={() => this.addon_open(row) }>
                <td>{ row.id }</td>
                <td className=""><Edit_Txt text={ row.text.cdi2txt() } getValue={(e)=> this.addon_save(row, e) } /></td>
                <td>{ row.status }</td>
            </tr>
            )}
            </tbody>
        </table>
    </div>
    <div className="flex flex-row">
        <table className="table table-bordered table-hover tb">
            <thead>
            <tr>
                <th>ID</th>
                <th className="flex">Text</th>
                <th>Status</th>
            </tr>
            </thead>
            <tbody style={ this.tb_style() }>
            { this.state.taa.slice(40, 60).map((row, key) =>
            <tr key={key} id={ row.id } onDoubleClick={() => this.addon_open(row) }>
                <td>{ row.id }</td>
                <td className=""><Edit_Txt text={ row.text.cdi2txt() } getValue={(e)=> this.addon_save(row, e) } /></td>
                <td>{ row.status }</td>
            </tr>
            )}
            </tbody>
        </table>
    </div>

    </div>
    </div>

    </div>
  );
    }
}
export default Pos_Addons;

/*
    <MDBTableEditable data={this.state.dda} columns={this.state.cols} striped bordered hover />

*/