import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../../lib/net";
import Fr_Input from "../../../lib/ui/fr_input";
import Fr_Select from "../../../lib/ui/fr_select";
import Fr_Cal from "../../../lib/ui/fr_cal";
import Wnd_Error from "../../../lib/ui/wnd_error";
import Wnd_Ok from "../../../lib/ui/wnd_ok";
import Wnd_Info from "../../../lib/ui/wnd_info";
import Wnd_Confirm from "../../../lib/ui/wnd_confirm";
import * as utils from "../../../assets/utils";

class KFakt_Add extends React.Component {
  constructor(props, context) {
    super(props);

    this.bcs = props.store.bcs;
    this.state = {};
    this.state.mka = [];

    this.state.gnr = "001";
    //this.state.lro = props.lro;
      this.state.ynq = [
          {id: '0', text: 'NEJ'},
          {id: '1', text: 'JA'}
      ];
      this.state.sval = [
          {id: '0', text: 'REGISTRERAT'},
          {id: '1', text: 'BEARBETAS'},
          {id: '3', text: 'VÄNTAR'},
          {id: '5', text: 'KLAR'},
          {id: '8', text: 'FAKTURERAD'},
          {id: '9', text: 'AVSLUTAD'}
      ];
      this.state.preg = [
          {id: '00000', text: 'EJ TILLDELAD'},
          {id: '00001', text: 'Joakim'},
          {id: '00002', text: 'Daniel'},
          {id: '00003', text: 'Stellan'},
          {id: '00004', text: 'Tobias'},
          {id: '00005', text: 'Emmanuel'},
          {id: '00006', text: 'Kari'}
      ];
      this.state.mval = [
          {id: '0000', text: 'MOMSFRITT'},
          {id: '0600', text: 'MOMS 6%'},
          {id: '1200', text: 'MOMS 12%'},
          {id: '2500', text: 'MOMS 25%'}
      ];

    this.state.wnds = {};
    this.state.msg = {};
    this.state.fko = {};
    this.state.fko.status = "";

      this.state.fakdat = "";
      this.state.ffdat = "";
      this.state.betdat = "";
    this.state.ktxt = "";
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      this.fak_init();
  }

    fak_init() {
        var fko = {};
        fko.f0bastyp = "K";
        fko.f0faknr = "";
        fko.f0kid = "";
        fko.f0company = "";
        fko.f0text = "";
        fko.f0belopp = "";
        fko.f0moms = "2500";
        fko.f0fakdatum = "";
        fko.f0ffdatum = "";
        fko.f0betdatum = "";
        //alert(JSON.stringify(rso));
        this.setState({ fko: fko });
        return;
    }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }

    wnd_close = () => {
      if(this.props.close) {
          this.props.close(this.props.ctx);
      }
      else this.props.ctx.setState({ wnds: {} });
	};
    mval_change (e) {
        const value = e.target.value;
        var fko = this.state.fko;
        fko.f0moms = value;
        this.setState({fko: fko});
    }
    fdata_change (e) {
        const value = e.target.value;
        var fko = this.state.fko;
        fko.fdata = value;
        this.setState({fko: fko});
    }

    val_fakdat_save(val) {
        //var tdo = this.state.tdo;
        //tdo.due = val;
        this.setState({ fakdat: val });
        return;
    }
    val_ffdat_save(val) {
        //var tdo = this.state.tdo;
        //tdo.due = val;
        this.setState({ ffdat: val });
        return;
    }
    val_betdat_save(val) {
        //var tdo = this.state.tdo;
        //tdo.due = val;
        this.setState({ betdat: val });
        return;
    }
    val_dt_save(val) {
        var fko = this.state.fko;
        fko.due = val;
        this.setState({ fko: fko });
        return;
    }
    val_save(id, val) {
        var fko = this.state.fko;
        fko[id] = val;
        this.setState({ fko: fko });
        return;
    }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
    stat_style(sval) {
        var css = {};
        var status = this.state.tdo.status.toString().tonum(1);
        if(sval == status) {
            css.background = "#080";
            css.color = "#fff";
        }
        return css;
    }

    fak_add() {
    var self = this;
    var fko = this.state.fko;

    fko.f0belopp = fko.f0belopp.pris2cdi();
    fko.f0fakdatum = this.state.fakdat.replaceAll("-", "");
    fko.f0ffdatum = this.state.ffdat.replaceAll("-", "");
    fko.f0betdatum = this.state.betdat.replaceAll("-", "");

    var prm = {};
    prm.req = "bfs.kfak.fak_add";
    prm.fko = fko;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));
        if(ret.ok == "000") {
            self.setState({ msg: {ok: true, text: "Sparad ..."} });
            setTimeout(function(){
                self.setState({msg: {} });
                if(self.props.wnds.cb) {
                    self.props.wnds.cb(self.props.ctx);
                }
                else self.props.ctx.setState({ wnds: {} });
                return;
            }, 1000);
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  fak_del() {
    var self = this;
    var prm = {};
    prm.req = "bfs.kfak.fak_del";
    prm.fko = this.state.fko;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));
        if(ret.ok == "000") {
            self.setState({ msg: {ok: true, text: "Borttagen ..."} });
            setTimeout(function(){
                self.setState({msg: {} });
                if(self.props.close) {
                    self.props.close(self.props.ctx);
                }
                else self.props.ctx.setState({ wnds: {} });
                return;
            }, 1000);
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
    sz_kid() {
        var self = this;
        var wnds = {};
        wnds.szkid = true;
        wnds.tdo = this.state.tdo;
        wnds.cb = self.cb_kid;
        this.setState({ wnds: wnds });
    }
    cb_kid(ctx, rsp) {
        //alert(JSON.stringify(rsp));
        if(rsp.ok == "000") {
            var kko = rsp.kko;
            var tdo = ctx.state.tdo;
            tdo.kid = kko.kk00.kid;
            var key = "K" + tdo.kid;
            var ktxt = "";
            if(ctx.bcs.kro[key]) {
                ktxt = ctx.bcs.kro[key].kk00.ftg;
            }
            ctx.setState({ wnds: {}, tdo: tdo, ktxt: ktxt });
            return;
        }
        ctx.setState({ wnds: {} });
        //ctx.tasks();
        //if(ctx.state.tdo.kid) ctx.state.ktxt = ctx.bcs.kro[this.state.tdo.kid];
    }
    set_status(status) {
        var tdo = this.state.tdo;
        tdo.status = status;
        this.setState({tdo: tdo});
    }
    stat_txt(status) {
        var text = "";
        status = status.toString().tonum(1);
        if( status == '0' )  text = 'REGISTRERAD';
        if( status == '1' )  text = 'BEARBETAS';
        if( status == '3' )  text = 'VÄNTAR';
        if( status == '5' )  text = 'KLAR';
        if( status == '7' )  text = 'FAKTURERAD';
        if( status == '9' )  text = 'AVSLUTAD';
        return text;
    }

  render() {
    //if(!this.props.show) return "";
    var title = this.props.title;
    if(!title) title = "Resurs";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        <div className="m2_backdrop" />
        <div className="m2_dlg mx_90 flex-col" style={ this.dlg_style() } >
        <div className="mx_content flex">

        <div className="mx_h01">
            <div>REGISTRERA KUNDFAKTURA</div>
            <div className="flex"></div>
            <div onClick={() => this.wnd_close() }>X</div>
        </div>
            <div className="flex-row" style={{padding:"5px",height:"35px"}}>
                    <div className="flex-col flex">
                       </div>
                    <div className="flex-col">
                        <div>UPPDATERA</div>
                    </div>
            </div>
            <div className="divider_orange"></div>

            <form className="cdi_f03">

                <div className="txt_box">
                <div className="flex-row">
                    <Fr_Input label="ExtFakt Nr" className="text-left w250" text={this.state.fko.f0extfaknr} getValue={(e)=> this.val_save("f0extfaknr", e) } />
                    <Fr_Input label="Kund ID" className="text-left w250" text={this.state.fko.f0kid} getValue={(e)=> this.val_save("f0kid", e) } />
                </div>
                    <div className="flex-row">
                        <Fr_Input label="Företag" className="text-left w350" text={this.state.fko.f0company} getValue={(e)=> this.val_save("f0company", e) } />
                    </div>
                    <div className="flex-row">
                        <Fr_Input label="Text" className="text-left w350" text={this.state.fko.f0text} getValue={(e)=> this.val_save("f0text", e) } />
                    </div>
                </div>
                <div className="divider_green"></div>

                <div className="txt_box">
                    <div className="flex-row">
                        <Fr_Cal label="Faktura datum" className="text-left w200" text={this.state.fakdat} getValue={(e)=> this.val_fakdat_save( e ) }/>
                        <Fr_Cal label="Förfallodatum datum" className="text-left w200" text={this.state.ffdat} getValue={(e)=> this.val_ffdat_save( e ) }/>
                    </div>
                    <div className="flex-row">
                        <Fr_Input label="Belopp" className="text-right w250" text={this.state.fko.f0belopp} getValue={(e)=> this.val_save("f0belopp", e) } />
                        <div className="form-group flex-row flex">
                            <span className="header">Moms</span>
                            <select value={this.state.fko.f0moms} className="form-control w250" onChange={(e) => this.mval_change(e)}>
                                { this.state.mval.map((row, key) =>
                                    <option key={key} value={row.id}>{row.text}</option>
                                )}
                            </select>
                        </div>
                    </div>
                    <div className="flex-row">
                        <Fr_Cal label="Betal datum" className="text-left w200" text={this.state.betdat} getValue={(e)=> this.val_betdat_save( e ) }/>
                        <Fr_Input label="Status" className="text-left w150" text={this.state.fko.f0status} getValue={(e)=> this.val_save("f0status", e) } />
                    </div>
                </div>

                <div className="divider_green"></div>
                <div className="flex-row">
                    <div className="flex flex-column" style={{padding: "0px 5px"}}>
                        <span className="header">Faktura information</span>
                        <textarea className="form-control" maxLength="512" rows="10" value={this.state.fko.fdata} onChange={(e) => this.fdata_change(e)}></textarea>
                    </div>
                </div>
            </form>

        </div>
        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250 ripple" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex-row">
            <button className="btn btn-hw-red w250 ripple" onClick={() => this.fak_add() } >
                SPARA
            </button>
            </div>
        </div>
      </div>

        <Wnd_Error msg={this.state.msg} ctx={this} />
        <Wnd_Ok msg={this.state.msg} ctx={this} />
        <Wnd_Info msg={this.state.msg} ctx={this} />
        <Wnd_Confirm msg={this.state.msg} ctx={this} />
    </div>
	;
	return html;
  };
}

export default KFakt_Add;