import React, { useState, useEffect, useContext } from 'react';
import * as net from "../../../lib/net";
import {format, subDays} from "date-fns";

import PdfRpDlg from "../fkn/pdf_rp_dlg";
import Fr_Input from "../../../lib/ui/fr_input";
import Dt_Cal from '../../../lib/ui/dt_cal';
import Dt_Tid from '../../../lib/ui/dt_tid';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Cred_Dlg extends React.Component {
  constructor(props, context) {
    super(props);

      this.dt_fd = React.createRef();
      this.dt_td = React.createRef();
      this.dt_fhm = React.createRef();
      this.dt_thm = React.createRef();

    this.pos = props.store.pos;
    this.state = {};
    this.state.today = new Date();
    this.state.lsa = [];
    this.state.lso = {};
    //this.state.lro = props.lro;
    this.state.tya = [
                {id: '01', text: 'Endast Betalningar'},
                {id: '02', text: 'Per Produkt'},
                {id: '03', text: 'Per Varugrupp'},
                {id: '04', text: 'Vald Listgrupp'}
            ];
    this.state.cols = {};

    this.state.favd = "";
    this.state.tavd = "";
      this.state.fdat = "";
      this.state.tdat = "";
      this.state.fhm = "";
      this.state.thm = "";
    this.state.typ = "01";
    this.state.pdf = 0;
    this.state.pdf_content = null;
    this.state.loading = 0;
    this.state.listgrp = "";

  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.dlg_init();
  }

  dlg_init() {
      var dat = this.state.today;
      dat = subDays(dat, 1);
      var dts = format(dat, "yyyy-MM-dd");
      var state = this.state;
      state.fdat = dts;
      state.tdat = dts;
      state.fhm = "00:00";
      state.thm = "24:00";
      state.favd = "01";
      state.tavd = "01";
      state.typ = "01";
      state.loading = 0;
    this.setState(state);
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    tab_style(tabid) {
      var css = {};
      css.display = "none";
      if(this.state.tabval === tabid) css.display = "block";
      return css;
    }
    tab_head(tabid) {
      var css = {};
      css.background = "#fff";
      css.color = "#000";
      if(this.state.tabval === tabid) {
          css.background = "#080";
          css.color = "#fff";
      }
      return css;
    }
  change_fdat(e) {
    //let cdat = this.state.seldate;
    let cdat = e.value;
    let formatted_date = cdat.getFullYear() + "-" + (cdat.getMonth() + 1).toString().tonum(2) + "-" + cdat.getDate().toString().tonum(2);
    this.setState({fromdate: e.value, fdat: formatted_date});
  }
  change_tdat(e) {
    //let cdat = this.state.seldate;
    let cdat = e.value;
    let formatted_date = cdat.getFullYear() + "-" + (cdat.getMonth() + 1).toString().tonum(2) + "-" + cdat.getDate().toString().tonum(2);
    this.setState({todate: e.value, tdat: formatted_date});
  }
  change_favd(e) {
    //let cdat = this.state.seldate;
    let avd = e.target.value;
    this.setState({favd: avd});
  }
  change_tavd(e) {
    //let cdat = this.state.seldate;
    let avd = e.target.value;
    this.setState({tavd: avd});
  }
    change_listgrp(value) {
        //let cdat = this.state.seldate;
        let listgrp = value;
        this.setState({listgrp: listgrp});
    }
    change_typ(e) {
        //let cdat = this.state.seldate;
        let typ = e.target.value;
        this.setState({typ: typ});
    }
    rp_prn() {
        //this.props.ctx.setState({ mr: false });
        //this.setState({ pdf: 1 });
        this.cred_prn();
    }

    cred_prn() {
        var self = this;

        this.setState({ loading: 1 });

   		var favd = this.state.favd;
   		var tavd = this.state.tavd;
   		var dpa = [];
   		var iavd = favd * 1;
   		var maxavd = tavd * 1;
   		do {
   			var avd = iavd.pad(2);
   			dpa.push( avd );
   			iavd = iavd + 1;
		} while( iavd <= maxavd);

        var prm = {};
     	//prm.req = "pos.pos_rp.bok";
     	prm.req = "pos.pos_rp.rp_kred";
   		prm.fdat = this.state.fdat;
   		prm.tdat = this.state.tdat;
        prm.fhm = this.state.fhm;
        prm.thm = this.state.thm;
        prm.favd = this.state.favd;
        prm.tavd = this.state.tavd;
        prm.listgrp = this.state.listgrp;
   		prm.typ = this.state.typ;
   		prm.dpa = dpa;
        //alert(JSON.stringify(prm));
        //return;

        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret.ok));

            //var uri = "data:application/pdf;base64," + ret.pdf;
            self.setState({pdf: 1, pdf_content: ret.pdf });
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }
    rp_xl() {
        var self = this;

        this.setState({ loading: 1 });

        var favd = this.state.favd;
        var tavd = this.state.tavd;
        var dpa = [];
        var iavd = favd * 1;
        var maxavd = tavd * 1;
        do {
            var avd = iavd.pad(2);
            dpa.push( avd );
            iavd = iavd + 1;
        } while( iavd <= maxavd);

        var prm = {};
        //prm.req = "pos.pos_rp.bok";
        prm.req = "pos.pos_rp.rp_kred_xl";
        prm.fdat = this.state.fdat;
        prm.tdat = this.state.tdat;
        prm.fhm = this.state.fhm;
        prm.thm = this.state.thm;
        prm.favd = this.state.favd;
        prm.tavd = this.state.tavd;
        prm.listgrp = this.state.listgrp;
        prm.typ = this.state.typ;
        prm.dpa = dpa;
        //alert(JSON.stringify(prm));
        //return;

        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret.ok));

            //var rawbuf = ret.buf;
            var rawbuf = ret.data;
            var file = new Blob([(rawbuf)], {type: 'application/octet-stream'});
            var fileURL = window.URL.createObjectURL(file);
            //$scope.fil_object = $sce.trustAsResourceUrl(fileURL);
            var a = document.createElement('a');
            a.href = fileURL;
            var filename = ret.filename;
            if(filename.length < 1) filename = "oms.xlsx";
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            self.setState({ loading: 0 });
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }

    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    handleClose = () => {
		this.props.ctx.setState({ rptyp: "" });
	};

  dt_fdat(e) {
    //alert(JSON.stringify(e));
    var dts = format(e, "yyyy-MM-dd");
    this.setState({ fdat: dts });
  }
  dt_tdat(e) {
      //alert(JSON.stringify(e));
    var dts = format(e, "yyyy-MM-dd");
    this.setState({ tdat: dts });
  }
  oneday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_td.current.updateDt(this.state.fdat);
  }
  addday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_fd.current.updateAdd();
    this.dt_td.current.updateAdd();
  }
  subday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_fd.current.updateSub();
    this.dt_td.current.updateSub();
  }
    change_fhm(value) {
        //let cdat = this.state.seldate;
        let fhm = value;
        this.setState({fhm: fhm});
    }
    change_thm(value) {
        //let cdat = this.state.seldate;
        let thm = value;
        this.setState({thm: thm});
    }
    onehh(e) {
        //alert(JSON.stringify(e));
        //this.setState({ tdat: this.state.fdat });
        this.dt_thm.current.updateTp(this.state.fhm);
    }
    addhm(e) {
        //alert(JSON.stringify(e));
        //this.setState({ tdat: this.state.fdat });
        this.dt_fhm.current.updateAdd();
        this.dt_thm.current.updateAdd();
    }
    subhm(e) {
        //alert(JSON.stringify(e));
        //this.setState({ tdat: this.state.fdat });
        this.dt_fhm.current.updateSub();
        this.dt_thm.current.updateSub();
    }
  pdf_done (ret) {
    this.setState({loading: 0, pdf: 0, pdf_content: null });
  }

  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  body_style() {
      var css = {};
      css.display = "";
      if( this.state.loading == 1 ) css.display = "none";
      return css;
  }
  cssCols (cid) {
      var css = {};
      if(this.state.cols[cid]) css.width = this.state.cols[cid].toString() + "px";
      //if(cid == "c01") alert(JSON.stringify(this.state.cols[cid]));
      return css;
  }

  render() {
    if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "KASSÖRSRAPPORT";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="mx_backdrop" /> : null }
        <div className="mx_dlg mx_rp flex-col" style={ this.dlg_style() }>
        <div className="mx_head mx_bg flex-row flex-space-between">
            <div className="flex-col flex">
                <div>Kassörs Rapport</div>
            </div>
            <div className="white-icon-close" onClick={() => this.handleClose() }>X</div>
        </div>
        { this.state.loading ? <div className="mx_content flex align-items-center justify-content-center"><FontAwesomeIcon icon="spinner" spin size="3x" fixed /></div> :
        null }
        <div className="mx_content flex" style={ this.body_style() }>
            <div className="flex-row px-2">
                    <div className="flex-col flex">
                        <div>Start</div>
                    </div>
                    <div className="flex-col flex">
                        <div></div>
                    </div>
                    <div className="flex-col w50">
                        <div>Ändra</div>
                    </div>
            </div>
            <div className="divider_orange"></div>

        <div className="mx_body flex">

            <div className="flex-row">
                <div className="flex-col">
                    <span>&nbsp;</span>
                    <div className="dt-btn-01" onClick={() => this.subday() } >-</div>
                </div>
                <div className="flex-col">
                    <span>Från</span>
                    <Dt_Cal startdatum="2020-02-01" rp="1" ref={this.dt_fd} getValue={(e)=> this.dt_fdat(e)} />
                </div>
                <div className="flex-col">
                    <span>&nbsp;</span>
                    <div className="dt-btn-01" onClick={() => this.oneday() } >1</div>
                </div>
                <div className="flex-col">
                    <span>Till</span>
                    <Dt_Cal startdatum="2020-02-01" rp="1" ref={this.dt_td} getValue={(e)=> this.dt_tdat(e)} />
                </div>
                <div className="flex-col">
                    <span>&nbsp;</span>
                    <div className="dt-btn-01" onClick={() => this.addday() } >+</div>
                </div>
            </div>

            <div className="flex-row">
                <div className="flex-col">
                    <span>&nbsp;</span>
                    <div className="dt-btn-01" onClick={() => this.subhm() } >-</div>
                </div>
                <div className="flex-col">
                    <span>Från</span>
                    <Dt_Tid tps="00:00" rp="1" ref={this.dt_fhm} text={this.state.fhm} getValue={(e)=> this.change_fhm(e) } />
                </div>
                <div className="flex-col">
                    <span>&nbsp;</span>
                    <div className="dt-btn-01" onClick={() => this.onehm() } >1</div>
                </div>
                <div className="flex-col">
                    <span>Till</span>
                    <Dt_Tid tps="24:00" rp="1" ref={this.dt_thm} text={this.state.thm} getValue={(e)=> this.change_thm(e) } />
                </div>
                <div className="flex-col">
                    <span>&nbsp;</span>
                    <div className="dt-btn-01" onClick={() => this.addhm() } >+</div>
                </div>
            </div>

            <div className="flex-row">
              <div className="flex-col">
                  <span>Från</span>
        <select className="form-control input-rp-01 w250" placeholder="Från avdelning" onChange={(e) => this.change_favd(e)}>
            { this.pos.deps.map((row, key) =>
            <option key={key} value={row.avd}>{row.avd} - {row.text.toswe()}</option>
            )}
        </select>
            </div>
              <div className="flex-col">
                  <span>Till</span>
        <select className="form-control input-rp-01 w250" placeholder="Till avdelning" onChange={(e) => this.change_tavd(e)}>
            { this.pos.deps.map((row, key) =>
            <option key={key} value={row.avd}>{row.avd} - {row.text.toswe()}</option>
            )}
        </select>
            </div>
            </div>

            <div className="flex-row">
                <div className="flex-col">
                    <Fr_Input col label="Listgrupp" className="input-rp-01 text-center w150" text={this.state.listgrp} getValue={(e)=> this.change_listgrp(e) } maxLength="3" />
                </div>
            </div>
            <div className="flex-row">
                <div className="flex-col">
                    <span>Typ</span>
                    <select className="form-control input-rp-01 w250" placeholder="Typ" onChange={(e) => this.change_typ(e)}>
                        { this.state.tya.map((row, key) =>
                            <option key={key} value={row.id}>{row.text}</option>
                        )}
                    </select>
                </div>
            </div>


        </div>

        </div>


        <div className="mx_foot flex-row">
            <div className="flex-row flex">
                <div className="btn-rp" onClick={() => this.handleClose() } >
                    <div>STÄNG</div>
                    <div><FontAwesomeIcon icon="times" size="2x" /></div>
                </div>
                <div className="btn-rp" onClick={() => this.rp_xl() } >
                    <div>EXCEL EXPORT</div>
                    <div><FontAwesomeIcon icon="download" size="2x" /></div>
                </div>
            </div>
            <div className="flex-row">
                <div className="btn-rp" onClick={() => this.rp_mx() } >
                    <div>EPOST</div>
                    <div><FontAwesomeIcon icon="at" size="2x" /></div>
                </div>
                <div className="btn-rp" onClick={() => this.rp_prn() } >
                    <div>SKRIV UT</div>
                    <div><FontAwesomeIcon icon="print" size="2x" /></div>
                </div>
            </div>
        </div>
      </div>
        <PdfRpDlg show={this.state.pdf} pdf={this.state.pdf_content} done={(ret) => this.pdf_done(ret)} backdrop={true}/>
    </div>
	;
	return html;
  };
}

export default Cred_Dlg;