import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";

import Rp_EeTp from "./dlg/rp_ee_tp";
import Rp_PerTp from "./dlg/rp_per_tp";
import Rp_PerSum from "./dlg/rp_per_sum";
import Rp_Perslist from "./dlg/rp_perslist";
import Rp_Export from "./dlg/rp_export";

class Ta_Rps extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = {};
    this.state.seldate = new Date();
    this.state.avd = "01";
    this.state.paa = [];
    this.state.aa = [];

    this.state.pdf = 0;
    this.state.ee = 0;
    this.state.th = 0;
    this.state.bok = 0;
    this.state.xrecov = 0;

      this.state.mna = [];
      this.state.mna.push({ mnid: "mn01", pos: "c1", text: "TIDRAPPORT ENSKILD", desc: "Tidrapport Enskild", link: "ee_tp" });
      this.state.mna.push({ mnid: "mn02", pos: "c1", text: "TIDRAPPORT ALLA", desc: "Tidrapport Period", link: "per_tp" });
      this.state.mna.push({ mnid: "mn03", pos: "c1", text: "PERIOD RAPPORT", desc: "Rapport för perioden", link: "per_sum" });
      this.state.mna.push({ mnid: "mn04", pos: "c1", text: "KONTROLLRAPPORT", desc: "Kontrollrapport för myndighet", link: "ctrl_rp" });
      this.state.mna.push({ mnid: "mn05", pos: "c2", text: "PERSONAL LISTA", desc: "Personal aktiva/avslutade", link: "perslist" });
      this.state.mna.push({ mnid: "mn06", pos: "c2", text: "TRANSAKTIONER", desc: "Transaktioner period", link: "trs" });
      this.state.mna.push({ mnid: "mn07", pos: "c2", text: "EXPORT LÖNESYSTEM", desc: "Export Lönesystem", link: "export" });
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 480;
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }
    mnc(pos) {
        var mna = [];
        for(var row of this.state.mna) {
            if(row.pos == pos) mna.push( row );
        }
        return mna;
    }
  rp_open(typ) {
    var self = this;
    this.setState({ rptyp: typ });
  }

    render() {
        return (
            <div className="web-app">
                <div className="web-pg py-4 flex">
                    <h3>RAPPORTER</h3>
                    <p>RAPPORTER funktioner</p>

                    <div className="divider_green"></div>

                    <div className="web-row">
                        <div className="flex-col flex">
                            { this.mnc("c1").map((row, key) =>
                                <div key={key} className="web-bx" onClick={() => this.rp_open(row.link) }>
                                    <div>{ row.text }</div>
                                    <div className="flex-row">
                                        <div className="flex">{ row.desc }</div>
                                        <div className="web-link">Välj</div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="flex-col flex">
                            { this.mnc("c2").map((row, key) =>
                                <div key={key} className="web-bx" onClick={() => this.rp_open(row.link) }>
                                    <div>{ row.text }</div>
                                    <div className="flex-row">
                                        <div className="flex">{ row.desc }</div>
                                        <div className="web-link">Välj</div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                </div>

                { this.state.rptyp == "ee_tp" ? <Rp_EeTp show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
                { this.state.rptyp == "per_tp" ? <Rp_PerTp show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
                { this.state.rptyp == "per_sum" ? <Rp_PerSum show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
                { this.state.rptyp == "perslist" ? <Rp_Perslist show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
                { this.state.rptyp == "export" ? <Rp_Export show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
            </div>
        );
    }
}
export default Ta_Rps;
