import React, {useState} from 'react';

import { observer } from 'mobx-react';
import { useStore } from '../../sdo.js'
import ddo from "../../ddo";
import sso from "../../sso";

import * as net from "../../lib/net";
import Dd_Sel from "../../lib/ui/dd_sel";
import Pms_Term from "../op/fkn/rf_gst";
import {useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Tiden from "../../lib/ui/tiden";

function Pms_Foot(props) {
    var navigate = useNavigate();
    let [state, setState] = useState({wnds: {}, msg: {}, modal: false, ma: []});
    let ctx = useStore();

    var seo = props.store.seo;


    var selitems = [
        {fkn: 'home', text: 'HEM'},
        {fkn: 'setup', text: 'INSTÄLLNINGAR'},
        {fkn: 'upd', text: 'UPPDATERA'},
        {fkn: 'sys', text: 'SYSTEM'},
        {fkn: 'logout', text: 'LOGGA UT'}
    ];
    var btmitems = [
        {fkn: 'kreg', text: 'KUNDREGISTER'},
        {fkn: 'logg', text: 'LOGG'},
        {fkn: 'hist', text: 'HISTORIK'}
    ];
    var langitems = [
        {fkn: 'sv', text: 'SVENSKA'},
        {fkn: 'no', text: 'NORSKA'},
        {fkn: 'en', text: 'ENGELSKA'}
    ];

      const goFull = () => {
        alert("Full");
      };

    function toggle() {
    };
    function logout() {
        var url = "/logout";
        navigate(url);
    };

    function acs() {
        var wnds = {};
        wnds.acs = 1;
        props.ctx.setState({ wnds: wnds });
    };
    function stat() {
        var wnds = {};
        wnds.stat = 1;
        props.ctx.setState({ wnds: wnds });
    };
    function bel() {
        var wnds = {};
        wnds.bel = 1;
        props.ctx.setState({ wnds: wnds });
    };
    function term() {
        var wnds = {};
        wnds.term = 1;
        props.ctx.setState({ wnds: wnds });
    };
      const mnu_toggle = () => {
		//props.ctx.setState({ mnu_show: !props.ctx.state.mnu_show });
		props.store.cfg.mnu_show = !props.store.cfg.mnu_show;
      };
      const bread_toggle = () => {
		props.store.cfg.bread = !props.store.cfg.bread;
      };
      const mnu_item_css = () => {
      };
      const mnu_sel = () => {
      };
      const login = () => {
      };

      function fknGo(url) {
        //alert(url);
          navigate(url);
      }
    function sel_btm() {
    }

    function rvn_open() {
        sso.wnds.rvn = 1;
    }
    function sz_open() {
        sso.wnds.szw = 1;
    }
    function rr_open() {
        sso.wnds.rr = 1;
    }
    function petty_open() {
        sso.wnds.petty = 1;
    }

    function sel_lang() {
    }
    function wrk_period() {
        sso.wnds.hotback = 1;
    }
    function wrk_history() {
        sso.wnds.hotreg = 1;
    }
    
function sel_fkn(sel) {
    //alert(JSON.stringify(fkn));
      if(sel.fkn == "home") {
        var url = "/hello";
          navigate(url);
      }
      if(sel.fkn == "logout") {
        var url = "/logout";
          navigate(url);
      }
  }
  const header_class = () => {
    var cls = "flex_col flex";

      if(false) {
        cls = cls + " web-head_1";
      }
      //else cls = cls +  " web-header";


      return cls;
  }

    var html =
    <div className={ header_class() }>
    <div className="web-foot">
        <div className="web-foot-mnu px-3" onClick={() => mnu_toggle() }>
            <FontAwesomeIcon icon="bars" size="1x"/>
        </div>
        <div className="web-foot-mnu" onClick={() =>  rvn_open() } title="NY BOKNING">
            <FontAwesomeIcon icon={["far", "plus"]} size="1x"/>
        </div>
        <div className="web-foot-mnu" onClick={() => fknGo("/pms/fo/rvp") } title="PRELIMINÄRA BOKNINGAR">           
            <div className=""><FontAwesomeIcon icon="diagram-previous" size="1x"/></div>
        </div>
        <div className="web-foot-mnu" onClick={() => fknGo("/pms/fo/rvl") } title="SENASTE BOKNINGAR">
            <FontAwesomeIcon icon={["far", "book-open"]} size="1x"/>
        </div>
        <div className="web-foot-mnu" onClick={() => sz_open() } title="SÖK">
            <FontAwesomeIcon icon="search" size="1x"/>
        </div>

        <div className="flex"></div>
        <div className="web-foot-btn hide" onClick={() => wrk_period() } >Arbets Period</div>
        <Dd_Sel items={ btmitems } getValue={(e)=> sel_btm(e)} />
        <div className="web-foot-btn hide" onClick={() => wrk_history() } >Historik</div>
        

        <div className="flex"></div>

        <div className="flex flex-row hide">
            <div className="flex"></div>

            <div className="web-foot-btn" onClick={() => fknGo("/pms/op/ank") } >ANKOMMANDE</div>
            <div className="web-foot-btn" onClick={() => fknGo("/pms/op/ci") }>CHECKIN</div>
        </div>
        <div className="flex flex-row hide">
            <div className="web-foot-btn" onClick={() => fknGo("/pms/op/inh") }>BOENDE</div>
            <div className="web-foot-btn" onClick={() => fknGo("/pms/op/avr") }>AVRESANDE</div>

            <div className="flex"></div>
        </div>

        <div className="web-foot-txt" onClick={() => wrk_history() } >
            <div className="txt_10">Funktion</div>
            <div className="txt_14">{ props.store.fkn.name }</div>
        </div>  

        <div className="web-foot-rmnu" onClick={() => rr_open() } title="ROOMRACK">
            <FontAwesomeIcon icon={["far", "bed-bunk"]} size="1x"/>
        </div>
        <div className="web-foot-mnu" onClick={() => petty_open() } title="PETTY CASH">
            <FontAwesomeIcon icon={["far", "shopping-cart"]} size="1x"/>
        </div>
        <div className="web-foot-rmnu hide" >
            <Dd_Sel items={ langitems } getValue={(e)=> sel_lang(e)} />
        </div>
        <div className="web-foot-mnu web-rows">
            <div className="txt_8">Termnr</div>
            <div className="txt_16">{ sso.seo.termnr }</div>
        </div>

        <Tiden />
    </div>

    </div>
    ;
    return html;
}

export default observer(Pms_Foot);
