import React, { useState, useEffect, useContext } from 'react';

import ddo from "../../../ddo";
import * as net from "../../../lib/net";
import Wnd_Ok from "../../../lib/ui/wnd_ok";
import Ki_Nota from "./ki_nota";
import Fr_Input from "../../../lib/ui/fr_input";

class Ki_Sz extends React.Component {
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.state = {};
    this.state.bqa = [];
    this.state.kia = [];
    this.state.msg = {};
      this.state.wnds = {};
      this.state.kro = {};
      this.state.sztxt = "";
      this.state.sortid = "";
      this.state.sortdir = 0;
   }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      //this.bqn();
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    bq_close() {
        this.props.ctx.setState({ wnds: {} });
    };
    tb_style() {
      var css = {};
      css.height = (window.innerHeight * 0.8) - 215;
        css.overflow = "auto";
      return css;
    }
    th_style(id) {
        var css = {};
        css.cursor = "pointer";
        if(id == this.state.sortid) css.color = "#080";
        return css;
    }

    ki_sz() {
        var self = this;
        var prm = {};
        prm.req = "pos.pos_ki.ki_sz";
        prm.sztxt = this.state.sztxt;

        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret));
            if(ret.ok != "000") {
                return;
            }
            self.setState({kia: ret.rca });
            self.syncTb("szTable");
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }
    show_bong(row) {
    //alert(JSON.stringify(row));
      var self = this;
      var wnds = {};
      wnds.bong = true;
      wnds.row = row;
      wnds.backdrop = true;
      self.setState({wnds: wnds });
    }
    row_bong(row) {
        var self = this;
        var prm = {};
        prm.req = "pos.pos_bq.bongrow";
        prm.row = row;
        prm.status = "09";

        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret.rca));

            //self.setState({bqa: ret.rca });
            //toastr.success("OK", "SAPI");
            var msg = { ok: true, text: "BONGAD ..." };
            self.setState({msg: msg });
            setTimeout(function(){
                self.setState({msg: {} });
            }, 1000);
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }
    syncTb(tb){
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
        tdElements = tbel.getElementsByTagName('td');

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
            width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
    val_sz (value) {
        //alert(JSON.stringify(name));
        //alert(JSON.stringify(value));
        this.setState({ sztxt: value } );
    }
    LevShow(row) {
        var self = this;
        var ktxt = "";
        var kid = row.lev.trim();
        ktxt = kid;
        //alert(JSON.stringify(kid));
        if(ddo.cache.levs && ddo.cache.levs[kid]) {
            var o = ddo.cache.levs[kid];
            ktxt = kid + " - " + o.name.toswe();
        }
        return ktxt;
    }
    tb_sort(typ) {
        var self = this;
        var kia = self.state.kia;
        var sz = this.state.sz_val;
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;

        if(sortid != typ) sortdir = 0;
        else {
            if(sortdir) sortdir = 0;
            else sortdir = 1;
        }

        if(true) {
            //if(sortdir) kia.sort((a,b) => (a.wfh[f1][f2] < b.wfh[f1][f2]) ? 1 : ((b.wfh[f1][f2] < a.wfh[f1][f2]) ? -1 : 0));
            //else kia.sort((a,b) => (a.wfh[f1][f2] > b.wfh[f1][f2]) ? 1 : ((b.wfh[f1][f2] > a.wfh[f1][f2]) ? -1 : 0));
            if(sortdir) kia.sort((a,b) => (a[typ] < b[typ]) ? 1 : ((b[typ] < a[typ]) ? -1 : 0));
            else kia.sort((a,b) => (a[typ] > b[typ]) ? 1 : ((b[typ] > a[typ]) ? -1 : 0));
        }
        self.setState({ kia: kia, sortid: typ, sortdir: sortdir });
    }
    show_nota(row) {
        var self = this;
        var wnds = {};
        wnds.nota = true;
        wnds.kio = row;
        wnds.backdrop = true;
        self.setState({wnds: wnds });
    }

  render() {

    var title = this.props.title;
    if(!title) title = "Titel";

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="m2_backdrop" /> : null }
        <div className="m2_dlg mx_80w flex-col" style={ this.dlg_style() }>
        <div className="flex-row " style={{padding:"0px",height:"50px",lineHeight:"50px", background: "#1a456b", color: "#fff"}}>
                <div className="flex-row flex" style={{padding:"0px 5px", fontSize: "22px"}}>
                    <div>SÖK NOTA</div>
                </div>
            <div className="wnd-close-btn" onClick={() => this.bq_close() }>X</div>
        </div>
        <div className="mx_content flex">

        <div className="divider_orange"></div>

            <div className="flex-row">
                <div className="px-2">Text:</div>
                <Fr_Input className="text-left w350" text={this.state.sztxt} getValue={(e) => this.val_sz(e) } />
                <button className="btn btn-hw-green w150" onClick={() => this.ki_sz() } aria-label="System" >
                    Sök
                </button>
            </div>
            <div id="szTable">
            <table className="table table-bordered table-hover tb fixed_header">
                <thead>
                <tr>
                    <th style={ this.th_style("datum") } onClick={() => this.tb_sort("datum") }>Datum</th>
                    <th style={ this.th_style("notanr") } onClick={() => this.tb_sort("notanr") }>Notanr</th>
                    <th style={ this.th_style("lev") } onClick={() => this.tb_sort("lev") }>Lev</th>
                    <th style={ this.th_style("text") } onClick={() => this.tb_sort("text") }>Text</th>
                    <th style={ this.th_style("status") } onClick={() => this.tb_sort("status") }>Status</th>
                </tr>
                </thead>
                <tbody style={ this.tb_style() }>
                { this.state.kia.map((row, key) =>
                <tr key={key} onDoubleClick={() => this.show_nota(row) }>
                    <td>{ row.datum.cdidate() }</td>
                    <td>{ row.notanr }</td>
                    <td>{ this.LevShow(row) }</td>
                    <td>{ row.text }</td>
                    <td>{ row.status }</td>
                </tr>
                )}
                </tbody>
            </table>
            </div>

        </div>

        <div className="mx_foot flex-row">
            <button className="btn btn-hw-red w250" onClick={() => this.bq_close() } >
                STÄNG
            </button>
            <div className="flex"></div>
            <button className="btn btn-hw-green w250" onClick={() => this.bqn() } >
                UPPDATERA
            </button>
        </div>
      </div>
        { this.state.wnds.nota ? <Ki_Nota wnds={this.state.wnds} ctx={this} /> : null}
        <Wnd_Ok msg={this.state.msg} ctx={this} />
    </div>
	;
	return html;
  };
}

export default Ki_Sz;