import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class SHD_Prs extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, tra: [] };
    this.state.seldate = new Date();
    this.state.avd = "01";
    this.state.eaa = [];
    this.state.kro = {};
    this.state.aa = [];
    this.state.cols = {};
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.prs();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
    tb_style() {
        var css = {};
        css.height = window.innerHeight - 275;
        css.overflow = "auto";
        return css;
    }
    th_style(id) {
        var css = {};
        css.cursor = "pointer";
        if(id == this.state.sortid) {
            if(this.state.sortdir) css.color = "#800";
            else css.color = "#080";
        }
        return css;
    }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

  prs() {
    var self = this;
    var prm = {};
    prm.req = "ta.ta_ee.shd_per";
    //prm.avd = this.state.avd;
    //prm.fplu = datum;
    //prm.tplu = datum;

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        var o = {};
        //o.deps = ret.rco;
        //alert(JSON.stringify(o));
        self.setState({eaa: ret.rca }, function() {
            self.syncTb("prsTable");
        });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }
    syncTb(tb) {
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
            tdElements = tbel.getElementsByTagName('td');
        if(tdElements.length < 1 ) return;

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
                width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }

  render() {
    return (
    <div className="web-app">

    <div className="flex-row flex-space-between">
        <div className="label_h03">PERSONAL SCHEMA</div>
        <select className="form-control sel_input" placeholder="Välj avdelning">
            { this.state.aa.map((row, key) =>
            <option key={key} value={row.id}>{row.text}</option>
            )}
        </select>
        <button className="btn btn-hw-blue w150 ripple" onClick={() => this.prs() } aria-label="System" >
            Visa
        </button>
    </div>
    <div className="divider"></div>

        <div id="prsTable" className="flex">
            <table className="table table-bordered table-hover tb " style={{marginBottom: "0px"}}>
                <thead>
                <tr>
                    <th style={ this.th_style("c01" ) }>Anstnr</th>
                    <th style={ this.th_style("c02" ) }>Namn</th>
                    <th style={ this.th_style("c03" ) }>Avd</th>
                    <th style={ this.th_style("c04" ) }>Basschema</th>
                    <th style={ this.th_style("c05" ) }>DT</th>
                    <th style={ this.th_style("c05" ) }>Status</th>
                    <th style={ this.th_style("c06" ) }></th>
                </tr>
                </thead>
            </table>
            <div style={ this.tb_style() }>
                <table className="table table-bordered table-hover tb">
                    <tbody>
                    { this.state.eaa.map((row, key) =>
                        <tr key={key} onDoubleClick={() => this.shd_edit(row) }>
                            <td>{ row.eid }</td>
                            <td>{ row.eeo.p1.namn }</td>
                            <td>{ row.eeo.p1.avdelning }</td>
                            <td>{ row.eeo.p1.arbschema }</td>
                            <td>{ row.xxx }</td>
                            <td>{ row.status }</td>
                            <td style={{width:"40px",cursor:"pointer"}} onClick={() => this.shd_del(row) }><i className="fa fa-cog text-danger"></i></td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
        </div>
   </div>
  );
    }
}
export default SHD_Prs;
