import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";

import Cr_Dlg from "./rps/cr_dlg";
import Crh_Dlg from "./rps/crh_dlg";
import Tr_Dlg from "./rps/tr_dlg";
import Pkat_Dlg from "./rps/pkat_dlg";
import Fsk_Dlg from "./rps/fsk_dlg";
import Saldo_Dlg from "./rps/saldo_dlg";
import Hot_Dlg from "./rps/hot_dlg";
import Scb_Dlg from "./rps/scb_dlg";

class Pms_Rps extends React.Component {
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.state = { pgid: 11, tra: [] };
    this.state.seldate = new Date();
    this.state.avd = "01";
    this.state.paa = [];
    this.state.aa = [];

    this.state.pdf = 0;
    this.state.rptyp = "";
    this.state.mr = 0;
    this.state.bok = 0;
    this.state.xrecov = 0;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 480;
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

  rp_open(typ) {
    var self = this;
    this.setState({ rptyp: typ });
  }
  dr_open() {
      var self = this;
    this.setState({ dr: 1 });
  }
  mr_open() {
      var self = this;
    this.setState({ mr: 1 });
  }
  bok_open() {
      var self = this;
    this.setState({ bok: 1 });
  }
  xrecov_open() {
      var self = this;
    this.setState({ xrecov: 1 });
  }
  plu_open(row) {
      var self = this;
  }
  plu_edit(row) {
      var self = this;
      alert(JSON.stringify(row.plu00));
  }
    pdf_show(id) {
        this.setState({ pdf: id });
        return;
    }

  plus() {
    var self = this;
    var prm = {};
    prm.req = "pos.pos_plu.plus";
    prm.avd = this.state.avd;
    //prm.fplu = datum;
    //prm.tplu = datum;

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        var o = {};
        //o.deps = ret.rco;
        //alert(JSON.stringify(o));
        self.setState({paa: ret.rco });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }

  render() {
    return (
    <div className="web-p00 flex flex-col">
        <div className="">
            HOTELL RAPPORTER
        </div>

    <div className="web-p01 flex">

    <div className="web-row">
    <div className="web-col flex">
        <div className="web-btn" onClick={() => this.rp_open("cr") } >
            <div>KASSARAPPORT</div>
            <div>Kassarapport för perioden</div>
        </div>
        <div className="web-btn" onClick={() => this.rp_open("crh") } >
            <div>KASSARAPPORT STÄNGD</div>
            <div>Kassarapport för stängd period</div>
        </div>
    </div>
     <div className="web-col flex" style={{padding: "0px 10px"}}>
        <div className="web-btn" onClick={() => this.rp_open("tr") } >
            <div>TRANSAKTIONS RAPPORT</div>
            <div>Periodens transaktioner</div>
        </div>
        <div className="web-btn" onClick={() => this.rp_open("pkat") } >
            <div>PRISKATEGORIER</div>
            <div>Periodens priskategorier</div>
        </div>
        <div className="web-btn" onClick={() => this.rp_open("hot") } >
            <div>HOTELLRAPPORT</div>
            <div>Hotellöverföring för perioden</div>
        </div>
   </div>
     <div className="web-col flex">
        <div className="web-btn" onClick={() => this.rp_open("saldo") } >
            <div>SALDOLISTA</div>
            <div>Dagliga saldon</div>
        </div>
        <div className="web-btn" onClick={() => this.rp_open("fsk") } >
            <div>FÖRSKOTT</div>
            <div>Periodens förskott</div>
        </div>
        <div className="web-btn" onClick={() => this.rp_open("scb") } >
            <div>SCB RAPPORT</div>
            <div>SCB för perioden</div>
        </div>
   </div>
   </div>

    </div>

        { this.state.rptyp == "cr" ? <Cr_Dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "crh" ? <Crh_Dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "tr" ? <Tr_Dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "pkat" ? <Pkat_Dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "hot" ? <Hot_Dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "saldo" ? <Saldo_Dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "fsk" ? <Fsk_Dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "scb" ? <Scb_Dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
    </div>
  );
    }
}
export default Pms_Rps;
