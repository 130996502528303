import React, { useState, useEffect, useContext } from 'react';

import Fr_Input from "../../../lib/ui/fr_input";
import * as net from "../../../lib/net";
import Dd_Sel from "../../../lib/ui/dd_sel";

class Scr_Tvflowrow extends React.Component {
  constructor(props, context) {
    super(props);

    this.store = props.store;
    //this.pos = props.store.pos;

    this.state = {};
    this.state.today = new Date();
    this.state.rta = [];
    this.state.pka = [];

      this.state.tvid = "1";
      this.state.scrid = "tv01";
    this.state.gso = {};

    this.state.ynq = [
                {id: '0', text: 'NEJ'},
                {id: '1', text: 'JA'}
            ];

      this.state.screens = [
          {scrid: 'tv01', text: 'TV 01'},
          {scrid: 'tv02', text: 'TV 02'},
          {scrid: 'tv03', text: 'TV 03'},
          {scrid: 'tv04', text: 'TV 04'}
      ];

      this.state.resolution = [
          { id: 'vga', text: 'VGA', w: "1024px", h: "768px" },
          { id: 'hd', text: 'HD Ready 720p', w: "1280px", h: "720px" },
          { id: 'fhd', text: 'FULL HD 1080p', w: "1920px", h: "1080px" },
          { id: 'qhd', text: 'QHD', w: "2560px", h: "1440px" },
          { id: '4k', text: '4K', w: "3840px", h: "2160px" },
          { id: '8k', text: '8K', w: "7680px", h: "4320px" }
      ];

      this.state.tv_width = "1280px";
      this.state.tv_height = "720px";
      this.state.rs = "";
    this.state.tbo = {};
    this.state.background = "#fff";

      //this.state.vsrc = "http://filpub.svepos.se/menytv/tradgard/trad01.mp4";
      this.state.vsrc = "";
    this.state.wnds = {};
    if(props.wnds) {
        this.state.tvid = props.wnds.tvid;
    }
      var tvo = {};
      tvo.data = {};

      tvo.data.c11 = {};
      tvo.data.c11.mnu = {};
      tvo.data.c11.mnu.text = "T11";
      tvo.data.c11.mnu.rows = [];
      tvo.data.c11.mnu.descrows = [];

      tvo.data.c12 = {};
      tvo.data.c12.mnu = {};
      tvo.data.c12.mnu.text = "T12";
      tvo.data.c12.mnu.rows = [];
      tvo.data.c12.mnu.descrows = [];

      tvo.data.c21 = {};
      tvo.data.c21.mnu = {};
      tvo.data.c21.mnu.text = "T21";
      tvo.data.c21.mnu.rows = [];
      tvo.data.c21.mnu.descrows = [];

      tvo.data.c22 = {};
      tvo.data.c22.mnu = {};
      tvo.data.c22.mnu.text = "T22";
      tvo.data.c22.mnu.rows = [];
      tvo.data.c22.mnu.descrows = [];
      //var tvo = props.store.tvo;
      this.state.tvo = tvo;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
      document.removeEventListener("keydown", this.handleKey);
  }
  componentDidMount() {
    document.addEventListener("keydown", this.handleKey);

      //this.init_tv();
      this.dsi();
  }

    handleKey = (e) => {
        e.preventDefault();
        //alert(e.key);
        //alert(e.code);
        if(this.state.wnds.tvcfg == true) return;

        if(e.keyCode == 13) {
            //alert("ENTER MNU");
            this.tv_cfg();
        }
        if(e.keyCode == 112) {
            //alert("F1");
        }
        if(e.keyCode == 113) {
            //alert("F2");
            //this.sz();
        }
        if(e.keyCode == 114) {
            //alert("F3");
        }
        if(e.keyCode == 115) {
            //alert("F4");
        }
        if(e.keyCode == 116) {
            //alert("F5");
        }
        if(e.keyCode == 117) {
            //alert("F6");
        }
        if(e.keyCode == 118) {
            //alert("F7");
        }
        if(e.keyCode == 119) {
            //alert("F8");
        }
        if(e.keyCode == 120) {
            //alert("F9");
        }
        if(e.keyCode == 121) {
            //alert("F10");
        }
        //if(e.keyCode == 122) {alert("F11");} // NOT TRAPPABLE
        if(e.keyCode == 123) {
            //alert("F12");
        }
    }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    wnd_close = () => {
      if(this.props.cb) {
        var ret = {};
        ret.ok = "999";
        this.props.cb(this.props.ctx, ret);
      }
      else this.props.ctx.setState({ wnds: {} });
	};

    show_mnu() {
        return "true";
    }

    css_tv(tvo) {
        var css = {};
        css.position = "relative";
        //css.width = "1024px"; css.height = "768px";     // VGA
        //css.width = "1280px"; css.height = "720px";   // HD
        //css.width = "1920px"; css.height = "1080px";  // FULL HD
        //css.width = "2560px"; css.height = "1440px";  // QHD
        //css.width = "3840px"; css.height = "2160px";  // 4K
        //css.width = "7680px"; css.height = "4320px";  // 8K
        css.width = this.state.tv_width;
        css.height = this.state.tv_height;
        css.margin = "0px auto";
        css.overfow = "hidden";
        return css;
    }
    css_colbody(tvo) {
        var css = {};
        css.position = "relative";
        css.width = this.state.tv_width;
        css.height = this.state.tv_height;
        //css.width = "1024px"; css.height = "768px";     // VGA
        //css.width = "1280px"; css.height = "720px";   // HD
        //css.width = "1920px"; css.height = "1080px";  // FULL HD
        //css.width = "2560px"; css.height = "1440px";  // QHD
        //css.width = "3840px"; css.height = "2160px";  // 4K
        //css.width = "7680px"; css.height = "4320px";  // 8K
        return css;
    }
    css_bg(tvo) {
        var css = {};
        css.position = "absolute";
        css.width = "100%";
        css.height = "100%";
        css.background = "#000";
        css.overfow = "hidden";
        css.zIndex = "-99";
        if(tvo.bg && tvo.bg.bgfarg) {
            if(tvo.bg.bgfarg.length > 0) css.background = tvo.bg.bgfarg;
        }
        return css;
    }

    css_category(tvo) {
        var css = {};
        //css.fontSize = "40px";
        if(tvo.css && tvo.css.category_size) css.fontSize = tvo.css.category_size;
        css.fontWeight = "bold";
        css.textAlign = "center";
        css.marginBottom = "0px";
        css.fontFamily = "Georgia";
        css.color = "#F36E21";
        return css;
    }
    css_desc(tvo) {
        var css = {};
        //css.fontSize = "20px";
        if(tvo.css && tvo.css.desc_size) css.fontSize = tvo.css.desc_size;
        css.textAlign = "left";
        css.color = "#eeeeee";
        return css;
    }

    css_row_text(tvo) {
        var css = {};
        //css.fontSize = "30px";
        if(tvo.css && tvo.css.row_text_size) css.fontSize = tvo.css.row_text_size;
        css.fontWeight = "normal";
        //css.lineHeight = "25px";
        //css.marginBottom = "0px";
        css.color = "#ffffff";
        return css;
    }
    css_row_price(tvo) {
        var css = {};
        css.display = "none";
        //css.fontSize = "30px";
        if(tvo.css && tvo.css.row_price_size) css.fontSize = tvo.css.row_price_size;
        css.fontWeight = "normal";
        //css.lineHeight = "25px";
        css.textAlign = "right";
        css.color = "#ffffff";
        return css;
    }
    css_row_desc(tvo) {
        var css = {};
        //css.width = "80%";
        //css.fontSize = "25px";
        if(tvo.css && tvo.css.row_desc_size) css.fontSize = tvo.css.row_desc_size;
        //css.lineHeight = "25px";
        css.textAlign = "left";
        css.paddingLeft = "5px";
        css.color = "#eeeeee";
        return css;
    }
    cls_cols(mnu) {
        var cls = "";
        cls = "twocolumns";
        //cls = "threecolumns";
        return cls;
    }
    price_text(row) {
        var belopp = row.pris.dec2();
        //belopp = moment().subtract(1, "days").format("YYYY-MM-DD");
        if(belopp) {
            if(belopp.trim().length > 0) belopp = belopp + " kr";
        }
        if(row.type == "INFO") belopp = "";
        return belopp;
    }

    tvo_poll() {
        var self = this;
        setInterval(function(){
            var req = {};
            req.tvid = "tv03";
            req.tid = "";
            req.ip = "";

            //alert(JSON.stringify(req));
            //self.get_tvo();
            //console.log("REQ SENT : " + util.inspect(req.fkn));
        }, 5000);
    }
    init_bg(farg) {
        var self = this;
        var bg = document.getElementById("myBg");
        bg.style.opacity = "1.0";
        bg.style.background = farg;
    }
    init_tv(src) {
        var self = this;
        //alert(JSON.stringify(src));
        //self.dsi();
        //self.get_tvo();
        //self.tvo_poll();
        var video = document.getElementById("myVideo");
        var mp4 = document.getElementById("mp4");
        //mp4.src = this.state.vsrc;
        mp4.src = src;

        video.load();
        video.play();
        //if (video.paused) {
        //    video.play();
        //}
    }
    dsi() {
        var self = this;
        var prm = {};
        prm.req = "dsi.dsi_run.tv_lt";
        //prm.sid = "S0000099";
        prm.scrid = this.state.scrid;
        //prm.scrid = "tv01";

        //alert(JSON.stringify(prm));
        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.rco));

                var tvo = ret.rco;
                //alert(JSON.stringify(tvo));

                if(!tvo.data.c11.mnu.descrows) tvo.data.c11.mnu.descrows = [];
                if(!tvo.data.c12.mnu.descrows) tvo.data.c12.mnu.descrows = [];
                if(!tvo.data.c21.mnu.descrows) tvo.data.c21.mnu.descrows = [];
                if(!tvo.data.c22.mnu.descrows) tvo.data.c22.mnu.descrows = [];
                if(!tvo.data.c11.mnu.rows) tvo.data.c11.mnu.rows = [];
                if(!tvo.data.c12.mnu.rows) tvo.data.c12.mnu.rows = [];
                if(!tvo.data.c21.mnu.rows) tvo.data.c21.mnu.rows = [];
                if(!tvo.data.c22.mnu.rows) tvo.data.c22.mnu.rows = [];
                self.setState({ tvo: tvo }, function() {
                    //if(tvo.bg.bgfarg && tvo.bg.bgfarg.length) self.init_bg(tvo.bg.bgfarg);
                    if(tvo.bg && (tvo.bg.bgtyp == "3")) {
                        //alert(JSON.stringify(tvo.bg.bgtyp));
                        if(tvo.bg.bgurl && tvo.bg.bgurl.length) self.init_tv(tvo.bg.bgurl);
                    }
                });
                //toastr.success("OK", "SAPI");
                //self.set_tvo(tvo);
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    sel_screen(sel) {
        //alert(JSON.stringify(fkn));
        this.setState({"scrid": sel.scrid});
        if(sel.fkn == "xx") {
            //var wnds = {};
            //wnds.manses = true;
            //this.setState({"wnds": wnds});
        }
    }
    sel_resolution(sel) {
        //alert(JSON.stringify(fkn));
        this.setState({"tv_width": sel.w, "tv_height": sel.h});
    }

  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "Gäst";
    //if(props.kro) alert(props.kro.krr00.kid);
    var tvo = this.state.tvo;

      // https://drive.google.com/file/d/1SjJ9fdfPaqEgdsTvYN78pE-XGKJOSHA_/view?usp=drive_web
      // <source src="http://filpub.svepos.se/menytv/tradgard/trad01.mp4" type="video/mp4" />

	var html =
        <div className="mx_base">
        <div className="m3_backdrop" />
        <div className="m3_dlg mx_100 flex-col" style={ this.dlg_style() } >

        <div className="mx_content flex" style={{ overflow:"auto" }}>

            <div className="flex" style={ this.css_tv(tvo) } >

                    { tvo.bg && ((tvo.bg.bgtyp == "0") || (tvo.bg.bgtyp == "1") || (tvo.bg.bgtyp == "")) ?
                        <div id="myBg" className="flex_row" style={ this.css_bg(tvo) }>
                        </div>
                        : null
                    }
                    { tvo.bg && (tvo.bg.bgtyp == "2") ?
                        <div className="" style={{ position: "absolute", width: "100%", height: "100%", zIndex: "-100" }}>
                            <img id="myPic" style={{ position: "absolute", width: "100%", height: "100%", zIndex: "-100", objectFit: "cover" }} src={tvo.bg.bgurl} />
                            <div id="myBg" className="flex_row" style={{position: "absolute", width: "100%", height: "100%", background: "#000", opacity: "0.4", overfow:"hidden", zIndex: "-99" }}>
                            </div>
                        </div>
                        : null
                    }
                    { tvo.bg && (tvo.bg.bgtyp == "3") ?
                        <div className="" style={{ position: "absolute", width: "100%", zIndex: "-100" }}>
                            <video autoPlay muted loop id="myVideo" style={{ position: "absolute", width: "100%", height: "100%", zIndex: "-100" }}>
                                <source id="mp4" type="video/mp4" />
                            </video>
                            <div id="myBg" className="flex_row" style={{position: "absolute", width: "100%", height: "100%", background: "#000", opacity: "0.4", overfow:"hidden", zIndex: "-99" }}>
                            </div>
                        </div>
                        : null
                    }

                    <div className={ this.cls_cols(tvo) } style={ this.css_colbody(tvo) }>

                        <div className="flex_col">
                            <div className="flex_row" style={ this.css_category(tvo) }>
                                { tvo.data.c11.mnu.text.toswe() }
                            </div>
                            { tvo.data.c11.mnu.descrows.map((desc, key) =>
                                <div key={key} className="" style={ this.css_desc(tvo) }>
                                    { desc.toswe() }
                                </div>
                            ) }
                        </div>

                        { tvo.data.c11.mnu.rows.map((row, key) =>
                            <div key={key} className="flex_row" style={{padding: "3px 0px"}}>
                                <div className="" style={ this.css_row_text(tvo) }>
                                    { row.text.toswe() }
                                </div>
                                <div className="flex">
                                    <div style={ this.css_row_desc(tvo) }>
                                        { row.desc.toswe() }
                                    </div>
                                </div>
                                <div className="" style={ this.css_row_price(tvo) }>
                                    { this.price_text(row) }
                                </div>
                            </div>
                        )}

                        <div className="flex_col">
                            <div className="flex_row" style={ this.css_category(tvo) }>
                                { tvo.data.c12.mnu.text.toswe() }
                            </div>
                            { tvo.data.c12.mnu.descrows.map((desc, key) =>
                                <div key={key} className="" style={ this.css_desc(tvo) }>
                                    { desc.toswe() }
                                </div>
                            ) }
                        </div>

                        { tvo.data.c12.mnu.rows.map((row, key) =>
                            <div key={key} className="flex_row" style={{padding: "3px 0px"}}>
                                <div className="" style={ this.css_row_text(tvo) }>
                                    { row.text.toswe() }
                                </div>
                                <div className="flex">
                                    <div style={ this.css_row_desc(tvo) }>
                                        { row.desc.toswe() }
                                    </div>
                                </div>
                                <div className="" style={ this.css_row_price(tvo) }>
                                    { this.price_text(row) }
                                </div>
                            </div>
                        )}

                        <div className="flex_col">
                            <div className="flex_row" style={ this.css_category(tvo) }>
                                { tvo.data.c21.mnu.text.toswe() }
                            </div>
                            { tvo.data.c21.mnu.descrows.map((desc, key) =>
                                <div key={key} className="" style={ this.css_desc(tvo) }>
                                    { desc.toswe() }
                                </div>
                            ) }
                        </div>

                        { tvo.data.c21.mnu.rows.map((row, key) =>
                            <div key={key} className="flex_row" style={{padding: "3px 0px"}}>
                                <div className="" style={ this.css_row_text(tvo) }>
                                    { row.text.toswe() }
                                </div>
                                <div className="flex">
                                    <div style={ this.css_row_desc(tvo) }>
                                        { row.desc.toswe() }
                                    </div>
                                </div>
                                <div className="" style={ this.css_row_price(tvo) }>
                                    { this.price_text(row) }
                                </div>
                            </div>
                        )}

                        <div className="flex_col">
                            <div className="flex_row" style={ this.css_category(tvo) }>
                                { tvo.data.c22.mnu.text.toswe() }
                            </div>
                            { tvo.data.c22.mnu.descrows.map((desc, key) =>
                                <div key={key} className="" style={ this.css_desc(tvo) }>
                                    { desc.toswe() }
                                </div>
                            ) }
                        </div>

                        { tvo.data.c22.mnu.rows.map((row, key) =>
                            <div key={key} className="flex_row" style={{padding: "3px 0px"}}>
                                <div className="" style={ this.css_row_text(tvo) }>
                                    { row.text.toswe() }
                                </div>
                                <div className="flex">
                                    <div style={ this.css_row_desc(tvo) }>
                                        { row.desc.toswe() }
                                    </div>
                                </div>
                                <div className="" style={ this.css_row_price(tvo) }>
                                    { this.price_text(row) }
                                </div>
                            </div>
                        )}

                    </div>
                <div className="mnu_qr hide">
                    <img src="/qr.png" width="200px" height="200px" />
                </div>

            </div>

        </div>

        <div className="mx_foot flex-row">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                AVBRYT
            </button>
            <div className="flex"></div>
            <Dd_Sel items={ this.state.screens } getValue={(e)=> this.sel_screen(e)} />
            <Dd_Sel items={ this.state.resolution } getValue={(e)=> this.sel_resolution(e)} />
            <div className="flex"></div>
            <button className="btn btn-hw-red w250" onClick={() => this.dsi() } >
                VISA
            </button>
        </div>
      </div>

      </div>
	;
	return html;
  };
}

export default Scr_Tvflowrow;
