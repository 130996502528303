import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../../lib/net";
import Td_Sel from "..//../../lib/ui/td_sel";
import {format} from "date-fns";

import Nota_Rad from "./fkn/notarad";
import Sell_Tr from "./sell_tr";
import Sell_Pay from "./sell_pay";
import Prd_Sz from "./fkn/prd_sz";

class Pms_Sell extends React.Component {
  constructor(props, context) {
    super(props);

     this.rowitems = [
        {fkn: 'corr', text: 'KORR'}
    ];

   //this.webStore = props.store;
    this.state = {};
    this.state.ynq = [];
    this.state.mka = [];

    this.state.paa = [];
    this.state.grp = "";
    this.state.gaa = [];
    this.state.bta = [];
    this.state.bgo = {};
    this.state.ix = 0;
    this.state.tot = {};
    this.state.tot.open = "";
    this.state.nta = [];
    this.state.pra = [];
    this.state.srow = {};
    this.state.wnds = {};

    if(props.wnds) {
        this.fkn_close = props.wnds.fkn_close;
        this.state.boknr = props.wnds.boknr;
        this.state.rvo = props.wnds.rvo;
        //this.state.nta = props.wnds.nta;
        //this.state.srow = props.wnds.srow;
    }

    this.state.today = new Date();
    var dts = format(this.state.today, "yyyy-MM-dd");
    this.state.dts = dts;
 }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      this.btn_grps();
      this.btn_grp("01");
      this.charges();
 }

    btn_grps() {
        var grps = {};
        var gaa = [];

        gaa.push({ grp: "01", text: "MINIBAR" });
        gaa.push({ grp: "02", text: "VIP BESTÄLLNING" });
        gaa.push({ grp: "03", text: "DORSIA PRODUKTER" });
        gaa.push({ grp: "04", text: "LILY & ROSE" });
        gaa.push({ grp: "05", text: "PRODUKTER" });
        gaa.push({ grp: "06", text: "GLAS & BESTICK" });
        gaa.push({ grp: "07", text: "HOTELL" });
        gaa.push({ grp: "08", text: "PRESENTKORT" });
        gaa.push({ grp: "09", text: "ÖPPNA PRODUKTER" });

        this.setState({gaa: gaa});
  }

  xbtn_grp(grp) {
    var btns = {};
    var baa = [];

	if (grp == "01") {	// Minibar

		baa.push({"id": "lok", "konto": "3031", "text": "Minibar läsk", 							"pris": "4500", 		"moms": "1200" });
		baa.push({"id": "lok", "konto": "3010", "text": "Minibar Krug", 							"pris": "95000", 		"moms": "2500" });
		baa.push({"id": "lok", "konto": "3032", "text": "Minibar nötter", 						"pris": "4500", 		"moms": "1200" });
		baa.push({"id": "lok", "konto": "3010", "text": "Minibar 1/2 flaska champagne", 			"pris": "49500", 		"moms": "2500" });
		baa.push({"id": "lok", "konto": "3031", "text": "Minibar Tonic", 							"pris": "2500", 		"moms": "1200" });
		baa.push({"id": "lok", "konto": "3020", "text": "Minibar Öl", 							"pris": "7500", 		"moms": "2500" });

		baa.push({"id": "lok", "konto": "3032", "text": "Minibar Chips", 							"pris": "3500", 		"moms": "1200" });
		baa.push({"id": "lok", "konto": "3032", "text": "Minibar Choklad", 						"pris": "3500", 		"moms": "1200" });
		baa.push({"id": "lok", "konto": "3040", "text": "Minibar Sprit", 							"pris": "12500", 		"moms": "2500"});
		baa.push({"id": "lok", "konto": "3010", "text": "Minibar Vin Rött", 						"pris": "29500", 		"moms": "2500" });
		baa.push({"id": "lok", "konto": "3010", "text": "Minibar Vin Vitt", 						"pris": "29500", 		"moms": "2500" });
	}

	if (grp == "02") {	// VIP Beställning

		baa.push({"id": "lok", "konto": "3010", "text": "Husets champagne", 						"pris": "88000", 		"moms": "2500" });
		baa.push({"id": "lok", "konto": "3010", "text": "Champagne NV Bollinger, Special Cuvée", 	"pris": "125000", 		"moms": "2500" });
		baa.push({"id": "lok", "konto": "3010", "text": "Champagne NV KRUG, Grande Cuvée", 		"pris": "199500", 		"moms": "2500" });
		baa.push({"id": "lok", "konto": "3010", "text": "Champagne Deutz, Classic Brut 1/2 flaska","pris": "49500", 		"moms": "2500" });
		baa.push({"id": "lok", "konto": "3010", "text": "Champagne NV André Clouet, Brut Rosé", 	"pris": "88000", 		"moms": "2500" });
		baa.push({"id": "lok", "konto": "3010", "text": "Husets Röda Vin", 						"pris": "41500", 		"moms": "2500" });

		baa.push({"id": "lok", "konto": "3010", "text": "Husets Vita vin", 						"pris": "41500", 		"moms": "2500" });
		baa.push({"id": "lok", "konto": "3002", "text": "Chokladpraliner 2 st", 					"pris": "4000", 			"moms": "1200" });
		baa.push({"id": "lok", "konto": "3002", "text": "Frukt och chokladpraliner", 				"pris": "19500", 		"moms": "1200" });
		baa.push({"id": "lok", "konto": "3002", "text": "Frukt 2 p", 								"pris": "12500", 		"moms": "1200" });
		baa.push({"id": "lok", "konto": "3002", "text": "Jordgubbar 2p", 							"pris": "9500", 		"moms": "1200" });
		baa.push({"id": "lok", "konto": "3002", "text": "Tryffelchips", 							"pris": "4500", 		"moms": "1200" });

		baa.push({"id": "lok", "konto": "3041", "text": "Blommor", 								"pris": "0", 			"moms": "2500" });
		baa.push({"id": "lok", "konto": "3054", "text": "Serviceavgift blommor", 					"pris": "15000", 		"moms": "2500" });
		baa.push({"id": "lok", "konto": "3001", "text": "Restaurant 25 %", 						"pris": "0", 			"moms": "2500" });
		baa.push({"id": "lok", "konto": "3002", "text": "Restaurant 12 %", 						"pris": "0", 			"moms": "1200" });
	}

	if (grp == "03") {	// Dorsia produkter

		baa.push({"id": "lok", "konto": "3064", "text": "Mobilskal Iphone", 						"pris": "4500", 		"moms": "2500" });
		baa.push({"id": "lok", "konto": "3064", "text": "Plastpåse LITEN", 						"pris": "4500", 		"moms": "2500" });
		baa.push({"id": "lok", "konto": "3064", "text": "Plastpåse STOR", 						"pris": "4500", 		"moms": "2500" });
		baa.push({"id": "lok", "konto": "3064", "text": "Presentpåse LITEN", 						"pris": "4500", 		"moms": "2500" });
		baa.push({"id": "lok", "konto": "3064", "text": "Presentpåse MELLAN", 					"pris": "4500", 		"moms": "2500" });
		baa.push({"id": "lok", "konto": "3064", "text": "Presentpåse STOR", 						"pris": "4500", 		"moms": "2500" });

		baa.push({"id": "lok", "konto": "3064", "text": "Canvas Bag", 							"pris": "4500", 		"moms": "2500" });
		baa.push({"id": "lok", "konto": "3064", "text": "Glasunderlägg", 							"pris": "4500", 		"moms": "2500" });
		baa.push({"id": "lok", "konto": "3064", "text": "Glasunderlägg i ask (10 st)", 			"pris": "4500", 		"moms": "2500" });
		baa.push({"id": "lok", "konto": "3064", "text": "Öronproppar", 							"pris": "4500", 		"moms": "2500" });
		baa.push({"id": "lok", "konto": "3064", "text": "Ask LITEN", 								"pris": "4500", 		"moms": "2500" });
		baa.push({"id": "lok", "konto": "3064", "text": "Ask STOR", 								"pris": "4500", 		"moms": "2500" });

		baa.push({"id": "lok", "konto": "3064", "text": "CD-Skiva", 								"pris": "4500", 		"moms": "2500" });
		baa.push({"id": "lok", "konto": "3064", "text": "Presentinslagning", 						"pris": "4500", 		"moms": "2500" });
	}

	if (grp == "04") {	//	Lily & Rose
		baa.push({"id": "lok", "konto": "3061", "text": "Lily & Rose", 							"pris": "0", 			"moms": "2500" });
	}

	if (grp == "05") {	//	Produktförsäljning

		baa.push({"id": "lok", "konto": "3059", "text": "Bäddlinnen set", 						"pris": "100000", 		"moms": "2500" });
		baa.push({"id": "lok", "konto": "3059", "text": "Underlakan enkel (155x260 cm)", 			"pris": "40000", 		"moms": "2500" });
		baa.push({"id": "lok", "konto": "3059", "text": "Underlakan dubbel (265x265 cm)", 		"pris": "60000", 		"moms": "2500" });
		baa.push({"id": "lok", "konto": "3059", "text": "Täcke st", 								"pris": "280000", 		"moms": "2500" });
		baa.push({"id": "lok", "konto": "3059", "text": "Kudde st", 								"pris": "139000", 		"moms": "2500" });
		baa.push({"id": "lok", "konto": "3059", "text": "Högtalare", 								"pris": "29500", 		"moms": "2500" });

		baa.push({"id": "lok", "konto": "3059", "text": "Paraply", 								"pris": "25000", 		"moms": "2500" });
		baa.push({"id": "lok", "konto": "3059", "text": "Skohorn", 								"pris": "20000", 		"moms": "2500" });
		baa.push({"id": "lok", "konto": "3059", "text": "Badrock", 								"pris": "69500", 		"moms": "2500" });
		baa.push({"id": "lok", "konto": "3059", "text": "Badrumsmatta", 							"pris": "15000", 		"moms": "2500" });
		baa.push({"id": "lok", "konto": "3059", "text": "Skål med strasskant", 					"pris": "7500", 		"moms": "2500" });
		baa.push({"id": "lok", "konto": "3059", "text": "Ryggborste", 							"pris": "10000", 		"moms": "2500" });

		baa.push({"id": "lok", "konto": "3059", "text": "Badsvamp", 								"pris": "13000", 		"moms": "2500" });
		baa.push({"id": "lok", "konto": "3059", "text": "Christian Lacroix Schampo 50 ml", 		"pris": "3000", 		"moms": "2500" });
		baa.push({"id": "lok", "konto": "3059", "text": "Christian Lacroix Conditioner 50 ml", 	"pris": "3000", 		"moms": "2500" });
		baa.push({"id": "lok", "konto": "3059", "text": "Christian Lacroix Shower Gel 50 ml", 	"pris": "3000", 		"moms": "2500" });
		baa.push({"id": "lok", "konto": "3059", "text": "Christian Lacroix Body Lotion 50 ml", 	"pris": "3000", 		"moms": "2500" });
		baa.push({"id": "lok", "konto": "3059", "text": "Christian Lacroix Body Lotion 100 ml", 	"pris": "6000", 		"moms": "2500" });

		baa.push({"id": "lok", "konto": "3059", "text": "Christian Lacroix Fast tvål 30g", 		"pris": "2500", 		"moms": "2500" });
		baa.push({"id": "lok", "konto": "3059", "text": "Christian Lacroix Fast tvål 70g", 		"pris": "4500", 		"moms": "2500" });
		baa.push({"id": "lok", "konto": "3059", "text": "Christian Lacroix Hand Soap 267 ml", 	"pris": "17500", 		"moms": "2500" });
		baa.push({"id": "lok", "konto": "3059", "text": "Christian Lacroix Hand Balm 267 ml", 	"pris": "17500", 		"moms": "2500" });
		baa.push({"id": "lok", "konto": "3059", "text": "SWE Tonic 200 ml", 						"pris": "29500", 		"moms": "2500" });
		baa.push({"id": "lok", "konto": "3059", "text": "SLH Bok", 								"pris": "17500", 		"moms": "2500" });

		baa.push({"id": "lok", "konto": "3003", "text": "Taschen Bok Warhol Polaroids 1958-1987", "pris": "95000", 		"moms": "0600" });
		baa.push({"id": "lok", "konto": "3003", "text": "Taschen Bok On Yoga Michael O'Neill", 	"pris": "65000", 		"moms": "0600" });
		baa.push({"id": "lok", "konto": "3003", "text": "Taschen Bok Hieronymus Bosch", 			"pris": "136000", 		"moms": "0600" });
		baa.push({"id": "lok", "konto": "3003", "text": "Gustav Klimt Bok", 						"pris": "182500", 		"moms": "0600" });
		baa.push({"id": "lok", "konto": "3003", "text": "Beng Petersen Fiskebok", 				"pris": "17500", 		"moms": "0600" });
	}

	if (grp == "06") {	// Glas & Bestick

		baa.push({"id": "lok", "konto": "3063", "text": "Spiegelau Vitvin", 						"pris": "16000", 		"moms": "2500" });
		baa.push({"id": "lok", "konto": "3063", "text": "Spiegelau Rödvin", 						"pris": "16500", 		"moms": "2500" });
		baa.push({"id": "lok", "konto": "3063", "text": "Spiegelau Champagne", 					"pris": "16000", 		"moms": "2500" });
		baa.push({"id": "lok", "konto": "3063", "text": "Spiegelau Vattenglas Line Stora", 		"pris": "19500", 		"moms": "2500" });
		baa.push({"id": "lok", "konto": "3063", "text": "Spiegelau Vattenglas Line Små", 			"pris": "17500", 		"moms": "2500" });
		baa.push({"id": "lok", "konto": "3063", "text": "Spiegelau Vattenglas Line med fot", 		"pris": "27500", 		"moms": "2500" });

		baa.push({"id": "lok", "konto": "3063", "text": "Nachtmann Prestige Vattenglas", 			"pris": "7500", 		"moms": "2500" });
		baa.push({"id": "lok", "konto": "3063", "text": "Glaskanna", 								"pris": "99500", 		"moms": "2500" });
		baa.push({"id": "lok", "konto": "3063", "text": "Jean Philip Contour Fleuri Satin Kniv", 	"pris": "38500", 		"moms": "2500" });
		baa.push({"id": "lok", "konto": "3063", "text": "Jean Philip Contour Fleuri Satin Gaffel", "pris": "23500", 		"moms": "2500" });
		baa.push({"id": "lok", "konto": "3063", "text": "Vas Hortensia", 							"pris": "69500", 		"moms": "2500" });
	}

	if (grp == "07") {	// Hotell

		baa.push({"id": "lok", "konto": "3051", "text": "Extra städ", 							"pris": "0", 			"moms": "2500" });
		baa.push({"id": "lok", "konto": "3190", "text": "Roomservice kostnad", 					"pris": "9500", 		"moms": "2500" });
		baa.push({"id": "lok", "konto": "3054", "text": "Servicekostnad", 						"pris": "0", 			"moms": "2500" });
		baa.push({"id": "lok", "konto": "2498", "text": "Gym Voucher", 							"pris": "20000", 		"moms": "2500" });
		baa.push({"id": "lok", "konto": "3059", "text": "Extra Handdukar", 						"pris": "0", 			"moms": "2500" });
		baa.push({"id": "lok", "konto": "3053", "text": "Parkeringsavgift", 						"pris": "28000", 		"moms": "2500" });

		baa.push({"id": "lok", "konto": "3054", "text": "Parkeringsservice", 						"pris": "20000", 		"moms": "2500" });
		baa.push({"id": "lok", "konto": "3111", "text": "Frukost Extern", 						"pris": "32500", 		"moms": "1200" });
		baa.push({"id": "lok", "konto": "3371", "text": "Konferens lokalhyra 25%", 				"pris": "0", 			"moms": "2500" });
		baa.push({"id": "lok", "konto": "3370", "text": "Lokalhyra 12%", 							"pris": "0", 			"moms": "1200" });
		baa.push({"id": "lok", "konto": "3054", "text": "Fakturakostnad", 						"pris": "7500", 		"moms": "2500" });
		baa.push({"id": "lok", "konto": "3048", "text": "Gästtvätt", 								"pris": "0", 			"moms": "2500" });

		baa.push({"id": "lok", "konto": "3054", "text": "Kopior", 								"pris": "200", 			"moms": "2500" });
		baa.push({"id": "lok", "konto": "3110", "text": "Logi", 									"pris": "0", 			"moms": "1200" });
		baa.push({"id": "lok", "konto": "2498", "text": "Hagabadet Spa Voucher sön-fre", 			"pris": "25000", 		"moms": "0000" });
		baa.push({"id": "lok", "konto": "2498", "text": "Hagabadet Spa Voucher lör", 				"pris": "49900", 		"moms": "0000" });
		baa.push({"id": "lok", "konto": "2499", "text": "Utlägg gäst", 							"pris": "0", 			"moms": "2500" });
	}

	if (grp == "08") {	//	Förskottsbetalning
		baa.push({"id": "lok", "konto": "2420", "text": "Förskottsbetalning", 					"pris": "0", 			"moms": "0000" });
	}
	if (grp == "09") {	//	Presentkort
		baa.push({"id": "lok", "konto": "2421", "text": "Presentkort", 							"pris": "0", 			"moms": "0000" });
	}
	if (grp == "12") {	//	Öppna
		baa.push({"id": "lok", "konto": "2421", "text": "Presentkort", 							"pris": "0", 			"moms": "0000" });
		baa.push({"id": "lok", "konto": "3001", "text": "Blommor", 							    "pris": "0", 			"moms": "2500" });
		baa.push({"id": "lok", "konto": "3001", "text": "Dorsia produkter/merchandise", 		"pris": "0", 			"moms": "2500" });
		baa.push({"id": "lok", "konto": "3001", "text": "Husets champagne", 					"pris": "0", 			"moms": "2500" });
		baa.push({"id": "lok", "konto": "3001", "text": "Lily & rose", 							"pris": "0", 			"moms": "2500" });
		baa.push({"id": "lok", "konto": "3001", "text": "Servicekostnad", 						"pris": "0", 			"moms": "2500" });
	}
    ////this.setState({baa: baa});
	this.setState({grp: grp, bta: baa});
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    tab_style(tabid) {
      var css = {};
      css.display = "none";
      if(this.state.tabval === tabid) css.display = "block";
      return css;
    }
  btn_cls(btn) {
      var cls = "flex col";
      cls = cls + " btn_free";
      //if(btn.f0plu && (btn.f0plu.trim() != "")) cls = cls + " btn_pos";
      //else cls = cls + " btn_free";
      return cls;
  }
  btn_style(btn) {
    var css = {};
    css.cursor = "pointer";
    //if(btn.f0kod) css.background = this.cdi_rgb(btn.f0kod);
    //if(btn.f0bkod) css.color = this.cdi_rgb(btn.f0bkod);
    return css;
  }
  grp_style(grp) {
    var css = {};
    if(grp == this.state.grp) {
        css.background = "#080";
        css.color = "#fff";
    }
    return css;
  }

    wnd_close() {
      if(this.fkn_close) this.fkn_close(this.props.ctx);
      else this.props.ctx.setState({ wnds: {} });
	};

  tb_style() {
      var css = {};
      css.height = window.innerHeight - 500;
      css.overflow = "auto";
      return css;
  }
  row_style(row) {
    var srow = this.state.srow;
    var css = {};
    if(srow[row.etr01.e0lopnr]) {
        css.background = "#080";
        css.color = "#fff";
    }
    if(row.etr01.e0tkn == "-") {
        css.background = "#fff";
        css.color = "#800";
    }
    return css;
  }
  toswe(s) {
      if(!s) s = "";
      s = s.toswe();
      return s;
    };
  topris(s) {
      if(!s) s = "";
      s = s.cdipris();
      return s;
    };

  btn_sell(btn) {
      var self = this;

      if(false) this.sell_tr(btn);
      if(btn.e1bastyp == "T") {
          var wnds = {};
          wnds.selltr = 1;
          wnds.boknr = this.state.rvo.boknr;
          wnds.gso = this.state.rvo.gsb00;
          wnds.tro = btn;
          wnds.cb = this.cb_sell_tr;
          this.setState({wnds: wnds});
      }
      if(btn.e1bastyp == "B") {
          var wnds = {};
          wnds.sellpay = 1;
          wnds.boknr = this.state.rvo.boknr;
          wnds.gso = this.state.rvo.gsb00;
          wnds.tro = btn;
          wnds.cb = this.cb_sell_pay;
          this.setState({wnds: wnds});
      }
  }
    cb_sell_tr(ctx, ret) {
        if(ret.ok == "000") {
            ctx.charges();
        }
        ctx.setState({wnds: {}});
    }
    cb_sell_pay(ctx, ret) {
        if(ret.ok == "000") {
            ctx.charges();
        }
        ctx.setState({wnds: {}});
    }

  corr() {
    var self = this;
      var last = this.state.nta.slice(-1)[0];
      //var last = this.state.nta.slice(-1).pop();
    var tro = last.etr01;
      self.corr_tr(tro);
  };

  row_sel(row) {
    var self = this;

    if(false) {
        self.corr_tr(row.etr01);
        return;
    }
    /*
    var wnds = {};
    wnds.pettyrad = true;
    wnds.boknr = self.state.boknr;
    wnds.gso = self.state.gso;
    wnds.row = row;
    wnds.fkn_close = self.wnds_close;
    self.setState({ wnds: wnds });
    */
  };
  sel_row(row) {
    var self = this;
    var wnds = {};
    wnds.notarad = true;
    wnds.row = row;
    //self.setState({ wnds: wnds });
  };
  sel_row_fkn(row, e) {
    var self = this;
    //alert(JSON.stringify(fkn));
      if(e.fkn == "corr") {
        var tro = row.etr01;
        self.corr_tr(tro);
      }
      if(e.fkn == "open") {
          //self.sel_row(row);
      }
  }

  btn_grp(grp) {
    var self = this;

    var prm = {};
    prm.req = "pms.tr.btns";
    prm.typ = "T";
    prm.grp = grp;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rco));

        self.setState({grp: grp, bta: ret.rca });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  sell_tr(tro) {
    var self = this;

    //alert(JSON.stringify(this.state.rvo.gsb00));
    //alert(JSON.stringify(this.state.gso));
    //return;
    var prm = {};
    prm.req = "pms.sell.charge_tr";
    prm.boknr = this.state.rvo.boknr;
    prm.gsb00 = this.state.rvo.gsb00;
    prm.trkod = tro.id;
    prm.text = tro.text;
    prm.antal = "1";
    prm.pris = tro.pris;
    prm.tkn = "+";
    prm.moms = tro.moms;
    prm.datum = this.state.dts;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rco));

        self.charges();
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  corr_tr(tro) {
    var self = this;

    if(tro.e0typ.trim() == "hot") {
        alert("Kan ej korrigera Restaurnagnota!");
        return;
    }

    var prm = {};
    prm.req = "pms.sell.corr_tr";
    prm.boknr = this.state.rvo.boknr;
    prm.gsb00 = this.state.rvo.gsb00;
    prm.etr01 = tro;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rco));

        //self.setState({rva: ret.rco });
        self.charges();
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  charges() {
    var self = this;
    var prm = {};
    prm.req = "pms.charge.charges_xx";
    prm.boknr = this.state.rvo.boknr;
    prm.glopnr = this.state.rvo.gsb00.r0glopnr;
    //alert(JSON.stringify(prm));

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        var tot = ret.tot;
        var nta = ret.rca;
        var pra = [];
        for(var row of nta) {
            pra.push( row.etr01);
        }
        self.setState({ nta: nta, pra: pra, tot: tot }, function () {
            self.syncTb("sellTable");
        });
        //self.syncTb("sellTable");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
    syncTb(tb){
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
        tdElements = tbel.getElementsByTagName('td');

        for (let i = 0; i < thElements.length; i++) {
            if(!tdElements[i]) continue;
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
            width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
  sz() {
    var self = this;
    var wnds = {};
    wnds.prdsz = 1;
    wnds.boknr = self.state.rvo.boknr;
    wnds.gso = self.state.rvo.gsb00;
    wnds.nta = self.state.nta;
    wnds.cb = this.cb_sz;
    this.setState({wnds: wnds});
  };
  cb_sz(ctx, ret) {
      if(ret.ok == "000") {
          ctx.charges();
      }
    ctx.setState({wnds: {}});
  }

  render() {
    //if(!this.props.show) return "";

    //alert(this.props.ctx.state.lro);

    var title = this.props.title;
    if(!title) title = "Knappar";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="m2_backdrop" /> : null }
        <div className="m2_dlg mx_100 flex-col" style={ this.dlg_style() }>


            <div className="mx_h01 flex-row" style={{padding:"0px",background: "#1a456b", color: "#fff"}}>
                    <div className="web-col w200" style={{padding:"5px"}}>
                        <div>SÄLJ</div>
                    </div>
        <div className="web-row flex" style={{padding:"0px 5px",height:"40px",lineHeight:"20px", fontSize: "22px"}}>
            <div className="web-col" onClick={() => this.fknSel("rvo") } >
                <div style={{fontSize: "12px"}}>Totalt</div>
                <div>{ this.state.tot.open.cdipris() }</div>
            </div>
            <div className="web-col flex" onClick={() => this.fknSel("rvo") } >
            </div>
            <div className="web-col" onClick={() => this.fknSel("rvo") } >
                <div style={{fontSize: "12px"}}>Totalt</div>
                <div>{ this.state.tot.open.cdipris() }</div>
            </div>
       </div>
                    <div className="web-col w200 text-right" style={{padding:"5px"}}>
                        <div>**</div>
                    </div>
            </div>

        <div className="mx_content flex">

        <div className="web-row flex">

        <div className="web-col">
        <div className="web-col flex">
            { this.state.gaa.map((grp, key) =>
            <div key={key} className="nota-grp-btn" style={ this.grp_style(grp)} onClick={() => this.btn_grp(grp.grp) } >
                <div>{ grp.text }</div>
                <div>{ grp.grp }</div>
            </div>
            )}
        </div>
        </div>

        <div className="web-col flex">
        <div className="web-row flex">
        <div className="web-col flex" style={{padding: "0px"}}>

        <div id="sellTable" className="flex">
          <table className="table table-bordered table-hover tb" style={{marginBottom: "0px"}}>
            <thead>
            <tr>
                <th>Datum</th>
                <th>T</th>
                <th>Typ</th>
                <th>Text</th>
                <th>Antal</th>
                <th>Pris</th>
                <th>Summa</th>
                <th>Tecken</th>
                <th>Löpnr</th>
                <th></th>
            </tr>
            </thead>
        </table>
        <div style={ this.tb_style() }>
          <table className="table table-bordered table-hover tb">
            <tbody>
            { this.state.nta.map((row, key) =>
            <tr key={key} style={ this.row_style(row) } onClick={ () => this.sel_row(row) } onDoubleClick={ () => this.row_sel(row) }>
                <td>{ row.etr01.e0datum.cdidate() }</td>
                <td>{ row.etr01.e0bastyp }</td>
                <td>{ row.etr01.e0typ }</td>
                <td>{ row.etr01.e0text.toswe() }</td>
                <td className="text-center">{ row.etr01.e0antal.ztrim() }</td>
                <td className="text-right">{ row.etr01.e0pris.cdipris() }</td>
                <td className="text-right">{ ((row.etr01.e0antal * 1) * (row.etr01.e0pris * 1)).cdipris() }</td>
                <td>{ row.etr01.e0tkn }</td>
                <td>{ row.etr01.e0lopnr.ztrim() }</td>
                <td>
                    <Td_Sel items={ this.rowitems } getValue={(e)=> this.sel_row_fkn(row, e)} />
                </td>
            </tr>
            )}
            </tbody>
        </table>
        </div>
        </div>

        </div>

         <div className="web-col" style={{padding: "0px", borderLeft: "1px solid #080", height: "100%"}}>
            <div className="web-box-01" onClick={() => this.wnd_close() } >
                <div>STÄNG</div>
                <div></div>
            </div>
            <div className="web-box-01" onClick={() => this.charges() } >
                <div>UPPDATERA</div>
                <div></div>
            </div>
             <div className="flex"></div>
            <div className="web-box-01 hide" onClick={() => this.pay_toinv() } >
                <div>NOTA SÄTT</div>
                <div></div>
            </div>
           <div className="web-box-01" onClick={() => this.corr() } >
                <div>KORR</div>
                <div></div>
            </div>
       </div>
       </div>

        <div className="divider_orange"></div>
       <div className="web-col">

       <div className="web-row" style={{ height: "350px" }}>
        <div className="bv">
        <div className="bv-grid">
        { this.state.bta.map((btn, key) =>
            <div key={key} className="nota-sell-btn"
                 style={ this.btn_style(btn) }
                 onClick={() => this.btn_sell(btn) } >
                <div>{ this.toswe(btn.e1text) }</div>
                <div>{ this.topris(btn.e1pris) }</div>
            </div>
            )}
        </div>
        </div>
       </div>

     </div>
        </div>
        </div>

        <div className="web-row" style={{padding: "0px", borderTop: "1px solid #800"}}>
            <div className="nota-bet-btn" onClick={() => this.wnd_close() } >
                <div>STÄNG</div>
                <div></div>
            </div>
            <div className="nota-grp-btn" onClick={() => this.sz() } >
                <div>SÖK</div>
                <div></div>
            </div>
             <div className="flex"></div>
            <div className="nota-bet-btn" onClick={() => this.wnd_close() } >
                <div>KLAR</div>
                <div></div>
            </div>
         </div>

        </div>

      </div>

    { this.state.wnds.selltr ? <Sell_Tr wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
    { this.state.wnds.sellpay ? <Sell_Pay wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
    { this.state.wnds.prdsz ? <Prd_Sz wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
    { this.state.wnds.notarad ?
        <Nota_Rad wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
    : null }
    </div>
	;
	return html;
  };
}

export default Pms_Sell;