import React, { useState, useEffect, useContext } from 'react';

function Prs_Edit(props) {
    const [state, setState] = useState({ user : {}, tabval: 0 });

    function dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    function tab_style(tabid) {
      var css = {};
      css.display = "none";
      css.padding = "5px 10px";
      if(state.tabval === tabid) css.display = "block";
      return css;
    }
    function tab_head(tabid) {
      var css = {};
      css.background = "#fff";
      css.color = "#222";
      if(state.tabval === tabid) {
          //css.background = "#080";
          //css.color = "#fff";
          css.borderBottom = "1px solid #a00";
      }
      return css;
    }
    function saveEE(event) {
        //const body = new FormData(this.form);
        //alert('FORM: ' + JSON.stringify(state));
        //props.ctx.save_eeo();
    }
    function handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = state.user;
      user[name] = value;
      setState({user: user});
      //setState({[name]: value});
    }
	const handleClose = () => {
		props.ctx.setState({ edit: false });
	};
    function tabChange (e) {
      setState({tabval: e.index});
    }
    if(!props.show) return "";
    var title = props.title;
    if(!title) title = "Anställd";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        { props.backdrop ? <div className="mx_backdrop" /> : null }
        <div className="mx_dlg_lg flex-col" style={ dlg_style() }>
        <div className="mx_head bg-w01 flex-row flex-space-between">
            <div className="mx_title">
                { title }
            </div>
            <div className="mx_close" onClick={() => handleClose() }>X</div>
        </div>
        <div className="mx_content flex-col flex">
        <div className="tab_row">
            <div className="tab_header flex" style={ tab_head(0) } onClick={ () => setState({tabval: 0}) }>ÖVERSIKT</div>
            <div className="tab_header flex" style={ tab_head(1) } onClick={ () => setState({tabval: 1}) }>EKONOMI</div>
        </div>
        <div className="tab_content flex" style={ tab_style(0) }>
            <div className="flex-row ff-box">
            <div className="flex-col flex f45">
                    <div className="ff-row">
                        <label className="ff-label-row">Anstnr</label>
                        <input type="text" className="form-control" value={props.eeo.p1.anstnummer.ztrim()} onChange={(event) => handleUserInput(event)} />
                    </div>
                    <div className="ff-row">
                        <label className="ff-label-row">Namn</label>
                        <input type="text" className="form-control" value={props.eeo.p1.namn.toswe()} onChange={(event) => handleUserInput(event)} />
                    </div>
                    <div className="ff-row">
                        <label className="ff-label-row">Telefon</label>
                        <input type="text" className="form-control" value={props.eeo.p3.Telefon} onChange={(event) => handleUserInput(event)} />
                    </div>
                    <div className="ff-row">
                        <label className="ff-label-row">Epost</label>
                        <input type="text" className="form-control" value={props.eeo.p4.mailadress} onChange={(event) => handleUserInput(event)} />
                    </div>
            </div>
            <div className="flex-col flex f10">
            </div>
            <div className="flex-col flex f45">
                    <div className="ff-row">
                        <label className="ff-label-row">Födelse nr</label>
                        <input type="text" className="form-control" value={props.eeo.p1.fodnummer} onChange={(event) => handleUserInput(event)} />
                    </div>
                    <div className="ff-row">
                        <label className="ff-label-row">Kort</label>
                        <input type="text" className="form-control" value={props.eeo.p1.kort} onChange={(event) => handleUserInput(event)} />
                    </div>
                    <div className="ff-row">
                        <label className="ff-label-row">Mobil</label>
                        <input type="text" className="form-control" value={props.eeo.p4.Telefon} onChange={(event) => handleUserInput(event)} />
                    </div>
            </div>
            </div>

            <div className="flex-row ff-box">
            <div className="flex-col flex f45">
                    <div className="ff-row">
                        <label className="ff-label-row">Avdelning</label>
                        <select className="form-control" value={props.eeo.p1.avdelning} onChange={(event) => handleUserInput(event)}>
                            <option value="0001">AVD 01</option>
                            <option value="0002">AVD 02</option>
                        </select>
                    </div>
                    <div className="ff-row">
                        <label className="ff-label-row">Schema Datum</label>
                        <input type="text" className="form-control" value={props.eeo.p2.schedat} onChange={(event) => handleUserInput(event)} />
                    </div>
                    <div className="ff-row">
                        <label className="ff-label-row">Start Datum</label>
                        <input type="text" className="form-control" value={props.eeo.p2.startdat} onChange={(event) => handleUserInput(event)} />
                    </div>
            </div>
            <div className="flex-col flex f10">
            </div>
            <div className="flex-col flex f45">
                    <div className="ff-row">
                        <label className="ff-label-row">Kategori</label>
                        <input type="text" className="form-control" value={props.eeo.p2.kategori} onChange={(event) => handleUserInput(event)} />
                    </div>
                    <div className="ff-row">
                        <label className="ff-label-row">Arbschema</label>
                        <input type="text" className="form-control" value={props.eeo.p1.arbschema} onChange={(event) => handleUserInput(event)} />
                    </div>
                    <div className="ff-row">
                        <label className="ff-label-row">Slut Datum</label>
                        <input type="text" className="form-control" value={props.eeo.p2.prssdat} onChange={(event) => handleUserInput(event)} />
                    </div>
            </div>
            </div>

            <div className="flex-row ff-box">
            <div className="flex-col flex f45">
                    <div className="ff-row">
                        <label className="ff-label-row">Initialer</label>
                        <input type="text" className="form-control" value={props.eeo.p2.initialer} onChange={(event) => handleUserInput(event)} />
                    </div>
                    <div className="ff-row">
                        <label className="ff-label-row">Bakgrund</label>
                        <input type="text" className="form-control" value={props.eeo.p4.bgfarg} onChange={(event) => handleUserInput(event)} />
                    </div>
            </div>
            <div className="flex-col flex f10">
            </div>
            <div className="flex-col flex f45">
                    <div className="ff-row">
                        <label className="ff-label-row">Annat anstnr</label>
                        <input type="text" className="form-control" value={props.eeo.p2.annatanstnr} onChange={(event) => handleUserInput(event)} />
                    </div>
                    <div className="ff-row">
                        <label className="ff-label-row">Kvalitet</label>
                        <input type="text" className="form-control" value={props.eeo.p4.kvalitet} onChange={(event) => handleUserInput(event)} />
                    </div>
            </div>
            </div>

            </div>

            <div className="tab_content flex" style={ tab_style(1) }>

            <div className="flex-row ff-box">
            <div className="flex-col flex f45">
                    <div className="ff-row">
                        <label className="ff-label-row">Bank</label>
                        <input type="text" className="form-control" value={props.eeo.p3.Bank} onChange={(event) => handleUserInput(event)} />
                    </div>
                    <div className="ff-row">
                        <label className="ff-label-row">Timlön</label>
                        <input type="text" className="form-control" value={props.eeo.p1.timlon} onChange={(event) => handleUserInput(event)} />
                    </div>
                    <div className="ff-row">
                        <label className="ff-label-row">Skattesats</label>
                        <input type="text" className="form-control" value={props.eeo.p2.taxproc} onChange={(event) => handleUserInput(event)} />
                    </div>
            </div>
            <div className="flex-col flex f10">
            </div>
            <div className="flex-col flex f45">
                    <div className="ff-row">
                        <label className="ff-label-row">Konto</label>
                        <input type="text" className="form-control" value={props.eeo.p3.Konto} onChange={(event) => handleUserInput(event)} />
                    </div>
                    <div className="ff-row">
                        <label className="ff-label-row">Månadslön</label>
                        <input type="text" className="form-control" value={props.eeo.p1.manlon} onChange={(event) => handleUserInput(event)} />
                    </div>
            </div>
            </div>

            <div className="flex-row ff-box">
            <div className="flex-col flex f45">
                    <div className="ff-row">
                        <label className="ff-label-row">Tidanledning</label>
                        <input type="text" className="form-control" value={props.eeo.p1.anledning} onChange={(event) => handleUserInput(event)} />
                    </div>
                    <div className="ff-row">
                        <label className="ff-label-row">Matavdrag</label>
                        <input type="text" className="form-control" value={props.eeo.p2.ejmat} onChange={(event) => handleUserInput(event)} />
                    </div>
                    <div className="ff-row">
                        <label className="ff-label-row">OB</label>
                        <input type="text" className="form-control" value={props.eeo.p2.prsejob} onChange={(event) => handleUserInput(event)} />
                    </div>
            </div>
            <div className="flex-col flex f10">
            </div>
            <div className="flex-col flex f45">
                    <div className="ff-row">
                        <label className="ff-label-row">Justera tid</label>
                        <input type="text" className="form-control" value={props.eeo.p2.justtid} onChange={(event) => handleUserInput(event)} />
                    </div>
                    <div className="ff-row">
                        <label className="ff-label-row">Avdelnings val</label>
                        <input type="text" className="form-control" value={props.eeo.p2.avdkrav} onChange={(event) => handleUserInput(event)} />
                    </div>
                    <div className="ff-row">
                        <label className="ff-label-row">Natt OB</label>
                        <input type="text" className="form-control" value={props.eeo.p2.prsejnob} onChange={(event) => handleUserInput(event)} />
                    </div>
            </div>
            </div>

            </div>
        </div>

        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w150 ripple" onClick={() => handleClose() } >
                STÄNG
            </button>
            <button className="btn btn-hw-green w150 ripple" onClick={(event) => saveEE(event) } >
                SPARA
            </button>
        </div>
      </div>
    </div>
	;
	return html;
}

export default Prs_Edit;