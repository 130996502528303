import React, { useState, useEffect, useContext } from 'react';
import {withRouter} from "../../lib/react/withRouter";

import sso from "../../sso";
import * as net from "../../lib/net";

import TechCaspeco from "./fkn/caspeco";
import TechPkoll from "./fkn/pkoll";
import TechFortknox from "./fkn/fortknox";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Tech_Ifs extends React.Component {
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.state = {};
    this.state.seldate = new Date();
    this.state.avd = "01";
    this.state.paa = [];
    this.state.aa = [];
      this.state.wnds = {};
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

    fknGo(url) {
        //alert(row.href);
        var row = {};
        row.url = url;
        sso.seo.sfkn = row;
        this.props.navigate(url);
    }
    fknOpen(fkn) {
        var self = this;
        var wnds = {};
        wnds[fkn] = true;
        wnds.backdrop = true;
        self.setState({wnds: wnds });
    }

  render() {
    return (
    <div className="web-app">
        <div className="web-pg py-4 flex">
            <h3>Interface</h3>
            <p>System kopplingar</p>

        <div className="web-row">
            <div className="flex-col flex px-1">
                <div className="web-bx" onClick={() => this.sie() }>
                    <div>Bokföring</div>
                    <div className="flex-row">
                        <div className="flex">SIE Fil</div>
                        <div className="web-link">Välj</div>
                    </div>
                </div>
                <div className="web-bx" onClick={() => this.fknOpen("fortknox") }>
                    <div>Fortknox</div>
                    <div className="flex-row">
                        <div className="flex">Direkt integration</div>
                        <div className="web-link">Välj</div>
                    </div>
                </div>
            </div>

            <div className="flex-col flex px-1">
                <div className="web-bx" onClick={() => this.fknOpen("caspeco") }>
                    <div>Caspeco</div>
                    <div className="flex-row">
                        <div className="flex"></div>
                        <div className="web-link">Välj</div>
                    </div>
                </div>
                <div className="web-bx" onClick={() => this.fknOpen("pkoll") }>
                    <div>Personalkollen</div>
                    <div className="flex-row">
                        <div className="flex"></div>
                        <div className="web-link">Välj</div>
                    </div>
                </div>
            </div>

            <div className="flex-col flex px-1">
                <div className="web-bx" onClick={() => this.fknGo("/pos/tech/foodora") }>
                    <div>Foodora</div>
                    <div className="flex-row">
                        <div className="flex"></div>
                        <div className="web-link">Välj</div>
                    </div>
                </div>
                <div className="web-bx" onClick={() => this.fknGo("/pos/tech/deliverect") }>
                    <div>Deliverect</div>
                    <div className="flex-row">
                        <div className="flex"></div>
                        <div className="web-link">Välj</div>
                    </div>
                </div>
            </div>
        </div>


    </div>

        { this.state.wnds.caspeco ? <TechCaspeco wnds={this.state.wnds} ctx={this} store={this.store} backdrop={true}/> : null }
        { this.state.wnds.pkoll ? <TechPkoll wnds={this.state.wnds} ctx={this} store={this.store} backdrop={true}/> : null }
        { this.state.wnds.fortknox ? <TechFortknox wnds={this.state.wnds} ctx={this} store={this.store} backdrop={true}/> : null }
    </div>
  );
    }
}
export default withRouter(Tech_Ifs);
