import React, { useState, useEffect, useContext } from 'react';
import { ProgressBar } from "react-bootstrap";

import * as net from "../../../lib/net";

import Wnd_Error from "../../../lib/ui/wnd_error";
import Wnd_Ok from "../../../lib/ui/wnd_ok";
import Wnd_Info from "../../../lib/ui/wnd_info";
import Wnd_Confirm from "../../../lib/ui/wnd_confirm";
import Fr_Input from "../../../lib/ui/fr_input";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const ProgressCtrl = (props) => {
    const { bgcolor, completed } = props;

    const containerStyles = {
        height: 20,
        width: '100%',
        backgroundColor: "#e0e0de",
        borderRadius: 50,
        margin: 0
    }

    const fillerStyles = {
        height: '100%',
        width: `${completed}%`,
        backgroundColor: bgcolor,
        borderRadius: 'inherit',
        textAlign: 'right',
        transition: "width .6s ease"
    }

    const labelStyles = {
        padding: "0 5",
        width: '100%',
        color: 'white',
        fontWeight: 'bold',
        textAlign: 'center'
    }

    return (
        <div style={containerStyles}>
            <div style={fillerStyles}>
                <div style={labelStyles}>{`${completed}%`}</div>
            </div>
        </div>
    );
};

class Cas_Tech extends React.Component {
  constructor(props, context) {
    super(props);

    this.pos = props.store.pos;
    this.state = {};
    this.state.today = new Date();
    this.state.lsa = [];
    this.state.lso = {};
    //this.state.lro = props.lro;
    this.state.tya = [
                {id: '02', text: 'Dag för dag'},
                {id: '01', text: 'Summerad'}
            ];
    this.state.dea = [
                {id: '01', text: 'Definition v1'},
                {id: '02', text: 'Definition v2'}
            ];
    this.state.cols = {};
    this.state.lrwrk = false;
    //context.posrend = this;
    this.state.favd = "";
    this.state.tavd = "";
    this.state.fdat = "";
    this.state.tdat = "";
    this.state.typ = "";
    this.state.def = "";
      this.state.msg = {};
      this.state.mx = {};
    this.state.pdf = 0;
    this.state.pdf_content = null;
      this.state.loading = 0;
      this.state.procup = 25;
      this.state.fcnr = "001";
      this.state.tcnr = "999";
      this.state.pluvalue = "";
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.dlg_init();
  }

  dlg_init() {
      var state = this.state;
      state.favd = "01";
      state.tavd = "01";
      state.typ = "02";
      state.def = "01";
      state.loading = 0;
      if(this.pos.deps && this.pos.deps.length) {
          state.favd = this.pos.deps[0].avd;
          state.tavd = this.pos.deps[0].avd;
      }
    this.setState(state);
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    tab_style(tabid) {
      var css = {};
      css.display = "none";
      if(this.state.tabval === tabid) css.display = "block";
      return css;
    }
    tab_head(tabid) {
      var css = {};
      css.background = "#fff";
      css.color = "#000";
      if(this.state.tabval === tabid) {
          css.background = "#080";
          css.color = "#fff";
      }
      return css;
    }
  change_avd(e) {
    //let cdat = this.state.seldate;
    let avd = e.target.value;
    this.setState({avd: avd});
  }
  change_typ(e) {
    //let cdat = this.state.seldate;
    let typ = e.target.value;
    this.setState({typ: typ});
  }
  change_def(e) {
    //let cdat = this.state.seldate;
    let def = e.target.value;
    this.setState({def: def});
  }

    clear_sign() {
        var self = this;

        if(false) {
            this.setState({procup: 40});
            return;
        }
        //this.setState({ loading: 1 });
        var msg = { info: true, text: "Rensar signaturer..."};
        self.setState({msg: msg });

        var prm = {};
        prm.req = "pos.pos_cr.clear_sign";
        prm.avd = this.state.avd;
        prm.fplu = this.state.fplu;
        prm.tplu = this.state.tplu;
        prm.mkod = this.state.pluvalue;
        //alert(JSON.stringify(prm));
        //return;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.ok));

                var msg = { info: false, ok: true, text: "Signaturer rensade..."};
                self.setState({msg: msg });
                setTimeout(function(){
                    self.setState({msg: {} });
                }, 1000);
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    clear_ft() {
        var self = this;

        if(false) {
            this.setState({procup: 40});
            return;
        }
        //this.setState({ loading: 1 });
        var msg = { info: true, text: "Rensar register..."};
        self.setState({msg: msg });

        var prm = {};
        prm.req = "pos.pos_cr.crs_clear";
        prm.avd = this.state.avd;
        prm.fcnr = this.state.fcnr;
        prm.tcnr = this.state.tcnr;
        //alert(JSON.stringify(prm));
        //return;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.ok));

                var msg = { info: false, ok: true, text: "Register rensat..."};
                self.setState({msg: msg });
                setTimeout(function(){
                    self.setState({msg: {} });
                }, 1000);
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    clear_crs() {
        var self = this;

        if(false) {
            this.setState({procup: 40});
            return;
        }
        //this.setState({ loading: 1 });
        var msg = { info: true, text: "Rensar register..."};
        self.setState({msg: msg });

        var prm = {};
        prm.req = "pos.pos_cr.crs_clear";
        prm.avd = this.state.avd;
        //alert(JSON.stringify(prm));
        //return;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.ok));

                var msg = { info: false, ok: true, text: "Register rensat..."};
                self.setState({msg: msg });
                setTimeout(function(){
                    self.setState({msg: {} });
                }, 1000);
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }

  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  body_style() {
      var css = {};
      css.display = "";
      if( this.state.loading == 1 ) css.display = "none";
      return css;
  }
    wnd_close = () => {
        this.props.ctx.setState({ wnds: {} });
    };

    onFileChange() {
    }

  render() {
    var title = this.props.title;
    if(!title) title = "";

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="mx_backdrop" /> : null }
        <div className="mx_dlg mx_rp flex-col" style={ this.dlg_style() }>
        <div className="mx_head mx_bg flex-row flex-space-between">
            <div className="flex-col flex">
                <div>KASSÖR / TEKNIKER</div>
            </div>
            <div className="white-icon-close" onClick={() => this.wnd_close() }>X</div>
        </div>
        { this.state.loading ? <div className="mx_content flex align-items-center justify-content-center"><FontAwesomeIcon icon="spinner" spin size="3x" fixed /></div> :
        null }
        <div className="mx_content flex" style={ this.body_style() }>
            <div className="flex-row px-2">
                    <div className="flex-col flex">
                        <div>Start</div>
                    </div>
                    <div className="flex-col flex">
                        <div></div>
                    </div>
                    <div className="flex-col w50">
                        <div>Ändra</div>
                    </div>
            </div>
            <div className="divider_orange"></div>

        <div className="flex">

            <div className="flex-row">
                <div className="flex"></div>
              <div className="flex-col">
                  <span>Avdelning</span>
        <select className="form-control input-rp-01 w250" placeholder="Avdelning" onChange={(e) => this.change_avd(e)}>
            { this.pos.deps.map((row, key) =>
            <option key={key} value={row.avd}>{row.avd} - {row.text.toswe()}</option>
            )}
        </select>
            </div>
                <div className="flex"></div>
            </div>
            <div className="flex-row">
                <div className="flex"></div>
                <Fr_Input noflex label="F Kassör" col className="text-center w250" text={this.state.fcnr} getValue={(e)=> this.setState( { fcnr: e }) } />
                <Fr_Input noflex label="T Kassör" col className="text-center w250" text={this.state.tcnr} getValue={(e)=> this.setState( { tcnr: e }) } />
                <div className="flex"></div>
            </div>
            <div className="flex-row hide">
                <div className="flex"></div>
                <Fr_Input noflex label="Nytt värde" col className="text-center w250" text={this.state.pluvalue} getValue={(e)=> this.setState( { pluvalue: e }) } />
                <div className="flex"></div>
            </div>

            <div className="flex-row hide">
              <div className="flex-col">
                  <span>Typ</span>
        <select className="form-control input-rp-01 w250" placeholder="Typ" onChange={(e) => this.change_typ(e)}>
            { this.state.tya.map((row, key) =>
            <option key={key} value={row.id}>{row.text}</option>
            )}
        </select>
            </div>
              <div className="flex-col">
                  <span>Konto Definition</span>
        <select className="form-control input-rp-01 w250" placeholder="Definition" onChange={(e) => this.change_def(e)}>
            { this.state.dea.map((row, key) =>
            <option key={key} value={row.id}>{row.text}</option>
            )}
        </select>
            </div>
            </div>

            <div className="flex-row hide">
                <div className="flex-col flex">
                    <ProgressCtrl bgcolor="#800" completed={ this.state.procup }/>
                    <ProgressBar now={ this.state.procup } label={ this.state.procup }/>
                </div>
            </div>
            <div className="flex-row hide">
                <div className="flex-col">
                    <span>Import fil</span>
                    <input type="file" onChange={this.onFileChange} />
                </div>
            </div>

        </div>

        </div>

        <div className="mx_foot flex-col">

            <div className="flex-row">
                <div className="flex-row flex">
                    <div className="btn-rp" onClick={() => this.wnd_close() } >
                        <div>STÄNG</div>
                        <div><FontAwesomeIcon icon="times" size="2x" /></div>
                    </div>
                    <div className="btn-rp" onClick={() => this.clear_sign() } >
                        <div>RENSA KODER</div>
                        <div><FontAwesomeIcon icon="at" size="2x" /></div>
                    </div>
            </div>
            <div className="flex-row">
                <div className="btn-rp" onClick={() => this.clear_ft() } >
                    <div>RENSA FT</div>
                    <div><FontAwesomeIcon icon="at" size="2x" /></div>
                </div>
                <div className="btn-rp" onClick={() => this.clear_crs() } >
                    <div>RENSA REGISTER</div>
                    <div><FontAwesomeIcon icon="at" size="2x" /></div>
                </div>
            </div>
            </div>
        </div>

      </div>
            <Wnd_Error msg={this.state.msg} ctx={this} />
            <Wnd_Ok msg={this.state.msg} ctx={this} />
            <Wnd_Info msg={this.state.msg} ctx={this} />
            <Wnd_Confirm msg={this.state.msg} ctx={this} />
    </div>
	;
	return html;
  };
}

export default Cas_Tech;