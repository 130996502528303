import React, { useState, useEffect, useContext } from 'react';
import {Route, Routes} from "react-router-dom";

import Bo_Home from './bo/home.js';
import Bo_Ftg from './bo/ftg.js';
import Bo_Prm from './bo/prm.js';
import Bo_Art from './bo/art.js';
import sso from "../sso";
import {withRouter} from "../lib/react/withRouter";

function pg_home() {

    var html =
    <div className="web-content">
      Hem
    </div>
    ;
    return html;
}

class Fakt_Bo extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, deps: [] };
      var po = {};
      po.ftg = { "fid": "f05", "url": "/fakt/bo/ftg", "name": "Företag", "active": false };
      po.prm = { "fid": "f05", "url": "/fakt/bo/prm", "name": "Parametrar", "active": false };
      po.art = { "fid": "f05", "url": "/fakt/bo/art", "name": "Artiklar", "active": false };
      this.state.po = po;
      this.state.pa = [];
      this.state.pa = Object.values(po);
  }
    fknGo(row) {
        sso.seo.sfkn = row;
        this.props.navigate(row.url);
    }
    br_css(row) {
        var css = {};
        if(sso.seo.sfkn && (row.url == sso.seo.sfkn.url)) css.color = "#080";
        //var brc = false;
        //if(row.url == sbo.state.url) brc = true;
        return css;
    }

  render() {
    return (
        <div className="web-app">
            { this.props.store.cfg.bread ?
                <div className="web-bread">
                    <div className="breadcrumb">
                        { this.state.pa.map((row, key) =>
                            <div key={key} className="breadcrumb-item" style={this.br_css(row)} onClick={() => this.fknGo(row)}>{row.name}</div>
                        )}
                        <div className="flex"></div>
                        <div className="cdigreen">{ sso.seo.sfkn ? sso.seo.sfkn.name : null }</div>
                    </div>
                </div>
                : null }
        <Routes>
            <Route path="ftg" element={ <Bo_Ftg store={this.webStore} />} />
            <Route path="prm" element={ <Bo_Prm store={this.webStore} />} />
            <Route path="art" element={ <Bo_Art store={this.webStore} />} />
            <Route path="*" element={ <Bo_Home store={this.webStore} />} />
        </Routes>
        </div>
  );
}
}
//Sys.contextType = ADOContext;

export default withRouter(Fakt_Bo);
