import React, { useState, useEffect, useContext } from 'react';
import {format} from "date-fns";

import * as net from "../../../lib/net";
import Dt_Cal from "../../../lib/ui/dt_cal";

import Saldo_Dag from "./saldo/saldo_dag";
import Saldo_Dt from "./saldo/saldo_dt";
import Saldo_Per from "./saldo/saldo_per";
import Saldo_FT from "./saldo/saldo_ft";
import Saldo_Obet from "./saldo/saldo_obet";
import Saldo_Pres from "./saldo/saldo_pres";
import Krub_Prn from "./saldo/krub_prn";
import Krub_Recov from "./saldo/krub_recov";
import Dd_Sel from "../../../lib/ui/dd_sel";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Eko_Saldo extends React.Component {
  constructor(props, context) {
    super(props);

    this.dt_fd = React.createRef();
    this.dt_td = React.createRef();

    this.store = props.store;
    this.pms = props.store.pms;

    this.state = { pgid: 11, dta: [], rva: [] };
    this.state.fdat = new Date();
    this.state.tdat = new Date();
    this.state.unit = null;
    this.state.tot = {};
    this.state.wnds = {};
    this.state.sortid = "";

    let cdat = new Date();
    let formatted_date = cdat.getFullYear() + "-" + (cdat.getMonth() + 1) + "-" + cdat.getDate();
    this.state.today = formatted_date;
    this.state.fdat = formatted_date;
    this.state.fdat = formatted_date;
    if(this.pms.base) {
        this.state.fdat = this.pms.base.fdat;
        this.state.tdat = this.pms.base.tdat;
    }
    this.selitems = [
        {fkn: 'krub_recov', text: 'RECOVER KRUBBA'},
        {fkn: 'saldo_dt', text: 'DAGENS SALDO'},
        {fkn: 'saldo_per', text: 'PERIODENS SALDO'},
        {fkn: 'saldo_ft', text: 'FT SALDO'},
        {fkn: 'saldo_obet', text: 'PERIOD OBETALT'}
    ];
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.trans();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      css.overflow = "auto";
      return css;
  }
  th_style(id) {
      var css = {};
      css.cursor = "pointer";
      if(id == this.state.sortid) css.color = "#080";
      return css;
  }
  dt_fdat(e) {
    //alert(JSON.stringify(e));
    var dts = format(e, "yyyy-MM-dd");
    this.setState({ fdat: dts });
  }
  dt_tdat(e) {
      //alert(JSON.stringify(e));
    var dts = format(e, "yyyy-MM-dd");
    this.setState({ tdat: dts });
  }
  oneday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_td.current.updateDt(this.state.fdat);
  }
  addday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_fd.current.updateAdd();
    this.dt_td.current.updateAdd();
  }
  subday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_fd.current.updateSub();
    this.dt_td.current.updateSub();
  }


  day_open(row) {
      var wnds = {};
      wnds.saldodag = true;
      wnds.dts = row.k0datum;
      this.setState({wnds: wnds});
  };
  saldo_dt() {
      var wnds = {saldodt: true, datum: this.state.datum};
      this.setState({wnds: wnds});
  };
  saldo_per() {
      var wnds = {saldoper: true, datum: this.state.datum};
      this.setState({wnds: wnds});
  };
  saldo_ft() {
      var wnds = {saldoft: true, datum: this.state.datum};
      this.setState({wnds: wnds});
  };
  saldo_obet() {
      var wnds = {saldoobet: true, datum: this.state.datum};
      this.setState({wnds: wnds});
  };
    krub_prn() {
        var wnds = {};
        wnds.krubprn = true;
        wnds.fdat = this.state.fdat;
        wnds.tdat = this.state.tdat;
        this.setState({wnds: wnds});
    };
    krub_pres() {
        var wnds = {saldopres: true, fdat: this.state.fdat, tdat: this.state.tdat};
        this.setState({wnds: wnds});
    };
  krub_recov() {
      var wnds = {};
      wnds.krubrecov = true;
      wnds.fdat = this.state.fdat;
      wnds.tdat = this.state.tdat;
      this.setState({wnds: wnds});
  };

  saldo_ftx() {
    var self = this;
    //var fdatum = format(this.state.fdat, "yyyy-MM-dd");
    //var tdatum = format(this.state.tdat, "yyyy-MM-dd");

    var prm = {};
    prm.req = "pms.eko_red.saldo_ft";
    prm.fdat = this.state.fdat;
    prm.tdat = this.state.tdat;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            return;
        }
        var dta = ret.dta;
        var tot = ret.tot;
        self.setState({ dta: dta, tot: tot }, function () {
            self.syncTb("saldoTable");
        });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
    }
  krub_ft() {
    var self = this;
    //var fdatum = format(this.state.fdat, "yyyy-MM-dd");
    //var tdatum = format(this.state.tdat, "yyyy-MM-dd");

    var prm = {};
    prm.req = "pms.krub.krub_ft";
    prm.fdat = this.state.fdat;
    prm.tdat = this.state.tdat;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            return;
        }
        var dta = ret.dta;
        var tot = ret.tot;
        self.setState({ dta: dta, tot: tot }, function () {
            self.syncTb("saldoTable");
        });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
    }
  xkrub_recov() {
    var self = this;
    //var fdatum = format(this.state.fdat, "yyyy-MM-dd");
    //var tdatum = format(this.state.tdat, "yyyy-MM-dd");

    var prm = {};
    prm.req = "pms.krub.krub_recov";
    prm.fdat = this.state.fdat;
    prm.tdat = this.state.tdat;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            return;
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
    }

  val_pris(val) {
      var s = "";
      if(val == 0) s = "0.00";
      if(val) s = val.cdipris();
      return s;
  };
    syncTb(tb){
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
        tdElements = tbel.getElementsByTagName('td');

        for (let i = 0; i < thElements.length; i++) {
            if(!thElements[i]) continue;
            if(!tdElements[i]) continue;
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
            width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
   tb_sort(typ) {
      var self = this;
      //var rvs = self.state.rvs;
      var tra = self.state.tra;
      var sz = this.state.sz_val;

      tra.sort((a,b) => (a[typ] > b[typ]) ? 1 : ((b[typ] > a[typ]) ? -1 : 0));
      self.setState({ tra: tra, sortid: typ });
   }
  tb_filtr() {
      var self = this;
      var rvs = self.state.rvs;
      var sz = this.state.sz_val;

      if(sz.length < 1) {
        self.setState({ rva: rvs });
          return;
      }
      sz = sz.toUpperCase();
      var rva = rvs.filter(function(row) {
            var brc = false;
            if(row.company.toUpperCase().includes(sz)) brc = true;
            if(row.name.toUpperCase().includes(sz)) brc = true;
            return brc;
      });
      self.setState({rva: rva });
   }
  sel_fkn(sel) {
    //alert(JSON.stringify(fkn));

      if(sel.fkn == "krub_recov") {
          this.krub_recov();
      }
      if(sel.fkn == "saldo_dt") {
          this.saldo_dt();
      }
      if(sel.fkn == "saldo_per") {
          this.saldo_per();
      }
      if(sel.fkn == "saldo_ft") {
          this.saldo_ft();
      }
      if(sel.fkn == "saldo_obet") {
          this.saldo_obet();
      }
  }

  render() {
    return (
    <div className="web-app">
    <div className="">
        KRUBBA SALDO
    </div>

    <div className="flex-row flex-space-between">
        <div className="flex-row">
              <div className="dt-btn-01" onClick={() => this.subday() } ><FontAwesomeIcon icon="minus" size="1x" /></div>
              <Dt_Cal dts={ this.state.fdat } rp="1" ref={this.dt_fd} getValue={(e)=> this.dt_fdat(e)} />
              <div className="dt-btn-01" onClick={() => this.oneday() } >1</div>
              <Dt_Cal dts={ this.state.tdat } rp="1" ref={this.dt_td} getValue={(e)=> this.dt_tdat(e)} />
              <div className="dt-btn-01" onClick={() => this.addday() } ><FontAwesomeIcon icon="plus" size="1x" /></div>
        </div>
        <div className="flex"></div>
        <button className="btn btn-hw-green w150" onClick={() => this.krub_pres() } aria-label="System" >
            Presentkort
        </button>
        <button className="btn btn-hw-green w150" onClick={() => this.krub_prn() } aria-label="System" >
            Rapport
        </button>
        <button className="btn btn-hw-blue w150" onClick={() => this.krub_ft() } aria-label="System" >
            Uppdatera
        </button>
        <Dd_Sel items={ this.selitems } cls="web-x-icon" getValue={(e)=> this.sel_fkn(e)} />
    </div>
    <div className="divider"></div>

        <div id="saldoTable">
          <table className="table table-bordered table-hover" style={{marginBottom: "0px"}}>
            <thead>
            <tr>
                <th>Datum</th>
                <th>Fsg</th>
                <th>Hotell</th>
                <th>Rest(Överf)</th>
                <th>Bet</th>
                <th>Krubb förändring</th>
                <th>Krubba In</th>
                <th>Krubba Ut</th>
            </tr>
            </thead>
            </table>
            <div style={ this.tb_style() }>
          <table className="table table-bordered table-hover">
            <tbody>
            { this.state.dta.map((row, key) =>
            <tr key={key} onDoubleClick={() => this.day_open(row) }>
                <td>{ row.k0datum.cdidate() }</td>
                <td>{ this.val_pris(row.k0fsg) }</td>
                <td>{ this.val_pris(row.k0hot) }</td>
                <td>{ this.val_pris(row.k0rst) }</td>
                <td>{ this.val_pris(row.k0bet) }</td>
                <td>{ this.val_pris(row.k0saldo) }</td>
                <td>{ this.val_pris(row.k0in) }</td>
                <td>{ this.val_pris(row.k0ut) }</td>
            </tr>
            )}
           </tbody>
        </table>
        </div>
    </div>
        { this.state.wnds.saldodag ? <Saldo_Dag wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.wnds.saldodt ? <Saldo_Dt wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.wnds.saldoper ? <Saldo_Per wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.wnds.saldoft ? <Saldo_FT wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.wnds.saldoobet ? <Saldo_Obet wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.wnds.saldopres ? <Saldo_Pres wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }

        { this.state.wnds.krubprn ? <Krub_Prn wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.wnds.krubrecov ? <Krub_Recov wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }

    </div>
  );
}
}
export default Eko_Saldo;

/*
        <table className="table table-bordered table-hover tb fixed_header">
            <thead>
            <tr>
                <th>Datum</th>
                <th>Fsg</th>
                <th>Bet</th>
                <th>Krubba In</th>
                <th>Krubba Diff</th>
                <th>Krubba Ut</th>
                <th>Saldo</th>
            </tr>
            </thead>
            <tbody style={ this.tb_style() }>
            { this.state.dta.map((row, key) =>
            <tr key={key} onDoubleClick={() => this.trans_open(row) }>
                <td>{ row.datum }</td>
                <td>{ this.val_pris(row.fsg) }</td>
                <td>{ this.val_pris(row.bet) }</td>
                <td>{ this.val_pris(row.kin) }</td>
                <td>{ this.val_pris(row.kdiff) }</td>
                <td>{ this.val_pris(row.kut) }</td>
                <td>{ this.val_pris(row.saldo) }</td>
            </tr>
            )}
            </tbody>
        </table>

*/