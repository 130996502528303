import React, { useState, useEffect, useContext } from 'react';
import ReactDOM from 'react-dom';
import { SDOContext } from '../sdo.js'
import {withRouter} from "../lib/react/withRouter";

//import './css/style-liberty.css';

import * as net from "../lib/net";
import Dd_Sel from "../lib/ui/dd_sel";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function web01() {

    var html =
    <div className="">
        Test
    </div>
    ;
    return html;
}

class Login extends React.Component {
    //var sdo = useContext(SDOContext); // Functional
    static contextType = SDOContext; // Class

    constructor(props, context) {
        super(props);

        this.s1ref = React.createRef();
        this.s2ref = React.createRef();

        this.store = props.store;
        this.seo = props.store.seo;

        this.state = { pgid: 11, stat: [] };
        this.state.open = false;
        this.state.pdfopen = false;
        this.state.xxopen = false;
        this.state.pdf_uri = "";
        this.state.seldate = new Date('2020-03-24T13:11:54');
        this.state.row = null;
        this.state.info = "";
        this.state.top = 1;
        this.state.user = {};
        this.state.sdo = {};
        this.state.msg = {};

        this.state.step = 1;
        this.state.pdf = 0;
        this.state.pdf_uri = "";
        this.state.mnus = ["m00", "m01", "m02", "m03", "m08", "m09"];
        this.state.mnu_sel = "m00";
        this.state.systemtext = "Svepos Portal";
        this.selitems = [
            {fkn: 'setup', text: 'INSTÄLLNINGAR'},
            {fkn: 'upd', text: 'UPPDATERA'},
            {fkn: 'sys', text: 'SYSTEM'}
        ];

        if(this.store.cfg.brand == "cdi") {
            this.state.systemtext = "CDI PORTAL";
        }

    }
    componentDidMount(){
    }

    componentWillUnmount(){
    }
    logo(){
        var logo = null;
        if(this.store.cfg.logo == "cdi") logo = require('./img/logo-cdi.png');
        else if(this.store.cfg.logo == "svk") logo = require('./img/logo-sk.png');
        else logo = require('./img/logo-spaider.png');

        return logo;
    }
    onScroll(event) {
        var body = document.getElementById("web-body");
        if (body.scrollTop > 150) {
          this.setState({top: 0})
          this.setState({info: 'small'})
        } else {
          this.setState({top: 1})
          this.setState({info: 'big:'+body.scrollTop})
        }

        if (true) {
            var offset = body.scrollTop;
            var start = body.scrollTop;
            var slut = body.scrollTop + window.innerHeight;

            for(var mnuid of this.state.mnus) {
                const element = document.getElementById(mnuid);
                if(!element || (element == null) || (element == undefined)) continue;
                var eltop = element.offsetTop;
                var elbot = element.offsetTop + element.offsetHeight;
                var brc = eltop >= start && eltop <= slut;
                //if(!brc) brc = elbot >= start && elbot <= slut;
                if(brc) {
                    this.setState({mnu_sel: mnuid});
                    return;
                }
            }
        }
    }
   wnd_css() {
  	    var css = {};
		if(this.state.msg.err) {
			css.color = "#fff";
			css.background = "#800";
		}

		return css;
    }
   header_css() {
  	    var css = {};
		if(this.state.top) {
			css.color = "#fff";
			css.background = "transparent";
			css.height = "50px";
			//css["background"] = "green";
		}
		else {
			css.color = "#000";
			css.background = "#fff";
		    css.height = "50px";
        }

		return css;
    }
   ham_mnu_css() {
  	    var css = {};
		if(this.state.top) {
			css.display = "flex";
			css.display = "none";
		}
		else {
			css.display = "none";
        }

		return css;
    }
    header_class() {
  	    var cls = "flex_col";
		if(this.state.top) {
			cls = cls + " web-head_1";
		}
		else cls = cls +  " web-header";

		return cls;
    }

    ham_show() {
         this.setState({ isOpen: !this.state.isOpen });
   }
    ham_ol() {
        //alert("OL : ..");
         //this.setState({ isOpen: 0 });
   }
    sdo_upd() {
        var sdo = this.state.sdo;
        if(!sdo.fid) sdo.fid = "0";
        sdo.fid = ((sdo.fid * 1) + 1).toString();
        this.setState({sdo: sdo})
        sessionStorage.setItem("svp", JSON.stringify(this.state.sdo));
        //this.props.history.push("cas");
    }
    sdo_ctx() {
        sessionStorage.removeItem('svp');
        //sessionStorage.clear();
    }
    sdo_cas() {
        this.context.fid = "45";
        this.props.navigate("cas");
    }
    ct_show(id) {
        //alert(id);
        const element = document.getElementById(id);
        if (element) element.scrollIntoView();
        //const tesNode = ReactDOM.findDOMNode(id);
        //window.scrollTo(0, tesNode.offsetTop);
    }
    w00_css() {
  	    var css = {};
        css.height = window.innerHeight;
        css['minHeight'] = window.innerHeight;
		return css;
    }
    cdi_step() {
        //alert(id);
        var step = this.state.step + 1;
        if(step > 2) step = 1;
        this.setState({ step: step });
        if(step == 1) {
            var element = ReactDOM.findDOMNode(this.s1ref.current);
            element.classList.add("animated", "rotateIn");
            element.addEventListener('animationend', function() {
                element.classList.remove('animated', "rotateIn")
                //node.removeEventListener('animationend', handleAnimationEnd)
            })
        }
        if(step == 2) {
            var element = ReactDOM.findDOMNode(this.s2ref.current);
            element.classList.add("animated", "bounceIn");
            element.addEventListener('animationend', function() {
                element.classList.remove('animated', "bounceIn")
                //node.removeEventListener('animationend', handleAnimationEnd)
            })
        }

         setTimeout(() => {
            this.cdi_step();
        }, 5000);
    }
    cdi_show(id) {
        //alert(id);
        var brc = false;
        if(this.state.step == id) brc = true;
        return brc;
    }

    row_css(row) {
  	    var css = {};
		if(this.state.row) {
		if(this.state.row.ank == row.ank) {
			css.background = "#080";
			css.color = "#fff";
		}
		}
		return css;
    }
    login() {
        this.setState({login: true });
        //this.props.history.push("pos");
    }
    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    handleLogin(event) {
        this.login_srv(this.state.user.uid, this.state.user.pwd);
    }
	handleClose = () => {
		//this.props.ctx.setState({ login: false });
	};
    login_srv(uid, pwd) {
        var self = this;
		var prm = {};
        prm.req = "usr:login";
		prm.uid = uid;
		prm.pwd = pwd;
		prm.token = "";

        if(uid == "old") {
            var url = "/renew";
            this.props.navigate(url);
            return;
        }
        if(uid == "err") {
            var msg = { info: false, err: true, text: "Login fel ..."};
            self.setState({msg: msg });
            setTimeout(function(){
                self.setState({msg: {} });
            }, 3000);
            return;
        }
		//alert(JSON.stringify(uid));
        //gda.wndLoading("Hämtar");
        net.gcs_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret));

            if(ret.ok == "991") {
                var url = "/renew";
                self.props.navigate(url);
                return;
            }
            if(ret.ok != "000") {
                //alert(JSON.stringify(ret));
                var msg = { info: false, err: true, text: "Login fel ..."};
                self.setState({msg: msg });
                setTimeout(function(){
                    self.setState({msg: {} });
                }, 3000);
                return;
            }
            //alert(JSON.stringify(ret.seo));

            var seo = ret.seo;
            //alert(JSON.stringify(seo));
            self.props.store.seo = seo;
            //var xdo = JSON.stringify(seo);
            //alert(JSON.stringify(xdo));
            //sessionStorage.setItem("svp", xdo);
            var xso = {};
            xso.cfg = {};
            xso.seo = seo;
            xso.sdo = {};
            var xss = JSON.stringify(xso);
            //alert(JSON.stringify(xss));
            sessionStorage.setItem("svp_sso", xss);

            //var gdo = JSON.stringify(ret.gdo);
            //sessionStorage.setItem("gdo", gdo);

            var url = seo.auto;
            //if(url == "casino.ov") url = "cas.ov";
            //url = "/" + url.replace(/\./g,"/");
            if(true) url = "/hello";
            if(url == "eco") url = "/ecom";
            if(url == "fak") url = "/fakt";
            if(url == "tec") url = "/tech";
            if(url == "tel") url = "/tele";

            self.props.navigate(url);
            self.props.navigate(0);
        })
        .catch(function(e){
            //var txt = JSON.stringify(e);
            alert("LOGIN ERR: " + JSON.stringify(e));
            //gda.wndError(txt);
        });
    }
    register() {
        var self = this;
        //alert(JSON.stringify(fkn));
        var url = "/register";
        self.props.navigate(url);
    }
    sel_fkn(sel) {
        var self = this;
        //alert(JSON.stringify(fkn));
        if(sel.fkn == "logout") {
            var url = "/logout";
            self.props.navigate(url);
        }
        if(sel.fkn == "sys") {
            var self = this;
            var url = "/bo";
            self.props.navigate(url);
        }
    }

    render() {
        var stat = [];
        var keys = Object.keys(this.state.stat);
        for (var key of keys) {
            var row = this.state.stat[key];
            stat.push(row);
        }
        const handleDateChange = date => {
            this.setState({seldate: date });
        };

        return (
        <div className="web-app">

            <div id="header" className={ this.header_class() }>
            <div className="web-top-content d-flex flex-row">
                <div className="px-3 web-top-icon">
                    <FontAwesomeIcon icon="home" size="1x"/>
                </div>
                <div className="hide">
                    <img className="svk-kub" src={ require('./img/logo-kub.png') } />
                </div>
                <div className="web-top-mnu d-none d-md-inline-flex flex-row" onClick={() => this.mnu_sel("m00") }>
                    <div className="item">Inloggning</div>
                </div>
                <div className="flex-fill"></div>
                <div className="px-3 web-top-txt">
                    { this.seo.name }
                </div>
                <div className="px-3 web-top-icon" onClick={() => this.login() }>
                    <FontAwesomeIcon icon="user" size="1x"/>
                </div>
                <div className="">
                    <Dd_Sel items={ this.selitems } cls="web-top-icon" getValue={(e)=> this.sel_fkn(e)} />
                </div>
            </div>
            </div>

    <div className="web-body">

          <div className="d-flex flex-column align-items-center justify-content-center" style={{ height: "100%" }}>
            <div className="p-md-4 bg-w00" style={ this.wnd_css() }>
                <div className="">
                <div className="login__header text-center mb-lg-5 mb-4">
                    <h3 className="login__title mb-2"> Login</h3>
                    <p>{ this.state.systemtext }</p>
                </div>
                <div className="px-3">
                    { this.state.msg.text }
                </div>
                <div className="web-login">
                <div className="form-group">
                    <label className="">Användare</label>
                    <input type="text" name="uid" className="form-control" placeholder="" required="" autoFocus
                      value={this.state.uid} onChange={(event) => this.handleUserInput(event)}
                    />
                </div>
                <div className="form-group">
                    <label className="">Lösenord</label>
                    <input type="password" name="pwd" className="form-control input-style" placeholder="" required
                       value={this.state.pwd} onChange={(event) => this.handleUserInput(event)}
                    />
                </div>
                <div className="align-items-center justify-content-between py-2">
                    <button onClick={(event) => this.handleLogin(event) } className="btn btn-hw-blue w150">
                        Rensa
                    </button>
                    <button onClick={(event) => this.handleLogin(event) } className="btn btn-hw-green w150">
                        Logga in
                    </button>
                </div>
                <div className="form-check check-remember check-me-out mt-4">
                    <input type="checkbox" className="form-check-input checkbox" />
                    <label className="form-check-label checkmark">Kom ihåg mig</label>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                    <p className="signup mt-4">
                        Saknar Ni konto ? <span className="olink" onClick={() => this.register() }>Registrera Er</span>
                    </p>
                </div>
                </div>
            </div>
        </div>
        </div>

            <div className="">
                <img className="svk-logo" src={ this.logo() } />
            </div>
        </div>
        </div>
        );
    }
}

//WebPub.contextType = SDOContext; // Function
export default withRouter(Login);