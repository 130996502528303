import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";

import PdfRpDlg from "./fkn/pdf_rp_dlg";
import Dt_Cal from "../../lib/ui/dt_cal";
import {addDays, subDays, format} from "date-fns";

import BuntWnd from "./cc/bunt_wnd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Pos_CC extends React.Component {
  constructor(props, context) {
    super(props);

    this.dt_fd = React.createRef();
    this.dt_td = React.createRef();

    this.pos = props.store.pos;
    this.state = { pgid: 11 };
    this.state.datum = "";
    this.state.avd = "01";
    this.state.sdo = {};
    this.state.sdo.fsg = "";
    this.state.sdo.bet = "";
    this.state.dta = [];
    this.state.aa = [];
    this.state.wnds = {};

    this.state.pdf = 0;
    this.state.pdf_content = null;

    this.state.today = new Date();
    //var fdat = subDays(this.state.today, 15);
    //this.state.fdat = format(fdat, "yyyy-MM-dd");
    //this.state.tdat = format(this.state.today, "yyyy-MM-dd");
      this.state.tdat = format(this.state.today, "yyyy-MM-01");
      this.state.tdat = format(this.state.today, "yyyy-MM-dd");
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      this.cc_dts();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 300;
      return css;
  }
  pdf_done (ret) {
    this.setState({loading: 0, pdf: 0, pdf_content: null });
  }
  bunt_show (dto) {
      //alert(JSON.stringify(dto.bunt));
      var wnds = {};
      wnds.bunt = 1;
      wnds.dto = dto;
    this.setState({wnds: wnds });
  }

  cc_dts() {
    var self = this;

    	var prm = {};
     	prm.req = "pos.pos_eko.trh_cc";
     	//prm.req = "pos.pos_red.jr_red";
   		prm.avd = this.state.avd;
   		prm.fdat = this.state.fdat;
   		prm.tdat = this.state.tdat;
   		prm.favd = this.state.avd;
   		prm.tavd = this.state.avd;

        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret.rco));

            var dta = [];
            var dto = ret.rco;
            dta = Object.values(dto);
            self.setState({ dta: dta });
            //toastr.success("OK", "SAPI");
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
            //gda.wndError(txt);
        });
  }
  rp_trs() {
    var self = this;
    	var prm = {};
     	prm.req = "pos.pos_rp.rp_trs";
   		prm.fdat = this.state.fdat;
   		prm.tdat = this.state.tdat;
   		prm.favd = this.state.avd;
   		prm.tavd = this.state.avd;

        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret.pdf));

        self.setState({pdf: 1, pdf_content: ret.pdf });
            //toastr.success("OK", "SAPI");
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
            //gda.wndError(txt);
        });
  }
  dt_fdat(e) {
    //alert(JSON.stringify(e));
    var dts = format(e, "yyyy-MM-dd");
    this.setState({ fdat: dts });
  }
  dt_tdat(e) {
      //alert(JSON.stringify(e));
    var dts = format(e, "yyyy-MM-dd");
    this.setState({ tdat: dts });
  }
  oneday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_td.current.updateDt(this.state.fdat);
  }
  addday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_fd.current.updateAdd();
    this.dt_td.current.updateAdd();
  }
  subday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_fd.current.updateSub();
    this.dt_td.current.updateSub();
  }

  render() {
    return (
    <div className="web-p00 flex flex-col">
        <div className="">
            Kort
        </div>

    <div className="web-p01">
    <div className="flex-row">
        <div className="flex-row">
              <div className="dt-btn-01" onClick={() => this.subday() } ><FontAwesomeIcon icon="minus" size="1x" /></div>
              <Dt_Cal dts={this.state.fdat} rp="1" ref={this.dt_fd} getValue={(e)=> this.dt_fdat(e)} />
              <div className="dt-btn-01" onClick={() => this.oneday() } >1</div>
              <Dt_Cal dts={this.state.tdat} rp="1" ref={this.dt_td} getValue={(e)=> this.dt_tdat(e)} />
              <div className="dt-btn-01" onClick={() => this.addday() } ><FontAwesomeIcon icon="plus" size="1x" /></div>
        </div>
        <div className="flex"></div>
        <button className="btn btn-hw-blue w150" onClick={() => this.rp_trs() } aria-label="System" >
            SKRIV UT
        </button>
        <button className="btn btn-hw-blue w150" onClick={() => this.cc_dts() } aria-label="System" >
            UPPDATERA
        </button>
    </div>
    <div className="divider_orange"></div>

    <div className="flex-column">
        <table className="table table-bordered table-hover tb fixed_header">
            <thead>
            <tr>
                <th>Datum</th>
                <th>Omsättning</th>
                <th>Kort Antal</th>
                <th>Kort Fsg</th>
                <th>Bunt Antal</th>
                <th>Bunt Fsg</th>
                <th>T001</th>
                <th>T002</th>
                <th>T003</th>
                <th>Signatur</th>
            </tr>
            </thead>
            <tbody style={ this.tb_style() }>
            { this.state.dta.map((row, key) =>
            <tr key={key} onDoubleClick={() => this.bunt_show(row) } >
                <td>{ row.datum }</td>
                <td className="text-center">{ row.brutto.cdipris() }</td>
                <td className="text-center">{ row.ccant }</td>
                <td className="text-right">{ row.ccbel.cdipris() }</td>
                <td className="text-right">{ row.buntant }</td>
                <td className="text-right">{ row.buntbel.cdipris() }</td>
                <td className="text-right">{ row.cc001.cdipris() }</td>
                <td className="text-right">{ row.cc002.cdipris() }</td>
                <td className="text-right">{ row.cc003.cdipris() }</td>
                <td>{ row.r0sign }</td>
            </tr>
            )}
            </tbody>
        </table>
        <div className="divider_orange"></div>
        <div className="flex-row">
            <span className="flex">TOTAL: { this.state.dta.length }</span>
            <span className="flex">KORT: { this.state.sdo.fsg.cdipris() }</span>
            <span className="flex">BUNT: { this.state.sdo.bet.cdipris() }</span>
        </div>
    </div>

    </div>

        { this.state.wnds.bunt ? <BuntWnd wnds={this.state.wnds} ctx={this} store={this.store} backdrop={true}/> : null }
        <PdfRpDlg show={this.state.pdf} pdf={this.state.pdf_content} done={(ret) => this.pdf_done(ret)} backdrop={true}/>
    </div>
  );
    }
}
export default Pos_CC;
