import React, { useState, useEffect, useContext } from 'react';
import * as net from "../lib/net";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Tech_Home extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, tra: [] };
    //context.posrend = this;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.props.fetchWells()
    //  .then(res => this.setState({ wells: res.wells }) )
    //this.trans();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 200;
      return css;
  }

  trans() {
      var self = this;
    var datum = "20200328";
    var prm = {};
    prm.req = "pos.pos_cas.trs_dt";
    prm.fdat = datum;
    prm.tdat = datum;

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        var o = {};
        //o.deps = ret.rco;
        //alert(JSON.stringify(o));
        self.setState({tra: ret.rca });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
    }

    render() {
        return (
        <div className="web-app">
            <div className="web-pg py-4 flex">
                <h3>Tekniker</h3>
                <p>Tekniker funktioner</p>
    
            <div className="web-row">
                <div className="flex-col flex px-1">
                    <div className="web-bx" onClick={() => this.xrecov() }>
                        <div>Kontrollera Period</div>
                        <div className="flex-row">
                            <div className="flex">X-Recov mm</div>
                            <div className="web-link">Välj</div>
                        </div>
                    </div>
                    <div className="web-bx" onClick={() => this.drecov() }>
                        <div>Återskapa Dag</div>
                        <div className="flex-row">
                            <div className="flex">Reparera trasig dag</div>
                            <div className="web-link">Välj</div>
                        </div>
                    </div>
                    <div className="web-bx" onClick={() => this.precov() }>
                        <div>Återskapa Period</div>
                        <div className="flex-row">
                            <div className="flex">Reparera trasig period</div>
                            <div className="web-link">Välj</div>
                        </div>
                    </div>
                    <div className="web-bx" onClick={() => this.creat_day() }>
                        <div>Skapa saknad dag</div>
                        <div className="flex-row">
                            <div className="flex">Skapa saknad dag</div>
                            <div className="web-link">Välj</div>
                        </div>
                    </div>
                </div>
    
                <div className="flex-col flex px-1">
                    <div className="web-bx" onClick={() => this.fknGo("/pos/tech/cpydep") }>
                        <div>Kopiera Avdelning</div>
                        <div className="flex-row">
                            <div className="flex"></div>
                            <div className="web-link">Välj</div>
                        </div>
                    </div>
                    <div className="web-bx" onClick={() => this.btn_fkns() }>
                        <div>Kopiera Knappar</div>
                        <div className="flex-row">
                            <div className="flex"></div>
                            <div className="web-link">Välj</div>
                        </div>
                    </div>
                    <div className="divider_orange"></div>
                    <div className="web-bx" onClick={() => this.swish_tst() }>
                        <div>Swish Test</div>
                        <div className="flex-row">
                            <div className="flex"></div>
                            <div className="web-link">Välj</div>
                        </div>
                    </div>
                    <div className="web-bx" onClick={() => this.webkassa() }>
                        <div>Web Kassa</div>
                        <div className="flex-row">
                            <div className="flex"></div>
                            <div className="web-link">Välj</div>
                        </div>
                    </div>
                    <div className="web-bx" onClick={() => this.west_trs() }>
                        <div>West Transaktioner</div>
                        <div className="flex-row">
                            <div className="flex"></div>
                            <div className="web-link">Välj</div>
                        </div>
                    </div>
                    <div className="web-bx" onClick={() => this.trs_per_upd() }>
                        <div>Per Transaktioner Upd</div>
                        <div className="flex-row">
                            <div className="flex"></div>
                            <div className="web-link">Välj</div>
                        </div>
                    </div>
                </div>
    
                <div className="flex-col flex px-1">
                    <div className="web-bx" onClick={() => this.fknGo("/pos/tech/tdef") }>
                        <div>Terminaldefinition</div>
                        <div className="flex-row">
                            <div className="flex"></div>
                            <div className="web-link">Välj</div>
                        </div>
                    </div>
                    <div className="web-bx" onClick={() => this.prn_tst() }>
                        <div>Skrivare</div>
                        <div className="flex-row">
                            <div className="flex"></div>
                            <div className="web-link">Välj</div>
                        </div>
                    </div>
                    <div className="web-bx" onClick={() => this.prn_direct() }>
                        <div>Skrivare Skriv</div>
                        <div className="flex-row">
                            <div className="flex"></div>
                            <div className="web-link">Välj</div>
                        </div>
                    </div>
                    <div className="web-bx" onClick={() => this.fknGo("/pos/tech/clr") }>
                        <div>Rensa Filer</div>
                        <div className="flex-row">
                            <div className="flex"></div>
                            <div className="web-link">Välj</div>
                        </div>
                    </div>
                </div>
            </div>
    
    
        </div>
    
        </div>
      );
        }
    }
export default Tech_Home;
