import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../../lib/net";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Reg_Signs extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, tra: [] };
    this.state.seldate = new Date();
    this.state.avd = "01";
    this.state.sia = [];
    this.state.kro = {};
    this.state.aa = [];
    this.state.cols = {};
    //context.posrend = this;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.signs();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

  kund_open(row) {
      var self = this;
  }
  kund_edit(row) {
      var self = this;
      //alert(JSON.stringify(row.krr00));
        this.setState({edit: 1, kro: row});
  }
  fkn_close() {
    this.setState({edit: false });
    //this.props.history.push("cas");
    //this.login_srv(uid, pwd);
  }

  signs() {
    var self = this;
    var prm = {};
    prm.req = "pms.sign.signs";
    prm.fdat = "20200915";
    prm.tdat = "20200915";
    //prm.avd = this.state.avd;

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        self.setState({sia: ret.rco }, function () {
            self.syncCols("signTable");
        });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e) {
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }
  cssCols = function (cid) {
      var css = {};
      if(this.state.cols[cid]) css.width = this.state.cols[cid].toString() + "px";
      //if(cid == "c01") alert(JSON.stringify(this.state.cols[cid]));
      return css;
  }
  syncCols = function (tb) {

    if (!document.getElementById(tb)) return;

    var cols = {};
    var ix = document.getElementById(tb).rows.length - 1;
    var ic = document.getElementById(tb).rows[0].cells.length;
    var ci = 0;
    while(ci < ic) {
        var cxi = ci + 1;
        var col = "c" + cxi.toString().padStart(2, "0");
        cols[col] = document.getElementById(tb).rows[ix].cells[ci].clientWidth + 1;
        ci = ci + 1;
    }
    this.setState({cols: cols });
    //alert(JSON.stringify(this.state.cols));
    //this.forceUpdate();
  };

  render() {
    return (
    <div className="web-app">
    <div className="flex-row flex-space-between">
        <div className="flex-row flex">
        <button className="btn btn-hw-blue w150 ripple" onClick={() => this.syncCols("kidTable") } aria-label="System" >
            FUNKTIONER
        </button>
        </div>
        <div className="flex-row">
       </div>
        <div className="flex-row flex-end flex">
        <button className="btn btn-hw-blue w150 ripple" onClick={() => this.syncCols("kidTable") } aria-label="System" >
            UPPDATERA
        </button>
        </div>
    </div>
    <div className="divider_orange"></div>

    <div className="web-col">
        <table className="table table-bordered table-hover tb " style={{marginBottom: "0px"}}>
            <thead>
            <tr>
                <th style={ this.cssCols("c01" ) }>Signatur</th>
                <th style={ this.cssCols("c02" ) }>Text</th>
                <th style={ this.cssCols("c03" ) }>Kort</th>
                <th style={ this.cssCols("c04" ) }>Kod</th>
                <th style={ this.cssCols("c05" ) }>Avd</th>
                <th style={ this.cssCols("c06" ) }>Kassalåda</th>
                <th style={ this.cssCols("c07" ) }>Nyckelnr</th>
                <th style={ this.cssCols("c08" ) }>Epost</th>
                <th style={ this.cssCols("c09" ) }>Uppdat</th>
                <th style={ this.cssCols("c10" ) }></th>
                <th style={{width:"16px"}} ></th>
            </tr>
            </thead>
        </table>
       <div className="tb-cdi" style={ this.tb_style() }>
       <table id="signTable" className="table table-bordered table-hover tb">
            <tbody>
            { this.state.sia.map((row, key) =>
            <tr key={key} id={ row.s0sig } onDoubleClick={() => this.kund_edit(row) }>
                <td>{ row.s0sig }</td>
                <td>{ row.s0namn }</td>
                <td>{ row.s0kort }</td>
                <td>{ row.s0kod }</td>
                <td>{ row.s0avd }</td>
                <td>{ row.s0draw }</td>
                <td>{ row.s0keyno }</td>
                <td>{ row.s0email }</td>
                <td>{ row.s0upddat }</td>
                <td style={{width:"40px",cursor:"pointer"}} onClick={() => this.kund_edit(row) }><i className="fa fa-cog text-danger"></i></td>
            </tr>
            )}
            <tr style={{visibility: "hidden"}}>
                <th>Signatur</th>
                <th>Text</th>
                <th>Kort</th>
                <th>Kod</th>
                <th>Avd</th>
                <th>Kassalåda</th>
                <th>Nyckelnr</th>
                <th>Epost</th>
                <th>Uppdat</th>
                <th></th>
            </tr>
            </tbody>
        </table>
        </div>
    </div>
   </div>
  );
    }
}
export default Reg_Signs;
