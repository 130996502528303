import React, { useState, useEffect, useContext } from 'react';
import {format} from "date-fns";

import * as utils from "../../../assets/utils";
import * as net from "../../../lib/net";
import Td_Sel from "../../../lib/ui/td_sel";

import Pay_Wnd from "./pay/pay_wnd";
import Pay_Emv from "./pay/pay_emv";

class Wcm_Wnd extends React.Component {
  constructor(props, context) {
    super(props);

    this.seo = props.store.seo;
    this.store = props.store;

     this.rowitems = [
        {fkn: 'open', text: 'ÖPPNA'},
        {fkn: 'corr', text: 'KORRIGERA'}
    ];
    this.state = {};
    this.state.ynq = [];
    this.state.mka = [];

    this.state.paa = [
                { plu00: {p0katnr: '1001', p0namn: 'Prod 11', p0pris: '2500'}},
                { plu00: {p0katnr: '1002', p0namn: 'Prod 22', p0pris: '25500'}},
                { plu00: {p0katnr: '1003', p0namn: 'Prod 33', p0pris: '33500'}}
            ];
    this.state.vga = [
                {v0katnr: '001', v0text: 'VGRP 001'},
                {v0katnr: '002', v0text: 'VGRP 002'},
                {v0katnr: '009', v0text: 'VGRP 009'}
            ];
    this.state.bgo = {};
    this.state.bgo[0] = [
                {grp: '001', text: 'VGRP 001'},
                {grp: '002', text: 'VGRP 002'},
                {grp: '003', text: 'VGRP 003'},
                {grp: '004', text: 'VGRP 004'},
                {grp: '005', text: 'VGRP 005'},
                {grp: '006', text: 'VGRP 006'}
            ];
    this.state.bgo[1] = [
                {grp: '007', text: 'VGRP 007'},
                {grp: '008', text: 'VGRP 008'},
                {grp: '009', text: 'VGRP 009'},
                {grp: '010', text: 'VGRP 010'},
                {grp: '011', text: 'VGRP 011'},
                {grp: '012', text: 'VGRP 012'}
            ];

    this.state.wnds = {};
    this.state.ix = 0;

    this.state.boknr = "B9999";
    this.state.tot = {};
    this.state.tot.open = "";
    this.state.srow = {};
    this.state.gso = {};
    this.state.gaa = [];
    this.state.baa = [];
    this.state.nta = [];

    this.state.today = new Date();
    this.state.dts = format(this.state.today, "yyyy-MM-dd");
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      this.petty_grps();
      //this.petty_btns("01");
      //this.dd_init();
  }

  petty_grps() {
    var grps = {};
    var gaa = [];

    gaa.push({ grp: "01", text: "MINIBAR" });
    gaa.push({ grp: "02", text: "VIP BESTÄLLNING" });
    gaa.push({ grp: "03", text: "DORSIA PRODUKTER" });
    gaa.push({ grp: "04", text: "LILY & ROSE" });
    gaa.push({ grp: "05", text: "PRODUKTER" });
    gaa.push({ grp: "06", text: "GLAS & BESTICK" });
    gaa.push({ grp: "07", text: "HOTELL" });
    gaa.push({ grp: "08", text: "PRESENTKORT" });
    gaa.push({ grp: "09", text: "ÖPPNA PRODUKTER" });

    this.setState({gaa: gaa});
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    tab_style(tabid) {
      var css = {};
      css.display = "none";
      if(this.state.tabval === tabid) css.display = "block";
      return css;
    }
    tab_head(tabid) {
      var css = {};
      css.background = "#fff";
      css.color = "#000";
      if(this.state.tabval === tabid) {
          css.background = "#080";
          css.color = "#fff";
      }
      return css;
    }

    wnd_close = () => {
		this.props.ctx.setState({ wnds: {} });
	};
    handleChange = (event, newValue) => {
        //alert(newValue);
        this.setState({ix: newValue});
      };
  tb_style() {
      var css = {};
      css.height = (window.innerHeight * 0.5) - 250;
      css.overflow = "auto";
      return css;
  }
  row_style(row) {
    var srow = this.state.srow;
    var css = {};
    if(srow[row.etr01.e0lopnr]) {
        css.background = "#080";
        css.color = "#fff";
    }
    return css;
  }

  notor() {
    var self = this;
    var wnds = {};
    wnds.notor = 1;
    wnds.boknr = this.state.boknr;
    wnds.gso = this.state.gso;
    this.setState({wnds: wnds});
  };

  grp_sel(grp) {
      this.petty_btns(grp.grp);
  };

  btn_sel(btn) {
      //alert(JSON.stringify(btn.e1bastyp));
      if(btn.e1bastyp == "T") this.sell_tr(btn);
      if(btn.e1bastyp == "B") this.sell_pay(btn);
  };

  sz() {
    var self = this;
    var wnds = {};
    wnds.prdsz = 1;
    wnds.boknr = self.state.boknr;
    wnds.gso = self.state.gso;
    wnds.nta = self.state.nta;
    wnds.cb = this.cb_sz;
    this.setState({wnds: wnds});
  };
  cb_sz(ctx, ret) {
      if(ret.ok == "000") {
          ctx.charges();
      }
    ctx.setState({wnds: {}});
  }
  pay(betkod) {
      ///alert(JSON.stringify(betkod));
      if(betkod == "kont") {
        this.pay_tr("kont", "KONTANT");
      }
      if(betkod == "swis") {
        this.pay_tr("swis", "SWISH");
      }
  };
  pay_select() {
    var self = this;
    var wnds = {};
    wnds.paysel = 1;
    wnds.boknr = self.state.boknr;
    wnds.gso = self.state.gso;
    wnds.nta = self.state.nta;
    wnds.cb = self.cb_paysel;
    this.setState({wnds: wnds});
  };
  cb_paysel(ctx, ret) {
      if(ret && (ret.ok == "000")) {

      }
      ctx.setState({ wnds: {}, srow: {} });
      ctx.charges();
  };
  pay_emv() {
    var self = this;

    if(this.state.nta.length < 1) {
        alert("Inga rader betala");
        return;
    }

    var pra = [];
    //pra.push( this.state.selrow.etr01);
    var belopp = 0;
    for(var row of this.state.nta) {
        var pris = (row.etr01.e0antal * 1) * (row.etr01.e0pris * 1);
        if(row.etr01.e0tkn == "-") pris = pris * (-1);
        belopp = belopp + pris
        pra.push( row.etr01 );
    }

    if(belopp == 0) {
        alert("Inget att betala");
        return;
    }
    belopp = belopp.toString();

    var wnds = {};
    wnds.emv = 1;
    wnds.boknr = this.state.boknr;
    wnds.gso = this.state.gso;
    wnds.nta = this.state.nta;
    wnds.belopp = belopp;
    wnds.cb = this.cb_payemv;
    this.setState({wnds: wnds});
  };
  cb_payemv(ctx, ret) {
      if(ret && (ret.ok == "000")) {

      }
      ctx.setState({ wnds: {}, srow: {} });
      ctx.charges();
  };

  corr() {
    var self = this;
      var last = this.state.nta.slice(-1)[0];
      //var last = this.state.nta.slice(-1).pop();
    var tro = last.etr01;
      self.corr_tr(tro);
  };

  dd_init() {
    var self = this;

    var prm = {};
    prm.req = "pms.gst.gsb";
    prm.boknr = "B9999";
    prm.glopnr = "00001";

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rco));

        self.setState({gso: ret.rco });

        var prm = {};
        prm.req = "pms.charge.charges_petty";
        prm.boknr = self.state.boknr;
        prm.glopnr = self.state.gso.r0glopnr;
        prm.datum = self.state.dts;
        return net.sio_req(prm);
    })
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        var tot = ret.tot;
        var nta = ret.rca;
        var pra = [];
        for(var row of nta) {
            pra.push( row.etr01);
        }
        self.setState({ nta: nta, pra: pra, tot: tot }, function () {
            self.syncTb("notaTable");
        });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  petty_init() {
    var self = this;

    var prm = {};
    prm.req = "pms.charge_fkn.petty_init";
    //prm.boknr = this.state.rvo.boknr;

    net.sio_req(prm)
    .then(function(ret){
        alert(JSON.stringify(ret.rco));

        //self.setState({rva: ret.rco });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  petty_btns(grp) {
    var self = this;

    var prm = {};
    prm.req = "pms.tr.btns";
    prm.typ = "T";
    prm.grp = grp;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rco));

        self.setState({baa: ret.rca });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

  sell_tr(tro) {
    var self = this;

    var wnds = {};
    wnds.selltr = 1;
    wnds.boknr = this.state.boknr;
    wnds.gso = this.state.gso;
    wnds.tro = tro;
    wnds.cb = this.cb_sell_tr;
    this.setState({wnds: wnds});
  }

  cb_sell_tr(ctx, ret) {
      if(ret.ok == "000") {
          ctx.charges();
      }
    ctx.setState({wnds: {}});
  }

  sell_pay(tro) {
    var self = this;

    var wnds = {};
    wnds.sellpay = 1;
    wnds.boknr = this.state.boknr;
    wnds.gso = this.state.gso;
    wnds.tro = tro;
    wnds.cb = this.cb_sell_pay;
    this.setState({wnds: wnds});
  }

  cb_sell_pay(ctx, ret) {
      if(ret.ok == "000") {
          ctx.charges();
      }
    ctx.setState({wnds: {}});
  }

  xsell_tr(tro) {
    var self = this;

    //alert(JSON.stringify(this.state.rvo.gsb00));
    //alert(JSON.stringify(this.state.gso));
    //return;
    var prm = {};
    prm.req = "pms.sell.charge_tr";
    prm.boknr = this.state.boknr;
    prm.gsb00 = this.state.gso;
    prm.trkod = tro.id;
    prm.text = tro.text;
    prm.antal = "1";
    prm.pris = tro.pris;
    prm.tkn = "+";
    prm.moms = tro.moms;
    prm.datum = this.state.dts;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rco));

        //self.setState({rva: ret.rco });
        self.charges();
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  corr_tr(tro) {
    var self = this;

    var prm = {};
    prm.req = "pms.sell.corr_tr";
    prm.boknr = this.state.boknr;
    prm.etr01 = tro;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rco));

        //self.setState({rva: ret.rco });
        self.charges();
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  charges() {
    var self = this;
    var prm = {};
    prm.req = "pms.charge.charges_petty";
    prm.boknr = this.state.boknr;
    prm.glopnr = this.state.gso.r0glopnr;
    prm.datum = this.state.dts;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        var tot = ret.tot;
        var nta = ret.rca;
        var pra = [];
        for(var row of nta) {
            pra.push( row.etr01);
        }
        self.setState({ nta: nta, pra: pra, tot: tot }, function () {
            self.syncTb("notaTable");
        });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
    syncTb(tb){
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
        tdElements = tbel.getElementsByTagName('td');

        for (let i = 0; i < thElements.length; i++) {
            if(!tdElements[i]) continue;
             const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
            width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
  pay_toinv() {
    var self = this;

    var pra = [];
    for(var row of this.state.nta) {
        pra.push(row.etr01);
    }

    var prm = {};
    prm.req = "pms.charge_fkn.charges_toinv";
    prm.boknr = this.state.boknr;
    prm.gsb00 = this.state.gso;
    prm.datum = this.state.dts;
    prm.payrows = pra;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        //self.setState({ nta: nta, pra: pra, tot: tot });
        //self.syncTb("notaTable");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  pay_tr(betkod, bettext) {
    var self = this;

    if(this.state.nta.length < 1) {
        alert("Inga rader betala");
        return;
    }

    var pra = [];
    //pra.push( this.state.selrow.etr01);
    var belopp = 0;
    for(var row of this.state.nta) {
        var pris = (row.etr01.e0antal * 1) * (row.etr01.e0pris * 1);
        if(row.etr01.e0tkn == "-") pris = pris * (-1);
        belopp = belopp + pris
        pra.push( row.etr01 );
    }

    if(belopp == 0) {
        alert("Inget att betala");
        return;
    }
    belopp = belopp.toString();

    var wnds = {};
    wnds.pay = 1;
    wnds.boknr = this.state.boknr;
    wnds.gso = this.state.gso;
    wnds.pra = pra;
    wnds.betkod = betkod;
    wnds.bettext = bettext;
    wnds.belopp = belopp;
    wnds.cb = this.cb_pay;
    this.setState({wnds: wnds});
  };

  cb_pay(ctx, ret) {
      if(ret && (ret.ok == "000")) {

      }
      ctx.setState({ wnds: {}, srow: {} });
      ctx.charges();
  };

  row_sel(row) {
    var self = this;
    var wnds = {};
    wnds.pettyrad = true;
    wnds.boknr = self.state.boknr;
    wnds.gso = self.state.gso;
    wnds.row = row;
    wnds.fkn_close = self.wnds_close;
    self.setState({ wnds: wnds });
  };
  sel_row(row) {
    var srow = this.state.srow;

    if(srow[row.etr01.e0lopnr]) {
      delete( srow[row.etr01.e0lopnr] );
    }
    else srow[row.etr01.e0lopnr] = row;
    this.setState({srow: srow});
  }
  sel_row_fkn(row, e) {
    var self = this;
    //alert(JSON.stringify(fkn));
      if(e.fkn == "corr") {
      }
      if(e.fkn == "open") {
          self.row_sel(row);
      }
  }
    wnds_close(ctx) {
		ctx.setState({ wnds: {} });
      ctx.charges();
	};
  term() {
    var self = this;
    var wnds = {};
    wnds.term = true;
    wnds.fkn_close = self.wnds_close;
    self.setState({ wnds: wnds });
  };
  toswe(s) {
      if(!s) s = "";
      s = s.toswe();
      return s;
    };
  topris(s) {
      if(!s) s = "";
      s = s.cdipris();
      return s;
    };

  render() {
    //if(!this.props.show) return "";

    //alert(this.props.ctx.state.lro);

    var title = this.props.title;
    if(!title) title = "Knappar";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="mx_backdrop" /> : null }
        <div className="mx_dlg mx_100 flex-col" style={ this.dlg_style() }>


        <div className="flex-row" style={{padding:"0px",height:"50px",lineHeight:"50px", background: "#1a456b", color: "#fff"}}>
            <div className="flex-row flex" style={{padding:"0px 5px", fontSize: "22px"}}>
                <div>WEB KASSA</div>
            </div>
            <div className="web-row flex" style={{padding:"0px 5px",height:"40px",lineHeight:"20px", fontSize: "22px"}}>
                <div className="web-col" onClick={() => this.fknSel("rvo") } >
                    <div style={{fontSize: "12px"}}>Totalt</div>
                    <div>{ this.state.tot.open.cdipris() }</div>
                </div>
                <div className="web-col flex" onClick={() => this.fknSel("rvo") } >
                </div>
                <div className="web-col" onClick={() => this.fknSel("rvo") } >
                    <div style={{fontSize: "12px"}}>Totalt</div>
                    <div>{ this.state.tot.open.cdipris() }</div>
                </div>
           </div>
            <div className="flex-row w200">
            <div className="flex"></div>
            <div className="web-top-sep"></div>
            <div className="web-top-usr d-flex flex-column" onClick={(e) => this.term() }>
                { (this.seo.termnr && (this.seo.termnr != "")) ?
                <div style={{padding: "0px", margin: "0px" }}>
                <div className="txt_10">TERMNR</div>
                <div className="txt_12 text-center">{ this.seo.termnr }</div>
                </div>
                :
                <div style={{padding: "0px", margin: "0px" }}>
                <div className="txt_10">STANDARD</div>
                <div className="txt_10">TERMINAL</div>
                </div>
                }
            </div>
            <div className="web-top-sep"></div>
            <div className="wnd-close-btn" onClick={() => this.wnd_close() }>X</div>
            </div>
        </div>

        <div className="mx_content flex">

        <div className="web-row flex">

        <div className="web-col" style={{borderRight: "1px solid #ff9900"}}>
            { this.state.gaa.map((grp, key) =>
            <div key={key} className="nota-grp-btn" onClick={() => this.grp_sel(grp) } >
                <div>{ grp.text }</div>
                <div>{ grp.grp }</div>
            </div>
            )}
        </div>

        <div className="web-col flex">
        <div className="web-row flex">
        <div className="web-col flex" style={{padding: "0px"}}>

        <div id="notaTable" className="flex">
          <table className="table table-bordered table-hover tb" style={{marginBottom: "0px"}}>
            <thead>
            <tr>
                <th>Datum</th>
                <th>T</th>
                <th>Typ</th>
                <th>Text</th>
                <th>Antal</th>
                <th>Pris</th>
                <th>Summa</th>
                <th>Tecken</th>
                <th>Löpnr</th>
                <th></th>
            </tr>
            </thead>
        </table>
        <div style={ this.tb_style() }>
          <table className="table table-bordered table-hover tb">
            <tbody>
            { this.state.nta.map((row, key) =>
            <tr key={key} style={ this.row_style(row) } onClick={ () => this.sel_row(row) } onDoubleClick={ () => this.row_sel(row) }>
                <td>{ utils.toDate( row.etr01.e0datum ) }</td>
                <td>{ row.etr01.e0bastyp }</td>
                <td>{ row.etr01.e0typ }</td>
                <td>{ utils.toLang( row.etr01.e0text ) }</td>
                <td className="text-center">{ utils.toNum( row.etr01.e0antal ) }</td>
                <td className="text-right">{ utils.toPris( row.etr01.e0pris ) }</td>
                <td className="text-right">{ utils.toPris( ((row.etr01.e0antal * 1) * (row.etr01.e0pris * 1)) ) }</td>
                <td>{ row.etr01.e0tkn }</td>
                <td>{ utils.toNum( row.etr01.e0lopnr ) }</td>
                <td>
                    <Td_Sel items={ this.rowitems } getValue={(e)=> this.sel_row_fkn(row, e)} />
                </td>
            </tr>
            )}
            </tbody>
        </table>
        </div>
        </div>

        </div>

         <div className="web-col" style={{padding: "0px", borderLeft: "1px solid #080", height: "100%"}}>
            <div className="nota-pay-btn" onClick={() => this.wnd_close() } >
                <div>STÄNG</div>
                <div></div>
            </div>
            <div className="nota-pay-btn" onClick={() => this.charges() } >
                <div>UPPDATERA</div>
                <div></div>
            </div>
            <div className="nota-pay-btn" onClick={() => this.notor() } >
                <div>NOTOR</div>
                <div></div>
            </div>
             <div className="flex"></div>
            <div className="nota-pay-btn hide" onClick={() => this.pay_toinv() } >
                <div>NOTA SÄTT</div>
                <div></div>
            </div>
           <div className="nota-pay-btn" onClick={() => this.corr() } >
                <div>KORR</div>
                <div></div>
            </div>
       </div>
       </div>

         <div className="divider_orange"></div>
       <div className="web-col">

       <div className="web-row">
       <div className="web-col flex">

       <div className="web-row" style={{ height: "350px" }}>
        <div className="bv">
        <div className="bv-grid">
        { this.state.baa.map((btn, key) =>
            <div key={key} className="nota-sell-btn" onClick={() => this.btn_sel(btn) } >
                <div>{ this.toswe(btn.e1text) }</div>
                <div>{ this.topris(btn.e1pris) }</div>
            </div>
            )}
        </div>
        </div>
       </div>

         </div>

     </div>
     </div>
        </div>
        </div>

        <div className="web-row" style={{padding: "0px", borderTop: "1px solid #800"}}>
            <div className="nota-fkn-btn" onClick={() => this.sz() } >
                <div>SÖK</div>
                <div></div>
            </div>
             <div className="flex"></div>
            <div className="nota-pay-btn" onClick={() => this.pay_select() } >
                <div>FLER BETALSÄTT</div>
                <div></div>
            </div>
            <div className="nota-pay-btn" onClick={() => this.pay("swis") } >
                <div>SWISH</div>
                <div></div>
            </div>
            <div className="nota-pay-btn" onClick={() => this.pay("kont") } >
                <div>KONTANT</div>
                <div></div>
            </div>
            <div className="nota-pay-btn" onClick={() => this.pay_emv() } >
                <div>KORT EMV</div>
                <div></div>
            </div>
         </div>

        </div>
      </div>
    { this.state.wnds.pay ? <Pay_Wnd wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
    { this.state.wnds.emv ? <Pay_Emv wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
    </div>
	;
	return html;
  };
}

export default Wcm_Wnd;
