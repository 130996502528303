import React, { useState, useEffect, useContext } from 'react';

import * as utils from "../../../assets/utils";
import * as net from "../../../lib/net";

import Pms_Rvo from "../../rv/fb/rvo";
import Pms_Nota from "../nota/nota";

class Pms_CoSel extends React.Component {
  constructor(props, context) {
    super(props);

    this.state = {};
    this.state.ynq = [];

    this.state.paa = [];
    this.state.aa = [];
    this.state.boknr = "";
    this.state.rvo = {};
    if(props.wnds) {
        this.state.boknr = props.wnds.boknr;
        this.state.rvo = props.wnds.rvo;
        if(!this.state.rvo.rblx0) this.state.rvo.rblx0 = {};
        if(!this.state.rvo.charges) this.state.rvo.charges = {};
        if(!this.state.rvo.charges.bokat) this.state.rvo.charges.bokat = "";
        if(!this.state.rvo.charges.saldo) this.state.rvo.charges.saldo = "";
        if(!this.state.rvo.charges.paid) this.state.rvo.charges.paid = "";
        if(!this.state.rvo.charges.topay) this.state.rvo.charges.topay = "";
    }
    this.state.wnds = {};
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }

    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    wnd_close() {
		this.props.ctx.setState({ wnds: {} });
	};
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  fknSel(sel) {
    //alert(JSON.stringify(fkn));
      if(sel == "rvo") {
          var wnds = {};
          wnds.wnd_rvo = true;
          wnds.boknr = this.state.boknr;
          wnds.rvo = this.state.rvo;
        this.setState({wnds: wnds});
      }
      if(sel == "nota") {
          var wnds = {};
          wnds.wnd_nota = true;
          wnds.boknr = this.state.boknr;
          wnds.rvo = this.state.rvo;
        this.setState({wnds: wnds});
      }
      if(sel == "unco") {
          this.gst_unco();
      }
  }
    rvo_close(ctx) {
        ctx.setState({ wnd_rvo: false });
    };
    gst_close(ctx) {
        ctx.setState({ wnd_gst: false });
    };
    gst_unco() {
        var self = this;

        var prm = {};
        prm.req = "pms.gst_op.gst_reactivate";
        prm.boknr = this.state.rvo.boknr;
        prm.glopnr = this.state.rvo.gsb00.r0glopnr;
        prm.rumsnr = this.state.rvo.gsb00.r0rumsnr;
        prm.gsb00 = this.state.rvo.gsb00;
        //alert(JSON.stringify(prm));
        //return;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.ok));
                if(ret.ok) {
                    self.props.ctx.setState({ wnds: {} });
                    return;
                }
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }

  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "";

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="mx_backdrop" /> : null }
        <div className="mx_dlg mx_70 flex-col" style={ this.dlg_style() }>
            <div className="mx_h01 flex-row" style={{background: "#1a456b", color: "#fff"}}>
                    <div className="flex-col flex">
                        <div>{ this.state.rvo.gsb00.r0rumsnr.cdirumsnr() } { this.state.rvo.gsb00.r0namn }</div>
                    </div>
                    <div className="flex-col">
                        <div>BOKNR: { this.state.boknr }</div>
                    </div>
            </div>
        <div className="mx_content flex">

            <div className="flex-row flex">
            <div className="flex-col flex">

            <div className="txt_box txt_bg_01 txt_22">
            <div className="flex-row">
                    <div className="flex-col flex">
                        <div className="txt_lbl">FÖRETAG:</div>
                        <div>{ this.state.rvo.resv00.Company }</div>
                    </div>
                    <div className="flex-col flex">
                        <div className="txt_lbl">TELE:</div>
                        <div>{ this.state.rvo.tele }</div>
                    </div>
            </div>
            <div className="flex-row">
                    <div className="flex-col flex">
                        <div className="txt_lbl">GÄST:</div>
                        <div>{ this.state.rvo.gsb00.r0namn }</div>
                    </div>
                    <div className="flex-col flex">
                        <div className="txt_lbl">EPOST:</div>
                        <div>{ this.state.rvo.gsb00.r0email }</div>
                    </div>
            </div>
            <div className="flex-row">
                    <div className="flex-col flex">
                        <div className="txt_lbl">ANM:</div>
                        <div>{ this.state.rvo.anm }</div>
                    </div>
            </div>
           </div>


             <div className="txt_box txt_bg_01 txt_22">
             <div className="flex-row">
                    <div className="flex-col flex">
                        <div className="txt_lbl">RUM:</div>
                        <div>{ utils.toRumsnr( this.state.rvo.gsb00.r0rumsnr ) }</div>
                    </div>
                    <div className="flex-col flex">
                        <div className="txt_lbl">RUMSTYP:</div>
                        <div> { this.state.rvo.rblx0.r0typ }</div>
                    </div>
            </div>
             <div className="flex-row">
                    <div className="flex-col flex">
                        <div className="txt_lbl">PERIOD:</div>
                        <div>{ utils.toDate( this.state.rvo.gsb00.r0ankdatum ) } - { utils.toDate( this.state.rvo.gsb00.r0avrdatum ) }</div>
                    </div>
                    <div className="flex-col flex">
                        <div className="txt_lbl">GÄSTER:</div>
                        <div>{ this.state.rvo.rblx0.r0guests }</div>
                    </div>
            </div>

           </div>

           <div className="txt_box txt_bg_01 txt_22 flex">
           </div>

           </div>

             <div className="txt_box txt_bg_01 txt_22">
                    <div className="flex-row flex">
                        <div className="txt_lbl flex">BOKAT VÄRDE:</div>
                        <div className="txt_price">{ utils.toPris( this.state.rvo.charges.bokat ) }</div>
                    </div>
                    <div className="flex-row flex">
                        <div className="txt_lbl flex">SALDO:</div>
                        <div className="txt_price">{ utils.toPris( this.state.rvo.charges.saldo ) }</div>
                    </div>
                    <div className="flex-row flex">
                        <div className="txt_lbl flex">BETALT:</div>
                        <div className="txt_price">{ utils.toPris( this.state.rvo.charges.paid ) }</div>
                    </div>
                    <div className="flex-row flex">
                        <div className="txt_lbl flex">ATT BETALA:</div>
                        <div className="txt_price">{ utils.toPris( this.state.rvo.charges.topay ) }</div>
                    </div>
                    <div className="divider_blue"></div>
                    <div className="flex-row flex py-2">
                        <div className="txt_lbl flex">RUMSNR:</div>
                        <div className="txt_price">{ utils.toRumsnr( this.state.rvo.gsb00.r0rumsnr ) }</div>
                    </div>
            </div>

            </div>
            <div className="divider_orange"></div>
        <div className="web-row">
        <div className="web-col flex">
            <div className="wnd-btn" onClick={() => this.fknSel("unco") } >
                <div>ÅTERTAG UTCHECKNING</div>
                <div></div>
            </div>
            <div className="wnd-btn" onClick={() => this.wnd_close() } >
                <div>STÄNG</div>
                <div></div>
            </div>
        </div>
         <div className="web-col flex" style={{padding: "0px 10px"}}>
            <div className="wnd-btn" onClick={() => this.fknSel("gstfkn") } >
                <div>GÄST FUNKTIONER</div>
                <div></div>
            </div>
            <div className="wnd-btn" onClick={() => this.fknSel("logg") } >
                <div>LOGG</div>
                <div></div>
            </div>
       </div>
         <div className="web-col flex" style={{padding: "0px 10px"}}>
            <div className="wnd-btn" onClick={() => this.fknSel("rvo") } >
                <div>ÖPPNA BOKNING</div>
                <div></div>
            </div>
            <div className="wnd-btn" onClick={() => this.fknSel("nota") } >
                <div>ÖPPNA NOTA</div>
                <div></div>
            </div>
       </div>
       </div>

        </div>
      </div>
            { this.state.wnds.wnd_rvo ?
        <Pms_Rvo wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
            : null }
            { this.state.wnds.wnd_nota ?
        <Pms_Nota wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
            : null }
    </div>
	;
	return html;
  };
}

export default Pms_CoSel;