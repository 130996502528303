import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../../lib/net";
import * as utils from "../../../assets/utils";
import Fr_Input from "../../../lib/ui/fr_input";

import Wnd_Error from "../../../lib/ui/wnd_error";
import Wnd_Ok from "../../../lib/ui/wnd_ok";
import Wnd_Info from "../../../lib/ui/wnd_info";
import Wnd_Confirm from "../../../lib/ui/wnd_confirm";
import Fr_Cal from "../../../lib/ui/fr_cal";

class Prj_open extends React.Component {
  constructor(props, context) {
    super(props);

    this.pos = props.store.pos;
    this.state = {};
    this.state.mka = [];
    this.state.sval = [];
    this.state.tval = [];
    this.state.paa = [];
    this.state.uaa = [];
    this.state.laa = [];

    this.state.gnr = "001";
    //this.state.lro = props.lro;
    this.state.ynq = [
                {id: '0', text: 'NEJ'},
                {id: '1', text: 'JA'}
            ];

      this.state.msg = {};
      this.state.cols = {};
      this.state.lid = "";
      this.state.lko = {};
      this.state.lko.lk00 = {};
      this.state.lko.lk01 = {};
      this.state.lko.lk02 = {};
      this.state.tabval = 0;
      this.state.lkanm = "";

    if(props.wnds.lko) {
        this.state.lko = props.wnds.lko;
        this.state.lid = this.state.lko.lk00.lid;
    }
      this.state.tda = [];
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      this.lk_rd();
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }

  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
    tab_style(tabid) {
        var css = {};
        css.display = "none";
        if(this.state.tabval === tabid) css.display = "block";
        return css;
    }
    tab_head(tabid) {
        var css = {};
        css.background = "#fff";
        css.color = "#000";
        if(this.state.tabval === tabid) {
            css.background = "#080";
            css.color = "#fff";
        }
        return css;
    }
    wnd_close = () => {
        if(this.props.close) {
            this.props.close(this.props.ctx);
        }
        else this.props.ctx.setState({ wnds: {} });
    };

    val_save(id, val) {
        var lko = this.state.lko;
        var na = id.split(".");
        lko[na[0]][na[1]] = val;
        this.setState({ lko: lko });
        return;
    }
    val_anm_save(e) {
        const value = e.target.value;
        var lko = this.state.lko;
        lko.lkdata = value;
        this.setState({lko: lko});
    }

    lk_rd() {
        var self = this;
        var prm = {};
        prm.req = "bcs.lreg.lk_rd";
        prm.lid = this.state.lid;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret));
                if(ret.ok == "000") {
                    self.setState({ lko: ret.rco });
                }
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    lk_save() {
        var self = this;
        var prm = {};
        prm.req = "bcs.lreg.lk_upd";
        prm.lko = this.state.lko;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret));
                if(ret.ok == "000") {
                    self.setState({ msg: {ok: true, text: "Sparad ..."} });
                    setTimeout(function(){
                        self.setState({msg: {} });
                        if(self.props.wnds.cb) {
                            self.props.wnds.cb(self.props.ctx);
                        }
                        else self.props.ctx.setState({ wnds: {} });
                        return;
                    }, 1000);
                }
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    lk_anm_save() {
        var self = this;
        var prm = {};
        prm.req = "bcs.lreg.lkdat_upd";
        prm.lko = this.state.lko;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret));
                if(ret.ok == "000") {
                    self.setState({ msg: {ok: true, text: "Anmärkning sparad ..."} });
                    setTimeout(function(){
                        self.setState({msg: {} });
                        return;
                    }, 1000);
                }
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    lk_del() {
        var self = this;
        var prm = {};
        prm.req = "bcs.lreg.lk_del";
        prm.lko = this.state.lko;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret));
                if(ret.ok == "000") {
                    self.setState({ msg: {ok: true, text: "Borttagen ..."} });
                    setTimeout(function(){
                        self.setState({msg: {} });
                        if(self.props.wnds.cb) {
                            self.props.wnds.cb(self.props.ctx);
                        }
                        else self.props.ctx.setState({ wnds: {} });
                        return;
                    }, 1000);
                }
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }

  render() {
    //if(!this.props.show) return "";
    var title = this.props.title;
    if(!title) title = "Resurs";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        <div className="m2_backdrop" />
        <div className="m2_dlg mx_90 flex-col" style={ this.dlg_style() } >
        <div className="mx_content flex">

        <div className="mx_h01">
            <div>PROJEKT: { utils.toNum( this.state.lid ) }</div>
            <div className="flex"></div>
            <div onClick={() => this.wnd_close() }>X</div>
        </div>
            <div className="flex-row">
                <div className="tab_header flex" style={ this.tab_head(0) } onClick={ () => this.setState({tabval: 0}) }>INFORMATION</div>
                <div className="tab_header flex" style={ this.tab_head(1) } onClick={ () => this.setState({tabval: 1}) }>ANMÄRKNING</div>
                <div className="tab_header flex" style={ this.tab_head(2) } onClick={ () => this.setState({tabval: 2}) }>SYSTEM</div>
                <div className="tab_header flex" style={ this.tab_head(3) } onClick={ () => this.setState({tabval: 3}) }>PUNKTER</div>
            </div>
            <div className="divider_orange"></div>

            <div style={ this.tab_style(0) }>
            <form className="cdi_f03">
                <div className="flex-row">
                    <Fr_Input label="Text" className="text-left w350" text={this.state.lko.lk00.text} getValue={(e)=> this.val_save("lk00.text", e) } />
                </div>
                <div className="flex-row">
                    <Fr_Input label="Företag" className="text-left w350" text={this.state.lko.lk00.ftg} getValue={(e)=> this.val_save("lk00.ftg", e) } />
                </div>
                <div className="flex-row">
                    <Fr_Input label="Orgnr" className="text-left w250" text={this.state.lko.lk00.orgnr} getValue={(e)=> this.val_save("lk00.orgnr", e) } />
                    <Fr_Input label="Tele" className="text-left w250" text={this.state.lko.lk02.tele} getValue={(e)=> this.val_save("lk02.tele", e) } />
                </div>
                <div className="flex-row">
                    <Fr_Input label="Referens" className="text-left w250" text={this.state.lko.lk00.ref} getValue={(e)=> this.val_save("lk00.ref", e) } />
                    <Fr_Cal label="Startdag" className="text-left w200" text={this.state.lko.lk00.reg} getValue={(e)=> this.val_save( "lk00.reg", e ) }/>
                </div>

                <div className="divider_green"></div>
                <div className="flex-row">
                    <Fr_Input label="Postadress" className="text-left w250" text={this.state.lko.lk01.postal} getValue={(e)=> this.val_save("lk01.postal", e) } />
                    <Fr_Input label="Gata" className="text-left w350" text={this.state.lko.lk01.street} getValue={(e)=> this.val_save("lk01.street", e) } />
                </div>
                <div className="flex-row">
                    <Fr_Input label="Postnr" className="text-left w150" text={this.state.lko.lk01.zip} getValue={(e)=> this.val_save("lk01.zip", e) } />
                    <Fr_Input label="Stad" className="text-left w350" text={this.state.lko.lk01.city} getValue={(e)=> this.val_save("lk01.city", e) } />
                </div>
                <div className="flex-row">
                    <Fr_Input label="Land" className="text-left w250" text={this.state.lko.lk01.country} getValue={(e)=> this.val_save("lk01.country", e) } />
                </div>
                <div className="divider_green"></div>
                <div className="flex-row">
                    <Fr_Input label="Epost" className="text-left w350" text={this.state.lko.lk02.email} getValue={(e)=> this.val_save("lk02.email", e) } />
                </div>
                <div className="flex-row">
                    <Fr_Input label="Web" className="text-left w350" text={this.state.lko.lk02.web} getValue={(e)=> this.val_save("lk02.web", e) } />
                </div>

            </form>
            </div>

            <div style={ this.tab_style(1) }>
                <form className="cdi_f03">
                    <div className="flex flex-column" style={{padding: "0px 5px"}}>
                        <label>Anmärkning</label>
                        <textarea className="form-control" maxLength="512" rows="10" value={ this.state.lko.lkdata } onChange={(event) => this.val_anm_save(event)} />
                    </div>
                    <div className="">
                        <div className="web-link txt_12" onClick={() => this.lk_anm_save() }>Spara Anmärkning</div>
                    </div>
                </form>
            </div>

            <div style={ this.tab_style(3) }>
                <div className="flex-row px-2">
                    <div>Ärenden</div>
                    <div className="flex"></div>
                    <div className="web-link" onClick={() => this.kid_tasks() }>Uppdatera</div>
                </div>
                <div className="divider_orange"></div>
                { this.state.tda.map((row, key) =>
                    <div className="flex-col px-2">
                        <div className="flex-row">
                            <div className="wnd_link px-2">{ row.pid }</div>
                            <div className="wnd_link px-2">{ utils.toTsDate(row.ts) }</div>
                            <div className="flex"></div>
                        </div>
                        <div className="flex-row px-2">
                            <div>{ row.msg }</div>
                        </div>
                        <div className="divider_gray"></div>
                    </div>
                )}
            </div>

        </div>
        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <button className="btn btn-hw-orange w250" onClick={() => this.lk_del() } >
                TA BORT
            </button>
            <div className="flex-row">
            <button className="btn btn-hw-green w250" onClick={() => this.lk_save() } >
                SPARA
            </button>
            </div>
        </div>
      </div>

            <Wnd_Error msg={this.state.msg} ctx={this} />
            <Wnd_Ok msg={this.state.msg} ctx={this} />
            <Wnd_Info msg={this.state.msg} ctx={this} />
            <Wnd_Confirm msg={this.state.msg} ctx={this} />
        </div>
	;
	return html;
  };
}

export default Prj_open;