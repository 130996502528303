import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";
import Pms_Sz from "../wrk/sz";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class PMS_RvSz extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, tra: [] };
    this.state.seldate = new Date();
    this.state.avd = "01";
    this.state.rva = [];
    this.state.kro = {};
    this.state.aa = [];
    this.state.cols = {};
    this.state.wnds = {};
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

    sz_rv() {
        var self = this;
        var wnds = {};
        wnds.szw = 1;
        this.setState({ wnds: wnds });
    }
    fkn_close() {
        this.setState({edit: false });
        //this.props.history.push("cas");
        //this.login_srv(uid, pwd);
    }

  render() {
    return (
    <div className="web-app">
        <div className="txt_18">SÖK</div>

    <div className="divider_orange"></div>

    <div className="web-row">
    <div className="web-col flex">
        <div className="web-btn" onClick={() => this.sz_rv() } >
            <div>SÖK BOKNINGAR</div>
            <div></div>
        </div>
    </div>
     <div className="web-col flex" style={{padding: "0px 10px"}}>
        <div className="web-btn" onClick={() => this.sz_rv() } >
            <div>SÖK KUNDER</div>
            <div></div>
        </div>
   </div>
   </div>

        { this.state.wnds.szw ?
        <Pms_Sz show={this.state.wnds.szw} ctx={this} store={this.props.store} backdrop={true}/>
        : null }
   </div>
  );
    }
}
export default PMS_RvSz;
