import React, { useState, useEffect, useContext } from 'react';
import {format, getDate, getMonth, getWeek, eachDayOfInterval, eachWeekOfInterval, subDays, subMonths} from 'date-fns'
import {sv} from "date-fns/locale";
import {observer} from "mobx-react";

import * as net from "../../lib/net";
import Dt_Cal from "../../lib/ui/dt_cal";
import ddo from '../../ddo.js'

import XrecovDlg from "./dlg/xrecov_dlg";
import PdfRpDlg from "./fkn/pdf_rp_dlg";
import KvittoWnd from "./dlg/kvitto_wnd";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Pos_Konto extends React.Component {
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pos = props.store.pos;
    this.state = { };
    this.state.dat = new Date();
    this.state.avd = ddo.cache.avd;
    this.state.kta = [];
    this.state.kto = {};
    this.state.sdo = {};
    this.state.aa = [];

    this.state.xrecov = false;
    this.state.pdf = 0;
    this.state.pdf_content = null;

      this.state.today = new Date();
      var dat = this.state.today;
      ///dat = subDays(dat, 1);
      var dts = format(dat, "yyyyMMdd");
      this.state.datum = dts;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.konton();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 250;
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }
  dt_dat(e) {
    //alert(JSON.stringify(e));
    var dts = format(e, "yyyy-MM-dd");
    this.setState({ datum: dts });
  }
  change_avd(e) {
    //let cdat = this.state.seldate;
    let avd = e.target.value;
    this.setState({avd: avd});
  }
  pdf_done (ret) {
    this.setState({loading: 0, pdf: 0, pdf_content: null });
  }
  recover () {
    this.setState({xrecov: true });
  }

  konton() {
    var self = this;
    	var prm = {};
     	prm.req = "pos.pos_eko.trs_konto";
        //prm.req = "pos.pos_eko_hist.trw";
     	//prm.req = "pos.pos_red.jr_red";
   		prm.avd = this.state.avd;
   		prm.datum = this.state.datum;
   		prm.fdat = this.state.datum;
   		prm.tdat = this.state.datum;
   		prm.favd = this.state.avd;
   		prm.tavd = this.state.avd;

        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret.rco));

            self.setState({kta: ret.rca, kto: ret.rco, sdo: ret.sdo });
            //toastr.success("OK", "SAPI");
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
            //gda.wndError(txt);
        });
  }
  rp_jr() {
    var self = this;
    	//var datum = moment().subtract(14, "days");
    	var favd = "01";
    	var tavd = "01";
        var dat = subDays(this.state.dat, 1);
        var datum = format(dat, "yyyyMMDD", { locale: sv });

    	var prm = {};
     	//prm.req = "pos.pos_trs.trs_per";
     	//prm.req = "pos.pos_rp.rp_jr";
     	prm.req = "pos.pos_red.jr_prn_red";
   		prm.avd = "01";
   		prm.fdat = datum;
   		prm.tdat = datum;
   		prm.favd = favd;
   		prm.tavd = tavd;
   		//if($scope.ftid)	prm.ftid = $scope.ftid.format("HHmm");
   		//if($scope.ttid)	prm.ttid = $scope.ttid.format("HHmm");

        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret.pdf));

            var o = {};
            //o.deps = ret.rco;
            //alert(JSON.stringify(o));
            //self.setState({tra: ret.rca });
            self.setState({pdf_content: ret.pdf });
            //toastr.success("OK", "SAPI");
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
            //gda.wndError(txt);
        });
  }

  render() {
    return (
    <div className="web-p00 flex flex-col">
        <div className="">
            Konton
        </div>

    <div className="web-p01">
    <div className="flex-row">
        <Dt_Cal startdatum="2020-02-01" getValue={(e)=> this.dt_dat(e)} />
        <select className="form-control w250" placeholder="Avdelning" onChange={(e) => this.change_avd(e)}>
            { this.props.store.pos.deps.map((row, key) =>
            <option key={"xx_" + key} value={row.avd}>{row.avd} - {row.text}</option>
            )}
        </select>
        <div className="flex"></div>
        <div className="link_14" onClick={() => this.recover() }>Återskapa</div>
        <div className="link_14" onClick={() => this.konton() }>Konton</div>
        <div className="link_14" onClick={() => this.rp_jr() }>Skriv ut</div>
    </div>
    <div className="divider_orange"></div>

    <div className="web-row">
        <table className="table table-bordered table-hover tb fixed_header">
            <thead>
            <tr>
                <th>Konto</th>
                <th>Text</th>
                <th>Pris</th>
                <th>Varugrupp</th>
                <th>Moms</th>
                <th>Sort</th>
                <th>Uppdat</th>
                <th style={{width:"40px"}} ></th>
                <th style={{width:"16px"}} ></th>
            </tr>
            </thead>
            <tbody style={ this.tb_style() }>
            { this.state.kta.map((row, key) =>
            <tr id={ row.konto } onDblClick="plu_open(row);" >
                <td>{ row.konto }</td>
                <td style={{width:"250px",cursor:"pointer",overflow:"hidden"}}>{ row.trs }</td>
                <td>{ row.nrof }</td>
                <td>{ row.sum }</td>
                <td>{ row.r0time }</td>
                <td>{ row.r0text }</td>
                <td>{ row.r0time }</td>
                <td style={{width:"40px",cursor:"pointer"}} onClick={() => this.plu_edit(row) }><i className="fa fa-cog text-danger"></i></td>
            </tr>
            )}
            </tbody>
        </table>
    </div>

    </div>

        { this.state.xrecov ? <XrecovDlg ctx={this} store={this.store} backdrop={true}/> : null }
        <PdfRpDlg show={this.state.pdf} pdf={this.state.pdf_content} done={(ret) => this.pdf_done(ret)} backdrop={true}/>
    </div>
  );
    }
}
export default observer(Pos_Konto);
