import React, { useState, useEffect, useContext } from 'react';
import { SDOContext } from '../sdo.js'
import {withRouter} from "../lib/react/withRouter";

function web01() {

    var html =
    <div className="web-content">
    </div>
    ;
    return html;
}

class Web_Pub extends React.Component {
    //var sdo = useContext(SDOContext); // Functional
    static contextType = SDOContext; // Class

    constructor(props, context) {
        super(props);

        this.webStore = props.store;
        this.state = { pgid: 11, stat: [] };
        this.state.open = false;
        this.state.pdfopen = false;
        this.state.xxopen = false;
        this.state.pdf_uri = "";
        this.state.seldate = new Date('2020-03-24T13:11:54');
        this.state.row = null;
        this.state.info = "";
        this.state.top = 1;
        this.state.sdo = {};
        //this.state.sdo = context;
        //context.posrend = this;
    }
    componentDidMount(){
        //var sdo = sessionStorage.getItem("svp");
        //if(sdo) this.setState({sdo: JSON.parse(sdo) });
    }

    componentWillUnmount(){
            this.setState({info: "UUU" });
    }
    onScroll(event) {
        var body = document.getElementById("web-body");
        if (body.scrollTop > 250) {
          this.setState({top: 0})
          this.setState({info: 'small'})
        } else {
          this.setState({top: 1})
          this.setState({info: 'big:'+body.scrollTop})
        }
    }
    header_css() {
  	    var css = {};
		if(this.state.top) {
			css.background = "#080";
			css.color = "#fff";
			css.height = "125px";
		}
		else css.height = "55px";

		return css;
    }

    sdo_upd() {
        var sdo = this.state.sdo;
        if(!sdo.fid) sdo.fid = "0";
        sdo.fid = ((sdo.fid * 1) + 1).toString();
        this.setState({sdo: sdo})
        sessionStorage.setItem("svp", JSON.stringify(this.state.sdo));
        //this.props.history.push("cas");
    }
    sdo_ctx() {
        sessionStorage.removeItem('svp');
        //sessionStorage.clear();
    }
    sdo_cas() {
        this.context.fid = "45";
        this.props.navigate("cas");
    }

    row_css(row) {
  	    var css = {};
		if(this.state.row) {
		if(this.state.row.ank == row.ank) {
			css.background = "#080";
			css.color = "#fff";
		}
		}
		return css;
    }
    render() {
        var stat = [];
        var keys = Object.keys(this.state.stat);
        for (var key of keys) {
            var row = this.state.stat[key];
            stat.push(row);
        }
        const handleDateChange = date => {
            this.setState({seldate: date });
        };

        return (
            <div className="web-app">
            <div className="web-header web-title" id="header" style={ this.header_css() }>
            <div className="web-top-content flex flex-row flex-space-between">
                <img className="web-logo" src={require('./img/logosvk.svg')} />
                <div className="web-top-mnu flex-row">
                    <div onClick={() => this.sdo_upd() }>Hem</div>
                    <div onClick={() => this.sdo_ctx() }>Produkter</div>
                    <div onClick={() => this.sdo_cas() }>Om oss</div>
                </div>
                <button className="btn btn-hw-blue w150 ripple" onClick={() => this.openXX() } aria-label="System" >
                    Logga in
                </button>
            </div>
            </div>

            <div className="web-body" id="web-body" onScroll={ (event) => this.onScroll(event) }>
                <p>ANK : { this.state.status }</p>
            <div className="web-s1">
                <h6>SVERIGEKASSAN</h6>
            </div>
            <div className="parallax"></div>
            <div className="">
                <img className="web-img" src={require('./img/pax00.jpg')} />
            </div>
            <div className="parallax"></div>
            <div className="web-s2">
                <h6>SDO : { this.state.sdo.fid }</h6>
                <h6>CTX : { this.context.fid }</h6>
            </div>
            <div className="web-s1">
                <div className="flex-row">
                    <button className="btn btn-hw-blue w150 ripple" onClick={() => this.tele_ank() } aria-label="System" >
                        Ladda
                    </button>
                    <button className="btn btn-hw-blue w150 ripple" onClick={() => this.openPie() } aria-label="System" >
                        Diagram
                    </button>
                    <button className="btn btn-hw-blue w150 ripple" onClick={() => this.pdf_show() } aria-label="System" >
                        Skriv ut
                    </button>
                    <button className="btn btn-hw-blue w150 ripple" onClick={() => this.openXX() } aria-label="System" >
                        Skriv ut
                    </button>
                </div>
            </div>
            </div>

            </div>
        );
    }

}
//WebPub.contextType = SDOContext; // Function
export default withRouter(Web_Pub);
