import React, { useState, useEffect, useContext } from 'react';
import * as fkn from "../lib/fkn";
import SDOContext from '../sdo.js'
import {Route, Routes} from "react-router-dom";
//import ADOContext from "../ado";

import Rst_Home from './rst/home.js';
import Rp_Home from "./rp/home";
import {withRouter} from "../lib/react/withRouter";
import sso from "../sso";

function pg_home() {

    var html =
    <div className="web-content">
      Hem
    </div>
    ;
    return html;
}

class Pms_Rst extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, deps: [] };
    //context.posrend = this;
  }
  fknGo(row) {
    //alert(row.href);
    this.props.store.fkn = row;
      sso.seo.sfkn = row;
    this.props.navigate(row.url);
  }
    br_css(row) {
        var css = {};
        if(sso.seo.sfkn && (row.url == sso.seo.sfkn.url)) css.color = "#080";
        //var brc = false;
        //if(row.url == sbo.state.url) brc = true;
        return css;
    }

  render() {
      var path = window.location.pathname;
      if(path.substr(-1) == "/") path = path.substring(0, path.length - 1);
      var op = path.split("/").pop();
      var po = {};
      po.list = { "fid": "f05", "url": "/pms/rst/list", "name": "Listor", "active": false };
      if(po[op]) {
          po[op].active = true;
          //this.props.store.fkn = po[op];
      }
      var pa = Object.values(po);

    return (
    <div className="web-app">
         { this.props.store.cfg.bread ?
        <div className="web-bread">
            <div className="breadcrumb">
            { pa.map((row, key) =>
                <div className="breadcrumb-item" key={key} style={this.br_css(row)} onClick={() => this.fknGo(row)}>{row.name}</div>
            )}
            <div className="flex"></div>
            <div className="cdigreen">{ this.props.store.fkn ? this.props.store.fkn.name : null }</div>
            </div>
        </div>
        : null }
        <Routes>
          <Route path="/xx" component={pg_home} />
            <Route path="home" element={ <Rst_Home store={this.webStore} />} />
            <Route path="*" element={ <Rst_Home store={this.webStore} />} />
        </Routes>
    </div>
  );
}
}
//Sys.contextType = ADOContext;

export default withRouter(Pms_Rst);
