import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";
import Dt_Cal from "../../lib/ui/dt_cal";
import PdfDlg from "../lib/pdf_dlg";
import {format} from "date-fns";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Stat_Saldo extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, tra: [] };
    this.state.seldate = new Date();
    this.state.typ = "01";
    this.state.dta = [];
    this.state.aa = [];
    this.state.sdo = {};
    this.state.vpa = [];
    this.state.pdf = 0;
    this.state.pdf_content = null;
    this.state.loading = 0;

      this.state.today = new Date();
      var dat = this.state.today;
      ///dat = subDays(dat, 1);
      var dts = format(dat, "yyyyMMdd");
      this.state.datum = dts;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.reg_list();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 250;
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

    dt_dat(e) {
        //alert(JSON.stringify(e));
        var dts = format(e, "yyyyMMdd");
        this.setState({ datum: dts });
    }
  vpo_edit(row) {
      var self = this;
      alert(JSON.stringify(row));
  }

  vip_saldo() {
    var self = this;
    var prm = {};
    prm.req = "vip.vip_buy.tot_per_dt";
    prm.typ = this.state.typ;
    prm.fdat = this.state.datum;
    prm.tdat = this.state.datum;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        var sdo = ret.rco;
        //var vp01 = sdo.vpo["01"];
        var vpa = Object.values(sdo.vpo);
        //alert(JSON.stringify(vp01));

        self.setState({sdo: sdo, vpa: vpa});
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  prn_saldo(fkn) {
    var self = this;
    var prm = {};
    prm.req = "vip.vip_rp.rp_saldo";
    prm.datum = this.state.datum;
    prm.typ = this.state.typ;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        self.setState({pdf: 1, pdf_content: ret.pdf });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  pdf_done (ret) {
    this.setState({loading: 0, pdf: 0, pdf_content: null });
  }

  render() {
    return (
    <div className="web-p00 flex flex-col">
        <div className="">
            SALDO
        </div>

    <div className="web-p01">
    <div className="flex-row">
        <Dt_Cal startdatum="2020-02-01" getValue={(e)=> this.dt_dat(e)} />
        <select className="form-control w250" placeholder="Välj avdelning">
            { this.state.aa.map((row, key) =>
            <option key={key} value={row.id}>{row.text}</option>
            )}
        </select>
        <div className="flex"></div>

        <button className="btn btn-hw-blue w150 ripple" onClick={() => this.prn_saldo() } aria-label="System" >
            Skriv Ut
        </button>
        <button className="btn btn-hw-blue w150 ripple" onClick={() => this.vip_saldo() } aria-label="System" >
            Visa
        </button>
    </div>
    <div className="divider">
    </div>

    <div className="web-row">
        <table className="table table-bordered table-hover tb fixed_header">
            <thead>
            <tr>
				<th>Typ</th>
				<th>Antal</th>
				<th>Antal Saldo</th>
				<th>Debit</th>
				<th>Tele</th>
				<th>Kredit</th>
				<th>Saldo</th>
				<th>Status</th>
            </tr>
            </thead>
            <tbody style={ this.tb_style() }>
            { this.state.vpa.map((vpo, key) =>
            <tr key={key} onDoubleClick={ () => this.vpo_edit(vpo) }>
                <td>{ vpo.typ }</td>
                <td>{ vpo.antal }</td>
                <td>{ vpo.antalsaldo }</td>
                <td>{ vpo.debit.cdipris() }</td>
                <td>{ vpo.kredit.cdipris() }</td>
                <td>{ vpo.saldo.cdipris() }</td>
                <td>{ vpo.status }</td>
            </tr>
            )}
            </tbody>
        </table>
    </div>

    </div>
        <PdfDlg show={this.state.pdf} pdf={this.state.pdf_content} done={(ret) => this.pdf_done(ret)} backdrop={true}/>
    </div>
  );
    }
}
export default Stat_Saldo;
