import React, { useState, useEffect, useContext } from 'react';
import * as net from "../../../lib/net";

class Lr_Pass extends React.Component {
  constructor(props, context) {
    super(props);

    //alert('LR CTX: ' + JSON.stringify(props.lro));
   //this.webStore = props.store;
    this.state = {};
    this.state.eea = [];
    this.state.eeo = {};
    this.state.lro = props.lro;
    this.state.aa = [
                {id: '01', text: 'AVD 01'},
                {id: '02', text: 'AVD 02'}
            ];
    this.state.cols = {};
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.tps_ft();
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    tab_style(tabid) {
      var css = {};
      css.display = "none";
      if(this.state.tabval === tabid) css.display = "block";
      return css;
    }
    tab_head(tabid) {
      var css = {};
      css.background = "#fff";
      css.color = "#000";
      if(this.state.tabval === tabid) {
          css.background = "#080";
          css.color = "#fff";
      }
      return css;
    }
    eea_edit(row) {
    }
    lr_wrk(event) {
    }
    lr_run(event) {
    }

    tps_ft() {
        var self = this;
        var prm = {};
     	prm.req = "ta.ta_th.tps_ft";
   		//prm.tlo = $scope.tlo;
   		prm.fdat = this.state.lro.fdat;
   		prm.tdat = this.state.lro.tdat;
        //alert(JSON.stringify(prm));

        //gda.wndLoading("Hämtar");
        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret.rca));

            self.setState({eea: ret.rca });
            self.syncCols("eeaTable");
            //toastr.success("OK", "SAPI");
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
            //gda.wndError(txt);
        });
    }

    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    wnd_close = () => {
		this.props.ctx.setState({ wnds: {} });
	};

  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  cssCols (cid) {
      var css = {};
      if(this.state.cols[cid]) css.width = this.state.cols[cid].toString() + "px";
      //if(cid == "c01") alert(JSON.stringify(this.state.cols[cid]));
      return css;
  }
  syncCols (tb) {

    if (!document.getElementById(tb)) return;

    var cols = {};
    var ix = document.getElementById(tb).rows.length - 1;
    var ic = document.getElementById(tb).rows[0].cells.length;
    var ci = 0;
    while(ci < ic) {
        var cxi = ci + 1;
        var col = "c" + cxi.toString().padStart(2, "0");
        cols[col] = document.getElementById(tb).rows[ix].cells[ci].clientWidth + 1;
        ci = ci + 1;
    }
    this.setState({cols: cols });
    //alert(JSON.stringify(this.state.cols));
    //this.forceUpdate();
  };

  render() {
    if(!this.props.wnds.lrpass) return "";

    //alert(this.props.ctx.state.lro);

    var title = this.props.title;
    if(!title) title = "Lönekörning";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="mx_backdrop" /> : null }
        <div className="mx_dlg mx_95 flex-col" style={ this.dlg_style() }>
        <div className="mx_content flex">
            <div className="flex-row">
                    <div className="flex-col flex">
                        <div>Datum:</div>
                        <div>{ this.props.lro.datum }</div>
                    </div>
                    <div className="flex-col flex">
                        <div>ID:</div>
                        <div>{ this.props.lro.datum }</div>
                    </div>
                    <div className="flex-col flex">
                        <div>XX:</div>
                        <div>{ this.props.lro.datum }</div>
                    </div>
                    <div className="flex-col w50">
                        <div>Ändra</div>
                    </div>
            </div>
            <div className="divider_orange"></div>

            <div className="flex-col">
            <table className="table table-bordered table-hover tb " style={{marginBottom: "0px"}}>
                <thead>
                <tr>
                    <th style={ this.cssCols("c01" ) }>Anstnr</th>
                    <th style={ this.cssCols("c02" ) }>Namn</th>
                    <th style={ this.cssCols("c03" ) }>Avd</th>
                    <th style={ this.cssCols("c04" ) }>Start</th>
                    <th style={ this.cssCols("c05" ) }>Slut</th>
                    <th style={ this.cssCols("c06" ) }>Timmar</th>
                    <th style={ this.cssCols("c07" ) }>OB1</th>
                    <th style={ this.cssCols("c08" ) }>OB2</th>
                    <th style={ this.cssCols("c09" ) }>Upd</th>
                    <th style={ this.cssCols("c10" ) }></th>
                    <th style={{width:"16px"}} ></th>
                </tr>
                </thead>
            </table>
            <div className="tb-cdi" style={ this.tb_style() }>
           <table id="eeaTable" className="table table-bordered table-hover tb">
                <tbody>
                { this.state.eea.map((row, key) =>
                <tr id={ row.anstnummer } onDoubleClick={() => this.eea_edit(row) }>
                    <td>{ row.anstnummer }</td>
                    <td>{ row.peo.p1.namn }</td>
                    <td>{ row.peo.p1.timlon }</td>
                    <td>{ row.peo.p2.taxproc }</td>
                    <td>{ row.ndag }</td>
                    <td>{ row.regtid }</td>
                    <td>{ row.ob1 }</td>
                    <td>{ row.ob2 }</td>
                    <td>{ row.status }</td>
                    <td style={{width:"40px",cursor:"pointer"}} onClick={() => this.eea_edit(row) }><i className="fa fa-cog text-danger"></i></td>
                </tr>
                )}
                <tr style={{visibility: "hidden"}}>
                    <th>Anstnr</th>
                    <th>Namn</th>
                    <th>Timlön</th>
                    <th>Skattesats</th>
                    <th>Dagar</th>
                    <th>Timmar</th>
                    <th>OB1</th>
                    <th>OB2</th>
                    <th>Upd</th>
                    <th></th>
                </tr>
                </tbody>
            </table>
            </div>
            </div>

        </div>
        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w150 ripple" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex-row">
            <button className="btn btn-hw-green w250 ripple" onClick={(event) => this.lr_shd(event) } >
                SCHEMALAGDA
            </button>
            </div>
            <button className="btn btn-hw-green w150 ripple" onClick={(event) => this.lr_run(event) } >
                KÖR ALLA
            </button>
        </div>
      </div>
    </div>
	;
	return html;
  };
}

export default Lr_Pass;