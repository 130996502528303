import React, { useState, useEffect, useContext } from 'react';
import {withRouter} from "../lib/react/withRouter";

import { SDOContext } from '../sdo.js'
import sso from "../sso";

//import './css/style-liberty.css';
import Tiden from "../lib/ui/tiden";

import * as net from "../lib/net";
import Dd_Sel from "../lib/ui/dd_sel";

import User_Setup from "./user/setup";
import User_Session from "./user/ses";
import Man_Sessions from "./manager/sessions";
import Man_ChangeSes from "./manager/change_ses";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function web01() {

    var html =
    <div className="">
        Test
    </div>
    ;
    return html;
}

class Hello extends React.Component {
    //var sdo = useContext(SDOContext); // Functional
    static contextType = SDOContext; // Class

    constructor(props, context) {
        super(props);

        this.s1ref = React.createRef();
        this.s2ref = React.createRef();

        this.store = props.store;
        this.seo = sso.seo;

        this.state = { pgid: 11, stat: [] };
        this.state.open = false;
        this.state.pdfopen = false;
        this.state.xxopen = false;
        this.state.pdf_uri = "";
        this.state.seldate = new Date('2020-03-24T13:11:54');
        this.state.row = null;
        this.state.info = "";
        this.state.top = 1;
        this.state.user = {};
        this.state.sdo = {};
        this.state.wnds = {};
        //this.state.sdo = context;
        //context.posrend = this;
        this.state.notify = false;
        this.state.step = 1;
        this.state.pdf = 0;
        this.state.pdf_uri = "";
        this.state.mnus = ["m00", "m01", "m02", "m03", "m08", "m09"];
        this.state.mnu_sel = "m00";

        this.state.useritems = [
            {fkn: 'user_setup', text: 'INSTÄLLNINGAR'},
            {fkn: 'user_ses', text: 'MIN SESSION'},
            {fkn: 'logout', text: 'LOGGA UT'}
        ];
        this.state.manitems = [
            {fkn: 'man_bo', text: 'BACKOFFICE'},
            {fkn: 'man_tech', text: 'TEKNISK SUPPPORT'},
            {fkn: 'man_sid', text: 'SYSTEM VAL'},
            {fkn: 'man_ses', text: 'SESSIONER'}
        ];

        this.state.msa = [];
        this.state.msa.push({ pos: "c1", text: "KASSASYSTEM", desc: "POS System", link: "pos", icon: "building" });
        this.state.msa.push({ pos: "c1", text: "HOTELLSYSTEM", desc: "PMS System", link: "pms", icon: "bed" });
        this.state.msa.push({ pos: "c1", text: "TID SYSTEM", desc: "TA System", link: "ta", icon: "building" });
        this.state.msa.push({ pos: "c1", text: "FAKTURA SYSTEM", desc: "Fakt System", link: "fakt", icon: "file-invoice" });
        this.state.msa.push({ pos: "c1", text: "LOJALITETS SYSTEM", desc: "Vip System", link: "vip", icon: "building" });
        this.state.msa.push({ pos: "c1", text: "BI SYSTEM", desc: "Business Intelligence Analys", link: "bi", icon: "user-tie" });
        this.state.msa.push({ pos: "c1", text: "MENY SYSTEM", desc: "TV System", link: "dsi", icon: "desktop" });
        this.state.msa.push({ pos: "c1", text: "ECOM SYSTEM", desc: "E Handels Analys", link: "ecom", icon: "shopping-cart" });

    }
    componentDidMount(){
        //this.forceUpdate();
        this.verify();
    }

    componentWillUnmount(){
        //this.setState({info: "UUU" });
    }
    logo(){
        var logo = null;
        if(this.store.cfg.logo == "cdi") logo = require('../img/logo-cdi.png');
        else logo = require('../img/logo-sk.png');
        return logo;
    }
    onScroll(event) {
        var body = document.getElementById("web-body");
        if (body.scrollTop > 150) {
          this.setState({top: 0})
          this.setState({info: 'small'})
        } else {
          this.setState({top: 1})
          this.setState({info: 'big:'+body.scrollTop})
        }

        if (true) {
            var offset = body.scrollTop;
            var start = body.scrollTop;
            var slut = body.scrollTop + window.innerHeight;

            for(var mnuid of this.state.mnus) {
                const element = document.getElementById(mnuid);
                if(!element || (element == null) || (element == undefined)) continue;
                var eltop = element.offsetTop;
                var elbot = element.offsetTop + element.offsetHeight;
                var brc = eltop >= start && eltop <= slut;
                //if(!brc) brc = elbot >= start && elbot <= slut;
                if(brc) {
                    this.setState({mnu_sel: mnuid});
                    return;
                }
            }
        }
    }
   header_css() {
  	    var css = {};
		if(this.state.top) {
			css.color = "#fff";
			css.background = "transparent";
			css.height = "50px";
			//css["background"] = "green";
		}
		else {
			css.color = "#000";
			css.background = "#fff";
		    css.height = "50px";
        }

		return css;
    }
    header_class() {
  	    var cls = "flex_col";
		if(this.state.top) {
			cls = cls + " web-head_1";
		}
		else cls = cls +  " web-header";

		return cls;
    }

    row_css(row) {
  	    var css = {};
		if(this.state.row) {
		if(this.state.row.ank == row.ank) {
			css.background = "#080";
			css.color = "#fff";
		}
		}
		return css;
    }
    login() {
        this.setState({login: true });
        //this.props.history.push("pos");
    }
    logout() {
        var self = this;
        var url = "/logout";
        self.props.navigate(url);
        self.props.navigate(0);
        return;

        var xss = sessionStorage.getItem("svp_sso");
		var xso = JSON.parse(xss);

        if(!xso) {
            var url = "/";
            self.props.navigate(url);
            return;
        }
		var prm = {};
        prm.req = "usr:logout";
		prm.uid = xso.seo.uid;
		prm.token = xso.seo.token;

		//alert(JSON.stringify(prm));
        net.gcs_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret));

            if(ret.ok != "000") {
                alert(JSON.stringify(ret));
                //return;
            }
            sessionStorage.removeItem("svp_sso");
            sso.seo = {};

            var url = "/logout";
            self.props.navigate(url);
            self.props.navigate(0);
            //self.props.history.go(0);
        })
        .catch(function(e){
            //var txt = JSON.stringify(e);
            var url = "/";
            self.props.navigate(url);
            //alert("LOGOUT ERR: " + JSON.stringify(e));
            //gda.wndError(txt);
        });
      };
    notifyShow() {
        //toast.info('<MDBIcon far icon="user" size="1x"/> Info message');
        //toast.success('Info message', {
        //    closeButton: false
        //  });
        //alert(this.state.notify);
        //this.setState({notify: true });
        //this.props.history.push("pos");
    }
    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    handleLogin(event) {
        //this.setState({value: event.target.value});
        //const body = new FormData(this.form);
        //alert('FORM: ' + JSON.stringify(state));
        //props.ctx.dologin(state.user.uid, state.user.pwd);
        this.login_srv(this.state.user.uid, this.state.user.pwd);
    }
	handleClose = () => {
		//this.props.ctx.setState({ login: false });
	};
    sysStart() {
        var url = "/pos";
        if(this.seo.auto) url = "/" + this.seo.auto;
        this.props.navigate(url);
   };
    verify() {
        var self = this;
        var xss = sessionStorage.getItem("svp_sso");
		var xso = JSON.parse(xss);

		var prm = {};
        prm.req = "ses:verify";
		prm.uid = xso.seo.uid;
		prm.token = xso.seo.token;

		//alert(JSON.stringify(prm));
        net.gcs_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret));

            if(ret.ok != "000") {
                //alert(JSON.stringify(ret));
                sessionStorage.removeItem("svp_sso");
                sso.seo = {};

                var url = "/sesout";
                self.navigate(url);
                //self.props.history.go(0);
                return;
            }
            //alert(JSON.stringify(self.seo));
            if(self.seo && self.seo.auth > 95) {
                var items = self.state.manitems;
                items.push( {fkn: 'live', text: 'LIVE STATS'} );
                self.setState({manitems: items });
            }

        })
        .catch(function(e){
            //var txt = JSON.stringify(e);
            var url = "/sesout";
            self.props.navigate(url);
            //self.props.history.go(0);
        });

      };
  br_menu() {
    //alert(JSON.stringify(fkn));
  }
    fknGo(fkn) {
        var url = "/" + fkn;
        this.props.navigate(url);
    }
    sel_fkn(sel) {
    //alert(JSON.stringify(fkn));
      if(sel.fkn == "logout") {
        var url = "/logout";
          this.props.navigate(url);
          //this.props.navigate(0);
      }
        if(sel.fkn == "man_bo") {
            var url = "/bo";
            this.props.navigate(url);
        }
        if(sel.fkn == "man_tech") {
            var url = "/pos/tech";
            this.props.navigate(url);
        }
      if(sel.fkn == "man_sid") {
          alert("SID");
      }
      if(sel.fkn == "man_ses") {
          var wnds = {};
          wnds.manses = true;
          this.setState({"wnds": wnds});
      }
      if(sel.fkn == "user_ses") {
          var wnds = {};
          wnds.userses = true;
          this.setState({"wnds": wnds});
      }
      if(sel.fkn == "user_setup") {
          var wnds = {};
          wnds.usersetup = true;
          this.setState({"wnds": wnds});
      }
  }

    render() {
        var stat = [];
        var keys = Object.keys(this.state.stat);
        for (var key of keys) {
            var row = this.state.stat[key];
            stat.push(row);
        }
        const handleDateChange = date => {
            this.setState({seldate: date });
        };

        return (
    <div className="web-app">
    <div className="web-top">

            <div id="header" className={ this.header_class() }>
            <div className="web-top-content d-flex flex-row">
                <div className="web-top-icon px-3 w60 hide" onClick={() => this.sysStart() }>
                    <FontAwesomeIcon icon="bars" size="1x"/>
                </div>
                <div className="web-top-txt txt_18">Hello</div>
                <div className="flex-fill"></div>
                <div className="web-top-usr d-flex flex-column">
                    <div><FontAwesomeIcon icon="building" size="1x"/> { this.seo.company }</div>
                    <div><FontAwesomeIcon icon="user" size="1x"/> { this.seo.name }</div>
                </div>
                <div className="">
                    <Dd_Sel items={ this.state.useritems } cls="web-top-icon" getValue={(e)=> this.sel_fkn(e)} />
                </div>
            </div>
            </div>
    </div>

    <div className="web-main">
    <div className="web-body">

        <div className="py-3"></div>

        <div className="">
            <div className="web-row flex">
                <div className="flex"></div>
                <h3>Välkommen: { this.seo.name }</h3>
                <div className="flex"></div>
            </div>
        </div>

        <div className="flex web-row">

                <div className="web-left">
                    <div className="web-row">
                        <div className="txt_18">MINA SYSTEM</div>
                    </div>
                    <div className="divider_grey"></div>

                    { this.state.msa.map((row, key) =>
                        <div key={key} className="web-bxi" onClick={() => this.fknGo( row.link ) }>
                            <div className="f30">
                                <FontAwesomeIcon icon={ row.icon } className="hello-icon"/>
                            </div>
                            <div className="web-col flex">
                                <div>{ row.text }</div>
                                <div className="flex-row">
                                    <div className="flex">{ row.desc }</div>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="flex"></div>
                    <div className="web-link" onClick={() => this.fknGo("pms") }>VISA ALLA SYSTEM</div>
                </div>

                <div className="web-col flex">
                    <div className="px-2">
                        <div className="web-row flex">
                            <div className="txt_18 flex">NYHETER</div>
                            <div className="web-link" onClick={() => this.fknGo("pms") }>VISA FLER</div>
                        </div>
                        <div className="divider_grey"></div>
                        <div className="web-col flex hide">
                            <div className="web-bx" onClick={() => this.fknGo("pms") }>
                                <div>SVEPOS A02</div>
                                <div className="flex-row">
                                    <div className="flex">Kassa system</div>
                                </div>
                            </div>
                            <div className="web-bx" onClick={() => this.fknGo("pms") }>
                                <div>SVEPOS A02</div>
                                <div className="flex-row">
                                    <div className="flex">Kassa system</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="web-right">
                    <div className="px-2">
                        <div className="web-row flex">
                            <div className="txt_18">ANVÄNDARE</div>
                        </div>
                        <div className="divider_grey"></div>
                        <div className="web-col flex">
                            <div className="txt_14">{ this.seo.name }</div>
                            <div className="txt_14">{ this.seo.company }</div>
                        </div>
                    </div>
                    <div className="py-3"></div>
                    <div className="px-2">
                        <div className="web-row flex">
                            <div className="txt_18 flex">FUNKTIONER</div>
                        </div>
                        <div className="divider_grey"></div>
                        <div className="web-col flex">
                            <div className="web-bx" onClick={() => this.fknGo("xx") }>
                                <div>KONTO</div>
                                <div className="flex-row">
                                    <div className="flex">Konto uppsättning</div>
                                </div>
                            </div>
                            <div className="web-bx" onClick={() => this.fknGo("xx") }>
                                <div>LOGG</div>
                                <div className="flex-row">
                                    <div className="flex">Logg för mitt konto</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="py-3"></div>
                    <div className="px-2">
                        <div className="web-row flex">
                            <div className="txt_18 flex">MANUALER</div>
                            <div className="web-link" onClick={() => this.fknGo("pms") }>VISA FLER</div>
                        </div>
                        <div className="divider_grey"></div>
                        <div className="web-col flex">
                            <div className="web-bx" onClick={() => this.fknGo("pms") }>
                                <div>SVEPOS A02</div>
                                <div className="flex-row">
                                    <div className="flex">Kassa system</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex"></div>
                    <div className="">
                        <img className="svk-logo" src={ this.logo() } />
                    </div>

                </div>

        </div>


    </div>
    </div>

    <div className="web-foot">
        <div className="web-foot-mnu" onClick={() => this.br_menu() }>
            <FontAwesomeIcon icon="bars" size="1x"/>
        </div>

        { (this.seo.auth > 90) ?
            <Dd_Sel items={ this.state.manitems } getValue={(e)=> this.sel_fkn(e)} />
        : null }

        <div className="flex"></div>

        <div className="web-foot-btn" onClick={() => this.logout() } >LOGGA UR</div>
        <div className="web-foot-icon">
          <FontAwesomeIcon icon="ellipsis-v" size="1x"/>
        </div>
        <div className="web-foot-btn" onClick={() => this.sysStart() }>STARTA</div>

        <div className="flex"></div>
        <Tiden />
    </div>

        <User_Setup wnds={this.state.wnds} ctx={this} />
        <User_Session wnds={this.state.wnds} ctx={this} />
        <Man_ChangeSes wnds={this.state.wnds} ctx={this} />
        <Man_Sessions wnds={this.state.wnds} ctx={this} />
    </div>
    );
    }
}

export default withRouter(Hello);
