import React, { useState, useEffect, useContext } from 'react';
import {Route, Routes} from "react-router-dom";
import {withRouter} from "../../lib/react/withRouter";

import * as net from "../../lib/net";

import Oms_Home from "./oms/home";
import Oms_Day from "./oms/day";
import Oms_Week from "./oms/week";
import Oms_Period from "./oms/period";
import sso from "../../sso";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Csc_Oms extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, tra: [] };
    this.state.avd = "01";
      var po = {};
      po.day = { "fid": "f05", "url": "/csc/oms/day", "name": "Dag", "active": false };
      po.week = { "fid": "f05", "url": "/csc/oms/week", "name": "Vecka", "active": false };
      po.period = { "fid": "f05", "url": "/csc/oms/period", "name": "Period", "active": false };
      this.state.po = po;
      this.state.pa = [];
      this.state.pa = Object.values(po);
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

    fknGo(row) {
        sso.seo.sfkn = row;
        this.props.navigate(row.url);
    }
    br_css(row) {
        var css = {};
        if(sso.seo.sfkn && (row.url == sso.seo.sfkn.url)) css.color = "#080";
        //var brc = false;
        //if(row.url == sbo.state.url) brc = true;
        return css;
    }

  render() {
      var path = window.location.pathname;

    return (
        <div className="web-app">
            { this.props.store.cfg.bread ?
                <div className="web-bread">
                    <div className="breadcrumb">
                        { this.state.pa.map((row, key) =>
                            <div key={key} className="breadcrumb-item" style={this.br_css(row)} onClick={() => this.fknGo(row)}>{row.name}</div>
                        )}
                        <div className="flex"></div>
                        <div className="cdigreen">{ sso.seo.sfkn ? sso.seo.sfkn.name : null }</div>
                    </div>
                </div>
                : null }
        <Routes>
            <Route path="day" element={ <Oms_Day store={this.webStore} />} />
            <Route path="week" element={ <Oms_Week store={this.webStore} />} />
            <Route path="period" element={ <Oms_Period store={this.webStore} />} />
            <Route path="*" element={ <Oms_Home store={this.webStore} />} />
        </Routes>
    </div>
  );
}
}
export default withRouter(Csc_Oms);
