import React, { useState, useEffect, useContext } from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";

import Tele_Top from './com/tele_top.js';
import Tele_Mnu from './com/tele_mnu.js';
import Tele_Home from './home.js';
import Tele_Dash from './dash.js';
import Tele_Stat from './stat.js';
import Tele_Drift from './drift.js';
import Tele_Stik from './stik.js';
import Tele_Bo from './bo.js';

import * as fkn from "../lib/fkn";
import * as srs from "../lib/srs";
import * as net from "../lib/net";
import Tiden from "../lib/ui/tiden";
import Stik_Home from "./stik/home";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function pg_home() {

    var html =
    <div className="web-content">
      Hem
    </div>
    ;
    return html;
}
function pg_s1() {

    var html =
    <div className="">
      S1
    </div>
    ;
    return html;
}
function pg_s2() {
    var units = [];
    units.push( {"id": "01", "unit": "u01", "text": "Tst 01"} );
    units.push( {"id": "02", "unit": "u02", "text": "Tst 02"} );

    var html =
    <div className="">
        <table className="table table-bordered table-striped table-hover">
            <thead>
            <tr>
                <th>ID</th>
                <th>Enhet</th>
                <th>Text</th>
                <th>Adress</th>
                <th>Postnr</th>
                <th>Stad</th>
                <th>Tel</th>
                <th>Öppet</th>
                <th>Status</th>
            </tr>
            </thead>
            <tbody>
            { units.map((row, key) =>
                <tr ondblclick={() => this.unit_open(row)}>
                <td>{ row.id }</td>
                <td>{ row.unit }</td>
                <td>{ row.text }</td>
                <td>{ row.adress }</td>
                <td>{ row.postnr }</td>
                <td>{ row.stad }</td>
                <td>{ row.tele }</td>
                <td>{ row.open }</td>
                <td>{ row.status }</td>
                </tr>
            )}
            </tbody>
        </table>
    </div>
    ;
    return html;
}
function pg_trans() {
    var units = [];
    units.push( {"id": "01", "unit": "u01", "text": "Tst 01"} );
    units.push( {"id": "02", "unit": "u02", "text": "Tst 02"} );

    var html =
    <div className="">
        <table className="table table-bordered table-striped table-hover">
            <thead>
            <tr>
                <th>ID</th>
                <th>Enhet</th>
                <th>Text</th>
                <th>Adress</th>
                <th>Postnr</th>
                <th>Stad</th>
                <th>Tel</th>
                <th>Öppet</th>
                <th>Status</th>
            </tr>
            </thead>
            <tbody>
            { units.map((row, key) =>
                <tr ondblclick={() => this.unit_open(row)}>
                <td>{ row.id }</td>
                <td>{ row.unit }</td>
                <td>{ row.text }</td>
                <td>{ row.adress }</td>
                <td>{ row.postnr }</td>
                <td>{ row.stad }</td>
                <td>{ row.tele }</td>
                <td>{ row.open }</td>
                <td>{ row.status }</td>
                </tr>
            )}
            </tbody>
        </table>
    </div>
    ;
    return html;
}

function web01() {

    var html =
    <div className="web-content">
    </div>
    ;
    return html;
}

class Tele extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, deps: [] };
    //context.posrend = this;
  }
/*
    const[txt, setTxt] = useState("SS00");
    var sdo = {};
    sdo.text = txt;
    sdo.setText = setTxt;
*/
  ddo() {
    alert("POS" + this.state.pgid );
    this.setState({ status: 1 });
  }
  udid_req() {
      var udid = "TT001920";
    var self = this;
	var req = {};
	req.req = "emv.print";
	req.udid = udid;
	req.fkn = "PRINT";

	var prm = {};
	req.prm = prm;

    //gda.wndLoading("Hämtar");
    srs.udid_req(req)
    .then(function(ret){
        //alert(JSON.stringify(ret.rco));
        //gda.wndClose();
        //var txt = JSON.stringify(data);
        //gda.wndSuccess(txt);
        //deps_list(ret.rco)

        alert(JSON.stringify(ret.rco));
        self.setState({ deps: ret.rco });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    })
    }
    settle() {
      var udid = "TT001920";
    var self = this;
	var req = {};
	req.req = "emv.settle";
	req.udid = udid;
	req.fkn = "SETTLE";

	var prm = {};
	req.prm = prm;

    //gda.wndLoading("Hämtar");
    srs.udid_req(req)
    .then(function(ret){
        //alert(JSON.stringify(ret.rco));
        //gda.wndClose();
        //var txt = JSON.stringify(data);
        //gda.wndSuccess(txt);
        //deps_list(ret.rco)

        alert(JSON.stringify(ret.rco));
        self.setState({ deps: ret.rco });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    })
    }

  render() {
    return (
    <div className="web-app">
    <div className="web-top">
        <Tele_Top store={this.webStore} />
    </div>
    <div className="web-main">

    <div className="web-row" style={{ height:"100%" }}>

    <div className="web-side">
        <Tele_Mnu store={this.webStore} />
    </div>

    <div className="web-body">
        <Routes>
          <Route path="/xx" component={pg_s1} />
          <Route path="/tele/yy" component={pg_s2} />
            <Route path="stat/*" element={ <Tele_Stat store={this.webStore} />} />
            <Route path="drift/*" element={ <Tele_Drift store={this.webStore} />} />
            <Route path="stik/*" element={ <Tele_Stik store={this.webStore} />} />
            <Route path="bo/*" element={ <Tele_Bo store={this.webStore} />} />
            <Route path="home" element={ <Tele_Home store={this.webStore} />} />
            <Route path="*" element={ <Tele_Dash store={this.webStore} />} />
        </Routes>
    </div>

    </div>

    </div>
    <div className="web-foot">
        <div className="web-foot-mnu" onClick={() => this.ddo() }>
            <FontAwesomeIcon icon="bars" size="1x"/>
        </div>
        <div className="flex flex-row">
            <div className="web-foot-btn" onClick={() => this.ddo() } >
                Telesystem
            </div>
            <div className="flex"></div>

            <div className="web-foot-btn" onClick={() => this.ddo() } >KÖER</div>
            <div className="web-foot-btn" onClick={() => this.ddo() } >ANKNYT</div>
        </div>
        <div className="web-foot-icon">
          <FontAwesomeIcon icon="ellipsis-v" size="1x"/>
        </div>
        <div className="flex flex-row">
            <div className="web-foot-btn" onClick={() => this.ddo() }>STATUS</div>

            <div className="flex"></div>
            <Tiden />
        </div>
    </div>

    </div>
  );
}
}
//Sys.contextType = ADOContext;

export default Tele;
