import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";
import ImgDlg from "./fkn/img_dlg";

import XrecovDlg from "./recov/xrecov_dlg";
import DrecovDlg from "./recov/drecov_dlg";
import PrecovDlg from "./recov/precov_dlg";
import Btn_Fkns from "./prds/btn_fkns";
import Wcm_Wnd from "./wcm/wcm_wnd";
import {withRouter} from "../../lib/react/withRouter";
import sso from "../../sso";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Tech_Home extends React.Component {
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.state = {};
    this.state.seldate = new Date();
    this.state.avd = "01";
    this.state.paa = [];
    this.state.aa = [];
      this.state.wnds = {};
      this.state.img = 0;
      this.state.img_content = null;
      this.state.loading = 0;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

  fknGo(url) {
    //alert(row.href);
    var row = {};
    row.url = url;
    sso.seo.sfkn = row;
    this.props.navigate(url);
  }
    xrecov() {
        var self = this;
        var wnds = {};
        wnds.xrecov = true;
        wnds.backdrop = true;
        self.setState({wnds: wnds });
    }
    drecov() {
        var self = this;
        var wnds = {};
        wnds.drecov = true;
        wnds.backdrop = true;
        self.setState({wnds: wnds });
    }
    precov() {
        var self = this;
        var wnds = {};
        wnds.precov = true;
        wnds.backdrop = true;
        self.setState({wnds: wnds });
    }
    btn_fkns() {
        var self = this;
        var wnds = { btn_fkns: true };
        this.setState({ wnds: wnds });
    }
    prn_tst() {
        var self = this;

        var prm = {};
        prm.req = "vcm.pa.pa_kvitto_prnbuf";
        prm.notanr = "028215E";
        //alert(JSON.stringify(prm));

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.prnbuf.svg));

                //var uri = "data:application/pdf;base64," + ret.pdf;
                self.setState({img: 1, img_content: ret.prnbuf.svg });
                //self.syncCols("lsTable");
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    prn_direct() {
        var self = this;

        var prm = {};
        prm.req = "vcm.run.prn_direct";
        prm.notanr = "028215E";
        //alert(JSON.stringify(prm));

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.prnbuf.svg));

                //var uri = "data:application/pdf;base64," + ret.pdf;
                //self.setState({img: 1, img_content: ret.prnbuf.svg });
                //self.syncCols("lsTable");
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    img_done (ret) {
        this.setState({loading: 0, img: 0, img_content: null });
    }
    swish_tst() {
        var self = this;

        var prm = {};
        prm.req = "vcm.swish.swish_refund";
        prm.notanr = "81026E";
        //alert(JSON.stringify(prm));

        net.sio_req(prm)
            .then(function(ret){
                alert(JSON.stringify(ret.prnbuf.svg));

                //var uri = "data:application/pdf;base64," + ret.pdf;
                //self.setState({img: 1, img_content: ret.prnbuf.svg });
                //self.syncCols("lsTable");
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    west_trs() {
        var self = this;

        var prm = {};
        //prm.req = "pos.pos_west.west_trs";
        prm.req = "pos.pos_west.west_trs_match";
        prm.notanr = "81026E";
        //alert(JSON.stringify(prm));

        net.sio_req(prm)
        .then(function(ret){
            alert(JSON.stringify(ret));

            //var uri = "data:application/pdf;base64," + ret.pdf;
            //self.setState({img: 1, img_content: ret.prnbuf.svg });
            //self.syncCols("lsTable");
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }
    trs_per_upd() {
        var self = this;

        var prm = {};
        prm.req = "pos.pos_trs.trs_per_upd";
        prm.fdat = "20230101";
        prm.tdat = "20230131";
        //alert(JSON.stringify(prm));

        net.sio_req(prm)
            .then(function(ret){
                alert(JSON.stringify(ret));

                //var uri = "data:application/pdf;base64," + ret.pdf;
                //self.setState({img: 1, img_content: ret.prnbuf.svg });
                //self.syncCols("lsTable");
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    webkassa() {
        var self = this;
        var wnds = { wcm: true };
        this.setState({ wnds: wnds });
    }
    creat_day() {
        var self = this;

        var prm = {};
        prm.req = "pos.pos_tech_recover.creat_day";
        prm.dts = "20230217";
        prm.avd = "01";
        //alert(JSON.stringify(prm));

        net.sio_req(prm)
            .then(function(ret){
                alert(JSON.stringify(ret));

                //var uri = "data:application/pdf;base64," + ret.pdf;
                //self.setState({img: 1, img_content: ret.prnbuf.svg });
                //self.syncCols("lsTable");
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }

  render() {
    return (
    <div className="web-app">
        <div className="web-pg py-4 flex">
            <h3>Tekniker</h3>
            <p>Tekniker funktioner</p>

        <div className="web-row">
            <div className="flex-col flex px-1">
                <div className="web-bx" onClick={() => this.xrecov() }>
                    <div>Kontrollera Period</div>
                    <div className="flex-row">
                        <div className="flex">X-Recov mm</div>
                        <div className="web-link">Välj</div>
                    </div>
                </div>
                <div className="web-bx" onClick={() => this.drecov() }>
                    <div>Återskapa Dag</div>
                    <div className="flex-row">
                        <div className="flex">Reparera trasig dag</div>
                        <div className="web-link">Välj</div>
                    </div>
                </div>
                <div className="web-bx" onClick={() => this.precov() }>
                    <div>Återskapa Period</div>
                    <div className="flex-row">
                        <div className="flex">Reparera trasig period</div>
                        <div className="web-link">Välj</div>
                    </div>
                </div>
                <div className="web-bx" onClick={() => this.creat_day() }>
                    <div>Skapa saknad dag</div>
                    <div className="flex-row">
                        <div className="flex">Skapa saknad dag</div>
                        <div className="web-link">Välj</div>
                    </div>
                </div>
            </div>

            <div className="flex-col flex px-1">
                <div className="web-bx" onClick={() => this.fknGo("/pos/tech/cpydep") }>
                    <div>Kopiera Avdelning</div>
                    <div className="flex-row">
                        <div className="flex"></div>
                        <div className="web-link">Välj</div>
                    </div>
                </div>
                <div className="web-bx" onClick={() => this.btn_fkns() }>
                    <div>Kopiera Knappar</div>
                    <div className="flex-row">
                        <div className="flex"></div>
                        <div className="web-link">Välj</div>
                    </div>
                </div>
                <div className="divider_orange"></div>
                <div className="web-bx" onClick={() => this.swish_tst() }>
                    <div>Swish Test</div>
                    <div className="flex-row">
                        <div className="flex"></div>
                        <div className="web-link">Välj</div>
                    </div>
                </div>
                <div className="web-bx" onClick={() => this.webkassa() }>
                    <div>Web Kassa</div>
                    <div className="flex-row">
                        <div className="flex"></div>
                        <div className="web-link">Välj</div>
                    </div>
                </div>
                <div className="web-bx" onClick={() => this.west_trs() }>
                    <div>West Transaktioner</div>
                    <div className="flex-row">
                        <div className="flex"></div>
                        <div className="web-link">Välj</div>
                    </div>
                </div>
                <div className="web-bx" onClick={() => this.trs_per_upd() }>
                    <div>Per Transaktioner Upd</div>
                    <div className="flex-row">
                        <div className="flex"></div>
                        <div className="web-link">Välj</div>
                    </div>
                </div>
            </div>

            <div className="flex-col flex px-1">
                <div className="web-bx" onClick={() => this.fknGo("/pos/tech/tdef") }>
                    <div>Terminaldefinition</div>
                    <div className="flex-row">
                        <div className="flex"></div>
                        <div className="web-link">Välj</div>
                    </div>
                </div>
                <div className="web-bx" onClick={() => this.prn_tst() }>
                    <div>Skrivare</div>
                    <div className="flex-row">
                        <div className="flex"></div>
                        <div className="web-link">Välj</div>
                    </div>
                </div>
                <div className="web-bx" onClick={() => this.prn_direct() }>
                    <div>Skrivare Skriv</div>
                    <div className="flex-row">
                        <div className="flex"></div>
                        <div className="web-link">Välj</div>
                    </div>
                </div>
                <div className="web-bx" onClick={() => this.fknGo("/pos/tech/clr") }>
                    <div>Rensa Filer</div>
                    <div className="flex-row">
                        <div className="flex"></div>
                        <div className="web-link">Välj</div>
                    </div>
                </div>
            </div>
        </div>


    </div>

        { this.state.wnds.xrecov ? <XrecovDlg wnds={this.state.wnds} ctx={this} store={this.store} backdrop={true}/> : null }
        { this.state.wnds.drecov ? <DrecovDlg wnds={this.state.wnds} ctx={this} store={this.store} backdrop={true}/> : null }
        { this.state.wnds.precov ? <PrecovDlg wnds={this.state.wnds} ctx={this} store={this.store} backdrop={true}/> : null }
        { this.state.wnds.btn_fkns ? <Btn_Fkns wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.wnds.wcm ? <Wcm_Wnd wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
        <ImgDlg show={this.state.img} img={this.state.img_content} done={(ret) => this.img_done(ret)} backdrop={true}/>
    </div>
  );
    }
}
export default withRouter(Tech_Home);
