import React from 'react';
import ReactDOM from 'react-dom';
//import { createRoot } from 'react-dom/client';

import 'bootstrap/dist/css/bootstrap.min.css';

//import '@fortawesome/fontawesome-free/css/all.min.css';
//import 'bootstrap-css-only/css/bootstrap.min.css';
//import 'mdbreact/dist/css/mdb.css';

import './css/index.css';
import './css/colours.css';
import './css/cdi-colours.css';
import './css/flex.css';
import './css/web.css';
import './css/tb.css';
import './css/form.css';
import './css/mdlg.css';
import './css/btns.css';
import './css/svk.css';
import './css/cdi.css';

//import 'react-toastify/dist/ReactToastify.css';
//import { ToastContainer } from 'react-toastify';
import {
    Chart,
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle
} from 'chart.js';

import RwsApp from './rws';
import * as serviceWorker from './serviceWorker';
import './assets/string.js';

import { library } from '@fortawesome/fontawesome-svg-core'
//import { fas } from '@fortawesome/free-solid-svg-icons'
//import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { far } from '@fortawesome/pro-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
library.add(fas, far, fab);

Chart.register(
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle
);

/*
const container = document.getElementById('root');
const root = createRoot(container);
root.render(<RwsApp tab="home" />);
*/


ReactDOM.render(
  <React.StrictMode>
    <RwsApp />
  </React.StrictMode>,
  document.getElementById('root')
);

/*
    <ToastContainer toastClassName="toast-rounded" />
*/
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
