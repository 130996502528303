import React, { useState, useEffect, useContext } from 'react';
import {format} from "date-fns";

import * as net from "../../../lib/net";
import Dd_Sel from "../../../lib/ui/dd_sel";
import Td_Sel from "../../../lib/ui/td_sel";

import PdfNotaDlg from "./fkn/pdf_nota_dlg";

import Nota_GstInfo from "../gst/gst_info";
import Nota_Master from "./master";
import Nota_Trs from "./trs";
import Nota_Paid from "./paid";
import Nota_Notor from "./notor";

import Nota_Fkn from "./fkn/nota_fkn";
import Nota_Sell from "./sell";
import Nota_Rad from "./fkn/notarad";
import Nota_Bilaga from "./bilaga";

import Nota_Pkat from "./pkat/pkat";
import Nota_Logi from "./logi";
import Nota_Collect from "./collect/collect";

import Pay_Wnd from "./pay/pay_wnd";
import Pay_Sel from "./pay/pay_sel";
import Pay_Emv from "./pay/pay_emv";
import Pay_Fakt from "./pay/pay_fakt";
import Pay_Pres from "./pay/pay_pres";
import Pay_Part from "./pay/pay_part";
import Pay_Belopp from "./pay/pay_belopp";

import Nota_Discount from "./discount";
import Pms_Term_Wnd from "../../wrk/term";
import Rst_Nota from "./rst_nota";

class Pms_Nota extends React.Component {
  constructor(props, context) {
    super(props);

     this.selitems = [
        {fkn: 'setup', text: 'INSTÄLLNINGAR'},
        {fkn: 'upd', text: 'UPPDATERA'},
        {fkn: 'sys', text: 'SYSTEM'},
        {fkn: 'logout', text: 'LOGGA UT'}
    ];
     this.rowitems = [
        {fkn: 'corr', text: 'KORR'},
        {fkn: 'open', text: 'ÖPPNA'}
    ];

    //alert('PBO CTX: ' + JSON.stringify(props.pbo));
   //this.webStore = props.store;
    this.seo = props.store.seo;

    this.state = {};
    this.state.ynq = [];
    //this.state.vga = [];
    this.state.tot = {};

    this.state.wnds = {};
    this.state.nta = [];
    this.state.pra = [];
    this.state.paa = [];
    this.state.aa = [];
    this.state.wnds = {};
    this.state.selrow = {};
    this.state.srow = {};

    this.state.boknr = "";
    this.state.rvo = {};
    if(props.wnds) {
        this.state.boknr = props.wnds.boknr;
      if(props.wnds.rvo) {
        this.state.rvo = props.wnds.rvo;
        this.state.boknr = this.state.rvo.gsb00.r0boknr;
      }
      if(props.wnds.prm) {
        this.state.rvo = props.wnds.prm;
        this.state.boknr = this.state.rvo.gsb00.r0boknr;
      }
    }
    this.state.wnd_sel = "";

    this.state.today = new Date();
    var dts = format(this.state.today, "yyyy-MM-dd");
    this.state.dts = dts;

    this.state.pdf = 0;
    this.state.pdf_content = null;
    this.state.loading = 0;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      this.charges();
  }
  pdf_done (ret) {
    this.setState({loading: 0, pdf: 0, pdf_content: null });
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }

    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    wnd_close() {
		this.props.ctx.setState({ wnds: {} });
	};
  tb_style() {
    var css = {};
    css.height = window.innerHeight - 280;
    css.overflow = "auto";
    return css;
  }
  row_style(row) {
    var srow = this.state.srow;
    var css = {};
    if(srow[row.etr01.e0lopnr]) {
        css.background = "#080";
        css.color = "#fff";
    }
    if(row.etr01.e0tkn == "-") {
        css.background = "#fff";
        css.color = "#800";
    }
    return css;
  }
    fkn_close(ctx) {
		ctx.setState({ wnd_sel: "" });
      ctx.charges();
	};
    wnds_close(ctx) {
		ctx.setState({ wnds: {} });
      ctx.charges();
	};

  term() {
    var self = this;
    var wnds = {};
    wnds.term = true;
    wnds.fkn_close = self.wnds_close;
    self.setState({ wnds: wnds });
  };

  fknSel(fkn) {
    var self = this;
    //alert(JSON.stringify(fkn));
      if(fkn == "prn") {
          this.prn_bilaga();
      }
      else if(fkn == "co") {
          this.gst_co();
      }
      else if(fkn == "refresh") {
          this.charges();
      }
      else if(fkn == "pay_all") {
        var wnds = {};
        wnds.pay_sel = true;
        wnds.boknr = self.state.boknr;
        wnds.rvo = self.state.rvo;
        wnds.nta = self.state.nta;
        wnds.srow = {};
        wnds.fkn_close = self.wnds_close;
        self.setState({ wnds: wnds, srow: {} });
      }
      else if(fkn == "pay_part") {
        var wnds = {};
        wnds.pay_part = true;
        wnds.boknr = self.state.boknr;
        wnds.rvo = self.state.rvo;
        wnds.nta = self.state.nta;
        wnds.srow = {};
        wnds.fkn_close = self.wnds_close;
        self.setState({ wnds: wnds, srow: {} });
      }
      else if(fkn == "pay_mark") {
        var nta = Object.values(this.state.srow);
        if(nta.length < 1) {
            alert("Inga rader markerade");
            return;
        }
        var wnds = {};
        wnds.pay_sel = true;
        wnds.boknr = self.state.boknr;
        wnds.rvo = self.state.rvo;
        wnds.nta = nta;
        wnds.srow = {};
        wnds.fkn_close = self.wnds_close;
        self.setState({ wnds: wnds, srow: {} });
      }
      else if(fkn == "bilaga") {
        var wnds = {};
        wnds.bilaga = true;
        wnds.boknr = self.state.boknr;
        wnds.rvo = self.state.rvo;
        wnds.nta = self.state.nta;
        wnds.srow = self.state.srow;
        wnds.fkn_close = self.wnds_close;
        self.setState({ wnds: wnds });
      }
      else if(fkn == "discount") {
        var wnds = {};
        wnds.discount = true;
        wnds.boknr = self.state.boknr;
        wnds.rvo = self.state.rvo;
        wnds.nta = self.state.nta;
        wnds.srow = self.state.srow;
        wnds.fkn_close = self.wnds_close;
        self.setState({ wnds: wnds });
      }
      else if(fkn == "fakt") {
        var wnds = {};
        wnds.fakt = true;
        wnds.boknr = self.state.boknr;
        wnds.rvo = self.state.rvo;
        wnds.nta = self.state.nta;
        wnds.srow = self.state.srow;
        wnds.fkn_close = self.wnds_close;
        self.setState({ wnds: wnds, srow: {} });
      }
      else {
        var wnds = {};
        wnds[fkn] = true;
        wnds.boknr = self.state.boknr;
        wnds.rvo = self.state.rvo;
        wnds.nta = self.state.nta;
        wnds.srow = self.state.srow;
        wnds.fkn_close = self.wnds_close;
        self.setState({ wnds: wnds });
        //this.setState({wnd_sel: fkn});
      }
  }

  row_sel(row) {
    var self = this;
      if(row.etr01.e0typ == "hot") {
          var text = row.etr01.e0text;
          var datum = row.etr01.e0datum;
          var avd = text.substr(0,2);
          var notanr = text.substr(2,7);
          //alert("REST: " + notanr);
            var wnds = {};
            wnds.rstnota = true;
            wnds.boknr = self.state.boknr;
            wnds.datum = datum;
            wnds.avd = avd;
            wnds.notanr = notanr;
            wnds.rvo = self.state.rvo;
            wnds.row = row;
            wnds.fkn_close = self.cb_rstnota;
            self.setState({ wnds: wnds });
      }
      else {
        var wnds = {};
        wnds.notarad = true;
        wnds.boknr = self.state.rvo.gsb00.r0boknr;
        wnds.rvo = self.state.rvo;
        wnds.row = row;
        wnds.fkn_close = self.wnds_close;
        self.setState({ wnds: wnds });
      }
  };
  cb_rstnota(ctx)
  {
    //ctx.setState({ wnds: {} });
    var prm = {};
    prm.req = "pms.charge.charges_xxp";
    prm.boknr = ctx.state.rvo.gsb00.r0boknr;
    prm.glopnr = ctx.state.rvo.gsb00.r0glopnr;

    net.sio_req(prm)
        .then(function(ret){
          //alert(JSON.stringify(ret.rca));

          var tot = ret.tot;
          var nta = ret.rca;
          var pra = [];
          for(var row of nta) {
            pra.push( row.etr01);
          }
          ctx.setState({ nta: nta, pra: pra, tot: tot, wnds: {} }, function () {
            ctx.syncTb("notaTable");
          });
        })
        .catch(function(e){
          var txt = JSON.stringify(e);
        });
  }
  clear() {
    this.setState({srow: {}, belopp: "0.00", belman:0});
  }
  set_all() {
    var srow = this.state.srow;
    var belopp = 0;
    for(var row of this.state.nta) {
        srow[row.etr01.e0lopnr] = row;
        var pris = row.etr01.e0pris * 1;
        if(row.etr01.e0tkn == "-") pris = pris * (-1);
        belopp = belopp + pris;
    }
    belopp = belopp.toString().cdipris();
    this.setState({srow: srow, belopp: belopp, belman:0});
  }
  sel_row(row) {
    var srow = this.state.srow;

    if(srow[row.etr01.e0lopnr]) {
      delete( srow[row.etr01.e0lopnr] );
    }
    else srow[row.etr01.e0lopnr] = row;
    this.setState({srow: srow});
  }
  sel_row_fkn(row, e) {
    var self = this;
    //alert(JSON.stringify(fkn));
      if(e.fkn == "corr") {
        var tro = row.etr01;
        self.corr_tr(tro);
      }
      if(e.fkn == "open") {
          self.row_sel(row);
      }
  }
  charge(betkod) {
    var self = this;
      if(betkod == "kont") {
        this.pay_trs(betkod);
      }
      if(betkod == "emv") {
        this.pay_emv();
      }
  }
  nota_rows_sel() {
    var self = this;
    var pra = Object.values(this.state.srow);
    alert( pra.length );
  }
  pay_emv() {
    var self = this;

    if(Object.keys(this.state.srow).length < 1) {
        alert("Inga rader betala");
        return;
    }

    var pra = [];
    //pra.push( this.state.selrow.etr01);
    var belopp = 0;
    for(var row of Object.values(this.state.srow)) {
        var pris = (row.etr01.e0antal * 1) * (row.etr01.e0pris * 1);
        if(row.etr01.e0tkn == "-") pris = pris * (-1);
        belopp = belopp + pris
        pra.push( row.etr01);
    }

    if(belopp == 0) {
        alert("Inget att betala");
        return;
    }
    belopp = belopp.toString();
    //alert("Belopp: " + belopp);

    var wnds = {};
    wnds.emv = 1;
    wnds.boknr = this.state.rvo.gsb00.r0boknr;
    wnds.gso = this.state.rvo.gsb00;
    wnds.pra = pra;
    wnds.belopp = belopp;
    wnds.cb = this.cb_payemv;
    this.setState({wnds: wnds});
  };
  pay_emv_all() {
    var self = this;


    var pra = [];
    //pra.push( this.state.selrow.etr01);
    var belopp = 0;
    for(var row of this.state.nta) {
        var pris = (row.etr01.e0antal * 1) * (row.etr01.e0pris * 1);
        if(row.etr01.e0tkn == "-") pris = pris * (-1);
        belopp = belopp + pris
        pra.push( row.etr01);
    }

    if(belopp == 0) {
        alert("Inget att betala");
        return;
    }
    belopp = belopp.toString();
    //alert("Belopp: " + belopp);

    var wnds = {};
    wnds.emv = 1;
    wnds.boknr = this.state.rvo.gsb00.r0boknr;
    wnds.gso = this.state.rvo.gsb00;
    wnds.pra = pra;
    wnds.belopp = belopp;
    wnds.cb = this.cb_payemv;
    this.setState({wnds: wnds});
  };

  cb_payemv(ctx, ret) {
      if(ret && (ret.ok == "000")) {

      }
      ctx.setState({ wnds: {}, srow: {} });
      ctx.charges();
  };

  prn_bilaga() {
    var self = this;

    var prm = {};
    prm.req = "pms.charge.prn_bilaga";
    prm.boknr = this.state.rvo.gsb00.r0boknr;
    prm.glopnr = this.state.rvo.gsb00.r0glopnr;
    //alert(JSON.stringify(prm));
    //return;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.ok));

        //var uri = "data:application/pdf;base64," + ret.pdf;
        self.setState({pdf: 1, pdf_content: ret.pdf });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  gst_co() {
    var self = this;

    var prm = {};
    prm.req = "pms.gst_op.gst_checkout";
    prm.boknr = this.state.rvo.gsb00.r0boknr;
    prm.glopnr = this.state.rvo.gsb00.r0glopnr;
    prm.rumsnr = this.state.rvo.gsb00.r0rumsnr;
    prm.gsb00 = this.state.rvo.gsb00;
    //alert(JSON.stringify(prm));
    //return;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.ok));
        if(ret.ok) {
		    self.props.ctx.setState({ wnds: {} });
            return;
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  pay_trs(betkod) {
    var self = this;

    if(Object.keys(this.state.srow).length < 1) {
        alert("Inga rader betala");
        return;
    }

    var pra = [];
    //pra.push( this.state.selrow.etr01);
    var belopp = 0;
    for(var row of Object.values(this.state.srow)) {
        var pris = (row.etr01.e0antal * 1) * (row.etr01.e0pris * 1);
        if(row.etr01.e0tkn == "-") pris = pris * (-1);
        belopp = belopp + pris
        pra.push( row.etr01 );
    }

    if(belopp == 0) {
        alert("Inget att betala");
        return;
    }
    belopp = belopp.toString();

    var wnds = {};
    wnds.pay = 1;
    wnds.boknr = this.state.rvo.gsb00.r0boknr;
    wnds.gso = this.state.rvo.gsb00;
    wnds.pra = pra;
    wnds.betkod = betkod;
    wnds.bettext = "BET: " + betkod;
    wnds.belopp = belopp;
    wnds.cb = this.cb_pay;
    this.setState({wnds: wnds});
  };

  cb_pay(ctx, ret) {
      if(ret && (ret.ok == "000")) {

      }
      ctx.setState({ wnds: {}, srow: {} });
      ctx.charges();
  };

  xxpay_trs(betkod) {
    var self = this;

    if(Object.keys(this.state.srow).length < 1) {
        alert("Inga rader betala");
        return;
    }

    var pra = [];
    //pra.push( this.state.selrow.etr01);
    var belopp = 0;
    for(var row of Object.values(this.state.srow)) {
        var pris = (row.etr01.e0antal * 1) * (row.etr01.e0pris * 1);
        if(row.etr01.e0tkn == "-") pris = pris * (-1);
        belopp = belopp + pris
        pra.push( row.etr01 );
    }

    if(belopp == 0) {
        alert("Inget att betala");
        return;
    }
    belopp = belopp.toString();

    var prm = {};
    prm.req = "pms.charge.pay_trs";
    prm.boknr = this.state.rvo.gsb00.r0boknr;
    prm.glopnr = this.state.rvo.gsb00.r0glopnr;
    prm.payrows = pra;
    prm.paycode = betkod;
    prm.paytext = "BET: " + betkod;
    prm.payamount = belopp;
    prm.tkn = "+";
    prm.datum = this.state.dts;
    //alert(JSON.stringify(prm));

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));
        if(ret.ok != "000") {
            alert("Betalning misslyckades");
            return;
        }
        var notanr = ret.notanr;
        self.setState({srow: {}});
        self.prn_spec(notanr);
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  pay_emv_trs() {
    var self = this;
    var prm = {};
    prm.req = "pms.charge.pay_emv_trs";
    prm.boknr = this.state.rvo.gsb00.r0boknr;
    prm.glopnr = this.state.rvo.gsb00.r0glopnr;
    prm.payrows = this.state.pra;
    prm.paycode = "";
    prm.paytext = "";
    prm.payamount = this.state.tot.open;
    prm.tkn = "+";
    prm.datum = this.state.dts;
    //alert(JSON.stringify(prm));

    net.sio_req(prm)
    .then(function(ret){
        alert(JSON.stringify(ret));
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  corr_tr(tro) {
    var self = this;

    var prm = {};
    prm.req = "pms.sell.corr_tr";
    prm.boknr = this.state.rvo.boknr;
    prm.gsb00 = this.state.rvo.gsb00;
    prm.etr01 = tro;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rco));

        //self.setState({rva: ret.rco });
        self.charges();
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  charges() {
    var self = this;
    var prm = {};
    prm.req = "pms.charge.charges_xxp";
    prm.boknr = this.state.rvo.gsb00.r0boknr;
    prm.glopnr = this.state.rvo.gsb00.r0glopnr;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        var tot = ret.tot;
        var nta = ret.rca;
        var pra = [];
        for(var row of nta) {
            pra.push( row.etr01);
        }
        self.setState({ nta: nta, pra: pra, tot: tot }, function() {
          self.syncTb("notaTable");
        });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  prn_spec(notanr) {
    var self = this;

    var prm = {};
    prm.req = "pms.charge.prn_spec";
    prm.boknr = this.state.rvo.gsb00.r0boknr;
    prm.glopnr = this.state.rvo.gsb00.r0glopnr;
    prm.notanr = notanr;
    //alert(JSON.stringify(prm));
    //return;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.ok));

        //var uri = "data:application/pdf;base64," + ret.pdf;
        self.setState({pdf: 1, pdf_content: ret.pdf });
        self.charges();
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
    syncTb(tb){
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
        tdElements = tbel.getElementsByTagName('td');

        for (let i = 0; i < thElements.length; i++) {
          if(!tdElements[i]) continue;
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
            width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
  topris(s) {
      if(!s) s = "";
      s = s.cdipris();
      return s;
    };
  val_date(val) {
      var ret = "";
      if(val) ret = val.toString().cdidate();
      return ret;
  }

  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "";

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="mx_backdrop" /> : null }
        <div className="mx_dlg mx_100 flex-col" style={ this.dlg_style() }>

        <div className="flex-row" style={{padding:"0px",height:"50px",lineHeight:"50px", background: "#1a456b", color: "#fff"}}>
                <div className="flex-row flex" style={{padding:"0px 5px", fontSize: "22px"}}>
                    <div>{ this.state.rvo.gsb00.r0rumsnr.cdirumsnr() } - { this.state.rvo.gsb00.r0namn }</div>
                    <div> : </div>
                    <div>{ this.state.rvo.gsb00.r0ankdatum.cdidate() } - { this.state.rvo.gsb00.r0avrdatum.cdidate() }</div>
                    <div className="flex"></div>
                    <div>{ this.state.rvo.gsb00.r0boknr } { this.state.rvo.gsb00.r0glopnr }</div>
                </div>
                <div className="web-top-sep"></div>
                <div className="web-top-usr d-flex flex-column" onClick={(e) => this.term() }>
                    { (this.seo.termnr && (this.seo.termnr != "")) ?
                    <div style={{padding: "0px", margin: "0px" }}>
                    <div className="txt_10">TERMNR</div>
                    <div className="txt_12 text-center">{ this.seo.termnr }</div>
                    </div>
                    :
                    <div style={{padding: "0px", margin: "0px" }}>
                    <div className="txt_10">STANDARD</div>
                    <div className="txt_10">TERMINAL</div>
                    </div>
                    }
                </div>
                <div className="web-top-sep"></div>
            <div className="">
                <Dd_Sel items={ this.selitems } cls="web-top-icon" getValue={(e)=> this.sel_fkn(e)} />
            </div>
        </div>
        <div className="mx_content flex">

        <div className="web-row flex">
        <div className="web-col" style={{padding: "0px", borderRight: "1px solid #B1A296", height: "100%"}}>
            <div className="nota-btn" onClick={() => this.fknSel("info") } >
                <div>GÄST</div>
                <div></div>
            </div>
            <div className="nota-btn" onClick={() => this.fknSel("master") } >
                <div>MASTERNOTA</div>
                <div></div>
            </div>
            <div className="nota-btn hide" onClick={() => this.fknSel("trs") } >
                <div>TRANSAKTIONER</div>
                <div></div>
            </div>
            <div className="nota-btn" onClick={() => this.fknSel("paid") } >
                <div>BETALT</div>
                <div></div>
            </div>
            <div className="nota-btn" onClick={() => this.fknSel("notor") } >
                <div>NOTOR</div>
                <div></div>
            </div>
        </div>
        <div className="web-col flex" style={{padding: "0px"}}>
        <div className="web-row" style={{padding:"5px",height:"50px",lineHeight:"20px", background: "#296ca8", color: "#fff", fontSize: "22px"}}>
            <div className="web-col" onClick={() => this.fknSel("rvo") } >
                <div style={{fontSize: "12px"}}>SALDO</div>
                <div>{ this.topris(this.state.tot.total) }</div>
            </div>
            <div className="flex"></div>
            <div className="web-col" onClick={() => this.fknSel("rvo") } >
                <div style={{fontSize: "12px"}}>MASTER</div>
                <div>{ this.topris(this.state.tot.master) }</div>
            </div>
            <div className="flex"></div>
            <div className="web-col" onClick={() => this.fknSel("rvo") } >
                <div style={{fontSize: "12px"}}>BETALT</div>
                <div>{ this.topris(this.state.tot.paid) }</div>
            </div>
            <div className="flex"></div>
            <div className="web-col" onClick={() => this.fknSel("rvo") } >
                <div style={{fontSize: "12px"}}>ATT BETALA</div>
                <div>{ this.topris(this.state.tot.open) }</div>
            </div>
       </div>

        <div id="notaTable" className="flex">
          <table className="table table-bordered table-hover tb" style={{marginBottom: "0px"}}>
            <thead>
            <tr>
                <th>Datum</th>
                <th>T</th>
                <th>Typ</th>
                <th>Text</th>
                <th>Antal</th>
                <th>Pris</th>
                <th>Summa</th>
                <th>Tecken</th>
                <th>Löpnr</th>
                <th></th>
            </tr>
            </thead>
        </table>
        <div style={ this.tb_style() }>
          <table className="table table-bordered table-hover tb">
            <tbody>
            { this.state.nta.map((row, key) =>
            <tr key={key} style={ this.row_style(row) } onClick={ () => this.sel_row(row) } onDoubleClick={ () => this.row_sel(row) }>
                <td>{ this.val_date( row.etr01.e0rdatum ) }</td>
                <td>{ row.etr01.e0bastyp }</td>
                <td>{ row.etr01.e0typ }</td>
                <td>{ row.etr01.e0text.toswe() }</td>
                <td className="text-center">{ row.etr01.e0antal.ztrim() }</td>
                <td className="text-right">{ row.etr01.e0pris.cdipris() }</td>
                <td className="text-right">{ ((row.etr01.e0antal * 1) * (row.etr01.e0pris * 1)).cdipris() }</td>
                <td>{ row.etr01.e0tkn }</td>
                <td>{ row.etr01.e0lopnr.ztrim() }</td>
                <td>
                    <Td_Sel items={ this.rowitems } getValue={(e)=> this.sel_row_fkn(row, e)} />
                </td>
            </tr>
            )}
            </tbody>
        </table>
        </div>
        </div>
        <div className="flex-row px-2">
            <div className="web-link" onClick={() => this.clear() }>Rensa</div>
            <div className="flex"></div>
            <div className="web-link" onClick={() => this.set_all() }>Välj alla</div>
        </div>

        </div>
         <div className="web-col" style={{padding: "0px", borderLeft: "1px solid #B1A296", height: "100%"}}>
            <div className="nota-btn" onClick={() => this.fknSel("pkat") } >
                <div>GÄST PÅSLAG</div>
                <div></div>
            </div>
            <div className="nota-btn" onClick={() => this.fknSel("logi") } >
                <div>RÄKNA FRAM</div>
                <div>AV LOGI</div>
            </div>
            <div className="nota-btn" onClick={() => this.fknSel("collect") } >
                <div>SAMLA IN</div>
                <div></div>
            </div>
             <div className="flex"></div>
            <div className="nota-pay-btn hide" onClick={() => this.fknSel("provision") } >
                <div>PROVISION</div>
                <div></div>
            </div>
            <div className="nota-pay-btn hide" onClick={() => this.fknSel("discount") } >
                <div>RABATT</div>
                <div></div>
            </div>
            <div className="nota-btn hide" onClick={() => this.nota_rows_sel() } >
                <div>VISA MARKERADE</div>
                <div></div>
            </div>
       </div>
       </div>
        <div className="web-row" style={{padding: "0px", borderTop: "1px solid #673AB7"}}>
            <div className="nota-btn hide" onClick={() => this.fknSel("fkn") } >
                <div>FUNKTIONER</div>
                <div></div>
            </div>
            <div className="nota-btn" onClick={() => this.fknSel("sell") } >
                <div>SÄLJGRUPPER</div>
                <div></div>
            </div>
            <div className="flex" style={{background: "#eee"}}></div>
            <div className="nota-pay-btn" onClick={() => this.fknSel("pay_mark") } >
                <div>BETALA MARKERADE</div>
                <div></div>
            </div>
            <div className="nota-pay-btn" onClick={() => this.fknSel("pay_all") } >
                <div>BETALA HELA NOTAN</div>
                <div></div>
            </div>
            <div className="nota-pay-btn" onClick={() => this.pay_emv_all() } >
                <div>EMV KORT</div>
                <div>HELA NOTAN</div>
            </div>
       </div>
        <div className="web-row" style={{padding: "0px", borderTop: "1px solid #080"}}>
            <div className="nota-btn" onClick={() => this.wnd_close() } >
                <div>STÄNG</div>
                <div></div>
            </div>
            <div className="nota-btn" onClick={() => this.fknSel("bilaga") } >
                <div>BILAGA</div>
                <div></div>
            </div>
            <div className="flex" style={{background: "#eee"}}></div>
            <div className="nota-pay-btn hide" onClick={() => this.fknSel("pay_bel") } >
                <div>BETALA BELOPP</div>
                <div></div>
            </div>
            <div className="nota-pay-btn hide" onClick={() => this.fknSel("pay_part") } >
                <div>BETALA ANDEL</div>
                <div></div>
            </div>
            <div className="nota-fkn-btn" onClick={() => this.fknSel("co") } >
                <div>CHECKA UT</div>
                <div></div>
            </div>
       </div>

        </div>
      </div>
    { this.state.wnds.info ? <Nota_GstInfo wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
    { this.state.wnds.master ? <Nota_Master wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
    { this.state.wnds.notor ? <Nota_Notor wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
    { this.state.wnds.trs ? <Nota_Trs wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
    { this.state.wnds.paid ? <Nota_Paid wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }

    { this.state.wnds.fkn ? <Nota_Fkn wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
    { this.state.wnds.sell ? <Nota_Sell wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
    { this.state.wnds.notarad ? <Nota_Rad wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
    { this.state.wnds.bilaga ? <Nota_Bilaga wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
    { this.state.wnds.rstnota ? <Rst_Nota wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }

    { this.state.wnds.pkat ? <Nota_Pkat wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
    { this.state.wnds.logi ? <Nota_Logi wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
    { this.state.wnds.collect ? <Nota_Collect wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }

    { this.state.wnds.pay ? <Pay_Wnd wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
    { this.state.wnds.pay_sel ? <Pay_Sel wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
    { this.state.wnds.emv ? <Pay_Emv wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
    { this.state.wnds.fakt ? <Pay_Fakt wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
    { this.state.wnds.pres ? <Pay_Pres wnds={this.state.wnds} ctx={this}  store={this.props.store} backdrop={true}/> : null }
    { this.state.wnds.pay_part ? <Pay_Part wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
    { this.state.wnds.pay_bel ? <Pay_Belopp wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }

    { this.state.wnds.discount ? <Nota_Discount wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }

    { this.state.wnds.term ? <Pms_Term_Wnd show={this.state.wnds.term} ctx={this} store={this.props.store} backdrop={true}/> : null }

    <PdfNotaDlg show={this.state.pdf} pdf={this.state.pdf_content} done={(ret) => this.pdf_done(ret)} backdrop={true}/>
    </div>
	;
	return html;
  };
}

export default Pms_Nota;

  // #edf4fb
  // #c2dbf0
  // #85b7e2
  // #4992d3
  // #296ca8
  // #1a456b
  // #153755
  // #102940
/*
.w3-flat-turquoise
{color:#fff;background-color:#1abc9c}
.w3-flat-emerald
{color:#fff;background-color:#2ecc71}
.w3-flat-peter-river
{color:#fff;background-color:#3498db}
.w3-flat-amethyst
{color:#fff;background-color:#9b59b6}
.w3-flat-wet-asphalt
{color:#fff;background-color:#34495e}
.w3-flat-green-sea
{color:#fff;background-color:#16a085}
.w3-flat-nephritis
{color:#fff;background-color:#27ae60}
.w3-flat-belize-hole
{color:#fff;background-color:#2980b9}
.w3-flat-wisteria
{color:#fff;background-color:#8e44ad}
.w3-flat-midnight-blue
{color:#fff;background-color:#2c3e50}
.w3-flat-sun-flower
{color:#fff;background-color:#f1c40f}
.w3-flat-carrot
{color:#fff;background-color:#e67e22}
.w3-flat-alizarin
{color:#fff;background-color:#e74c3c}
.w3-flat-clouds
{color:#000;background-color:#ecf0f1}
.w3-flat-concrete
{color:#fff;background-color:#95a5a6}
.w3-flat-orange
{color:#fff;background-color:#f39c12}
.w3-flat-pumpkin
{color:#fff;background-color:#d35400}
.w3-flat-pomegranate
{color:#fff;background-color:#c0392b}
.w3-flat-silver
{color:#000;background-color:#bdc3c7}
.w3-flat-asbestos
{color:#fff;background-color:#7f8c8d}
*/
    /*
    #5D5C61 // DARK BROWN
    #379683 // GREEN
    #7395AE // BLUE GRAY
    #557A95 // BLUE
    #B1A296 // BROWN
    */
