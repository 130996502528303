import React, { useState, useEffect, useContext } from 'react';

import * as utils from "../../../assets/utils";
import * as net from "../../../lib/net";

import Pms_Rvo from "../../rv/fb/rvo";
import Pms_Nota from "../nota/nota";
import Pms_CiPay from "./ci_pay";
import Pms_CiKeys from "./ci_keys";
import sso from "../../../sso";

class Pms_CiDone extends React.Component {
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.state = {};
    this.state.ynq = [];

    this.state.paa = [];
    this.state.aa = [];
    this.state.boknr = "";
    this.state.rvo = {};
    this.state.gso = {};
    this.state.wnds = {};

    if(props.wnds) {
        this.state.boknr = props.wnds.boknr;
        this.state.gso = props.wnds.gso;
        //this.state.gsb00 = props.wnds.gsb00;
        this.state.rvo.charges = {};
   }
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      this.key_new();
      this.rvg_belopp();
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }

    wnd_close() {
		this.props.ctx.setState({ wnds: {} });
	};
    tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
    }
    fknSel(sel) {
        //alert(JSON.stringify(fkn));
        if(sel == "rvo") {
            var wnds = {};
            wnds.wnd_rvo = true;
            this.setState({wnds: wnds});
        }
        if(sel == "nota") {
            var rvo = {};
            rvo.boknr = this.state.boknr;
            rvo.gsb00 = this.state.gso;

            var rsp = {};
            rsp.ok = "000";
            rsp.fkn = "nota";
            rsp.boknr = this.state.boknr;
            rsp.rvo = rvo;
            if(this.props.wnds.cb) this.props.wnds.cb(this.props.ctx, rsp);
		    else this.props.ctx.setState({ wnds: {} });
       }
        if(sel == "cipay") {
            var wnds = {};
            wnds.cipay = true;
            wnds.boknr = this.state.boknr;
            wnds.gso = this.state.gso;
            this.setState({wnds: wnds});
       }
        if(sel == "keys") {
            var wnds = {};
            wnds.cikeys = true;
            wnds.boknr = this.state.boknr;
            wnds.gso = this.state.gso;
            this.setState({wnds: wnds});
       }
    }
    rvo_close(ctx) {
        ctx.setState({ wnds: {} });
    };
    gst_close(ctx) {
        ctx.setState({ wnds: {} });
    };
    room_tv() {
        var self = this;

        var prm = {};
        prm.req = "pms.hk.room_tv";
        prm.rumsnr = "0205";
        prm.namn = "Teste Testsson";
        //alert(JSON.stringify(prm));

        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret));

            if(ret.ok == "000") {
            }
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
        return;
    }
    room_msg() {
        var self = this;

        var prm = {};
        prm.req = "pms.hk.room_tv";
        prm.rumsnr = "0205";
        prm.namn = "Teste Testsson";
        //alert(JSON.stringify(prm));

        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret));

            if(ret.ok == "000") {
            }
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
        return;
    }
  rvg_belopp() {
    var self = this;

    var prm = {};
    //prm.req = "pms.br.brg_rr_upd";
    prm.req = "pms.charge.rvg_belopp";
    prm.boknr = this.state.gso.r0boknr;
    prm.glopnr = this.state.gso.r0glopnr;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rco));

        var rvo = self.state.rvo;
        rvo.charges.bokat = ret.rco.bokat;
        self.setState({rvo: rvo });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  charge_todate() {
    var self = this;

    var todate = this.state.gso.r0avrdatum;

    var prm = {};
    prm.req = "pms.sell.charge_todate";
    prm.gsb00 = this.state.gso;
    prm.permanent = 1;
    prm.datum = todate;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            return;
        }
        self.charges_pkats();
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  charges_pkats() {
    var self = this;
    var prm = {};
    prm.req = "pms.charge.charges_pkats";
    prm.boknr = this.state.gso.r0boknr;
    prm.glopnr = this.state.gso.r0glopnr;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            return;
        }
        var tot = ret.tot;
        var pka = ret.rca;
        //self.setState({ pka: pka, tot: tot });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  key_new() {
    var self = this;

    var prm = {};
    prm.req = "pms.hk.room_key";
    prm.rumsnr = this.state.gso.r0rumsnr;
    prm.kodare = sso.seo.encoder;
    prm.nrk = this.state.gso.r0guests;
    prm.datum = this.state.gso.r0avrdatum;
    //alert(JSON.stringify(prm));

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok == "000") {
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
    return;
  }
  key_copy() {
    var self = this;

    var prm = {};
    prm.req = "pms.hk.room_key";
    prm.rumsnr = this.state.gso.r0rumsnr;
    prm.kodare = sso.seo.encoder;
    //alert(JSON.stringify(prm));

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok == "000") {
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
    return;
  }

  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "";

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="m2_backdrop" /> : null }
        <div className="m2_dlg mx_70h flex-col" style={ this.dlg_style() }>
            <div className="mx_h01 flex-row" style={{background: "#1a456b", color: "#fff"}}>
                    <div className="flex-col flex">
                        <div>INCHECKAD</div>
                    </div>
                    <div className="flex-col">
                        <div>BOKNR: { this.state.boknr }</div>
                    </div>
            </div>
        <div className="mx_content flex">

         <div className="flex-row flex">
         <div className="flex-col flex">

            <div className="txt_box flex-col">
            <div className="flex-row">
                <div className="wnd_link">
                    DELBOKNING
                </div>
                <div className="flex"></div>
                <div className="wnd_link" onClick={() => this.gst_upd() }>
                    UPPDATERA
                </div>
            </div>
            <div className="flex-row">
                    <div className="flex-column flex py-2">
                        <div className="txt_lbl">PERIOD:</div>
                        <div className="txt_data">{ this.state.gso.r0ankdatum.cdidate() } - { this.state.gso.r0avrdatum.cdidate() }</div>
                    </div>
                    <div className="flex-column flex py-2">
                        <div className="txt_lbl">RUMSTYP:</div>
                        <div className="txt_data">{ this.state.rvo.rt }</div>
                    </div>
            </div>
            </div>
            <div className="txt_box flex-col flex">
            <div className="flex-row">
                <div className="wnd_link">
                    GÄST
                </div>
                <div className="flex"></div>
                <div className="wnd_link" onClick={() => this.gst_upd() }>
                    UPPDATERA
                </div>
            </div>
            <div className="flex-row">
                    <div className="flex-column flex py-2">
                        <div className="txt_lbl">NAMN:</div>
                        <div className="txt_data">{ this.state.gso.r0namn.toswe() }</div>
                    </div>
                    <div className="flex-column flex py-2">
                        <div className="txt_lbl">RUMSNR:</div>
                        <div className="txt_data">{ this.state.gso.r0rumsnr.cdirumsnr() }</div>
                    </div>
            </div>
            <div className="flex-row">
                    <div className="flex-column flex py-2">
                        <div className="txt_lbl">GÄSTER:</div>
                        <div className="txt_data">{ this.state.gso.r0guests.ztrim() }</div>
                    </div>
            </div>
            </div>

            </div>

           <div className="txt_box txt_bg_01 txt_22 w250">
                    <div className="flex-row flex">
                        <div className="txt_lbl flex">BOKAT VÄRDE:</div>
                        <div className="txt_price">{ utils.toPris( this.state.rvo.charges.bokat ) }</div>
                    </div>
                    <div className="flex-row flex">
                        <div className="txt_lbl flex">SALDO:</div>
                        <div className="txt_price">{ utils.toPris( this.state.rvo.charges.saldo ) }</div>
                    </div>
                    <div className="flex-row flex">
                        <div className="txt_lbl flex">BETALT:</div>
                        <div className="txt_price">{ utils.toPris( this.state.rvo.charges.paid ) }</div>
                    </div>
                    <div className="flex-row flex">
                        <div className="txt_lbl flex">ATT BETALA:</div>
                        <div className="txt_price">{ utils.toPris( this.state.rvo.charges.topay ) }</div>
                    </div>
            </div>
        </div>

        <div className="flex"></div>

        <div className="divider_blue"></div>
        <div className="web-row">
            <div className="wnd-btn" onClick={() => this.fknSel("fsk") } >
                <div>BETALA FÖRSKOTT</div>
                <div></div>
            </div>
            <div className="wnd-btn" onClick={() => this.fknSel("cipay") } >
                <div>BETALA VISTELSE</div>
            </div>
        </div>
        <div className="divider_blue"></div>

        <div className="web-row">
            <div className="wnd-btn" onClick={() => this.fknSel("roomfkn") } >
                <div>RUMSFUNKTIONER</div>
                <div></div>
            </div>
            <div className="wnd-btn" onClick={() => this.fknSel("keys") } >
                <div>NYCKLAR</div>
            </div>
        </div>

        <div className="divider_orange"></div>
        <div className="web-row">
            <div className="wnd-btn" onClick={() => this.fknSel("unci") } >
                <div>ÅTERTAG INCHECKNING</div>
                <div></div>
            </div>
            <div className="wnd-btn" onClick={() => this.fknSel("chroom") } >
                <div>BYT RUM</div>
            </div>
        </div>
        <div className="web-row">
            <div className="wnd-btn" onClick={() => this.wnd_close() } >
                <div>STÄNG</div>
                <div></div>
            </div>
            <div className="wnd-btn" onClick={() => this.fknSel("nota") } >
                <div>ÖPPNA NOTA</div>
            </div>
        </div>

        </div>
      </div>
            { this.state.wnds.wnd_rvo ?
        <Pms_Rvo close={this.rvo_close} boknr={this.state.boknr} ctx={this} store={this.props.store} backdrop={true}/>
            : null }
            { this.state.wnds.nota ?
        <Pms_Nota wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
            : null }
            { this.state.wnds.cipay ?
        <Pms_CiPay wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
            : null }
            { this.state.wnds.cikeys ?
        <Pms_CiKeys wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
            : null }
    </div>
	;
	return html;
  };
}

export default Pms_CiDone;