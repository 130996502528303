import React, { useState, useEffect, useContext } from 'react';
import {Route, Routes} from "react-router-dom";
import {withRouter} from "../lib/react/withRouter";
import { observer } from 'mobx-react'

import Ccs_Home from './ccs/home.js';
import Ccs_Dash from './ccs/dash.js';
import Ccs_Pt from './ccs/pt.js';
import Ccs_Oms from './ccs/oms.js';
import Ccs_Rps from './ccs/rps.js';
import Ccs_Units from './ccs/units.js';
import * as net from "../lib/net";
import sso from "../sso";

function pg_home() {

    var html =
    <div className="web-content">
      Hem
    </div>
    ;
    return html;
}

class Pos_Ccs extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.state = { pgid: 11, deps: [] };
    this.state.wnds = {};

    this.units();
      var po = {};
      po.pt = { "fid": "f01", "url": "/pos/ccs/pt", "name": "Status", "active": false };
      po.oms = { "fid": "f01", "url": "/pos/ccs/oms", "name": "Period", "active": false };
      po.rps = { "fid": "f01", "url": "/pos/ccs/rps", "name": "Rapporter", "active": false };
      po.units = { "fid": "f01", "url": "/pos/ccs/units", "name": "Enheter", "active": false };
      this.state.po = po;
      this.state.pa = [];
  }
    componentDidUpdate() {
    }
    componentWillUnmount() {
    }
    componentDidMount() {
      /*
        var path = window.location.pathname;
        if(path.substr(-1) == "/") path = path.substring(0, path.length - 1);
        var op = path.split("/").pop();

        var po = this.state.po;
        if(po[op]) {
            po[op].active = true;
            //this.props.store.fkn = po[op];
            sbo.state.fkn = po[op].name;
            //alert(JSON.stringify(sbo.state));
        }
        var pa = Object.values(po);
        this.setState({ po: po, pa: pa });
    */
    }
  units() {
    var self = this;

    if(!self.props.store.pos) self.props.store.pos = {};
    self.props.store.pos.csa = [];

    if( this.props.store.seo.cs < "1") return;

    var prm = {};
    prm.req = "cs.cs_units.csa";
    //prm.fdat = fdat;
    //prm.tdat = tdat;

    net.ccs_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            alert(JSON.stringify(ret));
            return;
        }
        var sa = ret.rca;
        if(!self.props.store.pos) self.props.store.pos = {};
        self.props.store.pos.csa = sa;
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
    fknGo(row) {
        sso.seo.sfkn = row;
        this.props.navigate(row.url);
    }
    br_css(row) {
        var css = {};
        if(sso.seo.sfkn && (row.url == sso.seo.sfkn.url)) css.color = "#080";
        //var brc = false;
        //if(row.url == sbo.state.url) brc = true;
        return css;
    }

    render() {
        return (
            <div className="web-app">
                { this.props.store.cfg.bread ?
                    <div className="web-bread">
                        <div className="breadcrumb">
                            { this.state.pa.map((row, key) =>
                                <div key={key} className="breadcrumb-item" style={this.br_css(row)} onClick={() => this.fknGo(row)}>{row.name}</div>
                            )}
                            <div className="flex"></div>
                            <div className="cdigreen">{ sso.sdo.sfkn ? sso.sdo.sfkn.name : null }</div>
                        </div>
                    </div>
                    : null }
        <Routes>
          <Route path="/xx" component={pg_home} />
            <Route path="dash" element={ <Ccs_Dash store={this.store} />} />
            <Route path="pt" element={ <Ccs_Pt store={this.store} />} />
            <Route path="oms" element={ <Ccs_Oms store={this.store} />} />
            <Route path="rps" element={ <Ccs_Rps store={this.store} />} />
            <Route path="units" element={ <Ccs_Units store={this.store} />} />
            <Route path="home" element={ <Ccs_Home store={this.store} />} />
            <Route path="*" element={ <Ccs_Dash store={this.store} />} />
        </Routes>
    </div>

  );
}
}
//Sys.contextType = ADOContext;

export default withRouter(observer(Pos_Ccs));

/*
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb my-breadcrumb">
                <li className="breadcrumb-item"><a href="/pos/stat/total">Total</a></li>
                <li className="breadcrumb-item"><a href="/pos/stat/grp">Grupp</a></li>
                <li className="breadcrumb-item"><a href="/pos/stat/hh">Tim</a></li>
                <li className="breadcrumb-item"><a href="/pos/stat/week">Vecko</a></li>
                <li className="breadcrumb-item active" aria-current="page">Trans</li>
                <li className="breadcrumb-item"><a href="/pos/stat/art">Art</a></li>
                <li className="breadcrumb-item"><a href="/pos/stat/drift">Drift</a></li>
            </ol>
        </nav>
*/