import React, { useState, useEffect, useContext } from 'react';
import {withRouter} from "../../lib/react/withRouter";
import sso from "../../sso";

class Rps_Home extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = {};
    this.state.seldate = new Date();
    this.state.avd = "01";

      this.state.mna = [];
      this.state.mna.push({ mnid: "mn01", pos: "c1", text: "RAPPORTER", desc: "Rapporter tidsystem", link: "/ta/rps/rps" });
      this.state.mna.push({ mnid: "mn02", pos: "c1", text: "REGISTER", desc: "Register utdrag", link: "/ta/rps/reg" });
      this.state.mna.push({ mnid: "mn03", pos: "c2", text: "LÖNERAPPORTER", desc: "Lönelistor mm", link: "/ta/rps/sal" });

  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 480;
      return css;
  }
    mnc(pos) {
        var mna = [];
        for(var row of this.state.mna) {
            if(row.pos == pos) mna.push( row );
        }
        return mna;
    }
    fknGo(url) {
        //alert(row.href);
        var row = {};
        row.url = url;
        sso.seo.sfkn = row;
        this.props.navigate(url);
    }

    render() {
        return (
    <div className="web-app">
        <div className="web-pg py-4 flex">
            <h3>RAPPORTER</h3>
            <p>RAPPORTER funktioner</p>

            <div className="divider_green"></div>

            <div className="web-row">
                <div className="flex-col flex">
                    { this.mnc("c1").map((row, key) =>
                        <div key={key} className="web-bx" onClick={() => this.fknGo(row.link) }>
                            <div>{ row.text }</div>
                            <div className="flex-row">
                                <div className="flex">{ row.desc }</div>
                                <div className="web-link">Välj</div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="flex-col flex">
                    { this.mnc("c2").map((row, key) =>
                        <div key={key} className="web-bx" onClick={() => this.fknGo(row.link) }>
                            <div>{ row.text }</div>
                            <div className="flex-row">
                                <div className="flex">{ row.desc }</div>
                                <div className="web-link">Välj</div>
                            </div>
                        </div>
                    )}
                </div>
            </div>

    </div>

    </div>
        );
    }
}
export default withRouter(Rps_Home);
