import React, { useState, useEffect, useContext } from 'react';
import { observer } from 'mobx-react'
import ddo from '../../ddo.js'

import Fsg_dlg from "./red/fsg_dlg";
import Mr_Dlg from "./red/mr_dlg";
import Bok_Dlg from "./red/bok_dlg";
import Ater_Dlg from "./red/ater_dlg";
import Cr_Dlg from "./red/cr_dlg";
import Cred_Dlg from "./red/cred_dlg";
import Tred_Dlg from "./red/tred_dlg";
import Tr_Dlg from "./red/tr_dlg";
import Oms_Dlg from "./red/oms_dlg";

import Xrecov_Dlg from "./dlg/xrecov_dlg";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Pos_Red extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = {};
    this.state.seldate = new Date();
    this.state.avd = ddo.cache.avd;
    this.state.paa = [];
    this.state.aa = [];

    this.state.pdf = 0;
    this.state.rptyp = "";
    this.state.xrecov = 0;

      this.state.mna = [];
      this.state.mna.push({ rpid: "fsg", pos: "c1", text: "FÖRSÄLJNINGSRAPPORT", desc: "Försäljning och varugruppsrapport", link: "" });
      this.state.mna.push({ rpid: "bok", pos: "c1", text: "BOKFÖRINGSRAPPORT", desc: "Löpande kontorapport", link: "" });
      this.state.mna.push({ rpid: "mr", pos: "c1", text: "MANAGERRAPPORT", desc: "Manager rapport", link: "" });
      this.state.mna.push({ rpid: "oms", pos: "c1", text: "OMSÄTTNINGSRAPPORT", desc: "Omsättningsrapport", link: "" });
      this.state.mna.push({ rpid: "ater", pos: "c2", text: "ÅTERKÖPSRAPPORT", desc: "Dagliga återköp", link: "" });
      this.state.mna.push({ rpid: "tr", pos: "c2", text: "TRANSAKTIONS RAPPORT", desc: "Periodens transaktioner", link: "" });
      this.state.mna.push({ rpid: "cr", pos: "c2", text: "KASSARAPPORT", desc: "Kassarapport för kassör", link: "" });
      this.state.mna.push({ rpid: "cred", pos: "c2", text: "KASSÖRSRAPPORT", desc: "Sammanställd kassörs rapport för perioden", link: "" });
      this.state.mna.push({ rpid: "tred", pos: "c2", text: "TOTAL REDOVISNING", desc: "Sammanställd kassörs redovisning för perioden", link: "" });
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 480;
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

    mnc(pos) {
        var mna = [];
        for(var row of this.state.mna) {
            if(row.pos == pos) mna.push( row );
        }
        return mna;
    }
  rp_open(typ) {
    var self = this;
    this.setState({ rptyp: typ });
  }

  render() {
    return (
        <div className="web-app">
        <div className="web-pg py-4 flex">
            <h3>Redovisning</h3>
            <p>Redovisning funktioner</p>

            <div className="divider_green"></div>

        <div className="web-row">
            <div className="flex-col flex">
                { this.mnc("c1").map((row, key) =>
                    <div key={key} className="web-bx" onClick={() => this.rp_open(row.rpid) }>
                        <div>{ row.text }</div>
                        <div className="flex-row">
                            <div className="flex">{ row.desc }</div>
                            <div className="web-link">Välj</div>
                        </div>
                    </div>
                )}
            </div>
            <div className="flex-col flex">
                { this.mnc("c2").map((row, key) =>
                    <div key={key} className="web-bx" onClick={() => this.rp_open(row.rpid) }>
                        <div>{ row.text }</div>
                        <div className="flex-row">
                            <div className="flex">{ row.desc }</div>
                            <div className="web-link">Välj</div>
                        </div>
                    </div>
                )}
            </div>
        </div>
        </div>

        { this.state.rptyp == "fsg" ? <Fsg_dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "mr" ? <Mr_Dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "bok" ? <Bok_Dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "ater" ? <Ater_Dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "cr" ? <Cr_Dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "cred" ? <Cred_Dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "tr" ? <Tr_Dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "oms" ? <Oms_Dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "tred" ? <Tred_Dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.xrecov ? <Xrecov_Dlg show={this.state.xrecov} ctx={this} store={this.props.store} backdrop={true}/> : null }
    </div>
  );
    }
}
export default observer(Pos_Red);
