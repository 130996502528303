import React, { useState, useEffect, useContext } from 'react';


import * as net from "../../lib/net";
import Sid_Add from "./sid_add";
import Sid_Open from "./sid_open";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Bo_Sids extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.pos = props.store.pos;

    this.state = {};
    this.state.sio = null;
    this.state.seldate = new Date();
    this.state.avd = "01";
    this.state.saa = [];
    this.state.paa = [];
    this.state.sidadd = 0;
    this.state.sidopen = 0;
    this.state.pbo = {};
    this.state.aa = [
                {id: '01', text: 'AVD 01'},
                {id: '02', text: 'AVD 02'}
            ];
    //context.posrend = this;
    if(this.pos.deps) this.state.aa = this.pos.deps;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.sids();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 250;
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

  ccs() {
    var self = this;
    this.setState({ ccs: 1 });
  }
  sid_add(row) {
      var self = this;
    this.setState({ pbo: row, sidadd: 1 });
  }
  sid_open(row) {
      var self = this;
    this.setState({ pbo: row, sidopen: 1 });
  }
  users_sz() {
      var self = this;
      var poa = self.state.poa;

      var paa = poa.filter(row => row.plu00.p0namn.includes("z"));
      self.setState({paa: paa });
   }

  sids() {
    var self = this;
    var prm = {};
    prm.req = "sid:list";

    net.gcs_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        self.setState({saa: ret.rca });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  tst() {
    var self = this;
    var prm = {};
    prm.req = "sid:list";
    //prm.sz = "a";

    //gda.wndLoading("Hämtar");
    net.gcs_req(prm)
    .then(function(ret){
        alert(JSON.stringify(ret));

        var o = {};
        //o.deps = ret.rco;
        //alert(JSON.stringify(o));
        //self.setState({paa: ret.rco });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }

  render() {
    return (
    <div className="web-app d-flex flex-column">

    <div className="web-p01">
    <div className="flex-row">
        <div className="txt_18">SYSTEM</div>
        <div className="flex"></div>
        <div className="rlink_12" onClick={() => this.ccs() }>CENTRAL SYSTEM</div>
    </div>
    <div className="divider_orange"></div>
    <div className="flex-row">
        <select className="form-control w250" placeholder="Välj grupp">
            { this.state.aa.map((row, key) =>
            <option key={key} value={row.id}>{row.text}</option>
            )}
        </select>
        <div className="flex"></div>
        <div className="link_14" onClick={() => this.sid_add() }>NYTT SYSTEM</div>
        <div className="link_14" onClick={() => this.sids() }>UPPDATERA</div>
    </div>
    <div className="divider_orange"></div>

    <div className="web-row">
        <table className="table table-bordered table-hover tb fixed_header">
            <thead>
            <tr>
                <th>SID</th>
                <th>Text</th>
                <th>Termnr</th>
                <th>xx</th>
                <th>xx</th>
                <th>xx</th>
                <th>Status</th>
                <th className="w50"></th>
            </tr>
            </thead>
            <tbody style={ this.tb_style() }>
            { this.state.saa.map((row, key) =>
            <tr key={key} onDoubleClick={() => this.sid_open(row) }>
                <td>{ row.sid00.s0sid }</td>
                <td>{ row.sid00.s0text.toswe() }</td>
                <td className="text-left">{ row.sid00.s0termnr }</td>
                <td>{ row.sid00.s0xx }</td>
                <td>{ row.sid00.s0xx }</td>
                <td>{ row.sid00.s0xx }</td>
                <td>{ row.sid00.s0status }</td>
                <td className="text-center w40" style={{cursor:"pointer"}} onClick={() => this.sid_open(row) }><i className="fa fa-cog text-danger"></i></td>
            </tr>
            )}
            </tbody>
        </table>
    </div>

    </div>

            { this.state.sidadd ?
        <Sid_Add show={this.state.sidadd} ctx={this} pbo={this.state.pbo} backdrop={true}/>
            : null }
            { this.state.sidopen ?
        <Sid_Open show={this.state.sidopen} cb={(e)=> {this.setState({sidopen: false}); this.sids();} } ctx={this} pbo={this.state.pbo} backdrop={true}/>
            : null }
    </div>
  );
    }
}
export default Bo_Sids;

/*

*/