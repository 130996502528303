import React, { useState, useContext } from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { observer } from 'mobx-react';
import { useStore } from '../../sdo.js'
import {useNavigate} from "react-router-dom";
import sso from "../../sso";

function Pms_Mnu(props) {
    var navigate = useNavigate();
    let [state, setState] = useState({mnu: "", rvn: 0, row: {}, htitle: "", ma: []});
    let ctx = useStore();

    var lock = "lock";
    var moa = {};
    moa["f00"] = { fid: "f00", name: "Översikt", url: "/pms", icon: "home", fa: "fa fa-home flex", ma: [] };
    moa["f01"] = { fid: "f01", name: "Bokdesk", url: "/pms/fo", icon: "desktop", fa: "fa fa-desktop flex", ma: [] };
    moa["f02"] = { fid: "f02", name: "Belägg", url: "/pms/bel", icon: "bars", fa: "fa fa-bars flex", ma: [] };
    moa["f03"] = { fid: "f03", name: "Frontdesk", url: "/pms/op", icon: "users", fa: "fa fa-users flex", ma: [] };
    moa["f05"] = { fid: "f05", name: "Ekonomi", url: "/pms/eko", icon: "books", fa: "fa fa-books flex", ma: [] };
    moa["f06"] = { fid: "f06", name: "Rapporter", url: "/pms/rp", icon: "list-alt", fa: "fa fa-list-alt flex", ma: [] };
    moa["f07"] = { fid: "f07", name: "Register", url: "/pms/reg", icon: "list", fa: "fa fa-list flex", ma: [] };
    moa["f08"] = { fid: "f08", name: "Funktioner", url: "/pms/fkn", icon: "wrench", fa: "fa fa-wrench flex", ma: [] };
    moa["f09"] = { fid: "f09", name: "Backoffice", url: "/pms/bo", icon: "cogs", fa: "fa fa-cogs flex", ma: [] };
    
    moa["f00"].ma.push( { fid: "f00", mid: "m01", name: "Hem", url: "/pms", fa: "fa fa-bars" } );
    moa["f00"].ma.push( { fid: "f00", mid: "m02", name: "Kalender", url: "/pms/ov", fa: "fa fa-bars" } );

    //moa["f01"].ma.push( { fid: "m01", name: "Ny Bokning", url: "/pms/fo/rvn", fa: "fa fa-bars" } );
    moa["f01"].ma.push( { fid: "f01", mid: "m01", name: "Preliminära", url: "/pms/fo/rvp", fa: "fa fa-bars" } );
    moa["f01"].ma.push( { fid: "f01", name: "Senast bokat", url: "/pms/fo/rvl", fa: "fa fa-bars" } );
    moa["f01"].ma.push( { fid: "f01", name: "Aktivitetsskärm", url: "/pms/fo/acs", fa: "fa fa-bars" } );
    moa["f01"].ma.push( { fid: "f01", name: "Sök", url: "/pms/fo/sz", fa: "fa fa-bars" } );
    moa["f01"].ma.push( { fid: "f01", name: "Profiler", url: "/pms/fo/profil", fa: "fa fa-bars" } );
    moa["f01"].ma.push( { fid: "f01", name: "Kunder", url: "/pms/fo/kreg", fa: "fa fa-bars" } );
    moa["f01"].ma.push( { fid: "f01", name: "Mallar", url: "/pms/fo/tmpl", fa: "fa fa-bars" } );

    moa["f02"].ma.push( { fid: "f02", mid: "m01", name: "Rumstyp", url: "/pms/bel/rt", fa: "fa fa-bars" } );
    moa["f02"].ma.push( { fid: "f02", mid: "m02", name: "Rum", url: "/pms/bel/room", fa: "fa fa-bars" } );
    moa["f02"].ma.push( { fid: "f02", mid: "m03", name: "Konferens", url: "/pms/bel/kf", fa: "fa fa-bars" } );
    moa["f02"].ma.push( { fid: "f02", mid: "m04", name: "Resurs", url: "/pms/bel/rs", fa: "fa fa-bars" } );
    moa["f02"].ma.push( { fid: "f02", mid: "m05", name: "Paket", url: "/pms/bel/paket", fa: "fa fa-bars" } );
    moa["f02"].ma.push( { fid: "f02", mid: "m06", name: "Roomrack", url: "/pms/bel/rr", fa: "fa fa-bars" } );
    moa["f02"].ma.push( { fid: "f02", mid: "m07", name: "Ank / Bo / Avr", url: "/pms/bel/aba", fa: "fa fa-bars" } );
    moa["f02"].ma.push( { fid: "f02", mid: "m08", name: "Bokat från-till", url: "/pms/bel/bft", fa: "fa fa-bars" } );

    moa["f03"].ma.push( { fid: "f03", mid: "m01", name: "Ankommande", url: "/pms/op/ank", fa: "fa fa-bars" } );
    moa["f03"].ma.push( { fid: "f03", mid: "m02", name: "Checkin", url: "/pms/op/ci", fa: "fa fa-bars" } );
    moa["f03"].ma.push( { fid: "f03", mid: "m03", name: "Aktiva Gäster", url: "/pms/op/inh", fa: "fa fa-bars" } );
    moa["f03"].ma.push( { fid: "f03", mid: "m04", name: "Avresande", url: "/pms/op/avr", fa: "fa fa-bars" } );
    moa["f03"].ma.push( { fid: "f03", mid: "m05", name: "Delutcheckade", url: "/pms/op/pco", fa: "fa fa-bars" } );
    moa["f03"].ma.push( { fid: "f03", mid: "m06", name: "Utcheckade", url: "/pms/op/co", fa: "fa fa-bars" } );
    moa["f03"].ma.push( { fid: "f03", mid: "m07", name: "No Show", url: "/pms/op/noshow", fa: "fa fa-bars" } );

    moa["f05"].ma.push( { fid: "f05", mid: "m03", name: "Omsättning", url: "/pms/eko/rev", fa: "fa fa-list-alt" } );
    moa["f05"].ma.push( { fid: "f05", mid: "m03", name: "Konto", url: "/pms/eko/konto", fa: "fa fa-list-alt" } );
    moa["f05"].ma.push( { fid: "f05", mid: "m03", name: "Krubba", url: "/pms/eko/krub", fa: "fa fa-list-alt" } );
    moa["f05"].ma.push( { fid: "f05", mid: "m03", name: "Transaktioner", url: "/pms/eko/trs", fa: "fa fa-list-alt" } );
    moa["f05"].ma.push( { fid: "f05", mid: "m03", name: "Notor", url: "/pms/eko/invs", fa: "fa fa-list-alt" } );
    moa["f05"].ma.push( { fid: "f05", mid: "m03", name: "Bokningar", url: "/pms/eko/rvs", fa: "fa fa-list-alt" } );
    moa["f05"].ma.push( { fid: "f05", mid: "m03", name: "Saldo", url: "/pms/eko/saldo", fa: "fa fa-list-alt" } );

    moa["f06"].ma.push( { fid: "f06", mid: "m01", name: "Listor", url: "/pms/rp/list", fa: "fa fa-list-ul" } );
    moa["f06"].ma.push( { fid: "f06", mid: "m02", name: "Rapporter", url: "/pms/rp/rps", fa: "fa fa-suitcase" } );
    moa["f06"].ma.push( { fid: "f06", mid: "m03", name: "Bokningar", url: "/pms/rp/rvs", fa: "fa fa-list-alt" } );
    moa["f06"].ma.push( { fid: "f06", mid: "m03", name: "Transaktioner", url: "/pms/rp/trs", fa: "fa fa-list-alt" } );

    moa["f07"].ma.push( { fid: "f07", mid: "m01", name: "Presentkort", url: "/pms/reg/pres", fa: "fa fa-list-ul" } );
    moa["f07"].ma.push( { fid: "f07", mid: "m02", name: "Förskott", url: "/pms/reg/fsk", fa: "fa fa-suitcase" } );
/*
    moa["f05"].ma.push( { fid: "m01", name: "Kalender", url: "/pms/rst/cal", fa: "fa fa-list-ul" } );
    moa["f05"].ma.push( { fid: "m02", name: "Beläggning", url: "/pms/rst/bel", fa: "fa fa-suitcase" } );
    moa["f05"].ma.push( { fid: "m03", name: "Bokningar", url: "/pms/rst/rvs", fa: "fa fa-list-alt" } );
    moa["f05"].ma.push( { fid: "m04", name: "Incheckade", url: "/pms/rst/in", fa: "fa fa-line-chart" } );
    moa["f05"].ma.push( { fid: "m05", name: "Utcheckade", url: "/pms/rst/ut", fa: "fa fa-list-ul" } );
    moa["f05"].ma.push( { fid: "m06", name: "NoShow", url: "/pms/rst/nos", fa: "fa fa-suitcase" } );
    moa["f05"].ma.push( { fid: "m07", name: "Listor", url: "/pms/rst/lst", fa: "fa fa-list-alt" } );
    moa["f05"].ma.push( { fid: "m08", name: "Bordsvy", url: "/pms/rst/tvy", fa: "fa fa-line-chart" } );
*/
    moa["f08"].ma.push( { fid: "f08", mid: "m01", name: "Telefonlista", url: "/pms/fkn/tele", fa: "fa fa-list-ul" } );
    moa["f08"].ma.push( { fid: "f08", mid: "m02", name: "Maintenance", url: "/pms/fkn/maint", fa: "fa fa-suitcase" } );
    moa["f08"].ma.push( { fid: "f08", mid: "m03", name: "Lost and Found", url: "/pms/fkn/laf", fa: "fa fa-list-alt" } );
    moa["f08"].ma.push( { fid: "f08", mid: "m04", name: "Bevakningar", url: "/pms/fkn/bevak", fa: "fa fa-line-chart" } );
    moa["f08"].ma.push( { fid: "f08", mid: "m05", name: "Logg", url: "/pms/fkn/logg", fa: "fa fa-line-chart" } );

    moa["f09"].ma.push( { fid: "f09", mid: "m01", name: "Register", url: "/pms/hotreg", fa: "fa fa-bars" } );
    moa["f09"].ma.push( { fid: "f09", mid: "m01", name: "Redovisning", url: "/pms/hotback", fa: "fa fa-bars" } );
    moa["f09"].ma.push( { fid: "f09", mid: "m01", name: "Teknik", url: "/pms/tech", fa: "fa fa-bars" } );
    moa["f09"].ma.push( { fid: "f09", mid: "m01", name: "Dagsinformation", url: "/pms/bo/di", fa: "fa fa-bars" } );
/*
    moa["f07"].ma.push( { fid: "m01", name: "Resurser", url: "/pms/bo/rs", fa: "fa fa-bars" } );
    moa["f07"].ma.push( { fid: "m02", name: "Konferenstyper", url: "/pms/bo/kt", fa: "fa fa-bars" } );
    moa["f07"].ma.push( { fid: "m02", name: "Rumstyper", url: "/pms/bo/rt", fa: "fa fa-bars" } );
    moa["f07"].ma.push( { fid: "m03", name: "Priser och Betalsätt", url: "/pms/bo/ptp", fa: "fa fa-bars" } );
    moa["f07"].ma.push( { fid: "m04", name: "Allotment", url: "/pms/bo/alot", fa: "fa fa-bars" } );
    moa["f07"].ma.push( { fid: "m05", name: "Signaturer", url: "/pms/bo/sign", fa: "fa fa-bars" } );
    moa["f07"].ma.push( { fid: "m06", name: "Dagsinformation", url: "/pms/bo/di", fa: "fa fa-bars" } );
    moa["f07"].ma.push( { fid: "m07", name: "Systemdata", url: "/pms/bo/sys", fa: "fa fa-bars" } );
    moa["f07"].ma.push( { fid: "m08", name: "Portaler", url: "/pms/bo/port", fa: "fa fa-bars" } );
*/
    var aa = [];
    var keys = Object.keys(moa);
    for(var key of keys) {
        var o = moa[key];
        aa.push( o );
    }

    //document.removeEventListener("mousedown", this.handleClick);
    document.addEventListener("mousedown", handleClick);

    var ma = [];
    var mnuHeight = window.innerHeight - 100;

   function handleClick(e) {
    if (e.pageX > 320) {
        if (state.ma.length) {
            setState({mnu : state.mnu, row : state.row, ma : [] });
            return;
        }
    }
  }
   function fknGo(row) {
    //var sdo = useContext(SDO);
    //alert(row);
       sso.sdo.sfkn = row;
       sso.seo.sfkn = row;

       //sso.mnu.url = row.url;

       var fkn = row.fid;
    setState({ mnu : row.fid, row : row,  ma : [] });
    ctx.fid = fkn;
    props.store.fkn = row;
       navigate(row.url);
   };
   function fknFix(fkn) {
    //var sdo = useContext(SDO);
    //alert(fkn);
    ctx.fid = fkn;

    if(fkn == "lock") navigate('/web');
    if(fkn == "logout") navigate('/');
   };
   function mnuGo(row) {
       //alert(JSON.stringify(row));
        sso.sdo.sfkn = row;
        //sso.mnu.url = row.url;
        setState({ mnu : state.hfid, row : state.row,  ma : [] });

       sso.seo.sfkn = row;
        props.store.fkn = row;
        navigate(row.url);
   };
   function hideMnu() {
    setState({mnu : state.mnu, row : state.row, ma : [] });
   };
   function mouseEnter(it) {
    setState({mnu : state.mnu, row : state.row, hfid : it.fid, htitle : it.name, ma : it.ma });
   };
   function mouseLeave() {
    setState({mnu : state.mnu, row : state.row, ma : [] });
   };

   function row_css(row) {
  	    var css = {};
        css.margin = "0px";
        css.padding = "0px";
        css.height = "55px";
        css.borderBottom = "1px #ccc solid";

        if(props.store.fkn && (props.store.fkn != undefined))
        {
            if (props.store.fkn.fid == row.fid) {
               css.background = "#080";
               css.color = "#fff";
            }
        }
		return css;
    }
   function row_icon_css(row) {
  	    var css = {};
        css.paddingTop = "10px";
        css.fontSize = "20px";

        if(props.store.fkn && (props.store.fkn != undefined))
        {
            if (props.store.fkn.fid == row.fid) {
               //css.background = "#080";
               css.color = "#fff";
            }
        }
		return css;
    }

   //<Icon className={ it.fa } style={{ fontSize: 18 }} />
   var html =
        <div className="">
        <div className="cdi_xmnu flex-fullcol" style={{padding: "5px 0px"}}>
            { aa.map((it, key) =>
            <div key={ key } onClick={() => fknGo(it)} className="web-col flex-cc xmnu_tab" style={ row_css(it) } onMouseEnter={ () => mouseEnter(it) }>
                <FontAwesomeIcon icon={ it.icon } size="1x" className="" style={ row_icon_css(it) } />
                <div style={{fontSize: "10px"}}>{ it.name }</div>
            </div>
            )}

        </div>

        { state.ma.length ?
        <div className="mtrl_mnu_sub mtrl_mnu_bg" aria-label="Sub Menu" style={{height: mnuHeight}} onMouseLeave={mouseLeave}>
          <div className="mtrl_mnu_title" onClick={() => hideMnu()}>
            { state.htitle }
          </div>
            { state.ma.map((mi, key) =>
          <div key={key} className="mtrl_mnu_item flex-row" onClick={ () => mnuGo(mi, key) }>
            <div className="flex">{ mi.name }</div>
            <i className={ mi.fa } aria-hidden="true" style={ row_icon_css(mi) }></i>
          </div>
            )}
        </div>
        : null }
        </div>
    ;
    return html;
}

export default observer(Pms_Mnu);

/*
            <div onClick={ () => fknFix("lock") } className="web-col flex-cc xmnu_tab">
                <FontAwesomeIcon icon="lock" size="1x" className="" style={{paddingTop: "10px", fontSize: "20px"}} />
                <div style={{fontSize: "10px"}}>Lås</div>
            </div>
            <div onClick={() => fknFix("logout") } className="web-col flex-cc xmnu_tab" >
                <i className="fa fa-power-off flex" aria-hidden="true" style={{paddingTop: "10px", fontSize: "20px"}}></i>
                <div style={{fontSize: "10px"}}>Logga ur</div>
            </div>

*/