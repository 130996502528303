import React, { useState, useEffect, useContext } from 'react';
import * as net from "../../../lib/net";

import {format} from "date-fns";

class Rr_Clean extends React.Component {
  constructor(props, context) {
    super(props);

   //this.webStore = props.store;
    this.state = {};
    this.state.ynq = [];
    this.state.paa = [];
    this.state.aa = [];

    this.state.rro = {};
    this.state.wnds = {};
    if(props.wnds) {
        this.state.rro = props.wnds.rro;
    }
    this.state.today = new Date();
    var hh = format(this.state.today, "hh");
    if(hh < 5) this.state.today.setDate(this.state.today.getDate() - 1);
    this.state.datum = format(this.state.today, "yyyyMMdd");
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }


    wnd_close() {
        var self = this;
        var ret = {};
        ret.ok = "999";
        if(self.props.close) self.props.close(self.props.ctx, ret);
        else self.props.ctx.setState({ wnds: {} });
	};
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }

  rr_clean(status) {
    var self = this;

    var prm = {};
    prm.req = "pms.rr.rr_clean";
    prm.datum = this.state.datum;
    prm.gsb01 = this.state.rro.gsb01;
    prm.status = status;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));
        if(ret.ok == "000") {
            if(self.props.close) self.props.close(self.props.ctx, ret);
		    else self.props.ctx.setState({ wnds: {} });
        }
    })
    .catch(function(e) {
        var txt = JSON.stringify(e);
    });
  }

  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "";

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="mx_backdrop" /> : null }
        <div className="m2_dlg mx_50 flex-col" style={ this.dlg_style() }>
            <div className="mx_h01 flex-row" style={{background: "#1a456b", color: "#fff"}}>
                    <div className="flex-col flex">
                        <div>STÄDA / OSTÄDA RUM</div>
                    </div>
                    <div className="flex-col">
                        <div>RUMSNR: { this.state.rro.rumsnr }</div>
                    </div>
            </div>
        <div className="mx_content flex">

            <div className="flex"></div>
            <div className="divider_orange"></div>
        <div className="web-row">
        <div className="web-col flex">
            <div className="wnd-btn" onClick={() => this.wnd_close() } >
                <div>STÄNG</div>
                <div></div>
            </div>
        </div>
         <div className="web-col flex" style={{padding: "0px 10px"}}>
            <div className="wnd-btn" onClick={() => this.rr_clean("0") } >
                <div>OSTÄDA</div>
                <div></div>
            </div>
       </div>
         <div className="web-col flex" style={{padding: "0px 10px"}}>
            <div className="wnd-btn" onClick={() => this.rr_clean("1") } >
                <div>STÄDA</div>
                <div></div>
            </div>
       </div>
       </div>

        </div>
      </div>
    </div>
	;
	return html;
  };
}

export default Rr_Clean;