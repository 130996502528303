import React, { useState, useEffect, useRef, useContext } from 'react';
import { parseISO, format, getDate, getMonth, getWeek, eachDayOfInterval, eachWeekOfInterval } from 'date-fns'
import { setMonth, setYear, startOfWeek, endOfWeek, startOfMonth, endOfMonth, addMonths, subMonths, addDays, subDays, isSameDay } from 'date-fns'
import { sv } from 'date-fns/locale'

import './css/edit_txt.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Sz_Txt extends React.Component {
  constructor(props, context) {
    super(props);

    this.node = React.createRef();
    //this.setNodeRef = this.setNodeRef.bind(this);

    this.state = {};
    this.state.itemx = [];
    this.state.lso = {};
    //this.state.lro = props.lro;
    this.state.tya = [
                {id: '01', text: 'Summerad'},
                {id: '02', text: 'Dag för dag'}
            ];
    this.state.itema = [
                {id: '01', text: 'VAL 01'},
                {id: '02', text: 'VAL 02'},
                {id: '03', text: 'VAL 03'}
            ];
    this.state.items = [];
    this.state.cols = {};
    this.state.sel_show = false;
    this.state.sel_step = 0;

    //context.posrend = this;
    this.state.today = new Date();
    this.state.ctrltext = props.text;
    this.state.txtval = props.text;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick);
  }
  componentDidMount() {
    //this.props.fetchWells()
    //  .then(res => this.setState({ wells: res.wells }) )
    //alert(JSON.stringify(this.props));
    document.addEventListener("mousedown", this.handleClick);
    //this.set_month(this.state.dat);
    if(this.props.items) {
      this.setState({ items: this.props.items })
    }
  }

  handleClick = (e) => {
    if(!this.state.sel_show) return;

    if (!this.node.current.contains(e.target)) {
      this.setState({sel_show: false});
      return;
    }
  }


    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    tab_style(tabid) {
      var css = {};
      css.display = "none";
      if(this.state.tabval === tabid) css.display = "block";
      return css;
    }
    tab_head(tabid) {
      var css = {};
      css.background = "#fff";
      css.color = "#000";
      if(this.state.tabval === tabid) {
          css.background = "#080";
          css.color = "#fff";
      }
      return css;
    }

    rp_prn() {
		//this.props.ctx.setState({ mr: false });
        //this.setState({ pdf: 1 });
        //this.dr_prn();
    }

    showsel(e) {
      e.preventDefault();

      //alert("X: " + JSON.stringify(this.node.current.getBoundingClientRect()) );
      //alert("XX: " + this.node.current.offsetX);

      var brc = this.state.sel_show;
      if(brc) return;

      brc = !brc;
      var val = this.props.text;
      this.setState({sel_show: brc, sel_step: 0, txtval: val});
    }
    selStep(e) {
      e.preventDefault();

      //this.setState({sel_step: 0});
      var brc = this.state.sel_show;
      brc = !brc;
      this.setState({sel_show: brc, sel_step: 0});
    }
    setVal() {

      var val = this.state.txtval;
      this.setState({sel_show: false, sel_step: 0, ctrltext: val, txtval: val});
      this.props.getValue(val);
    }

    keydown (e) {
        e.preventDefault();
      const keyCode = e.keyCode;
      const key = e.key;
      const value = e.target.value;
      //alert(JSON.stringify(key));
      var dts = this.state.dts + key;
      this.setState({dts: dts});
      return;

      var dat = new Date(2020, key, 15);

      var dts = format(dat, "yyyy-MM-dd");
      this.setState({dat: dat, dts: dts});
    }

    changeHandler (e) {
      e.preventDefault();
      const value = e.target.value;
      //alert("CHANGE: " + JSON.stringify(value));
      this.setState({ txtval: value });
    }
    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    handleClose(e) {
      //alert(JSON.stringify(this.state.dat));
      var dts = format(this.state.dat, "yyyy-MM-dd");
      //this.props.getValue(this.state.dat);
      //alert(JSON.stringify(dts));
      this.props.getValue(dts);
      this.showcal(e);
	};

  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  inputStyle() {
      var css = {};
      if(this.state.cal_show) {
        css.background = "#080";
        css.color = "#fff";
      }
      return css;
  }
  syncCols (tb) {

    if (!document.getElementById(tb)) return;

    var cols = {};
    var ix = document.getElementById(tb).rows.length - 1;
    var ic = document.getElementById(tb).rows[0].cells.length;
    var ci = 0;
    while(ci < ic) {
        var cxi = ci + 1;
        var col = "c" + cxi.toString().padStart(2, "0");
        cols[col] = document.getElementById(tb).rows[ix].cells[ci].clientWidth + 1;
        ci = ci + 1;
    }
    this.setState({cols: cols });
    //alert(JSON.stringify(this.state.cols));
    //this.forceUpdate();
  };
  sel_style() {
    var css = {};

    if(!this.node.current) return css;

    var max_w = window.innerWidth;
    var pos_w = this.node.current.getBoundingClientRect().left;
    var pos_t = this.node.current.getBoundingClientRect().top;
    var out = false;

    if(max_w < (pos_w + 400)) out = true;;
    if(out) css.right = "5px";
    css.top = (pos_t - 50) + "px";

    return css;
  }

  render() {
    //if(!this.props.show) return "";

    //alert(this.props.ctx.state.lro);

    var title = this.props.title;
    if(!title) title = "URVAL";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div ref={this.node} className="sz_val" onClick={(e) => this.showsel(e) }>
          <div className="sz_top" >
          <FontAwesomeIcon icon="search" size="2x" style={{ color: "#ccc"}}/>
          <span className="edit_text">{ this.props.text }</span>
          </div>
       { this.state.sel_show ?
        <div className="edit_sel flex flex-col" style={ this.sel_style() }>
        <div className="edit_content flex">
            <div className="flex-row">
                    <div className="edit_head">SÖK TEXT</div>
            </div>

            <div className="flex-row">
              <input value={this.state.txtval} onChange={(e) => this.changeHandler(e)}
                     type="text" className="form-control"
                     placeholder="" required
              />
            </div>

        </div>

        <div className="edit_foot flex-row flex-space-between">
            <div className="foot_btn" onClick={(e) => this.selStep(e) }>
                AVBRYT
            </div>
            <div className="flex-row"></div>
            <div className="foot_btn" onClick={(e) => this.setVal(e) }>
                SÖK
            </div>
        </div>

        </div>
        : null }

        </div>
	;
	return html;
  };
}

export default Sz_Txt;