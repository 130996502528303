import React, { useState, useContext } from 'react';

import { observer } from 'mobx-react';
import { useStore } from '../../sdo.js'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function Std_Menu(props) {
    var row = {};
    //if(props.store.fkn) row = props.store.fkn;
    let [state, setState] = useState({row: row, mnu: "", htitle: "", ma: []});
    let ctx = useStore();


    var lock = "lock";
    var moa = {};
    moa["f00"] = { fid: "f00", name: "Hem", url: "/pos/ccs", icon: "home", fa: "fa fa-home flex", ma: [] };
    moa["f01"] = { fid: "f01", name: "Status", url: "/pos/ccs/pt", icon: "desktop", fa: "fa fa-key flex", ma: [] };
    moa["f02"] = { fid: "f02", name: "Period", url: "/pos/ccs/oms", icon: "list-alt", fa: "fa fa-key flex", ma: [] };
    moa["f04"] = { fid: "f04", name: "Rapporter", url: "/pos/ccs/rps", icon: "print", fa: "fa fa-key flex", ma: [] };
    moa["f06"] = { fid: "f06", name: "Enheter", url: "/pos/ccs/units", icon: "cogs", fa: "fa fa-cogs flex", ma: [] };

    moa["f06"].ma.push( { fid: "f06", name: "Kontodefinition", url: "/pos/ccs/konto", fa: "fa fa-bars" } );

    var aa = [];
    var keys = Object.keys(moa);
    for(var key of keys) {
        var o = moa[key];
        aa.push( o );
    }

    //document.removeEventListener("mousedown", this.handleClick);
    document.addEventListener("mousedown", handleClick);

    var ma = [];
    var mnuHeight = window.innerHeight - 100;

   function handleClick(e) {
    if (e.pageX > 320) {
        if (state.ma.length) {
            setState({mnu : state.mnu, row : state.row, ma : [] });
            return;
        }
    }
  }
   function fknGo(row) {
    //var sdo = useContext(SDO);
    var fkn = row.fid;
    setState({ mnu : row.fid, row : row,  ma : [] });
    ctx.fid = fkn;

    props.store.fkn = row;
       this.props.navigate(row.url);
   };
   function fknFix(fkn) {
    //var sdo = useContext(SDO);
    //alert(fkn);
    ctx.fid = fkn;

    if(fkn == "lock") this.props.navigate('/web');
    if(fkn == "logout") this.props.navigate('/');
   };
   function mnuGo(row) {
    //var sdo = useContext(SDO);
    //alert(fkn);
    //ctx.fid = fkn;
    setState({ mnu : state.hfid, row : state.row,  ma : [] });

    props.store.fkn = row;
       this.props.navigate(row.url);
   };
   function hideMnu() {
    setState({mnu : state.mnu, row : state.row, ma : [] });
   };
   function mouseEnter(it) {
    setState({mnu : state.mnu, row : state.row, hfid : it.fid, htitle : it.name, ma : it.ma });
   };
   function mouseLeave() {
    setState({mnu : state.mnu, row : state.row, ma : [] });
   };

   function row_css(row) {
  	    var css = {};
        css.margin = "0px";
        css.padding = "0px";
        css.height = "55px";
        css.borderBottom = "1px #ccc solid";

        if(state && (state != undefined))
        {
            if (state.mnu == row.fid) {
               css.background = "#080";
               css.color = "#fff";
            }
        }
		return css;
    }
   function row_icon_css(row) {
  	    var css = {};
        css.paddingTop = "10px";
        css.fontSize = "20px";

        if(state && (state != undefined))
        {
            if (state.mnu == row.fid) {
               //css.background = "#080";
               css.color = "#fff";
            }
        }
		return css;
    }

   //<Icon className={ it.fa } style={{ fontSize: 18 }} />
   var html =
        <div className="">
        <div className="cdi_xmnu flex-fullcol" style={{padding: "5px 0px"}}>
            { aa.map((it, key) =>
            <div key={ key } onClick={() => fknGo(it)} className="web-col flex-cc xmnu_tab" style={ row_css(it) } onMouseEnter={ () => mouseEnter(it) }>
                <FontAwesomeIcon icon={ it.icon } size="1x" className="flex" style={ row_icon_css(it) } />
                <div style={{fontSize: "10px"}}>{ it.name }</div>
            </div>
            )}
            <div onClick={ () => fknFix("lock") } className="web-col flex-cc xmnu_tab">
                <FontAwesomeIcon icon="lock" size="1x" className="flex" style={{paddingTop: "10px", fontSize: "20px"}} />
                <div style={{fontSize: "10px"}}>Lås</div>
            </div>
            <div onClick={() => fknFix("logout") } className="web-col flex-cc xmnu_tab" >
                <i className="fa fa-power-off flex" aria-hidden="true" style={{paddingTop: "10px", fontSize: "20px"}}></i>
                <div style={{fontSize: "10px"}}>Logga ur</div>
            </div>
        </div>

            { state.ma.length ?
        <div className="mtrl_mnu_sub mtrl_mnu_bg" aria-label="Sub Menu" style={{height: mnuHeight}} onMouseLeave={mouseLeave}>
          <div className="mtrl_mnu_title" onClick={() => hideMnu()}>
            { state.htitle }
          </div>
            { state.ma.map((mi, key) =>
          <div key={key} className="mtrl_mnu_item flex-row" onClick={ () => mnuGo(mi) }>
            <div className="flex">{ mi.name }</div>
            <i className={ mi.fa } aria-hidden="true" style={ row_icon_css(mi) }></i>
          </div>
            )}
        </div>
                : null }
        </div>
    ;
    return html;
}

export default observer(Std_Menu);
