import React, { useState, useEffect, useContext } from 'react';
import {format, startOfMonth, subDays} from "date-fns";
import {Chart} from "chart.js";

import * as net from "../../lib/net";
import Dt_Cal from "../../lib/ui/dt_cal";
import Tid_FT from "../../lib/ui/tid_ft";
import Fr_Select from "../../lib/ui/fr_select";
import sso from "../../sso";
import {observer} from "mobx-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Csc_Sts extends React.Component {
  constructor(props, context) {
    super(props);

    this.dt_fd = React.createRef();
    this.dt_td = React.createRef();

    this.my_bar = null;
    this.store = props.store;
    this.pos = props.store.pos;

    this.state = { pgid: 11, tra: [] };

    this.state.avd = "01";
    this.state.saa = [];
    this.state.sta = [];

    this.state.csa = [
        { id: 'tgif01', text: 'KUNGSAN', sid: 'S0003101' },
        { id: 'tgif02', text: 'TÄBY', sid: 'S0003102' },
        { id: 'tgif03', text: 'MALMÖ', sid: 'S0003103' },
        { id: 'tgif04', text: 'GAMLASTAN', sid: 'S0003104' },
        { id: 'tgif05', text: 'UPPSALA', sid: 'S0003105' },
    ];
    this.state.vga = [
        { id: '000', text: 'ALLA VARUGRUPPER', vgrp: '000' },
        { id: '001', text: 'MAT', vgrp: '001', konto: '3110', mproc: '1071' },
        { id: '005', text: 'DIPPSÅSER', vgrp: '005', konto: '3110', mproc: '1071' },
        { id: '010', text: 'VARMDRYCK', vgrp: '010', konto: '3119', mproc: '1071' },
        { id: '020', text: 'ALKOHOLFRIDRYCK', vgrp: '020', konto: '3113', mproc: '1071' },
        { id: '030', text: 'CIDER', vgrp: '030', konto: '3013', mproc: '2000' },
        { id: '040', text: 'STARKÖL', vgrp: '040', konto: '3016', mproc: '2000' },
        { id: '050', text: 'VIN', vgrp: '050', konto: '3017', mproc: '2000' },
        { id: '060', text: 'SPRIT', vgrp: '060', konto: '3015', mproc: '2000' },
        { id: '070', text: 'GARDEROB', vgrp: '070', konto: '3470', mproc: '2000' },
        { id: '071', text: 'ENTRE', vgrp: '071', konto: '3441', mproc: '2000' },
        { id: '072', text: 'SPEL', vgrp: '072', konto: '3422', mproc: '0000' },
        { id: '073', text: 'TOBAK', vgrp: '073', konto: '3430', mproc: '2000' },
        { id: '074', text: 'LOKAL', vgrp: '074', konto: '3410', mproc: '2000' },
        { id: '075', text: 'FEST', vgrp: '075', konto: '3460', mproc: '2000' },
        { id: '080', text: 'BUTIK', vgrp: '080', konto: '3000', mproc: '2000' },
        { id: '090', text: 'ÖVRIGT', vgrp: '090', konto: '3000', mproc: '2000' }
    ];
    this.state.typ = "0";

    this.state.sid = "";
    this.state.vgrp = "000";
    this.state.sortid = "";
    this.state.sz_typ = "0";

    this.state.today = new Date();
    //var yesterday = subDays(this.state.today, 1);
    //this.state.fdat = new Date(2021, 1, 1);
    //this.state.tdat = new Date(2021, 1, 15);
    //this.state.fdat = startOfMonth(yesterday, 1);
    //this.state.tdat = yesterday
    //this.state.tdat = subDays(this.state.today, 1);
    //this.state.tdat = new Date();
    //this.state.tdat.setDate(this.state.tdat.getDate() - 1);
    this.state.fdat = format(startOfMonth(subDays(this.state.today, 1), 1), "yyyy-MM-dd");
    this.state.tdat = format(subDays(this.state.today, 1), "yyyy-MM-dd");
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.st_art();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 175;
      return css;
  }
  th_style(id) {
      var css = {};
      css.cursor = "pointer";
      if(id == this.state.sortid) {
          css.color = "#080";
        if("1" == this.state.sz_typ) css.color = "#800";
      }
      return css;
  }

  dt_fdat(e) {
    //alert(JSON.stringify(e));
    var dts = format(e, "yyyy-MM-dd");
    this.setState({ fdat: dts });
  }
  dt_tdat(e) {
      //alert(JSON.stringify(e));
    var dts = format(e, "yyyy-MM-dd");
    this.setState({ tdat: dts });
  }
  oneday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_td.current.updateDt(this.state.fdat);
  }
  addday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_fd.current.updateAdd();
    this.dt_td.current.updateAdd();
  }
  subday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_fd.current.updateSub();
    this.dt_td.current.updateSub();
  }

  onUnitChange(e) {
        this.setState({unit: e.value});
  }

    st_art() {
        var self = this;

        var sdo = {};
        sdo.eko = {};
        sdo.plus = {};
        sdo.vgrp = this.state.vgrp;

        //var csa = self.get_csa();
        var csa = sso.sdo.csa;

        function send_req(cso) {
            const promise1 = new Promise((resolve, reject) => {
                //setTimeout(resolve, 500, 'one');
                var prm = {};
                prm.sid = cso.c0sid;
                prm.req = "pos.pos_eko.trh_stat_sum";
                prm.avd = "01";
                prm.fdat = self.state.fdat;
                prm.tdat = self.state.tdat;
                prm.fvgrp = "001"
                prm.tvgrp = "999";
                //alert(JSON.stringify(prm));

                net.sid_req(prm)
                    .then(function(ret){
                        //alert(JSON.stringify(ret));
                        //alert(JSON.stringify(ret));
                        //sdo.eko[sid] = ret.rco;
                        sdo.eko[cso.c0sid] = {};
                        sdo.eko[cso.c0sid].text = cso.c0text;
                        sdo.eko[cso.c0sid].unit = cso.unit;
                        sdo.eko[cso.c0sid].plus = ret.rco.plus;
                        resolve();
                    })
                    .catch(function(e){
                        var txt = JSON.stringify(e);
                    });
            });
            return promise1;
        }
        var qa = [];
        for(var so of csa) {
            var promise = send_req(so);
            qa.push(promise);
        }
        Promise.all(qa)
            .then(function(ret){
                //alert(JSON.stringify(ret));
                //self.setState({sdo: sdo, eka: Object.values(sdo.eko) });
                var eka = Object.values(sdo.eko);
                for(so of eka) {
                    var paa = Object.values(so.plus);
                    for(var row of paa) {
                        var plunr = row.plunr;
                        if(sdo.vgrp > "000") {
                            if(sdo.vgrp != row.vgrp) continue;
                        }
                        if( !sdo.plus[plunr] ) {
                            var text = row.text.trim();
                            if(text.endsWith("KORR")) text = text.substring(0, text.length - 4);
                            sdo.plus[plunr] = {};
                            sdo.plus[plunr] = {};
                            sdo.plus[plunr].plunr = row.plunr;
                            sdo.plus[plunr].vgrp = row.vgrp;
                            sdo.plus[plunr].text = text;
                            sdo.plus[plunr].cs01 = 0;
                            sdo.plus[plunr].cs02 = 0;
                            sdo.plus[plunr].cs03 = 0;
                            sdo.plus[plunr].cs04 = 0;
                            sdo.plus[plunr].cs05 = 0;
                            sdo.plus[plunr].antal = 0;
                            sdo.plus[plunr].belopp = 0;
                            sdo.plus[plunr].brutto = 0;
                            sdo.plus[plunr].moms = 0;
                            sdo.plus[plunr].netto = 0;
                            sdo.plus[plunr].snitt = 0;
                        }
                        if(so.unit == "tgif01") sdo.plus[plunr].cs01 = sdo.plus[plunr].cs01 + row.antal;
                        if(so.unit == "tgif02") sdo.plus[plunr].cs02 = sdo.plus[plunr].cs02 + row.antal;
                        if(so.unit == "tgif03") sdo.plus[plunr].cs03 = sdo.plus[plunr].cs03 + row.antal;
                        if(so.unit == "tgif04") sdo.plus[plunr].cs04 = sdo.plus[plunr].cs04 + row.antal;
                        if(so.unit == "tgif05") sdo.plus[plunr].cs05 = sdo.plus[plunr].cs05 + row.antal;
                        sdo.plus[plunr].antal = sdo.plus[plunr].antal + row.antal;
                        sdo.plus[plunr].belopp = sdo.plus[plunr].belopp + row.belopp;
                        sdo.plus[plunr].brutto = sdo.plus[plunr].brutto + row.brutto;
                        sdo.plus[plunr].moms = sdo.plus[plunr].moms + row.moms;
                        sdo.plus[plunr].netto = sdo.plus[plunr].netto + row.netto;
                    }
                }
                var sa = Object.values(sdo.plus);
                //alert(JSON.stringify(sa.length));
                self.setState({ saa: sa, sta: sa }, () => {
                    self.syncTb("statTable");
                });
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
  st_artx() {
    var self = this;
    //var fdat = format(this.state.fdat, "yyyy-MM-dd");
    //var tdat = format(this.state.tdat, "yyyy-MM-dd");

    var prm = {};
    prm.req = "bi.bi_stat.cs_plu";
    //prm.req = "pos.pos_eko.trh_stat_sum";
    prm.csid = this.store.seo.csid;
    prm.avd = this.state.avd;
    prm.fdat = this.state.fdat;
    prm.tdat = this.state.tdat;
    prm.vgrp = this.state.vgrp;
    //alert(JSON.stringify(prm));

    net.ccs_req(prm)
    .then(function(ret){
        //var keys = Object.keys(ret.rco);
        //alert(JSON.stringify(keys));

        var sa = Object.values(ret.plus);
        //alert(JSON.stringify(sa.length));
        self.setState({ saa: sa, sta: sa }, () => {
         self.syncTb("stsTable");
       });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

    row_proc(row) {
      var proc = 0;
      if(this.state.sdo.tot.fsg > 0) {
          proc = (row.fsg / this.state.sdo.tot.fsg) * 100;
          proc = Math.round(proc);
      }
      return proc + " %";
    }
    row_wday(row) {
      var day = "";
      if(row.wday == 1) day = "Måndag";
      if(row.wday == 2) day = "Tisdag";
      if(row.wday == 3) day = "Onsdag";
      if(row.wday == 4) day = "Torsdag";
      if(row.wday == 5) day = "Fredag";
      if(row.wday == 6) day = "Lördag";
      if(row.wday == 7) day = "Söndag";
      return day;
    }
    bar_chart() {
      var lbls = [];
      var da = {};
      da.ink = [];
      da.ex = [];

      for(var row of this.state.eka) {
          if(row.grp == "tot") continue;
          lbls.push( row.text.toswe() );
          da.ink.push( row.belopp.cdipris() );
      }

        if(this.my_bar) this.my_bar.destroy();
        this.my_bar =  new Chart(document.getElementById("barchart"), {
            type: 'bar',
            data: {
				labels: lbls,
                datasets: [{
                    data: da.ink,
                    label: 'Belopp',
                    backgroundColor: "#4755AB",
                    borderWidth: 1,
                }]
            },
            options: {
                responsive: true,
                legend: {
                    display: false,
                    position: 'top'
                },
            }
        });
	};
    syncTb(tb){
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
        tdElements = tbel.getElementsByTagName('td');
        if(tdElements.length < 1 ) return;

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
            width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
  change_vgrp(e) {
    var self = this;
    //let cdat = this.state.seldate;
    //let vgrp = e.target.value;
    let vgrp = e;
    this.setState({vgrp: vgrp}, function(){
      self.vgrp_filtr();
    });
  }
  vgrp_filtr() {
      var self = this;
      var saa = self.state.saa;
      var sta = self.state.sta;
      var vgrp = this.state.vgrp;

      //alert(JSON.stringify( vgrp ));
      if(vgrp == "000") {
        self.setState({sta: saa });
        return;
      }

      var sta = saa.filter(row => row.vgrp == vgrp);
      self.setState({ sta: sta });
   }

   tb_sort(typ) {
      var self = this;
      //var rvs = self.state.rvs;
      var sta = self.state.sta;
      var sortid = this.state.sortid;
      var sz = this.state.sz_val;
      var sztyp = this.state.sz_typ;

      if(sortid == typ) {
          if(sztyp == "1") sztyp = "0";
          else sztyp = "1";
      }

      if(sztyp == "1") sta.sort((a,b) => (a[typ] < b[typ]) ? 1 : ((b[typ] < a[typ]) ? -1 : 0));
      else sta.sort((a,b) => (a[typ] > b[typ]) ? 1 : ((b[typ] > a[typ]) ? -1 : 0));

      self.setState({ sta: sta, sortid: typ, sz_typ: sztyp });
   }

  render() {
    return (
    <div className="web-p00 flex flex-col">

    <div className="web-p01">
    <div className="flex-row">
        <div className="flex-row">
              <div className="dt-btn-01" onClick={() => this.subday() } ><FontAwesomeIcon icon="minus" size="1x" /></div>
              <Dt_Cal dts={this.state.fdat} rp="1" ref={this.dt_fd} getValue={(e)=> this.dt_fdat(e)} />
              <div className="dt-btn-01" onClick={() => this.oneday() } >1</div>
              <Dt_Cal dts={this.state.tdat} rp="1" ref={this.dt_td} getValue={(e)=> this.dt_tdat(e)} />
              <div className="dt-btn-01" onClick={() => this.addday() } ><FontAwesomeIcon icon="plus" size="1x" /></div>
        </div>
        <Fr_Select label="" className="text-left w250" text={this.state.vgrp} items={this.state.vga} getValue={(e)=> this.change_vgrp(e) } />
        <div className="flex"></div>

        <button className="btn btn-hw-blue w150" onClick={() => this.st_art() } aria-label="System" >
            VISA
        </button>
    </div>
    <div className="divider_orange"></div>

    <div id="stsTable" className="flex">
        <table className="table table-bordered table-hover tb fixed_header">
            <thead>
            <tr>
                <th style={ this.th_style("vgrp") } onClick={() => this.tb_sort("vgrp") }>Varugrupp</th>
                <th style={ this.th_style("plunr") } onClick={() => this.tb_sort("plunr") }>Artikel</th>
                <th style={ this.th_style("text") } onClick={() => this.tb_sort("text") }>Text</th>
                <th style={ this.th_style("cs01") } onClick={() => this.tb_sort("cs01") }>Visby</th>
                <th style={ this.th_style("cs02") } onClick={() => this.tb_sort("cs02") }>Vasastan</th>
                <th style={ this.th_style("cs03") } onClick={() => this.tb_sort("cs03") }>Mariatorget</th>
                <th style={ this.th_style("cs04") } onClick={() => this.tb_sort("cs04") }>Göteborg</th>
                <th style={ this.th_style("cs05") } onClick={() => this.tb_sort("cs05") }>German</th>
                <th style={ this.th_style("antal") } onClick={() => this.tb_sort("antal") }>Summa</th>
                <th style={ this.th_style("netto") } onClick={() => this.tb_sort("netto") }>Netto</th>
            </tr>
            </thead>

            <tbody style={ this.tb_style() }>
            { this.state.sta.map((row, key) =>
            <tr key={ key } onDoubleClick={() => this.st_edit(row) }>
                <td>{ row.vgrp }</td>
                <td>{ row.plunr }</td>
                <td>{ row.text.toswe() }</td>
                <td className="text-center">{ row.cs01.ztrim() }</td>
                <td className="text-center">{ row.cs02.ztrim() }</td>
                <td className="text-center">{ row.cs03.ztrim() }</td>
                <td className="text-center">{ row.cs04.ztrim() }</td>
                <td className="text-center">{ row.cs05.ztrim() }</td>
                <td className="text-center">{ row.antal.ztrim() }</td>
                <td className="text-right">{ row.netto.cdipris() }</td>
            </tr>
            )}
            </tbody>
        </table>
    </div>

    </div>
    </div>
  );
    }
}
export default observer(Csc_Sts);
