import React, { useState, useEffect, useContext } from 'react';
import * as net from "../../../lib/net";
import Pms_Rvo from "../../rv/rvo";
import Dd_Sel from "../../../lib/ui/dd_sel";
import Td_Sel from "../../../lib/ui/td_sel";
import Fr_Input from "../../../lib/ui/fr_input";

class Discount extends React.Component {
  constructor(props, context) {
    super(props);

     this.selitems = [
        {fkn: 'setup', text: 'INSTÄLLNINGAR'},
        {fkn: 'upd', text: 'UPPDATERA'},
        {fkn: 'sys', text: 'SYSTEM'},
        {fkn: 'logout', text: 'LOGGA UT'}
    ];

    this.fkn_close = null;
    this.state = {};
    this.state.ynq = [];
    this.state.aa = [];
    this.state.selrow = {};
    this.state.srow = {};

    this.state.boknr = "";
    this.state.rvo = {};
    this.state.nta = [];
    if(props.wnds) {
        this.fkn_close = props.wnds.fkn_close;
        this.state.boknr = props.wnds.boknr;
        this.state.rvo = props.wnds.rvo;
        this.state.nta = props.wnds.nta;
        //this.state.srow = props.wnds.srow;
    }
    this.state.belopp = "0.00";
    this.state.belman = 0;
    this.state.proc = "10";
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      this.syncTb("rabTable");
      this.calc_rab();
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }

    wnd_close() {
      if(this.fkn_close) this.fkn_close(this.props.ctx);
      else this.props.ctx.setState({ wnds: {} });
	};
  tb_style() {
      var css = {};
      //css.height = window.innerHeight - 275;
      css.overflow = "auto";
      return css;
  }
  row_style(row) {
    var srow = this.state.srow;
    var css = {};
    if(srow[row.etr01.e0lopnr]) {
        css.background = "#080";
        css.color = "#fff";
    }
    return css;
  }
  clear() {
    this.setState({srow: {}, belopp: "0.00", belman:0});
  }
  set_all() {
    var srow = this.state.srow;
    var belopp = 0;
    for(var row of this.state.nta) {
        srow[row.etr01.e0lopnr] = row;
        belopp = belopp + (row.etr01.e0pris * 1);
    }
    belopp = belopp.toString().cdipris();
    this.setState({srow: srow, belopp: belopp, belman:0});
  }
  sel_row(row) {
    var srow = this.state.srow;
    var belopp = this.state.belopp;
    belopp = belopp.toString().replace(/[.]/g, '');
    belopp = belopp * 1;
    if(this.state.belman) belopp = 0;

    if(srow[row.etr01.e0lopnr]) {
      delete( srow[row.etr01.e0lopnr] );
        belopp = belopp - (row.etr01.e0pris * 1);
    }
    else {
        srow[row.etr01.e0lopnr] = row;
        belopp = belopp + (row.etr01.e0pris * 1);
    }
    belopp = belopp.toString().cdipris();
    this.setState({srow: srow, belopp: belopp, belman:0});
  }
  sz_rab(){
  }
  calc_rab() {
    var belopp = 0;
    for(var row of this.state.nta) {
        belopp = belopp + (row.etr01.e0pris * 1);
    }
    belopp = belopp.toString().cdipris();
    this.setState({belopp: belopp, belman:0});
  }
  add_rab(rabkod) {
    var self = this;
    var belopp = this.state.belopp;
    var proc = this.state.proc;
    belopp = belopp.toString().replace(/[.]/g, '');
    belopp = belopp * 1;
    var rabtext = "Rabatt";

    if(rabkod == "ra10") {
        rabkod = "ra10";
        proc = "10";
        rabtext = "Rabatt 10%";
    }
    if(rabkod == "ra20") {
        rabkod = "ra10";
        proc = "20";
        rabtext = "Rabatt 20%";
    }
    if(rabkod == "ra25") {
        rabkod = "ra10";
        proc = "25";
        rabtext = "Rabatt 25%";
    }
    if(rabkod == "ra50") {
        rabkod = "ra10";
        proc = "50";
        rabtext = "Rabatt 50%";
    }
    var rab = belopp * (proc / 100);
    rab = Math.round( rab );
    rab = rab.toString();

    //alert(JSON.stringify(rab));
    //return;

    var tro = {}
    tro.id = rabkod;
    tro.text = rabtext;
    tro.pris = rab;
    tro.moms = "";

    //alert(JSON.stringify(this.state.rvo.gsb00));
    //alert(JSON.stringify(this.state.gso));
    //return;
    var prm = {};
    prm.req = "pms.sell.charge_tr";
    prm.boknr = this.state.rvo.boknr;
    prm.gsb00 = this.state.rvo.gsb00;
    prm.trkod = tro.id;
    prm.text = tro.text;
    prm.antal = "1";
    prm.pris = tro.pris;
    prm.tkn = "+";
    prm.moms = tro.moms;
    prm.datum = this.state.dts;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rco));

        if(self.fkn_close) self.fkn_close(self.props.ctx);
        else self.props.ctx.setState({ wnds: {} });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
    syncTb(tb){
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
        tdElements = tbel.getElementsByTagName('td');
        if(tdElements.length < 1 ) return;

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
            width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
  val_date(val) {
      var ret = "";
      if(val) ret = val.toString().cdidate();
      return ret;
  }
  proc_set(val) {
      var proc = "";
      if(val) {
          proc = val.toString();
         this.setState({proc: proc});
     }
  }
  belopp_set(val) {
      var belopp = "";
      if(val) {
          belopp = val.toString();
          //belopp = belopp.cdipris();
         this.setState({belopp: belopp, belman:1});
     }
  }

  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "";

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="m2_backdrop" /> : null }
        <div className="m2_dlg mx_70h flex-col" style={ this.dlg_style() }>
        <div className="flex-row " style={{padding:"0px",height:"50px",lineHeight:"50px", background: "#1a456b", color: "#fff"}}>
            <div className="flex-row flex" style={{padding:"0px 5px", fontSize: "22px"}}>
                <div>Rabatter { this.state.rumsnr }</div>
            </div>
            <div className="wnd-close-btn" onClick={() => this.wnd_close() }>X</div>
        </div>
        <div className="mx_content flex">

        <div id="rabTable" className="flex">
          <table className="table table-bordered table-hover tb" style={{marginBottom: "0px"}}>
            <thead>
            <tr>
                <th>Datum</th>
                <th>T</th>
                <th>Typ</th>
                <th>Text</th>
                <th>Antal</th>
                <th>Pris</th>
                <th>Summa</th>
                <th>Tecken</th>
                <th>Löpnr</th>
                <th></th>
            </tr>
            </thead>
        </table>
        <div style={ this.tb_style() }>
          <table className="table table-bordered table-hover tb">
            <tbody>
            { this.state.nta.map((row, key) =>
            <tr key={key} style={ this.row_style(row) } onClick={ () => this.sel_row(row) } onDoubleClick={ () => this.row_sel(row) }>
                <td>{ this.val_date( row.etr01.e0datum ) }</td>
                <td>{ row.etr01.e0bastyp }</td>
                <td>{ row.etr01.e0typ }</td>
                <td>{ row.etr01.e0text.toswe() }</td>
                <td className="text-center">{ row.etr01.e0antal.ztrim() }</td>
                <td className="text-right">{ row.etr01.e0pris.cdipris() }</td>
                <td className="text-right">{ ((row.etr01.e0antal * 1) * (row.etr01.e0pris * 1)).cdipris() }</td>
                <td>{ row.etr01.e0tkn }</td>
                <td>{ row.etr01.e0lopnr.ztrim() }</td>
                <td>
                    <Td_Sel items={ this.rowitems } getValue={(e)=> this.sel_row_fkn(row, e)} />
                </td>
            </tr>
            )}
            </tbody>
        </table>
        </div>
        </div>

        <div className="flex-row px-2">
            <div className="web-link" onClick={() => this.clear() }>Rensa</div>
            <div className="flex"></div>
            <div className="web-link" onClick={() => this.set_all() }>Välj alla</div>
        </div>

        <div className="divider_orange"></div>

        <div className="web-col">
        <div className="web-row px-2">
            <div className="txt_16">BELOPP ATT RABATTERA: </div>
            <Fr_Input noflex className="text-right w150" text={this.state.belopp} getValue={(e)=> this.belopp_set(e) } />
            <div className="flex"></div>
            <div className="txt_16 hide">RABATT PROCENT: </div>
            <Fr_Input noflex className="text-center w100 hide" text={this.state.proc} getValue={(e)=> this.proc_set(e) } />
        </div>
        </div>

        <div className="divider_orange"></div>

        <div className="web-row">
        <div className="web-col flex">
            <div className="wnd-btn" onClick={() => this.sz_rab() } >
                FLER RABATTER
            </div>
            <div className="wnd-btn" onClick={() => this.wnd_close() } >
                STÄNG
            </div>
        </div>

        <div className="web-col flex" style={{padding: "0px 10px"}}>
            <div className="wnd-btn" onClick={() => this.add_rab("ra25") } >
                RABATT 25%
            </div>
            <div className="wnd-btn" onClick={() => this.add_rab("ra50") } >
                RABATT 50%
            </div>
        </div>
        <div className="web-col flex" style={{padding: "0px 10px"}}>
            <div className="wnd-btn" onClick={() => this.add_rab("ra20") } >
                RABATT 20%
            </div>
            <div className="wnd-btn" onClick={() => this.add_rab("ra10") } >
                RABATT 10%
            </div>
        </div>
        </div>

        </div>
      </div>
            { this.state.wnd_rvo ?
        <Pms_Rvo close={this.rvo_close} boknr={this.state.boknr} ctx={this} store={this.props.store} backdrop={true}/>
            : null }
    </div>
	;
	return html;
  };
}

export default Discount;