import React, { useState, useEffect, useContext } from 'react';
import ReactDOM from 'react-dom';
import { SDOContext } from '../sdo.js'

//import './css/style-liberty.css';

import * as net from "../lib/net";
import {withRouter} from "../lib/react/withRouter";
import Dd_Sel from "../lib/ui/dd_sel";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function web01() {

    var html =
    <div className="">
        Test
    </div>
    ;
    return html;
}

class Start extends React.Component {
    //var sdo = useContext(SDOContext); // Functional
    static contextType = SDOContext; // Class

    constructor(props, context) {
        super(props);

        this.s1ref = React.createRef();
        this.s2ref = React.createRef();

        this.webStore = props.store;
        this.seo = props.store.seo;

        this.state = { pgid: 11, stat: [] };
        this.state.open = false;
        this.state.pdfopen = false;
        this.state.xxopen = false;
        this.state.pdf_uri = "";
        this.state.seldate = new Date('2020-03-24T13:11:54');
        this.state.row = null;
        this.state.info = "";
        this.state.top = 1;
        this.state.user = {};
        this.state.sdo = {};
        this.state.msg = {};
        //this.state.sdo = context;
        //context.posrend = this;
        this.state.step = 1;
        this.state.pdf = 0;
        this.state.pdf_uri = "";
        this.state.mnus = ["m00", "m01", "m02", "m03", "m08", "m09"];
        this.state.mnu_sel = "m00";
        this.state.useritems = [
            {fkn: 'user_setup', text: 'INSTÄLLNINGAR'},
            {fkn: 'user_ses', text: 'MIN SESSION'},
            {fkn: 'logout', text: 'LOGGA UT'}
        ];

    }
    componentDidMount(){
        //var sdo = sessionStorage.getItem("svp");
        //if(sdo) this.setState({sdo: JSON.parse(sdo) });
        //this.cdi_step();
    }

    componentWillUnmount(){
            this.setState({info: "UUU" });
    }
    onScroll(event) {
        var body = document.getElementById("web-body");
        if (body.scrollTop > 150) {
          this.setState({top: 0})
          this.setState({info: 'small'})
        } else {
          this.setState({top: 1})
          this.setState({info: 'big:'+body.scrollTop})
        }

        if (true) {
            var offset = body.scrollTop;
            var start = body.scrollTop;
            var slut = body.scrollTop + window.innerHeight;

            for(var mnuid of this.state.mnus) {
                const element = document.getElementById(mnuid);
                if(!element || (element == null) || (element == undefined)) continue;
                var eltop = element.offsetTop;
                var elbot = element.offsetTop + element.offsetHeight;
                var brc = eltop >= start && eltop <= slut;
                //if(!brc) brc = elbot >= start && elbot <= slut;
                if(brc) {
                    this.setState({mnu_sel: mnuid});
                    return;
                }
            }
        }
    }
   wnd_css() {
  	    var css = {};
		if(this.state.msg.err) {
			css.color = "#fff";
			css.background = "#800";
		}

		return css;
    }
   header_css() {
  	    var css = {};
		if(this.state.top) {
			css.color = "#fff";
			css.background = "transparent";
			css.height = "50px";
			//css["background"] = "green";
		}
		else {
			css.color = "#000";
			css.background = "#fff";
		    css.height = "50px";
        }

		return css;
    }
   ham_mnu_css() {
  	    var css = {};
		if(this.state.top) {
			css.display = "flex";
			css.display = "none";
		}
		else {
			css.display = "none";
        }

		return css;
    }
    header_class() {
  	    var cls = "flex_col";
		if(this.state.top) {
			cls = cls + " web-head_1";
		}
		else cls = cls +  " web-header";

		return cls;
    }

    ham_show() {
         this.setState({ isOpen: !this.state.isOpen });
   }
    ham_ol() {
        //alert("OL : ..");
         //this.setState({ isOpen: 0 });
   }
    sdo_upd() {
        var sdo = this.state.sdo;
        if(!sdo.fid) sdo.fid = "0";
        sdo.fid = ((sdo.fid * 1) + 1).toString();
        this.setState({sdo: sdo})
        sessionStorage.setItem("svp", JSON.stringify(this.state.sdo));
        //this.props.history.push("cas");
    }
    sdo_ctx() {
        sessionStorage.removeItem('svp');
        //sessionStorage.clear();
    }
    sdo_cas() {
        this.context.fid = "45";
        this.props.navigate("cas");
    }
    ct_show(id) {
        //alert(id);
        const element = document.getElementById(id);
        if (element) element.scrollIntoView();
        //const tesNode = ReactDOM.findDOMNode(id);
        //window.scrollTo(0, tesNode.offsetTop);
    }
    w00_css() {
  	    var css = {};
        css.height = window.innerHeight;
        css['minHeight'] = window.innerHeight;
		return css;
    }
    cdi_step() {
        //alert(id);
        var step = this.state.step + 1;
        if(step > 2) step = 1;
        this.setState({ step: step });
        if(step == 1) {
            var element = ReactDOM.findDOMNode(this.s1ref.current);
            element.classList.add("animated", "rotateIn");
            element.addEventListener('animationend', function() {
                element.classList.remove('animated', "rotateIn")
                //node.removeEventListener('animationend', handleAnimationEnd)
            })
        }
        if(step == 2) {
            var element = ReactDOM.findDOMNode(this.s2ref.current);
            element.classList.add("animated", "bounceIn");
            element.addEventListener('animationend', function() {
                element.classList.remove('animated', "bounceIn")
                //node.removeEventListener('animationend', handleAnimationEnd)
            })
        }

         setTimeout(() => {
            this.cdi_step();
        }, 5000);
    }
    cdi_show(id) {
        //alert(id);
        var brc = false;
        if(this.state.step == id) brc = true;
        return brc;
    }
    cdi_slide(id) {
        //alert(id);
  	    var css = {};
        //css.display = "none";
        css.width = "150px";
        css.height = "150px";
        //css.color = "#fff";
        css.display = "none";
        if(id == "s1") {
            css.background = "#080";
            if(this.state.step == 1) css.display = "block";
        }
        if(id == "s2") {
            css.background = "#008";
            if(this.state.step == 2) css.display = "block";
        }
		return css;
    }

    mnu_sel(mnuid) {

        this.setState({mnu_sel: mnuid});
        const element = document.getElementById(mnuid);
        if (element) element.scrollIntoView();
    }

   mnu_item_css(mnuid) {
  	    var css = {};

        css.color = "#222";
        if(this.state.mnu_sel == mnuid) {
			///css.background = "#080";
			//css.color = "#fff";
			css.color = "#080";
        }
		return css;
    }
   xmnu_item_css(mnuid) {
  	    var css = {};
        var body = document.getElementById("web-body");
        if(!body) {
		    return css;
		}

        var offset = body.scrollTop;
        var start = body.scrollTop;
        var slut = body.scrollTop + window.innerHeight;

        const element = document.getElementById(mnuid);
        const top = element.getBoundingClientRect().top;
        var eltop = element.offsetTop;
        var elbot = element.offsetTop + element.offsetHeight;
        var brc = eltop >= start && eltop <= slut;
        if(!brc) brc = elbot >= start && elbot <= slut;
        //const brc = (top + offset) >= 0 && (top - offset) <= body.scrollHeight;

		if(brc) {
			css.background = "#080";
			css.color = "#fff";
		}

		return css;
    }

    row_css(row) {
  	    var css = {};
		if(this.state.row) {
		if(this.state.row.ank == row.ank) {
			css.background = "#080";
			css.color = "#fff";
		}
		}
		return css;
    }
    login() {
        this.setState({login: true });
        //this.props.history.push("pos");
    }
    pdf_show(id) {
        this.setState({ pdf: id });
        return;
    }
    pdf_fetch(id) {
       var headers = new Headers({'Content-Type': 'application/pdf'});
       var options = {
            method: 'GET',
            headers: headers,
            mode: 'no-cors',
            cache: 'default'
        };
        //var pdf_url = "http://pub.svepos.se/docs/marknad/c100.pdf";
        var pdf_url = "/docs/c100.pdf";
        //alert(pdf_url);
        //fetch(pdf_url, options)
        fetch(pdf_url)
        .then(response => response.blob())
        .then(data => {
            //alert(data);
            //alert(response.data);
            var pdf_uri = "data:application/pdf;base64," + data;
            //alert(JSON.stringify(pdf_uri));
            this.setState({ pdf: 1, pdf_uri: pdf_uri })
        });

        //this.setState({ pdf: 1 });
		return;
    }
    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    handleLogin(event) {
        //this.setState({value: event.target.value});
        //const body = new FormData(this.form);
        //alert('FORM: ' + JSON.stringify(state));
        //props.ctx.dologin(state.user.uid, state.user.pwd);
        this.login_srv(this.state.user.uid, this.state.user.pwd);
    }
	handleClose = () => {
		//this.props.ctx.setState({ login: false });
	};
    login_srv(uid, pwd) {
        var self = this;
		var prm = {};
        prm.req = "usr:login";
		prm.uid = uid;
		prm.pwd = pwd;
		prm.token = "";

		//alert(JSON.stringify(uid));
        //gda.wndLoading("Hämtar");
        net.gcs_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret));

            var o = {};
            //o.deps = ret.rco;
            //alert(JSON.stringify(o));
            //self.setState({tra: ret.rca });
            //toastr.success("OK", "SAPI");
            if(ret.ok != "000") {
                //alert(JSON.stringify(ret));
                var msg = { info: false, err: true, text: "Login fel ..."};
                self.setState({msg: msg });
                setTimeout(function(){
                    self.setState({msg: {} });
                }, 3000);
                return;
            }
            //alert(JSON.stringify(ret.seo));

            var seo = ret.seo;
            //alert(JSON.stringify(seo));
            self.props.store.seo = seo;
            var xdo = JSON.stringify(seo);
            //alert(JSON.stringify(xdo));
            sessionStorage.setItem("svp", xdo);
		    //props.ctx.setState({ login: false });
		    //props.ctx.props.history.push("cas");
            var gdo = JSON.stringify(ret.gdo);
            //sessionStorage.setItem("gdo", gdo);

            var url = seo.auto;
            if(url == "casino.ov") url = "cas.ov";
            url = "/" + url.replace(/\./g,"/");
            if(true) url = "/hello";

            if(self.props.navigate == null) {
                alert("Ctx");
                //alert(self.context.history);
                self.context.navigate(url);
                //alert("Done");
           }
            else {
                //alert("Props");
                //alert(self.props.history);
                self.props.navigate(url);
                //self.props.history.go(0);
                //self.forceUpdate();
                //alert("Done");
            }
        })
        .catch(function(e){
            //var txt = JSON.stringify(e);
            alert("LOGIN ERR: " + JSON.stringify(e));
            //gda.wndError(txt);
        });
    }

    render() {
        var stat = [];
        var keys = Object.keys(this.state.stat);
        for (var key of keys) {
            var row = this.state.stat[key];
            stat.push(row);
        }
        const handleDateChange = date => {
            this.setState({seldate: date });
        };

        return (
        <div className="web-app">

            <div id="header" className={ this.header_class() }>
            <div className="web-top-content d-flex flex-row">
                <div className="px-3 web-top-icon">
                    <FontAwesomeIcon icon="home" size="1x"/>
                </div>
                <div className="hide">
                    <img className="svk-kub" src={ require('./img/logo-kub.png') } />
                </div>
                <div className="px-3 web-top-icon hide" onClick={() => this.ham_show() }>
                    <FontAwesomeIcon icon="bars" size="1x"/>
                </div>
                <div className="web-top-mnu d-none d-md-inline-flex flex-row">
                    <div className="item" onClick={() => this.mnu_sel("m00") } style={ this.mnu_item_css("m00") }>Login</div>
                </div>
                <div className="flex-fill"></div>
                <div className="px-3 web-top-txt">
                    { this.seo.name }
                </div>
                <div className="px-3 web-top-icon" onClick={() => this.login() }>
                    <FontAwesomeIcon icon="user" size="1x"/>
                </div>
                <div className="">
                    <Dd_Sel items={ this.state.useritems } cls="web-top-icon" getValue={(e)=> this.sel_fkn(e)} />
                </div>
            </div>
            </div>

    <div className="web-body" id="web-body">

               <div className="d-flex flex-column align-items-center justify-content-center" style={{ height: "100%" }}>
                        <div className="p-md-4" style={ this.wnd_css() }>
                            <div className="">
                                    <div className="login__header text-center mb-lg-5 mb-4">
                                        <h3 className="login__title mb-2"> Login</h3>
                                        <p>Svepos Portal</p>
                                    </div>
                                    <div className="px-3">
                                        { this.state.msg.text }
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1" className="input__label">Användare</label>
                                        <input type="text" className="form-control login_text_field_bg input-style"
                                               id="uid" aria-describedby="emailHelp" placeholder=""
                                               required=""
                                               name="uid" value={this.state.uid} onChange={(event) => this.handleUserInput(event)}
                                               autoFocus />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputPassword1" className="input__label">Lösenord</label>
                                        <input type="password" className="form-control login_text_field_bg input-style" id="pwd" name="pwd" value={this.state.pwd} onChange={(event) => this.handleUserInput(event)} placeholder="" required />
                                    </div>
                                    <div className="form-check check-remember check-me-out">
                                        <input type="checkbox" className="form-check-input checkbox" id="exampleCheck1" />
                                            <label className="form-check-label checkmark" htmlFor="exampleCheck1">Kom ihåg mig</label>
                                    </div>
                                    <div className="d-flex align-items-center flex-wrap justify-content-between">
                                        <button onClick={(event) => this.handleLogin(event) } className="btn btn-hw-blue btn-style mt-4 w150">
                                            Rensa
                                        </button>
                                        <button onClick={(event) => this.handleLogin(event) } className="btn btn-hw-green btn-style mt-4 w250">
                                            Logga in
                                        </button>
                                    </div>
                                    <div className="d-flex align-items-center flex-wrap justify-content-between">
                                        <p className="signup mt-4">
                                            Saknar Ni konto ? <a href="register.html" className="signuplink">Registrera Er</a>
                                        </p>
                                    </div>
                            </div>
                        </div>
                    </div>

                <div className="">
                    <img className="svk-logo" src={ require('./img/logo-sk.png') } />
                </div>
        </div>
        </div>
        );
    }
}

//WebPub.contextType = SDOContext; // Function
export default withRouter(Start);