import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";
import Td_Sel from "../../lib/ui/td_sel";
import * as utils from "../../assets/utils";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class PMS_Logg extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, tra: [] };
    this.state.seldate = new Date();
    this.state.avd = "01";
    this.state.laa = [];
    this.state.kro = {};
    this.state.aa = [
                {id: '01', text: 'AVD 01'},
                {id: '02', text: 'AVD 02'}
            ];
    this.state.cols = {};
    //context.posrend = this;
      this.selitems = [
          {fkn: 'edit', text: 'ÖPPNA'}
      ];
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.logg();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 225;
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

  kund_open(row) {
      var self = this;
  }
  kund_edit(row) {
      var self = this;
      //alert(JSON.stringify(row.krr00));
        this.setState({edit: 1, kro: row});
  }
  fkn_close() {
    this.setState({edit: false });
    //this.props.history.push("cas");
    //this.login_srv(uid, pwd);
  }

  logg() {
    var self = this;
    var prm = {};
    prm.req = "pms.log.qs_logs";
    prm.typ = "0";
    //prm.avd = this.state.avd;

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        var o = {};
        //o.deps = ret.rco;
        //alert(JSON.stringify(o));
        self.setState({laa: ret.rco }, function () {
            self.syncTb("loggTable");
        });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e) {
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }
    syncTb(tb){
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
            tdElements = tbel.getElementsByTagName('td');

        for (let i = 0; i < thElements.length; i++) {
            if(!tdElements[i]) continue;
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
                width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
    th_style(id) {
        var css = {};
        css.cursor = "pointer";
        if(id == this.state.sortid) {
            if(this.state.sortdir) css.color = "#800";
            else css.color = "#080";
        }
        return css;
    }
    th_icon(typ) {
        var cls = "";
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;
        if(sortid == typ)
        {
            if(sortdir) cls = "fa fa-caret-up";
            else cls = "fa fa-caret-down";
        }
        return cls;
    }
    tb_sort(typ) {
        var self = this;
        //var rvs = self.state.rvs;
        var rva = self.state.rva;
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;

        if(sortid != typ) sortdir = 0;
        else {
            if(sortdir) sortdir = 0;
            else sortdir = 1;
        }

        if(sortdir) rva.sort((a,b) => (a.noshow[typ] > b.noshow[typ]) ? 1 : ((b.noshow[typ] > a.noshow[typ]) ? -1 : 0));
        else rva.sort((a,b) => (a.noshow[typ] < b.noshow[typ]) ? 1 : ((b.noshow[typ] < a.noshow[typ]) ? -1 : 0));
        self.setState({ rva: rva, sortid: typ, sortdir: sortdir });
    }
    sel_row_fkn(row, sel) {
        if(sel.fkn == "edit") {
            //this.ns_sel(row);
        }
    }

  render() {
    return (
    <div className="web-app">
    <div className="flex-row flex-space-between">
        <div className="flex-row flex">
        <button className="btn btn-hw-blue w150" onClick={() => this.syncCols("kidTable") } aria-label="System" >
            FUNKTIONER
        </button>
        </div>
        <div className="flex-row">
       </div>
        <div className="flex-row flex-end flex">
        <button className="btn btn-hw-blue w150" onClick={() => this.syncCols("kidTable") } aria-label="System" >
            UPPDATERA
        </button>
        </div>
    </div>
    <div className="divider_orange"></div>

    <div id="loggTable">
        <table className="table table-bordered table-hover tb " style={{marginBottom: "0px"}}>
            <thead>
            <tr>
                <th style={ this.th_style("datum" ) }>Datum</th>
                <th style={ this.th_style("tidpunkt" ) }>Tid</th>
                <th style={ this.th_style("boknr" ) }>Boknr</th>
                <th style={ this.th_style("Text" ) }>Text</th>
                <th style={ this.th_style("s0sig" ) }>Signatur</th>
                <th style={{width:"16px"}} ></th>
            </tr>
            </thead>
        </table>
       <div className="tb-cdi" style={ this.tb_style() }>
       <table className="table table-bordered table-hover tb">
            <tbody>
            { this.state.laa.map((row, key) =>
            <tr key={key} id={ row.r0lopnr } onDoubleClick={() => this.kund_edit(row) }>
                <td>{ utils.toDate( row.datum ) }</td>
                <td>{ row.tidpunkt }</td>
                <td>{ row.boknr }</td>
                <td>{ row.Text }</td>
                <td>{ row.s0sig }</td>
                <td>
                    <Td_Sel items={ this.selitems } getValue={(e)=> this.sel_row_fkn(row, e)} />
                </td>
            </tr>
            )}
            </tbody>
        </table>
        </div>
    </div>
   </div>
  );
    }
}
export default PMS_Logg;
