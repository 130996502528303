import React, { useState, useEffect, useContext } from 'react';
import { observer } from "mobx-react"
import * as net from "../../lib/net";

import { format, getDate, getMonth, getWeek, eachDayOfInterval, eachWeekOfInterval } from 'date-fns'
import {sv} from "date-fns/locale";

function UI_Tiden(props) {
    const [state, setState] = useState({ tid : {} });

    function setTime() {
        var dat = new Date();
       var tid = {};
        tid.datum = format(dat, "yyyy-MM-DD", { locale: sv });
        //tid.tidpkt = moment().format("HH:mm.ss");
        tid.tidpkt = format(dat, "HH:mm", { locale: sv });
        setState({tid: tid});
    }
    function handleLogin(event) {
        //this.setState({value: event.target.value});
        //const body = new FormData(this.form);
        //alert('FORM: ' + JSON.stringify(state));
        //props.ctx.dologin(state.user.uid, state.user.pwd);
        //dologin(state.user.uid, state.user.pwd);
    }
    function handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = state.user;
      user[name] = value;
      setState({user: user});
      //setState({[name]: value});
    }
    if(!props.show) return "";

        var dat = new Date();
    //var tid = {};
    state.tid.datum = format(dat, "yyyy-MM-DD", { locale: sv });
    //state.tid.tidpkt = moment().format("HH:mm.ss");
    state.tid.tidpkt = format(dat, "HH:mm", { locale: sv });
    //setState({tid: tid});
    setInterval(setTime, 30000);

	var html =
    <div className="tid_ctrl">
        <span className="text-right">{ state.tid.datum }</span>
        <br />
        <span className="text-right">{ state.tid.tidpkt }</span>
    </div>
	;
	return html;
}

export default UI_Tiden;