import React, { useState, useEffect, useContext } from 'react';
import {format} from "date-fns";

import * as fkn from "../../../lib/fkn";
import * as net from "../../../lib/net";
import * as utils from "../../../assets/utils";
import {toTid} from "../../../assets/utils";

import Dt_Cal from "../../../lib/ui/dt_cal";

import Dd_Sel from "../../../lib/ui/dd_sel";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PdfRpDlg from "../../lib/pdf_rp_dlg";

class Rev_Dts extends React.Component {
  constructor(props, context) {
    super(props);

    this.dt_dt = React.createRef();

    this.store = props.store;
    this.pms = props.store.pms;

    this.state = {};
    this.state.fkn = "";
    this.state.sortid = "";
    this.state.dta = [];
    this.state.kta = [];
    this.state.tot = {};

    this.state.today = new Date();
    var dts = format(this.state.today, "yyyy-MM-dd");
    this.state.dts = dts;
    if(this.pms.base) {
      this.state.dts = this.pms.base.fdat;
    }
    if(props.wnds) {
      //alert(JSON.stringify(props.wnds.fdat));
      if(props.wnds.fdat) this.state.dts = props.wnds.fdat.cdidate();
    }

    this.state.msg = {};
    this.state.wnds = {};
    this.selitems = [
        { fkn: 'upd', text: 'Uppdatera' }
    ];
      this.state.pdf = 0;
      this.state.pdf_content = null;
    }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //if(this.props.wnds.show) this.krub_rvs();
    this.konto_dts();
  }

  dlg_style() {
    var css = {};
    //css.width = window.innerWidth * 0.8;
    //css.height = window.innerHeight * 0.8;
    return css;
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = (window.innerHeight * 0.95) - 220;
      css.overflow = "auto";
      return css;
  }
  th_style(id) {
      var css = {};
      css.cursor = "pointer";
      if(id == this.state.sortid) css.color = "#080";
      return css;
  }
    row_style(row) {

        var css = {};
        if(row.e0typ == "xx") {
            css.background = "#3c983c";
            css.color = "#fff";
        }
        if(this.state.fkn == "FSK") {
            if(row.e0typ.substr(0,3) !== "fsk") {
                css.display = "none";
            }
        }
        return css;
    }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

  dt_dat(e) {
    //alert(JSON.stringify(e));
    var dts = format(e, "yyyy-MM-dd");
    this.setState({ dts: dts });
  }
  oneday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_dt.current.updateDt(this.state.dts);
  }
  addday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_dt.current.updateAdd();
  }
  subday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_dt.current.updateSub();
  }

  change_fkn(e) {
      var self = this;
    //let cdat = this.state.seldate;
    //alert(JSON.stringify(e));
    let fkn = e.target.value;
    this.setState({fkn: fkn}, function () {
        self.syncTb("trsTable");
    });
    //this.setState({tra: [], tsa: [], tta: [] });
  }

  konto_dts() {
    var self = this;

    var msg = { info: true, text: "Hämtar konton ..."};
    self.setState({ kta: [], dta: [], msg: msg });

    var prm = {};
    prm.req = "pms.eko.trs_konto";
    prm.fdat = this.state.dts;
    prm.tdat = this.state.dts;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        if(ret.ok != "000") {
            self.setState({ msg: {} });
            return;
        }
        var tot = {};
        tot.fsg = 0;
        tot.bet = 0;
        tot.trf = 0;
        for(var row of ret.rca){
          var belopp = row.belopp * 1;
          if(row.konto < "2000") tot.bet = tot.bet + belopp;
          if((row.konto >= "2000") && (row.konto <= "2999")) tot.trf = tot.trf + belopp;
          if(row.konto > "2999") tot.fsg = tot.fsg + belopp;
        }
        tot.tfsg = tot.fsg - tot.trf;
        tot.saldo = tot.tfsg - tot.bet;
        
        self.setState({ kta: ret.rca, tot: tot, msg: {} }, function () {
            self.syncTb("dtaTable");
        });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  krub_prn() {
      var self = this;
  
  
      var msg = { info: true, text: "Skriver ut krubba ..."};
      self.setState({ dta: [], msg: msg });
  
      var prm = {};
      prm.req = "pms.krub.krub_dts_prn";
      prm.dts = this.state.dts;
   
      net.sio_req(prm)
      .then(function(ret){
          //alert(JSON.stringify(ret.rca));
  
          if(ret.ok != "000") {
              self.setState({ msg: {} });
              return;
          }
          self.setState({pdf: 1, pdf_content: ret.pdf });
        })
      .catch(function(e){
          var txt = JSON.stringify(e);
      });
    }
  
  tr_edit(row) {
    var self = this;
    var wnds = {};
    wnds.tropen = true;
    wnds.tro = row;
    this.setState({ wnds: wnds });
  }
    syncTb(tb){
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
        tdElements = tbel.getElementsByTagName('td');

        for (let i = 0; i < thElements.length; i++) {
            if(!thElements[i]) continue;
            if(!tdElements[i]) continue;
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
            width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
   tb_sort(typ) {
      var self = this;
      //var rvs = self.state.rvs;
      var kta = self.state.kta;
      var sz = this.state.sz_val;

      kta.sort((a,b) => (a[typ] > b[typ]) ? 1 : ((b[typ] > a[typ]) ? -1 : 0));
      self.setState({ kta: kta, sortid: typ });
   }
  tb_filtr() {
      var self = this;
      var rvs = self.state.rvs;
      var sz = this.state.sz_val;

      if(sz.length < 1) {
        self.setState({ rva: rvs });
          return;
      }
      sz = sz.toUpperCase();
      var rva = rvs.filter(function(row) {
            var brc = false;
            if(row.company.toUpperCase().includes(sz)) brc = true;
            if(row.name.toUpperCase().includes(sz)) brc = true;
            return brc;
      });
      self.setState({rva: rva });
   }
   sie_pms() {
    var self = this;
    var datum = this.state.dts;

    var prm = {};
   //prm.req = "pms.rp.bok_sie";
   prm.req = "pms.rp.bok_tot_sie";
   prm.fdat = datum;
   prm.tdat = datum;
   prm.typ = "";
   prm.def = "";
    //alert(JSON.stringify(prm));

    net.sio_req(prm)
    .then(function(ret) {
        //alert(JSON.stringify(ret.rca));
        //var uri = "data:application/pdf;base64," + ret.pdf;
        //self.setState({pdf: 1, pdf_content: ret.pdf });
        //self.syncCols("lsTable");
     var rawbuf = ret.buf;
  var file = new Blob([(rawbuf)], {type: 'application/octet-stream'});
  var fileURL = window.URL.createObjectURL(file);
  //$scope.fil_object = $sce.trustAsResourceUrl(fileURL);
  var a = document.createElement('a');
  a.href = fileURL;
  //var filename = req.filename;
  var filename = "";
  //if(filename.length < 1) filename = "webfile.txt";
  if(filename.length < 1) filename = prm.fdat + "-" + prm.tdat + ".si";
  a.download = filename;
  document.body.appendChild(a);
  a.click();
   })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
}

    sel_fkn(sel) {
        //alert(JSON.stringify(fkn));
        if(sel.fkn == "invsrp") {
            this.invs_rp();
        }
        if(sel.fkn == "logi") {
            this.trs_logi();
        }
    }
    invs_rp() {
        var self = this;
        var wnds = {};
        wnds.invsrp = true;
        wnds.fdat = this.state.fdat;
        wnds.tdat = this.state.tdat;
        wnds.bdat = this.state.bdat;
        this.setState({ wnds: wnds });
    }
    wnd_close = () => {
      this.props.ctx.setState({ wnds: {} });
    };
    krub_rv(row) {
      var self = this;
      var wnds = {};
      wnds.krubrv = true;
      wnds.fdat = this.state.fdat;
      wnds.tdat = this.state.tdat;
      wnds.boknr = row.boknr;
      this.setState({ wnds: wnds });
    }
    krub_corr(row) {
      var self = this;
      var wnds = {};
      wnds.krubcorr = true;
      wnds.dts = this.state.dts;
      wnds.kro = row;
      this.setState({ wnds: wnds });
    }
    pdf_done (ret) {
      this.setState({loading: 0, pdf: 0, pdf_content: null });
    }
  
  render() {
    return (
      <div className="mx_base">
      { this.props.backdrop ? <div className="mx_backdrop" /> : null }
      <div className="mx_dlg mx_80h flex-col" style={ this.dlg_style() }>
      <div className="mx_h01 flex-row" style={{background: "#1a456b", color: "#fff"}}>
          <div>OMSÄTTNING PER DATUM</div>
          <div className="flex"></div>
          <div>UPPDATERA</div>
      </div>

      <div className="mx_content flex px-3">


    <div className="flex-row flex-space-between">
        <div className="flex-row">
        <div className="flex-row">
              <div className="dt-btn-01" onClick={() => this.subday() } ><FontAwesomeIcon icon="minus" size="1x" /></div>
              <Dt_Cal dts={ this.state.dts } rp="1" ref={this.dt_dt} getValue={(e)=> this.dt_dat(e)} />
              <div className="dt-btn-01" onClick={() => this.addday() } ><FontAwesomeIcon icon="plus" size="1x" /></div>
        </div>
        </div>

        <div className="flex"></div>

        <button className="btn btn-hw-blue w150" onClick={() => this.konto_dts() } aria-label="System" >
            Visa
        </button>
    </div>
    <div className="divider_green"></div>

        <div id="dtaTable">
          <table className="table table-bordered table-hover" style={{marginBottom: "0px"}}>
                <thead>
                <tr>
                    <th style={ this.th_style("konto") } onClick={() => this.tb_sort("konto") }>Konto</th>
                    <th style={ this.th_style("text") } onClick={() => this.tb_sort("text") }>Text</th>
                    <th style={ this.th_style("belopp") } onClick={() => this.tb_sort("belopp") }>Belopp</th>
                    <th style={ this.th_style("moms") } onClick={() => this.tb_sort("moms") }>Moms</th>
                    <th style={ this.th_style("netto") } onClick={() => this.tb_sort("netto") }>Netto</th>
                    <th style={ this.th_style("mproc") } onClick={() => this.tb_sort("mproc") }>Mproc</th>
                    <th style={ this.th_style("status") } onClick={() => this.tb_sort("status") }>Status</th>
                </tr>
                </thead>
            </table>
            <div style={ this.tb_style() }>
          <table className="table table-bordered table-hover">
            <tbody>
                { this.state.kta.map((row, key) =>
                    <tr key={key} style={ this.row_style(row) } onDoubleClick={() => this.krub_rv(row) }>
                        <td>{ utils.toLang( row.konto ) }</td>
                        <td>{ utils.toLang( row.text ) }</td>
                        <td>{ utils.toPris( row.belopp ) }</td>
                        <td>{ utils.toPris( row.moms ) }</td>
                        <td>{ utils.toPris( row.netto ) }</td>
                        <td>{ utils.toLang( row.mproc ) }</td>
                        <td onClick={() => this.krub_corr(row) }>{ row.status }</td>
                    </tr>
                )}
                </tbody>
            </table>
        </div>
        <div className="divider_green"></div>
        <div className="flex-row">
                <div className="txt_16">FSG: {utils.toPris(this.state.tot.tfsg)}</div>
                <div className="flex"></div>
                <div className="txt_16">BET: {utils.toPris(this.state.tot.bet)}</div>
                <div className="flex"></div>
                <div className="txt_16">SALDO: {utils.toPris(this.state.tot.saldo)}</div>
        </div>

        </div>

        </div>
        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex-row"></div>
            <button className="btn btn-hw-blue w250" onClick={() => this.sie_pms() } >
                SIE FIL
            </button>
            <div className="flex-row"></div>
            <button className="btn btn-hw-green w250" onClick={() => this.krub_prn() } >
                SKRIV UT
            </button>           
      </div>

        </div>
        <PdfRpDlg show={this.state.pdf} pdf={this.state.pdf_content} done={(ret) => this.pdf_done(ret)} backdrop={true}/>
      </div>
  );
}
}
export default Rev_Dts;
