import React, { useState, useEffect, useContext } from 'react';

import * as utils from "../../../../assets/utils";
import * as net from "../../../../lib/net";
import Fr_Input from "../../../../lib/ui/fr_input";
import Fr_Select from "../../../../lib/ui/fr_select";
import Pkat_Rt_Add from "./pkat_rt_add";
import Pkat_Rt_Upd from "./pkat_rt_upd";
import Pkat_Di_Upd from "./pkat_di_upd";

class Pkat_Open extends React.Component {
  constructor(props, context) {
    super(props);

    this.pos = props.store.pos;
    this.state = {};
    this.state.mka = [];


    this.state.gnr = "001";
    //this.state.lro = props.lro;
    this.state.ynq = [
                {id: '0', text: 'NEJ'},
                {id: '1', text: 'JA'}
            ];

    this.state.wnd_ok = false;
    this.state.wnd_err = false;

    this.state.part = "01";
      this.state.wnds = {};
      this.state.cols = {};
    this.state.pko = {};
    this.state.rta = [];
    this.state.dia = [];
    if(props.wnds.pko) {
        this.state.pkb = props.wnds.pko;
        this.state.pko = JSON.parse(JSON.stringify(props.wnds.pko));
    }
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      if(!this.props.wnds.pko) this.pko_init();
      else this.pkat_read();
  }

    pko_init() {
        var pko = {};
        pko.boktyp = "";
        pko.iresurs = "";
        pko.restname = "";
        pko.resursordn = "";
        pko.platser = "";
        pko.fromtime = "";
        pko.tilltime = "";
        pko.reservtime = "";
        //alert(JSON.stringify(pko));
        //this.setState({ pko: pko });
        return;
    }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    rt_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    di_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }

    formchange (e) {
      const name = e.target.name;
      const value = e.target.value;
      var pbo = this.state.pbo;
      var na = name.split(".");
      pbo[na[0]][na[1]] = value;
      this.setState({pbo: pbo});
      //setState({[name]: value});
    }

    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    wnd_close = () => {
      if(this.props.close) {
          this.props.close(this.props.ctx);
      }
      else this.props.ctx.setState({ wnds: {} });
	};
    handleChange = (event, newValue) => {
        //alert(newValue);
        this.setState({ix: newValue});
      };
    toggle() {
      this.setState({modal: false});
	};

  submitHandler = event => {
    event.preventDefault();
    event.target.className += " was-validated";
  };

  changeHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  val_save(id, val) {
      var pko = this.state.pko;
      pko[id] = val;
        this.setState({ pko: pko });
      return;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  tab_style(part) {
      var css = {};
      if(this.state.part == part ){
          css.background = "#fff";
          css.color = "#282";
      }
      return css;
  }
  cssCols (cid) {
      var css = {};
      if(this.state.cols[cid]) css.width = this.state.cols[cid].toString() + "px";
      //if(cid == "c01") alert(JSON.stringify(this.state.cols[cid]));
      return css;
  }
  syncCols (tb) {

    if (!document.getElementById(tb)) return;

    var cols = {};
    var ix = document.getElementById(tb).rows.length - 1;
    var ic = document.getElementById(tb).rows[0].cells.length;
    var ci = 0;
    while(ci < ic) {
        var cxi = ci + 1;
        var col = "c" + cxi.toString().padStart(2, "0");
        cols[col] = document.getElementById(tb).rows[ix].cells[ci].clientWidth + 1;
        ci = ci + 1;
    }
    this.setState({cols: cols });
    //alert(JSON.stringify(this.state.cols));
    //this.forceUpdate();
  };

    show_part(part) {
        //if(part == "02") this.rv_kf();
        this.setState({part: part});
  };

  pkat_read() {
    var self = this;
    var prm = {};
    prm.req = "pms.pkat.qs_pkat";
    prm.pkat = this.state.pko.n0typ;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));
        var pko = ret.rco;
        var rta = pko.rta;
        var dia = pko.dia;
        self.setState({rta: rta, dia: dia});
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  pkat_save() {
    var self = this;
    var prm = {};
    prm.req = "pms.pkat.pkat_upd";
    prm.pkb = this.state.pkb;
    prm.pko = this.state.pko;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));
        if(ret.ok == "000") {
            self.setState({wnd_ok: true });
            setTimeout(function(){
                self.setState({wnd_ok: false });
                  if(self.props.cb) {
                      self.props.cb(self.props.ctx);
                  }
                  else self.props.ctx.setState({ wnds: {} });
                return;
            }, 1000);
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  pkat_del() {
    var self = this;
    var prm = {};
    prm.req = "pms.pkat.pkat_del";
    prm.pko = this.state.pko;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));
        if(ret.ok == "000") {
            self.setState({wnd_ok: true });
            setTimeout(function(){
                self.setState({wnd_ok: false });
                  if(self.props.cb) {
                      self.props.cb(self.props.ctx);
                  }
                  else self.props.ctx.setState({ wnds: {} });
                return;
            }, 1000);
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

    rtp_add() {
        var self = this;
        var wnds = {};
        wnds.rtpadd = 1;
        wnds.pko = this.state.pko;
        this.setState({wnds: wnds});
    }
    rtp_edit(row) {
        var self = this;
        var wnds = {};
        wnds.rtpedit = 1;
        wnds.pko = this.state.pko;
        wnds.rtp = row;
        this.setState({wnds: wnds});
    }
    di_add() {
        var self = this;
        var wnds = {};
        wnds.diadd = 1;
        wnds.pko = this.state.pko;
        this.setState({wnds: wnds});
    }
    di_edit(row) {
        var self = this;
        var wnds = {};
        wnds.diedit = 1;
        wnds.pko = this.state.pko;
        wnds.tro = row;
        this.setState({wnds: wnds});
    }

  rt_pris(dag, buf) {
      var pris = "";

      if(dag > 7) return pris;
      if(dag < 0) return pris;

      var i = dag - 1;
      var ix = (i * 10);
      pris = buf.substr(ix, 10);
      pris = pris.cdipris();
      return pris;
  }

  render() {
    //if(!this.props.show) return "";
    var title = this.props.title;
    if(!title) title = "Resurs";

	var html =
        <div className="mx_base">
        <div className="m2_backdrop" />
        <div className="m2_dlg mx_80 flex-col" style={ this.dlg_style() } draggable="true" >
        <div className="mx_content flex">

        <div className="mx_h01">
            <div>PRISKATEGORI</div>
        </div>
            <div className="flex-row bg-black">
                <div className="rv-tab-btn" style={this.tab_style("01")} onClick={() => this.show_part("01") } >
                    ALLMÄNT
                </div>
                <div className="rv-tab-btn" style={this.tab_style("02")} onClick={() => this.show_part("02") } >
                    RUMSTYPER
                </div>
                <div className="rv-tab-btn" style={this.tab_style("03")} onClick={() => this.show_part("03") } >
                    FÖRDELNING
                </div>
            </div>
            <div className="divider_orange"></div>

        { this.state.part == "01" ?
            <div className="">
            <form className="cdi_f03">
            <div className="flex-row">
                    <Fr_Input label="Typ" className="text-left w250" text={this.state.pko.n0typ} getValue={(e)=> this.val_save("n0typ", e) } />
                    <Fr_Input label="Sortering" className="text-left w150" text={this.state.pko.n0sort} getValue={(e)=> this.val_save("n0sort", e) } />
            </div>
            <div className="flex-row">
                    <Fr_Input label="Text" className="text-left w350" text={this.state.pko.n0text} getValue={(e)=> this.val_save("n0text", e) } />
            </div>
            <div className="flex-row">
                    <Fr_Input label="Fråndatum" className="text-left w250" text={this.state.pko.n0fromdate} getValue={(e)=> this.val_save("n0fromdate", e) } />
                    <Fr_Input label="Tilldatum" className="text-left w250" text={this.state.pko.n0todatum} getValue={(e)=> this.val_save("n0todatum", e) } />
            </div>

            <div className="divider_green"></div>
            <div className="flex-row">
                    <Fr_Input label="Ej moms" className="text-left w150" text={this.state.pko.n0ejmoms} getValue={(e)=> this.val_save("n0ejmoms", e) } />
                    <Fr_Input label="Per Person" className="text-left w150" text={this.state.pko.n0perpers} getValue={(e)=> this.val_save("n0perpers", e) } />
            </div>
            <div className="flex-row">
                    <Fr_Input label="Start dag" className="text-left w250" text={this.state.pko.n0start} getValue={(e)=> this.val_save("n0start", e) } />
                    <Fr_Select label="Barn typ" flex className="text-left w250" text={this.state.pko.n0barntyp} items={this.state.ynq} getValue={(e)=> this.val_save("n0barntyp", e) } />
            </div>
            <div className="flex-row">
                    <Fr_Input label="Paket" className="text-left w250" text={this.state.pko.n0paketpaket} getValue={(e)=> this.val_save("n0paketpaket", e) } />
            </div>
            <div className="flex-row">
                    <Fr_Input label="Min Dagar" className="text-left w150" text={this.state.pko.n0minidagar} getValue={(e)=> this.val_save("n0minidagar", e) } />
                    <Fr_Select label="Min krav" flex className="text-left w150" text={this.state.pko.n0minkrav} items={this.state.ynq} getValue={(e)=> this.val_save("n0minkrav", e) } />
            </div>
            <div className="flex-row">
                    <Fr_Input label="Rapport Sortering" className="text-left w250" text={this.state.pko.n0psort} getValue={(e)=> this.val_save("n0psort", e) } />
            </div>


            </form>
            </div>
        : null }

        { this.state.part == "02" ?
            <div className="">
            <div className="flex-row" style={{padding:"5px",height:"35px"}}>
                    <div className="flex-col link_14" onClick={() => this.rtp_add() }>
                        LÄGG TILL
                    </div>
                <div className="flex"></div>
                <div className="flex-col link_14">
                        <div onClick={() => this.pkat_read() }>UPPDATERA</div>
                    </div>
            </div>
            <div className="divider_orange"></div>
            <div className="flex flex-col" style={{border: "0px", margin: "0px", padding: "0px"}}>
                <table className="table" style={{border: "0px", margin: "0px", padding: "0px"}}>
                    <thead style={{fontSize: "12px", fontWeight: "normal", color: "#080", border: "0px", margin: "0px", padding: "0px"}}>
                    <tr>
                        <th style={{textAlign: "left"}}>Rumstyp</th>
                        <th style={{textAlign: "left"}}>Datum</th>
                        <th style={{textAlign: "left"}}>Antal</th>
                        <th style={{textAlign: "left"}}>Måndag</th>
                        <th style={{textAlign: "left"}}>Tisdag</th>
                        <th style={{textAlign: "left"}}>Onsdag</th>
                        <th style={{textAlign: "left"}}>Torsdag</th>
                        <th style={{textAlign: "left"}}>Fredag</th>
                        <th style={{textAlign: "left"}}>Lördag</th>
                        <th style={{textAlign: "left"}}>Söndag</th>
                    </tr>
                    </thead>
                    <tbody style={{fontSize: "16px", color: "#000", border: "0px", margin: "0px", padding: "0px", borderBottom: "1px solid #ccc"}}>
                    { this.state.rta.map((row, key) =>
                    <tr key={key} onDoubleClick={() => this.rtp_edit(row) } style={ this.rt_style(row) }>
                        <td>{ row.n1rumstyp }</td>
                        <td>{ row.n1todatum }</td>
                        <td>{ row.n1pers }</td>
                        <td>{ this.rt_pris(1, row.n1pris) }</td>
                        <td>{ this.rt_pris(2, row.n1pris) }</td>
                        <td>{ this.rt_pris(3, row.n1pris) }</td>
                        <td>{ this.rt_pris(4, row.n1pris) }</td>
                        <td>{ this.rt_pris(5, row.n1pris) }</td>
                        <td>{ this.rt_pris(6, row.n1pris) }</td>
                        <td>{ this.rt_pris(7, row.n1pris) }</td>
                    </tr>
                    )}
                    </tbody>
                </table>
             </div>
            </div>
        : null }

        { this.state.part == "03" ?
            <div className="">
            <div className="flex-row" style={{padding:"5px",height:"35px"}}>
                    <div className="flex-col link_14" onClick={() => this.di_add() }>
                        LÄGG TILL
                    </div>
                <div className="flex"></div>
                    <div className="flex-col link_14">
                        <div>UPPDATERA</div>
                    </div>
            </div>
            <div className="divider_orange"></div>
            <div className="flex flex-col" style={{border: "0px", margin: "0px", padding: "0px"}}>
                <table className="table" style={{border: "0px", margin: "0px", padding: "0px"}}>
                    <thead style={{fontSize: "12px", fontWeight: "normal", color: "#080", border: "0px", margin: "0px", padding: "0px"}}>
                    <tr>
                        <th style={{textAlign: "left"}}>Trans</th>
                        <th style={{textAlign: "left"}}>Dag</th>
                        <th style={{textAlign: "left"}}>Belopp</th>
                        <th style={{textAlign: "left"}}>Procent</th>
                        <th style={{textAlign: "left"}}>Ford</th>
                        <th style={{textAlign: "left"}}>Status</th>
                    </tr>
                    </thead>
                    <tbody style={{fontSize: "16px", color: "#000", border: "0px", margin: "0px", padding: "0px", borderBottom: "1px solid #ccc"}}>
                    { this.state.dia.map((row, key) =>
                    <tr key={key} onDoubleClick={() => this.di_edit(row) } style={ this.di_style(row) }>
                        <td>{ row.n2transtyp }</td>
                        <td>{ row.n2dag }</td>
                        <td>{ utils.toPris( row.n2belopp ) }</td>
                        <td>{ utils.toNum( row.n2procent ) }</td>
                        <td>{ row.n2fordn }</td>
                        <td>{ row.n2sign }</td>
                    </tr>
                    )}
                    </tbody>
                </table>
             </div>
            </div>
        : null }

        </div>
       <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <button className="btn btn-hw-red w250" onClick={() => this.pkat_del() } >
                TA BORT
            </button>
            <div className="flex-row">
            <button className="btn btn-hw-red w250" onClick={() => this.pkat_save() } >
                SPARA
            </button>
            </div>
        </div>
      </div>
            { this.state.wnds.rtpadd ? <Pkat_Rt_Add wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
            { this.state.wnds.rtpedit ? <Pkat_Rt_Upd wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
            { this.state.wnds.diedit ? <Pkat_Di_Upd wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }

      </div>
	;
	return html;
  };
}

export default Pkat_Open;