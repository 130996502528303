import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../../lib/net";
import Fr_Input from "../../../lib/ui/fr_input";

class Tro_Open extends React.Component {
  constructor(props, context) {
    super(props);

    this.pos = props.store.pos;
    this.state = {};
    this.state.ynq = [];
    this.state.sval = [];

    this.state.dao = {};
    this.state.aa = [
                {id: '01', text: 'AVD 01'},
                {id: '02', text: 'AVD 02'}
            ];
    this.state.cval = [
                {id: '001', text: 'ANTAL GER NYTT PRIS'},
                {id: '002', text: 'ANTAL GER RABATT PÅ PRIS'},
                {id: '003', text: 'PRISÄNDRING GENOM RABATT'}
            ];

    this.state.hpo = {};
    this.state.hap = {};
    this.state.vpo = props.vpo;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.happy();
  }
    cdi_init() {
        var self = this;
        var hpo = {};
        var hap = self.state.hap;

        if(hap.hap00) {
            hpo.q0pris2 = hap.hap00.q0pris2.formatpris();
            hpo.q0npris2 = hap.hap00.q0npris2.formatpris();
            hpo.q0pris3 = hap.hap00.q0pris3.formatpris();
            hpo.q0npris3 = hap.hap00.q0npris3.formatpris();
            hpo.q0pris4 = hap.hap00.q0pris4.formatpris();
            hpo.q0npris4 = hap.hap00.q0npris4.formatpris();
            hpo.q0pris5 = hap.hap00.q0pris5.formatpris();
            hpo.q0npris5 = hap.hap00.q0npris5.formatpris();
        }

        self.setState({hpo: hpo,  md_init: true});
    }
    cdi_store() {
        var self = this;
        var hap = self.state.hap;
        var hpo = self.state.hpo;

        if(!hap.hap00) {
            hap.hap00 = {};
        }
        hap.hap00 = {};
        hap.hap01 = {};
        hap.hap02 = {};
        hap.hap03 = {};

        hap.hap00.q0p = "Q";
        hap.hap00.q0kstns = this.state.pbo.plu00.p0kstns
        hap.hap00.q0katnr = this.state.pbo.plu00.p0katnr

        hap.hap00.q0pris2 = "";
        hap.hap00.q0npris2 = "";
        hap.hap00.q0pris3 = "";
        hap.hap00.q0npris3 = "";
        hap.hap00.q0pris4 = "";
        hap.hap00.q0npris4 = "";
        hap.hap00.q0pris5 = "";
        hap.hap00.q0npris5 = "";

        if(hpo.q0pris2) hap.hap00.q0pris2 = hpo.q0pris2.pris2cdi();
        if(hpo.q0npris2) hap.hap00.q0npris2 = hpo.q0npris2.pris2cdi();
        if(hpo.q0pris3) hap.hap00.q0pris3 = hpo.q0pris3.pris2cdi();
        if(hpo.q0npris3) hap.hap00.q0npris3 = hpo.q0npris3.pris2cdi();
        if(hpo.q0pris4) hap.hap00.q0pris4 = hpo.q0pris4.pris2cdi();
        if(hpo.q0npris4) hap.hap00.q0npris4 = hpo.q0npris4.pris2cdi();
        if(hpo.q0pris5) hap.hap00.q0pris5 = hpo.q0pris5.pris2cdi();
        if(hpo.q0npris5) hap.hap00.q0npris5 = hpo.q0npris5.pris2cdi();

        self.setState({hap: hap});
    }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    tab_style(tabid) {
      var css = {};
      css.display = "none";
      if(this.state.tabval === tabid) css.display = "block";
      return css;
    }
    tab_head(tabid) {
      var css = {};
      css.background = "#fff";
      css.color = "#000";
      if(this.state.tabval === tabid) {
          css.background = "#080";
          css.color = "#fff";
      }
      return css;
    }
    grp_style(row) {
      var css = {};
      css.background = "#008";
      if(this.state.gnr === row.vgrupp) css.background = "#080";
      return css;
    }

    formchange (e) {
      const name = e.target.name;
      const value = e.target.value;
      var pbo = this.state.pbo;
      var na = name.split(".");
      pbo[na[0]][na[1]] = value;
      this.setState({pbo: pbo});
      //setState({[name]: value});
    }

    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    handleClose = () => {
		this.props.ctx.setState({ vipopen: false });
	};
    handleChange = (event, newValue) => {
        //alert(newValue);
        this.setState({ix: newValue});
      };
    toggle() {
      this.setState({modal: false});
	};

  submitHandler = event => {
    event.preventDefault();
    event.target.className += " was-validated";
  };

  changeHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  cssCols (cid) {
      var css = {};
      if(this.state.cols[cid]) css.width = this.state.cols[cid].toString() + "px";
      //if(cid == "c01") alert(JSON.stringify(this.state.cols[cid]));
      return css;
  }
  happy() {
    var self = this;
    var prm = {};
    prm.req = "pos.pos_plu.plu_happy";
    prm.avd = this.state.pbo.plu00.p0kstns;
    prm.plu = this.state.pbo.plu00.p0katnr;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        self.setState({hap: ret.rco }, function() { this.cdi_init(); });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  happy_save() {
    var self = this;

    this.cdi_store();

    var prm = {};
    prm.req = "pos.pos_plu.plu_happy_save";
    prm.avd = this.state.pbo.plu00.p0kstns;
    prm.plu = this.state.pbo.plu00.p0katnr;
    prm.hap00 = this.state.hap.hap00;
    prm.hap01 = this.state.hap.hap01;
    prm.hap02 = this.state.hap.hap02;
    prm.hap03 = this.state.hap.hap03;

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

		self.props.ctx.setState({ pluhappy: false });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }
    val_pris (name, value) {
        //alert(JSON.stringify(name));
        //alert(JSON.stringify(value));
      var pro = this.state.pro;
      pro[name] = value;
      this.setState({pro: pro});
      //setState({[name]: value});
    }
    hap_pris (name, value) {
        //alert(JSON.stringify(name));
        //alert(JSON.stringify(value));
      var hpo = this.state.hpo;
      //var na = name.split(".");
      //hap[na[0]][na[1]] = value;
      hpo[name] = value;
      this.setState({hpo: hpo});
      //setState({[name]: value});
    }

  render() {
    if(!this.props.show) return "";

    //alert(this.props.ctx.state.lro);

    var title = this.props.title;
    if(!title) title = "Knappar";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        <div className="m2_backdrop" />
        <div className="m2_dlg mx_70 flex-col" style={ this.dlg_style() } draggable="true" >
        <div className="mx_content flex">

            <div className="flex-row" style={{padding:"5px",height:"35px"}}>
                    <div className="flex-col flex">
                        <div>Transaktion:</div>
                    </div>
                    <div className="flex-col">
                        <div>UPPDATERA</div>
                    </div>
            </div>
            <div className="divider_orange"></div>

            <form className="cdi_f03">
                <div className="form-row flex-row">
                    <Fr_Input label="Produkt Pris" className="text-right w250" text={this.state.vpo.p0pris} getValue={(e)=> this.val_pris("p0pris", e) } placeholder="0.00" />
                    <Fr_Input label="Nytt Produkt Pris" className="text-right w250" text={this.state.vpo.p0nypris} getValue={(e)=> this.val_pris("p0nypris", e) } placeholder="0.00" />
                </div>
                <div className="divider_green"></div>
                <div className="form-row flex-row">
                    <Fr_Input label="Happy1 Pris" className="text-right w250" text={this.state.vpo.p0happy} getValue={(e)=> this.val_pris("p0happy", e) } placeholder="0.00" />
                    <Fr_Input label="Nytt Happy1 Pris" className="text-right w250" text={this.state.vpo.p1nyhappy} getValue={(e)=> this.val_pris("p1nyhappy", e) } placeholder="0.00" />
                </div>
                <div className="form-row flex-row">
                    <Fr_Input label="Happy2 Pris" className="text-right w250" text={this.state.vpo.q0pris2} getValue={(e)=> this.hap_pris("q0pris2", e) } placeholder="0.00" />
                    <Fr_Input label="Nytt Happy2 Pris" className="text-right w250" text={this.state.vpo.q0npris2} getValue={(e)=> this.hap_pris("q0npris2", e) } placeholder="0.00" />
                </div>
                <div className="form-row flex-row">
                    <Fr_Input label="Happy3 Pris" className="text-right w250" text={this.state.vpo.q0pris3} getValue={(e)=> this.hap_pris("q0pris3", e) } placeholder="0.00" />
                    <Fr_Input label="Nytt Happy3 Pris" className="text-right w250" text={this.state.vpo.q0npris3} getValue={(e)=> this.hap_pris("q0npris3", e) } placeholder="0.00" />
                </div>
                <div className="form-row flex-row">
                    <Fr_Input label="Happy4 Pris" className="text-right w250" text={this.state.vpo.q0pris4} getValue={(e)=> this.hap_pris("q0pris4", e) } placeholder="0.00" />
                    <Fr_Input label="Nytt Happy4 Pris" className="text-right w250" text={this.state.vpo.q0npris4} getValue={(e)=> this.hap_pris("q0npris4", e) } placeholder="0.00" />
                </div>
                <div className="form-row flex-row">
                    <Fr_Input label="Happy5 Pris" className="text-right w250" text={this.state.vpo.q0pris5} getValue={(e)=> this.hap_pris("q0pris5", e) } placeholder="0.00" />
                    <Fr_Input label="Nytt Happy5 Pris" className="text-right w250" text={this.state.vpo.q0npris5} getValue={(e)=> this.hap_pris("q0npris5", e) } placeholder="0.00" />
                </div>
            </form>

        </div>
        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.handleClose() } >
                STÄNG
            </button>
            <button className="btn btn-hw-green w250" onClick={() => this.happy_save() } >
                SPARA
            </button>
        </div>
      </div>
      </div>
	;
	return html;
  };
}

export default Tro_Open;