import React, { useState, useEffect, useContext } from 'react';
import {format, addDays, subDays} from "date-fns";

import * as net from "../../../../lib/net";
import Fr_Input from "../../../../lib/ui/fr_input";
import Fr_Select from "../../../../lib/ui/fr_select";
import Dt_Cal from "../../../../lib/ui/dt_cal";
import * as q from "../../../../lib/defer";

import Wnd_Error from "../../../../lib/ui/wnd_error";
import Wnd_Ok from "../../../../lib/ui/wnd_ok";
import Wnd_Info from "../../../../lib/ui/wnd_info";
import Wnd_Confirm from "../../../../lib/ui/wnd_confirm";

class Rv_Cancel extends React.Component {
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pms = props.store.pms;

    this.state = {};
    this.state.today = new Date();
    this.state.pka = [];

    this.state.gnr = "001";
    //this.state.lro = props.lro;
    this.state.ynq = [
                {id: '0', text: 'NEJ'},
                {id: '1', text: 'JA'}
            ];

    this.state.background = "#fff";

    this.state.msg = {};
    this.state.boknr = {};
    this.state.rvo = {};
    if(props.wnds) {
        this.state.boknr = props.wnds.boknr;
        this.state.rvo = props.wnds.rvo;
    }
    this.state.status = 0;

    this.state.pdf = 0;
    this.state.pdf_content = null;
    this.state.loading = 0;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    grp_style(row) {
      var css = {};
      css.background = "#008";
      if(this.state.gnr === row.vgrupp) css.background = "#080";
      return css;
    }

    rv_close = () => {
		//this.props.ctx.setState({ rvbradd: false });
        if(this.props.wnds.cb) {
            var ret = {};
            ret.ok = "000";
            ret.fkn = "close";
            this.props.wnds.cb(this.props.ctx, ret);
        }
        else this.props.ctx.setState({ wnds: {} });
	};
    wnd_close = () => {
		//this.props.ctx.setState({ rvbradd: false });
        if(this.props.wnds.cb) {
            var ret = {};
            ret.ok = "999";
            this.props.wnds.cb(this.props.ctx, ret);
        }
        else this.props.ctx.setState({ wnds: {} });
	};
  pdf_done (ret) {
    this.setState({loading: 0, pdf: 0, pdf_content: null });
  }
    handleChange = (event, newValue) => {
        //alert(newValue);
        this.setState({ix: newValue});
      };
    toggle() {
      this.setState({modal: false});
	};

  submitHandler = event => {
    event.preventDefault();
    event.target.className += " was-validated";
  };

  changeHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }

  rv_cancel() {
    var self = this;
    let pre = self.state.boknr.substr(0, 2);
    if(pre == pre.toLowerCase()) {
        //var msg = { confirm: true, text: "Skall Preliminär avbokas?", text0: "JA", text1: "NEJ", cb: "rv_close_do"};
        //self.setState({msg: msg });
        self.rv_cancel_prel();
        return;
    }
    else {
        self.rv_cancel_rv();
    }

  }

  rv_cancel_rv() {
    var self = this;

    var prm = {};
    prm.req = "pms.rv.rvo_cancel";
    //prm.fkn = "7217";
    prm.boknr = this.state.boknr;
    prm.rvo = this.state.rvo;
    //alert(JSON.stringify(prm));

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            self.toast("ERROR", "FEL VID AVBOKNING");
            return;
        }
        self.setState({status: "A" });
        /*
        if(self.props.wnds.cb) {
            var rsp = {};
            rsp.ok = "000";
            rsp.fkn = "cancel";
            self.props.wnds.cb(self.props.ctx, rsp);
        }
        else self.props.ctx.setState({ wnds: {} });
         */

    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
    return;
  }
  rv_cancel_prel() {
    var self = this;

    var prm = {};
    prm.req = "pms.rv_fkn.py_cancel";
    prm.boknr = this.state.boknr;
    //alert(JSON.stringify(prm));

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            self.toast("ERROR", "FEL VID AVBOKNING");
            return;
        }
        self.setState({status: "A" });
        /*
        if(self.props.wnds.cb) {
            var rsp = {};
            rsp.ok = "000";
            rsp.fkn = "cancel";
            self.props.wnds.cb(self.props.ctx, rsp);
        }
        else self.props.ctx.setState({ wnds: {} });
         */
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //alert(JSON.stringify(txt));
    });
    return;
  }

  toast(type, txt) {
        var self = this;
        var defer = q.defer();

        var msg = {};
        msg.text = txt;
        if(type == "OK") msg.ok = true;
        if(type == "ERROR") msg.error = true;

        self.setState({msg: msg });
        setTimeout(function(){
            self.setState({msg: {} });
            //self.props.ctx.setState({ pluopen: false });
            //self.props.ctx.plu_upd(self.props.ctx, plubuf);
            var rsp = {};
            defer.resolve(rsp);
        }, 2000);
  }

  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "Delbokning";
    //if(props.kro) alert(props.kro.krr00.kid);
    const popover = {
      position: 'absolute',
      top: '50px',
      zIndex: '2',
    }
    const cover = {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    }

	var html =
        <div className="mx_base">
        <div className="m2_backdrop" />
        <div className="m2_dlg mx_70 flex-col" style={ this.dlg_style() } draggable="true" >

        <div className="mx_h01">
            <div>AVBOKNING</div>
        </div>
        <div className="mx_content flex">

            <div className="flex-row" style={{padding:"5px",height:"35px"}}>
                    <div className="flex-col flex">
                        <div></div>
                    </div>
                    <div className="flex-col">
                        <div></div>
                    </div>
            </div>
            <div className="divider_orange"></div>

            { (this.state.status == "0") ?
            <div className="flex flex-cc">
                <div className="flex-row flex-cc py-2">
                    <div className="txt_18">SKALL BOKNING : { this.state.boknr }</div>
                </div>
                <div className="flex-row flex-cc py-2">
                    <div className="txt_18">AVBOKAS?</div>
                </div>
                <div className="divider_green"></div>
                <button className="btn btn-hw-red w250" onClick={() => this.rv_cancel() } >
                   AVBOKA BOKNING
                </button>
            </div>
            :
            <div className="flex">
                <div className="flex-row py-2">
                    <div className="txt_18">BOKNING AVBOKAD : { this.state.boknr }</div>
                </div>
                <div className="divider_green"></div>
            </div>
             }

        </div>

        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                AVBRYT
            </button>
            <div className="flex-row">
            <button className="btn btn-hw-red w250" onClick={() => this.rv_close() } >
               STÄNG
            </button>
            </div>
        </div>
      </div>
        <Wnd_Error msg={this.state.msg} ctx={this} />
        <Wnd_Ok msg={this.state.msg} ctx={this} />
        <Wnd_Info msg={this.state.msg} ctx={this} />
        <Wnd_Confirm msg={this.state.msg} ctx={this} />
      </div>
	;
	return html;
  };
}

export default Rv_Cancel;