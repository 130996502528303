import React, { useState, useEffect, useContext } from 'react';
import {withRouter} from "../../lib/react/withRouter";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Bi_Bo extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, tra: [] };
    this.state.seldate = new Date();
    this.state.avd = "01";
    this.state.paa = [];
    this.state.aa = [
                {id: '01', text: 'AVD 01'},
                {id: '02', text: 'AVD 02'}
            ];
    //context.posrend = this;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

  fknGo(url) {
    //alert(row.href);
    this.props.navigate(url);
  }

  render() {
    return (
    <div className="web-sub">
    <h3>BACKOFFICE</h3>

    <div className="web-p01 flex">

    <div className="web-row">
    <div className="web-col flex">
        <div className="web-btn" onClick={() => this.fknGo("/pos/bo/konto") } >
            <div>KONTOPLAN</div>
            <div>Definition av konton</div>
        </div>
        <div className="web-btn" onClick={() => this.fknGo("/pos/bo/deps") } >
            <div>AVDELNINGAR</div>
            <div>Avdelningsregister</div>
        </div>
        <div className="web-btn" onClick={() => this.fknGo("/pos/bo/vgrps") } >
            <div>VARUGRUPPER</div>
            <div>Varugruppsregister</div>
        </div>
    </div>
     <div className="web-col flex" style={{padding: "0px 10px"}}>
        <div className="web-btn" onClick={() => this.fknGo("/pos/bo/pays") } >
            <div>BETALMEDEL</div>
            <div>Betalmedelsregister</div>
        </div>
        <div className="web-btn" onClick={() => this.fknGo("/pos/bo/tax") } >
            <div>MOMS</div>
            <div>Momsregister</div>
        </div>
        <div className="web-btn" onClick={() => this.fknGo("/pos/bo/cur") } >
            <div>VALUTA</div>
            <div>Definierad valuta med inlagd kurs</div>
        </div>
   </div>
     <div className="web-col flex">
        <div className="web-btn" onClick={() => this.fknGo("/pos/bo/cas") } >
            <div>KASSÖRER</div>
            <div>Kassörsregister med koppling</div>
        </div>
        <div className="web-btn" onClick={() => this.fknGo("/pos/bo/tdef") } >
            <div>TERMINAL DEFINITION</div>
            <div>Definition av terminaler</div>
        </div>
        <div className="web-btn" onClick={() => this.fknGo("/pos/bo/tcfg") } >
            <div>TERMINAL KONFIG</div>
            <div>Uppsättning av terminaler</div>
        </div>
   </div>
   </div>

    </div>

    </div>
  );
    }
}
export default withRouter(Bi_Bo);
