import React, { useState, useEffect, useContext } from 'react';

import * as net from "../lib/net";
import {Route, Routes} from "react-router-dom";

import Fsg_Home from "./fsg/home";
import Fsg_Trs from "./fsg/trs";
import Fsg_Hh from "./fsg/hh";
import Fsg_Units from "./fsg/units";

import ddo from "../ddo";
import {withRouter} from "../lib/react/withRouter";
import sso from "../sso";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Cas_Fsg extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, tra: [] };
    this.state.seldate = new Date();
    this.state.unit = null;
    //this.state.units = [];

      this.state.una = Object.values(ddo.cfg.units);
      this.state.uno = ddo.cfg.units;

    //context.posrend = this;
    let cdat = new Date();
    let formatted_date = cdat.getFullYear() + "-" + (cdat.getMonth() + 1) + "-" + cdat.getDate();
    this.state.today = formatted_date;
    this.state.datum = formatted_date;
      var po = {};
      po.trs = { "fid": "f06", "url": "/cas/fsg/trs", "name": "Transaktioner", "active": false };
      po.unit = { "fid": "f06", "url": "/cas/fsg/unit", "name": "Enhetfsg", "active": false };
      po.hh = { "fid": "f06", "url": "/cas/fsg/hh", "name": "Timfsg", "active": false };
      this.state.po = po;
      this.state.pa = [];
      this.state.pa = Object.values(po);
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

    fknGo(row) {
        sso.seo.sfkn = row;
        this.props.navigate(row.url);
    }
    br_css(row) {
        var css = {};
        if(sso.seo.sfkn && (row.url == sso.seo.sfkn.url)) css.color = "#080";
        //var brc = false;
        //if(row.url == sbo.state.url) brc = true;
        return css;
    }

  render() {
    return (
        <div className="web-app">
            { this.props.store.cfg.bread ?
                <div className="web-bread">
                    <div className="breadcrumb">
                        { this.state.pa.map((row, key) =>
                            <div key={key} className="breadcrumb-item" style={this.br_css(row)} onClick={() => this.fknGo(row)}>{row.name}</div>
                        )}
                        <div className="flex"></div>
                        <div className="cdigreen">{ sso.seo.sfkn ? sso.seo.sfkn.name : null }</div>
                    </div>
                </div>
                : null }
        <Routes>
            <Route path="trs" element={ <Fsg_Trs store={this.webStore} />} />
            <Route path="unit" element={ <Fsg_Units store={this.webStore} />} />
            <Route path="hh" element={ <Fsg_Hh store={this.webStore} />} />
            <Route path="*" element={ <Fsg_Home store={this.webStore} />} />
        </Routes>
    </div>
  );
}
}
export default withRouter(Cas_Fsg);

/*
    <td>{ this.state.uno[row.r0cstplc].text }</td>
*/