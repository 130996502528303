import React, { useState, useEffect, useContext } from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";

import Dsi_Top from './com/dsi_top.js';
import Dsi_Mnu from './com/dsi_mnu.js';

import Dsi_Dash from './dash.js';
import Dsi_Media from './media.js';
import Dsi_Ecom from './ecom.js';
import Dsi_Menu from './mnu.js';
import Dsi_Fkn from './fkn.js';
import Dsi_Bo from './bo.js';

import Dsi_Tvm from './pub/tvm.js';
import Dsi_Mob from './pub/mob.js';

class Dsi extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = {};
  }
  ddo() {
    alert("POS" + this.state.pgid );
    this.setState({ status: 1 });
  }

  render() {
    return (
    <div className="web-app">
    <div className="web-top">
        <Dsi_Top store={this.webStore} />
    </div>
    <div className="web-main">

    <div className="web-row" style={{ height:"100%" }}>

    <div className="web-side">
        <Dsi_Mnu store={this.webStore} />
    </div>

    <div className="web-body">
        <Routes>
          <Route path="media/*" element={ <Dsi_Media store={this.webStore} />} />
          <Route path="ecom/*" element={ <Dsi_Ecom store={this.webStore} />} />
          <Route path="mnu/*" element={ <Dsi_Menu store={this.webStore} />} />
          <Route path="fkn/*" element={ <Dsi_Fkn store={this.webStore} />} />
          <Route path="bo/*" element={ <Dsi_Bo store={this.webStore} />} />
          <Route path="ct24/*" element={ <Dsi_Mob store={this.webStore} />} />
          <Route path="tvm/*" element={ <Dsi_Tvm store={this.webStore} />} />
          <Route path="mob/*" element={ <Dsi_Mob store={this.webStore} />} />
          <Route path="*" element={ <Dsi_Dash store={this.webStore} />} />
        </Routes>
    </div>

    </div>

    </div>
    <div className="web-foot">
        <div>Digital Signage : { this.state.status }</div>
    </div>
    </div>
  );
}
}
//Sys.contextType = ADOContext;

export default Dsi;
