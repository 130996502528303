import React, { useState, useEffect, useContext } from 'react';
import {format} from "date-fns";

import * as net from "../../lib/net";
import * as utils from "../../assets/utils";
import Dt_Cal from "../../lib/ui/dt_cal";
import Td_Sel from "../../lib/ui/td_sel";
import Und_Edit from "./und/und_edit";
import Und_Period from "./und/und_period";
import Inv_Rebuild from "./und/inv_rebuild";
import Dd_Sel from "../../lib/ui/dd_sel";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Fo_Und extends React.Component {
  constructor(props, context) {
    super(props);

      this.dt_fd = React.createRef();
      this.dt_td = React.createRef();

    this.store = props.store;

    this.state = {};
    this.state.seldate = new Date();
    this.state.avd = "01";
    this.state.uaa = [];
    this.state.uas = [];
      this.state.fao = {};
      this.state.tot = {};

      this.state.today = new Date();
      var dts = format(this.state.today, "yyyy-MM-dd");
      this.state.fdat = dts;
      this.state.tdat = dts;
      this.state.sortid = "";
      this.state.msg = {};
      this.state.wnds = {};
      this.state.sz_val = "";

      this.selitems = [
          {fkn: 'edit', text: 'ÖPPNA'},
          {fkn: 'deactivate', text: 'AVAKTIVERA'}
      ];
      this.tya = [
          {fkn: '0', text: 'ÖPPNA'},
          {fkn: '1', text: 'STÄNGDA'},
          {fkn: '21', text: 'ALLA'}
      ];
      this.fknitems = [
          {fkn: 'rebuild', text: 'ÅTERSKAPA UNDERLAG'},
          {fkn: 'print', text: 'SKRIVUT UNDERLAG'}
      ];
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.unds();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      css.overflow = "auto";
      return css;
  }
    th_style(id) {
        var css = {};
        css.cursor = "pointer";
        if(id == this.state.sortid) css.color = "#080";
        return css;
    }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }
    dt_fdat(e) {
        //alert(JSON.stringify(e));
        var dts = format(e, "yyyy-MM-dd");
        this.setState({ fdat: dts });
    }
    dt_tdat(e) {
        //alert(JSON.stringify(e));
        var dts = format(e, "yyyy-MM-dd");
        this.setState({ tdat: dts });
    }
    oneday(e) {
        //alert(JSON.stringify(e));
        //this.setState({ tdat: this.state.fdat });
        this.dt_td.current.updateDt(this.state.fdat);
    }
    addday(e) {
        //alert(JSON.stringify(e));
        //this.setState({ tdat: this.state.fdat });
        this.dt_fd.current.updateAdd();
        this.dt_td.current.updateAdd();
    }
    subday(e) {
        //alert(JSON.stringify(e));
        //this.setState({ tdat: this.state.fdat });
        this.dt_fd.current.updateSub();
        this.dt_td.current.updateSub();
    }

    und_edit(row) {
        var self = this;

        var wnds = {};
        wnds.edit = 1;
        wnds.datum = row.datum;
        wnds.notanr = row.notanr;
        wnds.und = row;
        wnds.cb = this.cb_undedit;
        this.setState({ wnds: wnds });
    }
    cb_undedit(ctx, rsp) {
        ctx.setState({ wnds: {} });
        ctx.unds();
    }
    und_period() {
        var self = this;

        var wnds = {};
        wnds.period = 1;
        wnds.cb = this.cb_undper;
        this.setState({ wnds: wnds });
    }
    cb_undper(ctx, rsp) {
        ctx.setState({ wnds: {} });
        ctx.unds();
    }

    unds() {
        var self = this;
        var prm = {};
        prm.req = "fakt.fakt_pos.underlag_open";
        prm.status = "0";

        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret.rca));

            var tot = {};
            tot.antal = 0;
            tot.belopp = 0;
            for(var row of ret.rca) {
                tot.antal = tot.antal + 1;
                if(row.belopp && (row.belopp != null)) {
                    var belopp = row.belopp;
                    if(belopp.indexOf("-") != -1) {
                        belopp = belopp.replace("-", "");
                        //alert(JSON.stringify(belopp))
                    }
                    tot.belopp = tot.belopp + (belopp * 1);
                }
                //alert(JSON.stringify(row.belopp));
            }
            //alert(JSON.stringify(tot.belopp));

            self.setState({ uaa: ret.rca, uas: ret.rca, tot: tot }, function () {
                self.syncTb("undTable");
            });
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
            //gda.wndError(txt);
        });
    }
    unds_period() {
        var self = this;
        var prm = {};
        prm.req = "fakt.fakt_pos.underlag";
        prm.datum = this.state.fdat;
        prm.fdat = this.state.fdat;
        prm.tdat = this.state.tdat;
        prm.avd = this.state.avd;
        prm.status = "0";

        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret.rca));

            self.setState({uaa: ret.rca });
            self.syncTb("undTable");
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
            //gda.wndError(txt);
        });
    }
    syncTb(tb){
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
            tdElements = tbel.getElementsByTagName('td');
        if(tdElements.length < 1 ) return;

        for (let i = 0; i < thElements.length; i++) {
            if(!tdElements[i]) continue;
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
                width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
    tb_sort(typ) {
        var self = this;
        var poa = self.state.poa;
        var paa = self.state.paa;
        var sz = this.state.sz_val;

        if(typ == "plu") {
            //paa.sort((a,b) => (a.plu00.p0katnr > b.plu00.p0katnr) ? 1 : ((b.plu00.p0katnr > a.plu00.p0katnr) ? -1 : 0));
        }
        self.setState({paa: paa, sortid: typ });
    }
    sel_row_fkn(row, sel) {
        //alert(JSON.stringify(fkn));
        if(sel.fkn == "open") {
            this.und_edit(row);
        }
        if(sel.fkn == "deact") {
            this.inv_deact();
        }
    }
    inv_deact() {
        var self = this;
        var prm = {};
        prm.req = "fakt.fakt_pos.inv_deact";
        prm.wfkt00 = this.state.und.fu;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.rco));

            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    inv_rebuild_do() {
        var self = this;

        var prm = {};
        prm.req = "fakt.fakt_pos.inv_rebuild";
        prm.avd = "04";
        prm.datum = "220328";

        net.sio_req(prm)
        .then(function(ret){
            alert(JSON.stringify(ret));

        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }
    inv_rebuild() {
        var self = this;

        var wnds = {};
        wnds.rebuild = 1;
        //wnds.cb = this.cb_undper;
        this.setState({ wnds: wnds });
    }

    sel_fkn(sel) {
        //alert(JSON.stringify(fkn));
        if(sel.fkn == "rebuild") {
            this.inv_rebuild();
        }
    }
    kundtext(row) {
        var text = "";
        if(row.krr && row.krr.krr00) text = row.krr.krr00.company;
        return text;
    }
    sz_style() {
        var css = {};
        css.height = "40px";
        css.lineHeight = "40px";
        css.padding = "0px 10px";
        css.fontSize = "12px";
        css.cursor = "pointer";
        return css;
    }

    sz_keydown (e) {
    //e.preventDefault();
      const key = e.key;
      const keyCode = e.keyCode;

      if(keyCode == 13) {
          this.tb_filtr();
      };
      if(keyCode == 27) {
          this.setState({sz_val: ""});
      };
  }
  sz_change (e) {
      const name = e.target.name;
      const value = e.target.value;
      this.setState({sz_val: value});
      //setState({[name]: value});
  }
  tb_filtr() {
    var self = this;
    var uas = self.state.uas;
    var sz = this.state.sz_val;

    if(sz.length < 1) {
      self.setState({ uaa: uas });
        return;
    }
    sz = sz.toUpperCase();
    var uaa = uas.filter(function(row) {
          var brc = false;
          if(row.datum.toUpperCase().includes(sz)) brc = true;
          if(row.notanr.toUpperCase().includes(sz)) brc = true;
          if(row.belopp.toUpperCase().includes(sz)) brc = true;
          if(row.krr && row.krr.krr00) {
            if(row.krr.krr00.company.toUpperCase().includes(sz)) brc = true;
          }
          return brc;
    });
    self.setState({uaa: uaa });
    }

  render() {
    return (
    <div className="web-app">

    <div className="flex-row">
        <button className="btn btn-hw-orange w250" onClick={() => this.und_period() } aria-label="System" >
            Period / Underlag
        </button>

        <div className="flex"></div>
        <input className="txt sztxt" value={this.state.sz_val} placeholder="Ange söktext..."  style={ this.sz_style() } onChange={(e) => this.sz_change(e)} onKeyDown={(e) => this.sz_keydown(e)} />
        <button className="btn btn-hw-blue w250" onClick={() => this.unds() } aria-label="System" >
            Uppdatera
        </button>
        <Dd_Sel items={ this.fknitems } cls="web-top-icon" getValue={(e)=> this.sel_fkn(e)} />
    </div>
    <div className="divider"></div>

    <div id="undTable" className="flex">
        <table className="table table-bordered table-hover tb " style={{marginBottom: "0px"}}>
            <thead>
            <tr>
                <th style={ this.th_style("datum") } onClick={() => this.tb_sort("datum") }>Datum</th>
                <th style={ this.th_style("notanr") } onClick={() => this.tb_sort("notanr") }>Notanr</th>
                <th style={ this.th_style("belopp") } onClick={() => this.tb_sort("belopp") }>Belopp</th>
                <th style={ this.th_style("tkn") } onClick={() => this.tb_sort("tkn") }>Tecken</th>
                <th style={ this.th_style("kundnr") } onClick={() => this.tb_sort("kundnr") }>Kundnr</th>
                <th style={ this.th_style("text") } onClick={() => this.tb_sort("text") }>Text</th>
                <th style={ this.th_style("status") } onClick={() => this.tb_sort("status") }>Status</th>
                <th></th>
            </tr>
            </thead>
        </table>
        <div style={ this.tb_style() }>
            <table className="table table-bordered table-hover tb" style={{marginBottom:"0px"}}>
                <tbody>
            { this.state.uaa.map((row, key) =>
            <tr key={key} onDoubleClick={() => this.und_edit(row) }>
                <td className="text-center">{ utils.toDate( row.datum ) }</td>
                <td className="text-center">{ utils.toNum( row.notanr ) }</td>
                <td className="text-right">{ utils.toPris( row.belopp ) }</td>
                <td>{ utils.toLang( row.tkn ) }</td>
                <td>{ utils.toNum( row.fu.kundnr ) }</td>
                <td>{ this.kundtext( row ) }</td>
                <td>{ row.status }</td>
                <td>
                    <Td_Sel items={ this.selitems } getValue={(e)=> this.sel_row_fkn(row, e)} />
                </td>
            </tr>
            )}
            </tbody>
        </table>
        </div>
    </div>
        <div className="flex-row flex-space-between">
            <div className="flex-col">
                <div className="cdi-label">Antal</div>
                <div className="">{ utils.toNum( this.state.tot.antal ) }</div>
            </div>
            <div className="flex-col">
            </div>
            <div className="flex-col">
                <div className="cdi-label">Summa</div>
                <div className="">{ utils.toPris( this.state.tot.belopp ) }</div>
            </div>
        </div>

        { this.state.wnds.edit ? <Und_Edit wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.wnds.period ? <Und_Period wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.wnds.rebuild ? <Inv_Rebuild wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }

    </div>
  );
    }
}
export default Fo_Und;
