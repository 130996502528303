import React, { useState, useEffect, useContext } from 'react';
import { Route, Routes } from "react-router-dom";

import Ecom_Top from './com/ecom_top.js';
import Ecom_Mnu from './com/ecom_mnu.js';

import Ecom_Home from './home.js';
import Ecom_Dash from './dash.js';
import Ecom_Op from './op.js';
import Ecom_Fo from './fo.js';
import Ecom_Reg from './reg.js';
import Ecom_Eko from './eko.js';
import Ecom_Cms from './cms.js';
import Ecom_Bo from './bo.js';

import * as net from "../lib/net";
import ddo from '../ddo.js'
import sso from "../sso";
import {withRouter} from "../lib/react/withRouter";

class Ecom extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pos = props.store.pos;
    this.state = {};
    this.state.mnu_show = true;
    this.state.deps = [];
    //context.posrend = this;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {

    var deps = [];
    deps.push( {"id": "01", "text": "DEP X1" } );
    deps.push( {"id": "02", "text": "DEP X2" } );
    deps.push( {"id": "03", "text": "DEP X3" } );

    //this.props.store.seo.cs = "5";
    ///this.props.store.seo.unit = "BSENHET";

    sso.sdo.sfkn = {};
    sso.sdo.sfkn.text = "";

    var pos = {};
    pos.deps = deps;
    pos.vgrps = [];
    pos.plus = [];
    this.props.store.pos = pos;
    //alert(JSON.stringify(pos));
    this.pos_init();
  }

  fknGo(url) {
    this.props.navigate(url);
  };

  ddo() {
    //alert("POS" + this.state.pgid );
    //this.setState({ status: 1 });
  }

  pos_init() {
    var self = this;

    ddo.cache.deps = {};
    ddo.cache.vgrps = [];
    ddo.cache.mkods = {};

    var req = {};
    req.req = "pos.pos_fkn.pos_init";

    net.sio_req(req)
        .then(function(ret){
          //alert(JSON.stringify(ret));

          if(!self.props.store.pos) self.props.store.pos = {};
          self.props.store.pos.deps = ret.rco.deps;
          self.props.store.pos.vgrps = ret.rco.vgrps;
          if(ret.rco.depo) ddo.cache.deps = ret.rco.depo;
          if(ret.rco.vgrps) ddo.cache.vgrps = ret.rco.vgrps;
          if(ret.rco.mkods) ddo.cache.mkods = ret.rco.mkods;

          var avd = Object.keys(ddo.cache.deps).sort()[0];
          if((avd * 1) > (ddo.cache.avd * 1)) {
            ddo.cache.avd = avd;
          }
        })
        .catch(function(e){
          var txt = JSON.stringify(e);
          //alert(JSON.stringify(txt));
        })
  }

  render() {
    return (
    <div className="web-app">
    <div className="web-top">
        <Ecom_Top store={this.store} />
    </div>
    <div className="web-main">

    <div className="web-row" style={{ height:"100%" }}>

    <div className="web-side">
        <Ecom_Mnu store={this.store} />
    </div>

    <div className="web-body">
        <Routes>
          <Route path="dash" element={ <Ecom_Dash store={this.store} />} />
          <Route path="op/*" element={ <Ecom_Op store={this.store} />} />
          <Route path="fo/*" element={ <Ecom_Fo store={this.store} />} />
          <Route path="reg/*" element={ <Ecom_Reg store={this.store} />} />
          <Route path="eko/*" element={ <Ecom_Eko store={this.store} />} />
          <Route path="cms/*" element={ <Ecom_Cms store={this.store} />} />
          <Route path="bo/*" element={ <Ecom_Bo store={this.store} />} />
          <Route path="home/*" element={ <Ecom_Home store={this.store} />} />
          <Route path="*" element={ <Ecom_Dash store={this.store} />} />
        </Routes>
    </div>

    </div>

    </div>
    <div className="web-foot">
        <div>E-Handel : { this.state.status }</div>
    </div>
    </div>
  );
}
}
//Sys.contextType = ADOContext;

export default withRouter(Ecom);
