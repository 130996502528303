import React, { useState, useEffect, useContext } from 'react';
import { observer } from 'mobx-react'

import * as net from "../../lib/net";
import sso from "../../sso";

class Csc_UC extends React.Component {
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pos = props.store.pos;

    this.state = { pgid: 11, tra: [] };
    this.state.seldate = new Date();
    this.state.avd = "01";
    this.state.csa = [];
    this.state.aa = [];
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      //this.units();
      //this.unit_init();
      //this.setState({ csa: sso.sdo.csa });
      //alert("UNITS" + JSON.stringify(sso.sdo));
  }
    _csa() {
      var csa = [];
      if(sso.sdo.csa) csa = sso.sdo.csa;
      return csa;
    }
    unit_init() {
        var csa = [
            //{ id:"0", typ:"0", text: "INGET CENTRALSYSTEM"},
            { c0lopnr:"1", c0sid:"S0003201", c0text: "SYSTEM 1", c0typ: ""},
            { c0lopnr:"3", c0sid:"S0003202", c0text: "SYSTEM 2", c0typ: ""},
            { c0lopnr:"5", c0sid:"S0003203", c0text: "SYSTEM 3", c0typ: ""}
        ];
        this.setState({ csa: csa });
    }
    body_style() {
        var css = {};
        css.height = window.innerHeight - 280;
        return css;
    }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }

  fknGo(url) {
    //alert(row.href);
    this.props.history.push(url);
  }
  units() {
    var self = this;

    //alert(JSON.stringify(this.store.seo));
    var prm = {};
    prm.req = "cs.cs_units.csa";
    prm.csid = this.store.seo.csid;
    //prm.cs = "0012";

    net.ccs_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            alert(JSON.stringify(ret));
            return;
        }
        var sa = ret.rca;
        //alert(JSON.stringify(sa));
        self.setState({ csa: sa }, () => {
        });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

  render() {
    return (
    <div className="web-app">
        <div className="flex-row flex-cc">
            <h3>Arbete pågår</h3>
        </div>

    <div className="web-p01 flex">
        <div className="flex-row flex-cc">
            <h3>Funktionen uppdateras, klart inom kort!</h3>
        </div>
        <div className="flex-row flex-cc">
            <h3>MVH</h3>
        </div>
    </div>

    </div>
  );
    }
}
export default observer(Csc_UC);
