import React, { useState, useEffect, useContext } from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import { observer } from 'mobx-react'

import SDOContext from '../sdo.js'
import ddo from '../ddo.js'

import Tmpl_Top from './com/tmpl_top.js';
import Tmpl_Mnu from './com/tmpl_mnu.js';
import Tmpl_Foot from './com/tmpl_foot.js';

import Tmpl_Home from './home.js';
import Tmpl_Dash from './dash.js';
import Tmpl_Pos from './pos.js';
import Tmpl_Eko from './eko.js';
import Tmpl_Bo from './bo.js';
import Tmpl_Sys from './sys.js';

import * as net from "../lib/net";
import Tiden from "../lib/ui/tiden";
import {withRouter} from "../lib/react/withRouter";
import sso from "../sso";

function web01() {

    var html =
    <div className="web-content">
    </div>
    ;
    return html;
}

class Tmpl extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pos = props.store.pos;
    this.state = { pgid: 11, mnu_show: true, deps: [] };
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.props.fetchWells()
    //  .then(res => this.setState({ wells: res.wells }) )
    var deps = [];
    deps.push( {"id": "01", "text": "DEP X1" } );
    deps.push( {"id": "02", "text": "DEP X2" } );
    deps.push( {"id": "03", "text": "DEP X3" } );

    //this.props.store.seo.cs = "5";
    ///this.props.store.seo.unit = "BSENHET";

    var pos = {};
    pos.deps = deps;
    pos.vgrps = [];
    pos.plus = [];
    this.props.store.pos = pos;
    //alert(JSON.stringify(pos));
    this.tmpl_init();
    //ddo.cache.auth = "05";
    //ddo.cache.gtag = "UIK";
  }
    tmpl_init() {
        var self = this;

        //alert(JSON.stringify(sso.seo.auth));
        //alert(JSON.stringify(sso.seo.grouptag));
        //alert(JSON.stringify(sso.seo));

        ddo.cache.sid = sso.sdo.sid;
        if(!ddo.cache.sid) ddo.cache.sid = sso.seo.sid;

        ddo.cache.org = "";
        ddo.cache.ftg = "";
        ddo.cache.sidtxt = "";
        if(ddo.cache.sid == "S0001393") { ddo.cache.org = "KK"; ddo.cache.ftg = "Kungsbacka Kommun"; ddo.cache.sidtxt = "KFT"; }
        if(ddo.cache.sid == "S0001394") { ddo.cache.org = "KK"; ddo.cache.ftg = "Kungsbacka Kommun"; ddo.cache.sidtxt = "FG"; }
        if(ddo.cache.sid == "S0001395") { ddo.cache.org = "KK"; ddo.cache.ftg = "Kungsbacka Kommun"; ddo.cache.sidtxt = "TE"; }
        if(ddo.cache.sid == "S0001396") { ddo.cache.org = "KK"; ddo.cache.ftg = "Kungsbacka Kommun"; ddo.cache.sidtxt = "IF"; }
        if(ddo.cache.sid == "S0001397") { ddo.cache.org = "KK"; ddo.cache.ftg = "Kungsbacka Kommun"; ddo.cache.sidtxt = "GA"; }
        if(ddo.cache.sid == "S0000091") { ddo.cache.org = "KK"; ddo.cache.ftg = "Kungsbacka Kommun"; ddo.cache.sidtxt = "KFT"; }

        //if(ddo.cache.sid == "S0000091") { ddo.cache.org = "LU"; ddo.cache.ftg = "Lunds Universitet"; ddo.cache.sidtxt = "LU"; }
        if(ddo.cache.sid == "S0001384") { ddo.cache.org = "LU"; ddo.cache.ftg = "Lunds Universitet"; ddo.cache.sidtxt = "LU"; }

        ddo.cache.avd = sso.seo.avd;
        ddo.cache.auth = sso.seo.auth;
        ddo.cache.grouptag = sso.seo.grouptag;
        //alert(JSON.stringify(ddo.cache));

        if(ddo.cache.auth == "99") ddo.cache.auth = "05";
        //ddo.cache.auth = "05"; 
        //ddo.cache.auth = "39";
        //ddo.cache.grouptag = "ADM";
        //ddo.cache.auth = "05";

        ddo.cache.depo = {};
        ddo.cache.deps = [];
        ddo.cache.vgrps = [];
        ddo.cache.vga = [];
        ddo.cache.mkods = {};

        var req = {};
        req.req = "pos.pos_fkn.pos_init";

        net.sio_req(req)
            .then(function(ret){
                //alert(JSON.stringify(ret));

                if(!self.props.store.pos) self.props.store.pos = {};
                self.props.store.pos.deps = ret.rco.deps;
                self.props.store.pos.vgrps = ret.rco.vgrps;
                if(ret.rco.depo) ddo.cache.depo = ret.rco.depo;
                if(ret.rco.deps) ddo.cache.deps = ret.rco.deps;
                if(ret.rco.vgrps) ddo.cache.vgrps = ret.rco.vgrps;
                if(ret.rco.mkods) ddo.cache.mkods = ret.rco.mkods;

                var avd = Object.keys(ddo.cache.deps).sort()[0];
                if((avd * 1) > (ddo.cache.avd * 1)) {
                    ddo.cache.avd = avd;
                }
                ddo.cache.avdtxt = "";
                if(ddo.cache.depo[ddo.cache.avd]) {
                    var dpo = ddo.cache.depo[ddo.cache.avd];
                    //alert(JSON.stringify(dpo));
                    ddo.cache.avdtxt = dpo.text;
                }
                var a = [];
                a.push({ var00: { v0katnr: '000', v0text: 'ALLA VGRP' } });
                if(ddo.cache.vgrps) a = a.concat(ddo.cache.vgrps);
                ddo.cache.vga = a;
                //alert(JSON.stringify(ddo.cache.vgrps));
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
                //alert(JSON.stringify(txt));
            })
        //ddo.cache.auth = "09";
    }

  fknGo(url) {
    //var sdo = useContext(SDO);
    //alert(fkn);
    //var fkn = row.fid;
    //setState({ row : row,  ma : [] });
    //ctx.fid = fkn;

    //props.history.push(row.url);
    this.props.navigate(url);
   };

  ddo() {
    //alert("POS" + this.state.pgid );
    //this.setState({ status: 1 });
  }

    tech_init() {
    var self = this;

    ddo.cache.deps = {};
    ddo.cache.vgrps = [];
    ddo.cache.mkods = {};

	var req = {};
	req.req = "pos.pos_fkn.pos_init";

    net.sio_req(req)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(!self.props.store.pos) self.props.store.pos = {};
        self.props.store.pos.deps = ret.rco.deps;
        self.props.store.pos.vgrps = ret.rco.vgrps;
        if(ret.rco.depo) ddo.cache.deps = ret.rco.depo;
        if(ret.rco.vgrps) ddo.cache.vgrps = ret.rco.vgrps;
        if(ret.rco.mkods) ddo.cache.mkods = ret.rco.mkods;

        var avd = Object.keys(ddo.cache.deps).sort()[0];
        if((avd * 1) > (ddo.cache.avd * 1)) {
            ddo.cache.avd = avd;
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //alert(JSON.stringify(txt));
    })
    }

  render() {

    return (
    <div className="web-app">
    <div className="web-top">
        <Tmpl_Top store={this.store} />
    </div>
    <div className="web-main">

    <div className="web-row" style={{ height:"100%" }}>

    { this.props.store.cfg.mnu_show ?
    <div className="web-side">
        <Tmpl_Mnu store={this.store} />

    </div>
    : null }

    <div className="web-body">
          <Routes>
              <Route path="pos/*" element={ <Tmpl_Pos store={this.store} />} />
              <Route path="eko/*" element={ <Tmpl_Eko store={this.store} />} />
              <Route path="bo/*" element={ <Tmpl_Bo store={this.store} />} />
              <Route path="sys/*" element={ <Tmpl_Sys store={this.store} />} />
              <Route path="home" element={ <Tmpl_Home store={this.store} />} />
              <Route path="*" element={ <Tmpl_Dash store={this.store} />} />
          </Routes>
    </div>

    </div>

    </div>

        <Tmpl_Foot store={this.store} />

    </div>
  );
}
}
//Sys.contextType = ADOContext;

export default withRouter(observer(Tmpl));
