import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";
import Dt_Cal from "../../lib/ui/dt_cal";
import {Chart} from "chart.js";
import {format} from "date-fns";
import Tid_FT from "../../lib/ui/tid_ft";
import Fr_Select from "../../lib/ui/fr_select";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Bi_Cos extends React.Component {
  constructor(props, context) {
    super(props);

    this.my_bar = null;
    this.store = props.store;
    this.pos = props.store.pos;

    this.state = { pgid: 11, tra: [] };
    this.state.today = new Date();
    this.state.fdat = new Date(2021, 0, 1);
    this.state.tdat = new Date(this.state.today.getDate() - 1);
    this.state.avd = "01";
    this.state.eka = [];
    this.state.tpa = [];
    this.state.tpa = [
                {id: '0', text: 'OMSÄTTNING'}
            ];
    this.state.typ = "0";
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.st_oms();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

  st_period() {
    var self = this;

    //alert(JSON.stringify(this.state.typ));
    if(this.state.typ == "0") this.st_oms();
    if(this.state.typ == "1") this.st_oms();
  }

  st_omx() {
    var self = this;

    var fdat = format(this.state.fdat, "yyyy-MM-dd");
    var tdat = format(this.state.tdat, "yyyy-MM-dd");

    var prm = {};
    prm.req = "pos.pos_eko.sth_oms";
    prm.avd = this.state.avd;
    prm.fdat = fdat;
    prm.tdat = tdat;
    //alert(JSON.stringify(prm));

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        var sdo = ret.rco;
        var a = [];
        var keys = Object.keys(sdo.dto);
        for(var key of keys) {
            var o = sdo.dto[key];
            a.push( o );
        }
        a.push( sdo.tot );
        //self.setState({dto: dto, eka: a }, () => self.line_chart());
        self.setState({sdo: sdo, eka: a });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  st_oms() {
    var self = this;
    var fdat = format(this.state.fdat, "yyyy-MM-dd");
    var tdat = format(this.state.tdat, "yyyy-MM-dd");

    var prm = {};
    prm.req = "bi.bi_stat.cs_oms";
    prm.avd = this.state.avd;
    prm.fdat = fdat;
    prm.tdat = tdat;

    //gda.wndLoading("Hämtar");
    net.ccs_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        var sa = ret.rca;
        //alert(JSON.stringify(sa));
        self.setState({ eka: sa }, () => {
        });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }

    row_proc(row) {
      var proc = 0;
      if(this.state.sdo.tot.fsg > 0) {
          proc = (row.fsg / this.state.sdo.tot.fsg) * 100;
          proc = Math.round(proc);
      }
      return proc + " %";
    }
    row_wday(row) {
      var day = "";
      if(row.wday == 1) day = "Måndag";
      if(row.wday == 2) day = "Tisdag";
      if(row.wday == 3) day = "Onsdag";
      if(row.wday == 4) day = "Torsdag";
      if(row.wday == 5) day = "Fredag";
      if(row.wday == 6) day = "Lördag";
      if(row.wday == 7) day = "Söndag";
      return day;
    }
    bar_chart() {
      var lbls = [];
      var da = {};
      da.ink = [];
      da.ex = [];

      for(var row of this.state.eka) {
          if(row.grp == "tot") continue;
          lbls.push( row.text.toswe() );
          da.ink.push( row.belopp.cdipris() );
      }

        if(this.my_bar) this.my_bar.destroy();
        this.my_bar =  new Chart(document.getElementById("barchart"), {
            type: 'bar',
            data: {
				labels: lbls,
                datasets: [{
                    data: da.ink,
                    label: 'Belopp',
                    backgroundColor: "#4755AB",
                    borderWidth: 1,
                }]
            },
            options: {
                responsive: true,
                legend: {
                    display: false,
                    position: 'top'
                },
            }
        });
	};

  render() {
    return (
    <div className="web-p00 flex flex-col">

        <div className="web-row">
            <div className="txt_22">Kostnader</div>
        </div>
        <div className="divider_orange"></div>
    <div className="web-p01">
    <div className="flex-row">
        <Dt_Cal dat={this.state.fdat} getValue={(e)=> this.setState({fdat: e}) }/>
        <Dt_Cal dat={this.state.tdat} getValue={(e)=> this.setState({tdat: e}) }/>
        <Fr_Select label="" className="text-left w250" text={this.state.typ} items={this.state.tpa} getValue={(e)=> this.setState({ "typ": e }) } />
        <select className="form-control w250 hide" placeholder="Välj avdelning">
            { this.pos.deps.map((row, key) =>
            <option key={key} value={row.id}>{row.text}</option>
            )}
        </select>
        <div className="flex"></div>

        <button className="btn btn-hw-blue w150" onClick={() => this.st_oms() } aria-label="System" >
            UPPDATERA
        </button>
    </div>
    <div className="divider_orange"></div>


    <div className="web-row">
        <table className="table table-bordered table-hover tb fixed_header">
            <thead>
            <tr>
                <th>Text</th>
                <th>Brutto</th>
                <th>Moms</th>
                <th>Netto</th>
                <th>Bet</th>
                <th>Diff</th>
                <th>Rabatt</th>
                <th>Resultat</th>
            </tr>
            </thead>
            <tbody style={ this.tb_style() }>
            { this.state.eka.map((row, key) =>
            <tr key={ key } id={ row.grp } onDoubleClick={() => this.st_edit(row) }>
                <td>{ row.text }</td>
                <td class="text-right">{ row.tot.brutto.cdipris() }</td>
                <td class="text-right">{ row.tot.moms.cdipris() }</td>
                <td class="text-right">{ row.tot.netto.cdipris() }</td>
                <td class="text-right">{ row.tot.bet.cdipris() }</td>
                <td class="text-right">{ row.tot.diff.cdipris() }</td>
                <td class="text-right">{ row.tot.rab.cdipris() }</td>
                <td class="text-right">{ row.tot.resultat.cdipris() }</td>
            </tr>
            )}
            </tbody>
        </table>
    </div>

    </div>
    </div>
  );
    }
}
export default Bi_Cos;
