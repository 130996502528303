import React, { useState, useEffect, useContext } from 'react';
import {addDays, format, parseISO} from "date-fns";

import * as net from "../../lib/net";
import Fr_Input from "../../lib/ui/fr_input";
import Fr_Cal from "../../lib/ui/fr_cal";
import Td_Sel from "../../lib/ui/td_sel";

import Pms_Rvo from "../rv/fb/rvo.js";
import Wnd_Sel from "../../lib/ui/wnd_sel";
import Nota_Show from "./nota_show";

class Pms_SzNota extends React.Component {
  constructor(props, context) {
    super(props);

    this.state = {};
    this.state.ynq = [];
    this.state.aa = [];
    this.state.cols = {};
    this.state.ix = 0;
    this.state.sz_val = "";
    this.state.msg = {};
    this.state.wnds = {};
    this.state.szo = {};
    this.state.sza = [];
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }

    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    wnd_close = () => {
		this.props.ctx.setState({ wnds: {} });
	};
    handleChange = (event, newValue) => {
        //alert(newValue);
        this.setState({ix: newValue});
      };

  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  sz_do() {
    var self = this;

    if(this.state.szo.boknr) this.sz_boknr();
    if(this.state.szo.notanr) this.sz_notanr();
    if(this.state.szo.dt) this.sz_nota_dt();

    return;
  }

  sz_boknr() {
    var self = this;

    var prm = {};
    prm.req = "pms.sz.mys_boknr";
    prm.boknr = this.state.szo.boknr;
    //alert(JSON.stringify(prm));

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));
        if(ret.ok != "000") {
            return;
        }
        self.setState({ sza: ret.rca });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
    return;
  }
    sz_notanr() {
        var self = this;

        self.setState({ sza: [] });

        var prm = {};
        prm.req = "pms.sz.mys_notanr";
        prm.notanr = this.state.szo.notanr;
        //alert(JSON.stringify(prm));

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret));
                if(ret.ok != "000") {
                    return;
                }
                self.setState({ sza: ret.rca });
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
        return;
    }
    sz_nota_dt() {
        var self = this;

        self.setState({ sza: [] });

        var prm = {};
        prm.req = "pms.sz.mys_nota_dt";
        prm.datum = this.state.szo.dt;
        //alert(JSON.stringify(prm));

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret));

                if(ret.ok != "000") {
                    return;
                }
                self.setState({ sza: ret.rca });
                //self.setState({gsa: ret.rca });
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
        return;
    }
    val_save (name, value) {
        var szo = this.state.szo;
        if(!szo[name]) {
            szo = {};
        }
        szo[name] = value;
        this.setState({szo: szo});
    }
    val_dt_save (name, value) {
        var szo = this.state.szo;
        //var dts = format(value, "yyyy-MM-dd");
        //alert(JSON.stringify(value));
        if(!szo[name]) {
            szo = {};
        }
        szo[name] = value;
        this.setState({szo: szo});
    }
    sz_edit(row) {
        var self = this;
        //alert(JSON.stringify(row.gsb00));
        var wnds = {};
        wnds.notashow = 1;
        wnds.notanr = row.notanr;
        wnds.boknr = row.boknr;
        wnds.nto = row;
        self.setState({ wnds: wnds });
    }
    xsz_edit(row) {
        var self = this;
        //alert(JSON.stringify(this.state.szo));
        if(this.state.szo.boknr) this.sz_rvsel(row);
        if(this.state.szo.notanr) this.sz_rvsel(row);
        if(this.state.szo.dt) this.sz_sel(row);
    }
    sz_rvo(row) {
        var self = this;
        var wnds = {};
        wnds.rvo = 1;
        wnds.boknr = row.boknr;
        this.props.ctx.setState({ wnds: wnds });
    }
    sz_rvo_copy(row) {
        var self = this;
        var wnds = {};
        wnds.rvo = 1;
        wnds.copy = row.boknr;
        this.props.ctx.setState({ wnds: wnds });
    }
    sz_nota(row) {
        var self = this;
        //alert(JSON.stringify(row.gsb00));
        var wnds = {};
        wnds.nota = 1;
        wnds.boknr = row.boknr;
        wnds.prm = row;
        this.props.ctx.setState({ wnds: wnds });
    }
    sz_sel_do(fkn) {
        var self = this;
        var row = this.state.srow;
        self.setState({msg: {} });
        if(fkn == "0") self.sz_rvo(row);
        if(fkn == "1") self.sz_nota(row);
    }
    sz_sel(row) {
        var self = this;
        var msg = { info: false, sel: true, text: "Öppna", text0: "BOKNING", text1: "NOTA", cb: "sz_sel_do" };
        self.setState({ msg: msg, srow: row });
    }
    sz_rvsel_do(fkn) {
        var self = this;
        var row = this.state.srow;
        self.setState({msg: {} });
        if(fkn == "0") self.sz_rvo_copy(row);
        if(fkn == "1") self.sz_rvo(row);
    }
    sz_rvsel(row) {
        var self = this;
        var msg = { info: false, sel: true, text: "SKAPA KOPIA ELLER ÖPPNA BOKNING?", text0: "KOPIA", text1: "ÖPPNA", cb: "sz_rvsel_do" };
        self.setState({ msg: msg, srow: row });
    }
    sz_keydown (e) {
        //e.preventDefault();
          const key = e.key;
          const keyCode = e.keyCode;
    
          if(keyCode == 13) {
              this.sz_do();
          };
          if(keyCode == 27) {
              //this.setState({sz_val: ""});
          };
      }

  render() {
    var title = this.props.title;
    if(!title) title = "Sök";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="m2_backdrop" /> : null }
        <div className="m2_dlg mx_80 flex-col" style={ this.dlg_style() }>
        <div className="mx_content flex">

            <div className="flex-row" style={{padding:"5px",height:"35px"}}>
                    <div className="flex-col flex">
                        <div>SÖK NOTA</div>
                    </div>
                    <div className="flex-col">
                        <div>UPPDATERA</div>
                    </div>
            </div>
            <div className="divider_orange"></div>

            <div className="flex" style={{overflow: "auto"}}>
            <div className="flex flex-row" style={{border: "0px", margin: "0px", padding: "0px"}}>
                <table className="table" style={{border: "0px", margin: "0px", padding: "0px"}}>
                    <thead style={{fontSize: "12px", fontWeight: "normal", color: "#080", border: "0px", margin: "0px", padding: "0px"}}>
                    <tr>
                        <th style={{textAlign: "left"}}>Notanr</th>
                        <th style={{textAlign: "left"}}>Boknr</th>
                        <th style={{textAlign: "left"}}>Namn</th>
                        <th style={{textAlign: "left"}}>Datum</th>
                        <th style={{textAlign: "left"}}>Belopp</th>
                        <th style={{textAlign: "left"}}>Betalt</th>
                        <th style={{textAlign: "left"}}>OBetalt</th>
                        <th style={{textAlign: "left"}}>Status</th>
                    </tr>
                    </thead>
                    <tbody style={{fontSize: "16px", color: "#000", border: "0px", margin: "0px", padding: "0px", borderBottom: "1px solid #ccc"}}>
                    { this.state.sza.map((row, key) =>
                    <tr key={key} onDoubleClick={() => this.sz_edit(row) }>
                        <td>{ row.notanr.ztrim() }</td>
                        <td>{ row.boknr }</td>
                        <td>{ row.namn }</td>
                        <td>{ row.datum.cdidate() }</td>
                        <td>{ row.fsg.cdipris() }</td>
                        <td>{ row.bet.cdipris() }</td>
                        <td>{ row.obet.cdipris() }</td>
                        <td>{ row.status }</td>
                    </tr>
                    )}
                    </tbody>
                </table>
             </div>
             </div>

             <div className="divider_orange"></div>
            <form className="cdi_f03">
            <div className="flex-row">
                <Fr_Input label="NOTANR" className="text-left w250" text={this.state.szo.notanr} getValue={(e)=> this.val_save("notanr", e) } onKeyDown={(e) => this.sz_keydown(e)} />
                <Fr_Input label="GÄST" className="text-left w350" text={this.state.szo.gst} getValue={(e)=> this.val_save("gst", e) } onKeyDown={(e) => this.sz_keydown(e)} />
            </div>
            <div className="flex-row">
                <Fr_Cal above label="DATUM" className="text-left w250" text={this.state.szo.dt} getValue={(e)=> this.val_dt_save("dt", e) } />
            </div>
            </form>
        </div>

        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex-row">
            <button className="btn btn-hw-green w250" onClick={(event) => this.sz_do(event) } >
                SÖK
            </button>
            </div>
      </div>
    </div>
        { this.state.wnds.rvo ? <Pms_Rvo wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.wnds.notashow ? <Nota_Show wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.msg.sel ? <Wnd_Sel msg={this.state.msg} ctx={this} /> : null }
    </div>
	;
	return html;
  };
}

export default Pms_SzNota;