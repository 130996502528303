import React, { useState, useEffect, useContext } from 'react';
import {Route, Routes} from "react-router-dom";

import Bo_Home from './bo/home.js';
import Bo_Prm from './bo/home.js';
import Bo_Kp from './bo/home.js';
import {withRouter} from "../lib/react/withRouter";
import Bfs_Dash from "./dash";

function pg_home() {

    var html =
    <div className="web-content">
      Hem
    </div>
    ;
    return html;
}

class Bfs_Bo extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.state = {};
  }
  fknGo(row) {
    //alert(row.href);
    this.props.navigate(row.url);
  }

  render() {
      var path = window.location.pathname;
      if(path.substr(-1) == "/") path = path.substring(0, path.length - 1);
      var op = path.split("/").pop();
      var po = {};
      po.prm = { "fid": "f06", "url": "/bfs/bo/prm", "name": "Parametrar", "active": false };
      po.kp = { "fid": "f06", "url": "/bfs/bo/kp", "name": "Kontoplan", "active": false };
      if(po[op]) {
          po[op].active = true;
          this.props.store.fkn = po[op];
      }
      var pa = Object.values(po);

    return (

    <div className="web-app">
         { this.props.store.cfg.bread ?
             <div className="web-bread">
                 <div className="breadcrumb">
            { pa.map((row, key) =>
                <div key={key} className="breadcrumb-item" active={row.active} onClick={() => this.fknGo(row)}>{row.name}</div>
            )}
            <div className="flex"></div>
            <div className="cdigreen">{ this.props.store.fkn ? this.props.store.fkn.name : null }</div>
            </div>
        </div>
        : null }
        <Routes>
          <Route path="/xx" component={pg_home} />
            <Route path="prm" element={ <Bo_Prm store={this.store} />} />
            <Route path="kp" element={ <Bo_Kp store={this.store} />} />
            <Route path="*" element={ <Bo_Home store={this.store} />} />
        </Routes>
    </div>

  );
}
}
//Sys.contextType = ADOContext;

export default withRouter(Bfs_Bo);
