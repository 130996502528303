import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";
import Td_Sel from "../../lib/ui/td_sel";

import PdfRpDlg from "./bekr/pdf_rp_dlg";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class PMS_Tmpl extends React.Component {
  constructor(props, context) {
    super(props);

    this.selitems = [
        {fkn: 'open', text: 'ÖPPNA'},
        {fkn: 'cancel', text: 'AVBOKA'}
    ];

    this.store = props.store;

    this.state = { pgid: 11, tra: [] };
    this.state.seldate = new Date();
    this.state.avd = "01";
    this.state.taa = [];
    this.state.kro = {};
    this.state.aa = [];
    this.state.cols = {};
    this.state.pdf = 0;
    this.state.pdf_content = null;
    this.state.loading = 0;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.tmpl();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

  kund_open(row) {
      var self = this;
  }
  kund_edit(row) {
      var self = this;
      //alert(JSON.stringify(row.krr00));
        this.setState({edit: 1, kro: row});
  }
  fkn_close() {
    this.setState({edit: false });
    //this.props.history.push("cas");
    //this.login_srv(uid, pwd);
  }
  pdf_done (ret) {
    this.setState({loading: 0, pdf: 0, pdf_content: null });
  }

  tmpl() {
    var self = this;
    var prm = {};
    prm.req = "pms.rv_op.rv_bekr_mall";
    prm.fdat = "20200915";
    prm.tdat = "20200915";

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        self.setState({taa: ret.rca }, function () {
            self.syncTb("tmplTable");
        });
    })
    .catch(function(e) {
        var txt = JSON.stringify(e);
    });
  }

  mall_edit() {
    var self = this;
  }
  tmpl_help() {
    var self = this;
  }
  refresh() {
    var self = this;
  }

  tst_pdf() {
    var self = this;

    var prm = {};
    prm.req = "pms.bekr.pdf_pms_tst";

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        self.setState({pdf: 1, pdf_content: ret.pdf });
    })
    .catch(function(e) {
        var txt = JSON.stringify(e);
    });
  }
  tst_mail() {
    var self = this;

    var prm = {};
    prm.req = "pms.bekr.mail_pms_tst";

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        //self.setState({taa: ret.rca });
    })
    .catch(function(e) {
        var txt = JSON.stringify(e);
    });
  }
    syncTb(tb) {
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
        tdElements = tbel.getElementsByTagName('td');

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
            width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
  sel_row_fkn(row, sel) {
    //alert(JSON.stringify(fkn));
      if(sel.fkn == "open") {
      }
      if(sel.fkn == "cancel") {
          //this.rvp_cancel(row.resv00.resno);
      }
  }

  render() {
    return (
    <div className="web-app">
        <div className="txt_18">Mallar</div>

    <div className="flex-row">
        <button className="btn btn-hw-blue w150" onClick={() => this.tst_mail() } aria-label="System" >
            LADDA UPP
        </button>
        <button className="btn btn-hw-blue w150" onClick={() => this.tst_pdf() } aria-label="System" >
            TST PDF
        </button>
        <button className="btn btn-hw-blue w150" onClick={() => this.tmpl_help() } aria-label="System" >
            MALL HJÄLP
        </button>
        <div className="flex"></div>
        <button className="btn btn-hw-blue w150" onClick={() => this.refresh() } aria-label="System" >
            FUNKTIONER
        </button>
    </div>
    <div className="divider_orange"></div>

    <div id="tmplTable" className="web-col">
        <table className="table table-bordered table-hover tb" style={{marginBottom: "0px"}}>
            <thead>
            <tr>
                <th>Filnamn</th>
                <th>Typ</th>
                <th>Storlek</th>
                <th>Datum</th>
                <th>Status</th>
                <th>Upd</th>
                <th></th>
            </tr>
            </thead>
        </table>
       <div className="tb-cdi" style={ this.tb_style() }>
       <table className="table table-bordered table-hover tb">
            <tbody>
            { this.state.taa.map((row, key) =>
            <tr key={key}  onDoubleClick={() => this.mall_edit(row) }>
                <td>{ row.filename }</td>
                <td>{ row.typ }</td>
                <td>{ row.size }</td>
                <td>{ row.create }</td>
                <td>{ row.status }</td>
                <td>{ row.upd }</td>
                <td>
                    <Td_Sel items={ this.selitems } getValue={(e)=> this.sel_row_fkn(row, e)} />
                </td>
            </tr>
            )}
            </tbody>
        </table>
        </div>
    </div>
        <PdfRpDlg show={this.state.pdf} pdf={this.state.pdf_content} done={(ret) => this.pdf_done(ret)} backdrop={true}/>
   </div>
  );
    }
}
export default PMS_Tmpl;
