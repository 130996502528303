import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../../lib/net";
import * as utils from "../../../assets/utils";

import Wnd_Ok from "../../../lib/ui/wnd_ok";

class Bq_Trs extends React.Component {
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.state = {};
    this.state.bqa = [];
    this.state.tra = [];
    this.state.msg = {};
   }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      this.trs();
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    tab_style(tabid) {
      var css = {};
      css.display = "none";
      if(this.state.tabval === tabid) css.display = "block";
      return css;
    }
    bq_close() {
		this.props.ctx.setState({ wnds: {} });
	};
  tb_style() {
      var css = {};
      css.height = (window.innerHeight * 0.9) - 175;
      css.overflow = "auto";
      return css;
  }
  cssCols (cid) {
      var css = {};
      if(this.state.cols[cid]) css.width = this.state.cols[cid].toString() + "px";
      //if(cid == "c01") alert(JSON.stringify(this.state.cols[cid]));
      return css;
  }
  syncCols (tb) {

    if (!document.getElementById(tb)) return;

    var cols = {};
    var ix = document.getElementById(tb).rows.length - 1;
    var ic = document.getElementById(tb).rows[0].cells.length;
    var ci = 0;
    while(ci < ic) {
        var cxi = ci + 1;
        var col = "c" + cxi.toString().padStart(2, "0");
        cols[col] = document.getElementById(tb).rows[ix].cells[ci].clientWidth + 1;
        ci = ci + 1;
    }
    this.setState({cols: cols });
    //alert(JSON.stringify(this.state.cols));
    //this.forceUpdate();
  };
  trs() {
    var self = this;
    var prm = {};
    prm.req = "pos.pos_bq.trs";
    prm.avd = this.state.avd;
    //prm.fplu = datum;
    //prm.tplu = datum;

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        self.setState({tra: ret.rca });
        self.syncTb("bqtrsTable");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }
  row_bong(row) {
    var self = this;
    var prm = {};
    prm.req = "pos.pos_bq.bongrow";
    prm.row = row;
    prm.status = "09";

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        //self.setState({bqa: ret.rca });
        //toastr.success("OK", "SAPI");
        var msg = { ok: true, text: "BONGAD ..." };
        self.setState({msg: msg });
        setTimeout(function(){
            self.setState({msg: {} });
        }, 1000);
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }
    syncTb(tb) {
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
            tdElements = tbel.getElementsByTagName('td');
        if(tdElements.length < 1 ) return;

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
                width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }

  render() {
    if(!this.props.wnds.trs) return "";

    var title = this.props.title;
    if(!title) title = "Titel";

	var html =
        <div className="mx_base">
        { this.props.wnds.backdrop ? <div className="mx_backdrop" /> : null }
        <div className="mx_dlg mx_90 flex-col" style={ this.dlg_style() }>
        <div className="mx_content flex">

            <div className="flex-row" style={{padding:"5px",height:"35px"}}>
                    <div className="flex-col flex">
                        <div>Transaktioner</div>
                    </div>
                    <div className="flex-col">
                        <div>UPPDATERA</div>
                    </div>
            </div>
            <div className="divider_orange"></div>

        <div className="web-row">
            <div id="bqtrsTable" className="flex">
            <table className="table table-bordered table-hover" style={{marginBottom: "0px"}}>
                <thead>
                <tr>
                    <th>ID</th>
                    <th>Bord</th>
                    <th>Notanr</th>
                    <th>Plu</th>
                    <th>Text</th>
                    <th>Antal</th>
                    <th>Pris</th>
                    <th>Vgrp</th>
                    <th>Bong</th>
                    <th>Tid</th>
                </tr>
                </thead>
            </table>
            <div style={ this.tb_style() }>
                <table className="table table-bordered table-hover">
                    <tbody>
                { this.state.tra.map((row, key) =>
                <tr key={key} id={ row.r0dateid } onDoubleClick={() => this.row_bong(row) }>
                    <td className="text-center">{ row.r0dateid }</td>
                    <td className="text-center">{ row.r0tablnr }</td>
                    <td className="text-center">{ utils.toNum( row.r0faktnr ) }</td>
                    <td className="text-center">{ row.r0etyp }</td>
                    <td className="text-left">{ row.r0text }</td>
                    <td className="text-center">{ utils.toNum( row.r0noof ) }</td>
                    <td className="text-right">{ utils.toPris( row.r0perprice ) }</td>
                    <td className="text-center">{ row.r0roomno }</td>
                    <td className="text-center">{ row.r0guestno }</td>
                    <td className="text-center">{ utils.toTid( row.r0time )}</td>
                </tr>
                )}
                </tbody>
            </table>
            </div>
            </div>
        </div>

        </div>

        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250 ripple" onClick={() => this.bq_close() } >
                STÄNG
            </button>
            <div className="flex-row">
            </div>
            <button className="btn btn-hw-green w250 ripple" onClick={(event) => this.prn(event) } >
                SKRIV UT
            </button>
        </div>
      </div>
        <Wnd_Ok msg={this.state.msg} ctx={this} />
    </div>
	;
	return html;
  };
}

export default Bq_Trs;