import React, { useState, useEffect, useContext } from 'react';
import {format} from "date-fns";

import * as net from "../../../../lib/net";

import Dd_Sel from "../../../../lib/ui/dd_sel";
import Dt_Cal from "../../../../lib/ui/dt_cal";

import Wnd_Error from "../../../../lib/ui/wnd_error";
import Wnd_Ok from "../../../../lib/ui/wnd_ok";
import Wnd_Info from "../../../../lib/ui/wnd_info";
import Wnd_Confirm from "../../../../lib/ui/wnd_confirm";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Eko_Recover extends React.Component {
  constructor(props, context) {
    super(props);

    this.dt_fd = React.createRef();
    this.dt_td = React.createRef();

    this.selitems = [
        {fkn: 'bevaka', text: 'BEVAKA'},
        {fkn: 'bokbild', text: 'BOKNINGSBILD'},
        {fkn: 'confirm', text: 'BEKRÄFTA'},
        {fkn: 'cancel', text: 'AVBOKA'}
    ];

    this.store = props.store;
    this.pms = props.store.pms;

    this.state = {};
    this.state.ynq = [];
    //this.state.vga = [];
    this.state.rta = [];
    this.state.bra = [];

    this.state.dta = [];
    this.state.rva = [];

    this.state.tot = {};

    this.state.krubba = 0;
    this.state.ix = 0;
    this.state.boknr = "";
    this.state.datum = "";
    this.state.fdat = "";
    this.state.tdat = "";

      this.state.msg = {};
    this.state.wnds = {};
    this.state.rvo = {};

      if(this.pms.base) {
          this.state.fdat = this.pms.base.fdat;
          this.state.tdat = this.pms.base.tdat;
      }
      if(props.wnds) {
          if(props.wnds.fdat) this.state.fdat = props.wnds.fdat;
          if(props.wnds.tdat) this.state.tdat = props.wnds.tdat;
      }
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      ///this.dt_saldo();
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
  val_pris(val) {
      var s = "";
      if(val == 0) s = "0.00";
      if(val) s = val.cdipris();
      return s;
  };

    formchange (e) {
      const name = e.target.name;
      const value = e.target.value;
      var rvo = this.state.rvo;
      var na = name.split(".");
      rvo[na[0]][na[1]] = value;
      this.setState({rvo: rvo});
      //setState({[name]: value});
    }

    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    wnd_close = () => {
      if(this.props.close) {
          this.props.close(this.props.ctx);
      }
      else this.props.ctx.setState({ wnds: {} });
	};
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
    val_save (name, value) {
        //alert(JSON.stringify(name));
        //alert(JSON.stringify(value));
      var rvo = this.state.rvo;
      var na = name.split(".");
      rvo[na[0]][na[1]] = value;
      this.setState({rvo: rvo});
      //setState({[name]: value});
    }
  dt_fdat(e) {
    //alert(JSON.stringify(e));
    var dts = format(e, "yyyy-MM-dd");
    this.setState({ fdat: dts });
  }
  dt_tdat(e) {
      //alert(JSON.stringify(e));
    var dts = format(e, "yyyy-MM-dd");
    this.setState({ tdat: dts });
  }
  oneday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_td.current.updateDt(this.state.fdat);
  }
  addday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_fd.current.updateAdd();
    this.dt_td.current.updateAdd();
  }
  subday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_fd.current.updateSub();
    this.dt_td.current.updateSub();
  }
    ekot_recov(typ) {
        var self = this;

        var msg = { info: true, text: "Bearbetar ..."};
        self.setState({ msg: msg });

        var prm = {};
        prm.req = "pms.eko_recov.ekot_recov";
        prm.fdat = this.state.fdat;
        prm.tdat = this.state.tdat;
        if(typ == "Rpru") {
            prm.ttyp = "Rpru";
            prm.kontonr = "2421";
            prm.tkn = "-";
        }

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret));

                if(ret.ok != "000") {
                    var msg = { error: true, info: false, text: "Bearbetning misslyckad ..."};
                    self.setState({ msg: msg });
                    setTimeout(function() {
                        self.setState({ msg: {} });
                    }, 1500);
                    return;
                }
                var msg = { ok: true, info: false, text: "Bearbetning klar ..."};
                self.setState({ msg: msg });
                setTimeout(function() {
                    self.setState({ msg: {} });
                }, 1500);
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    hbok_recov(typ) {
        var self = this;

        var msg = { info: true, text: "Återställer ..."};
        self.setState({ msg: msg });

        var prm = {};
        prm.req = "pms.eko_recov.hbok_recov";
        prm.fdat = this.state.fdat;
        prm.tdat = this.state.tdat;
        if(typ == "Rpru") {
            prm.typ = "P";
            prm.ttyp = "Rpru";
            prm.kontonr = "2421";
            prm.tkn = "-";
        }
        if(typ == "kort") {
            prm.typ = "P";
            prm.ttyp = "kort";
            prm.kontonr = "1580";
        }
        if(typ == "hot") {
            prm.typ = "S";
            prm.ttyp = "hot";
            prm.kontonr = "2422";
        }
        if(typ == "rack") {
            prm.typ = "S";
            prm.ttyp = "rack";
            prm.kontonr = "3055";
        }
        if(typ == "F") {
            prm.typ = "S";
            prm.ttyp = "F   ";
            prm.kontonr = "3055";
        }

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret));

                if(ret.ok != "000") {
                    var msg = { error: true, info: false, text: "Återställning misslyckad ..."};
                    self.setState({ msg: msg });
                    setTimeout(function() {
                        self.setState({ msg: {} });
                    }, 1500);
                    return;
                }
                var msg = { ok: true, info: false, text: "Återställning klar ..."};
                self.setState({ msg: msg });
                setTimeout(function() {
                    self.setState({ msg: {} });
                }, 1500);
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    hbok_reg_recov() {
        var self = this;

        var msg = { info: true, text: "Återställer ..."};
        self.setState({ msg: msg });

        var prm = {};
        prm.req = "pms.eko_recov.hbok_reg_recov";
        prm.fdat = this.state.fdat;
        prm.tdat = this.state.tdat;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret));

                if(ret.ok != "000") {
                    var msg = { error: true, info: false, text: "Återställning misslyckad ..."};
                    self.setState({ msg: msg });
                    setTimeout(function() {
                        self.setState({ msg: {} });
                    }, 1500);
                    return;
                }
                var msg = { ok: true, info: false, text: "Återställning klar ..."};
                self.setState({ msg: msg });
                setTimeout(function() {
                    self.setState({ msg: {} });
                }, 1500);
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    hbok_ptyp() {
        var self = this;

        var msg = { info: true, text: "Återställer ..."};
        self.setState({ msg: msg });

        var prm = {};
        prm.req = "pms.eko_recov.hbok_ptyp";
        prm.fdat = this.state.fdat;
        prm.tdat = this.state.tdat;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret));

                if(ret.ok != "000") {
                    var msg = { error: true, info: false, text: "Återställning misslyckad ..."};
                    self.setState({ msg: msg });
                    setTimeout(function() {
                        self.setState({ msg: {} });
                    }, 1500);
                    return;
                }
                var msg = { ok: true, info: false, text: "Återställning klar ..."};
                self.setState({ msg: msg });
                setTimeout(function() {
                    self.setState({ msg: {} });
                }, 1500);
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    trs_recov(typ) {
        var self = this;

        var msg = { info: true, text: "Återställer ..."};
        self.setState({ msg: msg });

        var prm = {};
        prm.req = "pms.eko_recov.trs_recov";
        prm.fdat = this.state.fdat;
        prm.tdat = this.state.tdat;
        if(typ == "xx") {
            prm.typ = "S";
            prm.ttyp = "Rpru";
            prm.kontonr = "";
        }

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret));

                if(ret.ok != "000") {
                    var msg = { error: true, info: false, text: "Återställning misslyckad ..."};
                    self.setState({ msg: msg });
                    setTimeout(function() {
                        self.setState({ msg: {} });
                    }, 1500);
                    return;
                }
                var msg = { ok: true, info: false, text: "Återställning klar ..."};
                self.setState({ msg: msg });
                setTimeout(function() {
                    self.setState({ msg: {} });
                }, 1500);
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }

  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "Bokning";

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="mx_backdrop" /> : null }
        <div className="mx_dlg mx_90 flex-col" style={ this.dlg_style() }>
        <div className="mx_h01">
            <div>EKONOMI RECOVER</div>
        </div>

            <div className="divider_orange hide"></div>

            <div className="flex-row">
                <div className="flex"></div>
            <div className="flex-row">
                  <div className="dt-btn-01" onClick={() => this.subday() } ><FontAwesomeIcon icon="minus" size="1x" /></div>
                  <Dt_Cal dts={ this.state.fdat } rp="1" ref={this.dt_fd} getValue={(e)=> this.dt_fdat(e)} />
                  <div className="dt-btn-01" onClick={() => this.oneday() } >1</div>
                  <Dt_Cal dts={ this.state.tdat } rp="1" ref={this.dt_td} getValue={(e)=> this.dt_tdat(e)} />
                  <div className="dt-btn-01" onClick={() => this.addday() } ><FontAwesomeIcon icon="plus" size="1x" /></div>
            </div>
                <div className="flex"></div>
            </div>
             <div className="divider_green"></div>

       <div className="mx_content flex">

        <div id="recTb">
            <div className="web-row">
                <div className="flex-col flex">
                    <div className="web-bx">
                        <div>Huvudboks Recover - Pres</div>
                        <div className="flex-row">
                            <div className="flex">Räkna om presentkort-Rpru från transaktionerna och sätt kontonr</div>
                            <div className="web-link" onClick={() => this.hbok_recov("Rpru") }>Kör</div>
                        </div>
                    </div>
                    <div className="web-bx">
                        <div>Huvudboks Recover - F</div>
                        <div className="flex-row">
                            <div className="flex">Räkna om Logi-F från transaktionerna och sätt kontonr</div>
                            <div className="web-link" onClick={() => this.hbok_recov("F") }>Kör</div>
                        </div>
                    </div>
                    <div className="web-bx">
                        <div>Huvudboks Recover - Rack</div>
                        <div className="flex-row">
                            <div className="flex">Räkna om Logi-Rack från transaktionerna och sätt kontonr</div>
                            <div className="web-link" onClick={() => this.hbok_recov("rack") }>Kör</div>
                        </div>
                    </div>
                    <div className="web-bx">
                        <div>Huvudboks Recover - Kort</div>
                        <div className="flex-row">
                            <div className="flex">Räkna om betalning-kort från transaktionerna och sätt kontonr</div>
                            <div className="web-link" onClick={() => this.hbok_recov("kort") }>Kör</div>
                        </div>
                    </div>
                    <div className="web-bx">
                        <div>Huvudboks Recover - Hot</div>
                        <div className="flex-row">
                            <div className="flex">Räkna om betalning-hot från transaktionerna och sätt kontonr</div>
                            <div className="web-link" onClick={() => this.hbok_recov("hot") }>Kör</div>
                        </div>
                    </div>
                </div>
                <div className="flex-col flex">
                    <div className="web-bx">
                        <div>Huvudboks Recover - Register</div>
                        <div className="flex-row">
                            <div className="flex">Uppdatera huvudbok från register moms och kontonr</div>
                            <div className="web-link" onClick={() => this.hbok_reg_recov() }>Kör</div>
                        </div>
                    </div>
                    <div className="web-bx">
                        <div>Huvudboks Recover - Ptyp</div>
                        <div className="flex-row">
                            <div className="flex">Sätt huvudbok samma ptyp som från transaktionerna. Ordnar korrade rader i huvudbok bla.</div>
                            <div className="web-link" onClick={() => this.hbok_ptyp() }>Kör</div>
                        </div>
                    </div>
                    <div className="web-bx">
                        <div>Transaktions Recover - Belopp</div>
                        <div className="flex-row">
                            <div className="flex">Rätta trasigt belopps fält på transaktionerna</div>
                            <div className="web-link" onClick={() => this.trs_recov("") }>Kör</div>
                        </div>
                    </div>
                    <div className="web-bx">
                        <div>Presentkort Recover - Tecken</div>
                        <div className="flex-row">
                            <div className="flex">Rätta presentkort komplett</div>
                            <div className="web-link" onClick={() => this.ekot_recov("Rpru") }>Kör</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        </div>

        <div className="mx_foot flex-row">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex"></div>
        </div>
        </div>
            <Wnd_Error msg={this.state.msg} ctx={this} />
            <Wnd_Ok msg={this.state.msg} ctx={this} />
            <Wnd_Info msg={this.state.msg} ctx={this} />
            <Wnd_Confirm msg={this.state.msg} ctx={this} />
    </div>
	;
	return html;
  };
}

export default Eko_Recover;