import React, { useState, useEffect, useContext } from 'react';
import {format} from "date-fns";
import Dt_Cal from "../../../lib/ui/dt_cal";

import * as net from "../../../lib/net";
import Fr_Input from "../../../lib/ui/fr_input";
import Fr_Select from "../../../lib/ui/fr_select";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Krub_Corr extends React.Component {
  constructor(props, context) {
    super(props);

    this.dt_dt = React.createRef();

    this.pos = props.store.pos;
    this.state = {};
    this.state.mka = [];
    this.state.sval = [];
    this.state.tval = [];
    this.state.paa = [];
    this.state.uaa = [];
    this.state.laa = [];

    this.state.gnr = "001";
    //this.state.lro = props.lro;
    this.state.ynq = [
                {id: '0', text: 'NEJ'},
                {id: '1', text: 'JA'}
            ];

    this.state.wnd_ok = false;
    this.state.wnd_err = false;

    this.state.today = new Date();
    var dts = format(this.state.today, "yyyy-MM-dd");
    this.state.dts = dts;

    this.state.cols = {};
    this.state.kro = {};
    if(props.wnds.kro) {
      this.state.dts = props.wnds.dts;
      this.state.kro = props.wnds.kro;
      //this.state.kro = JSON.parse(JSON.stringify(props.wnds.tro));
    }
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.btn_grps();
    //if(!this.props.wnds.tro) this.tro_init();
  }

    tro_init() {
        var tro = {};
        tro.e0bastyp = "";
        tro.e0datum = "";
        tro.e0lopnr = "";
        tro.e0subnr = "";
        tro.e0typ = "";
        tro.e0klockan = "";
        tro.e0filler0 = "";
        tro.e0kontonr = "";
        tro.e0text = "";
        tro.e0antal = "";
        tro.e0pris = "";
        tro.e0tkn = "";
        tro.e0moms = "";
        tro.e0rumsnr = "";
        tro.e0sign = "";
        tro.e0updtime = "";
        //alert(JSON.stringify(rso));
        this.setState({ tro: tro });
        return;
    }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    dt_dat(e) {
      //alert(JSON.stringify(e));
      var dts = format(e, "yyyy-MM-dd");
      this.setState({ dts: dts });
    }
    oneday(e) {
        //alert(JSON.stringify(e));
      //this.setState({ tdat: this.state.fdat });
      this.dt_dt.current.updateDt(this.state.dts);
    }
    addday(e) {
        //alert(JSON.stringify(e));
      //this.setState({ tdat: this.state.fdat });
      this.dt_dt.current.updateAdd();
    }
    subday(e) {
        //alert(JSON.stringify(e));
      //this.setState({ tdat: this.state.fdat });
      this.dt_dt.current.updateSub();
    }
  
    formchange (e) {
      const name = e.target.name;
      const value = e.target.value;
      var pbo = this.state.pbo;
      var na = name.split(".");
      pbo[na[0]][na[1]] = value;
      this.setState({pbo: pbo});
      //setState({[name]: value});
    }

    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    wnd_close = () => {
      if(this.props.close) {
          this.props.close(this.props.ctx);
      }
      else this.props.ctx.setState({ wnds: {} });
	};
    toggle() {
      this.setState({modal: false});
	};

  submitHandler = event => {
    event.preventDefault();
    event.target.className += " was-validated";
  };

  changeHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  val_save(id, val) {
      var tro = this.state.tro;
      tro[id] = val;
        this.setState({ tro: tro });
      return;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }

  krub_post_add() {
    var self = this;

    var kro = this.state.kro;
    //var fsg = 100000000000 - (kro.fsg * 1);
    //var bet = 100000000000 - (kro.bet * 1);
    //var saldo = 100000000000 - (kro.saldo * 1);
    var fsg = kro.bet;
    var bet = kro.fsg;
    var saldo = 100000000000 - (kro.saldo * 1);
    //saldo = "99" + saldo.toString();

    var tro = {};
    tro.k0datum = this.state.dts.replace(/-/g, "");
    tro.k0boknr = kro.boknr;
    tro.k0glopnr = "";
    tro.k0namn = kro.namn.fromswe();
    tro.k0fsg = fsg.toString();
    tro.k0bet = bet.toString();
    tro.k0saldo = saldo.toString();
    //alert(JSON.stringify(tro));
    //return;

    var prm = {};
    prm.req = "pms.krub.krub_tr_add";
    prm.tro = tro;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));
        if(ret.ok == "000") {

            //var tr00 = JSON.parse(JSON.stringify(self.state.tro));
            self.setState({ wnd_ok: true });

            setTimeout(function(){
                self.setState({wnd_ok: false });
                  if(self.props.close) {
                      self.props.close(self.props.ctx);
                  }
                  else self.props.ctx.setState({ wnd_rs: false });
                return;
            }, 1000);
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

  krub_post_del() {
    var self = this;

    var kro = this.state.kro;
    var tro = {};
    tro.k0datum = this.state.dts.replace(/-/g, "");
    tro.k0boknr = kro.boknr;
    tro.k0glopnr = "";

    var prm = {};
    prm.req = "pms.krub.krub_tr_del";
    prm.tro = tro;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));
        if(ret.ok == "000") {
            self.setState({wnd_ok: true });
            setTimeout(function(){
                self.setState({wnd_ok: false });
                  if(self.props.close) {
                      self.props.close(self.props.ctx);
                  }
                  else self.props.ctx.setState({ wnd_rs: false });
                return;
            }, 1000);
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

  render() {
    //if(!this.props.show) return "";
    var title = this.props.title;
    if(!title) title = "Resurs";
    //if(props.kro) alert(props.kro.krr00.kid);
    const popover = {
      position: 'absolute',
      top: '50px',
      zIndex: '2',
    }
    const cover = {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    }
    const okwnd = {
      position: 'absolute',
      width: '35%',
      height: '35%',
      zIndex: '19999',
      top: '35%',
      left: '35%',
        border: "1px solid #ccc",
        borderRadius: "5px",
       backgroundColor: "#015d00",
        backgroundImage: "linear-gradient(314deg, #015d00 0%, #04bf00 74%)"
    }
    const errwnd = {
      position: 'absolute',
      width: '35%',
      height: '35%',
      zIndex: '19999',
      top: '35%',
      left: '35%',
        border: "1px solid #ccc",
        borderRadius: "5px",
        backgroundColor: "#ED213A",  /* fallback for old browsers */
        background: "-webkit-linear-gradient(to right, #93291E, #ED213A)",  /* Chrome 10-25, Safari 5.1-6 */
        backgroundImage: "linear-gradient(to right, #93291E, #ED213A)" /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        //backgroundColor: "#8E0E00",  /* fallback for old browsers */
        //background: "-webkit-linear-gradient(to right, #1F1C18, #8E0E00)",  /* Chrome 10-25, Safari 5.1-6 */
        //backgroundImage: "linear-gradient(to right, #1F1C18, #8E0E00)" /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    }

	var html =
        <div className="mx_base">
        <div className="m2_backdrop" />
        <div className="m2_dlg mx_70 flex-col" style={ this.dlg_style() } >
        <div className="mx_content flex">

        <div className="mx_h01">
            <div>KRUBBA POST</div>
            <div>: { this.state.kro.boknr  }</div>
        </div>
            <div className="flex-row" style={{padding:"5px",height:"35px"}}>
                    <div className="flex-col flex">
                    </div>
                    <div className="flex-col">
                        <div>UPPDATERA</div>
                    </div>
            </div>
            <div className="divider_orange"></div>

            <form className="cdi_f03">
            <div className="flex-row">
                    <Fr_Input label="Boknr" className="text-left w150" text={this.state.kro.boknr} getValue={(e)=> this.val_save("boknr", e) } />
            </div>
            <div className="flex-row">
                    <Fr_Input label="Från" className="text-left w150" text={this.state.kro.fdat} getValue={(e)=> this.val_save("fdat", e) } />
                    <Fr_Input label="Till" className="text-left w150" text={this.state.kro.tdat} getValue={(e)=> this.val_save("tdat", e) } />
            </div>
            <div className="flex-row">
                    <Fr_Input label="Text" className="text-left w350" text={this.state.kro.namn} getValue={(e)=> this.val_save("namn", e) } />
            </div>
            <div className="divider_green"></div>
            <div className="flex-row">
                    <Fr_Input label="Fsg" className="text-left w250" text={this.state.kro.fsg} getValue={(e)=> this.val_save("fsg", e) } />
            </div>
            <div className="flex-row">
                    <Fr_Input label="Betal" className="text-left w250" text={this.state.kro.bet} getValue={(e)=> this.val_save("bet", e) } />
            </div>
            <div className="flex-row">
                    <Fr_Input label="Saldo" className="text-left w250" text={this.state.kro.saldo} getValue={(e)=> this.val_save("saldo", e) } />
            </div>

            </form>

            <div className="divider_green"></div>
            <div className="flex-row">
              <div className="dt-btn-01" onClick={() => this.subday() } ><FontAwesomeIcon icon="minus" size="1x" /></div>
              <Dt_Cal dts={ this.state.dts } rp="1" ref={this.dt_dt} getValue={(e)=> this.dt_dat(e)} />
              <div className="dt-btn-01" onClick={() => this.addday() } ><FontAwesomeIcon icon="plus" size="1x" /></div>
            </div>

        </div>
        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex"></div>
            <button className="btn btn-hw-red w250" onClick={() => this.krub_post_add() } >
                KORRIGERA
            </button>
        </div>
      </div>

        { this.state.wnd_ok ?
            <div style={ okwnd } className="d-flex flex-column align-content-center justify-content-center text-center" >
                    <h2 className="" style={{color: "#fff"}}>SPARAT</h2>
                    <FontAwesomeIcon className="" icon="check" size="4x" style={{color: "#fff"}} />
            </div>
            : null }
            { this.state.wnd_err ?
                <div style={ errwnd } className="d-flex flex-column align-content-center justify-content-center text-center" >
                        <h2 className="" style={{color: "#fff"}}>FEL</h2>
                        <FontAwesomeIcon className="" icon="exclamation" size="4x" style={{color: "#fff"}} />
                </div>
                : null }
      </div>
	;
	return html;
  };
}

export default Krub_Corr;