import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../../lib/net";
import Fr_Input from "../../../lib/ui/fr_input";
import Fr_Cal from "../../../lib/ui/fr_cal";
import Fr_Select from "../../../lib/ui/fr_select";

class Tr_Add extends React.Component {
  constructor(props, context) {
    super(props);

    //this.ta = props.store.ta;
    this.state = {};
    this.state.ynq = [];
    this.state.sval = [];

    this.state.dao = {};
    this.state.aa = [];
    this.state.types = [
                {id: 'T0', text: 'LÖNETRANSAKTION MINUTER'},
                {id: 'T1', text: 'LÖNETRANSAKTION TIMMAR'},
                {id: 'T2', text: 'LÖNETRANSAKTION ANTAL'},
                {id: 'T7', text: 'LÖNETRANSAKTION ANTAL EFTER SKATT'},
                {id: 'A0', text: 'AVDRAG FÖRE SKATT MINUTER'},
                {id: 'A1', text: 'AVDRAG FÖRE SKATT TIMMAR'},
                {id: 'A2', text: 'AVDRAG FÖRE SKATT ANTAL'},
                {id: 'A5', text: 'AVDRAG EFTER SKATT MINUTER'},
                {id: 'A6', text: 'AVDRAG EFTER SKATT TIMMAR'},
                {id: 'A7', text: 'AVDRAG EFTER SKATT ANTAL'}
            ];

    this.state.lso = {};
    this.state.tro = {};
    if(props.lso) this.state.lso = props.lso;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.cdi_init();
  }
    cdi_init() {
        var self = this;

        var tro = {};
        tro.lsid = "";	        //[10];         /* Lönespec ID          */
        tro.lopnr = "";	        //[4];          /* Löpnr                */
        tro.typ = "";		    //[2];          /* TYP = T0, A0/A1      */
        tro.trans = "";	        //[4];          /* Transaktions kod     */
        tro.text = "";		    //[30];         /* TEXT / INFO          */
        tro.antal = "";		    //[5];          /* Antal                */
        tro.belopp = "";	    //[11];         /* Belopp               */
        tro.tkn = "";	        //[1];          /* Tecken               */
        tro.tax = "";	        //[4];          /* Skattesats           */
        tro.pos = "";	        //[2];          /* Position             */
        tro.status = "";	    //[1];          /* Status               */
        tro.sign = "";	        //[3];          /* Signatur             */
        tro.filler = "";		//[35];
        tro.ts = "";		    //[12];
        tro.updtime = "";		//[4];          /* updtime              */

        tro.lsid = self.state.lso.lsid;

        //alert(JSON.stringify( self.state.lso ));
        self.setState({tro: tro});
    }
    cdi_store() {
        var self = this;
   }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    tab_style(tabid) {
      var css = {};
      css.display = "none";
      if(this.state.tabval === tabid) css.display = "block";
      return css;
    }
    tab_head(tabid) {
      var css = {};
      css.background = "#fff";
      css.color = "#000";
      if(this.state.tabval === tabid) {
          css.background = "#080";
          css.color = "#fff";
      }
      return css;
    }
    grp_style(row) {
      var css = {};
      css.background = "#008";
      if(this.state.gnr === row.vgrupp) css.background = "#080";
      return css;
    }

    formchange (e) {
      const name = e.target.name;
      const value = e.target.value;
      var pbo = this.state.pbo;
      var na = name.split(".");
      pbo[na[0]][na[1]] = value;
      this.setState({pbo: pbo});
      //setState({[name]: value});
    }

    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    handleClose = () => {
		this.props.ctx.setState({ wnds: {} });
	};
    handleChange = (event, newValue) => {
        //alert(newValue);
        this.setState({ix: newValue});
      };

  submitHandler = event => {
    event.preventDefault();
    event.target.className += " was-validated";
  };

  changeHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  };


  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  cssCols (cid) {
      var css = {};
      if(this.state.cols[cid]) css.width = this.state.cols[cid].toString() + "px";
      //if(cid == "c01") alert(JSON.stringify(this.state.cols[cid]));
      return css;
  }
  tr_get() {
    var self = this;
    var prm = {};
    prm.req = "ta.ta_lon.lr_add";
    prm.lrid = "";

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        self.setState({hap: ret.rco }, function() { this.cdi_init(); });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  tr_save() {
    var self = this;

    //this.cdi_store();

    if(false){
      alert(JSON.stringify(this.state.tro));
      return;
    }

    var prm = {};
    prm.req = "ta.ta_lon.trans_add";
    prm.tro = this.state.tro;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        var rsp = {};
        rsp.wnd = "tradd";
		self.props.ctx.wnds_done(self.props.ctx, rsp);
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
    val_save (name, value) {
        //alert(JSON.stringify(name));
        //alert(JSON.stringify(value));
      var tro = this.state.tro;
      tro[name] = value;
      this.setState({tro: tro});
      //setState({[name]: value});
    }

  render() {
    //if(!this.props.show) return "";

    //alert(this.props.ctx.state.lro);

    var title = this.props.title;
    if(!title) title = "Knappar";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        <div className="m2_backdrop" />
        <div className="m2_dlg mx_70 flex-col" style={ this.dlg_style() } draggable="true" >
        <div className="mx_h03">
            <div>TR</div>
        </div>
        <div className="mx_content flex">

            <div className="flex-row" style={{padding:"5px",height:"35px"}}>
                    <div className="flex-col flex">
                        <div>TRANSAKTION</div>
                    </div>
                    <div className="flex-col">
                        <div>UPPDATERA</div>
                    </div>
            </div>
            <div className="divider_orange"></div>

            <form className="cdi_f03">
                <div className="form-row flex-row">
                    <Fr_Input label="LS ID" className="w250" text={this.state.tro.lsid} getValue={(e)=> this.val_save("lsid", e) } placeholder="" />
                </div>
                <div className="form-row flex-row">
                    <Fr_Input label="Text" className="w350" text={this.state.tro.text} getValue={(e)=> this.val_save("text", e) } placeholder="" />
                </div>
                <div className="divider_green"></div>
                <div className="form-row flex-row">
                    <Fr_Input label="Position" className="w150" text={this.state.tro.pos} getValue={(e)=> this.val_save("pos", e) } placeholder="" />
                    <Fr_Input label="Status" className="w150" text={this.state.tro.status} getValue={(e)=> this.val_save("status", e) } placeholder="" />
                </div>
                <div className="form-row flex-row">
                    <Fr_Select flex label="Typ" className="text-left w350" text={this.state.tro.typ} items={this.state.types} getValue={(e)=> this.val_save("typ", e) } />
                </div>
                <div className="form-row flex-row">
                    <Fr_Input label="Transaktion" className="w250" text={this.state.tro.trans} getValue={(e)=> this.val_save("trans", e) } placeholder="" />
                </div>
                <div className="form-row flex-row">
                    <Fr_Input label="Antal" className="w250" text={this.state.tro.antal} getValue={(e)=> this.val_save("antal", e) } placeholder="" />
                    <Fr_Input label="Balopp" className="w250" text={this.state.tro.belopp} getValue={(e)=> this.val_save("belopp", e) } placeholder="" />
                </div>
                <div className="form-row flex-row">
                    <Fr_Input label="Skatt" className="w250" text={this.state.tro.tax} getValue={(e)=> this.val_save("tax", e) } placeholder="" />
                    <Fr_Input label="Tecken" className="w150" text={this.state.tro.tkn} getValue={(e)=> this.val_save("tkn", e) } placeholder="" />
                </div>
            </form>

        </div>
        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.handleClose() } >
                STÄNG
            </button>
            <button className="btn btn-hw-green w250" onClick={() => this.tr_save() } >
                SPARA
            </button>
        </div>
      </div>
      </div>
	;
	return html;
  };
}

export default Tr_Add;