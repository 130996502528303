import React, { useState, useEffect, useContext } from 'react';
import * as fkn from "../../lib/fkn";
import * as net from "../../lib/net";
import * as utils from "../../assets/utils";

import Dt_Cal from "../../lib/ui/dt_cal";
import {format} from "date-fns";
import Lang from "../../lib/lang";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import Rev_Dts from "./rev/rev_dts";

class Eko_Rev extends React.Component {
  constructor(props, context) {
    super(props);

    this.dt_fd = React.createRef();
    this.dt_td = React.createRef();

    this.webStore = props.store;
    this.state = {};
    this.state.fkn = "";

    this.state.dta = [];
    this.state.tot = {};
 
    this.state.today = new Date();
    var dts = format(this.state.today, "yyyy-MM-dd");
    this.state.fdat = dts;
    this.state.tdat = dts;
      this.state.wnds = {};
      this.state.sortid = "";
      this.state.sortdir = 1;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 260;
      css.overflow = "auto";
      return css;
  }
    th_style(id) {
        var css = {};
        css.cursor = "pointer";
        if(id == this.state.sortid) {
            if(this.state.sortdir) css.color = "#800";
            else css.color = "#080";
        }
        return css;
    }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }
  dt_fdat(e) {
    //alert(JSON.stringify(e));
    var dts = format(e, "yyyy-MM-dd");
    this.setState({ fdat: dts });
  }
  dt_tdat(e) {
      //alert(JSON.stringify(e));
    var dts = format(e, "yyyy-MM-dd");
    this.setState({ tdat: dts });
  }
  oneday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_td.current.updateDt(this.state.fdat);
  }
  addday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_fd.current.updateAdd();
    this.dt_td.current.updateAdd();
  }
  subday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_fd.current.updateSub();
    this.dt_td.current.updateSub();
  }

  change_fkn(e) {
    //let cdat = this.state.seldate;
    //alert(JSON.stringify(e));
    let fkn = e.target.value;
    this.setState({fkn: fkn});
    this.setState({tra: [], tsa: [], tta: [] });
  }

  dt_oms_tot() {
    var self = this;
    var prm = {};
    prm.req = "pms.eko_red.red_period";
    prm.fdat = this.state.fdat;
    prm.tdat = this.state.tdat;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        if(ret.ok != "000") {
            return;
        }
        var sdo = ret.sdo;
        self.setState({ dta: sdo.dta, tot: sdo.tot }, function() {
            self.syncTb("revTable");
        });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
    }
    syncTb(tb) {
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
            tdElements = tbel.getElementsByTagName('td');
        if(tdElements.length < 1 ) return;

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
                width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
    tb_sort(typ) {
        var self = this;
        //var rvs = self.state.rvs;
        var tra = self.state.tra;
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;

        if(sortid != typ) sortdir = 0;
        else {
            if(sortdir) sortdir = 0;
            else sortdir = 1;
        }

        if(sortdir) tra.sort((a,b) => (a[typ] < b[typ]) ? 1 : ((b[typ] < a[typ]) ? -1 : 0));
        else tra.sort((a,b) => (a[typ] > b[typ]) ? 1 : ((b[typ] > a[typ]) ? -1 : 0));

        self.setState({ tra: tra, sortid: typ, sortdir: sortdir });
    }
    th_icon(typ) {
        var cls = "";
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;
        if(sortid == typ)
        {
            if(sortdir) cls = "fa fa-caret-up";
            else cls = "fa fa-caret-down";
        }
        return cls;
    }
    rev_dts(row) {
        var self = this;
        var dts = row.datum;
        //alert(JSON.stringify(dts));
    
        var wnds = {};
        wnds.revdts = true;
        wnds.row = row;
        wnds.fdat = dts;
        wnds.tdat = dts;
        wnds.show = true;
        this.setState({ wnds: wnds });
      }
    
  render() {
    return (
    <div className="web-app">
    <div className="">
        OMSÄTTNING
    </div>

    <div className="flex-row">
    <div className="flex-row">
              <div className="dt-btn-01" onClick={() => this.subday() } ><FontAwesomeIcon icon="minus" size="1x" /></div>
              <Dt_Cal dts={ this.state.fdat } rp="1" ref={this.dt_fd} getValue={(e)=> this.dt_fdat(e)} />
              <div className="dt-btn-01" onClick={() => this.oneday() } >1</div>
              <Dt_Cal dts={ this.state.tdat } rp="1" ref={this.dt_td} getValue={(e)=> this.dt_tdat(e)} />
              <div className="dt-btn-01" onClick={() => this.addday() } ><FontAwesomeIcon icon="plus" size="1x" /></div>
        </div>
        <div className="flex"></div>
        <div className="flex"></div>
        <button className="btn btn-hw-blue w150" onClick={() => this.dt_oms_tot() } aria-label="System" >
            Visa
        </button>
    </div>
    <div className="divider_green"></div>

        <div id="revTable" className="flex">
            <table className="table table-bordered table-hover" style={{marginBottom: "0px"}}>
                <thead>
                <tr>
                    <th style={ this.th_style("datum") } onClick={() => this.tb_sort("datum") }><Lang>Datum <i className={ this.th_icon("datum") }></i></Lang></th>
                    <th style={ this.th_style("day") } onClick={() => this.tb_sort("day") }><Lang>Dag <i className={ this.th_icon("day") }></i></Lang></th>
                    <th style={ this.th_style("fsg") } onClick={() => this.tb_sort("fsg") }><Lang>Fsg <i className={ this.th_icon("fsg") }></i></Lang></th>
                    <th style={ this.th_style("trf") } onClick={() => this.tb_sort("trf") }><Lang>Trf <i className={ this.th_icon("trf") }></i></Lang></th>
                    <th style={ this.th_style("oms") } onClick={() => this.tb_sort("oms") }><Lang>Oms <i className={ this.th_icon("oms") }></i></Lang></th>
                    <th style={ this.th_style("bet") } onClick={() => this.tb_sort("bet") }><Lang>Bet <i className={ this.th_icon("bet") }></i></Lang></th>
                    <th style={ this.th_style("saldo") } onClick={() => this.tb_sort("saldo") }><Lang>Saldo <i className={ this.th_icon("saldo") }></i></Lang></th>
                    <th style={ this.th_style("status") } onClick={() => this.tb_sort("status") }><Lang>Status <i className={ this.th_icon("status") }></i></Lang></th>
                </tr>
                </thead>
            </table>
            <div style={ this.tb_style() }>
                <table className="table table-bordered table-hover tb">
                    <tbody>
                { this.state.dta.map((row, key) =>
                    <tr key={key} onDoubleClick={() => this.rev_dts(row) }>
                        <td>{ row.datum.cdidate() }</td>
                        <td>{ row.day }</td>
                        <td>{ row.tot.fsg.cdipris() }</td>
                        <td>{ row.tot.trf.cdipris() }</td>
                        <td>{ row.tot.oms.cdipris() }</td>
                        <td>{ row.tot.bet.cdipris() }</td>
                        <td>{ row.tot.saldo.cdipris() }</td>
                        <td>{ row.status }</td>
                    </tr>
                )}
                </tbody>
            </table>
            </div>
        </div>
        <div className="flex-row">
         <div className="flex">TOTALER:</div>
         <div className="px-5">FSG: { utils.toPris(this.state.tot.fsg) }</div>
         <div className="px-5">OMS: { utils.toPris(this.state.tot.oms) }</div>
         <div className="px-5">BET: { utils.toPris(this.state.tot.bet) }</div>
           <div className="px-5">SALDO: { utils.toPris(this.state.tot.saldo) }</div>
     </div>

        { this.state.wnds.revdts ? <Rev_Dts wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
    </div>
  );
}
}
export default Eko_Rev;
