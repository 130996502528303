import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";
import * as utils from "../../assets/utils";
import Dt_Cal from "../../lib/ui/dt_cal";
import Dd_Sel from "../../lib/ui/dd_sel";
import * as q from "../../lib/defer";

import PdfDlg from "../../lib/pdf/pdf_dlg";
import VKod_List from "./fkn/vkod_list";
import VSerie_Add from "./fkn/vserie_add";
import VSerie_Edit from "./fkn/vserie_edit";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Fo_Vkod extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, tra: [] };
    this.state.seldate = new Date();
    this.state.typ = "01";
    this.state.datum = "20201231";

      this.state.vsa = [];
      this.state.vpa = [];
    this.state.rda = [];
    this.state.aa = [];
    this.state.kta = [];
    this.state.pdf = 0;
    this.state.pdf_content = null;
    this.state.loading = 0;
    this.state.wnds = {};
    this.state.vpo = {};

    this.state.loading = 0;
    this.state.urval = 1;
    this.state.uaa = [
        {id: '1', text: 'MED SALDO'},
        {id: '0', text: 'ALLA'}
    ];
    this.state.fknitems = [
        {fkn: 'fkn_active', text: 'AKTIVA'},
        {fkn: 'fkn_inactive', text: 'INAKTIVA'},
        {fkn: 'prn_saldo', text: 'KORTSALDO'}
    ];
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    var self = this;
    self.vseries();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 250;
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

  fltr(aa) {
      var self = this;
      //self.setState({loading: 0 });
      //var aa = self.state.rga;
      var a = [];
      for(var row of aa) {
          if(this.state.urval == 1) {
              var saldo = (row.vip02.v2tillgodo * 1);
              if(saldo == 0) continue;
              var text = (row.vip00.v0company);
              //if(text.indexOf("A") < 0) continue;
          }
          a.push( row );
      }
      return a;
    //self.setState({rda: a, loading: 0 });
  }

    vserie_add() {
        var self = this;
        var wnds = {};
        wnds.vsoadd = 1;
        self.setState({ wnds: wnds });
    }
    vserie_edit(vso) {
        var self = this;
        var wnds = {};
        wnds.vsoedit = 1;
        wnds.vso = vso;
        self.setState({ wnds: wnds });
    }
    vkod_list() {
        var self = this;
        var wnds = {};
        wnds.vkodlist = 1;
        self.setState({ wnds: wnds });
    }

  ladda_do() {
    var self = this;
    var prm = {};
    prm.req = "vcm.pres.refill";
    prm.typ = this.state.typ;
    prm.id = "12";
    prm.belopp = "4500";

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        alert(JSON.stringify(ret));
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }

    vseries() {
        var self = this;
        self.setState({vsa: [], loading: 1 });

        var prm = {};
        prm.req = "pos.pos_ecom_vkod.vkod_series";
        prm.vtyp = this.state.typ;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.rca));

                //var a = self.fltr(ret.rca);
                self.setState({vsa: ret.rca, loading: 0 });
                //toastr.success("OK", "SAPI");
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }

    vseries() {
        var self = this;
        self.setState({vpa: [], loading: 1 });

        var prm = {};
        prm.req = "pos.pos_ecom_vkod.vkod_series";
        //prm.vtyp = this.state.typ;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.rca));

                //var a = self.fltr(ret.rca);
                self.setState({vsa: ret.rca, loading: 0 });
                //toastr.success("OK", "SAPI");
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    vkods() {
    var self = this;
    self.setState({vpa: [], loading: 1 });

    var prm = {};
    prm.req = "pos.pos_ecom_vkod.vkod_list";
    prm.vtyp = this.state.typ;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        //var a = self.fltr(ret.rca);
        self.setState({vpa: ret.rca, loading: 0 });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  kts() {
    var self = this;
    var defer = q.defer();

    var prm = {};
    prm.req = "vip.vip_reg.kt_list";
    prm.typ = "";

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        var kta = [];
        for(var row of ret.rca) {
            var o = {};
            o.id = row.vip00.v0ktyp;
            o.text = row.vip00.v0company;
            kta.push( o );
        }
        //alert(JSON.stringify(kta));
        self.setState({kta: kta }, function() {
            var rsp = {};
            rsp.ok = "000";
            defer.resolve(rsp);
        });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        var rsp = {};
        rsp.ok = "999";
        defer.resolve(rsp);
    });

    return defer.promise;
  }
  prn_saldo() {
    var self = this;
    var prm = {};

    prm.req = "vip.vip_reg.reg_list";
    prm.typ = this.state.typ;
    prm.datum = this.state.datum;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        //self.setState({rga: ret.rca });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  prn_fkn(fkn) {
    var self = this;
    var prm = {};
    prm.req = fkn;
    prm.typ = this.state.typ;
    prm.viptyp = this.state.typ;
    prm.datum = "20201231";

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        self.setState({pdf: 1, pdf_content: ret.pdf });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  pdf_done (ret) {
    this.setState({loading: 0, pdf: 0, pdf_content: null });
  }
    sel_fkn(sel) {
        //alert(JSON.stringify(fkn));
        if(sel.fkn == "fkn_active") {
            this.prn_fkn("vip.vip_rp.rp_aktiva");
        }
        if(sel.fkn == "fkn_inactive") {
            this.prn_fkn("vip.vip_rp.rp_inaktiva");
        }
        if(sel.fkn == "prn_saldo") {
            this.prn_fkn("vip.vip_rp.rp_kortsaldo");
        }
    }
    sel_typ (e) {
      var self = this;
      var typ = e.target.value;
      this.setState({typ: typ}, function() {
            self.reg_list();
        });
    }
    sel_urval (e) {
      var self = this;
      self.setState({loading: 1 });
      var urval = e.target.value;
      this.setState({urval: urval}, function() {
          var a = self.fltr(self.state.rga);
        self.setState({rda: a, loading: 0 });
      });
    }

  render() {
    return (
    <div className="web-p00 flex flex-col">
        <div className="">
            VÄRDEKODER
        </div>
    <div className="divider_orange"></div>

    <div className="flex-row">
        <button className="btn btn-hw-blue w150" onClick={() => this.vserie_add() } aria-label="System" >
            LÄGG TILL SERIE
        </button>

        <div className="flex"></div>

        <button className="btn btn-hw-blue w150" onClick={() => this.vkod_list() } aria-label="System" >
            VISA KODER
        </button>
        <button className="btn btn-hw-blue w150" onClick={() => this.vseries() } aria-label="System" >
            UPPDATERA
        </button>
        <div className="">
            <Dd_Sel items={ this.state.fknitems } getValue={(e)=> this.sel_fkn(e)} />
        </div>
    </div>
    <div className="divider_green"></div>

    { this.state.loading ?
        <div className="mx_content flex align-items-center justify-content-center"><FontAwesomeIcon icon="spinner" spin size="3x" /></div>
    :
        <div className="web-row">
            <table className="table table-bordered table-hover tb fixed_header">
                <thead>
                <tr>
                    <th>Serie</th>
                    <th>Text</th>
                    <th>State</th>
                    <th>Antal</th>
                    <th>Skapad</th>
                    <th>Giltigt till</th>
                    <th>Status</th>
                </tr>
                </thead>
                <tbody style={ this.tb_style() }>
                { this.state.vsa.map((vso, key) =>
                    <tr key={key} onDoubleClick={ () => this.vserie_edit(vso) }>
                        <td>{ vso.serie }</td>
                        <td>{ vso.text }</td>
                        <td>{ vso.state }</td>
                        <td>{ utils.toNum(vso.nrof) }</td>
                        <td>{ vso.creat }</td>
                        <td>{ vso.expire }</td>
                        <td>{ vso.status }</td>
                    </tr>
                )}
                </tbody>
            </table>
        </div>
        /*
    <div className="web-row">
        <table className="table table-bordered table-hover tb fixed_header">
            <thead>
            <tr>
				<th>Kod</th>
				<th>Text</th>
				<th>State</th>
				<th>Procent</th>
				<th>Belopp</th>
				<th>Använt</th>
				<th>Notanr</th>
				<th>Status</th>
            </tr>
            </thead>
            <tbody style={ this.tb_style() }>
            { this.state.vpa.map((vpo, key) =>
            <tr key={key} onDoubleClick={ () => this.vkod_edit(vpo) }>
                <td>{ vpo.rkod }</td>
                <td>{ vpo.text }</td>
                <td>{ vpo.state }</td>
                <td>{ vpo.rproc }</td>
                <td>{ vpo.belopp }</td>
                <td>{ vpo.used }</td>
                <td>{ vpo.notanr }</td>
                <td>{ vpo.status }</td>
            </tr>
            )}
            </tbody>
        </table>
    </div>
         */
    }

        { this.state.wnds.vkodlist ? <VKod_List wnds={this.state.wnds} store={this.store} ctx={this} /> : null}
        { this.state.wnds.vsoadd ? <VSerie_Add wnds={this.state.wnds} store={this.store} ctx={this} /> : null}
        { this.state.wnds.vsoedit ? <VSerie_Edit wnds={this.state.wnds} store={this.store} ctx={this} /> : null}
    </div>
  );
    }
}
export default Fo_Vkod;
