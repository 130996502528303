import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";
import * as utils from "../../assets/utils";
import Td_Sel from "../../lib/ui/td_sel";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Bo_Reason extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = {};
    this.state.seldate = new Date();
    this.state.avd = "01";
    this.state.kaa = [];
    this.state.kro = {};
    this.state.aa = [];
      this.state.wnds = {};
      this.state.msg = {};
      this.state.sortid = "";
      this.state.sortdir = 1;

      this.selitems = [
          {fkn: 'open', text: 'ÖPPNA'},
          {fkn: 'stat', text: 'STATUS'}
      ];
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.reason();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
    tb_style() {
        var css = {};
        css.height = window.innerHeight - 250;
        css.overflow = "auto";
        return css;
    }
    th_style(id) {
        var css = {};
        css.cursor = "pointer";
        if(id == this.state.sortid) css.color = "#080";
        return css;
    }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

  reason() {
    var self = this;
    var prm = {};
    prm.req = "ta.ta_def.reason_list";
    //prm.avd = this.state.avd;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        self.setState({kaa: ret.rca });
        self.syncTb("reasonTable");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
    syncTb(tb){
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
            tdElements = tbel.getElementsByTagName('td');

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
                width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
    th_icon(typ) {
        var cls = "";
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;
        if(sortid == typ)
        {
            if(sortdir) cls = "fa fa-caret-up";
            else cls = "fa fa-caret-down";
        }
        return cls;
    }
    tb_sort(typ) {
        var self = this;
        //var rvs = self.state.rvs;
        var kaa = self.state.kaa;
        var sz = this.state.sz_val;
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;

        if(sortid != typ) sortdir = 0;
        else {
            if(sortdir) sortdir = 0;
            else sortdir = 1;
        }

        if(sortdir) kaa.sort((a,b) => (a[typ] > b[typ]) ? 1 : ((b[typ] > a[typ]) ? -1 : 0));
        else kaa.sort((a,b) => (a[typ] < b[typ]) ? 1 : ((b[typ] < a[typ]) ? -1 : 0));
        self.setState({ kaa: kaa, sortid: typ, sortdir: sortdir });
    }
    sel_row_fkn(row, sel) {
        if(sel.fkn == "open") {
            this.cat_edit(row);
        }
    }

  render() {
    return (
    <div className="web-app">
    <div className="flex-row flex-space-between">
        <div className="label_h03">ANLEDNINGAR</div>
        <select className="form-control sel_input" placeholder="Välj avdelning">
            { this.state.aa.map((row, key) =>
            <option key={key} value={row.id}>{row.text}</option>
            )}
        </select>
        <button className="btn btn-hw-blue w150 ripple" onClick={() => this.syncCols("kidTable") } aria-label="System" >
            Visa
        </button>
    </div>
    <div className="divider"></div>

        <div id="reasonTable">
            <table id="coHead" className="table table-bordered table-hover tb" style={{marginBottom: "0px"}}>
                <thead>
                <tr>
                    <th style={ this.th_style("anledning") } onClick={() => this.tb_sort("anledning") }>Anledning <i className={ this.th_icon("anledning") }></i></th>
                    <th style={ this.th_style("namn") } onClick={() => this.tb_sort("namn") }>Text <i className={ this.th_icon("namn") }></i></th>
                    <th style={ this.th_style("art") } onClick={() => this.tb_sort("art") }>Artikel <i className={ this.th_icon("art") }></i></th>
                    <th style={ this.th_style("kat") } onClick={() => this.tb_sort("kat") }>Kategori <i className={ this.th_icon("kat") }></i></th>
                    <th style={ this.th_style("debet") } onClick={() => this.tb_sort("debet") }>Debet <i className={ this.th_icon("debet") }></i></th>
                    <th style={ this.th_style("utart") } onClick={() => this.tb_sort("utart") }>Ut Artikel <i className={ this.th_icon("utart") }></i></th>
                    <th></th>
                </tr>
                </thead>
            </table>
            <div style={ this.tb_style() }>
                <table id="coTable" className="table table-bordered table-hover tb">
                    <tbody>
                    { this.state.kaa.map((row, key) =>
                        <tr key={key} onDoubleClick={() => this.wnd_edit(row)}>
                            <td>{ row.anledning }</td>
                            <td>{ utils.toLang( row.namn ) }</td>
                            <td>{ row.art }</td>
                            <td>{ row.kat }</td>
                            <td>{ row.debet }</td>
                            <td>{ row.utart }</td>
                            <td>
                                <Td_Sel items={ this.selitems } getValue={(e)=> this.sel_row_fkn(row, e)} />
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
        </div>
   </div>
  );
    }
}
export default Bo_Reason;
