import React, { useState, useEffect, useContext } from 'react';

import * as utils from "../../assets/utils";
import * as net from "../../lib/net";
import Lang from "../../lib/lang";

import Dt_Cal from "../../lib/ui/dt_cal";
import Td_Sel from "../../lib/ui/td_sel";
import Dd_Sel from "../../lib/ui/dd_sel";

import ddo from "../../ddo";

import Mnu_Add from "./fkn/mnu_add";
import Mnu_Edit from "./fkn/mnu_edit";
import Mnu_Pub from "./fkn/mnu_pub";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Pos_Menus extends React.Component {
  constructor(props, context) {
    super(props);

    this.store = props.store;

    this.state = {};
    this.state.seldate = new Date();
    this.state.avd = ddo.cache.avd;
    this.state.maa = [];
    this.state.aa = [];
    this.state.mno = {};
    this.state.wnds = {};
      this.state.mna = [];
      this.state.selrow = {};
      this.state.wnds = {};
      this.state.sortid = "";
      this.state.sortdir = 1;

      this.selitems = [
          {fkn: 'open', text: 'ÖPPNA'},
          {fkn: 'cancel', text: 'AVBOKA'}
      ];
      this.fknitems = [
          {fkn: 'mnu_import', text: 'IMPORTERA MENYER'},
          {fkn: 'mnu_pub', text: 'PUBLICERA MENYER'}
      ];
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
    componentDidMount() {
        this.dsi_mnu();
    }

    body_style() {
        var css = {};
        css.height = (window.innerHeight * 1) - 280;
        return css;
    }
    tb_style() {
        var css = {};
        css.height = (window.innerHeight * 1) - 250;
        css.overflow = "auto";
        return css;
    }
    th_style(id) {
        var css = {};
        css.cursor = "pointer";
        if(id == this.state.sortid) {
            if(this.state.sortdir) css.color = "#800";
            else css.color = "#080";
        }
        return css;
    }
    row_style(row) {
        var srow = this.state.selrow;

        var css = {};
        if(srow == row) {
            css.background = "#080";
            css.color = "#fff";
        }
        return css;
    }
    onUnitChange(e) {
        this.setState({unit: e.value});
    }

    mnu_add() {
        var self = this;

        var wnds = {};
        wnds.mnuadd = true;
        //wnds.mno = row;
        this.setState({wnds: wnds});
    }

    mnu_edit(row) {
        var self = this;

        var wnds = {};
        wnds.mnuedit = true;
        wnds.mno = row;
        wnds.cb = self.cb_mnu;
        this.setState({wnds: wnds});
    }

    cb_mnu(ctx, rsp) {
      ctx.dsi_mnu();
    }

    dsi_mnu() {
        var self = this;
        var prm = {};
        prm.req = "dsi.dsi_mnu.mnu_list";

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.rca));

                self.setState({ mna: ret.rca }, function() {
                    self.syncTb("mnuTable");
                });
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    syncTb(tb) {
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
            tdElements = tbel.getElementsByTagName('td');
        if(tdElements.length < 1 ) return;

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
                width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
    sel_row(row) {
        var srow = this.state.selrow;
        if(srow == row) {
            this.setState({selrow: {}});
        }
        else this.setState({selrow: row});
    }
    sel_row_fkn(row, sel) {
        if(sel.fkn == "open") {
            this.sel_gst(row);
        }
    }
    gst_close(ctx) {
        ctx.setState({ wnds: {} });
    };
    tb_sort(typ) {
        var self = this;
        //var rvs = self.state.rvs;
        var mna = self.state.mna;
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;

        if(sortid != typ) sortdir = 0;
        else {
            if(sortdir) sortdir = 0;
            else sortdir = 1;
        }

        if(typ.indexOf(".") != -1) {
            var fa = typ.split(".");
            if(sortdir) mna.sort((a,b) => (a[fa[0]][fa[1]] < b[fa[0]][fa[1]]) ? 1 : ((b[fa[0]][fa[1]] < a[fa[0]][fa[1]]) ? -1 : 0));
            else mna.sort((a,b) => (a[fa[0]][fa[1]] > b[fa[0]][fa[1]]) ? 1 : ((b[fa[0]][fa[1]] > a[fa[0]][fa[1]]) ? -1 : 0));
        }
        else {
            if(sortdir) mna.sort((a,b) => (a[typ] < b[typ]) ? 1 : ((b[typ] < a[typ]) ? -1 : 0));
            else mna.sort((a,b) => (a[typ] > b[typ]) ? 1 : ((b[typ] > a[typ]) ? -1 : 0));
        }
        self.setState({ mna: mna, sortid: typ, sortdir: sortdir });
    }
    th_icon(typ) {
        var cls = "";
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;
        if(sortid == typ)
        {
            if(sortdir) cls = "fa fa-caret-up";
            else cls = "fa fa-caret-down";
        }
        return cls;
    }
    sel_fkn(sel) {
        //alert(JSON.stringify(fkn));
        if(sel.fkn == "mnu_import") {
            //this.plus_copy();
        }
        if(sel.fkn == "mnu_pub") {
            this.mnu_pub();
        }
    }
    mnu_pub() {
        var self = this;
        var wnds = {};
        wnds.mnupub = 1;
        wnds.avd = ddo.cache.avd;
        self.setState({ wnds: wnds });
    }

    render() {
        return (
            <div className="web-bas">

                <div className="flex-row">
                    <button className="btn btn-hw-blue w200 hide" onClick={() => this.tst() } aria-label="System" >
                        MENY FUNKTIONER
                    </button>
                    <button className="btn btn-hw-blue w200" onClick={() => this.mnu_add() } aria-label="System" >
                        LÄGG TILL
                    </button>
                    <div className="flex"></div>
                    <button className="btn btn-hw-blue w200" onClick={() => this.dsi_mnu() } aria-label="System" >
                        UPPDATERA
                    </button>
                    <Dd_Sel items={ this.fknitems } cls="web-top-icon" getValue={(e)=> this.sel_fkn(e)} />
                </div>
                <div className="divider_green"></div>

                <div className="web-row">

                    <div id="mnuTable" className="flex">
                        <table className="table table-bordered table-hover" style={{marginBottom: "0px"}}>
                            <thead>
                            <tr>
                                <th style={ this.th_style("typ") } onClick={() => this.tb_sort("typ") }><Lang>Typ <i className={ this.th_icon("typ") }></i></Lang></th>
                                <th style={ this.th_style("mnuid") } onClick={() => this.tb_sort("mnuid") }><Lang>Meny ID</Lang> <i className={ this.th_icon("mnuid") }></i></th>
                                <th style={ this.th_style("sort") } onClick={() => this.tb_sort("sort") }><Lang>Sortering</Lang> <i className={ this.th_icon("sort") }></i></th>
                                <th style={ this.th_style("text") } onClick={() => this.tb_sort("text") }><Lang>Text</Lang> <i className={ this.th_icon("text") }></i></th>
                                <th style={ this.th_style("mnutyp") } onClick={() => this.tb_sort("mnutyp") }><Lang>Meny Typ</Lang> <i className={ this.th_icon("mnutyp") }></i></th>
                                <th style={ this.th_style("status") } onClick={() => this.tb_sort("status") }><Lang>Status</Lang> <i className={ this.th_icon("status") }></i></th>
                                <th></th>
                            </tr>
                            </thead>
                        </table>
                        <div style={ this.tb_style() }>
                            <table className="table table-bordered table-hover">
                                <tbody>
                                { this.state.mna.map((row, key) =>
                                    <tr key={key} style={ this.row_style(row) } onClick={() => this.sel_row(row)} onDoubleClick={ () => this.mnu_edit(row) }>
                                        <td>{ row.typ }</td>
                                        <td>{ row.mnuid }</td>
                                        <td>{ row.sort }</td>
                                        <td>{ utils.toLang( row.text ) }</td>
                                        <td className="text-center">{ utils.toNum( row.mnutyp ) }</td>
                                        <td>{ row.status }</td>
                                        <td>
                                            <Td_Sel items={ this.selitems } getValue={(e)=> this.sel_row_fkn(row, e)} />
                                        </td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                { this.state.wnds.mnuadd ? <Mnu_Add wnds={this.state.wnds} store={this.store} ctx={this} /> : null}
                { this.state.wnds.mnuedit ? <Mnu_Edit wnds={this.state.wnds} store={this.store} ctx={this} /> : null}
                { this.state.wnds.mnupub ? <Mnu_Pub wnds={this.state.wnds} store={this.store} ctx={this} /> : null}
            </div>
  );
    }
}
export default Pos_Menus;
