import React, { useState, useEffect, useContext } from 'react';
import {Route, Routes} from "react-router-dom";

import * as net from "../../lib/net";

import Bi_Home from "./bi/home";
import Bi_Ov from "./bi/ov";
import Bi_Export from "./bi/export";
import {withRouter} from "../../lib/react/withRouter";
import sso from "../../sso";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Csc_Bi extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, tra: [] };
    this.state.avd = "01";
      var po = {};
      po.ov = { "fid": "f06", "url": "/csc/bi/ov", "name": "Översikt", "active": false };
      po.export = { "fid": "f06", "url": "/csc/bi/export", "name": "Export", "active": false };
      this.state.po = po;
      this.state.pa = [];
      this.state.pa = Object.values(po);
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

    fknGo(row) {
        sso.seo.sfkn = row;
        this.props.navigate(row.url);
    }
    br_css(row) {
        var css = {};
        if(sso.seo.sfkn && (row.url == sso.seo.sfkn.url)) css.color = "#080";
        //var brc = false;
        //if(row.url == sbo.state.url) brc = true;
        return css;
    }

  render() {
    return (
        <div className="web-app">
            { this.props.store.cfg.bread ?
                <div className="web-bread">
                    <div className="breadcrumb">
                        { this.state.pa.map((row, key) =>
                            <div key={key} className="breadcrumb-item" style={this.br_css(row)} onClick={() => this.fknGo(row)}>{row.name}</div>
                        )}
                        <div className="flex"></div>
                        <div className="cdigreen">{ sso.seo.sfkn ? sso.seo.sfkn.name : null }</div>
                    </div>
                </div>
                : null }
        <Routes>
            <Route path="ov" element={ <Bi_Ov store={this.webStore} />} />
            <Route path="export" element={ <Bi_Export store={this.webStore} />} />
            <Route path="*" element={ <Bi_Home store={this.webStore} />} />
        </Routes>
    </div>
  );
}
}
export default withRouter(Csc_Bi);
