import React, { useState, useEffect, useContext } from 'react';
import * as net from "../../../lib/net";

import Pms_Rvo from "../../rv/fb/rvo";
import Pms_Gso from "../../rv/gst/gso";
import Pms_Nota from "../nota/nota";

class Pms_InhCo extends React.Component {
  constructor(props, context) {
    super(props);

    //alert('PBO CTX: ' + JSON.stringify(props.pbo));
   //this.webStore = props.store;
    this.state = {};
    this.state.ynq = [];
    //this.state.vga = [];
    this.state.lso = {};
    //this.state.lro = props.lro;
    this.state.paa = [];
    this.state.aa = [];
    this.state.boknr = "";
    if(props.boknr) this.state.boknr = props.boknr;
    this.state.rvo = {};
    if(props.rvo) this.state.rvo = props.rvo;
    if(props.wnds) {
        this.state.boknr = props.wnds.boknr;
        this.state.rvo = props.wnds.rvo;
    }
    this.state.wnds = {};
    this.state.wnd_rvo = false;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }

    wnd_close() {
		this.props.ctx.setState({ wnds: {} });
	};
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  fknSel(sel) {
    //alert(JSON.stringify(fkn));
      if(sel == "rvo") {
          var wnds = {};
          wnds.wnd_rvo = true;
        this.setState({wnds: wnds});
      }
      if(sel == "gst") {
          var wnds = {};
          wnds.wnd_gso = true;
        this.setState({wnds: wnds});
      }
      if(sel == "nota") {
          var wnds = {};
          wnds.wnd_nota = true;
        this.setState({wnds: wnds});
      }
  }
    co() {
        this.gst_co();
    };

  gst_co() {
    var self = this;

    var prm = {};
    prm.req = "pms.gst_op.gst_checkout";
    prm.boknr = this.state.rvo.boknr;
    prm.glopnr = this.state.rvo.gsb00.r0glopnr;
    prm.rumsnr = this.state.rvo.gsb00.r0rumsnr;
    prm.gsb00 = this.state.rvo.gsb00;
    //alert(JSON.stringify(prm));
    //return;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.ok));
        if(ret.ok) {
		    self.props.ctx.setState({ wnds: {} });
            return;
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

    gst_close(ctx) {
        ctx.setState({ wnds: {} });
    };

  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "";

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="mx_backdrop" /> : null }
        <div className="mx_dlg mx_50 flex-col" style={ this.dlg_style() }>
            <div className="mx_h01 flex-row" style={{background: "#1a456b", color: "#fff"}}>
                    <div className="flex-col flex">
                        <div>CHECKOUT</div>
                    </div>
                    <div className="flex-col">
                        <div>BOKNR: { this.state.boknr }</div>
                    </div>
            </div>
        <div className="mx_content flex">
            <div className="flex"></div>

            <div className="flex-row txt_32">
                <div className="flex text-center">SKALL GÄSTEN CHECKAS UT?</div>
            </div>

            <div className="flex"></div>
            <div className="divider_orange"></div>
        <div className="web-row">
        <div className="web-col flex">
            <div className="wnd-btn" onClick={() => this.wnd_close() } >
                <div>STÄNG</div>
                <div></div>
            </div>
        </div>
         <div className="web-col flex" style={{padding: "0px 10px"}}>
       </div>
         <div className="web-col flex" style={{padding: "0px 10px"}}>
            <div className="wnd-btn" onClick={() => this.co() } >
                <div>CHECKA UT</div>
                <div></div>
            </div>
       </div>
       </div>

        </div>
      </div>
            { this.state.wnds.wnd_rvo ?
        <Pms_Rvo close={this.rvo_close} boknr={this.state.boknr} ctx={this} store={this.props.store} backdrop={true}/>
            : null }
    </div>
	;
	return html;
  };
}

export default Pms_InhCo;