import React, { useState, useEffect, useContext } from 'react';
import {format} from "date-fns";

import * as net from "../../lib/net";
import * as utils from "../../assets/utils";

import Dt_Cal from "../../lib/ui/dt_cal";
import Td_Sel from "../../lib/ui/td_sel";
import Pms_FbRvo from "../rv/fb/rvo";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class PMS_Rwl extends React.Component {
  constructor(props, context) {
    super(props);

    this.selitems = [
        {fkn: 'open', text: 'ÖPPNA'},
        {fkn: 'cancel', text: 'AVBOKA'}
    ];

    this.store = props.store;

    this.state = { pgid: 11, tra: [] };
    this.state.seldate = new Date();
    this.state.fdat = new Date();
    this.state.tdat = new Date();
    this.state.avd = "01";
    this.state.rva = [];
    this.state.kro = {};
    this.state.aa = [];
    this.state.cols = {};
    this.state.boknr = "";
    this.state.rvo = false;
    this.state.wnds = {};
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.rvl();
  }
  sel_row_fkn(row, sel) {
    //alert(JSON.stringify(fkn));
      if(sel.fkn == "open") {
          this.rv_edit(row);
      }
      if(sel.fkn == "cancel") {
          //this.rvp_cancel(row.resv00.resno);
      }
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  rv_style(row) {
      var css = {};
      if(row.resv00.status == "C") {
        //css.background = "#800";
        css.color = "#800";
        css.fontStyle = "oblique";
      }
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

  rv_edit(row) {
      var self = this;
      var wnds = {};
      wnds.rvo = 1;
      wnds.boknr = row.resv00.resno;
      wnds.cb = this.cb_rv;
    this.setState({wnds: wnds, boknr: row.resv00.resno});
  }
   cb_rv(ctx, ret) {
    //alert(JSON.stringify(ret));
    if(ret && (ret.ok == "000")) {
        ctx.rvl();
        //ctx.st_state();
        ctx.setState({ wnds: {} });
    }
    else ctx.setState({wnds: {}});
  }
  fkn_close() {
    this.setState({edit: false });
    //this.props.history.push("cas");
    //this.login_srv(uid, pwd);
  }
  st_state() {
    var self = this;

    var prm = {};
    prm.req = "pms.cal.pms_dt_state";
    prm.datum = "";

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        alert(JSON.stringify(ret));

        self.props.store.pms.stat = ret.rco;
    })
    .catch(function(e) {
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }

  rvl() {
    var self = this;
    var fdatum = format(this.state.fdat, "yyyy-MM-dd");
    var tdatum = format(this.state.tdat, "yyyy-MM-dd");

      self.setState({ rva: [] });

    var prm = {};
    prm.req = "pms.rv_fkn.rv_rev";

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        self.setState({rva: ret.rco }, function () {
            self.syncTb("rvlTable");
        });
    })
    .catch(function(e) {
        var txt = JSON.stringify(e);
    });
  }
    syncTb(tb) {
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
        tdElements = tbel.getElementsByTagName('td');

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
            width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
    mshow(part) {
        var css = {};
        css.display = "none";
        if(part == 0) {
            if(this.state.rva.length < 1) css.display = "";
        }
        if(part == 1) {
            if(this.state.rva.length > 0) css.display = "";
        }
        return css;
    }

  render() {
    return (
    <div className="web-app">

    <div className="flex-row flex-space-between">
        <div className="flex-row">
            <Dt_Cal startdatum="2020-02-01" getValue={(e)=> this.setState({fdat: e}) }/>
            <Dt_Cal startdatum="2020-02-01" getValue={(e)=> this.setState({tdat: e}) }/>
        </div>
        <div className="flex"></div>
        <button className="btn btn-hw-blue w150 ripple" onClick={() => this.rvl() } aria-label="System" >
            Visa
        </button>
    </div>
    <div className="divider_orange"></div>

        <div className="web-col flex-center" style={ this.mshow(0) }>
            <div className="flex-row flex-center">
                <div className="txt_22">LADDAR ...</div>
            </div>
        </div>
        <div className="web-col" style={ this.mshow(1) }>
        <div id="rvlTable" className="flex">
        <table className="table table-bordered table-hover tb " style={{marginBottom: "0px"}}>
            <thead>
            <tr>
                <th>Boknr</th>
                <th>Företag</th>
                <th>Namn</th>
                <th>Telefon</th>
                <th>Ankomst</th>
                <th>Avresa</th>
                <th>Signatur</th>
                <th>Status</th>
                <th></th>
            </tr>
            </thead>
        </table>
       <div className="tb-cdi" style={ this.tb_style() }>
       <table className="table table-bordered table-hover tb">
            <tbody>
            { this.state.rva.map((row, key) =>
            <tr key={key} style={ this.rv_style(row) } onDoubleClick={() => this.rv_edit(row) }>
                <td>{ row.resv00.resno }</td>
                <td>{ row.resv00.Company }</td>
                <td>{ row.resv01.Name }</td>
                <td>{ row.resv01.Tfn }</td>
                <td>{ utils.toDate( row.ankdat ) }</td>
                <td>{ utils.toDate( row.avrdat ) }</td>
                <td>{ row.sign }</td>
                <td>{ row.resv00.status }</td>
                <td>
                    <Td_Sel items={ this.selitems } getValue={(e)=> this.sel_row_fkn(row, e)} />
                </td>
            </tr>
            )}
            </tbody>
        </table>
        </div>
        </div>
    </div>
        { this.state.wnds.rvo ?
            <Pms_FbRvo wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
        : null }
   </div>
  );
    }
}
export default PMS_Rwl;
