import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../../lib/net";
import Wnd_Ok from "../../../lib/ui/wnd_ok";
import Td_Sel from "../../../lib/ui/td_sel";

import Bqq_Add from "./bqq_add";
import Bqq_Edit from "./bqq_edit";

class Bqq_Reg extends React.Component {
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.state = {};
    this.state.bqa = [];
    this.state.tra = [];
    this.state.msg = {};
    this.state.wnds = {};
    this.state.sortid = "";
    this.state.sortdir = 1;

      if(props.wnds) {
        if(props.wnds.bqa) this.state.bqa = props.wnds.bqa;
      }
      this.selitems = [
          {fkn: 'open', text: 'ÖPPNA'},
          {fkn: 'prn', text: 'SKRIV UT'}
      ];
   }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      this.bqq_list();
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    tab_style(tabid) {
      var css = {};
      css.display = "none";
      if(this.state.tabval === tabid) css.display = "block";
      return css;
    }
    bq_close() {
		this.props.ctx.setState({ wnds: {} });
	};
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      css.overflow = "auto";
      return css;
  }
    th_style(id) {
        var css = {};
        css.cursor = "pointer";
        if(id == this.state.sortid) {
            if(this.state.sortdir) css.color = "#800";
            else css.color = "#080";
        }
        return css;
    }
    bqq_list() {
    var self = this;
    var prm = {};
      prm.req = "pos.pos_bqq.bqq_list";

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        self.setState({bqa: ret.rca });
        self.syncTb("bqqTable");
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }
  row_bong(row) {
    var self = this;
    var prm = {};
    prm.req = "pos.pos_bq.bongrow";
    prm.row = row;
    prm.status = "09";

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        //self.setState({bqa: ret.rca });
        //toastr.success("OK", "SAPI");
        var msg = { ok: true, text: "BONGAD ..." };
        self.setState({msg: msg });
        setTimeout(function(){
            self.setState({msg: {} });
        }, 1000);
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }
  prn(event) {
  }
    bqq_add() {
        var self = this;

        var wnds = {};
        wnds.bqqadd = true;
        self.setState({wnds: wnds });
    }
    bqq_edit(bqo) {
        var self = this;

        var wnds = {};
        wnds.bqqedit = true;
        wnds.bqo = bqo;
        wnds.bqa = this.state.bqa;
        self.setState({wnds: wnds });
    }
    syncTb(tb) {
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
            tdElements = tbel.getElementsByTagName('td');
        if(tdElements.length < 1 ) return;

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
                width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
    tb_sort(typ) {
        var self = this;
        //var rvs = self.state.rvs;
        var tha = self.state.tha;
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;

        if(sortid != typ) sortdir = 0;
        else {
            if(sortdir) sortdir = 0;
            else sortdir = 1;
        }

        if(typ) {
            if(sortdir) tha.sort((a,b) => (a[typ] < b[typ]) ? 1 : ((b[typ] < a[typ]) ? -1 : 0));
            else tha.sort((a,b) => (a[typ] > b[typ]) ? 1 : ((b[typ] > a[typ]) ? -1 : 0));
        }
        self.setState({ tha: tha, sortid: typ, sortdir: sortdir });
    }
    th_icon(typ) {
        var cls = "";
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;
        if(sortid == typ)
        {
            if(sortdir) cls = "fa fa-caret-up";
            else cls = "fa fa-caret-down";
        }
        return cls;
    }

  render() {
    //if(!this.props.wnds.rs) return "";

    var title = this.props.title;
    if(!title) title = "Titel";

	var html =
        <div className="mx_base">
        { this.props.wnds.backdrop ? <div className="mx_backdrop" /> : null }
        <div className="mx_dlg mx_90 flex-col" style={ this.dlg_style() }>
        <div className="mx_content flex">

            <div className="flex-row" style={{padding:"5px",height:"35px"}}>
                    <div className="flex-col flex">
                        <div>BQ REGISTER</div>
                    </div>
                    <div className="flex-col">
                        <div onClick={() => this.bqq_list() }>UPPDATERA</div>
                    </div>
            </div>
            <div className="divider_orange"></div>

        <div id="bqqTable" className="web-col">
            <table className="table table-bordered table-hover tb" style={{marginBottom: "0px"}}>
                <thead>
                <tr>
                <th style={ this.th_style("b0q") } onClick={() => this.tb_sort("b0q") }>BQ Kanal <i className={ this.th_icon("b0q") }></i></th>
                <th style={ this.th_style("b0pos") } onClick={() => this.tb_sort("b0pos") }>Position <i className={ this.th_icon("b0pos") }></i></th>
                <th style={ this.th_style("b0text") } onClick={() => this.tb_sort("b0text") }>Text <i className={ this.th_icon("b0text") }></i></th>
                <th style={ this.th_style("b0status") } onClick={() => this.tb_sort("b0status") }>Status <i className={ this.th_icon("b0status") }></i></th>
                <th></th>
                </tr>
                </thead>
            </table>
            <div className="tb-cdi" style={ this.tb_style() }>
            <table className="table table-bordered table-hover tb">
                <tbody>
                { this.state.bqa.map((row, key) =>
                <tr key={key} onDoubleClick={() => this.bqq_edit(row) }>
                    <td>{ row.b0q }</td>
                    <td>{ row.b0pos }</td>
                    <td>{ row.b0text }</td>
                    <td>{ row.b0status }</td>
                    <td>
                        <Td_Sel items={ this.selitems } getValue={(e)=> this.sel_row_fkn(row, e)} />
                    </td>
                </tr>
                )}
                </tbody>
            </table>
            </div>
        </div>
        </div>

        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.bq_close() } >
                STÄNG
            </button>
            <div className="flex-row"></div>
            <button className="btn btn-hw-blue w250" onClick={() => this.bqq_add() } >
                LÄGG TILL
            </button>
            <div className="flex-row"></div>
            <button className="btn btn-hw-green w250" onClick={(event) => this.prn(event) } >
                SKRIV UT
            </button>
        </div>
      </div>
            { this.state.wnds.bqqadd ? <Bqq_Add wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true} /> : null}
            { this.state.wnds.bqqedit ? <Bqq_Edit wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true} /> : null}
        <Wnd_Ok msg={this.state.msg} ctx={this} />
    </div>
	;
	return html;
  };
}

export default Bqq_Reg;