import React, { useState, useEffect, useContext } from 'react';

import * as utils from "../../assets/utils";
import * as net from "../../lib/net";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Bo_Ftg extends React.Component {
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.state = {};
    this.state.aa = [];
      this.state.wnds = {};
      this.state.msg = {};
      this.state.gpo = {};
      this.state.gpo.gpost1 = {};
      this.state.gpo.gpost2 = {};
      this.state.gpo.gpost3 = {};
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.ftg_rd();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      css.overflow = "auto";
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

  ftg_rd() {
    var self = this;
    var prm = {};
    prm.req = "ta.ta_bo.company";
    //prm.avd = this.state.avd;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        self.setState({gpo: ret.rco });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

  render() {
    return (
    <div className="web-app">

    <div className="flex-row">
        <div className="label_h03">FÖRETAG</div>
        <div className="flex"></div>
        <button className="btn btn-hw-blue w150" onClick={() => this.ftg_rd() } aria-label="System" >
            Visa
        </button>
    </div>
    <div className="divider_blue"></div>

    <div className="flex-row txt_18">
        <div className="flex"></div>
        <div className="web-col flex">
            <div className="">Företag: { utils.toLang( this.state.gpo.gpost1.namn ) }</div>
            <div className="">Adress: { utils.toLang( this.state.gpo.gpost1.adress ) }</div>
            <div className="">Postnr: { this.state.gpo.gpost1.postnr }</div>
            <div className="">Postadress: { utils.toLang( this.state.gpo.gpost1.postadr ) }</div>
            <div className="">Telefon: { this.state.gpo.gpost2.tfnnr }</div>
            <div className="">Orgnr: { this.state.gpo.gpost2.orgnr }</div>
        </div>
        <div className="w50"></div>
        <div className="web-col flex">
            <div className="">Period Start: { this.state.gpo.gpost1.periodstart }</div>
            <div className="">Period Slut: { this.state.gpo.gpost1.periodslut }</div>
            <div className="">Generell Mot: { this.state.gpo.gpost1.generellmot }</div>
        </div>
        <div className="flex"></div>
    </div>

   </div>
  );
    }
}
export default Bo_Ftg;
