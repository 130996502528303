import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../../lib/net";
import Dd_Sel from "../../../lib/ui/dd_sel";
import Td_Sel from "../../../lib/ui/td_sel";

import PdfNotaDlg from "./fkn/pdf_nota_dlg";
import Pms_Kvitto from "./fkn/kvitto";
import sso from "../../../sso";

class Pms_GstNotor extends React.Component {
  constructor(props, context) {
    super(props);

     this.selitems = [
        {fkn: 'setup', text: 'INSTÄLLNINGAR'},
        {fkn: 'upd', text: 'UPPDATERA'},
        {fkn: 'sys', text: 'SYSTEM'},
        {fkn: 'logout', text: 'LOGGA UT'}
    ];
     this.rowitems = [
        {fkn: 'open', text: 'ÖPPNA'},
        {fkn: 'print', text: 'SKRIV UT'},
        {fkn: 'pdf', text: 'VISA PDF'},
        {fkn: 'corr', text: 'KORRIGERA'},
        {fkn: 'recall', text: 'ÅTERVINN'}
    ];

    //alert('PBO CTX: ' + JSON.stringify(props.pbo));
   //this.webStore = props.store;
    this.state = {};
    this.state.ynq = [];
    this.state.nta = [];
    this.state.aa = [];
    if(props.wnds) {
        this.fkn_close = props.wnds.fkn_close;
        this.state.boknr = props.wnds.boknr;
        this.state.rvo = props.wnds.rvo;
    }

    this.state.pdf = 0;
    this.state.pdf_content = null;
    this.state.loading = 0;
    this.state.wnds = {};
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      this.notor();
  }
  pdf_done (ret) {
    this.setState({loading: 0, pdf: 0, pdf_content: null });
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }

    wnd_close() {
      if(this.fkn_close) this.fkn_close(this.props.ctx);
      else this.props.ctx.setState({ wnds: {} });
	};
  tb_style() {
      var css = {};
      //css.height = window.innerHeight - 275;
      css.overflow = "auto";
      return css;
  }
  sel_row_fkn(row, sel) {
      if(sel.fkn == "open") {
          this.sel_row(row);
      }
      if(sel.fkn == "print") {
          this.prn_kvitto(row.notanr);
      }
      if(sel.fkn == "pdf") {
          this.prn_spec(row.notanr);
      }
      if(sel.fkn == "corr") {
          this.corr(row.notanr);
      }
      if(sel.fkn == "recall") {
          this.recall(row.notanr);
      }
  }
  sel_row(row) {
    var self = this;
      //var notanr = row.notanr;
      //this.prn_spec(notanr);
    var wnds = {};
    wnds.kvitto = 1;
    wnds.boknr = this.state.rvo.boknr;
    wnds.gso = this.state.rvo.gsb00;
    wnds.nto = row;
    this.setState({wnds: wnds});
  }
  prn_bilaga() {
    var self = this;

    var prm = {};
    prm.req = "pms.charge.prn_bilaga";
    prm.boknr = this.state.rvo.boknr;
    prm.glopnr = this.state.rvo.gsb00.r0glopnr;
    //alert(JSON.stringify(prm));
    //return;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.ok));

        //var uri = "data:application/pdf;base64," + ret.pdf;
        self.setState({pdf: 1, pdf_content: ret.pdf });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  prn_spec(notanr) {
    var self = this;

    var prm = {};
    prm.req = "pms.charge.prn_spec";
    prm.boknr = this.state.rvo.boknr;
    prm.glopnr = this.state.rvo.gsb00.r0glopnr;
    prm.notanr = notanr;
    //alert(JSON.stringify(prm));
    //return;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.ok));

        //var uri = "data:application/pdf;base64," + ret.pdf;
        self.setState({pdf: 1, pdf_content: ret.pdf });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  prn_kvitto(notanr) {
    var self = this;

    var prm = {};
    prm.req = "pms.charge.kvitto_gst";
    prm.boknr = this.state.rvo.boknr;
    prm.gso = this.state.rvo.gsb00;
    prm.prnnr = sso.seo.prnnr;
    prm.notanr = notanr;
    //alert(JSON.stringify(prm));
    //return;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.ok));

        //var uri = "data:application/pdf;base64," + ret.pdf;
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  recall(notanr) {
    var self = this;

    var prm = {};
    prm.req = "pms.charge.recall_nota";
    prm.boknr = this.state.rvo.boknr;
    prm.glopnr = this.state.rvo.gsb00.r0glopnr;
    prm.notanr = notanr;
    //alert(JSON.stringify(prm));
    //return;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.ok));

        //var uri = "data:application/pdf;base64," + ret.pdf;
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  corr(notanr) {
    var self = this;

    var prm = {};
    prm.req = "pms.charge.corr_nota";
    prm.boknr = this.state.rvo.boknr;
    prm.glopnr = this.state.rvo.gsb00.r0glopnr;
    prm.notanr = notanr;
    //alert(JSON.stringify(prm));
    //return;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.ok));

        //var uri = "data:application/pdf;base64," + ret.pdf;
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  notor() {
    var self = this;
    var prm = {};
    prm.req = "pms.charge_bill.gst_bills";
    prm.boknr = this.state.rvo.boknr;
    prm.glopnr = this.state.rvo.gsb00.r0glopnr;
    prm.gsb00 = this.state.rvo.gsb00;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        self.setState({ nta: ret.rca }, function () {
            self.syncTb("notorTable");
        });
        //self.syncTb("notorTable");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
    syncTb(tb){
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
        tdElements = tbel.getElementsByTagName('td');

        for (let i = 0; i < thElements.length; i++) {
            if(!tdElements[i]) continue;
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
            width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }

  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "";

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="m2_backdrop" /> : null }
        <div className="m2_dlg mx_70h flex-col" style={ this.dlg_style() }>
        <div className="flex-row " style={{padding:"0px",height:"50px",lineHeight:"50px", background: "#1a456b", color: "#fff"}}>
                <div className="flex-row flex" style={{padding:"0px 5px", fontSize: "22px"}}>
                    <div>Notor { this.state.rumsnr }</div>
                </div>
            <div className="wnd-close-btn" onClick={() => this.wnd_close() }>X</div>
        </div>
        <div className="mx_content flex">

        <div className="web-row flex">

        <div id="notorTable" className="flex">
          <table className="table table-bordered table-hover tb" style={{marginBottom: "0px"}}>
            <thead>
            <tr>
                <th>Datum</th>
                <th>Notanr</th>
                <th>Text</th>
                <th>Antal</th>
                <th>Belopp</th>
                <th>Tecken</th>
                <th></th>
            </tr>
            </thead>
        </table>
        <div style={ this.tb_style() }>
          <table className="table table-bordered table-hover tb">
            <tbody>
            { this.state.nta.map((row, key) =>
            <tr key={key} onDoubleClick={ () => this.sel_row(row) }>
                <td>{ row.datum.cdidate() }</td>
                <td>{ row.notanr.ztrim() }</td>
                <td>{ row.txt }</td>
                <td>{ row.antal.ztrim() }</td>
                <td>{ row.bet.cdipris() }</td>
                <td>{ row.obet }</td>
                <td>
                    <Td_Sel items={ this.rowitems } getValue={(e)=> this.sel_row_fkn(row, e)} />
                </td>
            </tr>
            )}
            </tbody>
        </table>
        </div>
        </div>

        </div>

        </div>
        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250 ripple" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex-row">
            </div>
            <button className="btn btn-hw-green w250 ripple" onClick={(event) => this.sel_default() } >
                VÄLJ
            </button>
        </div>
      </div>

        { this.state.wnds.kvitto ? <Pms_Kvitto wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
        <PdfNotaDlg show={this.state.pdf} pdf={this.state.pdf_content} done={(ret) => this.pdf_done(ret)} backdrop={true}/>
    </div>
	;
	return html;
  };
}

export default Pms_GstNotor;