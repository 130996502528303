import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../../lib/net";

import Fr_Input from "../../../lib/ui/fr_input";

import Pms_Rvo from "../../rv/fb/rvo";
import Pms_Gso from "../../rv/gst/gso";
import Pms_Nota from "../nota/nota";
import sso from "../../../sso";

class Pms_CiKeys extends React.Component {
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.state = {};
    this.state.ynq = [];

    this.state.paa = [];
    this.state.aa = [];
    this.state.boknr = "";
    this.state.rvo = {};
    this.state.gso = {};
    this.state.room = {};
    this.state.wnds = {};

    if(props.wnds) {
        this.state.boknr = props.wnds.boknr;
        this.state.gso = props.wnds.gso;
        //this.state.gsb00 = props.wnds.gsb00;
        this.state.rvo.charges = {};
   }
    this.state.msg = {};
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    body_css() {
      var css = {};
      if(this.state.msg.typ == "error") {
          css.background = "#800";
          css.color = "#fff";
      }
      if(this.state.msg.typ == "success") {
          css.background = "#080";
          css.color = "#fff";
      }
      return css;
    }

    wnd_close() {
		this.props.ctx.setState({ wnds: {} });
	};
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }

  key_new() {
    var self = this;

    var prm = {};
    prm.req = "pms.hk.room_key";
    prm.rumsnr = this.state.gso.r0rumsnr;
    prm.kodare = sso.seo.encoder;
    //prm.nrk = this.state.gso.r0guests;
    prm.nrk = "1";
    prm.datum = this.state.gso.r0avrdatum;
    //alert(JSON.stringify(prm));

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok == "000") {
            self.setState({msg: {"typ": "success"} });
            setTimeout(function(){
                self.setState({msg: {} });
            }, 1500);
        }
        else {
            self.setState({msg: {"typ": "error"} });
            setTimeout(function(){
                self.setState({msg: {} });
            }, 1500);
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
    return;
  }
  key_copy() {
    var self = this;

    var prm = {};
    prm.req = "pms.hk.room_key_copy";
    prm.rumsnr = this.state.gso.r0rumsnr;
    prm.kodare = sso.seo.encoder;
    //prm.nrk = this.state.gso.r0guests;
    prm.nrk = "1";
    prm.datum = this.state.gso.r0avrdatum;
    //alert(JSON.stringify(prm));

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok == "000") {
            self.setState({msg: {"typ": "success"} });
            setTimeout(function(){
                self.setState({msg: {} });
            }, 1500);
        }
        else {
            self.setState({msg: {"typ": "error"} });
            setTimeout(function(){
                self.setState({msg: {} });
            }, 1500);
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
    return;
  }
    val_save (name, value) {
      var room = this.state.room;
      //var na = name.split(".");
      //gso[na[0]][na[1]] = value;
      room[name] = value;
      this.setState({room: room});
      //setState({[name]: value});
    }

  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "";

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="mx_backdrop" /> : null }
        <div className="m2_dlg mx_50 flex-col" style={ this.dlg_style() }>
        <div className="mx_h01 flex-row" style={{background: "#1a456b", color: "#fff"}}>
                <div className="flex-col flex">
                    <div>NYCKLAR</div>
                </div>
                <div className="flex-col">
                    <div>BOKNR: { this.state.boknr }</div>
                </div>
        </div>
        <div className="mx_content flex">

            <div className="text-center txt_18">NYCKELKODARE</div>
            <div className="text-center txt_22">{ sso.seo.encoder }</div>
            <div className="flex"></div>
            <div className="txt_box flex-col flex">
            <div className="flex-row">
                <div className="wnd_link">
                    GÄST
                </div>
                <div className="flex"></div>
                <div className="wnd_link" onClick={() => this.gst_upd() }>
                    UPPDATERA
                </div>
            </div>
            <div className="flex-row">
                    <div className="flex-column flex py-2">
                        <div className="txt_lbl">NAMN:</div>
                        <div className="txt_data">{ this.state.gso.r0namn.toswe() }</div>
                    </div>
                    <div className="flex-column flex py-2">
                        <div className="txt_lbl">RUMSNR:</div>
                        <div className="txt_data">{ this.state.gso.r0rumsnr.cdirumsnr() }</div>
                    </div>
            </div>
            <div className="flex-row">
                    <div className="flex-column flex py-2">
                        <div className="txt_lbl">GÄSTER:</div>
                        <div className="txt_data">{ this.state.gso.r0guests.ztrim() }</div>
                    </div>
            </div>
            </div>
            <div className="flex"></div>

            <div className="divider_orange"></div>
        <div className="web-row">
        <div className="web-col flex">
            <div className="wnd-btn" onClick={() => this.wnd_close() } >
                <div>STÄNG</div>
                <div></div>
            </div>
        </div>
         <div className="web-col flex" style={{padding: "0px 10px"}}>
            <div className="wnd-btn" onClick={() => this.key_copy() } >
                <div>KOPIE NYCKEL</div>
                <div></div>
            </div>
       </div>
         <div className="web-col flex" style={{padding: "0px 10px"}}>
            <div className="wnd-btn" onClick={() => this.key_new() } >
                <div>NY NYCKEL</div>
                <div></div>
            </div>
       </div>
       </div>

        </div>
      </div>
            { this.state.wnds.wnd_rvo ?
        <Pms_Rvo close={this.rvo_close} boknr={this.state.boknr} ctx={this} store={this.props.store} backdrop={true}/>
            : null }
    </div>
	;
	return html;
  };
}

export default Pms_CiKeys;