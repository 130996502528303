import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../../lib/net";
import Fr_Input from "../../../lib/ui/fr_input";
import Fr_Select from "../../../lib/ui/fr_select";

class Trs_Open extends React.Component {
  constructor(props, context) {
    super(props);

    this.pos = props.store.pos;
    this.state = {};
    this.state.mka = [];
    this.state.sval = [];
    this.state.tval = [];
    this.state.paa = [];
    this.state.uaa = [];
    this.state.laa = [];

    this.state.gnr = "001";
    //this.state.lro = props.lro;
    this.state.ynq = [
                {id: '0', text: 'NEJ'},
                {id: '1', text: 'JA'}
            ];

    this.state.wnd_ok = false;
    this.state.wnd_err = false;

    this.state.cols = {};
    this.state.rrs00 = {};
    this.state.tro = {};
    if(props.wnds.tro) {
        this.state.rrs00 = props.wnds.tro;
        this.state.tro = JSON.parse(JSON.stringify(props.wnds.tro));
        this.state.tro.r0perprice = props.wnds.tro.r0perprice.cdipris();
    }
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.btn_grps();
      if(!this.props.wnds.tro) this.tro_init();
  }

    tro_init() {
        var tro = {};
        tro.e0bastyp = "";
        tro.e0datum = "";
        tro.e0lopnr = "";
        tro.e0subnr = "";
        tro.e0typ = "";
        tro.e0klockan = "";
        tro.e0filler0 = "";
        tro.e0kontonr = "";
        tro.e0text = "";
        tro.e0antal = "";
        tro.e0pris = "";
        tro.e0tkn = "";
        tro.e0moms = "";
        tro.e0rumsnr = "";
        tro.e0sign = "";
        tro.e0updtime = "";
        //alert(JSON.stringify(rso));
        this.setState({ tro: tro });
        return;
    }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }

    formchange (e) {
      const name = e.target.name;
      const value = e.target.value;
      var pbo = this.state.pbo;
      var na = name.split(".");
      pbo[na[0]][na[1]] = value;
      this.setState({pbo: pbo});
      //setState({[name]: value});
    }

    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    wnd_close = () => {
      if(this.props.close) {
          this.props.close(this.props.ctx);
      }
      else this.props.ctx.setState({ wnds: {} });
	};
    toggle() {
      this.setState({modal: false});
	};

  submitHandler = event => {
    event.preventDefault();
    event.target.className += " was-validated";
  };

  changeHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  val_save(id, val) {
      var tro = this.state.tro;
      tro[id] = val;
        this.setState({ tro: tro });
      return;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  tr_save() {
    var self = this;
    var tro = this.state.tro;
      tro.r0text = tro.r0text.fromswe();
      tro.r0perprice = tro.r0perprice.pris2cdi();

    var prm = {};
      //prm.req = "pos.pos_trs.trs_upd";
      prm.req = "pos.pos_trs.trh_upd";
    prm.rrs00 = this.state.rrs00;
    prm.tro = this.state.tro;

    //alert(JSON.stringify(prm));
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));
        if(ret.ok == "000") {

            var rrs00 = JSON.parse(JSON.stringify(self.state.tro));
            var msg = { info: false, ok: true, text: "Sparad ..."};
            self.setState({ rrs00: rrs00, msg: msg });

            setTimeout(function(){
                self.setState({msg: {} });
                  if(self.props.close) {
                      self.props.close(self.props.ctx);
                  }
                  else self.props.ctx.setState({ wnds: {} });
                return;
            }, 1000);
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

  render() {
    //if(!this.props.show) return "";
    var title = this.props.title;
    if(!title) title = "Resurs";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        <div className="m2_backdrop" />
        <div className="m2_dlg mx_70h flex-col" style={ this.dlg_style() } >
        <div className="mx_content flex">

        <div className="mx_h01">
            <div>TRANSAKTIONS POST</div>
            <div>: { this.state.tro.r0dateid  }</div>
        </div>
            <div className="flex-row" style={{padding:"5px",height:"35px"}}>
                    <div className="flex-col flex">
                    </div>
                    <div className="flex-col">
                        <div>UPPDATERA</div>
                    </div>
            </div>
            <div className="divider_orange"></div>

            <form className="cdi_f03">
            <div className="flex-row">
                    <Fr_Input label="Datum" className="text-left w150" text={this.state.tro.r0datum} getValue={(e)=> this.val_save("r0datum", e) } />
                    <Fr_Input label="Tid" className="text-left w150" text={this.state.tro.r0time} getValue={(e)=> this.val_save("r0time", e) } />
            </div>
            <div className="flex-row">
                    <Fr_Input label="Notanr" className="text-left w250" text={this.state.tro.r0faktnr} getValue={(e)=> this.val_save("r0faktnr", e) } />
                    <Fr_Input label="Löpnr" className="text-left w250" text={this.state.tro.r0lopnr} getValue={(e)=> this.val_save("r0lopnr", e) } />
            </div>
                <div className="flex-row">
                    <Fr_Input label="Avd" className="text-left w150" text={this.state.tro.r0cstplc} getValue={(e)=> this.val_save("r0cstplc", e) } />
                    <Fr_Input label="Bordsnr" className="text-left w150" text={this.state.tro.r0tablnr} getValue={(e)=> this.val_save("r0tablnr", e) } />
                </div>
                <div className="divider_green"></div>
                <div className="flex-row">
                    <Fr_Input label="Typ" className="text-left w150" text={this.state.tro.r0etyp} getValue={(e)=> this.val_save("r0etyp", e) } />
                    <Fr_Input label="Transtyp" className="text-left w150" text={this.state.tro.r0ttyp} getValue={(e)=> this.val_save("r0ttyp", e) } />
                </div>
                <div className="flex-row">
                    <Fr_Input label="Resttyp" className="text-left w150" text={this.state.tro.r0etyprest} getValue={(e)=> this.val_save("r0etyprest", e) } />
                    <Fr_Input label="Radnr" className="text-left w150" text={this.state.tro.r0radnr} getValue={(e)=> this.val_save("r0radnr", e) } />
                </div>
            <div className="flex-row">
                    <Fr_Input label="Text" className="text-left w350" text={this.state.tro.r0text} getValue={(e)=> this.val_save("r0text", e) } />
            </div>
            <div className="divider_green"></div>
            <div className="flex-row">
                    <Fr_Input label="Antal" className="text-left w250" text={this.state.tro.r0noof} getValue={(e)=> this.val_save("r0noof", e) } />
                    <Fr_Input label="Tecken" className="text-left w150" text={this.state.tro.r0debcre} getValue={(e)=> this.val_save("r0debcre", e) } />
            </div>
            <div className="flex-row">
                    <Fr_Input label="Pris" className="text-left w250" text={this.state.tro.r0perprice} getValue={(e)=> this.val_save("r0perprice", e) } />
                    <Fr_Input label="Moms" className="text-left w250" text={this.state.tro.r0vatproc} getValue={(e)=> this.val_save("r0vatproc", e) } />
            </div>
            <div className="divider_green"></div>
                <div className="flex-row">
                    <Fr_Input label="Rumsnr" className="text-left w250" text={this.state.tro.r0roomno} getValue={(e)=> this.val_save("r0roomno", e) } />
                    <Fr_Input label="Gästnr" className="text-left w150" text={this.state.tro.r0guestno} getValue={(e)=> this.val_save("r0guestno", e) } />
                </div>
                <div className="flex-row">
                    <Fr_Input label="Kassalåda" className="text-left w150" text={this.state.tro.r0draw} getValue={(e)=> this.val_save("r0draw", e) } />
                    <Fr_Input label="Sign" className="text-left w250" text={this.state.tro.r0sign} getValue={(e)=> this.val_save("r0sign", e) } />
                </div>
                <div className="flex-row">
                    <Fr_Input label="Terminal" className="text-left w250" text={this.state.tro.r0terminal} getValue={(e)=> this.val_save("r0terminal", e) } />
                    <Fr_Input label="Init" className="text-left w150" text={this.state.tro.r0init} getValue={(e)=> this.val_save("r0init", e) } />
                </div>

            </form>

        </div>
        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex"></div>
            <button className="btn btn-hw-red w250" onClick={() => this.tr_save() } >
                SPARA
            </button>
        </div>
      </div>

      </div>
	;
	return html;
  };
}

export default Trs_Open;