import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../../lib/net";
import Dd_Sel from "../../../lib/ui/dd_sel";
import Fr_Input from "../../../lib/ui/fr_input";

class Key_Edit extends React.Component {
  constructor(props, context) {
    super(props);

     this.selitems = [
        {fkn: 'setup', text: 'INSTÄLLNINGAR'},
        {fkn: 'upd', text: 'UPPDATERA'},
        {fkn: 'sys', text: 'SYSTEM'},
        {fkn: 'logout', text: 'LOGGA UT'}
    ];

    //alert('PBO CTX: ' + JSON.stringify(props.pbo));
   //this.webStore = props.store;
    this.state = {};
    this.state.ynq = [];
    //this.state.vga = [];
    this.state.lso = {};
    //this.state.lro = props.lro;
    this.state.paa = [];
    this.state.aa = [];

    this.state.cso = {};
    if(props.wnds) {
        this.state.cso = props.wnds.cso;
    }

    this.state.korttyper = [
      { typ: 'D', text: 'DALLAS' },
      { typ: 'K', text: 'SPAIDER KORT' }
    ];

  }
  componentDidUpdate() {
  }

  componentWillUnmount() {
  }

  componentDidMount() {
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }

    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    wnd_close() {
      if(this.props.cb) this.props.cb(this.props.ctx);
      else this.props.ctx.setState({ wnds: {} });
	  };

    fknSel(sel) {
    //alert(JSON.stringify(fkn));
      if(sel == "rvo") {
        this.setState({wnd_rvo: true});
      }
      if(sel == "gst") {
        this.setState({wnd_gst: true});
      }
    }

  val_save(name, val) {
    var self = this;
    var cso = self.state.cso;

    var na = name.split(".");
    var n1 = na[0];
    var n2 = na[1];
    cso[n1][n2] = val
    self.setState({ cso: cso });
  }

  val_key_save(name, val) {
    var self = this;
    var cso = self.state.cso;

    cso.key[name] = val
    self.setState({ cso: cso });
  }

  key_save() {
    var self = this;
    var cso = self.state.cso;

    if(cso.key.n0nyckeltyp == "K") cso.key.n0nyckelid = cso.key.n0nyckelid.toString().tonum(37);

    var prm = {};
    prm.req = "pos.pos_cr.cr_key_upd";
    prm.hkod = this.state.cso.ser00.s0hkod;
    prm.typ = cso.key.n0nyckeltyp;
    prm.keyid = cso.key.n0nyckelid;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  change_key(e) {
    //let cdat = this.state.seldate;
    let typ = e.target.value;
    var cso = this.state.cso;
    cso.key.n0nyckeltyp = typ;
    this.setState({ cso: cso });
  }

  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "";

	var html =
      <div className="mx_base">
        { this.props.backdrop ? <div className="m2_backdrop" /> : null }
        <div className="m2_dlg mx_70 flex-col" style={ this.dlg_style() }>
        <div className="flex-row" style={{padding:"0px",height:"50px",lineHeight:"50px", background: "#1a456b", color: "#fff"}}>
                <div className="flex-row flex" style={{padding:"0px 5px", fontSize: "22px"}}>
                    <div>Nyckel { this.state.cso.ser00.s0namn }</div>
                </div>
            <div className="wnd-close-btn" onClick={() => this.wnd_close() }>X</div>
        </div>

        <div className="mx_content flex">

            <div className="divider_orange"></div>

            <form className="cdi_f03">
            <div className="flex-row py-2">
                    <Fr_Input label="Signatur" className="text-left w150" text={this.state.cso.ser00.s0hkod} getValue={(e)=> this.val_save("ser00.s0hkod", e) } />
            </div>
            <div className="flex-row py-2 hide">
                    <Fr_Input label="Nyckeltyp" className="text-left w150" text={this.state.cso.key.n0nyckeltyp} getValue={(e)=> this.val_key_save("n0nyckeltyp", e) } />
            </div>
            <div className="flex-row py-2 form-group">
            <span className="header txt_12">Nyckel typ</span>
            <select className="form-control w250" placeholder="Välj typ" onChange={(e) => this.change_key(e)} value={this.state.cso.key.n0nyckeltyp}>
              <option value="">Välj typ</option>
              { this.state.korttyper.map((row, key) =>
                <option key={key} value={row.typ}>{row.typ} - {row.text}</option>
              )}
            </select>
            </div>
            <div className="flex-row py-2">
                  <Fr_Input label="Nyckel" className="text-left w450" text={this.state.cso.key.n0nyckelid} getValue={(e)=> this.val_key_save("n0nyckelid", e) } />
            </div>
            <div className="divider_green"></div>
            </form>

        </div>

        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex-row">
            </div>
            <button className="btn btn-hw-green w250" onClick={(event) => this.key_save() } >
                SPARA
            </button>
        </div>
      </div>
    </div>
	;
	return html;
  };
}

export default Key_Edit;