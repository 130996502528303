import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../../lib/net";
import {withRouter} from "../../../lib/react/withRouter";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Bi_Home extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = {};
    this.state.seldate = new Date();
    this.state.avd = "01";
      this.state.paa = [];
      this.state.tra = [];
    this.state.aa = [];
    //context.posrend = this;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

  fknGo(url) {
    //alert(row.href);
    this.props.navigate(url);
  }

  render() {
    return (
    <div className="web-sub">
    <h3>BUSINESS INTELLIGENCE</h3>

    <div className="web-p01 flex">

    <div className="web-row">
    <div className="web-col flex">
        <div className="web-btn" onClick={() => this.fknGo("/csc/bi/ov") } >
            <div>Översikt</div>
            <div>Daglig översikt</div>
        </div>
    </div>
     <div className="web-col flex" style={{padding: "0px 10px"}}>
   </div>
     <div className="web-col flex">
        <div className="web-btn" onClick={() => this.fknGo("/csc/bi/export") } >
            <div>Export</div>
            <div>Export av transaktionsdata</div>
        </div>
   </div>
   </div>

    </div>

    </div>
  );
    }
}
export default withRouter(Bi_Home);
