import React, { useState, useEffect, useRef, useContext } from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

//import './css/dd_sel.css';

class Wnd_Confirm extends React.Component {
  constructor(props, context) {
    super(props);

    this.node = React.createRef();
    //this.setNodeRef = this.setNodeRef.bind(this);

    this.state = {};
    this.state.cols = {};
    this.state.sel_show = false;
    this.state.sel_step = 0;
  }

  componentDidUpdate() {
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick);
  }
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClick);
  }

  handleClick = (e) => {
    if(!this.props.msg.confirm) return;

    if (!this.node.current.contains(e.target)) {
      //this.setState({sel_show: false});
      this.props.ctx.setState({ msg: {} });
      return;
    }
  }
  sel_fkn(val) {
    this.props.ctx[this.props.msg.cb](val);
    //this.props.ctx.setState({ msg: {} });
  }
  close_info() {
    this.props.ctx.setState({ msg: {} });
  }
  render() {
    if(!this.props.msg.confirm) return "";

    const infownd = {
      position: 'absolute',
      width: '35%',
      height: '35%',
      zIndex: '19999',
      top: '35%',
      left: '35%',
        border: "1px solid #ccc",
        borderRadius: "5px",
        backgroundColor: "#0575E6",  /* fallback for old browsers */
        background: "-webkit-linear-gradient(to right, #021B79, #0575E6)",  /* Chrome 10-25, Safari 5.1-6 */
        backgroundImage: "linear-gradient(to right, #021B79, #0575E6)" /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    }

    var title = this.props.title;
    if(!title) title = "URVAL";

	var html =
        <div ref={this.node} style={ infownd } className="d-flex flex-column" >
        <div className="flex d-flex flex-column align-content-center justify-content-center text-center">
        <h2 className="" style={{color: "#fff"}}>
            { this.props.msg.text }
        </h2>
        { this.props.msg.textx ?
        <h2 className="" style={{color: "#fff"}}>
            { this.props.msg.textx }
        </h2>
        : null }
        <FontAwesomeIcon className="hide" icon="info" size="4x" style={{color: "#fff"}} />
        </div>

        <div className="flex-row justify-content-center mb-3">
            <button className="btn btn-hw-red w200" onClick={(event) => this.sel_fkn("0") } >
                { this.props.msg.text0 }
            </button>
            <button className="btn btn-hw-green w200" onClick={(event) => this.sel_fkn("1") } >
                { this.props.msg.text1 }
            </button>
        </div>

        </div>
	;
	return html;
  };
}

export default Wnd_Confirm;