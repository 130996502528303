import React, { useState, useEffect, useContext } from 'react';
import ReactDOM from 'react-dom';
import { SDOContext } from '../sdo.js'
import { Chart } from 'chart.js'
import {format, addDays, subDays, getHours} from "date-fns";
import { sv } from 'date-fns/locale';
import {withRouter} from "../lib/react/withRouter";
import { observer } from 'mobx-react';

import styled from "styled-components"

//import './css/style-liberty.css';

import * as net from "../lib/net";
import Dt_Cal from '../lib/ui/dt_cal.js';
import Dt_Txt from '../lib/ui/dt_txt.js';
import Pie from '../lib/ui/pcirc.js';
import ddo from "../ddo";
  
class Pms_Dash_11 extends React.Component {
    //var sdo = useContext(SDOContext); // Functional
    static contextType = SDOContext; // Class

    constructor(props, context) {
        super(props);

        this.s1ref = React.createRef();
        this.s2ref = React.createRef();
        this.dt_dt = React.createRef();

        this.my_bar = null;
        this.my_pie = null;
        this.my_stack = null;
        this.my_line = null;
        this.webStore = props.store;
        this.state = {};
        this.state.open = false;
        this.state.pdfopen = false;
        this.state.xxopen = false;
        this.state.pdf_uri = "";
        this.state.seldate = new Date('2020-03-24T13:11:54');
        this.state.row = null;
        this.state.info = "";
        this.state.top = 1;
        this.state.user = {};
        this.state.sdo = {};

        //this.state.sdo = context;
        //context.posrend = this;
        this.state.step = 1;
        this.state.pdf = 0;
        this.state.pdf_uri = "";
        this.state.mnus = ["m00", "m01", "m02", "m03", "m08", "m09"];
        this.state.mnu_sel = "m00";

        this.state.today = new Date();
        var hh = getHours(this.state.today);
        if( hh < 5) {
            const dt = subDays(new Date(), 1);
            this.state.dts = format(dt, "yyyy-MM-dd");
        }
        else this.state.dts = format(this.state.today, "yyyy-MM-dd");

        this.state.dayinfo = format(this.state.today, "EEEE", { locale: sv });
        this.state.dayinfo = this.state.dayinfo + ", vecka: " + format(this.state.today, "w");

        this.state.inap = 0;
        this.state.utap = 0;
        this.state.infg = "#4755AB";
        this.state.utfg = "#4755AB";

        this.chartColors = {
            navy: '#4755AB',
            green: '#03c895',
            danger: '#ff4f81',
            blue: '#4A496E',
            purple: '#B162AC',
            grey: '#E7EDF6'
        };
    }
    componentDidMount(){
        //var sdo = sessionStorage.getItem("svp");
        //if(sdo) this.setState({sdo: JSON.parse(sdo) });
        this.st_io();

        this.bar_chart();
        this.ota_chart();
        //this.pie_chart();
        //this.stack_chart();
        //this.line_chart();
    }

    componentWillUnmount(){
            this.setState({info: "UUU" });
    }

   st_io() {
    var self = this;

      //var msg = { info: true, text: "Hämtar notor ..."};
      //self.setState({ dta: [], msg: msg });

    var prm = {};
    prm.req = "pms.cal.pms_st_io";
    prm.datum = this.state.dts;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.ok));

        if(ret.ok != "000") {
            self.setState({ msg: {} });
            return;
        }
        //alert(JSON.stringify(ret.rco));
        self.setState({sdo: ret.rco, inap: -1, utap: -1, msg: {} }, function () {
            //self.syncTb("dtaTable");
            var infg = "#800";
            if(ret.rco.inap > 50) infg = "#ff9900";
            if(ret.rco.inap > 90) infg = "#080";
            var utfg = "#800";
            if(ret.rco.utp > 50) utfg = "#ff9900";
            if(ret.rco.utp > 90) utfg = "#080";
            self.setState({inap: ret.rco.inap, infg: infg, utfg: utfg, utap: ret.rco.utp});
        });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
    }

  header_css() {
  	    var css = {};
		if(this.state.top) {
			css.color = "#fff";
			css.background = "transparent";
			css.height = "50px";
			//css["background"] = "green";
		}
		else {
			css.color = "#000";
			css.background = "#fff";
		    css.height = "50px";
        }

		return css;
    }
    header_class() {
  	    var cls = "flex_col";
		if(this.state.top) {
			cls = cls + " web-head_1";
		}
		else cls = cls +  " web-header";

		return cls;
    }
    dt_dat(e) {
        //alert(JSON.stringify(e));
        var dts = format(e, "yyyy-MM-dd");
        var dayinfo = format(e, "EEEE", { locale: sv });
        dayinfo = dayinfo + ", vecka: " + format(e, "w");
        this.setState({ dts: dts, dayinfo: dayinfo });
      }
    fknGo(url) {
        //alert(row.href);
        this.props.navigate(url);
    }

    pie_chart() {
        var config = {
            type: 'pie',
            data: {
                datasets: [{
                    data: [
                        25,
                        38,
                        66,
                        15,
                        90,
                    ],
                    backgroundColor: [
                        this.chartColors.navy,
                        this.chartColors.blue,
                        this.chartColors.purple,
                        this.chartColors.danger,
                        this.chartColors.grey,
                    ],
                    label: 'Dataset 1'
                }],
                labels: [
                    'navy',
                    'blue',
                    'purple',
                    'danger',
                    'grey'
                ]
            },
            options: {
                responsive: true
            }
        };

        var ctx4 = document.getElementById('piechart').getContext('2d');
        this.my_pie = new Chart(ctx4, config);
	};
    stack_chart() {
        this.my_stack =  new Chart(document.getElementById("stackedbar"), {
			type: 'bar',
			data: {
				labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
				datasets: [{
					data: [10,20,30,40,50,60,70,80],
					label: 'Dataset 1',
					backgroundColor: this.chartColors.navy,
					borderWidth: 1,
				}, {
					data: [30,10,70,15,30,20,70,80],
					label: 'Dataset 2',
					backgroundColor: this.chartColors.purple,
					borderWidth: 1,
				}, {
					data: [20,30,40,50,60,70,80,80],
					label: 'Dataset 3',
					backgroundColor: this.chartColors.grey,
				}]
			},
			options: {
				// title: {
				// 	display: true,
				// 	text: 'Chart.js Bar Chart - Stacked'
				// },
				tooltips: {
					mode: 'index',
					intersect: false
				},
				responsive: true,
				scales: {
					x: {
						stacked: true,
					},
					y: {
						stacked: true
					}
				}
			}
		});
	};
    line_chart() {

        this.my_line =  new Chart(document.getElementById("linechart"), {
            type: 'line',
            data: {
                labels: [],
                datasets: [{
                    label: 'Ankommit',
                    backgroundColor: this.chartColors.navy,
                    borderColor: this.chartColors.navy,
                    data: [],
                    fill: false,
                }, {
                    label: 'Ej ankommit',
                    fill: false,
                    backgroundColor: this.chartColors.purple,
                    borderColor: this.chartColors.purple,
                    data: [],
                }]
            },
            options: {
                responsive: true,
                // title: {
                // 	display: true,
                // 	text: 'Chart.js Line Chart'
                // },
                tooltips: {
                    mode: 'index',
                    intersect: false,
                },
                hover: {
                    mode: 'nearest',
                    intersect: true
                },
                scales: {
                    x: {
                        display: true,
                        scaleLabel: {
                            display: true,
                            labelString: 'Rumstyper'
                        }
                    },
                    y: {
                        display: true,
                        scaleLabel: {
                            display: true,
                            labelString: 'Antal'
                        }
                    }
                }
            }
        });
    };
    ota_chart() {

        this.my_ota =  new Chart(document.getElementById("otachart"), {
            type: 'bar',
            data: {
                labels: ['System', 'Web', 'Hotels', 'Booking'],
                datasets: [{
                    data: [100,20,30,80],
                    label: 'Dataset 1',
                    backgroundColor: "#4755AB",
                    borderWidth: 1,
                    }]
            },
            options: {
                plugins: {
                  legend: {
                    display: false
                  }
                }
              }
        });
    };
    bar_chart() {

        this.my_bar =  new Chart(document.getElementById("barchart"), {
            type: 'bar',
            data: {
                labels: ['Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lördag', 'Söndag'],
                datasets: [{
                    data: [10,20,30,40,50,60,70,80],
                    label: 'v.38',
                    backgroundColor: "#4755AB",
                    borderWidth: 1,
                    }, {
                        data: [10,20,30,40,50,60,70,80],
                        label: 'v.37',
                        backgroundColor: "#800",
                        borderWidth: 1,
                        }]
            },
        });
/*
        this.my_bar =  new Chart(document.getElementById("barchart"), {
            type: 'bar',
            data: {
                datasets: [{
                    data: [10],
                    label: 'Måndag',
                    backgroundColor: "#4755AB",
                    borderWidth: 1,
                }, {
                    data: [30],
                    label: 'Tisdag',
                    backgroundColor: "#E7EDF6",
                    borderWidth: 1,
                }, {
                    data: [60],
                    label: 'Onsdag',
                    backgroundColor: "#E7EDF6",
                    borderWidth: 1,
                }, {
                    data: [50],
                    label: 'Torsdag',
                    backgroundColor: "#E7EDF6",
                    borderWidth: 1,
                }, {
                    data: [75],
                    label: 'Fredag',
                    backgroundColor: "#E7EDF6",
                    borderWidth: 1,
                }]
            },
            options: {
                responsive: true,
            }
        });
        */
	};

    render() {

        return (
            <div className="web-app" style={{background: "#eff1f9"}}>
 
                <div className="flex-row py-3">
                    <div className="flex"></div>
                    <div className="flex-col">
                    <Dt_Txt className="txt_32" dts={this.state.dts} ref={this.dt_dt} getValue={(e)=> this.dt_dat(e)} />   
                    <div className="txt_16">{this.state.dayinfo}</div>
                    <div className="txt_18"></div>
                    </div>
                    <div className="flex"></div>
                    <div className="" onClick={() => this.st_io() }>UPPDATERA</div>
                </div>

                <div className="flex-col flex px-5">
                    <div className="flex-row flex">
                            <div className="card card_border border-primary-top p-4 flex">
                            <div className="flex-row">
                                <div className="flex-col flex">
                                    <h3 className="">Check-In</h3>
                                    <div className="">Ankommande: { this.state.sdo.ankr}/{ this.state.sdo.ankg}</div>
                                    <div className="">Incheckade: { this.state.sdo.inar}</div>
                                    <div className="">Ej Incheckade: { this.state.sdo.inan}</div>
                                </div>
                                <div className="flex-col">
                                <div className="w75">
                                    { this.state.inap > -1 ?<Pie percent={ this.state.sdo.inap } color={this.state.infg} /> : null }
                                </div>
                                </div>
                            </div>
                            </div>

                            <div className="card card_border border-primary-top p-4 flex">
                            <div className="flex-row">
                                <div className="flex-col flex">
                                    <h3 className="">Check-Out</h3>
                                    <div className="">Avresande: { this.state.sdo.avrr}/{ this.state.sdo.avrg}</div>
                                    <div className="">Utcheckade: { this.state.sdo.utr}</div>
                                    <div className="">Ej Utcheckade: { this.state.sdo.utn}</div>
                                </div>
                                <div className="flex-col">
                                <div className="w75">
                                    { this.state.utap > -1 ? <Pie percent={ this.state.sdo.utp } color={this.state.utfg} /> : null }
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>

                </div>

                <div className="flex-col flex px-5">
                    <div className="flex-row flex">
                    <div className="card card_border border-primary-top p-4 flex">
                            <h3 className="" onClick={() => this.fknGo("/pms/ov") }>Bokat</h3>
                            <div id="container">
                                <canvas id="barchart"></canvas>
                            </div>
                       </div>
                        <div className="card card_border border-primary-top p-4 flex">
                        <h3 className="">Kanaler</h3>
                        <div id="container">
                                <canvas id="otachart"></canvas>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        );
    }

}
//WebPub.contextType = SDOContext; // Function
export default withRouter(observer(Pms_Dash_11));

const Txt01 = styled.div`
  font-size: 32px;
  color: #222;
`
