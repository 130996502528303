import React, { useState, useEffect, useContext } from 'react';
import {format, addDays, subDays} from "date-fns";

import * as net from "../../../../lib/net";
import * as utils from "../../../../assets/utils";

import Br_Open from "./br_open";
import Br_PkatAdd from "./br_pkat_add";
import Rv_GstAdd from "../gst/gst_add";
import Rv_GstEdit from "../gst/gst_edit";
import Edit_Txt from "../../../../lib/ui/edit_txt";
import Td_Sel from "../../../../lib/ui/td_sel";

class Br_Edit extends React.Component {
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pms = props.store.pms;
    this.pms.wrk = {};

    this.state = {};
    this.state.today = new Date();
    this.state.rta = [];
    this.state.nba = [];
    this.state.pka = [];
    this.state.pkat = "";
    this.state.pko = {};
    this.state.pks = {};

    this.state.gnr = "001";
    this.state.gbo = {};
    //this.state.lro = props.lro;
    this.state.ynq = [
                {id: '0', text: 'NEJ'},
                {id: '1', text: 'JA'}
            ];

    this.state.background = "#fff";

    this.state.part = "01";
    this.state.wnds = {};
    this.state.cols = {};
    this.state.lrwrk = false;
    this.state.boknr = {};
    this.state.bro = {};
    this.state.gsa = [];
    if(props.wnds) {
        if(props.wnds.boknr) this.state.boknr = props.wnds.boknr;
        if(props.wnds.bro) {
            this.state.bro = props.wnds.bro;
            this.pms.wrk.bro = props.wnds.bro;
        }
    }

    this.pkatitems = [
        {fkn: 'open', text: 'ÖPPNA'},
        {fkn: 'cancel', text: 'AVBOKA'}
    ];
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.br_pkats();
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    grp_style(row) {
      var css = {};
      css.background = "#008";
      if(this.state.gnr === row.vgrupp) css.background = "#080";
      return css;
    }

    formchange (e) {
      const name = e.target.name;
      const value = e.target.value;
      var pbo = this.state.pbo;
      var na = name.split(".");
      pbo[na[0]][na[1]] = value;
      this.setState({pbo: pbo});
      //setState({[name]: value});
    }

    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    wnd_close = () => {
        var self = this;
        if( self.props.cb ) {
            var ret = {};
            ret.ok = "000";
            self.props.cb(self.props.ctx, ret);
        }
        else {
            this.props.ctx.setState({ wnds: {} });
            var ret = {};
            ret.ok = "999";
            //this.props.cb(this.props.ctx, ret);
        }
	};
    handleChange = (event, newValue) => {
        //alert(newValue);
        this.setState({ix: newValue});
      };
    toggle() {
      this.setState({modal: false});
	};

    submitHandler = event => {
        event.preventDefault();
        event.target.className += " was-validated";
    };

    changeHandler = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    rsv_save (name, value) {
        //alert(JSON.stringify(name));
        //alert(JSON.stringify(value));
        var rsv = this.state.bro;
        var na = name.split(".");
        rsv[na[0]][na[1]] = value;
        this.setState({rsv: rsv});
        //setState({[name]: value});
    }
    val_save (name, value) {
        //alert(JSON.stringify(name));
        //alert(JSON.stringify(value));
      var bro = this.state.bro;
      bro[name] = value;
      this.setState({bro: bro});
      //setState({[name]: value});
    }
    rt_set (name, e) {
      const value = e.target.value;
      var bro = this.state.bro;
      bro[name] = value;
      this.setState({bro: bro});
      //setState({[name]: value});
    }
    pkat_set (e) {
      const value = e.target.value;
      var pks = this.state.pks;
      var pko = pks[value];
      this.setState({pkat: pko.n0typ, pko: pko});
      //setState({[name]: value});
    }
    pkat_pris_set (row, e) {
      //const value = e.target.value;
      ///this.setState({pkat: pko.n0typ, pko: pko});
        //alert(row.n0pris);
        //alert(e);
        this.br_pkat_upd(row, e);
    }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  tab_style(part) {
      var css = {};
      if(this.state.part == part) { css.background = "#fff"; css.color = "#009900"; }
      return css;
  }

  br_init() {
    var self = this;
    var dat = addDays(this.state.today, 1);
    var fdat = format(this.state.today, "yyyy-MM-dd");
    var tdat = format(dat, "yyyy-MM-dd");

    var bro = {};
    bro.fdat = fdat;
    bro.tdat = tdat;
    bro.nrr = "1";
    bro.nrg = "1";
    bro.nrgr = "1";
    bro.nrb0 = "0";
    bro.nrb1 = "0";
    bro.nrb2 = "0";
    bro.rt = "";
    bro.pkat = "";
    bro.rab = "";
    this.setState({bro: bro});
  }
  rts() {
    var self = this;
    var prm = {};
    prm.req = "pms.rt.rt_date";
    //prm.datum = this.state.datum;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            return;
        }
        self.setState({rta: ret.rco});
        self.pkats();
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  pkats() {
    var self = this;
    var prm = {};
    prm.req = "pms.pkat.qa_pkats";
    //prm.datum = this.state.datum;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            return;
        }
        self.setState({pka: ret.rca, pks: ret.rco});
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

  br_save() {
    var self = this;
    var prm = {};
    prm.req = "pms.br.br_upd";
    prm.boknr = this.state.boknr;
    prm.rblx0 = this.state.bro;
    //prm.nyp00 = this.state.pko;
    //alert(JSON.stringify(prm));
    //return;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok == "000") {
		    self.props.cb(self.props.ctx, ret);
            return;
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  br_del() {
    var self = this;

    var prm = {};
    prm.req = "pms.br.br_del";
    //prm.fkn = "7217";
    prm.boknr = this.state.boknr;
    prm.rblx0 = this.state.bro;
    //alert(JSON.stringify(prm));

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            return;
        }
        self.props.cb(self.props.ctx, ret);
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
    return;
  }
  br_pkats() {
    var self = this;
    var prm = {};
    prm.req = "pms.br.br_pkats";
    prm.rblx0 = this.state.bro;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok == "000") {
            self.setState({nba: ret.rca});
        }

        self.gsts();
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
    br_pkat_set() {
    var self = this;

    var prm = {};
    prm.req = "pms.br.br_row_pkat";
    prm.boknr = this.state.boknr;
    prm.rblx0 = this.state.bro;
    prm.nyp00 = this.state.pko;
    //alert(JSON.stringify(prm));

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            return;
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
    return;
  }
    br_pkat_upd(nyb00, pris) {
    var self = this;

    var prm = {};
    prm.req = "pms.br.br_pkat_upd";
    prm.boknr = this.state.boknr;
    prm.pris = pris;
    prm.nyb00 = nyb00;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            return;
        }
        self.br_pkats();
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
    return;
  }
  gsts() {
    var self = this;

    var prm = {};
    prm.req = "pms.gst.gsa_br";
    prm.boknr = this.state.boknr;
    prm.blopnr = this.state.bro.r0lopnr;
    //alert(JSON.stringify(prm));

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok == "000") {
            self.setState({gsa: ret.rca });
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
    return;
  }

  br_open() {
      //var wnds = {bropen: true, boknr: this.state.boknr, bro: this.state.bro};
      var wnds = {};
      wnds.bropen = true;
      wnds.boknr = this.state.boknr;
      wnds.bro = this.pms.wrk.bro;
      //wnds.rvo = this.state.rvo;
      this.setState({ wnds: wnds });
  }
  br_pkat_add() {
      var wnds = {};
      wnds.pkatadd = true;
      wnds.boknr = this.state.boknr;
      wnds.bro = this.state.bro;
      wnds.cb = this.cb_pkat;
      this.setState({wnds: wnds});
  }
  br_pkat_edit() {
      var wnds = {};
      wnds.pkatadd = true;
      wnds.boknr = this.state.boknr;
      wnds.bro = this.state.bro;
      wnds.cb = this.cb_pkat;
      this.setState({wnds: wnds});
  }
  br_gst_add() {
      var wnds = {gstadd: true, boknr: this.state.boknr, bro: this.state.bro};
      this.setState({wnds: wnds});
  }
  br_gst_edit(row) {
      var wnds = {gstedit: true, boknr: this.state.boknr, bro: this.state.bro, gso: row};
      this.setState({wnds: wnds});
  }
  show_part(part) {
        //if(part == "02") this.rv_kf();
        this.setState({part: part});
  };
    cb_pkat(ctx, ret) {
        ctx.br_pkats();
        ctx.setState({wnds: {}});
    };
    cb_gst(ctx, ret) {
        ctx.gsts();
        ctx.setState({wnds: {}});
    };

  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "Delbokning";
    //if(props.kro) alert(props.kro.krr00.kid);
    const popover = {
      position: 'absolute',
      top: '50px',
      zIndex: '2',
    }
    const cover = {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    }

	var html =
        <div className="mx_base">
        <div className="m2_backdrop" />
        <div className="m2_dlg mx_80h flex-col" style={ this.dlg_style() }>

        <div className="mx_h01">
            <div>DELBOKNING: {this.pms.wrk.bro.r0lopnr.ztrim()} </div>
        </div>
        <div className="mx_content flex">

            <div className="divider_orange"></div>

            <div className="txt_box txt_bg_01 txt_22">
            <div className="flex-row">
                <div className="wnd_link">
                    DELBOKNING
                </div>
                <div className="flex"></div>
                <div className="wnd_link" onClick={() => this.br_open() }>
                    ÄNDRA
                </div>
            </div>
            <div className="flex-row py-2">
                <div className="flex-row flex">
                    <div className="rv-lbl">Från:</div>
                    <div className="rv-txt">{this.pms.wrk.bro.r0ankdatum.cdidate()}</div>
                </div>
                <div className="flex-row flex">
                    <div className="rv-lbl">Till:</div>
                    <div className="rv-txt">{this.pms.wrk.bro.r0avrdatum.cdidate()}</div>
                </div>
            </div>
            <div className="flex-row py-2">
                <div className="flex-row flex">
                    <div className="rv-lbl">Antal Rum:</div>
                    <div className="rv-txt">{this.pms.wrk.bro.r0borr.ztrim()}</div>
                </div>
                <div className="flex-row flex">
                    <div className="rv-lbl">Antal Gäster:</div>
                    <div className="rv-txt">{this.pms.wrk.bro.r0borg.ztrim()}</div>
                </div>
            </div>
            <div className="flex-row py-2">
                <div className="flex-row flex">
                    <div className="rv-lbl">Barn (0-4):</div>
                    <div className="rv-txt">{this.pms.wrk.bro.r0antb0.ztrim()}</div>
                </div>
                <div className="flex-row flex">
                    <div className="rv-lbl">Barn (5-18):</div>
                    <div className="rv-txt">{this.pms.wrk.bro.r0antb1.ztrim()}</div>
                </div>
            </div>
            <div className="flex-row py-2">
                <div className="flex-row flex">
                    <div className="rv-lbl">Rumstyp:</div>
                    <div className="rv-txt">{this.pms.wrk.bro.r0typ}</div>
                </div>
            </div>

            </div>

            <div className="divider_green"></div>

            <div className="txt_box flex txt_22">
            <div className="flex-row">
                <div className="wnd_link">
                    PRISER
                </div>
                <div className="flex"></div>
                <div className="wnd_link" onClick={() => this.br_pkat_add() }>
                    LÄGG TILL
                </div>
            </div>
            <div className="flex flex-row">
                <table className="table" style={{border: "0px", margin: "0px", padding: "0px"}}>
                    <thead style={{fontSize: "14px", fontWeight: "normal", color: "#080", border: "0px", margin: "0px", padding: "0px"}}>
                    <tr>
                        <th style={{textAlign: "left"}}>Datum</th>
                        <th style={{textAlign: "left"}}>Text</th>
                        <th style={{textAlign: "left"}}>Pkat</th>
                        <th style={{textAlign: "left"}}>Baspris</th>
                        <th style={{textAlign: "left"}}>Pris</th>
                        <th style={{textAlign: "left"}}></th>
                    </tr>
                    </thead>
                    <tbody style={{fontSize: "18px", color: "#000", border: "0px", margin: "0px", padding: "0px", borderBottom: "1px solid #ccc"}}>
                    { this.state.nba.map((row, key) =>
                    <tr>
                        <td>{ row.n0datum.cdidate() }</td>
                        <td>{ row.n0text }</td>
                        <td>{ row.n0typ }</td>
                        <td>{ row.n0baspris.cdipris() }</td>
                        <td className=""><Edit_Txt text={ row.n0pris.cdipris() } getValue={(e)=> this.pkat_pris_set(row, e) } /></td>
                        <td>
                            <Td_Sel items={ this.pkatitems } getValue={(e)=> this.br_pkat_edit(row, e)} />
                        </td>
                    </tr>
                    )}
                    </tbody>
                </table>
            </div>
             </div>

            <div className="txt_box flex txt_22">
            <div className="flex-row">
                <div className="wnd_link">
                    GÄSTER
                </div>
                <div className="flex"></div>
                <div className="wnd_link" onClick={() => this.br_gst_add() }>
                    LÄGG TILL
                </div>
            </div>
            <div className="flex flex-row" style={{border: "0px", margin: "0px", padding: "0px"}}>
                <table className="table" style={{border: "0px", margin: "0px", padding: "0px"}}>
                    <thead style={{fontSize: "14px", fontWeight: "normal", color: "#080", border: "0px", margin: "0px", padding: "0px"}}>
                    <tr>
                        <th style={{textAlign: "left"}}>Gäst</th>
                        <th style={{textAlign: "left"}}>Rumsnr</th>
                        <th style={{textAlign: "left"}}>Rumstyp</th>
                        <th style={{textAlign: "left"}}>Löpnr</th>
                        <th style={{textAlign: "left"}}>Namn</th>
                        <th style={{textAlign: "left"}}>Gäster</th>
                        <th style={{textAlign: "left"}}>Status</th>
                        <th style={{textAlign: "center", color: "#800"}}></th>
                    </tr>
                    </thead>
                    <tbody style={{fontSize: "18px", color: "#000", border: "0px", margin: "0px", padding: "0px", borderBottom: "1px solid #ccc"}}>
                    { this.state.gsa.map((row, key) =>
                    <tr>
                        <td>{ row.r0glopnr.ztrim() }</td>
                        <td>{ row.r0rumsnr.cdirumsnr() }</td>
                        <td>{ row.r0typ }</td>
                        <td>{ row.r0blopnr.ztrim() }/{ row.r0glopnr.ztrim() }</td>
                        <td>{ row.r0namn.toswe() }</td>
                        <td>{ row.r0guests.ztrim() }</td>
                        <td>{ row.r0status }</td>
                        <td>
                            <Td_Sel items={ this.pkatitems } getValue={(e)=> this.br_gst_edit(row, e)} />
                        </td>
                    </tr>
                    )}
                    </tbody>
                </table>
             </div>
            </div>

        </div>

        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex-row">
            <button className="btn btn-hw-orange w250" onClick={() => this.br_del() } >
                AVBOKA DELBOKNING
            </button>
            </div>
            <button className="btn btn-hw-green w250" onClick={() => this.br_save() } >
                SPARA
            </button>
        </div>
      </div>
        { this.state.wnds.bropen ? <Br_Open wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.wnds.pkatadd ? <Br_PkatAdd wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.wnds.gstadd ? <Rv_GstAdd wnds={this.state.wnds} cb={this.cb_gst} ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.wnds.gstedit ? <Rv_GstEdit wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }

      </div>
	;
	return html;
  };
}

export default Br_Edit;