import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../../../lib/net";

import Fr_Input from "../../../../lib/ui/fr_input";
import Dd_Sel from "../../../../lib/ui/dd_sel";

import Rv_DocKfAdd from "./doc_kf_add";
import Rv_DocKfEdit from "./doc_kf_edit";
import Rv_DocPrds from "./doc_prds";
import Rv_DocRowAdd from "./doc_row_add";
import Rv_DocRowEdit from "./doc_row_edit";
import Rv_DocFreeText from "./doc_freetext";
import Td_Sel from "../../../../lib/ui/td_sel";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Rv_Doc extends React.Component {
  constructor(props, context) {
    super(props);

    this.selitems = [
        {fkn: 'bevaka', text: 'BEVAKA'},
        {fkn: 'bokbild', text: 'BOKNINGSBILD'},
        {fkn: 'confirm', text: 'BEKRÄFTA'},
        {fkn: 'cancel', text: 'AVBOKA'}
    ];
    this.dcritems = [
        {fkn: 'edit', text: 'ÖPPNA'},
        {fkn: 'cancel', text: 'AVBOKA'}
    ];

    this.store = props.store;
    this.state = {};
    this.state.ynq = [];
    //this.state.vga = [];
    this.state.rta = [];
    this.state.bra = [];
    this.state.dca = [];

    this.state.bgo = {};
    this.state.wnds = {};

    this.state.ix = 0;
    this.state.boknr = "";
    if(props.boknr) this.state.boknr = props.boknr;

    this.state.rvo = {};
    this.state.rvo.nrr = "1";
    this.state.rvo.sum = "2000";
    this.state.rvo.rvo = {};
    if(props.wnds) {
        if(props.wnds.boknr) this.state.boknr = props.wnds.boknr;
    }
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      this.docs();
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }

    wnd_close = () => {
      if(this.props.close) {
          this.props.close(this.props.ctx);
      }
      else this.props.ctx.setState({ wnds: {} });
	};
    drag(event) {
        //event.dataTransfer.setData("text", event.target.id);
	};
    drop(event) {
        event.preventDefault();
        alert("Drop");
	};
    dragEnter(event) {
        event.preventDefault();
        event.target.style.color = "#fff";
        event.target.style.background = "#ccc";
        //alert("Allow Drop");
	};
    dragLeave(event) {
        event.preventDefault();
        event.target.style.color = "#fff";
        event.target.style.background = "#080";
        //alert("Allow Drop");
	};
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  kf_add() {
      var wnds = {kfadd: true, boknr: this.state.boknr};
      this.setState({wnds: wnds});
  };
  doc_row_add() {
      var wnds = {};
      wnds.docrowadd = 1;
      wnds.boknr = this.state.boknr;
      this.setState({wnds: wnds});
  };
  doc_row_edit(row) {
      if(row.typ == "D") {
          var wnds = {};
          wnds.docrowedit = 1;
          wnds.boknr = this.state.boknr;
          wnds.row = row;
          this.setState({wnds: wnds});
      }
      if(row.typ == "L") {
          var wnds = {};
          wnds.kfedit = 1;
          wnds.boknr = this.state.boknr;
          wnds.row = row;
          this.setState({wnds: wnds});
      }
  };
  doc_row_res(ctx, ret) {
      ctx.setState({wnds: {}, docrowadd: 0, docrowedit: 0});
      if(ret.ok == "000") {
          ctx.docs();
      }
  };
  doc_freetext() {
      var wnds = {};
      wnds.docfreetext = 1;
      wnds.boknr = this.state.boknr;
      this.setState({wnds: wnds});
  };
  doc_prds() {
      var wnds = {};
      wnds.docprds = 1;
      wnds.boknr = this.state.boknr;
      this.setState({wnds: wnds});
  };
  doc_master() {
      //this.docs();
      alert("Uppsättning saknas, kontakta support!");
  };
  doc_prn() {
      this.docs();
  };
  doc_fkn(row, e) {
      if(e.fkn == "edit") {
          this.doc_row_edit(row);
      }
  }
  docs() {
    var self = this;

    var prm = {};
    prm.req = "pms.doc.doc_rv";
    prm.boknr = self.state.boknr;
    //alert(JSON.stringify(prm));

    net.sio_req(prm)
    .then(function(ret){
        ///alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            return;
        }
        self.setState({dca: ret.rca });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
    return;
  }

  render() {
    //if(!this.props.show) return "";

    //alert(this.props.ctx.state.lro);

    var title = this.props.title;
    if(!title) title = "Bokning";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="m2_backdrop" /> : null }
        <div className="m2_dlg mx_100 flex-col" style={ this.dlg_style() }>
        <div className="mx_h01">
            <div>DOKUMENT</div>
        </div>
        <div className="mx_content flex">

            <div className="divider_orange hide"></div>

            <div className="flex-row" style={{fontSize: "18px", margin: "0px", height: "30px", lineHeight: "30px"}}>
                <div className="rv_cdi_02" onClick={() => this.kf_add() }>NY LOKAL</div>
                <div className="rv_cdi_02" onClick={() => this.doc_prds() }>PRODUKTER</div>
                <div className="flex"></div>
                <div className="rv_cdi_02" onClick={() => this.doc_row_add() }>LÄGG TILL RAD</div>
                <div className="rv_cdi_02" onClick={() => this.doc_freetext() }>FRITEXT</div>
                <div className="rv_cdi_02" onClick={() => this.doc_master() }>DEBITERA MASTER</div>
                <div className="text-center w50" onClick={() => this.docs() }>
                    <FontAwesomeIcon icon="ellipsis-v" size="1x"/>
                </div>
            </div>
            <div className="divider_orange"></div>

            <div className="flex flex-row" style={{border: "0px", margin: "0px", padding: "0px"}}>
                <table className="table" style={{border: "0px", margin: "0px", padding: "0px"}}>
                    <thead style={{fontSize: "12px", fontWeight: "normal", color: "#080", border: "0px", margin: "0px", padding: "0px"}}>
                    <tr>
                        <th style={{textAlign: "left"}}>Datum</th>
                        <th style={{textAlign: "left"}}>Tid</th>
                        <th style={{textAlign: "left"}}>Text</th>
                        <th style={{textAlign: "left"}}>Belopp</th>
                        <th style={{textAlign: "left"}}>#</th>
                    </tr>
                    </thead>
                    <tbody>
                    { this.state.dca.map((row, key) =>
                    <tr key={key} onDoubleClick={() => this.doc_row_edit(row) }>
                        <td>{ row.datum }</td>
                        <td>{ row.start } - { row.slut }</td>
                        <td>{ row.text }</td>
                        <td>{ row.belopp.cdipris() }</td>
                        <td>
                            <Td_Sel items={ this.dcritems } getValue={(e)=> this.doc_fkn(row, e)} />
                        </td>
                    </tr>
                    )}
                    </tbody>
                </table>
             </div>

            </div>

            <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250 ripple" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex-row">
            <Dd_Sel items={ this.selitems } getValue={(e)=> this.sel_fkn(e)} />
            </div>
            <button className="btn btn-hw-red w250 ripple" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
        </div>
      </div>
        { this.state.wnds.kfadd ? <Rv_DocKfAdd boknr={this.state.boknr} cb={this.doc_row_res} ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.wnds.kfedit ? <Rv_DocKfEdit boknr={this.state.boknr} cb={this.doc_row_res} ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.wnds.docprds ? <Rv_DocPrds boknr={this.state.boknr} cb={this.doc_row_res} ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.wnds.docrowadd ? <Rv_DocRowAdd boknr={this.state.boknr} cb={this.doc_row_res} ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.wnds.docrowedit ? <Rv_DocRowEdit wnds={this.state.wnds} cb={this.doc_row_res} ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.wnds.docfreetext ? <Rv_DocFreeText wnds={this.state.wnds} cb={this.doc_row_res} ctx={this} store={this.props.store} backdrop={true}/> : null }
    </div>
	;
	return html;
  };
}

export default Rv_Doc;