import React, { useState, useEffect, useContext } from 'react';
import {Route, Routes} from "react-router-dom";
import {withRouter} from "../lib/react/withRouter";
import { observer } from 'mobx-react'

import Pos_Home from './pos/home.js';
import Pos_Plus from './pos/plus.js';
import Pos_Menus from './pos/menus.js';
import Pos_Btns from './pos/btns.js';
import Pos_Addons from './pos/addons.js';
import Pos_Steps from './pos/steps.js';
import Pos_Tbv from './pos/tbv.js';
import sso from "../sso";

function pg_home() {

    var html =
    <div className="web-content">
      Hem
    </div>
    ;
    return html;
}

class Tmpl_Pos extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.state = {};

      var po = {};
      po.plus = { "fid": "f03", "url": "/tmpl/pos/plus", "name": "Produkter", "active": false };
      po.menus = { "fid": "f03", "url": "/tmpl/pos/menus", "name": "Menyer", "active": false };
      po.btns = { "fid": "f03", "url": "/tmpl/pos/btns", "name": "Knappar", "active": false };
      po.addons = { "fid": "f03", "url": "/tmpl/pos/addons", "name": "Tillval", "active": false };
      po.steps = { "fid": "f03", "url": "/tmpl/pos/steps", "name": "Stegval", "active": false };
      po.tbv = { "fid": "f03", "url": "/tmpl/pos/tbv", "name": "Bordsvy", "active": false };
      //po.pt = { "fid": "f03", "url": "/tmpl/pos/pt", "name": "Pristabell", "active": false };
      this.state.po = po;
      this.state.pa = [];
      this.state.pa = Object.values(po);
  }
    componentDidUpdate() {
    }
    componentWillUnmount() {
    }
    componentDidMount() {
      /*
        var path = window.location.pathname;
        if(path.substr(-1) == "/") path = path.substring(0, path.length - 1);
        var op = path.split("/").pop();

        var po = this.state.po;
        if(po[op]) {
            po[op].active = true;
            //this.props.store.fkn = po[op];
            sbo.state.fkn = po[op].name;
            //alert(JSON.stringify(sbo.state));
        }
        var pa = Object.values(po);
        this.setState({ po: po, pa: pa });

       */
    }
    fknGo(row) {
        sso.seo.sfkn = row;
        this.props.navigate(row.url);
    }
    br_css(row) {
        var css = {};
        if(sso.seo.sfkn && (row.url == sso.seo.sfkn.url)) css.color = "#080";
        //var brc = false;
        //if(row.url == sbo.state.url) brc = true;
        return css;
    }

    render() {
        return (
            <div className="web-app">
                { this.props.store.cfg.bread ?
                    <div className="web-bread">
                        <div className="breadcrumb">
                            { this.state.pa.map((row, key) =>
                                <div key={key} className="breadcrumb-item" style={this.br_css(row)} onClick={() => this.fknGo(row)}>{row.name}</div>
                            )}
                            <div className="flex"></div>
                            <div className="cdigreen">{ sso.sdo.sfkn ? sso.sdo.sfkn.name : null }</div>
                        </div>
                    </div>
                    : null }
        <Routes>
          <Route path="/xx" component={pg_home} />
            <Route path="plus" element={ <Pos_Plus store={this.store} />} />
            <Route path="menus" element={ <Pos_Menus store={this.store} />} />
            <Route path="btns" element={ <Pos_Btns store={this.store} />} />
            <Route path="addons" element={ <Pos_Addons store={this.store} />} />
            <Route path="steps" element={ <Pos_Steps store={this.store} />} />
            <Route path="tbv" element={ <Pos_Tbv store={this.store} />} />
            <Route path="*" element={ <Pos_Home store={this.store} />} />
        </Routes>
    </div>
  );
}
}
//Sys.contextType = ADOContext;

export default withRouter(observer(Tmpl_Pos));
