import React, { useState, useEffect, useContext } from 'react';
import {format, addDays, subDays} from "date-fns";

import * as net from "../../../lib/net";
import Fr_Input from "../../../lib/ui/fr_input";

class Rv_Sz extends React.Component {
  constructor(props, context) {
    super(props);

    this.state = {};
    this.state.ynq = [];
    this.state.aa = [];
    this.state.cols = {};
    this.state.ix = 0;
    this.state.sz_val = "";
    this.state.wnds = {};
    this.state.szo = {};
    this.state.sza = [];
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }

    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    wnd_close = () => {
      if(this.props.wnds.cb) {
        var ret = {};
        ret.ok = "999";
        this.props.wnds.cb(this.props.ctx, ret);
      }
      else this.props.ctx.setState({ wnds: {} });
	};
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  sz_do() {
    var self = this;

    if(this.state.szo.boknr) this.sz_boknr();
    if(this.state.szo.ftg) this.sz_ftg();
    if(this.state.szo.namn) this.sz_namn();

    return;
  }
  sz_boknr() {
    var self = this;

    var prm = {};
    prm.req = "pms.sz.mys_boknr";
    prm.boknr = this.state.szo.boknr;
    //alert(JSON.stringify(prm));

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));
        if(ret.ok != "000") {
            return;
        }
        self.setState({ sza: ret.rca });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
    return;
  }
  sz_ftg() {
    var self = this;

    var prm = {};
    prm.req = "pms.sz.mys_ftg";
    prm.ftg = this.state.szo.ftg;
    //alert(JSON.stringify(prm));

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            return;
        }
        self.setState({ sza: ret.rca });
        //self.setState({gsa: ret.rca });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
    return;
  }
  sz_namn() {
    var self = this;

    var prm = {};
    prm.req = "pms.sz.mys_namn";
    prm.namn = this.state.szo.namn;
    //alert(JSON.stringify(prm));

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            return;
        }
        self.setState({ sza: ret.rca });
        //self.setState({gsa: ret.rca });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
    return;
  }
    val_save (name, value) {
        var szo = this.state.szo;
        if(!szo[name]) {
            szo = {};
        }
        szo[name] = value;
        this.setState({szo: szo});
    }
  sz_sel(row) {
      if(this.props.wnds.cb) {
        var ret = {};
        ret.ok = "000";
        ret.rco = row;
        this.props.wnds.cb(this.props.ctx, ret);
      }
      else this.props.ctx.setState({ wnds: {} });
 }

  render() {
    var title = this.props.title;
    if(!title) title = "Sök";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="mx_backdrop" /> : null }
        <div className="mx_dlg mx_90 flex-col" style={ this.dlg_style() }>
        <div className="mx_content flex">

            <div className="flex-row" style={{padding:"5px",height:"35px"}}>
                    <div className="flex-col flex">
                        <div>SÖK</div>
                    </div>
                    <div className="flex-col">
                        <div>UPPDATERA</div>
                    </div>
            </div>
            <div className="divider_orange"></div>
            <form className="cdi_f03">
            <div className="flex-row">
                <Fr_Input label="BOKNR" className="text-left w250" text={this.state.szo.boknr} getValue={(e)=> this.val_save("boknr", e) } />
            </div>
            <div className="flex-row">
                <Fr_Input label="FÖRETAG" className="text-left w350" text={this.state.szo.ftg} getValue={(e)=> this.val_save("ftg", e) } />
                <Fr_Input label="NAMN" className="text-left w350" text={this.state.szo.namn} getValue={(e)=> this.val_save("namn", e) } />
            </div>
            </form>
            <div className="divider_orange"></div>

            <div className="flex">
            <div className="flex flex-row" style={{border: "0px", margin: "0px", padding: "0px"}}>
                <table className="table" style={{border: "0px", margin: "0px", padding: "0px"}}>
                    <thead style={{fontSize: "12px", fontWeight: "normal", color: "#080", border: "0px", margin: "0px", padding: "0px"}}>
                    <tr>
                        <th style={{textAlign: "left"}}>Boknr</th>
                        <th style={{textAlign: "left"}}>Löppnr</th>
                        <th style={{textAlign: "left"}}>Företag</th>
                        <th style={{textAlign: "left"}}>Namn</th>
                        <th style={{textAlign: "left"}}>Ankomst</th>
                        <th style={{textAlign: "left"}}>Avresa</th>
                        <th style={{textAlign: "left"}}>Gäster</th>
                        <th style={{textAlign: "left"}}>Status</th>
                    </tr>
                    </thead>
                    <tbody style={{fontSize: "16px", color: "#000", border: "0px", margin: "0px", padding: "0px", borderBottom: "1px solid #ccc"}}>
                    { this.state.sza.map((row, key) =>
                    <tr key={key} onDoubleClick={() => this.sz_sel(row) }>
                        <td>{ row.boknr }</td>
                        <td>{ row.lopnr }</td>
                        <td>{ row.ftg }</td>
                        <td>{ row.namn }</td>
                        <td>{ row.ank }</td>
                        <td>{ row.avr }</td>
                        <td>{ row.nrg }</td>
                        <td>{ row.status }</td>
                    </tr>
                    )}
                    </tbody>
                </table>
             </div>
             </div>

        </div>

        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex-row">
            <button className="btn btn-hw-green w250" onClick={(event) => this.sz_do(event) } >
                SÖK
            </button>
            </div>
      </div>
    </div>
    </div>
	;
	return html;
  };
}

export default Rv_Sz;