import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../../lib/net";
import Wnd_Ok from "../../../lib/ui/wnd_ok";

class Bq_Screen extends React.Component {
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.state = {};
    this.state.bqa = [];
    this.state.tra = [];
    this.state.msg = {};
    this.state.bongpos = "01";
   }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      //this.bqn();
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    bq_close() {
        this.props.ctx.setState({ wnds: {} });
    };
    tb_style() {
      var css = {};
      //css.height = window.innerHeight - 275;
        css.overflow = "auto";
      return css;
    }
    bq_style() {
      var css = {};
      css.height = (window.innerHeight * 0.8) - 275;
      css.overflow = "auto";
      return css;
    }
  bong_cls(row) {
      var cls = "";
       return cls;
  }
  bong_style(row) {
    var css = {};
    if(row.b0bongnr == "00003") css.height = "125px";
    return css;
  }
  status_style(bong) {
    var css = {};
    if(bong.status == "") { css.background = "#008"; css.color = "#fff"; }
    if(bong.status == "F") { css.background = "#008"; css.color = "#fff"; }
    return css;
  }
  row_style(row) {
    var css = {};
    if(row.brow.b0state == "3") { css.background = "#ff9900"; css.color = "#fff"; }
    if(row.brow.b0state == "5") { css.background = "#080"; css.color = "#fff"; }
    if(row.brow.b0state == "9") { css.background = "#800"; css.color = "#fff"; }
    return css;
  }

  bong_open(row) {
    var self = this;
  }

    bqn() {
        var self = this;
        var bongpos = "91";
        var prm = {};
        prm.req = "pos.pos_bq.bqn";
        prm.avd = this.state.avd;

        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret.rca));

            self.setState({bqa: ret.rca, bongpos: bongpos });
            self.syncTb("bqnTable");
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }
    bqs(bongpos) {
        var self = this;
        var prm = {};
        prm.req = "pos.pos_bq.bqs";
        prm.bongpos = bongpos;

        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret.rca));

            /*
            for(var bong of ret.rca) {
            for(var row of bong.rows) {
                alert(JSON.stringify(row));
                alert(JSON.stringify(row.xtra));
            }
            }
            */

            self.setState({bqa: ret.rca, bongpos: bongpos });
            self.syncTb("bqnTable");
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }
    bq_bong(bong) {
        var self = this;
        var prm = {};
        prm.req = "pos.pos_bq.bq_set";
        prm.bongpos = this.state.bongpos;
        prm.bongnr = bong.bongnr;
        prm.status = "F";

        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret));
            self.bqs(this.state.bongpos);
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }
    bq_state(row) {
        var self = this;
        var state = "3";
        if(row.brow.b0state == "3") state = "5";
        if(row.brow.b0state == "5") state = "9";

        var rows = [];
        rows.push( row );

        var prm = {};
        prm.req = "pos.pos_bq.bq_state";
        prm.rows = rows;
        prm.state = state;

        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret));
            self.bqs(this.state.bongpos);
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }
    row_bong(row) {
        var self = this;
        var prm = {};
        prm.req = "pos.pos_bq.bongrow";
        prm.row = row;
        prm.status = "09";

        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret.rca));

            //self.setState({bqa: ret.rca });
            //toastr.success("OK", "SAPI");
            var msg = { ok: true, text: "BONGAD ..." };
            self.setState({msg: msg });
            setTimeout(function(){
                self.setState({msg: {} });
            }, 1000);
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }
    syncTb(tb){
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
        tdElements = tbel.getElementsByTagName('td');

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
            width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
    bong_text(bong){
        var txt = "NORMAL";
        if(bong.status == "V") txt = "VÄNTA";
        if(bong.status == "F") txt = "FRAM";
        return txt;
    }

  render() {

    var title = this.props.title;
    if(!title) title = "Titel";

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="m2_backdrop" /> : null }
        <div className="m2_dlg mx_80w flex-col" style={ this.dlg_style() }>
        <div className="flex-row " style={{padding:"0px",height:"50px",lineHeight:"50px", background: "#1a456b", color: "#fff"}}>
                <div className="flex-row flex" style={{padding:"0px 5px", fontSize: "22px"}}>
                    <div>BONG SKÄRM: { this.state.bongpos }</div>
                </div>
            <div className="wnd-close-btn" onClick={() => this.bq_close() }>X</div>
        </div>
        <div className="mx_content flex">

        <div className="divider_orange"></div>

            <div className="bqv" style={ this.bq_style() }>
            { this.state.bqa.map((bong, key) =>
                <div className="bong px-2 py-2" key={key} >
                    <div className="bong-body" onDoubleClick={() => this.bq_bong(bong) }
                     style={ this.bong_style(bong) }
                    >
                        <div className="flex-row" >
                            <div>Bord: { bong.bordnr }</div>
                            <div className="flex"></div>
                            <div>Tid: { bong.tid }</div>
                        </div>
                        <div className="flex-row" >
                            <div>ID: { bong.bongnr }</div>
                            <div className="flex"></div>
                            <div>Tid: { bong.vtid }</div>
                        </div>
                        <div className="divider_orange"></div>
                        <div className="flex-cc" style={ this.status_style(bong) }>
                            { this.bong_text(bong) }
                        </div>

                        <div className="divider_green"></div>
                        { Object.values(bong.rows).map((row, rkey) =>
                            <div className="" key={key} onClick={() => this.bq_state(row) } style={ this.row_style(row) }>
                                <div className="flex-row" >
                                    <div className="txt_14 flex">{ row.brow.b0text }</div>
                                    <div className="txt_14">{ row.brow.b0perprice.cdipris() }</div>
                                </div>
                                { row.xtra.map((xrow, xkey) =>
                                    <div className="px-2" key={xkey} >
                                    <div className="txt_12 cdigreen">{ xrow.text }</div>
                                    </div>
                                )}
                                { row.tval.map((trow, tkey) =>
                                    <div className="px-2" key={tkey} >
                                    <div className="txt_12 cdired">{ trow.text }</div>
                                    </div>
                                )}
                           </div>
                        )}
                        <div className="divider_green"></div>
                        { Object.values(bong.irows).map((row, rkey) =>
                            <div className="" key={key} style={ this.row_style(row) }>
                                <div className="flex-row" >
                                    <div className="txt_14 flex">{ row.brow.b0text }</div>
                                    <div className="txt_14">{ row.brow.b0perprice.cdipris() }</div>
                                </div>
                           </div>
                        )}
                   </div>
                    <div className="flex-row" >
                        <div className="flex"></div>
                        <div className="txt_14">STATE: { bong.state } </div>
                        <div className="flex"></div>
                    </div>
                    <div className="flex-row" >
                        <div className="txt_14">PÅBÖRJA</div>
                        <div className="flex"></div>
                        <div className="txt_14">FÄRDIG</div>
                    </div>
                 </div>
            )}
            </div>

        </div>

        <div className="mx_foot flex-row">
            <button className="btn btn-hw-red w150" onClick={() => this.bq_close() } >
                STÄNG
            </button>
            <div className="flex"></div>
            <button className="btn btn-hw-green w150" onClick={() => this.bqs("01") } >
                BQ01
            </button>
            <button className="btn btn-hw-green w150" onClick={() => this.bqs("02") } >
                BQ02
            </button>
            <button className="btn btn-hw-green w150" onClick={() => this.bqs("03") } >
                BQ03
            </button>
            <button className="btn btn-hw-green w150" onClick={() => this.bqs("04") } >
                BQ04
            </button>
            <button className="btn btn-hw-green w150" onClick={() => this.bqs("05") } >
                BQ05
            </button>
            <div className="flex"></div>
            <button className="btn btn-hw-green w150" onClick={() => this.bqn() } >
                EXPO
            </button>
        </div>
      </div>
        <Wnd_Ok msg={this.state.msg} ctx={this} />
    </div>
	;
	return html;
  };
}

export default Bq_Screen;