import React, { useState, useEffect, useContext } from 'react';
import {format, addDays, subDays} from "date-fns";

import * as net from "../../../../lib/net";
import Br_PkatAdd from "./br_pkat_add";
import Rv_GstAdd from "../gst/gst_add";
import Rv_GstEdit from "../gst/gst_edit";
import Edit_Txt from "../../../../lib/ui/edit_txt";
import Td_Sel from "../../../../lib/ui/td_sel";
import Fr_Cal from "../../../../lib/ui/fr_cal";
import Dt_Cal from "../../../../lib/ui/dt_cal";
import Fr_Input from "../../../../lib/ui/fr_input";
import Fr_Inpsel from "../../../../lib/ui/fr_inpsel";

class Br_Edit extends React.Component {
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pms = props.store.pms;

    this.state = {};
    this.state.today = new Date();
    this.state.rta = [];
    this.state.nba = [];
    this.state.pka = [];
    this.state.pkat = "";
    this.state.pko = {};
    this.state.pks = {};

    this.state.gnr = "001";
    this.state.gbo = {};
    //this.state.lro = props.lro;
    this.state.ynq = [
                {id: '0', text: 'NEJ'},
                {id: '1', text: 'JA'}
            ];

    this.state.background = "#fff";

    this.state.part = "01";
    this.state.wnds = {};
    this.state.cols = {};
    this.state.lrwrk = false;
    this.state.boknr = {};
    this.state.gso = {};
    this.state.org = {};
    this.state.bro = {};
    this.state.gsa = [];
    if(props.wnds) {
        if(props.wnds.boknr) this.state.boknr = props.wnds.boknr;
        if(props.wnds.bro) {
            this.state.org = props.wnds.bro;
            this.state.bro = JSON.parse(JSON.stringify(props.wnds.bro));
            this.state.bro.r0ankdatum = this.state.bro.r0ankdatum.cdidate();
            this.state.bro.r0avrdatum = this.state.bro.r0avrdatum.cdidate();
            this.state.bro.r0borr = this.state.bro.r0borr.ztrim();
            this.state.bro.r0borg = this.state.bro.r0borg.ztrim();
        }
    }

    this.pkatitems = [
        {fkn: 'open', text: 'ÖPPNA'},
        {fkn: 'cancel', text: 'AVBOKA'}
    ];

    this.state.pkk = [
        {n0typ: '', text: 'VÄLJ PRISKATEGORI', pris: ''},
        {n0typ: 'rack', text: 'ROM OG FROKOST', pris: '79500'},
        {n0typ: 'halp', text: 'HALVPENSION', pris: '99500'},
        {n0typ: 'help', text: 'HELPENSION', pris: '129500'}
    ];
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.br_pkats();
      this.rts();
  }

    bro_init() {
      var gso = {};
      gso.fdat = this.state.bro.r0ankdatum;
      gso.tdat = this.state.bro.r0avrdatum;
      gso.nrr = this.state.bro.r0borr;
      gso.nrg = this.state.bro.r0guests;
      gso.nrb0 = this.state.bro.r0antb0;
      gso.nrb1 = this.state.bro.r0antb1;
      gso.rt = this.state.bro.r0typ;
      this.setState({gso: gso});
    }
    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    grp_style(row) {
      var css = {};
      css.background = "#008";
      if(this.state.gnr === row.vgrupp) css.background = "#080";
      return css;
    }

    formchange (e) {
      const name = e.target.name;
      const value = e.target.value;
      var pbo = this.state.pbo;
      var na = name.split(".");
      pbo[na[0]][na[1]] = value;
      this.setState({pbo: pbo});
      //setState({[name]: value});
    }

    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    wnd_close = () => {
		//this.props.ctx.setState({ rvbradd: false });
        var ret = {};
        ret.ok = "999";
        //this.props.cb(this.props.ctx, ret);
        this.props.ctx.setState({ wnds: {} });
	};
    handleChange = (event, newValue) => {
        //alert(newValue);
        this.setState({ix: newValue});
      };
    toggle() {
      this.setState({modal: false});
	};

    submitHandler = event => {
        event.preventDefault();
        event.target.className += " was-validated";
    };

    changeHandler = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    rsv_save (name, value) {
        //alert(JSON.stringify(name));
        //alert(JSON.stringify(value));
        var rsv = this.state.bro;
        var na = name.split(".");
        rsv[na[0]][na[1]] = value;
        this.setState({rsv: rsv});
        //setState({[name]: value});
    }
    val_save (name, value) {
        //alert(JSON.stringify(name));
        //alert(JSON.stringify(value));
      var bro = this.state.bro;
      bro[name] = value;
      this.setState({bro: bro});
      //setState({[name]: value});
    }
    rt_set (name, e) {
      const value = e.target.value;
      var bro = this.state.bro;
      bro[name] = value;
      this.setState({bro: bro});
      //setState({[name]: value});
    }
    pkat_set (e) {
      const value = e.target.value;
      var pks = this.state.pks;
      var pko = pks[value];
      this.setState({pkat: pko.n0typ, pko: pko});
      //setState({[name]: value});
    }
    pkat_pris_set (row, e) {
      //const value = e.target.value;
      ///this.setState({pkat: pko.n0typ, pko: pko});
        //alert(row.n0pris);
        //alert(e);
        this.br_pkat_upd(row, e);
    }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  tab_style(part) {
      var css = {};
      if(this.state.part == part) { css.background = "#fff"; css.color = "#009900"; }
      return css;
  }

  br_init() {
    var self = this;
    var dat = addDays(this.state.today, 1);
    var fdat = format(this.state.today, "yyyy-MM-dd");
    var tdat = format(dat, "yyyy-MM-dd");

    var bro = {};
    bro.fdat = fdat;
    bro.tdat = tdat;
    bro.nrr = "1";
    bro.nrg = "1";
    bro.nrgr = "1";
    bro.nrb0 = "0";
    bro.nrb1 = "0";
    bro.nrb2 = "0";
    bro.rt = "";
    bro.pkat = "";
    bro.rab = "";
    this.setState({bro: bro});
  }
  rts() {
    var self = this;
    var prm = {};
    prm.req = "pms.rt.rt_date";
    //prm.datum = this.state.datum;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            return;
        }
        self.setState({rta: ret.rco});
        //self.pkats();
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  pkats() {
    var self = this;
    var prm = {};
    prm.req = "pms.pkat.qa_pkats";
    //prm.datum = this.state.datum;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            return;
        }
        self.setState({pka: ret.rca, pks: ret.rco});
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  brs() {
    var self = this;

    var prm = {};
    prm.req = "pms.br.brgs";
    //prm.fkn = "721G";
    prm.boknr = this.state.boknr;
    //alert(JSON.stringify(prm));

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            return;
        }
        self.pms.rvo.bra = ret.rca;
        //self.setState({bra: ret.rca });
        self.props.ctx.setState({wnds: {}});
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
    return;
  }

  br_save() {
    var self = this;
    var prm = {};
    prm.req = "pms.br.br_upd";
    prm.boknr = this.state.boknr;
    prm.org = this.state.org;
    prm.rblx0 = this.state.bro;
    //prm.nyp00 = this.state.pko;
    //alert(JSON.stringify(prm));
    //return;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok == "000") {
		    //self.props.cb(self.props.ctx, ret);
		    //self.props.ctx.setState({wnds: {}});
            self.pms.wrk.bro = ret.rco;
		    self.brs();
            return;
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  br_del() {
    var self = this;

    var prm = {};
    prm.req = "pms.br.br_del";
    //prm.fkn = "7217";
    prm.boknr = this.state.boknr;
    prm.rblx0 = this.state.bro;
    //alert(JSON.stringify(prm));

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            return;
        }
        self.props.cb(self.props.ctx, ret);
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
    return;
  }
  br_pkats() {
    var self = this;
    var prm = {};
    prm.req = "pms.br.br_pkats";
    prm.rblx0 = this.state.bro;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            return;
        }
        self.setState({nba: ret.rca});
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
    br_pkat_set() {
    var self = this;

    var prm = {};
    prm.req = "pms.br.br_row_pkat";
    prm.boknr = this.state.boknr;
    prm.rblx0 = this.state.bro;
    prm.nyp00 = this.state.pko;
    //alert(JSON.stringify(prm));

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            return;
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
    return;
  }
    br_pkat_upd(nyb00, pris) {
    var self = this;

    var prm = {};
    prm.req = "pms.br.br_pkat_upd";
    prm.boknr = this.state.boknr;
    prm.pris = pris;
    prm.nyb00 = nyb00;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            return;
        }
        self.br_pkats();
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
    return;
  }

  br_pkat_add() {
      var wnds = {pkatadd: true, boknr: this.state.boknr, bro: this.state.bro};
      this.setState({wnds: wnds});
  }
  br_pkat_edit() {
      var wnds = {pkatadd: true, boknr: this.state.boknr, bro: this.state.bro};
      this.setState({wnds: wnds});
  }
  br_gst_add() {
      var wnds = {gstadd: true, boknr: this.state.boknr, bro: this.state.bro};
      this.setState({wnds: wnds});
  }
  br_gst_edit(row) {
      var wnds = {gstedit: true, boknr: this.state.boknr, bro: this.state.bro, gso: row};
      this.setState({wnds: wnds});
  }
  show_part(part) {
        //if(part == "02") this.rv_kf();
        this.setState({part: part});
  };

  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "Delbokning";
    //if(props.kro) alert(props.kro.krr00.kid);
    const popover = {
      position: 'absolute',
      top: '50px',
      zIndex: '2',
    }
    const cover = {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    }

	var html =
        <div className="mx_base">
        <div className="m2_backdrop" />
        <div className="m2_dlg mx_70 flex-col" style={ this.dlg_style() }>

        <div className="mx_h01">
            <div>DELBOKNING: {this.state.bro.r0lopnr} </div>
        </div>
        <div className="mx_content flex">

            <div className="divider_orange"></div>

            <form className="cdi_f03">
            <div className="flex-row py-2">
                <Fr_Cal label="Från" className="text-left w250" min="1" text={this.state.bro.r0ankdatum} getValue={(e)=> this.val_save( "r0ankdatum", e ) }/>
                <Fr_Cal label="Till" className="text-left w250" min="1" text={this.state.bro.r0avrdatum} getValue={(e)=> this.val_save( "r0avrdatum", e ) }/>
            </div>

            <div className="flex-row py-2">
                    <Fr_Input label="Antal Rum" className="text-left w250" text={this.state.bro.r0borr} getValue={(e)=> this.val_save("r0borr", e) } />
                    <Fr_Input label="Antal Gäster" className="text-left w250" text={this.state.bro.r0borg} getValue={(e)=> this.val_save("r0borg", e) } />
            </div>
            <div className="flex-row py-2 hide">
                    <Fr_Inpsel label="RT" className="text-left w250"
                               text={this.state.bro.nrr}
                               items={this.state.nra}
                               getValue={(e)=> this.val_save("nrr", e) }
                               onClick={() => this.rt_get() }
                    />
            </div>
            <div className="flex-row py-2">
                    <Fr_Input label="Barn (0-4)" className="text-left w250" text={this.state.bro.r0antb0} getValue={(e)=> this.val_save("r0antb0", e) } />
                    <Fr_Input label="Barn (5-8)" className="text-left w250" text={this.state.bro.r0antb1} getValue={(e)=> this.val_save("r0antb1", e) } />
            </div>
            <div className="divider_green"></div>
            <div className="flex-row py-2">
                <div className="form-group flex-row flex">
                        <span className="header">Rumstyp</span>
                        <select value={this.state.bro.r0typ} className="form-control w350" onChange={(e) => this.rt_set("r0typ", e)}>
                            { this.state.rta.map((row, key) =>
                            <option key={key} value={row.r1typ}>{row.r1typ}</option>
                            )}
                        </select>
                </div>
            </div>
            </form>
        </div>

        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                AVBRYT
            </button>
            <div className="flex-row">
            <button className="btn btn-hw-orange w250" onClick={() => this.br_del() } >
                AVBOKA DELBOKNING
            </button>
            </div>
            <button className="btn btn-hw-green w250" onClick={() => this.br_save() } >
                SPARA
            </button>
        </div>
      </div>
        { this.state.wnds.pkatadd ? <Br_PkatAdd wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.wnds.gstadd ? <Rv_GstAdd wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.wnds.gstedit ? <Rv_GstEdit wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }

      </div>
	;
	return html;
  };
}

export default Br_Edit;