import React, { useState, useEffect, useContext } from 'react';
import {withRouter} from "../../lib/react/withRouter";
import sso from "../../sso";

class Calc_Home extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, tra: [] };
    this.state.seldate = new Date();
    this.state.avd = "01";
    this.state.paa = [];
    this.state.aa = [];

      this.state.mna = [];
      this.state.mna.push({ mnid: "mn01", pos: "c1", text: "TOTAL", desc: "Total summering", link: "/ta/calc/tot" });
      this.state.mna.push({ mnid: "mn02", pos: "c1", text: "AVDELNING", desc: "Avdelnings fördelning", link: "/ta/calc/avd" });
      this.state.mna.push({ mnid: "mn04", pos: "c2", text: "TIM", desc: "Tim översikt", link: "/ta/calc/hh" });
      this.state.mna.push({ mnid: "mn05", pos: "c2", text: "DAG", desc: "Dag översikt", link: "/ta/calc/day" });
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 480;
      return css;
  }
    mnc(pos) {
        var mna = [];
        for(var row of this.state.mna) {
            if(row.pos == pos) mna.push( row );
        }
        return mna;
    }
  fknGo(url) {
    //alert(row.href);
      var row = {};
      row.url = url;
      sso.seo.sfkn = row;
    this.props.navigate(url);
  }

  render() {
    return (
    <div className="web-app">
        <div className="web-pg py-4 flex">
            <h3>KALKYL</h3>
            <p>Kalkyl funktioner</p>

            <div className="divider_green"></div>

            <div className="web-row">
                <div className="flex-col flex">
                    { this.mnc("c1").map((row, key) =>
                        <div key={key} className="web-bx" onClick={() => this.fknGo(row.link) }>
                            <div>{ row.text }</div>
                            <div className="flex-row">
                                <div className="flex">{ row.desc }</div>
                                <div className="web-link"></div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="flex-col flex">
                    { this.mnc("c2").map((row, key) =>
                        <div key={key} className="web-bx" onClick={() => this.fknGo(row.link) }>
                            <div>{ row.text }</div>
                            <div className="flex-row">
                                <div className="flex">{ row.desc }</div>
                                <div className="web-link"></div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    </div>
  );
    }
}
export default withRouter(Calc_Home);
