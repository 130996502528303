import React, { useState, useEffect, useContext } from 'react';
import * as net from "../../../lib/net";

import Pms_Rvo from "../../rv/fb/rvo";
import Pms_Gso from "../../rv/gst/gso";
import Pms_Nota from "../nota/nota";
import Fr_Input from "../../../lib/ui/fr_input";
import sso from "../../../sso";

class Pms_InhKeys extends React.Component {
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.state = {};
    this.state.ynq = [];

    this.state.paa = [];
    this.state.aa = [];
    this.state.boknr = "";
    this.state.rvo = {};
    this.state.room = {};
    this.state.rumsnr = "";
    this.state.wnds = {};
    if(props.wnds) {
        this.state.rumsnr = props.wnds.rumsnr;
        this.state.room.rumsnr = this.state.rumsnr.cdirumsnr();
        this.state.room.namn = props.wnds.rvo.gsb00.r0namn;
        this.state.room.datum = props.wnds.rvo.gsb00.r0avrdatum;
        //this.state.room.nrk = props.wnds.rvo.gsb00.r0guests;
        this.state.room.nrk = "1";
    }
    this.state.msg = {};
  }

  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    body_css() {
      var css = {};
      if(this.state.msg.typ == "error") {
          css.background = "#800";
          css.color = "#fff";
      }
      if(this.state.msg.typ == "success") {
          css.background = "#080";
          css.color = "#fff";
      }
      return css;
    }

    wnd_close() {
		this.props.ctx.setState({ wnds: {} });
	};
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }

  key_new() {
    var self = this;

    var prm = {};
    prm.req = "pms.hk.room_key";
    prm.rumsnr = this.state.room.rumsnr;
    prm.kodare = sso.seo.encoder;
    prm.nrk = this.state.nrk;
    prm.datum = this.state.room.datum;
    //alert(JSON.stringify(prm));

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok == "000") {
            self.setState({msg: {"typ": "success"} });
            setTimeout(function(){
                self.setState({msg: {} });
            }, 1500);
        }
        else {
            self.setState({msg: {"typ": "error"} });
            setTimeout(function(){
                self.setState({msg: {} });
            }, 1500);
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
    return;
  }
  key_copy() {
    var self = this;

    var prm = {};
    prm.req = "pms.hk.room_key_copy";
    prm.rumsnr = this.state.room.rumsnr;
    prm.kodare = sso.seo.encoder;
    prm.nrk = this.state.nrk;
    prm.datum = this.state.room.datum;
    //alert(JSON.stringify(prm));

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok == "000") {
            self.setState({msg: {"typ": "success"} });
            setTimeout(function(){
                self.setState({msg: {} });
            }, 1500);
        }
        else {
            self.setState({msg: {"typ": "error"} });
            setTimeout(function(){
                self.setState({msg: {} });
            }, 1500);
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
    return;
  }
    val_save (name, value) {
      var room = this.state.room;
      //var na = name.split(".");
      //gso[na[0]][na[1]] = value;
      room[name] = value;
      this.setState({room: room});
      //setState({[name]: value});
    }

  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "";

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="mx_backdrop" /> : null }
        <div className="m2_dlg mx_50 flex-col" style={ this.dlg_style() }>
        <div className="mx_h01 flex-row" style={{background: "#1a456b", color: "#fff"}}>
                <div className="flex-col flex">
                    <div>NYCKLAR</div>
                </div>
                <div className="flex-col">
                    <div>RUMSNR: { this.state.rumsnr.cdirumsnr() }</div>
                </div>
        </div>
        <div className="mx_content flex" style={ this.body_css() }>

            <div className="text-center txt_18">NYCKELKODARE</div>
            <div className="text-center txt_22">{ sso.seo.encoder }</div>
            <div className="flex"></div>
             <div className="text-center txt_18">Rums Nr: { this.state.room.rumsnr }</div>
             <div className="text-center txt_18">Namn: { this.state.room.namn }</div>
             <div className="text-center txt_18">Avresedag: { this.state.room.datum }</div>
             <div className="text-center txt_18">Antal nycklar: { this.state.room.nrk }</div>
           <form className="cdi_f03">
            <div className="flex-col flex-center hide">
                <div className="flex-row py-2">
                    <Fr_Input label="Rums Nr" col className="text-left w250 mx-2" text={this.state.room.rumsnr} getValue={(e)=> this.val_save("rumsnr", e) } />
                </div>
                <div className="flex-row py-2">
                    <Fr_Input label="Namn" col className="text-left w250 mx-2" text={this.state.room.namn} getValue={(e)=> this.val_save("namn", e) } />
                </div>
            </div>
            </form>
            <div className="flex"></div>

            <div className="divider_orange"></div>
        <div className="web-row">
        <div className="web-col flex">
            <div className="wnd-btn" onClick={() => this.wnd_close() } >
                <div>STÄNG</div>
                <div></div>
            </div>
        </div>
         <div className="web-col flex" style={{padding: "0px 10px"}}>
            <div className="wnd-btn" onClick={() => this.key_copy() } >
                <div>KOPIE NYCKEL</div>
                <div></div>
            </div>
       </div>
         <div className="web-col flex" style={{padding: "0px 10px"}}>
            <div className="wnd-btn" onClick={() => this.key_new() } >
                <div>NY NYCKEL</div>
                <div></div>
            </div>
       </div>
       </div>

        </div>
      </div>
            { this.state.wnds.wnd_rvo ?
        <Pms_Rvo close={this.rvo_close} boknr={this.state.boknr} ctx={this} store={this.props.store} backdrop={true}/>
            : null }
    </div>
	;
	return html;
  };
}

export default Pms_InhKeys;