import React, { useState, useEffect, useContext } from 'react';
import {format, addDays, subDays} from "date-fns";

import * as net from "../../../../lib/net";
import Fr_Input from "../../../../lib/ui/fr_input";
import Fr_Select from "../../../../lib/ui/fr_select";
import Fr_Cal from "../../../../lib/ui/fr_cal";
import Dt_Cal from "../../../../lib/ui/dt_cal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Rt_Bel extends React.Component {
  constructor(props, context) {
    super(props);

    this.dt_fd = React.createRef();
    this.dt_td = React.createRef();

    this.store = props.store;
    this.pms = props.store.pms;

    this.state = {};
    this.state.today = new Date();
    this.state.ynq = [
                {id: '0', text: 'NEJ'},
                {id: '1', text: 'JA'}
            ];

    this.state.background = "#fff";
    this.state.rsa = [];
    this.state.dta = [];

    this.state.cols = {};
    this.state.boknr = {};
    if(props.boknr) this.state.boknr = props.boknr;
    this.state.kfo = {};
    if(props.wnds) {
        if(props.wnds.boknr) this.state.boknr = props.wnds.boknr;
        if(props.wnds.kfo) this.state.kfo = props.wnds.kfo;
    }

    this.state.fdat = format(this.state.today, "yyyy-MM-dd");
    var dat = addDays(this.state.today, 15);
    this.state.tdat = format(dat, "yyyy-MM-dd");

    this.state.firstday = {};
    this.state.caldata = [];
    this.state.rtdata = [];
    this.state.dio = {};
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.bel_rt();
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
  dt_style(dto) {
    var css = {};
    css.color = "#fff";
    return css;
  }
  dt_class(dto) {
      var cls = "";
      if(dto.wday == 5) cls = "weekend";
      if(dto.wday == 6) cls = "weekend2";
      return cls;
  }
  get_css(dto) {
      var cls = "";
      if(dto.wday == 5) cls = "weekend";
      if(dto.wday == 6) cls = "weekend2";
      return cls;
  }
  dt_check(dto) {
      //dto.wday == '0';
      return true;
  }
  dioenter(dto) {
      //dto.wday == '0';
      return;
  }
  dioleave(dto) {
      //dto.wday == '0';
      return;
  }
  set_di(dto) {
      //dto.wday == '0';
      return;
  }
  selov(dto) {
      //dto.wday == '0';
      return;
  }
  seldn(dto) {
      //dto.wday == '0';
      return;
  }
  selup(dto) {
      //dto.wday == '0';
      return;
  }
    selfdt(dto) {
        //dto.wday == '0';
        return;
    }
    selrt(dto) {
        //dto.wday == '0';
        return;
    }
    dt_fdat(e) {
        //alert(JSON.stringify(e));
        var dts = format(e, "yyyy-MM-dd");
        this.setState({ fdat: dts });
    }
    dt_tdat(e) {
        //alert(JSON.stringify(e));
        var dts = format(e, "yyyy-MM-dd");
        this.setState({ tdat: dts });
    }
    oneday(e) {
        //alert(JSON.stringify(e));
        //this.setState({ tdat: this.state.fdat });
        this.dt_td.current.updateDt(this.state.fdat);
    }
    addday(e) {
        //alert(JSON.stringify(e));
        //this.setState({ tdat: this.state.fdat });
        this.dt_fd.current.updateAdd();
        this.dt_td.current.updateAdd();
    }
    subday(e) {
        //alert(JSON.stringify(e));
        //this.setState({ tdat: this.state.fdat });
        this.dt_fd.current.updateSub();
        this.dt_td.current.updateSub();
    }
    wnd_close = () => {
      if(this.props.cb) {
        var ret = {};
        ret.ok = "999";
        this.props.cb(this.props.ctx, ret);
      }
      else this.props.ctx.setState({ wnds: {} });
	};

  bel_rt() {
    var self = this;

    var prm = {};
    prm.req = "pms.cal.pms_cal_rt";
    prm.fdat = this.state.fdat;
    prm.tdat = this.state.tdat;
    //prm.avd = this.state.avd;

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        //alert(JSON.stringify(ret.rta));
        var rta = [];
        var keys = Object.keys(ret.rta);
        for(var key of keys) {
            var rto = ret.rta[key];
            //alert(JSON.stringify(key));

            var o = {};
            o.rt = key;
            o.dta = [];

            var dkeys = Object.keys(rto);
            for(var dkey of dkeys) {
                var dto = rto[dkey];
                //alert(JSON.stringify(dkey));
               o.dta.push(dto);
            };
            rta.push( o );
        };

        self.setState({caldata: ret.cal, rtdata: rta, loading: false });
        //self.syncCols("rtTable");
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e) {
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }
  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "Delbokning";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        <div className="m2_backdrop" />
        <div className="m2_dlg mx_90 flex-col" style={ this.dlg_style() } >

        <div className="mx_h01">
            <div>BELÄGGNING</div>
        </div>
        <div className="mx_content flex px-2">

            <div className="flex-row flex-space-between">
                <div className="flex-row">
                    <div className="dt-btn-01" onClick={() => this.subday() } ><FontAwesomeIcon icon="minus" size="1x" /></div>
                    <Dt_Cal startdatum="2020-02-01" rp="1" ref={this.dt_fd} getValue={(e)=> this.dt_fdat(e)} />
                    <div className="dt-btn-01" onClick={() => this.oneday() } >1</div>
                    <Dt_Cal startdatum="2020-02-01" rp="1" ref={this.dt_td} getValue={(e)=> this.dt_tdat(e)} />
                    <div className="dt-btn-01" onClick={() => this.addday() } ><FontAwesomeIcon icon="plus" size="1x" /></div>
                </div>
                <div className="flex"></div>
                <button className="btn btn-hw-blue w150" onClick={() => this.bel_rt() } aria-label="System" >
                    UPPDATERA
                </button>
            </div>
            <div className="divider_green"></div>
    <div className="web-col">

        <table className="table table-bordered table-striped tb_pres">
            <thead>
            <tr>
                <th className='header'></th>
                { this.state.caldata.map((dto, key) =>
                    <th key={dto.dt} style={ this.dt_style(dto) } onMouseEnter={ () => this.dioenter(dto) }
                        onMouseLeave={ () => this.dioleave(dto) } onClick={ () => this.set_di(dto) }>{ dto.dt }</th>
                )}
            </tr>
            <tr>
                <th className='header'></th>
                { this.state.caldata.map((dto, key) =>
                    <th style={ this.dt_style(dto) } key={dto.dt}>{ dto.shortday }</th>
                )}
            </tr>
            </thead>
            <tbody>
            <tr>
                <td className='header'>Bokat</td>
                { this.state.caldata.map((dto, key) =>
                    <td key={dto.dt} className={ this.dt_class(dto)}>{ dto.borr }</td>
                )}
            </tr>
             <tr>
                <td className='header'>Gäster</td>
                { this.state.caldata.map((dto, key) =>
                    <td key={dto.dt} className={ this.dt_class(dto)}>{ dto.borg }</td>
                )}
            </tr>
            <tr>
                <td className='header'>Barn</td>
                { this.state.caldata.map((dto, key) =>
                    <td key={dto.dt} className={ this.dt_class(dto)}>{ dto.antb0 }{ dto.antb1 }{ dto.antb2 }</td>
                )}
            </tr>
            <tr>
                <td className='header'>Ankommande</td>
                { this.state.caldata.map((dto, key) =>
                    <td key={dto.dt} className={ this.dt_class(dto)}>{ dto.ankr }</td>
                )}
            </tr>
            <tr>
                <td className='header'>Lediga rum</td>
                { this.state.caldata.map((dto, key) =>
                    <td key={dto.dt} className={ this.dt_class(dto)}>{ dto.free }</td>
                )}
            </tr>
            <tr>
                <td className='header'>Stängda</td>
                { this.state.caldata.map((dto, key) =>
                    <td key={dto.dt} className={ this.dt_class(dto)}>{ dto.closed }</td>
                )}
            </tr>
            <tr>
                <td className='header'>Frukost</td>
                { this.state.caldata.map((dto, key) =>
                    <td key={dto.dt} className={ this.dt_class(dto)}>{ dto.breakfast }</td>
                )}
            </tr>
            <tr className='totrad'>
                <td className='header'>Belägging</td>
                { this.state.caldata.map((dto, key) =>
                    <td key={dto.dt}>{ dto.belag }</td>
                )}
            </tr>
           </tbody>
            <tbody className="">
                { this.state.rtdata.map((rto, key) =>
            <tr key={key}>
                <td onClick={ () => this.selrt() } className='header'>{rto.rt.trim()}</td>
                { rto.dta.map((dto, dkey) =>
                <td key={dkey} onClick={ () => this.selfdt(dto) } onMouseOver={ () => this.selov(dto) } onMouseDown={ () => this.seldn(dto) } onMouseUp={ () => this.selup(dto) }
                    className={ this.get_css(dto) }>{ dto.maxr - dto.borr - dto.closed}</td>
                )}
            </tr>
                )}
            <tr className='totrad'>
                <td className='header'>Totalt</td>
                { this.state.caldata.map((dto, key) =>
                <td key={key} >{dto.available - dto.nrr - dto.closed}</td>
                )}
            </tr>
            </tbody>

        </table>

    </div>

        </div>

        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex-row">
            </div>
        </div>
      </div>
      </div>
	;
	return html;
  };
}

export default Rt_Bel;