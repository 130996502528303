import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";
import Td_Sel from "../../lib/ui/td_sel";
import Dd_Sel from "../../lib/ui/dd_sel";
import * as utils from '../../assets/utils.js'

import Udid_New from "./fkn/udid_new";
import Udid_Edit from "./fkn/udid_edit";
import { parseISO, addYears, format } from 'date-fns';

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Terms_Udid extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = {};
    this.state.seldate = new Date();
    this.state.avd = "01";
    this.state.uda = [];
    this.state.udo = {};
    this.state.ta = [
                {id: '01', text: 'TERMINALER'},
                {id: '02', text: 'ÖVRIGA'}
            ];
    this.state.wnds = {};

    this.selitems = [
        {fkn: 'open', text: 'ÖPPNA'}
    ];
      this.fknitems = [
          {fkn: 'preg_inx', text: 'INDEXERA REGISTER'},
          {fkn: 'preg_imp', text: 'IMPORTERA REGISTER'}
      ];
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.udids();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      css.overflow = "auto";
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }


udids() {
    var self = this;
    var prm = {};
    prm.req = "sys.sa_kid.udids";
    prm.sz = "";
    //prm.avd = this.state.avd;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        var uda = ret.rca;
        var udo = ret.rca;
        var o = ret.rca[0];
        //uda = Object.values(udo);
        //alert(JSON.stringify( o.status ));
        //toastr.success("OK", "SAPI");
        self.setState({ uda: uda, udo: udo }, function () {
            self.syncTb("udidTable");
        });
    })
    .catch(function(e) {
        var txt = JSON.stringify(e);
    });
  }
    syncTb(tb){
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
        tdElements = tbel.getElementsByTagName('td');

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
            width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
  sel_row_fkn(sel, e) {
    //alert(JSON.stringify(fkn));
      if(sel.fkn == "logout") {
      }
  }

  sel_fkn(sel) {
        //alert(JSON.stringify(sel));
        if(sel.fkn == "preg_imp") {
            this.preg_imp();
        }
    }

    udid_new() {
        var wnds = {};
        wnds.udidnew = 1;
        wnds.pro = {};
        this.setState({ wnds: wnds });
    }
    udid_edit(row) {
        var wnds = {};
        wnds.udidedit = 1;
        wnds.udo = row;
        wnds.cb = this.cb_wnd;
        this.setState({ wnds: wnds });
    }
    cb_wnd(ctx, ret) {
        if(!ret.ok || (ret.ok !== "000")) {
          ctx.setState({ wnds: {} });
          ctx.presreg();
          return;
          }
      
          ctx.setState({ wnds: {} });
          ctx.presreg();
      }
    tb_sort(typ) {
        var self = this;
        //var rvs = self.state.rvs;
        var pra = self.state.pra;
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;
/*
        if(sortid != typ) sortdir = 0;
        else {
            if(sortdir) sortdir = 0;
            else sortdir = 1;
        }
        var fa = typ.split(".");
        if(true) {
            if(sortdir) pra.sort((a,b) => (a[fa[0]][fa[1]] < b[fa[0]][fa[1]]) ? 1 : ((b[fa[0]][fa[1]] < a[fa[0]][fa[1]]) ? -1 : 0));
            else pra.sort((a,b) => (a[fa[0]][fa[1]] > b[fa[0]][fa[1]]) ? 1 : ((b[fa[0]][fa[1]] > a[fa[0]][fa[1]]) ? -1 : 0));
        }
        self.setState({ pra: pra, sortid: typ, sortdir: sortdir });
    */
    }

  render() {
    return (
    <div className="web-app">

    <div className="flex-row">
        <select className="form-control sel_input" placeholder="Välj typ">
            { this.state.ta.map((row, key) =>
            <option key={key} value={row.id}>{row.text}</option>
            )}
        </select>
        <div className="flex"></div>
        <button className="btn btn-hw-green w150" onClick={() => this.udid_new() } aria-label="System" >
            LÄGG TILL
        </button>
        <button className="btn btn-hw-blue w150" onClick={() => this.udids() } aria-label="System" >
            UPPDATERA
        </button>
        <Dd_Sel items={ this.fknitems } cls="web-top-icon" getValue={(e)=> this.sel_fkn(e)} />
    </div>
    <div className="divider_orange"></div>

    <div className="web-col">
        <div id="udidTable">
        <table className="table table-bordered table-hover tb " style={{marginBottom: "0px"}}>
            <thead>
            <tr>
                <th onClick={() => this.tb_sort("p0id") }>UDID</th>
                <th onClick={() => this.tb_sort("p0text") }>Termnr</th>
                <th onClick={() => this.tb_sort("p0datum") }>Typ</th>
                <th onClick={() => this.tb_sort("p0expire") }>HW</th>
                <th onClick={() => this.tb_sort("p0belopp") }>Text</th>
                <th onClick={() => this.tb_sort("p0saldo") }>Ip</th>
                <th onClick={() => this.tb_sort("p1issuedby") }>Port</th>
                <th onClick={() => this.tb_sort("p1anm") }>Databas</th>
                <th onClick={() => this.tb_sort("p1anm") }>Status</th>
                <th></th>
            </tr>
            </thead>
            </table>
            <div style={ this.tb_style() }>
          <table className="table table-bordered table-hover">
            <tbody>
            { this.state.uda.map((row, key) =>
            <tr key={key} onDoubleClick={() => this.udid_edit(row) }>
                <td>{ row.udid }</td>
                <td>{ row.termnr }</td>
                <td>{ row.termtyp }</td>
                <td>{ row.termhw }</td>
                <td>{ utils.toLang(row.text) }</td>
                <td>{ row.tos.ip }</td>
                <td>{ row.tos.port }</td>
                <td>{ row.tos.db }</td>
                <td>{ row.pos.start }</td>
                <td>
                    <Td_Sel items={ this.selitems } getValue={(e)=> this.sel_row_fkn(row, e)} />
                </td>
            </tr>
            )}
            </tbody>
        </table>
        </div>
        </div>
    </div>
    { this.state.wnds.udidnew ? <Udid_New wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
    { this.state.wnds.udidedit ? <Udid_Edit wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
   </div>
  );
    }
}
export default Terms_Udid;
