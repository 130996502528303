import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";

import Bq_Trs from "./bq/bq_trs";
import Bq_List from "./bq/bq_list";
import Bq_Screen from "./bq/bq_screen";

import Dt_Cal from "../../lib/ui/dt_cal";
import Wnd_Ok from "../../lib/ui/wnd_ok";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Pos_Mtv extends React.Component {
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pos = props.store.pos;

    this.state = { pgid: 11, tra: [] };
    this.state.seldate = new Date();
    this.state.avd = "01";
    this.state.bqo = {};
    this.state.bqa = [];
    this.state.msg = {};
    this.state.wnds = {};
    this.state.psa = [];
    this.state.psa = [
        {id: '00', text: 'ÖVERVAK / LEDARE'},
        {id: '01', text: 'VARM KÖK'},
        {id: '02', text: 'KALL KÖK'},
        {id: '03', text: 'DESSERT KÖK'},
        {id: '04', text: 'BAR 1'},
        {id: '05', text: 'BAR 2'},
        {id: '00', text: 'SERVIS / LEVERANS'}
    ];
    this.state.bongpos = "00";
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.bq();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  bong_style(row) {
      var css = {};
      if(row.vtid > 1150) {
          css.background = "#ff9900";
          css.color = "#fff";
      }
      if(row.vtid > 1200) {
          css.background = "#800";
          css.color = "#fff";
      }
      return css;
  }
  stol_text(row) {
      var text = "";
      if((row.stol == "00") || (row.stol == "  ")) {
          text = "";
      }
      else {
          text = "STOL: " + row.stol;
      }
      return text;
  }
  bongrow_style(row) {
      var css = {};
      if(row.r0guestno != "00") {
          css.color = "#ff9900";
          css.textDecoration = "line-through";
      }
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

  bq_edit(row) {
      var self = this;
      alert(JSON.stringify(row.plu00));
  }
  bq_trs() {
      var self = this;
      var wnds = {};
      wnds.trs = true;
      wnds.tra = this.state.bqa;
      wnds.backdrop = true;
      self.setState({wnds: wnds });
  }
  bong_show(row) {
      var self = this;
      var wnds = {};
      wnds.bong = true;
      wnds.row = row;
      wnds.backdrop = true;
      self.setState({wnds: wnds });
  }
  bq_list() {
      var self = this;
      var wnds = {};
      wnds.list = true;
      wnds.tra = this.state.bqa;
      wnds.backdrop = true;
      self.setState({wnds: wnds });
  }
  bq_screen() {
      var self = this;
      var wnds = {};
      wnds.screen = true;
      wnds.tra = this.state.bqa;
      wnds.backdrop = true;
      self.setState({wnds: wnds });
  }

  bq() {
    var self = this;
    var prm = {};
    prm.req = "pos.pos_bq.bq";
    prm.bongpos = this.state.bongpos;

    //alert(JSON.stringify(prm));
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        var bqo = ret.rco;
        self.setState({bqo: bqo });
        self.syncTb("bqTable");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

  row_bong(row, e) {
    var self = this;

    if(true) return;
    e.preventDefault();

    var prm = {};
    prm.req = "pos.pos_bq.bongrow";
    prm.row = row;
    prm.status = "09";

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        if(ret.ok != "000") {
            var msg = { ok: true, text: "MISSLYCKADES ..." };
            self.setState({msg: msg });
            setTimeout(function(){
                self.setState({msg: {} });
            }, 1000);
            return;
        }
        row.r0guestno = "09";
        var msg = { ok: true, text: "BONGAD ..." };
        self.setState({msg: msg });
        setTimeout(function(){
            self.setState({msg: {} });
        }, 1000);
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }

  row_stol(row, e) {
    var self = this;

    e.preventDefault();

    var prm = {};
    prm.req = "pos.pos_bq.stolrow";
    prm.row = row;
    prm.status = "03";

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        //self.setState({bqa: ret.rca });
        //toastr.success("OK", "SAPI");
        var msg = { ok: true, text: "STOL SATT ..." };
        self.setState({msg: msg });
        setTimeout(function(){
            self.setState({msg: {} });
        }, 1000);
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
    syncTb(tb){
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
        tdElements = tbel.getElementsByTagName('td');

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
            width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }

  render() {
    return (
    <div className="web-app">

    <div className="flex-row">
        <select className="form-control w250 hide" placeholder="Välj position" onChange={(e) => this.setState({bongpos: e.target.value})}>
            { this.state.psa.map((row, key) =>
            <option key={key} value={row.id}>{row.text}</option>
            )}
        </select>
        <div className="txt_18 px-3">MENYTV SKÄRM</div>
        <div className="flex"></div>
        <button className="btn btn-hw-blue w150" onClick={() => this.bq_screen() } aria-label="System" >
            SKÄRMAR
        </button>
        <button className="btn btn-hw-blue w150" onClick={() => this.bq_trs() } aria-label="System" >
            LAYOUTER
        </button>
        <button className="btn btn-hw-blue w150" onClick={() => this.bq_trs() } aria-label="System" >
            MENYER
        </button>
        <button className="btn btn-hw-blue w150" onClick={() => this.bq() } aria-label="System" >
            UPPDATERA
        </button>
    </div>
    <div className="divider_orange"></div>

    <div className="grid">
        { Object.values(this.state.bqo).map((row, key) =>
        <div key={key} className=""  onClick={() => this.bong_show(row) }>
            <div className="" style={ this.bong_style(row) }>
            <div className="flex-row flex-sb txt_18">
                <div className="">Bord: { row.bordnr }</div>
                <div>Notanr: { row.notanr }</div>
            </div>
            <div className="flex-row flex-sb txt_14">
                <div className="">Tid: { row.tid }</div>
                <div>VTid: { row.vtid }</div>
            </div>
            </div>
            <div className="divider_green"></div>
            { Object.values(row.srows).map((srow, skey) =>
                <div key={skey} className="">
                    <div className="flex-row flex-sb txt_14">
                        <div className="">{ this.stol_text(srow) }</div>
                    </div>
                { srow.rows.map((rrs00, rkey) =>
                    <div className="bq-row"  style={ this.bongrow_style(rrs00) } onClick={(e) => this.row_bong(rrs00, e) } onContextMenu={(e) => this.row_stol(rrs00, e) }>{ rrs00.r0radnr } : { rrs00.r0etyp } - { rrs00.r0text.toswe() } - { rrs00.r0perprice.cdipris() }</div>
                )}
                </div>
            )}
            <div className="divider_green"></div>
            <div>Bongat: { row.bongat }</div>
        </div>
        )}
    </div>

    <div className="web-row hide">
        <div id="bqTable" className="flex">
        <table className="table table-bordered table-hover tb fixed_header">
            <thead>
            <tr>
                <th>Bord</th>
                <th>Notanr</th>
                <th>Belopp</th>
                <th>Rader</th>
                <th>Bongat</th>
                <th>Status</th>
            </tr>
            </thead>
            <tbody style={ this.tb_style() }>
            { Object.values(this.state.bqo).map((row, key) =>
            <tr key={key} id={ row.bordnr } onDoubleClick={() => this.row_bong(row) }>
                <td>{ row.bordnr }</td>
                <td>{ row.notanr }</td>
                <td>{ row.belopp.cdipris() }</td>
                <td>{ row.rows.length }</td>
                <td>{ row.bongat }</td>
                <td>{ row.status }</td>
            </tr>
            )}
            </tbody>
        </table>
        </div>
    </div>

        { this.state.wnds.trs ? <Bq_Trs wnds={this.state.wnds} ctx={this} /> : null}
        { this.state.wnds.list ? <Bq_List wnds={this.state.wnds} ctx={this} /> : null}
        { this.state.wnds.screen ? <Bq_Screen wnds={this.state.wnds} ctx={this} /> : null}
        <Wnd_Ok msg={this.state.msg} ctx={this} />
    </div>
  );
  }
}
export default Pos_Mtv;
