import React, { useState, useEffect, useContext } from 'react';
import * as fkn from "../../lib/fkn";
import * as net from "../../lib/net";
import Dt_Cal from "../../lib/ui/dt_cal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Pos_Dr extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, fsa: [], bea: [], dta: [] };
    this.state.date = new Date();
    this.state.fdat = new Date();
    this.state.tdat = new Date();
    this.state.fkn = "";
    this.state.sdo = {};
    //context.posrend = this;
    let cdat = new Date();
    let formatted_date = cdat.getFullYear() + "-" + (cdat.getMonth() + 1) + "-" + cdat.getDate();
    this.state.today = formatted_date;
    this.state.datum = formatted_date;
    this.state.fdatum = formatted_date;
    this.state.tdatum = formatted_date;
  }
  componentDidUpdate() {
    let value = this.context;
    /* ... */
  }
  componentWillUnmount() {
    let value = this.context;
    /* ... */
  }
  componentDidMount() {
    //this.props.fetchWells()
    //  .then(res => this.setState({ wells: res.wells }) )
    //this.trans();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tbs_style() {
      var css = {};
      css.minHeight = window.innerHeight - 250;
      return css;
  }
  tb_style() {
      var css = {};
      //css.height = window.innerHeight - 250;
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }
  change_fdat(e) {
    //let cdat = this.state.seldate;
      if(e.value == null) return;

    let cdat = e.value;
    let formatted_date = cdat.getFullYear() + "-" + (cdat.getMonth() + 1).toString().tonum(2) + "-" + cdat.getDate().toString().tonum(2);
    this.setState({fdat: e.value});
    this.setState({fdatum: formatted_date});
    this.setState({tra: [] });
  }
  change_tdat(e) {
    //let cdat = this.state.seldate;
      if(e.value == null) return;
    let cdat = e.value;
    let formatted_date = cdat.getFullYear() + "-" + (cdat.getMonth() + 1).toString().tonum(2) + "-" + cdat.getDate().toString().tonum(2);
    this.setState({tdat: e.value});
    this.setState({tdatum: formatted_date});
    this.setState({tra: [] });
  }
  change_datum(e) {
    //let cdat = this.state.seldate;
      if(e.value == null) return;
    let cdat = e.value;
    let formatted_date = cdat.getFullYear() + "-" + (cdat.getMonth() + 1).toString().tonum(2) + "-" + cdat.getDate().toString().tonum(2);
    this.setState({date: e.value});
    this.setState({datum: formatted_date});
    this.setState({tra: [] });
  }
  change_fkn(e) {
    //let cdat = this.state.seldate;
    //alert(JSON.stringify(e));
    let fkn = e.target.value;
    this.setState({fkn: fkn});
    this.setState({tra: [], tsa: [], tta: [] });
  }

  dt_fkn() {
    var self = this;
    var datum = this.state.datum;
    var today = this.state.today;

    if(this.state.fkn == "TRS") this.dt_hbok();
    else if(this.state.fkn == "TOT") this.dt_htot();
    else if(this.state.fkn == "KTO") this.dt_hkonto();
    else this.dt_hsum();
  }

  dt_dr() {
    var self = this;
    var prm = {};
    prm.req = "pos.pos_red.red_dag";
    prm.fdat = this.state.datum;
    prm.tdat = this.state.datum;

    //gda.wndLoading("Hämtar");
    //net.sio_req(prm)
    net.sid_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            return;
        }
        var dts = self.state.datum.replace(/-/g, "");
        var dto = ret.rco[dts];
        var dta = [];
        var keys = Object.keys(dto);
        for(var key of keys) {
            var o = dto[key];
            dta.push( o );
        }
        //alert(JSON.stringify(dta));
        self.setState({ dta: dta });
        //self.setState({tra: ret.rca });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
    }
  show_fsg() {
    var pris = "0.00";
    if(this.state.sdo.tot && this.state.sdo.tot.fsg) {
        pris = this.state.sdo.tot.fsg.cdipris()
    }
    return pris;
  }
  show_bet() {
    var pris = "0.00";
    if(this.state.sdo.tot && this.state.sdo.tot.bet) {
        pris = this.state.sdo.tot.bet.cdipris()
    }
    return pris;
  }

  render() {
    return (
    <div className="web-app" style={{margin: "0px", padding: "5px 10px"}}>
    <h3>Kassa Dagrapport</h3>

    <div className="flex-row flex-space-between">
        <div className="flex-row">
            <div className="flex-row">
                <div className="dt-btn-01" onClick={() => this.subday() } ><FontAwesomeIcon icon="minus" size="1x" /></div>
                <Dt_Cal dts={this.state.dts} rp="1" ref={this.dt_dt} getValue={(e)=> this.dt_dat(e)} />
                <div className="dt-btn-01" onClick={() => this.addday() } ><FontAwesomeIcon icon="plus" size="1x" /></div>
            </div>
        </div>
        <button className="btn btn-hw-blue w150 ripple" onClick={() => this.dt_dr() } aria-label="System" >
            Visa
        </button>
    </div>
    <div className="divider_orange"></div>

    <div className="web-col" style={{background: "#555"}}>
    <div className="web-row" style={{margin: "0px auto", padding: "5px 10px", width: "750px", background: "#fff"}}>
        <table className="tb-lt01 tb fix_head" style={ this.tbs_style() }>
            <thead>
            <tr>
                <th>Konto</th>
                <th style={{width: "300px"}}>Text</th>
                <th>Antal</th>
                <th>Belopp</th>
            </tr>
            </thead>
            <div className="">Försäljning</div>
            <div className="divider_orange"></div>
            <tbody style={ this.tb_style() }>
            { this.state.dta.map((row, key) =>
            <tr key={ row.konto }>
                <td>{ row.konto }</td>
                <td style={{width: "300px"}}>{ row.text }</td>
                <td className="text-center">{ row.antal.ztrim() }</td>
                <td className="text-right">{ row.belopp.cdipris() }</td>
            </tr>
            )}
            </tbody>
            <div className="divider_green"></div>
            <div className="flex text-right">{ this.show_fsg() }</div>

            <div className="">Betalning</div>
            <div className="divider_orange"></div>
            <tbody style={ this.tb_style() }>
            { this.state.bea.map((row, key) =>
            <tr id={ row.konto } ng-dblclick="trans_open(row);">
                <td>{ row.konto }</td>
                <td style={{width: "300px"}}>{ row.text }</td>
                <td className="text-center">{ row.antal.ztrim() }</td>
                <td className="text-right">{ row.belopp.cdipris() }</td>
            </tr>
            )}
            </tbody>
            <div className="divider_green"></div>
            <div className="flex text-right">{ this.show_bet() }</div>
        </table>
    </div>
    </div>
    <div className="divider_orange"></div>
    </div>
  );
}
}
export default Pos_Dr;
