import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";

import Dt_Cal from "../../lib/ui/dt_cal";
import {format, getHours, subDays} from "date-fns";
import ddo from "../../ddo";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Mark_Pass extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, paa: [] };
    this.state.seldate = new Date();
    this.state.unit = null;
      this.state.una = ddo.cfg.una;
      this.state.uno = ddo.cfg.units;

      this.state.today = new Date();
      var hh = getHours(this.state.today);
      if( hh < 7) {
          const dt = subDays(new Date(), 1);
          this.state.datum = format(dt, "yyyy-MM-dd");
      }
      else this.state.datum = format(this.state.today, "yyyy-MM-dd");
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.pass();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 250;
      return css;
  }

  onUnitChange(e) {
        this.setState({unit: e.value});
  }
  change_unit(e) {
    //let cdat = this.state.seldate;
    //alert(JSON.stringify(e));
    let unit = e.target.value;
    this.setState({unit: unit});
    this.setState({tra: [] });
  }

  dt_dat(e) {
    //alert(JSON.stringify(e));
    var dts = format(e, "yyyy-MM-dd");
    this.setState({ datum: dts });
  }
  pass() {
    var self = this;
    var datum = this.state.datum;
    var prm = {};
    prm.req = "pos.pos_cas.mark_pass";
    prm.datum = datum;
    //prm.tdat = datum;

    //gda.wndLoading("Hämtar");
    //net.sio_req(prm)
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            return;
        }
        self.setState({paa: ret.rca });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
    }
    trs_open(row) {
    }
    show_unit(row) {
      var text = "";
      var unitid = row.s0enhet.trim();
      text = row.s0enhet.trim();
      if(ddo.cfg.units[unitid]) {
          var o = ddo.cfg.units[unitid];
          text = o.id + "-" + o.text;
      }
      return text;
    }
    show_typ(row) {
        var text = "";
        if(row.s0typ == "SD") text = "START PASS";
        if(row.s0typ == "ED") text = "SLUT PASS";
        return text;
    }

  render() {
    return (
    <div className="web-app">
    <h3>Arbets Pass</h3>

    <div className="flex-row flex-space-between">
        <div className="flex-row">
            <Dt_Cal dts={this.state.datum} getValue={(e)=> this.dt_dat(e)} />
        <select className="form-control sel_input w300" placeholder="Välj enhet" value={this.state.unitid} onChange={(e) => this.change_unit(e)} >
            { this.state.una.map((row, key) =>
            <option key={key} value={row.id}>{row.text}</option>
            )}
        </select>
        </div>
         <button className="btn btn-hw-blue w150 ripple" onClick={() => this.pass() } aria-label="System" >
            Visa
        </button>
   </div>
    <div className="divider_orange"></div>

    <div className="web-row">
        <table className="table table-bordered table-hover tb fixed_header">
            <thead>
            <tr>
                <th>Datum</th>
                <th>Typ</th>
                <th>Person nr</th>
                <th>Text</th>
                <th>Tid</th>
                <th>Enhet</th>
                <th>Lopnr</th>
                <th>Status</th>
            </tr>
            </thead>
            <tbody style={ this.tb_style() }>
            { this.state.paa.map((row, key) =>
            <tr key={key} onClick={() => this.trs_open(row) }>
                <td>{ row.s0datum.cdidate() }</td>
                <td>{ this.show_typ(row) }</td>
                <td>{ row.s0id.cdipnr() }</td>
                <td>{ row.s0text }</td>
                <td>{ row.s0regtid.cditid() }</td>
                <td>{ this.show_unit(row) }</td>
                <td>{ row.s0lopnr }</td>
                <td>{ row.s0status }</td>
            </tr>
            )}
            </tbody>
        </table>
    </div>

    </div>
  );
}
}
export default Mark_Pass;

/*
    <td>{ this.state.uno[row.r0cstplc].text }</td>
*/