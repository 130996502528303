import React, { useState, useEffect, useContext } from 'react';
import * as fkn from "../lib/fkn";
import SDOContext from '../sdo.js'
import {Route, Routes} from "react-router-dom";
//import ADOContext from "../ado";

import Stat_Home from './stat/home.js';
import {withRouter} from "../lib/react/withRouter";
import Pos_Home from "./pos/home";
import sso from "../sso";

function pg_home() {

    var html =
    <div className="web-content">
      Hem
    </div>
    ;
    return html;
}

class Bi_Stat extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, deps: [] };
      var po = {};
      po.total = { "fid": "f06", "url": "/bi/stat/total", "name": "Totalstatus", "active": false };
      this.state.po = po;
      this.state.pa = [];
      this.state.pa = Object.values(po);
  }
    fknGo(row) {
        sso.seo.sfkn = row;
        this.props.navigate(row.url);
    }
    br_css(row) {
        var css = {};
        if(sso.seo.sfkn && (row.url == sso.seo.sfkn.url)) css.color = "#080";
        //var brc = false;
        //if(row.url == sbo.state.url) brc = true;
        return css;
    }

  render() {
    return (

        <div className="web-app">
            { this.props.store.cfg.bread ?
                <div className="web-bread">
                    <div className="breadcrumb">
                        { this.state.pa.map((row, key) =>
                            <div key={key} className="breadcrumb-item" style={this.br_css(row)} onClick={() => this.fknGo(row)}>{row.name}</div>
                        )}
                        <div className="flex"></div>
                        <div className="cdigreen">{ sso.seo.sfkn ? sso.seo.sfkn.name : null }</div>
                    </div>
                </div>
                : null }
        <Routes>
          <Route path="/xx" component={pg_home} />
            <Route path="total" element={ <Stat_Home store={this.webStore} />} />
            <Route path="*" element={ <Stat_Home store={this.webStore} />} />
        </Routes>
    </div>

  );
}
}
//Sys.contextType = ADOContext;

export default withRouter(Bi_Stat);
