import React, { useState, useEffect, useContext } from 'react';
import {Route, Routes} from "react-router-dom";
import {withRouter} from "../lib/react/withRouter";
import {observer} from "mobx-react";

import Sys_Home from './sys/home.js';
import Sys_Users from './sys/users.js';
import sso from "../sso";


class Tmpl_Sys extends React.Component {
    //var sdo = useContext(SDO);
    constructor(props, context) {
        super(props);

        this.store = props.store;
        this.state = {};
        //context.posrend = this;

        var po = {};
        po.users = { "fid": "f06", "url": "/tmpl/sys/users", "name": "Användare", "active": false };
        //po.tech = { "fid": "f06", "url": "/tmpl/tech", "name": "Teknik", "active": false };
        this.state.po = po;
        this.state.pa = [];
        this.state.pa = Object.values(po);
    }
    componentDidUpdate() {
    }
    componentWillUnmount() {
    }
    componentDidMount() {
        /*
        var path = window.location.pathname;
        if(path.substr(-1) == "/") path = path.substring(0, path.length - 1);
        var op = path.split("/").pop();

        var po = this.state.po;
        if(po[op]) {
            po[op].active = true;
            //this.props.store.fkn = po[op];
            sbo.state.fkn = po[op].name;
            //alert(JSON.stringify(sbo.state));
        }
        var pa = Object.values(po);
        this.setState({ po: po, pa: pa });
         */
    }
    fknGo(row) {
        sso.seo.sfkn = row;
        this.props.navigate(row.url);
    }
    br_css(row) {
        var css = {};
        if(sso.seo.sfkn && (row.url == sso.seo.sfkn.url)) css.color = "#080";
        //var brc = false;
        //if(row.url == sbo.state.url) brc = true;
        return css;
    }

    render() {
        return (
            <div className="web-app">
                { this.props.store.cfg.bread ?
                    <div className="web-bread">
                        <div className="breadcrumb">
                            { this.state.pa.map((row, key) =>
                                <div key={key} className="breadcrumb-item" style={this.br_css(row)} onClick={() => this.fknGo(row)}>{row.name}</div>
                            )}
                            <div className="flex"></div>
                            <div className="cdigreen">{ sso.sdo.sfkn ? sso.sdo.sfkn.name : null }</div>
                        </div>
                    </div>
                    : null }
        <Routes>
            <Route path="users" element={ <Sys_Users store={this.store} />} />
            <Route path="*" element={ <Sys_Home store={this.store} />} />
        </Routes>
    </div>
  );
}
}
//Sys.contextType = ADOContext;

export default withRouter(observer(Tmpl_Sys));
