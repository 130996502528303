import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class SHD_Dep extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, tra: [] };
    this.state.seldate = new Date();
    this.state.avd = "01";
    this.state.kaa = [];
    this.state.kro = {};
    this.state.aa = [];
    this.state.cols = {};
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
    tb_style() {
        var css = {};
        css.height = window.innerHeight - 275;
        css.overflow = "auto";
        return css;
    }
    th_style(id) {
        var css = {};
        css.cursor = "pointer";
        if(id == this.state.sortid) {
            if(this.state.sortdir) css.color = "#800";
            else css.color = "#080";
        }
        return css;
    }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

  render() {
    return (
    <div className="web-app">

    <div className="flex-row flex-space-between">
        <div className="label_h03">AVDELNINGS SCHEMA</div>
        <select className="form-control sel_input" placeholder="Välj avdelning">
            { this.state.aa.map((row, key) =>
            <option key={key} value={row.id}>{row.text}</option>
            )}
        </select>
        <button className="btn btn-hw-blue w150 ripple" onClick={() => this.syncCols("kidTable") } aria-label="System" >
            Visa
        </button>
    </div>
    <div className="divider"></div>

        <div id="schdTable" className="flex">
            <table className="table table-bordered table-hover tb " style={{marginBottom: "0px"}}>
                <thead>
                <tr>
                    <th style={ this.th_style("c01" ) }>Schemanr</th>
                    <th style={ this.th_style("c02" ) }>Text</th>
                    <th style={ this.th_style("c03" ) }>Veckor</th>
                    <th style={ this.th_style("c04" ) }>Tid</th>
                    <th style={ this.th_style("c05" ) }>Status</th>
                    <th style={ this.th_style("c06" ) }></th>
                </tr>
                </thead>
            </table>
            <div style={ this.tb_style() }>
                <table className="table table-bordered table-hover tb">
                    <tbody>
                    { this.state.kaa.map((row, key) =>
                        <tr key={key} onDoubleClick={() => this.shd_edit(row) }>
                            <td>{ row.nr }</td>
                            <td>{ row.text }</td>
                            <td>{ row.veckor }</td>
                            <td>{ row.tid }</td>
                            <td>{ row.status }</td>
                            <td style={{width:"40px",cursor:"pointer"}} onClick={() => this.shd_del(row) }><i className="fa fa-cog text-danger"></i></td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
        </div>
   </div>
  );
    }
}
export default SHD_Dep;
