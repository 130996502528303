import React, { useState, useEffect, useContext } from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";

import { observer } from "mobx-react";
import {withStore} from "../../sdo";

import * as net from "../../lib/net";

import Tiden from "../../lib/ui/tiden";
import Dd_Sel from "../../lib/ui/dd_sel";

import Reg_Top from './hotreg_top.js';
import Reg_Mnu from './hotreg_mnu.js';
import Reg_Home from './home.js';
import Reg_Dash from './dash.js';
import Reg_Auth from './auth.js';

import Reg_Eko from './eko.js';
import Reg_Rs from './rs/rs.js';
import Reg_Rt from './rt/rt.js';
import Reg_Kt from './kt/kt.js';
import Reg_Signs from './signs/signs.js';
import Reg_Allot from './allot/allot.js';
import Reg_Di from './di/di.js';
import Fo_Home from "../fo/home";
import {withRouter} from "../../lib/react/withRouter";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function web01() {

    var html =
    <div className="web-content">
    </div>
    ;
    return html;
}

class Hotreg extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.selitems = [
        {fkn: 'setup', text: 'INSTÄLLNINGAR'},
        {fkn: 'upd', text: 'UPPDATERA'},
        {fkn: 'sys', text: 'SYSTEM'},
        {fkn: 'logout', text: 'LOGGA UT'}
    ];
    this.btmitems = [
        {fkn: 'kreg', text: 'KUNDREGISTER'},
        {fkn: 'logg', text: 'LOGG'},
        {fkn: 'hist', text: 'HISTORIK'}
    ];
    this.langitems = [
        {fkn: 'sv', text: 'SVENSKA'},
        {fkn: 'no', text: 'NORSKA'},
        {fkn: 'en', text: 'ENGELSKA'}
    ];

    this.webStore = props.store;
    this.state = { pgid: 11, deps: [] };

    this.state.wnds = {};
    this.state.boknr = "";
    this.state.lang = "sv";

  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {

    var pms = {};
      pms.rta = [];
      pms.rto = {};
      pms.pka = [];
      pms.pko = {};
    pms.stat = {};
    this.props.store.pms = pms;
    //alert(JSON.stringify(pos));
    this.pms_init();
  }

    pms_init() {
        var self = this;

        var req = {};
        req.req = "pms.fkn.pms_init";

        net.sio_req(req)
        .then(function(ret){
            //alert(JSON.stringify(ret));

            if(!self.props.store.pms) self.props.store.pms = {};
            self.props.store.pms.rto = ret.rco.rto;
            self.props.store.pms.rta = ret.rco.rta;
            self.props.store.pms.pko = ret.rco.pko;
            self.props.store.pms.pka = ret.rco.pka;

            //self.setState({ deps: ret.rco });
            self.st_state();
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
            //alert(JSON.stringify(txt));
        })
    }
  st_state() {
    var self = this;

    var prm = {};
    prm.req = "pms.cal.pms_dt_state";
    prm.datum = "";

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        self.props.store.pms.stat = ret.rco;
    })
    .catch(function(e) {
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }

  sz_open() {
      var self = this;
    var wnds = {};
    wnds.szw = 1;
    this.setState({ wnds: wnds });
  }
  rvn_open() {
      var self = this;
    var wnds = {};
    wnds.rvn = 1;
    this.setState({ wnds: wnds });
  }
  rr_open() {
    var self = this;
    var wnds = {};
    wnds.rr = 1;
    this.setState({ wnds: wnds });
  }
  rrp_open() {
    var self = this;
    var wnds = {};
    wnds.rrp = 1;
    this.setState({ wnds: wnds });
  }
  petty_open() {
      var self = this;
    var wnds = {};
    wnds.petty = 1;
    this.setState({ wnds: wnds });
  }
  fknGo(url) {
    //alert(row.href);
    this.props.navigate(url);
  }
  sel_fkn(sel) {
    //alert(JSON.stringify(fkn));
      if(sel.fkn == "logout") {
      }
  }
  sel_btm(sel) {
    //alert(JSON.stringify(fkn));
      if(sel.fkn == "kreg") this.fknGo("/pms/fo/kreg")
      if(sel.fkn == "logg") this.fknGo("/pms/fkn/logg")
  }
  sel_lang(sel) {
    //alert(JSON.stringify(fkn));
      if(sel.fkn == "sv") {
        this.props.xstore.setLang("sv");
      }
      if(sel.fkn == "no") {
        this.props.xstore.setLang("no");
      }
      if(sel.fkn == "en") {
        this.props.xstore.setLang("en");
      }
  }

  render() {

      //if(!this.props.store.pms.regauth) {
      if(false) {
          //this.props.store.pms.regauth = true;
            //alert("REGISTER AUTH");
            return (
            <div className="web-app">
                <div className="web-body">
                    <Routes>
                      <Route path="/pms/boreg/" render={(props) => <Reg_Auth {...props} store={this.webStore} />} />
                    </Routes>
                </div>
            </div>
          );
      }
      //else alert("REGISTER OK");

    return (
    <div className="web-appx">
    <div className="web-top">
        <Reg_Top ctx={this} store={this.webStore} />
    </div>
    <div className="web-main">

    <div className="web-row" style={{ height:"100%" }}>

    { this.props.store.cfg.mnu_show ?
    <div className="web-side">
        <Reg_Mnu store={this.webStore} />
    </div>
    : null }

    <div className="web-body">
        <Routes>
            <Route path="eko/*" element={ <Reg_Eko store={this.webStore} />} />
            <Route path="rs" element={ <Reg_Rs store={this.webStore} />} />
            <Route path="rt" element={ <Reg_Rt store={this.webStore} />} />
            <Route path="kt" element={ <Reg_Kt store={this.webStore} />} />
            <Route path="signs" element={ <Reg_Signs store={this.webStore} />} />
            <Route path="allot" element={ <Reg_Allot store={this.webStore} />} />
            <Route path="di" element={ <Reg_Di store={this.webStore} />} />
            <Route path="*" element={ <Reg_Home store={this.webStore} />} />
        </Routes>
    </div>

    </div>

    </div>
    <div className="web-foot">
        <div className="web-foot-mnu" onClick={() => this.rvn_open() } title="NY BOKNING">
            <FontAwesomeIcon icon="file-alt" size="1x"/>
        </div>
        <div className="flex flex-row">
            <div className="flex"></div>

            <div className="web-foot-btn" onClick={() => this.fknGo("/pms/hotreg/tr") } >TRANSAKTIONER</div>
        </div>
        <Dd_Sel items={ this.btmitems } getValue={(e)=> this.sel_btm(e)} />
        <div className="flex flex-row">
            <div className="web-foot-btn" onClick={() => this.fknGo("/pms/hotreg/pay") }>BETALNINGAR</div>

            <div className="flex"></div>
        </div>
        <div className="web-foot-rmnu hide" >
            <Dd_Sel items={ this.langitems } getValue={(e)=> this.sel_lang(e)} />
        </div>
        <Tiden />
    </div>

    </div>
  );
}
}
//Sys.contextType = ADOContext;
export default withRouter(withStore(observer(Hotreg)));

/*
        <div className="web-foot-rmnu" onClick={() => this.sz_open() }>
            <MDBIcon icon="building" size="1x"/>
        </div>
        <div className="web-foot-mnu" onClick={() => this.fknGo("/pms/fkn/logg") }>
            <MDBIcon icon="undo" size="1x"/>
        </div>

          <Route path="/xx" component={pg_s1} />
          <Route path="/pms/yy" component={pg_s2} />

            <div className="web-foot-btn" onClick={() => this.rvn_open() }>BOKA</div>
            <div className="web-foot-btn" onClick={() => this.fknGo("/pms/fkn/logg") } >
                Logg
            </div>
 */