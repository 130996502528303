import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../../lib/net";
import * as utils from "../../../assets/utils";
import Td_Sel from "../../../lib/ui/td_sel";
import {addDays, format} from "date-fns";
import Dt_Cal from "../../../lib/ui/dt_cal";
import PdfRpDlg from "../../lib/pdf_rp_dlg";
import Edit_Txt from "../../../lib/ui/edit_txt";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Dep_Per extends React.Component {
  constructor(props, context) {
    super(props);

    this.dt_fd = React.createRef();
    this.dt_td = React.createRef();

    this.store = props.store;
    this.state = {};
    this.state.aa = [];
    this.state.cols = {};
    this.state.wnds = {};

      if(this.ta && this.ta.base) {
          this.state.fdat = this.ta.base.fdat;
          this.state.tdat = this.ta.base.tdat;
      }
      this.state.tot = {};
      this.state.dpo = {};
      this.state.eeo = {};
    this.state.lso = {};
    this.state.dta = [];
    if(props.wnds) {
        this.state.dpo = props.wnds.dpo;
        this.state.fdat = props.wnds.fdat;
        this.state.tdat = props.wnds.tdat;
    }
    this.state.today = new Date();
    var dts = format(this.state.today, "yyyy-MM-dd");
    //this.state.fdat = dts;
    //this.state.tdat = dts;

    //var tdat = addDays(this.state.today, 30);
    //var tds = format(tdat, "yyyy-MM-dd");
    //this.state.tdat = tds;
      this.state.pdf = 0;
      this.state.pdf_content = null;
      this.state.loading = 0;

      this.selrowitems = [
          {fkn: 'edit', text: 'ÖPPNA'},
          {fkn: 'remove', text: 'TABORT'}
      ];
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.ee_per();
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    th_style(id) {
        var css = {};
        css.cursor = "pointer";
        if(id == this.state.sortid) {
            if(this.state.sortdir) css.color = "#800";
            else css.color = "#080";
        }
        return css;
    }
    row_style(row) {
        var css = {};
        if(row.antalopen != "0") {
            css.background = "#fff";
            css.color = "#800";
        }
        //css.cursor = "pointer";
        return css;
    }
    tab_style(tabid) {
      var css = {};
      css.display = "none";
      if(this.state.tabval === tabid) css.display = "block";
      return css;
    }
    tab_head(tabid) {
      var css = {};
      css.background = "#fff";
      css.color = "#000";
      if(this.state.tabval === tabid) {
          css.background = "#080";
          css.color = "#fff";
      }
      return css;
    }
    dt_fdat(e) {
        //alert(JSON.stringify(e));
        var dts = format(e, "yyyy-MM-dd");
        if(dts > this.state.tdat) {
            //this.setState({ fdat: dts, tdat: dts });
            var tdat = addDays(e, 15);
            var tds = format(tdat, "yyyy-MM-dd");
            this.dt_td.current.updateDt(tds);
        }
        this.setState({ fdat: dts });
    }
    dt_tdat(e) {
        //alert(JSON.stringify(e));
        var dts = format(e, "yyyy-MM-dd");
        this.setState({ tdat: dts });
    }
    oneday(e) {
        //alert(JSON.stringify(e));
        //this.setState({ tdat: this.state.fdat });
        this.dt_td.current.updateDt(this.state.fdat);
    }
    addday(e) {
        //alert(JSON.stringify(e));
        //this.setState({ tdat: this.state.fdat });
        this.dt_fd.current.updateAdd();
        this.dt_td.current.updateAdd();
    }
    subday(e) {
        //alert(JSON.stringify(e));
        //this.setState({ tdat: this.state.fdat });
        this.dt_fd.current.updateSub();
        this.dt_td.current.updateSub();
    }
    row_edit(row) {
        var self = this;
        var wnds = {};
        wnds.dtedit = 1;
        wnds.datum = row.datum;
        wnds.dto = row;
        wnds.eeo = this.state.eeo;
        wnds.cb = this.cb_dtedit;
        this.setState({wnds: wnds});
    }

    cb_dtedit(ctx) {
        ctx.ee_per();
        ctx.setState({ wnds: {} });
    }

    wnds_done(ctx, rsp) {
        if(rsp.wnd == "lredit") {
          if(rsp.status == "removed") {
                ctx.setState({wnds: {}});
		        ctx.props.ctx.wnds_done(ctx.props.ctx, rsp);
		        return;
          }
        }
        ctx.lr_lsa();
        ctx.setState({wnds: {}});
    }
    pdf_done (ret) {
        this.setState({loading: 0, pdf: 0, pdf_content: null });
    }

    ee_prn() {
        var self = this;

        //this.setState({ loading: 1 });

        var prm = {};
        prm.req = "ta.ta_rp.rp_tp";
        prm.fdat = this.state.fdat;
        prm.tdat = this.state.tdat;
        prm.enr = this.state.eeo.anstnummer;
        //alert(JSON.stringify(prm));
        //return;

        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret.ok));

            //var uri = "data:application/pdf;base64," + ret.pdf;
            self.setState({pdf: 1, pdf_content: ret.pdf });
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }

    ee_per() {
        var self = this;
        var prm = {};
     	prm.req = "ta.ta_th.enr_ft";
        prm.enr = this.state.eeo.anstnummer;
        prm.fdat = this.state.fdat;
        prm.tdat = this.state.tdat;
        //alert(JSON.stringify(prm));

        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret.rca));

            self.setState({ dta: ret.rca, tot: ret.tot }, function() {
                self.syncTb("eeperTable");
            });
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }

    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    wnd_close = () => {
		this.props.ctx.setState({ wnds: {} });
	};

  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
    syncTb(tb) {
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
            tdElements = tbel.getElementsByTagName('td');
        if(tdElements.length < 1 ) return;

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
                width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
    tb_sort(typ) {
        var self = this;
        //var rvs = self.state.rvs;
        var tha = self.state.tha;
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;

        if(sortid != typ) sortdir = 0;
        else {
            if(sortdir) sortdir = 0;
            else sortdir = 1;
        }

        if(typ) {
            if(sortdir) tha.sort((a,b) => (a[typ] < b[typ]) ? 1 : ((b[typ] < a[typ]) ? -1 : 0));
            else tha.sort((a,b) => (a[typ] > b[typ]) ? 1 : ((b[typ] > a[typ]) ? -1 : 0));
        }
        self.setState({ tha: tha, sortid: typ, sortdir: sortdir });
    }
    th_icon(typ) {
        var cls = "";
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;
        if(sortid == typ)
        {
            if(sortdir) cls = "fa fa-caret-up";
            else cls = "fa fa-caret-down";
        }
        return cls;
    }
    show_wday(wday) {
        var dtxt = "";

        if(wday == 1) dtxt = "Mån";
        if(wday == 2) dtxt = "Tis";
        if(wday == 3) dtxt = "Ons";
        if(wday == 4) dtxt = "Tor";
        if(wday == 5) dtxt = "Fre";
        if(wday == 6) dtxt = "Lör";
        if(wday == 7) dtxt = "Sön";

        return dtxt;
    }
    sel_row_fkn(row, sel) {
        //alert(JSON.stringify(fkn));
        if(sel.fkn == "edit") {
        }
        if(sel.fkn == "remove") {
            this.tr_del(row);
        }
    }
    start_save(row, key, e) {
        var self = this;

        var rtid = e;
        var tpo = null;
        for(var o of row.tha) {
            if(o.anledning == "0001") {
                var tid = o.tidpunkt.substr(6, 4);
                if(tid == row.start) {
                    tpo = o;
                }
            }
        }
        //alert(JSON.stringify(tro));
        if(tpo == null) {
            return;
        }

        var prm = {};
        prm.req = "ta.ta_th.ee_tr_upd";
        prm.tpo = tpo;
        prm.rtid = rtid;
        //prm.enr = this.state.eeo.anstnummer;

        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret));

            if(ret.ok != "000") {
                return;
            }

            var dta = self.state.dta;
            tpo.start = rtid;
            dta[key].start = rtid;
            //var aa = Object.values(ao);
            self.setState({ dta: dta });
            self.syncTb("dtsTable");
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }
    slut_save(row, key, e) {
        var self = this;

        var rtid = e;
        var tpo = null;
        for(var o of row.tha) {
            if(o.anledning == "0002") {
                var tid = o.tidpunkt.substr(6, 4);
                if(tid == row.slut) {
                    tpo = o;
                }
            }
        }
        //alert(JSON.stringify(tro));
        if(tpo == null) {
            return;
        }

        var prm = {};
        prm.req = "ta.ta_th.ee_tr_upd";
        prm.tpo = tpo;
        prm.rtid = rtid;
        //prm.enr = this.state.eeo.anstnummer;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret));

                if(ret.ok != "000") {
                    return;
                }

                var dta = self.state.dta;
                tpo.slut = rtid;
                dta[key].slut = rtid;
                //var aa = Object.values(ao);
                self.setState({ dta: dta });
                self.syncTb("dtsTable");
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }

  render() {
    ///if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "Period";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="mx_backdrop" /> : null }
        <div className="mx_dlg mx_100 flex-col" style={ this.dlg_style() }>
        <div className="mx_h01">
            <div>AVDELNING PERIOD</div>
            <div className="flex"></div>
            <div className="web-x-icon" onClick={() => this.wnd_close() }>X</div>
        </div>
        <div className="mx_content px-3 flex">
            <div className="flex-row">
                    <div className="flex-col">
                        <div>Avdelning:</div>
                    </div>
                <div className="flex"></div>
                <div className="flex-row">
                    <div className="dt-btn-01" onClick={() => this.subday() } ><FontAwesomeIcon icon="minus" size="1x" /></div>
                    <Dt_Cal dts={ this.state.fdat } rp="1" ref={this.dt_fd} getValue={(e)=> this.dt_fdat(e)} />
                    <div className="dt-btn-01" onClick={() => this.oneday() } >1</div>
                    <Dt_Cal dts={ this.state.tdat } rp="1" ref={this.dt_td} getValue={(e)=> this.dt_tdat(e)} />
                    <div className="dt-btn-01" onClick={() => this.addday() } ><FontAwesomeIcon icon="plus" size="1x" /></div>
                </div>
                <div className="flex"></div>
                <button className="btn btn-hw-blue w150" onClick={() => this.ee_per() } aria-label="System" >
                    UPPDATERA
                </button>
           </div>
            <div className="divider_green"></div>

            <div id="eeperTable" className="flex-col">
                <table className="table table-bordered table-hover tb " style={{marginBottom: "0px"}}>
                    <thead>
                    <tr>
                        <th style={ this.th_style("datum") } onClick={() => this.tb_sort("datum") }>Anstnr <i className={ this.th_icon("datum") }></i></th>
                        <th style={ this.th_style("open") } onClick={() => this.tb_sort("open") }>Ö <i className={ this.th_icon("open") }></i></th>
                        <th style={ this.th_style("wday") } onClick={() => this.tb_sort("wday") }>Dag <i className={ this.th_icon("wday") }></i></th>
                        <th style={ this.th_style("avd") } onClick={() => this.tb_sort("avd") }>Avd <i className={ this.th_icon("avd") }></i></th>
                        <th style={ this.th_style("start") } onClick={() => this.tb_sort("start") }>Start <i className={ this.th_icon("start") }></i></th>
                        <th style={ this.th_style("slut") } onClick={() => this.tb_sort("slut") }>Slut <i className={ this.th_icon("slut") }></i></th>
                        <th style={ this.th_style("rtid") } onClick={() => this.tb_sort("rtid") }>Reg Tid <i className={ this.th_icon("rtid") }></i></th>
                        <th style={ this.th_style("ob1") } onClick={() => this.tb_sort("ob1") }>OB1 <i className={ this.th_icon("ob1") }></i></th>
                        <th style={ this.th_style("ob2") } onClick={() => this.tb_sort("ob2") }>OB2 <i className={ this.th_icon("ob2") }></i></th>
                        <th style={ this.th_style("rast") } onClick={() => this.tb_sort("rast") }>Rast <i className={ this.th_icon("rast") }></i></th>
                        <th style={ this.th_style("lunch") } onClick={() => this.tb_sort("lunch") }>Lunch <i className={ this.th_icon("lunch") }></i></th>
                        <th style={ this.th_style("schematid") } onClick={() => this.tb_sort("schematid") }>Schematid <i className={ this.th_icon("schematid") }></i></th>
                        <th style={ this.th_style("vstart") } onClick={() => this.tb_sort("vstart") }>VStart <i className={ this.th_icon("vstart") }></i></th>
                        <th style={ this.th_style("vslut") } onClick={() => this.tb_sort("vslut") }>VSlut <i className={ this.th_icon("vslut") }></i></th>
                        <th style={ this.th_style("vtid") } onClick={() => this.tb_sort("vtid") }>VTid <i className={ this.th_icon("vtid") }></i></th>
                        <th style={ this.th_style("djust") } onClick={() => this.tb_sort("djust") }>Dagjust <i className={ this.th_icon("djust") }></i></th>
                        <th style={ this.th_style("diff") } onClick={() => this.tb_sort("diff") }>Diff <i className={ this.th_icon("diff") }></i></th>
                        <th style={ this.th_style("sign") } onClick={() => this.tb_sort("sign") }>Sign <i className={ this.th_icon("sign") }></i></th>
                        <th></th>
                    </tr>
                    </thead>
                </table>
                <div className="tb-cdi" style={ this.tb_style() }>
                    <table className="table table-bordered table-hover tb">
                        <tbody>
                        { this.state.dta.map((row, key) =>
                            <tr key={key} onDoubleClick={() => this.row_edit(row) } style={ this.row_style(row) }>
                                <td>{ utils.toDate( row.datum ) }</td>
                                <td className="text-center">{ utils.toLang( row.antalopen ) }</td>
                                <td className="text-center">{ this.show_wday( row.wday ) }</td>
                                <td className="text-center">{ row.avdelning }</td>
                                <td className="text-center"><Edit_Txt text={ row.start.cditid() } getValue={(e)=> this.start_save(row, key, e) } /></td>
                                <td className="text-center"><Edit_Txt text={ row.slut.cditid() } getValue={(e)=> this.slut_save(row, key, e) } /></td>
                                <td className="text-center">{ utils.toMinTid( row.rtid ) }</td>
                                <td className="text-center">{ utils.toMinTid( row.ob1 ) }</td>
                                <td className="text-center">{ utils.toMinTid( row.ob2 ) }</td>
                                <td className="text-center">{ utils.toMinTid( row.rast ) }</td>
                                <td className="text-center">{ row.lunch }</td>
                                <td className="text-center">{ utils.toMinTid( row.schematid ) }</td>
                                <td className="text-center">{ utils.toTid( row.vstart ) }</td>
                                <td className="text-center">{ utils.toTid( row.vslut ) }</td>
                                <td className="text-center">{ utils.toMinTid( row.vtid ) }</td>
                                <td className="text-center">{ row.djust }</td>
                                <td className="text-center">{ row.diff }</td>
                                <td className="text-center">{ row.oksign }</td>
                                <td>
                                    <Td_Sel items={ this.selrowitems } getValue={(e)=> this.sel_row_fkn(row, e)} />
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
            </div>
            </div>

        </div>
        <div className="divider_green"></div>
        <div className="flex-row px-3 pb-1">
                <div className="flex-col">
                    <div className="btn_link" onClick={() => this.ee_th_add() }>LÄGG TILL TID</div>
                </div>
                <div className="flex"></div>
                <div className="flex-col">
                    <div className="btn_link" onClick={() => this.ee_per() }>UPPDATERA</div>
                </div>
         </div>
        <div className="mx_foot flex-row flex-space-between">
            <div className="flex-row">
            <button className="btn btn-hw-green w250" onClick={(event) => this.ee_prn(event) } >
                SKRIV UT UNDERLAG
            </button>
            </div>
            <button className="btn btn-hw-green w250" onClick={(event) => this.ee_edit(event) } >
                ÖPPNA PERSONALKORT
            </button>
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
        </div>
      </div>
        <PdfRpDlg show={this.state.pdf} pdf={this.state.pdf_content} done={(ret) => this.pdf_done(ret)} backdrop={true}/>
    </div>
	;
	return html;
  };
}

export default Dep_Per;