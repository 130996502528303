import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";
import Td_Sel from "../../lib/ui/td_sel";
import Dd_Sel from "../../lib/ui/dd_sel";

import Profil_Add from "./profil/profil_add";
import Profil_Edit from "./profil/profil_edit";

class PMS_Profil extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = {};
    this.state.seldate = new Date();
    this.state.avd = "01";
    this.state.pfs = [];
    this.state.pfa = [];
    this.state.pfo = {};
    this.state.ta = [
                {id: '01', text: 'FÖRETAG'},
                {id: '02', text: 'AVDELNING'},
                {id: '03', text: 'BOKARE'},
                {id: '04', text: 'GÄSTER'},
                {id: '05', text: 'PRIVAT KUNDER'}
            ];
    this.state.wnds = {};
    this.state.sz_val = "";
    this.state.sortid = "";
    this.state.sortdir = 1;

    this.selitems = [
        { fkn: 'pfofromrvs', text: 'Profil Från Bokningar' },
        { fkn: 'verify', text: 'Verifiera' }
    ];
    }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //alert("KID");
    this.profils();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      css.overflow = "auto";
      return css;
  }
  sz_style() {
    var css = {};
    css.height = "40px";
    css.lineHeight = "40px";
    css.padding = "0px 10px";
    css.fontSize = "12px";
    css.cursor = "pointer";
    return css;
}
onUnitChange(e) {
        this.setState({unit: e.value});
  }

  profil_edit(row) {
    var self = this;
    //alert(JSON.stringify(row));
    var wnds = {};
    wnds.profiledit = 1;
    wnds.pfo = row;
    this.setState({ wnds: wnds });
  }
  profil_add() {
    var self = this;
    //alert(JSON.stringify(row));
    var wnds = {};
    wnds.profiladd = 1;
    wnds.pfo = {};
    this.setState({ wnds: wnds });
  }

  profils() {
    var self = this;
    var prm = {};
    prm.req = "pms.profil.profils";
    prm.sz = "";
    //prm.avd = this.state.avd;
    //alert(JSON.stringify(prm));

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        self.setState({ pfa: ret.rca, pfs: ret.rca }, function () {
            self.syncTb("pfaTable");
        });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e) {
        var txt = JSON.stringify(e);
    });
  }
  syncTb(tb){
    if (!document.getElementById(tb)) {
        //alert("No tb");
        return;
    }
    var tbel = document.getElementById(tb);
    const thElements = tbel.getElementsByTagName('th'),
    tdElements = tbel.getElementsByTagName('td');

    for (let i = 0; i < thElements.length; i++) {
        if(!tdElements[i]) continue;
        const widerElement =
                thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
        width = window.getComputedStyle(widerElement).width;
        thElements[i].style.width = tdElements[i].style.width = width;
    }
}
pfofromrvs() {
    var self = this;
    var prm = {};
    prm.req = "pms.profil.profil_from_rvs";
    prm.sz = "";
    //prm.avd = this.state.avd;
    //alert(JSON.stringify(prm));

    net.sio_req(prm)
    .then(function(ret){
        alert(JSON.stringify(ret));

        //toastr.success("OK", "SAPI");
    })
    .catch(function(e) {
        var txt = JSON.stringify(e);
    });
  }

sel_row_fkn(sel, e) {
    //alert(JSON.stringify(fkn));
      if(sel.fkn == "logout") {
      }
  }
  lists() {
  }
  sel_fkn(sel) {
    //alert(JSON.stringify(fkn));
    if(sel.fkn == "verify") {
        //this.krub_verify();
    }
    if(sel.fkn == "pfofromrvs") {
        this.pfofromrvs();
    }
}
sz_keydown (e) {
    //e.preventDefault();
      const key = e.key;
      const keyCode = e.keyCode;

      if(keyCode == 13) {
          this.tb_filtr();
      };
      if(keyCode == 27) {
          this.setState({sz_val: ""});
      };
  }
  sz_change (e) {
      const name = e.target.name;
      const value = e.target.value;
      this.setState({sz_val: value});
      //setState({[name]: value});
  }
  tb_filtr() {
    var self = this;
    var pfs = self.state.pfs;
    var sz = this.state.sz_val;

    if(sz.length < 1) {
      self.setState({ pfa: pfs });
        return;
    }
    sz = sz.toUpperCase();
    var pfa = pfs.filter(function(row) {
          var brc = false;
          if(row.namn.toUpperCase().includes(sz)) brc = true;
          if(row.ftg.toUpperCase().includes(sz)) brc = true;
          if(row.tele.toUpperCase().includes(sz)) brc = true;
          return brc;
    });
    self.setState({ pfa: pfa });
}
tb_sort(typ) {
    var self = this;
    //var pfs = self.state.pfs;
    var pfa = self.state.pfa;
    var sortid = this.state.sortid;
    var sortdir = this.state.sortdir;

    if(sortid != typ) sortdir = 0;
    else {
      if(sortdir) sortdir = 0;
      else sortdir = 1;
    }

    if(sortdir) pfa.sort((a,b) => (a[typ] > b[typ]) ? 1 : ((b[typ] > a[typ]) ? -1 : 0));
    else pfa.sort((a,b) => (a[typ] < b[typ]) ? 1 : ((b[typ] < a[typ]) ? -1 : 0));
    self.setState({ pfa: pfa, sortid: typ, sortdir: sortdir });
 }

  render() {
    return (
    <div className="web-app">

    <div className="flex-row">
    <button className="btn btn-hw-blue w150" onClick={() => this.profil_add() } aria-label="System" >
            LÄGG TILL
        </button>
        <div className="flex"></div>
        <input className="txt sztxt" value={this.state.sz_val} placeholder="Ange söktext..."  style={ this.sz_style() } onChange={(e) => this.sz_change(e)} onKeyDown={(e) => this.sz_keydown(e)} />
        <div className="flex"></div>
        <button className="btn btn-hw-blue w150 hide" onClick={() => this.lists() } aria-label="System" >
            LISTOR
        </button>
        <button className="btn btn-hw-blue w150" onClick={() => this.profils() } aria-label="System" >
            UPPDATERA
        </button>
        <Dd_Sel items={ this.selitems } cls="web-x-icon" getValue={(e)=> this.sel_fkn(e)} />
    </div>
    <div className="divider_orange"></div>

    <div className="web-col">
        <div id="pfaTable">
        <table className="table table-bordered table-hover tb" style={{ marginBottom: "0px" }}>
            <thead>
            <tr>
                <th onClick={() => this.tb_sort("id") }>ID</th>
                <th onClick={() => this.tb_sort("namn") }>Namn</th>
                <th onClick={() => this.tb_sort("ftg") }>Företag</th>
                <th onClick={() => this.tb_sort("tele") }>Telefon</th>
                <th onClick={() => this.tb_sort("nrb") }>Besök</th>
                <th></th>
            </tr>
            </thead>
            </table>
            <div style={ this.tb_style() }>
          <table className="table table-bordered table-hover">
            <tbody>
            { this.state.pfa.map((row, key) =>
            <tr key={key} onDoubleClick={() => this.profil_edit(row) }>
                <td>{ row.id }</td>
                <td>{ row.namn }</td>
                <td>{ row.ftg }</td>
                <td>{ row.tele }</td>
                <td>{ row.pfb.nrb }</td>
                <td>
                    <Td_Sel items={ this.selitems } getValue={(e)=> this.sel_row_fkn(row, e)} />
                </td>
            </tr>
            )}
            </tbody>
        </table>
        </div>
        </div>
    </div>
    { this.state.wnds.profiledit ? <Profil_Edit wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
    { this.state.wnds.profiladd ? <Profil_Add wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
   </div>
  );
    }
}
export default PMS_Profil;
