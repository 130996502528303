import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../../lib/net";
import * as utils from "../../../assets/utils";

import Fr_Input from "../../../lib/ui/fr_input";
import Wnd_Error from "../../../lib/ui/wnd_error";
import Wnd_Ok from "../../../lib/ui/wnd_ok";
import Wnd_Info from "../../../lib/ui/wnd_info";
import Wnd_Confirm from "../../../lib/ui/wnd_confirm";
import Fr_Cal from "../../../lib/ui/fr_cal";
import Lang from "../../../lib/lang";
import Td_Sel from "../../../lib/ui/td_sel";
import ddo from "../../../ddo";

function TabPanel(props) {
    const { children, value, index, ctx, ...other } = props;
    function tab_change(val) {
        //alert(newValue);
        ctx.setState({ix: val});
    };
    function tab_style(val) {
        var css = {};
        css.height = "30px";
        css.lineHeight = "30px";
        css.background = "#fff";
        css.borderBottom = "1px #ccc solid";
        css.color = "#ccc";
        if(val == ctx.state.ix) {
            css.color = "#080";
            css.borderBottom = "2px #080 solid";
        }
        return css;
    }

    return (
        <div
            className="flex ripple text-center" onClick={() => tab_change(index)} style={ tab_style(index) }
            {...other}
        >
            {children}
        </div>
    );
}

class Prd_Mp extends React.Component {
  constructor(props, context) {
    super(props);

    ///this.pos = props.store.pos;

    this.state = {};
      this.state.mpa = [];
      this.state.moa = [];
      this.state.sz_val = "";
      this.state.sortid = "";
      this.state.sortdir = "";

    this.state.gnr = "001";
    this.state.ynq = [
                {id: '0', text: 'NEJ'},
                {id: '1', text: 'JA'}
            ];
      this.state.mtval = [
          {id: 'PLU', text: 'PRODUKT'},
          {id: 'INFO', text: 'INFORMATION'}
      ];

      this.state.ix = 0;
      this.state.selrow = {};
      this.state.msg = {};
      this.state.cols = {};
      this.state.mnuid = "";
      this.state.mpo = {};
      //if(props.wnds.mno) this.state.mno = props.wnds.mno;
      if(props.wnds.mnuid) this.state.mnuid = props.wnds.mnuid;
      this.state.paa = props.store.pos.plus;
      this.state.poa = props.store.pos.plus;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      if(!this.props.wnds.mpo) this.mpo_init();
      if(this.state.paa.length) this.syncTb("pluSz");
      else this.plus();
  }

    mpo_init() {
        var mpo = {};
        mpo.typ = "P";
        mpo.mnuid = this.state.mnuid;
        mpo.plu = "";
        mpo.text = "";
        mpo.prdtyp = "";
        mpo.sort = "";
        mpo.status = "";
        //alert(JSON.stringify(mno));
        this.setState({ mpo: mpo });
        return;
    }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    tb_style() {
        var css = {};
        css.height = (window.innerHeight * 0.8) - 175;
        css.overflow = "auto";
        return css;
    }
    th_style(id) {
        var css = {};
        css.cursor = "pointer";
        if(id == this.state.sortid) {
            if(this.state.sortdir) css.color = "#800";
            else css.color = "#080";
        }
        return css;
    }
    row_style(row) {
        var srow = this.state.selrow;

        var css = {};
        if(srow == row) {
            css.background = "#080";
            css.color = "#fff";
        }
        return css;
    }

    wnd_close = () => {
        if(this.props.wnds.cb) {
            this.props.wnds.cb(this.props.ctx);
        }
        else this.props.ctx.setState({ wnds: {} });
    };
    mt_change (e) {
        const value = e.target.value;
        var mpo = this.state.mpo;
        mpo.prdtyp = value;
        this.setState({mpo: mpo});
    }
    anm_change (e) {
        const value = e.target.value;
        var mpo = this.state.mpo;
        mpo.anm = value;
        this.setState({mpo: mpo});
    }

    val_save(id, val) {
        var mpo = this.state.mpo;
        mpo[id] = val;
        this.setState({ mpo: mpo });
        return;
    }
    sel_row(row) {
        var srow = this.state.selrow;
        if(srow == row) {
            this.setState({selrow: {}});
        }
        else this.setState({selrow: row});
    }
    sel_row_fkn(row, sel) {
        if(sel.fkn == "open") {
        }
    }
    plus() {
        var self = this;

        var prm = {};
        prm.req = "pos.pos_plu.plus";
        //prm.avd = this.state.avd;
        prm.avd = ddo.cache.avd;
        //alert(JSON.stringify(prm));

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret));

                self.props.store.pos.plus = ret.rco;
                var paa = ret.rco;
                self.setState({ paa: paa, poa: paa }, function() {
                    self.syncTb("pluSz");
                });
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    mnu_mp() {
        var self = this;
        var prm = {};
        prm.req = "dsi.dsi_mp.mp_mnu";
        prm.mnuid = this.state.mpo.mnuid;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.rca));

                self.setState({ mpa: ret.rca, moa: ret.rca }, function() {
                    self.syncTb("mpaTable");
                });
                //self.setState({ rva: ret.rca });
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    syncTb(tb) {
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
            tdElements = tbel.getElementsByTagName('td');
        if(tdElements.length < 1 ) return;

        for (let i = 0; i < thElements.length; i++) {
            if(!tdElements[i]) continue;
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
                width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
    tb_sort(typ) {
        var self = this;
        //var rvs = self.state.rvs;
        var paa = self.state.paa;
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;

        if(sortid != typ) sortdir = 0;
        else {
            if(sortdir) sortdir = 0;
            else sortdir = 1;
        }

        if(typ == "plu") {
            if(sortdir) paa.sort((a,b) => (a.plu00.p0katnr < b.plu00.p0katnr) ? 1 : ((b.plu00.p0katnr < a.plu00.p0katnr) ? -1 : 0));
            else paa.sort((a,b) => (a.plu00.p0katnr > b.plu00.p0katnr) ? 1 : ((b.plu00.p0katnr > a.plu00.p0katnr) ? -1 : 0));
        }
        if(typ == "vgrp") {
            if(sortdir) paa.sort((a,b) => (a.plu02.p2vgrupp < b.plu02.p2vgrupp) ? 1 : ((b.plu02.p2vgrupp < a.plu02.p2vgrupp) ? -1 : 0));
            else paa.sort((a,b) => (a.plu02.p2vgrupp > b.plu02.p2vgrupp) ? 1 : ((b.plu02.p2vgrupp > a.plu02.p2vgrupp) ? -1 : 0));
        }
        if(typ == "text") {
            if(sortdir) paa.sort((a,b) => (a.plu00.p0namn < b.plu00.p0namn) ? 1 : ((b.plu00.p0namn < a.plu00.p0namn) ? -1 : 0));
            else paa.sort((a,b) => (a.plu00.p0namn > b.plu00.p0namn) ? 1 : ((b.plu00.p0namn > a.plu00.p0namn) ? -1 : 0));
        }
        if(typ == "pris") {
            if(sortdir) paa.sort((a,b) => (a.plu00.p0pris < b.plu00.p0pris) ? 1 : ((b.plu00.p0pris < a.plu00.p0pris) ? -1 : 0));
            else paa.sort((a,b) => (a.plu00.p0pris > b.plu00.p0pris) ? 1 : ((b.plu00.p0pris > a.plu00.p0pris) ? -1 : 0));
        }
        self.setState({ mpa: paa, sortid: typ, sortdir: sortdir });
    }
    th_icon(typ) {
        var cls = "";
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;
        if(sortid == typ)
        {
            if(sortdir) cls = "fa fa-caret-up";
            else cls = "fa fa-caret-down";
        }
        return cls;
    }
    prd_add(row) {
        var self = this;
        var mpo = this.state.mpo;
        mpo.plu = row.plu00.p0kstns + row.plu00.p0katnr;
        mpo.text = row.plu00.p0namn;

        var prm = {};
        prm.req = "dsi.dsi_mp.mp_add";
        prm.mpo = this.state.mpo;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret));
                if(ret.ok == "000") {
                    self.setState({ msg: {ok: true, text: "Sparad ..."} });
                    setTimeout(function(){
                        self.setState({msg: {} });
                        /*
                        if(self.props.wnds.cb) {
                            self.props.wnds.cb(self.props.ctx);
                        }
                        else self.props.ctx.setState({ wnds: {} });
                        */
                        return;
                    }, 1000);
                }
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
  prd_del() {
    var self = this;
    var prm = {};
    prm.req = "dsi.dsi_mp.mp_del";
    prm.mpo = this.state.mpo;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));
        if(ret.ok == "000") {
            self.setState({ msg: {ok: true, text: "Borttagen"} });
            setTimeout(function(){
                self.setState({msg: {} });
                  if(self.props.close) {
                      self.props.close(self.props.ctx);
                  }
                  else self.props.ctx.setState({ wnds: {} });
                return;
            }, 1000);
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
    prd_sz() {
        var self = this;
        var poa = self.state.poa;
        var sz = this.state.sz_val;

        var paa = poa.filter(row => row.plu00.p0namn.toUpperCase().includes(sz.toUpperCase()));
        self.setState({paa: paa });
    }
    prd_filtr() {
        var self = this;
        var poa = self.state.poa;
        var vgrp = this.state.vgrp;

        if(vgrp == "000") {
            self.setState({paa: poa });
            return;
        }

        var paa = poa.filter(row => row.plu02.p2vgrupp == vgrp);
        self.setState({paa: paa });
    }
    sz_keydown (e) {
        //e.preventDefault();
        const key = e.key;
        const keyCode = e.keyCode;

        if(keyCode == 13) {
            this.prd_sz();
        };
        if(keyCode == 27) {
            this.setState({sz_val: ""});
        };
    }
    sz_change (e) {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({sz_val: value});
        //setState({[name]: value});
    }

  render() {
    //if(!this.props.show) return "";
    var title = this.props.title;
    if(!title) title = "Resurs";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        <div className="m2_backdrop" />
        <div className="m2_dlg mx_90 flex-col" style={ this.dlg_style() } >
        <div className="mx_content flex">

        <div className="mx_h01">
            <div>KOPPLA PRODUKT</div>
        </div>
            <div className="divider_orange"></div>

            <form className="cdi_f03">
                <div className="flex-row">
                    <Fr_Input label="Meny ID" className="text-left w150" text={this.state.mpo.mnuid} getValue={(e)=> this.val_save("mnuid", e) } />
                </div>
                <div className="flex-row">
                    <div className="form-group flex-row flex">
                        <span className="header">Menytyp</span>
                        <select value={this.state.mpo.prdtyp} className="form-control w250" onChange={(e) => this.mt_change(e)}>
                            { this.state.mtval.map((row, key) =>
                                <option key={key} value={row.id}>{row.text}</option>
                            )}
                        </select>
                    </div>
                    <input className="txt sztxt" value={this.state.sz_val} onChange={(e) => this.sz_change(e)} onKeyDown={(e) => this.sz_keydown(e)} />
                </div>
            </form>
            <div className="divider_green"></div>
            <div id="pluSz" className="flex">
                <table className="table table-bordered table-hover tb" style={{marginBottom:"0px"}}>
                    <thead>
                    <tr>
                        <th style={ this.th_style("plu") } onClick={() => this.tb_sort("plu") }>PLU</th>
                        <th style={ this.th_style("vgrp") } onClick={() => this.tb_sort("vgrp") }>Varugrupp</th>
                        <th style={ this.th_style("text") } onClick={() => this.tb_sort("text") }>Text</th>
                        <th style={ this.th_style("pris") } onClick={() => this.tb_sort("pris") }>Pris</th>
                        <th>Moms</th>
                        <th>Sort</th>
                        <th>Uppdat</th>
                        <th></th>
                    </tr>
                    </thead>
                </table>
                <div style={ this.tb_style() }>
                    <table className="table table-bordered table-hover tb" style={{marginBottom:"0px"}}>
                        <tbody>
                        { this.state.paa.map((row, key) =>
                            <tr key={key} onDoubleClick={() => this.prd_add(row) }>
                                <td>{ row.plu00.p0katnr }</td>
                                <td>{ row.plu02.p2vgrupp }</td>
                                <td>{ row.plu00.p0namn.toswe() }</td>
                                <td className="text-right">{ row.plu00.p0pris.formatpris() }</td>
                                <td>{ row.plu00.p0moms }</td>
                                <td>{ row.plu00.p0sort }</td>
                                <td>{ row.plu00.p0upddat }</td>
                                <td>
                                    <Td_Sel items={ this.selitems } getValue={(e)=> this.sel_row_fkn(row, e)} />
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
        <div className="mx_foot flex-row">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex"></div>
            <button className="btn btn-hw-red w250 hide" onClick={() => this.prd_add() } >
                SPARA
            </button>
        </div>
      </div>

        <Wnd_Error msg={this.state.msg} ctx={this} />
        <Wnd_Ok msg={this.state.msg} ctx={this} />
        <Wnd_Info msg={this.state.msg} ctx={this} />
        <Wnd_Confirm msg={this.state.msg} ctx={this} />
      </div>
	;
	return html;
  };
}

export default Prd_Mp;