import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../../lib/net";
import * as utils from "../../../assets/utils";

import Fr_Input from "../../../lib/ui/fr_input";
import Wnd_Error from "../../../lib/ui/wnd_error";
import Wnd_Ok from "../../../lib/ui/wnd_ok";
import Wnd_Info from "../../../lib/ui/wnd_info";
import Wnd_Confirm from "../../../lib/ui/wnd_confirm";
import Fr_Cal from "../../../lib/ui/fr_cal";
import Td_Sel from "../../../lib/ui/td_sel";

class Sz_Kid extends React.Component {
  constructor(props, context) {
    super(props);

    this.pos = props.store.pos;
    this.state = {};
    this.state.mka = [];
    this.state.sza = [];

    this.state.gnr = "001";
    //this.state.lro = props.lro;
    this.state.ynq = [
                {id: '0', text: 'NEJ'},
                {id: '1', text: 'JA'}
            ];

      this.state.msg = {};
      this.state.cols = {};
    this.state.tdo = {};
    if(props.wnds.tdo) this.state.tdo = props.wnds.tdo;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      this.kreg();
  }

    task_init() {
        var tdo = {};
        tdo.typ = "";
        tdo.id = "";
        tdo.text = "";
        tdo.reg = "";
        tdo.status = "";
        //alert(JSON.stringify(rso));
        this.setState({ tdo: tdo });
        return;
    }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    tb_style() {
        var css = {};
        css.height = ( window.innerHeight * 0.8 ) - 225;
        css.overflow = "auto";
        return css;
    }
    th_style(id) {
        var css = {};
        css.cursor = "pointer";
        if(id == this.state.sortid) css.color = "#080";
        return css;
    }

    kid_sel(row) {
        if(this.props.wnds.cb) {
            var rsp = {};
            rsp.ok = "000";
            rsp.kko = row;
            this.props.wnds.cb(this.props.ctx, rsp);
        }
        else this.props.ctx.setState({ wnds: {} });
    }

    wnd_close = () => {
        if(this.props.close) {
            this.props.close(this.props.ctx);
        }
        else this.props.ctx.setState({ wnds: {} });
    };
    sz_sel = () => {
        if(this.props.close) {
            this.props.close(this.props.ctx);
        }
        else this.props.ctx.setState({ wnds: {} });
    };
    tdata_change (e) {
        const value = e.target.value;
        var tdo = this.state.tdo;
        tdo.tdata = value;
        this.setState({tdo: tdo});
    }

    val_dt_save(val) {
        var tdo = this.state.tdo;
        tdo.due = val;
        this.setState({ tdo: tdo });
        return;
    }
    val_save(id, val) {
        var tdo = this.state.tdo;
        tdo[id] = val;
        this.setState({ tdo: tdo });
        return;
    }
    kid_edit() {
        var self = this;
        var wnds = {};
        wnds.szkid = true;
        wnds.tdo = this.state.tdo;
        wnds.cb = self.cb_kid;
        this.setState({ wnds: wnds });
    }
    cb_kid(ctx) {
        ctx.setState({ wnds: {} });
        //ctx.tasks();
    }
    kreg() {
        var self = this;
        self.setState({typ: "trs", sza: [] });

        var prm = {};
        prm.req = "bcs.kreg.kks";
        prm.typ = "K";

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.rca));

                self.setState({sza: ret.rca });
                self.syncTb("szkTable");
            })
            .catch(function(e) {
                var txt = JSON.stringify(e);
                //gda.wndError(txt);
            });
    }
    syncTb(tb) {
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
            tdElements = tbel.getElementsByTagName('td');
        if(tdElements.length < 1 ) return;

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
                width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
    tb_sort(typ) {
        var self = this;
        //var rvs = self.state.rvs;
        var tra = self.state.tra;
        var sz = this.state.sz_val;

        tra.sort((a,b) => (a[typ] > b[typ]) ? 1 : ((b[typ] > a[typ]) ? -1 : 0));
        self.setState({ tra: tra, sortid: typ });
    }
    sel_row_fkn(row, sel) {
        //alert(JSON.stringify(fkn));
        if(sel.fkn == "open") {
            //this.sel_gst(row);
        }
        if(sel.fkn == "br") {
        }
    }

  render() {
    //if(!this.props.show) return "";
    var title = this.props.title;
    if(!title) title = "Resurs";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        <div className="m2_backdrop" />
        <div className="m2_dlg mx_70h flex-col" style={ this.dlg_style() } draggable="true" >
        <div className="mx_content flex">

        <div className="mx_h01">
            <div>KUNDER : { utils.toNum( this.state.tdo.id ) }</div>
        </div>
            <div className="flex-row" style={{padding:"5px",height:"35px"}}>
                    <div className="flex-col flex">
                    </div>
                    <div className="flex-col">
                        <div>UPPDATERA</div>
                    </div>
            </div>
            <div className="divider_orange"></div>

            <div id="szkTable" className="flex">
                <table className="table table-bordered table-hover tb" style={{marginBottom: "0px"}}>
                    <thead>
                    <tr>
                        <th style={ this.th_style("e1typ") } onClick={() => this.tb_sort("e1typ") }>KID</th>
                        <th style={ this.th_style("e1text") } onClick={() => this.tb_sort("e1text") }>Företag</th>
                        <th style={ this.th_style("e1debcre") } onClick={() => this.tb_sort("e1debcre") }>Referens</th>
                        <th style={ this.th_style("e1moms") } onClick={() => this.tb_sort("e1moms") }>Orgnr</th>
                        <th style={ this.th_style("e1pris") } onClick={() => this.tb_sort("e1pris") }>Tele</th>
                        <th style={ this.th_style("e1mattyp") } onClick={() => this.tb_sort("e1mattyp") }>Web</th>
                        <th style={ this.th_style("e1mattyp") } onClick={() => this.tb_sort("e1mattyp") }>Start</th>
                        <th></th>
                    </tr>
                    </thead>
                </table>
                <div style={ this.tb_style() }>
                    <table className="table table-bordered table-hover tb">
                        <tbody>
                        { this.state.sza.map((row, key) =>
                            <tr key={key} onDoubleClick={() => this.kid_sel(row) }>
                                <td>{ utils.toNum( row.kk00.kid ) }</td>
                                <td>{ utils.toLang( row.kk00.ftg ) }</td>
                                <td>{ row.kk00.ref }</td>
                                <td>{ row.kk00.orgnr }</td>
                                <td>{ row.kk00.tele }</td>
                                <td>{ row.kk02.web }</td>
                                <td>{ utils.toDate( row.kk00.reg ) }</td>
                                <td>
                                    <Td_Sel items={ this.selitems } getValue={(e)=> this.sel_row_fkn(row, e)} />
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>

        </div>
        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250 ripple" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex-row">
            <button className="btn btn-hw-green w250 ripple" onClick={() => this.sz_sel() } >
                VÄLJ
            </button>
            </div>
        </div>
        </div>
        </div>

        <Wnd_Error msg={this.state.msg} ctx={this} />
        <Wnd_Ok msg={this.state.msg} ctx={this} />
        <Wnd_Info msg={this.state.msg} ctx={this} />
        <Wnd_Confirm msg={this.state.msg} ctx={this} />
      </div>
	;
	return html;
  };
}

export default Sz_Kid;