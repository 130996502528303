import React, { useState, useEffect, useContext } from 'react';
import {format} from "date-fns";

import * as net from "../../../../lib/net";

import Dd_Sel from "../../../../lib/ui/dd_sel";
import Dt_Cal from "../../../../lib/ui/dt_cal";
import * as utils from "../../../../assets/utils";

class Saldo_Gst extends React.Component {
  constructor(props, context) {
    super(props);

    this.dt_datum = React.createRef();
    this.selitems = [
        {fkn: 'bevaka', text: 'BEVAKA'},
        {fkn: 'bokbild', text: 'BOKNINGSBILD'},
        {fkn: 'confirm', text: 'BEKRÄFTA'},
        {fkn: 'cancel', text: 'AVBOKA'}
    ];

    this.store = props.store;
    this.state = {};
    this.state.ynq = [];
    //this.state.vga = [];
    this.state.rta = [];
    this.state.bra = [];

    this.state.paa = [];
    this.state.tra = [];

    this.state.gso = {};

    this.state.ix = 0;
    this.state.boknr = "";
    this.state.glopnr = "";

    this.state.wnds = {};
    this.state.rvo = {};
    if(props.wnds) {
        if(props.wnds.boknr) this.state.boknr = props.wnds.boknr;
        if(props.wnds.glopnr) this.state.glopnr = props.wnds.glopnr;
    }
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      this.gst_saldo();
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
  th_style(id) {
      var css = {};
      css.cursor = "pointer";
      if(id == this.state.sortid) css.color = "#080";
      return css;
  }

    formchange (e) {
      const name = e.target.name;
      const value = e.target.value;
      var rvo = this.state.rvo;
      var na = name.split(".");
      rvo[na[0]][na[1]] = value;
      this.setState({rvo: rvo});
      //setState({[name]: value});
    }

    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    wnd_close = () => {
      if(this.props.close) {
          this.props.close(this.props.ctx);
      }
      else this.props.ctx.setState({ wnds: {} });
	};
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  cssCols (cid) {
      var css = {};
      if(this.state.cols[cid]) css.width = this.state.cols[cid].toString() + "px";
      //if(cid == "c01") alert(JSON.stringify(this.state.cols[cid]));
      return css;
  }
  syncCols (tb) {

    if (!document.getElementById(tb)) return;

    var cols = {};
    var ix = document.getElementById(tb).rows.length - 1;
    var ic = document.getElementById(tb).rows[0].cells.length;
    var ci = 0;
    while(ci < ic) {
        var cxi = ci + 1;
        var col = "c" + cxi.toString().padStart(2, "0");
        cols[col] = document.getElementById(tb).rows[ix].cells[ci].clientWidth + 1;
        ci = ci + 1;
    }
    this.setState({cols: cols });
    //alert(JSON.stringify(this.state.cols));
    //this.forceUpdate();
  };
    val_save (name, value) {
        //alert(JSON.stringify(name));
        //alert(JSON.stringify(value));
      var rvo = this.state.rvo;
      var na = name.split(".");
      rvo[na[0]][na[1]] = value;
      this.setState({rvo: rvo});
      //setState({[name]: value});
    }
  dt_dat(e) {
    //alert(JSON.stringify(e));
    var dts = format(e, "yyyy-MM-dd");
    this.setState({ datum: dts });
  }
  addday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_datum.current.updateAdd();
  }
  subday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_datum.current.updateSub();
  }
  gst_saldo() {
    var self = this;
    //var datum = format(this.state.datum, "yyyy-MM-dd");

    var prm = {};
    prm.req = "pms.eko_red.red_gst";
    prm.boknr = this.state.boknr;
    prm.glopnr = this.state.glopnr;
    //alert(JSON.stringify(prm));

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.ok));

        if(ret.ok != "000") {
            return;
        }
        var gso = ret.rco;
        var tra = gso.tra;
        self.setState({ gso: gso, tra: tra });
        self.syncTb("trsTable");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
    }
    syncTb(tb){
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
        tdElements = tbel.getElementsByTagName('td');

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
            width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
   tb_sort(typ) {
      var self = this;
      //var rvs = self.state.rvs;
      var tra = self.state.tra;
      var sz = this.state.sz_val;

      tra.sort((a,b) => (a[typ] > b[typ]) ? 1 : ((b[typ] > a[typ]) ? -1 : 0));
      self.setState({ tra: tra, sortid: typ });
   }
  tb_filtr() {
      var self = this;
      var rvs = self.state.rvs;
      var sz = this.state.sz_val;

      if(sz.length < 1) {
        self.setState({ rva: rvs });
          return;
      }
      sz = sz.toUpperCase();
      var rva = rvs.filter(function(row) {
            var brc = false;
            if(row.company.toUpperCase().includes(sz)) brc = true;
            if(row.name.toUpperCase().includes(sz)) brc = true;
            return brc;
      });
      self.setState({rva: rva });
   }

  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "Bokning";

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="m2_backdrop" /> : null }
        <div className="m2_dlg mx_70 flex-col" style={ this.dlg_style() }>
        <div className="mx_h01">
            <div>GÄST SALDON</div>
        </div>

            <div className="divider_orange hide"></div>

            <div className="flex-row">
                <div className="flex"></div>
              <div className="flex-col">
                  { this.state.boknr } - { this.state.glopnr }
            </div>
                <div className="flex"></div>
                <button className="btn btn-hw-blue w150" onClick={() => this.gst_saldo() } aria-label="System" >
                    Visa
                </button>
            </div>
            <div className="flex-row">
            <div className="flex"></div>
              <div className="flex-row">
                  <div className="px-2">FSG: { utils.toPris( this.state.gso.fsg ) }</div>
                  <div className="px-2">BET: { utils.toPris( this.state.gso.bet ) }</div>
                  <div className="px-2">SALDO: { utils.toPris( this.state.gso.saldo ) }</div>
              </div>
               <div className="flex"></div>
            </div>
             <div className="divider_green"></div>
       <div className="mx_content flex">


        <div id="trsTable">
          <table className="table table-bordered table-hover" style={{marginBottom: "0px"}}>
                <thead>
                <tr>
                    <th style={ this.th_style("e0datum") } onClick={() => this.tb_sort("e0datum") }>Datum</th>
                    <th style={ this.th_style("e0rumsnr") } onClick={() => this.tb_sort("e0rumsnr") }>Rum</th>
                    <th style={ this.th_style("e0typ") } onClick={() => this.tb_sort("e0typ") }>Typ</th>
                    <th style={ this.th_style("e0tid") } onClick={() => this.tb_sort("e0tid") }>Tid</th>
                    <th style={ this.th_style("e0boknr") } onClick={() => this.tb_sort("e0boknr") }>Bok-Gst</th>
                    <th style={ this.th_style("e0text") } onClick={() => this.tb_sort("e0text") }>Text</th>
                    <th style={ this.th_style("e0antal") } onClick={() => this.tb_sort("e0antal") }>Antal</th>
                    <th style={ this.th_style("e0pris") } onClick={() => this.tb_sort("e0pris") }>Pris</th>
                    <th style={ this.th_style("e0tkn") } onClick={() => this.tb_sort("e0tkn") }>Tkn</th>
                </tr>
                </thead>
            </table>
            <div style={ this.tb_style() }>
          <table className="table table-bordered table-hover">
            <tbody>
                { this.state.tra.map((row, key) =>
                    <tr key={key} onDoubleClick={() => this.tr_edit(row) }>
                        <td>{ utils.toDate( row.e0datum ) }</td>
                        <td>{ utils.toRumsnr( row.e0rumsnr ) }</td>
                        <td>{ row.e0typ }</td>
                        <td>{ utils.toTid( row.e0tid ) }</td>
                        <td>{ row.e0boknr }-{ row.e0glopnr }</td>
                        <td>{ utils.toLang( row.e0text ) }</td>
                        <td>{ utils.toNum( row.e0antal ) }</td>
                        <td>{ utils.toPris( row.e0pris ) }</td>
                        <td>{ row.e0tkn }</td>
                    </tr>
                )}
                </tbody>
            </table>
        </div>
        </div>


            </div>

            <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250 ripple" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex-row">
            </div>
        </div>
      </div>

    </div>
	;
	return html;
  };
}

export default Saldo_Gst;