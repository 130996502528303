import React, { useState, useEffect, useContext } from 'react';
import {Route, Routes} from "react-router-dom";
import {withRouter} from "../lib/react/withRouter";
import { observer } from 'mobx-react'

import Op_Home from './op/home.js';
import Op_Kassa from './op/kassa.js';
import Op_Ot from './op/ot.js';
import Op_Event from './op/event.js';
import Op_Tbv from './op/tbv.js';
import Op_Tbs from './op/tbs.js';
import Op_Mtv from './op/mtv.js';
import Op_Bq from './op/bq.js';
import Op_Ki from './op/ki.js';
import Op_Kund from './op/kund.js';
import Op_Swish from './op/swish.js';
import Op_Pres from './op/pres.js';
import Stat_Home from "./stat/home";
import sso from "../sso";

function pg_home() {

    var html =
    <div className="web-content">
      Hem
    </div>
    ;
    return html;
}

class Pos_Op extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.state = { pgid: 11, deps: [] };
    //context.posrend = this;
      var po = {};
      po.kassa = { "fid": "f02", "url": "/pos/op/kassa", "name": "Kassor", "active": false };
      po.event = { "fid": "f02", "url": "/pos/op/event", "name": "Event", "active": false };
      po.tbv = { "fid": "f02", "url": "/pos/op/tbv", "name": "Bordsvy", "active": false };
      po.tbs = { "fid": "f02", "url": "/pos/op/tbs", "name": "Bordstatus", "active": false };
      po.mtv = { "fid": "f02", "url": "/pos/op/mtv", "name": "MenyTV", "active": false };
      po.bq = { "fid": "f02", "url": "/pos/op/bq", "name": "Bongkö", "active": false };
      po.ki = { "fid": "f02", "url": "/pos/op/ki", "name": "Kvittoinfo", "active": false };
      po.kund = { "fid": "f02", "url": "/pos/op/kund", "name": "Kunder", "active": false };
      po.swish = { "fid": "f02", "url": "/pos/op/swish", "name": "Swish", "active": false };
      po.pres = { "fid": "f02", "url": "/pos/op/pres", "name": "Presentkort", "active": false };
      this.state.po = po;
      this.state.pa = [];
      this.state.pa = Object.values(po);
  }
    componentDidUpdate() {
    }
    componentWillUnmount() {
    }
    componentDidMount() {
      /*
        var path = window.location.pathname;
        if(path.substr(-1) == "/") path = path.substring(0, path.length - 1);
        var op = path.split("/").pop();

        var po = this.state.po;
        if(po[op]) {
            po[op].active = true;
            //this.props.store.fkn = po[op];
            sbo.state.fkn = po[op].name;
            //alert(JSON.stringify(sbo.state));
        }
        var pa = Object.values(po);
        this.setState({ po: po, pa: pa });
    */
  }
  fknGo(row) {
    sso.seo.sfkn = row;
    this.props.navigate(row.url);
  }
  br_css(row) {
    var css = {};
    if(sso.seo.sfkn && (row.url == sso.seo.sfkn.url)) css.color = "#080";
    //var brc = false;
    //if(row.url == sbo.state.url) brc = true;
    return css;
  }

  render() {
    return (
    <div className="web-app">
         { this.props.store.cfg.bread ?
             <div className="web-bread">
               <div className="breadcrumb">
                 { this.state.pa.map((row, key) =>
                     <div key={key} className="breadcrumb-item" style={this.br_css(row)} onClick={() => this.fknGo(row)}>{row.name}</div>
                 )}
                 <div className="flex"></div>
                 <div className="cdigreen">{ sso.sdo.sfkn ? sso.sdo.sfkn.name : null }</div>
               </div>
             </div>
        : null }
        <Routes>
          <Route path="/xx" component={pg_home} />
          <Route path="kassa" element={ <Op_Kassa store={this.store} />} />
          <Route path="ot" element={ <Op_Ot store={this.store} />} />
            <Route path="event" element={ <Op_Event store={this.store} />} />
            <Route path="tbv" element={ <Op_Tbv store={this.store} />} />
            <Route path="tbs" element={ <Op_Tbs store={this.store} />} />
            <Route path="mtv" element={ <Op_Mtv store={this.store} />} />
            <Route path="bq" element={ <Op_Bq store={this.store} />} />
            <Route path="ki" element={ <Op_Ki store={this.store} />} />
            <Route path="kund" element={ <Op_Kund store={this.store} />} />
            <Route path="swish" element={ <Op_Swish store={this.store} />} />
            <Route path="pres" element={ <Op_Pres store={this.store} />} />
            <Route path="*" element={ <Op_Home store={this.store} />} />
        </Routes>
    </div>
  );
}
}
//Sys.contextType = ADOContext;

export default withRouter(observer(Pos_Op));
