import React, { useState, useEffect, useContext } from 'react';
import { observer } from 'mobx-react';

import * as net from "../../lib/net";
import Dd_Sel from "../../lib/ui/dd_sel";
import {useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function Fakt_Top(props) {
    var navigate = useNavigate();
    var seo = props.store.seo;

    var selitems = [
        {fkn: 'setup', text: 'INSTÄLLNINGAR'},
        {fkn: 'upd', text: 'UPPDATERA'},
        {fkn: 'sys', text: 'SYSTEM'},
        {fkn: 'logout', text: 'LOGGA UT'}
    ];

    var it = {};
    it.name = "TesTe";
    var app = {};
    app.name = "TesTe";
    var sdo = {};
    sdo.name = "TesTe";
    sdo.user = {};
    sdo.user.text = "TesTe";
    var app = {};
    app.sub_active = "11";
    app.fkn_active = "22";

    var fa = "fa fa-key";
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleOpen = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const goFull = () => {
        alert("Full");
    };

    function fknGo(url) {
        //var sdo = useContext(SDO);
        //alert(fkn);
        //var fkn = row.fid;
        //props.store.fkn = row;

        navigate(url);
    };
    function logout() {
        var self = this;
        var xdo = sessionStorage.getItem("svp");
        var sdo = JSON.parse(xdo);

        var prm = {};
        prm.req = "usr:logout";
        prm.uid = sdo.uid;
        prm.token = sdo.token;

        //alert(JSON.stringify(prm));
        net.gcs_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret));

                if(ret.ok != "000") {
                    alert(JSON.stringify(ret));
                    //return;
                }
                setAnchorEl(null);
                sessionStorage.removeItem("svp");
                props.store.seo = {};

                var url = "/";
                navigate(url);
            })
            .catch(function(e){
                //var txt = JSON.stringify(e);
                alert("LOGOUT ERR: " + JSON.stringify(e));
                //gda.wndError(txt);
            });
    };
    const mnu_toggle = () => {
        //props.ctx.setState({ mnu_show: !props.ctx.state.mnu_show });
        props.store.cfg.mnu_show = !props.store.cfg.mnu_show;
    };
    const bread_toggle = () => {
        props.store.cfg.bread = !props.store.cfg.bread;
    };
    const mnu_item_css = () => {
    };
    const mnu_sel = () => {
    };
    const login = () => {
    };
    const header_class = () => {
        var cls = "flex_col";

        if(false) {
            cls = cls + " web-head_1";
        }
        else cls = cls +  " web-header";


        return cls;
    }
    function sel_fkn(sel) {
        //alert(JSON.stringify(fkn));
        if(sel.fkn == "logout") {
            var url = "/logout";
            navigate(url);
        }
    }

    var html =
        <div id="header" className={ header_class() }>
            <div className="web-top-content d-flex flex-row">
                <div className="web-top-icon px-3 w60" onClick={() => mnu_toggle() }>
                    <FontAwesomeIcon icon="bars" size="1x"/>
                </div>
                <div className="web-top-mnu d-none d-md-inline-flex flex-row">
                    <div className="item" onClick={() => mnu_sel("m00") } style={ mnu_item_css("m00") }>{ props.store.fkn.name }</div>
                </div>
                <div className="flex-fill"></div>
                <div className="web-top-usr d-flex flex-column" onClick={() => fknGo("/hello") }>
                    <div><FontAwesomeIcon icon="user" size="1x"/> { seo.name }</div>
                    <div><FontAwesomeIcon icon="envelope" size="1x"/> { seo.mbox }</div>
                </div>
                <div className="web-top-sep"></div>
                <div className="">
                    <Dd_Sel items={ selitems } cls="web-top-icon" getValue={(e)=> sel_fkn(e)} />
                </div>
                <div className="">
                </div>
            </div>
        </div>
    ;
    return html;
}

export default observer(Fakt_Top);
