import React, { useState, useEffect, useContext } from 'react';
import {Route, Routes} from "react-router-dom";

import Kids_Home from './kids/home.js';
import {withRouter} from "../lib/react/withRouter";
import sso from "../sso";

class Sys_Kids extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, deps: [] };
    //context.posrend = this;
  }
  fknGo(row) {
    //alert(row.href);
    this.props.store.fkn = row;
      sso.seo.sfkn = row;
    this.props.navigate(row.url);
  }
    br_css(row) {
        var css = {};
        if(sso.seo.sfkn && (row.url == sso.seo.sfkn.url)) css.color = "#080";
        //var brc = false;
        //if(row.url == sbo.state.url) brc = true;
        return css;
    }

  render() {
      var path = window.location.pathname;
      if(path.substr(-1) == "/") path = path.substring(0, path.length - 1);
      var op = path.split("/").pop();
      var po = {};
      po.pres = { "fid": "f07", "url": "/pms/reg/pres", "name": "Presentkort", "active": false };
      po.fsk = { "fid": "f07", "url": "/pms/reg/fsk", "name": "Förskott", "active": false };
      if(po[op]) {
          po[op].active = true;
          //this.props.store.fkn = po[op];
      }
      var pa = Object.values(po);

    return (
    <div className="web-app">
         { this.props.store.cfg.bread ?
             <div className="web-bread">
                 <div className="breadcrumb">
            { pa.map((row, key) =>
                <div className="breadcrumb-item" key={key} style={this.br_css(row)} onClick={() => this.fknGo(row)}>{row.name}</div>
            )}
            <div className="flex"></div>
            <div className="cdigreen">{ this.props.store.fkn ? this.props.store.fkn.name : null }</div>
            </div>
        </div>
        : null }
        <Routes>
          <Route path="udid/*" element={ <Kids_Home store={this.webStore} />} />
          <Route path="treg/*" element={ <Kids_Home store={this.webStore} />} />
          <Route path="*" element={ <Kids_Home store={this.webStore} />} />
        </Routes>
    </div>

  );
}
}
export default withRouter(Sys_Kids);
