import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../../../lib/net";
import Fr_Input from "../../../../lib/ui/fr_input";
import Fr_Select from "../../../../lib/ui/fr_select";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Tr_Open extends React.Component {
  constructor(props, context) {
    super(props);

    this.pos = props.store.pos;
    this.state = {};
    this.state.mka = [];
    this.state.sval = [];
    this.state.tval = [];
    this.state.paa = [];
    this.state.uaa = [];
    this.state.laa = [];

    this.state.gnr = "001";
    //this.state.lro = props.lro;
    this.state.ynq = [
                {id: '0', text: 'NEJ'},
                {id: '1', text: 'JA'}
            ];

    this.state.wnd_ok = false;
    this.state.wnd_err = false;

    this.state.cols = {};
    this.state.eko = {};
    if(props.wnds.tro) {
        this.state.eko = props.wnds.tro;
        this.state.eko.e1grp = this.state.eko.e1grp.trim();
        this.state.eko.e1pris = this.state.eko.e1pris.cdipris();
    }

    this.state.ynq = [
                {id: '0', text: 'NEJ'},
                {id: '1', text: 'JA'}
            ];
    this.state.debcre = [
                {id: '0', text: 'KREDIT'},
                {id: '1', text: 'DEBET'}
            ];
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.btn_grps();
      if(!this.props.wnds.tro) this.eko_init();
  }

    eko_init() {
        var eko = {};
        eko.e1bastyp = "";
        eko.e1typ = "";
        eko.e1blank = "";
        eko.e1kontonr = "";
        eko.e1text = "";
        eko.e1debcre = "";
        eko.e1moms = "";
        eko.e1pris = "";
        eko.e1mattyp = "";
        eko.e1kassabal = "";
        eko.e1cancelled = "";
        eko.e1paytrans = "";
        eko.e1man_rep = "";
        eko.e1no_avail = "";
        eko.e1sort = "";
        eko.e1grp = "";
        eko.e1filler = "";
        eko.e1sign = "";
        eko.e1updtime = "";
        //alert(JSON.stringify(rso));
        this.setState({ eko: eko });
        return;
    }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }

    formchange (e) {
      const name = e.target.name;
      const value = e.target.value;
      var pbo = this.state.pbo;
      var na = name.split(".");
      pbo[na[0]][na[1]] = value;
      this.setState({pbo: pbo});
      //setState({[name]: value});
    }

    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    wnd_close = () => {
      if(this.props.close) {
          this.props.close(this.props.ctx);
      }
      else this.props.ctx.setState({ wnds: {} });
	};
    handleChange = (event, newValue) => {
        //alert(newValue);
        this.setState({ix: newValue});
      };
    toggle() {
      this.setState({modal: false});
	};

  submitHandler = event => {
    event.preventDefault();
    event.target.className += " was-validated";
  };

  changeHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  val_save(id, val) {
      var eko = this.state.eko;
      eko[id] = val;
        this.setState({ eko: eko });
      return;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  cssCols (cid) {
      var css = {};
      if(this.state.cols[cid]) css.width = this.state.cols[cid].toString() + "px";
      //if(cid == "c01") alert(JSON.stringify(this.state.cols[cid]));
      return css;
  }
  tr_save() {
    var self = this;

    this.state.eko.e1pris = this.state.eko.e1pris.pris2cdi();

    var prm = {};
    prm.req = "pms.tr.tr_upd";
    prm.eko = this.state.eko;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));
        if(ret.ok == "000") {
            self.setState({wnd_ok: true });
            setTimeout(function(){
                self.setState({wnd_ok: false });
                  if(self.props.close) {
                      self.props.close(self.props.ctx);
                  }
                  else self.props.ctx.setState({ wnd_rs: false });
                return;
            }, 1000);
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  tr_del() {
    var self = this;
    var prm = {};
    prm.req = "pms.tr.tr_del";
    prm.eko = this.state.eko;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));
        if(ret.ok == "000") {
            self.setState({wnd_ok: true });
            setTimeout(function(){
                self.setState({wnd_ok: false });
                  if(self.props.close) {
                      self.props.close(self.props.ctx);
                  }
                  else self.props.ctx.setState({ wnd_rs: false });
                return;
            }, 1000);
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

  render() {
    //if(!this.props.show) return "";
    var title = this.props.title;
    if(!title) title = "Resurs";
    //if(props.kro) alert(props.kro.krr00.kid);
    const popover = {
      position: 'absolute',
      top: '50px',
      zIndex: '2',
    }
    const cover = {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    }
    const okwnd = {
      position: 'absolute',
      width: '35%',
      height: '35%',
      zIndex: '19999',
      top: '35%',
      left: '35%',
        border: "1px solid #ccc",
        borderRadius: "5px",
       backgroundColor: "#015d00",
        backgroundImage: "linear-gradient(314deg, #015d00 0%, #04bf00 74%)"
    }
    const errwnd = {
      position: 'absolute',
      width: '35%',
      height: '35%',
      zIndex: '19999',
      top: '35%',
      left: '35%',
        border: "1px solid #ccc",
        borderRadius: "5px",
        backgroundColor: "#ED213A",  /* fallback for old browsers */
        background: "-webkit-linear-gradient(to right, #93291E, #ED213A)",  /* Chrome 10-25, Safari 5.1-6 */
        backgroundImage: "linear-gradient(to right, #93291E, #ED213A)" /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        //backgroundColor: "#8E0E00",  /* fallback for old browsers */
        //background: "-webkit-linear-gradient(to right, #1F1C18, #8E0E00)",  /* Chrome 10-25, Safari 5.1-6 */
        //backgroundImage: "linear-gradient(to right, #1F1C18, #8E0E00)" /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    }

	var html =
        <div className="mx_base">
        <div className="m2_backdrop" />
        <div className="m2_dlg mx_70 flex-col" style={ this.dlg_style() } draggable="true" >
        <div className="mx_content flex">

        <div className="mx_h01">
            <div>TRANSAKTION</div>
        </div>
            <div className="flex-row" style={{padding:"5px",height:"35px"}}>
                    <div className="flex-col flex">
                    </div>
                    <div className="flex-col">
                        <div>UPPDATERA</div>
                    </div>
            </div>
            <div className="divider_orange"></div>

            <form className="cdi_f03">
            <div className="flex-row">
                    <Fr_Input label="typ" className="text-left w150" text={this.state.eko.e1typ} getValue={(e)=> this.val_save("e1typ", e) } />
                    <Fr_Input label="Bastyp" className="text-left w150" text={this.state.eko.e1bastyp} getValue={(e)=> this.val_save("e1bastyp", e) } />
            </div>
            <div className="flex-row">
                    <Fr_Input label="Text" className="text-left w350" text={this.state.eko.e1text} getValue={(e)=> this.val_save("e1text", e) } />
                    <Fr_Input label="Konto" className="text-left w250" text={this.state.eko.e1kontonr} getValue={(e)=> this.val_save("e1kontonr", e) } />
            </div>
            <div className="flex-row">
                    <Fr_Input label="Pris" className="text-left w250" text={this.state.eko.e1pris} getValue={(e)=> this.val_save("e1pris", e) } />
            </div>

            <div className="divider_green"></div>
            <div className="flex-row">
                    <Fr_Select flex label="Debet/Kredit" className="text-left w250" text={this.state.eko.e1debcre} items={this.state.debcre} getValue={(e)=> this.val_save("e1debcre", e) } />
                    <Fr_Input label="Moms" className="text-left w250" text={this.state.eko.e1moms} getValue={(e)=> this.val_save("e1moms", e) } />
            </div>
            <div className="flex-row">
                    <Fr_Select flex label="Kassabalans" className="text-left w250" text={this.state.eko.e1kassabal} items={this.state.ynq} getValue={(e)=> this.val_save("e1kassabal", e) } />
            </div>
            <div className="flex-row">
                    <Fr_Input label="Mattyp" className="text-left w250" text={this.state.eko.e1mattyp} getValue={(e)=> this.val_save("e1mattyp", e) } />
                    <Fr_Input label="Sortering" className="text-left w250" text={this.state.eko.e1sort} getValue={(e)=> this.val_save("e1sort", e) } />
            </div>
            <div className="flex-row">
                    <Fr_Input label="Grupp" className="text-left w250" text={this.state.eko.e1grp} getValue={(e)=> this.val_save("e1grp", e) } />
            </div>


            </form>

        </div>
        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250 ripple" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <button className="btn btn-hw-red w250 ripple" onClick={() => this.tr_del() } >
                TA BORT
            </button>
            <div className="flex-row">
            <button className="btn btn-hw-red w250 ripple" onClick={() => this.tr_save() } >
                SPARA
            </button>
            </div>
        </div>
      </div>

        { this.state.wnd_ok ?
            <div style={ okwnd } className="d-flex flex-column align-content-center justify-content-center text-center" >
                    <h2 className="" style={{color: "#fff"}}>SPARAT</h2>
                    <FontAwesomeIcon className="" icon="check" size="4x" style={{color: "#fff"}} />
            </div>
            : null }
            { this.state.wnd_err ?
                <div style={ errwnd } className="d-flex flex-column align-content-center justify-content-center text-center" >
                        <h2 className="" style={{color: "#fff"}}>FEL</h2>
                        <FontAwesomeIcon className="" icon="exclamation" size="4x" style={{color: "#fff"}} />
                </div>
                : null }
      </div>
	;
	return html;
  };
}

export default Tr_Open;