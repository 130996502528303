import React, { useState, useEffect, useContext } from 'react';

import * as utils from "../../../../assets/utils";
import * as net from "../../../../lib/net";

import Dd_Sel from "../../../../lib/ui/dd_sel";
import Td_Sel from "../../../../lib/ui/td_sel";
import PdfNotaDlg from "../fkn/pdf_nota_dlg";

import Pay_Emv from "../pay/pay_emv";

class Collect_Pay extends React.Component {
  constructor(props, context) {
    super(props);

     this.selitems = [
        {fkn: 'setup', text: 'INSTÄLLNINGAR'},
        {fkn: 'upd', text: 'UPPDATERA'},
        {fkn: 'sys', text: 'SYSTEM'},
        {fkn: 'logout', text: 'LOGGA UT'}
    ];

   //this.webStore = props.store;
    this.state = {};
    this.state.ynq = [];
    this.state.aa = [];
    this.state.cra = [];
    this.state.srow = {};
    this.state.wnds = {};

    if(props.wnds) {
        this.fkn_close = props.wnds.fkn_close;
        this.state.boknr = props.wnds.boknr;
        this.state.rvo = props.wnds.rvo;
        this.state.cra = props.wnds.cra;
    }

    this.state.pdf = 0;
    this.state.pdf_content = null;
    this.state.loading = 0;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.syncTb("craTable");
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }

    wnd_close() {
      if(this.fkn_close) this.fkn_close(this.props.ctx);
      else this.props.ctx.setState({ wnds: {} });
	};
  pdf_done (ret) {
      if(this.fkn_close) this.fkn_close(this.props.ctx);
      else this.props.ctx.setState({ wnds: {} });
  }
  tb_style() {
      var css = {};
      css.height = (window.innerHeight * 0.8) - 275;
      css.overflow = "auto";
      return css;
  }
  row_style(row) {
    var srow = this.state.srow;
    var css = {};
    if(srow[row.etr01.e0lopnr]) {
        css.background = "#080";
        css.color = "#fff";
    }
    return css;
  }
    syncTb(tb){
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
        tdElements = tbel.getElementsByTagName('td');

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
            width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
  collect_pay(betkod) {
    var self = this;
      if(betkod == "kont") {
        this.pay_trs(betkod);
      }
      if(betkod == "swis") {
        this.pay_trs(betkod);
      }
      if(betkod == "fakt") {
        this.pay_trs(betkod);
      }
      if(betkod == "emv") {
        this.pay_emv_trs();
      }
  }
  pay_emv_trs() {
    var self = this;

    var nta = this.state.cra;

    if(Object.keys(this.state.srow).length > 0) {
        nta = Object.values(this.state.srow);
    }
    if(nta.length < 1) {
        alert("Inga rader betala");
        return;
    }

    var pra = [];
    //pra.push( this.state.selrow.etr01);
    var belopp = 0;
    for(var row of nta) {
        var pris = row.etr01.e0pris * 1;
        belopp = belopp + pris
        pra.push( row.etr01);
    }

    if(belopp == 0) {
        alert("Inget att betala");
        return;
    }
    belopp = belopp.toString();

    var wnds = {};
    wnds.emv = 1;
    wnds.boknr = this.state.rvo.gsb00.r0boknr;
    wnds.gso = this.state.rvo.gsb00;
    wnds.pra = pra;
    wnds.belopp = belopp;
    wnds.cb = this.cb_payemv;
    this.setState({wnds: wnds});
  }
  cb_payemv(ctx, ret) {
      if(ret && (ret.ok == "000")) {

      }
      ctx.setState({ wnds: {}, srow: {} });
      //ctx.charges();
      if(ctx.fkn_close) ctx.fkn_close(ctx.props.ctx);
      else ctx.props.ctx.setState({ wnds: {} });
  };

  pay_trs(betkod) {
    var self = this;

    var nta = this.state.cra;

    if(Object.keys(this.state.srow).length > 0) {
        nta = Object.values(this.state.srow);
    }
    if(nta.length < 1) {
        alert("Inga rader betala");
        return;
    }

    var pra = [];
    //pra.push( this.state.selrow.etr01);
    var belopp = 0;
    for(var row of nta) {
        var pris = row.etr01.e0pris * 1;
        belopp = belopp + pris
        pra.push( row.etr01);
    }

    if(belopp == 0) {
        alert("Inget att betala");
        return;
    }
    belopp = belopp.toString();

    var bettxt = "BET: " + betkod;
    if(betkod == "kont") bettxt = "KONTANT";
    if(betkod == "fakt") bettxt = "FAKTURA";
    if(betkod == "swis") bettxt = "SWISH";
    if(betkod == "Rpri") bettxt = "PRESENTKORT";

    var prm = {};
    prm.req = "pms.charge.pay_trs";
    prm.boknr = this.state.rvo.gsb00.r0boknr;
    prm.glopnr = this.state.rvo.gsb00.r0glopnr;
    prm.payrows = pra;
    prm.paycode = betkod;
    prm.paytext = bettxt;
    prm.payamount = belopp;
    prm.tkn = "+";
    prm.datum = this.state.dts;
    //alert(JSON.stringify(prm));

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));
        if(ret.ok != "000") {
            alert("Betalning misslyckades");
            return;
        }
        var notanr = ret.notanr;
        self.prn_spec(notanr);
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  prn_spec(notanr) {
    var self = this;

    var prm = {};
    prm.req = "pms.charge.prn_spec";
    prm.boknr = this.state.rvo.gsb00.r0boknr;
    prm.glopnr = this.state.rvo.gsb00.r0glopnr;
    prm.notanr = notanr;
    //alert(JSON.stringify(prm));
    //return;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.ok));

        //var uri = "data:application/pdf;base64," + ret.pdf;
        self.setState({pdf: 1, pdf_content: ret.pdf });
        //self.charges();
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  sel_row(row) {
    var srow = this.state.srow;
    var key = row.etr01.e0lopnr;
    if(srow[key]) {
      delete( srow[key] );
    }
    else srow[key] = row;
    this.setState({srow: srow});
  }

  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "";

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="m2_backdrop" /> : null }
        <div className="m2_dlg mx_70h flex-col" style={ this.dlg_style() }>
        <div className="flex-row " style={{padding:"0px",height:"50px",lineHeight:"50px", background: "#1a456b", color: "#fff"}}>
                <div className="flex-row flex" style={{padding:"0px 5px", fontSize: "22px"}}>
                    <div>SAMLAIN BETALA { this.state.rumsnr }</div>
                </div>
            <div className="">
                <Dd_Sel items={ this.selitems } cls="web-top-icon" getValue={(e)=> this.sel_fkn(e)} />
            </div>
        </div>
        <div className="mx_content flex">

        <div className="web-col flex" style={{padding: "0px"}}>

        <div id="craTable" className="flex">
          <table className="table table-bordered table-hover tb" style={{marginBottom: "0px"}}>
            <thead>
            <tr>
                <th>Datum</th>
                <th>Gäst</th>
                <th>Rumsnr</th>
                <th>Typ</th>
                <th>Text</th>
                <th>Antal</th>
                <th>Pris</th>
                <th>Summa</th>
                <th>Tecken</th>
                <th>Löpnr</th>
                <th></th>
            </tr>
            </thead>
        </table>
        <div style={ this.tb_style() }>
          <table className="table table-bordered table-hover tb">
            <tbody>
            { this.state.cra.map((row, key) =>
            <tr key={key} style={ this.row_style(row) } onClick={ () => this.sel_row(row) }>
                <td>{ utils.toDate( row.etr01.e0datum ) }</td>
                <td>{ row.etr01.e0boknr }/{ row.etr01.e0glopnr.ztrim() }</td>
                <td>{ utils.toRumsnr( row.etr01.e0rumsnr ) }</td>
                <td>{ row.etr01.e0typ }</td>
                <td>{ row.etr01.e0text }</td>
                <td className="text-center">{ utils.toNum( row.etr01.e0antal ) }</td>
                <td className="text-right">{ utils.toPris( row.etr01.e0pris ) }</td>
                <td className="text-right">{ utils.toPris((row.etr01.e0antal * 1) * (row.etr01.e0pris * 1)) }</td>
                <td>{ row.etr01.e0tkn }</td>
                <td>{ utils.toNum( row.etr01.e0lopnr ) }</td>
                <td>
                    <Td_Sel items={ this.rowitems } getValue={(e)=> this.sel_row_fkn(row, e)} />
                </td>
            </tr>
            )}
            </tbody>
        </table>
        </div>
        </div>

        <div className="divider_orange"></div>

        <div className="web-row">
        <div className="web-col flex">
            <div className="wnd-btn" onClick={() => this.collect_pay("fakt") } >
                <div>FAKTURA</div>
                <div></div>
            </div>
            <div className="wnd-btn" onClick={() => this.wnd_close() } >
                <div>STÄNG</div>
                <div></div>
            </div>
        </div>
         <div className="web-col flex" style={{padding: "0px 10px"}}>
            <div className="wnd-btn" onClick={() => this.collect_pay("swis") } >
                <div>SWISH</div>
                <div></div>
            </div>
            <div className="wnd-btn" onClick={() => this.collect_pay("Rpri") } >
                <div>PRESENTKORT</div>
                <div></div>
            </div>
       </div>
         <div className="web-col flex" style={{padding: "0px 10px"}}>
            <div className="wnd-btn" onClick={() => this.collect_pay("emv") } >
                <div>EMV KORT</div>
                <div></div>
            </div>
            <div className="wnd-btn" onClick={() => this.collect_pay("kont") } >
                <div>KONTANT</div>
                <div></div>
            </div>
       </div>
       </div>

        </div>

        </div>
      </div>
    { this.state.wnds.emv ? <Pay_Emv wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
    <PdfNotaDlg show={this.state.pdf} pdf={this.state.pdf_content} done={(ret) => this.pdf_done(ret)} backdrop={true}/>
    </div>
	;
	return html;
  };
}

export default Collect_Pay;