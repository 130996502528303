import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../../lib/net";
import * as utils from "../../../assets/utils";

import Fr_Input from "../../../lib/ui/fr_input";
import Fr_Select from "../../../lib/ui/fr_select";
import Fr_Cal from "../../../lib/ui/fr_cal";
import Wnd_Error from "../../../lib/ui/wnd_error";
import Wnd_Ok from "../../../lib/ui/wnd_ok";
import Wnd_Info from "../../../lib/ui/wnd_info";
import Wnd_Confirm from "../../../lib/ui/wnd_confirm";

class Di_Edit extends React.Component {
  constructor(props, context) {
    super(props);

    this.pms = props.store.pms;

    this.state = {};
    this.state.mka = [];
    this.state.sval = [];
    this.state.tval = [];
    this.state.paa = [];
    this.state.uaa = [];
    this.state.msg = {};

    this.state.gnr = "001";
    //this.state.lro = props.lro;
    this.state.ynq = [
                {id: '0', text: 'NEJ'},
                {id: '1', text: 'JA'}
            ];

    this.state.wnd_ok = false;
    this.state.wnd_err = false;

    this.state.cols = {};
      this.state.kao = {};
    if(props.wnds.kao) {
        this.state.kao = props.wnds.kao;
        //this.state.kao.e1grp = this.state.kao.e1grp.trim();
    }

    this.state.ynq = [
                {id: '0', text: 'NEJ'},
                {id: '1', text: 'JA'}
            ];
    this.state.debcre = [
                {id: '0', text: 'KREDIT'},
                {id: '1', text: 'DEBET'}
            ];
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.btn_grps();
      if(!this.props.wnds.kao) this.kao_init();
  }

    kao_init() {
        var kao = {};
        kao.e1bastyp = "";
        kao.e1typ = "";
        kao.e1blank = "";
        //alert(JSON.stringify(rso));
        this.setState({ kao: kao });
        return;
    }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }

    wnd_close = () => {
      if(this.props.close) {
          this.props.close(this.props.ctx);
      }
      else this.props.ctx.setState({ wnds: {} });
	};

  val_save(id, val) {
    var kao = this.state.kao;
    kao[id] = val;
    this.setState({ kao: kao });
    return;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }

  di_save() {
    var self = this;
    var prm = {};
    prm.req = "pms.di.di_upd";
    prm.kao = this.state.kao;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));
        if(ret.ok == "000") {
            self.setState({ msg: {ok: true, text: "Sparad ..."} });
            setTimeout(function(){
                self.setState({msg: {} });
                if(self.props.wnds.cb) {
                    self.props.wnds.cb(self.props.ctx);
                }
                else self.props.ctx.setState({ wnds: {} });
                return;
            }, 1000);
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  di_del() {
    var self = this;
      var kao = this.state.kao;
      kao.info = "";
      kao.farg = "";
      kao.priskat = "";

    var prm = {};
    prm.req = "pms.di.di_upd";
    prm.kao = kao;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));
        if(ret.ok == "000") {
            self.setState({ msg: {ok: true, text: "Rensad ..."} });
            setTimeout(function(){
                self.setState({msg: {} });
                if(self.props.wnds.cb) {
                    self.props.wnds.cb(self.props.ctx);
                }
                else self.props.ctx.setState({ wnds: {} });
                return;
            }, 1000);
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

  render() {
    //if(!this.props.show) return "";
    var title = this.props.title;
    if(!title) title = "Resurs";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        <div className="m2_backdrop" />
        <div className="m2_dlg mx_70 flex-col" style={ this.dlg_style() } draggable="true" >
        <div className="mx_content flex">

        <div className="mx_h01">
            <div>DATUM INFORMATION</div>
            <div className="flex"></div>
            <div onClick={() => this.wnd_close() }>X</div>
        </div>
            <div className="flex-row" style={{padding:"5px",height:"35px"}}>
                    <div className="flex-col flex">
                        <div className="txt_18">{ utils.toDate(this.state.kao.datum)}</div>
                    </div>
                    <div className="flex-col">
                        <div>UPPDATERA</div>
                    </div>
            </div>
            <div className="divider_orange"></div>

            <form className="cdi_f03">
            <div className="flex-row hide">
                <Fr_Cal label="Datum" className="text-left w250" text={this.state.kao.datum} getValue={(e)=> this.val_save("datum", e) } />
            </div>
            <div className="flex-row">
                <Fr_Input label="Info" className="text-left w500" text={this.state.kao.info} getValue={(e)=> this.val_save("info", e) } />
            </div>

            <div className="divider_green"></div>
            <div className="flex-row">
                <Fr_Input label="Färg" className="text-left w250" text={this.state.kao.farg} getValue={(e)=> this.val_save("farg", e) } />
            </div>
            <div className="flex-row">
                <Fr_Input label="Priskat" className="text-left w250" text={this.state.kao.priskat} getValue={(e)=> this.val_save("priskat", e) } />
            </div>


            </form>

        </div>
        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <button className="btn btn-hw-red w250" onClick={() => this.di_del() } >
                RENSA DAG
            </button>
            <div className="flex-row">
            <button className="btn btn-hw-red w250" onClick={() => this.di_save() } >
                SPARA
            </button>
            </div>
        </div>
      </div>

            <Wnd_Error msg={this.state.msg} ctx={this} />
            <Wnd_Ok msg={this.state.msg} ctx={this} />
            <Wnd_Info msg={this.state.msg} ctx={this} />
            <Wnd_Confirm msg={this.state.msg} ctx={this} />
      </div>
	;
	return html;
  };
}

export default Di_Edit;