import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../../lib/net";
import Fr_Input from "../../../lib/ui/fr_input";
import Wnd_Error from "../../../lib/ui/wnd_error";
import Wnd_Ok from "../../../lib/ui/wnd_ok";

class Sid_Add extends React.Component {
  constructor(props, context) {
    super(props);

    this.pos = props.store.pos;

    this.state = {};
    this.state.ynq = [];

    this.state.cols = {};
    this.state.lrwrk = false;
    this.state.mno = {};
    this.state.sdo = {};
    this.state.msg = {};
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      this.sid_init();
  }

    sid_init() {
        var sdo = {};
        sdo.uid = "";
        sdo.sid = "";
        sdo.text = "";
        sdo.styp = "";
        sdo.chan = {};
        sdo.city = "";
        sdo.tele = "";
        sdo.logo = "";
        sdo.bord = "";
        sdo.swish = "";
        sdo.shoptheme = "";
    }
    dlg_style() {
        var css = {};
        //css.width = window.innerWidth * 0.8;
        //css.height = window.innerHeight * 0.8;
        return css;
    }
    tab_style(tabid) {
      var css = {};
      css.display = "none";
      if(this.state.tabval === tabid) css.display = "block";
      return css;
    }
    tab_head(tabid) {
      var css = {};
      css.background = "#fff";
      css.color = "#000";
      if(this.state.tabval === tabid) {
          css.background = "#080";
          css.color = "#fff";
      }
      return css;
    }
    grp_style(row) {
      var css = {};
      css.background = "#008";
      if(this.state.gnr === row.vgrupp) css.background = "#080";
      return css;
    }

    formchange (e) {
      const name = e.target.name;
      const value = e.target.value;
      var pbo = this.state.pbo;
      var na = name.split(".");
      pbo[na[0]][na[1]] = value;
      this.setState({pbo: pbo});
      //setState({[name]: value});
    }

    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    wnd_close = () => {
		//this.props.ctx.setState({ wnds: {} });
		this.props.ctx.upd(this.props.ctx);
	};
    handleChange = (event, newValue) => {
        //alert(newValue);
        this.setState({ix: newValue});
      };
    toggle() {
      this.setState({modal: false});
	};

  submitHandler = event => {
    event.preventDefault();
    event.target.className += " was-validated";
  };

  changeHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  cssCols (cid) {
      var css = {};
      if(this.state.cols[cid]) css.width = this.state.cols[cid].toString() + "px";
      //if(cid == "c01") alert(JSON.stringify(this.state.cols[cid]));
      return css;
  }
  syncCols (tb) {

    if (!document.getElementById(tb)) return;

    var cols = {};
    var ix = document.getElementById(tb).rows.length - 1;
    var ic = document.getElementById(tb).rows[0].cells.length;
    var ci = 0;
    while(ci < ic) {
        var cxi = ci + 1;
        var col = "c" + cxi.toString().padStart(2, "0");
        cols[col] = document.getElementById(tb).rows[ix].cells[ci].clientWidth + 1;
        ci = ci + 1;
    }
    this.setState({cols: cols });
    //alert(JSON.stringify(this.state.cols));
    //this.forceUpdate();
  };
  val_save (name, value) {
     var sdo = this.state.sdo;
      //var na = name.split(".");
      //pbo[na[0]][na[1]] = value;
      sdo[name] = value;
      this.setState({sdo: sdo});
      //setState({[name]: value});
    }

  sid_save() {
    var self = this;
    var prm = {};
    prm.req = "wsc.msa.sid_add";
      prm.sdo = this.state.sdo;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));
   		//self.props.ctx.upd(self.props.ctx);
        var msg = { info: false, ok: true, text: "Sparad ..."};
        self.setState({msg: msg });
        setTimeout(function(){
            self.setState({msg: {} });
        }, 1000);
  })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  sid_del() {
    var self = this;

    var prm = {};
    prm.req = "wsc.msa.sid_remove";
    prm.sdo = this.state.sdo;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));
   		self.props.ctx.upd(self.props.ctx);
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

  render() {
    //if(!this.props.show) return "";

    //alert(this.props.ctx.state.lro);

    var title = this.props.title;
    if(!title) title = "Knappar";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        <div className="m2_backdrop" />
        <div className="m2_dlg mx_70 flex-col" style={ this.dlg_style() } draggable="true" >
        <div className="mx_content flex">

            <div className="flex-row" style={{padding:"5px",height:"35px"}}>
                    <div className="flex-col flex">
                        <div>MENY:</div>
                    </div>
                    <div className="flex-col">
                        <div>UPPDATERA</div>
                    </div>
            </div>
            <div className="divider_orange"></div>

            <form className="cdi_f03">
                <div className="form-row flex-row">
                    <Fr_Input label="System" className="text-left w250" text={this.state.sdo.uid} getValue={(e)=> this.val_save("uid", e) } />
                </div>
                <div className="form-row flex-row">
                    <Fr_Input label="SID" className="text-left w250" text={this.state.sdo.sid} getValue={(e)=> this.val_save("sid", e) } />
                    <Fr_Input label="Typ" className="text-left w150" text={this.state.sdo.styp} getValue={(e)=> this.val_save("styp", e) } />
                </div>
                <div className="form-row flex-row">
                    <Fr_Input label="Text" className="text-left w350" text={this.state.sdo.text} getValue={(e)=> this.val_save("text", e) } />
                </div>
                <div className="form-row flex-row">
                    <Fr_Input label="Stad" className="text-left w250" text={this.state.sdo.city} getValue={(e)=> this.val_save("city", e) } />
                    <Fr_Input label="Tele" className="text-left w150" text={this.state.sdo.tele} getValue={(e)=> this.val_save("tele", e) } />
                </div>
                <div className="form-row flex-row">
                    <Fr_Input label="Tema" className="text-left w250" text={this.state.sdo.shoptheme} getValue={(e)=> this.val_save("shoptheme", e) } />
                    <Fr_Input label="Logo" className="text-left w150" text={this.state.sdo.logo} getValue={(e)=> this.val_save("logo", e) } />
                </div>
            </form>

        </div>
        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <button className="btn btn-hw-green w250" onClick={() => this.sid_save() } >
                SPARA
            </button>
        </div>
      </div>
        <Wnd_Error msg={this.state.msg} ctx={this} />
        <Wnd_Ok msg={this.state.msg} ctx={this} />
      </div>
	;
	return html;
  };
}

export default Sid_Add;