import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../../lib/net";

class Cond_Open extends React.Component {
  constructor(props, context) {
    super(props);

    this.pos = props.store.pos;
    this.state = {};
    this.state.ynq = [];
    this.state.sval = [];

    this.state.gnr = "001";
    this.state.gbo = {};
    //this.state.lro = props.lro;
    this.state.aa = [
                {id: '01', text: 'AVD 01'},
                {id: '02', text: 'AVD 02'}
            ];
    this.state.vga = [
                {v0katnr: '001', v0text: 'VGRP 001'},
                {v0katnr: '002', v0text: 'VGRP 002'},
                {v0katnr: '009', v0text: 'VGRP 009'}
            ];
    this.state.cval = [
                {id: '001', text: 'ANTAL GER NYTT PRIS'},
                {id: '002', text: 'ANTAL GER RABATT PÅ PRIS'},
                {id: '003', text: 'PRISÄNDRING GENOM RABATT'}
            ];

  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.btn_grps();
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    tab_style(tabid) {
      var css = {};
      css.display = "none";
      if(this.state.tabval === tabid) css.display = "block";
      return css;
    }
    tab_head(tabid) {
      var css = {};
      css.background = "#fff";
      css.color = "#000";
      if(this.state.tabval === tabid) {
          css.background = "#080";
          css.color = "#fff";
      }
      return css;
    }
    grp_style(row) {
      var css = {};
      css.background = "#008";
      if(this.state.gnr === row.vgrupp) css.background = "#080";
      return css;
    }
    prn_val() {
      alert(this.state.pbo.plu00.p0namn);
      alert(this.state.pbo.plu00.p0pris);
      alert(this.state.pbo.plu02.p2vgrupp);
    }

    formchange (e) {
      const name = e.target.name;
      const value = e.target.value;
      var pbo = this.state.pbo;
      var na = name.split(".");
      pbo[na[0]][na[1]] = value;
      this.setState({pbo: pbo});
      //setState({[name]: value});
    }

    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    handleClose = () => {
		this.props.ctx.setState({ condopen: false });
	};
    handleChange = (event, newValue) => {
        //alert(newValue);
        this.setState({ix: newValue});
      };
    toggle() {
      this.setState({modal: false});
	};

  submitHandler = event => {
    event.preventDefault();
    event.target.className += " was-validated";
  };

  changeHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  };


  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  cssCols (cid) {
      var css = {};
      if(this.state.cols[cid]) css.width = this.state.cols[cid].toString() + "px";
      //if(cid == "c01") alert(JSON.stringify(this.state.cols[cid]));
      return css;
  }
  syncCols (tb) {

    if (!document.getElementById(tb)) return;

    var cols = {};
    var ix = document.getElementById(tb).rows.length - 1;
    var ic = document.getElementById(tb).rows[0].cells.length;
    var ci = 0;
    while(ci < ic) {
        var cxi = ci + 1;
        var col = "c" + cxi.toString().padStart(2, "0");
        cols[col] = document.getElementById(tb).rows[ix].cells[ci].clientWidth + 1;
        ci = ci + 1;
    }
    this.setState({cols: cols });
    //alert(JSON.stringify(this.state.cols));
    //this.forceUpdate();
  };
  cond_save() {
    var self = this;
    var prm = {};
    prm.req = "pos.pos_cond.cond_save";
    prm.avd = this.state.avd;
    prm.dao = this.state.dao;

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        alert(JSON.stringify(ret));

        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }

  render() {
    if(!this.props.show) return "";

    //alert(this.props.ctx.state.lro);

    var title = this.props.title;
    if(!title) title = "Knappar";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        <div className="m2_backdrop" />
        <div className="m2_dlg mx_70 flex-col" style={ this.dlg_style() } draggable="true" >
        <div className="mx_content flex">

            <div className="flex-row" style={{padding:"5px",height:"35px"}}>
                    <div className="flex-col flex">
                        <div>NYTT VILLKOR:</div>
                    </div>
                    <div className="flex-col">
                        <div>UPPDATERA</div>
                    </div>
            </div>
            <div className="divider_orange"></div>

            <div className="flex-row">
                <div className="col flex">
              <label className="grey-text">Avd</label>
              <input value={this.state.dao.c0avd} name="con00.c0avd" onChange={this.changeHandler}
                     type="text" id="avd" className="form-control"
                     placeholder="" required
              />
            </div>
                <div className="col flex">
              <label className="grey-text">PLU</label>
              <input value={this.state.dao.c0plu} name="con00.c0plu" onChange={this.changeHandler}
                     type="text" id="plu" className="form-control w150"
                     placeholder="" required
              />
            </div>
            </div>
            <div className="flex-row">
                <div className="col flex">
              <label className="grey-text">VILLKOR</label>
                <select name="con00.c0cond" value={this.state.dao.c0cond} className="form-control w250" onChange={(e) => this.formchange(e)}>
                    { this.state.cval.map((row, key) =>
                    <option key={key} value={row.id}>{ row.id } - {row.text}</option>
                    )}
                </select>
            </div>
                <div className="col flex">
              <label className="grey-text">KOPPLING</label>
              <input value={this.state.dao.c0koppl} name="con00.c0koppl" onChange={this.changeHandler}
                     type="text" id="v03" className="form-control"
                     placeholder="" required
              />
            </div>
            </div>
            <div className="flex-row">
                <div className="col flex">
              <label className="grey-text">Antal</label>
              <input value={this.state.dao.c0antal} name="con00.c0antal" onChange={this.changeHandler}
                     type="text" id="v03" className="form-control"
                     placeholder="" required
              />
            </div>
                <div className="col flex">
              <label className="grey-text">Pris</label>
              <input value={this.state.dao.c0pris} name="con00.c0pris" onChange={this.changeHandler}
                     type="text" id="v03" className="form-control"
                     placeholder="" required
              />
            </div>
            </div>
            <div className="divider_green"></div>
            <div className="flex-row">
                <div className="col flex">
              <label className="grey-text">Plu 2</label>
              <input value={this.state.dao.c0prod2} name="con00.c0prod2" onChange={this.changeHandler}
                     type="text" id="v03" className="form-control"
                     placeholder="" required
              />
            </div>
                <div className="col flex">
              <label className="grey-text">Pris 2</label>
              <input value={this.state.dao.c0pris1} name="con00.c0pris1" onChange={this.changeHandler}
                     type="text" id="v03" className="form-control"
                     placeholder="" required
              />
            </div>
            </div>

        </div>
        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250 ripple" onClick={() => this.handleClose() } >
                STÄNG
            </button>
        </div>
      </div>
      </div>
	;
	return html;
  };
}

export default Cond_Open;