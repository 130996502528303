import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";

import Dt_Cal from "../../lib/ui/dt_cal";
import {addDays, format} from "date-fns";
import * as utils from "../../assets/utils";
import Td_Sel from "../../lib/ui/td_sel";
import Pms_Rvo from "../rv/fb/rvo";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class PMS_BelAba extends React.Component {
  constructor(props, context) {
    super(props);

    this.dt_dt = React.createRef();

    this.webStore = props.store;
    this.state = { pgid: 11, tra: [] };
    this.state.seldate = new Date();
    this.state.avd = "01";
    this.state.kro = {};
    this.state.aa = [];
    this.state.cols = {};
    this.state.rva = [];
    this.state.sel = 1;
    this.state.wnds = {};
    this.state.tot = {};

    this.state.today = new Date();
    var dts = format(this.state.today, "yyyy-MM-dd");
    this.state.dts = dts;

    this.selitems = [
        {fkn: 'open', text: 'ÖPPNA'},
        {fkn: 'cancel', text: 'AVBOKA'}
    ];
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.show_ank();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  th_style(id) {
      var css = {};
      css.cursor = "pointer";
      if(id == this.state.sortid) css.color = "#080";
      return css;
  }
  selcss(part) {
      var css = {};
      css.margin = "0px";
      css.padding = "0px 10px";
      css.height = "40px";
      css.lineHeight = "40px";
      css.background = "#fff";
      css.color = "#800";
      if(part == this.state.sel) {
          css.background = "#080";
          css.color = "#fff";
      }
      css.cursor = "pointer";
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

  rv_edit(row) {
    var self = this;
    var wnds = {};
    wnds.wnd_rvo = true;
    wnds.boknr = row.rblx0.r0boknr;
    this.setState({wnds: wnds});
  }
  fkn_close() {
    this.setState({edit: false });
    //this.props.history.push("cas");
    //this.login_srv(uid, pwd);
  }
  refresh() {
    var self = this;
    if(self.state.sel == 1) self.show_ank();
    if(self.state.sel == 2) self.show_bo();
    if(self.state.sel == 3) self.show_avr();
  }

  show_ank() {
    var self = this;
    self.setState({rva: [], tot: {}, sel: 1 });

    var prm = {};
    //prm.req = "pms.hot_fkn.ank_list";
    prm.req = "pms.hot_fkn.ank_dt";
    prm.datum = this.state.dts;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        self.setState({ rva: ret.rca, tot: ret.tot }, function () {
            self.syncTb("rvaTable");
        });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e) {
        var txt = JSON.stringify(e);
    });
  }
  show_bo() {
    var self = this;
    self.setState({rva: [], tot: {}, sel: 2 });

    var prm = {};
    prm.req = "pms.hot_fkn.bo_dt";
    //prm.req = "pms.op.my_bo";
    prm.datum = this.state.dts;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        self.setState({ rva: ret.rca, tot: ret.tot }, function () {
            self.syncTb("rvaTable");
        });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e) {
        var txt = JSON.stringify(e);
    });
  }
  show_avr() {
    var self = this;
    self.setState({rva: [], tot: {}, sel: 3 });

    var prm = {};
    prm.req = "pms.hot_fkn.avr_dt";
    //prm.req = "pms.op.my_dep";
    prm.datum = this.state.dts;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        self.setState({ rva: ret.rca, tot: ret.tot }, function () {
            self.syncTb("rvaTable");
        });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e) {
        var txt = JSON.stringify(e);
    });
  }
    syncTb(tb){
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
        tdElements = tbel.getElementsByTagName('td');

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
            width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
   tb_sort(typ) {
      var self = this;
      //var rvs = self.state.rvs;
      var rva = self.state.rva;
      var sz = this.state.sz_val;

      if(typ == "ftg") {
        rva.sort((a,b) => (a.company > b.company) ? 1 : ((b.company > a.company) ? -1 : 0));
      }
      if(typ == "namn") {
        rva.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
      }
      if(typ == "boknr") {
        rva.sort((a,b) => (a.boknr > b.boknr) ? 1 : ((b.boknr > a.boknr) ? -1 : 0));
      }
      if(typ == "rumsnr") {
        rva.sort((a,b) => (a.rumsnr > b.rumsnr) ? 1 : ((b.rumsnr > a.rumsnr) ? -1 : 0));
      }
      if(typ == "rt") {
        rva.sort((a,b) => (a.rt > b.rt) ? 1 : ((b.rt > a.rt) ? -1 : 0));
      }
      if(typ == "pris") {
        rva.sort((a,b) => (a.pris > b.pris) ? 1 : ((b.pris > a.pris) ? -1 : 0));
      }
      if(typ == "avr") {
        rva.sort((a,b) => (a.avr > b.avr) ? 1 : ((b.avr > a.avr) ? -1 : 0));
      }
      if(typ == "anktid") {
        rva.sort((a,b) => (a.anktid > b.anktid) ? 1 : ((b.anktid > a.anktid) ? -1 : 0));
      }
      self.setState({ rva: rva, sortid: typ });
   }
  tb_filtr() {
      var self = this;
      var rvs = self.state.rvs;
      var sz = this.state.sz_val;

      if(sz.length < 1) {
        self.setState({ rva: rvs });
          return;
      }
      sz = sz.toUpperCase();
      var rva = rvs.filter(function(row) {
            var brc = false;
            if(row.company.toUpperCase().includes(sz)) brc = true;
            if(row.name.toUpperCase().includes(sz)) brc = true;
            return brc;
      });
      self.setState({rva: rva });
   }
  dt_dat(e) {
    //alert(JSON.stringify(e));
    var dts = format(e, "yyyy-MM-dd");
    this.setState({ dts: dts });
  }
  addday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_dt.current.updateAdd();
  }
  subday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_dt.current.updateSub();
  }
  sel_row_fkn(row, sel) {
      //alert(JSON.stringify(fkn));
    if(sel.fkn == "open") {
        //this.sel_gst(row);
    }
  }

  render() {
    return (
    <div className="web-app">

    <div className="flex-row">
        <div className="flex-row flex">
          <div className="dt-btn-01" onClick={() => this.subday() } ><FontAwesomeIcon icon="minus" size="1x" /></div>
          <Dt_Cal startdatum="2020-02-01" rp="1" ref={this.dt_dt} getValue={(e)=> this.dt_dat(e)} />
          <div className="dt-btn-01" onClick={() => this.addday() } ><FontAwesomeIcon icon="plus" size="1x" /></div>
        </div>
        <div className="flex-row">
        <div style={ this.selcss(1) } onClick={() => this.show_ank() }>ANKOMMANDE</div>
        <div style={ this.selcss(2) } onClick={() => this.show_bo() }>BOENDE</div>
        <div style={ this.selcss(3) } onClick={() => this.show_avr() }>AVRESANDE</div>
        </div>
        <div className="flex-row flex">
        <div className="flex"></div>
        <button className="btn btn-hw-blue w250" onClick={() => this.refresh() } aria-label="System" >
            UPPDATERA
        </button>
        </div>
    </div>
    <div className="divider_orange"></div>

    <div className="web-col">
        <div id="rvaTable">
        <table className="table table-bordered table-hover" style={{marginBottom: "0px"}}>
            <thead>
            <tr>
                <th style={ this.th_style("boknr") } onClick={() => this.tb_sort("boknr") }>Boknr</th>
                <th style={ this.th_style("rumsnr") } onClick={() => this.tb_sort("rumsnr") }>Ankomst</th>
                <th style={ this.th_style("rumsnr") } onClick={() => this.tb_sort("rumsnr") }>Avresa</th>
                <th style={ this.th_style("rumsnr") } onClick={() => this.tb_sort("rumsnr") }>Företag</th>
                <th style={ this.th_style("rumsnr") } onClick={() => this.tb_sort("rumsnr") }>Namn</th>
                <th style={ this.th_style("rumsnr") } onClick={() => this.tb_sort("rumsnr") }>Telefon</th>
                <th style={ this.th_style("rumsnr") } onClick={() => this.tb_sort("rumsnr") }># Rum</th>
                <th style={ this.th_style("rumsnr") } onClick={() => this.tb_sort("rumsnr") }># Gst</th>
                <th style={ this.th_style("rumsnr") } onClick={() => this.tb_sort("rumsnr") }>Status</th>
                <th style={{width:"16px"}} ></th>
            </tr>
            </thead>
        </table>
       <div className="tb-cdi" style={ this.tb_style() }>
       <table className="table table-bordered table-hover">
            <tbody>
            { this.state.rva.map((row, key) =>
            <tr key={key} onDoubleClick={() => this.rv_edit(row) }>
                <td>{ row.rblx0.r0boknr }</td>
                <td>{ utils.toDate( row.rblx0.r0ankdatum ) }</td>
                <td>{ utils.toDate( row.rblx0.r0avrdatum ) }</td>
                <td>{ row.resv00.Company }</td>
                <td>{ row.resv01.Name }</td>
                <td>{ row.resv01.Tfn }</td>
                <td>{ utils.toNum( row.rblx0.r0ankr ) }</td>
                <td>{ utils.toNum( row.rblx0.r0ankg ) }</td>
                <td>{ row.rblx0.r0status }</td>
                <td>
                    <Td_Sel items={ this.selitems } getValue={(e)=> this.sel_row_fkn(row, e)} />
                </td>
            </tr>
            )}
            </tbody>
        </table>
        </div>
        </div>
    </div>
    <div className="divider_orange"></div>
    <div className="web-row">
        <div className="txt_14 text-center flex">Antal Bokningar: { utils.toNum( this.state.tot.nrb ) }</div>
        <div className="txt_14 text-center flex">Antal Rum: { utils.toNum( this.state.tot.nrr ) }</div>
        <div className="txt_14 text-center flex">Antal Gäster: { utils.toNum( this.state.tot.nrg ) }</div>
    </div>

        { this.state.wnds.wnd_rvo ?
    <Pms_Rvo wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
        : null }
   </div>
  );
    }
}
export default PMS_BelAba;
