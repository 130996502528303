import React, { useState, useEffect, useContext } from 'react';
import {addDays, format} from "date-fns";

import ddo from '../../ddo.js'

import * as utils from "../../assets/utils";
import * as net from "../../lib/net";
import Td_Sel from "../../lib/ui/td_sel";
import EE_Per from "./per/ee_per";
import Dt_Cal from "../../lib/ui/dt_cal";
import {observer} from "mobx-react";
import EE_Dt from "./per/ee_dt";
import EE_Sel from "./per/ee_sel";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class TH_Per extends React.Component {
  constructor(props, context) {
    super(props);

    this.dt_fd = React.createRef();
    this.dt_td = React.createRef();

    this.store = props.store;
      this.ta = props.store.ta;

    this.state = {};
    this.state.tha = [];
    this.state.kro = {};
    this.state.aa = [];
    this.state.wnds = {};
    this.state.sortid = "";
    this.state.sortdir = 1;
    this.state.loading = true;

    this.state.tot = {};
    this.state.avd = "";

    this.state.today = new Date();
    var dts = format(this.state.today, "yyyy-MM-dd");
    //var tdat = addDays(this.state.today, 15);
    //var tdat = subDays(this.state.today, 15);
    let cdat = new Date();
    //let formatted_date = cdat.getFullYear() + "-" + (cdat.getMonth() + 1) + "-" + cdat.getDate();
    let formatted_date = cdat.getFullYear() + "-" + (cdat.getMonth() + 1) + "-" + "01";
    this.state.fdat = formatted_date;
    this.state.tdat = dts;

    if(this.ta && this.ta.base) {
      this.state.fdat = this.ta.base.fdat;
      this.state.tdat = this.ta.base.tdat;
    }

    this.selitems = [
      {fkn: 'open', text: 'ÖPPNA'},
      {fkn: 'prn', text: 'SKRIV UT'}
    ];
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
      ddo.cache.fkn = "";
  }
  componentDidMount() {
      ddo.cache.fkn = "PERIOD";
    this.per();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      css.overflow = "auto";
      return css;
  }
    th_style(id) {
        var css = {};
        css.cursor = "pointer";
        if(id == this.state.sortid) {
            if(this.state.sortdir) css.color = "#800";
            else css.color = "#080";
        }
        return css;
    }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }
    dt_fdat(e) {
        //alert(JSON.stringify(e));
        var dts = format(e, "yyyy-MM-dd");
        this.setState({ fdat: dts });
    }
    dt_tdat(e) {
        //alert(JSON.stringify(e));
        var dts = format(e, "yyyy-MM-dd");
        this.setState({ tdat: dts });
    }
    oneday(e) {
        //alert(JSON.stringify(e));
        //this.setState({ tdat: this.state.fdat });
        this.dt_td.current.updateDt(this.state.fdat);
    }
    addday(e) {
        //alert(JSON.stringify(e));
        //this.setState({ tdat: this.state.fdat });
        this.dt_fd.current.updateAdd();
        this.dt_td.current.updateAdd();
    }
    subday(e) {
        //alert(JSON.stringify(e));
        //this.setState({ tdat: this.state.fdat });
        this.dt_fd.current.updateSub();
        this.dt_td.current.updateSub();
    }
    change_avd(e) {
        let avd = e.target.value;
        //ddo.cache.avd = avd;
        this.setState({avd: avd});
    }

    ee_edit(row) {
        var self = this;
        var wnds = {};
        wnds.eeper = 1;
        wnds.fdat = this.state.fdat;
        wnds.tdat = this.state.tdat;
        wnds.eeo = row;
        this.setState({wnds: wnds});
    }
    tr_add() {
        var self = this;

        var wnds = {};
        wnds.tradd = 1;
        wnds.datum = this.state.fdat;
        wnds.cb = this.cb_tradd;
        this.setState({wnds: wnds});
    }
    cb_tradd(ctx, rsp) {
        if(rsp.ok == "000") {
            var enr = rsp.enr;
            var eeo = {};
            eeo.anstnummer = enr;

            var datum = ctx.state.fdat;
            datum = datum.replaceAll("-", "");
            var wnds = {};
            wnds.dtedit = 1;
            wnds.datum = datum;
            wnds.eeo = eeo;
            //alert(JSON.stringify(wnds));
            ctx.setState({wnds: wnds});
        }
        else ctx.setState({wnds: {}});
    }
    ee_dt(enr) {
        var self = this;
        var eeo = {};
        eeo.anstnummer = enr;

        var wnds = {};
        wnds.dtedit = 1;
        wnds.datum = this.state.fdat;
        wnds.eeo = eeo;
        this.setState({wnds: wnds});
    }
    fkn_close() {
        this.setState({edit: false });
        //this.props.history.push("cas");
        //this.login_srv(uid, pwd);
    }

  per() {
    var self = this;
    self.setState({ tha: [], tot: {}, loading: true });

    var prm = {};
    //prm.req = "ta.ta_th.tps_ft";
      prm.req = "ta.ta_th.per_ft";
      //prm.req = "ta.ta_lib.per_ft";
    prm.fdat = this.state.fdat;
    prm.tdat = this.state.tdat;
    //prm.avd = this.state.avd;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));
        if(ret.ok != "000") {
            return;
        }
        self.setState({ tha: ret.rca, tot: ret.tot, loading: false }, function() {
            self.syncTb("perTable");
        });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
    syncTb(tb) {
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
            tdElements = tbel.getElementsByTagName('td');
        if(tdElements.length < 1 ) return;

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
                width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
    tb_sort(typ) {
        var self = this;
        //var rvs = self.state.rvs;
        var tha = self.state.tha;
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;

        if(sortid != typ) sortdir = 0;
        else {
            if(sortdir) sortdir = 0;
            else sortdir = 1;
        }

        if(typ == "anstnummer") {
            if(sortdir) tha.sort((a,b) => (a[typ] < b[typ]) ? 1 : ((b[typ] < a[typ]) ? -1 : 0));
            else tha.sort((a,b) => (a[typ] > b[typ]) ? 1 : ((b[typ] > a[typ]) ? -1 : 0));
        }
        if(typ == "avd") {
            var atyp = "avdelning";
            if(sortdir) tha.sort((a,b) => (a.peo.p1[atyp] < b.peo.p1[atyp]) ? 1 : ((b.peo.p1[atyp] < a.peo.p1[atyp]) ? -1 : 0));
            else tha.sort((a,b) => (a.peo.p1[atyp] > b.peo.p1[atyp]) ? 1 : ((b.peo.p1[atyp] > a.peo.p1[atyp]) ? -1 : 0));
        }
        if(typ == "namn") {
            if(sortdir) tha.sort((a,b) => (a.peo.p1[typ] < b.peo.p1[typ]) ? 1 : ((b.peo.p1[typ] < a.peo.p1[typ]) ? -1 : 0));
            else tha.sort((a,b) => (a.peo.p1[typ] > b.peo.p1[typ]) ? 1 : ((b.peo.p1[typ] > a.peo.p1[typ]) ? -1 : 0));
        }
        self.setState({ tha: tha, sortid: typ, sortdir: sortdir });
    }
    th_icon(typ) {
        var cls = "";
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;
        if(sortid == typ)
        {
            if(sortdir) cls = "fa fa-caret-up";
            else cls = "fa fa-caret-down";
        }
        return cls;
    }
    th_show() {
        var a = [];
        var avd = this.state.avd;
        var row = {};
        for(row of this.state.tha) {
            if(avd.length > 0) {
                if(row.peo.p1.avdelning != avd) continue;
            }
            a.push( row );
        }
        return a;
    }
    show_netto() {
        //utils.toMinTid(this.state.tot.netto)
        var belopp = 0;
        var avd = this.state.avd;
        var row = {};
        for(row of this.state.tha) {
            if(avd.length > 0) {
                if(row.peo.p1.avdelning != avd) continue;
            }
            belopp = belopp + (row.netto * 1);
        }
        belopp = utils.toMinTid( belopp.toString() );
        return belopp;
    }
    show_peo(row, field) {
      var val = "";
      if(row.peo) {
          if(row.peo.p1) {
              val = row.peo.p1[field];
          }
      }
      return val;
    }
    show_brutto() {
        //utils.toMinTid(this.state.tot.brutto)
        var belopp = 0;
        var avd = this.state.avd;
        var row = {};
        for(row of this.state.tha) {
            if(avd.length > 0) {
                if(row.peo.p1.avdelning != avd) continue;
            }
            belopp = belopp + (row.brutto * 1);
        }
        belopp = utils.toMinTid( belopp.toString() );
        return belopp;
    }
  render() {
    return (
    <div className="web-app">

    <div className="flex-row flex-space-between">
        <select className="form-control w250" placeholder="Välj avdelning" onChange={(e) => this.change_avd(e)}>
            <option value="">ALLA AVDELNINGAR</option>
            { this.props.store.ta.dpa.map((row, key) =>
                <option key={key} value={row.avdelning}>{row.avdelning} - {utils.toLang( row.namn )}</option>
            )}
        </select>
        <div className="flex"></div>
        <div className="flex-row">
            <div className="dt-btn-01" onClick={() => this.subday() } ><FontAwesomeIcon icon="minus" size="1x" /></div>
            <Dt_Cal dts={ this.state.fdat } rp="1" ref={this.dt_fd} getValue={(e)=> this.dt_fdat(e)} />
            <div className="dt-btn-01" onClick={() => this.oneday() } >1</div>
            <Dt_Cal dts={ this.state.tdat } rp="1" ref={this.dt_td} getValue={(e)=> this.dt_tdat(e)} />
            <div className="dt-btn-01" onClick={() => this.addday() } ><FontAwesomeIcon icon="plus" size="1x" /></div>
        </div>
        <div className="flex"></div>
        <button className="btn btn-hw-blue w150" onClick={() => this.tr_add() } aria-label="System" >
            LÄGG TILL
        </button>
        <button className="btn btn-hw-blue w150" onClick={() => this.per() } aria-label="System" >
            UPPDATERA
        </button>
    </div>
    <div className="divider_blue"></div>

    { this.state.loading ? <div className="flex flex-col flex-center"><FontAwesomeIcon icon="spinner" spin size="4x" /></div> : null }
    { !this.state.loading ?
    <div id="perTable" className="web-col">
        <table className="table table-bordered table-hover tb " style={{marginBottom: "0px"}}>
            <thead>
            <tr>
                <th style={ this.th_style("anstnummer") } onClick={() => this.tb_sort("anstnummer") }>Anstnr <i className={ this.th_icon("anstnummer") }></i></th>
                <th style={ this.th_style("namn") } onClick={() => this.tb_sort("namn") }>Namn <i className={ this.th_icon("namn") }></i></th>
                <th style={ this.th_style("avd") } onClick={() => this.tb_sort("avd") }>Avdelning <i className={ this.th_icon("avd") }></i></th>
                <th style={ this.th_style("nreg") } onClick={() => this.tb_sort("nreg") }>Regs <i className={ this.th_icon("nreg") }></i></th>
                <th style={ this.th_style("ndag") } onClick={() => this.tb_sort("ndag") }>Dagar <i className={ this.th_icon("ndag") }></i></th>
                <th style={ this.th_style("schematid") } onClick={() => this.tb_sort("schematid") }>Schematid <i className={ this.th_icon("schematid") }></i></th>
                <th style={ this.th_style("netto") } onClick={() => this.tb_sort("netto") }>Nettotid <i className={ this.th_icon("netto") }></i></th>
                <th style={ this.th_style("rast") } onClick={() => this.tb_sort("rast") }>Rast <i className={ this.th_icon("rast") }></i></th>
                <th style={ this.th_style("brutto") } onClick={() => this.tb_sort("brutto") }>Bruttotid <i className={ this.th_icon("brutto") }></i></th>
                <th style={ this.th_style("ob1") } onClick={() => this.tb_sort("ob1") }>OB1 <i className={ this.th_icon("ob1") }></i></th>
                <th style={ this.th_style("ob2") } onClick={() => this.tb_sort("ob2") }>OB2 <i className={ this.th_icon("ob2") }></i></th>
                <th style={ this.th_style("reg_ok") } onClick={() => this.tb_sort("reg_ok") }>Status <i className={ this.th_icon("reg_ok") }></i></th>
                <th></th>
            </tr>
            </thead>
        </table>
       <div className="tb-cdi" style={ this.tb_style() }>
       <table className="table table-bordered table-hover tb">
            <tbody>
            { this.th_show().map((row, key) =>
            <tr key={key} onDoubleClick={() => this.ee_edit(row) }>
                <td className="text-center">{ utils.toNum( row.anstnummer ) }</td>
                <td>{ utils.toLang( this.show_peo(row, "namn") ) }</td>
                <td className="text-center">{ utils.toLang( this.show_peo(row, "avdelning") ) }</td>
                <td className="text-center">{ row.nreg }</td>
                <td className="text-center">{ row.ndag }</td>
                <td className="text-center">{ row.schematid }</td>
                <td className="text-center">{ utils.toMinTid( row.netto ) }</td>
                <td className="text-center">{ utils.toMinTid( row.rast ) }</td>
                <td className="text-center">{ utils.toMinTid( row.brutto ) }</td>
                <td className="text-center">{ utils.toMinTid( row.ob1 ) }</td>
                <td className="text-center">{ utils.toMinTid( row.ob2 ) }</td>
                <td className="text-center">{ row.reg_ok }</td>
                <td>
                    <Td_Sel items={ this.selitems } getValue={(e)=> this.sel_row_fkn(row, e)} />
                </td>
            </tr>
            )}
            </tbody>
        </table>
        </div>
        </div>
    : null }
        <div className="divider_blue"></div>
        <div className="flex-row">
            <div className="txt_18">TOTAL NETTO: { this.show_netto() }</div>
            <div className="flex"></div>
            <div className="flex"></div>
            <div className="txt_18">TOTAL BRUTTO: { this.show_brutto() }</div>
        </div>

        { this.state.wnds.eeper ? <EE_Per wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.wnds.dtedit ? <EE_Dt wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.wnds.tradd ? <EE_Sel wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }

   </div>
  );
    }
}
export default observer(TH_Per);
