import React, { useState, useEffect, useContext } from 'react';
import * as fkn from "../lib/fkn";
import SDOContext from '../sdo.js'
import {Route, Routes} from "react-router-dom";
//import ADOContext from "../ado";

import Eko_Home from './eko/home.js';
import Eko_Period from './eko/period.js';
import Eko_Dr from './eko/dr.js';
import Eko_Konto from './eko/konto.js';
import Eko_Kassa from './eko/kassa.js';
import {withRouter} from "../lib/react/withRouter";
import Bo_Home from "./bo/home";
import sso from "../sso";

function pg_home() {

    var html =
    <div className="web-content">
      Hem
    </div>
    ;
    return html;
}

class Red_Eko extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, deps: [] };
      var po = {};
      po.konto = { "fid": "f06", "url": "/pos/bo/konto", "name": "Kontoplan", "active": false };
      po.period = { "fid": "f06", "url": "/red/eko/period", "name": "Period", "active": false };
      po.dr = { "fid": "f06", "url": "/red/eko/dr", "name": "Dagrapport", "active": false };
      po.konto = { "fid": "f06", "url": "/red/eko/konto", "name": "Konto", "active": false };
      po.kassa = { "fid": "f06", "url": "/red/eko/kassa", "name": "Kassa", "active": false };
      this.state.po = po;
      this.state.pa = [];
      this.state.pa = Object.values(po);
  }
    fknGo(row) {
        sso.seo.sfkn = row;
        this.props.navigate(row.url);
    }
    br_css(row) {
        var css = {};
        if(sso.seo.sfkn && (row.url == sso.seo.sfkn.url)) css.color = "#080";
        //var brc = false;
        //if(row.url == sbo.state.url) brc = true;
        return css;
    }

  render() {
    return (
        <div className="web-app">
            { this.props.store.cfg.bread ?
                <div className="web-bread">
                    <div className="breadcrumb">
                        { this.state.pa.map((row, key) =>
                            <div key={key} className="breadcrumb-item" style={this.br_css(row)} onClick={() => this.fknGo(row)}>{row.name}</div>
                        )}
                        <div className="flex"></div>
                        <div className="cdigreen">{ sso.seo.sfkn ? sso.seo.sfkn.name : null }</div>
                    </div>
                </div>
                : null }
        <Routes>
          <Route path="/xx" component={pg_home} />
            <Route path="period" element={ <Eko_Period store={this.webStore} />} />
            <Route path="dr" element={ <Eko_Dr store={this.webStore} />} />
            <Route path="konto" element={ <Eko_Konto store={this.webStore} />} />
            <Route path="kassa" element={ <Eko_Kassa store={this.webStore} />} />
            <Route path="*" element={ <Eko_Home store={this.webStore} />} />
        </Routes>
    </div>

  );
}
}
//Sys.contextType = ADOContext;

export default withRouter(Red_Eko);
