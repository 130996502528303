import React from 'react';

import * as net from "../lib/net";
import LoginDlg from "./dlg/login_dlg";
import {withRouter} from "../lib/react/withRouter";

function web01() {

    var html =
    <div className="web-content">
    </div>
    ;
    return html;
}

class Web_Demo extends React.Component {
    //var sdo = useContext(SDO);
    constructor(props, context) {
        super(props);

        this.webStore = props.store;
        this.state = { pgid: 11, stat: [] };
        this.state.open = false;
        this.state.pdfopen = false;
        this.state.xxopen = false;
        this.state.pdf_uri = "";
        this.state.seldate = new Date('2020-03-24T13:11:54');
        this.state.row = null;
        this.state.pageNumber = 1;
        this.state.numPages = null;
        this.state.login = false;
        //context.posrend = this;
    }

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages });
    }
   row_css(row) {
  	    var css = {};
		if(this.state.row) {
		if(this.state.row.ank == row.ank) {
			css.background = "#080";
			css.color = "#fff";
		}
		}
		return css;
    }
    login() {
        this.setState({login: true });
        //this.props.history.push("pos");
    }
    dologin(uid, pwd) {
        this.setState({login: false });
        //this.props.history.push("cas");
        this.login_srv(uid, pwd);
    }
    login_srv(uid, pwd) {
        var self = this;
		var prm = {};
		prm.uid = uid;
		prm.pwd = pwd;
		prm.token = "";

        //gda.wndLoading("Hämtar");
        net.gcs_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret));

            var o = {};
            //o.deps = ret.rco;
            //alert(JSON.stringify(o));
            //self.setState({tra: ret.rca });
            //toastr.success("OK", "SAPI");
            if(ret.ok != "000") {
                alert(JSON.stringify(ret));
                return;
            }

            var seo = ret.seo;
            alert(JSON.stringify(seo));
            //alert(JSON.stringify(seo.auto));
            self.props.navigate("cas");
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
            alert(JSON.stringify(e));
            //gda.wndError(txt);
        });
    }
    order(typ) {
        //this.setState({login: true });
        this.props.navigate("ecom/m");
    }
    subsys(sub) {
        //this.setState({login: true });
        this.props.navigate(sub);
    }
    homepage() {
        //this.setState({login: true });
        this.props.navigate("pub");
    }
    render() {
        const { pageNumber, numPages } = this.state;
        var stat = [];
        var keys = Object.keys(this.state.stat);
        for (var key of keys) {
            var row = this.state.stat[key];
            stat.push(row);
        }
        const handleDateChange = date => {
            this.setState({seldate: date });
        };

        return (
            <div className="web-app">
            <div className="web-title flex-row flex-space-between">
                <h6 style={{ padding:"5px 10px" }}>SVERIGEKASSAN</h6>
                <button className="btn btn-hw-blue w150 ripple" onClick={() => this.login() } aria-label="System" >
                    Logga in
                </button>
            </div>

            <div className="web-body">
            <div className="flex-col flex-vcenter">
                <img className="logo-img" src={require('./img/svepos.png')} />
            </div>
            <div className="web-s1 flex-col flex-vcenter">
                <h6>Order System</h6>
                <button className="btn btn-hw-blue w150 ripple" onClick={() => this.order('unit') } aria-label="System" >
                    Order - Enhet
                </button>
                <button className="btn btn-hw-blue w150 ripple" onClick={() => this.order('central') } aria-label="System" >
                    Order - Central
                </button>
            </div>
            <div className="divider_orange"></div>
            <div className="web-s1 flex-col flex-vcenter">
                <h6>Kassa System</h6>
                <button className="btn btn-hw-blue w150 ripple" onClick={() => this.subsys('pos') } aria-label="System" >
                    Kassa - Demo
                </button>
            </div>
            <div className="divider_orange"></div>
            <div className="web-s1 flex-col flex-vcenter">
                <h6>Sverigekassan</h6>
                <button className="btn btn-hw-green w150 ripple" onClick={() => this.homepage() } aria-label="System" >
                    Hemsida
                </button>
            </div>
            <div className="divider_orange"></div>

            </div>
            <LoginDlg show={this.state.login} ctx={this} backdrop={true}/>
           </div>
        );
    }

}
//Sys.contextType = ADOContext;

export default withRouter(Web_Demo);
