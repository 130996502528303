import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";
import Fak_Open from "./fkn/fak_open";
import * as utils from "../../assets/utils";
import Td_Sel from "../../lib/ui/td_sel";
import Wnd_Error from "../../lib/ui/wnd_error";
import Wnd_Ok from "../../lib/ui/wnd_ok";
import Wnd_Confirm from "../../lib/ui/wnd_confirm";
import Dd_Sel from "../../lib/ui/dd_sel";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Rsk_Rsk extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, tra: [] };
    this.state.seldate = new Date();
    this.state.avd = "01";
    this.state.rss = [];
    this.state.rsa = [];
    this.state.aa = [];
      this.state.fao = {};
      this.state.tot = {};
    this.state.cols = {};
      this.state.msg = {};
      this.state.wnds = {};
      this.state.sz_val = "";

      this.selitems = [
          {fkn: 'open', text: 'ÖPPNA'},
          {fkn: 'cred', text: 'KREDITERA'}
      ];
      this.fknitems = [
        {fkn: 'tax_rebuild', text: 'ÅTERSKAPA MOMS'},
        {fkn: 'print', text: 'SKRIVUT RESKONTRA'}
    ];
}
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.rsk();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 255;
      css.overflow = "auto";
      return css;
  }
    th_style(id) {
        var css = {};
        css.cursor = "pointer";
        if(id == this.state.sortid) {
            if(this.state.sortdir) css.color = "#800";
            else css.color = "#080";
        }
        return css;
    }
    row_style(row) {
        var css = {};
        if(row.wfak00.kredit == "1") {
            css.color = "#800";
        }
        return css;
    }
    fak_belopp(row, belopp) {
        var amount = "";
        amount = utils.toPris( belopp );
        if(row.wfak00.kredit == "1") {
            amount = "-" + amount;
        }
        return amount;
    }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

  fak_open(row) {
      var self = this;
      //alert(JSON.stringify( row ));

      var wnds = {};
      wnds.fakopen = 1;
      wnds.faknr = row.wfak00.faknr;
      wnds.wfh = row;
      wnds.cb = this.cb_rsk;
      this.setState({ wnds: wnds });
  }

    cb_rsk(ctx) {
        ctx.rsk();
        ctx.setState({ wnds: {} });
    }

    rsk() {
    var self = this;
    var prm = {};
    prm.req = "fakt.fakt_rsk.rsk";
    //prm.avd = this.state.avd;
    //prm.fplu = datum;
    //prm.tplu = datum;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        var tot = {};
        tot.antal = 0;
        tot.belopp = 0;
        for(var row of ret.rco) {
            tot.antal = tot.antal + 1;
            if(row.wfak00.belopp && (row.wfak00.belopp != null)) {
                var belopp = row.wfak00.belopp.toString();
                if(belopp.indexOf("-") != -1) {
                    belopp = belopp.replace("-", "");
                    //alert(JSON.stringify(belopp))
                }
                tot.belopp = tot.belopp + (belopp * 1);
            }
            //alert(JSON.stringify(row.belopp));
        }
        //alert(JSON.stringify(tot.belopp));
        self.setState({rsa: ret.rco, rss: ret.rco, tot: tot }, function() {
            self.syncTb("rskTable");
        });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
    syncTb(tb){
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
            tdElements = tbel.getElementsByTagName('td');
        if(tdElements.length < 1 ) return;

        for (let i = 0; i < thElements.length; i++) {
            if(!tdElements[i]) continue;
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
                width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
    fak_cred(row) {
        var self = this;

        var prm = {};
        prm.req = "fakt.fakt_fak.fak_ext_cred";
        prm.faktyp = "E";
        prm.faknr = row.wfak00.faknr;
        prm.wfak00 = row.wfak00;

        net.sio_req(prm)
            .then(function(ret) {
                //alert(JSON.stringify(ret.rco));

                var msg = { info: false, error: true, text: "Krediterad ..."};
                self.setState({msg: msg });
                setTimeout(function(){
                    self.setState({msg: {} });
                    self.rsk();
                   return;
                }, 1000);
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    tax_upd() {
        var self = this;

        var prm = {};
        prm.req = "fakt.fakt_pos.fak_tax_upd";
        prm.faktyp = "E";
        //prm.faknr = row.wfak00.faknr;

        net.sio_req(prm)
        .then(function(ret) {
            //alert(JSON.stringify(ret.ok));

            var msg = { info: false, error: true, text: "Uppdaterat ..."};
            self.setState({msg: msg });
            setTimeout(function(){
                self.setState({msg: {} });
                //self.rsk();
                return;
            }, 1000);
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }

    sel_row_fkn(row, sel) {
        //alert(JSON.stringify(fkn));
        if(sel.fkn == "open") {
            this.fak_open(row);
        }
        if(sel.fkn == "cred") {
            this.fak_cred(row);
        }
    }
    sz_style() {
        var css = {};
        css.height = "40px";
        css.lineHeight = "40px";
        css.padding = "0px 10px";
        css.fontSize = "12px";
        css.cursor = "pointer";
        return css;
    }

    sz_keydown (e) {
    //e.preventDefault();
      const key = e.key;
      const keyCode = e.keyCode;

      if(keyCode == 13) {
          this.tb_filtr();
      };
      if(keyCode == 27) {
          this.setState({sz_val: ""});
      };
  }
  sz_change (e) {
      const name = e.target.name;
      const value = e.target.value;
      this.setState({sz_val: value});
      //setState({[name]: value});
  }
  tb_filtr() {
    var self = this;
    var rss = self.state.rss;
    var sz = this.state.sz_val;

    if(sz.length < 1) {
      self.setState({ rsa: rss });
        return;
    }
    sz = sz.toUpperCase();
    var rsa = rss.filter(function(row) {
          var brc = false;
          if(row.wfak00.faknr.toUpperCase().includes(sz)) brc = true;
          if(row.wfak00.kund.toUpperCase().includes(sz)) brc = true;
          if(row.wfak00.company.toUpperCase().includes(sz)) brc = true;
          if(row.wfak00.belopp.toUpperCase().includes(sz)) brc = true;
          if(row.wfak00.fakdatum.toUpperCase().includes(sz)) brc = true;
          return brc;
    });
    self.setState({rsa: rsa });
    }
    sel_fkn(sel) {
        //alert(JSON.stringify(fkn));
        if(sel.fkn == "tax_rebuild") {
            this.tax_upd();
        }
    }

  render() {
    return (
    <div className="web-app">

    <div className="flex-row">
        <div className="label_h03">Reskontra</div>
        <div className="flex"></div>
        <input className="txt sztxt" value={this.state.sz_val} placeholder="Ange söktext..."  style={ this.sz_style() } onChange={(e) => this.sz_change(e)} onKeyDown={(e) => this.sz_keydown(e)} />
        <button className="btn btn-hw-blue w150 hide" onClick={() => this.tax_upd() } aria-label="System" >
            Moms
        </button>
        <button className="btn btn-hw-blue w150" onClick={() => this.rsk() } aria-label="System" >
            Visa
        </button>
        <Dd_Sel items={ this.fknitems } cls="web-top-icon" getValue={(e)=> this.sel_fkn(e)} />
    </div>
    <div className="divider"></div>

    <div className="web-col">
        <div id="rskTable">
        <table className="table table-bordered table-hover tb" style={{marginBottom: "0px"}}>
            <thead>
            <tr>
                <th style={ this.th_style("faknr" ) }>Faknr</th>
                <th style={ this.th_style("kund" ) }>Kund</th>
                <th style={ this.th_style("company" ) }>Företag</th>
                <th style={ this.th_style("belopp" ) }>Belopp</th>
                <th style={ this.th_style("moms" ) }>Moms</th>
                <th style={ this.th_style("brutto" ) }>Ex moms</th>
                <th style={ this.th_style("fakdatum" ) }>Fakturadatum</th>
                <th style={ this.th_style("ffdatum" ) }>Förfallodatum</th>
                <th style={ this.th_style("days" ) }>Dagar</th>
                <th style={ this.th_style("status" ) }>Status</th>
                <th style={ this.th_style("" ) }></th>
            </tr>
            </thead>
        </table>
        <div style={ this.tb_style() }>
       <table className="table table-bordered table-hover tb">
            <tbody>
            { this.state.rsa.map((row, key) =>
            <tr key={ key } style={ this.row_style(row) } onDoubleClick={() => this.fak_open(row) }>
                <td>{ utils.toNum( row.wfak00.faknr ) }</td>
                <td>{ utils.toNum( row.wfak00.kund ) }</td>
                <td>{ utils.toLang( row.wfak00.company ) }</td>
                <td className="txt_right">{ this.fak_belopp( row, row.wfak00.belopp ) }</td>
                <td className="txt_right">{ this.fak_belopp( row, row.wfak00.moms ) }</td>
                <td className="txt_right">{ this.fak_belopp( row, ( (row.wfak00.belopp * 1) - (row.wfak00.moms * 1)).toString() ) }</td>
                <td className="txt_center">{ utils.toDate( row.wfak00.fakdatum ) }</td>
                <td className="txt_center">{ utils.toDate( row.wfak00.ffdatum ) }</td>
                <td className="txt_center">{ utils.toNum( row.wfak00.days ) }</td>
                <td>{ row.wfak00.status }</td>
                <td>
                    <Td_Sel items={ this.selitems } getValue={(e)=> this.sel_row_fkn(row, e)} />
                </td>
            </tr>
            )}
            </tbody>
        </table>
        </div>
        </div>
    </div>
        <div className="flex-row flex-space-between">
            <div className="flex-col">
                <div className="cdi-label">Antal</div>
                <div className="">{ utils.toNum( this.state.tot.antal ) }</div>
            </div>
            <div className="flex-col">
            </div>
            <div className="flex-col">
                <div className="cdi-label">Summa</div>
                <div className="">{ utils.toPris( this.state.tot.belopp ) }</div>
            </div>
        </div>

        { this.state.wnds.fakopen ? <Fak_Open wnds={this.state.wnds} ctx={this} backdrop={true}/> : null }
        <Wnd_Error msg={this.state.msg} ctx={this} />
        <Wnd_Ok msg={this.state.msg} ctx={this} />
        <Wnd_Confirm msg={this.state.msg} ctx={this} />
    </div>
  );
}
}
export default Rsk_Rsk;
