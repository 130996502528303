import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../../lib/net";
import Fr_Input from "../../../lib/ui/fr_input";
import Fr_Select from "../../../lib/ui/fr_select";

class Vgrp_Edit extends React.Component {
  constructor(props, context) {
    super(props);

    this.pos = props.store.pos;
    this.state = {};
    this.state.mka = [];

    this.state.ynq = [
                {id: '0', text: 'NEJ'},
                {id: '1', text: 'JA'}
            ];

    this.state.vgo = {};
    if(props.wnds.vgo) this.state.vgo = props.wnds.vgo;

    this.state.v01 = "";
    this.state.v02 = "";
    this.state.v03 = "";
      if(this.state.vgo.v0moms){
          var v0moms = this.state.vgo.v0moms;
          this.state.v01 = v0moms.substr(0, 2);
          this.state.v02 = v0moms.substr(2, 2);
          this.state.v03 = v0moms.substr(4, 2);
      }
      if(this.state.vgo.var03){
          this.state.vgo.var03.v3ansvar = this.state.vgo.var03.v3ansvar.trim();
          this.state.vgo.var03.v3verksamhet = this.state.vgo.var03.v3verksamhet.trim();
          this.state.vgo.var03.v3aktivitet = this.state.vgo.var03.v3aktivitet.trim();
          this.state.vgo.var03.v3projekt = this.state.vgo.var03.v3projekt.trim();
          this.state.vgo.var03.v3objekt = this.state.vgo.var03.v3objekt.trim();
          this.state.vgo.var03.v3fridel = this.state.vgo.var03.v3fridel.trim();
      }
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    tab_style(tabid) {
      var css = {};
      css.display = "none";
      if(this.state.tabval === tabid) css.display = "block";
      return css;
    }
    tab_head(tabid) {
      var css = {};
      css.background = "#fff";
      css.color = "#000";
      if(this.state.tabval === tabid) {
          css.background = "#080";
          css.color = "#fff";
      }
      return css;
    }
    grp_style(row) {
      var css = {};
      css.background = "#008";
      if(this.state.gnr === row.vgrupp) css.background = "#080";
      return css;
    }

    formchange (e) {
      const name = e.target.name;
      const value = e.target.value;
      var pbo = this.state.pbo;
      var na = name.split(".");
      pbo[na[0]][na[1]] = value;
      this.setState({pbo: pbo});
      //setState({[name]: value});
    }

    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    wnd_close = () => {
		this.props.ctx.setState({ wnds: {} });
	};
    handleChange = (event, newValue) => {
        //alert(newValue);
        this.setState({ix: newValue});
      };

  val_color(id, val) {
      var css = {};
      //alert(JSON.stringify(val))
      this.ctrl_fg.updateState(val.hex);
      this.setState({ bgfarg: val.hex });
      return css;
  }
  val_save(name, value) {
      var vgo = this.state.vgo;
      var na = name.split(".");
      vgo[na[0]][na[1]] = value;
      //bto[na[1]] = value;
      this.setState({vgo: vgo});
      return;
  }
  val_state_save(name, value) {
      var state = this.state;
      state[name] = value;
      this.setState(state);
      return;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  vgrp_save() {
    var self = this;

    var vgo = this.state.vgo;
    var v0moms = this.state.v01.toString().tonum(2) + this.state.v02.toString().tonum(2) + this.state.v03.toString().tonum(2);
    vgo.var00.v0moms = v0moms;
    self.setState({ vgo: vgo }, function(ret){
        this.vgrp_save_do();
    });
  }
  vgrp_save_do() {
    var self = this;

      var var00 = this.state.vgo.var00;
      var var03 = this.state.vgo.var03;

    var prm = {};
    prm.req = "pos.pos_pg.vgrp_upd";
    //prm.vgo = this.state.vgo;
      prm.var00 = var00;
      prm.var03 = var03;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        //self.setState({paa: ret.rco });
		self.props.ctx.setState({ wnds: {} });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "Varugrupp";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        <div className="m2_backdrop" />
        <div className="m2_dlg mx_70 flex-col" style={ this.dlg_style() } draggable="true" >
        <div className="mx_content flex">

            <div className="flex-row" style={{padding:"5px",height:"35px"}}>
                    <div className="flex-col flex">
                        <div>VARUGRUPP:</div>
                    </div>
                    <div className="flex-col">
                        <div>UPPDATERA</div>
                    </div>
            </div>
            <div className="divider_orange"></div>

            <form className="cdi_f03">
            <div className="flex-row">
                    <Fr_Input label="Avd" className="text-left w350" text={this.state.vgo.var00.v0kstns} getValue={(e)=> this.val_save("var00.v0kstns", e) } />
                    <Fr_Input label="Varugrupp" className="text-left w350" text={this.state.vgo.var00.v0katnr} getValue={(e)=> this.val_save("var00.v0katnr", e) } />
            </div>
            <div className="flex-row">
                    <Fr_Input label="Text" className="text-left w350" text={this.state.vgo.var00.v0text} getValue={(e)=> this.val_save("var00.v0text", e) } />
                    <Fr_Input label="Sortering" className="text-left w350" text={this.state.vgo.var00.v0ordn} getValue={(e)=> this.val_save("var00.v0ordn", e) } />
            </div>
            <div className="divider_green"></div>
            <div className="flex-row">
                    <Fr_Input label="Momskod" className="text-left w250" text={ this.state.v01 } getValue={(e)=> this.val_state_save("v01", e) } />
                    <Fr_Input label="Konto" className="text-left w250" text={this.state.vgo.var00.v0kontonr1} getValue={(e)=> this.val_save("var00.v0kontonr1", e) } />
            </div>
            <div className="flex-row">
                    <Fr_Input label="Momskod" className="text-left w250" text={this.state.v02} getValue={(e)=> this.val_state_save("v02", e) } />
                    <Fr_Input label="Konto" className="text-left w250" text={this.state.vgo.var00.v0kontonr2} getValue={(e)=> this.val_save("var00.v0kontonr2", e) } />
            </div>
            <div className="flex-row">
                    <Fr_Input label="Momskod" className="text-left w250" text={this.state.v03} getValue={(e)=> this.val_state_save("v03", e) } />
                    <Fr_Input label="Konto" className="text-left w250" text={this.state.vgo.var00.v0kontonr3} getValue={(e)=> this.val_save("var00.v0kontonr3", e) } />
            </div>
            <div className="divider_green"></div>
            <div className="flex-row">
                    <Fr_Input label="Kostnadsställe" className="text-left w250" text={this.state.vgo.var00.v0kst} getValue={(e)=> this.val_save("var00.v0kst", e) } />
                    <Fr_Input label="Projekt" className="text-left w250" text={this.state.vgo.var00.v0projekt} getValue={(e)=> this.val_save("var00.v0projekt", e) } />
            </div>

                <div className="divider_green"></div>
                <div className="flex-row">
                    <Fr_Input label="Ansvar" className="text-left w250" text={this.state.vgo.var03.v3ansvar} getValue={(e)=> this.val_save("var03.v3ansvar", e) } />
                    <Fr_Input label="Verksamhet" className="text-left w250" text={this.state.vgo.var03.v3verksamhet} getValue={(e)=> this.val_save("var03.v3verksamhet", e) } />
                </div>
                <div className="flex-row">
                    <Fr_Input label="Aktivitet" className="text-left w250" text={this.state.vgo.var03.v3aktivitet} getValue={(e)=> this.val_save("var03.v3aktivitet", e) } />
                    <Fr_Input label="Projekt" className="text-left w250" text={this.state.vgo.var03.v3projekt} getValue={(e)=> this.val_save("var03.v3projekt", e) } />
                </div>
                <div className="flex-row">
                    <Fr_Input label="Objekt" className="text-left w250" text={this.state.vgo.var03.v3objekt} getValue={(e)=> this.val_save("var03.v3objekt", e) } />
                    <Fr_Input label="Fridel" className="text-left w250" text={this.state.vgo.var03.v3fridel} getValue={(e)=> this.val_save("var03.v3fridel", e) } />
                </div>
0
            <div className="divider_green"></div>
            <div className="flex-row">
                    <Fr_Input label="Ej Rabatt" className="text-left w150" text={this.state.vgo.var00.v0ejrab} getValue={(e)=> this.val_save("var00.v0ejrab", e) } />
                    <Fr_Input label="Ej Takeaway" className="text-left w150" text={this.state.vgo.var00.v0ejtawy} getValue={(e)=> this.val_save("var00.v0ejtawy", e) } />
            </div>
            </form>

        </div>
        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250 ripple" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <button className="btn btn-hw-red w250 ripple" onClick={() => this.wnd_close() } >
                TA BORT
            </button>
            <div className="flex-row">
            <button className="btn btn-hw-red w250 ripple" onClick={() => this.vgrp_save() } >
                SPARA
            </button>
            </div>
        </div>
      </div>
      </div>
	;
	return html;
  };
}

export default Vgrp_Edit;