import React, { useState, useEffect, useContext } from 'react';
import { observer } from 'mobx-react'

import Fsg_dlg from "./rps/fsg_dlg";
import Bet_dlg from "./rps/bet_dlg";
import HH_Dlg from "./rps/hh_dlg";
import Hotel_Dlg from "./rps/hotel_dlg";
import Term_Dlg from "./rps/term_dlg";
import Tips_Dlg from "./rps/tips_dlg";
import Tp_Dlg from "./rps/tp_dlg";

import Xrecov_Dlg from "./dlg/xrecov_dlg";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Pos_Rps extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, tra: [] };
    this.state.seldate = new Date();
    this.state.avd = "01";
    this.state.paa = [];
    this.state.aa = [];
    //context.posrend = this;
    this.state.pdf = 0;
    this.state.rptyp = "";
    this.state.xrecov = 0;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 480;
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

  rp_open(typ) {
    var self = this;
    this.setState({ rptyp: typ });
  }

  render() {
    return (
    <div className="web-p00 flex flex-col">
        <div className="">
            RAPPORTER
        </div>

    <div className="web-p01 flex">

    <div className="web-row">
    <div className="web-col flex">
        <div className="web-btn" onClick={() => this.rp_open("fsg") } >
            <div>FSG STATISTIK</div>
            <div>Statistik för perioden</div>
        </div>
        <div className="web-btn" onClick={() => this.rp_open("bet") } >
            <div>BETALSTATISTIK</div>
            <div>Statistik för perioden</div>
        </div>
    </div>
     <div className="web-col flex" style={{padding: "0px 10px"}}>
        <div className="web-btn" onClick={() => this.rp_open("hh") } >
            <div>TIMFSGRAPPORT</div>
            <div>Timfsg över perioden</div>
        </div>
        <div className="web-btn" onClick={() => this.rp_open("tp") } >
            <div>TIDPERIODSRAPPORT</div>
            <div>Specifik period dag för dag</div>
        </div>
   </div>
     <div className="web-col flex">
        <div className="web-btn" onClick={() => this.rp_open("tips") } >
            <div>TIPSRAPPORT</div>
            <div>Sammanställd tips rapport för perioden</div>
        </div>
        <div className="web-btn" onClick={() => this.rp_open("term") } >
            <div>TERMINALRAPPORT</div>
            <div>Fsg per terminal för perioden</div>
        </div>
        <div className="web-btn" onClick={() => this.rp_open("hotel") } >
            <div>HOTELLRAPPORT</div>
            <div>Hotellöverföring för perioden</div>
        </div>
   </div>
   </div>

    </div>

        { this.state.rptyp == "fsg" ? <Fsg_dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "bet" ? <Bet_dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "hh" ? <HH_Dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "tp" ? <Tp_Dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "hotel" ? <Hotel_Dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "term" ? <Term_Dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "tips" ? <Tips_Dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.xrecov ? <Xrecov_Dlg show={this.state.xrecov} ctx={this} store={this.props.store} backdrop={true}/> : null }
    </div>
  );
    }
}
export default observer(Pos_Rps);
