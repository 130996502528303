import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../../lib/net";
import Fr_Input from "../../../lib/ui/fr_input";

import Wnd_Error from "../../../lib/ui/wnd_error";
import Wnd_Ok from "../../../lib/ui/wnd_ok";
import Wnd_Info from "../../../lib/ui/wnd_info";
import Wnd_Confirm from "../../../lib/ui/wnd_confirm";
import * as utils from "../../../assets/utils";
import Fr_Cal from "../../../lib/ui/fr_cal";

class Pro_add extends React.Component {
  constructor(props, context) {
    super(props);

    this.bcs = props.store.bcs;
    this.state = {};
    this.state.mka = [];
    this.state.sval = [];
    this.state.tval = [];
    this.state.paa = [];
    this.state.uaa = [];
    this.state.laa = [];

    this.state.gnr = "001";
    //this.state.lro = props.lro;
    this.state.ynq = [
                {id: '0', text: 'NEJ'},
                {id: '1', text: 'JA'}
            ];

      this.state.preg = [
          {id: '00000', text: 'EJ TILLDELAD'},
          {id: '00001', text: 'Joakim'},
          {id: '00002', text: 'Daniel'},
          {id: '00003', text: 'Stellan'},
          {id: '00004', text: 'Tobias'},
          {id: '00005', text: 'Emmanuel'},
          {id: '00006', text: 'Kari'}
      ];
    this.state.msg = {};
    this.state.cols = {};
      this.state.pro = {};
      this.state.pro.pros00 = {};
      this.state.pro.pros01 = {};
      this.state.pro.pros02 = {};
      this.state.tabval = 0;
      this.state.kanm = "";
      this.state.ddat = "";
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      this.pro_init();
  }

    pro_init() {
        var pro = {};
        pro.pros00 = {};
        pro.pros01 = {};
        pro.pros02 = {};
        pro.pros00.typ = "";
        pro.pros00.kid = "";
        pro.pros00.ftg = "";
        pro.pros00.ref = "";
        pro.pros00.orgnr = "";
        pro.pros00.tele = "";
        //alert(JSON.stringify(rso));
        this.setState({ pro: pro });
        return;
    }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    tb_style() {
        var css = {};
        css.height = window.innerHeight - 275;
        return css;
    }
    tab_style(tabid) {
        var css = {};
        css.display = "none";
        if(this.state.tabval === tabid) css.display = "block";
        return css;
    }
    tab_head(tabid) {
        var css = {};
        css.background = "#fff";
        css.color = "#000";
        if(this.state.tabval === tabid) {
            css.background = "#080";
            css.color = "#fff";
        }
        return css;
    }

    wnd_close = () => {
      if(this.props.close) {
          this.props.close(this.props.ctx);
      }
      else this.props.ctx.setState({ wnds: {} });
	};

    preg_change (e) {
        const value = e.target.value;
        var pro = this.state.pro;
        pro.pros00.pid = value;
        this.setState({pro: pro});
    }
  val_save(id, val) {
    var pro = this.state.pro;
    var na = id.split(".");
      pro[na[0]][na[1]] = val;
    this.setState({ pro: pro });
    return;
  }
    val_ddat_save(val) {
        //var tdo = this.state.tdo;
        //tdo.due = val;
        this.setState({ ddat: val });
        return;
    }
    val_anm_save(e) {
        const value = e.target.value;
        var pro = this.state.pro;
        pro.kdata = value;
        this.setState({pro: pro});
    }
    pro_add() {
        var self = this;
        var pro = this.state.pro;
        var ts = this.state.ddat;
        ts = ts.replaceAll("-", "");
        pro.pros00.due = ts;

        var prm = {};
        prm.req = "bcs.pro.pro_add";
        prm.pro = pro;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret));
                if(ret.ok == "000") {
                    self.setState({ msg: {ok: true, text: "Sparad ..."} });
                    setTimeout(function(){
                        self.setState({msg: {} });
                        if(self.props.wnds.cb) {
                            self.props.wnds.cb(self.props.ctx);
                        }
                        else self.props.ctx.setState({ wnds: {} });
                        return;
                    }, 1000);
                }
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }

  render() {
    //if(!this.props.show) return "";
    var title = this.props.title;
    if(!title) title = "Resurs";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        <div className="m2_backdrop" />
        <div className="m2_dlg mx_80 flex-col" style={ this.dlg_style() } >
        <div className="mx_content flex">

        <div className="mx_h01">
            <div>NYTT PROSPEKT</div>
            <div className="flex"></div>
            <div onClick={() => this.wnd_close() }>X</div>
        </div>
            <div className="flex-row">
                <div className="tab_header flex" style={ this.tab_head(0) } onClick={ () => this.setState({tabval: 0}) }>INFORMATION</div>
                <div className="tab_header flex" style={ this.tab_head(1) } onClick={ () => this.setState({tabval: 1}) }>KUND</div>
                <div className="tab_header flex" style={ this.tab_head(2) } onClick={ () => this.setState({tabval: 2}) }>ANMÄRKNING</div>
            </div>
            <div className="divider_orange"></div>

            <div style={ this.tab_style(0) }>
                <form className="cdi_f03">
                    <div className="flex-row">
                        <Fr_Input label="Text" className="text-left w350" text={this.state.pro.pros00.text} getValue={(e)=> this.val_save("pros00.text", e) } />
                    </div>
                    <div className="flex-row">
                        <div className="form-group flex-row flex">
                            <span className="header">Tilldelad</span>
                            <select value={this.state.pro.pros00.pid} className="form-control w250" onChange={(e) => this.preg_change(e)}>
                                { this.state.preg.map((row, key) =>
                                    <option key={key} value={row.id}>{row.text}</option>
                                )}
                            </select>
                        </div>
                    </div>
                    <div className="flex-row">
                        <Fr_Input label="Värde" className="text-left w250" text={this.state.pro.pros00.value} getValue={(e)=> this.val_save("pros00.value", e) } />
                        <Fr_Input label="Sannolikhet" className="text-left w150" text={this.state.pro.pros00.proc} getValue={(e)=> this.val_save("pros00.proc", e) } />
                    </div>
                    <div className="flex-row">
                        <Fr_Cal label="Förväntat datum" className="text-left w200" text={this.state.ddat} getValue={(e)=> this.val_ddat_save( e ) }/>
                    </div>

                </form>
            </div>

            <div style={ this.tab_style(1) }>
            <form className="cdi_f03">
                <div className="flex-row">
                    <Fr_Input label="KID" className="text-left w150" text={this.state.pro.pros01.kid} getValue={(e)=> this.val_save("pros01.kid", e) } />
                </div>
                <div className="flex-row">
                    <Fr_Input label="Företag" className="text-left w350" text={this.state.pro.pros01.ftg} getValue={(e)=> this.val_save("pros01.ftg", e) } />
                </div>
                <div className="flex-row">
                    <Fr_Input label="Orgnr" className="text-left w250" text={this.state.pro.pros01.orgnr} getValue={(e)=> this.val_save("pros01.orgnr", e) } />
                    <Fr_Input label="Tele" className="text-left w250" text={this.state.pro.pros01.tele} getValue={(e)=> this.val_save("pros01.tele", e) } />
                </div>
                <div className="flex-row">
                    <Fr_Input label="Referens" className="text-left w250" text={this.state.pro.pros01.ref} getValue={(e)=> this.val_save("pros01.ref", e) } />
                    <Fr_Cal label="Startdag" className="text-left w200" text={this.state.pro.pros01.reg} getValue={(e)=> this.val_save( "pros01.reg", e ) }/>
                </div>

                <div className="divider_green"></div>
                <div className="flex-row">
                    <Fr_Input label="Postadress" className="text-left w250" text={this.state.pro.pros01.postal} getValue={(e)=> this.val_save("pros01.postal", e) } />
                    <Fr_Input label="Gata" className="text-left w350" text={this.state.pro.pros01.street} getValue={(e)=> this.val_save("pros01.street", e) } />
                </div>
                <div className="flex-row">
                    <Fr_Input label="Postnr" className="text-left w150" text={this.state.pro.pros01.zip} getValue={(e)=> this.val_save("pros01.zip", e) } />
                    <Fr_Input label="Stad" className="text-left w350" text={this.state.pro.pros01.city} getValue={(e)=> this.val_save("pros01.city", e) } />
                </div>
                <div className="flex-row">
                    <Fr_Input label="Land" className="text-left w250" text={this.state.pro.pros01.country} getValue={(e)=> this.val_save("pros01.country", e) } />
                </div>
                <div className="divider_green"></div>
                <div className="flex-row">
                    <Fr_Input label="Epost" className="text-left w350" text={this.state.pro.pros01.email} getValue={(e)=> this.val_save("pros01.email", e) } />
                </div>
                <div className="flex-row">
                    <Fr_Input label="Web" className="text-left w350" text={this.state.pro.pros01.web} getValue={(e)=> this.val_save("pros01.web", e) } />
                </div>

            </form>
            </div>

            <div style={ this.tab_style(2) }>
                <form className="cdi_f03">
                    <div className="flex flex-column" style={{padding: "0px 5px"}}>
                        <label>Anmärkning</label>
                        <textarea className="form-control" maxLength="512" rows="10" value={ this.state.pro.prodata } onChange={(event) => this.val_anm_save(event)} />
                    </div>
                </form>
            </div>

        </div>
        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex-row">
            <button className="btn btn-hw-green w250" onClick={() => this.pro_add() } >
                SPARA
            </button>
            </div>
        </div>
      </div>

            <Wnd_Error msg={this.state.msg} ctx={this} />
            <Wnd_Ok msg={this.state.msg} ctx={this} />
            <Wnd_Info msg={this.state.msg} ctx={this} />
            <Wnd_Confirm msg={this.state.msg} ctx={this} />
      </div>
	;
	return html;
  };
}

export default Pro_add;