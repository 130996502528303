import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../../lib/net";
import Fr_Input from "../../../lib/ui/fr_input";
import Fr_Inpsel from "../../../lib/ui/fr_inpsel";
import Dd_Sel from "../../../lib/ui/dd_sel";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Rv_Info extends React.Component {
  constructor(props, context) {
    super(props);

    this.selitems = [
        {fkn: 'bevaka', text: 'BEVAKA'},
        {fkn: 'bokbild', text: 'BOKNINGSBILD'},
        {fkn: 'confirm', text: 'BEKRÄFTA'},
        {fkn: 'cancel', text: 'AVBOKA'}
    ];
      this.pma = [
          {val: 'kont', text: 'KONTANT'},
          {val: 'kort', text: 'KORT'},
          {val: 'swish', text: 'SWISH'},
          {val: 'fakt', text: 'FAKTURA'}
      ];
      this.segment = [
          {val: 'OTA', text: 'OTA'},
          {val: 'WEB', text: 'WEB'},
          {val: 'GDS', text: 'BOOKING'},
          {val: 'WALKIN', text: 'WALKIN'}
      ];
      this.mkod = [
          {val: 'priv', text: 'PRIVAT/LEISURE'},
          {val: 'busi', text: 'BUSINESS'}
      ];
      this.garanti = [
          {val: 'fsk', text: 'FÖRSKOTT'},
          {val: 'kort', text: 'KORT'}
      ];
      this.scb = [
          {val: 'SE', text: 'SVERIGE'},
          {val: 'NO', text: 'NORGE'},
          {val: 'DK', text: 'DANMARK'},
          {val: 'GB', text: 'STORBRITANIEN'},
          {val: 'DE', text: 'TYSKLAND'},
          {val: 'FR', text: 'FRANKRIKE'},
          {val: 'ES', text: 'SPANIEN'},
          {val: 'IT', text: 'ITALIEN'},
          {val: 'IN', text: 'INDIEN'},
          {val: 'CN', text: 'KINA'},
          {val: 'US', text: 'USA'}
      ];

    this.store = props.store;
    this.state = {};
    this.state.ynq = [];

    this.state.boknr = "";
    if(props.boknr) this.state.boknr = props.boknr;

    this.state.rsv = {};
    this.state.rsv.resv00 = {};
    this.state.rsv.resv01 = {};
    this.state.rsv.resv02 = {};
    this.state.rsv.resv03 = {};
    this.state.rsv.resv04 = {};
    this.state.rsv.anm = {};

    this.state.resm = {};
    this.state.resm.resm00 = {};
    this.state.resm.resm01 = {};
    this.state.resm.resm02 = {};
    this.state.resm.resm03 = {};

    this.state.rvo = {};
    this.state.rvo.nrr = "1";
    this.state.rvo.sum = "2000";
    this.state.rvo.rvo = {};

    if(props.wnds) {
        this.state.boknr = props.wnds.boknr;
        this.state.rsv = props.wnds.rsv;
        this.state.resm = props.wnds.resm;
    }
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }

    formchange (e) {
      const name = e.target.name;
      const value = e.target.value;
      var rvo = this.state.rvo;
      var na = name.split(".");
      rvo[na[0]][na[1]] = value;
      this.setState({rvo: rvo});
      //setState({[name]: value});
    }
    rsv_anm_change (e) {
      const name = e.target.name;
      const value = e.target.value;
      var rsv = this.state.rsv;
      var na = name.split(".");
      rsv[na[0]][na[1]] = value;
      this.setState({rsv: rsv});
      //setState({[name]: value});
    }

    wnd_close = () => {
      if(this.props.cb) {
        var ret = {};
        ret.ok = "999";
        this.props.cb(this.props.ctx, ret);
      }
      else this.props.ctx.setState({ wnds: {} });
	};
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
    val_save (name, value) {
        //alert(JSON.stringify(name));
        //alert(JSON.stringify(value));
      var rvo = this.state.rvo;
      var na = name.split(".");
      rvo[na[0]][na[1]] = value;
      this.setState({rvo: rvo});
      //setState({[name]: value});
    }
    rsv_save (name, value) {
        //alert(JSON.stringify(name));
        //alert(JSON.stringify(value));
      var rsv = this.state.rsv;
      var na = name.split(".");
      rsv[na[0]][na[1]] = value;
      this.setState({rsv: rsv});
      //setState({[name]: value});
    }
    resm_save (name, value) {
        //alert(JSON.stringify(name));
        //alert(JSON.stringify(value));
      var resm = this.state.resm;
      var na = name.split(".");
      resm[na[0]][na[1]] = value;
      this.setState({resm: resm});
      //setState({[name]: value});
    }
  rv_save() {
    alert(JSON.stringify(this.state.rsv));
  };
  sz() {
    alert(JSON.stringify("X123"));
  };
  sel_fkn(sel) {
    //alert(JSON.stringify(fkn));
      if(sel.fkn == "logout") {
      }
  }
  rv_store() {
    var self = this;

    var prm = {};
    prm.req = "pms.rv.rvx_upd";
    prm.boknr = self.state.boknr;
    prm.rsv = self.state.rsv;
    prm.resm = self.state.resm;
    //alert(JSON.stringify(prm));
    //alert(JSON.stringify(self.state.rsv));

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            throw "992 - Fel";
            return;
        }

        var prm = {};
        prm.req = "pms.rv.pmx_upd";
        prm.boknr = self.state.boknr;
        prm.anm = self.state.rsv.anm;
        return net.sio_req(prm);
    })
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            //self.toast("ERROR", "FEL Sparat");
            //return;
        }
        //self.toast("OK", "Bokning sparad");
        //toastr.success("Reservation sparad : " + $scope.boknr);
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //self.toast("ERROR", "FEL: " + e);
    });
    return;
  }

  render() {
    var title = this.props.title;
    if(!title) title = "Bokning";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="mx_backdrop" /> : null }
        <div className="mx_dlg mx_100 flex-col" style={ this.dlg_style() }>
            <div className="flex-row" style={{backgroundColor: "#008", color: "#fff", fontSize: "18px", paddingLeft: "5px"}}>
                <div className="f40 flex-row">
                <div className="web-top-icon px-3 w60" onClick={() => this.mnu_toggle() }>
                    <FontAwesomeIcon icon="bars" size="1x"/>
                </div>
                <div className="flex-column">
                    <div style={{fontSize: "12px", margin: "0px", padding: "0px", lineHeight: "20px"}}>
                        Bokning
                    </div>
                    <div style={{fontSize: "18px", margin: "0px", padding: "0px", lineHeight: "20px"}}>
                        { this.state.boknr }
                    </div>
                </div>
                </div>

             </div>
        <div className="mx_content flex">

            <div className="divider_green"></div>
            <div className="flex-row">
                <div className="wnd_link">
                    RENSA
                </div>
                <div className="flex"></div>
                <div className="wnd_link">
                    BOKNINGSDATA
                </div>
            </div>

            <form className="cdi_f03">
                <div className="flex-row" style={{border: "0px", margin: "0px", padding: "0px"}}>
                    <div className="form-row flex-col flex">
                        <Fr_Input label="Namn" sz={this.sz} className="text-left w250" text={this.state.rsv.resv01.Name} getValue={(e)=> this.rsv_save("resv01.Name", e) } />
                        <Fr_Input label="Företag" sz={this.sz} className="text-left w250" text={this.state.rsv.resv00.Company} getValue={(e)=> this.rsv_save("resv00.Company", e) } />
                        <Fr_Input label="Org Nr" className="text-left w250" text={this.state.rsv.resv04.Orgnr} getValue={(e)=> this.rsv_save("resv04.Orgnr", e) } />
                    </div>
                    <div className="form-row flex-col flex">
                        <Fr_Input label="Telefon" className="text-left w250" text={this.state.rsv.resv01.Tfn} getValue={(e)=> this.rsv_save("resv01.Tfn", e) } />
                        <Fr_Input label="Mobil" className="text-left w250" text={this.state.rsv.resv01.Mobil} getValue={(e)=> this.rsv_save("resv01.Mobil", e) } />
                        <Fr_Input label="Epost" className="text-left w250" text={this.state.rsv.resv02.Email} getValue={(e)=> this.rsv_save("resv02.Email", e) } />
                    </div>
                    <div className="form-row flex-col flex">
                        <Fr_Input label="Referens" className="text-left w250" text={this.state.rsv.resv04.Referens} getValue={(e)=> this.rsv_save("resv04.Referens", e) } />
                        <Fr_Input label="Avdelning" className="text-left w250" text={this.state.rsv.resv04.Avdelning} getValue={(e)=> this.rsv_save("resv04.Avdelning", e) } />
                        <Fr_Inpsel label="SCB" className="text-left w250" items={this.scb} text={this.state.rsv.resv04.Scb} getValue={(e)=> this.rsv_save("resv04.Scb", e) } />
                    </div>
                </div>

                <div className="divider_orange"></div>

                <div className="flex-row" style={{border: "0px", margin: "0px", padding: "0px"}}>
                    <div className="form-row flex-col flex">
                        <Fr_Input label="Adress 1" className="text-left w250" text={this.state.rsv.resv03.Adress1} getValue={(e)=> this.rsv_save("resv03.Adress1", e) } />
                        <Fr_Input label="Adress 2" className="text-left w250" text={this.state.rsv.resv03.Adress2} getValue={(e)=> this.rsv_save("resv03.Adress2", e) } />
                        <Fr_Input label="Adress 3" className="text-left w250" text={this.state.rsv.resv03.Adress3} getValue={(e)=> this.rsv_save("resv03.Adress3", e) } />
                    </div>
                    <div className="form-row flex-col flex">
                    </div>
                    <div className="form-row flex-col flex">
                        <Fr_Input label="Land" className="text-left w250" text={this.state.rsv.resv04.Referens} getValue={(e)=> this.rsv_save("resv04.Referens", e) } />
                    </div>
                </div>

                <div className="divider_orange"></div>

                <div className="flex-row" style={{border: "0px", margin: "0px", padding: "0px"}}>
                    <div className="form-row flex-col flex">
                        <Fr_Inpsel label="Betalsätt" className="text-left w250" items={this.pma} text={this.state.rsv.resv02.bettyp} getValue={(e)=> this.rsv_save("resv02.bettyp", e) } />
                        <Fr_Input label="Externt Boknr" className="text-left w250" text={this.state.resm.resm01.Extboknr} getValue={(e)=> this.resm_save("resm01.Extboknr", e) } />
                    </div>
                    <div className="form-row flex-col flex">
                        <Fr_Input label="Kortnr" className="text-left w250" text={this.state.resm.resm03.ccnr} getValue={(e)=> this.resm_save("resm03.ccnr", e) } />
                        <Fr_Input label="Ankomsttid" className="text-left w250" text={this.state.rsv.resv02.anktid} getValue={(e)=> this.rsv_save("resv02.anktid", e) } />
                    </div>
                    <div className="form-row flex-col flex">
                        <Fr_Input label="Exp" className="text-left w250" text={this.state.resm.resm03.ccexp} getValue={(e)=> this.resm_save("resm03.ccexp", e) } />
                    </div>
                </div>
                <div className="divider_orange"></div>

                <div className="flex-row" style={{border: "0px", margin: "0px", padding: "0px"}}>
                    <div className="form-row flex-col flex">
                        <Fr_Inpsel label="Segment" className="text-left w250" items={this.segment} text={this.state.resm.resm01.Kanal} getValue={(e)=> this.resm_save("resm01.Kanal", e) } />
                        <Fr_Inpsel label="Marknadskod" className="text-left w250" items={this.mkod} text={this.state.rsv.resv04.Mkod} getValue={(e)=> this.rsv_save("resv04.Mkod", e) } />
                    </div>
                    <div className="form-row flex">
                    </div>
                    <div className="form-row flex-row flex">
                        <Fr_Inpsel label="Garanti" className="text-left w250" items={this.garanti} text={this.state.resm.resm03.garanti} getValue={(e)=> this.resm_save("resm03.garanti", e) } />
                    </div>
                </div>
            <div className="flex"></div>
            <div className="divider_orange"></div>

            <div className="flex-row pb-3">
                <div className="flex flex-column" style={{padding: "0px 5px"}}>
                    <span className="header">Anmärkning reception</span>
                    <textarea className="form-control" maxLength="512" rows="5" name="anm.rec" value={this.state.rsv.anm.rec} onChange={(e) => this.rsv_anm_change(e)}></textarea>
                </div>
                <div className="flex flex-column" style={{padding: "0px 5px"}}>
                    <span className="header">Anmärkning städ</span>
                    <textarea className="form-control" maxLength="512" rows="5" name="anm.stad" value={this.state.rsv.anm.stad} onChange={(e) => this.rsv_anm_change(e)}></textarea>
                </div>
            </div>
            </form>

            </div>

            <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250 ripple" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex-row">
                <Dd_Sel items={ this.selitems } getValue={(e)=> this.sel_fkn(e)} />
            </div>
            <button className="btn btn-hw-green w250 ripple" onClick={(event) => this.rv_store(event) } >
                SPARA
            </button>
        </div>
      </div>
    </div>
	;
	return html;
  };
}

export default Rv_Info;