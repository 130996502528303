import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";
import {SDOProvider} from "../../sdo";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Pass_Schema extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, tra: [] };
    this.state.seldate = new Date();
    this.state.avd = "01";
    this.state.kaa = [];
    this.state.kro = {};
    this.state.aa = [];
    this.state.cols = {};
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

  kreg() {
    var self = this;
    var prm = {};
    prm.req = "kreg.kreg_wrk.kreg_list";
    //prm.avd = this.state.avd;
    //prm.fplu = datum;
    //prm.tplu = datum;

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        var o = {};
        //o.deps = ret.rco;
        //alert(JSON.stringify(o));
        self.setState({kaa: ret.rco });
        self.syncCols("kidTable");
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }

  render() {
    return (
    <div className="web-app">
    <div className="flex-row flex-space-between">
        <div className="label_h03">PASS SCHEMA</div>
        <select className="form-control sel_input" placeholder="Välj avdelning">
            { this.state.aa.map((row, key) =>
            <option key={key} value={row.id}>{row.text}</option>
            )}
        </select>
        <button className="btn btn-hw-blue w150 ripple" onClick={() => this.syncCols("kidTable") } aria-label="System" >
            Visa
        </button>
    </div>
    <div className="divider"></div>

   </div>
  );
    }
}
export default Pass_Schema;
