import React, { useState, useEffect, useContext } from 'react';
import {format, addDays, subDays} from "date-fns";

import * as net from "../../../lib/net";
import Fr_Input from "../../../lib/ui/fr_input";

class Rv_Logg extends React.Component {
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pms = props.store.pms;

    this.state = {};
    this.state.today = new Date();
    this.state.rta = [];
    this.state.lga = [];

    this.state.ynq = [
                {id: '0', text: 'NEJ'},
                {id: '1', text: 'JA'}
            ];

    this.state.background = "#fff";

    this.state.cols = {};
    this.state.lrwrk = false;
    this.state.boknr = {};
    if(props.boknr) this.state.boknr = props.boknr;

    this.state.wnds = {};
    if(props.wnds) {
        this.state.wnds = props.wnds;
        if(props.wnds.boknr) this.state.boknr = props.wnds.boknr;
    }
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.log_rv();
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    grp_style(row) {
      var css = {};
      css.background = "#008";
      if(this.state.gnr === row.vgrupp) css.background = "#080";
      return css;
    }

    wnd_close = () => {
      if(this.props.cb) {
        var ret = {};
        ret.ok = "999";
        this.props.cb(this.props.ctx, ret);
      }
      else this.props.ctx.setState({ wnds: {} });
	};
    handleChange = (event, newValue) => {
        //alert(newValue);
        this.setState({ix: newValue});
      };
    toggle() {
      this.setState({modal: false});
	};

  submitHandler = event => {
    event.preventDefault();
    event.target.className += " was-validated";
  };

  changeHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  };


  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }

  log_edit(row) {
    var self = this;
  }

  log_rv() {
    var self = this;
    var prm = {};
    prm.req = "pms.log.log_rv";
    prm.boknr = this.state.boknr;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            return;
        }
        self.setState({lga: ret.rca});
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "Gäst";
    //if(props.kro) alert(props.kro.krr00.kid);
    const popover = {
      position: 'absolute',
      top: '50px',
      zIndex: '2',
    }
    const cover = {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    }

	var html =
        <div className="mx_base">
        <div className="mx_backdrop" />
        <div className="mx_dlg mx_100 flex-col" style={ this.dlg_style() } >

        <div className="mx_h01">
            <div>LOGG</div>
        </div>
        <div className="mx_content flex">

            <div className="divider_orange"></div>

            <div className="divider_green"></div>
            <div className="flex-row" style={{border: "0px", margin: "0px", padding: "0px"}}>
                <table className="table tb">
                    <thead>
                    <tr>
                        <th style={{textAlign: "left"}}>Datum</th>
                        <th style={{textAlign: "left"}}>Tid</th>
                        <th style={{textAlign: "left"}}>Text</th>
                        <th style={{textAlign: "left"}}>Kod</th>
                        <th style={{textAlign: "left"}}>Signatur</th>
                        <th style={{textAlign: "left"}}>Status</th>
                        <th style={{textAlign: "center", color: "#800"}}>#</th>
                    </tr>
                    </thead>
                    <tbody>
                    { this.state.lga.map((row, key) =>
                    <tr onDoubleClick={() => this.log_edit(row) }>
                        <td>{ row.datum }</td>
                        <td>{ row.tidpunkt }</td>
                        <td>{ row.s0sig }</td>
                        <td>{ row.Text }</td>
                        <td>{ row.boknr }</td>
                        <td>{ row.r0status }</td>
                        <td></td>
                    </tr>
                    )}
                    </tbody>
                </table>
             </div>

        </div>

        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                AVBRYT
            </button>
            <div className="flex-row">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            </div>
        </div>
      </div>
      </div>
	;
	return html;
  };
}

export default Rv_Logg;