import React, { useState, useEffect, useContext } from 'react';

import * as net from "../lib/net";
import Dt_Cal from "../lib/ui/dt_cal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Tst_List extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, tra: [] };
    this.state.seldate = new Date();
    this.state.avd = "01";
    this.state.paa = [];
    this.state.aa = [
                {id: '01', text: 'AVD 01'},
                {id: '02', text: 'AVD 02'}
            ];
    //context.posrend = this;
    this.se_calendar = {
        firstDayOfWeek: 1,
        dayNames: [ "Söndag", "Måndag", "Tisdag", "Onsdag", "Torsdag", "Fredag", "Lördag" ],
        dayNamesShort: [ "sön", "mån", "tis", "ons", "tor", "fre", "lör" ],
        dayNamesMin: ["S", "M", "T", "O", "T", "F", "L" ],
        monthNames: ["Januari", "Februari", "Mars", "April", "Maj", "Juni", "Juli", "Augusti", "September", "Oktober", "November", "December"],
        monthNamesShort: ["jan", "feb", "mar", "apr", "maj", "jun", "jul", "aug", "sep", "okt", "nov", "dec"],
        today: 'Idag',
        clear: 'Rensa',
        dateFormat: 'yy/mm/dd',
        weekHeader: 'V'
    };

    this.state.show = true;
  }

  componentDidUpdate() {
    let value = this.context;
    /* ... */
  }
  componentWillUnmount() {
    let value = this.context;
    /* ... */
  }
  componentDidMount() {
    //this.props.fetchWells()
    //  .then(res => this.setState({ wells: res.wells }) )
    //this.plus();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 250;
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

  toggleDropdown = event => {
    let state = this.state;
    state.show = !state.show;
    this.setState({ state: state });
  };

  plu_open(row) {
      var self = this;
  }
  plu_edit(row) {
      var self = this;
      alert(JSON.stringify(row.plu00));
  }

  plus() {
    var self = this;
    var prm = {};
    prm.req = "pos.pos_plu.plus";
    prm.avd = this.state.avd;
    //prm.fplu = datum;
    //prm.tplu = datum;

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        var o = {};
        //o.deps = ret.rco;
        //alert(JSON.stringify(o));
        self.setState({paa: ret.rco });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }

  render() {
    return (
    <div className="web-p00 flex flex-col">
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb my-breadcrumb">
                <li className="breadcrumb-item active" aria-current="page">Produkter</li>
                <li className="breadcrumb-item"><a href="/pos/prds/plu">PLU</a></li>
                <li className="breadcrumb-item"><a href="/pos/prds#btns">Knappar</a></li>
                <li className="breadcrumb-item"><a href="/pos/prds/addons">Tilval</a></li>
            </ol>
        </nav>
        <div className="">
            Produkter
        </div>

    <div className="web-p01">
    <div className="flex-row">
        <div className="flex-row">
            <div className="dt-btn-01" onClick={() => this.subday() } ><FontAwesomeIcon icon="minus" size="1x" /></div>
            <Dt_Cal dts={this.state.dts} rp="1" ref={this.dt_dt} getValue={(e)=> this.dt_dat(e)} />
            <div className="dt-btn-01" onClick={() => this.addday() } ><FontAwesomeIcon icon="plus" size="1x" /></div>
        </div>
        <select className="form-control w250" placeholder="Välj avdelning">
            { this.state.aa.map((row, key) =>
            <option value={row.id}>{row.text}</option>
            )}
        </select>
        <button className="btn btn-hw-blue w150 ripple" onClick={() => this.plus() } aria-label="System" >
            Visa
        </button>
    </div>

    <div className="web-row">
        <table className="table table-bordered table-hover tb fixed_header">
            <thead>
            <tr>
                <th>PLU</th>
                <th>Text</th>
                <th>Pris</th>
                <th>Varugrupp</th>
                <th>Moms</th>
                <th>Sort</th>
                <th>Uppdat</th>
                <th style={{width:"40px"}} ></th>
                <th style={{width:"16px"}} ></th>
            </tr>
            </thead>
            <tbody style={ this.tb_style() }>
            { this.state.paa.map((row, key) =>
            <tr id={ row.plu00.p0katnr } onDblClick="plu_open(row);">
                <td>{ row.plu00.p0katnr }</td>
                <td>{ row.plu00.p0namn }</td>
                <td>{ row.plu00.p0pris }</td>
                <td>{ row.plu02.p2vgrupp }</td>
                <td>{ row.plu00.p0moms }</td>
                <td>{ row.plu00.p0sort }</td>
                <td>{ row.plu00.p0upddat }</td>
                <td style={{width:"40px",cursor:"pointer"}} onClick={() => this.plu_edit(row) }><i className="fa fa-cog text-danger"></i></td>
            </tr>
            )}
            </tbody>
        </table>
    </div>
    </div>

    </div>
  );
    }
}
export default Tst_List;
