import React, { useState, useEffect, useContext } from 'react';
import ReactDOM from 'react-dom';
import { SDOContext } from '../sdo.js'
import { Chart } from 'chart.js'

import * as net from "../lib/net";
import Dt_Txt from "../lib/ui/dt_txt";
import {format, getHours, subDays} from "date-fns";
import ddo from "../ddo";

function web01() {

    var html =
    <div className="">
        Test
    </div>
    ;
    return html;
}

class Cas_Dash extends React.Component {
    //var sdo = useContext(SDOContext); // Functional
    static contextType = SDOContext; // Class

    constructor(props, context) {
        super(props);

        this.s1ref = React.createRef();
        this.s2ref = React.createRef();

        this.my_bar = null;
        this.my_pie = null;
        this.my_stack = null;
        this.my_line = null;

        this.webStore = props.store;
        this.state = { pgid: 11, stat: [] };
        this.state.open = false;
        this.state.pdfopen = false;
        this.state.xxopen = false;
        this.state.pdf_uri = "";
        this.state.seldate = new Date('2020-03-24T13:11:54');
        this.state.row = null;
        this.state.info = "";
        this.state.top = 1;
        this.state.user = {};
        this.state.sdo = {};
        this.state.tra = [];
        this.state.snitt = 0;
        //this.state.sdo = context;
        //context.posrend = this;
        this.state.step = 1;
        this.state.pdf = 0;
        this.state.pdf_uri = "";
        this.state.mnus = ["m00", "m01", "m02", "m03", "m08", "m09"];
        this.state.mnu_sel = "m00";

        this.state.today = new Date();
        var hh = getHours(this.state.today);
        if( hh < 7) {
            const dt = subDays(new Date(), 1);
            this.state.datum = format(dt, "yyyy-MM-dd");
        }
        else this.state.datum = format(this.state.today, "yyyy-MM-dd");

        this.state.sto = {};
        this.state.sto.fsg = 0;
        this.state.sto.retur = 0;
        this.state.sto.nrp = 0;

        this.chartColors = {
            navy: '#4755AB',
            green: '#03c895',
            danger: '#ff4f81',
            blue: '#4A496E',
            purple: '#B162AC',
            grey: '#E7EDF6'
        };
        this.state.una = ddo.cfg.una;
        this.state.uno = ddo.cfg.units;
    }
    componentDidMount(){
        //var sdo = sessionStorage.getItem("svp");
        //if(sdo) this.setState({sdo: JSON.parse(sdo) });
        this.datum_sum();
        this.bar_chart();
        //this.pie_chart();
        this.stack_chart();
        //this.line_chart();
    }

    componentWillUnmount(){
        this.setState({info: "UUU" });
    }

    datum_sum() {
        var self = this;
        var prm = {};
        prm.req = "pos.pos_cas.datum_sum";
        prm.datum = this.state.datum;

        //gda.wndLoading("Hämtar");
        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.rca));

                if(ret.ok != "000") {
                    return;
                }
                var sto = ret.rco;
                self.bar_chart(sto);
                self.stack_chart(sto);
                var snitt = 0;
                if(sto.nrp > 0) snitt = sto.fsg / sto.nrp;
                self.setState({sto: ret.rco, tra: ret.rca, snitt: snitt });
                //toastr.success("OK", "SAPI");
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
                //gda.wndError(txt);
            });
    }
    cas_sum(datum) {
        var self = this;
        //var datum = this.state.datum;
        var today = this.state.today;
        if(!datum) datum = this.state.datum;
        if(datum == today) this.today_sum();
        else this.dt_sum(datum);
    }
  today_sum() {
    var self = this;
    var prm = {};
    prm.req = "pos.pos_cas.today_sum";
    prm.datum = "";

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        if(ret.ok != "000") {
            return;
        }
        var sto = ret.rco;
        self.bar_chart(sto);
        self.stack_chart(sto);
        var snitt = 0;
        if(sto.nrp > 0) snitt = sto.fsg / sto.nrp;
        self.setState({sto: ret.rco, tra: ret.rca, snitt: snitt });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
    }
  dt_sum(datum) {
    var self = this;
    var prm = {};
    prm.req = "pos.pos_cas.dt_sum";
    prm.datum = datum;

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        if(ret.ok != "000") {
            return;
        }
        var sto = ret.rco;
        self.bar_chart(sto);
        self.stack_chart(sto);
        var snitt = 0;
        if(sto.nrp > 0) snitt = sto.fsg / sto.nrp;
        self.setState({sto: ret.rco, tra: ret.rca, snitt: snitt });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
    }

    onScroll(event) {
        var body = document.getElementById("web-body");
        if (body.scrollTop > 150) {
          this.setState({top: 0})
          this.setState({info: 'small'})
        } else {
          this.setState({top: 1})
          this.setState({info: 'big:'+body.scrollTop})
        }

        if (true) {
            var offset = body.scrollTop;
            var start = body.scrollTop;
            var slut = body.scrollTop + window.innerHeight;

            for(var mnuid of this.state.mnus) {
                const element = document.getElementById(mnuid);
                if(!element || (element == null) || (element == undefined)) continue;
                var eltop = element.offsetTop;
                var elbot = element.offsetTop + element.offsetHeight;
                var brc = eltop >= start && eltop <= slut;
                //if(!brc) brc = elbot >= start && elbot <= slut;
                if(brc) {
                    this.setState({mnu_sel: mnuid});
                    return;
                }
            }
        }
    }
   header_css() {
  	    var css = {};
		if(this.state.top) {
			css.color = "#fff";
			css.background = "transparent";
			css.height = "50px";
			//css["background"] = "green";
		}
		else {
			css.color = "#000";
			css.background = "#fff";
		    css.height = "50px";
        }

		return css;
    }
    header_class() {
  	    var cls = "flex_col";
		if(this.state.top) {
			cls = cls + " web-head_1";
		}
		else cls = cls +  " web-header";

		return cls;
    }

    pie_chart() {
        var config = {
            type: 'pie',
            data: {
                datasets: [{
                    data: [
                        25,
                        38,
                        66,
                        15,
                        90,
                    ],
                    backgroundColor: [
                        this.chartColors.navy,
                        this.chartColors.blue,
                        this.chartColors.purple,
                        this.chartColors.danger,
                        this.chartColors.grey,
                    ],
                    label: 'Dataset 1'
                }],
                labels: [
                    'navy',
                    'blue',
                    'purple',
                    'danger',
                    'grey'
                ]
            },
            options: {
                responsive: true
            }
        };

        var ctx4 = document.getElementById('piechart').getContext('2d');
        this.my_pie = new Chart(ctx4, config);
	};
    stack_chart(sto) {
        var lbls = [];
        var cash = [];
        var card = [];
        var win = [];
        var ao = {};

        for(var row of this.state.una) {
            if(row.id == "00") continue;
            var lbl = row.id + ": " + row.text;

            ao[row.id] = {};
            ao[row.id].text = lbl;
            ao[row.id].fsg = "0";
            ao[row.id].cash = "0";
            ao[row.id].card = "0";
            ao[row.id].win = "0";
        }
        if(sto && sto.dpo) {
            var dpa = Object.values(sto.dpo);
            for(var row of dpa) {
                var text = this.state.uno[row.avd];
                var lbl = row.avd + ": " + text;
                if(ao[row.avd]) {
                    ao[row.avd].fsg = row.fsg.cdibelopp();
                    ao[row.avd].cash = row.kont.cdibelopp();
                    ao[row.avd].card = row.kort.cdibelopp();
                    ao[row.avd].win = row.retur.cdibelopp();
                }
            }
        }

        var keys = Object.keys(ao);
        keys.sort();
        for(var key of keys) {
            var row = ao[key];
            lbls.push( row.text );
            cash.push( row.cash );
            card.push( row.card );
            win.push( row.win );
        }
        //alert( JSON.stringify(faa) );

        if(this.my_stack) this.my_stack.destroy();
        this.my_stack =  new Chart(document.getElementById("stackedbar"), {
			type: 'bar',
			data: {
				labels: lbls,
				datasets: [{
					data: cash,
					label: 'Kontant',
					backgroundColor: this.chartColors.navy,
					borderWidth: 1,
				}, {
					data: card,
					label: 'Kort',
					backgroundColor: this.chartColors.purple,
					borderWidth: 1,
				}, {
					data: win,
					label: 'Vinst',
					backgroundColor: this.chartColors.grey,
				}]
			},
			options: {
				// title: {
				// 	display: true,
				// 	text: 'Chart.js Bar Chart - Stacked'
				// },
				tooltips: {
					mode: 'index',
					intersect: false
				},
				responsive: true,
				scales: {
					x: {
						stacked: true,
					},
					y: {
						stacked: true
					}
				}
			}
		});
	};
    line_chart() {
        this.my_line =  new Chart(document.getElementById("linechart"), {
            type: 'line',
            data: {
                labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
                datasets: [{
                    label: 'Avd 01',
                    backgroundColor: this.chartColors.navy,
                    borderColor: this.chartColors.navy,
                    data: [30,10,70,15,60,20,70,80],
                    fill: false,
                }, {
                    label: 'Avd 02',
                    fill: false,
                    backgroundColor: this.chartColors.purple,
                    borderColor: this.chartColors.purple,
                    data: [10,40,20,35,25,50,10,70],
                }, {
                    label: 'Avd 03',
                    fill: false,
                    backgroundColor: this.chartColors.green,
                    borderColor: this.chartColors.green,
                    data: [15,10,67,56,25,98,23,43],
                }, {
                    label: 'Avd 04',
                    fill: false,
                    backgroundColor: this.chartColors.blue,
                    borderColor: this.chartColors.blue,
                    data: [25,60,70,20,42,78,25,67],
                }]
            },
            options: {
                responsive: true,
                // title: {
                // 	display: true,
                // 	text: 'Chart.js Line Chart'
                // },
                tooltips: {
                    mode: 'index',
                    intersect: false,
                },
                hover: {
                    mode: 'nearest',
                    intersect: true
                },
                scales: {
                    x: {
                        display: true,
                        scaleLabel: {
                            display: true,
                            labelString: 'Month'
                        }
                    },
                    y: {
                        display: true,
                        scaleLabel: {
                            display: true,
                            labelString: 'Value'
                        }
                    }
                }
            }
        });
    };
    cdipris(pris) {

        if(pris) pris = pris.cdipris();
        return pris;
    };
    bar_chart(sto) {

        var lbls = [];
        var faa = [];
        var waa = [];
        var ao = {};

        for(var row of this.state.una) {
            if(row.id == "00") continue;
            var lbl = row.id + ": " + row.text;

            ao[row.id] = {};
            ao[row.id].text = lbl;
            ao[row.id].fsg = "0";
            ao[row.id].win = "0";
        }
        if(sto && sto.dpo) {
            var dpa = Object.values(sto.dpo);
            for(var row of dpa) {
                //alert(JSON.stringify(row));
                var text = this.state.uno[row.avd];
                var lbl = row.avd + ": " + text;
                if(ao[row.avd]) {
                    ao[row.avd].fsg = row.fsg.cdibelopp();
                    //ao[row.avd].win = row.retur.cdipris();
                }
                //lbls.push( lbl );
                //faa.push( row.fsg.cdipris() );
                //waa.push( row.kont.cdipris() );
                //waa.push( "0" );
            }
        }

        var keys = Object.keys(ao);
        keys.sort();
        for(var key of keys) {
            var row = ao[key];
            lbls.push( row.text );
            faa.push( row.fsg );
            waa.push( row.win );
        }
        //alert( JSON.stringify(faa) );

        if(this.my_bar) this.my_bar.destroy();
        this.my_bar =  new Chart(document.getElementById("barchart"), {
            type: 'bar',
            data: {
                labels: lbls,
                datasets: [{
                    data: faa,
                    label: 'OMSÄTTNING',
                    backgroundColor: "#4755AB",
                    borderWidth: 1,
                }, {
                    data: waa,
                    label: 'VINST',
                    backgroundColor: "#E7EDF6",
                    borderWidth: 1,
                }]
            },
            options: {
                responsive: true,
                legend: {
                    position: 'top',
                },
            }
        });
	};
  dt_dat(e) {
      var self = this;
    //alert(JSON.stringify(e));
    var dts = format(e, "yyyy-MM-dd");
    this.setState({ datum: dts }, function (){
        self.datum_sum();
    });
  }
  sel_fkn(e) {
    alert(JSON.stringify(e));
  }

    render() {
        var stat = [];
        var keys = Object.keys(this.state.stat);
        for (var key of keys) {
            var row = this.state.stat[key];
            stat.push(row);
        }
        const handleDateChange = date => {
            this.setState({seldate: date });
        };

        return (
            <div className="container-fluid" style={{background: "#eff1f9"}}>

                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb my-breadcrumb">
                        <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                        <li className="breadcrumb-item active" aria-current="page">Dashboard</li>
                    </ol>
                </nav>
                <div className="welcome-msg pt-3 pb-4">
                    <div className="flex-row flex-space-between">
                    <h1><span className="text-primary" onClick={(e)=> this.cas_sum(e)}>Kasino</span></h1>
                    <Dt_Txt dts={this.state.datum} getValue={(e)=> this.dt_dat(e)} />
                    </div>
                </div>

                <div className="statistics">
                    <div className="row">
                        <div className="col-xl-6 pr-xl-2">
                            <div className="row">
                                <div className="col-sm-6 pr-sm-2 statistics-grid">
                                    <div className="card card_border border-primary-top p-4">
                                        <i className="lnr lnr-users"> </i>
                                        <h3 className="text-primary number">{ this.cdipris( this.state.sto.fsg) }</h3>
                                        <p className="stat-text">Dagens Omsättning</p>
                                    </div>
                                </div>
                                <div className="col-sm-6 pl-sm-2 statistics-grid">
                                    <div className="card card_border border-primary-top p-4">
                                        <i className="lnr lnr-eye"> </i>
                                        <h3 className="text-secondary number">{ this.cdipris( this.state.sto.retur) }</h3>
                                        <p className="stat-text">Dagens Utbetalda vinst</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 pl-xl-2">
                            <div className="row">
                                <div className="col-sm-6 pr-sm-2 statistics-grid">
                                    <div className="card card_border border-primary-top p-4">
                                        <i className="lnr lnr-cloud-download"> </i>
                                        <h3 className="text-success number">{ this.state.sto.nrp }</h3>
                                        <p className="stat-text">Dagens Spel</p>
                                    </div>
                                </div>
                                <div className="col-sm-6 pl-sm-2 statistics-grid">
                                    <div className="card card_border border-primary-top p-4">
                                        <i className="lnr lnr-cart"> </i>
                                        <h3 className="text-danger number">{ this.cdipris( this.state.snitt) }</h3>
                                        <p className="stat-text">Omsatt per spel</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="chart">
                    <div className="row">
                        <div className="col-lg-6 pr-lg-2 chart-grid">
                            <div className="card text-center card_border">
                                <div className="card-header chart-grid__header">
                                    Omsättning
                                </div>
                                <div className="card-body">
                                    <div id="container">
                                        <canvas id="barchart"></canvas>
                                    </div>
                                </div>
                                <div className="card-footer text-muted chart-grid__footer">
                                    Balans
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 pl-lg-2 chart-grid">
                            <div className="card text-center card_cdi">
                                <div className="card-header chart-grid__header">
                                    Betalsätt
                                </div>
                                <div className="card-body">
                                    <div id="container">
                                        <canvas id="stackedbar"></canvas>
                                    </div>
                                </div>
                                <div className="card-footer text-muted chart-grid__footer">
                                    Detaljer
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }

}
//WebPub.contextType = SDOContext; // Function
export default Cas_Dash;
