import React, { useState, useEffect, useContext } from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import { observer } from 'mobx-react'

import SDOContext from '../sdo.js'

import Std_Top from './com/std_top.js';
import Std_Mnu from './com/std_mnu.js';
import Std_Foot from './com/std_foot.js';

import Std_Home from './home.js';
import Std_Dash from './dash.js';
import Std_Oms from './oms.js';
import Std_Pt from './pt.js';
import Std_Rps from './rps.js';
import Std_Units from './units.js';

import * as net from "../lib/net";
import {withRouter} from "../lib/react/withRouter";
import Pos_Home from "../red/pos/home";

function web01() {

    var html =
    <div className="web-content">
    </div>
    ;
    return html;
}

class Std extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pos = props.store.pos;
    this.state = { pgid: 11, mnu_show: true, deps: [] };
    //context.posrend = this;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.props.fetchWells()
    //  .then(res => this.setState({ wells: res.wells }) )
    var deps = [];
    deps.push( {"id": "01", "text": "DEP X1" } );
    deps.push( {"id": "02", "text": "DEP X2" } );
    deps.push( {"id": "03", "text": "DEP X3" } );

    //this.props.store.seo.cs = "5";
    ///this.props.store.seo.unit = "BSENHET";

    var pos = {};
    pos.deps = deps;
    pos.vgrps = [];
    pos.plus = [];
    this.props.store.pos = pos;
    //alert(JSON.stringify(pos));
      this.pos_init();
  }

  fknGo(url) {
    //var sdo = useContext(SDO);
    //alert(fkn);
    //var fkn = row.fid;
    //setState({ row : row,  ma : [] });
    //ctx.fid = fkn;

    //props.history.push(row.url);
    this.props.navigate(url);
   };

  ddo() {
    //alert("POS" + this.state.pgid );
    //this.setState({ status: 1 });
  }

    pos_init() {
    var self = this;

	var req = {};
	req.req = "pos.pos_fkn.pos_init";

    //gda.wndLoading("Hämtar");
    net.sio_req(req)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        //var pos = {};
        //pos.deps = ret.rco.deps;
        //self.props.store.pos = pos;
        if(!self.props.store.pos) self.props.store.pos = {};
        self.props.store.pos.deps = ret.rco.deps;
        self.props.store.pos.vgrps = ret.rco.vgrps;

        //self.setState({ deps: ret.rco });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //alert(JSON.stringify(txt));
    })
    }

  render() {

    return (
    <div className="web-app">
    <div className="web-top">
        <Std_Top store={this.store} />
    </div>
    <div className="web-main">

    <div className="web-row" style={{ height:"100%" }}>

    { this.props.store.cfg.mnu_show ?
    <div className="web-side">
        <Std_Mnu store={this.store} />

    </div>
    : null }

    <div className="web-body">
      <Routes>
        <Route path="oms/*" element={ <Std_Oms store={this.store} />} />
        <Route path="pt/*" element={ <Std_Pt store={this.store} />} />
        <Route path="rps/*" element={ <Std_Rps store={this.store} />} />
        <Route path="units/*" element={ <Std_Units store={this.store} />} />
        <Route path="home" element={ <Std_Home store={this.store} />} />
        <Route path="*" element={ <Std_Dash store={this.store} />} />
      </Routes>
    </div>

    </div>

    </div>

        <Std_Foot store={this.store} />

    </div>
  );
}
}
//Sys.contextType = ADOContext;

export default withRouter(observer(Std));
