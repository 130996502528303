import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../../lib/net";
import Dd_Sel from "../../../lib/ui/dd_sel";
import Fr_Input from "../../../lib/ui/fr_input";

import Key_Edit from "./key_edit";

class Cas_Edit extends React.Component {
  constructor(props, context) {
    super(props);

     this.selitems = [
        {fkn: 'setup', text: 'INSTÄLLNINGAR'},
        {fkn: 'upd', text: 'UPPDATERA'},
        {fkn: 'sys', text: 'SYSTEM'},
        {fkn: 'logout', text: 'LOGGA UT'}
    ];

    this.state = {};
    this.state.ynq = [];
    this.state.paa = [];
    this.state.aa = [];

    this.state.cso = {};
    if(props.wnds) {
        this.state.cso = props.wnds.cso;
        this.state.cso.key = {};
    }
    this.state.wnds = {};
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      this.cr_key();
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }

    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }

  wnd_close() {
      if(this.props.close) this.props.close(this.props.ctx);
      else this.props.ctx.setState({ wnds: {} });
	};
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }

  fknSel(sel) {
    //alert(JSON.stringify(fkn));
      if(sel == "rvo") {
        this.setState({wnd_rvo: true});
      }
      if(sel == "gst") {
        this.setState({wnd_gst: true});
      }
  }
  rvo_close(ctx) {
      ctx.setState({ wnd_rvo: false });
  };

  sel_fkn(sel) {
    //alert(JSON.stringify(fkn));
      if(sel.fkn == "logout") {
      }
  }
  cr_key() {
    var self = this;
    var prm = {};
    prm.req = "pos.pos_cr.cr_key";
    prm.hkod = this.state.cso.ser00.s0hkod;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        var cso = self.state.cso;
        cso.key = ret.rco;
        self.setState({ cso: cso });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

  cso_save() {
    var self = this;
    var prm = {};
    prm.req = "pos.pos_cr.cr_upd";
    prm.ser00 = this.state.cso.ser00;
    prm.ser01 = this.state.cso.ser01;
    prm.ser02 = this.state.cso.ser02;
    prm.ser03 = this.state.cso.ser03;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.ok));
        if(ret.ok != "000") {
            alert("Kan ej spara");
            return;
        }
       if(self.props.close) self.props.close(self.props.ctx);
       else self.props.ctx.setState({ wnds: {} });

        //self.setState({cso: cso });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

  val_save(name, val) {
      var self = this;
      var cso = self.state.cso;

      var na = name.split(".");
      var n1 = na[0];
      var n2 = na[1];
      cso[n1][n2] = val
      self.setState({cso: cso });
  }
  cas_key() {
    var self = this;
    var wnds = {};
    wnds.caskey = true;
    wnds.cso = self.state.cso;
    self.setState({wnds: wnds });
  }

  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "";

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="m2_backdrop" /> : null }
        <div className="m2_dlg mx_80 flex-col" style={ this.dlg_style() }>
        <div className="flex-row " style={{padding:"0px",height:"50px",lineHeight:"50px", background: "#1a456b", color: "#fff"}}>
                <div className="flex-row flex" style={{padding:"0px 5px", fontSize: "22px"}}>
                    <div>Kassör { this.state.rumsnr }</div>
                </div>
            <div className="wnd-close-btn" onClick={() => this.wnd_close() }>X</div>
        </div>
        <div className="mx_content flex">

            <div className="divider_orange"></div>

            <form className="cdi_f03">
            <div className="flex-row py-2">
                    <Fr_Input label="ID" className="text-left w250" text={this.state.cso.ser00.s0serv} getValue={(e)=> this.val_save("ser00.s0serv", e) } />
                    <Fr_Input label="Pnr" className="text-left w250" text={this.state.cso.ser02.s2fodnr} getValue={(e)=> this.val_save("ser02.s2fodnr", e) } />
            </div>
            <div className="flex-row py-2">
                    <Fr_Input label="Namn" className="text-left w350" text={this.state.cso.ser00.s0namn} getValue={(e)=> this.val_save("ser00.s0namn", e) } />
            </div>
            <div className="flex-row py-2">
                    <Fr_Input label="Förkortning" className="text-left w250" text={this.state.cso.ser00.s0kort} getValue={(e)=> this.val_save("ser00.s0kort", e) } />
                    <Fr_Input label="Avdelning" className="text-left w150" text={this.state.cso.ser00.s0kstns} getValue={(e)=> this.val_save("ser00.s0kstns", e) } />
            </div>
            <div className="flex-row py-2">
                    <Fr_Input label="Kassalåda" className="text-left w150" text={this.state.cso.ser00.s0kassa} getValue={(e)=> this.val_save("ser00.s0kassa", e) } />
            </div>
            <div className="divider_green"></div>
            <div className="flex-row py-2">
                    <Fr_Input label="Auktoritet" className="text-left w150" text={this.state.cso.ser00.s0aukt} getValue={(e)=> this.val_save("ser00.s0aukt", e) } />
            </div>
            <div className="flex-row py-2">
                    <Fr_Input label="Signatur" className="text-left w150" text={this.state.cso.ser00.s0hkod} getValue={(e)=> this.val_save("ser00.s0hkod", e) } />
            </div>
            <div className="flex-row py-2">
                    <Fr_Input label="Nyckeltyp" className="text-left w150" text={this.state.cso.key.n0nyckeltyp} getValue={(e)=> this.val_key_save("n0nyckeltyp", e) } />
            </div>
            <div className="flex-row py-2">
                    <Fr_Input label="Nyckel" className="text-left w450" text={this.state.cso.key.n0nyckelid} getValue={(e)=> this.val_key_save("n0nyckelid", e) } />
            </div>
            <div className="divider_green"></div>
            </form>


        </div>
        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex-row"></div>
            <button className="btn btn-hw-red w250" onClick={() => this.cas_key() } >
                NYCKEL
            </button>
            <div className="flex-row"></div>
            <button className="btn btn-hw-red w250" onClick={() => this.cso_save() } >
                SPARA
            </button>
        </div>
      </div>
      { this.state.wnds.caskey ? <Key_Edit wnds={ this.state.wnds } ctx={this} store={this.props.store} backdrop={true}/> : null }
      </div>
	;
	return html;
  };
}

export default Cas_Edit;