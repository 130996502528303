import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../../lib/net";
import Fr_Input from "../../../lib/ui/fr_input";
import Fr_Select from "../../../lib/ui/fr_select";
import ddo from "../../../ddo";

class Cond_New extends React.Component {
  constructor(props, context) {
    super(props);

    this.pos = props.store.pos;
    this.state = {};
    this.state.ynq = [];
    this.state.sval = [];

      this.state.dao = {};
      this.state.dao.con00 = {};
      this.state.dao.con00.c0avd = ddo.cache.avd;
      this.state.dao.con01 = {};
    this.state.aa = [];
    this.state.cval = [
                {id: '001', text: 'ANTAL GER NYTT PRIS'},
                {id: '002', text: 'ANTAL GER RABATT PÅ PRIS'},
                {id: '003', text: 'PRISÄNDRING GENOM RABATT'}
            ];

  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.btn_grps();
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    tab_style(tabid) {
      var css = {};
      css.display = "none";
      if(this.state.tabval === tabid) css.display = "block";
      return css;
    }
    tab_head(tabid) {
      var css = {};
      css.background = "#fff";
      css.color = "#000";
      if(this.state.tabval === tabid) {
          css.background = "#080";
          css.color = "#fff";
      }
      return css;
    }
    grp_style(row) {
      var css = {};
      css.background = "#008";
      if(this.state.gnr === row.vgrupp) css.background = "#080";
      return css;
    }
    prn_val() {
      alert(this.state.pbo.plu00.p0namn);
      alert(this.state.pbo.plu00.p0pris);
      alert(this.state.pbo.plu02.p2vgrupp);
    }

    formchange (e) {
      const name = e.target.name;
      const value = e.target.value;
      var pbo = this.state.pbo;
      var na = name.split(".");
      pbo[na[0]][na[1]] = value;
      this.setState({pbo: pbo});
      //setState({[name]: value});
    }

    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    handleClose = () => {
		this.props.ctx.setState({ condnew: false });
	};
    handleChange = (event, newValue) => {
        //alert(newValue);
        this.setState({ix: newValue});
      };
    toggle() {
      this.setState({modal: false});
	};

  submitHandler = event => {
    event.preventDefault();
    event.target.className += " was-validated";
  };

  changeHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  };


  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  cssCols (cid) {
      var css = {};
      if(this.state.cols[cid]) css.width = this.state.cols[cid].toString() + "px";
      //if(cid == "c01") alert(JSON.stringify(this.state.cols[cid]));
      return css;
  }
  syncCols (tb) {

    if (!document.getElementById(tb)) return;

    var cols = {};
    var ix = document.getElementById(tb).rows.length - 1;
    var ic = document.getElementById(tb).rows[0].cells.length;
    var ci = 0;
    while(ci < ic) {
        var cxi = ci + 1;
        var col = "c" + cxi.toString().padStart(2, "0");
        cols[col] = document.getElementById(tb).rows[ix].cells[ci].clientWidth + 1;
        ci = ci + 1;
    }
    this.setState({cols: cols });
    //alert(JSON.stringify(this.state.cols));
    //this.forceUpdate();
  };
  cond_save() {
    var self = this;
      //alert(JSON.stringify(this.state.dao));
    var prm = {};
    prm.req = "pos.pos_cond.cond_add";
    prm.avd = this.state.avd;
    prm.dao = this.state.dao;

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        alert(JSON.stringify(ret));

        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }
    val_save(name, value) {
        var dao = this.state.dao;
        var na = name.split(".");
        dao[na[0]][na[1]] = value;
        this.setState({dao: dao});
    }

  render() {
    if(!this.props.show) return "";

    //alert(this.props.ctx.state.lro);

    var title = this.props.title;
    if(!title) title = "Knappar";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        <div className="m2_backdrop" />
        <div className="m2_dlg mx_70 flex-col" style={ this.dlg_style() } >
        <div className="mx_content flex">

            <div className="mx-5">
                <div className="flex-row" style={{padding:"5px",height:"35px"}}>
                    <div className="flex-col flex">
                        <div>NYTT VILLKOR:</div>
                    </div>
                    <div className="flex-col">
                        <div>UPPDATERA</div>
                    </div>
                </div>
            </div>
            <div className="divider_orange"></div>

            <div className="cdi_f03 mx-5">
                <div className="flex-row">
                    <div className="flex-col">
                        <Fr_Select flex idval="avd" label="Avd" className="text-left w250" text={this.state.dao.c0avd} items={this.props.store.pos.deps} getValue={(e)=> this.val_save("con00.c0avd", e) } />
                        <Fr_Input label="PLU" className="text-left w150" text={this.state.dao.c0plu} getValue={(e)=> this.val_save("con00.c0plu", e) } maxLength="4" />
                        <Fr_Select flex label="VILLKOR" className="text-left w250" text={this.state.dao.c0cond} items={this.state.cval} getValue={(e)=> this.val_save("con00.c0cond", e) } />
                        <Fr_Input label="KOPPLING" className="text-left w150" text={this.state.dao.c0koppl} getValue={(e)=> this.val_save("con00.c0koppl", e) } maxLength="3" />
                    </div>
                </div>
                <div className="py-3">
                    <div className="divider_orange"></div>
                </div>
                <div className="flex-row">
                    <div className="flex-col">
                        <Fr_Input label="Antal" className="text-left w250" text={this.state.dao.c0antal} getValue={(e)=> this.val_save("con00.c0antal", e) } maxLength="2" />
                        <Fr_Input label="Pris" className="text-left w250" text={this.state.dao.c0pris} getValue={(e)=> this.val_save("con00.c0pris", e) } maxLength="4" />
                        <Fr_Input label="Plu 2" className="text-left w150" text={this.state.dao.c0prod2} getValue={(e)=> this.val_save("con00.c0prod2", e) } maxLength="3" />
                        <Fr_Input label="Pris 2" className="text-left w250" text={this.state.dao.c0pris1} getValue={(e)=> this.val_save("con00.c0pris1", e) } maxLength="3" />
                    </div>
                </div>

        </div>

        </div>
        <div className="mx_foot flex-row">
            <button className="btn btn-hw-red w250" onClick={() => this.handleClose() } >
                STÄNG
            </button>
            <div className="flex"></div>
            <button className="btn btn-hw-green w250" onClick={() => this.cond_save() } >
                SPARA
            </button>
        </div>
      </div>
      </div>
	;
	return html;
  };
}

export default Cond_New;