import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";

import Kreg_Edit from "./fkn/kreg_edit.js";
import Kreg_Add from "./fkn/kreg_add.js";
import Kreg_Tech from "./fkn/kreg_tech.js";
import Td_Sel from "../../lib/ui/td_sel";
import Wnd_Error from "../../lib/ui/wnd_error";
import Wnd_Ok from "../../lib/ui/wnd_ok";
import Wnd_Confirm from "../../lib/ui/wnd_confirm";
import Dd_Sel from "../../lib/ui/dd_sel";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Kreg_Kund extends React.Component {
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.state = {};
    this.state.seldate = new Date();
    this.state.avd = "01";
    this.state.kaa = [];
    this.state.kas = [];
    this.state.kro = {};
    this.state.aa = [];
      this.state.cols = {};
      this.state.wnds = {};
      this.state.sortid = "";
      this.state.sortdir = 1;
      this.state.msg = {};
      this.state.sz_val = "";

      this.selitems = [
          {fkn: 'edit', text: 'ÖPPNA'},
          {fkn: 'remove', text: 'TA BORT'}
      ];
      this.fknitems = [
          {fkn: 'kreg_import', text: 'IMPORTERA REGISTER'},
          {fkn: 'kreg_pub', text: 'EXPORTERA REGISTER'},
          {fkn: 'kreg_tech', text: 'TEKNIKER BERABETNING'}
      ];
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.kreg();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 225;
      css.overflow = "auto";
      return css;
  }
    th_style(id) {
        var css = {};
        css.cursor = "pointer";
        if(id == this.state.sortid) {
            if(this.state.sortdir) css.color = "#800";
            else css.color = "#080";
        }
        return css;
    }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

    kreg_add(row) {
        var self = this;
        var wnds = {};
        wnds.add = 1;
        wnds.kid = "";
        wnds.cb = this.cb_kreg;
        this.setState({ wnds: wnds });
    }
    kreg_edit(row) {
        var self = this;
        var wnds = {};
        wnds.edit = 1;
        wnds.kid = row.krr00.kid;
        wnds.kro = row;
        wnds.cb = this.cb_kreg;
        this.setState({ wnds: wnds });
    }
    cb_kreg(ctx, rsp) {
        ctx.setState({wnds: {} });
        ctx.kreg();
    }
    fkn_close() {
        this.setState({edit: false });
        //this.props.history.push("cas");
        //this.login_srv(uid, pwd);
    }

    remove_kid(row) {
        var self = this;
        var prm = {};
        prm.req = "kreg.kreg_wrk.kreg_del";
        prm.krr00 = row.krr00;

        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret.rca));

            if(ret.ok != "000") {
                return;
            }
            var msg = { info: false, ok: true, text: "Borttagen ..."};
            self.setState({msg: msg });
            setTimeout(function(){
                self.setState({msg: {} });
                self.kreg();
                return;
            }, 1000);
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }

    kreg() {
        var self = this;
        var prm = {};
        prm.req = "kreg.kreg_wrk.kreg_list";

        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret.rca));

            if(ret.ok != "000") {
                return;
            }
            self.setState({kaa: ret.rca, kas: ret.rca }, function() {
                self.syncTb("kidTable");
            });
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }
    syncTb(tb){
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
            tdElements = tbel.getElementsByTagName('td');

        for (let i = 0; i < thElements.length; i++) {
            if(!tdElements[i]) continue;
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
                width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
    tb_sort(typ) {
        var self = this;
        //var rvs = self.state.rvs;
        var kaa = self.state.kaa;
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;

        if(sortid != typ) sortdir = 0;
        else {
            if(sortdir) sortdir = 0;
            else sortdir = 1;
        }

        var f1 = "krr00";
        var f2 = "kid";
        if(typ == "company") { f1 = "krr00"; f2 = "company"; }
        if(typ == "namn") { f1 = "krr00"; f2 = "namn"; }
        if(typ == "orgnr") { f1 = "krr02"; f2 = "orgnr"; }
        if(typ == "tfn") { f1 = "krr03"; f2 = "tfn"; }
        if(typ == "mobil") { f1 = "krr03"; f2 = "mobil"; }
        if(typ == "email") { f1 = "krr03"; f2 = "email"; }
        if(typ == "betvillkor") { f1 = "krr00"; f2 = "betvillkor"; }

        if(true) {
            if(sortdir) kaa.sort((a,b) => (a[f1][f2] < b[f1][f2]) ? 1 : ((b[f1][f2] < a[f1][f2]) ? -1 : 0));
            else kaa.sort((a,b) => (a[f1][f2] > b[f1][f2]) ? 1 : ((b[f1][f2] > a[f1][f2]) ? -1 : 0));
        }
        self.setState({ kaa: kaa, sortid: typ, sortdir: sortdir });
    }
    th_icon(typ) {
        var cls = "";
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;
        if(sortid == typ)
        {
            if(sortdir) cls = "fa fa-caret-up";
            else cls = "fa fa-caret-down";
        }
        return cls;
    }
    kreg_tech() {
        var self = this;
        var wnds = {};
        wnds.tech = 1;
        wnds.cb = this.cb_kreg;
        this.setState({ wnds: wnds });
    }
    sel_row_fkn(row, sel) {
        if(sel.fkn == "edit") {
            this.kreg_edit(row);
        }
        if(sel.fkn == "remove") {
            this.remove_kid(row);
        }
    }
    sel_fkn(sel) {
        //alert(JSON.stringify(fkn));
        if(sel.fkn == "kreg_import") {
            //this.kreg_import();
        }
        if(sel.fkn == "kreg_export") {
            //this.kreg_export();
        }
        if(sel.fkn == "kreg_tech") {
            this.kreg_tech();
        }
    }
    tb_filtr() {
        var self = this;
        var kas = self.state.kas;
        var sz = this.state.sz_val;
    
        if(sz.length < 1) {
          self.setState({ kaa: kas });
            return;
        }
        sz = sz.toUpperCase();
        var kaa = kas.filter(function(row) {
              var brc = false;
              if(row.krr00.kid.toUpperCase().includes(sz)) brc = true;
              if(row.krr00.company.toUpperCase().includes(sz)) brc = true;
              if(row.krr00.namn.toUpperCase().includes(sz)) brc = true;
              if(row.krr02.orgnr.toUpperCase().includes(sz)) brc = true;
              if(row.krr03.tfn.toUpperCase().includes(sz)) brc = true;
              return brc;
        });
        self.setState({kaa: kaa });
        }
    
    sz_style() {
        var css = {};
        css.height = "40px";
        css.lineHeight = "40px";
        css.padding = "0px 10px";
        css.fontSize = "12px";
        css.cursor = "pointer";
        return css;
    }

    sz_keydown (e) {
    //e.preventDefault();
      const key = e.key;
      const keyCode = e.keyCode;

      if(keyCode == 13) {
          this.tb_filtr();
      };
      if(keyCode == 27) {
          this.setState({sz_val: ""});
      };
  }
  sz_change (e) {
      const name = e.target.name;
      const value = e.target.value;
      this.setState({sz_val: value});
      //setState({[name]: value});
  }

  render() {
    return (
    <div className="web-app">

    <div className="flex-row flex-space-between">
        <div className="label_h03">Kunder</div>
        <div className="flex"></div>
        <input className="txt sztxt" value={this.state.sz_val} placeholder="Ange söktext..."  style={ this.sz_style() } onChange={(e) => this.sz_change(e)} onKeyDown={(e) => this.sz_keydown(e)} />
        <button className="btn btn-hw-blue w150" onClick={() => this.kreg_add() } aria-label="System" >
            Ny
        </button>
        <button className="btn btn-hw-blue w150" onClick={() => this.kreg() } aria-label="System" >
            Visa
        </button>
        <Dd_Sel items={ this.fknitems } cls="web-top-icon" getValue={(e)=> this.sel_fkn(e)} />
    </div>
    <div className="divider"></div>

        <div className="web-col">
        <div id="kidTable">
        <table className="table table-bordered table-hover tb" style={{marginBottom: "0px"}}>
            <thead>
            <tr>
                <th style={ this.th_style("kid" ) } onClick={() => this.tb_sort("kid") }>KID <i className={ this.th_icon("kid") }></i></th>
                <th style={ this.th_style("company" ) } onClick={() => this.tb_sort("company") }>Företag <i className={ this.th_icon("company") }></i></th>
                <th style={ this.th_style("namn" ) } onClick={() => this.tb_sort("namn") }>Namn <i className={ this.th_icon("namn") }></i></th>
                <th style={ this.th_style("orgnr" ) } onClick={() => this.tb_sort("orgnr") }>Orgnr <i className={ this.th_icon("orgnr") }></i></th>
                <th style={ this.th_style("tfn" ) } onClick={() => this.tb_sort("tfn") }>Telefon <i className={ this.th_icon("tfn") }></i></th>
                <th style={ this.th_style("mobil" ) } onClick={() => this.tb_sort("mobil") }>Mobil <i className={ this.th_icon("mobil") }></i></th>
                <th style={ this.th_style("email" ) } onClick={() => this.tb_sort("email") }>Epost <i className={ this.th_icon("email") }></i></th>
                <th style={ this.th_style("betvillkor" ) } onClick={() => this.tb_sort("betvillkor") }>Villkor <i className={ this.th_icon("betvillkor") }></i></th>
                <th style={ this.th_style("" ) }></th>
            </tr>
            </thead>
        </table>
        <div style={ this.tb_style() }>
       <table className="table table-bordered table-hover tb">
            <tbody>
            { this.state.kaa.map((row, key) =>
            <tr key={ key } onDoubleClick={() => this.kreg_edit(row) }>
                <td>{ row.krr00.kid }</td>
                <td>{ row.krr00.company }</td>
                <td>{ row.krr00.namn }</td>
                <td>{ row.krr02.orgnr }</td>
                <td>{ row.krr03.tfn }</td>
                <td>{ row.krr03.mobil }</td>
                <td>{ row.krr03.email }</td>
                <td>{ row.krr00.betvillkor }</td>
                <td>
                    <Td_Sel items={ this.selitems } getValue={(e)=> this.sel_row_fkn(row, e)} />
                </td>
            </tr>
            )}
            </tbody>
        </table>
        </div>
        </div>
    </div>
        { this.state.wnds.add ? <Kreg_Add wnds={this.state.wnds} ctx={this} backdrop={true}/> : null }
        { this.state.wnds.edit ? <Kreg_Edit wnds={this.state.wnds} ctx={this} backdrop={true}/> : null }
        { this.state.wnds.tech ? <Kreg_Tech wnds={this.state.wnds} ctx={this} backdrop={true}/> : null }
        <Wnd_Error msg={this.state.msg} ctx={this} />
        <Wnd_Ok msg={this.state.msg} ctx={this} />
        <Wnd_Confirm msg={this.state.msg} ctx={this} />
   </div>
  );
    }
}
export default Kreg_Kund;
