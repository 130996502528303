import React, { useState, useEffect, useContext } from 'react';
import {Route, Routes} from "react-router-dom";
import {withRouter} from "../lib/react/withRouter";
import { observer } from 'mobx-react'
import sso from "../sso";

import Stat_Home from './stat/home.js';
import Stat_Total from './stat/total.js';
import Stat_Grp from './stat/grp.js';
import Stat_HH from './stat/hh.js';
import Stat_Tp from './stat/tp.js';
import Stat_Oms from './stat/oms.js';
import Stat_Week from './stat/week.js';
import Stat_Trs from './stat/trs.js';
import Stat_Art from './stat/art.js';
import Stat_Drift from './stat/drift.js';

function pg_home() {

    var html =
    <div className="web-content">
      Hem
    </div>
    ;
    return html;
}

class Pos_Stat extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.state = { pgid: 11, deps: [] };
    //context.posrend = this;
      var po = {};
      po.total = { "fid": "f01", "url": "/pos/stat/total", "name": "Totalstatus", "active": false };
      po.grp = { "fid": "f01", "url": "/pos/stat/grp", "name": "Gruppstatus", "active": false };
      po.hh = { "fid": "f01", "url": "/pos/stat/hh", "name": "Timstatus", "active": false };
      po.week = { "fid": "f01", "url": "/pos/stat/week", "name": "Veckostatus", "active": false };
      po.tp = { "fid": "f01", "url": "/pos/stat/tp", "name": "Tidperiod", "active": false };
      po.oms = { "fid": "f01", "url": "/pos/stat/oms", "name": "Omsättning", "active": false };
      po.trs = { "fid": "f01", "url": "/pos/stat/trs", "name": "Transaktioner", "active": false };
      po.art = { "fid": "f01", "url": "/pos/stat/art", "name": "Artiklar", "active": false };
      po.drift = { "fid": "f01", "url": "/pos/stat/drift", "name": "Drift", "active": false };
      this.state.po = po;
      this.state.pa = [];
      this.state.pa = Object.values(po);
  }
    componentDidUpdate() {
    }
    componentWillUnmount() {
    }
    componentDidMount() {
      /*
        var path = window.location.pathname;
        if(path.substr(-1) == "/") path = path.substring(0, path.length - 1);
        var op = path.split("/").pop();

        var po = this.state.po;
        if(po[op]) {
            po[op].active = true;
            //this.props.store.fkn = po[op];
            sbo.state.fkn = po[op].name;
            //alert(JSON.stringify(sbo.state));
        }
        var pa = Object.values(po);
        this.setState({ po: po, pa: pa });

       */
    }
    fknGo(row) {
        sso.seo.sfkn = row;
        this.props.navigate(row.url);
    }
    br_css(row) {
        var css = {};
        if(sso.seo.sfkn && (row.url == sso.seo.sfkn.url)) css.color = "#080";
        //var brc = false;
        //if(row.url == sbo.state.url) brc = true;
        return css;
    }

    render() {
        return (
            <div className="web-app">
                { this.props.store.cfg.bread ?
                    <div className="web-bread">
                        <div className="breadcrumb">
                            { this.state.pa.map((row, key) =>
                                <div key={key} className="breadcrumb-item" style={this.br_css(row)} onClick={() => this.fknGo(row)}>{row.name}</div>
                            )}
                            <div className="flex"></div>
                            <div className="cdigreen">{ sso.sdo.sfkn ? sso.sdo.sfkn.name : null }</div>
                        </div>
                    </div>
                    : null }
        <Routes>
          <Route path="/xx" component={pg_home} />
            <Route path="total" element={ <Stat_Total store={this.store} />} />
            <Route path="grp" element={ <Stat_Grp store={this.store} />} />
            <Route path="hh" element={ <Stat_HH store={this.store} />} />
            <Route path="week" element={ <Stat_Week store={this.store} />} />
            <Route path="tp" element={ <Stat_Tp store={this.store} />} />
            <Route path="oms" element={ <Stat_Oms store={this.store} />} />
            <Route path="trs" element={ <Stat_Trs store={this.store} />} />
            <Route path="art" element={ <Stat_Art store={this.store} />} />
            <Route path="drift" element={ <Stat_Drift store={this.store} />} />
            <Route path="*" element={ <Stat_Home store={this.store} />} />
        </Routes>
    </div>

  );
}
}
//Sys.contextType = ADOContext;

export default withRouter(observer(Pos_Stat));

/*
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb my-breadcrumb">
                <li className="breadcrumb-item"><a href="/pos/stat/total">Total</a></li>
                <li className="breadcrumb-item"><a href="/pos/stat/grp">Grupp</a></li>
                <li className="breadcrumb-item"><a href="/pos/stat/hh">Tim</a></li>
                <li className="breadcrumb-item"><a href="/pos/stat/week">Vecko</a></li>
                <li className="breadcrumb-item active" aria-current="page">Trans</li>
                <li className="breadcrumb-item"><a href="/pos/stat/art">Art</a></li>
                <li className="breadcrumb-item"><a href="/pos/stat/drift">Drift</a></li>
            </ol>
        </nav>
*/