import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../../../lib/net";
import Fr_Cal from "../../../../lib/ui/fr_cal";
import Fr_Input from "../../../../lib/ui/fr_input";
import Fr_Select from "../../../../lib/ui/fr_select";
import Wnd_Error from "../../../../lib/ui/wnd_error";
import Wnd_Ok from "../../../../lib/ui/wnd_ok";
import Wnd_Info from "../../../../lib/ui/wnd_info";
import Wnd_Confirm from "../../../../lib/ui/wnd_confirm";

class Pkat_Rt_Add extends React.Component {
  constructor(props, context) {
    super(props);

    this.pms = props.store.pms;

    this.state = {};
    this.state.mka = [];

    this.state.gnr = "001";
    //this.state.lro = props.lro;
    this.state.ynq = [
                {id: '0', text: 'NEJ'},
                {id: '1', text: 'JA'}
            ];

      this.state.wnds = {};
      this.state.msg = {};
      this.state.cols = {};
    //this.state.tr00 = {};
      this.state.rtp = {};
      this.state.pto = {};
      this.state.pko = {};
      if(props.wnds.pko) {
          this.state.pko = props.wnds.pko;
      }
      if(props.wnds.rtp) {
          //this.state.rtp = props.wnds.rtp;
          //this.state.tr00 = props.wnds.tro;
          //this.state.tro = JSON.parse(JSON.stringify(props.wnds.tro));
      }
      this.state.rt = "";
      this.state.rta = [];
      var first = 1;
      for(var rto of this.pms.rta) {
          var o = {};
          o.id = rto.r1typ;
          o.text = rto.r1typ;
          if(first) { this.state.rt = o.id; first = 0; }
          this.state.rta.push(o);
      }
      var rtp = {};
      rtp.n1bastyp = "";
      rtp.n1typ = "";
      rtp.n1rumstyp = "";
      rtp.n1todatum = "";
      rtp.n1pers = "";
      rtp.n1blank = "";
      rtp.n1pris = "";
      rtp.n1filler = "";
      rtp.n1sign = "";
      rtp.n1updtime = "";
      this.state.rtp = rtp;

      var pto = {};
      pto.mon = "";
      pto.tue = "";
      pto.wed = "";
      pto.thu = "";
      pto.fri = "";
      pto.sat = "";
      pto.sun = "";
      this.state.pto = pto;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      //if(!this.props.wnds.tro) this.tro_init();
      //this.rtp_init();
      //this.pto_init();
  }

    rtp_init() {
        var rtp = {};
        rtp.n1bastyp = "";
        rtp.n1typ = "";
        rtp.n1rumstyp = "";
        rtp.n1todatum = "";
        rtp.n1pers = "";
        rtp.n1blank = "";
        rtp.n1pris = "";
        rtp.n1filler = "";
        rtp.n1sign = "";
        rtp.n1updtime = "";
        this.setState({ rtp: rtp });
    }
    pto_init() {
        var pto = {};
        pto.mon = "";
        pto.tue = "";
        pto.wed = "";
        pto.thu = "";
        pto.fri = "";
        pto.sat = "";
        pto.sun = "";
        this.setState({ pto: pto });
    }
    pto_set() {
        var rtp = this.state.rtp;
        var pos = 0;
        var pto = {};
        pto.mon = rtp.n1pris.substr(pos, 10).cdipris(); pos = pos + 10;
        pto.tue = rtp.n1pris.substr(pos, 10).cdipris(); pos = pos + 10;
        pto.wed = rtp.n1pris.substr(pos, 10).cdipris(); pos = pos + 10;
        pto.thu = rtp.n1pris.substr(pos, 10).cdipris(); pos = pos + 10;
        pto.fri = rtp.n1pris.substr(pos, 10).cdipris(); pos = pos + 10;
        pto.sat = rtp.n1pris.substr(pos, 10).cdipris(); pos = pos + 10;
        pto.sun = rtp.n1pris.substr(pos, 10).cdipris(); pos = pos + 10;
        this.setState({ pto: pto });
    }
    pto_save() {
        var rtp = this.state.rtp;
        var pto = this.state.pto;
        var pos = 0;
        rtp.n1pris = "";
        rtp.n1pris = rtp.n1pris + pto.mon.pris2cdi().ztrim().tonum(10);
        rtp.n1pris = rtp.n1pris + pto.tue.pris2cdi().ztrim().tonum(10);
        rtp.n1pris = rtp.n1pris + pto.wed.pris2cdi().ztrim().tonum(10);
        rtp.n1pris = rtp.n1pris + pto.thu.pris2cdi().ztrim().tonum(10);
        rtp.n1pris = rtp.n1pris + pto.fri.pris2cdi().ztrim().tonum(10);
        rtp.n1pris = rtp.n1pris + pto.sat.pris2cdi().ztrim().tonum(10);
        rtp.n1pris = rtp.n1pris + pto.sun.pris2cdi().ztrim().tonum(10);
        this.setState({ rtp: rtp });
        return rtp;
    }

    tro_init() {
        var tro = {};
        tro.e0bastyp = "";
        tro.e0datum = "";
        tro.e0lopnr = "";
        tro.e0subnr = "";
        tro.e0typ = "";
        tro.e0klockan = "";
        tro.e0filler0 = "";
        tro.e0kontonr = "";
        tro.e0text = "";
        tro.e0antal = "";
        tro.e0pris = "";
        tro.e0tkn = "";
        tro.e0moms = "";
        tro.e0rumsnr = "";
        tro.e0sign = "";
        tro.e0updtime = "";
        //alert(JSON.stringify(rso));
        this.setState({ tro: tro });
        return;
    }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }

    formchange (e) {
      const name = e.target.name;
      const value = e.target.value;
      var pbo = this.state.pbo;
      var na = name.split(".");
      pbo[na[0]][na[1]] = value;
      this.setState({pbo: pbo});
      //setState({[name]: value});
    }

    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    wnd_close = () => {
      if(this.props.close) {
          this.props.close(this.props.ctx);
      }
      else this.props.ctx.setState({ wnds: {} });
	};
    toggle() {
      this.setState({modal: false});
	};

  submitHandler = event => {
    event.preventDefault();
    event.target.className += " was-validated";
  };

  changeHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
    val_rt_ch(e) {
        var rt = this.state.rt;
        var val = e.target.value;
        alert(JSON.stringify(val));
        this.setState({ rt: val });
        return;
    }

    val_rt_save(val) {
        var rtp = this.state.rtp;
        //rtp[id] = val;
        this.setState({ rtp: rtp });
        return;
    }
    val_save(id, val) {
        var rtp = this.state.rtp;
        rtp[id] = val;
        this.setState({ rtp: rtp });
        return;
    }
    val_pris_save(id, val) {
        var pto = this.state.pto;
        pto[id] = val;
        this.setState({ pto: pto });
        return;
    }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  rtp_save() {
    var self = this;
    //var rtp = this.state.rtp;
    //tro.e0text = tro.e0text.fromswe();
    var rtp = this.pto_save();

    //alert(JSON.stringify(rtp));
    rtp.n1todatum = rtp.n1todatum.replaceAll("-", "");
    rtp.n1rumstyp = this.state.rt;
    //alert(JSON.stringify(rtp));

    var prm = {};
    prm.req = "pms.pkat.pkat_rt_add";
    //prm.tr00 = this.state.tr00;
      prm.pko = this.state.pko;
      prm.rtp = rtp;

    net.sio_req(prm)
    .then(function(ret) {
        //alert(JSON.stringify(ret));
        if(ret.ok != "000") {
            var msg = { error: true, info: false, text: "Uppdatering misslyckad ..."};
            self.setState({ msg: msg });
            setTimeout(function() {
                self.setState({ msg: {} });
            }, 1500);
            return;
        }
        var msg = { ok: true, info: false, text: "Uppdatering klar ..."};
        self.setState({ msg: msg });
        setTimeout(function() {
            self.setState({ msg: {} });
        }, 1500);
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

  rtp_del() {
    var self = this;
    var prm = {};
    prm.req = "pms.pkat.pkat_rt_del";
    prm.rtp = this.state.rtp;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));
        if(ret.ok != "000") {
            var msg = { error: true, info: false, text: "Bortagning misslyckad ..."};
            self.setState({ msg: msg });
            setTimeout(function() {
                self.setState({ msg: {} });
            }, 1500);
            return;
        }
        var msg = { ok: true, info: false, text: "Bortagning klar ..."};
        self.setState({ msg: msg });
        setTimeout(function() {
            self.setState({ msg: {} });
        }, 1500);
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

  render() {
    //if(!this.props.show) return "";
    var title = this.props.title;
    if(!title) title = "Resurs";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        <div className="m3_backdrop" />
        <div className="m3_dlg mx_60h flex-col" style={ this.dlg_style() } >
        <div className="mx_content flex">

        <div className="mx_h01">
            <div>LÄGG TILL RUMSTYP</div>
        </div>
            <div className="flex-row" style={{padding:"5px",height:"35px"}}>
                    <div className="flex-col flex">
                    </div>
                    <div className="flex-col">
                        <div>UPPDATERA</div>
                    </div>
            </div>
            <div className="divider_orange"></div>

            <form className="cdi_f03">
            <div className="flex-row">
                <div className="form-group flex-col">
                    <span className="header">Rumstyp</span>
                    <select value={this.state.rt} className="form-control text-left w350" onChange={(e) => this.val_rt_ch(e)}>
                        { this.pms.rta.map((row, key) =>
                            <option key={key} value={ row.r1typ }>{ row.r1typ }</option>
                        )}
                    </select>
                </div>
                <div className="flex"></div>
                <Fr_Input label="Personer" col className="text-left w150" text={this.state.rtp.n1pers} getValue={(e)=> this.val_save("n1pers", e) } />
                <Fr_Cal label="Till Datum" col className="text-left w250" text={this.state.rtp.n1todatum} getValue={(e)=> this.val_save("n1todatum", e) } />
            </div>
                <div className="divider_green"></div>
                <div className="flex-row">
                    <div className="flex">Måndag</div>
                    <Fr_Input noflex className="text-right w250" text={this.state.pto.mon} getValue={(e)=> this.val_pris_save("mon", e) } />
                </div>
                <div className="flex-row">
                    <div className="flex">Tisdag</div>
                    <Fr_Input noflex className="text-right w250" text={this.state.pto.tue} getValue={(e)=> this.val_pris_save("tue", e) } />
                </div>
                <div className="flex-row">
                    <div className="flex">Onsdag</div>
                    <Fr_Input noflex className="text-right w250" text={this.state.pto.wed} getValue={(e)=> this.val_pris_save("wed", e) } />
                </div>
                <div className="flex-row">
                    <div className="flex">Torsdag</div>
                    <Fr_Input noflex className="text-right w250" text={this.state.pto.thu} getValue={(e)=> this.val_pris_save("thu", e) } />
                </div>
                <div className="flex-row">
                    <div className="flex">Fredag</div>
                    <Fr_Input noflex className="text-right w250" text={this.state.pto.fri} getValue={(e)=> this.val_pris_save("fri", e) } />
                </div>
                <div className="flex-row">
                    <div className="flex">Lördag</div>
                    <Fr_Input noflex className="text-right w250" text={this.state.pto.sat} getValue={(e)=> this.val_pris_save("sat", e) } />
                </div>
                <div className="flex-row">
                    <div className="flex">Söndag</div>
                    <Fr_Input noflex className="text-right w250" text={this.state.pto.sun} getValue={(e)=> this.val_pris_save("sun", e) } />
                </div>

            </form>

        </div>
        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex"></div>
            <button className="btn btn-hw-red w250" onClick={() => this.rtp_save() } >
                SPARA
            </button>
        </div>
      </div>
            <Wnd_Error msg={this.state.msg} ctx={this} />
            <Wnd_Ok msg={this.state.msg} ctx={this} />
            <Wnd_Info msg={this.state.msg} ctx={this} />
            <Wnd_Confirm msg={this.state.msg} ctx={this} />

      </div>
	;
	return html;
  };
}

export default Pkat_Rt_Add;