import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";
import {SDOProvider} from "../../sdo";
import Dt_Cal from "../../lib/ui/dt_cal";
import {format, subDays} from "date-fns";
import Td_Sel from "../../lib/ui/td_sel";
import ddo from "../../ddo";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Pos_Week extends React.Component {
  constructor(props, context) {
    super(props);

    this.days = {};
    this.days[1] = "Måndag";
    this.days[2] = "Tisdag";
    this.days[3] = "Onsdag";
    this.days[4] = "Torsdag";
    this.days[5] = "Fredag";
    this.days[6] = "Lördag";
    this.days[7] = "Söndag";

    this.store = props.store;
    this.pos = props.store.pos;
    this.state = {};
    this.state.seldate = new Date();
    this.state.avd = ddo.cache.avd;
    this.state.eka = [];
    this.state.aa = [];

    this.selitems = [
        {fkn: 'open', text: 'VISA'}
    ];
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.st_week();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 250;
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }
  wday(row) {
      var txt = "";
      txt = this.days[row.wday];
      return txt;
  }
  calc_snitt(row) {
      var snitt = "";
      if((row.nrs * 1)) {
        snitt = (row.tot * 1) / (row.nrs * 1);
        snitt = Math.round(snitt, 0).toString();
      }
      snitt = snitt.cdipris();
      return snitt;
  }

  st_week() {
    var tdat = format(this.state.seldate, "yyyy-MM-dd");
    var fdat = format(subDays(this.state.seldate, 7), "yyyy-MM-dd");

    var self = this;
    var prm = {};
    prm.req = "pos.pos_eko.rev_period";
    prm.avd = ddo.cache.avd;
    prm.fdat = fdat;
    prm.tdat = tdat;

    //alert(JSON.stringify(prm));
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));
        self.setState({eka: ret.rca });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }
  sel_row_fkn(row, sel) {
      //alert(JSON.stringify(fkn));
    if(sel.fkn == "open") {
    }
  }

  render() {
    return (
    <div className="web-p00 flex flex-col">
        <div className="">
            Tim
        </div>

    <div className="web-p01">
    <div className="flex-row">
        <Dt_Cal startdatum="2020-02-01" getValue={(e)=> this.setState({seldate: e}) }/>
        <select className="form-control w250 hide" placeholder="Välj avdelning">
            { this.pos.deps.map((row, key) =>
            <option key={key} value={row.id}>{row.text}</option>
            )}
        </select>
        <div className="flex"></div>
        <button className="btn btn-hw-blue w150" onClick={() => this.st_week() } aria-label="System" >
            UPPDATERA
        </button>
    </div>
    <div className="divider_orange"></div>

    <div className="web-row">
        <table className="table table-bordered table-hover tb fixed_header">
            <thead>
            <tr>
                <th>Datum</th>
                <th>Text</th>
                <th>Belopp</th>
                <th>Antal</th>
                <th>Snitt</th>
                <th>Status</th>
                <th></th>
            </tr>
            </thead>
            <tbody style={ this.tb_style() }>
            { this.state.eka.map((row, key) =>
            <tr key={ key } onDoubleClick={() => this.plu_open(row) }>
                <td>{ row.datum }</td>
                <td>{ this.wday(row) }</td>
                <td>{ row.tot.cdipris() }</td>
                <td>{ row.nrs.cdinum() }</td>
                 <td>{ this.calc_snitt(row) }</td>
                <td>{ row.status }</td>
                <td>
                    <Td_Sel items={ this.selitems } getValue={(e)=> this.sel_row_fkn(row, e)} />
                </td>
            </tr>
            )}
            </tbody>
        </table>
    </div>

    </div>
    </div>
  );
    }
}
export default Pos_Week;
