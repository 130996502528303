import React, { useState, useEffect, useContext } from 'react';

import * as utils from "../../assets/utils";
import * as net from "../../lib/net";
import Lang from "../../lib/lang";

import Td_Sel from "../../lib/ui/td_sel";
import Prd_Add from ".//fkn/prd_add";
import Prd_Edit from ".//fkn/prd_edit";
import Prd_Mp from ".//fkn/prd_mp";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Mnu_Prds extends React.Component {
  constructor(props, context) {
    super(props);

    //this.lang = {};
    this.store = props.store;

    this.state = {};
    this.state.today = new Date();
    this.state.avd = "01";
    this.state.aa = [];
    this.state.inhsel = 0;
      this.state.msg = {};
      this.state.rvo = {};

      this.state.maa = [];
      this.state.mpa = [];
    this.state.rvo = {};
    this.state.selrow = {};
    this.state.wnds = {};
    this.state.sortid = "";
    this.state.sortdir = 1;
    this.state.sz_val = "";

    this.selitems = [
        {fkn: 'open', text: 'ÖPPNA'},
        {fkn: 'cancel', text: 'AVBOKA'}
    ];
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.dsi_mp();
  }

  body_style() {
    var css = {};
    css.height = window.innerHeight - 280;
    return css;
  }
  tb_style() {
    var css = {};
    css.height = window.innerHeight - 250;
    css.overflow = "auto";
    return css;
  }
  th_style(id) {
      var css = {};
      css.cursor = "pointer";
      if(id == this.state.sortid) {
          if(this.state.sortdir) css.color = "#800";
          else css.color = "#080";
      }
      return css;
  }
  row_style(row) {
    var srow = this.state.selrow;

    var css = {};
    if(srow == row) {
        css.background = "#080";
        css.color = "#fff";
    }
    return css;
  }

    dsi_mp() {
        var self = this;
        var prm = {};
        prm.req = "dsi.dsi_mp.mp_mnu";
        //prm.req = "dsi.dsi_mp.mp_list";
        //prm.req = "dsi.dsi_mp.mp_web";

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.rca));

                self.setState({ maa: ret.rca, mpa: ret.rca }, function () {
                    self.syncTb("mpaTable");
                });
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    syncTb(tb) {
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
        tdElements = tbel.getElementsByTagName('td');
        if(tdElements.length < 1 ) return;

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
            width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
  sel_row(row) {
    var srow = this.state.selrow;
    if(srow == row) {
      this.setState({selrow: {}});
    }
    else this.setState({selrow: row});
  }
  sel_row_fkn(row, sel) {
      if(sel.fkn == "open") {
          this.sel_gst(row);
      }
  }
    gst_close(ctx) {
        ctx.setState({ wnds: {} });
    };
   tb_sort(typ) {
      var self = this;
      //var rvs = self.state.rvs;
      var rva = self.state.rva;
      var sortid = this.state.sortid;
      var sortdir = this.state.sortdir;

      if(sortid != typ) sortdir = 0;
      else {
        if(sortdir) sortdir = 0;
        else sortdir = 1;
      }

      if(typ == "ank") {
        if(sortdir) rva.sort((a,b) => (a.gsb00.r0ankdatum < b.gsb00.r0ankdatum) ? 1 : ((b.gsb00.r0ankdatum < a.gsb00.r0ankdatum) ? -1 : 0));
        else rva.sort((a,b) => (a.gsb00.r0ankdatum > b.gsb00.r0ankdatum) ? 1 : ((b.gsb00.r0ankdatum > a.gsb00.r0ankdatum) ? -1 : 0));
      }
      self.setState({ rva: rva, sortid: typ, sortdir: sortdir });
   }
   th_icon(typ) {
      var cls = "";
      var sortid = this.state.sortid;
      var sortdir = this.state.sortdir;
      if(sortid == typ)
      {
        if(sortdir) cls = "fa fa-caret-up";
        else cls = "fa fa-caret-down";
      }
      return cls;
    }
    prd_add() {
        var self = this;

        var wnds = {};
        wnds.prdadd = true;
        wnds.cb = self.cb_prd;
        this.setState({wnds: wnds});
    }
    prd_edit(row) {
        var self = this;

        var wnds = {};
        wnds.prdedit = true;
        wnds.mpo = row;
        wnds.cb = self.cb_prd;
        this.setState({wnds: wnds});
    }
    prd_mp() {
        var self = this;

        var wnds = {};
        wnds.prdmp = true;
        wnds.mpo = self.cb_prd;
        this.setState({wnds: wnds});
    }
    cb_prd(ctx) {
        ctx.setState({ wnds: {} });
        ctx.dsi_mp();
    };
    sz_keydown (e) {
        //e.preventDefault();
        const key = e.key;
        const keyCode = e.keyCode;

        if(keyCode == 13) {
            this.prd_sz();
        };
        if(keyCode == 27) {
            this.setState({sz_val: ""});
        };
    }
    sz_change (e) {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({sz_val: value});
        //setState({[name]: value});
    }
    prd_sz() {
        var self = this;
        var maa = self.state.maa;
        var sz = this.state.sz_val;

        var mpa = maa.filter(row => row.text.toUpperCase().includes(sz.toUpperCase()));
        self.setState({ mpa: mpa });
    }

  render() {
    return (
    <div className="web-bas">

    <div className="flex-row">
        <button className="btn btn-hw-blue w200" onClick={() => this.prd_mp() } aria-label="System" >
            KOPPLA
        </button>
        <input className="txt sztxt" value={this.state.sz_val} onChange={(e) => this.sz_change(e)} onKeyDown={(e) => this.sz_keydown(e)} />
        <div className="flex"></div>
        <button className="btn btn-hw-blue w200" onClick={() => this.prd_add() } aria-label="System" >
            LÄGG TILL
        </button>
        <button className="btn btn-hw-green w200" onClick={() => this.dsi_mp() } aria-label="System" >
            UPPDATERA
        </button>
    </div>
    <div className="divider_green"></div>

    <div className="web-row">

        <div id="mpaTable" className="flex">
            <table className="table table-bordered table-hover" style={{marginBottom: "0px"}}>
                <thead>
                <tr>
                    <th style={ this.th_style("mnuid") } onClick={() => this.tb_sort("mnuid") }><Lang>Meny ID <i className={ this.th_icon("mnuid") }></i></Lang></th>
                    <th style={ this.th_style("plu") } onClick={() => this.tb_sort("plu") }><Lang>Plu</Lang> <i className={ this.th_icon("plu") }></i></th>
                    <th style={ this.th_style("text") } onClick={() => this.tb_sort("text") }><Lang>Text</Lang> <i className={ this.th_icon("text") }></i></th>
                    <th style={ this.th_style("prdtyp") } onClick={() => this.tb_sort("prdtyp") }><Lang>Prd typ</Lang> <i className={ this.th_icon("prdtyp") }></i></th>
                    <th style={ this.th_style("sort") } onClick={() => this.tb_sort("sort") }><Lang>Sortering</Lang> <i className={ this.th_icon("sort") }></i></th>
                    <th style={ this.th_style("anm") } onClick={() => this.tb_sort("anm") }><Lang>Anmärkning</Lang> <i className={ this.th_icon("anm") }></i></th>
                    <th></th>
                </tr>
                </thead>
            </table>
            <div style={ this.tb_style() }>
                <table className="table table-bordered table-hover">
                    <tbody>
                    { this.state.mpa.map((row, key) =>
                        <tr key={key} style={ this.row_style(row) } onClick={() => this.sel_row(row)} onDoubleClick={ () => this.prd_edit(row) }>
                            <td>{ row.mnuid }</td>
                            <td>{ row.plu }</td>
                            <td>{ utils.toLang( row.text ) }</td>
                            <td className="text-center">{ row.prdtyp }</td>
                            <td>{ row.sort }</td>
                            <td>{ utils.toLang( row.anm ) }</td>
                            <td>
                                <Td_Sel items={ this.selitems } getValue={(e)=> this.sel_row_fkn(row, e)} />
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
        </div>
    </div>

        { this.state.wnds.prdadd ? <Prd_Add wnds={this.state.wnds} store={this.store} ctx={this} /> : null}
        { this.state.wnds.prdedit ? <Prd_Edit wnds={this.state.wnds} store={this.store} ctx={this} /> : null}
        { this.state.wnds.prdmp ? <Prd_Mp wnds={this.state.wnds} store={this.store} ctx={this} /> : null}
    </div>
  );
    }
}
export default Mnu_Prds;
