import React, { useState, useEffect, useContext } from 'react';
import * as net from "../../../lib/net";

import Pms_Rvo from "../../rv/fb/rvo";
import Pms_Gso from "../../rv/gst/gso";
import Pms_Nota from "../nota/nota";
import Pms_GstFkn from "../inh/gstfkn";
import Pms_RoomFkn from "../inh/roomfkn";
import Pms_Co from "../inh/co";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Pms_AvrSel extends React.Component {
  constructor(props, context) {
    super(props);

    //alert('PBO CTX: ' + JSON.stringify(props.pbo));
   //this.webStore = props.store;
    this.state = {};
    this.state.ynq = [];
    //this.state.vga = [];
    this.state.lso = {};
    //this.state.lro = props.lro;
    this.state.paa = [];
    this.state.aa = [];
    this.state.boknr = "";
    this.state.rvo = {};
    if(props.wnds) {
        this.state.boknr = props.wnds.boknr;
        this.state.rvo = props.wnds.rvo;
        if(!this.state.rvo.charges) this.state.rvo.charges = {};
        if(!this.state.rvo.charges.bokat) this.state.rvo.charges.bokat = "";
        if(!this.state.rvo.charges.saldo) this.state.rvo.charges.saldo = "";
        if(!this.state.rvo.charges.paid) this.state.rvo.charges.paid = "";
        if(!this.state.rvo.charges.topay) this.state.rvo.charges.topay = "";
    }
    this.state.wnds = {};
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }


    wnd_close() {
		this.props.ctx.setState({ wnds: {} });
	};
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  cssCols (cid) {
      var css = {};
      if(this.state.cols[cid]) css.width = this.state.cols[cid].toString() + "px";
      //if(cid == "c01") alert(JSON.stringify(this.state.cols[cid]));
      return css;
  }
  fknSel(sel) {
    //alert(JSON.stringify(fkn));
      if(sel == "rvo") {
          var wnds = {};
          wnds.wnd_rvo = true;
          wnds.boknr = this.state.boknr;
          wnds.rvo = this.state.rvo;
        this.setState({wnds: wnds});
      }
      if(sel == "gstfkn") {
          var wnds = {};
          wnds.gstfkn = true;
          wnds.boknr = this.state.boknr;
          wnds.rvo = this.state.rvo;
        this.setState({wnds: wnds});
      }
      if(sel == "roomfkn") {
          var wnds = {};
          wnds.roomfkn = true;
          wnds.boknr = this.state.boknr;
          wnds.rvo = this.state.rvo;
        this.setState({wnds: wnds});
      }
      if(sel == "co") {
          var wnds = {};
          wnds.co = true;
          wnds.boknr = this.state.boknr;
          wnds.rvo = this.state.rvo;
        this.setState({wnds: wnds});
      }
      if(sel == "nota") {
          var wnds = {};
          wnds.wnd_nota = true;
          wnds.boknr = this.state.boknr;
          wnds.rvo = this.state.rvo;
        this.setState({wnds: wnds});
      }
  }
    rvo_close(ctx) {
        ctx.setState({ wnds: {} });
    };
    gst_close(ctx) {
        ctx.setState({ wnds: {} });
    };

  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "";

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="mx_backdrop" /> : null }
        <div className="mx_dlg mx_80 flex-col" style={ this.dlg_style() }>
            <div className="mx_h01 flex-row" style={{background: "#1a456b", color: "#fff"}}>
                    <div className="flex-col flex">
                        <div>{ this.state.rvo.gsb00.r0rumsnr.cdirumsnr() } { this.state.rvo.gsb00.r0namn }</div>
                    </div>
                    <div className="flex-col">
                        <div>BOKNR: { this.state.boknr }</div>
                    </div>
            </div>
        <div className="mx_content flex">

            <div className="flex-row flex">
            <div className="flex-col flex">

            <div className="txt_box txt_bg_01 flex txt_22">
            <div className="flex-row">
                    <div className="flex-col flex">
                        <div className="txt_lbl">FÖRETAG:</div>
                        <div>{ this.state.rvo.resv00.Company }</div>
                    </div>
                    <div className="flex-col flex">
                        <div className="txt_lbl">TELE:</div>
                        <div>{ this.state.rvo.tele }</div>
                    </div>
            </div>
            <div className="flex-row">
                    <div className="flex-col flex">
                        <div className="txt_lbl">GÄST:</div>
                        <div>{ this.state.rvo.gsb00.r0namn }</div>
                    </div>
                    <div className="flex-col flex">
                        <div className="txt_lbl">EPOST:</div>
                        <div>{ this.state.rvo.gsb00.r0email }</div>
                    </div>
            </div>
            <div className="flex-row">
                    <div className="flex-col flex">
                        <div className="txt_lbl">ANM:</div>
                        <div>{ this.state.rvo.anm }</div>
                    </div>
            </div>
           </div>

             <div className="txt_box txt_bg_01 flex txt_22">
             <div className="flex-row">
                    <div className="flex-col flex">
                        <div className="txt_lbl">RUM:</div>
                        <div>{ this.state.rvo.gsb00.r0rumsnr.cdirumsnr() }</div>
                    </div>
                    <div className="flex-col flex">
                        <div className="txt_lbl">RUMSTYP:</div>
                        <div> { this.state.rvo.r0typ }</div>
                    </div>
            </div>
             <div className="flex-row">
                    <div className="flex-col flex">
                        <div className="txt_lbl">PERIOD:</div>
                        <div>{ this.state.rvo.gsb00.r0ankdatum.cdidate() } - { this.state.rvo.gsb00.r0avrdatum.cdidate() }</div>
                    </div>
                    <div className="flex-col flex">
                        <div className="txt_lbl">GÄSTER:</div>
                        <div>{ this.state.rvo.r0guests }</div>
                    </div>
            </div>

           </div>
           </div>

             <div className="txt_box txt_bg_01 txt_22">
                    <div className="flex-row flex">
                        <div className="txt_lbl flex">BOKAT VÄRDE:</div>
                        <div className="txt_price">{ this.state.rvo.charges.bokat.cdipris() }</div>
                    </div>
                    <div className="flex-row flex">
                        <div className="txt_lbl flex">SALDO:</div>
                        <div className="txt_price">{ this.state.rvo.charges.saldo.cdipris() }</div>
                    </div>
                    <div className="flex-row flex">
                        <div className="txt_lbl flex">BETALT:</div>
                        <div className="txt_price">{ this.state.rvo.charges.paid.cdipris() }</div>
                    </div>
                    <div className="flex-row flex">
                        <div className="txt_lbl flex">ATT BETALA:</div>
                        <div className="txt_price">{ this.state.rvo.charges.topay.cdipris() }</div>
                    </div>
                    <div className="divider_blue"></div>
                    <div className="flex-row flex py-2">
                        <div className="txt_lbl flex">RUMSNR:</div>
                        <div className="txt_price">{ this.state.rvo.gsb00.r0rumsnr.cdirumsnr() }</div>
                    </div>
            </div>

            </div>
            <div className="divider_orange"></div>
        <div className="web-row">
        <div className="web-col flex">
            <div className="wnd-btn" onClick={() => this.fknSel("rvo") } >
                <div>ÖPPNA BOKNING</div>
                <div></div>
            </div>
            <div className="wnd-btn" onClick={() => this.wnd_close() } >
                <FontAwesomeIcon icon="new" size="1x"/>
                <div>STÄNG</div>
            </div>
        </div>
         <div className="web-col flex" style={{padding: "0px 10px"}}>
            <div className="wnd-btn" onClick={() => this.fknSel("gstfkn") } >
                <div>GÄST FUNKTIONER</div>
                <div></div>
            </div>
            <div className="wnd-btn" onClick={() => this.fknSel("roomfkn") } >
                <div>RUMS FUNKTIONER</div>
                <div></div>
            </div>
       </div>
         <div className="web-col flex" style={{padding: "0px 10px"}}>
            <div className="wnd-btn" onClick={() => this.fknSel("co") } >
                <div>CHECKA UT</div>
                <div></div>
            </div>
            <div className="wnd-btn" onClick={() => this.fknSel("nota") } >
                <div>ÖPPNA NOTA</div>
                <div></div>
            </div>
       </div>
       </div>

        </div>
      </div>
            { this.state.wnds.wnd_rvo ?
        <Pms_Rvo close={this.rvo_close} wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
            : null }
            { this.state.wnds.wnd_nota ?
        <Pms_Nota close={this.gst_close} wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
            : null }
            { this.state.wnds.co ?
        <Pms_Co close={this.gst_close} wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
            : null }
            { this.state.wnds.gstfkn ?
        <Pms_GstFkn close={this.gst_close} wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
            : null }
            { this.state.wnds.roomfkn ?
        <Pms_RoomFkn close={this.gst_close} wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
            : null }
    </div>
	;
	return html;
  };
}

export default Pms_AvrSel;