import React, { useState, useEffect, useContext } from 'react';

import * as q from '../../../lib/defer';
import * as net from "../../../lib/net";
import * as utils from "../../../assets/utils";

import Fr_Input from "../../../lib/ui/fr_input";
import Fr_Inpsel from "../../../lib/ui/fr_inpsel";
import Dd_Sel from "../../../lib/ui/dd_sel";

import Wnd_Error from "../../../lib/ui/wnd_error";
import Wnd_Ok from "../../../lib/ui/wnd_ok";
import Wnd_Info from "../../../lib/ui/wnd_info";
import Wnd_Confirm from "../../../lib/ui/wnd_confirm";

import Fr_Inprts from "../../lib/ui/fr_inprts";
import Fr_Inpkats from "../../lib/ui/fr_inpkats";

import Rv_Info from "./rv_info";
import Rv_Kk from "./rv_kk";
import Rv_Sz from "./rv_sz";
import Rv_Logg from "./rv_logg";
import Rv_Bekr from "./bekr/rv_bekr";
import Rv_Cancel from "./fkn/rv_cancel";

import Rv_Hot from "./hot/rv_hot";

import Rv_RtBel from "./br/rt_bel";
import Rv_Br from "./br/rv_br";
import Rv_BrAdd from "./br/br_add";
import Rv_BrEdit from "./br/br_edit";
import Rv_Br_Pkat from "./br/rv_br_pkat";

import Rv_Gsts from "./gst/rv_gsts";
import Rv_GstAdd from "./gst/gst_add";
import Rv_GstWrk from "./gst/gst_wrk";

import Rv_Kf from "./kf/rv_kf";
import Rv_KfBel from "./kf/kf_bel";
import Rv_KfAdd from "./kf/kf_add";
import Rv_Dtg from "./kf/rv_dtg";
import Rv_DtgAdd from "./kf/dtg_add";

import Rv_Doc from "./doc/rv_doc";
import Rv_DocPrds from "./doc/doc_prds";
import Rv_DocRowAdd from "./doc/doc_row_add";
import Rv_DocRowEdit from "./doc/doc_row_edit";
import Rv_DocFreeText from "./doc/doc_freetext";

import Rv_Rs from "./rs/rv_rs";
import Rv_RskAdd from "./rs/rsk_add";

import Rv_Nota from "./nota/rv_nota";

import Fr_Cal from "../../../lib/ui/fr_cal";
import {addDays, differenceInCalendarDays, format, parseISO} from "date-fns";
import sso from "../../../sso";

class Pms_FbRv02 extends React.Component {
  constructor(props, context) {
    super(props);

    this.mnuitems = [
        {fkn: 'rvb', text: 'BYT BOKNINGSBILD'},
        {fkn: 'close', text: 'TVÅNGSSTÄNG'}
    ];
    this.topitems = [
        {fkn: 'setup', text: 'INSTÄLLNINGAR'},
        {fkn: 'upd', text: 'UPPDATERA'},
        {fkn: 'sys', text: 'SYSTEM'},
        {fkn: 'close', text: 'STÄNG'}
    ];

      this.selitems = [
          {fkn: 'save', text: 'SPARA'},
          {fkn: 'bevaka', text: 'BEVAKA'},
          {fkn: 'confirm', text: 'BEKRÄFTA'},
          {fkn: 'logg', text: 'LOGG'},
          {fkn: 'cancel', text: 'AVBOKA'}
      ];
      this.pma = [
          {val: 'kont', text: 'KONTANT'},
          {val: 'kort', text: 'KORT'},
          {val: 'swish', text: 'SWISH'},
          {val: 'fakt', text: 'FAKTURA'}
      ];

    this.store = props.store;
    props.store.pms.rvo = {};
    this.pms = props.store.pms;
    this.pms.rvo.bra = [];

    this.state = {};
    this.state.ynq = [];
    //this.state.vga = [];
      this.state.rta = [];
      this.state.pka = [];
    this.state.bra = [];
    this.state.dca = [];
    this.state.rsa = [];
    this.state.nota = [];
    //this.state.lro = props.lro;
    this.state.paa = [];
    this.state.aa = [];
    this.state.vga = [];
      this.state.bgo = {};
      this.state.brn = {};
    this.state.cols = {};
    this.state.rvinfo = false;
    this.state.rvgsts = false;
    this.state.rvbr = false;
    this.state.rvbradd = false;
    this.state.rvgstadd = false;
    this.state.rvrskadd = false;
    this.state.docrowadd = false;
    //this.state.pbo = {};
    //this.state.pbo = props.pbo;
    this.state.ix = 0;
      this.state.boknr = "";
      this.state.copy = "";
    this.state.part = "01";
    if(props.boknr) this.state.boknr = props.boknr;
    if(props.wnds) {
        if(props.wnds.boknr) this.state.boknr = props.wnds.boknr;
        if(props.wnds.copy) this.state.copy = props.wnds.copy;
    }

    this.state.msg = {};
    this.state.wnds = {};
    this.state.rsv = {};
    this.state.rsv.resv00 = {};
    this.state.rsv.resv01 = {};
    this.state.rsv.resv02 = {};
    this.state.rsv.resv03 = {};
    this.state.rsv.resv04 = {};
    this.state.rsv.anm = {};
    this.state.rsv.resv00.Company = "";
    this.state.rsv.resv01.Name = "";

    this.state.resm = {};
    this.state.resm.resm00 = {};
    this.state.resm.resm01 = {};
    this.state.resm.resm02 = {};
    this.state.resm.resm03 = {};
    this.state.resm.resm04 = {};

      this.state.rvo = {};
    this.state.rvo.bra = [];
    this.state.rvo.nrr = "1";
    this.state.rvo.sum = "0";
    this.state.rvo.rvo = {};
    this.state.kfa = [];

      this.state.today = new Date();
      this.state.tds = parseISO(this.state.today);
      var dat = addDays(this.state.today, 1);

      this.state.bro = {};
      this.state.brn = {};
      this.state.brn.fdat = format(this.state.today, "yyyy-MM-dd");
      this.state.brn.tdat = format(dat, "yyyy-MM-dd");
      this.state.brn.nrr = "1";
      this.state.brn.nrg = "2";
      this.state.brn.rt = "";
      this.state.brn.pkat = "";
      this.state.nra = [
          {val: '0', text: '0', extra: 'st'},
          {val: '1', text: '1', extra: 'st'},
          {val: '2', text: '2', extra: 'st'},
          {val: '3', text: '3', extra: 'st'},
          {val: '4', text: '4', extra: 'st'}
      ];

    this.state.btn_rvtext = "SPARA";
    let pre = this.state.boknr.substr(0, 2);
    if(pre == pre.toLowerCase()) this.state.btn_rvtext = "BOKA";

      /*
      this.segment = [
          {val: 'OTA', text: 'OTA'},
          {val: 'WEB', text: 'WEB'},
          {val: 'GDS', text: 'BOOKING'},
          {val: 'WALKIN', text: 'WALKIN'}
      ];
      */
      this.segment = [
          {val: 'SLH24C', text: 'SLH24C'},
          {val: 'SLHAGENT', text: 'SLHAGENT'},
          {val: 'Booking.com', text: 'Booking.com'},
          {val: 'Expedia/Hotels.com', text: 'Expedia/Hotels.com'},
          {val: 'Mejl', text: 'Mejl'},
          {val: 'Telefon', text: 'Telefon'},
          {val: 'Walk-In', text: 'Walk-In'},
          {val: 'TP-bokning', text: 'TP-bokning'},
          {val: 'Delta Scandinavia', text: 'Delta Scandinavia'}
      ];

      this.mkod = [
          {val: 'priv', text: 'PRIVAT/LEISURE'},
          {val: 'busi', text: 'BUSINESS'}
      ];
      //this.garanti = [
      //    {val: 'fsk', text: 'FÖRSKOTT'},
      //    {val: 'kort', text: 'KORT'}
      //];
      this.garanti = [
          {val: '48H', text: '48H'},
          {val: 'NON REFUNDABLE', text: 'NON REFUNDABLE'},
          {val: 'FRI', text: 'FRI'}
      ];
      this.scb = [
          {val: 'SE', text: 'SVERIGE'},
          {val: 'NO', text: 'NORGE'},
          {val: 'DK', text: 'DANMARK'},
          {val: 'GB', text: 'STORBRITANIEN'},
          {val: 'DE', text: 'TYSKLAND'},
          {val: 'FR', text: 'FRANKRIKE'},
          {val: 'ES', text: 'SPANIEN'},
          {val: 'IT', text: 'ITALIEN'},
          {val: 'IN', text: 'INDIEN'},
          {val: 'CN', text: 'KINA'},
          {val: 'US', text: 'USA'}
      ];

  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
      //document.removeEventListener("keydown", this.handleKey);
  }
  componentDidMount() {
    //document.addEventListener("keydown", this.handleKey);

    if(this.state.boknr){
        this.rv_init();
    }
    else if(this.state.copy){
      this.rv_create_copy();
    }
    else this.rv_create();
  }

    handleKey = (e) => {
      e.preventDefault();
      //alert(e.key);
      //alert(e.code);
      if(e.keyCode == 13) {
          //alert("ENTER");
      }
      if(e.keyCode == 112) {
          //alert("F1");
      }
      if(e.keyCode == 113) {
          //alert("F2");
          this.sz();
      }
      if(e.keyCode == 114) {
          //alert("F3");
      }
      if(e.keyCode == 115) {
          //alert("F4");
      }
      if(e.keyCode == 116) {
          //alert("F5");
      }
      if(e.keyCode == 117) {
          //alert("F6");
      }
      if(e.keyCode == 118) {
          //alert("F7");
      }
      if(e.keyCode == 119) {
          //alert("F8");
      }
      if(e.keyCode == 120) {
          //alert("F9");
      }
      if(e.keyCode == 121) {
          //alert("F10");
      }
      //if(e.keyCode == 122) {alert("F11");} // NOT TRAPPABLE
      if(e.keyCode == 123) {
          //alert("F12");
      }
    }

    _nra(nrs) {
      var nra = [];
      var i = 0;
      while(i < nrs) {
          var nr = i + 1;
          nr = nr.toString();
          nra.push( {val: nr, text: nr, extra: 'st'} );
          i = i + 1;
      }
      return nra;
    }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    top_style() {
        var css = {};
        css.backgroundColor = "#800";
        css.color = "#fff";
        css.fontSize = "18px";
        css.paddingLeft = "5px";
        
        var boknr = this.state.boknr.toUpperCase();
        if(boknr == this.state.boknr) css.backgroundColor = "#080";

        return css;
    }
    rvbox_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      css.height = window.innerHeight - 520;
      //css.overflow = "auto";
      return css;
    }
    rvboxm_style() {
      var css = {};
      css.fontSize = "18px";
      css.color = "#000";
      css.border = "0px";
      css.borderBottom = "0px solid #ccc";
      css.margin = "0px";
      css.padding = "0px";
      css.height = window.innerHeight - 540;
      css.overflow = "auto";
      return css;
    }
   br_style(row) {
        var css = {};
        if(this.state.bro) {
            if(this.state.bro.r0lopnr == row.rblx0.r0lopnr) {
                css.color = "#080";
                css.border = "2px";
            }
        }
        return css;
    }
    rv_btn_style() {
      var css = {};
        let pre = this.state.boknr.substr(0, 2);
        if(pre != pre.toLowerCase()) {
        css.display = "none";
        }
      return css;
    }

    formchange (e) {
      const name = e.target.name;
      const value = e.target.value;
      var rvo = this.state.rvo;
      var na = name.split(".");
      rvo[na[0]][na[1]] = value;
      this.setState({rvo: rvo});
      //setState({[name]: value});
    }
    rsv_anm_change (e) {
      const name = e.target.name;
      const value = e.target.value;
      var rsv = this.state.rsv;
      var na = name.split(".");
      rsv[na[0]][na[1]] = value;
      this.setState({rsv: rsv});
      //setState({[name]: value});
    }

    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }

    wnd_close = () => {

      var rsv = this.state.rsv;
      if(rsv.resv01.Name.trim().length < 1) {
          //alert("Inget namn");
          //return
      }
      if( sso.wnds.rvn == 1 ) {
        sso.wnds.rvn = 0;
        return
    }
        if(this.props.wnds && this.props.wnds.cb) {
            var rsp = {};
          rsp.ok = "999";
          this.props.wnds.cb(this.props.ctx, rsp);
      }
      else this.props.ctx.setState({ wnds: {} });
	};
    drag(event) {
        //event.dataTransfer.setData("text", event.target.id);
	};
    drop(event) {
        event.preventDefault();
        alert("Drop");
	};
    dragEnter(event) {
        event.preventDefault();
        event.target.style.color = "#fff";
        event.target.style.background = "#ccc";
        //alert("Allow Drop");
	};
    dragLeave(event) {
        event.preventDefault();
        event.target.style.color = "#fff";
        event.target.style.background = "#080";
        //alert("Allow Drop");
	};
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  rv_tab_style(part) {
      var css = {};
      if(this.state.part == part ){
          css.background = "#fff";
          css.color = "#282";
      }
      return css;
  }
  mnu_toggle() {
    //props.ctx.setState({ mnu_show: !props.ctx.state.mnu_show });
    //props.store.cfg.mnu_show = !props.store.cfg.mnu_show;
  };
  show_info() {
      var wnds = {info: true, boknr: this.state.boknr, rsv: this.state.rsv, resm: this.state.resm};
      this.setState({wnds: wnds});
  };
  show_hot() {
      var wnds = {rvhot: true, boknr: this.state.boknr};
      this.setState({wnds: wnds});
  };
  show_gsts() {
      var wnds = {rvgsts: true, boknr: this.state.boknr};
      this.setState({wnds: wnds});
  };
    show_br() {
        var wnds = {rvbr: true, boknr: this.state.boknr};
        this.setState({wnds: wnds});
    };
    show_br_pkat() {
        var wnds = {rvbrpkat: true, boknr: this.state.boknr};
        this.setState({wnds: wnds});
    };
  show_kf() {
      var wnds = {rvkf: true, boknr: this.state.boknr};
      this.setState({wnds: wnds});
  };
  show_dtg() {
      var wnds = {rvdtg: true, boknr: this.state.boknr};
      this.setState({wnds: wnds});
  };
  show_doc() {
      var wnds = {rvdoc: true, boknr: this.state.boknr};
      this.setState({wnds: wnds});
  };
  show_rs() {
      var wnds = {rvrs: true, boknr: this.state.boknr};
      this.setState({wnds: wnds});
  };
  show_nota() {
      var wnds = {rvnota: true, boknr: this.state.boknr};
      this.setState({wnds: wnds});
  };
  show_part(part) {
      if(part == "02") this.rv_kf();
      this.setState({part: part});
  };
  show_kk() {
      var wnds = {kk: true, boknr: this.state.boknr};
      this.setState({wnds: wnds});
  };
  show_logg() {
      var wnds = {logg: true, boknr: this.state.boknr};
      this.setState({wnds: wnds});
  };
  show_bekr() {
      var wnds = {};
      wnds.bekr = true;
      wnds.boknr = this.state.boknr;
      wnds.rsv = this.state.rsv;
      this.setState({wnds: wnds});
  };
  sz() {
      var wnds = {};
      wnds.sz = true;
      wnds.boknr = this.state.boknr;
      wnds.cb = this.cb_sz;
      this.setState({wnds: wnds});
  };
   cb_sz(ctx, ret) {
    //alert(JSON.stringify(ret));
    if(ret && (ret.ok == "000")) {
        var row = ret.rco;
        var rsv = ctx.state.rsv;
        rsv.resv01.Name = row.namn;
        rsv.resv00.Company = row.ftg;
        ctx.setState({ rsv: rsv, wnds: {} });
    }
    else ctx.setState({wnds: {}});
  }
    rt_bel() {
        var wnds = {};
        wnds.rtbel = true;
        wnds.boknr = this.state.boknr;
        wnds.rsv = this.state.rsv;
        this.setState({wnds: wnds});
    };
    brn_add(e) {
        var self = this;
        e.preventDefault();

        if(!this.state.brn.rt || (this.state.bro.rt == "")) {
            alert("Rumstyp saknas");
            return;
        }
        if(!this.state.brn.pkat || (this.state.bro.pkat == "")) {
            alert("Priskategori saknas");
            return;
        }
        var pko = this.pms.pko[this.state.brn.pkat];
        if(!pko) {
            alert("Priskategori objekt saknas");
            return;
        }
        var pris = "";
        //pris = "4990.00";

        var prm = {};
        prm.req = "pms.br.br_add";
        prm.boknr = this.state.boknr;
        prm.rt = this.state.brn;
        prm.nyp00 = pko;
        prm.pris = pris;
        //alert(JSON.stringify(prm.rt));
        //return;
        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret));

                if(ret.ok == "000") {
                    self.brs();
                    return;
                }
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
  br_add() {
      var self = this;
      self.rvp_store()
      .then(function(ret) {
          var wnds = {rvbradd: true, boknr: self.state.boknr};
          self.setState({wnds: wnds});
      });
  };

  br_sel(row) {
      if(row.r0lopnr == this.state.bro.r0lopnr) this.setState({bro: {}});
      else this.setState({bro: row});
  };
  br_edit(row) {
      var wnds = {};
      wnds.rvbredit = true;
      wnds.boknr = this.state.boknr;
      wnds.bro = row;
      wnds.rvo = this.state.rvo;
      this.setState({ wnds: wnds });
  };
  rv_logg() {
      //this.setState({rvlogg: true});
      this.show_logg();
  };
  br_add_res(ctx, ret) {
      ctx.setState({wnds: {}, rvbradd: false});
      if(ret.ok == "000") {
          ctx.rv_init();
      }
  };
  gst_add() {
      var wnds = {};
      wnds.rvgstadd = 1;
      wnds.boknr = this.state.boknr;
      wnds.bro = this.state.bro;
      this.setState({wnds: wnds});
  };
  gst_add_res(ctx, ret) {
      ctx.setState({wnds: {}});
      if(ret.ok == "000") {
          ctx.rv_br();
      }
  };
  rt_bel() {
      var wnds = {rtbel: true, boknr: this.state.boknr};
      this.setState({wnds: wnds});
  };
  kf_bel() {
      var wnds = {kfbel: true, boknr: this.state.boknr};
      this.setState({wnds: wnds});
  };
  kf_add() {
      var wnds = {kfadd: true, boknr: this.state.boknr};
      this.setState({wnds: wnds});
  };
  dtg_add() {
      var wnds = {dtgadd: true, boknr: this.state.boknr};
      this.setState({wnds: wnds});
  };

  rsk_add() {
      this.setState({rvrskadd: true});
  };
  rsk_add_res(ctx, ret) {
      ctx.setState({rvrskadd: false});
      if(ret.ok == "000") {
          ctx.rv_br();
      }
  };
  doc_row_add() {
      var wnds = {};
      wnds.docrowadd = 1;
      wnds.boknr = this.state.boknr;
      this.setState({wnds: wnds});
  };
  doc_row_edit(row) {
      var wnds = {};
      wnds.docrowedit = 1;
      wnds.boknr = this.state.boknr;
      wnds.row = row;
      this.setState({wnds: wnds});
  };
  doc_row_res(ctx, ret) {
      ctx.setState({wnds: {}, docrowadd: 0, docrowedit: 0});
      if(ret.ok == "000") {
          ctx.docs();
      }
  };
  doc_freetext() {
      var wnds = {};
      wnds.docfreetext = 1;
      wnds.boknr = this.state.boknr;
      this.setState({wnds: wnds});
  };
  doc_prds() {
      var wnds = {};
      wnds.docprds = 1;
      wnds.boknr = this.state.boknr;
      this.setState({wnds: wnds});
  };
  doc_master() {
      //this.docs();
      alert("Uppsättning saknas, kontakta support!");
  };
  doc_prn() {
      this.docs();
  };
    val_brn_save (name, value) {
        //alert(JSON.stringify(name));
        //alert(JSON.stringify(value));
        var brn = this.state.brn;
        brn[name] = value;
        if(brn.rt.trim().length > 0) {
            if(brn.pkat.trim().length > 0) {
                brn.pris = this.val_brn_price(brn);
            }
        }
        this.setState({brn: brn});
        //setState({[name]: value});
    }
    val_brn_price(brn) {
        var pris = "";
        //var brn = this.state.brn;

        if(brn.rt.substr(0, 3) == "LAR") pris = "780000";
        if(brn.rt.substr(0, 3) == "LEK") pris = "720000";
        if(brn.rt.substr(0, 3) == "LPK") pris = "640000";
        if(brn.rt.substr(0, 3) == "LCK") pris = "470000";
        if(brn.rt.substr(0, 3) == "MCK") pris = "390000";
        if(brn.rt.substr(0, 3) == "MCQ") pris = "310000";
        if(brn.rt.substr(0, 3) == "SPQ") pris = "270000";
        if(brn.rt.substr(0, 3) == "SCS") pris = "220000";

        pris = pris.cdipris();
        return pris;
    }
    val_brn_rt (name, value) {
        //alert(JSON.stringify(name));
        //alert(JSON.stringify(value));
        var brn = this.state.brn;
        brn[name] = value.r1typ;
        this.setState({brn: brn});
        //setState({[name]: value});
    }
    val_brn_pk (name, value) {
        //alert(JSON.stringify(name));
        //alert(JSON.stringify(value));
        var brn = this.state.brn;
        brn[name] = value.r1typ;
        this.setState({brn: brn});
        //setState({[name]: value});
    }
    val_save (name, value) {
      var rvo = this.state.rvo;
      var na = name.split(".");
      rvo[na[0]][na[1]] = value;
      this.setState({rvo: rvo});
      //setState({[name]: value});
    }
    rsv_save (name, value) {
        //alert(JSON.stringify(name));
        //alert(JSON.stringify(value));
      var rsv = this.state.rsv;
      var na = name.split(".");
      rsv[na[0]][na[1]] = value;
      this.setState({rsv: rsv});
      //setState({[name]: value});
    }
    resm_save (name, value) {
        //alert(JSON.stringify(name));
        //alert(JSON.stringify(value));
      var resm = this.state.resm;
      var na = name.split(".");
      resm[na[0]][na[1]] = value;
      this.setState({resm: resm});
      //setState({[name]: value});
    }
  sel_fkn(sel) {
    //alert(JSON.stringify(fkn));
      if(sel.fkn == "save") {
          this.rv_store();
      }
      if(sel.fkn == "cancel") {
          this.rv_cancel();
      }
      if(sel.fkn == "confirm") {
          this.rv_confirm();
      }
      if(sel.fkn == "logg") {
          this.rv_logg();
      }
      if(sel.fkn == "logout") {
      }
  }
  sel_mnu_fkn(sel) {
    //alert(JSON.stringify(fkn));
      if(sel.fkn == "rvb") {
      }
      if(sel.fkn == "close") {
          this.wnd_close();
      }
  }
  toast(type, txt) {
        var self = this;
        var defer = q.defer();

        var msg = {};
        msg.text = txt;
        if(type == "OK") msg.ok = true;
        if(type == "ERROR") msg.error = true;

        self.setState({msg: msg });
        setTimeout(function(){
            self.setState({msg: {} });
            //self.props.ctx.setState({ pluopen: false });
            //self.props.ctx.plu_upd(self.props.ctx, plubuf);
            var rsp = {};
            defer.resolve(rsp);
        }, 2000);
  }
  brs() {
    var self = this;

    var prm = {};
    prm.req = "pms.br.brgs";
    prm.boknr = this.state.boknr;
    //alert(JSON.stringify(prm));

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            return;
        }
        //self.setState({bra: ret.rca });
        var bra = [];
        if(ret.rca) {
            bra = ret.rca;
            self.pms.rvo.bra = ret.rca;
        }
        var bro = {};
        if(bra.length) {
            for(var row of bra) {
                var rblx0 = row.rblx0;
                if(!bro[rblx0.r0lopnr]) bro[rblx0.r0lopnr] = {};
                bro[rblx0.r0lopnr] = rblx0;
            }
        }
        self.setState({bra: bra, bro: bro });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
    return;
  }
  gsts() {
    var self = this;

    var prm = {};
    prm.req = "pms.gst_rv.rv_gsts";
    //prm.fkn = "7221";
    prm.boknr = this.state.boknr;
    //alert(JSON.stringify(prm));

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            return;
        }
        self.setState({gsa: ret.rca });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
    return;
  }
  docs() {
    var self = this;

    var prm = {};
    prm.req = "pms.doc.doc_rv";
    prm.boknr = self.state.boknr;
    //alert(JSON.stringify(prm));

    net.sio_req(prm)
    .then(function(ret){
        ///alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            return;
        }
        self.setState({dca: ret.rca });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
    return;
  }

  rvp_store() {
    var self = this;

    var defer = q.defer();

    var prm = {};
    prm.req = "pms.rv.rvx_upd";
    prm.boknr = self.state.boknr;
    prm.rsv = self.state.rsv;
    prm.resm = self.state.resm;
    //alert(JSON.stringify(prm));
    //alert(JSON.stringify(self.state.rsv));

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            throw "992 - Fel";
            return;
        }

        var prm = {};
        prm.req = "pms.rv.pmx_upd";
        prm.boknr = self.state.boknr;
        prm.anm = self.state.rsv.anm;
        return net.sio_req(prm);
    })
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            //self.toast("ERROR", "FEL Sparat");
            //return;
        }
        //self.toast("OK", "Bokning sparad");
        //toastr.success("Reservation sparad : " + $scope.boknr);
        var rsp = {};
        rsp.ok = "000";
        defer.resolve(rsp);
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //self.toast("ERROR", "FEL: " + e);
        var rsp = {};
        rsp.ok = "999";
        defer.resolve(rsp);
    });
    return defer.promise;
  }

  rv_store() {
    var self = this;

    var prm = {};
    prm.req = "pms.rv.rvx_upd";
    prm.boknr = self.state.boknr;
    prm.rsv = self.state.rsv;
    prm.resm = self.state.resm;
    //alert(JSON.stringify(prm));
    //alert(JSON.stringify(self.state.rsv));

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            throw "992 - Fel";
            return;
        }

        var prm = {};
        prm.req = "pms.rv.pmx_upd";
        prm.boknr = self.state.boknr;
        prm.anm = self.state.rsv.anm;
        return net.sio_req(prm);
    })
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            //self.toast("ERROR", "FEL Sparat");
            //return;
        }
        self.toast("OK", "Bokning sparad");
        //toastr.success("Reservation sparad : " + $scope.boknr);
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        self.toast("ERROR", "FEL: " + e);
    });
    return;
  }
  rv_store_close() {
    var self = this;

    var prm = {};
    prm.req = "pms.rv.rvx_upd";
    prm.boknr = self.state.boknr;
    prm.rsv = self.state.rsv;
    prm.resm = self.state.resm;
    //alert(JSON.stringify(prm));
    //alert(JSON.stringify(self.state.rsv));

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            throw "992 - Fel";
            return;
        }

        var prm = {};
        prm.req = "pms.rv.pmx_upd";
        prm.boknr = self.state.boknr;
        prm.anm = self.state.rsv.anm;
        return net.sio_req(prm);
    })
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            //self.toast("ERROR", "FEL Sparat");
            //return;
        }
        self.wnd_close();
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        self.toast("ERROR", "FEL: " + e);
    });
    return;
  }
  rv_br() {
    var self = this;

    var prm = {};
    prm.req = "pms.br.brgs";
    //prm.fkn = "7217";
    prm.boknr = this.state.boknr;
    //alert(JSON.stringify(prm));

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            return;
        }
        var bra = [];
        if(ret.rca) bra = ret.rca;
    	var bro = {};
        if(bra.length) {
            for(var row of bra) {
                var rblx0 = row.rblx0;
                if(!bro[rblx0.r0lopnr]) bro[rblx0.r0lopnr] = {};
                bro[rblx0.r0lopnr] = rblx0;
            }
        }
        self.setState({bra: bra, bro: bro });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
    return;
  }
  rv_br_cancel(rblx0) {
    var self = this;

    var prm = {};
    prm.req = "pms.br.br_del";
    //prm.fkn = "7217";
    prm.boknr = this.state.boknr;
    prm.rblx0 = rblx0;
    //alert(JSON.stringify(prm));

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            return;
        }
        self.rv_br();
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
    return;
  }

  rv_cancel() {
      var wnds = {};
      wnds.cancel = true;
      wnds.boknr = this.state.boknr;
      wnds.rvo = this.state.rvo;
      wnds.cb = this.cb_rvcancel;
      this.setState({wnds: wnds});
  }

  cb_rvcancel(ctx, ret) {
        //alert(JSON.stringify(rsp));
        if(ret.ok == "000") {
            ctx.setState({wnds: {}});

            if(ret.fkn == "close") {
              if(ctx.props.wnds.cb) {
                  var rsp = {};
                  rsp.ok = "999";
                  ctx.props.wnds.cb(ctx.props.ctx, rsp);
              }
              else ctx.props.ctx.setState({wnds: {}});
            }
            if(ret.fkn == "cancel") {
              if(ctx.props.wnds.cb) {
                  var rsp = {};
                  rsp.ok = "000";
                  ctx.props.wnds.cb(ctx.props.ctx, rsp);
              }
              else ctx.props.ctx.setState({wnds: {}});
            }

            return;
        }
      ctx.setState({wnds: {}});
  }

  rv_cancel_rv() {
    var self = this;

    var prm = {};
    prm.req = "pms.rv.rvo_cancel";
    //prm.fkn = "7217";
    prm.boknr = this.state.boknr;
    prm.rvo = this.state.rvo;
    //alert(JSON.stringify(prm));

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            return;
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
    return;
  }
  rv_cancel_prel() {
    var self = this;

    var prm = {};
    prm.req = "pms.rv_fkn.py_cancel";
    prm.boknr = this.state.boknr;
    //alert(JSON.stringify(prm));

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if( sso.wnds.rvn == 1 ) {
            sso.wnds.rvn = 0;
            return
        }
                if(ret.ok != "000") {
            if(self.props.wnds && self.props.wnds.cb) {
                var rsp = {};
                rsp.ok = "999";
                self.props.wnds.cb(self.props.ctx, rsp);
            }
            else self.props.ctx.setState({ wnds: {} });
            return;
        }

        if(self.props.wnds && self.props.wnds.cb) {
            var rsp = {};
            rsp.ok = "000";
            self.props.wnds.cb(self.props.ctx, rsp);
        }
        else self.props.ctx.setState({ wnds: {} });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //alert(JSON.stringify(txt));
    });
    return;
  }
  rv_confirm() {
    var self = this;
    var boknr = this.state.boknr;

    /*
    if (boknr.substr(0, 1) == boknr.substr(0, 1).toLowerCase()) {
        self.rv_reserve();
    }
    */
      this.show_bekr();
    return;
  }

  rv_close() {
    var self = this;

    let pre = self.state.boknr.substr(0, 2);
    if(pre == pre.toLowerCase()) {
        var msg = { confirm: true, text: "Skall Preliminär avbokas?", text0: "JA", text1: "NEJ", cb: "rv_close_do"};
        self.setState({msg: msg });
        return;
    }

    if(self.state.rsv.resv01.Name == "") {
        var msg = { error: true, text: "Namn saknas", timeout: 1500};
        self.setState({msg: msg });
        setTimeout(function() {
            self.setState({ msg: {} });
        }, 1500);
        return;
    }
    if(self.state.rsv.resv01.Tfn == "") {
        var msg = { error: true, text: "Telefon saknas", timeout: 1500};
        self.setState({msg: msg });
        setTimeout(function() {
            self.setState({ msg: {} });
        }, 1500);
        return;
    }

    self.wnd_close();
  }
  rv_close_do (val) {
    var self = this;

    self.setState({msg: {} });
    if(val != 1) {
        self.rv_cancel_prel();
        return;
    }
    if(self.state.rsv.resv01.Name == "") {
        var msg = { error: true, text: "Namn saknas", timeout: 1500};
        self.setState({msg: msg });
        setTimeout(function() {
            self.setState({ msg: {} });
        }, 1500);
        return;
    }
    if(self.state.rsv.resv01.Tfn == "") {
        var msg = { error: true, text: "Telefon saknas", timeout: 1500};
        self.setState({msg: msg });
        setTimeout(function() {
            self.setState({ msg: {} });
        }, 1500);
        return;
    }
    self.rv_store_close();
  }

  rv_reserve() {
    var self = this;

    if(self.state.rsv.resv01.Name == "") {
        alert("Namn saknas");
        return;
    }
    if(self.state.rsv.resv01.Tfn == "") {
        alert("Telefon saknas");
        return;
    }

    let pre = self.state.boknr.substr(0, 2);
    if(pre != pre.toLowerCase()) {
        self.rv_store();
        return;
    }

    var prm = {};
    prm.req = "pms.rv.rvx_upd";
    prm.boknr = self.state.boknr;
    prm.rsv = self.state.rsv;
    prm.resm = self.state.resm;
    //alert(JSON.stringify(prm));
    //alert(JSON.stringify(self.state.rsv));

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            throw "992 - Fel";
            return;
        }

        var prm = {};
        prm.req = "pms.rv.pmx_upd";
        prm.boknr = self.state.boknr;
        prm.anm = self.state.rsv.anm;
        return net.sio_req(prm);
    })
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            //self.toast("ERROR", "FEL Sparat");
            //return;
        }
        var prm = {};
        prm.req = "pms.rv_op.rv_reserve";
        //prm.fkn = "7209";
        prm.boknr = self.state.boknr;
        return net.sio_req(prm);
    })
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            return;
        }
         self.setState({boknr: ret.resno, btn_rvtext: "SPARA" }, function(e) {
             self.rv_init();
         });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        self.toast("ERROR", "FEL: " + e);
    });

    return;
  }
  rv_create() {
    var self = this;

    var prm = {};
    //prm.req = "pms.rv_op.rv_create";
    prm.req = "pms.rv.rvx_creat";
    prm.boknr = "";
    //alert(JSON.stringify(prm));

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            alert("Bokning kan ej skapas");
            if(self.props.wnds.cb) {
                var rsp = {};
                rsp.ok = "999";
                self.props.wnds.cb(self.props.ctx, rsp);
            }
            else self.props.ctx.setState({wnds: {}});
            return;
        }
        var boknr = ret.rco.boknr;
        var rsv = ret.rco.rsv;
        var rsm = ret.rco.rsm;
        var anm = ret.rco.anm;
        rsv.anm = {};
        self.setState({boknr: boknr, rsv: rsv, resm: rsm });
        //self.setState({boknr: ret.rco.boknr, rvo: ret.rco.rvo });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
    return;
  }
    rv_create_copy() {
        var self = this;

        var prm = {};
        //prm.req = "pms.rv_op.rv_create";
        prm.req = "pms.rv.rvx_creat_copy";
        prm.boknr = this.state.copy;
        //alert(JSON.stringify(prm));

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret));

                if(ret.ok != "000") {
                    alert("Bokning kan ej skapas");
                    if(self.props.wnds.cb) {
                        var rsp = {};
                        rsp.ok = "999";
                        self.props.wnds.cb(self.props.ctx, rsp);
                    }
                    else self.props.ctx.setState({wnds: {}});
                    return;
                }
                var boknr = ret.rco.boknr;
                var rsv = ret.rco.rsv;
                var rsm = ret.rco.rsm;
                var anm = ret.rco.anm;
                rsv.anm = {};
                self.setState({boknr: boknr, rsv: rsv, resm: rsm });
                //self.setState({boknr: ret.rco.boknr, rvo: ret.rco.rvo });
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
        return;
    }
  rv_init() {
    var self = this;

    var prm = {};
    prm.req = "pms.rv.rvx";
    prm.boknr = self.state.boknr;
    //alert(JSON.stringify(prm));

    net.sio_req(prm)
    .then(function(ret) {
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            //return;
        }
        var rsv = ret.rco.rsv;
        var rsm = ret.rco.rsm;
        var anm = ret.rco.anm;
        rsv.anm = {};
        self.setState({ rsv: rsv, resm: rsm });

        var prm = {};
        prm.req = "pms.rv.pmx";
        prm.boknr = self.state.boknr;
        return net.sio_req(prm);
    })
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok == "000") {
            var rsv = self.state.rsv;
            rsv.anm = ret.rco;
            //alert(JSON.stringify(rsv.anm));
            if(rsv.anm.rec) rsv.anm.rec = rsv.anm.rec.toswe();
            if(rsv.anm.stad) rsv.anm.stad = rsv.anm.stad.toswe();
            if(rsv.anm.rest) rsv.anm.rest = rsv.anm.rest.toswe();
            self.setState({rsv: rsv });
        }

        var prm = {};
        prm.req = "pms.rv.rv_pkats";
        //prm.fkn = "72QP";
        prm.boknr = self.state.boknr;
        return net.sio_req(prm);
    })
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            //return;
        }
        var rvo = self.state.rvo;
        rvo.rate = ret.rco.belopp;
        rvo.xsum = "0";
        rvo.sum = ret.rco.belopp;
        rvo.pts = ret.rco.pta.length;
        self.setState({rvo: rvo, pta: ret.rco.pta });

        var prm = {};
        prm.req = "pms.br.brgs";
        prm.boknr = self.state.boknr;
        return net.sio_req(prm);
    })
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            //return;
        }
        var bra = [];
        if(ret.rca) {
            bra = ret.rca;
            self.pms.rvo.bra = ret.rca;
        }
    	var bro = {};
        if(bra.length) {
            for(var row of bra) {
                var rblx0 = row.rblx0;
                if(!bro[rblx0.r0lopnr]) bro[rblx0.r0lopnr] = {};
                bro[rblx0.r0lopnr] = rblx0;
            }
        }
        self.setState({bra: bra, bro: bro });

        var prm = {};
        prm.req = "pms.gst_rv.rv_gsts";
        //prm.fkn = "7221";
        prm.boknr = self.state.boknr;
        return net.sio_req(prm);
    })
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok == "000") {
            var gsa = [];
            if(ret.rco) gsa = ret.rco;
            var nrr = 0;
            var gro = {};
            if(gsa.length) {
                for(var gsb00 of gsa) {
                    if(!gro[gsb00.r0blopnr]) gro[gsb00.r0blopnr] = {};
                    gro[gsb00.r0blopnr][gsb00.r0glopnr] = gsb00;
                    if(gsb00.r0status != "C") nrr = nrr + 1;
                }
            }
            self.setState({gsa: gsa, gro: gro });
        }
        //if(rvo.kid) self.rv_read_kid(rvo.kid);

        var prm = {};
        prm.req = "pms.doc.doc_rv";
        prm.boknr = self.state.boknr;
        return net.sio_req(prm);
    })
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok == "000") {
            self.setState({dca: ret.rca });
        }
        self.forceUpdate();
        //alert(JSON.stringify("Reservation hämtad : " + self.state.boknr));
        //toastr.success("Reservation hämtad : " + $scope.boknr);
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });

    return;
  }
  sel_top_fkn(sel) {
    //alert(JSON.stringify(fkn));
      if(sel.fkn == "close") {
      }
  }
  rv_kf() {
    var self = this;

    var prm = {};
    prm.req = "pms.kf.kfa";
    prm.boknr = this.state.boknr;
    //alert(JSON.stringify(prm));

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            return;
        }
        var kfa = [];
        if(ret.rca) kfa = ret.rca;
        self.setState({ kfa: kfa });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
    return;
  }
  ov_hot() {
    var self = this;
    //this.show_part("02");
    this.show_hot();
  }
  ov_kf() {
    var self = this;
    //this.show_part("04");
    this.show_doc();
  }
  ov_sum() {
    var self = this;
  }
  hot_ret(ctx, ret) {
    ctx.rv_init();
    ctx.setState({ wnds: {} });
  }
  doc_ret(ctx, ret) {
    ctx.rv_init();
    ctx.setState({ wnds: {} });
  }
  val_rum(val) {
      var ret = "";
      if(val) ret = val.toString().cdirumsnr();
      return ret;
  }
  val_pris(val) {
      var ret = "";
      if(val) ret = val.toString().cdipris();
      return ret;
  }
    val_fd_save (dts) {
        var brn = this.state.brn;
        //var dts = format(value, "yyyy-MM-dd");
        if(dts < this.state.tds) dts = this.state.tds;
        brn.fdat = dts;
        if(brn.tdat < dts) {
            var tdd = new Date(dts);
            var tdat = addDays(tdd, 1);
            brn.tdat = format(tdat, "yyyy-MM-dd");
            //alert(dts);
            //bro.tdat = dts;
        }
        var fdd = new Date(brn.fdat);
        var tdd = new Date(brn.tdat);
        var days = differenceInCalendarDays(tdd, fdd);
        //var pris = (this.state.pris.replaceAll(".", "") * 1);
        //var sum = pris * (bro.nrr * 1) * days;
        //this.setState({bro: bro, sum: sum});
        this.setState({brn: brn});
        //this.bel_rt();
    }
    val_td_save (dts) {
        var brn = this.state.brn;
        //var dts = format(value, "yyyy-MM-dd");
        if(dts < this.state.tds) dts = this.state.tds;
        if(dts < brn.fdat) {
            //var dts = format(dts, "yyyy-MM-dd");
            brn.fdat = dts;
        }
        brn.tdat = dts;
        var fdd = new Date(brn.fdat);
        var tdd = new Date(brn.tdat);
        var days = differenceInCalendarDays(tdd, fdd);
        //var pris = (this.state.pris.replaceAll(".", "") * 1);
        //var sum = pris * (bro.nrr * 1) * days;
        //this.setState({bro: bro, sum: sum});
        this.setState({brn: brn});
    }
    sz_keydown (e) {
        //e.preventDefault();
          const key = e.key;
          const keyCode = e.keyCode;
            //alert(keyCode);
          if(keyCode == 13) {
              //this.sz_do();
          };
          if(keyCode == 27) {
            //this.setState({sz_val: ""});
        };
        if(keyCode == 113) {
            this.sz();
        };
  }

  render() {
    //if(!this.props.show) return "";
    //alert(this.props.ctx.state.lro);

    var title = this.props.title;
    if(!title) title = "Bokning";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="mx_backdrop" /> : null }
        <div className="mx_fb flex-col" style={ this.dlg_style() }>
            <div className="flex-row" style={ this.top_style() }>
                <div className="f40 flex-row">

                <div className="w60" onClick={() => this.mnu_toggle() }>
                    <Dd_Sel items={ this.mnuitems } cls="rv-top-icon" icon="bars" getValue={(e)=> this.sel_mnu_fkn(e)} />
                </div>
                <div className="flex-column">
                    <div style={{fontSize: "12px", margin: "0px", padding: "0px", lineHeight: "20px"}}>
                        Bokning
                    </div>
                    <div style={{fontSize: "18px", margin: "0px", padding: "0px", lineHeight: "20px"}}>
                        { this.state.boknr }
                    </div>
                </div>
                </div>

                <div className="flex flex-row flex-space-between">
                 <div className="flex-column">
                     <div style={{fontSize: "12px", margin: "0px", padding: "0px", lineHeight: "20px"}}>Antal Rum</div>
                     <div className="text-center" style={{fontSize: "18px", margin: "0px", padding: "0px", lineHeight: "20px"}}>{ this.state.rvo.nrr }</div>
                 </div>
                 <div className="flex-column">
                     <div style={{fontSize: "12px", margin: "0px", padding: "0px", lineHeight: "20px"}}>Summa Rum</div>
                     <div style={{fontSize: "18px", margin: "0px", padding: "0px", lineHeight: "20px"}}>{ this.val_pris(this.state.rvo.rate) }</div>
                 </div>
                 <div className="flex-column">
                     <div style={{fontSize: "12px", margin: "0px", padding: "0px", lineHeight: "20px"}}>Antal Lokal</div>
                     <div className="text-center" style={{fontSize: "18px", margin: "0px", padding: "0px", lineHeight: "20px"}}>{ this.state.rvo.nrk }</div>
                 </div>
                 <div className="flex-column">
                     <div style={{fontSize: "12px", margin: "0px", padding: "0px", lineHeight: "20px"}}>Summa Lokal</div>
                     <div style={{fontSize: "18px", margin: "0px", padding: "0px", lineHeight: "20px"}}>{ this.val_pris(this.state.rvo.krate) }</div>
                 </div>
                 <div className="flex-column">
                     <div style={{fontSize: "12px", margin: "0px", padding: "0px", lineHeight: "20px"}}>Tillägg</div>
                     <div style={{fontSize: "18px", margin: "0px", padding: "0px", lineHeight: "20px"}}>{ this.val_pris(this.state.rvo.xsum) }</div>
                 </div>
                 <div className="flex-column">
                     <div style={{fontSize: "12px", margin: "0px", padding: "0px", lineHeight: "20px"}}>Summa Bokning</div>
                     <div className="text-right" style={{fontSize: "18px", margin: "0px", padding: "0px", lineHeight: "20px"}}>{ this.val_pris(this.state.rvo.sum) }</div>
                 </div>
                </div>
                 <div className="web-top-sep"></div>
                <div className="">
                    <Dd_Sel items={ this.topitems } cls="rv-top-icon" getValue={(e)=> this.sel_top_fkn(e)} />
                </div>
            </div>
        <div className="mx_content flex">

            <div className="flex-row py-1 px-2">
                <div className="txt_12 web_mnu_link" onClick={() => this.show_hot() }>HOTELL</div>
                <div className="txt_12 web_mnu_link" onClick={() => this.show_br() }>DELBOKNINGAR</div>
                <div className="txt_12 web_mnu_link" onClick={() => this.show_gsts() }>GÄSTER</div>
                <div className="txt_12 web_mnu_link" onClick={() => this.show_br_pkat() }>PRISER</div>
                <div className="flex"></div>
                <div className="txt_12 web_mnu_head" onClick={() => this.ov_hot() }>ÖVERSIKT</div>
                <div className="flex"></div>
                <div className="txt_12 web_mnu_link" onClick={() => this.show_doc() }>DOKUMENT</div>
                <div className="txt_12 web_mnu_link" onClick={() => this.show_rs() }>RESURSER</div>
                <div className="txt_12 web_mnu_link" onClick={() => this.show_nota() }>NOTA</div>
            </div>

        <div className="rv-tab">
            <div className="flex-row flex">

                <div className="rv-ov-box flex-col flex">
                <div className="flex">
                <table className="table" style={{border: "0px", margin: "0px", padding: "0px"}}>
                    <thead style={{fontSize: "14px", fontWeight: "normal", color: "#080", border: "0px", margin: "0px", padding: "0px"}}>
                    <tr>
                        <th style={{textAlign: "left"}}>Från - Till</th>
                        <th style={{textAlign: "left"}}>Rumstyp</th>
                        <th style={{textAlign: "left"}}>Rum</th>
                        <th style={{textAlign: "left"}}>Gäster</th>
                        <th style={{textAlign: "left"}}>Belopp</th>
                    </tr>
                    </thead>
                    <tbody>
                    { this.pms.rvo.bra.map((row, key) =>
                    <tr key={key} onClick={() => this.br_sel(row.rblx0) } onDoubleClick={() => this.br_edit(row.rblx0) } style={ this.br_style(row) }>
                        <td>{ row.rblx0.r0ankdatum.cdidate() } - { row.rblx0.r0avrdatum.cdidate() }</td>
                        <td>{ row.rblx0.r0typ }</td>
                        <td>{ row.rblx0.r0borr.ztrim() }</td>
                        <td>{ row.rblx0.r0borg.ztrim() }</td>
                        <td>{ row.belopp.cdipris() }</td>
                    </tr>
                    )}
                    </tbody>
                </table>
                </div>
                <div className="divider_green"></div>
                    <div className="flex-row">
                        <div className="rv-ov-btn px-2" onClick={() => this.br_add() }>LÄGG TILL DELBOKNING</div>
                        <div className="rv-ov-btn px-2" onClick={() => this.gst_add() }>LÄGG TILL GÄST</div>
                        <div className="flex"></div>
                        <div className="rv-ov-btn" onClick={() => this.brs() }><i className="fa fa-sync"></i></div>
                    </div>
                </div>

            </div>

            <div className="txt_box txt_bg_01">
            <form className="cdi_f03">
            <div className="flex-row px-2">
                <Fr_Cal col mid noflex label="Från" className="text-center w125" text={this.state.brn.fdat} getValue={(e)=> this.val_fd_save( e ) }/>
                <Fr_Cal col mid noflex label="Till" className="text-center w125" text={this.state.brn.tdat} getValue={(e)=> this.val_td_save( e ) }/>
                <Fr_Inpsel col noflex label="Antal Rum" className="text-center w100"
                           text={this.state.brn.nrr}
                           items={this._nra(9)}
                           getValue={(e)=> this.val_brn_save("nrr", e) }
                />
                <Fr_Inpsel col noflex label="Antal Gäster" className="text-center w100"
                           text={this.state.brn.nrg}
                           items={this._nra(4)}
                           getValue={(e)=> this.val_brn_save("nrg", e) }
                />
                <Fr_Inprts col noflex label="Rumstyp" className="text-center w200"
                           text={this.state.brn.rt}
                           items={this.pms.rta}
                           getValue={(e)=> this.val_brn_save("rt", e) }
                />
                <Fr_Inpkats col noflex label="Priskategori" className="text-center w150"
                           text={this.state.brn.pkat}
                           items={this.pms.pka}
                           getValue={(e)=> this.val_brn_save("pkat", e) }
                />
                <Fr_Input col noflex label="Pris" className="text-right w125" text={this.state.brn.pris} getValue={(e)=> this.val_brn_save("pris", e) } />
                <span className="flex"></span>
                <div className="form-group">
                    <span className="header">&nbsp;</span>
                    <div className="flex-row">
                    <button className="btn btn-hw-green w150" onClick={(event) => this.brn_add(event) } >
                        LÄGG TILL
                    </button>
                    </div>
                </div>
            </div>
            </form>
            </div>

            <div className="flex-row py-1 px-2">
                <div className="txt_12 web_mnu_link" onClick={() => this.show_info() }>BOKNINGSDATA</div>
                <div className="txt_12 web_mnu_head" onClick={() => this.show_kk() }>KUNDKORT</div>
                <div className="flex"></div>
                <div className="txt_12 web_mnu_link" onClick={() => this.rt_bel() }>BELÄGGNING</div>
            </div>
            <div className="divider_green"></div>

            <form className="cdi_f03">
            <div className="flex-row" style={{border: "0px", margin: "0px", padding: "0px"}}>
                <div className="form-row flex-col flex">
                    <Fr_Input label="Namn" sz={(e) => this.sz(e)} className="text-left w250" text={this.state.rsv.resv01.Name} getValue={(e)=> this.rsv_save("resv01.Name", e) }  onKeyDown={(e) => this.sz_keydown(e)} />
                    <Fr_Input label="Företag" sz={(e) => this.sz(e)} className="text-left w250" text={this.state.rsv.resv00.Company} getValue={(e)=> this.rsv_save("resv00.Company", e) }  onKeyDown={(e) => this.sz_keydown(e)} />
                    <Fr_Input label="Org Nr" className="text-left w250" text={this.state.rsv.resv04.Orgnr} getValue={(e)=> this.rsv_save("resv04.Orgnr", e) } />
                </div>
                <div className="form-row flex-col flex">
                    <Fr_Input label="Telefon" className="text-left w250" text={this.state.rsv.resv01.Tfn} getValue={(e)=> this.rsv_save("resv01.Tfn", e) } />
                    <Fr_Input label="Mobil" className="text-left w250" text={this.state.rsv.resv01.Mobil} getValue={(e)=> this.rsv_save("resv01.Mobil", e) } />
                    <Fr_Input label="Epost" className="text-left w250" text={this.state.rsv.resv02.Email} getValue={(e)=> this.rsv_save("resv02.Email", e) } />
                </div>
                <div className="form-row flex-col flex">
                    <Fr_Input label="Referens" className="text-left w250" text={this.state.rsv.resv04.Referens} getValue={(e)=> this.rsv_save("resv04.Referens", e) } />
                    <Fr_Input label="Avdelning" className="text-left w250" text={this.state.rsv.resv04.Avdelning} getValue={(e)=> this.rsv_save("resv04.Avdelning", e) } />
                    <Fr_Inpsel label="SCB" className="text-left w250" items={this.scb} text={this.state.rsv.resv04.Scb} getValue={(e)=> this.rsv_save("resv04.Scb", e) } />
                </div>
            </div>

            <div className="divider_orange"></div>

            <div className="flex-row" style={{border: "0px", margin: "0px", padding: "0px"}}>
                <div className="form-row flex-col flex">
                    <Fr_Inpsel label="Betalsätt" className="text-left w250" items={this.pma} text={this.state.rsv.resv02.bettyp} getValue={(e)=> this.rsv_save("resv02.bettyp", e) } />
                    <Fr_Input label="Externt Boknr" className="text-left w250" text={this.state.resm.resm01.Extboknr} getValue={(e)=> this.resm_save("resm01.Extboknr", e) } />
                </div>
                <div className="form-row flex-col flex">
                    <Fr_Input label="Kortnr" className="text-left w250" text={this.state.resm.resm03.ccnr} getValue={(e)=> this.resm_save("resm03.ccnr", e) } />
                    <Fr_Input label="Ankomsttid" className="text-left w250" text={this.state.rsv.resv02.anktid} getValue={(e)=> this.rsv_save("resv02.anktid", e) } />
                </div>
                <div className="form-row flex-col flex">
                    <Fr_Input label="Exp" className="text-left w250" text={this.state.resm.resm03.ccexp} getValue={(e)=> this.resm_save("resm03.ccexp", e) } />
                </div>
            </div>
            <div className="divider_orange"></div>

            <div className="flex-row" style={{border: "0px", margin: "0px", padding: "0px"}}>
                <div className="form-row flex-col flex">
                    <Fr_Inpsel mid label="Segment" className="text-left w250" items={this.segment} text={this.state.resm.resm01.Kanal} getValue={(e)=> this.resm_save("resm01.Kanal", e) } />
                    <Fr_Inpsel label="Marknadskod" className="text-left w250" items={this.mkod} text={this.state.rsv.resv04.Mkod} getValue={(e)=> this.rsv_save("resv04.Mkod", e) } />
                </div>
                <div className="form-row flex">
                </div>
                <div className="form-row flex-row flex">
                    <Fr_Inpsel label="Garanti" className="text-left w250" items={this.garanti} text={this.state.resm.resm03.garanti} getValue={(e)=> this.resm_save("resm03.garanti", e) } />
                </div>
            </div>
            <div className="flex"></div>
            <div className="divider_orange"></div>

            <div className="flex-row pb-3">
                <div className="flex flex-column" style={{padding: "0px 5px"}}>
                    <span className="header">Anmärkning reception</span>
                    <textarea className="form-control" maxLength="512" rows="5" name="anm.rec" value={this.state.rsv.anm.rec} onChange={(e) => this.rsv_anm_change(e)}></textarea>
                </div>
                <div className="flex flex-column" style={{padding: "0px 5px"}}>
                    <span className="header">Anmärkning städ</span>
                    <textarea className="form-control" maxLength="512" rows="5" name="anm.stad" value={this.state.rsv.anm.stad} onChange={(e) => this.rsv_anm_change(e)}></textarea>
                </div>
            </div>
            </form>

            </div>

            </div>

            <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w200" onClick={() => this.rv_close() } >
                STÄNG
            </button>
            <div className="flex-row">
            <button className="btn btn-hw-blue w200" onClick={(event) => this.rv_logg(event) } >
                LOGG
            </button>
            <Dd_Sel items={ this.selitems } getValue={(e)=> this.sel_fkn(e)} />
            <button className="btn btn-hw-green w200" onClick={(event) => this.rv_store(event) } >
                SPARA
            </button>
            </div>
            <div className="flex-row">
            <button className="btn btn-hw-green w200" style={this.rv_btn_style()} onClick={(event) => this.rv_reserve(event) } >
                { this.state.btn_rvtext }
            </button>
            </div>
        </div>
      </div>

        { this.state.wnds.info ? <Rv_Info wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.wnds.kk ? <Rv_Kk wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.wnds.sz ? <Rv_Sz wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.wnds.logg ? <Rv_Logg wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.wnds.bekr ? <Rv_Bekr wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.wnds.cancel ? <Rv_Cancel wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }

        { this.state.wnds.rvhot ? <Rv_Hot wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.wnds.rtbel ? <Rv_RtBel wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.wnds.rvbr ? <Rv_Br wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.wnds.rvgsts ? <Rv_Gsts wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.wnds.rvbrpkat ? <Rv_Br_Pkat wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }

        { this.state.wnds.rvbradd ? <Rv_BrAdd wnds={this.state.wnds} cb={this.br_add_res} ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.wnds.rvbredit ? <Rv_BrEdit wnds={this.state.wnds} cb={this.br_add_res} ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.wnds.rvgstaddx ? <Rv_GstAdd wnds={this.state.wnds} cb={this.gst_add_res} ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.wnds.rvgstadd ? <Rv_GstWrk wnds={this.state.wnds} cb={this.gst_add_res} ctx={this} store={this.props.store} backdrop={true}/> : null }

        { this.state.wnds.rvkf ? <Rv_Kf wnds={this.state.wnds} cb={this.doc_ret} ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.wnds.kfbel ? <Rv_KfBel wnds={this.state.wnds} cb={this.doc_ret} ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.wnds.kfadd ? <Rv_KfAdd wnds={this.state.wnds} cb={this.doc_ret} ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.wnds.rvdtg ? <Rv_Dtg wnds={this.state.wnds} cb={this.doc_ret} ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.wnds.dtgadd ? <Rv_DtgAdd wnds={this.state.wnds} cb={this.doc_ret} ctx={this} store={this.props.store} backdrop={true}/> : null }

        { this.state.wnds.rvdoc ? <Rv_Doc boknr={this.state.boknr} cb={this.doc_ret} cb={this.doc_row_res} ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.wnds.docprds ? <Rv_DocPrds boknr={this.state.boknr} cb={this.doc_ret} cb={this.doc_row_res} ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.wnds.docrowadd ? <Rv_DocRowAdd boknr={this.state.boknr} cb={this.doc_ret} cb={this.doc_row_res} ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.wnds.docrowedit ? <Rv_DocRowEdit wnds={this.state.wnds} cb={this.doc_ret} cb={this.doc_row_res} ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.wnds.docfreetext ? <Rv_DocFreeText wnds={this.state.wnds} cb={this.doc_ret} cb={this.doc_row_res} ctx={this} store={this.props.store} backdrop={true}/> : null }

        { this.state.wnds.rvrs ? <Rv_Rs boknr={this.state.boknr} cb={this.doc_row_res} ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.wnds.rvrskadd ? <Rv_RskAdd wnds={this.state.wnds} cb={this.rsk_add_res} ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.wnds.rvnota ? <Rv_Nota boknr={this.state.boknr} cb={this.doc_row_res} ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.wnds.szw ? <Rv_Sz boknr={this.state.boknr} cb={this.doc_row_res} ctx={this} store={this.props.store} backdrop={true}/> : null }

        <Wnd_Error msg={this.state.msg} ctx={this} />
        <Wnd_Ok msg={this.state.msg} ctx={this} />
        <Wnd_Info msg={this.state.msg} ctx={this} />
        <Wnd_Confirm msg={this.state.msg} ctx={this} />
    </div>
	;
	return html;
  };
}

export default Pms_FbRv02;