import React, { useState, useEffect, useContext } from 'react';
import {format} from "date-fns";

import * as net from "../../../lib/net";
import * as utils from "../../../assets/utils";

import Dd_Sel from "../../../lib/ui/dd_sel";
import Dt_Cal from "../../../lib/ui/dt_cal";

import Trs_Open from "./trs_open";
import Trs_Per from "./trs_per";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Trs_Beh extends React.Component {
  constructor(props, context) {
    super(props);

    this.dt_dt = React.createRef();

    this.selitems = [
        {fkn: 'bevaka', text: 'BEVAKA'},
        {fkn: 'bokbild', text: 'BOKNINGSBILD'},
        {fkn: 'confirm', text: 'BEKRÄFTA'},
        {fkn: 'cancel', text: 'AVBOKA'}
    ];

    this.store = props.store;
    this.pms = props.store.pms;

    this.state = {};
    this.state.ynq = [];
    //this.state.vga = [];
    this.state.tra = [];
    this.state.bra = [];

    this.state.dta = [];
    this.state.rva = [];

    this.state.tot = {};
    this.state.tot.hbok = {};
    this.state.tot.tbok = {};

    this.state.krubba = 0;
    this.state.ix = 0;
    this.state.boknr = "";
    this.state.datum = "";
    this.state.dts = "";
    if(this.pms.base) {
        this.state.dts = this.pms.base.fdat;
        //this.state.tdat = this.pms.base.tdat;
    }
      var dt = new Date();
      var dts = format(dt, "yyyy-MM-dd");
      this.state.today = dts;
      this.state.dts = dts;

    this.state.wnds = {};
    this.state.rvo = {};
    if(props.wnds) {
        if(props.wnds.boknr) this.state.boknr = props.wnds.boknr;
    }
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      this.trs();
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
  val_pris(val) {
      var s = "";
      if(val == 0) s = "0.00";
      if(val) s = val.cdipris();
      return s;
  };

    wnd_close = () => {
      if(this.props.close) {
          this.props.close(this.props.ctx);
      }
      else this.props.ctx.setState({ wnds: {} });
	};
  tb_style() {
      var css = {};
      css.height = (window.innerHeight * 0.9) - 205;
      css.overflow = "auto";
      return css;
  }
    th_style(id) {
        var css = {};
        css.cursor = "pointer";
        if(id == this.state.sortid) css.color = "#080";
        return css;
    }
    val_save (name, value) {
        //alert(JSON.stringify(name));
        //alert(JSON.stringify(value));
      var rvo = this.state.rvo;
      var na = name.split(".");
      rvo[na[0]][na[1]] = value;
      this.setState({rvo: rvo});
      //setState({[name]: value});
    }
  dt_dat(e) {
    //alert(JSON.stringify(e));
    var dts = format(e, "yyyy-MM-dd");
    this.setState({ dts: dts });
  }
  addday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_dt.current.updateAdd();
  }
  subday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_dt.current.updateSub();
  }
    trs() {
        var self = this;

        if(this.state.dts == this.state.today) {
            self.st_trs();
            return;
        }
        var prm = {};
        prm.req = "pos.pos_trs.trs_per";
        prm.avd = this.state.avd;
        prm.datum = this.state.dts;
        prm.fdat = this.state.dts;
        prm.tdat = this.state.dts;
        prm.favd = this.state.avd;
        prm.tavd = this.state.avd;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.rco));

                self.setState({tra: ret.rca, trs: ret.rca, sdo: ret.sdo });
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    st_trs() {
        var self = this;
        var prm = {};
        prm.req = "pos.pos_eko.trs";
        prm.avd = this.state.avd;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.rca));

                self.setState({tra: ret.rca, trs: ret.rca });
                self.syncTb("trsBehTable");
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    syncTb(tb){
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
        tdElements = tbel.getElementsByTagName('td');

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
            width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
    trs_open(row) {
        var self = this;
        var wnds = {};
        wnds.trsopen = true;
        wnds.tro = row;
        this.setState({ wnds: wnds });
    }
    tb_sort(key) {
        var self = this;
        var tra = self.state.tra;

        tra.sort((a,b) => (a[key] > b[key]) ? 1 : ((b[key] > a[key]) ? -1 : 0));
        self.setState({tra: tra, sortid: key });
    }

  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "Bokning";

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="mx_backdrop" /> : null }
        <div className="mx_dlg mx_90 flex-col" style={ this.dlg_style() }>
        <div className="mx_h01">
            <div>BEHANDLA TRANSAKTIONER</div>
        </div>

            <div className="divider_orange hide"></div>

            <div className="flex-row">
                <div className="flex"></div>
            <div className="flex-row">
                  <div className="dt-btn-01" onClick={() => this.subday() } ><FontAwesomeIcon icon="minus" size="1x" /></div>
                  <Dt_Cal dts={ this.state.dts } rp="1" ref={this.dt_dt} getValue={(e)=> this.dt_dat(e)} />
                  <div className="dt-btn-01" onClick={() => this.addday() } ><FontAwesomeIcon icon="plus" size="1x" /></div>
            </div>
                <div className="flex"></div>
                <button className="btn btn-hw-blue w150" onClick={() => this.trs() } aria-label="System" >
                    Visa
                </button>
            </div>
            <div className="flex-row">
                <div className="px-2">ANTAL : { this.state.tot.antal }</div>
                <div className="px-2">SUMMA : { this.val_pris( this.state.tot.belopp ) }</div>
            </div>
             <div className="divider_green"></div>

       <div className="mx_content flex">

        <div id="trsBehTable">
          <table className="table table-bordered table-hover" style={{marginBottom: "0px"}}>
            <thead>
            <tr>
                <th style={ this.th_style("r0datum") } onClick={() => this.tb_sort("r0datum") }>Datum</th>
                <th style={ this.th_style("r0dateid") } onClick={() => this.tb_sort("r0dateid") }>ID</th>
                <th style={ this.th_style("r0faktnr") } onClick={() => this.tb_sort("r0faktnr") }>Notanr</th>
                <th style={ this.th_style("r0lopnr") } onClick={() => this.tb_sort("r0lopnr") }>Löpnr</th>
                <th style={ this.th_style("r0text") } onClick={() => this.tb_sort("r0text") }>Text</th>
                <th style={ this.th_style("r0noof") } onClick={() => this.tb_sort("r0noof") }>Antal</th>
                <th style={ this.th_style("r0perprice") } onClick={() => this.tb_sort("r0perprice") }>Belopp</th>
                <th style={ this.th_style("r0vatproc") } onClick={() => this.tb_sort("r0vatproc") }>Moms</th>
                <th style={ this.th_style("r0terminal") } onClick={() => this.tb_sort("r0terminal") }>Terminal</th>
                <th style={ this.th_style("r0sign") } onClick={() => this.tb_sort("r0sign") }>Signatur</th>
            </tr>
            </thead>
            </table>
            <div style={ this.tb_style() }>
          <table className="table table-bordered table-hover">
            <tbody>
            { this.state.tra.map((row, key) =>
            <tr key={key} onDoubleClick={() => this.trs_open(row) }>
                <td>{ utils.toDate(row.r0datum) }</td>
                <td>{ row.r0dateid }</td>
                <td>{ utils.toNum(row.r0faktnr) }</td>
                <td>{ row.r0lopnr }</td>
                <td>{ utils.toLang(row.r0text) }</td>
                <td>{ utils.toNum(row.r0noof) }</td>
                <td>{ utils.toPris(row.r0perprice) }</td>
                <td>{ utils.toPris(row.r0vatproc) }</td>
                <td>{ row.r0terminal }</td>
                <td>{ row.r0sign }</td>
            </tr>
            )}
            </tbody>
        </table>
        </div>
        </div>

            </div>

            <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex-row">
            </div>
        </div>
      </div>

            { this.state.wnds.trsopen ? <Trs_Open wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
    </div>
	;
	return html;
  };
}

export default Trs_Beh;