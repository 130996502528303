import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";
import * as utils from "../../assets/utils";

import Kid_add from "./fkn/kid_add";
import Kid_open from "./fkn/kid_open";
import Td_Sel from "../../lib/ui/td_sel";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Wrk_Kreg extends React.Component {
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.state = {};
    this.state.seldate = new Date();
    this.state.avd = "01";

      this.state.koa = [];
      this.state.kka = [];

    this.state.tro = {};
    this.state.wnds = {};
    this.state.typ = "";
    this.state.sz_val = "";
    this.state.sortid = "";
    this.state.sortdir = 1;

    this.selitems = [
        {fkn: 'open', text: 'ÖPPNA'},
        {fkn: 'status', text: 'STATUS'},
        {fkn: 'remove', text: 'TA BORT'}
    ];
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.kreg();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 225;
      css.overflow = "auto";
      return css;
  }
    th_style(id) {
        var css = {};
        css.cursor = "pointer";
        if(id == this.state.sortid) css.color = "#080";
        return css;
    }
  tab_style(typ) {
      var css = {};
      if(this.state.typ == typ) { css.background = "#009900"; css.color = "#fff"; }
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

  kid_add() {
    var self = this;
    var wnds = {};
    wnds.kidadd = true;
    wnds.cb = self.cb_kid;
    this.setState({ wnds: wnds });
  }
  kid_edit(row) {
    var self = this;
    var wnds = {};
    wnds.kidedit = true;
    wnds.kko = row;
    wnds.cb = self.cb_kid;
    this.setState({ wnds: wnds });
  }
  cb_kid(ctx) {
    ctx.setState({ wnds: {} });
    ctx.kreg();
  }

  kreg() {
    var self = this;
    self.setState({typ: "trs", tsa: [] });

    var prm = {};
    prm.req = "bcs.kreg.kks";
    prm.typ = "K";

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        self.setState({koa: ret.rca, kka: ret.rca }, function () {
            self.syncTb("kkTable");
        });
    })
    .catch(function(e) {
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }
    syncTb(tb) {
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
        tdElements = tbel.getElementsByTagName('td');
        if(tdElements.length < 1 ) return;

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
            width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
   tb_sort(typ) {
      var self = this;
      //var rvs = self.state.rvs;
      var kka = self.state.kka;
      var sz = this.state.sz_val;
       var sortid = this.state.sortid;
       var sortdir = this.state.sortdir;

       if(sortid != typ) sortdir = 0;
       else {
           if(sortdir) sortdir = 0;
           else sortdir = 1;
       }

       if(sortdir) kka.sort((a,b) => (a.kk00[typ] > b.kk00[typ]) ? 1 : ((b.kk00[typ] > a.kk00[typ]) ? -1 : 0));
       else kka.sort((a,b) => (a.kk00[typ] < b.kk00[typ]) ? 1 : ((b.kk00[typ] < a.kk00[typ]) ? -1 : 0));
      self.setState({ kka: kka, sortid: typ, sortdir: sortdir });
   }
  sel_row_fkn(row, sel) {
      //alert(JSON.stringify(fkn));
    if(sel.fkn == "open") {
        //this.sel_gst(row);
    }
    if(sel.fkn == "br") {
    }
  }
   refresh() {
      var self = this;
      self.kreg();
    }
    sz_keydown (e) {
        //e.preventDefault();
        const key = e.key;
        const keyCode = e.keyCode;

        if(keyCode == 13) {
            this.kreg_sz();
        };
        if(keyCode == 27) {
            this.setState({sz_val: ""});
        };
    }
    sz_change (e) {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({sz_val: value});
        //setState({[name]: value});
    }
    kreg_sz() {
        var self = this;
        var koa = self.state.koa;
        var sz = this.state.sz_val;

        //var kka = koa.filter(row => row.kk00.ftg.toUpperCase().includes(sz.toUpperCase()));
        var kka = koa.filter(row => row.kk00.ftg.toUpperCase().includes(sz.toUpperCase()));
        self.setState({kka: kka });
    }

  render() {
    return (
    <div className="web-app">
    <div className="flex-row flex-space-between">
        <div className="flex-row">
            <input className="txt sztxt" value={this.state.sz_val} onChange={(e) => this.sz_change(e)} onKeyDown={(e) => this.sz_keydown(e)} />
        </div>

        <div className="flex"></div>

        <div className="flex-row">
        <button className="btn btn-hw-orange w150" onClick={() => this.kid_add() } aria-label="System" >
            LÄGG TILL
        </button>
        <button className="btn btn-hw-blue w150" onClick={() => this.refresh() } aria-label="System" >
            UPPDATERA
        </button>
        </div>
    </div>
    <div className="divider_orange"></div>

        <div id="kkTable" className="flex">
          <table className="table table-bordered table-hover tb" style={{marginBottom: "0px"}}>
            <thead>
            <tr>
                <th style={ this.th_style("kid") } onClick={() => this.tb_sort("kid") }>KID</th>
                <th style={ this.th_style("text") } onClick={() => this.tb_sort("text") }>Text</th>
                <th style={ this.th_style("ftg") } onClick={() => this.tb_sort("ftg") }>Företag</th>
                <th style={ this.th_style("ref") } onClick={() => this.tb_sort("ref") }>Referens</th>
                <th style={ this.th_style("orgnr") } onClick={() => this.tb_sort("orgnr") }>Orgnr</th>
                <th style={ this.th_style("tele") } onClick={() => this.tb_sort("tele") }>Tele</th>
                <th style={ this.th_style("reg") } onClick={() => this.tb_sort("reg") }>Start</th>
                <th></th>
            </tr>
            </thead>
        </table>
        <div style={ this.tb_style() }>
          <table className="table table-bordered table-hover tb">
            <tbody>
            { this.state.kka.map((row, key) =>
            <tr key={key} onDoubleClick={() => this.kid_edit(row) }>
                <td>{ utils.toNum( row.kk00.kid ) }</td>
                <td>{ utils.toLang( row.kk00.text ) }</td>
                <td>{ utils.toLang( row.kk00.ftg ) }</td>
                <td>{ row.kk00.ref }</td>
                <td>{ row.kk00.orgnr }</td>
                <td>{ row.kk02.tele }</td>
                <td>{ utils.toDate( row.kk00.reg ) }</td>
                <td>
                    <Td_Sel items={ this.selitems } getValue={(e)=> this.sel_row_fkn(row, e)} />
                </td>
            </tr>
            )}
            </tbody>
        </table>
        </div>
    </div>
        { this.state.wnds.kidadd ? <Kid_add wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.wnds.kidedit ? <Kid_open wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }

   </div>
  );
  }
}
export default Wrk_Kreg;
/*

*/