import React, { useState, useEffect, useContext } from 'react';
import * as fkn from "../lib/fkn";
import SDOContext from '../sdo.js'
import {Route, Routes} from "react-router-dom";
//import ADOContext from "../ado";

import Fkn_Home from './fkn/home.js';
import Fkn_Tele from './fkn/tele.js';
import Fkn_Maint from './fkn/maint.js';
import Fkn_Laf from './fkn/laf.js';
import Fkn_Bevak from './fkn/bevak.js';
import Fkn_Logg from './fkn/logg.js';
import Bo_Home from "./bo/home";
import {withRouter} from "../lib/react/withRouter";
import sso from "../sso";

function pg_home() {

    var html =
    <div className="web-content">
      Hem
    </div>
    ;
    return html;
}

class Pms_Fkn extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, deps: [] };
    //context.posrend = this;
  }
  fknGo(row) {
    //alert(row.href);
    this.props.store.fkn = row;
      sso.seo.sfkn = row;
    this.props.navigate(row.url);
  }
    br_css(row) {
        var css = {};
        if(sso.seo.sfkn && (row.url == sso.seo.sfkn.url)) css.color = "#080";
        //var brc = false;
        //if(row.url == sbo.state.url) brc = true;
        return css;
    }

  render() {
      var path = window.location.pathname;
      if(path.substr(-1) == "/") path = path.substring(0, path.length - 1);
      var op = path.split("/").pop();
      var po = {};
      po.tele = { "fid": "f08", "url": "/pms/fkn/tele", "name": "Telelista", "active": false };
      po.maint = { "fid": "f08", "url": "/pms/fkn/maint", "name": "Maintenance", "active": false };
      po.laf = { "fid": "f08", "url": "/pms/fkn/laf", "name": "Lost and Found", "active": false };
      po.bevak = { "fid": "f08", "url": "/pms/fkn/bevak", "name": "Bevakningar", "active": false };
      po.logg = { "fid": "f08", "url": "/pms/fkn/logg", "name": "Logg", "active": false };
      if(po[op]) {
          po[op].active = true;
          //this.props.store.fkn = po[op];
      }
      var pa = Object.values(po);

    return (
    <div className="web-app">
         { this.props.store.cfg.bread ?
        <div className="web-bread">
            <div className="breadcrumb">
            { pa.map((row, key) =>
                <div className="breadcrumb-item" key={key} style={this.br_css(row)} onClick={() => this.fknGo(row)}>{row.name}</div>
            )}
            <div className="flex"></div>
            <div className="cdigreen">{ this.props.store.fkn ? this.props.store.fkn.name : null }</div>
            </div>
        </div>
        : null }
        <Routes>
          <Route path="/xx" component={pg_home} />
            <Route path="tele" element={ <Fkn_Tele store={this.webStore} />} />
            <Route path="maint" element={ <Fkn_Maint store={this.webStore} />} />
            <Route path="laf" element={ <Fkn_Laf store={this.webStore} />} />
            <Route path="bevak" element={ <Fkn_Bevak store={this.webStore} />} />
            <Route path="logg" element={ <Fkn_Logg store={this.webStore} />} />
            <Route path="*" element={ <Fkn_Home store={this.webStore} />} />
        </Routes>
    </div>
  );
}
}
//Sys.contextType = ADOContext;

export default withRouter(Pms_Fkn);
