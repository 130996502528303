import React, { useState, useEffect, useContext } from 'react';
import {format} from "date-fns";

import Dd_Sel from "../../../../lib/ui/dd_sel";
import * as net from "../../../../lib/net";

import Split_Row from "./split_row";
import Split_To_Gst from "./split_to_gst";
import Move_To_Gst from "./move_to_gst";

class Nota_Rad extends React.Component {
  constructor(props, context) {
    super(props);

    this.fkn_close = null;
    this.state = {};
    this.state.ynq = [];

    this.state.paa = [];
    this.state.aa = [];

    this.state.rstrow = false;
    this.state.boknr = "";
    this.state.rvo = {};
    if(props.wnds) {
        this.fkn_close = props.wnds.fkn_close;
        this.state.boknr = props.wnds.boknr;
        this.state.rvo = props.wnds.rvo;
        this.state.row = props.wnds.row;
    }
    this.state.wnds = {};

    this.state.today = new Date();
    var dts = format(this.state.today, "yyyy-MM-dd");
    this.state.dts = dts;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }


    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    wnd_close() {
      if(this.fkn_close) this.fkn_close(this.props.ctx);
      else this.props.ctx.setState({ wnds: {} });
	};
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  corr_row() {
    var self = this;

    var tro = this.state.row.etr01;

    var prm = {};
    prm.req = "pms.sell.corr_tr";
    prm.boknr = this.state.rvo.boknr;
    prm.gsb00 = this.state.rvo.gsb00;
    prm.etr01 = tro;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rco));

      if(self.fkn_close) self.fkn_close(self.props.ctx);
      else self.props.ctx.setState({ wnds: {} });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
    cb_close(ctx) {
      if(ctx.fkn_close) ctx.fkn_close(this.props.ctx);
      else ctx.props.ctx.setState({ wnds: {} });
	};
  split_to_gst() {
    var self = this;
    var wnds = {};
    wnds.splittogst = true;
    wnds.boknr = this.state.rvo.boknr;
    wnds.gsb00 = this.state.rvo.gsb00;
    wnds.etr01 = this.state.row.etr01;
    //wnds.fkn_close = self.wnds_close;
    self.setState({ wnds: wnds });
  }
  move_to_gst() {
    var self = this;
    var wnds = {};
    wnds.movetogst = true;
    wnds.boknr = this.state.rvo.boknr;
    wnds.gsb00 = this.state.rvo.gsb00;
    wnds.etr01 = this.state.row.etr01;
    wnds.fkn_close = self.cb_close;
    self.setState({ wnds: wnds });
  }
  split_row() {
    var self = this;
    var wnds = {};
    wnds.splitrow = true;
    wnds.boknr = this.state.rvo.boknr;
    wnds.gsb00 = this.state.rvo.gsb00;
    wnds.etr01 = this.state.row.etr01;
    //wnds.fkn_close = self.wnds_close;
    self.setState({ wnds: wnds });
  }

  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "";

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="m2_backdrop" /> : null }
        <div className="m2_dlg mx_50h flex-col" style={ this.dlg_style() }>
        <div className="flex-row " style={{padding:"0px",height:"50px",lineHeight:"50px", background: "#1a456b", color: "#fff"}}>
                <div className="flex-row flex" style={{padding:"0px 5px", fontSize: "22px"}}>
                    <div>NOTA RAD { this.state.rumsnr }</div>
                </div>
            <div className="wnd-close-btn" onClick={() => this.wnd_close() }>X</div>
        </div>
        <div className="mx_content flex">

        <div className="flex-row">
            <div className="txt_16">RAD:</div>
        </div>
        <div className="flex-row">
            <div className="txt_16">{ this.state.row.etr01.e0text }</div>
            <div className="txt_16 text-center flex">{ this.state.row.etr01.e0antal.ztrim() } * { this.state.row.etr01.e0pris.cdipris() }</div>
            <div className="txt_16">{ (this.state.row.etr01.e0antal * this.state.row.etr01.e0pris).cdipris() }</div>
        </div>
        <div className="divider_green"></div>

        <div className="web-col flex-center flex">
            <div className="wnd-btn w250" onClick={() => this.corr_row() } >
                <div>KORRIGERA RAD</div>
                <div></div>
            </div>
            <div className="wnd-btn w250" onClick={() => this.split_to_gst() } >
                <div>DELA TILL GÄST</div>
                <div></div>
            </div>
            <div className="wnd-btn w250" onClick={() => this.move_to_gst() } >
                <div>FLYTTA TILL GÄST</div>
                <div></div>
            </div>
            <div className="wnd-btn w250" onClick={() => this.split_row() } >
                <div>DELA RAD</div>
                <div></div>
            </div>
            { this.state.rstrow ?
            <div className="wnd-btn w250 hide" onClick={() => this.show_rst() } >
                <div>VISA NOTA</div>
                <div></div>
            </div>
            : null }
        </div>

        </div>
        <div className="mx_foot flex-row flex-space-between">
            <div className="flex-row"></div>
            <button className="btn btn-hw-red w250 ripple" onClick={() => this.wnd_close() } >
                AVBRYT
            </button>
            <div className="flex-row"></div>
        </div>
      </div>
    { this.state.wnds.splitrow ? <Split_Row wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
    { this.state.wnds.splittogst ? <Split_To_Gst wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
    { this.state.wnds.movetogst ? <Move_To_Gst wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
    </div>
	;
	return html;
  };
}

export default Nota_Rad;