import React, { useState, useEffect, useContext } from 'react';

import Dt_Cal from "../../lib/ui/dt_cal";
import {format, getHours, subDays} from "date-fns";

import * as net from "../../lib/net";
import * as utils from "../../assets/utils";

import ddo from "../../ddo";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Fsg_Trs extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, tra: [] };
    this.state.unit = "00";
    //this.state.units = [];
      this.state.una = ddo.cfg.una;
      this.state.uno = ddo.cfg.units;

      this.state.today = new Date();
      var hh = getHours(this.state.today);
      if( hh < 7) {
          const dt = subDays(new Date(), 1);
          this.state.datum = format(dt, "yyyy-MM-dd");
      }
      else this.state.datum = format(this.state.today, "yyyy-MM-dd");
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      this.trans(this.state.unit);
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 250;
      return css;
  }

  dt_dat(e) {
    //alert(JSON.stringify(e));
    var dts = format(e, "yyyy-MM-dd");
    this.setState({ datum: dts });
  }

  onUnitChange(e) {
        this.setState({unit: e.value});
  }
  change_unit(e) {
    //let cdat = this.state.seldate;
    //alert(JSON.stringify(e));
    let unit = e.target.value;
    this.setState({unit: unit, tra: []});
    this.trans(unit);
  }

  get_unit(id) {
    var self = this;
    var text = "";
    if(this.state.uno[id]) {
        var o = this.state.uno[id];
        if(o) text = o.text;
    }
    return text;
  }

  trans(unit) {
    var self = this;
    var datum = this.state.datum;
    var today = this.state.today;

    var avd = "";
    var sid = null;
    //var unit = this.state.unit;
    if(unit) {
        if(this.state.uno[unit]) {
            var o = this.state.uno[unit];
            sid = o.sid;
            avd = o.id;
        }
    }
    if(!sid) {
        //alert("VÄLJ ENHET");
        //self.setState({tra: [] });
        //return;
    }
      //alert(JSON.stringify(sid));
    //if(datum == today) this.trans_today(avd);
    //else this.trans_dt(avd, datum);
      var prm = {};
      prm.avd = avd;
      prm.req = "pos.pos_cas.trs_datum";
      prm.datum = datum;
      prm.fdat = datum;
      prm.tdat = datum;

      net.sio_req(prm)
      .then(function(ret){
          //alert(JSON.stringify(ret.rca));

          if(ret.ok != "000") {
              return;
          }
          self.setState({tra: ret.rca });
          //toastr.success("OK", "SAPI");
      })
      .catch(function(e){
          var txt = JSON.stringify(e);
      });
  }

  trans_today(avd) {
    var self = this;
    var prm = {};
    prm.avd = avd;
    prm.req = "pos.pos_cas.trs";
    //prm.fdat = datum;
    //prm.tdat = datum;

    //gda.wndLoading("Hämtar");
    //net.sio_req(prm)
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            return;
        }
        var o = {};
        //o.deps = ret.rco;
        //alert(JSON.stringify(o));
        self.setState({tra: ret.rca });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
    }

  trans_dt(avd, datum) {
    var self = this;
    var prm = {};
    prm.avd = avd;
    prm.req = "pos.pos_cas.trs_dt";
    prm.fdat = datum;
    prm.tdat = datum;

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        if(ret.ok != "000") {
            return;
        }
        var o = {};
        //o.deps = ret.rco;
        //alert(JSON.stringify(o));
        self.setState({tra: ret.rca });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
    }

  render() {
    return (
    <div className="web-app">
    <h3>Transaktioner</h3>

    <div className="flex-row flex-space-between">
        <div className="flex-row">
            <Dt_Cal dts={this.state.datum} getValue={(e)=> this.dt_dat(e)} />
        <select className="form-control sel_input w300" placeholder="Välj enhet" value={this.state.unitid} onChange={(e) => this.change_unit(e)} >
            { this.state.una.map((row, key) =>
            <option key={key} value={row.id}>{row.text}</option>
            )}
        </select>
        </div>
         <button className="btn btn-hw-blue w150 ripple" onClick={() => this.trans(this.state.unit) } aria-label="System" >
            UPPDATERA
        </button>
   </div>
    <div className="divider_orange"></div>

    <div className="web-row">
        <table className="table table-bordered table-hover tb fixed_header">
            <thead>
            <tr>
                <th>Avd</th>
                <th>Enhet</th>
                <th>Tid</th>
                <th>ID</th>
                <th>Text</th>
                <th>Antal</th>
                <th>Pris</th>
                <th>Summa</th>
                <th>Terminal</th>
                <th>Status</th>
            </tr>
            </thead>
            <tbody style={ this.tb_style() }>
            { this.state.tra.map((row, key) =>
            <tr key={key} id={ row.r0dateid } ng-dblclick="trans_open(row);">
                <td>{ row.r0cstplc }</td>
                <td>{ this.get_unit(row.r0cstplc) }</td>
                <td>{ utils.toTid( row.r0time ) }</td>
                <td>{ row.r0etyp }</td>
                <td>{ utils.toLang( row.r0text ) }</td>
                <td>{ utils.toNum( row.r0noof ) }</td>
                <td>{ utils.toPris( row.r0perprice ) }</td>
                <td>{ utils.toPris( row.r0perprice ) }</td>
                <td>{ row.r0terminal }</td>
                <td>{ row.r0ttyp }</td>
            </tr>
            )}
            </tbody>
        </table>
    </div>

    </div>
  );
}
}
export default Fsg_Trs;

/*
    <td>{ this.state.uno[row.r0cstplc].text }</td>
*/