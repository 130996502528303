import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../../lib/net";
import * as utils from "../../../assets/utils";

import Fr_Input from "../../../lib/ui/fr_input";
import Wnd_Error from "../../../lib/ui/wnd_error";
import Wnd_Ok from "../../../lib/ui/wnd_ok";
import Wnd_Info from "../../../lib/ui/wnd_info";
import Wnd_Confirm from "../../../lib/ui/wnd_confirm";
import Fr_Cal from "../../../lib/ui/fr_cal";
import Lang from "../../../lib/lang";
import Td_Sel from "../../../lib/ui/td_sel";

class Plu_Menu_Edit extends React.Component {
  constructor(props, context) {
    super(props);

    ///this.pos = props.store.pos;

    this.state = {};
    this.state.mpa = [];

    this.state.gnr = "001";
    this.state.ynq = [
                {id: '0', text: 'NEJ'},
                {id: '1', text: 'JA'}
            ];
      this.state.mtval = [
          {id: 'menu', text: 'MENY PRODUKT'},
          {id: 'info', text: 'INFORMATION'}
      ];

      this.state.tabval = 0;
      this.state.selrow = {};
      this.state.msg = {};
      this.state.wnds = {};
      this.state.mpb = {};
      this.state.mpo = {};
    if(props.wnds.mpo) {
        this.state.mpb = props.wnds.mpo;
        this.state.mpo = JSON.parse(JSON.stringify(props.wnds.mpo));
        this.state.mpo.text = this.state.mpo.text.cdi2txt();
    }
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      //if(!this.props.wnds.tdo) this.task_init();
      //this.mnu_mp();
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    tb_style() {
        var css = {};
        css.height = (window.innerHeight * 0.9) - 275;
        return css;
    }
    th_style(id) {
        var css = {};
        css.cursor = "pointer";
        if(id == this.state.sortid) {
            if(this.state.sortdir) css.color = "#800";
            else css.color = "#080";
        }
        return css;
    }
    row_style(row) {
        var srow = this.state.selrow;

        var css = {};
        if(srow == row) {
            css.background = "#080";
            css.color = "#fff";
        }
        return css;
    }
    tab_head(tabid) {
        var css = {};
        css.background = "#fff";
        css.color = "#000";
        if(this.state.tabval === tabid) {
            css.background = "#080";
            css.color = "#fff";
        }
        return css;
    }
    tab_style(tabid) {
        var css = {};
        css.display = "none";
        if(this.state.tabval === tabid) css.display = "block";
        return css;
    }
    tab_sel(tabid) {
      var self = this;
        this.setState({tabval: tabid}, function() {
            if(tabid == 1) {
                self.syncTb("mnpTable");
            }
        });
    }

    wnd_close = () => {
        if(this.props.close) {
            this.props.close(this.props.ctx);
        }
        else this.props.ctx.setState({ wnds: {} });
    };
    mt_change (e) {
        const value = e.target.value;
        var mpo = this.state.mpo;
        mpo.prdtyp = value;
        this.setState({mpo: mpo});
    }
    anm_change (e) {
        const value = e.target.value;
        var mno = this.state.mno;
        mno.anm = value;
        this.setState({mno: mno});
    }

    val_save(id, val) {
        var mpo = this.state.mpo;
        mpo[id] = val;
        this.setState({ mpo: mpo });
        return;
    }
    sel_row(row) {
        var srow = this.state.selrow;
        if(srow == row) {
            this.setState({selrow: {}});
        }
        else this.setState({selrow: row});
        this.syncTb("mnpTable");
    }
    sel_row_fkn(row, sel) {
        if(sel.fkn == "open") {
        }
    }
    mnu_mp() {
        var self = this;
        var prm = {};
        prm.req = "dsi.dsi_mp.mp_mnu";
        prm.mnuid = this.state.mno.mnuid;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.rca));

                self.setState({ mpa: ret.rca });
                //self.setState({ rva: ret.rca });
                self.syncTb("mnpTable");
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    syncTb(tb) {
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
            tdElements = tbel.getElementsByTagName('td');
        if(tdElements.length < 1 ) return;

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
                width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
    tb_sort(typ) {
        var self = this;
        //var rvs = self.state.rvs;
        var rva = self.state.rva;
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;

        if(sortid != typ) sortdir = 0;
        else {
            if(sortdir) sortdir = 0;
            else sortdir = 1;
        }

        if(typ == "ank") {
            if(sortdir) rva.sort((a,b) => (a.gsb00.r0ankdatum < b.gsb00.r0ankdatum) ? 1 : ((b.gsb00.r0ankdatum < a.gsb00.r0ankdatum) ? -1 : 0));
            else rva.sort((a,b) => (a.gsb00.r0ankdatum > b.gsb00.r0ankdatum) ? 1 : ((b.gsb00.r0ankdatum > a.gsb00.r0ankdatum) ? -1 : 0));
        }
        self.setState({ rva: rva, sortid: typ, sortdir: sortdir });
    }
    th_icon(typ) {
        var cls = "";
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;
        if(sortid == typ)
        {
            if(sortdir) cls = "fa fa-caret-up";
            else cls = "fa fa-caret-down";
        }
        return cls;
    }
  prd_save() {
    var self = this;
    var prm = {};
    prm.req = "dsi.dsi_mp.mp_upd";
    prm.mpo = this.state.mpo;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));
        if(ret.ok == "000") {
            self.setState({ msg: {ok: true, text: "Sparad ..."} });
            setTimeout(function(){
                self.setState({msg: {} });
                  if(self.props.wnds.cb) {
                      self.props.wnds.cb(self.props.ctx);
                  }
                  else self.props.ctx.setState({ wnds: {} });
                return;
            }, 1000);
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  prd_del() {
    var self = this;
    var prm = {};
    prm.req = "dsi.dsi_mp.mp_del";
    prm.mpo = this.state.mpo;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));
        if(ret.ok == "000") {
            self.setState({ msg: {ok: true, text: "Borttagen"} });
            setTimeout(function(){
                self.setState({msg: {} });
                if(self.props.wnds.cb) {
                    self.props.wnds.cb(self.props.ctx);
                }
                else self.props.ctx.setState({ wnds: {} });
                return;
            }, 1000);
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

  render() {
    //if(!this.props.show) return "";
    var title = this.props.title;
    if(!title) title = "Resurs";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        <div className="m2_backdrop" />
        <div className="m2_dlg mx_70 flex-col" style={ this.dlg_style() } draggable="true" >
        <div className="mx_content flex">

        <div className="mx_h01">
            <div>MENY PRODUKT : { this.state.mpo.plu }</div>
        </div>
            <div className="flex-row" style={{padding:"5px",height:"35px"}}>
                    <div className="flex-col flex">
                    </div>
                    <div className="flex-col">
                        <div>UPPDATERA</div>
                    </div>
            </div>
            <div className="divider_orange"></div>

            <form className="cdi_f03">
                <div className="flex-row">
                    <div className="form-group flex-row flex">
                        <span className="header">Menytyp</span>
                        <select value={this.state.mpo.mnutyp} className="form-control w250" onChange={(e) => this.mt_change(e)}>
                            { this.state.mtval.map((row, key) =>
                                <option key={key} value={row.id}>{row.text}</option>
                            )}
                        </select>
                    </div>
                </div>
                <div className="flex-row">
                    <Fr_Input label="Text" className="text-left w350" text={this.state.mpo.text} getValue={(e)=> this.val_save("text", e) } />
                </div>
                <div className="flex-row">
                    <Fr_Input label="Meny ID" className="text-left w150" text={this.state.mpo.mnuid} getValue={(e)=> this.val_save("mnuid", e) } />
                    <Fr_Input label="Status" className="text-left w350" text={this.state.mpo.status} getValue={(e)=> this.val_save("status", e) } />
                </div>
                <div className="flex-row">
                    <Fr_Input label="Sortering" className="text-left w150" text={this.state.mpo.sort} getValue={(e)=> this.val_save("sort", e) } />
                </div>

                <div className="divider_green"></div>
                <div className="flex-row hide">
                    <div className="flex flex-column" style={{padding: "0px 5px"}}>
                        <span className="header">Produkt information</span>
                        <textarea className="form-control" maxLength="512" rows="10" value={this.state.mpo.anm} onChange={(e) => this.tdata_change(e)}></textarea>
                    </div>
                </div>
            </form>

        </div>
        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250 ripple" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <button className="btn btn-hw-red w250 ripple" onClick={() => this.prd_del() } >
                TA BORT
            </button>
            <div className="flex-row">
            <button className="btn btn-hw-red w250 ripple" onClick={() => this.prd_save() } >
                SPARA
            </button>
            </div>
        </div>
      </div>

        <Wnd_Error msg={this.state.msg} ctx={this} />
        <Wnd_Ok msg={this.state.msg} ctx={this} />
        <Wnd_Info msg={this.state.msg} ctx={this} />
        <Wnd_Confirm msg={this.state.msg} ctx={this} />
      </div>
	;
	return html;
  };
}

export default Plu_Menu_Edit;