import React, { useState, useEffect, useRef, useContext } from 'react';
import { parseISO, format, getDate, getMonth, getWeek, eachDayOfInterval, eachWeekOfInterval } from 'date-fns'
import { setMonth, setYear, startOfWeek, endOfWeek, startOfMonth, endOfMonth, addMonths, subMonths, addDays, subDays, isSameDay } from 'date-fns'
import { sv } from 'date-fns/locale'

import './css/fm_input.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Fr_Inpkats extends React.Component {
  constructor(props, context) {
    super(props);

    this.node = React.createRef();
    //this.setNodeRef = this.setNodeRef.bind(this);

    this.state = {};
    this.state.itemx = [];
    this.state.lso = {};
    //this.state.lro = props.lro;
    this.state.cols = {};
    this.state.sel_show = false;
    this.state.sel_step = 0;

    //context.posrend = this;
    this.state.today = new Date();
    this.state.ctrltext = props.text;
    this.state.txtval = "";
    this.state.txtsel = 0;
    if(props.text) this.state.txtval = props.text.trim();
    this.state.row = 0;
    if(props.row) this.state.row = 1;
    this.state.col = 0;
    if(props.col) this.state.col = 1;
    this.state.placeholder = "";
    if(props.placeholder) this.state.placeholder = props.placeholder;
    //this.state.items = [];
    //if(props.items) this.state.items = props.items;

    this.txtsel = 0;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick);
  }
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClick);
    this.setState({ txtsel: false })
  }
  handleClick = (e) => {
    if(!this.state.sel_show) return;

    if (!this.node.current.contains(e.target)) {
      this.setState({sel_show: false});
      return;
    }
  }

  cls() {
    var cls = "";
    cls = "ff_ctrl flex";
    //if(this.props.className) cls = cls + " " + this.props.className;
    if(this.props.noflex) cls = "ff_ctrl";
    return cls;
  }

  clsGroup() {
    var cls = "";
    cls = "form-group";
    if(!this.props.col) cls = cls + " flex-row";
    if(this.props.col) cls = cls + " flex-column";
    cls = cls + " flex";
    return cls;
  }
  clsFrm() {
    var cls = "";
    cls = "";
    if(this.props.row) cls = "flex-row";
    return cls;
  }

  clsInput() {
    var cls = "";
    cls = "form-control";
    if(this.props.className) cls = cls + " " + this.props.className;
    return cls;
  }

    showsel(e) {
      e.preventDefault();

      var brc = this.state.sel_show;
      brc = !brc;
      //var val = this.props.text;
      //this.setState({sel_show: brc, sel_step: 0, txtval: val});
      //this.setState({txtsel: true});
   }
    selStep(e) {
      e.preventDefault();

      //this.setState({sel_step: 0});
      var brc = this.state.sel_show;
      brc = !brc;
      this.setState({sel_show: brc, sel_step: 0});
    }
    selRow(row) {

      //this.setState({sel_step: 0});
      var brc = this.state.sel_show;
      brc = !brc;
      this.setState({sel_show: brc, sel_step: 0});
      //this.props.getValue(row);
      this.props.getValue(row.n0typ);
    }
    onFocus(e) {
      e.preventDefault();

      this.showsel(e);
      //this.setState({txtsel: 1});
    }
    onBlur(e) {
      e.preventDefault();

      this.setState({txtsel: 0});

      if(this.state.txtval.length > 0) {
          //this.props.getValue(this.state.txtval);
      }
    }

    changeHandler (e) {
      e.preventDefault();
      const value = e.target.value;
      //alert("CHANGE: " + JSON.stringify(value));
      //this.setState({ txtval: value });
      this.props.getValue(value);
    }
    inputClick (e) {
      //e.preventDefault();
      if(this.props.onClick) {
          this.props.onClick(e);
      }
    }

  inputStyle() {
      var css = {};
      if(this.state.cal_show) {
        css.background = "#080";
        css.color = "#fff";
      }
      return css;
  }
  label_style() {
    var css = {};

    if(this.state.row) {
        css.width = "75px";
        css.fontSize = "14px";
        css.background = "#fff";
        css.color = "#800";
    }

    return css;
  }
  sel_style() {
    var css = {};

    if(!this.node.current) return css;

    var max_w = window.innerWidth;
    var max_h = window.innerHeight;
    var pos_w = this.node.current.getBoundingClientRect().left;
    var pos_t = this.node.current.getBoundingClientRect().top;
    var pos_h = this.node.current.getBoundingClientRect().height;
    var out = false;

    if(max_w < (pos_w + 400)) out = true;;
    if(out) css.right = "5px";
    //else css.left = pos_w + 75 + "px";
    //else css.left = pos_w + 175 + "px";

    if(max_h < (pos_t + pos_h + 50)) {
      css.bottom = "40px";
      css.zIndex = "29999";
    }

    return css;
  }
    updateState = (value) => {
      //alert("CTRL: " + value);
      this.setState({ txtval: value });
    }
    showsel(e) {
      e.preventDefault();

      //alert("X: " + JSON.stringify(this.node.current.getBoundingClientRect()) );
      //alert("XX: " + this.node.current.offsetX);

      var brc = this.state.sel_show;
      brc = !brc;
      this.setState({sel_show: brc, sel_step: 0});
    }

  render() {
    //if(!this.props.show) return "";
    //alert(this.props.ctx.state.lro);

    var title = this.props.title;
    if(!title) title = "URVAL";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div ref={this.node} className={ this.cls() }>
        <div className={ this.clsGroup() }>
            <span className="header txt_12">{ this.props.label }</span>
          <div className="flex-column">
            <input type="text" className={ this.clsInput() }
               value={this.props.text} onClick={(e) => this.inputClick(e)}
               placeholder={this.state.placeholder} onChange={(e) => this.changeHandler(e)}
               onFocus={(e) => this.onFocus(e)} onBlur={(e) => this.onBlur(e)}
            />
          { this.state.sel_show ?
              <div className="dd_sel flex flex-col" style={ this.sel_style() }>
                <div className="ddi_content flex">

                  { this.props.items.map((row, key) =>
                      <div key={key} className="ddi_row flex-row" onClick={(e) => this.selRow(row) }>
                        <div>{row.n0typ}-{row.n0text}</div>
                        <div className="flex"></div>
                        <div>{ row.extra }</div>
                      </div>
                  )}
                </div>

                <div className="ddi_foot flex-row flex-center">
                  <div className="ifoot_close" onClick={(e) => this.selStep(e) }>
                    STÄNG
                  </div>
                  <div className="flex-row"></div>
                </div>

              </div>
              : null }
          </div>
            { this.props.sz ?
            <div className="input-group-append">
                <div className="text-center input-group-text" onClick={() => this.props.sz() }>
                    <FontAwesomeIcon icon="search" size="1x"/>
                </div>
            </div>
            : null }
        </div>
        </div>
	;
	return html;
  };
}

export default Fr_Inpkats;

//{...this.props}