import React, { useState, useEffect, useContext } from 'react';
import {Route, Routes} from "react-router-dom";

import Eko_Home from './eko/home.js';
import Eko_Rev from './eko/rev.js';
import Eko_Konto from './eko/konto.js';
import Eko_Krub from './eko/krub.js';
import Eko_Trs from './eko/trs.js';
import Eko_Invs from './eko/invs.js';
import Eko_Rvs from './eko/rvs.js';
import Eko_Saldo from './eko/saldo.js';

import {withRouter} from "../lib/react/withRouter";
import sso from "../sso";

function pg_home() {

    var html =
    <div className="web-content">
      Hem
    </div>
    ;
    return html;
}

class Pms_Eko extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, deps: [] };
  }
  fknGo(row) {
    //alert(row.href);
    this.props.store.fkn = row;
      sso.seo.sfkn = row;
    this.props.navigate(row.url);
  }
    br_css(row) {
        var css = {};
        if(sso.seo.sfkn && (row.url == sso.seo.sfkn.url)) css.color = "#080";
        //var brc = false;
        //if(row.url == sbo.state.url) brc = true;
        return css;
    }

  render() {
      var path = window.location.pathname;
      if(path.substr(-1) == "/") path = path.substring(0, path.length - 1);
      var op = path.split("/").pop();
      var po = {};
      po.rev = { "fid": "f05", "url": "/pms/eko/rev", "name": "Omsättning", "active": false };
      po.konto = { "fid": "f05", "url": "/pms/eko/konto", "name": "Konto", "active": false };
      po.krub = { "fid": "f05", "url": "/pms/eko/krub", "name": "Krubba", "active": false };
      po.trs = { "fid": "f05", "url": "/pms/eko/trs", "name": "Transaktioner", "active": false };
      po.invs = { "fid": "f05", "url": "/pms/eko/invs", "name": "Notor", "active": false };
      po.rvs = { "fid": "f05", "url": "/pms/eko/rvs", "name": "Bokningar", "active": false };
      po.saldo = { "fid": "f05", "url": "/pms/eko/saldo", "name": "Saldo", "active": false };
      if(po[op]) {
          po[op].active = true;
          //this.props.store.fkn = po[op];
      }
      var pa = Object.values(po);

    return (
    <div className="web-app">
         { this.props.store.cfg.bread ?
        <div className="web-bread">
            <div className="breadcrumb">
            { pa.map((row, key) =>
                <div className="breadcrumb-item" key={key} style={this.br_css(row)} onClick={() => this.fknGo(row)}>{row.name}</div>
            )}
            <div className="flex"></div>
            <div className="cdigreen">{ this.props.store.fkn ? this.props.store.fkn.name : null }</div>
            </div>
        </div>
        : null }
        <Routes>
          <Route path="/xx" component={pg_home} />
            <Route path="rev" element={ <Eko_Rev store={this.webStore} />} />
            <Route path="konto" element={ <Eko_Konto store={this.webStore} />} />
            <Route path="krub" element={ <Eko_Krub store={this.webStore} />} />
            <Route path="trs" element={ <Eko_Trs store={this.webStore} />} />
            <Route path="invs" element={ <Eko_Invs store={this.webStore} />} />
            <Route path="rvs" element={ <Eko_Rvs store={this.webStore} />} />
            <Route path="saldo" element={ <Eko_Saldo store={this.webStore} />} />
            <Route path="*" element={ <Eko_Home store={this.webStore} />} />
        </Routes>
    </div>

  );
}
}
//Sys.contextType = ADOContext;

export default withRouter(Pms_Eko);
