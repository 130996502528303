import React, { useState, useEffect, useContext } from 'react';

import * as net from "../lib/net";
import Dt_Cal from "../lib/ui/dt_cal";
import Dd_Sel from "../lib/ui/dd_sel";
import Sim_Emv from "./sim/sim_emv";
import Tst_Emv from "./sim/tst_emv";
import Run_Grs from "./sim/run_grs";
import Emv_WsApi from "./sim/emv_wsapi";
import {withRouter} from "../lib/react/withRouter";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Tech_Emv extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, tra: [] };
    this.state.seldate = new Date();
    this.state.avd = "01";
    this.state.taa = [];
    this.state.aa = [];
    this.state.wnds = {};

      this.state.mna = [];
      this.state.mna.push({ mnid: "mn01", pos: "c1", text: "SIMULERING EMV", desc: "Simulering av EMV", link: "/tech/sim" });
      this.state.mna.push({ mnid: "mn02", pos: "c1", text: "SIMULERING", desc: "Simulering", link: "/pos/op/event" });

      this.state.selitems = [
          {fkn: 'home', text: 'HEM'},
          {fkn: 'setup', text: 'INSTÄLLNINGAR'},
          {fkn: 'ses', text: 'MIN SESSION'},
      ];
      this.state.selitems.push( {fkn: 'logout', text: 'LOGGA UT'} );
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 250;
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }
    mnc(pos) {
        var mna = [];
        for(var row of this.state.mna) {
            if(row.pos == pos) mna.push( row );
        }
        return mna;
    }
    sim_emv() {
        var self = this;
        var wnds = {};
        wnds.simemv = true;
        //wnds.tra = this.state.bqa;
        wnds.backdrop = true;
        self.setState({wnds: wnds });
    }
    tst_emv() {
        var self = this;
        var wnds = {};
        wnds.tstemv = true;
        //wnds.tra = this.state.bqa;
        wnds.backdrop = true;
        self.setState({wnds: wnds });
    }
    run_grs() {
        var self = this;
        var wnds = {};
        wnds.rungrs = true;
        //wnds.tra = this.state.bqa;
        wnds.backdrop = true;
        self.setState({wnds: wnds });
    }
    emv_wsapi() {
        var self = this;
        var wnds = {};
        wnds.emvwsapi = true;
        //wnds.tra = this.state.bqa;
        wnds.backdrop = true;
        self.setState({wnds: wnds });
    }

    sel_fkn(sel) {
        //alert(JSON.stringify(fkn));
        if(sel.fkn == "home") {
            var url = "/hello";
            this.state.navigate(url);
        }
        if(sel.fkn == "setup") {
            var wnds = {};
            wnds.setup = true;
            this.setState({"wnds": wnds});
        }
        if(sel.fkn == "logout") {
            var url = "/";
            this.props.navigate(url);
        }
    }

  render() {
    return (
    <div className="web-app">

    <div className="flex-row px-2">
        <div className="txt_22">Teknik EMV</div>
        <div className="flex"></div>
        <div className="">
            <Dd_Sel items={ this.state.selitems } cls="web-top-icon" getValue={(e)=> this.sel_fkn(e)} />
        </div>
    </div>
    <div className="divider_orange"></div>

        <div className="web-pg py-4 flex">
            <h3>EMV funktioner</h3>
            <p>Diverse funktioner för EMV</p>

            <div className="divider_green"></div>

            <div className="web-row">
                <div className="flex-col flex">
                    <div className="web-bx" onClick={() => this.tst_emv() }>
                        <div>TEST EMV</div>
                        <div className="flex-row">
                            <div className="flex">Testa integrerad EMV (via SRS).Skicka kommandon till kopplad EMV terminal. Test av integrerad funktion.</div>
                            <div className="web-link">Välj</div>
                        </div>
                    </div>
                    <div className="web-bx" onClick={() => this.run_grs() }>
                        <div>Centralsystem Simulering</div>
                        <div className="flex-row">
                            <div className="flex">Simulera kundsystem (via GRS) till EMV. Skicka kommadon för test av funktioner av integrerad terminal.</div>
                            <div className="web-link">Välj</div>
                        </div>
                    </div>
                    <div className="web-bx" onClick={() => this.emv_wsapi() }>
                        <div>Kund Simulering</div>
                        <div className="flex-row">
                            <div className="flex">Simulera kundsystem (via API) till EMV. Skicka kommadon för test av funktioner av integrerad terminal.</div>
                            <div className="web-link">Välj</div>
                        </div>
                    </div>
                </div>
                <div className="flex-col flex">
                    <div className="web-bx" onClick={() => this.sim_emv() }>
                        <div>SIMULERA EMV - VIRTUELL EMVTERMINAL</div>
                        <div className="flex-row">
                            <div className="flex">Simulera EMV Terminal i webläsaren. Se display och utskrift på skärm. Kund kan testa integration mot EMV.</div>
                            <div className="web-link">Välj</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        { this.state.wnds.simemv ? <Sim_Emv wnds={this.state.wnds} ctx={this} /> : null}
        { this.state.wnds.tstemv ? <Tst_Emv wnds={this.state.wnds} ctx={this} /> : null}
        { this.state.wnds.rungrs ? <Run_Grs wnds={this.state.wnds} ctx={this} /> : null}
        { this.state.wnds.emvwsapi ? <Emv_WsApi wnds={this.state.wnds} ctx={this} /> : null}

    </div>
  );
    }
}
export default withRouter(Tech_Emv);
