import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";

import Sal_Edit from "./sal/sal_edit.js";
import Lr_Add from "./sal/lr_add.js";
import Lr_Edit from "./sal/lr_edit.js";
import Lr_Wrk from "./sal/lr_wrk.js";
import Dt_Cal from "../../lib/ui/dt_cal";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class EE_Sal extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, tra: [] };
    this.state.dat = new Date();
    this.state.avd = "01";
    this.state.fdat = "20210101";
    this.state.tdat = "20210131";
    this.state.laa = [];
    this.state.aa = [];
    this.state.cols = {};
    this.state.dat = new Date();
    this.state.lradd = 0;
    this.state.lredit = 0;
    this.state.lro = {};
    this.state.ya = [
                {id: '2022', text: 'ÅR 2022'},
                {id: '2021', text: 'ÅR 2021'},
                {id: '2020', text: 'ÅR 2020'}
            ];
    this.state.wnds = {};
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.lr_period();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

  dt_fdat(e) {
    var self = this;
    var val = e.target.value;
    this.setState({fdat: val});
  }
  dt_tdat(e) {
    var self = this;
    var val = e.target.value;
    this.setState({tdat: val});
  }
  lr_edit(row) {
    var self = this;
    var wnds = {lredit: 1, lro: row};
    this.setState({wnds: wnds});
  }
  lr_wrk(row) {
    var self = this;
    var wnds = {lrwrk: 1, lro: row};
    this.setState({wnds: wnds});
  }
  lr_new() {
    var wnds = {lradd: 1, lro: {}};
    this.setState({wnds: wnds});
  }
  wnds_done(ctx, wnds) {
    ctx.lr_period();
    ctx.setState({wnds: {}});
  }

  lr_period() {
	//var fdat = moment().startOf('month');
	//var tdat = moment().endOf('month');
    //var fdat = startOfMonth(this.state.dat);
    //var tdat = endOfMonth(this.state.dat);

    var self = this;
    var prm = {};
    prm.req = "ta.ta_lon.lr_list";
    prm.fdat = this.state.fdat;
    prm.tdat = this.state.tdat;

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        var o = {};
        //o.deps = ret.rco;
        //alert(JSON.stringify(o));
        self.setState({laa: ret.rca });
        self.syncCols("lonTable");
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }
  lr_del(lro) {
    var self = this;

    var prm = {};
    prm.req = "ta.ta_lon.lr_del";
    prm.lro = lro;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

		self.lr_period();
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  cssCols = function (cid) {
      var css = {};
      if(this.state.cols[cid]) css.width = this.state.cols[cid].toString() + "px";
      //if(cid == "c01") alert(JSON.stringify(this.state.cols[cid]));
      return css;
  }
  syncCols = function (tb) {

    if (!document.getElementById(tb)) return;

    var cols = {};
    var ix = document.getElementById(tb).rows.length - 1;
    var ic = document.getElementById(tb).rows[0].cells.length;
    var ci = 0;
    while(ci < ic) {
        var cxi = ci + 1;
        var col = "c" + cxi.toString().padStart(2, "0");
        cols[col] = document.getElementById(tb).rows[ix].cells[ci].clientWidth + 1;
        ci = ci + 1;
    }
    this.setState({cols: cols });
    //alert(JSON.stringify(this.state.cols));
    //this.forceUpdate();
  };

  render() {
    return (
    <div className="web-app">
    <div className="flex-row flex-space-between">
        <div className="txt_b18 lh_35 px-2">Lönehantering</div>
        <select className="form-control sel_input" placeholder="År">
            { this.state.ya.map((row, key) =>
            <option key={key} value={row.id}>{row.text}</option>
            )}
        </select>
        <div className="flex"></div>

        <div className="flex-row">
        <button className="btn btn-hw-blue w150" onClick={() => this.lr_new() } aria-label="System" >
            Ny Lönekörning
        </button>
        <button className="btn btn-hw-blue w150" onClick={() => this.lr_period() } aria-label="System" >
            UPPDATERA
        </button>
        </div>
    </div>
    <div className="divider"></div>

    <div className="web-col">
        <table className="table table-bordered table-hover tb " style={{marginBottom: "0px"}}>
            <thead>
            <tr>
                <th style={ this.cssCols("c01" ) }>Datum</th>
                <th style={ this.cssCols("c02" ) }>Körid</th>
                <th style={ this.cssCols("c03" ) }>Text</th>
                <th style={ this.cssCols("c04" ) }>Från</th>
                <th style={ this.cssCols("c05" ) }>Till</th>
                <th style={ this.cssCols("c06" ) }>Betdag</th>
                <th style={ this.cssCols("c07" ) }>Belopp</th>
                <th style={ this.cssCols("c08" ) }>Status</th>
                <th style={ this.cssCols("c09" ) }></th>
                <th style={{width:"16px"}} ></th>
            </tr>
            </thead>
        </table>
        <div className="tb-cdi" style={ this.tb_style() }>
       <table id="lonTable" className="table table-bordered table-hover tb">
            <tbody>
            { this.state.laa.map((row, key) =>
            <tr key={key} id={ row.lrid } onDoubleClick={() => this.lr_wrk(row) }>
                <td>{ row.datum }</td>
                <td>{ row.lrid }</td>
                <td>{ row.text }</td>
                <td>{ row.fdat }</td>
                <td>{ row.tdat }</td>
                <td>{ row.betdat }</td>
                <td>{ row.belopp }</td>
                <td>{ row.status }</td>
                <td style={{width:"40px",cursor:"pointer"}} onClick={() => this.lr_del(row) }><i className="fa fa-cog text-danger"></i></td>
            </tr>
            )}
            <tr style={{visibility: "hidden"}}>
                <th>Datum</th>
                <th>Körid</th>
                <th>Text</th>
                <th>Från</th>
                <th>Till</th>
                <th>Betdag</th>
                <th>Belopp</th>
                <th>Status</th>
                <th></th>
            </tr>
            </tbody>
        </table>
        </div>
    </div>
    { this.state.wnds.edit ? <Sal_Edit wnds={this.state.wnds} ctx={this} backdrop={true}/> : null }
    { this.state.wnds.lradd ? <Lr_Add wnds={this.state.wnds} ctx={this} backdrop={true}/> : null }
    { this.state.wnds.lredit ? <Lr_Edit wnds={this.state.wnds} ctx={this} backdrop={true}/> : null }
    { this.state.wnds.lrwrk ? <Lr_Wrk wnds={this.state.wnds} ctx={this} backdrop={true}/> : null }
   </div>
  );
    }
}
export default EE_Sal;
