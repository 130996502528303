import React, { useState, useEffect, useContext } from 'react';

import * as utils from "../../../../assets/utils";
import * as net from "../../../../lib/net";
import Pms_Rvo from "../../../rv/rvo";
import Dd_Sel from "../../../../lib/ui/dd_sel";
import Td_Sel from "../../../../lib/ui/td_sel";

class Move_To_Gst extends React.Component {
  constructor(props, context) {
    super(props);

     this.selitems = [
        {fkn: 'setup', text: 'INSTÄLLNINGAR'},
        {fkn: 'upd', text: 'UPPDATERA'},
        {fkn: 'sys', text: 'SYSTEM'},
        {fkn: 'logout', text: 'LOGGA UT'}
    ];

   //this.webStore = props.store;
    this.state = {};
    this.state.ynq = [];
    this.state.rva = [];
    this.state.srow = {};
    this.state.styp = "inh";
    this.state.boknr = "";
    this.state.etr01 = {};

    if(props.wnds) {
        this.fkn_close = props.wnds.fkn_close;
        this.state.boknr = props.wnds.boknr;
        this.state.etr01 = props.wnds.etr01;
    }
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      this.gsts_inh();
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }

    wnd_close() {
      var rsp = {};
      rsp.ok = "999";

      if(this.fkn_close) this.fkn_close(this.props.ctx, rsp);
      else this.props.ctx.setState({ wnds: {} });
	};
    collect() {
        var cra = [];
        var gsa = Object.values(this.state.srow);
        for(var row of gsa) {
            for(var tro of row.trs) {
                var o = {};
                o.gsb00 = row.gsb00;
                o.etr01 = tro;
                cra.push( o );
            }
        }
        if(cra.length < 1) {
            alert("Inget att samla in!");
            return;
        }
      var rsp = {};
      rsp.ok = "000";
      rsp.cra = cra;

      if(this.fkn_close) this.fkn_close(this.props.ctx, rsp);
      else this.props.ctx.setState({ wnds: {} });
	};
  tb_style() {
      var css = {};
      css.height = (window.innerHeight * 0.9) - 275;
      css.overflow = "auto";
      return css;
  }
  sel_style(typ) {
    var css = {};
    var styp = this.state.styp;
    if(typ == styp) {
        css.background = "#080";
        css.color = "#fff";
    }
    return css;
  }
  mrow_style(row) {
    var srow = this.state.srow;
    var key = row.gsb00.r0boknr + row.gsb00.r0glopnr;
    var css = {};
    if(srow[key]) {
        css.background = "#080";
        css.color = "#fff";
    }
    return css;
  }
  row_style(row) {
    var srow = this.state.srow;
    var css = {};
    if(srow == row) {
        css.background = "#080";
        css.color = "#fff";
    }
    return css;
  }
  sel_typ(typ) {
    var self = this;
    var styp = this.state.styp;
    self.setState({ styp: typ });
    if(typ == "inh") {
      self.gsts_inh();
    }
    if(typ == "pco") {
      self.gsts_pco();
    }
  }

  gsts() {
    var self = this;
    var prm = {};
    prm.req = "pms.op.my_gst_collect";

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        var rva = [];
        var row = {};
        for(row of ret.rca) {
            //alert(JSON.stringify(row.resv00));

            if(row.gsb00) rva.push(row);
        }
        self.setState({ rva: rva });
        self.syncTb("gstTable");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  gsts_inh() {
    var self = this;
    self.setState({ rva: [] });
    var prm = {};
    prm.req = "pms.op.my_inhouse";

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        var rva = [];
        var row = {};
        for(row of ret.rca) {
            //alert(JSON.stringify(row.resv00));

            if(row.gsb00) rva.push(row);
        }
        self.setState({ rva: rva });
        self.syncTb("gstTable");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  gsts_pco() {
    var self = this;
    self.setState({ rva: [] });

    var prm = {};
    prm.req = "pms.op.my_pco";

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        var rva = [];
        var row = {};
        for(row of ret.rco) {
            //alert(JSON.stringify(row.resv00));

            if(row.gsb00) rva.push(row);
        }
        self.setState({ rva: rva });
        self.syncTb("gstTable");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
    syncTb(tb){
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
        tdElements = tbel.getElementsByTagName('td');

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
            width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
  sel_mrow(row) {
    var srow = this.state.srow;
    var key = row.gsb00.r0boknr + row.gsb00.r0glopnr;
    if(srow[key]) {
      delete( srow[key] );
    }
    else srow[key] = row;
    this.setState({srow: srow});
  }
  sel_row(row) {
    var srow = this.state.srow;
    if(srow == row) {
      srow = {};
    }
    else srow = row;
    this.setState({srow: srow});
  }

  sel_split() {
    var self = this;
    var srow = this.state.srow;
    if(srow == {}) {
        return;
    }
    self.move_row(srow);
  }
  move_row(row) {
    var self = this;

    var tro = this.state.etr01;
    var lopnr = tro.e0lopnr;

    var prm = {};
    prm.req = "pms.split.move_tr_togst";
    prm.boknr = this.state.boknr;
    prm.gso = row.gsb00;
    prm.lopnr = lopnr;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rco));

      if(self.fkn_close) self.fkn_close(self.props.ctx);
      else self.props.ctx.setState({ wnds: {} });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "";

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="m2_backdrop" /> : null }
        <div className="m2_dlg mx_80h flex-col" style={ this.dlg_style() }>
        <div className="flex-row " style={{padding:"0px",height:"50px",lineHeight:"50px", background: "#1a456b", color: "#fff"}}>
                <div className="flex-row flex" style={{padding:"0px 5px", fontSize: "22px"}}>
                    <div>FLYTTA TILL GÄST { this.state.rumsnr }</div>
                </div>
            <div className="">
                <Dd_Sel items={ this.selitems } cls="web-top-icon" getValue={(e)=> this.sel_fkn(e)} />
            </div>
        </div>
        <div className="mx_content flex">

        <div className="flex-row">
            <div className="web-btn flex h50" style={ this.sel_style("inh") } onClick={() => this.sel_typ("inh")}>BOENDE</div>
            <div className="web-btn flex h50" style={ this.sel_style("pco") } onClick={() => this.sel_typ("pco")}>DELUTCHECKADE</div>
        </div>
        <div className="divider_green"></div>

        <div className="web-row flex">
        <div className="web-col flex" style={{padding: "0px"}}>

        <div id="gstTable">
          <table className="table table-bordered table-hover" style={{marginBottom: "0px"}}>
            <thead>
            <tr>
                <th>Arrival</th>
                <th>Departure</th>
                <th>Resno</th>
                <th>Company</th>
                <th>Name</th>
                <th>Roomnr</th>
                <th>Balance</th>
                <th>Betalt</th>
                <th>Topay</th>
                <th></th>
            </tr>
            </thead>
            </table>
            <div style={ this.tb_style() }>
          <table className="table table-bordered table-hover">
            { this.state.rva.map((row, key) =>
           <tbody>
            <tr key={key} style={ this.row_style(row) } onDoubleClick={() => this.move_row(row)}>
                <td>{ utils.toDate( row.gsb00.r0ankdatum ) }</td>
                <td>{ utils.toDate( row.gsb00.r0avrdatum ) }</td>
                <td>{ row.gsb00.r0boknr }</td>
                <td>{ row.resv00.Company }</td>
                <td>{ row.gsb00.r0namn }</td>
                <td>{ utils.toRumsnr( row.gsb00.r0rumsnr ) }</td>
                <td className="text-right">{ utils.toPris( row.charges.saldo ) }</td>
                <td className="text-right">{ utils.toPris( row.charges.payments ) }</td>
                <td className="text-right">{ utils.toPris( row.charges.topay ) }</td>
                <td>
                    <Td_Sel items={ this.selitems } getValue={(e)=> this.sel_row_fkn(row, e)} />
                </td>
            </tr>
            </tbody>
            )}
        </table>
        </div>
        </div>

        </div>
        </div>

        </div>
        <div className="mx_foot flex-row">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex"></div>
            <button className="btn btn-hw-green w250" onClick={(event) => this.sel_split() } >
                VÄLJ GÄST
            </button>
        </div>
      </div>
            { this.state.wnd_rvo ?
        <Pms_Rvo close={this.rvo_close} boknr={this.state.boknr} ctx={this} store={this.props.store} backdrop={true}/>
            : null }
    </div>
	;
	return html;
  };
}

export default Move_To_Gst;