import React, { useState, useEffect, useContext } from 'react';
import { ChromePicker } from 'react-color';

import * as net from "../../../lib/net";
import Fr_Input from "../../../lib/ui/fr_input";
import Fr_Select from "../../../lib/ui/fr_select";
import ddo from "../../../ddo";

class Btn_New extends React.Component {
  constructor(props, context) {
    super(props);

    this.pos = props.store.pos;
    this.state = {};
    this.state.mka = [];
    this.state.sval = [];
    this.state.tval = [];
    this.state.paa = [];
    this.state.uaa = [];
    this.state.laa = [];

    this.state.gnr = "001";
    this.state.gbo = {};
    //this.state.lro = props.lro;
    this.state.ynq = [
                {id: '0', text: 'NEJ'},
                {id: '1', text: 'JA'}
            ];
    this.state.fargtema = [
                {id: '00', text: 'EGET VAL'},
                {id: '01', text: 'MÖRKRÖD / VIT'},
                {id: '02', text: 'MÖRKBLÅ / VIT'},
                {id: '03', text: 'MÖRKGRÖN / VIT'},
                {id: '04', text: 'ORANGE / VIT'},
                {id: '05', text: 'SVART / VIT'},
                {id: '06', text: 'GUL / SVART'},
                {id: '07', text: 'VIT / SVART'},
                {id: '08', text: 'LJUSRÖD / VIT'},
                {id: '09', text: 'LJUSBLÅ / VIT'},
                {id: '10', text: 'LJUSGRÖN / VIT'}
            ];

    this.state.bgo = {};
    this.state.bto = {};
    this.state.background = "#fff";

    this.state.cols = {};
    this.state.lrwrk = false;
    //this.state.pbo = {};
    //this.state.pbo = props.pbo;
    this.state.displayColorPicker = false;
    if(props.wnds.bto) this.state.bto = props.wnds.bto;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //alert(JSON.stringify(this.state.bto));
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    tab_style(tabid) {
      var css = {};
      css.display = "none";
      if(this.state.tabval === tabid) css.display = "block";
      return css;
    }
    tab_head(tabid) {
      var css = {};
      css.background = "#fff";
      css.color = "#000";
      if(this.state.tabval === tabid) {
          css.background = "#080";
          css.color = "#fff";
      }
      return css;
    }
    grp_style(row) {
      var css = {};
      css.background = "#008";
      if(this.state.gnr === row.vgrupp) css.background = "#080";
      return css;
    }

    formchange (e) {
      const name = e.target.name;
      const value = e.target.value;
      var pbo = this.state.pbo;
      var na = name.split(".");
      pbo[na[0]][na[1]] = value;
      this.setState({pbo: pbo});
      //setState({[name]: value});
    }

    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    wnd_close() {
		this.props.ctx.setState({ wnds: {} });
	};
    handleChange = (event, newValue) => {
        //alert(newValue);
        this.setState({ix: newValue});
      };
    toggle() {
      this.setState({modal: false});
	};

  submitHandler = event => {
    event.preventDefault();
    event.target.className += " was-validated";
  };

  changeHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  pad(n, width, z) {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
  }

  cdi_iscolor(rgb) {
    var brc = false;
    var pre = parseInt( rgb.substring(0,2) );
    if(pre == 11) brc = true;
    return brc;
  }
  cdi_rgb(rgb) {
    var r = parseInt( rgb.substring(2,5) ).toString(16);
    var g = parseInt( rgb.substring(5,8) ).toString(16);
    var b = parseInt( rgb.substring(8,11) ).toString(16);
    var hex = "#" + this.pad(r, 2) + this.pad(g, 2) + this.pad(b, 2);
    return hex;
  }
	cdi_hex(rgb) {
		var r = parseInt( rgb.substring(2,5) ).toString(16);
		var g = parseInt( rgb.substring(5,8) ).toString(16);
		var b = parseInt( rgb.substring(8,11) ).toString(16);
		var hex = this.pad(r, 2) + this.pad(g, 2) + this.pad(b, 2);
		return hex;
	}
	hex_cdi(hex) {
		if(hex.length == 4) hex = hex + hex.substr(1,3);
		var r = parseInt( hex.substr(1,2), 16 ).toString();
		var g = parseInt( hex.substr(3,2), 16 ).toString();
		var b = parseInt( hex.substr(5,2), 16 ).toString();
		var rgb = "11" + this.pad(r, 3) + this.pad(g, 3) + this.pad(b, 3);
		return rgb;
	}
    tstbtn_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
        css.width = "250px";
        css.height = "50px";
        css.lineHeight = "50px";
        css.border = "1px solid #000";
        css.background = "#fff";
        css.color = "#000";
        var bto = this.state.bto;
        if(this.cdi_iscolor(bto.f0kod)) {
            css.background = this.cdi_rgb(bto.f0kod);
        }
        if(this.cdi_iscolor(bto.f0bkod)) {
            css.color = this.cdi_rgb(bto.f0bkod);
        }
      return css;
    }

    /*
                {id: '00', text: 'EGET VAL'},
                {id: '01', text: 'MÖRKRÖD / VIT'},
                {id: '02', text: 'MÖRKBLÅ / VIT'},
                {id: '03', text: 'MÖRKGRÖN / VIT'},
                {id: '04', text: 'ORANGE / VIT'},
                {id: '05', text: 'SVART / VIT'},
                {id: '06', text: 'GUL / SVART'},
                {id: '07', text: 'VIT / SVART'},
                {id: '08', text: 'LJUSRÖD / VIT'},
                {id: '09', text: 'LJUSBLÅ / VIT'},
                {id: '10', text: 'LJUSGRÖN / VIT'}
    */
  val_tema(val) {
      var css = {};
      //alert(JSON.stringify(val))
      var farg = "TEMA00";
      var fg = "11255255255";
      var bg = "11000000128";
      if(val == "01") {
          farg = "TEMA01";
          fg = "11255255255";
          bg = "11128000000";
      }
      if(val == "02") {
          farg = "TEMA02";
          fg = "11255255255";
          bg = "11000000128";
      }
      if(val == "03") {
          farg = "TEMA03";
          fg = "11255255255";
          bg = "11000128000";
      }
      if(val == "04") {
          farg = "TEMA04";
          fg = "11255255255";
          bg = "11255128000";
      }
      if(val == "05") {
          farg = "TEMA05";
          fg = "11255255255";
          bg = "11000000000";
      }
      if(val == "06") {
          farg = "TEMA06";
          fg = "11000000000";
          bg = "11255255000";
      }
      if(val == "07") {
          farg = "TEMA07";
          fg = "11000000000";
          bg = "11255255255";
      }
      if(val == "08") {
          farg = "TEMA08";
          fg = "11255255255";
          bg = "11255000000";
      }
      if(val == "09") {
          farg = "TEMA09";
          fg = "11255255255";
          bg = "11000000255";
      }
      if(val == "10") {
          farg = "TEMA10";
          fg = "11255255255";
          bg = "11000255000";
      }
      var bto = this.state.bto;
      bto.f0farg = farg;
      bto.f0kod = bg;
      bto.f0bkod = fg;
      this.setState({bto: bto});
      return;
  }
  val_color(id, val) {
      //alert(JSON.stringify(val))
      //this.ctrl_fg.updateState(val.hex);

      var bto = this.state.bto;
      var ia = id.split(".");
      var key = ia[1];
      //bto[ia[0]][ia[1]] = val;
      bto[key] = this.hex_cdi(val.hex);
      bto.f0farg = "EGEN";
      this.setState({bto: bto});
      return;
  }
  val_save(id, val) {
      var bto = this.state.bto;
      var ia = id.split(".");
      var key = ia[1];
      //bto[ia[0]][ia[1]] = val;
      bto[key] = val;
      this.setState({bto: bto});
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  cssCols (cid) {
      var css = {};
      if(this.state.cols[cid]) css.width = this.state.cols[cid].toString() + "px";
      //if(cid == "c01") alert(JSON.stringify(this.state.cols[cid]));
      return css;
  }
  syncCols (tb) {

    if (!document.getElementById(tb)) return;

    var cols = {};
    var ix = document.getElementById(tb).rows.length - 1;
    var ic = document.getElementById(tb).rows[0].cells.length;
    var ci = 0;
    while(ci < ic) {
        var cxi = ci + 1;
        var col = "c" + cxi.toString().padStart(2, "0");
        cols[col] = document.getElementById(tb).rows[ix].cells[ci].clientWidth + 1;
        ci = ci + 1;
    }
    this.setState({cols: cols });
    //alert(JSON.stringify(this.state.cols));
    //this.forceUpdate();
  };
  btn_save() {
    var self = this;

    var prm = {};
    prm.req = "pos.pos_btns.btn_add";
    prm.fam00 = self.state.bto;
    //alert(JSON.stringify(prm.fam00));

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));
  		self.props.ctx.btn_saved(self.props.ctx, self.state.bto);
  })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  plu_save() {
    var self = this;

    var prm = {};
    prm.req = "pos.pos_btns.famgrps";
    prm.avd = ddo.cache.avd;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

    colorClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker })
    };

  colorClose = () => {
    this.setState({ displayColorPicker: false })
  };
  render() {
    //if(!this.props.show) return "";

    //alert(this.props.ctx.state.lro);

    var title = this.props.title;
    if(!title) title = "Knappar";
    //if(props.kro) alert(props.kro.krr00.kid);
    const popover = {
      position: 'absolute',
      top: '75px',
      left: '400px',
      zIndex: '2',
    }
    const cover = {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    }

	var html =
        <div className="mx_base">
        <div className="m2_backdrop" />
        <div className="m2_dlg mx_70 flex-col" style={ this.dlg_style() } draggable="true" >
        <div className="flex-row " style={{padding:"0px",height:"50px",lineHeight:"50px", background: "#B388FF", color: "#fff"}}>
                <div className="flex-row flex" style={{padding:"0px 5px", fontSize: "22px"}}>
                    <div>NY KNAPP { this.state.rumsnr }</div>
                </div>
            <div className="wnd-close-btn" onClick={() => this.wnd_close() }>X</div>
        </div>
        <div className="mx_content flex">

            <div className="flex-row" style={{padding:"5px",height:"35px"}}>
                    <div className="flex-col flex">
                        <div>NY KNAPP:</div>
                    </div>
                    <div className="flex-col">
                        <div>UPPDATERA</div>
                    </div>
            </div>
            <div className="divider_orange"></div>

            <div className="flex-row px-5">
                <div className="">Avdelning: { this.state.bto.f0avd }</div>
                <div className="flex"></div>
                <div className="">Knapp grupp: { this.state.bto.f0grupp }</div>
                <div className="flex"></div>
                <div className="">Knapp: { this.state.bto.f0knapp }</div>
            </div>

            <div className="divider_orange"></div>
            <form className="cdi_f03">
            <div className="flex-row">
                    <Fr_Input label="Knapp grupp" className="text-left w150" text={this.state.bto.f0grupp} getValue={(e)=> this.val_save("bto.f0grupp", e) } />
                    <Fr_Input label="Knapp" className="text-left w150" text={this.state.bto.f0knapp} getValue={(e)=> this.val_save("bto.f0knapp", e) } />
            </div>
            <div className="flex-row">
                    <Fr_Input label="Knapp Text" className="text-left w350" text={this.state.bto.f0text} getValue={(e)=> this.val_save("bto.f0text", e) } />
            </div>
            <div className="divider_green"></div>
            <div className="flex-row">
                    <Fr_Input label="PLU" className="text-left w150" text={this.state.bto.f0plu} getValue={(e)=> this.val_save("bto.f0plu", e) } />
                    <Fr_Input label="Plu Pris" className="text-left w250" text={this.state.bto.f0pris} getValue={(e)=> this.val_save("bto.f0pris", e) } />
            </div>
            <div className="flex-row">
                    <Fr_Input label="Plu Text" readonly className="text-left w350" text={this.state.bto.f0plutext} getValue={(e)=> this.val_save("bto.f0plutext", e) } />
            </div>
            <div className="divider_green"></div>

            <div className="flex-row">
                <div className="flex-col flex">
                <Fr_Input label="Tema namn" className="text-left w250" text={this.state.bto.f0farg} />
                <Fr_Select label="Färg tema" className="text-left w250" text={this.state.bto.ftema} items={this.state.fargtema} getValue={(e)=> this.val_tema(e) } />
               <div className="flex flex-row">
                    <Fr_Input label="Text färg" className="text-left w250" text={this.state.bto.f0bkod} getValue={(e)=> this.val_save("bto.f0bkod", e) } />
                    <div className="link_14 flex" onClick={ () => this.colorClick("bto.f0bkod") }>VAL</div>
                </div>
                <div className="flex flex-row">
                    <Fr_Input label="Bakgrundsfärg" className="text-left w250" text={this.state.bto.f0kod} getValue={(e)=> this.val_save("bto.f0kod", e) } />
                    <div className="link_14 flex" onClick={ () => this.colorClick("bto.f0kod") }>VAL</div>
                </div>
                </div>
                <div className="flex-row flex flex-cc">
                    <div style={ this.tstbtn_style() }>TEST KNAPP</div>
                </div>
            </div>

            <div className="divider_green"></div>
            <div className="flex-row">
                    <Fr_Input label="Betalkod" className="text-left w250" text={this.state.bto.p0namn} getValue={(e)=> this.val_save("bto.p0namn", e) } />
                    <Fr_Input label="Sortering" className="text-left w250" text={this.state.bto.p0namn} getValue={(e)=> this.val_save("bto.p0namn", e) } />
            </div>

            </form>

            { this.state.displayColorPicker ? <div style={ popover }>
                <div style={ cover } onClick={ this.colorClose }/>
                <ChromePicker color={ this.state.background } onChangeComplete={(e)=> this.val_color(this.state.colorid, e) } />
                </div>
            : null }

        </div>
        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250 ripple" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex-row">
            <button className="btn btn-hw-red w250 ripple" onClick={() => this.plu_save() } >
                SPARA PLU
            </button>
            <button className="btn btn-hw-red w250 ripple" onClick={() => this.btn_save() } >
                SPARA
            </button>
            </div>
        </div>
      </div>
      </div>
	;
	return html;
  };
}

export default Btn_New;