import React, { useState, useEffect, useContext } from 'react';
import * as net from "../../../lib/net";

import Pms_Rvo from "../../rv/fb/rvo";
import Pms_Gso from "../../rv/gst/gso";
import Pms_Nota from "../nota/nota";
import Pms_Keys from "./keys";
import Pms_Unci from "./unci";
import Pms_Logg from "../fkn/logg";
import Pms_Msg from "../fkn/gst_msg";
import Pms_Ch_Room from "../fkn/gst_ch_room";
import Pms_Ch_Stay from "../fkn/gst_ch_stay";
import Pms_Ch_Info from "../fkn/gst_ch_info";

class Pms_GstFkn extends React.Component {
  constructor(props, context) {
    super(props);

    //alert('PBO CTX: ' + JSON.stringify(props.pbo));
   //this.webStore = props.store;
    this.state = {};
    this.state.ynq = [];
    //this.state.vga = [];
    this.state.lso = {};
    //this.state.lro = props.lro;
    this.state.paa = [];
    this.state.aa = [];
    this.state.boknr = "";
    if(props.boknr) this.state.boknr = props.boknr;
    this.state.rvo = {};
    if(props.rvo) this.state.rvo = props.rvo;
    if(props.wnds) {
        this.state.boknr = props.wnds.boknr;
        this.state.rvo = props.wnds.rvo;
    }
    this.state.wnds = {};
    this.state.wnd_rvo = false;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }


    wnd_close() {
		this.props.ctx.setState({ wnds: {} });
	};
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  cssCols (cid) {
      var css = {};
      if(this.state.cols[cid]) css.width = this.state.cols[cid].toString() + "px";
      //if(cid == "c01") alert(JSON.stringify(this.state.cols[cid]));
      return css;
  }
  fknSel(sel) {
    //alert(JSON.stringify(fkn));
      if(sel == "rvo") {
          var wnds = {};
          wnds.rvo = true;
          wnds.boknr = this.state.boknr;
          wnds.rvo = this.state.rvo;
        this.setState({wnds: wnds});
      }
      if(sel == "unci") {
          var wnds = {};
          wnds.unci = true;
          wnds.boknr = this.state.boknr;
          wnds.rvo = this.state.rvo;
          wnds.cb = this.wnds_close;
          this.setState({wnds: wnds});
      }
      if(sel == "chroom") {
          var wnds = {};
          wnds.chroom = true;
          wnds.boknr = this.state.boknr;
          wnds.rvo = this.state.rvo;
          wnds.cb = this.wnds_close;
          this.setState({wnds: wnds});
      }
      if(sel == "chstay") {
          var wnds = {};
          wnds.chstay = true;
          wnds.boknr = this.state.boknr;
          wnds.rvo = this.state.rvo;
          wnds.cb = this.wnds_close;
          this.setState({wnds: wnds});
      }
      if(sel == "chinfo") {
          var wnds = {};
          wnds.chinfo = true;
          wnds.boknr = this.state.boknr;
          wnds.rvo = this.state.rvo;
          wnds.cb = this.wnds_close;
          this.setState({wnds: wnds});
      }
      if(sel == "msg") {
          var wnds = {};
          wnds.msg = true;
          wnds.boknr = this.state.boknr;
          wnds.rvo = this.state.rvo;
          wnds.cb = this.wnds_close;
          this.setState({wnds: wnds});
      }
  }
    wnds_close(ctx) {
        ctx.setState({ wnds: {} });
    };
    rvo_close(ctx) {
        ctx.setState({ wnds: {} });
    };
    gst_close(ctx) {
        ctx.setState({ wnds: {} });
    };
  room_tv() {
    var self = this;

    var prm = {};
    prm.req = "pms.hk.room_tv";
    prm.rumsnr = "0205";
    prm.namn = "Teste Testsson";
    //alert(JSON.stringify(prm));

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok == "000") {
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
    return;
  }
  room_msg() {
    var self = this;

    var prm = {};
    prm.req = "pms.hk.room_tv";
    prm.rumsnr = "0205";
    prm.namn = "Teste Testsson";
    //alert(JSON.stringify(prm));

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok == "000") {
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
    return;
  }

  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "";

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="m2_backdrop" /> : null }
        <div className="m2_dlg mx_70 flex-col" style={ this.dlg_style() }>
            <div className="mx_h01 flex-row" style={{background: "#1a456b", color: "#fff"}}>
                    <div className="flex-col flex">
                        <div>GÄST FUNKTIONER</div>
                    </div>
                    <div className="flex-col">
                        <div>BOKNR: { this.state.boknr }</div>
                    </div>
            </div>
        <div className="mx_content flex">

             <div className="txt_box txt_bg_01 txt_22">
             <div className="flex-row">
                    <div className="flex-col flex">
                        <div className="txt_lbl">RUM:</div>
                        <div>{ this.state.rvo.gsb00.r0rumsnr.cdirumsnr() }</div>
                    </div>
                    <div className="flex-col flex">
                        <div className="txt_lbl">RUMSTYP:</div>
                        <div> { this.state.rvo.rblx0.r0typ }</div>
                    </div>
            </div>
             <div className="flex-row">
                    <div className="flex-col flex">
                        <div className="txt_lbl">PERIOD:</div>
                        <div>{ this.state.rvo.gsb00.r0ankdatum.cdidate() } - { this.state.rvo.gsb00.r0avrdatum.cdidate() }</div>
                    </div>
                    <div className="flex-col flex">
                        <div className="txt_lbl">GÄSTER:</div>
                        <div>{ this.state.rvo.rblx0.r0guests }</div>
                    </div>
            </div>

           </div>

            <div className="flex"></div>
            <div className="divider_orange"></div>
        <div className="web-row">
        <div className="web-col flex">
            <div className="wnd-btn" onClick={() => this.fknSel("unci") } >
                <div>ÅTERTAG INCHECKNING</div>
            </div>
            <div className="wnd-btn" onClick={() => this.wnd_close() } >
                <div>STÄNG</div>
                <div></div>
            </div>
        </div>
         <div className="web-col flex" style={{padding: "0px 10px"}}>
            <div className="wnd-btn" onClick={() => this.fknSel("chroom") } >
                <div>BYT RUM</div>
                <div></div>
            </div>
            <div className="wnd-btn" onClick={() => this.fknSel("chstay") } >
                <div>FÖRÄNDRA VISTELSE</div>
                <div></div>
            </div>
       </div>
         <div className="web-col flex" style={{padding: "0px 10px"}}>
            <div className="wnd-btn" onClick={() => this.fknSel("wakeup") } >
                <div>VÄCKNING</div>
                <div></div>
            </div>
            <div className="wnd-btn" onClick={() => this.fknSel("msg") } >
                <div>MEDDELANDE</div>
                <div></div>
            </div>
       </div>
         <div className="web-col flex" style={{padding: "0px 10px"}}>
            <div className="wnd-btn" onClick={() => this.fknSel("chinfo") } >
                <div>ÄNDRA UPPGIFTER</div>
                <div></div>
            </div>
            <div className="wnd-btn" onClick={() => this.fknSel("logg") } >
                <div>LOGG</div>
                <div></div>
            </div>
       </div>
       </div>

        </div>
      </div>
            { this.state.wnds.rvo ?
        <Pms_Rvo close={this.rvo_close} wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
            : null }
            { this.state.wnds.unci ?
        <Pms_Unci close={this.gst_close} wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
            : null }
            { this.state.wnds.logg ?
                <Pms_Logg close={this.gst_close} wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
                : null }
            { this.state.wnds.msg ?
                <Pms_Msg wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
                : null }
            { this.state.wnds.chroom ?
                <Pms_Ch_Room wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
                : null }
            { this.state.wnds.chstay ?
                <Pms_Ch_Stay wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
                : null }
            { this.state.wnds.chinfo ?
                <Pms_Ch_Info wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
                : null }
    </div>
	;
	return html;
  };
}

export default Pms_GstFkn;