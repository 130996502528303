import React, { useState, useEffect, useContext } from 'react';
import {format} from "date-fns";

import * as net from "../lib/net";

import Dt_Cal from "../lib/ui/dt_cal";
import {Route, Routes} from "react-router-dom";
import Rps_Home from "./rps/home";
import Rps_Cas from "./rps/cas";
import Rps_Cr from "./rps/cr";
import Rps_Dr from "./rps/dr";
import Rps_Mr from "./rps/mr";
import ddo from "../ddo";
import {withRouter} from "../lib/react/withRouter";
import sso from "../sso";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Cas_Rps extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, tra: [] };
    this.state.seldate = new Date();
    //this.state.unit = null;
    this.state.sdo = {};
    this.state.unitid = "00";
    this.state.unit = {};
      this.state.una = Object.values(ddo.cfg.units);
      this.state.uno = ddo.cfg.units;
    this.state.today = format(new Date(), "yyyy-MM-dd");
    this.state.datum = format(new Date(), "yyyy-MM-dd");
      var po = {};
      po.cas = { "fid": "f06", "url": "/cas/rps/cas", "name": "Kasino rapport", "active": false };
      po.cr = { "fid": "f06", "url": "/cas/rps/cr", "name": "Kassarapport", "active": false };
      po.dr = { "fid": "f06", "url": "/cas/rps/dr", "name": "Dagrapport", "active": false };
      po.mr = { "fid": "f06", "url": "/cas/rps/mr", "name": "Månadsrapport", "active": false };
      this.state.po = po;
      this.state.pa = [];
      this.state.pa = Object.values(po);
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

    fknGo(row) {
        sso.seo.sfkn = row;
        this.props.navigate(row.url);
    }
    br_css(row) {
        var css = {};
        if(sso.seo.sfkn && (row.url == sso.seo.sfkn.url)) css.color = "#080";
        //var brc = false;
        //if(row.url == sbo.state.url) brc = true;
        return css;
    }

  render() {
    return (
        <div className="web-app">
            { this.props.store.cfg.bread ?
                <div className="web-bread">
                    <div className="breadcrumb">
                        { this.state.pa.map((row, key) =>
                            <div key={key} className="breadcrumb-item" style={this.br_css(row)} onClick={() => this.fknGo(row)}>{row.name}</div>
                        )}
                        <div className="flex"></div>
                        <div className="cdigreen">{ sso.seo.sfkn ? sso.seo.sfkn.name : null }</div>
                    </div>
                </div>
                : null }
        <Routes>
            <Route path="cas" element={ <Rps_Cas store={this.webStore} />} />
            <Route path="cr" element={ <Rps_Cr store={this.webStore} />} />
            <Route path="dr" element={ <Rps_Dr store={this.webStore} />} />
            <Route path="mr" element={ <Rps_Mr store={this.webStore} />} />
            <Route path="*" element={ <Rps_Home store={this.webStore} />} />
        </Routes>
    </div>
  );
    }
}
export default withRouter(Cas_Rps);
