import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../../../lib/net";
import Wnd_Ok from "../../../../lib/ui/wnd_ok";

class Export_Format extends React.Component {
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.state = {};
    this.state.avd = "01";
    this.state.datum = "";
    this.state.msg = {};
    this.state.bqr = {};
    this.state.bqr.srows = {};
    if(props.wnds && props.wnds.datum) this.state.datum = props.wnds.datum;
   }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      //this.trs();
      //alert(JSON.stringify(this.props.wnds.row));
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    tab_style(tabid) {
      var css = {};
      css.display = "none";
      if(this.state.tabval === tabid) css.display = "block";
      return css;
    }
    bq_close() {
		this.props.ctx.setState({ wnds: {} });
	};
  trs() {
    var self = this;
    var prm = {};
    prm.req = "pos.pos_bq.trs";
    prm.avd = this.state.avd;
    //prm.fplu = datum;
    //prm.tplu = datum;

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        self.setState({tra: ret.rca });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }
    export_json() {
        var self = this;

        this.setState({ loading: 1 });

    	var prm = {};
     	prm.req = "pos.pos_trs.trs_export_json";
     	prm.sid = "S0003101";
     	//prm.sid = "S0000091";
   		prm.typ = "json";
   		prm.avd = this.state.avd;
   		prm.datum = this.state.datum;
   		prm.fdat = this.state.datum;
   		prm.tdat = this.state.datum;
   		prm.favd = this.state.avd;
   		prm.tavd = this.state.avd;

        net.sid_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret.ok));

            //var uri = "data:application/pdf;base64," + ret.pdf;
            //self.setState({pdf: 1, pdf_content: ret.pdf });

            //alert(JSON.stringify(ret.rco));
            var jsonbuf = JSON.stringify(ret.rco);
            var file = new Blob([jsonbuf], {type: "application/json"});
			var fileURL = window.URL.createObjectURL(file);
			//$scope.fil_object = $sce.trustAsResourceUrl(fileURL);
			var a = document.createElement('a');
			a.href = fileURL;
			var filename = ret.filename;
			if(filename.length < 1) filename = "export.json";
			a.download = filename;
			document.body.appendChild(a);
			a.click();
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
            //gda.wndError(txt);
        });
	};

    export_xl() {
        var self = this;

        this.setState({ loading: 1 });

    	var prm = {};
     	prm.req = "pos.pos_trs.trs_export_xl";
     	prm.sid = "S0003101";
     	//prm.sid = "S0000091";
   		prm.typ = "xl";
   		prm.avd = this.state.avd;
   		prm.datum = this.state.datum;
   		prm.fdat = this.state.datum;
   		prm.tdat = this.state.datum;
   		prm.favd = this.state.avd;
   		prm.tavd = this.state.avd;

        net.sid_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret.ok));

            //var uri = "data:application/pdf;base64," + ret.pdf;
            //self.setState({pdf: 1, pdf_content: ret.pdf });

 	  		//var rawbuf = ret.buf;
 	  		var rawbuf = ret.data;
			var file = new Blob([(rawbuf)], {type: 'application/octet-stream'});
			var fileURL = window.URL.createObjectURL(file);
			//$scope.fil_object = $sce.trustAsResourceUrl(fileURL);
			var a = document.createElement('a');
			a.href = fileURL;
			var filename = ret.filename;
			if(filename.length < 1) filename = "export.xlsx";
			a.download = filename;
			document.body.appendChild(a);
			a.click();
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
            //gda.wndError(txt);
        });
	};

    export_raw() {
	};

  render() {
    var title = this.props.title;
    if(!title) title = "Titel";

	var html =
        <div className="mx_base">
        { this.props.wnds.backdrop ? <div className="mx_backdrop" /> : null }
        <div className="m2_dlg mx_70 flex-col" style={ this.dlg_style() }>
        <div className="mx_content flex">

            <div className="flex-row txt_18" style={{padding:"5px",height:"35px"}}>
                    <div className="flex-col flex">
                        <div>EXPORT FORMAT</div>
                    </div>
                    <div className="flex-col">
                        <div>UPPDATERA</div>
                    </div>
            </div>
            <div className="divider_orange"></div>
            <div className="px-5">

            <div className="flex-row txt_18" onClick={() => this.export_json() }>
                <div className="w300">Datum:</div>
                <div className="flext">{this.state.datum}</div>
            </div>
            <div className="divider_green"></div>
            <div className="web-btn txt_18" onClick={() => this.export_json() }>
                <div className="w300">JSON Format</div>
                <div className="flext"></div>
            </div>
            <div className="web-btn txt_18" onClick={() => this.export_xl() }>
                <div className="w300">Excel Format</div>
                <div className="flext"></div>
            </div>
            <div className="web-btn txt_18 hide" onClick={() => this.export_raw() }>
                <div className="w300">Fast längd Format</div>
                <div className="flext"></div>
            </div>

            <div className="divider_green"></div>
        </div>
        </div>

        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.bq_close() } >
                STÄNG
            </button>
            <div className="flex-row">
            </div>
            <button className="btn btn-hw-green w250 hide" onClick={(event) => this.export(event) } >
                EXPORTERA
            </button>
        </div>
      </div>
        <Wnd_Ok msg={this.state.msg} ctx={this} />
    </div>
	;
	return html;
  };
}

export default Export_Format;

/*
    { Object.values(this.state.bqr.srows).map((srow, skey) =>
        <div key={skey} className="">
            <div className="flex-row flex-sb txt_14">
                <div className="">{ this.stol_text(srow) }</div>
            </div>
        { srow.rows.map((rrs00, rkey) =>
            <div className="bq-row"  style={ this.bongrow_style(rrs00) } onClick={() => this.row_bong(rrs00) } onContextMenu={(e) => this.row_stol(rrs00, e) }>{ rrs00.r0radnr } : { rrs00.r0etyp } - { rrs00.r0text.toswe() } - { rrs00.r0perprice.cdipris() }</div>
        )}
        </div>
    )}
*/
