import React, { useState, useEffect, useContext } from 'react';
import {Route, Routes} from "react-router-dom";
import {withRouter} from "../lib/react/withRouter";

import Stat_Home from './stat/home.js';
import Stat_Tot from './stat/tot.js';
import Stat_Split from './stat/split.js';
import Stat_Typ from './stat/typ.js';
import Stat_Kortsaldo from './stat/kortsaldo.js';
import Stat_Saldo from './stat/saldo.js';
import sso from "../sso";

function pg_home() {

    var html =
    <div className="web-content">
      Hem
    </div>
    ;
    return html;
}

class Vip_Stat extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, deps: [] };
      var po = {};
      po.tot = { "fid": "f06", "url": "/vip/stat/tot", "name": "Totalt", "active": false };
      po.split = { "fid": "f06", "url": "/vip/stat/split", "name": "Fördelning", "active": false };
      po.typ = { "fid": "f06", "url": "/vip/stat/typ", "name": "Korttyp", "active": false };
      po.kortsaldo = { "fid": "f06", "url": "/vip/stat/kortsaldo", "name": "Kortsaldo", "active": false };
      po.saldo = { "fid": "f06", "url": "/vip/stat/saldo", "name": "Saldo", "active": false };
      this.state.po = po;
      this.state.pa = [];
      this.state.pa = Object.values(po);
  }
  fknGo(row) {
    sso.seo.sfkn = row;
    this.props.navigate(row.url);
  }
  br_css(row) {
    var css = {};
    if(sso.seo.sfkn && (row.url == sso.seo.sfkn.url)) css.color = "#080";
    //var brc = false;
    //if(row.url == sbo.state.url) brc = true;
    return css;
  }

  render() {
    return (
        <div className="web-app">
            { this.props.store.cfg.bread ?
                <div className="web-bread">
                    <div className="breadcrumb">
                        { this.state.pa.map((row, key) =>
                            <div key={key} className="breadcrumb-item" style={this.br_css(row)} onClick={() => this.fknGo(row)}>{row.name}</div>
                        )}
                        <div className="flex"></div>
                        <div className="cdigreen">{ sso.seo.sfkn ? sso.seo.sfkn.name : null }</div>
                    </div>
                </div>
                : null }
        <Routes>
          <Route path="/xx" component={pg_home} />
            <Route path="tot" element={ <Stat_Tot store={this.webStore} />} />
            <Route path="split" element={ <Stat_Split store={this.webStore} />} />
            <Route path="typ" element={ <Stat_Typ store={this.webStore} />} />
            <Route path="kortsaldo" element={ <Stat_Kortsaldo store={this.webStore} />} />
            <Route path="saldo" element={ <Stat_Saldo store={this.webStore} />} />
            <Route path="*" element={ <Stat_Home store={this.webStore} />} />
        </Routes>
    </div>
  );
}
}

export default withRouter(Vip_Stat);
