import React, { useState, useEffect, useContext } from 'react';
import {Route, Routes} from "react-router-dom";

import Mbr_Home from './mbr/home.js';
import Mbr_Mreg from './mbr/mreg.js';
import Mbr_Mship from './mbr/mship.js';
import Mbr_Eko from './mbr/eko.js';
import Mbr_Logg from './mbr/logg.js';
import Mbr_Stat from './mbr/stat.js';
import {withRouter} from "../lib/react/withRouter";
import sso from "../sso";

function pg_home() {

    var html =
        <div className="web-content">
            Hem
        </div>
    ;
    return html;
}

class Gym_Mbr extends React.Component {
    //var sdo = useContext(SDO);
    constructor(props, context) {
        super(props);

        this.webStore = props.store;
        this.state = { pgid: 11, deps: [] };
        var po = {};
        po.mreg = { "fid": "f06", "url": "/gym/mbr/mreg", "name": "Register", "active": false };
        po.mship = { "fid": "f06", "url": "/gym/mbr/mship", "name": "Medlemskap", "active": false };
        po.eko = { "fid": "f06", "url": "/gym/mbr/eko", "name": "Ekonomi", "active": false };
        po.logg = { "fid": "f06", "url": "/gym/mbr/logg", "name": "Logg", "active": false };
        po.stat = { "fid": "f06", "url": "/gym/mbr/stat", "name": "Statistik", "active": false };
        this.state.po = po;
        this.state.pa = [];
        this.state.pa = Object.values(po);
    }

    xxn(row) {
        var html = "";
        if(row.active) html = <li className="">{row.text}</li>;
        else html = <li className=""><a href={row.href}>{row.text}</a></li>;
        return html;
    }
    fknGo(row) {
        //alert(row.href);
        sso.seo.sfkn = row;
        this.props.navigate(row.url);
    }
    br_css(row) {
        var css = {};
        if(sso.seo.sfkn && (row.url == sso.seo.sfkn.url)) css.color = "#080";
        //var brc = false;
        //if(row.url == sbo.state.url) brc = true;
        return css;
    }

    render() {

        return (
            <div className="web-app">
                { this.props.store.cfg.bread ?
                    <div className="web-bread">
                        <div className="breadcrumb">
                            { this.state.pa.map((row, key) =>
                                <div key={key} className="breadcrumb-item" style={this.br_css(row)} onClick={() => this.fknGo(row)}>{row.name}</div>
                            )}
                            <div className="flex"></div>
                            <div className="cdigreen">{ sso.seo.sfkn ? sso.seo.sfkn.name : null }</div>
                        </div>
                    </div>
                    : null }
                <Routes>
                    <Route path="/xx" component={pg_home} />
                    <Route path="mreg" element={ <Mbr_Mreg store={this.webStore} />} />
                    <Route path="mship" element={ <Mbr_Mship store={this.webStore} />} />
                    <Route path="eko" element={ <Mbr_Eko store={this.webStore} />} />
                    <Route path="logg" element={ <Mbr_Logg store={this.webStore} />} />
                    <Route path="stat" element={ <Mbr_Stat store={this.webStore} />} />
                    <Route path="*" element={ <Mbr_Home store={this.webStore} />} />
                </Routes>
            </div>

        );
    }
}
//Sys.contextType = ADOContext;

export default withRouter(Gym_Mbr);
