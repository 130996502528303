import React, { useState, useEffect, useContext } from 'react';

import {format} from "date-fns";

import * as net from "../../../lib/net";
import Dt_Cal from "../../../lib/ui/dt_cal";
import Rr_Help from "./help";
import Rr_Roomfkn from "./roomfkn";
import Rr_Clean from "./clean";
import Pms_Rooms from "./rooms";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import sso from "../../../sso";

class Pms_Rr extends React.Component {
  constructor(props, context) {
    super(props);

    this.state = {};
    this.state.ynq = [];

    this.dt_dt = React.createRef();

    this.state.wnds = {};
    this.state.lrwrk = false;
    this.state.ix = 0;
      this.state.rra = [];
      this.state.flo = {};

    this.state.today = new Date();
    var hh = format(this.state.today, "HH");
    if(hh < 5) this.state.today.setDate(this.state.today.getDate() - 1);
    this.state.tday = format(this.state.today, "yyyy-MM-dd");
    this.state.dts = format(this.state.today, "yyyy-MM-dd");
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.rr();
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
  cls_rr(row) {
      var cls = "";
      cls = cls + "flex col";
      if(row.nrg > '0') cls = cls + " rr_inh";
      else if(row.status == 'C') cls = cls + " rr_close";
      else if(row.closed == '1') cls = cls + " rr_close";
      else cls = cls + " rr_free";
      return cls;
  }
  cls_clean(row) {
      var cls = "";
      cls = cls + "";
      if(row.cleaned == '0') cls = cls + " rr_clean";
      return cls;
  }
  cls_ank(row) {
      var cls = "";
      cls = cls + "";
      if(row.ankg > '0') cls = cls + " rr_ank";
      return cls;
  }
  cls_avr(row) {
      var cls = "";
      cls = cls + "";
      if(row.avrg > '0') cls = cls + " rr_avr";
      return cls;
  }
  dd_close(ctx, ret) {
      if(ret.ok == "000") {
        ctx.rr();
      }
    ctx.setState({ wnds: {} });
  }
    wnd_close = () => {
      sso.wnds.rr = 0;
        this.props.ctx.setState({ wnds: {} });
    };
    rrp = () => {
        var wnds = {};
        wnds.rrp = 1;
        this.props.ctx.setState({ wnds: wnds });
    };
  rr() {
    var self = this;
    var prm = {};
    prm.req = "pms.rr.rr_dt";
    if(this.state.dts == this.state.tday) prm.req = "pms.rr.rrq";
    prm.datum = this.state.dts;
    //prm.avd = this.state.avd;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        var rra = [];
        var keys = Object.keys(ret.rco);
        for(var key of keys) {
            var rro = ret.rco[key];
            //alert(JSON.stringify(key));

            rra.push( rro );
        };
        self.setState({rra: rra, flo: ret.flo, loading: false });
    })
    .catch(function(e) {
        var txt = JSON.stringify(e);
    });
  }
  rr_status() {
    var self = this;
    var prm = {};
    prm.req = "pms.rr.rrq";
    prm.datum = this.state.dts;
    prm.status = this.state.status;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        var rra = [];
        var keys = Object.keys(ret.rco);
        for(var key of keys) {
            var rro = ret.rco[key];
            //alert(JSON.stringify(key));

            rra.push( rro );
        };
        self.setState({rra: rra, loading: false });
    })
    .catch(function(e) {
        var txt = JSON.stringify(e);
    });
  }
  help() {
    var self = this;
    var wnds = {};
    wnds.help = "1";
    self.setState({wnds: wnds });
  }
  rooms() {
    var self = this;
    var wnds = {};
    wnds.rstatus = "1";
    wnds.rra = this.state.rra;
    self.setState({wnds: wnds });
  }
  rr_roomfkn(row) {
    var self = this;
    var wnds = {};
    wnds.roomfkn = 1;
    wnds.rro = row;
    self.setState({ wnds: wnds });
  }
  rr_clean(row) {
    var self = this;
    var wnds = {};
    wnds.clean = 1;
    wnds.rro = row;
    self.setState({ wnds: wnds });
  }
  xxrr_clean(row, status) {
    var self = this;
    var prm = {};
    prm.req = "pms.rr.rr_clean";
    prm.gsb01 = row.gsb01;
    prm.status = status;

    net.sio_req(prm)
    .then(function(ret){
        alert(JSON.stringify(ret));
    })
    .catch(function(e) {
        var txt = JSON.stringify(e);
    });
  }
  dt_dat(e) {
    //alert(JSON.stringify(e));
    var dts = format(e, "yyyy-MM-dd");
    this.setState({ dts: dts });
  }
  addday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_dt.current.updateAdd();
  }
  subday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_dt.current.updateSub();
  }

  render() {
    //if(!this.props.show) return "";

    //alert(this.props.ctx.state.lro);

    var title = this.props.title;
    if(!title) title = "Knappar";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="mx_backdrop" /> : null }
        <div className="mx_fb flex-col" style={ this.dlg_style() }>
        <div className="mx_h01">
            <div>ROOOMRACK</div>
        </div>
        <div className="mx_content flex">

    <div className="flex-row">
        <button className="btn btn-hw-blue w150" onClick={() => this.rooms() } aria-label="System" >
            RUMS HANTERING
        </button>
        <button className="btn btn-hw-blue w150" onClick={() => this.help() } aria-label="System" >
            HJÄLP
        </button>
        <div className="flex"></div>
        <div className="flex-row">
              <div className="dt-btn-01" onClick={() => this.subday() } ><FontAwesomeIcon icon="minus" size="1x" /></div>
              <Dt_Cal dts={this.state.dts} rp="1" ref={this.dt_dt} getValue={(e)=> this.dt_dat(e)} />
              <div className="dt-btn-01" onClick={() => this.addday() } ><FontAwesomeIcon icon="plus" size="1x" /></div>
        </div>
        <div className="flex"></div>
        <button className="btn btn-hw-blue w150" onClick={() => this.rr() } aria-label="System" >
            UPPDATERA
        </button>
    </div>
    <div className="divider_orange"></div>

    <div className="web-col">
        <div className="container">
            <div className="col">
            { Object.values(this.state.flo).map((flo, key) =>
            <div key={key} className="col">
                <div className="divider_green"></div>
                <div className="txt_18">Våning: { flo.floor }</div>
                <div className="row">
        { Object.values(flo.rro).map((row, key) =>
            <div key={key} className="rr_grp flex-row" onDoubleClick={() => this.rr_roomfkn(row) }>
                <div className="rr_pre flex-row">
                    <div className={this.cls_avr(row)}></div>
                </div>
                <div className={this.cls_rr(row)}>
                    <div className="rr_txt">{row.rumsnr}</div>
                    <div className="rr_txt">{row.rt}</div>
                </div>
                <div className="rr_post flex-row">
                    <div className={this.cls_ank(row)}></div>
                    <div className={this.cls_clean(row)}></div>
                </div>
            </div>
        )}
                </div>
            </div>
            )}
            </div>
        </div>
    </div>

        </div>
        <div className="mx_foot flex-row flex-space-between">
            <div className="flex-row flex">
                <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                    STÄNG
                </button>
            </div>
            <button className="btn btn-hw-blue w250" onClick={() => this.rrp() } aria-label="System" >
                ROOMRACK PERIOD
            </button>
            <div className="flex-row flex"></div>
        </div>
      </div>
            { this.state.wnds.help ?
        <Rr_Help wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
            : null }
            { this.state.wnds.roomfkn ?
        <Rr_Roomfkn close={this.dd_close} wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
            : null }
            { this.state.wnds.clean ?
        <Rr_Clean close={this.dd_close} wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
            : null }
    { this.state.wnds.rstatus ?
        <Pms_Rooms wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
    : null }
    </div>
	;
	return html;
  };
}

export default Pms_Rr;