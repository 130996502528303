import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";
import {withRouter} from "../../lib/react/withRouter";
import sso from "../../sso";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Pos_Lager extends React.Component {
    constructor(props, context) {
        super(props);

        this.webStore = props.store;
        this.state = {};
        this.state.seldate = new Date();
        this.state.avd = "01";
        this.state.paa = [];
        this.state.aa = [];

        this.state.mna = [];
        this.state.mna.push({ mnid: "mn01", pos: "c1", text: "LEVERANTÖRER", desc: "Leverantörs register", link: "/pos/lager/levs" });
        this.state.mna.push({ mnid: "mn01", pos: "c1", text: "AVDELNINGAR", desc: "Huvudlager och underlager", link: "/pos/lager/lags" });
        this.state.mna.push({ mnid: "mn02", pos: "c1", text: "ARTIKLAR", desc: "Artikel register", link: "/pos/lager/arts" });
        this.state.mna.push({ mnid: "mn03", pos: "c1", text: "INVENTERING", desc: "Inventerings funktioner", link: "/pos/lager/inv" });
        this.state.mna.push({ mnid: "mn04", pos: "c2", text: "DRIFT", desc: "Driftfunktioner", link: "/pos/lager/drift" });
        this.state.mna.push({ mnid: "mn05", pos: "c2", text: "LEVERANSER", desc: "Leveranshantering", link: "/pos/lager/lev" });
        this.state.mna.push({ mnid: "mn06", pos: "c2", text: "RESULTAT", desc: "Resultat drift/inventering", link: "/pos/lager/res" });
        this.state.mna.push({ mnid: "mn07", pos: "c2", text: "LISTOR", desc: "Lager listor", link: "/pos/lager/list" });
    }
    componentDidUpdate() {
    }
    componentWillUnmount() {
    }
    componentDidMount() {
    }

    mnc(pos) {
        var mna = [];
        for(var row of this.state.mna) {
            if(row.pos == pos) mna.push( row );
        }
        return mna;
    }
    fknGo(url) {
        //alert(row.href);
        var row = {};
        row.url = url;
        sso.seo.sfkn = row;
        this.props.navigate(url);
    }

    render() {
        return (
            <div className="web-app">

                <div className="web-pg py-4 flex">
                    <h3>Drift funktioner</h3>
                    <p>Diverse funktioner för drift</p>

                    <div className="divider_green"></div>

                    <div className="web-row">
                        <div className="flex-col flex">
                            { this.mnc("c1").map((row, key) =>
                                <div key={key} className="web-bx" onClick={() => this.fknGo(row.link) }>
                                    <div>{ row.text }</div>
                                    <div className="flex-row">
                                        <div className="flex">{ row.desc }</div>
                                        <div className="web-link">Välj</div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="flex-col flex">
                            { this.mnc("c2").map((row, key) =>
                                <div key={key} className="web-bx" onClick={() => this.fknGo(row.link) }>
                                    <div>{ row.text }</div>
                                    <div className="flex-row">
                                        <div className="flex">{ row.desc }</div>
                                        <div className="web-link">Välj</div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                </div>

            </div>
        );
    }
}
export default withRouter(Pos_Lager);
