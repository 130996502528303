import React, { useState, useEffect, useContext } from 'react';
import {format, addDays, subDays, differenceInCalendarDays} from "date-fns";

import * as net from "../../../lib/net";
import Fr_Input from "../../../lib/ui/fr_input";
import Fr_Select from "../../../lib/ui/fr_select";
import Dt_Cal from "../../../lib/ui/dt_cal";

import Rf_GstRooms from "./rf_rooms";
import Fr_Cal from "../../../lib/ui/fr_cal";

class Gst_Ch_Stay extends React.Component {
  constructor(props, context) {
    super(props);

    this.dt_fd = React.createRef();
    this.dt_td = React.createRef();

    this.store = props.store;
    this.pms = props.store.pms;

    this.state = {};
    this.state.today = new Date();
    this.state.rta = [];
    this.state.pka = [];

    this.state.gnr = "001";
    this.state.gbo = {};
    //this.state.lro = props.lro;
    this.state.ynq = [
                {id: '0', text: 'NEJ'},
                {id: '1', text: 'JA'}
            ];

    this.state.bgo = {};
    this.state.bto = {};
    this.state.background = "#fff";

    this.state.cols = {};
    this.state.lrwrk = false;
    this.state.boknr = {};
      this.state.name = "";
      this.state.pris = "";
    if(props.boknr) this.state.boknr = props.boknr;

    this.state.wnds = {};
      this.state.rvo = {};
      this.state.bro = {};
      this.state.brs = {};
      this.state.gsb = {};
    this.state.gso = {};
    this.state.rro = {};
    this.state.anm = "";
    if(props.wnds) {
        if(props.wnds.boknr) this.state.boknr = props.wnds.boknr;
        if(props.wnds.name) this.state.name = props.wnds.name;
        if(props.wnds.rvo) this.state.rvo = props.wnds.rvo;
        this.state.bro = this.state.rvo.rblx0;
        this.state.gsb = this.state.rvo.gsb00;
    }
    this.state.bra = [];
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.gst_init();
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    grp_style(row) {
      var css = {};
      css.background = "#008";
      if(this.state.gnr === row.vgrupp) css.background = "#080";
      return css;
    }

    wnd_close = () => {
      if(this.props.wnds.cb) {
        var ret = {};
        ret.ok = "999";
        this.props.wnds.cb(this.props.ctx, ret);
      }
      else this.props.ctx.setState({ wnds: {} });
	};

    rsv_save (name, value) {
        //alert(JSON.stringify(name));
        //alert(JSON.stringify(value));
      var rsv = this.state.bro;
      var na = name.split(".");
      rsv[na[0]][na[1]] = value;
      this.setState({rsv: rsv});
      //setState({[name]: value});
    }
    val_save (name, value) {
        //alert(JSON.stringify(name));
        //alert(JSON.stringify(value));
      var gso = this.state.gso;
      gso[name] = value;
      this.setState({gso: gso});
      //setState({[name]: value});
    }
    anm_save (e) {
      const name = e.target.name;
      const value = e.target.value;
      this.setState({anm: value});
    }
    cb_save (name, e) {
      const value = e.target.value;
      var bro = this.state.bro;
      bro[name] = value;
      this.setState({bro: bro});
      //setState({[name]: value});
    }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }

  br_open() {
  }

  gst_init() {
    var self = this;
    //var dat = addDays(this.state.today, 1);
    //var fdat = format(this.state.today, "yyyy-MM-dd");
    //var tdat = format(dat, "yyyy-MM-dd");

    var gso = {};
    gso.fdat = this.state.gsb.r0ankdatum;
    gso.tdat = this.state.gsb.r0avrdatum;
    gso.rumsnr = this.state.gsb.r0rumsnr.cdirumsnr();
    gso.namn = this.state.gsb.r0namn;
    gso.nrr = "1";
    gso.nrg = this.state.gsb.r0guests.ztrim();
    gso.nrgr = this.state.gsb.r0guests.ztrim();
    gso.nrb0 = this.state.gsb.r0antb0.ztrim();
    gso.nrb1 = this.state.gsb.r0antb1.ztrim();
    gso.nrb2 = this.state.gsb.r0antb2.ztrim();
    gso.rt = this.state.bro.r0typ;
    gso.pkat = "";
    gso.rab = "";
    if(this.state.rro && this.state.rro.room) {
        gso.rumsnr = this.state.rro.room.rumsnr;
        gso.rt = this.state.rro.room.rt;
    }
    var anm = this.state.anm.toswe();
    gso.fdat = gso.fdat.cdidate();
    gso.tdat = gso.tdat.cdidate();

    //alert(JSON.stringify(this.state.rro));
    this.setState({gso: gso, anm: anm});
  }

    val_fd_save (dts) {
        var gso = this.state.gso;
        //var dts = format(value, "yyyy-MM-dd");
        if(dts < this.state.tds) dts = this.state.tds;
        gso.fdat = dts;
        if(gso.tdat < dts) {
            var tdd = new Date(dts);
            var tdat = addDays(tdd, 1);
            gso.tdat = format(tdat, "yyyy-MM-dd");
            //alert(dts);
            //bro.tdat = dts;
        }
        var fdd = new Date(gso.fdat);
        var tdd = new Date(gso.tdat);
        var days = differenceInCalendarDays(tdd, fdd);
        var pris = (this.state.pris.replaceAll(".", "") * 1);
        var sum = pris * (gso.nrr * 1) * days;
        this.setState({gso: gso, sum: sum});
        //this.bel_rt();
    }
    val_td_save (dts) {
        var gso = this.state.gso;
        //var dts = format(value, "yyyy-MM-dd");
        if(dts < this.state.tds) dts = this.state.tds;
        if(dts < gso.fdat) {
            //var dts = format(dts, "yyyy-MM-dd");
            gso.fdat = dts;
        }
        gso.tdat = dts;
        var fdd = new Date(gso.fdat);
        var tdd = new Date(gso.tdat);
        var days = differenceInCalendarDays(tdd, fdd);
        var pris = (this.state.pris.replaceAll(".", "") * 1);
        var sum = pris * (gso.nrr * 1) * days;
        this.setState({gso: gso, sum: sum});
    }

    rts() {
    var self = this;
    var prm = {};
    prm.req = "pms.rt.rt_date";
    //prm.datum = this.state.datum;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            return;
        }
        self.setState({rta: ret.rco});
        self.pkats();
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  pkats() {
    var self = this;
    var prm = {};
    prm.req = "pms.pkat.qa_pkats";
    //prm.datum = this.state.datum;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            return;
        }
        self.setState({pka: ret.rco});
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

    gst_dt_ch() {
    var self = this;

    var gso = this.state.gso;
    //if(gso.rumsnr.length == 4) gso.rumsnr = gso.rumsnr + "00";

    var prm = {};
    prm.req = "pms.gso.gst_dt_change";
    prm.boknr = this.state.boknr;
    prm.rblx0 = this.state.bro;
    prm.gsb00 = this.state.gsb;
    prm.fdat = gso.fdat;
    prm.tdat = gso.tdat;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok == "000") {
		    //self.props.cb(self.props.ctx, ret);
              if(self.props.wnds.cb) {
                var ret = {};
                ret.ok = "000";
                self.props.wnds.cb(self.props.ctx, ret);
              }
              else self.props.ctx.setState({ wnds: {} });
            return;
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

  gst_room(e) {
    e.preventDefault();
    var self = this;
    var wnds = {};
    wnds.gstroom = true;
    wnds.boknr = this.state.boknr;
    wnds.bro = this.state.bro;
    wnds.cb = this.cb_gstrooms;
    this.setState({wnds: wnds});
  }
  cb_gstrooms(ctx, ret) {
      if(ret.ok == "000") {
          //ctx.rv_br();
        var gso = ctx.state.gso;
        //gso.rumsnr = ret.rumsnr + "00";
        gso.rumsnr = ret.rumsnr;
        gso.rt = ret.rt;
        ctx.setState({ wnds: {}, gso: gso });
      }
      else {
        ctx.setState({ wnds: {} });
      }
  };
  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "Gäst";
    //if(props.kro) alert(props.kro.krr00.kid);
    const popover = {
      position: 'absolute',
      top: '50px',
      zIndex: '2',
    }
    const cover = {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    }

	var html =
        <div className="mx_base">
        <div className="m3_backdrop" />
        <div className="m3_dlg mx_80h flex-col" style={ this.dlg_style() } draggable="true" >

        <div className="mx_h01">
            <div>FÖRÄNDRA VISTELSE</div>
        </div>
        <div className="mx_content flex">

            <div className="divider_orange"></div>

            <div className="txt_box txt_bg_01 txt_22">
            <div className="flex-row">
                <div className="wnd_link">
                    DELBOKNING
                </div>
                <div className="flex"></div>
                <div className="wnd_link" onClick={() => this.br_open() }>
                    ÄNDRA
                </div>
            </div>
            <div className="flex-row py-2">
                <div className="flex-row flex">
                    <div className="rv-lbl">Från:</div>
                    <div className="rv-txt">{this.state.bro.r0ankdatum.cdidate()}</div>
                </div>
                <div className="flex-row flex">
                    <div className="rv-lbl">Till:</div>
                    <div className="rv-txt">{this.state.bro.r0avrdatum.cdidate()}</div>
                </div>
            </div>
            <div className="flex-row py-2">
                <div className="flex-row flex">
                    <div className="rv-lbl">Antal Rum:</div>
                    <div className="rv-txt">{this.state.bro.r0borr}</div>
                </div>
                <div className="flex-row flex">
                    <div className="rv-lbl">Antal Gäster:</div>
                    <div className="rv-txt">{this.state.bro.r0borg}</div>
                </div>
            </div>
            <div className="flex-row py-2">
                <div className="flex-row flex">
                    <div className="rv-lbl">Barn (0-4):</div>
                    <div className="rv-txt">{this.state.bro.r0antb0}</div>
                </div>
                <div className="flex-row flex">
                    <div className="rv-lbl">Barn (5-18):</div>
                    <div className="rv-txt">{this.state.bro.r0antb1}</div>
                </div>
            </div>
            <div className="flex-row py-2">
                <div className="flex-row flex">
                    <div className="rv-lbl">Rumstyp:</div>
                    <div className="rv-txt">{this.state.bro.r0typ}</div>
                </div>
            </div>

            </div>
            <div className="divider_orange"></div>

            <form className="cdi_f03">
            <div className="flex-row hide">
            <div className="flex-row py-2">
                    <Fr_Input label="Från" className="text-left w250" text={this.state.gso.fdat} getValue={(e)=> this.val_save("fdat", e) } />
            </div>
            <div className="flex-row py-2">
                    <Fr_Input label="Till" className="text-left w250" text={this.state.gso.tdat} getValue={(e)=> this.val_save("tdat", e) } />
            </div>
            </div>

            <div className="flex-row py-2">
                <Fr_Cal label="Från" className="text-left w250" min="0" text={this.state.gso.fdat} getValue={(e)=> this.val_fd_save( e ) }/>
                <Fr_Cal label="Till" className="text-left w250" min="0" text={this.state.gso.tdat} getValue={(e)=> this.val_td_save( e ) }/>
            </div>

            <div className="divider_green"></div>
            </form>
        </div>

        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex-row">
            <button className="btn btn-hw-green w250" onClick={() => this.gst_dt_ch() } >
                ÄNDRA
            </button>
            </div>
        </div>
      </div>
        { this.state.wnds.gstroom ? <Rf_GstRooms wnds={this.state.wnds} cb={this.cb_gstrooms} ctx={this} store={this.props.store} backdrop={true}/> : null }
      </div>
	;
	return html;
  };
}

export default Gst_Ch_Stay;

/*
    <Fr_Input label="Rumsnr" className="text-left w250" text={this.state.gso.rumsnr} getValue={(e)=> this.val_save("rumsnr", e) } />
*/