import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";
import Dt_Cal from "../../lib/ui/dt_cal";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Bo_Marker extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, tra: [] };
    this.state.seldate = new Date();
    this.state.avd = "01";
    this.state.paa = [];
    this.state.aa = [];
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 480;
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

  plu_open(row) {
      var self = this;
  }
  plu_edit(row) {
      var self = this;
      alert(JSON.stringify(row.plu00));
  }

  render() {
    return (
    <div className="web-app">
    <h3>Marker</h3>

    <div className="flex-row">
        <Dt_Cal startdatum="2020-02-01" getValue={(e)=> this.dt_dat(e)} />
        <select className="form-control sel_input" placeholder="Välj avdelning">
            { this.state.aa.map((row, key) =>
            <option key={key} value={row.id}>{row.text}</option>
            )}
        </select>
        <button className="btn btn-hw-blue w150 ripple" onClick={() => this.plus() } aria-label="System" >
            Visa
        </button>
    </div>
    <div className="divider">
    </div>

    <div className="web-row">
        <table className="table table-bordered table-hover tb fixed_header">
            <thead>
            <tr>
                <th>PLU</th>
                <th>Text</th>
                <th>Pris</th>
                <th>Varugrupp</th>
                <th>Moms</th>
                <th>Sort</th>
                <th>Uppdat</th>
                <th style={{width:"40px"}} ></th>
                <th style={{width:"16px"}} ></th>
            </tr>
            </thead>
            <tbody style={ this.tb_style() }>
            { this.state.paa.map((row, key) =>
            <tr key={key} id={ row.plu00.p0katnr } onDblClick="plu_open(row);">
                <td>{ row.plu00.p0katnr }</td>
                <td>{ row.plu00.p0namn }</td>
                <td>{ row.plu00.p0pris }</td>
                <td>{ row.plu02.p2vgrupp }</td>
                <td>{ row.plu00.p0moms }</td>
                <td>{ row.plu00.p0sort }</td>
                <td>{ row.plu00.p0upddat }</td>
                <td style={{width:"40px",cursor:"pointer"}} onClick={() => this.plu_edit(row) }><i className="fa fa-cog text-danger"></i></td>
            </tr>
            )}
            </tbody>
        </table>
    </div>

    </div>
  );
    }
}
export default Bo_Marker;
