import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../../lib/net";
import Fr_Input from "../../../lib/ui/fr_input";
import * as utils from "../../../assets/utils";
import {format, getHours, subDays} from "date-fns";

class Order_Cart extends React.Component {
  constructor(props, context) {
    super(props);

    this.pos = props.store.pos;
    this.state = {};
    this.state.ynq = [];
    this.state.sval = [];

    this.state.dao = {};
    this.state.aa = [];
    this.state.cval = [
                {id: '001', text: 'ANTAL GER NYTT PRIS'},
                {id: '002', text: 'ANTAL GER RABATT PÅ PRIS'},
                {id: '003', text: 'PRISÄNDRING GENOM RABATT'}
            ];

    this.state.tra = [];
      this.state.vpo = {};
      if(props.wnds) {
          this.state.vpo = props.wnds.vpo;
      }
      this.state.today = new Date();
      var hh = getHours(this.state.today);
      if( hh < 5) {
          const dt = subDays(new Date(), 1);
          this.state.dts = format(dt, "yyyyMMdd");
      }
      else this.state.dts = format(this.state.today, "yyyyMMdd");
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.order_cart();
    //alert(JSON.stringify(this.state.vpo));
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    tab_style(tabid) {
      var css = {};
      css.display = "none";
      if(this.state.tabval === tabid) css.display = "block";
      return css;
    }
    tab_head(tabid) {
      var css = {};
      css.background = "#fff";
      css.color = "#000";
      if(this.state.tabval === tabid) {
          css.background = "#080";
          css.color = "#fff";
      }
      return css;
    }
    grp_style(row) {
      var css = {};
      css.background = "#008";
      if(this.state.gnr === row.vgrupp) css.background = "#080";
      return css;
    }

    wnd_close = () => {
		this.props.ctx.setState({ wnds: {} });
	};
    handleChange = (event, newValue) => {
        //alert(newValue);
        this.setState({ix: newValue});
      };
    toggle() {
      this.setState({modal: false});
	};

  tb_style() {
      var css = {};
      //css.width = window.innerwidth * 0.7;
      //css.height = window.innerHeight - 375;
      return css;
  }
    order_cart() {
        var self = this;
        self.setState({ora: [], loading: 1 });

        var prm = {};
        prm.req = "pos.pos_ecom_order.order_cart";
        prm.orderid = this.state.vpo.order00.orderid;

        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret.sbo));

            var sbo = ret.sbo;
            //alert(JSON.stringify(sbo));
            var tra = [];
            if(sbo.cart) tra = sbo.cart.rows;
            //var a = self.fltr(ret.rca);
            self.setState({tra: tra, loading: 0 });
            //toastr.success("OK", "SAPI");
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }
    pro_ina(row) {
        var a = [];
        //alert(JSON.stringify(row.pro.ino));
        if(row.pro.ino) a = Object.values(row.pro.ino);
        return a;
    }
    pro_ixa(row) {
        var a = [];
        if(row.pro.ixo) a = Object.values(row.pro.ixo);
        return a;
    }
    pro_txa(row) {
        var a = [];
        if(row.pro.txo) a = Object.values(row.pro.txo);
        return a;
    }
    pro_sxa(row) {
        var a = [];
        if(row.pro.sxo) a = Object.values(row.pro.sxo);
        return a;
    }
    pro_dxa(row) {
        var a = [];
        if(row.pro.dxo) a = Object.values(row.pro.dxo);
        return a;
    }

  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        <div className="m2_backdrop" />
        <div className="m2_dlg mx_70 flex-col" style={ this.dlg_style() } >
        <div className="mx_head">
            <div>ORDER CART</div>
        </div>
        <div className="mx_content flex">

            <div className="flex-row" style={{padding:"5px",height:"35px"}}>
                    <div className="flex-col flex">
                    </div>
                    <div className="flex-col">
                        <div>UPPDATERA</div>
                    </div>
            </div>
            <div className="divider_orange"></div>

        <table className="table table-bordered table-hover">
            <thead>
            <tr>
                <th>ID</th>
                <th>Text</th>
				<th>Antal</th>
                <th>Pris</th>
                <th>Summa</th>
				<th>Typ</th>
            </tr>
            </thead>
            { this.state.tra.map((tro, key) =>
            <tbody>
            <tr key={key} onDoubleClick={ () => this.tro_edit(tro) }>
                <td>{ utils.toLang(tro.plu) }</td>
                <td>{ utils.toLang(tro.text) }</td>
                <td>{ utils.toNum(tro.antal) }</td>
                <td>{ utils.toPris(tro.pris) }</td>
                <td>{ utils.toPris( (tro.antal * 1) * (tro.pris * 1) ) }</td>
                <td>{ tro.r0ttyp }</td>
            </tr>
            { this.pro_ixa(tro).map((row, key) =>
                <tr key={key} onDoubleClick={ () => this.tro_edit(tro) }>
                    <td>EXTRA</td>
                    <td>{ utils.toLang(row.m0text) }</td>
                    <td>{ utils.toNum(row.m0nrof) }</td>
                    <td>{ utils.toPris(row.m0pris) }</td>
                    <td>{ utils.toPris( (row.m0nrof * 1) * (row.m0pris * 1) ) }</td>
                    <td>{ row.r0ttyp }</td>
                </tr>
            )}
            { this.pro_txa(tro).map((row, key) =>
                <tr key={key} onDoubleClick={ () => this.tro_edit(tro) }>
                    <td>TBH</td>
                    <td>{ utils.toLang(row.m0text) }</td>
                    <td>{ utils.toNum(row.m0nrof) }</td>
                    <td>{ utils.toPris(row.m0pris) }</td>
                    <td>{ utils.toPris( (row.m0nrof * 1) * (row.m0pris * 1) ) }</td>
                    <td>{ row.r0ttyp }</td>
                </tr>
            )}
            { this.pro_sxa(tro).map((row, key) =>
                <tr key={key} onDoubleClick={ () => this.tro_edit(tro) }>
                    <td>SIDE</td>
                    <td>{ utils.toLang(row.m0text) }</td>
                    <td>{ utils.toNum(row.m0nrof) }</td>
                    <td>{ utils.toPris(row.m0pris) }</td>
                    <td>{ utils.toPris( (row.m0nrof * 1) * (row.m0pris * 1) ) }</td>
                    <td>{ row.r0ttyp }</td>
                </tr>
            )}
            { this.pro_dxa(tro).map((row, key) =>
                <tr key={key} onDoubleClick={ () => this.tro_edit(tro) }>
                    <td>DRINK</td>
                    <td>{ utils.toLang(row.m0text) }</td>
                    <td>{ utils.toNum(row.m0nrof) }</td>
                    <td>{ utils.toPris(row.m0pris) }</td>
                    <td>{ utils.toPris( (row.m0nrof * 1) * (row.m0pris * 1) ) }</td>
                    <td>{ row.r0ttyp }</td>
                </tr>
            )}
            </tbody>
            )}
        </table>

        </div>
        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
        </div>
      </div>
      </div>
	;
	return html;
  };
}

export default Order_Cart;