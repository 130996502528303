import React, { useState, useEffect, useContext } from 'react';
import {format} from "date-fns";

import * as net from "../../../../lib/net";

import Dd_Sel from "../../../../lib/ui/dd_sel";
import Dt_Cal from "../../../../lib/ui/dt_cal";
import Wnd_Error from "../../../../lib/ui/wnd_error";
import Wnd_Ok from "../../../../lib/ui/wnd_ok";
import Wnd_Info from "../../../../lib/ui/wnd_info";
import Wnd_Confirm from "../../../../lib/ui/wnd_confirm";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Saldo_Dt extends React.Component {
  constructor(props, context) {
    super(props);

    this.dt_datum = React.createRef();
    this.selitems = [
        {fkn: 'bevaka', text: 'BEVAKA'},
        {fkn: 'bokbild', text: 'BOKNINGSBILD'},
        {fkn: 'confirm', text: 'BEKRÄFTA'},
        {fkn: 'cancel', text: 'AVBOKA'}
    ];

    this.store = props.store;
    this.state = {};
    this.state.ynq = [];
    //this.state.vga = [];
    this.state.rta = [];
    this.state.bra = [];

    this.state.paa = [];
    this.state.gsa = [];

    this.state.cols = {};

    this.state.ix = 0;
    this.state.boknr = "";
    this.state.datum = "";

      this.state.msg = {};
      this.state.wnds = {};
    this.state.rvo = {};
    if(props.wnds) {
        if(props.wnds.dts) this.state.datum = props.wnds.dts;
        if(props.wnds.boknr) this.state.boknr = props.wnds.boknr;
    }
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      ///this.dt_saldo();
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }

    formchange (e) {
      const name = e.target.name;
      const value = e.target.value;
      var rvo = this.state.rvo;
      var na = name.split(".");
      rvo[na[0]][na[1]] = value;
      this.setState({rvo: rvo});
      //setState({[name]: value});
    }

    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    wnd_close = () => {
      if(this.props.close) {
          this.props.close(this.props.ctx);
      }
      else this.props.ctx.setState({ wnds: {} });
	};
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  cssCols (cid) {
      var css = {};
      if(this.state.cols[cid]) css.width = this.state.cols[cid].toString() + "px";
      //if(cid == "c01") alert(JSON.stringify(this.state.cols[cid]));
      return css;
  }
  syncCols (tb) {

    if (!document.getElementById(tb)) return;

    var cols = {};
    var ix = document.getElementById(tb).rows.length - 1;
    var ic = document.getElementById(tb).rows[0].cells.length;
    var ci = 0;
    while(ci < ic) {
        var cxi = ci + 1;
        var col = "c" + cxi.toString().padStart(2, "0");
        cols[col] = document.getElementById(tb).rows[ix].cells[ci].clientWidth + 1;
        ci = ci + 1;
    }
    this.setState({cols: cols });
    //alert(JSON.stringify(this.state.cols));
    //this.forceUpdate();
  };
    val_save (name, value) {
        //alert(JSON.stringify(name));
        //alert(JSON.stringify(value));
      var rvo = this.state.rvo;
      var na = name.split(".");
      rvo[na[0]][na[1]] = value;
      this.setState({rvo: rvo});
      //setState({[name]: value});
    }
  dt_dat(e) {
    //alert(JSON.stringify(e));
    var dts = format(e, "yyyy-MM-dd");
    this.setState({ datum: dts });
  }
  addday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_datum.current.updateAdd();
  }
  subday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_datum.current.updateSub();
  }
  dt_saldo() {
    var self = this;
    //var datum = format(this.state.datum, "yyyy-MM-dd");
      var msg = { info: true, text: "Hämtar saldo ..."};
      self.setState({ msg: msg });

    var prm = {};
    prm.req = "pms.eko_red.red_saldo";
    prm.fdat = this.state.datum;
    prm.tdat = this.state.datum;
    //alert(JSON.stringify(prm));

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.ok));

        if(ret.ok != "000") {
            return;
        }
        var gsa = ret.sdo.gsa;
        self.setState({gsa: gsa, msg: {} });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
    }

  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "Bokning";

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="mx_backdrop" /> : null }
        <div className="mx_dlg mx_90 flex-col" style={ this.dlg_style() }>
        <div className="mx_h01">
            <div>DAGS SALDON</div>
        </div>

            <div className="divider_orange hide"></div>

            <div className="flex-row">
                <div className="flex"></div>
            <div className="flex-col">
                <div className="dt-btn-01" onClick={() => this.subday() } ><FontAwesomeIcon icon="minus" size="1x" /></div>
            </div>
            <div className="flex-col">
                  <Dt_Cal text={ this.state.datum } rp="1" ref={this.dt_datum} getValue={(e)=> this.dt_dat(e)} />
            </div>
            <div className="flex-col">
                <div className="dt-btn-01" onClick={() => this.addday() } ><FontAwesomeIcon icon="plus" size="1x" /></div>
            </div>
            <div className="flex"></div>
                <button className="btn btn-hw-blue w150" onClick={() => this.dt_saldo() } aria-label="System" >
                    Visa
                </button>
            </div>
             <div className="divider_green"></div>
       <div className="mx_content flex">

           <div id="saldodtTable">
                <table className="table table-bordered table-hover" style={{marginBottom: "0px"}}>
                    <thead>
                    <tr>
                        <th style={{textAlign: "left"}}>Från - Till</th>
                        <th style={{textAlign: "left"}}>Boknr</th>
                        <th style={{textAlign: "left"}}>Rumsnr</th>
                        <th style={{textAlign: "left"}}>Rumstyp</th>
                        <th style={{textAlign: "left"}}>Namn</th>
                        <th style={{textAlign: "left"}}>Gäster</th>
                        <th style={{textAlign: "left"}}>Fsg</th>
                        <th style={{textAlign: "left"}}>Bet</th>
                        <th style={{textAlign: "left"}}>Post</th>
                        <th style={{textAlign: "left"}}>Saldo</th>
                        <th style={{textAlign: "left"}}>Status</th>
                    </tr>
                    </thead>
                    <tbody>
                    { this.state.gsa.map((row, key) =>
                    <tr key={key} onDoubleClick={() => this.gst_edit(row) }>
                        <td>{ row.ankdat } - { row.avrdat }</td>
                        <td>{ row.boknr }/{ row.lopnr }</td>
                        <td>{ row.rumsnr }</td>
                        <td>{ row.typ }</td>
                        <td>{ row.name }</td>
                        <td>{ row.nrg }</td>
                        <td>{ row.charges.fsg.cdipris() }</td>
                        <td>{ row.charges.bet.cdipris() }</td>
                        <td>{ row.charges.post.cdipris() }</td>
                        <td>{ row.charges.saldo.cdipris() }</td>
                        <td>{ row.status }</td>
                    </tr>
                    )}
                    </tbody>
                </table>
             </div>


            </div>

            <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250 ripple" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex-row">
            </div>
        </div>
      </div>


            <Wnd_Error msg={this.state.msg} ctx={this} />
            <Wnd_Ok msg={this.state.msg} ctx={this} />
            <Wnd_Info msg={this.state.msg} ctx={this} />
            <Wnd_Confirm msg={this.state.msg} ctx={this} />
    </div>
	;
	return html;
  };
}

export default Saldo_Dt;