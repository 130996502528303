import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";
import ddo from '../../ddo.js'

import Tr_dlg from "./list/tr_dlg";
import Sort_dlg from "./list/sort_dlg";
import Betm_dlg from "./list/betm_dlg";
import Term_dlg from "./list/term_dlg";
import Kassor_dlg from "./list/kassor_dlg";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Pos_List extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, tra: [] };
    this.state.seldate = new Date();
    this.state.avd = ddo.cache.avd;
    this.state.paa = [];
    this.state.aa = [];

    this.state.pdf = 0;
    this.state.rptyp = "";
    this.state.fsg = 0;
    this.state.bet = 0;

      this.state.mna = [];
      this.state.mna.push({ rpid: "tr", pos: "c1", text: "TRANSAKTIONSLISTA", desc: "Transaktioner för perioden", link: "" });
      this.state.mna.push({ rpid: "sort", pos: "c1", text: "SORTIMENTS LISTA", desc: "Sortiment hela eller urval", link: "" });
      this.state.mna.push({ rpid: "betm", pos: "c1", text: "BETALMEDELS LISTA", desc: "Upplagda betalmedel", link: "" });
      this.state.mna.push({ rpid: "term", pos: "c2", text: "TERMINALLISTA", desc: "Upplagda terminaler", link: "" });
      this.state.mna.push({ rpid: "kassor", pos: "c2", text: "KASSÖRS LISTA", desc: "Upplagda kassörer", link: "" });
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 480;
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

    mnc(pos) {
        var mna = [];
        for(var row of this.state.mna) {
            if(row.pos == pos) mna.push( row );
        }
        return mna;
    }

  rp_open(typ) {
      var self = this;
    this.setState({ rptyp: typ });
  }

  render() {
    return (
        <div className="web-app">
            <div className="web-pg py-4 flex">
                <h3>Listor</h3>
                <p>List funktioner</p>

                <div className="divider_green"></div>

                <div className="web-row">
                    <div className="flex-col flex">
                        { this.mnc("c1").map((row, key) =>
                            <div key={key} className="web-bx" onClick={() => this.rp_open(row.rpid) }>
                                <div>{ row.text }</div>
                                <div className="flex-row">
                                    <div className="flex">{ row.desc }</div>
                                    <div className="web-link">Välj</div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="flex-col flex">
                        { this.mnc("c2").map((row, key) =>
                            <div key={key} className="web-bx" onClick={() => this.rp_open(row.rpid) }>
                                <div>{ row.text }</div>
                                <div className="flex-row">
                                    <div className="flex">{ row.desc }</div>
                                    <div className="web-link">Välj</div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>

        { this.state.rptyp == "tr" ? <Tr_dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "sort" ? <Sort_dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "betm" ? <Betm_dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "term" ? <Term_dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "kassor" ? <Kassor_dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
    </div>
  );
    }
}
export default Pos_List;
