import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../../lib/net";
import Fr_Input from "../../../lib/ui/fr_input";
import Fr_Select from "../../../lib/ui/fr_select";
import Fr_Cal from "../../../lib/ui/fr_cal";
import Wnd_Error from "../../../lib/ui/wnd_error";
import Wnd_Ok from "../../../lib/ui/wnd_ok";
import Wnd_Info from "../../../lib/ui/wnd_info";
import Wnd_Confirm from "../../../lib/ui/wnd_confirm";

class Task_event_add extends React.Component {
  constructor(props, context) {
    super(props);

    this.pos = props.store.pos;
    this.state = {};
    this.state.mka = [];

    this.state.gnr = "001";
    //this.state.lro = props.lro;
      this.state.ynq = [
          {id: '0', text: 'NEJ'},
          {id: '1', text: 'JA'}
      ];
      this.state.sval = [
          {id: '0', text: 'REGISTRERAD'},
          {id: '1', text: 'BEARBETAS'},
          {id: '5', text: 'KLAR'},
          {id: '8', text: 'FAKTURERAD'},
          {id: '9', text: 'AVSLUTAD'}
      ];
      this.state.preg = [
          {id: '00000', text: 'EJ TILLDELAD'},
          {id: '00001', text: 'Joakim'},
          {id: '00002', text: 'Daniel'},
          {id: '00003', text: 'Stellan'},
          {id: '00004', text: 'Tobias'},
          {id: '00005', text: 'Emmanuel'},
          {id: '00006', text: 'Kari'}
      ];

      this.state.wnds = {};
      this.state.msg = {};
    this.state.evo = {};
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.btn_grps();
      this.tev_init();
  }

    tev_init() {
        var evo = {};
        evo.typ = "E";
        evo.id = this.props.wnds.taskid;
        evo.lopnr = "";
        evo.pid = "";
        evo.ts = "";
        evo.msg = "";
        //alert(JSON.stringify(rso));
        this.setState({ evo: evo });
        return;
    }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }

    wnd_close = () => {
      if(this.props.close) {
          this.props.close(this.props.ctx);
      }
      else this.props.ctx.setState({ wnds: {} });
	};
    preg_change (e) {
        const value = e.target.value;
        var evo = this.state.evo;
        evo.pid = value;
        this.setState({evo: evo});
    }
    msg_change (e) {
        const value = e.target.value;
        var evo = this.state.evo;
        evo.msg = value;
        this.setState({evo: evo});
    }

    val_dt_save(val) {
        var tdo = this.state.tdo;
        tdo.due = val;
        this.setState({ tdo: tdo });
        return;
    }
    val_save(id, val) {
        var tdo = this.state.tdo;
        tdo[id] = val;
        this.setState({ tdo: tdo });
        return;
    }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  tev_add() {
    var self = this;
    var prm = {};
    prm.req = "bcs.wrk.task_event_add";
    prm.evo = this.state.evo;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));
        if(ret.ok == "000") {
            self.setState({ msg: {ok: true, text: "Sparad ..."} });
            setTimeout(function(){
                self.setState({msg: {} });
                if(self.props.wnds.cb) {
                    self.props.wnds.cb(self.props.ctx);
                }
                else self.props.ctx.setState({ wnds: {} });
                return;
            }, 1000);
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  tev_del() {
    var self = this;
    var prm = {};
    prm.req = "bcs.wrk.tev_del";
    prm.evo = this.state.evo;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));
        if(ret.ok == "000") {
            self.setState({ msg: {ok: true, text: "Borttagen ..."} });
            setTimeout(function(){
                self.setState({msg: {} });
                if(self.props.close) {
                    self.props.close(self.props.ctx);
                }
                else self.props.ctx.setState({ wnds: {} });
                return;
            }, 1000);
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

  render() {
    //if(!this.props.show) return "";
    var title = this.props.title;
    if(!title) title = "Resurs";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        <div className="m2_backdrop" />
        <div className="m2_dlg mx_80 flex-col" style={ this.dlg_style() } >
        <div className="mx_content flex">

        <div className="mx_h01">
            <div>NY HÄNDELSE</div>
            <div className="flex"></div>
            <div onClick={() => this.wnd_close() }>X</div>
        </div>
            <div className="flex-row" style={{padding:"5px",height:"35px"}}>
                    <div className="flex-col flex">
                       </div>
                    <div className="flex-col">
                        <div>UPPDATERA</div>
                    </div>
            </div>
            <div className="divider_orange"></div>

            <form className="cdi_f03">
                <div className="flex-row">
                    <div className="form-group flex-row flex">
                        <span className="header">Tilldelad</span>
                        <select value={this.state.evo.pid} className="form-control w250" onChange={(e) => this.preg_change(e)}>
                            { this.state.preg.map((row, key) =>
                                <option key={key} value={row.id}>{row.text}</option>
                            )}
                        </select>
                    </div>
                </div>
                <div className="divider_green"></div>
                <div className="flex-row">
                    <div className="flex flex-column" style={{padding: "0px 5px"}}>
                        <span className="header">Händelse information</span>
                        <textarea className="form-control" maxLength="512" rows="20" value={this.state.evo.msg} onChange={(e) => this.msg_change(e)}></textarea>
                    </div>
                </div>
            </form>

        </div>
        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex-row">
            <button className="btn btn-hw-red w250" onClick={() => this.tev_add() } >
                SPARA
            </button>
            </div>
        </div>
      </div>

            <Wnd_Error msg={this.state.msg} ctx={this} />
            <Wnd_Ok msg={this.state.msg} ctx={this} />
            <Wnd_Info msg={this.state.msg} ctx={this} />
            <Wnd_Confirm msg={this.state.msg} ctx={this} />
    </div>
	;
	return html;
  };
}

export default Task_event_add;