import React, { useState, useEffect, useContext } from 'react';
import * as net from "../../lib/net";
import sso from "../../sso";

import Fr_Input from "../../lib/ui/fr_input";
import {observer} from "mobx-react";

class Pms_Term extends React.Component {
  constructor(props, context) {
    super(props);

    this.store = props.store;

    this.state = {};
    this.state.ynq = [];

    this.state.paa = [];
    this.state.aa = [];

    this.state.auth = 0;
    this.state.signatur = "";
    this.state.termnr = "";
    if(props.wnds) {
        //this.state.termnr = props.wnds.termnr;
    }
    this.state.wnds = {};
    this.state.seo = {};
      if(props.store && props.store.seo) {
          this.state.seo = props.store.seo;
      }
      if(sso && sso.seo) {
          //this.state.seo = sso.seo;
          this.state.seo.termnr = sso.seo.termnr;
          this.state.seo.prnnr = sso.seo.prnnr;
          this.state.seo.emvnr = sso.seo.emvnr;
          this.state.seo.encoder = sso.seo.encoder;

      }
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }

    wnd_close() {
		this.props.ctx.setState({ wnds: {} });
	};
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  fknSel(sel) {
    //alert(JSON.stringify(fkn));
      if(sel == "rvo") {
          var wnds = {};
          wnds.wnd_rvo = true;
        this.setState({wnds: wnds});
      }
      if(sel == "gst") {
          var wnds = {};
          wnds.wnd_gso = true;
        this.setState({wnds: wnds});
      }
      if(sel == "nota") {
          var wnds = {};
          wnds.wnd_nota = true;
        this.setState({wnds: wnds});
      }
  }
    seo_save() {
        //var xeo = sessionStorage.getItem("svp");
        //var seo = JSON.parse(xeo);
        var seo = this.state.seo;
        //seo.termnr = "311";
        //seo.encoder = "01";
        this.store.seo = seo;
        sso.seo.termnr = seo.termnr;
        sso.seo.prnnr = seo.prnnr;
        sso.seo.emvnr = seo.emvnr;
        sso.seo.encoder = seo.encoder;
        //alert(JSON.stringify(sso.seo));

        //var xeo = JSON.stringify(seo);
        //sessionStorage.setItem("svp_sso", xeo);
        var xss = JSON.stringify(sso);
        sessionStorage.setItem("svp_sso", xss);
        this.props.ctx.setState({ wnds: {} });
    };
    term_reset() {
        //var xeo = sessionStorage.getItem("svp");
        //var seo = JSON.parse(xeo);

        var seo = this.state.seo;
        seo.termnr = "";
        seo.emvnr = "";
        seo.encoder = "";
        //delete(seo.termnr);
        //delete(seo.encoder);
        //delete(this.store.seo.termnr);
        //delete(this.store.seo.encoder);
        this.store.seo = seo;
        sso.seo.termnr = "";
        sso.seo.prnnr = "";
        sso.seo.emvnr = "";
        sso.seo.encoder = "";

        //var xeo = JSON.stringify(seo);
        //sessionStorage.setItem("svp_sso", xeo);
        var xss = JSON.stringify(sso);
        sessionStorage.setItem("svp_sso", xss);
        this.setState({seo: seo});
    };
    term_set() {
        var seo = {};
        var xeo = JSON.stringify(seo);
        sessionStorage.setItem("svp", xeo);
    };

  gst_co() {
    var self = this;

    var prm = {};
    prm.req = "pms.gst_op.gst_checkout";
    prm.boknr = this.state.rvo.boknr;
    prm.glopnr = this.state.rvo.gsb00.r0glopnr;
    prm.rumsnr = this.state.rvo.gsb00.r0rumsnr;
    prm.gsb00 = this.state.rvo.gsb00;
    //alert(JSON.stringify(prm));
    //return;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.ok));
        if(ret.ok) {
		    self.props.ctx.setState({ wnds: {} });
            return;
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

    val_save (name, value) {
      var seo = this.state.seo;
      //var na = name.split(".");
      //gso[na[0]][na[1]] = value;
      seo[name] = value;
      this.setState({seo: seo});
      //setState({[name]: value});
    }
   gst_close(ctx) {
        ctx.setState({ wnds: {} });
    };
   exit() {
        this.props.ctx.setState({ wnds: {} });
    };
    sign() {
      var signatur = this.state.signatur;
      if(signatur == "00") {
        this.setState({auth: 1});
      }
  }
    sign_save (value) {
      var signatur = this.state.signatur;
      //tbo[name] = value;
      this.setState({signatur: value});
    }
  box_style() {
      var css = {};
      css.width = window.innerWidth / 3;
      css.height = window.innerHeight / 3;
      css.border = "1px solid #ccc";
      return css;
  }

  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "";

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="mx_backdrop" /> : null }
        <div className="m2_dlg mx_80 flex-col" style={ this.dlg_style() }>
            <div className="mx_h01 flex-row" style={{background: "#1a456b", color: "#fff"}}>
                    <div className="flex-col flex">
                        <div>TERMINAL</div>
                    </div>
                    <div className="flex-col">
                        <div>TERMNR: { this.store.seo.termnr }</div>
                    </div>
            </div>
        <div className="mx_content flex">

            { this.state.auth == 0 ?
    <div className="web-col flex">
        <div className="flex"></div>
        <div className="web-row">
            <div className="flex"></div>

            <div className="web-col text-center" style={ this.box_style() }>
            <div className="flex"></div>
                <div className="txt_22">TERMINAL FUNKTIONER</div>
                <div className="txt_22">ANGE SIGNATUR</div>

        <div className="web-row">
            <div className="flex"></div>
                <Fr_Input type="password" noheader className="text-center w250" text={this.state.signatur} getValue={(e)=> this.sign_save(e) } />
            <div className="flex"></div>
        </div>
            <div className="flex"></div>

        <div className="web-row">
            <div className="flex"></div>
            <button className="btn btn-hw-red w200" onClick={() => this.exit() } >
                AVBRYT
            </button>
            <button className="btn btn-hw-green w200" onClick={() => this.sign() } >
                SIGNERA
            </button>
            <div className="flex"></div>
        </div>
            </div>

            <div className="flex"></div>
        </div>
        <div className="flex"></div>
   </div>
    :
    <div className="web-col flex">
            <div className="flex-row txt_22">
                <div className="flex text-center">INSTÄLLNINGAR</div>
            </div>
            <div className="flex"></div>
            <form className="cdi_f03">
            <div className="flex-col flex-cc">
                <div className="flex-row py-2">
                    <Fr_Input label="Terminal Nr" col className="text-left w250 mx-2" text={this.state.seo.termnr} getValue={(e)=> this.val_save("termnr", e) } />
                    <Fr_Input label="Skrivar Nr" col className="text-left w250 mx-2" text={this.state.seo.prnnr} getValue={(e)=> this.val_save("prnnr", e) } />
                </div>
                <div className="flex-row py-2">
                    <Fr_Input label="EMV Nr" col className="text-left w250 mx-2" text={this.state.seo.emvnr} getValue={(e)=> this.val_save("emvnr", e) } />
                    <Fr_Input label="Nyckel Kodare" col className="text-left w250 mx-2" text={this.state.seo.encoder} getValue={(e)=> this.val_save("encoder", e) } />
                </div>
            </div>
            </form>
            <div className="flex"></div>

            <div className="divider_orange"></div>
        <div className="web-row">
        <div className="web-col flex">
            <div className="wnd-btn" onClick={() => this.wnd_close() } >
                <div>STÄNG</div>
                <div></div>
            </div>
        </div>
         <div className="web-col flex" style={{padding: "0px 10px"}}>
             <div className="wnd-btn" onClick={() => this.term_reset() } >
                <div>RENSA TERMINAL</div>
                <div></div>
            </div>
      </div>
         <div className="web-col flex" style={{padding: "0px 10px"}}>
            <div className="wnd-btn" onClick={() => this.seo_save() } >
                <div>SPARA</div>
                <div></div>
            </div>
       </div>
       </div>
       </div>
            }

        </div>
      </div>
    </div>
	;
	return html;
  };
}

export default observer(Pms_Term);