import React, {useState} from 'react';
import {observer} from "mobx-react";

import sso from "../../sso";
import ddo from '../../ddo.js'
import * as utils from "../../assets/utils";

import * as net from "../../lib/net";
import Dd_Sel from "../../lib/ui/dd_sel";
import { useNavigate } from 'react-router-dom';

import Tmpl_Setup from "./tmpl_setup";
import Tmpl_Ses from "./tmpl_ses";
import Tmpl_Manager from "./tmpl_manager";
import Tmpl_Sids from "./tmpl_sids";
import Tmpl_Uds from "./tmpl_uds";
import Tmpl_Gtag from "./tmpl_gtag";
import Tmpl_Support from "./tmpl_support";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {toLang} from "../../assets/utils";

function Tmpl_Top(props) {
    var navigate = useNavigate();
    let [state, setState] = useState({wnds: {}, ma: []});

    //var seo = props.store.seo;

    sso.sdo.support = "0";

    var selitems = [
        {fkn: 'home', text: 'HEM'},
        {fkn: 'setup', text: 'INSTÄLLNINGAR'},
        {fkn: 'ses', text: 'MIN SESSION'},
    ];
    if(sso.seo.cs > 0) {
        selitems.push( {fkn: 'ccs', text: 'CENTRALSYSTEM'} );
    }
    if(sso.seo.auth >= 90) {
        selitems.push( {fkn: 'man', text: 'MANAGER'} );
    }
    selitems.push( {fkn: 'logout', text: 'LOGGA UT'} );

    var it = {};

    var fa = "fa fa-key";
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleOpen = event => {
    setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const wnds_close = () => {
        setState({wnds: {}});
    };

    const goFull = () => {
        alert("Full");
    };

    function logout() {
        var url = "/logout";
        navigate(url);
    };
      function xlogout() {
        var self = this;
        var xdo = sessionStorage.getItem("svp");
		var sdo = JSON.parse(xdo);

		var prm = {};
        prm.req = "usr:logout";
		prm.uid = sdo.uid;
		prm.token = sdo.token;

		//alert(JSON.stringify(prm));
        net.gcs_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret));

            if(ret.ok != "000") {
                alert(JSON.stringify(ret));
                //return;
            }
            setAnchorEl(null);
            sessionStorage.removeItem("svp_sso");
            sso.seo = {};

            var url = "/";
            navigate(url);
        })
        .catch(function(e){
            //var txt = JSON.stringify(e);
            alert("LOGOUT ERR: " + JSON.stringify(e));
            //gda.wndError(txt);
        });
      };
      const mnu_toggle = () => {
		//props.ctx.setState({ mnu_show: !props.ctx.state.mnu_show });
		props.store.cfg.mnu_show = !props.store.cfg.mnu_show;
      };
      const bread_toggle = () => {
		props.store.cfg.bread = !props.store.cfg.bread;
      };
      const mnu_item_css = () => {
      };
      const mnu_sel = () => {
      };
      const login = () => {
      };
      const header_class = () => {
  	    var cls = "flex_col";

		if(false) {
			cls = cls + " web-head_1";
		}
		else cls = cls +  " web-header";


		return cls;
    }
  function fknGo(url) {
    //alert(row.href);
      navigate(url);
  }
  function fkn_cs() {
      var wnds = {};
      wnds.ccs = true;
      wnds.backdrop = true;
      setState({"wnds": wnds});
  }
    function fkn_manager() {
        var wnds = {};
        wnds.manager = true;
        wnds.backdrop = true;
        setState({"wnds": wnds});
    }
    function fkn_syssel() {
        var wnds = {};
        wnds.syssel = true;
        wnds.backdrop = true;
        setState({"wnds": wnds});
    }
    function fkn_sids() {
        var wnds = {};
        wnds.sids = true;
        wnds.backdrop = true;
        setState({"wnds": wnds});
    }
    function fkn_uds() {
        var wnds = {};
        wnds.uds = true;
        wnds.backdrop = true;
        setState({"wnds": wnds});
    }
    function fkn_gtag() {
        var wnds = {};
        wnds.gtag = true;
        wnds.backdrop = true;
        setState({"wnds": wnds});
    }
    function fkn_support() {
        var wnds = {};
        wnds.support = true;
        wnds.backdrop = true;
        setState({"wnds": wnds});
    }

    function sel_fkn(sel) {
    //alert(JSON.stringify(fkn));
      if(sel.fkn == "home") {
        var url = "/tpl";
          navigate(url);
      }
      if(sel.fkn == "setup") {
          var wnds = {};
          wnds.setup = true;
          setState({"wnds": wnds});
      }
      if(sel.fkn == "ses") {
          var wnds = {};
          wnds.ses = true;
          setState({"wnds": wnds});
      }
      if(sel.fkn == "ccs") {
          var wnds = {};
          wnds.ccs = true;
          wnds.backdrop = true;
          setState({"wnds": wnds});
      }
      if(sel.fkn == "man") {
          var wnds = {};
          wnds.manager = true;
          setState({"wnds": wnds});
      }
      if(sel.fkn == "logout") {
        var url = "/outx";
          navigate(url);
      }
  }
    function show_avd() {
        //var deps = this.props.store.pos.deps;
        var dpo = ddo.cache.depo[ddo.cache.avd];
        //alert(JSON.stringify(dpo));
        return dpo.text;
    }

    var html =
        <div>
        <div id="header" className={ header_class() }>
            <div className="web-top-content d-flex flex-row">
                <div className="web-top-icon px-3 w60" onClick={() => mnu_toggle() }>
                    <FontAwesomeIcon icon="bars" size="1x"/>
                </div>
                <div className="web-top-mnu d-none d-md-inline-flex flex-column" onClick={() => fkn_manager() }>
                    <div className="txt_12">{ ddo.cache.ftg }</div>
                    <div className="txt_12"></div>
                </div>
                { ddo.cache.org == "" ?
                    <div className="flex-row flex">
                        <div className="flex-fill"></div>
                        <div className="txt_12">INGEN ORGANISATION</div>
                        <div className="flex-fill"></div>
                    </div>
                    : null
                }

                { ddo.cache.org == "LU" ?
                    <div className="flex-row flex">
                        <div className="flex-fill"></div>

                        { ddo.cache.auth == "05" ?
                            <div className="flex-row" onClick={() => fkn_uds() }>
                                <div className="web-top-usr d-flex flex-column px-3">
                                    <div className="txt_12">VÄLJ ENHET</div>
                                </div>
                                <div className="web-top-usr d-flex flex-column px-3">
                                    <div className="item-sys">{ ddo.cache.avd }</div>
                                    <div className="item-fkn">{ utils.toLang(ddo.cache.avdtxt) }</div>
                                </div>
                            </div>
                            : null
                        }
                        { ddo.cache.auth == "09" ?
                            <div className="web-top-usr d-flex flex-column px-3" onClick={() => show_avd() }>
                                <div className="item-sys">{ ddo.cache.avd }</div>
                                <div className="item-fkn">{ utils.toLang(ddo.cache.avdtxt) }</div>
                            </div>
                            : null
                        }
                        { ddo.cache.auth == "39" ?
                            <div className="flex-row" onClick={() => fkn_gtag() }>
                                <div className="web-top-usr d-flex flex-column px-3">
                                    <div className="txt_12">VÄLJ ENHET</div>
                                </div>
                                <div className="web-top-usr d-flex flex-column px-3">
                                    <div className="item-sys">{ ddo.cache.avd }</div>
                                    <div className="item-fkn">{ utils.toLang(ddo.cache.avdtxt) }</div>
                                </div>
                            </div>
                            : null
                        }
                        { ddo.cache.auth == "59" ?
                            <div className="web-top-usr d-flex flex-column px-3" onClick={() => show_avd() }>
                                <div className="item-sys">{ ddo.cache.avd }</div>
                                <div className="item-fkn">{ utils.toLang(ddo.cache.avdtxt) }</div>
                            </div>
                            : null
                        }
                        <div className="flex-fill"></div>
                    </div>
                    : null
                }
                { ddo.cache.org == "KK" ?
                <div className="flex-row flex">
                 <div className="flex-fill"></div>
               { props.store.seo.cs > 0 ?
                    <div className="" onClick={() => fkn_cs() }>
                        <div className="">TYP: { sso.seo.cs }</div>
                        <div className="">ENHET: { sso.seo.sid }</div>
                    </div>
                    : null
                }
                { ddo.cache.auth == "05" ?
                    <div className="flex-row">
                    <div className="flex-row" onClick={() => fkn_sids() }>
                        <div className="web-top-usr d-flex flex-column px-3">
                            <div className="txt_12">VÄLJ FÖRVALTNING</div>
                        </div>
                        <div className="web-top-usr d-flex flex-column px-3">
                            <div className="item-sys">{ ddo.cache.sidtxt }</div>
                            <div className="item-fkn"></div>
                        </div>
                    </div>
                    <div className="flex-row" onClick={() => fkn_uds() }>
                        <div className="web-top-usr d-flex flex-column px-3">
                            <div className="txt_12">VÄLJ ENHET</div>
                        </div>
                        <div className="web-top-usr d-flex flex-column px-3">
                            <div className="item-sys">{ ddo.cache.avd }</div>
                            <div className="item-fkn">{ utils.toLang(ddo.cache.avdtxt) }</div>
                        </div>
                    </div>
                    </div>
                    :
                    <div className="web-top-usr d-flex flex-column px-3" onClick={() => show_avd() }>
                        <div className="item-sys">Förvaltning</div>
                        <div className="item-fkn">{ utils.toLang(ddo.cache.sidtxt) }</div>
                    </div>
                 }
                { ddo.cache.auth == "09" ?
                    <div className="flex-row" onClick={() => fkn_uds() }>
                        <div className="web-top-usr d-flex flex-column px-3">
                            <div className="txt_12">VÄLJ ENHET</div>
                        </div>
                        <div className="web-top-usr d-flex flex-column px-3">
                            <div className="item-sys">{ ddo.cache.avd }</div>
                            <div className="item-fkn">{ utils.toLang(ddo.cache.avdtxt) }</div>
                        </div>
                    </div>
                    : null
                }
                { ddo.cache.auth == "39" ?
                    <div className="flex-row" onClick={() => fkn_gtag() }>
                        <div className="web-top-usr d-flex flex-column px-3">
                            <div className="txt_12">VÄLJ ENHET</div>
                        </div>
                        <div className="web-top-usr d-flex flex-column px-3">
                            <div className="item-sys">{ ddo.cache.avd }</div>
                            <div className="item-fkn">{ utils.toLang(ddo.cache.avdtxt) }</div>
                        </div>
                    </div>
                    : null
                }
                { ddo.cache.auth == "59" ?
                    <div className="web-top-usr d-flex flex-column px-3" onClick={() => show_avd() }>
                        <div className="item-sys">{ ddo.cache.avd }</div>
                        <div className="item-fkn">{ utils.toLang(ddo.cache.avdtxt) }</div>
                    </div>
                    : null
                }

                <div className="flex-fill"></div>


                { sso.seo.cs == 2 ?
                    <div className="web-top-usr d-flex flex-column px-3" onClick={() => fkn_syssel() }>
                        <div className="item-sys">System</div>
                        <div className="item-fkn">{ sso.sdo.stxt }</div>
                    </div>
                    : null
                }
                { ddo.cache.auth == "05" ?
                    <div className="web-top-usr d-flex flex-column px-3" onClick={() => fkn_support() }>
                        <div className="item-sys">Ärenden</div>
                        <div className="item-fkn text-center">{ sso.sdo.support }</div>
                    </div>
                    : null
                }
                { ddo.cache.auth == "09" ?
                    <div className="web-top-usr d-flex flex-column px-3" onClick={() => fkn_support() }>
                        <div className="item-sys">Ärenden</div>
                        <div className="item-fkn text-center">{ sso.sdo.support }</div>
                    </div>
                    : null
                }
                </div>
                : null
                }

                <div className="web-top-usr d-flex flex-column" onClick={() => fknGo("/tpl") }>
                    <div><FontAwesomeIcon icon="user" size="1x"/> { sso.seo.name }</div>
                    <div><FontAwesomeIcon icon="building" size="1x"/> { sso.seo.company }</div>
                </div>
                <div className="web-top-sep"></div>
                <div className="">
                    <Dd_Sel items={ selitems } cls="web-top-icon" getValue={(e)=> sel_fkn(e)} />
                </div>
                <div className="">
                </div>
            </div>

        </div>
        <Tmpl_Setup wnds={state.wnds} cb={wnds_close} />
        <Tmpl_Ses wnds={state.wnds} cb={wnds_close} />
        <Tmpl_Manager wnds={state.wnds} cb={wnds_close} />
        <Tmpl_Sids wnds={state.wnds} store={props.store} cb={wnds_close} />
        <Tmpl_Uds wnds={state.wnds} store={props.store} cb={wnds_close} />
        { state.wnds.gtag ? <Tmpl_Gtag wnds={state.wnds} store={props.store} cb={wnds_close} /> : null }
        { state.wnds.support ? <Tmpl_Support wnds={state.wnds} store={props.store} cb={wnds_close} /> : null }
        </div>
    ;
    return html;
}

export default observer(Tmpl_Top);
