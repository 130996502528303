import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../../lib/net";
import * as utils from "../../../assets/utils";

import Fr_Input from "../../../lib/ui/fr_input";

import Wnd_Error from "../../../lib/ui/wnd_error";
import Wnd_Ok from "../../../lib/ui/wnd_ok";
import Wnd_Info from "../../../lib/ui/wnd_info";
import Wnd_Confirm from "../../../lib/ui/wnd_confirm";

import Prd_Add from "./mnu_prd_add";
import Prd_Edit from "./mnu_prd_edit";

class Md_Edit extends React.Component {
  constructor(props, context) {
    super(props);

      this.store = props.store;
    ///this.pos = props.store.pos;

    this.state = {};
    this.state.mpa = [];

    this.state.gnr = "001";
    this.state.ynq = [
                {id: '0', text: 'NEJ'},
                {id: '1', text: 'JA'}
            ];
      this.state.mtval = [
          {id: 'bas', text: 'BAS MENY'},
          {id: 'xtra', text: 'EXTRA MENY'}
      ];

      this.state.tabval = 0;
      this.state.selrow = {};
      this.state.msg = {};
      this.state.wnds = {};

      this.state.mtyp = "";
      this.state.mavd = "01";
      this.state.mnuid = "";
      this.state.title = "MENYDATA";
      this.state.mno = {};
      this.state.mdo = {};
      //if(props.wnds.mdo) this.state.mdo = props.wnds.mdo;
      if(props.wnds) {
          if(props.wnds.mno) {
              this.state.mno = props.wnds.mno;
              //this.state.mavd = this.state.mno.avd;
              this.state.mnuid = this.state.mno.mnuid;
          }
          if(props.wnds.mdo) {
              this.state.mdo = JSON.parse(JSON.stringify(props.wnds.mdo));
              this.state.mdo.m0text = utils.toLang( this.state.mdo.m0text );
              this.state.mdo.m0pris = utils.toPris( this.state.mdo.m0pris );
              this.state.mtyp = this.state.mdo.m0typ;
          }
      }
      //alert(JSON.stringify(this.state.mdo));

      if(this.state.mtyp == "E") this.state.title = "EXTRA INGREDIENS";
      if(this.state.mtyp == "T") this.state.title = "TILLBEHÖR";
      if(this.state.mtyp == "S") this.state.title = "SIDEORDER";
      if(this.state.mtyp == "D") this.state.title = "DRYCK";
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      if(!this.props.wnds.mdo) this.md_init();
  }

    md_init() {
        var mdo = {};
        mdo.m0typ = this.state.mtyp;
        mdo.m0kstns = this.state.mavd;
        mdo.m0katnr = "";
        mdo.m0ingnr = "";
        mdo.m0text = "";
        mdo.m0pris = "";
        mdo.m0moms = "";
        mdo.m0debcre = "";
        mdo.m0sort = "";
        //alert(JSON.stringify(rso));
        this.setState({ mdo: mdo });
        return;
    }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    tb_style() {
        var css = {};
        css.height = (window.innerHeight * 0.9) - 275;
        css.overflow = "auto";
        return css;
    }
    th_style(id) {
        var css = {};
        css.cursor = "pointer";
        if(id == this.state.sortid) {
            if(this.state.sortdir) css.color = "#800";
            else css.color = "#080";
        }
        return css;
    }
    row_style(row) {
        var srow = this.state.selrow;

        var css = {};
        if(srow == row) {
            css.background = "#080";
            css.color = "#fff";
        }
        return css;
    }
    tab_head(tabid) {
        var css = {};
        css.background = "#fff";
        css.color = "#000";
        if(this.state.tabval === tabid) {
            css.background = "#080";
            css.color = "#fff";
        }
        return css;
    }
    tab_style(tabid) {
        var css = {};
        css.display = "none";
        if(this.state.tabval === tabid) css.display = "block";
        return css;
    }
    tab_sel(tabid) {
      var self = this;
        this.setState({tabval: tabid}, function() {
            if(tabid == 1) {
                self.syncTb("mnpTable");
            }
        });
    }

    wnd_close = () => {
        if(this.props.close) {
            this.props.close(this.props.ctx);
        }
        else this.props.ctx.setState({ wnds: {} });
    };
    anm_change (e) {
        const value = e.target.value;
        var mdo = this.state.mdo;
        mdo.anm = value;
        this.setState({mdo: mdo});
    }

    val_save(id, val) {
        var mdo = this.state.mdo;
        mdo[id] = val;
        this.setState({ mdo: mdo });
        return;
    }
    sel_row(row) {
        var srow = this.state.selrow;
        if(srow == row) {
            this.setState({selrow: {}});
        }
        else this.setState({selrow: row});
        this.syncTb("mnpTable");
    }
    sel_row_fkn(row, sel) {
        if(sel.fkn == "open") {
        }
    }
    syncTb(tb) {
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
            tdElements = tbel.getElementsByTagName('td');
        if(tdElements.length < 1 ) return;

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
                width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
    tb_sort(typ) {
        var self = this;
        //var rvs = self.state.rvs;
        var rva = self.state.rva;
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;

        if(sortid != typ) sortdir = 0;
        else {
            if(sortdir) sortdir = 0;
            else sortdir = 1;
        }

        if(typ == "ank") {
            if(sortdir) rva.sort((a,b) => (a.gsb00.r0ankdatum < b.gsb00.r0ankdatum) ? 1 : ((b.gsb00.r0ankdatum < a.gsb00.r0ankdatum) ? -1 : 0));
            else rva.sort((a,b) => (a.gsb00.r0ankdatum > b.gsb00.r0ankdatum) ? 1 : ((b.gsb00.r0ankdatum > a.gsb00.r0ankdatum) ? -1 : 0));
        }
        self.setState({ rva: rva, sortid: typ, sortdir: sortdir });
    }
    th_icon(typ) {
        var cls = "";
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;
        if(sortid == typ)
        {
            if(sortdir) cls = "fa fa-caret-up";
            else cls = "fa fa-caret-down";
        }
        return cls;
    }
  md_upd() {
    var self = this;

    var mdo = this.state.mdo;
    mdo.m0pris = mdo.m0pris.pris2cdi();

    var prm = {};
    prm.req = "pos.pos_mnudata.md_upd";
    prm.mdo = mdo;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));
        if(ret.ok == "000") {
            self.setState({ msg: {ok: true, text: "Sparad ..."} });
            setTimeout(function(){
                self.setState({msg: {} });
                  if(self.props.wnds.cb) {
                      self.props.wnds.cb(self.props.ctx);
                  }
                  else self.props.ctx.setState({ wnds: {} });
                return;
            }, 1000);
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
    md_del() {
        var self = this;
        var prm = {};
        prm.req = "pos.pos_mnudata.md_del";
        prm.mdo = this.state.mdo;

        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret));
            if(ret.ok == "000") {
                self.setState({ msg: {ok: true, text: "Sparad ..."} });
                setTimeout(function(){
                    self.setState({msg: {} });
                    if(self.props.wnds.cb) {
                        self.props.wnds.cb(self.props.ctx);
                    }
                    else self.props.ctx.setState({ wnds: {} });
                    return;
                }, 1000);
            }
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }

  render() {
    //if(!this.props.show) return "";
    var title = this.props.title;
    if(!title) title = "Resurs";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        <div className="mx_backdrop" />
        <div className="mx_dlg mx_70 flex-col" style={ this.dlg_style() } >
        <div className="mx_content flex">

        <div className="mx_h01">
            <div>{ this.state.title } : {this.state.mdo.m0lopnr}</div>
        </div>
        <div className="divider_orange"></div>

            <form className="cdi_f03">
                <div className="flex-row">
                    <Fr_Input label="Meny ID" className="text-left w150" text={this.state.mdo.m0mnuid} getValue={(e)=> this.val_save("m0mnuid", e) } />
                    <Fr_Input label="Status" className="text-left w150" text={this.state.mdo.status} getValue={(e)=> this.val_save("status", e) } />
                </div>
                <div className="divider_green"></div>
                <div className="flex-row">
                    <Fr_Input label="Katnr" className="text-left w150" text={this.state.mdo.m0katnr} getValue={(e)=> this.val_save("m0katnr", e) } />
                </div>
                <div className="flex-row">
                    <Fr_Input label="Text" className="text-left w350" text={ this.state.mdo.m0text } getValue={(e)=> this.val_save("m0text", e) } />
                </div>
                <div className="flex-row">
                    <Fr_Input label="Pris" className="text-left w250" text={ this.state.mdo.m0pris } getValue={(e)=> this.val_save("m0pris", e) } />
                </div>
                <div className="flex-row">
                    <Fr_Input label="Sortering" className="text-left w150" text={this.state.mdo.m0sort} getValue={(e)=> this.val_save("m0sort", e) } />
                    <Fr_Input label="Debit/Kredit" className="text-left w150" text={this.state.mdo.m0debcre} getValue={(e)=> this.val_save("m0debcre", e) } />
                </div>

                <div className="divider_green"></div>
                <div className="flex-row hide">
                    <div className="flex flex-column" style={{padding: "0px 5px"}}>
                        <span className="header">Meny information</span>
                        <textarea className="form-control" maxLength="512" rows="10" value={this.state.mdo.anm} onChange={(e) => this.tdata_change(e)}></textarea>
                    </div>
                </div>
            </form>

        </div>
        <div className="mx_foot flex-row">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex"></div>
            <button className="btn btn-hw-orange w250" onClick={() => this.md_del() } >
                TA BORT
            </button>
            <div className="flex"></div>
            <button className="btn btn-hw-green w250" onClick={() => this.md_upd() } >
                SPARA
            </button>
        </div>
      </div>

        { this.state.wnds.prdadd ? <Prd_Add wnds={this.state.wnds} store={this.store} ctx={this} /> : null}
        { this.state.wnds.prdedit ? <Prd_Edit wnds={this.state.wnds} store={this.store} ctx={this} /> : null}

        <Wnd_Error msg={this.state.msg} ctx={this} />
        <Wnd_Ok msg={this.state.msg} ctx={this} />
        <Wnd_Info msg={this.state.msg} ctx={this} />
        <Wnd_Confirm msg={this.state.msg} ctx={this} />
      </div>
	;
	return html;
  };
}

export default Md_Edit;