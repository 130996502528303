import React, { useState, useEffect, useContext } from 'react';
import { Route, Routes } from "react-router-dom";
import {format, addDays, subDays, getHours} from "date-fns";

import { observer } from "mobx-react";
import {withStore} from "../sdo";
import {withRouter} from "../lib/react/withRouter";

import './css/pms.css';
import './css/rr.css';

import Pms_Top from './com/pms_top.js';
import Pms_Head from './com/pms_head.js';
import Pms_Foot from './com/pms_foot.js';
import Pms_Mnu from './com/pms_mnu.js';
import Pms_Home from './home.js';
import Pms_Dash from './dash.js';
import Pms_Dash_11 from './dash_11.js';
import Pms_Dash_21 from './dash_21.js';
import Pms_OvCal from './ov/ov_cal.js';
import Pms_OvRt from './ov/ov_rt.js';

import Pms_Fo from './fo.js';
import Pms_Bel from './bel.js';
import Pms_Op from './op.js';
import Pms_Reg from './reg.js';
import Pms_Rst from './rst.js';
import Pms_Fkn from './fkn.js';
import Pms_Eko from './eko.js';
import Pms_Rp from './rp.js';
import Pms_Bo from './bo.js';

import Pms_FbRvo from "./rv/fb/rvo.js";
import Pms_Gso from './rv/gst/gso.js';

import Pms_Sz from './wrk/sz.js';
import Pms_Petty from './op/petty/petty.js';
import Pms_Rr from './bel/rr/rr.js';
import Pms_Rrp from './bel/rr/rrp.js';

import Pms_Stat_Wnd from './wrk/stat_wnd.js';
import Pms_Acs_Wnd from './wrk/acs_wnd.js';
import Pms_Bel_Wnd from './wrk/bel_wnd.js';
import Pms_Term_Wnd from './wrk/term.js';

import * as net from "../lib/net";
import sso from "../sso";

import Tiden from "../lib/ui/tiden";
import Dd_Sel from "../lib/ui/dd_sel";
import Wnd_Sign from "../lib/ui/wnd_sign";
import Pms_Nota from "./op/nota/nota";
import ddo from "../ddo";
import Pms_Hotback from "./hotback/hotback";
import Pms_Hotreg from "./hotreg/hotreg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function web01() {

    var html =
    <div className="web-content">
    </div>
    ;
    return html;
}

class Pms extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.selitems = [
        {fkn: 'setup', text: 'INSTÄLLNINGAR'},
        {fkn: 'upd', text: 'UPPDATERA'},
        {fkn: 'sys', text: 'SYSTEM'},
        {fkn: 'logout', text: 'LOGGA UT'}
    ];
    this.btmitems = [
        {fkn: 'kreg', text: 'KUNDREGISTER'},
        {fkn: 'logg', text: 'LOGG'},
        {fkn: 'hist', text: 'HISTORIK'}
    ];
    this.langitems = [
        {fkn: 'sv', text: 'SVENSKA'},
        {fkn: 'no', text: 'NORSKA'},
        {fkn: 'en', text: 'ENGELSKA'}
    ];

    this.store = props.store;
    this.state = {};
    this.state.deps = [];
    this.state.msg = {};
    this.state.wnds = {};
    this.state.boknr = "";
    this.state.lang = "sv";
    this.state.today = new Date();
    var hh = format(this.state.today, "hh");
    if(hh < 5) this.state.today.setDate(this.state.today.getDate() - 1);
    this.state.datum = format(this.state.today, "yyyyMMdd");

  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {

    var pms = {};
      pms.rta = [];
      pms.rto = {};
      pms.pka = [];
      pms.pko = {};
    pms.stat = {};
    this.props.store.pms = pms;
    //alert(JSON.stringify(this.props.store.seo));
    this.pms_init();
  }

    pms_init() {
        var self = this;

        var req = {};
        req.req = "pms.fkn.pms_init";

        net.sio_req(req)
        .then(function(ret){
            //alert(JSON.stringify(ret));

            if(!self.props.store.pms) self.props.store.pms = {};
            //self.props.store.pms.rts = ret.rco.rts;
            //self.props.store.pms.pkats = ret.rco.pkats;
            self.props.store.pms.rto = ret.rco.rto;
            self.props.store.pms.rta = ret.rco.rta;
            self.props.store.pms.pko = ret.rco.pko;
            self.props.store.pms.pka = ret.rco.pka;
            self.props.store.pms.pka.sort((a,b) => (a.n0sort < b.n0sort) ? 1 : ((b.n0sort < a.n0sort) ? -1 : 0));
            //alert(JSON.stringify(self.props.store.pms.pka));
            //self.setState({ deps: ret.rco });
            self.st_state();

        })
        .catch(function(e){
            var txt = JSON.stringify(e);
            //alert(JSON.stringify(txt));
        })
    }
    st_state() {
        var self = this;

        var prm = {};
        prm.req = "pms.cal.pms_dt_state";
        prm.datum = this.state.datum;

        net.sio_req(prm)
        .then(function(ret){
            self.props.store.pms.stat = ret.rco;
            //ddo.cache.pms = {};
            ddo.cache.pms.stat = ret.rco;
        })
        .catch(function(e) {
            var txt = JSON.stringify(e);
        });
    }

    sz_open() {
        var self = this;
        var msg = { info: false, sign: true, text: "Signatur?", text0: "JA", text1: "NEJ", cb: "sz_open_do" };
        self.setState({ msg: msg });
    }
    sz_open_do(signatur) {
        var self = this;
        var wnds = {};
        wnds.szw = 1;
        this.setState({ msg: {}, wnds: wnds });
    }

    rvo_edit(boknr, rvo) {
        var wnds = {};
        wnds.rvo = true;
        wnds.boknr = boknr;
        wnds.rvo = rvo;
        this.setState({wnds: wnds});
    }
    rvn_open() {
        ddo.store.tst = "456";
        var self = this;
        var msg = { info: false, sign: true, text: "Signatur?", text0: "JA", text1: "NEJ", cb: "rvn_open_do" };
        self.setState({ msg: msg });
    }
    rvn_open_do(signatur) {
        var self = this;
        var wnds = {};
        wnds.rvn = 1;
        wnds.cb = ddo.store.cb;
        this.setState({ msg: {}, wnds: wnds });
    }
    rr_open() {
        var self = this;
        var wnds = {};
        wnds.rr = 1;
        this.setState({ wnds: wnds });
    }
    rrp_open() {
        var self = this;
        var wnds = {};
        wnds.rrp = 1;
        this.setState({ wnds: wnds });
    }
    petty_open() {
        var self = this;
        var wnds = {};
        wnds.petty = 1;
        this.setState({ wnds: wnds });
    }
    fknGo(url) {
        //alert(row.href);
        this.props.navigate(url);
    }
    sel_fkn(sel) {
        //alert(JSON.stringify(fkn));
        if(sel.fkn == "logout") {
        }
    }
    sel_btm(sel) {
        //alert(JSON.stringify(fkn));
        if(sel.fkn == "kreg") this.fknGo("/pms/fo/kreg")
        if(sel.fkn == "logg") this.fknGo("/pms/fkn/logg")
    }
    sel_lang(sel) {
        //alert(JSON.stringify(fkn));
        if(sel.fkn == "sv") {
            this.props.xstore.setLang("sv");
        }
        if(sel.fkn == "no") {
            this.props.xstore.setLang("no");
        }
        if(sel.fkn == "en") {
            this.props.xstore.setLang("en");
        }
    }

  render() {
      var hash = window.location.hash;
      if(hash == "#red") {
          return (
          <Routes>
              <Route path="hotback/*" element={ <Pms_Hotback store={this.props.store} />} />
              <Route path="hotreg/*" element={ <Pms_Hotreg store={this.props.store} />} />
              <Route path="*" element={ <Pms_OvCal store={this.props.store} />} />
          </Routes>
          );
      }

    return (
    <div className="web-app">
        <div className="web-top">
            <Pms_Top ctx={this} store={this.props.store} />
        </div>

    <div className="web-main">

    <div className="web-row" style={{ height:"100%" }}>

    { this.props.store.cfg.mnu_show ?
    <div className="web-side">
        <Pms_Mnu store={this.props.store} />
    </div>
    : null }

    <div className="web-body px-1">
        <Routes>
            <Route path="fo/*" element={ <Pms_Fo store={this.props.store} />} />
            <Route path="bel/*" element={ <Pms_Bel store={this.props.store} />} />
            <Route path="op/*" element={ <Pms_Op store={this.props.store} />} />
            <Route path="eko/*" element={ <Pms_Eko store={this.props.store} />} />
            <Route path="reg/*" element={ <Pms_Reg store={this.props.store} />} />
            <Route path="rst/*" element={ <Pms_Rst store={this.props.store} />} />
            <Route path="fkn/*" element={ <Pms_Fkn store={this.props.store} />} />
            <Route path="rp/*" element={ <Pms_Rp store={this.props.store} />} />
            <Route path="bo/*" element={ <Pms_Bo store={this.props.store} />} />
            <Route path="home" element={ <Pms_Home store={this.props.store} />} />
            <Route path="dash" element={ <Pms_Dash store={this.props.store} />} />
            <Route path="stat" element={ <Pms_Dash_11 store={this.props.store} />} />
            <Route path="ov" element={ <Pms_OvCal store={this.props.store} />} />
            <Route path="*" element={ <Pms_Dash_21 store={this.props.store} />} />
        </Routes>
    </div>

    </div>

    </div>
    <div className="">
        <Pms_Foot ctx={this} store={this.props.store} />
    </div>

        { sso.wnds.hotback ?
            <Pms_Hotback boknr={this.state.boknr} wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
        : null }
        { sso.wnds.hotreg ?
            <Pms_Hotreg boknr={this.state.boknr} wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
        : null }

        { sso.wnds.rvn ?
            <Pms_FbRvo boknr={this.state.boknr} wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
        : null }
        { sso.wnds.petty ?
            <Pms_Petty show={sso.wnds.petty} ctx={this} store={this.props.store} backdrop={true}/>
        : null }
        { sso.wnds.szw ?
            <Pms_Sz show={sso.wnds.szw} ctx={this} store={this.props.store} backdrop={true}/>
        : null }
        { sso.wnds.rr ?
            <Pms_Rr show={this.state.wnds.rr} ctx={this} store={this.props.store} backdrop={true}/>
        : null }

       { this.state.wnds.szw ?
            <Pms_Sz show={this.state.wnds.szw} ctx={this} store={this.props.store} backdrop={true}/>
        : null }
        { this.state.wnds.rvo ?
            <Pms_FbRvo boknr={this.state.boknr} wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
        : null }
        { this.state.wnds.rvn ?
            <Pms_FbRvo boknr={this.state.boknr} wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
        : null }

        { this.state.wnds.nota ? <Pms_Nota wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.wnds.rrp ?
        <Pms_Rrp show={this.state.wnds.rrp} ctx={this} store={this.props.store} backdrop={true}/>
        : null }

        { this.state.wnds.stat ?
        <Pms_Stat_Wnd show={this.state.wnds.stat} ctx={this} store={this.props.store} backdrop={true}/>
        : null }
        { this.state.wnds.acs ?
        <Pms_Acs_Wnd show={this.state.wnds.acs} ctx={this} store={this.props.store} backdrop={true}/>
        : null }
        { this.state.wnds.bel ?
        <Pms_Bel_Wnd show={this.state.wnds.bel} ctx={this} store={this.props.store} backdrop={true}/>
        : null }
        { this.state.wnds.term ?
        <Pms_Term_Wnd show={this.state.wnds.term} ctx={this} store={this.props.store} backdrop={true}/>
        : null }
        { this.state.msg.sign ? <Wnd_Sign msg={this.state.msg} ctx={this} /> : null }
    </div>
  );
}
}
//Sys.contextType = ADOContext;
export default withRouter(withStore(observer(Pms)));

/*

        { this.state.wnds.rvox ?
            <Pms_FbRvo boknr={this.state.boknr} wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
            : null }
        { this.state.wnds.rvny ?
        <Pms_FbRvn wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
        : null }
        { this.state.wnds.rvnx ?
        <Pms_Rvn show={this.state.wnds.rvn} ctx={this} store={this.props.store} backdrop={true}/>
        : null }


        <div className="web-foot-rmnu" onClick={() => this.sz_open() }>
            <MDBIcon icon="building" size="1x"/>
        </div>
        <div className="web-foot-mnu" onClick={() => this.fknGo("/pms/fkn/logg") }>
            <MDBIcon icon="undo" size="1x"/>
        </div>

          <Route path="/xx" component={pg_s1} />
          <Route path="/pms/yy" component={pg_s2} />

            <div className="web-foot-btn" onClick={() => this.rvn_open() }>BOKA</div>
            <div className="web-foot-btn" onClick={() => this.fknGo("/pms/fkn/logg") } >
                Logg
            </div>
 
 
 
         <div className="web-top-sys">
            <Pms_Top ctx={this} store={this.props.store} />
        </div>
        <div className="web-top-sub">
            <Pms_Head ctx={this} store={this.props.store} />
        </div>
           */