import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";
import {withRouter} from "../../lib/react/withRouter";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Cas_Bo extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, tra: [] };
    this.state.seldate = new Date();
    this.state.avd = "01";
    this.state.paa = [];
    this.state.aa = [];
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 480;
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

  plu_open(row) {
      var self = this;
  }
  plu_edit(row) {
      var self = this;
      alert(JSON.stringify(row.plu00));
  }

  fknGo(url) {
    //alert(row.href);
    this.props.navigate(url);
  }

  render() {
    return (
    <div className="web-app">
    <h3>BACKOFFICE</h3>

    <div className="web-p01 flex">

    <div className="web-row">
    <div className="web-col flex">
        <div className="web-btn" onClick={() => this.fknGo("/cas/bo/ftg") } >
            <div>FÖRETAG</div>
            <div>Företags parametrar</div>
        </div>
        <div className="web-btn" onClick={() => this.fknGo("/cas/bo/konto") } >
            <div>KONTOPLAN</div>
            <div>Konton</div>
        </div>
    </div>
     <div className="web-col flex" style={{padding: "0px 10px"}}>
        <div className="web-btn" onClick={() => this.fknGo("/cas/bo/units") } >
            <div>ENHETER</div>
            <div>Företagets Enheter</div>
        </div>
   </div>
     <div className="web-col flex">
        <div className="web-btn" onClick={() => this.fknGo("/cas/bo/marker") } >
            <div>MARKER</div>
            <div>Marker valörer</div>
        </div>
   </div>
   </div>

    </div>

    </div>
  );
    }
}
export default withRouter(Cas_Bo);
