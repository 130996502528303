import React, { useState, useEffect, useContext } from 'react';
import {Route, Routes} from "react-router-dom";

import Wrk_Home from './wrk/home.js';
import Wrk_Plan from './wrk/plan.js';
import Wrk_Tasks from './wrk/tasks.js';
import Wrk_Board from './wrk/board.js';
import Wrk_Sup from './wrk/sup.js';
import Wrk_Complete from './wrk/complete.js';
import Wrk_Hist from './wrk/hist.js';
import {withRouter} from "../lib/react/withRouter";
import sso from "../sso";

function pg_home() {

    var html =
    <div className="web-content">
      Hem
    </div>
    ;
    return html;
}

class Bcs_Wrk extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.state = {};
  }
    fknGo(row) {
        //alert(row.href);
        sso.seo.sfkn = row;
        this.props.navigate(row.url);
    }
    bread_style(row) {
        var css = {};
        if(row.active) css.color = "#ff9900";
        return css;
    }
    br_css(row) {
        var css = {};
        if(sso.seo.sfkn && (row.url == sso.seo.sfkn.url)) css.color = "#080";
        //var brc = false;
        //if(row.url == sbo.state.url) brc = true;
        return css;
    }

  render() {
      var path = window.location.pathname;
      if(path.substr(-1) == "/") path = path.substring(0, path.length - 1);
      var op = path.split("/").pop();
      var po = {};
      po.plan = { "fid": "f01", "url": "/bcs/wrk/plan", "name": "Plan", "active": false };
      po.tasks = { "fid": "f01", "url": "/bcs/wrk/tasks", "name": "Tasks", "active": false };
      po.board = { "fid": "f01", "url": "/bcs/wrk/board", "name": "Onboarding", "active": false };
      po.sup = { "fid": "f01", "url": "/bcs/wrk/sup", "name": "Support", "active": false };
      po.complete = { "fid": "f01", "url": "/bcs/wrk/complete", "name": "Slutför", "active": false };
      po.hist = { "fid": "f01", "url": "/bcs/wrk/hist", "name": "Historik", "active": false };
      if(po[op]) {
          po[op].active = true;
          this.props.store.fkn = po[op];
      }
      var pa = Object.values(po);

    return (

    <div className="web-app">
         { this.props.store.cfg.bread ?
             <div className="web-bread">
                 <div className="breadcrumb">
            { pa.map((row, key) =>
                <div key={key} className="breadcrumb-item" style={this.br_css(row)} onClick={() => this.fknGo(row)}>{row.name}</div>
            )}
            <div className="flex"></div>
            <div className="cdigreen">{ this.props.store.fkn ? this.props.store.fkn.name : null }</div>
            </div>
        </div>
        : null }
        <Routes>
          <Route path="/xx" component={pg_home} />
            <Route path="plan" element={ <Wrk_Plan store={this.store} />} />
            <Route path="tasks" element={ <Wrk_Tasks store={this.store} />} />
            <Route path="board" element={ <Wrk_Board store={this.store} />} />
            <Route path="sup" element={ <Wrk_Sup store={this.store} />} />
            <Route path="complete" element={ <Wrk_Complete store={this.store} />} />
            <Route path="hist" element={ <Wrk_Hist store={this.store} />} />
            <Route path="*" element={ <Wrk_Home store={this.store} />} />
        </Routes>
    </div>

  );
}
}
//Sys.contextType = ADOContext;

export default withRouter(Bcs_Wrk);
