import React, { useState, useEffect, useContext } from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import { observer } from 'mobx-react'

import SDOContext from '../sdo.js'

import Sys_Top from './com/sys_top.js';
import Sys_Mnu from './com/sys_mnu.js';
import Sys_Foot from './com/sys_foot.js';

import Sys_Home from './home.js';
import Sys_Dash from './dash.js';
import Sys_Gms from './gms.js';
import Sys_Kids from './kids.js';
import Sys_Sids from './sids.js';
import Sys_Srvs from './srvs.js';
import Sys_Terms from './terms.js';
import Sys_Bo from './bo.js';

import * as net from "../lib/net";
import Tiden from "../lib/ui/tiden";
import {withRouter} from "../lib/react/withRouter";
import Gym_Door from "../gym/door";


class Sys extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pos = props.store.pos;
    this.state = { pgid: 11, mnu_show: true, deps: [] };
    //context.posrend = this;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

  fknGo(url) {
    //var sdo = useContext(SDO);
    //alert(fkn);
    //var fkn = row.fid;
    //setState({ row : row,  ma : [] });
    //ctx.fid = fkn;

    //props.history.push(row.url);
    this.props.navigate(url);
   };

  ddo() {
    //alert("POS" + this.state.pgid );
    //this.setState({ status: 1 });
  }


  render() {
    return (
    <div className="web-app">
    <div className="web-top">
        <Sys_Top store={this.store} />
    </div>
    <div className="web-main">

    <div className="web-row" style={{ height:"100%" }}>

    { this.props.store.cfg.mnu_show ?
    <div className="web-side">
      <Sys_Mnu store={this.store} />

    </div>
    : null }

    <div className="web-body">
          <Routes>
          <Route path="kids/*" element={ <Sys_Kids store={this.store} />} />
          <Route path="sids/*" element={ <Sys_Sids store={this.store} />} />
          <Route path="srvs/*" element={ <Sys_Srvs store={this.store} />} />
          <Route path="terms/*" element={ <Sys_Terms store={this.store} />} />
          <Route path="bo/*" element={ <Sys_Bo store={this.store} />} />
          <Route path="gms" element={ <Sys_Gms store={this.store} />} />
          <Route path="home" element={ <Sys_Home store={this.store} />} />
          <Route path="*" element={ <Sys_Dash store={this.store} />} />
          </Routes>
    </div>

    </div>

    </div>
      <Sys_Foot store={this.store} />


    </div>
  );
}
}
//Sys.contextType = ADOContext;

export default withRouter(observer(Sys));
