import React, { useState, useEffect, useContext } from 'react';
import {addDays, format} from "date-fns";

import * as net from "../../lib/net";

import Dt_Cal from "../../lib/ui/dt_cal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class PMS_BelPk extends React.Component {
  constructor(props, context) {
    super(props);

    this.dt_fd = React.createRef();
    this.dt_td = React.createRef();

    this.webStore = props.store;
    this.state = { pgid: 11, tra: [] };
    this.state.avd = "01";
    this.state.rsa = [];
    this.state.dta = [];
    this.state.kro = {};
    this.state.aa = [
                {id: '01', text: 'AVD 01'},
                {id: '02', text: 'AVD 02'}
            ];
    this.state.cols = {};
    this.state.rtdata = [];
    this.state.loading = true;

    this.state.today = new Date();
    this.state.fdat = format(this.state.today, "yyyy-MM-dd");
    var tdat = addDays(this.state.today, 15);
    this.state.tdat = format(tdat, "yyyy-MM-dd");
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.bel_kf();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

  dt_kf(row, dto) {
      var self = this;
      var txt = "";

      var rs = row.restname.trim();
      if(dto.rs[rs]) {
          var o = dto.rs[rs];
          //alert(JSON.stringify(dto));
          txt = o.status;
      }
      return txt;
  }
  kund_edit(row) {
      var self = this;
      //alert(JSON.stringify(row.krr00));
        this.setState({edit: 1, kro: row});
  }
  fkn_close() {
    this.setState({edit: false });
    //this.props.history.push("cas");
    //this.login_srv(uid, pwd);
  }

  bel_kf() {
    var self = this;

    var prm = {};
    prm.req = "pms.rs.rs_per_dt";
    prm.fdat = this.state.fdat;
    prm.tdat = this.state.tdat;
    //prm.avd = this.state.avd;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        self.setState({rsa: ret.rco.rsa, dta: ret.rco.rca, loading: false });
        //self.syncCols("rvpTable");
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e) {
        var txt = JSON.stringify(e);
    });
  }
  dt_fdat(e) {
    //alert(JSON.stringify(e));
    var dts = format(e, "yyyy-MM-dd");
    this.setState({ fdat: dts });
  }
  dt_tdat(e) {
      //alert(JSON.stringify(e));
    var dts = format(e, "yyyy-MM-dd");
    this.setState({ tdat: dts });
  }
  oneday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_td.current.updateDt(this.state.fdat);
  }
  addday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_fd.current.updateAdd();
    this.dt_td.current.updateAdd();
  }
  subday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_fd.current.updateSub();
    this.dt_td.current.updateSub();
  }
  selrt(row) {
  }

  render() {
    return (
    <div className="web-app">

    <div className="flex-row flex-space-between">
        <div className="flex-row">
              <div className="dt-btn-01" onClick={() => this.subday() } ><FontAwesomeIcon icon="minus" size="1x" /></div>
              <Dt_Cal dts={this.state.fdat} rp="1" ref={this.dt_fd} getValue={(e)=> this.dt_fdat(e)} />
              <div className="dt-btn-01" onClick={() => this.oneday() } >1</div>
              <Dt_Cal dts={this.state.tdat} rp="1" ref={this.dt_td} getValue={(e)=> this.dt_tdat(e)} />
              <div className="dt-btn-01" onClick={() => this.addday() } ><FontAwesomeIcon icon="plus" size="1x" /></div>
        </div>
        <button className="btn btn-hw-blue w150 ripple" onClick={() => this.bel_kf() } aria-label="System" >
            UPPDATERA
        </button>
    </div>
    <div className="divider_orange"></div>

    <div className="web-col">
        <table className="table table-bordered tb_pres">
            <thead>
            <tr>
                <th style={{textAlign: "center"}}>Text</th>
                <th style={{textAlign: "left"}}>ID</th>
                <th style={{textAlign: "center"}}>Plats</th>
                { this.state.dta.map((dto, key) =>
                    <th key={key}>{ dto.dt }<br />{ dto.day }</th>
                )}
            </tr>
            </thead>
            <tbody>
            { this.state.rsa.map((row, key) =>
            <tr key={key} >
                <td className='header' onClick={ () => this.selrt(row) }>{ row.restname }</td>
                <td style={{textAlign: "left", padding: "0px 3px"}}>{ row.iresurs }</td>
                <td>{ row.platser }</td>
                { this.state.dta.map((dto, key) =>
                    <td key={key}>{ this.dt_kf(row, dto) }</td>
                )}
            </tr>
                )}
            </tbody>
        </table>
    </div>
   </div>
  );
}
}
export default PMS_BelPk;

/*
{ dto.rs[row.restname].status }
*/