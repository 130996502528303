import React, {useState} from 'react';
import {observer} from "mobx-react";

import sso from "../../sso";
import ddo from '../../ddo.js'

import * as net from "../../lib/net";
import Dd_Sel from "../../lib/ui/dd_sel";
import { useNavigate } from 'react-router-dom';

import Bos_Setup from "./bos_setup";
import Bos_Ses from "./bos_ses";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function Bos_Top(props) {
    var navigate = useNavigate();
    let [state, setState] = useState({wnds: {}, ma: []});

    //var seo = props.store.seo;

    var selitems = [
        {fkn: 'home', text: 'HEM'},
        {fkn: 'setup', text: 'INSTÄLLNINGAR'},
        {fkn: 'ses', text: 'MIN SESSION'},
    ];
    if(sso.seo.cs > 0) {
        selitems.push( {fkn: 'ccs', text: 'CENTRALSYSTEM'} );
    }
    if(sso.seo.auth >=90) {
        selitems.push( {fkn: 'man', text: 'MANAGER'} );
    }
    selitems.push( {fkn: 'logout', text: 'LOGGA UT'} );

    var it = {};

    var fa = "fa fa-key";
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleOpen = event => {
    setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const wnds_close = () => {
        setState({wnds: {}});
    };

    const goFull = () => {
        alert("Full");
    };

    function logout() {
        var url = "/logout";
        navigate(url);
    };
      function xlogout() {
        var self = this;
        var xdo = sessionStorage.getItem("svp");
		var sdo = JSON.parse(xdo);

		var prm = {};
        prm.req = "usr:logout";
		prm.uid = sdo.uid;
		prm.token = sdo.token;

		//alert(JSON.stringify(prm));
        net.gcs_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret));

            if(ret.ok != "000") {
                alert(JSON.stringify(ret));
                //return;
            }
            setAnchorEl(null);
            sessionStorage.removeItem("svp_sso");
            sso.seo = {};

            var url = "/";
            navigate(url);
        })
        .catch(function(e){
            //var txt = JSON.stringify(e);
            alert("LOGOUT ERR: " + JSON.stringify(e));
            //gda.wndError(txt);
        });
      };

      const mnu_toggle = () => {
		//props.ctx.setState({ mnu_show: !props.ctx.state.mnu_show });
		props.store.cfg.mnu_show = !props.store.cfg.mnu_show;
      };
      const bread_toggle = () => {
		props.store.cfg.bread = !props.store.cfg.bread;
      };
      const mnu_item_css = () => {
      };
      const mnu_sel = () => {
      };
      const login = () => {
      };

      const header_class = () => {
  	    var cls = "flex_col flex";

		if(false) {
			cls = cls + " web-head_1";
		}
		//else cls = cls +  " web-header";


		return cls;
    }
    
  function fknGo(url) {
    //alert(row.href);
      navigate(url);
  }
  function fkn_cs() {
      var wnds = {};
      wnds.ccs = true;
      wnds.backdrop = true;
      setState({"wnds": wnds});
  }
    function fkn_manager() {
        var wnds = {};
        wnds.manager = true;
        wnds.backdrop = true;
        setState({"wnds": wnds});
    }
    function fkn_syssel() {
        var wnds = {};
        wnds.syssel = true;
        wnds.backdrop = true;
        setState({"wnds": wnds});
    }
    function fkn_depsel() {
        var wnds = {};
        wnds.depsel = true;
        wnds.backdrop = true;
        setState({ "wnds": wnds });
    }
  function sel_fkn(sel) {
    //alert(JSON.stringify(fkn));
      if(sel.fkn == "home") {
        var url = "/hello";
          navigate(url);
      }
      if(sel.fkn == "setup") {
          var wnds = {};
          wnds.setup = true;
          setState({"wnds": wnds});
      }
      if(sel.fkn == "ses") {
          var wnds = {};
          wnds.ses = true;
          setState({"wnds": wnds});
      }
      if(sel.fkn == "ccs") {
          var wnds = {};
          wnds.ccs = true;
          wnds.backdrop = true;
          setState({"wnds": wnds});
      }
      if(sel.fkn == "man") {
          var wnds = {};
          wnds.manager = true;
          setState({"wnds": wnds});
      }
      if(sel.fkn == "logout") {
        var url = "/logout";
          navigate(url);
      }
  }

    var html =
        <div className={ header_class() }>
            <div className="web-top-content flex-row">
                <div className="web-top-icon px-3" onClick={() => mnu_toggle() }>
                    <FontAwesomeIcon icon="bars" size="1x"/>
                </div>

                <div className="web-top-sep"></div>
                <div className="web-top-mnu flex-column cursor-point" onClick={() => fkn_syssel() }>
                    <div className="item-sys">Företag</div>
                    <div className="item-fkn">{ sso.seo.company }</div>
                </div>
                <div className="web-top-sep"></div>

                { props.store.seo.cs > 0 ?
                    <div>
                        <div className="web-top-mnu flex-column cursor-point" onClick={() => fkn_cs() }>
                        <div className="item-sys">TYP: { sso.seo.cs }</div>
                        <div className="item-fkn">ENHET: { sso.seo.sid }</div>
                        </div>
                    </div>
                    : null
                }

                <div className="flex-fill"></div>
                
                <div className="web-top-mnu flex-column cursor-point" onClick={() => fkn_depsel() }>
                    <div className="item-sys">Avdelning</div>
                    <div className="item-fkn">{ ddo.cache.avd } - { ddo.cache.avdtxt }</div>
                </div>

                { sso.seo.cs == 2 ?
                    <div className="web-top-mnu flex-column px-3" onClick={() => fkn_syssel() }>
                        <div className="item-sys">System</div>
                        <div className="item-fkn">{ sso.sdo.stxt }</div>
                    </div>
                    : null
                }

                <div className="flex-fill"></div>

                <div className="web-top-sep"></div>

                <div className="web-top-usr px-3" onClick={() => fkn_manager() }>
                    <div className="text-center"><FontAwesomeIcon icon={["far", "user"]} size="1x"/></div>
                    <div>{ sso.seo.name }</div>
                </div>
                <div className="web-top-sep"></div>
                <div className="">
                    <Dd_Sel icon="right-from-bracket" items={ selitems } cls="web-top-icon" getValue={(e)=> sel_fkn(e)} />
                </div>
            </div>

        <Bos_Setup wnds={state.wnds} cb={wnds_close} />
        <Bos_Ses wnds={state.wnds} cb={wnds_close} />
        </div>
    ;
    return html;
}

export default observer(Bos_Top);
