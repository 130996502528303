import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../../../lib/net";
import Pms_Rvo from "../../../rv/rvo";
import Dd_Sel from "../../../../lib/ui/dd_sel";
import Td_Sel from "../../../../lib/ui/td_sel";

class Pkat extends React.Component {
  constructor(props, context) {
    super(props);

     this.pkatitems = [
        {fkn: 'upd', text: 'UPPDATERA'},
        {fkn: 'del', text: 'TA BORT'}
    ];

   //this.webStore = props.store;
    this.state = {};
    this.state.ynq = [];
    this.state.paa = [];
    this.state.aa = [];
    this.state.nba = [];
    if(props.wnds) {
        this.fkn_close = props.wnds.fkn_close;
        this.state.boknr = props.wnds.boknr;
        this.state.rvo = props.wnds.rvo;
    }
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      //alert(JSON.stringify(this.state.rvo));
      this.gst_pkats();
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
  tb_style() {
    var css = {};
    css.height = (window.innerHeight * 0.8) - 150;
    css.overflow = "auto";
    return css;
  }
  row_style(row) {
    var srow = this.state.selrow;
    var css = {};
    if(srow.etr01 && (srow.etr01.e0lopnr == row.etr01.e0lopnr)) {
        css.background = "#ff9900";
        css.color = "#fff";
    }
    return css;
  }

    wnd_close() {
      if(this.fkn_close) this.fkn_close(this.props.ctx);
      else this.props.ctx.setState({ wnds: {} });
	};

    br_pkats() {
        var self = this;
        var prm = {};
        prm.req = "pms.br.br_pkats";
        prm.rblx0 = this.state.rvo.rblx0;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret));

                if(ret.ok != "000") {
                    return;
                }
                self.setState({nba: ret.rca});
                self.syncTb("nbaTable");
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    gst_pkats() {
        var self = this;
        var prm = {};
        prm.req = "pms.br.gst_pkats";
        prm.rblx0 = this.state.rvo.rblx0;
        prm.gsb00 = this.state.rvo.gsb00;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret));

                if(ret.ok != "000") {
                    return;
                }
                self.setState({nba: ret.rca});
                self.syncTb("nbaTable");
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    syncTb(tb){
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
        tdElements = tbel.getElementsByTagName('td');

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
            width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
  pkat_row_fkn(row, e) {
    var self = this;
    //alert(JSON.stringify(e.fkn));
      if(e.fkn == "corr") {
      }
  }
    pkat_edit(row) {
        var self = this;
    }
    updtid(row) {
        var s = "";
        if(row && row.n0upddat && (row.n0upddat.trim() != "")) {
            s = row.n0upddat + "-" + row.n0updtid;
        }
        return s;
    }

  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "";

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="m2_backdrop" /> : null }
        <div className="m2_dlg mx_80 flex-col" style={ this.dlg_style() }>
        <div className="flex-row " style={{padding:"0px",height:"50px",lineHeight:"50px", background: "#1a456b", color: "#fff"}}>
                <div className="flex-row flex" style={{padding:"0px 5px", fontSize: "22px"}}>
                    <div>Påslag { this.state.rumsnr }</div>
                </div>
            <div className="wnd-close-btn" onClick={() => this.wnd_close() }>X</div>
        </div>
        <div className="mx_content flex">

        <div className="web-row flex">
        <div className="web-col flex" style={{padding: "0px"}}>

        <div id="nbaTable" className="flex">
          <table className="table table-bordered table-hover" style={{marginBottom: "0px"}}>
                <thead style={{fontSize: "12px", fontWeight: "normal", color: "#080", border: "0px", margin: "0px", padding: "0px"}}>
                <tr>
                    <th style={{textAlign: "left"}}>Datum</th>
                    <th style={{textAlign: "left"}}>Text</th>
                    <th style={{textAlign: "left"}}>Pkat</th>
                    <th style={{textAlign: "left"}}>Baspris</th>
                    <th style={{textAlign: "left"}}>Pris</th>
                    <th style={{textAlign: "left"}}>UPD</th>
                    <th style={{textAlign: "left"}}>Debiterad</th>
                    <th style={{textAlign: "left"}}></th>
                </tr>
                </thead>
            </table>
            <div style={ this.tb_style() }>
              <table className="table table-bordered table-hover">
                <tbody>
                { this.state.nba.map((row, key) =>
                <tr onDoubleClick={() => this.pkat_edit(row) }>
                    <td>{ row.n0datum.cdidate() }</td>
                    <td>{ row.n0text }</td>
                    <td>{ row.n0typ }</td>
                    <td>{ row.n0baspris.cdipris() }</td>
                    <td>{ row.n0pris.cdipris() }</td>
                    <td>{ this.updtid(row) }</td>
                    <td>{ row.n0debiterad }</td>
                    <td>
                        <Td_Sel items={ this.pkatitems } getValue={(e)=> this.pkat_row_fkn(row, e)} />
                    </td>
                </tr>
                )}
                </tbody>
            </table>
        </div>
        </div>

        </div>
        </div>


        </div>
        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250 ripple" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex"></div>
            <button className="btn btn-hw-green w250 ripple" onClick={(event) => this.wnd_close() } >
                VÄLJ
            </button>
        </div>
      </div>
            { this.state.wnd_rvo ?
        <Pms_Rvo close={this.rvo_close} boknr={this.state.boknr} ctx={this} store={this.props.store} backdrop={true}/>
            : null }
    </div>
	;
	return html;
  };
}

export default Pkat;