import React, { useState, useEffect, useRef, useContext } from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

//import './css/dd_sel.css';

class Wnd_Error extends React.Component {
  constructor(props, context) {
    super(props);

    this.node = React.createRef();
    //this.setNodeRef = this.setNodeRef.bind(this);

    this.state = {};
    this.state.cols = {};
    this.state.sel_show = false;
    this.state.sel_step = 0;
  }

  componentDidUpdate() {
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick);
  }
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClick);
  }

  wnd_close() {
    this.props.ctx.setState({ msg: {} });
  }

  handleClick = (e) => {
    if(!this.props.msg.error) return;

    if (!this.node.current.contains(e.target)) {
      //this.setState({sel_show: false});
      //this.props.ctx.setState({ wnd_ok: false });
      return;
    }
  }
  render() {
    if(!this.props.msg.error) return "";

    const errwnd = {
      position: 'absolute',
      width: '35%',
      height: '35%',
      zIndex: '19999',
      top: '35%',
      left: '35%',
        border: "1px solid #ccc",
        borderRadius: "5px",
        backgroundColor: "#ED213A",  /* fallback for old browsers */
        background: "-webkit-linear-gradient(to right, #93291E, #ED213A)",  /* Chrome 10-25, Safari 5.1-6 */
        backgroundImage: "linear-gradient(to right, #93291E, #ED213A)" /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        //backgroundColor: "#8E0E00",  /* fallback for old browsers */
        //background: "-webkit-linear-gradient(to right, #1F1C18, #8E0E00)",  /* Chrome 10-25, Safari 5.1-6 */
        //backgroundImage: "linear-gradient(to right, #1F1C18, #8E0E00)" /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    }

    var title = this.props.title;
    if(!title) title = "URVAL";

	var html =
        <div ref={this.node} style={ errwnd } className="d-flex flex-column align-content-center justify-content-center text-center" >
        <h2 className="" style={{color: "#fff"}}>
            { this.props.msg.text }
        </h2>
        <FontAwesomeIcon className="" icon="exclamation" size="4x" style={{color: "#fff"}} />

        </div>
	;
	return html;
  };
}

export default Wnd_Error;