import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../../lib/net";
import Fr_Input from "../../../lib/ui/fr_input";
import Fr_Inpsel from "../../../lib/ui/fr_inpsel";
import Fak_Kund from "./fak_kund";
import Fr_Cal from "../../../lib/ui/fr_cal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as utils from "../../../assets/utils";

class Fak_Head extends React.Component {
  constructor(props, context) {
    super(props);

    this.state = {};
    this.state.mka = [];
    this.state.sval = [];
    this.state.tval = [];
    this.state.paa = [];
    this.state.uaa = [];
    this.state.laa = [];

    this.state.gnr = "001";
      this.state.ynq = [
          {id: '0', text: 'NEJ'},
          {id: '1', text: 'JA'}
      ];
      this.state.mval = [
          {val: '0000', text: 'MOMSFRITT'},
          {val: '0566', text: 'MOMS 6 %'},
          {val: '1071', text: 'MOMS 12 %'},
          {val: '2000', text: 'MOMS 25 %'}
      ];

    this.state.wnd_ok = false;
    this.state.wnd_err = false;

      this.state.msg = {};
      this.state.wnds = {};
      this.state.cols = {};
    this.state.tr00 = {};
    this.state.faknr = "";
    this.state.wfh = {};
    if(props.wnds) {
        this.state.faknr = props.wnds.faknr;
        this.state.wfh = props.wnds.wfh;
        this.state.wfh = JSON.parse(JSON.stringify(props.wnds.wfh));
        this.state.wfh.wfak00.fakdatum = this.state.wfh.wfak00.fakdatum.cdidate();
        this.state.wfh.wfak00.ffdatum = this.state.wfh.wfak00.ffdatum.cdidate();
        this.state.wfh.wfak01.levdatum = this.state.wfh.wfak01.levdatum.cdidate();
        //this.state.wfr.pris = this.state.wfr.pris.cdipris();
    }
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.btn_grps();
      //if(!this.props.wnds.wfh) this.wfh_init();
  }

    wfh_init() {
        var wfh = {};
        wfh.faktyp = "I";
        wfh.faknr = this.state.faknr;
        wfh.fakrad = "P";
        //alert(JSON.stringify(rso));
        this.setState({ wfh: wfh });
        return;
    }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }

    wnd_close = () => {
      if(this.props.close) {
          this.props.close(this.props.ctx);
      }
      else this.props.ctx.setState({ wnds: {} });
	};
    toggle() {
      this.setState({modal: false});
	};

  submitHandler = event => {
    event.preventDefault();
    event.target.className += " was-validated";
  };

  changeHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  val_save(id, val) {
      var wfh = this.state.wfh;
      var id1 = id.split(".")[0];
      var id2 = id.split(".")[1];
      wfh[id1][id2] = val;
        this.setState({ wfh: wfh });
      return;
  }
    val_dt_save (id, dts) {
        var wfh = this.state.wfh;
        //var dts = format(value, "yyyy-MM-dd");
        //if(dts < this.state.tds) dts = this.state.tds;
        //bro.fdat = dts;
        //if(bro.tdat < dts) bro.tdat = dts;
        var id1 = id.split(".")[0];
        var id2 = id.split(".")[1];
        wfh[id1][id2] = dts;
        this.setState({ wfh: wfh });
    }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
    fak_head_save() {
        var self = this;

        var wfh = this.state.wfh;

        var prm = {};
        prm.req = "fakt.fakt_fak.fak_head_save";
        prm.wfak00 = this.state.wfh.wfak00;
        prm.wfak01 = this.state.wfh.wfak01;
        prm.wfak02 = this.state.wfh.wfak02;
        prm.wfak03 = this.state.wfh.wfak03;

        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret));

            var wfh = JSON.parse(JSON.stringify(self.state.wfh));
            self.setState({ wfh: wfh, wnd_ok: true });

            setTimeout(function(){
                self.setState({wnd_ok: false });
                if(self.props.wnds.cb) {
                    self.props.wnds.cb(self.props.ctx);
                }
                else self.props.ctx.setState({ wnds: {} });
                return;
            }, 1000);
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }
    cb_kund(ctx, kro) {
        var self = this;
        var wfh = ctx.state.wfh;
        wfh.wfak00.ktyp = "K";
        wfh.wfak00.kund = kro.krr00.kid;
        wfh.wfak00.company = kro.krr00.company;
        ctx.setState({ wnds: {}, wfh: wfh });
    }
    fak_kund_sz() {
        var self = this;
        var wnds = {};
        wnds.kund = 1;
        wnds.cb = this.cb_kund;
        this.setState({ wnds: wnds });
    }

  render() {
    //if(!this.props.show) return "";
    var title = this.props.title;
    if(!title) title = "Resurs";
    //if(props.kro) alert(props.kro.krr00.kid);
    const popover = {
      position: 'absolute',
      top: '50px',
      zIndex: '2',
    }
    const cover = {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    }
    const okwnd = {
      position: 'absolute',
      width: '35%',
      height: '35%',
      zIndex: '19999',
      top: '35%',
      left: '35%',
        border: "1px solid #ccc",
        borderRadius: "5px",
       backgroundColor: "#015d00",
        backgroundImage: "linear-gradient(314deg, #015d00 0%, #04bf00 74%)"
    }
    const errwnd = {
      position: 'absolute',
      width: '35%',
      height: '35%',
      zIndex: '19999',
      top: '35%',
      left: '35%',
        border: "1px solid #ccc",
        borderRadius: "5px",
        backgroundColor: "#ED213A",  /* fallback for old browsers */
        background: "-webkit-linear-gradient(to right, #93291E, #ED213A)",  /* Chrome 10-25, Safari 5.1-6 */
        backgroundImage: "linear-gradient(to right, #93291E, #ED213A)" /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        //backgroundColor: "#8E0E00",  /* fallback for old browsers */
        //background: "-webkit-linear-gradient(to right, #1F1C18, #8E0E00)",  /* Chrome 10-25, Safari 5.1-6 */
        //backgroundImage: "linear-gradient(to right, #1F1C18, #8E0E00)" /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    }

	var html =
        <div className="mx_base">
        <div className="m2_backdrop" />
        <div className="m2_dlg mx_70 flex-col" style={ this.dlg_style() } draggable="true" >
        <div className="mx_content flex">

        <div className="mx_h01">
            <div>FAKTURA HUVUD</div>
            <div>: { utils.toNum( this.state.faknr ) }</div>
        </div>
            <div className="flex-row py-2 px-2">
                <div className="link_14" onClick={() => this.fak_kund_sz() } >SÖK KUND</div>
                <div className="flex"></div>
                <div></div>
            </div>

            <div className="divider_orange"></div>

            <form className="cdi_f03">
            <div className="flex-row">
                    <Fr_Input label="Företag" className="text-left w350" text={this.state.wfh.wfak00.company} getValue={(e)=> this.val_save("wfak00.company", e) } />
                    <Fr_Input label="Orgnr" className="text-left w250" text={this.state.wfh.wfak01.orgnr} getValue={(e)=> this.val_save("wfak01.orgnr", e) } />
            </div>
                <div className="flex-row">
                    <Fr_Input label="KundTyp" className="text-left w150" text={this.state.wfh.wfak00.ktyp} getValue={(e)=> this.val_save("wfak00.ktyp", e) } />
                    <Fr_Input label="Kund" className="text-left w250" text={this.state.wfh.wfak00.kund} getValue={(e)=> this.val_save("wfak00.kund", e) } />
                </div>
            <div className="divider_green"></div>
                <div className="flex-row">
                    <Fr_Input label="Postadress" className="text-left w350" text={this.state.wfh.wfak01.adress1} getValue={(e)=> this.val_save("wfak01.adress1", e) } />
                    <Fr_Input label="Referens" className="text-left w250" text={this.state.wfh.wfak01.referens} getValue={(e)=> this.val_save("wfak01.referens", e) } />
                </div>
                <div className="flex-row">
                    <Fr_Input label="Gatuadress" className="text-left w350" text={this.state.wfh.wfak02.adress2} getValue={(e)=> this.val_save("wfak02.adress2", e) } />
                    <Fr_Input label="Märkning" className="text-left w350" text={this.state.wfh.wfak03.remarks} getValue={(e)=> this.val_save("wfak03.remarks", e) } />
                </div>
                <div className="flex-row">
                    <Fr_Input label="Postnr Stad" className="text-left w350" text={this.state.wfh.wfak02.adress3} getValue={(e)=> this.val_save("wfak02.adress3", e) } />
                </div>
                <div className="flex-row">
                    <Fr_Input label="Land" className="text-left w350" text={this.state.wfh.wfak03.adress4} getValue={(e)=> this.val_save("wfak03.adress4", e) } />
                </div>
            <div className="divider_green"></div>
                <div className="flex-row">
                    <Fr_Cal label="Fak Datum" className="text-left w250" min="0" text={this.state.wfh.wfak00.fakdatum} getValue={(e)=> this.val_dt_save( "wfak00.fakdatum", e ) }/>
                    <Fr_Cal label="FF Datum" className="text-left w250" min="0" text={this.state.wfh.wfak00.ffdatum} getValue={(e)=> this.val_dt_save( "wfak00.ffdatum", e ) }/>
                </div>
                <div className="flex-row">
                    <Fr_Cal label="Lev Datum" className="text-left w250" min="0" text={this.state.wfh.wfak01.levdatum} getValue={(e)=> this.val_dt_save( "wfak01.levdatum", e ) }/>
                </div>
                <div className="flex-row hide">
                    <Fr_Input label="Belopp" className="text-left w250" text={this.state.wfh.wfak00.belopp} getValue={(e)=> this.val_save("wfak00.belopp", e) } />
                    <Fr_Input label="Moms" className="text-left w250" text={this.state.wfh.wfak00.moms} getValue={(e)=> this.val_save("wfak00.moms", e) } />
                </div>

            </form>

        </div>
        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex"></div>
            <button className="btn btn-hw-red w250" onClick={() => this.fak_kund_sz() } >
                KUND
            </button>
            <div className="flex"></div>
            <button className="btn btn-hw-red w250" onClick={() => this.fak_head_save() } >
                SPARA
            </button>
        </div>
      </div>

        { this.state.wnd_ok ?
            <div style={ okwnd } className="d-flex flex-column align-content-center justify-content-center text-center" >
                    <h2 className="" style={{color: "#fff"}}>SPARAT</h2>
                    <FontAwesomeIcon className="" icon="check" size="4x" style={{color: "#fff"}} />
            </div>
            : null }
            { this.state.wnd_err ?
                <div style={ errwnd } className="d-flex flex-column align-content-center justify-content-center text-center" >
                        <h2 className="" style={{color: "#fff"}}>FEL</h2>
                        <FontAwesomeIcon className="" icon="exclamation" size="4x" style={{color: "#fff"}} />
                </div>
                : null }
            { this.state.wnds.kund ? <Fak_Kund wnds={this.state.wnds} ctx={this} backdrop={true}/> : null }
      </div>
	;
	return html;
  };
}

export default Fak_Head;