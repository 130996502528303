import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";
import * as utils from "../../assets/utils";
import Td_Sel from "../../lib/ui/td_sel";
import EE_Per from "./per/ee_per";
import {observer} from "mobx-react";
import {format, getHours, subDays} from "date-fns";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class TH_Act extends React.Component {
  constructor(props, context) {
    super(props);

    this.store = props.store;

    this.state = {};
    this.state.avd = "";
    this.state.tha = [];
    this.state.kro = {};
    this.state.aa = [];
      this.state.cols = {};
      this.state.wnds = {};
      this.state.tot = {};
    this.state.today = new Date();
    var dts = format(this.state.today, "yyyy-MM-dd");
    this.state.tdat = dts;
    var hh = getHours(this.state.today);
    if( hh < 6) {
      const dt = subDays(new Date(), 1);
      dts = format(dt, "yyyy-MM-dd");
    }
    this.state.fdat = dts;
  }

  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.act();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
    tb_style() {
        var css = {};
        css.height = window.innerHeight - 275;
        css.overflow = "auto";
        return css;
    }
    th_style(id) {
        var css = {};
        css.cursor = "pointer";
        if(id == this.state.sortid) {
            if(this.state.sortdir) css.color = "#800";
            else css.color = "#080";
        }
        return css;
    }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }
    change_avd (e) {
        const value = e.target.value;
        this.setState({avd: value});
    }


  act() {
    var self = this;
    var prm = {};
    prm.req = "ta.ta_th.act_list";
    prm.fdat = this.state.fdat;
      prm.tdat = this.state.tdat;
      prm.avd = this.state.avd;
      //alert(JSON.stringify(prm));

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        self.setState({tha: ret.rca });
        self.syncTb("actTable");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
    syncTb(tb) {
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
            tdElements = tbel.getElementsByTagName('td');
        if(tdElements.length < 1 ) return;

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
                width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
    tb_sort(typ) {
        var self = this;
        //var rvs = self.state.rvs;
        var tha = self.state.tha;
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;

        if(sortid != typ) sortdir = 0;
        else {
            if(sortdir) sortdir = 0;
            else sortdir = 1;
        }

        if(typ) {
            if(sortdir) tha.sort((a,b) => (a[typ] < b[typ]) ? 1 : ((b[typ] < a[typ]) ? -1 : 0));
            else tha.sort((a,b) => (a[typ] > b[typ]) ? 1 : ((b[typ] > a[typ]) ? -1 : 0));
        }
        self.setState({ tha: tha, sortid: typ, sortdir: sortdir });
    }
    th_icon(typ) {
        var cls = "";
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;
        if(sortid == typ)
        {
            if(sortdir) cls = "fa fa-caret-up";
            else cls = "fa fa-caret-down";
        }
        return cls;
    }

  render() {
    return (
    <div className="web-app">

    <div className="flex-row">
        <select className="form-control w250" placeholder="Välj avdelning" onChange={(e) => this.change_avd(e)}>
            { this.props.store.ta.dpa.map((row, key) =>
                <option key={key} value={row.avdelning}>{row.avdelning} - {utils.toLang( row.namn )}</option>
            )}
        </select>
        <div className="flex"></div>
        <button className="btn btn-hw-blue w150 ripple" onClick={() => this.syncCols("kidTable") } aria-label="System" >
            Visa
        </button>
    </div>
    <div className="divider_blue"></div>

        <div id="actTable" className="web-col">
            <table className="table table-bordered table-hover tb " style={{marginBottom: "0px"}}>
                <thead>
                <tr>
                    <th style={ this.th_style("anstnummer") } onClick={() => this.tb_sort("anstnummer") }>Anstnr <i className={ this.th_icon("anstnummer") }></i></th>
                    <th style={ this.th_style("namn") } onClick={() => this.tb_sort("namn") }>Namn <i className={ this.th_icon("namn") }></i></th>
                    <th style={ this.th_style("avd") } onClick={() => this.tb_sort("avd") }>Avdelning <i className={ this.th_icon("avd") }></i></th>
                    <th style={ this.th_style("start") } onClick={() => this.tb_sort("start") }>Start <i className={ this.th_icon("start") }></i></th>
                    <th style={ this.th_style("slut") } onClick={() => this.tb_sort("slut") }>Slut <i className={ this.th_icon("slut") }></i></th>
                    <th style={ this.th_style("schematid") } onClick={() => this.tb_sort("schematid") }>Schematid <i className={ this.th_icon("schematid") }></i></th>
                    <th style={ this.th_style("netto") } onClick={() => this.tb_sort("netto") }>Nettotid <i className={ this.th_icon("netto") }></i></th>
                    <th style={ this.th_style("rast") } onClick={() => this.tb_sort("rast") }>Rast <i className={ this.th_icon("rast") }></i></th>
                    <th style={ this.th_style("brutto") } onClick={() => this.tb_sort("brutto") }>Bruttotid <i className={ this.th_icon("brutto") }></i></th>
                    <th style={ this.th_style("ob1") } onClick={() => this.tb_sort("ob1") }>OB1 <i className={ this.th_icon("ob1") }></i></th>
                    <th style={ this.th_style("ob2") } onClick={() => this.tb_sort("ob2") }>OB2 <i className={ this.th_icon("ob2") }></i></th>
                    <th style={ this.th_style("reg_ok") } onClick={() => this.tb_sort("reg_ok") }>Status <i className={ this.th_icon("reg_ok") }></i></th>
                    <th></th>
                </tr>
                </thead>
            </table>
            <div className="tb-cdi" style={ this.tb_style() }>
                <table className="table table-bordered table-hover tb">
                    <tbody>
                    { this.state.tha.map((row, key) =>
                        <tr key={key} onDoubleClick={() => this.ee_edit(row) }>
                            <td className="text-center">{ utils.toNum( row.anstnummer ) }</td>
                            <td>{ utils.toLang( row.peo.p1.namn ) }</td>
                            <td className="text-center">{ utils.toLang( row.peo.p1.avdelning ) }</td>
                            <td className="text-center">{ row.start }</td>
                            <td className="text-center">{ row.slut }</td>
                            <td className="text-center">{ row.schematid }</td>
                            <td className="text-center">{ utils.toMinTid( row.netto ) }</td>
                            <td className="text-center">{ utils.toMinTid( row.rast ) }</td>
                            <td className="text-center">{ utils.toMinTid( row.brutto ) }</td>
                            <td className="text-center">{ utils.toMinTid( row.ob1 ) }</td>
                            <td className="text-center">{ utils.toMinTid( row.ob2 ) }</td>
                            <td className="text-center">{ row.reg_ok }</td>
                            <td>
                                <Td_Sel items={ this.selitems } getValue={(e)=> this.sel_row_fkn(row, e)} />
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
        </div>
        <div className="divider_blue"></div>
        <div className="flex-row">
            <div className="txt_18">TOTAL NETTO: { utils.toMinTid(this.state.tot.netto) }</div>
            <div className="flex"></div>
            <div className="flex"></div>
            <div className="txt_18">TOTAL BRUTTO: { utils.toMinTid(this.state.tot.brutto) }</div>
        </div>
        { this.state.wnds.eeper ? <EE_Per wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
   </div>
  );
    }
}
export default observer(TH_Act);
