import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../../lib/net";
import Fr_Input from "../../../lib/ui/fr_input";

class Typ_Add extends React.Component {
  constructor(props, context) {
    super(props);

    this.pos = props.store.pos;
    this.state = {};
    this.state.ynq = [];
    this.state.sval = [];

    this.state.dao = {};
    this.state.aa = [
                {id: '01', text: 'AVD 01'},
                {id: '02', text: 'AVD 02'}
            ];
    this.state.cval = [
                {id: '001', text: 'ANTAL GER NYTT PRIS'},
                {id: '002', text: 'ANTAL GER RABATT PÅ PRIS'},
                {id: '003', text: 'PRISÄNDRING GENOM RABATT'}
            ];

    this.state.hpo = {};
    this.state.hap = {};
    this.state.vpo = {};
    this.state.vpo.vip00 = {};
    this.state.vpo.vip01 = {};
    this.state.vpo.vip02 = {};
    this.state.vpo.vip03 = {};

    if( props.wnds ) {
        //this.state.vpo = props.wnds.vpo;
    }
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.happy();
  }
    cdi_init() {
        var self = this;
        var hpo = {};
        var hap = self.state.hap;

        if(hap.hap00) {
            hpo.q0pris2 = hap.hap00.q0pris2.formatpris();
            hpo.q0npris2 = hap.hap00.q0npris2.formatpris();
        }

        self.setState({hpo: hpo,  md_init: true});
    }
    cdi_store() {
        var self = this;
        var hap = self.state.hap;
        var hpo = self.state.hpo;

        if(!hap.hap00) {
            hap.hap00 = {};
        }
        hap.hap00 = {};
        hap.hap01 = {};
        hap.hap02 = {};
        hap.hap03 = {};

        hap.hap00.q0p = "Q";
        hap.hap00.q0kstns = this.state.pbo.plu00.p0kstns
        hap.hap00.q0katnr = this.state.pbo.plu00.p0katnr

        hap.hap00.q0pris2 = "";
        hap.hap00.q0npris2 = "";

        if(hpo.q0pris2) hap.hap00.q0pris2 = hpo.q0pris2.pris2cdi();
        if(hpo.q0npris2) hap.hap00.q0npris2 = hpo.q0npris2.pris2cdi();

        self.setState({hap: hap});
    }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    tab_style(tabid) {
      var css = {};
      css.display = "none";
      if(this.state.tabval === tabid) css.display = "block";
      return css;
    }
    tab_head(tabid) {
      var css = {};
      css.background = "#fff";
      css.color = "#000";
      if(this.state.tabval === tabid) {
          css.background = "#080";
          css.color = "#fff";
      }
      return css;
    }
    grp_style(row) {
      var css = {};
      css.background = "#008";
      if(this.state.gnr === row.vgrupp) css.background = "#080";
      return css;
    }

    formchange (e) {
      const name = e.target.name;
      const value = e.target.value;
      var pbo = this.state.pbo;
      var na = name.split(".");
      pbo[na[0]][na[1]] = value;
      this.setState({pbo: pbo});
      //setState({[name]: value});
    }

    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    wnd_close = () => {
		this.props.ctx.setState({ wnds: {} });
	};
    handleChange = (event, newValue) => {
        //alert(newValue);
        this.setState({ix: newValue});
      };
    toggle() {
      this.setState({modal: false});
	};

  submitHandler = event => {
    event.preventDefault();
    event.target.className += " was-validated";
  };

  changeHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  cssCols (cid) {
      var css = {};
      if(this.state.cols[cid]) css.width = this.state.cols[cid].toString() + "px";
      //if(cid == "c01") alert(JSON.stringify(this.state.cols[cid]));
      return css;
  }
  typ_get() {
    var self = this;
    var prm = {};
    prm.req = "pos.pos_plu.plu_happy";
    prm.avd = this.state.pbo.plu00.p0kstns;
    prm.plu = this.state.pbo.plu00.p0katnr;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        self.setState({hap: ret.rco }, function() { this.cdi_init(); });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  typ_save() {
    var self = this;

    //this.cdi_store();

    var prm = {};
    prm.req = "vip.vip_reg.reg_upd";
    prm.vpo = this.state.vpo;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

		//self.props.ctx.setState({ pluhappy: false });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

  val_get (name) {
      var value = "";
    //alert(JSON.stringify(name));
    //alert(JSON.stringify(value));
    var na = name.split(".");
    //var ff = na[1];
    var vpo = this.state.vpo;
    if(vpo) value = vpo[na[0]][na[1]];
    return value;
   }
  val_set (name, value) {
    //alert(JSON.stringify(name));
    //alert(JSON.stringify(value));
    var na = name.split(".");
  var vpo = this.state.vpo;
  vpo[na[0]][na[1]] = value;
  this.setState({vpo: vpo});
  }
    hap_pris (name, value) {
        //alert(JSON.stringify(name));
        //alert(JSON.stringify(value));
      var hpo = this.state.hpo;
      //var na = name.split(".");
      //hap[na[0]][na[1]] = value;
      hpo[name] = value;
      this.setState({hpo: hpo});
      //setState({[name]: value});
    }

  render() {
    //if(!this.props.show) return "";

    //alert(this.props.ctx.state.lro);

    var title = this.props.title;
    if(!title) title = "Knappar";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        <div className="m2_backdrop" />
        <div className="m2_dlg mx_70 flex-col" style={ this.dlg_style() } >
        <div className="mx_h03">
            <div>Ny Korttyp</div>
        </div>
        <div className="mx_content flex">

            <div className="flex-row" style={{padding:"5px",height:"35px"}}>
                    <div className="flex-col flex">
                    </div>
                    <div className="flex-col">
                        <div>UPPDATERA</div>
                    </div>
            </div>
            <div className="divider_orange"></div>

            <form className="cdi_f03">
                <div className="form-row flex-col">
                    <Fr_Input label="Typ" className="w250" text={ this.val_get( "vip00.v0typ" ) } getValue={(e)=> this.val_set("vip00.v0typ", e) } placeholder="" />
                    <Fr_Input label="ID" className="w250" text={ this.val_get( "vip00.v0ktyp" ) } getValue={(e)=> this.val_set("vip00.v0ktyp", e) } placeholder="" />
                </div>
                <div className="divider_green"></div>
                <div className="form-row flex-row">
                    <Fr_Input label="Text" className="w350" text={ this.val_get( "vip00.v0company" ) } getValue={(e)=> this.val_set("vip00.v0company", e) } placeholder="" />
                </div>
                <div className="form-row flex-row">
                    <Fr_Input label="Serie Nr" className="w350" text={ this.val_get( "vip00.v0namn" ) } getValue={(e)=> this.val_set("vip00.v0namn", e) } placeholder="" />
                </div>
            </form>

        </div>
        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <button className="btn btn-hw-green w250" onClick={() => this.typ_save() } >
                LÄGG TILL
            </button>
        </div>
      </div>
      </div>
	;
	return html;
  };
}

export default Typ_Add;