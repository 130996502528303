import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../../lib/net";
import Rs_Open from "./rs_open";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Reg_Rs extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, tra: [] };
    this.state.seldate = new Date();
    this.state.avd = "01";
    this.state.rsa = [];
    this.state.rso = {};
    this.state.aa = [];
    this.state.cols = {};
    this.state.wnd_rs = false;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.rs();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

  rs_add(row) {
      var self = this;
      //alert(JSON.stringify(row.krr00));
        this.setState({wnd_rs: true, rso: null});
  }
  rs_edit(row) {
      var self = this;
      //alert(JSON.stringify(row.krr00));
        this.setState({wnd_rs: true, rso: row});
  }
  fkn_close(ctx) {
    ctx.setState({wnd_rs: false });
    ctx.rs();
  }

  rs() {
    var self = this;
    var prm = {};
    prm.req = "pms.rs.qs_rs";
    //prm.fdat = "20200915";
    //prm.tdat = "20200915";
    //prm.avd = this.state.avd;

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        self.setState({rsa: ret.rco }, function () {
            self.syncCols("rsTable");
        });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e) {
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }
  cssCols = function (cid) {
      var css = {};
      if(this.state.cols[cid]) css.width = this.state.cols[cid].toString() + "px";
      //if(cid == "c01") alert(JSON.stringify(this.state.cols[cid]));
      return css;
  }
  syncCols = function (tb) {

    if (!document.getElementById(tb)) return;

    var cols = {};
    var ix = document.getElementById(tb).rows.length - 1;
    var ic = document.getElementById(tb).rows[0].cells.length;
    var ci = 0;
    while(ci < ic) {
        var cxi = ci + 1;
        var col = "c" + cxi.toString().padStart(2, "0");
        cols[col] = document.getElementById(tb).rows[ix].cells[ci].clientWidth + 1;
        ci = ci + 1;
    }
    this.setState({cols: cols });
    //alert(JSON.stringify(this.state.cols));
    //this.forceUpdate();
  };

  render() {
    return (
    <div className="web-app">
    <div className="flex-row flex-space-between">
        <div className="flex-row flex">
        <button className="btn btn-hw-blue w150 ripple" onClick={() => this.rs_add() } aria-label="System" >
            LÄGG TILL
        </button>
        </div>
        <div className="flex-row">
       </div>
        <div className="flex-row flex-end flex">
        <button className="btn btn-hw-blue w150 ripple" onClick={() => this.rs() } aria-label="System" >
            UPPDATERA
        </button>
        </div>
    </div>
    <div className="divider_orange"></div>

    <div className="web-col">
        <table className="table table-bordered table-hover tb " style={{marginBottom: "0px"}}>
            <thead>
            <tr>
                <th style={ this.cssCols("c01" ) }>Boktyp</th>
                <th style={ this.cssCols("c02" ) }>Text</th>
                <th style={ this.cssCols("c03" ) }>Sort</th>
                <th style={ this.cssCols("c04" ) }>Platser</th>
                <th style={ this.cssCols("c05" ) }>Från</th>
                <th style={ this.cssCols("c06" ) }>Till</th>
                <th style={ this.cssCols("c07" ) }>ID</th>
                <th style={ this.cssCols("c08" ) }>Reservationstid</th>
                <th style={ this.cssCols("c09" ) }>Uppdaterad</th>
                <th style={ this.cssCols("c10" ) }></th>
            </tr>
            </thead>
        </table>
       <div className="tb-cdi" style={ this.tb_style() }>
       <table id="rsTable" className="table table-bordered table-hover tb">
            <tbody>
            { this.state.rsa.map((row, key) =>
            <tr key={key} id={ row.boktyp } onDoubleClick={() => this.rs_edit(row) }>
                <td>{ row.boktyp }</td>
                <td>{ row.restname }</td>
                <td>{ row.resursordn }</td>
                <td>{ row.platser }</td>
                <td>{ row.fromtime }</td>
                <td>{ row.tilltime }</td>
                <td>{ row.iresurs }</td>
                <td>{ row.reservtime }</td>
                <td>{ row.upddat }</td>
                <td style={{width:"40px",cursor:"pointer"}} onClick={() => this.rs_edit(row) }><i className="fa fa-cog text-danger"></i></td>
            </tr>
            )}
            <tr style={{visibility: "hidden"}}>
				<th>Boktyp</th>
				<th>Text</th>
				<th>Sort</th>
				<th>Platser</th>
				<th>Från</th>
				<th>Till</th>
				<th>ID</th>
				<th>Reservationstid</th>
				<th>Uppdaterad</th>
                <th></th>
            </tr>
            </tbody>
        </table>
        </div>
    </div>

        { this.state.wnd_rs ? <Rs_Open close={this.fkn_close} ctx={this} rso={this.state.rso} store={this.props.store} backdrop={true}/> : null }
   </div>
  );
    }
}
export default Reg_Rs;
