import React, { useState, useEffect, useContext } from 'react';
import {format} from "date-fns";

import * as fkn from "../../../lib/fkn";
import * as net from "../../../lib/net";
import * as utils from "../../../assets/utils";
import {toTid} from "../../../assets/utils";

import Dt_Cal from "../../../lib/ui/dt_cal";

import Dd_Sel from "../../../lib/ui/dd_sel";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Krub_Verify extends React.Component {
  constructor(props, context) {
    super(props);

    this.dt_fd = React.createRef();
      this.dt_td = React.createRef();
      this.dt_bdt = React.createRef();

    this.store = props.store;
    this.pms = props.store.pms;

    this.state = {};
    this.state.fkn = "";
      this.state.sortid = "";
      this.state.dta = [];

      this.state.today = new Date();
    var dts = format(this.state.today, "yyyy-MM-dd");
    this.state.fdat = dts;
    this.state.tdat = dts;
    if(this.pms.base) {
        this.state.fdat = this.pms.base.fdat;
        this.state.tdat = this.pms.base.tdat;
    }
      var bdts = format(this.state.today, "yyyy-01-01");
      this.state.bdat = bdts;

      this.state.msg = {};
      this.state.wnds = {};
      this.selitems = [
          { fkn: 'invsrp', text: 'Förskotts Utskrift' },
          { fkn: 'logi', text: 'Logi avstämning' }
      ];
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.krub_verify();
  }

  dlg_style() {
    var css = {};
    //css.width = window.innerWidth * 0.8;
    //css.height = window.innerHeight * 0.8;
    return css;
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      //css.height = window.innerHeight - 275;
      css.overflow = "auto";
      return css;
  }
  th_style(id) {
      var css = {};
      css.cursor = "pointer";
      if(id == this.state.sortid) css.color = "#080";
      return css;
  }
    row_style(row) {

        var css = {};
        if(row.e0typ == "xx") {
            css.background = "#3c983c";
            css.color = "#fff";
        }
        if(this.state.fkn == "FSK") {
            if(row.e0typ.substr(0,3) !== "fsk") {
                css.display = "none";
            }
        }
        return css;
    }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

  dt_fdat(e) {
    //alert(JSON.stringify(e));
    var dts = format(e, "yyyy-MM-dd");
    this.setState({ fdat: dts });
  }
    dt_tdat(e) {
        //alert(JSON.stringify(e));
        var dts = format(e, "yyyy-MM-dd");
        this.setState({ tdat: dts });
    }
    dt_bdat(e) {
        //alert(JSON.stringify(e));
        var dts = format(e, "yyyy-MM-dd");
        this.setState({ bdat: dts });
    }
  oneday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_td.current.updateDt(this.state.fdat);
  }
  addday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_fd.current.updateAdd();
    this.dt_td.current.updateAdd();
  }
  subday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_fd.current.updateSub();
    this.dt_td.current.updateSub();
  }

  change_fkn(e) {
      var self = this;
    //let cdat = this.state.seldate;
    //alert(JSON.stringify(e));
    let fkn = e.target.value;
    this.setState({fkn: fkn}, function () {
        self.syncTb("trsTable");
    });
    //this.setState({tra: [], tsa: [], tta: [] });
  }

  krub_verify() {
    var self = this;


      var msg = { info: true, text: "Hämtar notor ..."};
      self.setState({ dta: [], msg: msg });

    var prm = {};
    prm.req = "pms.krub.krub_verify";
    prm.fdat = this.state.fdat;
    prm.tdat = this.state.tdat;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        if(ret.ok != "000") {
            self.setState({ msg: {} });
            return;
        }
        self.setState({dta: ret.dta, msg: {} }, function () {
            self.syncTb("dtaTable");
        });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
    }

  tr_edit(row) {
    var self = this;
    var wnds = {};
    wnds.tropen = true;
    wnds.tro = row;
    this.setState({ wnds: wnds });
  }
    syncTb(tb){
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
        tdElements = tbel.getElementsByTagName('td');

        for (let i = 0; i < thElements.length; i++) {
            if(!thElements[i]) continue;
            if(!tdElements[i]) continue;
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
            width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
   tb_sort(typ) {
      var self = this;
      //var rvs = self.state.rvs;
      var tra = self.state.tra;
      var sz = this.state.sz_val;

      tra.sort((a,b) => (a[typ] > b[typ]) ? 1 : ((b[typ] > a[typ]) ? -1 : 0));
      self.setState({ tra: tra, sortid: typ });
   }
  tb_filtr() {
      var self = this;
      var rvs = self.state.rvs;
      var sz = this.state.sz_val;

      if(sz.length < 1) {
        self.setState({ rva: rvs });
          return;
      }
      sz = sz.toUpperCase();
      var rva = rvs.filter(function(row) {
            var brc = false;
            if(row.company.toUpperCase().includes(sz)) brc = true;
            if(row.name.toUpperCase().includes(sz)) brc = true;
            return brc;
      });
      self.setState({rva: rva });
   }
    sel_fkn(sel) {
        //alert(JSON.stringify(fkn));
        if(sel.fkn == "invsrp") {
            this.invs_rp();
        }
        if(sel.fkn == "logi") {
            this.trs_logi();
        }
    }
    invs_rp() {
        var self = this;
        var wnds = {};
        wnds.invsrp = true;
        wnds.fdat = this.state.fdat;
        wnds.tdat = this.state.tdat;
        wnds.bdat = this.state.bdat;
        this.setState({ wnds: wnds });
    }
    wnd_close = () => {
      this.props.ctx.setState({ wnds: {} });
    };
  
  render() {
    return (
      <div className="mx_base">
      { this.props.backdrop ? <div className="mx_backdrop" /> : null }
      <div className="mx_dlg mx_80 flex-col" style={ this.dlg_style() }>
      <div className="mx_h01 flex-row" style={{background: "#1a456b", color: "#fff"}}>
          <div>KRUBB KONTROLL</div>
          <div className="flex"></div>
          <div>UPPDATERA</div>
      </div>

      <div className="mx_content flex">


    <div className="flex-row flex-space-between">
        <div className="flex-row">
        <div className="flex-row">
              <div className="dt-btn-01" onClick={() => this.subday() } ><FontAwesomeIcon icon="minus" size="1x" /></div>
              <Dt_Cal dts={ this.state.fdat } rp="1" ref={this.dt_fd} getValue={(e)=> this.dt_fdat(e)} />
              <div className="dt-btn-01" onClick={() => this.oneday() } >1</div>
              <Dt_Cal dts={ this.state.tdat } rp="1" ref={this.dt_td} getValue={(e)=> this.dt_tdat(e)} />
              <div className="dt-btn-01" onClick={() => this.addday() } ><FontAwesomeIcon icon="plus" size="1x" /></div>
        </div>
        </div>

        <div className="flex"></div>

        <button className="btn btn-hw-blue w150" onClick={() => this.krub_verify() } aria-label="System" >
            Visa
        </button>
        <Dd_Sel items={ this.selitems } cls="web-x-icon" getValue={(e)=> this.sel_fkn(e)} />
    </div>
    <div className="divider_green"></div>

        <div id="dtaTable">
          <table className="table table-bordered table-hover" style={{marginBottom: "0px"}}>
                <thead>
                <tr>
                    <th style={ this.th_style("datum") } onClick={() => this.tb_sort("e0datum") }>Datum</th>
                    <th style={ this.th_style("rev") } onClick={() => this.tb_sort("e0typ") }>Dag</th>
                    <th style={ this.th_style("notanr") } onClick={() => this.tb_sort("e0typ") }>Omsättning</th>
                    <th style={ this.th_style("notanr") } onClick={() => this.tb_sort("e0typ") }>Betalt</th>
                    <th style={ this.th_style("notanr") } onClick={() => this.tb_sort("e0typ") }>Saldo</th>
                    <th style={ this.th_style("e0tid") } onClick={() => this.tb_sort("e0tid") }>Krubba</th>
                    <th style={ this.th_style("e0boknr") } onClick={() => this.tb_sort("e0boknr") }>Diff</th>
                    <th style={ this.th_style("e0antal") } onClick={() => this.tb_sort("e0antal") }>Saldo</th>
                    <th style={ this.th_style("e0ptyp") } onClick={() => this.tb_sort("e0ptyp") }>Status</th>
                </tr>
                </thead>
            </table>
            <div style={ this.tb_style() }>
          <table className="table table-bordered table-hover">
            <tbody>
                { this.state.dta.map((row, key) =>
                    <tr key={key} style={ this.row_style(row) } onDoubleClick={() => this.tr_edit(row) }>
                        <td>{ utils.toDate( row.datum ) }</td>
                        <td>{ row.wday }</td>
                        <td>{ utils.toPris( row.fsg ) }</td>
                        <td>{ utils.toPris( row.bet ) }</td>
                        <td>{ utils.toPris( row.balans ) }</td>
                        <td>{ utils.toPris( row.krubba ) }</td>
                        <td>{ utils.toPris( row.diff ) }</td>
                        <td>{ utils.toPris( row.saldo ) }</td>
                        <td>{ row.status }</td>
                    </tr>
                )}
                </tbody>
            </table>
        </div>
        </div>

        </div>
        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex-row">
            <button className="btn btn-hw-green w250" onClick={(event) => this.wnd_close() } >
                SKRIV UT
            </button>
            </div>
      </div>

        </div>
        </div>
  );
}
}
export default Krub_Verify;
