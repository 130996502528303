import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";
import Wnd_Ok from "../../lib/ui/wnd_ok";

class Pos_Manager extends React.Component {
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.state = {};
    this.state.bqa = [];
    this.state.tra = [];
    this.state.msg = {};
   }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      //this.trs();
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    tab_style(tabid) {
      var css = {};
      css.display = "none";
      if(this.state.tabval === tabid) css.display = "block";
      return css;
    }
    wnd_close() {
		//this.props.ctx.setState({ wnds: {} });
		this.props.cb();
	};
    tb_style() {
        var css = {};
        css.height = window.innerHeight - 275;
        return css;
    }
    cssCols (cid) {
        var css = {};
        if(this.state.cols[cid]) css.width = this.state.cols[cid].toString() + "px";
        //if(cid == "c01") alert(JSON.stringify(this.state.cols[cid]));
        return css;
    }
  syncCols (tb) {

    if (!document.getElementById(tb)) return;

    var cols = {};
    var ix = document.getElementById(tb).rows.length - 1;
    var ic = document.getElementById(tb).rows[0].cells.length;
    var ci = 0;
    while(ci < ic) {
        var cxi = ci + 1;
        var col = "c" + cxi.toString().padStart(2, "0");
        cols[col] = document.getElementById(tb).rows[ix].cells[ci].clientWidth + 1;
        ci = ci + 1;
    }
    this.setState({cols: cols });
    //alert(JSON.stringify(this.state.cols));
    //this.forceUpdate();
  };
  trs() {
    var self = this;
    var prm = {};
    prm.req = "pos.pos_bq.trs";
    prm.avd = this.state.avd;
    //prm.fplu = datum;
    //prm.tplu = datum;

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        self.setState({tra: ret.rca });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }
  row_bong(row) {
    var self = this;
    var prm = {};
    prm.req = "pos.pos_bq.bongrow";
    prm.row = row;
    prm.status = "09";

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        //self.setState({bqa: ret.rca });
        //toastr.success("OK", "SAPI");
        var msg = { ok: true, text: "BONGAD ..." };
        self.setState({msg: msg });
        setTimeout(function(){
            self.setState({msg: {} });
        }, 1000);
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }

  render() {
    if(!this.props.wnds || !this.props.wnds.manager) return "";

    var title = this.props.title;
    if(!title) title = "Titel";

	var html =
        <div className="mx_base">
        { this.props.wnds.backdrop ? <div className="mx_backdrop" /> : null }
        <div className="mx_dlg mx_90 flex-col" style={ this.dlg_style() }>
        <div className="mx_content flex">

            <div className="flex-row" style={{color: "#000", padding:"5px",height:"35px"}}>
                    <div className="flex-col flex">
                        <div>MANAGER</div>
                    </div>
                    <div className="flex-col">
                        <div>UPPDATERA</div>
                    </div>
            </div>
            <div className="divider_orange"></div>

        </div>

        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250 ripple" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex-row">
            </div>
            <button className="btn btn-hw-green w250 ripple" onClick={(event) => this.prn(event) } >
                SKRIV UT
            </button>
        </div>
      </div>
        <Wnd_Ok msg={this.state.msg} ctx={this} />
    </div>
	;
	return html;
  };
}

export default Pos_Manager;