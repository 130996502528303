import React, { useState, useEffect, useContext } from 'react';
import * as net from "../../../lib/net";

class Rr_Ljus extends React.Component {
  constructor(props, context) {
    super(props);

   //this.webStore = props.store;
    this.state = {};
    this.state.ynq = [];
    this.state.paa = [];
    this.state.aa = [];

    this.state.rro = {};
    this.state.rumsnr = "";
    this.state.wnds = {};
    if(props.wnds) {
        this.state.rumsnr = props.wnds.rumsnr;
    }
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }


    wnd_close() {
        var self = this;
        var ret = {};
        ret.ok = "999";
        if(self.props.close) self.props.close(self.props.ctx, ret);
        else self.props.ctx.setState({ wnds: {} });
	};
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }

  fkn_light(status) {
    var self = this;

    var prm = {};
    prm.req = "pms.hk.room_lights";
    prm.rumsnr = this.state.rumsnr;
    prm.status = status;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));
        if(ret.ok == "000") {
            if(self.props.close) self.props.close(self.props.ctx, ret);
		    else self.props.ctx.setState({ wnds: {} });
        }
    })
    .catch(function(e) {
        var txt = JSON.stringify(e);
    });
  }

  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "";

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="mx_backdrop" /> : null }
        <div className="m2_dlg mx_50 flex-col" style={ this.dlg_style() }>
            <div className="mx_h01 flex-row" style={{background: "#1a456b", color: "#fff"}}>
                    <div className="flex-col flex">
                        <div>TÄND / SLÄCK LJUS PÅ RUM</div>
                    </div>
                    <div className="flex-col">
                        <div>RUMSNR: { this.state.rumsnr.cdirumsnr() }</div>
                    </div>
            </div>
        <div className="mx_content flex">

            <div className="flex"></div>
            <div className="divider_orange"></div>
        <div className="web-row">
        <div className="web-col flex">
            <div className="wnd-btn" onClick={() => this.wnd_close() } >
                <div>STÄNG</div>
                <div></div>
            </div>
        </div>
         <div className="web-col flex" style={{padding: "0px 10px"}}>
            <div className="wnd-btn" onClick={() => this.fkn_light("0") } >
                <div>SLÄCK</div>
                <div></div>
            </div>
       </div>
         <div className="web-col flex" style={{padding: "0px 10px"}}>
            <div className="wnd-btn" onClick={() => this.fkn_light("1") } >
                <div>TÄND</div>
                <div></div>
            </div>
       </div>
       </div>

        </div>
      </div>
    </div>
	;
	return html;
  };
}

export default Rr_Ljus;