import React, { useState, useEffect, useRef, useContext } from 'react';
import { parseISO, format, getDate, getMonth, getWeek, eachDayOfInterval, eachWeekOfInterval } from 'date-fns'
import { setMonth, setYear, startOfWeek, endOfWeek, startOfMonth, endOfMonth, addMonths, subMonths, addDays, subDays, isSameDay } from 'date-fns'
import { sv } from 'date-fns/locale'

import './css/fm_input.css';

class Ff_Input extends React.Component {
  constructor(props, context) {
    super(props);

    this.node = React.createRef();
    //this.setNodeRef = this.setNodeRef.bind(this);

    this.state = {};
    this.state.itemx = [];
    this.state.lso = {};
    //this.state.lro = props.lro;
    this.state.tya = [
                {id: '01', text: 'Summerad'},
                {id: '02', text: 'Dag för dag'}
            ];
    this.state.itema = [
                {id: '01', text: 'VAL 01'},
                {id: '02', text: 'VAL 02'},
                {id: '03', text: 'VAL 03'}
            ];
    this.state.items = [];
    this.state.cols = {};
    this.state.sel_show = false;
    this.state.sel_step = 0;

    //context.posrend = this;
    this.state.today = new Date();
    this.state.ctrltext = "";
    if(props.text) this.state.ctrltext = props.text.toString();
    this.state.txtval = "";
    this.state.txtsel = 0;
    if(props.text) this.state.txtval = props.text.toString();
    this.state.row = 0;
    if(props.row) this.state.row = 1;

    this.txtsel = 0;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick);
  }
  componentDidMount() {
    //this.props.fetchWells()
    //  .then(res => this.setState({ wells: res.wells }) )
    //alert(JSON.stringify(this.props));
    document.addEventListener("mousedown", this.handleClick);
    //this.set_month(this.state.dat);
    if(this.props.outline) {
      //this.setState({ outline: this.props.outline })
    }
    this.setState({ txtsel: false })
  }

  cls() {
    var cls = "";
    cls = "ff_ctrl";
    if(this.props.className) cls = cls + " " + this.props.className;
    return cls;
  }

  clsFrm() {
    var cls = "";
    cls = "";
    if(this.props.row) cls = "flex-row";
    return cls;
  }
    tab_style(tabid) {
      var css = {};
      css.display = "none";
      if(this.state.tabval === tabid) css.display = "block";
      return css;
    }
    tab_head(tabid) {
      var css = {};
      css.background = "#fff";
      css.color = "#000";
      if(this.state.tabval === tabid) {
          css.background = "#080";
          css.color = "#fff";
      }
      return css;
    }

    showsel(e) {
      e.preventDefault();

      //alert("X: " + JSON.stringify(this.node.current.getBoundingClientRect()) );
      //alert("XX: " + this.node.current.offsetX);

      //alert(this.txtsel);

      var brc = this.state.sel_show;
      brc = !brc;
      //var val = this.props.text;
      //this.setState({sel_show: brc, sel_step: 0, txtval: val});
      //this.setState({txtsel: true});
   }
    onFocus(e) {
      e.preventDefault();
      this.setState({txtsel: 1});
    }
    onBlur(e) {
      e.preventDefault();
      this.setState({txtsel: 0});
      if(this.props.getValue) this.props.getValue(this.state.txtval);
    }

    changeHandler (e) {
      e.preventDefault();
      const value = e.target.value;
      //alert("CHANGE: " + JSON.stringify(value));
      if(this.props.onChange) this.props.onChange(value);
      this.setState({ txtval: value });
    }

  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  inputStyle() {
      var css = {};
      if(this.state.cal_show) {
        css.background = "#080";
        css.color = "#fff";
      }
      return css;
  }
  label_style() {
    var css = {};

    if(this.state.row) {
        css.width = "75px";
        css.fontSize = "14px";
        css.background = "#fff";
        css.color = "#800";
    }

    return css;
  }
    updateState = (value) => {
      //alert("CTRL: " + value);
      this.setState({ txtval: value });
    }
  render() {
    //if(!this.props.show) return "";

    //alert(this.props.ctx.state.lro);

    var title = this.props.title;
    if(!title) title = "URVAL";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div ref={this.node} className={ this.cls() }>
          <div className={ this.clsFrm() } onClick={(e) => this.showsel(e) }>
          <label className="ff_label" style={ this.label_style() }>{ this.props.label }</label>
          <input value={this.state.txtval} onChange={(e) => this.changeHandler(e)}
                 type="text" className="fm_input form-control"
                 placeholder="" required
                 onFocus={(e) => this.onFocus(e)} onBlur={(e) => this.onBlur(e)}
           />
        </div>
        </div>
	;
	return html;
  };
}

export default Ff_Input;

//{...this.props}