import React, { useState, useEffect, useContext } from 'react';
import {format} from "date-fns";

import * as net from "../../../../lib/net";
import * as utils from "../../../../assets/utils";

import Dd_Sel from "../../../../lib/ui/dd_sel";
import Td_Sel from "../../../../lib/ui/td_sel";
import PdfNotaDlg from "../pdf_nota_dlg";
import sso from "../../../../sso";

class Petty_Kvitto extends React.Component {
  constructor(props, context) {
    super(props);

     this.selitems = [
        {fkn: 'setup', text: 'INSTÄLLNINGAR'},
        {fkn: 'upd', text: 'UPPDATERA'},
        {fkn: 'sys', text: 'SYSTEM'},
        {fkn: 'logout', text: 'LOGGA UT'}
    ];

    this.fkn_close = null;
    this.store = props.store;

    this.state = {};
    this.state.ynq = [];

    this.state.paa = [];
    this.state.aa = [];
    this.state.selrow = {};
    this.state.srow = {};

    this.state.lang = "se";
    this.state.boknr = "";
    this.state.rvo = {};
    this.state.nta = [];
    if(props.wnds) {
        //this.fkn_close = props.wnds.fkn_close;
        this.state.boknr = props.wnds.boknr;
        this.state.gso = props.wnds.gso;
        this.state.nto = props.wnds.nto;
    }

    this.state.today = new Date();
    var dts = format(this.state.today, "yyyy-MM-dd");
    this.state.dts = dts;

    this.state.pdf = 0;
    this.state.pdf_content = null;
    this.state.loading = 0;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      //alert(JSON.stringify(this.state.nto));
      this.kvitto();
  }
  pdf_done (ret) {
    this.setState({loading: 0, pdf: 0, pdf_content: null });
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }

    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    wnd_close() {
      if(this.fkn_close) this.fkn_close(this.props.ctx);
      else this.props.ctx.setState({ wnds: {} });
	};
  tb_style() {
      var css = {};
      //css.height = window.innerHeight - 275;
      css.overflow = "auto";
      return css;
  }
  row_style(row) {
    var srow = this.state.srow;
    var css = {};
    if(srow[row.etr02.e0lopnr]) {
        css.background = "#080";
        css.color = "#fff";
    }
    return css;
  }
  clear() {
    this.setState({srow: {}});
  }
  set_all() {
    var srow = this.state.srow;
    for(var row of this.state.nta) {
        srow[row.etr02.e0lopnr] = row;
    }
    this.setState({srow: srow});
  }
  sel_row(row) {
    var srow = this.state.srow;

    if(srow[row.etr02.e0lopnr]) {
      delete( srow[row.etr02.e0lopnr] );
    }
    else srow[row.etr02.e0lopnr] = row;
    this.setState({srow: srow});
  }
  kvitto() {
    var self = this;

    var prm = {};
    prm.req = "pms.charge.charges_xx_nota";
    prm.boknr = this.state.boknr;
    prm.glopnr = this.state.gso.r0glopnr;
    prm.notanr = this.state.nto.notanr;
    //alert(JSON.stringify(prm));

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        self.setState({nta: ret.rco});
        self.syncTb("kvittoTable");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
    syncTb(tb){
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
        tdElements = tbel.getElementsByTagName('td');
        if(tdElements.length == 0) {
            //alert("No td");
             return;
       }
        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
            width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
  val_date(val) {
      var ret = "";
      if(val) ret = val.toString().cdidate();
      return ret;
  }
  prn_spec() {
    var self = this;

    var prm = {};
    prm.req = "pms.charge.prn_spec";
    prm.boknr = this.state.boknr;
    prm.glopnr = this.state.gso.r0glopnr;
    prm.notanr = this.state.nto.notanr;
    //alert(JSON.stringify(prm));
    //return;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.ok));

        //var uri = "data:application/pdf;base64," + ret.pdf;
        self.setState({pdf: 1, pdf_content: ret.pdf });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
    prn_kvitto_pdf() {
        var self = this;

        var prm = {};
        prm.req = "pms.charge.kvitto_gst_pdf";
        prm.boknr = this.state.gso.r0boknr;
        prm.glopnr = this.state.gso.r0glopnr;
        prm.gso = this.state.gso;
        prm.notanr = this.state.nto.notanr;
        prm.prnnr = sso.seo.prnnr;
        prm.petty = 1;
        prm.lang = this.state.lang;
        //alert(JSON.stringify(prm));
        //return;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.ok));

                self.setState({pdf: 1, pdf_content: ret.pdf });
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
  prn_kvitto() {
    var self = this;

    var prm = {};
    prm.req = "pms.charge.kvitto_petty";
    prm.boknr = this.state.boknr;
    prm.glopnr = this.state.gso.r0glopnr;
    prm.gso = this.state.gso;
    prm.notanr = this.state.nto.notanr;
    prm.prnnr = sso.seo.prnnr;
    prm.lang = this.state.lang;
    //alert(JSON.stringify(prm));
    //return;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.ok));

        //var uri = "data:application/pdf;base64," + ret.pdf;
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  prn_mx() {
    var self = this;
  }
  recall() {
    var self = this;

    var prm = {};
    prm.req = "pms.charge.recall_nota";
    prm.boknr = this.state.boknr;
    prm.glopnr = this.state.gso.r0glopnr;
    prm.notanr = this.state.nto.notanr;
    //alert(JSON.stringify(prm));
    //return;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.ok));

      if(self.fkn_close) self.fkn_close(self.props.ctx);
      else self.props.ctx.setState({ wnds: {} });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  corr() {
    var self = this;

    var prm = {};
    prm.req = "pms.charge.corr_nota";
    prm.boknr = this.state.boknr;
    prm.glopnr = this.state.gso.r0glopnr;
    prm.notanr = this.state.nto.notanr;
    //alert(JSON.stringify(prm));
    //return;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.ok));

      if(self.fkn_close) self.fkn_close(self.props.ctx);
      else self.props.ctx.setState({ wnds: {} });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  showLang() {
    var langtxt = "Svenska";
    if(this.state.lang == "en") langtxt = "Engelska";
    return langtxt;
}
set_lang() {
    var lang = this.state.lang;
    if(lang == "en") lang = "se";
    else lang = "en";
    this.setState({lang: lang});
  }

  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "";

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="m2_backdrop" /> : null }
        <div className="m2_dlg mx_80h flex-col" style={ this.dlg_style() }>
        <div className="flex-row " style={{padding:"0px",height:"50px",lineHeight:"50px", background: "#1a456b", color: "#fff"}}>
                <div className="flex-row flex" style={{padding:"0px 5px", fontSize: "22px"}}>
                    <div>KVITTO { this.state.rumsnr }</div>
                </div>
            <div className="wnd-close-btn" onClick={() => this.wnd_close() }>X</div>
        </div>
        <div className="mx_content flex">

        <div className="web-col flex" style={{padding: "0px"}}>

        <div id="kvittoTable" className="flex">
          <table className="table table-bordered table-hover tb" style={{marginBottom: "0px"}}>
            <thead>
            <tr>
                <th>Datum</th>
                <th>T</th>
                <th>Typ</th>
                <th>Text</th>
                <th>Antal</th>
                <th>Pris</th>
                <th>Summa</th>
                <th>Tecken</th>
                <th>Löpnr</th>
            </tr>
            </thead>
        </table>
        <div style={ this.tb_style() }>
          <table className="table table-bordered table-hover tb">
            <tbody>
            { this.state.nta.map((row, key) =>
            <tr key={key} style={ this.row_style(row) } onClick={ () => this.sel_row(row) }>
                <td>{ this.val_date( row.etr02.e0datum ) }</td>
                <td>{ row.etr02.e0bastyp }</td>
                <td>{ row.etr02.e0typ }</td>
                <td>{ utils.toLang( row.etr02.e0text ) }</td>
                <td className="text-center">{ utils.toNum( row.etr02.e0antal ) }</td>
                <td className="text-right">{ utils.toPris( row.etr02.e0pris ) }</td>
                <td className="text-right">{ utils.toPris( (row.etr02.e0antal * 1) * (row.etr02.e0pris * 1) ) }</td>
                <td>{ row.etr02.e0tkn }</td>
                <td>{ utils.toNum( row.etr02.e0lopnr ) }</td>
            </tr>
            )}
            </tbody>
        </table>
        </div>
        </div>

        <div className="flex-row px-2">
            <div className="flex"></div>
            <div className="web-link" onClick={() => this.set_lang() }>{ this.showLang() }</div>
            <div className="flex"></div>
        </div>
        <div className="divider_orange"></div>

        <div className="web-row">
        <div className="web-col flex">
            <div className="wnd-btn" onClick={() => this.prn_mx() } >
                <div>MAILA KVITTO</div>
                <div></div>
            </div>
            <div className="wnd-btn" onClick={() => this.wnd_close() } >
                <div>STÄNG</div>
                <div></div>
            </div>
        </div>
         <div className="web-col flex" style={{padding: "0px 10px"}}>
            <div className="wnd-btn" onClick={() => this.recall() } >
                <div>ÅTERVINN KVITTO</div>
                <div></div>
            </div>
            <div className="wnd-btn" onClick={() => this.corr() } >
                <div>KORRIGERA KVITTO</div>
                <div></div>
            </div>
       </div>
         <div className="web-col flex" style={{padding: "0px 10px"}}>
            <div className="wnd-btn" onClick={() => this.prn_kvitto_pdf() } >
                <div>VISA KVITTO</div>
                <div></div>
            </div>
            <div className="wnd-btn" onClick={() => this.prn_kvitto() } >
                <div>SKRIV UT</div>
                <div></div>
            </div>
       </div>
       </div>

        </div>

        </div>
      </div>
        <PdfNotaDlg show={this.state.pdf} pdf={this.state.pdf_content} done={(ret) => this.pdf_done(ret)} backdrop={true}/>
    </div>
	;
	return html;
  };
}

export default Petty_Kvitto;