import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";

import KvittoWnd from "./dlg/kvitto_wnd";
import PdfRpDlg from "./fkn/pdf_rp_dlg";
import Dt_Cal from "../../lib/ui/dt_cal";
import {format} from "date-fns";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Pos_Kvitton extends React.Component {
    constructor(props, context) {
        super(props);

        this.ref_dat = React.createRef();

        this.pos = props.store.pos;
        this.state = { pgid: 11 };
        this.state.today = new Date();
        var dts = format(this.state.today, "yyyy-MM-dd");
        this.state.datum = dts;
        this.state.avd = "01";
        this.state.raa = [];
        this.state.aa = [];
        //context.posrend = this;
        this.state.kvitto = false;
        this.state.kvo = {};
        this.state.pdf = 0;
        this.state.pdf_content = null;
        if(this.pos.deps && this.pos.deps.length) {
            this.state.avd = this.pos.deps[0].avd;
        }
    }
    componentDidUpdate() {
    }
    componentWillUnmount() {
    }
    componentDidMount() {
        //this.kvitton();
    }

    body_style() {
        var css = {};
        css.height = window.innerHeight - 280;
        return css;
    }
    tb_style() {
        var css = {};
        css.height = window.innerHeight - 250;
        return css;
    }
    onUnitChange(e) {
        this.setState({unit: e.value});
    }
    dt_dat(e) {
        //alert(JSON.stringify(e));
        var dts = format(e, "yyyy-MM-dd");
        this.setState({ datum: dts });
    }
    addday(e) {
        //alert(JSON.stringify(e));
        //this.setState({ tdat: this.state.fdat });
        this.ref_dat.current.updateAdd();
    }
    subday(e) {
        //alert(JSON.stringify(e));
        //this.setState({ tdat: this.state.fdat });
        this.ref_dat.current.updateSub();
    }
    xdt_dat(e) {
        //alert(JSON.stringify(e));
        var dts = format(e, "yyyy-MM-dd");
        this.setState({ datum: dts });
    }
    change_avd(e) {
        //let cdat = this.state.seldate;
        let avd = e.target.value;
        this.setState({avd: avd});
    }
    kvitto_edit(row) {
        this.setState({kvo: row, kvitto: true});
    }
    pdf_done (ret) {
        this.setState({loading: 0, pdf: 0, pdf_content: null });
    }

    kvitton() {
        var self = this;
        var prm = {};
        prm.req = "pos.pos_eko.invh_list";
        //prm.req = "pos.pos_red.jr_red";
        prm.avd = this.state.avd;
        prm.datum = this.state.datum;
        prm.fdat = this.state.datum;
        prm.tdat = this.state.datum;
        prm.favd = this.state.avd;
        prm.tavd = this.state.avd;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret));

                if(ret.ok != "000") {
                    alert(JSON.stringify(ret));
                    return
                }
                if(ret.rca) {
                    self.setState({raa: ret.rca });
                }
                //toastr.success("OK", "SAPI");
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
                //gda.wndError(txt);
            });
    }
    rp_jr() {
        var self = this;
        //var datum = moment().subtract(14, "days");
        var favd = "01";
        var tavd = "01";

        var prm = {};
        //prm.req = "pos.pos_trs.trs_per";
        //prm.req = "pos.pos_rp.rp_jr";
        prm.req = "pos.pos_red.jr_prn_red";
        prm.avd = "01";
        //prm.fdat = datum.format("YYYYMMDD");
        //prm.tdat = datum.format("YYYYMMDD");
        prm.favd = favd;
        prm.tavd = tavd;
        //if($scope.ftid)	prm.ftid = $scope.ftid.format("HHmm");
        //if($scope.ttid)	prm.ttid = $scope.ttid.format("HHmm");

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.pdf));

                var o = {};
                //o.deps = ret.rco;
                //alert(JSON.stringify(o));
                //self.setState({tra: ret.rca });
                self.setState({pdf_content: ret.pdf });
                //toastr.success("OK", "SAPI");
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
                //gda.wndError(txt);
            });
    }

    render() {
        return (
            <div className="web-p00 flex flex-col">
                <div className="">
                    Kvitton
                </div>

                <div className="web-p01">
                    <div className="flex-row">
                        <div className="flex-row">
                            <div className="dt-btn-01" onClick={() => this.subday() } ><FontAwesomeIcon icon="minus" size="1x" /></div>
                            <Dt_Cal dts={this.state.datum} rp="1" ref={this.ref_dat} getValue={(e)=> this.dt_dat(e)} />
                            <div className="dt-btn-01" onClick={() => this.addday() } ><FontAwesomeIcon icon="plus" size="1x" /></div>
                        </div>
                        <select className="form-control w250" placeholder="Avdelning" onChange={(e) => this.change_avd(e)}>
                            { this.pos.deps.map((row, key) =>
                                <option key={"xx_" + key} value={row.avd}>{row.avd} - {row.text}</option>
                            )}
                        </select>
                        <div className="flex"></div>
                        <button className="btn btn-hw-blue w150 ripple" onClick={() => this.kvitton() } aria-label="System" >
                            Visa
                        </button>
                        <button className="btn btn-hw-blue w150 ripple" onClick={() => this.rp_jr() } aria-label="System" >
                            Skriv ut
                        </button>
                    </div>
                    <div className="divider">
                    </div>

                    <div className="web-row">
                        <table className="table table-bordered table-hover tb fixed_header">
                            <thead>
                            <tr>
                                <th>Notanr</th>
                                <th>Avd</th>
                                <th>Bordsnr</th>
                                <th>Start</th>
                                <th>Slut</th>
                                <th>Fsg</th>
                                <th>Bet</th>
                                <th>Diff</th>
                                <th>Bettyp</th>
                                <th>Rader</th>
                                <th>Terminal</th>
                                <th>Kassör</th>
                                <th style={{width:"40px"}} ></th>
                            </tr>
                            </thead>
                            <tbody style={ this.tb_style() }>
                            { this.state.raa.map((row, key) =>
                                <tr key={key} id={ row.notanr } onDoubleClick={() => this.kvitto_edit(row) } >
                                    <td>{ row.notanr }</td>
                                    <td className="text-center">{ row.avd }</td>
                                    <td className="text-center">{ row.bord }</td>
                                    <td className="text-center">{ row.start.cditid() }</td>
                                    <td className="text-center">{ row.slut.cditid() }</td>
                                    <td className="text-right">{ row.fsg.cdipris() }</td>
                                    <td className="text-right">{ row.bet.cdipris() }</td>
                                    <td className="text-right">{ row.diff.cdipris() }</td>
                                    <td>{ row.bettyp }</td>
                                    <td className="text-center">{ row.rader.length }</td>
                                    <td className="text-center">{ row.tnr }</td>
                                    <td className="text-center">{ row.cnr }</td>
                                    <td style={{width:"40px",cursor:"pointer"}} onClick={() => this.kvitto_edit(row) }><i className="fa fa-cog text-danger"></i></td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>

                </div>

                { this.state.kvitto ? <KvittoWnd kvo={this.state.kvo} ctx={this} store={this.store} backdrop={true}/> : null }
                <PdfRpDlg show={this.state.pdf} pdf={this.state.pdf_content} done={(ret) => this.pdf_done(ret)} backdrop={true}/>
            </div>
        );
    }
}
export default Pos_Kvitton;
