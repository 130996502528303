import React, { useState, useEffect, useContext } from 'react';
import {Route, Routes} from "react-router-dom";

import Und_Home from './und/home.js';
import Und_Rps from './und/rps.js';
import Und_List from './und/list.js';
import Und_Reg from './und/reg.js';
import sso from "../sso";
import {withRouter} from "../lib/react/withRouter";

function pg_home() {

    var html =
    <div className="web-content">
      Hem
    </div>
    ;
    return html;
}

class Fakt_Und extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, deps: [] };
      var po = {};
      po.rps = { "fid": "f05", "url": "/fakt/und/rps", "name": "Rapporter", "active": false };
      po.list = { "fid": "f05", "url": "/fakt/und/list", "name": "Listor", "active": false };
      po.reg = { "fid": "f05", "url": "/fakt/und/reg", "name": "Register", "active": false };
      this.state.po = po;
      this.state.pa = [];
      this.state.pa = Object.values(po);
  }
    fknGo(row) {
        sso.seo.sfkn = row;
        this.props.navigate(row.url);
    }
    br_css(row) {
        var css = {};
        if(sso.seo.sfkn && (row.url == sso.seo.sfkn.url)) css.color = "#080";
        //var brc = false;
        //if(row.url == sbo.state.url) brc = true;
        return css;
    }

  render() {
    return (
        <div className="web-app">
            { this.props.store.cfg.bread ?
                <div className="web-bread">
                    <div className="breadcrumb">
                        { this.state.pa.map((row, key) =>
                            <div key={key} className="breadcrumb-item" style={this.br_css(row)} onClick={() => this.fknGo(row)}>{row.name}</div>
                        )}
                        <div className="flex"></div>
                        <div className="cdigreen">{ sso.seo.sfkn ? sso.seo.sfkn.name : null }</div>
                    </div>
                </div>
                : null }
        <Routes>
          <Route path="/xx" component={pg_home} />
            <Route path="rps" element={ <Und_Rps store={this.webStore} />} />
            <Route path="list" element={ <Und_List store={this.webStore} />} />
            <Route path="reg" element={ <Und_Reg store={this.webStore} />} />
            <Route path="*" element={ <Und_Home store={this.webStore} />} />
        </Routes>
        </div>
  );
}
}
//Sys.contextType = ADOContext;

export default withRouter(Fakt_Und);
