import React, { useState, useEffect, useContext } from 'react';

import * as utils from "../../../assets/utils";
import * as net from "../../../lib/net";
import Td_Sel from "../../../lib/ui/td_sel";

import Pms_Rvo from "../../rv/fb/rvo";
import Pms_Gso from "../../rv/gst/gso";
import Ank_Gst_Add from "./ank_gst_add";

class Pms_AnkBr extends React.Component {
  constructor(props, context) {
    super(props);

    //alert('PBO CTX: ' + JSON.stringify(props.pbo));
   //this.webStore = props.store;
    this.state = {};
    this.state.ynq = [];
    //this.state.vga = [];
    this.state.wnds = {};

    this.state.paa = [];
    this.state.aa = [];
    this.state.gsa = [];

    this.state.boknr = "";
    this.state.rvo = {};
    this.state.bro = {};
    if(props.wnds) {
        this.state.boknr = props.wnds.boknr;
        this.state.rvo = props.wnds.rvo;
        this.state.bro = this.state.rvo.rblx0;
    }
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      this.gsts();
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }


    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    wnd_close() {
		this.props.ctx.setState({ wnds: {} });
	};
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  cssCols (cid) {
      var css = {};
      if(this.state.cols[cid]) css.width = this.state.cols[cid].toString() + "px";
      //if(cid == "c01") alert(JSON.stringify(this.state.cols[cid]));
      return css;
  }
  fknSel(sel) {
    //alert(JSON.stringify(fkn));
      if(sel == "rvo") {
        var wnds = {};
        wnds.wnd_rvo = true;
        this.setState({wnds: wnds});
      }
      if(sel == "gso") {
        var wnds = {};
        wnds.wnd_gso = true;
        this.setState({wnds: wnds});
      }
  }
  ank_gst_add() {
      var wnds = {};
      wnds.gstadd = true;
      wnds.boknr = this.state.boknr;
      wnds.rvo = this.state.rvo;
      wnds.bro = this.state.bro;
      wnds.cb = this.cb_gstadd;
    this.setState({wnds: wnds});
  }
    cb_gstadd(ctx, ret) {
        if(ret.ok == "000") {
          ctx.gsts();
        }
        ctx.setState({ wnds: {} });
    };
  gsts() {
    var self = this;

    var prm = {};
    prm.req = "pms.gst.gsa_br";
    prm.boknr = this.state.boknr;
    prm.blopnr = this.state.bro.r0lopnr;
    //alert(JSON.stringify(prm));

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok == "000") {
            self.setState({gsa: ret.rca });
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
    return;
  }

  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "";

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="mx_backdrop" /> : null }
        <div className="mx_dlg mx_80 flex-col" style={ this.dlg_style() }>
        <div className="mx_h01 flex-row" style={{background: "#1a456b", color: "#fff"}}>
            <div>DELBOKNING: { this.state.boknr } / { this.state.rvo.blopnr }</div>
            <div className="flex"></div>
            <div>UPPDATERA</div>
        </div>
        <div className="mx_content flex">

             <div className="flex-col flex">
             <div className="flex-row">

            <div className="txt_box txt_bg_01 flex-col flex">
            <div className="flex-row">
                    <div className="flex-column flex py-2">
                         <div className="txt_lbl">FÖRETAG:</div>
                         <div className="txt_data">{ this.state.rvo.company.toswe() }</div>
                    </div>
                    <div className="flex-column flex py-2">
                         <div className="txt_lbl">TELE:</div>
                         <div className="txt_data">{ this.state.rvo.tele }</div>
                    </div>
            </div>
            <div className="flex-row">
                    <div className="flex-column flex py-2">
                        <div className="txt_lbl">ANM:</div>
                        <div className="txt_data">{ this.state.rvo.anm.toswe() }</div>
                    </div>
            </div>
            <div className="flex"></div>
            <div className="flex-row">
                <div className="wnd_link">
                    RENSA
                </div>
                <div className="flex"></div>
                <div className="wnd_link" onClick={() => this.gst_upd() }>
                    SPARA BOKNING
                </div>
            </div>
            </div>

             <div className="txt_box txt_bg_01 txt_22">
                    <div className="flex-row flex">
                        <div className="txt_lbl flex">BOKAT VÄRDE:</div>
                        <div className="txt_price">{ this.state.rvo.charges.bokat.cdipris() }</div>
                    </div>
                    <div className="flex-row flex">
                        <div className="txt_lbl flex">SALDO:</div>
                        <div className="txt_price">{ this.state.rvo.charges.saldo.cdipris() }</div>
                    </div>
                    <div className="flex-row flex">
                        <div className="txt_lbl flex">BETALT:</div>
                        <div className="txt_price">{ this.state.rvo.charges.paid.cdipris() }</div>
                    </div>
                    <div className="flex-row flex">
                        <div className="txt_lbl flex">ATT BETALA:</div>
                        <div className="txt_price">{ this.state.rvo.charges.topay.cdipris() }</div>
                    </div>
            </div>
            </div>

            <div className="divider_orange"></div>

             <div className="flex-row flex">
             <div className="flex-col flex">
            <div className="txt_box flex-col">
            <div className="flex-row">
                <div className="wnd_link">
                    DELBOKNING
                </div>
                <div className="flex"></div>
                <div className="wnd_link" onClick={() => this.gst_upd() }>
                    UPPDATERA
                </div>
            </div>
            <div className="flex-row">
                    <div className="flex-column flex py-2">
                        <div className="txt_lbl">PERIOD:</div>
                        <div className="txt_data">{ this.state.rvo.ank.cdidate() } - { this.state.rvo.avr.cdidate() }</div>
                    </div>
                    <div className="flex-column flex py-2">
                        <div className="txt_lbl">RUMSTYP:</div>
                        <div className="txt_data">{ this.state.rvo.rt.toswe() }</div>
                    </div>
            </div>
            <div className="flex-row">
                    <div className="flex-column flex py-2">
                        <div className="txt_lbl">REGR:</div>
                        <div className="txt_data">{ this.state.rvo.nrr } / { this.state.rvo.regr }</div>
                    </div>
                    <div className="flex-column flex py-2">
                        <div className="txt_lbl">REGG:</div>
                        <div className="txt_data">{ this.state.rvo.nrg } / { this.state.rvo.regg }</div>
                    </div>
            </div>
            </div>

            <div className="divider_orange"></div>

             <div className="flex-col flex">
            <div className="flex-row">
                <div className="wnd_link">
                    GÄSTER
                </div>
                <div className="flex"></div>
                <div className="wnd_link" onClick={() => this.gst_upd() }>
                    UPPDATERA
                </div>
            </div>
                <table className="table" style={{border: "0px", margin: "0px", padding: "0px"}}>
                    <thead style={{fontSize: "14px", fontWeight: "normal", color: "#080", border: "0px", margin: "0px", padding: "0px"}}>
                    <tr>
                        <th style={{textAlign: "left"}}>Gäst</th>
                        <th style={{textAlign: "left"}}>Rumsnr</th>
                        <th style={{textAlign: "left"}}>Namn</th>
                        <th style={{textAlign: "left"}}>Gäster</th>
                        <th style={{textAlign: "left"}}>Status</th>
                        <th style={{textAlign: "center", color: "#800"}}></th>
                    </tr>
                    </thead>
                    <tbody style={{fontSize: "18px", color: "#000", border: "0px", margin: "0px", padding: "0px", borderBottom: "1px solid #ccc"}}>
                    { this.state.gsa.map((row, key) =>
                    <tr>
                        <td>{ utils.toNum( row.r0glopnr ) }</td>
                        <td>{ utils.toRumsnr( row.r0rumsnr ) }</td>
                        <td>{ utils.toLang( row.r0namn ) }</td>
                        <td>{ utils.toNum( row.r0nrg ) }</td>
                        <td>{ row.r0status }</td>
                        <td>
                            <Td_Sel items={ this.pkatitems } getValue={(e)=> this.br_gst_edit(row, e)} />
                        </td>
                    </tr>
                    )}
                    </tbody>
                </table>
            </div>

            </div>
            </div>

            <div className="divider_orange"></div>

        </div>

        <div className="web-row">
        <div className="web-col flex">
            <div className="wnd-btn" onClick={() => this.fknSel("rvo") } >
                <div>ÖPPNA BOKNING</div>
                <div></div>
            </div>
            <div className="wnd-btn" onClick={() => this.wnd_close() } >
                <div>STÄNG</div>
                <div></div>
            </div>
        </div>
         <div className="web-col flex" style={{padding: "0px 10px"}}>
            <div className="wnd-btn" onClick={() => this.fknSel("confirm") } >
                <div>BEKRÄFTA BOKNING</div>
                <div></div>
            </div>
       </div>
         <div className="web-col flex" style={{padding: "0px 10px"}}>
            <div className="wnd-btn" onClick={() => this.ank_gst_add() } >
                <div>SKAPA GÄST</div>
                <div></div>
            </div>
       </div>
       </div>

        </div>
      </div>
            { this.state.wnds.wnd_rvo ?
        <Pms_Rvo close={this.rvo_close} boknr={this.state.boknr} ctx={this} store={this.props.store} backdrop={true}/>
            : null }
            { this.state.wnds.wnd_gso ?
        <Pms_Gso close={this.rvo_close} boknr={this.state.boknr} ctx={this} store={this.props.store} backdrop={true}/>
            : null }
            { this.state.wnds.gstadd ?
        <Ank_Gst_Add wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
            : null }
    </div>
	;
	return html;
  };
}

export default Pms_AnkBr;