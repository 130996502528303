import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";
import * as utils from "../../assets/utils";
import Dt_Cal from "../../lib/ui/dt_cal";
import Dd_Sel from "../../lib/ui/dd_sel";
import * as q from "../../lib/defer";

import PdfDlg from "../../lib/pdf/pdf_dlg";
import Order_Edit from "./fkn/order_edit";
import {format, getHours, subDays} from "date-fns";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Op_Orders extends React.Component {
  constructor(props, context) {
    super(props);

      this.dt_dt = React.createRef();

    this.webStore = props.store;
    this.state = { pgid: 11, tra: [] };
    this.state.seldate = new Date();
    this.state.typ = "01";
    this.state.datum = "20201231";

    this.state.ora = [];
    this.state.rda = [];
    this.state.aa = [];
    this.state.kta = [];
    this.state.pdf = 0;
    this.state.pdf_content = null;
    this.state.loading = 0;
    this.state.wnds = {};
    this.state.vpo = {};

      this.state.today = new Date();
      var hh = getHours(this.state.today);
      if( hh < 5) {
          const dt = subDays(new Date(), 1);
          this.state.dts = format(dt, "yyyy-MM-dd");
      }
      else this.state.dts = format(this.state.today, "yyyy-MM-dd");

    this.state.loading = 0;
    this.state.urval = 1;
    this.state.uaa = [
        {id: '1', text: 'MED SALDO'},
        {id: '0', text: 'ALLA'}
    ];
    this.state.fknitems = [
        {fkn: 'fkn_active', text: 'AKTIVA'},
        {fkn: 'fkn_inactive', text: 'INAKTIVA'},
        {fkn: 'prn_saldo', text: 'KORTSALDO'}
    ];
      this.state.showfkn = 0;
      this.state.showtxt = "ENDAST KLARA";
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    var self = this;
    self.order_list();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
    tb_style() {
        var css = {};
        css.height = window.innerHeight - 250;
        return css;
    }
    row_style(row) {
        var css = {};
        //css.color = "#222";
        if(row.order00.paid != "1") {
            css.background = "#800";
            css.color = "#fff";
        }
        return css;
    }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }
    dt_dat(e) {
      var self = this;
        //alert(JSON.stringify(e));
        var dts = format(e, "yyyy-MM-dd");
        this.setState({ dts: dts }, function() {
            self.order_list();
        });
    }
    addday(e) {
        //alert(JSON.stringify(e));
        //this.setState({ tdat: this.state.fdat });
        this.dt_dt.current.updateAdd();
    }
    subday(e) {
        //alert(JSON.stringify(e));
        //this.setState({ tdat: this.state.fdat });
        this.dt_dt.current.updateSub();
    }

  fltr(aa) {
      var self = this;
      //self.setState({loading: 0 });
      //var aa = self.state.rga;
      var a = [];
      for(var row of aa) {
          if(this.state.urval == 1) {
              var saldo = (row.vip02.v2tillgodo * 1);
              if(saldo == 0) continue;
              var text = (row.vip00.v0company);
              //if(text.indexOf("A") < 0) continue;
          }
          a.push( row );
      }
      return a;
    //self.setState({rda: a, loading: 0 });
  }

  ladda() {
    var self = this;
    var wnds = {};
    wnds.vipladda = 1;
    self.setState({ wnds: wnds });
  }
  order_edit(vpo) {
    var self = this;
    var wnds = {};
    wnds.orderedit = 1;
    wnds.vpo = vpo;
    self.setState({ wnds: wnds });
  }

  ladda_do() {
    var self = this;
    var prm = {};
    prm.req = "vcm.pres.refill";
    prm.typ = this.state.typ;
    prm.id = "12";
    prm.belopp = "4500";

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        alert(JSON.stringify(ret));
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }
    vip_gen() {
        var self = this;
        var prm = {};
        prm.req = "vip.vip_reg.vip_gen";
        prm.viptyp = this.state.typ;

        net.sio_req(prm)
        .then(function(ret){
            alert(JSON.stringify(ret));
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }
  order_list() {
    var self = this;
    self.setState({ora: [], loading: 1 });

    var prm = {};
    prm.req = "pos.pos_ecom_order.order_list";
    prm.dts = this.state.dts;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        //var a = self.fltr(ret.rca);
        self.setState({ora: ret.rca, loading: 0 });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  prn_fkn(fkn) {
    var self = this;
    var prm = {};
    prm.req = fkn;
    prm.typ = this.state.typ;
    prm.viptyp = this.state.typ;
    prm.datum = "20201231";

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        self.setState({pdf: 1, pdf_content: ret.pdf });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  pdf_done (ret) {
    this.setState({loading: 0, pdf: 0, pdf_content: null });
  }

    show_sel () {
        if(this.state.showfkn == 0) this.setState({showfkn: 1, showtxt: "PÅGÅENDE" });
        else if(this.state.showfkn == 1) this.setState({showfkn: 2, showtxt: "ALLA" });
        else this.setState({showfkn: 0, showtxt: "ENDAST KLARA" });
    }

    ora_get() {
      var a = [];
      for(var row of this.state.ora)
          if(this.state.showfkn == 0) {
              if(row.order00.paid == "1") {
                  a.push( row );
              }
          }
        else if(this.state.showfkn == 1) {
            if(row.order00.paid != "1") {
                a.push( row );
            }
        }
        else {
            a.push( row );
        }
        return a;
    }

  render() {
    return (
    <div className="web-p00 flex flex-col">
        <div className="">
            ORDRAR
        </div>
    <div className="divider_orange"></div>

    <div className="flex-row">
        <div className="flex-row">
            <div className="dt-btn-01" onClick={() => this.subday() } ><FontAwesomeIcon icon="minus" size="1x" /></div>
            <Dt_Cal dts={this.state.dts} rp="1" ref={this.dt_dt} getValue={(e)=> this.dt_dat(e)} />
            <div className="dt-btn-01" onClick={() => this.addday() } ><FontAwesomeIcon icon="plus" size="1x" /></div>
        </div>

        <div className="flex"></div>

        <button className="btn btn-hw-blue w150" onClick={() => this.show_sel() } aria-label="System" >
            { this.state.showtxt }
        </button>
        <button className="btn btn-hw-blue w150" onClick={() => this.order_list() } aria-label="System" >
            VISA
        </button>
        <div className="">
            <Dd_Sel items={ this.state.fknitems } getValue={(e)=> this.sel_fkn(e)} />
        </div>
    </div>
    <div className="divider_green"></div>

    { this.state.loading ?
        <div className="mx_content flex align-items-center justify-content-center"><FontAwesomeIcon icon="spinner" spin size="3x" /></div>
    :
    <div className="web-row">
        <table className="table table-bordered table-hover tb fixed_header">
            <thead>
            <tr>
				<th>OrderID</th>
				<th>Datum</th>
				<th>Slot</th>
				<th>Postnr</th>
                <th>Namn</th>
                <th>Telefon</th>
				<th>Levtyp</th>
				<th>Notanr</th>
                <th>Belopp</th>
                <th>Betald</th>
                <th>Status</th>
            </tr>
            </thead>
            <tbody style={ this.tb_style() }>
            { this.ora_get().map((vpo, key) =>
            <tr key={key} onDoubleClick={ () => this.order_edit(vpo) } style={ this.row_style(vpo) } >
                <td>{ utils.toNum( vpo.order00.orderid ) }</td>
                <td>{ utils.toDate( vpo.order00.datum ) }</td>
                <td>{ utils.toTid( vpo.order00.slot ) }</td>
                <td>{ vpo.order02.zip }</td>
                <td>{ utils.toLang( vpo.order01.fnamn ) } { utils.toLang( vpo.order01.enamn ) }</td>
                <td>{ vpo.order01.tele }</td>
                <td>{ vpo.order00.levtyp }</td>
                <td>{ utils.toNum( vpo.order00.notanr ) }</td>
                <td className="text-right">{ utils.toPris( vpo.order00.belopp ) }</td>
                <td>{ vpo.order00.paid }</td>
                <td>{ vpo.order00.status }</td>
            </tr>
            )}
            </tbody>
        </table>
    </div>
    }

        <PdfDlg show={this.state.pdf} pdf={this.state.pdf_content} done={(ret) => this.pdf_done(ret)} backdrop={true}/>
        { this.state.wnds.orderedit ?
            <Order_Edit wnds={this.state.wnds} ctx={this} vpo={this.state.vpo} store={this.props.store} backdrop={true}/>
        : null }
    </div>
  );
    }
}
export default Op_Orders;
