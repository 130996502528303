import React, { useState, useEffect, useContext } from 'react';
import {Route, Routes} from "react-router-dom";
import {withRouter} from "../lib/react/withRouter";
import { observer } from 'mobx-react'

import Eko_Home from './eko/home.js';
import Eko_Red from './eko/red.js';
import Eko_Rps from './eko/rps.js';
import Eko_List from './eko/list.js';
import Eko_Trs from './eko/trs.js';
import Eko_Konto from './eko/konto.js';
import Eko_Kvitton from './eko/kvitton.js';
import Eko_CC from './eko/cc.js';
import Eko_Swish from './eko/swish.js';
import Eko_Kassa from './eko/kassa.js';
import Pos_Dash from "./dash";
import sso from "../sso";

function pg_home() {

    var html =
    <div className="web-content">
      Hem
    </div>
    ;
    return html;
}

class Pos_Eko extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.state = { pgid: 11, deps: [] };
    //context.posrend = this;

      var po = {};
      po.red = { "fid": "f05", "url": "/pos/eko/red", "name": "Redovisning", "active": false };
      po.rps = { "fid": "f05", "url": "/pos/eko/rps", "name": "Rapporter", "active": false };
      po.list = { "fid": "f05", "url": "/pos/eko/list", "name": "Listor", "active": false };
      po.kassa = { "fid": "f05", "url": "/pos/eko/kassa", "name": "Kassa", "active": false };
      po.konto = { "fid": "f05", "url": "/pos/eko/konto", "name": "Konto", "active": false };
      po.trs = { "fid": "f05", "url": "/pos/eko/trs", "name": "Transaktioner", "active": false };
      po.kvitton = { "fid": "f05", "url": "/pos/eko/kvitton", "name": "Kvitton", "active": false };
    po.cc = { "fid": "f05", "url": "/pos/eko/cc", "name": "Kort", "active": false };
    po.swish = { "fid": "f05", "url": "/pos/eko/swish", "name": "Swish", "active": false };
      this.state.po = po;
      this.state.pa = [];
      this.state.pa = Object.values(po);
  }
    componentDidUpdate() {
    }
    componentWillUnmount() {
    }
    componentDidMount() {
      /*
        var path = window.location.pathname;
        if(path.substr(-1) == "/") path = path.substring(0, path.length - 1);
        var op = path.split("/").pop();

        var po = this.state.po;
        if(po[op]) {
            po[op].active = true;
            //this.props.store.fkn = po[op];
            sbo.state.fkn = po[op].name;
            //alert(JSON.stringify(sbo.state));
        }
        var pa = Object.values(po);
        this.setState({ po: po, pa: pa });
       */
    }
  fknGo(row) {
    sso.seo.sfkn = row;
    this.props.navigate(row.url);
  }
  br_css(row) {
    var css = {};
    if(sso.seo.sfkn && (row.url == sso.seo.sfkn.url)) css.color = "#080";
    //var brc = false;
    //if(row.url == sbo.state.url) brc = true;
    return css;
  }

  render() {
    return (
    <div className="web-app">
         { this.props.store.cfg.bread ?
             <div className="web-bread">
               <div className="breadcrumb">
                 { this.state.pa.map((row, key) =>
                     <div key={key} className="breadcrumb-item" style={this.br_css(row)} onClick={() => this.fknGo(row)}>{row.name}</div>
                 )}
                 <div className="flex"></div>
                 <div className="cdigreen">{ sso.sdo.sfkn ? sso.sdo.sfkn.name : null }</div>
               </div>
             </div>
        : null }
        <Routes>
          <Route path="/xx" component={pg_home} />
            <Route path="red" element={ <Eko_Red store={this.store} />} />
            <Route path="rps" element={ <Eko_Rps store={this.store} />} />
            <Route path="list" element={ <Eko_List store={this.store} />} />
            <Route path="trs" element={ <Eko_Trs store={this.store} />} />
            <Route path="konto" element={ <Eko_Konto store={this.store} />} />
            <Route path="kvitton" element={ <Eko_Kvitton store={this.store} />} />
            <Route path="cc" element={ <Eko_CC store={this.store} />} />
            <Route path="swish" element={ <Eko_Swish store={this.store} />} />
            <Route path="kassa" element={ <Eko_Kassa store={this.store} />} />
            <Route path="*" element={ <Eko_Home store={this.store} />} />
        </Routes>
    </div>
  );
}
}
//Sys.contextType = ADOContext;

export default withRouter(observer(Pos_Eko));

/*
    <div className="flex-row">
        <div>
            <ol className="cdibread">
            { pa.map((row, key) =>
                <li key={key} className="" onClick={() => this.fknGo(row)}>{ row.active ? row.text : <span className="nav_active">{row.text}</span> }</li>
            )}
            </ol>
        </div>
    </div>

*/