import React, { useState, useEffect, useContext } from 'react';
import {observer} from "mobx-react";

import * as net from "../../lib/net";
import Dt_Cal from "../../lib/ui/dt_cal";
import User_New from "./user/user_new";
import User_Edit from "./user/user_edit";
import ddo from "../../ddo";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Sys_Users extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.pos = props.store.pos;

    this.state = {};
    this.state.seldate = new Date();
    this.state.avd = ddo.cache.avd;
      this.state.sza = [];
      this.state.uaa = [];
    this.state.aa = [];
    this.state.wnds = {};
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.users();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

    user_new() {
        var self = this;
        //alert(JSON.stringify(row.bet00));
        //var bto = JSON.parse(JSON.stringify(row.bet00));
        var wnds = {};
        wnds.usernew = true;
        wnds.uso = {};
        wnds.backdrop = true;
        wnds.cb = self.cb_usr;
        self.setState({wnds: wnds });
    }
    user_edit(row) {
        var self = this;
        //alert(JSON.stringify(row.bet00));
        //var bto = JSON.parse(JSON.stringify(row.bet00));
        var wnds = {};
        wnds.useredit = true;
        wnds.uso = row;
        wnds.backdrop = true;
        wnds.cb = self.cb_usr;
        self.setState({wnds: wnds });
    }

    cb_usr(ctx, rsp) {
      //alert(rsp);
      ctx.users();
      ctx.setState({wnds: {}});
    }

    users() {
        var self = this;
        var prm = {};
        prm.req = "usr:list";

        net.gcs_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.rca));
                var rca = [];
                //rca = ret.rca;
                for(var row of ret.rca) {
                    var kid = "";
                    if(ddo.cache.org == "KK") kid = "00001393";
                    if(ddo.cache.org == "LU") kid = "00001394";
                    
                    if(row.usr00.u0kid == kid) {
                        rca.push( row );
                    }
                }
                self.setState({sza: rca, uaa: rca });
                //toastr.success("OK", "SAPI");
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
  change_avd(e) {
      var self = this;
      let avd = e.target.value;
      this.setState({avd: avd}, function() {
          self.vgrps();
      });
  }
    get_profil(row) {
        var self = this;
        let profil = "";
        if(row.usr00) {
            if(row.usr00.u0pid == "0001") profil = "KULTUR OCH FRITID";
            if(row.usr00.u0pid == "0002") profil = "FÖR OCH GRUNDSKOLA";
            if(row.usr00.u0pid == "0003") profil = "TEKNIK";
            if(row.usr00.u0pid == "0004") profil = "IF";
            if(row.usr00.u0pid == "0005") profil = "GYMNASIESKOLAN";
        }
        if(ddo.cache.org == "LU") profil = "UNIVERSITETET";
        return profil;
    }
    get_auth(row) {
        var self = this;
        let auth = "";
        if(row.usr00) {
            if(row.usr00.u0auth == "05") auth = "SYSTEM ADMIN";
            if(row.usr00.u0auth == "09") auth = "FÖRVALTNINGS ADMIN";
            if(row.usr00.u0auth == "39") auth = "GRUPP ADMIN";
            if(row.usr00.u0auth == "59") auth = "ENHETS ADMIN";
            if(ddo.cache.org == "LU") {
                if(row.usr00.u0auth == "09") auth = "ENHETS ADMIN";
            }
        }
        return auth;
    }
    get_enhet(row) {
        var self = this;
        let enhet = "";
        if(row.usr00) {
            if(row.usr00.u0pid == "0001") {
                if(row.usr01.u1avd == "01") enhet = "NATURRUM";
                if(row.usr01.u1avd == "02") enhet = "ISHALLEN";
                if(row.usr01.u1avd == "03") enhet = "SKATEHALLEN";
                if(row.usr01.u1avd == "04") enhet = "TEATER";
                if(row.usr01.u1avd == "05") enhet = "TURISTINFO";
                if(row.usr01.u1avd == "06") enhet = "ÄSKHULTS BY";
                if(row.usr01.u1avd == "11") enhet = "FYREN";
                if(row.usr01.u1avd == "14") enhet = "KULLAVIK";
                if(row.usr01.u1avd == "16") enhet = "ÅSA";
                if(row.usr01.u1avd == "20") enhet = "UIK SÄRÖ";
                if(row.usr01.u1avd == "21") enhet = "UIK ÅSA";
                if(row.usr01.u1avd == "23") enhet = "UIK FRILLESÅS";
                if(row.usr01.u1avd == "24") enhet = "UIK HÅLABÄCK";
                if(row.usr01.u1avd == "25") enhet = "UIK LAN";
            }
            if(row.usr00.u0pid == "0002") {
                if(row.usr01.u1avd == "01") enhet = "KOLLASKOLAN";
                if(row.usr01.u1avd == "02") enhet = "KAPARESKOLAN";
                if(row.usr01.u1avd == "03") enhet = "SÄRÖSKOLAN";
                if(row.usr01.u1avd == "04") enhet = "ÅSASKOLAN";
                if(row.usr01.u1avd == "05") enhet = "VARLASKOLAN";
                if(row.usr01.u1avd == "06") enhet = "SMEDINGESKOLAN";
                if(row.usr01.u1avd == "07") enhet = "HÅLABÄSCKSSKOLAN";
                if(row.usr01.u1avd == "08") enhet = "KULLAVIKSSKOLAN";
                if(row.usr01.u1avd == "09") enhet = "FRILLESÅSSKOLAN";
                if(row.usr01.u1avd == "10") enhet = "MALEVIKSSKOLAN";
                if(row.usr01.u1avd == "11") enhet = "SKÅRBYSKOLAN";
            }
            if(row.usr00.u0pid == "0003") {
                if(row.usr01.u1avd == "01") enhet = "TEKNIK";
            }
            if(row.usr00.u0pid == "0004") {
                if(row.usr01.u1avd == "01") enhet = "FAMILJERÅDGIVNING";
                if(row.usr01.u1avd == "02") enhet = "LINDEN";
                if(row.usr01.u1avd == "03") enhet = "REGNBÅGEN";
                if(row.usr01.u1avd == "04") enhet = "SERVICELAGET";
            }
            if(row.usr00.u0pid == "0005") {
                if(row.usr01.u1avd == "01") enhet = "ELOFS HÖRNA";
                if(row.usr01.u1avd == "02") enhet = "RESTAURANG LINDÄLV";
            }
            if(ddo.cache.org == "LU") {
                enhet = row.usr01.u1avd;
                if(row.usr00.u0pid == "0001") {
                    if(row.usr01.u1avd == "01") enhet = "ADMINISTRATIVA ENHETEN";
                    if(row.usr01.u1avd == "02") enhet = "DRIFTSENHETEN";
                    if(row.usr01.u1avd == "03") enhet = "SERVICE/PARKERING";
                    if(row.usr01.u1avd == "04") enhet = "MEDIA/TRYCK";
                    if(row.usr01.u1avd == "05") enhet = "MAX IV";
                    if(row.usr01.u1avd == "06") enhet = "BOSTÄDER/SERVICE";
                    if(row.usr01.u1avd == "07") enhet = "LITTERATURCENTRUM";
                    if(row.usr01.u1avd == "08") enhet = "LUX BIBLIOTEKET";
                    if(row.usr01.u1avd == "09") enhet = "SOL BIBLIOTEKET";
                    if(row.usr01.u1avd == "10") enhet = "UNIVERSITETSBIBLIOTEKET";
                    if(row.usr01.u1avd == "11") enhet = "HBG BIBLIOTEK";
                    if(row.usr01.u1avd == "12") enhet = "SAMHÄLLS BIBLIOTEK";
                }
            }
        }
        return enhet;
    }

  render() {
    return (
    <div className="web-app">

    <div className="flex-row">
        <select className="form-control w250 hide" value={this.state.avd} placeholder="Välj avdelning" onChange={(e) => this.change_avd(e)}>
            { this.props.store.pos.deps.map((row, key) =>
            <option key={key} value={row.avd}>{row.avd} - {row.text}</option>
            )}
        </select>
        <div className="flex"></div>

        <button className="btn btn-hw-green w150" onClick={() => this.user_new() } aria-label="System" >
            Lägg upp ny
        </button>
        <button className="btn btn-hw-blue w150" onClick={() => this.users() } aria-label="System" >
            Visa
        </button>
    </div>
    <div className="divider_orange"></div>

        <div className="web-row">
            <table className="table table-bordered table-hover tb fixed_header">
                <thead>
                <tr>
                    <th>UID</th>
                    <th>Namn</th>
                    <th>Anstnr</th>
                    <th>Auktoritet</th>
                    <th>Profil</th>
                    <th>Enhet</th>
                    <th>Status</th>
                    <th className="w50"></th>
                </tr>
                </thead>
                <tbody style={ this.tb_style() }>
                { this.state.uaa.map((row, key) =>
                    <tr key={key} id={ row.usr00.u0uid } onDoubleClick={() => this.user_edit(row) }>
                        <td>{ row.usr00.u0uid }</td>
                        <td>{ row.usr00.u0namn.toswe() }</td>
                        <td className="text-left">{ row.usr00.u0enr }</td>
                        <td>{ this.get_auth(row) }</td>
                        <td>{ this.get_profil(row) }</td>
                        <td>{ this.get_enhet(row) }</td>
                        <td>{ row.usr00.u0status }</td>
                        <td className="text-center w40" style={{cursor:"pointer"}} onClick={() => this.user_edit(row) }><i className="fa fa-cog text-danger"></i></td>
                    </tr>
                )}
                </tbody>
            </table>
        </div>

        { this.state.wnds.usernew ? <User_New wnds={ this.state.wnds } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.wnds.useredit ? <User_Edit wnds={ this.state.wnds } ctx={this} store={this.props.store} backdrop={true}/> : null }
    </div>
  );
    }
}
export default observer(Sys_Users);
