import React, { useState, useContext } from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import sso from "../../sso";
//import { useStore } from '../../sdo.js'
import { useNavigate } from 'react-router-dom';
//import {withRouter} from "../../lib/react/withRouter";
import {observer} from "mobx-react";

function Pos_Menu(props) {
    var navigate = useNavigate();
    var row = {};
    //if(props.store.fkn) row = props.store.fkn;
    let [state, setState] = useState({row: row, mnu: "", htitle: "", ma: []});
    //let ctx = useStore();

    var lock = "lock";
    var moa = {};
    moa["f00"] = { fid: "f00", name: "Hem", url: "/pos", icon: "home", fa: "fa fa-home flex", ma: [] };
    moa["f01"] = { fid: "f01", name: "Status", url: "/pos/stat", icon: "key", fa: "fa fa-key flex", ma: [] };
    moa["f02"] = { fid: "f02", name: "Drift", url: "/pos/op", icon: "play", fa: "fa fa-play flex", ma: [] };
    moa["f03"] = { fid: "f03", name: "Produkter", url: "/pos/prds", icon: "desktop", fa: "fa fa-desktop flex", ma: [] };
    //moa["f04"] = { fid: "f04", name: "Manager", url: "/pos/man", icon: "chart-bar", fa: "fa fa-chart-bar flex", ma: [] };
    moa["f05"] = { fid: "f05", name: "Ekonomi", url: "/pos/eko", icon: "chart-bar", fa: "fa fa-chart-bar flex", ma: [] };
    moa["f06"] = { fid: "f06", name: "Lager", url: "/pos/lager", icon: "list-alt", fa: "fa fa-list-alt flex", ma: [] };
    moa["f07"] = { fid: "f07", name: "Backoffice", url: "/pos/bo", icon: "cogs", fa: "fa fa-cogs flex", ma: [] };

    moa["f01"].ma.push( { fid: "f01", name: "Totalstatus", url: "/pos/stat/total", fa: "fa fa-bars" } );
    moa["f01"].ma.push( { fid: "f01", name: "Timstatus", url: "/pos/stat/hh", fa: "fa fa-bars" } );
    moa["f01"].ma.push( { fid: "f01", name: "Veckostatus", url: "/pos/stat/week", fa: "fa fa-bars" } );
    moa["f01"].ma.push( { fid: "f01", name: "Tidperiod", url: "/pos/stat/tp", fa: "fa fa-bars" } );
    moa["f01"].ma.push( { fid: "f01", name: "Omsättning", url: "/pos/stat/oms", fa: "fa fa-bars" } );
    moa["f01"].ma.push( { fid: "f01", name: "Transaktioner", url: "/pos/stat/trs", fa: "fa fa-bars" } );
    moa["f01"].ma.push( { fid: "f01", name: "Artikelstatus", url: "/pos/stat/art", fa: "fa fa-bars" } );
    moa["f01"].ma.push( { fid: "f01", name: "Driftstatus", url: "/pos/stat/drift", fa: "fa fa-bars" } );

    moa["f02"].ma.push( { fid: "f02", name: "Kassor", url: "/pos/op/kassa", fa: "fa fa-bars" } );
    moa["f02"].ma.push( { fid: "f02", name: "Bord", url: "/pos/op/ot", fa: "fa fa-bars" } );
    moa["f02"].ma.push( { fid: "f02", name: "Event", url: "/pos/op/event", fa: "fa fa-bars" } );
    moa["f02"].ma.push( { fid: "f02", name: "Bordsvy", url: "/pos/op/tbv", fa: "fa fa-bars" } );
    moa["f02"].ma.push( { fid: "f02", name: "Bordsstatus", url: "/pos/op/tbs", fa: "fa fa-bars" } );
    moa["f02"].ma.push( { fid: "f02", name: "MenyTV", url: "/pos/op/mtv", fa: "fa fa-bars" } );
    moa["f02"].ma.push( { fid: "f02", name: "Bongkö", url: "/pos/op/bq", fa: "fa fa-bars" } );
    moa["f02"].ma.push( { fid: "f02", name: "Kvitto information", url: "/pos/op/ki", fa: "fa fa-bars" } );
    moa["f02"].ma.push( { fid: "f02", name: "Kunder", url: "/pos/op/kund", fa: "fa fa-bars" } );
    moa["f02"].ma.push( { fid: "f02", name: "Swish", url: "/pos/op/swish", fa: "fa fa-bars" } );
    moa["f02"].ma.push( { fid: "f02", name: "Presentkort", url: "/pos/op/pres", fa: "fa fa-bars" } );

    moa["f03"].ma.push( { fid: "f03", name: "Struktur", url: "/pos/prds/str", fa: "fa fa-bars" } );
    moa["f03"].ma.push( { fid: "f03", name: "Produkter", url: "/pos/prds/plus", fa: "fa fa-bars" } );
    moa["f03"].ma.push( { fid: "f03", name: "Menyer", url: "/pos/prds/menus", fa: "fa fa-bars" } );
    moa["f03"].ma.push( { fid: "f03", name: "Knappar", url: "/pos/prds/btns", fa: "fa fa-bars" } );
    moa["f03"].ma.push( { fid: "f03", name: "Tillval", url: "/pos/prds/addons", fa: "fa fa-bars" } );
    moa["f03"].ma.push( { fid: "f03", name: "Villkor", url: "/pos/prds/cond", fa: "fa fa-bars" } );
    //moa["f03"].ma.push( { fid: "f03", name: "Produktval", url: "/pos/prds/psel", fa: "fa fa-bars" } );
    moa["f03"].ma.push( { fid: "f03", name: "Stegval", url: "/pos/prds/steps", fa: "fa fa-bars" } );
    moa["f03"].ma.push( { fid: "f03", name: "Happyhour", url: "/pos/prds/happy", fa: "fa fa-bars" } );
    //moa["f03"].ma.push( { fid: "f03", name: "Pristabell", url: "/pos/prds/pt", fa: "fa fa-bars" } );
/*
    moa["f04"].ma.push( { fid: "f04", name: "Rapporter", url: "/pos/man/rps", fa: "fa fa-bars" } );
    moa["f04"].ma.push( { fid: "f04", name: "Kassa", url: "/pos/man/kassa", fa: "fa fa-bars" } );
    moa["f04"].ma.push( { fid: "f04", name: "Listor", url: "/pos/man/list", fa: "fa fa-bars" } );
    moa["f04"].ma.push( { fid: "f04", name: "Transaktioner", url: "/pos/man/trs", fa: "fa fa-bars" } );
    moa["f04"].ma.push( { fid: "f04", name: "Kvitton", url: "/pos/man/kvitton", fa: "fa fa-bars" } );
*/
    moa["f05"].ma.push( { fid: "f05", name: "Redovisning", url: "/pos/eko/red", fa: "fa fa-bars" } );
    moa["f05"].ma.push( { fid: "f05", name: "Rapporter", url: "/pos/eko/rps", fa: "fa fa-bars" } );
    moa["f05"].ma.push( { fid: "f05", name: "Kassa", url: "/pos/eko/kassa", fa: "fa fa-bars" } );
    moa["f05"].ma.push( { fid: "f05", name: "Konto", url: "/pos/eko/konto", fa: "fa fa-bars" } );
    moa["f05"].ma.push( { fid: "f05", name: "Transaktioner", url: "/pos/eko/trs", fa: "fa fa-bars" } );
    moa["f05"].ma.push( { fid: "f05", name: "Kvitton", url: "/pos/eko/kvitton", fa: "fa fa-bars" } );
    moa["f05"].ma.push( { fid: "f05", name: "Kort", url: "/pos/eko/cc", fa: "fa fa-bars" } );
    moa["f05"].ma.push( { fid: "f05", name: "Swish", url: "/pos/eko/swish", fa: "fa fa-bars" } );

    moa["f06"].ma.push( { fid: "f06", name: "Leverantörer", url: "/pos/lager/levs", fa: "fa fa-bars" } );
    moa["f06"].ma.push( { fid: "f06", name: "Avdelningar", url: "/pos/lager/avd", fa: "fa fa-bars" } );
    moa["f06"].ma.push( { fid: "f06", name: "Artiklar", url: "/pos/lager/art", fa: "fa fa-bars" } );
    moa["f06"].ma.push( { fid: "f06", name: "Inventering", url: "/pos/lager/inv", fa: "fa fa-bars" } );
    moa["f06"].ma.push( { fid: "f06", name: "Drift", url: "/pos/lager/drift", fa: "fa fa-bars" } );
    moa["f06"].ma.push( { fid: "f06", name: "Leverans", url: "/pos/lager/lev", fa: "fa fa-bars" } );
    moa["f06"].ma.push( { fid: "f06", name: "Resultat", url: "/pos/lager/res", fa: "fa fa-bars" } );
    moa["f06"].ma.push( { fid: "f06", name: "Listor", url: "/pos/lager/list", fa: "fa fa-bars" } );

    moa["f07"].ma.push( { fid: "f07", name: "Register", url: "/pos/reg", fa: "fa fa-bars" } );
    moa["f07"].ma.push( { fid: "f07", name: "Redovisning", url: "/pos/red", fa: "fa fa-bars" } );
    moa["f07"].ma.push( { fid: "f07", name: "Teknik", url: "/pos/tech", fa: "fa fa-bars" } );
    moa["f07"].ma.push( { fid: "f07", name: "Interface", url: "/pos/tech/ifs", fa: "fa fa-bars" } );
    /*
    moa["f06"].ma.push( { fid: "f06", name: "Kontodefinition", url: "/pos/bo/konto", fa: "fa fa-bars" } );
    moa["f06"].ma.push( { fid: "f06", name: "Avdelningar", url: "/pos/bo/deps", fa: "fa fa-bars" } );
    moa["f06"].ma.push( { fid: "f06", name: "Varugrupper", url: "/pos/bo/vgrps", fa: "fa fa-bars" } );
    moa["f06"].ma.push( { fid: "f06", name: "Betalmedel", url: "/pos/bo/pays", fa: "fa fa-bars" } );
    moa["f06"].ma.push( { fid: "f06", name: "Moms", url: "/pos/bo/tax", fa: "fa fa-bars" } );
    moa["f06"].ma.push( { fid: "f06", name: "Valuta", url: "/pos/bo/cur", fa: "fa fa-bars" } );
    moa["f06"].ma.push( { fid: "f06", name: "Kassörer", url: "/pos/bo/cas", fa: "fa fa-bars" } );
    moa["f06"].ma.push( { fid: "f06", name: "Terminal def", url: "/pos/bo/tdef", fa: "fa fa-bars" } );
    moa["f06"].ma.push( { fid: "f06", name: "Terminal Cfg", url: "/pos/bo/tcfg", fa: "fa fa-bars" } );
    */

    var aa = [];
    var keys = Object.keys(moa);
    for(var key of keys) {
        var o = moa[key];
        aa.push( o );
    }

    //document.removeEventListener("mousedown", this.handleClick);
    document.addEventListener("mousedown", handleClick);

    var ma = [];
    var mnuHeight = window.innerHeight - 100;

   function handleClick(e) {
    if (e.pageX > 320) {
        if (state.ma.length) {
            setState({mnu : state.mnu, row : state.row, ma : [] });
            return;
        }
    }
  }
   function fknGo(row) {
    //var sdo = useContext(SDO);
    //alert(fkn);
   sso.seo.sfkn = row;

   var fkn = row.fid;
    setState({ mnu : row.fid, row : row,  ma : [] });
    //ctx.fid = fkn;
    //props.store.fkn = row;
    navigate(row.url);
   };
   function fknFix(fkn) {
    //var sdo = useContext(SDO);
    //alert(fkn);
       //ctx.fid = fkn;
       var sfkn = {};
       sfkn.text = "";
       sfkn.url = fkn;
       sso.seo.sfkn = sfkn;

        if(fkn == "lock") navigate('/web');
        if(fkn == "logout") navigate('/');
   };

   function mnuGo(row) {
        //var sdo = useContext(SDO);
        //alert(fkn);
        //ctx.fid = fkn;
        //sbo.state.fid = fkn;
        setState({ mnu : state.hfid, row : state.row,  ma : [] });

        sso.seo.sfkn = row;
        //props.store.fkn = row;
        navigate(row.url);
   };
   function hideMnu() {
    setState({mnu : state.mnu, row : state.row, ma : [] });
   };
   function mouseEnter(it) {
    setState({mnu : state.mnu, row : state.row, hfid : it.fid, htitle : it.name, ma : it.ma });
   };
   function mouseLeave() {
    setState({mnu : state.mnu, row : state.row, ma : [] });
   };

   function row_css(row) {
  	    var css = {};
        css.margin = "0px";
        css.padding = "0px";
        css.height = "55px";
        css.borderBottom = "1px #ccc solid";

       //if(props.store.fkn && (props.store.fkn != undefined))
       if(sso.seo.sfkn && (sso.seo.sfkn != undefined))
        {
            //if (props.store.fkn.fid == row.fid) {
            if (sso.seo.sfkn.fid == row.fid) {
               css.background = "#080";
               css.color = "#fff";
            }
        }
		return css;
    }
   function row_icon_css(row) {
  	    var css = {};
        css.paddingTop = "10px";
        css.fontSize = "20px";

       //if(props.store.fkn && (props.store.fkn != undefined))
       if(sso.seo.sfkn && (sso.seo.sfkn != undefined))
       {
           //if (props.store.fkn.fid == row.fid) {
           if (sso.seo.sfkn.fid == row.fid) {
               //css.background = "#080";
               css.color = "#fff";
            }
        }
		return css;
    }

   //<Icon className={ it.fa } style={{ fontSize: 18 }} />
   var html =
        <div className="">
        <div className="cdi_xmnu flex-fullcol" style={{padding: "5px 0px"}}>
            { aa.map((it, key) =>
            <div key={ key } onClick={() => fknGo(it)} className="web-col flex-cc xmnu_tab" style={ row_css(it) } onMouseEnter={ () => mouseEnter(it) }>
                <FontAwesomeIcon icon={ it.icon } size="1x" className="" style={ row_icon_css(it) } />
                <div style={{fontSize: "10px"}}>{ it.name }</div>
            </div>
            )}
            <div onClick={ () => fknFix("lock") } className="web-col flex-cc xmnu_tab">
                <FontAwesomeIcon icon="lock" size="1x" className="" style={{paddingTop: "10px", fontSize: "20px"}} />
                <div style={{fontSize: "10px"}}>Lås</div>
            </div>
            <div onClick={() => fknFix("logout") } className="web-col flex-cc xmnu_tab" >
                <i className="fa fa-power-off flex" aria-hidden="true" style={{paddingTop: "10px", fontSize: "20px"}}></i>
                <div style={{fontSize: "10px"}}>Logga ur</div>
            </div>
        </div>

            { state.ma.length ?
        <div className="mtrl_mnu_sub mtrl_mnu_bg" aria-label="Sub Menu" style={{height: mnuHeight}} onMouseLeave={mouseLeave}>
          <div className="mtrl_mnu_title" onClick={() => hideMnu()}>
            { state.htitle }
          </div>
            { state.ma.map((mi, key) =>
          <div key={key} className="mtrl_mnu_item flex-row" onClick={ () => mnuGo(mi) }>
            <div className="flex">{ mi.name }</div>
            <i className={ mi.fa } aria-hidden="true" style={ row_icon_css(mi) }></i>
          </div>
            )}
        </div>
                : null }
        </div>
    ;
    return html;
}

export default observer(Pos_Menu);
