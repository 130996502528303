import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";
import Dt_Cal from "../../lib/ui/dt_cal";
import Tro_Open from "./fkn/tro_open";
import {format, subDays} from "date-fns";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Reg_Kop extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, tra: [] };
    this.state.seldate = new Date();
    this.state.typ = "01";
    this.state.rga = [];
    this.state.aa = [];

    this.state.troopen = 0;
    this.state.vpo = {};

    this.state.today = new Date();
    var dat = this.state.today;
    ///dat = subDays(dat, 1);
    var dts = format(dat, "yyyyMMdd");
    this.state.datum = dts;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.bet_list();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 250;
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }
  dt_dat(e) {
    //alert(JSON.stringify(e));
    var dts = format(e, "yyyyMMdd");
    this.setState({ datum: dts });
  }

  tro_edit(vpo) {
    var self = this;
    self.setState({troopen: 1, vpo: vpo });
  }

  bet_list() {
    var self = this;
    var prm = {};
    prm.req = "vip.vip_buy.pay_list";
    prm.typ = this.state.typ;
    prm.datum = this.state.datum;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        self.setState({rga: ret.rca });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

  render() {
    return (
    <div className="web-p00 flex flex-col">
        <div className="">
            KÖP / TRANSAKTIONER
        </div>

    <div className="web-p01">
    <div className="flex-row">
        <Dt_Cal startdatum="2020-02-01" getValue={(e)=> this.dt_dat(e)} />
        <select className="form-control w250" placeholder="Välj avdelning">
            { this.state.aa.map((row, key) =>
            <option key={key} value={row.id}>{row.text}</option>
            )}
        </select>
        <div className="flex"></div>

        <button className="btn btn-hw-blue w150 ripple" onClick={() => this.bet_list() } aria-label="System" >
            Visa
        </button>
    </div>
    <div className="divider">
    </div>

    <div className="web-row">
        <table className="table table-bordered table-hover tb fixed_header">
            <thead>
            <tr>
				<th>Datum</th>
				<th>Tid</th>
				<th>Typ</th>
				<th>Notanr</th>
				<th>Text</th>
				<th>Brutto</th>
				<th>Rabatt</th>
				<th>Signatur</th>
            </tr>
            </thead>
            <tbody style={ this.tb_style() }>
            { this.state.rga.map((pao, key) =>
            <tr key={key} onDoubleClick={ () => this.tro_edit(pao) }>
                <td>{ pao.p0datum }</td>
                <td>{ pao.p0tid }</td>
                <td>{ pao.p0typ }</td>
                <td>{ pao.p0notanr }</td>
                <td>{ pao.p0text }</td>
                <td>{ pao.p0brutto.cdipris() }</td>
                <td>{ pao.p0rabatt.cdipris() }</td>
                <td>{ pao.p0sign }</td>
            </tr>
            )}
            </tbody>
        </table>
    </div>

    </div>
        { this.state.troopen ?
            <Tro_Open show={this.state.troopen} ctx={this} vpo={this.state.vpo} store={this.props.store} backdrop={true}/>
        : null }
    </div>
  );
}
}
export default Reg_Kop;
