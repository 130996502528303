import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../../lib/net";
import * as utils from "../../../assets/utils";
import ddo from "../../../ddo";

class Btn_Grps extends React.Component {
  constructor(props, context) {
    super(props);

    this.pos = props.store.pos;
    this.state = {};
    this.state.bga = [];

      this.state.avd = ddo.cache.avd;
      this.state.gnr = "001";
    this.state.selrows = {};

    this.state.ynq = [
                {id: '0', text: 'NEJ'},
                {id: '1', text: 'JA'}
            ];
    this.state.fargtema = [
                {id: '00', text: 'EGET VAL'},
                {id: '01', text: 'MÖRKRÖD / VIT'},
                {id: '02', text: 'MÖRKBLÅ / VIT'},
                {id: '03', text: 'MÖRKGRÖN / VIT'},
                {id: '04', text: 'ORANGE / VIT'},
                {id: '05', text: 'SVART / VIT'},
                {id: '06', text: 'GUL / SVART'},
                {id: '07', text: 'VIT / SVART'},
                {id: '08', text: 'LJUSRÖD / VIT'},
                {id: '09', text: 'LJUSBLÅ / VIT'},
                {id: '10', text: 'LJUSGRÖN / VIT'}
            ];

    this.state.bgo = {};
    this.state.bto = {};
    this.state.background = "#fff";

    this.state.displayColorPicker = false;
    if(props.bto) this.state.bto = props.bto;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.btn_grps();
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
  tb_style() {
      var css = {};
      css.height = ( window.innerHeight * 0.7 ) - 175;
      css.overflow = "auto";
      return css;
  }
    grp_style(row) {
      var css = {};
      css.background = "#008";
      if(this.state.gnr === row.vgrupp) css.background = "#080";
      return css;
    }
  row_style(row) {
    var css = {};
    var selrows = this.state.selrows;
    var key = row.r0dateid;
    if(selrows[key]) {
        css.background = "#080";
        css.color = "#fff";
    }
    return css;
  }

    wnd_close = () => {
		this.props.ctx.setState({ wnds: {} });
	};

    hoverEnter(event) {
        event.preventDefault();
        event.target.style.color = "#fff";
        event.target.style.background = "#ccc";
        //alert("Allow Drop");
	};
    hoverLeave(event) {
        event.preventDefault();
        event.target.style.color = "#fff";
        event.target.style.background = "#080";
        //alert("Allow Drop");
	};

    tstbtn_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
        css.width = "250px";
        css.height = "50px";
        css.border = "1px solid #000";
        css.background = "#fff";
        css.color = "#000";
        if(this.state.bgfarg) {
            css.background = this.state.bgfarg;
        }
      return css;
    }

  val_color(id, val) {
      var css = {};
      //alert(JSON.stringify(val))
      this.ctrl_fg.updateState(val.hex);
      this.setState({ bgfarg: val.hex });
      return css;
  }
  val_save(id, val) {
      var css = {};
      //alert(JSON.stringify(val))
      return css;
  }
  row_sel(row) {
    }
  grp_edit(row) {
    }
  btn_grps() {
    var self = this;
    var prm = {};
    prm.req = "pos.pos_btns.famgrps";
    prm.avd = this.state.avd;
    //prm.fplu = datum;
    //prm.tplu = datum;

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        var bga = ret.rco;
        //alert(JSON.stringify(bga.length));
        //var o = {};
        //o[0] = bga.slice(0, 6);
        //o[1] = bga.slice(6, 12);
        self.setState({bga: bga }, function() {
            self.syncTb("grpTable");
        });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }
    syncTb(tb){
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
        tdElements = tbel.getElementsByTagName('td');

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
            width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
    colorClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  };

  colorClose = () => {
    this.setState({ displayColorPicker: false })
  };
  render() {

    var title = this.props.title;
    if(!title) title = "Knappar";
    //if(props.kro) alert(props.kro.krr00.kid);
    const popover = {
      position: 'absolute',
      top: '50px',
      zIndex: '2',
    }
    const cover = {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    }

	var html =
        <div className="mx_base">
        { this.props.wnds.backdrop ? <div className="mx_backdrop" /> : null }
        <div className="mx_dlg mx_70 flex-col" style={ this.dlg_style() }>
        <div className="mx_content flex">

            <div className="flex-row" style={{padding:"5px",height:"35px"}}>
                    <div className="flex-col flex">
                        <div>KNAPP GRUPPER:</div>
                    </div>
                    <div className="flex-col">
                        <div>UPPDATERA</div>
                    </div>
            </div>
            <div className="divider_orange"></div>

            <div id="grpTable" className="flex">
            <table className="table table-bordered table-hover tb">
                <thead>
                <tr>
                    <th>Grp</th>
                    <th>Text</th>
                    <th>Färg</th>
                    <th>Förgrund</th>
                    <th>Bakgrund</th>
                    <th>Status</th>
                </tr>
                </thead>
            </table>
            <div style={ this.tb_style() }>
              <table className="table table-bordered table-hover tb">
                <tbody>
                { this.state.bga.map((row, key) =>
                <tr key={key} style={ this.row_style(row) } onClick={() => this.row_sel(row) } onDoubleClick={() => this.grp_edit(row) }>
                    <td>{ row.vgrupp }</td>
                    <td>{ utils.toLang(row.namn) }</td>
                    <td>{ row.farg }</td>
                    <td>{ row.fgfarg }</td>
                    <td>{ row.bgfarg }</td>
                    <td>{ row.status }</td>
                </tr>
                )}
                </tbody>
            </table>
        </div>
        </div>

        </div>
        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
        </div>
      </div>
      </div>
	;
	return html;
  };
}

export default Btn_Grps;