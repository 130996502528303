import React, { useState, useEffect, useContext } from 'react';
import ReactDOM from 'react-dom';
import { SDOContext } from '../sdo.js'

//import './css/style-liberty.css';
import Tiden from "../lib/ui/tiden";

import * as net from "../lib/net";
import Dd_Sel from "../lib/ui/dd_sel";
import {withRouter} from "../lib/react/withRouter";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import sso from "../sso";

class Bo_Dash extends React.Component {
    //var sdo = useContext(SDOContext); // Functional
    static contextType = SDOContext; // Class

    constructor(props, context) {
        super(props);

        this.s1ref = React.createRef();
        this.s2ref = React.createRef();

        this.store = props.store;
        this.seo = props.store.seo;

        this.state = { pgid: 11, stat: [] };
        this.state.open = false;
        this.state.pdfopen = false;
        this.state.xxopen = false;
        this.state.pdf_uri = "";
        this.state.seldate = new Date('2020-03-24T13:11:54');
        this.state.row = null;
        this.state.info = "";
        this.state.top = 1;
        this.state.user = {};
        this.state.sdo = {};
        this.state.wnds = {};
        //this.state.sdo = context;
        //context.posrend = this;
        this.state.notify = false;
        this.state.step = 1;
        this.state.pdf = 0;
        this.state.pdf_uri = "";
        this.state.mnus = ["m00", "m01", "m02", "m03", "m08", "m09"];
        this.state.mnu_sel = "m00";

        this.state.useritems = [
            {fkn: 'user_setup', text: 'INSTÄLLNINGAR'},
            {fkn: 'user_ses', text: 'MIN SESSION'},
            {fkn: 'logout', text: 'LOGGA UT'}
        ];
        this.state.manitems = [
            {fkn: 'man_bo', text: 'BACKOFFICE'},
            {fkn: 'man_sid', text: 'SYSTEM VAL'},
            {fkn: 'man_ses', text: 'SESSIONER'}
        ];
        this.state.mna = [];
        this.state.mna.push({ mnid: "mn01", pos: "c1", text: "ANVÄNDARE", desc: "Användare", link: "/bos/users" });
        this.state.mna.push({ mnid: "mn02", pos: "c1", text: "KUNDER", desc: "Kunder", link: "/bos/kids" });
        this.state.mna.push({ mnid: "mn03", pos: "c1", text: "PROFILER", desc: "Profiler", link: "/bos/kps" });
        this.state.mna.push({ mnid: "mn04", pos: "c2", text: "SYSTEM", desc: "System", link: "/bos/sids" });
        this.state.mna.push({ mnid: "mn05", pos: "c2", text: "ENHETER", desc: "Enheter", link: "/bos/udids" });
        this.state.mna.push({ mnid: "mn06", pos: "c2", text: "SESSIONER", desc: "Sessioner", link: "/bos/ses" });

    }
    componentDidMount(){
        //this.forceUpdate();
        this.verify();
    }

    componentWillUnmount(){
        //this.setState({info: "UUU" });
    }
    logo(){
        var logo = null;
        if(this.store.cfg.logo == "cdi") logo = require('../img/logo-cdi.png');
        else logo = require('../img/logo-sk.png');
        return logo;
    }
    mnc(pos) {
        var mna = [];
        for(var row of this.state.mna) {
            if(row.pos == pos) mna.push( row );
        }
        return mna;
    }
    fknGo(url) {
        //alert(row.href);
        var row = {};
        row.url = url;
        sso.seo.sfkn = row;
        this.props.navigate(url);
    }
    sysStart() {
        var url = "/pos";
        if(this.seo.auto) url = "/" + this.seo.auto;
        this.props.navigate(url);
   };
    verify() {
        var self = this;
        //var xdo = sessionStorage.getItem("svp_sso");
		//var sdo = JSON.parse(xdo);

		var prm = {};
        prm.req = "ses:verify";
		prm.uid = sso.seo.uid;
		prm.token = sso.seo.token;

		//alert(JSON.stringify(prm));
        net.gcs_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret));

            if(ret.ok != "000") {
                //alert(JSON.stringify(ret));
                sessionStorage.removeItem("svp_sso");
                self.props.store.seo = {};
                sso.seo = {};

                var url = "/sesout";
                self.props.navigate(url);
                //self.props.history.go(0);
                return;
            }
            //alert(JSON.stringify(self.seo));
            if(self.seo && self.seo.auth > 95) {
                var items = self.state.manitems;
                items.push( {fkn: 'live', text: 'LIVE STATS'} );
                self.setState({manitems: items });
            }

        })
        .catch(function(e){
            //var txt = JSON.stringify(e);
            var url = "/sesout";
            self.props.navigate(url);
            //self.props.history.go(0);
        });

      };
  br_menu() {
    //alert(JSON.stringify(fkn));
  }
  sel_fkn(sel) {
    //alert(JSON.stringify(fkn));
      if(sel.fkn == "logout") {
        var url = "/logout";
        this.props.navigate(url);
      }
      if(sel.fkn == "man_bo") {
        var url = "/bo";
        this.props.navigate(url);
      }
      if(sel.fkn == "man_sid") {
          alert("SID");
      }
      if(sel.fkn == "man_ses") {
          var wnds = {};
          wnds.manses = true;
          this.setState({"wnds": wnds});
      }
      if(sel.fkn == "user_ses") {
          var wnds = {};
          wnds.userses = true;
          this.setState({"wnds": wnds});
      }
      if(sel.fkn == "user_setup") {
          var wnds = {};
          wnds.usersetup = true;
          this.setState({"wnds": wnds});
      }
  }

    render() {
        return (
            <div className="web-app">
                <div className="web-pg py-4 flex">
                    <h3>Svepos Backoffice</h3>
                    <p>Backoffice funktioner</p>

                    <div className="divider_green"></div>

                    <div className="web-row">
                        <div className="flex-col flex">
                            { this.mnc("c1").map((row, key) =>
                                <div key={key} className="web-bx" onClick={() => this.fknGo(row.link) }>
                                    <div>{ row.text }</div>
                                    <div className="flex-row">
                                        <div className="flex">{ row.desc }</div>
                                        <div className="web-link">Välj</div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="flex-col flex">
                            { this.mnc("c2").map((row, key) =>
                                <div key={key} className="web-bx" onClick={() => this.fknGo(row.link) }>
                                    <div>{ row.text }</div>
                                    <div className="flex-row">
                                        <div className="flex">{ row.desc }</div>
                                        <div className="web-link">Välj</div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

//WebPub.contextType = SDOContext; // Function
export default withRouter(Bo_Dash);
