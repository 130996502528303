import React, { useState, useEffect, useContext } from 'react';
import {format, addDays, subDays} from "date-fns";

import * as net from "../../../lib/net";
import Fr_Input from "../../../lib/ui/fr_input";

class Kds_Edit extends React.Component {
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pos = props.store.pos;

    this.state = {};
    this.state.today = new Date();
    this.state.rta = [];
    this.state.rsa = [];

    this.state.gnr = "001";
    this.state.gso = {};

    this.state.ynq = [
                {id: '0', text: 'NEJ'},
                {id: '1', text: 'JA'}
            ];

    this.state.rs = "";
    this.state.rso = {};
    this.state.background = "#fff";

    this.state.wnds = {};
    if(props.wnds) {
        this.state.rsa = props.wnds.rsa;
        this.state.rso = props.wnds.rso;
        this.state.rs = props.wnds.rso.restname;
    }
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.rso_init();
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    grp_style(row) {
      var css = {};
      css.background = "#008";
      if(this.state.gnr === row.vgrupp) css.background = "#080";
      return css;
    }

    formchange (e) {
      const name = e.target.name;
      const value = e.target.value;
      var pbo = this.state.pbo;
      var na = name.split(".");
      pbo[na[0]][na[1]] = value;
      this.setState({pbo: pbo});
      //setState({[name]: value});
    }

    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    wnd_close = () => {
      if(this.props.cb) {
        var ret = {};
        ret.ok = "999";
        this.props.cb(this.props.ctx, ret);
      }
      else this.props.ctx.setState({ wnds: {} });
	};

  submitHandler = event => {
    event.preventDefault();
    event.target.className += " was-validated";
  };

    val_save (name, value) {
        //alert(JSON.stringify(name));
        //alert(JSON.stringify(value));
      var rso = this.state.rso;
        rso[name] = value;
      this.setState({rso: rso});
    }
    cb_save (name, e) {
      const value = e.target.value;
      var bro = this.state.bro;
      bro[name] = value;
      this.setState({bro: bro});
      //setState({[name]: value});
    }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }

  rso_init() {
    var self = this;

    var rso = {};
    rso.resurstyp = "";
    rso.restname = "";
    rso.resursordn = "";
    rso.platser = "";
    rso.fromtime = "";
    rso.tilltime = "";
    rso.avd = "";
    rso.reservtime = "";
    rso.iresurs = "";
    rso.boktyp = "";
    rso.rnamn = "";
    rso.bokbar = "";
    rso.upddat = "";
    rso.sign = "";
    this.setState({rso: rso});
  }

  kds_save() {
        var self = this;
        var prm = {};
        prm.req = "pos.pos_kds.kds_add";
        prm.rs = this.state.rs;
        prm.rso = this.state.rso;

        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret));

          if(self.props.wnds.cb) {
            var ret = {};
            ret.ok = "000";
            self.props.wnds.cb(self.props.ctx, ret);
          }
          else self.props.ctx.setState({ wnds: {} });
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }
    kds_del() {
        var self = this;
        var prm = {};
        prm.req = "pos.pos_kds.kds_del";
        prm.rs = this.state.rs;
        prm.rso = this.state.rso;

        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret));

            if(self.props.wnds.cb) {
                var ret = {};
                ret.ok = "000";
                self.props.wnds.cb(self.props.ctx, ret);
            }
            else self.props.ctx.setState({ wnds: {} });
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }
    tbv_copy() {
        var self = this;
        var wnds = {};
        wnds.tbvcopy = true;
        wnds.rs = this.state.rs;
        wnds.rsa = this.state.rsa;
        //wnds.cb = self.cb_tbo;
        self.setState({wnds: wnds });
    }

  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "Gäst";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        <div className="m3_backdrop" />
        <div className="m3_dlg mx_70 flex-col" style={ this.dlg_style() } draggable="true" >

        <div className="mx_h01">
            <div>KDS</div>
        </div>
        <div className="mx_content flex">

            <div className="divider_orange"></div>

            <form className="cdi_f03">
            <div className="flex-row py-2">
                    <Fr_Input label="Text" className="text-left w350" text={this.state.rso.restname} getValue={(e)=> this.val_save("restname", e) } />
            </div>
            <div className="flex-row py-2">
                    <Fr_Input label="Resursordn" className="text-left w250" text={this.state.rso.resursordn} getValue={(e)=> this.val_save("resursordn", e) } />
                    <Fr_Input label="Platser" className="text-left w250" text={this.state.rso.platser} getValue={(e)=> this.val_save("platser", e) } />
            </div>
            <div className="flex-row py-2">
                    <Fr_Input label="Från" className="text-left w250" text={this.state.rso.fromtime} getValue={(e)=> this.val_save("fromtime", e) } />
                    <Fr_Input label="Till" className="text-left w250" text={this.state.rso.tilltime} getValue={(e)=> this.val_save("tilltime", e) } />
            </div>
            <div className="divider_green"></div>
            <div className="flex-row py-2">
                    <Fr_Input label="Reservations Tid" className="text-left w250" text={this.state.rso.reservtime} getValue={(e)=> this.val_save("reservtime", e) } />
            </div>
            <div className="flex-row py-2">
                    <Fr_Input label="Intern Resurs" className="text-left w250" text={this.state.rso.iresurs} getValue={(e)=> this.val_save("iresurs", e) } />
            </div>
            <div className="flex-row py-2">
                    <Fr_Input label="Boktyp" className="text-left w250" text={this.state.rso.boktyp} getValue={(e)=> this.val_save("boktyp", e) } />
            </div>
            <div className="divider_green"></div>
            </form>
        </div>

        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                AVBRYT
            </button>
            <div className="flex-row"></div>
            <button className="btn btn-hw-orange w250" onClick={() => this.kds_del() } >
                TA BORT
            </button>
                <div className="flex-row"></div>
                <button className="btn btn-hw-green w250" onClick={() => this.kds_save() } >
                    SPARA
                </button>
        </div>
      </div>
 
      </div>
	;
	return html;
  };
}

export default Kds_Edit;
