import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";
import {SDOProvider} from "../../sdo";
import {withRouter} from "../../lib/react/withRouter";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Rps_Home extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, tra: [] };
    this.state.seldate = new Date();
    this.state.avd = "01";
    this.state.paa = [];
    this.state.aa = [];
    //context.posrend = this;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }
  fknGo(url) {
    //alert(row.href);
    this.props.navigate(url);
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 480;
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

  plu_open(row) {
      var self = this;
  }
  plu_edit(row) {
      var self = this;
      alert(JSON.stringify(row.plu00));
  }

  render() {
    return (
    <div className="web-app">
    <h3>RAPPORTER</h3>

    <div className="web-p01 flex">

    <div className="web-row">
    <div className="web-col flex">
        <div className="web-btn" onClick={() => this.fknGo("/cas/rps/cas") } >
            <div>KASINO RAPPORT</div>
            <div>Komplett kasino rapport</div>
        </div>
    </div>
     <div className="web-col flex" style={{padding: "0px 10px"}}>
        <div className="web-btn" onClick={() => this.fknGo("/cas/rps/cr") } >
            <div>KASSA RAPPORT</div>
            <div>Kassörs rapport</div>
        </div>
   </div>
     <div className="web-col flex">
        <div className="web-btn" onClick={() => this.fknGo("/cas/rps/dr") } >
            <div>DAG RAPPORT</div>
            <div>Daglig rapport</div>
        </div>
        <div className="web-btn" onClick={() => this.fknGo("/cas/rps/mr") } >
            <div>MÅNADS RAPPORT</div>
            <div>Månatlig rapport</div>
        </div>
   </div>
   </div>

    </div>

    </div>
  );
    }
}
export default withRouter(Rps_Home);
