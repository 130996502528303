import React, { useState, useEffect, useContext } from 'react';
import * as fkn from "../../lib/fkn";
import * as net from "../../lib/net";

import Dt_Cal from "../../lib/ui/dt_cal";
import Dd_Sel from "../../lib/ui/dd_sel";

import {format} from "date-fns";
import Lang from "../../lib/lang";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import * as utils from "../../assets/utils";

import Krub_Verify from "./krub/verify";
import Krub_Jr from "./krub/krub_jr";
import Krub_Dts from "./krub/krub_dts";
import Krub_Rvs from "./krub/krub_rvs";
import Krub_Recov from "./krub/krub_recov";
import Krub_Ov from "./krub/krub_ov";

class Eko_Krub extends React.Component {
  constructor(props, context) {
    super(props);

    this.dt_fd = React.createRef();
    this.dt_td = React.createRef();

    this.store = props.store;
    this.pms = props.store.pms;

    this.state = {};
    this.state.fkn = "";
    this.state.superusr = "";

    this.state.today = new Date();
    var dts = format(this.state.today, "yyyy-MM-dd");
    this.state.fdat = dts;
    this.state.tdat = dts;
      this.state.wnds = {};
      this.state.sortid = "";
      this.state.sortdir = 1;
      this.state.dta = [];
      this.state.tot = {};
      if(this.pms.base) {
          this.state.fdat = this.pms.base.fdat;
          this.state.tdat = this.pms.base.tdat;
      }

      this.selitems = [
        { fkn: 'recov', text: 'Recover Krubba' },
        { fkn: 'verify', text: 'Verifiera' }
    ];

  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.trans();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 260;
      css.overflow = "auto";
      return css;
  }
    th_style(id) {
        var css = {};
        css.cursor = "pointer";
        if(id == this.state.sortid) {
            if(this.state.sortdir) css.color = "#800";
            else css.color = "#080";
        }
        return css;
    }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }
  dt_fdat(e) {
    //alert(JSON.stringify(e));
    var dts = format(e, "yyyy-MM-dd");
    this.setState({ fdat: dts });
  }
  dt_tdat(e) {
      //alert(JSON.stringify(e));
    var dts = format(e, "yyyy-MM-dd");
    this.setState({ tdat: dts });
  }
  oneday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_td.current.updateDt(this.state.fdat);
  }
  addday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_fd.current.updateAdd();
    this.dt_td.current.updateAdd();
  }
  subday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_fd.current.updateSub();
    this.dt_td.current.updateSub();
  }

  change_fkn(e) {
    //let cdat = this.state.seldate;
    //alert(JSON.stringify(e));
    let fkn = e.target.value;
    this.setState({fkn: fkn});
    this.setState({tra: [], tsa: [], tta: [] });
  }

  krub_ft() {
    var self = this;
    //var fdatum = format(this.state.fdat, "yyyy-MM-dd");
    //var tdatum = format(this.state.tdat, "yyyy-MM-dd");

    var prm = {};
    prm.req = "pms.krub.krub_ft";
    prm.fdat = this.state.fdat;
    prm.tdat = this.state.tdat;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            return;
        }
        var dta = ret.dta;
        var tot = ret.tot;
        self.setState({ dta: dta, tot: tot }, function () {
            self.syncTb("saldoTable");
        });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
    }
    
  saldo_ft() {
    var self = this;
    //var fdatum = format(this.state.fdat, "yyyy-MM-dd");
    //var tdatum = format(this.state.tdat, "yyyy-MM-dd");

    var prm = {};
    prm.req = "pms.eko_red.saldo_ft";
    prm.fdat = this.state.fdat;
    prm.tdat = this.state.tdat;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            return;
        }
        var dta = ret.dta;
        var tot = ret.tot;
        self.setState({ dta: dta, tot: tot }, function () {
            self.syncTb("saldoTable");
        });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
    }
    syncTb(tb) {
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
            tdElements = tbel.getElementsByTagName('td');
        if(tdElements.length < 1 ) return;

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
                width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
    tb_sort(typ) {
        var self = this;
        //var rvs = self.state.rvs;
        var tra = self.state.tra;
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;

        if(sortid != typ) sortdir = 0;
        else {
            if(sortdir) sortdir = 0;
            else sortdir = 1;
        }

        if(sortdir) tra.sort((a,b) => (a[typ] < b[typ]) ? 1 : ((b[typ] < a[typ]) ? -1 : 0));
        else tra.sort((a,b) => (a[typ] > b[typ]) ? 1 : ((b[typ] > a[typ]) ? -1 : 0));

        self.setState({ tra: tra, sortid: typ, sortdir: sortdir });
    }
    th_icon(typ) {
        var cls = "";
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;
        if(sortid == typ)
        {
            if(sortdir) cls = "fa fa-caret-up";
            else cls = "fa fa-caret-down";
        }
        return cls;
    }
    val_pris(val) {
        var s = "";
        if(val == 0) s = "0.00";
        if(val) s = val.cdipris();
        return s;
    };


    krub_verify() {
        var self = this;
        var wnds = {};
        wnds.verify = true;
        //wnds.tro = row;
        this.setState({ wnds: wnds });
      }
      krub_jr() {
        var self = this;
        var wnds = {};
        wnds.krubjr = true;
        wnds.fdat = this.state.fdat;
        wnds.tdat = this.state.tdat;
        this.setState({ wnds: wnds });
      }
      krub_day(row) {
        var self = this;
        var wnds = {};
        wnds.krubjr = true;
        wnds.fdat = row.k0datum.cdidate();
        wnds.tdat = row.k0datum.cdidate();
        wnds.show = true;
        this.setState({ wnds: wnds });
      }
      krub_dts() {
        var self = this;
        var wnds = {};
        wnds.krubdts = true;
        wnds.fdat = this.state.fdat;
        wnds.tdat = this.state.tdat;
        this.setState({ wnds: wnds });
      }
      krub_rvs() {
        var self = this;
        var wnds = {};
        wnds.krubrvs = true;
        wnds.fdat = this.state.fdat;
        wnds.tdat = this.state.tdat;
        this.setState({ wnds: wnds });
      }
      krub_recov() {
        var self = this;
        var wnds = {};
        wnds.krubrecov = true;
        //wnds.tro = row;
        this.setState({ wnds: wnds });
      }
      
      krub_ov() {
        var self = this;
        var wnds = {};
        wnds.krubov = true;
        //wnds.tro = row;
        this.setState({ wnds: wnds });
      }
    sel_fkn(sel) {
        //alert(JSON.stringify(fkn));
        if(sel.fkn == "verify") {
            this.krub_verify();
        }
        if(sel.fkn == "recov") {
            this.krub_recov();
        }
    }
       set_su() {
        var self = this;
        var superusr = this.state.superusr;
        if(superusr == "S") superusr = "";
        else superusr = "S";
        this.setState({ superusr: superusr });
      }
    
  render() {
    return (
    <div className="web-app">
    <div className="red" onDoubleClick={() => this.set_su() }>
        KRUBBA
    </div>

    <div className="flex-row">
    <div className="flex-row">
              <div className="dt-btn-01" onClick={() => this.subday() } ><FontAwesomeIcon icon="minus" size="1x" /></div>
              <Dt_Cal dts={ this.state.fdat } rp="1" ref={this.dt_fd} getValue={(e)=> this.dt_fdat(e)} />
              <div className="dt-btn-01" onClick={() => this.oneday() } >1</div>
              <Dt_Cal dts={ this.state.tdat } rp="1" ref={this.dt_td} getValue={(e)=> this.dt_tdat(e)} />
              <div className="dt-btn-01" onClick={() => this.addday() } ><FontAwesomeIcon icon="plus" size="1x" /></div>
        </div>
        <div className="flex"></div>
        <button className="btn btn-hw-blue w150" onClick={() => this.krub_dts() } aria-label="System" >
            Krubba Dag
        </button>
        <button className="btn btn-hw-blue w150" onClick={() => this.krub_rvs() } aria-label="System" >
            Bokningar
        </button>
        <button className="btn btn-hw-blue w150" onClick={() => this.krub_jr() } aria-label="System" >
            Journal
        </button>
       <button className="btn btn-hw-blue w150" onClick={() => this.krub_ft() } aria-label="System" >
            Visa
        </button>
        { this.state.superusr == "S" ? <Dd_Sel items={ this.selitems } cls="web-x-icon" getValue={(e)=> this.sel_fkn(e)} /> : null }
    </div>
    <div className="divider_green"></div>

        <div id="saldoTable" className="flex">
        <table className="table table-bordered table-hover" style={{marginBottom: "0px"}}>
            <thead>
            <tr>
                <th>Datum</th>
                <th>Fsg</th>
                <th>Hotell</th>
                <th>Rest(Överf)</th>
                <th>Bet</th>
                <th>Krubb förändring</th>
                <th>Krubba In</th>
                <th>Krubba Ut</th>
            </tr>
            </thead>
            </table>
            <div style={ this.tb_style() }>
          <table className="table table-bordered table-hover">
            <tbody>
            { this.state.dta.map((row, key) =>
            <tr key={key} onDoubleClick={() => this.krub_day(row) }>
                <td>{ row.k0datum.cdidate() }</td>
                <td>{ this.val_pris(row.k0fsg) }</td>
                <td>{ this.val_pris(row.k0hot) }</td>
                <td>{ this.val_pris(row.k0rst) }</td>
                <td>{ this.val_pris(row.k0bet) }</td>
                <td>{ this.val_pris(row.k0saldo) }</td>
                <td>{ this.val_pris(row.k0in) }</td>
                <td>{ this.val_pris(row.k0ut) }</td>
            </tr>
            )}
           </tbody>
        </table>
            </div>

        </div>
        <div className="flex-row">
         <div className="flex">TOTALER:</div>
         <div className="px-5">FSG: { utils.toPris(this.state.tot.fsg) }</div>
         <div className="px-5">BET: { utils.toPris(this.state.tot.bet) }</div>
         <div className="px-5">IN: { utils.toPris(this.state.tot.kin) }</div>
         <div className="px-5">UT: { utils.toPris(this.state.tot.kut) }</div>
         <div className="px-5">SALDO: { utils.toPris(this.state.tot.saldo) }</div>
     </div>

        { this.state.wnds.verify ? <Krub_Verify wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.wnds.krubjr ? <Krub_Jr wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.wnds.krubdts ? <Krub_Dts wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.wnds.krubrvs ? <Krub_Rvs wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.wnds.krubrecov ? <Krub_Recov wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.wnds.krubov ? <Krub_Ov wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
    </div>
  );
}
}
export default Eko_Krub;
