import React, { useState, useEffect, useContext } from 'react';
import { format, addDays } from 'date-fns'
import {withRouter} from "../../lib/react/withRouter";

import * as net from "../../lib/net";
import {SDOProvider} from "../../sdo";
import Dt_Cal from "../../lib/ui/dt_cal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class PMS_OvCal extends React.Component {
  constructor(props, context) {
    super(props);

    this.dt_fd = React.createRef();
    this.dt_td = React.createRef();

    this.webStore = props.store;
    this.state = { pgid: 11, tra: [] };
    this.state.avd = "01";
    this.state.rva = [];
    this.state.kro = {};
    this.state.aa = [];
    this.state.cols = {};
    this.state.firstday = {};
    this.state.caldata = [];
    this.state.rtdata = [];
    this.state.hoa = [];
    this.state.kfa = [];
    this.state.rsa = [];

    this.state.dio = {};
    this.state.loading = true;

    this.state.today = new Date();
    this.state.sdat = format(this.state.today, "yyyyMMdd");
    this.state.fdat = format(this.state.today, "yyyy-MM-dd");
    var tdat = addDays(this.state.today, 30);
    this.state.tdat = format(tdat, "yyyy-MM-dd");
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.bel_cal();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
    tb_style() {
        var css = {};
        css.height = window.innerHeight - 175;
        css.overflow = "auto";
        return css;
    }
    right_style() {
        var css = {};
        css.width = "475px";
        css.height = window.innerHeight - 150;
        css.overflow = "auto";
        return css;
    }
    right_top() {
        var css = {};
        css.width = "475px";
        return css;
    }
  row_style(row) {
      var css = {};
      return css;
  }
  row_css(row) {
      var css = {};
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

  dt_style(dto) {
      //dto.wday == '0';
      return;
  }
  dt_class(dto) {
      var cls = "";
      if(dto.wday == 5) cls = "weekend";
      if(dto.wday == 6) cls = "weekend2";
      return cls;
  }
  get_css(dto) {
      var cls = "";
      if(dto.wday == 5) cls = "weekend";
      if(dto.wday == 6) cls = "weekend2";
      return cls;
  }
    dt_fdat(e) {
        //alert(JSON.stringify(e));
        var dts = format(e, "yyyy-MM-dd");
        this.setState({ fdat: dts });
    }
    dt_tdat(e) {
        //alert(JSON.stringify(e));
        var dts = format(e, "yyyy-MM-dd");
        this.setState({ tdat: dts });
    }
    oneday(e) {
        //alert(JSON.stringify(e));
        //this.setState({ tdat: this.state.fdat });
        this.dt_td.current.updateDt(this.state.fdat);
    }
    addday(e) {
        //alert(JSON.stringify(e));
        //this.setState({ tdat: this.state.fdat });
        this.dt_fd.current.updateAdd();
        this.dt_td.current.updateAdd();
    }
    subday(e) {
        //alert(JSON.stringify(e));
        //this.setState({ tdat: this.state.fdat });
        this.dt_fd.current.updateSub();
        this.dt_td.current.updateSub();
    }
  dt_check(dto) {
      //dto.wday == '0';
      return true;
  }
  dioenter(dto) {
      //dto.wday == '0';
      return;
  }
  dioleave(dto) {
      //dto.wday == '0';
      return;
  }
  set_di(dto) {
      //dto.wday == '0';
      return;
  }
  selov(dto) {
      //dto.wday == '0';
      return;
  }
  seldn(dto) {
      //dto.wday == '0';
      return;
  }
  selup(dto) {
      //dto.wday == '0';
      return;
  }
  st_cal_sel(row) {
      var self = this;
      this.setState({sdat: row.date}, function() {
          self.hot_dts();
      });
  }
  selrt(row) {
      var self = this;
  }
  sel_rt(row) {
      var self = this;
  }
  fkn_close() {
    this.setState({edit: false });
    //this.props.history.push("cas");
    //this.login_srv(uid, pwd);
  }
  fknGo(url) {
    //alert(row.href);
    this.props.navigate(url);
}

  bel_cal() {
    var self = this;

    var prm = {};
    prm.req = "pms.cal.pms_cal_state";
    prm.fdat = this.state.fdat;
    prm.tdat = this.state.tdat;

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        self.setState({caldata: ret.rco, loading: false }, function () {
            self.syncTb("calTable");
        });
        self.hot_dts();
    })
    .catch(function(e) {
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }
  hot_dts() {
    var self = this;

    var prm = {};
    prm.req = "pms.rt.rt_state";
    prm.datum = this.state.sdat;

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rco));

        var keys = Object.keys(ret.rco);
        var a = [];
        for(var key of keys) {
            var o = ret.rco[key];
            a.push( o );
        }
        self.setState({hoa: a, loading: false });
        //self.syncCols("rtTable");
        //toastr.success("OK", "SAPI");
         self.kf_dts();
   })
    .catch(function(e) {
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }
  kf_dts() {
    var self = this;

    var prm = {};
    prm.req = "pms.rs.rs_per_dt";
    prm.datum = this.state.sdat;

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        self.setState({kfa: ret.rco.rsa, rsa: ret.rco.rsa, loading: false });
        //self.syncCols("rtTable");
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e) {
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }
  st_state() {
    var self = this;

    var prm = {};
    prm.req = "pms.cal.pms_dt_state";
    prm.datum = "";

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        alert(JSON.stringify(ret));

        self.props.store.pms.stat = ret.rco;
    })
    .catch(function(e) {
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }

    syncTb(tb) {
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
            tdElements = tbel.getElementsByTagName('td');

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
                width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }

  render() {
    return (
    <div className="web-app">

    <div className="flex-row">
        <div className="flex-row flex">
        <div className="flex-row">
              <div className="dt-btn-01" onClick={() => this.subday() } ><FontAwesomeIcon icon="minus" size="1x" /></div>
              <Dt_Cal dts={this.state.fdat} rp="1" ref={this.dt_fd} getValue={(e)=> this.dt_fdat(e)} />
              <div className="dt-btn-01" onClick={() => this.oneday() } >1</div>
              <Dt_Cal dts={this.state.tdat} rp="1" ref={this.dt_td} getValue={(e)=> this.dt_tdat(e)} />
              <div className="dt-btn-01" onClick={() => this.addday() } ><FontAwesomeIcon icon="plus" size="1x" /></div>
        </div>
        <div className="flex"></div>
       <button className="btn btn-hw-blue w150" onClick={() => this.bel_cal() } aria-label="System" >
            UPPDATERA
        </button>
        </div>
       <div className="flex-row" style={ this.right_top() }>
            <div className="flex"></div>
            <button className="btn btn-hw-blue w150" onClick={() => this.fknGo("/pms/stat") } aria-label="System" >
                STATUS
            </button>
        </div>
    </div>
    <div className="divider_orange"></div>

    { this.state.loading ? <div className="flex flex-col flex-center"><FontAwesomeIcon icon="spinner" spin size="4x" /></div> : null }
    { !this.state.loading ?
    <div className="flex-row flex" style={{overflow: "auto"}}>
    <div className="web-col flex px-2">

        <div id="calTable" className="flex">
        <table className="table table-bordered tb_pres" style={{marginBottom: "0px", marginTop: "2px"}}>
            <thead>
            <tr>
                <th className="text-center">Datum</th>
                <th className="text-center">Vecka</th>
                <th className="text-center">Dag</th>
                <th className="text-center">S</th>
                <th className="text-center">H / F</th>
                <th className="text-center">Belägg</th>
                <th className="text-center">Information</th>
            </tr>
            </thead>
        </table>

        <div style={ this.tb_style() }>
            <table className="table table-bordered table-striped tb_pres" style={{ marginTop: "0px" }}>
            <tbody>
            { this.state.caldata.map((row, key) =>
                <tr key={key} className={ this.row_css(row) } style={ this.row_style(row) }>
                    <td className='header text-center' onClick={ () => this.st_cal_sel(row) }>{ row.date.cdidate() }</td>
                    <td className="text-center">{ row.week }</td>
                    <td className="text-center">{ row.day.toswe() }</td>
                    <td className="text-center">{ row.cleaned }</td>
                    <td className="text-center">{ row.nrr } / { row.free }</td>
                    <td className="text-center">{ row.belag } %</td>
                    <td className="text-left" onClick={ () => this.set_di(row) }>{ row.info }</td>
                </tr>
            )}
            </tbody>
            </table>
        </div>
        </div>

        </div>
        <div className="vert_green"></div>
        <div className="web-col px-2"  style={ this.right_style() }>

            <div className="flex_row">
                <div className="txt_18 flex">HOTELL</div>
                <div className="txt_18">{ this.state.sdat.cdidate() }</div>
            </div>
            <table className="table table-bordered table-striped tb_pres" style={{ margin: "0px"}}>
                <thead>
                <tr>
                    <td style={{ textAlign: "center", maxWidth: "200px"}}>Rumstyp</td>
                    <td style={{ textAlign: "center"}}>Ank</td>
                    <td style={{ textAlign: "center"}}>Bo</td>
                    <td style={{ textAlign: "center"}}>Avr</td>
                    <td style={{ textAlign: "center"}}>Fria</td>
                </tr>
                </thead>
                <tbody>
            { this.state.hoa.map((row, key) =>
                <tr key={key}>
                    <td className='header'>{ row.rt }</td>
                    <td>{ row.ankr }/{ row.ankg }</td>
                    <td>{ row.borr }/{ row.borg }</td>
                    <td>{ row.avrr }/{ row.avrg }</td>
                    <td>{ row.free }</td>
                </tr>
            )}
                </tbody>
            </table>
            <div className="divider_orange"></div>
            <div className="txt_18">KONFERENS</div>

            <table className="table table-bordered table-striped tb_pres" style={{ margin: "0px"}}>
                <thead>
                <tr>
                    <td style={{ textAlign: "center", maxWidth: "300px"}}>Lokal</td>
                    <td style={{ textAlign: "center"}}>F</td>
                    <td style={{ textAlign: "center"}}>E</td>
                    <td style={{ textAlign: "center"}}>K</td>
                    <td style={{ textAlign: "center"}}>Fria</td>
                </tr>
                </thead>
                <tbody>
            { this.state.kfa.map((row, key) =>
                <tr key={key}>
                    <td className='header'>{ row.restname }</td>
                    <td>{ row.status }</td>
                    <td>{ row.status }</td>
                    <td>{ row.status }</td>
                    <td>{ row.platser.ztrim() }</td>
                </tr>
            )}
                </tbody>
            </table>
            <div className="hide">
            <div className="divider_orange"></div>
            <div className="txt_18">RESURSER</div>

            <table className="table table-bordered table-striped tb_pres" style={{ margin: "0px"}}>
                <thead>
                <tr>
                    <td className='header' style={{ textAlign: "center", maxWidth: "200px"}}>Rumstyp</td>
                    <td style={{ textAlign: "center"}}>FR</td>
                    <td style={{ textAlign: "center"}}>FM</td>
                    <td style={{ textAlign: "center"}}>LU</td>
                    <td style={{ textAlign: "center"}}>EM</td>
                    <td style={{ textAlign: "center"}}>KV</td>
                    <td style={{ textAlign: "center"}}>Fria</td>
                    <td style={{ textAlign: "center"}}>Pris</td>
                </tr>
                </thead>
                <tbody>
            { this.state.rsa.map((row, key) =>
                <tr key={key}>
                    <td className='header'>{ row.restname }</td>
                    <td>{ row.fr }</td>
                    <td>{ row.fm }</td>
                    <td>{ row.lu }</td>
                    <td>{ row.em }</td>
                    <td>{ row.kv }</td>
                    <td>{ row.platser }</td>
                    <td>{ row.fria }</td>
                </tr>
            )}
                </tbody>
            </table>
        </div>
        </div>
        </div>
        : null }
   </div>
  );
  }
}

export default withRouter(PMS_OvCal);
