import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../../lib/net";
import Fr_Input from "../../../lib/ui/fr_input";
import Fr_Select from "../../../lib/ui/fr_select";

class Pay_Edit extends React.Component {
  constructor(props, context) {
    super(props);

    this.pos = props.store.pos;
    this.state = {};
    this.state.mka = [];

    this.state.ynq = [
                {id: '0', text: 'NEJ'},
                {id: '1', text: 'JA'}
            ];

    this.state.bto = {};
    if(props.wnds.bto) this.state.bto = props.wnds.bto;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    tab_style(tabid) {
      var css = {};
      css.display = "none";
      if(this.state.tabval === tabid) css.display = "block";
      return css;
    }
    tab_head(tabid) {
      var css = {};
      css.background = "#fff";
      css.color = "#000";
      if(this.state.tabval === tabid) {
          css.background = "#080";
          css.color = "#fff";
      }
      return css;
    }
    grp_style(row) {
      var css = {};
      css.background = "#008";
      if(this.state.gnr === row.vgrupp) css.background = "#080";
      return css;
    }

    formchange (e) {
      const name = e.target.name;
      const value = e.target.value;
      var pbo = this.state.pbo;
      var na = name.split(".");
      pbo[na[0]][na[1]] = value;
      this.setState({pbo: pbo});
      //setState({[name]: value});
    }

    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    wnd_close = () => {
		this.props.ctx.setState({ wnds: {} });
	};
    handleChange = (event, newValue) => {
        //alert(newValue);
        this.setState({ix: newValue});
      };

  val_color(id, val) {
      var css = {};
      //alert(JSON.stringify(val))
      this.ctrl_fg.updateState(val.hex);
      this.setState({ bgfarg: val.hex });
      return css;
  }
  val_save(name, value) {
      var bto = this.state.bto;
      var na = name.split(".");
      bto[na[0]][na[1]] = value;
      //bto[na[1]] = value;
      this.setState({bto: bto});
      return;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  pay_save() {
    var self = this;
    var prm = {};
    prm.req = "pos.pos_pay.pay_upd";
    prm.pbo = this.state.bto;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        //self.setState({paa: ret.rco });
		self.props.ctx.setState({ wnds: {} });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "Betalsätt";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        <div className="m2_backdrop" />
        <div className="m2_dlg mx_90 flex-col" style={ this.dlg_style() } >
        <div className="mx_content flex">

            <div className="flex-row" style={{padding:"5px",height:"35px"}}>
                    <div className="flex-col flex">
                        <div>BETALSÄTT:</div>
                    </div>
                    <div className="flex-col">
                        <div>UPPDATERA</div>
                    </div>
            </div>
            <div className="divider_orange"></div>

            <form className="cdi_f03">
            <div className="flex-row">
                    <Fr_Input label="Avd" className="text-left w350" text={this.state.bto.bet00.b0kstns} getValue={(e)=> this.val_save("bet00.b0kstns", e) } />
                    <Fr_Input label="Betalkod" className="text-left w350" text={this.state.bto.bet00.b0kod} getValue={(e)=> this.val_save("bet00.b0kod", e) } />
            </div>
            <div className="flex-row">
                    <Fr_Input label="Betal Text" className="text-left w350" text={this.state.bto.bet00.b0txt} getValue={(e)=> this.val_save("bet00.b0txt", e) } />
                    <Fr_Input label="Redovisning" className="text-left w350" text={this.state.bto.bet00.b0red} getValue={(e)=> this.val_save("bet00.b0red", e) } />
            </div>
            <div className="flex-row">
                    <Fr_Input label="Knapp Text" className="text-left w350" text={this.state.bto.bet00.b0pluknapp} getValue={(e)=> this.val_save("bet00.b0pluknapp", e) } />
            </div>
            <div className="flex-row">
                    <Fr_Select label="Moms" className="text-left w350" text={this.state.bto.bet00.b0moms} items={this.state.fargtema} getValue={(e)=> this.val_save("bet00.b0moms", e) } />
            </div>
            <div className="divider_green"></div>
            <div className="flex-row">
                    <Fr_Input label="Konto" className="text-left w350" text={this.state.bto.bet00.b0kontonr} getValue={(e)=> this.val_save("bet00.b0kontonr", e) } />
                    <Fr_Input label="Ukonto" className="text-left w350" text={this.state.bto.bet00.b0ukonto} getValue={(e)=> this.val_save("bet00.b0ukonto", e) } />
            </div>
            <div className="flex-row">
                    <Fr_Input label="Rabatt Betal" className="text-left w350" text={this.state.bto.bet00.b0rabkod} getValue={(e)=> this.val_save("bet00.b0rabkod", e) } />
                    <Fr_Input label="Rabatt Procent" className="text-left w350" text={this.state.bto.bet00.b0rabatt} getValue={(e)=> this.val_save("bet00.b0rabatt", e) } />
            </div>
            <div className="flex-row">
                    <Fr_Input label="Valuta" className="text-left w350" text={this.state.bto.bet00.b0valuta} getValue={(e)=> this.val_save("bet00.b0valuta", e) } />
                    <Fr_Input label="Valuta Justering" className="text-left w350" text={this.state.bto.bet00.b0valjus} getValue={(e)=> this.val_save("bet00.b0valjus", e) } />
            </div>
            <div className="divider_green"></div>
            <div className="flex-row">
                    <Fr_Input label="Kostnadsställe" className="text-left w250" text={this.state.bto.bet02.b2kst} getValue={(e)=> this.val_save("bet02.b2kst", e) } />
                    <Fr_Input label="Projekt" className="text-left w250" text={this.state.bto.bet02.b2projekt} getValue={(e)=> this.val_save("bet02.b2projekt", e) } />
            </div>
            <div className="flex-row">
            <Fr_Input label="Konto" className="text-left w250" text={this.state.bto.bet02.b2konto} getValue={(e)=> this.val_save("bet02.b2konto", e) } />
            <Fr_Input label="Kontotyp" className="text-left w150" text={this.state.bto.bet02.b2ktyp} getValue={(e)=> this.val_save("bet02.b2ktyp", e) } />
            </div>

                <div className="divider_green"></div>
                <div className="flex-row">
                    <Fr_Input label="Ansvar" className="text-left w250" text={this.state.bto.bet03.b3ansvar} getValue={(e)=> this.val_save("bet03.b3ansvar", e) } />
                    <Fr_Input label="Verksamhet" className="text-left w250" text={this.state.bto.bet03.b3verksamhet} getValue={(e)=> this.val_save("bet03.b3verksamhet", e) } />
                </div>
                <div className="flex-row">
                    <Fr_Input label="Aktivitet" className="text-left w250" text={this.state.bto.bet03.b3aktivitet} getValue={(e)=> this.val_save("bet03.b3aktivitet", e) } />
                    <Fr_Input label="Projekt" className="text-left w250" text={this.state.bto.bet03.b3projekt} getValue={(e)=> this.val_save("bet03.b3projekt", e) } />
                </div>
                <div className="flex-row">
                    <Fr_Input label="Objekt" className="text-left w250" text={this.state.bto.bet03.b3objekt} getValue={(e)=> this.val_save("bet03.b3objekt", e) } />
                    <Fr_Input label="Fridel" className="text-left w250" text={this.state.bto.bet03.b3fridel} getValue={(e)=> this.val_save("bet03.b3fridel", e) } />
                </div>
                <div className="flex-row">
                    <Fr_Input label="Motpart" className="text-left w250" text={this.state.bto.bet03.b3motpart} getValue={(e)=> this.val_save("bet03.b3motpart", e) } />
                </div>

            <div className="divider_green"></div>
            <div className="flex-row">
                    <Fr_Input label="Auktoritet" className="text-left w150" text={this.state.bto.bet00.b0filler1} getValue={(e)=> this.val_save("bet00.b0filler1", e) } />
                    <Fr_Input label="Lådöppning" className="text-left w150" text={this.state.bto.bet00.b0ladopn} getValue={(e)=> this.val_save("bet00.b0ladopn", e) } />
            </div>
            </form>

        </div>
        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250 ripple" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <button className="btn btn-hw-red w250 ripple" onClick={() => this.wnd_close() } >
                TA BORT
            </button>
            <div className="flex-row">
            <button className="btn btn-hw-red w250 ripple" onClick={() => this.pay_save() } >
                SPARA
            </button>
            </div>
        </div>
      </div>
      </div>
	;
	return html;
  };
}

export default Pay_Edit;