import React, { useState, useEffect, useRef, useContext } from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

//import './css/dd_sel.css';
import sso from "../../sso";

class Wnd_Sign extends React.Component {
  constructor(props, context) {
    super(props);

    this.node = React.createRef();
    //this.setNodeRef = this.setNodeRef.bind(this);

    this.state = {};
    this.state.cols = {};
    this.state.sel_show = false;
      this.state.sel_step = 0;
      this.state.signatur = "";
      this.state.backdrop = 1;
    this.state.val = props.msg.val;
  }

  componentDidUpdate() {
  }
  componentWillUnmount() {
      //document.removeEventListener("mousedown", this.handleClick);
      document.removeEventListener("keydown", this.handleKey);
  }
  componentDidMount() {
      //document.addEventListener("mousedown", this.handleClick);
      document.addEventListener("keydown", this.handleKey);
  }
/*
TS - Thom Stenström - full access
SN - Sofie Nilsson - full access

BL - Barbara Larsson
OJ - Oskar Johansson
OL -  Olivia Lindecrantz
HH -  Helena Hansson
KT - Kajsa Tunius
LT - Linda Tilly
YL - Ylva Lindemark
DA - Daniel Aspen
LS - Ludvig Sardwall
HG - Hebron Gadissa Tuji
*/
    handleKey = (e) => {
        if(!this.props.msg.sign) return;

        e.preventDefault();
        const keyCode = e.keyCode;
        const key = e.key;
        const value = e.target.value;

        //alert(JSON.stringify(keyCode));
        if(keyCode == 13) {
            //alert(JSON.stringify(this.state.signatur));
            var brc = false;
            var signatur = this.state.signatur;
            signatur = signatur.toUpperCase();
            if(signatur == "00") brc = true;
            if(signatur == "TS") brc = true;
            if(signatur == "SN") brc = true;
            if(signatur == "BL") brc = true;
            if(signatur == "OJ") brc = true;
            if(signatur == "OL") brc = true;
            if(signatur == "HH") brc = true;
            if(signatur == "KT") brc = true;
            if(signatur == "LT") brc = true;
            if(signatur == "YL") brc = true;
            if(signatur == "DA") brc = true;
            if(signatur == "LS") brc = true;
            if(signatur == "HG") brc = true;
            if(brc) {
                //this.props.ctx.setState({ msg: {} });
                this.props.ctx[this.props.msg.cb](signatur);
            }
            return;
        }
        if(keyCode == 8) {
            var signatur = this.state.signatur;
            var len = signatur.length;
            if(len > 0) signatur = signatur.substr(0,  len - 1);
            this.setState({ signatur: signatur });
        }
        if(!isNaN(key)) {
            var signatur = this.state.signatur;
            signatur = signatur + key;
            this.setState({ signatur: signatur });
        }
        if(key.match(/^[a-zA-Z()]$/)) {
            var signatur = this.state.signatur;
            signatur = signatur + key;
            this.setState({ signatur: signatur });
        }
    }
    handleClick = (e) => {
        if(!this.props.msg.sign) return;

        if (!this.node.current.contains(e.target)) {
            //this.setState({sel_show: false});
            this.props.ctx.setState({ msg: {} });
            return;
        }
    }
  val_save (e) {
    //alert(JSON.stringify(name));
    //alert(JSON.stringify(value));
    e.preventDefault();
    const signatur = e.target.value;
      this.setState({signatur: signatur});
    }
    sel_cancel() {
        //this.props.ctx[this.props.msg.cb](val, this.state.val);
        this.props.ctx.setState({ msg: {} });
    }
    sel_sign() {
        var brc = false;
        var signatur = this.state.signatur;
        signatur = signatur.toUpperCase();
        if(signatur == "00") brc = true;
        if(signatur == "TS") brc = true;
        if(signatur == "SN") brc = true;
        if(signatur == "BL") brc = true;
        if(signatur == "OJ") brc = true;
        if(signatur == "OL") brc = true;
        if(signatur == "HH") brc = true;
        if(signatur == "KT") brc = true;
        if(signatur == "LT") brc = true;
        if(signatur == "YL") brc = true;
        if(signatur == "DA") brc = true;
        if(signatur == "LS") brc = true;
        if(signatur == "HG") brc = true;
        if(brc) {
            //this.props.ctx.setState({ msg: {} });
            this.props.ctx[this.props.msg.cb](signatur);
        }
    }
    sel_fkn(val) {
        this.props.ctx[this.props.msg.cb](val, this.state.val);
        //this.props.ctx.setState({ msg: {} });
    }
  close_info() {
    this.props.ctx.setState({ msg: {} });
  }
  render() {
    if(!this.props.msg.sign) return "";

    const infobody = {
        borderRadius: "6px"
    }
    const infownd = {
        position: 'absolute',
        width: '35%',
      height: '35%',
      zIndex: '19999',
      top: '35%',
      left: '35%',
        border: "0px solid #ccc",
        borderRadius: "6px",
        backgroundColor: "#0575E6",  /* fallback for old browsers */
        background: "-webkit-linear-gradient(to right, #021B79, #0575E6)",  /* Chrome 10-25, Safari 5.1-6 */
        backgroundImage: "linear-gradient(to right, #021B79, #0575E6)" /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    }

    var title = this.props.title;
    if(!title) title = "URVAL";

	var html =
        <div className="mx_base">
        { this.state.backdrop ? <div className="mx_backdrop" /> : null }
        <div ref={this.node} style={ infownd } className="d-flex flex-column" >
        <div style={ infobody } className="flex d-flex flex-column align-content-center justify-content-center text-center">
        <h2 className="" style={{color: "#fff"}}>
            { this.props.msg.text }
        </h2>
        <FontAwesomeIcon className="hide" icon="info" size="4x" style={{color: "#fff"}} />
        <div className="flex-row align-content-center justify-content-center text-center">
              <input value={this.state.signatur} onChange={(e)=> this.val_save(e) }
                     type="password" className="form-control txt_22 text-center w250"
                     placeholder="" required
              />
        </div>
        </div>

        <div className="flex-row justify-content-center mb-3">
            <button className="btn btn-hw-red w200" onClick={(event) => this.sel_cancel() } >
                AVBRYT
            </button>
            <button className="btn btn-hw-green w200" onClick={(event) => this.sel_sign() } >
                SIGNERA
            </button>
        </div>

        </div>
        </div>
	;
	return html;
  };
}

export default Wnd_Sign;