import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../../lib/net";
import * as utils from "../../../assets/utils";

import Fr_Input from "../../../lib/ui/fr_input";
import Wnd_Error from "../../../lib/ui/wnd_error";
import Wnd_Ok from "../../../lib/ui/wnd_ok";
import Wnd_Info from "../../../lib/ui/wnd_info";
import Wnd_Confirm from "../../../lib/ui/wnd_confirm";
import Fr_Cal from "../../../lib/ui/fr_cal";
import Lang from "../../../lib/lang";
import Td_Sel from "../../../lib/ui/td_sel";

class Mnu_Prd_Add extends React.Component {
  constructor(props, context) {
    super(props);

    this.pos = props.store.pos;
    if(!this.pos.plus) this.pos.plus = [];

    this.state = {};
    this.state.paa = this.pos.plus;
    this.state.poa = this.pos.plus;

    this.state.gnr = "001";
    this.state.ynq = [
                {id: '0', text: 'NEJ'},
                {id: '1', text: 'JA'}
            ];
      this.state.mtval = [
          {id: 'bas', text: 'BAS MENY'},
          {id: 'xtra', text: 'EXTRA MENY'}
      ];

      this.state.tabval = 0;
      this.state.selrow = {};
      this.state.msg = {};
      this.state.wnds = {};
      this.state.mno = {};
      this.state.mpa = [];
      if(props.wnds.mno) {
          if(props.wnds.mno) this.state.mno = props.wnds.mno;
          if(props.wnds.mpa) this.state.mpa = props.wnds.mpa;
      }
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      //if(!this.props.wnds.tdo) this.task_init();
      //this.mnu_mp();
      if(!this.pos.plus.length > 0) this.plus();
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    tb_style() {
        var css = {};
        css.height = (window.innerHeight * 0.8) - 175;
        css.overflow = "auto";
        return css;
    }
    th_style(id) {
        var css = {};
        css.cursor = "pointer";
        if(id == this.state.sortid) {
            if(this.state.sortdir) css.color = "#800";
            else css.color = "#080";
        }
        return css;
    }
    row_style(row) {
        var srow = this.state.selrow;

        var css = {};
        if(srow == row) {
            css.background = "#080";
            css.color = "#fff";
        }
        return css;
    }
    tab_head(tabid) {
        var css = {};
        css.background = "#fff";
        css.color = "#000";
        if(this.state.tabval === tabid) {
            css.background = "#080";
            css.color = "#fff";
        }
        return css;
    }
    tab_style(tabid) {
        var css = {};
        css.display = "none";
        if(this.state.tabval === tabid) css.display = "block";
        return css;
    }
    tab_sel(tabid) {
      var self = this;
        this.setState({tabval: tabid}, function() {
            if(tabid == 1) {
                self.syncTb("mnpTable");
            }
        });
    }

    wnd_close = () => {
        var self = this;
        if(self.props.wnds.cb) {
            self.props.wnds.cb(self.props.ctx);
        }
        else self.props.ctx.setState({ wnds: {} });
    };
    mt_change (e) {
        const value = e.target.value;
        var mno = this.state.mno;
        mno.mnutyp = value;
        this.setState({mno: mno});
    }
    anm_change (e) {
        const value = e.target.value;
        var mno = this.state.mno;
        mno.anm = value;
        this.setState({mno: mno});
    }

    val_save(id, val) {
        var mno = this.state.mno;
        mno[id] = val;
        this.setState({ mno: mno });
        return;
    }
    sel_row(row) {
        var srow = this.state.selrow;
        if(srow == row) {
            this.setState({selrow: {}});
        }
        else this.setState({selrow: row});
        this.syncTb("mnpTable");
    }
    sel_row_fkn(row, sel) {
        if(sel.fkn == "open") {
        }
    }
    mnu_mp() {
        var self = this;
        var prm = {};
        prm.req = "dsi.dsi_mp.mp_mnu";
        prm.mnuid = this.state.mno.mnuid;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.rca));

                self.setState({ mpa: ret.rca });
                //self.setState({ rva: ret.rca });
                self.syncTb("mnpTable");
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    syncTb(tb) {
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
            tdElements = tbel.getElementsByTagName('td');
        if(tdElements.length < 1 ) return;

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
                width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
    tb_sort(typ) {
        var self = this;
        //var rvs = self.state.rvs;
        var rva = self.state.rva;
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;

        if(sortid != typ) sortdir = 0;
        else {
            if(sortdir) sortdir = 0;
            else sortdir = 1;
        }

        if(typ == "ank") {
            if(sortdir) rva.sort((a,b) => (a.gsb00.r0ankdatum < b.gsb00.r0ankdatum) ? 1 : ((b.gsb00.r0ankdatum < a.gsb00.r0ankdatum) ? -1 : 0));
            else rva.sort((a,b) => (a.gsb00.r0ankdatum > b.gsb00.r0ankdatum) ? 1 : ((b.gsb00.r0ankdatum > a.gsb00.r0ankdatum) ? -1 : 0));
        }
        self.setState({ rva: rva, sortid: typ, sortdir: sortdir });
    }
    th_icon(typ) {
        var cls = "";
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;
        if(sortid == typ)
        {
            if(sortdir) cls = "fa fa-caret-up";
            else cls = "fa fa-caret-down";
        }
        return cls;
    }
  mnu_save() {
    var self = this;
    var prm = {};
    prm.req = "bcs.wrk.task_upd";
    prm.tdo = this.state.tdo;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));
        if(ret.ok == "000") {
            self.setState({ msg: {ok: true, text: "Sparad ..."} });
            setTimeout(function(){
                self.setState({msg: {} });
                  if(self.props.wnds.cb) {
                      self.props.wnds.cb(self.props.ctx);
                  }
                  else self.props.ctx.setState({ wnds: {} });
                return;
            }, 1000);
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  mnu_del() {
    var self = this;
    var prm = {};
    prm.req = "bcs.wrk.task_del";
    prm.eko = this.state.eko;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));
        if(ret.ok == "000") {
            self.setState({ msg: {ok: true, text: "Borttagen"} });
            setTimeout(function(){
                self.setState({msg: {} });
                  if(self.props.close) {
                      self.props.close(self.props.ctx);
                  }
                  else self.props.ctx.setState({ wnds: {} });
                return;
            }, 1000);
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
    plus() {
        var self = this;
        var prm = {};
        prm.req = "pos.pos_plu.plus";
        prm.avd = this.state.avd;

        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret.rca));

            self.props.store.pos.plus = ret.rco;
            self.setState({paa: ret.rco, poa: ret.rco });
            self.syncTb("plusTable");
            //toastr.success("OK", "SAPI");
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }
    prd_add_sel() {
        var self = this;
        var srow = self.state.selrow;
        if(srow && srow.plu00) {
            self.prd_add(srow);;
        }
    }
    prd_add(row) {
        var self = this;

        var mpo = {};
        mpo.typ = "P";
        mpo.mnuid = this.state.mno.mnuid;
        mpo.plu = row.plu00.p0kstns + row.plu00.p0katnr;
        mpo.text = row.plu00.p0namn;
        mpo.prdtyp = "";
        mpo.sort = "";
        mpo.status = "";

        var prm = {};
        prm.req = "dsi.dsi_mp.mp_add";
        prm.mpo = mpo;

        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret));
            if(ret.ok == "000") {
                //this.props.wnds.mpa.push(mpo);

                self.setState({ msg: {ok: true, text: "Sparad ..."} });
                setTimeout(function(){
                    self.setState({msg: {} });
                    /*
                    if(self.props.wnds.cb) {
                        self.props.wnds.cb(self.props.ctx);
                    }
                    else self.props.ctx.setState({ wnds: {} });
                    */
                    return;
                }, 1000);
            }
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }

  render() {
    //if(!this.props.show) return "";
    var title = this.props.title;
    if(!title) title = "Resurs";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        <div className="m2_backdrop" />
        <div className="m2_dlg mx_70h flex-col" style={ this.dlg_style() } draggable="true" >
        <div className="mx_content flex">

        <div className="mx_h01">
            <div>MENY PRODUKT</div>
        </div>
            <div className="flex-row" style={{padding:"5px",height:"35px"}}>
                    <div className="flex-col flex">
                    </div>
                    <div className="flex-col">
                        <div>UPPDATERA</div>
                    </div>
            </div>
            <div className="divider_orange"></div>

            <div id="plusTable" className="flex">
                <table className="table table-bordered table-hover tb"  style={{marginBottom: "0px"}}>
                    <thead>
                    <tr>
                        <th style={ this.th_style("plu") } onClick={() => this.tb_sort("plu") }>PLU</th>
                        <th style={ this.th_style("text") } onClick={() => this.tb_sort("text") }>Text</th>
                        <th style={ this.th_style("pris") } onClick={() => this.tb_sort("pris") }>Pris</th>
                    </tr>
                    </thead>
                </table>
                <div style={ this.tb_style() }>
                    <table className="table table-bordered table-hover tb">
                        <tbody>
                        { this.state.paa.map((row, key) =>
                            <tr key={ key } style={ this.row_style(row) } onClick={() => this.sel_row(row)} onDoubleClick={() => this.prd_add(row) }>
                                <td>{ row.plu00.p0katnr }</td>
                                <td>{ row.plu00.p0namn.toswe() }</td>
                                <td className="text-right">{ row.plu00.p0pris.formatpris() }</td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
        <div className="mx_foot flex-row">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex"></div>
            <button className="btn btn-hw-red w250" onClick={() => this.prd_add_sel() } >
                LÄGG TILL
            </button>
        </div>
      </div>

        <Wnd_Error msg={this.state.msg} ctx={this} />
        <Wnd_Ok msg={this.state.msg} ctx={this} />
        <Wnd_Info msg={this.state.msg} ctx={this} />
        <Wnd_Confirm msg={this.state.msg} ctx={this} />
      </div>
	;
	return html;
  };
}

export default Mnu_Prd_Add;