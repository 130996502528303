import React, { useState, useEffect, useContext } from 'react';
import {format, addDays, subDays} from "date-fns";

import * as net from "../../../../lib/net";
import Fr_Input from "../../../../lib/ui/fr_input";
import Fr_Select from "../../../../lib/ui/fr_select";
import Fr_Cal from "../../../../lib/ui/fr_cal";
import Dt_Cal from "../../../../lib/ui/dt_cal";

class Kf_Bel extends React.Component {
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pms = props.store.pms;

    this.state = {};
    this.state.today = new Date();
    this.state.ynq = [
                {id: '0', text: 'NEJ'},
                {id: '1', text: 'JA'}
            ];

    this.state.background = "#fff";
    this.state.rsa = [];
    this.state.dta = [];

    this.state.cols = {};
    this.state.boknr = {};
    if(props.boknr) this.state.boknr = props.boknr;
    this.state.kfo = {};
    if(props.wnds) {
        if(props.wnds.boknr) this.state.boknr = props.wnds.boknr;
        if(props.wnds.kfo) this.state.kfo = props.wnds.kfo;
    }
    this.state.fdat = format(this.state.today, "yyyy-MM-dd");
    var dat = addDays(this.state.today, 15);
    this.state.tdat = format(dat, "yyyy-MM-dd");
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.bel_kf();
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    grp_style(row) {
      var css = {};
      css.background = "#008";
      if(this.state.gnr === row.vgrupp) css.background = "#080";
      return css;
    }

    formchange (e) {
      const name = e.target.name;
      const value = e.target.value;
      var pbo = this.state.pbo;
      var na = name.split(".");
      pbo[na[0]][na[1]] = value;
      this.setState({pbo: pbo});
      //setState({[name]: value});
    }

    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    wnd_close = () => {
      if(this.props.cb) {
        var ret = {};
        ret.ok = "999";
        this.props.cb(this.props.ctx, ret);
      }
      else this.props.ctx.setState({ wnds: {} });
	};
    handleChange = (event, newValue) => {
        //alert(newValue);
        this.setState({ix: newValue});
      };
    toggle() {
      this.setState({modal: false});
	};

    submitHandler = event => {
        event.preventDefault();
        event.target.className += " was-validated";
    };

    changeHandler = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    rsv_save (name, value) {
        //alert(JSON.stringify(name));
        //alert(JSON.stringify(value));
        var rsv = this.state.bro;
        var na = name.split(".");
        rsv[na[0]][na[1]] = value;
        this.setState({rsv: rsv});
        //setState({[name]: value});
    }
    val_save (name, value) {
        //alert(JSON.stringify(name));
        //alert(JSON.stringify(value));
      var kfo = this.state.kfo;
      kfo[name] = value;
      this.setState({kfo: kfo});
      //setState({[name]: value});
    }
    val_dt_save (name, value) {
        //alert(JSON.stringify(name));
        //alert(JSON.stringify(value));
      var state = this.state;
     var dts = format(value, "yyyy-MM-dd");
     state[name] = dts;
      this.setState(state);
      //setState({[name]: value});
    }
    rs_set (name, e) {
      const value = e.target.value;
      var kfo = this.state.kfo;
      kfo[name] = value;
      this.setState({kfo: kfo});
      //setState({[name]: value});
    }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  dt_kf(row, dto) {
      var self = this;
      var txt = "";

      var rs = row.restname.trim();
      if(dto.rs[rs]) {
          var o = dto.rs[rs];
          //alert(JSON.stringify(dto));
          txt = o.status;
      }
      return txt;
  }

  bel_kf() {
    var self = this;
    //var fdat = format(this.state.seldate, "yyyy-MM-dd");
    //var tdat = format(this.state.seldate, "yyyy-MM-dd");

    var prm = {};
    prm.req = "pms.rs.rs_per_dt";
    prm.fdat = this.state.fdat;
    prm.tdat = this.state.tdat;
    //prm.avd = this.state.avd;

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        self.setState({rsa: ret.rco.rsa, dta: ret.rco.rca, loading: false });
        //self.syncCols("rvpTable");
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e) {
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }
  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "Delbokning";
    //if(props.kro) alert(props.kro.krr00.kid);
    const popover = {
      position: 'absolute',
      top: '50px',
      zIndex: '2',
    }
    const cover = {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    }

	var html =
        <div className="mx_base">
        <div className="m2_backdrop" />
        <div className="m2_dlg mx_80 flex-col" style={ this.dlg_style() } draggable="true" >

        <div className="mx_h01">
            <div>-BELÄGGNING</div>
        </div>
        <div className="mx_content flex px-2">

            <div className="flex-row flex-space-between">
                <div className="flex-row">
                <Dt_Cal className="text-left w250" text={this.state.fdat} getValue={(e)=> this.val_dt_save("fdat", e) }/>
                <Dt_Cal className="text-left w250" text={this.state.tdat} getValue={(e)=> this.val_dt_save("tdat", e) }/>
                </div>
                <button className="btn btn-hw-blue w150 ripple" onClick={() => this.bel_kf() } aria-label="System" >
                    UPPDATERA
                </button>
            </div>
            <div className="divider_green"></div>
    <div className="web-col">
        <table className="table table-bordered tb_pres">
            <thead>
            <tr>
                <th style={{textAlign: "center"}}>Text</th>
                <th style={{textAlign: "left"}}>ID</th>
                <th style={{textAlign: "center"}}>Plats</th>
                { this.state.dta.map((dto, key) =>
                    <th key={key}>{ dto.dt }<br />{ dto.day }</th>
                )}
            </tr>
            </thead>
            <tbody>
            { this.state.rsa.map((row, key) =>
            <tr key={key} >
                <td className='header' onClick={ () => this.selrt(row) }>{ row.restname }</td>
                <td style={{textAlign: "left", padding: "0px 3px"}}>{ row.iresurs }</td>
                <td>{ row.platser }</td>
                { this.state.dta.map((dto, key) =>
                    <td key={key}>{ this.dt_kf(row, dto) }</td>
                )}
            </tr>
                )}
            </tbody>
        </table>
    </div>

        </div>

        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex-row">
            </div>
        </div>
      </div>
      </div>
	;
	return html;
  };
}

export default Kf_Bel;