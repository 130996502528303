import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";
import Udid_Add from "./udid_add";
import Udid_Open from "./udid_open";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Bo_Udids extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.pos = props.store.pos;

    this.state = {};
    this.state.sio = null;
    this.state.seldate = new Date();
    this.state.avd = "01";
    this.state.uaa = [];
    this.state.paa = [];
    this.state.udidadd = 0;
    this.state.udidopen = 0;
    this.state.pbo = {};
    this.state.aa = [
                {id: '01', text: 'AVD 01'},
                {id: '02', text: 'AVD 02'}
            ];

    if(this.pos.deps) this.state.aa = this.pos.deps;
  }
  componentDidUpdate() {
    let value = this.context;
  }
  componentWillUnmount() {
    let value = this.context;
  }
  componentDidMount() {
      this.udids();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 250;
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

  udid_add(row) {
      var self = this;
    this.setState({ pbo: row, udidadd: 1 });
  }
  udid_open(row) {
      var self = this;
    this.setState({ pbo: row, udidopen: 1 });
  }
  users_sz() {
      var self = this;
      var poa = self.state.poa;

      var paa = poa.filter(row => row.plu00.p0namn.includes("z"));
      self.setState({paa: paa });
   }

  udids() {
    var self = this;
    var prm = {};
    prm.req = "udid:list";

    net.gcs_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        self.setState({uaa: ret.rca });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  tst() {
    var self = this;
    var prm = {};
    prm.req = "udid:list";
    //prm.sz = "a";

    //gda.wndLoading("Hämtar");
    net.gcs_req(prm)
    .then(function(ret){
        alert(JSON.stringify(ret));

        var o = {};
        //o.deps = ret.rco;
        //alert(JSON.stringify(o));
        //self.setState({paa: ret.rco });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }

  render() {
    return (
    <div className="web-app d-flex flex-column">

    <div className="web-p01">
    <div className="flex-row">
        <div className="txt_18">ENHETER</div>
        <div className="flex"></div>
        <div className="rlink_12" onClick={() => this.udid_add() }>NY ENHET</div>
    </div>
    <div className="divider_orange"></div>
    <div className="flex-row">
        <select className="form-control w250" placeholder="Välj typ">
            { this.state.aa.map((row, key) =>
            <option key={key} value={row.id}>{row.text}</option>
            )}
        </select>
        <div className="flex"></div>
        <div className="link_14" onClick={() => this.udid_add() }>NY ENHET</div>
        <div className="link_14" onClick={() => this.udids() }>UPPDATERA</div>
    </div>
    <div className="divider_orange"></div>

    <div className="web-row">
        <table className="table table-bordered table-hover tb fixed_header">
            <thead>
            <tr>
                <th>UDID</th>
                <th>Text</th>
                <th>Token</th>
                <th>SID</th>
                <th>xx</th>
                <th>xx</th>
                <th>Status</th>
                <th className="w50"></th>
            </tr>
            </thead>
            <tbody style={ this.tb_style() }>
            { this.state.uaa.map((row, key) =>
            <tr key={key} id={ row.udid00.u0udid } onDoubleClick={() => this.udid_open(row) }>
                <td>{ row.udid00.u0udid }</td>
                <td>{ row.udid00.u0text.toswe() }</td>
                <td className="text-left">{ row.udid00.u0token }</td>
                <td>{ row.udid00.u0sid }</td>
                <td>{ row.udid00.xx }</td>
                <td>{ row.udid00.xx }</td>
                <td>{ row.udid00.u0status }</td>
                <td className="text-center w40" style={{cursor:"pointer"}} onClick={() => this.udid_open(row) }><i className="fa fa-cog text-danger"></i></td>
            </tr>
            )}
            </tbody>
        </table>
    </div>

    </div>
            { this.state.udidadd ?
        <Udid_Add show={this.state.udidadd} ctx={this} pbo={this.state.pbo} backdrop={true}/>
            : null }
            { this.state.udidopen ?
        <Udid_Open show={this.state.udidopen} cb={(e)=> {this.setState({udidopen: false}); this.udids();} } ctx={this} pbo={this.state.pbo} backdrop={true}/>
            : null }
    </div>
  );
    }
}
export default Bo_Udids;

/*

            { this.state.usropen ?
        <Usr_Open show={this.state.usropen} ctx={this} pbo={this.state.pbo} backdrop={true}/>
            : null }

*/