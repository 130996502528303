import React, { useState, useEffect, useContext } from 'react';

import Fr_Input from "../../../lib/ui/fr_input";

class Tv_Cfg extends React.Component {
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pos = props.store.pos;

    this.state = {};
    this.state.today = new Date();
    this.state.rta = [];
    this.state.pka = [];

    this.state.tvid = "1";
    this.state.gso = {};

    this.state.ynq = [
                {id: '0', text: 'NEJ'},
                {id: '1', text: 'JA'}
            ];

    this.state.rs = "";
    this.state.tbo = {};
    this.state.background = "#fff";

    this.state.wnds = {};
    if(props.wnds) {
        this.state.tvid = props.wnds.tvid;
    }
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
      document.removeEventListener("keydown", this.handleKey);
  }
  componentDidMount() {
    document.addEventListener("keydown", this.handleKey);
    //this.tbo_init();
  }

    handleKey = (e) => {
      e.preventDefault();
      //alert(e.key);
      //alert(e.keyCode);
      if(e.keyCode == 27) {
          //alert("ENTER DLG");
          this.wnd_close();
      }
      if(e.keyCode == 13) {
          //alert("ENTER DLG");
          this.pitv_upd();
      }
      if(e.keyCode == 49) {
        var tvid = "1";
        //wnds.cb = self.cb_tbo;
        this.setState({tvid: tvid });
      }
      if(e.keyCode == 50) {
        var tvid = "2";
        //wnds.cb = self.cb_tbo;
        this.setState({tvid: tvid });
      }
      if(e.keyCode == 51) {
        var tvid = "3";
        //wnds.cb = self.cb_tbo;
        this.setState({tvid: tvid });
      }
      if(e.keyCode == 52) {
        var tvid = "4";
        //wnds.cb = self.cb_tbo;
        this.setState({tvid: tvid });
      }
      if(e.keyCode == 53) {
        var tvid = "5";
        //wnds.cb = self.cb_tbo;
        this.setState({tvid: tvid });
      }
      if(e.keyCode == 54) {
        var tvid = "6";
        //wnds.cb = self.cb_tbo;
        this.setState({tvid: tvid });
      }
  }
    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    wnd_close = () => {
      if(this.props.cb) {
        var ret = {};
        ret.ok = "999";
        this.props.cb(this.props.ctx, ret);
      }
      else this.props.ctx.setState({ wnds: {} });
	};

  pitv_upd() {
    var self = this;

    self.props.store.cfg.scrid = "tv0" + self.state.tvid;

    var req = {};
    req.tvid = self.state.tvid;
    fetch("http://127.0.0.1:7071/pitv_upd", {
    //fetch("/pitv_upd", {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: "POST",
        body: JSON.stringify(req)
    })
    .then(response => response.json())
    .then(
        (rsp) => {
          //alert("RSP: " + JSON.stringify(rsp));
          //self.setState({tvo : rsp.tvo });
            self.wnd_close();
        },
        (error) => {
          alert("Err: " + JSON.stringify(error));
        }
    )
    .catch(function(res){
          alert("Err Catch: " + JSON.stringify(res));
    });
  }

  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "Gäst";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        <div className="m3_backdrop" />
        <div className="m3_dlg mx_50h flex-col" style={ this.dlg_style() } draggable="true" >

        <div className="mx_h01">
            <div>TV CONFIG</div>
        </div>
        <div className="mx_content flex">

            <div className="divider_orange"></div>

            <form className="cdi_f03">
            <div className="flex-row py-2 hide">
                <Fr_Input col label="TV ID" className="text-left w250" text={this.state.tvid} getValue={(e)=> this.val_save("tvid", e) } />
            </div>
            <div className="flex-row flex-center py-2">
                <div className="txt_28">ANGE TV ID</div>
            </div>
            <div className="flex-row flex-center py-2">
                <div className="txt_28">{ this.state.tvid }</div>
            </div>
            <div className="divider_green"></div>
            </form>
        </div>

        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                AVBRYT
            </button>
            <div className="flex-row">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            </div>
        </div>
      </div>

      </div>
	;
	return html;
  };
}

export default Tv_Cfg;
