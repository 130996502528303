import React, { useState, useEffect, useContext } from 'react';
import * as net from "../../../lib/net";

import Lr_Edit from "./lr_edit.js";
import Lr_Pass from "./lr_pass.js";
import Ls_Add from "./ls_add.js";
import Ls_Edit from "./ls_edit.js";

class Lr_Wrk extends React.Component {
  constructor(props, context) {
    super(props);

    //alert('LR CTX: ' + JSON.stringify(props.lro));
   //this.webStore = props.store;
    this.state = {};
    this.state.aa = [];
    this.state.cols = {};
    this.state.wnds = {};

    this.state.lro = {};
    this.state.lso = {};
    this.state.lsa = [];
    if(props.wnds) {
        this.state.lro = props.wnds.lro;
    }
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.lr_lsa();
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    tab_style(tabid) {
      var css = {};
      css.display = "none";
      if(this.state.tabval === tabid) css.display = "block";
      return css;
    }
    tab_head(tabid) {
      var css = {};
      css.background = "#fff";
      css.color = "#000";
      if(this.state.tabval === tabid) {
          css.background = "#080";
          css.color = "#fff";
      }
      return css;
    }
    lr_pass(event) {
      var wnds = {lrpass: 1, lro: this.state.lro};
      this.setState({wnds: wnds});
    }
    lr_edit(event) {
      var wnds = {lredit: 1, lro: this.state.lro};
      this.setState({wnds: wnds});
    }

    ls_add() {
      var wnds = {lsadd: 1, lro: this.state.lro};
      this.setState({wnds: wnds});
    }
    ls_edit(row) {
      var wnds = {lsedit: 1, lro: this.state.lro, lso: row};
      this.setState({wnds: wnds});
    }
    wnds_done(ctx, rsp) {
        if(rsp.wnd == "lredit") {
          if(rsp.status == "removed") {
                ctx.setState({wnds: {}});
		        ctx.props.ctx.wnds_done(ctx.props.ctx, rsp);
		        return;
          }
        }
        ctx.lr_lsa();
        ctx.setState({wnds: {}});
    }

    lr_shd(event) {
    }
    lr_prn(event) {
    }

    lr_lsa() {
        var self = this;
        var prm = {};
     	prm.req = "ta.ta_lon.ls_list";
   		prm.lrid = this.state.lro.lrid;
        //alert(JSON.stringify(prm));

        //gda.wndLoading("Hämtar");
        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret.rca));

            self.setState({lsa: ret.rca }, function() {
                self.syncCols("lsTable");
            });
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
            //gda.wndError(txt);
        });
    }

    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    wnd_close = () => {
		this.props.ctx.setState({ wnds: {} });
	};

  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  cssCols (cid) {
      var css = {};
      if(this.state.cols[cid]) css.width = this.state.cols[cid].toString() + "px";
      //if(cid == "c01") alert(JSON.stringify(this.state.cols[cid]));
      return css;
  }
  syncCols (tb) {

    if (!document.getElementById(tb)) return;

    var cols = {};
    var ix = document.getElementById(tb).rows.length - 1;
    var ic = document.getElementById(tb).rows[0].cells.length;
    var ci = 0;
    while(ci < ic) {
        var cxi = ci + 1;
        var col = "c" + cxi.toString().padStart(2, "0");
        cols[col] = document.getElementById(tb).rows[ix].cells[ci].clientWidth + 1;
        ci = ci + 1;
    }
    this.setState({cols: cols });
    //alert(JSON.stringify(this.state.cols));
    //this.forceUpdate();
  };

  render() {
    ///if(!this.props.show) return "";

    //alert(this.props.ctx.state.lro);

    var title = this.props.title;
    if(!title) title = "Lönekörning";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="mx_backdrop" /> : null }
        <div className="mx_dlg mx_100 flex-col" style={ this.dlg_style() }>
        <div className="mx_h01">
            <div>LR</div>
        </div>
        <div className="mx_content px-3 flex">
            <div className="flex-row">
                    <div className="flex-col flex">
                        <div>Lönekörning:</div>
                        <div>{ this.state.lro.lrid } - { this.state.lro.datum }</div>
                    </div>
                    <div className="flex-col flex">
                        <div>Text:</div>
                        <div>{ this.state.lro.text }</div>
                    </div>
                    <div className="flex-col flex">
                        <div>Period:</div>
                        <div>{ this.state.lro.fdat }-{ this.state.lro.tdat }</div>
                    </div>
                    <div className="flex-col">
                        <div>Betaldag:</div>
                        <div>{ this.state.lro.betdat }</div>
                    </div>
           </div>
            <div className="divider_orange"></div>
            <div className="flex-row">
                    <div className="flex-row flex">
                        <div>Antal Underlag:</div>
                        <div>0</div>
                    </div>
                    <div className="flex-row flex">
                        <div>Brutto:</div>
                        <div>0.00</div>
                    </div>
                    <div className="flex-row flex">
                        <div>Skatt:</div>
                        <div>0.00</div>
                    </div>
                    <div className="flex-row flex">
                        <div>Netto:</div>
                        <div>0.00</div>
                    </div>
                    <div className="flex-col">
                        <div>Uppdatera</div>
                    </div>
             </div>
            <div className="divider_green"></div>

            <div className="flex-col">
            <table className="table table-bordered table-hover tb " style={{marginBottom: "0px"}}>
                <thead>
                <tr>
                    <th style={ this.cssCols("c01" ) }>ID</th>
                    <th style={ this.cssCols("c02" ) }>Text</th>
                    <th style={ this.cssCols("c03" ) }>Från</th>
                    <th style={ this.cssCols("c04" ) }>Till</th>
                    <th style={ this.cssCols("c05" ) }>Betaldag</th>
                    <th style={ this.cssCols("c06" ) }>Bank</th>
                    <th style={ this.cssCols("c07" ) }>Skattesats</th>
                    <th style={ this.cssCols("c08" ) }>Brutto</th>
                    <th style={ this.cssCols("c09" ) }>Skatt</th>
                    <th style={ this.cssCols("c10" ) }>Netto</th>
                    <th style={ this.cssCols("c11" ) }>Status</th>
                </tr>
                </thead>
            </table>
            <div className="tb-cdi" style={ this.tb_style() }>
           <table id="lsTable" className="table table-bordered table-hover tb">
                <tbody>
                { this.state.lsa.map((row, key) =>
                <tr key={ key } onDoubleClick={() => this.ls_edit(row) }>
                    <td>{ row.lsid.ztrim() }</td>
                    <td>{ row.eid.ztrim() } - { row.text }</td>
                    <td>{ row.fdat.cdidate() }</td>
                    <td>{ row.tdat.cdidate() }</td>
                    <td>{ row.betdat }</td>
                    <td>{ row.bank }</td>
                    <td>{ row.tax }</td>
                    <td>{ row.brutto }</td>
                    <td>{ row.skatt }</td>
                    <td>{ row.netto }</td>
                    <td>{ row.status }</td>
                </tr>
                )}
                <tr style={{visibility: "hidden"}}>
                    <th>LSID</th>
                    <th>Text</th>
                    <th>Från</th>
                    <th>Till</th>
                    <th>Betaldag</th>
                    <th>Bank</th>
                    <th>Skattesats</th>
                    <th>Brutto</th>
                    <th>Skatt</th>
                    <th>Netto</th>
                    <th>Status</th>
                </tr>
                </tbody>
            </table>
            </div>
            </div>

        </div>
        <div className="divider_green"></div>
        <div className="flex-row px-3 pb-1">
                <div className="flex-col">
                    <div className="btn_link" onClick={() => this.ls_add() }>Lägg till underlag</div>
                </div>
                <div className="flex"></div>
                <div className="flex-col">
                    <div className="btn_link" onClick={() => this.lr_edit() }>Ändra</div>
                </div>
         </div>
        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w150 ripple" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex-row">
            <button className="btn btn-hw-green w250" onClick={(event) => this.lr_pass(event) } >
                ARBETSPASS
            </button>
            </div>
            <button className="btn btn-hw-green w150" onClick={(event) => this.lr_prn(event) } >
                SKRIV UT ALL
            </button>
        </div>
      </div>
    { this.state.wnds.lrpass ? <Lr_Pass wnds={this.state.wnds} ctx={this} lro={this.state.lro} backdrop={true}/> : null }
    { this.state.wnds.lredit ? <Lr_Edit wnds={this.state.wnds} ctx={this} lro={this.state.lro} backdrop={true}/> : null }
    { this.state.wnds.lsadd ? <Ls_Add wnds={this.state.wnds} ctx={this} lro={this.state.lro} backdrop={true}/> : null }
    { this.state.wnds.lsedit ? <Ls_Edit wnds={this.state.wnds} ctx={this} lro={this.state.lro} backdrop={true}/> : null }
    </div>
	;
	return html;
  };
}

export default Lr_Wrk;