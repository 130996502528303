import React, { useState, useEffect, useContext } from 'react';
import {format} from "date-fns";
import * as utils from "../../../../assets/utils";

import * as net from "../../../../lib/net";
import Dd_Sel from "../../../../lib/ui/dd_sel";
import Dt_Cal from "../../../../lib/ui/dt_cal";
import Fr_Input from "../../../../lib/ui/fr_input";
import sso from "../../../../sso";

class Pay_Pres extends React.Component {
  constructor(props, context) {
    super(props);

     this.selitems = [
        {fkn: 'setup', text: 'INSTÄLLNINGAR'},
        {fkn: 'upd', text: 'UPPDATERA'},
        {fkn: 'sys', text: 'SYSTEM'},
        {fkn: 'logout', text: 'LOGGA UT'}
    ];

    this.dt_dt = React.createRef();

    this.state = {};
    this.state.ynq = [];

      this.state.pro = {};
      this.state.aa = [];
      this.state.presid = "";

      this.state.wnd_ok = false;
      this.state.wnd_error = false;

      this.state.boknr = "";
      this.state.gso = {};
      this.state.pra = [];
      this.state.belopp = "";
      this.state.usebelopp = "";
      if(props.wnds) {
          this.state.boknr = props.wnds.boknr;
          this.state.gso = props.wnds.gso;
          this.state.pra = props.wnds.pra;
          this.state.belopp = props.wnds.belopp;
          //this.state.usebelopp = props.wnds.belopp;
      }

    this.state.today = new Date();
    var dts = format(this.state.today, "yyyy-MM-dd");
    this.state.dts = dts;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      if(this.state.wnd_ok) {
        css.background = "#080";
        css.color = "#fff";
      }
      if(this.state.wnd_error) {
        css.background = "#800";
        css.color = "#fff";
      }
      return css;
    }


    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    wnd_close() {
      if(this.fkn_close) this.fkn_close(this.props.ctx);
      else this.props.ctx.setState({ wnds: {} });
	};
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  val_save (name, value) {
    //var tro = this.state.tro;
    //var na = name.split(".");
    //gso[na[0]][na[1]] = value;
    //tro[name] = value;
    this.setState({presid: value});
    //setState({[name]: value});
}
val_use (name, value) {
    //var tro = this.state.tro;
    //var na = name.split(".");
    //gso[na[0]][na[1]] = value;
    //tro[name] = value;
    this.setState({usebelopp: value});
    //setState({[name]: value});
}
pres_text() {
        var self = this;
        var text = "";
        if(this.state.pro.pres00) {
            text = this.state.pro.pres00.p0text;
        }
        return text;
    }
    pres_saldo() {
        var self = this;
        var saldo = "";
        if(this.state.pro.pres00) {
            saldo = this.state.pro.pres00.p0saldo;
            saldo = utils.toPris(saldo);
        }
        return saldo;
    }
    pres_sz() {
        var self = this;

        var prm = {};
        prm.req = "crs.crs_reg.pres_sz";
        prm.presid = this.state.presid;
        //alert(JSON.stringify(prm));
        //return;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret));
                if(ret.ok == "000") {
                    var saldo = ret.pro.pres00.p0saldo * 1;
                    var touse = self.state.belopp.toString().replace(".", "") * 1;
                    if(saldo < touse) {
                        var usebelopp = ret.pro.pres00.p0saldo;
                        self.setState({pro: ret.pro, usebelopp: usebelopp});
                    }
                    else self.setState({pro: ret.pro, usebelopp: self.state.belopp});

                    self.setState({wnd_ok: true });
                    setTimeout(function(){
                        self.setState({wnd_ok: false });
                        return;
                    }, 1000);
                }
                else {
                    self.setState({wnd_error: true });
                    setTimeout(function(){
                        self.setState({wnd_error: false });
                        return;
                    }, 1000);
               }
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }

    pres_pay() {
        var self = this;

        if(!this.state.pro.pres00) {
            self.setState({wnd_error: true });
            setTimeout(function(){
                self.setState({wnd_error: false });
                return;
            }, 1000);
            alert("Presentkort saknas");
            return;
        }
        
        var psaldo = this.state.pro.pres00.p0saldo.toString().replace(/\./g, "") * 1;
        var belopp = this.state.belopp.toString().replace(/\./g, "") * 1;
        var topay = this.state.belopp.toString().replace(/\./g, "") * 1;
        
        if(psaldo < belopp) {
            alert("Presentkorts belopp för lågt!");
            self.setState({wnd_error: true });
            setTimeout(function(){
                self.setState({wnd_error: false });
                return;
            }, 1000);
            /*
            var topay = psaldo;

            var prm = {};
            prm.req = "crs.crs_reg.pres_use";
            prm.pro = this.state.pro;
            prm.belopp = topay.toString();
            //alert(JSON.stringify(prm));
            //return;
    
            net.sio_req(prm)
            .then(function(ret) {
                var betkod = "Rpri";
                var bettext = "Presentkortsbetalning";
                
                var prm = {};
                //prm.req = "pms.sell.charge_payment";
                //prm.req = "pms.sell.charge_tro";
                prm.req = "pms.sell.charge_tr";
                prm.boknr = self.state.boknr;
                prm.gsb00 = self.state.gso;
                //prm.eko = self.state.eko;
                prm.typ = "P";
                prm.trkod = betkod;
                prm.text = bettext;
                prm.antal = "1";
                prm.pris = topay.toString();
                prm.tkn = "+";
                prm.moms = "";
                prm.datum = this.state.dts;
                return net.sio_req(prm);
            })
            .then(function(ret){
                //alert(JSON.stringify(ret));
                if(ret.ok != "000") {
                    alert("Betal fel...");
                    return;
                }
                
                if(self.props.wnds.cb) {
                    var rsp = {};
                    rsp.ok = "000";
                    self.props.wnds.cb(self.props.ctx, rsp);
                }
                else self.props.ctx.setState({ wnds: {} });
            })
            */
            return;
        }

        var prm = {};
        prm.req = "crs.crs_reg.pres_use";
        prm.pro = this.state.pro;
        prm.belopp = this.state.belopp.toString().replace(".", "");
        //alert(JSON.stringify(prm));
        //return;

        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret));
            if(ret.ok == "000") {
                self.setState({ok: true});
                self.pay_tr("Rpri", "Presentkortsbetalning");
            }
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }

    pay_tr(betkod, bettext) {
        var self = this;

        //var pra = [];
        //for(var row of self.state.nta) {
        //    pra.push(row.etr01);
        //}
        var pra = this.state.pra;
        if(pra.length < 1) {
            alert("Inga rader");
            return;
        }

        var sdo = {};
        sdo.notanr = "";

        var prm = {};
        prm.req = "pms.charge_fkn.charges_toinv";
        prm.boknr = this.state.boknr;
        prm.gsb00 = this.state.gso;
        prm.datum = this.state.dts;
        prm.payrows = pra;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret));

                if(ret.ok != "000") {
                    alert("Nota fel...");
                    return;
                }

                var notanr = ret.notanr;
                var belopp = ret.belopp;
                sdo.notanr = notanr;

                var prm = {};
                prm.req = "pms.sell.charge_pay";
                prm.boknr = self.state.boknr;
                prm.gsb00 = self.state.gso;
                prm.trkod = betkod;
                prm.text = bettext;
                prm.antal = "1";
                prm.pris = belopp;
                prm.tkn = "+";
                prm.moms = "";
                prm.datum = self.state.dts;
                prm.notanr = notanr;
                return net.sio_req(prm);
            })
            .then(function(ret){
                //alert(JSON.stringify(ret));
                if(ret.ok != "000") {
                    alert("Betal fel...");
                    return;
                }

                var prm = {};
                prm.req = "pms.charge.kvitto_gst";
                prm.boknr = self.state.boknr;
                prm.glopnr = self.state.gso.r0glopnr;
                prm.gso = self.state.gso;
                prm.notanr = sdo.notanr;
                prm.prnnr = sso.seo.prnnr;
                return net.sio_req(prm);
            })
            .then(function(ret){
                //alert(JSON.stringify(ret));
                if(ret.ok != "000") {
                    alert("Betal fel...");
                    return;
                }

                if(self.props.wnds.cb) {
                    var rsp = {};
                    rsp.ok = "000";
                    self.props.wnds.cb(self.props.ctx, rsp);
                }
                else self.props.ctx.setState({ wnds: {} });
                //self.charges();
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }

  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "";

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="m2_backdrop" /> : null }
        <div className="m2_dlg mx_70 flex-col" style={ this.dlg_style() }>
        <div className="flex-row " style={{padding:"0px",height:"50px",lineHeight:"50px", background: "#1a456b", color: "#fff"}}>
                <div className="flex-row flex" style={{padding:"0px 5px", fontSize: "22px"}}>
                    <div>PRESENTKORT { this.state.rumsnr }</div>
                </div>
            <div className="wnd-close-btn" onClick={() => this.wnd_close() }>X</div>
        </div>
        <div className="mx_content flex">

            <div className="flex-col">
                <div className="flex-row py-2">
                    <div className="f30"></div>
                    <div className="">BELOPP ATT BETALA: { utils.toPris(this.state.belopp) }</div>
                </div>
            </div>
            <div className="flex-col">
                <div className="flex-row py-2">
                    <div className="f30"></div>
                    <Fr_Input label="Presentkorts ID" col className="text-left w350 mx-2" text={this.state.presid} getValue={(e)=> this.val_save("presid", e) } />
                </div>
                <div className="flex-row py-2">
                    <div className="f30"></div>
                    <button className="btn btn-hw-green w250" onClick={() => this.pres_sz() } >
                        SÖK
                    </button>
                </div>
            </div>

            <div className="flex-col">
                <div className="flex-row py-2">
                    <div className="f30"></div>
                    <div className="">TEXT: { this.pres_text() }</div>
                </div>
            </div>
            <div className="flex-col">
                <div className="flex-row py-2">
                    <div className="f30"></div>
                    <div className="">SALDO: { this.pres_saldo() }</div>
                </div>
            </div>

            <div className="flex-row py-2">
                    <div className="f30"></div>
                    <Fr_Input label="Belopp att utnyttja:" col className="text-right w250 mx-2" text={utils.toPris(this.state.usebelopp)} getValue={(e)=> this.val_use("usebelopp", e) } />
            </div>

        <div className="flex"></div>

        <div className="web-col hide">
        <div className="web-row px-2">
            <div className="txt_16">BELOPP ATT BETALA: </div>
            <Fr_Input noflex className="text-right w150" text={utils.toPris(this.state.belopp)} getValue={(e)=> this.belopp_set(e) } />
            <div className="flex"></div>
        </div>
        </div>

        <div className="divider_orange"></div>

        </div>
        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex"></div>
            <div className="flex"></div>
            <button className="btn btn-hw-green w250" onClick={() => this.pres_pay() } >
                BETALA
            </button>
        </div>
      </div>
    </div>
	;
	return html;
  };
}

export default Pay_Pres;