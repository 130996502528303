import React, { useState, useEffect, useContext } from 'react';
import {addDays, format} from "date-fns";

import * as net from "../../lib/net";
import ddo from '../../ddo.js'

import Dt_Cal from "../../lib/ui/dt_cal";
import DdBtn_Sel from "../../lib/ui/dd_btnsel";

import Ki_Nota from "./ki/ki_nota";
import Ki_Sz from "./ki/ki_sz";
import PdfRpDlg from "../eko/fkn/pdf_rp_dlg";
import Td_Sel from "../../lib/ui/td_sel";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Pos_Ki extends React.Component {
  constructor(props, context) {
    super(props);

    this.selitems = [
        {fkn: 'prn', text: 'SKRIV UT'},
        {fkn: 'prn_inv', text: 'MED NOTOR'}
    ];
    this.dt_fd = React.createRef();
    this.dt_td = React.createRef();

    this.store = props.store;
    this.pos = props.store.pos;
    this.state = { pgid: 11, tra: [] };
    this.state.avd = "01";
    this.state.kro = {};
    this.state.kia = [];
    this.state.aa = [];
    //context.posrend = this;
    this.state.today = new Date();
    var dts = format(this.state.today, "yyyy-MM-dd");
    this.state.fdat = dts;
    this.state.tdat = dts;
    this.state.pdf = 0;
    this.state.pdf_content = null;
    this.state.loading = 0;
    this.state.wnds = {};
      this.state.sortid = "";
      this.state.sortdir = 0;

      this.selitems = [
          {fkn: 'edit', text: 'ÖPPNA'},
          {fkn: 'remove', text: 'TABORT'}
      ];

      this.fknitems = [
          {fkn: 'prn', text: 'SKRIV UT'},
          {fkn: 'prn_list', text: 'SKRIV LISTA'}
      ];
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.kids();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      css.overflow = "auto";
      return css;
  }
    th_style(id) {
        var css = {};
        css.cursor = "pointer";
        if(id == this.state.sortid) css.color = "#080";
        return css;
    }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

    dt_fdat(e) {
        //alert(JSON.stringify(e));
        var dts = format(e, "yyyy-MM-dd");
        if(dts > this.state.tdat) {
            //this.setState({ fdat: dts, tdat: dts });
            //var tdat = addDays(e, 15);
            //var tds = format(tdat, "yyyy-MM-dd");
            this.dt_td.current.updateDt(dts);
        }
        this.setState({ fdat: dts });
    }
    dt_tdat(e) {
        //alert(JSON.stringify(e));
        var dts = format(e, "yyyy-MM-dd");
        this.setState({ tdat: dts });
    }
    oneday(e) {
        //alert(JSON.stringify(e));
        //this.setState({ tdat: this.state.fdat });
        this.dt_td.current.updateDt(this.state.fdat);
    }
    addday(e) {
        //alert(JSON.stringify(e));
        //this.setState({ tdat: this.state.fdat });
        this.dt_fd.current.updateAdd();
        this.dt_td.current.updateAdd();
    }
    subday(e) {
        //alert(JSON.stringify(e));
        //this.setState({ tdat: this.state.fdat });
        this.dt_fd.current.updateSub();
        this.dt_td.current.updateSub();
    }

    ki_edit(row) {
        var self = this;
        var wnds = {};
        wnds.kinota = true;
        wnds.kio = row;
        wnds.backdrop = true;
        self.setState({wnds: wnds });
    }
    ki_sz() {
        var self = this;
        var wnds = {};
        wnds.kisz = true;
        wnds.backdrop = true;
        self.setState({wnds: wnds });
    }

  LevShow(row) {
    var self = this;
    var ktxt = "";
    var levid = row.lev.trim();
    ktxt = levid;
    //alert(JSON.stringify(kid));
    if(this.state.kro[levid]) {
        var o = this.state.kro[levid];
        ktxt = levid + " - " + o.name.toswe();
    }
    return ktxt;
  }
  pdf_done (ret) {
    this.setState({loading: 0, pdf: 0, pdf_content: null });
  }

  kis_prn() {
    var self = this;
    var prm = {};
    prm.req = "pos.pos_rp.rp_ki_list";
    prm.datum = this.state.fdat;
    prm.fdat = this.state.fdat;
    prm.tdat = this.state.tdat;

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        self.setState({pdf: 1, pdf_content: ret.pdf });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }
  kis_prn_notor() {
    var self = this;
    var prm = {};
    prm.req = "pos.pos_rp.rp_ki";
    prm.datum = this.state.fdat;
    prm.fdat = this.state.fdat;
    prm.tdat = this.state.tdat;

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        self.setState({pdf: 1, pdf_content: ret.pdf });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }
  kis() {
    var self = this;

    var prm = {};
    prm.req = "pos.pos_ki.ki_list";
    prm.fdat = this.state.fdat;
    prm.tdat = this.state.tdat;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        self.setState({kia: ret.rca }, function() {
            self.syncTb("kiTable");
        });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

  kids() {
    var self = this;
    var sdo = {};
    var prm = {};
    //prm.req = "kreg.kreg_wrk.kreg_list";
    prm.req = "pos.pos_ki.levs";

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rco));

        ddo.cache.levs = ret.rco;
        sdo.kro = ret.rco;

        //self.setState({kro: ret.rco });
        //self.setState({kro: ret.rco });

        var prm = {};
        prm.req = "pos.pos_ki.ki_list";
        prm.fdat = self.state.fdat;
        prm.tdat = self.state.tdat;
        return net.sio_req(prm);
    })
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        self.setState({kro: sdo.kro, kia: ret.rca },function() {
            self.syncTb("kiTable");
        });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  sel_fkn(sel) {
    //alert(JSON.stringify(fkn));
      if(sel.fkn == "prn") {
          this.kis_prn();
      }
      if(sel.fkn == "prn_inv") {
          this.kis_prn_notor();
      }
  }
    syncTb(tb){
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
        tdElements = tbel.getElementsByTagName('td');

        for (let i = 0; i < thElements.length; i++) {
            if(!tdElements[i]) continue;
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
            width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
  sel_row_fkn(row, sel) {
      //alert(JSON.stringify(fkn));
    if(sel.fkn == "edit") {
        this.kid_edit(row);
    }
  }
    tb_sort(typ) {
        var self = this;
        var kia = self.state.kia;
        var sz = this.state.sz_val;
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;

        if(sortid != typ) sortdir = 0;
        else {
            if(sortdir) sortdir = 0;
            else sortdir = 1;
        }

        if(true) {
            //if(sortdir) kia.sort((a,b) => (a.wfh[f1][f2] < b.wfh[f1][f2]) ? 1 : ((b.wfh[f1][f2] < a.wfh[f1][f2]) ? -1 : 0));
            //else kia.sort((a,b) => (a.wfh[f1][f2] > b.wfh[f1][f2]) ? 1 : ((b.wfh[f1][f2] > a.wfh[f1][f2]) ? -1 : 0));
            if(sortdir) kia.sort((a,b) => (a[typ] < b[typ]) ? 1 : ((b[typ] < a[typ]) ? -1 : 0));
            else kia.sort((a,b) => (a[typ] > b[typ]) ? 1 : ((b[typ] > a[typ]) ? -1 : 0));
        }
        self.setState({ kia: kia, sortid: typ, sortdir: sortdir });
    }

  render() {
    return (
    <div className="web-app">

    <div className="flex-row">
        <div className="flex-row">
            <div className="dt-btn-01" onClick={() => this.subday() } ><FontAwesomeIcon icon="minus" size="1x" /></div>
            <Dt_Cal dts={this.state.fdat} rp="1" ref={this.dt_fd} getValue={(e)=> this.dt_fdat(e)} />
            <div className="dt-btn-01" onClick={() => this.oneday() } >1</div>
            <Dt_Cal dts={this.state.tdat} rp="1" ref={this.dt_td} getValue={(e)=> this.dt_tdat(e)} />
            <div className="dt-btn-01" onClick={() => this.addday() } ><FontAwesomeIcon icon="plus" size="1x" /></div>
        </div>
        <button className="btn btn-hw-blue w150" onClick={() => this.kis() } aria-label="System" >
            Visa
        </button>
        <button className="btn btn-hw-green w150" onClick={() => this.ki_sz() } aria-label="System" >
            Sök
        </button>
        <div className="flex"></div>
        <button className="btn btn-hw-green w150" onClick={() => this.kis_prn() } aria-label="System" >
            Skriv ut
        </button>
        <button className="btn btn-hw-green w150" onClick={() => this.kis_prn_notor() } aria-label="System" >
            Skriv ut notor
        </button>
        <div className="hide">
            <DdBtn_Sel className="btn btn-hw-blue w150" text="SKRIV UT" items={ this.fknitems } getValue={(e)=> this.sel_fkn(e)} />
        </div>
    </div>
    <div className="divider">
    </div>

    <div className="web-row">
        <div id="kiTable" className="flex">
        <table className="table table-bordered table-hover tb" style={{marginBottom:"0px"}}>
            <thead>
            <tr>
                <th style={ this.th_style("datum") } onClick={() => this.tb_sort("datum") }>Datum</th>
                <th style={ this.th_style("notanr") } onClick={() => this.tb_sort("notanr") }>Notanr</th>
                <th style={ this.th_style("lev") } onClick={() => this.tb_sort("lev") }>Lev</th>
                <th style={ this.th_style("rows") } onClick={() => this.tb_sort("rows") }>Rader</th>
                <th style={ this.th_style("status") } onClick={() => this.tb_sort("status") }>Status</th>
                <th></th>
            </tr>
            </thead>
            </table>
            <div style={ this.tb_style() }>
              <table className="table table-bordered table-hover tb" style={{marginBottom:"0px"}}>
                <tbody>
            { this.state.kia.map((row, key) =>
            <tr key={key} onDoubleClick={() => this.ki_edit(row) }>
                <td>{ row.datum.cdidate() }</td>
                <td>{ row.notanr }</td>
                <td>{ this.LevShow(row) }</td>
                <td>{ row.rows.length }</td>
                <td>{ row.status }</td>
                <td>
                    <Td_Sel className="text-danger" items={ this.selitems } getValue={(e)=> this.sel_row_fkn(row, e)} />
                </td>
            </tr>
            )}
            </tbody>
        </table>
        </div>
        </div>
    </div>

    { this.state.wnds.kisz ?
        <Ki_Sz wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
    : null }
    { this.state.wnds.kinota ?
        <Ki_Nota wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
    : null }
    <PdfRpDlg show={this.state.pdf} pdf={this.state.pdf_content} done={(ret) => this.pdf_done(ret)} backdrop={true}/>
    </div>
  );
    }
}
export default Pos_Ki;
