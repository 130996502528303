import React, { useState, useEffect, useContext } from 'react';
import {Route, Routes} from "react-router-dom";

import Fo_Home from './fo/home.js';
import Fo_Rvp from './fo/rvp.js';
import Fo_Rvl from './fo/rvl.js';
import Fo_Acs from './fo/acs.js';
import Fo_Rvsz from './fo/rvsz.js';
import Fo_Profil from './fo/profil.js';
import Fo_Kreg from './fo/kreg.js';
import Fo_Tmpl from './fo/tmpl.js';
import {withRouter} from "../lib/react/withRouter";
import sso from "../sso";

function pg_home() {

    var html =
    <div className="web-content">
      Hem
    </div>
    ;
    return html;
}

class Pms_Fo extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = {};
  }
  componentDidMount() {
  }
  
  fknGo(row) {
    //alert(row.href);
    this.props.store.fkn = row;
      sso.seo.sfkn = row;
    this.props.navigate(row.url);
  }
    br_css(row) {
        var css = {};
        if(sso.seo.sfkn && (row.url == sso.seo.sfkn.url)) css.color = "#080";
        //var brc = false;
        //if(row.url == sbo.state.url) brc = true;
        return css;
    }

  render() {
      var path = window.location.pathname;
      if(path.substr(-1) == "/") path = path.substring(0, path.length - 1);
      var op = path.split("/").pop();
      var po = {};
      po.rvp = { "fid": "f01", "url": "/pms/fo/rvp", "name": "Preliminära", "active": false };
      po.rvl = { "fid": "f01", "url": "/pms/fo/rvl", "name": "Senast", "active": false };
      po.acs = { "fid": "f01", "url": "/pms/fo/acs", "name": "Aktivitetsskärm", "active": false };
      po.sz = { "fid": "f01", "url": "/pms/fo/sz", "name": "Sök", "active": false };
      po.profil = { "fid": "f01", "url": "/pms/fo/profil", "name": "Profiler", "active": false };
      po.kreg = { "fid": "f01", "url": "/pms/fo/kreg", "name": "Kundregister", "active": false };
      po.tmpl = { "fid": "f01", "url": "/pms/fo/tmpl", "name": "Mallar", "active": false };
      if(po[op]) {
          po[op].active = true;
          //this.props.store.fkn = po[op];
      }
      var pa = Object.values(po);

    return (
    <div className="web-app">
         { this.props.store.cfg.bread ?
             <div className="web-bread">
                 <div className="breadcrumb">
            { pa.map((row, key) =>
                <div className="breadcrumb-item" key={key} style={this.br_css(row)} onClick={() => this.fknGo(row)}>{row.name}</div>
            )}
            <div className="flex"></div>
            <div className="cdigreen">{ this.props.store.fkn ? this.props.store.fkn.name : null }</div>
            </div>
        </div>
        : null }
        <Routes>
          <Route path="/xx" component={pg_home} />
            <Route path="rvp" element={ <Fo_Rvp store={this.webStore} />} />
            <Route path="rvl" element={ <Fo_Rvl store={this.webStore} />} />
            <Route path="acs" element={ <Fo_Acs store={this.webStore} />} />
            <Route path="sz" element={ <Fo_Rvsz store={this.webStore} />} />
            <Route path="profil" element={ <Fo_Profil store={this.webStore} />} />
            <Route path="kreg" element={ <Fo_Kreg store={this.webStore} />} />
            <Route path="tmpl" element={ <Fo_Tmpl store={this.webStore} />} />
            <Route path="*" element={ <Fo_Home store={this.webStore} />} />
        </Routes>
    </div>

  );
}
}
export default withRouter(Pms_Fo);
