import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";
import Dt_Cal from "../../lib/ui/dt_cal";

import Sid_Open from "./fkn/sid_open";
import ddo from "../../ddo";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Msa_Theme extends React.Component {
  constructor(props, context) {
    super(props);

    this.store = props.store;

    this.state = {};
    this.state.seldate = new Date();
    this.state.avd = ddo.cache.avd;
    this.state.maa = [];
    this.state.aa = [];
    this.state.mno = {};
    this.state.wnds = {};
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.steps();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

    step_new() {
    var self = this;
    var mno = {};
    mno.typ = "P";
    mno.avd = this.state.avd;
    mno.plunr = "";
    mno.plunrdel = "";
    mno.Text = "";

    var wnds = {};
    wnds.stepopen = true;
    wnds.mno = mno;
    wnds.backdrop = true;
    self.setState({wnds: wnds });
  }
  step_edit(row) {
    var self = this;
    var wnds = {};
    wnds.stepopen = true;
    wnds.mno = row;
    wnds.backdrop = true;
    self.setState({wnds: wnds });
  }

  upd(ctx) {
    ctx.setState({wnds: {} });
    ctx.steps();
  }

    steps() {
    var self = this;
    var prm = {};
    prm.req = "pos.pos_plumenu.plumenus";
    prm.avd = this.state.avd;
    //prm.fplu = datum;
    //prm.tplu = datum;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        self.setState({maa: ret.rca });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

  render() {
    return (
    <div className="web-app">

    <div className="flex-row">
        <select className="form-control w250 hide" placeholder="Välj avdelning">
            { this.props.store.pos.deps.map((row, key) =>
            <option key={key} value={row.avd}>{row.avd} - {row.text}</option>
            )}
        </select>
        <div className="flex"></div>
        <button className="btn btn-hw-blue w150" onClick={() => this.step_new() } aria-label="System" >
            NY
        </button>
        <button className="btn btn-hw-blue w150" onClick={() => this.steps() } aria-label="System" >
            Visa
        </button>
    </div>
    <div className="divider_orange"></div>

    <div className="web-row">
        <table className="table table-bordered table-hover tb fixed_header">
            <thead>
            <tr>
                <th>Avd</th>
                <th>Meny plu</th>
                <th>Under plu</th>
                <th>Text</th>
                <th>Status</th>
                <th style={{width:"40px"}} ></th>
            </tr>
            </thead>
            <tbody style={ this.tb_style() }>
            { this.state.maa.map((row, key) =>
            <tr key={key} id={ row.plunr } onDoubleClick={() => this.step_edit(row) }>
                <td>{ row.avd }</td>
                <td>{ row.plunr }</td>
                <td>{ row.plunrdel }</td>
                <td>{ row.Text }</td>
                <td>{ row.status }</td>
                <td style={{width:"40px",cursor:"pointer"}} onClick={() => this.mnu_edit(row) }><i className="fa fa-cog text-danger"></i></td>
            </tr>
            )}
            </tbody>
        </table>
    </div>

        { this.state.wnds.sidopen ? <Sid_Open wnds={this.state.wnds} ctx={this} store={this.store} /> : null}
    </div>
  );
    }
}
export default Msa_Theme;
