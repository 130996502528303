import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../../lib/net";
import Fr_Input from "../../../lib/ui/fr_input";
import Lang from "../../../lib/lang";
import * as utils from "../../../assets/utils";
import Td_Sel from "../../../lib/ui/td_sel";

class Plu_Menu extends React.Component {
  constructor(props, context) {
    super(props);

    this.pos = props.store.pos;
    this.state = {};
    this.state.ynq = [];
    this.state.sval = [];

    this.state.dao = {};
    this.state.cval = [
                {id: '001', text: 'ANTAL GER NYTT PRIS'},
                {id: '002', text: 'ANTAL GER RABATT PÅ PRIS'},
                {id: '003', text: 'PRISÄNDRING GENOM RABATT'}
            ];

    this.state.hpo = {};
      this.state.hap = {};
      this.state.pbo = {};
      this.state.mno = {};
      this.state.mna = [];
      if(props.wnds.pbo) this.state.pbo = props.wnds.pbo;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.mnus();
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    tab_style(tabid) {
      var css = {};
      css.display = "none";
      if(this.state.tabval === tabid) css.display = "block";
      return css;
    }
    tb_style() {
        var css = {};
        css.height = (window.innerHeight * 0.8) - 150;
        css.overflow = "auto";
        return css;
    }
    th_style(id) {
        var css = {};
        css.cursor = "pointer";
        if(id == this.state.sortid) {
            if(this.state.sortdir) css.color = "#800";
            else css.color = "#080";
        }
        return css;
    }
    row_style(row) {
        var srow = this.state.selrow;

        var css = {};
        if(srow == row) {
            css.background = "#080";
            css.color = "#fff";
        }
        return css;
    }

    wnd_close = () => {
		this.props.ctx.setState({ wnds: {} });
	};

  mnus() {
        var self = this;
        var prm = {};
      prm.req = "dsi.dsi_mnu.mnu_list";

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.rca));

                self.setState({ mna: ret.rca });
                //self.setState({ rva: ret.rca });
                self.syncTb("mnsTable");
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    syncTb(tb) {
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
            tdElements = tbel.getElementsByTagName('td');
        if(tdElements.length < 1 ) return;

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
                width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
    sel_row(row) {
        var srow = this.state.selrow;
        if(srow == row) {
            this.setState({selrow: {}});
        }
        else this.setState({selrow: row});
    }
    tb_sort(typ) {
        var self = this;
        //var rvs = self.state.rvs;
        var rva = self.state.rva;
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;

        if(sortid != typ) sortdir = 0;
        else {
            if(sortdir) sortdir = 0;
            else sortdir = 1;
        }

        if(typ == "ank") {
            if(sortdir) rva.sort((a,b) => (a.gsb00.r0ankdatum < b.gsb00.r0ankdatum) ? 1 : ((b.gsb00.r0ankdatum < a.gsb00.r0ankdatum) ? -1 : 0));
            else rva.sort((a,b) => (a.gsb00.r0ankdatum > b.gsb00.r0ankdatum) ? 1 : ((b.gsb00.r0ankdatum > a.gsb00.r0ankdatum) ? -1 : 0));
        }
        self.setState({ rva: rva, sortid: typ, sortdir: sortdir });
    }
    th_icon(typ) {
        var cls = "";
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;
        if(sortid == typ)
        {
            if(sortdir) cls = "fa fa-caret-up";
            else cls = "fa fa-caret-down";
        }
        return cls;
    }
    mnu_add_sel() {
        var self = this;
        var row = this.state.selrow;
        if(!row.mnuid) return;
        self.mnu_add(row);
    }
    mnu_add(row) {
        var self = this;

        if(!self.state.pbo.plu00) {
            return;
        }

        var mpo = {};
        mpo.typ = "P";
        mpo.mnuid = row.mnuid;
        mpo.plu = self.state.pbo.plu00.p0kstns + self.state.pbo.plu00.p0katnr;
        mpo.text = self.state.pbo.plu00.p0namn;
        mpo.prdtyp = "";
        mpo.sort = "";
        mpo.status = "";

        var prm = {};
        prm.req = "dsi.dsi_mp.mp_add";
        prm.mpo = mpo;

        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret));
            if(self.props.wnds.cb) {
                self.props.wnds.cb(self.props.ctx);
            }
            else self.props.ctx.setState({ wnds: {} });
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }

  render() {
    var title = this.props.title;
    if(!title) title = "Knappar";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        <div className="m2_backdrop" />
        <div className="m2_dlg mx_70h flex-col" style={ this.dlg_style() } >
        <div className="mx_content flex">

            <div className="flex-row" style={{padding:"5px",height:"35px"}}>
                    <div className="flex-col flex">
                        <div>MENYER:</div>
                    </div>
                    <div className="flex-col">
                        <div>UPPDATERA</div>
                    </div>
            </div>
            <div className="divider_orange"></div>

            <div id="mnsTable" className="flex">
                <table className="table table-bordered table-hover" style={{marginBottom: "0px"}}>
                    <thead>
                    <tr>
                        <th style={ this.th_style("typ") } onClick={() => this.tb_sort("typ") }><Lang>Typ <i className={ this.th_icon("typ") }></i></Lang></th>
                        <th style={ this.th_style("mnuid") } onClick={() => this.tb_sort("mnuid") }><Lang>Meny ID</Lang> <i className={ this.th_icon("mnuid") }></i></th>
                        <th style={ this.th_style("text") } onClick={() => this.tb_sort("text") }><Lang>Text</Lang> <i className={ this.th_icon("text") }></i></th>
                        <th style={ this.th_style("mnutyp") } onClick={() => this.tb_sort("mnutyp") }><Lang>Meny Typ</Lang> <i className={ this.th_icon("mnutyp") }></i></th>
                        <th style={ this.th_style("status") } onClick={() => this.tb_sort("status") }><Lang>Status</Lang> <i className={ this.th_icon("status") }></i></th>
                        <th></th>
                    </tr>
                    </thead>
                </table>
                <div style={ this.tb_style() }>
                    <table className="table table-bordered table-hover">
                        <tbody>
                        { this.state.mna.map((row, key) =>
                            <tr key={key} style={ this.row_style(row) } onClick={() => this.sel_row(row)} onDoubleClick={ () => this.mnu_add(row) }>
                                <td>{ row.typ }</td>
                                <td>{ row.mnuid }</td>
                                <td>{ utils.toLang( row.text ) }</td>
                                <td className="text-center">{ utils.toNum( row.mnutyp ) }</td>
                                <td>{ row.status }</td>
                                <td>
                                    <Td_Sel items={ this.selitems } getValue={(e)=> this.sel_row_fkn(row, e)} />
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <button className="btn btn-hw-green w250" onClick={() => this.mnu_add_sel() } >
                SPARA
            </button>
        </div>
      </div>
      </div>
	;
	return html;
  };
}

export default Plu_Menu;