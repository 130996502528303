import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../../lib/net";
import * as utils from "../../../assets/utils";
import Td_Sel from "../../../lib/ui/td_sel";

import Reg_RoomEdit from "./room_edit.js";

class Reg_RtEdit extends React.Component {
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pms = props.store.pms;

    this.state = {};
    this.state.rra = [];
    this.state.rro = {};
    this.state.cols = {};
    this.state.wnds = {};
    this.state.lrwrk = false;
    this.state.ix = 0;
      this.state.rta = [];
      if(props.wnds.rta) {
          this.state.rta = props.wnds.rta;
      }

  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      this.rr();
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }

    wnd_close = () => {
		this.props.ctx.setState({ wnds: {} });
	};
    handleChange = (event, newValue) => {
        //alert(newValue);
        this.setState({ix: newValue});
      };
    tb_style() {
        var css = {};
        css.height = window.innerHeight - 275;
        css.overflow = "auto";
        return css;
    }
    th_style(id) {
        var css = {};
        css.cursor = "pointer";
        if(id == this.state.sortid) {
            if(this.state.sortdir) css.color = "#800";
            else css.color = "#080";
        }
        return css;
    }
  rr() {
    var self = this;
    var prm = {};
    prm.req = "pms.rr.rrq";
    prm.datum = this.state.datum;
    //prm.avd = this.state.avd;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        var rra = [];
        var keys = Object.keys(ret.rco);
        for(var key of keys) {
            var rro = ret.rco[key];
            //alert(JSON.stringify(key));

            rra.push( rro );
        };
        self.setState({ rra: rra, loading: false });
        self.syncTb("roomsTable");
    })
    .catch(function(e) {
        var txt = JSON.stringify(e);
    });
  }
    syncTb(tb) {
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
            tdElements = tbel.getElementsByTagName('td');
        if(tdElements.length < 1 ) return;

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
                width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
    tb_sort(typ) {
        var self = this;
        //var rvs = self.state.rvs;
        var tha = self.state.tha;
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;

        if(sortid != typ) sortdir = 0;
        else {
            if(sortdir) sortdir = 0;
            else sortdir = 1;
        }

        if(typ) {
            if(sortdir) tha.sort((a,b) => (a[typ] < b[typ]) ? 1 : ((b[typ] < a[typ]) ? -1 : 0));
            else tha.sort((a,b) => (a[typ] > b[typ]) ? 1 : ((b[typ] > a[typ]) ? -1 : 0));
        }
        self.setState({ tha: tha, sortid: typ, sortdir: sortdir });
    }
    th_icon(typ) {
        var cls = "";
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;
        if(sortid == typ)
        {
            if(sortdir) cls = "fa fa-caret-up";
            else cls = "fa fa-caret-down";
        }
        return cls;
    }

    room_edit(row) {
        var self = this;
        var wnds = {};
        wnds.wnd_room = 1;
        wnds.rta = this.state.rta;
        wnds.rro = row;
        this.setState({wnds: wnds});
    }

  render() {
    //if(!this.props.show) return "";
    var title = this.props.title;
    if(!title) title = "Rum";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="mx_backdrop" /> : null }
        <div className="mx_dlg mx_90 flex-col" style={ this.dlg_style() }>
        <div className="mx_content flex">

            <div className="flex-row" style={{padding:"5px",height:"35px"}}>
                    <div className="flex-col flex">
                        <div>RUMSTYPS RUM: { this.state.rra.length }</div>
                    </div>
                    <div className="flex-col">
                        <div>UPPDATERA</div>
                    </div>
            </div>
            <div className="divider_orange"></div>

            <div id="roomsTable" className="web-col">
                <table className="table table-bordered table-hover tb " style={{marginBottom: "0px"}}>
                    <thead>
                    <tr>
                        <th style={ this.th_style("rumsnr") } onClick={() => this.tb_sort("rumsnr") }>Rumsnr <i className={ this.th_icon("rumsnr") }></i></th>
                        <th style={ this.th_style("rt") } onClick={() => this.tb_sort("rt") }>Typ <i className={ this.th_icon("rt") }></i></th>
                        <th style={ this.th_style("r1tillgr") } onClick={() => this.tb_sort("r1tillgr") }>Antal <i className={ this.th_icon("r1tillgr") }></i></th>
                        <th style={ this.th_style("r1closed") } onClick={() => this.tb_sort("r1closed") }>Stängda <i className={ this.th_icon("r1closed") }></i></th>
                        <th style={ this.th_style("r1max") } onClick={() => this.tb_sort("r1max") }>Max <i className={ this.th_icon("r1max") }></i></th>
                        <th style={ this.th_style("r1lediga") } onClick={() => this.tb_sort("r1lediga") }>Ledigt <i className={ this.th_icon("r1lediga") }></i></th>
                        <th style={ this.th_style("status") } onClick={() => this.tb_sort("status") }>Status <i className={ this.th_icon("status") }></i></th>
                        <th></th>
                    </tr>
                    </thead>
                </table>
                <div className="tb-cdi" style={ this.tb_style() }>
                    <table className="table table-bordered table-hover tb">
                        <tbody>
                        { this.state.rra.map((row, key) =>
                            <tr key={key} onDoubleClick={() => this.room_edit(row) }>
                                <td className="px-2">{ utils.toLang( row.rumsnr ) }</td>
                                <td className="px-2">{ row.rt }</td>
                                <td className="text-center">{ utils.toNum( row.r1tillgr ) }</td>
                                <td className="text-center">{ row.r1closed }</td>
                                <td className="text-center">{ utils.toNum( row.r1max ) }</td>
                                <td className="text-center">{ utils.toNum( row.r1lediga ) }</td>
                                <td className="text-center">{ utils.toLang( row.status ) }</td>
                                <td>
                                    <Td_Sel items={ this.selitems } getValue={(e)=> this.sel_row_fkn(row, e)} />
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex-row">
            </div>
            <button className="btn btn-hw-green w250" onClick={(event) => this.lr_prn(event) } >
                SPARA
            </button>
        </div>
      </div>
        { this.state.wnds.wnd_room ? <Reg_RoomEdit wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
    </div>
	;
	return html;
  };
}

export default Reg_RtEdit;