import React, { useState, useEffect, useContext } from 'react';
import {format} from "date-fns";
import {observer} from "mobx-react";

import Dt_Cal from "../../lib/ui/dt_cal";
import Dd_Sel from "../../lib/ui/dd_sel";

import * as net from "../../lib/net";
import ddo from '../../ddo.js'

import KvittoWnd from "./dlg/kvitto_wnd";
import KvittoObal from "./dlg/kvitto_obal";
import PdfRpDlg from "./fkn/pdf_rp_dlg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Pos_Kvitton extends React.Component {
  constructor(props, context) {
    super(props);

    this.dt_fd = React.createRef();
    this.dt_td = React.createRef();

    this.pos = props.store.pos;
    this.state = {};
    this.state.today = new Date();
    var dts = format(this.state.today, "yyyy-MM-dd");
    this.state.datum = dts;
    this.state.fdat = dts;
    this.state.tdat = dts;
    this.state.avd = ddo.cache.avd;
    this.state.raa = [];
    this.state.aa = [];
    this.state.sz_val = "";

    this.state.kvitto = false;
    this.state.wnds = {};
    this.state.kvo = {};
    this.state.pdf = 0;
    this.state.pdf_content = null;

    //if(this.pos.deps && this.pos.deps.length) {
    //  this.state.avd = this.pos.deps[0].avd;
    //}
    this.fknitems = [
        {fkn: 'kvitton', text: 'UPPDATERA'},
        {fkn: 'rp_jr', text: 'SKRIV UT'}
    ];
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.kvitton();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 250;
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }
  dt_fdat(e) {
    //alert(JSON.stringify(e));
    var dts = format(e, "yyyy-MM-dd");
    this.setState({ fdat: dts });
  }
  dt_tdat(e) {
      //alert(JSON.stringify(e));
    var dts = format(e, "yyyy-MM-dd");
    this.setState({ tdat: dts });
  }
  oneday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_td.current.updateDt(this.state.fdat);
  }
  addday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_fd.current.updateAdd();
    this.dt_td.current.updateAdd();
  }
  subday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_fd.current.updateSub();
    this.dt_td.current.updateSub();
  }
  change_avd(e) {
    //let cdat = this.state.seldate;
    let avd = e.target.value;
    this.setState({avd: avd});
  }
  sz_keydown (e) {
    //e.preventDefault();
      const key = e.key;
      const keyCode = e.keyCode;

      if(keyCode == 13) {
          this.kvitto_sz();
      };
      if(keyCode == 27) {
          this.setState({sz_val: ""});
      };
  }
  sz_change (e) {
      const name = e.target.name;
      const value = e.target.value;
      this.setState({sz_val: value});
      //setState({[name]: value});
  }
kvitto_edit(row) {
    var wnds = {};
    wnds.kvitto = true;
    wnds.kvo = row;
    this.setState({ wnds: wnds });
    //this.setState({kvo: row, kvitto: true});
  }
  pdf_done (ret) {
    this.setState({loading: 0, pdf: 0, pdf_content: null });
  }
  kvitto_sz() {
    var self = this;
    var roa = self.state.roa;
    var sz = this.state.sz_val;

    //var raa = roa.filter(row => row.notanr.toUpperCase().includes(sz.toUpperCase()));
    var raa = roa.filter( row => (
        row.notanr.toUpperCase().includes(sz.toUpperCase()) ||
        ( row.fsg && row.fsg.toString().toUpperCase().includes(sz.toUpperCase())) ||
        ( row.bet && row.bet.toString().toUpperCase().includes(sz.toUpperCase())) ||
        ( row.bettyp && row.bettyp.toString().toUpperCase().includes(sz.toUpperCase())) ||
        ( row.bord && row.bord.toString().toUpperCase().includes(sz.toUpperCase()))
     ));
   self.setState({raa: raa });
 }

  kvitton() {
        var self = this;
    	var prm = {};
        prm.req = "pos.pos_eko.invh_list";
   		prm.avd = ddo.cache.avd;
   		prm.datum = this.state.fdat;
   		prm.fdat = this.state.fdat;
   		prm.tdat = this.state.tdat;
   		prm.favd = ddo.cache.avd;
   		prm.tavd = ddo.cache.avd;

       var dts = format(this.state.today, "yyyy-MM-dd");
       if(this.state.fdat == dts) prm.req = "pos.pos_eko.nota_list";

       //alert(JSON.stringify(prm));
      net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret));

            if(ret.ok != "000") {
                //alert(JSON.stringify(ret));
                return
            }
            if(ret.rca) {
                self.setState({ raa: ret.rca, roa: ret.rca });
            }
            //toastr.success("OK", "SAPI");
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
            //gda.wndError(txt);
        });
  }
  rp_jr() {
    var self = this;
    	//var datum = moment().subtract(14, "days");
    	var favd = ddo.cache.avd;
    	var tavd = ddo.cache.avd;

    	var prm = {};
     	//prm.req = "pos.pos_trs.trs_per";
     	//prm.req = "pos.pos_rp.rp_jr";
     	prm.req = "pos.pos_red.jr_prn_red";
   		prm.avd = ddo.cache.avd;
   		//prm.fdat = datum.format("YYYYMMDD");
   		//prm.tdat = datum.format("YYYYMMDD");
   		prm.favd = favd;
   		prm.tavd = tavd;
   		//if($scope.ftid)	prm.ftid = $scope.ftid.format("HHmm");
   		//if($scope.ttid)	prm.ttid = $scope.ttid.format("HHmm");

        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret.pdf));

            var o = {};
            //o.deps = ret.rco;
            //alert(JSON.stringify(o));
            //self.setState({tra: ret.rca });
            self.setState({pdf_content: ret.pdf });
            //toastr.success("OK", "SAPI");
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
            //gda.wndError(txt);
        });
  }
  kvitto_obal() {
    var self = this;
    var wnds = {};
    wnds.obal = true;
    wnds.dts = this.state.fdat;
    this.setState({ wnds: wnds });
}
sel_fkn(sel) {
    //alert(JSON.stringify(fkn));
    if(sel.fkn == "kvitton") {
        this.kvitton();
    }
}

  render() {
    return (
    <div className="web-p00 flex flex-col">
        <div className="">
            Kvitton
        </div>

    <div className="web-p01">
    <div className="flex-row">
        <div className="flex-row">
        <div className="dt-btn-01" onClick={() => this.subday() } ><FontAwesomeIcon icon="minus" size="1x" /></div>
              <Dt_Cal dts={this.state.fdat} rp="1" ref={this.dt_fd} getValue={(e)=> this.dt_fdat(e)} />
              <div className="dt-btn-01" onClick={() => this.oneday() } >1</div>
              <Dt_Cal dts={this.state.tdat} rp="1" ref={this.dt_td} getValue={(e)=> this.dt_tdat(e)} />
              <div className="dt-btn-01" onClick={() => this.addday() } ><FontAwesomeIcon icon="plus" size="1x" /></div>
        </div>
        <select className="form-control w250 hide" placeholder="Avdelning" onChange={(e) => this.change_avd(e)}>
            { this.props.store.pos.deps.map((row, key) =>
            <option key={key} value={row.avd}>{row.avd} - {row.text}</option>
            )}
        </select>
        <input className="txt sztxt" value={this.state.sz_val} placeholder="Ange söktext..." onChange={(e) => this.sz_change(e)} onKeyDown={(e) => this.sz_keydown(e)} />
        <div className="flex"></div>
        <button className="btn btn-hw-blue w150" onClick={() => this.kvitto_obal() } aria-label="System" >
            OBalans
        </button>
        <button className="btn btn-hw-blue w150" onClick={() => this.rp_jr() } aria-label="System" >
            Skriv ut
        </button>
        <button className="btn btn-hw-blue w150" onClick={() => this.kvitton() } aria-label="System" >
            Visa
        </button>
        <Dd_Sel items={ this.fknitems } cls="web-top-icon" getValue={(e)=> this.sel_fkn(e)} />
    </div>
    <div className="divider">
    </div>

    <div className="web-row">
        <table className="table table-bordered table-hover tb fixed_header">
            <thead>
            <tr>
                <th>Datum</th>
                <th>Notanr</th>
                <th>Avd</th>
                <th>Bordsnr</th>
                <th>Start-Slut</th>
                <th>Fsg</th>
                <th>Bet</th>
                <th>Diff</th>
                <th>Bettyp</th>
                <th>Info</th>
                <th>Rader</th>
                <th>Terminal</th>
                <th>Kassör</th>
                <th style={{width:"40px"}} ></th>
            </tr>
            </thead>
            <tbody style={ this.tb_style() }>
            { this.state.raa.map((row, key) =>
            <tr key={key} onDoubleClick={() => this.kvitto_edit(row) } >
                <td>{ row.datum }</td>
                <td>{ row.notanr }</td>
                <td className="text-center">{ row.avd }</td>
                <td className="text-center">{ row.bord }</td>
                <td className="text-center">{ row.start.cditid() }-{ row.slut.cditid() }</td>
                <td className="text-right">{ row.fsg.cdipris() }</td>
                <td className="text-right">{ row.bet.cdipris() }</td>
                <td className="text-right">{ row.diff.cdipris() }</td>
                <td>{ row.bettyp }</td>
                <td>{ row.info }</td>
                <td className="text-center">{ row.rader.length }</td>
                <td className="text-center">{ row.tnr }</td>
                <td className="text-center">{ row.cnr }</td>
                <td style={{width:"40px",cursor:"pointer"}} onClick={() => this.kvitto_edit(row) }><i className="fa fa-cog text-danger"></i></td>
            </tr>
            )}
            </tbody>
        </table>
    </div>

    </div>

    { this.state.wnds.kvitto ? <KvittoWnd wnds={this.state.wnds} ctx={this} store={this.store} backdrop={true}/> : null }
    { this.state.wnds.obal ? <KvittoObal wnds={this.state.wnds} ctx={this} store={this.store} backdrop={true}/> : null }
        <PdfRpDlg show={this.state.pdf} pdf={this.state.pdf_content} done={(ret) => this.pdf_done(ret)} backdrop={true}/>
    </div>
  );
    }
}
export default observer(Pos_Kvitton);
