import React, { useState, useEffect, useContext } from 'react';
import * as net from "../../../lib/net";
import Pms_Rvo from "../../rv/rvo";
import Dd_Sel from "../../../lib/ui/dd_sel";
import Td_Sel from "../../../lib/ui/td_sel";
import Rst_Nota from "./rst_nota";

class Paid extends React.Component {
  constructor(props, context) {
    super(props);

     this.selitems = [
        {fkn: 'setup', text: 'INSTÄLLNINGAR'},
        {fkn: 'upd', text: 'UPPDATERA'},
        {fkn: 'sys', text: 'SYSTEM'},
        {fkn: 'logout', text: 'LOGGA UT'}
    ];
     this.rowitems = [
        {fkn: 'open', text: 'ÖPPNA'},
        {fkn: 'open', text: 'ÖPPNA'}
    ];

    //alert('PBO CTX: ' + JSON.stringify(props.pbo));
   //this.webStore = props.store;
    this.state = {};
    this.state.ynq = [];
    this.state.wnds = {};
    this.state.tra = [];
    this.state.aa = [];
    if(props.wnds) {
        this.fkn_close = props.wnds.fkn_close;
        this.state.boknr = props.wnds.boknr;
        this.state.rvo = props.wnds.rvo;
        this.state.nta = props.wnds.nta;
        this.state.srow = props.wnds.srow;
    }
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      this.paid_trs();
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }

    wnd_close() {
      if(this.fkn_close) this.fkn_close(this.props.ctx);
      else this.props.ctx.setState({ wnds: {} });
	};
  tb_style() {
      var css = {};
      css.height = (window.innerHeight * 0.8) - 150;
      css.overflow = "auto";
      return css;
  }
  paid_trs() {
    var self = this;
    var prm = {};
    prm.req = "pms.charge.charges_paid";
    prm.boknr = this.state.rvo.gsb00.r0boknr;
    prm.glopnr = this.state.rvo.gsb00.r0glopnr;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        self.setState({ tra: ret.rca }, function () {
            self.syncTb("paidTable");
        });
        //self.syncTb("paidTable");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
    syncTb(tb){
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
        tdElements = tbel.getElementsByTagName('td');

        for (let i = 0; i < thElements.length; i++) {
            if(!tdElements[i]) continue;
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
            width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
  sel_row_fkn(row, e) {
    var self = this;
    //alert(JSON.stringify(fkn));
      if(e.fkn == "open") {
      }
  }
  sel_row(row) {
    var self = this;
      if(row.etr01.e0typ == "hot") {
          var text = row.etr01.e0text;
          var datum = row.etr01.e0datum;
          var avd = text.substr(0,2);
          var notanr = text.substr(2,7);
          //alert("REST: " + notanr);
        var wnds = {};
        wnds.rstnota = true;
        wnds.boknr = self.state.boknr;
        wnds.datum = datum;
        wnds.avd = avd;
        wnds.notanr = notanr;
        //wnds.fkn_close = self.wnds_close;
        self.setState({ wnds: wnds });
      }
  }

  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "";

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="m2_backdrop" /> : null }
        <div className="m2_dlg mx_80 flex-col" style={ this.dlg_style() }>
        <div className="flex-row " style={{padding:"5px",height:"50px", background: "#1a456b", color: "#fff", fontSize: "18px"}}>
                <div className="flex-row flex">
                    <div>BETALT { this.state.rumsnr }</div>
                </div>
            <div className="wnd-close-btn" onClick={() => this.wnd_close() }>X</div>
        </div>
        <div className="mx_content flex">

        <div className="web-row flex">

        <div id="paidTable" className="flex">
          <table className="table table-bordered table-hover tb" style={{marginBottom: "0px"}}>
            <thead>
            <tr>
                <th>Datum</th>
                <th>T</th>
                <th>Typ</th>
                <th>Text</th>
                <th>Antal</th>
                <th>Pris</th>
                <th>Summa</th>
                <th>Tecken</th>
                <th>Ptyp</th>
                <th>Paid</th>
                <th>Löpnr</th>
                <th></th>
            </tr>
            </thead>
        </table>
        <div style={ this.tb_style() }>
          <table className="table table-bordered table-hover tb">
            <tbody>
            { this.state.tra.map((row, key) =>
            <tr key={key} onDoubleClick={ () => this.sel_row(row) }>
                <td>{ row.etr01.e0datum.cdidate() }</td>
                <td>{ row.etr01.e0bastyp }</td>
                <td>{ row.etr01.e0typ }</td>
                <td>{ row.etr01.e0text.toswe() }</td>
                <td>{ row.etr01.e0antal.ztrim() }</td>
                <td>{ row.etr01.e0pris.cdipris() }</td>
                <td className="text-right">{ ((row.etr01.e0antal * 1) * (row.etr01.e0pris * 1)).cdipris() }</td>
                <td>{ row.etr01.e0tkn }</td>
                <td>{ row.etr01.e0ptyp }</td>
                <td>{ row.etr01.e0paid }</td>
                <td>{ row.e0lopnr }</td>
                <td>
                    <Td_Sel items={ this.rowitems } getValue={(e)=> this.sel_row_fkn(row, e)} />
                </td>
            </tr>
            )}
            </tbody>
        </table>
        </div>
        </div>

        </div>


        </div>
        <div className="mx_foot flex-row flex-space-between">
            <div className="flex-row"></div>
            <button className="btn btn-hw-red w250 ripple" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex-row"></div>
        </div>
      </div>
            { this.state.wnd_rvo ?
        <Pms_Rvo close={this.rvo_close} boknr={this.state.boknr} ctx={this} store={this.props.store} backdrop={true}/>
            : null }
    { this.state.wnds.rstnota ? <Rst_Nota wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
    </div>
	;
	return html;
  };
}

export default Paid;