import React, { useState, useEffect, useContext } from 'react';
import * as fkn from "../lib/fkn";
import SDOContext from '../sdo.js'
import {BrowserRouter, Route, Routes} from "react-router-dom";
//import ADOContext from "../ado";

import Red_Top from './com/red_top.js';
import Red_Mnu from './com/red_mnu.js';
import Red_Home from './home.js';
import Red_Dash from './dash.js';
import Red_Eko from './eko.js';
import Red_Pms from './pms.js';
import Red_Pos from './pos.js';
import Red_Bo from './bo.js';

import * as net from "../lib/net";
import {observer} from "mobx-react";
import Tiden from "../lib/ui/tiden";
import Trs_Home from "../trs/home";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function web01() {

    var html =
    <div className="web-content">
    </div>
    ;
    return html;
}

class Red extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, deps: [] };
    //context.posrend = this;
  }

  ddo() {
    alert("POS" + this.state.pgid );
    this.setState({ status: 1 });
  }

  render() {
    return (
    <div className="web-app">
    <div className="web-top">
        <Red_Top store={this.webStore} />
    </div>
    <div className="web-main">

    <div className="web-row" style={{ height:"100%" }}>

    { this.props.store.cfg.mnu_show ?
    <div className="web-side">
        <Red_Mnu store={this.webStore} />
    </div>
    : null }

    <div className="web-body">
        <Routes>
            <Route path="eko/*" element={ <Red_Eko store={this.webStore} />} />
            <Route path="pms/*" element={ <Red_Pms store={this.webStore} />} />
            <Route path="pos/*" element={ <Red_Pos store={this.webStore} />} />
            <Route path="bo/*" element={ <Red_Bo store={this.webStore} />} />
            <Route path="home" element={ <Red_Home store={this.webStore} />} />
            <Route path="*" element={ <Red_Dash store={this.webStore} />} />
        </Routes>
    </div>

    </div>

    </div>
    <div className="web-foot">
        <div className="web-foot-mnu" onClick={() => this.ddo() }>
            <FontAwesomeIcon icon="bars" size="1x"/>
        </div>
        <div className="flex flex-row">
            <div className="web-foot-btn" onClick={() => this.ddo() } >
                Redovisning
            </div>
            <div className="flex"></div>

            <div className="web-foot-btn" onClick={() => this.ddo() } >PMS</div>
            <div className="web-foot-btn" onClick={() => this.ddo() } >POS</div>
        </div>
        <div className="web-foot-icon">
          <FontAwesomeIcon icon="ellipsis-v" size="1x"/>
        </div>
        <div className="flex flex-row">
            <div className="web-foot-btn" onClick={() => this.ddo() }>DAG</div>

            <div className="flex"></div>
            <Tiden />
        </div>
    </div>

    </div>
  );
}
}
//Sys.contextType = ADOContext;

export default observer(Red);
