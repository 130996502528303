import React, { useState, useEffect, useContext } from 'react';
import {Route, Routes} from "react-router-dom";

import Eko_Home from './eko/home.js';
import Eko_Oms from './eko/oms.js';
import Eko_Saldo from './eko/saldo.js';
import Eko_GstSaldo from './eko/gstsaldo.js';
import Eko_Rvs from './eko/rvs.js';
import Eko_Hbok from './eko/hbok.js';
import Eko_Trs from './eko/trs.js';
import Eko_Invs from './eko/invs.js';
import {withRouter} from "../../lib/react/withRouter";
import sso from "../../sso";

class Red_Eko extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.state = { pgid: 11, deps: [] };
    //context.posrend = this;
  }
  fknGo(row) {
    //alert(row.href);
      //this.props.store.fkn = row;
      sso.seo.sfkn = row;
    this.props.navigate(row.url);
  }
    br_css(row) {
        var css = {};
        if(sso.seo.sfkn && (row.url == sso.seo.sfkn.url)) css.color = "#080";
        //var brc = false;
        //if(row.url == sbo.state.url) brc = true;
        return css;
    }

  render() {
      var urlbas = "/pms/hotback";
      var path = window.location.pathname;
      if(path.substr(-1) == "/") path = path.substring(0, path.length - 1);
      var op = path.split("/").pop();
      var po = {};
      po.oms = { "fid": "f01", "url": urlbas+"/eko/oms", "name": "Omsättning", "active": false };
      po.saldo = { "fid": "f01", "url": urlbas+"/eko/saldo", "name": "Saldo", "active": false };
      po.gstsaldo = { "fid": "f01", "url": urlbas+"/eko/gstsaldo", "name": "Gäst Saldo", "active": false };
      po.rvs = { "fid": "f01", "url": urlbas+"/eko/rvs", "name": "Bokningar", "active": false };
      po.hbok = { "fid": "f01", "url": urlbas+"/eko/hbok", "name": "Huvudbok", "active": false };
      po.trs = { "fid": "f01", "url": urlbas+"/eko/trs", "name": "Transaktioner", "active": false };
      po.invs = { "fid": "f01", "url": urlbas+"/eko/invs", "name": "Notor", "active": false };
      if(po[op]) {
          po[op].active = true;
          //this.props.store.fkn = po[op];
      }
      var pa = Object.values(po);

    return (
    <div className="web-app">
         { this.props.store.cfg.bread ?
        <div className="web-bread">
            <div className="breadcrumb">
            { pa.map((row, key) =>
                <div key={key} className="breadcrumb-item" style={this.br_css(row)} onClick={() => this.fknGo(row)}>{row.name}</div>
            )}
            <div className="flex"></div>
            <div className="cdigreen">{ this.props.store.fkn ? this.props.store.fkn.name : null }</div>
            </div>
        </div>
        : null }
        <Routes>
            <Route path="oms" element={ <Eko_Oms store={this.store} />} />
            <Route path="saldo" element={ <Eko_Saldo store={this.store} />} />
            <Route path="gstsaldo" element={ <Eko_GstSaldo store={this.store} />} />
            <Route path="rvs" element={ <Eko_Rvs store={this.store} />} />
            <Route path="hbok" element={ <Eko_Hbok store={this.store} />} />
            <Route path="trs" element={ <Eko_Trs store={this.store} />} />
            <Route path="invs" element={ <Eko_Invs store={this.store} />} />
            <Route path="*" element={ <Eko_Home store={this.store} />} />
        </Routes>
    </div>

  );
}
}
//Sys.contextType = ADOContext;

export default withRouter(Red_Eko);
