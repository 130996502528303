import React, { useState, useEffect, useContext } from 'react';
import * as net from "../../lib/net";
import Fr_Input from "../../lib/ui/fr_input";
import Td_Sel from "../../lib/ui/td_sel";
import {addDays, format} from "date-fns";
import Dt_Cal from "../../lib/ui/dt_cal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Pms_Bel_Wnd extends React.Component {
  constructor(props, context) {
    super(props);

    this.dt_fd = React.createRef();
    this.dt_td = React.createRef();

    this.state = {};
    this.state.ynq = [];
    this.state.rva = [];
    this.state.kro = {};
    this.state.aa = [];
    this.state.cols = {};
    this.state.firstday = {};
    this.state.caldata = [];
    this.state.rtdata = [];
    this.state.dio = {};
    this.state.loading = true;

    this.state.today = new Date();
    var dat = addDays(this.state.today, 15);
    this.state.fdat = format(this.state.today, "yyyy-MM-dd");
    this.state.tdat = format(dat, "yyyy-MM-dd");
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.bel_rt();
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }

    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    wnd_close = () => {
		this.props.ctx.setState({ wnds: {} });
	};
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  dt_style(dto) {
    var css = {};
    css.color = "#fff";
    return css;
  }
  dt_class(dto) {
      var cls = "";
      if(dto.wday == 5) cls = "weekend";
      if(dto.wday == 6) cls = "weekend2";
      return cls;
  }
  get_css(dto) {
      var cls = "";
      if(dto.wday == 5) cls = "weekend";
      if(dto.wday == 6) cls = "weekend2";
      return cls;
  }
  dt_check(dto) {
      //dto.wday == '0';
      return true;
  }
  dioenter(dto) {
      //dto.wday == '0';
      return;
  }
  dioleave(dto) {
      //dto.wday == '0';
      return;
  }
  set_di(dto) {
      //dto.wday == '0';
      return;
  }
  selov(dto) {
      //dto.wday == '0';
      return;
  }
  seldn(dto) {
      //dto.wday == '0';
      return;
  }
  selup(dto) {
      //dto.wday == '0';
      return;
  }

  bel_rt() {
    var self = this;
    //var fdat = format(this.state.fdate, "yyyy-MM-dd");
    //var dat = addDays(this.state.fdate, 15);
    //var tdat = format(dat, "yyyy-MM-dd");

    var prm = {};
    prm.req = "pms.cal.pms_cal_rt";
    prm.fdat = this.state.fdat;
    prm.tdat = this.state.tdat;
    //prm.avd = this.state.avd;

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        //alert(JSON.stringify(ret.rta));
        var rta = [];
        var keys = Object.keys(ret.rta);
        for(var key of keys) {
            var rto = ret.rta[key];
            //alert(JSON.stringify(key));

            var o = {};
            o.rt = key;
            o.dta = [];

            var dkeys = Object.keys(rto);
            for(var dkey of dkeys) {
                var dto = rto[dkey];
                //alert(JSON.stringify(dkey));
               o.dta.push(dto);
            };
            rta.push( o );
        };

        self.setState({caldata: ret.cal, rtdata: rta, loading: false });
        //self.syncCols("rtTable");
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e) {
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }

  dt_fdat(e) {
    //alert(JSON.stringify(e));
    var dts = format(e, "yyyy-MM-dd");
    this.setState({ fdat: dts });
  }
  dt_tdat(e) {
      //alert(JSON.stringify(e));
    var dts = format(e, "yyyy-MM-dd");
    this.setState({ tdat: dts });
  }
  oneday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_td.current.updateDt(this.state.fdat);
  }
  addday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_fd.current.updateAdd();
    this.dt_td.current.updateAdd();
  }
  subday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_fd.current.updateSub();
    this.dt_td.current.updateSub();
  }

  sel_rt(rt) {
  }

  render() {
    if(!this.props.show) return "";

    //alert(this.props.ctx.state.lro);

    var title = this.props.title;
    if(!title) title = "Sök";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="mx_backdrop" /> : null }
        <div className="mx_dlg mx_100 flex-col" style={ this.dlg_style() }>
        <div className="mx_content flex">

            <div className="mx_h01 flex-row" style={{background: "#1a456b", color: "#fff"}}>
                    <div className="flex-col flex">
                        <div>BELÄGGNING</div>
                    </div>
                    <div className="flex-col">
                        <div>UPPDATERA</div>
                    </div>
            </div>
            <div className="divider_orange"></div>
        <div className="flex-row">
              <div className="dt-btn-01" onClick={() => this.subday() } ><FontAwesomeIcon icon="minus" size="1x" /></div>
              <Dt_Cal dts={this.state.fdat} rp="1" ref={this.dt_fd} getValue={(e)=> this.dt_fdat(e)} />
              <div className="dt-btn-01" onClick={() => this.oneday() } >1</div>
              <Dt_Cal dts={this.state.tdat} rp="1" ref={this.dt_td} getValue={(e)=> this.dt_tdat(e)} />
              <div className="dt-btn-01" onClick={() => this.addday() } ><FontAwesomeIcon icon="plus" size="1x" /></div>
            <div className="flex"></div>
             <button className="btn btn-hw-red w250" onClick={() => this.bel_rt() } >
                UPPDATERA
            </button>
       </div>
            <div className="divider_orange"></div>

    <div className="web-col px-2">

        <table className="table table-bordered table-striped tb_pres">
            <thead>
            <tr>
                <th className='header'></th>
                { this.state.caldata.map((dto, key) =>
                    <th key={dto.dt} style={ this.dt_style(dto) } onMouseEnter={ () => this.dioenter(dto) }
                        onMouseLeave={ () => this.dioleave(dto) } onClick={ () => this.set_di(dto) }>{ dto.dt }</th>
                )}
            </tr>
            <tr>
                <th className='header'></th>
                { this.state.caldata.map((dto, key) =>
                    <th style={ this.dt_style(dto) } key={dto.dt}>{ dto.shortday }</th>
                )}
            </tr>
            </thead>
            <tbody>
            <tr>
                <td className='header'>Bokat</td>
                { this.state.caldata.map((dto, key) =>
                    <td key={dto.dt} className={ this.dt_class(dto)}>{ dto.borr }</td>
                )}
            </tr>
             <tr>
                <td className='header'>Gäster</td>
                { this.state.caldata.map((dto, key) =>
                    <td key={dto.dt} className={ this.dt_class(dto)}>{ dto.borg }</td>
                )}
            </tr>
            <tr>
                <td className='header'>Barn (0-4)/(5-15)</td>
                { this.state.caldata.map((dto, key) =>
                    <td key={dto.dt} className={ this.dt_class(dto)}>{ dto.antb0 }/{ dto.antb1 }</td>
                )}
            </tr>
            <tr>
                <td className='header'>Ankommande</td>
                { this.state.caldata.map((dto, key) =>
                    <td key={dto.dt} className={ this.dt_class(dto)}>{ dto.ankr }</td>
                )}
            </tr>
            <tr>
                <td className='header'>Lediga rum</td>
                { this.state.caldata.map((dto, key) =>
                    <td key={dto.dt} className={ this.dt_class(dto)}>{ dto.free }</td>
                )}
            </tr>
            <tr>
                <td className='header'>Stängda</td>
                { this.state.caldata.map((dto, key) =>
                    <td key={dto.dt} className={ this.dt_class(dto)}>{ dto.closed }</td>
                )}
            </tr>
            <tr>
                <td className='header'>Frukost</td>
                { this.state.caldata.map((dto, key) =>
                    <td key={dto.dt} className={ this.dt_class(dto)}>{ dto.breakfast }</td>
                )}
            </tr>
            <tr className='totrad'>
                <td className='header'>Belägging</td>
                { this.state.caldata.map((dto, key) =>
                    <td key={dto.dt}>{ dto.belag }</td>
                )}
            </tr>
           </tbody>
            <tbody className="">
                { this.state.rtdata.map((rto, key) =>
            <tr key={key}>
                <td onClick={ () => this.selrt() } className='header'>{rto.rt.trim()}</td>
                { rto.dta.map((dto, dkey) =>
                <td key={dkey} onClick={ () => this.selfdt(dto) } onMouseOver={ () => this.selov(dto) } onMouseDown={ () => this.seldn(dto) } onMouseUp={ () => this.selup(dto) }
                    className={ this.get_css(dto) }>{ dto.maxr - dto.borr - dto.closed}</td>
                )}
            </tr>
                )}
            <tr className='totrad'>
                <td className='header'>Totalt</td>
                { this.state.caldata.map((dto, key) =>
                <td key={key} >{dto.available - dto.nrr - dto.closed}</td>
                )}
            </tr>
            </tbody>

        </table>

    </div>

        </div>

        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex-row">
            <button className="btn btn-hw-green w250" onClick={(event) => this.sz_do(event) } >
                SKRIV UT
            </button>
            </div>
      </div>
    </div>
    </div>
	;
	return html;
  };
}

export default Pms_Bel_Wnd;