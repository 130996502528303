import React, { useState, useEffect, useContext } from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import { observer } from 'mobx-react'

import Gym_Top from './com/gym_top.js';
import Gym_Foot from './com/gym_foot.js';
import Gym_Mnu from './com/gym_mnu.js';

import Gym_Home from './home.js';
import Gym_Dash from './dash.js';
import Gym_Door from './door.js';
import Gym_Mbr from './mbr.js';

import * as srs from "../lib/srs";
import {withRouter} from "../lib/react/withRouter";
import Mbr_Home from "./mbr/home";

class Gym extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, mnu_show: true, deps: [] };
    //context.posrend = this;
  }

  fknGo(url) {
    //var sdo = useContext(SDO);
    //alert(fkn);
    //var fkn = row.fid;
    //setState({ row : row,  ma : [] });
    //ctx.fid = fkn;

    //props.history.push(row.url);
    this.props.navigate(url);
   };

  ddo() {
    alert("POS" + this.state.pgid );
    this.setState({ status: 1 });
  }
  udid_req() {
      var udid = "TT001920";
    var self = this;
	var req = {};
	req.req = "emv.print";
	req.udid = udid;
	req.fkn = "PRINT";

	var prm = {};
	req.prm = prm;

    //gda.wndLoading("Hämtar");
    srs.udid_req(req)
    .then(function(ret){
        //alert(JSON.stringify(ret.rco));
        //gda.wndClose();
        //var txt = JSON.stringify(data);
        //gda.wndSuccess(txt);
        //deps_list(ret.rco)

        alert(JSON.stringify(ret.rco));
        self.setState({ deps: ret.rco });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    })
    }
    settle() {
      var udid = "TT001920";
    var self = this;
	var req = {};
	req.req = "emv.settle";
	req.udid = udid;
	req.fkn = "SETTLE";

	var prm = {};
	req.prm = prm;

    //gda.wndLoading("Hämtar");
    srs.udid_req(req)
    .then(function(ret){
        //alert(JSON.stringify(ret.rco));
        //gda.wndClose();
        //var txt = JSON.stringify(data);
        //gda.wndSuccess(txt);
        //deps_list(ret.rco)

        alert(JSON.stringify(ret.rco));
        self.setState({ deps: ret.rco });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    })
    }

  render() {
    return (
    <div className="web-app">
    <div className="web-top">
        <Gym_Top store={this.webStore} />
    </div>
    <div className="web-main">

    <div className="web-row" style={{ height:"100%" }}>

    { this.props.store.cfg.mnu_show ?
    <div className="web-side">
        <Gym_Mnu store={this.webStore} />
    </div>
    : null }

    <div className="web-body">
        <Routes>
            <Route path="mbr/*" element={ <Gym_Mbr store={this.webStore} />} />
            <Route path="door" element={ <Gym_Door store={this.webStore} />} />
            <Route path="dash" element={ <Gym_Dash store={this.webStore} />} />
            <Route path="home" element={ <Gym_Home store={this.webStore} />} />
            <Route path="*" element={ <Gym_Door store={this.webStore} />} />
        </Routes>
    </div>

    </div>

    </div>

    <Gym_Foot store={this.webStore} />
    </div>
  );
}
}
//Sys.contextType = ADOContext;
export default withRouter(observer(Gym));
