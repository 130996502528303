import React, { useState, useEffect, useContext } from 'react';

import * as utils from "../../assets/utils";
import * as net from "../../lib/net";
import Lang from "../../lib/lang";

import Td_Sel from "../../lib/ui/td_sel";
import Ct_Add from "./fkn/ct_add";
import Ct_Edit from "./fkn/ct_edit";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Scr_Content extends React.Component {
  constructor(props, context) {
    super(props);

    //this.lang = {};
    this.webStore = props.store;
    this.state = { pgid: 11, tra: [] };
    this.state.today = new Date();
    this.state.avd = "01";
    this.state.aa = [];
    this.state.inhsel = 0;
      this.state.msg = {};
      this.state.rvo = {};

    this.state.cta = [];
    this.state.rvo = {};
    this.state.selrow = {};
    this.state.wnds = {};
    this.state.sortid = "";
    this.state.sortdir = 1;

    this.selitems = [
        {fkn: 'open', text: 'ÖPPNA'},
        {fkn: 'cancel', text: 'AVBOKA'}
    ];
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      this.dsi_ct();
  }

  body_style() {
    var css = {};
    css.height = window.innerHeight - 280;
    return css;
  }
  tb_style() {
    var css = {};
    css.height = window.innerHeight - 250;
    css.overflow = "auto";
    return css;
  }
  th_style(id) {
      var css = {};
      css.cursor = "pointer";
      if(id == this.state.sortid) {
          if(this.state.sortdir) css.color = "#800";
          else css.color = "#080";
      }
      return css;
  }
  row_style(row) {
    var srow = this.state.selrow;

    var css = {};
    if(srow == row) {
        css.background = "#080";
        css.color = "#fff";
    }
    return css;
  }
  onUnitChange(e) {
    this.setState({unit: e.value});
  }

    ct_add() {
        var self = this;

        var wnds = {};
        wnds.ctadd = true;
        //wnds.cto = row;
        wnds.cb = self.cb_ct;
        //alert(JSON.stringify(wnds));
        self.setState({ wnds: wnds });
    }
    ct_edit(row) {
        var self = this;

        var wnds = {};
        wnds.ctedit = true;
        wnds.cto = row;
        //wnds.cb = self.cb_scr;
        //alert(JSON.stringify(wnds));
        self.setState({ wnds: wnds });
    }
    cb_ct(ctx) {
        ctx.setState({ wnds: {} });
        ctx.dsi_ct();
    };

    dsi_ct() {
    var self = this;
    var prm = {};
    prm.req = "dsi.dsi_cont.ct_list";

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        self.setState({ cta: ret.rca }, function() {
            self.syncTb("scrltTable");
        });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
    syncTb(tb) {
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
        tdElements = tbel.getElementsByTagName('td');
        if(tdElements.length < 1 ) return;

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
            width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
  sel_row(row) {
    var srow = this.state.selrow;
    if(srow == row) {
      this.setState({selrow: {}});
    }
    else this.setState({selrow: row});
  }
  sel_row_fkn(row, sel) {
      if(sel.fkn == "open") {
          this.sel_gst(row);
      }
  }
    gst_close(ctx) {
        ctx.setState({ wnds: {} });
    };
   tb_sort(typ) {
      var self = this;
      //var rvs = self.state.rvs;
      var rva = self.state.rva;
      var sortid = this.state.sortid;
      var sortdir = this.state.sortdir;

      if(sortid != typ) sortdir = 0;
      else {
        if(sortdir) sortdir = 0;
        else sortdir = 1;
      }

      if(typ == "ank") {
        if(sortdir) rva.sort((a,b) => (a.gsb00.r0ankdatum < b.gsb00.r0ankdatum) ? 1 : ((b.gsb00.r0ankdatum < a.gsb00.r0ankdatum) ? -1 : 0));
        else rva.sort((a,b) => (a.gsb00.r0ankdatum > b.gsb00.r0ankdatum) ? 1 : ((b.gsb00.r0ankdatum > a.gsb00.r0ankdatum) ? -1 : 0));
      }
      self.setState({ rva: rva, sortid: typ, sortdir: sortdir });
   }
   th_icon(typ) {
      var cls = "";
      var sortid = this.state.sortid;
      var sortdir = this.state.sortdir;
      if(sortid == typ)
      {
        if(sortdir) cls = "fa fa-caret-up";
        else cls = "fa fa-caret-down";
      }
      return cls;
    }

  render() {
    return (
    <div className="web-bas">

    <div className="flex-row">
        <div className="flex"></div>
        <button className="btn btn-hw-blue w200" onClick={() => this.ct_add() } aria-label="System" >
            LÄGG TILL
        </button>
        <button className="btn btn-hw-blue w200" onClick={() => this.dsi_ct() } aria-label="System" >
            UPPDATERA
        </button>
    </div>
    <div className="divider_green"></div>

    <div className="web-row">

        <div id="scrltTable" className="flex">
          <table className="table table-bordered table-hover" style={{marginBottom: "0px"}}>
            <thead>
            <tr>
                <th style={ this.th_style("typ") } onClick={() => this.tb_sort("typ") }><Lang>Typ <i className={ this.th_icon("typ") }></i></Lang></th>
                <th style={ this.th_style("id") } onClick={() => this.tb_sort("id") }><Lang>ID</Lang> <i className={ this.th_icon("id") }></i></th>
                <th style={ this.th_style("text") } onClick={() => this.tb_sort("text") }><Lang>Text</Lang> <i className={ this.th_icon("text") }></i></th>
                <th style={ this.th_style("lopnr") } onClick={() => this.tb_sort("lopnr") }><Lang>Url</Lang> <i className={ this.th_icon("lopnr") }></i></th>
                <th style={ this.th_style("cttyp") } onClick={() => this.tb_sort("cttyp") }><Lang>Typ</Lang> <i className={ this.th_icon("cttyp") }></i></th>
                <th></th>
            </tr>
            </thead>
        </table>
        <div style={ this.tb_style() }>
          <table className="table table-bordered table-hover">
            <tbody>
            { this.state.cta.map((row, key) =>
            <tr key={key} style={ this.row_style(row) } onClick={() => this.sel_row(row)} onDoubleClick={ () => this.ct_edit(row) }>
                <td>{ row.typ }</td>
                <td>{ row.ctid }</td>
                <td>{ utils.toLang( row.text ) }</td>
                <td className="text-left">{ row.url }</td>
                <td>{ row.cttyp }</td>
                <td>
                    <Td_Sel items={ this.selitems } getValue={(e)=> this.sel_row_fkn(row, e)} />
                </td>
            </tr>
            )}
            </tbody>
        </table>
        </div>
        </div>
    </div>
        { this.state.wnds.ctadd ? <Ct_Add wnds={this.state.wnds} store={this.store} ctx={this} /> : null}
        { this.state.wnds.ctedit ? <Ct_Edit wnds={this.state.wnds} store={this.store} ctx={this} /> : null}

    </div>
  );
    }
}
export default Scr_Content;
