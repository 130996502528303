import React, { useState, useEffect, useContext } from 'react';
import { observer } from 'mobx-react';

import * as net from "../../lib/net";
import sso from "../../sso";
import ddo from '../../ddo.js'
import Wnd_Ok from "../../lib/ui/wnd_ok";

class Ecom_SysSel extends React.Component {
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.seo = props.store.seo;

    this.state = {};
      this.state.cst = [
          //{ id:"0", typ:"0", text: "INGET CENTRALSYSTEM"},
          { id:"1", typ:"1", text: "ADMINISTRATIV / USER"},
          { id:"3", typ:"3", text: "ADMINISTRATIV / MANAGER"},
          { id:"5", typ:"5", text: "EKONOMI / ÖVERSIKT"}
      ];
    this.state.csa = [];
    this.state.msg = {};
   }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      this.set_cs();
  }

    set_cs() {
        var self = this;
      //alert(JSON.stringify(sso.seo));

        if(sso.seo.csid == "1000") {
            var csa = [
                //{ id:"0", typ:"0", text: "INGET CENTRALSYSTEM"},
                { id:"1", sid:"S0003201", text: "SYSTEM 1"},
                { id:"3", sid:"S0003202", text: "SYSTEM 2"},
                { id:"5", sid:"S0003203", text: "SYSTEM 3"}
            ];
            this.setState({ csa: csa });
        }
        //if(sso.seo.csid == "0003") {
        if(true) {
            var csa = [
                //{ id:"0", typ:"0", text: "INGET CENTRALSYSTEM"},
                { id:"1", sid:"S0003201", text: "FOODLE NYKOPING"},
                { id:"2", sid:"S0003202", text: "FOODLE DANDERYD"},
                { id:"3", sid:"S0003203", text: "FOODLE SICKLA"},
                { id:"4", sid:"S0003204", text: "FOODLE HUDDINGE"}
            ];
            this.setState({ csa: csa }, function () {
            });
        }
    }
    pos_init() {
        var self = this;

        var pos = {};
        pos.deps = [];
        pos.vgrps = [];
        pos.plus = [];
        self.props.store.pos = pos;

        ddo.cache.deps = {};
        ddo.cache.vgrps = [];
        ddo.cache.mkods = {};

        var req = {};
        req.req = "pos.pos_fkn.pos_init";

        net.sio_req(req)
            .then(function(ret){
                //alert(JSON.stringify(ret));

                if(!self.props.store.pos) self.props.store.pos = {};
                self.props.store.pos.deps = ret.rco.deps;
                self.props.store.pos.vgrps = ret.rco.vgrps;
                if(ret.rco.depo) ddo.cache.deps = ret.rco.depo;
                if(ret.rco.vgrps) ddo.cache.vgrps = ret.rco.vgrps;
                if(ret.rco.mkods) ddo.cache.mkods = ret.rco.mkods;

                var avd = Object.keys(ddo.cache.deps).sort()[0];
                if((avd * 1) > (ddo.cache.avd * 1)) {
                    ddo.cache.avd = avd;
                }
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
                //alert(JSON.stringify(txt));
            })
    }

    dlg_style() {
      var css = {};
       css.zIndex = "19999";
     //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    tab_style(tabid) {
      var css = {};
      css.display = "none";
      if(this.state.tabval === tabid) css.display = "block";
      return css;
    }
    wnd_close() {
		//this.props.ctx.setState({ wnds: {} });
		this.props.cb();
	};
    tb_style() {
        var css = {};
        css.height = window.innerHeight - 275;
        return css;
    }
    list_style() {
        var css = {};
        css.height = (window.innerHeight * 0.7) - 175;
        css.color = "#000";
        css.padding = "0px 5px";
        css.overflow = "auto";
        return css;
    }
    cssCols (cid) {
        var css = {};
        if(this.state.cols[cid]) css.width = this.state.cols[cid].toString() + "px";
        //if(cid == "c01") alert(JSON.stringify(this.state.cols[cid]));
        return css;
    }
  syncCols (tb) {

    if (!document.getElementById(tb)) return;

    var cols = {};
    var ix = document.getElementById(tb).rows.length - 1;
    var ic = document.getElementById(tb).rows[0].cells.length;
    var ci = 0;
    while(ci < ic) {
        var cxi = ci + 1;
        var col = "c" + cxi.toString().padStart(2, "0");
        cols[col] = document.getElementById(tb).rows[ix].cells[ci].clientWidth + 1;
        ci = ci + 1;
    }
    this.setState({cols: cols });
    //alert(JSON.stringify(this.state.cols));
    //this.forceUpdate();
  };
  cs_upd() {
    var self = this;
    self.ccs();
  };
  cs_active(row) {
    var self = this;
    self.ccs_sid(row.c0sid);
  };
  cs_typ(e) {
    var self = this;
    const value = e.target.value;
    self.seo.cs = value;
  };

  ccs_sid(sid) {
    var self = this;
    var prm = {};
    prm.req = "ccs:set_unit";
    prm.sid = sid;

    net.ccs_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        self.seo.sid = sid;
        //self.setState({cca: ret.rca });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

  ccs() {
    var self = this;
    var prm = {};
    prm.req = "cs.cs_units.csa";
    prm.avd = this.state.avd;
    //prm.fplu = datum;
    //prm.tplu = datum;

    //gda.wndLoading("Hämtar");
    net.ccs_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        self.setState({csa: ret.rca });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }
    row_sel(row) {
        var self = this;

        sso.sdo.stxt = row.text;
        sso.sdo.sid = row.sid;

        var xss = JSON.stringify(sso);
        sessionStorage.setItem("svp_sso", xss);
        self.pos_init();
    }

  render() {
    if(!this.props.wnds.syssel) return "";

    var title = this.props.title;
    if(!title) title = "Titel";

	var html =
        <div className="mx_base">
        { this.props.wnds.backdrop ? <div className="mx_backdrop" /> : null }
        <div className="mx_dlg mx_70 flex-col" style={ this.dlg_style() }>
        <div className="mx_content flex">

            <div className="flex-row" style={{color: "#000", padding:"5px",height:"35px"}}>
                    <div className="flex-col flex">
                        <div>SYSTEM VAL</div>
                    </div>
                    <div className="flex-col" onClick={() => this.cs_upd() }  >
                        <div>UPPDATERA</div>
                    </div>
            </div>

            <div className="divider_orange"></div>

            <div className="web-row text-center" style={{color: "#000", padding:"5px",height:"35px"}}>
                AKTIVT: { sso.sdo.sid } / { sso.sdo.stxt }
            </div>

            <div className="divider_green"></div>

            <div className="web-row px-1">
              <div className="cdi_list flex-column flex" style={this.list_style()}>
                {this.state.csa.map((row, key) =>
                <div className="cdi_list_row flex" onClick={() => this.row_sel(row) }>
                  <div className="">
                    <h5 className="mb-1">{ row.text }</h5>
                    <small>{ row.desc }</small>
                  </div>
                  <p className="mb-1">SID: { row.sid }</p>
                  <small>{ row.c0typ }</small>
                </div>
            )}
            </div>
            </div>

        </div>

        <div className="mx_foot flex-row flex-space-between">
            <div className="flex-row">
            </div>
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex-row">
            </div>
        </div>
      </div>
        <Wnd_Ok msg={this.state.msg} ctx={this} />
    </div>
	;
	return html;
  };
}

export default observer(Ecom_SysSel);