import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";
import {withRouter} from "../../lib/react/withRouter";
import sso from "../../sso";

class Pms_Fo extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = {};
    this.state.seldate = new Date();
    this.state.avd = "01";
    this.state.paa = [];
    this.state.aa = [];

    this.state.mna = [];
    this.state.mna.push({ mnid: "mn01", pos: "c1", text: "ANKOMMANDE", desc: "Ankommande gäster valt datum", link: "/pms/op/ank" });
    this.state.mna.push({ mnid: "mn02", pos: "c1", text: "CHECKIN", desc: "Gäster kvar att checkain", link: "/pms/op/ci" });
    this.state.mna.push({ mnid: "mn03", pos: "c1", text: "BOENDE", desc: "Boende gäster", link: "/pms/op/inh" });
    this.state.mna.push({ mnid: "mn04", pos: "c1", text: "AVRESANDE", desc: "Avresande gäster idag", link: "/pms/op/avr" });
    this.state.mna.push({ mnid: "mn05", pos: "c2", text: "DELUTCHECKADE", desc: "Gäster som ligger delutcheckade", link: "/pms/op/pco" });
    this.state.mna.push({ mnid: "mn06", pos: "c2", text: "UTCHECKADE", desc: "Utcheckade gäster", link: "/pms/op/co" });
    this.state.mna.push({ mnid: "mn07", pos: "c2", text: "NO SHOW", desc: "Gäster som ej ankommit", link: "/pms/op/noshow" });
  }

  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 480;
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }
    mnc(pos) {
        var mna = [];
        for(var row of this.state.mna) {
            if(row.pos == pos) mna.push( row );
        }
        return mna;
    }

  fknGo(url) {
    //alert(row.href);
    var row = {};
    row.url = url;
    sso.seo.sfkn = row;
    this.props.navigate(url);
  }

  render() {
    return (
    <div className="web-app">
        <div className="web-pg py-4 flex">
            <h3>Drift</h3>
            <p>Drift funktioner</p>

            <div className="divider_green"></div>

            <div className="web-row">
                <div className="flex-col flex">
                    { this.mnc("c1").map((row, key) =>
                        <div key={key} className="web-bx" onClick={() => this.fknGo(row.link) }>
                            <div>{ row.text }</div>
                            <div className="flex-row">
                                <div className="flex">{ row.desc }</div>
                                <div className="web-link">Välj</div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="flex-col flex">
                    { this.mnc("c2").map((row, key) =>
                        <div key={key} className="web-bx" onClick={() => this.fknGo(row.link) }>
                            <div>{ row.text }</div>
                            <div className="flex-row">
                                <div className="flex">{ row.desc }</div>
                                <div className="web-link">Välj</div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>

    </div>
  );
    }
}
export default withRouter(Pms_Fo);
