import React, { useState, useEffect, useContext } from 'react';
import {format, subDays} from "date-fns";

import * as net from "../../../lib/net";
import Fr_Input from "../../../lib/ui/fr_input";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class FT_Plu extends React.Component {
  constructor(props, context) {
    super(props);

    this.pos = props.store.pos;
    this.state = {};
    this.state.today = new Date();
    this.state.lsa = [];
    this.state.lso = {};
    //this.state.lro = props.lro;
    this.state.tya = [
                {id: '01', text: 'Summerad'},
                {id: '02', text: 'Dag för dag'}
            ];
    this.state.cols = {};
    this.state.lrwrk = false;
    //context.posrend = this;
      this.state.title = "FT PLU";
      this.state.fkntext = "KÖR";
      this.state.favd = "01";
    this.state.tavd = "01";
    this.state.fplu = "";
    this.state.tplu = "";
    if(props.wnds) {
        if(props.wnds.title) this.state.title = props.wnds.title;
        if(props.wnds.fkntext) this.state.fkntext = props.wnds.fkntext;
        if(props.wnds.favd) this.state.favd = props.wnds.favd;
        if(props.wnds.fplu) this.state.fplu = props.wnds.fplu;
        if(props.wnds.tavd) this.state.tavd = props.wnds.tavd;
        if(props.wnds.tplu) this.state.tplu = props.wnds.tplu;
    }
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.dlg_init();
  }

  dlg_init() {
      var dat = this.state.today;
      dat = subDays(dat, 1);
      var dts = format(dat, "yyyy-MM-dd");
      var state = this.state;
      state.fdat = dts;
      state.tdat = dts;
      state.favd = "01";
      state.tavd = "01";
      state.loading = 0;
    this.setState(state);
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    tab_style(tabid) {
      var css = {};
      css.display = "none";
      if(this.state.tabval === tabid) css.display = "block";
      return css;
    }
    tab_head(tabid) {
      var css = {};
      css.background = "#fff";
      css.color = "#000";
      if(this.state.tabval === tabid) {
          css.background = "#080";
          css.color = "#fff";
      }
      return css;
    }
  change_favd(e) {
    //let cdat = this.state.seldate;
    let avd = e.target.value;
    this.setState({favd: avd});
  }
  change_tavd(e) {
    //let cdat = this.state.seldate;
    let avd = e.target.value;
    this.setState({tavd: avd});
  }


  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  body_style() {
      var css = {};
      css.display = "";
      if( this.state.loading == 1 ) css.display = "none";
      return css;
  }
    val_save (name, value) {
        //alert(JSON.stringify(name));
        //alert(JSON.stringify(value));
        var state = this.state;
        state[name] = value;
        this.setState(state);
        //setState({[name]: value});
    }
    wnd_close = () => {
        this.props.ctx.setState({ wnds: {} });
    };
    fkn_do() {
        var self = this;
        if(self.props.wnds.cb) {
            var rsp = {};
            rsp.ok = "000";
            rsp.tavd = this.state.tavd;
            rsp.tplu = this.state.tplu;
            self.props.wnds.cb(self.props.ctx, rsp);
        }
        else self.props.ctx.setState({ wnds: {} });
    };

  render() {

    var title = this.props.title;
    if(!title) title = "MÅNADSRAPPORT";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="m2_backdrop" /> : null }
        <div className="m2_dlg flex-col" style={ this.dlg_style() }>
        <div className="mx_head mx_bg flex-row flex-space-between">
            <div className="flex-col flex">
                <div>{ this.state.title }</div>
            </div>
            <div className="white-icon-close" onClick={() => this.handleClose() }>X</div>
        </div>
        { this.state.loading ? <div className="mx_content flex align-items-center justify-content-center"><FontAwesomeIcon icon="spinner" spin size="3x" fixed /></div> :
        null }
        <div className="mx_content flex" style={ this.body_style() }>
            <div className="flex-row px-2">
                    <div className="flex-col flex">
                        <div>Start</div>
                    </div>
                    <div className="flex-col flex">
                        <div></div>
                    </div>
                    <div className="flex-col w50">
                        <div>Ändra</div>
                    </div>
            </div>
            <div className="divider_orange"></div>

        <div className="mx_body flex">

            <div className="flex-row">
                <div className="flex-col">
                    <span>Från Avd</span>
                    <select className="form-control input-rp-01 w250" placeholder="Från avdelning" onChange={(e) => this.change_favd(e)}>
                        { this.pos.deps.map((row, key) =>
                            <option key={key} value={row.avd}>{row.avd} - {row.text.toswe()}</option>
                        )}
                    </select>
                </div>
                <div className="flex-col">
                    <span>Till Avd</span>
                    <select className="form-control input-rp-01 w250" placeholder="Till avdelning" onChange={(e) => this.change_tavd(e)}>
                        { this.pos.deps.map((row, key) =>
                            <option key={key} value={row.avd}>{row.avd} - {row.text.toswe()}</option>
                        )}
                    </select>
                </div>
            </div>
            <div className="flex-row">
                <div className="flex-col">
                    <Fr_Input col label="Från Plu" className="text-left w250" text={this.state.fplu} getValue={(e)=> this.val_save("fplu", e) } />
                </div>
                <div className="flex-col">
                    <Fr_Input col label="Till Plu" className="text-left w250" text={this.state.tplu} getValue={(e)=> this.val_save("tplu", e) } />
                </div>
            </div>

        </div>

        </div>


        <div className="mx_foot">
        <div className="flex-row">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex"></div>
            <button className="btn btn-hw-green w250" onClick={() => this.fkn_do() } >
                { this.state.fkntext }
            </button>
        </div>
        </div>
      </div>

    </div>
	;
	return html;
  };
}

export default FT_Plu;