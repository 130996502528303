import React, { useState, useEffect, useContext } from 'react';
import {withRouter} from "../../lib/react/withRouter";
import sso from "../../sso";
import ddo from '../../ddo.js'

class Reg_Home extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.pos = props.store.pos;
    this.state = { pgid: 11, tra: [] };
    this.state.seldate = new Date();
    this.state.avd = ddo.cache.avd;

      this.state.mna = [];
      this.state.mna.push({ mnid: "mn01", pos: "c1", text: "KONTOPLAN", desc: "Definition av konton", link: "/pos/reg/konto" });
      this.state.mna.push({ mnid: "mn02", pos: "c1", text: "AVDELNINGAR", desc: "Avdelningsregister", link: "/pos/reg/deps" });
      this.state.mna.push({ mnid: "mn03", pos: "c1", text: "VARUGRUPPER", desc: "Varugruppsregister", link: "/pos/reg/vgrps" });
      this.state.mna.push({ mnid: "mn04", pos: "c1", text: "BETALMEDEL", desc: "Betalmedelsregister", link: "/pos/reg/pays" });
      this.state.mna.push({ mnid: "mn05", pos: "c1", text: "MOMS", desc: "Momsregister", link: "/pos/reg/tax" });
      this.state.mna.push({ mnid: "mn06", pos: "c2", text: "VALUTA", desc: "Definierad valuta med inlagd kurs", link: "/pos/reg/cur" });
      this.state.mna.push({ mnid: "mn07", pos: "c2", text: "KASSÖRER", desc: "Kassörsregister med koppling", link: "/pos/reg/cas" });
      this.state.mna.push({ mnid: "mn08", pos: "c2", text: "TERMINAL DEFINITION", desc: "Definition av terminaler", link: "/pos/reg/tdef" });
      this.state.mna.push({ mnid: "mn09", pos: "c2", text: "TERMINAL KONFIG", desc: "Uppsättning av terminaler", link: "/pos/reg/tcfg" });
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

    mnc(pos) {
        var mna = [];
        for(var row of this.state.mna) {
            if(row.pos == pos) mna.push( row );
        }
        return mna;
    }
  fknGo(url) {
    //alert(row.href);
    var row = {};
    row.url = url;
    sso.seo.sfkn = row;
    this.props.navigate(url);
  }

  render() {
    return (
    <div className="web-app">
        <div className="web-pg py-4 flex">
            <h3>Register</h3>
            <p>Register funktioner</p>

            <div className="divider_green"></div>

            <div className="web-row">
                <div className="flex-col flex">
                    { this.mnc("c1").map((row, key) =>
                        <div key={key} className="web-bx" onClick={() => this.fknGo(row.link) }>
                            <div>{ row.text }</div>
                            <div className="flex-row">
                                <div className="flex">{ row.desc }</div>
                                <div className="web-link">Välj</div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="flex-col flex">
                    { this.mnc("c2").map((row, key) =>
                        <div key={key} className="web-bx" onClick={() => this.fknGo(row.link) }>
                            <div>{ row.text }</div>
                            <div className="flex-row">
                                <div className="flex">{ row.desc }</div>
                                <div className="web-link">Välj</div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    </div>
  );
    }
}
export default withRouter(Reg_Home);
