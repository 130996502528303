import React, { useState, useEffect, useContext } from 'react';
import {format} from "date-fns";

import * as utils from "../../../assets/utils";
import * as net from "../../../lib/net";
import Dd_Sel from "../../../lib/ui/dd_sel";
import Dt_Cal from "../../../lib/ui/dt_cal";

import Pms_Rvo from "../../rv/rvo";
import Fr_Input from "../../../lib/ui/fr_input";

class Krubb_Sell extends React.Component {
  constructor(props, context) {
    super(props);

     this.selitems = [
        {fkn: 'setup', text: 'INSTÄLLNINGAR'},
        {fkn: 'upd', text: 'UPPDATERA'},
        {fkn: 'sys', text: 'SYSTEM'},
        {fkn: 'logout', text: 'LOGGA UT'}
    ];

    this.store = props.store;
    this.state = {};
    this.state.ynq = [];
    this.state.wnds = {};

    this.state.grp = "";
    this.state.bta = [];
    this.state.gaa = [];
    this.state.paa = [];
    this.state.aa = [];

    this.state.boknr = "";
    this.state.glopnr = "00001";
    this.state.gso = {};
    this.state.tro = {};
    if(props.wnds) {
        this.state.boknr = props.wnds.boknr;
        //this.state.glopnr = props.wnds.glopnr;
    }

    this.state.today = new Date();
    var dts = format(this.state.today, "yyyy-MM-dd");
    this.state.dts = dts;
    this.state.ok = false;
    this.state.error = false;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.get_gst();

    this.btn_grps();
    var grp = "01";
    this.btn_grp(grp);
  }
  btn_grps() {
    var grps = {};
    var gaa = [];

    gaa.push({ grp: "01", text: "MINIBAR" });
    gaa.push({ grp: "02", text: "VIP BESTÄLLNING" });
    gaa.push({ grp: "03", text: "DORSIA PRODUKTER" });
    gaa.push({ grp: "04", text: "LILY & ROSE" });
    gaa.push({ grp: "05", text: "PRODUKTER" });
    gaa.push({ grp: "06", text: "GLAS & BESTICK" });
    gaa.push({ grp: "07", text: "HOTELL" });
    gaa.push({ grp: "08", text: "PRESENTKORT" });
    gaa.push({ grp: "09", text: "ÖPPNA PRODUKTER" });

    this.setState({gaa: gaa});
}

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    body_style() {
      var css = {};
      if(this.state.ok) {
          css.background = "#080";
          css.color = "#fff";
      }
      if(this.state.error) {
          css.background = "#800";
          css.color = "#fff";
      }
      css.height = window.innerHeight * 0.8 - 120;
      return css;
    }

    wnd_close() {
      if(this.props.wnds.cb) {
        var rsp = {};
        rsp.ok = "999";
          this.props.wnds.cb(this.props.ctx, rsp);
      }
      else this.props.ctx.setState({ wnds: {} });
	};
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  btn_style(btn) {
    var css = {};
    css.cursor = "pointer";
    //if(btn.f0kod) css.background = this.cdi_rgb(btn.f0kod);
    //if(btn.f0bkod) css.color = this.cdi_rgb(btn.f0bkod);
    return css;
  }
  grp_style(grp) {
    var css = {};
    if(grp == this.state.grp) {
        css.background = "#080";
        css.color = "#fff";
    }
    return css;
  }

    val_save (name, value) {
      var tro = this.state.tro;
      //var na = name.split(".");
      //gso[na[0]][na[1]] = value;
      tro[name] = value;
      this.setState({tro: tro});
      //setState({[name]: value});
    }

    btn_grp(grp) {
      var self = this;
  
      var prm = {};
      prm.req = "pms.tr.btns";
      prm.typ = "T";
      prm.grp = grp;
  
      net.sio_req(prm)
      .then(function(ret){
          //alert(JSON.stringify(ret));
  
          self.setState({grp: grp, bta: ret.rca });
      })
      .catch(function(e){
          var txt = JSON.stringify(e);
      });
    }
    get_gst() {
      var self = this;
  
      var tro = this.state.tro;
  
      var prm = {};
      prm.req = "pms.gst.gsb";
      prm.boknr = this.state.boknr;
      prm.glopnr = tro.glopnr;
      //alert(JSON.stringify(prm));

      net.sio_req(prm)
      .then(function(ret){
          //alert(JSON.stringify(ret.rco));
  
          self.setState({gso: ret.rco });
        })
      .catch(function(e){
          var txt = JSON.stringify(e);
      });
    }
  
  sell_tr() {
    var self = this;

    var tro = this.state.tro;
    //alert(JSON.stringify(tro));
    var tkn = "+";
    if(tro.pris.includes("-")) {
        tro.pris = tro.pris.replace(/\-/g,"");
        tkn = "-";
    }
    if(tro.tkn.includes("-")) tkn = "-";

    if(tro.pris.includes(".")) tro.pris = tro.pris.replace(/\./g,"");
    else tro.pris = tro.pris + "00";
    //alert(JSON.stringify(this.state.rvo.gsb00));
    //alert(JSON.stringify(this.state.gso));
    //return;

    var prm = {};
    prm.req = "pms.gst.gsb";
    prm.boknr = self.state.boknr;
    prm.glopnr = tro.glopnr;

    net.sio_req(prm)
    .then(function(ret) {
      //alert(JSON.stringify(ret));
      var gso = ret.rco;

      var prm = {};
      prm.req = "pms.sell.charge_tr";
      prm.boknr = self.state.boknr;
      prm.glopnr = tro.glopnr;
      prm.trkod = tro.id;
      prm.text = tro.text;
      prm.antal = tro.antal;
      prm.pris = tro.pris;
      prm.tkn = tkn;
      prm.moms = tro.moms;
      prm.datum = tro.dts;

      //alert(JSON.stringify(prm));
      prm.gsb00 = gso;

      return net.sio_req(prm);
    })
    .then(function(ret){
      //alert(JSON.stringify(ret.rco));

      if(self.props.wnds.cb) {
      var rsp = {};
      rsp.ok = "000";
        self.props.wnds.cb(self.props.ctx, rsp);
    }
    else self.props.ctx.setState({ wnds: {} });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
    
  btn_sell(btn) {
    var self = this;
    var tro = {};
      
    tro.id = btn.e1typ.trim();
    tro.text = btn.e1text.trim();
    tro.antal = "1";
    tro.pris = utils.toPris(btn.e1pris);
    tro.moms = btn.e1moms.trim();
    tro.tkn = "+";
    if(btn.e1debcre == "0") tro.tkn = "-";
    
    if(btn.e1bastyp == "T") {
    }
    if(btn.e1bastyp == "B") {
    }

    self.setState({ tro: tro });
  }

  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "";

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="m2_backdrop" /> : null }
        <div className="m2_dlg mx_70h flex-col" style={ this.dlg_style() }>
        <div className="flex-row " style={{padding:"0px",height:"50px",lineHeight:"50px", background: "#1a456b", color: "#fff"}}>
                <div className="flex-row flex" style={{padding:"0px 5px", fontSize: "22px"}}>
                    <div>Ny Transaktion: { this.state.boknr }</div>
                </div>
            <div className="wnd-close-btn" onClick={() => this.wnd_close() }>X</div>
        </div>
        <div className="mx_content flex" style={ this.body_style() }>

        <div className="web-row flex">
        <div className="web-col">
        <div className="web-col flex">
            { this.state.gaa.map((grp, key) =>
            <div key={key} className="nota-grp-btn" style={ this.grp_style(grp)} onClick={() => this.btn_grp(grp.grp) } >
                <div>{ grp.text }</div>
                <div>{ grp.grp }</div>
            </div>
            )}
        </div>
        </div>
 
        <div className="web-row flex">
        <div className="bv">
        <div className="bv-grid">
        { this.state.bta.map((btn, key) =>
            <div key={key} className="nota-sell-btn"
                 style={ this.btn_style(btn) }
                 onClick={() => this.btn_sell(btn) } >
                <div>{ utils.toLang(btn.e1text) }</div>
                <div>{ utils.toPris(btn.e1pris) }</div>
            </div>
            )}
        </div>
        </div>
        </div>

        </div>

        </div>
       <div className="divider_orange"></div>

       <form className="cdi_f03">
            <div className="flex-row">
                <div className="flex-row py-2">
                    <div className="f30"></div>
                    <Fr_Input label="Text" col className="text-left w350 mx-2" text={this.state.tro.text} getValue={(e)=> this.val_save("text", e) } />
                </div>
                <div className="flex-row py-2">
                    <div className="f30"></div>
                    <Fr_Input label="Antal" col className="text-left w150 mx-2" text={this.state.tro.antal} getValue={(e)=> this.val_save("antal", e) } />
                </div>
                <div className="flex-row py-2">
                    <div className="f30"></div>
                    <Fr_Input label="Pris" col className="text-left w250 mx-2" text={this.state.tro.pris} getValue={(e)=> this.val_save("pris", e) } />
                </div>
                <div className="flex-row py-2">
                    <div className="f30"></div>
                    <Fr_Input label="Tecken" col className="text-left w150 mx-2" text={this.state.tro.tkn} getValue={(e)=> this.val_save("tkn", e) } />
                </div>
                <div className="flex-row py-2">
                    <div className="f30"></div>
                    <Fr_Input label="Datum" col className="text-left w150 mx-2" text={this.state.tro.dts} getValue={(e)=> this.val_save("dts", e) } />
                </div>
                <div className="flex-row py-2">
                    <div className="f30"></div>
                    <Fr_Input label="Gäst" col className="text-left w150 mx-2" text={this.state.tro.glopnr} getValue={(e)=> this.val_save("glopnr", e) } />
                </div>
            </div>
            </form>


        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex"></div>
            <button className="btn btn-hw-red w250" onClick={() => this.get_gst() } >
                GÄST
            </button>
            <div className="flex"></div>
            <button className="btn btn-hw-red w250" onClick={() => this.sell_tr() } >
                SÄLJ
            </button>
        </div>
      </div>
    </div>
	;
	return html;
  };
}

export default Krubb_Sell;