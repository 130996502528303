import React, { useState, useEffect, useContext } from 'react';
import * as net from "../../../lib/net";

import Ff_Input from "../../../lib/ui/ff_input";
import {format} from "date-fns";

class Plu_Split extends React.Component {
  constructor(props, context) {
    super(props);

    this.ctrl_plu = null;
    this.ctrl_text = null;
    this.ctrl_pris = null;

    //alert('PBO CTX: ' + JSON.stringify(props.pbo));
    this.store = props.store;
    this.state = {};
    this.state.uaa = [];
    this.state.cam = [];
    this.state.lso = {};
    //this.state.lro = props.lro;
    this.state.ynq = [
                {id: '0', text: 'JA'},
                {id: '1', text: 'NEJ'}
            ];
    this.state.aa = [
                {id: '01', text: 'AVD 01'},
                {id: '02', text: 'AVD 02'}
            ];
    this.state.tval = [
                {id: '0', text: 'INGET VALT'},
                {id: '1', text: 'INGEN FRÅGA'},
                {id: '2', text: 'AUTOMATISK FRÅGA'}
            ];
    this.state.pro = {};
    this.state.cols = {};
    this.state.lrwrk = false;
    //this.state.pbo = {};
    this.state.pbo = {};
    this.state.qbo = props.pbo;
    this.state.ix = 0;
    this.state.md_01 = false;
    this.state.md_init = false;
    this.state.plu = "";
    this.state.text = "";
    this.state.pris = "";
    this.state.paa = [];
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.cdi_init();
    this.cam_rows();
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
  tb_style() {
      var css = {};
      //css.height = window.innerHeight - 350;
      //css.overflow = "auto";
      return css;
  }
  c1_style() {
      var css = {};
      css.height = (window.innerHeight * 0.9) - 200;
      css.overflow = "auto";
      return css;
  }
  c2_style() {
      var css = {};
      css.margin = "0px";
      css.padding = "0px";
      css.border = "0px";
      css.width = "350px";
      css.height = (window.innerHeight * 0.9) - 200;
      css.overflow = "auto";
      return css;
  }
    cdi_init() {
        var self = this;
        var pro = {};
        var pbo = {};
        var qbo = self.state.qbo;

        var paa = [];
        for(var row of this.store.pos.plus) {
            var plu = (row.plu00.p0katnr * 1);
            if(plu < 9000) continue;
            paa.push( row );
        }

        var keys = Object.keys(qbo);
        for(var key of keys) {
            var o = qbo[key];
            pbo[key] = {};
            var okeys = Object.keys(qbo[key]);
            for(var okey of okeys) {
                var oo = qbo[key][okey];
                pbo[key][okey] = oo;
            }
        }

        pbo.plu00.p0namn = pbo.plu00.p0namn.cdi2txt();
        pbo.plu02.p2pluknapp = pbo.plu02.p2pluknapp.cdi2txt();
        pbo.plu04.p4longname = pbo.plu04.p4longname.cdi2txt();

        pro.p0pris = pbo.plu00.p0pris.formatpris();
        pro.p0nypris = pbo.plu00.p0nypris.formatpris();
        pro.p0happy = pbo.plu00.p0happy.formatpris();
        pro.p1nyhappy = pbo.plu01.p1nyhappy.formatpris();
        pro.p3kost = pbo.plu03.p3kost.formatpris();

        self.setState({pbo: pbo, pro: pro, paa: paa,  md_init: true});
    }
    cdi_store() {
        var self = this;
        var pbo = self.state.pbo;
        var qbo = self.state.qbo;
        var pro = self.state.pro;

        pbo.plu00.p0namn = pbo.plu00.p0namn.txt2cdi();
        pbo.plu02.p2pluknapp = pbo.plu02.p2pluknapp.txt2cdi();
        pbo.plu04.p4longname = pbo.plu04.p4longname.txt2cdi();

        pbo.plu00.p0pris = pro.p0pris.pris2cdi();
        pbo.plu00.p0nypris = pro.p0nypris.pris2cdi();
        pbo.plu00.p0happy = pro.p0happy.pris2cdi();
        pbo.plu01.p1nyhappy = pro.p1nyhappy.pris2cdi();
        pbo.plu03.p3kost = pro.p3kost.pris2cdi();

        var keys = Object.keys(pbo);
        for(var key of keys) {
            var o = pbo[key];
            if(key.substr(0,3) != "plu") continue;

            var okeys = Object.keys(pbo[key]);
            for(var okey of okeys) {
                var oo = pbo[key][okey];
                qbo[key][okey] = oo;
            }
        }
        self.setState({qbo: qbo});
    }
    cdi_plubuf() {
        var self = this;
        var plubuf = {};
        var pbo = self.state.pbo;
        var pro = self.state.pro;

        pbo.plu00.p0namn = pbo.plu00.p0namn.txt2cdi();
        pbo.plu02.p2pluknapp = pbo.plu02.p2pluknapp.txt2cdi();
        pbo.plu04.p4longname = pbo.plu04.p4longname.txt2cdi();

        pbo.plu00.p0pris = pro.p0pris.pris2cdi();
        pbo.plu00.p0nypris = pro.p0nypris.pris2cdi();
        pbo.plu00.p0happy = pro.p0happy.pris2cdi();
        pbo.plu01.p1nyhappy = pro.p1nyhappy.pris2cdi();
        pbo.plu03.p3kost = pro.p3kost.pris2cdi();

        pro.p0pris = pbo.plu00.p0pris.formatpris();
        pro.p0nypris = pbo.plu00.p0nypris.formatpris();
        pro.p0happy = pbo.plu00.p0happy.formatpris();
        pro.p1nyhappy = pbo.plu01.p1nyhappy.formatpris();
        pro.p3kost = pbo.plu03.p3kost.formatpris();

        var keys = Object.keys(pbo);
        for(var key of keys) {
            var o = pbo[key];
            if(key.substr(0,3) != "plu") continue;

            plubuf[key] = {};
            var okeys = Object.keys(pbo[key]);
            for(var okey of okeys) {
                var oo = pbo[key][okey];
                plubuf[key][okey] = oo;
            }
        }
        self.setState({pro: pro});
        return plubuf;
    }

    prn_val() {
      alert(this.state.pbo.plu00.p0namn);
      alert(this.state.pbo.plu00.p0pris);
      alert(this.state.pbo.plu02.p2vgrupp);
    }

    formchange (e) {
      const name = e.target.name;
      const value = e.target.value;
      var pbo = this.state.pbo;
      var na = name.split(".");
      pbo[na[0]][na[1]] = value;
      this.setState({pbo: pbo});
      //setState({[name]: value});
    }
    prischange (e) {
      const name = e.target.name;
      const value = e.target.value;
      var pro = this.state.pro;
      var na = name.split(".");
      pro[na[1]] = value;
      this.setState({pro: pro});
      //setState({[name]: value});
    }

    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    handleClose = () => {
		this.props.ctx.setState({ plusplit: false });
	};
    handleChange = (event, newValue) => {
        //alert(newValue);
        this.setState({ix: newValue});
      };
  cssCols (cid) {
      var css = {};
      if(this.state.cols[cid]) css.width = this.state.cols[cid].toString() + "px";
      //if(cid == "c01") alert(JSON.stringify(this.state.cols[cid]));
      return css;
  }
  syncCols (tb) {

    if (!document.getElementById(tb)) return;

    var cols = {};
    var ix = document.getElementById(tb).rows.length - 1;
    var ic = document.getElementById(tb).rows[0].cells.length;
    var ci = 0;
    while(ci < ic) {
        var cxi = ci + 1;
        var col = "c" + cxi.toString().padStart(2, "0");
        cols[col] = document.getElementById(tb).rows[ix].cells[ci].clientWidth + 1;
        ci = ci + 1;
    }
    this.setState({cols: cols });
    //alert(JSON.stringify(this.state.cols));
    //this.forceUpdate();
  };

  md_toggle (event) {
    var self = this;
    self.setState({md_01: false });
    return;
  }
  plu_split (event) {
    var self = this;
    self.setState({md_01: true });
    return;
  }

  plu_sel (row) {
    var self = this;
    this.ctrl_plu.updateState(row.plu00.p0katnr);
    this.ctrl_text.updateState(row.plu00.p0namn.toswe());
    this.ctrl_pris.updateState(row.plu00.p0pris.cdipris());
    self.setState({plu: row.plu00.p0katnr, text: row.plu00.p0namn.toswe(), pris: row.plu00.p0pris.cdipris() });
    return;
  }

  plu_save (val) {
    var self = this;
    //alert("PLU: " + JSON.stringify(val));
    self.setState({plu: val });
    return;
  }

  text_save (val) {
    var self = this;
    //alert("TEXT: " + JSON.stringify(val));
    self.setState({text: val });
    return;
  }

  pris_save (val) {
    var self = this;
    //alert("PRIS: " + JSON.stringify(val));
    self.setState({pris: val });
    return;
  }
  split_row_del (drow) {
    var self = this;
    var cam = this.state.cam;
    var caa = [];
    for(var row of cam) {
        if(row.kplu == drow.kplu) continue;
        caa.push( row );
    }
    //alert("PRIS: " + JSON.stringify(val));
    self.setState({cam: caa });
  }
  split_add () {
    var self = this;
    var pbo = this.state.qbo;

    var row = {};
    row.avd = "01";
    row.hplu = pbo.plu00.p0katnr;
    row.kplu = this.state.plu;
    row.text = this.state.text;
    row.belopp = this.state.pris.replace(".", "");
    var cam = this.state.cam;
    cam.push( row );
    //alert("PRIS: " + JSON.stringify(val));
    self.setState({cam: cam }, function() {
        self.syncTb("ssTable");
    });
    return;
  }
  cam_rows() {
    var self = this;
    var pbo = this.state.qbo;

    var prm = {};
    prm.req = "pos.pos_campaign.cam_rows";
    prm.plunr = pbo.plu00.p0katnr;
    //alert(JSON.stringify(prm));

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            return;
        }
        self.setState({cam: ret.rca });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

  split_save () {
    var self = this;
    self.setState({md_01: true });

    //var plubuf = self.cdi_plubuf();
    var pbo = this.state.qbo;

    var prm = {};
    prm.req = "pos.pos_campaign.cam_rows_save";
    prm.plunr = pbo.plu00.p0katnr;
    prm.rows = this.state.cam;
    ///alert(JSON.stringify(prm));

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok == "000") {
            //self.cdi_store();
            ///self.props.pbo.plu00.p0pris = ret.belopp;
            ///////self.props.pbo.plu00.p0kampanj = "1";
  		    ///self.props.ctx.setState({ plusplit: false });
  		    self.props.ctx.plu_split_done(self.props.ctx, ret);
      }
        /*
        setTimeout(function(){
            self.setState({md_01: false });
        }, 500);
         */
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        self.setState({md_01: false });
    });
    return;
  }
    syncTb(tb){
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
        tdElements = tbel.getElementsByTagName('td');

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
            width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }

  render() {
    if(!this.props.show) return "";

    //alert(this.props.ctx.state.lro);

    var title = this.props.title;
    if(!title) title = "Plu";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="mx_backdrop" /> : null }
        <div className="mx_dlg mx_90 flex-col" style={ this.dlg_style() }>
        { this.state.md_init ?
        <div className="flex">

            <div className="flex-row" style={{padding:"5px",height:"30px"}}>
                    <div className="flex-row flex txt_18">
                        <div>FÖRDELA PLU: </div>
                        <div>{ this.state.pbo.plu00.p0katnr } - { this.state.pbo.plu00.p0namn }</div>
                    </div>
                    <div className="flex-col">
                        <div>UPPDATERA</div>
                    </div>
            </div>
            <div className="divider_orange"></div>
        <div className="mx_content flex">

            <div className="flex-row flex">
            <div id="ssTable" className="flex-col flex" style={ this.c1_style() }>
                <table className="table table-bordered table-hover" style={{marginBottom: "0px"}}>
                <thead>
                <tr>
                    <th>HPlu</th>
                    <th>KPlu</th>
                    <th>Text</th>
                    <th>Belopp</th>
                    <th>Status</th>
                </tr>
                </thead>
            </table>
            <div style={this.tb_style()}>
            <table className="table table-bordered table-hover">
                <tbody>
                { this.state.cam.map((row, key) =>
                <tr key={key} onDoubleClick={() => this.split_row_del(row) }>
                    <td>{ row.hplu }</td>
                    <td>{ row.kplu }</td>
                    <td>{ row.text }</td>
                    <td>{ row.belopp.cdipris() }</td>
                    <td>{ row.status }</td>
                </tr>
                )}
                </tbody>
            </table>
            </div>
            </div>

            <div className="vert_orange"></div>

            <div className="flex-column"  style={ this.c2_style() }>

            <div className="flex-row flex">
                <div className="w50">PLU</div>
                <div className="w150">Text</div>
                <div className="w50">Pris</div>
            </div>
            <div style={ this.tb_style() }>
            { this.state.paa.map((row, key) =>
                <div key={key} id={ row.plu00.p0katnr } className="tb_row flex-row" onDoubleClick={() => this.plu_sel(row) }>
                <div className="w50">{ row.plu00.p0katnr }</div>
                <div className="w150">{ row.plu00.p0namn.toswe() }</div>
                <div className="w50 text-right">{ row.plu00.p0pris.formatpris() }</div>
                </div>
            )}
            </div>
            </div>

            </div>

            <div className="divider_green"></div>
            <div className="flex-row mb-2">
                <Ff_Input ref={(ip) => {this.ctrl_plu = ip}} label="Plu" text={this.state.plu} onChange={(e)=> this.plu_save(e) } />
                <Ff_Input ref={(ip) => {this.ctrl_text = ip}} clasName="w250" label="Text" text={this.state.text} getValue={(e)=> this.text_save(e) } />
                <Ff_Input ref={(ip) => {this.ctrl_pris = ip}} label="Pris" text={this.state.pris} getValue={(e)=> this.pris_save(e) } />
                <div className="flex"></div>
                <div className="web-link self-end" onClick={() => this.split_add() } >
                    LÄGG TILL
                </div>
                <div style={{border: "0px", margin: "0px", padding: "0px", width: "350px"}}></div>
            </div>
        </div>
        </div>
        : null }
        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250 ripple" onClick={() => this.handleClose() } >
                STÄNG
            </button>
            <div className="flex-row">
            </div>
            <button className="btn btn-hw-green w250 ripple" onClick={(event) => this.split_save(event) } >
                SPARA
            </button>
        </div>
      </div>

    </div>
	;
	return html;
  };
}

export default Plu_Split;