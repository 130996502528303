import React, { useState, useEffect, useContext } from 'react';
import * as net from "../../../lib/net";
import Dt_Cal from '../../../lib/ui/dt_cal';
import {format, subDays} from "date-fns";

import PdfRpDlg from "../fkn/pdf_rp_dlg";
import MxRpDlg from "../fkn/mx_rp_dlg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Tr_Dlg extends React.Component {
  constructor(props, context) {
    super(props);

    this.dt_fd = React.createRef();
    this.dt_td = React.createRef();

    this.pos = props.store.pos;
    this.state = {};
    this.state.today = new Date();

    this.state.tya = [
                {id: '01', text: 'ALLA RÖRELSER'},
                {id: '02', text: 'ENDAST TRANSAKTIONER'},
                {id: '03', text: 'ENDAST PRISKATEGORIER'},
                {id: '04', text: 'ENDAST BETALNINGAR'}
            ];
    this.state.dea = [
                {id: '01', text: 'Definition v1'},
                {id: '02', text: 'Definition v2'}
            ];
    this.state.cols = {};

    this.state.fdat = "";
    this.state.tdat = "";
    this.state.typ = "";
    this.state.def = "";
    this.state.mx = {};
    this.state.pdf = 0;
    this.state.pdf_content = null;
    this.state.loading = 0;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.dlg_init();
  }

  dlg_init() {
      var dat = this.state.today;
      dat = subDays(dat, 1);
      var dts = format(dat, "yyyy-MM-dd");
      var state = this.state;
      state.fdat = dts;
      state.tdat = dts;
      state.typ = "01";
      state.def = "01";
      state.loading = 0;
    this.setState(state);
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    tab_style(tabid) {
      var css = {};
      css.display = "none";
      if(this.state.tabval === tabid) css.display = "block";
      return css;
    }
    tab_head(tabid) {
      var css = {};
      css.background = "#fff";
      css.color = "#000";
      if(this.state.tabval === tabid) {
          css.background = "#080";
          css.color = "#fff";
      }
      return css;
    }
  change_typ(e) {
    //let cdat = this.state.seldate;
    let typ = e.target.value;
    this.setState({typ: typ});
  }
  change_def(e) {
    //let cdat = this.state.seldate;
    let def = e.target.value;
    this.setState({def: def});
  }

    rp_mx() {
        var prm = {};
     	prm.req = "pms.rp.tr";
   		prm.fdat = this.state.fdat;
   		prm.tdat = this.state.tdat;
   		prm.typ = this.state.typ;
   		prm.def = this.state.def;

        var mx = {};
     	mx.rp = "bok";
     	mx.prm = prm;

        this.setState({ mx: mx });
    }
    rp_prn() {
        var self = this;

        this.setState({ loading: 1 });

        var prm = {};
     	prm.req = "pms.rp.trs";
   		prm.fdat = this.state.fdat;
   		prm.tdat = this.state.tdat;
   		prm.typ = this.state.typ;
   		prm.def = this.state.def;
        //alert(JSON.stringify(prm));
        //return;

        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret.ok));

            //var uri = "data:application/pdf;base64," + ret.pdf;
            self.setState({pdf: 1, pdf_content: ret.pdf });
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }
    rp_xl() {
        var self = this;

        this.setState({ loading: 1 });

        var favd = this.state.favd;
        var tavd = this.state.tavd;

        var prm = {};
        prm.req = "pms.rp.trs_xl";
        prm.fdat = this.state.fdat;
        prm.tdat = this.state.tdat;
        //alert(JSON.stringify(prm));
        //return;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.ok));

                var rawbuf = ret.data;
                var file = new Blob([(rawbuf)], {type: 'application/octet-stream'});
                var fileURL = window.URL.createObjectURL(file);
                //$scope.fil_object = $sce.trustAsResourceUrl(fileURL);
                var a = document.createElement('a');
                a.href = fileURL;
                //var filename = req.filename;
                var filename = "";
                if(filename.length < 1) filename = "trs.xlsx";
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                self.setState({ loading: 0 });
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }

    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    handleClose = () => {
		this.props.ctx.setState({ rptyp: "" });
	};

  dt_fdat(e) {
    //alert(JSON.stringify(e));
    var dts = format(e, "yyyy-MM-dd");
    this.setState({ fdat: dts });
  }
  dt_tdat(e) {
      //alert(JSON.stringify(e));
    var dts = format(e, "yyyy-MM-dd");
    this.setState({ tdat: dts });
  }
  oneday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_td.current.updateDt(this.state.fdat);
  }
  addday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_fd.current.updateAdd();
    this.dt_td.current.updateAdd();
  }
  subday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_fd.current.updateSub();
    this.dt_td.current.updateSub();
  }
  mx_done (ret) {
    this.setState({loading: 0, mx: 0 });
  }
  pdf_done (ret) {
    this.setState({loading: 0, pdf: 0, pdf_content: null });
  }

  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  body_style() {
      var css = {};
      css.display = "";
      if( this.state.loading == 1 ) css.display = "none";
      return css;
  }
  cssCols (cid) {
      var css = {};
      if(this.state.cols[cid]) css.width = this.state.cols[cid].toString() + "px";
      //if(cid == "c01") alert(JSON.stringify(this.state.cols[cid]));
      return css;
  }
  syncCols (tb) {

    if (!document.getElementById(tb)) return;

    var cols = {};
    var ix = document.getElementById(tb).rows.length - 1;
    var ic = document.getElementById(tb).rows[0].cells.length;
    var ci = 0;
    while(ci < ic) {
        var cxi = ci + 1;
        var col = "c" + cxi.toString().padStart(2, "0");
        cols[col] = document.getElementById(tb).rows[ix].cells[ci].clientWidth + 1;
        ci = ci + 1;
    }
    this.setState({cols: cols });
    //alert(JSON.stringify(this.state.cols));
    //this.forceUpdate();
  };

  render() {
    if(!this.props.show) return "";

    //alert(this.props.ctx.state.lro);

    var title = this.props.title;
    if(!title) title = "MÅNADSRAPPORT";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="mx_backdrop" /> : null }
        <div className="mx_dlg mx_rp flex-col" style={ this.dlg_style() }>
        <div className="mx_head mx_bg flex-row flex-space-between">
            <div className="flex-col flex">
                <div>Transaktions Rapport</div>
            </div>
            <div className="white-icon-close" onClick={() => this.handleClose() }>X</div>
        </div>
        { this.state.loading ? <div className="mx_content flex align-items-center justify-content-center"><FontAwesomeIcon icon="spinner" spin size="3x" fixed /></div> :
        null }
        <div className="mx_content flex" style={ this.body_style() }>
            <div className="flex-row px-2">
                    <div className="flex-col flex">
                        <div>Start</div>
                    </div>
                    <div className="flex-col flex">
                        <div></div>
                    </div>
                    <div className="flex-col w50">
                        <div>Ändra</div>
                    </div>
            </div>
            <div className="divider_orange"></div>

        <div className="mx_body flex">

            <div className="flex-row">
              <div className="flex-col">
                  <span>&nbsp;</span>
                  <div className="dt-btn-01" onClick={() => this.subday() } ><FontAwesomeIcon icon="minus" size="1x" /></div>
            </div>
              <div className="flex-col">
                  <span>Från</span>
                  <Dt_Cal startdatum="2020-02-01" rp="1" ref={this.dt_fd} getValue={(e)=> this.dt_fdat(e)} />
            </div>
               <div className="flex-col">
                  <span>&nbsp;</span>
                  <div className="dt-btn-01" onClick={() => this.oneday() } >1</div>
            </div>
             <div className="flex-col">
                  <span>Till</span>
                  <Dt_Cal startdatum="2020-02-01" rp="1" ref={this.dt_td} getValue={(e)=> this.dt_tdat(e)} />
            </div>
              <div className="flex-col">
                  <span>&nbsp;</span>
                  <div className="dt-btn-01" onClick={() => this.addday() } ><FontAwesomeIcon icon="plus" size="1x" /></div>
            </div>
            </div>

            <div className="flex-row">
              <div className="flex-col">
                  <span>Typ</span>
        <select className="form-control input-rp-01 w250" placeholder="Typ" onChange={(e) => this.change_typ(e)}>
            { this.state.tya.map((row, key) =>
            <option key={key} value={row.id}>{row.text}</option>
            )}
        </select>
            </div>
              <div className="flex-col">
                  <span>Konto Definition</span>
        <select className="form-control input-rp-01 w250" placeholder="Definition" onChange={(e) => this.change_def(e)}>
            { this.state.dea.map((row, key) =>
            <option key={key} value={row.id}>{row.text}</option>
            )}
        </select>
            </div>
            </div>

        </div>

        </div>


        <div className="mx_foot flex-row">
            <div className="flex-row flex">
                <div className="btn-rp" onClick={() => this.handleClose() } >
                    <div>STÄNG</div>
                    <div><FontAwesomeIcon icon="times" size="2x" /></div>
                </div>
                <div className="btn-rp" onClick={() => this.rp_xl() } >
                    <div>EXCEL</div>
                    <div><FontAwesomeIcon icon="at" size="2x" /></div>
                </div>
            </div>
            <div className="flex-row">
                <div className="btn-rp" onClick={() => this.rp_mx() } >
                    <div>EPOST</div>
                    <div><FontAwesomeIcon icon="at" size="2x" /></div>
                </div>
                <div className="btn-rp" onClick={() => this.rp_prn() } >
                    <div>SKRIV UT</div>
                    <div><FontAwesomeIcon icon="print" size="2x" /></div>
                </div>
            </div>
        </div>

      </div>
        <MxRpDlg mx={this.state.mx} done={(ret) => this.mx_done(ret)} backdrop={true}/>
        <PdfRpDlg show={this.state.pdf} pdf={this.state.pdf_content} done={(ret) => this.pdf_done(ret)} backdrop={true}/>
    </div>
	;
	return html;
  };
}

export default Tr_Dlg;