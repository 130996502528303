import React, { useState, useEffect, useContext } from 'react';
import { ChromePicker } from 'react-color';

import * as net from "../../../lib/net";
import * as utils from "../../../assets/utils";

import Fr_Input from "../../../lib/ui/fr_input";
import Wnd_Error from "../../../lib/ui/wnd_error";
import Wnd_Ok from "../../../lib/ui/wnd_ok";
import Wnd_Info from "../../../lib/ui/wnd_info";
import Wnd_Confirm from "../../../lib/ui/wnd_confirm";
import Fr_Cal from "../../../lib/ui/fr_cal";

class Ct_Edit extends React.Component {
  constructor(props, context) {
    super(props);

    ///this.pos = props.store.pos;

    this.state = {};
    this.state.mka = [];

    this.state.gnr = "001";
      this.state.ynq = [
          {id: '0', text: 'NEJ'},
          {id: '1', text: 'JA'}
      ];
      this.state.bgval = [
          {id: '0', text: 'INGEN BAKGRUND'},
          {id: '1', text: 'FÄRG BAKGRUND'},
          {id: '2', text: 'BILD BAKGRUND'},
          {id: '3', text: 'FILM BAKGRUND'}
      ];
      this.state.ltval = [
          {id: 'base', text: 'BAS LAYOUT'},
          {id: 'rut4', text: '4 RUTOR'},
          {id: 'col2', text: '2 KOLUMNER'},
          {id: 'col3', text: '3 KOLUMNER'},
          {id: 'vert2', text: '2 VERTIKALA DELAR'},
          {id: 'vert3', text: '3 VERTIKALA DELAR'},
          {id: 'hor2', text: '2 HORIZONTELLA DELAR'},
          {id: 'hor3', text: '3 HORIZONTELLA DELAR'}
      ];
      this.state.ctval = [
        {id: 'pdf', text: 'PDF DOKUMENT'},
        {id: 'mp4', text: 'FILM MP4'}
    ];

    this.state.msg = {};
    this.state.cols = {};
    this.state.cto = {};
    if(props.wnds.cto) {
        this.state.cto = props.wnds.cto;
    }
    this.state.background = "#fff";
    this.state.colorid = "";
    this.state.displayColorPicker = false;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      //this.mnu_mp();
  }

    task_init() {
        var tdo = {};
        tdo.typ = "";
        tdo.id = "";
        tdo.text = "";
        tdo.reg = "";
        tdo.status = "";
        //alert(JSON.stringify(rso));
        this.setState({ tdo: tdo });
        return;
    }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    tb_style() {
        var css = {};
        css.height = window.innerHeight - 275;
        return css;
    }
    pad(n, width, z) {
        z = z || '0';
        n = n + '';
        return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
    }
    cdi_rgb(rgb) {
        var r = parseInt( rgb.substring(2,5) ).toString(16);
        var g = parseInt( rgb.substring(5,8) ).toString(16);
        var b = parseInt( rgb.substring(8,11) ).toString(16);
        var hex = "#" + this.pad(r, 2) + this.pad(g, 2) + this.pad(b, 2);
        return hex;
    }
    cdi_hex(rgb) {
        var r = parseInt( rgb.substring(2,5) ).toString(16);
        var g = parseInt( rgb.substring(5,8) ).toString(16);
        var b = parseInt( rgb.substring(8,11) ).toString(16);
        var hex = this.pad(r, 2) + this.pad(g, 2) + this.pad(b, 2);
        return hex;
    }
    hex_cdi(hex) {
        if(hex.length == 4) hex = hex + hex.substr(1,3);
        var r = parseInt( hex.substr(1,2), 16 ).toString();
        var g = parseInt( hex.substr(3,2), 16 ).toString();
        var b = parseInt( hex.substr(5,2), 16 ).toString();
        var rgb = "11" + this.pad(r, 3) + this.pad(g, 3) + this.pad(b, 3);
        return rgb;
    }
    val_color(id, val) {
        var lto = this.state.lto;
        var ia = id.split(".");
        var pre = ia[0];
        var key = ia[1];
        //lto[pre][key] = this.hex_cdi(val.hex);
        lto[pre][key] = val.hex;
        this.setState({lto: lto});
        return;
    }
    colorClick = (id, color) => {
      //alert(id);
        this.setState({ colorid: id, displayColorPicker: !this.state.displayColorPicker });
    };

    colorClose = () => {
        this.setState({ displayColorPicker: false })
    };

    wnd_close = () => {
        if(this.props.close) {
            this.props.close(this.props.ctx);
        }
        else this.props.ctx.setState({ wnds: {} });
    };
    ct_change (e) {
        const value = e.target.value;
        var cto = this.state.cto;
        cto.cttyp = value;
        this.setState({cto: cto});
    }
    mp_change (e) {
        const value = e.target.value;
        var lto = this.state.lto;
        lto.ctx00.mpris = value;
        this.setState({lto: lto});
    }
    bg_change (e) {
        const value = e.target.value;
        var lto = this.state.lto;
        lto.ctx01.bgtyp = value;
        this.setState({lto: lto});
    }
    anm_change (e) {
        const value = e.target.value;
        var lto = this.state.lto;
        lto.anm = value;
        this.setState({lto: lto});
    }

    val_save(id, val) {
        var cto = this.state.cto;
        //var ia = id.split(".");
        //lto[ia[0]][ia[1]] = val;
        cto[id] = val;
        this.setState({ cto: cto });
        return;
    }
    mnu_mp() {
        var self = this;
        var prm = {};
        prm.req = "dsi.dsi_mp.mp_mnu";
        prm.mnuid = "mn02";

        net.sio_req(prm)
            .then(function(ret){
                alert(JSON.stringify(ret.rca));

                self.setState({ mna: ret.rca });
                //self.setState({ rva: ret.rca });
                self.syncTb("mnuTable");
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    syncTb(tb) {
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
            tdElements = tbel.getElementsByTagName('td');
        if(tdElements.length < 1 ) return;

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
                width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
    ct_upd() {
    var self = this;
    var prm = {};
    prm.req = "dsi.dsi_cont.ct_upd";
    prm.cto = this.state.cto;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));
        if(ret.ok == "000") {
            self.setState({ msg: {ok: true, text: "Sparad ..."} });
            setTimeout(function(){
                self.setState({msg: {} });
                  if(self.props.wnds.cb) {
                      self.props.wnds.cb(self.props.ctx);
                  }
                  else self.props.ctx.setState({ wnds: {} });
                return;
            }, 1000);
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  ct_del() {
    var self = this;
    var prm = {};
    prm.req = "dsi.dsi_cont.ct_del";
    prm.cto = this.state.cto;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));
        if(ret.ok == "000") {
            self.setState({ msg: {ok: true, text: "Borttagen"} });
            setTimeout(function(){
                self.setState({msg: {} });
                  if(self.props.close) {
                      self.props.close(self.props.ctx);
                  }
                  else self.props.ctx.setState({ wnds: {} });
                return;
            }, 1000);
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

  render() {
    //if(!this.props.show) return "";
    var title = this.props.title;
    if(!title) title = "Resurs";
    //if(props.kro) alert(props.kro.krr00.kid);
      const popover = {
          position: 'absolute',
          top: '75px',
          left: '400px',
          zIndex: '2',
      }
      const cover = {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
      }

	var html =
        <div className="mx_base">
        <div className="m2_backdrop" />
        <div className="m2_dlg mx_90 flex-col" style={ this.dlg_style() } >
        <div className="mx_content flex">

        <div className="mx_h01">
            <div>Layout : { utils.toNum( this.state.cto.ctid ) }</div>
        </div>
            <div className="flex-row" style={{padding:"5px",height:"35px"}}>
                    <div className="flex-col flex">
                    </div>
                    <div className="flex-col">
                        <div>UPPDATERA</div>
                    </div>
            </div>
            <div className="divider_orange"></div>

            <form className="cdi_f03">
                <div className="flex-row">
                    <Fr_Input label="Layout ID" className="text-left w150" text={this.state.cto.ctid} getValue={(e)=> this.val_save("ctid", e) } />
                    <Fr_Input label="Status" className="text-left w350" text={this.state.cto.status} getValue={(e)=> this.val_save("status", e) } />
                </div>
                <div className="flex-row">
                    <Fr_Input label="Text" className="text-left w350" text={this.state.cto.text} getValue={(e)=> this.val_save("text", e) } />
                </div>

                <div className="flex-row hide">
                    <div className="form-group flex-row flex">
                        <span className="header">Layout Typ</span>
                        <select value={this.state.cto.cttyp} className="form-control w250" onChange={(e) => this.ct_change(e)}>
                            { this.state.ctval.map((row, key) =>
                                <option key={key} value={row.id}>{row.text}</option>
                            )}
                        </select>
                    </div>
                </div>

                <div className="divider_green"></div>
                <div className="flex-row">
                    <div className="form-group flex-row flex">
                        <span className="header">Innehålls Typ</span>
                        <select value={this.state.cto.cttyp} className="form-control w250" onChange={(e) => this.ct_change(e)}>
                            { this.state.ctval.map((row, key) =>
                                <option key={key} value={row.id}>{row.text}</option>
                            )}
                        </select>
                    </div>
                </div>
                <div className="flex-row">
                    <Fr_Input label="Url" className="text-left w350" text={this.state.cto.url} getValue={(e)=> this.val_save("url", e) } />
                </div>


                <div className="flex-row hide">
                    <div className="form-group flex-row flex">
                        <span className="header">BG Typ</span>
                        <select value={this.state.cto.bgtyp} className="form-control w250" onChange={(e) => this.bg_change(e)}>
                            { this.state.bgval.map((row, key) =>
                                <option key={key} value={row.id}>{row.text}</option>
                            )}
                        </select>
                    </div>
                </div>
                <div className="flex-row hide">
                    <div className="flex-row flex">
                    <Fr_Input noflex label="Färg" className="text-left w150" text={this.state.cto.bgfarg} getValue={(e)=> this.val_save("bgfarg", e) } />
                    <div className="web-link px-3 py-3" onClick={ () => this.colorClick("bgfarg", this.state.cto.bgfarg) }>VAL</div>
                    </div>
                    <Fr_Input label="Url" className="text-left w350" text={this.state.cto.bgurl} getValue={(e)=> this.val_save("bgurl", e) } />
                </div>

                <div className="divider_green"></div>
                <div className="flex-row">
                     <Fr_Input label="Status" className="text-left w350" text={this.state.cto.status} getValue={(e)=> this.val_save("status", e) } />
                </div>
                <div className="flex-row hide">
                    <Fr_Input label="Kategori Storlek" className="text-left w150" text={this.state.cto.cat_sz} getValue={(e)=> this.val_save("cat_sz", e) } />
                    <div className="flex-row flex">
                    <Fr_Input noflex label="Kategori Färg" className="text-left w150" text={this.state.cto.cat_fg} getValue={(e)=> this.val_save("cat_fg", e) } />
                    <div className="web-link px-3 py-3" onClick={ () => this.colorClick("cat_fg", this.state.cto.cat_fg) }>VAL</div>
                    </div>
                </div>
            </form>

            { this.state.displayColorPicker ?
                <div style={ popover }>
                    <div style={ cover } onClick={ this.colorClose }/>
                    <ChromePicker color={ this.state.background } onChangeComplete={(e)=> this.val_color(this.state.colorid, e) } />
                </div>
                : null }
        </div>
        <div className="mx_foot flex-row">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex"></div>
            <button className="btn btn-hw-red w250" onClick={() => this.ct_del() } >
                TA BORT
            </button>
            <div className="flex"></div>
            <button className="btn btn-hw-red w250" onClick={() => this.ct_upd() } >
                SPARA
            </button>
        </div>
      </div>


        <Wnd_Error msg={this.state.msg} ctx={this} />
        <Wnd_Ok msg={this.state.msg} ctx={this} />
        <Wnd_Info msg={this.state.msg} ctx={this} />
        <Wnd_Confirm msg={this.state.msg} ctx={this} />
      </div>
	;
	return html;
  };
}

export default Ct_Edit;