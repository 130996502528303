import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../../../lib/net";
import Dd_Sel from "../../../../lib/ui/dd_sel";

class Nota_fkn extends React.Component {
  constructor(props, context) {
    super(props);

     this.selitems = [
        {fkn: 'setup', text: 'INSTÄLLNINGAR'},
        {fkn: 'upd', text: 'UPPDATERA'},
        {fkn: 'sys', text: 'SYSTEM'},
        {fkn: 'logout', text: 'LOGGA UT'}
    ];

   //this.webStore = props.store;
    this.state = {};
    this.state.ynq = [];
    this.state.paa = [];
    this.state.aa = [];

    if(props.wnds) {
        this.fkn_close = props.wnds.fkn_close;
        this.state.boknr = props.wnds.boknr;
        this.state.rvo = props.wnds.rvo;
        this.state.nta = props.wnds.nta;
        this.state.srow = props.wnds.srow;
    }
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }

    wnd_close() {
      if(this.props.close) this.props.close(this.props.ctx);
      else this.props.ctx.setState({ wnds: {} });
	};
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }

  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "";

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="m2_backdrop" /> : null }
        <div className="m2_dlg mx_70 flex-col" style={ this.dlg_style() }>
        <div className="flex-row " style={{padding:"0px",height:"50px",lineHeight:"50px", background: "#1a456b", color: "#fff"}}>
                <div className="flex-row flex" style={{padding:"0px 5px", fontSize: "22px"}}>
                    <div>Funktioner { this.state.rumsnr }</div>
                </div>
            <div className="">
                <Dd_Sel items={ this.selitems } cls="web-top-icon" getValue={(e)=> this.sel_fkn(e)} />
            </div>
        </div>
        <div className="mx_content flex">

        <div className="web-row flex">
        <div className="web-col flex" style={{padding: "0px"}}>
                INGA EXTRA FUNKTIONER UPPSATTA
        </div>
        </div>


        </div>
        <div className="mx_foot flex-row flex-space-between">
            <div className="flex-row"></div>
            <button className="btn btn-hw-red w250 ripple" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex-row"></div>
        </div>
      </div>
    </div>
	;
	return html;
  };
}

export default Nota_fkn;