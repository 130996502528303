import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";
import ddo from '../../ddo.js'

import Dt_Cal from "../../lib/ui/dt_cal";
import Td_Sel from "../../lib/ui/td_sel";

import Cas_Add from "./cas/cas_add";
import Cas_Edit from "./cas/cas_edit";
import Cas_Tech from "./cas/cas_tech";
import Lang from "../../lib/lang";
import {observer} from "mobx-react";
import Dd_Sel from "../../lib/ui/dd_sel";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Bo_Cas extends React.Component {
  constructor(props, context) {
    super(props);

     this.rowitems = [
        {fkn: 'edit', text: 'ÖPPNA'},
        {fkn: 'keey', text: 'NYCKEL'}
    ];

    this.webStore = props.store;
    this.pos = props.store.pos;

    this.state = {};
    this.state.seldate = new Date();
    this.state.avd = ddo.cache.avd;
      this.state.cra = [];
      this.state.crs = [];
      this.state.selrow = {};
      this.state.sortid = "";
      this.state.sortdir = 1;
    this.state.aa = [];
    this.state.casedit = false;
    if(this.pos.deps) this.state.aa = this.pos.deps;
    this.state.wnds = {};
      this.fknitems = [
          {fkn: 'cas_copy', text: 'KOPIERA KASSÖRER'},
          {fkn: 'cas_import', text: 'IMPORTERA KASSÖRER'},
          {fkn: 'cas_pub', text: 'PUBLICERA KASSÖRER'},
          {fkn: 'cas_tech', text: 'TEKNIKER FUNKTIONER'}
      ];
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.crs_list();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 250;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
    th_style(id) {
        var css = {};
        css.cursor = "pointer";
        if(id == this.state.sortid) {
            if(this.state.sortdir) css.color = "#800";
            else css.color = "#080";
        }
        return css;
    }
    row_style(row) {
        var srow = this.state.selrow;

        var css = {};
        if(srow == row) {
            css.background = "#080";
            css.color = "#fff";
        }
        if(this.state.avd != "00") {
            if(this.state.avd !== row.ser00.s0kstns) {
                css.display = "none";
            }
        }
        return css;
    }
    th_icon(typ) {
        var cls = "";
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;
        if(sortid == typ)
        {
            if(sortdir) cls = "fa fa-caret-up";
            else cls = "fa fa-caret-down";
        }
        return cls;
    }
    tb_sort(typ) {
        var self = this;
        //var rvs = self.state.rvs;
        var cra = self.state.cra;
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;

        if(sortid != typ) sortdir = 0;
        else {
            if(sortdir) sortdir = 0;
            else sortdir = 1;
        }

        if(sortdir) cra.sort((a,b) => (a.ser00[typ] > b.ser00[typ]) ? 1 : ((b.ser00[typ] > a.ser00[typ]) ? -1 : 0));
        else cra.sort((a,b) => (a.ser00[typ] < b.ser00[typ]) ? 1 : ((b.ser00[typ] < a.ser00[typ]) ? -1 : 0));
        self.setState({ cra: cra, sortid: typ, sortdir: sortdir });
    }
    tb_filtr() {
        var self = this;
        var crs = self.state.crs;
        var sz = this.state.sz_val;

        if(sz.length < 1) {
            self.setState({ cra: crs });
            return;
        }
        sz = sz.toUpperCase();
        var cra = crs.filter(function(row) {
            var brc = false;
            if(row.id.toUpperCase().includes(sz)) brc = true;
            if(row.text.toUpperCase().includes(sz)) brc = true;
            return brc;
        });
        self.setState({cra: cra });
    }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

  cas_add() {
      var self = this;
      var wnds = {};
      wnds.casadd = true;
      wnds.cso = {};
      self.setState({wnds: wnds });
  }
    cas_edit(row) {
        var self = this;
        var wnds = {};
        wnds.cas = true;
        wnds.cso = row;
        self.setState({wnds: wnds });
    }
    cas_tech() {
        var self = this;
        var wnds = {};
        wnds.castech = true;
        wnds.cso = {};
        self.setState({wnds: wnds });
    }
  wnd_ret(ctx, rsp) {
      ctx.crs();
  }

    crs_list() {
    var self = this;
    var prm = {};
    prm.req = "pos.pos_cr.crs";
    prm.avd = this.state.avd;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        self.setState({cra: ret.rco }, function () {
            self.syncTb("casTable");
        });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
    crs_pd() {
        var self = this;
        var prm = {};
        prm.req = "pos.pos_cr.crs_pd";
        prm.avd = this.state.avd;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.rca));

                self.setState({cra: ret.rco }, function () {
                    self.syncTb("casTable");
                });
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    syncTb(tb) {
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
        tdElements = tbel.getElementsByTagName('td');

        for (let i = 0; i < thElements.length; i++) {
            if(!tdElements[i]) continue;
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
            width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
  change_avd(e) {
    //let cdat = this.state.seldate;
    let avd = e.target.value;
    this.setState({avd: avd});
  }
    sel_row(row) {
        var srow = this.state.selrow;
        if(srow == row) {
            this.setState({selrow: {}});
        }
        else this.setState({selrow: row});
    }

    sel_fkn(sel) {
        //alert(JSON.stringify(fkn));
        if(sel.fkn == "cas_tech") {
            this.cas_tech();
        }
        if(sel.fkn == "cas_import") {
            //this.plus_import();
        }
    }

  render() {
    return (
    <div className="web-app">

    <div className="flex-row">
        <select className="form-control w250" placeholder="Välj avdelning" onChange={(e) => this.change_avd(e)}>
            <option value="00">ALLA AVDELNINGAR</option>
            { ddo.cache.deps.map((row, key) =>
            <option key={key} value={row.avd}>{row.avd} - {row.text}</option>
            )}
        </select>
        <div className="flex"></div>
        <button className="btn btn-hw-blue w150" onClick={() => this.cas_add() } aria-label="System" >
            LÄGG TILL
        </button>
        <button className="btn btn-hw-blue w150" onClick={() => this.crs_list() } aria-label="System" >
            VISA
        </button>
        <Dd_Sel items={ this.fknitems } cls="web-top-icon" getValue={(e)=> this.sel_fkn(e)} />
    </div>
    <div className="divider_orange"></div>

    <div className="web-row">
        <div id="casTable" className="flex">
        <table className="table table-bordered table-hover tb fixed_header">
            <thead>
            <tr>
                <th style={ this.th_style("s0serv") } onClick={() => this.tb_sort("s0serv") }><Lang>ID</Lang> <i className={ this.th_icon("s0serv") }></i></th>
                <th style={ this.th_style("s0namn") } onClick={() => this.tb_sort("s0namn") }><Lang>Text</Lang> <i className={ this.th_icon("s0namn") }></i></th>
                <th style={ this.th_style("s0kort") } onClick={() => this.tb_sort("s0kort") }><Lang>Kort</Lang> <i className={ this.th_icon("s0kort") }></i></th>
                <th style={ this.th_style("s0kstns") } onClick={() => this.tb_sort("s0kstns") }><Lang>Avd</Lang> <i className={ this.th_icon("s0kstns") }></i></th>
                <th>Aukt</th>
                <th>Tele</th>
                <th>Pnr</th>
                <th>Upd</th>
                <th>Sign</th>
                <th></th>
            </tr>
            </thead>
            <tbody style={ this.tb_style() }>
            { this.state.cra.map((row, key) =>
            <tr key={key} style={ this.row_style(row) } onClick={() => this.sel_row(row) } onDoubleClick={() => this.cas_edit(row) }>
                <td>{ row.ser00.s0serv }</td>
                <td>{ row.ser00.s0namn }</td>
                <td>{ row.ser00.s0kort }</td>
                <td>{ row.ser00.s0kstns }</td>
                <td>{ row.ser00.s0aukt }</td>
                <td>{ row.ser03.s3tfnnr }</td>
                <td>{ row.ser02.s2fodnr }</td>
                <td>{ row.ser00.s0upddat }</td>
                <td>{ row.ser00.s0sign }</td>
                <td>
                    <Td_Sel items={ this.rowitems } getValue={(e)=> this.sel_row_fkn(row, e)} />
                </td>
            </tr>
            )}
            </tbody>
        </table>
        </div>
    </div>

    { this.state.wnds.casadd ? <Cas_Add close={ this.state.wnd_ret } wnds={ this.state.wnds } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.wnds.cas ? <Cas_Edit close={ this.state.wnd_ret } wnds={ this.state.wnds } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.wnds.castech ? <Cas_Tech close={ this.state.wnd_ret } wnds={ this.state.wnds } ctx={this} store={this.props.store} backdrop={true}/> : null }
    </div>
  );
    }
}
export default observer(Bo_Cas);
