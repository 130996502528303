import React, { useState, useEffect, useContext } from 'react';
import * as fkn from "../lib/fkn";
import SDOContext from '../sdo.js'
import {BrowserRouter, Route, Routes} from "react-router-dom";
//import ADOContext from "../ado";

import Tst_Top from './com/tst_top.js';
import Tst_Mnu from './com/tst_mnu.js';
import Tst_Login from './login.js';
import Tst_Dash from './dash.js';
import Tst_List from './list.js';

import * as srs from "../lib/srs";
import * as net from "../lib/net";

function pg_home() {

    var html =
    <div className="web-content">
      Hem
    </div>
    ;
    return html;
}

class Tst extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, deps: [] };
    //context.posrend = this;
  }
/*
    const[txt, setTxt] = useState("SS00");
    var sdo = {};
    sdo.text = txt;
    sdo.setText = setTxt;
*/
  ddo() {
    alert("POS" + this.state.pgid );
    this.setState({ status: 1 });
  }
  udid_req() {
      var udid = "TT001920";
    var self = this;
	var req = {};
	req.req = "emv.print";
	req.udid = udid;
	req.fkn = "PRINT";

	var prm = {};
	req.prm = prm;

    //gda.wndLoading("Hämtar");
    srs.udid_req(req)
    .then(function(ret){
        //alert(JSON.stringify(ret.rco));
        //gda.wndClose();
        //var txt = JSON.stringify(data);
        //gda.wndSuccess(txt);
        //deps_list(ret.rco)

        alert(JSON.stringify(ret.rco));
        self.setState({ deps: ret.rco });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    })
    }
    settle() {
      var udid = "TT001920";
    var self = this;
	var req = {};
	req.req = "emv.settle";
	req.udid = udid;
	req.fkn = "SETTLE";

	var prm = {};
	req.prm = prm;

    //gda.wndLoading("Hämtar");
    srs.udid_req(req)
    .then(function(ret){
        //alert(JSON.stringify(ret.rco));
        //gda.wndClose();
        //var txt = JSON.stringify(data);
        //gda.wndSuccess(txt);
        //deps_list(ret.rco)

        alert(JSON.stringify(ret.rco));
        self.setState({ deps: ret.rco });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    })
    }

  render() {
    return (
    <div className="web-app">
    <div className="web-top">
        <div style={{ color: "#222", fontSize:"22px" }}>CDI Hotel - Information</div>
    </div>
    <div className="web-center" style={{ height:"100%" }}>
    <div className="web-row" style={{ fontSize:"32px" }}>
        <div  style={{ fontSize:"32px" }}>HOTELLSYSTEMET UPPDATERAS ...</div>
    </div>
    <div className="web-row">
        <div style={{ fontSize:"32px" }}>KLART INOM KORT ...</div>
    </div>
    </div>
    <div className="web-foot">
        <div>Hotellsystem { this.state.status }</div>
    </div>
    </div>
  );
}
}
//Sys.contextType = ADOContext;

export default Tst;
