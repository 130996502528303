import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../../../lib/net";
import Dd_Sel from "../../../../lib/ui/dd_sel";

import Collect_Rv from "./collect_rv";
import Collect_Gst from "./collect_gst";
import Collect_Row from "./collect_row";
import Collect_Pay from "./collect_pay";
import Move_From_Gst from "./move_from_gst";

class Collect extends React.Component {
  constructor(props, context) {
    super(props);

     this.selitems = [
        {fkn: 'setup', text: 'INSTÄLLNINGAR'},
        {fkn: 'upd', text: 'UPPDATERA'},
        {fkn: 'sys', text: 'SYSTEM'},
        {fkn: 'logout', text: 'LOGGA UT'}
    ];

   //this.webStore = props.store;
    this.state = {};
    this.state.ynq = [];
    this.state.aa = [];
    this.state.wnds = {};
    this.state.nta = [];
    this.state.srow = {};

    if(props.wnds) {
        this.fkn_close = props.wnds.fkn_close;
        this.state.boknr = props.wnds.boknr;
        this.state.rvo = props.wnds.rvo;
    }
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }


    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    wnd_close() {
      if(this.fkn_close) this.fkn_close(this.props.ctx);
      else this.props.ctx.setState({ wnds: {} });
	};
    drag(event) {
        //event.dataTransfer.setData("text", event.target.id);
	};
    drop(event) {
        event.preventDefault();
        alert("Drop");
	};
    dragEnter(event) {
        event.preventDefault();
        event.target.style.color = "#fff";
        event.target.style.background = "#ccc";
        //alert("Allow Drop");
	};
    dragLeave(event) {
        event.preventDefault();
        event.target.style.color = "#fff";
        event.target.style.background = "#080";
        //alert("Allow Drop");
	};
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  collect_rv() {
    var wnds = {};
    wnds.rv = true;
    wnds.boknr = this.state.boknr;
    wnds.rvo = this.state.rvo;
    wnds.fkn_close = this.collect_rsp;
    this.setState({wnds: wnds});
  }
  collect_gst() {
    var wnds = {};
    wnds.gst = true;
    wnds.boknr = this.state.boknr;
    wnds.rvo = this.state.rvo;
    wnds.fkn_close = this.collect_rsp;
    this.setState({wnds: wnds});
  }
  collect_row() {
    var wnds = {};
    wnds.row = true;
    wnds.boknr = this.state.boknr;
    wnds.rvo = this.state.rvo;
    wnds.fkn_close = this.collect_rsp;
    this.setState({wnds: wnds});
  }
  collect_rsp(ctx, rsp) {
      if(rsp.ok == "000") {
        var wnds = {};
        wnds.pay = true;
        wnds.boknr = ctx.state.boknr;
        wnds.rvo = this.state.rvo;
        wnds.cra = rsp.cra;
        wnds.fkn_close = ctx.pay_rsp;
        ctx.setState({wnds: wnds});
      }
      else {
          if(ctx.fkn_close) ctx.fkn_close(ctx.props.ctx);
          else ctx.props.ctx.setState({ wnds: {} });
      }
  }
  pay_rsp(ctx) {
      if(ctx.fkn_close) ctx.fkn_close(ctx.props.ctx);
      else ctx.props.ctx.setState({ wnds: {} });
  }

  move_rows() {
    var wnds = {};
    wnds.moverows = true;
    wnds.boknr = this.state.boknr;
    wnds.rvo = this.state.rvo;
    wnds.fkn_close = this.collect_rsp;
    this.setState({wnds: wnds});
  }

  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "";

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="m2_backdrop" /> : null }
        <div className="m2_dlg mx_50 flex-col" style={ this.dlg_style() }>
        <div className="flex-row " style={{padding:"0px",height:"50px",lineHeight:"50px", background: "#1a456b", color: "#fff"}}>
                <div className="flex-row flex" style={{padding:"0px 5px", fontSize: "22px"}}>
                    <div>SAMLAIN { this.state.rumsnr }</div>
                </div>
            <div className="">
                <Dd_Sel items={ this.selitems } cls="web-top-icon" getValue={(e)=> this.sel_fkn(e)} />
            </div>
        </div>
        <div className="mx_content flex">

        <div className="web-col flex-center flex">
            <div className="wnd-btn w250" onClick={() => this.collect_rv() } >
                <div>SAMLA IN BOKNING</div>
                <div></div>
            </div>
            <div className="wnd-btn w250" onClick={() => this.collect_gst() } >
                <div>SAMLA IN GÄST</div>
                <div></div>
            </div>
            <div className="wnd-btn w250" onClick={() => this.collect_row() } >
                <div>SAMLA IN RAD</div>
                <div></div>
            </div>
            <div className="wnd-btn w250" onClick={() => this.move_rows() } >
                <div>HÄMTA IN RADER</div>
                <div></div>
            </div>
        </div>

        </div>
        <div className="mx_foot flex-row flex-space-between">
            <div className="flex-row"></div>
            <button className="btn btn-hw-red w250 ripple" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex-row"></div>
        </div>
      </div>
            { this.state.wnds.rv ?
        <Collect_Rv wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
            : null }
            { this.state.wnds.gst ?
        <Collect_Gst wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
            : null }
            { this.state.wnds.row ?
        <Collect_Row wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
            : null }
            { this.state.wnds.pay ?
        <Collect_Pay wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
            : null }
            { this.state.wnds.moverows ?
        <Move_From_Gst wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
            : null }
    </div>
	;
	return html;
  };
}

export default Collect;