import React, { useState, useEffect, useContext } from 'react';
import * as net from "../../../../lib/net";
import Dd_Sel from "../../../../lib/ui/dd_sel";
import Dt_Cal from "../../../../lib/ui/dt_cal";
import * as utils from "../../../../assets/utils";
import sso from "../../../../sso";
import {format} from "date-fns";

class Pay_Emv extends React.Component {
  constructor(props, context) {
    super(props);

     this.selitems = [
        {fkn: 'setup', text: 'INSTÄLLNINGAR'},
        {fkn: 'upd', text: 'UPPDATERA'},
        {fkn: 'sys', text: 'SYSTEM'},
        {fkn: 'logout', text: 'LOGGA UT'}
    ];

    this.dt_dt = React.createRef();

    this.store = props.store;
    this.state = {};
    this.state.ynq = [];

    this.state.paa = [];
    this.state.aa = [];
    this.state.boknr = "";
    this.state.gso = {};
    this.state.nta = [];
    this.state.belopp = "";
    if(props.wnds) {
        this.state.boknr = props.wnds.boknr;
        this.state.gso = props.wnds.gso;
        this.state.nta = props.wnds.nta;
        this.state.belopp = props.wnds.belopp;
    }

    this.state.today = new Date();
    var dts = format(this.state.today, "yyyy-MM-dd");
    this.state.dts = dts;
    this.state.ok = false;
    this.state.error = false;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      this.pay_emv();
      //this.pay_simulate();
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    body_style() {
      var css = {};
      if(this.state.ok) {
          css.background = "#080";
          css.color = "#fff";
      }
      if(this.state.error) {
          css.background = "#800";
          css.color = "#fff";
      }
      //css.height = window.innerHeight * 0.8;
      return css;
    }

    wnd_close() {
        var self = this;
        if(self.props.wnds.cb) {
            var rsp = {};
            rsp.ok = "999";
            self.props.wnds.cb(self.props.ctx, rsp);
        }
        else self.props.ctx.setState({ wnds: {} });
    };
    tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
    }
  pay_simulate() {
    var self = this;
    var rc = "000";

    //alert(JSON.stringify(this.state.nta));

    setTimeout(function() {

        if(rc != "000") {
            self.setState({error: true});
            setTimeout(function() {
                if(self.props.wnds.cb) {
                    var rsp = {};
                    rsp.ok = "999";
                    self.props.wnds.cb(self.props.ctx, rsp);
                }
                else self.props.ctx.setState({ wnds: {} });
                self.setState({error: false});
            }, 3000);
        }
        if(rc == "000") {
            self.setState({ok: true});
            self.pay_tr("kort", "Kortbetalning");
        }

    }, 2000);

    }
  pay_emv() {
    var self = this;

    var prm = {};
    prm.req = "pms.charge_fkn.charge_emv";
    prm.boknr = this.state.boknr;
    prm.gsb00 = this.state.gso;
    prm.datum = this.state.dts;
    prm.emvnr = sso.seo.emvnr;
    prm.antal = "1";
    prm.payamount = this.state.belopp;
    prm.tkn = "+";
    prm.korr = "0";
    prm.datum = self.state.dts;
    prm.notanr = "";

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            self.setState({error: true});
            setTimeout(function() {
                alert("Betal fel...");
                if(self.props.wnds.cb) {
                    var rsp = {};
                    rsp.ok = "999";
                    self.props.wnds.cb(self.props.ctx, rsp);
                }
                else self.props.ctx.setState({ wnds: {} });
                self.setState({error: false});
            }, 3000);
            return;
        }
        if(ret.rspcode != "000") {
            self.setState({error: true});
            setTimeout(function() {
                if(self.props.wnds.cb) {
                    var rsp = {};
                    rsp.ok = "999";
                    self.props.wnds.cb(self.props.ctx, rsp);
                }
                else self.props.ctx.setState({ wnds: {} });
                self.setState({error: false});
            }, 3000);
            return;
        }
        self.setState({ok: true});
        self.pay_tr("kort", "Kortbetalning");
        //setTimeout(function() {
        //    if(self.props.close) self.props.close(self.props.ctx);
        //    else self.props.ctx.setState({ wnds: {} });
        //}, 3000);
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  pay_tr(betkod, bettext) {
    var self = this;

    var pra = [];
    for(var row of self.state.nta) {
        pra.push(row.etr01);
    }
    if(pra.length < 1) {
        alert("Inga rader");
        return;
    }

    var sdo = {};
    sdo.notanr = "";

    var prm = {};
    prm.req = "pms.charge_fkn.charges_toinv";
    prm.boknr = this.state.boknr;
    prm.gsb00 = this.state.gso;
    prm.datum = this.state.dts;
    prm.payrows = pra;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            alert("Nota fel...");
            return;
        }

        var notanr = ret.notanr;
        var belopp = ret.belopp;
        sdo.notanr = notanr;

        var prm = {};
        prm.req = "pms.sell.charge_pay";
        prm.boknr = self.state.boknr;
        prm.gsb00 = self.state.gso;
        prm.trkod = betkod;
        prm.text = bettext;
        prm.antal = "1";
        prm.pris = belopp;
        prm.tkn = "+";
        prm.moms = "";
        prm.datum = self.state.dts;
        prm.notanr = notanr;
        return net.sio_req(prm);
    })
    .then(function(ret){
        //alert(JSON.stringify(ret));
        if(ret.ok != "000") {
            alert("Betal fel...");
            return;
        }

        var prm = {};
        prm.req = "pms.charge.kvitto_petty";
        prm.boknr = self.state.boknr;
        prm.glopnr = self.state.gso.r0glopnr;
        prm.gso = self.state.gso;
        prm.notanr = sdo.notanr;
        prm.prnnr = sso.seo.prnnr;
        return net.sio_req(prm);
    })
    .then(function(ret){
        //alert(JSON.stringify(ret));
        if(ret.ok != "000") {
            alert("Skriv fel...");
            return;
        }

        if(self.props.wnds.cb) {
            var rsp = {};
            rsp.ok = "000";
            self.props.wnds.cb(self.props.ctx, rsp);
        }
        else self.props.ctx.setState({ wnds: {} });
        //self.charges();
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "";

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="m2_backdrop" /> : null }
        <div className="m2_dlg mx_50 flex-col" style={ this.dlg_style() }>
        <div className="flex-row " style={{padding:"0px",height:"50px",lineHeight:"50px", background: "#1a456b", color: "#fff"}}>
                <div className="flex-row flex" style={{padding:"0px 5px", fontSize: "22px"}}>
                    <div>EMV { this.state.rumsnr }</div>
                </div>
            <div className="">
                <Dd_Sel items={ this.selitems } cls="web-top-icon" getValue={(e)=> this.sel_fkn(e)} />
            </div>
        </div>
        <div className="mx_content flex" style={ this.body_style() }>

            <div className="flex-row py-2">
                <div className="flex"></div>
                <div className="flex-col">
                    EMV BETALNING
                </div>
                <div className="flex"></div>
            </div>

        <div className="web-row flex-center">
            <div className="txt_22" >
                BETALNING PÅ EMV : { sso.seo.emvnr }
            </div>
        </div>

        <div className="flex"></div>
         <div className="flex-col flex-center">
            <div className="txt_22">BELOPP</div>
            <div className="txt_32">{ utils.toPris(this.state.belopp) }</div>
        </div>
        <div className="flex"></div>

        </div>
        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex"></div>
        </div>
      </div>
    </div>
	;
	return html;
  };
}

export default Pay_Emv;