import React, {useState} from 'react';

import * as net from "../../lib/net";
import {observer} from "mobx-react";
import Dd_Sel from "../../lib/ui/dd_sel";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

/*
                 <i className="fa fa-ellipsis-v fa-2x" aria-hidden="true"></i>
                 <MDBIcon icon="bars" size="2x" className="white-text" />
                 <MDBIcon icon="ellipsis-v-alt" size="2x" className="white-text" />
*/

function Cas_Top(props) {
    let [state, setState] = useState({modal: false, ma: []});

    var seo = props.store.seo;

    var selitems = [
        {fkn: 'setup', text: 'INSTÄLLNINGAR'},
        {fkn: 'upd', text: 'UPPDATERA'},
        {fkn: 'sys', text: 'SYSTEM'},
        {fkn: 'logout', text: 'LOGGA UT'}
    ];

    var it = {};
    it.name = "TesTe";
    var app = {};
    app.name = "TesTe";
    var sdo = {};
    sdo.name = "TesTe";
    sdo.user = {};
    sdo.user.text = "TesTe";
    var app = {};
    app.sub_active = "11";
    app.fkn_active = "22";

    var fa = "fa fa-key";
    const [anchorEl, setAnchorEl] = React.useState(null);

      const handleOpen = event => {
        setAnchorEl(event.currentTarget);
      };

      const handleClose = () => {
        setAnchorEl(null);
      };

      const goFull = () => {
        alert("Full");
      };

     function toggle() {
      setState({
        modal: !state.modal
      });
    }
   function fknGo(url) {
    //var sdo = useContext(SDO);
    //alert(fkn);
    //var fkn = row.fid;
    //props.store.fkn = row;

       this.props.navigate(url);
   };
    function logout() {
        var url = "/logout";
        this.props.navigate(url);
    };
      function xlogout() {
        var self = this;
        var xdo = sessionStorage.getItem("svp");
		var sdo = JSON.parse(xdo);

		var prm = {};
        prm.req = "usr:logout";
		prm.uid = sdo.uid;
		prm.token = sdo.token;

		//alert(JSON.stringify(prm));
        net.gcs_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret));

            if(ret.ok != "000") {
                alert(JSON.stringify(ret));
                //return;
            }
            setAnchorEl(null);
            sessionStorage.removeItem("svp");
            props.store.seo = {};

            var url = "/";
            this.props.navigate(url);
        })
        .catch(function(e){
            //var txt = JSON.stringify(e);
            alert("LOGOUT ERR: " + JSON.stringify(e));
            //gda.wndError(txt);
        });
      };
      const mnu_toggle = () => {
		//props.ctx.setState({ mnu_show: !props.ctx.state.mnu_show });
		props.store.cfg.mnu_show = !props.store.cfg.mnu_show;
      };
      const bread_toggle = () => {
		props.store.cfg.bread = !props.store.cfg.bread;
      };
      const mnu_item_css = () => {
      };
      const mnu_sel = () => {
      };
      const login = () => {
      };
      const header_class = () => {
  	    var cls = "flex_col";

		if(false) {
			cls = cls + " web-head_1";
		}
		else cls = cls +  " web-header";


		return cls;
    }
  function fknGo(url) {
    //alert(row.href);
      this.props.navigate(url);
  }
  function sel_fkn(sel) {
    //alert(JSON.stringify(fkn));
      if(sel.fkn == "logout") {
        var url = "/logout";
          this.props.navigate(url);
      }
  }

    var html =
            <div id="header" className={ header_class() }>
            <div className="web-top-content d-flex flex-row">
                <div className="web-top-icon px-3 w60" onClick={() => mnu_toggle() }>
                    <FontAwesomeIcon icon="bars" size="1x"/>
                </div>
                <div className="web-top-mnu d-none d-md-inline-flex flex-column" onClick={() => toggle() }>
                    <div className="item-sys">Kasinosystem</div>
                    <div className="item-fkn">{ props.store.fkn.name }</div>
                </div>
                <div className="flex-fill"></div>
                <div className="web-top-usr d-flex flex-column" onClick={() => fknGo("/hello") }>
                    <div><FontAwesomeIcon icon="building" size="1x"/> { seo.company }</div>
                    <div><FontAwesomeIcon icon="user" size="1x"/> { seo.name }</div>
                </div>
                <div className="web-top-sep"></div>
                <div className="">
                    <Dd_Sel items={ selitems } cls="web-top-icon" getValue={(e)=> sel_fkn(e)} />
                </div>
                <div className="">
                </div>
            </div>

            </div>
    ;
    return html;
}

export default observer(Cas_Top);

/*
        <MDBContainer>
      <MDBModal isOpen={state.modal} fullHeight position="top" keyboard={false} backdrop={true}>
        <MDBModalHeader toggle={() => toggle() }>Kassasystem</MDBModalHeader>
        <MDBModalBody>
  <MDBRow>
    <MDBCol>
    <div className="web-row">
    <div className="web-col flex">
        <div className="web-btn" onClick={() => fknGo("/pos") } >
            <div>KASSA</div>
            <div>Summerad total fsg</div>
        </div>
        <div className="web-btn" onClick={() => fknGo("/pms") } >
            <div>HOTELL</div>
            <div>Fsg per varugrupp</div>
        </div>
        <div className="web-btn" onClick={() => fknGo("/ta") } >
            <div>TIDSYSTEM</div>
            <div>Fsg timme för timme</div>
        </div>
    </div>
     <div className="web-col flex" style={{padding: "0px 10px"}}>
        <div className="web-btn" onClick={() => fknGo("/fakt") } >
            <div>FAKTURA</div>
            <div>Fsg timme för timme</div>
        </div>
        <div className="web-btn" onClick={() => fknGo("/tbv") } >
            <div>BORDSBOKNING</div>
            <div>Veckosammanställning</div>
        </div>
   </div>
     <div className="web-col flex">
        <div className="web-btn" onClick={() => fknGo("/red") } >
            <div>REDOVISNING</div>
            <div>Transaktions flöde</div>
        </div>
        <div className="web-btn" onClick={() => fknGo("/tele") } >
            <div>TELESYSTEM</div>
            <div>Sammanställda artiklar</div>
        </div>
        <div className="web-btn" onClick={() => fknGo("/vip") } >
            <div>LOJALITET</div>
            <div>Sammanställda artiklar</div>
        </div>
   </div>
   </div>
    </MDBCol>
  </MDBRow>
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn color="secondary" onClick={() => toggle()}>Close</MDBBtn>
          <MDBBtn color="primary">Save changes</MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    </MDBContainer>


        <div className="flex flex-row flex-space-between">
            <div className="flex-row flex-vcenter">
                <button className="btn btn-icon" onClick={handleOpen}>
                    <MenuIcon className="white-text" />
                </button>
                <div>Kassa</div>
           </div>

            <button className="btn btn-icon" onClick={handleOpen}>
                <MoreVertIcon className="white-text" />
            </button>
            <Menu id="adm-menu"
                style={{zIndex:"5999"}}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <div className="cdi-hmx">
                <div className="cdi-hmx-header">
                    <h4>You have 3 new notifications</h4>
                </div>
                <div className="cdi-hmx-body">
                    <div className="flex-row">
                    <div className="w50">
                        <MDBIcon icon="bars" size="2x" className="" />
                    </div>
                    <div className="">
                        <p>FULLSKÄRM</p>
                        <span>Helbilds skärm</span>
                    </div>
                    </div>

                    <div className="cdi-hmx-item odd grid">
                    <div className="user_img"><img src="images/avatar1.jpg" alt="" /></div>
                    <div className="notification_desc">
                        <p>Inställningar</p>
                        <span>Uppsättning</span>
                    </div>
                    </div>

                    <div className="cdi-hmx-item grid">
                    <div className="user_img"><img src="images/avatar2.jpg" alt="" /></div>
                    <div className="notification_desc">
                        <p>Support / Hjälp</p>
                        <span>Online hjälp och information</span>
                    </div>
                    </div>
                </div>
                <div className="cdi-hmx-footer" onClick={logout}>
                    Logga ut
                </div>
            </div>
            </Menu>

        </div>

*/