import React, { useState, useEffect, useContext } from 'react';
import {Route, Routes} from "react-router-dom";

import Scr_Home from './media/home.js';
import Scr_Screens from './media/screens.js';
import Scr_Content from './media/content.js';
import Scr_Schedule from './media/schedule.js';
import {withRouter} from "../lib/react/withRouter";
import sso from "../sso";

function pg_home() {

    var html =
        <div className="web-content">
            Hem
        </div>
    ;
    return html;
}

class Dsi_Scr extends React.Component {
    //var sdo = useContext(SDO);
    constructor(props, context) {
        super(props);

        this.webStore = props.store;
        this.state = { pgid: 11, deps: [] };
        //context.posrend = this;
    }

    xxn(row) {
        var html = "";
        if(row.active) html = <li className="">{row.text}</li>;
        else html = <li className=""><a href={row.href}>{row.text}</a></li>;
        return html;
    }
    fknGo(row) {
        //alert(row.href);
        //this.props.xstore.setFkn(row);
        sso.seo.sfkn = row;
        //this.props.store.fkn = row;
        this.props.navigate(row.url);
    }
    br_css(row) {
        var css = {};
        if(sso.seo.sfkn && (row.url == sso.seo.sfkn.url)) css.color = "#080";
        //var brc = false;
        //if(row.url == sbo.state.url) brc = true;
        return css;
    }

    render() {
        var path = window.location.pathname;
        if(path.substr(-1) == "/") path = path.substring(0, path.length - 1);
        var op = path.split("/").pop();
        var po = {};
        po.screens = { "fid": "f03", "url": "/dsi/media/screens", "name": "Skärmar", "active": false };
        po.content = { "fid": "f03", "url": "/dsi/media/content", "name": "Innehåll", "active": false };
        po.schedule = { "fid": "f03", "url": "/dsi/media/schedule", "name": "Schema", "active": false };
        if(po[op]) {
            po[op].active = true;
            //this.props.store.fkn = po[op];
            //this.props.xstore.setFkn(po[op]);
        }
        var pa = Object.values(po);

        return (
            <div className="web-app">
                { this.props.store.cfg.bread ?
                    <div className="web-bread">
                        <div className="breadcrumb">
                            { pa.map((row, key) =>
                                <div key={key} className="breadcrumb-item" style={this.br_css(row)} onClick={() => this.fknGo(row)}>{row.name}</div>
                            )}
                            <div className="flex"></div>
                            <div className="cdigreen">{ this.props.store.fkn ? this.props.store.fkn.name : null }</div>
                        </div>
                    </div>
                    : null }
                <Routes>
                    <Route path="/xx" component={pg_home} />
                    <Route path="screens" element={ <Scr_Screens store={this.webStore} />} />
                    <Route path="content" element={ <Scr_Content store={this.webStore} />} />
                    <Route path="schedule" element={ <Scr_Schedule store={this.webStore} />} />
                    <Route path="*" element={ <Scr_Home store={this.webStore} />} />
                </Routes>
            </div>

        );
    }
}
//Sys.contextType = ADOContext;

export default withRouter(Dsi_Scr);
