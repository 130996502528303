import React, { useState, useEffect, useContext } from 'react';
import ReactDOM from 'react-dom';
import { SDOContext } from '../sdo.js'
import { Carousel } from 'react-bootstrap'
import { Chart } from 'chart.js'

//import './css/style-liberty.css';

import * as net from "../lib/net";

function web01() {

    var html =
    <div className="">
        Test
    </div>
    ;
    return html;
}

class Vip_Dash extends React.Component {
    //var sdo = useContext(SDOContext); // Functional
    static contextType = SDOContext; // Class

    constructor(props, context) {
        super(props);

        this.s1ref = React.createRef();
        this.s2ref = React.createRef();

        this.my_bar = null;
        this.my_pie = null;
        this.my_stack = null;
        this.my_line = null;
        this.webStore = props.store;
        this.state = { pgid: 11, stat: [] };
        this.state.open = false;
        this.state.pdfopen = false;
        this.state.xxopen = false;
        this.state.pdf_uri = "";
        this.state.seldate = new Date('2020-03-24T13:11:54');
        this.state.row = null;
        this.state.info = "";
        this.state.top = 1;
        this.state.user = {};
        this.state.sdo = {};
        //this.state.sdo = context;
        //context.posrend = this;
        this.state.step = 1;
        this.state.pdf = 0;
        this.state.pdf_uri = "";
        this.state.mnus = ["m00", "m01", "m02", "m03", "m08", "m09"];
        this.state.mnu_sel = "m00";

        this.chartColors = {
            navy: '#4755AB',
            green: '#03c895',
            danger: '#ff4f81',
            blue: '#4A496E',
            purple: '#B162AC',
            grey: '#E7EDF6'
        };
    }
    componentDidMount(){
    }

    componentWillUnmount(){
            this.setState({info: "UUU" });
    }
   header_css() {
  	    var css = {};
		if(this.state.top) {
			css.color = "#fff";
			css.background = "transparent";
			css.height = "50px";
			//css["background"] = "green";
		}
		else {
			css.color = "#000";
			css.background = "#fff";
		    css.height = "50px";
        }

		return css;
    }
    header_class() {
  	    var cls = "flex_col";
		if(this.state.top) {
			cls = cls + " web-head_1";
		}
		else cls = cls +  " web-header";

		return cls;
    }

    pie_chart() {
        var config = {
            type: 'pie',
            data: {
                datasets: [{
                    data: [
                        25,
                        38,
                        66,
                        15,
                        90,
                        75,
                    ],
                    backgroundColor: [
                        this.chartColors.navy,
                        this.chartColors.blue,
                        this.chartColors.purple,
                        this.chartColors.danger,
                        this.chartColors.green,
                        this.chartColors.grey,
                    ],
                    label: 'Dataset 1'
                }],
                labels: [
                    'Mat',
                    'Vatten',
                    'Sarköl',
                    'Vin',
                    'Sprit',
                    'Kaffe'
                ]
            },
            options: {
                responsive: true
            }
        };

        var ctx4 = document.getElementById('piechart').getContext('2d');
        this.my_pie = new Chart(ctx4, config);
	};
    stack_chart() {
        this.my_stack =  new Chart(document.getElementById("stackedbar"), {
			type: 'bar',
			data: {
				labels: ['Januari', 'Februari', 'Mars', 'April', 'Maj', 'Juni', 'Juli', 'Augusti', 'September'],
				datasets: [{
					data: [10,20,30,40,50,60,70,80],
					label: 'Kontant',
					backgroundColor: this.chartColors.navy,
					borderWidth: 1,
				}, {
					data: [30,10,70,15,30,20,70,80],
					label: 'Kort',
					backgroundColor: this.chartColors.purple,
					borderWidth: 1,
				}, {
					data: [20,30,40,50,60,70,80,80],
					label: 'Swish',
					backgroundColor: this.chartColors.grey,
				}]
			},
			options: {
				// title: {
				// 	display: true,
				// 	text: 'Chart.js Bar Chart - Stacked'
				// },
				tooltips: {
					mode: 'index',
					intersect: false
				},
				responsive: true,
				scales: {
					x: {
						stacked: true,
					},
					y: {
						stacked: true
					}
				}
			}
		});
	};
    line_chart() {
        this.my_line =  new Chart(document.getElementById("linechart"), {
            type: 'line',
            data: {
				labels: ['Januari', 'Februari', 'Mars', 'April', 'Maj', 'Juni', 'Juli', 'Augusti', 'September'],
                datasets: [{
                    label: 'Snitt per timme',
                    backgroundColor: this.chartColors.navy,
                    borderColor: this.chartColors.navy,
                    data: [30,10,70,15,60,20,70,80],
                    fill: false,
                }, {
                    label: 'Snitt per kvitto',
                    fill: false,
                    backgroundColor: this.chartColors.purple,
                    borderColor: this.chartColors.purple,
                    data: [10,40,20,35,25,50,10,70],
                }]
            },
            options: {
                responsive: true,
                // title: {
                // 	display: true,
                // 	text: 'Chart.js Line Chart'
                // },
                tooltips: {
                    mode: 'index',
                    intersect: false,
                },
                hover: {
                    mode: 'nearest',
                    intersect: true
                },
                scales: {
                    x: {
                        display: true,
                        scaleLabel: {
                            display: true,
                            labelString: 'Month'
                        }
                    },
                    y: {
                        display: true,
                        scaleLabel: {
                            display: true,
                            labelString: 'Value'
                        }
                    }
                }
            }
        });
    };
    bar_chart() {
        this.my_bar =  new Chart(document.getElementById("barchart"), {
            type: 'bar',
            data: {
				labels: ['Januari', 'Februari', 'Mars', 'April', 'Maj', 'Juni', 'Juli', 'Augusti', 'September'],
                datasets: [{
                    data: [10,20,30,40,50,60,70,80],
                    label: 'Inklusive Moms',
                    backgroundColor: "#4755AB",
                    borderWidth: 1,
                }, {
                    data: [30,10,70,15,30,20,70,80],
                    label: 'Exklusive Moms',
                    backgroundColor: "#E7EDF6",
                    borderWidth: 1,
                }]
            },
            options: {
                responsive: true,
                legend: {
                    position: 'top',
                },
            }
        });
	};

    render() {
        var stat = [];
        var keys = Object.keys(this.state.stat);
        for (var key of keys) {
            var row = this.state.stat[key];
            stat.push(row);
        }
        const handleDateChange = date => {
            this.setState({seldate: date });
        };

        return (
            <div className="container-fluid" style={{background: "#eff1f9"}}>

                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb my-breadcrumb">
                        <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                        <li className="breadcrumb-item active" aria-current="page">Dashboard</li>
                    </ol>
                </nav>
                <div className="welcome-msg pt-3 pb-4">
                    <h1>Lojalitets Funktioner</h1>
                    <p></p>
                </div>

            </div>

        );
    }

}
//WebPub.contextType = SDOContext; // Function
export default Vip_Dash;
