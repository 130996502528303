import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";
import {SDOProvider} from "../../sdo";
import Dt_Cal from "../../lib/ui/dt_cal";
import {format, getHours, subDays} from "date-fns";
import Mark_Add from "./mark_add";
import ddo from "../../ddo";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Mark_Ctrl extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, paa: [] };
    this.state.seldate = new Date();
    this.state.markadd = 0;
    this.state.unit = null;
      this.state.una = ddo.cfg.una;
      this.state.uno = ddo.cfg.units;

      this.state.today = new Date();
      var hh = getHours(this.state.today);
      if( hh < 7) {
          const dt = subDays(new Date(), 1);
          this.state.datum = format(dt, "yyyy-MM-dd");
      }
      else this.state.datum = format(this.state.today, "yyyy-MM-dd");
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.regs();
  }
  get_unit(id) {
    var self = this;
    var o = this.state.uno[id];
    if(!o) return id;
    else return o.text;
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 250;
      return css;
  }

  onUnitChange(e) {
        this.setState({unit: e.value});
  }
  change_unit(e) {
    //let cdat = this.state.seldate;
    //alert(JSON.stringify(e));
    let unit = e.target.value;
    this.setState({unit: unit});
    this.setState({tra: [] });
  }

  mark_add(e) {
    //alert(JSON.stringify(e));
    this.setState({ markadd: 1 });
  }
  dt_dat(e) {
    //alert(JSON.stringify(e));
    var dts = format(e, "yyyy-MM-dd");
    this.setState({ datum: dts });
  }
  regs() {
    var self = this;
    var datum = this.state.datum;
    var prm = {};
    prm.req = "pos.pos_cas.mark_regs";
    prm.datum = datum;
    //prm.tdat = datum;

    //gda.wndLoading("Hämtar");
    //net.sio_req(prm)
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            return;
        }
        self.setState({paa: ret.rca });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
    }

  render() {
    return (
    <div className="web-app">
    <h3>Marker Kontroll</h3>

    <div className="flex-row flex-space-between">
        <div className="flex-row">
            <Dt_Cal dts={this.state.datum} getValue={(e)=> this.dt_dat(e)} />
        <select className="form-control sel_input w300" placeholder="Välj enhet" value={this.state.unitid} onChange={(e) => this.change_unit(e)} >
            { this.state.una.map((row, key) =>
            <option key={key} value={row.id}>{row.text}</option>
            )}
        </select>
        </div>
        <div className="flex-row">
         <button className="btn btn-hw-blue w150 ripple" onClick={() => this.mark_add() } aria-label="System" >
            Ny
        </button>
         <button className="btn btn-hw-blue w150 ripple" onClick={() => this.regs() } aria-label="System" >
            Visa
        </button>
        </div>
   </div>
    <div className="divider_orange"></div>

    <div className="web-row">
        <table className="table table-bordered table-hover tb fixed_header">
            <thead>
            <tr>
                <th>Enhet</th>
                <th>Datum</th>
                <th>Pass</th>
                <th>Bord</th>
                <th>ID</th>
                <th>TYP</th>
                <th>V 01</th>
                <th>V 02</th>
                <th>V 03</th>
                <th>V 04</th>
                <th>V 05</th>
                <th>V 06</th>
                <th>Status</th>
            </tr>
            </thead>
            <tbody style={ this.tb_style() }>
            { this.state.paa.map((row, key) =>
            <tr key={key} ng-dblclick="trans_open(row);">
                <td>{ this.get_unit(row.m0enhet) }</td>
                <td>{ row.m0datum }</td>
                <td>{ row.m0pass }</td>
                <td>{ row.m0bord }</td>
                <td>{ row.m0id }</td>
                <td>{ row.m0typ }</td>
                <td>{ row.m0m01 }</td>
                <td>{ row.m0m02 }</td>
                <td>{ row.m0m03 }</td>
                <td>{ row.m0m04 }</td>
                <td>{ row.m0m05 }</td>
                <td>{ row.m0m06 }</td>
                <td>{ row.m0status }</td>
            </tr>
            )}
            </tbody>
        </table>
    </div>

            { this.state.markadd ?
        <Mark_Add show={this.state.markadd} ctx={this} pbo={this.state.pbo} backdrop={true}/>
            : null }
    </div>
  );
}
}
export default Mark_Ctrl;

/*
    <td>{ this.state.uno[row.r0cstplc].text }</td>
*/