import React, { useState, useEffect, useContext } from 'react';
import {Route, Routes} from "react-router-dom";

import Fo_Home from './fo/home.js';
import Fo_Und from './fo/und.js';
import Fo_Kund from './fo/kund.js';
import Fo_Intrsk from './fo/intrsk.js';
import sso from "../sso";
import {withRouter} from "../lib/react/withRouter";

function pg_home() {

    var html =
    <div className="web-content">
      Hem
    </div>
    ;
    return html;
}

class Fakt_Fo extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, deps: [] };
      var po = {};
      po.und = { "fid": "f05", "url": "/fakt/fo/und", "name": "Kassaunderlag", "active": false };
      po.kund = { "fid": "f05", "url": "/fakt/fo/kund", "name": "Kundunderlag", "active": false };
      po.intrsk = { "fid": "f05", "url": "/fakt/fo/intrsk", "name": "Intern Reskontra", "active": false };
      this.state.po = po;
      this.state.pa = [];
      this.state.pa = Object.values(po);
  }
    fknGo(row) {
        sso.seo.sfkn = row;
        this.props.navigate(row.url);
    }
    br_css(row) {
        var css = {};
        if(sso.seo.sfkn && (row.url == sso.seo.sfkn.url)) css.color = "#080";
        //var brc = false;
        //if(row.url == sbo.state.url) brc = true;
        return css;
    }

  render() {
    return (
        <div className="web-app">
            { this.props.store.cfg.bread ?
                <div className="web-bread">
                    <div className="breadcrumb">
                        { this.state.pa.map((row, key) =>
                            <div key={key} className="breadcrumb-item" style={this.br_css(row)} onClick={() => this.fknGo(row)}>{row.name}</div>
                        )}
                        <div className="flex"></div>
                        <div className="cdigreen">{ sso.seo.sfkn ? sso.seo.sfkn.name : null }</div>
                    </div>
                </div>
                : null }
        <Routes>
            <Route path="und" element={ <Fo_Und store={this.webStore} />} />
            <Route path="kund" element={ <Fo_Kund store={this.webStore} />} />
            <Route path="intrsk" element={ <Fo_Intrsk store={this.webStore} />} />
            <Route path="*" element={ <Fo_Home store={this.webStore} />} />
        </Routes>
  </div>
  );
}
}
//Sys.contextType = ADOContext;

export default withRouter(Fakt_Fo);
