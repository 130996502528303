import React, { useState, useEffect, useContext } from 'react';
import * as net from "../../../lib/net";

import PdfRpDlg from "../fkn/pdf_rp_dlg";
import Dt_Cal from "../../../lib/ui/dt_cal";
import {format, subDays} from "date-fns";

class Xrecov_Dlg extends React.Component {
  constructor(props, context) {
    super(props);

    this.dt_fd = React.createRef();
    this.dt_td = React.createRef();

    this.store = props.store;
    this.pos = props.store.pos;
    this.state = {};
    this.state.lsa = [];
    this.state.lso = {};
    //this.state.lro = props.lro;
    this.state.tya = [
                {id: '01', text: 'Summerad'},
                {id: '02', text: 'Dag för dag'}
            ];
    this.state.aa = [];
    this.state.cols = {};

    this.state.today = new Date();
    this.state.favd = "";
    this.state.tavd = "";
    this.state.fdat = "";
    this.state.tdat = "";
    this.state.pdf = 0;
    this.state.pdf_content = null;
    this.state.loading = 0;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.dlg_init();
  }
  dlg_init() {
      var dat = this.state.today;
      dat = subDays(dat, 1);
      var dts = format(dat, "yyyy-MM-dd");
      var state = this.state;
      state.fdat = dts;
      state.tdat = dts;
      state.favd = "01";
      state.tavd = "01";
      state.loading = 0;
      if(this.pos.deps && this.pos.deps.length) {
          state.favd = this.pos.deps[0].avd;
          state.tavd = this.pos.deps[0].avd;
      }
    this.setState(state);
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    tab_style(tabid) {
      var css = {};
      css.display = "none";
      if(this.state.tabval === tabid) css.display = "block";
      return css;
    }
    tab_head(tabid) {
      var css = {};
      css.background = "#fff";
      css.color = "#000";
      if(this.state.tabval === tabid) {
          css.background = "#080";
          css.color = "#fff";
      }
      return css;
    }

  dt_fdat(e) {
    //alert(JSON.stringify(e));
    var dts = format(e, "yyyy-MM-dd");
    this.setState({ fdat: dts });
  }
  dt_tdat(e) {
      //alert(JSON.stringify(e));
    var dts = format(e, "yyyy-MM-dd");
    this.setState({ tdat: dts });
  }
  oneday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_td.current.updateDt(this.state.fdat);
  }
  addday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_fd.current.updateAdd();
    this.dt_td.current.updateAdd();
  }
  subday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_fd.current.updateSub();
    this.dt_td.current.updateSub();
  }
  change_favd(e) {
    //let cdat = this.state.seldate;
    let avd = e.target.value;
    this.setState({favd: avd});
  }
  change_tavd(e) {
    //let cdat = this.state.seldate;
    let avd = e.target.value;
    this.setState({tavd: avd});
  }
  pdf_done (ret) {
    this.setState({loading: 0, pdf: 0, pdf_content: null });
  }

    rp_prn() {
		//this.props.ctx.setState({ mr: false });
        //this.setState({ pdf: 1 });
        //this.bok_prn();
    }

    xrecov() {
        var self = this;

   		var favd = this.state.favd;
   		var tavd = this.state.tavd;
   		var dpa = [];
   		var iavd = favd * 1;
   		var maxavd = tavd * 1;
   		do {
   			var avd = iavd.pad(2);
   			dpa.push( avd );
   			iavd = iavd + 1;
		} while( iavd <= maxavd);

        var prm = {};
     	//prm.req = "pos.pos_rp.bok";
     	prm.req = "pos.pos_trs_wrk.xrecov";
   		prm.fdat = this.state.fdat;
   		prm.tdat = this.state.tdat;
   		//prm.fdat = "20200601";
   		//prm.tdat = "20200612";
   		prm.dpa = dpa;
        //alert(JSON.stringify(prm));

        //gda.wndLoading("Hämtar");
        net.sio_req(prm)
        .then(function(ret){
            alert(JSON.stringify(ret.ok));

            //var uri = "data:application/pdf;base64," + ret.pdf;
            //self.setState({pdf: 1, pdf_content: ret.pdf });
            //self.syncCols("lsTable");
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
            //gda.wndError(txt);
        });
    }
    ackrecov() {
        var self = this;

   		var favd = this.state.favd;
   		var tavd = this.state.tavd;
   		var dpa = [];
   		var iavd = favd * 1;
   		var maxavd = tavd * 1;
   		do {
   			var avd = iavd.pad(2);
   			dpa.push( avd );
   			iavd = iavd + 1;
		} while( iavd <= maxavd);

        var prm = {};
     	//prm.req = "pos.pos_rp.bok";
     	prm.req = "pos.pos_trs_wrk.ackrecov";
   		prm.fdat = this.state.fdat;
   		prm.tdat = this.state.tdat;
   		//prm.fdat = "20200701";
   		//prm.tdat = "20200701";
   		prm.dpa = dpa;
        //alert(JSON.stringify(prm));

        //gda.wndLoading("Hämtar");
        net.sio_req(prm)
        .then(function(ret){
            alert(JSON.stringify(ret.ok));

            //var uri = "data:application/pdf;base64," + ret.pdf;
            //self.setState({pdf: 1, pdf_content: ret.pdf });
            //self.syncCols("lsTable");
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
            //gda.wndError(txt);
        });
    }
    reko() {
        var self = this;
   		var favd = this.state.favd;
   		var tavd = this.state.tavd;
   		var dpa = [];
   		var iavd = favd * 1;
   		var maxavd = tavd * 1;
   		do {
   			var avd = iavd.pad(2);
   			dpa.push( avd );
   			iavd = iavd + 1;
		} while( iavd <= maxavd);

        var prm = {};
     	//prm.req = "pos.pos_rp.bok";
     	prm.req = "pos.pos_red.red_reko_ft";
   		prm.fdat = this.state.fdat;
   		prm.tdat = this.state.tdat;
   		//prm.fdat = "20200601";
   		//prm.tdat = "20200612";
   		prm.dpa = dpa;
        //alert(JSON.stringify(prm));

        //gda.wndLoading("Hämtar");
        net.sio_req(prm)
        .then(function(ret){
            alert(JSON.stringify(ret.ok));

            //var uri = "data:application/pdf;base64," + ret.pdf;
            //self.setState({pdf: 1, pdf_content: ret.pdf });
            //self.syncCols("lsTable");
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
            //gda.wndError(txt);
        });
    }
    per_fix() {
        var self = this;

   		var favd = this.state.favd;
   		var tavd = this.state.tavd;
   		var dpa = [];
   		var iavd = favd * 1;
   		var maxavd = tavd * 1;
   		do {
   			var avd = iavd.pad(2);
   			dpa.push( avd );
   			iavd = iavd + 1;
		} while( iavd <= maxavd);

       var prm = {};
     	//prm.req = "pos.pos_rp.bok";
        prm.req = "pos.pos_red.trs_blank_fix";
   		prm.fdat = this.state.fdat;
   		prm.tdat = this.state.tdat;
   		prm.dpa = dpa;
        //alert(JSON.stringify(prm));

        //gda.wndLoading("Hämtar");
        net.sio_req(prm)
        .then(function(ret){
            alert(JSON.stringify(ret.ok));

            //var uri = "data:application/pdf;base64," + ret.pdf;
            //self.setState({pdf: 1, pdf_content: ret.pdf });
            //self.syncCols("lsTable");
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
            //gda.wndError(txt);
        });
    }
    nto_fix() {
        var self = this;

        var favd = this.state.favd;
        var tavd = this.state.tavd;
        var dpa = [];
        var iavd = favd * 1;
        var maxavd = tavd * 1;
        do {
            var avd = iavd.pad(2);
            dpa.push( avd );
            iavd = iavd + 1;
        } while( iavd <= maxavd);

        var prm = {};
        //prm.req = "pos.pos_rp.bok";
        //prm.req = "pos.pos_red.trs_blank_fix";
        prm.req = "pos.pos_red.trs_nto_fix";
        prm.fdat = this.state.fdat;
        prm.tdat = this.state.tdat;
        prm.dpa = dpa;
        //alert(JSON.stringify(prm));

        //gda.wndLoading("Hämtar");
        net.sio_req(prm)
        .then(function(ret){
            alert(JSON.stringify(ret.ok));

            //var uri = "data:application/pdf;base64," + ret.pdf;
            //self.setState({pdf: 1, pdf_content: ret.pdf });
            //self.syncCols("lsTable");
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
            //gda.wndError(txt);
        });
    }
    err_fix() {
        var self = this;

        var favd = this.state.favd;
        var tavd = this.state.tavd;
        var dpa = [];
        var iavd = favd * 1;
        var maxavd = tavd * 1;
        do {
            var avd = iavd.pad(2);
            dpa.push( avd );
            iavd = iavd + 1;
        } while( iavd <= maxavd);

        var prm = {};
        prm.req = "pos.pos_red.trs_err_fix";
        prm.fdat = this.state.fdat;
        prm.tdat = this.state.tdat;
        prm.dpa = dpa;
        //alert(JSON.stringify(prm));

        //gda.wndLoading("Hämtar");
        net.sio_req(prm)
        .then(function(ret){
            alert(JSON.stringify(ret.ok));

            //var uri = "data:application/pdf;base64," + ret.pdf;
            //self.setState({pdf: 1, pdf_content: ret.pdf });
            //self.syncCols("lsTable");
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
            //gda.wndError(txt);
        });
    }

    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    wnd_close = () => {
		this.props.ctx.setState({ xrecov: false });
	};

  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
    avdrecover() {
        var self = this;

   		var favd = this.state.favd;
   		var tavd = this.state.tavd;
   		var dpa = [];
   		var iavd = favd * 1;
   		var maxavd = tavd * 1;
   		do {
   			var avd = iavd.pad(2);
   			dpa.push( avd );
   			iavd = iavd + 1;
		} while( iavd <= maxavd);

        var prm = {};
     	prm.req = "pos.pos_red.recover_avd";
   		prm.fdat = this.state.fdat;
   		prm.tdat = this.state.tdat;
   		//prm.fdat = "20200601";
   		//prm.tdat = "20200612";
   		prm.dpa = dpa;
        //alert(JSON.stringify(prm));

        //gda.wndLoading("Hämtar");
        net.sio_req(prm)
        .then(function(ret){
            alert(JSON.stringify(ret.ok));

            //var uri = "data:application/pdf;base64," + ret.pdf;
            //self.setState({pdf: 1, pdf_content: ret.pdf });
            //self.syncCols("lsTable");
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
            //gda.wndError(txt);
        });
    }
    tipsrecover() {
        var self = this;

   		var favd = this.state.favd;
   		var tavd = this.state.tavd;
   		var dpa = [];
   		var iavd = favd * 1;
   		var maxavd = tavd * 1;
   		do {
   			var avd = iavd.pad(2);
   			dpa.push( avd );
   			iavd = iavd + 1;
		} while( iavd <= maxavd);

        var prm = {};
     	prm.req = "pos.pos_red.recover_tips";
   		prm.fdat = this.state.fdat;
   		prm.tdat = this.state.tdat;
   		prm.dpa = dpa;
        //alert(JSON.stringify(prm));

        //gda.wndLoading("Hämtar");
        net.sio_req(prm)
        .then(function(ret){
            alert(JSON.stringify(ret.ok));

            //var uri = "data:application/pdf;base64," + ret.pdf;
            //self.setState({pdf: 1, pdf_content: ret.pdf });
            //self.syncCols("lsTable");
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
            //gda.wndError(txt);
        });
    }
    split_term_avd() {
        var self = this;

        var prm = {};
        prm.req = "pos.pos_red.split_term_avd";
        prm.fdat = this.state.fdat;
        prm.tdat = this.state.tdat;
        //alert(JSON.stringify(prm));

        net.sio_req(prm)
        .then(function(ret){
            alert(JSON.stringify(ret.ok));
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }

  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "TITEL";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="mx_backdrop" /> : null }
        <div className="mx_dlg mx_70 flex-col" style={ this.dlg_style() }>
        <div className="mx_head mx_bg flex-row flex-space-between">
            <div className="flex-col flex">
                <div>RECOVERY</div>
            </div>
             <div className="white-icon-close" onClick={() => this.handleClose() }>X</div>
       </div>
        <div className="mx_content flex">
            <div className="flex-row px-2">
                    <div className="flex-col flex">
                        <div>Start</div>
                    </div>
                    <div className="flex-col flex">
                        <div></div>
                    </div>
                    <div className="flex-col w50">
                        <div>Ändra</div>
                    </div>
            </div>
            <div className="divider_orange"></div>

        <div className="mx_body flex">

            <div className="flex-row">
              <div className="flex-col">
                  <span>&nbsp;</span>
                  <div className="dt-btn-01" onClick={() => this.subday() } >-</div>
            </div>
              <div className="flex-col">
                  <span>Från</span>
                  <Dt_Cal startdatum="2020-02-01" rp="1" ref={this.dt_fd} getValue={(e)=> this.dt_fdat(e)} />
            </div>
               <div className="flex-col">
                  <span>&nbsp;</span>
                  <div className="dt-btn-01" onClick={() => this.oneday() } >1</div>
            </div>
             <div className="flex-col">
                  <span>Till</span>
                  <Dt_Cal startdatum="2020-02-01" rp="1" ref={this.dt_td} getValue={(e)=> this.dt_tdat(e)} />
            </div>
              <div className="flex-col">
                  <span>&nbsp;</span>
                  <div className="dt-btn-01" onClick={() => this.addday() } >+</div>
            </div>
            </div>

            <div className="flex-row">
              <div className="flex-col">
                  <span>Från</span>
        <select className="form-control input-rp-01 w250" placeholder="Från avdelning" onChange={(e) => this.change_favd(e)}>
            { this.pos.deps.map((row, key) =>
            <option key={key} value={row.avd}>{row.avd} - {row.text}</option>
            )}
        </select>
            </div>
              <div className="flex-col">
                  <span>Till</span>
        <select className="form-control input-rp-01 w250" placeholder="Till avdelning" onChange={(e) => this.change_tavd(e)}>
            { this.pos.deps.map((row, key) =>
            <option key={key} value={row.avd}>{row.avd} - {row.text}</option>
            )}
        </select>
            </div>
            </div>

        </div>
        <div className="divider_blue"></div>
        <div className="flex-row">
            <div className="web-link txt_16 px-2" onClick={() => this.avdrecover() }>Avd recover</div>
            <div className="web-link txt_16 px-2" onClick={() => this.nto_fix() }>Nota balansering</div>
            <div className="web-link txt_16 px-2" onClick={() => this.tipsrecover() }>Tips recover</div>
            <div className="flex"></div>
            <div className="web-link txt_16 px-2" onClick={() => this.err_fix() }>QR Fix</div>
            <div className="web-link txt_16 px-2" onClick={() => this.split_term_avd() }>Split per Terminal</div>
        </div>

        <div className="divider_green"></div>
        <div className="flex-row h100">
            <div className="flex-row flex">
                <div className="web-btn w200" onClick={() => this.reko() } >
                    <div>REKO</div>
                    <div>Grandtotal recover</div>
                </div>
                <div className="web-btn w200" onClick={() => this.per_fix() } >
                    <div>PERIOD BALANSERING</div>
                    <div>Hantera blanka notanr, balansera dagar</div>
                </div>
            </div>
            <div className="flex-row">
                <div className="web-btn w200" onClick={() => this.xrecov() } >
                    <div>XRECOV</div>
                    <div>Recover av X filer</div>
                </div>
                <div className="web-btn w200" onClick={() => this.ackrecov() } >
                    <div>ACKRECOV</div>
                    <div>Recover för ackumulatorer</div>
                </div>
            </div>

        </div>
        </div>
        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w150" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <button className="btn btn-hw-red w150" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
        </div>
      </div>
        <PdfRpDlg show={this.state.pdf} ctx={this} backdrop={true}/>
    </div>
	;
	return html;
  };
}

export default Xrecov_Dlg;