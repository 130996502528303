import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";
import {format} from "date-fns";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Pass_Week extends React.Component {
  constructor(props, context) {
    super(props);

    this.store = props.store;
    //this.ta = props.store.ta;

    this.state = {};
    this.state.avd = "01";
    this.state.dta = [];
    this.state.eaa = [];
    this.state.coa = [];
    this.state.pta = [];
    this.state.dth = {};
    this.state.kro = {};
    this.state.aa = [];
    this.state.cols = {};

      this.state.today = new Date();
      var dts = format(this.state.today, "yyyy-MM-dd");
      //var tdat = addDays(this.state.today, 15);
      this.state.fdat = format(this.state.today, "yyyy-MM-01");
      this.state.tdat = format(this.state.today, "yyyy-MM-dd");
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.sp_init();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  ee_dt_class() {
      var cls = "";
      return cls;
  }
  pass_style() {
		//{background: '#'+fam00.f0kod.substring(2);}
		var so = {};
		so.background = "#eaeefe";
		so.border = "1px solid #ccc";
		so.margin = "10px 0px";
		so['textAlign'] = "center";
		so['borderTop'] = "1px solid #000";
		so.width = "40px";
		so.height = "80px";
		if(this.state.pass_xp) {
			so.width = "120px";
		}
		return so;
	};
	co_style(row) {
		//{background: '#'+fam00.f0kod.substring(2);}
		var so = {};
		so.background = "#" + row.bg;
		so.color = "#000000";
		so.border = "1px solid #ccc";
		so.height = "80px";
		return so;
	};
	dt_style(eo, dto) {
		//{background: '#'+fam00.f0kod.substring(2);}
		var so = {};
		so.background = "#ffffff";
		so.color = "#000000";
		so.border = "1px solid #ccc";
		if(eo.dio[dto.datum]) { so.background = "#" + eo.dio[dto.datum].bg; so.color = "#ffffff"; }
		//if(eo.status == "R") { so.background = "#880000"; so.color = "#ffffff"; }
		//if(eo.status == "G") { so.background = "#008800"; so.color = "#ffffff"; }
		//if(eo.status == "B") { so.background = "#000088"; so.color = "#ffffff"; }
		//if(eo.status == "O") { so.background = "#ff9900"; so.color = "#ffffff"; }
		//if(fam00.f0knapp == $scope.hoverbtn) so.background = "#ff9900";
		if((dto.datum == this.state.hoverdt) && (eo.eid == this.state.hoverid)) so.background = "#ff9900";
		return so;
	};

  onUnitChange(e) {
        this.setState({unit: e.value});
  }

  kund_open(row) {
      var self = this;
  }
  kund_edit(row) {
      var self = this;
      //alert(JSON.stringify(row.krr00));
        this.setState({edit: 1, kro: row});
  }
  fkn_close() {
    this.setState({edit: false });
    //this.props.history.push("cas");
    //this.login_srv(uid, pwd);
  }

  co_add() {
    var self = this;
  }

  sp_init() {
    var self = this;
    var prm = {};
    prm.fkn = "8P12";
    //prm.req = "kreg.kreg_wrk.kreg_list";
    prm.fdat = this.state.fdat;
    prm.tdat = this.state.tdat;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        self.setState({dta: ret.dta, eaa: ret.rca });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

  render() {
    return (
    <div className="web-app">

    <div className="flex-row flex-space-between">
        <div className="label_h03">VECKO PASS</div>
        <select className="form-control sel_input" placeholder="Välj avdelning">
            { this.state.aa.map((row, key) =>
            <option key={key} value={row.id}>{row.text}</option>
            )}
        </select>
        <button className="btn btn-hw-blue w150" onClick={() => this.syncCols("kidTable") } aria-label="System" >
            Visa
        </button>
    </div>
    <div className="divider_orange"></div>

    <div className="web-col">

        <div className="row">
            <div onClick={ this.co_add()} style={{ margin:"10px 0px", width:"20px", textAlign:"center", borderTop:"1px #000 solid" }}>
            { this.state.coa.map((row, key) =>
                <div key={key} ui-draggable="true" drag="row" drag-channel="A" drop-channel="A"
                     style={ this.co_style(row) } title={ row.text }>
                </div>
            )}
            </div>
            <div className="row flex">
                <table className="table table-bordered table-striped tb_pres">
                        <thead>
                        <tr>
                            <th rowSpan="2" style={{textAlign: "center"}}>Vecka</th>
                            { (this.state.dth.wday*1) > 0 ? <th colSpan={ (7 - ( this.state.dth.wday*1)) }>v.{ this.state.this.state.dth.week }</th> : null }
                            { this.state.dta.map((dto, key) =>
                                <th key={key}>
                                { dto.wday == '0' ? <div>v.{ dto.week }</div> : null }
                                </th>
                            )}
                            <th style={{ textAlign: "center"}}></th>
                        </tr>
                        <tr>
                            { this.state.dta.map((dto, key) =>
                                <th key={key}>{ dto.dt }</th>
                            )}
                            <th style={{ textAlign: "center"}}></th>
                        </tr>
                        <tr>
                            <th style={{textAlign: "center"}}>Text</th>
                            { this.state.dta.map((dto, key) =>
                                <th key={key}>{ dto.shortday }</th>
                            )}
                            <th style={{textAlign: "center" }}>Summa</th>
                        </tr>
                        </thead>
                        <tbody>
                        { this.state.eaa.map((eo, key) =>
                        <tr key={key}>
                            <td className='header w250 text-center' ui-draggable="true" drag-channel="R"
                                drop-channel="R" drag="eo" ui-on-drop="onEEDrop(eo, $data)"
                                onDoubleClick={ this.ee_sel(eo)}>{ this.get_eid(eo) }</td>
                            { this.state.dta.map((dto, key) =>
                            <td className={ this.ee_dt_class(dto) }
                                style={ this.dt_style(eo, dto) } onDoubleClick={ this.sp_sel(eo, dto) }
                                ui-draggable="true" drag="eo.dpo[dto.datum]" drag-channel="A" drop-channel="A"
                                ui-on-drop="onDtDrop(dto, eo, $data)" on-drag-enter="onDtEnter(dto, eo)"
                                on-drag-leave="onDtLeave(dto, eo)">
                                { eo.dto[dto.datum] }
                            </td>
                            )}
                            <td className='header text-center' style={{ textAlign: "center" }}>{ eo.tot }</td>
                        </tr>
                        )}
                        </tbody>
                        <tfoot>
                        <tr>
                            <td className='header'>Timmar</td>
                            { this.state.dta.map((dto, key) =>
                            <td key={key} className={ this.ee_dt_class(dto) }>
                                { dto.tot }
                            </td>
                            )}
                            <td className='header'>{ this.state.tot }</td>
                        </tr>
                        </tfoot>
                    </table>
            </div>
            <div className="column">
            <div style={ this.pass_style() }>
                    <div ng-click="pass_toggle();"
                         style={{ height: "40px", lineHeight: "40px", borderBottom: "1px #000 solid" }}>
                        <FontAwesomeIcon icon="trash" size="1x" />
                    </div>
                    <div className="">

                        { this.state.pta.map((row, key) =>
                        <div key={key} style={{ borderBottom: "1px #ccc solid", borderRight: "1px #ccc solid" }}>
                            <div style={{ height: "35px", lineHeight: "35px" }}>
                                { row.ptyp }
                            </div>
                        </div>
                        )}
                    </div>
            </div>
            </div>

        </div>

    </div>
   </div>
  );
    }
}
export default Pass_Week;
