import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";

import Fsg_Dlg from "./rps/fsg_dlg";
import Bok_Dlg from "./rps/bok_dlg";
import Ater_Dlg from "./rps/ater_dlg";
import Jr_Dlg from "./rps/jr_dlg";
import Oms_Dlg from "./rps/oms_dlg";
import HH_Dlg from "./rps/hh_dlg";
import Tp_Dlg from "./rps/tp_dlg";
import StatFsg_Dlg from "./rps/statfsg_dlg";
import StatBet_Dlg from "./rps/statbet_dlg";
import Cr_Dlg from "./rps/cr_dlg";
import Cred_Dlg from "./rps/cred_dlg";
import Tips_Dlg from "./rps/tips_dlg";
import Term_Dlg from "./rps/term_dlg";
import {withRouter} from "../../lib/react/withRouter";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class CCS_Rps extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.pos = props.store.pos;
    this.state = { pgid: 11, tra: [] };
    this.state.seldate = new Date();
    this.state.avd = "01";
    this.state.paa = [];
    this.state.aa = [];
    this.state.pdf = 0;
    this.state.rptyp = "";
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

  fknGo(url) {
    //alert(row.href);
    this.props.navigate(url);
  }
  rp_open(typ) {
    var self = this;
    this.setState({ rptyp: typ });
  }

  render() {
    return (
    <div className="web-p00 flex flex-col">
        <div className="">
            RAPPORTER
        </div>

    <div className="web-p01 flex">

    <div className="web-row">
    <div className="web-col flex">
        <div className="web-btn" onClick={() => this.rp_open("fsg") } >
            <div>FÖRSÄLJNINGSRAPPORT</div>
            <div>Daglig och summerad varugruppsrapport</div>
        </div>
        <div className="web-btn" onClick={() => this.rp_open("bok") } >
            <div>BOKFÖRINGSRAPPORT</div>
            <div>Daglig och summerad kontorapport</div>
        </div>
        <div className="web-btn" onClick={() => this.rp_open("ater") } >
            <div>ÅTERKÖPSRAPPORT</div>
            <div>Dagliga återköp</div>
        </div>
        <div className="web-btn" onClick={() => this.rp_open("jr") } >
            <div>JOURNALRAPPORT</div>
            <div>Periodens journal</div>
        </div>
    </div>
     <div className="web-col flex" style={{padding: "0px 10px"}}>
        <div className="web-btn" onClick={() => this.rp_open("oms") } >
            <div>OMSÄTTNINGSRAPPORT</div>
            <div>Daglig och summerad varugruppsrapport</div>
        </div>
        <div className="web-btn" onClick={() => this.rp_open("hh") } >
            <div>TIMFSGRAPPORT</div>
            <div>Timfsg över perioden</div>
        </div>
        <div className="web-btn" onClick={() => this.rp_open("tp") } >
            <div>TIDPERIODSRAPPORT</div>
            <div>Specifik period dag för dag</div>
        </div>
        <div className="web-btn" onClick={() => this.rp_open("statfsg") } >
            <div>FSG STATISTIK</div>
            <div>Statistik för perioden</div>
        </div>
        <div className="web-btn" onClick={() => this.rp_open("statbet") } >
            <div>BETALSTATISTIK</div>
            <div>Statistik för perioden</div>
        </div>
   </div>
     <div className="web-col flex">
        <div className="web-btn" onClick={() => this.rp_open("cr") } >
            <div>KASSARAPPORT</div>
            <div>Kassarapport för kassör</div>
        </div>
        <div className="web-btn" onClick={() => this.rp_open("cred") } >
            <div>KASSÖRSRAPPORT</div>
            <div>Sammanställd kassörs rapport för perioden</div>
        </div>
        <div className="web-btn" onClick={() => this.rp_open("tips") } >
            <div>TIPSRAPPORT</div>
            <div>Sammanställd tips rapport för perioden</div>
        </div>
        <div className="web-btn" onClick={() => this.rp_open("term") } >
            <div>TERMINALRAPPORT</div>
            <div>Fsg per terminal för perioden</div>
        </div>
   </div>
   </div>

    </div>

        { this.state.rptyp == "fsg" ? <Fsg_Dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "bok" ? <Bok_Dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "ater" ? <Ater_Dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "jr" ? <Jr_Dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "oms" ? <Oms_Dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "hh" ? <HH_Dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "tp" ? <Tp_Dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "statfsg" ? <StatFsg_Dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "statbet" ? <StatBet_Dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "cr" ? <Cr_Dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "cred" ? <Cred_Dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "tips" ? <Tips_Dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "term" ? <Term_Dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
    </div>
  );
    }
}
export default withRouter(CCS_Rps);
