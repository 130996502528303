import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";
import Td_Sel from "../../lib/ui/td_sel";

import Kid_Edit from "./kreg/kid_edit";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class PMS_Kreg extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, tra: [] };
    this.state.seldate = new Date();
    this.state.avd = "01";
    this.state.kaa = [];
    this.state.kro = {};
    this.state.ta = [
                {id: '01', text: 'FÖRETAG'},
                {id: '02', text: 'AVDELNING'},
                {id: '03', text: 'BOKARE'},
                {id: '04', text: 'GÄSTER'},
                {id: '05', text: 'PRIVAT KUNDER'}
            ];
    this.state.wnds = {};

    this.selitems = [
        {fkn: 'open', text: 'ÖPPNA'}
    ];
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //alert("KID");
    this.knd();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      css.overflow = "auto";
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

  kund_open(row) {
      var self = this;
  }
  kund_edit(row) {
    var self = this;
    var wnds = {};
    wnds.edit = 1;
    wnds.kro = row;
    this.setState({ wnds: wnds });
  }

  knd() {
    var self = this;
    var prm = {};
    prm.req = "red.red_knd.kund_list";
    prm.sz = "";
    //prm.avd = this.state.avd;

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        self.setState({kaa: ret.rca }, function () {
            self.syncTb("kidTable");
        });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e) {
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }
  kreg() {
    var self = this;
    var prm = {};
    prm.req = "kreg.kreg_wrk.kreg_list";
    prm.sz = "";
    //prm.avd = this.state.avd;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        self.setState({kaa: ret.rco }, function () {
            self.syncTb("kidTable");
        });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e) {
        var txt = JSON.stringify(e);
    });
  }
  syncTb(tb){
    if (!document.getElementById(tb)) {
        //alert("No tb");
        return;
    }
    var tbel = document.getElementById(tb);
    const thElements = tbel.getElementsByTagName('th'),
    tdElements = tbel.getElementsByTagName('td');

    for (let i = 0; i < thElements.length; i++) {
        if(!tdElements[i]) continue;
        const widerElement =
                thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
        width = window.getComputedStyle(widerElement).width;
        thElements[i].style.width = tdElements[i].style.width = width;
    }
}
  sel_row_fkn(sel, e) {
    //alert(JSON.stringify(fkn));
      if(sel.fkn == "logout") {
      }
  }
  lists() {
  }

  render() {
    return (
    <div className="web-app">

    <div className="flex-row">
        <select className="form-control sel_input" placeholder="Välj typ">
            { this.state.ta.map((row, key) =>
            <option key={key} value={row.id}>{row.text}</option>
            )}
        </select>
        <div className="flex"></div>
        <button className="btn btn-hw-blue w150 hide" onClick={() => this.lists() } aria-label="System" >
            LISTOR
        </button>
        <button className="btn btn-hw-blue w150" onClick={() => this.knd() } aria-label="System" >
            UPPDATERA
        </button>
    </div>
    <div className="divider_orange"></div>

    <div className="web-col">
        <div id="kidTable">
        <table className="table table-bordered table-hover tb " style={{marginBottom: "0px"}}>
            <thead>
            <tr>
                <th>KID</th>
                <th>Företag</th>
                <th>Namn</th>
                <th>Orgnr</th>
                <th>Telefon</th>
                <th>Mobil</th>
                <th>Epost</th>
                <th>Villkor</th>
                <th></th>
            </tr>
            </thead>
            </table>
            <div style={ this.tb_style() }>
          <table className="table table-bordered table-hover">
            <tbody>
            { this.state.kaa.map((row, key) =>
            <tr key={key} onDoubleClick={() => this.kund_edit(row) }>
                <td>{ row.knd00.k0kundnr }</td>
                <td>{ row.knd00.k0namn }</td>
                <td>{ row.knd04.k4company }</td>
                <td>{ row.knd00.orgnr }</td>
                <td>{ row.knd00.tfn }</td>
                <td>{ row.knd00.mobil }</td>
                <td>{ row.knd00.email }</td>
                <td>{ row.knd00.betvillkor }</td>
                <td>
                    <Td_Sel items={ this.selitems } getValue={(e)=> this.sel_row_fkn(row, e)} />
                </td>
            </tr>
            )}
            </tbody>
        </table>
        </div>
        </div>
    </div>
    { this.state.wnds.edit ?
        <Kid_Edit wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
    : null }
   </div>
  );
    }
}
export default PMS_Kreg;

/*
       <table id="kidTable" className="table table-bordered table-hover tb">
            <tbody>
            { this.state.kaa.map((row, key) =>
            <tr key={key} id={ row.krr00.kid } onDoubleClick={() => this.kund_edit(row) }>
                <td>{ row.krr00.kid }</td>
                <td>{ row.krr00.company }</td>
                <td>{ row.krr00.namn }</td>
                <td>{ row.krr02.orgnr }</td>
                <td>{ row.krr03.tfn }</td>
                <td>{ row.krr03.mobil }</td>
                <td>{ row.krr03.email }</td>
                <td>{ row.krr00.betvillkor }</td>
                <td style={{width:"40px",cursor:"pointer"}} onClick={() => this.kund_edit(row) }><i className="fa fa-cog text-danger"></i></td>
            </tr>
            )}
            <tr style={{visibility: "hidden"}}>
                <th>KID</th>
                <th>Företag</th>
                <th>Namn</th>
                <th>Orgnr</th>
                <th>Telefon</th>
                <th>Mobil</th>
                <th>Epost</th>
                <th>Villkor</th>
                <th></th>
            </tr>
            </tbody>
        </table>

*/