import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";
import ddo from "../../ddo";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Pos_Pt extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = {};
    this.state.seldate = new Date();
    this.state.avd = ddo.cache.avd;
    this.state.pta = [];
    this.state.pte = [];
    this.state.aa = [];
    //context.posrend = this;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.pts();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 480;
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

  plu_open(row) {
      var self = this;
  }
  plu_edit(row) {
      var self = this;
      alert(JSON.stringify(row.plu00));
  }

  pts() {
    var self = this;
    var prm = {};
    prm.req = "pos.pos_pt.pta";
    prm.avd = ddo.cache.avd;
    //prm.fplu = datum;
    //prm.tplu = datum;

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        var o = {};
        //o.deps = ret.rco;
        //alert(JSON.stringify(o));
        self.setState({ pta: ret.rco.pta });
        self.setState({ pte: ret.rco.pte });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }

  render() {
    return (
    <div className="web-app">
    <h3>Pristabell</h3>

    <div className="flex-row">
        <button className="btn btn-hw-blue w150 ripple" onClick={() => this.plus() } aria-label="System" >
            Visa
        </button>
    </div>
    <div className="divider">
    </div>

    <div className="web-row">
        <table className="table table-bordered table-hover tb fixed_header">
            <thead>
            <tr>
                <th>ID</th>
                <th>Från</th>
                <th>Till</th>
                <th>Pristabell</th>
                <th>Upd</th>
                <th>Sign</th>
                <th style={{width:"40px"}} ></th>
            </tr>
            </thead>
            <tbody style={ this.tb_style() }>
            { this.state.pte.map((row, key) =>
            <tr key={key} id={ row.event } onDoubleClick={() => this.plu_open(row) }>
                <td>{ row.event }</td>
                <td>{ row.text }</td>
                <td>{ row.pt }</td>
                <td>{ row.period.typ }</td>
                <td>{ row.period.fdat }</td>
                <td>{ row.period.tdat }</td>
                <td>{ row.status }</td>
                <td style={{width:"40px",cursor:"pointer"}} onClick={() => this.plu_edit(row) }><i className="fa fa-cog text-danger"></i></td>
            </tr>
            )}
            </tbody>
        </table>
    </div>
    <div className="web-row">
        <table className="table table-bordered table-hover tb fixed_header">
            <thead>
            <tr>
                <th>ID</th>
                <th>Text</th>
                <th>Info</th>
                <th>Antal</th>
                <th>Upd</th>
                <th>Sign</th>
                <th style={{width:"40px"}} ></th>
            </tr>
            </thead>
            <tbody style={ this.tb_style() }>
            { this.state.pta.map((row, key) =>
            <tr key={key} id={ row.pt } onDoubleClick={() => this.plu_open(row) }>
                <td>{ row.pt }</td>
                <td>{ row.text }</td>
                <td>{ row.nrp }</td>
                <td>{ row.status }</td>
                <td>{ row.nrp }</td>
                <td>{ row.upd }</td>
                <td>{ row.sign }</td>
                <td style={{width:"40px",cursor:"pointer"}} onClick={() => this.plu_edit(row) }><i className="fa fa-cog text-danger"></i></td>
            </tr>
            )}
            </tbody>
        </table>
    </div>

    </div>
  );
    }
}
export default Pos_Pt;
