import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";
import * as utils from "../../assets/utils";
import Dt_Cal from "../../lib/ui/dt_cal";
import Dd_Sel from "../../lib/ui/dd_sel";
import * as q from "../../lib/defer";

import PdfDlg from "../../lib/pdf/pdf_dlg";
import Vip_Ladda from "./fkn/vip_ladda";
import Vip_Edit from "./fkn/vip_edit";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Fo_Vipreg extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, tra: [] };
    this.state.seldate = new Date();
    this.state.typ = "01";
    this.state.datum = "20201231";

    this.state.rga = [];
    this.state.rda = [];
    this.state.aa = [];
    this.state.kta = [];
    this.state.pdf = 0;
    this.state.pdf_content = null;
    this.state.loading = 0;
    this.state.wnds = {};
    this.state.vpo = {};

    this.state.loading = 0;
    this.state.urval = 1;
    this.state.uaa = [
        {id: '1', text: 'MED SALDO'},
        {id: '0', text: 'ALLA'}
    ];
    this.state.fknitems = [
        {fkn: 'fkn_active', text: 'AKTIVA'},
        {fkn: 'fkn_inactive', text: 'INAKTIVA'},
        {fkn: 'prn_saldo', text: 'KORTSALDO'}
    ];
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    var self = this;
    self.kts()
    .then(function(ret) {
        self.reg_list();
    });
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 250;
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

  fltr(aa) {
      var self = this;
      //self.setState({loading: 0 });
      //var aa = self.state.rga;
      var a = [];
      for(var row of aa) {
          if(this.state.urval == 1) {
              var saldo = (row.vip02.v2tillgodo * 1);
              if(saldo == 0) continue;
              var text = (row.vip00.v0company);
              //if(text.indexOf("A") < 0) continue;
          }
          a.push( row );
      }
      return a;
    //self.setState({rda: a, loading: 0 });
  }

  ladda() {
    var self = this;
    var wnds = {};
    wnds.vipladda = 1;
    self.setState({ wnds: wnds });
  }
  vip_edit(vpo) {
    var self = this;
    var wnds = {};
    wnds.vipedit = 1;
    self.setState({wnds: wnds, vpo: vpo });
  }

  ladda_do() {
    var self = this;
    var prm = {};
    prm.req = "vcm.pres.refill";
    prm.typ = this.state.typ;
    prm.id = "12";
    prm.belopp = "4500";

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        alert(JSON.stringify(ret));
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }
    vip_gen() {
        var self = this;
        var prm = {};
        prm.req = "vip.vip_reg.vip_gen";
        prm.viptyp = this.state.typ;

        net.sio_req(prm)
        .then(function(ret){
            alert(JSON.stringify(ret));
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }
  reg_list() {
    var self = this;
    self.setState({rga: [], loading: 1 });

    var prm = {};
    prm.req = "vip.vip_reg.reg_list";
    prm.viptyp = this.state.typ;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        var a = self.fltr(ret.rca);
        self.setState({rga: ret.rca, rda: a, loading: 0 });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  kts() {
    var self = this;
    var defer = q.defer();

    var prm = {};
    prm.req = "vip.vip_reg.kt_list";
    prm.typ = "";

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        var kta = [];
        for(var row of ret.rca) {
            var o = {};
            o.id = row.vip00.v0ktyp;
            o.text = row.vip00.v0company;
            kta.push( o );
        }
        //alert(JSON.stringify(kta));
        self.setState({kta: kta }, function() {
            var rsp = {};
            rsp.ok = "000";
            defer.resolve(rsp);
        });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        var rsp = {};
        rsp.ok = "999";
        defer.resolve(rsp);
    });

    return defer.promise;
  }
  prn_saldo() {
    var self = this;
    var prm = {};

    prm.req = "vip.vip_reg.reg_list";
    prm.typ = this.state.typ;
    prm.datum = this.state.datum;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        //self.setState({rga: ret.rca });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  prn_fkn(fkn) {
    var self = this;
    var prm = {};
    prm.req = fkn;
    prm.typ = this.state.typ;
    prm.viptyp = this.state.typ;
    prm.datum = "20201231";

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        self.setState({pdf: 1, pdf_content: ret.pdf });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  pdf_done (ret) {
    this.setState({loading: 0, pdf: 0, pdf_content: null });
  }
    sel_fkn(sel) {
        //alert(JSON.stringify(fkn));
        if(sel.fkn == "fkn_active") {
            this.prn_fkn("vip.vip_rp.rp_aktiva");
        }
        if(sel.fkn == "fkn_inactive") {
            this.prn_fkn("vip.vip_rp.rp_inaktiva");
        }
        if(sel.fkn == "prn_saldo") {
            this.prn_fkn("vip.vip_rp.rp_kortsaldo");
        }
    }
    sel_typ (e) {
      var self = this;
      var typ = e.target.value;
      this.setState({typ: typ}, function() {
            self.reg_list();
        });
    }
    sel_urval (e) {
      var self = this;
      self.setState({loading: 1 });
      var urval = e.target.value;
      this.setState({urval: urval}, function() {
          var a = self.fltr(self.state.rga);
        self.setState({rda: a, loading: 0 });
      });
    }

  render() {
    return (
    <div className="web-p00 flex flex-col">
        <div className="">
            LOJALITETS REGISTER
        </div>
    <div className="divider_orange"></div>

    <div className="flex-row">
        <select className="form-control w250" value={this.state.typ} placeholder="Välj typ" onChange={(e) => this.sel_typ(e)}>
            { this.state.kta.map((row, key) =>
            <option key={key} value={row.id}>{row.id} - { utils.toLang(row.text) }</option>
            )}
        </select>
        <select className="form-control w150" value={this.state.urval} placeholder="Välj Urval" onChange={(e) => this.sel_urval(e)}>
            { this.state.uaa.map((row, key) =>
            <option key={key} value={row.id}>{row.text}</option>
            )}
        </select>
        <div className="flex"></div>

        <button className="btn btn-hw-blue w150 ripple" onClick={() => this.vip_gen() } aria-label="System" >
            GEN
        </button>
        <button className="btn btn-hw-blue w150 ripple" onClick={() => this.ladda() } aria-label="System" >
            LADDA
        </button>
        <button className="btn btn-hw-blue w150 ripple" onClick={() => this.reg_list() } aria-label="System" >
            VISA
        </button>
        <div className="">
            <Dd_Sel items={ this.state.fknitems } getValue={(e)=> this.sel_fkn(e)} />
        </div>
    </div>
    <div className="divider_green"></div>

    { this.state.loading ?
        <div className="mx_content flex align-items-center justify-content-center"><FontAwesomeIcon icon="spinner" spin size="3x" /></div>
    :
    <div className="web-row">
        <table className="table table-bordered table-hover tb fixed_header">
            <thead>
            <tr>
				<th>ID</th>
				<th>Företag</th>
				<th>Namn</th>
				<th>Utg</th>
				<th>Kortnr</th>
				<th>Rabatt</th>
				<th>Tillgodo</th>
				<th>Kredit</th>
            </tr>
            </thead>
            <tbody style={ this.tb_style() }>
            { this.state.rda.map((vpo, key) =>
            <tr key={key} id={ vpo.vip00.v0id } onDoubleClick={ () => this.vip_edit(vpo) }>
                <td>{ vpo.vip00.v0id }</td>
                <td>{ vpo.vip00.v0company }</td>
                <td>{ vpo.vip00.v0namn }</td>
                <td>{ vpo.vip00.v0expire }</td>
                <td>{ vpo.vip02.v2kortnr.ztrim() }</td>
                <td>{ vpo.vip02.v2rabatt.ztrim() }</td>
                <td>{ vpo.vip02.v2tillgodo.cdipris() }</td>
                <td>{ vpo.vip02.v2kredit.cdipris() }</td>
            </tr>
            )}
            </tbody>
        </table>
    </div>
    }

        <PdfDlg show={this.state.pdf} pdf={this.state.pdf_content} done={(ret) => this.pdf_done(ret)} backdrop={true}/>
        { this.state.wnds.vipladda ?
            <Vip_Ladda wnds={this.state.wnds} ctx={this} vpo={this.state.vpo} store={this.props.store} backdrop={true}/>
        : null }
        { this.state.wnds.vipedit ?
            <Vip_Edit wnds={this.state.wnds} ctx={this} vpo={this.state.vpo} store={this.props.store} backdrop={true}/>
        : null }
    </div>
  );
    }
}
export default Fo_Vipreg;
