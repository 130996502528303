import React, { useState, useEffect, useContext } from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import ddo from '../../../ddo.js'
import Lang from "../../../lib/lang";
import * as net from "../../../lib/net";
import * as utils from "../../../assets/utils";

import Fr_Input from "../../../lib/ui/fr_input";
import Fr_Select from "../../../lib/ui/fr_select";
import Dd_BtnSel from "../../../lib/ui/dd_btnsel";

import Wnd_Error from "../../../lib/ui/wnd_error";
import Wnd_Ok from "../../../lib/ui/wnd_ok";
import Wnd_Info from "../../../lib/ui/wnd_info";
import Wnd_Confirm from "../../../lib/ui/wnd_confirm";
import Prd_Pub from "./prd_pub";
import Prd_ingny from "./prd_ingny";
import Prd_ingadd from "./prd_ingadd";
import Prd_ingred from "./prd_ingred";
import Prd_Menu from "./prd_menu";
import Prd_Menu_Edit from "./prd_menu_edit";

function TabPanel(props) {
  const { children, value, index, ctx, ...other } = props;
    function tab_change(val) {
        //alert(newValue);
        ctx.setState({ix: val});
      };
  function tab_style(val) {
      var css = {};
      css.height = "30px";
      css.lineHeight = "30px";
      css.background = "#fff";
      css.borderRight = "1px #e5e5e5 solid";
      css.borderBottom = "1px #ccc solid";
      css.color = "#aaa";
      if(val == ctx.state.ix) {
          css.background = "#fff";
          css.color = "#080";
          css.fontWeight = "700";
          css.borderBottom = "2px #080 solid";
      }
      return css;
  }

  return (
    <div
      className="flex ripple text-center" onClick={() => tab_change(index)} style={ tab_style(index) }
      {...other}
    >
        {children}
    </div>
  );
}
class Prd_Open extends React.Component {
  constructor(props, context) {
    super(props);

    //alert('PBO CTX: ' + JSON.stringify(props.pbo));
    this.store = props.store;
    this.pos = props.store.pos;
    this.state = {};
    this.state.uaa = [];
    this.state.laa = [];
    this.state.lso = {};
    //this.state.lro = props.lro;
    this.state.ynq = [
                {id: '0', text: 'NEJ'},
                {id: '1', text: 'JA'}
            ];
    this.state.aa = [];
    this.state.vga = [];
    this.state.mka = Object.values( ddo.cache.mkods );
    this.state.courses = [
                {id: '0', text: 'INGEN TYPNING'},
                {id: '1', text: 'FÖRRÄTT'},
                {id: '2', text: 'VARMRÄTT'},
                {id: '3', text: 'DESSERT'},
                {id: '4', text: 'TAKE AWAY'}
            ];
    this.state.sval = [
                {id: '00', text: 'INGEN STORHET'},
                {id: '01', text: 'HEL FLASKA'},
                {id: '02', text: 'HALV FLASKA'},
                {id: '03', text: 'GLAS'},
                {id: '04', text: 'DRINK 4 CL'},
                {id: '05', text: 'DRINK 3 CL'},
                {id: '06', text: 'DRINK 6 CL'},
                {id: '07', text: 'ALTERNATIV BAS'},
                {id: '08', text: 'ORDINARIE BAS'},
                {id: '20', text: 'VARIANT BAS'},
                {id: '21', text: 'VARIANT STEG 1'},
                {id: '22', text: 'VARIANT STEG 2'},
                {id: '23', text: 'VARIANT STEG 3'},
                {id: '97', text: 'NOLLPRIS PÅ NOTA'},
                {id: '98', text: 'SPECIAL MARKERING'},
                {id: '99', text: 'NOLLPRIS EJ PÅ NOTA'}
            ];
    this.state.tval = [
                {id: '0', text: 'INGET VALT'},
                {id: '1', text: 'INGEN FRÅGA'},
                {id: '2', text: 'AUTOMATISK FRÅGA'},
                {id: '9', text: 'WOK LÖSNING'},
                {id: 'M', text: 'MENY VAL'},
                {id: 'S', text: 'MENY STEG'},
                {id: 'F', text: 'MENY FAST'},
                {id: 'X', text: 'EXTRA TILLÄGG'}
            ];
    this.state.pro = {};
    this.state.cols = {};
    this.state.lrwrk = false;
    //this.state.pbo = {};
      this.state.pao = {};
      this.state.pbo = {};
      this.state.qbo = {};
    if(props.wnds.pbo) this.state.qbo = props.wnds.pbo;

    this.state.ix = 0;
    this.state.md_01 = false;
    this.state.md_init = false;
    this.state.plusplit = false;
    this.state.pluhappy = false;
      this.state.mno = {};
      this.state.msg = {};
      this.state.wnds = {};
      this.state.iga = [];
      this.state.mna = [];
    this.plufkns = [
        {fkn: 'copy', text: 'KOPIERA'},
        {fkn: 'move', text: 'FLYTTA'},
        {fkn: 'del', text: 'TA BORT'},
        {fkn: 'pub', text: 'PUBLICERA'}
    ];

  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.cdi_init();
    //this.cdi_encode();
      this.vgrps();
      this.mnu_mnus();
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    tab_style(tabid) {
      var css = {};
      css.display = "none";
      if(this.state.tabval === tabid) css.display = "block";
      return css;
    }
    tab_head(tabid) {
      var css = {};
      css.background = "#fff";
      css.color = "#000";
      if(this.state.tabval === tabid) {
          css.background = "#080";
          css.color = "#fff";
      }
      return css;
    }
    cdi_init() {
        var self = this;
        var pro = {};
        var pbo = {};
        var qbo = self.state.qbo;

        var keys = Object.keys(qbo);
        for(var key of keys) {
            var o = qbo[key];
            pbo[key] = {};
            var okeys = Object.keys(qbo[key]);
            for(var okey of okeys) {
                var oo = qbo[key][okey];
                pbo[key][okey] = oo;
            }
        }

        if(!pbo.plu00) return;

        pbo.plu00.p0namn = pbo.plu00.p0namn.cdi2txt();
        pbo.plu02.p2pluknapp = pbo.plu02.p2pluknapp.cdi2txt();
        pbo.plu04.p4longname = pbo.plu04.p4longname.cdi2txt();
        //pbo.plu00.p0moms = "";

        pro.p0pris = pbo.plu00.p0pris.formatpris();
        pro.p0nypris = pbo.plu00.p0nypris.formatpris();
        pro.p0happy = pbo.plu00.p0happy.formatpris();
        pro.p1nyhappy = pbo.plu01.p1nyhappy.formatpris();
        pro.p3kost = pbo.plu03.p3kost.formatpris();

        self.setState({pbo: pbo, pro: pro,  md_init: true});
    }
    cdi_store() {
        var self = this;
        var pbo = self.state.pbo;
        var qbo = self.state.qbo;
        var pro = self.state.pro;

        pbo.plu00.p0namn = pbo.plu00.p0namn.txt2cdi();
        pbo.plu02.p2pluknapp = pbo.plu02.p2pluknapp.txt2cdi();
        pbo.plu04.p4longname = pbo.plu04.p4longname.txt2cdi();

        pbo.plu00.p0pris = pro.p0pris.pris2cdi();
        pbo.plu00.p0nypris = pro.p0nypris.pris2cdi();
        pbo.plu00.p0happy = pro.p0happy.pris2cdi();
        pbo.plu01.p1nyhappy = pro.p1nyhappy.pris2cdi();
        pbo.plu03.p3kost = pro.p3kost.pris2cdi();

        var keys = Object.keys(pbo);
        for(var key of keys) {
            var o = pbo[key];
            if(key.substr(0,3) != "plu") continue;

            var okeys = Object.keys(pbo[key]);
            for(var okey of okeys) {
                var oo = pbo[key][okey];
                qbo[key][okey] = oo;
            }
        }
        self.setState({qbo: qbo});
    }
    cdi_plubuf() {
        var self = this;
        var plubuf = {};
        var pbo = self.state.pbo;
        var pro = self.state.pro;

        pbo.plu00.p0namn = pbo.plu00.p0namn.txt2cdi();
        pbo.plu02.p2pluknapp = pbo.plu02.p2pluknapp.txt2cdi();
        pbo.plu04.p4longname = pbo.plu04.p4longname.txt2cdi();

        pbo.plu00.p0pris = pro.p0pris.pris2cdi();
        pbo.plu00.p0nypris = pro.p0nypris.pris2cdi();
        pbo.plu00.p0happy = pro.p0happy.pris2cdi();
        pbo.plu01.p1nyhappy = pro.p1nyhappy.pris2cdi();
        pbo.plu03.p3kost = pro.p3kost.pris2cdi();

        pro.p0pris = pbo.plu00.p0pris.formatpris();
        pro.p0nypris = pbo.plu00.p0nypris.formatpris();
        pro.p0happy = pbo.plu00.p0happy.formatpris();
        pro.p1nyhappy = pbo.plu01.p1nyhappy.formatpris();
        pro.p3kost = pbo.plu03.p3kost.formatpris();

        var keys = Object.keys(pbo);
        for(var key of keys) {
            var o = pbo[key];
            if(key.substr(0,3) != "plu") continue;

            plubuf[key] = {};
            var okeys = Object.keys(pbo[key]);
            for(var okey of okeys) {
                var oo = pbo[key][okey];
                plubuf[key][okey] = oo;
            }
        }
        self.setState({pro: pro});
        return plubuf;
    }

    formchange (e) {
      const name = e.target.name;
      const value = e.target.value;
      var pbo = this.state.pbo;
      var na = name.split(".");
      pbo[na[0]][na[1]] = value;
      this.setState({pbo: pbo});
      //setState({[name]: value});
    }
    vgrpchange (e) {
      const name = e.target.name;
      const value = e.target.value;
      var pbo = this.state.pbo;
      var na = name.split(".");
      pbo[na[0]][na[1]] = value;

        var vgrp = value;
        var mkod = "01";
        for(var row of this.state.vga) {
            if(row.v0katnr == vgrp) {
                var kod = row.v0moms.substr(0, 2).trim();
                if(kod.length > 0) mkod = kod;
            }
        }
      pbo.plu00.p0moms = mkod;

      this.setState({pbo: pbo});
      //setState({[name]: value});
    }
    prischange (e) {
      const name = e.target.name;
      const value = e.target.value;
      var pro = this.state.pro;
      var na = name.split(".");
      pro[na[1]] = value;
      this.setState({pro: pro});
      //setState({[name]: value});
    }

    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    wnd_close = () => {
		this.props.ctx.setState({ wnds: {} });
	};
    handleChange = (event, newValue) => {
        //alert(newValue);
        this.setState({ix: newValue});
      };
    val_save (name, value) {
        //alert(JSON.stringify(name));
        //alert(JSON.stringify(value));
      var pbo = this.state.pbo;
      var na = name.split(".");
      pbo[na[0]][na[1]] = value;
      this.setState({pbo: pbo});
      //setState({[name]: value});
    }
    val_pris (name, value) {
        //alert(JSON.stringify(name));
        //alert(JSON.stringify(value));
      var pro = this.state.pro;
      var na = name.split(".");
      pro[na[1]] = value;
      this.setState({pro: pro});
      //setState({[name]: value});
    }
  tb_style() {
      var css = {};
      css.height = (window.innerHeight * 0.9) - 235;
      css.overflow = "auto";
      return css;
  }
  cssCols (cid) {
      var css = {};
      if(this.state.cols[cid]) css.width = this.state.cols[cid].toString() + "px";
      //if(cid == "c01") alert(JSON.stringify(this.state.cols[cid]));
      return css;
  }
  syncCols (tb) {

    if (!document.getElementById(tb)) return;

    var cols = {};
    var ix = document.getElementById(tb).rows.length - 1;
    var ic = document.getElementById(tb).rows[0].cells.length;
    var ci = 0;
    while(ci < ic) {
        var cxi = ci + 1;
        var col = "c" + cxi.toString().padStart(2, "0");
        cols[col] = document.getElementById(tb).rows[ix].cells[ci].clientWidth + 1;
        ci = ci + 1;
    }
    this.setState({cols: cols });
    //alert(JSON.stringify(this.state.cols));
    //this.forceUpdate();
  };

  md_toggle (event) {
    var self = this;
    //self.setState({md_01: false });
    return;
  }
  plu_split (event) {
    var self = this;
    self.setState({plusplit: true });
    return;
  }
  plu_split_done (ctx, ret) {
    var self = this;
    if(ret.ok == "000") {
      var pro = ctx.state.pro;
      var pbo = ctx.state.pbo;
      pro.p0pris = ret.belopp.formatpris();
      pbo.plu00.p0pris = ret.belopp.formatpris();
      pbo.plu00.p0kampanj = "1";
      ctx.setState({plusplit: false, pro: pro, pbo: pbo});
      return;
    }
    ctx.setState({plusplit: false });
    return;
  }
  happy_show (event) {
    var self = this;
    event.preventDefault();

    self.setState({pluhappy: true });
    return;
  }

  plu_fkn (event) {
    var self = this;
    //self.setState({md_02: !self.state.md_02 });
    return;
  }

  plu_save (event) {
    var self = this;
    var msg = { info: true, text: "Sparar ..."};
    self.setState({msg: msg });

    var plubuf = self.cdi_plubuf();

    //alert(JSON.stringify(plubuf.plu00.p0namn));
    //alert(JSON.stringify(plubuf.plu00.p0pris));

    var prm = {};
    //prm.req = "pos.pos_plu.plu_bufsave";
    prm.req = "pos.pos_plu.pb_upd";
    prm.plubuf = plubuf;
    //alert(JSON.stringify(prm));

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            var msg = { info: false, error: true, text: "Misslyckades ..."};
            self.setState({msg: msg });
            setTimeout(function(){
                self.setState({msg: {} });
            }, 1000);
        }
        else {
            self.cdi_store();
            var msg = { info: false, ok: true, text: "Sparat ..."};
            self.setState({msg: msg });
            setTimeout(function(){
                self.setState({msg: {} });
                //self.props.ctx.setState({ pluopen: false });
		        self.props.ctx.plu_upd(self.props.ctx, plubuf);
            }, 1000);
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
        self.setState({md_03: false });
    });
    return;
  }
  plu_del (event) {
    var self = this;
    var msg = { confirm: true, text: "Skall PLU:n tas bort?", text0: "JA", text1: "NEJ", cb: "plu_del_do"};
    self.setState({msg: msg });
  }
  plu_del_do (val) {
    var self = this;

    if(val == 1) {
        self.setState({msg: {} });
        return;
    }

    var msg = { info: true, text: "Tar bort ..."};
    self.setState({msg: msg });

    var plubuf = self.cdi_plubuf();

    var prm = {};
    prm.req = "pos.pos_plu.plu_remove";
    prm.avd = this.state.pbo.plu00.p0kstns;
    prm.plu = this.state.pbo.plu00.p0katnr;
    //alert(JSON.stringify(prm));

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            var msg = { info: false, error: true, text: "Misslyckades ..."};
            self.setState({msg: msg });
            setTimeout(function(){
                self.setState({msg: {} });
            }, 1000);
        }
        else {
            self.cdi_store();
            var msg = { info: false, ok: true, text: "Borttagen ..."};
            self.setState({msg: msg });
            setTimeout(function(){
                self.setState({msg: {} });
                self.props.ctx.plu_upd(self.props.ctx, plubuf);
                self.props.ctx.setState({ wnds: {} });
            }, 1000);
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
        self.setState({md_03: false });
    });
    return;
  }
    plu_copy () {
        //var self = this;
        //var msg = { confirm: true, text: "Skall PLU:n koieras?", text0: "JA", text1: "NEJ", cb: "plu_copy_do"};
        //self.setState({msg: msg });
        var self = this;
        var wnds = {};
        wnds.ftplu = 1;
        wnds.title = "KOPIERA PLU";
        wnds.fkntext = "KOPIERA";
        wnds.favd = this.state.pbo.plu00.p0kstns;
        wnds.fplu = this.state.pbo.plu00.p0katnr;
        wnds.tavd = this.state.pbo.plu00.p0kstns;
        wnds.tplu = "";
        wnds.cb = self.cb_plu_copy;
        self.setState({ wnds: wnds });
    }
    cb_plu_copy (ctx, rsp) {
        var self = ctx;

        //alert(JSON.stringify(rsp));
        if(rsp.ok != "000") {
            self.setState({msg: {} });
            return;
        }

    var msg = { info: true, text: "Kopierar ..."};
    self.setState({msg: msg });

    var plubuf = self.cdi_plubuf();

    var prm = {};
    prm.req = "pos.pos_plu.plu_copy";
    prm.favd = self.state.pbo.plu00.p0kstns;
    prm.tavd = rsp.tavd;
    prm.fplu = self.state.pbo.plu00.p0katnr;
    prm.tplu = rsp.tplu;
    //alert(JSON.stringify(prm));

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            var msg = { info: false, error: true, text: "Misslyckades ..."};
            self.setState({msg: msg });
            setTimeout(function(){
                self.setState({msg: {} });
            }, 1000);
        }
        else {
            self.cdi_store();
            var msg = { info: false, ok: true, text: "Kopierad ..."};
            self.setState({msg: msg });
            setTimeout(function(){
                self.setState({msg: {} });
                self.props.ctx.plu_upd(self.props.ctx, plubuf);
            }, 1000);
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        self.setState({msg: {} });
    });
    return;
  }
    plu_move () {
        //var self = this;
        //var msg = { confirm: true, text: "Skall PLU:n koieras?", text0: "JA", text1: "NEJ", cb: "plu_copy_do"};
        //self.setState({msg: msg });
        var self = this;
        var wnds = {};
        wnds.ftplu = 1;
        wnds.title = "FLYTTA PLU";
        wnds.fkntext = "FLYTTA";
        wnds.favd = this.state.pbo.plu00.p0kstns;
        wnds.fplu = this.state.pbo.plu00.p0katnr;
        wnds.tavd = this.state.pbo.plu00.p0kstns;
        wnds.tplu = "";
        wnds.cb = self.cb_move_copy;
        self.setState({ wnds: wnds });
    }
    cb_move_copy (ctx, rsp) {
        var self = ctx;

        //alert(JSON.stringify(rsp));
        if(rsp.ok != "000") {
            self.setState({msg: {} });
            return;
        }

        var msg = { info: true, text: "Flyttar ..."};
        self.setState({msg: msg });

        var plubuf = self.cdi_plubuf();

        var prm = {};
        prm.req = "pos.pos_plu.plu_move";
        prm.favd = self.state.pbo.plu00.p0kstns;
        prm.tavd = rsp.tavd;
        prm.fplu = self.state.pbo.plu00.p0katnr;
        prm.tplu = rsp.tplu;
        //alert(JSON.stringify(prm));

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            var msg = { info: false, error: true, text: "Misslyckades ..."};
            self.setState({msg: msg });
            setTimeout(function(){
                self.setState({msg: {} });
            }, 1000);
        }
        else {
            self.cdi_store();
            var msg = { info: false, ok: true, text: "Flyttad ..."};
            self.setState({msg: msg });
            setTimeout(function(){
                self.setState({msg: {} });
                self.props.ctx.plu_upd(self.props.ctx, plubuf);
                self.props.ctx.setState({wnds: {} });
            }, 1000);
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        self.setState({msg: {} });
    });
    return;
  }
    plu_pub () {
        var self = this;
        var plubuf = self.cdi_plubuf();

        var wnds = {};
        wnds.pubplu = 1;
        //wnds.pbo = this.state.pbo;
        wnds.pbo = plubuf;
        wnds.iga = self.state.iga;
        //wnds.cb = self.cb_move_copy;
        self.setState({ wnds: wnds });
    }
    xplu_pub () {
        var self = this;
        var msg = { confirm: true, text: "Skall denna PLU publiceras?", text0: "JA", text1: "NEJ", cb: ""};
        self.setState({msg: msg });
    }
    zplu_pub () {
        var self = this;
        var msg = { info: true, text: "Publicerar ..."};
        self.setState({msg: msg });

        var plubuf = self.cdi_plubuf();

        //alert(JSON.stringify(plubuf.plu00.p0namn));
        //alert(JSON.stringify(plubuf.plu00.p0pris));

        var prm = {};
        prm.sid = "S0003202";
        prm.req = "pos.pos_plu.pb_upd";
        prm.plubuf = plubuf;
        //alert(JSON.stringify(prm));

        //gda.wndLoading("Hämtar");
        net.sid_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret));

                if(ret.ok != "000") {
                    var msg = { info: false, error: true, text: "Misslyckades ..."};
                    self.setState({msg: msg });
                    setTimeout(function(){
                        self.setState({msg: {} });
                    }, 1000);
                }
                else {
                    self.cdi_store();
                    var msg = { info: false, ok: true, text: "Sparat ..."};
                    self.setState({msg: msg });
                    setTimeout(function(){
                        self.setState({msg: {} });
                        //self.props.ctx.setState({ pluopen: false });
                        self.props.ctx.plu_upd(self.props.ctx, plubuf);
                    }, 1000);
                }
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
                //gda.wndError(txt);
                self.setState({md_03: false });
            });
        return;
    }

  vgrps() {
    var self = this;
    var prm = {};
    prm.req = "pos.pos_pg.vgrps";
    prm.avd = this.state.avd;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        self.setState({vga: ret.rco });
        self.pluanm_get();
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }


    sel_fkn(sel) {
    //alert(JSON.stringify(fkn));
      if(sel.fkn == "copy") {
          this.plu_copy();
      }
      if(sel.fkn == "move") {
          this.plu_move();
      }
        if(sel.fkn == "del") {
            this.plu_del();
        }
        if(sel.fkn == "pub") {
            this.plu_pub();
        }
  }
    ts_upd() {
      if(true) return;
      var ts = this.state.pbo.plu00.p0updtime;
        var c1 = ts.charCodeAt(0).toString(16);
        var c2 = ts.charCodeAt(1).toString(16);
        var c3 = ts.charCodeAt(2).toString(16);
        var c4 = ts.charCodeAt(3).toString(16);
        var yy = c1.substr(0, 1);
        var mm = c1.substr(1, 1);
        mm = parseInt(mm, 16).toString(10);
        var dd = c2;
        var hh = c3;
        var xx = c4;
        alert(JSON.stringify(yy));
        alert(JSON.stringify(mm));
        alert(JSON.stringify(dd));
        alert(JSON.stringify(hh));
        alert(JSON.stringify(xx));
    }
    pluanm_change (e) {
        const value = e.target.value;
        var pbo = this.state.pbo;
        pbo.pluanm = value;
        this.setState({pbo: pbo});
    }
    pluanm_get () {
        var self = this;

        var key = this.state.pbo.plu00.p0kstns + this.state.pbo.plu00.p0katnr;

        var prm = {};
        prm.req = "pos.pos_pluanm.anm_get";
        prm.avd = this.state.avd;
        prm.pluid = key;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.rco));
                var pbo = self.state.pbo;
                pbo.pluanm = ret.rco.anm.trim();
                pbo.pluanm = pbo.pluanm.cdi2txt();
                self.setState({ pbo: pbo, pao: ret.rco });
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    pluanm_upd () {
        var self = this;

        var anm = this.state.pbo.pluanm;
        anm = anm.txt2cdi();

        var pao = {};
        pao.typ = "A";
        pao.avd = this.state.pbo.plu00.p0kstns;
        pao.plunr = this.state.pbo.plu00.p0katnr;
        pao.anm = anm;
        pao.lopnr = "";

        var prm = {};
        prm.req = "pos.pos_pluanm.anm_upd";
        prm.pao = pao;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.rca));
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    pluanm_del () {
        var self = this;

        var prm = {};
        prm.req = "pos.pos_pluanm.anm_del";
        prm.pao = this.state.pao;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.rca));
                var pbo = self.state.pbo;
                pbo.pluanm = "";
                self.setState({pbo: pbo});
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    pluanm_clear () {
        var self = this;
        var pbo = this.state.pbo;
        pbo.pluanm = "";
        this.setState({pbo: pbo});
    }

    mnu_add() {
        var self = this;

        var wnds = {};
        wnds.mnuadd = true;
        wnds.pbo = this.state.pbo;
        wnds.cb = this.cb_mnu;
        self.setState({wnds: wnds });
        return;
    }
    mnu_del (row) {
        var self = this;

        var prm = {};
        prm.req = "dsi.dsi_mp.mp_del";
        prm.mpo = row;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.rca));
                self.mnu_mp();
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    cb_mnu (ctx) {
        var self = this;
        ctx.setState({wnds: {}});
        ctx.mnu_mp();
    }

    pluing_ny() {
        var self = this;

        var wnds = {};
        wnds.pluingny = true;
        wnds.pbo = this.state.pbo;
        wnds.cb = this.cb_pluing;
        self.setState({ wnds: wnds });
        return;
    }
    pluing_add() {
        var self = this;

        var wnds = {};
        wnds.pluingadd = true;
        wnds.pbo = this.state.pbo;
        wnds.cb = this.cb_pluing;
        self.setState({wnds: wnds });
        return;
    }
    pluing_edit(row) {
        var self = this;

        var wnds = {};
        wnds.pluingred = true;
        wnds.pbo = this.state.pbo;
        wnds.igo = row;
        wnds.cb = this.cb_pluing;
        self.setState({wnds: wnds });
        return;
    }
    cb_pluing (ctx) {
        var self = this;
        ctx.setState({wnds: {}});
        ctx.pluing_get();
    }

    pluing_clear () {
        var self = this;
        this.setState({iga: []});
    }
    pluing_get () {
        var self = this;
        var prm = {};
        prm.req = "pos.pos_ingred.pluing_plu";
        prm.avd = this.state.pbo.plu00.p0kstns;
        prm.plu = this.state.pbo.plu00.p0katnr;

        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret));

            self.setState({ iga: ret.rca });
            //self.setState({ rva: ret.rca });
            self.syncTb("igaTable");
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }
    pluing_del (row) {
        var self = this;

        var prm = {};
        prm.req = "pos.pos_ingred.pluing_del";
        prm.plo = row;

        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret.rca));
            self.pluing_get();
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }
    pluing_pub () {
        var self = this;
        var prm = {};
        prm.sid = "S0003202";
        prm.req = "pos.pos_ingred.pluing_set";
        prm.avd = this.state.pbo.plu00.p0kstns;
        prm.plu = this.state.pbo.plu00.p0katnr;
        prm.iga = this.state.iga;

        net.sid_req(prm)
        .then(function(ret){
            alert(JSON.stringify(ret));
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }
    mnu_mnus() {
        var self = this;
        var prm = {};
        prm.req = "dsi.dsi_mnu.mnus";

        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret));

            self.setState({ mno: ret.rco });
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }
    mnu_txt(row) {
        var self = this;
        var txt = row.mnuid;
        if(this.state.mno[row.mnuid]) {
            txt = utils.toLang( this.state.mno[row.mnuid].text );
        }
        return txt;
    }
    mnu_mp() {
        var self = this;
        var prm = {};
        prm.req = "dsi.dsi_mp.mp_plu";
        prm.avd = this.state.pbo.plu00.p0kstns;
        prm.plu = this.state.pbo.plu00.p0katnr;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.rca));

                self.setState({ mna: ret.rca, ix: 3 });
                //self.setState({ rva: ret.rca });
                self.syncTb("mnaTable");
                self.pluing_get();
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    plumenu_edit(row) {
        var self = this;

        var wnds = {};
        wnds.mnuedit = true;
        wnds.pbo = this.state.pbo;
        wnds.mpo = row;
        wnds.cb = this.cb_plumenu;
        self.setState({wnds: wnds });
        return;
    }
    cb_plumenu (ctx) {
        var self = this;
        ctx.setState({wnds: {}});
        ctx.mnu_mp();
    }
    th_style(id) {
        var css = {};
        css.cursor = "pointer";
        if(id == this.state.sortid) {
            if(this.state.sortdir) css.color = "#800";
            else css.color = "#080";
        }
        return css;
    }
    th_icon(typ) {
        var cls = "";
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;
        if(sortid == typ)
        {
            if(sortdir) cls = "fa fa-caret-up";
            else cls = "fa fa-caret-down";
        }
        return cls;
    }
    tb_sort(typ) {
        var self = this;
        //var rvs = self.state.rvs;
        var rva = self.state.rva;
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;

        if(sortid != typ) sortdir = 0;
        else {
            if(sortdir) sortdir = 0;
            else sortdir = 1;
        }

        if(typ == "ank") {
            if(sortdir) rva.sort((a,b) => (a.gsb00.r0ankdatum < b.gsb00.r0ankdatum) ? 1 : ((b.gsb00.r0ankdatum < a.gsb00.r0ankdatum) ? -1 : 0));
            else rva.sort((a,b) => (a.gsb00.r0ankdatum > b.gsb00.r0ankdatum) ? 1 : ((b.gsb00.r0ankdatum > a.gsb00.r0ankdatum) ? -1 : 0));
        }
        self.setState({ rva: rva, sortid: typ, sortdir: sortdir });
    }
    syncTb(tb) {
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
            tdElements = tbel.getElementsByTagName('td');
        if(tdElements.length < 1 ) return;

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
                width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
    row_style(row) {
        var srow = this.state.selrow;

        var css = {};
        if(srow == row) {
            css.background = "#080";
            css.color = "#fff";
        }
        return css;
    }

  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "Plu";
    //if(props.kro) alert(props.kro.krr00.kid);
    const popover = {
      position: 'absolute',
      zIndex: '19999',
    }

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="mx_backdrop" /> : null }
        <div className="mx_dlg mx_90 flex-col" style={ this.dlg_style() }>
        { this.state.md_init ?
        <div className="mx_content flex">
            <div className="flex-row" style={{padding:"5px",height:"30px"}}>
                    <div className="flex-row flex txt_18">
                        <div>PLU: </div>
                        <div>{ this.state.pbo.plu00.p0katnr } - { this.state.pbo.plu00.p0namn }</div>
                    </div>
                    <div className="flex-col">
                        <div onClick={() => this.ts_upd() }>UPPDATERA</div>
                    </div>
            </div>
            <div className="divider_orange"></div>

            <div className="flex-row">
                <TabPanel index={0} ctx={this}>DATA</TabPanel>
                <TabPanel index={1} ctx={this}>PARAMETRAR</TabPanel>
                <TabPanel index={2} ctx={this}>PRISER</TabPanel>
                <TabPanel index={3} ctx={this} onClick={() => this.mnu_mp() }>MENY</TabPanel>
                <TabPanel index={4} ctx={this}>BONGAR</TabPanel>
                <TabPanel index={5} ctx={this}>BESKRIVNING</TabPanel>
            </div>
            <div style={{padding:"5px"}}></div>

            <form className="cdi_f03">
            <div hidden={this.state.ix !== 0}>

                <div className="form-row flex-row">
                    <Fr_Input label="Text" className="text-left w350" text={this.state.pbo.plu00.p0namn} getValue={(e)=> this.val_save("plu00.p0namn", e) } />
                    <Fr_Input label="Pris" className="text-right w250" text={this.state.pro.p0pris} getValue={(e)=> this.val_pris("plu00.p0pris", e) } placeholder="0.00" />
                </div>
                <div className="form-row flex-row">
                    <Fr_Input label="Lång Text" className="text-left w450" text={this.state.pbo.plu04.p4longname} getValue={(e)=> this.val_save("plu04.p4longname", e) } />
                </div>

                <div className="form-row flex-row">
                    <div className="form-group flex-row flex">
                        <span className="header">Varugrupp</span>
                        <select name="plu02.p2vgrupp" value={this.state.pbo.plu02.p2vgrupp} className="form-control w250" onChange={(e) => this.vgrpchange(e)}>
                            { this.state.vga.map((row, key) =>
                            <option key={key} value={row.v0katnr}>{ row.v0katnr } - { row.v0text.toswe() }</option>
                            )}
                        </select>
                    </div>
                    <div className="form-group flex-row flex">
                        <span className="header">Momskod</span>
                        <select name="plu00.p0moms" value={this.state.pbo.plu00.p0moms} className="form-control w250" onChange={(e) => this.formchange(e)}>
                            { this.state.mka.map((row, key) =>
                            <option key={key} value={row.m0kod}>{row.m0kod } - { row.m0txt.toswe() }</option>
                            )}
                        </select>
                    </div>
                </div>

                <div className="form-sep"></div>

                <div className="form-row flex-row">
                    <Fr_Input label="Knapptext" className="text-left w250" text={this.state.pbo.plu02.p2pluknapp} getValue={(e)=> this.val_save("plu02.p2pluknapp", e) } />
                    <Fr_Input label="Brytpos knapptext" className="text-left w150" text={this.state.pbo.plu04.p4knappbryt} getValue={(e)=> this.val_save("plu04.p4knappbryt", e) } />
                </div>
                <div className="form-row flex-row">
                    <Fr_Input label="EAN Kod" className="text-left w250" text={this.state.pbo.plu04.p4scan} getValue={(e)=> this.val_save("plu04.p4scan", e) } />
                    <Fr_Input label="Sortering" className="text-left w150" text={this.state.pbo.plu00.p0sort} getValue={(e)=> this.val_save("plu00.p0sort", e) } />
                </div>
                <div className="form-row flex-row">
                    <Fr_Select flex label="Debet/Kredit" className="text-left w250" text={this.state.pbo.plu00.p0debcre} items={this.state.ynq} getValue={(e)=> this.val_save("plu00.p0debcre", e) } />
                    <Fr_Select flex label="Visas" className="text-left w150" text={this.state.pbo.plu02.p2pluvis} items={this.state.ynq} getValue={(e)=> this.val_save("plu02.p2pluvis", e) } />
                </div>

                 <div className="form-row flex-row">
                    <Fr_Select flex label="Storhet" className="text-left w250" text={this.state.pbo.plu02.p2storhet} items={this.state.sval} getValue={(e)=> this.val_save("plu02.p2storhet", e) } />
                    <Fr_Input label="Kopplad till" className="text-left w150" text={this.state.pbo.plu02.p2koppling} getValue={(e)=> this.val_save("plu02.p2koppling", e) } />
                </div>
                <div className="divider_green"></div>
                 <div className="form-row flex-row">
                    <Fr_Select flex label="Rätt" className="text-left w250" text={this.state.pbo.plu00.p0brkod} items={this.state.courses} getValue={(e)=> this.val_save("plu00.p0brkod", e) } />
                </div>
                 <div className="form-row flex-row">
                    <Fr_Select flex label="Meny/Tillval" className="text-left w250" text={this.state.pbo.plu00.p0fritxt} items={this.state.tval} getValue={(e)=> this.val_save("plu00.p0fritxt", e) } />
                    <Fr_Input label="Meny grupp" className="text-left w250" text={this.state.pbo.plu00.p0bryttxt} getValue={(e)=> this.val_save("plu00.p0bryttxt", e) } />
                </div>

            </div>

            <div hidden={this.state.ix !== 1}>

                <div className="form-row flex-row">
                    <Fr_Input label="XPLU" className="text-left w150" text={this.state.pbo.plu03.p3xplu} getValue={(e)=> this.val_save("plu03.p3xplu", e) } />
                    <Fr_Input label="YPLU" className="text-left w150" text={this.state.pbo.plu04.p4yplu} getValue={(e)=> this.val_save("plu04.p4yplu", e) } />
                </div>
                <div className="form-row flex-row">
                    <Fr_Select flex label="Sälj fråga" className="text-left w150" text={this.state.pbo.plu04.p4xplufraga} items={this.state.ynq} getValue={(e)=> this.val_save("plu04.p4xplufraga", e) } />
                    <Fr_Select flex label="Vägas" className="text-left w150" text={this.state.pbo.plu04.p4vagen} items={this.state.ynq} getValue={(e)=> this.val_save("plu04.p4vagen", e) } />
                </div>
               <div className="form-row flex-row">
                    <Fr_Input label="Typ" className="text-left w150" text={this.state.pbo.plu04.p4konftyp} getValue={(e)=> this.val_save("plu04.p4konftyp", e) } />
                </div>

                <div className="form-sep-text">Lager</div>
                <div className="flex-row">
                    <div className="form-group flex-row flex">
                        <span className="header">Huvudlager</span>
                        <select name="plu02.p2lagst" value={this.state.pbo.plu02.p2lagst} className="form-control w250" onChange={(e) => this.formchange(e)}>
                            { this.state.laa.map((row, key) =>
                            <option key={key} value={row.id}>{row.text}</option>
                            )}
                        </select>
                    </div>
                    <div className="form-group flex-row flex">
                        <span className="header">Underlager</span>
                        <select name="plu02.p2ulager" value={this.state.pbo.plu02.p2ulager} className="form-control w250" onChange={(e) => this.formchange(e)}>
                            { this.state.uaa.map((row, key) =>
                            <option key={key} value={row.id}>{row.text}</option>
                            )}
                        </select>
                    </div>
                </div>
                <div className="form-row flex-row">
                    <Fr_Input label="Receptur" className="text-left w150" text={this.state.pbo.plu00.p0fkod} getValue={(e)=> this.val_save("plu00.p0fkod", e) } />
                    <Fr_Input label="Produktkostnad" className="text-right w150" text={this.state.pro.p3kost} getValue={(e)=> this.val_pris("plu03.p3kost", e) } placeholder="0.00" />
                </div>
                <div className="form-row flex-row">
                    <Fr_Input label="Artikel" className="text-left w150" text={this.state.pbo.plu04.p4artnr} getValue={(e)=> this.val_save("plu04.p4artnr", e) } />
                </div>

                <div className="form-sep-text">Struktur</div>
                <div className="form-row flex-row">
                    <Fr_Input label="Grupp/Tid" className="text-left w150" text={this.state.pbo.plu04.p4gstruktur} getValue={(e)=> this.val_save("plu04.p4gstruktur", e) } />
                    <Fr_Input label="Under/Färg" className="text-left w150" text={this.state.pbo.plu04.p4ustruktur} getValue={(e)=> this.val_save("plu04.p4ustruktur", e) } />
                </div>
                <div className="form-row flex-row">
                    <Fr_Input label="Sub/Typ" className="text-left w150" text={this.state.pbo.plu04.p4sstruktur} getValue={(e)=> this.val_save("plu04.p4sstruktur", e) } />
                </div>

            </div>
            <div hidden={this.state.ix !== 2}>
                <div className="form-row flex-row">
                    <Fr_Input label="Pris" className="text-right w250" text={this.state.pro.p0pris} getValue={(e)=> this.val_pris("p0pris", e) } placeholder="0.00" />
                    <Fr_Input label="Ny Pris" className="text-right w250" text={this.state.pro.p0nypris} getValue={(e)=> this.val_pris("p0nypris", e) } placeholder="0.00" />
                </div>
                <div className="form-row flex-row">
                    <Fr_Input label="Happy Pris" className="text-right w250" text={this.state.pro.p0happy} getValue={(e)=> this.val_pris("p0happy", e) } placeholder="0.00" />
                    <Fr_Input label="Nytt Happy Pris" className="text-right w250" text={this.state.pro.p1nyhappy} getValue={(e)=> this.val_pris("p1nyhappy", e) } placeholder="0.00" />
                </div>
                <div className="form-row flex-row">
                    <button className="btn btn-hw-blue w250" onClick={(e) => this.happy_show(e) } >
                        HAPPY PRISER
                    </button>
                </div>

            </div>

                <div hidden={this.state.ix !== 3}>

                    <div className="flex-row">
                    <div className="flex px-3">
                    <div className="txt_18">Meny</div>
                        <div id="mnaTable" className="flex">
                            <table className="table table-bordered table-hover" style={{marginBottom: "0px"}}>
                                <thead>
                                <tr>
                                    <th style={ this.th_style("menyid") } onClick={() => this.tb_sort("menyid") }><Lang>Menyid</Lang> <i className={ this.th_icon("menyid") }></i></th>
                                    <th style={ this.th_style("meny") } onClick={() => this.tb_sort("meny") }><Lang>Meny</Lang> <i className={ this.th_icon("meny") }></i></th>
                                    <th style={ this.th_style("text") } onClick={() => this.tb_sort("text") }><Lang>Text</Lang> <i className={ this.th_icon("text") }></i></th>
                                    <th style={ this.th_style("sort") } onClick={() => this.tb_sort("sort") }><Lang>Sort</Lang> <i className={ this.th_icon("sort") }></i></th>
                                    <th></th>
                                </tr>
                                </thead>
                            </table>
                            <div style={ this.tb_style() }>
                                <table className="table table-bordered table-hover">
                                    <tbody>
                                    { this.state.mna.map((row, key) =>
                                        <tr key={key} style={ this.row_style(row) } onDoubleClick={ () => this.plumenu_edit(row) }>
                                            <td>{ row.mnuid }</td>
                                            <td>{ this.mnu_txt(row) }</td>
                                            <td>{ utils.toLang( row.text ) }</td>
                                            <td>{ row.sort }</td>
                                            <td onClick={() => this.mnu_del(row) }>
                                                <div className=""><i className="fas fa-xmark" /></div>
                                            </td>
                                        </tr>
                                    )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="divider_green"></div>
                        <div className="form-row flex-row px-5">
                            <div className="web-link" onClick={() => this.mnu_mp() }>Uppdatera</div>
                            <div className="flex"></div>
                            <div className="web-link hide" onClick={() => this.mnu_clear() }>Rensa</div>
                            <div className="flex"></div>
                            <div className="web-link" onClick={() => this.mnu_add() }>Lägg till</div>
                        </div>
                    </div>

                    <div className="flex px-3">
                    <div className="txt_18">Ingredienser</div>
                        <div id="igaTable" className="flex">
                            <table className="table table-bordered table-hover" style={{marginBottom: "0px"}}>
                                <thead>
                                <tr>
                                    <th style={ this.th_style("lopnr") } onClick={() => this.tb_sort("lopnr") }><Lang>Löpnr</Lang> <i className={ this.th_icon("lopnr") }></i></th>
                                    <th style={ this.th_style("plu") } onClick={() => this.tb_sort("plu") }><Lang>Artikel</Lang> <i className={ this.th_icon("plu") }></i></th>
                                    <th style={ this.th_style("text") } onClick={() => this.tb_sort("text") }><Lang>Text</Lang> <i className={ this.th_icon("text") }></i></th>
                                    <th style={ this.th_style("fast") } onClick={() => this.tb_sort("fast") }><Lang>Fast</Lang> <i className={ this.th_icon("fast") }></i></th>
                                    <th style={ this.th_style("sort") } onClick={() => this.tb_sort("sort") }><Lang>Sortering</Lang> <i className={ this.th_icon("sort") }></i></th>
                                    <th></th>
                                </tr>
                                </thead>
                            </table>
                            <div style={ this.tb_style() }>
                                <table className="table table-bordered table-hover">
                                    <tbody>
                                    { this.state.iga.map((row, key) =>
                                        <tr key={key} style={ this.row_style(row) } onDoubleClick={ () => this.pluing_edit(row) }>
                                            <td>{ utils.toNum( row.p0lopnr ) }</td>
                                            <td>{ row.p0ingnr }</td>
                                            <td>{ utils.toLang( row.p0text ) }</td>
                                            <td>{ row.p0fast }</td>
                                            <td>{ row.p0sort }</td>
                                            <td onClick={() => this.pluing_del(row) }>
                                                <div className=""><i className="fas fa-xmark" /></div>
                                            </td>
                                        </tr>
                                    )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="divider_green"></div>
                        <div className="form-row flex-row px-5">
                            <div className="web-link" onClick={() => this.pluing_get() }>Uppdatera</div>
                            <div className="flex"></div>
                            <div className="web-link hide" onClick={() => this.pluing_clear() }>Rensa</div>
                            <div className="web-link" onClick={() => this.pluing_ny() }>Ny ingrediens</div>
                            <div className="web-link px-3" onClick={() => this.pluing_pub() }>Publicera</div>
                            <div className="flex"></div>
                            <div className="web-link" onClick={() => this.pluing_add() }>Lägg till</div>
                        </div>
                    </div>
                    </div>
            </div>

            <div hidden={this.state.ix !== 4}>

                <div className="form-sep-text">Bongskrivare</div>
                <div className="flex-row">
                    <div className="form-row flex-col px-3">
                        <Fr_Input label="1 BURGERS" className="text-left w150" text={this.state.pbo.plu00.p0kskr1} getValue={(e)=> this.val_save("plu00.p0kskr1", e) } maxLength="1" />
                        <Fr_Input label="2 PIZZA" className="text-left w150" text={this.state.pbo.plu00.p0kskr2} getValue={(e)=> this.val_save("plu00.p0kskr2", e) } maxLength="1" />
                        <Fr_Input label="3 PASTA" className="text-left w150" text={this.state.pbo.plu00.p0kskr3} getValue={(e)=> this.val_save("plu00.p0kskr3", e) } maxLength="1" />
                        <Fr_Input label="4 SUSHI" className="text-left w150" text={this.state.pbo.plu00.p0bar1} getValue={(e)=> this.val_save("plu00.p0bar1", e) } maxLength="1" />
                        <Fr_Input label="5 TACOS" className="text-left w150" text={this.state.pbo.plu00.p0bar2} getValue={(e)=> this.val_save("plu00.p0bar2", e) } maxLength="1" />
                        <div className="flex"></div>
                    </div>
                    <div className="form-row flex-col px-3">
                        <Fr_Input label="6 KEBAB" className="text-left w150" text={this.state.pbo.plu04.p4bong1} getValue={(e)=> this.val_save("plu04.p4bong1", e) } maxLength="1" />
                        <Fr_Input label="7 WOK" className="text-left w150" text={this.state.pbo.plu04.p4bong2} getValue={(e)=> this.val_save("plu04.p4bong2", e) } maxLength="1" />
                        <Fr_Input label="8 SALLAD" className="text-left w150" text={this.state.pbo.plu04.p4bong3} getValue={(e)=> this.val_save("plu04.p4bong3", e) } maxLength="1" />
                        <Fr_Input label="9 HUSMAN" className="text-left w150" text={this.state.pbo.plu04.p4bong4} getValue={(e)=> this.val_save("plu04.p4bong4", e) } maxLength="1" />
                        <Fr_Input label="10 SIDES" className="text-left w150" text={this.state.pbo.plu04.p4bong5} getValue={(e)=> this.val_save("plu04.p4bong5", e) } maxLength="1" />
                    </div>
                </div>

                <div className="form-sep-text">Bongskärm</div>
                <div className="flex-row">
                    <div className="flex-col px-3">
                        <Fr_Input label="1 BURGERS" className="text-left w150" text={this.state.pbo.plu05.p5bs01} getValue={(e)=> this.val_save("plu05.p5bs01", e) } maxLength="1" />
                        <Fr_Input label="2 PIZZA" className="text-left w150" text={this.state.pbo.plu05.p5bs02} getValue={(e)=> this.val_save("plu05.p5bs02", e) } maxLength="1" />
                        <Fr_Input label="3 PASTA" className="text-left w150" text={this.state.pbo.plu05.p5bs03} getValue={(e)=> this.val_save("plu05.p5bs03", e) } maxLength="1" />
                        <Fr_Input label="4 SUSHI" className="text-left w150" text={this.state.pbo.plu05.p5bs04} getValue={(e)=> this.val_save("plu05.p5bs04", e) } maxLength="1" />
                        <Fr_Input label="5 TACOS" className="text-left w150" text={this.state.pbo.plu05.p5bs05} getValue={(e)=> this.val_save("plu05.p5bs05", e) } maxLength="1" />
                        <Fr_Input label="HEADCHEF" className="text-left w150" text={this.state.pbo.plu05.p5bs11} getValue={(e)=> this.val_save("plu05.p5bs11", e) } maxLength="1" />
                    </div>
                    <div className="flex-col px-3">
                        <Fr_Input label="6 KEBAB" className="text-left w150" text={this.state.pbo.plu05.p5bs06} getValue={(e)=> this.val_save("plu05.p5bs06", e) } maxLength="1" />
                        <Fr_Input label="7 WOK" className="text-left w150" text={this.state.pbo.plu05.p5bs07} getValue={(e)=> this.val_save("plu05.p5bs07", e) } maxLength="1" />
                        <Fr_Input label="8 SALLAD" className="text-left w150" text={this.state.pbo.plu05.p5bs08} getValue={(e)=> this.val_save("plu05.p5bs08", e) } maxLength="1" />
                        <Fr_Input label="9 HUSMAN" className="text-left w150" text={this.state.pbo.plu05.p5bs09} getValue={(e)=> this.val_save("plu05.p5bs09", e) } maxLength="1" />
                        <Fr_Input label="10 SIDES" className="text-left w150" text={this.state.pbo.plu05.p5bs10} getValue={(e)=> this.val_save("plu05.p5bs10", e) } maxLength="1" />
                    </div>
                </div>

            </div>
                <div hidden={this.state.ix !== 5}>
                    <div className="flex-row">
                        <div className="flex flex-column" style={{padding: "0px 5px"}}>
                            <span className="header">Produkt beskrivning</span>
                            <textarea className="form-control" maxLength="512" rows="10" value={this.state.pbo.pluanm} onChange={(e) => this.pluanm_change(e)}></textarea>
                        </div>
                    </div>
                    <div className="flex-row px-3">
                        <div className="web-link" onClick={() => this.pluanm_get() }>Hämta</div>
                        <div className="flex"></div>
                        <div className="web-link" onClick={() => this.pluanm_clear() }>Rensa</div>
                        <div className="flex"></div>
                        <div className="web-link" onClick={() => this.pluanm_del() }>Ta Bort</div>
                        <div className="flex"></div>
                        <div className="web-link" onClick={() => this.pluanm_upd() }>Spara</div>
                    </div>
                </div>
        </form>

        </div>
        : null }
        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex-row">
            <button className="btn btn-hw-blue w250" onClick={(event) => this.plu_split(event) } >
                FÖRDELA
            </button>
            <Dd_BtnSel text="FUNKTIONER" height={window.innerHeight*0.9} cls="w250" items={ this.plufkns } getValue={(e)=> this.sel_fkn(e)} />
            </div>
            <button className="btn btn-hw-green w250" onClick={(event) => this.plu_save(event) } >
                SPARA
            </button>
        </div>
      </div>

            { this.state.wnds.pubplu ? <Prd_Pub wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
            { this.state.wnds.pluingny ? <Prd_ingny wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
            { this.state.wnds.pluingadd ? <Prd_ingadd wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
            { this.state.wnds.pluingred ? <Prd_ingred wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
            { this.state.wnds.mnuadd ? <Prd_Menu wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
            { this.state.wnds.mnuedit ? <Prd_Menu_Edit wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
    <Wnd_Error msg={this.state.msg} ctx={this} />
    <Wnd_Ok msg={this.state.msg} ctx={this} />
    <Wnd_Info msg={this.state.msg} ctx={this} />
    <Wnd_Confirm msg={this.state.msg} ctx={this} />

    </div>
	;
	return html;
  };
}

export default Prd_Open;
