import React, { useState, useEffect, useContext } from 'react';
import { format } from "date-fns";

import * as utils from "../../../assets/utils";
import * as net from "../../../lib/net";

import Dt_Cal from "../../../lib/ui/dt_cal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function Oms_Trs(props) {
    return(
        <div className="flex-row flex">
        <div className="flex">
            <table className="table table-bordered table-hover tb fixed_header">
                <thead>
                <tr>
                    <th>Typ</th>
                    <th>Konto</th>
                    <th>Text</th>
                    <th>Antal</th>
                    <th>Pris</th>
                    <th>Tkn</th>
                </tr>
                </thead>
                <tbody style={ props.ctx.tb_style() }>
                { Object.values(props.ctx.state.fsg).map((row, key) =>
                    <tr key={key} onDoubleClick={() => this.trans_open(row) }>
                        <td>{ row.typ }</td>
                        <td>{ row.konto }</td>
                        <td>{ utils.toLang( row.text ) }</td>
                        <td>{ utils.toNum( row.antal ) }</td>
                        <td>{ utils.toPris( row.belopp ) }</td>
                        <td>{ row.tkn }</td>
                    </tr>
                )}
                </tbody>
            </table>
        </div>
        <div className="flex">
            <table className="table table-bordered table-hover tb fixed_header">
                <thead>
                <tr>
                    <th>Typ</th>
                    <th>Konto</th>
                    <th>Text</th>
                    <th>Antal</th>
                    <th>Pris</th>
                    <th>Tkn</th>
                </tr>
                </thead>
                <tbody style={ props.ctx.tb_style() }>
                { Object.values(props.ctx.state.bet).map((row, key) =>
                    <tr key={key} onDoubleClick={() => this.trans_open(row) }>
                        <td>{ row.typ }</td>
                        <td>{ row.konto }</td>
                        <td>{ utils.toLang( row.text ) }</td>
                        <td>{ utils.toNum( row.antal ) }</td>
                        <td>{ utils.toPris( row.belopp ) }</td>
                        <td>{ row.tkn }</td>
                    </tr>
                )}
                </tbody>
            </table>
        </div>
        </div>
    );
}

function Oms_Konto(props) {
    return(
        <div className="flex">
            <table className="table table-bordered table-hover tb fixed_header">
                <thead>
                <tr>
                    <th>Konto</th>
                    <th>Text</th>
                    <th>Antal</th>
                    <th>Pris</th>
                    <th>Tkn</th>
                </tr>
                </thead>
                <tbody style={ props.ctx.tb_style() }>
                { Object.values(props.ctx.state.kto).map((row, key) =>
                    <tr key={key} onDoubleClick={() => this.trans_open(row) }>
                        <td>{ row.konto }</td>
                        <td>{ utils.toLang( row.text ) }</td>
                        <td>{ utils.toNum( row.antal ) }</td>
                        <td>{ utils.toPris( row.belopp ) }</td>
                        <td>{ row.tkn }</td>
                    </tr>
                )}
                </tbody>
            </table>
        </div>
    );
}

class Eko_Oms extends React.Component {
  constructor(props, context) {
    super(props);

    this.dt_fd = React.createRef();
    this.dt_td = React.createRef();

    this.webStore = props.store;
    this.state = { pgid: 11, tra: [], tsa: [], tta: [] };
    this.state.fkn = "TRS";
    this.state.today = new Date();
    this.state.sortid = "";

    var dts = format(this.state.today, "yyyy-MM-dd");
    this.state.fdat = dts;
    this.state.tdat = dts;

    this.state.kto = {};
    this.state.fsg = {};
    this.state.bet = {};
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.trans();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 250;
      return css;
  }
  tb2_style() {
      var css = {};
      css.height = window.innerHeight - 650;
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }
  dt_fdat(e) {
    //alert(JSON.stringify(e));
    var dts = format(e, "yyyy-MM-dd");
    this.setState({ fdat: dts });
  }
  dt_tdat(e) {
      //alert(JSON.stringify(e));
    var dts = format(e, "yyyy-MM-dd");
    this.setState({ tdat: dts });
  }
  oneday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_td.current.updateDt(this.state.fdat);
  }
  addday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_fd.current.updateAdd();
    this.dt_td.current.updateAdd();
  }
  subday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_fd.current.updateSub();
    this.dt_td.current.updateSub();
  }

  change_fkn(e) {
    //let cdat = this.state.seldate;
    //alert(JSON.stringify(e));
    let fkn = e.target.value;
    this.setState({fkn: fkn});
    this.setState({tra: [], tsa: [], tta: [] });
  }

  dt_fkn() {
    var self = this;

    if(this.state.fkn == "TRS") this.dt_oms_tr();
    else if(this.state.fkn == "KTO") this.dt_oms_konto();
    else if(this.state.fkn == "TOT") this.dt_oms_tot();
    else this.dt_oms_tr();
   }

  dt_oms_tr() {
    var self = this;

    var prm = {};
    //prm.req = "pms.eko_red.red_hbok";
    prm.req = "pms.eko_red.red_oms_trs";
    prm.fdat = this.state.fdat;
    prm.tdat = this.state.tdat;
    alert(JSON.stringify(prm));

    //net.sid_req(prm)
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            return;
        }
        var sdo = ret.rco;
        //alert(JSON.stringify(sdo.bet));
        self.setState({ fsg: sdo.fsg, bet: sdo.bet });
        //self.setState({tra: ret.rca });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
    }

  dt_oms_konto() {
    var self = this;
    var prm = {};
    //prm.req = "pms.eko_red.red_hkonto";
    prm.req = "pms.eko_red.red_oms_konto";
    prm.fdat = this.state.fdat;
    prm.tdat = this.state.tdat;
    alert(JSON.stringify(prm));

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        if(ret.ok != "000") {
            return;
        }
        var sdo = ret.rco;
        self.setState({kto: sdo.kto });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
    }
  dt_hsum() {
    var self = this;
    var prm = {};
    prm.req = "pms.eko_red.red_hsum";
    prm.fdat = this.state.fdat;
    prm.tdat = this.state.tdat;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        if(ret.ok != "000") {
            return;
        }

        self.setState({tra: ret.rca });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
    }
  dt_htot() {
    var self = this;
    var prm = {};
    prm.req = "pms.eko_red.red_htot";
    prm.fdat = this.state.fdat;
    prm.tdat = this.state.tdat;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        if(ret.ok != "000") {
            return;
        }

        self.setState({tra: ret.rca });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
    }

  render() {
    return (
    <div className="web-app px-1">
    <div className="">
        OMSÄTTNING
    </div>

    <div className="flex-row flex-space-between">
        <div className="flex-row">
        <div className="flex-row">
              <div className="dt-btn-01" onClick={() => this.subday() } ><FontAwesomeIcon icon="minus" size="1x" /></div>
              <Dt_Cal startdatum="2020-02-01" rp="1" ref={this.dt_fd} getValue={(e)=> this.dt_fdat(e)} />
              <div className="dt-btn-01" onClick={() => this.oneday() } >1</div>
              <Dt_Cal startdatum="2020-02-01" rp="1" ref={this.dt_td} getValue={(e)=> this.dt_tdat(e)} />
              <div className="dt-btn-01" onClick={() => this.addday() } ><FontAwesomeIcon icon="plus" size="1x" /></div>
        </div>
            <select className="form-control sel_input" placeholder="Välj funktion" value={this.state.fkn} onChange={(e) => this.change_fkn(e)} >
                <option value="TRS">PER TRANSAKTION</option>
                <option value="KTO">PER KONTO</option>
            </select>
        </div>
        <button className="btn btn-hw-blue w150" onClick={() => this.dt_fkn() } aria-label="System" >
            Visa
        </button>
    </div>
    <div className="divider"></div>

    { ( this.state.fkn == "TRS") ? <Oms_Trs ctx={this} fkn={this.state.fkn} /> : null }
    { ( this.state.fkn == "KTO") ? <Oms_Konto ctx={this} fkn={this.state.fkn} /> : null }

    </div>
  );
}
}
export default Eko_Oms;

/*
            <Dt_Cal startdatum="2020-02-01" getValue={(e)=> this.dt_fdat(e)} />
            <Dt_Cal startdatum="2020-02-01" getValue={(e)=> this.dt_tdat(e)} />
        <Oms_lt ctx={this} fkn={this.state.fkn} tra={this.state.tra} />
*/