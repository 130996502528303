import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";
import * as utils from "../../assets/utils";
import Lang from "../../lib/lang";

import Dt_Cal from "../../lib/ui/dt_cal";
import Td_Sel from "../../lib/ui/td_sel";
import Dd_Sel from "../../lib/ui/dd_sel";
import ddo from "../../ddo";

import Ingred_Add from "./fkn/ingred_add";
import Ingred_Edit from "./fkn/ingred_edit";
import Ingred_Pub from "./fkn/ingred_pub";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Pos_Ingred extends React.Component {
  constructor(props, context) {
    super(props);

    this.store = props.store;

    this.state = {};
    this.state.seldate = new Date();
    this.state.avd = ddo.cache.avd;
    this.state.maa = [];
    this.state.aa = [];
    this.state.mno = {};
    this.state.wnds = {};
      this.state.mna = [];
      this.state.selrow = {};
      this.state.wnds = {};
      this.state.sortid = "";
      this.state.sortdir = 1;
      this.state.tabval = 1;

      this.selitems = [
          {fkn: 'open', text: 'ÖPPNA'},
          {fkn: 'cancel', text: 'AVBOKA'}
      ];
      this.fknitems = [
          {fkn: 'ingred_import', text: 'IMPORTERA INGREDIENSER'},
          {fkn: 'ingred_pub', text: 'PUBLICERA INGREDIENSER'}
      ];
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
    componentDidMount() {
        //this.ingreds();
        this.extras();
    }

    body_style() {
        var css = {};
        css.height = (window.innerHeight * 1) - 280;
        return css;
    }
    tb_style() {
        var css = {};
        css.height = (window.innerHeight * 1) - 250;
        css.overflow = "auto";
        return css;
    }
    th_style(id) {
        var css = {};
        css.cursor = "pointer";
        if(id == this.state.sortid) {
            if(this.state.sortdir) css.color = "#800";
            else css.color = "#080";
        }
        return css;
    }
    row_style(row) {
        var srow = this.state.selrow;

        var css = {};
        if(srow == row) {
            css.background = "#080";
            css.color = "#fff";
        }
        return css;
    }
    onUnitChange(e) {
        this.setState({unit: e.value});
    }

    ingred_add() {
        var self = this;

        var wnds = {};
        wnds.ingredadd = true;
        wnds.cb = this.cb_wnds;
        this.setState({wnds: wnds});
    }
    cb_wnds(ctx) {
        ctx.setState({wnds: {}});
        ctx.ingreds();
    }
    ingred_edit(row) {
        var self = this;

        var wnds = {};
        wnds.ingrededit = true;
        wnds.igo = row;
        wnds.cb = this.cb_wnds;
        this.setState({wnds: wnds});
    }

    ingreds() {
        var self = this;
        var prm = {};
        prm.req = "pos.pos_ingred.ingred_list";
        prm.typ = "I";

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.rca));

                self.setState({ mna: ret.rca, tabval: 0 });
                //self.setState({ rva: ret.rca });
                self.syncTb("mnuTable");
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    extras() {
        var self = this;
        var prm = {};
        prm.req = "pos.pos_ingred.ingred_list";
        prm.typ = "E";

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.rca));

                self.setState({ mna: ret.rca, tabval: 1 }, function() {
                    self.syncTb("mnuTable");
                });
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    syncTb(tb) {
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
            tdElements = tbel.getElementsByTagName('td');
        if(tdElements.length < 1 ) return;

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
                width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
    sel_row(row) {
        var srow = this.state.selrow;
        if(srow == row) {
            this.setState({selrow: {}});
        }
        else this.setState({selrow: row});
    }
    sel_row_fkn(row, sel) {
        if(sel.fkn == "open") {
            this.sel_gst(row);
        }
    }
    gst_close(ctx) {
        ctx.setState({ wnds: {} });
    };
    tb_sort(typ) {
        var self = this;
        //var rvs = self.state.rvs;
        var mna = self.state.mna;
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;

        if(sortid != typ) sortdir = 0;
        else {
            if(sortdir) sortdir = 0;
            else sortdir = 1;
        }

        if(typ.indexOf(".") != -1) {
            var fa = typ.split(".");
            if(sortdir) mna.sort((a,b) => (a[fa[0]][fa[1]] < b[fa[0]][fa[1]]) ? 1 : ((b[fa[0]][fa[1]] < a[fa[0]][fa[1]]) ? -1 : 0));
            else mna.sort((a,b) => (a[fa[0]][fa[1]] > b[fa[0]][fa[1]]) ? 1 : ((b[fa[0]][fa[1]] > a[fa[0]][fa[1]]) ? -1 : 0));
        }
        else {
            if(sortdir) mna.sort((a,b) => (a[typ] < b[typ]) ? 1 : ((b[typ] < a[typ]) ? -1 : 0));
            else mna.sort((a,b) => (a[typ] > b[typ]) ? 1 : ((b[typ] > a[typ]) ? -1 : 0));
        }
        self.setState({ mna: mna, sortid: typ, sortdir: sortdir });
    }
    th_icon(typ) {
        var cls = "";
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;
        if(sortid == typ)
        {
            if(sortdir) cls = "fa fa-caret-up";
            else cls = "fa fa-caret-down";
        }
        return cls;
    }
    sel_fkn(sel) {
        //alert(JSON.stringify(fkn));
        if(sel.fkn == "ingred_import") {
            //this.ingred_import();
        }
        if(sel.fkn == "ingred_pub") {
            this.ingred_pub();
        }
    }
    ingred_pub() {
        var self = this;
        var wnds = {};
        wnds.ingredpub = 1;
        wnds.avd = ddo.cache.avd;
        self.setState({ wnds: wnds });
    }
    tab_head(tabid) {
        var css = {};
        css.background = "#fff";
        css.color = "#000";
        if(this.state.tabval === tabid) {
            css.background = "#080";
            css.color = "#fff";
        }
        return css;
    }
    tab_style(tabid) {
        var css = {};
        css.display = "none";
        if(this.state.tabval === tabid) css.display = "block";
        return css;
    }
    tab_sel(tabid) {
        var self = this;
        this.setState({tabval: tabid}, function() {
            if(tabid == 1) {
                self.syncTb("mnpTable");
            }
        });
    }

    render() {
        return (
            <div className="web-bas">

                <div className="flex-row">
                    <button className="btn btn-hw-blue w200" style={ this.tab_head(0) } onClick={() => this.ingreds() } aria-label="System" >
                        INGREDIENSER
                    </button>
                    <button className="btn btn-hw-blue w200" style={ this.tab_head(1) } onClick={() => this.extras() } aria-label="System" >
                        EXTRA INGREDIENSER
                    </button>
                    <div className="flex"></div>
                    <button className="btn btn-hw-orange w200" onClick={() => this.ingred_add() } aria-label="System" >
                        LÄGG TIL
                    </button>
                    <Dd_Sel items={ this.fknitems } cls="web-top-icon" getValue={(e)=> this.sel_fkn(e)} />
                </div>
                <div className="divider_green"></div>

                <div className="web-row">

                    <div id="mnuTable" className="flex">
                        <table className="table table-bordered table-hover" style={{marginBottom: "0px"}}>
                            <thead>
                            <tr>
                                <th style={ this.th_style("p0typ") } onClick={() => this.tb_sort("p0typ") }><Lang>Typ</Lang> <i className={ this.th_icon("p0typ") }></i></th>
                                <th style={ this.th_style("p0ingnr") } onClick={() => this.tb_sort("p0ingnr") }><Lang>ID</Lang> <i className={ this.th_icon("p0ingnr") }></i></th>
                                <th style={ this.th_style("p0artid") } onClick={() => this.tb_sort("p0artid") }><Lang>Artikel ID</Lang> <i className={ this.th_icon("p0artid") }></i></th>
                                <th style={ this.th_style("p0text") } onClick={() => this.tb_sort("p0text") }><Lang>Text</Lang> <i className={ this.th_icon("p0text") }></i></th>
                                <th style={ this.th_style("p0pris") } onClick={() => this.tb_sort("p0pris") }><Lang>Pris</Lang> <i className={ this.th_icon("p0pris") }></i></th>
                                <th style={ this.th_style("p0sort") } onClick={() => this.tb_sort("p0sort") }><Lang>Sortering</Lang> <i className={ this.th_icon("p0sort") }></i></th>
                                <th style={ this.th_style("status") } onClick={() => this.tb_sort("status") }><Lang>Status</Lang> <i className={ this.th_icon("status") }></i></th>
                                <th></th>
                            </tr>
                            </thead>
                        </table>
                        <div style={ this.tb_style() }>
                            <table className="table table-bordered table-hover">
                                <tbody>
                                { this.state.mna.map((row, key) =>
                                    <tr key={key} style={ this.row_style(row) } onClick={() => this.sel_row(row)} onDoubleClick={ () => this.ingred_edit(row) }>
                                        <td>{ row.p0typ }</td>
                                        <td>{ row.p0ingnr }</td>
                                        <td>{ row.p0artid }</td>
                                        <td>{ utils.toLang( row.p0text ) }</td>
                                        <td className="text-center">{ utils.toPris( row.p0pris ) }</td>
                                        <td>{ row.p0sort }</td>
                                        <td>{ row.status }</td>
                                        <td>
                                            <Td_Sel items={ this.selitems } getValue={(e)=> this.sel_row_fkn(row, e)} />
                                        </td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                { this.state.wnds.ingredadd ? <Ingred_Add wnds={this.state.wnds} store={this.store} ctx={this} /> : null}
                { this.state.wnds.ingrededit ? <Ingred_Edit wnds={this.state.wnds} store={this.store} ctx={this} /> : null}
                { this.state.wnds.ingredpub ? <Ingred_Pub wnds={this.state.wnds} store={this.store} ctx={this} /> : null}
            </div>
  );
    }
}
export default Pos_Ingred;
