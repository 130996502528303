import React, { useState, useEffect, useContext } from 'react';
import {Route, Routes} from "react-router-dom";

import Wrk_Home from './wrk/home.js';
import Wrk_Kund from './wrk/kund.js';
import Wrk_Lev from './wrk/lev.js';
import Wrk_Tax from './wrk/tax.js';
import {withRouter} from "../lib/react/withRouter";

function pg_home() {

    var html =
    <div className="web-content">
      Hem
    </div>
    ;
    return html;
}

class Bfs_Wrk extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.state = {};
  }
  fknGo(row) {
    //alert(row.href);
    this.props.navigate(row.url);
  }

  render() {
      var path = window.location.pathname;
      if(path.substr(-1) == "/") path = path.substring(0, path.length - 1);
      var op = path.split("/").pop();
      var po = {};
      po.tax = { "fid": "f04", "url": "/bfs/wrk/tax", "name": "Moms", "active": false };
      po.kund = { "fid": "f04", "url": "/bfs/wrk/kund", "name": "Kund", "active": false };
      po.lev = { "fid": "f04", "url": "/bfs/wrk/lev", "name": "Leverantör", "active": false };
      if(po[op]) {
          po[op].active = true;
          this.props.store.fkn = po[op];
      }
      var pa = Object.values(po);

    return (

    <div className="web-app">
         { this.props.store.cfg.bread ?
             <div className="web-bread">
                 <div className="breadcrumb">
            { pa.map((row, key) =>
                <div key={key} className="breadcrumb-item" active={row.active} onClick={() => this.fknGo(row)}>{row.name}</div>
            )}
            <div className="flex"></div>
            <div className="cdigreen">{ this.props.store.fkn ? this.props.store.fkn.name : null }</div>
            </div>
        </div>
        : null }
        <Routes>
          <Route path="/xx" component={pg_home} />
            <Route path="kund" element={ <Wrk_Kund store={this.store} />} />
            <Route path="lev" element={ <Wrk_Lev store={this.store} />} />
            <Route path="tax" element={ <Wrk_Tax store={this.store} />} />
            <Route path="*" element={ <Wrk_Home store={this.store} />} />
        </Routes>
    </div>

  );
}
}
//Sys.contextType = ADOContext;

export default withRouter(Bfs_Wrk);
