import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";
import Dt_Cal from "../../lib/ui/dt_cal";
import ddo from '../../ddo.js'

import Dep_Edit from "./deps/dep_edit";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Reg_Deps extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, tra: [] };
    this.state.seldate = new Date();
    this.state.avd = ddo.cache.avd;
    this.state.dpa = [];
    this.state.depedit = false;
    this.state.wnds = {};
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.deps();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 480;
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

  dep_edit(row) {
    var self = this;
    var wnds = {};
    wnds.depedit = true;
    wnds.dpo = row;
    wnds.backdrop = true;
    self.setState({ wnds: wnds });
  }

  deps() {
    var self = this;
    var prm = {};
    prm.req = "pos.pos_dep.deps";
    //prm.fplu = datum;
    //prm.tplu = datum;

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        var o = {};
        //o.deps = ret.rco;
        //alert(JSON.stringify(o));
        self.setState({dpa: ret.rco });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }

  render() {
    return (
    <div className="web-app">

    <div className="flex-row">
        <Dt_Cal startdatum="2020-02-01" getValue={(e)=> this.setState({seldate: e}) }/>
        <div className="flex"></div>

        <button className="btn btn-hw-blue w150 ripple" onClick={() => this.plus() } aria-label="System" >
            Visa
        </button>
    </div>
    <div className="divider_orange"></div>

    <div className="web-row">
        <table className="table table-bordered table-hover tb fixed_header">
            <thead>
            <tr>
                <th>Avd</th>
                <th>Text</th>
                <th>Orgnr</th>
                <th>Nota</th>
                <th>Typ</th>
                <th>Upd</th>
                <th>Sign</th>
                <th style={{width:"40px"}} ></th>
            </tr>
            </thead>
            <tbody style={ this.tb_style() }>
            { this.state.dpa.map((row, key) =>
            <tr key={key} onDoubleClick={() => this.dep_edit(row) }>
                <td>{ row.kst00.k0kstns }</td>
                <td>{ row.kst00.k0txt }</td>
                <td>{ row.kst00.k0orgnr }</td>
                <td>{ row.kst00.k0notatyp }</td>
                <td>{ row.kst00.k0typ }</td>
                <td>{ row.kst00.k0upddat }</td>
                <td>{ row.kst00.k0sign }</td>
                <td style={{width:"40px",cursor:"pointer"}} onClick={() => this.dep_edit(row) }><i className="fa fa-cog text-danger"></i></td>
            </tr>
            )}
            </tbody>
        </table>
    </div>

    { this.state.wnds.depedit ? <Dep_Edit wnds={ this.state.wnds } ctx={this} store={this.props.store} backdrop={true}/> : null }
    </div>
  );
    }
}
export default Reg_Deps;
