import React, { useState, useEffect, useContext } from 'react';
import {format} from "date-fns";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import * as net from "../../../lib/net";

import Dd_Sel from "../../../lib/ui/dd_sel";
import Dt_Cal from "../../../lib/ui/dt_cal";
import Wnd_Error from "../../../lib/ui/wnd_error";
import Wnd_Ok from "../../../lib/ui/wnd_ok";
import Wnd_Info from "../../../lib/ui/wnd_info";
import Wnd_Confirm from "../../../lib/ui/wnd_confirm";
import * as utils from "../../../assets/utils";
import PdfRpDlg from "../../lib/pdf_rp_dlg";

class Pres_Reg extends React.Component {
  constructor(props, context) {
    super(props);

    this.dt_dt = React.createRef();

    this.selitems = [
        {fkn: 'bevaka', text: 'BEVAKA'},
        {fkn: 'bokbild', text: 'BOKNINGSBILD'},
        {fkn: 'confirm', text: 'BEKRÄFTA'},
        {fkn: 'cancel', text: 'AVBOKA'}
    ];

    this.store = props.store;
    this.pms = props.store.pms;

    this.state = {};
    this.state.ynq = [];
    //this.state.vga = [];
    this.state.rta = [];
    this.state.bra = [];

    this.state.dta = [];
    this.state.pra = [];
    this.state.prs = [];

    this.state.tot = {};

    this.state.krubba = 0;
    this.state.ix = 0;
    this.state.boknr = "";
    this.state.datum = "";
    this.state.sz_val = "";

    this.state.today = new Date();

    this.state.dts = format(this.state.today, "yyyy-MM-01");

    this.state.msg = {};
    this.state.wnds = {};
    this.state.rvo = {};

    if(props.wnds) {
        //if(props.wnds.boknr) this.state.boknr = props.wnds.boknr;
    }
    this.state.pdf = 0;
    this.state.pdf_content = null;
    this.state.loading = 0;

  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      this.reg_wrk();
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
  val_pris(val) {
      var s = "";
      if(val == 0) s = "0.00";
      if(val) s = val.cdipris();
      return s;
  };

    formchange (e) {
      const name = e.target.name;
      const value = e.target.value;
      var rvo = this.state.rvo;
      var na = name.split(".");
      rvo[na[0]][na[1]] = value;
      this.setState({rvo: rvo});
      //setState({[name]: value});
    }

    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    wnd_close = () => {
      if(this.props.close) {
          this.props.close(this.props.ctx);
      }
      else this.props.ctx.setState({ wnds: {} });
	};
  tb_style() {
    var css = {};
    css.height = (window.innerHeight * 0.9) - 205;
    css.overflow = "auto";
    return css;
}
  val_save (name, value) {
        //alert(JSON.stringify(name));
        //alert(JSON.stringify(value));
      var rvo = this.state.rvo;
      var na = name.split(".");
      rvo[na[0]][na[1]] = value;
      this.setState({rvo: rvo});
      //setState({[name]: value});
    }
  dt_dat(e) {
      //alert(JSON.stringify(e));
    var dts = format(e, "yyyy-MM-dd");
    this.setState({ dts: dts });
  }
  addday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_dt.current.updateAdd();
  }
  subday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_dt.current.updateSub();
  }
  gst_open(row) {
    //alert(JSON.stringify(row));
    var wnds = {};
    wnds.saldogst = true;
    wnds.boknr = row.boknr;
    wnds.glopnr = row.glopnr;
    this.setState({wnds: wnds});
  }

  rp_reg() {
    var self = this;

    this.setState({ loading: 1 });

    var prm = {};
    prm.req = "crs.crs_reg.rp_reg";
    prm.datum = this.state.dts;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.ok));

        //var uri = "data:application/pdf;base64," + ret.pdf;
        self.setState({pdf: 1, pdf_content: ret.pdf });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
}

reg_wrk() {
    var self = this;

    var msg = { info: true, text: "Hämtar Transaktioner ..."};
    self.setState({ msg: msg });

    //var fdatum = format(this.state.fdat, "yyyy-MM-dd");
    //var tdatum = format(this.state.tdat, "yyyy-MM-dd");
    self.setState({ dta: [], rva: [], krubba: 0 });

    var prm = {};
    prm.req = "crs.crs_reg.pres_reg_wrk";
    prm.datum = this.state.dts;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.tot));

        if(ret.ok != "000") {
            self.setState({ msg: {} });
            return;
        }

        var pra = ret.rca;
        var tot = ret.tot;
        self.setState({ pra: pra, prs: pra, tot: tot, msg: {} }, function() {
            self.syncTb("trsTable");
        });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
    }
    syncTb(tb){
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
        tdElements = tbel.getElementsByTagName('td');

        for (let i = 0; i < thElements.length; i++) {
            if(!thElements[i]) continue;
            if(!tdElements[i]) continue;
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
            width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
    sz_change (e) {
      const name = e.target.name;
      const value = e.target.value;
      this.setState({sz_val: value});
      //setState({[name]: value});
  }
  sz_keydown (e) {
    //e.preventDefault();
      const key = e.key;
      const keyCode = e.keyCode;

      if(keyCode == 13) {
          this.tb_filtr();
      };
      if(keyCode == 27) {
          this.setState({sz_val: ""});
      };
  }
tb_filtr() {
    var self = this;
    var prs = self.state.prs;
    var sz = this.state.sz_val;

    if(sz.length < 1) {
      self.setState({ pra: prs });
        return;
    }
    sz = sz.toUpperCase();
    var pra = prs.filter(function(row) {
          var brc = false;
          if(row.id.toUpperCase().includes(sz)) brc = true;
          if(row.text.toUpperCase().includes(sz)) brc = true;
          if(row.pres00.p0belopp.toUpperCase().includes(sz)) brc = true;
          return brc;
    });
    self.setState({pra: pra });
 }
 pdf_done (ret) {
  this.setState({loading: 0, pdf: 0, pdf_content: null });
  }

  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "Bokning";

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="mx_backdrop" /> : null }
        <div className="mx_dlg mx_90 flex-col" style={ this.dlg_style() }>
        <div className="mx_h01">
            <div>REGISTER PRESENTKORT</div>
        </div>

            <div className="divider_orange hide"></div>

            <div className="flex-row">
            <input className="txt sztxt" value={this.state.sz_val} onChange={(e) => this.sz_change(e)} onKeyDown={(e) => this.sz_keydown(e)} />
                <div className="flex"></div>
            <div className="flex-row">
                  <div className="dt-btn-01" onClick={() => this.subday() } ><FontAwesomeIcon icon="minus" size="1x" /></div>
                  <Dt_Cal dts={ this.state.dts } rp="1" ref={this.dt_dt} getValue={(e)=> this.dt_dat(e)} />
                  <div className="dt-btn-01" onClick={() => this.addday() } ><FontAwesomeIcon icon="plus" size="1x" /></div>
            </div>
                <div className="flex"></div>
                <button className="btn btn-hw-blue w150" onClick={() => this.rp_reg() } aria-label="System" >
                    Skriv ut
                </button>
                <button className="btn btn-hw-blue w150" onClick={() => this.reg_wrk() } aria-label="System" >
                    Visa
                </button>
            </div>

       <div className="mx_content flex">

        <div id="trsTable">
          <table className="table table-bordered table-hover" style={{marginBottom: "0px"}}>
            <thead>
            <tr>
                <th>ID</th>
                <th>Text</th>
                <th>Ingående Belopp</th>
                <th>Aktuellt Saldo</th>
                <th>Trans Saldo</th>
                <th>Skapat</th>
                <th>Använt</th>
                <th>Giltigt</th>
            </tr>
            </thead>
            </table>
            <div style={ this.tb_style() }>
          <table className="table table-bordered table-hover">
            <tbody>
            { this.state.pra.map((row, key) =>
            <tr key={key} onDoubleClick={() => this.tro_open(row) }>
                <td>{ utils.toNum(row.id) }</td>
                <td>{ utils.toLang(row.text) }</td>
                <td>{ utils.toPris(row.belopp) }</td>
                <td>{ utils.toPris(row.saldo) }</td>
                <td>{ utils.toPris(row.trsaldo) }</td>
                <td>{ utils.toDate(row.datum) }</td>
                <td>{ utils.toDate(row.used) }</td>
                <td>{ utils.toDate(row.expire) }</td>
            </tr>
            )}
            </tbody>
        </table>
        </div>
        </div>
           <div className="divider_green"></div>
           <div className="flex-row px-5">
               <div className="flex">TOTALER:</div>
               <div className="px-5">SUMMA: { utils.toPris(this.state.tot.belopp) }/{ utils.toNum(this.state.tot.nrof) }</div>
               <div className="px-5">MANUELLA: { utils.toPris(this.state.tot.usedbelopp) }/{ utils.toNum(this.state.tot.used) }</div>
               <div className="px-5">SALDO: { utils.toPris(this.state.tot.saldo) }/{ utils.toNum(this.state.tot.active) }</div>
               <div className="px-5">TRANS SALDO: { utils.toPris(this.state.tot.trsaldo) }/{ utils.toNum(this.state.tot.trs) }</div>
           </div>

            </div>

            <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex-row">
            </div>
        </div>
      </div>
            <Wnd_Error msg={this.state.msg} ctx={this} />
            <Wnd_Ok msg={this.state.msg} ctx={this} />
            <Wnd_Info msg={this.state.msg} ctx={this} />
            <Wnd_Confirm msg={this.state.msg} ctx={this} />
            <PdfRpDlg show={this.state.pdf} pdf={this.state.pdf_content} done={(ret) => this.pdf_done(ret)} backdrop={true}/>

    </div>
	;
	return html;
  };
}

export default Pres_Reg;