import React, { useState, useEffect, useContext } from 'react';
import ReactDOM from 'react-dom';
import { SDOContext } from '../sdo.js'
import { Chart } from 'chart.js'

import * as net from "../lib/net";

function web01() {

    var html =
    <div className="">
        Test
    </div>
    ;
    return html;
}

class Ta_Dash extends React.Component {
    //var sdo = useContext(SDOContext); // Functional
    static contextType = SDOContext; // Class

    constructor(props, context) {
        super(props);

        this.s1ref = React.createRef();
        this.s2ref = React.createRef();

        this.my_bar = null;
        this.my_pie = null;
        this.my_stack = null;
        this.my_line = null;
        this.webStore = props.store;
        this.state = { pgid: 11, stat: [] };
        this.state.open = false;
        this.state.pdfopen = false;
        this.state.xxopen = false;
        this.state.pdf_uri = "";
        this.state.seldate = new Date('2020-03-24T13:11:54');
        this.state.row = null;
        this.state.info = "";
        this.state.top = 1;
        this.state.user = {};
        this.state.sdo = {};
        //this.state.sdo = context;
        //context.posrend = this;
        this.state.step = 1;
        this.state.pdf = 0;
        this.state.pdf_uri = "";
        this.state.mnus = ["m00", "m01", "m02", "m03", "m08", "m09"];
        this.state.mnu_sel = "m00";

        this.chartColors = {
            navy: '#4755AB',
            green: '#03c895',
            danger: '#ff4f81',
            blue: '#4A496E',
            purple: '#B162AC',
            grey: '#E7EDF6'
        };
    }
    componentDidMount(){
        //var sdo = sessionStorage.getItem("svp");
        //if(sdo) this.setState({sdo: JSON.parse(sdo) });
        //this.cdi_step();
        this.bar_chart();
        this.pie_chart();
        this.stack_chart();
        this.line_chart();
    }

    componentWillUnmount(){
            this.setState({info: "UUU" });
    }
    onScroll(event) {
        var body = document.getElementById("web-body");
        if (body.scrollTop > 150) {
          this.setState({top: 0})
          this.setState({info: 'small'})
        } else {
          this.setState({top: 1})
          this.setState({info: 'big:'+body.scrollTop})
        }

        if (true) {
            var offset = body.scrollTop;
            var start = body.scrollTop;
            var slut = body.scrollTop + window.innerHeight;

            for(var mnuid of this.state.mnus) {
                const element = document.getElementById(mnuid);
                if(!element || (element == null) || (element == undefined)) continue;
                var eltop = element.offsetTop;
                var elbot = element.offsetTop + element.offsetHeight;
                var brc = eltop >= start && eltop <= slut;
                //if(!brc) brc = elbot >= start && elbot <= slut;
                if(brc) {
                    this.setState({mnu_sel: mnuid});
                    return;
                }
            }
        }
    }
   header_css() {
  	    var css = {};
		if(this.state.top) {
			css.color = "#fff";
			css.background = "transparent";
			css.height = "50px";
			//css["background"] = "green";
		}
		else {
			css.color = "#000";
			css.background = "#fff";
		    css.height = "50px";
        }

		return css;
    }
    header_class() {
  	    var cls = "flex_col";
		if(this.state.top) {
			cls = cls + " web-head_1";
		}
		else cls = cls +  " web-header";

		return cls;
    }

    pie_chart() {
        var config = {
            type: 'pie',
            data: {
                datasets: [{
                    data: [
                        25,
                        38,
                        66,
                        15,
                        90,
                        75,
                    ],
                    backgroundColor: [
                        this.chartColors.navy,
                        this.chartColors.blue,
                        this.chartColors.purple,
                        this.chartColors.danger,
                        this.chartColors.green,
                        this.chartColors.grey,
                    ],
                    label: 'Dataset 1'
                }],
                labels: [
                    'Mat',
                    'Vatten',
                    'Sarköl',
                    'Vin',
                    'Sprit',
                    'Kaffe'
                ]
            },
            options: {
                responsive: true
            }
        };

        var ctx4 = document.getElementById('piechart').getContext('2d');
        this.my_pie = new Chart(ctx4, config);
	};
    stack_chart() {
        this.my_stack =  new Chart(document.getElementById("stackedbar"), {
			type: 'bar',
			data: {
				labels: ['Januari', 'Februari', 'Mars', 'April', 'Maj', 'Juni', 'Juli', 'Augusti', 'September'],
				datasets: [{
					data: [10,20,30,40,50,60,70,80],
					label: 'Kontant',
					backgroundColor: this.chartColors.navy,
					borderWidth: 1,
				}, {
					data: [30,10,70,15,30,20,70,80],
					label: 'Kort',
					backgroundColor: this.chartColors.purple,
					borderWidth: 1,
				}, {
					data: [20,30,40,50,60,70,80,80],
					label: 'Swish',
					backgroundColor: this.chartColors.grey,
				}]
			},
			options: {
				// title: {
				// 	display: true,
				// 	text: 'Chart.js Bar Chart - Stacked'
				// },
				tooltips: {
					mode: 'index',
					intersect: false
				},
				responsive: true,
				scales: {
					x: {
						stacked: true,
					},
					y: {
						stacked: true
					}
				}
			}
		});
	};
    line_chart() {
        this.my_line =  new Chart(document.getElementById("linechart"), {
            type: 'line',
            data: {
				labels: ['Januari', 'Februari', 'Mars', 'April', 'Maj', 'Juni', 'Juli', 'Augusti', 'September'],
                datasets: [{
                    label: 'Snitt per timme',
                    backgroundColor: this.chartColors.navy,
                    borderColor: this.chartColors.navy,
                    data: [30,10,70,15,60,20,70,80],
                    fill: false,
                }, {
                    label: 'Snitt per kvitto',
                    fill: false,
                    backgroundColor: this.chartColors.purple,
                    borderColor: this.chartColors.purple,
                    data: [10,40,20,35,25,50,10,70],
                }]
            },
            options: {
                responsive: true,
                // title: {
                // 	display: true,
                // 	text: 'Chart.js Line Chart'
                // },
                tooltips: {
                    mode: 'index',
                    intersect: false,
                },
                hover: {
                    mode: 'nearest',
                    intersect: true
                },
                scales: {
                    x: {
                        display: true,
                        scaleLabel: {
                            display: true,
                            labelString: 'Month'
                        }
                    },
                    y: {
                        display: true,
                        scaleLabel: {
                            display: true,
                            labelString: 'Value'
                        }
                    }
                }
            }
        });
    };
    bar_chart() {
        this.my_bar =  new Chart(document.getElementById("barchart"), {
            type: 'bar',
            data: {
				labels: ['Januari', 'Februari', 'Mars', 'April', 'Maj', 'Juni', 'Juli', 'Augusti', 'September'],
                datasets: [{
                    data: [10,20,30,40,50,60,70,80],
                    label: 'Inklusive Moms',
                    backgroundColor: "#4755AB",
                    borderWidth: 1,
                }, {
                    data: [30,10,70,15,30,20,70,80],
                    label: 'Exklusive Moms',
                    backgroundColor: "#E7EDF6",
                    borderWidth: 1,
                }]
            },
            options: {
                responsive: true,
                legend: {
                    position: 'top',
                },
            }
        });
	};

    render() {
        var stat = [];
        var keys = Object.keys(this.state.stat);
        for (var key of keys) {
            var row = this.state.stat[key];
            stat.push(row);
        }
        const handleDateChange = date => {
            this.setState({seldate: date });
        };

        return (
            <div className="container-fluid" style={{background: "#eff1f9"}}>

                <div className="welcome-msg pt-3 pb-4">
                    <h1>Översikt</h1>
                    <p>Nyckeltal för innevarande år</p>
                </div>

                <div className="statistics">
                    <div className="row">
                        <div className="col-xl-6 pr-xl-2">
                            <div className="row">
                                <div className="col-sm-6 pr-sm-2 statistics-grid">
                                    <div className="card card_cdi p-4">
                                        <i className="lnr lnr-users"> </i>
                                        <h3 className="text-primary number">29.75 M</h3>
                                        <p className="stat-text">Omsättning</p>
                                    </div>
                                </div>
                                <div className="col-sm-6 pl-sm-2 statistics-grid">
                                    <div className="card card_cdi p-4">
                                        <i className="lnr lnr-eye"> </i>
                                        <h3 className="text-secondary number">51.25 K</h3>
                                        <p className="stat-text">Dag snitt</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 pl-xl-2">
                            <div className="row">
                                <div className="col-sm-6 pr-sm-2 statistics-grid">
                                    <div className="card card_cdi p-4">
                                        <i className="lnr lnr-cloud-download"> </i>
                                        <h3 className="text-success number">166.89 M</h3>
                                        <p className="stat-text">Personer</p>
                                    </div>
                                </div>
                                <div className="col-sm-6 pl-sm-2 statistics-grid">
                                    <div className="card card_cdi p-4">
                                        <i className="lnr lnr-cart"> </i>
                                        <h3 className="text-danger number">1,250k</h3>
                                        <p className="stat-text">Snitt person</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="chart">
                    <div className="row">
                        <div className="col-lg-6 pr-lg-2 chart-grid">
                            <div className="card text-center card_cdi">
                                <div className="card-header chart-grid__header">
                                    Timmar
                                </div>
                                <div className="card-body">
                                    <div id="container">
                                        <canvas id="barchart"></canvas>
                                    </div>
                                </div>
                                <div className="card-footer text-muted chart-grid__footer">
                                    Detaljer och djupdykning
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 pl-lg-2 chart-grid">
                            <div className="card text-center card_cdi">
                                <div className="card-header chart-grid__header">
                                    Snitt värden
                                </div>
                                <div className="card-body">
                                    <div id="container">
                                        <canvas id="linechart"></canvas>
                                    </div>
                                </div>
                                <div className="card-footer text-muted chart-grid__footer">
                                    Detaljer
                                </div>
                            </div>
                        </div>
                    </div>

                        <div className="row hide">
                        <div className="col-lg-6 pr-lg-2 chart-grid">
                            <div className="card text-center card_cdi">
                                <div className="card-header chart-grid__header">
                                    Fördelning Varugrupp
                                </div>
                                <div className="card-body">
                                    <div id="canvas-holder">
                                        <canvas id="piechart"></canvas>
                                    </div>
                                </div>
                                <div className="card-footer text-muted chart-grid__footer">
                                    Detaljer
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 pl-lg-2 chart-grid">
                            <div className="card text-center card_cdi">
                                <div className="card-header chart-grid__header">
                                    Betalsätt
                                </div>
                                <div className="card-body">
                                    <div id="container">
                                        <canvas id="stackedbar"></canvas>
                                    </div>
                                </div>
                                <div className="card-footer text-muted chart-grid__footer">
                                    Detaljer
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }

}
//WebPub.contextType = SDOContext; // Function
export default Ta_Dash;
