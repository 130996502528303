import React, { useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { configure } from "mobx"

//import logo from './logo.svg';
import './css/rws.css';

import ddo from './ddo.js';
import sso from './sso.js';

import WebStd from './webext/web.js';
import WebStart from './webext/start.js';
import WebLogin from './webext/login.js';
import WebDemo from './webext/demo.js';
import WebPub from './webext/pub.js';
import WebAdm from './webext/adm.js';
import WebRegister from './webext/register.js';
import WebRenew from './webext/renew.js';

import WebSso from './webext/sso.js';
import WebTmpl from './tmpl/tmpl.js';
import WebTst from './tst/tst.js';

import WebWsc from './wsc/wsc.js';

import WebHome from './webint/home.js';
import WebHello from './webint/hello.js';
import WebLogout from './webint/logout.js';
import WebOutX from './webint/outx.js';
import WebSesout from './webint/sesout.js';

import SysXt from './xt/xt.js';

import SubStd from './std/std.js';
import SubPms from './pms/pms.js';
import SubPos from './pos/pos.js';

import SubFakt from './fakt/fakt.js';
import SubTa from './ta/ta.js';
import SubTrs from './trs/trs.js';
import SubCas from './cas/cas.js';
import SubVip from './vip/vip.js';

import SubDsi from './dsi/dsi.js';
import SubTele from './tele/tele.js';
import SubSys from './sys/sys.js';
import SubEcom from './ecom/ecom.js';
import SubBi from './bi/bi.js';
import SubGym from './gym/gym.js';

import SubRed from './red/red.js';

import SubBos from './bos/bos.js';
import SubBcs from './bcs/bcs.js';
import SubBfs from './bfs/bfs.js';

import SubCsg from './csa/csg/csg.js';
import SubCsc from './csa/csc/csc.js';
import SubCsd from './csa/csd/csd.js';

import TechHome from './tech/home.js';
import TechEmv from './tech/emv.js';

import Store, { StoreProvider } from "./sdo";
import WebStore from "./ado";

const webStore = new WebStore();
const store = new Store();

function RwsApp() {
    //var sdo = useContext(SDO);

//    fetch('/cfg')
//    .then(rsp => alert(JSON.stringify(rsp)));
//  .then(response => response.json())
//  .then(data => this.setState({ data }));
    configure({
        enforceActions: "never",
    })

    const[txt, setTxt] = useState("SS00");
    var url = window.location.href;
    var tstUrl = url.match(/test.cdihotel.com/g);
    //var tstUrl = url.match(/localhost/g);
    //tstUrl = true;
    var hash = window.location.hash;
    if(hash) {
        //alert(JSON.stringify(hash));
    }

     if(false) {
       var html =
        <BrowserRouter forceRefresh={false}>
            <Routes>
                <Route path="/bo" forceRefresh={true} element={ <SubBos store={webStore} />} />
                <Route path="*" forceRefresh={true} element={ <WebTst store={webStore} />} />
            </Routes>
        </BrowserRouter>
        return html;
    }

    webStore.cfg.brand = 'spdr';
    webStore.cfg.logo = 'spdr';
    //webStore.cfg.brand = 'svk';
    //webStore.cfg.logo = 'svk';
    //webStore.cfg.logo = 'cdi';
    if(tstUrl) {
        webStore.cfg.brand = 'cdi';
        webStore.cfg.logo = 'cdi';
    }

    var xss = sessionStorage.getItem("svp_sso");
    //alert(xdo);
    if(!xss) {
        webStore.seo = {};
        webStore.seo.loggedin = false;
        sso.cfg = {};
        sso.seo = {};
        sso.sdo = {};
       var html =
        <BrowserRouter forceRefresh={false}>
            <Routes>
                <Route path="/xt/*" element={ <SysXt store={webStore} />} />
                <Route path="/sesout" forceRefresh={true} element={ <WebSesout store={webStore} />} />
                <Route path="/register" forceRefresh={true} element={ <WebRegister store={webStore} />} />
                <Route path="/renew" forceRefresh={true} element={ <WebRenew store={webStore} />} />
                <Route path="/start" forceRefresh={true} element={ <WebStart store={webStore} />} />
                <Route path="/tech/sim" forceRefresh={true} element={ <TechEmv store={webStore} />} />
                <Route path="/tech/emv" forceRefresh={true} element={ <TechEmv store={webStore} />} />
                <Route path="/sso" forceRefresh={true} element={ <WebSso store={webStore} />} />
                <Route path="*" forceRefresh={true} element={ <WebLogin store={webStore} />} />
            </Routes>
        </BrowserRouter>
        return html;
    }

    webStore.seo = {};
    sso.cfg = {};
    sso.seo = {};
    sso.sdo = {};
    if(xss) {
        var xso = JSON.parse(xss);
        //webStore.seo = seo;
        sso.cfg = xso.cfg;
        sso.seo = xso.seo;
        sso.sdo = xso.sdo;
        //return routefkn(sdo);
    }
    //alert(JSON.stringify(sso.seo));
    //sso.seo.cs = 2;
    //sso.seo.csid = "0002";

    webStore.cfg.mnu_show = true;
    webStore.cfg.bread = false;
    webStore.cfg.bread = true;

    var pos = {};
    pos.deps = [];
    pos.vgrps = [];
    pos.plus = [];
    webStore.pos = pos;

    var pms = {};
    pms.rta = [];
    pms.rto = {};
    pms.pka = [];
    pms.pko = {};
    pms.stat = {};
    webStore.pms = pms;

    var ta = {};
    webStore.ta = ta;

    ddo.cache.avd = "01";

    var html =
    <StoreProvider store={store}>
        <BrowserRouter forceRefresh={false}>
        <Routes>
            <Route path="/xt/*" element={ <SysXt store={webStore} />} />
            <Route path="/std/*" element={ <SubStd store={webStore} />} />

            <Route path="/pms/*" element={ <SubPms store={webStore} />} />
            <Route path="/pos/*" element={ <SubPos store={webStore} />} />

            <Route path="/fakt/*" element={ <SubFakt store={webStore} />} />
            <Route path="/trs/*" element={ <SubTrs store={webStore} />} />
            <Route path="/dsi/*" element={ <SubDsi store={webStore} />} />
            <Route path="/cas/*" element={ <SubCas store={webStore} />} />
            <Route path="/vip/*" element={ <SubVip store={webStore} />} />
            <Route path="/gym/*" element={ <SubGym store={webStore} />} />
            <Route path="/ta/*" element={ <SubTa store={webStore} />} />

            <Route path="/bi/*" element={ <SubBi store={webStore} />} />
            <Route path="/red/*" element={ <SubRed store={webStore} />} />

            <Route path="/bos/*" element={ <SubBos store={webStore} />} />
            <Route path="/bcs/*" element={ <SubBcs store={webStore} />} />
            <Route path="/bfs/*" element={ <SubBfs store={webStore} />} />
            
            <Route path="/ecom/*" element={ <SubEcom store={webStore} />} />

            <Route path="/csg/*" element={ <SubCsg store={webStore} />} />
            <Route path="/csc/*" element={ <SubCsc store={webStore} />} />
            <Route path="/csd/*" element={ <SubCsd store={webStore} />} />

            <Route path="/tech/sim" element={ <TechEmv store={webStore} />} />
            <Route path="/tech/emv" element={ <TechEmv store={webStore} />} />
            <Route path="/tech/*" element={ <TechHome store={webStore} />} />

            <Route path="/tele/*" element={ <SubTele store={webStore} />} />
            <Route path="/sys/*" element={ <SubSys store={webStore} />} />
            <Route path="/adm/*" element={ <WebAdm store={webStore} />} />
            <Route path="/demo/*" element={ <WebDemo store={webStore} />} />
            <Route path="/pub/*" element={ <WebPub store={webStore} />} />
            <Route path="/web/*" element={ <WebStd store={webStore} />} />
            <Route path="/wsc/*" element={ <WebWsc store={webStore} />} />

            <Route path="/tmpl/*" element={ <WebTmpl store={webStore} />} />
            <Route path="/tpl/*" element={ <WebTmpl store={webStore} />} />
            <Route path="/sso" element={ <WebSso store={webStore} />} />

            <Route path="/hellox" forceRefresh={true} element={ <WebHello store={webStore} />} />
            <Route path="/hello" forceRefresh={true} element={ <WebHome store={webStore} />} />
            <Route path="/login" forceRefresh={true} element={ <WebLogin store={webStore} />} />
            <Route path="/logout" forceRefresh={true} element={ <WebLogout store={webStore} />} />
            <Route path="/outx" forceRefresh={true} element={ <WebOutX store={webStore} />} />
            <Route path="/sesout" forceRefresh={true} element={ <WebSesout store={webStore} />} />

            <Route path="/tst" element={ <WebTst store={webStore} />} />
            <Route path="*" element={ <WebHome store={webStore} />} />
        </Routes>
        </BrowserRouter>
    </StoreProvider>
    ;
    return html;
}

export default RwsApp;
