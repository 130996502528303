import React, { useState, useEffect, useContext } from 'react';
import {format, getHours, subDays} from "date-fns";
import { observer } from "mobx-react";

import * as utils from "../../assets/utils";
import * as net from "../../lib/net";
import Lang from "../../lib/lang";
import ddo from "../../ddo";

import Pms_CiBr from "./ci/ci_br";
import Pms_CiGst from "./ci/ci_gst";
import Pms_CiDone from "./ci/ci_done";
import Pms_Rf from "./fkn/rf";
import Td_Sel from "../../lib/ui/td_sel";
import Pms_Nota from "./nota/nota";
import Wnd_Sign from "../../lib/ui/wnd_sign";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Pms_Ci extends React.Component {
    //static contextType = SDOContext; // Class
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.state = {};
    this.state.avd = "01";
    this.state.rva = [];
    this.state.rvs = [];
    this.state.msg = {};

    this.state.aa = [];
    this.state.cisel = 0;
    this.state.boknr = "";
    this.state.rvo = {};
    this.state.wnds = {};
    this.state.selrow = {};
    this.state.sortid = "";
    this.state.sortdir = 1;
    this.state.showanm = 1;
      this.state.loading = false;

    this.state.today = new Date();
    var hh = getHours(this.state.today);
    if( hh < 5) {
        const dt = subDays(new Date(), 1);
        this.state.dts = format(dt, "yyyy-MM-dd");
    }
    else this.state.dts = format(this.state.today, "yyyy-MM-dd");

    this.selitems = [
        {fkn: 'ci', text: 'CHECKIN'},
        {fkn: 'br', text: 'DELBOKNING'},
        {fkn: 'cancel', text: 'AVBOKA'}
    ];
  }

  componentDidUpdate() {
  }
  componentWillUnmount() {
      ddo.store.cb = null;
      ddo.store.ctx = null;
  }
  componentDidMount() {
      ddo.store.cb = this.cb;
      ddo.store.ctx = this;
      ddo.store.tst = "112";
      //alert(JSON.stringify(ddo));
    this.ci_list();
  }

    cb(ctx, rsp) {
        //alert(JSON.stringify("CB"));
        ctx.setState({ wnds: {} });
        ddo.store.ctx.ci_list();
        ddo.store.tst = "789";
    }

    body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 225;
      css.overflow = "auto";
      return css;
  }
  th_style(id) {
      var css = {};
      css.cursor = "pointer";
      if(id == this.state.sortid) {
          if(this.state.sortdir) css.color = "#800";
          else css.color = "#080";
      }
      return css;
  }
  row_style(row) {
    var srow = this.state.selrow;

    var css = {};
    if(srow == row) {
        css.background = "#080";
        css.color = "#fff";
    }
    return css;
  }
  clean_style(row) {
    var css = {};
    css.background = "#ff8";
    css.color = "#222";

    if(row.clean == "1") {
        css.background = "#080";
        css.color = "#fff";
    }
    if(row.clean == "2") {
        css.background = "#f90";
        css.color = "#fff";
    }
    return css;
}

  sz_style() {
      var css = {};

      css.height = "40px";
      css.lineHeight = "40px";
      css.padding = "0px 10px";
      css.fontSize = "12px";
      css.cursor = "pointer";
      css.background = "#fff";
      css.color = "#222";
      css.border = "1px solid #222";

      return css;
  }
  btnanm_style() {
      var css = {};
      css.height = "40px";
      css.lineHeight = "40px";
      css.padding = "0px 10px";
      css.fontSize = "12px";
      css.cursor = "pointer";
      css.background = "#080";
      css.color = "#fff";
      css.borderLeft = "1px solid #222";
      css.borderRight = "1px solid #222";
      if(this.state.showanm) {
          css.background = "#800";
          css.color = "#fff";
          css.borderLeft = "1px solid #222";
          css.borderRight = "1px solid #222";
      }
      return css;
  }
  btn_anm() {
      var showanm = this.state.showanm;
      if(showanm) showanm = 0;
      else showanm = 1;
      this.setState({showanm: showanm});
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }
    rf(row) {
        var self = this;
        var msg = { info: false, sign: true, text: "Signatur?", text0: "JA", text1: "NEJ", cb: "rf_do" };
        self.setState({ msg: msg, srow: row });
    }
    rf_do(signatur) {
        var self = this;
        var row = this.state.srow;
        self.setState({msg: {} });
    var wnds = {};
    wnds.rf = true;
    this.setState({wnds: wnds});
  }
  ci_sel() {
    var self = this;

    var srow = this.state.selrow;
    if(!srow.typ) return;

    if(srow.typ == "G") {
        var wnds = {};
        wnds.gst = true;
        wnds.boknr = srow.boknr;
        wnds.rvo = srow;
        this.setState({wnds: wnds});
    }
    else {
        var wnds = {};
        wnds.br = true;
        wnds.boknr = srow.boknr;
        wnds.rvo = srow;
        this.setState({wnds: wnds});
    }
  }
    ci(row) {
        var self = this;
        var msg = { info: false, sign: true, text: "Signatur?", text0: "JA", text1: "NEJ", cb: "ci_do" };
        self.setState({ msg: msg, srow: row });
    }
    ci_do(signatur) {
        var self = this;
        var row = this.state.srow;
        self.setState({msg: {} });

    if(row.typ == "G") {
        var wnds = {};
        wnds.gst = true;
        wnds.boknr = row.boknr;
        wnds.rvo = row;
        wnds.cb = this.cb_ci;
        this.setState({wnds: wnds});
    }
    else {
        var wnds = {};
        wnds.br = true;
        wnds.boknr = row.boknr;
        wnds.rvo = row;
        this.setState({wnds: wnds});
    }
  }

  ci_list() {
    var self = this;
      this.setState({rvs: [], rva: [], loading: true});

    var prm = {};
    prm.req = "pms.op.my_arrivals";
    prm.datum = this.state.dts;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rco));

        var rva = [];
        for(var row of ret.rco) {
            if(row.status == "I") continue;
            if(row.status == "P") continue;
            if(row.status == "O") continue;
            rva.push( row );
        }
        self.setState({ rvs: ret.rco, rva: rva, loading: false }, function() {
            self.syncTb("ciTable");
        });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }

    syncTb(tb){
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
        tdElements = tbel.getElementsByTagName('td');

        for (let i = 0; i < thElements.length; i++) {
            if(!tdElements[i]) continue;
            const widerElement = thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
            width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
  val_date(val) {
      var ret = "";
      if(val) ret = val.toString().cdidate();
      return ret;
  }
    val_rum(val) {
      var ret = "";
      if(val) ret = val.toString().cdirumsnr();
      return ret;
    }
    val_pris(val) {
      var ret = "";
      if(val) ret = val.toString().cdipris();
      return ret;
    }
  sel_row(row) {
    var srow = this.state.selrow;
    if(srow == row) {
      this.setState({selrow: {}});
    }
    else this.setState({selrow: row});
  }
  sel_row_fkn(row, sel) {
      //alert(JSON.stringify(fkn));
    if(sel.fkn == "ci") {
        if(row.typ == "G") {
            var wnds = {};
            wnds.gst = true;
            wnds.boknr = row.boknr;
            wnds.rvo = row;
            wnds.cb = this.cb_ci;
            this.setState({wnds: wnds});
        }
    }
    if(sel.fkn == "br") {
        var wnds = {};
        wnds.br = true;
        wnds.boknr = row.boknr;
        wnds.rvo = row;
        this.setState({wnds: wnds});
    }
  }
   cb_ci(ctx, ret) {
    if(ret && (ret.ok == "000")) {
        var wnds = {};
        wnds.cidone = true;
        wnds.boknr = ret.boknr;
        wnds.gso = ret.gso;
        wnds.cb = ctx.cb_cidone;
        ctx.setState({wnds: wnds});
        return;
    }
    ctx.setState({wnds: {}});
  }
   cb_cidone(ctx, ret) {
    //alert(JSON.stringify(ret));
    if(ret && (ret.ok == "000")) {
    if(ret.fkn == "nota") {
        var wnds = {};
        wnds.nota = true;
        wnds.boknr = ret.boknr;
        wnds.rvo = ret.rvo;
        ctx.setState({wnds: wnds});
        return;
    }
    }
    ctx.setState({wnds: {}});
  }
   th_icon(typ) {
      var cls = "";
      var sortid = this.state.sortid;
      var sortdir = this.state.sortdir;
      if(sortid == typ)
      {
        if(sortdir) cls = "fa fa-caret-up";
        else cls = "fa fa-caret-down";
      }
      return cls;
    }
    tb_sort(typ) {
      var self = this;
      //var rvs = self.state.rvs;
      var rva = self.state.rva;
      var sortid = this.state.sortid;
      var sortdir = this.state.sortdir;

      if(sortid != typ) sortdir = 0;
      else {
        if(sortdir) sortdir = 0;
        else sortdir = 1;
      }

      if(sortdir) rva.sort((a,b) => (a[typ] > b[typ]) ? 1 : ((b[typ] > a[typ]) ? -1 : 0));
      else rva.sort((a,b) => (a[typ] < b[typ]) ? 1 : ((b[typ] < a[typ]) ? -1 : 0));
      self.setState({ rva: rva, sortid: typ, sortdir: sortdir });
    }
  tb_filtr() {
      var self = this;
      var rvs = self.state.rvs;
      var sz = this.state.sz_val;

      if(sz.length < 1) {
        self.setState({ rva: rvs });
          return;
      }
      sz = sz.toUpperCase();
      var rva = rvs.filter(function(row) {
            var brc = false;
            if(row.company.toUpperCase().includes(sz)) brc = true;
            if(row.name.toUpperCase().includes(sz)) brc = true;
            return brc;
      });
      self.setState({rva: rva });
   }
    sz_keydown (e) {
      //e.preventDefault();
        const key = e.key;
        const keyCode = e.keyCode;

        if(keyCode == 13) {
            this.tb_filtr();
        };
        if(keyCode == 27) {
            this.setState({sz_val: ""});
        };
    }
    sz_change (e) {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({sz_val: value});
        //setState({[name]: value});
    }

  render() {
    return (
    <div className="web-sub">

    <div className="flex-row">
        <div className="flex">
        <button className="btn btn-hw-blue w200" onClick={() => this.rf() } aria-label="System" >
            RUMSFÖRDELA
        </button>
        </div>

        <div className="" style={ this.btnanm_style() } onClick={() => this.btn_anm() }>
            <div>ANM</div>
        </div>
        <div className="flex flex-end">
        <input style={ this.sz_style() } className="txt sztxt" value={this.state.sz_val} onChange={(e) => this.sz_change(e)} onKeyDown={(e) => this.sz_keydown(e)} />
        <button className="btn btn-hw-blue w200" onClick={() => this.ci_sel() } aria-label="System" >
            CHECKA IN
        </button>
        </div>
    </div>
    <div className="divider_orange"></div>

    { this.state.loading ? <div className="flex flex-col flex-center"><FontAwesomeIcon icon="spinner" spin size="4x" /></div> : null }
    { !this.state.loading ?
    <div className="web-col">

        <div id="ciTable">
          <table className="table table-bordered table-hover" style={{marginBottom: "0px"}}>
            <thead>
            <tr>
                <th style={ this.th_style("rumsnr") } onClick={() => this.tb_sort("rumsnr") }><Lang>Roomnr</Lang> <i className={ this.th_icon("rumsnr") }></i></th>
                <th style={ this.th_style("boknr") } onClick={() => this.tb_sort("boknr") }><Lang>Resno</Lang> <i className={ this.th_icon("boknr") }></i></th>
                <th style={ this.th_style("kanal") } onClick={() => this.tb_sort("kanal") }><Lang>Segment</Lang> <i className={ this.th_icon("kanal") }></i></th>
                <th style={ this.th_style("company") } onClick={() => this.tb_sort("company") }><Lang>Company</Lang> <i className={ this.th_icon("company") }></i></th>
                <th style={ this.th_style("name") } onClick={() => this.tb_sort("name") }><Lang>Name</Lang> <i className={ this.th_icon("name") }></i></th>
                <th style={ this.th_style("nrr") } onClick={() => this.tb_sort("nrr") }><Lang>Rooms</Lang> <i className={ this.th_icon("nrr") }></i></th>
                <th style={ this.th_style("nrg") } onClick={() => this.tb_sort("nrg") }><Lang>Guests</Lang> <i className={ this.th_icon("nrg") }></i></th>
                <th style={ this.th_style("rt") } onClick={() => this.tb_sort("rt") }><Lang>Rumstyp</Lang> <i className={ this.th_icon("rt") }></i></th>
                <th style={ this.th_style("anktid") } onClick={() => this.tb_sort("anktid") }><Lang>Arrivaltime</Lang> <i className={ this.th_icon("anktid") }></i></th>
                <th style={ this.th_style("avr") } onClick={() => this.tb_sort("avr") }><Lang>Departure</Lang> <i className={ this.th_icon("avr") }></i></th>
                <th style={ this.th_style("bettyp") } onClick={() => this.tb_sort("bettyp") }><Lang>Paytype</Lang> <i className={ this.th_icon("bettyp") }></i></th>
                <th style={ this.th_style("pris") } onClick={() => this.tb_sort("pris") }><Lang>Price</Lang> <i className={ this.th_icon("pris") }></i></th>
                <th style={ this.th_style("typ") } onClick={() => this.tb_sort("typ") }><Lang>Type</Lang> <i className={ this.th_icon("typ") }></i></th>
                <th></th>
            </tr>
            </thead>
            </table>
            <div style={ this.tb_style() }>
          <table className="table table-bordered table-hover tbs01">
            { this.state.rva.map((row, key) =>
            <tbody key={key}>
            <tr style={ this.row_style(row) } onClick={() => this.sel_row(row)} onDoubleClick={() => this.ci(row)}>
                <td className="bold text-center" style={ this.clean_style(row) }>{ utils.toRumsnr( row.rumsnr ) }</td>
                <td>{ row.boknr }</td>
                <td>{ row.kanal }</td>
                <td>{ utils.toLang( row.company ) }</td>
                <td className="bold">{ utils.toLang( row.name ) }</td>
                <td>{ utils.toNum( row.nrr ) }</td>
                <td>{ utils.toNum( row.nrg ) }</td>
                <td>{ utils.toLang( row.rt ) }</td>
                <td>{ utils.toTid( row.resv02.anktid ) }</td>
                <td>{ utils.toDate( row.avr ) }</td>
                <td>{ row.resv02.bettyp }</td>
                <td>{ utils.toPris( row.pris ) }</td>
                <td>{ row.typ }</td>
                <td>
                    <Td_Sel items={ this.selitems } getValue={(e)=> this.sel_row_fkn(row, e)} />
                </td>
            </tr>
            { ( false ) ?
                <tr className=""  onDoubleClick={() => this.sel_gst(row)}>
                    <td colSpan={10}>Rumstyp: { row.rt }</td>
                    <td colSpan={2}>{ row.status }</td>
                </tr>
            : null }
            { ( this.state.showanm && row.anm ) ?
                <tr className="txt_tb_anm"  onDoubleClick={() => this.sel_gst(row)}>
                    <td colSpan={12}>{ row.anm.toswe() }</td>
                </tr>
            : null }
            </tbody>
            )}
        </table>
            </div>
            </div>
    </div>
    : null }

    { this.state.wnds.br ?
        <Pms_CiBr wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
    : null }
    { this.state.wnds.gst ?
        <Pms_CiGst wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
    : null }
    { this.state.wnds.rf ?
        <Pms_Rf boknr={this.state.boknr} ctx={this} store={this.props.store} backdrop={true}/>
    : null }
    { this.state.wnds.cidone ?
        <Pms_CiDone wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
    : null }
    { this.state.wnds.nota ?
        <Pms_Nota wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
    : null }
        { this.state.msg.sign ? <Wnd_Sign msg={this.state.msg} ctx={this} /> : null }
    </div>
  );
    }
}
export default observer(Pms_Ci);
