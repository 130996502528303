import React, { useState, useEffect, useContext } from 'react';
import * as net from "../../../lib/net";
import Dt_Cal from '../../../lib/ui/dt_cal';
import {format, subDays} from "date-fns";

import Wnd_Error from "../../../lib/ui/wnd_error";
import Wnd_Ok from "../../../lib/ui/wnd_ok";
import Wnd_Info from "../../../lib/ui/wnd_info";
import Wnd_Confirm from "../../../lib/ui/wnd_confirm";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ddo from "../../../ddo";
import Fr_Input from "../../../lib/ui/fr_input";

class Plu_Cpy extends React.Component {
  constructor(props, context) {
    super(props);

    this.pos = props.store.pos;
    this.state = {};
    this.state.today = new Date();
    this.state.lsa = [];
    this.state.lso = {};
    //this.state.lro = props.lro;
    this.state.tya = [
                {id: '02', text: 'Dag för dag'},
                {id: '01', text: 'Summerad'}
            ];
    this.state.dea = [
                {id: '01', text: 'Definition v1'},
                {id: '02', text: 'Definition v2'}
            ];
    this.state.cols = {};
    this.state.lrwrk = false;
    //context.posrend = this;
    this.state.favd = "";
    this.state.tavd = "";
    this.state.fdat = "";
    this.state.tdat = "";
    this.state.fgrp = "";
    this.state.tgrp = "";
    this.state.typ = "";
    this.state.def = "";
      this.state.msg = {};
      this.state.mx = {};
    this.state.pdf = 0;
    this.state.pdf_content = null;
    this.state.loading = 0;

  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.dlg_init();
  }

  dlg_init() {
      var state = this.state;
      state.favd = ddo.cache.avd;
      state.tavd = ddo.cache.avd;
      state.typ = "02";
      state.def = "01";
      state.loading = 0;
      if(this.pos.deps && this.pos.deps.length) {
          state.favd = this.pos.deps[0].avd;
          state.tavd = this.pos.deps[0].avd;
      }
    this.setState(state);
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    tab_style(tabid) {
      var css = {};
      css.display = "none";
      if(this.state.tabval === tabid) css.display = "block";
      return css;
    }
    tab_head(tabid) {
      var css = {};
      css.background = "#fff";
      css.color = "#000";
      if(this.state.tabval === tabid) {
          css.background = "#080";
          css.color = "#fff";
      }
      return css;
    }
  change_favd(e) {
    //let cdat = this.state.seldate;
    let avd = e.target.value;
    this.setState({favd: avd});
  }
  change_tavd(e) {
    //let cdat = this.state.seldate;
    let avd = e.target.value;
    this.setState({tavd: avd});
  }
  change_typ(e) {
    //let cdat = this.state.seldate;
    let typ = e.target.value;
    this.setState({typ: typ});
  }
  change_def(e) {
    //let cdat = this.state.seldate;
    let def = e.target.value;
    this.setState({def: def});
  }

    copy_plus() {
        var self = this;

        if(this.state.favd == this.state.tavd) {
            alert("EJ SAMMA AVDELNING");
            return;
        }
        //this.setState({ loading: 1 });
        var msg = { info: true, text: "Kopierar PLU..."};
        self.setState({msg: msg });

        var favd = this.state.favd;
        var tavd = this.state.tavd;
        var dpa = [];
        var iavd = favd * 1;
        var maxavd = tavd * 1;
        do {
            var avd = iavd.pad(2);
            dpa.push( avd );
            iavd = iavd + 1;
        } while( iavd <= maxavd);

        var prm = {};
        //prm.req = "pos.pos_rp.bok";
        prm.req = "pos.pos_plu.plus_cpy";
        prm.favd = this.state.favd;
        prm.tavd = this.state.tavd;
        //prm.typ = this.state.typ;
        //prm.def = this.state.def;
        //alert(JSON.stringify(prm));
        //return;

        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret.ok));

            var msg = { info: false, ok: true, text: "PLU kopierade..."};
            self.setState({msg: msg });
            setTimeout(function(){
                self.setState({msg: {} });
            }, 1000);
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }
    copy_pays() {
        var self = this;

        if(this.state.favd == this.state.tavd) {
            alert("EJ SAMMA AVDELNING");
            return;
        }
        //this.setState({ loading: 1 });
        var msg = { info: true, text: "Kopierar Betalsätt..."};
        self.setState({msg: msg });

        var prm = {};
        prm.req = "pos.pos_pay.pays_cpy";
        prm.favd = this.state.favd;
        prm.tavd = this.state.tavd;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.ok));

                var msg = { info: false, ok: true, text: "Betalsätt kopierade..."};
                self.setState({msg: msg });
                setTimeout(function(){
                    self.setState({msg: {} });
                }, 1000);
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    copy_pgs() {
        var self = this;

        if(this.state.favd == this.state.tavd) {
            alert("EJ SAMMA AVDELNING");
            return;
        }
        //this.setState({ loading: 1 });
        var msg = { info: true, text: "Kopierar Varugrupper..."};
        self.setState({msg: msg });

        var prm = {};
        prm.req = "pos.pos_pg.vgrps_cpy";
        prm.favd = this.state.favd;
        prm.tavd = this.state.tavd;

        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret.ok));

            var msg = { info: false, ok: true, text: "Varugrupper kopierade..."};
            self.setState({msg: msg });
            setTimeout(function(){
                self.setState({msg: {} });
            }, 1000);
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }
    copy_btns() {
        var self = this;

        if(this.state.favd == this.state.tavd) {
            alert("EJ SAMMA AVDELNING");
            return;
        }
        //this.setState({ loading: 1 });
        var msg = { info: true, text: "Kopierar Knappar..."};
        self.setState({msg: msg });

        var prm = {};
        //prm.req = "pos.pos_rp.bok";
        prm.req = "pos.pos_btns.btns_cpy";
        prm.favd = this.state.favd;
        prm.tavd = this.state.tavd;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.ok));

                var msg = { info: false, ok: true, text: "Knappar kopierade..."};
                self.setState({msg: msg });
                setTimeout(function(){
                    self.setState({msg: {} });
                }, 1000);
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    export_btns() {
        var self = this;

        //this.setState({ loading: 1 });
        var msg = { info: true, text: "Exporterar Knappar..."};
        self.setState({msg: msg });

        var prm = {};
        //prm.req = "pos.pos_rp.bok";
        prm.req = "pos.pos_btns.export_btns";
        prm.favd = this.state.favd;
        prm.tavd = this.state.tavd;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.ok));

                var msg = { info: false, ok: true, text: "Knappar exporterade..."};
                self.setState({msg: msg });
                setTimeout(function(){
                    self.setState({msg: {} });
                }, 1000);
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    export_fams() {
        var self = this;

        //this.setState({ loading: 1 });
        var msg = { info: true, text: "Exporterar familjer..."};
        self.setState({msg: msg });

        var prm = {};
        //prm.req = "pos.pos_rp.bok";
        prm.req = "pos.pos_btns.export_fams";
        prm.favd = this.state.favd;
        prm.tavd = this.state.tavd;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.ok));

                var msg = { info: false, ok: true, text: "Familjer exporterade..."};
                self.setState({msg: msg });
                setTimeout(function(){
                    self.setState({msg: {} });
                }, 1000);
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    export_grps() {
        var self = this;

        //this.setState({ loading: 1 });
        var msg = { info: true, text: "Exporterar grupper..."};
        self.setState({msg: msg });

        var prm = {};
        //prm.req = "pos.pos_rp.bok";
        prm.req = "pos.pos_btns.export_grps";
        prm.favd = this.state.favd;
        prm.tavd = this.state.tavd;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.ok));

                var msg = { info: false, ok: true, text: "Gruppe exporterade..."};
                self.setState({msg: msg });
                setTimeout(function(){
                    self.setState({msg: {} });
                }, 1000);
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    import_btns() {
        var self = this;

        //this.setState({ loading: 1 });
        var msg = { info: true, text: "Importerar Knappar..."};
        self.setState({msg: msg });

        var prm = {};
        //prm.req = "pos.pos_rp.bok";
        prm.req = "pos.pos_btns.import_btns";
        prm.favd = this.state.favd;
        prm.tavd = this.state.tavd;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.ok));

                var msg = { info: false, ok: true, text: "Knappar importerade..."};
                self.setState({msg: msg });
                setTimeout(function(){
                    self.setState({msg: {} });
                }, 1000);
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    inx_btns() {
        var self = this;

        //this.setState({ loading: 1 });
        var msg = { info: true, text: "Indexera Knappar..."};
        self.setState({msg: msg });

        var favd = this.state.favd;
        var tavd = this.state.tavd;
        var dpa = [];
        var iavd = favd * 1;
        var maxavd = tavd * 1;
        do {
            var avd = iavd.pad(2);
            dpa.push( avd );
            iavd = iavd + 1;
        } while( iavd <= maxavd);

        var prm = {};
        //prm.req = "pos.pos_rp.bok";
        prm.req = "pos.pos_btns.zrecov_btns";
        prm.favd = this.state.favd;
        prm.tavd = this.state.tavd;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.ok));

                var msg = { info: false, ok: true, text: "Knappar indexerade..."};
                self.setState({msg: msg });
                setTimeout(function() {
                    self.setState({msg: {} });
                }, 1000);
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }

    clear_plus() {
        var self = this;

        //this.setState({ loading: 1 });
        var msg = { info: true, text: "Rensar PLU..."};
        self.setState({msg: msg });

        var favd = this.state.favd;
        var tavd = this.state.tavd;
        var dpa = [];
        var iavd = favd * 1;
        var maxavd = tavd * 1;
        do {
            var avd = iavd.pad(2);
            dpa.push( avd );
            iavd = iavd + 1;
        } while( iavd <= maxavd);

        var prm = {};
        //prm.req = "pos.pos_rp.bok";
        prm.req = "pos.pos_plu.plus_clear";
        prm.favd = this.state.favd;
        prm.tavd = this.state.tavd;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.ok));

                var msg = { info: false, ok: true, text: "PLU rensade..."};
                self.setState({msg: msg });
                setTimeout(function(){
                    self.setState({msg: {} });
                }, 1000);
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    clear_btnsx() {
        alert("Warning! All buttons will be removed!");
    }
    clear_btns() {
        var self = this;

        //this.setState({ loading: 1 });
        var msg = { info: true, text: "Rensar Knappar..."};
        self.setState({msg: msg });

        var favd = this.state.favd;
        var tavd = this.state.tavd;
        var dpa = [];
        var iavd = favd * 1;
        var maxavd = tavd * 1;
        do {
            var avd = iavd.pad(2);
            dpa.push( avd );
            iavd = iavd + 1;
        } while( iavd <= maxavd);

        var prm = {};
        //prm.req = "pos.pos_rp.bok";
        prm.req = "pos.pos_btns.clear_btns";
        prm.favd = this.state.favd;
        prm.tavd = this.state.tavd;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.ok));

                var msg = { info: false, ok: true, text: "Knappar rensade..."};
                self.setState({msg: msg });
                setTimeout(function() {
                    self.setState({msg: {} });
                }, 1000);
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    clear_pays() {
        var self = this;

        //this.setState({ loading: 1 });
        var msg = { info: true, text: "Rensar Betalsätt..."};
        self.setState({msg: msg });

        var favd = this.state.favd;
        var tavd = this.state.tavd;
        var dpa = [];
        var iavd = favd * 1;
        var maxavd = tavd * 1;
        do {
            var avd = iavd.pad(2);
            dpa.push( avd );
            iavd = iavd + 1;
        } while( iavd <= maxavd);

        var prm = {};
        //prm.req = "pos.pos_rp.bok";
        prm.req = "pos.pos_pay.pays_clear";
        prm.favd = this.state.favd;
        prm.tavd = this.state.tavd;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.ok));

                var msg = { info: false, ok: true, text: "Betalsätt rensade..."};
                self.setState({msg: msg });
                setTimeout(function(){
                    self.setState({msg: {} });
                }, 1000);
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }

    famgrp_move() {
        var self = this;

        //this.setState({ loading: 1 });
        var msg = { info: true, text: "Flyttar Grupp..."};
        self.setState({msg: msg });

        var prm = {};
        prm.req = "pos.pos_btns.famgrp_move";
        prm.avd = this.state.favd;
        prm.fgrp = this.state.fgrp;
        prm.tgrp = this.state.tgrp;
        //alert(JSON.stringify(prm));
        //return;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.ok));

                var msg = { info: false, ok: true, text: "Grupp flyttad..."};
                self.setState({msg: msg });
                setTimeout(function(){
                    self.setState({msg: {} });
                }, 1000);
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    btns_recover() {
        var self = this;

        //this.setState({ loading: 1 });
        var msg = { info: true, text: "Återställer index..."};
        self.setState({msg: msg });

        var prm = {};
        prm.req = "pos.pos_btns.zrecov_btns";
        prm.avd = this.state.favd;
        prm.fgrp = this.state.fgrp;
        prm.tgrp = this.state.tgrp;
        //alert(JSON.stringify(prm));
        //return;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.ok));

                var msg = { info: false, ok: true, text: "Index återställda..."};
                self.setState({msg: msg });
                setTimeout(function(){
                    self.setState({msg: {} });
                }, 1000);
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }


    rp_mx() {
   		var favd = this.state.favd;
   		var tavd = this.state.tavd;
   		var dpa = [];
   		var iavd = favd * 1;
   		var maxavd = tavd * 1;
   		do {
   			var avd = iavd.pad(2);
   			dpa.push( avd );
   			iavd = iavd + 1;
		} while( iavd <= maxavd);

        var prm = {};
     	//prm.req = "pos.pos_rp.bok";
     	prm.req = "pos.pos_red.red_bok_prn";
     	if(this.state.typ == "02") prm.req = "pos.pos_red.red_bokdt_prn";
   		prm.fdat = this.state.fdat;
   		prm.tdat = this.state.tdat;
   		prm.favd = this.state.favd;
   		prm.tavd = this.state.tavd;
   		prm.typ = this.state.typ;
   		prm.def = this.state.def;
   		prm.dpa = dpa;

        var mx = {};
     	mx.rp = "bok";
     	mx.prm = prm;

        this.setState({ mx: mx });
    }
    rp_prn() {
        var self = this;

        this.setState({ loading: 1 });

   		var favd = this.state.favd;
   		var tavd = this.state.tavd;
   		var dpa = [];
   		var iavd = favd * 1;
   		var maxavd = tavd * 1;
   		do {
   			var avd = iavd.pad(2);
   			dpa.push( avd );
   			iavd = iavd + 1;
		} while( iavd <= maxavd);

        var prm = {};
     	//prm.req = "pos.pos_rp.bok";
     	prm.req = "pos.pos_red.red_bok_prn";
     	if(this.state.typ == "02") prm.req = "pos.pos_red.red_bokdt_prn";
   		prm.fdat = this.state.fdat;
   		prm.tdat = this.state.tdat;
   		prm.favd = this.state.favd;
   		prm.tavd = this.state.tavd;
   		prm.typ = this.state.typ;
   		prm.def = this.state.def;
   		prm.dpa = dpa;
        //alert(JSON.stringify(prm));
        //return;

        //gda.wndLoading("Hämtar");
        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret.ok));

            //var uri = "data:application/pdf;base64," + ret.pdf;
            self.setState({pdf: 1, pdf_content: ret.pdf });
            //self.syncCols("lsTable");
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
            //gda.wndError(txt);
        });
    }
    rp_xl() {
        var self = this;

        this.setState({ loading: 1 });

   		var favd = this.state.favd;
   		var tavd = this.state.tavd;
   		var dpa = [];
   		var iavd = favd * 1;
   		var maxavd = tavd * 1;
   		do {
   			var avd = iavd.pad(2);
   			dpa.push( avd );
   			iavd = iavd + 1;
		} while( iavd <= maxavd);

        var prm = {};
     	//prm.req = "pos.pos_rp.dr";
     	prm.req = "pos.pos_rp.bok_xl";
     	//prm.req = "pos.pos_xl.tst_xl";
   		prm.fdat = this.state.fdat;
   		prm.tdat = this.state.tdat;
   		prm.favd = this.state.favd;
   		prm.tavd = this.state.tavd;
   		prm.dpa = dpa;
        //alert(JSON.stringify(prm));
        //return;

        //gda.wndLoading("Hämtar");
        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret.ok));

            //var uri = "data:application/pdf;base64," + ret.pdf;
            //self.setState({pdf: 1, pdf_content: ret.pdf });

 	  		//var rawbuf = ret.buf;
 	  		var rawbuf = ret.data;
			var file = new Blob([(rawbuf)], {type: 'application/octet-stream'});
			var fileURL = window.URL.createObjectURL(file);
			//$scope.fil_object = $sce.trustAsResourceUrl(fileURL);
			var a = document.createElement('a');
			a.href = fileURL;
			var filename = ret.filename;
			if(filename.length < 1) filename = "oms.xlsx";
			a.download = filename;
			document.body.appendChild(a);
			a.click();
            self.setState({ loading: 0 });
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
            //gda.wndError(txt);
        });
    }

  mx_done (ret) {
    this.setState({loading: 0, mx: 0 });
  }
  pdf_done (ret) {
    this.setState({loading: 0, pdf: 0, pdf_content: null });
  }

  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  body_style() {
      var css = {};
      css.display = "";
      if( this.state.loading == 1 ) css.display = "none";
      return css;
  }
    wnd_close = () => {
        this.props.ctx.setState({ wnds: {} });
    };

    plu_part_copy() {
        var self = this;

        if(this.state.favd == this.state.tavd) {
            alert("EJ SAMMA AVDELNING");
            return;
        }
        //this.setState({ loading: 1 });
        var msg = { info: true, text: "Del Kopierar PLU..."};
        self.setState({msg: msg });

        var favd = this.state.favd;
        var tavd = this.state.tavd;
        var dpa = [];
        var iavd = favd * 1;
        var maxavd = tavd * 1;
        do {
            var avd = iavd.pad(2);
            dpa.push( avd );
            iavd = iavd + 1;
        } while( iavd <= maxavd);

        var prm = {};
        //prm.req = "pos.pos_rp.bok";
        prm.req = "pos.pos_plu.plu_part_copy";
        prm.favd = this.state.favd;
        prm.tavd = this.state.tavd;
        //prm.typ = this.state.typ;
        //prm.def = this.state.def;
        //alert(JSON.stringify(prm));
        //return;

        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret.ok));

            var msg = { info: false, ok: true, text: "PLU Del kopierade..."};
            self.setState({msg: msg });
            setTimeout(function(){
                self.setState({msg: {} });
            }, 1000);
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }

  render() {
    var title = this.props.title;
    if(!title) title = "PLU COPY";

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="mx_backdrop" /> : null }
        <div className="mx_dlg mx_rp flex-col" style={ this.dlg_style() }>
        <div className="mx_head mx_bg flex-row flex-space-between">
            <div className="flex-col flex">
                <div>Kopiera PLU</div>
            </div>
            <div className="white-icon-close" onClick={() => this.wnd_close() }>X</div>
        </div>
        { this.state.loading ? <div className="mx_content flex align-items-center justify-content-center"><FontAwesomeIcon icon="spinner" spin size="3x" fixed /></div> :
        null }
        <div className="mx_content flex" style={ this.body_style() }>
            <div className="flex-row px-2">
                    <div className="flex-col flex">
                        <div>Start</div>
                    </div>
                    <div className="flex-col flex">
                        <div></div>
                    </div>
                    <div className="flex-col w50">
                        <div>Ändra</div>
                    </div>
            </div>
            <div className="divider_orange"></div>

        <div className="mx_body flex">

            <div className="flex-row">
              <div className="flex-col">
                  <span>Från</span>
        <select className="form-control input-rp-01 w250" placeholder="Från avdelning" onChange={(e) => this.change_favd(e)}>
            { this.pos.deps.map((row, key) =>
            <option key={key} value={row.avd}>{row.avd} - {row.text.toswe()}</option>
            )}
        </select>
            </div>
              <div className="flex-col">
                  <span>Till</span>
        <select className="form-control input-rp-01 w250" placeholder="Till avdelning" onChange={(e) => this.change_tavd(e)}>
            { this.pos.deps.map((row, key) =>
            <option key={key} value={row.avd}>{row.avd} - {row.text.toswe()}</option>
            )}
        </select>
            </div>
            </div>

            <div className="flex-row">
                <div className="flex"></div>
                <Fr_Input noflex label="F Grupp" col className="text-center w250" text={this.state.fgrp} getValue={(e)=> this.setState( { fgrp: e }) } />
                <Fr_Input noflex label="T Grupp" col className="text-center w250" text={this.state.tgrp} getValue={(e)=> this.setState( { tgrp: e }) } />
                <div className="flex"></div>
            </div>

            <div className="flex-row hide">
              <div className="flex-col">
                  <span>Typ</span>
        <select className="form-control input-rp-01 w250" placeholder="Typ" onChange={(e) => this.change_typ(e)}>
            { this.state.tya.map((row, key) =>
            <option key={key} value={row.id}>{row.text}</option>
            )}
        </select>
            </div>
              <div className="flex-col">
                  <span>Konto Definition</span>
        <select className="form-control input-rp-01 w250" placeholder="Definition" onChange={(e) => this.change_def(e)}>
            { this.state.dea.map((row, key) =>
            <option key={key} value={row.id}>{row.text}</option>
            )}
        </select>
            </div>
            </div>

        </div>

        </div>

        <div className="mx_foot flex-col">
            <div className="flex-row">
                <div className="web-link txt_16 px-2" onClick={() => this.inx_btns() }>INDEXERA KNAPPAR</div>
                <div className="flex"></div>
                <div className="web-link txt_16 px-2" onClick={() => this.plu_part_copy() }>DEL KOPIER</div>
           </div>
            <div className="flex-row">
            <div className="web-link txt_16 px-2" onClick={() => this.famgrp_move() }>FLYTTA KNAPPGRUPP</div>
            <div className="web-link txt_16 px-2" onClick={() => this.btns_recover() }>INDEXERA KNAPPAR</div>
            <div className="flex"></div>
            <div className="web-link txt_16 px-2" onClick={() => this.export_btns() }>EXPORTERA KNAPPAR</div>
            <div className="web-link txt_16 px-2" onClick={() => this.import_btns() }>IMPORTERA KNAPPAR</div>
            </div>

            <div className="divider_green"></div>

            <div className="flex-row">
            <div className="flex-row flex">
                <div className="btn-rp" onClick={() => this.clear_pays() } >
                    <div>RENSA BETALSÄTT</div>
                    <div><FontAwesomeIcon icon="at" size="2x" /></div>
                </div>
                <div className="btn-rp" onClick={() => this.copy_pgs() } >
                    <div>KOPIERA VARUGRUPPER</div>
                    <div><FontAwesomeIcon icon="at" size="2x" /></div>
                </div>
            </div>
            <div className="flex-row">
                <div className="btn-rp" onClick={() => this.clear_btns() } >
                    <div>RENSA KNAPPAR</div>
                    <div><FontAwesomeIcon icon="at" size="2x" /></div>
                </div>
                <div className="btn-rp" onClick={() => this.clear_plus() } >
                    <div>RENSA PLU</div>
                    <div><FontAwesomeIcon icon="at" size="2x" /></div>
                </div>
            </div>
            </div>

            <div className="flex-row">
                <div className="flex-row flex">
                <div className="btn-rp" onClick={() => this.wnd_close() } >
                    <div>STÄNG</div>
                    <div><FontAwesomeIcon icon="times" size="2x" /></div>
                </div>
                <div className="btn-rp" onClick={() => this.copy_pays() } >
                    <div>KOPIERA BETALSÄTT</div>
                    <div><FontAwesomeIcon icon="at" size="2x" /></div>
                </div>
            </div>
            <div className="flex-row">
                <div className="btn-rp" onClick={() => this.copy_btns() } >
                    <div>KOPIERA KNAPPAR</div>
                    <div><FontAwesomeIcon icon="at" size="2x" /></div>
                </div>
                <div className="btn-rp" onClick={() => this.copy_plus() } >
                    <div>KOPIERA PLU</div>
                    <div><FontAwesomeIcon icon="at" size="2x" /></div>
                </div>
            </div>
            </div>
        </div>

      </div>
            <Wnd_Error msg={this.state.msg} ctx={this} />
            <Wnd_Ok msg={this.state.msg} ctx={this} />
            <Wnd_Info msg={this.state.msg} ctx={this} />
            <Wnd_Confirm msg={this.state.msg} ctx={this} />
    </div>
	;
	return html;
  };
}

export default Plu_Cpy;