import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../../lib/net";
import Fr_Input from "../../../lib/ui/fr_input";
import Fr_Select from "../../../lib/ui/fr_select";

class Term_Edit extends React.Component {
  constructor(props, context) {
    super(props);

    this.pos = props.store.pos;
    this.state = {};
    this.state.mka = [];

    this.state.ynq = [
                {id: '0', text: 'NEJ'},
                {id: '1', text: 'JA'}
            ];

    this.state.tdo = {};
    if(props.wnds.tdo) this.state.tdo = props.wnds.tdo;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    tab_style(tabid) {
      var css = {};
      css.display = "none";
      if(this.state.tabval === tabid) css.display = "block";
      return css;
    }
    tab_head(tabid) {
      var css = {};
      css.background = "#fff";
      css.color = "#000";
      if(this.state.tabval === tabid) {
          css.background = "#080";
          css.color = "#fff";
      }
      return css;
    }
    grp_style(row) {
      var css = {};
      css.background = "#008";
      if(this.state.gnr === row.vgrupp) css.background = "#080";
      return css;
    }

    formchange (e) {
      const name = e.target.name;
      const value = e.target.value;
      var pbo = this.state.pbo;
      var na = name.split(".");
      pbo[na[0]][na[1]] = value;
      this.setState({pbo: pbo});
      //setState({[name]: value});
    }

    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    wnd_close = () => {
		this.props.ctx.setState({ wnds: {} });
	};
    handleChange = (event, newValue) => {
        //alert(newValue);
        this.setState({ix: newValue});
      };

  val_color(id, val) {
      var css = {};
      //alert(JSON.stringify(val))
      this.ctrl_fg.updateState(val.hex);
      this.setState({ bgfarg: val.hex });
      return css;
  }
  val_save(name, value) {
      var bto = this.state.bto;
      var na = name.split(".");
      bto[na[0]][na[1]] = value;
      //bto[na[1]] = value;
      this.setState({bto: bto});
      return;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
    term_save() {
    var self = this;
    var prm = {};
    prm.req = "pos.pos_term.term_upd";
    prm.tdo = this.state.tdo;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        //self.setState({paa: ret.rco });
		self.props.ctx.setState({ wnds: {} });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
    term_del() {
        var self = this;
        var prm = {};
        prm.req = "pos.pos_term.term_del";
        prm.tdo = this.state.tdo;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret));

                //self.setState({paa: ret.rco });
                self.props.ctx.setState({ wnds: {} });
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }

  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "Terminal";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        <div className="m2_backdrop" />
        <div className="m2_dlg mx_90 flex-col" style={ this.dlg_style() } >
        <div className="mx_content flex">

            <div className="flex-row" style={{padding:"5px",height:"35px"}}>
                    <div className="flex-col flex">
                        <div>TERMINAL: {this.state.tdo.ter00.z0ttynr}</div>
                    </div>
                    <div className="flex-col">
                        <div>UPPDATERA</div>
                    </div>
            </div>
            <div className="divider_orange"></div>

            <form className="cdi_f03">
                <div className="flex-col">
                    <Fr_Input label="Akt Avd" className="text-left w150" text={this.state.tdo.ter00.z0kst} getValue={(e)=> this.val_save("bet00.b0kstns", e) } />
                    <Fr_Input label="Bas Avd" className="text-left w150" text={this.state.tdo.ter00.z0kstb} getValue={(e)=> this.val_save("bet00.b0txt", e) } />
                </div>

                <div className="divider_green"></div>

                <div className="flex-row">
                <div className="flex-col">
                    <Fr_Input label="Typ" className="text-left w150" text={this.state.tdo.ter00.z0typ} getValue={(e)=> this.val_save("bet00.b0red", e) } />
                    <Fr_Input label="RP" className="text-left w150" text={this.state.tdo.ter00.z0rptprt} getValue={(e)=> this.val_save("bet00.b0red", e) } />
                    <Fr_Input label="Nota" className="text-left w150" text={this.state.tdo.ter00.z0notaprt1} getValue={(e)=> this.val_save("bet00.b0red", e) } />
                    <Fr_Input label="Nota X" className="text-left w150" text={this.state.tdo.ter00.z0notaprt2} getValue={(e)=> this.val_save("bet00.b0red", e) } />
                    <Fr_Input label="Notatyp" className="text-left w150" text={this.state.tdo.ter00.z0notatyp} getValue={(e)=> this.val_save("bet00.b0red", e) } />
                    <Fr_Input label="Nota RP" className="text-left w150" text={this.state.tdo.ter00.z0typ} getValue={(e)=> this.val_save("bet00.b0red", e) } />
                    <Fr_Input label="Format S" className="text-left w150" text={this.state.tdo.ter00.z0utformat} getValue={(e)=> this.val_save("bet00.b0red", e) } />
                    <Fr_Input label="Bonga S" className="text-left w150" text={this.state.tdo.ter00.z0bonga} getValue={(e)=> this.val_save("bet00.b0red", e) } />
                </div>
                <div className="flex-col">
                    <Fr_Input noflex label="Bong K1" className="text-left w150" text={this.state.tdo.ter00.z0kskr1} getValue={(e)=> this.val_save("bet00.b0txt", e) } />
                    <Fr_Input noflex label="Bong K2" className="text-left w150" text={this.state.tdo.ter00.z0kskr2} getValue={(e)=> this.val_save("bet00.b0red", e) } />
                    <Fr_Input noflex label="Bong K3" className="text-left w150" text={this.state.tdo.ter00.z0kskr3} getValue={(e)=> this.val_save("bet00.b0red", e) } />
                    <Fr_Input noflex label="Bong B1" className="text-left w150" text={this.state.tdo.ter00.z0bar1} getValue={(e)=> this.val_save("bet00.b0red", e) } />
                    <Fr_Input noflex label="Bong B2" className="text-left w150" text={this.state.tdo.ter00.z0bar2} getValue={(e)=> this.val_save("bet00.b0red", e) } />
                </div>
                </div>

                <div className="divider_green"></div>

                <div className="flex-row">
                <div className="flex-col">
                    <Fr_Input label="KRP ID" className="text-left w350" text={this.state.tdo.ter00.z0kasrpt} getValue={(e)=> this.val_save("bet00.b0kstns", e) } />
                    <Fr_Input label="Tryck" className="text-left w350" text={this.state.tdo.ter00.z0tryck} getValue={(e)=> this.val_save("bet00.b0txt", e) } />
                    <Fr_Input label="Serv" className="text-left w350" text={this.state.tdo.ter00.z0nyckel} getValue={(e)=> this.val_save("bet00.b0red", e) } />
                </div>
                <div className="flex-col">
                    <Fr_Input label="Betal Text" className="text-left w350" text={this.state.tdo.ter00.b0txt} getValue={(e)=> this.val_save("bet00.b0txt", e) } />
                    <Fr_Input label="Redovisning" className="text-left w350" text={this.state.tdo.ter00.b0red} getValue={(e)=> this.val_save("bet00.b0red", e) } />
                </div>
                </div>
            </form>

        </div>
        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex"></div>
            <button className="btn btn-hw-red w250" onClick={() => this.term_del() } >
                TA BORT
            </button>
            <div className="flex"></div>
            <button className="btn btn-hw-red w250" onClick={() => this.term_save() } >
                SPARA
            </button>

        </div>
      </div>
      </div>
	;
	return html;
  };
}

export default Term_Edit;