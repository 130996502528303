import React, { useState, useEffect, useContext } from 'react';
import {withRouter} from "../../lib/react/withRouter";
import sso from "../../sso";

class Red_Home extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.pos = props.store.pos;
    this.state = { pgid: 11, tra: [] };
    this.state.seldate = new Date();
    this.state.avd = "01";

      var urlbas = "/pms/hotback";
      this.state.mna = [];
      this.state.mna.push({ mnid: "mn01", pos: "c1", text: "EKONOMI", desc: "", link: urlbas+"/eko" });
      this.state.mna.push({ mnid: "mn02", pos: "c1", text: "STATISTIK", desc: "", link: urlbas+"/stat" });
      this.state.mna.push({ mnid: "mn03", pos: "c1", text: "BELÄGGNING", desc: "", link: urlbas+"/bel" });
      this.state.mna.push({ mnid: "mn04", pos: "c2", text: "RAPPORTER", desc: "", link: urlbas+"/rps" });
      this.state.mna.push({ mnid: "mn05", pos: "c2", text: "KONTOPLAN", desc: "", link: urlbas+"/kp" });
      this.state.mna.push({ mnid: "mn06", pos: "c2", text: "DAGSINFO", desc: "", link: urlbas+"/di" });
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

    mnc(pos) {
        var mna = [];
        for(var row of this.state.mna) {
            if(row.pos == pos) mna.push( row );
        }
        return mna;
    }
  fknGo(url) {
    //alert(row.href);
    var row = {};
    row.url = url;
    sso.seo.sfkn = row;
    this.props.navigate(url);
  }

  render() {
    return (
    <div className="web-app">
        <div className="web-pg py-4 flex">
            <h3>Redovisning</h3>
            <p>Redovisnings funktioner</p>

            <div className="divider_green"></div>

            <div className="web-row">
                <div className="flex-col flex">
                    { this.mnc("c1").map((row, key) =>
                        <div key={key} className="web-bx" onClick={() => this.fknGo(row.link) }>
                            <div>{ row.text }</div>
                            <div className="flex-row">
                                <div className="flex">{ row.desc }</div>
                                <div className="web-link"></div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="flex-col flex">
                    { this.mnc("c2").map((row, key) =>
                        <div key={key} className="web-bx" onClick={() => this.fknGo(row.link) }>
                            <div>{ row.text }</div>
                            <div className="flex-row">
                                <div className="flex">{ row.desc }</div>
                                <div className="web-link"></div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>

    </div>
  );
    }
}
export default withRouter(Red_Home);
