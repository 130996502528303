import React, { useState, useEffect, useContext } from 'react';
import {format} from "date-fns";

import * as net from "../../lib/net";
import Dt_Cal from "../../lib/ui/dt_cal";

import Tbv_Rs from "./tbv/tbv_rs";
import Tbv_Zon from "./tbv/tbv_zon";
import Tbv_Tbs from "./tbv/tbv_tbs";
import Tb_Add from "./tbv/tb_add";
import Tb_Edit from "./tbv/tb_edit";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Pos_Tbv extends React.Component {
  constructor(props, context) {
    super(props);

    this.dt_ref = React.createRef();

    this.store = props.store;
    this.state = {};
    this.state.seldate = new Date();
    this.state.avd = "01";
    this.state.rsa = [];
    this.state.tba = [];
    this.state.aa = [];
     this.state.rs = "";
     this.state.xpos = 0;
     this.state.ypos = 0;
     this.state.moving = 0;
     this.state.wnds = {};
     this.state.dragtbo = {};

  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.rs_list();
  }

  body_style() {
      var css = {};
      css.overflow = "auto"
      css.background = "#fafafa"
      css.width = "100%"
      css.textAlign = "center"
      css.height = window.innerHeight - 200;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 480;
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

  tb_css(tbo) {
  		var css = {};
		var xoff = 0;
		var yoff = 0;
		var bg = "dedede";
		var fg = "000";

        css.position = "absolute";
        css.border = "1px #000 solid";
        css.textAlign = "center";

		var xpos = (tbo.bordX * 1) + xoff;
		var ypos = (tbo.bordY * 1) + yoff;
		var xlen = tbo.bordXlen * 1;
		var ylen = tbo.bordYlen * 1;

		if(tbo.orientation == "090") {
			var xtmp = xlen;
			xlen = ylen;
			ylen = xtmp;
			bg = "ff9900";
		}

        css.left = xpos + "px";
        css.top = ypos + "px";
        css.width = xlen + "px";
        css.height = ylen + "px";
        css.lineHeight = ylen + "px";
        css.background = "#" + bg;
        css.color = "#" + fg;


		if(tbo.bordsname.trim() == "RECTANGULAR") {
			var radius = 5;
            css.borderRadius = radius + "px";
		}
		if(tbo.bordsname.trim() == "OVAL") {
			var radius = xlen / 2;
            css.borderRadius = radius + "px";
		}
		if(tbo.bordsname.trim() == "ROUND") {
			var radius = xlen / 2;
            css.borderRadius = radius + "px";
		}

		return css;
  }

  mark_tbo(row) {
  }
  open_tbo(tbo, event) {
  }
  ctx_tbo(tbo, event) {
      event.preventDefault();
  }

  tbo_add() {
    var self = this;
    var wnds = {};
    wnds.tbadd = true;
    wnds.rs = this.state.rs;
    wnds.cb = self.cb_tbo;
    self.setState({wnds: wnds });
  }
  tbo_edit(tbo) {
    var self = this;
    var wnds = {};
    wnds.tbedit = true;
      wnds.rs = this.state.rs;
      wnds.rsa = this.state.rsa;
    wnds.tbo = tbo;
    wnds.cb = self.cb_tbo;
    self.setState({wnds: wnds });
  }

  cb_tbo(ctx, rsp) {
    var self = this;
    if(rsp.ok == "000") {
        ctx.tb_view();
    }
    ctx.setState({wnds: {} });
  }

  rs_show() {
    var self = this;

    var wnds = {};
    wnds.tbvrs = true;
      wnds.rs = this.state.rs;
      wnds.rsa = this.state.rsa;
    self.setState({wnds: wnds });
  }
  zon_show() {
    var self = this;

    var wnds = {};
    wnds.tbvzon = true;
      wnds.rs = this.state.rs;
      wnds.rsa = this.state.rsa;
    self.setState({wnds: wnds });
  }
  tb_show() {
    var self = this;

    var wnds = {};
    wnds.tbs = true;
    self.setState({wnds: wnds });
  }
  rs_edit(row) {
    var self = this;

    var wnds = {};
    wnds.tbvrs = true;
      wnds.rs = this.state.rs;
      wnds.rsa = this.state.rsa;
    self.setState({wnds: wnds });
  }

  rs_list() {
    var self = this;
    var prm = {};
    prm.req = "pms.rs.qs_rs";

    net.sio_req(prm)
    .then(function(ret) {
        //alert(JSON.stringify(ret));

        let rs = "";
        if (ret.rco[0]) {
            rs = ret.rco[0].restname;
        }
        self.setState({ rsa: ret.rco, rs: rs });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }

  tb_view() {
    var self = this;

    var prm = {};
    //prm.req = "pms.rs_lt.lt_tbs";
    prm.req = "pos.pos_tbv.tbv";
    prm.rs = this.state.rs;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));
        self.setState({tba: ret.rca });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  tb_upd(tbo) {
    var self = this;
    var prm = {};
    //prm.req = "pms.rs_lt.lt_tb_save";
    prm.req = "pos.pos_tbv.tbv_upd";
    prm.rs = this.state.rs;
    prm.tbo = tbo;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  tb_del(tbo) {
    var self = this;
    var prm = {};
    //prm.req = "pms.rs_lt.lt_tb_save";
    prm.req = "pos.pos_tbv.tbv_del";
    prm.rs = this.state.rs;
    prm.tbo = tbo;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));
        self.tb_view();
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  dt_dat(e) {
    //alert(JSON.stringify(e));
    var dts = format(e, "yyyy-MM-dd");
    this.setState({ fdat: dts });
  }
  change_rs(e) {
    //let cdat = this.state.seldate;
    let rs = e.target.value;
    this.setState({rs: rs});
  }
    drag_tbo(event, row) {
        //event.preventDefault();
        var bordsnr = "";
        var text = "";
        if(row) {
            bordsnr = row.bordsnr;
            text = row.bordsnr.toswe();
        }

        var rzo = {};
        rzo.x = row.bordX.trim()*1;
        rzo.y = row.bordY.trim()*1;

        var dx = Math.round( row.bordXlen.trim()*1/2 );
        var dy = Math.round( row.bordYlen.trim()*1/2 );

        var xoff = Math.round( (window.innerWidth - 1024) / 2 );
        var xpos = event.clientX - rzo.x;
        var ypos = event.clientY - rzo.y;
        /*
        var dragImage = document.createElement('div');
        dragImage.id = 'drag-image';
        //dragImage.classList.add('rf_drag');
        //dragImage.style = this.tb_css(row);
        dragImage.style.border = "1px #000 solid";
        dragImage.style.width = row.bordXlen + "px";
        dragImage.style.height = row.bordYlen + "px";
        dragImage.style.lineHeight = row.bordYlen + "px";
        dragImage.style.background = "#800";
        dragImage.style.color = "#fff";
        dragImage.innerHTML = "<div>"+ text + "</div><div>"+ bordsnr + "</div>";
        this.dragImage = dragImage;
        document.body.appendChild( dragImage );
        event.dataTransfer.setDragImage(dragImage, dx, dy);
        */
        //this.node.current.appendChild( dragImage );
        //event.dataTransfer.setDragImage(dragImage, 62, 32);
        //event.dataTransfer.setData("typ", "btn");

        var o = {};
        o.typ = "tb";
        o.xpos = xpos;
        o.ypos = ypos;
        o.tbo = row;
        var txt = JSON.stringify(o);
        event.dataTransfer.setData("text", txt);
        this.setState({dragtbo: row});
   }
    drag_end(event) {
        //this.dragImage.remove();
        this.setState({dropgst: {}, droproom : ""});
    };
    drop_tbo(event, row) {
        event.preventDefault();
        //event.target.style.background = this.prev_bg;
        var xpos = event.clientX - row.bordX;
        var ypos = event.clientY - row.bordY;

        //alert(JSON.stringify(xpos));
        var txt = event.dataTransfer.getData("text");
        var o = JSON.parse(txt);

       if(row.closed == "1") {
            alert("Stängt");
            return;
        }
    };
    drag_tbo_enter(event, row) {
        event.preventDefault();
        this.setState({droptbo: row});
	};
    drag_tbo_leave(event) {
        event.preventDefault();
        //alert("Allow Drop");
        this.setState({droptbo: {}});
	};
    drag_rst_enter(event) {
        event.preventDefault();
	};
    drag_rst_leave(event) {
        event.preventDefault();
        //alert("Allow Drop");
	};
    drop_rst(event) {
        event.preventDefault();
        //event.target.style.background = this.prev_bg;

        var txt = event.dataTransfer.getData("text");
        var o = JSON.parse(txt);
        var tbo = o.tbo;

        var xpos = event.clientX - o.xpos;
        var ypos = event.clientY - o.ypos;

        tbo.bordX = xpos.toString();
        tbo.bordY = ypos.toString();
        //alert(JSON.stringify(tbo));
        this.tb_upd(tbo);
        var row = this.state.dragtbo;
        row.bordX = xpos.toString();
        row.bordY = ypos.toString();
        this.setState({dragtbo: {}});
    };

    tbv_prnx() {
      window.print();
    }
    tbv_prn() {
      var divContents = document.getElementById("tbvbody").innerHTML;
      var a = window.open('', '', 'height=1024, width=1024');
      a.document.write('<html>');
      a.document.write('<head><style>@media print{@page {size: landscape}}</style></head>');
      a.document.write('<body className="web-app">');
      a.document.write(divContents);
      a.document.write('</body></html>');
      a.document.close();
      a.focus(); // necessary for IE >= 10*/
      a.print();
      a.close();
}
  render() {
    return (
    <div className="web-app">
    <div className="flex-row">
        <select className="form-control sel_input w350" placeholder="Välj Resurs" onChange={(e) => this.change_rs(e)}>
            { this.state.rsa.map((row, key) =>
            <option key={key} value={row.restname}>{row.restname} : {row.fromtime} - {row.tilltime}</option>
            )}
        </select>
        <button className="btn btn-hw-blue w150" onClick={() => this.tb_view() } aria-label="System" >
            VISA
        </button>
        <div className="flex"></div>
        <button className="btn btn-hw-blue w150" onClick={() => this.rs_show() } aria-label="System" >
            RESURSER
        </button>
        <button className="btn btn-hw-blue w150" onClick={() => this.zon_show() } aria-label="System" >
            ZONER
        </button>
        <button className="btn btn-hw-blue w150" onClick={() => this.tb_show() } aria-label="System" >
            BORD
        </button>
    </div>
    <div className="divider_orange"></div>

    <div className="flex-row">
        <div className="flex"></div>
        <div className="web-link" onClick={() => this.tbo_add() }>LÄGG TILL NYTT BORD</div>
        <div className="flex"></div>
        <div className="web-link" onClick={() => this.tbv_prn() }>Skriv ut</div>
    </div>
    <div className="divider_green"></div>

    <div id="tbvbody" className="web-row">
        <div style={ this.body_style() }>
        <div
            style={{position: "relative",width: "1024px", height:"768px",margin: "0px auto",background: "#fff"}}
            onDragOver={ (event) => this.drag_rst_enter(event) }
            onDragLeave={ (event) => this.drag_rst_leave(event) }
            onDrop={ (event) => this.drop_rst(event)}
        >

            { this.state.tba.map((tbo, key) =>
            <div key={ key }
                onClick={ () => this.mark_tbo(tbo) }
                onDoubleClick={() => this.tbo_edit(tbo) }
                onContextMenu={(event) => this.ctx_tbo(tbo, event) }
                draggable
                onDragStart={ (event) => this.drag_tbo(event, tbo)}
                onDragEnd={ (event) => this.drag_end(event)}
                onDragOver={ (event) => this.drag_tbo_enter(event, tbo) }
                onDragLeave={ (event) => this.drag_tbo_leave(event) }
                onDrop={ (event) => this.drop_tbo(event, tbo)}
                style={ this.tb_css(tbo) }
            >
            { tbo.bordsnr }
            </div>
            )}

    </div>
    </div>
    </div>
    <div className="divider_orange"></div>

    { this.state.wnds.tbvrs ? <Tbv_Rs wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true} /> : null}
    { this.state.wnds.tbvzon ? <Tbv_Zon wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true} /> : null}
        { this.state.wnds.tbs ? <Tbv_Tbs wnds={this.state.wnds} rs={this.state.rs} ctx={this} store={this.props.store} backdrop={true} /> : null}
        { this.state.wnds.tbadd ? <Tb_Add wnds={this.state.wnds} rs={this.state.rs} ctx={this} store={this.props.store} backdrop={true} /> : null}
        { this.state.wnds.tbedit ? <Tb_Edit wnds={this.state.wnds} rs={this.state.rs} ctx={this} store={this.props.store} backdrop={true} /> : null}
    </div>
  );
    }
}
export default Pos_Tbv;