import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";
import {withRouter} from "../../lib/react/withRouter";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Fsg_Home extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, tra: [] };
    this.state.seldate = new Date();
    this.state.avd = "01";
    this.state.paa = [];
    this.state.aa = [
                {id: '01', text: 'AVD 01'},
                {id: '02', text: 'AVD 02'}
            ];
    //context.posrend = this;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.plus();
  }
  fknGo(url) {
    //alert(row.href);
    this.props.navigate(url);
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 480;
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

  plu_open(row) {
      var self = this;
  }
  plu_edit(row) {
      var self = this;
      alert(JSON.stringify(row.plu00));
  }

  plus() {
    var self = this;
    var prm = {};
    prm.req = "pos.pos_plu.plus";
    prm.avd = this.state.avd;
    //prm.fplu = datum;
    //prm.tplu = datum;

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        var o = {};
        //o.deps = ret.rco;
        //alert(JSON.stringify(o));
        self.setState({paa: ret.rco });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }

  render() {
    return (
    <div className="web-app">
    <h3>FÖRSÄLJNING</h3>

    <div className="web-p01 flex">

    <div className="web-row">
    <div className="web-col flex">
        <div className="web-btn" onClick={() => this.fknGo("/cas/fsg/trs") } >
            <div>TRANSAKTIONER</div>
            <div>Terminal transaktioner</div>
        </div>
    </div>
     <div className="web-col flex" style={{padding: "0px 10px"}}>
        <div className="web-btn" onClick={() => this.fknGo("/cas/fsg/hh") } >
            <div>TIM FSG</div>
            <div>Fsg timme för timme</div>
        </div>
   </div>
     <div className="web-col flex">
        <div className="web-btn hide" onClick={() => this.fknGo("/cas/fsg/xxx") } >
            <div>XXX</div>
            <div>YYY</div>
        </div>
   </div>
   </div>

    </div>

    </div>
  );
    }
}
export default withRouter(Fsg_Home);
