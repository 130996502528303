import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";
import Dt_Cal from "../../lib/ui/dt_cal";
import {format} from "date-fns";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Drift_Queues extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, tra: [] };
    this.state.seldate = new Date();
    this.state.datum = "01";
    this.state.avd = "01";
    this.state.caa = [];
    this.state.aa = [
                {id: '01', text: 'AVD 01'},
                {id: '02', text: 'AVD 02'}
            ];
    //context.posrend = this;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.plus();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

  call_edit(row) {
      var self = this;
      alert(JSON.stringify(row.plu00));
  }
  dt_dat(e) {
    //alert(JSON.stringify(e));
    var dts = format(e, "yyyy-MM-dd");
    this.setState({ datum: dts });
    //this.cas_sum(dts);
  }

  calls() {
    var self = this;
    var dts = this.state.datum;

    var prm = {};
    prm.req = "tele.tele_stat.cdr";
    prm.datum = dts;
    prm.fdat = dts;
    prm.tdat = dts;

    net.sio_req(prm)
    .then(function(ret){
        alert(JSON.stringify(ret));

        self.setState({caa: ret.rca });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

  render() {
    return (
    <div className="web-app">
    <h3>Köer</h3>

    <div className="flex-row">
        <Dt_Cal startdatum="2020-02-01" getValue={(e)=> this.dt_dat(e)} />
        <select className="form-control sel_input" placeholder="Välj avdelning">
            { this.state.aa.map((row, key) =>
            <option key={key} value={row.id}>{row.text}</option>
            )}
        </select>
        <div className="flex"></div>
        <button className="btn btn-hw-blue w150 ripple" onClick={() => this.calls() } aria-label="System" >
            UPPDATERA
        </button>
    </div>
    <div className="divider_orange"></div>

    <div className="web-row">
        <table className="table table-bordered table-hover tb fixed_header">
            <thead>
            <tr>
                <th>ID</th>
                <th>Ringtid</th>
                <th>Src</th>
                <th>Dst</th>
                <th>Dura</th>
                <th>Disp</th>
                <th>UID</th>
            </tr>
            </thead>
            <tbody style={ this.tb_style() }>
            { this.state.caa.map((row, key) =>
            <tr key={key} id={ row.id } onDoubleClick={() => this.plu_open(row) }>
                <td>{ row.lastapp }</td>
                <td>{ row.calldate }</td>
                <td>{ row.src }</td>
                <td>{ row.dst }</td>
                <td>{ row.duration }</td>
                <td>{ row.disposition }</td>
                <td>{ row.uniqueid }</td>
            </tr>
            )}
            </tbody>
        </table>
    </div>

    </div>
  );
    }
}
export default Drift_Queues;

/*
calldate,src,dst,duration,uniqueid,disposition
calldate,src,dst,duration,dstchannel,disposition
*/