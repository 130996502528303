import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";
import Fr_Input from "../../lib/ui/fr_input";
import Fr_Cal from "../../lib/ui/fr_cal";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Bo_Ftg extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = {};
    this.state.seldate = new Date();
    this.state.avd = "01";
    this.state.paa = [];
      this.state.aa = [];
      this.state.sdo = {};
      this.state.sdo.gpost1 = {};
      this.state.sdo.gpost2 = {};
      this.state.sdo.gpost3 = {};
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      this.ta_prm();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 480;
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

    ta_prm() {
        var self = this;
        var prm = {};
        prm.req = "ta.ta_bo.company";
        //prm.faknr = this.state.faknr;

        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret.rco));
            if(ret.ok == "000") {
                var sdo = ret.rco;
                self.setState({ sdo: sdo });
                //self.syncTb("fakTable");
            }
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }
    ta_prm_save() {
        var self = this;

        var sdo = this.state.sdo;

        var prm = {};
        prm.req = "ta.ta_bo.company_save";
        prm.sdo = this.state.sdo;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret));
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    val_save(id, val) {
        var sdo = this.state.sdo;
        var id1 = id.split(".")[0];
        var id2 = id.split(".")[1];
        sdo[id1][id2] = val;
        //wfp[id] = val;
        this.setState({ sdo: sdo });
        return;
    }

  render() {
    return (
        <div className="web-app">

            <div className="flex-row">
                <div className="label_h03">Företag</div>
                <div className="flex"></div>
                <button className="btn btn-hw-orange w150" onClick={() => this.ta_prm_save() } aria-label="System" >
                    Spara
                </button>
                <button className="btn btn-hw-blue w150" onClick={() => this.ta_prm() } aria-label="System" >
                    Visa
                </button>
            </div>
            <div className="divider_green"></div>

            <div className="cdi_f03">
                <div className="flex-row">
                    <Fr_Input label="Företag" className="text-left w350" text={this.state.sdo.gpost1.namn} getValue={(e)=> this.val_save("gpost1.namn", e) } />
                    <Fr_Input label="Orgnr" className="text-left w250" text={this.state.sdo.gpost2.orgnr} getValue={(e)=> this.val_save("gpost2.orgnr", e) } />
                </div>
                <div className="flex-row">
                    <Fr_Input label="Adress" className="text-left w350" text={this.state.sdo.gpost1.adress} getValue={(e)=> this.val_save("gpost1.adress", e) } />
                </div>
                <div className="flex-row">
                    <Fr_Input label="Postnr" className="text-left w150" text={this.state.sdo.gpost1.postnr} getValue={(e)=> this.val_save("gpost1.postnr", e) } />
                    <Fr_Input label="Postadress" className="text-left w350" text={this.state.sdo.gpost1.postadr} getValue={(e)=> this.val_save("gpost1.postadr", e) } />
                </div>
                <div className="flex-row">
                    <Fr_Input label="Period Start" className="text-left w250" text={this.state.sdo.gpost1.periodstart} getValue={(e)=> this.val_save("gpost1.periodstart", e) } />
                    <Fr_Input label="Period Slut" className="text-left w250" text={this.state.sdo.gpost1.periodslut} getValue={(e)=> this.val_save("gpost1.periodslut", e) } />
                </div>
                <div className="divider_green"></div>
                <div className="flex-row">
                    <Fr_Input label="Anled Tid" className="text-left w250" text={this.state.sdo.gpost2.anledn_tid} getValue={(e)=> this.val_save("gpost2.anledn_tid", e) } />
                </div>
                <div className="flex-row">
                    <Fr_Input label="Anled OB1" className="text-left w250" text={this.state.sdo.gpost2.anledn_ob1} getValue={(e)=> this.val_save("gpost2.anledn_ob1", e) } />
                    <Fr_Input label="Anled OB2" className="text-left w250" text={this.state.sdo.gpost2.anledn_ob2} getValue={(e)=> this.val_save("gpost2.anledn_ob2", e) } />
                </div>
                <div className="flex-row">
                    <Fr_Input label="Utj Tid" className="text-left w250" text={this.state.sdo.gpost2.utjamn_tid} getValue={(e)=> this.val_save("gpost2.utjamn_tid", e) } />
                </div>

            </div>
    </div>
  );
    }
}
export default Bo_Ftg;
