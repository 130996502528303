import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";
import Fsg_dlg from "./rps/fsg_dlg";
import Oms_dlg from "./rps/oms_dlg";
import Trs_dlg from "./rps/tr_dlg";
import Pr_dlg from "./rps/pr_dlg";
import {withRouter} from "../../lib/react/withRouter";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Eko_Rps extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = {};
    this.state.seldate = new Date();
    this.state.avd = "01";
    this.state.paa = [];
    this.state.aa = [];

    this.state.mna = [];
    this.state.mna.push({ rpid: "fsg", pos: "c1", text: "FSG Rapport", desc: "Aktuell status", link: "/ecom/eko/fsg" });
    this.state.mna.push({ rpid: "per", pos: "c1", text: "PERIOD", desc: "Period summering", link: "/ecom/eko/per" });
      this.state.mna.push({ rpid: "trs", pos: "c2", text: "TRANSAKTIONER", desc: "Ekonomiska rapporter", link: "/ecom/eko/trs" });
      this.state.mna.push({ rpid: "pr", pos: "c2", text: "PRODUKTER", desc: "Produkt summering", link: "/ecom/eko/pr" });
      //this.state.mna.push({ rpid: "invs", pos: "c2", text: "KVITTON", desc: "Ekonomiska rapporter", link: "/ecom/eko/kvitton" });
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

    mnc(pos) {
        var mna = [];
        for(var row of this.state.mna) {
            if(row.pos == pos) mna.push( row );
        }
        return mna;
    }
  fknGo(url) {
    //alert(row.href);
    this.props.navigate(url);
  }
    rp_open(typ) {
        var self = this;
        if(typ == "invs") self.xrecov();
        this.setState({ rptyp: typ });
    }

    xrecov() {
        var self = this;

        //var favd = this.state.favd;
        //var tavd = this.state.tavd;
        var favd = "01";
        var tavd = "01";
        var dpa = [];
        var iavd = favd * 1;
        var maxavd = tavd * 1;
        do {
            var avd = iavd.pad(2);
            dpa.push( avd );
            iavd = iavd + 1;
        } while( iavd <= maxavd);

        var prm = {};
        //prm.req = "pos.pos_rp.bok";
        prm.sid = "S0000291";
        prm.req = "pos.pos_trs_wrk.xrecov";
        //prm.fdat = this.state.fdat;
        //prm.tdat = this.state.tdat;
        prm.fdat = "20220401";
        prm.tdat = "20220622";
        prm.dpa = dpa;
        //alert(JSON.stringify(prm));

        //gda.wndLoading("Hämtar");
        net.sid_req(prm)
            .then(function(ret){
                alert(JSON.stringify(ret.ok));

                //var uri = "data:application/pdf;base64," + ret.pdf;
                //self.setState({pdf: 1, pdf_content: ret.pdf });
                //self.syncCols("lsTable");
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
                //gda.wndError(txt);
            });
    }
    ackrecov() {
        var self = this;

        var favd = this.state.favd;
        var tavd = this.state.tavd;
        var dpa = [];
        var iavd = favd * 1;
        var maxavd = tavd * 1;
        do {
            var avd = iavd.pad(2);
            dpa.push( avd );
            iavd = iavd + 1;
        } while( iavd <= maxavd);

        var prm = {};
        //prm.req = "pos.pos_rp.bok";
        prm.req = "pos.pos_trs_wrk.ackrecov";
        prm.fdat = this.state.fdat;
        prm.tdat = this.state.tdat;
        //prm.fdat = "20200701";
        //prm.tdat = "20200701";
        prm.dpa = dpa;
        //alert(JSON.stringify(prm));

        //gda.wndLoading("Hämtar");
        net.sio_req(prm)
            .then(function(ret){
                alert(JSON.stringify(ret.ok));

                //var uri = "data:application/pdf;base64," + ret.pdf;
                //self.setState({pdf: 1, pdf_content: ret.pdf });
                //self.syncCols("lsTable");
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
                //gda.wndError(txt);
            });
    }

  render() {
    return (
    <div className="web-app">
        <div className="web-pg py-4 flex">
            <h3>Rapporter</h3>
            <p>Ekonomi rapporter</p>

            <div className="divider_green"></div>

            <div className="divider_green"></div>

            <div className="web-row">
                <div className="flex-col flex">
                    { this.mnc("c1").map((row, key) =>
                        <div className="web-bx" onClick={() => this.rp_open(row.rpid) }>
                            <div>{ row.text }</div>
                            <div className="flex-row">
                                <div className="flex">{ row.desc }</div>
                                <div className="web-link">Välj</div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="flex-col flex">
                    { this.mnc("c2").map((row, key) =>
                        <div className="web-bx" onClick={() => this.rp_open(row.rpid) }>
                            <div>{ row.text }</div>
                            <div className="flex-row">
                                <div className="flex">{ row.desc }</div>
                                <div className="web-link">Välj</div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
        { this.state.rptyp == "fsg" ? <Fsg_dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "per" ? <Oms_dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "trs" ? <Trs_dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "pr" ? <Pr_dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }

    </div>
  );
    }
}
export default withRouter(Eko_Rps);
