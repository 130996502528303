import React, { useState, useEffect, useContext } from 'react';
import ReactDOM from 'react-dom';
import { SDOContext } from '../sdo.js'
import {withRouter} from "../lib/react/withRouter";

//import './css/style-liberty.css';

import * as net from "../lib/net";
import Dd_Sel from "../lib/ui/dd_sel";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ddo from '../ddo.js'

class Sso_Login extends React.Component {
    //var sdo = useContext(SDOContext); // Functional
    static contextType = SDOContext; // Class

    constructor(props, context) {
        super(props);

        this.s1ref = React.createRef();
        this.s2ref = React.createRef();

        this.store = props.store;
        this.seo = props.store.seo;

        this.state = { pgid: 11, stat: [] };
        this.state.open = false;
        this.state.pdfopen = false;
        this.state.xxopen = false;
        this.state.pdf_uri = "";
        this.state.seldate = new Date('2020-03-24T13:11:54');
        this.state.row = null;
        this.state.info = "";
        this.state.top = 1;
        this.state.user = {};
        this.state.sdo = {};
        this.state.msg = {};

        this.state.step = 1;
        this.state.pdf = 0;
        this.state.pdf_uri = "";
        this.state.mnus = ["m00", "m01", "m02", "m03", "m08", "m09"];
        this.state.mnu_sel = "m00";
        this.state.systemtext = "kungsbacka.se";
        this.selitems = [
            {fkn: 'setup', text: 'INSTÄLLNINGAR'},
            {fkn: 'upd', text: 'UPPDATERA'},
            {fkn: 'sys', text: 'SYSTEM'}
        ];

        if(this.store.cfg.brand == "cdi") {
            this.state.systemtext = "CDI PORTAL";
        }

    }
    componentDidMount(){
        this.verify_sso();
    }

    componentWillUnmount(){
    }

    logo(){
        var logo = null;
        if(this.store.cfg.logo == "cdi") logo = require('./img/logo-cdi.png');
        else logo = require('./img/logo-sk.png');
        return logo;
    }
    onScroll(event) {
        var body = document.getElementById("web-body");
        if (body.scrollTop > 150) {
          this.setState({top: 0})
          this.setState({info: 'small'})
        } else {
          this.setState({top: 1})
          this.setState({info: 'big:'+body.scrollTop})
        }

        if (true) {
            var offset = body.scrollTop;
            var start = body.scrollTop;
            var slut = body.scrollTop + window.innerHeight;

            for(var mnuid of this.state.mnus) {
                const element = document.getElementById(mnuid);
                if(!element || (element == null) || (element == undefined)) continue;
                var eltop = element.offsetTop;
                var elbot = element.offsetTop + element.offsetHeight;
                var brc = eltop >= start && eltop <= slut;
                //if(!brc) brc = elbot >= start && elbot <= slut;
                if(brc) {
                    this.setState({mnu_sel: mnuid});
                    return;
                }
            }
        }
    }
   wnd_css() {
  	    var css = {};
		if(this.state.msg.err) {
			css.color = "#fff";
			css.background = "#800";
		}

		return css;
    }
   header_css() {
  	    var css = {};
		if(this.state.top) {
			css.color = "#fff";
			css.background = "transparent";
			css.height = "50px";
			//css["background"] = "green";
		}
		else {
			css.color = "#000";
			css.background = "#fff";
		    css.height = "50px";
        }

		return css;
    }
   ham_mnu_css() {
  	    var css = {};
		if(this.state.top) {
			css.display = "flex";
			css.display = "none";
		}
		else {
			css.display = "none";
        }

		return css;
    }
    header_class() {
  	    var cls = "flex_col";
		if(this.state.top) {
			cls = cls + " web-head_1";
		}
		else cls = cls +  " web-header";

		return cls;
    }

    ham_show() {
         this.setState({ isOpen: !this.state.isOpen });
   }
    ham_ol() {
        //alert("OL : ..");
         //this.setState({ isOpen: 0 });
   }
    sdo_upd() {
        var sdo = this.state.sdo;
        if(!sdo.fid) sdo.fid = "0";
        sdo.fid = ((sdo.fid * 1) + 1).toString();
        this.setState({sdo: sdo})
        sessionStorage.setItem("svp", JSON.stringify(this.state.sdo));
        //this.props.history.push("cas");
    }
    sdo_ctx() {
        sessionStorage.removeItem('svp');
        //sessionStorage.clear();
    }
    sdo_cas() {
        this.context.fid = "45";
        this.props.navigate("cas");
    }
    ct_show(id) {
        //alert(id);
        const element = document.getElementById(id);
        if (element) element.scrollIntoView();
        //const tesNode = ReactDOM.findDOMNode(id);
        //window.scrollTo(0, tesNode.offsetTop);
    }
    w00_css() {
  	    var css = {};
        css.height = window.innerHeight;
        css['minHeight'] = window.innerHeight;
		return css;
    }
    cdi_step() {
        //alert(id);
        var step = this.state.step + 1;
        if(step > 2) step = 1;
        this.setState({ step: step });
        if(step == 1) {
            var element = ReactDOM.findDOMNode(this.s1ref.current);
            element.classList.add("animated", "rotateIn");
            element.addEventListener('animationend', function() {
                element.classList.remove('animated', "rotateIn")
                //node.removeEventListener('animationend', handleAnimationEnd)
            })
        }
        if(step == 2) {
            var element = ReactDOM.findDOMNode(this.s2ref.current);
            element.classList.add("animated", "bounceIn");
            element.addEventListener('animationend', function() {
                element.classList.remove('animated', "bounceIn")
                //node.removeEventListener('animationend', handleAnimationEnd)
            })
        }

         setTimeout(() => {
            this.cdi_step();
        }, 5000);
    }
    cdi_show(id) {
        //alert(id);
        var brc = false;
        if(this.state.step == id) brc = true;
        return brc;
    }

    row_css(row) {
  	    var css = {};
		if(this.state.row) {
		if(this.state.row.ank == row.ank) {
			css.background = "#080";
			css.color = "#fff";
		}
		}
		return css;
    }
    login() {
        this.setState({login: true });
        //this.props.history.push("pos");
    }
    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    handleLogin(event) {
        this.login_srv(this.state.user.uid, this.state.user.pwd);
    }
	handleClose = () => {
		//this.props.ctx.setState({ login: false });
	};
    verify_sso() {
        var qs = window.location.search;
        var qa = qs.split("=");
        var uid = qa[1];
        var pwd = "kba1234";
        //alert(JSON.stringify(uid));

        /*
        var hash = window.location.hash;
        if(hash) {
            //alert(JSON.stringify(hash));
        }
        */

        this.login_srv(uid, pwd);
    };
    login_srv(uid, pwd) {
        var self = this;
		var prm = {};
        prm.req = "usr:login";
		prm.uid = uid;
		prm.pwd = pwd;
		prm.token = "";

        if(uid == "old") {
            var url = "/renew";
            this.props.navigate(url);
            return;
        }
        if(uid == "err") {
            var msg = { info: false, err: true, text: "Login fel ..."};
            self.setState({msg: msg });
            setTimeout(function(){
                self.setState({msg: {} });
            }, 3000);
            return;
        }
		//alert(JSON.stringify(prm));
        net.gcs_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret));

            if(ret.ok == "991") {
                var url = "/renew";
                self.props.navigate(url);
                return;
            }
            if(ret.ok != "000") {
                //alert(JSON.stringify(ret));
                var msg = { info: false, err: true, text: "Login fel ..."};
                self.setState({msg: msg });
                setTimeout(function(){
                    self.setState({msg: {} });
                }, 3000);
                return;
            }
            //alert(JSON.stringify(ret.seo));

            var seo = ret.seo;
            /*
            if(true) {  // KFT

                if(uid == "yippd4fnurfn4igb@kungsbacka.se") { seo.avd = "00"; seo.auth = "05"; } // Anna-Lena Berntsson anna-lena.berntson@kungsbacka.se
                if(uid == "xmcrytpey3a6icy8@kungsbacka.se") { seo.avd = "00"; seo.auth = "09"; } // Vivi-anne Olofsson (Koordinator)
                if(uid == "azq725nn5dnnani5@kungsbacka.se") { seo.avd = "00"; seo.auth = "09"; } // Carina Bokesand (Ekonom)
                if(uid == "qtu97khryhbhdryd@kungsbacka.se") { seo.avd = "00"; seo.auth = "09"; } // Lena Ottosson (Administratör)

                if(uid == "ienkr5s7yunzxj9s@kungsbacka.se") { seo.avd = "04"; seo.auth = "09"; } // Alexander Aronsson
                if(uid == "h6pvbryfefhcej8b@kungsbacka.se") { seo.avd = "11"; seo.auth = "39"; seo.grouptag = "bibl"; } // Marianne Pettersson (Biblioteken)
                if(uid == "n5upv62dap8utj9e@kungsbacka.se") { seo.avd = "11"; seo.auth = "39"; seo.grouptag = "bibl"; } // Ida Nolke (Biblioteken)
                if(uid == "dme53grbvgcxxaf7@kungsbacka.se") { seo.avd = "02"; seo.auth = "59"; } // Michael Wing (Kungsbacka ishall)
                if(uid == "mmpm58gspihg9xad@kungsbacka.se") { seo.avd = "02"; seo.auth = "59"; } // Axel Berntsson (Kungsbacka ishall)
                if(uid == "dnxdjgirb7ftemh8@kungsbacka.se") { seo.avd = "20"; seo.auth = "59"; } // Nedim Kirlic (UiK/LAN)
                if(uid == "htpiuc28suqadtun@kungsbacka.se") { seo.avd = "03"; seo.auth = "39"; seo.grouptag = "UIK"; } // Niklas Liljebjörn (Skatehallen/Kobacka mötesplats)
                if(uid == "77u34yhumrj78e9y@kungsbacka.se") { seo.avd = "03"; seo.auth = "59"; } // Mirja Brillman (Skatehallen/Kobacka mötesplats)
                if(uid == "n5mqg2isqanc9ns9@kungsbacka.se") { seo.avd = "01"; seo.auth = "59"; } // Niklas Lilja (Naturum)
                if(uid == "njcbqyjt5a66ka3e@kungsbacka.se") { seo.avd = "01"; seo.auth = "59"; } // Johanna Svennevid (Naturum)
                if(uid == "8guqfgdh7k9dmd2a@kungsbacka.se") { seo.avd = "01"; seo.auth = "59"; } // Linda Landberg (Naturum)
                if(uid == "m6yubfjn9pb8uv3i@kungsbacka.se") { seo.avd = "04"; seo.auth = "59"; } // Deborah Haugaard (Kungsbacka Teater)
                if(uid == "t948ty5eqxhfng3t@kungsbacka.se") { seo.avd = "04"; seo.auth = "59"; } // Ann-Ci Warlöv (Kungsbacka Teater)
                if(uid == "ridt8rjgd4emcbz2@kungsbacka.se") { seo.avd = "05"; seo.auth = "59"; } // Birgitta Severinsson (Kungsbacka Konsthall & Turistinformation)
                if(uid == "ncdn2gzvubdtdjrf@kungsbacka.se") { seo.avd = "05"; seo.auth = "59"; } // Frida Lindblad (Kungsbacka Konsthall & Turistinformation)
                if(uid == "bbv88u3ui3vfba8x@kungsbacka.se") { seo.avd = "06"; seo.auth = "59"; } // Julia Mielke (Äskhults by)
                if(uid == "mbne4g8uih5etvxn@kungsbacka.se") { seo.avd = "06"; seo.auth = "59"; } // Charlotta Hamnell (Äskhults by) charlotta.hamnell@kungsbacka.se
                if(uid == "gfbpedjcrurz2e9a@kungsbacka.se") { seo.avd = "21"; seo.auth = "59"; } // Ewa Hallberg (UiK) ewa.hallberg@kungsbacka.se
                if(uid == "5yhae3qeu4u6s4vb@kungsbacka.se") { seo.avd = "21"; seo.auth = "59"; } // Cecilia Anderssson (UiK) cecilia.andersson@kungsbacka.se
                if(uid == "23azvuutn9x6pf72@kungsbacka.se") { seo.avd = "21"; seo.auth = "59"; } // Ulrika Glingborn (UiK)  ulrika.glingborn@kungsbacka.se
                if(uid == "57uhuivuqrze3rnh@kungsbacka.se") { seo.avd = "21"; seo.auth = "59"; } // Julia Lindström (UiK) julia.lindstrom2@kungsbacka.se
                if(uid == "v69hn4scgdpnmha5@kungsbacka.se") { seo.avd = "21"; seo.auth = "59"; } // Kajsa Ingemansson (UiK) kajsa.ingemansson@kungsbacka.se
            }

            if(true) {  // FG

                if(uid == "39tf4jq53a9gdduk@kungsbacka.se") { seo.avd = "00"; seo.auth = "09"; } // Thomas Andersson
                if(uid == "9ihr8xq9d3qget72@kungsbacka.se") { seo.avd = "00"; seo.auth = "09"; } // Lee-Anne Hilmarch
                if(uid == "xggpjergbm42qpg7@kungsbacka.se") { seo.avd = "00"; seo.auth = "09"; } // Gustav Sjöström

                if(uid == "kvdz9s2a2rak3mnx@kungsbacka.se") { seo.avd = "01"; seo.auth = "59"; } // Märta Andersson
                if(uid == "95jgi52j79n8aez9@kungsbacka.se") { seo.avd = "02"; seo.auth = "59"; } // Robin Fritz Pedersen
                if(uid == "g8c2z4zrqd2di7p7@kungsbacka.se") { seo.avd = "03"; seo.auth = "59"; } // Carina Hörlinge Kavsjö
                if(uid == "8dvf6zufqr39mnmt@kungsbacka.se") { seo.avd = "05"; seo.auth = "59"; } // Thorbjörn Svensson
                if(uid == "ab6923n6ms8anj9a@kungsbacka.se") { seo.avd = "06"; seo.auth = "59"; } // Sebastian Waern
                if(uid == "jviyy2idfcitf6za@kungsbacka.se") { seo.avd = "06"; seo.auth = "59"; } // Jenny Rappe
                if(uid == "537tiurxmisjmasd@kungsbacka.se") { seo.avd = "07"; seo.auth = "59"; } // Birgitta Häggsten
                if(uid == "5yhnpzqh7nmbuxxg@kungsbacka.se") { seo.avd = "08"; seo.auth = "59"; } // Ulrika Pettersson
                if(uid == "atbvsfd6bjv85pxy@kungsbacka.se") { seo.avd = "09"; seo.auth = "59"; } // Peter Mars
                if(uid == "xaar4rrny3a6b56c@kungsbacka.se") { seo.avd = "04"; seo.auth = "59"; } // Carina Sandström
                if(uid == "rn3kg4ujhr2hbess@kungsbacka.se") { seo.avd = "10"; seo.auth = "59"; } // Adam Nordquist

                if(uid == "skkvaqc8te28nmmv@kungsbacka.se") { seo.avd = "11"; seo.auth = "59"; } // Veronica Löfman
                if(uid == "fcj9vdhhu8pr8nhi@kungsbacka.se") { seo.avd = "11"; seo.auth = "59"; } // Helena Anund
            }

            if(true) {  // TEKNIK

                if(uid == "4nu24kz8d9uqgp44@kungsbacka.se") { seo.avd = "00"; seo.auth = "09"; } // Tina Landén

                if(uid == "57h6xxb7qkucrsy8@kungsbacka.se") { seo.avd = "01"; seo.auth = "59"; } // Helén Wigerdt
                if(uid == "i8hmqf9mmigr68qq@kungsbacka.se") { seo.avd = "01"; seo.auth = "59"; } // Linda Sövgren
            }

            if(true) {  //  FAMILJ

                if(uid == "nuhfhvcidsve48c4@kungsbacka.se") { seo.avd = "00"; seo.auth = "09"; } // Emily Clarkson

                if(uid == "b6szbmu85ha3kzda@kungsbacka.se") { seo.avd = "04"; seo.auth = "59"; } // Sandra Axén
                if(uid == "87xhfyb7ucesi6zr@kungsbacka.se") { seo.avd = "04"; seo.auth = "59"; } // Malin Lindqvist
                if(uid == "i752gfikb2vfaje9@kungsbacka.se") { seo.avd = "01"; seo.auth = "59"; } // Gunilla Östling
                if(uid == "9s43ggcnn2jec85p@kungsbacka.se") { seo.avd = "03"; seo.auth = "59"; } // Camilla Soneson
                if(uid == "t6qzyrc3r2v4fhn4@kungsbacka.se") { seo.avd = "03"; seo.auth = "59"; } // Kenny Soneson
                if(uid == "jmt9rdz84xpv6ejr@kungsbacka.se") { seo.avd = "03"; seo.auth = "59"; } // Charlotte Åkerlund
                if(uid == "at3vfkajctyb36n8@kungsbacka.se") { seo.avd = "02"; seo.auth = "59"; } // Karin Lindoff
            }

            if(true) {  //  GYMNASIUM

                if(uid == "ee55x54udxvanmu5@kungsbacka.se") { seo.avd = "00"; seo.auth = "09"; } // Marcus Karlsson

                if(uid == "gtzacjaaarcypff2@kungsbacka.se") { seo.avd = "02"; seo.auth = "59"; } // Marie Adielsson
                if(uid == "fapjbekmc5jxjxq5@kungsbacka.se") { seo.avd = "01"; seo.auth = "59"; } // Karin Christensson
            }
            */

            //alert(JSON.stringify(seo));
            self.props.store.seo = seo;
            //var xdo = JSON.stringify(seo);
            //alert(JSON.stringify(xdo));
            //sessionStorage.setItem("svp", xdo);
            var xso = {};
            xso.cfg = {};
            xso.seo = seo;
            xso.sdo = {};
            var xss = JSON.stringify(xso);
            //alert(JSON.stringify(xss));
            sessionStorage.setItem("svp_sso", xss);

            //var gdo = JSON.stringify(ret.gdo);
            //sessionStorage.setItem("gdo", gdo);

            var url = seo.auto;
            //if(url == "casino.ov") url = "cas.ov";
            //url = "/" + url.replace(/\./g,"/");
            if(true) url = "/tmpl";

            self.props.navigate(url);
            self.props.navigate(0);
        })
        .catch(function(e){
            //var txt = JSON.stringify(e);
            alert("LOGIN ERR: " + JSON.stringify(e));
            //gda.wndError(txt);
        });
    }
    register() {
        var self = this;
        //alert(JSON.stringify(fkn));
        var url = "/kbareg";
        self.props.navigate(url);
    }
    sel_fkn(sel) {
        var self = this;
        //alert(JSON.stringify(fkn));
        if(sel.fkn == "logout") {
            var url = "/logout";
            self.props.navigate(url);
        }
        if(sel.fkn == "sys") {
            var self = this;
            var url = "/bo";
            self.props.navigate(url);
        }
    }

    render() {
        var stat = [];
        var keys = Object.keys(this.state.stat);
        for (var key of keys) {
            var row = this.state.stat[key];
            stat.push(row);
        }
        const handleDateChange = date => {
            this.setState({seldate: date });
        };

        return (
        <div className="web-app">

            <div id="header" className={ this.header_class() }>
            <div className="web-top-content d-flex flex-row">
                <div className="px-3 web-top-icon">
                    <FontAwesomeIcon icon="home" size="1x"/>
                </div>
                <div className="hide">
                    <img className="svk-kub" src={ require('./img/logo-kub.png') } />
                </div>
                <div className="web-top-mnu d-none d-md-inline-flex flex-row" onClick={() => this.mnu_sel("m00") }>
                    <div className="item">Kungsbacka Kommun - Inloggning</div>
                </div>
                <div className="flex-fill"></div>
                <div className="px-3 web-top-txt">
                    { this.seo.name }
                </div>
                <div className="px-3 web-top-icon" onClick={() => this.login() }>
                    <FontAwesomeIcon icon="user" size="1x"/>
                </div>
                <div className="">
                    <Dd_Sel items={ this.selitems } cls="web-top-icon" getValue={(e)=> this.sel_fkn(e)} />
                </div>
            </div>
            </div>

    <div className="web-body">


            <div className="">
                <img className="svk-logo" src={ this.logo() } />
            </div>
    </div>
    </div>
        );
    }
}

//WebPub.contextType = SDOContext; // Function
export default withRouter(Sso_Login);