import React, { useState, useEffect, useContext } from 'react';
import {format} from "date-fns";

import * as utils from "../../../assets/utils";
import * as net from "../../../lib/net";
import Dt_Cal from "../../../lib/ui/dt_cal";

import Saldo_Dt from "./saldo/saldo_dt";
import Saldo_Gst from "./saldo/saldo_gst";
import Saldo_Rp from "./saldo/saldo_rp";
import Pms_Nota from "../../op/nota/nota";
import Wnd_Error from "../../../lib/ui/wnd_error";
import Wnd_Ok from "../../../lib/ui/wnd_ok";
import Wnd_Info from "../../../lib/ui/wnd_info";
import Wnd_Confirm from "../../../lib/ui/wnd_confirm";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Eko_Saldo extends React.Component {
  constructor(props, context) {
    super(props);

    this.dt_fd = React.createRef();
    this.dt_td = React.createRef();

    this.store = props.store;
    this.pms = props.store.pms;

    this.state = { pgid: 11, dta: [], rva: [] };
    this.state.fdat = new Date();
    this.state.tdat = new Date();
    this.state.unit = null;
      this.state.msg = {};
      this.state.wnds = {};
    this.state.sortid = "";

    let cdat = new Date();
    let formatted_date = cdat.getFullYear() + "-" + (cdat.getMonth() + 1) + "-" + cdat.getDate();
    this.state.today = formatted_date;
    this.state.fdatum = formatted_date;
    this.state.tdatum = formatted_date;
    this.state.gsa = [];
    this.state.tot = {};
    if(this.pms.base) {
        this.state.fdat = this.pms.base.fdat;
        this.state.tdat = this.pms.base.tdat;
    }
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.trans();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      css.overflow = "auto";
      return css;
  }
  th_style(id) {
      var css = {};
      css.cursor = "pointer";
      if(id == this.state.sortid) css.color = "#080";
      return css;
  }
  dt_fdat(e) {
    //alert(JSON.stringify(e));
    var dts = format(e, "yyyy-MM-dd");
    this.setState({ fdat: dts });
  }
  dt_tdat(e) {
      //alert(JSON.stringify(e));
    var dts = format(e, "yyyy-MM-dd");
    this.setState({ tdat: dts });
  }
  oneday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_td.current.updateDt(this.state.fdat);
  }
  addday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_fd.current.updateAdd();
    this.dt_td.current.updateAdd();
  }
  subday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_fd.current.updateSub();
    this.dt_td.current.updateSub();
  }

  dt_saldo() {
    var self = this;
    //var datum = format(this.state.datum, "yyyy-MM-dd");
    var msg = { info: true, text: "Hämtar saldo ..."};
    self.setState({gsa: [], tot: {}, msg: msg });

    var prm = {};
    prm.req = "pms.eko_red.red_saldo";
    prm.fdat = this.state.fdat;
    prm.tdat = this.state.tdat;
    //alert(JSON.stringify(prm));

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.ok));

        if(ret.ok != "000") {
            return;
        }
        var gsa = ret.sdo.gsa;
        var tot = ret.tot;
        self.setState({gsa: gsa, tot: tot, msg: {} }, function () {
            self.syncTb("saldoTable");
        });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
    }

  saldo_dt() {
      var wnds = {saldo: true, datum: this.state.datum};
      this.setState({wnds: wnds});
  };

  saldo_per() {
    var self = this;
    //var fdatum = format(this.state.fdat, "yyyy-MM-dd");
    //var tdatum = format(this.state.tdat, "yyyy-MM-dd");

    var prm = {};
    prm.req = "pms.eko_red.red_eko_saldo";
    prm.fdat = this.state.fdat;
    prm.tdat = this.state.tdat;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            return;
        }
        var dta = ret.dta;
        var rva = ret.rva;
        self.setState({ dta: dta, rva: rva }, function () {
            self.syncTb("saldoTable");
        });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
    }
  val_pris(val) {
      var s = "";
      if(val == 0) s = "0.00";
      if(val) s = val.cdipris();
      return s;
  };
    syncTb(tb){
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
        tdElements = tbel.getElementsByTagName('td');

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
            width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
   tb_sort(typ) {
      var self = this;
      //var rvs = self.state.rvs;
      var gsa = self.state.gsa;
      var sz = this.state.sz_val;

      gsa.sort((a,b) => (a[typ] > b[typ]) ? 1 : ((b[typ] > a[typ]) ? -1 : 0));
      self.setState({ gsa: gsa, sortid: typ });
   }
  tb_filtr() {
      var self = this;
      var rvs = self.state.rvs;
      var sz = this.state.sz_val;

      if(sz.length < 1) {
        self.setState({ rva: rvs });
          return;
      }
      sz = sz.toUpperCase();
      var rva = rvs.filter(function(row) {
            var brc = false;
            if(row.company.toUpperCase().includes(sz)) brc = true;
            if(row.name.toUpperCase().includes(sz)) brc = true;
            return brc;
      });
      self.setState({rva: rva });
   }
  xgst_open(row) {
    var self = this;
    var prm = {};
    prm.req = "pms.br.br_gst";
    prm.boknr = row.boknr;
    prm.glopnr = row.glopnr;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        var rvo = {};
        rvo.boknr = row.boknr;
        rvo.gsb00 = ret.rco;
        self.gst_nota(rvo);
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  gst_nota(rvo) {
    //alert(JSON.stringify(row));
    var wnds = {};
    wnds.wnd_nota = true;
    wnds.boknr = rvo.boknr;
    wnds.rvo = rvo;
    this.setState({wnds: wnds});
  }
  gst_open(row) {
    //alert(JSON.stringify(row));
    var wnds = {};
    wnds.saldogst = true;
    wnds.boknr = row.boknr;
    wnds.glopnr = row.lopnr;
    this.setState({wnds: wnds});
  }
  rp_saldo() {
    //alert(JSON.stringify(row));
    var wnds = {};
    wnds.saldorp = true;
    wnds.fdat = this.state.fdat;
    wnds.tdat = this.state.tdat;
    this.setState({wnds: wnds});
  }

  render() {
    return (
    <div className="web-app">
    <div className="">
        SALDON
    </div>

    <div className="flex-row flex-space-between">
        <div className="flex-row">
              <div className="dt-btn-01" onClick={() => this.subday() } ><FontAwesomeIcon icon="minus" size="1x" /></div>
              <Dt_Cal dts={ this.state.fdat } rp="1" ref={this.dt_fd} getValue={(e)=> this.dt_fdat(e)} />
              <div className="dt-btn-01" onClick={() => this.oneday() } >1</div>
              <Dt_Cal dts={ this.state.tdat } rp="1" ref={this.dt_td} getValue={(e)=> this.dt_tdat(e)} />
              <div className="dt-btn-01" onClick={() => this.addday() } ><FontAwesomeIcon icon="plus" size="1x" /></div>
        </div>
        <div className="flex"></div>
        <button className="btn btn-hw-blue w150" onClick={() => this.rp_saldo() } aria-label="System" >
            Saldolista
        </button>
        <button className="btn btn-hw-blue w150" onClick={() => this.dt_saldo() } aria-label="System" >
            Visa
        </button>
    </div>
    <div className="divider"></div>

        <div id="saldoTable">
          <table className="table table-bordered table-hover" style={{marginBottom: "0px"}}>
            <thead>
            <tr>
                <th style={ this.th_style("ankdat") } onClick={() => this.tb_sort("ankdat") }>Från - Till</th>
                <th style={ this.th_style("boknr") } onClick={() => this.tb_sort("boknr") }>Boknr</th>
                <th style={ this.th_style("rumsnr") } onClick={() => this.tb_sort("rumsnr") }>Rumsnr</th>
                <th style={ this.th_style("typ") } onClick={() => this.tb_sort("typ") }>Rumstyp</th>
                <th style={ this.th_style("name") } onClick={() => this.tb_sort("name") }>Namn</th>
                <th style={ this.th_style("nrg") } onClick={() => this.tb_sort("nrg") }>Gäster</th>
                <th style={{textAlign: "left"}}>Fsg</th>
                <th style={{textAlign: "left"}}>Bet</th>
                <th style={{textAlign: "left"}}>Post</th>
                <th style={{textAlign: "left"}}>Saldo</th>
                <th style={{textAlign: "left"}}>Krubba</th>
            </tr>
            </thead>
            </table>
            <div style={ this.tb_style() }>
          <table className="table table-bordered table-hover">
            <tbody>
            { this.state.gsa.map((row, key) =>
            <tr key={key} onDoubleClick={() => this.gst_open(row) }>
                <td>{ utils.toDate( row.ankdat ) } - { utils.toDate( row.avrdat ) }</td>
                <td>{ row.boknr }/{ row.lopnr }</td>
                <td>{ utils.toRumsnr( row.rumsnr ) }</td>
                <td>{ row.typ }</td>
                <td>{ utils.toLang( row.name ) }</td>
                <td>{ row.nrg }</td>
                <td>{ utils.toPris( row.charges.fsg ) }</td>
                <td>{ utils.toPris( row.charges.bet ) }</td>
                <td>{ utils.toPris( row.charges.post ) }</td>
                <td>{ utils.toPris( row.charges.saldo ) }</td>
                <td>{ utils.toPris( row.charges.krubba ) }</td>
            </tr>
            )}
            </tbody>
        </table>
        </div>
    </div>
     <div className="flex-row">
         <div className="flex">TOTALER:</div>
         <div className="px-5">FSG: { utils.toPris(this.state.tot.fsg) }</div>
         <div className="px-5">BET: { utils.toPris(this.state.tot.bet) }</div>
         <div className="px-5">SALDO: { utils.toPris(this.state.tot.saldo) }</div>
         <div className="px-5">KRUBBA: { utils.toPris(this.state.tot.krubba) }</div>
     </div>

        { this.state.wnds.saldo ? <Saldo_Dt wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.wnds.saldogst ? <Saldo_Gst wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.wnds.saldorp ? <Saldo_Rp wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
            { this.state.wnds.wnd_nota ?
        <Pms_Nota wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
            : null }

        <Wnd_Error msg={this.state.msg} ctx={this} />
        <Wnd_Ok msg={this.state.msg} ctx={this} />
        <Wnd_Info msg={this.state.msg} ctx={this} />
        <Wnd_Confirm msg={this.state.msg} ctx={this} />

    </div>
  );
}
}
export default Eko_Saldo;

/*
        <table className="table table-bordered table-hover tb fixed_header">
            <thead>
            <tr>
                <th>Datum</th>
                <th>Fsg</th>
                <th>Bet</th>
                <th>Krubba In</th>
                <th>Krubba Diff</th>
                <th>Krubba Ut</th>
                <th>Saldo</th>
            </tr>
            </thead>
            <tbody style={ this.tb_style() }>
            { this.state.dta.map((row, key) =>
            <tr key={key} onDoubleClick={() => this.trans_open(row) }>
                <td>{ row.datum }</td>
                <td>{ this.val_pris(row.fsg) }</td>
                <td>{ this.val_pris(row.bet) }</td>
                <td>{ this.val_pris(row.kin) }</td>
                <td>{ this.val_pris(row.kdiff) }</td>
                <td>{ this.val_pris(row.kut) }</td>
                <td>{ this.val_pris(row.saldo) }</td>
            </tr>
            )}
            </tbody>
        </table>

*/