import React, { useState, useEffect, useContext } from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {withRouter} from "../lib/react/withRouter";
import { observer } from 'mobx-react'

import SDOContext from '../sdo.js'
import ddo from '../ddo.js'

import Pos_Top from './com/pos_top.js';
import Pos_Mnu from './com/pos_mnu.js';
import Pos_Foot from './com/pos_foot.js';
import Std_Top from './com/std_top.js';
import Std_Mnu from './com/std_mnu.js';
import Std_Foot from './com/std_foot.js';

import Ccs_Mnu from './com/ccs_mnu.js';
import Ccs_Foot from './com/ccs_foot.js';

import Pos_Home from './home.js';
import Pos_Dash from './dash.js';
import Pos_Ccs from './ccs.js';
import Pos_Stat from './stat.js';
import Pos_Op from './op.js';
import Pos_Man from './man.js';
import Pos_Eko from './eko.js';
import Pos_Prds from './prds.js';
import Pos_Lager from './lager.js';
import Pos_Bo from './bo.js';
import Pos_Tech from './tech/tech.js';
import Pos_Red from './red/red.js';
import Pos_Reg from './reg/reg.js';
import Pos_Bas from './pos_bas.js';

import * as net from "../lib/net";
import Tiden from "../lib/ui/tiden";
import sso from "../sso";

function web01() {

    var html =
    <div className="web-content">
    </div>
    ;
    return html;
}

class Pos extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pos = props.store.pos;
    this.state = { pgid: 11, mnu_show: true, deps: [] };

    //ddo.cache.avd = "02";
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      //alert(JSON.stringify(sso.seo.auto));
      var self = this;
      if(false) {
          if(sso.seo.auto !== "pos") {
              setTimeout(function(){
                  var url = "/" + sso.seo.auto;
                  //alert(JSON.stringify(url));
                  self.props.navigate(url);
              }, 1000);
              return;
          }
      }
      if(sso.seo.auto === "tpl") {
          setTimeout(function(){
              var url = "/" + sso.seo.auto;
              //alert(JSON.stringify(url));
              self.props.navigate(url);
          }, 1000);
          return;
      }
      var deps = [];
      deps.push( {"id": "01", "text": "DEP X1" } );
      deps.push( {"id": "02", "text": "DEP X2" } );
      deps.push( {"id": "03", "text": "DEP X3" } );

      //this.props.store.seo.cs = "5";
      ///this.props.store.seo.unit = "BSENHET";

      var pos = {};
      pos.deps = deps;
      pos.vgrps = [];
      pos.plus = [];
      this.props.store.pos = pos;
      //alert(JSON.stringify(pos));
      this.pos_init();
  }

  fknGo(url) {
    //var sdo = useContext(SDO);
    //alert(fkn);
    //var fkn = row.fid;
    //setState({ row : row,  ma : [] });
    //ctx.fid = fkn;

    //props.history.push(row.url);
    this.props.navigate(url);
   };

  ddo() {
    //alert("POS" + this.state.pgid );
    //this.setState({ status: 1 });
  }

    pos_init() {
        var self = this;

        ddo.cache.depo = {};
        ddo.cache.deps = [];
        ddo.cache.vgrps = [];
        ddo.cache.mkods = {};

        var req = {};
        req.req = "pos.pos_fkn.pos_init";

        net.sio_req(req)
        .then(function(ret){
            //alert(JSON.stringify(ret));

            if(!self.props.store.pos) self.props.store.pos = {};
            self.props.store.pos.deps = ret.rco.deps;
            self.props.store.pos.vgrps = ret.rco.vgrps;
            if(ret.rco.depo) ddo.cache.depo = ret.rco.depo;
            if(ret.rco.deps) ddo.cache.deps = ret.rco.deps;
            if(ret.rco.vgrps) ddo.cache.vgrps = ret.rco.vgrps;
            if(ret.rco.mkods) ddo.cache.mkods = ret.rco.mkods;

            var avd = Object.keys(ddo.cache.deps).sort()[0];
            if((avd * 1) > (ddo.cache.avd * 1)) {
                ddo.cache.avd = avd;
            }
            ddo.cache.avdtxt = ddo.cache.depo[ddo.cache.avd].text;
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
            //alert(JSON.stringify(txt));
        })
    }

  render() {

    return (
          <Routes>
              <Route path="red/*" element={ <Pos_Red store={this.store} />} />
              <Route path="reg/*" element={ <Pos_Reg store={this.store} />} />
              <Route path="*" element={ <Pos_Bas store={this.store} />} />
          </Routes>
  );
}
}
//Sys.contextType = ADOContext;

export default withRouter(observer(Pos));
