import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../../lib/net";
import Fr_Input from "../../../lib/ui/fr_input";
import Fr_Select from "../../../lib/ui/fr_select";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Tr01_Open extends React.Component {
  constructor(props, context) {
    super(props);

    this.pos = props.store.pos;
    this.state = {};
    this.state.mka = [];
    this.state.sval = [];
    this.state.tval = [];
    this.state.paa = [];
    this.state.uaa = [];
    this.state.laa = [];

    this.state.gnr = "001";
    //this.state.lro = props.lro;
    this.state.ynq = [
                {id: '0', text: 'NEJ'},
                {id: '1', text: 'JA'}
            ];

    this.state.wnd_ok = false;
    this.state.wnd_err = false;

    this.state.cols = {};
    this.state.tr01 = {};
    this.state.tro = {};
    if(props.wnds.tro) {
        this.state.tr01 = props.wnds.tro;
        this.state.tro = JSON.parse(JSON.stringify(props.wnds.tro));
    }
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.btn_grps();
      if(!this.props.wnds.tro) this.tro_init();
  }

    tro_init() {
        var tro = {};
        tro.e0bastyp = "";
        tro.e0boknr = "";
        tro.e0glopnr = "";
        tro.e0datum = "";
        tro.e0lopnr = "";
        tro.e0typ = "";
        tro.e0tid = "";
        tro.e0rdatum = "";
        tro.e0ptyp = "";
        tro.e0text = "";
        tro.e0rumsnr = "";
        tro.e0antal = "";
        tro.e0pris = "";
        tro.e0tkn = "";
        tro.e0moms = "";
        tro.e0paid = "";
        tro.e0sign = "";
        tro.e0updtime = "";
        //alert(JSON.stringify(rso));
        this.setState({ tro: tro });
        return;
    }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }

    formchange (e) {
      const name = e.target.name;
      const value = e.target.value;
      var pbo = this.state.pbo;
      var na = name.split(".");
      pbo[na[0]][na[1]] = value;
      this.setState({pbo: pbo});
      //setState({[name]: value});
    }

    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    wnd_close = () => {
      if(this.props.close) {
          this.props.close(this.props.ctx);
      }
      else this.props.ctx.setState({ wnds: {} });
	};
    handleChange = (event, newValue) => {
        //alert(newValue);
        this.setState({ix: newValue});
      };
    toggle() {
      this.setState({modal: false});
	};

  submitHandler = event => {
    event.preventDefault();
    event.target.className += " was-validated";
  };

  changeHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  val_save(id, val) {
      var tro = this.state.tro;
      tro[id] = val;
        this.setState({ tro: tro });
      return;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  tr_hbok_save() {
    var self = this;

    var tro = this.state.tro;
    tro.e0text = tro.e0text.fromswe();

    var prm = {};
    prm.req = "pms.hbok.tr01_hbok_upd";
    prm.tr01 = this.state.tr01;
    prm.tro = this.state.tro;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));
        if(ret.ok == "000") {
            self.setState({wnd_ok: true });
            setTimeout(function(){
                self.setState({wnd_ok: false });
                  if(self.props.close) {
                      self.props.close(self.props.ctx);
                  }
                  else self.props.ctx.setState({ wnd_rs: false });
                return;
            }, 1000);
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  tr_save() {
    var self = this;

    var tro = this.state.tro;
    tro.e0text = tro.e0text.fromswe();

    var prm = {};
    prm.req = "pms.hbok.tr01_upd";
    prm.tr01 = this.state.tr01;
    prm.tro = this.state.tro;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));
        if(ret.ok == "000") {
            self.setState({wnd_ok: true });
            setTimeout(function(){
                self.setState({wnd_ok: false });
                  if(self.props.close) {
                      self.props.close(self.props.ctx);
                  }
                  else self.props.ctx.setState({ wnd_rs: false });
                return;
            }, 1000);
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  tr_del() {
    var self = this;
    var prm = {};
    prm.req = "pms.tr.tr_del";
    prm.tro = this.state.tro;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));
        if(ret.ok == "000") {
            self.setState({wnd_ok: true });
            setTimeout(function(){
                self.setState({wnd_ok: false });
                  if(self.props.close) {
                      self.props.close(self.props.ctx);
                  }
                  else self.props.ctx.setState({ wnd_rs: false });
                return;
            }, 1000);
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

  render() {
    //if(!this.props.show) return "";
    var title = this.props.title;
    if(!title) title = "Resurs";
    //if(props.kro) alert(props.kro.krr00.kid);
    const popover = {
      position: 'absolute',
      top: '50px',
      zIndex: '2',
    }
    const cover = {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    }
    const okwnd = {
      position: 'absolute',
      width: '35%',
      height: '35%',
      zIndex: '19999',
      top: '35%',
      left: '35%',
        border: "1px solid #ccc",
        borderRadius: "5px",
       backgroundColor: "#015d00",
        backgroundImage: "linear-gradient(314deg, #015d00 0%, #04bf00 74%)"
    }
    const errwnd = {
      position: 'absolute',
      width: '35%',
      height: '35%',
      zIndex: '19999',
      top: '35%',
      left: '35%',
        border: "1px solid #ccc",
        borderRadius: "5px",
        backgroundColor: "#ED213A",  /* fallback for old browsers */
        background: "-webkit-linear-gradient(to right, #93291E, #ED213A)",  /* Chrome 10-25, Safari 5.1-6 */
        backgroundImage: "linear-gradient(to right, #93291E, #ED213A)" /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        //backgroundColor: "#8E0E00",  /* fallback for old browsers */
        //background: "-webkit-linear-gradient(to right, #1F1C18, #8E0E00)",  /* Chrome 10-25, Safari 5.1-6 */
        //backgroundImage: "linear-gradient(to right, #1F1C18, #8E0E00)" /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    }

	var html =
        <div className="mx_base">
        <div className="m2_backdrop" />
        <div className="m2_dlg mx_70 flex-col" style={ this.dlg_style() } draggable="true" >
        <div className="mx_content flex">

        <div className="mx_h01">
            <div>TRANSAKTION: { this.state.tro.e0lopnr.ztrim() }</div>
        </div>
            <div className="flex-row" style={{padding:"5px",height:"35px"}}>
                    <div className="flex-col flex">
                    </div>
                    <div className="flex-col">
                        <div>UPPDATERA</div>
                    </div>
            </div>
            <div className="divider_orange"></div>

            <form className="cdi_f03">
            <div className="flex-row">
                    <Fr_Input label="Datum" className="text-left w150" text={this.state.tro.e0datum} getValue={(e)=> this.val_save("e0datum", e) } />
                    <Fr_Input label="Tid" className="text-left w150" text={this.state.tro.e0tid} getValue={(e)=> this.val_save("e0tid", e) } />
            </div>
            <div className="flex-row">
                    <Fr_Input label="Boknr" className="text-left w150" text={this.state.tro.e0boknr} getValue={(e)=> this.val_save("e0boknr", e) } />
                    <Fr_Input label="Glopnr" className="text-left w150" text={this.state.tro.e0glopnr} getValue={(e)=> this.val_save("e0glopnr", e) } />
            </div>
            <div className="flex-row">
                    <Fr_Input label="Typ" className="text-left w150" text={this.state.tro.e0typ} getValue={(e)=> this.val_save("e0typ", e) } />
                    <Fr_Input label="Bastyp" className="text-left w150" text={this.state.tro.e0bastyp} getValue={(e)=> this.val_save("e0bastyp", e) } />
            </div>
            <div className="divider_green"></div>
            <div className="flex-row">
                    <Fr_Input label="RDatum" className="text-left w150" text={this.state.tro.e0rdatum} getValue={(e)=> this.val_save("e0rdatum", e) } />
                    <Fr_Input label="Ptyp" className="text-left w150" text={this.state.tro.e0ptyp} getValue={(e)=> this.val_save("e0ptyp", e) } />
            </div>
            <div className="flex-row">
                    <Fr_Input label="Text" className="text-left w350" text={this.state.tro.e0text} getValue={(e)=> this.val_save("e0text", e) } />
            </div>
            <div className="flex-row">
                    <Fr_Input label="Antal" className="text-left w250" text={this.state.tro.e0antal} getValue={(e)=> this.val_save("e0antal", e) } />
                    <Fr_Input label="Tecken" className="text-left w250" text={this.state.tro.e0tkn} getValue={(e)=> this.val_save("e0tkn", e) } />
            </div>
            <div className="flex-row">
                    <Fr_Input label="Pris" className="text-left w250" text={this.state.tro.e0pris} getValue={(e)=> this.val_save("e0pris", e) } />
                    <Fr_Input label="Moms" className="text-left w250" text={this.state.tro.e0moms} getValue={(e)=> this.val_save("e0moms", e) } />
            </div>

            <div className="divider_green"></div>
            <div className="flex-row">
                    <Fr_Input label="Rumsnr" className="text-left w150" text={this.state.tro.e0rumsnr} getValue={(e)=> this.val_save("e0rumsnr", e) } />
            </div>
            <div className="flex-row">
                    <Fr_Input label="Betald" className="text-left w250" text={this.state.tro.e0paid} getValue={(e)=> this.val_save("e0paid", e) } />
                    <Fr_Input label="Sign" className="text-left w250" text={this.state.tro.e0sign} getValue={(e)=> this.val_save("e0sign", e) } />
            </div>

            </form>

        </div>
        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex"></div>
            <button className="btn btn-hw-red w250" onClick={() => this.tr_hbok_save() } >
                SPARA HBOK TYP/TEXT
            </button>
            <button className="btn btn-hw-red w250" onClick={() => this.tr_save() } >
                SPARA
            </button>
        </div>
      </div>

        { this.state.wnd_ok ?
            <div style={ okwnd } className="d-flex flex-column align-content-center justify-content-center text-center" >
                    <h2 className="" style={{color: "#fff"}}>SPARAT</h2>
                    <FontAwesomeIcon className="" icon="check" size="4x" style={{color: "#fff"}} />
            </div>
            : null }
            { this.state.wnd_err ?
                <div style={ errwnd } className="d-flex flex-column align-content-center justify-content-center text-center" >
                        <h2 className="" style={{color: "#fff"}}>FEL</h2>
                        <FontAwesomeIcon className="" icon="exclamation" size="4x" style={{color: "#fff"}} />
                </div>
                : null }
      </div>
	;
	return html;
  };
}

export default Tr01_Open;