import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../../lib/net";
import Btn_Open from "./btn_open";
import ddo from "../../../ddo";

class Btn_Term extends React.Component {
  constructor(props, context) {
    super(props);

    this.pos = props.store.pos;
    if(!this.pos.plus) this.pos.plus = [];

    this.state = {};
    this.state.paa = this.pos.plus;
    this.state.ynq = [];
    //this.state.vga = [];
    this.state.mka = [];
    this.state.sval = [];
    this.state.tval = [];
    this.state.uaa = [];
    this.state.laa = [];

      this.state.avd = ddo.cache.avd;
    this.state.gnr = "001";
    this.state.sel_btn = {};
    this.state.gbo = {};
    //this.state.lro = props.lro;
    this.state.aa = [];
    this.state.vga = [
                {v0katnr: '001', v0text: 'VGRP 001'},
                {v0katnr: '002', v0text: 'VGRP 002'},
                {v0katnr: '009', v0text: 'VGRP 009'}
            ];
    this.state.bgo = {};
    this.state.bgo[0] = [
                {vgrupp: '001', namn: 'VGRP 001'},
                {vgrupp: '002', namn: 'VGRP 002'},
                {vgrupp: '003', namn: 'VGRP 003'},
                {vgrupp: '004', namn: 'VGRP 004'},
                {vgrupp: '005', namn: 'VGRP 005'},
                {vgrupp: '006', namn: 'VGRP 006'}
            ];
    this.state.bgo[1] = [
                {vgrupp: '007', namn: 'VGRP 007'},
                {vgrupp: '008', namn: 'VGRP 008'},
                {vgrupp: '009', namn: 'VGRP 009'},
                {vgrupp: '010', namn: 'VGRP 010'},
                {vgrupp: '011', namn: 'VGRP 011'},
                {vgrupp: '012', namn: 'VGRP 012'}
            ];
    this.state.bto = {};
    this.state.bto[0] = [
                {f0knapp: '01', text: 'BTN 01'},
                {f0knapp: '02', text: 'BTN 02'},
                {f0knapp: '03', text: 'BTN 03'},
                {f0knapp: '04', text: 'BTN 04'},
                {f0knapp: '05', text: 'BTN 05'},
                {f0knapp: '06', text: 'BTN 06'}
            ];
    this.state.bto[1] = [
                {f0knapp: '07', text: 'BTN 07'},
                {f0knapp: '08', text: 'BTN 08'},
                {f0knapp: '09', text: 'BTN 09'},
                {f0knapp: '10', text: 'BTN 10'},
                {f0knapp: '11', text: 'BTN 11'},
                {f0knapp: '12', text: 'BTN 12'}
            ];
    this.state.bto[2] = [
                {f0knapp: '13', text: 'BTN 13'},
                {f0knapp: '14', text: 'BTN 14'},
                {f0knapp: '15', text: 'BTN 15'},
                {f0knapp: '16', text: 'BTN 16'},
                {f0knapp: '17', text: 'BTN 17'},
                {f0knapp: '18', text: 'BTN 18'}
            ];
    this.state.bto[3] = [
                {f0knapp: '19', text: 'BTN 19'},
                {f0knapp: '20', text: 'BTN 20'},
                {f0knapp: '21', text: 'BTN 21'},
                {f0knapp: '22', text: 'BTN 22'},
                {f0knapp: '23', text: 'BTN 23'},
                {f0knapp: '24', text: 'BTN 24'}
            ];
    this.state.bto[4] = [
                {f0knapp: '25', text: 'BTN 25'},
                {f0knapp: '26', text: 'BTN 26'},
                {f0knapp: '27', text: 'BTN 27'},
                {f0knapp: '28', text: 'BTN 28'},
                {f0knapp: '29', text: 'BTN 29'},
                {f0knapp: '30', text: 'BTN 30'}
            ];
    this.state.cols = {};
    this.state.lrwrk = false;
    //this.state.pbo = {};
    //this.state.pbo = props.pbo;
    this.state.ix = 0;
    this.state.modal = 0;
    this.state.v01 = "";
    this.state.v02 = "";
    this.state.v03 = "";
    this.state.v04 = "";
    //context.posrend = this;
      //alert('PBO CTX: ' + JSON.stringify(props.pbo));
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.btn_grps();
    this.btn_btns();
    if(!this.pos.plus.length > 0) this.plus();

  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    tab_style(tabid) {
      var css = {};
      css.display = "none";
      if(this.state.tabval === tabid) css.display = "block";
      return css;
    }
    tab_head(tabid) {
      var css = {};
      css.background = "#fff";
      css.color = "#000";
      if(this.state.tabval === tabid) {
          css.background = "#080";
          css.color = "#fff";
      }
      return css;
    }
    grp_style(row) {
      var css = {};
      css.background = "#008";
      if(this.state.gnr === row.vgrupp) css.background = "#080";
      return css;
    }

    formchange (e) {
      const name = e.target.name;
      const value = e.target.value;
      var pbo = this.state.pbo;
      var na = name.split(".");
      pbo[na[0]][na[1]] = value;
      this.setState({pbo: pbo});
      //setState({[name]: value});
    }

    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    handleClose = () => {
		this.props.ctx.setState({ wnds: {} });
	};
    handleChange = (event, newValue) => {
        //alert(newValue);
        this.setState({ix: newValue});
      };
    toggle() {
      this.setState({modal: true});
	};
    grp_list() {
      this.setState({grplist: true});
    }
    btn_list() {
      this.setState({btnlist: false});
    }

    dragcss() {
        var css = {};
        css["-khtml-user-drag"] = "element";
        css["-webkit-user-drag"] = "element";
        css["-webkit-user-select"] = "none";
        css["-khtml-user-select"] = "none";
        return css;
    }
    drag_start(event, row) {
        //event.preventDefault();
        var text = "XX";
        if(row) {
            text = row.plu00.p0namn.toswe();
        }

        var dragImage = document.createElement('div');
        dragImage.id = 'drag-image';
        dragImage.classList.add('btn_drag');
        dragImage.innerHTML = "<div>"+ text + "</div><div>R2</div>";
        this.dragImage = dragImage;
        document.body.appendChild( dragImage );
        event.dataTransfer.setDragImage(dragImage, 62, 32);
        this.setState({dragrow: row});
    }
    drag_btn(event, btn) {
        //event.preventDefault();
        var knapp = "";
        var text = "";
        if(btn) {
            knapp = btn.f0knapp;
            text = btn.f0text.toswe();
        }

        var dragImage = document.createElement('div');
        dragImage.id = 'drag-image';
        dragImage.classList.add('btn_drag');
        dragImage.innerHTML = "<div>"+ text + "</div><div>"+ knapp + "</div>";
        this.dragImage = dragImage;
        document.body.appendChild( dragImage );
        event.dataTransfer.setDragImage(dragImage, 62, 32);
        this.setState({dragbtn: btn});
    }
    drag_end(event) {
        this.dragImage.remove();
    };
    drag(event) {
        //event.preventDefault();
        //alert("Drag");
        //event.dataTransfer.setData("text", event.target.id);
/*        var crt = this.cloneNode(true);
        crt.style.backgroundColor = "red";
        crt.style.position = "absolute"; crt.style.top = "0px"; crt.style.right = "0px";
        //document.body.appendChild(crt);
        event.dataTransfer.setDragImage(crt, 0, 0);
*/
    };
    drop(event) {
        event.preventDefault();
        //alert("Drop");
        var text = "XX";
        if(this.state.dragrow) {
            text = this.state.dragrow.plu00.p0namn.toswe();
        }
      this.setState({v03: text, modal: true});
	};
    dragEnter(event) {
        event.preventDefault();
        event.target.style.color = "#fff";
        event.target.style.background = "#ccc";
        //alert("Allow Drop");
	};
    dragLeave(event) {
        event.preventDefault();
        event.target.style.color = "#fff";
        event.target.style.background = "#080";
        //alert("Allow Drop");
	};
    hoverEnter(event) {
        event.preventDefault();
        event.target.style.color = "#fff";
        event.target.style.background = "#ccc";
        //alert("Allow Drop");
	};
    hoverLeave(event) {
        event.preventDefault();
        event.target.style.color = "#fff";
        event.target.style.background = "#080";
        //alert("Allow Drop");
	};
  submitHandler = event => {
    event.preventDefault();
    event.target.className += " was-validated";
  };

  changeHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  };


  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  cssCols (cid) {
      var css = {};
      if(this.state.cols[cid]) css.width = this.state.cols[cid].toString() + "px";
      //if(cid == "c01") alert(JSON.stringify(this.state.cols[cid]));
      return css;
  }
  syncCols (tb) {

    if (!document.getElementById(tb)) return;

    var cols = {};
    var ix = document.getElementById(tb).rows.length - 1;
    var ic = document.getElementById(tb).rows[0].cells.length;
    var ci = 0;
    while(ci < ic) {
        var cxi = ci + 1;
        var col = "c" + cxi.toString().padStart(2, "0");
        cols[col] = document.getElementById(tb).rows[ix].cells[ci].clientWidth + 1;
        ci = ci + 1;
    }
    this.setState({cols: cols });
    //alert(JSON.stringify(this.state.cols));
    //this.forceUpdate();
  };
  plus() {
    var self = this;
    var prm = {};
    prm.req = "pos.pos_plu.plus";
    prm.avd = this.state.avd;
    //prm.fplu = datum;
    //prm.tplu = datum;

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        self.props.store.pos.plus = ret.rco;
        self.setState({paa: ret.rco });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }
  btn_grps() {
    var self = this;
    var prm = {};
    prm.req = "pos.pos_btns.famgrps";
    prm.avd = this.state.avd;
    //prm.fplu = datum;
    //prm.tplu = datum;

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        var bga = ret.rco;
        //alert(JSON.stringify(bga.length));
        var o = {};
        o[0] = bga.slice(0, 6);
        o[1] = bga.slice(6, 12);
        self.setState({bgo: o });
        //alert(JSON.stringify(o[0].length));
        //alert(JSON.stringify(o[1].length));
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }
  set_grp(row) {
    var self = this;
    //self.setState({gnr: row.vgrupp });
    self.show_btns(row.vgrupp);
  }
  show_grps(gbo) {
    var self = this;
    //var gbo = self.state.gbo;

    var bka = Object.keys(gbo);
    bka.sort();
    //alert(JSON.stringify(bga.length));
    //alert(JSON.stringify(bga[0]));
    var bga = [];
    for(var bkey of bka) {
      var o = gbo[bkey];
      var gro = {};
      gro.vgrupp = o.varbuf.vgrupp;
      gro.namn = o.varbuf.namn;
      bga.push( gro );
    };

    var o = {};
    o[0] = bga.slice(0, 6);
    o[1] = bga.slice(6, 12);
    self.setState({bgo: o });
  }
  show_btns(gnr) {
    var self = this;
    var gbo = self.state.gbo;
    //var gnr = self.state.gnr;

    if(!gbo[gnr]) return;

    var bka = Object.keys(gbo[gnr].btns);
    bka.sort();
    var bta = [];
    for(var bkey of bka) {
      var o = gbo[gnr].btns[bkey];
      bta.push( o );
    };
    //alert(JSON.stringify(bta.length));
    //alert(JSON.stringify(bta[0]));
    var o = {};
    o[0] = bta.slice(0, 6);
    o[1] = bta.slice(6, 12);
    o[2] = bta.slice(12, 18);
    o[3] = bta.slice(18, 24);
    o[4] = bta.slice(24, 30);
    self.setState({ gnr: gnr, bto: o });
  }
  btn_btns() {
    var self = this;
    var prm = {};
    prm.req = "pos.pos_btns.btns_yy";
    prm.avd = this.state.avd;
    //prm.fplu = datum;
    //prm.tplu = datum;

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        var gbo = ret.rco;
        self.setState({gbo: gbo });
        self.show_grps(gbo);
        self.show_btns(self.state.gnr);
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }
    btn_open(btn) {
        this.setState({sel_btn: btn, modal: true});
    };

  render() {
    //if(!this.props.show) return "";

    //alert(this.props.ctx.state.lro);

    var title = this.props.title;
    if(!title) title = "Knappar";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="mx_backdrop" /> : null }
        <div className="mx_dlg mx_95 flex-col" style={ this.dlg_style() }>
        <div className="mx_content flex">

            <div className="flex-row" style={{padding:"5px",height:"35px"}}>
                    <div className="flex-col flex">
                        <div>KNAPPAR:</div>
                    </div>
                    <div className="flex-col">
                        <div onClick={() => this.btn_btns() }>UPPDATERA</div>
                    </div>
            </div>
            <div className="divider_orange"></div>

            <div className="flex-row">

            <div className="flex-col flex">
            <div className="">
                <div className="row btn_row">
            { this.state.bgo[0].map((row, key) =>
                <div key={"grp_" + row.vgrupp } className="col btn_grp" style={ this.grp_style(row) } onClick={() => this.set_grp(row) }>{row.vgrupp}-{row.namn}</div>
            )}
                </div>
                <div className="row btn_row">
            { this.state.bgo[1].map((row, key) =>
                <div key={"grp_" + row.vgrupp } className="col btn_grp" style={ this.grp_style(row) } onClick={() => this.set_grp(row) }>{row.vgrupp}-{row.namn}</div>
            )}
                </div>
            </div>
            <div className="divider_orange"></div>

            <div className="">
            { Object.values(this.state.bto).map((row, key) =>
                <div key={key} className="row btn_row">
                { row.map((btn, btnkey) =>
                    <div key={"btn_" + btn.f0knapp } draggable={true}
                         onDragStart={ (event) => this.drag_btn(event, btn)}
                         onDragEnd={ (event) => this.drag_end(event)}
                         onDrag={ (event) => this.drag(event)}
                         onDragOver={ (event) => this.dragEnter(event) }
                         onDragLeave={ (event) => this.dragLeave(event) }
                         onDrop={ (event) => this.drop(event)}
                         onMouseEnter={ (event) => this.hoverEnter(event) }
                         onMouseLeave={ (event) => this.hoverLeave(event) }
                         onDoubleClick={() => this.btn_open(btn) }
                         className="col btn_pos">
                        { btn.f0knapp } - { btn.f0text }
                    </div>
                )}
                </div>
            )}
            </div>
            </div>

           </div>
        </div>

        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250 ripple" onClick={() => this.handleClose() } >
                STÄNG
            </button>
            <div className="flex-row hide">
            </div>
            <button className="btn btn-hw-green w250 ripple" onClick={(event) => this.handleClose(event) } >
                STÄNG
            </button>
        </div>
      </div>


    { this.state.modal ?
        <Btn_Open show={this.state.modal} bto={this.state.sel_btn} ctx={this} store={this.props.store} backdrop={true}/>
    : null }
    </div>
	;
	return html;
  };
}

export default Btn_Term;
