import React, { useState, useEffect, useContext } from 'react';
import {Route, Routes} from "react-router-dom";

import * as utils from "../assets/utils";
import Td_Sel from "../lib/ui/td_sel";
import * as net from "../lib/net";
import {format, getHours, subDays} from "date-fns";
import {withRouter} from "../lib/react/withRouter";

import EE_Sel from "./ta/fkn/ee_sel";
import EE_In from "./ta/fkn/ee_in";
import EE_Ut from "./ta/fkn/ee_ut";

function pg_home() {

    var html =
    <div className="web-content">
      Hem
    </div>
    ;
    return html;
}

class Xt_Ta extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, deps: [] };

      this.state.tha = [];
      this.state.wnds = {};
      this.state.tot = {};
      this.state.today = new Date();
      var dts = format(this.state.today, "yyyy-MM-dd");
      this.state.tdat = dts;
      var hh = getHours(this.state.today);
      if( hh < 5) {
          const dt = subDays(new Date(), 1);
          dts = format(dt, "yyyy-MM-dd");
      }
      this.state.fdat = dts;
  }
  fknGo(row) {
    //alert(row.href);
    this.props.navigate(row.href);
  }
    body_style() {
        var css = {};
        css.height = window.innerHeight - 280;
        return css;
    }
    tb_style() {
        var css = {};
        css.height = window.innerHeight - 175;
        css.overflow = "auto";
        return css;
    }
    th_style(id) {
        var css = {};
        css.cursor = "pointer";
        if(id == this.state.sortid) {
            if(this.state.sortdir) css.color = "#800";
            else css.color = "#080";
        }
        return css;
    }
    act() {
        var self = this;
        var prm = {};
        prm.req = "ta.ta_th.act_dt";
        prm.datum = this.state.fdat;
        prm.fdat = this.state.fdat;
        prm.tdat = this.state.tdat;
        prm.avd = this.state.avd;
        //alert(JSON.stringify(prm));

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.rca));

                self.setState({tha: ret.rca }, function() {
                    self.syncTb("actTable");
                });
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    syncTb(tb) {
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
            tdElements = tbel.getElementsByTagName('td');
        if(tdElements.length < 1 ) return;

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
                width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
    tb_sort(typ) {
        var self = this;
        //var rvs = self.state.rvs;
        var tha = self.state.tha;
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;

        if(sortid != typ) sortdir = 0;
        else {
            if(sortdir) sortdir = 0;
            else sortdir = 1;
        }

        if(typ) {
            if(sortdir) tha.sort((a,b) => (a[typ] < b[typ]) ? 1 : ((b[typ] < a[typ]) ? -1 : 0));
            else tha.sort((a,b) => (a[typ] > b[typ]) ? 1 : ((b[typ] > a[typ]) ? -1 : 0));
        }
        self.setState({ tha: tha, sortid: typ, sortdir: sortdir });
    }
    th_icon(typ) {
        var cls = "";
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;
        if(sortid == typ)
        {
            if(sortdir) cls = "fa fa-caret-up";
            else cls = "fa fa-caret-down";
        }
        return cls;
    }
    ee_sel() {
        var self = this;
        var wnds = {};
        wnds.eesel = 1;
        wnds.enr = this.state.enr;
        self.setState({ wnds: wnds });
    }
    btn_in() {
        var self = this;
        var wnds = {};
        wnds.eesel = 1;
        wnds.enr = this.state.enr;
        wnds.cb = this.ee_in;
        self.setState({ wnds: wnds });
    }
    ee_in(ctx, rsp) {
      //alert(JSON.stringify(rsp));
        if(rsp.ok == "000") {
            var wnds = {};
            wnds.eein = 1;
            wnds.enr = rsp.enr;
            wnds.cb = ctx.ee_refresh;
            //alert(JSON.stringify(wnds));
            ctx.setState({ wnds: wnds });
        }
    }
    btn_ut() {
        var self = this;
        var wnds = {};
        wnds.eesel = 1;
        wnds.enr = this.state.enr;
        wnds.cb = this.ee_ut;
        self.setState({ wnds: wnds });
    }
    ee_ut(ctx, rsp) {
        if(rsp.ok == "000") {
            var wnds = {};
            wnds.eeut = 1;
            wnds.enr = rsp.enr;
            wnds.cb = ctx.ee_refresh;
            ctx.setState({ wnds: wnds });
        }
    }
    ee_refresh(ctx, rsp) {
    }

  render() {
      var path = window.location.pathname;
      var op = path.split("/").pop();

    return (
    <div className="web-app">
        <div className="flex-row">
            <button className="btn btn-hw-blue w150" onClick={() => this.act() } aria-label="System" >
                Visa
            </button>
            <div className="flex"></div>
            <div className="txt_18">TIDER</div>
            <div className="flex"></div>
            <button className="btn btn-hw-blue w150" onClick={() => this.btn_in() } aria-label="System" >
                IN
            </button>
            <button className="btn btn-hw-blue w150" onClick={() => this.btn_ut() } aria-label="System" >
                UT
            </button>
        </div>
        <div className="divider_blue"></div>

        <div id="actTable" className="web-col">
            <table className="table table-bordered table-hover tb " style={{marginBottom: "0px"}}>
                <thead>
                <tr>
                    <th style={ this.th_style("anstnummer") } onClick={() => this.tb_sort("anstnummer") }>Anstnr <i className={ this.th_icon("anstnummer") }></i></th>
                    <th style={ this.th_style("namn") } onClick={() => this.tb_sort("namn") }>Namn <i className={ this.th_icon("namn") }></i></th>
                    <th style={ this.th_style("avd") } onClick={() => this.tb_sort("avd") }>Avdelning <i className={ this.th_icon("avd") }></i></th>
                    <th style={ this.th_style("start") } onClick={() => this.tb_sort("start") }>Start <i className={ this.th_icon("start") }></i></th>
                    <th style={ this.th_style("slut") } onClick={() => this.tb_sort("slut") }>Slut <i className={ this.th_icon("slut") }></i></th>
                    <th style={ this.th_style("vstart") } onClick={() => this.tb_sort("vstart") }>V-Start <i className={ this.th_icon("vstart") }></i></th>
                    <th style={ this.th_style("vslut") } onClick={() => this.tb_sort("vslut") }>V-Slut <i className={ this.th_icon("vslut") }></i></th>
                    <th style={ this.th_style("netto") } onClick={() => this.tb_sort("netto") }>Nettotid <i className={ this.th_icon("netto") }></i></th>
                    <th style={ this.th_style("status") } onClick={() => this.tb_sort("status") }>Status <i className={ this.th_icon("status") }></i></th>
                    <th></th>
                </tr>
                </thead>
            </table>
            <div className="tb-cdi" style={ this.tb_style() }>
                <table className="table table-bordered table-hover tb">
                    <tbody>
                    { this.state.tha.map((row, key) =>
                        <tr key={key} onDoubleClick={() => this.ee_edit(row) }>
                            <td className="text-center">{ utils.toNum( row.anstnummer ) }</td>
                            <td>{ utils.toLang( row.namn ) }</td>
                            <td className="text-center">{ utils.toLang( row.avd ) }</td>
                            <td className="text-center">{ utils.toTid( row.start ) }</td>
                            <td className="text-center">{ utils.toTid( row.slut ) }</td>
                            <td className="text-center">{ utils.toTid( row.vstart ) }</td>
                            <td className="text-center">{ utils.toTid( row.vslut ) }</td>
                            <td className="text-center">{ utils.toTid( row.netto ) }</td>
                            <td className="text-center">{ row.status }</td>
                            <td>
                                <Td_Sel items={ this.selitems } getValue={(e)=> this.sel_row_fkn(row, e)} />
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
        </div>
        <div className="divider_blue"></div>
        <div className="flex-row">
            <div className="txt_18">TOTAL NETTO: { utils.toMinTid(this.state.tot.netto) }</div>
            <div className="flex"></div>
            <div className="flex"></div>
            <div className="txt_18">TOTAL BRUTTO: { utils.toMinTid(this.state.tot.brutto) }</div>
        </div>
        { this.state.wnds.eesel ? <EE_Sel wnds={this.state.wnds} ctx={this} backdrop={true}/> : null }
        { this.state.wnds.eein ? <EE_In wnds={this.state.wnds} ctx={this} backdrop={true}/> : null }
        { this.state.wnds.eeut ? <EE_Ut wnds={this.state.wnds} ctx={this} backdrop={true}/> : null }
    </div>
  );
}
}
//Sys.contextType = ADOContext;

export default withRouter(Xt_Ta);
/*
        <Routes>
          <Route path="/xx" component={pg_home} />
            <Route path="in" element={ <Ta_In store={this.webStore} />} />
            <Route path="th" element={ <Ta_In store={this.webStore} />} />
            <Route path="*" element={ <Ta_Home store={this.webStore} />} />
        </Routes>
*/
