import React, { useState, useEffect, useContext } from 'react';

import * as utils from "../../../assets/utils";
import * as net from "../../../lib/net";

import Pkat_Open from "./fkn/pkat_open";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Reg_Pkat extends React.Component {
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.state = { pgid: 11, tra: [] };
    this.state.seldate = new Date();
    this.state.avd = "01";

    this.state.tra = [];
    this.state.pka = [];
    this.state.pks = [];
    this.state.pta = [];

    this.state.tro = {};
    this.state.pko = {};
    this.state.aa = [];
    this.state.cols = {};
    this.state.wnds = {};
    this.state.typ = "";
    this.state.sz_val = "";
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.pkat();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 225;
      css.overflow = "auto";
      return css;
  }
  tab_style(typ) {
      var css = {};
      if(this.state.typ == typ) { css.background = "#009900"; css.color = "#fff"; }
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }
  th_style(id) {
      var css = {};
      css.cursor = "pointer";
      if(id == this.state.sortid) css.color = "#080";
      return css;
  }

  pkat_add(row) {
    var self = this;
    var wnds = {};
    wnds.pkatopen = true;
    wnds.pko = null;
    this.setState({ wnds: wnds });
  }
  pkat_edit(row) {
    var self = this;
    var wnds = {};
    wnds.pkatopen = true;
    wnds.pko = row;
    this.setState({ wnds: wnds });
  }
  pkat_close(ctx) {
    ctx.setState({ wnd_pkat: false });
    ctx.pkat();
  }
  pay_edit(row) {
    var self = this;
    var wnds = {};
    wnds.payopen = true;
    wnds.tro = row;
    this.setState({ wnds: wnds });
  }

  pkat() {
    var self = this;
    self.setState({typ: "pkat", tra: [], pka: [], pta: [] });

    var prm = {};
    prm.req = "pms.pkat.qa_pkats";
    prm.typ = "P";
    //prm.avd = this.state.avd;

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        self.setState({ pka: ret.rca, pks: ret.rca }, function () {
            self.syncTb("pkaTable");
        });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e) {
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }
    syncTb(tb) {
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
        tdElements = tbel.getElementsByTagName('td');
        if(tdElements.length < 1 ) return;

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
            width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
   tb_sort(typ) {
      var self = this;
      //var rvs = self.state.rvs;
      var pka = self.state.pka;
      var sz = this.state.sz_val;

      pka.sort((a,b) => (a[typ] > b[typ]) ? 1 : ((b[typ] > a[typ]) ? -1 : 0));
      self.setState({ pka: pka, sortid: typ });
   }
   tb_filtr() {
    var self = this;
    var pks = self.state.pks;
    var sz = this.state.sz_val;

    if(sz.length < 1) {
      self.setState({ pka: pks });
        return;
    }
    sz = sz.toUpperCase();
    var pka = pks.filter(function(row) {
          var brc = false;
          if(row.n0typ.toUpperCase().includes(sz)) brc = true;
          if(row.n0sort.toUpperCase().includes(sz)) brc = true;
          if(row.n0text.toUpperCase().includes(sz)) brc = true;
          return brc;
    });
    self.setState({pka: pka });
 }

  sz_style() {
        var css = {};
        css.height = "40px";
        css.lineHeight = "40px";
        css.padding = "0px 10px";
        css.fontSize = "12px";
        css.cursor = "pointer";
        return css;
    }

    sz_keydown (e) {
    //e.preventDefault();
      const key = e.key;
      const keyCode = e.keyCode;

      if(keyCode == 13) {
          this.tb_filtr();
      };
      if(keyCode == 27) {
          this.setState({sz_val: ""});
      };
  }
  sz_change (e) {
      const name = e.target.name;
      const value = e.target.value;
      this.setState({sz_val: value});
      //setState({[name]: value});
  }

  render() {
    return (
    <div className="web-app">
    <div className="flex-row flex-space-between">
      <input className="txt sztxt" value={this.state.sz_val} placeholder="Ange söktext..."  style={ this.sz_style() } onChange={(e) => this.sz_change(e)} onKeyDown={(e) => this.sz_keydown(e)} />

        <div className="flex-row">
        </div>

        <div className="flex-row flex-end flex">
        <button className="btn btn-hw-orange w150" onClick={() => this.tr_add() } aria-label="System" >
            LÄGG TILL
        </button>
        <button className="btn btn-hw-blue w150" onClick={() => this.syncCols("kidTable") } aria-label="System" >
            UPPDATERA
        </button>
        </div>
    </div>
    <div className="divider_orange"></div>

        <div id="pkaTable" className="flex">
          <table className="table table-bordered table-hover tb" style={{marginBottom: "0px"}}>
            <thead>
            <tr>
                <th style={ this.th_style("ank") } onClick={() => this.tb_sort("ank") }>Typ</th>
                <th style={ this.th_style("ank") } onClick={() => this.tb_sort("ank") }>Till Datum</th>
                <th style={ this.th_style("ank") } onClick={() => this.tb_sort("ank") }>Sortering</th>
                <th style={ this.th_style("ank") } onClick={() => this.tb_sort("ank") }>Text</th>
                <th style={ this.th_style("ank") } onClick={() => this.tb_sort("ank") }>Perpers</th>
                <th style={ this.th_style("ank") } onClick={() => this.tb_sort("ank") }>Status</th>
                <th></th>
            </tr>
            </thead>
        </table>
        <div style={ this.tb_style() }>
          <table className="table table-bordered table-hover tb">
            <tbody>
            { this.state.pka.map((row, key) =>
            <tr key={key} onDoubleClick={() => this.pkat_edit(row) }>
                <td>{ row.n0typ }</td>
                <td>{ row.n0todatum }</td>
                <td>{ row.n0sort }</td>
                <td>{ utils.toLang( row.n0text ) }</td>
                <td>{ row.n0perpers }</td>
                <td>{ row.n0start }</td>
                <td>{ row.status }</td>
                <td style={{width:"40px",cursor:"pointer"}} onClick={() => this.pkat_edit(row) }><i className="fa fa-cog text-danger"></i></td>
            </tr>
            )}
            </tbody>
        </table>
        </div>
    </div>
        { this.state.wnds.pkatopen ? <Pkat_Open wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }

   </div>
  );
    }
}
export default Reg_Pkat;
/*
        { this.state.wnds.tradd ? <Tr_Add wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }

*/