import React, { useState, useEffect, useContext } from 'react';
import {format, addDays, subDays} from "date-fns";

import * as net from "../../../lib/net";
import Fr_Input from "../../../lib/ui/fr_input";

import Ank_GstRooms from "./ank_rooms";

class Ank_Gst_Edit extends React.Component {
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pms = props.store.pms;

    this.state = {};
    this.state.today = new Date();
    this.state.rta = [];
    this.state.pka = [];

    this.state.gnr = "001";
    this.state.gbo = {};
    //this.state.lro = props.lro;
    this.state.ynq = [
                {id: '0', text: 'NEJ'},
                {id: '1', text: 'JA'}
            ];

    this.state.bgo = {};
    this.state.bto = {};
    this.state.background = "#fff";

    this.state.cols = {};
    this.state.lrwrk = false;
    this.state.boknr = {};
    if(props.boknr) this.state.boknr = props.boknr;

    this.state.wnds = {};
    this.state.bro = {};
    this.state.gso = {};
    this.state.gsb00 = {};
    if(props.wnds) {
        if(props.wnds.boknr) this.state.boknr = props.wnds.boknr;
        if(props.wnds.bro) this.state.bro = props.wnds.bro;
        if(props.wnds.gso) this.state.gsb00 = props.wnds.gso;
    }
    this.state.bra = [];
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.gst_init();
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    grp_style(row) {
      var css = {};
      css.background = "#008";
      if(this.state.gnr === row.vgrupp) css.background = "#080";
      return css;
    }

    formchange (e) {
      const name = e.target.name;
      const value = e.target.value;
      var pbo = this.state.pbo;
      var na = name.split(".");
      pbo[na[0]][na[1]] = value;
      this.setState({pbo: pbo});
      //setState({[name]: value});
    }

    wnd_close = () => {
      if(this.props.cb) {
        var ret = {};
        ret.ok = "999";
        this.props.cb(this.props.ctx, ret);
      }
      else this.props.ctx.setState({ wnds: {} });
	};

    rsv_save (name, value) {
        //alert(JSON.stringify(name));
        //alert(JSON.stringify(value));
      var rsv = this.state.bro;
      var na = name.split(".");
      rsv[na[0]][na[1]] = value;
      this.setState({rsv: rsv});
      //setState({[name]: value});
    }
    val_save (name, value) {
        //alert(JSON.stringify(name));
        //alert(JSON.stringify(value));
      var gso = this.state.gso;
      gso[name] = value;
      this.setState({gso: gso});
      //setState({[name]: value});
    }
    cb_save (name, e) {
      const value = e.target.value;
      var bro = this.state.bro;
      bro[name] = value;
      this.setState({bro: bro});
      //setState({[name]: value});
    }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }

  gst_init() {
    var self = this;

    var gso = {};
    gso.fdat = this.state.gsb00.r0ankdatum;
    gso.tdat = this.state.gsb00.r0avrdatum;
    gso.rumsnr = this.state.gsb00.r0rumsnr;
    gso.namn = this.state.gsb00.r0namn;
    gso.nrr = "1";
    gso.nrg = this.state.gsb00.r0guests;
    gso.nrgr = this.state.gsb00.r0guests;
    gso.nrb0 = "0";
    gso.nrb1 = "0";
    gso.nrb2 = "0";
    gso.rt = "";
    gso.pkat = "";
    gso.rab = "";
    this.setState({gso: gso});
  }
  rts() {
    var self = this;
    var prm = {};
    prm.req = "pms.rt.rt_date";
    //prm.datum = this.state.datum;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            return;
        }
        self.setState({rta: ret.rco});
        self.pkats();
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  pkats() {
    var self = this;
    var prm = {};
    prm.req = "pms.pkat.qa_pkats";
    //prm.datum = this.state.datum;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            return;
        }
        self.setState({pka: ret.rco});
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

  gst_save() {
    var self = this;

    var gsb00 = this.state.gsb00;
    gsb00.r0namn = this.state.gso.namn;

    var prm = {};
    prm.req = "pms.br.br_gst_upd";
    prm.boknr = this.state.boknr;
    prm.gsb00 = gsb00;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok == "000") {
		    //self.props.cb(self.props.ctx, ret);
              if(self.props.cb) {
                var ret = {};
                ret.ok = "000";
                self.props.cb(self.props.ctx, ret);
              }
              else self.props.ctx.setState({ wnds: {} });
            return;
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  gst_room(e) {
    e.preventDefault();
    var self = this;
    var wnds = {gstroom: true, boknr: this.state.boknr, bro: this.state.bro};
    this.setState({wnds: wnds});
  }
  cb_gstrooms(ctx, ret) {
      if(ret.ok == "000") {
          //ctx.rv_br();
        var gso = ctx.state.gso;
        gso.rumsnr = ret.rumsnr + "00";
        ctx.setState({ wnds: {}, gso: gso });
      }
      else {
        ctx.setState({ wnds: {} });
      }
  };
  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "Gäst";
    //if(props.kro) alert(props.kro.krr00.kid);
    const popover = {
      position: 'absolute',
      top: '50px',
      zIndex: '2',
    }
    const cover = {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    }

	var html =
        <div className="mx_base">
        <div className="m3_backdrop" />
        <div className="m3_dlg mx_80 flex-col" style={ this.dlg_style() } draggable="true" >

        <div className="mx_h01">
            <div>GÄST: {this.state.gsb00.r0glopnr}</div>
        </div>
        <div className="mx_content flex">

            <div className="divider_orange"></div>

            <div className="txt_box txt_bg_01 txt_22">
            <div className="flex-row">
                <div className="wnd_link">
                    DELBOKNING
                </div>
                <div className="flex"></div>
                <div className="wnd_link" onClick={() => this.br_open() }>
                    ÄNDRA
                </div>
            </div>
            <div className="flex-row py-2">
                <div className="flex-row flex">
                    <div className="rv-lbl">Från:</div>
                    <div className="rv-txt">{this.state.bro.r0ankdatum.cdidate()}</div>
                </div>
                <div className="flex-row flex">
                    <div className="rv-lbl">Till:</div>
                    <div className="rv-txt">{this.state.bro.r0avrdatum.cdidate()}</div>
                </div>
            </div>
            <div className="flex-row py-2">
                <div className="flex-row flex">
                    <div className="rv-lbl">Antal Rum:</div>
                    <div className="rv-txt">{this.state.bro.r0borr}</div>
                </div>
                <div className="flex-row flex">
                    <div className="rv-lbl">Antal Gäster:</div>
                    <div className="rv-txt">{this.state.bro.r0borg}</div>
                </div>
            </div>
            <div className="flex-row py-2">
                <div className="flex-row flex">
                    <div className="rv-lbl">Barn (0-4):</div>
                    <div className="rv-txt">{this.state.bro.r0antb0}</div>
                </div>
                <div className="flex-row flex">
                    <div className="rv-lbl">Barn (5-18):</div>
                    <div className="rv-txt">{this.state.bro.r0antb1}</div>
                </div>
            </div>
            <div className="flex-row py-2">
                <div className="flex-row flex">
                    <div className="rv-lbl">Rumstyp:</div>
                    <div className="rv-txt">{this.state.bro.r0typ}</div>
                </div>
            </div>

            </div>
            <div className="divider_orange"></div>

            <form className="cdi_f03">
            <div className="flex-row py-2">
                    <Fr_Input label="Namn" className="text-left w350" text={this.state.gso.namn} getValue={(e)=> this.val_save("namn", e) } />
                <div className="flex-row">
                    <div className="txt_18 w250">Rumsnr: {this.state.gso.rumsnr}</div>
                    <button className="btn btn-hw-blue w150" onClick={(e) => this.gst_room(e) } >
                        SÖK RUM
                    </button>
                </div>
            </div>
            <div className="flex-row py-2">
                    <Fr_Input label="Antal" className="text-left w150" text={this.state.gso.nrg} getValue={(e)=> this.val_save("nrg", e) } />
            </div>
            <div className="flex-row py-2">
                    <Fr_Input label="Barn (0-4)" className="text-left w250" text={this.state.gso.nrb0} getValue={(e)=> this.val_save("nrb0", e) } />
                    <Fr_Input label="Barn (5-8)" className="text-left w250" text={this.state.gso.nrb1} getValue={(e)=> this.val_save("nrb1", e) } />
            </div>
            <div className="divider_green"></div>
            <div className="flex-row py-2">
                    <Fr_Input label="Företag" className="text-left w350" text={this.state.gso.ftg} getValue={(e)=> this.val_save("ftg", e) } />
            </div>
            <div className="flex-row py-2">
                    <Fr_Input label="Epost" className="text-left w250" text={this.state.gso.email} getValue={(e)=> this.val_save("email", e) } />
                    <Fr_Input label="Pass" className="text-left w250" text={this.state.gso.pass} getValue={(e)=> this.val_save("pass", e) } />
            </div>
            <div className="flex-row py-2">
                    <Fr_Input label="Telefon" className="text-left w250" text={this.state.gso.tele} getValue={(e)=> this.val_save("tele", e) } />
                    <Fr_Input label="Mobil" className="text-left w250" text={this.state.gso.mobile} getValue={(e)=> this.val_save("mobile", e) } />
            </div>
            <div className="divider_green"></div>
            </form>
        </div>

        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                AVBRYT
            </button>
            <div className="flex-row">
            <button className="btn btn-hw-red w250" onClick={() => this.gst_save() } >
                SPARA
            </button>
            </div>
        </div>
      </div>
        { this.state.wnds.gstroom ? <Ank_GstRooms wnds={this.state.wnds} cb={this.cb_gstrooms} ctx={this} store={this.props.store} backdrop={true}/> : null }
      </div>
	;
	return html;
  };
}

export default Ank_Gst_Edit;

/*
    <Fr_Input label="Rumsnr" className="text-left w250" text={this.state.gso.rumsnr} getValue={(e)=> this.val_save("rumsnr", e) } />
*/