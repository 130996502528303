import React, { useState, useEffect, useContext } from 'react';
import * as net from "../../../lib/net";

import * as utils from "../../../assets/utils";
import Fr_Input from "../../../lib/ui/fr_input";

import Pms_Rvo from "../../rv/fb/rvo";
import Pms_Rr from "../../bel/rr/rr";
import Pms_RfGst from "../fkn/rf_gst";
import Pms_CiDone from "../ci/ci_done";
import Pms_Nota from "../nota/nota";

import Wnd_Error from "../../../lib/ui/wnd_error";
import Wnd_Ok from "../../../lib/ui/wnd_ok";
import Wnd_Info from "../../../lib/ui/wnd_info";
import Wnd_Confirm from "../../../lib/ui/wnd_confirm";

import * as q from "../../../lib/defer";

class Pms_CiSel extends React.Component {
  constructor(props, context) {
    super(props);

   this.store = props.store;
    this.state = {};
    this.state.ynq = [];
    this.state.paa = [];
    this.state.aa = [];

    this.state.gstok = false;
    this.state.boknr = "";
    this.state.rvo = {};
    this.state.bro = {};
    this.state.gso = {};
    if(props.wnds) {
        this.state.boknr = props.wnds.boknr;
        this.state.rvo = props.wnds.rvo;
        this.state.gsb00 = props.wnds.rvo.gsb00;
        this.state.bro = this.state.rvo.rblx0;
    }
     this.state.wnds = {};
     this.state.msg = {};

    //this.state.gso = JSON.parse(JSON.stringify( this.state.rvo.gsb00 ));
    var gso = {};
    gso.rumsnr = this.state.gsb00.r0rumsnr;
    gso.namn = this.state.gsb00.r0namn;
    gso.nrg = this.state.gsb00.r0guests;
    gso.nrb0 = this.state.gsb00.r0antb0;
    gso.nrb1 = this.state.gsb00.r0antb1;
    gso.namn = gso.namn.trim();
    gso.nrg = gso.nrg.ztrim();
    gso.nrb0 = gso.nrb0.ztrim();
    gso.nrb1 = gso.nrb1.ztrim();
    this.state.gso = gso;
  }

  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

  componentDidMount() {
      //this.gst_init();
  }

  gst_init() {
      var gso = {};
      gso.rumsnr = this.state.gsb00.r0rumsnr;
      gso.namn = this.state.gsb00.r0namn;
      gso.nrg = this.state.gsb00.r0guests;
      gso.nrb0 = this.state.gsb00.r0antb0;
      gso.nrb1 = this.state.gsb00.r0antb1;
      gso.namn = gso.namn.trim();
      gso.nrg = gso.nrg.ztrim();
      gso.nrb0 = gso.nrb0.ztrim();
      gso.nrb1 = gso.nrb1.ztrim();
      this.setState({gso: gso});
  }
    dlg_style() {
        var css = {};
        //css.width = window.innerWidth * 0.8;
        //css.height = window.innerHeight * 0.8;
        return css;
    }
    gstbox_style() {
        var css = {};
        if(this.state.gstok) {
            css.background = "#080";
            css.color = "#fff";
        }
        return css;
    }

    handleUserInput (e) {
        const name = e.target.name;
        const value = e.target.value;
        var user = this.state.user;
        user[name] = value;
        this.setState({user: user});
        //setState({[name]: value});
    }
    wnd_close() {
		this.props.ctx.setState({ wnds: {} });
	};
    tb_style() {
        var css = {};
        css.height = window.innerHeight - 275;
        return css;
    }
  sz_rooms() {
      var wnds = {};
      wnds.rooms = true;
      wnds.bro = this.state.bro;
      wnds.cb = this.cb_rooms;
    this.setState({wnds: wnds});
  }
  cb_rooms(ctx, ret) {
      if(ret.ok == "000") {
          var rumsnr = ret.rumsnr + "00";
          var gso = ctx.state.gso;
          gso.rumsnr = rumsnr;
        //ctx.setState({gso: gso, wnds: {} });
        var prm = {};
        //prm.req = "pms.br.brg_rr_upd";
        prm.req = "pms.gso.gst_rr_upd";
        prm.boknr = ctx.state.rvo.boknr;
        prm.gsb00 = ctx.state.rvo.gsb00;
        prm.rumsnr = ctx.state.gso.rumsnr;

        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret.rco));

            var rvo = ctx.state.rvo;
            rvo.gsb00 = ret.rco;
            ctx.setState({rvo: rvo, wnds: {} });
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
        return;
      }
    ctx.setState({ wnds: {} });
  }
  rr() {
      var wnds = {};
      wnds.rr = true;
    this.setState({wnds: wnds});
  }
  rf_gst() {
      var wnds = {};
      wnds.rfgst = true;
      wnds.bro = this.state.bro;
      wnds.cb = this.cb_rooms;
    this.setState({wnds: wnds});
  }
  ci_done() {
      var wnds = {};
      wnds.cidone = true;
      wnds.bro = this.state.bro;
        wnds.cb = this.cb_cidone;
    this.setState({wnds: wnds});
  }
   cb_cidone(ctx, ret) {
    //alert(JSON.stringify(ret));
    if(ret && (ret.ok == "000")) {
    if(ret.fkn == "nota") {
        var wnds = {};
        wnds.nota = true;
        wnds.boknr = ret.boknr;
        wnds.rvo = ret.rvo;
        ctx.setState({wnds: wnds});
        return;
    }
    }
    ctx.setState({wnds: {}});
  }
  fknSel(sel) {
    //alert(JSON.stringify(fkn));
      if(sel == "rvo") {
        var wnds = {};
        wnds.wndrvo = true;
        wnds.boknr = this.state.boknr;
        this.setState({wnds: wnds});
      }
      if(sel == "gso") {
        var wnds = {};
        wnds.cidone = true;
        wnds.boknr = this.state.boknr;
        wnds.gso = this.state.rvo.gsb00;
        wnds.cb = this.cb_cidone;
        this.setState({wnds: wnds});
      }
      if(sel == "nota") {
        var wnds = {};
        wnds.nota = true;
        wnds.boknr = this.state.boknr;
        wnds.rvo = this.state.rvo;
        this.props.ctx.setState({wnds: wnds});
      }
  }
    rvo_close(ctx) {
        ctx.setState({ wnd_rvo: false });
    };
  gst_upd() {
    var self = this;

    var gso = this.state.rvo.gsb00;
    gso.r0namn = this.state.gso.namn;
    gso.r0guests = this.state.gso.nrg;
    gso.r0antb0 = this.state.gso.nrb0;
    gso.r0antb1 = this.state.gso.nrb1;

    var prm = {};
    //prm.req = "pms.br.br_gst_upd";
    prm.req = "pms.gso.gst_upd";
    prm.boknr = this.state.rvo.boknr;
    prm.gsb00 = gso;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rco));

        self.toast("OK", "GÄST UPPDATERAD");
        /*
        self.setState({gstok: true });
        setTimeout(function() {
            self.setState({gstok: false });
        }, 3000);
        */
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  gst_setroom() {
    var self = this;

    var prm = {};
    //prm.req = "pms.br.brg_rr_upd";
    prm.req = "pms.gso.gst_rr_upd";
    prm.boknr = this.state.rvo.boknr;
    prm.gsb00 = this.state.rvo.gsb00;
    prm.rumsnr = this.state.gso.rumsnr;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rco));

        var rvo = self.state.rvo;
        rvo.gsb00 = ret.rco;
        self.setState({rvo: rvo });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  xgst_ci() {
    var self = this;

    var prm = {};
    prm.req = "pms.gso.gst_ci";
    prm.boknr = this.state.rvo.boknr;
    prm.gsb00 = this.state.rvo.gsb00;

    net.sio_req(prm)
    .then(function(ret){
        alert(JSON.stringify(ret));

        //self.setState({rva: ret.rco });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
   gst_ci() {
    var self = this;

    if(this.state.gso.rumsnr.ztrim() == "") {
        self.toast("ERROR", "INGET RUM VALT");
        return;
    }
    var msg = {};
    msg.text = "CHECKAR IN GÄST...";
    msg.info = true;
    self.setState({msg: msg });

    var rvo = {};
    rvo.boknr = this.state.rvo.boknr;
    rvo.rumsnr = this.state.gso.rumsnr;

    var prm = {};
    prm.req = "pms.gst_op.gst_ci";
    prm.boknr = this.state.rvo.boknr;
    prm.rvo = rvo;
    prm.gsb00 = this.state.rvo.gsb00;
    //alert(JSON.stringify(prm.rvo));
    //return;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rco));

        if(ret.ok == "000") {
            self.toast("OK", "GÄST INCHECKAD");

            var rsp = {};
            rsp.ok = "000";
            rsp.boknr = self.state.rvo.boknr;
            rsp.gso = self.state.rvo.gsb00;
            if(self.props.wnds.cb) self.props.wnds.cb(self.props.ctx, rsp);
		    else self.props.ctx.setState({ wnds: {} });
		    return;
        }
        //alert("FEL: " + JSON.stringify(ret.ok));
        if(ret.ok == "996") {
            self.toast("ERROR", "RUMMET OSTÄDAT");
        }
        if(ret.ok == "997") {
            self.toast("ERROR", "RUMMET STÄNGT");
        }
        if(ret.ok == "998") {
            self.toast("ERROR", "RUMMET RESERVERAT");
        }
        if(ret.ok == "999") {
            self.toast("ERROR", "KAN EJ CHECKAIN");
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
   val_save (name, value) {
      var gso = this.state.gso;
      //var na = name.split(".");
      //gso[na[0]][na[1]] = value;
      gso[name] = value;
      this.setState({gso: gso});
      //setState({[name]: value});
    }
  toast(type, txt) {
        var self = this;
        var defer = q.defer();

        var msg = {};
        msg.text = txt;
        if(type == "OK") msg.ok = true;
        if(type == "ERROR") msg.error = true;

        self.setState({msg: msg });
        setTimeout(function(){
            self.setState({msg: {} });
            //self.props.ctx.setState({ pluopen: false });
            //self.props.ctx.plu_upd(self.props.ctx, plubuf);
            var rsp = {};
            defer.resolve(rsp);
        }, 1500);
  }

  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "";

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="mx_backdrop" /> : null }
        <div className="mx_dlg mx_100 flex-col" style={ this.dlg_style() }>
        <div className="mx_content flex">

            <div className="mx_h01 flex-row" style={{background: "#1a456b", color: "#fff"}}>
                    <div className="flex-col flex txt_22">
                        <div>GÄST CHECKIN: { this.state.boknr }</div>
                    </div>
                    <div className="flex-col">
                        <div>UPPDATERA</div>
                    </div>
            </div>
            <div className="divider_orange"></div>

             <div className="flex-col flex">
             <div className="flex-row">

            <div className="txt_box txt_bg_01 flex-col flex">
            <div className="flex-row">
                    <div className="flex-column flex py-2">
                         <div className="txt_lbl">FÖRETAG:</div>
                         <div className="txt_data">{ this.state.rvo.company.toswe() }</div>
                    </div>
                    <div className="flex-column flex py-2">
                         <div className="txt_lbl">TELE:</div>
                         <div className="txt_data">{ this.state.rvo.tele }</div>
                    </div>
            </div>
            <div className="flex-row">
                    <div className="flex-column flex py-2">
                        <div className="txt_lbl">ANM:</div>
                        <div className="txt_data">{ this.state.rvo.anm.toswe() }</div>
                    </div>
            </div>
            <div className="flex"></div>
            <div className="flex-row">
                <div className="wnd_link">
                    RENSA
                </div>
                <div className="flex"></div>
                <div className="wnd_link" onClick={() => this.gst_upd() }>
                    SPARA BOKNING
                </div>
            </div>
            </div>

           <div className="txt_box txt_bg_01 txt_22 w250">
                    <div className="flex-row flex">
                        <div className="txt_lbl flex">BOKAT VÄRDE:</div>
                        <div className="txt_price">{ utils.toPris( this.state.rvo.charges.bokat ) }</div>
                    </div>
                    <div className="flex-row flex">
                        <div className="txt_lbl flex">SALDO:</div>
                        <div className="txt_price">{ utils.toPris( this.state.rvo.charges.saldo ) }</div>
                    </div>
                    <div className="flex-row flex">
                        <div className="txt_lbl flex">BETALT:</div>
                        <div className="txt_price">{ utils.toPris( this.state.rvo.charges.paid ) }</div>
                    </div>
                    <div className="flex-row flex">
                        <div className="txt_lbl flex">ATT BETALA:</div>
                        <div className="txt_price">{ utils.toPris( this.state.rvo.charges.topay ) }</div>
                    </div>
            </div>
            </div>

            <div className="divider_orange"></div>

             <div className="flex-row flex">
             <div className="flex-col flex" style={ this.gstbox_style() }>
            <div className="txt_box flex-col">
            <div className="flex-row">
                <div className="wnd_link">
                    DELBOKNING
                </div>
                <div className="flex"></div>
                <div className="wnd_link" onClick={() => this.gst_upd() }>
                    UPPDATERA
                </div>
            </div>
            <div className="flex-row">
                    <div className="flex-column flex py-2">
                        <div className="txt_lbl">PERIOD:</div>
                        <div className="txt_data">{ utils.toDate( this.state.rvo.ank ) } - { utils.toDate( this.state.rvo.avr ) }</div>
                    </div>
                    <div className="flex-column flex py-2">
                        <div className="txt_lbl">RUMSTYP:</div>
                        <div className="txt_data">{ this.state.rvo.rt }</div>
                    </div>
            </div>
            </div>

            <div className="divider_orange"></div>

            <div className="txt_box flex flex-col">
            <div className="flex-row">
                <div className="wnd_link">
                    GÄST
                </div>
                <div className="flex"></div>
                <div className="wnd_link" onClick={() => this.gst_upd() }>
                    SPARA GÄST
                </div>
            </div>
            <form className="cdi_f03">
                <div className="flex-row py-2">
                    <Fr_Input label="Namn" col className="text-left w350" text={this.state.gso.namn} getValue={(e)=> this.val_save("namn", e) } />
                    <Fr_Input label="Antal" col className="text-left w250" text={this.state.gso.nrg} getValue={(e)=> this.val_save("nrg", e) } />
                </div>
                <div className="flex-row py-2">
                        <Fr_Input label="Barn (0-4)" col className="text-left w250" text={this.state.gso.nrb0} getValue={(e)=> this.val_save("nrb0", e) } />
                        <Fr_Input label="Barn (5-8)" col className="text-left w250" text={this.state.gso.nrb1} getValue={(e)=> this.val_save("nrb1", e) } />
                </div>
                <div className="flex flex-column text-left">
                    <span className="header">Anmärkning</span>
                    <textarea className="form-control" maxLength="512" name="anm.rec" value={this.state.gso.anm} onChange={(e) => this.val_save("anm", e)}></textarea>
                </div>
            </form>

            <div className="flex"></div>
            <div className="flex-row">
                <div className="wnd_link">
                    RENSA
                </div>
                <div className="flex"></div>
                <div className="wnd_link" onClick={() => this.gst_upd() }>
                    SPARA GÄST
                </div>
            </div>
            </div>
            </div>

             <div className="flex-col">
           <div className="txt_box flex-col w250 flex">
                <div className="text-center txt_14">RUMSNR</div>
                <div className="text-center txt_22">{ utils.toRumsnr( this.state.gso.rumsnr ) }</div>
                <div className="flex"></div>
                <div className="wnd-btn" onClick={() => this.rf_gst() } >
                    <div>ANGE RUMSNR</div>
                    <div></div>
                </div>
                <div className="wnd-btn" onClick={() => this.rf_gst() } >
                    <div>VÄLJ RUM</div>
                    <div></div>
                </div>
            </div>
            </div>

            </div>
            </div>


            <div className="divider_orange"></div>

        <div className="web-row">
        <div className="web-col flex" style={{padding: "0px 10px"}}>
            <div className="wnd-btn" onClick={() => this.fknSel("rvo") } >
                <div>ÖPPNA BOKNING</div>
                <div></div>
            </div>
            <div className="wnd-btn" onClick={() => this.wnd_close() } >
                <div>STÄNG</div>
                <div></div>
            </div>
        </div>
         <div className="web-col flex" style={{padding: "0px 10px"}}>
            <div className="wnd-btn" onClick={() => this.fknSel("gso") } >
                <div>ÖPPNA GÄST</div>
                <div></div>
            </div>
            <div className="wnd-btn" onClick={() => this.fknSel("nota") } >
                <div>GÄST NOTA</div>
                <div></div>
            </div>
       </div>
       <div className="web-col flex" style={{padding: "0px 10px"}}>
            <div className="wnd-btn" onClick={() => this.rr() } >
                <div>ROOMRACK</div>
                <div></div>
            </div>
            <div className="wnd-btn" onClick={() => this.gst_ci() } >
                <div>CHECKA IN</div>
                <div></div>
            </div>
       </div>
       </div>

    </div>
    </div>
            { this.state.wnds.wndrvo ?
        <Pms_Rvo wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
            : null }
            { this.state.wnds.rr ?
        <Pms_Rr wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
            : null }
            { this.state.wnds.rfgst ?
        <Pms_RfGst wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
            : null }
            { this.state.wnds.cidone ?
        <Pms_CiDone wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
            : null }
            { this.state.wnds.nota ?
        <Pms_Nota wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
            : null }

        <Wnd_Error msg={this.state.msg} ctx={this} />
        <Wnd_Ok msg={this.state.msg} ctx={this} />
        <Wnd_Info msg={this.state.msg} ctx={this} />
        <Wnd_Confirm msg={this.state.msg} ctx={this} />
    </div>
	;
	return html;
  };
}

export default Pms_CiSel;

/*
    <Fr_Input label="Rum" col className="text-right txt_22 w200 hide" text={this.state.gso.rumsnr} getValue={(e)=> this.val_save("rumsnr", e) } />
*/