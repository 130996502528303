import React, { useState, useEffect, useContext } from 'react';
import {format} from "date-fns";
import * as utils from "../../../../assets/utils";

import * as net from "../../../../lib/net";
import Dd_Sel from "../../../../lib/ui/dd_sel";
import Dt_Cal from "../../../../lib/ui/dt_cal";
import Fr_Input from "../../../../lib/ui/fr_input";
import sso from "../../../../sso";

class Pay_Fsk extends React.Component {
  constructor(props, context) {
    super(props);

     this.selitems = [
        {fkn: 'setup', text: 'INSTÄLLNINGAR'},
        {fkn: 'upd', text: 'UPPDATERA'},
        {fkn: 'sys', text: 'SYSTEM'},
        {fkn: 'logout', text: 'LOGGA UT'}
    ];

    this.dt_dt = React.createRef();

    this.state = {};
    this.state.ynq = [];
      this.state.fso = {};

    this.state.paa = [];
      this.state.aa = [];
      this.state.fskid = "";

      this.state.boknr = "";
      this.state.gso = {};
      this.state.pra = [];
      this.state.belopp = "";
      if(props.wnds) {
          this.state.boknr = props.wnds.boknr;
          this.state.gso = props.wnds.gso;
          this.state.pra = props.wnds.pra;
          this.state.belopp = props.wnds.belopp;
      }

    this.state.today = new Date();
    var dts = format(this.state.today, "yyyy-MM-dd");
    this.state.dts = dts;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }


    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    wnd_close() {
      if(this.fkn_close) this.fkn_close(this.props.ctx);
      else this.props.ctx.setState({ wnds: {} });
	};
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
    val_save (name, value) {
        //var tro = this.state.tro;
        //var na = name.split(".");
        //gso[na[0]][na[1]] = value;
        //tro[name] = value;
        this.setState({fskid: value});
        //setState({[name]: value});
    }
    fsk_text() {
        var self = this;
        var text = "";
        if(this.state.fso.fsk00) {
            text = this.state.fso.fsk00.f0text;
        }
        return text;
    }
    fsk_saldo() {
        var self = this;
        var saldo = "";
        if(this.state.fso.fsk00) {
            saldo = this.state.fso.fsk00.f0saldo;
            saldo = utils.toPris(saldo);
        }
        return saldo;
    }
    fsk_sz() {
        var self = this;

        var prm = {};
        prm.req = "pms.fsk.fsk_sz";
        prm.fskid = this.state.fskid;
        //alert(JSON.stringify(prm));
        //return;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret));
                if(ret.ok == "000") {
                    self.setState({fso: ret.fso});
                }
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    fsk_pay() {
        var self = this;

        if(!this.state.fso.fsk00) {
            alert("Förskott saknas");
            return;
        }
        var fsaldo = this.state.fso.fsk00.f0saldo * 1;
        var belopp = this.state.belopp * 1;
        var topay = this.state.belopp * 1;
        
        if(fsaldo < belopp) {
            alert("Förskotts saldo för lågt!");
            return;
        }

        var prm = {};
        prm.req = "pms.fsk.fsk_use";
        prm.fso = this.state.fso;
        prm.belopp = this.state.belopp;
        //alert(JSON.stringify(prm));
        //return;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret));
                if(ret.ok == "000") {
                    self.setState({ok: true});
                    self.pay_tr("Fors", "Forskottutnyttja");
                }
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }

    pay_tr(betkod, bettext) {
        var self = this;

        //var pra = [];
        //for(var row of self.state.nta) {
        //    pra.push(row.etr01);
        //}
        var pra = this.state.pra;
        if(pra.length < 1) {
            alert("Inga rader");
            return;
        }

        var belopp = this.state.belopp * 1;
        var sdo = {};
        sdo.notanr = "";

        var prm = {};
        prm.req = "pms.charge.pay_trs";
        prm.boknr = this.state.gso.r0boknr;
        prm.glopnr = this.state.gso.r0glopnr;
        prm.payrows = pra;
        prm.paycode = betkod;
        prm.paytext = bettext;
        prm.payamount = belopp;
        prm.tkn = "+";
        prm.datum = this.state.dts;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret));
                if(ret.ok != "000") {
                    alert("Betal fel...");
                    return;
                }
                
                sdo.notanr = ret.notanr;

                var prm = {};
                prm.req = "pms.charge.kvitto_petty";
                prm.boknr = self.state.boknr;
                prm.glopnr = self.state.gso.r0glopnr;
                prm.gso = self.state.gso;
                prm.notanr = sdo.notanr;
                prm.prnnr = sso.seo.prnnr;
                return net.sio_req(prm);
            })
            .then(function(ret){
                //alert(JSON.stringify(ret));
                if(ret.ok != "000") {
                    alert("Betal fel...");
                    return;
                }

                if(self.props.wnds.cb) {
                    var rsp = {};
                    rsp.ok = "000";
                    self.props.wnds.cb(self.props.ctx, rsp);
                }
                else self.props.ctx.setState({ wnds: {} });
                //self.charges();
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }

  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "";

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="m2_backdrop" /> : null }
        <div className="m2_dlg mx_70 flex-col" style={ this.dlg_style() }>
        <div className="flex-row " style={{padding:"0px",height:"50px",lineHeight:"50px", background: "#1a456b", color: "#fff"}}>
                <div className="flex-row flex" style={{padding:"0px 5px", fontSize: "22px"}}>
                    <div>FÖRSKOTT { this.state.rumsnr }</div>
                </div>
            <div className="wnd-close-btn" onClick={() => this.wnd_close() }>X</div>
        </div>
        <div className="mx_content flex">

            <div className="flex-col">
                <div className="flex-row py-2">
                    <div className="f30"></div>
                    <div className="">BELOPP ATT BETALA: { utils.toPris(this.state.belopp) }</div>
                </div>
            </div>
            <div className="flex-col">
                <div className="flex-row py-2">
                    <div className="f30"></div>
                    <Fr_Input label="Förskotts ID" col className="text-left w350 mx-2" text={this.state.fskid} getValue={(e)=> this.val_save("presid", e) } />
                    <button className="btn btn-hw-green w250" onClick={() => this.fsk_sz() } >
                        SÖK
                    </button>
                </div>
            </div>
            <div className="flex-col">
                <div className="flex-row py-2">
                    <div className="f30"></div>
                    <div className="">TEXT: { this.fsk_text() }</div>
                </div>
            </div>
            <div className="flex-col">
                <div className="flex-row py-2">
                    <div className="f30"></div>
                    <div className="">SALDO: { this.fsk_saldo() }</div>
                </div>
            </div>

        <div className="flex"></div>

        <div className="divider_orange"></div>

        </div>
        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex"></div>
            <div className="flex"></div>
            <button className="btn btn-hw-green w250" onClick={() => this.fsk_pay() } >
                BETALA
            </button>
        </div>
      </div>
    </div>
	;
	return html;
  };
}

export default Pay_Fsk;