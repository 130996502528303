import React, { useState, useEffect, useContext } from 'react';
import {addDays, format} from "date-fns";

import * as net from "../../../lib/net";
import Dt_Cal from "../../../lib/ui/dt_cal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Pms_Rrp extends React.Component {
  constructor(props, context) {
    super(props);

    this.state = {};
    this.state.ynq = [];

    this.dt_fd = React.createRef();
    this.dt_td = React.createRef();

    this.state.wnds = {};
    this.state.lrwrk = false;
    this.state.ix = 0;

    this.state.dta = [];
    this.state.rtdata = [];
    this.state.loading = true;

    this.state.today = new Date();
    this.state.fdat = format(this.state.today, "yyyy-MM-dd");
    var tdat = addDays(this.state.today, 15);
    this.state.tdat = format(tdat, "yyyy-MM-dd");
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.bel_rooms();
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 200;
      css.overflow = "auto";
      return css;
  }
    wnd_close = () => {
        this.props.ctx.setState({ wnds: {} });
    };
    rr = () => {
        var wnds = {};
        wnds.rr = 1;
        this.props.ctx.setState({ wnds: wnds });
    };
  bel_rooms() {
    var self = this;

    var prm = {};
    prm.req = "pms.bel.room_period";
    prm.fdat = this.state.fdat;
    prm.tdat = this.state.tdat;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rco));
/*
        var rta = [];
        var keys = Object.keys(ret.rco);
        for(var key of keys) {
            var rto = ret.rco[key];
            //alert(JSON.stringify(key));

            rta.push( rto );
        };
 */
        //alert(JSON.stringify(ret.dta));
        self.setState({rtdata: ret.rca, dta: ret.dta, loading: false });
    })
    .catch(function(e) {
        var txt = JSON.stringify(e);
    });
  }
  rr_clean(row, status) {
    var self = this;
    var prm = {};
    prm.req = "pms.rr.rr_clean";
    prm.gsb01 = row.gsb01;
    prm.status = status;

    net.sio_req(prm)
    .then(function(ret){
        alert(JSON.stringify(ret));
    })
    .catch(function(e) {
        var txt = JSON.stringify(e);
    });
  }

  dt_fdat(e) {
    //alert(JSON.stringify(e));
    var dts = format(e, "yyyy-MM-dd");
    this.setState({ fdat: dts });
  }
  dt_tdat(e) {
      //alert(JSON.stringify(e));
    var dts = format(e, "yyyy-MM-dd");
    this.setState({ tdat: dts });
  }
  oneday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_td.current.updateDt(this.state.fdat);
  }
  addday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_fd.current.updateAdd();
    this.dt_td.current.updateAdd();
  }
  subday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_fd.current.updateSub();
    this.dt_td.current.updateSub();
  }
  room_style(row) {
      var css = {};
      css.textAlign = "center";
      if(row.reserved == "1") {
          css.background = "#800";
          css.color = "#fff";
      }
      if(row.closed == "1") {
          css.background = "#000";
          css.color = "#fff";
      }
      return css;
  }
  room_disp(row) {
      var val = "";
      if(row.reserved == "1") {
          val = "B";
      }
      return val;
  }

  render() {
    //if(!this.props.show) return "";

    //alert(this.props.ctx.state.lro);

    var title = this.props.title;
    if(!title) title = "Knappar";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="mx_backdrop" /> : null }
        <div className="mx_fb flex-col" style={ this.dlg_style() }>
        <div className="mx_h01">
            <div>ROOOMRACK PERIOD</div>
        </div>

        <div className="mx_content flex">

    <div className="flex-row">
        <div className="flex-row"></div>
        <div className="flex-row">
              <div className="dt-btn-01" onClick={() => this.subday() } ><FontAwesomeIcon icon="minus" size="1x" /></div>
              <Dt_Cal dts={this.state.fdat} rp="1" ref={this.dt_fd} getValue={(e)=> this.dt_fdat(e)} />
              <div className="dt-btn-01" onClick={() => this.oneday() } >1</div>
              <Dt_Cal dts={this.state.tdat} rp="1" ref={this.dt_td} getValue={(e)=> this.dt_tdat(e)} />
              <div className="dt-btn-01" onClick={() => this.addday() } ><FontAwesomeIcon icon="plus" size="1x" /></div>
        </div>
        <div className="flex"></div>
        <button className="btn btn-hw-blue w150" onClick={() => this.bel_rooms() } aria-label="System" >
            UPPDATERA
        </button>
    </div>
    <div className="divider_orange"></div>

    <div className="web-col">
        <div style={ this.tb_style() }>
        <table className="table table-bordered table-striped tb_pres" flex="20">
            <thead>
            <tr>
                <th style={{textAlign: "center"}}>Rumsnr</th>
                <th style={{textAlign: "left"}}>Rumstyp</th>
                { this.state.dta.map((row, key) =>
                    <th style={{textAlign: "center"}}>{ row.dt }</th>
                )}
            </tr>
            </thead>
            <tbody>
            { this.state.rtdata.map((row, key) =>
            <tr key={key} >
                <td className='header' onClick="selrt();" style={{textAlign: "center"}}>{row.rumsnr}</td>
                <td style={{textAlign: "left", padding: "0px 3px"}}>{row.typ}</td>
                { Object.values(row.dto).map((roomdt, key) =>
                    <th style={ this.room_style(roomdt) }>{ this.room_disp(roomdt) }</th>
                )}
            </tr>
                )}
            </tbody>
        </table>
    </div>
    </div>

    </div>
        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex-row"></div>
            <button className="btn btn-hw-blue w250" onClick={() => this.rr() } aria-label="System" >
                ROOMRACK
            </button>
            <div className="flex-row"></div>
            <button className="btn btn-hw-green w250" onClick={(event) => this.wnd_close(event) } >
                STÄNG
            </button>
        </div>
      </div>
    </div>
	;
	return html;
  };
}

export default Pms_Rrp;