import React, { useState, useEffect, useContext } from 'react';
import {format} from "date-fns";

import * as fkn from "../../../lib/fkn";
import * as net from "../../../lib/net";
import * as utils from "../../../assets/utils";
import {toTid} from "../../../assets/utils";

import Dt_Cal from "../../../lib/ui/dt_cal";
import Tr01_Open from "./fkn/tr01_open";
import Trs_Logi from "./trs/trs_logi";
import Eko_Recover from "./fkn/eko_recover";
import Dd_Sel from "../../../lib/ui/dd_sel";
import Wnd_Error from "../../../lib/ui/wnd_error";
import Wnd_Ok from "../../../lib/ui/wnd_ok";
import Wnd_Info from "../../../lib/ui/wnd_info";
import Wnd_Confirm from "../../../lib/ui/wnd_confirm";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Eko_Trs extends React.Component {
  constructor(props, context) {
    super(props);

    this.dt_fd = React.createRef();
    this.dt_td = React.createRef();

    this.store = props.store;
    this.pms = props.store.pms;

    this.state = {};
    this.state.fkn = "";
      this.state.sortid = "";
      this.state.tra = [];
      this.state.tsa = [];
      this.state.tta = [];
      this.state.tot = {};

    this.state.today = new Date();
    var dts = format(this.state.today, "yyyy-MM-dd");
    this.state.fdat = dts;
    this.state.tdat = dts;
    if(this.pms.base) {
        this.state.fdat = this.pms.base.fdat;
        this.state.tdat = this.pms.base.tdat;
    }
      this.state.msg = {};
      this.state.wnds = {};
      this.selitems = [
          { fkn: 'recov', text: 'Ekonomi Recover' },
          { fkn: 'logi', text: 'Logi avstämning' }
      ];
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.trans();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      css.overflow = "auto";
      return css;
  }
  th_style(id) {
      var css = {};
      css.cursor = "pointer";
      if(id == this.state.sortid) css.color = "#080";
      return css;
  }
    row_style(row) {

        var css = {};
        if(row.e0typ == "xx") {
            css.background = "#3c983c";
            css.color = "#fff";
        }
        if(this.state.fkn == "FSG") {
            if(row.e0bastyp !== "S") {
                css.display = "none";
            }
        }
        if(this.state.fkn == "BET") {
            if(row.e0bastyp !== "P") {
                css.display = "none";
            }
        }
        if(this.state.fkn == "FSK") {
            if(row.e0typ.substr(0,3) !== "fsk") {
                css.display = "none";
            }
        }
        return css;
    }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }
  trs_logi() {
    //alert(JSON.stringify(e));
    var wnds = {};
    wnds.trslogi = true;
    this.setState({ wnds: wnds });
  }
  dt_fdat(e) {
    //alert(JSON.stringify(e));
    var dts = format(e, "yyyy-MM-dd");
    this.setState({ fdat: dts });
  }
  dt_tdat(e) {
      //alert(JSON.stringify(e));
    var dts = format(e, "yyyy-MM-dd");
    this.setState({ tdat: dts });
  }
  oneday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_td.current.updateDt(this.state.fdat);
  }
  addday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_fd.current.updateAdd();
    this.dt_td.current.updateAdd();
  }
  subday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_fd.current.updateSub();
    this.dt_td.current.updateSub();
  }

  change_fkn(e) {
      var self = this;
    //let cdat = this.state.seldate;
    //alert(JSON.stringify(e));
    let fkn = e.target.value;
    this.setState({fkn: fkn}, function () {
        self.syncTb("trsTable");
    });
    //this.setState({tra: [], tsa: [], tta: [] });
  }

  dt_fkn() {
    var self = this;


      var msg = { info: true, text: "Hämtar transaktions rader ..."};
      self.setState({ msg: msg });

    var prm = {};
    //prm.req = "pms.eko_red.red_hbok";
    prm.req = "pms.eko_red.red_trs";
    prm.fdat = this.state.fdat;
    prm.tdat = this.state.tdat;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rco.tot));

        if(ret.ok != "000") {
            self.setState({ msg: {} });
            return;
        }
        self.setState({ tra: ret.rca, tot: ret.tot, msg: {} }, function () {
            self.syncTb("trsTable");
        });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
    }
  tr_edit(row) {
    var self = this;
    var wnds = {};
    wnds.tropen = true;
    wnds.tro = row;
    this.setState({ wnds: wnds });
  }
    syncTb(tb){
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
        tdElements = tbel.getElementsByTagName('td');

        for (let i = 0; i < thElements.length; i++) {
            if(!thElements[i]) continue;
            if(!tdElements[i]) continue;
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
            width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
   tb_sort(typ) {
      var self = this;
      //var rvs = self.state.rvs;
      var tra = self.state.tra;
      var sz = this.state.sz_val;

      tra.sort((a,b) => (a[typ] > b[typ]) ? 1 : ((b[typ] > a[typ]) ? -1 : 0));
      self.setState({ tra: tra, sortid: typ });
   }
  tb_filtr() {
      var self = this;
      var rvs = self.state.rvs;
      var sz = this.state.sz_val;

      if(sz.length < 1) {
        self.setState({ rva: rvs });
          return;
      }
      sz = sz.toUpperCase();
      var rva = rvs.filter(function(row) {
            var brc = false;
            if(row.company.toUpperCase().includes(sz)) brc = true;
            if(row.name.toUpperCase().includes(sz)) brc = true;
            return brc;
      });
      self.setState({rva: rva });
   }
    sel_fkn(sel) {
        //alert(JSON.stringify(fkn));
        if(sel.fkn == "recov") {
            this.eko_recov();
        }
        if(sel.fkn == "logi") {
            this.trs_logi();
        }
    }
    eko_recov() {
        var self = this;
        var wnds = {};
        wnds.ekorecov = true;
        wnds.fdat = this.state.fdat;
        wnds.tdat = this.state.tdat;
        this.setState({ wnds: wnds });
    }

  render() {
    return (
    <div className="web-app">

    <div className="flex-row flex-space-between">
        <div className="flex-row">
        <div className="flex-row">
              <div className="dt-btn-01" onClick={() => this.subday() } ><FontAwesomeIcon icon="minus" size="1x" /></div>
              <Dt_Cal dts={ this.state.fdat } rp="1" ref={this.dt_fd} getValue={(e)=> this.dt_fdat(e)} />
              <div className="dt-btn-01" onClick={() => this.oneday() } >1</div>
              <Dt_Cal dts={ this.state.tdat } rp="1" ref={this.dt_td} getValue={(e)=> this.dt_tdat(e)} />
              <div className="dt-btn-01" onClick={() => this.addday() } ><FontAwesomeIcon icon="plus" size="1x" /></div>
        </div>
            <select className="form-control sel_input" placeholder="Välj funktion" value={this.state.fkn} onChange={(e) => this.change_fkn(e)} >
                <option value="">ALLA</option>
                <option value="FSG">FÖRSÄLJNING</option>
                <option value="BET">BETALNINGAR</option>
                <option value="FSK">FÖRSKOTT</option>
            </select>
        </div>
        <div className="flex"></div>
        <button className="btn btn-hw-blue w150" onClick={() => this.dt_fkn() } aria-label="System" >
            Visa
        </button>
        <Dd_Sel items={ this.selitems } cls="web-x-icon" getValue={(e)=> this.sel_fkn(e)} />
    </div>
    <div className="divider_green"></div>

        <div id="trsTable">
          <table className="table table-bordered table-hover" style={{marginBottom: "0px"}}>
                <thead>
                <tr>
                    <th style={ this.th_style("e0datum") } onClick={() => this.tb_sort("e0datum") }>Datum</th>
                    <th style={ this.th_style("e0rumsnr") } onClick={() => this.tb_sort("e0rumsnr") }>Rum</th>
                    <th style={ this.th_style("e0typ") } onClick={() => this.tb_sort("e0typ") }>Typ</th>
                    <th style={ this.th_style("e0tid") } onClick={() => this.tb_sort("e0tid") }>Tid</th>
                    <th style={ this.th_style("e0boknr") } onClick={() => this.tb_sort("e0boknr") }>Bok-Gst</th>
                    <th style={ this.th_style("e0text") } onClick={() => this.tb_sort("e0text") }>Text</th>
                    <th style={ this.th_style("e0antal") } onClick={() => this.tb_sort("e0antal") }>Antal</th>
                    <th style={ this.th_style("e0pris") } onClick={() => this.tb_sort("e0pris") }>Pris</th>
                    <th style={ this.th_style("e0tkn") } onClick={() => this.tb_sort("e0tkn") }>Tkn</th>
                    <th style={ this.th_style("e0ptyp") } onClick={() => this.tb_sort("e0ptyp") }>PTyp</th>
                </tr>
                </thead>
            </table>
            <div style={ this.tb_style() }>
          <table className="table table-bordered table-hover">
            <tbody>
                { this.state.tra.map((row, key) =>
                    <tr key={key} style={ this.row_style(row) } onDoubleClick={() => this.tr_edit(row) }>
                        <td>{ utils.toDate( row.e0datum ) }</td>
                        <td>{ utils.toRumsnr( row.e0rumsnr ) }</td>
                        <td>{ row.e0typ }</td>
                        <td>{ utils.toTid( row.e0tid ) }</td>
                        <td>{ row.e0boknr }-{ row.e0glopnr }</td>
                        <td>{ utils.toLang( row.e0text ) }</td>
                        <td>{ utils.toNum( row.e0antal ) }</td>
                        <td>{ utils.toPris( row.e0pris ) }</td>
                        <td>{ row.e0tkn }</td>
                        <td>{ row.e0ptyp }</td>
                    </tr>
                )}
                </tbody>
            </table>
        </div>
        </div>
        <div className="divider_green"></div>
        <div className="flex-row px-5">
            <div className="flex">TOTALER:</div>
            <div className="px-5">FSG: { utils.toPris(this.state.tot.fsg) }</div>
            <div className="px-5">BET: { utils.toPris(this.state.tot.bet) }</div>
            <div className="px-5">SALDO: { utils.toPris(this.state.tot.saldo) }</div>
        </div>

        { this.state.wnds.tropen ? <Tr01_Open wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.wnds.trslogi ? <Trs_Logi wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.wnds.ekorecov ? <Eko_Recover wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }

        <Wnd_Error msg={this.state.msg} ctx={this} />
        <Wnd_Ok msg={this.state.msg} ctx={this} />
        <Wnd_Info msg={this.state.msg} ctx={this} />
        <Wnd_Confirm msg={this.state.msg} ctx={this} />

    </div>
  );
}
}
export default Eko_Trs;
