import React, { useState, useEffect, useContext } from 'react';

import Usr_Add from "./usr_add";
import Usr_Open from "./usr_open";

import * as net from "../../../lib/net";
import Sz_Txt from "../../../lib/ui/sz_txt";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Bo_Users extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.pos = props.store.pos;

    this.state = {};
    this.state.sio = null;
    this.state.seldate = new Date();
    this.state.avd = "01";
    this.state.sza = [];
    this.state.uaa = [];
    this.state.paa = [];
    this.state.usradd = 0;
    this.state.usropen = 0;
    this.state.pbo = {};
    this.state.sz_val = "";
    this.state.grps = [
                {id: '000', text: 'ALLA'},
                {id: '001', text: 'INTERNA'},
                {id: '051', text: 'PARTNERS'},
                {id: '101', text: 'KUNDER SVERIGE'},
                {id: '201', text: 'KUNDER NORGE'}
            ];
    this.state.msg = {};
    //if(this.pos.deps) this.state.aa = this.pos.deps;
  }
  componentDidUpdate() {
    let value = this.context;
  }
  componentWillUnmount() {
    let value = this.context;
  }
  componentDidMount() {
    this.users();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 250;
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

  user_add(row) {
      var self = this;
    this.setState({ pbo: row, usradd: 1 });
  }
  user_open(row) {
      var self = this;
    this.setState({ pbo: row, usropen: 1 });
  }
  user_open_done() {
    var self = this;
    self.setState({ usropen: 0 });
    self.users();
  }
  users_sz() {
      var self = this;
      var poa = self.state.poa;

      var paa = poa.filter(row => row.plu00.p0namn.includes("z"));
      self.setState({paa: paa });
   }
  usr_upd(ctx, plubuf) {
    var self = this;
    ctx.users();
    this.setState({ usradd: 0, usropen: 0 });
  }

  users() {
    var self = this;
    var prm = {};
    prm.req = "usr:list";

    net.gcs_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        self.setState({sza: ret.rca, uaa: ret.rca });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  gcsupd() {
    var self = this;
    var prm = {};
    prm.req = "gcs:refresh";

    net.gcs_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

  tst() {
    var self = this;
    var prm = {};
    prm.req = "usr:list";
    //prm.sz = "a";

    //gda.wndLoading("Hämtar");
    net.gcs_req(prm)
    .then(function(ret){
        alert(JSON.stringify(ret));

        var o = {};
        //o.deps = ret.rco;
        //alert(JSON.stringify(o));
        //self.setState({paa: ret.rco });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }
  usr_sz(txt) {
      var self = this;
      var sza = self.state.sza;
      //var sz = this.state.sz_val;
      var sz = txt.toString();

      var rca = sza.filter(row => {
          if(row.usr00) {
            return row.usr00.u0namn.toUpperCase().includes(sz.toUpperCase());
          }
          return false;
      });
      self.setState({ sz_val: txt, uaa: rca });
   }

  render() {
    return (
    <div className="web-app d-flex flex-column">

    <div className="web-p01">
    <div className="flex-row">
        <div className="txt_18">ANVÄNDARE</div>
        <div className="flex"></div>
        <div className="rlink_12" onClick={() => this.users() }>ANVÄNDAR LISTA</div>
    </div>
    <div className="divider_orange"></div>

    <div className="flex-row">
        <select className="form-control w250" placeholder="Välj grupp">
            { this.state.grps.map((row, key) =>
            <option key={key} value={row.id}>{ row.text }</option>
            )}
        </select>

        <Sz_Txt text={ this.state.sz_val } getValue={(e)=> this.usr_sz(e) } />
        <div className="flex"></div>

        <div className="link_14" onClick={() => this.user_add() }>NY ANVÄNDARE</div>
        <div className="link_14" onClick={() => this.gcsupd() }>RESET</div>
        <div className="link_14" onClick={() => this.users() }>UPPDATERA</div>
    </div>
    <div className="divider_orange"></div>

    <div className="web-row">
        <table className="table table-bordered table-hover tb fixed_header">
            <thead>
            <tr>
                <th>UID</th>
                <th>Namn</th>
                <th>Enr</th>
                <th>Kundid</th>
                <th>Profil</th>
                <th>Sub</th>
                <th>Status</th>
                <th className="w50"></th>
            </tr>
            </thead>
            <tbody style={ this.tb_style() }>
            { this.state.uaa.map((row, key) =>
            <tr key={key} id={ row.usr00.u0uid } onDoubleClick={() => this.user_open(row) }>
                <td>{ row.usr00.u0uid }</td>
                <td>{ row.usr00.u0namn.toswe() }</td>
                <td className="text-left">{ row.usr00.u0enr }</td>
                <td>{ row.usr00.u0kid }</td>
                <td>{ row.usr00.u0pid }</td>
                <td>{ row.usr00.u0sub }</td>
                <td>{ row.usr00.u0status }</td>
                <td className="text-center w40" style={{cursor:"pointer"}} onClick={() => this.user_open(row) }><i className="fa fa-cog text-danger"></i></td>
            </tr>
            )}
            </tbody>
        </table>
    </div>

    </div>

    { this.state.usradd ? <Usr_Add show={this.state.usradd} ctx={this} pbo={this.state.pbo} backdrop={true}/> : null }
    { this.state.usropen ?
        <Usr_Open show={this.state.usropen} cb={(e)=> {this.setState({usropen: false}); this.users();} } ctx={this} pbo={this.state.pbo} backdrop={true}/>
    : null }
    </div>
  );
    }
}
export default Bo_Users;

/*

*/