import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";
import {format} from "date-fns";
import * as utils from "../../assets/utils";
import Dt_Cal from "../../lib/ui/dt_cal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Pass_Plan extends React.Component {
  constructor(props, context) {
    super(props);

      this.dt_fd = React.createRef();
      this.dt_td = React.createRef();

      this.store = props.store;
      this.ta = props.store.ta;

    this.state = {};
    this.state.avd = "";
    this.state.kaa = [];
    this.state.kro = {};
    this.state.aa = [];
    this.state.cols = {};
      this.state.today = new Date();
      var dts = format(this.state.today, "yyyy-MM-dd");
      //var tdat = addDays(this.state.today, 15);
      this.state.fdat = format(this.state.today, "yyyy-MM-01");
      this.state.tdat = format(this.state.today, "yyyy-MM-dd");
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }
    dt_fdat(e) {
        //alert(JSON.stringify(e));
        var dts = format(e, "yyyy-MM-dd");
        this.setState({ fdat: dts });
    }
    dt_tdat(e) {
        //alert(JSON.stringify(e));
        var dts = format(e, "yyyy-MM-dd");
        this.setState({ tdat: dts });
    }
    oneday(e) {
        //alert(JSON.stringify(e));
        //this.setState({ tdat: this.state.fdat });
        this.dt_td.current.updateDt(this.state.fdat);
    }
    addday(e) {
        //alert(JSON.stringify(e));
        //this.setState({ tdat: this.state.fdat });
        this.dt_fd.current.updateAdd();
        this.dt_td.current.updateAdd();
    }
    subday(e) {
        //alert(JSON.stringify(e));
        //this.setState({ tdat: this.state.fdat });
        this.dt_fd.current.updateSub();
        this.dt_td.current.updateSub();
    }
    change_avd(e) {
        //let cdat = this.state.seldate;
        let avd = e.target.value;
        //ddo.cache.avd = avd;
        this.setState({avd: avd});
    }

  render() {
    return (
    <div className="web-app">
        <div className="flex-row flex-space-between">
            <select className="form-control w250" placeholder="Välj avdelning" onChange={(e) => this.change_avd(e)}>
                { this.props.store.ta.dpa.map((row, key) =>
                    <option key={key} value={row.avdelning}>{row.avdelning} - {utils.toLang( row.namn )}</option>
                )}
            </select>
            <div className="flex"></div>
            <div className="flex-row">
                <div className="dt-btn-01" onClick={() => this.subday() } ><FontAwesomeIcon icon="minus" size="1x" /></div>
                <Dt_Cal dts={ this.state.fdat } rp="1" ref={this.dt_fd} getValue={(e)=> this.dt_fdat(e)} />
                <div className="dt-btn-01" onClick={() => this.oneday() } >1</div>
                <Dt_Cal dts={ this.state.tdat } rp="1" ref={this.dt_td} getValue={(e)=> this.dt_tdat(e)} />
                <div className="dt-btn-01" onClick={() => this.addday() } ><FontAwesomeIcon icon="plus" size="1x" /></div>
            </div>
            <div className="flex"></div>
            <button className="btn btn-hw-blue w150" onClick={() => this.per() } aria-label="System" >
                UPPDATERA
            </button>
        </div>
        <div className="divider_blue"></div>

   </div>
  );
    }
}
export default Pass_Plan;
