import React, { useState, useEffect, useContext } from 'react';
import {format} from "date-fns";
import {observer} from "mobx-react";

import * as net from "../../lib/net";
import Dt_Cal from "../../lib/ui/dt_cal";

import KvittoWnd from "./dlg/kvitto_wnd";
import PdfRpDlg from "./fkn/pdf_rp_dlg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ddo from "../../ddo";
import * as utils from "../../assets/utils";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Pos_Orders extends React.Component {
  constructor(props, context) {
    super(props);

    this.ref_dat = React.createRef();

    this.pos = props.store.pos;
    this.state = {};
    this.state.today = new Date();
    var dts = format(this.state.today, "yyyy-MM-dd");
      this.state.datum = dts;
      this.state.dts = dts;
    this.state.avd = ddo.cache.avd;
    this.state.raa = [];
    this.state.aa = [];
      this.state.wnds = {};

    this.state.kvitto = false;
    this.state.kvo = {};
    this.state.pdf = 0;
    this.state.pdf_content = null;
      this.state.ora = [];
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.order_list();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 250;
      return css;
  }
    row_style(row) {
        var css = {};
        //css.color = "#222";
        if(row.order00.paid != "1") {
            css.background = "#800";
            css.color = "#fff";
        }
        return css;
    }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }
    dt_dat(e) {
        //alert(JSON.stringify(e));
        var dts = format(e, "yyyy-MM-dd");
        this.setState({ datum: dts });
    }
    addday(e) {
        //alert(JSON.stringify(e));
        //this.setState({ tdat: this.state.fdat });
        this.ref_dat.current.updateAdd();
    }
    subday(e) {
        //alert(JSON.stringify(e));
        //this.setState({ tdat: this.state.fdat });
        this.ref_dat.current.updateSub();
    }
  xdt_dat(e) {
    //alert(JSON.stringify(e));
    var dts = format(e, "yyyy-MM-dd");
    this.setState({ datum: dts });
  }
  change_avd(e) {
    //let cdat = this.state.seldate;
    let avd = e.target.value;
    this.setState({avd: avd});
  }
  kvitto_edit(row) {
    this.setState({kvo: row, kvitto: true});
  }
  pdf_done (ret) {
    this.setState({loading: 0, pdf: 0, pdf_content: null });
  }

  kvitton() {
        var self = this;
    	var prm = {};
        prm.req = "pos.pos_eko.invh_list";
   		prm.avd = ddo.cache.avd;
   		prm.datum = this.state.datum;
   		prm.fdat = this.state.datum;
   		prm.tdat = this.state.datum;
   		prm.favd = ddo.cache.avd;
   		prm.tavd = ddo.cache.avd;

       var dts = format(this.state.today, "yyyy-MM-dd");
       if(this.state.datum == dts) prm.req = "pos.pos_eko.nota_list";

      net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret));

            if(ret.ok != "000") {
                //alert(JSON.stringify(ret));
                return
            }
            if(ret.rca) {
                self.setState({ raa: ret.rca });
            }
            //toastr.success("OK", "SAPI");
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
            //gda.wndError(txt);
        });
  }
  rp_jr() {
    var self = this;
    	//var datum = moment().subtract(14, "days");
    	//var favd = "01";
    	//var tavd = "01";

    	var prm = {};
     	//prm.req = "pos.pos_trs.trs_per";
     	//prm.req = "pos.pos_rp.rp_jr";
     	prm.req = "pos.pos_red.jr_prn_red";
   		prm.avd = ddo.cache.avd;
   		//prm.fdat = datum.format("YYYYMMDD");
   		//prm.tdat = datum.format("YYYYMMDD");
   		prm.favd = ddo.cache.avd;
   		prm.tavd = ddo.cache.avd;
   		//if($scope.ftid)	prm.ftid = $scope.ftid.format("HHmm");
   		//if($scope.ttid)	prm.ttid = $scope.ttid.format("HHmm");

        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret.pdf));

            var o = {};
            //o.deps = ret.rco;
            //alert(JSON.stringify(o));
            //self.setState({tra: ret.rca });
            self.setState({pdf_content: ret.pdf });
            //toastr.success("OK", "SAPI");
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
            //gda.wndError(txt);
        });
  }
    order_list() {
        var self = this;
        self.setState({ora: [], loading: 1 });

        var prm = {};
        prm.req = "pos.pos_ecom_order.order_list";
        prm.dts = this.state.datum;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.rca));

                //var a = self.fltr(ret.rca);
                self.setState({ora: ret.rca, loading: 0 });
                //toastr.success("OK", "SAPI");
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    ora_get() {
        var a = [];
        for(var row of this.state.ora)
            if(this.state.showfkn == 0) {
                if(row.order00.paid == "1") {
                    a.push( row );
                }
            }
            else if(this.state.showfkn == 1) {
                if(row.order00.paid != "1") {
                    a.push( row );
                }
            }
            else {
                a.push( row );
            }
        return a;
    }
    order_edit(vpo) {
        var self = this;
        var wnds = {};
        wnds.orderedit = 1;
        wnds.vpo = vpo;
        self.setState({ wnds: wnds });
    }

  render() {
    return (
    <div className="web-p00 flex flex-col">
        <div className="">
            Ordrar
        </div>

    <div className="web-p01">
    <div className="flex-row">
        <div className="flex-row">
            <div className="dt-btn-01" onClick={() => this.subday() } ><FontAwesomeIcon icon="minus" size="1x" /></div>
            <Dt_Cal dts={this.state.datum} rp="1" ref={this.ref_dat} getValue={(e)=> this.dt_dat(e)} />
            <div className="dt-btn-01" onClick={() => this.addday() } ><FontAwesomeIcon icon="plus" size="1x" /></div>
        </div>
        <select className="form-control w250 hide" placeholder="Avdelning" onChange={(e) => this.change_avd(e)}>
            { this.props.store.pos.deps.map((row, key) =>
            <option key={"xx_" + key} value={row.avd}>{row.avd} - {row.text}</option>
            )}
        </select>
        <div className="flex"></div>
        <button className="btn btn-hw-blue w150" onClick={() => this.order_list() } aria-label="System" >
            Visa
        </button>
        <button className="btn btn-hw-blue w150 hide" onClick={() => this.rp_orders() } aria-label="System" >
            Skriv ut
        </button>
    </div>
    <div className="divider">
    </div>

        <div className="web-row">
            <table className="table table-bordered table-hover tb fixed_header">
                <thead>
                <tr>
                    <th>OrderID</th>
                    <th>Datum</th>
                    <th>Slot</th>
                    <th>Postnr</th>
                    <th>Namn</th>
                    <th>Telefon</th>
                    <th>Levtyp</th>
                    <th>Notanr</th>
                    <th>Belopp</th>
                    <th>Betald</th>
                    <th>Status</th>
                </tr>
                </thead>
                <tbody style={ this.tb_style() }>
                { this.ora_get().map((vpo, key) =>
                    <tr key={key} onDoubleClick={ () => this.order_edit(vpo) } style={ this.row_style(vpo) } >
                        <td>{ utils.toNum( vpo.order00.orderid ) }</td>
                        <td>{ utils.toDate( vpo.order00.datum ) }</td>
                        <td>{ utils.toTid( vpo.order00.slot ) }</td>
                        <td>{ vpo.order02.zip }</td>
                        <td>{ utils.toLang( vpo.order01.fnamn ) } { utils.toLang( vpo.order01.enamn ) }</td>
                        <td>{ vpo.order01.tele }</td>
                        <td>{ vpo.order00.levtyp }</td>
                        <td>{ utils.toNum( vpo.order00.notanr ) }</td>
                        <td className="text-right">{ utils.toPris( vpo.order00.belopp ) }</td>
                        <td>{ vpo.order00.paid }</td>
                        <td>{ vpo.order00.status }</td>
                    </tr>
                )}
                </tbody>
            </table>
        </div>

    </div>

        { this.state.kvitto ? <KvittoWnd kvo={this.state.kvo} ctx={this} store={this.store} backdrop={true}/> : null }
        <PdfRpDlg show={this.state.pdf} pdf={this.state.pdf_content} done={(ret) => this.pdf_done(ret)} backdrop={true}/>
    </div>
  );
    }
}
export default observer(Pos_Orders);
