import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";
import Td_Sel from "../../lib/ui/td_sel";

import Pid_add from "./fkn/uid_add";
import Udid_Open from "./fkn/udid_open";
import ddo from "../../ddo";
import Edit_Inline from "../../lib/ui/edit_inline";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Reg_Udid extends React.Component {
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.state = {};
    this.state.seldate = new Date();
    this.state.avd = ddo.cache.udid;

      this.state.uds = [];
      this.state.uda = [];

    this.state.tro = {};
    this.state.wnds = {};
    this.state.typ = "";
    this.state.sz_val = "";

    this.selitems = [
        {fkn: 'open', text: 'ÖPPNA'},
        {fkn: 'status', text: 'STATUS'},
        {fkn: 'remove', text: 'TA BORT'}
    ];
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      this.udid();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 225;
      css.overflow = "auto";
      return css;
  }
  tab_style(typ) {
      var css = {};
      if(this.state.typ == typ) { css.background = "#009900"; css.color = "#fff"; }
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }
  th_style(id) {
      var css = {};
      css.cursor = "pointer";
      if(id == this.state.sortid) css.color = "#080";
      return css;
  }

  kid_add() {
    var self = this;
    var wnds = {};
    wnds.kidadd = true;
    this.setState({ wnds: wnds });
  }
  udid_edit(row) {
    var self = this;
    var wnds = {};
    wnds.udidedit = true;
    wnds.udo = row;
    this.setState({ wnds: wnds });
  }
  kid_close(ctx) {
    ctx.setState({ wnds: {} });
    ctx.trs();
  }

  udid() {
    var self = this;
    self.setState({typ: "trs", uda: [] });

    var prm = {};
    prm.req = "bcs.udid.udids";
    prm.typ = "T";

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        self.setState({uda: ret.rca, uds: ret.rca }, function () {
            self.syncTb("udidTable");
        });
    })
    .catch(function(e) {
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }
    syncTb(tb) {
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
        tdElements = tbel.getElementsByTagName('td');
        if(tdElements.length < 1 ) return;

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
            width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
   tb_sort(typ) {
      var self = this;
      //var rvs = self.state.rvs;
      var tra = self.state.tra;
      var sz = this.state.sz_val;

      tra.sort((a,b) => (a[typ] > b[typ]) ? 1 : ((b[typ] > a[typ]) ? -1 : 0));
      self.setState({ tra: tra, sortid: typ });
   }
  sel_row_fkn(row, sel) {
      //alert(JSON.stringify(fkn));
    if(sel.fkn == "open") {
        //this.sel_gst(row);
    }
    if(sel.fkn == "br") {
    }
  }
   refresh() {
      this.udid();
    }
    udid_sz() {
        var self = this;
        var uds = self.state.uds;
        var sz = this.state.sz_val;

        var uda = uds.filter(row => row.text.toUpperCase().includes(sz.toUpperCase()));
        self.setState({uda: uda });
    }
    udid_filtr() {
        var self = this;
        var poa = self.state.poa;
        var vgrp = this.state.vgrp;

        if(vgrp == "000") {
            self.setState({paa: poa });
            return;
        }

        var paa = poa.filter(row => row.plu02.p2vgrupp == vgrp);
        self.setState({paa: paa });
    }
    sz_keydown (e) {
        //e.preventDefault();
        const key = e.key;
        const keyCode = e.keyCode;

        if(keyCode == 13) {
            this.udid_sz();
        };
        if(keyCode == 27) {
            this.setState({sz_val: ""});
        };
    }
    sz_change (e) {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({sz_val: value});
        //setState({[name]: value});
    }
    udid_save(row) {
        alert(JSON.stringify(row));
    }

  render() {
    return (
    <div className="web-app">
    <div className="flex-row">
        <div className="flex-row">
            <input className="txt sztxt" value={this.state.sz_val} onChange={(e) => this.sz_change(e)} onKeyDown={(e) => this.sz_keydown(e)} />
        </div>

        <div className="flex"></div>

        <div className="flex-row">
            <button className="btn btn-hw-orange w150" onClick={() => this.kid_add() } aria-label="System" >
                LÄGG TILL
            </button>
            <button className="btn btn-hw-blue w150" onClick={() => this.refresh() } aria-label="System" >
                UPPDATERA
            </button>
        </div>
    </div>
    <div className="divider_orange"></div>

        <div id="udidTable" className="flex">
          <table className="table table-bordered table-hover tb" style={{marginBottom: "0px"}}>
            <thead>
            <tr>
                <th style={ this.th_style("e1typ") } onClick={() => this.tb_sort("e1typ") }>UDID</th>
                <th style={ this.th_style("e1kontonr") } onClick={() => this.tb_sort("e1kontonr") }>Termnr</th>
                <th style={ this.th_style("e1kontonr") } onClick={() => this.tb_sort("e1kontonr") }>Typs</th>
                <th style={ this.th_style("e1text") } onClick={() => this.tb_sort("e1text") }>Text</th>
                <th style={ this.th_style("e1debcre") } onClick={() => this.tb_sort("e1debcre") }>Registrerat</th>
                <th style={ this.th_style("e1moms") } onClick={() => this.tb_sort("e1moms") }>Förfaller</th>
                <th style={ this.th_style("e1pris") } onClick={() => this.tb_sort("e1pris") }>Ägare</th>
                <th style={ this.th_style("e1mattyp") } onClick={() => this.tb_sort("e1mattyp") }>Kund</th>
                <th style={ this.th_style("e1mattyp") } onClick={() => this.tb_sort("e1mattyp") }>Upd</th>
                <th></th>
            </tr>
            </thead>
        </table>
        <div style={ this.tb_style() }>
          <table className="table table-bordered table-hover tb">
            <tbody>
            { this.state.uda.map((row, key) =>
            <tr key={key} onDoubleClick={() => this.udid_edit(row) }>
                <td>{ row.udid }</td>
                <td>{ row.termnr }</td>
                <td>{ row.termtyp }</td>
                <td>{ row.text.cdi2txt() }</td>
                <td className="hide"><Edit_Inline text={ row.text.cdi2txt() } getValue={(e)=> this.udid_save(row, e) } /></td>
                <td>{ row.termhw }</td>
                <td>{ row.due }</td>
                <td>{ row.owner }</td>
                <td>{ row.kid }</td>
                <td>{ row.upd }</td>
                <td>
                    <Td_Sel items={ this.selitems } getValue={(e)=> this.sel_row_fkn(row, e)} />
                </td>
            </tr>
            )}
            </tbody>
        </table>
        </div>
    </div>
        { this.state.wnds.pidadd ? <Pid_add wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.wnds.udidedit ? <Udid_Open wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }

   </div>
  );
    }
}
export default Reg_Udid;
