import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../../lib/net";
import * as utils from "../../../assets/utils";
import Td_Sel from "../../../lib/ui/td_sel";
import {addDays, format} from "date-fns";
import Dt_Cal from "../../../lib/ui/dt_cal";
import Prs_Edit from "../../ee/prs/prs_edit";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Tot_EE extends React.Component {
  constructor(props, context) {
    super(props);

    this.dt_fd = React.createRef();
    this.dt_td = React.createRef();

    this.store = props.store;
    this.state = {};
    this.state.aa = [];
    this.state.cols = {};
    this.state.wnds = {};

      if(this.ta && this.ta.base) {
          this.state.fdat = this.ta.base.fdat;
          this.state.tdat = this.ta.base.tdat;
      }
      this.state.tot = {};
      this.state.eeo = {};
    this.state.lso = {};
    this.state.tha = [];
    if(props.wnds) {
        this.state.fdat = props.wnds.fdat;
        this.state.tdat = props.wnds.tdat;
    }
    this.state.today = new Date();
    var dts = format(this.state.today, "yyyy-MM-dd");
    //this.state.fdat = dts;
    //this.state.tdat = dts;

    //var tdat = addDays(this.state.today, 30);
    //var tds = format(tdat, "yyyy-MM-dd");
    //this.state.tdat = tds;

  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.per_tot();
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    tb_style() {
        var css = {};
        css.height = window.innerHeight - 225;
        css.overfow = "auto";
        return css;
    }
    th_style(id) {
        var css = {};
        css.cursor = "pointer";
        if(id == this.state.sortid) {
            if(this.state.sortdir) css.color = "#800";
            else css.color = "#080";
        }
        return css;
    }
    row_style(row) {
        var css = {};
        if(row.antalopen != "0") {
            css.background = "#fff";
            css.color = "#800";
        }
        //css.cursor = "pointer";
        return css;
    }
    tab_style(tabid) {
      var css = {};
      css.display = "none";
      if(this.state.tabval === tabid) css.display = "block";
      return css;
    }
    tab_head(tabid) {
      var css = {};
      css.background = "#fff";
      css.color = "#000";
      if(this.state.tabval === tabid) {
          css.background = "#080";
          css.color = "#fff";
      }
      return css;
    }
    dt_fdat(e) {
        //alert(JSON.stringify(e));
        var dts = format(e, "yyyy-MM-dd");
        if(dts > this.state.tdat) {
            //this.setState({ fdat: dts, tdat: dts });
            var tdat = addDays(e, 15);
            var tds = format(tdat, "yyyy-MM-dd");
            this.dt_td.current.updateDt(tds);
        }
        this.setState({ fdat: dts });
    }
    dt_tdat(e) {
        //alert(JSON.stringify(e));
        var dts = format(e, "yyyy-MM-dd");
        this.setState({ tdat: dts });
    }
    oneday(e) {
        //alert(JSON.stringify(e));
        //this.setState({ tdat: this.state.fdat });
        this.dt_td.current.updateDt(this.state.fdat);
    }
    addday(e) {
        //alert(JSON.stringify(e));
        //this.setState({ tdat: this.state.fdat });
        this.dt_fd.current.updateAdd();
        this.dt_td.current.updateAdd();
    }
    subday(e) {
        //alert(JSON.stringify(e));
        //this.setState({ tdat: this.state.fdat });
        this.dt_fd.current.updateSub();
        this.dt_td.current.updateSub();
    }
    prs_edit(row) {
        var self = this;
        var wnds = {};
        wnds.prsedit = 1;
        wnds.eeo = row.peo;
        this.setState({wnds: wnds});
    }

    wnds_done(ctx, rsp) {
        if(rsp.wnd == "lredit") {
          if(rsp.status == "removed") {
                ctx.setState({wnds: {}});
		        ctx.props.ctx.wnds_done(ctx.props.ctx, rsp);
		        return;
          }
        }
        ctx.lr_lsa();
        ctx.setState({wnds: {}});
    }
    per_tot() {
        var self = this;
        var prm = {};
        prm.req = "ta.ta_calc.per_tot";
        prm.fdat = this.state.fdat;
        prm.tdat = this.state.tdat;
        //prm.avd = this.state.avd;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret));

                var tha = [];
                if(ret.rca) tha = ret.rca;
                self.setState({ tha: tha, tot: ret.tot });
                self.syncTb("perTable");
                //toastr.success("OK", "SAPI");
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }

    wnd_close = () => {
		this.props.ctx.setState({ wnds: {} });
	};

    syncTb(tb) {
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
            tdElements = tbel.getElementsByTagName('td');
        if(tdElements.length < 1 ) return;

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
                width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
    tb_sort(typ) {
        var self = this;
        //var rvs = self.state.rvs;
        var tha = self.state.tha;
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;

        if(sortid != typ) sortdir = 0;
        else {
            if(sortdir) sortdir = 0;
            else sortdir = 1;
        }

        if(typ) {
            if(sortdir) tha.sort((a,b) => (a[typ] < b[typ]) ? 1 : ((b[typ] < a[typ]) ? -1 : 0));
            else tha.sort((a,b) => (a[typ] > b[typ]) ? 1 : ((b[typ] > a[typ]) ? -1 : 0));
        }
        self.setState({ tha: tha, sortid: typ, sortdir: sortdir });
    }
    th_icon(typ) {
        var cls = "";
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;
        if(sortid == typ)
        {
            if(sortdir) cls = "fa fa-caret-up";
            else cls = "fa fa-caret-down";
        }
        return cls;
    }

  render() {
    ///if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "Period";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="mx_backdrop" /> : null }
        <div className="mx_dlg mx_90 flex-col" style={ this.dlg_style() }>
        <div className="mx_h01">
            <div>PERSONER PERIOD</div>
            <div className="flex"></div>
            <div className="web-x-icon" onClick={() => this.wnd_close() }>X</div>
        </div>
        <div className="mx_content px-3 flex">
            <div className="flex-row flex-space-between">
                <div className="flex"></div>
                <div className="flex-row">
                    <div className="dt-btn-01" onClick={() => this.subday() } ><FontAwesomeIcon icon="minus" size="1x" /></div>
                    <Dt_Cal dts={ this.state.fdat } rp="1" ref={this.dt_fd} getValue={(e)=> this.dt_fdat(e)} />
                    <div className="dt-btn-01" onClick={() => this.oneday() } >1</div>
                    <Dt_Cal dts={ this.state.tdat } rp="1" ref={this.dt_td} getValue={(e)=> this.dt_tdat(e)} />
                    <div className="dt-btn-01" onClick={() => this.addday() } ><FontAwesomeIcon icon="plus" size="1x" /></div>
                </div>
                <div className="flex"></div>
                <button className="btn btn-hw-blue w150" onClick={() => this.per_tot() } aria-label="System" >
                    UPPDATERA
                </button>
            </div>
            <div className="divider"></div>

            <div id="perTable" className="web-col">
                <table className="table table-bordered table-hover tb " style={{marginBottom: "0px"}}>
                    <thead>
                    <tr>
                        <th style={ this.th_style("anstnummer") } onClick={() => this.tb_sort("anstnummer") }>Anstnr <i className={ this.th_icon("anstnummer") }></i></th>
                        <th style={ this.th_style("namn") } onClick={() => this.tb_sort("namn") }>Namn <i className={ this.th_icon("namn") }></i></th>
                        <th style={ this.th_style("avd") } onClick={() => this.tb_sort("avd") }>Avdelning <i className={ this.th_icon("avd") }></i></th>
                        <th style={ this.th_style("wrktid") } onClick={() => this.tb_sort("wrktid") }>Arbetad tid <i className={ this.th_icon("nreg") }></i></th>
                        <th style={ this.th_style("timlon") } onClick={() => this.tb_sort("timlon") }>Timlön <i className={ this.th_icon("netto") }></i></th>
                        <th style={ this.th_style("lon") } onClick={() => this.tb_sort("lon") }>Lön <i className={ this.th_icon("rast") }></i></th>
                        <th style={ this.th_style("ob1") } onClick={() => this.tb_sort("ob1") }>OB1 <i className={ this.th_icon("ob1") }></i></th>
                        <th style={ this.th_style("ob2") } onClick={() => this.tb_sort("ob2") }>OB2 <i className={ this.th_icon("ob2") }></i></th>
                        <th style={ this.th_style("tlon") } onClick={() => this.tb_sort("tlon") }>Summa <i className={ this.th_icon("brutto") }></i></th>
                        <th></th>
                    </tr>
                    </thead>
                </table>
                <div className="tb-cdi" style={ this.tb_style() }>
                    <table className="table table-bordered table-hover tb">
                        <tbody>
                        { this.state.tha.map((row, key) =>
                            <tr key={key} onDoubleClick={() => this.prs_edit(row) }>
                                <td>{ row.anstnummer }</td>
                                <td>{ utils.toLang( row.peo.p1.namn ) }</td>
                                <td>{ row.avd }</td>
                                <td>{ row.wrktid }</td>
                                <td>{ utils.toPris(row.timlon) }</td>
                                <td>{ utils.toPris(row.lon) }</td>
                                <td>{ utils.toPris(row.ob1lon) }</td>
                                <td>{ utils.toPris(row.ob2lon) }</td>
                                <td>{ utils.toPris(row.tlon) }</td>
                                <td>
                                    <Td_Sel items={ this.selitems } getValue={(e)=> this.sel_row_fkn(row, e)} />
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
            <div className="divider_blue"></div>
            <div className="flex-row txt_16 px-2">
                <div className="">TOTAL TID: { utils.toNum(this.state.tot.wrktid) }</div>
                <div className="flex"></div>
                <div className="px-2">TOTAL POS: { utils.toPris(this.state.tot.pos) }</div>
                <div className="px-2">TOTAL PMS: { utils.toPris(this.state.tot.pms) }</div>
                <div className="flex"></div>
                <div className="">TOTAL LÖN: { utils.toPris(this.state.tot.tlon) }</div>
            </div>
        <div className="mx_foot flex-row flex-space-between">
            <div className="flex-row">
            <button className="btn btn-hw-green w250 hide" onClick={(event) => this.ee_prn(event) } >
                SKRIV UT
            </button>
            </div>
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
        </div>
      </div>

            { this.state.wnds.prsedit ? <Prs_Edit wnds={this.state.wnds} ctx={this} backdrop={true}/> : null }
    </div>
	;
	return html;
  };
}

export default Tot_EE;