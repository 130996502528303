import React, { useState, useEffect, useContext } from 'react';
import {format, addDays, subDays, parseISO, differenceInCalendarDays} from "date-fns";

import * as utils from "../../../../assets/utils";
import * as net from "../../../../lib/net";
import Fr_Input from "../../../../lib/ui/fr_input";
import Fr_Inpsel from "../../../../lib/ui/fr_inpsel";
import Fr_Select from "../../../../lib/ui/fr_select";
import Fr_Cal from "../../../../lib/ui/fr_cal";
import Dt_Cal from "../../../../lib/ui/dt_cal";
import Fr_Inprts from "../../../lib/ui/fr_inprts";
import Fr_Inpkats from "../../../lib/ui/fr_inpkats";

import Wnd_Error from "../../../../lib/ui/wnd_error";
import Wnd_Ok from "../../../../lib/ui/wnd_ok";
import Wnd_Info from "../../../../lib/ui/wnd_info";
import Wnd_Confirm from "../../../../lib/ui/wnd_confirm";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Br_New extends React.Component {
  constructor(props, context) {
    super(props);

    this.dt_fd = React.createRef();
    this.dt_td = React.createRef();

    this.store = props.store;
    this.pms = props.store.pms;

    this.state = {};
    this.state.today = new Date();
    this.state.tds = parseISO(this.state.today);

      this.state.msg = {};
    this.state.caldata = [];
    this.state.rtdata = [];
    this.state.rta = [];
    this.state.pka = [];
    this.state.pkat = "F";
    this.state.pko = {};
      this.state.pks = {};
      this.state.sum = 0;

    this.state.fdat = "";
    this.state.tdat = "";
    this.state.gnr = "001";
    this.state.gbo = {};
    //this.state.lro = props.lro;
    this.state.ynq = [
        {id: '0', text: 'NEJ'},
        {id: '1', text: 'JA'}
    ];
    this.state.nra = [
        {val: '0', text: '0', extra: 'st'},
        {val: '1', text: '1', extra: 'st'},
        {val: '2', text: '2', extra: 'st'}
    ];

    this.state.background = "#fff";

    this.state.cols = {};
    this.state.lrwrk = false;
    this.state.boknr = {};
    if(props.boknr) this.state.boknr = props.boknr;
    this.state.bro = {};
    if(props.bro) this.state.bro = props.bro;
    if(props.wnds) {
        if(props.wnds.boknr) this.state.boknr = props.wnds.boknr;
        if(props.wnds.bro) this.state.bro = props.wnds.bro;
    }

    this.state.pkk = [
        {n0typ: '', text: 'VÄLJ PRISKATEGORI', pris: ''},
        {n0typ: 'rack', text: 'ROM OG FROKOST', pris: '79500'},
        {n0typ: 'halp', text: 'HALVPENSION', pris: '99500'},
        {n0typ: 'help', text: 'HELPENSION', pris: '129500'}
    ];

    this.state.pkd = [
        {n0typ: 'F', text: 'ORDINARIE LOGI - F', pris: ''}
    ];
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.br_init();
    this.dd_init();
  }

  pk_price(bro) {
    var pris = "";
    var pkat = this.state.pkat;

      if(this.pms.pko[pkat]) {
        if(this.pms.pko[pkat].rto[bro.rt]) {
            var rto = this.pms.pko[pkat].rto[bro.rt];
            pris = rto.mon;
        }    
    }
/*
      if(bro.rt.substr(0, 3) == "LAR") pris = "780000";
      if(bro.rt.substr(0, 3) == "LEK") pris = "720000";
      if(bro.rt.substr(0, 3) == "LPK") pris = "640000";
      if(bro.rt.substr(0, 3) == "LCK") pris = "470000";
      if(bro.rt.substr(0, 3) == "MCK") pris = "390000";
      if(bro.rt.substr(0, 3) == "MCQ") pris = "310000";
      if(bro.rt.substr(0, 3) == "SPQ") pris = "270000";
      if(bro.rt.substr(0, 3) == "SCS") pris = "220000";
*/
      pris = pris.cdipris();
      return pris;
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    grp_style(row) {
      var css = {};
      css.background = "#008";
      if(this.state.gnr === row.vgrupp) css.background = "#080";
      return css;
    }

    formchange (e) {
      const name = e.target.name;
      const value = e.target.value;
      var pbo = this.state.pbo;
      var na = name.split(".");
      pbo[na[0]][na[1]] = value;
      this.setState({pbo: pbo});
      //setState({[name]: value});
    }

    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    wnd_close = () => {
		//this.props.ctx.setState({ rvbradd: false });
        var ret = {};
        ret.ok = "999";
        this.props.cb(this.props.ctx, ret);
	};
    handleChange = (event, newValue) => {
        //alert(newValue);
        this.setState({ix: newValue});
      };
    toggle() {
      this.setState({modal: false});
	};

    submitHandler = event => {
        event.preventDefault();
        event.target.className += " was-validated";
    };

    changeHandler = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    rsv_save (name, value) {
        //alert(JSON.stringify(name));
        //alert(JSON.stringify(value));
        var rsv = this.state.bro;
        var na = name.split(".");
        rsv[na[0]][na[1]] = value;
        this.setState({rsv: rsv});
        //setState({[name]: value});
    }
    val_save (name, value) {
        //alert(JSON.stringify(name));
        //alert(JSON.stringify(value));
        var bro = this.state.bro;
        bro[name] = value;
        this.setState({bro: bro});
        //setState({[name]: value});
    }
    val_nrr_save (name, value) {
        //alert(JSON.stringify(name));
        //alert(JSON.stringify(value));
        var bro = this.state.bro;
        bro[name] = value;
        if(bro.nrg < bro.nrr) bro.nrg = bro.nrr;
        
        var nrr = (value * 1);
        var pris = (this.state.pris.replaceAll(".", "") * 1);
        var fdd = new Date(this.state.bro.fdat);
        var tdd = new Date(this.state.bro.tdat);
        var days = differenceInCalendarDays(tdd, fdd);
        var sum = pris * nrr * days;
        this.setState({bro: bro, sum: sum});
        //setState({[name]: value});
    }
    val_pris_save (value) {
        var pris = (value.replaceAll(".", "") * 1);
        var nrr = (this.state.bro.nrr * 1);
        var fdd = new Date(this.state.bro.fdat);
        var tdd = new Date(this.state.bro.tdat);
        var days = differenceInCalendarDays(tdd, fdd);
        var sum = pris * nrr * days;
        this.setState({pris: value, sum: sum});
    }
    val_dt_save (name, value) {
        //alert(JSON.stringify(name));
        //alert(JSON.stringify(value));
      var bro = this.state.bro;
     //var dts = format(value, "yyyy-MM-dd");
     bro[name] = value;
      this.setState({bro: bro});
      //setState({[name]: value});
    }
    val_fd_save (dts) {
      var bro = this.state.bro;
      //var dts = format(value, "yyyy-MM-dd");
      if(dts < this.state.tds) dts = this.state.tds;
      bro.fdat = dts;
      if(bro.tdat < dts) {
          var tdd = new Date(dts);
          var tdat = addDays(tdd, 1);
          bro.tdat = format(tdat, "yyyy-MM-dd");
          //alert(dts);
          //bro.tdat = dts;
      }
    var fdd = new Date(bro.fdat);
    var tdd = new Date(bro.tdat);
    var days = differenceInCalendarDays(tdd, fdd);
    var pris = (this.state.pris.replaceAll(".", "") * 1);
    var sum = pris * (bro.nrr * 1) * days;
      this.setState({bro: bro, sum: sum});
      this.bel_rt();
    }
    val_td_save (dts) {
      var bro = this.state.bro;
      //var dts = format(value, "yyyy-MM-dd");
      if(dts < this.state.tds) dts = this.state.tds;
      if(dts < bro.fdat) {
          //var dts = format(dts, "yyyy-MM-dd");
          bro.fdat = dts;
      }
      bro.tdat = dts;
        var fdd = new Date(bro.fdat);
        var tdd = new Date(bro.tdat);
        var days = differenceInCalendarDays(tdd, fdd);
        var pris = (this.state.pris.replaceAll(".", "") * 1);
        var sum = pris * (bro.nrr * 1) * days;
        this.setState({bro: bro, sum: sum});
    }
    rt_set (name, e) {
      //const value = e.target.value;
      const value = e;
      var bro = this.state.bro;
      bro[name] = value;
      var pris = this.pk_price(bro);
        var fdd = new Date(this.state.bro.fdat);
        var tdd = new Date(this.state.bro.tdat);
        var days = differenceInCalendarDays(tdd, fdd);
      var sum = (this.state.bro.nrr * 1) * (pris.replaceAll(".", "") * 1) * days;
      this.setState({bro: bro, pris: pris, sum: sum});
      //setState({[name]: value});
    }
    pkat_set (e) {
      const value = e.target.value;
      var pks = this.state.pks;
      var pko = pks[value];
      this.setState({pkat: pko.n0typ, pko: pko});
      //setState({[name]: value});
    }
    pkk_set (e) {
      const value = e.target.value;
      //var pks = this.state.pks;
      //var pko = pks[value];
      var pko = {};
      var pris = "";
      for(var row of this.state.pkk) {
        if(value == row.n0typ) {
            pko = row;
            pris = row.pris.cdipris();
        }
      }
        var fdd = new Date(this.state.bro.fdat);
        var tdd = new Date(this.state.bro.tdat);
        var days = differenceInCalendarDays(tdd, fdd);
      var sum = (this.state.bro.nrr * 1) * (pris.replaceAll(".", "") * 1) * days;
      this.setState({pkat: value, pko: pko, pris: pris, sum: sum});
      //setState({[name]: value});
    }
    pkd_set (name, e) {
      //const value = e.target.value;
      const value = e;
      var bro = this.state.bro;
      bro[name] = value;
      var pris = this.pk_price(bro);
      var pko = {};

      var fdd = new Date(this.state.bro.fdat);
      var tdd = new Date(this.state.bro.tdat);
      var days = differenceInCalendarDays(tdd, fdd);
      var sum = (this.state.bro.nrr * 1) * (pris.replaceAll(".", "") * 1) * days;
      
      this.setState({pkat: value, bro: bro, pko: pko, pris: pris, sum: sum});
      //setState({[name]: value});
    }
    selrt (rto) {
      var bro = this.state.bro;
      bro.rt = rto.rt;
      var pris = this.pk_price(bro);
        var fdd = new Date(this.state.bro.fdat);
        var tdd = new Date(this.state.bro.tdat);
        var days = differenceInCalendarDays(tdd, fdd);
        var sum = (this.state.bro.nrr * 1) * (pris.replaceAll(".", "") * 1) * days;
        this.setState({bro: bro, pris: pris, sum: sum});
      //this.setState({bro: bro});
    }
    selrtfd (rto, dto) {
        var dat = parseISO(dto.datum, "yyyyMMdd");
        var fdat = format(dat, "yyyy-MM-dd");
        dat = addDays(dat, 1);
        var tdat = format(dat, "yyyy-MM-dd");

      var bro = this.state.bro;
      bro.rt = rto.rt;
      bro.fdat = fdat;
      bro.tdat = tdat;
      var pris = this.pk_price(bro);
        var fdd = new Date(this.state.bro.fdat);
        var tdd = new Date(this.state.bro.tdat);
        var days = differenceInCalendarDays(tdd, fdd);
      var sum = (this.state.bro.nrr * 1) * (pris.replaceAll(".", "") * 1) * days;
      this.setState({bro: bro, pris: pris, sum: sum});
      //this.setState({bro: bro});
        this.dt_fd.current.updateDt(fdat);
        this.dt_td.current.updateDt(tdat);
    }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  bel_style() {
      var h = window.innerHeight;
      ///var h = (window.innerHeight * 0.95);
      var css = {};
      //css.height = (h * 0.2);
      css.height = (h - 550);
      css.maxheight = (h - 550);
      css.overflow = "auto";
      return css;
  }
  dt_class(dto) {
      var cls = "";
      if(dto.wday == 5) cls = "weekend";
      if(dto.wday == 6) cls = "weekend2";
      return cls;
  }
  get_css(dto) {
      var cls = "";
      if(dto.wday == 5) cls = "weekend";
      if(dto.wday == 6) cls = "weekend2";
      return cls;
  }
  dt_fdat(e) {
    //alert(JSON.stringify(e));
    var dts = format(e, "yyyy-MM-dd");
    this.setState({ fdat: dts });
  }
  dt_tdat(e) {
      //alert(JSON.stringify(e));
    var dts = format(e, "yyyy-MM-dd");
    this.setState({ tdat: dts });
  }
  oneday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_td.current.updateDt(this.state.fdat);
  }
  addday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_fd.current.updateAdd();
    this.dt_td.current.updateAdd();
  }
  subday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_fd.current.updateSub();
    this.dt_td.current.updateSub();
  }

  br_init() {
    var self = this;
    var dat = addDays(this.state.today, 1);
    var fdat = format(this.state.today, "yyyy-MM-dd");
    var tdat = format(dat, "yyyy-MM-dd");

    var bro = {};
    bro.fdat = fdat;
    bro.tdat = tdat;
    bro.nrr = "1";
    bro.nrg = "1";
    bro.nrgr = "1";
    bro.nrb0 = "0";
    bro.nrb1 = "0";
    bro.nrb2 = "0";
    bro.rt = "";
    bro.pkat = "";
    bro.rab = "";

    if(true) {
        var pkat = "F";
        var pka = this.state.pkd;
        var pko = this.state.pkd[0];
        var pris = "7.800.00";
        var sum = (bro.nrr * 1) * (pris.replaceAll(".", "") * 1);
        this.setState({bro: bro, pka: pka, pko: pko, pkat: pkat, pris: pris, sum: sum});
    }
    else this.setState({bro: bro});
  }
  rt_get() {
    var self = this;
    //var nra = this.state.nra;
    var nra = [];
    nra.push( { val: "11", text: "NR 11" } );
    nra.push( { val: "22", text: "NR 22" } );
    nra.push( { val: "33", text: "NR 33" } );
    setTimeout(function(){
        self.setState({nra: nra });
      }, 3000);
  }
  dd_init() {
    var self = this;
    //var fdat = parseISO(this.state.bro.fdat, "yyyy-MM-dd");
    var fdat = this.state.today;
    var fdatum = format(fdat, "yyyy-MM-dd");
    var tdat = addDays(fdat, 15);
    var tdatum = format(tdat, "yyyy-MM-dd");

    var prm = {};
    prm.req = "pms.cal.pms_cal_rt";
    prm.fdat = fdat;
    prm.tdat = tdat;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok == "000") {
            var rta = [];
            var keys = Object.keys(ret.rta);
            for(var key of keys) {
                var rto = ret.rta[key];
                //alert(JSON.stringify(key));

                var o = {};
                o.rt = key;
                o.dta = [];

                var dkeys = Object.keys(rto);
                for(var dkey of dkeys) {
                    var dto = rto[dkey];
                    //alert(JSON.stringify(dkey));
                   o.dta.push(dto);
                };
                rta.push( o );
            };
            var bro = self.state.bro;
            bro.rt = rta[0].r1typ;

            self.setState({caldata: ret.cal, rtdata: rta, bro: bro });
            //self.setState({caldata: ret.rco});
        }

        var prm = {};
        prm.req = "pms.rt.rt_date";
        return net.sio_req(prm);
    })
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok == "000") {
            var bro = self.state.bro;
            bro.rt = ret.rco[0].r1typ;

            self.setState({bro: bro, rta: ret.rco});
        }

        var prm = {};
        prm.req = "pms.pkat.qa_pkats";
        return net.sio_req(prm);
    })
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok == "000") {
            //self.setState({pka: ret.rca, pks: ret.rco});
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  bel_rt() {
    var self = this;
    //var fdat = format(this.state.fdat, "yyyy-MM-dd");
    var dat = parseISO(this.state.bro.fdat);
    var fdat = format(dat, "yyyy-MM-dd");
    dat = addDays(dat, 15);
    var tdat = format(dat, "yyyy-MM-dd");

    var prm = {};
    prm.req = "pms.cal.pms_cal_rt";
    prm.fdat = fdat;
    prm.tdat = tdat;
    //prm.avd = this.state.avd;

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        //alert(JSON.stringify(ret.rta));
        var rta = [];
        var keys = Object.keys(ret.rta);
        for(var key of keys) {
            var rto = ret.rta[key];
            //alert(JSON.stringify(key));

            var o = {};
            o.rt = key;
            o.dta = [];

            var dkeys = Object.keys(rto);
            for(var dkey of dkeys) {
                var dto = rto[dkey];
                //alert(JSON.stringify(dkey));
               o.dta.push(dto);
            };
            rta.push( o );
        };

        self.setState({caldata: ret.cal, rtdata: rta, loading: false });
        //self.syncCols("rtTable");
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e) {
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }
  bel() {
    var self = this;
    //var fdat = parseISO(this.state.bro.fdat, "yyyy-MM-dd");
    var fdat = this.state.today;
    var fdatum = format(fdat, "yyyy-MM-dd");
    var tdat = addDays(fdat, 15);
    var tdatum = format(tdat, "yyyy-MM-dd");

    var prm = {};
    prm.req = "pms.cal.pms_cal_state";
    prm.fdat = fdat;
    prm.tdat = tdat;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            return;
        }
        self.setState({cal: ret.rco});
        self.pkats();
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  rts() {
    var self = this;
    var prm = {};
    prm.req = "pms.rt.rt_date";
    //prm.datum = this.state.datum;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            return;
        }
        self.setState({rta: ret.rco});
        self.pkats();
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  pkats() {
    var self = this;
    var prm = {};
    prm.req = "pms.pkat.qa_pkats";
    //prm.datum = this.state.datum;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            return;
        }
        self.setState({pka: ret.rca, pks: ret.rco});
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

  br_add() {
    var self = this;

    if(this.state.bro.rt == "") {
        alert("Rumstyp saknas");
        return;
    }
    if(!this.state.pko.n0typ) {
        alert("Priskategori saknas");
        return;
    }

    var prm = {};
    prm.req = "pms.br.br_test";
    prm.boknr = this.state.boknr;
    prm.rt = this.state.bro;
    prm.nyp00 = this.state.pko;
    prm.pris = this.state.pris;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            //alert(JSON.stringify(ret.ok + " : " + ret.info));
            var msg = { confirm: true, text: ret.info + ", överboka?", text0: "JA", text1: "NEJ", cb: "br_add_force"};
            self.setState({ msg: msg });
            return;
        }
        if(ret.ok == "000") {
            self.br_add_do();
            return;
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
    br_add_force(val) {
        var self = this;

        self.setState({msg: {} });
        if(val == 0) {
            self.br_add_do();
            return;
        }
    }
    br_add_do() {
        var self = this;

        if(this.state.bro.rt == "") {
            alert("Rumstyp saknas");
            return;
        }
        if(!this.state.pko.n0typ) {
            alert("Priskategori saknas");
            return;
        }

        var prm = {};
        prm.req = "pms.br.br_add";
        prm.boknr = this.state.boknr;
        prm.rt = this.state.bro;
        prm.nyp00 = this.state.pko;
        prm.pris = this.state.pris;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret));

                if(ret.ok == "000") {
                    self.props.cb(self.props.ctx, ret);
                    return;
                }
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "Delbokning";
    //if(props.kro) alert(props.kro.krr00.kid);
    const popover = {
      position: 'absolute',
      top: '50px',
      zIndex: '2',
    }
    const cover = {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    }

	var html =
        <div className="mx_base">
        <div className="m2_backdrop" />
        <div className="m2_dlg mx_80 flex-col" style={ this.dlg_style() } draggable="true" >

        <div className="mx_h01">
            <div>NY DELBOKNING</div>
        </div>
        <div className="mx_content px-2 flex">

            <div className="" style={{border: "0px", margin: "0px", padding: "0px"}}>
           <table className="table table-bordered tb_pres" style={{border: "0px", margin: "0px", padding: "0px"}}>
            <thead>
            <tr>
                { this.state.caldata.map((dto, key) =>
                    <th className="text-white" key={dto.dt} onClick={ () => this.set_di(dto) }>{ dto.dt }</th>
                )}
            </tr>
            </thead>

            <tbody>
            <tr className=''>
                { this.state.caldata.map((dto, key) =>
                    <td key={dto.dt}>{ dto.belag }</td>
                )}
            </tr>
            </tbody>
  
            </table>
            </div>

            <div className="divider_orange"></div>

            <form className="cdi_f03 flex">
            <div className="flex-row py-2">
                <Fr_Cal label="Från" className="text-center w250" min="1" text={this.state.bro.fdat} getValue={(e)=> this.val_fd_save( e ) }/>
                <Fr_Cal label="Till" className="text-center w250" min="1" text={this.state.bro.tdat} getValue={(e)=> this.val_td_save( e ) }/>
            </div>

            <div className="flex-row py-2">
                    <Fr_Input label="Antal Rum" className="text-center w150" text={this.state.bro.nrr} getValue={(e)=> this.val_nrr_save("nrr", e) } />
                    <Fr_Input label="Antal Gäster" className="text-center w150" text={this.state.bro.nrg} getValue={(e)=> this.val_save("nrg", e) } />
            </div>
            <div className="flex-row py-2 hide">
                    <Fr_Inpsel label="RT" className="text-left w250"
                               text={this.state.bro.nrr}
                               items={this.state.nra}
                               getValue={(e)=> this.val_save("nrr", e) }
                               onClick={() => this.rt_get() }
                    />
            </div>
            <div className="flex-row py-2">
                    <Fr_Input label="Barn (0-4)" className="text-center w150" text={this.state.bro.nrb0} getValue={(e)=> this.val_save("nrb0", e) } />
                    <Fr_Input label="Barn (5-8)" className="text-center w150" text={this.state.bro.nrb1} getValue={(e)=> this.val_save("nrb1", e) } />
            </div>
            <div className="divider_green"></div>
            <div className="flex-row py-2">
                <Fr_Inprts label="Rumstyp" className="text-center w350"
                           text={this.state.bro.rt}
                           items={this.pms.rta}
                           getValue={(e)=> this.rt_set("rt", e) }
                />
                <Fr_Inpkats label="Priskategori" className="text-center w250"
                           text={this.state.pkat}
                           items={this.pms.pka}
                           getValue={(e)=> this.pkd_set("pkat", e) }
                />

                <div className="form-group flex-row flex hide">
                        <span className="header">Rumstyp</span>
                        <select value={this.state.bro.rt} className="form-control text-center w350" onChange={(e) => this.rt_set("rt", e)}>
                            { this.state.rta.map((row, key) =>
                            <option key={key} value={row.r1typ}>{row.r1typ}</option>
                            )}
                        </select>
                </div>
                <div className="form-group flex-row flex hide">
                        <span className="header">Priskategori</span>
                        <select value={this.state.pkat} className="form-control text-center w350" onChange={(e) => this.pkd_set(e)}>
                            { this.state.pkd.map((row, key) =>
                            <option key={key} value={row.n0typ}>{row.text}</option>
                            )}
                        </select>
                </div>
                <div className="form-group flex-row flex hide">
                        <span className="header">Priskategori</span>
                        <select value={this.state.pkat} className="form-control w350" onChange={(e) => this.pkat_set(e)}>
                            { this.state.pkk.map((row, key) =>
                            <option key={key} value={row.n0typ}>{row.text} : {row.pris.cdipris()}</option>
                            )}
                        </select>
                </div>
                <div className="form-group flex-row flex hide">
                        <span className="header">Priskategori</span>
                        <select value={this.state.pkat} className="form-control w350" onChange={(e) => this.pkat_set(e)}>
                            { this.state.pka.map((row, key) =>
                            <option key={key} value={row.n0typ}>{row.n0typ} - {row.n0text}</option>
                            )}
                        </select>
                </div>
            </div>
            <div className="flex-row py-2">
                <div className="flex"></div>
                <Fr_Input label="Pris" className="text-right w250" text={this.state.pris} getValue={(e)=> this.val_pris_save(e) } />
            </div>
            </form>

        </div>

        <div className="divider_green"></div>
        <div className="flex-row py-2">
            <div className="flex"></div>
            <span className="form-group flex-row txt-16 px-5">Summa delbokning: { utils.toPris( this.state.sum ) }</span>
        </div>

        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                AVBRYT
            </button>
            <div className="flex-row">
            <button className="btn btn-hw-red w250" onClick={() => this.br_add() } >
                LÄGG TILL
            </button>
            </div>
        </div>
      </div>
            <Wnd_Error msg={this.state.msg} ctx={this} />
            <Wnd_Ok msg={this.state.msg} ctx={this} />
            <Wnd_Info msg={this.state.msg} ctx={this} />
            <Wnd_Confirm msg={this.state.msg} ctx={this} />
      </div>
	;
	return html;
  };
}

export default Br_New;

/*
            <div className="flex-row py-2 hide">
                  <div className="flex-col">
                      <div className="dt-btn-01" onClick={() => this.subday() } ><FontAwesomeIcon icon="minus" size="1x" /></div>
                </div>
                  <div className="flex-col">
                      <Dt_Cal dts={this.state.fdat} rp="1" ref={this.dt_fd} getValue={(e)=> this.dt_fdat(e)} />
               </div>
                   <div className="flex-col">
                      <div className="dt-btn-01" onClick={() => this.oneday() } >1</div>
                </div>
                 <div className="flex-col">
                      <Dt_Cal dts={this.state.tdat} rp="1" ref={this.dt_td} getValue={(e)=> this.dt_tdat(e)} />
                </div>
                  <div className="flex-col">
                      <div className="dt-btn-01" onClick={() => this.addday() } ><FontAwesomeIcon icon="plus" size="1x" /></div>
                </div>
            </div>

*/