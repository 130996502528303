import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";

import * as utils from "../../assets/utils";
import ddo from '../../ddo.js'
import sso from "../../sso";

import Td_Sel from "../../lib/ui/td_sel";
import { timeout } from 'q';

class Tmpl_Support extends React.Component {
  constructor(props, context) {
    super(props);

    //this.pos = props.store.pos;
    this.state = {};
    this.state.lsa = [];
    this.state.lso = {};
    //this.state.lro = props.lro;
    this.state.tya = [
                {id: '01', text: 'Summerad'},
                {id: '02', text: 'Dag för dag'}
            ];
    this.state.aa = [];
      this.state.cols = {};

      this.state.selavd = ddo.cache.avd;
      this.state.dpo = {};
      this.state.dpa = [];

      this.state.tsa = [];
      
      this.state.xsa = [

      ];
      this.state.csa = [
        { inx: 'S00000011', id: '11', status: '0009', enhet: 'ALLA', text: 'Swishdiffar', reg: '20231201', due: '20231231', upd: '20240131', notes: "Klar-rapporten från Swish har inte kommit till systemet. Insats för att informera användarna att det finns kvitton/köp som inte slagits ut på betalsätt. Sverigekassan lägger in en varning om att en betlaning inte bokats med betalsätt. Det ska inte gå att göra kassaavslut när det finns öppna kvitton, utan betalsätt. Grundproblemet ska lösas, bör vara löst nästa vecka( v49). Från januar kommer inte problemet att finnas kvar." },
        { inx: 'S00000012', id: '12', status: '0009', enhet: 'KFT', text: 'Kassan hänger sig', reg: '20231201', due: '20231231', upd: '20240212', notes: "Kassorna ska inte släckas ned och ska inte behöva startas om. Användare ska i första hand ringa supporten när problet uppstår, innan omstart. Problemet kommer inte att kvarstå i januari." },
        { inx: 'S00000013', id: '13', status: '0009', enhet: 'LINDÄLV', text: 'Fel på skrivare', reg: '20231201', due: '20231215', upd: '20231215', notes: "Kommunens IT avdelning har tilldelat adresser så skrivarna ej byter adress och därmed ej kan nås." },
        { inx: 'S00000014', id: '14', status: '0009', enhet: 'FAMILJERÅDGIVNINGEN', text: 'Diffar', reg: '20231201', due: '20231212', upd: '20231212', notes: "" },
        { inx: 'S00000015', id: '15', status: '0009', enhet: 'SMEDINGE', text: 'Kassan stänger ner, display svart', reg: '20231201', due: '20231231', upd: '20240131', notes: "", solution: "Kortterminal felanmäld och garanti utbytt" },
        { inx: 'S00000016', id: '16', status: '0009', enhet: 'UIK Särö', text: 'Rapporter fel kostnadsställe UIK Särö', reg: '20231201', due: '20231208', upd: '20231211', notes: "" },
        { inx: 'S00000017', id: '17', status: '0005', enhet: 'ALLA', text: 'Genomgång av rapporter', reg: '20231201', due: '', upd: '20231201', notes: "" },
        { inx: 'S00000018', id: '18', status: '0009', enhet: 'ALLA', text: 'Kontroll stavning enheter', reg: '20231201', due: '20231231', upd: '20240212', notes: "Sedan är vissa försäljningsställen mm felstavat i administratörsportalen, ingen stor sak men bör vara rätt." },
        { inx: 'S00000019', id: '19', status: '0006', enhet: 'ALLA', text: 'Kontroll av rapporter', reg: '20231201', due: '20231231', upd: '20231201', notes: "Bokstäverna å, ä och ö fungerar inte på rapporterna. På vissa rapporter står inte aktuellt försäljningsställe." },
        { inx: 'S00000020', id: '20', status: '0009', enhet: 'Naturrum', text: 'Dubbelbetalning Naturrum', reg: '20231201', due: '20231201', upd: '20231201', notes: "Problemet åtgärdat" },
        { inx: 'S00000021', id: '21', status: '0009', enhet: 'Linden/Servicelaget', text: 'Kassan svart och går ej att starta', reg: '20231201', due: '20231201', upd: '20231201', notes: "Strömsparläge åtgärdat" },
        { inx: 'S00000022', id: '22', status: '0009', enhet: 'Linden/Servicelaget', text: 'Meny korsad', reg: '20231201', due: '20231201', upd: '20231201', notes: "Åtgärdat" },
        { inx: 'S00000023', id: '23', status: '0009', enhet: 'Kullaviks Bibliotek', text: 'Återköps kontroll', reg: '20231201', due: '20231231', upd: '20240131', notes: "Kommer att lösa sig när lösningen med att inte kunna göra slutrapporten utan att alla betalningar är bokade korekt. Spärr på återköp endas 1 gång." },
        { inx: 'S00000024', id: '24', status: '0009', enhet: 'Ishallen', text: 'Försäljningsstatisksrapporten kommer ej fram', reg: '20231201', due: '20231231', upd: '20231201', notes: "Fungerar vid kontroll. Ishallen kontaktas för genomgång och instruktion" },
        { inx: 'S00000025', id: '25', status: '0009', enhet: 'Ishallen', text: 'Momssats fel', reg: '20240105', due: '20240116', upd: '20240116', notes: "Felaktigmomssats definierad på Ishallens produkter", solution: "Momssats uppdaterad på samtliga produkter" },
        { inx: 'S00000026', id: '26', status: '0009', enhet: 'Ishallen', text: 'Saknad ID på swishtransaktion', reg: '20240119', due: '20240229', upd: '20240305', notes: "ID saknas på swish transaktion från termnal T0101150", solution: "Genomgång och koppling av terminal mot ID" },
        { inx: 'S00000027', id: '27', status: '0009', enhet: 'LINDÄLV', text: 'Bong kommer ej', reg: '20240221', due: '20240223', upd: '20240301', notes: "Bong kommer ej fram till skrivare", solution: "Strömavbrott som nollställde switchar. Kungsbacka IT har åtgärdat." },
        { inx: 'S00000028', id: '28', status: '0009', enhet: 'Turistifo', text: 'Presentkort', reg: '', due: '20240430', upd: '20240424', notes: "Text på X-rapport. Det står Ticketmaster som omfattar både biljetter och Presentkort. Svårt att stämma av kassan.", solution: "" },
        { inx: 'S00000029', id: '29', status: '0009', enhet: 'Turistifo', text: 'Service avgift', reg: '', due: '20240430', upd: '20240424', notes: "Texten på kundkvitto: Serviceavgifter annan avdelning  Moms 25%", solution: "" },
        { inx: 'S00000030', id: '30', status: '0009', enhet: 'Turistifo', text: 'Naturpass', reg: '', due: '20240430', upd: '20240424', notes: "Text på kvitto naturguidning enligt Birgitta. Ligger på fel varugrupp.", solution: "" },
        { inx: 'S00000031', id: '31', status: '0009', enhet: 'Turistifo', text: 'Fördelning', reg: '', due: '20240430', upd: '20240424', notes: "Diverse fördelningar.", solution: "Amanuel uppdaterar önskade fördelningar" },
        { inx: 'S00000032', id: '32', status: '0006', enhet: 'ALLA', text: 'Rapporter', reg: '', due: '20240430', upd: '20240424', notes: "Svensak tecken, å, ä, ö ej korrekt på alla rapporter", solution: "Kontroller pågår" },
        { inx: 'S00000033', id: '33', status: '0009', enhet: 'ALLA', text: 'American Express', reg: '', due: '20240430', upd: '20240424', notes: "American Express tas ej emot.", solution: "Betalsätt borttaget" },
        { inx: 'S00000034', id: '34', status: '0006', enhet: 'ALLA', text: 'Rapporter', reg: '', due: '20240430', upd: '20240424', notes: "Vid export till excel saknas på vissa försälnings enhet.", solution: "Kontroll och ändring pågår" },
        { inx: 'S00000035', id: '35', status: '0005', enhet: 'Skatehallen', text: 'Medlemskort', reg: '', due: '20240430', upd: '20240424', notes: "Medlemskort önskas till Skatehallen", solution: "Kostnader samt lösning presenteras" },
        { inx: 'S00000036', id: '36', status: '0009', enhet: 'Äskhult', text: 'Terminal', reg: '', due: '20240430', upd: '20240424', notes: "Trögstartad terminal", solution: "Amanuel kontrollerar med Julia" },
        { inx: 'S00000037', id: '37', status: '0009', enhet: 'Lindälv', text: 'Terminal', reg: '', due: '20240530', upd: '20240509', notes: "Bongskrivare får ej rätt adress", solution: "Amanuel sätter om bongskrivarna" },
    ];
    

    this.state.showstatus = "";
    this.state.sta = [
        {status: '', text: 'ENDAST AKTIVA'},
        {status: '0009', text: 'KLARA'},
        {status: '0006', text: 'VERIFIERAS'},
        {status: '0005', text: 'BEARBETAS'},
        {status: '0003', text: 'PÅBÖRJADE'},
        {status: '0001', text: 'TILLDELADE'},
        {status: '0000', text: 'EJ STARTADE'}
    ];

    //alert(JSON.stringify(ddo.cache));
      //alert(JSON.stringify(this.state.dpa));

    this.state.today = new Date();

    this.state.sortid = "";
    this.state.sortdir = 1;
    this.state.showanm = 1;
    this.state.showsol = 1;
    this.state.pdf = 0;
    this.state.pdf_content = null;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      //alert(JSON.stringify(ddo.cache));
      this.syncTb("tsaTable");
      this.sups();
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    tab_style(tabid) {
      var css = {};
      css.display = "none";
      if(this.state.tabval === tabid) css.display = "block";
      return css;
    }
    tab_head(tabid) {
        var css = {};
        css.background = "#fff";
        css.color = "#000";
        if(this.state.tabval === tabid) {
            css.background = "#080";
            css.color = "#fff";
        }
        return css;
    }
    change_avd(e) {
        //let cdat = this.state.seldate;0
        let key = e.target.value;
        let dpo = ddo.cache.deps[key];
        this.setState({dpo: dpo});
    }
    avd_sel(row) {
        var self = this;
        this.setState({ dpo: row }, function() {
            ddo.cache.avd = row.avd;
            ddo.cache.avdtxt = row.text;
            //alert(JSON.stringify(ddo.cache.avd));
            self.props.cb();
        });
    }

    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    handleClose = () => {
		this.props.ctx.setState({ rptyp: "" });
	};

    th_style(id) {
        var css = {};
        css.cursor = "pointer";
        if(id == this.state.sortid) css.color = "#080";
        return css;
    }
      tb_style() {
        var css = {};
        css.height = window.innerHeight - 275;
        return css;
    }
    row_style(row) {
        var css = {};
        css.display = "";
        if(this.state.showstatus.length > 0) {
            css.display = "none";
            if(row.status == this.state.showstatus) css.display = "";
        }
        else {
            if(row.status == "0009") css.display = "none";
        }
        return css;
    }
    anm_style() {
        var css = {};
        css.background = "#fff";
        css.color = "#222";
        if(this.state.showanm == 0) {
            css.background = "#800";
            css.color = "#fff";
        }
        return css;
    }
    sol_style() {
        var css = {};
        css.background = "#fff";
        css.color = "#222";
        if(this.state.showsol == 0) {
            css.background = "#800";
            css.color = "#fff";
        }
        return css;
    }
    sel_unit() {
        var dpo = this.state.dpo;
        if(!dpo) {
            this.props.cb();
            return;
        }
        ddo.cache.avd = dpo.avd;
        ddo.cache.avdtxt = dpo.text;
        //alert(JSON.stringify(dpo.avd));
        this.props.cb();
    }
    wnd_close() {
        //this.props.ctx.setState({ wnds: {} });
        this.props.cb();
    };
    task_edit(row) {
    };

    tasks() {
        var self = this;
        var prm = {};
        prm.req = "bcs.sup.sups";
        //prm.datum = this.state.datum;
    
        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret));
    
            if(ret.ok != "000") {
                return;
            }
            self.setState({tsa: ret.rca});
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
      }
      syncTb(tb){
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
        tdElements = tbel.getElementsByTagName('td');

        for (let i = 0; i < thElements.length; i++) {
            if(!thElements[i] || !tdElements[i]) continue;
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
            width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
    change_status(e) {
        var self = this;
        //let cdat = this.state.seldate;
        let status = e.target.value;
        this.setState({showstatus: status}, function() {
            setTimeout(function(){
                self.syncTb("tsaTable"); 
            }, 500);
        });
    }

    sel_row_fkn(row, e) {
        var self = this;
    }
    set_anm() {
        var self = this;
        var showanm = 1;
        if(self.state.showanm == 1) showanm = 0;
        self.setState({ showanm: showanm });
    }
    set_sol() {
        var self = this;
        var showsol = 1;
        if(self.state.showsol == 1) showsol = 0;
        self.setState({ showsol: showsol });
    }
    tb_sort(typ) {
        var self = this;
        //var rvs = self.state.rvs;
        var tsa = self.state.tsa;
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;

        if(sortid != typ) sortdir = 0;
        else {
            if(sortdir) sortdir = 0;
            else sortdir = 1;
        }

        if(sortdir) tsa.sort((a,b) => (a[typ] < b[typ]) ? 1 : ((b[typ] < a[typ]) ? -1 : 0));
        else tsa.sort((a,b) => (a[typ] > b[typ]) ? 1 : ((b[typ] > a[typ]) ? -1 : 0));

        self.setState({ tsa: tsa, sortid: typ, sortdir: sortdir });
    }

    sups() {
        var self = this;
        var prm = {};
        prm.req = "sys.support.sup_open";
        prm.kid = "";
        //alert(JSON.stringify(prm));
        //sso.sdo.sid = "S0000091";

        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret));

            if(ret.ok == "000") {
                //alert(JSON.stringify(ret.rca));
                //self.setState({ msg: {ok: true, text: "Hämtat ..."} });
                self.setState({ tsa: ret.rca }, function() {
                    self.syncTb("tsaTable");
                });
            }
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }
    sup_gen() {
        var self = this;

        var prm = {};
        prm.req = "sys.support.sup_gen";
        prm.spa = self.state.csa;
        //alert(JSON.stringify(prm));
        sso.sdo.sid = "S0000091";

        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret));

            if(ret.ok == "000") {
                //alert(JSON.stringify(ret.rca));
                //self.setState({ msg: {ok: true, text: "Hämtat ..."} });
            }
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }

    sup_add() {
        var self = this;
        var spo = {};
        spo = self.state.xsa[3];
        //var s = JSON.stringify( spo );

        var prm = {};
        prm.req = "sys.support.sup_upd";
        prm.inx = "S00000004";
        prm.kid = "";
        prm.spo = spo;
        //alert(JSON.stringify(prm));
        sso.sdo.sid = "S0000091";

        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret));

            if(ret.ok == "000") {
                //alert(JSON.stringify(ret.rca));
                //self.setState({ msg: {ok: true, text: "Hämtat ..."} });
            }
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }

    sup_new() {
    }

    getStatus(row) {
        var txt = "";
        if(row.status == "0000") txt = "EJ PÅBÖRJAD";
        if(row.status == "0001") txt = "TILLDELAD";
        if(row.status == "0003") txt = "PÅBÖRJAD";
        if(row.status == "0005") txt = "BEARBETAS";
        if(row.status == "0006") txt = "VERIFIERAS";
        if(row.status == "0009") txt = "KLAR";
        return txt;
    }
  
  render() {
    //if(!this.props.wnds || !this.props.wnds.gtag) return "";
    var title = this.props.title;
    if(!title) title = "";

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="mx_backdrop" /> : null }
        <div className="mx_dlg mx_80h flex-col" style={ this.dlg_style() }>

        <div className="mx_head mx_bg flex-row flex-space-between">
            <div className="flex-col flex">
                <div>Support</div>
            </div>
        </div>

        <div className="mx_content flex px-3">
            <div className="flex-row"  style={{ cursor: "pointer" }}>
                <div className="flex-col flex-cc px-3">
                        <div className="" style={ this.anm_style() } onClick={() => this.set_anm() }>VISA ANMÄRKNING</div>
                    </div>
                    <div className="flex-col flex-cc px-3">
                        <div className="" style={ this.sol_style() } onClick={() => this.set_sol() }>VISA ÅTGÄRD</div>
                    </div>
                    <div className="flex"></div>
                    <div className="flex-col">
                    <select className="form-control w250" placeholder="Välj Status" value={this.state.showstatus} onChange={(e) => this.change_status(e)}>
                        { this.state.sta.map((row, key) =>
                        <option key={key} value={row.status}>{row.text}</option>
                        )}
                        </select>
                    </div>
                    <div className="flex"></div>

                    <div className="flex-col flex-cc">
                    </div>
                    <button className="btn btn-hw-red w150" onClick={() => this.sup_new() } >
                        LÄGG TILL
                    </button>
            </div>
            <div className="divider_orange"></div>


<div id="tsaTable" className="flex" style={{ overflow: "auto" }}>
  <table className="table table-bordered table-hover tb" style={{marginBottom: "0px"}}>
    <thead>
    <tr>
        <th style={ this.th_style("id") } onClick={() => this.tb_sort("id") }>ID</th>
        <th style={ this.th_style("status") } onClick={() => this.tb_sort("status") }>Status</th>
        <th style={ this.th_style("enhet") } onClick={() => this.tb_sort("enhet") }>Enhet</th>
        <th style={ this.th_style("text") } onClick={() => this.tb_sort("text") }>Text</th>
        <th style={ this.th_style("reg") } onClick={() => this.tb_sort("reg") }>Registrerat</th>
        <th style={ this.th_style("due") } onClick={() => this.tb_sort("due") }>Förfaller</th>
        <th style={ this.th_style("upd") } onClick={() => this.tb_sort("upd") }>Upd</th>
        <th></th>
    </tr>
    </thead>
</table>
<div style={ this.tb_style() }>
  <table className="table table-bordered table-hover tb">
    { this.state.tsa.map((row, key) =>
    <tbody key={key} style={ this.row_style(row) }>
    <tr onDoubleClick={() => this.task_edit(row) }>
        <td>{ utils.toNum( row.id ) }</td>
        <td>{ this.getStatus(row) }</td>
        <td>{ utils.toLang(row.enhet) }</td>
        <td>{ utils.toLang( row.text ) }</td>
        <td>{ utils.toDate( row.reg ) }</td>
        <td>{ utils.toDate( row.due ) }</td>
        <td>{ utils.toDate( row.upd ) }</td>
        <td>
        </td>
    </tr>
    { (this.state.showanm && row.notes) ?
        <tr>
            <td>Anm</td>
            <td colspan="6" style={{fontStyle: "italic", color: "#888"}}>{ utils.toLang( row.notes ) }</td>
        </tr>
    : null }
    { (this.state.showsol && row.solution) ?
        <tr>
            <td>Åtgärd</td>
            <td colspan="6" style={{fontStyle: "normal", color: "#080"}}>{ utils.toLang( row.solution ) }</td>
        </tr>
    : null }
    </tbody>
)}
</table>
</div>
</div>

</div>

        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w150" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex-row"></div>
            <button className="btn btn-hw-green w150 hide" onClick={(event) => this.sel_unit(event) } >
                SKRIV UT
            </button>
        </div>

      </div>
    </div>
	;
	return html;
  };
}

export default Tmpl_Support;
/*
            <Td_Sel items={ this.selitems } getValue={(e)=> this.sel_row_fkn(row, e)} />
*/