import React, { useState, useEffect, useContext } from 'react';

function Sal_Edit(props) {
    const [state, setState] = useState({ user : {}, tabval: 0 });

    function dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    function tab_style(tabid) {
      var css = {};
      css.display = "none";
      if(state.tabval === tabid) css.display = "block";
      return css;
    }
    function tab_head(tabid) {
      var css = {};
      css.background = "#fff";
      css.color = "#000";
      if(state.tabval === tabid) {
          css.background = "#080";
          css.color = "#fff";
      }
      return css;
    }
    function saveKid(event) {
        //this.setState({value: event.target.value});
        //const body = new FormData(this.form);
        //alert('FORM: ' + JSON.stringify(state));
        props.ctx.save_kid();
    }
    function handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = state.user;
      user[name] = value;
      setState({user: user});
      //setState({[name]: value});
    }
	const handleClose = () => {
		props.ctx.setState({ edit: false });
	};
    function tabChange (e) {
      setState({tabval: e.index});
    }
    if(!props.show) return "";
    var title = props.title;
    if(!title) title = "Lönekörning";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        { props.backdrop ? <div className="mx_backdrop" /> : null }
        <div className="mx_dlg_lg flex-col" style={ dlg_style() }>
        <div className="mx_title">
            { title }
        </div>
        <div className="mx_content flex">
        <div className="flex-row">
            <div className="tab_header flex" style={ tab_head(0) } onClick={ () => setState({tabval: 0}) }>FÖRETAG</div>
            <div className="tab_header flex" style={ tab_head(1) } onClick={ () => setState({tabval: 1}) }>ANMÄRKNING</div>
            <div className="tab_header flex" style={ tab_head(2) } onClick={ () => setState({tabval: 2}) }>VILLKOR</div>
        </div>
        <div style={ tab_style(0) }>
            <div className="flex-row">
            <div className="flex-col flex">
                    <div className="form-group">
                        <label>Företag</label>
                        <input type="text" className="form-control" value={props.kro.krr00.company.toswe()} onChange={(event) => handleUserInput(event)} />
                    </div>
                    <div className="form-group">
                        <label>Namn</label>
                        <input type="text" className="form-control" value={props.kro.krr00.namn.toswe()} onChange={(event) => handleUserInput(event)} />
                    </div>
                    <div className="form-group">
                        <label>Postadress</label>
                        <input type="text" className="form-control" value={props.kro.krr01.adress1.toswe()} onChange={(event) => handleUserInput(event)} />
                    </div>
                    <div className="form-group">
                        <label>Gatuadress</label>
                        <input type="text" className="form-control" value={props.kro.krr01.adress2.toswe()} onChange={(event) => handleUserInput(event)} />
                    </div>
                    <div className="form-group">
                        <label>Postnr</label>
                        <input type="text" className="form-control" value={props.kro.krr02.postnr} onChange={(event) => handleUserInput(event)} />
                    </div>
                    <div className="form-group">
                        <label>Land</label>
                        <input type="text" className="form-control" value={props.kro.krr02.land.toswe()} onChange={(event) => handleUserInput(event)} />
                    </div>
            </div>
            <div className="flex-col flex">
                    <div className="form-group">
                        <label>Telefon</label>
                        <input type="text" className="form-control" value={props.kro.krr03.tfn} onChange={(event) => handleUserInput(event)} />
                    </div>
                    <div className="form-group">
                        <label>Email</label>
                        <input type="text" className="form-control" value={props.kro.krr03.email} onChange={(event) => handleUserInput(event)} />
                    </div>
                    <div className="form-group">
                        <label>Orgnr</label>
                        <input type="text" className="form-control" value={props.kro.krr02.orgnr} onChange={(event) => handleUserInput(event)} />
                    </div>
                    <div className="form-group">
                        <label>Mobil</label>
                        <input type="text" className="form-control" value={props.kro.krr03.mobil} onChange={(event) => handleUserInput(event)} />
                    </div>
                    <div className="form-group">
                        <label>Ort</label>
                        <input type="text" className="form-control" value={props.kro.krr02.city.toswe()} onChange={(event) => handleUserInput(event)} />
                    </div>
            </div>
            </div>
            </div>

            <div style={ tab_style(1) }>
                <div className="form-group">
                    <label>Anmärkning</label>
                    <textarea rows="10" className="form-control" value={props.kro.krr02.city.toswe()} onChange={(event) => handleUserInput(event)} />
                </div>
            </div>

            <div style={ tab_style(2) }>
                    <div className="form-group">
                        <label>Betallvillkor</label>
                        <input type="text" className="form-control" value={props.kro.krr00.betvillkor} onChange={(event) => handleUserInput(event)} />
                    </div>
                    <div className="form-group">
                        <label>Påminnelse</label>
                        <input type="text" className="form-control" value={props.kro.krr00.reminder} onChange={(event) => handleUserInput(event)} />
                    </div>
                    <div className="form-group">
                        <label>Ränta</label>
                        <input type="text" className="form-control" value={props.kro.krr00.interest} onChange={(event) => handleUserInput(event)} />
                    </div>
            </div>
        </div>
        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w150 ripple" onClick={() => handleClose() } >
                STÄNG
            </button>
            <button className="btn btn-hw-green w150 ripple" onClick={(event) => saveKid(event) } >
                SPARA
            </button>
        </div>
      </div>
    </div>
	;
	return html;
}

export default Sal_Edit;