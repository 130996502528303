import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";
import Fr_Input from "../../lib/ui/fr_input";
import Fr_Cal from "../../lib/ui/fr_cal";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Bo_Ftg extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = {};
    this.state.seldate = new Date();
    this.state.avd = "01";
    this.state.paa = [];
      this.state.aa = [];
      this.state.wfp = {};
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      this.fak_prm();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 480;
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

    fak_prm() {
        var self = this;
        var prm = {};
        prm.req = "fakt.fakt_prm.prm";
        prm.faknr = this.state.faknr;
        prm.faktnr = this.state.faknr;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.rco));

                self.setState({ wfp: ret.wfp });
                //self.syncTb("fakTable");
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    fak_prm_save() {
        var self = this;

        var wfp = this.state.wfp;

        var prm = {};
        prm.req = "fakt.fakt_prm.prm_save";
        prm.wfp = this.state.wfp;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret));
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    val_save(id, val) {
        var wfp = this.state.wfp;
        //var id1 = id.split(".")[0];
        //var id2 = id.split(".")[1];
        //wfh[id1][id2] = val;
        wfp[id] = val;
        this.setState({ wfp: wfp });
        return;
    }

  render() {
    return (
        <div className="web-app">

            <div className="flex-row">
                <div className="label_h03">Företag</div>
                <div className="flex"></div>
                <button className="btn btn-hw-orange w150" onClick={() => this.fak_prm_save() } aria-label="System" >
                    Spara
                </button>
                <button className="btn btn-hw-blue w150" onClick={() => this.fak_prm() } aria-label="System" >
                    Visa
                </button>
            </div>
            <div className="divider_green"></div>

            <form className="cdi_f03">
                <div className="flex-row">
                    <Fr_Input label="Företag" className="text-left w350" text={this.state.wfp.Company} getValue={(e)=> this.val_save("Company", e) } />
                    <Fr_Input label="Orgnr" className="text-left w250" text={this.state.wfp.Org_nr} getValue={(e)=> this.val_save("Org_nr", e) } />
                </div>
                <div className="flex-row">
                    <Fr_Input label="Momsnr" className="text-left w150" text={this.state.wfp.Moms_nr} getValue={(e)=> this.val_save("Moms_nr", e) } />
                </div>
                <div className="flex-row">
                    <Fr_Input label="Telefon" className="text-left w350" text={this.state.wfp.Tfn} getValue={(e)=> this.val_save("Tfn", e) } />
                    <Fr_Input label="Fax" className="text-left w250" text={this.state.wfp.Fax} getValue={(e)=> this.val_save("Fax", e) } />
                </div>
                <div className="flex-row">
                    <Fr_Input label="Mail" className="text-left w350" text={this.state.wfp.mail} getValue={(e)=> this.val_save("mail", e) } />
                    <Fr_Input label="Web" className="text-left w250" text={this.state.wfp.web} getValue={(e)=> this.val_save("web", e) } />
                </div>
                <div className="divider_green"></div>
                <div className="flex-row">
                    <Fr_Input label="Postadress" className="text-left w350" text={this.state.wfp.Adress1} getValue={(e)=> this.val_save("Adress1", e) } />
                    <Fr_Input label="Referens" className="text-left w250" text={this.state.wfp.referens} getValue={(e)=> this.val_save("referens", e) } />
                </div>
                <div className="flex-row">
                    <Fr_Input label="Gatuadress" className="text-left w350" text={this.state.wfp.Adress2} getValue={(e)=> this.val_save("Adress2", e) } />
                    <Fr_Input label="Märkning" className="text-left w350" text={this.state.wfp.remarks} getValue={(e)=> this.val_save("wfak03.remarks", e) } />
                </div>
                <div className="flex-row">
                    <Fr_Input label="Postnr Stad" className="text-left w350" text={this.state.wfp.Adress3} getValue={(e)=> this.val_save("Adress3", e) } />
                </div>
                <div className="flex-row">
                    <Fr_Input label="Land" className="text-left w350" text={this.state.wfp.Adress4} getValue={(e)=> this.val_save("Adress4", e) } />
                </div>
                <div className="divider_green"></div>
                <div className="flex-row">
                    <Fr_Input label="Bankgiro" className="text-left w250" text={this.state.wfp.bankgiro} getValue={(e)=> this.val_save("bankgiro", e) } />
                    <Fr_Input label="Plusgiro" className="text-left w250" text={this.state.wfp.plusgiro} getValue={(e)=> this.val_save("plusgiro", e) } />
                </div>
                <div className="flex-row">
                    <Fr_Input label="IBAN" className="text-left w250" text={this.state.wfp.IBAN} getValue={(e)=> this.val_save("IBAN", e) } />
                    <Fr_Input label="Swift" className="text-left w250" text={this.state.wfp.SWIFT_BIC} getValue={(e)=> this.val_save("SWIFT_BIC", e) } />
                </div>
                <div className="divider_green"></div>
                <div className="flex-row">
                    <Fr_Input label="Betvillkor" className="text-left w350" text={this.state.wfp.Betvillkor_txt} getValue={(e)=> this.val_save("Betvillkor_txt", e) } />
                </div>
                <div className="flex-row">
                    <Fr_Input label="F-Skatt" className="text-left w350" text={this.state.wfp.F_skattnr_txt} getValue={(e)=> this.val_save("F_skattnr_txt", e) } />
                </div>

            </form>
    </div>
  );
    }
}
export default Bo_Ftg;
