import React, { useState, useContext } from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import { observer } from 'mobx-react';
import { useStore } from '../../sdo.js'
import {useNavigate} from "react-router-dom";

function Bfs_Menu(props) {
    var navigate = useNavigate();
    var row = {};
    //if(props.store.fkn) row = props.store.fkn;
    let [state, setState] = useState({row: row, mnu: "", htitle: "", ma: []});
    let ctx = useStore();


    var lock = "lock";
    var moa = {};
    moa["f00"] = { fid: "f00", name: "Hem", url: "/bfs", icon: "home", fa: "fa fa-home flex", ma: [] };
    moa["f01"] = { fid: "f01", name: "Huvudbok", url: "/bfs/bf/hbok", icon: "book-open", fa: "fa fa-book-open flex", ma: [] };
    moa["f02"] = { fid: "f02", name: "Verifikat", url: "/bfs/bf/veri", icon: "file-signature", fa: "fa fa-file-signature flex", ma: [] };
    moa["f04"] = { fid: "f04", name: "Bearbeta", url: "/bfs/wrk", icon: "receipt", fa: "fa fa-receipt flex", ma: [] };
    moa["f06"] = { fid: "f06", name: "Rapporter", url: "/bfs/rps", icon: "list", fa: "fa fa-list flex", ma: [] };
    moa["f07"] = { fid: "f07", name: "Register", url: "/bfs/reg", icon: "desktop", fa: "fa fa-desktop flex", ma: [] };
    moa["f09"] = { fid: "f09", name: "Backoffice", url: "/bfs/bo", icon: "cogs", fa: "fa fa-cogs flex", ma: [] };

    //moa["f01"].ma.push( { fid: "f01", name: "Plan", url: "/bfs/wrk/plan", fa: "fa fa-bars" } );

    //moa["f02"].ma.push( { fid: "f02", name: "Flöde", url: "/bcs/sup/flow", fa: "fa fa-bars" } );
    //moa["f02"].ma.push( { fid: "f02", name: "Historik", url: "/bcs/sup/hist", fa: "fa fa-bars" } );

    moa["f04"].ma.push( { fid: "f04", name: "Moms", url: "/bfs/wrk/tax", fa: "fa fa-bars" } );
    moa["f04"].ma.push( { fid: "f04", name: "Kunder", url: "/bfs/wrk/kund", fa: "fa fa-bars" } );
    moa["f04"].ma.push( { fid: "f04", name: "Leverantörer", url: "/bfs/wrk/lev", fa: "fa fa-bars" } );

    moa["f07"].ma.push( { fid: "f07", name: "Kunder", url: "/bfs/reg/kreg", fa: "fa fa-bars" } );
    moa["f07"].ma.push( { fid: "f07", name: "Leverantörer", url: "/bfs/reg/lreg", fa: "fa fa-bars" } );

    moa["f09"].ma.push( { fid: "f09", name: "Parametrar", url: "/bfs/bo/prm", fa: "fa fa-bars" } );
    moa["f09"].ma.push( { fid: "f09", name: "Kontoplan", url: "/bfs/bo/kp", fa: "fa fa-bars" } );
    moa["f09"].ma.push( { fid: "f09", name: "Redovisning", url: "/bfs/bo/red", fa: "fa fa-bars" } );
    moa["f09"].ma.push( { fid: "f09", name: "Teknik", url: "/bfs/bo/tech", fa: "fa fa-bars" } );

    var aa = [];
    var keys = Object.keys(moa);
    for(var key of keys) {
        var o = moa[key];
        aa.push( o );
    }

    //document.removeEventListener("mousedown", this.handleClick);
    document.addEventListener("mousedown", handleClick);

    var ma = [];
    var mnuHeight = window.innerHeight - 100;

   function handleClick(e) {
    if (e.pageX > 320) {
        if (state.ma.length) {
            setState({mnu : state.mnu, row : state.row, ma : [] });
            return;
        }
    }
  }
   function fknGo(row) {
    //var sdo = useContext(SDO);
    //alert(fkn);
    var fkn = row.fid;
    setState({ mnu : row.fid, row : row,  ma : [] });
    ctx.fid = fkn;

    props.store.fkn = row;
    navigate(row.url);
   };
   function fknFix(fkn) {
    //var sdo = useContext(SDO);
    //alert(fkn);
    ctx.fid = fkn;

    if(fkn == "lock") navigate('/web');
    if(fkn == "logout") navigate('/');
   };
   function mnuGo(row) {
    //var sdo = useContext(SDO);
    //alert(fkn);
    //ctx.fid = fkn;
    setState({ mnu : state.hfid, row : state.row,  ma : [] });

    props.store.fkn = row;
       navigate(row.url);
   };
   function hideMnu() {
    setState({mnu : state.mnu, row : state.row, ma : [] });
   };
   function mouseEnter(it) {
    setState({mnu : state.mnu, row : state.row, hfid : it.fid, htitle : it.name, ma : it.ma });
   };
   function mouseLeave() {
    setState({mnu : state.mnu, row : state.row, ma : [] });
   };

   function row_css(row) {
  	    var css = {};
        css.margin = "0px";
        css.padding = "0px";
        css.height = "55px";
        css.borderBottom = "1px #ccc solid";

        if(props.store.fkn && (props.store.fkn != undefined))
        {
            if (props.store.fkn.fid == row.fid) {
               css.background = "#080";
               css.color = "#fff";
            }
        }
		return css;
    }
   function row_icon_css(row) {
  	    var css = {};
        css.paddingTop = "10px";
        css.fontSize = "20px";

        if(props.store.fkn && (props.store.fkn != undefined))
        {
            if (props.store.fkn.fid == row.fid) {
               //css.background = "#080";
               css.color = "#fff";
            }
        }
		return css;
    }

   //<Icon className={ it.fa } style={{ fontSize: 18 }} />
   var html =
        <div className="">
        <div className="cdi_xmnu flex-fullcol" style={{padding: "5px 0px"}}>
            { aa.map((it, key) =>
            <div key={ key } onClick={() => fknGo(it)} className="web-col flex-cc xmnu_tab" style={ row_css(it) } onMouseEnter={ () => mouseEnter(it) }>
                <FontAwesomeIcon icon={ it.icon } size="1x" className="" style={ row_icon_css(it) } />
                <div style={{fontSize: "10px"}}>{ it.name }</div>
            </div>
            )}
            <div onClick={ () => fknFix("lock") } className="web-col flex-cc xmnu_tab">
                <FontAwesomeIcon icon="lock" size="1x" className="" style={{paddingTop: "10px", fontSize: "20px"}} />
                <div style={{fontSize: "10px"}}>Lås</div>
            </div>
            <div onClick={() => fknFix("logout") } className="web-col flex-cc xmnu_tab" >
                <i className="fa fa-power-off flex" aria-hidden="true" style={{paddingTop: "10px", fontSize: "20px"}}></i>
                <div style={{fontSize: "10px"}}>Logga ur</div>
            </div>
        </div>

            { state.ma.length ?
        <div className="mtrl_mnu_sub mtrl_mnu_bg" aria-label="Sub Menu" style={{height: mnuHeight}} onMouseLeave={mouseLeave}>
          <div className="mtrl_mnu_title" onClick={() => hideMnu()}>
            { state.htitle }
          </div>
            { state.ma.map((mi, key) =>
          <div key={key} className="mtrl_mnu_item flex-row" onClick={ () => mnuGo(mi) }>
            <div className="flex">{ mi.name }</div>
            <i className={ mi.fa } aria-hidden="true" style={ row_icon_css(mi) }></i>
          </div>
            )}
        </div>
                : null }
        </div>
    ;
    return html;
}

export default observer(Bfs_Menu);
