import React, { useState, useEffect, useContext } from 'react';
import {addDays, format} from "date-fns";

import * as utils from "../../assets/utils";
import * as net from "../../lib/net";
import Td_Sel from "../../lib/ui/td_sel";
import Dt_Cal from "../../lib/ui/dt_cal";
import Tot_EE from "./fkn/tot_ee";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Calc_Tot extends React.Component {
  constructor(props, context) {
    super(props);

    this.dt_fd = React.createRef();
    this.dt_td = React.createRef();

    this.store = props.store;
      this.ta = props.store.ta;

    this.state = {};
    this.state.seldate = new Date();
    this.state.avd = "01";
    this.state.tha = [];
    this.state.kro = {};
    this.state.aa = [];
      this.state.tot = {};
      this.state.pms = {};
      this.state.pos = {};
    this.state.wnds = {};
    this.state.sortid = "";
    this.state.sortdir = 1;

    this.state.today = new Date();
    var dts = format(this.state.today, "yyyy-MM-dd");
    //var tdat = addDays(this.state.today, 15);
    //var tdat = subDays(this.state.today, 15);
    let cdat = new Date();
      //let formatted_date = cdat.getFullYear() + "-" + (cdat.getMonth() + 1) + "-" + cdat.getDate();
      let formatted_date = cdat.getFullYear() + "-" + (cdat.getMonth() + 1) + "-" + "01";
    this.state.fdat = formatted_date;
    this.state.tdat = dts;

      if(this.ta && this.ta.base) {
          this.state.fdat = this.ta.base.fdat;
          this.state.tdat = this.ta.base.tdat;
      }

    this.selitems = [
      {fkn: 'open', text: 'ÖPPNA'},
      {fkn: 'prn', text: 'SKRIV UT'}
    ];
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.per_tot();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      css.overflow = "auto";
      return css;
  }
    th_style(id) {
        var css = {};
        css.cursor = "pointer";
        if(id == this.state.sortid) {
            if(this.state.sortdir) css.color = "#800";
            else css.color = "#080";
        }
        return css;
    }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }
    dt_fdat(e) {
        //alert(JSON.stringify(e));
        var dts = format(e, "yyyy-MM-dd");
        this.setState({ fdat: dts });
    }
    dt_tdat(e) {
        //alert(JSON.stringify(e));
        var dts = format(e, "yyyy-MM-dd");
        this.setState({ tdat: dts });
    }
    oneday(e) {
        //alert(JSON.stringify(e));
        //this.setState({ tdat: this.state.fdat });
        this.dt_td.current.updateDt(this.state.fdat);
    }
    addday(e) {
        //alert(JSON.stringify(e));
        //this.setState({ tdat: this.state.fdat });
        this.dt_fd.current.updateAdd();
        this.dt_td.current.updateAdd();
    }
    subday(e) {
        //alert(JSON.stringify(e));
        //this.setState({ tdat: this.state.fdat });
        this.dt_fd.current.updateSub();
        this.dt_td.current.updateSub();
    }

  tot_ee() {
    var self = this;
    var wnds = {};
    wnds.totee = 1;
      wnds.fdat = this.state.fdat;
      wnds.tdat = this.state.tdat;
    this.setState({wnds: wnds});
  }
    prs_edit(row) {
        alert(JSON.stringify(row.peo));
        var self = this;
        var wnds = {};
        wnds.edit = 1;
        wnds.eeo = row.peo;
        this.setState({wnds: wnds});
    }
  fkn_close() {
    this.setState({edit: false });
    //this.props.history.push("cas");
    //this.login_srv(uid, pwd);
  }

    per_tot() {
    var self = this;
    var prm = {};
    prm.req = "ta.ta_calc.per_tot";
    prm.fdat = this.state.fdat;
    prm.tdat = this.state.tdat;
    //prm.avd = this.state.avd;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        var tha = [];
        if(ret.rca) tha = ret.rca;
        self.setState({ tha: tha, tot: ret.tot, pms: ret.pms, pos: ret.pos }, function () {
            self.syncTb("perTable");
        });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
    syncTb(tb) {
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
            tdElements = tbel.getElementsByTagName('td');
        if(tdElements.length < 1 ) return;

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
                width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
    tb_sort(typ) {
        var self = this;
        //var rvs = self.state.rvs;
        var tha = self.state.tha;
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;

        if(sortid != typ) sortdir = 0;
        else {
            if(sortdir) sortdir = 0;
            else sortdir = 1;
        }

        if(typ) {
            if(sortdir) tha.sort((a,b) => (a[typ] < b[typ]) ? 1 : ((b[typ] < a[typ]) ? -1 : 0));
            else tha.sort((a,b) => (a[typ] > b[typ]) ? 1 : ((b[typ] > a[typ]) ? -1 : 0));
        }
        self.setState({ tha: tha, sortid: typ, sortdir: sortdir });
    }
    th_icon(typ) {
        var cls = "";
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;
        if(sortid == typ)
        {
            if(sortdir) cls = "fa fa-caret-up";
            else cls = "fa fa-caret-down";
        }
        return cls;
    }

  render() {
    return (
    <div className="web-app">

    <div className="flex-row flex-space-between">
        <div className="label_h03">TOTAL KALKYL</div>
        <div className="flex"></div>
        <div className="flex-row">
            <div className="dt-btn-01" onClick={() => this.subday() } ><FontAwesomeIcon icon="minus" size="1x" /></div>
            <Dt_Cal dts={ this.state.fdat } rp="1" ref={this.dt_fd} getValue={(e)=> this.dt_fdat(e)} />
            <div className="dt-btn-01" onClick={() => this.oneday() } >1</div>
            <Dt_Cal dts={ this.state.tdat } rp="1" ref={this.dt_td} getValue={(e)=> this.dt_tdat(e)} />
            <div className="dt-btn-01" onClick={() => this.addday() } ><FontAwesomeIcon icon="plus" size="1x" /></div>
        </div>
        <div className="flex"></div>
        <button className="btn btn-hw-blue w150" onClick={() => this.tot_ee() } aria-label="System" >
            VISA ALLA
        </button>
        <button className="btn btn-hw-blue w150" onClick={() => this.per_tot() } aria-label="System" >
            UPPDATERA
        </button>
    </div>
    <div className="divider_blue"></div>

        <div className="flex-col flex" style={{ margin: "0 auto", minWidth: "600px", maxWidth: "700px" }}>

            <div className="flex-col txt_16 py-2">
                <div className="flex-row">
                    <div className="txt_18">TOTALT:</div>
                </div>
                <div className="flex-row">
                    <div className="flex-row flex">
                        <div className="txt_14">TIMMAR:</div>
                        <div className="flex"></div>
                        <div className="">{ utils.toNum(this.state.tot.wrktid) }</div>
                    </div>
                    <div className="flex"></div>
                    <div className="flex-row flex">
                        <div className="txt_14">LÖN:</div>
                        <div className="flex"></div>
                        <div className="">{ utils.toPris(this.state.tot.tlon) }</div>
                    </div>
                </div>
                <div className="flex-row">
                    <div className="flex-row flex">
                        <div className="txt_14">PERSONER:</div>
                        <div className="flex"></div>
                        <div className="">{ utils.toNum(this.state.tot.nrp) }</div>
                    </div>
                    <div className="flex"></div>
                    <div className="flex-row flex">
                        <div className="txt_14">OMS/PERSON:</div>
                        <div className="flex"></div>
                        <div className="">{ utils.toPris(this.state.tot.opp) }</div>
                    </div>
                </div>
                <div className="flex-row">
                    <div className="flex-row flex">
                        <div className="txt_14">OMSÄTTNING:</div>
                        <div className="flex"></div>
                        <div className="">{ utils.toPris(this.state.tot.oms) }</div>
                    </div>
                    <div className="flex"></div>
                    <div className="flex-row flex">
                        <div className="txt_14">OMS/TIMMA:</div>
                        <div className="flex"></div>
                        <div className="">{ utils.toPris(this.state.tot.opt) }</div>
                    </div>
                </div>
            </div>

            <div className="divider_green"></div>

            <div className="flex-col txt_16 py-2">
                <div className="flex-row">
                    <div className="txt_18">HOTELL:</div>
                </div>
                <div className="flex-row">
                    <div className="flex-row flex">
                        <div className="txt_14">TIMMAR:</div>
                        <div className="flex"></div>
                        <div className="">{ utils.toNum(this.state.pms.wrktid) }</div>
                    </div>
                    <div className="flex"></div>
                    <div className="flex-row flex">
                        <div className="txt_14">LÖN:</div>
                        <div className="flex"></div>
                        <div className="">{ utils.toPris(this.state.pms.tlon) }</div>
                    </div>
                </div>
                <div className="flex-row">
                    <div className="flex-row flex">
                        <div className="txt_14">PERSONER:</div>
                        <div className="flex"></div>
                        <div className="">{ utils.toNum(this.state.pms.nrp) }</div>
                    </div>
                    <div className="flex"></div>
                    <div className="flex-row flex">
                        <div className="txt_14">OMS/PERSON:</div>
                        <div className="flex"></div>
                        <div className="">{ utils.toPris(this.state.pms.opp) }</div>
                    </div>
                </div>
                <div className="flex-row">
                    <div className="flex-row flex">
                        <div className="txt_14">OMSÄTTNING:</div>
                        <div className="flex"></div>
                        <div className="">{ utils.toPris(this.state.pms.oms) }</div>
                    </div>
                    <div className="flex"></div>
                    <div className="flex-row flex">
                        <div className="txt_14">OMS/TIMMA:</div>
                        <div className="flex"></div>
                        <div className="">{ utils.toPris(this.state.pms.opt) }</div>
                    </div>
                </div>
            </div>

            <div className="divider_green"></div>

            <div className="flex-col txt_16 py-2">
                <div className="flex-row">
                    <div className="txt_18">RESTAURANG:</div>
                </div>
                <div className="flex-row">
                    <div className="flex-row flex">
                        <div className="txt_14">TIMMAR:</div>
                        <div className="flex"></div>
                        <div className="">{ utils.toNum(this.state.pos.wrktid) }</div>
                    </div>
                    <div className="flex"></div>
                    <div className="flex-row flex">
                        <div className="txt_14">LÖN:</div>
                        <div className="flex"></div>
                        <div className="">{ utils.toPris(this.state.pos.tlon) }</div>
                    </div>
                </div>
                <div className="flex-row">
                    <div className="flex-row flex">
                        <div className="txt_14">PERSONER:</div>
                        <div className="flex"></div>
                        <div className="">{ utils.toNum(this.state.pos.nrp) }</div>
                    </div>
                    <div className="flex"></div>
                    <div className="flex-row flex">
                        <div className="txt_14">OMS/PERSON:</div>
                        <div className="flex"></div>
                        <div className="">{ utils.toPris(this.state.pos.opp) }</div>
                    </div>
                </div>
                <div className="flex-row">
                    <div className="flex-row flex">
                        <div className="txt_14">OMSÄTTNING:</div>
                        <div className="flex"></div>
                        <div className="">{ utils.toPris(this.state.pos.oms) }</div>
                    </div>
                    <div className="flex"></div>
                    <div className="flex-row flex">
                        <div className="txt_14">OMS/TIMMA:</div>
                        <div className="flex"></div>
                        <div className="">{ utils.toPris(this.state.pos.opt) }</div>
                    </div>
                </div>
            </div>

        </div>

    <div className="divider_blue"></div>
    <div className="flex-row">
        <div className="txt_18">TOTAL TID: { utils.toNum(this.state.tot.wrktid) }</div>
        <div className="flex"></div>
        <div className="txt_18 px-2">TOTAL POS: { utils.toPris(this.state.tot.pos) }</div>
        <div className="txt_18 px-2">TOTAL PMS: { utils.toPris(this.state.tot.pms) }</div>
        <div className="flex"></div>
        <div className="txt_18">TOTAL LÖN: { utils.toPris(this.state.tot.tlon) }</div>
    </div>

        { this.state.wnds.totee ? <Tot_EE wnds={this.state.wnds} ctx={this} backdrop={true}/> : null }
   </div>
  );
    }
}
export default Calc_Tot;
