import React, { useState, useEffect, useRef, useContext } from 'react';
import { format, getDate, getMonth, getWeek, eachDayOfInterval, eachWeekOfInterval } from 'date-fns'
import { setMonth, setYear, startOfWeek, endOfWeek, startOfMonth, endOfMonth, addMonths, subMonths, subDays, isSameDay } from 'date-fns'
import { sv } from 'date-fns/locale'

import './css/pcirc.css';

class PCirc extends React.Component {
  constructor(props, context) {
    super(props);

    this.state = {};
    this.state.wka = [];    
    this.state.tid = {};
    this.state.proc = 0;
    this.state.color = "#ff9900";
    if(props.percent) this.state.proc = props.percent;
    if(props.color) this.state.color = props.color;
    this.state.deg = 180/100 * this.state.proc;
    this.state.dash = this.state.proc + ", 100";
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.setTime();
  }
  
  mask_style() {
    var css = {};
    //css.width = window.innerWidth * 0.8;
    //css.fontSize = "10px";
    //if(window.innerWidth < 800) css.display = "none";
    css.transform = "rotate(" + this.state.deg + "deg)";
 
    return css;
}
dash_style() {
    var cls
    var css = {};
    css.stroke = this.state.color;
    return css;
}
fill_style() {
var css = {};
    //css.width = window.innerWidth * 0.8;
    //css.fontSize = "10px";
    //if(window.innerWidth < 800) css.display = "none";
    css.transform = "rotate(" + this.state.deg + "deg)";
    css.backgroundColor = this.state.color;

    return css;
  }

  handleClick = (e) => {
    if(!this.state.cal_show) return;

    if (!this.node.current.contains(e.target)) {
      this.setState({cal_show: false});
      return;
    }
  }
  setTime() {
        var dat = new Date();
       var tid = {};
        tid.datum = format(dat, "yyyy-MM-dd", { locale: sv });
        //tid.tidpkt = format(dat, "HH:mm", { locale: sv });
        tid.tidpkt = format(dat, "HH:mm.ss", { locale: sv });
        this.setState({tid: tid});
        //this.forceUpdate();
    }

  render() {
	var html =
    <div className="flex-wrapper">
    <div className="single-chart">

   <svg viewBox="0 0 36 36">
      <path className="circle-bg"
        d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
      />
      <path className="circle"
        style={ this.dash_style() }
        strokeDasharray={ this.state.dash }
        d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
      />
      <text x="18" y="20.35" className="percentage">{ this.state.proc }%</text>
    </svg>

    </div>
    </div>
  	;
	return html;
  };
}

export default PCirc;