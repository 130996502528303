import React, { useState, useEffect, useContext } from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";

import {withRouter} from "../../lib/react/withRouter";
import { observer } from 'mobx-react'
import {withStore} from "../../sdo";

import * as net from "../../lib/net";

import Tiden from "../../lib/ui/tiden";
import Dd_Sel from "../../lib/ui/dd_sel";

import Reg_Top from './reg_top.js';
import Reg_Mnu from './reg_mnu.js';
import Reg_Home from './home.js';
import Reg_Dash from './dash.js';
import Reg_Auth from './auth.js';

import Reg_Deps from './deps.js';
import Reg_Vgrps from './vgrps.js';
import Reg_Pays from './pays.js';
import Reg_Tax from './tax.js';
import Reg_Cas from './cas.js';
import Reg_Cur from './cur.js';
import Reg_Kp from './kp.js';
import Reg_TDef from './tdef.js';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function web01() {

    var html =
    <div className="web-content">
    </div>
    ;
    return html;
}

class Reg extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.selitems = [
        {fkn: 'setup', text: 'INSTÄLLNINGAR'},
        {fkn: 'upd', text: 'UPPDATERA'},
        {fkn: 'sys', text: 'SYSTEM'},
        {fkn: 'logout', text: 'LOGGA UT'}
    ];
    this.btmitems = [
        {fkn: 'kreg', text: 'KUNDREGISTER'},
        {fkn: 'logg', text: 'LOGG'},
        {fkn: 'hist', text: 'HISTORIK'}
    ];
    this.langitems = [
        {fkn: 'sv', text: 'SVENSKA'},
        {fkn: 'no', text: 'NORSKA'},
        {fkn: 'en', text: 'ENGELSKA'}
    ];

    this.store = props.store;
    this.state = { pgid: 11, deps: [] };

    this.state.wnds = {};
    this.state.boknr = "";
    this.state.lang = "sv";

  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

  fknGo(url) {
    //alert(row.href);
    this.props.navigate(url);
  }
  sel_fkn(sel) {
    //alert(JSON.stringify(fkn));
      if(sel.fkn == "logout") {
      }
  }
  sel_btm(sel) {
    //alert(JSON.stringify(fkn));
      if(sel.fkn == "kreg") this.fknGo("/pms/fo/kreg")
      if(sel.fkn == "logg") this.fknGo("/pms/fkn/logg")
  }
  sel_lang(sel) {
    //alert(JSON.stringify(fkn));
      if(sel.fkn == "sv") {
        this.props.xstore.setLang("sv");
      }
      if(sel.fkn == "no") {
        this.props.xstore.setLang("no");
      }
      if(sel.fkn == "en") {
        this.props.xstore.setLang("en");
      }
  }

  render() {
      if(false) {
      //if(!this.props.store.pos.regauth) {
          //this.props.store.pos.regauth = true;
            //alert("REGISTER AUTH");
            return (
            <div className="web-app">
                <div className="web-body">
                    <Routes>
                        <Route path="*" element={ <Reg_Auth store={this.store} />} />
                    </Routes>
                </div>
            </div>
          );
      }

    return (
    <div className="web-app">
    <div className="web-top">
        <Reg_Top ctx={this} store={this.store} />
    </div>
    <div className="web-main">

    <div className="web-row" style={{ height:"100%" }}>

    { this.props.store.cfg.mnu_show ?
    <div className="web-side">
        <Reg_Mnu store={this.store} />
    </div>
    : null }

    <div className="web-body">
        <Routes>
            <Route path="deps" element={ <Reg_Deps store={this.store} />} />
            <Route path="vgrps" element={ <Reg_Vgrps store={this.store} />} />
            <Route path="pays" element={ <Reg_Pays store={this.store} />} />
            <Route path="tax" element={ <Reg_Tax store={this.store} />} />
            <Route path="cur" element={ <Reg_Cur store={this.store} />} />
            <Route path="cas" element={ <Reg_Cas store={this.store} />} />
            <Route path="kp" element={ <Reg_Kp store={this.store} />} />
            <Route path="tdef" element={ <Reg_TDef store={this.store} />} />
            <Route path="home" element={ <Reg_Home store={this.store} />} />
            <Route path="*" element={ <Reg_Auth store={this.store} />} />
        </Routes>
    </div>

    </div>

    </div>
    <div className="web-foot">
        <div className="web-foot-mnu" onClick={() => this.rvn_open() } title="NY BOKNING">
            <FontAwesomeIcon icon="file-alt" size="1x"/>
        </div>
        <div className="flex flex-row">
            <div className="flex"></div>

            <div className="web-foot-btn" onClick={() => this.fknGo("/pms/reg/tr") } >TRANSAKTIONER</div>
        </div>
        <Dd_Sel items={ this.btmitems } getValue={(e)=> this.sel_btm(e)} />
        <div className="flex flex-row">
            <div className="web-foot-btn" onClick={() => this.fknGo("/pms/reg/pay") }>BETALNINGAR</div>

            <div className="flex"></div>
        </div>
        <div className="web-foot-rmnu hide" >
            <Dd_Sel items={ this.langitems } getValue={(e)=> this.sel_lang(e)} />
        </div>
        <Tiden />
    </div>

    </div>
  );
}
}
//Sys.contextType = ADOContext;
export default withRouter(withStore(observer(Reg)));
