import React, { useState, useEffect, useContext } from 'react';

//import logo from './logo.svg';

import * as net from "../../lib/net";
import Tv_Cfg from "./fkn/tv_cfg";

class Pub_Tvm extends React.Component {
  constructor(props) {
    super(props);

    this.store = props.store;

    this.state = {};
    this.state.scrid = this.store.cfg.tvid;
    this.state.qr = "";
    this.state.wnds = {};

        var tvo = {};
        tvo.data = {};

        tvo.data.c11 = {};
        tvo.data.c11.mnu = {};
        tvo.data.c11.mnu.text = "T11";
        tvo.data.c11.mnu.rows = [];
        tvo.data.c11.mnu.descrows = [];

        tvo.data.c12 = {};
        tvo.data.c12.mnu = {};
        tvo.data.c12.mnu.text = "T12";
        tvo.data.c12.mnu.rows = [];
        tvo.data.c12.mnu.descrows = [];

        tvo.data.c21 = {};
        tvo.data.c21.mnu = {};
        tvo.data.c21.mnu.text = "T21";
        tvo.data.c21.mnu.rows = [];
        tvo.data.c21.mnu.descrows = [];

        tvo.data.c22 = {};
        tvo.data.c22.mnu = {};
        tvo.data.c22.mnu.text = "T22";
        tvo.data.c22.mnu.rows = [];
        tvo.data.c22.mnu.descrows = [];
    //var tvo = props.store.tvo;
    this.state.tvo = tvo;
  }

  componentWillUnmount() {
      document.removeEventListener("keydown", this.handleKey);
  }
  componentDidMount() {
    document.addEventListener("keydown", this.handleKey);

    this.init_tv();
  }
    handleKey = (e) => {
      e.preventDefault();
      //alert(e.key);
      //alert(e.code);
      if(this.state.wnds.tvcfg == true) return;

      if(e.keyCode == 13) {
          //alert("ENTER MNU");
          this.tv_cfg();
      }
      if(e.keyCode == 112) {
          //alert("F1");
      }
      if(e.keyCode == 113) {
          //alert("F2");
          //this.sz();
      }
      if(e.keyCode == 114) {
          //alert("F3");
      }
      if(e.keyCode == 115) {
          //alert("F4");
      }
      if(e.keyCode == 116) {
          //alert("F5");
      }
      if(e.keyCode == 117) {
          //alert("F6");
      }
      if(e.keyCode == 118) {
          //alert("F7");
      }
      if(e.keyCode == 119) {
          //alert("F8");
      }
      if(e.keyCode == 120) {
          //alert("F9");
      }
      if(e.keyCode == 121) {
          //alert("F10");
      }
      //if(e.keyCode == 122) {alert("F11");} // NOT TRAPPABLE
      if(e.keyCode == 123) {
          //alert("F12");
      }
    }
  tv_cfg() {
    var self = this;
    var wnds = {};
    wnds.tvcfg = true;
    wnds.tvid = self.store.cfg.scrid;
    //wnds.cb = self.cb_tbo;
    self.setState({wnds: wnds });
  }

  show_mnu() {
    return "true";
  }
  css_desc() {
    var css = {};
    return css;
  }
  css_category() {
    var css = {};
    return css;
  }
  css_row_text() {
    var css = {};
    return css;
  }
  css_row_price() {
    var css = {};
    return css;
  }
  css_row_desc() {
    var css = {};
    css.width = "80%";
    return css;
  }
  price_text(row) {
    var belopp = row.pris.dec2();
    //belopp = moment().subtract(1, "days").format("YYYY-MM-DD");
      if(belopp) {
        if(belopp.trim().length > 0) belopp = belopp + " kr";
      }
    if(row.type == "INFO") belopp = "";
    return belopp;
  }

  tvo_poll() {
    var self = this;
	setInterval(function(){
		var req = {};
		req.tvid = "tv03";
		req.tid = "";
		req.ip = "";

		//alert(JSON.stringify(req));
		//self.get_tvo();
		//console.log("REQ SENT : " + util.inspect(req.fkn));
	}, 5000);
  }
  init_tv() {
    var self = this;
    self.dsi();
    //self.get_tvo();
    //self.tvo_poll();
  }
  dsi() {
    var self = this;
    var prm = {};
    prm.req = "dsi.dsi_run.tv_lt";
    //prm.sid = "S0000099";
    prm.scrid = this.state.scrid;
    //prm.scrid = "tv03";

    //alert(JSON.stringify(prm));
    net.sio_req(prm)
    .then(function(ret){
        alert(JSON.stringify(ret.rco));

        var tvo = ret.rco;
        //alert(JSON.stringify(tvo));
        if(!tvo.data.c11.mnu.descrows) tvo.data.c11.mnu.descrows = [];
        if(!tvo.data.c12.mnu.descrows) tvo.data.c12.mnu.descrows = [];
        if(!tvo.data.c21.mnu.descrows) tvo.data.c21.mnu.descrows = [];
        if(!tvo.data.c22.mnu.descrows) tvo.data.c22.mnu.descrows = [];
        if(!tvo.data.c11.mnu.rows) tvo.data.c11.mnu.rows = [];
        if(!tvo.data.c12.mnu.rows) tvo.data.c12.mnu.rows = [];
        if(!tvo.data.c21.mnu.rows) tvo.data.c21.mnu.rows = [];
        if(!tvo.data.c22.mnu.rows) tvo.data.c22.mnu.rows = [];
        self.setState({ tvo: tvo });
        //toastr.success("OK", "SAPI");
        //self.set_tvo(tvo);
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

  render() {
    var tvo = this.state.tvo;
    //alert(JSON.stringify(tvo));

    return (
      <div className="web-app">

        <div className="flex_row flex" style={{background: "#333",overfow:"hidden"}}>
          <div className="flex_col flex mnu-base">

            <div className="flex flex_col" show={ this.show_mnu(tvo.data.c11.mnu) }>
              <div className="flex_col category-box">
                <div className="flex_row category-title" style={ this.css_category(tvo.data.c11.mnu) }>
                  { tvo.data.c11.mnu.text.toswe() }
                </div>
            { tvo.data.c11.mnu.descrows.map((desc, key) =>
                <div key={key} className="dish-descriptionx" style={ this.css_desc(tvo.data.c11.mnu) }>
                  { desc.toswe() }
                </div>
            ) }
              </div>

            { tvo.data.c11.mnu.rows.map((row, key) =>
              <div key={key} className="flex_col" style={{padding: "20px 0px"}}>
                <div className="flex_row">
                  <div className="flex dish-title" style={ this.css_row_text(tvo.data.c11.mnu) }>
                    { row.text.toswe() }
                  </div>
                  <div className="dish-pricing" style={ this.css_row_price(tvo.data.c11.mnu) }>
                    { this.price_text(row) }
                  </div>
                </div>
                <div className="dish-description">
                  <div style={ this.css_row_desc(tvo.data.c11.mnu) } flex="75">
                    { row.desc.toswe() }
                  </div>
                </div>
              </div>
            )}
            </div>

            <div className="flex flex_col" show={ this.show_mnu(tvo.data.c12.mnu) }>
              <div layout="row" style={{paddingTop:"30px"}}>&nbsp;</div>

              <div className="flex_col category-box" style={{overflow: "hidden"}}>
                <div className="flex_row category-title" style={ this.css_category(tvo.data.c12.mnu) }>
                  { tvo.data.c12.mnu.text.toswe() }
                </div>

                { tvo.data.c12.mnu.descrows.map((desc, key) =>
                    <div key={key} className="dish-description"
                         style={ this.css_desc(tvo.data.c12.mnu) }>
                      { desc.toswe() }
                    </div>
                )}
              </div>

            { tvo.data.c12.mnu.rows.map((row, key) =>
              <div key={key} layout="column" style={{padding: "20px 0px"}}>
                <div className="flex_row">
                  <div className="flex dish-title" style={ this.css_row_text(tvo.data.c12.mnu) }>
                    { row.text.toswe() }
                  </div>
                  <div className="dish-pricing" style={ this.css_row_price(tvo.data.c12.mnu) }>
                    { this.price_text(row) }
                  </div>
                </div>
                <div className="dish-description">
                  <div style={ this.css_row_desc(tvo.data.c12.mnu) }>
                    { row.desc.toswe() }
                  </div>
                </div>
              </div>
            )}
            </div>
          </div>

          <div className="flex_col flex mnu-base">

            <div className="flex flex_col" show={ this.show_mnu(tvo.data.c21.mnu) }>
              <div className="flex_col category-box">
                <div className="flex_row category-title" style={ this.css_category(tvo.data.c21.mnu) }>
                  { tvo.data.c21.mnu.text.toswe() }
                </div>
            { tvo.data.c21.mnu.descrows.map((desc, key) =>
                <div className="dish-description"
                     style={ this.css_desc(tvo.data.c21.mnu) }>
                  { desc.toswe() }
                </div>
            )}
              </div>

            { tvo.data.c21.mnu.rows.map((row, key) =>
              <div key={key} style={{padding: "20px 0px"}}>
                <div className="flex_row">
                  <div className="flex dish-title" style={ this.css_row_text(tvo.data.c21.mnu) }>
                    { row.text.toswe() }
                  </div>
                  <div className="dish-pricing" style={ this.css_row_price(tvo.data.c21.mnu) }>
                    { this.price_text(row) }
                  </div>
                </div>
                <div className="dish-description">
                  <div style={ this.css_row_desc(tvo.data.c21.mnu) }>
                    { row.desc.toswe() }
                  </div>
                </div>
              </div>
            )}
            </div>

            <div className="flex flex_col" show={ this.show_mnu(tvo.data.c22.mnu) }>
              <div layout="row" style={{paddingTop:"30px"}}>&nbsp;</div>

              <div className="category-box" layout="column" layout-align="start start" style={{overflow: "hidden"}}>
                <div layout="row" layout-align="start start" className="category-title" style={ this.css_category(tvo.data.c22.mnu) }>
                  { tvo.data.c22.mnu.text.toswe() }
                </div>
            { tvo.data.c22.mnu.descrows.map((desc, key) =>
                <div key={key} className="dish-description"
                     style={ this.css_desc(tvo.data.c22.mnu) }>
                  { desc.toswe() }
                </div>
            )}
              </div>

            { tvo.data.c22.mnu.rows.map((row, key) =>
              <div key={key} layout="column" style={{padding: "20px 0px"}}>
                <div className="flex_row">
                  <div className="flex dish-title" style={ this.css_row_text(tvo.data.c22.mnu) }>
                    { row.text.toswe() }
                  </div>
                  <div className="dish-pricing" style={ this.css_row_price(tvo.data.c22.mnu) }>
                    { this.price_text(row) }
                  </div>
                </div>
                <div className="dish-description" flex>
                  <div style={ this.css_row_desc(tvo.data.c22.mnu) } flex="75">
                    { row.desc.toswe() }
                  </div>
                </div>
              </div>
            )}
            </div>

          </div>

        </div>

        <div className="mnu_qr hide">
            <img src="/qr.png" width="200px" height="200px" />
        </div>
        { this.state.wnds.tvcfg ? <Tv_Cfg wnds={this.state.wnds} store={this.store} ctx={this} /> : null}
      </div>
  );
  }
}

export default Pub_Tvm;

