import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";
import Td_Sel from "../../lib/ui/td_sel";
import Dd_Sel from "../../lib/ui/dd_sel";
import * as utils from '../../assets/utils.js'

import Fsk_New from "./fkn/fsk_new";
import Fsk_Edit from "./fkn/fsk_edit";
import Fsk_Trs from "./fkn/fsk_trs";
import Preg_Trs from "./fkn/pres_trs";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Reg_Fsk extends React.Component {
    constructor(props, context) {
        super(props);

        this.webStore = props.store;
        this.state = {};
        this.state.seldate = new Date();
        this.state.avd = "01";
        this.state.fsa = [];
        this.state.kro = {};
        this.state.ta = [
            {id: '01', text: 'PRESENTKORT'},
            {id: '02', text: 'VIPKORT'}
        ];
        this.state.wnds = {};

        this.selitems = [
            {fkn: 'open', text: 'ÖPPNA'}
        ];
        this.fknitems = [
            {fkn: 'preg_inx', text: 'INDEXERA REGISTER'},
            {fkn: 'preg_imp', text: 'IMPORTERA REGISTER'}
        ];
    }
    componentDidUpdate() {
    }
    componentWillUnmount() {
    }
    componentDidMount() {
        this.fskreg();
    }

    body_style() {
        var css = {};
        css.height = window.innerHeight - 280;
        return css;
    }
    tb_style() {
        var css = {};
        css.height = window.innerHeight - 275;
        css.overflow = "auto";
        return css;
    }
    onUnitChange(e) {
        this.setState({unit: e.value});
    }

    kund_open(row) {
        var self = this;
    }
    kund_edit(row) {
        var self = this;
        var wnds = {};
        wnds.edit = 1;
        wnds.kro = row;
        this.setState({ wnds: wnds });
    }

    fskreg() {
        var self = this;
        var prm = {};
        prm.req = "pms.fsk.fsk_list";
        prm.sz = "";
        //prm.avd = this.state.avd;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.rca));

                self.setState({fsa: ret.rca }, function () {
                    self.syncTb("fskTable");
                });
                //toastr.success("OK", "SAPI");
            })
            .catch(function(e) {
                var txt = JSON.stringify(e);
            });
    }
    syncTb(tb){
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
            tdElements = tbel.getElementsByTagName('td');

        for (let i = 0; i < thElements.length; i++) {
            if(!thElements[i]) continue;
            if(!tdElements[i]) continue;
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
                width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
    sel_row_fkn(sel, e) {
        //alert(JSON.stringify(fkn));
        if(sel.fkn == "logout") {
        }
    }
    lists() {
    }
    sel_fkn(sel) {
        //alert(JSON.stringify(sel));
        if(sel.fkn == "preg_imp") {
            this.preg_imp();
        }
    }

    fsk_new() {
        var wnds = {};
        wnds.fsknew = 1;
        wnds.fso = {};
        this.setState({ wnds: wnds });
    }
    fsk_edit(row) {
        var wnds = {};
        wnds.fskedit = 1;
        wnds.fso = row;
        this.setState({ wnds: wnds });
    }
    fsk_trs() {
        var wnds = {};
        wnds.fsktrs = 1;
        wnds.fso = {};
        this.setState({ wnds: wnds });
    }
    tb_sort(typ) {
        var self = this;
        //var rvs = self.state.rvs;
        var fsa = self.state.fsa;
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;

        if(sortid != typ) sortdir = 0;
        else {
            if(sortdir) sortdir = 0;
            else sortdir = 1;
        }
        var fa = typ.split(".");
        if(true) {
            if(sortdir) fsa.sort((a,b) => (a[fa[0]][fa[1]] < b[fa[0]][fa[1]]) ? 1 : ((b[fa[0]][fa[1]] < a[fa[0]][fa[1]]) ? -1 : 0));
            else fsa.sort((a,b) => (a[fa[0]][fa[1]] > b[fa[0]][fa[1]]) ? 1 : ((b[fa[0]][fa[1]] > a[fa[0]][fa[1]]) ? -1 : 0));
        }
        self.setState({ fsa: fsa, sortid: typ, sortdir: sortdir });
    }

    render() {
        return (
            <div className="web-app">

                <div className="flex-row">
                    <select className="form-control sel_input hide" placeholder="Välj typ">
                        { this.state.ta.map((row, key) =>
                            <option key={key} value={row.id}>{row.text}</option>
                        )}
                    </select>
                    <div className="flex"></div>
                    <button className="btn btn-hw-blue w150 hide" onClick={() => this.lists() } aria-label="System" >
                        LISTOR
                    </button>
                    <button className="btn btn-hw-green w150" onClick={() => this.fsk_new() } aria-label="System" >
                        LÄGG TILL
                    </button>
                    <button className="btn btn-hw-green w150" onClick={() => this.fsk_trs() } aria-label="System" >
                        TRANSAKTIONER
                    </button>
                    <button className="btn btn-hw-blue w150" onClick={() => this.fskreg() } aria-label="System" >
                        UPPDATERA
                    </button>
                    <Dd_Sel items={ this.fknitems } cls="web-top-icon" getValue={(e)=> this.sel_fkn(e)} />
                </div>
                <div className="divider_orange"></div>

                <div className="web-col">
                    <div id="fskTable">
                        <table className="table table-bordered table-hover tb " style={{marginBottom: "0px"}}>
                            <thead>
                            <tr>
                                <th onClick={() => this.tb_sort("fsk00.f0id") }>Fsk Nr</th>
                                <th onClick={() => this.tb_sort("fsk00.f0text") }>Text</th>
                                <th onClick={() => this.tb_sort("fsk00.f0datum") }>Datum</th>
                                <th onClick={() => this.tb_sort("fsk00.f0belopp") }>Belopp</th>
                                <th onClick={() => this.tb_sort("fsk00.f0saldo") }>Att utnyttja</th>
                                <th onClick={() => this.tb_sort("fsk01.f1issuedby") }>Utställt</th>
                                <th onClick={() => this.tb_sort("fsk01.f1anm") }>Information</th>
                                <th></th>
                            </tr>
                            </thead>
                        </table>
                        <div style={ this.tb_style() }>
                            <table className="table table-bordered table-hover">
                                <tbody>
                                { this.state.fsa.map((row, key) =>
                                    <tr key={key} onDoubleClick={() => this.fsk_edit(row) }>
                                        <td className="text-center">{ utils.toNum(row.fsk00.f0id) }</td>
                                        <td>{ row.fsk00.f0text }</td>
                                        <td>{ utils.toDate(row.fsk00.f0datum) }</td>
                                        <td>{ utils.toPris(row.fsk00.f0belopp) }</td>
                                        <td>{ utils.toPris(row.fsk00.f0saldo) }</td>
                                        <td>{ row.fsk01.f1issuedby }</td>
                                        <td>{ row.fsk01.f1anm }</td>
                                        <td>
                                            <Td_Sel items={ this.selitems } getValue={(e)=> this.sel_row_fkn(row, e)} />
                                        </td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                { this.state.wnds.fsknew ? <Fsk_New wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
                { this.state.wnds.fskedit ? <Fsk_Edit wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
                { this.state.wnds.fsktrs ? <Fsk_Trs wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
            </div>
        );
    }
}
export default Reg_Fsk;
