import React, {useState} from 'react';

import { observer } from 'mobx-react';
import { useStore } from '../../sdo.js'
import ddo from "../../ddo";
import sso from "../../sso";

import * as net from "../../lib/net";
import Dd_Sel from "../../lib/ui/dd_sel";
import Pms_Term from "../op/fkn/rf_gst";
import {useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function Pms_Top(props) {
    var navigate = useNavigate();
    let [state, setState] = useState({modal: false, ma: []});
    let ctx = useStore();

    var seo = props.store.seo;


    var selitems = [
        {fkn: 'home', text: 'HEM'},
        {fkn: 'setup', text: 'INSTÄLLNINGAR'},
        {fkn: 'upd', text: 'UPPDATERA'},
        {fkn: 'sys', text: 'SYSTEM'},
        {fkn: 'logout', text: 'LOGGA UT'}
    ];

      const goFull = () => {
        alert("Full");
      };

    function toggle() {
    };
    function logout() {
        var url = "/logout";
        navigate(url);
    };
    function acs() {
        var wnds = {};
        wnds.acs = 1;
        props.ctx.setState({ wnds: wnds });
    };
    function bi() {
        var wnds = {};
        wnds.bi = 1;
        props.ctx.setState({ wnds: wnds });
    };
    function stat() {
        var wnds = {};
        wnds.stat = 1;
        props.ctx.setState({ wnds: wnds });
    };
    function bel() {
        var wnds = {};
        wnds.bel = 1;
        props.ctx.setState({ wnds: wnds });
    };
    function term() {
        var wnds = {};
        wnds.term = 1;
        props.ctx.setState({ wnds: wnds });
    };
    function kund_info() {
        var wnds = {};
        wnds.kundinfo = 1;
        props.ctx.setState({ wnds: wnds });
    };
                                                                                                                                                                                                                                                        function usr_info() {
                                                                                                                                                                                                                                                            var wnds = {};
                                                                                                                                                                                                                                                            wnds.usrinfo = 1;
                                                                                                                                                                                                                                                            props.ctx.setState({ wnds: wnds });
                                                                                                                                                                                                                                                        };
      const mnu_toggle = () => {
		//props.ctx.setState({ mnu_show: !props.ctx.state.mnu_show });
		props.store.cfg.mnu_show = !props.store.cfg.mnu_show;
      };
      const bread_toggle = () => {
		props.store.cfg.bread = !props.store.cfg.bread;
      };
      const mnu_item_css = () => {
      };
      const mnu_sel = () => {
      };
      const login = () => {
      };

      function fknGo(url) {
    //alert(url);
      navigate(url);
  }
  function sel_fkn(sel) {
    //alert(JSON.stringify(fkn));
      if(sel.fkn == "home") {
        var url = "/hello";
          navigate(url);
      }
      if(sel.fkn == "logout") {
        var url = "/logout";
          navigate(url);
      }
  }
  const header_class = () => {
    var cls = "flex_col flex";

      if(false) {
        cls = cls + " web-head_1";
      }
      //else cls = cls +  " web-header";


      return cls;
  }

    var html =
            <div className={ header_class() }>
            <div className="web-top-content flex-row">
            <div className="web-top-icon px-3 hide" onClick={() => mnu_toggle() }>
                    <FontAwesomeIcon icon="bars" size="1x"/>
                </div>
                <div className="web-top-id flex-column cursor-point" onClick={() => kund_info() }>
                    <div className="txt_14"><img className="logo-img" height="25px" src={require('../../img/logo-cdi.png')} /></div>
                    <div className="txt_14">HOTELLSYSTEM</div>
                </div>

                <div className="web-top-sep"></div>
                <div className="web-top-mnu flex-column cursor-point" onClick={() => kund_info() }>
                    <div className="item-sys">Företag</div>
                    <div className="item-fkn">{ sso.seo.company }</div>
                </div>

                <div className="web-top-sep"></div>


                <div className="flex-fill"></div>
                <div className="web-top-icon px-3 w60" onClick={(e) => bel(e) }>
                    <FontAwesomeIcon icon={["far", "bed"]} size="1x"/>
                </div>
                <div className="flex-row" style={{padding: "5px", fontSize:"10px", color:"#222", cursor: "pointer" }} onClick={() => stat() }>
                <div className="flex-col" style={{padding: "0px 5px", borderLeft: "1px solid #999", borderRight: "1px solid #999" }}>
                    <div>TOT</div>
                    <div style={{fontSize:"16px", color:"#4781ec", textAlign: "center" }}>{ props.store.pms.stat.nrr }</div>
                </div>
                <div className="flex-col" style={{padding: "0px 5px", borderRight: "1px solid #999" }}>
                    <div>ANK</div>
                    <div style={{fontSize:"16px", color:"#4781ec", textAlign: "center" }}>{ props.store.pms.stat.ankr }</div>
                </div>
                <div className="flex-col" style={{padding: "0px 5px", borderRight: "1px solid #999" }}>
                    <div>BO</div>
                    <div style={{fontSize:"16px", color:"#4781ec", textAlign: "center" }}>{ props.store.pms.stat.borr }</div>
                </div>
                <div className="flex-col" style={{padding: "0px 5px", borderRight: "1px solid #999" }}>
                    <div>AVR</div>
                    <div style={{fontSize:"16px", color:"#4781ec", textAlign: "center" }}>{ props.store.pms.stat.avrr }</div>
                </div>
                <div className="flex-col" style={{padding: "0px 5px", borderRight: "1px solid #999" }}>
                    <div>FREE</div>
                    <div style={{fontSize:"16px", color:"#4781ec", textAlign: "center" }}>{ props.store.pms.stat.free }</div>
                </div>
                </div>
                <div className="web-top-icon px-3 w40" onClick={(e) => acs(e) }>
                    <FontAwesomeIcon icon={["far", "clock"]} size="1x"/>
                </div>
                <div className="web-top-icon px-3 w4    0" onClick={(e) => bi(e) }>
                    <FontAwesomeIcon icon={["far", "chart-line"]} size="1x"/>
                </div>

                <div className="flex-fill"></div>

                <div className="web-top-sep"></div>

                <div className="web-top-usr px-3" onClick={() => usr_info() }>
                    <div className="text-center"><FontAwesomeIcon icon={["far", "user"]} size="1x"/></div>
                    <div className="txt_12">{ sso.seo.name }</div>
                </div>
                <div className="web-top-sep"></div>
                <div className="">
                    <Dd_Sel icon="right-from-bracket" items={ selitems } cls="web-top-icon" getValue={(e)=> sel_fkn(e)} />
                </div>
            </div>

            </div>
    ;
    return html;
}

export default observer(Pms_Top);
