import React, { useState, useEffect, useContext } from 'react';
import {Route, Routes} from "react-router-dom";

import Calc_Home from './calc/home.js';
import Calc_Tot from './calc/tot.js';
import Calc_Avd from './calc/avd.js';
import Calc_Day from './calc/day.js';
import Calc_Per from './calc/per.js';
import {withRouter} from "../lib/react/withRouter";
import Bo_Home from "./bo/home";
import sso from "../sso";

function pg_home() {

    var html =
    <div className="web-content">
      Hem
    </div>
    ;
    return html;
}

class Ta_Calc extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.state = { pgid: 11, deps: [] };
      var po = {};
      po.tot = { "fid": "f06", "url": "/ta/calc/tot", "name": "Totalt", "active": false };
      po.avd = { "fid": "f06", "url": "/ta/calc/avd", "name": "Avdelning", "active": false };
      po.day = { "fid": "f06", "url": "/ta/calc/day", "name": "Dag", "active": false };
      po.per = { "fid": "f06", "url": "/ta/calc/per", "name": "Period", "active": false };
      this.state.po = po;
      this.state.pa = [];
      this.state.pa = Object.values(po);
  }
    fknGo(row) {
        sso.seo.sfkn = row;
        this.props.navigate(row.url);
    }
    br_css(row) {
        var css = {};
        if(sso.seo.sfkn && (row.url == sso.seo.sfkn.url)) css.color = "#080";
        //var brc = false;
        //if(row.url == sbo.state.url) brc = true;
        return css;
    }

  render() {
      //var path = window.location.pathname;
      //var op = path.split("/").pop();

    return (
        <div className="web-app">
            { this.props.store.cfg.bread ?
                <div className="web-bread">
                    <div className="breadcrumb">
                        { this.state.pa.map((row, key) =>
                            <div key={key} className="breadcrumb-item" style={this.br_css(row)} onClick={() => this.fknGo(row)}>{row.name}</div>
                        )}
                        <div className="flex"></div>
                        <div className="cdigreen">{ sso.seo.sfkn ? sso.seo.sfkn.name : null }</div>
                    </div>
                </div>
                : null }
        <Routes>
          <Route path="/xx" component={pg_home} />
            <Route path="tot" element={ <Calc_Tot store={this.store} />} />
            <Route path="avd" element={ <Calc_Avd store={this.store} />} />
            <Route path="day" element={ <Calc_Day store={this.store} />} />
            <Route path="per" element={ <Calc_Per store={this.store} />} />
            <Route path="*" element={ <Calc_Home store={this.store} />} />
        </Routes>
    </div>
  );
}
}
//Sys.contextType = ADOContext;

export default withRouter(Ta_Calc);
