import React, { useState, useContext } from 'react';

import { observer } from 'mobx-react';
import { useStore } from '../../sdo.js'

function Pos_Menu(props) {
    let [state, setState] = useState({row: {}, htitle: "", ma: []});
    let ctx = useStore();

    var lock = "lock";
    var moa = {};
    moa["f00"] = { fid: "f00", name: "Hem", url: "/tst", fa: "fa fa-home flex", ma: [] };
    moa["f01"] = { fid: "f01", name: "Status", url: "/pos/stat", fa: "fa fa-key flex", ma: [] };
    moa["f02"] = { fid: "f02", name: "Drift", url: "/tst/list", fa: "fa fa-play flex", ma: [] };
    moa["f03"] = { fid: "f03", name: "Ekonomi", url: "/pos/eko", fa: "fa fa-chart-bar flex", ma: [] };
    moa["f04"] = { fid: "f04", name: "Produkter", url: "/pos/prds", fa: "fa fa-desktop flex", ma: [] };
    moa["f05"] = { fid: "f05", name: "Lager", url: "/pos/lager", fa: "fa fa-list-alt flex", ma: [] };
    moa["f06"] = { fid: "f06", name: "Backoffice", url: "/pos/bo", fa: "fa fa-cogs flex", ma: [] };

    moa["f01"].ma.push( { fid: "m01", name: "Totalstatus", url: "/pos/stat/total", fa: "fa fa-bars" } );
    moa["f01"].ma.push( { fid: "m01", name: "Timstatus", url: "/pos/stat/hh", fa: "fa fa-bars" } );
    moa["f01"].ma.push( { fid: "m01", name: "Veckostatus", url: "/pos/stat/week", fa: "fa fa-bars" } );
    moa["f01"].ma.push( { fid: "m01", name: "Transaktioner", url: "/pos/stat/trans", fa: "fa fa-bars" } );
    moa["f01"].ma.push( { fid: "m01", name: "Bord / Notor", url: "/pos/stat/bord", fa: "fa fa-bars" } );
    moa["f01"].ma.push( { fid: "m01", name: "Artikelstatus", url: "/pos/stat/art", fa: "fa fa-bars" } );

    moa["f02"].ma.push( { fid: "m01", name: "Kassa", url: "/pos/drift/kassa", fa: "fa fa-bars" } );
    moa["f02"].ma.push( { fid: "m01", name: "Aktivitet", url: "/pos/drift/akt", fa: "fa fa-bars" } );
    moa["f02"].ma.push( { fid: "m01", name: "Bordsvy", url: "/pos/drift/bordsvy", fa: "fa fa-bars" } );
    moa["f02"].ma.push( { fid: "m01", name: "Bordsstatus", url: "/pos/drift/bordstatus", fa: "fa fa-bars" } );
    moa["f02"].ma.push( { fid: "m01", name: "Bongkö", url: "/pos/drift/bq", fa: "fa fa-bars" } );
    moa["f02"].ma.push( { fid: "m01", name: "Kvitto information", url: "/pos/drift/ki", fa: "fa fa-bars" } );

    moa["f03"].ma.push( { fid: "m01", name: "Rapporter", url: "/pos/eko/rps", fa: "fa fa-bars" } );
    moa["f03"].ma.push( { fid: "m01", name: "Listor", url: "/pos/eko/list", fa: "fa fa-bars" } );
    moa["f03"].ma.push( { fid: "m01", name: "Konto", url: "/pos/eko/konto", fa: "fa fa-bars" } );
    moa["f03"].ma.push( { fid: "m01", name: "Transaktioner", url: "/pos/eko/trans", fa: "fa fa-bars" } );
    moa["f03"].ma.push( { fid: "m01", name: "Kvitton", url: "/pos/eko/kvitton", fa: "fa fa-bars" } );

    moa["f04"].ma.push( { fid: "m01", name: "Struktur", url: "/pos/prds/struktur", fa: "fa fa-bars" } );
    moa["f04"].ma.push( { fid: "m01", name: "PLU", url: "/pos/prds/plus", fa: "fa fa-bars" } );
    moa["f04"].ma.push( { fid: "m02", name: "Knappar", url: "/pos/prds/btns", fa: "fa fa-bars" } );
    moa["f04"].ma.push( { fid: "m03", name: "Tillval", url: "/pos/prds/addons", fa: "fa fa-bars" } );
    moa["f04"].ma.push( { fid: "m04", name: "Villkor", url: "/pos/prds/cond", fa: "fa fa-bars" } );
    moa["f04"].ma.push( { fid: "m04", name: "Produktval", url: "/pos/prds/psel", fa: "fa fa-bars" } );
    moa["f04"].ma.push( { fid: "m04", name: "Menykoppling", url: "/pos/prds/menus", fa: "fa fa-bars" } );
    moa["f04"].ma.push( { fid: "m04", name: "Happyhour", url: "/pos/prds/happy", fa: "fa fa-bars" } );
    moa["f04"].ma.push( { fid: "m04", name: "Pristabell", url: "/pos/prds/pt", fa: "fa fa-bars" } );

    moa["f06"].ma.push( { fid: "m01", name: "Kontodefinition", url: "/pos/bo/konto", fa: "fa fa-bars" } );
    moa["f06"].ma.push( { fid: "m02", name: "Avdelningar", url: "/pos/bo/deps", fa: "fa fa-bars" } );
    moa["f06"].ma.push( { fid: "m03", name: "Varugrupper", url: "/pos/bo/vgrps", fa: "fa fa-bars" } );
    moa["f06"].ma.push( { fid: "m03", name: "Betalmedel", url: "/pos/bo/pays", fa: "fa fa-bars" } );
    moa["f06"].ma.push( { fid: "m03", name: "Moms", url: "/pos/bo/tax", fa: "fa fa-bars" } );
    moa["f06"].ma.push( { fid: "m03", name: "Valuta", url: "/pos/bo/cur", fa: "fa fa-bars" } );
    moa["f06"].ma.push( { fid: "m03", name: "Kassörer", url: "/pos/bo/cas", fa: "fa fa-bars" } );
    moa["f06"].ma.push( { fid: "m03", name: "Terminal def", url: "/pos/bo/tdef", fa: "fa fa-bars" } );
    moa["f06"].ma.push( { fid: "m03", name: "Terminal Cfg", url: "/pos/bo/tcfg", fa: "fa fa-bars" } );

    var aa = [];
    var keys = Object.keys(moa);
    for(var key of keys) {
        var o = moa[key];
        aa.push( o );
    }

    var ma = [];
    var mnuHeight = window.innerHeight - 100;

   function fknGo(row) {
    //var sdo = useContext(SDO);
    //alert(fkn);
    var fkn = row.fid;
    setState({ row : row,  ma : [] });
    ctx.fid = fkn;

       this.props.navigate(row.url);
   };
   function fknFix(fkn) {
    //var sdo = useContext(SDO);
    //alert(fkn);
    ctx.fid = fkn;

    if(fkn == "lock") this.props.navigate('/web');
    if(fkn == "logout") this.props.navigate('/');
   };
   function mnuGo(row) {
    //var sdo = useContext(SDO);
    //alert(fkn);
    //ctx.fid = fkn;
    setState({ row : state.row,  ma : [] });

       this.props.navigate(row.url);
   };
   function hideMnu() {
    setState({row : state.row, ma : [] });
   };
   function mouseEnter(it) {
    setState({row : state.row, htitle : it.name, ma : it.ma });
   };
   function mouseLeave() {
    setState({row : state.row, ma : [] });
   };

   function row_css(row) {
  	    var css = {};
        css.margin = "0px";
        css.padding = "0px";
        css.height = "55px";
        css.borderBottom = "1px #ccc solid";

		if(state.row) {
		if(state.row.fid == row.fid) {
			css.background = "#080";
			css.color = "#fff";
		}
		}
		return css;
    }
   function row_icon_css(row) {
  	    var css = {};
        css.padding = "10px";

		if(state.row) {
		if(state.row.fid == row.fid) {
			//css.color = "#ff9900";
			css.color = "#fff";
		}
		}
		return css;
    }

   //<Icon className={ it.fa } style={{ fontSize: 18 }} />
   var html =
        <div className="">
        <div className="cdi_xmnu flex-fullcol" style={{padding: "5px 0px"}}>
            { aa.map((it, key) =>
            <div onClick={() => fknGo(it)} className="web-col flex-cc xmnu_tab" style={ row_css(it) } onMouseEnter={ () => mouseEnter(it) }>
                <i className={ it.fa } aria-hidden="true" style={ row_icon_css(it) }></i>
                <div style={{fontSize: "9px"}}>{ it.name }</div>
            </div>
            )}
            <div onClick={ () => fknFix("lock") } className="web-col flex-cc xmnu_tab">
                <i className="fa fa-lock flex" aria-hidden="true" style={{padding: "10px"}}></i>
                <div style={{fontSize: "9px"}}>Lås</div>
            </div>
            <div onClick={() => fknFix("logout") } className="web-col flex-cc xmnu_tab" >
                <i className="fa fa-power-off flex" aria-hidden="true" style={{padding: "10px"}}></i>
                <div style={{fontSize: "9px"}}>Logga ur</div>
            </div>
        </div>

            { state.ma.length ?
        <div className="mtrl_mnu_sub mtrl_mnu_bg" aria-label="Sub Menu" style={{height: mnuHeight}} onMouseLeave={mouseLeave}>
          <div class="mtrl_mnu_title" onClick={() => hideMnu()}>
            { state.htitle }
          </div>
            { state.ma.map((mi, key) =>
          <div className="mtrl_mnu_item flex-row" onClick={ () => mnuGo(mi) }>
            <div className="flex">{ mi.name }</div>
            <i className={ mi.fa } aria-hidden="true" style={ row_icon_css(mi) }></i>
          </div>
            )}
        </div>
                : null }
        </div>
    ;
    return html;
}

export default observer(Pos_Menu);
