import React, { useState, useEffect, useContext } from 'react';
import {format} from "date-fns";

import * as net from "../../../../lib/net";
import PdfNotaDlg from "./pdf_nota_dlg";
import Split_Row from "./split_row";
import Split_To_Gst from "./split_to_gst";
import Move_To_Gst from "./move_to_gst";
import Td_Sel from "../../../../lib/ui/td_sel";
import sso from "../../../../sso";

class Pms_NotaRst extends React.Component {
  constructor(props, context) {
    super(props);

     this.selitems = [
        {fkn: 'setup', text: 'INSTÄLLNINGAR'},
        {fkn: 'upd', text: 'UPPDATERA'},
        {fkn: 'sys', text: 'SYSTEM'},
        {fkn: 'logout', text: 'LOGGA UT'}
    ];
     this.rowitems = [
        {fkn: 'open', text: 'ÖPPNA'},
        {fkn: 'del', text: 'HANTERA'}
    ];

    this.fkn_close = null;
    this.store = props.store;

    this.state = {};
    this.state.ynq = [];

    this.state.paa = [];
    this.state.aa = [];
    this.state.selrow = {};
    this.state.srow = {};
    this.state.vy = 0;

    this.state.boknr = "";
    this.state.row = {};
    this.state.rvo = {};
    this.state.nto = {};
    this.state.nta = [];
    if(props.wnds) {
        //this.fkn_close = props.wnds.fkn_close;
        this.state.boknr = props.wnds.boknr;
        this.state.gso = props.wnds.gso;
        this.state.datum = props.wnds.datum;
        this.state.avd = props.wnds.avd;
        this.state.notanr = props.wnds.notanr;
        this.state.rvo = props.wnds.rvo;
        this.state.row = props.wnds.row;
    }
    this.state.wnds = {};

    this.state.today = new Date();
    var dts = format(this.state.today, "yyyy-MM-dd");
    this.state.dts = dts;

    this.state.pdf = 0;
    this.state.pdf_content = null;
    this.state.loading = 0;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      //alert(JSON.stringify(this.state.nto));
      this.nota_rst();
  }
  pdf_done (ret) {
    this.setState({loading: 0, pdf: 0, pdf_content: null });
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }

    wnd_close() {
      if(this.fkn_close) this.fkn_close(this.props.ctx);
      else this.props.ctx.setState({ wnds: {} });
	};
  tb_style() {
      var css = {};
      //css.height = window.innerHeight - 275;
      css.overflow = "auto";
      return css;
  }
  row_style(row) {
    var srow = this.state.srow;
    var css = {};
    if(srow[row.r0dateid]) {
        css.background = "#080";
        css.color = "#fff";
    }
    var belopp = (row.r0noof * 1) * (row.r0perprice * 1);
    if(belopp == 0) {
        css.background = "#fff";
        css.color = "#800";
        css.display = "none";
    }
    if(row.r0init != "0") {
        css.background = "#fff";
        css.color = "#800";
        css.display = "none";
    }
    if(row.r0rkorr != "0") {
        css.background = "#fff";
        css.color = "#800";
        css.display = "none";
    }
      if(row.r0text.substr(26,4) == "KORR") {
          css.background = "#fff";
          css.color = "#800";
          css.display = "none";
      }
    if( (row.r0ttyp != "P") && (row.r0ttyp != "B") ) {
        css.background = "#fff";
        css.color = "#800";
        css.display = "none";
    }
    if( (row.r0ttyp != "P") && (row.r0ttyp != "B") ) {
        if(this.state.vy == 0) {
            css.background = "#fff";
            css.color = "#800";
            css.display = "none";
        }
    }
    return css;
  }
  show_prn() {
    this.setState({vy: 0});
  }
  show_all() {
      this.setState({vy: 1});
  }
  clear() {
    this.setState({srow: {}});
  }
  set_all() {
    var srow = this.state.srow;
    for(var row of this.state.nta) {
        srow[row.etr02.e0lopnr] = row;
    }
    this.setState({srow: srow});
  }
  sel_row_fkn(row, e) {
  }
  sel_row(row) {
    var srow = this.state.srow;

    if(srow[row.etr02.e0lopnr]) {
      delete( srow[row.etr02.e0lopnr] );
    }
    else srow[row.etr02.e0lopnr] = row;
    this.setState({srow: srow});
  }
  nota_rst() {
    var self = this;

    var prm = {};
    //prm.req = "pos.pos_eko.invh_copy";
    prm.req = "pos.pos_eko.trh_nota";
    prm.sid = "S0001612";
    prm.datum = this.state.datum;
    prm.avd = this.state.avd;
    prm.notanr = this.state.notanr;
    //alert(JSON.stringify(prm));

    //net.sio_req(prm)
    net.sid_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        self.setState({nta: ret.rca });
        self.syncTb("rstnotaTable");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
    syncTb(tb){
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
        tdElements = tbel.getElementsByTagName('td');
        if(tdElements.length == 0) {
            //alert("No td");
             return;
       }
        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
            width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
  val_date(val) {
      var ret = "";
      if(val) ret = val.toString().cdidate();
      return ret;
  }
  prn_spec() {
    var self = this;

    var prm = {};
    prm.req = "pms.charge.prn_spec";
    prm.boknr = this.state.boknr;
    prm.glopnr = this.state.gso.r0glopnr;
    prm.notanr = this.state.nto.notanr;
    //alert(JSON.stringify(prm));
    //return;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.ok));

        //var uri = "data:application/pdf;base64," + ret.pdf;
        self.setState({pdf: 1, pdf_content: ret.pdf });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  prn_kvitto() {
    var self = this;

    var prm = {};
    prm.req = "pms.charge.kvitto_gst";
    prm.boknr = this.state.boknr;
    prm.glopnr = this.state.gso.r0glopnr;
    prm.gso = this.state.gso;
    prm.notanr = this.state.nto.notanr;
    prm.prnnr = sso.seo.prnnr;
    //alert(JSON.stringify(prm));
    //return;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.ok));

        //var uri = "data:application/pdf;base64," + ret.pdf;
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  prn_mx() {
    var self = this;
  }
  split_to_gst() {
    var self = this;
    var wnds = {};
    wnds.splittogst = true;
    wnds.boknr = this.state.rvo.boknr;
    wnds.gsb00 = this.state.rvo.gsb00;
    wnds.etr01 = this.state.row.etr01;
    //wnds.fkn_close = self.wnds_close;
    self.setState({ wnds: wnds });
  }
  move_to_gst() {
    var self = this;
    var wnds = {};
    wnds.movetogst = true;
    wnds.boknr = this.state.rvo.boknr;
    wnds.gsb00 = this.state.rvo.gsb00;
    wnds.etr01 = this.state.row.etr01;
    //wnds.fkn_close = self.wnds_close;
    self.setState({ wnds: wnds });
  }
  split_row() {
    var self = this;
    var wnds = {};
    wnds.splitrow = true;
    wnds.boknr = this.state.rvo.boknr;
    wnds.gsb00 = this.state.rvo.gsb00;
    wnds.etr01 = this.state.row.etr01;
    //wnds.fkn_close = self.wnds_close;
    self.setState({ wnds: wnds });
  }

  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "";

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="m2_backdrop" /> : null }
        <div className="m2_dlg mx_70h flex-col" style={ this.dlg_style() }>
        <div className="flex-row " style={{padding:"0px",height:"50px",lineHeight:"50px", background: "#1a456b", color: "#fff"}}>
                <div className="flex-row flex" style={{padding:"0px 5px", fontSize: "22px"}}>
                    <div>RESTAURANG NOTA { this.state.notanr }</div>
                </div>
            <div className="wnd-close-btn" onClick={() => this.wnd_close() }>X</div>
        </div>
        <div className="mx_content flex">

        <div className="web-col hide">
            <div>NOTANR: { this.state.notanr }</div>
        </div>
        <div className="web-col flex" style={{padding: "0px"}}>

        <div id="rstnotaTable" className="flex">
          <table className="table table-bordered table-hover tb" style={{marginBottom: "0px"}}>
            <thead>
            <tr>
                <th>Datum</th>
                <th>Typ</th>
                <th>Text</th>
                <th>Antal</th>
                <th>Pris</th>
                <th>Summa</th>
                <th>Tecken</th>
                <th>Löpnr</th>
            </tr>
            </thead>
        </table>
        <div style={ this.tb_style() }>
          <table className="table table-bordered table-hover tb">
            <tbody>
            { this.state.nta.map((row, key) =>
            <tr key={key} style={ this.row_style(row) } onClick={ () => this.sel_row(row) } onDoubleClick={ () => this.row_sel(row) }>
                <td>{ this.val_date( row.r0datum ) }</td>
                <td>{ row.r0time }</td>
                <td>{ row.r0etyp }</td>
                <td>{ row.r0text.toswe() }</td>
                <td className="text-center">{ row.r0noof.ztrim() }</td>
                <td className="text-right">{ row.r0perprice.cdipris() }</td>
                <td className="text-right">{ ((row.r0noof * 1) * (row.r0perprice * 1)).cdipris() }</td>
                <td>{ row.r0tkn }</td>
                <td>
                    <Td_Sel items={ this.rowitems } getValue={(e)=> this.sel_row_fkn(row, e)} />
                </td>
            </tr>
            )}
            </tbody>
        </table>
        </div>
        </div>
        <div className="flex-row px-2">
            <div className="web-link" onClick={() => this.show_prn() }>Visa utskriift</div>
            <div className="flex"></div>
            <div className="web-link" onClick={() => this.show_all() }>Visa alla</div>
        </div>

        <div className="divider_orange"></div>

        <div className="web-row">
        <div className="web-col flex">
            <div className="wnd-btn" onClick={() => this.split_to_gst() } >
                <div>DELA TILL GÄST</div>
                <div></div>
            </div>
        </div>
         <div className="web-col flex" style={{padding: "0px 10px"}}>
            <div className="wnd-btn" onClick={() => this.move_to_gst() } >
                <div>FLYTTA TILL GÄST</div>
                <div></div>
            </div>
       </div>
         <div className="web-col flex" style={{padding: "0px 10px"}}>
            <div className="wnd-btn" onClick={() => this.split_row() } >
                <div>DELA RAD</div>
                <div></div>
            </div>
       </div>
       </div>

        <div className="web-row">
        <div className="web-col flex">
            <div className="wnd-btn" onClick={() => this.wnd_close() } >
                <div>STÄNG</div>
                <div></div>
            </div>
        </div>
         <div className="web-col flex" style={{padding: "0px 10px"}}>
            <div className="wnd-btn" onClick={() => this.prn_spec() } >
                <div>VISA KVITTO</div>
                <div></div>
            </div>
       </div>
         <div className="web-col flex" style={{padding: "0px 10px"}}>
            <div className="wnd-btn" onClick={() => this.prn_kvitto() } >
                <div>SKRIV UT</div>
                <div></div>
            </div>
       </div>
       </div>

        </div>

        </div>
      </div>
    { this.state.wnds.splitrow ? <Split_Row wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
    { this.state.wnds.splittogst ? <Split_To_Gst wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
    { this.state.wnds.movetogst ? <Move_To_Gst wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
        <PdfNotaDlg show={this.state.pdf} pdf={this.state.pdf_content} done={(ret) => this.pdf_done(ret)} backdrop={true}/>
    </div>
	;
	return html;
  };
}

export default Pms_NotaRst;