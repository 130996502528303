import React, { useState, useEffect, useContext } from 'react';
import {format} from "date-fns";

import * as net from "../../../../lib/net";
import Dd_Sel from "../../../../lib/ui/dd_sel";
import Td_Sel from "../../../../lib/ui/td_sel";

class Pay_Sz extends React.Component {
  constructor(props, context) {
    super(props);

     this.selitems = [
        {fkn: 'setup', text: 'INSTÄLLNINGAR'},
        {fkn: 'upd', text: 'UPPDATERA'},
        {fkn: 'sys', text: 'SYSTEM'},
        {fkn: 'logout', text: 'LOGGA UT'}
    ];

    this.fkn_close = null;
    this.state = {};
    this.state.ynq = [];

    this.state.paa = [];
    this.state.aa = [];
    this.state.selrow = {};
    this.state.srow = {};

    this.state.boknr = "";
    this.state.rvo = {};
    this.state.nta = [];
    if(props.wnds) {
        this.fkn_close = props.wnds.fkn_close;
        this.state.boknr = props.wnds.boknr;
        this.state.gso = props.wnds.gso;
        this.state.nta = props.wnds.nta;
    }

    this.state.today = new Date();
    var dts = format(this.state.today, "yyyy-MM-dd");
    this.state.dts = dts;

     this.state.pays = [
        {betkod: 'kont', text: 'KONTANT', konto: "1910"},
        {betkod: 'fakt', text: 'FAKTURA', konto: "1910"},
        {betkod: 'mank', text: 'KORT MANUELL', konto: "1910"},
        {betkod: 'swish', text: 'SWISH', konto: "1910"}
    ];
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      this.syncTb("paymentsTable")
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }

    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    wnd_close() {
      if(this.fkn_close) this.fkn_close(this.props.ctx);
      else this.props.ctx.setState({ wnds: {} });
	};
  tb_style() {
      var css = {};
      //css.height = window.innerHeight - 275;
      css.overflow = "auto";
      return css;
  }
  row_style(row) {
    var srow = this.state.srow;
    var css = {};
    if(srow[row.betkod]) {
        css.background = "#080";
        css.color = "#fff";
    }
    return css;
  }
  clear() {
    this.setState({srow: {}});
  }
  set_all() {
    var srow = this.state.srow;
    for(var row of this.state.pays) {
        srow[row.betkod] = row;
    }
    this.setState({srow: srow});
  }
  sel_row(row) {
    var srow = this.state.srow;

    if(srow[row.betkod]) {
      delete( srow[row.betkod] );
    }
    else srow[row.betkod] = row;
    this.setState({srow: srow});
  }
  pay_trs(betkod) {
    var self = this;

    if(Object.keys(this.state.srow).length < 1) {
        alert("Inga rader betala");
        return;
    }

    if(betkod == "emv") {
        return;
    }
    if(betkod == "fakt") {
        return;
    }
    if(betkod == "pres") {
        return;
    }

    var pra = [];
    //pra.push( this.state.selrow.etr01);
    var belopp = 0;
    for(var row of Object.values(this.state.srow)) {
        var pris = row.etr01.e0pris * 1;
        belopp = belopp + pris
        pra.push( row.etr01);
    }

    if(belopp == 0) {
        alert("Inget att betala");
        return;
    }
    belopp = belopp.toString();

    var prm = {};
    prm.req = "pms.charge.pay_trs";
    prm.boknr = this.state.rvo.boknr;
    prm.glopnr = this.state.rvo.gsb00.r0glopnr;
    prm.payrows = pra;
    prm.paycode = betkod;
    prm.paytext = "BET: " + betkod;
    prm.payamount = belopp;
    prm.tkn = "+";
    prm.datum = this.state.dts;
    //alert(JSON.stringify(prm));

    net.sio_req(prm)
    .then(function(ret){
        alert(JSON.stringify(ret));
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
    syncTb(tb){
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
        tdElements = tbel.getElementsByTagName('td');
        if(tdElements.length == 0) {
            //alert("No td");
             return;
       }

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
            width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
  sz() {
  }
  val_date(val) {
      var ret = "";
      if(val) ret = val.toString().cdidate();
      return ret;
  }

  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "";

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="m2_backdrop" /> : null }
        <div className="m2_dlg mx_50h flex-col" style={ this.dlg_style() }>
        <div className="flex-row " style={{padding:"0px",height:"50px",lineHeight:"50px", background: "#1a456b", color: "#fff"}}>
                <div className="flex-row flex" style={{padding:"0px 5px", fontSize: "22px"}}>
                    <div>BETAL SÖK { this.state.rumsnr }</div>
                </div>
            <div className="wnd-close-btn" onClick={() => this.wnd_close() }>X</div>
        </div>
        <div className="mx_content flex">

        <div className="web-col flex" style={{padding: "0px"}}>

        <div id="paymentsTable" className="flex">
          <table className="table table-bordered table-hover tb" style={{marginBottom: "0px"}}>
            <thead>
            <tr>
                <th>Betalkod</th>
                <th>Text</th>
                <th>Konto</th>
                <th></th>
            </tr>
            </thead>
        </table>
        <div style={ this.tb_style() }>
          <table className="table table-bordered table-hover tb">
            <tbody>
            { this.state.pays.map((row, key) =>
            <tr key={key} style={ this.row_style(row) } onClick={ () => this.sel_row(row) }>
                <td>{ row.betkod }</td>
                <td>{ row.text }</td>
                <td>{ row.konto }</td>
                <td>
                    <Td_Sel items={ this.rowitems } getValue={(e)=> this.sel_row_fkn(row, e)} />
                </td>
            </tr>
            )}
            </tbody>
        </table>
        </div>
        </div>

        <div className="divider_orange"></div>

        <div className="web-row">
        <div className="web-col flex">
            <div className="wnd-btn" onClick={() => this.wnd_close() } >
                <div>STÄNG</div>
                <div></div>
            </div>
        </div>
         <div className="web-col flex" style={{padding: "0px 10px"}}>
       </div>
         <div className="web-col flex" style={{padding: "0px 10px"}}>
            <div className="wnd-btn" onClick={() => this.pay_sel() } >
                <div>VÄLJ</div>
                <div></div>
            </div>
       </div>
       </div>

        </div>

        </div>
      </div>
    </div>
	;
	return html;
  };
}

export default Pay_Sz;