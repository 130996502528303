import React, { useState, useEffect, useContext } from 'react';
import {addDays, format} from "date-fns";

import * as net from "../../../lib/net";
import * as utils from "../../../assets/utils";

import Td_Sel from "../../../lib/ui/td_sel";
import Dt_Cal from "../../../lib/ui/dt_cal";
import Di_Edit from "./fkn/di_edit";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Reg_Di extends React.Component {
  constructor(props, context) {
    super(props);

      this.dt_fd = React.createRef();
      this.dt_td = React.createRef();

    this.store = props.store;
    this.state = {};
    this.state.avd = "";
    this.state.dia = [];
    this.state.kro = {};
    this.state.aa = [];
    this.state.wnds = {};

      this.state.today = new Date();
      this.state.sdat = format(this.state.today, "yyyyMMdd");
      this.state.fdat = format(this.state.today, "yyyy-MM-dd");
      var tdat = addDays(this.state.today, 30);
      this.state.tdat = format(tdat, "yyyy-MM-dd");

      this.selitems = [
          {fkn: 'open', text: 'ÖPPNA'},
          {fkn: 'status', text: 'STATUS'},
          {fkn: 'clear', text: 'RENSA'}
      ];
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.di();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
    tb_style() {
        var css = {};
        css.height = window.innerHeight - 275;
        css.overflow = "auto";
        return css;
    }
    th_style(id) {
        var css = {};
        css.cursor = "pointer";
        if(id == this.state.sortid) {
            if(this.state.sortdir) css.color = "#800";
            else css.color = "#080";
        }
        return css;
    }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }
    dt_fdat(e) {
        //alert(JSON.stringify(e));
        var dts = format(e, "yyyy-MM-dd");
        this.setState({ fdat: dts });
    }
    dt_tdat(e) {
        //alert(JSON.stringify(e));
        var dts = format(e, "yyyy-MM-dd");
        this.setState({ tdat: dts });
    }
    oneday(e) {
        //alert(JSON.stringify(e));
        //this.setState({ tdat: this.state.fdat });
        this.dt_td.current.updateDt(this.state.fdat);
    }
    addday(e) {
        //alert(JSON.stringify(e));
        //this.setState({ tdat: this.state.fdat });
        this.dt_fd.current.updateAdd();
        this.dt_td.current.updateAdd();
    }
    subday(e) {
        //alert(JSON.stringify(e));
        //this.setState({ tdat: this.state.fdat });
        this.dt_fd.current.updateSub();
        this.dt_td.current.updateSub();
    }

    di_edit(row) {
        var self = this;
        var wnds = {};
        wnds.diedit = true;
        wnds.kao = row;
        wnds.cb = self.di_close;
        this.setState({ wnds: wnds });
    }
    di_close(ctx) {
        ctx.setState({ wnds: {} });
        ctx.di();
    }
  di() {
    var self = this;
    var prm = {};
    prm.req = "pms.di.dia";
    prm.fdat = this.state.fdat;
    prm.tdat = this.state.tdat;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        self.setState({dia: ret.rca }, function () {
            self.syncTb("diTable");
        });
    })
    .catch(function(e) {
        var txt = JSON.stringify(e);
    });
  }
    syncTb(tb) {
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
            tdElements = tbel.getElementsByTagName('td');
        if(tdElements.length < 1 ) return;

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
                width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
    tb_sort(typ) {
        var self = this;
        //var rvs = self.state.rvs;
        var tha = self.state.tha;
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;

        if(sortid != typ) sortdir = 0;
        else {
            if(sortdir) sortdir = 0;
            else sortdir = 1;
        }

        if(typ) {
            if(sortdir) tha.sort((a,b) => (a[typ] < b[typ]) ? 1 : ((b[typ] < a[typ]) ? -1 : 0));
            else tha.sort((a,b) => (a[typ] > b[typ]) ? 1 : ((b[typ] > a[typ]) ? -1 : 0));
        }
        self.setState({ tha: tha, sortid: typ, sortdir: sortdir });
    }
    th_icon(typ) {
        var cls = "";
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;
        if(sortid == typ)
        {
            if(sortdir) cls = "fa fa-caret-up";
            else cls = "fa fa-caret-down";
        }
        return cls;
    }
    sel_row_fkn(row, sel) {
        //alert(JSON.stringify(fkn));
        if(sel.fkn == "open") {
            //this.sel_gst(row);
        }
        if(sel.fkn == "br") {
        }
    }

  render() {
    return (
    <div className="web-app">
    <div className="flex-row flex-space-between">
        <div className="flex-row">
            <div className="dt-btn-01" onClick={() => this.subday() } ><FontAwesomeIcon icon="minus" size="1x" /></div>
            <Dt_Cal dts={this.state.fdat} rp="1" ref={this.dt_fd} getValue={(e)=> this.dt_fdat(e)} />
            <div className="dt-btn-01" onClick={() => this.oneday() } >1</div>
            <Dt_Cal dts={this.state.tdat} rp="1" ref={this.dt_td} getValue={(e)=> this.dt_tdat(e)} />
            <div className="dt-btn-01" onClick={() => this.addday() } ><FontAwesomeIcon icon="plus" size="1x" /></div>
        </div>
        <div className="flex"></div>
        <button className="btn btn-hw-blue w150" onClick={() => this.di() } aria-label="System" >
            UPPDATERA
        </button>
    </div>
    <div className="divider_orange"></div>

        <div id="diTable" className="web-col">
            <table className="table table-bordered table-hover tb " style={{marginBottom: "0px"}}>
                <thead>
                <tr>
                    <th style={ this.th_style("r1typ") } onClick={() => this.tb_sort("r1typ") }>Datum <i className={ this.th_icon("r1typ") }></i></th>
                    <th style={ this.th_style("r1sort") } onClick={() => this.tb_sort("r1sort") }>Vecka <i className={ this.th_icon("r1sort") }></i></th>
                    <th style={ this.th_style("r1tillgr") } onClick={() => this.tb_sort("r1tillgr") }>Dag <i className={ this.th_icon("r1tillgr") }></i></th>
                    <th style={ this.th_style("r1closed") } onClick={() => this.tb_sort("r1closed") }>Information <i className={ this.th_icon("r1closed") }></i></th>
                    <th style={ this.th_style("r1max") } onClick={() => this.tb_sort("r1max") }>Pristyp <i className={ this.th_icon("r1max") }></i></th>
                    <th style={ this.th_style("r1lediga") } onClick={() => this.tb_sort("r1lediga") }>Färg <i className={ this.th_icon("r1lediga") }></i></th>
                    <th style={ this.th_style("status") } onClick={() => this.tb_sort("status") }>Status <i className={ this.th_icon("status") }></i></th>
                    <th></th>
                </tr>
                </thead>
            </table>
            <div className="tb-cdi" style={ this.tb_style() }>
                <table className="table table-bordered table-hover tb">
                    <tbody>
                    { this.state.dia.map((row, key) =>
                        <tr key={key} onDoubleClick={() => this.di_edit(row) }>
                            <td className="px-2">{ utils.toDate( row.datum ) }</td>
                            <td className="text-center">{ row.vecka }</td>
                            <td className="text-center">{ row.dag }</td>
                            <td className="text-center">{ utils.toLang( row.info ) }</td>
                            <td className="text-center">{ utils.toLang( row.priskat ) }</td>
                            <td className="text-center">{ utils.toLang( row.farg ) }</td>
                            <td className="text-center">{ utils.toLang( row.status ) }</td>
                            <td>
                                <Td_Sel items={ this.selitems } getValue={(e)=> this.sel_row_fkn(row, e)} />
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
        </div>
        { this.state.wnds.diedit ? <Di_Edit wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
   </div>
  );
    }
}
export default Reg_Di;
