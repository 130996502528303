import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../../lib/net";
import * as utils from "../../../assets/utils";
import Td_Sel from "../../../lib/ui/td_sel";
import Dt_Cal from "../../../lib/ui/dt_cal";
import {format} from "date-fns";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Reg_RtInit extends React.Component {
  constructor(props, context) {
    super(props);


      this.dt_fd = React.createRef();
      this.dt_td = React.createRef();
      this.store = props.store;
    this.pms = props.store.pms;

    this.state = {};
    this.state.cols = {};
    this.state.wnds = {};
    this.state.lrwrk = false;
      this.state.ix = 0;
      this.state.rumsnr = "";
      this.state.fdat = "";
      this.state.tdat = "";
      if(props.wnds.fdat) {
          this.state.fdat = props.wnds.fdat;
      }
      if(props.wnds.tdat) {
          this.state.tdat = props.wnds.tdat;
      }
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.rr();
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }

    wnd_close = () => {
		this.props.ctx.setState({ wnds: {} });
	};
    tb_style() {
        var css = {};
        css.height = window.innerHeight - 275;
        css.overflow = "auto";
        return css;
    }

    change_rt(e) {
        //alert(JSON.stringify(e));
        var rt = e.target.value;
        this.setState({ rt: rt });
    }
    dt_fdat(e) {
        //alert(JSON.stringify(e));
        var dts = format(e, "yyyy-MM-dd");
        this.setState({ fdat: dts });
    }
    dt_tdat(e) {
        //alert(JSON.stringify(e));
        var dts = format(e, "yyyy-MM-dd");
        this.setState({ tdat: dts });
    }
    oneday(e) {
        //alert(JSON.stringify(e));
        //this.setState({ tdat: this.state.fdat });
        this.dt_td.current.updateDt(this.state.fdat);
    }
    addday(e) {
        //alert(JSON.stringify(e));
        //this.setState({ tdat: this.state.fdat });
        this.dt_fd.current.updateAdd();
        this.dt_td.current.updateAdd();
    }
    subday(e) {
        //alert(JSON.stringify(e));
        //this.setState({ tdat: this.state.fdat });
        this.dt_fd.current.updateSub();
        this.dt_td.current.updateSub();
    }

    rt_clear() {
        var self = this;
        var fdat = this.state.fdat.replaceAll("-", "");
        var tdat = this.state.tdat.replaceAll("-", "");

        var prm = {};
        prm.req = "pms.rt.rt_clear";
        prm.fdat = fdat;
        prm.tdat = tdat;

        net.sio_req(prm)
            .then(function(ret){
                alert(JSON.stringify(ret));

                //self.setState({rta: ret.rco });
            })
            .catch(function(e) {
                var txt = JSON.stringify(e);
                //gda.wndError(txt);
            });
    }
    rt_rumclear() {
        var self = this;
        var fdat = this.state.fdat.replaceAll("-", "");
        var tdat = this.state.tdat.replaceAll("-", "");

        var prm = {};
        prm.req = "pms.rt.rt_gsb_clear";
        prm.fdat = fdat;
        prm.tdat = tdat;

        net.sio_req(prm)
        .then(function(ret){
            alert(JSON.stringify(ret));

            //self.setState({rta: ret.rco });
        })
        .catch(function(e) {
            var txt = JSON.stringify(e);
            //gda.wndError(txt);
        });
    }
    rt_init() {
        var self = this;
        var prm = {};
        prm.req = "pms.rt.rt_init";
        prm.fdat = this.state.fdat;
        prm.tdat = this.state.tdat;
        //prm.avd = this.state.avd;

        //gda.wndLoading("Hämtar");
        net.sio_req(prm)
            .then(function(ret){
                alert(JSON.stringify(ret));

                //self.setState({rta: ret.rco });
            })
            .catch(function(e) {
                var txt = JSON.stringify(e);
                //gda.wndError(txt);
            });
    }
    rt_ruminit() {
        var self = this;
        var prm = {};
        prm.req = "pms.rt.rt_gsb_init";
        prm.fdat = this.state.fdat;
        prm.tdat = this.state.tdat;
        //prm.avd = this.state.avd;

        //gda.wndLoading("Hämtar");
        net.sio_req(prm)
        .then(function(ret){
            alert(JSON.stringify(ret));

            //self.setState({rta: ret.rco });
        })
        .catch(function(e) {
            var txt = JSON.stringify(e);
            //gda.wndError(txt);
        });
    }
    rt_balance() {
        var self = this;
        var prm = {};
        prm.req = "pms.rt.rt_balance";
        prm.fdat = this.state.fdat;
        prm.tdat = this.state.tdat;

        //gda.wndLoading("Hämtar");
        net.sio_req(prm)
        .then(function(ret){
            alert(JSON.stringify(ret));

            //self.setState({rta: ret.rco });
        })
        .catch(function(e) {
            var txt = JSON.stringify(e);
            //gda.wndError(txt);
        });
    }

  render() {
    //if(!this.props.show) return "";
    var title = this.props.title;
    if(!title) title = "Rum";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="mx_backdrop" /> : null }
        <div className="mx_dlg mx_50 flex-col" style={ this.dlg_style() }>
        <div className="mx_content flex">

            <div className="flex-row" style={{padding:"5px",height:"35px"}}>
                    <div className="flex-col flex">
                        <div>INITIERA RUMSTYPER OCH RUM</div>
                    </div>
                    <div className="flex-col">
                        <div>UPPDATERA</div>
                    </div>
            </div>
            <div className="divider_orange"></div>
            <div className="flex-row">
                <div className="flex"></div>
                <div className="flex-row">
                    <div className="dt-btn-01" onClick={() => this.subday() } ><FontAwesomeIcon icon="minus" size="1x" /></div>
                    <Dt_Cal dts={ this.state.fdat } rp="1" ref={this.dt_fd} getValue={(e)=> this.dt_fdat(e)} />
                    <div className="dt-btn-01" onClick={() => this.oneday() } >1</div>
                    <Dt_Cal dts={ this.state.tdat } rp="1" ref={this.dt_td} getValue={(e)=> this.dt_tdat(e)} />
                    <div className="dt-btn-01" onClick={() => this.addday() } ><FontAwesomeIcon icon="plus" size="1x" /></div>
                </div>
                <div className="flex"></div>
           </div>

            <div className="divider_orange"></div>
        </div>

            <div className="divider_blue"></div>
            <div className="flex-row">
                <div className="web-link txt_16 px-2" onClick={() => this.rt_clear() }>Rensa Rumstyper</div>
                <div className="web-link txt_16 px-2" onClick={() => this.rt_rumclear() }>Rensa Rum</div>
                <div className="flex"></div>
                <div className="web-link txt_16 px-2" onClick={() => this.rt_init() }>Skapa rumstyper</div>
                <div className="web-link txt_16 px-2" onClick={() => this.rt_ruminit() }>Skapa rum</div>
            </div>

            <div className="divider_green"></div>
            <div className="flex-row h100">
                <div className="flex-row flex">
                </div>
                <div className="flex-row">
                    <div className="web-btn w200" onClick={() => this.rt_balance() } >
                        <div>Balansera Rumstyper</div>
                        <div>Balansering av rumstyper</div>
                    </div>
                </div>

            </div>

        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex"></div>
        </div>

      </div>
    </div>
	;
	return html;
  };
}

export default Reg_RtInit;