import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../../lib/net";

import Fr_Input from "../../../lib/ui/fr_input";
import Dd_Sel from "../../../lib/ui/dd_sel";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Sz_Profil extends React.Component {
  constructor(props, context) {
    super(props);

    this.selitems = [
        {fkn: 'bevaka', text: 'BEVAKA'},
        {fkn: 'bokbild', text: 'BOKNINGSBILD'},
        {fkn: 'confirm', text: 'BEKRÄFTA'},
        {fkn: 'cancel', text: 'AVBOKA'}
    ];

    this.store = props.store;
    this.state = {};
    this.state.ynq = [];
    //this.state.vga = [];
    this.state.rta = [];
    this.state.bra = [];

    this.state.bgo = {};
    this.state.cols = {};

    this.state.ix = 0;
    this.state.boknr = "";
    if(props.boknr) this.state.boknr = props.boknr;

    this.state.rvo = {};
    this.state.rvo.nrr = "1";
    this.state.rvo.sum = "2000";
    this.state.rvo.rvo = {};

    this.state.kro = {};
    this.state.kro.krr00 = {};
    this.state.kro.krr01 = {};
    this.state.kro.krr02 = {};
    this.state.kro.krr03 = {};
    this.state.pfs = [];
    this.state.pfa = [];
    this.state.pfo = {};
    this.state.sz_val = "";
    this.state.sortid = "";
    this.state.sortdir = 1;
    
    if(props.wnds) {
        if(props.wnds.boknr) this.state.boknr = props.wnds.boknr;
    }
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      this.profils();
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    sz_style() {
      var css = {};
      css.height = "40px";
      css.lineHeight = "40px";
      css.padding = "0px 10px";
      css.fontSize = "12px";
      css.cursor = "pointer";
      return css;
  }
  
    formchange (e) {
      const name = e.target.name;
      const value = e.target.value;
      var rvo = this.state.rvo;
      var na = name.split(".");
      rvo[na[0]][na[1]] = value;
      this.setState({rvo: rvo});
      //setState({[name]: value});
    }
    rsv_anm_change (e) {
      const name = e.target.name;
      const value = e.target.value;
      var rsv = this.state.rsv;
      var na = name.split(".");
      rsv[na[0]][na[1]] = value;
      this.setState({rsv: rsv});
      //setState({[name]: value});
    }

    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    wnd_close = () => {
      if(this.props.close) {
          this.props.close(this.props.ctx);
      }
      else this.props.ctx.setState({ wnds: {} });
	};

profil_use (row) {
    if(this.props.wnds.cb) {
      var rsp = {};
      rsp.ok = "000";
      rsp.rco = row;
      this.props.wnds.cb(this.props.ctx, rsp);
    }
    else this.props.ctx.setState({ wnds: {} });
};

profils() {
    var self = this;
    var prm = {};
    prm.req = "pms.profil.profils";
    prm.sz = "";
    //prm.avd = this.state.avd;
    //alert(JSON.stringify(prm));

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        self.setState({ pfa: ret.rca, pfs: ret.rca }, function () {
            self.syncTb("pfaTable");
        });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e) {
        var txt = JSON.stringify(e);
    });
  }
  syncTb(tb){
    if (!document.getElementById(tb)) {
        //alert("No tb");
        return;
    }
    var tbel = document.getElementById(tb);
    const thElements = tbel.getElementsByTagName('th'),
    tdElements = tbel.getElementsByTagName('td');

    for (let i = 0; i < thElements.length; i++) {
        if(!tdElements[i]) continue;
        const widerElement =
                thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
        width = window.getComputedStyle(widerElement).width;
        thElements[i].style.width = tdElements[i].style.width = width;
    }
}

    drag(event) {
        //event.dataTransfer.setData("text", event.target.id);
	};
    drop(event) {
        event.preventDefault();
        alert("Drop");
	};
    dragEnter(event) {
        event.preventDefault();
        event.target.style.color = "#fff";
        event.target.style.background = "#ccc";
        //alert("Allow Drop");
	};
    dragLeave(event) {
        event.preventDefault();
        event.target.style.color = "#fff";
        event.target.style.background = "#080";
        //alert("Allow Drop");
	};
  tb_style() {
    var css = {};
    css.height = window.innerHeight - 275;
    css.overflow = "auto";
    return css;
}
cssCols (cid) {
      var css = {};
      if(this.state.cols[cid]) css.width = this.state.cols[cid].toString() + "px";
      //if(cid == "c01") alert(JSON.stringify(this.state.cols[cid]));
      return css;
  }
  syncCols (tb) {

    if (!document.getElementById(tb)) return;

    var cols = {};
    var ix = document.getElementById(tb).rows.length - 1;
    var ic = document.getElementById(tb).rows[0].cells.length;
    var ci = 0;
    while(ci < ic) {
        var cxi = ci + 1;
        var col = "c" + cxi.toString().padStart(2, "0");
        cols[col] = document.getElementById(tb).rows[ix].cells[ci].clientWidth + 1;
        ci = ci + 1;
    }
    this.setState({cols: cols });
    //alert(JSON.stringify(this.state.cols));
    //this.forceUpdate();
  };
  mnu_toggle() {
    //props.ctx.setState({ mnu_show: !props.ctx.state.mnu_show });
    //props.store.cfg.mnu_show = !props.store.cfg.mnu_show;
  };
    val_save (name, value) {
        //alert(JSON.stringify(name));
        //alert(JSON.stringify(value));
      var rvo = this.state.rvo;
      var na = name.split(".");
      rvo[na[0]][na[1]] = value;
      this.setState({rvo: rvo});
      //setState({[name]: value});
    }
  sel_fkn(sel) {
    //alert(JSON.stringify(fkn));
      if(sel.fkn == "logout") {
      }
  }
  brs() {
    var self = this;

    var prm = {};
    prm.req = "pms.br.bra";
    prm.boknr = this.state.boknr;
    //alert(JSON.stringify(prm));

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            return;
        }
        self.setState({bra: ret.rca });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
    return;
  }
  kk() {
    var self = this;

    return;
    var prm = {};
    prm.req = "pos.pos_plu.plu_bufsave";
    prm.fkn = "7221";
    prm.boknr = this.state.boknr;
    //alert(JSON.stringify(prm));

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            return;
        }
        self.setState({gsa: ret.rca });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
    return;
  }

  sz_keydown (e) {
    //e.preventDefault();
      const key = e.key;
      const keyCode = e.keyCode;

      if(keyCode == 13) {
          this.tb_filtr();
      };
      if(keyCode == 27) {
          this.setState({sz_val: ""});
      };
  }
  sz_change (e) {
      const name = e.target.name;
      const value = e.target.value;
      this.setState({sz_val: value});
      //setState({[name]: value});
  }
  tb_filtr() {
    var self = this;
    var pfs = self.state.pfs;
    var sz = this.state.sz_val;

    if(sz.length < 1) {
      self.setState({ pfa: pfs });
        return;
    }
    sz = sz.toUpperCase();
    var pfa = pfs.filter(function(row) {
          var brc = false;
          if(row.namn.toUpperCase().includes(sz)) brc = true;
          if(row.ftg.toUpperCase().includes(sz)) brc = true;
          if(row.tele.toUpperCase().includes(sz)) brc = true;
          return brc;
    });
    self.setState({ pfa: pfa });
}
tb_sort(typ) {
    var self = this;
    //var pfs = self.state.pfs;
    var pfa = self.state.pfa;
    var sortid = this.state.sortid;
    var sortdir = this.state.sortdir;

    if(sortid != typ) sortdir = 0;
    else {
      if(sortdir) sortdir = 0;
      else sortdir = 1;
    }

    if(sortdir) pfa.sort((a,b) => (a[typ] > b[typ]) ? 1 : ((b[typ] > a[typ]) ? -1 : 0));
    else pfa.sort((a,b) => (a[typ] < b[typ]) ? 1 : ((b[typ] < a[typ]) ? -1 : 0));
    self.setState({ pfa: pfa, sortid: typ, sortdir: sortdir });
 }

  render() {
    //if(!this.props.show) return "";

    //alert(this.props.ctx.state.lro);

    var title = this.props.title;
    if(!title) title = "Bokning";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="m2_backdrop" /> : null }
        <div className="m2_dlg mx_90 flex-col" style={ this.dlg_style() }>
        <div className="mx_h01">
            <div>PROFIL</div>
        </div>
        <div className="mx_content flex">

        <div className="flex-row py-1 px-2">
              <div className="txt_12 web_mnu_head" onClick={() => this.kk() }>PROFIL</div>
              <div className="flex"></div>
              <input className="txt sztxt" value={this.state.sz_val} placeholder="Ange söktext..."  style={ this.sz_style() } onChange={(e) => this.sz_change(e)} onKeyDown={(e) => this.sz_keydown(e)} />
              <div className="flex"></div>
              <div className="txt_12 web_mnu_link" onClick={() => this.kk() }>UPPDATERA</div>
      </div>
      <div className="divider_green"></div>
      
      <div className="web-col">
        <div id="pfaTable">
        <table className="table table-bordered table-hover tb" style={{ marginBottom: "0px" }}>
            <thead>
            <tr>
                <th onClick={() => this.tb_sort("id") }>ID</th>
                <th onClick={() => this.tb_sort("namn") }>Namn</th>
                <th onClick={() => this.tb_sort("ftg") }>Företag</th>
                <th onClick={() => this.tb_sort("tele") }>Telefon</th>
                <th onClick={() => this.tb_sort("nrb") }>Besök</th>
                <th></th>
            </tr>
            </thead>
            </table>
            <div style={ this.tb_style() }>
          <table className="table table-bordered table-hover">
            <tbody>
            { this.state.pfa.map((row, key) =>
            <tr key={key} onDoubleClick={() => this.profil_use(row) }>
                <td>{ row.id }</td>
                <td>{ row.namn }</td>
                <td>{ row.ftg }</td>
                <td>{ row.tele }</td>
                <td>{ row.pfb.nrb }</td>
                <td>
                </td>
            </tr>
            )}
            </tbody>
        </table>
        </div>
        </div>
    </div>

        <div className="divider_green"></div>

        </div>

        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex-row">
            </div>
            <button className="btn btn-hw-green w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
        </div>
      </div>
    </div>
	;
	return html;
  };
}

export default Sz_Profil;