import React, { useState, useEffect, useContext } from 'react';
import {format} from "date-fns";

import * as utils from "../../../../assets/utils";
import * as net from "../../../../lib/net";
import Dd_Sel from "../../../../lib/ui/dd_sel";
import Dt_Cal from "../../../../lib/ui/dt_cal";
import sso from "../../../../sso";

class Pay_Wnd extends React.Component {
  constructor(props, context) {
    super(props);

     this.selitems = [
        {fkn: 'setup', text: 'INSTÄLLNINGAR'},
        {fkn: 'upd', text: 'UPPDATERA'},
        {fkn: 'sys', text: 'SYSTEM'},
        {fkn: 'logout', text: 'LOGGA UT'}
    ];

    this.dt_dt = React.createRef();

    this.fkn_close = null;
    this.store = props.store;

    this.state = {};
    this.state.ynq = [];

    this.state.paa = [];
    this.state.aa = [];
    this.state.boknr = "";
    this.state.gso = {};
    this.state.pra = [];
    this.state.betkod = "";
    this.state.bettext = "";
    this.state.belopp = "";
    this.state.lang = "";
    if(props.wnds) {
        this.state.boknr = props.wnds.boknr;
        this.state.gso = props.wnds.gso;
        this.state.pra = props.wnds.pra;
        this.state.betkod = props.wnds.betkod;
        this.state.bettext = props.wnds.bettext;
        this.state.belopp = props.wnds.belopp;
        this.state.lang = props.wnds.lang;
    }

    this.state.today = new Date();
    var dts = format(this.state.today, "yyyy-MM-dd");
    this.state.dts = dts;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      this.pay_tr();
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }

    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    wnd_close() {
        var self = this;
        if(self.props.wnds.cb) {
            var rsp = {};
            rsp.ok = "999";
            self.props.wnds.cb(self.props.ctx, rsp);
        }
        else self.props.ctx.setState({ wnds: {} });
	};
  tb_style() {
      var css = {};
      //css.height = window.innerHeight - 275;
      css.overflow = "auto";
      return css;
  }
  pay_tr() {
    var self = this;

    //var pra = [];
    //for(var row of self.state.nta) {
    //    pra.push(row.etr01);
    //}
    var pra = this.state.pra;
    if(self.state.betkod.trim().length < 1) {
        alert("Ingen betalkod");
        return;
    }
    if(pra.length < 1) {
        alert("Inga rader");
        return;
    }

    var sdo = {};
    sdo.notanr = "";

    var prm = {};
    prm.req = "pms.charge_fkn.charges_toinv";
    prm.boknr = this.state.boknr;
    prm.gsb00 = this.state.gso;
    prm.datum = this.state.dts;
    prm.payrows = pra;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            alert("Nota fel...");
            return;
        }

        var notanr = ret.notanr;
        var belopp = ret.belopp;
        sdo.notanr = notanr;

        var prm = {};
        prm.req = "pms.sell.charge_pay";
        prm.boknr = self.state.boknr;
        prm.gsb00 = self.state.gso;
        prm.trkod = self.state.betkod;
        prm.text = self.state.bettext;
        prm.antal = "1";
        prm.pris = belopp;
        prm.tkn = "+";
        prm.moms = "";
        prm.datum = self.state.dts;
        prm.notanr = notanr;
        return net.sio_req(prm);
    })
    .then(function(ret){
        //alert(JSON.stringify(ret));
        if(ret.ok != "000") {
            alert("Betal fel...");
            return;
        }

        var prm = {};
        prm.req = "pms.charge.kvitto_petty";
        prm.boknr = self.state.boknr;
        prm.glopnr = self.state.gso.r0glopnr;
        prm.gso = self.state.gso;
        prm.notanr = sdo.notanr;
        prm.prnnr = sso.seo.prnnr;
        prm.lang = self.state.lang;
        return net.sio_req(prm);
    })
    .then(function(ret){
        //alert(JSON.stringify(ret));
        if(ret.ok != "000") {
            alert("Betal fel...");
            return;
        }

        if(self.props.wnds.cb) {
            var rsp = {};
            rsp.ok = "000";
            self.props.wnds.cb(self.props.ctx, rsp);
        }
        else self.props.ctx.setState({ wnds: {} });
        //self.charges();
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "";

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="m2_backdrop" /> : null }
        <div className="m2_dlg mx_50 flex-col" style={ this.dlg_style() }>
        <div className="flex-row " style={{padding:"0px",height:"50px",lineHeight:"50px", background: "#1a456b", color: "#fff"}}>
                <div className="flex-row flex" style={{padding:"0px 5px", fontSize: "22px"}}>
                    <div>BETALNING { this.state.rumsnr }</div>
                </div>
            <div className="wnd-close-btn" onClick={() => this.wnd_close() }>X</div>
        </div>
        <div className="mx_content flex">

            <div className="flex-row py-2">
                <div className="flex"></div>
                <div className="flex-col">
                    BETALNING PÅGÅR
                </div>
                <div className="flex"></div>
           </div>

            <div className="flex"></div>
             <div className="flex-col flex-center">
                <div className="txt_22">BELOPP</div>
                <div className="txt_32">{ utils.toPris(this.state.belopp) }</div>
            </div>
            <div className="flex"></div>

        </div>
        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250 ripple" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex"></div>
        </div>
      </div>
    </div>
	;
	return html;
  };
}

export default Pay_Wnd;