import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";
import Dt_Cal from "../../lib/ui/dt_cal";
import * as utils from "../../assets/utils";
import {format} from "date-fns";
import Fak_Open from "./fkn/fak_open";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Rsk_Hist extends React.Component {
  constructor(props, context) {
    super(props);

    this.dt_fd = React.createRef();
    this.dt_td = React.createRef();
    this.store = props.store;

    this.state = {};
    this.state.today = new Date();
    this.state.avd = "01";
    this.state.rss = [];
    this.state.rsa = [];
    this.state.aa = [];
    this.state.fao = {};
    this.state.cols = {};
    this.state.fdat = format(this.state.today, "yyyy-MM-01");
    this.state.tdat = format(this.state.today, "yyyy-MM-dd");
      this.state.wnds = {};
      this.state.sz_val = "";

  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.rsk();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
    tb_style() {
        var css = {};
        css.height = window.innerHeight - 225;
        css.overflow = "auto";
        return css;
    }
    th_style(id) {
        var css = {};
        css.cursor = "pointer";
        if(id == this.state.sortid) {
            if(this.state.sortdir) css.color = "#800";
            else css.color = "#080";
        }
        return css;
    }
    row_style(row) {
        var css = {};
        if(row.wfak00.kredit == "1") {
            css.color = "#800";
        }
        return css;
    }
    fak_belopp(row, belopp) {
      var amount = "";
      amount = utils.toPris( belopp );
        if(row.wfak00.kredit == "1") {
            amount = "-" + amount;
        }
        return amount;
    }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }
    dt_fdat(e) {
        //alert(JSON.stringify(e));
        var dts = format(e, "yyyy-MM-dd");
        this.setState({ fdat: dts });
    }
    dt_tdat(e) {
        //alert(JSON.stringify(e));
        var dts = format(e, "yyyy-MM-dd");
        this.setState({ tdat: dts });
    }
    oneday(e) {
        //alert(JSON.stringify(e));
        //this.setState({ tdat: this.state.fdat });
        this.dt_td.current.updateDt(this.state.fdat);
    }
    addday(e) {
        //alert(JSON.stringify(e));
        //this.setState({ tdat: this.state.fdat });
        this.dt_fd.current.updateAdd();
        this.dt_td.current.updateAdd();
    }
    subday(e) {
        //alert(JSON.stringify(e));
        //this.setState({ tdat: this.state.fdat });
        this.dt_fd.current.updateSub();
        this.dt_td.current.updateSub();
    }

  fak_open(row) {
      var self = this;
      //alert(JSON.stringify( row ));

      var wnds = {};
      wnds.fakopen = 1;
      wnds.faknr = row.wfak00.faknr;
      wnds.wfh = row;
      this.setState({ wnds: wnds });
  }
  fak_edit(row) {
      var self = this;
      alert(JSON.stringify(row.plu00));
  }

    rsk_all() {
        var self = this;
        var prm = {};
        prm.req = "fakt.fakt_rsk.rsk_closed";

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.rca));

                self.setState({rsa: ret.rco, rss: ret.rco }, function() {
                    self.syncTb("rskTable");
                });
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    rsk() {
        var self = this;
        var prm = {};
        prm.req = "fakt.fakt_rsk.rsk_per_closed";
        prm.fdat = self.state.fdat;
        prm.tdat = self.state.tdat;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.rca));

                self.setState({ rsa: ret.rco, rss: ret.rco }, function() {
                    self.syncTb("rskTable");
                });
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    rsk_pay() {
        var self = this;
        var prm = {};
        prm.req = "fakt.fakt_rsk.rsk_pay_closed";
        prm.fdat = self.state.fdat;
        prm.tdat = self.state.tdat;

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.rca));

                self.setState({ rsa: ret.rco, rss: ret.rco }, function() {
                    self.syncTb("rskTable");
                });
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    syncTb(tb){
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
            tdElements = tbel.getElementsByTagName('td');
        if(tdElements.length < 1 ) return;

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
                width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
    sz_style() {
        var css = {};
        css.height = "40px";
        css.lineHeight = "40px";
        css.padding = "0px 10px";
        css.fontSize = "12px";
        css.cursor = "pointer";
        return css;
    }

    sz_keydown (e) {
    //e.preventDefault();
      const key = e.key;
      const keyCode = e.keyCode;

      if(keyCode == 13) {
          this.tb_filtr();
      };
      if(keyCode == 27) {
          this.setState({sz_val: ""});
      };
  }
  sz_change (e) {
      const name = e.target.name;
      const value = e.target.value;
      this.setState({sz_val: value});
      //setState({[name]: value});
  }
  tb_filtr() {
    var self = this;
    var rss = self.state.rss;
    var sz = this.state.sz_val;

    if(sz.length < 1) {
      self.setState({ rsa: rss });
        return;
    }
    sz = sz.toUpperCase();
    var rsa = rss.filter(function(row) {
          var brc = false;
          if(row.wfak00.faknr.toUpperCase().includes(sz)) brc = true;
          if(row.wfak00.kund.toUpperCase().includes(sz)) brc = true;
          if(row.wfak00.company.toUpperCase().includes(sz)) brc = true;
          if(row.wfak00.belopp.toUpperCase().includes(sz)) brc = true;
          if(row.wfak00.fakdatum.toUpperCase().includes(sz)) brc = true;
          return brc;
    });
    self.setState({rsa: rsa });
    }

  render() {
    return (
    <div className="web-app">

    <div className="flex-row">
        <div className="flex-row">
            <div className="dt-btn-01" onClick={() => this.subday() } ><FontAwesomeIcon icon="minus" size="1x" /></div>
            <Dt_Cal dts={ this.state.fdat } rp="1" ref={this.dt_fd} getValue={(e)=> this.dt_fdat(e)} />
            <div className="dt-btn-01" onClick={() => this.oneday() } >1</div>
            <Dt_Cal dts={ this.state.tdat } rp="1" ref={this.dt_td} getValue={(e)=> this.dt_tdat(e)} />
            <div className="dt-btn-01" onClick={() => this.addday() } ><FontAwesomeIcon icon="plus" size="1x" /></div>
        </div>
        <div className="flex"></div>
        <input className="txt sztxt" value={this.state.sz_val} placeholder="Ange söktext..."  style={ this.sz_style() } onChange={(e) => this.sz_change(e)} onKeyDown={(e) => this.sz_keydown(e)} />
        <button className="btn btn-hw-blue w150" onClick={() => this.rsk() } aria-label="System" >
            Visa faktdatum
        </button>
        <button className="btn btn-hw-blue w150" onClick={() => this.rsk_pay() } aria-label="System" >
            Visa betaldatum
        </button>
    </div>
    <div className="divider"></div>

        <div className="web-col">
            <div id="rskTable">
                <table className="table table-bordered table-hover tb" style={{marginBottom: "0px"}}>
                    <thead>
                    <tr>
                        <th style={ this.th_style("faknr" ) }>Faknr</th>
                        <th style={ this.th_style("kund" ) }>Kund</th>
                        <th style={ this.th_style("company" ) }>Företag</th>
                        <th style={ this.th_style("belopp" ) }>Belopp</th>
                        <th style={ this.th_style("moms" ) }>Moms</th>
                        <th style={ this.th_style("brutto" ) }>Ex moms</th>
                        <th style={ this.th_style("fakdatum" ) }>Fakturadatum</th>
                        <th style={ this.th_style("slutbetdat" ) }>Betaldatum</th>
                        <th style={ this.th_style("days" ) }>Dagar</th>
                        <th style={ this.th_style("status" ) }>Status</th>
                        <th style={ this.th_style("" ) }></th>
                    </tr>
                    </thead>
                </table>
                <div style={ this.tb_style() }>
                    <table className="table table-bordered table-hover tb">
                        <tbody>
                        { this.state.rsa.map((row, key) =>
                            <tr key={ key } style={ this.row_style(row) } onDoubleClick={() => this.fak_open(row) }>
                                <td>{ utils.toNum( row.wfak00.faknr ) }</td>
                                <td>{ utils.toNum( row.wfak00.kund ) }</td>
                                <td>{ utils.toLang( row.wfak00.company ) }</td>
                                <td className="txt_right">{ this.fak_belopp( row, row.wfak00.belopp ) }</td>
                                <td className="txt_right">{ this.fak_belopp( row, row.wfak00.moms ) }</td>
                                <td className="txt_right">{ this.fak_belopp( row, ((row.wfak00.belopp * 1) - (row.wfak00.moms * 1)).toString() ) }</td>
                                <td className="txt_center">{ utils.toDate( row.wfak00.fakdatum ) }</td>
                                <td className="txt_center">{ utils.toDate( row.wfak00.slutbetdat ) }</td>
                                <td className="txt_center">{ utils.toNum( row.wfak00.days ) }</td>
                                <td>{ row.wfak00.status }</td>
                                <td onClick={() => this.fak_open(row) }><i className="fa fa-cog text-danger"></i></td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
            </div>
    </div>
        { this.state.wnds.fakopen ? <Fak_Open wnds={this.state.wnds} ctx={this} backdrop={true}/> : null }

    </div>
  );
    }
}
export default Rsk_Hist;
