import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../../../lib/net";
import * as utils from "../../../../assets/utils";

import Fr_Input from "../../../../lib/ui/fr_input";
import Dd_Sel from "../../../../lib/ui/dd_sel";
import Td_Sel from "../../../../lib/ui/td_sel";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Rv_Br_Pkat extends React.Component {
  constructor(props, context) {
    super(props);

    this.selitems = [
        {fkn: 'refresh', text: 'UPPDATERA'},
        {fkn: 'wcancel', text: 'MED AVBOKADE'}
    ];
    this.britems = [
        {fkn: 'open', text: 'ÖPPNA'},
        {fkn: 'cancel', text: 'AVBOKA'}
    ];

    this.store = props.store;
    this.state = {};
    this.state.ynq = [];
    //this.state.vga = [];
    this.state.rta = [];
      this.state.bra = [];
      this.state.pka = [];

    this.state.bgo = {};
    this.state.cols = {};

    this.state.ix = 0;
    this.state.boknr = "";
    if(props.boknr) this.state.boknr = props.boknr;

    this.state.rvo = {};
    this.state.rvo.nrr = "1";
    this.state.rvo.sum = "2000";
    this.state.rvo.rvo = {};
    if(props.wnds) {
        if(props.wnds.boknr) this.state.boknr = props.wnds.boknr;
    }
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      this.brp();
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }

    formchange (e) {
      const name = e.target.name;
      const value = e.target.value;
      var rvo = this.state.rvo;
      var na = name.split(".");
      rvo[na[0]][na[1]] = value;
      this.setState({rvo: rvo});
      //setState({[name]: value});
    }
    rsv_anm_change (e) {
      const name = e.target.name;
      const value = e.target.value;
      var rsv = this.state.rsv;
      var na = name.split(".");
      rsv[na[0]][na[1]] = value;
      this.setState({rsv: rsv});
      //setState({[name]: value});
    }

    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    wnd_close = () => {
      if(this.props.close) {
          this.props.close(this.props.ctx);
      }
      else this.props.ctx.setState({ wnds: {} });
	};
    drag(event) {
        //event.dataTransfer.setData("text", event.target.id);
	};
    drop(event) {
        event.preventDefault();
        alert("Drop");
	};
    dragEnter(event) {
        event.preventDefault();
        event.target.style.color = "#fff";
        event.target.style.background = "#ccc";
        //alert("Allow Drop");
	};
    dragLeave(event) {
        event.preventDefault();
        event.target.style.color = "#fff";
        event.target.style.background = "#080";
        //alert("Allow Drop");
	};
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  cssCols (cid) {
      var css = {};
      if(this.state.cols[cid]) css.width = this.state.cols[cid].toString() + "px";
      //if(cid == "c01") alert(JSON.stringify(this.state.cols[cid]));
      return css;
  }
  syncCols (tb) {

    if (!document.getElementById(tb)) return;

    var cols = {};
    var ix = document.getElementById(tb).rows.length - 1;
    var ic = document.getElementById(tb).rows[0].cells.length;
    var ci = 0;
    while(ci < ic) {
        var cxi = ci + 1;
        var col = "c" + cxi.toString().padStart(2, "0");
        cols[col] = document.getElementById(tb).rows[ix].cells[ci].clientWidth + 1;
        ci = ci + 1;
    }
    this.setState({cols: cols });
    //alert(JSON.stringify(this.state.cols));
    //this.forceUpdate();
  };
  mnu_toggle() {
    //props.ctx.setState({ mnu_show: !props.ctx.state.mnu_show });
    //props.store.cfg.mnu_show = !props.store.cfg.mnu_show;
  };
    val_save (name, value) {
        //alert(JSON.stringify(name));
        //alert(JSON.stringify(value));
      var rvo = this.state.rvo;
      var na = name.split(".");
      rvo[na[0]][na[1]] = value;
      this.setState({rvo: rvo});
      //setState({[name]: value});
    }
  sel_fkn(sel) {
    //alert(JSON.stringify(fkn));
      if(sel.fkn == "refresh") {
          this.brs();
      }
      if(sel.fkn == "wcancel") {
          this.brs_wc();
      }
  }
  br_fkn(row, sel) {
      if(sel.fkn == "cancel") {
          this.brp_del(row);
      }
  }
    brs() {
        var self = this;

        var prm = {};
        prm.req = "pms.br.bra";
        prm.boknr = this.state.boknr;
        //alert(JSON.stringify(prm));

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret));

                if(ret.ok != "000") {
                    return;
                }
                self.setState({bra: ret.rca });
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
        return;
    }
    brs_wc() {
        var self = this;

        var prm = {};
        prm.req = "pms.br.bra";
        prm.boknr = this.state.boknr;
        prm.withcancel = 1;
        //alert(JSON.stringify(prm));

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret));

                if(ret.ok != "000") {
                    return;
                }
                self.setState({bra: ret.rca });
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
        return;
    }
    brp() {
        var self = this;

        var prm = {};
        prm.req = "pms.br.brp";
        prm.boknr = this.state.boknr;
        //alert(JSON.stringify(prm));

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret));

                if(ret.ok != "000") {
                    return;
                }
                self.setState({pka: ret.rca });
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
        return;
    }
    brp_del(row) {
        var self = this;

        var prm = {};
        prm.req = "pms.br.br_pkat_del";
        prm.boknr = this.state.boknr;
        prm.nyb00 = row;
        //alert(JSON.stringify(prm));

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret));

                if(ret.ok != "000") {
                    return;
                }
                self.brp();
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
        return;
    }

  render() {
    //if(!this.props.show) return "";

    //alert(this.props.ctx.state.lro);

    var title = this.props.title;
    if(!title) title = "Bokning";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="m2_backdrop" /> : null }
        <div className="m2_dlg mx_90 flex-col" style={ this.dlg_style() }>
        <div className="mx_h01">
            <div>PRISKATEGORIER</div>
        </div>
        <div className="mx_content flex">

            <div className="divider_orange hide"></div>

            <div className="flex-row hide" style={{fontSize: "18px", margin: "0px", height: "30px", lineHeight: "30px"}}>
                <div style={{width:"250px",fontSize: "16px",background: "#fff", color: "#080", textAlign:"center",borderRight: "1px dashed #080"}}>
                    Bokningsrader
                </div>
                <div style={{width:"250px",fontSize: "16px",background: "#fff", color: "#080", textAlign:"center",borderRight: "1px dashed #080"}}>
                    Beläggning
                </div>
                <div className="flex"></div>
                <div className="text-center w50" onClick={() => this.mnu_toggle() }>
                    <FontAwesomeIcon icon="ellipsis-v" size="1x"/>
                </div>
            </div>

            <div className="divider_green"></div>
            <div className="flex-row" style={{border: "0px", margin: "0px", padding: "0px"}}>
                <table className="table" style={{border: "0px", margin: "0px", padding: "0px"}}>
                    <thead style={{fontSize: "12px", fontWeight: "normal", color: "#080", border: "0px", margin: "0px", padding: "0px"}}>
                    <tr>
                        <th style={{textAlign: "left"}}>Löpnr</th>
                        <th style={{textAlign: "left"}}>Datum</th>
                        <th style={{textAlign: "left"}}>Typ</th>
                        <th style={{textAlign: "left"}}>Teext</th>
                        <th style={{textAlign: "left"}}>Pris</th>
                        <th style={{textAlign: "left"}}>Baspris</th>
                        <th style={{textAlign: "left"}}>Typ</th>
                        <th style={{textAlign: "center", color: "#800"}}>#</th>
                    </tr>
                    </thead>
                    <tbody style={{fontSize: "16px", color: "#000", border: "0px", margin: "0px", padding: "0px", borderBottom: "1px solid #ccc"}}>
                    { this.state.pka.map((row, key) =>
                    <tr>
                        <td>{ utils.toNum(row.n0lopnr) }</td>
                        <td>{ utils.toDate(row.n0datum) }</td>
                        <td>{ row.n0typ }</td>
                        <td>{ row.n0text }</td>
                        <td>{ utils.toPris(row.n0pris) }</td>
                        <td>{ utils.toPris(row.n0baspris) }</td>
                        <td>{ row.n0bastyp }</td>
                        <td>
                            <Td_Sel items={ this.britems } getValue={(e)=> this.br_fkn(row, e)} />
                        </td>
                    </tr>
                    )}
                    </tbody>
                </table>
             </div>
            <div className="flex"></div>

            </div>

            <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250 ripple" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex-row">
            <Dd_Sel up  items={ this.selitems } getValue={(e)=> this.sel_fkn(e)} />
            </div>
            <button className="btn btn-hw-green w250 ripple" onClick={(event) => this.rv_save(event) } >
                LÄGG TILL PRIS
            </button>
        </div>
      </div>
    </div>
	;
	return html;
  };
}

export default Rv_Br_Pkat;