import React, { useState, useEffect, useContext } from 'react';
import {Route, Routes} from "react-router-dom";

import Rps_Home from './rps/home.js';
import Rps_Rps from './rps/rps.js';
import Rps_Reg from './rps/reg.js';
import Rps_Sal from './rps/sal.js';
import {withRouter} from "../lib/react/withRouter";
import Bo_Home from "./bo/home";
import sso from "../sso";

function pg_home() {

    var html =
    <div className="web-content">
      Hem
    </div>
    ;
    return html;
}

class Ta_Rps extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, deps: [] };
      var po = {};
      po.rps = { "fid": "f06", "url": "/ta/rps/rps", "name": "Rapporter", "active": false };
      po.reg = { "fid": "f06", "url": "/ta/rps/reg", "name": "Register", "active": false };
      po.sal = { "fid": "f06", "url": "/ta/rps/sal", "name": "Lön", "active": false };
      this.state.po = po;
      this.state.pa = [];
      this.state.pa = Object.values(po);
  }
    fknGo(row) {
        sso.seo.sfkn = row;
        this.props.navigate(row.url);
    }
    br_css(row) {
        var css = {};
        if(sso.seo.sfkn && (row.url == sso.seo.sfkn.url)) css.color = "#080";
        //var brc = false;
        //if(row.url == sbo.state.url) brc = true;
        return css;
    }

  render() {
      var path = window.location.pathname;
      var op = path.split("/").pop();
      var po = {};
      po.rps = { "href": "/ta/rps/rps", "text": "Rapporter", "active": false };
      po.reg = { "href": "/ta/rps/reg", "text": "Register", "active": false };
      po.sal = { "href": "/ta/rps/sal", "text": "Lön", "active": false };
      if(po[op]) po[op].active = true;
      var pa = Object.values(po);

    return (
        <div className="web-app">
            { this.props.store.cfg.bread ?
                <div className="web-bread">
                    <div className="breadcrumb">
                        { this.state.pa.map((row, key) =>
                            <div key={key} className="breadcrumb-item" style={this.br_css(row)} onClick={() => this.fknGo(row)}>{row.name}</div>
                        )}
                        <div className="flex"></div>
                        <div className="cdigreen">{ sso.seo.sfkn ? sso.seo.sfkn.name : null }</div>
                    </div>
                </div>
                : null }
        <Routes>
          <Route path="/xx" component={pg_home} />
            <Route path="rps" element={ <Rps_Rps store={this.webStore} />} />
            <Route path="reg" element={ <Rps_Reg store={this.webStore} />} />
            <Route path="sal" element={ <Rps_Sal store={this.webStore} />} />
            <Route path="*" element={ <Rps_Home store={this.webStore} />} />
        </Routes>
    </div>
  );
}
}
//Sys.contextType = ADOContext;

export default withRouter(Ta_Rps);
