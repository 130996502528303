import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../../lib/net";
import * as utils from "../../../assets/utils";

import Fr_Input from "../../../lib/ui/fr_input";
import Wnd_Error from "../../../lib/ui/wnd_error";
import Wnd_Ok from "../../../lib/ui/wnd_ok";
import Wnd_Info from "../../../lib/ui/wnd_info";
import Wnd_Confirm from "../../../lib/ui/wnd_confirm";
import Fr_Cal from "../../../lib/ui/fr_cal";
import Lang from "../../../lib/lang";
import Td_Sel from "../../../lib/ui/td_sel";
import Prd_Mp from "./prd_mp";
import Prd_Edit from "./prd_edit";

function TabPanel(props) {
    const { children, value, index, ctx, ...other } = props;
    function tab_change(val) {
        //alert(newValue);
        ctx.setState({ix: val});
    };
    function tab_style(val) {
        var css = {};
        css.height = "30px";
        css.lineHeight = "30px";
        css.background = "#fff";
        css.borderBottom = "1px #ccc solid";
        css.color = "#ccc";
        if(val == ctx.state.ix) {
            css.color = "#080";
            css.borderBottom = "2px #080 solid";
        }
        return css;
    }

    return (
        <div
            className="flex ripple text-center" onClick={() => tab_change(index)} style={ tab_style(index) }
            {...other}
        >
            {children}
        </div>
    );
}

class Mnu_Edit extends React.Component {
  constructor(props, context) {
    super(props);

    this.store = props.store;

    this.state = {};
    this.state.mpa = [];

    this.state.gnr = "001";
      this.state.ynq = [
          {id: '0', text: 'NEJ'},
          {id: '1', text: 'JA'}
      ];
      this.state.stdval = [
          {id: '', text: 'STANDARD'},
          {id: '0', text: 'NEJ'},
          {id: '1', text: 'JA'}
      ];
      this.state.mtval = [
          {id: 'bas', text: 'BAS MENY'},
          {id: 'xtra', text: 'EXTRA MENY'}
      ];

      this.state.ix = 0;
      this.state.selrow = {};
      this.state.msg = {};
      this.state.cols = {};
      this.state.wnds = {};
    this.state.mno = {};
    if(props.wnds.mno) this.state.mno = props.wnds.mno;

      this.selitems = [
          {fkn: 'open', text: 'ÖPPNA'},
          {fkn: 'cancel', text: 'AVBOKA'}
      ];
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      //if(!this.props.wnds.tdo) this.task_init();
      this.mnu_mp();
  }

    task_init() {
        var tdo = {};
        tdo.typ = "";
        tdo.id = "";
        tdo.text = "";
        tdo.reg = "";
        tdo.status = "";
        //alert(JSON.stringify(rso));
        this.setState({ tdo: tdo });
        return;
    }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    tb_style() {
        var css = {};
        css.height = window.innerHeight - 275;
        return css;
    }
    th_style(id) {
        var css = {};
        css.cursor = "pointer";
        if(id == this.state.sortid) {
            if(this.state.sortdir) css.color = "#800";
            else css.color = "#080";
        }
        return css;
    }
    row_style(row) {
        var srow = this.state.selrow;

        var css = {};
        if(srow == row) {
            css.background = "#080";
            css.color = "#fff";
        }
        return css;
    }

    wnd_close = () => {
        if(this.props.close) {
            this.props.close(this.props.ctx);
        }
        else this.props.ctx.setState({ wnds: {} });
    };
    mt_change (e) {
        const value = e.target.value;
        var mno = this.state.mno;
        mno.mnutyp = value;
        this.setState({mno: mno});
    }
    mp_change (e) {
        const value = e.target.value;
        var mno = this.state.mno;
        mno.mpris = value;
        this.setState({mno: mno});
    }
    anm_change (e) {
        const value = e.target.value;
        var mno = this.state.mno;
        mno.anm = value;
        this.setState({mno: mno});
    }

    val_save(id, val) {
        var mno = this.state.mno;
        mno[id] = val;
        this.setState({ mno: mno });
        return;
    }
    sel_row(row) {
        var srow = this.state.selrow;
        if(srow == row) {
            this.setState({selrow: {}});
        }
        else this.setState({selrow: row});
    }
    sel_row_fkn(row, sel) {
        if(sel.fkn == "open") {
        }
    }
    mnu_mp() {
        var self = this;
        var prm = {};
        prm.req = "dsi.dsi_mp.mp_mnu";
        prm.mnuid = this.state.mno.mnuid;

        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret.rca));

            self.setState({ mpa: ret.rca }, function () {
                self.syncTb("mpaTable");
            });
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }
    syncTb(tb) {
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
            tdElements = tbel.getElementsByTagName('td');
        if(tdElements.length < 1 ) return;

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
                width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
    tb_sort(typ) {
        var self = this;
        //var rvs = self.state.rvs;
        var rva = self.state.rva;
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;

        if(sortid != typ) sortdir = 0;
        else {
            if(sortdir) sortdir = 0;
            else sortdir = 1;
        }

        if(typ == "ank") {
            if(sortdir) rva.sort((a,b) => (a.gsb00.r0ankdatum < b.gsb00.r0ankdatum) ? 1 : ((b.gsb00.r0ankdatum < a.gsb00.r0ankdatum) ? -1 : 0));
            else rva.sort((a,b) => (a.gsb00.r0ankdatum > b.gsb00.r0ankdatum) ? 1 : ((b.gsb00.r0ankdatum > a.gsb00.r0ankdatum) ? -1 : 0));
        }
        self.setState({ rva: rva, sortid: typ, sortdir: sortdir });
    }
    th_icon(typ) {
        var cls = "";
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;
        if(sortid == typ)
        {
            if(sortdir) cls = "fa fa-caret-up";
            else cls = "fa fa-caret-down";
        }
        return cls;
    }
  mnu_save() {
    var self = this;
    var prm = {};
    prm.req = "dsi.dsi_mnu.mnu_upd";
    prm.mno = this.state.mno;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));
        if(ret.ok == "000") {
            self.setState({ msg: {ok: true, text: "Sparad ..."} });
            setTimeout(function(){
                self.setState({msg: {} });
                  if(self.props.wnds.cb) {
                      self.props.wnds.cb(self.props.ctx);
                  }
                  else self.props.ctx.setState({ wnds: {} });
                return;
            }, 1000);
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  mnu_del() {
    var self = this;
    var prm = {};
    prm.req = "dsi.dsi_mnu.mnu_del";
    prm.mno = this.state.mno;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));
        if(ret.ok == "000") {
            self.setState({ msg: {ok: true, text: "Borttagen"} });
            setTimeout(function(){
                self.setState({msg: {} });
                  if(self.props.close) {
                      self.props.close(self.props.ctx);
                  }
                  else self.props.ctx.setState({ wnds: {} });
                return;
            }, 1000);
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
    prd_add() {
        var self = this;

        var wnds = {};
        wnds.prdmp = true;
        wnds.mnuid = self.state.mno.mnuid;
        wnds.cb = self.cb_prd;
        this.setState({wnds: wnds});
    }
    cb_prd(ctx) {
        ctx.setState({ wnds: {} });
        ctx.mnu_mp();
    };
    prd_edit(row) {
        var self = this;

        var wnds = {};
        wnds.prdedit = true;
        wnds.mpo = row;
        wnds.cb = self.cb_prd;
        this.setState({wnds: wnds});
    }

  render() {
    //if(!this.props.show) return "";
    var title = this.props.title;
    if(!title) title = "Resurs";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        <div className="m2_backdrop" />
        <div className="m2_dlg mx_90 flex-col" style={ this.dlg_style() } >
        <div className="mx_content flex">

        <div className="mx_h01">
            <div>MENY : { utils.toNum( this.state.mno.mnuid ) }</div>
        </div>
            <div className="divider_orange"></div>

            <div className="flex-row">
                <TabPanel index={0} ctx={this}>MENY</TabPanel>
                <TabPanel index={1} ctx={this}>PRODUKTER</TabPanel>
            </div>
            <div hidden={this.state.ix !== 0}>
            <form className="cdi_f03">
                <div className="flex-row">
                    <Fr_Input label="Meny ID" className="text-left w150" text={this.state.mno.mnuid} getValue={(e)=> this.val_save("mnuid", e) } />
                    <Fr_Input label="Status" className="text-left w350" text={this.state.mno.status} getValue={(e)=> this.val_save("status", e) } />
                </div>
                <div className="flex-row">
                    <Fr_Input label="Text" className="text-left w350" text={this.state.mno.text} getValue={(e)=> this.val_save("text", e) } />
                </div>
                <div className="flex-row">
                    <div className="form-group flex-row flex">
                        <span className="header">Menytyp</span>
                        <select value={this.state.mno.mnutyp} className="form-control w250" onChange={(e) => this.mt_change(e)}>
                            { this.state.mtval.map((row, key) =>
                                <option key={key} value={row.id}>{row.text}</option>
                            )}
                        </select>
                    </div>
                    <div className="form-group flex-row flex">
                        <span className="header">Med Pris</span>
                        <select value={this.state.mno.mpris} className="form-control w250" onChange={(e) => this.mp_change(e)}>
                            { this.state.stdval.map((row, key) =>
                                <option key={key} value={row.id}>{row.text}</option>
                            )}
                        </select>
                    </div>
                </div>
                <div className="flex-row">
                    <Fr_Input label="Text färg" className="text-left w150" text={this.state.mno.txt_fg} getValue={(e)=> this.val_save("txt_fg", e) } />
                </div>

                <div className="divider_green"></div>
                <div className="flex-row">
                    <div className="flex flex-column" style={{padding: "0px 5px"}}>
                        <span className="header">Meny information</span>
                        <textarea className="form-control" maxLength="512" rows="10" value={this.state.mno.anm} onChange={(e) => this.anm_change(e)}></textarea>
                    </div>
                </div>
            </form>
            </div>
            <div hidden={this.state.ix !== 1}>

                <div className="flex-row px-5">
                    <div className="web-link" onClick={() => this.prd_add() }>LÄGG TILL</div>
                    <div className="flex"></div>
                    <div className="web-link" onClick={() => this.mnu_mp() }>UPPDATERA</div>
                </div>
                <div id="mpaTable" className="flex">
                    <table className="table table-bordered table-hover" style={{marginBottom: "0px"}}>
                        <thead>
                        <tr>
                            <th style={ this.th_style("plu") } onClick={() => this.tb_sort("plu") }><Lang>Plu</Lang> <i className={ this.th_icon("plu") }></i></th>
                            <th style={ this.th_style("text") } onClick={() => this.tb_sort("text") }><Lang>Text</Lang> <i className={ this.th_icon("text") }></i></th>
                            <th style={ this.th_style("prdtyp") } onClick={() => this.tb_sort("prdtyp") }><Lang>Prd typ</Lang> <i className={ this.th_icon("prdtyp") }></i></th>
                            <th style={ this.th_style("sort") } onClick={() => this.tb_sort("sort") }><Lang>Sortering</Lang> <i className={ this.th_icon("sort") }></i></th>
                            <th></th>
                        </tr>
                        </thead>
                    </table>
                    <div style={ this.tb_style() }>
                        <table className="table table-bordered table-hover">
                            <tbody>
                            { this.state.mpa.map((row, key) =>
                                <tr key={key} style={ this.row_style(row) } onClick={() => this.sel_row(row)} onDoubleClick={ () => this.prd_edit(row) }>
                                    <td>{ row.plu }</td>
                                    <td>{ utils.toLang( row.text ) }</td>
                                    <td className="text-center">{ row.prdtyp }</td>
                                    <td>{ row.sort }</td>
                                    <td>
                                        <Td_Sel items={ this.selitems } getValue={(e)=> this.sel_row_fkn(row, e)} />
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </div>
        <div className="mx_foot flex-row">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex"></div>
            <button className="btn btn-hw-red w250" onClick={() => this.mnu_del() } >
                TA BORT
            </button>
            <div className="flex"></div>
            <button className="btn btn-hw-red w250" onClick={() => this.mnu_save() } >
                SPARA
            </button>
        </div>
      </div>

        { this.state.wnds.prdmp ? <Prd_Mp wnds={this.state.wnds} store={this.store} ctx={this} /> : null}
        { this.state.wnds.prdedit ? <Prd_Edit wnds={this.state.wnds} store={this.store} ctx={this} /> : null}
        <Wnd_Error msg={this.state.msg} ctx={this} />
        <Wnd_Ok msg={this.state.msg} ctx={this} />
        <Wnd_Info msg={this.state.msg} ctx={this} />
        <Wnd_Confirm msg={this.state.msg} ctx={this} />
      </div>
	;
	return html;
  };
}

export default Mnu_Edit;