import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";

import Fsg_dlg from "./rps/fsg_dlg";
import Bok_Dlg from "./rps/bok_dlg";
import Saldo_Dlg from "./rps/saldo_dlg";
import Fsk_Dlg from "./rps/fsk_dlg";
import Tr_Dlg from "./rps/tr_dlg";
import Pkat_Dlg from "./rps/pkat_dlg";
import Scb_Dlg from "./rps/scb_dlg";
import Cr_Dlg from "./rps/cr_dlg";
import Crh_Dlg from "./rps/crh_dlg";
import Hot_Dlg from "./rps/hot_dlg";
import Rvs_Dlg from "./rps/rvs_dlg";

class Eko_Rps extends React.Component {
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.state = { pgid: 11, tra: [] };
    this.state.seldate = new Date();
    this.state.avd = "01";
    this.state.paa = [];
    this.state.aa = [];
    //context.posrend = this;
    this.state.pdf = 0;
    this.state.rptyp = "";
    this.state.mr = 0;
    this.state.bok = 0;
    this.state.xrecov = 0;

      this.state.mna = [];
      this.state.mna.push({ mnid: "mn01", pos: "c1", text: "FÖRSÄLJNINGSRAPPORT", desc: "Daglig och summerad rapport", link: "fsg" });
      this.state.mna.push({ mnid: "mn02", pos: "c1", text: "BOKFÖRINGSRAPPORT", desc: "Daglig och summerad kontorapport", link: "bok" });
      this.state.mna.push({ mnid: "mn03", pos: "c1", text: "SALDOLISTA", desc: "Dagliga saldon", link: "saldo" });
      this.state.mna.push({ mnid: "mn04", pos: "c1", text: "FÖRSKOTT", desc: "Periodens förskott", link: "fsk" });
      this.state.mna.push({ mnid: "mn05", pos: "c1", text: "TRANSAKTIONS RAPPORT", desc: "Periodens transaktioner", link: "tr" });
      this.state.mna.push({ mnid: "mn06", pos: "c2", text: "PRISKATEGORIER", desc: "Periodens priskategorier", link: "pkat" });
      this.state.mna.push({ mnid: "mn07", pos: "c2", text: "SCB RAPPORT", desc: "SCB för perioden", link: "scb" });
      this.state.mna.push({ mnid: "mn08", pos: "c2", text: "KASSARAPPORT", desc: "Kassarapport för perioden", link: "cr" });
      this.state.mna.push({ mnid: "mn09", pos: "c2", text: "KASSARAPPORT STÄNGD", desc: "Kassarapport för stängd period", link: "crh" });
      this.state.mna.push({ mnid: "mn10", pos: "c2", text: "HOTELLRAPPORT", desc: "Hotellöverföring för perioden", link: "hot" });
      this.state.mna.push({ mnid: "mn11", pos: "c2", text: "BOKNINGAR", desc: "Bokningar för perioden", link: "rvs" });
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 480;
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }
    mnc(pos) {
        var mna = [];
        for(var row of this.state.mna) {
            if(row.pos == pos) mna.push( row );
        }
        return mna;
    }

  rp_open(typ) {
    var self = this;
    this.setState({ rptyp: typ });
  }
  dr_open() {
      var self = this;
    this.setState({ dr: 1 });
  }
  mr_open() {
      var self = this;
    this.setState({ mr: 1 });
  }
  bok_open() {
      var self = this;
    this.setState({ bok: 1 });
  }
  xrecov_open() {
      var self = this;
    this.setState({ xrecov: 1 });
  }

  render() {
    return (
        <div className="web-app">
            <div className="web-pg py-4 flex">
                <h3>HOTELL REDOVISNING RAPPORTER</h3>
                <p>Rapport funktioner</p>

                <div className="divider_green"></div>

                <div className="web-row">
                    <div className="flex-col flex">
                        { this.mnc("c1").map((row, key) =>
                            <div key={key} className="web-bx" onClick={() => this.rp_open(row.link) }>
                                <div>{ row.text }</div>
                                <div className="flex-row">
                                    <div className="flex">{ row.desc }</div>
                                    <div className="web-link">Välj</div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="flex-col flex">
                        { this.mnc("c2").map((row, key) =>
                            <div key={key} className="web-bx" onClick={() => this.rp_open(row.link) }>
                                <div>{ row.text }</div>
                                <div className="flex-row">
                                    <div className="flex">{ row.desc }</div>
                                    <div className="web-link">Välj</div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>

        { this.state.rptyp == "fsg" ? <Fsg_dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "bok" ? <Bok_Dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "saldo" ? <Saldo_Dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "fsk" ? <Fsk_Dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "tr" ? <Tr_Dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "pkat" ? <Pkat_Dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "scb" ? <Scb_Dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "cr" ? <Cr_Dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "crh" ? <Crh_Dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "hot" ? <Hot_Dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.rptyp == "rvs" ? <Rvs_Dlg show={ 1 } ctx={this} store={this.props.store} backdrop={true}/> : null }
    </div>
  );
    }
}
export default Eko_Rps;
