import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../lib/net";
import Td_Sel from "../../lib/ui/td_sel";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class PMS_Tele extends React.Component {
  constructor(props, context) {
    super(props);

    this.socket = null;

    this.webStore = props.store;
    this.state = { pgid: 11, tra: [] };
    this.state.seldate = new Date();
    this.state.avd = "01";
    this.state.rva = [];
    this.state.kro = {};
    this.state.aa = [];
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.tele();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

  wss_msg() {
    var self = this;
    var msg = {};
    msg.txt = "Tjohooo...";
    self.socket.send(msg);
  }
  wss_close() {
    var self = this;
    self.socket.close();
  }

  wss_open() {
      var self = this;
        self.socket = new WebSocket('ws://localhost:7091');
        self.socket.addEventListener('open', function (event) {
          self.socket.send('Hello Server!');
        });

        self.socket.addEventListener('message', function (event) {
          //console.log('Message from server ', event.data);
            alert(event.data);
        });

        self.socket.addEventListener('close', function (event) {
          //console.log('The connection has been closed');
            alert("The connection has been closed");
        });
        self.socket.addEventListener('error', function (event) {
          //console.log('The connection has been closed');
            alert(JSON.stringify(event));
        });
  }

tele() {
    var self = this;
    var prm = {};
    prm.req = "pms.fkn.tele";
    //prm.avd = this.state.avd;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        self.setState({dda: ret.rco }, function () {
            self.syncTb("teleTable");
        });
    })
    .catch(function(e) {
        var txt = JSON.stringify(e);
    });
  }
    syncTb(tb){
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
        tdElements = tbel.getElementsByTagName('td');

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
            width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }

  render() {
    return (
    <div className="web-app">

    <div className="flex-row flex-space-between">
        <div className="flex-row flex">
        <button className="btn btn-hw-blue w150 ripple" onClick={() => this.tele() } aria-label="System" >
            FUNKTIONER
        </button>
        </div>
        <div className="flex-row">
       </div>
        <div className="flex-row flex-end flex hide">
        <button className="btn btn-hw-blue w150 ripple" onClick={() => this.wss_open() } aria-label="System" >
            STARTA
        </button>
        <button className="btn btn-hw-blue w150 ripple" onClick={() => this.wss_close() } aria-label="System" >
            STÄNG
        </button>
        <button className="btn btn-hw-blue w150 ripple" onClick={() => this.wss_msg() } aria-label="System" >
            UPPDATERA
        </button>
        </div>
    </div>
    <div className="divider_orange"></div>

    <div className="web-col">
        <div id="teleTable" className="flex">
        <table className="table table-bordered table-hover tb " style={{marginBottom: "0px"}}>
            <thead>
            <tr>
                <th>Företag</th>
                <th>Namn</th>
                <th>Orgnr</th>
                <th>Telefon</th>
                <th>Mobil</th>
                <th>Epost</th>
                <th>Web</th>
                <th></th>
            </tr>
            </thead>
        </table>
       <div className="tb-cdi" style={ this.tb_style() }>
       <table id="rvpTable" className="table table-bordered table-hover tb">
            <tbody>
            { this.state.rva.map((row, key) =>
            <tr key={key} onDoubleClick={() => this.kund_edit(row) }>
                <td>{ row.resv00.resno }</td>
                <td>{ row.resv00.Company }</td>
                <td>{ row.resv01.Name }</td>
                <td>{ row.resv01.Tfn }</td>
                <td>{ row.resv01.Mobil }</td>
                <td>{ row.resv02.Email }</td>
                <td>{ row.resv00.sign }</td>
                <td>
                    <Td_Sel items={ this.rowitems } getValue={(e)=> this.sel_row_fkn(row, e)} />
                </td>
            </tr>
            )}
            </tbody>
        </table>
        </div>
        </div>

    </div>
   </div>
  );
    }
}
export default PMS_Tele;
