import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { observer } from 'mobx-react';

function Tst_top(props) {
    var it = {};
    it.name = "TesTe";
    var app = {};
    app.name = "TesTe";
    var sdo = {};
    sdo.name = "TesTe";
    sdo.user = {};
    sdo.user.text = "TesTe";
    var app = {};
    app.sub_active = "11";
    app.fkn_active = "22";

    var fa = "fa fa-key";
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [menuClass, setMenuClass] = React.useState(null);

      const handleOpen = event => {
        setAnchorEl(event.currentTarget);
      };

      const handleClose = () => {
        setAnchorEl(null);
      };

      const handleMenuOpen = event => {
        setMenuClass(event.currentTarget);
      };
      const handleMenuClose = () => {
        setMenuClass(null);
      };

      const goFull = () => {
        alert("Full");
      };

    var html =
        <div className="flex flex-row flex-space-between">
            <div className="flex-row flex-vcenter">
                <button className="btn btn-icon" onClick={handleOpen}>
                </button>
                <div>Kassa</div>
            </div>

        <button className="btn btn-icon" onClick={handleOpen}>
        </button>

            <button className="btn btn-icon" onClick={handleMenuOpen}>
            </button>

        </div>
    ;
    return html;
}

export default observer(Tst_top);
