import React, { useState, useEffect, useContext } from 'react';
import {addDays, format, getWeek, startOfWeek, subDays} from "date-fns";
import {Chart} from "chart.js";

import * as net from "../../../lib/net";
import Dt_Cal from "../../../lib/ui/dt_cal";
import Dt_Week from "../../../lib/ui/dt_week";
import Tid_FT from "../../../lib/ui/tid_ft";
import Fr_Select from "../../../lib/ui/fr_select";
import * as utils from "../../../assets/utils";
import sso from "../../../sso";
import {sv} from "date-fns/locale";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Oms_Week extends React.Component {
  constructor(props, context) {
    super(props);

    this.my_bar = null;
    this.store = props.store;
    this.pos = props.store.pos;

    this.state = {};
    this.state.today = new Date();
      //this.state.fdat = new Date(2021, 0, 1);
      this.state.fdat = new Date();
    this.state.tdat = subDays(this.state.today, 1);

      var wdt = startOfWeek(this.state.today, { weekStartsOn: 1, locale: sv });
      var wnr = getWeek(this.state.today, { locale: sv });
      var week = {};
      week.week = wnr;
      week.fdat = format(wdt, "yyyy-MM-dd");
      var wdat = addDays(wdt, 6);
      week.tdat = format(wdat, "yyyy-MM-dd");
      this.state.week = week;
      //this.state.week = {};

    this.state.avd = "01";
    this.state.eka = [];
    this.state.dta = [];
    this.state.tpa = [];
    this.state.weo = {
        15: {id: '15', text: 'V.15 11/4-17/4', fdat:"20220411", tdat:"20220417"},
        16: {id: '16', text: 'V.16 18/4-24/4', fdat:"20220418", tdat:"20220424"},
        17: {id: '17', text: 'V.17 25/4-1/5', fdat:"20220425", tdat:"20220501"},
        18: {id: '18', text: 'V.18 2/5-8/5', fdat:"20220502", tdat:"20220508"},
        19: {id: '19', text: 'V.19 9/5-15/5', fdat:"20220509", tdat:"20220515"},
        20: {id: '20', text: 'V.20 16/5-22/5', fdat:"20220516", tdat:"20220522"},
        21: {id: '21', text: 'V.21 23/5-29/5', fdat:"20220523", tdat:"20220529"},
        22: {id: '22', text: 'V.22 30/5-5/6', fdat:"20220530", tdat:"20220605"},
        23: {id: '23', text: 'V.23 6/6-12/6', fdat:"20220606", tdat:"20220612"},
        24: {id: '24', text: 'V.24 13/6-19/6', fdat:"20220613", tdat:"20220619"},
        25: {id: '25', text: 'V.25 20/6-26/6', fdat:"20220620", tdat:"20220626"},
        26: {id: '26', text: 'V.26 27/6-3/7', fdat:"20220627", tdat:"20220703"},
        27: {id: '27', text: 'V.27 4/7-10/7', fdat:"20220704", tdat:"20220710"},
        28: {id: '28', text: 'V.28 11/7-17/7', fdat:"20220711", tdat:"20220717"},
        29: {id: '29', text: 'V.29 18/7-24/7', fdat:"20220718", tdat:"20220724"},
        30: {id: '30', text: 'V.30 25/7-31/7', fdat:"20220725", tdat:"20220731"},
        31: {id: '31', text: 'V.31 1/8-7/8', fdat:"20220801", tdat:"20220807"},
        32: {id: '32', text: 'V.32 8/8-14/8', fdat:"20220808", tdat:"20220814"},
        33: {id: '33', text: 'V.33 15/8-21/8', fdat:"20220815", tdat:"20220821"},
    };
    this.state.typ = "0";
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.st_week();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }
    get_csa() {
        var csa = [];

        if(sso.seo.csid == "1000") {
            csa = [
                //{ id:"0", typ:"0", text: "INGET CENTRALSYSTEM"},
                { c0lopnr:"1", c0sid:"S0003201", c0text: "SYSTEM 1", c0typ: ""},
                { c0lopnr:"3", c0sid:"S0003202", c0text: "SYSTEM 2", c0typ: ""},
                //{ c0lopnr:"5", c0sid:"S0003203", c0text: "SYSTEM 3", c0typ: ""}
            ];
            //this.setState({ csa: csa });
        }
        if(sso.seo.csid == "0002") {
            csa = [
                //{ id:"0", typ:"0", text: "INGET CENTRALSYSTEM"},
                { c0lopnr:"1", c0sid:"S0003101", c0text: "TGIF KUNGSTRADGARDEN", c0typ: ""},
                { c0lopnr:"2", c0sid:"S0003102", c0text: "TGIF TABY", c0typ: ""},
                { c0lopnr:"3", c0sid:"S0003103", c0text: "TGIF MALMO", c0typ: ""},
                { c0lopnr:"4", c0sid:"S0003104", c0text: "TGIF GAMLASTAN", c0typ: ""},
                { c0lopnr:"5", c0sid:"S0003105", c0text: "UPPSALA", c0typ: ""}
            ];
            //this.setState({ csa: csa });
        }
        if(sso.seo.csid == "0003") {
            csa = [
                //{ id:"0", typ:"0", text: "INGET CENTRALSYSTEM"},
                { c0lopnr:"1", c0sid:"S0003101", c0text: "TGIF KUNGSTRADGARDEN", c0typ: ""},
                { c0lopnr:"2", c0sid:"S0003102", c0text: "TGIF TABY", c0typ: ""},
                { c0lopnr:"3", c0sid:"S0003103", c0text: "TGIF MALMO", c0typ: ""},
                { c0lopnr:"4", c0sid:"S0003104", c0text: "TGIF GAMLASTAN", c0typ: ""},
                { c0lopnr:"5", c0sid:"S0003105", c0text: "UPPSALA", c0typ: ""}
            ];
            //this.setState({ csa: csa });
        }
        if(sso.seo.csid == "0021") {
            csa = [
                //{ id:"0", typ:"0", text: "INGET CENTRALSYSTEM"},
                { c0lopnr:"1", c0sid:"S0004101", c0text: "LIONBAR VISBY", c0typ: "", unit: "lion0001"},
                { c0lopnr:"2", c0sid:"S0004102", c0text: "LIONBAR VASASTAN", c0typ: "", unit: "lion0002"},
                { c0lopnr:"3", c0sid:"S0004103", c0text: "LIONBAR MARIATORGET", c0typ: "", unit: "lion0003"},
                { c0lopnr:"4", c0sid:"S0004201", c0text: "GERMAN VISBY", c0typ: "", unit: "german01"},
            ];
            //this.setState({ csa: csa });
            sso.sdo.csa = csa;
        }
   }

    st_week() {
        var self = this;

        var sdo = {};
        sdo.eko = {};
        var week = this.state.week;
        var fdat = week.fdat;
        var tdat = week.tdat;

        //var csa = self.get_csa();
        var csa = sso.sdo.csa;

        function send_req(cso) {
            const promise1 = new Promise((resolve, reject) => {
                //setTimeout(resolve, 500, 'one');
                var prm = {};
                prm.sid = cso.c0sid;
                prm.req = "pos.pos_eko.sth_oms";
                prm.avd = "01";
                prm.fdat = fdat;
                prm.tdat = tdat;
                //alert(JSON.stringify(prm));

                net.sid_req(prm)
                .then(function(ret){
                    //alert(JSON.stringify(ret));
                    //alert(JSON.stringify(ret));
                    //sdo.eko[sid] = ret.rco;
                    sdo.eko[cso.c0sid] = {};
                    sdo.eko[cso.c0sid].text = cso.c0text;
                    var oms = ret.rco;
                    //alert(JSON.stringify(oms));
                    //so.oms = oms;
                    sdo.eko[cso.c0sid].dto = oms.dto;
                    sdo.eko[cso.c0sid].tot = sdo.tot + (oms.tot * 1);
                    resolve();
                })
                .catch(function(e){
                    var txt = JSON.stringify(e);
                });
            });
            return promise1;
        }
        var qa = [];
        for(var so of csa) {
            var promise = send_req(so);
            qa.push(promise);
        }
        Promise.all(qa)
        .then(function(ret){
            //alert(JSON.stringify(ret));

            var eka = [];
            var keys = Object.keys(sdo.eko);
            keys.sort();
            for(var key of keys) {
                var o = sdo.eko[key];
                eka.push(o);
            }

            var tot = {};
            tot.brutto = 0;
            tot.moms = 0;
            tot.netto = 0;
            tot.fsg = 0;
            tot.bet = 0;
            tot.rab = 0;
            for(so of eka) {
                tot.brutto = tot.brutto + so.tot.brutto;
                so.wtot = 0;
                so.waa = [];
                for(var dto of Object.values(so.dto)) {
                    tot.fsg = tot.fsg + dto.brutto;
                    so.wtot = so.wtot + dto.brutto;
                    so.waa.push( dto.brutto );
                }
            }
            tot.diff = tot.fsg - tot.bet;
            tot.resultat = tot.netto - tot.rab;

            self.setState({ dta: eka, tot: tot });
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }

  st_weekx() {
    var self = this;
    if(this.state.week == null) return;

    var week = this.state.week;
    var fdat = week.fdat;
    var tdat = week.tdat;
    //alert(JSON.stringify(fdat));
    //return;

    //var fdat = format(fdat, "yyyy-MM-dd");
    //var tdat = format(tdat, "yyyy-MM-dd");

    var prm = {};
    prm.req = "bi.bi_stat.cs_week";
    prm.csid = this.store.seo.csid;
    prm.avd = this.state.avd;
    prm.fdat = fdat;
    prm.tdat = tdat;
    //alert(JSON.stringify(prm));

    net.ccs_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        var dta = ret.rca;
        alert(JSON.stringify(dta));
        self.setState({ dta: dta }, () => {
        });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

    row_proc(row) {
      var proc = 0;
      if(this.state.sdo.tot.fsg > 0) {
          proc = (row.fsg / this.state.sdo.tot.fsg) * 100;
          proc = Math.round(proc);
      }
      return proc + " %";
    }
    row_wday(row) {
      var day = "";
      if(row.wday == 1) day = "Måndag";
      if(row.wday == 2) day = "Tisdag";
      if(row.wday == 3) day = "Onsdag";
      if(row.wday == 4) day = "Torsdag";
      if(row.wday == 5) day = "Fredag";
      if(row.wday == 6) day = "Lördag";
      if(row.wday == 7) day = "Söndag";
      return day;
    }
    bar_chart() {
      var lbls = [];
      var da = {};
      da.ink = [];
      da.ex = [];

      for(var row of this.state.eka) {
          if(row.grp == "tot") continue;
          lbls.push( row.text.toswe() );
          da.ink.push( row.belopp.cdipris() );
      }

        if(this.my_bar) this.my_bar.destroy();
        this.my_bar =  new Chart(document.getElementById("barchart"), {
            type: 'bar',
            data: {
				labels: lbls,
                datasets: [{
                    data: da.ink,
                    label: 'Belopp',
                    backgroundColor: "#4755AB",
                    borderWidth: 1,
                }]
            },
            options: {
                responsive: true,
                legend: {
                    display: false,
                    position: 'top'
                },
            }
        });
	};
    ch_week(e) {
        e.preventDefault();
        const id = e.target.value;
        var week = this.state.weo[id];
        //alert(JSON.stringify(week));
        this.setState({ week: week }, () => {
        });
    }

  render() {
    return (
    <div className="web-p00 flex flex-col">

    <div className="web-p01">
    <div className="flex-row">
        <Fr_Select label="" className="text-left w350 hide" text={this.state.week} items={this.state.waa} getValue={(e)=> this.setState({ "week": e }) } />
        <Dt_Week dat={this.state.fdat} getValue={(e)=> this.setState({week: e}) }/>
        <select className="form-control w250 hide" placeholder="Välj avdelning">
            { this.pos.deps.map((row, key) =>
            <option key={key} value={row.id}>{row.text}</option>
            )}
        </select>
        <div className="flex"></div>

        <button className="btn btn-hw-blue w150" onClick={() => this.st_week() } aria-label="System" >
            UPPDATERA
        </button>
    </div>

    <div className="divider_orange"></div>

    <div className="web-row">
        <table className="table table-bordered table-hover tb fixed_header">
            <thead>
            <tr>
                <th>Text</th>
                <th>Måndag</th>
                <th>Tisdag</th>
                <th>Onsdag</th>
                <th>Torsdag</th>
                <th>Fredag</th>
                <th>Lördag</th>
                <th>Söndag</th>
                <th>Totalt</th>
            </tr>
            </thead>
            <tbody style={ this.tb_style() }>
            { this.state.dta.map((row, key) =>
            <tr key={ key } onDoubleClick={() => this.st_edit(row) }>
                <td>{ row.text }</td>
                { row.waa.map((brutto, key) =>
                    <td className="text-right">{ utils.toPris( brutto ) }</td>
                )}
                <td className="text-right">{ utils.toPris( row.wtot ) }</td>
            </tr>
            )}
            </tbody>
        </table>
    </div>

    </div>
    </div>
  );
    }
}
export default Oms_Week;
