import React, { useState, useEffect, useContext } from 'react';
import {format} from "date-fns";

import * as net from "../../../../lib/net";
import Dd_Sel from "../../../../lib/ui/dd_sel";
import Dt_Cal from "../../../../lib/ui/dt_cal";
import Fr_Input from "../../../../lib/ui/fr_input";

class Pay_Belopp extends React.Component {
  constructor(props, context) {
    super(props);

     this.selitems = [
        {fkn: 'setup', text: 'INSTÄLLNINGAR'},
        {fkn: 'upd', text: 'UPPDATERA'},
        {fkn: 'sys', text: 'SYSTEM'},
        {fkn: 'logout', text: 'LOGGA UT'}
    ];

    this.dt_dt = React.createRef();

    this.state = {};
    this.state.ynq = [];

    this.state.paa = [];
    this.state.aa = [];
    this.state.belopp = "";

    if(props.wnds) {
        this.fkn_close = props.wnds.fkn_close;
        this.state.boknr = props.wnds.boknr;
        this.state.rvo = props.wnds.rvo;
        this.state.nta = props.wnds.nta;
        this.state.srow = props.wnds.srow;
    }

    this.state.today = new Date();
    var dts = format(this.state.today, "yyyy-MM-dd");
    this.state.dts = dts;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }


    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    wnd_close() {
      if(this.fkn_close) this.fkn_close(this.props.ctx);
      else this.props.ctx.setState({ wnds: {} });
	};
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  belopp_set(val) {
      var belopp = "";
      if(val) {
          belopp = val.toString();
          //belopp = belopp.cdipris();
         this.setState({belopp: belopp, belman:1});
     }
  }
  ch_belopp(betkod) {
    var self = this;
    var belopp = this.state.belopp;
    belopp = belopp.toString().replace(/[.]/g, '');
    belopp = belopp * 1;


    var tro = {}
    tro.id = betkod;
    tro.text = "BET : " + betkod;
    tro.pris = belopp;
    tro.moms = "";

    //alert(JSON.stringify(this.state.rvo.gsb00));
    //alert(JSON.stringify(this.state.gso));
    //return;
    var prm = {};
    prm.req = "pms.sell.charge_tr";
    prm.boknr = this.state.rvo.boknr;
    prm.gsb00 = this.state.rvo.gsb00;
    prm.trkod = tro.id;
    prm.text = tro.text;
    prm.antal = "1";
    prm.pris = tro.pris;
    prm.tkn = "-";
    prm.moms = tro.moms;
    prm.datum = this.state.dts;
    prm.notanr = "";

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rco));

        if(self.fkn_close) self.fkn_close(self.props.ctx);
        else self.props.ctx.setState({ wnds: {} });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "";

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="m2_backdrop" /> : null }
        <div className="m2_dlg mx_70 flex-col" style={ this.dlg_style() }>
        <div className="flex-row " style={{padding:"0px",height:"50px",lineHeight:"50px", background: "#1a456b", color: "#fff"}}>
                <div className="flex-row flex" style={{padding:"0px 5px", fontSize: "22px"}}>
                    <div>BETALA BELOPP { this.state.rumsnr }</div>
                </div>
            <div className="wnd-close-btn" onClick={() => this.wnd_close() }>X</div>
        </div>
        <div className="mx_content flex">

        <div className="flex"></div>

        <div className="divider_orange"></div>

        <div className="web-col">
        <div className="web-row px-2">
            <div className="flex"></div>
            <div className="txt_16">BELOPP ATT BETALA: </div>
            <Fr_Input noflex className="text-right w150" text={this.state.belopp} getValue={(e)=> this.belopp_set(e) } />
            <div className="flex"></div>
        </div>
        </div>

        <div className="divider_orange"></div>
        <div className="flex"></div>

        </div>

        <div className="web-row">
        <div className="web-col flex">
            <div className="wnd-btn" onClick={() => this.sz() } >
                <div>FLER BETALSÄTT</div>
                <div></div>
            </div>
            <div className="wnd-btn" onClick={() => this.wnd_close() } >
                <div>STÄNG</div>
                <div></div>
            </div>
        </div>
         <div className="web-col flex" style={{padding: "0px 10px"}}>
            <div className="wnd-btn" onClick={() => this.ch_belopp("card") } >
                <div>KORT EXTERN</div>
                <div></div>
            </div>
            <div className="wnd-btn" onClick={() => this.ch_belopp("pres") } >
                <div>PRESENTKORT</div>
                <div></div>
            </div>
       </div>
         <div className="web-col flex" style={{padding: "0px 10px"}}>
            <div className="wnd-btn" onClick={() => this.ch_belopp("emv") } >
                <div>EMV KORT</div>
                <div></div>
            </div>
            <div className="wnd-btn" onClick={() => this.ch_belopp("kont") } >
                <div>KONTANT</div>
                <div></div>
            </div>
       </div>
       </div>

      </div>
    </div>
	;
	return html;
  };
}

export default Pay_Belopp;