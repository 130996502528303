import React, { useState, useEffect, useContext } from 'react';
import {format, addDays, subDays} from "date-fns";

import * as net from "../../../lib/net";
import Fr_Input from "../../../lib/ui/fr_input";
import Fr_Select from "../../../lib/ui/fs_select";
import Tb_Copy from "./tb_cpy";

class Tb_Edit extends React.Component {
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pos = props.store.pos;

    this.state = {};
    this.state.today = new Date();
    this.state.rta = [];
    this.state.pka = [];

    this.state.gnr = "001";
    this.state.gso = {};

    this.state.ynq = [
                {id: '0', text: 'NEJ'},
                {id: '1', text: 'JA'}
            ];
      this.state.fval = [
          {id: 'RECTANGULAR', text: 'RECTANGULAR'},
          {id: 'ROUND', text: 'ROUND'}
      ];

      this.state.rs = "";
      this.state.rsa = [];
    this.state.tbo = {};
    this.state.background = "#fff";

    this.state.wnds = {};
    if(props.wnds) {
        this.state.rs = props.wnds.rs;
        this.state.rsa = props.wnds.rsa;
        this.state.tbo = props.wnds.tbo;
        this.state.tbo.bordsname = props.wnds.tbo.bordsname.trim();
    }
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    grp_style(row) {
      var css = {};
      css.background = "#008";
      if(this.state.gnr === row.vgrupp) css.background = "#080";
      return css;
    }

    formchange (e) {
      const name = e.target.name;
      const value = e.target.value;
      var pbo = this.state.pbo;
      var na = name.split(".");
      pbo[na[0]][na[1]] = value;
      this.setState({pbo: pbo});
      //setState({[name]: value});
    }

    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    wnd_close = () => {
      if(this.props.cb) {
        var ret = {};
        ret.ok = "999";
        this.props.cb(this.props.ctx, ret);
      }
      else this.props.ctx.setState({ wnds: {} });
	};

  submitHandler = event => {
    event.preventDefault();
    event.target.className += " was-validated";
  };

    val_save (name, value) {
        //alert(JSON.stringify(name));
        //alert(JSON.stringify(value));
      var tbo = this.state.tbo;
      tbo[name] = value;
      this.setState({tbo: tbo});
    }
    cb_save (name, e) {
      const value = e.target.value;
      var bro = this.state.bro;
      bro[name] = value;
      this.setState({bro: bro});
      //setState({[name]: value});
    }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }

  tbo_init() {
    var self = this;

    var tbo = {};
    tbo.bordsnr = "";
    tbo.bordstyp = "RECTANGULAR";
    tbo.bordX = "120";
    tbo.bordXlen = "120";
    tbo.bordY = "80";
    tbo.bordYlen = "880";
    tbo.fg = "";
    tbo.bg = "";
    this.setState({tbo: tbo});
  }
  rts() {
    var self = this;
    var prm = {};
    prm.req = "pms.rt.rt_date";
    //prm.datum = this.state.datum;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            return;
        }
        self.setState({rta: ret.rco});
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

    tb_copy() {
        var self = this;
        var wnds = {};
        wnds.tbcopy = true;
        wnds.rs = this.state.rs;
        wnds.rsa = this.state.rsa;
        wnds.tbo = this.state.tbo;
        //wnds.cb = self.cb_tbo;
        self.setState({wnds: wnds });
    }
  tb_upd() {
    var self = this;
    var prm = {};
    //prm.req = "pms.rs_lt.lt_tb_save";
    prm.req = "pos.pos_tbv.tbv_upd";
    prm.rs = this.state.rs;
    prm.tbo = this.state.tbo;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));
        if(self.props.wnds.cb) {
        var ret = {};
        ret.ok = "000";
        self.props.wnds.cb(self.props.ctx, ret);
      }
      else self.props.ctx.setState({ wnds: {} });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  tb_del() {
    var self = this;
    var prm = {};
    //prm.req = "pms.rs_lt.lt_tb_del";
    prm.req = "pos.pos_tbv.tbv_del";
    prm.rs = this.state.rs;
    prm.tbo = this.state.tbo;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));
      if(self.props.wnds.cb) {
        var ret = {};
        ret.ok = "000";
        self.props.wnds.cb(self.props.ctx, ret);
      }
      else self.props.ctx.setState({ wnds: {} });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "Gäst";
    //if(props.kro) alert(props.kro.krr00.kid);
    const popover = {
      position: 'absolute',
      top: '50px',
      zIndex: '2',
    }
    const cover = {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    }

	var html =
        <div className="mx_base">
        <div className="m3_backdrop" />
        <div className="m3_dlg mx_50h flex-col" style={ this.dlg_style() } draggable="true" >

        <div className="mx_h01">
            <div>BORD { this.state.tbo.bordsnr }</div>
        </div>
        <div className="mx_content flex">

            <div className="divider_orange"></div>

            <form className="cdi_f03">
            <div className="flex-row py-2">
                    <Fr_Input label="Bordsnr" className="text-left w150" text={this.state.tbo.bordsnr} getValue={(e)=> this.val_save("bordsnr", e) } />
            </div>
            <div className="flex-row py-2">
                    <Fr_Input label="X" className="text-left w250" text={this.state.tbo.bordX} getValue={(e)=> this.val_save("bordX", e) } />
                    <Fr_Input label="X Längd" className="text-left w250" text={this.state.tbo.bordXlen} getValue={(e)=> this.val_save("bordXlen", e) } />
            </div>
            <div className="flex-row py-2">
                    <Fr_Input label="Y" className="text-left w250" text={this.state.tbo.bordY} getValue={(e)=> this.val_save("bordY", e) } />
                    <Fr_Input label="Y Längd" className="text-left w250" text={this.state.tbo.bordYlen} getValue={(e)=> this.val_save("bordYlen", e) } />
            </div>
            <div className="divider_green"></div>
            <div className="flex-row py-2">
                    <Fr_Select label="Bordstyp" className="text-left w350" text={this.state.tbo.bordsname} items={this.state.fval} getValue={(e)=> this.val_save("bordsname", e) } />
            </div>
            <div className="flex-row py-2">
                    <Fr_Input label="Färg" className="text-left w250" text={this.state.tbo.coolor} getValue={(e)=> this.val_save("color", e) } />
            </div>
            <div className="flex-row py-2">
                    <Fr_Input label="Bakgrund" className="text-left w250" text={this.state.tbo.background} getValue={(e)=> this.val_save("background", e) } />
            </div>
            <div className="divider_green"></div>
            <div className="flex-row py-2">
                    <Fr_Input label="Zon" className="text-left w250" text={this.state.tbo.zon} getValue={(e)=> this.val_save("zon", e) } />
            </div>
            <div className="divider_green"></div>
            </form>
        </div>

        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w200" onClick={() => this.wnd_close() } >
                AVBRYT
            </button>
            <div className="flex"></div>
            <button className="btn btn-hw-red w150" onClick={() => this.tb_del() } >
                TA BORT
            </button>
            <button className="btn btn-hw-red w150" onClick={() => this.tb_copy() } >
                KOPIERA
            </button>
            <div className="flex"></div>
            <button className="btn btn-hw-green w200" onClick={() => this.tb_upd() } >
                SPARA
            </button>

        </div>
      </div>
            { this.state.wnds.tbcopy ? <Tb_Copy wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true} /> : null}

      </div>
	;
	return html;
  };
}

export default Tb_Edit;
