import React, { useState, useEffect, useContext } from 'react';
import { format, addDays } from 'date-fns'

import * as net from "../../lib/net";
import Dt_Cal from "../../lib/ui/dt_cal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class PMS_BelRt extends React.Component {
  constructor(props, context) {
    super(props);

    this.dt_fd = React.createRef();
    this.dt_td = React.createRef();

    this.webStore = props.store;
    this.state = { pgid: 11, tra: [] };
    this.state.avd = "01";
    this.state.rva = [];
    this.state.kro = {};
    this.state.aa = [];
    this.state.cols = {};
    this.state.firstday = {};
    this.state.caldata = [];
    this.state.rtdata = [];
    this.state.dio = {};
    this.state.loading = true;

    this.state.today = new Date();
    var hh = format(this.state.today, "hh");
    if(hh < 5) this.state.today.setDate(this.state.today.getDate() - 1);
    this.state.fdat = format(this.state.today, "yyyy-MM-dd");
    var tdat = addDays(this.state.today, 15);
    this.state.tdat = format(tdat, "yyyy-MM-dd");
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.bel_rt();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

  val_belag(val) {
      var css = {};
      if(val > 74){ css.background = "#ff9900"; css.color = "#fff"; }
      if(val > 99){ css.background = "#800"; css.color = "#fff"; }
      return css;
  }
  val_style(val) {
      var css = {};
      if(val < 0){ css.background = "#800"; css.color = "#fff"; }
      return css;
  }
  dt_style(dto) {
    var css = {};
    css.color = "#fff";
    return css;
  }
  dt_class(dto) {
      var cls = "";
      if(dto.wday == 5) cls = "weekend";
      if(dto.wday == 6) cls = "weekend2";
      return cls;
  }
  get_css(dto) {
      var cls = "";
      if(dto.wday == 5) cls = "weekend";
      if(dto.wday == 6) cls = "weekend2";
      return cls;
  }
  dt_check(dto) {
      //dto.wday == '0';
      return true;
  }
  dioenter(dto) {
      //dto.wday == '0';
      return;
  }
  dioleave(dto) {
      //dto.wday == '0';
      return;
  }
  set_di(dto) {
      //dto.wday == '0';
      return;
  }
  selov(dto) {
      //dto.wday == '0';
      return;
  }
  seldn(dto) {
      //dto.wday == '0';
      return;
  }
  selup(dto) {
      //dto.wday == '0';
      return;
  }
  sel_rt(dto) {
      //dto.wday == '0';
      return;
  }
  selfdt(dto) {
      //dto.wday == '0';
      return;
  }
  selrt(dto) {
      //dto.wday == '0';
      return;
  }

  bel_rt() {
    var self = this;

    var prm = {};
    prm.req = "pms.cal.pms_cal_rt";
    prm.fdat = this.state.fdat;
    prm.tdat = this.state.tdat;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        //alert(JSON.stringify(ret.rta));
        var rta = [];
        var keys = Object.keys(ret.rta);
        for(var key of keys) {
            var rto = ret.rta[key];
            //alert(JSON.stringify(key));

            var o = {};
            o.rt = key;
            o.dta = [];

            var dkeys = Object.keys(rto);
            for(var dkey of dkeys) {
                var dto = rto[dkey];
                //alert(JSON.stringify(dkey));
               o.dta.push(dto);
            };
            rta.push( o );
        };

        self.setState({caldata: ret.cal, rtdata: rta, loading: false });
        //self.syncCols("rtTable");
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e) {
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }
  dt_fdat(e) {
    //alert(JSON.stringify(e));
    var dts = format(e, "yyyy-MM-dd");
    if(dts > this.state.tdat) {
        //this.setState({ fdat: dts, tdat: dts });
        var tdat = addDays(e, 15);
        var tds = format(tdat, "yyyy-MM-dd");
        this.dt_td.current.updateDt(tds);
    }
    this.setState({ fdat: dts });
  }
  dt_tdat(e) {
      //alert(JSON.stringify(e));
    var dts = format(e, "yyyy-MM-dd");
    this.setState({ tdat: dts });
  }
  oneday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_td.current.updateDt(this.state.fdat);
  }
  addday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_fd.current.updateAdd();
    this.dt_td.current.updateAdd();
  }
  subday(e) {
      //alert(JSON.stringify(e));
    //this.setState({ tdat: this.state.fdat });
    this.dt_fd.current.updateSub();
    this.dt_td.current.updateSub();
  }

  render() {
    return (
    <div className="web-app">

    <div className="flex-row flex-space-between">
        <div className="flex-row">
              <div className="dt-btn-01" onClick={() => this.subday() } ><FontAwesomeIcon icon="minus" size="1x" /></div>
              <Dt_Cal dts={this.state.fdat} rp="1" ref={this.dt_fd} getValue={(e)=> this.dt_fdat(e)} />
              <div className="dt-btn-01" onClick={() => this.oneday() } >1</div>
              <Dt_Cal dts={this.state.tdat} rp="1" ref={this.dt_td} getValue={(e)=> this.dt_tdat(e)} />
              <div className="dt-btn-01" onClick={() => this.addday() } ><FontAwesomeIcon icon="plus" size="1x" /></div>
        </div>
        <div className="flex"></div>
        <button className="btn btn-hw-blue w150 ripple" onClick={() => this.bel_rt() } aria-label="System" >
            UPPDATERA
        </button>
    </div>
    <div className="divider_orange"></div>

    { this.state.loading ? <div className="flex center"><FontAwesomeIcon icon="spinner" spin /></div> : null }
    { !this.state.loading ?
    <div className="web-col">

        <table className="table table-bordered table-striped tb_pres">
            <thead>
            <tr>
                <th className='header'></th>
                { this.state.caldata.map((dto, key) =>
                    <th key={dto.dt} style={ this.dt_style(dto) } onMouseEnter={ () => this.dioenter(dto) }
                        onMouseLeave={ () => this.dioleave(dto) } onClick={ () => this.set_di(dto) }>{ dto.dt }</th>
                )}
            </tr>
            <tr>
                <th className='header'></th>
                { this.state.caldata.map((dto, key) =>
                    <th style={{color: "#fff"}} key={dto.dt}>{ dto.shortday }</th>
                )}
            </tr>
            </thead>
            <tbody>
            <tr>
                <td className='header'>Bokat</td>
                { this.state.caldata.map((dto, key) =>
                    <td key={dto.dt} className={ this.dt_class(dto)}>{ dto.borr }</td>
                )}
            </tr>
             <tr>
                <td className='header'>Gäster</td>
                { this.state.caldata.map((dto, key) =>
                    <td key={dto.dt} className={ this.dt_class(dto)}>{ dto.borg }</td>
                )}
            </tr>
            <tr>
                <td className='header'>Barn (0-4)/(5-15)</td>
                { this.state.caldata.map((dto, key) =>
                    <td key={dto.dt} className={ this.dt_class(dto)}>{ dto.antb0 }/{ dto.antb1 }</td>
                )}
            </tr>
            <tr>
                <td className='header'>Ankommande</td>
                { this.state.caldata.map((dto, key) =>
                    <td key={dto.dt} className={ this.dt_class(dto)}>{ dto.ankr }</td>
                )}
            </tr>
            <tr>
                <td className='header'>Lediga rum</td>
                { this.state.caldata.map((dto, key) =>
                    <td key={dto.dt} className={ this.dt_class(dto)} style={ this.val_style(dto.free ) }>{ dto.free }</td>
                )}
            </tr>
            <tr>
                <td className='header'>Stängda</td>
                { this.state.caldata.map((dto, key) =>
                    <td key={dto.dt} className={ this.dt_class(dto)} style={ this.val_style(dto.closed ) }>{ dto.closed }</td>
                )}
            </tr>
            <tr>
                <td className='header'>Frukost</td>
                { this.state.caldata.map((dto, key) =>
                    <td key={dto.dt} className={ this.dt_class(dto)} style={ this.val_style(dto.breakfast ) }>{ dto.breakfast }</td>
                )}
            </tr>
            <tr className='totrad'>
                <td className='header'>Belägging</td>
                { this.state.caldata.map((dto, key) =>
                    <td key={dto.dt} style={ this.val_belag(dto.belag ) }>{ dto.belag }%</td>
                )}
            </tr>
           </tbody>
            <tbody className="">
                { this.state.rtdata.map((rto, key) =>
            <tr key={key}>
                <td onClick={ () => this.selrt() } className='header'>{rto.rt.trim()}</td>
                { rto.dta.map((dto, dkey) =>
                <td key={dkey} onClick={ () => this.selfdt(dto) } onMouseOver={ () => this.selov(dto) } onMouseDown={ () => this.seldn(dto) } onMouseUp={ () => this.selup(dto) }
                    className={ this.get_css(dto) } style={ this.val_style(dto.maxr - dto.borr - dto.closed) }>{ dto.maxr - dto.borr - dto.closed}</td>
                )}
            </tr>
                )}
            <tr className='totrad'>
                <td className='header'>Totalt</td>
                { this.state.caldata.map((dto, key) =>
                <td key={key} style={ this.val_style(dto.available - dto.nrr - dto.closed) }>{dto.available - dto.nrr - dto.closed}</td>
                )}
            </tr>
            </tbody>

        </table>

    </div>
        : null }
   </div>
  );
    }
}
export default PMS_BelRt;

/*
                <tr>
                <th rowSpan="3" style={{textAlign: "center"}}>Text</th>
                { (this.state.firstday.wday * 1) > 0 ? <th colSpan={ (7 - (this.state.firstday.wday * 1)) }>v.{ this.state.firstday.week }</th> : null }
                { this.state.caldata.map((dto, key) =>
                    <div>
                    { this.dt_check(dto) ? <th colSpan="7">v.{ dto.week }</th> : null }
                    </div>
                )}
            </tr>


*************

            <tr ng-repeat="(rt, dta) in rtdata" ng-if="rt.indexOf('**') !== -1" ng-dblclick="">
                <td className='header'>{{rt | txt2sv}}</td>
                <td ng-repeat="(dt, dto) in dta"
                    ng-class="{ weekend: dto.wday==5, weekend2: dto.wday==6, overbook: dto.free<0 }">{{
                    dto.maxr - dto.borr - dto.closed
                }}</td>
            </tr>

*/