import React, { useState, useEffect, useContext } from 'react';
import {Route, Routes} from "react-router-dom";

import Eko_Home from './eko/home.js';
import Reg_Trs from './eko/trs.js';
import Reg_Pkat from './eko/pkat.js';
import Reg_Pay from './eko/pay.js';
import {withRouter} from "../../lib/react/withRouter";
import sso from "../../sso";

class Reg_Eko extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = { pgid: 11, deps: [] };
    //context.posrend = this;
  }
  fknGo(row) {
    //alert(row.href);
    this.props.store.fkn = row;
    this.props.navigate(row.url);
  }
    br_css(row) {
        var css = {};
        if(sso.seo.sfkn && (row.url == sso.seo.sfkn.url)) css.color = "#080";
        //var brc = false;
        //if(row.url == sbo.state.url) brc = true;
        return css;
    }

  render() {
      var path = window.location.pathname;
      if(path.substr(-1) == "/") path = path.substring(0, path.length - 1);
      var op = path.split("/").pop();
      var po = {};
      po.trs = { "fid": "f01", "url": "/pms/hotreg/eko/trs", "name": "Transaktioner", "active": false };
      po.pkat = { "fid": "f01", "url": "/pms/hotreg/eko/pkat", "name": "Priskategorier", "active": false };
      po.pay = { "fid": "f01", "url": "/pms/hotreg/eko/pay", "name": "Betalningar", "active": false };
      if(po[op]) {
          po[op].active = true;
          //this.props.store.fkn = po[op];
      }
      var pa = Object.values(po);

    return (
    <div className="web-app">
         { this.props.store.cfg.bread ?
        <div className="web-bread">
            <div className="breadcrumb">
            { pa.map((row, key) =>
                <div key={key} className="breadcrumb-item" style={this.br_css(row)} onClick={() => this.fknGo(row)}>{row.name}</div>
            )}
            <div className="flex"></div>
            <div className="cdigreen">{ this.props.store.fkn ? this.props.store.fkn.name : null }</div>
            </div>
        </div>
        : null }
        <Routes>
            <Route path="trs" element={ <Reg_Trs store={this.webStore} />} />
            <Route path="pkat" element={ <Reg_Pkat store={this.webStore} />} />
            <Route path="pay" element={ <Reg_Pay store={this.webStore} />} />
            <Route path="*" element={ <Eko_Home store={this.webStore} />} />
        </Routes>
    </div>

  );
}
}
//Sys.contextType = ADOContext;

export default withRouter(Reg_Eko);
