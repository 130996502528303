import React, { useState, useEffect, useContext } from 'react';

import * as net from "../../../lib/net";
import * as utils from "../../../assets/utils";
import Fr_Input from "../../../lib/ui/fr_input";
import Order_Trs from "./order_trs";
import PdfRpDlg from "./pdf_rp_dlg";
import Wnd_Error from "../../../lib/ui/wnd_error";
import Wnd_Ok from "../../../lib/ui/wnd_ok";
import Wnd_Info from "../../../lib/ui/wnd_info";
import Wnd_Confirm from "../../../lib/ui/wnd_confirm";

class Order_kvitto extends React.Component {
  constructor(props, context) {
    super(props);

    this.pos = props.store.pos;
    this.state = {};
    this.state.ynq = [];
    this.state.sval = [];

    this.state.dao = {};
    this.state.aa = [];
    this.state.cval = [
        {id: '001', text: 'ANTAL GER NYTT PRIS'},
        {id: '002', text: 'ANTAL GER RABATT PÅ PRIS'},
        {id: '003', text: 'PRISÄNDRING GENOM RABATT'}
    ];

      this.state.msg = {};
      this.state.wnds = {};
      this.state.vpo = {};
      this.state.epost = "";
      this.state.tele = "";
    if(props.wnds) {
        this.state.vpo = props.wnds.vpo;
        this.state.orderid = this.state.vpo.order00.orderid;
        this.state.epost = this.state.vpo.order01.epost;
        this.state.tele = this.state.vpo.order01.tele;
    }
      this.state.pdf = 0;
      this.state.pdf_content = null;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.happy();
  }
    cdi_init() {
        var self = this;
        var vpo = self.state.vpo;

        if(vpo.vip00) {
            //vpo.vip00.q0pris2 = vpo.vip00.q0pris2.formatpris();
            //vpo.vip00.q0npris2 = vpo.vip00.q0npris2.formatpris();
        }

        //self.setState({hpo: hpo,  md_init: true});
    }
    cdi_store() {
        var self = this;
/*
        var hap = self.state.hap;
        var hpo = self.state.hpo;

        if(!hap.hap00) {
            hap.hap00 = {};
        }
        hap.hap00 = {};

        hap.hap00.q0p = "Q";
        hap.hap00.q0kstns = this.state.pbo.plu00.p0kstns
        hap.hap00.q0katnr = this.state.pbo.plu00.p0katnr

        hap.hap00.q0pris2 = "";
        hap.hap00.q0npris2 = "";

        if(hpo.q0pris2) hap.hap00.q0pris2 = hpo.q0pris2.pris2cdi();
        if(hpo.q0npris2) hap.hap00.q0npris2 = hpo.q0npris2.pris2cdi();

        self.setState({hap: hap});
*/
    }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    tab_style(tabid) {
      var css = {};
      css.display = "none";
      if(this.state.tabval === tabid) css.display = "block";
      return css;
    }
    tab_head(tabid) {
      var css = {};
      css.background = "#fff";
      css.color = "#000";
      if(this.state.tabval === tabid) {
          css.background = "#080";
          css.color = "#fff";
      }
      return css;
    }
    grp_style(row) {
      var css = {};
      css.background = "#008";
      if(this.state.gnr === row.vgrupp) css.background = "#080";
      return css;
    }

    formchange (e) {
      const name = e.target.name;
      const value = e.target.value;
      var pbo = this.state.pbo;
      var na = name.split(".");
      pbo[na[0]][na[1]] = value;
      this.setState({pbo: pbo});
      //setState({[name]: value});
    }

    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    wnd_close = () => {
		this.props.ctx.setState({ wnds: {} });
	};
    handleChange = (event, newValue) => {
        //alert(newValue);
        this.setState({ix: newValue});
      };
    toggle() {
      this.setState({modal: false});
	};

  submitHandler = event => {
    event.preventDefault();
    event.target.className += " was-validated";
  };

  changeHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
    pdf_done (ret) {
        this.setState({loading: 0, pdf: 0, pdf_content: null });
    }

    kvitto_show() {
        var self = this;
    }
    kvitto_prn() {
        var self = this;
        var prm = {};
        prm.req = "pos.pos_rp.inv_copy";
        prm.datum = this.state.vpo.order00.datum;
        prm.avd = this.state.vpo.order00.avd;
        prm.notanr = this.state.vpo.order00.notanr;
        //alert(JSON.stringify(prm));

        //gda.wndLoading("Hämtar");
        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret));

                if(ret.ok == "000") {
                    //self.cdi_store();
                }
                self.setState({pdf: 1, pdf_content: ret.pdf });
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
                //gda.wndError(txt);
                self.setState({md_01: false });
            });
        return;
     }
    kvitto_mail() {
        var self = this;
        var orderid = this.state.vpo.order00.orderid;
        var msg = { info: true, text: "Skickar Mail..."};
        self.setState({msg: msg });

        var prm = {};
        prm.req = "vcm.ecom.order_confirm_mail";
        prm.orderid = orderid;
        prm.epost = this.state.epost;

        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret));
            var msg = { info: false, ok: true, text: "Mail skickat..."};
            self.setState({msg: msg });
            setTimeout(function(){
                self.setState({msg: {} });
            }, 1000);
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }
    kvitto_sms() {
        var self = this;

        var orderid = this.state.vpo.order00.orderid;
        var msg = { info: true, text: "Skickar SMS..."};
        self.setState({msg: msg });

        var prm = {};
        prm.req = "vcm.ecom.order_confirm_sms";
        prm.orderid = orderid;
        prm.tele = this.state.tele;

        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret));
            var msg = { info: false, ok: true, text: "SMS skickat..."};
            self.setState({msg: msg });
            setTimeout(function(){
                self.setState({msg: {} });
            }, 1000);
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }
    shownota() {
        var self = this;
        var wnds = {};
        wnds.ordertrs = 1;
        wnds.vpo = this.state.vpo;
        self.setState({ wnds: wnds });
    }
    val_get (name) {
        var value = "";
        //alert(JSON.stringify(name));
        //alert(JSON.stringify(value));
        var na = name.split(".");
        //var ff = na[1];
        var vpo = this.state.vpo;
        if(vpo) value = vpo[na[0]][na[1]];
        if(name == "vip02.v2kortnr") value = value.ztrim();
        if(name == "vip02.v2tillgodo") value = value.cdipris();
        if(name == "vip02.v2kredit") value = value.cdipris();
        return value;
   }
    val_set (name, value) {
        //alert(JSON.stringify(name));
        //alert(JSON.stringify(value));
        //var na = name.split(".");
        var state = this.state;
        state[name] = value;
        this.setState(state);
    }

  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "Knappar";
    //if(props.kro) alert(props.kro.krr00.kid);

	var html =
        <div className="mx_base">
        <div className="m2_backdrop" />
        <div className="m2_dlg mx_50 flex-col" style={ this.dlg_style() } >
        <div className="mx_head">
            <div>ORDER: { utils.toNum(this.state.vpo.order00.orderid) }</div>
        </div>
        <div className="mx_content flex">

            <div className="flex-row" style={{padding:"5px",height:"35px"}}>
                <div className="web-link flex-col px-3" onClick={() => this.kvitto_show() }>
                    <div>VISA KVITTO</div>
                </div>
                <div className="flex"></div>
                <div className="web-link flex-col px-3" onClick={() => this.kvitto_prn() }>
                    <div>SKRIV UT</div>
                </div>
            </div>
            <div className="divider_orange"></div>


            <form className="cdi_f03">
            <div className="flex-row">
                <div className="form-col py-5 px-5 flex">
                <div className="form-row flex-row">
                    <div className="txt_22">OrderID:</div>
                    <div className="txt_22">{ utils.toNum(this.state.orderid) }</div>
                </div>
                    <div className="form-row flex-row">
                        <Fr_Input label="Epost" className="w400" text={ this.state.epost } getValue={(e)=> this.val_set("epost", e) } placeholder="" />
                    </div>
                    <div className="form-row flex-row">
                        <Fr_Input label="Mobil" className="w250" text={ this.state.tele } getValue={(e)=> this.val_set("tele", e) } placeholder="" />
                    </div>
                </div>


            </div>
            </form>

        </div>
        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="form-row flex-row"></div>
            <button className="btn btn-hw-green w250" onClick={() => this.kvitto_sms() } >
                SKICKA SMS
            </button>
            <button className="btn btn-hw-green w250" onClick={() => this.kvitto_mail() } >
                SKICKA MAIL
            </button>
        </div>
      </div>
        { this.state.wnds.ordertrs ?
            <Order_Trs wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
        : null }
            <PdfRpDlg show={this.state.pdf} pdf={this.state.pdf_content} done={(ret) => this.pdf_done(ret)} backdrop={true}/>
            <Wnd_Error msg={this.state.msg} ctx={this} />
            <Wnd_Ok msg={this.state.msg} ctx={this} />
            <Wnd_Info msg={this.state.msg} ctx={this} />
            <Wnd_Confirm msg={this.state.msg} ctx={this} />
      </div>
	;
	return html;
  };
}

export default Order_kvitto;