import React, { useState, useEffect, useContext } from 'react';
import * as net from "../../../lib/net";

class Rr_Help extends React.Component {
  constructor(props, context) {
    super(props);

   //this.webStore = props.store;
    this.state = {};
    this.state.ynq = [];
    this.state.paa = [];

    this.state.txa = [
        { color: "VITT", text : "RUMMET ÄR LEDIGT"},
        { color: "RÖTT", text : "RUMMET ÄR UPPTAGET"},
        { color: "ORANGE", text : "RUMMET ÄR OSTÄDAT"},
        { color: "RÖD RANDIGT", text : "RUMMET HAR AVRESA"},
        { color: "GRÖN RANDIGT", text : "RUMMET HAR ANKOMST"},
        { color: "GUL BLINK", text : "STÄDERSKA PÅ RUMMET"}
    ];

    this.state.rro = {};
    this.state.wnds = {};
    if(props.wnds) {
        this.state.rro = props.wnds.rro;
    }
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }


    wnd_close() {
        var self = this;
        var ret = {};
        ret.ok = "999";
        if(self.props.close) self.props.close(self.props.ctx, ret);
        else self.props.ctx.setState({ wnds: {} });
	};
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }

  rr_clean(status) {
    var self = this;

    var prm = {};
    prm.req = "pms.rr.rr_clean";
    prm.gsb01 = this.state.rro.gsb01;
    prm.status = status;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));
        if(ret.ok == "000") {
            if(self.props.close) self.props.close(self.props.ctx, ret);
		    else self.props.ctx.setState({ wnds: {} });
        }
    })
    .catch(function(e) {
        var txt = JSON.stringify(e);
    });
  }

  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "";

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="mx_backdrop" /> : null }
        <div className="mx_dlg mx_80 flex-col" style={ this.dlg_style() }>
            <div className="mx_h01 flex-row" style={{background: "#1a456b", color: "#fff"}}>
                    <div className="flex-col flex">
                        <div>ROOMRACK HJÄLP</div>
                    </div>
            </div>
        <div className="mx_content flex">

        <div className="flex-col txt_18">
        { this.state.txa.map((row, key) =>
            <div className="flex-row">
                <div className="f45 text-right">{ row.color }</div>
                <div className="f10"></div>
                <div className="f45">{ row.text }</div>
            </div>
        )}
        </div>
        <div className="divider_orange"></div>

        </div>
        <div className="mx_foot flex-row">
            <div className="flex"></div>
            <button className="btn btn-hw-red w250 ripple" onClick={() => this.wnd_close() } >
                STÄNG
            </button>
            <div className="flex"></div>
        </div>
      </div>
    </div>
	;
	return html;
  };
}

export default Rr_Help;