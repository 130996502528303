import React, { useState, useEffect, useContext } from 'react';
import {addDays, format} from "date-fns";

import * as utils from "../../assets/utils";
import * as net from "../../lib/net";
import Td_Sel from "../../lib/ui/td_sel";
import Dt_Cal from "../../lib/ui/dt_cal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Calc_Dag extends React.Component {
  constructor(props, context) {
    super(props);

    this.dt_dt = React.createRef();

    this.store = props.store;

    this.state = {};
    this.state.hha = [];
    this.state.kro = {};
    this.state.aa = [];
    this.state.wnds = {};
    this.state.sortid = "";
    this.state.sortdir = 1;

    this.state.today = new Date();
    var dts = format(this.state.today, "yyyy-MM-dd");
    this.state.dts = dts;

    this.selitems = [
      {fkn: 'open', text: 'ÖPPNA'},
      {fkn: 'prn', text: 'SKRIV UT'}
    ];
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.per_hh();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      css.overflow = "auto";
      return css;
  }
    th_style(id) {
        var css = {};
        css.cursor = "pointer";
        if(id == this.state.sortid) {
            if(this.state.sortdir) css.color = "#800";
            else css.color = "#080";
        }
        return css;
    }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }
    dt_dat(e) {
        //alert(JSON.stringify(e));
        var dts = format(e, "yyyy-MM-dd");
        this.setState({ dts: dts });
    }
    addday(e) {
        //alert(JSON.stringify(e));
        //this.setState({ tdat: this.state.fdat });
        this.dt_dt.current.updateAdd();
    }
    subday(e) {
        //alert(JSON.stringify(e));
        //this.setState({ tdat: this.state.fdat });
        this.dt_dt.current.updateSub();
    }

  ee_edit(row) {
    var self = this;
    var wnds = {};
    wnds.eeper = 1;
      wnds.fdat = this.state.fdat;
      wnds.tdat = this.state.tdat;
      wnds.eeo = row;
    this.setState({wnds: wnds});
  }
  fkn_close() {
    this.setState({edit: false });
    //this.props.history.push("cas");
    //this.login_srv(uid, pwd);
  }

  per_hh() {
    var self = this;
    var prm = {};
    prm.req = "ta.ta_calc.per_hh";
    prm.datum = this.state.dts;
    //prm.avd = this.state.avd;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        self.setState({ hha: ret.rca }, function () {
            self.syncTb("perTable");
        });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
    syncTb(tb) {
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
            tdElements = tbel.getElementsByTagName('td');
        if(tdElements.length < 1 ) return;

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
                width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
    tb_sort(typ) {
        var self = this;
        //var rvs = self.state.rvs;
        var hha = self.state.hha;
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;

        if(sortid != typ) sortdir = 0;
        else {
            if(sortdir) sortdir = 0;
            else sortdir = 1;
        }

        if(typ) {
            if(sortdir) hha.sort((a,b) => (a[typ] < b[typ]) ? 1 : ((b[typ] < a[typ]) ? -1 : 0));
            else hha.sort((a,b) => (a[typ] > b[typ]) ? 1 : ((b[typ] > a[typ]) ? -1 : 0));
        }
        self.setState({ hha: hha, sortid: typ, sortdir: sortdir });
    }
    th_icon(typ) {
        var cls = "";
        var sortid = this.state.sortid;
        var sortdir = this.state.sortdir;
        if(sortid == typ)
        {
            if(sortdir) cls = "fa fa-caret-up";
            else cls = "fa fa-caret-down";
        }
        return cls;
    }

  render() {
    return (
    <div className="web-app">

    <div className="flex-row flex-space-between">
        <div className="label_h03">DAG KALKYL / PER TIMME</div>
        <div className="flex"></div>
        <div className="flex-row">
            <div className="dt-btn-01" onClick={() => this.subday() } ><FontAwesomeIcon icon="minus" size="1x" /></div>
            <Dt_Cal dts={ this.state.dat } rp="1" ref={this.dt_dt} getValue={(e)=> this.dt_dat(e)} />
            <div className="dt-btn-01" onClick={() => this.addday() } ><FontAwesomeIcon icon="plus" size="1x" /></div>
        </div>
        <div className="flex"></div>
        <button className="btn btn-hw-blue w150" onClick={() => this.per_hh() } aria-label="System" >
            UPPDATERA
        </button>
    </div>
    <div className="divider"></div>

    <div id="perTable" className="web-col">
        <table className="table table-bordered table-hover tb " style={{marginBottom: "0px"}}>
            <thead>
            <tr>
                <th style={ this.th_style("hh") } onClick={() => this.tb_sort("hh") }>Timme <i className={ this.th_icon("hh") }></i></th>
                <th style={ this.th_style("nrp") } onClick={() => this.tb_sort("nrp") }>Personer <i className={ this.th_icon("nrp") }></i></th>
                <th style={ this.th_style("wrktid") } onClick={() => this.tb_sort("wrktid") }>Arbetad tid <i className={ this.th_icon("wrktid") }></i></th>
                <th style={ this.th_style("lon") } onClick={() => this.tb_sort("lon") }>Lön <i className={ this.th_icon("lon") }></i></th>
                <th style={ this.th_style("ob1lon") } onClick={() => this.tb_sort("ob1lon") }>OB1 Lön <i className={ this.th_icon("ob1lon") }></i></th>
                <th style={ this.th_style("ob2lon") } onClick={() => this.tb_sort("ob2lon") }>OB2 Lön <i className={ this.th_icon("ob2lon") }></i></th>
                <th style={ this.th_style("tlon") } onClick={() => this.tb_sort("tlon") }>Summa <i className={ this.th_icon("tlon") }></i></th>
                <th></th>
            </tr>
            </thead>
        </table>
       <div className="tb-cdi" style={ this.tb_style() }>
       <table className="table table-bordered table-hover tb">
            <tbody>
            { this.state.hha.map((row, key) =>
            <tr key={key} onDoubleClick={() => this.ee_edit(row) }>
                <td className="text-center">{ row.text }</td>
                <td className="text-center">{ row.nrp }</td>
                <td className="text-center">{ utils.toMinTid(row.regtid) }</td>
                <td className="text-right">{ utils.toPris( row.lon ) }</td>
                <td className="text-right">{ utils.toPris( row.ob1lon ) }</td>
                <td className="text-right">{ utils.toPris( row.ob2lon ) }</td>
                <td className="text-right">{ utils.toPris( row.tlon ) }</td>
                <td>
                    <Td_Sel items={ this.selitems } getValue={(e)=> this.sel_row_fkn(row, e)} />
                </td>
            </tr>
            )}
            </tbody>
        </table>
        </div>
    </div>

   </div>
  );
    }
}
export default Calc_Dag;
