import React, { useState, useEffect, useContext } from 'react';

import * as utils from "../../assets/utils";
import * as net from "../../lib/net";
import Lang from "../../lib/lang";

import Td_Sel from "../../lib/ui/td_sel";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Scr_Schedule extends React.Component {
  constructor(props, context) {
    super(props);

    //this.lang = {};
    this.webStore = props.store;
    this.state = { pgid: 11, tra: [] };
    this.state.today = new Date();
    this.state.avd = "01";
    this.state.aa = [];
    this.state.inhsel = 0;
      this.state.msg = {};
      this.state.rvo = {};

    this.state.rva = [];
    this.state.rvo = {};
    this.state.selrow = {};
    this.state.wnds = {};
    this.state.sortid = "";
    this.state.sortdir = 1;

    this.selitems = [
        {fkn: 'open', text: 'ÖPPNA'},
        {fkn: 'cancel', text: 'AVBOKA'}
    ];
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.inh();
  }

  body_style() {
    var css = {};
    css.height = window.innerHeight - 280;
    return css;
  }
  tb_style() {
    var css = {};
    css.height = window.innerHeight - 250;
    css.overflow = "auto";
    return css;
  }
  th_style(id) {
      var css = {};
      css.cursor = "pointer";
      if(id == this.state.sortid) {
          if(this.state.sortdir) css.color = "#800";
          else css.color = "#080";
      }
      return css;
  }
  row_style(row) {
    var srow = this.state.selrow;

    var css = {};
    if(srow == row) {
        css.background = "#080";
        css.color = "#fff";
    }
    return css;
  }
  onUnitChange(e) {
    this.setState({unit: e.value});
  }

    sel_gst(row) {
        var self = this;
        var msg = { info: false, sign: true, text: "Signatur?", text0: "JA", text1: "NEJ", cb: "sel_gst_do" };
        self.setState({ msg: msg, srow: row });
    }
    sel_gst_do(signatur) {
        var self = this;
        var row = this.state.srow;

        //alert(JSON.stringify(val));
        self.setState({msg: {} });

        var wnds = {};
        wnds.inhsel = true;
        wnds.boknr = row.boknr;
        wnds.rvo = row;
        ///this.setState({inhsel: 1, boknr: row.boknr, rvo: row});
        this.setState({wnds: wnds});
    }
  gst_nota() {
    var self = this;

    var srow = this.state.selrow;
    if(!srow.boknr) return;

    var wnds = {};
    wnds.nota = true;
    wnds.boknr = srow.boknr;
    wnds.rvo = srow;
    this.setState({wnds: wnds});
  }

    dsi_schedule() {
        var self = this;
        var prm = {};
        prm.req = "dsi.dsi_tmpl.tmpl_list";

        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret.rca));

                self.setState({ lta: ret.rca });
                //self.setState({ rva: ret.rca });
                self.syncTb("scrltTable");
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    syncTb(tb) {
        if (!document.getElementById(tb)) {
            //alert("No tb");
            return;
        }
        var tbel = document.getElementById(tb);
        const thElements = tbel.getElementsByTagName('th'),
            tdElements = tbel.getElementsByTagName('td');
        if(tdElements.length < 1 ) return;

        for (let i = 0; i < thElements.length; i++) {
            const widerElement =
                    thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
                width = window.getComputedStyle(widerElement).width;
            thElements[i].style.width = tdElements[i].style.width = width;
        }
    }
  sel_row(row) {
    var srow = this.state.selrow;
    if(srow == row) {
      this.setState({selrow: {}});
    }
    else this.setState({selrow: row});
  }
  sel_row_fkn(row, sel) {
      if(sel.fkn == "open") {
          this.sel_gst(row);
      }
  }
    gst_close(ctx) {
        ctx.setState({ wnds: {} });
    };
   tb_sort(typ) {
      var self = this;
      //var rvs = self.state.rvs;
      var rva = self.state.rva;
      var sortid = this.state.sortid;
      var sortdir = this.state.sortdir;

      if(sortid != typ) sortdir = 0;
      else {
        if(sortdir) sortdir = 0;
        else sortdir = 1;
      }

      if(typ == "ank") {
        if(sortdir) rva.sort((a,b) => (a.gsb00.r0ankdatum < b.gsb00.r0ankdatum) ? 1 : ((b.gsb00.r0ankdatum < a.gsb00.r0ankdatum) ? -1 : 0));
        else rva.sort((a,b) => (a.gsb00.r0ankdatum > b.gsb00.r0ankdatum) ? 1 : ((b.gsb00.r0ankdatum > a.gsb00.r0ankdatum) ? -1 : 0));
      }
      self.setState({ rva: rva, sortid: typ, sortdir: sortdir });
   }
   th_icon(typ) {
      var cls = "";
      var sortid = this.state.sortid;
      var sortdir = this.state.sortdir;
      if(sortid == typ)
      {
        if(sortdir) cls = "fa fa-caret-up";
        else cls = "fa fa-caret-down";
      }
      return cls;
    }

  render() {
    return (
    <div className="web-bas">

        <div className="flex-row">
            <button className="btn btn-hw-blue w200" onClick={() => this.tst() } aria-label="System" >
                SCHEMA
            </button>
            <div className="flex"></div>
            <button className="btn btn-hw-blue w200" onClick={() => this.dsi_schedule() } aria-label="System" >
                UPPDATERA
            </button>
        </div>
        <div className="divider_green"></div>

    <div className="web-row">

        <div id="inhTable" className="flex">
          <table className="table table-bordered table-hover tb" style={{marginBottom: "0px"}}>
            <thead>
            <tr>
                <th style={ this.th_style("rumsnr") } onClick={() => this.tb_sort("rumsnr") }><Lang>Skärm <i className={ this.th_icon("rumsnr") }></i></Lang></th>
                <th style={ this.th_style("boknr") } onClick={() => this.tb_sort("boknr") }><Lang>Text</Lang> <i className={ this.th_icon("boknr") }></i></th>
                <th style={ this.th_style("ank") } onClick={() => this.tb_sort("ank") }><Lang>Typ</Lang> <i className={ this.th_icon("ank") }></i></th>
                <th style={ this.th_style("avr") } onClick={() => this.tb_sort("avr") }><Lang>Från Tid</Lang> <i className={ this.th_icon("avr") }></i></th>
                <th style={ this.th_style("ftg") } onClick={() => this.tb_sort("ftg") }><Lang>Till Tid</Lang> <i className={ this.th_icon("ftg") }></i></th>
                <th style={ this.th_style("bettyp") } onClick={() => this.tb_sort("bettyp") }><Lang>Status</Lang> <i className={ this.th_icon("bettyp") }></i></th>
                <th></th>
            </tr>
            </thead>
        </table>
        <div style={ this.tb_style() }>
          <table className="table table-bordered table-hover tb tbs02">
            <tbody>
            { this.state.rva.map((row, key) =>
            <tr key={key} style={ this.row_style(row) } onClick={() => this.sel_row(row)} onDoubleClick={ () => this.sel_gst(row) }>
                <td>{ row.scrid }</td>
                <td>{ row.text }</td>
                <td>{ row.cttyp }</td>
                <td>{ row.fttid }</td>
                <td>{ row.ttid }</td>
                <td>{ row.status }</td>
                <td>
                    <Td_Sel items={ this.selitems } getValue={(e)=> this.sel_row_fkn(row, e)} />
                </td>
            </tr>
            )}
            </tbody>
        </table>
        </div>
        </div>
    </div>

    </div>
  );
    }
}
export default Scr_Schedule;
