import React, { useState, useEffect, useContext } from 'react';
import {format, addDays, subDays} from "date-fns";

import * as net from "../../../../lib/net";
import Fr_Input from "../../../../lib/ui/fr_input";
import Fr_Select from "../../../../lib/ui/fr_select";
import Dt_Cal from "../../../../lib/ui/dt_cal";
import Td_Sel from "../../../../lib/ui/td_sel";

import Rv_GstRooms from "./gst_rooms";

class Gst_Wrk extends React.Component {
  constructor(props, context) {
    super(props);

    this.gstrowitems = [
        {fkn: 'open', text: 'ÖPPNA'},
        {fkn: 'cancel', text: 'AVBOKA'}
    ];

    this.store = props.store;
    this.pms = props.store.pms;

    this.state = {};
    this.state.today = new Date();
    this.state.rta = [];
    this.state.dtga = [];

    this.state.gnr = "001";
    this.state.gbo = {};
    //this.state.lro = props.lro;
    this.state.ynq = [
                {id: '0', text: 'NEJ'},
                {id: '1', text: 'JA'}
            ];

    this.state.background = "#fff";

    this.state.cols = {};
    this.state.boknr = {};
    if(props.boknr) this.state.boknr = props.boknr;

    this.state.wnds = {};
    this.state.gso = {};
    if(props.wnds) {
        if(props.wnds.boknr) this.state.boknr = props.wnds.boknr;
        if(props.wnds.gso) this.state.gso = props.wnds.gso;
    }
    this.state.blopnr = "";
    this.state.dela = "";

    this.state.gsa = [];
    this.state.dgsa = [];
    this.state.bro = {};
    this.state.brx = {};
    this.state.bra = [];
    if(props.ctx) {
        //this.state.bra = props.ctx.bra;
    }
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.gst_init();
    this.brgs();
  }
  gst_row_fkn(row, sel) {
    //alert(JSON.stringify(fkn));
      if(sel.fkn == "open") {
          //this.rv_edit(row);
      }
      if(sel.fkn == "cancel") {
          this.gst_cancel(row);
      }
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }
    grp_style(row) {
      var css = {};
      css.background = "#008";
      if(this.state.gnr === row.vgrupp) css.background = "#080";
      return css;
    }

    formchange (e) {
      const name = e.target.name;
      const value = e.target.value;
      var pbo = this.state.pbo;
      var na = name.split(".");
      pbo[na[0]][na[1]] = value;
      this.setState({pbo: pbo});
      //setState({[name]: value});
    }

    handleUserInput (e) {
      const name = e.target.name;
      const value = e.target.value;
      var user = this.state.user;
      user[name] = value;
      this.setState({user: user});
      //setState({[name]: value});
    }
    wnd_close = () => {
      if(this.props.cb) {
        var ret = {};
        ret.ok = "999";
        this.props.cb(this.props.ctx, ret);
      }
      else this.props.ctx.setState({ wnds: {} });
	};
    handleChange = (event, newValue) => {
        //alert(newValue);
        this.setState({ix: newValue});
      };
    toggle() {
      this.setState({modal: false});
	};

    submitHandler = event => {
        event.preventDefault();
        event.target.className += " was-validated";
    };

    changeHandler = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    bro_set (e) {
      const value = e.target.value;
      var bro = this.state.brx[value];
      this.setState({blopnr: value, bro: bro});
    }
    dela_set (e) {
      const value = e.target.value;
      //var bro = this.state.brx[value];
      this.setState({dela: value});
    }

    val_save (name, value) {
        //alert(JSON.stringify(name));
        //alert(JSON.stringify(value));
      var gso = this.state.gso;
      gso[name] = value;
      this.setState({gso: gso});
      //setState({[name]: value});
    }
    cb_save (name, e) {
      const value = e.target.value;
      var bro = this.state.bro;
      bro[name] = value;
      this.setState({bro: bro});
      //setState({[name]: value});
    }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }

  gst_edit(row) {
    var self = this;
  }
  gst_room(e) {
    e.preventDefault();
    var self = this;
    var wnds = {gstroom: true, boknr: this.state.boknr, bro: this.state.bro};
    this.setState({wnds: wnds});
  }
  cb_gstrooms(ctx, ret) {
      if(ret.ok == "000") {
          //ctx.rv_br();
        var gso = ctx.state.gso;
        gso.rumsnr = ret.rumsnr + "00";
        ctx.setState({ wnds: {}, gso: gso });
      }
      else {
        ctx.setState({ wnds: {} });
      }
  };

  gst_init() {
    var self = this;
    var dat = addDays(this.state.today, 1);
    var fdat = format(this.state.today, "yyyy-MM-dd");
    var tdat = format(dat, "yyyy-MM-dd");

    var gso = {};
    gso.fdat = fdat;
    gso.tdat = tdat;
    gso.rumsnr = "";
    gso.namn = "";
    gso.nrr = "1";
    gso.nrg = "1";
    gso.nrgr = "1";
    gso.nrb0 = "0";
    gso.nrb1 = "0";
    gso.nrb2 = "0";
    gso.rt = "";
    gso.pkat = "";
    gso.rab = "";
    this.setState({gso: gso});
  }
  brgs() {
    var self = this;

    var prm = {};
    prm.req = "pms.br.bra";
    prm.boknr = this.state.boknr;
    //alert(JSON.stringify(prm));

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok == "000") {
            var bra = ret.rca;
            var bro = bra[0];

            self.setState({bra: bra, bro: bro, brx: ret.rco });
        }

        var prm = {};
        prm.req = "pms.br.gsa";
        prm.boknr = self.state.boknr;
        //alert(JSON.stringify(prm));
        return net.sio_req(prm);
    })
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok == "000") {
            var dgsa = [];
            dgsa.push({ r0glopnr: "", r0namn: "DELA EJ"})
            for(var row of ret.rca) {
                dgsa.push( row );
            }
            self.setState({gsa: ret.rca, dgsa: dgsa });
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
    return;
  }

  brs() {
    var self = this;

    var prm = {};
    prm.req = "pms.br.bra";
    prm.boknr = this.state.boknr;
    //alert(JSON.stringify(prm));

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            return;
        }
        self.setState({bra: ret.rca });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
    return;
  }
  gsts() {
    var self = this;

    var prm = {};
    prm.req = "pms.br.gsa";
    prm.boknr = this.state.boknr;
    //alert(JSON.stringify(prm));

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            return;
        }
        var dgsa = [];
        dgsa.push({ r0glopnr: "", r0namn: "DELA EJ"})
        for(var row of ret.rca) {
            dgsa.push( row );
        }
        self.setState({gsa: ret.rca, dgsa: dgsa });
        //self.setState({gsa: ret.rca });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
    return;
  }
  gst_add() {
    var self = this;
    var prm = {};
    prm.req = "pms.br.br_gst_add";
    prm.boknr = this.state.boknr;
    prm.rblx0 = this.state.bro;
    prm.gso = this.state.gso;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok == "000") {
		    //self.props.cb(self.props.ctx, ret);
            /*
              if(self.props.cb) {
                var ret = {};
                ret.ok = "999";
                self.props.cb(this.props.ctx, ret);
              }
              else self.props.ctx.setState({ wnds: {} });
             */
            self.gsts();
            return;
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  gst_cancel(row) {
    var self = this;
    var prm = {};
    prm.req = "pms.br.br_gst_del";
    prm.boknr = this.state.boknr;
    prm.gsb00 = row;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok == "000") {
            self.gsts();
            return;
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "Gäst";
    //if(props.kro) alert(props.kro.krr00.kid);
    const popover = {
      position: 'absolute',
      top: '50px',
      zIndex: '2',
    }
    const cover = {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    }

	var html =
        <div className="mx_base">
        <div className="m2_backdrop" />
        <div className="m2_dlg mx_100 flex-col" style={ this.dlg_style() } draggable="true" >

        <div className="mx_h01">
            <div>GÄSTER</div>
        </div>
        <div className="mx_content flex">

            <div className="divider_orange"></div>
            <div className="flex">
            <div className="flex flex-row" style={{border: "0px", margin: "0px", padding: "0px"}}>
                <table className="table" style={{border: "0px", margin: "0px", padding: "0px"}}>
                    <thead style={{fontSize: "12px", fontWeight: "normal", color: "#080", border: "0px", margin: "0px", padding: "0px"}}>
                    <tr>
                        <th style={{textAlign: "left"}}>Delbok</th>
                        <th style={{textAlign: "left"}}>Gästnr</th>
                        <th style={{textAlign: "left"}}>Namn</th>
                        <th style={{textAlign: "left"}}>Antal</th>
                        <th style={{textAlign: "left"}}>Barn 1</th>
                        <th style={{textAlign: "left"}}>Barn 2</th>
                        <th style={{textAlign: "left"}}>Status</th>
                        <th style={{textAlign: "center", color: "#800"}}>#</th>
                    </tr>
                    </thead>
                    <tbody style={{fontSize: "16px", color: "#000", border: "0px", margin: "0px", padding: "0px", borderBottom: "1px solid #ccc"}}>
                    { this.state.gsa.map((row, key) =>
                    <tr onDoubleClick={() => this.gst_edit(row) }>
                        <td>{ row.r0blopnr }</td>
                        <td>{ row.r0glopnr }</td>
                        <td>{ row.r0namn }</td>
                        <td>{ row.r0guests }</td>
                        <td>{ row.r0antb0 }</td>
                        <td>{ row.r0antb1 }</td>
                        <td>{ row.r0status }</td>
                        <td>
                            <Td_Sel items={ this.gstrowitems } getValue={(e)=> this.gst_row_fkn(row, e)} />
                        </td>
                    </tr>
                    )}
                    </tbody>
                </table>
             </div>
             </div>

            <div className="divider_orange"></div>

            <form className="cdi_f03">
            <div className="flex-row py-2">
                <div className="txt_18 text-danger">NY GÄST</div>
            </div>
            <div className="flex-row py-2">
                <div className="form-group flex-row flex">
                       <span className="header">Delbokning</span>
                        <select value={this.state.blopnr} className="form-control w450" onChange={(e) => this.bro_set(e)}>
                            { this.state.bra.map((row, key) =>
                            <option key={key} value={row.r0lopnr}>{row.r0typ} : {row.r0ankdatum.cdidate()} - {row.r0avrdatum.cdidate()}</option>
                            )}
                        </select>
                </div>
                <div className="flex-row">
                    <div className="txt_18 w250">Rumsnr: {this.state.gso.rumsnr}</div>
                    <button className="btn btn-hw-blue w150" onClick={(e) => this.gst_room(e) } >
                        SÖK RUM
                    </button>
                </div>
            </div>
            <div className="flex-row py-2">
                    <Fr_Input label="Namn" className="text-left w350" text={this.state.gso.namn} getValue={(e)=> this.val_save("namn", e) } />
            </div>
            <div className="flex-row py-2">
                    <Fr_Input label="Antal" className="text-left w150" text={this.state.gso.nrg} getValue={(e)=> this.val_save("nrg", e) } />
                    <Fr_Input label="Barn 0-4" className="text-left w150" text={this.state.gso.nrb0} getValue={(e)=> this.val_save("nrb0", e) } />
            </div>
            <div className="flex-row py-2">
                <div className="form-group flex-row flex">
                       <span className="header">Dela med</span>
                       <select value={this.state.dela} className="form-control w350" onChange={(e) => this.dela_set(e)}>
                            { this.state.dgsa.map((row, key) =>
                            <option key={key} value={row.r0glopnr}>{row.r0glopnr} : {row.r0namn}</option>
                            )}
                        </select>
                </div>
                    <Fr_Input label="Barn 5-18" className="text-left w150" text={this.state.gso.nrb1} getValue={(e)=> this.val_save("nrb1", e) } />
            </div>
            <div className="divider_green"></div>
            <div className="flex-row py-2">
                    <Fr_Input label="Företag" className="text-left w350" text={this.state.gso.ftg} getValue={(e)=> this.val_save("ftg", e) } />
            </div>
            <div className="flex-row py-2">
                    <Fr_Input label="Epost" className="text-left w250" text={this.state.gso.email} getValue={(e)=> this.val_save("email", e) } />
                    <Fr_Input label="Pass" className="text-left w250" text={this.state.gso.pass} getValue={(e)=> this.val_save("pass", e) } />
            </div>
            <div className="flex-row py-2">
                    <Fr_Input label="Telefon" className="text-left w250" text={this.state.gso.tele} getValue={(e)=> this.val_save("tele", e) } />
                    <Fr_Input label="Mobil" className="text-left w250" text={this.state.gso.mobile} getValue={(e)=> this.val_save("mobile", e) } />
            </div>
            <div className="divider_green"></div>
            </form>
        </div>

        <div className="mx_foot flex-row flex-space-between">
            <button className="btn btn-hw-red w250" onClick={() => this.wnd_close() } >
                AVBRYT
            </button>
            <div className="flex-row">
            <button className="btn btn-hw-red w250" onClick={() => this.gst_add() } >
                LÄGG TILL
            </button>
            </div>
        </div>
      </div>
        { this.state.wnds.gstroom ? <Rv_GstRooms wnds={this.state.wnds} cb={this.cb_gstrooms} ctx={this} store={this.props.store} backdrop={true}/> : null }

      </div>
	;
	return html;
  };
}

export default Gst_Wrk;

/*
<Fr_Input label="Rumsnr" className="text-left w250" text={this.state.gso.rumsnr} getValue={(e)=> this.val_save("rumsnr", e) } />

*/