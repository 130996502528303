import React, { useState, useEffect, useContext } from 'react';
import * as net from "../../../lib/net";

import Pms_Rvo from "../../rv/fb/rvo";
import Pms_Keys from "./keys";
import Pms_Stad from "../fkn/rr_clean";
import Pms_Vent from "../fkn/rr_vent";
import Pms_Ljus from "../fkn/rr_ljus";
import Pms_Tv from "../fkn/rr_tv";

class Pms_RoomFkn extends React.Component {
  constructor(props, context) {
    super(props);

   //this.webStore = props.store;
    this.state = {};
    this.state.ynq = [];

    this.state.paa = [];
    this.state.aa = [];
    this.state.boknr = "";
    if(props.boknr) this.state.boknr = props.boknr;
    this.state.rvo = {};
    if(props.rvo) this.state.rvo = props.rvo;
    if(props.wnds) {
        this.state.boknr = props.wnds.boknr;
        this.state.rvo = props.wnds.rvo;
    }
    this.state.wnds = {};
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }

    wnd_close() {
		this.props.ctx.setState({ wnds: {} });
	};
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
  cssCols (cid) {
      var css = {};
      if(this.state.cols[cid]) css.width = this.state.cols[cid].toString() + "px";
      //if(cid == "c01") alert(JSON.stringify(this.state.cols[cid]));
      return css;
  }
  fknSel(sel) {
    //alert(JSON.stringify(fkn));
      if(sel == "rvo") {
          var wnds = {};
          wnds.wnd_rvo = true;
        this.setState({wnds: wnds});
      }
      if(sel == "stad") {
          var wnds = {};
          wnds.stad = true;
          wnds.boknr = this.state.boknr;
          wnds.rvo = this.state.rvo;
          wnds.rumsnr = this.state.rvo.gsb00.r0rumsnr;
        this.setState({wnds: wnds});
      }
      if(sel == "keys") {
          var wnds = {};
          wnds.keys = true;
          wnds.boknr = this.state.boknr;
          wnds.rvo = this.state.rvo;
          wnds.rumsnr = this.state.rvo.gsb00.r0rumsnr;
        this.setState({wnds: wnds});
      }
      if(sel == "ljus") {
          var wnds = {};
          wnds.ljus = true;
          wnds.boknr = this.state.boknr;
          wnds.rvo = this.state.rvo;
          wnds.rumsnr = this.state.rvo.gsb00.r0rumsnr;
        this.setState({wnds: wnds});
      }
      if(sel == "vent") {
          var wnds = {};
          wnds.vent = true;
          wnds.boknr = this.state.boknr;
          wnds.rvo = this.state.rvo;
          wnds.rumsnr = this.state.rvo.gsb00.r0rumsnr;
       this.setState({wnds: wnds});
      }
      if(sel == "tv") {
          var wnds = {};
          wnds.tv = true;
          wnds.boknr = this.state.boknr;
          wnds.rvo = this.state.rvo;
          wnds.rumsnr = this.state.rvo.gsb00.r0rumsnr;
       this.setState({wnds: wnds});
      }
  }
    rvo_close(ctx) {
        ctx.setState({ wnds: {} });
    };
    gst_close(ctx) {
        ctx.setState({ wnds: {} });
    };
  room_tv() {
    var self = this;

    var prm = {};
    prm.req = "pms.hk.room_tv";
    prm.rumsnr = "0205";
    prm.namn = "Teste Testsson";
    //alert(JSON.stringify(prm));

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok == "000") {
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
    return;
  }
  room_msg() {
    var self = this;

    var prm = {};
    prm.req = "pms.hk.room_tv";
    prm.rumsnr = "0205";
    prm.namn = "Teste Testsson";
    //alert(JSON.stringify(prm));

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok == "000") {
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
    return;
  }

  render() {
    //if(!this.props.show) return "";

    var title = this.props.title;
    if(!title) title = "";

	var html =
        <div className="mx_base">
        { this.props.backdrop ? <div className="m2_backdrop" /> : null }
        <div className="m2_dlg mx_70 flex-col" style={ this.dlg_style() }>
            <div className="mx_h01 flex-row" style={{background: "#1a456b", color: "#fff"}}>
                    <div className="flex-col flex">
                        <div>RUMS FUNKTIONER</div>
                    </div>
                    <div className="flex-col">
                        <div>BOKNR: { this.state.boknr }</div>
                    </div>
            </div>
        <div className="mx_content flex">

             <div className="txt_box txt_bg_01 txt_22">
             <div className="flex-row">
                    <div className="flex-col flex">
                        <div className="txt_lbl">RUM:</div>
                        <div>{ this.state.rvo.gsb00.r0rumsnr.cdirumsnr() }</div>
                    </div>
                    <div className="flex-col flex">
                        <div className="txt_lbl">RUMSTYP:</div>
                        <div> { this.state.rvo.rblx0.r0typ }</div>
                    </div>
            </div>
             <div className="flex-row">
                    <div className="flex-col flex">
                        <div className="txt_lbl">PERIOD:</div>
                        <div>{ this.state.rvo.gsb00.r0ankdatum.cdidate() } - { this.state.rvo.gsb00.r0avrdatum.cdidate() }</div>
                    </div>
                    <div className="flex-col flex">
                        <div className="txt_lbl">GÄSTER:</div>
                        <div>{ this.state.rvo.rblx0.r0guests }</div>
                    </div>
            </div>

           </div>

            <div className="flex"></div>
            <div className="divider_orange"></div>
        <div className="web-row">
        <div className="web-col flex">
            <div className="wnd-btn" onClick={() => this.fknSel("tv") } >
                <div>TV</div>
            </div>
            <div className="wnd-btn" onClick={() => this.wnd_close() } >
                <div>STÄNG</div>
                <div></div>
            </div>
        </div>
         <div className="web-col flex" style={{padding: "0px 10px"}}>
            <div className="wnd-btn" onClick={() => this.fknSel("vent") } >
                <div>VENTILATION</div>
                <div></div>
            </div>
            <div className="wnd-btn" onClick={() => this.fknSel("ljus") } >
                <div>LJUS</div>
                <div></div>
            </div>
       </div>
         <div className="web-col flex" style={{padding: "0px 10px"}}>
            <div className="wnd-btn" onClick={() => this.fknSel("keys") } >
                <div>NYCKEL</div>
                <div></div>
            </div>
            <div className="wnd-btn" onClick={() => this.fknSel("stad") } >
                <div>STÄDA / OSTÄDA</div>
                <div></div>
            </div>
       </div>
       </div>

        </div>
      </div>
            { this.state.wnds.wnd_rvo ?
        <Pms_Rvo close={this.rvo_close} boknr={this.state.boknr} ctx={this} store={this.props.store} backdrop={true}/>
            : null }
            { this.state.wnds.stad ?
        <Pms_Stad close={this.gst_close} wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
            : null }
            { this.state.wnds.keys ?
        <Pms_Keys close={this.gst_close} wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
            : null }
            { this.state.wnds.ljus ?
        <Pms_Ljus close={this.gst_close} wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
            : null }
            { this.state.wnds.vent ?
        <Pms_Vent close={this.gst_close} wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
            : null }
            { this.state.wnds.tv ?
        <Pms_Tv close={this.gst_close} wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
            : null }
    </div>
	;
	return html;
  };
}

export default Pms_RoomFkn;